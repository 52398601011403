import * as React from 'react';

import './BurgerButton.scss';

interface Props {
  onClick: () => void;
}

export const BurgerButton = (props: Props) => {
  const { onClick } = props;

  return (
    <div className="bBurgerButton" onClick={onClick}>
      <div className="eBurgerButtonItem eBurgerButtonItem--first"></div>
      <div className="eBurgerButtonItem"></div>
      <div className="eBurgerButtonItem eBurgerButtonItem--last"></div>
    </div>
  );
};

BurgerButton.displayName = 'BurgerButton';
