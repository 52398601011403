import { AppUser } from 'Src/views/App/App';
import axios from 'axios';

export function sendTournamentNotification(user: AppUser, data, tournamentId) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/notifications`, data, config)
    .then(response => {
      return response.data;
    });
}
