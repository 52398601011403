import * as React from 'react';
import * as propz from 'propz';
import { AppUser } from '../App/App';
import { LoginSession } from 'Src/models/sessions';
import { login } from 'Src/helpers/auth/auth';
import StorageHelper from '../../helpers/storage/storage';
import { LoginForm } from 'Src/components/LoginForm/LoginForm';
import { UserProfile } from '../../models/profile';
import { getProfile } from '../../helpers/service/admin/user';
import { History, Location } from 'history';

interface Props {
  user: AppUser;
  history: History;
  location: Location;
  onFormSubmit: (userId: string, key: string, profile: UserProfile) => void;
}

interface State {
  email: string;
  password: string;
  isRememberMe: boolean;
  isError: boolean;
}

export class Login extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      isRememberMe: false,
      isError: false
    };
  }

  onEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ email: value });
  };

  onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ password: value });
  };

  onFormSubmit = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    const { email, password, isRememberMe } = this.state;
    const { user, onFormSubmit, history, location } = this.props;
    let userId, key;

    login(email, password)
      .then((loginSession: LoginSession) => {
        userId = loginSession.userId;
        key = loginSession.key;

        if (isRememberMe) {
          //delete cookie from old frontend
          StorageHelper.Cookie.remove('loginSession', { domain: '.squadintouch.com' });
          // one year cookie. just count of days because js-cookie convert days to msec itself
          StorageHelper.Cookie.set('loginSession', loginSession, { expires: 365 });
        } else {
          //delete cookie from old frontend
          StorageHelper.Cookie.remove('loginSession', { domain: '.squadintouch.com' });
          StorageHelper.Cookie.set('loginSession', loginSession);
        }

        const userUpdated = {
          ...user,
          authorized: true,
          userId: userId,
          loginSessionKey: key
        };

        return getProfile(userUpdated);
      })
      .then(profile => {
        onFormSubmit(userId, key, profile);
        const isEmailVerified = propz.get(profile, ['verification', 'status', 'email']);
        const isPhoneVerified = propz.get(profile, ['verification', 'status', 'sms']);
        const isUserVerified = isEmailVerified && isPhoneVerified;

        if (isUserVerified) {
          history.push({
            pathname: 'role',
            state: location.state
          });
        } else {
          history.push('/profile/verification');
        }
      })
      .catch(error => {
        this.setState({ isError: true });
      });
  };

  onRememberMeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.checked;
    this.setState({ isRememberMe: value });
  };

  render() {
    return (
      <LoginForm
        email={this.state.email}
        password={this.state.password}
        onEmailChange={this.onEmailChange}
        onPasswordChange={this.onPasswordChange}
        onFormSubmit={this.onFormSubmit}
        isRememberMe={this.state.isRememberMe}
        onRememberMeChange={this.onRememberMeChange}
        isError={this.state.isError}
      />
    );
  }
}
