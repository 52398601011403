import * as React from 'react';
import * as Yup from 'yup';
import * as propz from 'propz';
import { Field, Form, Formik, ErrorMessage } from 'formik';
import { Button } from '../../../components/Button/Button';
import * as Moment from 'moment';
import { DATE_FORMAT } from '../../../consts/date';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import { Autocomplete } from '../../../components/Autocomplete/Autocomplete';
import {
  getName,
  searchFunctionSchoolFormsPublic,
  searchFunctionSchoolHousesPublic
} from '../../../helpers/autocomplete/autocomplete';
import { GENDER_SERVER_TO_CLIENT_MAPPING, USER_GENDER } from '../../../consts/user';
import { SCHOOL_PERMISSION_REQUEST_FIELD_STATUS } from '../../../consts/school';
import { TextUtils } from '../../../helpers/utils/TextUtils';
import { School } from '../../../models/school';

interface Props {
  onSubmit: (data) => void;
  school: any;
}
interface State {
  school: School;
}

function getValidationString(school, fieldName) {
  switch (school.additionalPermissionRequestFields[fieldName]) {
    case SCHOOL_PERMISSION_REQUEST_FIELD_STATUS.REQUIRED:
      return Yup.string().required('Required');
    default:
      return Yup.string();
  }
}

export class ParentDetails extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  getSchoolForms = (text: string) => {
    const { school } = this.props;
    return searchFunctionSchoolFormsPublic(text, school.id);
  };

  getSchoolHouses = (text: string) => {
    const { school } = this.props;
    return searchFunctionSchoolHousesPublic(text, school.id);
  };

  render() {
    const { onSubmit, school } = this.props;

    const validationSchema = Yup.object().shape({
      students: Yup.array().of(
        Yup.object().shape({
          firstName: getValidationString(school, 'childFirstName'),
          lastName: getValidationString(school, 'childLastName'),
          gender: getValidationString(school, 'childGender'),
          birthday: getValidationString(school, 'childDateOfBirth'),
          formId: getValidationString(school, 'childForm'),
          houseId: getValidationString(school, 'childHouse'),
          oneOffCode: Yup.string()
        })
      )
    });

    const initialValues = {
      students: [
        {
          firstName: '',
          lastName: '',
          gender: USER_GENDER.MALE,
          birthday: undefined,
          form: undefined,
          house: undefined,
          formId: '',
          houseId: '',
          oneOffCode: ''
        }
      ],
      school: null
    };

    const isChildFirstName =
      school.additionalPermissionRequestFields.childFirstName !== SCHOOL_PERMISSION_REQUEST_FIELD_STATUS.HIDDEN;
    const isChildLastName =
      school.additionalPermissionRequestFields.childLastName !== SCHOOL_PERMISSION_REQUEST_FIELD_STATUS.HIDDEN;
    const isChildDateOfBirth =
      school.additionalPermissionRequestFields.childDateOfBirth !== SCHOOL_PERMISSION_REQUEST_FIELD_STATUS.HIDDEN;
    const isChildGender =
      school.additionalPermissionRequestFields.childGender !== SCHOOL_PERMISSION_REQUEST_FIELD_STATUS.HIDDEN;
    const isChildForm =
      school.additionalPermissionRequestFields.childForm !== SCHOOL_PERMISSION_REQUEST_FIELD_STATUS.HIDDEN;
    const isChildHouse =
      school.additionalPermissionRequestFields.childHouse !== SCHOOL_PERMISSION_REQUEST_FIELD_STATUS.HIDDEN;

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className={'font-weight-bold'}>Enter the details of your child/children</div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={values => {
                const { students } = values;
                const dataToPost = students.map(student => {
                  const { firstName, lastName, gender, birthday, formId, houseId, oneOffCode } = student;
                  let studentData = {
                    comment: `Request to be parent of [${firstName} ${lastName}]`,
                    childGender: gender
                  };

                  if (!TextUtils.isEmpty(birthday)) {
                    propz.set(studentData, ['childDateOfBirth'], birthday);
                  }

                  if (!TextUtils.isEmpty(formId)) {
                    propz.set(studentData, ['childFormId'], formId);
                  }

                  if (!TextUtils.isEmpty(houseId)) {
                    propz.set(studentData, ['childHouseId'], houseId);
                  }

                  if (!TextUtils.isEmpty(oneOffCode)) {
                    propz.set(studentData, ['details', 'oneOffCode'], oneOffCode);
                  }

                  return studentData;
                });

                onSubmit(dataToPost);
              }}
            >
              {({ touched, errors, values, setFieldValue }) => (
                <Form>
                  {values.students.map((student, index) => {
                    return (
                      <div key={`student_${index}`}>
                        {isChildFirstName && (
                          <div className="form-group">
                            <label>First name</label>
                            <Field name={`students.${index}.firstName`} className={'form-control mb-3'} />
                            <ErrorMessage
                              component="div"
                              className="alert alert-danger mt-3"
                              name={`students.${index}.firstName`}
                            />
                          </div>
                        )}

                        {isChildLastName && (
                          <div className="form-group">
                            <label>Last name</label>
                            <Field name={`students.${index}.lastName`} className={'form-control mb-3'} />
                            <ErrorMessage
                              component="div"
                              className="alert alert-danger mt-3"
                              name={`students.${index}.lastName`}
                            />
                          </div>
                        )}
                        {isChildDateOfBirth && (
                          <div className="form-group">
                            <label>Date of birth</label>
                            <Field
                              name={`students.${index}.birthday`}
                              render={({ field }) => {
                                //convert server field birthday
                                let date;

                                if (Moment(student.birthday, DATE_FORMAT).isValid()) {
                                  date = Moment(student.birthday, DATE_FORMAT).toDate();
                                }

                                return (
                                  <div className="mb-3">
                                    <DatePicker
                                      selected={date}
                                      onChange={birthday => {
                                        if (birthday !== null) {
                                          //empty date
                                          setFieldValue(`students.${index}.birthday`, birthday);
                                        } else {
                                          setFieldValue(`students.${index}.birthday`, '');
                                        }
                                      }}
                                      className="form-control"
                                      dateFormat={'dd-MM-yyyy'}
                                      placeholderText="DD-MM-YYYY"
                                      customInput={
                                        <MaskedInput
                                          mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                        />
                                      }
                                    />
                                  </div>
                                );
                              }}
                            />
                            <ErrorMessage
                              component="div"
                              className="alert alert-danger mt-3"
                              name={`students.${index}.birthday`}
                            />
                          </div>
                        )}
                        {isChildGender && (
                          <div className="form-group">
                            <label>Gender</label>
                            <Field component="select" name={`students.${index}.gender`} className="form-control mb-3">
                              <option value={USER_GENDER.MALE}>{GENDER_SERVER_TO_CLIENT_MAPPING.MALE}</option>
                              <option value={USER_GENDER.FEMALE}>{GENDER_SERVER_TO_CLIENT_MAPPING.FEMALE}</option>
                            </Field>
                            <ErrorMessage
                              component="div"
                              className="alert alert-danger mt-3"
                              name={`students.${index}.gender`}
                            />
                          </div>
                        )}
                        {isChildForm && (
                          <div className="form-group">
                            <label>Form</label>
                            <Field
                              name={`students.${index}.form`}
                              render={({ field }) => {
                                return (
                                  <Autocomplete
                                    searchFunction={this.getSchoolForms}
                                    getElementTitle={getName}
                                    customClass="mFullWidth mb-3"
                                    defaultItem={student.form}
                                    onSelect={form => {
                                      setFieldValue(`students.${index}.formId`, form.id);
                                      setFieldValue(`students.${index}.form`, form);
                                    }}
                                  />
                                );
                              }}
                            />
                            <ErrorMessage
                              component="div"
                              className="alert alert-danger mt-3"
                              name={`students.${index}.formId`}
                            />
                          </div>
                        )}
                        {isChildHouse && (
                          <div className="form-group">
                            <label>House</label>
                            <Field
                              name={`students.${index}.house`}
                              render={({ field }) => {
                                return (
                                  <Autocomplete
                                    searchFunction={this.getSchoolHouses}
                                    getElementTitle={getName}
                                    customClass="mFullWidth mb-3"
                                    defaultItem={student.house}
                                    onSelect={house => {
                                      setFieldValue(`students.${index}.houseId`, house.id);
                                      setFieldValue(`students.${index}.house`, house);
                                    }}
                                  />
                                );
                              }}
                            />
                            <ErrorMessage
                              component="div"
                              className="alert alert-danger mt-3"
                              name={`students.${index}.houseId`}
                            />
                          </div>
                        )}
                        {school.isOneOffCodeOnRegisterEnabled && (
                          <div className="form-group">
                            <label>If the school provided you with a unique code please enter it here</label>
                            <Field name={`students.${index}.oneOffCode`} className={'form-control mb-3'} />
                            <ErrorMessage
                              component="div"
                              className="alert alert-danger mt-3"
                              name={`students.${index}.oneOffCode`}
                            />
                          </div>
                        )}

                        {values.students.length > 1 && (
                          <button
                            type="button"
                            className="mt-3 mb-3 btn btn-secondary"
                            onClick={() => {
                              const students = values.students.filter((student, i) => i !== index);
                              setFieldValue('students', students);
                            }}
                          >
                            Remove child
                          </button>
                        )}
                      </div>
                    );
                  })}

                  <div className="mt-3 mb-3">
                    <Button
                      text={'Add new child'}
                      onClick={() => {
                        const students = [
                          ...values.students,
                          {
                            firstName: '',
                            lastName: '',
                            gender: USER_GENDER.MALE,
                            birthday: undefined,
                            form: undefined,
                            house: undefined,
                            formId: '',
                            houseId: '',
                            oneOffCode: ''
                          }
                        ];
                        setFieldValue('students', students);
                      }}
                    />
                  </div>

                  <button type="submit" className="mt-3 mb-3 btn btn-primary">
                    Finish
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}
