import * as React from 'react';
import { FunctionComponent } from 'react';
import * as propz from 'propz';
import { PUBLIC_SITE_STATUS_OPTIONS } from '../../consts/tournament';
import { AppUser } from 'Src/views/App/App';
import { getTournamentPublicSiteLink, getTournamentTVPublicSiteLink } from '../../helpers/link/link';

interface Props {
  championship: any;
  user: AppUser;
}

const ROWS = [
  { title: "Publish championship on school union's website", field: 'isPublishOnTheWebsite' },
  { title: 'Highlight championship', field: 'isPromoteTournament' },
  { title: "Championship's public website access", field: 'publicSiteStatus' },
  { title: "Championship's public website access password", field: 'publicSitePassword' },
  { title: 'Allowed for following on the mobile app', field: 'allowedForFollowing' },
  { title: "Championship's public website domain name", field: 'domain' },
  { title: 'Highlight schools list on top', field: 'isSchoolsListOnTop' },
  { title: "Championship's public website external link", field: 'link' },
  { title: "Photos available on championship's website", field: 'isPhotosAvailable' },
  { title: "Photos promotion on championship's website", field: 'isPhotosAvailable' },
  { title: "Text of disclaimer on championship's website", field: 'disclaimer' },
  { title: 'Csv result availability', field: 'isCsvResultAvailable' },
  { title: 'Public Bigscreen Site Access', field: 'publicBigscreenSiteStatus' },
  { title: "Championship's public bigscreen website access", field: 'publicBigscreenSitePassword' },
  { title: 'Scrolling delay (in seconds)', field: 'publicBigscreenSiteAutoScrollingDelay' },
  { title: 'Championship public website link', field: 'publicWebsiteLink' },
  { title: 'Championship TV link', field: 'publicWebsiteTvLink' }
];

export const ChampionshipPublicSiteSettingsSummary: FunctionComponent<Props> = props => {
  const { championship } = props;

  const tournamentPublicSiteLink = getTournamentPublicSiteLink(championship),
    tournamentTVPublicSiteLink = getTournamentTVPublicSiteLink(championship);

  const rows = ROWS.map((row, index) => {
    let contentCell: object | string;
    const field = row.field;
    let isTournamentTVlink;
    let isExternalSite;

    switch (field) {
      case 'isPublishOnTheWebsite':
        contentCell = championship.isPublishOnTheWebsite ? 'yes' : 'no';
        break;
      case 'isPromoteTournament':
        contentCell = championship.isPromoteTournament ? 'yes' : 'no';
        break;
      case 'publicSiteStatus':
        contentCell = PUBLIC_SITE_STATUS_OPTIONS[championship.publicSite.status];
        break;
      case 'publicSitePassword':
        contentCell = propz.get(championship, ['publicSite', 'password'], '');
        break;
      case 'allowedForFollowing':
        contentCell = championship.allowedForFollowing ? 'yes' : 'no';
        break;
      case 'domain':
        contentCell = propz.get(championship, ['domain'], '');
        break;
      case 'isSchoolsListOnTop':
        contentCell = championship.isSchoolsListOnTop ? 'yes' : 'no';
        break;
      case 'link':
        contentCell = propz.get(championship, ['link'], '');
        break;
      case 'isPhotosAvailable':
        contentCell = championship.isPhotosAvailable ? 'yes' : 'no';
        break;
      case 'isPhotosPromotion':
        contentCell = championship.isPhotosPromotion ? 'yes' : 'no';
        break;
      case 'disclaimer':
        contentCell = propz.get(championship, ['disclaimer'], '');
        break;
      case 'isCsvResultAvailable':
        contentCell = championship.isCsvResultAvailable ? 'yes' : 'no';
        break;
      case 'publicBigscreenSiteStatus':
        contentCell = PUBLIC_SITE_STATUS_OPTIONS[championship.publicBigscreenSite.status];
        break;
      case 'publicBigscreenSitePassword':
        contentCell = propz.get(championship, ['publicBigscreenSite', 'password'], '');
        break;
      case 'publicBigscreenSiteAutoScrollingDelay':
        contentCell = propz.get(championship, ['publicBigscreenSite', 'autoScrollingDelay'], '');
        break;
      case 'publicWebsiteLink':
        contentCell =
          typeof championship.domain !== 'undefined' && championship.domain !== '' ? (
            <a href={tournamentPublicSiteLink} target="_blank">
              {tournamentPublicSiteLink}
            </a>
          ) : (
            'no'
          );
        break;
      case 'publicWebsiteTvLink':
        isTournamentTVlink = true;
        isExternalSite = true;
        contentCell =
          typeof championship.domain !== 'undefined' && championship.domain !== '' ? (
            <a href={tournamentTVPublicSiteLink} target="_blank">
              {tournamentTVPublicSiteLink}
            </a>
          ) : (
            'no'
          );
        break;
      default:
        contentCell = propz.get(championship, [row.field], '');
    }

    return (
      <tr key={`championship_PublicSiteSettings_summary_${index}`}>
        <td>{row.title}</td>
        <td>{contentCell}</td>
      </tr>
    );
  });

  return (
    <table className={'table table-sm mPre'}>
      <tbody>{rows}</tbody>
    </table>
  );
};

ChampionshipPublicSiteSettingsSummary.displayName = 'ChampionshipPublicSiteSettingsSummary';
