export type TYPE_OF_PLAYERS_TYPE = '1X1' | '2X2' | 'INDIVIDUAL' | 'TEAM';
export type POINTS_DISPLAY_TYPE = 'PLAIN' | 'TIME' | 'DISTANCE' | 'PRESENCE_ONLY';
export type TYPE_OF_POINTS_SIGN = 'PLUS' | 'MINUS' | 'BOTH';
export type SCORING_TYPE =
  | 'MORE_SCORES'
  | 'LESS_SCORES'
  | 'MORE_TIME'
  | 'LESS_TIME'
  | 'MORE_RESULT'
  | 'LESS_RESULT'
  | 'FIRST_TO_N_POINTS';
export type FIELD_POSITION_SHAPES = 'CIRCLE' | 'RECT' | 'POLY';

export const TYPE_OF_PLAYERS = {
  '1X1': '1X1',
  '2X2': '2X2',
  INDIVIDUAL: 'INDIVIDUAL',
  TEAM: 'TEAM'
};

export const POINTS_DISPLAY = {
  PLAIN: 'PLAIN',
  TIME: 'TIME',
  DISTANCE: 'DISTANCE',
  PRESENCE_ONLY: 'PRESENCE_ONLY'
};

export const SCORING: {
  MORE_SCORES: SCORING_TYPE;
  LESS_SCORES: SCORING_TYPE;
  MORE_TIME: SCORING_TYPE;
  LESS_TIME: SCORING_TYPE;
  MORE_RESULT: SCORING_TYPE;
  LESS_RESULT: SCORING_TYPE;
  FIRST_TO_N_POINTS: SCORING_TYPE;
} = {
  MORE_SCORES: 'MORE_SCORES',
  LESS_SCORES: 'LESS_SCORES',
  MORE_TIME: 'MORE_TIME',
  LESS_TIME: 'LESS_TIME',
  MORE_RESULT: 'MORE_RESULT',
  LESS_RESULT: 'LESS_RESULT',
  FIRST_TO_N_POINTS: 'FIRST_TO_N_POINTS'
};

export const SPORT_POINTS_SIGN: {
  PLUS: TYPE_OF_POINTS_SIGN;
  MINUS: TYPE_OF_POINTS_SIGN;
  BOTH: TYPE_OF_POINTS_SIGN;
} = {
  PLUS: 'PLUS',
  MINUS: 'MINUS',
  BOTH: 'BOTH'
};

export const CRICKET_WICKETS = 10;
