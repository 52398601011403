import * as React from 'react';
import * as propz from 'propz';
import * as BPromise from 'bluebird';
import { parse } from 'query-string';
import { AppUser } from 'Src/views/App/App';
import { Loader } from 'Src/components/Loader/Loader';
import { History, Location } from 'history';
import { Tournament } from 'Src/models/tournament';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { getSeason, getStartTime, getTournamentTagNamesPublicOnly } from 'Src/helpers/accessor/accessor';
import { getOrder, getSearchFilter, getSearchOrder, getServerFieldSectionWhere } from 'Src/helpers/table/table';
import {
  getSelectOptionForSeason,
  getSelectOptionForUpcomingTournamentsSeason,
  getSelectOptionForBooleanQuestion,
  getSelectOptionForTournamentTag
} from 'Src/helpers/table/select';
import {
  getAllUpcomingHighLevelTournaments,
  getUpcomingHighLevelTournaments,
  getUpcomingHighLevelTournamentsCount
} from 'Src/helpers/service/adminSU/tournamentsTable';
import { getIsPromoteTournament, getIsPublishOnTheWebsite } from 'Src/helpers/accessor/accessor';
import {
  ColumnDefinition,
  getFilters2,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { isIndividualTournament, isMiniTeamTournament, isTeamTournament } from 'Src/helpers/tournament/tournament';
import { Grid2 } from 'Src/components/Grid/Grid2';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/report';
import { uploadReport } from 'Src/helpers/service/admin/report';
import { HighLevelTournamentAreaStartListReportForm } from '../Reports/HighLevelTournamentAreaStartListReportForm/HighLevelTournamentAreaStartListReportForm';
import { HighLevelTournamentSchoolAdminsAreaSideReportForm } from '../Reports/HighLevelTournamentSchoolAdminsAreaSideReportForm/HighLevelTournamentSchoolAdminsAreaSideReportForm';
import { TournamentResultsIndividualParticipantSideReportForm } from '../Reports/TournamentResultsIndividualParticipantSideReportForm/TournamentResultsIndividualParticipantSideReportForm';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Name',
    field: 'name',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['name']
  },
  {
    text: 'Season',
    field: 'season',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getSeason
  },
  {
    text: 'Sport',
    field: 'sport',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: ['sport', 'name']
  },
  {
    text: 'Start',
    field: 'startTime',
    isSort: true,
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: getStartTime
  },
  {
    text: 'Tags',
    field: 'tournamentTagId',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getTournamentTagNamesPublicOnly
  }
];

interface State {
  isLoading: boolean;
  items: Tournament[];
  selectedItems: Tournament[];
  isSelectAllChecked: boolean;
  currentPage: number;
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  filters: any;
  isDataFiltered: boolean;
  total: number;
  basePath: string;
  errorMessage: string;
  isErrorModalOpen: boolean;
  currentTabIndex: number;
  isHighLevelTournamentAreaStartListReportModalOpen: boolean; //062
  isHighLevelTournamentSchoolAdminsAreaSideReportModalOpen: boolean; //064
  isTournamentResultsIndividualParticipantSideReportModalOpen: boolean; //03601
  tournamentTags: any[];
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export class HighLevelTournamentsUpcoming extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      items: [],
      selectedItems: [],
      isSelectAllChecked: false,
      currentPage: FIRST_PAGE,
      currentTabIndex: 0,
      total: 0,
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      filters: {},
      isDataFiltered: false,
      basePath: '',
      errorMessage: '',
      isErrorModalOpen: false,
      isHighLevelTournamentAreaStartListReportModalOpen: false,
      isHighLevelTournamentSchoolAdminsAreaSideReportModalOpen: false,
      isTournamentResultsIndividualParticipantSideReportModalOpen: false,
      tournamentTags: []
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setItems();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  setItems() {
    const { history, user, location } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;
    const lastSelectedFilters = propz.get(location, ['state', 'selectedFilters']);
    const isExistSelectedFilters = typeof lastSelectedFilters !== 'undefined';
    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = isExistSelectedFilters ? lastSelectedFilters : getFilters2(COLUMNS, search);
    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters, { isCorrectTime: true });

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const getItemsPromise = getUpcomingHighLevelTournaments(user, serverQueryFilter);
    const getItemsCountPromise = getUpcomingHighLevelTournamentsCount(user, where);

    return BPromise.all([getItemsCountPromise, getItemsPromise]).then(([countObj, items]) => {
      const tournamentId = propz.get(location, ['state', 'tournamentId']);
      if (typeof tournamentId !== 'undefined') {
        const tournament = items.find(item => item.id === tournamentId);
        this.setState({
          currentPage: page,
          sortDirection,
          sortColumnsName,
          isShowFilter: isShowFilter,
          isDataFiltered: isDataFiltered,
          filters: filters,
          items: items,
          total: countObj.count,
          basePath: history.location.pathname,
          isLoading: false,
          selectedItems: typeof tournament !== 'undefined' ? [tournament] : []
        });

        //clear history
        const state = { ...(history.location.state as any) };
        delete state.tournamentId;
        history.replace({ ...history.location, state });
      } else {
        this.setState({
          currentPage: page,
          sortDirection: sortDirection,
          sortColumnsName: sortColumnsName,
          isShowFilter: isShowFilter,
          isDataFiltered: isDataFiltered,
          filters: filters,
          items: items,
          total: countObj.count,
          basePath: history.location.pathname,
          isLoading: false
        });
      }

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const { history } = this.props;
    const { basePath } = this.state;

    history.push({
      pathname: basePath,
      search: search.join('&')
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated,
      currentTabIndex: 0
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: nextSelectedItems,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters, { isCorrectTime: true });

    getAllUpcomingHighLevelTournaments(user, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const nextSelectedItems = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: nextSelectedItems,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const { history } = this.props;
    const { basePath } = this.state;

    history.push({
      pathname: basePath,
      search: search.join('&')
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = { ...this.state.filters };
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    const { history } = this.props;
    const { basePath } = this.state;

    history.push({
      pathname: basePath,
      search: search.join('&')
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    const { history } = this.props;
    const { basePath } = this.state;

    history.push({
      pathname: basePath,
      search: ''
    });
  };

  onManageTeamsClick = () => {
    const { history } = this.props;
    const { selectedItems, basePath } = this.state;
    const tournament = selectedItems[0];
    const tournamentId = tournament.id;
    const search = history.location.search;

    history.push({
      pathname: `/highLevelTournaments/teams`,
      search: `tournamentId=${tournamentId}`,

      state: {
        search,
        prevPath: basePath
      }
    });
  };

  onManageEventsClick = () => {
    const { history } = this.props;
    const { selectedItems, basePath } = this.state;
    const tournament = selectedItems[0];
    const tournamentId = tournament.id;
    const search = history.location.search;

    history.push({
      pathname: `/highLevelTournaments/events`,
      search: `tournamentId=${tournamentId}`,
      state: {
        search,
        prevPath: basePath
      }
    });
  };

  renderGrid = () => {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isSelectAllChecked,
      currentPage,
      total
    } = this.state;

    const isIndividualTournamentSelected = selectedItems.some(item => isIndividualTournament(item));

    const actionItems = [
      {
        itemText: 'Manage Teams',
        onItemClick: this.onManageTeamsClick,
        isActive: this.isOneItemSelected()
      },
      {
        itemText: 'Manage Events',
        onItemClick: this.onManageEventsClick,
        isActive: this.isOneItemSelected()
      },
      {
        itemText: 'Reports',
        isActive: true,
        subItems: [
          isIndividualTournamentSelected &&
            selectedItems.length === 1 && {
              itemText: 'Tournament start list',
              onItemClick: this.openHighLevelTournamentAreaStartListReportForm,
              isActive: true
            },
          isIndividualTournamentSelected &&
            selectedItems.length === 1 && {
              itemText: 'Tournament results',
              onItemClick: this.openTournamentResultsIndividualParticipantSideReportForm,
              isActive: true
            },
          selectedItems.length === 1 && {
            itemText: 'Tournament schools contact details',
            onItemClick: this.openHighLevelTournamentSchoolAdminsAreaSideReportForm,
            isActive: true
          }
        ].filter(Boolean)
      }
    ].filter(Boolean);

    const filterOptions = {
      season: getSelectOptionForUpcomingTournamentsSeason(items),
      isPublishOnTheWebsite: getSelectOptionForBooleanQuestion(),
      isPromoteTournament: getSelectOptionForBooleanQuestion(),
      tournamentTag: getSelectOptionForTournamentTag(this.state.tournamentTags)
    };

    const todayMidnight = new Date(new Date().setHours(0, 0, 1));

    return (
      <Grid2
        dataItems={items}
        filters={filters}
        currentPage={currentPage}
        total={total}
        isSelectAllChecked={isSelectAllChecked}
        isDataFiltered={isDataFiltered}
        sortDirection={sortDirection}
        sortColumnsName={sortColumnsName}
        isShowFilter={isShowFilter}
        dataItemsSelected={selectedItems}
        columns={COLUMNS}
        actionItems={actionItems}
        options={filterOptions}
        onItemClick={this.onItemClick}
        onSortClick={this.onTableSortClick}
        onApplyFilterClick={this.onApplyFilterClick}
        onClearFilterClick={this.onClearFilterClick}
        onTableFilterChange={this.onTableFilterChange}
        onTableFilterClick={this.onTableFilterClick}
        setCurrentPageParams={this.setCurrentPageParams}
        onSelectAllClick={this.onSelectAllClick}
        onSelectAllOnPageClick={this.onSelectAllOnPageClick}
        onUnselectAllClick={this.onUnselectAllClick}
        filterMinDateInterval={todayMidnight}
      />
    );
  };

  isOneItemSelected(): boolean {
    const selectedItems = this.state.selectedItems;
    return selectedItems.length === 1;
  }

  onSubmitReportForm = (data, reportType) => {
    const { user } = this.props;
    const { activeSchoolId, activeSchool } = user;
    const { selectedItems } = this.state;
    const tournament = selectedItems[0];
    const tournamentId = propz.get(tournament, ['id'], '');

    const { isShowBlank, isHighlightNoAge, isPrintFullNames, isShowAllAdmins } = data;

    let modData;
    let formsUniq;

    switch (reportType) {
      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.HIGH_LEVEL_TOURNAMENT_AREA_START_LIST:
        this.setState({
          isHighLevelTournamentAreaStartListReportModalOpen: false,
          isLoading: true
        });

        modData = {
          reportParamsCSV: {
            unionId: activeSchoolId,
            tournamentId,
            showBlank: isShowBlank,
            highlightNoAge: isHighlightNoAge
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.TOURNAMENT_RESULTS_INDIVIDUAL_PARTICIPANT_SIDE:
        this.setState({
          isTournamentResultsIndividualParticipantSideReportModalOpen: false,
          isLoading: true
        });

        modData = {
          reportParamsCSV: {
            participantSchoolId: activeSchoolId,
            tournamentId,
            showBlank: isShowBlank,
            printFullNames: isPrintFullNames
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.HIGH_LEVEL_TOURNAMENT_SCHOOL_ADMINS_AREA_SIDE:
        this.setState({
          isHighLevelTournamentSchoolAdminsAreaSideReportModalOpen: false,
          isLoading: true
        });

        modData = {
          reportParamsCSV: {
            unionId: activeSchoolId,
            tournamentId,
            showAllAdmins: isShowAllAdmins
          }
        };
        break;
    }

    uploadReport(user, reportType, modData)
      .then(data => {
        if (typeof data.reportId !== 'undefined') {
          const reportType = data.reportType;

          this.setState({
            isLoading: false
          });

          const url = `${window.apiBase}/public/report/${data.reportId}/reportType/${reportType}`;
          window.open(url);
        } else {
          this.setState({
            isLoading: false,
            errorMessage: 'No read report',
            isErrorModalOpen: true
          });
        }
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        console.error(error);

        this.setState({
          isLoading: false,
          errorMessage: errorText,
          isErrorModalOpen: true
        });
      });
  };

  openHighLevelTournamentAreaStartListReportForm = () => {
    this.setState({
      isHighLevelTournamentAreaStartListReportModalOpen: true
    });
  };

  closeHighLevelTournamentAreaStartListReportForm = () => {
    this.setState({
      isHighLevelTournamentAreaStartListReportModalOpen: false
    });
  };

  openTournamentResultsIndividualParticipantSideReportForm = () => {
    this.setState({
      isTournamentResultsIndividualParticipantSideReportModalOpen: true
    });
  };

  closeTournamentResultsIndividualParticipantSideReportForm = () => {
    this.setState({
      isTournamentResultsIndividualParticipantSideReportModalOpen: false
    });
  };

  openHighLevelTournamentSchoolAdminsAreaSideReportForm = () => {
    this.setState({
      isHighLevelTournamentSchoolAdminsAreaSideReportModalOpen: true
    });
  };

  closeHighLevelTournamentSchoolAdminsAreaSideReportForm = () => {
    this.setState({
      isHighLevelTournamentSchoolAdminsAreaSideReportModalOpen: false
    });
  };

  renderHighLevelTournamentAreaStartListReportFormModal() {
    const { isHighLevelTournamentAreaStartListReportModalOpen, selectedItems } = this.state;
    const tournament = selectedItems[0];
    return (
      <SimpleModal isOpen={isHighLevelTournamentAreaStartListReportModalOpen}>
        <HighLevelTournamentAreaStartListReportForm
          onCloseClick={this.closeHighLevelTournamentAreaStartListReportForm}
          onSubmit={this.onSubmitReportForm}
          tournament={tournament}
        />
      </SimpleModal>
    );
  }

  renderTournamentResultsIndividualParticipantSideReportFormModal() {
    const { isTournamentResultsIndividualParticipantSideReportModalOpen, selectedItems } = this.state;
    const tournament = selectedItems[0];
    return (
      <SimpleModal isOpen={isTournamentResultsIndividualParticipantSideReportModalOpen}>
        <TournamentResultsIndividualParticipantSideReportForm
          onCloseClick={this.closeTournamentResultsIndividualParticipantSideReportForm}
          onSubmit={this.onSubmitReportForm}
          tournament={tournament}
        />
      </SimpleModal>
    );
  }

  renderHighLevelTournamentSchoolAdminsAreaSideReportFormModal() {
    const { isHighLevelTournamentSchoolAdminsAreaSideReportModalOpen, selectedItems } = this.state;
    const tournament = selectedItems[0];
    return (
      <SimpleModal isOpen={isHighLevelTournamentSchoolAdminsAreaSideReportModalOpen}>
        <HighLevelTournamentSchoolAdminsAreaSideReportForm
          onCloseClick={this.closeHighLevelTournamentSchoolAdminsAreaSideReportForm}
          onSubmit={this.onSubmitReportForm}
          tournament={tournament}
        />
      </SimpleModal>
    );
  }

  render() {
    const {
      isLoading,
      isHighLevelTournamentAreaStartListReportModalOpen,
      isTournamentResultsIndividualParticipantSideReportModalOpen,
      isHighLevelTournamentSchoolAdminsAreaSideReportModalOpen
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes =
      isHighLevelTournamentAreaStartListReportModalOpen ||
      isTournamentResultsIndividualParticipantSideReportModalOpen ||
      isHighLevelTournamentSchoolAdminsAreaSideReportModalOpen
        ? 'mt-3 modal-open'
        : 'mt-3';

    return (
      <div className={classes}>
        {this.renderHighLevelTournamentAreaStartListReportFormModal()}
        {this.renderTournamentResultsIndividualParticipantSideReportFormModal()}
        {this.renderHighLevelTournamentSchoolAdminsAreaSideReportFormModal()}
        <div className="row">
          <div className="col-md-12">{this.renderGrid()}</div>
        </div>
      </div>
    );
  }
}
