import * as React from 'react';
import * as propz from 'propz';
import { History } from 'history';
import { AppUser } from 'Src/views/App/App';
import './Avatar.scss';

interface Props {
  user: AppUser;
  history: History;
  onClick: (event) => void;
}

export function Avatar(props: Props) {
  const { onClick } = props;

  const imageSrc = propz.get(props, ['user', 'profile', 'avatar']);
  const isImageSrc = typeof imageSrc !== 'undefined';

  const userAvatar = isImageSrc ? `url(${imageSrc}?sizing=minvalue&value=50)` : 'url(/dist/images/default-avatar.png)';

  return <div className="rounded-circle bAvatar" style={{ backgroundImage: userAvatar }} onClick={onClick} />;
}
