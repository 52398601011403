import * as React from 'react';
import { Component } from 'react';
import { parse } from 'query-string';
import * as BPromise from 'bluebird';
import * as propz from 'propz';
import {
  ColumnDefinition,
  getFilters2,
  getOrder,
  getSearchFilter,
  getSearchOrder,
  getServerFieldSectionWhere,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';

import { Loader } from 'Src/components/Loader/Loader';
import { AppUser } from 'Src/views/App/App';
import './SchoolLeagues.css';
import { GenericSummary } from 'Src/components/GenericSummary/GenericSummary';
import { Button } from 'Src/components/Button/Button';
import { History, Location } from 'history';
import { Grid2 } from 'Src/components/Grid/Grid2';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { ResizablePanel } from 'Src/components/ResizablePanel/ResizablePanel';
import { LeagueNews, LeaguePhoto, SchoolLeague } from 'Src/models/schoolLeague';
import {
  createSchoolLeagues,
  deleteSchoolLeagues,
  getAllSchoolLeagues,
  getSchoolLeagueEvents,
  getSchoolLeagues,
  getSchoolLeaguesCount,
  updateSchoolLeagues
} from 'Src/helpers/service/admin/schoolLeagues';
import { SchoolLeagueForm } from './SchoolLeagueForm/SchoolLeagueForm';
import { getAggregationType } from 'Src/helpers/accessor/accessor';
import { getAllForms } from 'Src/helpers/service/admin/forms';
import { getSelectOptionForAggregationType } from 'Src/helpers/table/select';
import { LEAGUE_AGGREGATION_TYPE, LEAGUE_TABS } from 'Src/consts/league';
import { SchoolLeagueSummary } from './SchoolLeagueSummary/SchoolLeagueSummary';
import { TABS } from 'Src/consts/common';
import { SchoolEvent } from 'Src/models/event';
import { SchoolLeagueEventsSummary } from './SchoolLeagueSummary/SchoolLeagueEventsSummary';
import { SchoolLeagueNewsSummary } from './SchoolLeagueNewsSummary/SchoolLeagueNewsSummary';
import { getAllSchoolLeagueNews } from 'Src/helpers/service/admin/schoolLeagueNews';
import { isLeagueHouse, isLeagueIndividual, isLeagueTeam } from '../../../../../helpers/league/league';
import { EVENT_TYPES } from '../../../../../consts/event';
import * as Moment from 'moment';
import { AggregatedTargetCompletionReportForm } from './Reports/AggregatedTargetCompletionReportForm/AggregatedTargetCompletionReportForm';
import { AggregatedTargetCompletionForThePeriodReportForm } from './Reports/AggregatedTargetCompletionForThePeriodReportForm/AggregatedTargetCompletionForThePeriodReportForm';
import { TargetParticipationStatisticsReportForm } from './Reports/TargetParticipationStatisticsReportForm/TargetParticipationStatisticsReportForm';
import { TargetCompletionStatisticsReportForm } from './Reports/TargetCompletionStatisticsReportForm/TargetCompletionStatisticsReportForm';
import { SchoolForm } from 'Src/models/form';
import { AGE_GROUPS_SORTED } from '../../../../../consts/school';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/report';
import * as Lazy from 'lazy.js';
import { sortAges } from 'Src/helpers/event/event';
import { uploadReport } from 'Src/helpers/service/admin/report';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Name',
    field: 'name',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['name']
  },
  {
    text: 'Aggregation',
    field: 'aggregationType',
    isSort: false,
    type: FILTER_TYPE.SELECT,
    accessor: getAggregationType
  }
];

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

interface State {
  items: SchoolLeague[];
  currentPage: number;
  selectedItems: SchoolLeague[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;
  currentTabIndex: number;
  leagueId: string;
  isErrorModalOpen: boolean;
  errorMessage: string;
  isEditLeaguePopupOpened: boolean;
  isDeleteLeagueModalOpen: boolean;

  leaguePhotos: LeaguePhoto[];
  leagueEvents: SchoolEvent[];
  leagueNews: LeagueNews[];

  isAggregatedTargetCompletionReportFormModalOpen: boolean;
  isAggregatedTargetCompletionForThePeriodReportFormModalOpen: boolean;
  isTargetParticipationStatisticsReportFormModalOpen: boolean;
  isTargetCompletionStatisticsReportFormModalOpen: boolean;

  forms: SchoolForm[];
}

export class SchoolLeagues extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',
      currentTabIndex: 0,
      leagueId: propz.get(this.props.location, ['state', 'leagueId']),
      isErrorModalOpen: false,
      errorMessage: '',
      isEditLeaguePopupOpened: false,
      isDeleteLeagueModalOpen: false,
      leaguePhotos: [],
      leagueEvents: [],
      leagueNews: [],
      isAggregatedTargetCompletionReportFormModalOpen: false,
      isAggregatedTargetCompletionForThePeriodReportFormModalOpen: false,
      isTargetParticipationStatisticsReportFormModalOpen: false,
      isTargetCompletionStatisticsReportFormModalOpen: false,
      forms: []
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setAdditionalItems().then(res => {
      this.setItems();
    });
  }

  setAdditionalItems() {
    const { user } = this.props;

    return getAllForms(user).then(forms => {
      this.setState({
        forms
      });
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });

      this.setItems();
    }
  }

  setItems() {
    const { history, user } = this.props;
    const { leagueId } = this.state;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const getItemsPromise = getSchoolLeagues(user, serverQueryFilter);
    const getItemsCountPromise = getSchoolLeaguesCount(user, where);

    return BPromise.all([getItemsCountPromise, getItemsPromise]).then(([countObj, items]) => {
      if (typeof leagueId !== 'undefined') {
        const league = items.find(item => item.id === leagueId);
        this.setState({
          currentPage: page,
          sortDirection: sortDirection as TABLE_SORT_DIRECTION,
          sortColumnsName: sortColumnsName,
          isShowFilter: isShowFilter,
          isDataFiltered: isDataFiltered,
          filters: filters,
          items: items,
          total: countObj.count,
          basePath: history.location.pathname,
          isLoading: false,
          selectedItems: typeof league !== 'undefined' ? [league] : [],
          leagueId: undefined
        });
        //clear history
        const state = { ...(history.location.state as any) };
        delete state.leagueId;
        history.replace({ ...history.location, state });
      } else {
        this.setState({
          currentPage: page,
          sortDirection: sortDirection,
          sortColumnsName: sortColumnsName,
          isShowFilter: isShowFilter,
          isDataFiltered: isDataFiltered,
          filters: filters,
          items: items,
          total: countObj.count,
          basePath: history.location.pathname,
          isLoading: false
        });
      }
      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated,
      currentTabIndex: 0
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: nextSelectedItems,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    getAllSchoolLeagues(user, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const nextSelectedItems = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: nextSelectedItems,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (filterField === 'ageGroup') {
      nextFilters.ageGroup = [];
    }

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: ''
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  onSaveButtonClick = (data): void => {
    const { user } = this.props;
    const { selectedItems } = this.state;

    this.setState({
      isLoading: true
    });

    const isNotIndividualLeague = data.aggregationType !== LEAGUE_AGGREGATION_TYPE.INDIVIDUAL;
    if (isNotIndividualLeague) {
      data.isDisplayHousePoints = false;
    }

    switch (selectedItems.length) {
      case 0: {
        createSchoolLeagues(user, data)
          .then(league => {
            this.setState({
              isEditLeaguePopupOpened: false,
              selectedItems: [league]
            });
            this.setItems();
          })
          .catch(error => {
            const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
            console.error(error);
            this.setState({
              isLoading: false,
              errorMessage: errorText,
              isErrorModalOpen: true
            });
          });
        break;
      }
      case 1:
        const leagueId = selectedItems[0].id;
        updateSchoolLeagues(user, leagueId, data)
          .then(league => {
            this.setState({
              isEditLeaguePopupOpened: false,
              selectedItems: [league]
            });
            this.setItems();
          })
          .catch(error => {
            const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
            console.error(error);
            this.setState({
              isLoading: false,
              errorMessage: errorText,
              isErrorModalOpen: true
            });
          });
        break;
    }
  };

  onCancelButtonClick = (): void => {
    this.setState({
      isEditLeaguePopupOpened: false
    });
  };

  onTabClick = (event, index: number) => {
    event.preventDefault();
    const { user } = this.props;
    const { selectedItems } = this.state;
    const league = selectedItems[0];
    const tabs = LEAGUE_TABS;
    const currentTab = tabs[index];

    switch (currentTab) {
      case TABS.EVENTS:
        this.setState({
          isLoading: true
        });
        getSchoolLeagueEvents(user, league.id).then(events => {
          this.setState({
            leagueEvents: events,
            currentTabIndex: index,
            isLoading: false
          });
        });
        break;
      case TABS.NEWS:
        this.setState({
          isLoading: true
        });
        getAllSchoolLeagueNews(user, league.id).then(news => {
          this.setState({
            leagueNews: news,
            currentTabIndex: index,
            isLoading: false
          });
        });
        break;
      default:
        this.setState({
          currentTabIndex: index
        });
    }
  };

  onEditLeagueClick = event => {
    event.preventDefault();

    this.setState({
      isEditLeaguePopupOpened: true
    });
  };

  onCreateEventClick = aggregationType => {
    const { history } = this.props;
    const { selectedItems } = this.state;

    const currentSelectedItem = selectedItems[0];
    const { id: leagueId } = currentSelectedItem;

    history.push({
      pathname: '/events/manager',
      state: { aggregationType, leagueId }
    });
  };

  onEditLeagueEventsClick = event => {
    event.preventDefault();
    const { selectedItems, basePath } = this.state;
    const leagueId = selectedItems[0].id;

    this.props.history.push({
      pathname: `${basePath}/events`,
      search: `league=${leagueId}`
    });
  };

  onEditLeagueNewsClick = event => {
    event.preventDefault();
    const { selectedItems, basePath } = this.state;
    const { history } = this.props;
    const leagueId = selectedItems[0].id;

    history.push({
      pathname: `${basePath}/news`,
      search: `league=${leagueId}`
    });
  };

  getSummaryTabs(): React.ReactNode {
    const { selectedItems, currentTabIndex, leagueEvents, leagueNews } = this.state;
    const { user } = this.props;

    const currentTab = LEAGUE_TABS[currentTabIndex];

    switch (currentTab) {
      case TABS.SUMMARY:
        return <SchoolLeagueSummary league={selectedItems[0]} />;
      case TABS.EVENTS:
        return <SchoolLeagueEventsSummary leagueEvents={leagueEvents} user={user} />;
      case TABS.NEWS:
        return <SchoolLeagueNewsSummary news={leagueNews} />;
    }
  }

  renderEditLeagueModal() {
    const { isEditLeaguePopupOpened, selectedItems } = this.state;
    const { user } = this.props;

    if (selectedItems.length > 1) {
      return null;
    }

    return (
      <SimpleModal isOpen={isEditLeaguePopupOpened}>
        <SchoolLeagueForm
          league={selectedItems[0]}
          onSubmit={this.onSaveButtonClick}
          onCancel={this.onCancelButtonClick}
          user={user}
        />
      </SimpleModal>
    );
  }

  onCreateSchoolLeagueClick = () => {
    this.setState({
      isEditLeaguePopupOpened: true
    });
  };

  removeSchoolLeagueModalOpen = (): void => {
    this.setState({
      isDeleteLeagueModalOpen: true
    });
  };

  removeSchoolLeagueModalClose = (): void => {
    this.setState({
      isDeleteLeagueModalOpen: false
    });
  };

  onRemoveSchoolLeagueClick = () => {
    const { selectedItems } = this.state;
    const { user } = this.props;
    if (typeof selectedItems === 'undefined' || selectedItems.length === 0) {
      return;
    }
    this.setState({ isLoading: true });
    const promises = selectedItems.map(league => {
      return deleteSchoolLeagues(user, league.id);
    });
    BPromise.all(promises).then(() => {
      this.setState({
        selectedItems: [],
        isDeleteLeagueModalOpen: false
      });
      this.setItems();
    });
  };

  onAddEventsButtonClick = (): void => {
    const { selectedItems } = this.state;
    const { history, location } = this.props;
    const { search: locationSearch } = location;
    const [league] = selectedItems;
    const leagueId = league.id;

    let search = [`league=${leagueId}`];

    if (isLeagueIndividual(league) || isLeagueTeam(league)) {
      search.push(`eventType=${EVENT_TYPES.INTERNAL_TEAMS}`);
    }

    if (isLeagueHouse(league)) {
      search.push(`eventType=${EVENT_TYPES.INTERNAL_HOUSES}`);
    }

    history.push({
      pathname: `/leagues/eligibleEvents`,
      search: search.join('&'),
      state: { search: locationSearch }
    });
  };

  renderDeleteSchoolLeagueModal() {
    const { selectedItems, isDeleteLeagueModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isDeleteLeagueModalOpen}
        title={'Remove league'}
        body={`Are you sure you want to remove ${
          selectedItems.length === 1 ? `league ${selectedItems[0].name}` : `${selectedItems.length} leagues`
        }?`}
        buttonText={'Delete'}
        onButtonClick={this.onRemoveSchoolLeagueClick}
        onCloseClick={this.removeSchoolLeagueModalClose}
      />
    );
  }

  onSubmitReportForm = (data, reportType) => {
    const { user } = this.props;
    const { activeSchoolId, activeSchool } = user;
    const { selectedItems, forms } = this.state;
    const { ageGroupsNaming } = activeSchool;
    const ageGroup = propz.get(AGE_GROUPS_SORTED, [ageGroupsNaming]);

    const {
      dateTo,
      dateFrom,
      isShowCompleted,
      isShowUncompleted,
      isShowBlank,
      genders,
      ages: ageItems,
      isPrintEligible,
      isPrintParticipating,
      isPrintPercent,
      isPrintPP,
      isPrintSEN,
      isPrintFSM
    } = data;

    let modData;
    let formsUniq;
    let ages: number[];

    switch (reportType) {
      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.AGGREGATED_TARGET_COMPLETION:
        this.setState({
          isAggregatedTargetCompletionReportFormModalOpen: false,
          isLoading: true
        });

        formsUniq = Lazy(forms)
          .uniq('age')
          .sort((form1, form2) => {
            const ageIndex1 = ageGroup.indexOf(form1.ageGroup);
            const ageIndex2 = ageGroup.indexOf(form2.ageGroup);

            return ageIndex1 - ageIndex2;
          })
          .toArray();

        ages = formsUniq.filter((form, index) => ageItems[index].isChecked).map(form => form.age);

        modData = {
          reportParamsCSV: {
            schoolId: activeSchoolId,
            leagueId: selectedItems.map(item => item.id),
            showCompleted: isShowCompleted,
            showUncompleted: isShowUncompleted,
            showBlank: isShowBlank,
            genders: genders.filter(gender => gender.isChecked === true).map(gender => gender.name.toUpperCase()),
            ageGroups: ages
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.AGGREGATED_TARGET_COMPLETION_FOR_THE_PERIOD:
        this.setState({
          isAggregatedTargetCompletionForThePeriodReportFormModalOpen: false,
          isLoading: true
        });

        dateFrom.setHours(0, 0, 0, 0);
        dateTo.setHours(23, 59, 59, 0);

        formsUniq = Lazy(forms)
          .uniq('age')
          .sort((form1, form2) => {
            const ageIndex1 = ageGroup.indexOf(form1.ageGroup);
            const ageIndex2 = ageGroup.indexOf(form2.ageGroup);

            return ageIndex1 - ageIndex2;
          })
          .toArray();

        ages = formsUniq.filter((form, index) => ageItems[index].isChecked).map(form => form.age);

        modData = {
          reportParamsCSV: {
            schoolId: activeSchoolId,
            dateTo: Moment(dateTo).format('YYYY-MM-DD HH:mm:ss'),
            dateFrom: Moment(dateFrom).format('YYYY-MM-DD HH:mm:ss'),
            showCompleted: isShowCompleted,
            showUncompleted: isShowUncompleted,
            showBlank: isShowBlank,
            genders: genders.filter(gender => gender.isChecked === true).map(gender => gender.name.toUpperCase()),
            ageGroups: ages
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.TARGET_PARTICIPATION_STATISTICS:
        this.setState({
          isTargetParticipationStatisticsReportFormModalOpen: false,
          isLoading: true
        });

        modData = {
          reportParamsCSV: {
            schoolId: activeSchoolId,
            leagueId: selectedItems.map(item => item.id),
            printEligible: isPrintEligible,
            printPercent: isPrintPercent,
            printPP: isPrintPP,
            printSEN: isPrintSEN,
            printFSM: isPrintFSM
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.TARGET_COMPLETION_STATISTICS:
        this.setState({
          isTargetCompletionStatisticsReportFormModalOpen: false,
          isLoading: true
        });

        modData = {
          reportParamsCSV: {
            schoolId: activeSchoolId,
            leagueId: selectedItems.map(item => item.id),
            printParticipating: isPrintParticipating,
            printPercent: isPrintPercent,
            printPP: isPrintPP,
            printSEN: isPrintSEN,
            printFSM: isPrintFSM
          }
        };
        break;
    }

    uploadReport(user, reportType, modData)
      .then(data => {
        if (typeof data.reportId !== 'undefined') {
          const reportType = data.reportType;
          this.setState({
            isLoading: false
          });
          const url = `${window.apiBase}/public/report/${data.reportId}/reportType/${reportType}`;
          window.open(url);
        } else {
          this.setState({
            isLoading: false,
            errorMessage: 'No read report',
            isErrorModalOpen: true
          });
        }
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        console.error(error);

        this.setState({
          isLoading: false,
          errorMessage: errorText,
          isErrorModalOpen: true
        });
      });
  };

  renderSendingError(): React.ReactNode {
    const { errorMessage, isErrorModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isErrorModalOpen}
        title={'Error'}
        body={errorMessage}
        buttonCancelText={'Ok'}
        onCloseClick={this.onCloseErrorClick}
      />
    );
  }

  onCloseErrorClick = () => {
    this.setState({
      isErrorModalOpen: false,
      errorMessage: ''
    });
  };

  renderAggregatedTargetCompletionReportFormModal() {
    const { isAggregatedTargetCompletionReportFormModalOpen, forms } = this.state;
    const { user } = this.props;
    const { activeSchool } = user;
    return (
      <SimpleModal isOpen={isAggregatedTargetCompletionReportFormModalOpen}>
        <AggregatedTargetCompletionReportForm
          onCloseClick={this.closeAggregatedTargetCompletionReportForm}
          onSubmit={this.onSubmitReportForm}
          forms={forms}
          school={activeSchool}
        />
      </SimpleModal>
    );
  }

  renderAggregatedTargetCompletionForThePeriodReportFormModal() {
    const { isAggregatedTargetCompletionForThePeriodReportFormModalOpen, forms } = this.state;
    const { user } = this.props;
    const { activeSchool } = user;
    return (
      <SimpleModal isOpen={isAggregatedTargetCompletionForThePeriodReportFormModalOpen}>
        <AggregatedTargetCompletionForThePeriodReportForm
          onCloseClick={this.closeAggregatedTargetCompletionForThePeriodReportForm}
          onSubmit={this.onSubmitReportForm}
          forms={forms}
          school={activeSchool}
        />
      </SimpleModal>
    );
  }

  renderTargetParticipationStatisticsReportFormModal() {
    const { isTargetParticipationStatisticsReportFormModalOpen, forms } = this.state;
    const { user } = this.props;
    const { activeSchool } = user;
    return (
      <SimpleModal isOpen={isTargetParticipationStatisticsReportFormModalOpen}>
        <TargetParticipationStatisticsReportForm
          onCloseClick={this.closeTargetParticipationStatisticsReportForm}
          onSubmit={this.onSubmitReportForm}
          forms={forms}
          school={activeSchool}
        />
      </SimpleModal>
    );
  }

  renderTargetCompletionStatisticsReportFormModal() {
    const { isTargetCompletionStatisticsReportFormModalOpen, forms } = this.state;
    const { user } = this.props;
    const { activeSchool } = user;
    return (
      <SimpleModal isOpen={isTargetCompletionStatisticsReportFormModalOpen}>
        <TargetCompletionStatisticsReportForm
          onCloseClick={this.closeTargetCompletionStatisticsReportForm}
          onSubmit={this.onSubmitReportForm}
          forms={forms}
          school={activeSchool}
        />
      </SimpleModal>
    );
  }

  renderSummary() {
    const { selectedItems, currentTabIndex } = this.state;
    const isOneItemSelected = selectedItems.length === 1;

    if (!isOneItemSelected) {
      return <div className="font-weight-bold">Select a league above</div>;
    }

    const currentSelectedItem = selectedItems[0];
    const { aggregationType } = currentSelectedItem;

    const summaryTabs = this.getSummaryTabs();
    const currentTab = LEAGUE_TABS[currentTabIndex];

    const isShowEditButton = currentTab === TABS.SUMMARY;
    const isShowEditEventsButton = currentTab === TABS.EVENTS;
    const isShowEditNewsButton = currentTab === TABS.NEWS;

    return (
      <GenericSummary onTabClick={this.onTabClick} tabs={LEAGUE_TABS} currentTabIndex={currentTabIndex}>
        {isShowEditButton && (
          <Button onClick={this.onEditLeagueClick} text={'Edit details'} customClass={'mt-3 mb-3'} />
        )}
        {isShowEditButton && (
          <Button
            onClick={() => this.onCreateEventClick(aggregationType)}
            text={'Create event'}
            customClass={'my-3 ml-3'}
          />
        )}
        {isShowEditEventsButton && (
          <Button onClick={this.onEditLeagueEventsClick} text={'Edit events'} customClass={'mt-3 mb-3'} />
        )}
        {isShowEditNewsButton && (
          <Button onClick={this.onEditLeagueNewsClick} text={'Edit news'} customClass={'mt-3 mb-3'} />
        )}
        {summaryTabs}
      </GenericSummary>
    );
  }

  openAggregatedTargetCompletionReportForm = () => {
    this.setState({
      isAggregatedTargetCompletionReportFormModalOpen: true
    });
  };

  closeAggregatedTargetCompletionReportForm = () => {
    this.setState({
      isAggregatedTargetCompletionReportFormModalOpen: false
    });
  };

  openAggregatedTargetCompletionForThePeriodReportForm = () => {
    this.setState({
      isAggregatedTargetCompletionForThePeriodReportFormModalOpen: true
    });
  };

  closeAggregatedTargetCompletionForThePeriodReportForm = () => {
    this.setState({
      isAggregatedTargetCompletionForThePeriodReportFormModalOpen: false
    });
  };

  openTargetParticipationStatisticsReportForm = () => {
    this.setState({
      isTargetParticipationStatisticsReportFormModalOpen: true
    });
  };

  closeTargetParticipationStatisticsReportForm = () => {
    this.setState({
      isTargetParticipationStatisticsReportFormModalOpen: false
    });
  };

  openTargetCompletionStatisticsReportForm = () => {
    this.setState({
      isTargetCompletionStatisticsReportFormModalOpen: true
    });
  };

  closeTargetCompletionStatisticsReportForm = () => {
    this.setState({
      isTargetCompletionStatisticsReportFormModalOpen: false
    });
  };

  render() {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isLoading,
      isSelectAllChecked,
      currentPage,
      total,
      isEditLeaguePopupOpened,
      isDeleteLeagueModalOpen,
      isAggregatedTargetCompletionReportFormModalOpen,
      isAggregatedTargetCompletionForThePeriodReportFormModalOpen,
      isTargetParticipationStatisticsReportFormModalOpen,
      isTargetCompletionStatisticsReportFormModalOpen,
      isErrorModalOpen
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes =
      isErrorModalOpen ||
      isEditLeaguePopupOpened ||
      isDeleteLeagueModalOpen ||
      isAggregatedTargetCompletionReportFormModalOpen ||
      isAggregatedTargetCompletionForThePeriodReportFormModalOpen ||
      isTargetParticipationStatisticsReportFormModalOpen ||
      isTargetCompletionStatisticsReportFormModalOpen
        ? 'mt-3 modal-open'
        : 'mt-3';

    const actionItems = [
      {
        itemText: 'Create league',
        onItemClick: this.onCreateSchoolLeagueClick,
        isActive: selectedItems.length === 0
      },
      {
        itemText: 'Add events',
        onItemClick: this.onAddEventsButtonClick,
        isActive: selectedItems.length === 1
      },
      {
        itemText: 'Delete league',
        onItemClick: this.removeSchoolLeagueModalOpen,
        isActive: selectedItems.length > 0
      },
      {
        itemText: 'Reports',
        isActive: true,
        subItems: [
          {
            itemText: 'Target Completion',
            onItemClick: this.openAggregatedTargetCompletionReportForm,
            isActive: selectedItems.length > 0
          },
          {
            itemText: 'Aggregated Target Completion for the Period',
            onItemClick: this.openAggregatedTargetCompletionForThePeriodReportForm,
            isActive: selectedItems.length == 0
          },
          {
            itemText: 'Target Participation Statistics',
            onItemClick: this.openTargetParticipationStatisticsReportForm,
            isActive: selectedItems.length > 0
          },
          {
            itemText: 'Target Completion Statistics',
            onItemClick: this.openTargetCompletionStatisticsReportForm,
            isActive: selectedItems.length > 0
          }
        ]
      }
    ];

    const filterOptions = {
      aggregationType: getSelectOptionForAggregationType()
    };

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderDeleteSchoolLeagueModal()}
        {this.renderEditLeagueModal()}
        {this.renderSendingError()}
        {this.renderAggregatedTargetCompletionReportFormModal()}
        {this.renderAggregatedTargetCompletionForThePeriodReportFormModal()}
        {this.renderTargetParticipationStatisticsReportFormModal()}
        {this.renderTargetCompletionStatisticsReportFormModal()}
        <div className="row">
          <div className="col-md-12">
            <ResizablePanel>
              <Grid2
                dataItems={items}
                filters={filters}
                currentPage={currentPage}
                total={total}
                isSelectAllChecked={isSelectAllChecked}
                isDataFiltered={isDataFiltered}
                sortDirection={sortDirection}
                sortColumnsName={sortColumnsName}
                isShowFilter={isShowFilter}
                dataItemsSelected={selectedItems}
                columns={COLUMNS}
                actionItems={actionItems}
                options={filterOptions}
                onItemClick={this.onItemClick}
                onSortClick={this.onTableSortClick}
                onApplyFilterClick={this.onApplyFilterClick}
                onClearFilterClick={this.onClearFilterClick}
                onTableFilterChange={this.onTableFilterChange}
                onTableFilterClick={this.onTableFilterClick}
                setCurrentPageParams={this.setCurrentPageParams}
                onSelectAllClick={this.onSelectAllClick}
                onSelectAllOnPageClick={this.onSelectAllOnPageClick}
                onUnselectAllClick={this.onUnselectAllClick}
              />
              {this.renderSummary()}
            </ResizablePanel>
          </div>
        </div>
      </div>
    );
  }
}
