import * as React from 'react';
import { FunctionComponent } from 'react';
import * as classNames from 'classnames';
import './LabelWithQuestionIcon.scss';

interface Props {
  hintText: string;
  labelText: string;
  customClass?: string;
  customLabelClass?: string;
  labelHtmlFor?: string;
}

export function LabelWithQuestionIcon(props: Props) {
  const { hintText, labelText, labelHtmlFor, customClass, customLabelClass } = props;
  const questionIconClass = classNames('d-flex justify-content-start', customClass);

  return (
    <div className={questionIconClass}>
      <label htmlFor={labelHtmlFor} className={customLabelClass}>
        {labelText}
      </label>
      <div className="ml-3">
        <img style={{ width: '20px' }} src="/dist/images/questionMark.png" title={hintText} alt={'question icon'} />
      </div>
    </div>
  );
}
