import * as React from 'react';
import { Component } from 'react';
import { AppUser } from 'Src/views/App/App';
import { ModalDialog } from '../ModalDialog/ModalDialog';
import { Championship } from '../../models/championship';
import { Tournament } from '../../models/tournament';

interface Props {
  user: AppUser;
  onSubmitChampionshipClick: (data, championshipId) => void;
  onCancelClick: () => void;
  championships: Championship[];
  tournaments: Tournament[];
  isOpen: boolean;
}

interface State {
  selectedChampionshipId: string;
}

export class TournamentAddToChampionshipModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const selectedId =
      typeof props.championships !== 'undefined' && props.championships.length > 0 ? props.championships[0].id : '';
    this.state = { selectedChampionshipId: selectedId };
  }

  onChampionshipSelected = (event): void => {
    this.setState({ selectedChampionshipId: event.target.value });
  };

  onChampionshipSubmit = (): void => {
    const { tournaments } = this.props;
    const championship = this.props.championships.find(
      championship => championship.id === this.state.selectedChampionshipId
    );
    const tournamentIds = tournaments.map(tournament => tournament.id);

    const data = {
      add: tournamentIds
    };

    this.props.onSubmitChampionshipClick(data, championship.id);
  };

  render() {
    const { championships } = this.props;

    return (
      <ModalDialog
        title="Add tournament to championship"
        isOpen={this.props.isOpen}
        positiveButtonTitle="Add"
        negativeButtonTitle="Cancel"
        onPositiveButtonClick={this.onChampionshipSubmit}
        onNegativeButtonClick={this.props.onCancelClick}
      >
        <div className="form-group">
          <label>Select championship</label>
          <select
            value={this.state.selectedChampionshipId}
            className="form-control"
            onChange={this.onChampionshipSelected}
          >
            {championships.map(championship => (
              <option key={championship.id} value={championship.id}>
                {championship.name}
              </option>
            ))}
          </select>
        </div>
      </ModalDialog>
    );
  }
}
