import * as React from 'react';
import { FunctionComponent } from 'react';
import { SORT } from '../../consts/table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
  tableSortDirection: string
  tableSortColumnsName: string
  tableColumnName: string
}

export const GridSortIcon: FunctionComponent<Props> = (props) => {
  switch(true){
    case props.tableSortDirection === SORT.ASC && props.tableSortColumnsName === props.tableColumnName:
      return <FontAwesomeIcon icon = "sort-up" />
    case props.tableSortDirection === SORT.DESC && props.tableSortColumnsName === props.tableColumnName:
      return <FontAwesomeIcon icon = "sort-down" />
    default:
      return <FontAwesomeIcon icon = "sort" />
  }
};

GridSortIcon.displayName = 'GridSortIcon';