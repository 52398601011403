import * as React from 'react';
import { FunctionComponent } from 'react';
import * as propz from 'propz';

interface Props {
  school: any;
}

const ROWS = [
  { title: 'Name', field: 'name' },
  { title: 'Postcode', field: 'postcode'},
  { title: 'Domain', field: 'domain' },
];

export const SchoolSummary: FunctionComponent<Props> = props => {
  const rows = ROWS.map((row, index) => {
    let contentCell: string;
    const field = row.field;
    const school = props.school;

    switch(field) {
      case 'postcode':
        contentCell = school.postcode.postcode;
        break;
      default:
        contentCell = propz.get(school, [row.field], '');
    }

    return (
      <tr key={`school_summary_${index}`}>
        <td>{row.title}</td>
        <td>{contentCell}</td>
      </tr>
    );
  });

  return (
    <table className={'table table-sm mPre'}>
      <tbody>{rows}</tbody>
    </table>
  );
};

SchoolSummary.displayName = 'SchoolSummary';
