export const FILES_CATEGORY_SERVER_TO_CLIENT_MAPPING = {
  CALENDAR: 'Calendar',
  RULES: 'National Governance',
  RISK_ASSESSMENT: 'Newsletters and Updates'
};

export const FILES_CATEGORY = {
  CALENDAR: 'CALENDAR',
  RULES: 'RULES',
  RISK_ASSESSMENT: 'RISK_ASSESSMENT'
};
