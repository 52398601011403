import * as React from 'react';
import * as propz from 'propz';
import { Field, Form, Formik } from 'formik';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from '../../../../../../consts/report';
import { Tournament } from '../../../../../../models/tournament';
import { Switch } from '../../../../../../components/Switch/Switch';
import { LabelWithQuestionIcon } from '../../../../../../components/LabelWithQuestionIcon/LabelWithQuestionIcon';

interface Props {
  onCloseClick: () => void;
  onSubmit: (data: any, reportType: string) => void;
  tournament: Tournament;
}

// report 035
export function TournamentSchoolPlayersReportForm(props: Props) {
  const { tournament } = props;

  const requestInitial = {
    isPrintFullNames: true,
    isPrintDescription: true,
    isPrintFormName: true,
    isPrintYearGroup: true,
    isPrintGender: true,
    isPrintDOB: true
  };

  return (
    <Formik
      onSubmit={values => {
        props.onSubmit(values, REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.TOURNAMENT_SCHOOL_PLAYERS);
      }}
      initialValues={requestInitial}
      render={({ values, setFieldValue }) => (
        <Form>
          <div className="bReportFormTitle">Tournament school players</div>
          <div className="mb-3 mt-3">
            {'View the full list of your school students involved in a selected tournament ' +
              ' as well as details of those who have been added to the tournament teams and/or events.'}
          </div>

          <div className="form-check mb-4">
            <div className="d-flex">
              <Field
                name="isPrintFullNames"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintFullNames}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintFullNames = event.target.checked;
                      setFieldValue('isPrintFullNames', isPrintFullNames);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show full names"
                hintText={`Select if you would like full student names to be printed. When unselected only first name and first letter of the surname will be shown.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintFullNames"
              />
            </div>
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isPrintFormName"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintFormName}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintFormName = event.target.checked;
                      setFieldValue('isPrintFormName', isPrintFormName);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show school form"
                hintText={`This will enable you to view the students form groups.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintFormName"
              />
            </div>
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isPrintYearGroup"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintYearGroup}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintYearGroup = event.target.checked;
                      setFieldValue('isPrintYearGroup', isPrintYearGroup);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show year group"
                hintText={`This will enable you to view the students yeargroups.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintYearGroup"
              />
            </div>
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isPrintGender"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintGender}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintGender = event.target.checked;
                      setFieldValue('isPrintGender', isPrintGender);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show gender"
                hintText={`This will enable you to view the students assigned gender.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintGender"
              />
            </div>
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isPrintDOB"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintDOB}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintDOB = event.target.checked;
                      setFieldValue('isPrintDOB', isPrintDOB);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show date of birth"
                hintText={`This will enable you to view the students date of birth.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintDOB"
              />
            </div>
          </div>

          <button type="button" className="btn btn-secondary mr-3" onClick={props.onCloseClick}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Get
          </button>
        </Form>
      )}
    />
  );
}
