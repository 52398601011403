import * as React from 'react';
import { Component } from 'react';
import { AppUser } from 'Src/views/App/App';
import * as BPromise from 'bluebird';
import * as Lazy from 'lazy.js';
import * as Moment from 'moment';
import { DATE_INTERVAL, DEFAULT_LIMIT, DEFAULT_SKIP, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { History, Location } from 'history';
import { parse } from 'query-string';
import {
  cancelEvent,
  createEventAttendance,
  createEventPerformance,
  getActionDescriptor,
  getAllSchoolEvents,
  getSchoolEventDetails,
  getSchoolEvents,
  getSchoolEventsCount,
  onDeleteJob,
  reasonForCancellation,
  updateActionDescriptor,
  updateEventAttendance,
  updateEventPerformance,
  updateSchoolEventDetails
} from 'Src/helpers/service/admin/schoolEvents';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import {
  ColumnDefinition,
  getFilters2,
  getOrder,
  getSearchFilter,
  getSearchOrder,
  getServerFieldSectionWhere,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import {
  getCoachEvent,
  getAgesGroupFormatted,
  getEventGender,
  getEventGeneratedName,
  getEventStartTime,
  getEventStatus,
  getEventSubtype,
  getEventType,
  getEventVenue,
  getEventVenueType,
  getStaffEvent
} from 'Src/helpers/accessor/accessor';
import { SchoolForm } from 'Src/models/form';
import { getAllForms } from 'Src/helpers/service/admin/forms';
import { sortAges } from 'Src/helpers/event/event';
import { EVENT_NOTIFICATION_MODE, EVENT_STATUS } from 'Src/consts/event';
import * as propz from 'propz';
import { getSchoolSports } from 'Src/helpers/service/admin/user';
import { GenericSummary } from 'Src/components/GenericSummary/GenericSummary';
import { SchoolEventSummary } from 'Src/components/SchoolEventSummary/SchoolEventSummary';
import { SchoolEventDetailsSummary } from 'Src/components/SchoolEventDetailsSummary/SchoolEventDetailsSummary';
import {
  SchoolEventPlayersSummary,
  SchoolEventPlayerSummary,
  SchoolEventTeamSummary
} from 'Src/components/SchoolEventPlayersSummary/SchoolEventPlayersSummary';
import { Button } from 'Src/components/Button/Button';
import { SchoolEventDetailsForm } from './SchoolEventDetailsForm/SchoolEventDetailsForm';
import {
  getSelectOptionForAge,
  getSelectOptionForEventTypes,
  getSelectOptionForEventVenueType,
  getSelectOptionForSport,
  getSelectOptionForTournamentEventGender,
  getSelectOptionForTournamentEventStatus
} from 'Src/helpers/table/select';
import { Grid2 } from 'Src/components/Grid/Grid2';
import { ResizablePanel } from 'Src/components/ResizablePanel/ResizablePanel';
import {
  createEventTasks,
  editEventTasks,
  getEventTasks,
  getSchoolEvent,
  updateSchoolEventTeamPlayersBatch
} from 'Src/helpers/service/admin/event';
import { SchoolEventJobsSummary } from 'Src/components/SchoolEventJobsSummary/SchoolEventJobsSummary';
import { SchoolEventJobForm } from './SchoolEventJobForm/SchoolEventJobForm';
import { Loader } from 'Src/components/Loader/Loader';
import { SchoolEventPerformanceSummary } from 'Src/components/SchoolEventPerformanceSummary/SchoolEventPerformanceSummary';
import { getEventPlayers, getEventTabs, isHousesEvent, isNonTeamSportEvent } from 'Src/helpers/event/event';
import { TABS } from 'Src/consts/common';
import {
  SchoolEvent,
  SchoolEventAttendance,
  SchoolEventIndividualData,
  SchoolEventIndividualPerformance,
  SchoolEventTeamDataPlayer
} from 'Src/models/event';
import { Sport } from 'Src/models/sport';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/report';
import { SchoolEventAttendanceSummary } from 'Src/components/SchoolEventAttendance/SchoolEventAttendanceSummary';
import { getEventNameWithLink } from 'Src/helpers/cell/cell';
import { isClubEvent } from '../../../../../helpers/event/event';
import { EmptyComponent } from '../../../../../components/EmptyComponent/EmptyComponent';
import { SchoolEventMatchReportSummary } from '../../../../../components/SchoolEventMatchReportSummary/SchoolEventMatchReportSummary';
import {
  createEventDiscipline,
  deleteEvent,
  getSchoolEventMatchReport,
  updateEventDiscipline,
  updateGroupSchoolEventDetails,
  updateSchoolEventMatchReport
} from '../../../../../helpers/service/admin/schoolEvents';
import { getEventEndTime } from '../../../../../helpers/accessor/accessor';
import { CLUB_EVENT_EDIT_MODE } from '../../../../../consts/event';
import { updateSchoolEventIndividualsBatch } from '../../../../../helpers/service/admin/schoolEventIndividuals';
import { getSelectOptionsForSubTypeEvent } from '../../../../../helpers/table/select';
import { isUserTeacher, isUserCoach } from 'Src/helpers/user/user';
import { SchoolEventIndividualDiscipline } from '../../../../../models/event';
import { SchoolEventDisciplineSummary } from '../../../../../components/SchoolEventDisciplineSummary/SchoolEventDisciplineSummary';
import { AGE_GROUPS_SORTED } from '../../../../../consts/school';
import { uploadReport } from 'Src/helpers/service/admin/report';
import { FixturesScheduleReport } from './FixturesScheduleReport/FixturesScheduleReport';
import { FixtureParticipationByStudentsReport } from './FixtureParticipationByStudentsReport/FixtureParticipationByStudentsReport';
import { FixtureResultsReport } from './FixtureResultsReport/FixtureResultsReport';
import { DateFixturesReport } from './DateFixturesReport/DateFixturesReport';
import { FixtureHistoryReport } from './FixtureHistoryReport/FixtureHistoryReport';
import { FixtureStatisticsForPeriodReport } from './FixtureStatisticsForPeriodReport/FixtureStatisticsForPeriodReport';
import { Field, Form, Formik, FormikProps } from 'formik';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Event',
    field: 'generatedName',
    isSort: false,
    type: FILTER_TYPE.NONE,
    cell: getEventNameWithLink
  },
  {
    text: 'Start time',
    field: 'startTime',
    isSort: true,
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: getEventStartTime
  },
  {
    text: 'Finish time',
    field: 'endTime',
    isSort: true,
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: getEventEndTime
  },
  {
    text: 'Type',
    field: 'subtype',
    isSort: false,
    type: FILTER_TYPE.SELECT,
    accessor: getEventSubtype
  },
  {
    text: 'Kind',
    field: 'eventType',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getEventType
  },
  {
    text: 'Status',
    field: 'status',
    isSort: true,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getEventStatus
  },
  {
    text: 'Activity',
    field: 'eventSport',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: ['sport', 'name']
  },
  {
    text: 'Age',
    field: 'eventAges',
    isSort: false,
    type: FILTER_TYPE.CHECKBOX,
    accessor: getAgesGroupFormatted
  },
  {
    text: 'Gender',
    field: 'gender',
    isSort: true,
    type: FILTER_TYPE.CHECKBOX,
    accessor: getEventGender
  },
  {
    text: 'Home/Away',
    field: 'venueType',
    isSort: true,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getEventVenueType
  },
  {
    text: 'Venue',
    field: 'venue',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getEventVenue
  },
  {
    text: 'Coach/Leader',
    field: 'coach',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getCoachEvent
  },
  {
    text: 'Staff',
    field: 'staff',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getStaffEvent
  }
];

interface State {
  items: SchoolEvent[];
  currentPage: number;
  selectedItems: SchoolEvent[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  filterOptions: any;
  currentTabIndex: number;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;
  isErrorModalOpen: boolean;
  errorMessage: string;
  isCreateEventModalOpen: boolean;
  isCreateEventJobModalOpen: boolean;
  isDeleteJobModalOpen: boolean;
  isCancelEventModalOpen: boolean;
  isEditEventDetailsModalOpen: boolean;
  isFixtureParticipationByStudentsReportModalOpen: boolean;
  isFixtureResultsReportModalOpen: boolean;
  isDateFixturesReportModalOpen: boolean;
  isFixtureHistoryReportModalOpen: boolean;
  isFixturesScheduleReportModalOpen: boolean;
  isFixtureStatisticsForPeriodReportModalOpen: boolean;
  isEditPerformance: boolean;
  isEditDiscipline: boolean;
  isEditAttendance: boolean;
  isEditMatchReportActive: boolean;
  forms: SchoolForm[];
  sports: Sport[];
  eventsDetails: any;
  eventTasks: any[];
  isEditPositions: boolean;
  playersForSummary: SchoolEventPlayerSummary[];
  teamsForSummary: SchoolEventTeamSummary[];
  activeTeamIndex: number;
  actionDescriptorIds: any[];
  eventTaskIndex: number;
  individualPerformance: SchoolEventIndividualPerformance[];
  individualDiscipline: SchoolEventIndividualDiscipline[];
  attendancePlayers: SchoolEventAttendance[];
  matchReport: string;
  isDeleteEventModalOpen: boolean;
  isSuccessDeleteEventModalOpen: boolean;
  isEditAllAttendance: boolean;
  cancelReason: string;
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export class SchoolEvents extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      filterOptions: undefined,
      isDataFiltered: false,
      currentTabIndex: 0,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',
      isErrorModalOpen: false,
      isCreateEventModalOpen: false,
      isCreateEventJobModalOpen: false,
      isDeleteJobModalOpen: false,
      isCancelEventModalOpen: false,
      isEditEventDetailsModalOpen: false,
      isFixtureParticipationByStudentsReportModalOpen: false,
      isFixtureResultsReportModalOpen: false,
      isDateFixturesReportModalOpen: false,
      isFixtureHistoryReportModalOpen: false,
      isFixturesScheduleReportModalOpen: false,
      isFixtureStatisticsForPeriodReportModalOpen: false,
      isEditPerformance: false,
      isEditDiscipline: false,
      errorMessage: '',
      forms: [],
      sports: [],
      eventsDetails: undefined,
      eventTasks: [],
      isEditPositions: false,
      playersForSummary: [],
      teamsForSummary: [],
      activeTeamIndex: 0,
      actionDescriptorIds: [],
      eventTaskIndex: 0,
      individualPerformance: [],
      individualDiscipline: [],
      isEditAttendance: false,
      attendancePlayers: [],
      matchReport: '',
      isEditMatchReportActive: true,
      isDeleteEventModalOpen: false,
      isSuccessDeleteEventModalOpen: false,
      isEditAllAttendance: true,
      cancelReason: ''
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });
    this.setAdditionalItems().then(res => {
      this.setItems();
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  setAdditionalItems() {
    const { user } = this.props;
    const filter = {
      where: {
        $or: [{ isSchoolEventsAvailable: true }, { isClubEventsAvailable: true }]
      },
      limit: DEFAULT_LIMIT,
      order: 'name ASC',
      skip: DEFAULT_SKIP
    };

    const formsPromise = getAllForms(user);
    const sportsPromise = getSchoolSports(user, filter);

    return BPromise.all([formsPromise, sportsPromise]).then(([forms, sports]) => {
      const filterOptions = this.getFilterOptions(forms, sports);

      this.setState({
        forms: forms,
        sports: sports,
        filterOptions: filterOptions
      });

      return true;
    });
  }

  setItems() {
    const { history, user } = this.props;
    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);
    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters, { isCorrectTime: true });

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const getItemsPromise = getSchoolEvents(user, serverQueryFilter);
    const getItemsCountPromise = getSchoolEventsCount(user, where);

    const promises = [getItemsCountPromise, getItemsPromise];

    return BPromise.all(promises).then(([countObj, items]) => {
      this.setState({
        currentPage: page,
        sortDirection: sortDirection as TABLE_SORT_DIRECTION,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: items,
        total: countObj.count,
        basePath: history.location.pathname,
        isLoading: false
      });

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection, basePath } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const { history } = this.props;

    history.push({
      pathname: basePath,
      search: search.join('&')
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated,
      currentTabIndex: 0
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const selectedItemsNext = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: selectedItemsNext,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true
    });

    const { user } = this.props;
    const where = getServerFieldSectionWhere(filters, { isCorrectTime: true });

    getAllSchoolEvents(user, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const selectedItemsNext = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: selectedItemsNext,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters, basePath } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const { history } = this.props;
    history.push({
      pathname: basePath,
      search: search.join('&')
    });
  };

  onTableFilterChange = (event, filterField: string, options?, index?: number, checkBoxIndex?: number): void => {
    const filterValue = propz.get(event, ['target', 'value'], '');
    const { filters, filterOptions } = this.state;
    const currentFilterField = filters[filterField];
    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      switch (filterType) {
        case FILTER_TYPE.MULTISELECT:
          const options = event.target.options;

          const value = [];
          for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
              value.push(options[i].value);
            }
          }
          nextFilters = {
            ...nextFilters,
            [filterField]: value
          };
          break;
        case FILTER_TYPE.CHECKBOX:
          const columns = COLUMNS;
          const currentField = columns[index].field;
          filterOptions[currentField][checkBoxIndex].isChecked = !filterOptions[currentField][checkBoxIndex].isChecked;

          this.setState({
            filterOptions: filterOptions
          });

          const checkBoxValue = [];
          if (typeof filterOptions[filterField] !== 'undefined') {
            for (let i = 0; i < filterOptions[filterField].length; i++) {
              if (filterOptions[filterField][i].isChecked) {
                checkBoxValue.push(filterOptions[filterField][i].value);
              }
            }
          }
          nextFilters = {
            ...nextFilters,
            [filterField]: checkBoxValue
          };
          break;
        default:
          nextFilters = {
            ...nextFilters,
            [filterField]: filterValue
          };
          break;
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName, basePath } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    const { history } = this.props;
    history.push({
      pathname: basePath,
      search: search.join('&')
    });
  };

  onClearFilterClick = (): void => {
    const { forms, sports, basePath } = this.state;
    const filterOptions = this.getFilterOptions(forms, sports);

    this.setState({
      filterOptions: filterOptions,
      selectedItems: [],
      isSelectAllChecked: false
    });

    const { history } = this.props;

    history.push({
      pathname: this.state.basePath,
      search: ''
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  onCloseErrorClick = () => {
    this.setState({
      isErrorModalOpen: false,
      errorMessage: ''
    });
  };

  onSubmitEventJobForm = data => {
    const { user } = this.props;
    const { selectedItems, eventTaskIndex, eventTasks } = this.state;
    const schoolEventId = selectedItems[0].id;
    const schoolEvent = selectedItems[0];
    this.setState({
      isLoading: true,
      isCreateEventJobModalOpen: false
    });

    const { players, ...rest } = data;

    const dataToSubmit = {
      ...rest,
      assignees: players
    };
    if (eventTaskIndex !== -1) {
      const activeTaskId = eventTasks[eventTaskIndex].id;

      editEventTasks(user, schoolEventId, activeTaskId, dataToSubmit)
        .then(res => getEventTasks(user, schoolEventId))
        .then(eventTasks => {
          const eventTab = getEventTabs(schoolEvent);
          const tabIndex = eventTab.indexOf(TABS.JOBS);
          this.setState({
            currentTabIndex: tabIndex,
            eventTasks: eventTasks,
            isCreateEventJobModalOpen: false,
            isLoading: false
          });
        })
        .catch(error => {
          const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
          console.error(error);
          this.setState({
            isLoading: false,
            errorMessage: errorText,
            isErrorModalOpen: true
          });
        });
    } else {
      createEventTasks(user, schoolEventId, dataToSubmit)
        .then(task => {
          const tasks = [...eventTasks];
          tasks.push(task);
          this.setState({
            eventTasks: tasks,
            isLoading: false
          });
        })
        .catch(error => {
          const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
          console.error(error);
          this.setState({
            isCreateEventJobModalOpen: false,
            isLoading: false,
            errorMessage: errorText,
            isErrorModalOpen: true
          });
        });
    }
  };

  onSubmitReportForm = (data, reportType) => {
    const { user } = this.props;
    const { activeSchoolId, activeSchool } = user;
    const { selectedItems, forms } = this.state;
    const { ageGroupsNaming } = activeSchool;
    const ageGroup = propz.get(AGE_GROUPS_SORTED, [ageGroupsNaming]);

    const {
      dateTo,
      dateFrom,
      isShowZero,
      isShowNonZero,
      isGroupBySports,
      genders,
      ages: ageItems,
      isShowInterSchool,
      isShowHouses,
      isShowIntraSchool,
      isShowUnclosed,
      isShowTournament,
      isPrintPP,
      isPrintSEN,
      isPrintFSM,
      isPrintEthnicity,
      isCurrentWeek,
      isNextWeek,
      isCurrentMonth,
      isNextMonth
    } = data;

    let modData;
    let formsUniq;
    let ages: number[];

    switch (reportType) {
      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.FIXTURES_SCHEDULE:
        this.setState({
          isFixturesScheduleReportModalOpen: false,
          isLoading: true
        });

        dateFrom.setHours(0, 0, 0, 0);
        dateTo.setHours(23, 59, 59, 0);

        formsUniq = Lazy(forms)
          .uniq('age')
          .sort((form1, form2) => sortAges(form1.age, form2.age, ageGroupsNaming))
          .toArray();

        ages = formsUniq.filter((form, index) => ageItems[index].isChecked).map(form => form.age);

        modData = {
          reportParamsCSV: {
            dateTo: Moment(dateTo).format('YYYY-MM-DD HH:mm:ss'),
            dateFrom: Moment(dateFrom).format('YYYY-MM-DD HH:mm:ss'),
            schoolId: activeSchoolId,
            showTournament: isShowTournament,
            genders: genders.filter(gender => gender.isChecked === true).map(gender => gender.name.toUpperCase()),
            ageGroups: ages,
            currentWeek: isCurrentWeek,
            nextWeek: isNextWeek,
            currentMonth: isCurrentMonth,
            nextMonth: isNextMonth
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.FIXTURE_PARTICIPATION_STATISTICS_BY_STUDENTS:
        this.setState({
          isFixtureParticipationByStudentsReportModalOpen: false,
          isLoading: true
        });

        dateFrom.setHours(0, 0, 0, 0);
        dateTo.setHours(23, 59, 59, 0);

        formsUniq = Lazy(forms)
          .uniq('age')
          .sort((form1, form2) => sortAges(form1.age, form2.age, ageGroupsNaming))
          .toArray();

        ages = formsUniq.filter((form, index) => ageItems[index].isChecked).map(form => form.age);

        modData = {
          reportParamsCSV: {
            dateTo: Moment(dateTo).format('YYYY-MM-DD HH:mm:ss'),
            dateFrom: Moment(dateFrom).format('YYYY-MM-DD HH:mm:ss'),
            schoolId: activeSchoolId,
            showZero: isShowZero,
            showNonZero: isShowNonZero,
            printPP: isPrintPP,
            printSEN: isPrintSEN,
            printFSM: isPrintFSM,
            groupBySports: isGroupBySports,
            genders: genders.filter(gender => gender.isChecked === true).map(gender => gender.name.toUpperCase()),
            ageGroups: ages
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.FIXTURE_RESULTS:
        this.setState({
          isFixtureResultsReportModalOpen: false,
          isLoading: true
        });

        dateFrom.setHours(0, 0, 0, 0);
        dateTo.setHours(23, 59, 59, 0);

        formsUniq = Lazy(forms)
          .uniq('age')
          .sort((form1, form2) => sortAges(form1.age, form2.age, ageGroupsNaming))
          .toArray();

        ages = formsUniq.filter((form, index) => ageItems[index].isChecked).map(form => form.age);

        modData = {
          reportParamsCSV: {
            dateTo: Moment(dateTo).format('YYYY-MM-DD HH:mm:ss'),
            dateFrom: Moment(dateFrom).format('YYYY-MM-DD HH:mm:ss'),
            schoolId: activeSchoolId,
            showInterSchool: isShowInterSchool,
            showHouses: isShowHouses,
            showIntraSchool: isShowIntraSchool,
            showUnclosed: isShowUnclosed,
            genders: genders.filter(gender => gender.isChecked === true).map(gender => gender.name.toUpperCase()),
            ageGroups: ages
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.DATE_FIXTURES:
        this.setState({
          isDateFixturesReportModalOpen: false,
          isLoading: true
        });

        dateFrom.setHours(0, 0, 0, 0);

        modData = {
          reportParamsCSV: {
            dateFrom: Moment(dateFrom).format('YYYY-MM-DD HH:mm:ss'),
            schoolId: activeSchoolId
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.FIXTURE_HISTORY:
        this.setState({
          isFixtureHistoryReportModalOpen: false,
          isLoading: true
        });

        formsUniq = Lazy(forms)
          .uniq('age')
          .sort((form1, form2) => sortAges(form1.age, form2.age, ageGroupsNaming))
          .toArray();

        ages = formsUniq.filter((form, index) => ageItems[index].isChecked).map(form => form.age);

        modData = {
          reportParamsCSV: {
            schoolId: activeSchoolId,
            showInterSchool: isShowInterSchool,
            showHouses: isShowHouses,
            showIntraSchool: isShowIntraSchool,
            showTournament: isShowTournament,
            genders: genders.filter(gender => gender.isChecked === true).map(gender => gender.name.toUpperCase()),
            ageGroups: ages
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.FIXTURE_STATISTICS_FOR_PERIOD:
        this.setState({
          isFixtureStatisticsForPeriodReportModalOpen: false,
          isLoading: true
        });

        dateFrom.setHours(0, 0, 0, 0);
        dateTo.setHours(23, 59, 59, 0);

        formsUniq = Lazy(forms)
          .uniq('age')
          .sort((form1, form2) => sortAges(form1.age, form2.age, ageGroupsNaming))
          .toArray();

        ages = formsUniq.filter((form, index) => ageItems[index].isChecked).map(form => form.age);

        modData = {
          reportParamsCSV: {
            dateTo: Moment(dateTo).format('YYYY-MM-DD HH:mm:ss'),
            dateFrom: Moment(dateFrom).format('YYYY-MM-DD HH:mm:ss'),
            schoolId: activeSchoolId,
            showInterSchool: isShowInterSchool,
            showHouses: isShowHouses,
            showIntraSchool: isShowIntraSchool,
            showTournament: isShowTournament,
            genders: genders.filter(gender => gender.isChecked === true).map(gender => gender.name.toUpperCase()),
            ageGroups: ages,
            printPP: isPrintPP,
            printSEN: isPrintSEN,
            printFSM: isPrintFSM,
            printEthnicity: isPrintEthnicity
          }
        };
        break;
    }

    uploadReport(user, reportType, modData)
      .then(data => {
        if (typeof data.reportId !== 'undefined') {
          const reportType = data.reportType;
          this.setState({
            isLoading: false
          });
          const url = `${window.apiBase}/public/report/${data.reportId}/reportType/${reportType}`;
          window.open(url);
        } else {
          this.setState({
            isLoading: false,
            errorMessage: 'No read report',
            isErrorModalOpen: true
          });
        }
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        console.error(error);

        this.setState({
          isLoading: false,
          errorMessage: errorText,
          isErrorModalOpen: true
        });
      });
  };

  renderSendingError(): React.ReactNode {
    const { errorMessage, isErrorModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isErrorModalOpen}
        title={'Error'}
        body={errorMessage}
        buttonCancelText={'Ok'}
        onCloseClick={this.onCloseErrorClick}
      />
    );
  }

  onShowError = (message: string) => {
    this.setState({
      isErrorModalOpen: true,
      errorMessage: message
    });
  };

  renderEventJobForm(): React.ReactNode {
    const { isCreateEventJobModalOpen, eventTasks, eventTaskIndex, selectedItems } = this.state;
    const { user } = this.props;
    const schoolEvent = selectedItems[0];
    const modalText = eventTaskIndex === -1 ? 'Create job' : 'Edit job';
    return (
      <SimpleModal isOpen={isCreateEventJobModalOpen} title={modalText}>
        <SchoolEventJobForm
          user={user}
          schoolEvent={schoolEvent}
          eventTasks={eventTasks}
          eventTaskIndex={eventTaskIndex}
          onCancel={this.onCloseEventJobClick}
          onSubmit={this.onSubmitEventJobForm}
        />
      </SimpleModal>
    );
  }

  renderDeleteJobModal() {
    const { isDeleteJobModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isDeleteJobModalOpen}
        title={'Remove Job'}
        body={`Are you sure you want to remove Job ?`}
        buttonText={'Delete'}
        onButtonClick={this.onDeleteJobClick}
        onCloseClick={this.deleteJobModalClose}
      />
    );
  }

  renderFixturesScheduleReportFormModal() {
    const { isFixturesScheduleReportModalOpen, forms } = this.state;
    const { user } = this.props;
    const { activeSchool } = user;
    return (
      <SimpleModal isOpen={isFixturesScheduleReportModalOpen}>
        <FixturesScheduleReport
          onCloseClick={this.closeFixturesScheduleReportForm}
          onSubmit={this.onSubmitReportForm}
          forms={forms}
          school={activeSchool}
        />
      </SimpleModal>
    );
  }

  renderFixtureParticipationByStudentsReportFormModal() {
    const { isFixtureParticipationByStudentsReportModalOpen, forms } = this.state;
    const { user } = this.props;
    const { activeSchool } = user;
    return (
      <SimpleModal isOpen={isFixtureParticipationByStudentsReportModalOpen}>
        <FixtureParticipationByStudentsReport
          onCloseClick={this.closeFixtureParticipationByStudentsReportForm}
          onSubmit={this.onSubmitReportForm}
          forms={forms}
          school={activeSchool}
        />
      </SimpleModal>
    );
  }

  renderFixtureResultsReportFormModal() {
    const { isFixtureResultsReportModalOpen, forms } = this.state;
    const { user } = this.props;
    const { activeSchool } = user;
    return (
      <SimpleModal isOpen={isFixtureResultsReportModalOpen}>
        <FixtureResultsReport
          onCloseClick={this.closeFixtureResultsReportForm}
          onSubmit={this.onSubmitReportForm}
          forms={forms}
          school={activeSchool}
        />
      </SimpleModal>
    );
  }
  renderDateFixturesReportFormModal() {
    const { isDateFixturesReportModalOpen, forms } = this.state;
    const { user } = this.props;
    const { activeSchool } = user;
    return (
      <SimpleModal isOpen={isDateFixturesReportModalOpen}>
        <DateFixturesReport
          onCloseClick={this.closeDateFixturesReportForm}
          onSubmit={this.onSubmitReportForm}
          school={activeSchool}
        />
      </SimpleModal>
    );
  }
  renderFixtureHistoryReportFormModal() {
    const { isFixtureHistoryReportModalOpen, forms } = this.state;
    const { user } = this.props;
    const { activeSchool } = user;
    return (
      <SimpleModal isOpen={isFixtureHistoryReportModalOpen}>
        <FixtureHistoryReport
          onCloseClick={this.closeFixtureHistoryReportForm}
          onSubmit={this.onSubmitReportForm}
          forms={forms}
          school={activeSchool}
        />
      </SimpleModal>
    );
  }
  renderFixtureStatisticsForPeriodReportFormModal() {
    const { isFixtureStatisticsForPeriodReportModalOpen, forms } = this.state;
    const { user } = this.props;
    const { activeSchool } = user;
    return (
      <SimpleModal isOpen={isFixtureStatisticsForPeriodReportModalOpen}>
        <FixtureStatisticsForPeriodReport
          onCloseClick={this.closeFixtureStatisticsForPeriodReportForm}
          onSubmit={this.onSubmitReportForm}
          forms={forms}
          school={activeSchool}
        />
      </SimpleModal>
    );
  }

  onCreateEventClick = (): void => {
    this.props.history.push({
      pathname: `/events/manager`
    });
  };

  onTabClick = (event, index: number) => {
    if (typeof event !== 'undefined') {
      event.preventDefault();
    }

    const { selectedItems, isEditPerformance, isEditAttendance, isEditPositions, isEditDiscipline } = this.state;
    const [schoolEvent] = selectedItems;
    const eventId = schoolEvent.id;
    const { user } = this.props;

    if (isEditPerformance || isEditAttendance || isEditPositions || isEditDiscipline) {
      return;
    }

    const tabs = getEventTabs(schoolEvent);
    const currentTab = tabs[index];

    const individualPerformance = schoolEvent.results.individualPerformance;
    const individualDiscipline = schoolEvent.results.individualDiscipline;
    const attendance = schoolEvent.results.attendance;
    const attendancePlayers = attendance.map(player => ({ ...player }));

    switch (currentTab) {
      case TABS.DETAILS:
        this.setState({
          isLoading: true
        });
        getSchoolEventDetails(user, eventId).then(details => {
          this.setState({
            currentTabIndex: index,
            eventsDetails: details,
            isLoading: false
          });
        });
        break;
      case TABS.JOBS:
        this.setState({
          isLoading: true
        });
        getEventTasks(user, eventId).then(eventTasks => {
          this.setState({
            currentTabIndex: index,
            eventTasks: eventTasks,
            isLoading: false
          });
        });
        break;
      case TABS.PARTICIPANTS:
        const playersForSummary = this.getPlayersForSummary();
        const teamsForSummary = this.getTeamsForSummary();
        this.setState({
          currentTabIndex: index,
          playersForSummary: playersForSummary,
          teamsForSummary: teamsForSummary
        });
        break;
      case isClubEvent(schoolEvent) ? TABS.PROGRESS : TABS.PERFORMANCE:
        this.setState({
          currentTabIndex: index,
          individualPerformance: individualPerformance
        });
        break;
      case TABS.DISCIPLINE: {
        this.setState({
          currentTabIndex: index,
          individualDiscipline: individualDiscipline
        });
        break;
      }
      case TABS.ATTENDANCE:
        this.setState({
          currentTabIndex: index,
          attendancePlayers: attendancePlayers
        });
        break;
      case isClubEvent(event) ? TABS.LEADER_COACH_NOTES : TABS.MATCH_REPORT:
        this.setState({
          isLoading: true
        });
        getSchoolEventMatchReport(user, eventId).then(matchReport => {
          const content = propz.get(matchReport, ['content'], '');
          this.setState({
            currentTabIndex: index,
            matchReport: content,
            isLoading: false
          });
        });
        break;
      default:
        this.setState({
          currentTabIndex: index
        });
    }
  };

  cancelEventModalOpen = () => {
    this.setState({
      isCancelEventModalOpen: true,
      cancelReason: ''
    });
  };

  cancelEventModalClose = () => {
    this.setState({
      isCancelEventModalOpen: false,
      cancelReason: '',
      selectedItems: []
    });
  };

  deleteEventModalOpen = () => {
    this.setState({
      isDeleteEventModalOpen: true
    });
  };

  deleteEventModalClose = (): void => {
    this.setState({
      isDeleteEventModalOpen: false
    });
  };

  deleteSuccessEventModalClose = (): void => {
    this.setState({
      isSuccessDeleteEventModalOpen: false
    });
  };

  editEventDetailsModalOpen = () => {
    this.setState({
      isEditEventDetailsModalOpen: true
    });
  };

  editEventDetailsModalClose = () => {
    this.setState({
      isEditEventDetailsModalOpen: false
    });
  };

  openFixturesScheduleReportForm = () => {
    this.setState({
      isFixturesScheduleReportModalOpen: true
    });
  };

  closeFixturesScheduleReportForm = () => {
    this.setState({
      isFixturesScheduleReportModalOpen: false
    });
  };

  openFixtureParticipationByStudentsReportForm = () => {
    this.setState({
      isFixtureParticipationByStudentsReportModalOpen: true
    });
  };

  closeFixtureParticipationByStudentsReportForm = () => {
    this.setState({
      isFixtureParticipationByStudentsReportModalOpen: false
    });
  };

  openFixtureResultsReportForm = () => {
    this.setState({
      isFixtureResultsReportModalOpen: true
    });
  };

  closeFixtureResultsReportForm = () => {
    this.setState({
      isFixtureResultsReportModalOpen: false
    });
  };

  openDateFixturesReportForm = () => {
    this.setState({
      isDateFixturesReportModalOpen: true
    });
  };

  closeDateFixturesReportForm = () => {
    this.setState({
      isDateFixturesReportModalOpen: false
    });
  };

  openFixtureHistoryReportForm = () => {
    this.setState({
      isFixtureHistoryReportModalOpen: true
    });
  };

  closeFixtureHistoryReportForm = () => {
    this.setState({
      isFixtureHistoryReportModalOpen: false
    });
  };

  openFixtureStatisticsForPeriodReportForm = () => {
    this.setState({
      isFixtureStatisticsForPeriodReportModalOpen: true
    });
  };

  closeFixtureStatisticsForPeriodReportForm = () => {
    this.setState({
      isFixtureStatisticsForPeriodReportModalOpen: false
    });
  };

  updateEventDetails = (data, editMode) => {
    const { selectedItems, items } = this.state;
    const schoolEvent = selectedItems[0];
    const eventId = schoolEvent.id;
    const { user } = this.props;

    this.setState({
      isLoading: true,
      isEditEventDetailsModalOpen: false
    });

    let details;

    const singleEditMode = editMode === CLUB_EVENT_EDIT_MODE.SINGLE;
    const getEditEventDetailsService = singleEditMode
      ? updateSchoolEventDetails(user, eventId, data)
      : updateGroupSchoolEventDetails(user, eventId, data);

    getEditEventDetailsService
      .then(_details => {
        details = _details;

        return getSchoolEvent(user, eventId);
      })
      .then(event => {
        const eventsNext = items.map(item => {
          return item.id === eventId ? event : item;
        });

        this.setState({
          isLoading: false,
          eventsDetails: details,
          selectedItems: [event],
          items: eventsNext
        });
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        console.error(error);
        this.setState({
          isLoading: false,
          errorMessage: errorText,
          isErrorModalOpen: true
        });
      });
  };

  renderEventDetailsForm(): React.ReactNode {
    const { isEditEventDetailsModalOpen, eventsDetails, selectedItems } = this.state;
    const { user } = this.props;
    const schoolEvent = selectedItems[0];
    const eventId = propz.get(schoolEvent, ['id'], '');
    return (
      <SimpleModal
        customClass="mSchoolEventDetailsFormWidth"
        isOpen={isEditEventDetailsModalOpen}
        title={'Event details'}
      >
        <SchoolEventDetailsForm
          eventId={eventId}
          user={user}
          onSubmit={this.updateEventDetails}
          onCancel={this.editEventDetailsModalClose}
          eventsDetails={eventsDetails}
          event={schoolEvent}
        />
      </SimpleModal>
    );
  }

  onDeleteEventClick = () => {
    const { selectedItems } = this.state;
    const { user } = this.props;

    this.setState({
      isDeleteEventModalOpen: false,
      isLoading: true
    });

    const promises = selectedItems.map(event => {
      return deleteEvent(user, event.id);
    });
    BPromise.all(promises)
      .then(() => {
        this.setState({
          selectedItems: [],
          isSuccessDeleteEventModalOpen: true
        });
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        console.error(error);
        this.setState({
          isLoading: false,
          errorMessage: errorText,
          isErrorModalOpen: true
        });
      });

    this.setItems();
  };

  onCancelEventClick = () => {
    const { selectedItems, cancelReason } = this.state;
    const { user } = this.props;
    const isEventUpdateNotificationCustomMessageEnabled = propz.get(
      user,
      ['activeSchool', 'isEventUpdateNotificationCustomMessageEnabled'],
      false
    );
    const notificationMode = isEventUpdateNotificationCustomMessageEnabled
      ? EVENT_NOTIFICATION_MODE.MANUAL
      : EVENT_NOTIFICATION_MODE.AUTO;

    this.setState({
      isLoading: true
    });

    let currentActionDescriptorIds = [];
    let updateEvents = [];
    const promises = selectedItems.map(event => {
      return cancelEvent(user, event.id, notificationMode)
        .then(res => {
          const updateEvent = res.data;
          const actionDescriptorId = propz.get(res, ['headers', 'action-descriptor-id'], '');
          currentActionDescriptorIds.push(actionDescriptorId);
          updateEvents.push(updateEvent);
        })
        .catch(error => {
          const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
          console.error(error);

          this.setState({
            isLoading: false,
            errorMessage: errorText,
            isErrorModalOpen: true
          });
        });
    });

    BPromise.all(promises).then(res => {
      if (isEventUpdateNotificationCustomMessageEnabled) {
        if (cancelReason) {
          const reasonForCancellationPromise = selectedItems.map(event =>
            reasonForCancellation(user, event.id, cancelReason)
          );
          BPromise.all(reasonForCancellationPromise).then(() => {
            this.updateActionDescriptors(currentActionDescriptorIds, cancelReason);
          });
        } else {
          this.updateActionDescriptors(currentActionDescriptorIds);
        }
      } else {
        this.setState(
          {
            selectedItems: [],
            isCancelEventModalOpen: false,
            actionDescriptorIds: []
          },
          () => {
            this.setItems();
          }
        );
      }
    });
  };

  updateActionDescriptors = (actionDescriptorIds, additionalDescription = '') => {
    const { user } = this.props;
    const promises = actionDescriptorIds.map(actionDescriptorId => {
      return getActionDescriptor(user, actionDescriptorId)
        .then(actionDescriptor => {
          const USERS_COUNT_IN_CHUNK = 30;

          const usersFiltered = [...actionDescriptor.affectedUserList];

          let userChunks = [];

          for (let i = 0; i < usersFiltered.length; i += USERS_COUNT_IN_CHUNK) {
            const chunk = usersFiltered.slice(i, i + USERS_COUNT_IN_CHUNK);
            userChunks.push(chunk);
          }

          const userChunksCount = userChunks.length;

          BPromise.all(
            userChunks.map((userChunk, index) => {
              const data = {
                manualConfirmationStatus: index === userChunksCount - 1 ? 'CONFIRMED' : 'AWAITING_CONFIRMATION',
                usersToNotifyList: userChunk,
                additionalDescription: additionalDescription
              };
              return updateActionDescriptor(user, actionDescriptor.id, data);
            })
          ).then(() => {
            this.setState({
              isLoading: false,
              isCancelEventModalOpen: false,
              actionDescriptorIds: [],
              selectedItems: []
            });
            this.setItems();
          });
        })
        .catch(error => {
          const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
          console.error(error);
          this.setState({
            isLoading: false,
            errorMessage: errorText,
            isErrorModalOpen: true
          });
        });
    });
  };

  renderCancelEventModal() {
    const { selectedItems, isCancelEventModalOpen, cancelReason } = this.state;
    const { user } = this.props;
    const isEventUpdateNotificationCustomMessageEnabled = propz.get(
      user,
      ['activeSchool', 'isEventUpdateNotificationCustomMessageEnabled'],
      false
    );

    return (
      <SimpleModal isOpen={isCancelEventModalOpen} title={'Cancel event'}>
        <div>
          <p>
            Are you sure you want to cancel
            {selectedItems.length === 1 ? (
              <>
                {' '}
                Event <b>{getEventGeneratedName(selectedItems[0], { user: user })}</b> (
                {Moment(selectedItems[0].startTime).format('dddd, MMMM Do YYYY, h:mm a')})?
              </>
            ) : (
              <> the following {selectedItems.length} Events:</>
            )}
          </p>
          {selectedItems.length > 1 && (
            <ul>
              {selectedItems.map((event, index) => (
                <li key={index}>
                  <b>{getEventGeneratedName(event, { user: user })} </b>(
                  {Moment(event.startTime).format('dddd, MMMM Do YYYY, h:mm a')}):
                </li>
              ))}
            </ul>
          )}
          {isEventUpdateNotificationCustomMessageEnabled && (
            <>
              <p>
                If you need to provide extra details like about the event to be rescheduled, or pitches frozen, etc.,
                specify them in the field below. The reason will be included in the notification sent to team members
                and parents. If you want to cancel event(s) without providing a reason, just keep the field empty.
              </p>
              <label htmlFor="cancelReason">
                <b>Reason for cancellation (optional):</b>
              </label>
              <textarea
                id="cancelReason"
                value={cancelReason}
                placeholder="Please enter a reason for cancellation if applicable"
                onChange={e => this.setState({ cancelReason: e.target.value })}
                className="form-control"
                style={{ width: '100%', height: '100px', marginTop: '10px' }}
              />
            </>
          )}
        </div>
        <div className="modal-footer">
          <Button
            text={selectedItems.length === 1 ? 'Keep event active' : 'Keep events active'}
            onClick={this.cancelEventModalClose}
            customClass={'mt-3 mb-3 mr-3 btn-secondary'}
          />
          <Button text="Confirm cancellation" onClick={this.onCancelEventClick} />
        </div>
      </SimpleModal>
    );
  }

  renderDeleteEventModal() {
    const { selectedItems, isDeleteEventModalOpen } = this.state;
    const { user } = this.props;
    const eventName = getEventGeneratedName(selectedItems[0], { user: user });

    return (
      <SimpleModal
        isOpen={isDeleteEventModalOpen}
        title={'Remove Event'}
        body={`Are you sure you want to remove ${
          selectedItems.length === 1 ? `Event ${eventName}` : `${selectedItems.length} Events`
        }?`}
        buttonText={'Delete'}
        onButtonClick={this.onDeleteEventClick}
        onCloseClick={this.deleteEventModalClose}
      />
    );
  }

  renderConfirmationDeleteEventModal() {
    const { isSuccessDeleteEventModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isSuccessDeleteEventModalOpen}
        title={'Success'}
        body={`Event has been successfully removed`}
        buttonText={'Ok'}
        onButtonClick={this.deleteSuccessEventModalClose}
      />
    );
  }

  onSavePositionsClick = () => {
    const { playersForSummary, selectedItems, teamsForSummary } = this.state;
    const { user } = this.props;

    const [schoolEvent] = selectedItems;
    const eventId = schoolEvent.id;

    this.setState({
      isLoading: true
    });

    const promise = isNonTeamSportEvent(schoolEvent)
      ? updateSchoolEventIndividualsBatch(user, eventId, {
          add: [],
          remove: [],
          update: playersForSummary.map(player => {
            const { isCaptain, isSub, positionId, playerId } = player;
            return {
              isCaptain: isCaptain,
              sub: isSub,
              positionId: positionId !== '' ? positionId : null,
              id: playerId
            };
          })
        })
      : BPromise.all(
          teamsForSummary.map(teamForSummary =>
            updateSchoolEventTeamPlayersBatch(user, eventId, teamForSummary.teamId, {
              add: [],
              remove: [],
              update: playersForSummary
                .filter(player => player.teamId === teamForSummary.teamId)
                .map(player => {
                  const { isCaptain, isSub, positionId, playerId } = player;
                  return {
                    isCaptain: isCaptain,
                    sub: isSub,
                    positionId: positionId !== '' ? positionId : null,
                    id: playerId
                  };
                })
            })
          )
        );

    promise
      .then(res => {
        return getSchoolEvent(user, eventId);
      })
      .then(event => {
        const playersForSummary = this.getPlayersForSummary(event);
        this.setState({
          isLoading: false,
          isEditPositions: false,
          playersForSummary: playersForSummary,
          selectedItems: [event],
          activeTeamIndex: 0
        });
      });
  };

  onEditPositionsClick = () => {
    this.setState({
      isEditPositions: true
    });
  };

  onChangeTeam = event => {
    const { teamsForSummary } = this.state;
    const teamId = event.target.value;
    const currentTeamIndex = teamsForSummary.findIndex(team => team.teamId === teamId);

    this.setState({
      activeTeamIndex: currentTeamIndex
    });
  };

  onChangeIsSubPlayer = (event, playerId: string): void => {
    const isSub = event.target.checked;
    const { playersForSummary } = this.state;
    const playerIndex = playersForSummary.findIndex(playerForSummary => playerForSummary.playerId === playerId);

    const nextPlayersForSummary = [...playersForSummary];
    nextPlayersForSummary[playerIndex].isSub = isSub;

    this.setState({
      playersForSummary: nextPlayersForSummary
    });
  };

  onChangeIsCaptainPlayer = (event, playerId) => {
    const isCaptain = event.target.checked;
    const { playersForSummary, teamsForSummary, activeTeamIndex } = this.state;
    const playerIndex = playersForSummary.findIndex(playerForSummary => playerForSummary.playerId === playerId);

    const nextPlayersForSummary = [...playersForSummary];

    if (isCaptain) {
      const teamId = teamsForSummary[activeTeamIndex].teamId;
      // ahah old captain, yohoho
      const oldCaptainIndex = playersForSummary.findIndex(player => player.teamId === teamId && player.isCaptain);

      // kill captain, i'm a captain
      if (oldCaptainIndex !== -1) {
        nextPlayersForSummary[oldCaptainIndex].isCaptain = false;
      }
    }

    nextPlayersForSummary[playerIndex].isCaptain = isCaptain;

    this.setState({
      playersForSummary: nextPlayersForSummary
    });
  };

  onChangePositionPlayer = (event, playerId: string): void => {
    const positionValue = event.target.value;

    const { playersForSummary } = this.state;
    const playerIndex = playersForSummary.findIndex(playerForSummary => playerForSummary.playerId === playerId);

    const nextPlayersForSummary = [...playersForSummary];
    nextPlayersForSummary[playerIndex].positionId = positionValue;

    this.setState({
      playersForSummary: nextPlayersForSummary
    });
  };

  getPlayersForSummary(event?: SchoolEvent): SchoolEventPlayerSummary[] {
    const { selectedItems } = this.state;
    const { user } = this.props;
    const { activeSchoolId } = user;
    const schoolEvent = typeof event !== 'undefined' ? event : selectedItems[0];

    const schoolPlayers = getEventPlayers(schoolEvent, activeSchoolId);

    return schoolPlayers.map(schoolPlayer => {
      const { userId, permissionId, firstName, lastName, form, id, teamId } = schoolPlayer;
      const isFormExist = typeof form !== 'undefined';
      const formName = isFormExist ? form.name : '';

      const playerFromEvent = schoolEvent.players.find(
        player => player.userId === userId && player.permissionId === permissionId
      );

      const positionId = typeof playerFromEvent !== 'undefined' ? playerFromEvent.positionId : '';
      const isCaptain = typeof playerFromEvent !== 'undefined' ? playerFromEvent.isCaptain : false;
      const isSub = typeof playerFromEvent !== 'undefined' ? playerFromEvent.sub : false;

      return {
        name: `${lastName} ${firstName}`,
        playerId: id,
        form: formName,
        positionId: positionId,
        isCaptain: isCaptain,
        isSub: isSub,
        teamId: teamId
      };
    });
  }

  getTeamsForSummary(): SchoolEventTeamSummary[] {
    const { selectedItems } = this.state;
    const { user } = this.props;
    const { activeSchoolId } = user;
    const [schoolEvent] = selectedItems;

    return schoolEvent.teamsData
      .filter(team => team.schoolId === activeSchoolId)
      .map(team => ({
        teamName: team.name,
        teamId: team.id,
        houseName: isHousesEvent(schoolEvent) ? team.house.name : undefined
      }));
  }

  onEditEventJobClick = (event, index) => {
    event.preventDefault();

    this.setState({
      isCreateEventJobModalOpen: true,
      eventTaskIndex: index
    });
  };

  deleteJobModalOpen = (event, index): void => {
    this.setState({
      isDeleteJobModalOpen: true,
      eventTaskIndex: index
    });
  };

  deleteJobModalClose = (): void => {
    this.setState({
      isDeleteJobModalOpen: false
    });
  };

  onDeleteJobClick = () => {
    const { user } = this.props;
    const { selectedItems, eventTasks, eventTaskIndex } = this.state;
    const schoolEvent = selectedItems[0];
    const eventId = schoolEvent.id;
    const eventTaskId = eventTasks[eventTaskIndex].id;

    this.setState({
      isDeleteJobModalOpen: false,
      isLoading: true
    });

    onDeleteJob(user, eventId, eventTaskId)
      .then(res => getEventTasks(user, eventId))
      .then(eventTasks => {
        const eventTab = getEventTabs(schoolEvent);
        const tabIndex = eventTab.indexOf(TABS.JOBS);
        this.setState({
          currentTabIndex: tabIndex,
          eventTasks: eventTasks,
          isLoading: false
        });
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        console.error(error);
        this.setState({
          isLoading: false,
          errorMessage: errorText,
          isErrorModalOpen: true
        });
      });
  };

  onAddEventJobClick = event => {
    event.preventDefault();

    this.setState({
      isCreateEventJobModalOpen: true,
      eventTaskIndex: -1
    });
  };

  onCloseEventJobClick = event => {
    event.preventDefault();

    this.setState({
      isCreateEventJobModalOpen: false,
      eventTaskIndex: 0
    });
  };

  onChangeEventPerformanceClick = (value, player, performanceId) => {
    const { individualPerformance } = this.state;

    const performanceIndex = individualPerformance.findIndex(
      performance => performance.performanceId === performanceId && performance.userId === player.userId
    );

    let nextPerformance;

    if (performanceIndex === -1) {
      nextPerformance = [
        ...individualPerformance,
        {
          performanceId: performanceId,
          userId: player.userId,
          teamId: player.teamId,
          value: value,
          permissionId: player.permissionId
        }
      ];
    } else {
      nextPerformance = [...individualPerformance];
      nextPerformance[performanceIndex].value = value;
    }

    this.setState({
      individualPerformance: nextPerformance
    });
  };

  onSaveEventPerformanceClick = () => {
    const { individualPerformance, selectedItems } = this.state;
    const { user } = this.props;
    const [schoolEvent] = selectedItems;
    const eventId = schoolEvent.id;

    this.setState({
      isLoading: true
    });

    const performanceWithoutId = individualPerformance.filter(performance => typeof performance._id === 'undefined');
    const performanceWithId = individualPerformance.filter(performance => typeof performance._id !== 'undefined');

    const performanceWithoutIdPromises = performanceWithoutId.map(data => createEventPerformance(user, eventId, data));
    const performanceWithIdPromises = performanceWithId.map(performance => {
      const updatePerformanceId = performance._id;
      const value = performance.value;

      return updateEventPerformance(user, eventId, updatePerformanceId, { value });
    });

    const promises = [...performanceWithoutIdPromises, ...performanceWithIdPromises];

    BPromise.all(promises)
      .then(res => {
        return getSchoolEvent(user, eventId);
      })
      .then(event => {
        this.setState({
          isLoading: false,
          isEditPerformance: false,
          individualPerformance: event.results.individualPerformance,
          selectedItems: [event]
        });
      });
  };

  onEditEventPerformanceClick = () => {
    this.setState({
      isEditPerformance: true
    });
  };

  onChangeEventDisciplineClick = (value, player, disciplineId) => {
    const { individualDiscipline } = this.state;

    const disciplineIndex = individualDiscipline.findIndex(
      discipline => discipline.disciplineId === disciplineId && discipline.userId === player.userId
    );

    let nextDiscipline;

    if (disciplineIndex === -1) {
      nextDiscipline = [
        ...individualDiscipline,
        {
          disciplineId: disciplineId,
          userId: player.userId,
          teamId: player.teamId,
          value: Number(value),
          permissionId: player.permissionId
        }
      ];
    } else {
      nextDiscipline = [...individualDiscipline];
      nextDiscipline[disciplineIndex].value = Number(value);
    }

    this.setState({
      individualDiscipline: nextDiscipline
    });
  };

  onEditEventDisciplineClick = () => {
    this.setState({
      isEditDiscipline: true
    });
  };

  onSaveEventDisciplineClick = () => {
    const { individualDiscipline, selectedItems } = this.state;
    const { user } = this.props;
    const [schoolEvent] = selectedItems;
    const eventId = schoolEvent.id;

    this.setState({
      isLoading: true
    });

    const disciplineWithoutId = individualDiscipline.filter(discipline => typeof discipline._id === 'undefined');
    const disciplineWithId = individualDiscipline.filter(discipline => typeof discipline._id !== 'undefined');

    const disciplineWithoutIdPromises = disciplineWithoutId.map(data => createEventDiscipline(user, eventId, data));
    const disciplineWithIdPromises = disciplineWithId.map(discipline => {
      const updateDisciplineId = discipline._id;
      const value = discipline.value;

      return updateEventDiscipline(user, eventId, updateDisciplineId, { value });
    });

    const promises = [...disciplineWithoutIdPromises, ...disciplineWithIdPromises];

    BPromise.all(promises)
      .then(res => {
        return getSchoolEvent(user, eventId);
      })
      .then(event => {
        this.setState({
          isLoading: false,
          isEditDiscipline: false,
          individualDiscipline: event.results.individualDiscipline,
          selectedItems: [event]
        });
      });
  };

  onEditEventMatchReportClick = () => {
    this.setState({
      isEditMatchReportActive: false
    });
  };

  onSaveEventMatchReportClick = () => {
    const { user } = this.props;
    const { selectedItems, matchReport } = this.state;
    const [schoolEvent] = selectedItems;
    const eventId = schoolEvent.id;

    this.setState({
      isLoading: true,
      isEditMatchReportActive: true
    });

    const content = {
      content: matchReport
    };

    updateSchoolEventMatchReport(user, eventId, content)
      .then(matchReport => {
        const content = propz.get(matchReport, ['content'], '');

        this.setState({
          isLoading: false,
          matchReport: content
        });
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        console.error(error);
        this.setState({
          isLoading: false,
          errorMessage: errorText,
          isErrorModalOpen: true
        });
      });
  };

  onChangeMatchReport = matchReport => {
    this.setState({
      matchReport: matchReport
    });
  };

  getInitAttendancePlayers = attendancePlayers => {
    const { user } = this.props;
    const { activeSchoolId } = user;
    const { selectedItems } = this.state;
    const [schoolEvent] = selectedItems;
    const allTeamPlayers = getEventPlayers(schoolEvent, activeSchoolId);
    const nextAttendancePlayers = allTeamPlayers.map(player => {
      const attendanceIndex = attendancePlayers.findIndex(attendance => attendance.userId === player.userId);
      if (attendanceIndex === -1) {
        return {
          userId: player.userId,
          isAttendance: true,
          permissionId: player.permissionId
        };
      } else {
        const allAttendancePlayers = attendancePlayers.map(attendance => ({ ...attendance }));
        return allAttendancePlayers[attendanceIndex];
      }
    });
    return nextAttendancePlayers;
  };

  onEditEventAttendanceClick = () => {
    const { selectedItems } = this.state;
    const [schoolEvent] = selectedItems;
    const { results } = schoolEvent;
    const { attendance } = results;
    const attendancePlayers = attendance.map(player => ({ ...player }));

    const allAttendancePlayers = this.getInitAttendancePlayers(attendancePlayers);

    const isOneAttendancePresent = allAttendancePlayers.every(player => {
      return propz.get(player, ['isAttendance'], true);
    });

    this.setState({
      attendancePlayers: allAttendancePlayers,
      isEditAllAttendance: isOneAttendancePresent,
      isEditAttendance: true
    });
  };

  onCancelEventAttendanceClick = () => {
    const { selectedItems } = this.state;
    const [schoolEvent] = selectedItems;
    const attendance = schoolEvent.results.attendance;
    const attendancePlayers = attendance.map(player => ({ ...player }));

    this.setState({
      attendancePlayers: attendancePlayers,
      isEditAttendance: false
    });
  };

  onSaveEventAttendanceClick = () => {
    const { attendancePlayers, selectedItems } = this.state;
    const { user } = this.props;
    const [schoolEvent] = selectedItems;
    const eventId = schoolEvent.id;

    this.setState({
      isLoading: true
    });

    const attendanceWithoutId = attendancePlayers.filter(attendance => typeof attendance._id === 'undefined');
    const attendanceWithId = attendancePlayers.filter(attendance => typeof attendance._id !== 'undefined');

    const attendanceWithoutIdPromises = attendanceWithoutId.map(data => createEventAttendance(user, eventId, data));
    const attendanceWithIdPromises = attendanceWithId.map(attendance => {
      const updateAttendanceId = attendance._id;
      const isAttendance = attendance.isAttendance;

      return updateEventAttendance(user, eventId, updateAttendanceId, { isAttendance });
    });

    const promises = [...attendanceWithoutIdPromises, ...attendanceWithIdPromises];

    BPromise.all(promises)
      .then(res => {
        return getSchoolEvent(user, eventId);
      })
      .then(event => {
        this.setState({
          isLoading: false,
          isEditAttendance: false,
          attendancePlayers: event.results.attendance,
          selectedItems: [event]
        });
      });
  };

  getFilterOptions = (forms, sports) => {
    const user = this.props.user;
    const activeSchool = user.activeSchool;
    const ageGroupsNaming = activeSchool.ageGroupsNaming;

    return {
      venueType: getSelectOptionForEventVenueType(),
      eventAges: getSelectOptionForAge(forms, ageGroupsNaming),
      gender: getSelectOptionForTournamentEventGender(),
      status: getSelectOptionForTournamentEventStatus(),
      eventType: getSelectOptionForEventTypes(),
      eventSport: getSelectOptionForSport(sports),
      subtype: getSelectOptionsForSubTypeEvent()
    };
  };

  onChangeEventAllAttendanceClick = (value: boolean): void => {
    const { user } = this.props;
    const { activeSchoolId } = user;
    const { selectedItems, attendancePlayers } = this.state;
    const [schoolEvent] = selectedItems;
    const allTeamPlayers = getEventPlayers(schoolEvent, activeSchoolId);

    const nextAttendancePlayers = allTeamPlayers.map(player => {
      const attendanceIndex = attendancePlayers.findIndex(attendance => attendance.userId === player.userId);
      if (attendanceIndex === -1) {
        return {
          userId: player.userId,
          isAttendance: value,
          permissionId: player.permissionId
        };
      } else {
        const allAttendancePlayers = attendancePlayers.map(attendance => {
          return {
            ...attendance,
            isAttendance: value
          };
        });
        return allAttendancePlayers[attendanceIndex];
      }
    });
    this.setState({
      isEditAllAttendance: value,
      attendancePlayers: nextAttendancePlayers
    });
  };

  onChangeEventAttendanceClick = (
    value: boolean,
    player: SchoolEventIndividualData | SchoolEventTeamDataPlayer
  ): void => {
    const { attendancePlayers } = this.state;

    const attendanceIndex = attendancePlayers.findIndex(attendance => attendance.userId === player.userId);

    let nextAttendancePlayers;

    if (attendanceIndex === -1) {
      nextAttendancePlayers = [
        ...attendancePlayers,
        {
          userId: player.userId,
          isAttendance: value,
          permissionId: player.permissionId
        }
      ];
    } else {
      nextAttendancePlayers = attendancePlayers.map(attendance => {
        return attendance.userId === player.userId
          ? {
              ...attendance,
              isAttendance: value
            }
          : { ...attendance };
      });
    }

    const isOneAttendancePresent = nextAttendancePlayers.every(player => {
      return propz.get(player, ['isAttendance'], true);
    });

    this.setState({
      isEditAllAttendance: isOneAttendancePresent,
      attendancePlayers: nextAttendancePlayers
    });
  };

  getSummaryTabs(): React.ReactNode {
    const { user } = this.props;
    const { activeSchoolId } = user;
    const {
      selectedItems,
      eventsDetails,
      currentTabIndex,
      sports,
      isEditPositions,
      activeTeamIndex,
      playersForSummary,
      teamsForSummary,
      eventTasks,
      individualPerformance,
      isEditPerformance,
      isEditAttendance,
      attendancePlayers,
      matchReport,
      isEditMatchReportActive,
      isEditAllAttendance,
      individualDiscipline,
      isEditDiscipline
    } = this.state;
    const event = selectedItems[0];
    const currentSport = sports.find(sport => sport.id === event.sportId);
    const allTeamPlayers = getEventPlayers(event, activeSchoolId);

    const tabs = getEventTabs(event);
    const currentTab = tabs[currentTabIndex];

    switch (currentTab) {
      case TABS.SUMMARY:
        return <SchoolEventSummary schoolEvent={event} user={user} />;
      case TABS.DETAILS:
        return <SchoolEventDetailsSummary schoolEvent={event} eventsDetails={eventsDetails} user={user} />;
      case TABS.JOBS:
        return (
          <SchoolEventJobsSummary
            eventTasks={eventTasks}
            onEditEventJobClick={this.onEditEventJobClick}
            onDeleteJobClick={this.deleteJobModalOpen}
          />
        );
      case isClubEvent(event) ? TABS.PROGRESS : TABS.PERFORMANCE:
        return (
          <SchoolEventPerformanceSummary
            schoolEvent={event}
            allTeamPlayers={allTeamPlayers}
            onEditEventPerformanceClick={this.onChangeEventPerformanceClick}
            individualPerformance={individualPerformance}
            isEditPerformance={isEditPerformance}
          />
        );
      case TABS.DISCIPLINE:
        return (
          <SchoolEventDisciplineSummary
            schoolEvent={event}
            allTeamPlayers={allTeamPlayers}
            onEditEventDisciplineClick={this.onChangeEventDisciplineClick}
            individualDiscipline={individualDiscipline}
            isEditDiscipline={isEditDiscipline}
          />
        );
      case TABS.ATTENDANCE:
        return (
          <SchoolEventAttendanceSummary
            schoolEvent={event}
            allTeamPlayers={allTeamPlayers}
            onChangeEventAttendanceClick={this.onChangeEventAttendanceClick}
            onChangeEventAllAttendanceClick={this.onChangeEventAllAttendanceClick}
            attendancePlayers={attendancePlayers}
            isEditAttendance={isEditAttendance}
            isEditAllAttendance={isEditAllAttendance}
          />
        );
      case TABS.PARTICIPANTS:
        return (
          <SchoolEventPlayersSummary
            schoolEvent={event}
            sport={currentSport}
            players={playersForSummary}
            teams={teamsForSummary}
            activeTeamIndex={activeTeamIndex}
            isEditPositions={isEditPositions}
            onChangeTeam={this.onChangeTeam}
            onChangePositionPlayer={this.onChangePositionPlayer}
            onChangeIsCaptainPlayer={this.onChangeIsCaptainPlayer}
            onChangeIsSubPlayer={this.onChangeIsSubPlayer}
          />
        );
      case isClubEvent(event) ? TABS.LEADER_COACH_NOTES : TABS.MATCH_REPORT:
        return (
          <SchoolEventMatchReportSummary
            isEditMatchReportActive={isEditMatchReportActive}
            schoolEvent={event}
            matchReport={matchReport}
            onChangeMatchReport={this.onChangeMatchReport}
          />
        );
      default:
        return <EmptyComponent />;
    }
  }

  renderSummary() {
    const {
      selectedItems,
      currentTabIndex,
      isEditPositions,
      isEditPerformance,
      isEditDiscipline,
      isEditAttendance,
      isEditMatchReportActive
    } = this.state;
    const { user } = this.props;
    const { activeSchoolId } = user;
    const isOneItemSelected = selectedItems.length === 1;

    if (!isOneItemSelected) {
      return <div className="font-weight-bold">Select a event above</div>;
    }
    const event = selectedItems[0];
    const players = getEventPlayers(event, activeSchoolId);

    const summaryTabs = this.getSummaryTabs();

    const tabs = getEventTabs(event);
    const currentTab = tabs[currentTabIndex];

    const isShowEditDetailsButton = currentTab === TABS.DETAILS;
    const isShowAddJobButton = currentTab === TABS.JOBS;
    const isShowEditPositionsButton = !isEditPositions && !isClubEvent(event) && currentTab === TABS.PARTICIPANTS;
    const isShowSavePositionsButton = isEditPositions && !isClubEvent(event) && currentTab === TABS.PARTICIPANTS;
    const isPlayersExist = players.length > 0;

    const isTabProgress = currentTab === TABS.PROGRESS;
    const isTabPerformance = currentTab === TABS.PERFORMANCE;
    const isTabProgressOrPerformance = isTabProgress || isTabPerformance;
    const isShowEditPerformanceButton = !isEditPerformance && isTabProgressOrPerformance;
    const isShowSavePerformanceButton = isEditPerformance && isTabProgressOrPerformance;

    const isShowEditDisciplineButton = !isEditDiscipline && currentTab === TABS.DISCIPLINE;
    const isShowSaveDisciplineButton = isEditDiscipline && currentTab === TABS.DISCIPLINE;

    const isShowEditAttendanceButton = !isEditAttendance && currentTab === TABS.ATTENDANCE;
    const isShowSaveAttendanceButton = isEditAttendance && currentTab === TABS.ATTENDANCE;

    const isTabLeaderAndCoach = currentTab === TABS.LEADER_COACH_NOTES;
    const isTabMatchReport = currentTab === TABS.MATCH_REPORT;
    const isTabbLeaderCoachOrMatchReport = isTabLeaderAndCoach || isTabMatchReport;
    const isShowEditMatchReportButton = isEditMatchReportActive && isTabbLeaderCoachOrMatchReport;
    const isShowSaveMatchReportButton = !isEditMatchReportActive && isTabbLeaderCoachOrMatchReport;

    return (
      <GenericSummary onTabClick={this.onTabClick} tabs={tabs} currentTabIndex={currentTabIndex}>
        {isShowEditPositionsButton && (
          <Button
            onClick={this.onEditPositionsClick}
            text={'Edit positions'}
            customClass={'mt-3 mb-3 ml-3'}
            disabled={!isPlayersExist}
          />
        )}
        {isShowSavePositionsButton && (
          <Button
            onClick={this.onSavePositionsClick}
            text={'Save positions'}
            customClass={'mt-3 mb-3 ml-3'}
            disabled={!isPlayersExist}
          />
        )}
        {isShowEditDetailsButton && (
          <Button onClick={this.editEventDetailsModalOpen} text={'Edit details'} customClass={'mt-3 mb-3 ml-3'} />
        )}
        {isShowAddJobButton && (
          <Button onClick={this.onAddEventJobClick} text={'Add job'} customClass={'mt-3 mb-3 ml-3'} />
        )}
        {isShowEditPerformanceButton && (
          <Button
            onClick={this.onEditEventPerformanceClick}
            text={isClubEvent(event) ? 'Edit progress' : 'Edit performance'}
            customClass={'mt-3 mb-3 ml-3'}
            disabled={!isPlayersExist}
          />
        )}
        {isShowSavePerformanceButton && (
          <Button
            onClick={this.onSaveEventPerformanceClick}
            text={isClubEvent(event) ? 'Save progress' : 'Save performance'}
            customClass={'mt-3 mb-3 ml-3'}
          />
        )}
        {isShowEditDisciplineButton && (
          <Button
            onClick={this.onEditEventDisciplineClick}
            text={'Edit discipline'}
            customClass={'mt-3 mb-3 ml-3'}
            disabled={!isPlayersExist}
          />
        )}
        {isShowSaveDisciplineButton && (
          <Button
            onClick={this.onSaveEventDisciplineClick}
            text={'Save discipline'}
            customClass={'mt-3 mb-3 ml-3'}
            disabled={!isPlayersExist}
          />
        )}
        {isShowEditAttendanceButton && (
          <Button
            onClick={this.onEditEventAttendanceClick}
            text={'Edit attendance'}
            customClass={'mt-3 mb-3 ml-3'}
            disabled={!isPlayersExist}
          />
        )}
        {isShowSaveAttendanceButton && (
          <>
            <Button
              onClick={this.onCancelEventAttendanceClick}
              text={'Cancel'}
              customClass={'mt-3 mb-3 ml-3 btn-secondary'}
            />
            <Button onClick={this.onSaveEventAttendanceClick} text={'Save'} customClass={'mt-3 mb-3 ml-3'} />
          </>
        )}
        {isShowEditMatchReportButton && (
          <Button
            onClick={this.onEditEventMatchReportClick}
            text={isClubEvent(event) ? 'Edit Leader/Coach notes' : 'Edit match report'}
            customClass={'mt-3 mb-3 ml-3'}
            disabled={!isEditMatchReportActive}
          />
        )}
        {isShowSaveMatchReportButton && (
          <Button
            onClick={this.onSaveEventMatchReportClick}
            text={isClubEvent(event) ? 'Save Leader/Coach notes' : 'Save match report'}
            customClass={'mt-3 mb-3 ml-3'}
          />
        )}
        {summaryTabs}
      </GenericSummary>
    );
  }

  renderGrid() {
    const { user } = this.props;
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isSelectAllChecked,
      currentPage,
      total,
      filterOptions,
      isEditPerformance,
      isEditAttendance,
      isEditPositions,
      isEditDiscipline
    } = this.state;

    const isCancelEventActionAvailable = selectedItems.every(
      item =>
        item.status === EVENT_STATUS.ACCEPTED ||
        item.status === EVENT_STATUS.COLLECTING_INVITE_RESPONSE ||
        item.status === EVENT_STATUS.DRAFT ||
        item.status === EVENT_STATUS.INVITES_SENT ||
        item.status === EVENT_STATUS.ON_HOLD ||
        item.status === EVENT_STATUS.SENDING_INVITES
    );

    const isUserTeacherOrCoach = isUserTeacher(user) || isUserCoach(user);

    const isDeleteEventActionAvailable = selectedItems.every(
      item => item.status === EVENT_STATUS.CANCELED || item.status === EVENT_STATUS.REJECTED
    );

    const actionItems = [
      {
        itemText: 'Create event',
        onItemClick: this.onCreateEventClick,
        isActive: selectedItems.length === 0
      },
      {
        itemText: 'Cancel event',
        onItemClick: this.cancelEventModalOpen,
        isActive: selectedItems.length > 0 && isCancelEventActionAvailable
      },
      {
        itemText: 'Delete event',
        onItemClick: this.deleteEventModalOpen,
        isActive: selectedItems.length > 0 && isDeleteEventActionAvailable
      },
      {
        itemText: 'Reports',
        isActive: true,
        subItems: [
          {
            itemText: 'Fixture schedule',
            onItemClick: this.openFixturesScheduleReportForm,
            isActive: selectedItems.length == 0
          },
          !isUserTeacherOrCoach && {
            itemText: 'Fixture participation by students',
            onItemClick: this.openFixtureParticipationByStudentsReportForm,
            isActive: selectedItems.length == 0
          },
          {
            itemText: 'Fixture statistics',
            onItemClick: this.openFixtureStatisticsForPeriodReportForm,
            isActive: selectedItems.length == 0
          },
          {
            itemText: 'Fixture results',
            onItemClick: this.openFixtureResultsReportForm,
            isActive: selectedItems.length == 0
          },
          {
            itemText: 'Fixture list for the selected date',
            onItemClick: this.openDateFixturesReportForm,
            isActive: selectedItems.length == 0
          },
          {
            itemText: 'Year-on-year fixtures history',
            onItemClick: this.openFixtureHistoryReportForm,
            isActive: selectedItems.length == 0
          }
        ]
      }
    ];

    return (
      <div className="row">
        <div className="col-md-12">
          <Grid2
            dataItems={items}
            filters={filters}
            currentPage={currentPage}
            total={total}
            isSelectAllChecked={isSelectAllChecked}
            isDataFiltered={isDataFiltered}
            sortDirection={sortDirection}
            sortColumnsName={sortColumnsName}
            isShowFilter={isShowFilter}
            dataItemsSelected={selectedItems}
            columns={COLUMNS}
            actionItems={actionItems}
            options={filterOptions}
            onItemClick={this.onItemClick}
            onSortClick={this.onTableSortClick}
            onApplyFilterClick={this.onApplyFilterClick}
            onClearFilterClick={this.onClearFilterClick}
            onTableFilterChange={this.onTableFilterChange}
            onTableFilterClick={this.onTableFilterClick}
            setCurrentPageParams={this.setCurrentPageParams}
            onSelectAllClick={this.onSelectAllClick}
            onSelectAllOnPageClick={this.onSelectAllOnPageClick}
            onUnselectAllClick={this.onUnselectAllClick}
            user={user}
            isSelectDisabled={isEditPerformance || isEditAttendance || isEditPositions || isEditDiscipline}
          />
        </div>
      </div>
    );
  }

  render() {
    const {
      isErrorModalOpen,
      isCreateEventModalOpen,
      isEditEventDetailsModalOpen,
      isCreateEventJobModalOpen,
      isFixturesScheduleReportModalOpen,
      isFixtureParticipationByStudentsReportModalOpen,
      isFixtureResultsReportModalOpen,
      isDateFixturesReportModalOpen,
      isFixtureHistoryReportModalOpen,
      isFixtureStatisticsForPeriodReportModalOpen,
      isLoading
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes =
      isErrorModalOpen ||
      isCreateEventModalOpen ||
      isEditEventDetailsModalOpen ||
      isCreateEventJobModalOpen ||
      isFixturesScheduleReportModalOpen ||
      isFixtureParticipationByStudentsReportModalOpen ||
      isFixtureResultsReportModalOpen ||
      isDateFixturesReportModalOpen ||
      isFixtureHistoryReportModalOpen ||
      isFixtureStatisticsForPeriodReportModalOpen
        ? 'mt-3 modal-open'
        : 'mt-3';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderEventJobForm()}
        {this.renderDeleteJobModal()}
        {this.renderCancelEventModal()}
        {this.renderDeleteEventModal()}
        {this.renderConfirmationDeleteEventModal()}
        {this.renderEventDetailsForm()}
        {this.renderSendingError()}
        {this.renderFixturesScheduleReportFormModal()}
        {this.renderFixtureParticipationByStudentsReportFormModal()}
        {this.renderFixtureResultsReportFormModal()}
        {this.renderDateFixturesReportFormModal()}
        {this.renderFixtureHistoryReportFormModal()}
        {this.renderFixtureStatisticsForPeriodReportFormModal()}
        <div className="row">
          <div className="col-md-12">
            <ResizablePanel>
              {this.renderGrid()}
              {this.renderSummary()}
            </ResizablePanel>
          </div>
        </div>
      </div>
    );
  }
}
