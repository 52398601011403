import * as React from 'react';
import { Route, Switch } from 'react-router';
import { SchoolSettings } from '../SchoolSettings';
import { EditSchoolSettings } from '../EditSchoolSettings/EditSchoolSettings';
import { SchoolSettingsAdvanced } from '../../SchoolSettingsAdvanced/SchoolSettingsAdvanced';

export const SchoolSettingsRouting = allProps => {
  return (
    <Switch>
      <Route
        exact
        path={'/school/settings/base'}
        render={props => <SchoolSettings {...props} user={allProps.user} />}
      />
      <Route
        exact
        path={'/school/settings/base/edit'}
        render={props => (
          <EditSchoolSettings {...props} user={allProps.user} onSchoolUpdate={allProps.onSchoolUpdate} />
        )}
      />
      <Route
        exact
        path={'/school/settings/advanced'}
        render={props => (
          <SchoolSettingsAdvanced {...props} user={allProps.user} onSchoolUpdate={allProps.onSchoolUpdate} />
        )}
      />
    </Switch>
  );
};

SchoolSettingsRouting.displayName = 'SchoolSettingsRouting';
