import * as React from 'react';
import { FunctionComponent } from 'react';

export const TriangleDown: FunctionComponent = () => {
  return (
    <div>
      <svg width="10px" height="10px" viewBox="0 0 404.308 404.309">
        <g xmlns="http://www.w3.org/2000/svg">
          <path d="M0,101.08h404.308L202.151,303.229L0,101.08z" fill="#007abf" />
        </g>
      </svg>
    </div>
  );
};

TriangleDown.displayName = 'Triangle_point_to_down';
