import * as React from 'react';
import './PreviewVideo.scss';
import { NO_VIDEO } from '../../../../../../../consts/common';
import * as Hls from 'hls.js';
import { ACCESS_PRESET_SERVER_TO_CLIENT_MAPPING } from '../../../../../../../consts/album';
import { getAccessPresetColor } from '../../../../../../../helpers/event/eventPhotoAndVideo';
import { ACCESS_PRESET_TYPE } from '../../../../../../../types/album';
import { ACCESS_PRESET } from 'Src/consts/album';

interface Props {
  id: string;
  url: string;
  accessPreset: ACCESS_PRESET_TYPE;
  onDeleteVideoClick: (id: string) => void;
  onVideoClick: (id: string, isVideoLoading: boolean) => void;
}
interface State {}

export class PreviewVideo extends React.Component<Props, State> {
  videoFileRef: any;

  constructor(props) {
    super(props);

    this.videoFileRef = React.createRef();
  }

  componentDidMount() {
    const { url } = this.props;
    const isUrlExist = typeof url !== 'undefined';

    if (isUrlExist) {
      const hls = new Hls();
      hls.loadSource(url);
      hls.attachMedia(this.videoFileRef);
    }
  }

  onDeleteVideo = event => {
    const { onDeleteVideoClick, id } = this.props;
    onDeleteVideoClick(id);
    event.stopPropagation();
  };

  onVideoPreviewClick = (event, isVideoLoading) => {
    const { onVideoClick, id } = this.props;
    onVideoClick(id, isVideoLoading);
    event.stopPropagation();
  };

  getAccessPresetColorClass = accessPreset => {
    switch (accessPreset) {
      case ACCESS_PRESET.PRIVATE:
        return 'mPreviewPhotoAccessPresetPrivate';
      case ACCESS_PRESET.PUBLIC:
        return 'mPreviewPhotoAccessPresetPublic';
      case ACCESS_PRESET.ALL_PARTICIPANT_PARENTS:
        return 'mPreviewPhotoAccessPresetAll';
    }
  };

  render() {
    const { id, onVideoClick, url, accessPreset } = this.props;

    const isUrlExist = typeof url !== 'undefined';
    const accessPresetColorClass = this.getAccessPresetColorClass(accessPreset);

    return isUrlExist ? (
      <div className="bPreviewVideo" onClick={event => this.onVideoPreviewClick(event, false)}>
        <video controls={false} ref={input => (this.videoFileRef = input)} />
        <div className="ePreviewVideoActions" onClick={this.onDeleteVideo}>
          <span>Delete</span>
        </div>
        <div className={`ePreviewPhotoAccessPreset ${accessPresetColorClass}`}>
          <span>{ACCESS_PRESET_SERVER_TO_CLIENT_MAPPING[accessPreset]}</span>
        </div>
      </div>
    ) : (
      <div
        className="bPreviewVideo"
        onClick={event => this.onVideoPreviewClick(event, true)}
        style={{ backgroundImage: `url(${NO_VIDEO})` }}
      >
        <div className="ePreviewVideoActions" onClick={this.onDeleteVideo}>
          <span>Delete</span>
        </div>
        <div className={`ePreviewPhotoAccessPreset ${accessPresetColorClass}`}>
          <span>{ACCESS_PRESET_SERVER_TO_CLIENT_MAPPING[accessPreset]}</span>
        </div>
      </div>
    );
  }
}
