import * as React from 'react';
import { Component } from 'react';
import './Sidebar.scss';
import { AppUser } from 'Src/views/App/App';
import {
  isUserAdmin,
  isUserAdminSU,
  isUserCoach,
  isUserManager,
  isUserParent,
  isUserTeacher,
  isUserStudent
} from '../../helpers/user/user';
import { SidebarItem } from './SidebarItem';
import { SidebarItems } from './SidebarItems';
import {
  getSidebarItemsAdmin,
  getSidebarItemsAdminSU,
  getSidebarItemsCoach,
  getSidebarItemsParent,
  getSidebarItemsTeacher,
  getSidebarItemsStudent,
  getSidebarItemsManager
} from '../../consts/sidebar';
import { SCHOOL_SUBSCRIPTION_TYPE } from '../../types/school';
import { BurgerButton } from 'Src/components/BurgerButton/BurgerButton';
import { WINDOW_BREAKPOINT_FOR_SIDEBAR } from 'Src/consts/responsive';

interface Props {
  user: AppUser;
  needUpdate: boolean;
  unsetNeedUpdate: () => void;
  onSidebarCloseClick: () => void;
  windowWidth: number;
  countBadges: {
    requests: string;
    bookings: string;
    inboxMessages: string;
    invites: string;
    waitingList: string;
    upcomingTournaments: string;
    availabilityReports: string;
    absenceRequests: string;
  };
}

export interface SidebarItemExpandable {
  title: string;
  items: SimpleSidebarItem[];
}

export interface SimpleSidebarItem {
  to: {
    pathname: string;
    search?: string;
  };
  itemTitle: string;
  visibleToSchoolSubscriptions: SCHOOL_SUBSCRIPTION_TYPE[];
  notificationCount?: string;
}

export const Sidebar = (props: Props) => {
  const { user, countBadges, onSidebarCloseClick, windowWidth } = props;

  const getSidebarItems = (): (SidebarItemExpandable | SimpleSidebarItem)[] => {
    switch (true) {
      case isUserParent(user):
        return getSidebarItemsParent(countBadges);
      case isUserCoach(user):
        return getSidebarItemsCoach(countBadges);
      case isUserTeacher(user):
        return getSidebarItemsTeacher(countBadges);
      case isUserAdminSU(user):
        return getSidebarItemsAdminSU();
      case isUserStudent(user):
        return getSidebarItemsStudent(countBadges);
      case isUserManager(user):
        return getSidebarItemsManager(countBadges);
      default:
        return getSidebarItemsAdmin(countBadges);
    }
  };

  const isSidebarItemExpandable = (arg: any): arg is SidebarItemExpandable => {
    return Array.isArray(arg.items);
  };

  const sidebarItems: (SidebarItemExpandable | SimpleSidebarItem)[] = getSidebarItems();

  return (
    <>
      <div className="bSidebar">
        {windowWidth < WINDOW_BREAKPOINT_FOR_SIDEBAR && (
          <div className="d-flex justify-content-end mb-2">
            <BurgerButton onClick={onSidebarCloseClick} />
          </div>
        )}
        {sidebarItems.map((sidebarItem, index) => {
          if (isSidebarItemExpandable(sidebarItem)) {
            return <SidebarItems key={`sidebar_items_${index}`} item={sidebarItem} user={user} />;
          } else {
            return <SidebarItem key={`sidebar_item_${index}`} item={sidebarItem} user={user} />;
          }
        })}
      </div>
      <div className="bSidebarOverlay" onClick={onSidebarCloseClick}></div>
    </>
  );
};

Sidebar.displayName = 'Sidebar';
