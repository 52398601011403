import { AppUser } from 'Src/views/App/App';
import axios from 'axios';
import { DEFAULT_FILTER } from './user';
import { ConfirmationRequestTemplateField } from 'Src/models/confirmationRequestTemplate';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from 'Src/consts/table';
import { ConfirmationRequestTemplate } from '../../../models/confirmationRequestTemplate';
import { Count } from '../../../models/count';

export function getClubStudents(user: AppUser, clubId: string, filter = DEFAULT_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter
    }
  };

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/clubs/${clubId}/acceptableUsers`, config)
    .then(response => {
      return response.data;
    });
}

export function getAllClubStudents(user: AppUser, clubId: string, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/clubs/${clubId}/acceptableUsers`, config)
    .then(response => {
      return response.data;
    });
}

export function getClubStudentsCount(user: AppUser, clubId: string, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where
        }
      }
    };
  } else {
    config = {
      headers: { usid: key }
    };
  }

  return axios
    .get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/clubs/${clubId}/acceptableUsers/count`, config)
    .then(response => {
      return response.data;
    });
}

export function addClubStudents(
  user: AppUser,
  clubId: string,
  data: { participants: { userId: string; permissionId: string }[] }
) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/clubs/${clubId}/participants/batch`, data, config)
    .then(response => {
      return response.data;
    });
}

export function removeClubStudents(user: AppUser, clubId: string, participantId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .delete(`${window.apiBase}/i/schools/${activeSchoolId}/clubs/${clubId}/participants/${participantId}`, config)
    .then(response => {
      return response.data;
    });
}

export function getClubConfirmationRequestTemplate(user: AppUser): Promise<ConfirmationRequestTemplate> {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .get<ConfirmationRequestTemplate>(
      `${window.apiBase}/i/schools/${activeSchoolId}/clubConfirmationRequestTemplate`,
      config
    )
    .then(response => {
      return response.data;
    });
}

export function updateClubConfirmationRequestTemplate(
  user: AppUser,
  data: { fields: ConfirmationRequestTemplateField[] }
): Promise<ConfirmationRequestTemplate> {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .put(`${window.apiBase}/i/schools/${activeSchoolId}/clubConfirmationRequestTemplate`, data, config)
    .then(response => {
      return response.data;
    });
}

export function updateClubMessage(
  user: AppUser,
  clubId: string,
  messageId: string,
  data: { invitationStatus: string; priority?: number }
) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .put(`${window.apiBase}/i/schools/${activeSchoolId}/clubs/${clubId}/messages/${messageId}`, data, config)
    .then(response => {
      return response.data;
    });
}
