import * as React from 'react';
import { Field, Form, Formik } from 'formik';
import { Tournament } from 'Src/models/tournament';
import { Button } from 'Src/components/Button/Button';
import { Switch } from 'Src/components/Switch/Switch';

interface Props {
  tournament: Tournament;
  onCancel: () => void;
  onSubmit: (data: any) => void;
}

export const TournamentEditTournamentTVForm: React.FunctionComponent<Props> = props => {
  const { tournament } = props;
  const stages = tournament.tournamentLayout || [];

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik
            initialValues={{ publicBigscreenStagesEnabled: tournament.publicBigscreenStagesEnabled }}
            validationSchema={''}
            onSubmit={values => {
              props.onSubmit(values);
            }}
            render={({ setFieldValue, values }) => (
              <Form>
                {stages.map((stage, index) => {
                  const isActive = values.publicBigscreenStagesEnabled[stage.type];
                  return (
                    <div key={'tournamentTVSetting_' + index} className="form-group">
                      <Field
                        name="isTournamentTVSetting"
                        render={({ field }) => (
                          <Switch
                            {...field}
                            value={isActive}
                            onChange={event => {
                              const value = event.target.checked;
                              let publicBigscreenStagesEnabled = { ...values.publicBigscreenStagesEnabled };
                              publicBigscreenStagesEnabled[stage.type] = value;
                              setFieldValue('publicBigscreenStagesEnabled', publicBigscreenStagesEnabled);
                            }}
                            name={index}
                            text={stage.name}
                            customClass="mb-3"
                          />
                        )}
                      />
                    </div>
                  );
                })}
                <Button onClick={props.onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                <button type="submit" className="mt-3 mb-3 btn btn-primary">
                  Save
                </button>
              </Form>
            )}
          />
        </div>
      </div>
    </div>
  );
};

TournamentEditTournamentTVForm.displayName = 'TournamentEditTournamentTVForm';
