import * as React from 'react';
import * as Lazy from 'lazy.js';
import { Autocomplete } from 'Src/components/Autocomplete/Autocomplete';
import { getNameAndAges, searchFunctionSchoolTeams } from 'Src/helpers/autocomplete/autocomplete';
import { AppUser } from 'Src/views/App/App';
import { Loader } from 'Src/components/Loader/Loader';
import { SchoolEventAddExistingTeamPlayers } from './SchoolEventAddExistingTeamPlayers';
import { Button } from 'Src/components/Button/Button';
import { TEAM_TYPE } from 'Src/consts/team';
import * as Moment from 'moment';
import * as propz from 'propz';
import { SPORT_GENDER_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/common';
import {
  getEventClash,
  getEventTitle,
  getPrototypeTeamPlayersMethod,
  getSchoolEventAges
} from 'Src/helpers/event/event';
import { getEventType } from 'Src/helpers/accessor/accessor';
import { LabelWithQuestionIcon } from 'Src/components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import { SchoolEventFromForm } from '../SchoolEventManager';
import { Team, TeamPlayerWithClashes } from 'Src/models/team';
import { SchoolEvent } from 'Src/models/event';
import { getSchoolEvent } from 'Src/helpers/service/admin/event';
import * as BPromise from 'bluebird';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';

interface Props {
  user: AppUser;
  currentEvent: SchoolEventFromForm;
  blackListTeamIds: string[];
  onAddExistingTeamClick: (team: any) => void;
  onAddNewTeamClick: (team: any) => void;
  onSaveClick: (team: any, isCreateAnotherEvent: boolean) => void;
  isSaveButtonDisabled: boolean;
  isAddTeamButtonDisabled: boolean;
  teams: Team[];
  goBack: () => void;
}

interface State {
  team: Team;
  players: TeamPlayerWithClashes[];
  isLoading: boolean;
  isError: boolean;
  playerEventsModalText: string;
  isPlayerEventsModalOpen: boolean;
}

export class SchoolEventAddExistingTeam extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      team: undefined,
      isLoading: false,
      players: [],
      isError: false,
      playerEventsModalText: '',
      isPlayerEventsModalOpen: false
    };
  }

  onSelectTeam = team => {
    const { user, currentEvent } = this.props;
    this.setState({
      isLoading: true
    });

    const { startTime, endTime } = currentEvent;
    const { id } = team;

    let where = {};

    // copy-paste from old frontend
    const startTimeCopy = new Date(startTime);
    startTimeCopy.setHours(startTime.getHours() - 1);
    const intervalClashStartDate = Moment(startTimeCopy)
      .utc()
      .format();

    const endTimeCopy = new Date(endTime);
    endTimeCopy.setHours(endTime.getHours() + 1);
    const intervalClashEndDate = Moment(endTimeCopy)
      .utc()
      .format();

    propz.set(where, ['intervalClashStartDate'], intervalClashStartDate);
    propz.set(where, ['intervalClashEndDate'], intervalClashEndDate);
    propz.set(where, ['fullClashStartDate'], startTime);
    propz.set(where, ['fullClashEndDate'], endTime);

    getPrototypeTeamPlayersMethod(user)(user, id, { where }).then(players => {
      this.setState({
        team,
        players,
        isLoading: false,
        isError: false
      });
    });
  };

  getSchoolTeams = (text): Promise<Team[]> => {
    const { user, currentEvent, blackListTeamIds, teams } = this.props;

    const isTeamsExist = teams.length > 0;
    const { ages, gender, sportId, houses } = currentEvent;

    const housesFiltered = houses.filter(house => teams.every(team => team.houseId !== house.id));

    const options = {
      teamIds: blackListTeamIds.filter(teamId => typeof teamId !== 'undefined'),
      text,
      ages,
      gender,
      sportId,
      houses: isTeamsExist ? housesFiltered : houses
    };

    return searchFunctionSchoolTeams(user, options);
  };

  onAddExistingTeamButtonClick = () => {
    const { team } = this.state;
    const { onAddExistingTeamClick } = this.props;
    const isTeamExist = typeof team !== 'undefined';

    if (isTeamExist) {
      this.setState({
        team: undefined,
        players: []
      });

      onAddExistingTeamClick({ ...team, cloneOf: team.id, teamType: TEAM_TYPE.CLONE });
    } else {
      this.setState({
        isError: true
      });
    }
  };

  onAddNewTeamButtonClick = () => {
    const { team } = this.state;
    const { onAddNewTeamClick } = this.props;
    const isTeamExist = typeof team !== 'undefined';

    if (isTeamExist) {
      onAddNewTeamClick({ ...team, cloneOf: team.id, teamType: TEAM_TYPE.CLONE });
    } else {
      this.setState({
        isError: true
      });
    }
  };

  onSaveButtonClick = isCreateAnotherEvent => {
    const { team } = this.state;
    const { onSaveClick } = this.props;
    const isTeamExist = typeof team !== 'undefined';

    if (isTeamExist) {
      onSaveClick({ ...team, cloneOf: team.id, teamType: TEAM_TYPE.CLONE }, isCreateAnotherEvent);
    } else {
      this.setState({
        isError: true
      });
    }
  };

  onClashClick = (player: TeamPlayerWithClashes) => {
    const { user } = this.props;
    const { clashes, firstName, lastName } = player;
    const { fullClashEvents, intervalClashEvents } = clashes;
    const fullClashEventIds = fullClashEvents.map(fullClashEvent => fullClashEvent._id);
    const intervalClashEventIds = intervalClashEvents.map(intervalClashEvent => intervalClashEvent._id);

    const allPlayerEventIds = [...fullClashEventIds, ...intervalClashEventIds];
    const allPlayerEventIdsUniq = Lazy(allPlayerEventIds)
      .uniq()
      .toArray();

    this.setState({
      isLoading: true
    });

    const eventsPromise: Promise<SchoolEvent>[] = allPlayerEventIdsUniq.map(eventId => getSchoolEvent(user, eventId));

    BPromise.all(eventsPromise).then(events => {
      const eventNames = events
        .map((event, index) => {
          const title = getEventTitle(event, user);
          const clash = getEventClash(event, fullClashEventIds, intervalClashEventIds);
          return `${index + 1}. ${title} ${clash}`;
        })
        .join('\n ');

      this.setState({
        isLoading: false,
        playerEventsModalText: `${firstName} ${lastName} is participating in the following events: \n${eventNames}`,
        isPlayerEventsModalOpen: true
      });
    });
  };

  onClosePlayerEventsModalClick = () => {
    this.setState({
      isPlayerEventsModalOpen: false
    });
  };

  renderPlayerEventsModal(): React.ReactNode {
    const { isPlayerEventsModalOpen, playerEventsModalText } = this.state;
    return (
      <SimpleModal
        isOpen={isPlayerEventsModalOpen}
        title={'Info'}
        body={playerEventsModalText}
        buttonText={'Ok'}
        onButtonClick={this.onClosePlayerEventsModalClick}
      />
    );
  }

  render() {
    const { team, isLoading, players, isError, isPlayerEventsModalOpen } = this.state;
    const { isSaveButtonDisabled, isAddTeamButtonDisabled, currentEvent, user, goBack } = this.props;

    const eventStartDateFormatted = Moment(currentEvent.startTime).format('D MMMM YYYY HH:mm');
    const eventSportName = propz.get(currentEvent, ['sport', 'name'], '');
    const eventGender = SPORT_GENDER_SERVER_TO_CLIENT_MAPPING[currentEvent.gender];
    const eventAges = getSchoolEventAges(currentEvent, user);
    const eventStatus = getEventType(currentEvent);

    if (isLoading) {
      return <Loader />;
    }

    const { activeSchool } = user;
    const { isShowStudentEventClashes } = activeSchool;

    const classes = isPlayerEventsModalOpen ? 'mt-3 modal-open' : 'mt-3';

    return (
      <div className={classes}>
        {this.renderPlayerEventsModal()}
        <div className="mt-3 bEventInformation">
          <div>{eventStartDateFormatted}</div>
          <LabelWithQuestionIcon
            labelText={`${eventSportName} ${eventGender} ${eventAges} ${eventStatus}`}
            hintText={
              'Here you can see basic event settings as you set them up at the previous step. ' +
              'If you would like to correct something you can select the "GO BACK" option.'
            }
            customClass="mt-3"
          />
        </div>
        <LabelWithQuestionIcon
          labelText="Within this section you can select one of your schools existing teams"
          hintText={
            'The teams you are able to select will have to match the criteria of the event you have created (Age, Gender).' +
            ' For house events the teams will automatically be filtered by participating houses. You are able to edit team members ' +
            'after you have created the event. If you have changed your mind and would rather select participants from scratch use ' +
            'the "GO BACK" option and select the "ADD NEW TEAM" option at the previous step.'
          }
          customClass="mt-3"
        />
        {isError && <div className="mt-3 alert alert-danger">Select team</div>}
        <LabelWithQuestionIcon
          labelText="Team name:"
          hintText={
            'Start typing the team name and select the team you need. If you cannot see the team' +
            ' you are looking for check your event settings and correct them if necessary by going back' +
            ' to the previous step.'
          }
          customClass="mt-3"
        />

        <Autocomplete
          searchFunction={this.getSchoolTeams}
          getElementTitle={getNameAndAges}
          onSelect={this.onSelectTeam}
          defaultItem={team}
          placeholder={'Start typing the team name'}
        />
        <div className={'mt-3'}>
          <SchoolEventAddExistingTeamPlayers
            onClashClick={this.onClashClick}
            isShowStudentEventClashes={isShowStudentEventClashes}
            players={players}
          />
        </div>

        <div className="col-md-12 pl-0">
          <div>
            The list of available options you can continue with depends on your event settings. For events allowing
            this, you can add more teams either from your list of pre-created teams or through adding a team from
            scratch. If the "SAVE & ADD ANOTHER EXISTING TEAM" AND "SAVE & ANOTHER NEW TEAM" options are greyed out then
            it means you have limited the number of teams that can be included within the event, e.g you have not
            selected 'Multiple teams available' when creating the event details.
          </div>
        </div>

        <Button onClick={goBack} text={'Go back'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
        <Button
          onClick={this.onAddExistingTeamButtonClick}
          text={'SAVE & ADD ANOTHER EXISTING TEAM'}
          customClass={'mt-3 mb-3 mr-3 btn btn-secondary'}
          disabled={isAddTeamButtonDisabled}
        />

        <Button
          onClick={this.onAddNewTeamButtonClick}
          text={'SAVE & ADD ANOTHER NEW TEAM'}
          customClass={'mt-3 mb-3 mr-3 btn btn-secondary'}
          disabled={isAddTeamButtonDisabled}
        />

        <Button
          onClick={() => this.onSaveButtonClick(true)}
          text={'SAVE & ADD ANOTHER EVENT'}
          customClass={'mt-3 mb-3 mr-3 btn btn-secondary'}
          disabled={isSaveButtonDisabled}
        />

        <Button
          disabled={isSaveButtonDisabled}
          onClick={() => this.onSaveButtonClick(false)}
          text={'CREATE EVENT'}
          customClass={'mt-3 mb-3 mr-3 btn btn-primary'}
        />
      </div>
    );
  }
}
