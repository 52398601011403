import * as React from 'react';
import {FunctionComponent} from 'react';
import * as Moment from 'moment';

import './GeneralMessagePreview.css';

interface Props {
  messagePushTitle: string
  messagePushBody: string
  showTitle?: boolean
}

export const GeneralMessagePreviewPush: FunctionComponent<Props> = (props) => {

  const now = new Date();
  const currentTime = Moment(now).format('HH:mm');

  return (
    <div className="card border-info h-100">
      {props.showTitle && (
        <h5 className="card-header text-white eGeneralMessagePreviewPushHeader">Push preview</h5>
      )}
      <div>
        <div className="eGeneralMessagePreviewPush">
          <div className="eGeneralMessagePreviewPushMessage">
            <div>
              <img className="eGeneralMessagePreviewPushLogo" src="/dist/images/logoImage.png"
                   alt='Logo push message preview'/>
              { `Squad in touch, ${ currentTime }` }
            </div>
            <div className="font-weight-bold">
              { props.messagePushTitle }
            </div>
            <div>
              { props.messagePushBody }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};