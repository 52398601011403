import { AppUser } from 'Src/views/App/App';
import axios from 'axios';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from 'Src/consts/table';
import { Count } from '../../../models/count';

const DEFAULT_FILTER: any = {
  limit: DEFAULT_LIMIT,
  skip: DEFAULT_SKIP
};

export function createSchoolLeagueNews(user: AppUser, leagueId: string, data: any) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/schoolLeagues/${leagueId}/news`, data, config)
    .then(response => {
      return response.data;
    });
}

export function updateSchoolLeagueNews(user: AppUser, leagueId: string, newsId: string, data: any) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .put(`${window.apiBase}/i/schools/${activeSchoolId}/schoolLeagues/${leagueId}/news/${newsId}`, data, config)
    .then(response => {
      return response.data;
    });
}

export function deleteSchoolLeagueNews(user: AppUser, leagueId: string, newsId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .delete(`${window.apiBase}/i/schools/${activeSchoolId}/schoolLeagues/${leagueId}/news/${newsId}`, config)
    .then(response => {
      return response.data;
    });
}

export function getSchoolLeagueNews(user: AppUser, leagueId, filter = DEFAULT_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order
      }
    }
  };

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/schoolLeagues/${leagueId}/news`, config)
    .then(response => {
      return response.data;
    });
}

export function getAllSchoolLeagueNews(user: AppUser, leagueId, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/schoolLeagues/${leagueId}/news`, config)
    .then(response => {
      return response.data;
    });
}

export function getSchoolLeagueNewsCount(user: AppUser, leagueId, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where
        }
      }
    };
  } else {
    config = {
      headers: { usid: key }
    };
  }

  return axios
    .get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/schoolLeagues/${leagueId}/news/count`, config)
    .then(response => {
      return response.data;
    });
}
