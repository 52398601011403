import * as React from 'react';
import { FunctionComponent } from 'react';
import { Field, Form, Formik, FormikProps } from 'formik';
import { Button } from 'Src/components/Button/Button';

interface Props {
  onCloseClick: () => void;
  onSubmit: (data: any) => void;
}

export const SchoolEventReasonCancelForm: FunctionComponent<Props> = props => {
  return (
    <Formik
      onSubmit={props.onSubmit}
      initialValues={{
        body: ''
      }}
      render={({ values, isValid, touched, errors }: FormikProps<{ body: string }>) => (
        <Form>
          <div className="form-group">
            <div className="mb-3 mt-3">
              If you need to provide extra details like about the event to be rescheduled, or pitches frozen, etc.,
              specify them in the field below. The reason will be included in the notification sent to team members and
              parents. If you want to cancel event(s) without providing a reason, just click the "No reason" button.
            </div>
            <Field
              component="textarea"
              name="body"
              placeholder="Enter cancellation reason"
              className={`form-control ${touched.body && errors.body ? 'is-invalid' : ''}`}
            />
            {touched.body && errors.body ? <div className="invalid-feedback">{errors.body}</div> : null}
          </div>
          <Button onClick={props.onCloseClick} text={'No reason'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
          <button type="submit" className="btn btn-primary" disabled={!values.body}>
            Commit
          </button>
        </Form>
      )}
    />
  );
};
SchoolEventReasonCancelForm.displayName = 'SchoolEventReasonCancelForm';
