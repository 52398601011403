import * as propz from 'propz';
import * as Lazy from 'lazy.js';
import { TOURNAMENT_LAYOUT_TYPE, TOURNAMENT_SUBTYPE, TOURNAMENT_TYPE } from '../../consts/tournament';
import { Tournament, TournamentAutoEnrollmentTeamsSetting, TournamentLayout } from '../../models/tournament';
import { GENDER } from '../../consts/common';
import { USER_GENDER } from '../../consts/user';
import { TournamentSchoolInvite } from '../../models/invite';

export function isTeamTournament(tournament: Tournament | TournamentSchoolInvite): boolean {
  const type = propz.get(tournament, ['type']);
  return type === TOURNAMENT_TYPE.TEAM_TOURNAMENT;
}

export function isIndividualTournament(tournament: Tournament | TournamentSchoolInvite): boolean {
  const type = propz.get(tournament, ['type']);
  return type === TOURNAMENT_TYPE.INDIVIDUAL_TOURNAMENT;
}
//deprecated
export function isSingleDoubleTournament(tournament: Tournament): boolean {
  const type = propz.get(tournament, ['type']);
  return type === TOURNAMENT_TYPE.SINGLE_DOUBLE_TOURNAMENT;
}

export function isMiniTeamTournament(tournament: Tournament): boolean {
  const type = propz.get(tournament, ['type']);
  return type === TOURNAMENT_TYPE.MINI_TEAM_TOURNAMENT;
}

export function isFootballSubTypeTournament(tournament: Tournament): boolean {
  const subType = propz.get(tournament, ['subType']);
  return subType === TOURNAMENT_SUBTYPE.FOOTBALL;
}
export function isRugbySubTypeTournament(tournament: Tournament): boolean {
  const subType = propz.get(tournament, ['subType']);
  return subType === TOURNAMENT_SUBTYPE.RUGBY;
}
export function isSailingSubTypeTournament(tournament: Tournament): boolean {
  const subType = propz.get(tournament, ['subType']);
  return subType === TOURNAMENT_SUBTYPE.SAILING;
}

export function isGroupStageExist(tournament: Tournament): boolean {
  const tournamentLayout = propz.get(tournament, ['tournamentLayout'], []);
  return tournamentLayout.some(stage => stage.type === TOURNAMENT_LAYOUT_TYPE.GROUP);
}
export function isQualifierStageExist(tournament: Tournament): boolean {
  const tournamentLayout = propz.get(tournament, ['tournamentLayout'], []);
  return tournamentLayout.some(stage => stage.type === TOURNAMENT_LAYOUT_TYPE.QUALIFIER);
}
export function isPlayoffStageExist(tournament: Tournament): boolean {
  const tournamentLayout = propz.get(tournament, ['tournamentLayout'], []);
  return tournamentLayout.some(stage => stage.type === TOURNAMENT_LAYOUT_TYPE.PLAYOFF);
}
export function isFinalStageExist(tournament: Tournament): boolean {
  const tournamentLayout = propz.get(tournament, ['tournamentLayout'], []);
  return tournamentLayout.some(stage => stage.type === TOURNAMENT_LAYOUT_TYPE.FINAL);
}

export function getStageByGroupId(tournament: Tournament, groupId: string): TournamentLayout {
  const { customGroups = [], tournamentLayout: stages = [] } = tournament;

  const group = customGroups.find(customGroup => customGroup.id === groupId);
  const stageId = group.stageId;
  const stage = stages.find(tournamentStage => tournamentStage.id === stageId);
  return stage;
}

export function getTeamPlacesLeft(
  autoEnrollmentTeamsSettings: TournamentAutoEnrollmentTeamsSetting[],
  age: number,
  gender: string
): number {
  const autoEnrollmentTeamsSetting = autoEnrollmentTeamsSettings.find(setting => {
    return setting.age === age && setting.gender === gender;
  });

  if (typeof autoEnrollmentTeamsSetting === 'undefined') {
    return 0;
  }

  const maximumNumberTeams: number = autoEnrollmentTeamsSetting.maximumNumberTeams || 0;
  const currentNumberTeams: number = autoEnrollmentTeamsSetting.currentNumberTeams || 0;

  return maximumNumberTeams - currentNumberTeams;
}

export function getUserGenderArrayConvertedFromEventGender(gender) {
  switch (gender) {
    case GENDER.FEMALE_ONLY:
      return [USER_GENDER.FEMALE];
    case GENDER.MALE_ONLY:
      return [USER_GENDER.MALE];
    case GENDER.MIXED:
      return [USER_GENDER.MALE, USER_GENDER.FEMALE];
    default:
      return undefined;
  }
}

export function getYesOrNoForBoolean(isBoolean): string {
  return isBoolean ? 'Yes' : 'No';
}

export function getBooleanForYesOrNo(selection): boolean {
  return selection === 'Yes';
}

export function isTournamentSubtypeSwimming(tournament: Tournament) {
  const subtype = propz.get(tournament, ['subType']);
  return subtype === TOURNAMENT_SUBTYPE.ATHLETIC_SWIMMING;
}

export function isTournamentSubtypeRegularIndividual(tournament: Tournament) {
  const subtype = propz.get(tournament, ['subType']);
  return subtype === TOURNAMENT_SUBTYPE.REGULAR_INDIVIDUAL;
}

export function isTournamentSubtypeJuniorTriathlon(tournament: Tournament) {
  const subtype = propz.get(tournament, ['subType']);
  return subtype === TOURNAMENT_SUBTYPE.TRIATHLON;
}

export function getSpaces(tournament: Tournament): string {
  const autoEnrollmentTeamsSettings = tournament.autoEnrollmentTeamsSettings || [];

  if (autoEnrollmentTeamsSettings.length === 0) {
    return '';
  }

  if (isIndividualTournament) {
    // In individual tournament, a school can have only one team for all ages
    const [autoEnrollmentTeamsSetting] = autoEnrollmentTeamsSettings;
    const { currentNumberTeams, maximumNumberTeams } = autoEnrollmentTeamsSetting;

    const maxTeamsDisplay = maximumNumberTeams === 0 ? 'Unlimited' : `${maximumNumberTeams}`;
    return `${maxTeamsDisplay} (${currentNumberTeams} booked)`;
  } else {
    const currentBookedSpaces = Lazy(autoEnrollmentTeamsSettings)
      .map(autoEnrollmentSetting => autoEnrollmentSetting.currentNumberTeams)
      .sum();

    const totalSpaces = Lazy(autoEnrollmentTeamsSettings)
      .map(autoEnrollmentSetting => autoEnrollmentSetting.maximumNumberTeams)
      .sum();

    const totalSpacesDisplay = totalSpaces === 0 ? 'Unlimited' : `${totalSpaces}`;
    return `${totalSpacesDisplay} (${currentBookedSpaces} booked)`;
  }
}
