import * as React from 'react';

interface Props {
  onClick: () => void;
}

export function EditorIcon(props: Props) {
  const { onClick } = props;
  return (
    <div className="ml-3 d-flex flex-column justify-content-center">
      <img style={{ width: '40px' }} src="/dist/images/font.png" title="" onClick={onClick} alt={'font'} />
    </div>
  );
}
