import { isInterSchoolsEvent } from '../event/event';
import {
  DEFAULT_POINT,
  EVENT_VENUE,
  MILES_TO_METERS,
  POINT,
  TBD,
  VENUE_SERVER_TO_CLIENT_MAPPING
} from '../../consts/venue';
import * as propz from 'propz';
import { searchFunctionPlacesAndPostcodes, searchFunctionVenues } from '../autocomplete/autocomplete';

export function getVenueView(event, schoolId): string {
  switch (true) {
    case isInterSchoolsEvent(event):
      return getInterSchoolsVenue(event, schoolId);
    default:
      return VENUE_SERVER_TO_CLIENT_MAPPING[event.venue.venueType];
  }
}

export function getInterSchoolsVenue(event, schoolId): string {
  const venueType = event.venue.venueType;
  const isVenueTypeHome = venueType === EVENT_VENUE.HOME;
  const isVenueTypeAway = venueType === EVENT_VENUE.AWAY;
  const isVenueTypeCustom = venueType === EVENT_VENUE.CUSTOM;
  const isVenueTypeTBD = venueType === EVENT_VENUE.TBD;

  switch (true) {
    case isVenueTypeHome && event.inviterSchoolId === schoolId:
      return VENUE_SERVER_TO_CLIENT_MAPPING[EVENT_VENUE.HOME];
    case isVenueTypeHome && event.inviterSchoolId !== schoolId:
      return VENUE_SERVER_TO_CLIENT_MAPPING[EVENT_VENUE.AWAY];
    case isVenueTypeAway && event.inviterSchoolId === schoolId:
      return VENUE_SERVER_TO_CLIENT_MAPPING[EVENT_VENUE.AWAY];
    case isVenueTypeAway && event.inviterSchoolId !== schoolId:
      return VENUE_SERVER_TO_CLIENT_MAPPING[EVENT_VENUE.HOME];
    case isVenueTypeCustom:
      return VENUE_SERVER_TO_CLIENT_MAPPING[EVENT_VENUE.CUSTOM];
    case isVenueTypeTBD:
      return VENUE_SERVER_TO_CLIENT_MAPPING[EVENT_VENUE.TBD];
  }
}

export function getMainGeoSchoolFilterByParams(distance, point) {
  switch (distance) {
    case 0:
      return getUnlimitedGeoSchoolFilter(point);
    default:
      return getGeoSchoolWithDistanceFilter(distance, point);
  }
}

function getUnlimitedGeoSchoolFilter(point) {
  return {
    $nearSphere: {
      $geometry: {
        type: POINT,
        coordinates: [point.lng, point.lat]
      }
    }
  };
}

function getGeoSchoolWithDistanceFilter(distance, point) {
  const filter = getUnlimitedGeoSchoolFilter(point);

  filter['$nearSphere']['$maxDistance'] = distance * MILES_TO_METERS;

  return filter;
}

export function getDefaultTournamentPostcode(tournament, activeSchool) {
  if (typeof tournament === 'undefined') {
    return undefined;
  }

  const homePostcode = getHomeSchoolPostCode(activeSchool),
    venue = propz.get(tournament, ['venue']),
    postcode = propz.get(venue, ['postcodeData']);
  let defVenue = venue;
  let defPostcode = postcode;
  switch (true) {
    case typeof homePostcode !== 'undefined' && typeof postcode !== 'undefined' && homePostcode.id === postcode.id:
      defPostcode.tooltip = ' (your school)';
      break;
    case venue.venueType === 'TBD':
      defPostcode = {
        id: 'TBD',
        postcode: 'TBD'
      };
      break;
  }
  defVenue.postcodeData = defPostcode;

  return defVenue;
}

export function getHomeSchoolPostCode(activeSchool) {
  const postcode = propz.get(activeSchool, ['postcode']);
  if (typeof postcode !== 'undefined') {
    propz.set(postcode, ['tooltip'], ' (your school)');
  }
  return postcode;
}

export function getVenueTypeByPostcode(value, activeSchool) {
  // home postcode can be undefined, so - check it
  const homePostcode = getHomeSchoolPostCode(activeSchool),
    homePostcodeId = typeof homePostcode !== 'undefined' ? homePostcode.id : undefined,
    valuePostcodeId = typeof value !== 'undefined' ? value.postcodeId : undefined;

  switch (true) {
    case homePostcodeId === valuePostcodeId:
      return 'HOME';
    case value.id === 'TBD':
      return 'TBD';
    //places with flag isHome (school console -> venues)
    case value.isHome === true:
      return 'HOME';
    default:
      return 'CUSTOM';
  }
}

export function getDefaultPoint(user) {
  const defaultPoint = propz.get(user, ['activeSchool', 'postcode', 'point'], DEFAULT_POINT);
  return defaultPoint;
}

export function isPlace(value) {
  return value.name !== undefined;
}

export function convertPlaceToPostcodeWithPlaceName(place) {
  return {
    id: place.postcodeId,
    placeId: place.id,
    name: place.name,
    point: place.point,
    postcode: place.postcode,
    postcodeNoSpaces: place.postcodeNoSpaces,
    isHome: place.isHome
  };
}

export function getPostcodes(text, user, isEventTypeExternalSchools?: boolean, participants?: any[]) {
  if (typeof text === 'undefined' || text === '') {
    return getResultForEmptySearchString(user, text, isEventTypeExternalSchools, participants);
  } else {
    return getResultForNotEmptySearchString(user, text, isEventTypeExternalSchools, participants);
  }
}

export function getResultForEmptySearchString(user, text, isEventTypeExternalSchools?: boolean, participants?: any[]) {
  return searchFunctionVenues(user, text).then(places => {
    let result = [];
    const { activeSchool, activeSchoolId } = user;

    // set home postcode
    const homePostCode = getHomeSchoolPostCode(activeSchool);
    if (typeof homePostCode !== 'undefined') {
      result.push(homePostCode);
    }

    //copy-paste
    if (isEventTypeExternalSchools) {
      let opponentSchoolPostCodes = [];

      if (Array.isArray(participants)) {
        opponentSchoolPostCodes = participants
          .filter(school => {
            const { postcode, id } = school;
            const { point } = postcode;

            const isPostcodeExist = typeof postcode !== 'undefined';
            const isPointExist = typeof point !== 'undefined';
            const participantSchoolId = id;

            if (activeSchoolId === participantSchoolId) {
              return false;
            }

            return isPostcodeExist && isPointExist;
          })
          .map(school => {
            const postcode = school.postcode;
            postcode.tooltip = ` (${school.name})`;
            postcode.point.lng = school.postcode.point.coordinates[0];
            postcode.point.lat = school.postcode.point.coordinates[1];
            return postcode;
          });
      }

      if (opponentSchoolPostCodes.length !== 0) {
        result = result.concat(opponentSchoolPostCodes);
      }
    }

    // set places
    result = result.concat(places);

    // set TBD plug - it's fake postcode
    result.push(TBD);

    return result;
  });
}

export function getResultForNotEmptySearchString(
  user,
  text,
  isEventTypeExternalSchools?: boolean,
  participants?: any[]
) {
  return searchFunctionPlacesAndPostcodes(user, text).then(postcodes => {
    const { activeSchool } = user;
    const homePostCode = getHomeSchoolPostCode(activeSchool);
    // home
    if (typeof homePostCode !== 'undefined') {
      const homePostcodeIndex = postcodes.findIndex(p => p.id === homePostCode.id);
      if (homePostcodeIndex !== -1) {
        postcodes[homePostcodeIndex].tooltip = ' (your school)';
      }
    }
    //copy-paste
    if (isEventTypeExternalSchools) {
      let opponentSchoolPostCodes = [];

      if (Array.isArray(participants)) {
        opponentSchoolPostCodes = participants
          .filter(school => typeof school.postcode !== 'undefined' && typeof school.postcode.point !== 'undefined')
          .map(school => {
            const postcode = school.postcode;
            postcode.tooltip = ` (${school.name})`;
            postcode.point.lng = school.postcode.point.coordinates[0];
            postcode.point.lat = school.postcode.point.coordinates[1];
            return postcode;
          });
      }

      opponentSchoolPostCodes.forEach(opponentPostcode => {
        const foundAwayPostcodeIndex = postcodes.findIndex(p => p.id === opponentPostcode.id);

        if (foundAwayPostcodeIndex !== -1) {
          postcodes[foundAwayPostcodeIndex].tooltip = ' (opponent school)';
          // Function modify args!!!
          function upPostcodeToStart(currentPostcodeIndex, postcodes) {
            const postcodeElement = postcodes[currentPostcodeIndex];

            postcodes.splice(currentPostcodeIndex, 1);
            postcodes.unshift(postcodeElement);

            return postcodes;
          }
          // Function modify args!!!
          postcodes = upPostcodeToStart(foundAwayPostcodeIndex, postcodes);
        }
      });
    }

    // tbd
    postcodes.unshift(TBD);
    return postcodes;
  });
}

export function getPoint(venuePoint, venuePostcodePoint) {
  if (venuePoint && venuePoint.coordinates.length > 0) {
    return venuePoint;
  } else {
    return venuePostcodePoint;
  }
}

export function getNewPoint(venue) {
  const { point, placeData, postcodeData } = venue;

  const isPointExist = typeof point !== 'undefined';
  const isPlaceDataExist = typeof placeData !== 'undefined';
  const isPostcodeDataExist = typeof postcodeData !== 'undefined';

  let coordinates;
  let result;

  switch (true) {
    case isPointExist:
      coordinates = propz.get(point, ['coordinates'], []);
      if (coordinates.length === 2) {
        result = point;
        break;
      }
    case isPlaceDataExist:
      coordinates = propz.get(placeData, ['point', 'coordinates'], []);
      if (coordinates.length === 2) {
        result = placeData.point;
        break;
      }
    case isPostcodeDataExist:
      coordinates = propz.get(postcodeData, ['point', 'coordinates'], []);
      if (coordinates.length === 2) {
        result = postcodeData.point;
        break;
      }
    default:
      result = DEFAULT_POINT;
      break;
  }

  return result;
}
