import * as React from 'react';
import { FunctionComponent } from 'react';
import { QUESTIONS_TEMPLATE_TYPE } from 'Src/consts/message';

interface Props {
  message: any;
}

export const ClubConfirmationRequestSummary: FunctionComponent<Props> = props => {
  const { message } = props;
  const fields = message.fields || [];

  const rows = fields.map((field, index) => {
    const { heading, value, type } = field;
    const isExistValue = typeof value !== 'undefined';

    let valueConverted;

    switch (type) {
      case QUESTIONS_TEMPLATE_TYPE.NUMBER:
      case QUESTIONS_TEMPLATE_TYPE.STRING:
      case QUESTIONS_TEMPLATE_TYPE.ENUM:
        valueConverted = value;
        break;
      case QUESTIONS_TEMPLATE_TYPE.BOOLEAN:
        if (isExistValue) {
          if (typeof value === 'boolean') {
            valueConverted = value ? 'Yes' : 'No';
          } else {
            valueConverted = value === 'true' ? 'Yes' : 'No';
          }
        } else {
          valueConverted = '';
        }
        break;
    }

    return (
      <tr key={`tournament_confirmation_request_${index}`}>
        <td>{heading}</td>
        <td>{valueConverted}</td>
      </tr>
    );
  });

  return (
    <table className={'table table-sm mPre'}>
      <tbody>{rows}</tbody>
    </table>
  );
};

ClubConfirmationRequestSummary.displayName = 'ClubConfirmationRequestSummary';
