import * as React from 'react';
import * as propz from 'propz';
import { Component } from 'react';
import { AppUser } from 'Src/views/App/App';
import { Participant, SchoolEvent, SchoolEventIndividualData, SchoolEventTeamDataPlayer } from 'Src/models/event';
import {
  getAllSchoolIdsFromEvent,
  getEventParticipants,
  getParticipantImg,
  getParticipantName,
  getParticipantTeamResult,
  getParticipantTeamResultNumber,
  getParticipantText,
  isEventStatusAccepted,
  isEventStatusFinished,
  isHousesEvent,
  isInternalEvent,
  isInterSchoolsEvent,
  isMultipartyEvent,
  isTeamOrTwoOnTwoSportEvent,
  getHouseColors
} from 'Src/helpers/event/event';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from 'Src/consts/table';
import { Loader } from 'Src/components/Loader/Loader';
import './SchoolEventTeamsTab.scss';
import { SchoolEventTeamPlayers } from './SchoolEventTeamPlayers';
import { SchoolEventIndividualPlayers } from './SchoolEventIndividualPlayers';
import { History } from 'history';
import { EVENT_RESULTS_MODE, EVENT_STATUS } from '../../../../../../consts/event';
import { getSchoolsPublic } from '../../../../../../helpers/service/public/public';
import { isInviteStatusAccepted } from '../../../../../../helpers/invite/invite';
import { PencilEditIcon } from '../../../../../../components/PencilEditIcon/PencilEditIcon';
import { RemoveIcon } from '../../../../../../components/RemoveIcon/RemoveIcon';
import { isCricket } from '../../../../../../helpers/sport/cricket';
import { SchoolEventCricketResult } from './SchoolEventCricketResult';
import {
  getExtraPointsByTeamId,
  getExtraPointsBySchoolId,
  getAbbreviationResultByTeamId,
  getAbbreviationResultBySchoolId,
  getAbbreviationColor,
  convertPointsToStringWithoutDelimeter
} from '../../../../../../helpers/event/schoolEventViewHelper';
import { ActionItem, ActionsButton } from '../../../../../../components/ActionsButton/ActionsButton';
import {
  isEventStatusCanceled,
  isEventStatusInvitesSent,
  isTournamentEvent,
  isTournamentEventReadOnly
} from '../../../../../../helpers/event/event';
import { getEventSportType } from 'Src/helpers/accessor/accessor';

interface State {
  isLoading: boolean;
}

interface Props {
  user: AppUser;
  history: History;
  eventInitial: SchoolEvent;
  participants: Participant[];
  onRemoveParticipantClick: (participant: Participant, isOneParticipant: boolean) => void;
  onChangeSchoolClick: (participant: Participant) => void;
}

export class SchoolEventTeamsTab extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };
  }

  getSearch(participant: Participant): string {
    const { eventInitial } = this.props;
    const { id: eventId } = eventInitial;
    let search = [`event=${eventId}`];

    const { team, house } = participant;

    const isTeamExist = typeof team !== 'undefined';
    if (isTeamExist) {
      search = [...search, `team=${team.id}`];
    }

    const isHouseExist = typeof house !== 'undefined';
    if (isHouseExist) {
      search = [...search, `house=${house.id}`];
    }

    return search.join('&');
  }

  getSearchForUpdateTeam(participant: Participant): string {
    const { eventInitial } = this.props;
    const { id: eventId } = eventInitial;

    const teamSourceOfCloneId = propz.get(participant, ['team', 'cloneOf']);
    const teamId = propz.get(participant, ['team', 'id']);

    let search = [`event=${eventId}`, `clone=${teamSourceOfCloneId}`, `team=${teamId}`];
    return search.join('&');
  }

  getTextResult(event: SchoolEvent): string {
    let textResult;

    const { resultsMode } = event;

    switch (resultsMode) {
      case EVENT_RESULTS_MODE.PLACES:
        textResult = 'Place: ';
        break;
      case EVENT_RESULTS_MODE.POINTS:
        textResult = 'Points: ';
        break;
      default:
        textResult = '';
        break;
    }

    return textResult;
  }

  onEditParticipantClick = (participant: Participant): void => {
    const { history, eventInitial } = this.props;

    const search = this.getSearch(participant);

    history.push({
      pathname: isTeamOrTwoOnTwoSportEvent(eventInitial) ? 'teamManager' : 'individualManager',
      search: search
    });
  };

  getActionItems(participant: Participant): ActionItem[] {
    const { eventInitial, user, onChangeSchoolClick, onRemoveParticipantClick, history, participants } = this.props;
    const { activeSchoolId } = user;

    const activeSchoolParticipants = participants.filter(
      participant => propz.get(participant, ['school', 'id'], '') === activeSchoolId
    );

    const activeSchoolParticipantsCount = activeSchoolParticipants.length;
    const opponentSchoolParticipants = participants.filter(
      participant => propz.get(participant, ['school', 'id'], '') !== activeSchoolId
    );
    const opponentSchoolParticipantsCount = opponentSchoolParticipants.length;

    const { inviterSchoolId } = eventInitial;

    const { invite } = participant;
    const participantSchoolId = propz.get(participant, ['school', 'id'], '');
    const isActiveSchoolParticipant = activeSchoolId === participantSchoolId;
    const interSchoolsConditionForEditButton = isInterSchoolsEvent(eventInitial) && isActiveSchoolParticipant;

    const isActiveEditButton =
      isInternalEvent(eventInitial) || isHousesEvent(eventInitial) || interSchoolsConditionForEditButton;

    const participantsFiltered = participants.filter(p => propz.get(p, ['school', 'id'], '') === participantSchoolId);
    const isOneParticipant = participantsFiltered.length === 1;

    const count = isHousesEvent(eventInitial) || isInternalEvent(eventInitial) ? 2 : 1;

    const isActiveSchoolInviter = activeSchoolId === inviterSchoolId;

    const activeSchoolConditionForRemoveButton = isActiveSchoolParticipant && activeSchoolParticipantsCount > count;
    const opponentSchoolsConditionForRemoveButton =
      !isActiveSchoolParticipant && opponentSchoolParticipantsCount > 1 && isActiveSchoolInviter;

    const isActiveRemoveButton = activeSchoolConditionForRemoveButton || opponentSchoolsConditionForRemoveButton;

    const isActiveChangeSchoolButton = isEventStatusInvitesSent(eventInitial) && isActiveSchoolInviter;

    const search = this.getSearch(participant);

    const isActiveChangeTeamButton = isTeamOrTwoOnTwoSportEvent(eventInitial) && isActiveSchoolParticipant;

    const teamSourceOfClone = propz.get(participant, ['team', 'cloneOf']);
    const isTeamSourseOfCloneExists = typeof teamSourceOfClone !== 'undefined' && teamSourceOfClone !== '';

    const isActiveUpdateTeamButton =
      isTeamOrTwoOnTwoSportEvent(eventInitial) && isActiveSchoolParticipant && isTeamSourseOfCloneExists;

    if (isTournamentEvent(eventInitial) && isTournamentEventReadOnly(eventInitial)) {
      return [
        {
          itemText: 'Edit participants',
          onItemClick: () => {},
          isActive: false
        },
        {
          itemText: 'Swap with an existing team',
          onItemClick: () => {},
          isActive: false
        },
        {
          itemText: 'Update team',
          onItemClick: () => {},
          isActive: false
        },
        {
          itemText: 'Remove team',
          onItemClick: () => {},
          isActive: false
        }
      ];
    }

    return isInterSchoolsEvent(eventInitial) && !isActiveSchoolParticipant
      ? [
          {
            itemText: 'Change opponent',
            onItemClick: () => {
              onChangeSchoolClick(participant);
            },
            isActive: isActiveChangeSchoolButton
          },
          {
            itemText: 'Remove team',
            onItemClick: () => {
              onRemoveParticipantClick(participant, isOneParticipant);
            },
            isActive: isActiveRemoveButton
          }
        ]
      : [
          {
            itemText: 'Edit participants',
            onItemClick: () => {
              this.onEditParticipantClick(participant);
            },
            isActive: isActiveEditButton
          },
          {
            itemText: 'Swap with an existing team',
            onItemClick: () => {
              history.push({
                pathname: 'existingTeamManager',
                search: search
              });
            },
            isActive: isActiveChangeTeamButton
          },
          {
            itemText: 'Update team',
            onItemClick: () => {
              history.push({
                pathname: 'updatingTeamManager',
                search: this.getSearchForUpdateTeam(participant)
              });
            },
            isActive: isActiveUpdateTeamButton
          },
          {
            itemText: 'Remove team',
            onItemClick: () => {
              onRemoveParticipantClick(participant, isOneParticipant);
            },
            isActive: isActiveRemoveButton
          }
        ];
  }

  render() {
    const { eventInitial, user, participants } = this.props;
    const { isLoading } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const isCricketResultShow =
      isEventStatusFinished(eventInitial) && isCricket(eventInitial) && !isMultipartyEvent(eventInitial);

    return (
      <div className="mt-3">
        {isCricketResultShow && <SchoolEventCricketResult event={eventInitial} user={user} />}
        <div className="eParticipantScroll">
          {participants.map((participant, index) => {
            const extraPoints = participant.team
              ? getExtraPointsByTeamId(eventInitial, participant.team.id)
              : getExtraPointsBySchoolId(eventInitial, participant.school.id);
            const abbreviationResult = participant.team
              ? getAbbreviationResultByTeamId(eventInitial, participant.team.id)
              : getAbbreviationResultBySchoolId(eventInitial, participant.school.id);
            const abbreviationColor = getAbbreviationColor(eventInitial, abbreviationResult);

            const participantName = getParticipantName(eventInitial, participant);
            const participantText = getParticipantText(eventInitial, participant, user);
            const participantImg = getParticipantImg(participant);
            const teamPlayers = propz.get<SchoolEventTeamDataPlayer[]>(participant, ['teamPlayers'], []);
            const individualPlayers = propz.get<SchoolEventIndividualData[]>(participant, ['individualPlayers'], []);

            const isEventFinished = isEventStatusFinished(eventInitial);
            const isTeamOrTwoOnTwoEvent = isTeamOrTwoOnTwoSportEvent(eventInitial);

            const sportType = getEventSportType(eventInitial);
            const isTeamSport = sportType === 'TEAM';

            const textResult = isTeamOrTwoOnTwoEvent && isTeamSport ? this.getTextResult(eventInitial) : '';
            const teamResult = isTeamSport ? getParticipantTeamResult(eventInitial, participant) : '';
            const teamResultNumber = isTeamSport ? getParticipantTeamResultNumber(eventInitial, participant) : 0;

            const isShowResult = isEventFinished && isTeamSport && (teamResult !== '' || teamResultNumber > 0);

            const actionItems = this.getActionItems(participant);
            const isEventCanceled = isEventStatusCanceled(eventInitial);

            const houseColors = getHouseColors(participant);

            return (
              <div key={`participant_${index}`} className={'eParticipantGeneral'}>
                <div className="bParticipantSchoolLogoSections">
                  <div className="bViewOpponentActions">
                    {!isEventCanceled && <ActionsButton actionItems={actionItems} />}
                  </div>
                  <img className="img-thumbnail eParticipantSchoolLogo" src={participantImg} alt={'Participant Logo'} />
                  <div className="font-weight-bold">
                    <span>{participantName}</span>
                    {houseColors.length > 0 && (
                      <span className="house-colors">
                        {houseColors.map((color, index) => (
                          <span
                            key={index}
                            style={{
                              display: 'inline-block',
                              width: '10px',
                              height: '10px',
                              backgroundColor: color,
                              borderRadius: '50%',
                              marginLeft: '5px'
                            }}
                          ></span>
                        ))}
                      </span>
                    )}
                  </div>
                  <div className="ePlayerScore">
                    {isShowResult && (
                      <>
                        <span>
                          {textResult} {teamResult}
                        </span>
                        {extraPoints > 0 && <span> | Points: {extraPoints}</span>}
                        {abbreviationResult && (
                          <>
                            <span> | </span>
                            <span style={{ color: abbreviationColor }}> {abbreviationResult}</span>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="bParticipantSchoolPlayersSections">
                  {participantText ? (
                    <div className="eParticipantText">{participantText}</div>
                  ) : (
                    <>
                      <SchoolEventTeamPlayers players={teamPlayers} event={eventInitial} />
                      <SchoolEventIndividualPlayers players={individualPlayers} event={eventInitial} />
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
