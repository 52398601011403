import * as React from 'react';
import * as BPromise from 'bluebird';
import * as Lazy from 'lazy.js';
import { AppUser } from 'Src/views/App/App';
import { parse } from 'query-string';
import { getOrder, getSearchFilter, getSearchOrder, getServerFieldSectionWhere } from 'Src/helpers/table/table';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { Loader } from 'Src/components/Loader/Loader';
import {
  ColumnDefinition,
  getFilters2,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { History, Location } from 'history';
import { Grid2 } from 'Src/components/Grid/Grid2';
import {
  getClubDays,
  getClubFinishDateFromMessage,
  getClubMessageStatus,
  getIsParticipant,
  getClubStartDateFromMessage,
  getClubStartTimeFromMessage,
  getUpdatedAt
} from 'Src/helpers/accessor/accessor';
import { ClubMessage } from 'Src/models/clubMessage';
import {
  getSelectOptionsForClubDays,
  getSelectOptionsForClubPriority,
  getSelectOptionsForClubMessagesStatus,
  getSelectOptionForBooleanQuestion
} from 'Src/helpers/table/select';
import {
  getAllSchoolClubMessages,
  getSchoolClubMessages,
  getSchoolClubMessagesCount
} from 'Src/helpers/service/admin/schoolClubMessages';

import './Invitations.css';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { addClubStudents, updateClubMessage } from 'Src/helpers/service/admin/clubStudents';
import { ClubMessageStatusForm } from './ClubMessageStatusForm/ClubMessageStatusForm';
import { CLUB_PARTICIPATION_MESSAGE_INVITATION_STATUS } from 'Src/consts/message';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Club name',
    field: 'name',
    isSort: false,
    type: FILTER_TYPE.TEXT,
    accessor: ['clubData', 'name']
  },
  {
    text: 'Start time',
    field: 'startTime',
    isSort: false,
    type: FILTER_TYPE.TIME_INTERVAL,
    accessor: getClubStartTimeFromMessage
  },
  {
    text: 'Start date',
    field: 'startDate',
    isSort: false,
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: getClubStartDateFromMessage
  },
  {
    text: 'Finish date',
    field: 'finishDate',
    isSort: false,
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: getClubFinishDateFromMessage
  },
  {
    text: 'Days',
    field: 'days',
    isSort: false,
    type: FILTER_TYPE.SELECT,
    accessor: getClubDays
  },
  {
    text: 'Name',
    field: 'firstName',
    isSort: false,
    type: FILTER_TYPE.TEXT,
    accessor: ['playerDetailsData', 'firstName']
  },
  {
    text: 'Surname',
    field: 'lastName',
    isSort: false,
    type: FILTER_TYPE.TEXT,
    accessor: ['playerDetailsData', 'lastName']
  },
  {
    text: 'Updated',
    field: 'updatedAt',
    isSort: true,
    type: FILTER_TYPE.NONE,
    accessor: getUpdatedAt
  },
  {
    text: 'Invitation status',
    field: 'invitationStatus',
    isSort: true,
    type: FILTER_TYPE.SELECT,
    accessor: getClubMessageStatus
  },
  {
    text: 'Priority',
    field: 'priority',
    isSort: true,
    accessor: ['priority'],
    type: FILTER_TYPE.SELECT
  },
  {
    text: 'Participant',
    field: 'participant',
    isSort: false,
    type: FILTER_TYPE.SELECT,
    accessor: getIsParticipant
  }
];

interface State {
  items: ClubMessage[];
  currentPage: number;
  selectedItems: ClubMessage[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;

  isClubErrorsModalOpen: boolean;
  errors: InvitationsError[];
  isClubInfoModalOpen: boolean;
  isChangeStatusModalOpen: boolean;
}

interface InvitationsError {
  name: string;
  maxParticipants: number;
  participantsCount: number;
  potentialClubParticipantsCount: number;
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export class Invitations extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',

      isClubErrorsModalOpen: false,
      errors: [],
      isClubInfoModalOpen: false,
      isChangeStatusModalOpen: false
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });
    this.setItems();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const getItemsPromise = getSchoolClubMessages(user, serverQueryFilter);
    const getItemsCountPromise = getSchoolClubMessagesCount(user, where);

    const promises = [getItemsCountPromise, getItemsPromise];

    return BPromise.all(promises).then(([countObj, items]) => {
      this.setState({
        currentPage: page,
        sortDirection: sortDirection,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: items,
        total: countObj.count,
        basePath: history.location.pathname,
        isLoading: false
      });

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: nextSelectedItems,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    getAllSchoolClubMessages(user, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const nextSelectedItems = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: nextSelectedItems,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: ''
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  onAddToClubClick = (): void => {
    const { selectedItems } = this.state;

    const potentialClubsParticipants = selectedItems.map(message => {
      return {
        userId: message.playerDetails.userId,
        permissionId: message.playerDetails.permissionId,
        clubId: message.clubId
      };
    });

    const clubIds = Lazy(selectedItems)
      .uniq('clubId')
      .map(message => message.clubId)
      .toArray();

    const errors = [];

    clubIds.forEach(clubId => {
      const potentialClubParticipants = potentialClubsParticipants.filter(participant => participant.clubId === clubId);
      const potentialClubParticipantsCount = potentialClubParticipants.length;

      const clubMessage = selectedItems.find(message => message.clubId === clubId);
      const { clubData } = clubMessage;
      const { maxParticipants, participants } = clubData;
      const participantsCount = participants.length;
      const totalParticipants = participantsCount + potentialClubParticipantsCount;
      const isUnlimitedMaxParticipants = maxParticipants === 0;

      if (!isUnlimitedMaxParticipants && maxParticipants < totalParticipants) {
        errors.push({
          name: clubData.name,
          maxParticipants: maxParticipants,
          participantsCount: participantsCount,
          potentialClubParticipantsCount: potentialClubParticipantsCount
        });
      }
    });

    if (errors.length > 0) {
      this.setState({
        isClubErrorsModalOpen: true,
        errors: errors
      });
    } else {
      this.setState({
        isClubInfoModalOpen: true
      });
    }
  };

  isAddToClubButtonActive(): boolean {
    const { selectedItems } = this.state;
    const isSomeSelectedItemsParticipant = selectedItems.some(message => message.isParticipant);
    return selectedItems.length !== 0 && !isSomeSelectedItemsParticipant;
  }

  changeStatus = () => {
    this.setState({
      isChangeStatusModalOpen: true
    });
  };

  onChangeStatusCancelClick = () => {
    this.setState({
      isChangeStatusModalOpen: false
    });
  };

  renderSchoolClubMessagesTable(): React.ReactNode {
    const { user } = this.props;
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isSelectAllChecked,
      currentPage,
      total
    } = this.state;

    const isOneItemSelected = selectedItems.length === 1;

    const actionItems = [
      {
        itemText: 'Add to club',
        onItemClick: this.onAddToClubClick,
        isActive: this.isAddToClubButtonActive()
      },
      {
        itemText: 'Change status',
        onItemClick: this.changeStatus,
        isActive: isOneItemSelected
      }
    ];

    const filterOptions = {
      invitationStatus: getSelectOptionsForClubMessagesStatus(user),
      priority: getSelectOptionsForClubPriority(),
      days: getSelectOptionsForClubDays(),
      participant: getSelectOptionForBooleanQuestion()
    };

    return (
      <Grid2
        dataItems={items}
        filters={filters}
        currentPage={currentPage}
        total={total}
        isSelectAllChecked={isSelectAllChecked}
        isDataFiltered={isDataFiltered}
        sortDirection={sortDirection}
        sortColumnsName={sortColumnsName}
        isShowFilter={isShowFilter}
        dataItemsSelected={selectedItems}
        columns={COLUMNS}
        actionItems={actionItems}
        options={filterOptions}
        onItemClick={this.onItemClick}
        onSortClick={this.onTableSortClick}
        onApplyFilterClick={this.onApplyFilterClick}
        onClearFilterClick={this.onClearFilterClick}
        onTableFilterChange={this.onTableFilterChange}
        onTableFilterClick={this.onTableFilterClick}
        setCurrentPageParams={this.setCurrentPageParams}
        onSelectAllClick={this.onSelectAllClick}
        onSelectAllOnPageClick={this.onSelectAllOnPageClick}
        onUnselectAllClick={this.onUnselectAllClick}
      />
    );
  }

  addStudentsToClub = () => {
    const { selectedItems } = this.state;
    const { user } = this.props;

    const potentialClubsParticipants = selectedItems.map(message => {
      return {
        userId: message.playerDetails.userId,
        permissionId: message.playerDetails.permissionId,
        clubId: message.clubId
      };
    });

    const clubIds = Lazy(selectedItems)
      .uniq('clubId')
      .map(message => message.clubId)
      .toArray();

    this.setState({
      isLoading: true
    });

    BPromise.all(
      clubIds.map(clubId => {
        const clubParticipants = potentialClubsParticipants.filter(participant => participant.clubId === clubId);

        const data = {
          participants: clubParticipants.map(participant => {
            return {
              userId: participant.userId,
              permissionId: participant.permissionId
            };
          })
        };

        return addClubStudents(user, clubId, data);
      })
    ).then(res => {
      this.setState({
        isClubInfoModalOpen: false
      });

      this.setItems();
    });
  };

  clubErrorsModalClose = (): void => {
    this.setState({
      isClubErrorsModalOpen: false
    });
  };

  renderClubErrorsModal(): React.ReactNode {
    const { errors, isClubErrorsModalOpen } = this.state;

    const errorsText = errors.map(error => {
      return `Club name: ${error.name} \nSpaces available: ${error.maxParticipants -
        error.participantsCount} \nStudents you are trying to add: ${error.potentialClubParticipantsCount} \n`;
    });

    return (
      <SimpleModal
        isOpen={isClubErrorsModalOpen}
        title={'Add students to club'}
        body={`There are no sufficient spaces on the clubs: \n\n${errorsText.join(
          '\n'
        )} \nPlease either amend the number of spaces available on the club settings page or deselect some students to match the number of available spaces.`}
        onCloseClick={this.clubErrorsModalClose}
      />
    );
  }

  clubInfoModalClose = (): void => {
    this.setState({
      isClubInfoModalOpen: false
    });
  };

  renderClubInfoModal() {
    const { selectedItems, isClubInfoModalOpen } = this.state;

    const potentialClubsParticipants = selectedItems.map(message => {
      return {
        userId: message.playerDetails.userId,
        permissionId: message.playerDetails.permissionId,
        clubId: message.clubId
      };
    });

    const clubIds = Lazy(selectedItems)
      .uniq('clubId')
      .map(message => message.clubId)
      .toArray();

    const infoText = clubIds.map(clubId => {
      const potentialClubParticipants = potentialClubsParticipants.filter(participant => participant.clubId === clubId);
      const potentialClubParticipantsCount = potentialClubParticipants.length;
      const messageClubData = selectedItems.find(message => message.clubId === clubId);
      const clubData = messageClubData.clubData;

      const { maxParticipants, participants } = clubData;
      const participantsCount = participants.length;

      const clubName = clubData.name;

      const availableSpaces = maxParticipants === 0 ? 'unlimited' : `${maxParticipants - participantsCount}`;

      return `Club name: ${clubName} \nSpaces available: ${availableSpaces} \nStudents you are trying to add: ${potentialClubParticipantsCount} \n`;
    });

    return (
      <SimpleModal
        isOpen={isClubInfoModalOpen}
        title={'Add students to club'}
        body={`${infoText.join('\n')}`}
        buttonText={'Add'}
        onButtonClick={this.addStudentsToClub}
        onCloseClick={this.clubInfoModalClose}
      />
    );
  }

  onChangeStatusSubmitClick = (data: { invitationStatus: string; priority?: number }): void => {
    const { user } = this.props;
    const { selectedItems } = this.state;
    const message = selectedItems[0];
    const { id: messageId, clubId } = message;

    const { invitationStatus, priority } = data;
    const isInvitationStatusAccepted = invitationStatus === CLUB_PARTICIPATION_MESSAGE_INVITATION_STATUS.ACCEPTED;

    const dataCopy = {
      invitationStatus: data.invitationStatus,
      priority: Number(priority)
    };

    if (!isInvitationStatusAccepted || dataCopy.priority === 0) {
      delete dataCopy.priority;
    }

    updateClubMessage(user, clubId, messageId, dataCopy).then(messageUpdated => {
      this.setState({
        isChangeStatusModalOpen: false,
        selectedItems: [],
        isSelectAllChecked: false
      });
      this.setItems();
    });
  };

  renderChangeStatusModal(): React.ReactNode {
    const { isChangeStatusModalOpen, selectedItems } = this.state;

    const message = selectedItems[0];

    return (
      <SimpleModal isOpen={isChangeStatusModalOpen} title={'Change status'}>
        <ClubMessageStatusForm
          message={message}
          onSubmit={this.onChangeStatusSubmitClick}
          onCancel={this.onChangeStatusCancelClick}
        />
      </SimpleModal>
    );
  }

  render() {
    const { isLoading, isClubErrorsModalOpen, isChangeStatusModalOpen, isClubInfoModalOpen } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes =
      isClubErrorsModalOpen || isChangeStatusModalOpen || isClubInfoModalOpen
        ? 'mt-3 bInvitations modal-open'
        : 'mt-3 bInvitations';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderClubErrorsModal()}
        {this.renderClubInfoModal()}
        {this.renderChangeStatusModal()}
        <div className="row">
          <div className="col-md-12">{this.renderSchoolClubMessagesTable()}</div>
        </div>
      </div>
    );
  }
}
