import { AppUser } from 'Src/views/App/App';
import axios from 'axios';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from 'Src/consts/table';

export function getNotificationSettings(user: AppUser) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        limit: DEFAULT_LIMIT,
        skip: DEFAULT_SKIP
      }
    }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/notifications`, config).then(response => {
    return response.data;
  });
}

export function updateNotificationSettings(user: AppUser, data) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.put(`${window.apiBase}/i/schools/${activeSchoolId}/notifications`, data, config).then(response => {
    return response.data;
  });
}
