import * as React from 'react';
import { FunctionComponent } from 'react';

import 'Src/components/GeneralMessagePreview/GeneralMessagePreview.css';
import { AffectedSchoolList } from './AffectedSchoolList/AffectedSchoolList';
import { GeneralMessagePreviewPush } from 'Src/components/GeneralMessagePreview/GeneralMessagePreviewPush';
import { GeneralMessagePreviewTabs } from 'Src/components/GeneralMessagePreview/GeneralMessagePreviewTabs';
import { TournamentCustomNotificationPreviewEmail } from './TournamentCustomNotificationPreviewEmail';
import { Attachment } from 'Src/models/generalMessage';

interface Props {
  messageEmailSubject: string;
  messageEmailBody: string;
  messagePushTitle: string;
  messagePushBody: string;
  messageAttachments: Attachment[];
  affectedSchools?: any[];
}

export const TournamentCustomNotificationPreview: FunctionComponent<Props> = props => {
  return (
    <div className="container-fluid">
      <div className="bGeneralMessagePreview row">
        <div className="col-md-6">
          <GeneralMessagePreviewTabs tabs={['Email preview', 'Push preview']}>
            {/*email preview*/}
            <TournamentCustomNotificationPreviewEmail
              messageEmailSubject={props.messageEmailSubject}
              messageEmailBody={props.messageEmailBody}
              messageAttachments={props.messageAttachments}
            />

            {/*push preview*/}
            <GeneralMessagePreviewPush
              messagePushTitle={props.messagePushTitle}
              messagePushBody={props.messagePushBody}
            />
          </GeneralMessagePreviewTabs>
        </div>
        <div className="col-md-6 overflow-auto">
          <AffectedSchoolList affectedSchools={props.affectedSchools} />
        </div>
      </div>
    </div>
  );
};

TournamentCustomNotificationPreview.displayName = 'TournamentCustomNotificationPreview';
