import * as React from 'react';
import { Component } from 'react';
import { parse } from 'query-string';
import * as BPromise from 'bluebird';
import {
  ColumnDefinition,
  getFilters2,
  getOrder,
  getSearchFilter,
  getSearchOrder,
  getServerFieldSectionWhere,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { Loader } from 'Src/components/Loader/Loader';
import { AppUser } from 'Src/views/App/App';
import { History, Location } from 'history';
import { DATE_INTERVAL, DEFAULT_LIMIT, DEFAULT_SKIP, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { Grid2 } from 'Src/components/Grid/Grid2';
import { Button } from 'Src/components/Button/Button';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { GenericSummary } from 'Src/components/GenericSummary/GenericSummary';
import { ResizablePanel } from 'Src/components/ResizablePanel/ResizablePanel';
import {
  createSchoolUnionRecords,
  deleteSchoolUnionRecords,
  getAllSchoolUnionRecords,
  getSchoolUnionRecords,
  getSchoolUnionRecordsCount,
  getSportsOfRecords,
  updateSchoolUnionRecords
} from 'Src/helpers/service/adminSU/recordsTable';
import { getAgeGroup, getGender, getRecord, getYear } from 'Src/helpers/accessor/accessor';
import { Record } from 'Src/models/record';
import { RecordForm } from './RecordForm/RecordForm';
import { RecordSummary } from 'Src/components/RecordSummary/RecordSummary';
import { RECORD_TABS } from 'Src/consts/record';
import {
  getSelectOptionForAgeRecords,
  getSelectOptionForGender,
  getSelectOptionForSport
} from 'Src/helpers/table/select';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Sport',
    field: 'sport',
    isSort: true,
    type: FILTER_TYPE.MULTISELECT,
    accessor: ['sport', 'name']
  },
  {
    text: 'Age',
    field: 'agesRecord',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getAgeGroup
  },
  {
    text: 'Gender',
    field: 'gender',
    isSort: true,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getGender
  },
  {
    text: 'Record',
    field: 'record',
    isSort: true,
    type: FILTER_TYPE.NONE,
    accessor: getRecord
  },
  {
    text: 'Athlete',
    field: 'athlete',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['athlete']
  },
  {
    text: 'Affiliation',
    field: 'area',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['area']
  },
  {
    text: 'Year',
    field: 'date',
    isSort: true,
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: getYear
  }
];

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

interface State {
  items: Record[];
  currentPage: number;
  selectedItems: Record[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;
  sports: any[];

  isEditFormPopupOpened: boolean;
  isDeleteFormModalOpen: boolean;
}

export class Records extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',
      sports: [],

      isEditFormPopupOpened: false,
      isDeleteFormModalOpen: false
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });
    this.setAdditionalItems().then(res => {
      this.setItems();
    });
  }

  setAdditionalItems() {
    const { user } = this.props;
    const filter = {
      limit: DEFAULT_LIMIT,
      order: 'name ASC',
      skip: DEFAULT_SKIP
    };
    return getSportsOfRecords(user, filter).then(sports => {
      this.setState({
        sports: sports
      });

      return true;
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const getRecordsPromise = getSchoolUnionRecords(user, serverQueryFilter);
    const getRecordCountPromise = getSchoolUnionRecordsCount(user, where);
    const promises = [getRecordCountPromise, getRecordsPromise];

    return BPromise.all(promises).then(([countObj, records]) => {
      this.setState({
        currentPage: page,
        sortDirection: sortDirection,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: records,
        total: countObj.count,
        basePath: history.location.pathname,
        isLoading: false
      });

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: nextSelectedItems,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    getAllSchoolUnionRecords(user, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const nextSelectedItems = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: nextSelectedItems,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: ''
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  onSaveButtonClick = (data): void => {
    const { user } = this.props;
    const { selectedItems } = this.state;

    this.setState({
      isLoading: true
    });

    switch (selectedItems.length) {
      case 0: {
        createSchoolUnionRecords(user, data).then(record => {
          this.setState({
            isEditFormPopupOpened: false,
            selectedItems: []
          });
          this.setItems();
        });
        break;
      }
      case 1:
        const recordId = selectedItems[0].id;
        updateSchoolUnionRecords(user, recordId, data).then(record => {
          this.setState({
            isEditFormPopupOpened: false,
            selectedItems: []
          });
          this.setItems();
        });
        break;
    }
  };

  onCancelButtonClick = (): void => {
    this.setState({
      isEditFormPopupOpened: false
    });
  };

  onTabClick = (event, index: number) => {
    event.preventDefault();

    this.setState({
      total: index
    });
  };

  onEditFormClick = event => {
    event.preventDefault();

    this.setState({
      isEditFormPopupOpened: true
    });
  };

  getSummaryTabs(): React.ReactNode {
    const { selectedItems, items } = this.state;

    const selectedRecords = items.find(record => record.id === selectedItems[0].id);
    switch (true) {
      case selectedItems.length === 1:
        return <RecordSummary record={selectedRecords} />;
    }
  }

  renderForm() {
    const { isEditFormPopupOpened, selectedItems } = this.state;
    const { user } = this.props;

    switch (selectedItems.length) {
      case 0:
        return (
          <SimpleModal isOpen={isEditFormPopupOpened} title={'Create record'}>
            <RecordForm
              isOpen={isEditFormPopupOpened}
              onCloseClick={this.onCancelButtonClick}
              onFormSubmit={this.onSaveButtonClick}
              user={user}
            />
          </SimpleModal>
        );
      case 1:
        return (
          <SimpleModal isOpen={isEditFormPopupOpened} title={'Edit record'}>
            <RecordForm
              isOpen={isEditFormPopupOpened}
              onCloseClick={this.onCancelButtonClick}
              onFormSubmit={this.onSaveButtonClick}
              record={selectedItems[0]}
              user={user}
            />
          </SimpleModal>
        );
    }
  }

  onCreateRecordClick = () => {
    this.setState({
      isEditFormPopupOpened: true
    });
  };

  removeFormModalOpen = (): void => {
    this.setState({
      isDeleteFormModalOpen: true
    });
  };

  removeFormModalClose = (): void => {
    this.setState({
      isDeleteFormModalOpen: false
    });
  };

  onRemoveRecordClick = () => {
    const { selectedItems } = this.state;
    if (typeof selectedItems === 'undefined' || selectedItems.length === 0) {
      return;
    }
    this.setState({ isLoading: true });
    const promises = selectedItems.map(record => {
      return deleteSchoolUnionRecords(this.props.user, record.id);
    });
    BPromise.all(promises).then(() => {
      this.setState({
        selectedItems: [],
        isDeleteFormModalOpen: false
      });
      this.setItems();
    });
  };

  renderDeleteFormModal() {
    const { selectedItems, isDeleteFormModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isDeleteFormModalOpen}
        title={'Remove record'}
        body={`Are you sure you want to remove ${
          selectedItems.length === 1 ? `record ${selectedItems[0].athlete}` : `${selectedItems.length} records`
        }?`}
        buttonText={'Delete'}
        onButtonClick={this.onRemoveRecordClick}
        onCloseClick={this.removeFormModalClose}
      />
    );
  }

  renderSummary() {
    const { selectedItems } = this.state;
    const isOneItemSelected = selectedItems.length === 1;

    if (!isOneItemSelected) {
      return <div className="font-weight-bold">Select a record above</div>;
    }

    const summaryTabs = this.getSummaryTabs();

    const isShowEditButton = selectedItems.length === 1;

    return (
      <GenericSummary onTabClick={this.onTabClick} tabs={RECORD_TABS} currentTabIndex={0}>
        {isShowEditButton && <Button onClick={this.onEditFormClick} text={'Edit record'} customClass={'mt-3 mb-3'} />}
        {summaryTabs}
      </GenericSummary>
    );
  }

  render() {
    const {
      items,
      sports,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isLoading,
      isSelectAllChecked,
      currentPage,
      total,
      isEditFormPopupOpened,
      isDeleteFormModalOpen
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes = isEditFormPopupOpened || isDeleteFormModalOpen ? 'mt-3 modal-open' : 'mt-3';

    const actionItems = [
      {
        itemText: 'Create record',
        onItemClick: this.onCreateRecordClick,
        isActive: selectedItems.length === 0
      },
      {
        itemText: 'Remove record',
        onItemClick: this.removeFormModalOpen,
        isActive: selectedItems.length > 0
      }
    ];

    const user = this.props.user;
    const activeSchool = user.activeSchool;
    const ageGroupsNaming = activeSchool.ageGroupsNaming;

    const filterOptions = {
      agesRecord: getSelectOptionForAgeRecords(ageGroupsNaming),
      gender: getSelectOptionForGender(),
      sport: getSelectOptionForSport(sports)
    };
    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderDeleteFormModal()}
        {this.renderForm()}
        <div className="row">
          <div className="col-md-12">
            <ResizablePanel>
              <Grid2
                dataItems={items}
                filters={filters}
                currentPage={currentPage}
                total={total}
                isSelectAllChecked={isSelectAllChecked}
                isDataFiltered={isDataFiltered}
                sortDirection={sortDirection}
                sortColumnsName={sortColumnsName}
                isShowFilter={isShowFilter}
                dataItemsSelected={selectedItems}
                columns={COLUMNS}
                actionItems={actionItems}
                options={filterOptions}
                onItemClick={this.onItemClick}
                onSortClick={this.onTableSortClick}
                onApplyFilterClick={this.onApplyFilterClick}
                onClearFilterClick={this.onClearFilterClick}
                onTableFilterChange={this.onTableFilterChange}
                onTableFilterClick={this.onTableFilterClick}
                setCurrentPageParams={this.setCurrentPageParams}
                onSelectAllClick={this.onSelectAllClick}
                onSelectAllOnPageClick={this.onSelectAllOnPageClick}
                onUnselectAllClick={this.onUnselectAllClick}
              />
              {this.renderSummary()}
            </ResizablePanel>
          </div>
        </div>
      </div>
    );
  }
}
