import * as React from 'react';
import * as propz from 'propz';
import * as Moment from 'moment';
import { FunctionComponent } from 'react';
import {INVITE_STATUS_SERVER_TO_CLIENT_MAPPING} from "../../consts/invite";

interface Props {
  invite: any;
}

const ROWS = [
  { title: 'Tournament', field: 'tournamentName' },
  { title: 'School', field: 'invitedSchoolName' },
  { title: 'Send', field: 'createdAt' },
  { title: 'Status', field: 'status' },
  { title: 'Updated', field: 'updatedAt' },
];

export const InviteArchiveSummary: FunctionComponent<Props> = props => {
  const rows = ROWS.map((row, index) => {
    let contentCell: any;
    const field = row.field;

    const invite = props.invite;
    const createdAt = invite.createdAt;
    const updatedAt = invite.updatedAt;

    switch (field) {
      case 'tournamentName':
        const tournamentName = propz.get(invite, ['tournament', 'name'], '');
        contentCell = tournamentName;
        break;
      case 'createdAt':
        const createdAtConverted = Moment(createdAt).format('DD/MM/YYYY HH:mm');
        contentCell = createdAtConverted;
        break;
      case 'invitedSchoolName':
        const invitedSchoolName = propz.get(invite, ['invitedSchool', 'name'], '');
        contentCell = invitedSchoolName;
        break;
      case 'status':
        const status = INVITE_STATUS_SERVER_TO_CLIENT_MAPPING[invite.status];
        contentCell = status;
        break;
      case 'updatedAt':
        const updatedAtConverted = Moment(updatedAt).format('DD/MM/YYYY HH:mm');
        contentCell = updatedAtConverted;
        break;
      default:
        contentCell = propz.get(invite, row.field.split('.'), '');
    }

    return (
      <tr key={`invite_summary_${index}`}>
        <td>{row.title}</td>
        <td>{contentCell}</td>
      </tr>
    );
  });

  return (
    <table className={'table table-sm mPre mVerticalAlignMiddle'}>
      <tbody>{rows}</tbody>
    </table>
  );
};

InviteArchiveSummary.displayName = 'InviteArchiveSummary';
