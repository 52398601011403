import * as React from 'react';
import * as propz from 'propz';
import { Component } from 'react';
import * as Moment from 'moment';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import MaskedInput from 'react-text-mask';
import { uploadImage } from '../../helpers/service/image';
import { AppUser } from 'Src/views/App/App';
import { News } from '../../models/news';
import './NewsForm.css';
import { DATE_TIME_FORMAT, DATE_TIME_MASK } from '../../consts/date';
import { LabelWithQuestionIcon } from '../LabelWithQuestionIcon/LabelWithQuestionIcon';
import { EditorIcon } from '../EditorIcon/EditorIcon';
import { HTMLEditorQuillForNews } from '../HTMLEditorQuill/HTMLEditorQuillForNews';

interface Props {
  onCloseClick: () => void;
  onFormSubmit: (news: any) => void;
  user: AppUser;
  news?: News;
}

interface State {
  title: string;
  body: string;
  date: string;
  picUrl: string;
  isImageError: boolean;
  isEditorActive: boolean;
}

const NewsValidationSchema = Yup.object().shape({
  title: Yup.string().required('Required'),
  body: Yup.string().required('Required'),
  date: Yup.string()
    .matches(
      /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}\/(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$/,
      'Must be correct date'
    )
    .required('Required')
});

export class NewsForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const isNewsExist = this.isNewsExist();
    const { news } = this.props;

    this.state = {
      title: isNewsExist ? propz.get(news, ['title'], '') : '',
      body: isNewsExist ? propz.get(news, ['body'], '') : '',
      date: isNewsExist ? this.getNewsDate() : '',
      picUrl: isNewsExist ? propz.get(news, ['picUrl'], '') : '',
      isImageError: false,
      isEditorActive: false
    };
  }

  getNewsDate(): string {
    const { news } = this.props;
    return typeof news.date !== 'undefined' ? Moment(news.date).format('DD-MM-YYYY/HH:mm') : '';
  }

  isNewsExist() {
    const { news } = this.props;

    return typeof news !== 'undefined';
  }

  onImageSelected = event => {
    const image = event.target.files[0];

    uploadImage(this.props.user, image)
      .then(response => {
        const picUrl = `${window.apiImg}/images/${response.key}`;
        this.setState({
          picUrl: picUrl,
          isImageError: false
        });
      })
      .catch(error => {
        console.error(error);
        this.setState({ isImageError: true });
      });
  };

  onSubmit = data => {
    //prepare data
    const { date, title, body } = data;
    const dateFormatted = Moment(date, DATE_TIME_FORMAT).toDate();
    const isoDate = dateFormatted.toISOString();
    const news = {
      picUrl: this.state.picUrl,
      date: isoDate,
      body: body,
      title: title
    };

    this.props.onFormSubmit(news);
  };

  onShowEditorClick = () => {
    const { isEditorActive } = this.state;

    this.setState({
      isEditorActive: !isEditorActive
    });
  };

  onRemoveImageClick = (): void => {
    this.setState({ picUrl: '' });
  };

  render() {
    const { isImageError, picUrl, isEditorActive } = this.state;
    const imgInputClassName = isImageError ? 'form-control-file is-invalid' : 'form-control-file is-invalid';

    const isNewsExist = this.isNewsExist();

    const isPicUrlExists = typeof picUrl !== 'undefined' && picUrl.length > 0;

    return (
      <Formik
        onSubmit={this.onSubmit}
        initialValues={{
          title: this.state.title,
          date: this.state.date,
          body: this.state.body
        }}
        validationSchema={NewsValidationSchema}
        render={({ values, setFieldValue, touched, errors }) => (
          <Form>
            <div className="container-fluid pl-0">
              <div className="row">
                <div className="col-md-10">
                  <div className="form-group">
                    <LabelWithQuestionIcon labelText="Title" hintText="" />
                    <Field
                      type="text"
                      name="title"
                      className={`${touched.title && errors.title ? 'form-control is-invalid' : 'form-control '}`}
                      placeholder="Enter title"
                    />
                    {touched.title && errors.title ? <div className="invalid-feedback">{errors.title}</div> : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fluid pl-0">
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <LabelWithQuestionIcon labelText="Date" hintText="" />
                    <Field
                      name="date"
                      render={() => {
                        return (
                          <MaskedInput
                            onChange={event => {
                              setFieldValue('date', event.target.value);
                            }}
                            mask={DATE_TIME_MASK}
                            className={`${touched.date && errors.date ? 'form-control is-invalid' : 'form-control '}`}
                            placeholder={DATE_TIME_FORMAT}
                            value={values.date}
                          />
                        );
                      }}
                    />
                    {touched.date && errors.date ? <div className="invalid-feedback">{errors.date}</div> : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fluid pl-0">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <LabelWithQuestionIcon labelText="Body" hintText="" />
                    <div className="d-flex eNewsTextEditor">
                      <HTMLEditorQuillForNews
                        onTextChange={body => {
                          setFieldValue('body', body);
                        }}
                        value={values.body}
                        isEnabled={isEditorActive}
                        placeholder="Enter body"
                      />
                      <EditorIcon onClick={() => this.onShowEditorClick()} />
                      {touched.body && errors.body ? <div className="invalid-feedback">{errors.body}</div> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group">
              <LabelWithQuestionIcon labelText="Image" hintText="" />
              <input type="file" name="picUrl" className={imgInputClassName} onChange={this.onImageSelected} />
              {isImageError && <div className="invalid-feedback">Error uploading image</div>}
            </div>

            {isPicUrlExists && (
              <div className="mMaxWidth200px">
                <button type="button" className="close" onClick={this.onRemoveImageClick}>
                  <span>&times;</span>
                </button>
                <img className="img-fluid img-thumbnail" src={picUrl} alt="News picture" />
              </div>
            )}
            <button type="button" className="btn btn-secondary mr-3 mt-3 mb-3" onClick={this.props.onCloseClick}>
              Cancel
            </button>
            <button type="submit" className="btn btn-primary mt-3 mb-3">
              Save
            </button>
          </Form>
        )}
      />
    );
  }
}
