import axios from 'axios';
import { AppUser } from 'Src/views/App/App';

export function getBasket(user: AppUser) {
  const { roleSessionKey: key, activeSchoolId, userId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/user/${userId}/basket`, config).then(response => {
    return response.data;
  });
}

export function addOrderToBasket(
  user: AppUser,
  schoolId: string,
  clubId: string,
  studentId: string,
  price: number,
  messageId: string
) {
  const { roleSessionKey: key, activeSchoolId, userId } = user;

  const config = {
    headers: { usid: key }
  };

  const body = {
    schoolId,
    clubId,
    studentId,
    price,
    messageId
  };

  return axios
    .put(`${window.apiBase}/i/schools/${activeSchoolId}/user/${userId}/basket/add/item`, body, config)
    .then(response => {
      return response.data;
    });
}

export function deleteOrderFromBasket(user: AppUser, itemId: string) {
  const { roleSessionKey: key, activeSchoolId, userId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .delete(`${window.apiBase}/i/schools/${activeSchoolId}/user/${userId}/basket/delete/item/${itemId}`, config)
    .then(response => {
      return response.data;
    });
}

export function createBasketPayment(
  user: AppUser,
  basketId: string,
  data: { successUrl: string; cancelUrl: string; messageIds: string[] }
) {
  const { roleSessionKey: key } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.post(`${window.apiBase}/i/children/basket/${basketId}/payment`, data, config).then(response => {
    return response.data;
  });
}
