import * as React from 'react';
import { Component } from 'react';
import { Field, Form, Formik } from 'formik';
import { Button } from 'Src/components/Button/Button';
import * as Yup from 'yup';
import {
  CLUB_PARTICIPATION_MESSAGE_INVITATION_STATUS,
  MESSAGE_INVITATION_STATUS_SERVER_TO_CLIENT_MAPPING
} from 'Src/consts/message';
import { MAX_CLUB_PRIORITY, MIN_CLUB_PRIORITY } from 'Src/consts/club';

interface Props {
  onCancel: () => void;
  onSubmit: (data: { invitationStatus: string; priority?: number }) => void;
  message: any;
}

const ClubMessageStatusSchema = Yup.object().shape({
  invitationStatus: Yup.string().required('Required'),
  priority: Yup.number()
    .max(10)
    .min(0)
});

export class ClubMessageStatusForm extends Component<Props> {
  renderMessagePriorityOptions() {
    let options = [];

    for (let index = MIN_CLUB_PRIORITY; index <= MAX_CLUB_PRIORITY; index++) {
      options.push(
        <option key={index} value={index}>
          {index}
        </option>
      );
    }

    options.unshift(
      <option key={`empty_priority`} value={0}>
        Not select
      </option>
    );

    return options;
  }

  render() {
    const { message } = this.props;
    const { invitationStatus, priority } = message;

    const clubMessageStatusForm = {
      invitationStatus:
        invitationStatus === CLUB_PARTICIPATION_MESSAGE_INVITATION_STATUS.REJECTED
          ? CLUB_PARTICIPATION_MESSAGE_INVITATION_STATUS.REJECTED
          : CLUB_PARTICIPATION_MESSAGE_INVITATION_STATUS.ACCEPTED,
      priority: typeof priority !== 'undefined' ? priority : 0
    };

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <Formik
              initialValues={clubMessageStatusForm}
              validationSchema={ClubMessageStatusSchema}
              onSubmit={values => {
                this.props.onSubmit(values);
              }}
              render={({ touched, errors }) => (
                <Form>
                  <div className="form-group form-check">
                    <div className="form-group">
                      <label>Status</label>
                      <Field component="select" name="invitationStatus" className="form-control mb-3">
                        <option
                          key={CLUB_PARTICIPATION_MESSAGE_INVITATION_STATUS.ACCEPTED}
                          value={CLUB_PARTICIPATION_MESSAGE_INVITATION_STATUS.ACCEPTED}
                        >
                          {MESSAGE_INVITATION_STATUS_SERVER_TO_CLIENT_MAPPING.ACCEPTED}
                        </option>
                        <option
                          key={CLUB_PARTICIPATION_MESSAGE_INVITATION_STATUS.REJECTED}
                          value={CLUB_PARTICIPATION_MESSAGE_INVITATION_STATUS.REJECTED}
                        >
                          {MESSAGE_INVITATION_STATUS_SERVER_TO_CLIENT_MAPPING.REJECTED}
                        </option>
                      </Field>
                      {touched.invitationStatus && errors.invitationStatus ? (
                        <div className="alert alert-danger">{errors.invitationStatus}</div>
                      ) : null}
                    </div>

                    <div className="form-group">
                      <label>Priority</label>
                      <Field component="select" name="priority" className="form-control mb-3">
                        {this.renderMessagePriorityOptions()}
                      </Field>
                      {touched.priority && errors.priority ? (
                        <div className="alert alert-danger">{errors.priority}</div>
                      ) : null}
                    </div>

                    <Button
                      onClick={this.props.onCancel}
                      text={'Cancel'}
                      customClass={'mt-3 mb-3 mr-3 btn-secondary'}
                    />
                    <button type="submit" className="mt-3 mb-3 btn btn-primary">
                      Save
                    </button>
                  </div>
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}
