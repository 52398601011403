export const ABSENCE_MESSAGE_INTERVAL = {
  FULL: 'FULL',
  HALF: 'HALF'
};

export const ABSENCE_MESSAGE_INTERVAL_SERVER_TO_CLIENT_MAPPING = {
  FULL: 'Full day',
  HALF: 'Half day'
};

export const ABSENCE_MESSAGE_REASON = {
  ILLNESS: 'ILLNESS',
  MEDICAL_APPOINTMENT: 'MEDICAL_APPOINTMENT',
  OTHER_REASON: 'OTHER_REASON'
};

export const ABSENCE_MESSAGE_REASON_SERVER_TO_CLIENT_MAPPING = {
  ILLNESS: 'Illness',
  MEDICAL_APPOINTMENT: 'Medical appointment',
  OTHER_REASON: 'Other reason (please provide details in the Details box below)'
};