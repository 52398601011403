import * as React from 'react';
import * as propz from 'propz';
import * as Lazy from 'lazy.js';
import * as Moment from 'moment';
import { FunctionComponent } from 'react';
import { Club } from 'Src/models/club';
import { School } from 'Src/models/school';
import {
  CLUB_CURRENCY_SYMBOL,
  CLUB_PRICING_SERVER_TO_CLIENT_MAPPING,
  CLUB_SCHEDULE,
  CLUB_STAFF
} from 'Src/consts/club';
import { DATE_FORMAT, WEEK_DAYS_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/date';
import { getClubTime } from 'Src/helpers/club/club';
import { SPORT_GENDER_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/common';
import { AGE_GROUPS_SORTED } from 'Src/consts/school';

interface Props {
  club: Club;
  school: School;
}

const ROWS = [
  { title: 'Name', field: 'name' },
  { title: 'Description', field: 'description' },
  { title: 'Gender', field: 'gender' },
  { title: 'Activity', field: 'sport.name' },
  { title: 'Ages', field: 'ageGroups' },
  { title: 'Maximum number of students', field: 'maxParticipants' },
  { title: 'Price type', field: 'priceType' },
  { title: 'Price', field: 'price' },
  { title: 'Venue', field: 'venue.placeName' },
  { title: 'Coach/Leader', field: 'coaches' },
  { title: 'Member of staff', field: 'membersOfStaff' },
  { title: 'Start', field: 'start' },
  { title: 'End', field: 'end' },
  { title: 'Week days', field: 'weekDays' },
  { title: 'Time', field: 'time' },
  { title: 'Duration (min)', field: 'schedule.duration' }
];

export const ClubSummary: FunctionComponent<Props> = props => {
  const { school } = props;
  const ageGroupsNaming = propz.get(school, ['ageGroupsNaming'], '');
  const ageGroupSorted = propz.get(AGE_GROUPS_SORTED, [ageGroupsNaming]);

  const rows = ROWS.map((row, index) => {
    let contentCell: string;
    const field = row.field;

    const club = props.club;

    switch (field) {
      case 'gender':
        contentCell = SPORT_GENDER_SERVER_TO_CLIENT_MAPPING[club.gender];
        break;
      case 'ageGroups':
        const ages = (Lazy(club.ageGroups) as any)
          .sort((age1, age2) => {
            const ageIndex1 = ageGroupSorted.indexOf(age1);
            const ageIndex2 = ageGroupSorted.indexOf(age2);

            return ageIndex1 - ageIndex2;
          })
          .join('\n');

        contentCell = ages;
        break;
      case 'priceType':
        contentCell = CLUB_PRICING_SERVER_TO_CLIENT_MAPPING[club.price.priceType];
        break;
      case 'price':
        contentCell = `${CLUB_CURRENCY_SYMBOL[club.price.currency]}${club.price.price}`;
        break;
      case 'coaches':
        contentCell = club.staff
          .filter(coach => coach.staffRole === CLUB_STAFF.COACH)
          .map(coach => `${coach.firstName} ${coach.lastName}`)
          .join('\n');
        break;
      case 'membersOfStaff':
        contentCell = club.staff
          .filter(memberOfStaff => memberOfStaff.staffRole === CLUB_STAFF.MEMBER_OF_STAFF)
          .map(memberOfStaff => `${memberOfStaff.firstName} ${memberOfStaff.lastName}`)
          .join('\n');
        break;
      case 'start':
        switch (club.schedule.scheduleType) {
          case CLUB_SCHEDULE.WEEKLY_SCHEDULE:
            contentCell = Moment.utc(club.schedule.startDate).format(DATE_FORMAT);
            break;

          case CLUB_SCHEDULE.INTERVALS:
            const startDate = club.schedule.intervals[0].start;
            contentCell = Moment.utc(startDate).format(DATE_FORMAT);
            break;

          default:
            console.error('schedule type not defined');
            contentCell = Moment.utc(club.schedule.startDate).format(DATE_FORMAT);
        }
        break;
      case 'end':
        switch (club.schedule.scheduleType) {
          case CLUB_SCHEDULE.WEEKLY_SCHEDULE:
            contentCell = Moment.utc(club.schedule.finishDate).format(DATE_FORMAT);
            break;

          case CLUB_SCHEDULE.INTERVALS:
            const finishDate = club.schedule.intervals[club.schedule.intervals.length - 1].end;
            contentCell = Moment.utc(finishDate).format(DATE_FORMAT);
            break;

          default:
            console.error('schedule type not defined');
            contentCell = Moment.utc(club.schedule.finishDate).format(DATE_FORMAT);
        }
        break;
      case 'weekDays':
        contentCell = club.schedule.days.map(day => WEEK_DAYS_SERVER_TO_CLIENT_MAPPING[day]).join('\n');
        break;
      case 'time':
        contentCell = getClubTime(club);
        break;
      default:
        contentCell = propz.get(club, row.field.split('.'), '');
    }

    return (
      <tr key={`club_summary_${index}`}>
        <td>{row.title}</td>
        <td>{contentCell}</td>
      </tr>
    );
  });

  return (
    <table className={'table table-sm mPre mVerticalAlignMiddle'}>
      <tbody>{rows}</tbody>
    </table>
  );
};

ClubSummary.displayName = 'ClubSummary';
