import axios from 'axios';
import { AppUser } from 'Src/views/App/App';
import { DEFAULT_FILTER } from './user';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from 'Src/consts/table';
import { Count } from '../../../models/count';
import { SchoolStudentClash, SchoolStudent } from '../../../models/schoolStudent';
import { TeamPlayer, TeamPlayerWithClashes } from '../../../models/team';

export function getSchoolTeamPlayers(user: AppUser, teamId: string, filter = DEFAULT_FILTER): Promise<TeamPlayer[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter
    }
  };

  return axios
    .get<TeamPlayer[]>(`${window.apiBase}/i/schools/${activeSchoolId}/teams/${teamId}/players`, config)
    .then(response => {
      return response.data;
    });
}

export function getAllSchoolTeamPlayers(user: AppUser, teamId: string, where?): Promise<TeamPlayer[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios
    .get<TeamPlayer[]>(`${window.apiBase}/i/schools/${activeSchoolId}/teams/${teamId}/players`, config)
    .then(response => {
      return response.data;
    });
}

export function getSchoolTeamPlayersCount(user: AppUser, teamId: string, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where
        }
      }
    };
  } else {
    config = {
      headers: { usid: key }
    };
  }

  return axios
    .get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/teams/${teamId}/players/count`, config)
    .then(response => {
      return response.data;
    });
}

export interface SchoolTeamPlayersBatchData {
  add?: SchoolTeamPlayerBatchData[];
  remove?: string[];
  update?: any[];
}
interface SchoolTeamPlayerBatchData {
  userId: string;
  permissionId: string;
}

export function updateSchoolTeamPlayersBatch(user: AppUser, teamId: string, data: SchoolTeamPlayersBatchData) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/teams/${teamId}/players/batch`, data, config)
    .then(response => {
      return response.data;
    });
}

export function getSchoolStudentsWithClashes(user: AppUser, filter): Promise<SchoolStudentClash[]> {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .post<SchoolStudentClash[]>(`${window.apiBase}/i/schools/${activeSchoolId}/studentsWithClashes`, filter, config)
    .then(response => {
      return response.data;
    });
}

export function getSchoolStudentsWithClashesCount(user: AppUser, filter?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .post<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/studentsWithClashes/count`, filter, config)
    .then(response => {
      return response.data;
    });
}

export function getSchoolStudentsWithoutClashes(user: AppUser, filter): Promise<SchoolStudentClash[]> {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .post<SchoolStudentClash[]>(`${window.apiBase}/i/schools/${activeSchoolId}/studentsWithoutClashes`, filter, config)
    .then(response => {
      return response.data;
    });
}

export function getSchoolTeamPlayersWithClashes(
  user: AppUser,
  teamId: string,
  filter
): Promise<TeamPlayerWithClashes[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .post<TeamPlayerWithClashes[]>(
      `${window.apiBase}/i/schools/${activeSchoolId}/teams/${teamId}/playersWithClashes`,
      filter,
      config
    )
    .then(response => {
      return response.data;
    });
}

export function getSchoolTeamPlayersWithoutClashes(
  user: AppUser,
  teamId: string,
  filter
): Promise<TeamPlayerWithClashes[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .post<TeamPlayerWithClashes[]>(
      `${window.apiBase}/i/schools/${activeSchoolId}/teams/${teamId}/playersWithoutClashes`,
      filter,
      config
    )
    .then(response => {
      return response.data;
    });
}
