import axios from 'axios';
import { AppUser } from 'Src/views/App/App';

export function getChildrenEvents(user: AppUser, filter) {
  const { roleSessionKey: key } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: filter
    }
  };

  return axios.get(`${window.apiBase}/i/children/events`, config).then(response => {
    return response.data;
  });
}

export function getChildrenEvent(user: AppUser, eventId) {
  const { roleSessionKey: key } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.get(`${window.apiBase}/i/children/events/${eventId}`, config).then(response => {
    return response.data;
  });
}

export function getChildrenEventsDates(user: AppUser, filter) {
  const { roleSessionKey: key } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: filter
    }
  };

  return axios.get(`${window.apiBase}/i/children/events/dates/distinct`, config).then(response => {
    return response.data;
  });
}

export function sentChildrenAvailabilityReport(user: AppUser, eventId, data) {
  const { roleSessionKey: key } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.post(`${window.apiBase}/i/children/events/${eventId}/messages/report`, data, config).then(response => {
    return response.data;
  });
}

export function getChildrenEventTasks(user: AppUser, eventId: string, activeSchoolId: string) {
  const { roleSessionKey: key } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/tasks`, config).then(response => {
    return response.data;
  });
}

export function getSchoolEventMatchReportForParent(user: AppUser, schoolId: string, eventId: string) {
  const { roleSessionKey: key } = user;
  const config = {
    headers: { usid: key }
  };

  return axios.get(`${window.apiBase}/i/schools/${schoolId}/events/${eventId}/report`, config).then(response => {
    return response.data;
  });
}
