import axios from 'axios';
import { AppUser } from 'Src/views/App/App';
import { SchoolStudent } from '../../../models/schoolStudent';

export function getSchoolStudentsList(user: AppUser, filter): Promise<SchoolStudent[]> {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    ...filter,
    headers: { usid: key }
  };

  return axios
    .post<SchoolStudent[]>(`${window.apiBase}/i/schools/${activeSchoolId}/studentsList`, filter, config)
    .then(response => {
      return response.data;
    });
}
