import { SchoolLeague } from '../../models/schoolLeague';
import { LEAGUE_AGGREGATION_TYPE } from '../../consts/league';

export function isLeagueIndividual(league: SchoolLeague): boolean {
  return league.aggregationType === LEAGUE_AGGREGATION_TYPE.INDIVIDUAL;
}

export function isLeagueTeam(league: SchoolLeague): boolean {
  return league.aggregationType === LEAGUE_AGGREGATION_TYPE.TEAM;
}

export function isLeagueHouse(league: SchoolLeague): boolean {
  return league.aggregationType === LEAGUE_AGGREGATION_TYPE.HOUSE;
}
