import * as React from 'react';
import { FunctionComponent } from 'react';

interface Props {
  admins: any[];
}

export const SchoolAdminsSummary: FunctionComponent<Props> = props => {
  const { admins } = props;

  const rows = admins.map((admin, index) => {
    return (
      <tr key={`tournament_${index}`}>
        <td>{index + 1}</td>
        <td>{admin.firstName}</td>
        <td>{admin.lastName}</td>
        <td>{admin.email}</td>
      </tr>
    );
  });

  const emptyRow = (
    <tr key={'tournament_empty_row'}>
      <td>No admins</td>
    </tr>
  );

  return (
    <table className={'table table-sm mPre mVerticalAlignMiddle'}>
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Surname</th>
          <th>Email</th>
        </tr>
      </thead>
      <tbody>{rows.length > 0 ? rows : emptyRow}</tbody>
    </table>
  );
};

SchoolAdminsSummary.displayName = 'SchoolAdminsSummary';
