import * as React from 'react';
import { AdminBoardGalleryItem } from './ArbiterBoard';
import { ACCESS_PRESET_TYPE } from '../../../../../../types/album';
import { ACCESS_PRESET_SERVER_TO_CLIENT_MAPPING } from '../../../../../../consts/album';

interface Props {
  item: AdminBoardGalleryItem;
}

export function ArbiterBoardGalleryItemMediaDetails(props: Props) {
  const { item } = props;
  const { uploadedAt, uploadedBy, accessPreset } = item;
  return (
    <div>
      <div className="eMediaDetailsTitle pl-5">MEDIA DETAILS</div>
      <div className="container pl-5 pt-2">
        <div className="row">
          <div className="col-md-2">
            <div className="eMediaDetailsSubTitle">Uploaded:</div>
          </div>
          <div className="col-md-10">
            <div className="eMediaDetailsText">
              {uploadedAt} by {uploadedBy}
            </div>
          </div>
        </div>
      </div>
      <div className="container pl-5 pt-3">
        <div className="row">
          <div className="col-md-2">
            <div className="eMediaDetailsSubTitle">Access Level:</div>
          </div>
          <div className="col-md-10">
            <div className="eAccessLevelType">{ACCESS_PRESET_SERVER_TO_CLIENT_MAPPING[accessPreset]}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
