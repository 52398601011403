import * as React from 'react';
import { Component } from 'react';
import { AppUser } from 'Src/views/App/App';
import { ModalDialog } from '../ModalDialog/ModalDialog';
import { SchoolForm } from '../../models/form';

interface Props {
  user: AppUser;
  onSubmitFormsClick: (form) => void;
  onCancelClick: () => void;
  forms: SchoolForm[];
  isOpen: boolean;
}

interface State {
  selectedFormId: string;
}

export class FormRemoveModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const selectedId = typeof props.forms !== 'undefined' && props.forms.length > 0 ? props.forms[0].id : '';
    this.state = { selectedFormId: selectedId };
  }

  onFormSelected = (event): void => {
    this.setState({ selectedFormId: event.target.value });
  };

  onFormsSubmit = (): void => {
    const form = this.props.forms.find(form => form.id === this.state.selectedFormId);
    this.props.onSubmitFormsClick(form);
  };

  render() {
    return (
      <ModalDialog
        title="Remove form"
        isOpen={this.props.isOpen}
        positiveButtonTitle="Remove"
        negativeButtonTitle="Cancel"
        onPositiveButtonClick={this.onFormsSubmit}
        onNegativeButtonClick={this.props.onCancelClick}
      >
        <div className="form-group">
          <label>Form</label>
          <select value={this.state.selectedFormId} className="form-control" onChange={this.onFormSelected}>
            {this.props.forms.map((form, index) => (
              <option key={form.id} value={form.id}>
                {form.name}
              </option>
            ))}
          </select>
        </div>
      </ModalDialog>
    );
  }
}
