import * as React from 'react';
import { FunctionComponent } from 'react';
import * as classNames from 'classnames';

interface Props {
  isOpen: boolean;
  title?: string;
  positiveButtonTitle: string;
  negativeButtonTitle: string;
  onPositiveButtonClick: () => void;
  onNegativeButtonClick: () => void;
}

export const ModalDialog: FunctionComponent<Props> = props => {
  if (!props.isOpen) {
    return null;
  }

  const onPositiveButtonClick = (event: Event): void => {
    event.preventDefault();
    props.onPositiveButtonClick();
  };

  const onNegativeButtonClick = (event: Event): void => {
    event.preventDefault();
    props.onNegativeButtonClick();
  };

  const modalClasses = classNames({
    mDisplayBlock: props.isOpen,
    show: props.isOpen,
    modal: true,
    fade: true
  });

  return (
    <div className={modalClasses}>
      <div className="modal-dialog">
        <div className="modal-content">
          {typeof props.title !== 'undefined' && (
            <div className="modal-header">
              <h5 className="modal-title">{props.title}</h5>
              <button type="button" className="close" onClick={() => onNegativeButtonClick(event)}>
                <span>&times;</span>
              </button>
            </div>
          )}

          <div className="modal-body">{props.children}</div>

          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={() => onNegativeButtonClick(event)}>
              {props.negativeButtonTitle}
            </button>
            <button type="button" className="btn btn-primary" onClick={() => onPositiveButtonClick(event)}>
              {props.positiveButtonTitle}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

ModalDialog.displayName = 'ModalDialog';
