import * as React from 'react';
import { Field, Form, Formik } from 'formik';
import { SchoolEvent } from 'Src/models/event';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING, REPORT_PRINT_FORMAT } from 'Src/consts/report';
import { Switch } from 'Src/components/Switch/Switch';
import { LabelWithQuestionIcon } from 'Src/components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import '../../SchoolEventView.scss';

interface Props {
  event: SchoolEvent;
  activeSchoolId: string;
  onCloseClick: () => void;
  onSubmit: (data: any, reportType: string) => void;
}

//report 0081
export const ExtendedParticipantListReportForm = ({ onCloseClick, onSubmit, event, activeSchoolId }: Props) => {
  const [schoolData, setSchoolData] = React.useState<any>(null);

  React.useEffect(() => {
    fetch('/dist/images/mis/misIntegrationSchoolsList.json')
      .then(response => response.json())
      .then(data => {
        const matchedSchoolData = data.find(item => item.schoolSITId === activeSchoolId);
        setSchoolData(matchedSchoolData);
      })
      .catch(error => console.error('Error loading JSON:', error));
  }, [activeSchoolId]);

  const { id: eventId } = event;

  const requestInitial = {
    isPrintFormName: true,
    isPrintYearGroup: false,
    isPrintGender: false,
    isPrintDOB: false,
    isPrintHouse: false,
    isPrintPP: schoolData ? schoolData.studentsPPInfo === 1 : false,
    isPrintSEN: schoolData ? schoolData.studentsSENInfo === 1 : false,
    isPrintFSM: schoolData ? schoolData.studentsFSMInfo === 1 : false,
    isPrintPhotoConsentOptOut: false,
    isPrintPosition: true,
    isPrintMedicalInfo: schoolData ? schoolData.studentsMedicalInfo === 1 : false,
    isPrintNextOfKin: true,
    isPrintDescription: true,
    isPrintKitNotes: true,
    isPrintComments: true,
    isPrintTransportDetails: true,
    isPrintOppositionContactDetails: false,
    isPrintMembersOfStaff: true,
    isPrintEventTimings: true,
    printFormat: REPORT_PRINT_FORMAT.PDF
  };

  return (
    <Formik
      onSubmit={values => {
        const {
          isPrintFormName,
          isPrintYearGroup,
          isPrintGender,
          isPrintDOB,
          isPrintHouse,
          isPrintPP,
          isPrintSEN,
          isPrintFSM,
          isPrintPhotoConsentOptOut,
          isPrintPosition,
          isPrintMedicalInfo,
          isPrintNextOfKin,
          isPrintDescription,
          isPrintKitNotes,
          isPrintComments,
          isPrintTransportDetails,
          isPrintOppositionContactDetails,
          isPrintMembersOfStaff,
          isPrintEventTimings,
          printFormat
        } = values;

        let isPrintXLSX = false;
        let isPrintPDF = false;

        switch (printFormat) {
          case REPORT_PRINT_FORMAT.XLSX:
            isPrintXLSX = true;
            break;
          case REPORT_PRINT_FORMAT.PDF:
            isPrintPDF = true;
            break;
        }

        const data = {
          schoolId: activeSchoolId,
          eventId: eventId,
          isPrintFormName,
          isPrintYearGroup,
          isPrintGender,
          isPrintDOB,
          isPrintHouse,
          isPrintPP,
          isPrintSEN,
          isPrintFSM,
          isPrintPhotoConsentOptOut,
          isPrintPosition,
          isPrintMedicalInfo,
          isPrintNextOfKin,
          isPrintDescription,
          isPrintKitNotes,
          isPrintComments,
          isPrintTransportDetails,
          isPrintOppositionContactDetails,
          isPrintMembersOfStaff,
          isPrintEventTimings,
          isPrintXLSX,
          isPrintPDF
        };

        onSubmit(data, REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.EXTENDED_PARTICIPANT_LIST);
      }}
      initialValues={requestInitial}
      render={({ values, setFieldValue }) => (
        <Form>
          <div className="bReportFormTitle">{'Extended participant list'}</div>
          <div className="mb-3 mt-3">
            {'A report that enables you to see in depth details surrounding your club register). ' +
              'View all relevant information linked to each student participating.'}
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isPrintFormName"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintFormName}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintFormName = event.target.checked;
                      setFieldValue('isPrintFormName', isPrintFormName);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show school form"
                hintText={`This will enable you to view the students form groups.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintFormName"
              />
            </div>
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isPrintYearGroup"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintYearGroup}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintYearGroup = event.target.checked;
                      setFieldValue('isPrintYearGroup', isPrintYearGroup);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show year group"
                hintText={`This will enable you to view the students yeargroups.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintYearGroup"
              />
            </div>
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isPrintGender"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintGender}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintGender = event.target.checked;
                      setFieldValue('isPrintGender', isPrintGender);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show gender"
                hintText={`This will enable you to view the students assigned gender.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintGender"
              />
            </div>
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isPrintDOB"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintDOB}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintDOB = event.target.checked;
                      setFieldValue('isPrintDOB', isPrintDOB);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show date of birth"
                hintText={`This will enable you to view the students date of birth.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintDOB"
              />
            </div>
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isPrintHouse"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintHouse}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintHouse = event.target.checked;
                      setFieldValue('isPrintHouse', isPrintHouse);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show house"
                hintText={`This will enable you to view the students house.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintHouse"
              />
            </div>
          </div>

          {schoolData && schoolData.studentsPPInfo === 1 && (
            <div className="form-check">
              <div className="d-flex">
                <Field
                  name="isPrintPP"
                  render={({ field }) => (
                    <Switch
                      {...field}
                      value={values.isPrintPP}
                      customClass="mb-3"
                      onChange={event => {
                        const isPrintPP = event.target.checked;
                        setFieldValue('isPrintPP', isPrintPP);
                      }}
                    />
                  )}
                />
                <LabelWithQuestionIcon
                  labelText="Show PP"
                  hintText={'Show information relating to PP'}
                  customLabelClass="form-check-label pl-2"
                  labelHtmlFor="isPrintPP"
                />
              </div>
            </div>
          )}

          {schoolData && schoolData.studentsSENInfo === 1 && (
            <div className="form-check">
              <div className="d-flex">
                <Field
                  name="isPrintSEN"
                  render={({ field }) => (
                    <Switch
                      {...field}
                      value={values.isPrintSEN}
                      customClass="mb-3"
                      onChange={event => {
                        const isPrintSEN = event.target.checked;
                        setFieldValue('isPrintSEN', isPrintSEN);
                      }}
                    />
                  )}
                />
                <LabelWithQuestionIcon
                  labelText="Show SEN"
                  hintText={'Show information relating to SEN.'}
                  customLabelClass="form-check-label pl-2"
                  labelHtmlFor="isPrintSEN"
                />
              </div>
            </div>
          )}

          {schoolData && schoolData.studentsFSMInfo === 1 && (
            <div className="form-check">
              <div className="d-flex">
                <Field
                  name="isPrintFSM"
                  render={({ field }) => (
                    <Switch
                      {...field}
                      value={values.isPrintFSM}
                      customClass="mb-3"
                      onChange={event => {
                        const isPrintFSM = event.target.checked;
                        setFieldValue('isPrintFSM', isPrintFSM);
                      }}
                    />
                  )}
                />
                <LabelWithQuestionIcon
                  labelText="Show FSM"
                  hintText={'Show information relating to FSM.'}
                  customLabelClass="form-check-label pl-2"
                  labelHtmlFor="isPrintFSM"
                />
              </div>
            </div>
          )}

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isPrintPosition"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintPosition}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintPosition = event.target.checked;
                      setFieldValue('isPrintPosition', isPrintPosition);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show positions"
                hintText={'Show assigned positions on the pitch.'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintPosition"
              />
            </div>
          </div>

          {schoolData && schoolData.studentsMedicalInfo === 1 && (
            <div className="form-check">
              <div className="d-flex">
                <Field
                  name="isPrintMedicalInfo"
                  render={({ field }) => (
                    <Switch
                      {...field}
                      value={values.isPrintMedicalInfo}
                      customClass="mb-3"
                      onChange={event => {
                        const isPrintMedicalInfo = event.target.checked;
                        setFieldValue('isPrintMedicalInfo', isPrintMedicalInfo);
                      }}
                    />
                  )}
                />
                <LabelWithQuestionIcon
                  labelText="Show medical info"
                  hintText={'Show any medical information relating to students.'}
                  customLabelClass="form-check-label pl-2"
                  labelHtmlFor="isPrintMedicalInfo"
                />
              </div>
            </div>
          )}

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isPrintNextOfKin"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintNextOfKin}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintNextOfKin = event.target.checked;
                      setFieldValue('isPrintNextOfKin', isPrintNextOfKin);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show next of kin details"
                hintText={'Show emergency contact details relating to specific students.'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintNextOfKin"
              />
            </div>
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isPrintPhotoConsentOptOut"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintPhotoConsentOptOut}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintPhotoConsentOptOut = event.target.checked;
                      setFieldValue('isPrintPhotoConsentOptOut', isPrintPhotoConsentOptOut);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show photo consent opt-out"
                hintText={'Select if you would like students with photo consent withdrawn to be marked.'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintPhotoConsentOptOut"
              />
            </div>
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isPrintDescription"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintDescription}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintDescription = event.target.checked;
                      setFieldValue('isPrintDescription', isPrintDescription);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show event description"
                hintText={`Show the description you have assigned to your event.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintDescription"
              />
            </div>
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isPrintKitNotes"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintKitNotes}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintKitNotes = event.target.checked;
                      setFieldValue('isPrintKitNotes', isPrintKitNotes);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show kit notes"
                hintText={`If you have added kit notes to your event these can be shown on your teamsheet.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintKitNotes"
              />
            </div>
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isPrintComments"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintComments}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintComments = event.target.checked;
                      setFieldValue('isPrintComments', isPrintComments);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show event comments"
                hintText={`If you have added comments to your event these can be shown on your teamsheet.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintComments"
              />
            </div>
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isPrintTransportDetails"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintTransportDetails}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintTransportDetails = event.target.checked;
                      setFieldValue('isPrintTransportDetails', isPrintTransportDetails);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show transport details"
                hintText={`If you have added transport details to your event these can be shown on your teamsheet.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintTransportDetails"
              />
            </div>
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isPrintOppositionContactDetails"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintOppositionContactDetails}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintOppositionContactDetails = event.target.checked;
                      setFieldValue('isPrintOppositionContactDetails', isPrintOppositionContactDetails);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show opposition contact details"
                hintText={`If you have added opposition contact details to your event these can be shown on your teamsheet.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintOppositionContactDetails"
              />
            </div>
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isPrintMembersOfStaff"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintMembersOfStaff}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintMembersOfStaff = event.target.checked;
                      setFieldValue('isPrintMembersOfStaff', isPrintMembersOfStaff);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show the coach and members of staff names"
                hintText={`View the names of the coach and members of staff assigned to the event.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintMembersOfStaff"
              />
            </div>
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isPrintEventTimings"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintEventTimings}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintEventTimings = event.target.checked;
                      setFieldValue('isPrintEventTimings', isPrintEventTimings);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show event timings"
                hintText={`If you've added timings (e.g., meet time, departure time) to your event details, they will be displayed here.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintEventTimings"
              />
            </div>
          </div>

          <div className="form-check">
            <Field name="printFormat">
              {({ field }) => (
                <>
                  <div className="d-flex">
                    <input
                      type="radio"
                      id="isPrintXLSX"
                      {...field}
                      checked={field.value === REPORT_PRINT_FORMAT.XLSX}
                      onChange={() => setFieldValue('printFormat', REPORT_PRINT_FORMAT.XLSX)}
                    />
                    <label htmlFor="isPrintXLSX" className="pl-2">
                      Print as XLSX
                    </label>
                  </div>

                  <div className="d-flex">
                    <input
                      type="radio"
                      id="isPrintPDF"
                      {...field}
                      checked={field.value === REPORT_PRINT_FORMAT.PDF}
                      onChange={() => setFieldValue('printFormat', REPORT_PRINT_FORMAT.PDF)}
                    />
                    <label htmlFor="isPrintPDF" className="pl-2">
                      Print as PDF
                    </label>
                  </div>
                </>
              )}
            </Field>
          </div>

          <button type="button" className="btn btn-secondary mr-3" onClick={onCloseClick}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Get
          </button>
        </Form>
      )}
    />
  );
};
