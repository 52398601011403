import * as React from 'react';
import { Component } from 'react';
import { SchoolEvent } from '../../../../../../../models/event';
import * as propz from 'propz';
import {
  convertPointsToString,
  getDefaultDigitMask,
  getIndividualScoreByStudent,
  getIsErrorHashMap,
  getMask,
  getPlayerScoreId,
  getScorePoints,
  getAbbreviationResultByStudent,
  compareNames
} from '../../../../../../../helpers/event/schoolEventViewHelper';
import * as TextMaskCore from 'text-mask-core';
import { Button } from '../../../../../../../components/Button/Button';
import './Results.scss';
import { ErrorHashMap, ValueHashMap } from './IndividualInternalResults';
import { EMPTY_STRING } from '../../../../../../../consts/date';
import { AppUser } from '../../../../../../App/App';
import { getSortedSchoolsFromEvent } from '../../../../../../../helpers/event/event';
import { ARROW_DOWN_KEY, ARROW_UP_KEY, ENTER_KEY } from '../../../../../../../consts/common';
import { KIND } from 'Src/consts/school';
import { Select } from 'Src/components/Select/Select';
import { Switch } from 'Src/components/Switch/Switch';
import { getSelectOptionsForNonFinishedPlayerResult } from 'Src/helpers/select/select';
import { NON_FINISHED_PLAYER_RESULTS_ARRAY } from 'Src/consts/player';

interface Props {
  event: SchoolEvent;
  onSaveClick: (data: any) => void;
  onCloseClick: () => void;
  user: AppUser;
}

interface State {
  mask: boolean | (string | RegExp)[];
  valueHashMap: ValueHashMap;
  isErrorHashMap: ErrorHashMap;
  oldValueHashMap: ValueHashMap;
  count: number;
  players: Player[];
  schoolsSorted: any[];
  abbreviations: Abbreviation[];
  selectedAbbreviations: { [playerId: string]: string };
}

interface Player {
  id: string;
  userId: string;
  permissionId: string;
  schoolId: string;
  firstName: string;
  lastName: string;
  isFinished: boolean;
  schoolName: string;
}

interface Abbreviation {
  _id: string;
  code: string;
  description: string;
  color: string;
}

export class IndividualExternalSchoolsResults extends Component<Props, State> {
  inputElement: any[];
  textMaskInputElement: any[];

  constructor(props: Props) {
    super(props);

    this.inputElement = [];
    this.textMaskInputElement = [];

    const { event, user } = props;
    const mask = propz.get(event, ['sport', 'points', 'inputMask']);

    const inputMask = getMask(mask);

    const { individualsData } = event;
    const schoolsSorted = getSortedSchoolsFromEvent(user, event);

    let valueHashMap = {};
    let oldValueHashMap = {};
    let isErrorHashMap = {};
    let players = [];
    let selectedAbbreviations = {};

    schoolsSorted.forEach(school => {
      const { kind, id: schoolId, name: schoolName } = school;
      const isSchoolUnion = kind === KIND.SCHOOL_UNION;

      const playersFiltered = individualsData.filter(individual => {
        if (isSchoolUnion) {
          const playerFromEvent = event.players.find(player => {
            return player.userId === individual.userId && player.schoolId === schoolId;
          });

          return typeof playerFromEvent !== 'undefined';
        }

        return individual.schoolId === schoolId;
      });

      playersFiltered.forEach(player => {
        players.push({ ...player, schoolName });
      });
    });

    const { scoring } = event.sport;

    players.sort((player1, player2) => {
      const result1 = getIndividualScoreByStudent(event, player1.userId, player1.permissionId);
      const result2 = getIndividualScoreByStudent(event, player2.userId, player2.permissionId);

      if ((result1 === 0 || result1 === null) && (result2 === 0 || result2 === null)) {
        return compareNames(player1, player2);
      }
      if (result1 === 0 || result1 === null) return 1;
      if (result2 === 0 || result2 === null) return -1;

      if (['LESS_TIME', 'LESS_RESULT', 'LESS_SCORES'].includes(scoring)) {
        if (result1 !== result2) {
          return result1 - result2;
        }
      } else if (['MORE_TIME', 'MORE_RESULT', 'MORE_SCORES', 'FIRST_TO_N_POINTS'].includes(scoring)) {
        if (result1 !== result2) {
          return result2 - result1;
        }
      }

      return compareNames(player1, player2);
    });

    players = players.map(player => {
      const playerPoints = getIndividualScoreByStudent(event, player.userId, player.permissionId);
      const playerPointsStr = convertPointsToString(event, playerPoints);
      const abbreviationResult = getAbbreviationResultByStudent(event, player.userId, player.permissionId);
      if (abbreviationResult) {
        selectedAbbreviations[player.id] = abbreviationResult;
      }

      valueHashMap[player.id] = playerPoints === 0 ? '' : playerPointsStr;
      oldValueHashMap[player.id] = playerPoints === 0 ? '' : playerPointsStr;
      isErrorHashMap[player.id] = false;

      return player;
    });

    this.state = {
      mask: inputMask,
      valueHashMap: valueHashMap,
      isErrorHashMap: isErrorHashMap,
      count: individualsData.length,
      oldValueHashMap: oldValueHashMap,
      players: players,
      schoolsSorted,
      abbreviations: this.props.event.sport.abbreviationAvailable
        ? this.props.event.sport.abbreviations.map(abbreviation => ({
            _id: abbreviation._id,
            code: abbreviation.code,
            description: abbreviation.description,
            color: abbreviation.color
          }))
        : [],
      selectedAbbreviations: selectedAbbreviations
    };
  }

  componentDidMount() {
    this.initTextMask();
  }

  initTextMask() {
    const { valueHashMap, players, mask } = this.state;
    if (players.length === 0) {
      return;
    }

    players.forEach((player, i) => {
      if (this.inputElement[i] && player) {
        this.textMaskInputElement[i] = TextMaskCore.createTextMaskInputElement({
          inputElement: this.inputElement[i],
          mask: mask || (inputValue => getDefaultDigitMask(this.props.event, inputValue)),
          showMask: false
        });

        const value = valueHashMap[player.id];
        this.textMaskInputElement[i].update(value);
      }
    });
  }

  onSubmit = () => {
    const { valueHashMap, players, selectedAbbreviations } = this.state;
    const { event, onSaveClick } = this.props;

    const isErrorHashMap = getIsErrorHashMap(event, valueHashMap, players);
    const isErrorPlayers = players.some(player => isErrorHashMap[player.id] === true);

    if (isErrorPlayers) {
      this.setState({
        isErrorHashMap: isErrorHashMap
      });
      return;
    }

    const playerScores = players.map(({ userId, permissionId, id, schoolId }) => {
      const scoreValue = valueHashMap[id] !== '' ? getScorePoints(event, valueHashMap, id) : 0;
      let scoreObject = {
        userId: userId,
        permissionId: permissionId,
        schoolId: schoolId,
        score: scoreValue,
        scoreId: getPlayerScoreId(event, userId, permissionId),
        richScore: { result: scoreValue } as any
      };

      if (selectedAbbreviations[id]) {
        scoreObject.richScore.abbreviationResult = selectedAbbreviations[id];
      } else {
        scoreObject.richScore.abbreviationResult = null;
      }

      return scoreObject;
    });

    onSaveClick(playerScores);
  };

  onChange(event: React.ChangeEvent<HTMLInputElement>, index: number, id: string) {
    const valueHashMap = { ...this.state.valueHashMap };
    const isErrorHashMap = { ...this.state.isErrorHashMap };

    const value = (event.target as HTMLInputElement).value;

    valueHashMap[id] = value;
    isErrorHashMap[id] = false;

    this.setState({
      valueHashMap: valueHashMap,
      isErrorHashMap: isErrorHashMap
    });

    this.textMaskInputElement[index].update(value);
  }

  onKeyDown = (eventDescriptor: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    const { count } = this.state;

    const isKeyEnter = eventDescriptor.key === ENTER_KEY;
    const isKeyArrowUp = eventDescriptor.key === ARROW_UP_KEY;
    const isKeyArrowDown = eventDescriptor.key === ARROW_DOWN_KEY;
    const isLastInput = index === count;
    const isFirstInput = index === 0;

    switch (true) {
      case isKeyEnter && !isLastInput:
      case isKeyArrowDown && !isLastInput:
        this.inputElement[index + 1].focus();
        break;
      case isKeyArrowUp && !isFirstInput:
        this.inputElement[index - 1].focus();
        break;
    }
  };

  onFocus(e: React.FocusEvent<HTMLInputElement>, index: number, id: string) {
    const value = this.state.valueHashMap[id];
    const oldValueHashMap = { ...this.state.oldValueHashMap };
    oldValueHashMap[id] = value;

    this.setState({
      oldValueHashMap: oldValueHashMap
    });

    this.textMaskInputElement[index].update('');

    e.stopPropagation();
  }

  onBlur(e: React.FocusEvent<HTMLInputElement>, index: number, id: string) {
    const eventValue = (e.target as HTMLInputElement).value;
    const oldValueHashMap = { ...this.state.oldValueHashMap };

    if (eventValue === EMPTY_STRING) {
      const valueHashMapWithOldValue = { ...this.state.valueHashMap };
      valueHashMapWithOldValue[id] = oldValueHashMap[id];
      this.setState({
        valueHashMap: valueHashMapWithOldValue
      });
      this.textMaskInputElement[index].update(oldValueHashMap[id]);
    } else {
      const valueHashMap = { ...this.state.valueHashMap };
      valueHashMap[id] = eventValue;
      this.setState({
        valueHashMap: valueHashMap
      });
      this.textMaskInputElement[index].update(eventValue);
    }

    e.stopPropagation();
  }

  onNonFinishedResultChange(value: string, id: string) {
    const valueHashMap = { ...this.state.valueHashMap };
    valueHashMap[id] = value;

    this.setState({
      valueHashMap: valueHashMap
    });
  }

  renderInput(index: number, id: string): React.ReactNode {
    const { isErrorHashMap, valueHashMap } = this.state;
    const currentPlayer = this.state.players.find(player => player.id === id);

    const options = getSelectOptionsForNonFinishedPlayerResult();

    const isPlayerNonFinished = NON_FINISHED_PLAYER_RESULTS_ARRAY.includes(valueHashMap[id]);
    const activeResultIndex = isPlayerNonFinished ? options.findIndex(item => item.value === valueHashMap[id]) : 0;

    return (
      <>
        <input
          ref={el => (this.inputElement[index] = el)}
          onKeyDown={event => this.onKeyDown(event, index)}
          onChange={event => this.onChange(event, index, id)}
          onFocus={event => this.onFocus(event, index, id)}
          onBlur={event => this.onBlur(event, index, id)}
          style={{ maxWidth: '80px' }}
          className={
            isErrorHashMap[id] === true
              ? 'eIndividualExternalSchoolsResultsInput mError'
              : 'eIndividualExternalSchoolsResultsInput'
          }
        />
        {
          // TODO: Non-finished logic
          /* {currentPlayer.isFinished ? (
          <input
            ref={el => (this.inputElement[index] = el)}
            onKeyDown={event => this.onKeyDown(event, index)}
            onChange={event => this.onChange(event, index, id)}
            onFocus={event => this.onFocus(event, index, id)}
            onBlur={event => this.onBlur(event, index, id)}
            className={
              isErrorHashMap[id] === true
                ? 'eIndividualExternalSchoolsResultsInput mError'
                : 'eIndividualExternalSchoolsResultsInput'
            }
          />
        ) : (
          <Select
            onChange={value => this.onNonFinishedResultChange(value, id)}
            options={options}
            isSetDefaultValue
            activeResultIndex={activeResultIndex}
            customClass={'form-control w-100'}
          />
        )} */
        }
      </>
    );
  }

  onChangeIsFinishedPlayerStatus(id: string) {
    const { players } = this.state;

    const playersUpdated = players.map(player => {
      return player.id === id
        ? {
            ...player,
            isFinished: !player.isFinished
          }
        : player;
    });

    this.setState(
      {
        players: playersUpdated
      },
      () => {
        this.initTextMask();
      }
    );
  }

  renderAbbreviationCheckboxes(playerId: string) {
    return (
      <div className="abbreviation-checkboxes-container">
        {this.state.abbreviations.map((abbreviation, index) => (
          <label key={index} className="abbreviation-checkbox-label">
            <input
              type="checkbox"
              className="abbreviation-checkbox"
              id={`${abbreviation.code}_${playerId}`}
              name="abbreviation"
              checked={this.state.selectedAbbreviations[playerId] === abbreviation.code}
              onChange={e => this.handleAbbreviationChange(playerId, abbreviation.code, e.target.checked)}
            />
            {abbreviation.code}
          </label>
        ))}
      </div>
    );
  }

  handleAbbreviationChange(playerId: string, code: string, isChecked: boolean) {
    if (isChecked) {
      this.setState(prevState => ({
        selectedAbbreviations: {
          ...prevState.selectedAbbreviations,
          [playerId]: code
        }
      }));
    } else {
      const selectedAbbreviations = { ...this.state.selectedAbbreviations };
      delete selectedAbbreviations[playerId];
      this.setState({ selectedAbbreviations });
    }
  }

  render() {
    const { event, onCloseClick } = this.props;
    const { players, abbreviations } = this.state;

    const isPlayersExist = players.length > 0;
    const hasAbbreviations = abbreviations.length > 0;

    if (!isPlayersExist) {
      return (
        <>
          <h1>Error</h1>
          <div className="alert alert-danger">First, add players in event</div>
          <Button text={'Close'} onClick={onCloseClick} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
        </>
      );
    }

    return (
      <div style={{ width: '100%' }}>
        <h2>Participant results</h2>
        <table className={'eIndividualExternalSchoolsResultsTable'}>
          <thead>
            <tr>
              <th className={'eIndividualExternalSchoolsResultsCell'} style={{ width: '5%' }}>
                #
              </th>
              <th className={'eIndividualExternalSchoolsResultsCell'} style={{ width: '40%' }}>
                Player
              </th>
              <th className={'eIndividualExternalSchoolsResultsCell'} style={{ width: '15%' }}>
                School
              </th>
              <th className={'eIndividualExternalSchoolsResultsCell'} style={{ width: '15%' }}>
                Results
              </th>
              {hasAbbreviations && (
                <th className={'eIndividualExternalSchoolsResultsCell'} style={{ width: '25%' }}></th>
              )}
            </tr>
          </thead>
          <tbody>
            {players.map(({ firstName, lastName, id, schoolName }, index) => {
              const position = index + 1;
              const schoolAbbreviation = schoolName
                .split(' ')
                .map(word => word[0])
                .join('');

              return (
                <tr key={`individual_external_schools_results_player_${index}`}>
                  <td className={'eIndividualExternalSchoolsResultsCell'} style={{ width: '5%' }}>
                    {position}.
                  </td>
                  <td className={'eIndividualExternalSchoolsResultsCell'} style={{ width: '40%' }}>
                    {`${firstName} ${lastName}`}
                  </td>
                  <td
                    className={'eIndividualExternalSchoolsResultsCell school-name-abbreviation'}
                    style={{ width: '15%' }}
                  >
                    {schoolAbbreviation}
                  </td>
                  <td className={'eIndividualExternalSchoolsResultsCell'} style={{ width: '15%' }}>
                    {this.renderInput(position - 1, id)}
                  </td>
                  {hasAbbreviations && (
                    <td className={'eIndividualExternalSchoolsResultsCell'} style={{ width: '25%' }}>
                      {this.renderAbbreviationCheckboxes(id)}
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
        <Button text={'Cancel'} onClick={onCloseClick} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
        <Button text={'Save results'} onClick={this.onSubmit} customClass={'mt-3 mb-3'} />
      </div>
    );
  }
}
