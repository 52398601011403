import * as React from 'react';
import { Component } from 'react';
import * as propz from 'propz';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Record } from 'Src/models/record';
import { AGE_GROUPS } from 'Src/consts/school';
import MaskedInput from 'react-text-mask';
import * as Moment from 'moment';
import { AppUser } from 'Src/views/App/App';
import { Multiselect } from 'Src/components/Multiselect/Multiselect';
import { SelectOption } from 'Src/helpers/table/table';
import { Autocomplete } from 'Src/components/Autocomplete/Autocomplete';
import { getName, searchFunctionSports } from 'Src/helpers/autocomplete/autocomplete';
import { USER_GENDER, GENDER_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/user';
import DatePicker from 'react-datepicker';
import {
  DEFAULT_TIME_MASK,
  plainPointsToDistanceString,
  plainPointsToTimeString,
  stringDistanceToPoints,
  stringDistanceValidation,
  stringTimeToPoints,
  validateStringTime
} from 'Src/helpers/score/score2';
import { POINTS_DISPLAY } from 'Src/consts/sport';
import { DATE_FORMAT } from '../../../../../consts/date';
import {stringPlainValidation} from "../../../../../helpers/score/score2";

interface Props {
  isOpen: boolean;
  onCloseClick: () => void;
  onFormSubmit: (data: any) => void;
  record?: Record;
  user: AppUser;
}

interface State {
  sport: any;
}

export class RecordForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { record } = props;

    this.state = {
      sport: propz.get(record, ['sport'], undefined)
    };
  }

  isRecordExist(): boolean {
    const { record } = this.props;

    return typeof record !== 'undefined';
  }

  onSubmit(values) {
    const { sportId, sport, gender, ages, athlete, area, record, date } = values;

    const dataCopy = {
      sportId: sportId,
      gender: gender,
      ages: ages.map(age => Number(age)),
      athlete: athlete,
      area: area,
      record: this.convertRecordValueToNumber(sport, record),
      date: date
    };

    if (date === '') {
        delete dataCopy.date
    }

    this.props.onFormSubmit(dataCopy);
  }

  getSports = (text: string) => {
    const user = this.props.user;
    return searchFunctionSports(user, text);
  };

  getAgeGroup(): SelectOption[] {
    const user = this.props.user;
    const activeSchool = user.activeSchool;
    const ageGroupsNaming = activeSchool.ageGroupsNaming;

    const ageGroups = propz.get(AGE_GROUPS, [ageGroupsNaming]);

    return ageGroups.map((ageGroup, index) => {
      return {
        value: index,
        text: ageGroup
      };
    });
  }

  getMaskForMaskedInput(sport) {
      const inputMask = propz.get(sport, ['points', 'inputMask']);
      if (typeof sport === 'undefined' || typeof inputMask === 'undefined') {
      return false;
    }
    const inputMaskConverted = inputMask.split('').map(char => {
      if (char === 'h' || char === 'k' || char === 'm' || char === 's' || char === 'c') {
        return /\d/;
      } else {
        return char;
      }
    });
    return inputMaskConverted;
  }

  convertRecordValueToNumber(sport, value) {
    const mask = propz.get(sport, ['points', 'inputMask'], '');
    const pointType = propz.get(sport, ['points', 'display'], POINTS_DISPLAY.PLAIN);

    switch (true) {
      case !value:
        return 0;
      case pointType === POINTS_DISPLAY.PLAIN:
        return Number(value);
      case pointType === POINTS_DISPLAY.TIME:
        return stringTimeToPoints(value, mask);
      case pointType === POINTS_DISPLAY.DISTANCE:
        return stringDistanceToPoints(value, mask);
    }
  }

  convertRecordValueToStr(sport, value) {
    const pointType = propz.get(sport, ['points', 'display'], POINTS_DISPLAY.PLAIN);
    const mask = propz.get(sport, ['points', 'inputMask'], DEFAULT_TIME_MASK);

    switch (true) {
       case pointType === POINTS_DISPLAY.PLAIN:
        return String(value);
      case pointType === POINTS_DISPLAY.TIME:
        return plainPointsToTimeString(value, mask);
      case pointType === POINTS_DISPLAY.DISTANCE:
        return plainPointsToDistanceString(value, mask);
    }
  }

  render() {
    const isRecordExist = this.isRecordExist();
    const positiveButtonTitle = isRecordExist ? 'Save record' : 'Create record';
    const { record } = this.props;
    const { sport } = this.state;
    const isExistDate = this.isRecordExist() && typeof record.date !== 'undefined';

    const recordInitial = {
      athlete: propz.get(record, ['athlete'], ''),
      area: propz.get(record, ['area'], ''),
      date: this.isRecordExist() && isExistDate ? new Date(record.date): '',
      sport: propz.get(record, ['sport'], undefined),
      sportId: propz.get(record, ['sportId'], undefined),
      gender: propz.get(record, ['gender'], USER_GENDER.MALE),
      record: this.isRecordExist() ? this.convertRecordValueToStr(record.sport, record.record) : '',
      ages: propz.get(record, ['ages'], [])
    };

    const RecordValidationSchema = Yup.object().shape({
      sportId: Yup.string().required('Required'),
      gender: Yup.string().required('Required'),
      ages: Yup.array().required('Required'),
      record: Yup.string().test('isValid', 'Must be correct', value => {
        if (!value) {
          return false;
        }

        if (typeof sport === 'undefined') {
          return false;
        }

        const inputMask = propz.get(sport, ['points', 'inputMask'], '');
        const pointType = propz.get(sport, ['points', 'display'], POINTS_DISPLAY.PLAIN);

        switch (true) {
          case pointType === POINTS_DISPLAY.PLAIN:
            return stringPlainValidation(value);
          case pointType === POINTS_DISPLAY.TIME:
            return validateStringTime(value, inputMask);
          case pointType === POINTS_DISPLAY.DISTANCE:
            return stringDistanceValidation(value, inputMask);
        }
      })
    });

    return (
      <Formik
        onSubmit={values => {
          this.onSubmit(values);
        }}
        initialValues={recordInitial}
        validationSchema={RecordValidationSchema}
        render={({ touched, errors, setFieldValue, values }) => (
          <Form>
            <div className="form-group">
              <label>Sport name</label>
              <Field
                name="sport"
                render={({ field }) => {
                  return (
                    <Autocomplete
                      searchFunction={this.getSports}
                      getElementTitle={getName}
                      customClass="mFullWidth mb-3"
                      defaultItem={values.sport}
                      onSelect={sport => {
                        setFieldValue('sportId', sport.id);
                        setFieldValue('sport', sport);
                        setFieldValue('record', '');
                        this.setState({
                          sport: sport
                        });
                      }}
                    />
                  );
                }}
              />
              {touched.sportId && errors.sportId ? <div className="alert alert-danger">{errors.sportId}</div> : null}
            </div>

            <div className="form-group">
              <label>Ages</label>
              <Field
                name="ages"
                render={({ field }) => {
                  return (
                    <Multiselect
                      items={this.getAgeGroup()}
                      onChange={options => {
                        setFieldValue('ages', options);
                      }}
                      value={field.value || []}
                    />
                  );
                }}
              />
            </div>
            {touched.ages && errors.ages ? <div className="alert alert-danger">{errors.ages}</div> : null}

            <div className="form-group">
              <label>Gender</label>
              <Field component="select" name="gender" className="form-control mb-3">
                <option value={USER_GENDER.MALE}>{GENDER_SERVER_TO_CLIENT_MAPPING.MALE}</option>
                <option value={USER_GENDER.FEMALE}>{GENDER_SERVER_TO_CLIENT_MAPPING.FEMALE}</option>
              </Field>
              {touched.gender && errors.gender ? <div className="alert alert-danger">{errors.gender}</div> : null}
            </div>

            <div className="form-group">
              <label>Record</label>
              <Field
                name="record"
                render={() => {
                  const sport = values.sport;
                  const record = values.record;
                  const inputMask = propz.get(sport, ['points', 'inputMask'], '');

                  return (
                    <MaskedInput
                      onChange={event => {
                        const value = event.target.value;
                        setFieldValue('record', value);
                      }}
                      mask={this.getMaskForMaskedInput(sport)}
                      className={'form-control '}
                      showMask={false}
                      value={record}
                      disabled={!sport}
                      placeholder={inputMask}
                    />
                  );
                }}
              />
            </div>
            {touched.record && errors.record ? <div className="alert alert-danger">{errors.record}</div> : null}

            <div className="form-group">
              <label>Athlete</label>
              <Field type="text" name="athlete" className="form-control" />
            </div>

            <div className="form-group">
              <label>Affiliation</label>
              <Field type="text" name="area" className={'form-control'} />
            </div>

            <div className="form-group">
              <label>Date</label>
              <Field
                name="date"
                render={({ field }) => {
                  let date;

                  if (Moment(values.date, DATE_FORMAT).isValid()) {
                    date = Moment(values.date, DATE_FORMAT).toDate();
                  }

                  return (
                    <div className="mb-3">
                      <DatePicker
                        selected={date}
                        onChange={date => {
                          setFieldValue('date', date);
                        }}
                        className="form-control"
                        dateFormat={'dd-MM-yyyy'}
                        customInput={
                          <MaskedInput
                            mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                            placeholder="DD-MM-YYYY"
                          />
                        }
                      />
                    </div>
                  );
                }}
              />
            </div>

            <button type="button" className="btn btn-secondary mr-3" onClick={this.props.onCloseClick}>
              Cancel
            </button>
            <button type="submit" className="btn btn-primary">
              {positiveButtonTitle}
            </button>
          </Form>
        )}
      />
    );
  }
}
