import * as React from 'react';
import { Component } from 'react';
import { parse } from 'query-string';
import * as BPromise from 'bluebird';
import {
  ColumnDefinition,
  getFilters2,
  getOrder,
  getSearchFilter,
  getSearchOrder,
  getServerFieldSectionWhere,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE, LIMIT } from 'Src/consts/table';
import { Loader } from 'Src/components/Loader/Loader';
import { AppUser } from 'Src/views/App/App';
import { GenericSummary } from 'Src/components/GenericSummary/GenericSummary';
import { Button } from 'Src/components/Button/Button';
import { FORM_TABS } from 'Src/consts/form';
import { History, Location } from 'history';
import { Grid2 } from 'Src/components/Grid/Grid2';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { ResizablePanel } from 'Src/components/ResizablePanel/ResizablePanel';
import { getPaymentAccountState, getPaymentAccountStatus } from 'Src/helpers/accessor/accessor';
import {
  deletePaymentAccount,
  getAllPaymentsAccounts,
  getPaymentAccountCount,
  getPaymentAccounts,
  createStripeAccountLink
} from 'Src/helpers/service/admin/paymentAccounts';
import { PaymentAccount } from 'Src/models/paymentAccount';
import { PaymentAccountSummary } from 'Src/components/PaymentAccountSummary/PaymentAccountSummary';
import { SimpleAlert } from 'Src/components/SimpleAlert/SimpleAlert';
import { PayoutForm } from './PayoutForm/PayoutForm';
import { CLUB_STATUS } from 'Src/consts/club';
import { getStartDateForSchoolYear, getFinishDateForSchoolYear } from 'Src/helpers/calendar/calendar';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Name',
    field: 'name',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['name']
  },
  {
    text: 'Status',
    field: 'status',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getPaymentAccountStatus
  },
  {
    text: 'Details',
    field: 'details',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['details']
  },
  {
    text: 'Default',
    field: 'isDefault',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getPaymentAccountState
  }
];

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

interface State {
  items: PaymentAccount[];
  currentPage: number;
  selectedItems: PaymentAccount[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;

  isEditFormPopupOpened: boolean;
  isDeleteFormModalOpen: boolean;
  isPayoutFormModalOpen: boolean;
  isError: boolean;
  errorMessage: string;
}

export class PaymentsAccounts extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',

      isEditFormPopupOpened: false,
      isDeleteFormModalOpen: false,
      isPayoutFormModalOpen: false,
      isError: false,
      errorMessage: ''
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });
    this.setItems();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const getItemsPromise = getPaymentAccounts(user, serverQueryFilter);
    const getItemsCountPromise = getPaymentAccountCount(user, where);

    const promises = [getItemsCountPromise, getItemsPromise];

    return BPromise.all(promises).then(([countObj, paymentsAccounts]) => {
      this.setState({
        currentPage: page,
        sortDirection: sortDirection,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: paymentsAccounts,
        total: countObj.count,
        basePath: history.location.pathname,
        isLoading: false
      });

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: nextSelectedItems,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    getAllPaymentsAccounts(user, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const nextSelectedItems = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: nextSelectedItems,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onClearFilterClick = (): void => {
    this.props.history.push({
      pathname: this.state.basePath,
      search: ''
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  onTabClick = (event, index: number) => {
    event.preventDefault();

    this.setState({
      total: index
    });
  };

  getSummaryTabs(): React.ReactNode {
    const { selectedItems, items } = this.state;
    const selectedAccounts = items.find(form => form.id === selectedItems[0].id);

    switch (true) {
      case selectedItems.length === 1:
        return <PaymentAccountSummary paymentAccount={selectedAccounts} />;
    }
  }

  removeFormModalOpen = (): void => {
    this.setState({
      isDeleteFormModalOpen: true
    });
  };

  removeFormModalClose = (): void => {
    this.setState({
      isDeleteFormModalOpen: false
    });
  };

  onRemoveFormClick = () => {
    const { selectedItems } = this.state;

    if (typeof selectedItems === 'undefined' || selectedItems.length === 0) {
      return;
    }

    this.setState({ isLoading: true });

    const promises = selectedItems.map(form => {
      return deletePaymentAccount(this.props.user, form.id);
    });

    BPromise.all(promises)
      .then(() => {
        this.setState({
          selectedItems: [],
          isDeleteFormModalOpen: false
        });
        this.setItems();
      })
      .catch(error => {
        this.setState({
          isDeleteFormModalOpen: false,
          isLoading: false
        });
        console.error(error.response.data.details.text);
        this.onShowError(error.response.data.details.text);
      });
  };

  onShowError = (message: string) => {
    this.setState({
      isError: true,
      errorMessage: message
    });
  };

  onCloseErrorClick = (event: Event) => {
    event.preventDefault();
    this.setState({
      isError: false,
      errorMessage: ''
    });
  };

  onCreatePaymentAccountClick = (): void => {
    this.props.history.push({
      pathname: `/accounts/account`
    });
  };

  onEditPaymentAccountClick = (): void => {
    const { user } = this.props;
    const { selectedItems } = this.state;
    const { id } = selectedItems[0] as any;

    const currentUrl = document.location.href;
    const updatedUrl = currentUrl.includes('http') ? `${currentUrl}` : `http://${currentUrl}`;

    const data = {
      refresh_url: updatedUrl,
      return_url: updatedUrl,
      type: 'account_onboarding'
    };

    this.setState({
      isLoading: true
    });

    createStripeAccountLink(user, id, data).then(accountLinkObj => {
      window.open(`${accountLinkObj.url}`);
      this.setState({
        isLoading: false
      });
    });
  };

  onViewPaymentsClick = (): void => {
    const selectedItems = this.state.selectedItems;
    const paymentAccount = selectedItems[0];
    const paymentAccountId = paymentAccount.id;

    const now = new Date();
    const schoolYearStartDate = {
      day: getStartDateForSchoolYear(now).getDate(),
      month: getStartDateForSchoolYear(now).getMonth(),
      year: getStartDateForSchoolYear(now).getFullYear()
    };
    const schoolYearStartDateFormatted = `${schoolYearStartDate.day}-${schoolYearStartDate.month}-${schoolYearStartDate.year}`;

    this.props.history.push({
      pathname: `/payments`,
      search: `paymentAccount=${paymentAccountId}&clubStatus=${CLUB_STATUS.DRAFT}&clubStatus=${CLUB_STATUS.ACTIVE}&createdAt=to_&createdAt=from_${schoolYearStartDateFormatted}`
    });
  };

  onEditPayoutAccount = (): void => {
    this.setState({
      isPayoutFormModalOpen: true
    });
  };

  closePayoutForm = (): void => {
    this.setState({
      isPayoutFormModalOpen: false
    });
  };

  renderDeleteFormModal() {
    const { selectedItems, isDeleteFormModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isDeleteFormModalOpen}
        title={'Remove payment account'}
        body={`Are you sure you want to remove ${
          selectedItems.length === 1
            ? `payment account ${selectedItems[0].name}`
            : `${selectedItems.length} payment accounts`
        }?`}
        buttonText={'Delete'}
        onButtonClick={this.onRemoveFormClick}
        onCloseClick={this.removeFormModalClose}
      />
    );
  }

  renderSendingError(): React.ReactNode {
    const { errorMessage, isError } = this.state;
    if (!isError) {
      return null;
    }

    return (
      <SimpleAlert
        message={errorMessage}
        additionalClassNames={'alert-danger'}
        onCloseAlertClick={this.onCloseErrorClick}
      />
    );
  }

  renderPayoutFormModal(): React.ReactNode {
    const { user } = this.props;
    const { isPayoutFormModalOpen, selectedItems } = this.state;

    let id;
    const isSelectedItemExist = typeof selectedItems[0] !== 'undefined';

    if (isSelectedItemExist) {
      id = selectedItems[0].id;
    }

    return (
      <SimpleModal isOpen={isPayoutFormModalOpen}>
        <PayoutForm user={user} onCancelButtonClick={this.closePayoutForm} paymentAccountId={id} />
      </SimpleModal>
    );
  }

  renderSummary() {
    const { selectedItems } = this.state;
    const isOneItemSelected = selectedItems.length === 1;

    if (!isOneItemSelected) {
      return <div className="font-weight-bold">Select a payment account above</div>;
    }

    const summaryTabs = this.getSummaryTabs();

    return (
      <GenericSummary onTabClick={this.onTabClick} tabs={FORM_TABS} currentTabIndex={0}>
        {isOneItemSelected && (
          <>
            <Button
              onClick={this.onEditPaymentAccountClick}
              text={'Sign up with Stripe'}
              customClass={'mt-3 mb-3 mr-3'}
            />
            <Button onClick={this.onEditPayoutAccount} text={'Payout account'} customClass={'mt-3 mb-3 mr-3'} />
            <Button onClick={this.onViewPaymentsClick} text={'Payments'} customClass={'mt-3 mb-3'} />
          </>
        )}
        {summaryTabs}
      </GenericSummary>
    );
  }

  render() {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isLoading,
      isSelectAllChecked,
      currentPage,
      total,
      isEditFormPopupOpened,
      isDeleteFormModalOpen
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes = isEditFormPopupOpened || isDeleteFormModalOpen ? 'mt-3 modal-open' : 'mt-3';

    const wouldMinOneAccountExistAfterRemoving = items.length - selectedItems.length > 0;

    const actionItems = [
      {
        itemText: 'Add new account',
        onItemClick: () => this.onCreatePaymentAccountClick(),
        isActive: false
      },
      {
        itemText: 'Remove account',
        onItemClick: this.removeFormModalOpen,
        isActive: wouldMinOneAccountExistAfterRemoving && selectedItems.length > 0
      }
    ];

    return (
      <div className={classes}>
        {this.renderDeleteFormModal()}
        {this.renderSendingError()}
        {this.renderPayoutFormModal()}
        <div className="row">
          <div className="col-md-12">
            <ResizablePanel>
              <Grid2
                dataItems={items}
                filters={filters}
                currentPage={currentPage}
                total={total}
                isSelectAllChecked={isSelectAllChecked}
                isDataFiltered={isDataFiltered}
                sortDirection={sortDirection}
                sortColumnsName={sortColumnsName}
                isShowFilter={isShowFilter}
                dataItemsSelected={selectedItems}
                columns={COLUMNS}
                actionItems={actionItems}
                onItemClick={this.onItemClick}
                onSortClick={this.onTableSortClick}
                onApplyFilterClick={this.onApplyFilterClick}
                onClearFilterClick={this.onClearFilterClick}
                onTableFilterChange={this.onTableFilterChange}
                onTableFilterClick={this.onTableFilterClick}
                setCurrentPageParams={this.setCurrentPageParams}
                onSelectAllClick={this.onSelectAllClick}
                onSelectAllOnPageClick={this.onSelectAllOnPageClick}
                onUnselectAllClick={this.onUnselectAllClick}
              />
              {this.renderSummary()}
            </ResizablePanel>
          </div>
        </div>
      </div>
    );
  }
}
