import * as React from 'react';

interface Props {
  onClick?: () => void;
}

export const EventsIcon = (props: Props) => {
  const { onClick } = props;
  const isOnClickExist = typeof onClick !== 'undefined';

  const emptyFunc = () => {};

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={32}
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      onClick={isOnClickExist ? onClick : emptyFunc}
    >
      <title>Click the button to manage events your school takes part in within the tournament.</title>
      <g fill="#2394be">
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M456 48h-48v-8a24 24 0 0 0-48 0v8h-80v-8a24 24 0 0 0-48 0v8h-80v-8a24 24 0 0 0-48 0v8H56a40.045 40.045 0 0 0-40 40v368a40.045 40.045 0 0 0 40 40h400a40.045 40.045 0 0 0 40-40V88a40.045 40.045 0 0 0-40-40zm-80-8a8 8 0 0 1 16 0v48a8 8 0 0 1-16 0zm-128 0a8 8 0 0 1 16 0v48a8 8 0 0 1-16 0zm-128 0a8 8 0 0 1 16 0v48a8 8 0 0 1-16 0zM56 64h48v24a24 24 0 0 0 48 0V64h80v24a24 24 0 0 0 48 0V64h80v24a24 24 0 0 0 48 0V64h48a24.028 24.028 0 0 1 24 24v56H32V88a24.028 24.028 0 0 1 24-24zm400 416H56a24.028 24.028 0 0 1-24-24V160h448v296a24.028 24.028 0 0 1-24 24z"
          data-original="#000000"
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M440 216h-32a8 8 0 0 0-8 8v32a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8v-32a8 8 0 0 0-8-8zm-8 32h-16v-16h16zM280 376h-32a8 8 0 0 0-8 8v32a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8v-32a8 8 0 0 0-8-8zm-8 32h-16v-16h16zM200 216h-32a8 8 0 0 0-8 8v32a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8v-32a8 8 0 0 0-8-8zm-8 32h-16v-16h16zM120 296H88a8 8 0 0 0-8 8v32a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8v-32a8 8 0 0 0-8-8zm-8 32H96v-16h16zM200 296h-32a8 8 0 0 0-8 8v32a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8v-32a8 8 0 0 0-8-8zm-8 32h-16v-16h16zM120 376H88a8 8 0 0 0-8 8v32a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8v-32a8 8 0 0 0-8-8zm-8 32H96v-16h16zM200 376h-32a8 8 0 0 0-8 8v32a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8v-32a8 8 0 0 0-8-8zm-8 32h-16v-16h16zM369.208 248.983l-40-6.112-17.966-38.271a8 8 0 0 0-14.484 0L278.8 242.871l-40 6.112a8 8 0 0 0-4.518 13.5l29.181 29.907-6.9 42.326A8 8 0 0 0 268.316 343L304 323.276 339.684 343a8 8 0 0 0 11.766-8.289l-6.9-42.326 29.181-29.907a8 8 0 0 0-4.518-13.5zM330.274 284.1a8 8 0 0 0-2.17 6.875l4.9 30.051-25.136-13.9a8 8 0 0 0-7.74 0l-25.136 13.9 4.9-30.051a8 8 0 0 0-2.17-6.875L256.6 262.448l28.833-4.4a8 8 0 0 0 6.033-4.509L304 226.828l12.535 26.706a8 8 0 0 0 6.033 4.509l28.833 4.4zM440 296h-32a8 8 0 0 0-8 8v32a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8v-32a8 8 0 0 0-8-8zm-8 32h-16v-16h16zM440 376h-32a8 8 0 0 0-8 8v32a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8v-32a8 8 0 0 0-8-8zm-8 32h-16v-16h16zM360 376h-32a8 8 0 0 0-8 8v32a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8v-32a8 8 0 0 0-8-8zm-8 32h-16v-16h16z"
          data-original="#000000"
        />
      </g>
    </svg>
  );
};

EventsIcon.displayName = 'EventsIcon';
