import * as React from 'react';
import * as propz from 'propz';
import { FunctionComponent } from 'react';
import { SPORT_GENDER_SERVER_TO_CLIENT_MAPPING, YES_NO_OPTIONS } from '../../consts/common';
import { getEventAges } from '../../helpers/accessor/accessor';

interface Props {
  team: any;
}

const ROWS = [
  { title: 'Name', field: 'name' },
  { title: 'Sport', field: 'sportName' },
  { title: 'Description ', field: 'description' },
  { title: 'Age', field: 'ages' },
  { title: 'Gender', field: 'gender' }
];

export const SchoolTeamSummary: FunctionComponent<Props> = props => {
  const rows = ROWS.map((row, index) => {
    let contentCell: string;
    const field = row.field;

    const { team } = props;

    switch (field) {
      case 'name':
        contentCell = propz.get(team, ['name'], '');
        break;
      case 'sportName':
        contentCell = propz.get(team, ['sport', 'name'], '');
        break;
      case 'description':
        contentCell = propz.get(team, ['description'], '');
        break;
      case 'ages':
        contentCell = getEventAges(team);
        break;
      case 'gender':
        contentCell = SPORT_GENDER_SERVER_TO_CLIENT_MAPPING[team.gender];
        break;
      default:
        contentCell = team[row.field];
    }

    return (
      <tr key={`team_summary_${index}`}>
        <td>{row.title}</td>
        <td>{contentCell}</td>
      </tr>
    );
  });

  return (
    <table className={'table table-sm mPre'}>
      <tbody>{rows}</tbody>
    </table>
  );
};

SchoolTeamSummary.displayName = 'SchoolTeamSummary';
