import * as React from 'react';
import {FunctionComponent} from 'react';

interface Props {
	message: string
	additionalClassNames: string
	onCloseAlertClick: (event) => void
}

export const SimpleAlert: FunctionComponent<Props> = props => {
	return(
		<div className={`alert alert-dismissible fade show ${props.additionalClassNames}`} role="alert">
			{ props.message }
			<button type="button" className="close" aria-label="Close" onClick={props.onCloseAlertClick}>
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
	);
};