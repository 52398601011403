import * as React from 'react';
import { Tournament } from 'Src/models/tournament';
import { GENDER, SPORT_GENDER_SERVER_TO_CLIENT_MAPPING, YES_NO_OPTIONS } from 'Src/consts/common';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import * as propz from 'propz';
import { AGE_GROUPS } from 'Src/consts/school';
import { TOURNAMENT_SCHEDULE, TOURNAMENT_SCHEDULE_MAPPING_CLIENT_TO_SERVER } from 'Src/consts/tournament';
import * as Moment from 'moment';
import DatePicker from 'react-datepicker';
import { FunctionComponent } from 'react';
import { AppUser } from 'Src/views/App/App';
import { getSelectOptionForTournamentEventAgesGroup } from 'Src/helpers/table/select';

interface Props {
  onCancel: () => void;
  onSubmit: (data: any) => void;
  tournament: Tournament;
  user?: AppUser;
}

const eventsGroupSchema = Yup.object().shape({
  interval: Yup.number().required('Please enter events interval (min)')
});

export const TournamentEventsGroupForm: FunctionComponent<Props> = props => {
  const { tournament, user } = props;

  const renderCustomGroupsOptions = () => {
    const customGroups = tournament.customGroups || [];
    if (customGroups.length === 0) {
      return <option value={''}>Not active option</option>;
    }

    return customGroups.map(customGroup => {
      return (
        <option key={customGroup.id} value={customGroup.id}>
          {customGroup.name}
        </option>
      );
    });
  };

  const renderAgeOptions = () => {
    const { ages: ageIndexes } = tournament;

    const tournamentAgesGroupName = propz.get(user, ['activeSchool', 'ageGroupsNaming'], []) as any;

    return [...ageIndexes]
      .sort((ageIndex1, ageIndex2) => ageIndex1 - ageIndex2)
      .map(ageIndex => {
        const agesGroup = propz.get(AGE_GROUPS, [tournamentAgesGroupName], []);
        const ageItem = agesGroup[ageIndex];

        return (
          <option key={ageIndex} value={ageIndex}>
            {ageItem}
          </option>
        );
      });
  };

  const renderGenderOptions = () => {
    const { tournament } = props;
    const isMixedTeamsAvailable = tournament.isMixedTeamsAvailable;
    const gender = tournament.gender;
    const isGenderMixed = gender === GENDER.MIXED;

    switch (true) {
      case isGenderMixed && !isMixedTeamsAvailable:
        return (
          <>
            <option key="gender_option_male" value={GENDER.MALE_ONLY}>
              {SPORT_GENDER_SERVER_TO_CLIENT_MAPPING.MALE_ONLY}
            </option>
            <option key="gender_option_female" value={GENDER.FEMALE_ONLY}>
              {SPORT_GENDER_SERVER_TO_CLIENT_MAPPING.FEMALE_ONLY}
            </option>
          </>
        );
      case isGenderMixed && isMixedTeamsAvailable:
        return (
          <option key="gender_option_mixed" value={GENDER.MIXED}>
            {SPORT_GENDER_SERVER_TO_CLIENT_MAPPING.MIXED}
          </option>
        );
      default:
        return (
          <option key="gender_option_mixed" value={GENDER[gender]}>
            {SPORT_GENDER_SERVER_TO_CLIENT_MAPPING[gender]}
          </option>
        );
    }
  };

  const getInitialGender = () => {
    const { tournament } = props;
    const isMixedTeamsAvailable = tournament.isMixedTeamsAvailable;
    const gender = propz.get(tournament, ['gender']);
    const isGenderMixed = gender === GENDER.MIXED;

    switch (true) {
      case isGenderMixed && !isMixedTeamsAvailable:
        return GENDER.MALE_ONLY;
      case isGenderMixed && isMixedTeamsAvailable:
        return GENDER.MIXED;
      default:
        return GENDER[gender];
    }
  };

  const customGroups = tournament.customGroups || [];
  const eventsGroupForm = {
    gender: getInitialGender(),
    age: propz.get(tournament, ['ages', '0'], ''),
    tournamentGroupId: customGroups.length === 0 ? '' : customGroups[0].id,
    schedule: TOURNAMENT_SCHEDULE_MAPPING_CLIENT_TO_SERVER.ROUND_ROBIN,
    startTime: new Date(tournament.startTime),
    interval: ''
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik
            initialValues={eventsGroupForm}
            validationSchema={eventsGroupSchema}
            onSubmit={props.onSubmit}
            render={({ touched, errors, values, setFieldValue }) => (
              <Form>
                <div className="form-group">
                  <label>Gender</label>
                  <Field component="select" name="gender" className="form-control mb-3">
                    {renderGenderOptions()}
                  </Field>
                </div>
                <div className="form-group">
                  <label>Age</label>
                  <Field
                    component="select"
                    name="age"
                    className="form-control mb-3"
                    onChange={event => {
                      setFieldValue('age', Number(event.target.value));
                    }}
                  >
                    {renderAgeOptions()}
                  </Field>
                </div>
                <div className="form-group">
                  <label>Group name</label>
                  <Field name="tournamentGroupId" component="select" className="form-control mb-3">
                    {renderCustomGroupsOptions()}
                  </Field>
                </div>
                <div className="form-group">
                  <label>Schedule</label>
                  <Field name="schedule" component="select" className="form-control mb-3">
                    <option value={TOURNAMENT_SCHEDULE_MAPPING_CLIENT_TO_SERVER.ROUND_ROBIN}>
                      {TOURNAMENT_SCHEDULE.ROUND_ROBIN}
                    </option>
                  </Field>
                </div>
                <div className="form-group">
                  <label>Start time</label>
                  <Field
                    name="startTime"
                    render={({ field }) => {
                      let startTime;
                      if (Moment(values.startTime, 'DD-MM-YYYY HH:mm aa').isValid()) {
                        startTime = Moment(values.startTime, 'DD-MM-YYYY HH:mm aa').toDate();
                      }
                      return (
                        <div className="mb-3">
                          <DatePicker
                            selected={startTime}
                            onChange={startTime => {
                              setFieldValue('startTime', startTime);
                            }}
                            className="form-control"
                            timeInputLabel="Time: "
                            showTimeSelect={true}
                            dateFormat={'dd-MM-yyyy hh:mm:a'}
                          />
                        </div>
                      );
                    }}
                  />
                  {touched.startTime && errors.startTime ? (
                    <div className="alert alert-danger">{errors.startTime}</div>
                  ) : null}
                </div>
                <div className="form-group">
                  <label>Events interval (min)</label>
                  <Field
                    type="number"
                    min="0"
                    name="interval"
                    className={`${touched.interval && errors.interval ? 'form-control is-invalid' : 'form-control '}`}
                  />
                  {touched.interval && errors.interval ? (
                    <div className="invalid-feedback">{errors.interval}</div>
                  ) : null}
                </div>
                <button type="button" className="btn btn-secondary mr-3" onClick={props.onCancel}>
                  Cancel
                </button>
                <button type="submit" className="mt-3 mb-3 btn btn-primary">
                  Save
                </button>
              </Form>
            )}
          />
        </div>
      </div>
    </div>
  );
};

TournamentEventsGroupForm.displayName = 'TournamentEventsGroupForm';
