export const GENDER = {
  MALE_ONLY: 'MALE_ONLY',
  FEMALE_ONLY: 'FEMALE_ONLY',
  MIXED: 'MIXED'
};

export const YES_NO_OPTIONS = { YES: 'Yes', NO: 'No' };

export const SPORT_GENDER_SERVER_TO_CLIENT_MAPPING = {
  MALE_ONLY: 'Male',
  FEMALE_ONLY: 'Female',
  MIXED: 'Mixed'
};

export const PLAYER_GENDER_SERVER_TO_CLIENT_MAPPING = {
  MALE_ONLY: 'Boys',
  FEMALE_ONLY: 'Girls',
  MIXED: 'Mixed'
};

export const SPORT_GENDER_CLIENT_TO_SERVER_MAPPING = {
  Male: 'MALE_ONLY',
  Female: 'FEMALE_ONLY',
  Mixed: 'MIXED'
};

export const TABS = {
  SUMMARY: 'Summary',
  PARTICIPANTS: 'Participants',
  ELIGIBLE_SCHOOLS: 'Eligible schools',
  CONFIRMATION_REQUESTS: 'Confirmation Requests',
  TOURNAMENT_EVENTS: 'Tournament Events',
  AUTO_ENROLLMENT: 'Autoenrollment',
  PUBLIC_SITE_SETTINGS: 'Public site settings',
  TOURNAMENT_TV: 'Tournament TV',
  ENROLLMENT_LOG: 'Enrollment log',
  ADMINS: 'Admins',
  TEAMS: 'Teams',
  PITCHES: 'Pitches',
  GROUPS: 'Groups',
  TEAM_MEMBERS: 'Team members',
  DETAILS: 'Details',
  JOBS: 'Jobs',
  PERFORMANCE: 'Performance',
  DISCIPLINE: 'Discipline',
  ATTENDANCE: 'Attendance',
  PLAYERS: 'Players',
  PROGRESS: 'Progress',
  EVENTS: 'Events',
  GALLERY: 'Gallery',
  MATCH_REPORT: 'Match report',
  LEADER_COACH_NOTES: 'Leader/Coach notes',
  NEWS: 'News',
  PARENTAL_CONSENT: 'Parental consent',
  AVAILABILITY_REPORTS: 'Availability reports',
  POSITIONS: 'Positions',
  DETAILS_AND_BOOKING: 'Details & Booking',
  ADDITIONAL_INFO_AND_DOCUMENTS: 'Additional info & Documents'
};

export const MODE_TYPE = {
  EDIT: 'edit',
  COPY: 'copy',
  CLUB_COPY: 'clubCopy',
  CLUB_COPY_WITH_PARTICIPANTS: 'clubCopyWithParticipants',
  CREATE_ANOTHER_EVENT: 'createAnotherEvent'
};

export const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const DAYS_OF_WEEK = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
export const NO_IMAGE = '/dist/images/no-image.jpg';
export const NO_VIDEO = '/dist/images/no-video.jpg';

export const PHONE_CODE = {
  //  RU: '+7',
  UK: '+44',
  US: '+1',
  DE: '+49',
  AE: '+971',
  ES: '+34'
};

export const PHONE_CODE_WITH_FLAG = {
  //  RU: { code: '+7', flag: 'russian_flag.png', alt: 'Russian flag' },
  UK: { code: '+44', flag: 'uk_flag.png', alt: 'UK flag' },
  US: { code: '+1', flag: 'usa_flag.png', alt: 'USA flag' },
  DE: { code: '+49', flag: 'german_flag.png', alt: 'German flag' },
  AE: { code: '+971', flag: 'uae_flag.png', alt: 'UAE flag' }
};

export const REGION = {
  //  RU: 'RU',
  UK: 'GB',
  US: 'US',
  DE: 'DE',
  AE: 'AE',
  ES: 'ES'
};

export const ENTER_KEY = 'Enter';
export const ARROW_UP_KEY = 'ArrowUp';
export const ARROW_DOWN_KEY = 'ArrowDown';

export const MILLISECONDS_IN_SECOND = 1000;
export const SECONDS_IN_MINUTE = 60;

export const TWEET_LENGTH = 140;

export const PENCES_IN_POUND = 100;
