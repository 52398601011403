import * as React from 'react';

interface Props {
  onClick?: () => void;
}

export const AddIcon = (props: Props) => {
  const { onClick } = props;
  const isOnClickExist = typeof onClick !== 'undefined';

  const emptyFunc = () => {};

  return (
    <svg
      className="bTableCellIcon"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      onClick={isOnClickExist ? onClick : emptyFunc}
    >
      <g fill="#2394be">
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M17.5 24c-3.584 0-6.5-2.916-6.5-6.5s2.916-6.5 6.5-6.5 6.5 2.916 6.5 6.5-2.916 6.5-6.5 6.5zm0-11.5c-2.757 0-5 2.243-5 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"
          data-original="#000000"
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M17.5 21a.75.75 0 0 1-.75-.75v-5.5a.75.75 0 0 1 1.5 0v5.5a.75.75 0 0 1-.75.75z"
          data-original="#000000"
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M20.25 18.25h-5.5a.75.75 0 0 1 0-1.5h5.5a.75.75 0 0 1 0 1.5zM9.19 21H2.75A2.752 2.752 0 0 1 0 18.25V2.75A2.752 2.752 0 0 1 2.75 0h11.5A2.752 2.752 0 0 1 17 2.75v6.09a.75.75 0 0 1-1.5 0V2.75c0-.689-.561-1.25-1.25-1.25H2.75c-.689 0-1.25.561-1.25 1.25v15.5c0 .689.561 1.25 1.25 1.25h6.44a.75.75 0 0 1 0 1.5z"
          data-original="#000000"
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M13.25 9.5h-9.5a.75.75 0 0 1 0-1.5h9.5a.75.75 0 0 1 0 1.5zM9.25 13.5h-5.5a.75.75 0 0 1 0-1.5h5.5a.75.75 0 0 1 0 1.5zM8.25 5.5h-4.5a.75.75 0 0 1 0-1.5h4.5a.75.75 0 0 1 0 1.5z"
          data-original="#000000"
        />
      </g>
    </svg>
  );
};

AddIcon.displayName = 'AddIcon';
