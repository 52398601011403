import axios from 'axios';
import { AppUser } from 'Src/views/App/App';

export function uploadImage(user: AppUser, image: any) {
  const key = user.roleSessionKey;
  const formData = new FormData();

  formData.append('image', image);

  const config = {
    headers: {
      usid: key,
      'Content-Type': 'multipart/form-data'
    }
  };

  return axios.post(`${window.apiImg}/images`, formData, config).then(response => {
    return response.data;
  });
}
