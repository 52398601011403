import * as React from 'react';
import { FunctionComponent } from 'react';
import {
  GENERAL_MESSAGE_DELIVERY_STATUS, GENERAL_MESSAGE_DELIVERY_STATUS_OBJECT,
  GENERAL_MESSAGE_DELIVERY_STATUS_SERVER_TO_CLIENT_MAPPING
} from '../../consts/generalMessage'

interface Props {
  statistics: any
}

const MESSAGE_DELIVERY_STATUS_TABLE_HEAD = [
  GENERAL_MESSAGE_DELIVERY_STATUS_OBJECT.DELIVERED,
  GENERAL_MESSAGE_DELIVERY_STATUS_OBJECT.SENT,
  GENERAL_MESSAGE_DELIVERY_STATUS_OBJECT.SENDING,
  GENERAL_MESSAGE_DELIVERY_STATUS_OBJECT.SCHEDULED,
  GENERAL_MESSAGE_DELIVERY_STATUS_OBJECT.NOT_SENT,
  GENERAL_MESSAGE_DELIVERY_STATUS_OBJECT.ERROR,
  GENERAL_MESSAGE_DELIVERY_STATUS_OBJECT.UNSUPPORTED_CHANNEL_TYPE,
  GENERAL_MESSAGE_DELIVERY_STATUS_OBJECT.NO_CHANNELS
];

export const MessageDeliveryStatus: FunctionComponent<Props> = (props) => {
  const tableHead = MESSAGE_DELIVERY_STATUS_TABLE_HEAD
    .map( (status, index) => {
      return (
        <th key = {`message_delivery_status_head_${index}`}>{GENERAL_MESSAGE_DELIVERY_STATUS_SERVER_TO_CLIENT_MAPPING[status]}</th>
      );
  });
  
  let smsTotal = 0;
  let emailTotal = 0;
  let pushTotal = 0;
  let notificationTotal = 0;
  
  const smsStatistic = MESSAGE_DELIVERY_STATUS_TABLE_HEAD.map(status => {
    smsTotal += props.statistics.sms[status];
    return (
      <td key={`message_delivery_status_sms_${status}`}>
        {props.statistics.sms[status] !== 0 ? props.statistics.sms[status] : ''}
      </td>
    );
  });
  
  const emailStatistic = MESSAGE_DELIVERY_STATUS_TABLE_HEAD.map(status => {
    pushTotal += props.statistics.push[status];
    return (
      <td key={`message_delivery_status_email_${status}`}>
        {props.statistics.email[status] !== 0 ? props.statistics.email[status] : ''}
      </td>
    );
  });
  
  const pushStatistic = MESSAGE_DELIVERY_STATUS_TABLE_HEAD.map(status => {
    emailTotal += props.statistics.email[status];
    return (
      <td key={`message_delivery_status_push_${status}`}>
        {props.statistics.push[status] !== 0 ? props.statistics.push[status] : ''}
      </td>
    );
  });
  
  const notificationStatistic = MESSAGE_DELIVERY_STATUS_TABLE_HEAD.map(status => {
    notificationTotal += props.statistics.notification[status];
    return (
      <td key={`message_delivery_status_notification_${status}`}>
        {props.statistics.notification[status] !== 0 ? props.statistics.notification[status] : ''}
      </td>
    );
  });
  
  let smsTotalTd = (<td>{smsTotal}</td>);
  let emailTotalTd = (<td>{emailTotal}</td>);
  let pushTotalTd = (<td>{pushTotal}</td>);
  let notificationTotalTd = (<td>{notificationTotal}</td>);
  
  const colCount = MESSAGE_DELIVERY_STATUS_TABLE_HEAD.length + 2; //table head + type + total
  
  return (
    <table className="table table-sm text-center">
      <thead>
      <tr>
        <th className={"text-left"}>Type</th>
        <th>Total</th>
        {tableHead}
      </tr>
      </thead>
      <tbody>
        <tr className={"font-weight-bold"}>
          <td className={"text-left"}>Notifications</td>
          {notificationTotalTd}
          {notificationStatistic}
        </tr>
        <tr className={"font-weight-bold"}>
          <td className={"text-left"} colSpan={colCount}>By channels</td>
        </tr>
        <tr>
          <td className={"text-left"}>Sms</td>
          {smsTotalTd}
          {smsStatistic}
        </tr>
        <tr>
          <td className={"text-left"}>Email</td>
          {emailTotalTd}
          {emailStatistic}
        </tr>
        <tr>
          <td className={"text-left"}>Push</td>
          {pushTotalTd}
          {pushStatistic}
        </tr>
      </tbody>
    </table>
  )
};

MessageDeliveryStatus.displayName = 'MessageDeliveryStatus';