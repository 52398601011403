import * as React from 'react';
import { History } from 'history';
import { Button } from 'Src/components/Button/Button';
import './PaymentSuccess.scss';

interface Props {
  history: History;
}

export const PaymentSuccess = (props: Props) => {
  const { history } = props;

  const onBackClick = () => {
    history.push({
      pathname: '/calendar'
    });
  };

  return (
    <div className="bPaymentSuccessWrapper">
      <div className="bPaymentSuccess">
        <div className="p-2 mb-4">
          <img src="/dist/images/logo.svg" className="d-inline-block align-top" alt="" />
        </div>

        <div className="row mb-4 py-5 align-items-center">
          <div className="col-12 col-sm-8">
            <h3 className="text-success mb-3">Payment successful</h3>

            <p>
              Your booking has been completed. You will get a separate notification once it is confirmed by your school
              / association.
            </p>

            <p>Thank you for using Squad In Touch!</p>
          </div>

          <div className="col-4 d-sm-flex justify-content-end d-none">
            <img src="/dist/images/bird_calculator.png" className="d-inline-block align-top w-75" alt="" />
          </div>
        </div>

        <Button text="Back home" onClick={onBackClick} />
      </div>
    </div>
  );
};
