import axios from 'axios';
import { AppUser } from 'Src/views/App/App';

export function getChildren(user: AppUser) {
  const { roleSessionKey: key } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.get(`${window.apiBase}/i/children`, config).then(response => {
    return response.data;
  });
}

export function getChild(user: AppUser, childId: string) {
  const { roleSessionKey: key } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.get(`${window.apiBase}/i/children/${childId}`, config).then(response => {
    return response.data;
  });
}

export function setChildAvatar(user: AppUser, childId: string, avatar: string) {
  const { roleSessionKey: key } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.put(`${window.apiBase}/i/children/${childId}/avatar`, { avatar }, config).then(response => {
    return response.data;
  });
}
