import { AppUser } from 'Src/views/App/App';
import axios from 'axios';

export function addSchoolTerms(user: AppUser, data: any) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.post(`${window.apiBase}/i/schools/${activeSchoolId}/terms`, data, config).then(response => {
    return response.data;
  });
}

export function getSchoolTerms(user: AppUser) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/terms`, config).then(response => {
    return response.data;
  });
}

export function updateSchoolTerms(user: AppUser, termId: string, data: any) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.put(`${window.apiBase}/i/schools/${activeSchoolId}/terms/${termId}`, data, config).then(response => {
    return response.data;
  });
}
