import * as React from 'react';
import * as propz from 'propz';
import { FunctionComponent } from 'react';
import { Record } from '../../models/record';
import { AGE_GROUPS } from '../../consts/school';
import { GENDER_SERVER_TO_CLIENT_MAPPING } from '../../consts/user';
import { POINTS_DISPLAY } from '../../consts/sport';
import {
  DEFAULT_DISTANCE_MASK,
  DEFAULT_TIME_MASK,
  plainPointsToDistanceString,
  plainPointsToTimeString
} from '../../helpers/score/score2';
import { convertPoints } from '../../helpers/points/points';

interface Props {
  record: Record;
}

const ROWS = [
  { title: 'Sport', field: 'name' },
  { title: 'Age', field: 'ages' },
  { title: 'Gender', field: 'gender' },
  { title: 'Record', field: 'record' },
  { title: 'Athlete', field: 'athlete' },
  { title: 'Affiliation', field: 'area' },
  { title: 'Year', field: 'date' }
];

export const RecordSummary: FunctionComponent<Props> = props => {
  const rows = ROWS.map((row, index) => {
    const field = row.field;

    let contentCell: string;

    switch (field) {
      case 'name':
        contentCell = propz.get(props.record, ['sport', 'name'], '');
        break;
      case 'ages':
        if (typeof props.record.ages !== 'undefined') {
          const ages = props.record.ages || [];
          contentCell = ages.map(age => propz.get(AGE_GROUPS, ['ENGLISH', age], '')).join(', ');
        } else {
          contentCell = '';
        }
        break;
      case 'gender':
        const gender = props.record.gender;
        contentCell = gender ? GENDER_SERVER_TO_CLIENT_MAPPING[gender] : '';
        break;
      case 'record':
        const sportPointsDisplay = propz.get(props.record, ['sport', 'points', 'display'], POINTS_DISPLAY.PLAIN);
        const inputMask = propz.get(props.record, ['sport', 'points', 'inputMask']);
        const record = propz.get(props.record, ['record'], 0);

        let result, mask;

        // points type
        switch (sportPointsDisplay) {
          case POINTS_DISPLAY.PLAIN:
            result = convertPoints(record, sportPointsDisplay).str;
            break;
          case POINTS_DISPLAY.TIME:
            mask = inputMask ? inputMask : DEFAULT_TIME_MASK;
            result = plainPointsToTimeString(record, mask);
            break;
          case POINTS_DISPLAY.DISTANCE:
            mask = inputMask ? inputMask : DEFAULT_DISTANCE_MASK;
            result = plainPointsToDistanceString(record, mask);
            break;
          default:
            console.error(`Can not find view for sport points display: ${sportPointsDisplay}`);
            break;
        }

        contentCell = result;
        break;
      case 'date':
        const date = props.record.date,
          dateObj = new Date(date);
        contentCell = typeof date !== 'undefined' ? dateObj.getFullYear().toString() : '';
        break;
      default:
        contentCell = propz.get(props.record, row.field.split('.'), '');
    }

    return (
      <tr key={`Record_summary_${index}`}>
        <td>{row.title}</td>
        <td>{contentCell}</td>
      </tr>
    );
  });

  return (
    <table className={'table table-sm mPre mVerticalAlignMiddle'}>
      <tbody>{rows}</tbody>
    </table>
  );
};
RecordSummary.displayName = 'RecordSummary';
