import { GALLERY_MODE_TYPE } from 'Src/types/album';

export const PHOTO_WIDTH = 240;
export const VIDEO_WIDTH = 240;
export const LAYOUT_WIDTH = 960;
export const ARBITER_BOARD_LAYOUT_WIDTH = 960;
export const PHOTO_COUNT_FOR_LOAD = 4;

export const GALLERY_MODE: {
  PREVIEW: GALLERY_MODE_TYPE;
  FULLSCREEN: GALLERY_MODE_TYPE;
} = {
  PREVIEW: 'GALLERY_PREVIEW_MODE',
  FULLSCREEN: 'GALLERY_FULLSCREEN_MODE'
};

export const ACCESS_PRESET = {
  PRIVATE: 'PRIVATE',
  PUBLIC: 'PUBLIC',
  ALL_PARTICIPANT_PARENTS: 'ALL_PARTICIPANT_PARENTS'
};

export const ACCESS_PRESET_SERVER_TO_CLIENT_MAPPING = {
  PRIVATE: 'Private',
  PUBLIC: 'Public',
  ALL_PARTICIPANT_PARENTS: 'Team members'
};

export const ACCESS_PRESET_OPTIONS_FOR_MANAGER = {
  PUBLIC: 'Public',
  PRIVATE: 'Private',
  ALL_PARTICIPANT_PARENTS: 'Team members'
};

export const ACCESS_PRESET_OPTIONS_FOR_PARENT = {
  PRIVATE: 'Private',
  ALL_PARTICIPANT_PARENTS: 'Team members'
};
