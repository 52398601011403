import * as React from 'react';
import { FunctionComponent } from 'react';
import { Tournament } from '../../models/tournament';
import { getYesOrNoForBoolean } from '../../helpers/tournament/tournament';

interface Props {
  tournament: Tournament;
}

export const TournamentTVSummary: FunctionComponent<Props> = props => {
  const { tournament } = props;
  const stages = tournament.tournamentLayout || [];
  const publicBigscreenStagesEnabled = tournament.publicBigscreenStagesEnabled;

  const rows = stages.map((stage, index) => {
    const field = stage.name;
    const contentCell = getYesOrNoForBoolean(publicBigscreenStagesEnabled[stage.type]);

    return (
      <tr key={`tournament_summary_${index}`}>
        <td>{field}</td>
        <td>{contentCell}</td>
      </tr>
    );
  });

  return (
    <table className={'table table-sm mPre'}>
      <tbody>{rows}</tbody>
    </table>
  );
};

TournamentTVSummary.displayName = 'TournamentTVSummary';
