import * as React from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import * as propz from 'propz';
import { getFullName, searchFunctionSchoolPlayers } from 'Src/helpers/autocomplete/autocomplete';
import { Autocomplete } from 'Src/components/Autocomplete/Autocomplete';
import { AppUser } from 'Src/views/App/App';
import { Component } from 'react';
import { Button } from 'Src/components/Button/Button';

interface Props {
  schoolEvent: any;
  onSubmit: (data) => void;
  onCancel: (event) => void;
  user: AppUser;
  eventTasks: any;
  eventTaskIndex: number;
}

const EventJobSchema = Yup.object().shape({
  text: Yup.string().required('Required'),
  players: Yup.array().min(1)
});

interface State {
  players: any[];
}

export class SchoolEventJobForm extends Component<Props, State> {
  constructor(props) {
    super(props);
    const { eventTasks, eventTaskIndex } = this.props;

    this.state = {
      players: propz.get(eventTasks, [eventTaskIndex, 'assignees'], [])
    };
  }

  getPlayers = (text: string) => {
    const { schoolEvent, user } = this.props;
    const playersWithSelect = [...this.state.players];
    const eventPlayers = propz.get(schoolEvent, ['players'], []);
    const uniqPlayersWithSelect = eventPlayers.filter(uniqPlayer =>
      playersWithSelect.every(playerWithSelect => playerWithSelect.userId !== uniqPlayer.userId)
    );

    return searchFunctionSchoolPlayers(user, text, uniqPlayersWithSelect);
  };

  renderPlayers = (players, setFieldValue) => {
    return players.map(player => {
      return (
        <div key={player.userId} className={'mb-3'}>
          <Autocomplete
            onSelect={() => {}}
            searchFunction={() => {}}
            getElementTitle={getFullName}
            defaultItem={player}
            disabled
          />
          <div className="eClearItemButton ml-3">
            <button
              type="button"
              className="close"
              onClick={() => {
                const playersFiltered = players.filter(p => p.userId !== player.userId);
                setFieldValue('players', playersFiltered);
                this.setState({
                  players: playersFiltered
                });
              }}
            >
              <span>&times;</span>
            </button>
          </div>
        </div>
      );
    });
  };

  render() {
    const { eventTasks, eventTaskIndex } = this.props;
    const isExistTask = eventTaskIndex !== -1;

    const jobInitial = {
      text: isExistTask ? propz.get(eventTasks, [eventTaskIndex, 'text'], '') : '',
      players: isExistTask ? propz.get(eventTasks, [eventTaskIndex, 'assignees'], []) : []
    };
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <Formik
              initialValues={jobInitial}
              validationSchema={EventJobSchema}
              onSubmit={values => {
                this.props.onSubmit(values);
              }}
              render={({ setFieldValue, touched, errors, values }) => (
                <Form>
                  <label>Description</label>
                  <Field component="textarea" rows={6} name="text" className="form-control mb-3" />
                  {touched.text && errors.text ? <div className="alert alert-danger">{errors.text}</div> : null}
                  <label>Players</label>
                  {this.renderPlayers(values.players, setFieldValue)}
                  <Field
                    name="players"
                    render={({ field }) => {
                      return (
                        <Autocomplete
                          searchFunction={this.getPlayers}
                          getElementTitle={getFullName}
                          customClass="mFullWidth mb-3"
                          defaultItem={undefined}
                          onSelect={player => {
                            player.userId = player.id;
                            player.permissionId = player.permissions[0]?.id;
                            const players = [].concat(values.players, player);
                            setFieldValue('players', players);
                            this.setState({
                              players: players
                            });
                          }}
                        />
                      );
                    }}
                  />
                  {touched.players && errors.players ? (
                    <div className="alert alert-danger">{errors.players}</div>
                  ) : null}

                  <Button onClick={this.props.onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                  <button type="submit" className="mt-3 mb-3 btn btn-primary">
                    Save
                  </button>
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}
