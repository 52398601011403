import * as Lazy from 'lazy.js';
import * as propz from 'propz';
import { SchoolForm } from '../../models/form';
import { School } from '../../models/school';
import { MultiSelectCheckboxItem } from '../../components/MultiSelectCheckboxes/MultiSelectCheckboxes';
import { SchoolTerms } from '../../models/schoolTerm';
import { WEEK_DAYS, WEEK_DAYS_SERVER_TO_CLIENT_MAPPING, WEEK_DAYS_TYPE } from '../../consts/date';
import { AGE_GROUPS_SORTED } from '../../consts/school';
import { Sport } from '../../models/sport';
import {
  GENDER,
  SPORT_GENDER_CLIENT_TO_SERVER_MAPPING,
  SPORT_GENDER_SERVER_TO_CLIENT_MAPPING
} from '../../consts/common';
import { GENDER_TYPE } from '../../types/common';
import { ClubScheduleIntervals, ClubSport } from '../../models/club';
import { sortAges } from 'Src/helpers/event/event';

export function getAgesForMultiSelect(school: School, forms: SchoolForm[], ages?: number[]): MultiSelectCheckboxItem[] {
  const { ageGroupsNaming } = school;
  const ageGroup = propz.get(AGE_GROUPS_SORTED, [ageGroupsNaming]);

  const formsUniq = Lazy(forms)
    .uniq('age')
    .sort((form1, form2) => {
      const ageIndex1 = ageGroup.indexOf(form1.ageGroup);
      const ageIndex2 = ageGroup.indexOf(form2.ageGroup);

      return ageIndex1 - ageIndex2;
    })
    .toArray();

  return formsUniq.map(formUniq => {
    return {
      isChecked: Array.isArray(ages) ? ages.some(age => age === formUniq.age) : false,
      name: formUniq.ageGroup
    };
  });
}

export function getReportAgesForMultiSelect(
  school: School,
  forms: SchoolForm[],
  ages?: number[]
): MultiSelectCheckboxItem[] {
  const { ageGroupsNaming } = school;

  const formsUniq = Lazy(forms)
    .uniq('age')
    .sort((form1, form2) => sortAges(form1.age, form2.age, ageGroupsNaming))
    .toArray();

  return formsUniq.map(formUniq => {
    return {
      isChecked: Array.isArray(ages) ? ages.some(age => age === formUniq.age) : true,
      name: formUniq.ageGroup
    };
  });
}

export function getDefaultReportAgesForMultiSelect(): MultiSelectCheckboxItem[] {
  const ageGroup = AGE_GROUPS_SORTED['ENGLISH'];

  return ageGroup.map(age => {
    return {
      isChecked: true,
      name: age
    };
  });
}

export function getTermsForMultiSelect(
  schoolTerms: SchoolTerms,
  intervals?: ClubScheduleIntervals[]
): MultiSelectCheckboxItem[] {
  const { terms } = schoolTerms;

  return terms.map(schoolTerm => ({
    name: schoolTerm.name,
    isChecked: Array.isArray(intervals)
      ? intervals.some(interval => {
          const startInterval = Number(new Date(interval.start));
          const endInterval = Number(new Date(interval.end));
          const startSchoolTerm = Number(new Date(schoolTerm.start));
          const endSchoolTerm = Number(new Date(schoolTerm.end));

          return startInterval === startSchoolTerm && endInterval === endSchoolTerm;
        })
      : false
  }));
}

export function getDaysForMultiselect(days?: WEEK_DAYS_TYPE[]): MultiSelectCheckboxItem[] {
  const weekDays = Object.keys(WEEK_DAYS);

  return weekDays.map(weekDay => {
    return {
      name: WEEK_DAYS_SERVER_TO_CLIENT_MAPPING[weekDay],
      isChecked: Array.isArray(days) ? days.some(day => day === weekDay) : false
    };
  });
}

export function getGendersForMultiSelect(sport: Sport | ClubSport, gender?: GENDER_TYPE): MultiSelectCheckboxItem[] {
  const { genders } = sport;
  const { maleOnly, femaleOnly } = genders;

  let gendersForMultiSelect = [];

  if (maleOnly) {
    gendersForMultiSelect.push({
      name: SPORT_GENDER_SERVER_TO_CLIENT_MAPPING.MALE_ONLY,
      isChecked: typeof gender !== 'undefined' ? gender === GENDER.MALE_ONLY || gender === GENDER.MIXED : false
    });
  }
  if (femaleOnly) {
    gendersForMultiSelect.push({
      name: SPORT_GENDER_SERVER_TO_CLIENT_MAPPING.FEMALE_ONLY,
      isChecked: typeof gender !== 'undefined' ? gender === GENDER.FEMALE_ONLY || gender === GENDER.MIXED : false
    });
  }

  return gendersForMultiSelect;
}

export function getDefaultGendersForMultiSelect(): MultiSelectCheckboxItem[] {
  const defaultGendersForMultiSelect = [
    {
      name: SPORT_GENDER_SERVER_TO_CLIENT_MAPPING.MALE_ONLY,
      isChecked: false
    },
    {
      name: SPORT_GENDER_SERVER_TO_CLIENT_MAPPING.FEMALE_ONLY,
      isChecked: false
    }
  ];

  return defaultGendersForMultiSelect;
}

export function getReportDefaultGendersForMultiSelect(): MultiSelectCheckboxItem[] {
  const defaultGendersForMultiSelect = [
    {
      name: SPORT_GENDER_SERVER_TO_CLIENT_MAPPING.MALE_ONLY,
      isChecked: true
    },
    {
      name: SPORT_GENDER_SERVER_TO_CLIENT_MAPPING.FEMALE_ONLY,
      isChecked: true
    }
  ];

  return defaultGendersForMultiSelect;
}

export function getGendersFromMultiSelect(genderItems): string {
  let gender;

  const genderItemsCount = genderItems.length;
  const genderItemsChecked = genderItems.filter(genderItem => genderItem.isChecked);
  const genderItemsCheckedCount = genderItemsChecked.length;

  switch (true) {
    //Boys or Girls
    case genderItemsCount === 1:
    case genderItemsCount === 2 && genderItemsCheckedCount === 1:
      gender = SPORT_GENDER_CLIENT_TO_SERVER_MAPPING[genderItemsChecked[0].name];
      break;
    case genderItemsCount === 2 && genderItemsCheckedCount === 2:
      gender = GENDER.MIXED;
      break;
    default:
      gender = GENDER.MIXED; // fallback
      console.error(`Gender not defined`);
  }

  return gender;
}

export function getAgesFromMultiSelect(school, forms, ageItems): number[] {
  // const formsUniq = Lazy(forms)
  //   .uniq('age')
  //   .sort((form1, form2) => form1.age - form2.age)
  //   .toArray();
  const { ageGroupsNaming } = school;
  const ageGroup = propz.get(AGE_GROUPS_SORTED, [ageGroupsNaming]);

  const formsUniq = Lazy(forms)
    .uniq('age')
    .sort((form1, form2) => {
      const ageIndex1 = ageGroup.indexOf(form1.ageGroup);
      const ageIndex2 = ageGroup.indexOf(form2.ageGroup);

      return ageIndex1 - ageIndex2;
    })
    .toArray();

  const ages: number[] = formsUniq.filter((form, index) => ageItems[index].isChecked).map(form => form.age);

  return ages;
}

export function getGendersFromClubMultiSelect(genderItems): string {
  let gender;

  const genderItemsCount = genderItems.length;
  const genderItemsChecked = genderItems.filter(genderItem => genderItem.isChecked);
  const genderItemsCheckedCount = genderItemsChecked.length;

  switch (true) {
    //Boys or Girls
    case genderItemsCount === 1:
    case genderItemsCount === 2 && genderItemsCheckedCount === 1:
      gender = genderItemsChecked[0].name;
      break;
    case genderItemsCount === 2 && genderItemsCheckedCount === 2:
      gender = GENDER.MIXED;
      break;
    default:
      gender = GENDER.MIXED; // fallback
      console.error(`Gender not defined`);
  }

  return gender;
}
