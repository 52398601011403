import axios from 'axios';
import { AppUser } from 'Src/views/App/App';
import { ConfirmationRequestTemplateField } from 'Src/models/confirmationRequestTemplate';

export function getConsentRequestTemplate(user: AppUser) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/template`, config).then(response => {
    return response.data;
  });
}

export function updateConsentRequestTemplate(
  user: AppUser,
  data: {
    fields: ConfirmationRequestTemplateField[];
  }
) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.put(`${window.apiBase}/i/schools/${activeSchoolId}/template`, data, config).then(response => {
    return response.data;
  });
}

export function sendConsentRequest(user: AppUser, eventId: string, data) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/players/messages/invite`, data, config)
    .then(response => {
      return response.data;
    });
}
