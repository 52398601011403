import * as React from 'react';

export const CrossIcon = () => {
  return (
    <svg
      className="bCrossIcon"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      baseProfile="tiny"
      x="0px"
      y="0px"
      viewBox="0 0 480 480"
    >
      <g id="cross">
        <path d="M310.182,235.995l103.285-103.259c5.006-5.018,5.006-13.237,0-18.251l-54.721-54.733c-5.014-5-13.229-5-18.24,0   l-103.281,103.28L133.944,59.752c-5.018-5-13.229-5-18.246,0l-54.717,54.733c-5.008,5.014-5.008,13.233,0,18.251l103.281,103.259   L60.999,339.263c-5.018,5.014-5.018,13.232,0,18.25l54.717,54.738c5.018,5.001,13.229,5.001,18.242,0l103.268-103.285   l103.264,103.285c5.018,5.001,13.229,5.001,18.24,0l54.721-54.738c5.014-5.018,5.014-13.236,0-18.25L310.182,235.995z" />
      </g>
    </svg>
  );
};

CrossIcon.displayName = 'CrossIcon';
