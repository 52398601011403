import * as React from 'react';
import * as propz from 'propz';
import * as Moment from 'moment';
import { SchoolInvite } from 'Src/models/invite';
import { AppUser } from 'Src/views/App/App';
import { Button } from 'Src/components/Button/Button';
import { getTournamentLocation } from 'Src/helpers/invite/invite';
import { AcceptTournamentInviteHeader } from '../AcceptTournamentInviteHeader/AcceptTournamentInviteHeader';

interface Props {
  invite: SchoolInvite;
  user: AppUser;
  onCancel: () => void;
  onAccept: () => void;
}

export function AcceptTournamentInviteWithoutAutoEnrollmentTeams(props: Props) {
  const { onCancel, onAccept, invite, user } = props;
  const { activeSchoolId } = user;
  const { tournament, inviterSchool } = invite;

  const tournamentLocation = getTournamentLocation(invite, activeSchoolId);
  const tournamentSportName = propz.get<string>(tournament, ['sport', 'name'], '');
  const tournamentName = propz.get<string>(tournament, ['name'], '');
  const tournamentStartTime = propz.get<string>(tournament, ['startTime']);
  const tournamentStartTimeFormatted = tournamentStartTime
    ? Moment(tournament.startTime).format('DD.MM.YYYY / HH:mm')
    : '';

  const inviterSchoolName = propz.get<string>(inviterSchool, ['name'], '');

  return (
    <div>
      <AcceptTournamentInviteHeader
        tournamentName={tournamentName}
        tournamentLocation={tournamentLocation}
        tournamentStartTimeFormatted={tournamentStartTimeFormatted}
        inviterSchoolName={inviterSchoolName}
        tournamentSportName={tournamentSportName}
      />
      <Button text={'Cancel'} onClick={onCancel} customClass={'btn btn-secondary mr-3 mt-3 mb-3'} />
      <Button text={'Book spaces'} onClick={onAccept} customClass={'mt-3 mb-3'} />
    </div>
  );
}
