import axios from 'axios';
import { AppUser } from 'Src/views/App/App';

export function verifyPhone(user: AppUser | { loginSessionKey: string }, token: string) {
  const { loginSessionKey: key } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.post(`${window.apiBase}/i/confirm/newPhone`, { token: token }, config).then(response => {
    return response.data;
  });
}

export function verifyEmail(user: AppUser | { loginSessionKey: string }, token: string) {
  const { loginSessionKey: key } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.post(`${window.apiBase}/i/confirm/newEmail`, { token: token }, config).then(response => {
    return response.data;
  });
}
