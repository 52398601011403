import * as React from 'react';
import * as Moment from 'moment';
import { AppUser } from 'Src/views/App/App';
import { SchoolEvent } from 'Src/models/event';
import { ARBITER_BOARD_MODE_TYPE } from 'Src/types/arbiterBoard';
import {
  convertPointsToString,
  convertStringToPoints,
  getDefaultDigitMask,
  getIndividualScoreByStudent,
  getIsError,
  getMask,
  getPlayerScoreId
} from 'Src/helpers/event/schoolEventViewHelper';
import { Button } from 'Src/components/Button/Button';
import { MODE_VIEW } from 'Src/consts/arbiterBoard';
import * as propz from 'propz';
import * as TextMaskCore from 'text-mask-core';
import { POINTS_DISPLAY } from 'Src/consts/sport';
import { DEFAULT_TIME_MASK } from 'Src/helpers/score/score2';
import { EMPTY_STRING } from 'Src/consts/date';
import { Switch } from 'Src/components/Switch/Switch';

interface Props {
  user: AppUser;
  event: SchoolEvent;
  selectedUserId: string;
  mode: ARBITER_BOARD_MODE_TYPE;
  onEditResultClick: () => void;
  onAddPhotoClick: (eventDescriptor: React.SyntheticEvent) => void;
  onAddVideoClick: (eventDescriptor: React.SyntheticEvent) => void;
  onEditCompletedClick: (data: any) => void;
  onSaveResultClick: (data: { userId: string; permissionId: string; scoreId: string; score: number }) => void;
  onCancelResultClick: () => void;
}

interface State {
  value: string;
  oldValue: string;
  isCompleted: boolean;
  isError: boolean;
  mask: boolean | (string | RegExp)[];
}

export class ArbiterBoardPlayer extends React.Component<Props, State> {
  inputElement: any;
  textMaskInputElement: any;

  constructor(props: Props) {
    super(props);

    const defaultState = this.getDefaultState(props);
    this.state = { ...defaultState };
  }

  getDefaultState(props: Props) {
    const { event, selectedUserId } = props;
    const mask = propz.get(event, ['sport', 'points', 'inputMask']);
    const inputMask = getMask(mask);

    const player = event.individualsData.find(individualData => individualData.userId === selectedUserId);
    const { userId, permissionId } = player;
    const individualCompleteness = propz.get(event, ['results', 'individualCompleteness'], []);
    const currentPlayersIndividualCompletenessData = individualCompleteness.find(
      score => score.userId === selectedUserId
    );
    const isCompleted = propz.get(currentPlayersIndividualCompletenessData, ['isCompleted'], false);

    const points = getIndividualScoreByStudent(event, userId, permissionId);
    const pointsStr = convertPointsToString(event, points);

    const value = points === 0 ? '' : pointsStr;
    const oldValue = points === 0 ? '' : pointsStr;

    return {
      isError: false,
      isCompleted,
      mask: inputMask,
      value,
      oldValue
    };
  }

  componentDidMount() {
    this.initTextMask();
  }

  initTextMask() {
    const { value, mask } = this.state;
    const { event } = this.props;

    this.textMaskInputElement = TextMaskCore.createTextMaskInputElement({
      inputElement: this.inputElement,
      mask: mask || (inputValue => getDefaultDigitMask(event, inputValue)),
      showMask: false
    });

    this.textMaskInputElement.update(value);
  }

  componentDidUpdate(prevProps) {
    const { selectedUserId, mode } = this.props;

    const isSelectedUserIdChanged = selectedUserId !== prevProps.selectedUserId;
    const isModeChanged = mode !== prevProps.mode;

    if (isSelectedUserIdChanged || isModeChanged) {
      const defaultState = this.getDefaultState(this.props);
      this.setState(
        {
          ...defaultState
        },
        () => {
          this.initTextMask();
        }
      );
    }
  }

  onSaveResult = () => {
    const { event, selectedUserId, onSaveResultClick } = this.props;
    const { value } = this.state;

    const pointType = propz.get(event, ['sport', 'points', 'display'], POINTS_DISPLAY.TIME);
    const mask = propz.get(event, ['sport', 'points', 'inputMask'], DEFAULT_TIME_MASK);

    const isError = getIsError(pointType, value, mask);

    if (isError) {
      this.setState({
        isError: isError
      });
      return;
    }

    const { individualsData } = event;
    const player = individualsData.find(individualData => individualData.userId === selectedUserId);
    const { userId, permissionId } = player;

    const data = {
      userId: userId,
      permissionId: permissionId,
      score: convertStringToPoints(event, value),
      scoreId: getPlayerScoreId(event, userId, permissionId)
    };

    onSaveResultClick(data);
  };

  onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;

    this.setState({
      value: value,
      isError: false
    });

    this.textMaskInputElement.update(value);
  }

  onFocus(e) {
    const { value } = this.state;

    this.setState({
      oldValue: value
    });

    this.textMaskInputElement.update('');
    e.stopPropagation();
  }

  onBlur(e) {
    const eventValue = e.target.value;
    const { oldValue } = this.state;

    if (eventValue === EMPTY_STRING) {
      this.setState({
        value: oldValue
      });
      this.textMaskInputElement.update(oldValue);
    } else {
      this.setState({
        value: eventValue
      });
      this.textMaskInputElement.update(eventValue);
    }
    e.stopPropagation();
  }

  render() {
    const {
      user,
      event,
      selectedUserId,
      mode,
      onEditResultClick,
      onCancelResultClick,
      onAddPhotoClick,
      onAddVideoClick,
      onEditCompletedClick
    } = this.props;

    const { isError, isCompleted } = this.state;
    const activeUserId = propz.get(user, ['userId'], '');
    const activeUserPermissionId = propz.get(user, ['activePermission', 'id'], '');
    const { individualsData } = event;
    const player = individualsData.find(individualData => individualData.userId === selectedUserId);
    const { firstName, lastName, userId, permissionId } = player;

    const individualCompleteness = propz.get(event, ['results', 'individualCompleteness'], []);
    const selectedPlayerCompletenessData = individualCompleteness.find(item => item.userId === selectedUserId);
    const isSelectedPlayerCompletenessDataExist = typeof selectedPlayerCompletenessData !== 'undefined';
    const isCompletedAt = propz.get(selectedPlayerCompletenessData, ['isCompletedAt'], '');

    const completedBy = propz.get(selectedPlayerCompletenessData, ['completedBy']);
    const completedByFirstName = propz.get(completedBy, ['firstName'], '');
    const completedByLastName = propz.get(completedBy, ['lastName'], '');

    const completedByName = `${completedByFirstName} ${completedByLastName}`;
    const completedByDate = Moment(isCompletedAt).format('DD/MM/YYYY HH:mm');
    const completedByText = `${completedByName}, ${completedByDate}`;

    const isCompletedByTextShow = isSelectedPlayerCompletenessDataExist && isCompleted;

    const isModeView = mode === MODE_VIEW;

    return (
      <div className="eArbiterBoardPlayer">
        <div className="eArbiterBoardPlayersName font-weight-bold">{`${firstName} ${lastName}`}</div>

        <div className="d-flex align-content-center my-2">
          <p className="mb-0 mr-2">Completed: </p>
          <p className="mb-0 mr-3">No</p>
          <Switch
            key={'isAllNotifications'}
            value={isCompleted}
            onChange={event => {
              const isChecked = event.target.checked;

              const data = {
                userId,
                permissionId,
                isCompleted: isChecked,
                completedBy: {
                  userId: activeUserId,
                  permissionId: activeUserPermissionId
                }
              };

              onEditCompletedClick(data);
            }}
            text={''}
            name="isCompleted"
          />
          <p className="mb-0 ml-2">Yes</p>
        </div>

        {isModeView ? (
          <div className="d-flex align-items-center">
            <p className="mb-0">Current result: </p>

            <input
              ref={el => (this.inputElement = el)}
              onChange={event => this.onChange(event)}
              onFocus={event => this.onFocus(event)}
              onBlur={event => this.onBlur(event)}
              placeholder={''}
              className={isError === true ? 'eArbiterBoardInput mError' : 'eArbiterBoardInput'}
              disabled={true}
            />

            <Button customClass="btn btn-success mArbiterBoardButton" text="Update" onClick={onEditResultClick} />
          </div>
        ) : (
          <div className="d-flex align-items-center">
            <p className="mb-0">Current result: </p>

            <input
              ref={el => (this.inputElement = el)}
              onChange={event => this.onChange(event)}
              onFocus={event => this.onFocus(event)}
              onBlur={event => this.onBlur(event)}
              className={isError === true ? 'eArbiterBoardInput mError' : 'eArbiterBoardInput'}
            />

            <Button text="Cancel" customClass="mr-3 btn-secondary mArbiterBoardButton" onClick={onCancelResultClick} />
            <Button customClass="btn btn-success mArbiterBoardButton" text="Save" onClick={this.onSaveResult} />
          </div>
        )}

        {isCompletedByTextShow && (
          <div className="my-2">
            <p className="mb-0">
              Confirmed by: <span className="text-primary">{completedByText}</span>
            </p>
          </div>
        )}

        <div className="mt-2">
          <Button text={'Add photo'} customClass={'mr-3 btn-primary'} onClick={onAddPhotoClick} />

          <Button text={'Add video'} customClass={'btn-primary'} onClick={onAddVideoClick} />
        </div>
      </div>
    );
  }
}
