import * as React from 'react';
import { Route, Switch } from 'react-router';
import { Clubs } from './Clubs';
import { ClubStudents } from './ClubStudents';
import { ClubParticipants } from './ClubParticipants';
import { ClubMessages } from './ClubMessages';
import { ClubWizard } from './ClubWizard/ClubWizard';
import { ClubEvents } from './ClubEvents/ClubEvents';

export const ClubRouting = allProps => {
  return (
    <Switch>
      <Route exact path={'/clubs'} render={props => <Clubs {...props} user={allProps.user} />} />
      <Route exact path={'/clubs/students'} render={props => <ClubStudents {...props} user={allProps.user} />} />
      <Route
        exact
        path={'/clubs/participants'}
        render={props => <ClubParticipants {...props} user={allProps.user} />}
      />
      <Route exact path={'/clubs/messages'} render={props => <ClubMessages {...props} user={allProps.user} />} />
      <Route exact path={'/clubs/wizard'} render={props => <ClubWizard {...props} user={allProps.user} />} />
      <Route exact path={'/clubs/events'} render={props => <ClubEvents {...props} user={allProps.user} />} />
    </Switch>
  );
};

ClubRouting.displayName = 'ClubRouting';
