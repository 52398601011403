import * as React from 'react';
import * as BPromise from 'bluebird';
import { AppUser } from 'Src/views/App/App';
import { parse } from 'query-string';
import { getOrder, getSearchFilter, getSearchOrder, getServerFieldSectionWhere } from 'Src/helpers/table/table';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE, LIMIT } from 'Src/consts/table';
import { Loader } from 'Src/components/Loader/Loader';
import {
  ColumnDefinition,
  getFilters2,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { History, Location } from 'history';
import { Grid2 } from 'Src/components/Grid/Grid2';
import {
  getCreatedAt,
  getGeneralMessageCreatedBy,
  getGeneralMessageSender,
  getGeneralMessageSentAt,
  getGeneralMessageStatus
} from 'Src/helpers/accessor/accessor';
import { Attachment, GeneralMessage } from 'Src/models/generalMessage';
import { getSelectOptionsForGeneralMessagesStatus } from 'Src/helpers/table/select';
import {
  deleteSchoolGeneralMessage,
  getAllSchoolGeneralMessages,
  getSchoolGeneralMessage,
  getSchoolGeneralMessages,
  getSchoolGeneralMessagesCount,
  sendSchoolGeneralMessage,
  updateSchoolGeneralMessage
} from 'Src/helpers/service/admin/generalMessage';

import './GeneralMessages.css';
import { GENERAL_MESSAGE_STATUS, GENERAL_MESSAGE_TABS } from 'Src/consts/generalMessage';
import { GenericSummary } from 'Src/components/GenericSummary/GenericSummary';
import { Button } from 'Src/components/Button/Button';
import { ScheduleInfo } from 'Src/components/GeneralMessages/ScheduleInfo/ScheduleInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ResizablePanel } from 'Src/components/ResizablePanel/ResizablePanel';
import { GeneralMessageContent } from 'Src/components/GeneralMessageSummary/MessageContent';
import { GeneralMessageTextForm } from 'Src/components/GeneralMessageTextForm/GeneralMessageTextForm';
import { GeneralMessagePreview } from 'Src/components/GeneralMessagePreview/GeneralMessagePreview';
import { GeneralMessageSubscribers } from 'Src/components/GeneralMessageSubscribers/GeneralMessageSubscribers';
import { MessageDeliveryStatus } from 'Src/components/GeneralMessageSummary/MessageDeliveryStatus';
import { uploadFileAllPath } from 'Src/helpers/service/file';
import * as propz from 'propz';
import { HTML_FIRST_PART_PAGE_CODE, HTML_LAST_PART_PAGE_CODE } from 'Src/components/HTMLEditorQuill/HTMLEditorConst';
import { getSettingsUploadFiles } from 'Src/helpers/service/nobody/settings';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Title',
    field: 'title',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['title']
  },
  {
    text: 'Status',
    field: 'statusOptions',
    isSort: true,
    type: FILTER_TYPE.SELECT,
    accessor: getGeneralMessageStatus
  },
  {
    text: 'Created by',
    field: 'createdBy',
    isSort: false,
    type: FILTER_TYPE.TEXT,
    accessor: getGeneralMessageCreatedBy
  },
  {
    text: 'Created at',
    field: 'createdAt',
    isSort: true,
    type: FILTER_TYPE.NONE,
    accessor: getCreatedAt
  },
  {
    text: 'Sent by',
    field: 'sender',
    isSort: false,
    type: FILTER_TYPE.TEXT,
    accessor: getGeneralMessageSender
  },
  {
    text: 'Sent at',
    field: 'sentAt',
    isSort: true,
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: getGeneralMessageSentAt
  }
];

interface State {
  items: GeneralMessage[];
  currentPage: number;
  selectedItems: GeneralMessage[];
  generalMessage: GeneralMessage;
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;

  currentTabIndex: number;
  isEditable: boolean;
  isError: boolean;
  errorMessage: string;
  isLoadingFile: boolean;

  // delivery status
  isMessageRefreshing: boolean;

  //content
  messageSmsText: string;
  messageEmailSubject: string;
  messageEmailBody: string;
  messagePushTitle: string;
  messagePushBody: string;

  isSmsEditorEnabled: boolean;
  isPushEditorEnabled: boolean;
  isEmailEditorEnabled: boolean;
  //email attachments
  messageAttachments: Attachment[];
  maxSizeUploadFile: number;
  filesSize: number;
  isImageError: boolean;
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export class GeneralMessages extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      generalMessage: undefined,
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',
      isError: false,
      errorMessage: '',

      currentTabIndex: 0,
      isEditable: false,
      isLoadingFile: false,

      isMessageRefreshing: false,

      messageSmsText: '',
      messageEmailSubject: '',
      messageEmailBody: '',
      messagePushTitle: '',
      messagePushBody: '',

      isSmsEditorEnabled: true,
      isPushEditorEnabled: true,
      isEmailEditorEnabled: true,

      messageAttachments: [],
      maxSizeUploadFile: 0,
      filesSize: 0,
      isImageError: false
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setItems();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const getItemsPromise = getSchoolGeneralMessages(user, serverQueryFilter);
    const getItemsCountPromise = getSchoolGeneralMessagesCount(user, where);
    const getSettingsUploadFilesPromise = getSettingsUploadFiles(user);

    const promises = [getItemsCountPromise, getItemsPromise, getSettingsUploadFilesPromise];

    return BPromise.all(promises).then(([countObj, items, maxSizeUploadFile]) => {
      const total = countObj.count;
      const message = propz.get(history, ['location', 'state', 'message']);
      if (typeof message !== 'undefined') {
        //clear history
        const state = { ...(history.location.state as any) };
        delete state.message;
        history.replace({ ...history.location, state });

        this.setState({
          isLoading: true
        });

        const messageId = message.id;
        getSchoolGeneralMessage(user, messageId).then(twoPartMessage => {
          const messageEmailSubject = propz.get(twoPartMessage, ['content', 'email', 'subject'], '');
          const messageEmailBody = propz.get(twoPartMessage, ['content', 'email', 'body'], '');
          const messagePushBody = propz.get(twoPartMessage, ['content', 'push', 'body'], '');
          const messageSmsText = propz.get(twoPartMessage, ['content', 'sms', 'text'], '');
          const messagePushTitle = propz.get(twoPartMessage, ['content', 'push', 'title'], '');
          const messageAttachments = propz.get(twoPartMessage, ['content', 'email', 'attachments'], []);

          this.setState({
            generalMessage: twoPartMessage,
            selectedItems: [message],
            sortDirection: sortDirection,
            sortColumnsName: sortColumnsName,
            isShowFilter: isShowFilter,
            isDataFiltered: isDataFiltered,
            filters: filters,
            items: items,
            total: total,
            basePath: history.location.pathname,
            isLoading: false,

            messageEmailSubject: messageEmailSubject,
            messageEmailBody: messageEmailBody,
            messagePushBody: messagePushBody,
            messageSmsText: messageSmsText,
            messagePushTitle: messagePushTitle,
            messageAttachments: messageAttachments,
            maxSizeUploadFile: maxSizeUploadFile
          });
        });
      } else {
        this.setState({
          currentPage: page,
          sortDirection: sortDirection,
          sortColumnsName: sortColumnsName,
          isShowFilter: isShowFilter,
          isDataFiltered: isDataFiltered,
          filters: filters,
          items: items,
          total: total,
          basePath: history.location.pathname,
          maxSizeUploadFile: maxSizeUploadFile,
          isLoading: false
        });
      }

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const { user } = this.props;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated,
      isEditable: false
    });

    if (selectedItemsUpdated.length === 1) {
      this.setState({
        isMessageRefreshing: true
      });

      const messageId = selectedItemsUpdated[0].id;
      getSchoolGeneralMessage(user, messageId).then(message => {
        const messageEmailSubject = propz.get(message, ['content', 'email', 'subject'], '');
        const messageEmailBody = propz.get(message, ['content', 'email', 'body'], '');
        const messagePushBody = propz.get(message, ['content', 'push', 'body'], '');
        const messageSmsText = propz.get(message, ['content', 'sms', 'text'], '');
        const messagePushTitle = propz.get(message, ['content', 'push', 'title'], '');
        const messageAttachments = propz.get(message, ['content', 'email', 'attachments'], []);

        this.setState({
          generalMessage: message,
          messageEmailSubject,
          messageEmailBody,
          messagePushBody,
          messageSmsText,
          messagePushTitle,
          messageAttachments,
          isMessageRefreshing: false
        });
      });
    }
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: nextSelectedItems,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    getAllSchoolGeneralMessages(user, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const nextSelectedItems = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: nextSelectedItems,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: ''
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  onCreateMessageClick = (): void => {
    const { history } = this.props;

    this.props.history.push({
      pathname: `/generalMessages/create`,
      state: {
        isClearPage: true,
        isCreateLikeThis: false,
        search: history.location.search
      }
    });
  };

  onCreateMessageLikeThisClick = (isAllMessageCopy: boolean): void => {
    const { history } = this.props;
    const { generalMessage } = this.state;
    const message = this.state.selectedItems[0];

    this.props.history.push({
      pathname: `/generalMessages/create`,
      state: {
        search: history.location.search,
        message: message,
        generalMessage: generalMessage,
        isCreateLikeThis: true,
        isAllMessageCopy: isAllMessageCopy
      }
    });
  };

  isOneMessageSelected() {
    const selectedItems = this.state.selectedItems;
    return selectedItems.length === 1;
  }

  onDeleteMessageClick = () => {
    const { selectedItems } = this.state;
    const { user } = this.props;
    this.setState({
      isLoading: true
    });

    BPromise.all(
      selectedItems.map(message => {
        return deleteSchoolGeneralMessage(user, message.id);
      })
    ).then(res => {
      this.setState({
        selectedItems: [],
        isSelectAllChecked: false
      });
      this.setItems();
    });
  };

  isDeleteMessageActive(): boolean {
    const { selectedItems } = this.state;

    const isEveryMessagesStatusDraft = selectedItems.every(message => message.status === GENERAL_MESSAGE_STATUS.DRAFT);
    return selectedItems.length > 0 && isEveryMessagesStatusDraft;
  }

  renderMessagesTable(): React.ReactNode {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isSelectAllChecked,
      currentPage,
      total
    } = this.state;

    const isOneMessageSelected = this.isOneMessageSelected();

    const actionItems = [
      {
        itemText: 'Create message',
        onItemClick: this.onCreateMessageClick,
        isActive: selectedItems.length === 0
      },
      {
        itemText: 'Create like this (content only)',
        onItemClick: () => this.onCreateMessageLikeThisClick(false),
        isActive: isOneMessageSelected
      },
      {
        itemText: 'Create like this (content & contact list)',
        onItemClick: () => this.onCreateMessageLikeThisClick(true),
        isActive: isOneMessageSelected
      },
      {
        itemText: selectedItems.length > 1 ? 'Delete messages' : 'Delete message',
        onItemClick: this.onDeleteMessageClick,
        isActive: this.isDeleteMessageActive()
      }
    ];

    const filterOptions = {
      statusOptions: getSelectOptionsForGeneralMessagesStatus()
    };

    return (
      <Grid2
        dataItems={items}
        filters={filters}
        currentPage={currentPage}
        total={total}
        isSelectAllChecked={isSelectAllChecked}
        isDataFiltered={isDataFiltered}
        sortDirection={sortDirection}
        sortColumnsName={sortColumnsName}
        isShowFilter={isShowFilter}
        dataItemsSelected={selectedItems}
        columns={COLUMNS}
        actionItems={actionItems}
        options={filterOptions}
        onItemClick={this.onItemClick}
        onSortClick={this.onTableSortClick}
        onApplyFilterClick={this.onApplyFilterClick}
        onClearFilterClick={this.onClearFilterClick}
        onTableFilterChange={this.onTableFilterChange}
        onTableFilterClick={this.onTableFilterClick}
        setCurrentPageParams={this.setCurrentPageParams}
        onSelectAllClick={this.onSelectAllClick}
        onSelectAllOnPageClick={this.onSelectAllOnPageClick}
        onUnselectAllClick={this.onUnselectAllClick}
      />
    );
  }

  onEmailSubjectChange = (event): void => {
    const subject = event.target.value;

    this.setState({ messageEmailSubject: subject });
  };

  onEmailBodyChange = (body: any): void => {
    this.setState({ messageEmailBody: body });
  };

  onSmsTextChange = (event): void => {
    const smsText = event.target.value;

    this.setState({ messageSmsText: smsText });
  };

  onPushTitleChange = (event): void => {
    const title = event.target.value;

    this.setState({ messagePushTitle: title });
  };

  onPushBodyChange = (event): void => {
    const body = event.target.value;

    this.setState({ messagePushBody: body });
  };

  onEmailAttachmentChange = (event): void => {
    const { user } = this.props;
    const { filesSize, maxSizeUploadFile } = this.state;
    const attachments = [...this.state.messageAttachments];
    const file = event.target.files[0];
    const currentFileSize = event.target.files[0].size;
    const fileName = file.name;
    const fileNameWithoutSpaces = fileName.split(' ').join('_');
    const formData = new FormData();
    formData.append('file', file, fileNameWithoutSpaces);

    const allFilesSize = filesSize + currentFileSize;

    if (allFilesSize > maxSizeUploadFile) {
      let maxSizeUploadFileText = '0 Mb';
      if (maxSizeUploadFile > 0) {
        maxSizeUploadFileText = `${(maxSizeUploadFile / 1000000).toFixed(0)}Mb`;
      }
      const errorText = `The message cannot be sent as the total size of attachments is too large.
      Please ensure your attachments don't exceed ${maxSizeUploadFileText} and try sending a message again.`;
      this.setState({
        errorMessage: errorText,
        isError: true
      });
    } else {
      this.setState({
        isLoadingFile: true
      });
      uploadFileAllPath(user, formData)
        .then(data => {
          const { key, link } = data;
          attachments.push({
            fileName: fileNameWithoutSpaces,
            link: link,
            externalFileId: key,
            fileSize: currentFileSize
          });
          this.setState({
            messageAttachments: attachments,
            filesSize: allFilesSize,
            isLoadingFile: false
          });
        })
        .catch(error => {
          this.setState({
            isImageError: true,
            isLoadingFile: false
          });
        });
    }
  };

  onCloseImageError = () => {
    this.setState({
      isImageError: false
    });
  };

  onIsEmailEditorChange = event => {
    const isEmailEditorEnabled = event.target.checked;

    if (!isEmailEditorEnabled) {
      this.setState({
        messageEmailSubject: '',
        messageEmailBody: '',
        messageAttachments: []
      });
    }

    this.setState({
      isEmailEditorEnabled: isEmailEditorEnabled
    });
  };

  onIsSmsEditorChange = event => {
    const isSmsEditorEnabled = event.target.checked;

    if (!isSmsEditorEnabled) {
      this.setState({
        messageSmsText: ''
      });
    }

    this.setState({ isSmsEditorEnabled: isSmsEditorEnabled });
  };

  onIsPushEditorChange = event => {
    const isPushEditorEnabled = event.target.checked;
    if (!isPushEditorEnabled) {
      this.setState({
        messagePushBody: '',
        messagePushTitle: ''
      });
    }
    this.setState({ isPushEditorEnabled: isPushEditorEnabled });
  };

  onRemoveAttachment = (key: string): void => {
    const attachments = [...this.state.messageAttachments];
    const attachmentIndex = attachments.findIndex(attachment => attachment.externalFileId === key);

    attachments.splice(attachmentIndex, 1);

    this.setState({
      messageAttachments: attachments
    });
  };

  getGeneralMessageSummaryTabs() {
    const isOneMessageSelected = this.isOneMessageSelected();

    if (!isOneMessageSelected) {
      return null;
    }

    const {
      currentTabIndex,
      isEditable,
      messageSmsText,
      messageEmailSubject,
      messageEmailBody,
      messagePushTitle,
      messagePushBody,
      messageAttachments,
      isMessageRefreshing,
      generalMessage,
      isSmsEditorEnabled,
      isPushEditorEnabled,
      isEmailEditorEnabled,
      isLoadingFile,
      isImageError,
      isError
    } = this.state;

    if (isMessageRefreshing) {
      return (
        <div>
          <Loader />
        </div>
      );
    }

    const subscribers = propz.get(generalMessage, ['affectedUserList'], []);
    const statistics = propz.get(generalMessage, ['statistics'], {});

    // attachments are located in the message body, so you need to remove them
    const messageEmailBodyWithoutLink = messageEmailBody.replace(/<div>Attachments:(.+)\s?/, '');

    switch (currentTabIndex) {
      case 0:
        return <GeneralMessageContent message={generalMessage} />;
      case 1:
        if (isEditable) {
          return (
            <div>
              {this.renderSendingError()}
              <GeneralMessageTextForm
                onEmailSubjectChange={this.onEmailSubjectChange}
                onEmailBodyChange={this.onEmailBodyChange}
                onSmsTextChange={this.onSmsTextChange}
                onPushTitleChange={this.onPushTitleChange}
                onPushBodyChange={this.onPushBodyChange}
                onEmailAttachmentChange={this.onEmailAttachmentChange}
                onRemoveAttachment={this.onRemoveAttachment}
                onCloseImageError={this.onCloseImageError}
                isEditable={isEditable}
                isError={isError}
                isImageError={isImageError}
                isLoadingFile={isLoadingFile}
                messageSmsText={messageSmsText}
                messageEmailSubject={messageEmailSubject}
                messageEmailBody={messageEmailBodyWithoutLink}
                messagePushTitle={messagePushTitle}
                messagePushBody={messagePushBody}
                messageAttachments={messageAttachments}
                isEmailEditorEnabled={isEmailEditorEnabled}
                isSmsEditorEnabled={isSmsEditorEnabled}
                isPushEditorEnabled={isPushEditorEnabled}
                onIsEmailEditorChange={this.onIsEmailEditorChange}
                onIsSmsEditorChange={this.onIsSmsEditorChange}
                onIsPushEditorChange={this.onIsPushEditorChange}
              />
            </div>
          );
        } else {
          return (
            <GeneralMessagePreview
              messageSmsText={messageSmsText}
              messageEmailSubject={messageEmailSubject}
              messageEmailBody={messageEmailBodyWithoutLink}
              messagePushTitle={messagePushTitle}
              messagePushBody={messagePushBody}
              messageAttachments={messageAttachments}
            />
          );
        }
      case 2:
        return <GeneralMessageSubscribers subscribers={subscribers} />;
      case 3:
        return <MessageDeliveryStatus statistics={statistics} />;
    }
  }

  onTabClick = (event, index: number): void => {
    event.preventDefault();

    if (this.state.isEditable) {
      return;
    }

    this.setState({
      currentTabIndex: index
    });
  };

  onGeneralMessageEditButtonClick = (): void => {
    this.setState({
      isEditable: true
    });
  };

  onGeneralMessageSendButtonClick = () => {
    const { selectedItems } = this.state;
    const message = selectedItems[0];
    const generalMessageId = message.id;
    const user = this.props.user;

    this.setState({
      isLoading: true
    });

    sendSchoolGeneralMessage(user, generalMessageId).then(message => {
      this.setState({
        selectedItems: [message]
      });
      this.setItems();
    });
  };

  getAttachmentAsLinksString = () => {
    const { messageAttachments } = this.state;
    if (messageAttachments.length > 0) {
      const attachmentAsLink = messageAttachments
        .map((attachment, index) => {
          return `<div key='attachment_${index}'><a href=${attachment.link} target="_blank">${attachment.fileName}</a></div>`;
        })
        .join('');
      return `<div>Attachments: ${attachmentAsLink}</div>`;
    } else {
      return '';
    }
  };

  onGeneralMessageSaveButtonClick = () => {
    const {
      messageSmsText,
      messageEmailSubject,
      messageEmailBody,
      messagePushTitle,
      messagePushBody,
      selectedItems,
      messageAttachments,
      isEmailEditorEnabled,
      isPushEditorEnabled,
      isSmsEditorEnabled
    } = this.state;

    let newMessageEmailBody;
    if (messageEmailBody === '<p><br></p>') {
      //  because   "<p><br></p>" === ''  in editor
      newMessageEmailBody = '';
      this.setState({
        messageEmailBody: newMessageEmailBody
      });
    } else {
      newMessageEmailBody = messageEmailBody;
    }

    // returns an error if content is empty
    const isSMSTextExist = messageSmsText !== '';
    const isPushBodyExist = messagePushBody !== '';
    const isPushTitleExist = messagePushTitle !== '';
    const isEmailSubjectExist = messageEmailSubject !== '';
    const isEmailBodyExist = newMessageEmailBody !== '';

    const isShowSMSError = !isSMSTextExist && isSmsEditorEnabled;
    const isShowPushError = (!isPushBodyExist || !isPushTitleExist) && isPushEditorEnabled;
    const isShowEmailError = (!isEmailSubjectExist || !isEmailBodyExist) && isEmailEditorEnabled;

    if (isShowSMSError || isShowPushError || isShowEmailError) {
      let errorText = '';

      switch (true) {
        case isShowSMSError:
          errorText = 'Text for sms content should be defined';
          break;
        case isShowPushError:
          errorText = 'Title or body for push content should be defined';
          break;
        case isShowEmailError:
          errorText = 'Subject or body for email content should be defined';
          break;
      }
      this.setState({
        isError: true,
        errorMessage: errorText
      });
    } else {
      const selectedMessage = selectedItems[0];
      const selectedMessageId = selectedMessage.id;

      const messageEmailBodyWithAttachment = newMessageEmailBody + this.getAttachmentAsLinksString();
      const messageHTMLEmailBody =
        newMessageEmailBody !== ''
          ? `${HTML_FIRST_PART_PAGE_CODE} ${messageEmailBodyWithAttachment} ${HTML_LAST_PART_PAGE_CODE}`
          : '';

      const data = {
        content: {
          sms: { text: messageSmsText },
          email: { subject: messageEmailSubject, body: messageHTMLEmailBody, attachments: messageAttachments },
          push: { title: messagePushTitle, body: messagePushBody }
        }
      };

      this.setState({
        isEditable: false,
        isLoading: true
      });

      const { user } = this.props;

      updateSchoolGeneralMessage(user, data, selectedMessageId).then(messageUpdated => {
        this.setState({
          selectedItems: [messageUpdated]
        });

        this.setItems();
      });
    }
  };

  onGeneralMessageShowNotificationsButtonClick = () => {
    const { selectedItems } = this.state;
    const message = selectedItems[0];

    this.props.history.push({
      pathname: `/notifications`,
      search: `generalMessageId=${message.id}`
    });
  };

  onRefreshClick = (): void => {
    this.setState({ isMessageRefreshing: true });

    const { selectedItems } = this.state;
    const message = selectedItems[0];
    const generalMessageId = message.id;
    const user = this.props.user;

    getSchoolGeneralMessage(user, generalMessageId).then(message => {
      this.setState({
        generalMessage: message,
        isMessageRefreshing: false
      });
    });
  };

  onCloseErrorClick = event => {
    event.preventDefault();

    this.setState({
      isError: false,
      errorMessage: ''
    });
  };

  renderSummary(): React.ReactNode {
    const {
      selectedItems,
      currentTabIndex,
      isEditable,
      isLoadingFile,
      isSmsEditorEnabled,
      isPushEditorEnabled,
      isEmailEditorEnabled,
      isError
    } = this.state;

    const isOneMessageSelected = this.isOneMessageSelected();
    const generalMessageSummaryTabs = this.getGeneralMessageSummaryTabs();
    const isMessageStatusDraft = isOneMessageSelected && selectedItems[0].status === GENERAL_MESSAGE_STATUS.DRAFT;
    const isMessageStatusSent = isOneMessageSelected && selectedItems[0].status === GENERAL_MESSAGE_STATUS.SENT;
    const isMessageStatusScheduled =
      isOneMessageSelected && selectedItems[0].status === GENERAL_MESSAGE_STATUS.SCHEDULED;
    const isShowScheduleInfo = (currentTabIndex === 0 || currentTabIndex === 3) && isMessageStatusScheduled;
    const isShowEditButton = isMessageStatusDraft && currentTabIndex === 1 && !isEditable;
    const isShowSaveButton = isMessageStatusDraft && currentTabIndex === 1 && isEditable;
    const isShowRefreshButton = isMessageStatusSent && currentTabIndex === 3;
    const isShowSendButton = (currentTabIndex === 0 || currentTabIndex === 3) && isMessageStatusDraft;
    const isAllEditorDisabled = !isSmsEditorEnabled && !isPushEditorEnabled && !isEmailEditorEnabled;

    if (!isOneMessageSelected) {
      return <div className="font-weight-bold">Select a general message above</div>;
    }

    return (
      <div>
        {isOneMessageSelected && (
          <GenericSummary currentTabIndex={currentTabIndex} onTabClick={this.onTabClick} tabs={GENERAL_MESSAGE_TABS}>
            {isShowEditButton && (
              <Button onClick={this.onGeneralMessageEditButtonClick} text={'Edit message'} customClass={'mt-3'} />
            )}
            {isShowScheduleInfo && (
              <ScheduleInfo
                user={this.props.user}
                messageId={selectedItems[0].id}
                onSendNowClick={this.onGeneralMessageSendButtonClick}
              />
            )}
            {isShowSaveButton && (
              <Button
                onClick={this.onGeneralMessageSaveButtonClick}
                disabled={isLoadingFile || isAllEditorDisabled || isError}
                text={'Save message'}
                customClass={'mt-3 mb-3'}
              />
            )}
            {isShowSendButton && !isShowScheduleInfo && (
              <Button onClick={this.onGeneralMessageSendButtonClick} text={'Send message'} customClass={'mt-3 mb-3'} />
            )}
            {isOneMessageSelected && isMessageStatusSent && currentTabIndex === 0 && (
              <Button
                onClick={this.onGeneralMessageShowNotificationsButtonClick}
                text={'Show notifications'}
                customClass={'mt-3 mb-3'}
              />
            )}
            {isShowRefreshButton && (
              <>
                <Button
                  onClick={this.onRefreshClick}
                  text={[
                    <FontAwesomeIcon key={'general_message_refresh_button'} className={'mr-2'} icon="sync-alt" />,
                    'Refresh'
                  ]}
                  customClass={'mt-3 mb-3 mr-3'}
                />
                <Button
                  onClick={this.onGeneralMessageShowNotificationsButtonClick}
                  text={'Show notifications'}
                  customClass={'mt-3 mb-3'}
                />
              </>
            )}
            {generalMessageSummaryTabs}
          </GenericSummary>
        )}
      </div>
    );
  }

  renderSendingError(): React.ReactNode {
    const { errorMessage, isError } = this.state;

    if (!isError) {
      return null;
    }

    return (
      <div className="alert fade show alert-danger d-flex justify-content-between align-items-center" role="alert">
        {errorMessage}
        <button
          type="button"
          className="ml-3 btn btn-danger"
          aria-label="Close"
          onClick={event => this.onCloseErrorClick(event)}
        >
          Close
        </button>
      </div>
    );
  }

  render() {
    const { isLoading } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes = 'mt-3';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        <div className="row">
          <div className="col-md-12">
            <ResizablePanel>
              {this.renderMessagesTable()}
              {this.renderSummary()}
            </ResizablePanel>
          </div>
        </div>
      </div>
    );
  }
}
