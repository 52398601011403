import { AppUser } from 'Src/views/App/App';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from 'Src/consts/table';
import axios from 'axios';

export function getAllEventVideos(user: AppUser, eventId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        limit: DEFAULT_LIMIT,
        skip: DEFAULT_SKIP
      }
    }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/videos`, config).then(response => {
    return response.data;
  });
}

export function addEventVideo(user: AppUser, eventId: string, data) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/videos`, data, config)
    .then(response => {
      return response.data;
    });
}

export function deleteEventVideo(user: AppUser, eventId: string, videoId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .delete(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/videos/${videoId}`, config)
    .then(response => {
      return response.data;
    });
}

export function addEventPinnedVideo(user: AppUser, eventId: string, data: any) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/video`, data, config)
    .then(response => {
      return response.data;
    });
}

export function updateEventVideo(user: AppUser, eventId: string, videoId: string, data) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .put(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/videos/${videoId}`, data, config)
    .then(response => {
      return response.data;
    });
}
