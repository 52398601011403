import * as React from 'react';
import * as propz from 'propz';
import { FunctionComponent } from 'react';
import { TournamentTag } from '../../models/tournamentTag';

interface Props {
  tournamentTag: TournamentTag;
}

const ROWS = [
  { title: 'Name', field: 'tagName' },
  { title: 'Description', field: 'tagDescription' },
  { title: 'Colour', field: 'tagColor' },
  { title: 'Public', field: 'isPublic' }
];

export const TournamentTagSummary: FunctionComponent<Props> = props => {
  const rows = ROWS.map((row, index) => {
    let contentCell: any;
    const field = row.field;

    const tournamentTag = props.tournamentTag;

    switch (row.field) {
      case 'tagColor':
        contentCell = <div className="eDataList_listItemColor" style={{ background: tournamentTag.tagColor }} />;
        break;

      case 'isPublic':
        contentCell = tournamentTag.isPublic ? 'Yes' : 'No';
        break;

      default:
        contentCell = tournamentTag[row.field];
    }

    return (
      <tr key={`tournament_tag_summary_${index}`}>
        <td>{row.title}</td>
        <td>{contentCell}</td>
      </tr>
    );
  });

  return (
    <table className={'table table-sm mPre mVerticalAlignMiddle'}>
      <tbody>{rows}</tbody>
    </table>
  );
};
TournamentTagSummary.displayName = 'TournamentTagSummary';
