import * as React from 'react';
import { Component } from 'react';
import * as propz from 'propz';
import * as BPromise from 'bluebird';
import { AppUser } from 'Src/views/App/App';
import { parse } from 'query-string';
import { getOrder, getSearchFilter, getSearchOrder, getServerFieldSectionWhere } from 'Src/helpers/table/table';
import {
  ColumnDefinition,
  getFilters2,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { History, Location } from 'history';
import { Grid2 } from 'Src/components/Grid/Grid2';
import { Loader } from 'Src/components/Loader/Loader';
import { FILTER_TYPE, FIRST_PAGE, DATE_INTERVAL } from 'Src/consts/table';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { getGender } from 'Src/helpers/accessor/accessor';
import {
  getSchoolTournamentTeamPlayers,
  getSchoolTournamentTeamPlayersCount,
  getAllSchoolTournamentTeamPlayers
} from 'Src/helpers/service/admin/tournamentTeamPlayers';
import { removeSchoolEventTeamPlayer } from 'Src/helpers/service/admin/tournamentTeams';
import {
  getSelectOptionForAge,
  getSelectOptionForSchoolOfTeam,
  getSelectOptionForGender
} from 'Src/helpers/table/select';
import { removeSchoolEventIndividualPlayer } from 'Src/helpers/service/admin/tournamentTeams';
import { updateSchoolEventIndividualsBatch } from 'Src/helpers/service/admin/schoolEventIndividuals';
import { updateSchoolEventTeamPlayersBatch } from 'Src/helpers/service/admin/event';
import { addSchoolEventTeam } from 'Src/helpers/service/admin/tournamentTeams';
import { getTeamPlayerAgesGroup } from 'Src/helpers/accessor/accessor';
import {
  getTeamPlayerEventsIcon,
  getTeamPlayerEventsActionIcon,
  getRemovePlayerFromTeamIcon
} from 'Src/helpers/cell/cell';
import { CELL_ACTION_ICON } from 'Src/consts/cell';
import { Checkbox } from 'Src/components/Checkbox/Checkbox';
import { getFullName } from 'Src/helpers/autocomplete/autocomplete';
import { getSchoolEvent } from 'Src/helpers/service/admin/event';
import { updateSchoolTournamentTeamPlayersBatch } from 'Src/helpers/service/admin/tournamentTeamPlayers';
import { AddPlayerToEventForm } from 'Src/views/GenericView/AdminSUView/HighLevelTournaments/HighLevelTournamentTeamPlayers/AddPlayerToEventForm';
import { IndividualTournamentTeamPlayer, TournamentTeamPlayerEvent } from 'Src/models/tournamentTeamPlayer';
import { SchoolEvent } from 'Src/models/event';
import { getAllForms } from 'Src/helpers/service/admin/forms';
import { SchoolForm } from 'Src/models/form';

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

interface State {
  items: IndividualTournamentTeamPlayer[];
  currentPage: number;
  selectedItems: IndividualTournamentTeamPlayer[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  filters: any;
  isDataFiltered: boolean;
  isLoading: boolean;
  total: number;
  basePath: string;
  isSelectAllChecked: boolean;
  modalText: string;
  isEditTeamModalOpen: boolean;
  isRemoveFromEventConfirmationModalOpen: boolean;
  isRemoveFromTeamConfirmationModalOpen: boolean;
  isRemoveFromTeamProhibitionModalOpen: boolean;
  isAddPlayerToEventModalOpen: boolean;
  forms: SchoolForm[];

  eventIdToRemove: string;
  currentPlayer: IndividualTournamentTeamPlayer;
  isAskToRemovePlayerFromEvent: boolean;
}

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Name',
    field: 'name',
    accessor: ['firstName'],
    type: FILTER_TYPE.TEXT,
    isSort: false
  },
  {
    text: 'Surname',
    field: 'lastName',
    accessor: ['lastName'],
    type: FILTER_TYPE.NONE,
    isSort: false
  },
  {
    text: 'School',
    field: 'schoolId',
    accessor: ['schoolName'],
    type: FILTER_TYPE.MULTISELECT,
    isSort: false
  },
  {
    text: 'Age group',
    field: 'ages',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getTeamPlayerAgesGroup
  },
  {
    text: 'Gender',
    field: 'gender',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getGender
  },
  {
    text: 'Event actions',
    field: 'eventsActions',
    isSort: false,
    type: FILTER_TYPE.NONE,
    cell: getTeamPlayerEventsActionIcon,
    align: 'center'
  },
  {
    text: '',
    field: 'events',
    isSort: false,
    type: FILTER_TYPE.NONE,
    cell: getTeamPlayerEventsIcon
  },
  {
    text: 'Remove',
    field: 'remove',
    isSort: false,
    type: FILTER_TYPE.NONE,
    cell: getRemovePlayerFromTeamIcon,
    align: 'right'
  }
];

export class TournamentTeamPlayers extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      filters: {},
      isDataFiltered: false,
      isLoading: false,
      total: 0,
      basePath: '',
      isSelectAllChecked: false,
      modalText: '',
      isEditTeamModalOpen: false,
      isRemoveFromEventConfirmationModalOpen: false,
      isRemoveFromTeamConfirmationModalOpen: false,
      isRemoveFromTeamProhibitionModalOpen: false,
      isAddPlayerToEventModalOpen: false,
      forms: [],
      eventIdToRemove: '',
      currentPlayer: undefined,
      isAskToRemovePlayerFromEvent: false
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setAdditionalItems().then(res => {
      this.setItems();
    });
  }

  setAdditionalItems() {
    const { user } = this.props;

    return getAllForms(user).then(forms => {
      this.setState({
        forms
      });
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  setItems() {
    const { history, user } = this.props;
    const { forms } = this.state;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const options = {
      forms
    };

    const where = getServerFieldSectionWhere(filters, options);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const tournamentId = this.getTournamentId();
    const teamId = this.getTeamId();

    this.setState({
      isLoading: true
    });

    const getItemsPromise = getSchoolTournamentTeamPlayers(user, tournamentId, teamId, serverQueryFilter);
    const getItemsCountPromise = getSchoolTournamentTeamPlayersCount(user, tournamentId, teamId, where);

    return BPromise.all([getItemsPromise, getItemsCountPromise]).then(([items, countObj]) => {
      this.setState({
        currentPage: page,
        sortDirection: sortDirection,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: items,
        total: countObj.count,
        basePath: history.location.pathname,
        isLoading: false
      });

      return true;
    });
  }

  goBack = () => {
    const { history, location } = this.props;
    const { state } = location;
    const search = propz.get(state, ['search'], '');
    const prevRoutePath = propz.get(state, ['prevRoutePath'], '');
    const tournamentId = this.getTournamentId();

    history.push({
      pathname: '/tournaments/teams',
      search: `tournamentId=${tournamentId}`,
      state: { search, prevRoutePath }
    });
  };

  getTournamentId(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    const tournamentId = search.tournamentId;
    return tournamentId;
  }

  getTeamId(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    const teamId = search.teamId;
    return teamId;
  }

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    this.setState(prevState => {
      return {
        isShowFilter: !prevState.isShowFilter
      };
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { location } = this.props;
    const { state } = location;
    const locationSearch = propz.get(state, ['search'], '');
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    const teamName = propz.get(location, ['state', 'teamName'], '');

    const { history } = this.props;
    const { basePath } = this.state;
    const teamId = this.getTeamId();
    const tournamentId = this.getTournamentId();

    history.push({
      pathname: basePath,
      search: `teamId=${teamId}&tournamentId=${tournamentId}&${search.join('&')}`,
      state: { search: locationSearch, teamName }
    });
  };

  onClearFilterClick = (): void => {
    const { location } = this.props;
    const { state } = location;
    const locationSearch = propz.get(state, ['search'], '');

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    const { history } = this.props;
    const { basePath } = this.state;
    const teamId = this.getTeamId();
    const tournamentId = this.getTournamentId();

    const teamName = propz.get(location, ['state', 'teamName'], '');

    history.push({
      pathname: basePath,
      search: `teamId=${teamId}&tournamentId=${tournamentId}`,
      state: { search: locationSearch, teamName }
    });
  };

  onCellIconClick = (
    data: { currentPlayer: IndividualTournamentTeamPlayer; currentEvent?: TournamentTeamPlayerEvent },
    cell: string
  ) => {
    const { isAskToRemovePlayerFromEvent } = this.state;
    const { currentPlayer, currentEvent } = data;
    const playerFullName = getFullName(currentPlayer);

    switch (true) {
      case cell === CELL_ACTION_ICON.ADD_TO_EVENT:
        this.setState({
          currentPlayer
        });

        this.openAddPlayerToEventModal();
        break;

      case cell === CELL_ACTION_ICON.REMOVE_FROM_EVENT:
        const { id } = currentEvent;
        const eventName = propz.get(currentEvent, ['generatedNames', 'official'], '');

        this.setState(
          {
            modalText: `${playerFullName} will be removed from ${eventName}. Are you sure?`,
            eventIdToRemove: id,
            currentPlayer
          },
          () => {
            isAskToRemovePlayerFromEvent ? this.removePlayerFromEvent() : this.openRemoveFromEventConfirmationModal();
          }
        );
        break;

      case cell === CELL_ACTION_ICON.REMOVE_FROM_TEAM:
        const { events } = currentPlayer;
        const IsEventsExist = events.length > 0;

        this.setState(
          {
            modalText: IsEventsExist ? '' : `${playerFullName} will be removed from the team. Are you sure?`,
            currentPlayer
          },
          () => {
            IsEventsExist ? this.openRemoveFromTeamProhibitionModal() : this.openRemoveFromTeamConfirmationModal();
          }
        );

        break;
    }
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;
    const { history } = this.props;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const tournamentId = this.getTournamentId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `tournamentId=${tournamentId}&${search.join('&')}`,
      state: history.location.state
    });
  };

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;
    const { history } = this.props;

    if (currentPage !== FIRST_PAGE) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const tournamentId = this.getTournamentId();
    const teamId = this.getTeamId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `teamId=${teamId}&tournamentId=${tournamentId}&${search.join('&')}`,
      state: history.location.state
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters, forms } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const options = {
      forms
    };

    const where = getServerFieldSectionWhere(filters, options);

    const tournamentId = this.getTournamentId();
    const teamId = this.getTeamId();

    getAllSchoolTournamentTeamPlayers(user, tournamentId, teamId, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const nextSelectedItems = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: nextSelectedItems,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: nextSelectedItems,
      isSelectAllChecked: true
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  removePlayerFromEvent = (): void => {
    const { user } = this.props;
    const { eventIdToRemove, currentPlayer } = this.state;
    const removePlayerId = currentPlayer.userId;

    this.setState({
      isLoading: true
    });

    getSchoolEvent(user, eventIdToRemove)
      .then(schoolEvent => {
        const isRelay = propz.get(schoolEvent, ['sport', 'isRelay'], false);

        const { players } = schoolEvent;
        const currentPlayer = players.find(player => player.userId === removePlayerId);
        const { teamId, _id: playerId } = currentPlayer;

        return isRelay
          ? removeSchoolEventTeamPlayer(user, eventIdToRemove, teamId, playerId)
          : removeSchoolEventIndividualPlayer(user, eventIdToRemove, playerId);
      })
      .then(() => {
        return this.setItems();
      })
      .then(() => {
        const { items, selectedItems } = this.state;

        const updatedSelectedItems = items.filter(item => {
          return selectedItems.some(selectedItem => selectedItem.id === item.id);
        });

        this.setState({
          selectedItems: [],
          isSelectAllChecked: false,
          isRemoveFromEventConfirmationModalOpen: false,
          modalText: '',
          isLoading: false,
          currentPlayer: undefined
        });
      });
  };

  addPlayerToEvent = (students: IndividualTournamentTeamPlayer[], events: SchoolEvent[]): void => {
    this.setState({
      isLoading: true
    });

    const { user } = this.props;
    const promises = [];

    for (let j = 0; j < events.length; j++) {
      const event = events[j];
      const { id: eventId, ages, gender } = event;
      const studentData = students.map(student => {
        const { permissionId, userId } = student;
        return {
          permissionId,
          userId
        };
      });

      const isRelay = propz.get(event, ['sport', 'isRelay'], false);

      if (isRelay) {
        const teamsData = propz.get(event, ['teamsData'], []);
        const schoolTeam = teamsData.find(team => team.schoolId === user.activeSchoolId);
        const isSchoolTeamExist = typeof schoolTeam !== 'undefined';

        const teamToAdd = {
          ages,
          gender,
          players: studentData
        };

        isSchoolTeamExist
          ? promises.push(updateSchoolEventTeamPlayersBatch(user, eventId, schoolTeam.id, { add: studentData }))
          : promises.push(addSchoolEventTeam(user, eventId, teamToAdd));
      } else {
        promises.push(updateSchoolEventIndividualsBatch(user, eventId, { add: studentData }));
      }
    }

    BPromise.all(promises)
      .then(() => {
        return this.setItems();
      })
      .then(() => {
        const { items, selectedItems } = this.state;

        const updatedSelectedItems = items.filter(item => {
          return selectedItems.some(selectedItem => selectedItem.id === item.id);
        });

        this.setState({
          selectedItems: [],
          isSelectAllChecked: false,
          isLoading: false,
          isAddPlayerToEventModalOpen: false,
          currentPlayer: undefined
        });
      });
  };

  removePlayerFromTeam = (): void => {
    const { user } = this.props;
    const { currentPlayer, selectedItems } = this.state;
    const isCurrentPlayerExist = typeof currentPlayer !== 'undefined';
    const playerIdsForRemove = isCurrentPlayerExist ? [currentPlayer.id] : selectedItems.map(item => item.id);

    const tournamentId = this.getTournamentId();
    const teamId = this.getTeamId();
    const removeTeamData = {
      remove: playerIdsForRemove
    };

    this.setState({
      isLoading: true
    });

    updateSchoolTournamentTeamPlayersBatch(user, tournamentId, teamId, removeTeamData).then(() => {
      this.setState({
        isRemoveFromTeamConfirmationModalOpen: false,
        modalText: '',
        isLoading: false,
        currentPlayer: undefined
      });

      this.setItems();
    });
  };

  renderRemoveFromEventConfirmationModal() {
    const { isRemoveFromEventConfirmationModalOpen, isAskToRemovePlayerFromEvent, modalText } = this.state;

    return (
      <SimpleModal
        isOpen={isRemoveFromEventConfirmationModalOpen}
        title="Confirm the action"
        body={modalText}
        buttonText="Confirm"
        buttonCancelText="Cancel"
        onButtonClick={() => this.removePlayerFromEvent()}
        onCloseClick={this.closeRemoveFromEventConfirmationModal}
      >
        <Checkbox
          customClass="mt-2"
          id="12"
          value={isAskToRemovePlayerFromEvent}
          labelText="Don't ask anymore"
          onChange={() =>
            this.setState(prevState => {
              return {
                isAskToRemovePlayerFromEvent: !prevState.isAskToRemovePlayerFromEvent
              };
            })
          }
        />
      </SimpleModal>
    );
  }

  openRemoveFromEventConfirmationModal = (): void => {
    this.setState({
      isRemoveFromEventConfirmationModalOpen: true
    });
  };

  closeRemoveFromEventConfirmationModal = (): void => {
    this.setState({
      isAskToRemovePlayerFromEvent: false,
      isRemoveFromEventConfirmationModalOpen: false,
      currentPlayer: undefined
    });
  };

  renderRemoveFromTeamConfirmationModal() {
    const { isRemoveFromTeamConfirmationModalOpen, modalText, currentPlayer, selectedItems } = this.state;

    const isCurrentPlayerExist = typeof currentPlayer !== 'undefined';
    const selectedPlayersNames = selectedItems.map(item => getFullName(item)).join(', ');
    const modalBodyText = isCurrentPlayerExist
      ? modalText
      : `${selectedPlayersNames} will be removed from the team. Are you sure?`;

    return (
      <SimpleModal
        isOpen={isRemoveFromTeamConfirmationModalOpen}
        title="Confirm the action"
        body={modalBodyText}
        buttonText="Confirm"
        buttonCancelText="Cancel"
        onButtonClick={this.removePlayerFromTeam}
        onCloseClick={this.closeRemoveFromTeamConfirmationModal}
      />
    );
  }

  openRemoveFromTeamConfirmationModal = (): void => {
    this.setState({
      isRemoveFromTeamConfirmationModalOpen: true
    });
  };

  closeRemoveFromTeamConfirmationModal = (): void => {
    this.setState({
      isAskToRemovePlayerFromEvent: false,
      isRemoveFromTeamConfirmationModalOpen: false,
      currentPlayer: undefined
    });
  };

  renderPlayersWithEvents = players => {
    return (
      <ul className="mt-2">
        {players
          .filter(item => {
            const { events } = item;

            return events.length > 0;
          })
          .map(player => {
            const { id } = player;
            const playerFullName = getFullName(player);

            return <li key={id}>{playerFullName}</li>;
          })}
      </ul>
    );
  };

  renderRemoveFromTeamProhibitionModal() {
    const { isRemoveFromTeamProhibitionModalOpen, selectedItems, currentPlayer } = this.state;

    const isCurrentPlayerExist = typeof currentPlayer !== 'undefined';
    const selectedPlayers = isCurrentPlayerExist ? [currentPlayer] : selectedItems;

    const modalText =
      'You cannot remove the following students as they have been added to some events within the tournament. Please remove them from the events first.';

    return (
      <SimpleModal
        isOpen={isRemoveFromTeamProhibitionModalOpen}
        title="The action is prohibited"
        body={modalText}
        buttonCancelText="Cancel"
        onCloseClick={this.closeRemoveFromTeamProhibitionModal}
      >
        {this.renderPlayersWithEvents(selectedPlayers)}
      </SimpleModal>
    );
  }

  openRemoveFromTeamProhibitionModal = (): void => {
    this.setState({
      isRemoveFromTeamProhibitionModalOpen: true
    });
  };

  closeRemoveFromTeamProhibitionModal = (): void => {
    this.setState({
      isRemoveFromTeamProhibitionModalOpen: false,
      currentPlayer: undefined
    });
  };

  renderAddPlayerToEventModal() {
    const { user } = this.props;
    const { isAddPlayerToEventModalOpen, selectedItems, currentPlayer } = this.state;

    const isCurrentPlayerExist = typeof currentPlayer !== 'undefined';
    const students = isCurrentPlayerExist ? [currentPlayer] : selectedItems;
    const tournamentId = this.getTournamentId();

    return (
      <SimpleModal
        isOpen={isAddPlayerToEventModalOpen}
        title="Adding students to the events"
        onCloseIconClick={this.closeAddPlayerToEventModal}
      >
        <AddPlayerToEventForm
          students={students}
          user={user}
          tournamentId={tournamentId}
          addPlayerToEvent={this.addPlayerToEvent}
          onCloseClick={this.closeAddPlayerToEventModal}
        />
      </SimpleModal>
    );
  }

  openAddPlayerToEventModal = (): void => {
    this.setState({
      isAddPlayerToEventModalOpen: true
    });
  };

  closeAddPlayerToEventModal = (): void => {
    this.setState({
      isAddPlayerToEventModalOpen: false,
      currentPlayer: undefined
    });
  };

  renderGrid() {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      forms,
      isSelectAllChecked,
      currentPage,
      total
    } = this.state;
    const { user, history } = this.props;
    const { location } = history;
    const gridTitle = propz.get(location, ['state', 'teamName'], '');

    const ageGroupName = propz.get(user, ['activeSchool', 'ageGroupsNaming'], '');

    const selectedPlayersWithEvents = selectedItems.filter(item => {
      const { events } = item;
      return events.length > 0;
    });

    const isSelectedItemsEventsExist = selectedPlayersWithEvents.length > 0;

    const actionItems = [
      {
        itemText: 'Add to an event',
        onItemClick: this.openAddPlayerToEventModal,
        isActive: selectedItems.length > 0
      },
      {
        itemText: 'Remove from the team',
        onItemClick: isSelectedItemsEventsExist
          ? this.openRemoveFromTeamProhibitionModal
          : this.openRemoveFromTeamConfirmationModal,
        isActive: selectedItems.length > 0
      }
    ];

    const filterOptions = {
      ages: getSelectOptionForAge(forms, ageGroupName),
      schoolId: getSelectOptionForSchoolOfTeam(items),
      gender: getSelectOptionForGender()
    };

    return (
      <Grid2
        dataItems={items}
        filters={filters}
        currentPage={currentPage}
        total={total}
        isSelectAllChecked={isSelectAllChecked}
        isDataFiltered={isDataFiltered}
        sortDirection={sortDirection}
        sortColumnsName={sortColumnsName}
        isShowFilter={isShowFilter}
        dataItemsSelected={selectedItems}
        columns={COLUMNS}
        actionItems={actionItems}
        options={filterOptions}
        onItemClick={this.onItemClick}
        onSortClick={this.onTableSortClick}
        onApplyFilterClick={this.onApplyFilterClick}
        onClearFilterClick={this.onClearFilterClick}
        onTableFilterChange={this.onTableFilterChange}
        onTableFilterClick={this.onTableFilterClick}
        onCellIconClick={this.onCellIconClick}
        setCurrentPageParams={this.setCurrentPageParams}
        onSelectAllClick={this.onSelectAllClick}
        onSelectAllOnPageClick={this.onSelectAllOnPageClick}
        onUnselectAllClick={this.onUnselectAllClick}
        gridTitle={gridTitle}
        goBack={this.goBack}
        user={user}
      />
    );
  }

  render() {
    const { isLoading } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    return (
      <div className="row">
        {this.renderAddPlayerToEventModal()}
        {this.renderRemoveFromEventConfirmationModal()}
        {this.renderRemoveFromTeamConfirmationModal()}
        {this.renderRemoveFromTeamProhibitionModal()}
        <div className="col-md-12 mt-2">{this.renderGrid()}</div>
      </div>
    );
  }
}
