import * as React from 'react';
import { Component } from 'react';
import * as propz from 'propz';
import MaskedInput from 'react-text-mask';
import { Loader } from 'Src/components/Loader/Loader';
import { AppUser } from 'Src/views/App/App';
import { History, Location } from 'history';
import { Button } from 'Src/components/Button/Button';
import { updateSchoolSettings } from 'Src/helpers/service/admin/settings';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { uploadImage } from 'Src/helpers/service/image';
import {
  TWITTER_STATUS_OPTIONS,
  getSelectOptionsForAgesGroupNaming,
  getSelectOptionsForPublicSiteAccess,
  getSelectOptionsForStudentNameView
} from 'Src/helpers/select/select';
import { getPostcode, searchFunctionSchoolPostcodes } from 'Src/helpers/autocomplete/autocomplete';
import { Autocomplete } from 'Src/components/Autocomplete/Autocomplete';
import { Map } from 'Src/components/Map/Map';
import { PhoneCodesList } from 'Src/views/GenericView/Profile/ProfileSummary/PhoneCodesList';
import { PHONE_CODE_WITH_FLAG } from 'Src/consts/common';
import { TriangleDown } from 'Src/components/SvgIcons/TriangleDown';
import { getPhoneAndCode } from 'Src/helpers/phone/phone';
import { SelectField } from 'Src/components/SelectField/SelectField';
import { SCHOOL_STATUS, DEFAULT_PUBLIC_BIGSCREEN_SITE, DEFAULT_PUBLIC_SITE } from 'Src/consts/school';
import { checkDomainAvailabilty } from 'Src/helpers/service/public/public';
import { PHONE_CODE } from 'Src/consts/common';
import { HH_MM } from 'Src/consts/date';
import { Switch } from 'Src/components/Switch/Switch';
import './EditSchoolSettings.scss';

interface Props {
  user: AppUser;
  history: History;
  location: Location;
  onSchoolUpdate: () => any;
}

interface State {
  isLoading: boolean;
  isPhoneCodeOptionsVisible: boolean;
  isLoadingPhoto: boolean;
  imagePreviewUrl: string;
}

export class EditSchoolSettings extends Component<Props, State> {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      isPhoneCodeOptionsVisible: false,
      isLoading: false,
      isLoadingPhoto: false,
      imagePreviewUrl: ''
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  goBack = () => {
    const { history } = this.props;

    history.push({
      pathname: `/school/settings/base`
    });
  };

  updateSchool = () => {
    const { onSchoolUpdate } = this.props;

    this.setState({
      isLoading: true
    });

    onSchoolUpdate().then(() => {
      if (this._isMounted) {
        this.setState({
          isLoading: false
        });
      }
    });
  };

  onChangeLogoClick = (event, setFieldValue) => {
    const image = event.target.files[0];
    const { user } = this.props;

    this.setState({
      isLoadingPhoto: true,
      imagePreviewUrl: URL.createObjectURL(image)
    });

    uploadImage(user, image).then(response => {
      if (this._isMounted) {
        const picUrl = `${window.apiImg}/images/${response.key}`;
        setFieldValue('pic', picUrl);

        this.setState({
          isLoadingPhoto: false
        });
      }
    });
  };

  onPhoneCodeClick = () => {
    const { isPhoneCodeOptionsVisible } = this.state;
    this.setState({
      isPhoneCodeOptionsVisible: !isPhoneCodeOptionsVisible
    });
  };

  getPhoneCodeWithFlag = (phoneCode: string) => {
    for (const key in PHONE_CODE_WITH_FLAG) {
      if (PHONE_CODE_WITH_FLAG.hasOwnProperty(key)) {
        const country = PHONE_CODE_WITH_FLAG[key];
        const { code, flag, alt } = country;

        if (code === phoneCode) {
          return (
            <>
              <img src={`/dist/images/flags/${flag}`} alt={alt} className="eProfileSummaryPhoneSelectFlag" />
              <span className="eProfileSummaryPhoneSelectCode">{code}</span>
              <TriangleDown />
            </>
          );
        }
      }
    }
  };

  getSchoolSchema = () => {
    const { user } = this.props;

    const domainErrorText = 'This domain name is already taken. Please select another domain name for your school.';

    const schema = Yup.object().shape({
      name: Yup.string().required('Required'),
      email: Yup.string().email('Must be valid email'),
      sportsDepartmentEmail: Yup.string().email('Must be valid email'),
      postcode: Yup.string().required('Required'),
      domain: Yup.string()
        .required('Required')
        .test('domain', domainErrorText, value => {
          if (!value) {
            return false;
          } else {
            const nextDomain = value;
            const prevDomain = propz.get(user, ['activeSchool', 'domain'], '');
            const isDomainChanged = prevDomain !== nextDomain;

            return isDomainChanged
              ? checkDomainAvailabilty(user, value).then(response => {
                  const { isAvailable } = response;

                  return isAvailable;
                })
              : true;
          }
        }),
      isEventUpdateNotificationCustomMessageEnabled: Yup.boolean().required('Required')
    });

    return schema;
  };

  onSubmit = (values, initialValues) => {
    const { user, history } = this.props;

    const data = {
      ...values,
      ownerId: values.ownerId,
      name: values.name,
      postcode: {
        point: values.postcode.point
      },
      postcodeId: values.postcodeId,
      publicSite: values.publicSite,
      publicBigscreenSite: values.publicBigscreenSite,
      publicStudentViewType: values.publicStudentViewType,
      isClubPriorityEnabled: values.isClubPriorityEnabled,
      ageGroupsNaming: values.ageGroupsNaming,
      isEventUpdateNotificationCustomMessageEnabled: values.isEventUpdateNotificationCustomMessageEnabled
    };

    console.log('Data to be sent to the backend:', JSON.stringify(data, null, 2));

    const { email, sportsDepartmentEmail } = data;

    if (email === '') {
      propz.set(data, ['email'], null);
    }

    if (sportsDepartmentEmail === '') {
      propz.set(data, ['sportsDepartmentEmail'], null);
    }

    const { code, phone, ...rest } = data;
    const phoneValue = typeof phone !== 'undefined' ? `${code}${phone}` : `${code}`;

    const dataWithPhone = {
      ...rest,
      phone: phoneValue
    };

    this.setState({
      isLoading: true
    });

    updateSchoolSettings(user, dataWithPhone)
      .then(() => {
        this.updateSchool();
      })
      .then(() => {
        history.push({
          pathname: '/school/settings/base'
        });
      });
  };

  render() {
    const { isPhoneCodeOptionsVisible, isLoadingPhoto, isLoading, imagePreviewUrl } = this.state;
    const logoUrl = imagePreviewUrl || this.props.user.activeSchool.pic || '/dist/images/empty_pic_uploader_box.png';
    const { user } = this.props;

    const { userId } = user;
    const point = propz.get(user, ['activeSchool', 'postcode', 'point']);
    const postcode = propz.get(user, ['activeSchool', 'postcode']);
    const postcodeWithoutPoint = {
      id: postcode.id,
      point: postcode.point,
      postcode: postcode.postcode,
      postcodeNoSpaces: postcode.postcodeNoSpaces,
      region: postcode.region
    };
    const phone = propz.get(user, ['activeSchool', 'phone'], '');
    const phoneObj = getPhoneAndCode(phone);
    const phoneCode = propz.get(phoneObj, ['code'], PHONE_CODE.UK);
    const phoneBody = propz.get(phoneObj, ['phone'], '');

    const logo = propz.get(user, ['activeSchool', 'pic'], '');

    const initialSchool = {
      pic: logo,
      ownerId: userId,
      name: propz.get(user, ['activeSchool', 'name'], ''),
      description: propz.get(user, ['activeSchool', 'description'], ''),
      postcode: postcodeWithoutPoint,
      code: phoneCode,
      phone: phoneBody,
      postcodeId: propz.get(user, ['activeSchool', 'postcode', 'id'], ''),
      email: propz.get(user, ['activeSchool', 'email'], ''),
      sportsDepartmentEmail: propz.get(user, ['activeSchool', 'sportsDepartmentEmail'], ''),
      address: propz.get(user, ['activeSchool', 'address'], ''),
      domain: propz.get(user, ['activeSchool', 'domain'], ''),
      publicSite: propz.get(user, ['activeSchool', 'publicSite'], DEFAULT_PUBLIC_SITE),
      publicBigscreenSite: propz.get(user, ['activeSchool', 'publicBigscreenSite'], DEFAULT_PUBLIC_BIGSCREEN_SITE),
      publicStudentViewType: propz.get(user, ['activeSchool', 'publicStudentViewType'], 'SHORTEN_LASTNAME_ONLY'),
      ageGroupsNaming: propz.get(user, ['activeSchool', 'ageGroupsNaming'], ''),
      isEventUpdateNotificationCustomMessageEnabled: propz.get(
        user,
        ['activeSchool', 'isEventUpdateNotificationCustomMessageEnabled'],
        false
      ),
      defaultFixtureStartTime: propz.get(user, ['activeSchool', 'defaultFixtureStartTime'], '10:00'),
      defaultClubStartTime: propz.get(user, ['activeSchool', 'defaultClubStartTime'], '10:00')
    };

    if (isLoading) {
      return <Loader />;
    }

    return (
      <div className="mt-3">
        <Button text="← back" onClick={this.goBack} customClass="btn-secondary mb-3" />
        <h2>Edit school settings</h2>

        <div className="mt-5">
          <Formik
            initialValues={initialSchool}
            validationSchema={this.getSchoolSchema()}
            onSubmit={values => this.onSubmit(values, initialSchool)}
            render={({ touched, errors, setFieldValue, values }) => {
              console.log('Formik values:', values); // Debugging log
              return (
                <Form>
                  <div className="row justify-content-center mx-0">
                    <div className="col-12 col-sm-4 col-lg-3 pl-0 pr-sm-3 pr-0">
                      <Field
                        name="pic"
                        render={({ field }) => {
                          return (
                            <div className="bEditSchoolLogoFrame">
                              {isLoadingPhoto ? (
                                <div className="bEditSchoolLogoLoader">
                                  <Loader />
                                </div>
                              ) : (
                                <div className="bEditSchoolLogo" style={{ backgroundImage: `url(${logoUrl})` }}>
                                  <input
                                    type="file"
                                    name="pic"
                                    id="schoolLogo"
                                    onChange={event => this.onChangeLogoClick(event, setFieldValue)}
                                  />
                                  <label htmlFor="schoolLogo">+</label>
                                </div>
                              )}
                            </div>
                          );
                        }}
                      />
                    </div>

                    <div className="d-flex flex-column justify-content-between col-12 col-sm-8 col-lg-5 mt-3 mt-sm-0 pr-0 pl-0 pl-sm-3">
                      <div>
                        <label>Name</label>
                        <Field type="text" name="name" className="form-control" disabled />
                      </div>

                      <div className="mt-3">
                        <label>School Official Email</label>
                        <Field type="text" name="email" className="form-control" />
                        {touched.email && errors.email && <div className="alert alert-danger mt-2">{errors.email}</div>}
                      </div>

                      <div className="mt-3">
                        <label>Sports Department Email</label>
                        <Field type="text" name="sportsDepartmentEmail" className="form-control" />
                        {touched.sportsDepartmentEmail && errors.sportsDepartmentEmail && (
                          <div className="alert alert-danger mt-2">{errors.sportsDepartmentEmail}</div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-center mt-3 mt-sm-5 mx-0">
                    <div className="col-12 col-lg-8 px-0">
                      <div className="bSchoolSettingsDescription">
                        <label>Description</label>
                        <Field component="textarea" name="description" className="form-control mb-3" />
                      </div>

                      <div className="d-flex flex-column">
                        <label>Phone</label>
                        <Field
                          name="phone"
                          render={({ field }) => {
                            return (
                              <div className="eProfileSummaryPhoneWrapper">
                                <div className="eProfileSummaryPhone">
                                  <div className="eProfileSummaryPhoneSelect" onClick={this.onPhoneCodeClick}>
                                    {this.getPhoneCodeWithFlag(values.code)}
                                  </div>
                                  <Field type="text" name="phone" className="form-control" />
                                </div>
                                {isPhoneCodeOptionsVisible && (
                                  <PhoneCodesList
                                    onClick={phoneCode => {
                                      setFieldValue('code', phoneCode);

                                      this.setState({
                                        isPhoneCodeOptionsVisible: false
                                      });
                                    }}
                                  />
                                )}
                              </div>
                            );
                          }}
                        />
                      </div>

                      <div className="d-flex flex-column mt-3">
                        <label>Postcode</label>
                        <Field
                          name="postcode.postcode"
                          render={({ field }) => {
                            return (
                              <Autocomplete
                                searchFunction={text => {
                                  const { user } = this.props;
                                  const textWithoutSpaces = text.split(' ').join('');

                                  setFieldValue('postcode.postcode', text);
                                  setFieldValue('postcode.postcodeNoSpaces', textWithoutSpaces);

                                  return searchFunctionSchoolPostcodes(user, text);
                                }}
                                getElementTitle={getPostcode}
                                customClass="mFullWidth"
                                defaultItem={values.postcode}
                                placeholder="Start typing the postcode name"
                                onSelect={postcode => {
                                  const result = {
                                    id: postcode.id,
                                    point: postcode.point,
                                    postcode: postcode.postcode,
                                    postcodeNoSpaces: postcode.postcodeNoSpaces,
                                    region: postcode.region
                                  };

                                  setFieldValue('postcode', result);
                                  setFieldValue('postcodeId', postcode.id);
                                }}
                              />
                            );
                          }}
                        />
                        {touched.postcode && errors.postcode && (
                          <div className="alert alert-danger mt-2">{errors.postcode.postcode}</div>
                        )}
                        <Map point={point} customStylingClass="eSchoolMap" isMarkerDraggable={true} />
                      </div>

                      <div className="d-flex flex-column mt-3">
                        <label>Address</label>
                        <Field type="text" name="address" className="form-control" />
                      </div>

                      <div className="d-flex flex-column mt-3">
                        <label>Domain</label>
                        <Field type="text" name="domain" className="form-control" />
                        {touched.domain && errors.domain && (
                          <div className="alert alert-danger mt-2">{errors.domain}</div>
                        )}
                      </div>

                      <div className="d-flex flex-column mt-3">
                        <label>Public Site Access</label>
                        <Field
                          name="publicSite.status"
                          render={({ field }) => {
                            return (
                              <SelectField
                                customClass="mMaxWidthNone"
                                items={getSelectOptionsForPublicSiteAccess()}
                                field={field}
                                onChange={event => {
                                  const publicSite = event.target.value;
                                  setFieldValue('publicSite.status', publicSite);
                                }}
                                value={values.publicSite.status}
                              />
                            );
                          }}
                        />
                      </div>

                      {values.publicSite.status === 'PROTECTED' && (
                        <div className="d-flex flex-column mt-3">
                          <label>Public Site Access Password</label>
                          <Field type="text" name="publicSite.password" className="form-control" />
                        </div>
                      )}

                      <div className="d-flex flex-column mt-3">
                        <label>Public Site Twitter</label>
                        <Field
                          name="publicSite.isTwitterVisible"
                          render={({ field }) => {
                            return (
                              <SelectField
                                customClass="mMaxWidthNone"
                                items={TWITTER_STATUS_OPTIONS}
                                field={field}
                                onChange={(event, value) => {
                                  const isTwitterVisible = event.target.value;
                                  isTwitterVisible === 'true'
                                    ? setFieldValue('publicSite.isTwitterVisible', true)
                                    : setFieldValue('publicSite.isTwitterVisible', false);
                                }}
                                value={values.publicSite.isTwitterVisible}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="d-flex flex-column mt-3">
                        <label>Public Bigscreen Site Access</label>
                        <Field
                          name="publicBigscreenSite.status"
                          render={({ field }) => {
                            return (
                              <SelectField
                                customClass="mMaxWidthNone"
                                items={getSelectOptionsForPublicSiteAccess()}
                                field={field}
                                onChange={event => {
                                  const publicBigscreenSite = event.target.value;
                                  setFieldValue('publicBigscreenSite.status', publicBigscreenSite);
                                }}
                                value={values.publicBigscreenSite.status}
                              />
                            );
                          }}
                        />
                      </div>

                      {values.publicBigscreenSite.status === SCHOOL_STATUS.PROTECTED && (
                        <div className="d-flex flex-column mt-3">
                          <label>Public Bigscreen Site Access Password</label>
                          <Field type="text" name="publicBigscreenSite.password" className="form-control" />
                        </div>
                      )}

                      <div className="d-flex flex-column mt-3">
                        <label>Public student view type</label>
                        <Field
                          name="publicStudentViewType"
                          render={({ field }) => {
                            return (
                              <SelectField
                                customClass="mMaxWidthNone"
                                items={getSelectOptionsForStudentNameView()}
                                field={field}
                                onChange={event => {
                                  const publicStudentViewType = event.target.value;
                                  setFieldValue('publicStudentViewType', publicStudentViewType);
                                }}
                                value={values.publicStudentViewType}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="d-flex flex-column mt-3">
                        <label>Age groups naming type</label>
                        <Field
                          name="ageGroupsNaming"
                          render={({ field }) => {
                            return (
                              <SelectField
                                customClass="mMaxWidthNone"
                                items={getSelectOptionsForAgesGroupNaming()}
                                field={field}
                                onChange={event => {
                                  const ageGroupsNaming = event.target.value;
                                  setFieldValue('ageGroupsNaming', ageGroupsNaming);
                                }}
                                value={values.ageGroupsNaming}
                              />
                            );
                          }}
                        />
                      </div>
                      <div className="d-flex flex-column mt-3">
                        <label>
                          Enable event update notification message (custom reason to be included when
                          changing/cancelling events)
                        </label>
                        <Field
                          name="isEventUpdateNotificationCustomMessageEnabled"
                          render={({ field }) => (
                            <Switch
                              value={values.isEventUpdateNotificationCustomMessageEnabled}
                              onChange={() => {
                                setFieldValue(
                                  'isEventUpdateNotificationCustomMessageEnabled',
                                  !values.isEventUpdateNotificationCustomMessageEnabled
                                );
                              }}
                              text={''}
                              name="isEventUpdateNotificationCustomMessageEnabled"
                            />
                          )}
                        />
                      </div>
                      <div className="d-flex flex-column mt-3">
                        <label>Club default start time</label>
                        <Field
                          name="defaultClubStartTime"
                          render={({ field }) => {
                            return (
                              <MaskedInput
                                type="text"
                                className="form-control"
                                onChange={event => {
                                  const defaultClubStartTime = event.target.value;
                                  setFieldValue('defaultClubStartTime', defaultClubStartTime);
                                }}
                                mask={HH_MM}
                                value={values.defaultClubStartTime}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="d-flex flex-column mt-3">
                        <label>Fixture default start time</label>
                        <Field
                          name="defaultFixtureStartTime"
                          render={({ field }) => {
                            return (
                              <MaskedInput
                                type="text"
                                className="form-control"
                                onChange={event => {
                                  const defaultFixtureStartTime = event.target.value;
                                  setFieldValue('defaultFixtureStartTime', defaultFixtureStartTime);
                                }}
                                mask={HH_MM}
                                value={values.defaultFixtureStartTime}
                              />
                            );
                          }}
                        />
                      </div>

                      <button type="submit" className="mt-5 mb-3 btn btn-lg btn-primary">
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          />
        </div>
      </div>
    );
  }
}
