import * as React from 'react';
import * as propz from 'propz';
import { FunctionComponent } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Tournament, TournamentCustomGroup } from 'Src/models/tournament';
import { Button } from 'Src/components/Button/Button';
import { getSelectOptionsForTournamentPrize, getSelectOptionsForTournamentSubstage } from 'Src/helpers/select/select';
import { SelectField } from 'Src/components/SelectField/SelectField';

interface Props {
  onCancel: () => void;
  onSubmit: (data: any) => void;
  tournament: Tournament;
  group?: TournamentCustomGroup;
}

const TournamentGroupSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  stageId: Yup.string().required('Required'),
  substage: Yup.string(),
  prize: Yup.string()
});

export const TournamentGroupForm: FunctionComponent<Props> = props => {
  const { group, tournament } = props;
  const isGroupExist = typeof group !== 'undefined';
  const stages = tournament.tournamentLayout || [];

  const getStageOptions = () =>
    stages.map(stage => (
      <option key={stage.id} value={stage.id}>
        {stage.name}
      </option>
    ));

  const isGroupStage = stageId => {
    const { tournamentLayout } = tournament;
    const stage = tournamentLayout.find(stage => stage.id === stageId);
    const type = propz.get(stage, ['type']);

    const isGroup = type === 'GROUP';

    return isGroup;
  };

  const TournamentGroupFormInitial = {
    name: isGroupExist ? propz.get(group, ['name'], '') : '',
    stageId: isGroupExist ? propz.get(group, ['stageId'], '') : '',
    substage: isGroupExist ? propz.get(group, ['substage'], '') : '',
    prize: isGroupExist ? propz.get(group, ['prize'], '') : ''
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik
            initialValues={TournamentGroupFormInitial}
            validationSchema={TournamentGroupSchema}
            onSubmit={values => {
              const isGroup = isGroupStage(values.stageId);

              if (isGroup) {
                delete values.prize;
                delete values.substage;
              }

              props.onSubmit(values);
            }}
            render={({ touched, errors, values, setFieldValue }) => (
              <Form>
                <div className="form-group form-check">
                  <div className="form-group">
                    <label>Name</label>
                    <Field type="text" name="name" className="form-control" />
                  </div>
                  {touched.name && errors.name ? <div className="alert alert-danger">{errors.name}</div> : null}

                  <div className="form-group">
                    <label>Stage</label>
                    <Field component="select" name="stageId" className="form-control mb-3">
                      <option value={''} />
                      {getStageOptions()}
                    </Field>
                    {touched.stageId && errors.stageId ? (
                      <div className="alert alert-danger">{errors.stageId}</div>
                    ) : null}
                  </div>

                  {!isGroupStage(values.stageId) && (
                    <>
                      <div className="form-group">
                        <label>Prize</label>
                        <Field
                          name="prize"
                          render={({ field }) => {
                            return (
                              <SelectField
                                customClass="form-control mb-3"
                                items={getSelectOptionsForTournamentPrize()}
                                field={field}
                                onChange={event => {
                                  const value = event.target.value;
                                  setFieldValue('prize', value);
                                }}
                                value={values.prize}
                                isEmptyOptionRequired={true}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="form-group">
                        <label>Substage</label>
                        <Field
                          name="substage"
                          render={({ field }) => {
                            return (
                              <SelectField
                                customClass="form-control mb-3"
                                items={getSelectOptionsForTournamentSubstage()}
                                field={field}
                                onChange={event => {
                                  const value = event.target.value;
                                  setFieldValue('substage', value);
                                }}
                                value={values.substage}
                                isEmptyOptionRequired={true}
                              />
                            );
                          }}
                        />
                      </div>
                    </>
                  )}

                  <Button onClick={props.onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                  <button type="submit" className="mt-3 mb-3 btn btn-primary">
                    Save
                  </button>
                </div>
              </Form>
            )}
          />
        </div>
      </div>
    </div>
  );
};

TournamentGroupForm.displayName = 'TournamentGroupForm';
