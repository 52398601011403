import * as React from 'react';
import { Route, Switch } from 'react-router';
import { SchoolLeagues } from './SchoolLeagues';
import { SchoolLeagueEvents } from './SchoolLeagueEvents';
import { SchoolLeagueNews } from './SchoolLeagueNews';
import { SchoolLeagueEligibleEvents } from './SchoolLeagueEligibleEvents/SchoolLeagueEligibleEvents';

export const LeagueRouting = allProps => {
  return (
    <Switch>
      <Route exact path={'/leagues'} render={props => <SchoolLeagues {...props} user={allProps.user} />} />
      <Route exact path={'/leagues/events'} render={props => <SchoolLeagueEvents {...props} user={allProps.user} />} />
      <Route
        exact
        path={'/leagues/eligibleEvents'}
        render={props => <SchoolLeagueEligibleEvents {...props} user={allProps.user} />}
      />
      <Route exact path={'/leagues/news'} render={props => <SchoolLeagueNews {...props} user={allProps.user} />} />
    </Switch>
  );
};

LeagueRouting.displayName = 'LeagueRouting';
