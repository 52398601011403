export const GENERAL_MESSAGE_STATUS = {
  DRAFT: 'DRAFT',
  SENT: 'SENT',
  SCHEDULED: 'SCHEDULED'
};

export type GENERAL_MESSAGE_STATUS_TYPE = 'DRAFT' | 'SENT' | 'SCHEDULED';

export const GENERAL_MESSAGE_STATUS_SERVER_TO_CLIENT_MAPPING = {
  DRAFT: 'Draft',
  SENT: 'Sent',
  SCHEDULED: 'Scheduled'
};

export const GENERAL_MESSAGE_DELIVERY_STATUS = [
  'NONE',
  'NO_CHANNELS',
  'UNSUPPORTED_CHANNEL_TYPE',
  'SCHEDULED', // ready to go, waiting sending mechanism to be ready
  'SENDING', // sending in progress. There can be a lot of channels, so this action can take some time
  'SENT', // everything sent
  'DELIVERED', // delivered by at least one channel
  'READ', // read by user at least on one channel
  'ERROR', // something bad happened - cannot send
  'NOT_SENT'
];

export const GENERAL_MESSAGE_DELIVERY_STATUS_OBJECT = {
  NONE: 'NONE',
  NO_CHANNELS: 'NO_CHANNELS',
  UNSUPPORTED_CHANNEL_TYPE: 'UNSUPPORTED_CHANNEL_TYPE',
  SCHEDULED: 'SCHEDULED',
  SENDING: 'SENDING',
  SENT: 'SENT',
  DELIVERED: 'DELIVERED',
  READ: 'READ',
  ERROR: 'ERROR',
  NOT_SENT: 'NOT_SENT'
};

export const GENERAL_MESSAGE_DELIVERY_STATUS_SERVER_TO_CLIENT_MAPPING = {
  NONE: 'None',
  NO_CHANNELS: 'No channels',
  UNSUPPORTED_CHANNEL_TYPE: 'Bad channel',
  SCHEDULED: 'Scheduled',
  SENDING: 'Sending',
  SENT: 'Sent',
  DELIVERED: 'Delivered',
  READ: 'Read',
  ERROR: 'Error',
  NOT_SENT: 'Not sent'
};

export const GENERAL_MESSAGE_TABS = ['Summary', 'Content', 'Users affected', 'Delivery Status'];

export const GENERAL_MESSAGE_MODAL_TOTAL_STEP: number = 3;

export const GENERAL_MESSAGE_ATTACHMENTS_MAX_SIZE: number = 10000000; // bytes
