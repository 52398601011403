import * as React from 'react';
import { Button } from '../../../../components/Button/Button';
import { CALENDAR_FILTER, FilterType } from './Calendar';
import {
  isAllFilter,
  isClubEventsFilter,
  isSportEventsFilter,
  isTournamentEventsFilter,
  isTournamentsFilter
} from '../../../../helpers/calendar/calendar';
import { AppUser } from '../../../App/App';
import { isUserCoach, isUserTeacher } from '../../../../helpers/user/user';

interface Props {
  onFilterButtonClick: (filter: FilterType) => void;
  filter: FilterType;
  user: AppUser;
}

export function CalendarFilterButtons(props: Props) {
  const { onFilterButtonClick, filter, user } = props;
  //not show tournaments and tournament events filter buttons for teacher/coach
  const isUserCoachOrTeacher = isUserCoach(user) || isUserTeacher(user);

  return (
    <div className={'mt-3 mb-3'}>
      <Button
        key={'calendar_filter_button_all'}
        customClass={isAllFilter(filter) ? 'eCalendarFilterActiveButton mr-3' : 'eCalendarFilterButton mr-3'}
        onClick={() => onFilterButtonClick(CALENDAR_FILTER.ALL as FilterType)}
        text={'All events'}
      />
      <Button
        key={'calendar_filter_button_sport_events'}
        customClass={isSportEventsFilter(filter) ? 'eCalendarFilterActiveButton mr-3' : 'eCalendarFilterButton mr-3'}
        onClick={() => onFilterButtonClick(CALENDAR_FILTER.SPORT_EVENTS as FilterType)}
        text={'Sport events'}
      />
      <Button
        key={'calendar_filter_button_club_events'}
        customClass={isClubEventsFilter(filter) ? 'eCalendarFilterActiveButton mr-3' : 'eCalendarFilterButton mr-3'}
        onClick={() => onFilterButtonClick(CALENDAR_FILTER.CLUB_EVENTS as FilterType)}
        text={'Club events'}
      />
      {!isUserCoachOrTeacher && (
        <>
          <Button
            key={'calendar_filter_button_tournament_events'}
            customClass={
              isTournamentEventsFilter(filter) ? 'eCalendarFilterActiveButton mr-3' : 'eCalendarFilterButton mr-3'
            }
            onClick={() => onFilterButtonClick(CALENDAR_FILTER.TOURNAMENT_EVENTS as FilterType)}
            text={'Tournament events'}
          />
          <Button
            key={'calendar_filter_button_tournaments'}
            customClass={
              isTournamentsFilter(filter) ? 'eCalendarFilterActiveButton mr-3' : 'eCalendarFilterButton mr-3'
            }
            onClick={() => onFilterButtonClick(CALENDAR_FILTER.TOURNAMENTS as FilterType)}
            text={'Tournaments'}
          />
        </>
      )}
    </div>
  );
}
