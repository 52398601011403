import * as React from 'react';
import { FunctionComponent } from 'react';
import * as classNames from 'classnames';
import { Integration } from '../../models/Integration';
import { INTEGRATION_TYPE, INTEGRATION_TYPE_SERVER_TO_CLIENT_MAPPING } from '../../consts/integration';

interface Props {
  items: Integration[];
  isOpen: boolean;
  onButtonClick?: (event, type) => void;
  onCloseClick?: () => void;
}

export const IntegrationAddModal: FunctionComponent<Props> = props => {
  const { isOpen, onCloseClick, items } = props;

  if (!isOpen) {
    return null;
  }

  const modalClasses = classNames({
    mDisplayBlock: isOpen,
    show: isOpen,
    modal: true,
    fade: true
  });

  const isExistICalendarType = items.length > 0 ? items.some(item => item.type === INTEGRATION_TYPE.I_CALENDAR) : false;

  return (
    <div className={modalClasses}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Choose integration and click it!</h5>
            <button type="button" className="close" onClick={onCloseClick}>
              <span>&times;</span>
            </button>
          </div>
          <div className="pt-4 pb-4 d-flex flex-column align-items-center">
            <button
              type="button"
              className="btn btn-primary mb-3 w-50"
              onClick={event => props.onButtonClick(event, INTEGRATION_TYPE.GOOGLE_CALENDAR)}
            >
              Google Calendar
            </button>
            <button
              type="button"
              className="btn btn-primary mb-3 w-50"
              onClick={event => props.onButtonClick(event, INTEGRATION_TYPE.TWITTER)}
            >
              Twitter
            </button>
            <button
              type="button"
              className="btn btn-primary mb-3 w-50"
              onClick={event => props.onButtonClick(event, INTEGRATION_TYPE.OUTLOOK)}
            >
              Outlook
            </button>
            <button
              type="button"
              disabled={isExistICalendarType}
              className="btn btn-primary mb-3 w-50"
              onClick={event => props.onButtonClick(event, INTEGRATION_TYPE.I_CALENDAR)}
            >
              iCalendar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

IntegrationAddModal.displayName = 'IntegrationAddModal';
