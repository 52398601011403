import * as React from 'react';
import { AppUser } from 'Src/views/App/App';

import {
  SchoolEvent,
  SchoolEventAttendance,
  SchoolEventIndividualData,
  SchoolEventTeamDataPlayer
} from 'Src/models/event';
import { getFullName } from 'Src/helpers/autocomplete/autocomplete';

interface Props {
  schoolEvent: SchoolEvent;
  allTeamPlayers: (SchoolEventIndividualData | SchoolEventTeamDataPlayer)[];
  attendancePlayers: SchoolEventAttendance[];
  user: AppUser;
}

export function StudentEventAttendanceSummaryTab(props: Props) {
  const { allTeamPlayers, attendancePlayers, user } = props;
  const { userId } = user;
  const currentPlayer = allTeamPlayers.find(player => player.userId === userId);
  const currentPlayerFullName = getFullName(currentPlayer);
  const isCurrentPlayerAttendance = attendancePlayers.find(attendance => attendance.userId === userId);

  const renderAttendanceViewMode = (player: SchoolEventIndividualData | SchoolEventTeamDataPlayer) => {
    const playerAttendance = attendancePlayers.find(attendance => attendance.userId === player.userId);
    switch (true) {
      case typeof playerAttendance === 'undefined':
        return '';
      case playerAttendance.isAttendance:
        return 'Present';
      case !playerAttendance.isAttendance:
        return 'Absent';
    }
  };

  const row = (
    <tr key={`attendance_summary_1`}>
      <td>1</td>
      <td>
        <div className="font-weight-bold">{currentPlayerFullName}</div>
      </td>
      <td>
        <div className={isCurrentPlayerAttendance.isAttendance ? '' : 'text-danger'}>
          {renderAttendanceViewMode(currentPlayer)}
        </div>
      </td>
      <td />
    </tr>
  );

  const emptyRow = (
    <tr key={'players_empty_row'}>
      <td>No participants</td>
    </tr>
  );

  return (
    <table className={'table table-sm mPre'}>
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Attendance</th>
          <th />
        </tr>
      </thead>
      <tbody>{typeof currentPlayer === 'undefined' ? emptyRow : row}</tbody>
    </table>
  );
}
