import * as React from 'react';
import * as propz from 'propz';
import { Field, Form, Formik } from 'formik';
import { LabelWithQuestionIcon } from 'Src/components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import { HTMLEditorQuillHide } from 'Src/components/HTMLEditorQuill/HTMLEditorQuillHide';
import { EditorIcon } from 'Src/components/EditorIcon/EditorIcon';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import { AppUser } from 'Src/views/App/App';
import { LeagueNews, LeagueNewsAttachment } from 'Src/models/schoolLeague';
import * as Moment from 'moment';
import { uploadFile } from 'Src/helpers/service/file';
import { uploadImage } from 'Src/helpers/service/image';

interface Props {
  onCloseClick: () => void;
  onFormSubmit: (data) => void;
  user: AppUser;
  news?: LeagueNews;
}
interface State {
  isEditorActive: boolean;
  isLoadingFile: boolean;
  isLoadingImage: boolean;
  files: LeagueNewsAttachment[];
  images: LeagueNewsAttachment[];
}

export class SchoolLeagueNewsForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { news } = props;

    this.state = {
      isEditorActive: false,
      isLoadingFile: false,
      isLoadingImage: false,
      files: propz.get(news, ['attachments', 'files'], []),
      images: propz.get(news, ['attachments', 'images'], [])
    };
  }

  onShowEditorClick = () => {
    const { isEditorActive } = this.state;
    this.setState({
      isEditorActive: !isEditorActive
    });
  };

  onFileChange = (event): void => {
    const { user } = this.props;

    const file = event.target.files[0];
    const fileName = file.name;
    const fileNameWithoutSpaces = fileName.split(' ').join('_');
    const formData = new FormData();
    formData.append('file', file, fileNameWithoutSpaces);

    this.setState({
      isLoadingFile: true
    });

    uploadFile(user, formData).then(data => {
      const { key } = data;

      const filesNext = [
        ...this.state.files,
        {
          url: key,
          name: fileNameWithoutSpaces
        }
      ];

      this.setState({
        isLoadingFile: false,
        files: filesNext
      });
    });
  };

  onImageChange = event => {
    const { user } = this.props;
    const image = event.target.files[0];
    const imageName = image.name;

    this.setState({
      isLoadingImage: true
    });

    uploadImage(user, image).then(response => {
      const picUrl = `${window.apiImg}/images/${response.key}`;

      const imagesNext = [
        ...this.state.images,
        {
          url: picUrl,
          name: imageName
        }
      ];

      this.setState({
        images: imagesNext,
        isLoadingImage: false
      });
    });
  };

  onSubmit = values => {
    const { onFormSubmit } = this.props;
    const { files, images } = this.state;

    const dataToPost = {
      ...values,
      attachments: {
        files,
        images
      }
    };

    onFormSubmit(dataToPost);
  };

  onRemoveFilesClick = fileIndex => {
    const { files } = this.state;
    const filesNext = files.filter((file, index) => fileIndex !== index);
    this.setState({
      files: filesNext
    });
  };

  renderFiles() {
    const { files } = this.state;

    return files.map((file, index) => {
      return (
        <div key={`files_${index}`} className="mMaxWidth200px">
          <button type="button" className="close" onClick={() => this.onRemoveFilesClick(index)}>
            <span>&times;</span>
          </button>
          <div>{file.name}</div>
        </div>
      );
    });
  }

  onRemoveImageClick = imageIndex => {
    const { images } = this.state;
    const imagesNext = images.filter((image, index) => imageIndex !== index);
    this.setState({
      images: imagesNext
    });
  };

  renderImages() {
    const { images } = this.state;

    return images.map((image, index) => {
      return (
        <div key={`images_${index}`} className="mMaxWidth200px">
          <button type="button" className="close" onClick={() => this.onRemoveImageClick(index)}>
            <span>&times;</span>
          </button>
          <img className="img-fluid img-thumbnail" src={image.url} alt="league news image" />
        </div>
      );
    });
  }

  render() {
    const { onCloseClick, news } = this.props;
    const { isEditorActive, isLoadingFile, isLoadingImage } = this.state;

    const newsDate = propz.get(news, ['date']);
    const date = Moment(newsDate, 'DD-MM-YYYY HH:mm aa').isValid() ? new Date(newsDate) : new Date();

    const newsInitial = {
      date: date,
      title: propz.get(news, ['title'], ''),
      body: propz.get(news, ['body'], '')
    };

    const newsSchema = Yup.object().shape({
      title: Yup.string().required('Required'),
      body: Yup.string().required('Required')
    });

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <Formik
              initialValues={newsInitial}
              validationSchema={newsSchema}
              onSubmit={values => this.onSubmit(values)}
              render={({ touched, errors, setFieldValue, values }) => (
                <Form>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Title</label>
                          <Field type="text" name="title" className="form-control" />
                        </div>
                        {touched.title && errors.title ? (
                          <div className="alert alert-danger">{errors.title}</div>
                        ) : null}

                        <div className="form-group">
                          <LabelWithQuestionIcon labelText="Body" hintText="" />
                          <div className="d-flex">
                            <HTMLEditorQuillHide
                              onTextChange={body => {
                                setFieldValue('body', body);
                              }}
                              value={values.body}
                              isEnabled={isEditorActive}
                            />
                            <EditorIcon onClick={this.onShowEditorClick} />
                          </div>
                        </div>
                        {touched.body && errors.body ? <div className="alert alert-danger">{errors.body}</div> : null}

                        <div className="form-group">
                          <label>Date</label>
                          <Field
                            render={({ field }) => {
                              return (
                                <div className="mb-3">
                                  <DatePicker
                                    selected={values.date}
                                    onChange={date => {
                                      setFieldValue('date', date);
                                    }}
                                    className="form-control"
                                    timeInputLabel="Date: "
                                    showTimeSelect={true}
                                    dateFormat={'dd-MM-yyyy hh:mm:a'}
                                  />
                                </div>
                              );
                            }}
                          />
                        </div>

                        {isLoadingFile ? (
                          <div>Loading...</div>
                        ) : (
                          <div className="form-group">
                            <label htmlFor="files">Files: </label>
                            <input type="file" className="form-control-file" id="files" onChange={this.onFileChange} />
                          </div>
                        )}

                        <div className="form-group">{this.renderFiles()}</div>

                        {isLoadingImage ? (
                          <div>Loading...</div>
                        ) : (
                          <div className="form-group">
                            <label htmlFor="images">Images: </label>
                            <input
                              type="file"
                              className="form-control-file"
                              id="images"
                              onChange={this.onImageChange}
                            />
                          </div>
                        )}

                        <div className="form-group">{this.renderImages()}</div>

                        <button type="button" className="btn btn-secondary mr-3 mt-3 mb-3" onClick={onCloseClick}>
                          Cancel
                        </button>
                        <button type="submit" className="btn btn-primary mt-3 mb-3">
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}
