import * as React from 'react';
import { FunctionComponent } from 'react';
import MaskedInput from 'react-text-mask';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DATE_TIME_FORMAT, DATE_TIME_MASK } from 'Src/consts/date';

interface Props {
  onScheduleDateChange: (event: any) => void;
}

export const ScheduleSendingForm: FunctionComponent<Props> = props => {
  return (
    <div className="alert border-light bg-light">
      <label>Schedule at</label>
      <div className="input-group">
        <div className="input-group-prepend">
          <div className="input-group-text">
            <FontAwesomeIcon icon="clock" />
          </div>
        </div>
        <MaskedInput
          type="text"
          className="form-control"
          onChange={props.onScheduleDateChange}
          mask={DATE_TIME_MASK}
          placeholder={DATE_TIME_FORMAT}
        />
      </div>
    </div>
  );
};
