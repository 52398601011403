import * as React from 'react';
import { FunctionComponent } from 'react';
import './DateIntervalField.css';
import { DATE_INTERVAL } from '../../consts/table';
import MaskedInput from 'react-text-mask';
import DatePicker from 'react-datepicker';
import * as Moment from 'moment';
import * as classNames from 'classnames';
import { DATE_FORMAT } from '../../consts/date';

interface Props {
  onTableFilterChange: (event, value: string, options?) => void;
  field: string;
  from: string;
  to: string;
  customClass?: string;
  filterMinDateInterval?: Date;
  filterMaxDateInterval?: Date;
  onEnterPress?: (event) => void;
}

export const DateIntervalField: FunctionComponent<Props> = props => {
  const { filterMinDateInterval, filterMaxDateInterval, onEnterPress } = props;
  const classes = classNames('pl-2', 'mMinWidth185', 'mMaxWidth250', props.customClass);

  return (
    <div className={classes}>
      <div className="input-group mMarginBottom4">
        <div className="input-group-prepend">
          <span className="input-group-text mMinWidth67">From:</span>
        </div>
        <DatePicker
          selected={Moment(props.from, DATE_FORMAT).isValid() ? Moment(props.from, 'DD-MM-YYYY').toDate() : null}
          onChange={date => {
            const isDateExist = date !== null;

            props.onTableFilterChange(
              { target: { value: isDateExist ? Moment(date).format(DATE_FORMAT) : '' } },
              props.field,
              DATE_INTERVAL.FROM
            );
          }}
          className="form-control mMaxWidth110"
          dateFormat={'dd-MM-yyyy'}
          customInput={
            <MaskedInput mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} placeholder="DD-MM-YYYY" />
          }
          minDate={filterMinDateInterval}
          maxDate={filterMaxDateInterval}
          onKeyDown={onEnterPress}
        />
      </div>
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text mMinWidth67">To:</span>
        </div>
        <DatePicker
          selected={Moment(props.to, DATE_FORMAT).isValid() ? Moment(props.to, 'DD-MM-YYYY').toDate() : null}
          onChange={date => {
            const isDateExist = date !== null;

            props.onTableFilterChange(
              { target: { value: isDateExist ? Moment(date).format(DATE_FORMAT) : '' } },
              props.field,
              DATE_INTERVAL.TO
            );
          }}
          className="form-control mMaxWidth110"
          dateFormat={'dd-MM-yyyy'}
          customInput={
            <MaskedInput mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} placeholder="DD-MM-YYYY" />
          }
          minDate={filterMinDateInterval}
          maxDate={filterMaxDateInterval}
          onKeyDown={onEnterPress}
        />
      </div>
    </div>
  );
};

DateIntervalField.displayName = 'DateIntervalField';
