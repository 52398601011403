import * as React from 'react';
import { FunctionComponent } from 'react';
import { AppUser } from 'Src/views/App/App';
import { Club } from 'Src/models/club';
import { CLUB_STATUS } from 'Src/consts/club';
import { EVENT_STATUS_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/event';
import { getEventNameWithLink } from 'Src/helpers/cell/cell';
import * as Moment from 'moment';

interface Props {
  user: AppUser;
  club: Club;
  clubEvents: any[];
}

export const DRAFT_TEXT = [
  'Once you have clicked the «Activate» button, the club schedule will be created and displayed in the calendars of your school staff. The club details will also appear in the calendars of relevant parents and pupils who have accounts with Squad In Touch.',
  'To make changes to the club schedule, including date and time as well as altering the pupils list, please amend the details directly from the clubs event page. From there you will then be able to apply the changes to either the selected event or all repeated events that follow.'
];
//may be useful later
const ACTIVE_TEXT = [
  'The club is now live.',
  'To make changes to the club schedule, including date and time as well as altering the pupils list, please amend the details directly from the clubs event page.'
];

export const ClubActivateSummary: FunctionComponent<Props> = props => {
  const { club, user } = props;
  const isClubActive = club.status === CLUB_STATUS.ACTIVE;

  if (isClubActive) {
    const clubEvents = props.clubEvents;

    const rows = clubEvents.map((event, index) => {
      return (
        <tr key={`club_events_${index}`}>
          <td>{index + 1}</td>
          {getEventNameWithLink(event, { user })}
          <td>{Moment(event.startTime).format('DD-MM-YYYY HH:mm')}</td>
          <td>{EVENT_STATUS_SERVER_TO_CLIENT_MAPPING[event.status]}</td>
        </tr>
      );
    });

    const emptyRow = (
      <tr key={'club_events_empty_row'}>
        <td>No club events</td>
      </tr>
    );

    return (
      <table className={'table table-sm mPre mVerticalAlignMiddle'}>
        <thead>
          <tr>
            <th>#</th>
            <th>Event</th>
            <th>Start</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>{rows.length > 0 ? rows : emptyRow}</tbody>
      </table>
    );
  } else {
    return (
      <div>
        <p>{DRAFT_TEXT[0]}</p>
        <p>{DRAFT_TEXT[1]}</p>
      </div>
    );
  }
};

ClubActivateSummary.displayName = 'ClubActivateSummary';
