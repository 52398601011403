import * as React from 'react';
import { Component } from 'react';
import { AppUser } from 'Src/views/App/App';
import * as BPromise from 'bluebird';
import * as Lazy from 'lazy.js';
import { DATE_INTERVAL, DEFAULT_LIMIT, DEFAULT_SKIP, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { History, Location } from 'history';
import { parse } from 'query-string';
import {
  createEventAttendance,
  createEventPerformance,
  getAllSchoolEvents,
  getSchoolEventDetails,
  getSchoolEvents,
  getSchoolEventsCount,
  onDeleteJob,
  updateEventAttendance,
  updateEventPerformance,
  updateSchoolEventDetails
} from 'Src/helpers/service/admin/schoolEvents';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import {
  ColumnDefinition,
  getFilters2,
  getOrder,
  getSearchFilter,
  getSearchOrder,
  getServerFieldSectionWhere,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import {
  getCoachEvent,
  getEventAges,
  getEventGender,
  getEventStartTime,
  getEventStatus,
  getEventSubtype,
  getEventType,
  getEventVenue,
  getEventVenueType,
  getStaffEvent
} from 'Src/helpers/accessor/accessor';
import { SchoolForm } from 'Src/models/form';
import { getAllForms } from 'Src/helpers/service/admin/forms';
import * as propz from 'propz';
import { getSchoolSports } from 'Src/helpers/service/admin/user';
import { GenericSummary } from 'Src/components/GenericSummary/GenericSummary';
import { SchoolEventSummary } from 'Src/components/SchoolEventSummary/SchoolEventSummary';
import { SchoolEventDetailsSummary } from 'Src/components/SchoolEventDetailsSummary/SchoolEventDetailsSummary';
import {
  SchoolEventPlayersSummary,
  SchoolEventPlayerSummary,
  SchoolEventTeamSummary
} from 'Src/components/SchoolEventPlayersSummary/SchoolEventPlayersSummary';
import { Button } from 'Src/components/Button/Button';
import {
  getSelectOptionForAge,
  getSelectOptionForEventTypes,
  getSelectOptionForEventVenueType,
  getSelectOptionForSport,
  getSelectOptionForTournamentEventGender,
  getSelectOptionForTournamentEventStatus
} from 'Src/helpers/table/select';
import { Grid2 } from 'Src/components/Grid/Grid2';
import { ResizablePanel } from 'Src/components/ResizablePanel/ResizablePanel';
import {
  createEventTasks,
  editEventTasks,
  getEventTasks,
  getSchoolEvent,
  updateSchoolEventTeamPlayersBatch
} from 'Src/helpers/service/admin/event';
import { SchoolEventJobsSummary } from 'Src/components/SchoolEventJobsSummary/SchoolEventJobsSummary';
import { Loader } from 'Src/components/Loader/Loader';
import { SchoolEventPerformanceSummary } from 'Src/components/SchoolEventPerformanceSummary/SchoolEventPerformanceSummary';
import { getEventPlayers, getEventTabs, isHousesEvent, isNonTeamSportEvent } from 'Src/helpers/event/event';
import { TABS } from 'Src/consts/common';
import {
  SchoolEvent,
  SchoolEventAttendance,
  SchoolEventIndividualData,
  SchoolEventIndividualPerformance,
  SchoolEventTeamDataPlayer
} from 'Src/models/event';
import { Sport } from 'Src/models/sport';
import { SchoolEventAttendanceSummary } from 'Src/components/SchoolEventAttendance/SchoolEventAttendanceSummary';
import { getEventNameWithLink } from 'Src/helpers/cell/cell';
import { isClubEvent } from 'Src/helpers/event/event';
import { SchoolLeague } from 'Src/models/schoolLeague';
import {
  addEventToSchoolLeague,
  getAllSchoolLeagueEvents,
  getSchoolLeague
} from 'Src/helpers/service/admin/schoolLeagues';
import { EmptyComponent } from 'Src/components/EmptyComponent/EmptyComponent';
import { SchoolEventMatchReportSummary } from 'Src/components/SchoolEventMatchReportSummary/SchoolEventMatchReportSummary';
import {
  getSchoolEventMatchReport,
  updateGroupSchoolEventDetails,
  updateSchoolEventMatchReport
} from 'Src/helpers/service/admin/schoolEvents';
import { getEventEndTime } from 'Src/helpers/accessor/accessor';
import { CLUB_EVENT_EDIT_MODE } from 'Src/consts/event';
import { updateSchoolEventIndividualsBatch } from 'Src/helpers/service/admin/schoolEventIndividuals';
import { SchoolEventJobForm } from '../../../TeamsAndEvents/Events/SchoolEventJobForm/SchoolEventJobForm';
import { SchoolEventDetailsForm } from '../../../TeamsAndEvents/Events/SchoolEventDetailsForm/SchoolEventDetailsForm';
import { LEAGUE_AGGREGATION_TYPE } from '../../../../../../consts/league';
import { TYPE_OF_PLAYERS } from '../../../../../../consts/sport';
import { isLeagueIndividual } from '../../../../../../helpers/league/league';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Event',
    field: 'generatedName',
    isSort: false,
    type: FILTER_TYPE.NONE,
    cell: getEventNameWithLink
  },
  {
    text: 'Start time',
    field: 'startTime',
    isSort: true,
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: getEventStartTime
  },
  {
    text: 'Finish time',
    field: 'endTime',
    isSort: true,
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: getEventEndTime
  },
  {
    text: 'Type',
    field: 'subtype',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getEventSubtype
  },
  {
    text: 'Kind',
    field: 'eventType',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getEventType
  },
  {
    text: 'Status',
    field: 'status',
    isSort: true,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getEventStatus
  },
  {
    text: 'Activity',
    field: 'eventSport',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: ['sport', 'name']
  },
  {
    text: 'Age',
    field: 'eventAges',
    isSort: false,
    type: FILTER_TYPE.CHECKBOX,
    accessor: getEventAges
  },
  {
    text: 'Gender',
    field: 'gender',
    isSort: true,
    type: FILTER_TYPE.CHECKBOX,
    accessor: getEventGender
  },
  {
    text: 'Home/Away',
    field: 'venueType',
    isSort: true,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getEventVenueType
  },
  {
    text: 'Venue',
    field: 'venue',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getEventVenue
  },
  {
    text: 'Coach/Leader',
    field: 'coach',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getCoachEvent
  },
  {
    text: 'Staff',
    field: 'staff',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getStaffEvent
  }
];

interface State {
  items: SchoolEvent[];
  currentPage: number;
  selectedItems: SchoolEvent[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  filterOptions: any;
  currentTabIndex: number;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;
  isErrorModalOpen: boolean;
  errorMessage: string;
  isCreateEventModalOpen: boolean;
  isCreateEventJobModalOpen: boolean;
  isDeleteJobModalOpen: boolean;
  isEditEventDetailsModalOpen: boolean;
  isEditPerformance: boolean;
  isEditAttendance: boolean;
  isEditMatchReportActive: boolean;
  forms: SchoolForm[];
  sports: Sport[];
  eventsDetails: any;
  eventTasks: any[];
  isEditPositions: boolean;
  playersForSummary: SchoolEventPlayerSummary[];
  teamsForSummary: SchoolEventTeamSummary[];
  activeTeamIndex: number;
  actionDescriptorIds: any[];
  eventTaskIndex: number;
  individualPerformance: SchoolEventIndividualPerformance[];
  attendancePlayers: SchoolEventAttendance[];
  league: SchoolLeague;
  leagueEventIds: string[];
  matchReport: string;
  isEditAllAttendance: boolean;
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export class SchoolLeagueEligibleEvents extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      filterOptions: undefined,
      isDataFiltered: false,
      currentTabIndex: 0,
      filters: {},
      isLoading: true,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',
      isErrorModalOpen: false,
      isCreateEventModalOpen: false,
      isCreateEventJobModalOpen: false,
      isDeleteJobModalOpen: false,
      isEditEventDetailsModalOpen: false,
      isEditPerformance: false,
      errorMessage: '',
      forms: [],
      sports: [],
      eventsDetails: undefined,
      eventTasks: [],
      isEditPositions: false,
      playersForSummary: [],
      teamsForSummary: [],
      activeTeamIndex: 0,
      actionDescriptorIds: [],
      eventTaskIndex: 0,
      individualPerformance: [],
      isEditAttendance: false,
      attendancePlayers: [],
      league: undefined,
      leagueEventIds: [],
      matchReport: '',
      isEditMatchReportActive: true,
      isEditAllAttendance: true
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });
    this.setAdditionalItems().then(res => {
      this.setItems();
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  getLeagueId(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    const leagueId = search.league;
    return leagueId;
  }

  getSportsFilterByLeagueType(league: SchoolLeague) {
    let additionalFilter = {};
    const { aggregationType } = league;
    switch (aggregationType) {
      case LEAGUE_AGGREGATION_TYPE.INDIVIDUAL:
        additionalFilter = {
          where: { players: { $in: [TYPE_OF_PLAYERS['1X1'], TYPE_OF_PLAYERS.INDIVIDUAL] } }
        };
        break;
      case LEAGUE_AGGREGATION_TYPE.TEAM:
      case LEAGUE_AGGREGATION_TYPE.HOUSE:
        additionalFilter = {
          where: { players: { $in: [TYPE_OF_PLAYERS['2X2'], TYPE_OF_PLAYERS.TEAM] } }
        };
        break;
    }

    return {
      ...additionalFilter,
      limit: DEFAULT_LIMIT,
      order: 'name ASC',
      skip: DEFAULT_SKIP
    };
  }

  setAdditionalItems() {
    const { user } = this.props;
    const leagueId = this.getLeagueId();
    let league: SchoolLeague;

    return getSchoolLeague(user, leagueId)
      .then(_league => {
        league = _league;
        const sportsFilter = this.getSportsFilterByLeagueType(league);
        const formsPromise = getAllForms(user);
        const sportsPromise = getSchoolSports(user, sportsFilter);
        const leagueEventsPromise = getAllSchoolLeagueEvents(user, leagueId);

        return BPromise.all([formsPromise, sportsPromise, leagueEventsPromise]);
      })
      .then(([forms, sports, leagueEvents]) => {
        const filterOptions = this.getFilterOptions(forms, sports);
        const leagueEventIds = leagueEvents.map(event => event.id);

        this.setState({
          forms: forms,
          sports: sports,
          league: league,
          leagueEventIds: leagueEventIds,
          filterOptions: filterOptions
        });

        return true;
      });
  }

  setItems() {
    const { history, user } = this.props;
    const { leagueEventIds, league } = this.state;
    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const options = isLeagueIndividual(league)
      ? {
          isCorrectTime: true,
          isExcludeClubEvents: true,
          isExcludeTournamentEvents: true,
          ninEventIds: leagueEventIds,
          isOnlyIndividualEvents: true
        }
      : {
          isCorrectTime: true,
          isExcludeClubEvents: true,
          isExcludeTournamentEvents: true,
          ninEventIds: leagueEventIds,
          isOnlyTeamEvents: true
        };

    const where = getServerFieldSectionWhere(filters, options);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const getItemsPromise = getSchoolEvents(user, serverQueryFilter);
    const getItemsCountPromise = getSchoolEventsCount(user, where);

    return BPromise.all([getItemsCountPromise, getItemsPromise]).then(([countObj, items]) => {
      this.setState({
        currentPage: page,
        sortDirection: sortDirection as TABLE_SORT_DIRECTION,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: items,
        total: countObj.count,
        basePath: history.location.pathname,
        isLoading: false
      });

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection, basePath } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const leagueId = this.getLeagueId();
    const { history } = this.props;

    history.push({
      pathname: basePath,
      search: `league=${leagueId}&${search.join('&')}`,
      state: history.location.state
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated,
      currentTabIndex: 0
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const selectedItemsNext = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: selectedItemsNext,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters, leagueEventIds, league } = this.state;

    this.setState({
      isLoading: true
    });

    const { user } = this.props;

    const options = isLeagueIndividual(league)
      ? {
          isCorrectTime: true,
          isExcludeClubEvents: true,
          isExcludeTournamentEvents: true,
          ninEventIds: leagueEventIds,
          isOnlyIndividualEvents: true
        }
      : {
          isCorrectTime: true,
          isExcludeClubEvents: true,
          isExcludeTournamentEvents: true,
          ninEventIds: leagueEventIds,
          isOnlyTeamEvents: true
        };

    const where = getServerFieldSectionWhere(filters, options);

    getAllSchoolEvents(user, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const selectedItemsNext = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: selectedItemsNext,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters, basePath } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const leagueId = this.getLeagueId();
    const { history } = this.props;

    history.push({
      pathname: basePath,
      search: `league=${leagueId}&${search.join('&')}`,
      state: history.location.state
    });
  };

  onTableFilterChange = (event, filterField: string, options?, index?: number, checkBoxIndex?: number): void => {
    const filterValue = propz.get(event, ['target', 'value'], '');
    const { filters, filterOptions } = this.state;
    const currentFilterField = filters[filterField];
    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      switch (filterType) {
        case FILTER_TYPE.MULTISELECT:
          const options = event.target.options;

          const value = [];
          for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
              value.push(options[i].value);
            }
          }
          nextFilters = {
            ...nextFilters,
            [filterField]: value
          };
          break;
        case FILTER_TYPE.CHECKBOX:
          const columns = COLUMNS;
          const currentField = columns[index].field;
          filterOptions[currentField][checkBoxIndex].isChecked = !filterOptions[currentField][checkBoxIndex].isChecked;

          this.setState({
            filterOptions: filterOptions
          });

          const checkBoxValue = [];
          if (typeof filterOptions[filterField] !== 'undefined') {
            for (let i = 0; i < filterOptions[filterField].length; i++) {
              if (filterOptions[filterField][i].isChecked) {
                checkBoxValue.push(filterOptions[filterField][i].value);
              }
            }
          }
          nextFilters = {
            ...nextFilters,
            [filterField]: checkBoxValue
          };
          break;
        default:
          nextFilters = {
            ...nextFilters,
            [filterField]: filterValue
          };
          break;
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName, basePath } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    const leagueId = this.getLeagueId();
    const { history } = this.props;

    history.push({
      pathname: basePath,
      search: `league=${leagueId}&${search.join('&')}`,
      state: history.location.state
    });
  };

  onClearFilterClick = (): void => {
    const { forms, sports, basePath } = this.state;
    const filterOptions = this.getFilterOptions(forms, sports);

    this.setState({
      filterOptions: filterOptions,
      selectedItems: [],
      isSelectAllChecked: false
    });

    const leagueId = this.getLeagueId();
    const { history } = this.props;

    history.push({
      pathname: basePath,
      search: `league=${leagueId}`,
      state: history.location.state
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  onCloseErrorClick = () => {
    this.setState({
      isErrorModalOpen: false,
      errorMessage: ''
    });
  };

  onSubmitEventJobForm = data => {
    const { user } = this.props;
    const { selectedItems, eventTaskIndex, eventTasks } = this.state;
    const schoolEventId = selectedItems[0].id;
    const schoolEvent = selectedItems[0];
    this.setState({
      isLoading: true,
      isCreateEventJobModalOpen: false
    });

    const { players, ...rest } = data;

    const dataToSubmit = {
      ...rest,
      assignees: players
    };
    if (eventTaskIndex !== -1) {
      const activeTaskId = eventTasks[eventTaskIndex].id;

      editEventTasks(user, schoolEventId, activeTaskId, dataToSubmit)
        .then(res => getEventTasks(user, schoolEventId))
        .then(eventTasks => {
          const eventTab = getEventTabs(schoolEvent);
          const tabIndex = eventTab.indexOf(TABS.JOBS);
          this.setState({
            currentTabIndex: tabIndex,
            eventTasks: eventTasks,
            isCreateEventJobModalOpen: false,
            isLoading: false
          });
        })
        .catch(error => {
          const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
          console.error(error);
          this.setState({
            isLoading: false,
            errorMessage: errorText,
            isErrorModalOpen: true
          });
        });
    } else {
      createEventTasks(user, schoolEventId, dataToSubmit)
        .then(task => {
          const tasks = [...eventTasks];
          tasks.push(task);
          this.setState({
            eventTasks: tasks,
            isLoading: false
          });
        })
        .catch(error => {
          const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
          console.error(error);
          this.setState({
            isCreateEventJobModalOpen: false,
            isLoading: false,
            errorMessage: errorText,
            isErrorModalOpen: true
          });
        });
    }
  };

  renderSendingError(): React.ReactNode {
    const { errorMessage, isErrorModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isErrorModalOpen}
        title={'Error'}
        body={errorMessage}
        buttonCancelText={'Ok'}
        onCloseClick={this.onCloseErrorClick}
      />
    );
  }

  onShowError = (message: string) => {
    this.setState({
      isErrorModalOpen: true,
      errorMessage: message
    });
  };

  renderEventJobForm(): React.ReactNode {
    const { isCreateEventJobModalOpen, eventTasks, eventTaskIndex, selectedItems } = this.state;
    const { user } = this.props;
    const schoolEvent = selectedItems[0];
    const modalText = eventTaskIndex === -1 ? 'Create job' : 'Edit job';
    return (
      <SimpleModal isOpen={isCreateEventJobModalOpen} title={modalText}>
        <SchoolEventJobForm
          user={user}
          schoolEvent={schoolEvent}
          eventTasks={eventTasks}
          eventTaskIndex={eventTaskIndex}
          onCancel={this.onCloseEventJobClick}
          onSubmit={this.onSubmitEventJobForm}
        />
      </SimpleModal>
    );
  }

  renderDeleteJobModal() {
    const { isDeleteJobModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isDeleteJobModalOpen}
        title={'Remove Job'}
        body={`Are you sure you want to remove Job ?`}
        buttonText={'Delete'}
        onButtonClick={this.onDeleteJobClick}
        onCloseClick={this.deleteJobModalClose}
      />
    );
  }

  onCreateEventClick = (): void => {
    this.props.history.push({
      pathname: `/events/manager`
    });
  };

  onAddEventToLeagueClick = () => {
    const { user } = this.props;
    const { leagueEventIds, selectedItems } = this.state;

    this.setState({
      isLoading: true
    });

    BPromise.all(
      selectedItems.map(selectedItem => {
        const eventId = selectedItem.id;
        const leagueId = this.getLeagueId();
        const data = {
          eventId: eventId
        };

        return addEventToSchoolLeague(user, leagueId, data);
      })
    ).then(events => {
      const eventIds = events.map(event => event.id);

      const updatedEventIds: string[] = Lazy([...leagueEventIds, ...eventIds])
        .uniq()
        .toArray();

      this.setState({
        leagueEventIds: updatedEventIds
      });
      this.setItems();
    });
  };

  onTabClick = (event, index: number) => {
    if (typeof event !== 'undefined') {
      event.preventDefault();
    }

    const { selectedItems, isEditPerformance, isEditAttendance, isEditPositions } = this.state;
    const [schoolEvent] = selectedItems;
    const eventId = schoolEvent.id;
    const { user } = this.props;

    if (isEditPerformance || isEditAttendance || isEditPositions) {
      return;
    }

    const tabs = getEventTabs(schoolEvent);
    const currentTab = tabs[index];

    const individualPerformance = schoolEvent.results.individualPerformance;
    const attendance = schoolEvent.results.attendance;
    const attendancePlayers = attendance.map(player => ({ ...player }));

    switch (currentTab) {
      case TABS.DETAILS:
        this.setState({
          isLoading: true
        });
        getSchoolEventDetails(user, eventId).then(details => {
          this.setState({
            currentTabIndex: index,
            eventsDetails: details,
            isLoading: false
          });
        });
        break;
      case TABS.JOBS:
        this.setState({
          isLoading: true
        });
        getEventTasks(user, eventId).then(eventTasks => {
          this.setState({
            currentTabIndex: index,
            eventTasks: eventTasks,
            isLoading: false
          });
        });
        break;
      case TABS.PARTICIPANTS:
        const playersForSummary = this.getPlayersForSummary();
        const teamsForSummary = this.getTeamsForSummary();
        this.setState({
          currentTabIndex: index,
          playersForSummary: playersForSummary,
          teamsForSummary: teamsForSummary
        });
        break;
      case isClubEvent(schoolEvent) ? TABS.PROGRESS : TABS.PERFORMANCE:
        this.setState({
          currentTabIndex: index,
          individualPerformance: individualPerformance
        });
        break;
      case TABS.ATTENDANCE:
        this.setState({
          currentTabIndex: index,
          attendancePlayers: attendancePlayers
        });
        break;
      case isClubEvent(event) ? TABS.LEADER_COACH_NOTES : TABS.MATCH_REPORT:
        this.setState({
          isLoading: true
        });
        getSchoolEventMatchReport(user, eventId).then(matchReport => {
          const content = propz.get(matchReport, ['content'], '');
          this.setState({
            currentTabIndex: index,
            matchReport: content,
            isLoading: false
          });
        });
        break;
      default:
        this.setState({
          currentTabIndex: index
        });
    }
  };

  editEventDetailsModalOpen = () => {
    this.setState({
      isEditEventDetailsModalOpen: true
    });
  };

  editEventDetailsModalClose = () => {
    this.setState({
      isEditEventDetailsModalOpen: false
    });
  };

  updateEventDetails = (data, editMode) => {
    const { selectedItems, items } = this.state;
    const schoolEvent = selectedItems[0];
    const eventId = schoolEvent.id;
    const { user } = this.props;

    this.setState({
      isLoading: true,
      isEditEventDetailsModalOpen: false
    });

    let details;

    const singleEditMode = editMode === CLUB_EVENT_EDIT_MODE.SINGLE;
    const getEditEventDetailsService = singleEditMode
      ? updateSchoolEventDetails(user, eventId, data)
      : updateGroupSchoolEventDetails(user, eventId, data);

    getEditEventDetailsService
      .then(_details => {
        details = _details;

        return getSchoolEvent(user, eventId);
      })
      .then(event => {
        const eventsNext = items.map(item => {
          return item.id === eventId ? event : item;
        });

        this.setState({
          isLoading: false,
          eventsDetails: details,
          selectedItems: [event],
          items: eventsNext
        });
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        console.error(error);
        this.setState({
          isLoading: false,
          errorMessage: errorText,
          isErrorModalOpen: true
        });
      });
  };

  renderEventDetailsForm(): React.ReactNode {
    const { isEditEventDetailsModalOpen, eventsDetails, selectedItems } = this.state;
    const { user } = this.props;
    const schoolEvent = selectedItems[0];
    const eventId = propz.get(schoolEvent, ['id'], '');
    return (
      <SimpleModal
        customClass="mSchoolEventDetailsFormWidth"
        isOpen={isEditEventDetailsModalOpen}
        title={'Event details'}
      >
        <SchoolEventDetailsForm
          eventId={eventId}
          user={user}
          onSubmit={this.updateEventDetails}
          onCancel={this.editEventDetailsModalClose}
          eventsDetails={eventsDetails}
          event={schoolEvent}
        />
      </SimpleModal>
    );
  }

  onSavePositionsClick = () => {
    const { playersForSummary, selectedItems, teamsForSummary } = this.state;
    const { user } = this.props;

    const [schoolEvent] = selectedItems;
    const eventId = schoolEvent.id;

    this.setState({
      isLoading: true
    });

    const promise = isNonTeamSportEvent(schoolEvent)
      ? updateSchoolEventIndividualsBatch(user, eventId, {
          add: [],
          remove: [],
          update: playersForSummary.map(player => {
            const { isCaptain, isSub, positionId, playerId } = player;
            return {
              isCaptain: isCaptain,
              sub: isSub,
              positionId: positionId !== '' ? positionId : null,
              id: playerId
            };
          })
        })
      : BPromise.all(
          teamsForSummary.map(teamForSummary =>
            updateSchoolEventTeamPlayersBatch(user, eventId, teamForSummary.teamId, {
              add: [],
              remove: [],
              update: playersForSummary
                .filter(player => player.teamId === teamForSummary.teamId)
                .map(player => {
                  const { isCaptain, isSub, positionId, playerId } = player;
                  return {
                    isCaptain: isCaptain,
                    sub: isSub,
                    positionId: positionId !== '' ? positionId : null,
                    id: playerId
                  };
                })
            })
          )
        );

    promise
      .then(res => {
        return getSchoolEvent(user, eventId);
      })
      .then(event => {
        const playersForSummary = this.getPlayersForSummary(event);
        this.setState({
          isLoading: false,
          isEditPositions: false,
          playersForSummary: playersForSummary,
          selectedItems: [event],
          activeTeamIndex: 0
        });
      });
  };

  onEditPositionsClick = () => {
    this.setState({
      isEditPositions: true
    });
  };

  onChangeTeam = event => {
    const { teamsForSummary } = this.state;
    const teamId = event.target.value;
    const currentTeamIndex = teamsForSummary.findIndex(team => team.teamId === teamId);

    this.setState({
      activeTeamIndex: currentTeamIndex
    });
  };

  onChangeIsSubPlayer = (event, playerId: string): void => {
    const isSub = event.target.checked;
    const { playersForSummary } = this.state;
    const playerIndex = playersForSummary.findIndex(playerForSummary => playerForSummary.playerId === playerId);

    const nextPlayersForSummary = [...playersForSummary];
    nextPlayersForSummary[playerIndex].isSub = isSub;

    this.setState({
      playersForSummary: nextPlayersForSummary
    });
  };

  onChangeIsCaptainPlayer = (event, playerId) => {
    const isCaptain = event.target.checked;
    const { playersForSummary, teamsForSummary, activeTeamIndex } = this.state;
    const playerIndex = playersForSummary.findIndex(playerForSummary => playerForSummary.playerId === playerId);

    const nextPlayersForSummary = [...playersForSummary];

    if (isCaptain) {
      const teamId = teamsForSummary[activeTeamIndex].teamId;
      // ahah old captain, yohoho
      const oldCaptainIndex = playersForSummary.findIndex(player => player.teamId === teamId && player.isCaptain);

      // kill captain, i'm a captain
      if (oldCaptainIndex !== -1) {
        nextPlayersForSummary[oldCaptainIndex].isCaptain = false;
      }
    }

    nextPlayersForSummary[playerIndex].isCaptain = isCaptain;

    this.setState({
      playersForSummary: nextPlayersForSummary
    });
  };

  onChangePositionPlayer = (event, playerId: string): void => {
    const positionValue = event.target.value;

    const { playersForSummary } = this.state;
    const playerIndex = playersForSummary.findIndex(playerForSummary => playerForSummary.playerId === playerId);

    const nextPlayersForSummary = [...playersForSummary];
    nextPlayersForSummary[playerIndex].positionId = positionValue;

    this.setState({
      playersForSummary: nextPlayersForSummary
    });
  };

  getPlayersForSummary(event?: SchoolEvent): SchoolEventPlayerSummary[] {
    const { selectedItems } = this.state;
    const { user } = this.props;
    const { activeSchoolId } = user;
    const schoolEvent = typeof event !== 'undefined' ? event : selectedItems[0];

    const schoolPlayers = getEventPlayers(schoolEvent, activeSchoolId);

    return schoolPlayers.map(schoolPlayer => {
      const { userId, permissionId, firstName, lastName, form, id, teamId } = schoolPlayer;
      const isFormExist = typeof form !== 'undefined';
      const formName = isFormExist ? form.name : '';

      const playerFromEvent = schoolEvent.players.find(
        player => player.userId === userId && player.permissionId === permissionId
      );

      const positionId = typeof playerFromEvent !== 'undefined' ? playerFromEvent.positionId : '';
      const isCaptain = typeof playerFromEvent !== 'undefined' ? playerFromEvent.isCaptain : false;
      const isSub = typeof playerFromEvent !== 'undefined' ? playerFromEvent.sub : false;

      return {
        name: `${firstName} ${lastName}`,
        playerId: id,
        form: formName,
        positionId: positionId,
        isCaptain: isCaptain,
        isSub: isSub,
        teamId: teamId
      };
    });
  }

  getTeamsForSummary(): SchoolEventTeamSummary[] {
    const { selectedItems } = this.state;
    const { user } = this.props;
    const { activeSchoolId } = user;
    const [schoolEvent] = selectedItems;

    return schoolEvent.teamsData
      .filter(team => team.schoolId === activeSchoolId)
      .map(team => ({
        teamName: team.name,
        teamId: team.id,
        houseName: isHousesEvent(schoolEvent) ? team.house.name : undefined
      }));
  }

  onEditEventJobClick = (event, index) => {
    event.preventDefault();

    this.setState({
      isCreateEventJobModalOpen: true,
      eventTaskIndex: index
    });
  };

  deleteJobModalOpen = (event, index): void => {
    this.setState({
      isDeleteJobModalOpen: true,
      eventTaskIndex: index
    });
  };

  deleteJobModalClose = (): void => {
    this.setState({
      isDeleteJobModalOpen: false
    });
  };

  onDeleteJobClick = () => {
    const { user } = this.props;
    const { selectedItems, eventTasks, eventTaskIndex } = this.state;
    const schoolEvent = selectedItems[0];
    const eventId = schoolEvent.id;
    const eventTaskId = eventTasks[eventTaskIndex].id;

    this.setState({
      isDeleteJobModalOpen: false,
      isLoading: true
    });

    onDeleteJob(user, eventId, eventTaskId)
      .then(res => getEventTasks(user, eventId))
      .then(eventTasks => {
        const eventTab = getEventTabs(schoolEvent);
        const tabIndex = eventTab.indexOf(TABS.JOBS);
        this.setState({
          currentTabIndex: tabIndex,
          eventTasks: eventTasks,
          isLoading: false
        });
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        console.error(error);
        this.setState({
          isLoading: false,
          errorMessage: errorText,
          isErrorModalOpen: true
        });
      });
  };

  onAddEventJobClick = event => {
    event.preventDefault();

    this.setState({
      isCreateEventJobModalOpen: true,
      eventTaskIndex: -1
    });
  };

  onCloseEventJobClick = event => {
    event.preventDefault();

    this.setState({
      isCreateEventJobModalOpen: false,
      eventTaskIndex: 0
    });
  };

  onChangeEventPerformanceClick = (value, player, performanceId) => {
    const { individualPerformance } = this.state;

    const performanceIndex = individualPerformance.findIndex(
      performance => performance.performanceId === performanceId && performance.userId === player.userId
    );

    let nextPerformance;

    if (performanceIndex === -1) {
      nextPerformance = [
        ...individualPerformance,
        {
          performanceId: performanceId,
          userId: player.userId,
          teamId: player.teamId,
          value: value,
          permissionId: player.permissionId
        }
      ];
    } else {
      nextPerformance = [...individualPerformance];
      nextPerformance[performanceIndex].value = value;
    }

    this.setState({
      individualPerformance: nextPerformance
    });
  };

  onSaveEventPerformanceClick = () => {
    const { individualPerformance, selectedItems } = this.state;
    const { user } = this.props;
    const [schoolEvent] = selectedItems;
    const eventId = schoolEvent.id;

    this.setState({
      isLoading: true
    });

    const performanceWithoutId = individualPerformance.filter(performance => typeof performance._id === 'undefined');
    const performanceWithId = individualPerformance.filter(performance => typeof performance._id !== 'undefined');

    const performanceWithoutIdPromises = performanceWithoutId.map(data => createEventPerformance(user, eventId, data));
    const performanceWithIdPromises = performanceWithId.map(performance => {
      const updatePerformanceId = performance._id;
      const value = performance.value;

      return updateEventPerformance(user, eventId, updatePerformanceId, { value });
    });

    const promises = [...performanceWithoutIdPromises, ...performanceWithIdPromises];

    BPromise.all(promises)
      .then(res => {
        return getSchoolEvent(user, eventId);
      })
      .then(event => {
        this.setState({
          isLoading: false,
          isEditPerformance: false,
          individualPerformance: event.results.individualPerformance,
          selectedItems: [event]
        });
      });
  };

  onEditEventPerformanceClick = () => {
    this.setState({
      isEditPerformance: true
    });
  };

  onEditEventMatchReportClick = () => {
    this.setState({
      isEditMatchReportActive: false
    });
  };

  onSaveEventMatchReportClick = () => {
    const { user } = this.props;
    const { selectedItems, matchReport } = this.state;
    const [schoolEvent] = selectedItems;
    const eventId = schoolEvent.id;

    this.setState({
      isLoading: true,
      isEditMatchReportActive: true
    });

    const content = {
      content: matchReport
    };

    updateSchoolEventMatchReport(user, eventId, content)
      .then(matchReport => {
        const content = propz.get(matchReport, ['content'], '');

        this.setState({
          isLoading: false,
          matchReport: content
        });
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        console.error(error);
        this.setState({
          isLoading: false,
          errorMessage: errorText,
          isErrorModalOpen: true
        });
      });
  };

  onChangeMatchReport = matchReport => {
    this.setState({
      matchReport: matchReport
    });
  };

  getInitAttendancePlayers = attendancePlayers => {
    const { user } = this.props;
    const { activeSchoolId } = user;
    const { selectedItems } = this.state;
    const [schoolEvent] = selectedItems;
    const allTeamPlayers = getEventPlayers(schoolEvent, activeSchoolId);
    const nextAttendancePlayers = allTeamPlayers.map(player => {
      const attendanceIndex = attendancePlayers.findIndex(attendance => attendance.userId === player.userId);
      if (attendanceIndex === -1) {
        return {
          userId: player.userId,
          isAttendance: true,
          permissionId: player.permissionId
        };
      } else {
        const allAttendancePlayers = attendancePlayers.map(attendance => ({ ...attendance }));
        return allAttendancePlayers[attendanceIndex];
      }
    });
    return nextAttendancePlayers;
  };

  onEditEventAttendanceClick = () => {
    const { selectedItems } = this.state;
    const [schoolEvent] = selectedItems;
    const { results } = schoolEvent;
    const { attendance } = results;
    const attendancePlayers = attendance.map(player => ({ ...player }));

    const allAttendancePlayers = this.getInitAttendancePlayers(attendancePlayers);

    const isOneAttendancePresent = allAttendancePlayers.every(player => {
      return propz.get(player, ['isAttendance'], true);
    });

    this.setState({
      attendancePlayers: allAttendancePlayers,
      isEditAllAttendance: isOneAttendancePresent,
      isEditAttendance: true
    });
  };

  onCancelEventAttendanceClick = () => {
    const { selectedItems } = this.state;
    const [schoolEvent] = selectedItems;
    const attendance = schoolEvent.results.attendance;
    const attendancePlayers = attendance.map(player => ({ ...player }));

    this.setState({
      attendancePlayers: attendancePlayers,
      isEditAttendance: false
    });
  };

  onSaveEventAttendanceClick = () => {
    const { attendancePlayers, selectedItems } = this.state;
    const { user } = this.props;
    const [schoolEvent] = selectedItems;
    const eventId = schoolEvent.id;

    this.setState({
      isLoading: true
    });

    const attendanceWithoutId = attendancePlayers.filter(attendance => typeof attendance._id === 'undefined');
    const attendanceWithId = attendancePlayers.filter(attendance => typeof attendance._id !== 'undefined');

    const attendanceWithoutIdPromises = attendanceWithoutId.map(data => createEventAttendance(user, eventId, data));
    const attendanceWithIdPromises = attendanceWithId.map(attendance => {
      const updateAttendanceId = attendance._id;
      const isAttendance = attendance.isAttendance;

      return updateEventAttendance(user, eventId, updateAttendanceId, { isAttendance });
    });

    const promises = [...attendanceWithoutIdPromises, ...attendanceWithIdPromises];

    BPromise.all(promises)
      .then(res => {
        return getSchoolEvent(user, eventId);
      })
      .then(event => {
        this.setState({
          isLoading: false,
          isEditAttendance: false,
          attendancePlayers: event.results.attendance,
          selectedItems: [event]
        });
      });
  };

  getFilterOptions(forms, sports) {
    const user = this.props.user;
    const activeSchool = user.activeSchool;
    const ageGroupsNaming = activeSchool.ageGroupsNaming;

    return {
      venueType: getSelectOptionForEventVenueType(),
      eventAges: getSelectOptionForAge(forms, ageGroupsNaming),
      gender: getSelectOptionForTournamentEventGender(),
      status: getSelectOptionForTournamentEventStatus(),
      eventType: getSelectOptionForEventTypes(),
      eventSport: getSelectOptionForSport(sports)
    };
  }

  onChangeEventAllAttendanceClick = (value: boolean): void => {
    const { user } = this.props;
    const { activeSchoolId } = user;
    const { selectedItems, attendancePlayers } = this.state;
    const [schoolEvent] = selectedItems;
    const allTeamPlayers = getEventPlayers(schoolEvent, activeSchoolId);

    const nextAttendancePlayers = allTeamPlayers.map(player => {
      const attendanceIndex = attendancePlayers.findIndex(attendance => attendance.userId === player.userId);
      if (attendanceIndex === -1) {
        return {
          userId: player.userId,
          isAttendance: value,
          permissionId: player.permissionId
        };
      } else {
        const allAttendancePlayers = attendancePlayers.map(attendance => {
          return {
            ...attendance,
            isAttendance: value
          };
        });
        return allAttendancePlayers[attendanceIndex];
      }
    });
    this.setState({
      isEditAllAttendance: value,
      attendancePlayers: nextAttendancePlayers
    });
  };

  onChangeEventAttendanceClick = (
    value: boolean,
    player: SchoolEventIndividualData | SchoolEventTeamDataPlayer
  ): void => {
    const { attendancePlayers } = this.state;

    const attendanceIndex = attendancePlayers.findIndex(attendance => attendance.userId === player.userId);

    let nextAttendancePlayers;

    if (attendanceIndex === -1) {
      nextAttendancePlayers = [
        ...attendancePlayers,
        {
          userId: player.userId,
          isAttendance: value,
          permissionId: player.permissionId
        }
      ];
    } else {
      nextAttendancePlayers = attendancePlayers.map(attendance => {
        return attendance.userId === player.userId
          ? {
              ...attendance,
              isAttendance: value
            }
          : { ...attendance };
      });
    }

    const isOneAttendancePresent = nextAttendancePlayers.every(player => {
      return propz.get(player, ['isAttendance'], true);
    });

    this.setState({
      isEditAllAttendance: isOneAttendancePresent,
      attendancePlayers: nextAttendancePlayers
    });
  };

  goBack = () => {
    const { history, location } = this.props;
    const { state } = location;
    const { search } = state as any;
    const leagueId = this.getLeagueId();

    history.push({
      pathname: '/leagues',
      search: search,
      state: { leagueId: leagueId }
    });
  };

  getSummaryTabs(): React.ReactNode {
    const { user } = this.props;
    const { activeSchoolId } = user;
    const {
      selectedItems,
      eventsDetails,
      currentTabIndex,
      sports,
      isEditPositions,
      activeTeamIndex,
      playersForSummary,
      teamsForSummary,
      eventTasks,
      individualPerformance,
      isEditPerformance,
      isEditAttendance,
      attendancePlayers,
      matchReport,
      isEditMatchReportActive,
      isEditAllAttendance
    } = this.state;
    const event = selectedItems[0];
    const currentSport = sports.find(sport => sport.id === event.sportId);
    const allTeamPlayers = getEventPlayers(event, activeSchoolId);

    const tabs = getEventTabs(event);
    const currentTab = tabs[currentTabIndex];

    switch (currentTab) {
      case TABS.SUMMARY:
        return <SchoolEventSummary schoolEvent={event} user={user} />;
      case TABS.DETAILS:
        return <SchoolEventDetailsSummary schoolEvent={event} eventsDetails={eventsDetails} user={user} />;
      case TABS.JOBS:
        return (
          <SchoolEventJobsSummary
            eventTasks={eventTasks}
            onEditEventJobClick={this.onEditEventJobClick}
            onDeleteJobClick={this.deleteJobModalOpen}
          />
        );
      case isClubEvent(event) ? TABS.PROGRESS : TABS.PERFORMANCE:
        return (
          <SchoolEventPerformanceSummary
            schoolEvent={event}
            allTeamPlayers={allTeamPlayers}
            onEditEventPerformanceClick={this.onChangeEventPerformanceClick}
            individualPerformance={individualPerformance}
            isEditPerformance={isEditPerformance}
          />
        );
      case TABS.ATTENDANCE:
        return (
          <SchoolEventAttendanceSummary
            schoolEvent={event}
            allTeamPlayers={allTeamPlayers}
            onChangeEventAttendanceClick={this.onChangeEventAttendanceClick}
            onChangeEventAllAttendanceClick={this.onChangeEventAllAttendanceClick}
            attendancePlayers={attendancePlayers}
            isEditAttendance={isEditAttendance}
            isEditAllAttendance={isEditAllAttendance}
          />
        );
      case TABS.PARTICIPANTS:
        return (
          <SchoolEventPlayersSummary
            schoolEvent={event}
            sport={currentSport}
            players={playersForSummary}
            teams={teamsForSummary}
            activeTeamIndex={activeTeamIndex}
            isEditPositions={isEditPositions}
            onChangeTeam={this.onChangeTeam}
            onChangePositionPlayer={this.onChangePositionPlayer}
            onChangeIsCaptainPlayer={this.onChangeIsCaptainPlayer}
            onChangeIsSubPlayer={this.onChangeIsSubPlayer}
          />
        );
      case isClubEvent(event) ? TABS.LEADER_COACH_NOTES : TABS.MATCH_REPORT:
        return (
          <SchoolEventMatchReportSummary
            isEditMatchReportActive={isEditMatchReportActive}
            schoolEvent={event}
            matchReport={matchReport}
            onChangeMatchReport={this.onChangeMatchReport}
          />
        );
      default:
        return <EmptyComponent />;
    }
  }

  renderSummary() {
    const {
      selectedItems,
      currentTabIndex,
      isEditPositions,
      isEditPerformance,
      isEditAttendance,
      isEditMatchReportActive
    } = this.state;
    const { user } = this.props;
    const { activeSchoolId } = user;
    const isOneItemSelected = selectedItems.length === 1;

    if (!isOneItemSelected) {
      return <div className="font-weight-bold">Select a event above</div>;
    }
    const event = selectedItems[0];
    const players = getEventPlayers(event, activeSchoolId);

    const summaryTabs = this.getSummaryTabs();

    const tabs = getEventTabs(event);
    const currentTab = tabs[currentTabIndex];

    const isShowEditDetailsButton = currentTab === TABS.DETAILS;
    const isShowAddJobButton = currentTab === TABS.JOBS;
    const isShowEditPositionsButton = !isEditPositions && !isClubEvent(event) && currentTab === TABS.PARTICIPANTS;
    const isShowSavePositionsButton = isEditPositions && !isClubEvent(event) && currentTab === TABS.PARTICIPANTS;
    const isPlayersExist = players.length > 0;

    const isTabProgress = currentTab === TABS.PROGRESS;
    const isTabPerformance = currentTab === TABS.PERFORMANCE;
    const isTabProgressOrPerformance = isTabProgress || isTabPerformance;
    const isShowEditPerformanceButton = !isEditPerformance && isTabProgressOrPerformance;
    const isShowSavePerformanceButton = isEditPerformance && isTabProgressOrPerformance;

    const isShowEditAttendanceButton = !isEditAttendance && currentTab === TABS.ATTENDANCE;
    const isShowSaveAttendanceButton = isEditAttendance && currentTab === TABS.ATTENDANCE;

    const isTabLeaderAndCoach = currentTab === TABS.LEADER_COACH_NOTES;
    const isTabMatchReport = currentTab === TABS.MATCH_REPORT;
    const isTabbLeaderCoachOrMatchReport = isTabLeaderAndCoach || isTabMatchReport;
    const isShowEditMatchReportButton = isEditMatchReportActive && isTabbLeaderCoachOrMatchReport;
    const isShowSaveMatchReportButton = !isEditMatchReportActive && isTabbLeaderCoachOrMatchReport;

    return (
      <GenericSummary onTabClick={this.onTabClick} tabs={tabs} currentTabIndex={currentTabIndex}>
        {isShowEditPositionsButton && (
          <Button
            onClick={this.onEditPositionsClick}
            text={'Edit positions'}
            customClass={'mt-3 mb-3 ml-3'}
            disabled={!isPlayersExist}
          />
        )}
        {isShowSavePositionsButton && (
          <Button
            onClick={this.onSavePositionsClick}
            text={'Save positions'}
            customClass={'mt-3 mb-3 ml-3'}
            disabled={!isPlayersExist}
          />
        )}
        {isShowEditDetailsButton && (
          <Button onClick={this.editEventDetailsModalOpen} text={'Edit details'} customClass={'mt-3 mb-3 ml-3'} />
        )}
        {isShowAddJobButton && (
          <Button onClick={this.onAddEventJobClick} text={'Add job'} customClass={'mt-3 mb-3 ml-3'} />
        )}
        {isShowEditPerformanceButton && (
          <Button
            onClick={this.onEditEventPerformanceClick}
            text={isClubEvent(event) ? 'Edit progress' : 'Edit performance'}
            customClass={'mt-3 mb-3 ml-3'}
            disabled={!isPlayersExist}
          />
        )}
        {isShowSavePerformanceButton && (
          <Button
            onClick={this.onSaveEventPerformanceClick}
            text={isClubEvent(event) ? 'Save progress' : 'Save performance'}
            customClass={'mt-3 mb-3 ml-3'}
          />
        )}
        {isShowEditAttendanceButton && (
          <Button
            onClick={this.onEditEventAttendanceClick}
            text={'Edit attendance'}
            customClass={'mt-3 mb-3 ml-3'}
            disabled={!isPlayersExist}
          />
        )}
        {isShowSaveAttendanceButton && (
          <>
            <Button
              onClick={this.onCancelEventAttendanceClick}
              text={'Cancel'}
              customClass={'mt-3 mb-3 ml-3 btn-secondary'}
            />
            <Button onClick={this.onSaveEventAttendanceClick} text={'Save'} customClass={'mt-3 mb-3 ml-3'} />
          </>
        )}
        {isShowEditMatchReportButton && (
          <Button
            onClick={this.onEditEventMatchReportClick}
            text={isClubEvent(event) ? 'Edit Leader/Coach notes' : 'Edit match report'}
            customClass={'mt-3 mb-3 ml-3'}
            disabled={!isEditMatchReportActive}
          />
        )}
        {isShowSaveMatchReportButton && (
          <Button
            onClick={this.onSaveEventMatchReportClick}
            text={isClubEvent(event) ? 'Save Leader/Coach notes' : 'Save match report'}
            customClass={'mt-3 mb-3 ml-3'}
          />
        )}
        {summaryTabs}
      </GenericSummary>
    );
  }

  renderGrid() {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isSelectAllChecked,
      currentPage,
      total,
      filterOptions,
      isEditPerformance,
      isEditAttendance,
      isEditPositions,
      league
    } = this.state;

    const actionItems = [
      {
        itemText: 'Add to league',
        onItemClick: this.onAddEventToLeagueClick,
        isActive: selectedItems.length > 0
      }
    ];

    const { user } = this.props;
    const gridTitle = `Adding events to the league ${league.name}`;

    return (
      <div className="row">
        <div className="col-md-12">
          <Grid2
            dataItems={items}
            filters={filters}
            currentPage={currentPage}
            total={total}
            isSelectAllChecked={isSelectAllChecked}
            isDataFiltered={isDataFiltered}
            sortDirection={sortDirection}
            sortColumnsName={sortColumnsName}
            isShowFilter={isShowFilter}
            dataItemsSelected={selectedItems}
            columns={COLUMNS}
            actionItems={actionItems}
            options={filterOptions}
            onItemClick={this.onItemClick}
            onSortClick={this.onTableSortClick}
            onApplyFilterClick={this.onApplyFilterClick}
            onClearFilterClick={this.onClearFilterClick}
            onTableFilterChange={this.onTableFilterChange}
            onTableFilterClick={this.onTableFilterClick}
            setCurrentPageParams={this.setCurrentPageParams}
            onSelectAllClick={this.onSelectAllClick}
            onSelectAllOnPageClick={this.onSelectAllOnPageClick}
            onUnselectAllClick={this.onUnselectAllClick}
            goBack={this.goBack}
            gridTitle={gridTitle}
            user={user}
            isSelectDisabled={isEditPerformance || isEditAttendance || isEditPositions}
          />
        </div>
      </div>
    );
  }

  render() {
    const {
      isErrorModalOpen,
      isCreateEventModalOpen,
      isEditEventDetailsModalOpen,
      isCreateEventJobModalOpen,
      isLoading
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes =
      isErrorModalOpen || isCreateEventModalOpen || isEditEventDetailsModalOpen || isCreateEventJobModalOpen
        ? 'mt-3 modal-open'
        : 'mt-3';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderEventJobForm()}
        {this.renderDeleteJobModal()}
        {this.renderEventDetailsForm()}
        {this.renderSendingError()}
        <div className="row">
          <div className="col-md-12">
            <ResizablePanel>
              {this.renderGrid()}
              {this.renderSummary()}
            </ResizablePanel>
          </div>
        </div>
      </div>
    );
  }
}
