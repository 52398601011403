import * as React from 'react';
import { Select } from '../../../../components/Select/Select';
import { getOptionsForYearDropdown } from '../../../../helpers/calendar/calendar';

interface Props {
  activeYearIndex: number;
  onYearChange: (value: number) => void;
}

export function YearDropdown(props: Props) {
  const { onYearChange, activeYearIndex } = props;
  const options = getOptionsForYearDropdown();

  return (
    <Select
      customClass="eYearDropdown"
      isSetDefaultValue={false}
      onChange={onYearChange}
      activeResultIndex={activeYearIndex}
      options={options}
    />
  );
}
