import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Button } from 'Src/components/Button/Button';
import { Loader } from 'Src/components/Loader/Loader';
import { AppUser } from 'Src/views/App/App';
import { readStudentsFromCSVFile, pullFormsHouses } from 'Src/helpers/utils/csvUtils';
import { uploadStudents, bulkDeleteStudentsBySchoolId } from 'Src/helpers/service/admin/students';
import { deleteForm, getAllForms } from 'Src/helpers/service/admin/forms';
import { deleteHouse, getAllHouses } from 'Src/helpers/service/admin/houses';
import { getSchoolStudentsCount } from 'Src/helpers/service/admin/students';
import { ImportError } from 'Src/helpers/utils/types';
import { Formik, Form, Field } from 'formik';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { Switch } from 'Src/components/Switch/Switch';
import './ImportStudents.scss';

interface School {
  id: string;
  name: string;
  studentImportForAdminAllowed: boolean;
}

interface Form {
  id: string;
  name: string;
}

interface House {
  id: string;
  name: string;
}

interface ImportStudentsProps {
  user: AppUser;
  school: School;
  forms: Form[];
  houses: House[];
}

export const ImportStudents: React.FC<ImportStudentsProps> = ({ user, school, forms, houses }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<ImportError[]>([]);
  const [studentsToUpload, setStudentsToUpload] = useState<any[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [removeExistingStudents, setRemoveExistingStudents] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  useEffect(() => {
    if (!school) {
      console.error('School data is missing or incomplete.');
    } else if (!school.id) {
      console.error('School ID is missing in the school data:', school);
    }
  }, [school, forms, houses]);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (!selectedFile) return;

    setErrors([]);

    if (selectedFile.type !== 'text/csv') {
      setErrors([{ type: 'FileTypeError', code: 'FILE_TYPE_ERROR', message: 'Please upload a CSV file.' }]);
      setFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      return;
    }

    const maxSize = 5 * 1024 * 1024; // 5MB
    if (selectedFile.size > maxSize) {
      setErrors([{ type: 'FileSizeError', code: 'FILE_SIZE_ERROR', message: 'File size exceeds 5MB limit.' }]);
      setFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      return;
    }

    setFile(selectedFile);
    setIsLoading(true);
    try {
      const result = await readStudentsFromCSVFile(selectedFile);
      setErrors(result.errors || []);
      setStudentsToUpload(result.students || []);

      if (result.errors.length > 0 && fileInputRef.current) {
        fileInputRef.current.value = '';
        setFile(null);
      }
    } catch (err) {
      if (err instanceof Error) {
        setErrors([{ type: 'FileReadError', code: 'FILE_READ_ERROR', message: err.message }]);
      } else {
        setErrors([{ type: 'FileReadError', code: 'FILE_READ_ERROR', message: 'An unknown error occurred.' }]);
      }

      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      setFile(null);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUploadClick = async (values: any) => {
    if (!file) {
      alert('Please select a file to upload.');
      return;
    }

    if (!school || !school.id) {
      alert('School data is missing or incomplete.');
      return;
    }

    setIsLoading(true);

    try {
      if (values.removeExistingStudents) {
        await bulkDeleteStudentsBySchoolId(user);
      }

      const result = await readStudentsFromCSVFile(file);
      const processedResult = await pullFormsHouses(result, school, user);

      await uploadStudents(user, processedResult.students);

      const fetchedForms = await getAllForms(user);
      const deleteFormPromises = fetchedForms.map(async form => {
        const studentCount = await getSchoolStudentsCount(user, { formId: form.id });
        if (studentCount.count === 0) {
          return deleteForm(user, form.id);
        }
      });
      await Promise.all(deleteFormPromises);

      const fetchedHouses = await getAllHouses(user);
      const deleteHousePromises = fetchedHouses.map(async house => {
        const studentCount = await getSchoolStudentsCount(user, { houseId: house.id });
        if (studentCount.count === 0) {
          return deleteHouse(user, house.id);
        }
      });
      await Promise.all(deleteHousePromises);

      setStudentsToUpload([]);
      setFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }

      setModalMessage('Students have been successfully uploaded.');
      setIsSuccessModalOpen(true);
    } catch (error) {
      if (error instanceof Error) {
        setErrors(prevErrors => [...prevErrors, { type: 'UploadError', code: 'UPLOAD_ERROR', message: error.message }]);
      } else {
        setErrors(prevErrors => [
          ...prevErrors,
          { type: 'UploadError', code: 'UPLOAD_ERROR', message: 'An unknown error occurred during upload.' }
        ]);
      }
      alert('Some students failed to upload. Please check the errors.');
    } finally {
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    setIsSuccessModalOpen(false);
  };

  if (school && !school.studentImportForAdminAllowed) {
    return (
      <div className="import-students-container">
        <h3>Import Students</h3>
        <div className="import-disabled-message">
          <p>
            Student import is currently disabled for your school account. If you believe this is an error or need
            assistance with importing students, please contact our support team.
          </p>
        </div>
      </div>
    );
  }

  return (
    <Formik
      initialValues={{
        removeExistingStudents: false
      }}
      onSubmit={values => handleUploadClick(values)}
    >
      {({ setFieldValue, values }) => (
        <Form className="import-students-container">
          <h3>Import Students</h3>

          <section className="import-instructions">
            <p>
              Use this tool to bulk import students. <strong className="important-text">Important:</strong> You have the
              option to either remove all existing students or retain them. Please read the following carefully before
              proceeding:
            </p>

            <h5>If You Choose to Remove All Existing Students:</h5>
            <p>
              Selecting the "Remove all existing students" option will <strong>delete all existing students</strong>,
              their participation history, and linked parental accounts. This action is <strong>permanent</strong> and
              cannot be undone. If you wish to retain any historical data or participation records, please contact our
              support team at <a href="mailto:support@squadintouch.com">support@squadintouch.com</a> before proceeding.
            </p>

            <h5>If You Choose to Retain Existing Students:</h5>
            <p>
              If you opt to <strong>not remove existing students</strong>, it is your responsibility to ensure there are
              no duplicate records. The system will attempt to import new students alongside existing ones, but
              duplicates will not be automatically removed. Please review and manage student records for duplicates or
              errors manually after the import. If you require assistance with this process, our support team is
              available to help.
            </p>
            <h5>Required Fields</h5>
            <p>Your CSV must include the following columns:</p>
            <ul>
              <li>
                <strong>Name</strong>
              </li>
              <li>
                <strong>Surname</strong>
              </li>
              <li>
                <strong>Gender</strong>
              </li>
              <li>
                <strong>Year</strong>
              </li>
            </ul>

            <h5>Optional Fields</h5>
            <p>
              You may also add student forms, date of birth (required for tournaments), and house. Note that medical or
              emergency contacts cannot be uploaded. For additional details, contact support.
            </p>

            <h5>Resources</h5>
            <ul>
              <li>
                <a href="/dist/images/templates/importStudentsTemplate.csv" target="_blank" rel="noopener noreferrer">
                  CSV Template
                </a>
              </li>
              <li>
                <a href="/dist/images/templates/ImportStudentsGuide.pdf" target="_blank" rel="noopener noreferrer">
                  Quick PDF Guide
                </a>
              </li>
            </ul>

            <p>
              Ensure your file is saved in <strong>.csv</strong> format.
            </p>

            <p>The import process may take some time. You can navigate away from this page while uploading.</p>
          </section>

          <section className="import-file-upload">
            <h5>Upload CSV File</h5>
            <div className="file-upload-input">
              <input
                type="file"
                id="student-file"
                name="file"
                onChange={handleFileChange}
                ref={fileInputRef}
                accept=".csv"
                style={{ display: 'none' }}
              />
              <button
                type="button"
                onClick={() => {
                  if (fileInputRef.current) {
                    fileInputRef.current.click();
                  }
                }}
                className="btn btn-primary my-2"
              >
                Select CSV File
              </button>
            </div>

            <div
              className="remove-existing-students-switch"
              style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}
            >
              <label htmlFor="removeExistingStudents" className="form-check-label" style={{ marginRight: '10px' }}>
                Remove all existing students
              </label>
              <Field
                name="removeExistingStudents"
                render={({ field }) => (
                  <Switch
                    {...field}
                    checked={values.removeExistingStudents}
                    onChange={() => setFieldValue('removeExistingStudents', !values.removeExistingStudents)}
                  />
                )}
              />
            </div>

            {isLoading && (
              <div className="loading-indicator">
                <Loader />
                <p>Processing your file. Please wait...</p>
              </div>
            )}

            {!isLoading && (
              <>
                {errors.length > 0 && (
                  <div className="error-messages">
                    <p className="error-title">Errors detected in your file:</p>
                    <ul>
                      {errors.map((error: ImportError, idx: number) => (
                        <li key={idx} className="error-item">
                          {error.message}
                        </li>
                      ))}
                    </ul>
                    <p>Please correct the errors and upload the updated CSV file.</p>
                  </div>
                )}
                {studentsToUpload.length > 0 && (
                  <div className="upload-summary">
                    <p>Number of students to be uploaded: {studentsToUpload.length}</p>
                    <Button type="submit" text="Upload Students" onClick={() => {}} />
                  </div>
                )}
              </>
            )}
          </section>

          {isSuccessModalOpen && (
            <SimpleModal
              isOpen={isSuccessModalOpen}
              title="Success"
              body={modalMessage}
              buttonText="Close"
              onButtonClick={closeModal}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};
