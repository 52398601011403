import * as React from 'react';
import * as propz from 'propz';
import { FunctionComponent } from 'react';
import { getFullName } from 'Src/helpers/autocomplete/autocomplete';
import { AppUser } from 'Src/views/App/App';

interface Props {
  eventTasks: any[];
  user: AppUser;
}

export const StudentEventJobsSummaryTab: FunctionComponent<Props> = props => {
  const { eventTasks, user } = props;

  const rows = eventTasks.map((eventTask, index) => {
    const { assignees } = eventTask;
    const studentId = propz.get(user, ['profile', 'id'], '');

    const studentAmongAssignees: any = assignees.find(assignee => {
      const { userId } = assignee;
      return studentId === userId;
    });

    const isStudentAmongAssigneesExist = typeof studentAmongAssignees !== 'undefined';

    const studentFullName = getFullName(studentAmongAssignees);

    return (
      isStudentAmongAssigneesExist && (
        <tr key={`jobs_summary_${index}`}>
          <td>{index + 1}</td>
          <td className="">{studentFullName}</td>
          <td className="">{eventTask.text}</td>
        </tr>
      )
    );
  });

  const emptyRow = (
    <tr key={'events_empty_row'}>
      <td>No jobs</td>
    </tr>
  );

  return (
    <table className={'table table-sm'}>
      <thead>
        <tr>
          <th>#</th>
          <th>Players</th>
          <th>Job description</th>
        </tr>
      </thead>
      <tbody>{rows.length > 0 ? rows : emptyRow}</tbody>
    </table>
  );
};

StudentEventJobsSummaryTab.displayName = 'StudentEventJobsSummaryTab';
