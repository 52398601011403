import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import * as propz from 'propz';
import * as classNames from 'classnames';
import { TournamentAnnouncement } from 'Src/models/event';
import './Announce.scss';

interface Props {
  item: TournamentAnnouncement;
}

export const Announce = (props: Props) => {
  const { item } = props;

  const [isShowMore, setShowMore] = useState<boolean>(false);
  const [announceBodyContentHeight, setAnnounceBodyContentHeight] = useState<number>(0);
  const announceBodyContentRef = useRef(null);

  const title = propz.get(item, ['title'], '');
  const body = propz.get(item, ['body'], '');
  const attachments = propz.get(item, ['attachments']);
  const attachmentsImages = propz.get(attachments, ['images'], []);
  const attachmentsFiles = propz.get(attachments, ['files'], []);
  const isAttachmentsImagesExist = attachmentsImages.length > 0 && attachmentsImages[0].url !== '';
  const isAttachmentsFilesExist = attachmentsFiles.length > 0;
  const announcePictureUrl = isAttachmentsImagesExist && attachmentsImages[0].url;

  const bodyContent = body.slice(body.lastIndexOf('<body>') + 6, body.indexOf('</body>'));
  const isBodyExist = typeof body !== 'undefined' && bodyContent.trim() !== '';

  useEffect(() => {
    if (isBodyExist) {
      setAnnounceBodyContentHeight(announceBodyContentRef.current.clientHeight);
    }
  }, []);

  const isGreaterThanSpecifiedHeight = announceBodyContentHeight > 200;

  const announceBodyClasses = classNames({
    eAnnounceBody: true,
    eAnnounceBodyOpen: isShowMore
  });

  const announceBodyContentClasses = classNames({
    eAnnounceBodyContent: true,
    eAnnounceBodyFullContent: isGreaterThanSpecifiedHeight
  });

  return (
    <div className="bAnnounce">
      <div className="eAnnounceHeader">
        {isAttachmentsImagesExist && <img className="eAnnouncePicture" src={announcePictureUrl} alt="Announce pic" />}

        <h4 className="eAnnounceTitle">{title}</h4>
      </div>

      {isBodyExist && (
        <div className={announceBodyClasses}>
          <div
            className={announceBodyContentClasses}
            ref={announceBodyContentRef}
            dangerouslySetInnerHTML={{ __html: body }}
          />

          {isGreaterThanSpecifiedHeight && (
            <div className="text-right">
              <span className="eAnnounceBodyShowMoreButton" onClick={() => setShowMore(!isShowMore)}>
                {isShowMore ? 'Show less' : 'Show more'}
              </span>
            </div>
          )}
        </div>
      )}

      {isAttachmentsFilesExist && (
        <>
          <p className="mb-0">Attachments:</p>
          {attachmentsFiles.map(item => {
            const { url, name, _id: itemKey } = item;
            const link = `${window.apiBaseFile}${url}`;

            return (
              <div key={itemKey}>
                <a className="d-inline-block" href={link} target="_blank">
                  {name}
                </a>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

Announce.displayName = 'Announce';
