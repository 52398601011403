import * as React from 'react';
import { FunctionComponent } from 'react';
import * as Moment from 'moment';
import * as propz from 'propz';

import { TOURNAMENT_TYPE_SERVER_TO_CLIENT_MAPPING } from '../../consts/tournament';
import { Championship } from '../../models/championship';

interface Props {
  championship: Championship;
}

export const ChampionshipTournamentsSummary: FunctionComponent<Props> = props => {
  const tournaments = props.championship.tournaments || [];

  const rows = tournaments.map((tournament, index) => {
    const tournamentName = propz.get(tournament, ['name'], '');
    const tournamentType = tournament.type ? TOURNAMENT_TYPE_SERVER_TO_CLIENT_MAPPING[tournament.type] : '';
    const tournamentStartDate = new Date(tournament.startTime);
    const tournamentStartTime =
      typeof tournamentStartDate !== 'undefined' ? Moment(tournamentStartDate).format('DD.MM.YYYY HH:mm') : '';
    const tournamentEndDate = new Date(tournament.startTime);
    const tournamentEndTime =
      typeof tournamentEndDate !== 'undefined' ? Moment(tournamentEndDate).format('DD.MM.YYYY HH:mm') : '';
    const sportName = propz.get(tournament, ['sport', 'name'], '');
    const tournamentDomain = propz.get(tournament, ['domain'], '');

    return (
      <tr key={`championship_tournaments_summary_${index}`}>
        <td>{index + 1}</td>
        <td>{tournamentName}</td>
        <td>{tournamentType}</td>
        <td>{sportName}</td>
        <td>{tournamentStartTime}</td>
        <td>{tournamentEndTime}</td>
        <td>{tournamentDomain}</td>
      </tr>
    );
  });

  const emptyRow = (
    <tr key={'tournaments_empty_row'}>
      <td>No tournaments</td>
    </tr>
  );

  return (
    <table className={'table table-sm mPre'}>
      <thead>
        <tr>
          <th />
          <th>Tournament</th>
          <th>Type</th>
          <th>Sport name</th>
          <th>Start time</th>
          <th>End time</th>
          <th>Domain</th>
        </tr>
      </thead>
      <tbody>{rows.length > 0 ? rows : emptyRow}</tbody>
    </table>
  );
};

ChampionshipTournamentsSummary.displayName = 'ChampionshipTournamentsSummary';
