import * as React from 'react';
import { Component } from 'react';
import * as propz from 'propz';
import { Loader } from 'Src/components/Loader/Loader';
import { AppUser } from 'Src/views/App/App';
import { History, Location } from 'history';
import {
  getSchoolGallery,
  addPhotoToSchoolGallery,
  getKeyToAddPhoto,
  deletePhotoFromSchoolGallery,
  changeSchoolPhotoInfo
} from 'Src/helpers/service/admin/schoolGallery';
import { Photo } from 'Src/models/photo';
import { Button } from 'Src/components/Button/Button';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { AddPhotoForm } from './AddPhotoForm/AddPhotoForm';
import { FullScreenPhoto } from './FullScreenPhoto/FullScreenPhoto';
import { GALLERY_MODE } from 'Src/consts/album';
import { SchoolGalleryPhoto } from './SchoolGalleryPhoto/SchoolGalleryPhoto';
import './SchoolGallery.scss';

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

interface State {
  mode: string;
  photos: Photo[];
  photosCount: number;
  fullScreenPhotoId: string;
  isAddPhotoModalOpen: boolean;
  isLoading: boolean;
}

export class SchoolGallery extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      mode: GALLERY_MODE.PREVIEW,
      photos: [],
      photosCount: 0,
      fullScreenPhotoId: '',
      isAddPhotoModalOpen: false,
      isLoading: false
    };
  }

  componentDidMount() {
    this.load();
  }

  load = () => {
    this.setState({
      isLoading: true
    });

    const { user } = this.props;
    const albumId = propz.get(user, ['activeSchool', 'defaultAlbumId'], '');

    getSchoolGallery(user, albumId).then(photos => {
      this.setState({
        photos,
        photosCount: photos.length,
        isLoading: false
      });
    });
  };

  renderAddPhotoModal = () => {
    const { isAddPhotoModalOpen } = this.state;

    return (
      <SimpleModal isOpen={isAddPhotoModalOpen} title="Add photo">
        <AddPhotoForm onCancel={this.closeAddPhotoModal} onSubmit={this.onSubmitAddPhotoForm} />
      </SimpleModal>
    );
  };

  onSubmitAddPhotoForm = (imageBlob: any) => {
    const { user } = this.props;
    const albumId = propz.get(user, ['activeSchool', 'defaultAlbumId'], '');
    const photoAccessPreset = propz.get(user, ['activeSchool', 'photoAccessPreset'], '');

    const imageBlobParts = imageBlob.name.split('.');
    const imageBlobType = imageBlobParts[imageBlobParts.length - 1];

    const formData = new FormData();
    formData.append('image', imageBlob, `newFile.${imageBlobType}`);

    this.setState({
      isLoading: true,
      isAddPhotoModalOpen: false
    });

    getKeyToAddPhoto(user, formData)
      .then(keyObj => {
        const { key } = keyObj;
        const data = {
          name: '',
          description: '',
          picUrl: `${window.apiImg}/images/${key}`,
          accessPreset: photoAccessPreset
        };

        return addPhotoToSchoolGallery(user, albumId, data);
      })
      .then(() => {
        this.load();
      });
  };

  openAddPhotoModal = () => {
    this.setState({
      isAddPhotoModalOpen: true
    });
  };

  closeAddPhotoModal = () => {
    this.setState({
      isAddPhotoModalOpen: false
    });
  };

  getFullScreenPhotoIndex() {
    const { photos, fullScreenPhotoId } = this.state;
    return photos.findIndex(photo => photo.id === fullScreenPhotoId);
  }

  onPhotoClick = (id: string): void => {
    this.setState({
      mode: GALLERY_MODE.FULLSCREEN,
      fullScreenPhotoId: id
    });
  };

  onCloseFullScreenPhotoClick = (): void => {
    this.setState({
      mode: GALLERY_MODE.PREVIEW,
      fullScreenPhotoId: ''
    });
  };

  onPrevPhotoClick = () => {
    const { photos } = this.state;
    const currentPhotoIndex = this.getFullScreenPhotoIndex();

    const fullScreenPhotoId = currentPhotoIndex === 0 ? photos[photos.length - 1].id : photos[currentPhotoIndex - 1].id;

    this.setState({
      fullScreenPhotoId
    });
  };

  onNextPhotoClick = () => {
    const { photos } = this.state;
    const currentPhotoIndex = this.getFullScreenPhotoIndex();

    let fullScreenPhotoId = currentPhotoIndex === photos.length - 1 ? photos[0].id : photos[currentPhotoIndex + 1].id;

    this.setState({
      fullScreenPhotoId
    });
  };

  onDeletePhotoClick = photoId => {
    const { user } = this.props;
    const albumId = propz.get(user, ['activeSchool', 'defaultAlbumId'], '');

    this.setState({
      isLoading: true
    });

    deletePhotoFromSchoolGallery(user, albumId, photoId).then(() => {
      this.onCloseFullScreenPhotoClick();
      this.load();
    });
  };

  onChangeSchoolPhotoInfoClick = (id, data) => {
    const { user } = this.props;
    const albumId = propz.get(user, ['activeSchool', 'defaultAlbumId'], '');

    this.setState({
      isLoading: true
    });

    changeSchoolPhotoInfo(user, albumId, id, data).then(() => {
      this.load();
    });
  };

  renderSchoolGalleryPhotos() {
    const { photos } = this.state;

    return (
      <div className="bSchoolGalleryPhotos">
        {photos.map(photo => {
          const { id } = photo;
          return <SchoolGalleryPhoto key={id} photo={photo} onPhotoClick={this.onPhotoClick} />;
        })}
      </div>
    );
  }

  renderFullScreenPhoto() {
    const { mode, photos, fullScreenPhotoId } = this.state;
    const { user } = this.props;
    const albumId = propz.get(user, ['activeSchool', 'defaultAlbumId'], '');

    if (mode === GALLERY_MODE.FULLSCREEN) {
      const currentPhoto = photos.find(photo => photo.id === fullScreenPhotoId);

      return (
        <FullScreenPhoto
          user={user}
          photo={currentPhoto}
          albumId={albumId}
          isShowArrowButtons={true}
          isShowEditPhotoPanel={true}
          onPrevPhotoClick={this.onPrevPhotoClick}
          onNextPhotoClick={this.onNextPhotoClick}
          onCloseClick={this.onCloseFullScreenPhotoClick}
          onDeletePhotoClick={this.onDeletePhotoClick}
          onChangeSchoolPhotoInfoClick={this.onChangeSchoolPhotoInfoClick}
        />
      );
    }
  }

  render() {
    const { isLoading, photosCount } = this.state;
    const classes = 'mt-3';

    if (isLoading) {
      return <Loader />;
    }

    return (
      <div className={classes}>
        {this.renderAddPhotoModal()}
        {this.renderFullScreenPhoto()}

        <div className="d-flex justify-content-between">
          <h2>School gallery ({photosCount})</h2>
          <Button onClick={this.openAddPhotoModal} text={'Add photo'} />
        </div>

        {this.renderSchoolGalleryPhotos()}
      </div>
    );
  }
}
