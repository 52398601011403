import * as React from 'react';
import * as propz from 'propz';
import { AppUser } from 'Src/views/App/App';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button } from 'Src/components/Button/Button';
import { Venue } from 'Src/models/venue';
import { Autocomplete } from 'Src/components/Autocomplete/Autocomplete';
import { getPostcode, searchFunctionSchoolPostcodes } from 'Src/helpers/autocomplete/autocomplete';
import { Map } from 'Src/components/Map/Map';
import './VenuesForm.css';
import { POINT } from 'Src/consts/venue';
import { Switch } from 'Src/components/Switch/Switch';
import { LabelWithQuestionIcon } from 'Src/components/LabelWithQuestionIcon/LabelWithQuestionIcon';

interface Props {
  user: AppUser;
  venue?: Venue;
  onCancel: () => void;
  onSubmit: (data: any) => void;
}

const venueSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  postcode: Yup.string().required('Required'),
  postcodeId: Yup.string().required('Required'),
  isHome: Yup.boolean(),
  point: Yup.object().shape({
    type: Yup.string().required('Required'),
    coordinates: Yup.array()
  })
});

export function VenueForm(props: Props) {
  const { venue, user, onSubmit, onCancel } = props;
  const { activeSchool } = user;
  const { postcode } = activeSchool;

  function getPostcodes(text: string) {
    return searchFunctionSchoolPostcodes(user, text);
  }

  function onSubmitForm(values) {
    const { name, isHome, point, postcodeId } = values;
    const dataCopy = {
      name,
      isHome,
      postcodeId,
      point
    };
    onSubmit(dataCopy);
  }

  const venueInitial = {
    name: propz.get(venue, ['name'], ''),
    postcodeId: propz.get(venue, ['postcodeId'], postcode.id),
    postcode: propz.get(venue, ['postcode'], postcode.postcode),
    isHome: propz.get(venue, ['isHome'], false),
    point: {
      type: POINT,
      coordinates: propz.get(venue, ['point', 'coordinates'], postcode.point.coordinates)
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik
            initialValues={venueInitial}
            validationSchema={venueSchema}
            onSubmit={values => {
              onSubmitForm(values);
            }}
            render={({ touched, errors, setFieldValue, values }) => (
              <Form>
                <LabelWithQuestionIcon labelText="Name" hintText="" />

                <Field type="text" name="name" className="form-control mb-3" />
                {touched.name && errors.name ? <div className="alert alert-danger">{errors.name}</div> : null}

                <div className="d-flex">
                  <Field
                    name="isHome"
                    render={({ field }) => (
                      <Switch
                        {...field}
                        value={values.isHome}
                        customClass="mb-3"
                        onChange={event => {
                          const isHome = event.target.checked;
                          setFieldValue('isHome', isHome);
                        }}
                      />
                    )}
                  />
                  <LabelWithQuestionIcon
                    labelText="Home venue"
                    hintText=""
                    labelHtmlFor="isHome"
                    customLabelClass="form-check-label pl-2"
                  />
                </div>
                <LabelWithQuestionIcon labelText="Postcode" hintText="" />

                <Field
                  name="postcode"
                  render={({ field }) => {
                    return (
                      <Autocomplete
                        searchFunction={getPostcodes}
                        getElementTitle={getPostcode}
                        customClass="mFullWidth mb-3"
                        defaultItem={values}
                        onSelect={postcode => {
                          setFieldValue('postcodeId', postcode.id);
                          setFieldValue('postcode', postcode.postcode);
                          setFieldValue('point', postcode.point);
                        }}
                      />
                    );
                  }}
                />
                {touched.postcode && errors.postcode ? (
                  <div className="alert alert-danger">{errors.postcode}</div>
                ) : null}

                <Field
                  name="point"
                  render={({ field }) => {
                    return (
                      <Map
                        key={venue ? venue.id : 'emptyVenue'}
                        point={values.point}
                        getNewPoint={point => {
                          setFieldValue('point', point);
                        }}
                        customStylingClass="eVenue_map"
                        isMarkerDraggable={true}
                      />
                    );
                  }}
                />

                <Button onClick={onCancel} text={'CANCEL'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                <button type="submit" className="mt-3 mb-3 btn btn-primary">
                  SAVE
                </button>
              </Form>
            )}
          />
        </div>
      </div>
    </div>
  );
}
