import * as React from 'react';

interface Props {
  onClick: () => void;
  basketOrdersCount: string;
}

export const BasketIcon = (props: Props) => {
  const { onClick, basketOrdersCount } = props;
  const isOnClickExist = typeof onClick !== 'undefined';

  const isOredersExist = Number(basketOrdersCount) > 0;

  const emptyFunc = () => {};

  return (
    <div className="bBasketIcon" onClick={isOnClickExist ? onClick : emptyFunc}>
      {isOredersExist && <div className="eBasketCountBadge">{basketOrdersCount}</div>}

      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="38" height="38" x="0" y="0" viewBox="0 0 64 64">
        <g>
          <g xmlns="http://www.w3.org/2000/svg" id="chart">
            <path d="m29.72 52a3 3 0 1 1 -3-3 3 3 0 0 1 3 3z" fill="#35aed9" data-original="#000000" />
            <path d="m49.42 52a3 3 0 1 1 -3-3 3 3 0 0 1 3 3z" fill="#35aed9" data-original="#000000" />
            <path
              d="m48.41 47.2h-23.82a4.34 4.34 0 0 1 -4.28-3.58l-3.65-20.28a2.45 2.45 0 0 1 2.41-2.88h33.3a4.35 4.35 0 0 1 4.25 5.28l-4 18a4.37 4.37 0 0 1 -4.21 3.46zm-28.68-23.74 3.53 19.63a1.35 1.35 0 0 0 1.33 1.11h23.82a1.34 1.34 0 0 0 1.32-1.06l4-18a1.35 1.35 0 0 0 -1.32-1.64z"
              fill="#35aed9"
              data-original="#000000"
            />
            <path
              d="m19.29 30.87a1.5 1.5 0 0 1 -1.47-1.21l-2.58-13.08a.5.5 0 0 0 -.49-.4h-6a1.5 1.5 0 1 1 0-3h6a3.51 3.51 0 0 1 3.43 2.82l2.58 13.08a1.5 1.5 0 0 1 -1.47 1.79z"
              fill="#35aed9"
              data-original="#000000"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

BasketIcon.displayName = 'BasketIcon';
