import * as React from 'react';
import * as propz from 'propz';
import { AppUser } from 'Src/views/App/App';
import { SchoolEvent, Participant } from 'Src/models/event';
import { ValueHashMap } from '../ResultsViews/IndividualInternalResults';
import {
  convertPointsToString,
  getDigitMask,
  getTeamScoreByTeamId,
  getSchoolScoreBySchoolId,
  convertPointsToStringWithoutDelimeter
} from 'Src/helpers/event/schoolEventViewHelper';
import * as TextMaskCore from 'text-mask-core';
import { ARROW_DOWN_KEY, ARROW_UP_KEY, ENTER_KEY } from 'Src/consts/common';
import { EMPTY_STRING } from 'Src/consts/date';
import { Button } from 'Src/components/Button/Button';
import { KIND } from 'Src/consts/school';
import { getInterSchoolsTeamEventParticipants } from 'Src/helpers/event/event';

interface Props {
  event: SchoolEvent;
  user: AppUser;
  onSaveClick: (data: any) => void;
  onCloseClick: () => void;
}

interface State {
  prevExtraPointHashMap: ValueHashMap;
  extraPointHashMap: ValueHashMap;
  allScores: Score[];
  participants: Participant[];
}

interface Score {
  _id: string;
  rishScore: { points: number; results: number };
  score: number;
  teamId: string;
  schoolId: string;
}

export class TeamExternalSchoolsExtraPoints extends React.Component<Props, State> {
  inputElement: any[];
  textMaskInputElement: any[];

  constructor(props: Props) {
    super(props);

    this.inputElement = [];
    this.textMaskInputElement = [];

    this.state = {
      prevExtraPointHashMap: {},
      extraPointHashMap: {},
      allScores: [],
      participants: []
    };
  }

  componentDidMount() {
    const { event, user } = this.props;

    // event.players     contains schoolId - id of player's school
    // event.teamsData   contains schoolId - id of union includes player school (or included in past)
    // participants contains event.teamsData
    // schoolScores      contains scores for schools without team
    // solution includes players from schools removed from union
    const participants = getInterSchoolsTeamEventParticipants(event, user);
    const teamScore = propz.get(event, ['results', 'teamScore'], []);
    const schoolScore = propz.get(event, ['results', 'schoolScore'], []);
    const allScores = [...teamScore, ...schoolScore];

    let extraPointHashMap = {};

    schoolScore.forEach(score => {
      const { schoolId } = score;
      const extraPoints = propz.get(score, ['richScore', 'points'], 0);
      const extraPointsStr = String(extraPoints);
      extraPointHashMap[schoolId] = extraPoints === 0 ? '' : extraPointsStr;
    });

    teamScore.forEach(score => {
      const { teamId } = score;
      const extraPoints = propz.get(score, ['richScore', 'points'], 0);
      const extraPointsStr = String(extraPoints);
      extraPointHashMap[teamId] = extraPoints === 0 ? '' : extraPointsStr;
    });

    this.setState(
      {
        allScores,
        extraPointHashMap,
        prevExtraPointHashMap: extraPointHashMap,
        participants
      },
      () => this.initTextMask()
    );
  }

  initTextMask() {
    const { event } = this.props;
    const { extraPointHashMap, participants } = this.state;

    for (let i = 0; i < participants.length; i++) {
      const currentParticipant = participants[i];
      const teamId = propz.get(currentParticipant, ['team', 'id']);
      const schoolId = propz.get(currentParticipant, ['school', 'id']);
      const isTeamScore = typeof teamId !== 'undefined';

      const id = isTeamScore ? teamId : schoolId;
      const value = extraPointHashMap[id];

      this.textMaskInputElement[i] = TextMaskCore.createTextMaskInputElement({
        inputElement: this.inputElement[i],
        mask: inputValue => getDigitMask(inputValue),
        showMask: false
      });

      const isValueExist = typeof value !== 'undefined';

      isValueExist && this.textMaskInputElement[i].update(value);
    }
  }

  onChange(event: React.ChangeEvent<HTMLInputElement>, index: number, id: string) {
    const extraPointHashMap = { ...this.state.extraPointHashMap };
    const value = event.target.value;

    extraPointHashMap[id] = value;

    this.setState({
      extraPointHashMap: extraPointHashMap
    });

    this.textMaskInputElement[index].update(value);
  }

  onKeyDown = (eventDescriptor, index) => {
    const { participants } = this.state;

    const isKeyEnter = eventDescriptor.key === ENTER_KEY;
    const isKeyArrowUp = eventDescriptor.key === ARROW_UP_KEY;
    const isKeyArrowDown = eventDescriptor.key === ARROW_DOWN_KEY;
    const isLastInput = index + 1 === participants.length;
    const isFirstInput = index === 0;

    switch (true) {
      case isKeyEnter && !isLastInput:
      case isKeyArrowDown && !isLastInput:
        this.inputElement[index + 1].focus();
        break;
      case isKeyArrowUp && !isFirstInput:
        this.inputElement[index - 1].focus();
        break;
    }
  };

  onFocus(e, index: number, id: string) {
    const extraPoint = this.state.extraPointHashMap[id];
    const prevExtraPointHashMap = { ...this.state.prevExtraPointHashMap };
    prevExtraPointHashMap[id] = extraPoint;

    this.setState({
      prevExtraPointHashMap
    });

    this.textMaskInputElement[index].update('');

    e.stopPropagation();
  }

  onBlur(e, index: number, id: string) {
    const eventValue = e.target.value;
    const prevExtraPointHashMap = { ...this.state.prevExtraPointHashMap };

    if (eventValue === EMPTY_STRING) {
      const extraPointHashMapWithOldValue = { ...this.state.extraPointHashMap };
      extraPointHashMapWithOldValue[id] = prevExtraPointHashMap[id];

      this.setState({
        extraPointHashMap: extraPointHashMapWithOldValue
      });
      this.textMaskInputElement[index].update(prevExtraPointHashMap[id]);
    } else {
      const extraPointHashMap = { ...this.state.extraPointHashMap };
      extraPointHashMap[id] = eventValue;

      this.setState({
        extraPointHashMap: extraPointHashMap
      });
      this.textMaskInputElement[index].update(eventValue);
    }

    e.stopPropagation();
  }

  renderInput(index: number, id: string): React.ReactNode {
    return (
      <input
        ref={el => (this.inputElement[index] = el)}
        onKeyDown={event => this.onKeyDown(event, index)}
        onChange={event => this.onChange(event, index, id)}
        onFocus={event => this.onFocus(event, index, id)}
        onBlur={event => this.onBlur(event, index, id)}
        className={'eIndividualInternalResultsInput'}
      />
    );
  }

  onSubmit = () => {
    const { allScores, extraPointHashMap } = this.state;
    const { onSaveClick } = this.props;

    const scores = allScores
      .filter(({ teamId, schoolId }) => {
        return extraPointHashMap[teamId] !== '' || extraPointHashMap[schoolId] !== '';
      })
      .map(({ teamId, schoolId, _id }) => {
        const isTeamExist = typeof teamId !== 'undefined';

        const result = isTeamExist
          ? {
              teamId,
              schoolId,
              scoreId: _id,
              richScore: {
                points: Number(extraPointHashMap[teamId])
              }
            }
          : {
              schoolId,
              scoreId: _id,
              richScore: {
                points: Number(extraPointHashMap[schoolId])
              }
            };

        return result;
      });

    onSaveClick(scores);
  };

  render() {
    const { onCloseClick, event } = this.props;
    const { participants } = this.state;
    const { invitedSchools, inviterSchool } = event;
    const schools = [...invitedSchools, inviterSchool];

    return (
      <div>
        <div>
          Any extra points you add here will be used when generating the leaderboard for this event; please note when
          extra points are added, the leaderboard will be based on extra points then on results. Also, if you are
          running a house competition, aggregated house points will include these points (providing this event is
          included in an individual league).
        </div>

        {participants.map((participant, index) => {
          const { team, school } = participant;
          const teamName = propz.get(team, ['name']);

          const isTeamExist = typeof team !== 'undefined';
          const isTeamNameExist = typeof teamName !== 'undefined';

          let points;
          let pointsStr;
          let teamId;

          if (!isTeamExist) {
            points = getSchoolScoreBySchoolId(event, school.id);
            pointsStr = convertPointsToStringWithoutDelimeter(event, points);
          } else {
            teamId = team.id;
            points = getTeamScoreByTeamId(event, teamId);
            pointsStr = convertPointsToStringWithoutDelimeter(event, points);
          }

          return (
            <table
              className={'eIndividualExternalSchoolsResultsTable'}
              key={`individual_inter_schools_results_table_${index}`}
            >
              <thead>
                <tr>
                  <th className={'eIndividualExternalSchoolsResultsCell'} style={{ width: '5%' }}>
                    #
                  </th>
                  <th className={'eIndividualExternalSchoolsResultsCell '} style={{ width: '45%' }}>
                    School
                  </th>
                  <th className={'eIndividualExternalSchoolsResultsCell'} style={{ width: '20%' }}>
                    Results
                  </th>
                  <th className={'eIndividualExternalSchoolsResultsCell'} style={{ width: '30%' }}>
                    Extra points
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td className={'eIndividualExternalSchoolsResultsCell'} style={{ width: '5%' }}>
                    {index + 1}.
                  </td>

                  <td className={'eIndividualExternalSchoolsResultsCell'} style={{ width: '5%' }}>
                    {isTeamNameExist ? `${team.name} / ${school.name}` : `${school.name}`}
                  </td>

                  <td className={'eIndividualExternalSchoolsResultsCell'} style={{ width: '20%' }}>
                    {pointsStr}
                  </td>

                  <td className={'eIndividualExternalSchoolsResultsCell'} style={{ width: '30%' }}>
                    {isTeamExist ? this.renderInput(index, teamId) : this.renderInput(index, school.id)}
                  </td>
                </tr>
              </tbody>
            </table>
          );
        })}

        <Button text={'Cancel'} onClick={onCloseClick} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
        <Button text={'Save results'} onClick={this.onSubmit} customClass={'mt-3 mb-3'} />
      </div>
    );
  }
}
