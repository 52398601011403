import * as React from 'react';
import { Route, Switch } from 'react-router';
import { EmptyComponent } from 'Src/components/EmptyComponent/EmptyComponent';
import { ParentMessagesOutbox } from './MessagesOutbox';
import { ParentMessagesArchive } from './MessagesArchive';
import { ParentMessagesInbox } from './MessagesInbox';

export const ParentMessagesRouting = allProps => {
  return (
    <Switch>
      <Route exact path={'/messages'} render={props => <EmptyComponent {...props} user={allProps.user} />} />
      <Route
        exact
        path={'/messages/inbox'}
        render={props => <ParentMessagesInbox {...props} user={allProps.user} setNeedUpdate={allProps.setNeedUpdate} />}
      />
      <Route
        exact
        path={'/messages/outbox'}
        render={props => (
          <ParentMessagesOutbox {...props} user={allProps.user} availableRoles={allProps.availableRoles} />
        )}
      />
      <Route
        exact
        path={'/messages/archive'}
        render={props => <ParentMessagesArchive {...props} user={allProps.user} />}
      />
    </Switch>
  );
};

ParentMessagesRouting.displayName = 'ParentMessagesRouting';
