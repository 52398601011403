import {
  CLUB_ACADEMIC_YEARS_MAX,
  CLUB_ACADEMIC_YEARS_MIN,
  CLUB_MESSAGE_INVITE_STATUS_SERVER_TO_CLIENT_MAPPING,
  CLUB_MESSAGE_INVITE_STATUS_IF_PAYMENTS_DISABLED_SERVER_TO_CLIENT_MAPPING,
  CLUB_SCHEDULE,
  CLUB_SCHEDULE_SERVER_TO_CLIENT_MAPPING,
  CLUB_STATUS,
  CLUB_STATUS_SERVER_TO_CLIENT_MAPPING,
  MAX_CLUB_PRIORITY,
  MIN_CLUB_PRIORITY
} from '../../consts/club';
import { SelectOption } from './table';
import { WEEK_DAYS, WEEK_DAYS_SERVER_TO_CLIENT_MAPPING } from '../../consts/date';
import {
  ALL_SCHOOL_ROLES_LIST,
  GENDER_SERVER_TO_CLIENT_MAPPING,
  ROLE,
  ROLE_SERVER_TO_CLIENT_MAPPING,
  STAFF_LIST,
  USER_STATUSES,
  USER_STATUSES_TO_CLIENT_MAPPING,
  ROLE_PUBLIC_BLOGGER,
  USER_GENDER
} from '../../consts/user';
import { AVAILABLE_AGES_MAX, AVAILABLE_AGES_MIN, KIND } from '../../consts/school';
import { Tag } from '../../models/tag';
import { SchoolForm } from '../../models/form';
import * as Lazy from 'lazy.js';
import * as propz from 'propz';
import { AGE_GROUPS, AGE_GROUPS_SORTED } from '../../consts/school';
import {
  GENERAL_MESSAGE_DELIVERY_STATUS,
  GENERAL_MESSAGE_DELIVERY_STATUS_SERVER_TO_CLIENT_MAPPING,
  GENERAL_MESSAGE_STATUS,
  GENERAL_MESSAGE_STATUS_SERVER_TO_CLIENT_MAPPING
} from '../../consts/generalMessage';
import {
  ABSENCE_MESSAGE_ACTIONS,
  CONFIRMATION_REQUESTS_STATUS,
  CONFIRMATION_REQUESTS_STATUS_SERVER_TO_CLIENT_MAPPING,
  MESSAGE_KIND,
  MESSAGE_KIND_SERVER_TO_CLIENT_MAPPING
} from '../../consts/message';
import { Role } from '../../models/roles';
import { Permission } from '../../models/permission';
import {
  INVITE_OUTBOX_STATUS_SERVER_TO_CLIENT_MAPPING,
  INVITE_STATUS_SERVER_TO_CLIENT_MAPPING
} from '../../consts/invite';
import { TOURNAMENT_SEASONS_MAX, TOURNAMENT_SEASONS_MIN } from '../../consts/tournament';
import { TRANSACTION_STATUS_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/transaction';
import { Record } from '../../models/record';
import { TOURNAMENT_LOG_ACTION_TYPES, TOURNAMENT_LOG_ACTION_TYPES_SERVER_TO_CLIENT_MAPPING } from '../../consts/log';
import { Tournament } from '../../models/tournament';
import { TournamentTag } from '../../models/tournamentTag';
import { GENDER, SPORT_GENDER_SERVER_TO_CLIENT_MAPPING } from '../../consts/common';
import {
  EVENT_STATUS,
  EVENT_STATUS_SERVER_TO_CLIENT_MAPPING,
  EVENT_SUB_TYPES_SERVER_TO_CLIENT_MAPPING,
  EVENT_TYPES,
  EVENT_TYPES_SERVER_TO_CLIENT_MAPPING
} from '../../consts/event';
import { PERMISSION_REQUEST_ARCHiVE_STATUS_SERVER_TO_CLIENT_MAPPING } from '../../consts/permissionRequest';
import { EVENT_VENUE, VENUE_SERVER_TO_CLIENT_MAPPING } from '../../consts/venue';
import { Sport } from '../../models/sport';
import { School } from 'Src/models/school';
import { LEAGUE_AGGREGATION_TYPE, LEAGUE_AGGREGATION_TYPE_SERVER_TO_CLIENT_MAPPING } from '../../consts/league';
import { GENDER_TYPE } from '../../types/common';
import { FILES_CATEGORY, FILES_CATEGORY_SERVER_TO_CLIENT_MAPPING } from '../../consts/file';
import { AppUser } from 'Src/views/App/App';
import { getFullName } from 'Src/helpers/autocomplete/autocomplete';

export function getSelectOptionsForClubMessagesStatus(user: AppUser): SelectOption[] {
  const isPaymentsEnabled = propz.get(user, ['activeSchool', 'isPaymentsEnabled'], false);
  const messageStatus = [];

  const clubMessageInviteStatus = isPaymentsEnabled
    ? CLUB_MESSAGE_INVITE_STATUS_SERVER_TO_CLIENT_MAPPING
    : CLUB_MESSAGE_INVITE_STATUS_IF_PAYMENTS_DISABLED_SERVER_TO_CLIENT_MAPPING;

  for (let key in clubMessageInviteStatus) {
    messageStatus.push({
      text: clubMessageInviteStatus[key],
      value: key
    });
  }

  return messageStatus;
}

export function getSelectOptionsForGeneralMessagesStatus(): SelectOption[] {
  const statusOptions = [];

  for (let status in GENERAL_MESSAGE_STATUS) {
    statusOptions.push({
      text: GENERAL_MESSAGE_STATUS_SERVER_TO_CLIENT_MAPPING[status],
      value: GENERAL_MESSAGE_STATUS[status]
    });
  }

  return statusOptions;
}

export function getSelectOptionsForGeneralMessagesDeliveryStatus(): SelectOption[] {
  return GENERAL_MESSAGE_DELIVERY_STATUS.map(status => {
    return {
      text: GENERAL_MESSAGE_DELIVERY_STATUS_SERVER_TO_CLIENT_MAPPING[status],
      value: status
    };
  });
}

export function getSelectOptionsForClubPriority(): SelectOption[] {
  const priority: SelectOption[] = [
    {
      text: '',
      value: ''
    }
  ];

  for (let index = MIN_CLUB_PRIORITY; index <= MAX_CLUB_PRIORITY; index++) {
    priority.push({
      text: index,
      value: index
    });
  }

  return priority;
}

export function getSelectOptionsForClubDays(): SelectOption[] {
  const days = [];

  for (let key in WEEK_DAYS_SERVER_TO_CLIENT_MAPPING) {
    days.push({
      text: WEEK_DAYS_SERVER_TO_CLIENT_MAPPING[key],
      value: key
    });
  }

  return days;
}

export function getSelectOptionsForChildren(children): SelectOption[] {
  return children.map(child => {
    return {
      text: `${child.firstName} ${child.lastName}`,
      value: child.id
    };
  });
}

export function getSelectOptionForRole(): SelectOption[] {
  return ALL_SCHOOL_ROLES_LIST.map(role => {
    return {
      text: ROLE_SERVER_TO_CLIENT_MAPPING[role],
      value: role
    };
  });
}

export function getSelectOptionForUserStatus(): SelectOption[] {
  return USER_STATUSES.map(status => {
    return {
      text: USER_STATUSES_TO_CLIENT_MAPPING[status],
      value: status
    };
  });
}

export function getSelectOptionForTag(tags: Tag[]): SelectOption[] {
  return tags.map(tag => {
    return {
      text: tag.tagName,
      value: tag.id
    };
  });
}

export function getSelectOptionForClubStatus(): SelectOption[] {
  let statusOptions = [];

  for (let status in CLUB_STATUS) {
    statusOptions.push({
      text: CLUB_STATUS_SERVER_TO_CLIENT_MAPPING[status],
      value: CLUB_STATUS[status]
    });
  }

  return statusOptions;
}

export function getSelectOptionForClubMessageStatus(): SelectOption[] {
  const messageStatus = [];

  for (let key in CLUB_MESSAGE_INVITE_STATUS_SERVER_TO_CLIENT_MAPPING) {
    messageStatus.push({
      text: CLUB_MESSAGE_INVITE_STATUS_SERVER_TO_CLIENT_MAPPING[key],
      value: key
    });
  }
  return messageStatus;
}

export function getSelectOptionForBooleanQuestion(): SelectOption[] {
  return [
    { text: 'Yes', value: 'true' },
    { text: 'No', value: 'false' }
  ];
}

export function getSelectOptionForStaffs(): SelectOption[] {
  return STAFF_LIST.map(staff => {
    return {
      text: ROLE_SERVER_TO_CLIENT_MAPPING[staff],
      value: staff
    };
  });
}

export function getSelectOptionForForms(forms): SelectOption[] {
  return forms.map(form => {
    return {
      text: form.name,
      value: form.id
    };
  });
}

export function getSelectOptionForHouses(houses): SelectOption[] {
  return houses.map(house => {
    return {
      text: house.name,
      value: house.id
    };
  });
}

export function getSelectOptionForTeams(teams): SelectOption[] {
  return teams.map(team => {
    return {
      text: team.name,
      value: team.id
    };
  });
}

export function getSelectOptionForAge(forms: SchoolForm[], ageGroupsNaming): SelectOption[] {
  const ages = (Lazy(forms) as any)
    .map(form => {
      const ageGroup = propz.get(AGE_GROUPS, [ageGroupsNaming, form.age]);

      return {
        value: form.age,
        text: ageGroup,
        name: ageGroup,
        isChecked: false
      };
    })
    .uniq('value')
    .sort((selectOption1, selectOption2) => {
      const ageGroup = propz.get(AGE_GROUPS_SORTED, [ageGroupsNaming]);
      const ageIndex1 = ageGroup.indexOf(selectOption1.text);
      const ageIndex2 = ageGroup.indexOf(selectOption2.text);

      return ageIndex1 - ageIndex2;
    })
    .toArray();

  return ages;
}

export function getSelectOptionForSchoolOfTeam(players): SelectOption[] {
  const schools = (Lazy(players) as any)
    .map(player => {
      const { schoolName, schoolId } = player;
      return {
        value: schoolId,
        text: schoolName
      };
    })
    .uniq('text')
    .toArray();

  return schools;
}

export function getSelectOptionForAgeRecords(ageGroupsNaming): SelectOption[] {
  const ageGroups = propz.get(AGE_GROUPS, [ageGroupsNaming]);

  return ageGroups.map((ageGroup, index) => {
    return {
      value: index,
      text: ageGroup
    };
  });
}

export function getSelectOptionForGender(optGender?: GENDER_TYPE): SelectOption[] {
  let result: SelectOption[] = [];

  const isMaleOnly = optGender === GENDER.MALE_ONLY;
  const isFemaleOnly = optGender === GENDER.FEMALE_ONLY;

  switch (true) {
    case isMaleOnly:
      return [
        {
          text: GENDER_SERVER_TO_CLIENT_MAPPING[USER_GENDER.MALE],
          value: USER_GENDER.MALE
        }
      ];
    case isFemaleOnly:
      return [
        {
          text: GENDER_SERVER_TO_CLIENT_MAPPING[USER_GENDER.FEMALE],
          value: USER_GENDER.FEMALE
        }
      ];

    default:
      for (let key in GENDER_SERVER_TO_CLIENT_MAPPING) {
        result.push({
          text: GENDER_SERVER_TO_CLIENT_MAPPING[key],
          value: key
        });
      }
      return result;
  }
}

export function getSelectOptionForSport(items: Record[] | Sport[]): SelectOption[] {
  const sports = (Lazy(items) as any)
    .map(item => {
      const sportName = propz.get(item, ['name'], '');
      const sportId = propz.get(item, ['id'], '');
      return {
        value: sportId,
        text: sportName
      };
    })
    .uniq('value')
    .toArray();

  return sports;
}

export function getSelectOptionsForMessageKindByType(): SelectOption[] {
  const kindOptions = [];

  for (let kind in MESSAGE_KIND) {
    kindOptions.push({
      text: MESSAGE_KIND_SERVER_TO_CLIENT_MAPPING[kind],
      value: MESSAGE_KIND[kind]
    });
  }

  return kindOptions;
}

export function getSelectOptionsForParentChildren(children): SelectOption[] {
  return children.map(child => {
    return {
      text: getFullName(child),
      value: child.id
    };
  });
}

export function getSelectOptionsForMessageActionStatus(): SelectOption[] {
  const actionStatusOptions = [
    {
      text: ABSENCE_MESSAGE_ACTIONS.Authorised,
      value: ABSENCE_MESSAGE_ACTIONS.Authorised
    },
    {
      text: ABSENCE_MESSAGE_ACTIONS.Unauthorised,
      value: ABSENCE_MESSAGE_ACTIONS.Unauthorised
    }
  ];

  return actionStatusOptions;
}

export function getSelectOptionsForSchools(availableRoles: Role[]): SelectOption[] {
  const schools = Lazy(availableRoles)
    .filter(role => role.name === ROLE.PARENT)
    .map(role => role.permissions)
    .flatten()
    .filter(permission => {
      const isStudentIdExist = typeof permission.studentId !== 'undefined';
      const isAbsenceRequestEnabled = propz.get(permission, ['school', 'isAbsenceRequestEnabled'], false);

      return isStudentIdExist && isAbsenceRequestEnabled;
    })
    .map(permission => ({
      text: permission.school.name,
      value: permission.school.id
    }))
    .uniq('value')
    .toArray();

  return schools;
}

export function getSelectOptionsForInvitesOutboxStatus(): SelectOption[] {
  const messageStatus = [];

  for (let key in INVITE_OUTBOX_STATUS_SERVER_TO_CLIENT_MAPPING) {
    messageStatus.push({
      text: INVITE_OUTBOX_STATUS_SERVER_TO_CLIENT_MAPPING[key],
      value: key
    });
  }
  return messageStatus;
}

export function getSelectOptionsForRequestArchiveStatus(): SelectOption[] {
  const requestStatus = [];

  for (let key in PERMISSION_REQUEST_ARCHiVE_STATUS_SERVER_TO_CLIENT_MAPPING) {
    requestStatus.push({
      text: PERMISSION_REQUEST_ARCHiVE_STATUS_SERVER_TO_CLIENT_MAPPING[key],
      value: key
    });
  }
  return requestStatus;
}

export function getSelectOptionsForInvitesStatus(): SelectOption[] {
  const messageStatus = [];

  for (let key in INVITE_STATUS_SERVER_TO_CLIENT_MAPPING) {
    messageStatus.push({
      text: INVITE_STATUS_SERVER_TO_CLIENT_MAPPING[key],
      value: key
    });
  }
  return messageStatus;
}

export function getSelectOptionsForTournamentConfirmationRequestMessageStatus(): SelectOption[] {
  const messageStatus = [];

  for (let key in CONFIRMATION_REQUESTS_STATUS) {
    messageStatus.push({
      text: CONFIRMATION_REQUESTS_STATUS_SERVER_TO_CLIENT_MAPPING[key],
      value: CONFIRMATION_REQUESTS_STATUS[key]
    });
  }

  return messageStatus;
}

export function getSelectOptionForSeason(): SelectOption[] {
  const max = TOURNAMENT_SEASONS_MAX;
  const min = TOURNAMENT_SEASONS_MIN;
  let i = max;
  let result = [];

  while (i > min) {
    result.push({
      text: `${i - 1}-${i}`,
      value: `${i - 1}`
    });
    i--;
  }

  return result;
}

export function getSelectOptionForUpcomingTournamentsSeason(tournaments): SelectOption[] {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const isAfterSeptemberFirst = currentDate > new Date(`${currentYear}-09-01T00:00:00.000Z`);

  const startingSeasonYear = isAfterSeptemberFirst ? currentYear + 1 : currentYear;

  const tournamentsStartYears = tournaments.map(item => new Date(item.startTime).getFullYear());
  const tournamentsStartMaxYear = Math.max.apply(null, tournamentsStartYears);

  const isNextSeasonStartExist = tournaments.some(tournament => {
    const startTime = new Date(tournament.startTime);
    const tournamentStartYear = startTime.getFullYear();

    return (
      tournamentStartYear === tournamentsStartMaxYear &&
      startTime > new Date(`${tournamentStartYear}-09-01T00:00:00.000Z`)
    );
  });

  let upcomingYear = Math.max(
    isNextSeasonStartExist ? tournamentsStartMaxYear + 1 : tournamentsStartMaxYear,
    startingSeasonYear
  );

  let result = [];

  while (upcomingYear >= startingSeasonYear) {
    result.push({
      text: `${upcomingYear - 1}-${upcomingYear}`,
      value: `${upcomingYear - 1}`
    });
    upcomingYear--;
  }

  return result;
}

export function getSelectOptionForArchiveTournamentsSeason(): SelectOption[] {
  const currentYear = new Date().getFullYear();
  const minYear = TOURNAMENT_SEASONS_MIN;
  const maxYear = TOURNAMENT_SEASONS_MAX;

  let result = [];

  if (maxYear === currentYear) {
    result.push({
      text: `${currentYear}-${currentYear + 1}`,
      value: `${currentYear}`
    });
  }

  for (let i = maxYear; i > minYear; i--) {
    result.push({
      text: `${i - 1}-${i}`,
      value: `${i - 1}`
    });
  }

  return result;
}

export function getSelectOptionForTournamentCustomGroups(customGroups): SelectOption[] {
  return customGroups.map(customGroup => {
    return {
      text: customGroup.name,
      value: customGroup.id
    };
  });
}

export function getSelectOptionsForTournamentEnrolledLogsAction(): SelectOption[] {
  const enrolledLogsAction = [];

  for (let key in TOURNAMENT_LOG_ACTION_TYPES) {
    enrolledLogsAction.push({
      text: TOURNAMENT_LOG_ACTION_TYPES_SERVER_TO_CLIENT_MAPPING[key],
      value: TOURNAMENT_LOG_ACTION_TYPES[key]
    });
  }

  return enrolledLogsAction;
}

export function getSelectOptionForTournamentEventAges(tournament: Tournament): SelectOption[] {
  const tournamentAges = propz.get(tournament, ['ages'], []);
  const selectOptions = (Lazy(tournamentAges) as any)
    .map(age => {
      const ageGroup = propz.get(AGE_GROUPS, ['U5-U18', age]);
      return {
        value: age,
        text: ageGroup
      };
    })
    .uniq('value')
    .sort((selectOption1, selectOption2) => selectOption1.value - selectOption2.value)
    .toArray();

  return selectOptions;
}

export function getSelectOptionForTournamentEventAgesGroup(tournament: Tournament, user?: AppUser): SelectOption[] {
  const tournamentAgesGroupName = propz.get(user, ['activeSchool', 'ageGroupsNaming'], '');
  const tournamentAgeIndexes = propz.get(tournament, ['ages'], []);
  const selectOptions = (Lazy(tournamentAgeIndexes) as any)
    .map(ageIndex => {
      const ageGroup = propz.get(AGE_GROUPS, [tournamentAgesGroupName], []);
      return {
        value: ageIndex,
        text: ageGroup[ageIndex]
      };
    })
    .uniq('value')
    .sort((selectOption1, selectOption2) => selectOption1.value - selectOption2.value)
    .toArray();

  return selectOptions;
}

export function getSelectOptionForTournamentEventGender(): SelectOption[] {
  const gender = [];
  for (let key in GENDER) {
    gender.push({
      text: SPORT_GENDER_SERVER_TO_CLIENT_MAPPING[key],
      value: GENDER[key],
      name: SPORT_GENDER_SERVER_TO_CLIENT_MAPPING[key],
      isChecked: false
    });
  }
  return gender;
}

export function getSelectOptionForTournamentEventStatus(): SelectOption[] {
  const status = [];
  status.push(
    {
      text: EVENT_STATUS_SERVER_TO_CLIENT_MAPPING.ACCEPTED,
      value: EVENT_STATUS.ACCEPTED
    },
    {
      text: EVENT_STATUS_SERVER_TO_CLIENT_MAPPING.FINISHED,
      value: EVENT_STATUS.FINISHED
    },
    {
      text: EVENT_STATUS_SERVER_TO_CLIENT_MAPPING.REJECTED,
      value: EVENT_STATUS.REJECTED
    },
    {
      text: EVENT_STATUS_SERVER_TO_CLIENT_MAPPING.CANCELED,
      value: EVENT_STATUS.CANCELED
    },
    {
      text: EVENT_STATUS_SERVER_TO_CLIENT_MAPPING.INVITES_SENT,
      value: EVENT_STATUS.INVITES_SENT
    }
  );
  return status;
}

export function getSelectOptionForClubEventStatus(): SelectOption[] {
  const status = [];
  status.push(
    {
      text: EVENT_STATUS_SERVER_TO_CLIENT_MAPPING.ACCEPTED,
      value: EVENT_STATUS.ACCEPTED
    },
    {
      text: EVENT_STATUS_SERVER_TO_CLIENT_MAPPING.CANCELED,
      value: EVENT_STATUS.CANCELED
    }
  );
  return status;
}

export function getSelectOptionForEventTypes(): SelectOption[] {
  const eventType = [];
  eventType.push(
    {
      text: EVENT_TYPES_SERVER_TO_CLIENT_MAPPING.EXTERNAL_SCHOOLS,
      value: EVENT_TYPES.EXTERNAL_SCHOOLS
    },
    {
      text: EVENT_TYPES_SERVER_TO_CLIENT_MAPPING.INTERNAL_HOUSES,
      value: EVENT_TYPES.INTERNAL_HOUSES
    },
    {
      text: EVENT_TYPES_SERVER_TO_CLIENT_MAPPING.INTERNAL_TEAMS,
      value: EVENT_TYPES.INTERNAL_TEAMS
    }
  );
  return eventType;
}

export function getSelectOptionForEventVenueType(): SelectOption[] {
  const venueType = [];
  venueType.push(
    {
      text: VENUE_SERVER_TO_CLIENT_MAPPING.AWAY,
      value: EVENT_VENUE.AWAY
    },
    {
      text: VENUE_SERVER_TO_CLIENT_MAPPING.HOME,
      value: EVENT_VENUE.HOME
    },
    {
      text: VENUE_SERVER_TO_CLIENT_MAPPING.TBD,
      value: EVENT_VENUE.TBD
    }
  );
  return venueType;
}

export function getSelectOptionForTournamentTeamGender(): SelectOption[] {
  const gender = [];
  for (let key in GENDER) {
    gender.push({
      text: SPORT_GENDER_SERVER_TO_CLIENT_MAPPING[key],
      value: GENDER[key]
    });
  }
  return gender;
}

export function getSelectOptionForTournamentTeamAges(tournament: Tournament, user?): SelectOption[] {
  const tournamentAgesGroupName = propz.get(user, ['activeSchool', 'ageGroupsNaming'], '');
  const tournamentAgeIndexes = propz.get(tournament, ['ages'], []);
  const selectOptions = (Lazy(tournamentAgeIndexes) as any)
    .map(ageIndex => {
      const ageGroup = propz.get(AGE_GROUPS, [tournamentAgesGroupName], []);
      return {
        value: ageIndex,
        text: ageGroup[ageIndex]
      };
    })
    .uniq('value')
    .sort((selectOption1, selectOption2) => selectOption1.value - selectOption2.value)
    .toArray();

  return selectOptions;
}

export function getSelectOptionForPositionsSport(sport: Sport): SelectOption[] {
  const positions = propz.get(sport, ['field', 'positions'], []);

  const selectOptions = (Lazy(positions) as any)
    .map(position => {
      const positionName = propz.get(position, ['name'], '');
      const positionId = propz.get(position, ['_id'], '');
      return {
        value: positionId,
        text: positionName
      };
    })
    .uniq('value')
    .toArray();

  return selectOptions;
}

export function getSelectOptionForClubGender(): SelectOption[] {
  let gender = [];
  for (let key in GENDER) {
    gender.push({
      text: SPORT_GENDER_SERVER_TO_CLIENT_MAPPING[key],
      value: GENDER[key]
    });
  }
  return gender;
}

export function getSelectOptionForClubAcademicYears(): SelectOption[] {
  const max = CLUB_ACADEMIC_YEARS_MAX;
  const min = CLUB_ACADEMIC_YEARS_MIN;
  let i = max;
  let result = [];

  while (i > min) {
    result.push({
      text: `${i - 1} / ${i}`,
      value: `${i - 1}`
    });
    i--;
  }

  return result;
}

export function getSelectOptionForClubWeekDays(): SelectOption[] {
  let weekDays = [];
  for (let key in WEEK_DAYS) {
    weekDays.push({
      text: WEEK_DAYS_SERVER_TO_CLIENT_MAPPING[key],
      value: WEEK_DAYS[key]
    });
  }
  return weekDays;
}

export function getSelectOptionForClubRecurrence(): SelectOption[] {
  const recurrence = [
    {
      text: CLUB_SCHEDULE_SERVER_TO_CLIENT_MAPPING.WEEKLY_SCHEDULE,
      value: CLUB_SCHEDULE.WEEKLY_SCHEDULE
    },
    {
      text: CLUB_SCHEDULE_SERVER_TO_CLIENT_MAPPING.INTERVALS,
      value: CLUB_SCHEDULE.INTERVALS
    }
  ];

  return recurrence;
}

export function getSelectOptionForRolesRequest(
  school: School,
  permissions: Permission[],
  isFirstRequest: boolean
): SelectOption[] {
  const allowedPermissionPresets = propz.get(school, ['allowedPermissionPresets']);
  let roles = [];

  for (let role in allowedPermissionPresets) {
    const isRoleAllowed = allowedPermissionPresets[role];

    if (isRoleAllowed) {
      switch (role) {
        case ROLE.STUDENT: {
          const studentSelfRegistrationEnabled = propz.get(school, ['studentSelfRegistrationEnabled']);

          if (isFirstRequest && studentSelfRegistrationEnabled) {
            roles.push({ value: role, text: ROLE_SERVER_TO_CLIENT_MAPPING[role] });
          }
          break;
        }
        case ROLE.TOURNAMENT_FOLLOWER: {
          const kind = propz.get(school, ['kind']);
          const isSchoolUnion = kind === KIND.SCHOOL_UNION;

          if (isSchoolUnion) {
            roles.push({ value: role, text: ROLE_SERVER_TO_CLIENT_MAPPING[role] });
          }
          break;
        }
        case ROLE.ADMIN: {
          const isAdminAvailable = permissions.every(
            permission => permission.schoolId !== school.id || permission.preset !== ROLE.ADMIN
          );

          if (isAdminAvailable) {
            roles.push({ value: role, text: ROLE_SERVER_TO_CLIENT_MAPPING[role] });
          }
          break;
        }
        case ROLE_PUBLIC_BLOGGER: {
          break;
        }
        default: {
          roles.push({ value: role, text: ROLE_SERVER_TO_CLIENT_MAPPING[role] });
          break;
        }
      }
    }
  }
  return roles;
}

export function getSelectOptionForAggregationType(): SelectOption[] {
  return [
    {
      text: LEAGUE_AGGREGATION_TYPE_SERVER_TO_CLIENT_MAPPING.HOUSE,
      value: LEAGUE_AGGREGATION_TYPE.HOUSE
    },
    {
      text: LEAGUE_AGGREGATION_TYPE_SERVER_TO_CLIENT_MAPPING.TEAM,
      value: LEAGUE_AGGREGATION_TYPE.TEAM
    },
    {
      text: LEAGUE_AGGREGATION_TYPE_SERVER_TO_CLIENT_MAPPING.INDIVIDUAL,
      value: LEAGUE_AGGREGATION_TYPE.INDIVIDUAL
    }
  ];
}

export function getSelectOptionForClubInvitationEnumQuestion(enumOptions): SelectOption[] {
  const options = enumOptions.map(option => {
    return { text: option, value: option };
  });

  return options;
}

export function getSelectOptionsForSubTypeEvent(): SelectOption[] {
  const subTypesEvent = [];

  for (let key in EVENT_SUB_TYPES_SERVER_TO_CLIENT_MAPPING) {
    subTypesEvent.push({
      text: EVENT_SUB_TYPES_SERVER_TO_CLIENT_MAPPING[key],
      value: EVENT_SUB_TYPES_SERVER_TO_CLIENT_MAPPING[key]
    });
  }
  return subTypesEvent;
}

export function getSelectOptionsForFilesCategory(): SelectOption[] {
  const category = [];
  for (let key in FILES_CATEGORY) {
    category.push({
      text: FILES_CATEGORY_SERVER_TO_CLIENT_MAPPING[key],
      value: FILES_CATEGORY[key]
    });
  }
  return category;
}

export function getSelectOptionForAvailableAges(): SelectOption[] {
  const max = AVAILABLE_AGES_MAX;
  const min = AVAILABLE_AGES_MIN;
  let i = min;
  let result = [];

  while (i < max) {
    result.push(i);
    i++;
  }

  return result.map(age => ({
    text: propz.get(AGE_GROUPS, ['ENGLISH', age]),
    value: age
  }));
}

export function getSelectOptionForAvailableGender(): SelectOption[] {
  return [
    {
      text: SPORT_GENDER_SERVER_TO_CLIENT_MAPPING.MALE_ONLY,
      value: GENDER.MALE_ONLY
    },
    {
      text: SPORT_GENDER_SERVER_TO_CLIENT_MAPPING.FEMALE_ONLY,
      value: GENDER.FEMALE_ONLY
    },
    {
      text: SPORT_GENDER_SERVER_TO_CLIENT_MAPPING.MIXED,
      value: GENDER.MIXED
    }
  ];
}

export function getSelectOptionForAreaSU(schoolUnions) {
  return schoolUnions.map(schoolUnion => {
    return {
      text: schoolUnion.name,
      value: schoolUnion.id
    };
  });
}

export function getSelectOptionForPaymentStatus() {
  const options = [];

  for (let key in TRANSACTION_STATUS_SERVER_TO_CLIENT_MAPPING) {
    options.push({
      text: TRANSACTION_STATUS_SERVER_TO_CLIENT_MAPPING[key],
      value: key
    });
  }
  return options;
}

export function getSelectOptionForTournamentTag(tournamentTags): SelectOption[] {
  return tournamentTags.map(tournamentTag => {
    return {
      text: tournamentTag.tagName,
      value: tournamentTag.id
    };
  });
}
