import * as React from 'react';
import { Component } from 'react';
import * as propz from 'propz';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { SchoolForm } from '../../models/form';
import { AGE_GROUPS, AGE_GROUPS_SORTED } from '../../consts/school';
import { AppUser } from 'Src/views/App/App';
import { checkAvailabilityFormName } from '../../helpers/service/adminSU/schoolStudentsTable';

interface Props {
  isOpen: boolean;
  onCloseClick: () => void;
  onFormCreated: (data: any) => void;
  form?: SchoolForm;
  user: AppUser;
  selectedSchool: any;
}

interface State {
  nameForm: string;
  ageGroup: string;
}

export class SchoolUnionSchoolFormForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const isFormExists = this.isFormExist();

    const { form } = this.props;

    this.state = {
      nameForm: isFormExists ? propz.get(form, ['name'], '') : '',
      ageGroup: isFormExists ? propz.get(form, ['ageGroup'], '') : ''
    };
  }

  isFormExist(): boolean {
    const { form } = this.props;
    return typeof form !== 'undefined';
  }

  onSubmit = data => {
    this.props.onFormCreated(data);
  };

  getAgeGroup() {
    const { selectedSchool } = this.props;
    const ageGroupsNaming = selectedSchool ? selectedSchool.ageGroupsNaming : '';
    const ageGroups = propz.get(AGE_GROUPS_SORTED, [ageGroupsNaming], []);
    return ageGroups;
  }

  render() {
    const isFormExists = this.isFormExist();
    const positiveButtonTitle = isFormExists ? 'Save form' : 'Create form';
    const { user, selectedSchool } = this.props;

    const FormValidationSchema = Yup.object().shape({
      name: Yup.string()
        .required('Required')
        .test(
          'name',
          'You already have a form group matching this name assigned to the year group. ' +
            'Please change the name or select a different year group.',
          function(value) {
            const ageGroup = propz.get(this.parent, ['ageGroup']);
            const ageGroupsNaming = selectedSchool.ageGroupsNaming;
            const ageGroups = propz.get(AGE_GROUPS, [ageGroupsNaming]);
            const age = ageGroups.findIndex(group => group === ageGroup);
            const isExistAge = age !== -1;
            switch (true) {
              case !isExistAge:
                return true;
              case !value:
                return false;
              default:
                return checkAvailabilityFormName(user, selectedSchool.id, { name: value.trim(), age: age }).then(
                  res => {
                    return res.isAvailable;
                  }
                );
            }
          }
        ),
      ageGroup: Yup.string().required('Required')
    });

    return (
      <Formik
        onSubmit={this.onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{
          name: this.state.nameForm,
          ageGroup: this.state.ageGroup
        }}
        validationSchema={FormValidationSchema}
        render={({ touched, errors }) => (
          <Form>
            <div className="form-group">
              <label>Name Form</label>
              <Field
                type="text"
                name="name"
                className={`${touched.name && errors.name ? 'form-control is-invalid' : 'form-control '}`}
                placeholder="Enter name form"
              />
              {touched.name && errors.name ? <div className="invalid-feedback">{errors.name}</div> : null}
            </div>
            <div className="form-group">
              <label>Age Group</label>

              <Field component="select" name="ageGroup" className="form-control mb-3">
                <option key="Select Age Group" value="" disabled>
                  Select Age Group
                </option>
                {this.getAgeGroup().map(ageGroup => (
                  <option key={ageGroup} value={ageGroup}>
                    {ageGroup}
                  </option>
                ))}
              </Field>
            </div>
            <button type="button" className="btn btn-secondary mr-3" onClick={this.props.onCloseClick}>
              Cancel
            </button>
            <button type="submit" className="btn btn-primary">
              {positiveButtonTitle}
            </button>
          </Form>
        )}
      />
    );
  }
}
