import axios from 'axios';
import { AppUser } from 'Src/views/App/App';

export function sendTournamentInvite(
  user: AppUser,
  tournamentId: string,
  data: { invitedSchoolId: string },
  inviterSchoolId?: string
) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  const schoolId = typeof inviterSchoolId !== 'undefined' ? inviterSchoolId : activeSchoolId;

  return axios
    .post(`${window.apiBase}/i/schools/${schoolId}/tournaments/${tournamentId}/invites`, data, config)
    .then(response => {
      return response.data;
    });
}
