import * as React from 'react';
import { Component } from 'react';
import { parse } from 'query-string';
import * as Lazy from 'lazy.js';
import * as propz from 'propz';

import './CreateTournamentCustomNotificationsView.css';
import { CreateGeneralMessageButtons } from 'Src/components/CreateGeneralMessageButtons/CreateGeneralMessageButtons';
import { ProgressBar } from 'Src/components/ProgressBar/ProgressBar';
import { History, Location } from 'history';
import { AppUser } from 'Src/views/App/App';
import { TournamentCustomNotificationPreview } from './TournamentCustomNotificationPreview';
import { Button } from 'Src/components/Button/Button';
import { Tournament } from 'Src/models/tournament';
import { getTournament } from 'Src/helpers/service/adminSU/tournamentsTable';
import { sendTournamentNotification } from 'Src/helpers/service/adminSU/tournamentNotificationTable';
import { HTML_FIRST_PART_PAGE_CODE, HTML_LAST_PART_PAGE_CODE } from 'Src/components/HTMLEditorQuill/HTMLEditorConst';
import { uploadFileAllPath } from 'Src/helpers/service/file';
import { Attachment } from 'Src/models/generalMessage';
import { Loader } from 'Src/components/Loader/Loader';
import * as BPromise from 'bluebird';
import { getAvailableSchools } from 'Src/helpers/service/adminSU/schoolsTable';
import { TournamentCustomNotificationTextForm } from './TournamentCustomNotificationTextForm';
import { TournamentCustomNotifications } from '../TournamentCustomNotifications';
import { AVAILABLE_SCHOOL_TYPE } from 'Src/consts/school';
import { getSettingsUploadFiles } from '../../../../../helpers/service/nobody/settings';

enum MESSAGE_CREATING_STEPS {
  SCHOOL_LIST = 'SCHOOL_LIST',
  CONTENT = 'CONTENT',
  PREVIEW = 'PREVIEW'
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

interface MessageState {
  messageEmailSubject: string;
  messageEmailBody: string;
  messagePushTitle: string;
  messagePushBody: string;
  availableSchools: any[];
  updatedAvailableSchools: any[];

  currentStep: number;
  isError: boolean;
  errorMessage: string;
  tournament: Tournament;

  messageAttachments: Attachment[];
  isImageError: boolean;
  tournamentId: string;
  isLoading: boolean;
  filterText: string;
  isSelectAll: boolean;
  isSelectTournamentParticipant: boolean;
  isSelectTournamentWaitingList: boolean;
  filesSize: number;
  maxSizeUploadFile: number;

  isLoadingFile: boolean;
  isPushEditorEnabled: boolean;
  isEmailEditorEnabled: boolean;
}

export class CreateTournamentCustomNotificationsView extends Component<Props, MessageState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      currentStep: 0,
      isError: false,
      errorMessage: '',
      messageEmailSubject: '',
      messageEmailBody: '',
      messagePushTitle: '',
      messagePushBody: '',
      tournament: undefined,
      messageAttachments: [],
      isImageError: false,
      availableSchools: [],
      tournamentId: '',
      isLoading: false,
      filterText: '',
      updatedAvailableSchools: [],
      isSelectAll: false,
      isSelectTournamentParticipant: false,
      isSelectTournamentWaitingList: false,
      filesSize: 0,
      maxSizeUploadFile: 0,
      isLoadingFile: false,
      isPushEditorEnabled: true,
      isEmailEditorEnabled: true
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });
    this.setAdditionalItems().then(res => {
      this.setItems();
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const tournamentId = this.getTournamentId();
    this.setState({
      tournamentId: tournamentId
    });
  }

  setAdditionalItems() {
    const { user } = this.props;
    return getSettingsUploadFiles(user)
      .then(maxSizeUploadFile => {
        this.setState({
          maxSizeUploadFile: maxSizeUploadFile
        });

        return true;
      })
      .catch(error => {
        console.error(error);
        return true;
      });
  }

  setItems() {
    const { user } = this.props;
    const tournamentId = this.getTournamentId();

    const promises = [getTournament(user, tournamentId), getAvailableSchools(user, tournamentId)];

    return BPromise.all(promises).then(([tournament, availableSchools]) => {
      const transformAvailableSchools = Lazy(availableSchools)
        .map(school => ({
          schoolId: school.schoolId,
          schoolName: school.schoolName,
          type: school.type,
          checked: false
        }))
        .flatten()
        .uniq('schoolId')
        .toArray();

      this.setState({
        tournament: tournament,
        availableSchools: transformAvailableSchools,
        updatedAvailableSchools: transformAvailableSchools,
        isLoading: false
      });

      return true;
    });
  }

  onItemCheck = nextItems => {
    this.setState({
      updatedAvailableSchools: nextItems,
      availableSchools: nextItems
    });
  };

  onUpdateItemsEmptyFilterText = text => {
    this.setState({
      updatedAvailableSchools: this.state.availableSchools,
      filterText: text,
      isSelectAll: false,
      isSelectTournamentParticipant: false,
      isSelectTournamentWaitingList: false
    });
  };

  onUpdateItemsFilterText = (itemsFiltered, text) => {
    this.setState({
      updatedAvailableSchools: itemsFiltered,
      filterText: text
    });
  };

  onSelectAllClick = () => {
    const isSelectAll = this.state.isSelectAll,
      items = this.state.updatedAvailableSchools,
      newItems = items.map(item => {
        return {
          ...item,
          checked: !isSelectAll
        };
      });

    this.setState({
      isSelectAll: !isSelectAll,
      isSelectTournamentParticipant: !isSelectAll,
      isSelectTournamentWaitingList: !isSelectAll,
      updatedAvailableSchools: newItems
    });
  };

  onSelectTournamentParticipantClick = (): void => {
    const { isSelectTournamentParticipant, updatedAvailableSchools } = this.state;
    const newItems = updatedAvailableSchools.map(item => {
      if (item.type === AVAILABLE_SCHOOL_TYPE.TOURNAMENT_PARTICIPANT) {
        return {
          ...item,
          checked: !isSelectTournamentParticipant
        };
      } else {
        return {
          ...item
        };
      }
    });
    this.setState({
      isSelectTournamentParticipant: !isSelectTournamentParticipant,
      updatedAvailableSchools: newItems
    });
  };

  onSelectTournamentWaitingListClick = (): void => {
    const { isSelectTournamentWaitingList, updatedAvailableSchools } = this.state;
    const newItems = updatedAvailableSchools.map(item => {
      if (item.type === AVAILABLE_SCHOOL_TYPE.TOURNAMENT_WAITING_LIST) {
        return {
          ...item,
          checked: !isSelectTournamentWaitingList
        };
      } else {
        return {
          ...item
        };
      }
    });
    this.setState({
      isSelectTournamentWaitingList: !isSelectTournamentWaitingList,
      updatedAvailableSchools: newItems
    });
  };

  getTournamentId(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    const tournamentId = search.tournament;
    return tournamentId;
  }

  onNextClick = () => {
    const {
      currentStep,
      isPushEditorEnabled,
      isEmailEditorEnabled,
      messageEmailBody,
      messageEmailSubject,
      messagePushBody,
      messagePushTitle
    } = this.state;

    const steps = this.getSteps();
    const currentStepName = steps[currentStep];
    const isCurrentStepContent = currentStepName === MESSAGE_CREATING_STEPS.CONTENT;

    // returns an error if content is empty
    const isPushBodyExist = messagePushBody !== '';
    const isPushTitleExist = messagePushTitle !== '';
    const isEmailSubjectExist = messageEmailSubject !== '';
    const isEmailBodyExist = messageEmailBody !== '';

    const isShowPushError = (!isPushBodyExist || !isPushTitleExist) && isPushEditorEnabled;
    const isShowEmailError = (!isEmailSubjectExist || !isEmailBodyExist) && isEmailEditorEnabled;

    if (isCurrentStepContent && (isShowPushError || isShowEmailError)) {
      let errorText = '';

      switch (true) {
        case isShowPushError:
          errorText = 'Title or body for push content should be defined';
          break;
        case isShowEmailError:
          errorText = 'Subject or body for email content should be defined';
          break;
      }
      this.setState({
        isError: true,
        errorMessage: errorText
      });
    } else {
      this.setState(prevState => ({
        currentStep: prevState.currentStep + 1
      }));

      //clear search
      const { history } = this.props;
      const { search, ...rest } = history.location;
      history.replace(rest);
    }
  };

  onPrevClick = () => {
    this.setState(prevState => ({
      currentStep: prevState.currentStep - 1
    }));
  };

  onEmailSubjectChange = event => {
    this.setState({
      messageEmailSubject: event.target.value
    });
  };

  onEmailBodyEmptyTextChange = emptyText => {
    this.setState({
      messageEmailBody: emptyText
    });
  };

  onEmailBodyChange = editorState => {
    this.setState({
      messageEmailBody: editorState
    });
  };

  onPushTitleChange = event => {
    this.setState({
      messagePushTitle: event.target.value
    });
  };

  onPushBodyChange = event => {
    this.setState({
      messagePushBody: event.target.value
    });
  };

  onEmailAttachmentChange = (event): void => {
    const { user } = this.props;

    const { filesSize, maxSizeUploadFile } = this.state;
    const attachments = [...this.state.messageAttachments];

    const file = event.target.files[0];
    const currentFileSize = event.target.files[0].size;
    const fileName = file.name;
    const fileNameWithoutSpaces = fileName.split(' ').join('_');
    const formData = new FormData();
    formData.append('file', file, fileNameWithoutSpaces);

    const allFilesSize = filesSize + currentFileSize;

    if (allFilesSize > maxSizeUploadFile) {
      let maxSizeUploadFileText = '0 Mb';
      if (maxSizeUploadFile > 0) {
        maxSizeUploadFileText = `${(maxSizeUploadFile / 1000000).toFixed(0)}Mb`;
      }
      const errorText = `The message cannot be sent as the total size of attachments is too large.
      Please ensure your attachments don't exceed ${maxSizeUploadFileText} and try sending a message again.`;
      this.setState({
        errorMessage: errorText,
        isError: true
      });
    } else {
      this.setState({
        isLoadingFile: true
      });
      uploadFileAllPath(user, formData)
        .then(data => {
          const { key, link } = data;
          attachments.push({
            fileName: fileNameWithoutSpaces,
            link: link,
            externalFileId: key,
            fileSize: currentFileSize
          });
          this.setState({
            messageAttachments: attachments,
            filesSize: allFilesSize,
            isLoadingFile: false
          });
        })
        .catch(error => {
          this.setState({
            isImageError: true,
            isLoadingFile: false
          });
        });
    }
  };

  onRemoveAttachment = (key: string) => {
    const { filesSize } = this.state;
    const attachments = [...this.state.messageAttachments];
    const attachmentIndex = attachments.findIndex(attachment => attachment.externalFileId === key);
    const attachment = attachments.find(attachment => attachment.externalFileId === key);

    attachments.splice(attachmentIndex, 1);
    const newFileSize = filesSize - propz.get(attachment, ['fileSize'], 0);

    this.setState({
      messageAttachments: attachments,
      filesSize: newFileSize
    });
  };

  onCloseImageError = () => {
    this.setState({
      isImageError: false
    });
  };

  onCloseErrorClick = event => {
    event.preventDefault();

    this.setState({
      isError: false,
      errorMessage: ''
    });
  };

  onShowError = (message: string) => {
    const errorMessage = typeof message === 'string' ? message : 'Error saving notification';

    this.setState({
      isError: true,
      errorMessage: errorMessage
    });
  };

  getSteps(): MESSAGE_CREATING_STEPS[] {
    let steps = [MESSAGE_CREATING_STEPS.SCHOOL_LIST];
    steps.push(MESSAGE_CREATING_STEPS.CONTENT);
    steps.push(MESSAGE_CREATING_STEPS.PREVIEW);
    return steps;
  }

  getStepTitle(): String {
    const { currentStep } = this.state;
    const steps = this.getSteps();
    const currentStepName = steps[currentStep];

    switch (currentStepName) {
      case MESSAGE_CREATING_STEPS.SCHOOL_LIST:
        return 'Select school';

      case MESSAGE_CREATING_STEPS.CONTENT:
        return 'Notification content';

      case MESSAGE_CREATING_STEPS.PREVIEW:
        return 'Preview';
    }
  }

  getAttachmentAsLinksString = () => {
    const { messageAttachments } = this.state;
    if (messageAttachments.length > 0) {
      const attachmentAsLink = messageAttachments
        .map((attachment, index) => {
          return `<div key='attachment_${index}'><a href=${attachment.link} target="_blank">${attachment.fileName}</a></div>`;
        })
        .join('');
      return `<div>Attachments: ${attachmentAsLink}</div>`;
    } else {
      return '';
    }
  };

  onIsEmailEditorChange = event => {
    const isEmailEditorEnabled = event.target.checked;

    if (!isEmailEditorEnabled) {
      this.setState({
        messageEmailSubject: '',
        messageEmailBody: '',
        messageAttachments: []
      });
    }

    this.setState({
      isEmailEditorEnabled: isEmailEditorEnabled
    });
  };

  onIsPushEditorChange = event => {
    const isPushEditorEnabled = event.target.checked;
    if (!isPushEditorEnabled) {
      this.setState({
        messagePushBody: '',
        messagePushTitle: ''
      });
    }
    this.setState({ isPushEditorEnabled: isPushEditorEnabled });
  };

  onSubmitCustomNotificationsForm = () => {
    const {
      messageEmailSubject,
      messageEmailBody,
      messagePushTitle,
      messagePushBody,
      tournament,
      updatedAvailableSchools
    } = this.state;

    const { user } = this.props;
    const checkedSchoolIds = updatedAvailableSchools.filter(item => item.checked).map(school => school.schoolId);
    const messageEmailBodyWithAttachment = messageEmailBody + this.getAttachmentAsLinksString();
    const messageHTMLEmailBody =
      messageEmailBody !== ''
        ? `${HTML_FIRST_PART_PAGE_CODE} ${messageEmailBodyWithAttachment} ${HTML_LAST_PART_PAGE_CODE}`
        : '';
    const data = {
      schoolIds: checkedSchoolIds,
      title: messageEmailSubject,
      text: messageEmailBody !== '' ? messageHTMLEmailBody : '',
      content: {
        push: { title: messagePushTitle, body: messagePushBody }
      }
    };

    sendTournamentNotification(user, data, tournament.id)
      .then(empty => {
        this.goBackTournament(tournament.id);
      })
      .catch(reason => {
        console.error(reason.response.data.details.text);
        this.onShowError(reason.response.data.details.text);
      });
  };

  goBackTournament = (tournamentId): void => {
    const { history, location } = this.props;
    const { state } = location;
    const { search } = state as any;

    history.push({
      pathname: '/tournaments',
      search: search,
      state: { tournamentId: tournamentId }
    });
  };

  onCancelClick = () => {
    const { history, location } = this.props;
    const { state } = location;
    const { search } = state as any;
    const tournamentId = this.getTournamentId();

    history.push({
      pathname: '/tournaments',
      search: search,
      state: { tournamentId: tournamentId }
    });
  };

  isNextButtonDisabled(): boolean {
    const {
      currentStep,
      updatedAvailableSchools,
      isError,
      isLoadingFile,
      isPushEditorEnabled,
      isEmailEditorEnabled
    } = this.state;
    const steps = this.getSteps();
    const currentStepName = steps[currentStep];
    const isAllEditorDisabled = !isPushEditorEnabled && !isEmailEditorEnabled;

    let isNextButtonDisabled = false;
    const checkedSchools = updatedAvailableSchools.filter(item => item.checked);
    switch (true) {
      case currentStepName === MESSAGE_CREATING_STEPS.PREVIEW:
        isNextButtonDisabled = typeof checkedSchools === 'undefined' || checkedSchools.length === 0;
        break;
      case currentStepName === MESSAGE_CREATING_STEPS.CONTENT:
        isNextButtonDisabled = isError || isLoadingFile || isAllEditorDisabled;
        break;
      case isError:
        isNextButtonDisabled = true;
        break;
    }

    return isNextButtonDisabled;
  }

  renderViewByStep(): React.ReactNode {
    const { currentStep } = this.state;
    const steps = this.getSteps();
    const currentStepName = steps[currentStep];

    switch (currentStepName) {
      case MESSAGE_CREATING_STEPS.SCHOOL_LIST:
        return this.renderSchoolList();

      case MESSAGE_CREATING_STEPS.CONTENT:
        return this.renderMessageTextForm();

      case MESSAGE_CREATING_STEPS.PREVIEW:
        return this.renderMessagePreview();
    }
  }

  renderSchoolList(): React.ReactNode {
    const { updatedAvailableSchools } = this.state;
    return (
      <TournamentCustomNotifications
        user={this.props.user}
        history={this.props.history}
        location={this.props.location}
        availableSchools={updatedAvailableSchools}
        onUpdateItemsEmptyFilterText={this.onUpdateItemsEmptyFilterText}
        onUpdateItemsFilterText={this.onUpdateItemsFilterText}
        onSelectAllClick={this.onSelectAllClick}
        onSelectTournamentParticipantClick={this.onSelectTournamentParticipantClick}
        onSelectTournamentWaitingListClick={this.onSelectTournamentWaitingListClick}
        onItemCheck={this.onItemCheck}
        tournamentId={this.state.tournamentId}
        filterText={this.state.filterText}
        isSelectAll={this.state.isSelectAll}
        isSelectTournamentParticipant={this.state.isSelectTournamentParticipant}
        isSelectTournamentWaitingList={this.state.isSelectTournamentWaitingList}
      />
    );
  }

  renderMessageTextForm(): React.ReactNode {
    const {
      messageEmailSubject,
      messageEmailBody,
      messagePushTitle,
      messagePushBody,
      messageAttachments,
      isImageError,
      isError,
      isPushEditorEnabled,
      isEmailEditorEnabled,
      isLoadingFile
    } = this.state;

    return (
      <TournamentCustomNotificationTextForm
        onEmailSubjectChange={this.onEmailSubjectChange}
        onEmailBodyChange={this.onEmailBodyChange}
        onEmailBodyEmptyTextChange={this.onEmailBodyEmptyTextChange}
        onPushTitleChange={this.onPushTitleChange}
        onPushBodyChange={this.onPushBodyChange}
        onEmailAttachmentChange={this.onEmailAttachmentChange}
        onRemoveAttachment={this.onRemoveAttachment}
        isImageError={isImageError}
        isError={isError}
        onCloseImageError={this.onCloseImageError}
        messageEmailSubject={messageEmailSubject}
        messageEmailBody={messageEmailBody}
        messagePushTitle={messagePushTitle}
        messagePushBody={messagePushBody}
        messageAttachments={messageAttachments}
        isLoadingFile={isLoadingFile}
        isEmailEditorEnabled={isEmailEditorEnabled}
        isPushEditorEnabled={isPushEditorEnabled}
        onIsEmailEditorChange={this.onIsEmailEditorChange}
        onIsPushEditorChange={this.onIsPushEditorChange}
      />
    );
  }

  renderMessagePreview(): React.ReactNode {
    const {
      messageEmailSubject,
      messageEmailBody,
      messagePushTitle,
      messagePushBody,
      messageAttachments,
      updatedAvailableSchools
    } = this.state;

    const checkedSchools = updatedAvailableSchools.filter(item => item.checked);
    return (
      <TournamentCustomNotificationPreview
        messageEmailSubject={messageEmailSubject}
        messageEmailBody={messageEmailBody}
        messagePushTitle={messagePushTitle}
        messagePushBody={messagePushBody}
        messageAttachments={messageAttachments}
        affectedSchools={checkedSchools}
      />
    );
  }

  renderSendingError(): React.ReactNode {
    const { errorMessage, isError } = this.state;

    if (!isError) {
      return null;
    }

    return (
      <div className="alert fade show alert-danger d-flex justify-content-between align-items-center" role="alert">
        {errorMessage}
        <button
          type="button"
          className="ml-3 btn btn-danger"
          aria-label="Close"
          onClick={event => this.onCloseErrorClick(event)}
        >
          Close
        </button>
      </div>
    );
  }

  renderProgressBar() {
    const currentStep = this.state.currentStep + 1;
    const totalSteps = this.getSteps().length;
    const progress = (currentStep / totalSteps) * 100;

    return <ProgressBar progress={progress} step={currentStep} stepTotal={totalSteps} />;
  }

  render() {
    const stepCount = this.getSteps().length;
    const { currentStep, isError } = this.state;
    const hasNext = currentStep + 1 < stepCount;
    const hasPrev = currentStep > 0;
    const isNextButtonDisabled = this.isNextButtonDisabled();
    const { isLoading } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    return (
      <div className="bCreateTournamentCustomNotificationsView">
        {this.renderSendingError()}

        <h3 className="mb-3 d-flex">
          Create new notification
          <span className="text-muted">{` / ${this.getStepTitle()}`}</span>
          {!hasNext && (
            <div className="ml-auto">
              <Button
                onClick={this.onSubmitCustomNotificationsForm}
                text={'Send'}
                customClass={'btn btn-primary'}
                disabled={isNextButtonDisabled || isError}
              />
            </div>
          )}
        </h3>

        {this.renderProgressBar()}
        {this.renderViewByStep()}

        <CreateGeneralMessageButtons
          hasNext={hasNext}
          hasPrevious={hasPrev}
          onNextClick={this.onNextClick}
          onPreviousClick={this.onPrevClick}
          onSendClick={() => {}}
          onCancelClick={this.onCancelClick}
          isNextButtonDisabled={isNextButtonDisabled}
          isError={isError}
        />
      </div>
    );
  }
}
