import * as React from 'react';
import * as Lazy from 'lazy.js';
import { AppUser } from '../../../../../App/App';
import { History, Location } from 'history';
import {
  ColumnDefinition,
  getOrder,
  getServerFieldSectionWhere,
  isFilterExist2,
  TABLE_SORT_DIRECTION
} from '../../../../../../helpers/table/table';
import { DATE_INTERVAL, DEFAULT_LIMIT, DEFAULT_SKIP, FILTER_TYPE, LIMIT } from '../../../../../../consts/table';
import { getAge, getEventType, getGender } from '../../../../../../helpers/accessor/accessor';
import { EventClash, SchoolEvent } from '../../../../../../models/event';
import { getUserGenderArrayConvertedFromEventGender } from '../../../../../../helpers/tournament/tournament';
import {
  getEventClash,
  getEventTitle,
  getIndividualsMethod,
  getSchoolEventAges,
  getStudentsMethod,
  isHousesEvent,
  isClubEvent,
  isTournamentEvent
} from '../../../../../../helpers/event/event';
import { getFromHistory } from '../../../../../../helpers/history/history';
import { getTwoPanelEditorFilters } from '../../../../../../helpers/twoPanelEditor/twoPanelEditor';
import { getSchoolEventPlayersCount } from 'Src/helpers/service/admin/schoolEvents';
import { getAllClubParticipants } from 'Src/helpers/service/admin/clubParticipants';
import { addClubStudents } from 'Src/helpers/service/admin/clubStudents';
import * as BPromise from 'bluebird';
import { getAllForms } from 'Src/helpers/service/admin/forms';
import { getAllHouses } from 'Src/helpers/service/admin/houses';
import { getSchoolEvent } from 'Src/helpers/service/admin/event';
import * as propz from 'propz';
import { Loader } from '../../../../../../components/Loader/Loader';
import {
  getSelectOptionForAge,
  getSelectOptionForForms,
  getSelectOptionForGender,
  getSelectOptionForHouses
} from '../../../../../../helpers/table/select';
import { TwoPanelEditor } from '../../../../../../components/TwoPanelEditor/TwoPanelEditor';
import { Button } from '../../../../../../components/Button/Button';
import {
  getSchoolStudentsWithClashesCount,
  getSchoolStudentsWithoutClashes
} from '../../../../../../helpers/service/admin/schoolTeamPlayers';
import { AGE_GROUPS } from '../../../../../../consts/school';
import * as Moment from 'moment';
import { getColorClashes } from '../../../../../../helpers/cell/cell';
import { SchoolStudentClash } from '../../../../../../models/schoolStudent';
import { SchoolForm } from '../../../../../../models/form';
import { SchoolHouse } from '../../../../../../models/house';
import { SimpleModal } from '../../../../../../components/SimpleModal/SimpleModal';
import { SPORT_GENDER_SERVER_TO_CLIENT_MAPPING } from '../../../../../../consts/common';
import { getEventStartDateAndTime } from '../../../../../../helpers/event/schoolEventViewHelper';
import { LabelWithQuestionIcon } from '../../../../../../components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import {
  updateSchoolEventIndividualsBatch,
  updateSchoolEventIndividualsGroupBatch
} from '../../../../../../helpers/service/admin/schoolEventIndividuals';
import { SchoolEventIndividualWithClashes } from '../../../../../../models/eventIndividuals';
import { CLUB_EVENT_EDIT_MODE } from '../../../../../../consts/event';
import { SchoolEventClubEventRadioButtons } from '../SchoolEventClubEventRadioButtons/SchoolEventClubEventRadioButtons';
import { getSchoolTournamentTeams, addSchoolTournamentTeam } from 'Src/helpers/service/admin/tournamentTeams';
import { updateSchoolTournamentTeamPlayersBatch } from 'Src/helpers/service/admin/tournamentTeamPlayers';
import { TEAM_TYPE } from 'Src/consts/team';
import { TournamentTeam } from 'Src/models/tournamentTeam';

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

interface Item {
  id: string;
  permissionId: string;
  form: any;
  house: any;
  gender: string;
  firstName: string;
  lastName: string;
  clashes: {
    status: string;
    fullClashEvents: EventClash[];
    intervalClashEvents: EventClash[];
  };
  playerId?: string;
}

interface State {
  items: Item[];
  itemsSelected: Item[];
  itemsCount: number;

  itemsFilters: any;
  isShowItemsFilter: boolean;
  isItemsFiltered: boolean;

  isSelectAllItemsChecked: boolean;

  sortItemsDirection: TABLE_SORT_DIRECTION;
  sortItemsColumnsName: string;

  itemCurrentPage: number;

  itemsAccumulatorInitial: Item[];
  itemsAccumulator: Item[];
  itemsAccumulatorSelected: Item[];
  itemsAccumulatorCount: number;

  itemsAccumulatorFilters: any;
  isShowItemsAccumulatorFilter: boolean;
  isItemsAccumulatorFiltered: boolean;

  isSelectAllItemsAccumulatorChecked: boolean;

  sortItemsAccumulatorDirection: TABLE_SORT_DIRECTION;
  sortItemsAccumulatorColumnsName: string;

  isLoading: boolean;

  itemsAddBuffer: Item[];
  itemsRemoveBuffer: Item[];

  forms: SchoolForm[];
  houses: SchoolHouse[];
  tournamentTeams: TournamentTeam[];

  event: SchoolEvent;
  editMode: string;
  isShowFollowingEventsSection: boolean;

  studentEventsModalText: string;
  isStudentEventsModalOpen: boolean;
}

const ITEMS_ACCUMULATOR_COLUMNS: ColumnDefinition[] = [
  {
    text: 'Surname',
    field: 'lastName',
    accessor: ['lastName'],
    type: FILTER_TYPE.NONE,
    isSort: false
  },
  {
    text: 'Name',
    field: 'firstName',
    accessor: ['firstName'],
    type: FILTER_TYPE.NONE,
    isSort: false
  },
  {
    text: 'Form',
    field: 'forms',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: ['form', 'name']
  },
  {
    text: 'House',
    field: 'house',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: ['house', 'name']
  },
  {
    text: 'Age',
    field: 'ages',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getAge
  },
  {
    text: 'Gender',
    field: 'gender',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getGender
  }
];

const ITEMS_ACCUMULATOR_COLUMNS_WITH_CLASHES: ColumnDefinition[] = [
  {
    text: 'Clashes',
    field: 'clashes',
    cell: getColorClashes,
    type: FILTER_TYPE.NONE,
    isSort: false
  },
  {
    text: 'Surname',
    field: 'lastName',
    accessor: ['lastName'],
    type: FILTER_TYPE.NONE,
    isSort: false
  },
  {
    text: 'Name',
    field: 'firstName',
    accessor: ['firstName'],
    type: FILTER_TYPE.NONE,
    isSort: false
  },
  {
    text: 'Form',
    field: 'forms',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: ['form', 'name']
  },
  {
    text: 'House',
    field: 'house',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: ['house', 'name']
  },
  {
    text: 'Age',
    field: 'ages',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getAge
  },
  {
    text: 'Gender',
    field: 'gender',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getGender
  }
];

const ITEMS_COLUMNS: ColumnDefinition[] = [
  {
    text: 'Surname',
    field: 'lastName',
    accessor: ['lastName'],
    type: FILTER_TYPE.TEXT,
    isSort: false
  },
  {
    text: 'Name',
    field: 'firstName',
    accessor: ['firstName'],
    type: FILTER_TYPE.TEXT,
    isSort: false
  },
  {
    text: 'Form',
    field: 'forms',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: ['form', 'name']
  },
  {
    text: 'House',
    field: 'houses',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: ['house', 'name']
  },
  {
    text: 'Age',
    field: 'ages',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getAge
  },
  {
    text: 'Gender',
    field: 'gender',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getGender
  }
];

const ITEMS_COLUMNS_WITH_CLASHES: ColumnDefinition[] = [
  {
    text: 'Clashes',
    field: 'clashes',
    cell: getColorClashes,
    type: FILTER_TYPE.NONE,
    isSort: false
  },
  {
    text: 'Surname',
    field: 'lastName',
    accessor: ['lastName'],
    type: FILTER_TYPE.TEXT,
    isSort: false
  },
  {
    text: 'Name',
    field: 'firstName',
    accessor: ['firstName'],
    type: FILTER_TYPE.TEXT,
    isSort: false
  },
  {
    text: 'Form',
    field: 'forms',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: ['form', 'name']
  },
  {
    text: 'House',
    field: 'houses',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: ['house', 'name']
  },
  {
    text: 'Age',
    field: 'ages',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getAge
  },
  {
    text: 'Gender',
    field: 'gender',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getGender
  }
];

const DEFAULT_INDIVIDUAL_PLAYERS_ORDER = 'firstName ASC';

export class SchoolIndividualManager extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      itemsCount: 0,
      itemsSelected: [],

      itemsFilters: {},
      isShowItemsFilter: false,
      isItemsFiltered: false,

      isSelectAllItemsChecked: false,

      sortItemsDirection: '',
      sortItemsColumnsName: '',

      itemCurrentPage: 1,

      itemsAccumulatorInitial: [],
      itemsAccumulator: [],
      itemsAccumulatorCount: 0,
      itemsAccumulatorSelected: [],

      itemsAccumulatorFilters: {},
      isShowItemsAccumulatorFilter: false,
      isItemsAccumulatorFiltered: false,

      isSelectAllItemsAccumulatorChecked: false,

      sortItemsAccumulatorDirection: '',
      sortItemsAccumulatorColumnsName: '',

      isLoading: true,

      itemsAddBuffer: [],
      itemsRemoveBuffer: [],

      forms: [],
      houses: [],
      event: undefined,
      tournamentTeams: [],
      studentEventsModalText: '',
      isStudentEventsModalOpen: false,

      isShowFollowingEventsSection: false,
      editMode: CLUB_EVENT_EDIT_MODE.SINGLE
    };
  }

  getDefaultItemsFilter(event: SchoolEvent) {
    const { gender, ages } = event;
    const genderArrayConverted = getUserGenderArrayConvertedFromEventGender(gender);
    const houseId = this.getHouseId();

    return isHousesEvent(event)
      ? {
          firstName: '',
          lastName: '',
          gender: genderArrayConverted,
          forms: [],
          ages: ages,
          houses: [houseId]
        }
      : {
          firstName: '',
          lastName: '',
          gender: genderArrayConverted,
          forms: [],
          ages: ages
        };
  }

  getDefaultItemsAccumulatorFilter(event: SchoolEvent) {
    const { gender, ages } = event;
    const genderArrayConverted = getUserGenderArrayConvertedFromEventGender(gender);
    const houseId = this.getHouseId();

    return isHousesEvent(event)
      ? {
          firstName: '',
          lastName: '',
          gender: genderArrayConverted,
          forms: [],
          ages: ages,
          houses: [houseId]
        }
      : {
          firstName: '',
          lastName: '',
          gender: genderArrayConverted,
          forms: [],
          ages: ages
        };
  }

  getHouseId(): string {
    const { history } = this.props;
    return getFromHistory(history, 'house');
  }

  getEventId(): string {
    const { history } = this.props;
    return getFromHistory(history, 'event');
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    const { user } = this.props;
    const eventId = this.getEventId();

    const itemsFilters = getTwoPanelEditorFilters(ITEMS_COLUMNS);
    const isShowItemsFilter = isFilterExist2(itemsFilters);
    const isItemsFiltered = isFilterExist2(itemsFilters);

    const itemsAccumulatorFilters = getTwoPanelEditorFilters(ITEMS_ACCUMULATOR_COLUMNS);
    const isShowItemsAccumulatorFilter = isFilterExist2(itemsAccumulatorFilters);
    const isItemsAccumulatorFiltered = isFilterExist2(itemsAccumulatorFilters);

    const formsPromise = getAllForms(user);
    const housesPromise = getAllHouses(user);
    const eventPromise = getSchoolEvent(user, eventId);

    BPromise.all([formsPromise, housesPromise, eventPromise])
      .then(([forms, houses, event]) => {
        const defaultItemsFilter = this.getDefaultItemsFilter(event);
        const defaultItemsAccumulatorFilter = this.getDefaultItemsAccumulatorFilter(event);

        this.setState({
          forms: forms,
          houses: houses,
          itemsFilters: defaultItemsFilter,
          itemsAccumulatorFilters: defaultItemsAccumulatorFilter,
          event: event
        });

        const tournamentTeamsPromise = isTournamentEvent(event)
          ? getSchoolTournamentTeams(user, event.tournamentId)
          : BPromise.resolve(undefined);

        return BPromise.all([this.getItemsAndItemsAccumulator(), tournamentTeamsPromise]);
      })
      .then(([{ playersInitial, players, playersCount, students, studentsCount }, tournamentTeams]) => {
        const studentsNormalized = this.getStudentsNormalized(students);
        const playersNormalized = this.getPlayersNormalized(players);
        const playersInitialNormalized = this.getPlayersNormalized(playersInitial);

        this.setState({
          items: studentsNormalized,
          itemsCount: studentsCount.count,
          isShowItemsFilter: isShowItemsFilter,
          isItemsFiltered: isItemsFiltered,

          itemsAccumulatorInitial: playersInitialNormalized,
          itemsAccumulator: playersNormalized,
          itemsAccumulatorCount: playersCount.count,
          isShowItemsAccumulatorFilter: isShowItemsAccumulatorFilter,
          isItemsAccumulatorFiltered: isItemsAccumulatorFiltered,
          tournamentTeams,

          isLoading: false
        });
      });
  }

  getPlayersNormalized(players: SchoolEventIndividualWithClashes[]): Item[] {
    return players.map(player => {
      const { permissionId, form, gender, firstName, lastName, userId, id, house, clashes } = player;
      return {
        permissionId,
        form,
        house,
        gender,
        firstName,
        lastName,
        clashes,
        id: userId,
        playerId: id
      };
    });
  }

  getStudentsNormalized(students: SchoolStudentClash[]): Item[] {
    return students.map(student => {
      const { permissionId, form, gender, firstName, lastName, id, house, clashes } = student;

      return {
        permissionId,
        form,
        house,
        gender,
        firstName,
        lastName,
        clashes,
        id
      };
    });
  }

  getItemsFilter(page, itemsAccumulator, itemsAddBuffer, isAllItems = false) {
    const { itemsFilters, forms, event } = this.state;

    const { startTime, endTime, players, id } = event;

    const {
      gender: itemsFiltersGender,
      ages: itemsFiltersAges = [],
      firstName: itemsFiltersFirstName = '',
      lastName: itemsFiltersLastName = '',
      forms: itemsFiltersForms = [],
      houses: itemsFiltersHouses = []
    } = itemsFilters;

    let where = {
      eventId: id,
      gender: {
        $in: itemsFiltersGender
      }
    };

    const playerUserIds = players
      .filter(player => {
        if (isHousesEvent(event)) {
          const houseId = this.getHouseId();
          return player.houseId !== houseId;
        } else {
          return false;
        }
      })
      .map(player => ({
        id: player.userId
      }));

    const allItems = [...itemsAccumulator, ...itemsAddBuffer, ...playerUserIds];
    const ninIds = Lazy(allItems)
      .map(item => item.id)
      .uniq()
      .toArray();
    propz.set(where, ['_id', '$nin'], ninIds);

    if (itemsFiltersForms.length > 0) {
      propz.set(where, ['formId', '$in'], itemsFiltersForms);
    } else {
      const ages = itemsFiltersAges.length > 0 ? itemsFiltersAges : AGE_GROUPS.ENGLISH.map((age, index) => index);
      const formIds = forms.filter(form => ages.some(age => Number(form.age) === Number(age))).map(form => form.id);

      if (formIds.length > 0) {
        propz.set(where, ['formId', '$in'], formIds);
      }
    }

    if (isHousesEvent(event)) {
      const houseId = this.getHouseId();
      propz.set(where, ['houseId'], houseId);
    }

    if (itemsFiltersHouses.length > 0) {
      propz.set(where, ['houseId', '$in'], itemsFiltersHouses);
    }

    let and = [];

    if (itemsFiltersFirstName.length > 0) {
      and.push({ firstName: { like: itemsFiltersFirstName, options: 'i' } });
    }

    if (itemsFiltersLastName.length > 0) {
      and.push({ lastName: { like: itemsFiltersLastName, options: 'i' } });
    }

    if (and.length > 0) {
      propz.set(where, ['$and'], and);
    }

    // copy-paste from old frontend
    const startTimeDate = new Date(startTime);
    const startTimeCopy = new Date(startTime);
    startTimeCopy.setHours(startTimeDate.getHours() - 1);
    const intervalClashStartDate = Moment(startTimeCopy)
      .utc()
      .format();

    const endTimeDate = new Date(endTime);
    const endTimeCopy = new Date(endTime);
    endTimeCopy.setHours(endTimeDate.getHours() + 1);
    const intervalClashEndDate = Moment(endTimeCopy)
      .utc()
      .format();

    propz.set(where, ['intervalClashStartDate'], intervalClashStartDate);
    propz.set(where, ['intervalClashEndDate'], intervalClashEndDate);
    propz.set(where, ['fullClashStartDate'], startTime);
    propz.set(where, ['fullClashEndDate'], endTime);

    const filter = {
      where: where,
      limit: isAllItems ? DEFAULT_LIMIT : LIMIT,
      skip: isAllItems ? 0 : (page - 1) * LIMIT,
      order: DEFAULT_INDIVIDUAL_PLAYERS_ORDER
    };

    return filter;
  }

  getItemsAndItemsAccumulator() {
    const { user } = this.props;
    const { itemsAccumulatorFilters, forms, event } = this.state;

    const options = { forms };
    const where = getServerFieldSectionWhere(itemsAccumulatorFilters, options);

    const eventId = this.getEventId();
    if (isHousesEvent(event)) {
      const houseId = this.getHouseId();
      propz.set(where, ['houseId', '$in'], [houseId]);
    }

    const getPlayersInitialPromise = getIndividualsMethod(user)(user, eventId, { where });
    const getPlayersPromise = getIndividualsMethod(user)(user, eventId, { where });
    const getPlayersCountPromise = getSchoolEventPlayersCount(user, eventId, where);

    let playersInitial, players, playersCount;

    return BPromise.all([getPlayersInitialPromise, getPlayersPromise, getPlayersCountPromise])
      .then(([_playersInitial, _players, _playersCount]) => {
        playersInitial = _playersInitial;
        players = _players;
        playersCount = _playersCount;

        const playersInitialNormalized = this.getPlayersNormalized(playersInitial);

        const filter = this.getItemsFilter(1, playersInitialNormalized, []);

        const method = getStudentsMethod(user);
        const getStudentsPromise = method(user, filter);
        const getStudentsCountPromise = getSchoolStudentsWithClashesCount(user, filter);

        return BPromise.all([getStudentsPromise, getStudentsCountPromise]);
      })
      .then(([students, studentsCount]) => {
        return {
          playersInitial,
          players,
          playersCount,
          students,
          studentsCount
        };
      });
  }

  onItemClick = (index: number): void => {
    const { items, itemsSelected } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = itemsSelected.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...itemsSelected];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      itemsAccumulatorSelected: [],
      itemsSelected: selectedItemsUpdated
    });
  };

  onItemAccumulatorClick = (index: number): void => {
    const { itemsAccumulator, itemsAccumulatorSelected } = this.state;
    const selectedItem = itemsAccumulator[index];

    const selectedItemIndex = itemsAccumulatorSelected.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...itemsAccumulatorSelected];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      itemsAccumulatorSelected: selectedItemsUpdated,
      itemsSelected: []
    });
  };

  onAddClick = () => {
    const { itemsSelected, itemsAddBuffer, itemsAccumulatorInitial, itemCurrentPage } = this.state;
    const { user } = this.props;

    const nextItemsAddBuffer = [...itemsAddBuffer, ...itemsSelected];

    this.setState({
      isLoading: true
    });

    const filter = this.getItemsFilter(itemCurrentPage, itemsAccumulatorInitial, nextItemsAddBuffer);
    const method = getStudentsMethod(user);
    const getStudentsPromise = method(user, filter);
    const getStudentsCountPromise = getSchoolStudentsWithClashesCount(user, filter);

    BPromise.all([getStudentsPromise, getStudentsCountPromise]).then(([students, studentsCount]) => {
      const studentsNormalized = this.getStudentsNormalized(students);

      this.setState({
        items: studentsNormalized,
        isLoading: false,
        itemsCount: studentsCount.count,
        itemsAddBuffer: nextItemsAddBuffer,
        itemsAccumulatorSelected: [],
        itemsSelected: [],
        isSelectAllItemsAccumulatorChecked: false,
        isSelectAllItemsChecked: false
      });
    });
  };

  onRemoveClick = () => {
    const {
      itemsAccumulatorSelected,
      itemsAddBuffer,
      itemsRemoveBuffer,
      itemsAccumulatorInitial,
      itemsAccumulator,
      itemCurrentPage
    } = this.state;
    const { user } = this.props;

    const nextItemsRemoveBuffer = [...itemsAccumulatorSelected, ...itemsRemoveBuffer];
    const nextItemsAccumulatorInitial = itemsAccumulatorInitial.filter(itemAccumulatorInitial =>
      nextItemsRemoveBuffer.every(nextItemRemoveBuffer => nextItemRemoveBuffer.id !== itemAccumulatorInitial.id)
    );
    const nextItemsAccumulator = itemsAccumulator.filter(itemAccumulator =>
      nextItemsRemoveBuffer.every(nextItemRemoveBuffer => nextItemRemoveBuffer.id !== itemAccumulator.id)
    );

    this.setState({
      isLoading: true
    });

    const filter = this.getItemsFilter(itemCurrentPage, nextItemsAccumulatorInitial, itemsAddBuffer);
    const method = getStudentsMethod(user);
    const getStudentsPromise = method(user, filter);
    const getStudentsCountPromise = getSchoolStudentsWithClashesCount(user, filter);

    BPromise.all([getStudentsPromise, getStudentsCountPromise]).then(([students, studentsCount]) => {
      const studentsNormalized = this.getStudentsNormalized(students);

      this.setState({
        items: studentsNormalized,
        isLoading: false,
        itemsCount: studentsCount.count,
        itemsAccumulatorSelected: [],
        itemsSelected: [],
        isSelectAllItemsAccumulatorChecked: false,
        isSelectAllItemsChecked: false,
        itemsRemoveBuffer: nextItemsRemoveBuffer,
        itemsAccumulatorInitial: nextItemsAccumulatorInitial,
        itemsAccumulator: nextItemsAccumulator,
        itemsAccumulatorCount: nextItemsAccumulator.length
      });
    });
  };

  onClickSave = () => {
    const {
      itemsAddBuffer,
      itemsRemoveBuffer,
      editMode,
      isShowFollowingEventsSection,
      itemsAccumulator,
      event,
      tournamentTeams
    } = this.state;
    const { clubId } = event;
    const { user, history } = this.props;
    const { activeSchoolId } = user;

    const groupEditMode = editMode === CLUB_EVENT_EDIT_MODE.GROUP;
    const eventId = this.getEventId();

    switch (true) {
      case isClubEvent(event) && !isShowFollowingEventsSection:
        this.showFollowingEventsSection();

        break;

      case isClubEvent(event) && groupEditMode:
        {
          this.setState({
            isLoading: true
          });

          const players = [...itemsAddBuffer, ...itemsAccumulator];
          const playersCommit = players.map(player => {
            return {
              userId: player.id,
              permissionId: player.permissionId
            };
          });

          const teamPromise = isClubEvent(event)
            ? BPromise.all([
                getAllClubParticipants(user, clubId),
                updateSchoolEventIndividualsGroupBatch(user, eventId, { participants: playersCommit })
              ])
            : updateSchoolEventIndividualsGroupBatch(user, eventId, { participants: playersCommit });

          teamPromise
            .then(response => {
              if (isClubEvent(event)) {
                const [participants] = response;

                const studentsAreNotClubParticipants = itemsAddBuffer.filter(item => {
                  return !participants.some(participant => {
                    return participant.userId === item.id;
                  });
                });

                const isStudentsAreNotClubParticipantsExist = studentsAreNotClubParticipants.length > 0;

                if (isStudentsAreNotClubParticipantsExist) {
                  const studentToAddData = {
                    participants: studentsAreNotClubParticipants.map(student => {
                      return {
                        userId: student.id,
                        permissionId: student.permissionId
                      };
                    })
                  };

                  return addClubStudents(user, clubId, studentToAddData);
                }
              }
            })
            .then(() => {
              history.push({
                pathname: '/events/event',
                search: `id=${eventId}`
              });
            });
        }

        break;

      case isTournamentEvent(event):
        const { tournamentId } = event;

        const currentSchoolTeam = tournamentTeams.find(team => team.schoolId === activeSchoolId);
        const isCurrentSchoolTeamExist = typeof currentSchoolTeam !== 'undefined';

        const tournamentTeamsPlayers = propz.get(currentSchoolTeam, ['players'], []);
        const tournamentTeamsPlayersIds = tournamentTeamsPlayers.map(player => player.userId);

        const studentsToAddToTeam = itemsAddBuffer.filter(item => !tournamentTeamsPlayersIds.includes(item.id));
        const isStudentsToAddToTeamExist = studentsToAddToTeam.length > 0;

        this.setState({
          isLoading: true
        });

        const data = {
          add: itemsAddBuffer.map(item => ({ userId: item.id, permissionId: item.permissionId })),
          remove: itemsRemoveBuffer.map(item => item.playerId)
        };

        updateSchoolEventIndividualsBatch(user, eventId, data)
          .then(() => {
            switch (true) {
              case isCurrentSchoolTeamExist:
                if (isStudentsToAddToTeamExist) {
                  const addToTeamData = {
                    add: studentsToAddToTeam.map(item => ({ userId: item.id, permissionId: item.permissionId })),
                    remove: []
                  };

                  return updateSchoolTournamentTeamPlayersBatch(
                    user,
                    tournamentId,
                    currentSchoolTeam.id,
                    addToTeamData
                  );
                }
                break;

              case !isCurrentSchoolTeamExist:
                return this.createDefaultTeam();
            }
          })
          .then(newTeam => {
            if (!isCurrentSchoolTeamExist) {
              const addToTeamData = {
                add: studentsToAddToTeam.map(item => ({ userId: item.id, permissionId: item.permissionId })),
                remove: []
              };

              return updateSchoolTournamentTeamPlayersBatch(user, tournamentId, newTeam.id, addToTeamData);
            }
          })
          .then(() => {
            history.push({
              pathname: '/events/event',
              search: `id=${eventId}`
            });
          });
        break;

      default:
        {
          this.setState({
            isLoading: true
          });

          const data = {
            add: itemsAddBuffer.map(item => ({ userId: item.id, permissionId: item.permissionId })),
            remove: itemsRemoveBuffer.map(item => item.playerId)
          };

          const teamPromise = isClubEvent(event)
            ? BPromise.all([
                getAllClubParticipants(user, clubId),
                updateSchoolEventIndividualsBatch(user, eventId, data)
              ])
            : updateSchoolEventIndividualsBatch(user, eventId, data);

          teamPromise
            .then(response => {
              if (isClubEvent(event)) {
                const [participants] = response;

                const studentsAreNotClubParticipants = itemsAddBuffer.filter(item => {
                  return !participants.some(participant => {
                    return participant.userId === item.id;
                  });
                });

                const isStudentsAreNotClubParticipantsExist = studentsAreNotClubParticipants.length > 0;

                if (isStudentsAreNotClubParticipantsExist) {
                  const studentToAddData = {
                    participants: studentsAreNotClubParticipants.map(student => {
                      return {
                        userId: student.id,
                        permissionId: student.permissionId
                      };
                    })
                  };

                  return addClubStudents(user, clubId, studentToAddData);
                }
              }
            })
            .then(() => {
              history.push({
                pathname: '/events/event',
                search: `id=${eventId}`
              });
            });
        }

        break;
    }
  };

  createDefaultTeam = () => {
    const { user } = this.props;
    const { event } = this.state;
    const { activeSchoolId: schoolId } = user;
    const { tournament, tournamentId } = event;
    const { gender, ages, sportId } = tournament;
    const tournamentName = propz.get(tournament, ['name'], '');
    const schoolName = propz.get(user, ['activeSchoolName'], '');
    const name = `${schoolName} ${tournamentName}`;

    const teamData = {
      name,
      ages,
      gender,
      sportId,
      schoolId,
      teamType: TEAM_TYPE.ADHOC,
      isAutoEnrolledTeam: true,
      players: []
    };

    return addSchoolTournamentTeam(user, tournamentId, teamData);
  };

  onItemsFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.itemsFilters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (filterField === 'forms') {
      nextFilters.ages = [];
    }

    if (filterField === 'ages') {
      nextFilters.forms = [];
    }

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = ITEMS_COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      itemsFilters: nextFilters
    });
  };

  onItemsAccumulatorFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.itemsAccumulatorFilters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = ITEMS_COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      itemsAccumulatorFilters: nextFilters
    });
  };

  onItemsFilterClick = (event): void => {
    event.preventDefault();

    const { isShowItemsFilter } = this.state;

    this.setState({
      isShowItemsFilter: !isShowItemsFilter
    });
  };

  onItemsAccumulatorFilterClick = (event): void => {
    event.preventDefault();

    const { isShowItemsAccumulatorFilter } = this.state;

    this.setState({
      isShowItemsAccumulatorFilter: !isShowItemsAccumulatorFilter
    });
  };

  itemsSetCurrentPageParams = (currentPage: number): void => {
    const { user } = this.props;
    const { itemsAccumulator, itemsAddBuffer } = this.state;

    this.setState({
      isLoading: true
    });

    const filter = this.getItemsFilter(currentPage, itemsAccumulator, itemsAddBuffer);

    const method = getStudentsMethod(user);

    method(user, filter).then(students => {
      const studentsNormalized = this.getStudentsNormalized(students);

      this.setState({
        itemCurrentPage: currentPage,
        items: studentsNormalized,
        isLoading: false
      });
    });
  };

  itemsOnApplyFilterClick = () => {
    const { itemsAccumulator, itemsAddBuffer } = this.state;

    this.setState({
      isLoading: true
    });

    const filter = this.getItemsFilter(1, itemsAccumulator, itemsAddBuffer);

    const { user } = this.props;

    const method = getStudentsMethod(user);
    const getStudentsPromise = method(user, filter);
    const getStudentCountPromise = getSchoolStudentsWithClashesCount(user, filter);

    BPromise.all([getStudentsPromise, getStudentCountPromise]).then(([students, studentsCount]) => {
      const studentsNormalized = this.getStudentsNormalized(students);

      this.setState({
        items: studentsNormalized,
        isLoading: false,
        itemsCount: studentsCount.count,
        itemsAccumulatorSelected: [],
        itemsSelected: [],
        itemCurrentPage: 1,
        sortItemsDirection: '',
        sortItemsColumnsName: ''
      });
    });
  };

  itemsAccumulatorOnApplyFilterClick = () => {};
  // itemsAccumulatorOnApplyFilterClick = () => {
  //   const { itemsAccumulatorFilters, itemsRemoveBuffer, forms } = this.state;
  //   this.setState({
  //     isLoading: true
  //   });
  //
  //   const options = { forms };
  //
  //   let where = getServerFieldSectionWhere(itemsAccumulatorFilters, options);
  //   const ninIds = itemsRemoveBuffer.map(item => item.id);
  //   propz.set(where, ['id', '$nin'], ninIds);
  //
  //   const { user } = this.props;
  //
  //   const eventId = this.getEventId();
  //
  //   const getPlayersPromise = getAllSchoolEventIndividuals(user, eventId, where);
  //   const getPlayersCountPromise = getSchoolEventPlayersCount(user, eventId, where);
  //
  //   const promiseItems = [getPlayersPromise, getPlayersCountPromise];
  //
  //   BPromise.all(promiseItems).then(([players, playersCount]) => {
  //     const playersNormalized = this.getPlayersNormalized(players);
  //
  //     this.setState({
  //       itemsAccumulator: playersNormalized,
  //       isLoading: false,
  //       itemsAccumulatorCount: playersCount.count,
  //       itemsAccumulatorSelected: [],
  //       itemsSelected: []
  //     });
  //   });
  // };

  itemsOnClearFilterClick = () => {
    const { itemsAccumulatorInitial, itemsAddBuffer, forms, event } = this.state;

    const defaultItemsFilter = this.getDefaultItemsFilter(event);

    this.setState({
      isLoading: true,
      itemsFilters: defaultItemsFilter
    });

    const { user } = this.props;
    const { startTime, endTime, players, id } = event;

    const {
      gender: itemsFiltersGender,
      ages: itemsFiltersAges = [],
      firstName: itemsFiltersFirstName = '',
      lastName: itemsFiltersLastName = ''
    } = defaultItemsFilter;

    let where = {
      eventId: id,
      gender: {
        $in: itemsFiltersGender
      }
    };

    const playerUserIds = players
      .filter(player => {
        if (isHousesEvent(event)) {
          const houseId = this.getHouseId();
          return player.houseId !== houseId;
        } else {
          return false;
        }
      })
      .map(player => ({
        id: player.userId
      }));

    const allItems = [...itemsAccumulatorInitial, ...itemsAddBuffer, ...playerUserIds];
    const ninIds = Lazy(allItems)
      .map(item => item.id)
      .uniq()
      .toArray();
    propz.set(where, ['_id', '$nin'], ninIds);

    const ages = itemsFiltersAges.length > 0 ? itemsFiltersAges : AGE_GROUPS.ENGLISH.map((age, index) => index);
    const formIds = forms.filter(form => ages.some(age => Number(form.age) === Number(age))).map(form => form.id);

    if (formIds.length > 0) {
      propz.set(where, ['formId', '$in'], formIds);
    }

    if (isHousesEvent(event)) {
      const houseId = this.getHouseId();
      propz.set(where, ['houseId'], houseId);
    }

    let and = [];

    if (itemsFiltersFirstName.length > 0) {
      and.push({ firstName: { like: itemsFiltersFirstName, options: 'i' } });
    }

    if (itemsFiltersLastName.length > 0) {
      and.push({ lastName: { like: itemsFiltersLastName, options: 'i' } });
    }

    if (and.length > 0) {
      propz.set(where, ['$and'], and);
    }

    // copy-paste from old frontend
    const startTimeDate = new Date(startTime);
    const startTimeCopy = new Date(startTime);
    startTimeCopy.setHours(startTimeDate.getHours() - 1);
    const intervalClashStartDate = Moment(startTimeCopy)
      .utc()
      .format();

    const endTimeDate = new Date(endTime);
    const endTimeCopy = new Date(endTime);
    endTimeCopy.setHours(endTimeDate.getHours() + 1);
    const intervalClashEndDate = Moment(endTimeCopy)
      .utc()
      .format();

    propz.set(where, ['intervalClashStartDate'], intervalClashStartDate);
    propz.set(where, ['intervalClashEndDate'], intervalClashEndDate);
    propz.set(where, ['fullClashStartDate'], startTime);
    propz.set(where, ['fullClashEndDate'], endTime);

    const filter = {
      where: where,
      limit: LIMIT,
      skip: DEFAULT_SKIP,
      order: DEFAULT_INDIVIDUAL_PLAYERS_ORDER
    };

    const method = getStudentsMethod(user);
    const getStudentsPromise = method(user, filter);
    const getSchoolsCountPromise = getSchoolStudentsWithClashesCount(user, filter);

    BPromise.all([getStudentsPromise, getSchoolsCountPromise]).then(([students, studentsCount]) => {
      const studentsNormalized = this.getStudentsNormalized(students);

      this.setState({
        items: studentsNormalized,
        isLoading: false,
        itemsCount: studentsCount.count,
        itemsAccumulatorSelected: [],
        itemsSelected: [],
        itemCurrentPage: 1,
        sortItemsColumnsName: '',
        sortItemsDirection: ''
      });
    });
  };

  itemsAccumulatorOnClearFilterClick = () => {};
  // itemsAccumulatorOnClearFilterClick = () => {
  //   this.setState({
  //     isLoading: true
  //   });
  //
  //   const { user } = this.props;
  //   const { itemsRemoveBuffer } = this.state;
  //
  //   const eventId = this.getEventId();
  //
  //   const getPlayersPromise = getAllSchoolEventIndividuals(user, eventId);
  //   const getPlayersCountPromise = getSchoolEventPlayersCount(user, eventId);
  //
  //   const promiseItems = [getPlayersPromise, getPlayersCountPromise];
  //
  //   BPromise.all(promiseItems).then(([players, playersCount]) => {
  //     const playersNormalized = this.getPlayersNormalized(players);
  //     const nextPlayersNormalized = playersNormalized.filter(playerNormalized =>
  //       itemsRemoveBuffer.every(item => item.id !== playerNormalized.id)
  //     );
  //
  //     this.setState({
  //       itemsAccumulator: nextPlayersNormalized,
  //       isLoading: false,
  //       itemsAccumulatorCount: playersCount.count,
  //       itemsAccumulatorSelected: [],
  //       itemsSelected: [],
  //       itemsAccumulatorFilters: {}
  //     });
  //   });
  // };

  onSelectAllItemsClick = () => {
    const { itemsSelected, itemCurrentPage, itemsAccumulator, itemsAddBuffer } = this.state;

    this.setState({
      isLoading: true
    });

    const { user } = this.props;

    const filter = this.getItemsFilter(itemCurrentPage, itemsAccumulator, itemsAddBuffer, true);

    getSchoolStudentsWithoutClashes(user, filter).then(items => {
      const itemsFiltered = items.filter(item => {
        return itemsSelected.every(selectedItem => selectedItem.id !== item.id);
      });

      const studentsNormalizedFiltered = this.getStudentsNormalized(itemsFiltered);

      const nextSelectedItems = [...itemsSelected, ...studentsNormalizedFiltered];

      this.setState({
        itemsSelected: nextSelectedItems,
        isSelectAllItemsChecked: true,
        itemsAccumulatorSelected: [],
        isSelectAllItemsAccumulatorChecked: false,
        isLoading: false
      });
    });
  };

  onSelectAllItemsOnPageClick = () => {
    const { items, itemsSelected } = this.state;

    const itemsFiltered = items.filter(item => {
      return itemsSelected.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...itemsSelected, ...itemsFiltered];

    this.setState({
      itemsSelected: nextSelectedItems,
      isSelectAllItemsChecked: true,
      itemsAccumulatorSelected: [],
      isSelectAllItemsAccumulatorChecked: false
    });
  };

  onUnselectAllItemsClick = () => {
    this.setState({
      itemsSelected: [],
      isSelectAllItemsChecked: false,
      itemsAccumulatorSelected: [],
      isSelectAllItemsAccumulatorChecked: false
    });
  };

  onSelectAllItemsAccumulatorClick = () => {
    const { itemsAccumulatorSelected, itemsAccumulatorFilters, itemsRemoveBuffer, forms, event } = this.state;

    this.setState({
      isLoading: true
    });

    const { user } = this.props;

    const eventId = this.getEventId();
    const options = { forms };
    const where = getServerFieldSectionWhere(itemsAccumulatorFilters, options);

    if (isHousesEvent(event)) {
      const houseId = this.getHouseId();
      propz.set(where, ['houseId', '$in'], [houseId]);
    }

    getIndividualsMethod(user)(user, eventId, { where }).then(players => {
      const playersNormalized = this.getPlayersNormalized(players);

      const playersFiltered = playersNormalized.filter(player => {
        return itemsAccumulatorSelected.every(selectedItem => selectedItem.id !== player.id);
      });

      const nextSelectedItems = [...itemsAccumulatorSelected, ...playersFiltered].filter(item => {
        return itemsRemoveBuffer.every(itemRemoveBuffer => itemRemoveBuffer.id !== item.id);
      });

      this.setState({
        itemsAccumulatorSelected: nextSelectedItems,
        isSelectAllItemsAccumulatorChecked: true,
        itemsSelected: [],
        isSelectAllItemsChecked: false,
        isLoading: false
      });
    });
  };

  onSelectAllItemsAccumulatorOnPageClick = () => {
    const { itemsAccumulator, itemsAccumulatorSelected, itemsRemoveBuffer } = this.state;

    const itemsFiltered = itemsAccumulator.filter(item => {
      return itemsAccumulatorSelected.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...itemsAccumulatorSelected, ...itemsFiltered].filter(item => {
      return itemsRemoveBuffer.every(itemRemoveBuffer => itemRemoveBuffer.id !== item.id);
    });

    this.setState({
      itemsAccumulatorSelected: nextSelectedItems,
      isSelectAllItemsAccumulatorChecked: true,
      itemsSelected: [],
      isSelectAllItemsChecked: false
    });
  };

  onUnselectAllItemsAccumulatorClick = () => {
    this.setState({
      itemsAccumulatorSelected: [],
      isSelectAllItemsAccumulatorChecked: false,
      itemsSelected: [],
      isSelectAllItemsChecked: false
    });
  };

  goBack = () => {
    const { event } = this.state;
    const { history } = this.props;
    const eventId = event.id;
    history.push({
      pathname: '/events/event',
      search: `id=${eventId}`
    });
  };

  onSortItemsClick = (sortField: string) => {
    const { sortItemsDirection, sortItemsColumnsName, itemCurrentPage, itemsAccumulator, itemsAddBuffer } = this.state;

    const order = getOrder(sortField, sortItemsDirection, sortItemsColumnsName) as TABLE_SORT_DIRECTION;

    const filter = this.getItemsFilter(itemCurrentPage, itemsAccumulator, itemsAddBuffer);
    const { user } = this.props;

    const method = getStudentsMethod(user);
    const getStudentsPromise = method(user, filter);
    const getSchoolsCountPromise = getSchoolStudentsWithClashesCount(user, filter);

    BPromise.all([getStudentsPromise, getSchoolsCountPromise]).then(([students, studentsCount]) => {
      const studentsNormalized = this.getStudentsNormalized(students);

      this.setState({
        items: studentsNormalized,
        isLoading: false,
        itemsCount: studentsCount.count,
        itemsAccumulatorSelected: [],
        itemsSelected: [],
        itemCurrentPage: 1,
        sortItemsColumnsName: sortField,
        sortItemsDirection: order
      });
    });
  };

  onSortItemsAccumulatorClick = (sortField: string) => {};

  onClashClick = (student: SchoolStudentClash) => {
    const { user } = this.props;
    const { clashes, firstName, lastName } = student;
    const { fullClashEvents, intervalClashEvents } = clashes;
    const fullClashEventIds = fullClashEvents.map(fullClashEvent => fullClashEvent._id);
    const intervalClashEventIds = intervalClashEvents.map(intervalClashEvent => intervalClashEvent._id);

    const allPlayerEventIds = [...fullClashEventIds, ...intervalClashEventIds];
    const allPlayerEventIdsUniq = Lazy(allPlayerEventIds)
      .uniq()
      .toArray();

    this.setState({
      isLoading: true
    });

    const eventsPromise: Promise<SchoolEvent>[] = allPlayerEventIdsUniq.map(eventId => getSchoolEvent(user, eventId));

    BPromise.all(eventsPromise).then(events => {
      const eventNames = events
        .map((event, index) => {
          const title = getEventTitle(event, user);
          const clash = getEventClash(event, fullClashEventIds, intervalClashEventIds);
          return `${index + 1}. ${title} ${clash}`;
        })
        .join('\n ');

      this.setState({
        isLoading: false,
        studentEventsModalText: `${firstName} ${lastName} is participating in the following events: \n${eventNames}`,
        isStudentEventsModalOpen: true
      });
    });
  };

  onCloseStudentEventsModalClick = () => {
    this.setState({
      isStudentEventsModalOpen: false
    });
  };

  renderStudentEventsModal(): React.ReactNode {
    const { isStudentEventsModalOpen, studentEventsModalText } = this.state;
    return (
      <SimpleModal
        isOpen={isStudentEventsModalOpen}
        title={'Info'}
        body={studentEventsModalText}
        buttonText={'Ok'}
        onButtonClick={this.onCloseStudentEventsModalClick}
      />
    );
  }

  showFollowingEventsSection = () => {
    this.setState({
      isShowFollowingEventsSection: true
    });
  };

  onEditModeClick = editMode => {
    this.setState({
      editMode: editMode
    });
  };

  render() {
    const {
      items,
      isLoading,
      itemsSelected,
      itemsAccumulatorSelected,
      itemsAccumulator,
      itemsFilters,
      isShowItemsFilter,
      isItemsFiltered,
      itemsCount,
      itemsAccumulatorCount,
      itemCurrentPage,
      itemsAccumulatorFilters,
      isShowItemsAccumulatorFilter,
      isItemsAccumulatorFiltered,
      isSelectAllItemsChecked,
      isSelectAllItemsAccumulatorChecked,
      sortItemsDirection,
      sortItemsColumnsName,
      sortItemsAccumulatorDirection,
      sortItemsAccumulatorColumnsName,
      itemsAddBuffer,
      itemsRemoveBuffer,
      forms,
      houses,
      event,
      isStudentEventsModalOpen,
      editMode,
      isShowFollowingEventsSection
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const { user } = this.props;
    const { activeSchool } = user;
    const { ageGroupsNaming, isShowStudentEventClashes } = activeSchool;

    const eventStartDateAndTimeFormatted = getEventStartDateAndTime(event);
    const eventSportName = propz.get(event, ['sport', 'name'], '');
    const eventGender = SPORT_GENDER_SERVER_TO_CLIENT_MAPPING[event.gender];
    const eventAges = getSchoolEventAges(event, user);
    const eventStatus = getEventType(event);

    const itemsFiltersOptions = {
      ages: getSelectOptionForAge(forms, ageGroupsNaming),
      gender: getSelectOptionForGender(),
      forms: getSelectOptionForForms(forms),
      houses: getSelectOptionForHouses(houses)
    };

    const itemsAccumulatorFiltersOptions = {};

    const houseId = this.getHouseId();
    const house = event.housesData.find(eventHouse => eventHouse.id === houseId);

    const classes = isStudentEventsModalOpen ? 'mt-3 modal-open' : 'mt-3';

    return (
      <div className={classes}>
        {this.renderStudentEventsModal()}
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="mt-3 bEventInformation">
                <div>{eventStartDateAndTimeFormatted}</div>
                <LabelWithQuestionIcon
                  labelText={`${eventSportName} ${eventGender} ${eventAges} ${eventStatus}`}
                  hintText={
                    'Here you can see basic event settings as you set them up within the previous step. ' +
                    'If you would like to correct something you can select the "GO BACK" option.'
                  }
                  customClass=""
                />
              </div>
              <LabelWithQuestionIcon
                labelText="Within this section you can add participants to the event"
                hintText={
                  'The students in this section will match the criteria you specified in your event creation ' +
                  '(Age, Gender and House for Inter-House events). Select the students from the right hand side and move ' +
                  'them to the left by clicking the "<<" button at the bottom of the list. You can search the students' +
                  ' using the filter panel on the right hand side. Once you have moved students into your team, they are ' +
                  'highlighted yellow which means the changes have not yet been saved, to apply changes you need to click ' +
                  'the "SAVE" button, you are able to edit team members after the event. If you are not sure who will ' +
                  'be involved in the event, you can add a Team name and save without adding any players.'
                }
                customClass="mt-3"
              />
              {isHousesEvent(event) && (
                <div className="form-group mb-3 mt-3">
                  <label className={'h2'}>House name:</label>
                  <select className="form-control" disabled value={houseId}>
                    <option key={house.id} value={house.id}>
                      {house.name}
                    </option>
                  </select>
                </div>
              )}
            </div>
          </div>
        </div>

        <TwoPanelEditor
          //items
          items={items}
          itemsTitle={'Eligible students'}
          itemsColumns={isShowStudentEventClashes ? ITEMS_COLUMNS_WITH_CLASHES : ITEMS_COLUMNS}
          itemsSelected={itemsSelected}
          onItemClick={this.onItemClick}
          //items filters
          onItemsFilterChange={this.onItemsFilterChange}
          itemsFilters={itemsFilters}
          itemsFiltersOptions={itemsFiltersOptions}
          isShowItemsFilter={isShowItemsFilter}
          isItemsFiltered={isItemsFiltered}
          onItemsFilterClick={this.onItemsFilterClick}
          //accumulator
          itemsAccumulator={itemsAccumulator}
          itemsAccumulatorTitle={'Participants'}
          itemsAccumulatorColumns={
            isShowStudentEventClashes ? ITEMS_ACCUMULATOR_COLUMNS_WITH_CLASHES : ITEMS_ACCUMULATOR_COLUMNS
          }
          onItemAccumulatorClick={this.onItemAccumulatorClick}
          itemsAccumulatorSelected={itemsAccumulatorSelected}
          //accumulator filters
          onItemsAccumulatorFilterChange={this.onItemsAccumulatorFilterChange}
          itemsAccumulatorFilters={itemsAccumulatorFilters}
          itemsAccumulatorFiltersOptions={itemsAccumulatorFiltersOptions}
          isShowItemsAccumulatorFilter={isShowItemsAccumulatorFilter}
          isItemsAccumulatorFiltered={isItemsAccumulatorFiltered}
          onItemsAccumulatorFilterClick={this.onItemsAccumulatorFilterClick}
          //buttons
          onAddClick={this.onAddClick}
          onRemoveClick={this.onRemoveClick}
          //counts
          itemsCount={itemsCount}
          itemsAccumulatorCount={itemsAccumulatorCount}
          //current pages
          itemAccumulatorCurrentPage={1}
          itemCurrentPage={itemCurrentPage}
          itemsSetCurrentPageParams={this.itemsSetCurrentPageParams}
          //items filter buttons
          itemsOnApplyFilterClick={this.itemsOnApplyFilterClick}
          itemsOnClearFilterClick={this.itemsOnClearFilterClick}
          //items accumulator filter buttons
          itemsAccumulatorOnApplyFilterClick={this.itemsAccumulatorOnApplyFilterClick}
          itemsAccumulatorOnClearFilterClick={this.itemsAccumulatorOnClearFilterClick}
          //items select checkbox
          onSelectAllItemsClick={this.onSelectAllItemsClick}
          onSelectAllItemsOnPageClick={this.onSelectAllItemsOnPageClick}
          onUnselectAllItemsClick={this.onUnselectAllItemsClick}
          isSelectAllItemsChecked={isSelectAllItemsChecked}
          //items accumulator select checkbox
          onSelectAllItemsAccumulatorClick={this.onSelectAllItemsAccumulatorClick}
          onSelectAllItemsAccumulatorOnPageClick={this.onSelectAllItemsAccumulatorOnPageClick}
          onUnselectAllItemsAccumulatorClick={this.onUnselectAllItemsAccumulatorClick}
          isSelectAllItemsAccumulatorChecked={isSelectAllItemsAccumulatorChecked}
          //items sort
          sortItemsDirection={sortItemsDirection}
          sortItemsColumnsName={sortItemsColumnsName}
          onSortItemsClick={this.onSortItemsClick}
          //items accumulator sort
          sortItemsAccumulatorDirection={sortItemsAccumulatorDirection}
          sortItemsAccumulatorColumnsName={sortItemsAccumulatorColumnsName}
          onSortItemsAccumulatorClick={this.onSortItemsAccumulatorClick}
          itemsAddBuffer={itemsAddBuffer}
          itemsRemoveBuffer={itemsRemoveBuffer}
          onCellItemsClick={this.onClashClick}
          onCellItemsAccumulatorClick={this.onClashClick}
        />
        <div className="container-fluid mt-3 mb-3">
          <div className="row">
            <div className="col-md-12">
              {isShowFollowingEventsSection && (
                <SchoolEventClubEventRadioButtons onEditModeClick={this.onEditModeClick} editMode={editMode} />
              )}
              <Button onClick={this.goBack} text={'Cancel'} customClass={'btn-lg mt-3 mr-3 btn-secondary'} />
              <Button text={'Save'} onClick={this.onClickSave} customClass={'btn-lg mt-3'} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
