import * as React from 'react';
import { FunctionComponent } from 'react'

import { Notification } from "../../models/notification";
import { ROLE_SERVER_TO_CLIENT_MAPPING } from "../../consts/user";
import { GENERAL_MESSAGE_DELIVERY_STATUS_SERVER_TO_CLIENT_MAPPING } from "../../consts/generalMessage";

interface Props {
  notification: Notification
}

export const SchoolNotificationSummary: FunctionComponent<Props> = (props) => {
  const { notification } = props;
  const rolesString = notification.permissionPresets
    .map(permission => ROLE_SERVER_TO_CLIENT_MAPPING[permission])
    .join(', ');
  const deliveryStatus = GENERAL_MESSAGE_DELIVERY_STATUS_SERVER_TO_CLIENT_MAPPING[notification.deliveryStatus];

  return (
    <div className='row mMarginTop'>
      <SchoolNotificationSummaryItem title='Id:' value={notification.id} />
      <SchoolNotificationSummaryItem title='User:' value={`${notification.user.firstName} ${notification.user.lastName}`} />
      <SchoolNotificationSummaryItem title='Roles:' value={rolesString} />
      <SchoolNotificationSummaryItem title='Delivery status' value={deliveryStatus} />
    </div>
  )
};

interface ItemProps {
  title: string
  value: string
}

const SchoolNotificationSummaryItem: FunctionComponent<ItemProps> = props => {
  return (
    <React.Fragment>
      <div className='col-md-2 text-secondary'>{props.title}</div>
      <div className='col-md-10'>{props.value}</div>
    </React.Fragment>
  )
};