import * as React from 'react';
import { Component } from 'react';
import { History, Location } from 'history';
import * as propz from 'propz';
import * as BPromise from 'bluebird';
import { parse } from 'query-string';
import { AppUser } from 'Src/views/App/App';
import { SchoolEvent } from 'Src/models/event';
import { SchoolEventIndividualData } from 'Src/models/event';
import { Tournament } from 'Src/models/tournament';
import { Team } from 'Src/models/team';
import { SchoolForm } from 'Src/models/form';
import { IndividualTournamentTeamPlayer } from 'Src/models/tournamentTeamPlayer';
import { CELL_ACTION_ICON } from 'Src/consts/cell';
import { DATE_INTERVAL, DEFAULT_LIMIT, DEFAULT_SKIP, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import {
  ColumnDefinition,
  getFilters2,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import {
  getAllSchoolUnionHighLevelTournamentEvents,
  getSchoolUnionHighLevelTournamentEvents,
  getSchoolUnionHighLevelTournamentEventsCount
} from 'Src/helpers/service/adminSU/tournamentEventsTable';
import {
  getSelectOptionForAge,
  getSelectOptionForEventTypes,
  getSelectOptionForEventVenueType,
  getSelectOptionForSport,
  getSelectOptionForTournamentEventGender,
  getSelectOptionForTournamentEventStatus
} from 'Src/helpers/table/select';
import {
  removeSchoolEventIndividualPlayer,
  addSchoolEventIndividualPlayer,
  removeSchoolEventTeamPlayer
} from 'Src/helpers/service/admin/tournamentTeams';
import { updateSchoolEventIndividualsBatch } from 'Src/helpers/service/admin/schoolEventIndividuals';
import { updateSchoolEventTeamPlayersBatch } from 'Src/helpers/service/admin/event';
import { addSchoolEventTeam } from 'Src/helpers/service/admin/tournamentTeams';
import { getSchoolTournamentTeams } from 'Src/helpers/service/admin/tournamentTeams';
import { getAllForms } from 'Src/helpers/service/admin/forms';
import { getSchoolEvent } from 'Src/helpers/service/admin/event';
import { getTournament } from 'Src/helpers/service/adminSU/tournamentsTable';
import { getOrder, getSearchFilter, getSearchOrder, getServerFieldSectionWhere } from 'Src/helpers/table/table';
import {
  getEventGeneratedName,
  getEventSportName,
  getAgesGroupFormatted,
  getAllowedGender
} from 'Src/helpers/accessor/accessor';
import { getFullName } from 'Src/helpers/autocomplete/autocomplete';
import { getTournamentEventsActionIcon, getEventParticipantsIcon } from 'Src/helpers/cell/cell';
import { Grid2 } from 'Src/components/Grid/Grid2';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { Checkbox } from 'Src/components/Checkbox/Checkbox';
import { StudentsToAddToEventForm } from './StudentsToAddToEventForm';
import { Loader } from 'Src/components/Loader/Loader';

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

interface State {
  items: SchoolEvent[];
  allEvents: SchoolEvent[];
  teams: Team[];
  forms: SchoolForm[];
  currentEvent: SchoolEvent;
  currentPage: number;
  selectedItems: SchoolEvent[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  filters: any;
  isDataFiltered: boolean;
  total: number;
  basePath: string;
  isSelectAllChecked: boolean;
  tournament: Tournament;
  modalText: string;
  removeParticipantId: string;
  isStudentsToAddToEventModalOpen: boolean;
  isRemovePlayerFromEventConfirmationModalOpen: boolean;
  isAskToRemovePlayerFromEvent: boolean;
  isLoading: boolean;
}

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Event Name',
    field: 'eventName',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getEventGeneratedName
  },
  {
    text: 'Sport',
    field: 'eventSport',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getEventSportName
  },
  {
    text: 'Ages',
    field: 'eventAges',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getAgesGroupFormatted
  },
  {
    text: 'Gender',
    field: 'gender',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getAllowedGender
  },
  {
    text: 'Actions',
    field: 'actions',
    isSort: false,
    type: FILTER_TYPE.NONE,
    cell: getTournamentEventsActionIcon,
    align: 'center'
  },
  {
    text: 'Participants',
    field: 'participants',
    isSort: false,
    type: FILTER_TYPE.NONE,
    cell: getEventParticipantsIcon
  }
];

export class HighLevelTournamentEvents extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      allEvents: [],
      teams: [],
      forms: [],
      currentEvent: undefined,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isSelectAllChecked: false,
      currentPage: FIRST_PAGE,
      total: 0,
      tournament: undefined,
      basePath: '',
      modalText: '',
      removeParticipantId: '',
      isStudentsToAddToEventModalOpen: false,
      isRemovePlayerFromEventConfirmationModalOpen: false,
      isAskToRemovePlayerFromEvent: false,
      isLoading: false
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setAdditionalItems().then(res => {
      this.setItems();
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  getTournamentId(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    const tournamentId = search.tournamentId;
    return tournamentId;
  }

  setAdditionalItems() {
    const { user } = this.props;

    const tournamentId = this.getTournamentId();

    return getTournament(user, tournamentId).then(tournament => {
      this.setState({
        tournament: tournament
      });

      return true;
    });
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const tournamentId = this.getTournamentId();

    const getItemsPromise = getSchoolUnionHighLevelTournamentEvents(user, tournamentId, serverQueryFilter);
    const getItemsCountPromise = getSchoolUnionHighLevelTournamentEventsCount(user, tournamentId, where);
    //  TODO: Get all the events to use sports in the filter. May affect performance
    const getAllTournamentEventsPromise = getAllSchoolUnionHighLevelTournamentEvents(user, tournamentId);
    const getSchoolTournamentTeamsPromise = getSchoolTournamentTeams(user, tournamentId);
    const getFormsPromise = getAllForms(user);

    const promises = [
      getItemsPromise,
      getItemsCountPromise,
      getAllTournamentEventsPromise,
      getSchoolTournamentTeamsPromise,
      getFormsPromise
    ];

    return BPromise.all(promises).then(([items, countObj, allEvents, teams, forms]) => {
      this.setState({
        items,
        allEvents,
        teams,
        forms,
        sortDirection,
        sortColumnsName,
        isShowFilter,
        isDataFiltered,
        filters,
        currentPage: page,
        total: countObj.count,
        basePath: history.location.pathname,
        isLoading: false
      });

      return true;
    });
  }

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated
    });
  };

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;
    const { history } = this.props;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const tournamentId = this.getTournamentId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `tournamentId=${tournamentId}&${search.join('&')}`,
      state: history.location.state
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: nextSelectedItems,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    const tournamentId = this.getTournamentId();

    getAllSchoolUnionHighLevelTournamentEvents(user, tournamentId, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const nextSelectedItems = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: nextSelectedItems,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;
    const { history } = this.props;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const tournamentId = this.getTournamentId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `tournamentId=${tournamentId}&${search.join('&')}`,
      state: history.location.state
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    const { history } = this.props;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    const tournamentId = this.getTournamentId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `tournamentId=${tournamentId}&${search.join('&')}`,
      state: history.location.state
    });
  };

  onClearFilterClick = (): void => {
    const { history } = this.props;
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    const tournamentId = this.getTournamentId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `tournamentId=${tournamentId}`,
      state: history.location.state
    });
  };

  onCellIconClick = (
    data: {
      currentEvent: SchoolEvent;
      currentParticipant?: SchoolEventIndividualData;
    },
    cell: string
  ) => {
    const { isAskToRemovePlayerFromEvent } = this.state;
    const { currentEvent, currentParticipant } = data;

    switch (true) {
      case cell === CELL_ACTION_ICON.ADD_TO_EVENT: {
        this.setState({
          currentEvent
        });

        this.openStudentsToAddToEventModal();

        break;
      }

      case cell === CELL_ACTION_ICON.REMOVE_FROM_EVENT: {
        const eventName = propz.get(currentEvent, ['generatedNames', 'official'], '');
        const { userId: removeParticipantId } = currentParticipant;
        const playerFullName = getFullName(currentParticipant);

        this.setState(
          {
            modalText: `${playerFullName} will be removed from ${eventName}. Are you sure?`,
            removeParticipantId,
            currentEvent
          },
          () => {
            isAskToRemovePlayerFromEvent
              ? this.removePlayerFromEvent()
              : this.openRemovePlayerFromEventConfirmationModal();
          }
        );
        break;
      }
    }
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  goBack = () => {
    const { history, location } = this.props;
    const { state } = location;
    const { search, prevPath } = state as any;
    const tournamentId = this.getTournamentId();

    history.push({
      pathname: prevPath,
      search: search,
      state: { tournamentId: tournamentId }
    });
  };

  addStudentsToEvent = (events: SchoolEvent[], students: IndividualTournamentTeamPlayer[]) => {
    this.setState({
      isLoading: true
    });

    const { user } = this.props;
    const { selectedItems } = this.state;

    const promises = [];

    for (let j = 0; j < events.length; j++) {
      const event = events[j];
      const { id: eventId, ages, gender } = event;
      const studentData = students.map(student => {
        const { permissionId, userId } = student;
        return {
          permissionId,
          userId
        };
      });

      const isRelay = propz.get(event, ['sport', 'isRelay'], false);

      if (isRelay) {
        const teamsData = propz.get(event, ['teamsData'], []);
        const schoolTeam = teamsData.find(team => team.schoolId === user.activeSchoolId);
        const isSchoolTeamExist = typeof schoolTeam !== 'undefined';

        const teamToAdd = {
          ages,
          gender,
          players: studentData
        };

        isSchoolTeamExist
          ? promises.push(updateSchoolEventTeamPlayersBatch(user, eventId, schoolTeam.id, { add: studentData }))
          : promises.push(addSchoolEventTeam(user, eventId, teamToAdd));
      } else {
        promises.push(updateSchoolEventIndividualsBatch(user, eventId, { add: studentData }));
      }
    }

    BPromise.all(promises)
      .then(() => {
        return this.setItems();
      })
      .then(() => {
        const { items } = this.state;

        const updatedSelectedItems = items.filter(item => {
          return selectedItems.some(selectedItem => selectedItem.id === item.id);
        });

        this.setState({
          isLoading: false,
          isStudentsToAddToEventModalOpen: false,
          currentEvent: undefined,
          selectedItems: []
        });
      });
  };

  removePlayerFromEvent = () => {
    const { user } = this.props;
    const { currentEvent, removeParticipantId } = this.state;
    const { id: removeEventId } = currentEvent;

    this.setState({
      isLoading: true
    });

    getSchoolEvent(user, removeEventId)
      .then(schoolEvent => {
        const { players } = schoolEvent;
        const currentPlayer = players.find(player => player.userId === removeParticipantId);
        const { teamId, _id: playerId } = currentPlayer;

        const isRelay = propz.get(schoolEvent, ['sport', 'isRelay'], false);

        return isRelay
          ? removeSchoolEventTeamPlayer(user, removeEventId, teamId, playerId)
          : removeSchoolEventIndividualPlayer(user, removeEventId, playerId);
      })
      .then(() => {
        return this.setItems();
      })
      .then(() => {
        const { items, selectedItems } = this.state;

        const updatedSelectedItems = items.filter(item => {
          return selectedItems.some(selectedItem => selectedItem.id === item.id);
        });

        this.setState({
          selectedItems: updatedSelectedItems,
          isRemovePlayerFromEventConfirmationModalOpen: false,
          modalText: '',
          isLoading: false,
          currentEvent: undefined
        });
      });
  };

  renderAddPlayerToEventModal = () => {
    const { user } = this.props;
    const { isStudentsToAddToEventModalOpen, selectedItems, currentEvent, teams, forms } = this.state;

    const isCurrentEventExist = typeof currentEvent !== 'undefined';
    const events = isCurrentEventExist ? [currentEvent] : selectedItems;

    const tournamentId = this.getTournamentId();
    const isTeamsExist = teams.length > 0;
    const teamId = isTeamsExist ? teams[0].id : '';

    return (
      <SimpleModal
        isOpen={isStudentsToAddToEventModalOpen}
        title="Adding students to the events"
        onCloseIconClick={this.closeStudentsToAddToEventModal}
      >
        <StudentsToAddToEventForm
          events={events}
          user={user}
          forms={forms}
          teamId={teamId}
          tournamentId={tournamentId}
          addStudentsToEvent={this.addStudentsToEvent}
          onCloseClick={this.closeStudentsToAddToEventModal}
        />
      </SimpleModal>
    );
  };

  openStudentsToAddToEventModal = () => {
    this.setState({
      isStudentsToAddToEventModalOpen: true
    });
  };

  closeStudentsToAddToEventModal = () => {
    this.setState({
      isStudentsToAddToEventModalOpen: false,
      currentEvent: undefined,
      selectedItems: []
    });
  };

  renderRemovePlayerFromEventConfirmationModal() {
    const { isRemovePlayerFromEventConfirmationModalOpen, isAskToRemovePlayerFromEvent, modalText } = this.state;

    return (
      <SimpleModal
        isOpen={isRemovePlayerFromEventConfirmationModalOpen}
        title="Confirm the action"
        body={modalText}
        buttonText="Confirm"
        buttonCancelText="Cancel"
        onButtonClick={() => this.removePlayerFromEvent()}
        onCloseClick={this.closeRemovePlayerFromEventConfirmationModal}
      >
        <Checkbox
          customClass="mt-2"
          id="12"
          value={isAskToRemovePlayerFromEvent}
          labelText="Don't ask anymore"
          onChange={() =>
            this.setState(prevState => {
              return {
                isAskToRemovePlayerFromEvent: !prevState.isAskToRemovePlayerFromEvent
              };
            })
          }
        />
      </SimpleModal>
    );
  }

  openRemovePlayerFromEventConfirmationModal = (): void => {
    this.setState({
      isRemovePlayerFromEventConfirmationModalOpen: true
    });
  };

  closeRemovePlayerFromEventConfirmationModal = (): void => {
    this.setState({
      isAskToRemovePlayerFromEvent: false,
      isRemovePlayerFromEventConfirmationModalOpen: false,
      currentEvent: undefined
    });
  };

  renderGrid() {
    const {
      items,
      allEvents,
      forms,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isSelectAllChecked,
      currentPage,
      total,
      tournament
    } = this.state;
    const { user } = this.props;

    const eventsSports = allEvents.map(event => event.sport);
    const ageGroupsNaming = propz.get(user, ['activeSchool', 'ageGroupsNaming'], '');

    const actionItems = [
      {
        itemText: 'Add participants',
        onItemClick: this.openStudentsToAddToEventModal,
        isActive: selectedItems.length > 0
      }
    ];

    const filterOptions = {
      eventSport: getSelectOptionForSport(eventsSports),
      eventAges: getSelectOptionForAge(forms, ageGroupsNaming),
      gender: getSelectOptionForTournamentEventGender()
    };

    const gridTitle = typeof tournament !== 'undefined' ? `${tournament.name} / Events` : '';

    return (
      <Grid2
        dataItems={items}
        filters={filters}
        currentPage={currentPage}
        total={total}
        isSelectAllChecked={isSelectAllChecked}
        isDataFiltered={isDataFiltered}
        sortDirection={sortDirection}
        sortColumnsName={sortColumnsName}
        isShowFilter={isShowFilter}
        dataItemsSelected={selectedItems}
        columns={COLUMNS}
        actionItems={actionItems}
        options={filterOptions}
        onItemClick={this.onItemClick}
        onCellIconClick={this.onCellIconClick}
        onSortClick={this.onTableSortClick}
        onApplyFilterClick={this.onApplyFilterClick}
        onClearFilterClick={this.onClearFilterClick}
        onTableFilterChange={this.onTableFilterChange}
        onTableFilterClick={this.onTableFilterClick}
        setCurrentPageParams={this.setCurrentPageParams}
        onSelectAllClick={this.onSelectAllClick}
        onSelectAllOnPageClick={this.onSelectAllOnPageClick}
        onUnselectAllClick={this.onUnselectAllClick}
        gridTitle={gridTitle}
        goBack={this.goBack}
        user={user}
      />
    );
  }

  render() {
    const { isLoading } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    return (
      <div className="row">
        {this.renderAddPlayerToEventModal()}
        {this.renderRemovePlayerFromEventConfirmationModal()}

        <div className="col-md-12">{this.renderGrid()}</div>
      </div>
    );
  }
}
