export const SORT = {
  ASC: 'ASC',
  DESC: 'DESC'
};

export const FILTER_TYPE = {
  NONE: 'NONE',
  TEXT: 'TEXT',
  SELECT: 'SELECT',
  MULTISELECT: 'MULTISELECT',
  CHECKBOX: 'CHECKBOX',
  DATE_INTERVAL: 'DATE_INTERVAL',
  TIME_INTERVAL: 'TIME_INTERVAL',
  COUNT_INTERVAL: 'COUNT_INTERVAL',
  AUTOCOMPLETE: 'AUTOCOMPLETE'
};

export const DATE_INTERVAL = {
  TO: 'TO',
  FROM: 'FROM'
};

export const COUNT_INTERVAL = {
  TO: 'TO',
  FROM: 'FROM'
};

export const FIRST_PAGE = 1;
export const LIMIT = 20;
export const CARD_LIMIT = 8;

export const DEFAULT_LIMIT = 3000;
export const DEFAULT_SKIP = 0;
