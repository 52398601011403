import * as React from 'react';
import * as propz from 'propz';
import { FunctionComponent } from 'react';
import * as Moment from 'moment';
import { DATE_FORMAT } from '../../consts/date';
import { YES_NO_OPTIONS } from '../../consts/common';

interface Props {
  participatingStudent: any;
}

const ROWS = [
  { title: 'Name', field: 'firstName' },
  { title: 'Surname', field: 'lastName' },
  { title: 'Date of birth', field: 'birthday' },
  { title: 'Form', field: 'formName' },
  { title: 'House', field: 'houseName' },
  { title: 'Club', field: 'clubName' },
  { title: 'Age', field: 'ages' },
  { title: 'PP', field: 'pupilsPremium' },
  { title: 'SEN', field: 'specialEducationNeeds' },
  { title: 'FSM', field: 'freeSchoolMeals' }
];

export const ParticipatingStudentSummary: FunctionComponent<Props> = props => {
  const rows = ROWS.map((row, index) => {
    let contentCell: string;
    const field = row.field;

    const participatingStudent = props.participatingStudent;

    switch (field) {
      case 'firstName':
        contentCell = propz.get(participatingStudent, ['user', 'firstName'], '');
        break;
      case 'lastName':
        contentCell = propz.get(participatingStudent, ['user', 'lastName'], '');
        break;
      case 'birthday':
        const birthday = participatingStudent.user.birthday;
        const birthdayDate = new Date(birthday);

        contentCell = typeof birthday !== 'undefined' ? Moment(birthdayDate).format(DATE_FORMAT) : '';
        break;
      case 'formName':
        contentCell = propz.get(participatingStudent, ['user', 'formName'], '');
        break;
      case 'houseName':
        contentCell = propz.get(participatingStudent, ['user', 'houseName'], '');
        break;
      case 'clubName':
        contentCell = propz.get(participatingStudent, ['club', 'name'], '');
        break;
      case 'ages':
        contentCell = propz.get(participatingStudent, ['user', 'ageGroupName'], '');
        break;
      case 'pupilsPremium':
        contentCell = participatingStudent.user.pupilsPremium ? YES_NO_OPTIONS.YES : YES_NO_OPTIONS.NO;
        break;
      case 'specialEducationNeeds':
        contentCell = participatingStudent.user.specialEducationNeeds ? YES_NO_OPTIONS.YES : YES_NO_OPTIONS.NO;
        break;
      case 'freeSchoolMeals':
        contentCell = participatingStudent.user.freeSchoolMeals ? YES_NO_OPTIONS.YES : YES_NO_OPTIONS.NO;
        break;
      default:
        contentCell = participatingStudent[row.field];
    }

    return (
      <tr key={`participating_student_summary_${index}`}>
        <td>{row.title}</td>
        <td>{contentCell}</td>
      </tr>
    );
  });

  return (
    <table className={'table table-sm mPre'}>
      <tbody>{rows}</tbody>
    </table>
  );
};

ParticipatingStudentSummary.displayName = 'ParticipatingStudentSummary';
