import * as React from 'react';
import { SchoolEventIndividualData } from 'Src/models/event';
import {
    convertPointsToStringWithoutDelimeter, getExtraPointsByStudent,
    getIndividualScoreByStudent
} from "../../../../../../../helpers/event/schoolEventViewHelper";
import {isEventStatusFinished} from "../../../../../../../helpers/event/event";
import {SchoolEvent} from "../../../../../../../models/event";

interface Props {
    players: SchoolEventIndividualData[];
    event: SchoolEvent;
}

export function ChildrenEventIndividualPlayers(props: Props) {
    const { players, event } = props;

    const rows = players.map((player, playerIndex) => {

        const {userId, permissionId} = player;
        const playerPoints = getIndividualScoreByStudent(event, userId, permissionId);
        const playerPointsStr = convertPointsToStringWithoutDelimeter(event, playerPoints);
        const extraPoints = getExtraPointsByStudent(event, userId);
        const extraPointsStr = String(extraPoints);
        const isEventFinished = isEventStatusFinished(event);

        return (
            <tr key={`school_players_${player.id}`}>
                <td style={{width: '5%'}}>
                    <div className={'ml-3'}>
                        {`${playerIndex + 1} `}
                    </div>
                </td>

                <td style={{width: '25%'}}>
                    <div className={'ml-3'}>
                        {`${player.firstName} `}</div>
                </td>

                <td style={{width: '25%'}}>
                    <div className={'ml-3'}>{`${player.lastName} `}</div>
                </td>

                <td style={{width: '30%'}}>
                    {isEventFinished &&
                    <div className={'ml-3 font-weight-bold'}>
                        {`${playerPointsStr} `}
                    </div>
                    }
                </td>

                <td style={{width: '15%'}}>
                    {isEventFinished &&
                    <div className={'ml-3 font-weight-bold'}>
                        {`${extraPointsStr} `}
                    </div>
                    }
                </td>
            </tr>
        );
    });
    return (
        <table className={'w-100'}>
            <tbody>{rows}</tbody>
        </table>
    );
}