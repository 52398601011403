import { AppUser } from '../../../views/App/App';
import { SchoolEventTeamPlayer, SchoolEventTeamPlayerWithClashes } from '../../../models/eventTeamPlayer';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../consts/table';
import axios from 'axios';
import { Count } from '../../../models/count';
import { SchoolStudentClash } from '../../../models/schoolStudent';

export function getAllSchoolEventTeamPlayers(
  user: AppUser,
  eventId: string,
  teamId: string,
  where?
): Promise<SchoolEventTeamPlayer[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios
    .get<SchoolEventTeamPlayer[]>(
      `${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/teams/${teamId}/players`,
      config
    )
    .then(response => {
      return response.data;
    });
}

export function getSchoolEventTeamPlayersCount(user: AppUser, eventId: string, teamId: string, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where
        }
      }
    };
  } else {
    config = {
      headers: { usid: key }
    };
  }

  return axios
    .get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/teams/${teamId}/players/count`, config)
    .then(response => {
      return response.data;
    });
}

export function getSchoolEventPlayersWithClashes(
  user: AppUser,
  eventId: string,
  teamId: string,
  filter?
): Promise<SchoolEventTeamPlayerWithClashes[]> {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .post<SchoolEventTeamPlayerWithClashes[]>(
      `${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/teams/${teamId}/playersWithClashes`,
      filter,
      config
    )
    .then(response => {
      return response.data;
    });
}

export function getSchoolEventPlayersWithoutClashes(
  user: AppUser,
  eventId: string,
  teamId: string,
  filter?
): Promise<SchoolEventTeamPlayerWithClashes[]> {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .post<SchoolEventTeamPlayerWithClashes[]>(
      `${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/teams/${teamId}/playersWithoutClashes`,
      filter,
      config
    )
    .then(response => {
      return response.data;
    });
}
