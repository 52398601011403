import * as React from 'react';
import { useState, FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionItem } from './ActionsButton';

interface Props {
  item: any;
  onItemClick: (actionItem: ActionItem) => void;
}

export const DropdownMenuItem: FunctionComponent<Props> = props => {
  const { item, onItemClick } = props;
  const { subItems, itemText, isActive } = item;

  const dropdownItemClasses = 'dropdown-item d-flex justify-content-between align-items-center';

  const isSubItemsExist = typeof subItems !== 'undefined' && subItems.length > 0;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const renderItems = () => {
    let result = [];

    if (isSubItemsExist) {
      subItems.map((item, index) => {
        result.push(<DropdownMenuItem key={`dropdownMenuItem_${index}`} item={item} onItemClick={onItemClick} />);
      });
    }

    return result;
  };

  return (
    <div className="bDropdownMenuItem">
      <span
        className={isActive ? dropdownItemClasses : `${dropdownItemClasses} disabled`}
        onClick={() => (isSubItemsExist ? setIsOpen(!isOpen) : onItemClick(item))}
      >
        {itemText}
        {isSubItemsExist && (
          <FontAwesomeIcon color="#3bafda" className={isOpen ? 'mDropdownMenuItemIcon' : ''} icon={'arrow-right'} />
        )}
      </span>

      {isOpen && <div className="bSubMenuWrapper">{renderItems()}</div>}
    </div>
  );
};
