import * as React from 'react';
import * as propz from 'propz';
import * as TextMaskCore from 'text-mask-core';
import { Component } from 'react';
import { SchoolEvent } from '../../../../../../../models/event';
import './Results.scss';
import {
  convertPointsToString,
  getDefaultDigitMask,
  getIndividualScoreByStudent,
  getIsErrorHashMap,
  getMask,
  getPlayerScoreId,
  getScorePoints,
  getAbbreviationResultByStudent,
  compareNames
} from '../../../../../../../helpers/event/schoolEventViewHelper';
import { Button } from '../../../../../../../components/Button/Button';
import { ErrorHashMap, ValueHashMap } from './IndividualInternalResults';
import { EMPTY_STRING } from '../../../../../../../consts/date';
import { isLessScoringSport } from '../../../../../../../helpers/sport/sport';
import { ARROW_DOWN_KEY, ARROW_UP_KEY, ENTER_KEY } from '../../../../../../../consts/common';
import { getHouseColors } from '../../../../../../../helpers/event/event';
import { sortIndividualPlayers } from '../../../../../../../helpers/players/players';

interface Props {
  event: SchoolEvent;
  onSaveClick: (data) => void;
  onCloseClick: () => void;
}

interface State {
  mask: boolean | (string | RegExp)[];
  valueHashMap: ValueHashMap;
  isErrorHashMap: ErrorHashMap;
  oldValueHashMap: ValueHashMap;
  count: number;
  players: Player[];
  abbreviations: Abbreviation[];
  selectedAbbreviations: { [playerId: string]: string };
}

interface Player {
  id: string;
  userId: string;
  permissionId: string;
  firstName: string;
  lastName: string;
  houseId: string;
  houseName: string;
  houseColors: string[];
}

interface Abbreviation {
  _id: string;
  code: string;
  description: string;
  color: string;
}

export class IndividualInternalHousesResults extends Component<Props, State> {
  inputElement: any[];
  textMaskInputElement: any[];

  constructor(props: Props) {
    super(props);

    this.inputElement = [];
    this.textMaskInputElement = [];

    const { event } = props;
    const mask = propz.get(event, ['sport', 'points', 'inputMask']);

    const inputMask = getMask(mask);

    const { housesData, individualsData } = event;

    let valueHashMap = {};
    let oldValueHashMap = {};
    let isErrorHashMap = {};
    let players: Player[] = [];
    let selectedAbbreviations = {};

    housesData.forEach(house => {
      const houseId = house.id;
      const houseName = house.name.slice(0, 3);
      const houseColors = house.colors;
      const playersFiltered = individualsData.filter(individual => individual.houseId === houseId);

      [...playersFiltered]
        .sort((player1, player2) => sortIndividualPlayers(event, player1, player2))
        .forEach(({ id, userId, permissionId, firstName, lastName }) => {
          const playerPoints = getIndividualScoreByStudent(event, userId, permissionId);
          const playerPointsStr = convertPointsToString(event, playerPoints);

          const abbreviationResult = getAbbreviationResultByStudent(event, userId, permissionId);
          if (abbreviationResult) {
            selectedAbbreviations[id] = abbreviationResult;
          }

          valueHashMap[id] = playerPoints === 0 ? '' : playerPointsStr;
          oldValueHashMap[id] = playerPoints === 0 ? '' : playerPointsStr;
          isErrorHashMap[id] = false;
          players.push({ id, userId, permissionId, firstName, lastName, houseId, houseName, houseColors });
        });
    });

    const { scoring } = event.sport;

    players.sort((player1, player2) => {
      const result1 = getIndividualScoreByStudent(event, player1.userId, player1.permissionId);
      const result2 = getIndividualScoreByStudent(event, player2.userId, player2.permissionId);

      if ((result1 === 0 || result1 === null) && (result2 === 0 || result2 === null)) {
        return compareNames(player1, player2);
      }
      if (result1 === 0 || result1 === null) return 1;
      if (result2 === 0 || result2 === null) return -1;

      if (['LESS_TIME', 'LESS_RESULT', 'LESS_SCORES'].includes(scoring)) {
        if (result1 !== result2) {
          return result1 - result2;
        }
      } else if (['MORE_TIME', 'MORE_RESULT', 'MORE_SCORES', 'FIRST_TO_N_POINTS'].includes(scoring)) {
        if (result1 !== result2) {
          return result2 - result1;
        }
      }

      return compareNames(player1, player2);
    });

    this.state = {
      mask: inputMask,
      valueHashMap: valueHashMap,
      isErrorHashMap: isErrorHashMap,
      count: individualsData.length,
      oldValueHashMap: oldValueHashMap,
      players: players,
      abbreviations: this.props.event.sport.abbreviationAvailable
        ? this.props.event.sport.abbreviations.map(abbreviation => ({
            _id: abbreviation._id,
            code: abbreviation.code,
            description: abbreviation.description,
            color: abbreviation.color
          }))
        : [],
      selectedAbbreviations: selectedAbbreviations
    };
  }

  componentDidMount() {
    this.initTextMask();
  }

  initTextMask() {
    const { valueHashMap, count, players, mask } = this.state;
    const { event } = this.props;

    for (let i = 0; i < count; i++) {
      this.textMaskInputElement[i] = TextMaskCore.createTextMaskInputElement({
        inputElement: this.inputElement[i],
        mask: mask || (inputValue => getDefaultDigitMask(event, inputValue)),
        showMask: false
      });
      const { id } = players[i];
      const value = valueHashMap[id];
      this.textMaskInputElement[i].update(value);
    }
  }

  onSubmit = () => {
    const { valueHashMap, players, selectedAbbreviations } = this.state;
    const { event, onSaveClick } = this.props;

    const isErrorHashMap = getIsErrorHashMap(event, valueHashMap, players);

    const isErrorPlayers = players.some(player => isErrorHashMap[player.id] === true);

    if (isErrorPlayers) {
      this.setState({
        isErrorHashMap: isErrorHashMap
      });
      return;
    }

    const playerScores = players.map(({ userId, permissionId, id, houseId }) => {
      const scoreValue = valueHashMap[id] !== '' ? getScorePoints(event, valueHashMap, id) : 0;
      let scoreObject = {
        userId: userId,
        permissionId: permissionId,
        houseId: houseId,
        score: scoreValue,
        scoreId: getPlayerScoreId(event, userId, permissionId),
        richScore: { result: scoreValue } as any
      };

      if (selectedAbbreviations[id]) {
        scoreObject.richScore.abbreviationResult = selectedAbbreviations[id];
      } else {
        scoreObject.richScore.abbreviationResult = null;
      }

      return scoreObject;
    });

    onSaveClick(playerScores);
  };

  onChange(event: React.ChangeEvent<HTMLInputElement>, index: number, id: string) {
    const valueHashMap = { ...this.state.valueHashMap };
    const isErrorHashMap = { ...this.state.isErrorHashMap };

    const value = event.target.value;

    valueHashMap[id] = value;
    isErrorHashMap[id] = false;

    this.setState({
      valueHashMap: valueHashMap,
      isErrorHashMap: isErrorHashMap
    });

    this.textMaskInputElement[index].update(value);
  }

  onKeyDown = (eventDescriptor, index: number) => {
    const { count } = this.state;

    const isKeyEnter = eventDescriptor.key === ENTER_KEY;
    const isKeyArrowUp = eventDescriptor.key === ARROW_UP_KEY;
    const isKeyArrowDown = eventDescriptor.key === ARROW_DOWN_KEY;
    const isLastInput = index === count;
    const isFirstInput = index === 0;

    switch (true) {
      case isKeyEnter && !isLastInput:
      case isKeyArrowDown && !isLastInput:
        this.inputElement[index + 1].focus();
        break;
      case isKeyArrowUp && !isFirstInput:
        this.inputElement[index - 1].focus();
        break;
    }
  };

  onFocus(e, index: number, id: string) {
    const value = this.state.valueHashMap[id];
    const oldValueHashMap = { ...this.state.oldValueHashMap };
    oldValueHashMap[id] = value;

    this.setState({
      oldValueHashMap: oldValueHashMap
    });

    this.textMaskInputElement[index].update('');

    e.stopPropagation();
  }

  onBlur(e, index: number, id: string) {
    const eventValue = e.target.value;
    const oldValueHashMap = { ...this.state.oldValueHashMap };

    if (eventValue === EMPTY_STRING) {
      const valueHashMapWithOldValue = { ...this.state.valueHashMap };
      valueHashMapWithOldValue[id] = oldValueHashMap[id];
      this.setState({
        valueHashMap: valueHashMapWithOldValue
      });
      this.textMaskInputElement[index].update(oldValueHashMap[id]);
    } else {
      const valueHashMap = { ...this.state.valueHashMap };
      valueHashMap[id] = eventValue;
      this.setState({
        valueHashMap: valueHashMap
      });
      this.textMaskInputElement[index].update(eventValue);
    }

    e.stopPropagation();
  }

  renderInput(index: number, id: string): React.ReactNode {
    const { isErrorHashMap } = this.state;
    return (
      <input
        ref={el => (this.inputElement[index] = el)}
        onKeyDown={event => this.onKeyDown(event, index)}
        onChange={event => this.onChange(event, index, id)}
        onFocus={event => this.onFocus(event, index, id)}
        onBlur={event => this.onBlur(event, index, id)}
        style={{ maxWidth: '80px' }}
        className={
          isErrorHashMap[id] === true
            ? 'eIndividualInterHousesResultsInput mError'
            : 'eIndividualInterHousesResultsInput'
        }
      />
    );
  }

  renderAbbreviationCheckboxes(playerId: string) {
    return (
      <div className="abbreviation-checkboxes-container">
        {this.state.abbreviations.map((abbreviation, index) => (
          <label key={index} className="abbreviation-checkbox-label">
            <input
              type="checkbox"
              className="abbreviation-checkbox"
              id={`${abbreviation.code}_${playerId}`}
              name="abbreviation"
              checked={this.state.selectedAbbreviations[playerId] === abbreviation.code}
              onChange={e => this.handleAbbreviationChange(playerId, abbreviation.code, e.target.checked)}
            />
            {abbreviation.code}
          </label>
        ))}
      </div>
    );
  }

  handleAbbreviationChange(playerId: string, code: string, isChecked: boolean) {
    if (isChecked) {
      this.setState(prevState => ({
        selectedAbbreviations: {
          ...prevState.selectedAbbreviations,
          [playerId]: code
        }
      }));
    } else {
      const selectedAbbreviations = { ...this.state.selectedAbbreviations };
      delete selectedAbbreviations[playerId];
      this.setState({ selectedAbbreviations });
    }
  }

  render() {
    const { event, onCloseClick } = this.props;
    const { players, abbreviations } = this.state;

    const isPlayersExist = players.length > 0;
    const hasAbbreviations = abbreviations.length > 0;

    if (!isPlayersExist) {
      return (
        <>
          <h1>Error</h1>
          <div className="alert alert-danger">First, add players in event</div>
          <Button text={'Close'} onClick={onCloseClick} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
        </>
      );
    }

    return (
      <div style={{ width: '100%' }}>
        <h2>Participant results</h2>
        <table className={'eIndividualInterHousesResultsTable'}>
          <thead>
            <tr>
              <th className={'eIndividualInterHousesResultsCell'} style={{ width: '5%' }}>
                #
              </th>
              <th className={'eIndividualInterHousesResultsCell'} style={{ width: '35%' }}>
                Player
              </th>
              <th className={'eIndividualInterHousesResultsCell'} style={{ width: '5%' }}>
                House
              </th>
              <th className={'eIndividualInterHousesResultsCell'} style={{ width: '10%' }}></th>
              <th className={'eIndividualInterHousesResultsCell'} style={{ width: '10%' }}>
                Results
              </th>
              {hasAbbreviations && <th className={'eIndividualInterHousesResultsCell'} style={{ width: '25%' }}></th>}
            </tr>
          </thead>
          <tbody>
            {players.map(({ firstName, lastName, id, houseName, houseColors }, index) => {
              return (
                <tr key={`individual_inter_houses_results_player_${index}`}>
                  <td className={'eIndividualInterHousesResultsCell'} style={{ width: '5%' }}>
                    {index + 1}.
                  </td>
                  <td className={'eIndividualInterHousesResultsCell'} style={{ width: '35%' }}>
                    {`${firstName} ${lastName}`}
                  </td>
                  <td className={'eIndividualInterHousesResultsCell'} style={{ width: '5%' }}>
                    {houseName}
                  </td>
                  <td className={'eIndividualInterHousesResultsCell'} style={{ width: '10%' }}>
                    {houseColors.map((color, index) => (
                      <span
                        key={index}
                        style={{
                          display: 'inline-block',
                          width: '10px',
                          height: '10px',
                          backgroundColor: color,
                          borderRadius: '50%',
                          marginLeft: '5px'
                        }}
                      ></span>
                    ))}
                  </td>
                  <td className={'eIndividualInterHousesResultsCell'} style={{ width: '10%' }}>
                    {this.renderInput(index, id)}
                  </td>
                  {hasAbbreviations && (
                    <td className={'eIndividualInterHousesResultsCell'} style={{ width: '25%' }}>
                      {this.renderAbbreviationCheckboxes(id)}
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
        <Button text={'Cancel'} onClick={onCloseClick} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
        <Button text={'Save results'} onClick={this.onSubmit} customClass={'mt-3 mb-3'} />
      </div>
    );
  }
}
