import { MessageDefinition } from '../../types/message';
import {
  AbsenceMessage,
  ClubParticipantInviteMessage,
  EventInvitationMessage,
  EventParticipationMessage
} from '../../models/message';
import {
  MESSAGE_INVITATION_STATUS,
  MESSAGE_KIND,
  MESSAGE_KIND_SERVER_TO_CLIENT_MAPPING,
  MESSAGE_TYPE
} from '../../consts/message';

export function getProgress(current: number, total: number): number {
  return (current / total) * 100;
}

export function isAbsenceMessage(message: MessageDefinition): message is AbsenceMessage {
  return (message as any).kind === MESSAGE_KIND.AbsenceMessage;
}

export function isEventInvitationMessage(message: MessageDefinition): message is EventInvitationMessage {
  return (message as any).kind === MESSAGE_KIND.EventInvitationMessage;
}

export function isEventParticipationMessage(message: MessageDefinition): message is EventParticipationMessage {
  return (message as any).kind === MESSAGE_KIND.EventParticipationMessage;
}

export function isClubParticipantInviteMessage(message: MessageDefinition): message is ClubParticipantInviteMessage {
  return (message as any).kind === MESSAGE_KIND.ClubParticipantInviteMessage;
}

export function getStatus(message, type): string {
  let status = '';

  const isMessageTypeArchive = type === MESSAGE_TYPE.ARCHIVE;

  const isMessageKindInvitation = message.kind === MESSAGE_KIND.EventInvitationMessage;
  const isMessageKindConfirmationRequest = message.kind === MESSAGE_KIND.TournamentConfirmationRequestMessage;
  const isMessageKindParticipantInvite = message.kind === MESSAGE_KIND.ClubParticipantInviteMessage;

  const isMessageStatusAccepted = message.invitationStatus === MESSAGE_INVITATION_STATUS.ACCEPTED;
  const isMessageStatusOutdated = message.invitationStatus === MESSAGE_INVITATION_STATUS.OUTDATED;
  const isMessageStatusRejected = message.invitationStatus === MESSAGE_INVITATION_STATUS.REJECTED;

  if (isMessageTypeArchive) {
    switch (true) {
      case isMessageKindInvitation:
      case isMessageKindConfirmationRequest:
        switch (true) {
          case isMessageStatusAccepted:
            status = 'Accepted';
            break;
          case isMessageStatusOutdated:
            status = 'Outdated';
            break;
          case isMessageStatusRejected:
            status = 'Declined';
            break;
        }
        break;
      case isMessageKindParticipantInvite:
        switch (true) {
          case isMessageStatusAccepted:
            status = 'Booked';
            break;
          case isMessageStatusOutdated:
            status = 'Outdated';
            break;
          case isMessageStatusRejected:
            status = 'Declined';
            break;
        }
        break;
    }
  }

  return status;
}
