import * as React from 'react';
import * as BPromise from 'bluebird';
import { AppUser } from 'Src/views/App/App';
import { parse } from 'query-string';
import { getOrder, getSearchFilter, getSearchOrder, getServerFieldSectionWhere } from 'Src/helpers/table/table';
import {
  ColumnDefinition,
  getFilters2,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { History, Location } from 'history';
import { Grid2 } from 'Src/components/Grid/Grid2';
import { Loader } from 'Src/components/Loader/Loader';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { getTournament } from 'Src/helpers/service/adminSU/tournamentsTable';
import { Tournament, TournamentCustomGroup } from 'Src/models/tournament';
import {
  assignPitchByTournamentIdAndCustomGroupId,
  createTournamentGroup,
  deleteTournamentGroup,
  editTournamentGroup,
  getAllTournamentCustomGroups,
  getTournamentCustomGroups,
  getTournamentCustomGroupsCount,
  unassignPitchByTournamentIdAndCustomGroupId
} from 'Src/helpers/service/adminSU/tournamentCustomGroupsTable';
import {
  getTournamentCustomGroupPitch,
  getTournamentStageName,
  getTournamentCustomGroupSubstage,
  getTournamentCustomGroupPrize
} from 'Src/helpers/accessor/accessor';
import { EventPitchForm } from 'Src/components/EventPitchForm/EventPitchForm';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import * as propz from 'propz';
import { TournamentGroupForm } from './TournamentGroupForm/TournamentGroupForm';
import { getTournamentEvents } from '../../../../helpers/service/admin/tournamentEvents';
import { SchoolEvent } from '../../../../models/event';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Name',
    field: 'name',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: ['name']
  },
  {
    text: 'Stage',
    field: 'stage',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getTournamentStageName
  },
  {
    text: 'Pitch',
    field: 'pitch',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getTournamentCustomGroupPitch
  },
  {
    text: 'Prize',
    field: 'prize',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getTournamentCustomGroupPrize
  },
  {
    text: 'Substage',
    field: 'substage',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getTournamentCustomGroupSubstage
  }
];

interface State {
  items: TournamentCustomGroup[];
  tournamentEvents: SchoolEvent[];
  currentPage: number;
  selectedItems: TournamentCustomGroup[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  filters: any;
  isDataFiltered: boolean;
  isLoading: boolean;
  total: number;
  basePath: string;
  isSelectAllChecked: boolean;
  tournament: Tournament;
  isPitchModalOpen: boolean;
  isGroupModalOpen: boolean;

  isErrorModalOpen: boolean;
  errorText: string;
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export class TournamentCustomGroups extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      tournamentEvents: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      filters: {},
      isDataFiltered: false,
      isLoading: false,
      total: 0,
      basePath: '',
      isSelectAllChecked: false,
      tournament: undefined,
      isPitchModalOpen: false,
      isGroupModalOpen: false,
      isErrorModalOpen: false,
      errorText: ''
    };
  }

  getTournamentId(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    const tournamentId = search.tournament;
    return tournamentId;
  }

  setAdditionalItems() {
    const { user } = this.props;
    const tournamentId = this.getTournamentId();

    let tournament;
    return getTournament(user, tournamentId)
      .then(tournament => {
        return tournament;
      })
      .then(_tournament => {
        tournament = _tournament;
        return getTournamentEvents(user, tournamentId);
      })
      .then(events => {
        this.setState({
          tournament,
          tournamentEvents: events
        });
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        this.setState({
          isLoading: false,
          isErrorModalOpen: true,
          errorText
        });
      });
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setAdditionalItems().then(res => {
      this.setItems();
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const tournamentId = this.getTournamentId();

    const getItemsPromise = getTournamentCustomGroups(user, tournamentId, serverQueryFilter);
    const getItemsCountPromise = getTournamentCustomGroupsCount(user, tournamentId, where);

    const promises = [getItemsCountPromise, getItemsPromise];

    return BPromise.all(promises).then(([countObj, items]) => {
      this.setState({
        currentPage: page,
        sortDirection: sortDirection,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: items,
        total: countObj.count,
        basePath: history.location.pathname,
        isLoading: false
      });

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;
    const { history } = this.props;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const tournamentId = this.getTournamentId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `tournament=${tournamentId}&${search.join('&')}`,
      state: history.location.state
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: nextSelectedItems,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    const tournamentId = this.getTournamentId();

    getAllTournamentCustomGroups(user, tournamentId, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const nextSelectedItems = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: nextSelectedItems,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;
    const { history } = this.props;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const tournamentId = this.getTournamentId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `tournament=${tournamentId}&${search.join('&')}`,
      state: history.location.state
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    const { history } = this.props;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    const tournamentId = this.getTournamentId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `tournament=${tournamentId}&${search.join('&')}`,
      state: history.location.state
    });
  };

  onClearFilterClick = (): void => {
    const { history } = this.props;
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    const tournamentId = this.getTournamentId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `tournament=${tournamentId}`,
      state: history.location.state
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  isOneItemSelected(): boolean {
    const selectedItems = this.state.selectedItems;
    return selectedItems.length === 1;
  }

  goBack = () => {
    const { history, location } = this.props;
    const { state } = location;
    const { search, prevPath } = state as any;
    const tournamentId = this.getTournamentId();

    history.push({
      pathname: prevPath,
      search: search,
      state: { tournamentId: tournamentId }
    });
  };

  onPitchUnassignClick = (): void => {
    const { selectedItems, tournament } = this.state;
    const { user } = this.props;

    const { id: tournamentId } = tournament;

    this.setState({
      isLoading: true
    });

    BPromise.all(
      selectedItems.map(customGroup => {
        const { id } = customGroup;
        return unassignPitchByTournamentIdAndCustomGroupId(user, tournamentId, id);
      })
    ).then(res => {
      this.setState({
        isPitchModalOpen: false
      });
      this.setItems();
    });
  };

  oAddGroupClick = () => {
    this.setState({
      isGroupModalOpen: true
    });
  };

  onEditGroupClick = () => {
    this.setState({
      isGroupModalOpen: true
    });
  };

  onGroupModalCloseClick = () => {
    this.setState({
      isGroupModalOpen: false
    });
  };

  onDeleteGroupClick = () => {
    const { selectedItems, tournamentEvents } = this.state;
    const { user } = this.props;
    const tournamentId = this.getTournamentId();
    this.setState({
      isLoading: true
    });

    const groupsWithEvent = selectedItems.filter(item =>
      tournamentEvents.some(event => item.id === event.tournamentGroupId)
    );
    if (groupsWithEvent.length > 0) {
      this.setState({
        isLoading: false,
        isErrorModalOpen: true
      });
    } else {
      BPromise.all(selectedItems.map(group => deleteTournamentGroup(user, tournamentId, group.id)))
        .then(res => {
          return this.setAdditionalItems();
        })
        .then(res => {
          this.setState({
            selectedItems: []
          });
          this.setItems();
        });
    }
  };

  renderGrid() {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isSelectAllChecked,
      currentPage,
      total,
      tournament
    } = this.state;

    const gridTitle = typeof tournament !== 'undefined' ? `${tournament.name} / Custom groups` : '';

    const actionItems = [
      {
        itemText: 'Add group',
        onItemClick: this.oAddGroupClick,
        isActive: selectedItems.length === 0
      },
      {
        itemText: 'Edit group',
        onItemClick: this.onEditGroupClick,
        isActive: selectedItems.length === 1
      },
      {
        itemText: selectedItems.length === 1 ? 'Delete group' : 'Delete groups',
        onItemClick: this.onDeleteGroupClick,
        isActive: selectedItems.length > 0
      },
      {
        itemText: 'Assign pitch to the group',
        onItemClick: this.onPitchAssignClick,
        isActive: selectedItems.length > 0
      },
      {
        itemText: 'Unassign pitch from group',
        onItemClick: this.onPitchUnassignClick,
        isActive: selectedItems.length > 0
      }
    ];

    const filterOptions = {};

    return (
      <Grid2
        dataItems={items}
        filters={filters}
        currentPage={currentPage}
        total={total}
        isSelectAllChecked={isSelectAllChecked}
        isDataFiltered={isDataFiltered}
        sortDirection={sortDirection}
        sortColumnsName={sortColumnsName}
        isShowFilter={isShowFilter}
        dataItemsSelected={selectedItems}
        columns={COLUMNS}
        actionItems={actionItems}
        options={filterOptions}
        onItemClick={this.onItemClick}
        onSortClick={this.onTableSortClick}
        onApplyFilterClick={this.onApplyFilterClick}
        onClearFilterClick={this.onClearFilterClick}
        onTableFilterChange={this.onTableFilterChange}
        onTableFilterClick={this.onTableFilterClick}
        setCurrentPageParams={this.setCurrentPageParams}
        onSelectAllClick={this.onSelectAllClick}
        onSelectAllOnPageClick={this.onSelectAllOnPageClick}
        onUnselectAllClick={this.onUnselectAllClick}
        gridTitle={gridTitle}
        goBack={this.goBack}
        tournament={tournament}
      />
    );
  }

  onPitchModalCloseClick = (): void => {
    this.setState({
      isPitchModalOpen: false
    });
  };

  onPitchAssignClick = (): void => {
    this.setState({
      isPitchModalOpen: true
    });
  };

  getPitchesFromTournament() {
    const { tournament } = this.state;

    const pitches = propz.get(tournament, ['pitches'], []);

    return pitches;
  }

  onSaveButtonClick = data => {
    const { selectedItems, tournament } = this.state;
    const { user } = this.props;

    const { id: tournamentId } = tournament;

    this.setState({
      isLoading: true
    });

    BPromise.all(
      selectedItems.map(customGroup => {
        const { id } = customGroup;
        return assignPitchByTournamentIdAndCustomGroupId(user, data, tournamentId, id);
      })
    ).then(items => {
      this.setState({
        isPitchModalOpen: false,
        selectedItems: items
      });
      this.setItems();
    });
  };

  renderAssignPitchModal(): React.ReactNode {
    const { isPitchModalOpen } = this.state;

    return (
      <SimpleModal isOpen={isPitchModalOpen} title={'Assign pitch'}>
        <EventPitchForm
          onCancel={this.onPitchModalCloseClick}
          onSubmit={this.onSaveButtonClick}
          pitches={this.getPitchesFromTournament()}
        />
      </SimpleModal>
    );
  }

  addTournamentGroup = data => {
    const { user } = this.props;
    const tournamentId = this.getTournamentId();
    const dataToTransfer = { ...data };

    this.setState({
      isLoading: true,
      isGroupModalOpen: false
    });

    createTournamentGroup(user, tournamentId, dataToTransfer)
      .then(nextGroup => {
        return this.setAdditionalItems();
      })
      .then(res => {
        this.setItems();
      });
  };

  editTournamentGroup = data => {
    const { user } = this.props;
    const { selectedItems } = this.state;
    const group = selectedItems[0];
    const { id } = group;

    const tournamentId = this.getTournamentId();
    const dataToTransfer = { ...data };

    this.setState({
      isLoading: true,
      isGroupModalOpen: false
    });

    editTournamentGroup(user, tournamentId, id, dataToTransfer)
      .then(nextGroup => {
        this.setState({
          selectedItems: [nextGroup]
        });
        return this.setAdditionalItems();
      })
      .then(res => {
        this.setItems();
      });
  };

  closeErrorModal = () => {
    this.setState({
      isErrorModalOpen: false
    });
  };

  renderGroupModal(): React.ReactNode {
    const { isGroupModalOpen, selectedItems, tournament } = this.state;

    if (selectedItems.length === 0) {
      return (
        <SimpleModal isOpen={isGroupModalOpen} title={'Add group'}>
          <TournamentGroupForm
            onCancel={this.onGroupModalCloseClick}
            onSubmit={this.addTournamentGroup}
            tournament={tournament}
          />
        </SimpleModal>
      );
    } else {
      const group = selectedItems[0];
      return (
        <SimpleModal isOpen={isGroupModalOpen} title={'Edit group'}>
          <TournamentGroupForm
            onCancel={this.onGroupModalCloseClick}
            onSubmit={this.editTournamentGroup}
            tournament={tournament}
            group={group}
          />
        </SimpleModal>
      );
    }
  }

  renderErrorModal() {
    const { isErrorModalOpen, tournamentEvents, selectedItems } = this.state;
    const groupsWithEvent = selectedItems.filter(item =>
      tournamentEvents.some(event => item.id === event.tournamentGroupId)
    );
    return (
      <SimpleModal
        isOpen={isErrorModalOpen}
        title={'Warning'}
        body={`The ${
          groupsWithEvent.length === 1
            ? `group ${propz.get(groupsWithEvent[0], ['name'])} is`
            : `groups ${groupsWithEvent.map(group => group.name).join(', ')} are`
        } linked to the ${groupsWithEvent.length === 1 ? `event;` : `events;`} unlink it to proceed.`}
        buttonCancelText={'Ok'}
        onCloseClick={this.closeErrorModal}
        customClass={''}
      />
    );
  }

  render() {
    const { isLoading, isPitchModalOpen, isGroupModalOpen } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes = isPitchModalOpen || isGroupModalOpen ? 'mt-3 modal-open' : 'mt-3';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderAssignPitchModal()}
        {this.renderGroupModal()}
        {this.renderErrorModal()}
        <div className="row">
          <div className="col-md-12">{this.renderGrid()}</div>
        </div>
      </div>
    );
  }
}
