import * as React from 'react';
import * as propz from 'propz';
import { FunctionComponent } from 'react';
import * as Moment from 'moment';
import { getInviteStatus } from '../../helpers/accessor/accessor';
import {
  getLocation,
  getSchoolInviteRecepient,
  getSchoolUnionNameIfNeed,
  getTournamentLocation
} from '../../helpers/invite/invite';
import { SchoolInvite } from '../../models/invite';

interface Props {
  invite: SchoolInvite;
  activeSchoolId: string;
  messageType: string;
}

const ROWS = [
  { title: 'School', field: 'schoolName' },
  { title: 'Event', field: 'event' },
  { title: 'Start time', field: 'startTime' },
  { title: 'Venue', field: 'venue' },
  { title: 'Status', field: 'status' }
];

export const AdminSchoolsTournamentSchoolInviteSummary: FunctionComponent<Props> = props => {
  const rows = ROWS.map((row, index) => {
    let contentCell: any;
    const field = row.field;

    const { invite, activeSchoolId } = props,
      { tournament, inviterSchool, invitedSchool } = invite;

    switch (field) {
      case 'schoolName':
        const recepient = getSchoolInviteRecepient(inviterSchool, invitedSchool, activeSchoolId);
        const tournamentSchoolName = getSchoolUnionNameIfNeed(inviterSchool);

        contentCell = `${tournamentSchoolName}${recepient}`;
        break;
      case 'event':
        const sportName = invite.sport ? invite.sport.name : '';
        const tournamentName = typeof tournament !== 'undefined' ? `${tournament.name} / ` : '';

        contentCell = `${tournamentName}${sportName}`;
        break;
      case 'startTime':
        const tournamentDate = new Date(tournament.startTime);

        contentCell = typeof tournamentDate !== 'undefined' ? Moment(tournamentDate).format('DD.MM.YYYY HH:mm') : '';
        break;
      case 'venue':
        contentCell = getTournamentLocation(invite, activeSchoolId);
        break;
      case 'status':
        contentCell = typeof invite.status !== 'undefined' ? getInviteStatus(invite) : '';
        break;
      default:
        contentCell = propz.get(invite, row.field.split('.'), '');
    }

    return (
      <tr key={`invite_summary_${index}`}>
        <td>{row.title}</td>
        <td>{contentCell}</td>
      </tr>
    );
  });

  return (
    <table className={'table table-sm mPre mVerticalAlignMiddle'}>
      <tbody>{rows}</tbody>
    </table>
  );
};

AdminSchoolsTournamentSchoolInviteSummary.displayName = 'AdminSchoolsTournamentSchoolInviteSummary';
