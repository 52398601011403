export const INVITE_TABS = ['Summary'];

export const INVITE_STATUS = {
  ACCEPTED: 'ACCEPTED', // those who received invite accepted sender's invitation
  REJECTED: 'REJECTED', // those who received invite rejected sender's invitation
  PREBOOKED: 'PREBOOKED', // invite booked. still await final status ACCEPTED or REJECTED
  NOT_READY: 'NOT_READY', // no decision made on this invite
  OUTDATED: 'OUTDATED', // invite was mark as outdated by system cron script
  CANCELED: 'CANCELED' // invite was canceled by sender
};

export const INVITE_STATUS_SERVER_TO_CLIENT_MAPPING = {
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected',
  PREBOOKED: 'Prebooked',
  NOT_READY: 'Waiting for school response',
  OUTDATED: 'Outdated',
  CANCELED: 'Cancelled'
};

//only frontend status
export const INVITE_STATUS_NOT_SENT_MAPPING = 'Not sent';
export const INVITE_STATUS_NOT_SENT = 'NOT_SENT';
export const INVITE_STATUS_AUTO_ACCEPTED_MAPPING = 'Accepted (A)';

export const INVITE_OUTBOX_STATUS_SERVER_TO_CLIENT_MAPPING = {
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected',
  PREBOOKED: 'Prebooked',
  NOT_READY: 'Waiting for school response',
  CANCELED: 'Cancelled'
};

export const INVITE_TYPE = {
  INBOX: 'INBOX',
  OUTBOX: 'OUTBOX',
  ARCHIVE: 'ARCHIVE'
};

export const INVITE_KIND = {
  EVENT_SCHOOL_INVITE: 'EventSchoolInvite',
  TOURNAMENT_SCHOOL_INVITE: 'TournamentSchoolInvite',
  CLUB_SCHOOL_INVITE: 'ClubSchoolInvite'
};
