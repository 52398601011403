import axios from 'axios';
import { AppUser } from 'Src/views/App/App';

export function login(email: string, password: string) {
  return axios.post(`${window.apiBase}/i/login`, { email, password }).then(response => {
    return response.data;
  });
}

export function become(user: AppUser, name: string) {
  const key = user.loginSessionKey;

  const config = {
    headers: { usid: key }
  };

  return axios.post(`${window.apiBase}/i/roles/${name}/become`, {}, config).then(response => {
    return response.data;
  });
}

export function logout(loginSessionKey, roleSessionKey) {
  const loginConfig = {
    headers: { usid: loginSessionKey }
  };

  const roleConfig = {
    headers: { usid: roleSessionKey }
  };

  return axios.delete(`${window.apiBase}/i/sessions/${loginSessionKey}`, loginConfig).then(
    () => {
      return axios.delete(`${window.apiBase}/i/sessions/${roleSessionKey}`, roleConfig);
    },
    () => {
      return axios.delete(`${window.apiBase}/i/sessions/${roleSessionKey}`, roleConfig);
    }
  );
}

export function logoutReduced(loginSessionKey) {
  const config = {
    headers: { usid: loginSessionKey }
  };

  return axios.delete(`${window.apiBase}/i/sessions/${loginSessionKey}`, config);
}

export function roleout(roleSessionKey) {
  const roleConfig = {
    headers: { usid: roleSessionKey }
  };

  return axios.delete(`${window.apiBase}/i/sessions/${roleSessionKey}`, roleConfig);
}
