import * as React from 'react';
import { AppUser } from 'Src/views/App/App';
import * as Promise from 'bluebird';
import { Component } from 'react';
import { DATE_INTERVAL, DEFAULT_LIMIT, DEFAULT_SKIP, FILTER_TYPE, FIRST_PAGE } from '../../../../../../consts/table';
import { History, Location } from 'history';
import { parse } from 'query-string';
import { SimpleModal } from '../../../../../../components/SimpleModal/SimpleModal';
import {
  ColumnDefinition,
  getFilters2,
  getOrder,
  getSearchFilter,
  getSearchOrder,
  getServerFieldSectionWhere,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from '../../../../../../helpers/table/table';
import {
  getCoachEvent,
  getEventStartTime,
  getEventStatus,
  getEventVenue,
  getStaffEvent,
  getEventGeneratedName
} from '../../../../../../helpers/accessor/accessor';
import { SchoolForm } from '../../../../../../models/form';
import { getAllForms } from '../../../../../../helpers/service/admin/forms';
import * as propz from 'propz';
import * as BPromise from 'bluebird';
import {
  getSelectOptionForAge,
  getSelectOptionForEventTypes,
  getSelectOptionForEventVenueType,
  getSelectOptionForSport,
  getSelectOptionForTournamentEventGender,
  getSelectOptionForClubEventStatus
} from '../../../../../../helpers/table/select';
import { Grid2 } from '../../../../../../components/Grid/Grid2';
import { SchoolEvent } from '../../../../../../models/event';
import { Sport } from '../../../../../../models/sport';
import { getEventNameWithLink } from '../../../../../../helpers/cell/cell';
import {
  getAllClubEvents,
  getClub,
  getClubEvents,
  getClubEventsCount
} from '../../../../../../helpers/service/admin/clubEvents';
import { Club } from '../../../../../../models/club';
import { Loader } from '../../../../../../components/Loader/Loader';
import { getSchoolSports } from '../../../../../../helpers/service/admin/user';
import {
  getActionDescriptor,
  reasonForCancellation,
  updateActionDescriptor,
  cancelEvent,
  deleteEvent
} from '../../../../../../helpers/service/admin/schoolEvents';
import { EVENT_NOTIFICATION_MODE, EVENT_STATUS } from '../../../../../../consts/event';
import * as Moment from 'moment';
import { Button } from 'Src/components/Button/Button';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Event',
    field: 'generatedName',
    isSort: false,
    type: FILTER_TYPE.NONE,
    cell: getEventNameWithLink
  },
  {
    text: 'Start time',
    field: 'startTime',
    isSort: true,
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: getEventStartTime
  },
  {
    text: 'Status',
    field: 'status',
    isSort: true,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getEventStatus
  },
  {
    text: 'Venue',
    field: 'venue',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getEventVenue
  },
  {
    text: 'Coach/Leader',
    field: 'coach',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getCoachEvent
  },
  {
    text: 'Staff',
    field: 'staff',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getStaffEvent
  }
];

interface State {
  items: SchoolEvent[];
  currentPage: number;
  selectedItems: SchoolEvent[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  filterOptions: any;
  currentTabIndex: number;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;
  isErrorModalOpen: boolean;
  errorMessage: string;

  isCancelEventModalOpen: boolean;
  isDeleteEventModalOpen: boolean;
  isSuccessDeleteEventModalOpen: boolean;
  isCancelWithoutReason: boolean;

  forms: SchoolForm[];
  sports: Sport[];
  club: Club;
  actionDescriptorIds: any[];

  cancelReason: string;
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export class ClubEvents extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      filterOptions: undefined,
      isDataFiltered: false,
      currentTabIndex: 0,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',
      isErrorModalOpen: false,
      errorMessage: '',
      forms: [],
      sports: [],
      club: undefined,
      isCancelEventModalOpen: false,
      isDeleteEventModalOpen: false,
      isSuccessDeleteEventModalOpen: false,
      actionDescriptorIds: [],
      isCancelWithoutReason: false,
      cancelReason: ''
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });
    this.setAdditionalItems().then(res => {
      this.setItems();
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  getClubId(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    const clubId = search.club;
    return clubId;
  }

  setAdditionalItems() {
    const { user } = this.props;
    const filter = { limit: DEFAULT_LIMIT, order: 'startTime ASC', skip: DEFAULT_SKIP };
    const clubId = this.getClubId();

    const formsPromise = getAllForms(user);
    const sportsPromise = getSchoolSports(user, filter);
    const clubPromise = getClub(user, clubId);

    return Promise.all([formsPromise, sportsPromise, clubPromise]).then(([forms, sports, club]) => {
      const filterOptions = this.getFilterOptions(forms, sports);
      this.setState({
        forms: forms,
        sports: sports,
        club: club,
        filterOptions: filterOptions
      });

      return true;
    });
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters, { isCorrectTime: true });

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);
    const clubId = this.getClubId();

    const getItemsPromise = getClubEvents(user, clubId, serverQueryFilter);
    const getItemsCountPromise = getClubEventsCount(user, clubId, where);

    return Promise.all([getItemsCountPromise, getItemsPromise]).then(([countObj, items]) => {
      this.setState({
        currentPage: page,
        sortDirection: sortDirection as TABLE_SORT_DIRECTION,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: items,
        total: countObj.count,
        basePath: history.location.pathname,
        isLoading: false
      });

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const clubId = this.getClubId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `club=${clubId}&${search.join('&')}`
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated,
      currentTabIndex: 0
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const selectedItemsNext = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: selectedItemsNext,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;
    const where = getServerFieldSectionWhere(filters);
    const clubId = this.getClubId();

    getAllClubEvents(user, clubId, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const selectedItemsNext = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: selectedItemsNext,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const clubId = this.getClubId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `club=${clubId}&${search.join('&')}`
    });
  };

  onTableFilterChange = (event, filterField: string, options?, index?: number, checkBoxIndex?: number): void => {
    const filterValue = propz.get(event, ['target', 'value'], '');
    const { filters, filterOptions } = this.state;
    const currentFilterField = filters[filterField];
    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      switch (filterType) {
        case FILTER_TYPE.MULTISELECT:
          const options = event.target.options;

          const value = [];
          for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
              value.push(options[i].value);
            }
          }
          nextFilters = {
            ...nextFilters,
            [filterField]: value
          };
          break;
        case FILTER_TYPE.CHECKBOX:
          const columns = COLUMNS;
          const currentField = columns[index].field;
          filterOptions[currentField][checkBoxIndex].isChecked = !filterOptions[currentField][checkBoxIndex].isChecked;

          this.setState({
            filterOptions: filterOptions
          });

          const checkBoxValue = [];
          if (typeof filterOptions[filterField] !== 'undefined') {
            for (let i = 0; i < filterOptions[filterField].length; i++) {
              if (filterOptions[filterField][i].isChecked) {
                checkBoxValue.push(filterOptions[filterField][i].value);
              }
            }
          }
          nextFilters = {
            ...nextFilters,
            [filterField]: checkBoxValue
          };
          break;
        default:
          nextFilters = {
            ...nextFilters,
            [filterField]: filterValue
          };
          break;
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    const clubId = this.getClubId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `club=${clubId}&${search.join('&')}`
    });
  };

  onClearFilterClick = (): void => {
    const { forms, sports } = this.state;
    const filterOptions = this.getFilterOptions(forms, sports);

    this.setState({
      filterOptions: filterOptions,
      selectedItems: [],
      isSelectAllChecked: false
    });

    const clubId = this.getClubId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `club=${clubId}`
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  cancelEventModalOpen = () => {
    this.setState({
      isCancelEventModalOpen: true,
      cancelReason: ''
    });
  };

  cancelEventModalClose = (): void => {
    this.setState({
      isCancelEventModalOpen: false,
      cancelReason: '',
      selectedItems: []
    });
  };

  deleteEventModalOpen = () => {
    this.setState({
      isDeleteEventModalOpen: true
    });
  };

  deleteEventModalClose = (): void => {
    this.setState({
      isDeleteEventModalOpen: false
    });
  };

  deleteSuccessEventModalClose = (): void => {
    this.setState({
      isSuccessDeleteEventModalOpen: false
    });
  };

  onCloseErrorClick = () => {
    this.setState({
      isErrorModalOpen: false,
      errorMessage: ''
    });
  };

  renderSendingError(): React.ReactNode {
    const { errorMessage, isErrorModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isErrorModalOpen}
        title={'Error'}
        body={errorMessage}
        buttonCancelText={'Ok'}
        onCloseClick={this.onCloseErrorClick}
      />
    );
  }

  onShowError = (message: string) => {
    this.setState({
      isErrorModalOpen: true,
      errorMessage: message
    });
  };

  getFilterOptions = (forms, sports) => {
    const user = this.props.user;
    const activeSchool = user.activeSchool;
    const ageGroupsNaming = activeSchool.ageGroupsNaming;

    return {
      venueType: getSelectOptionForEventVenueType(),
      eventAges: getSelectOptionForAge(forms, ageGroupsNaming),
      gender: getSelectOptionForTournamentEventGender(),
      status: getSelectOptionForClubEventStatus(),
      eventType: getSelectOptionForEventTypes(),
      eventSport: getSelectOptionForSport(sports)
    };
  };

  goBack = () => {
    const search = propz.get(this.props.location, ['state', 'search']);
    const isSearchExist = typeof search !== 'undefined';
    const clubId = this.getClubId();

    this.props.history.push({
      pathname: '/clubs',
      search: isSearchExist ? search : '',
      state: { clubId }
    });
  };

  onDeleteEventClick = () => {
    const { selectedItems } = this.state;
    const { user } = this.props;

    this.setState({
      isDeleteEventModalOpen: false,
      isLoading: true
    });

    const promises = selectedItems.map(event => {
      return deleteEvent(user, event.id);
    });
    BPromise.all(promises)
      .then(() => {
        this.setState({
          selectedItems: [],
          isSuccessDeleteEventModalOpen: true
        });
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        console.error(error);
        this.setState({
          isLoading: false,
          errorMessage: errorText,
          isErrorModalOpen: true
        });
      });

    this.setItems();
  };

  onCancelEventClick = () => {
    const { selectedItems, cancelReason } = this.state;
    const { user } = this.props;
    const notificationMode = propz.get(user, ['activeSchool', 'isEventUpdateNotificationCustomMessageEnabled'], false)
      ? EVENT_NOTIFICATION_MODE.MANUAL
      : EVENT_NOTIFICATION_MODE.AUTO;

    this.setState({ isLoading: true });

    let actionDescriptorIds = [];
    const promises = selectedItems.map(event => {
      return cancelEvent(user, event.id, notificationMode)
        .then(res => {
          const updateEvent = res.data;
          const actionDescriptorId = propz.get(res, ['headers', 'action-descriptor-id'], '');
          actionDescriptorIds.push(actionDescriptorId);
        })
        .catch(error => {
          const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
          console.error(error);
          this.setState({
            isLoading: false,
            errorMessage: errorText,
            isErrorModalOpen: true
          });
        });
    });

    Promise.all(promises).then(() => {
      if (notificationMode === EVENT_NOTIFICATION_MODE.MANUAL && cancelReason) {
        const reasonForCancellationPromise = selectedItems.map(event =>
          reasonForCancellation(user, event.id, cancelReason)
        );
        Promise.all(reasonForCancellationPromise).then(() => {
          this.updateActionDescriptors(actionDescriptorIds, cancelReason);
        });
      } else {
        this.updateActionDescriptors(actionDescriptorIds);
      }
    });
  };

  updateActionDescriptors = (actionDescriptorIds, additionalDescription = '') => {
    const { user } = this.props;
    const promises = actionDescriptorIds
      .filter(id => id)
      .map(actionDescriptorId => {
        return getActionDescriptor(user, actionDescriptorId)
          .then(actionDescriptor => {
            const USERS_COUNT_IN_CHUNK = 30;
            const usersFiltered = [...actionDescriptor.affectedUserList];
            let userChunks = [];

            for (let i = 0; i < usersFiltered.length; i += USERS_COUNT_IN_CHUNK) {
              const chunk = usersFiltered.slice(i, i + USERS_COUNT_IN_CHUNK);
              userChunks.push(chunk);
            }

            const userChunksCount = userChunks.length;

            return BPromise.all(
              userChunks.map((userChunk, index) => {
                const data = {
                  manualConfirmationStatus: index === userChunksCount - 1 ? 'CONFIRMED' : 'AWAITING_CONFIRMATION',
                  usersToNotifyList: userChunk,
                  additionalDescription: additionalDescription
                };
                return updateActionDescriptor(user, actionDescriptor.id, data);
              })
            );
          })
          .catch(error => {
            if (error.response && error.response.status === 404) {
              console.warn(`ActionDescriptor not found for id: ${actionDescriptorId}`);
            } else {
              const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
              console.error(`Error updating ActionDescriptor ${actionDescriptorId}:`, errorText);
              this.setState({
                isLoading: false,
                errorMessage: errorText,
                isErrorModalOpen: true
              });
            }
          });
      });

    return BPromise.all(promises)
      .then(() => {
        this.setState({
          isLoading: false,
          isCancelEventModalOpen: false,
          actionDescriptorIds: [],
          selectedItems: []
        });
        this.setItems();
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        console.error(error);
        this.setState({
          isLoading: false,
          errorMessage: errorText,
          isErrorModalOpen: true
        });
      });
  };

  createUserChunks = userList => {
    const chunkSize = 30;
    let chunks = [];
    for (let i = 0; i < userList.length; i += chunkSize) {
      chunks.push(userList.slice(i, i + chunkSize));
    }
    return chunks;
  };

  renderCancelEventModal() {
    const { selectedItems, isCancelEventModalOpen, cancelReason } = this.state;
    const { user } = this.props;
    const isEventUpdateNotificationCustomMessageEnabled = propz.get(
      user,
      ['activeSchool', 'isEventUpdateNotificationCustomMessageEnabled'],
      false
    );

    return (
      <SimpleModal isOpen={isCancelEventModalOpen} title={'Cancel event'}>
        <div>
          <p>
            Are you sure you want to cancel
            {selectedItems.length === 1 ? (
              <>
                {' '}
                Event <b>{getEventGeneratedName(selectedItems[0], { user: user })}</b> (
                {Moment(selectedItems[0].startTime).format('dddd, MMMM Do YYYY, h:mm a')})?
              </>
            ) : (
              <> the following {selectedItems.length} Events:</>
            )}
          </p>
          {selectedItems.length > 1 && (
            <ul>
              {selectedItems.map((event, index) => (
                <li key={index}>
                  <b>{getEventGeneratedName(event, { user: user })}</b> (
                  {Moment(event.startTime).format('dddd, MMMM Do YYYY, h:mm a')}):
                </li>
              ))}
            </ul>
          )}
          {isEventUpdateNotificationCustomMessageEnabled && (
            <>
              <p>
                If you need to provide extra details like about the event to be rescheduled, or pitches frozen, etc.,
                specify them in the field below. The reason will be included in the notification sent to team members
                and parents. If you want to cancel event(s) without providing a reason, just keep the field empty.
              </p>
              <label htmlFor="cancelReason">
                <b>Reason for cancellation (optional):</b>
              </label>
              <textarea
                id="cancelReason"
                value={cancelReason}
                placeholder="Please enter a reason for cancellation if applicable"
                onChange={e => this.setState({ cancelReason: e.target.value })}
                className="form-control"
                style={{ width: '100%', height: '100px', marginTop: '10px' }}
              />
            </>
          )}
        </div>
        <div className="modal-footer">
          <Button
            text={selectedItems.length === 1 ? 'Keep event active' : 'Keep events active'}
            onClick={this.cancelEventModalClose}
            customClass={'mt-3 mb-3 mr-3 btn-secondary'}
          />
          <Button text="Confirm cancellation" onClick={this.onCancelEventClick} />
        </div>
      </SimpleModal>
    );
  }

  renderDeleteEventModal() {
    const { selectedItems, isDeleteEventModalOpen } = this.state;
    const { user } = this.props;
    const eventName = getEventGeneratedName(selectedItems[0], { user: user });

    return (
      <SimpleModal
        isOpen={isDeleteEventModalOpen}
        title={'Remove Event'}
        body={`Are you sure you want to remove ${
          selectedItems.length === 1 ? `Event ${eventName}` : `${selectedItems.length} Events`
        }?`}
        buttonText={'Delete'}
        onButtonClick={this.onDeleteEventClick}
        onCloseClick={this.deleteEventModalClose}
      />
    );
  }

  renderConfirmationDeleteEventModal() {
    const { isSuccessDeleteEventModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isSuccessDeleteEventModalOpen}
        title={'Success'}
        body={`Selected events have been successfully removed`}
        buttonText={'Ok'}
        onButtonClick={this.deleteSuccessEventModalClose}
      />
    );
  }

  render() {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isSelectAllChecked,
      currentPage,
      total,
      filterOptions,
      club,
      isErrorModalOpen,
      isLoading
    } = this.state;

    const isCancelEventActionAvailable = selectedItems.every(
      item =>
        item.status === EVENT_STATUS.ACCEPTED ||
        item.status === EVENT_STATUS.COLLECTING_INVITE_RESPONSE ||
        item.status === EVENT_STATUS.DRAFT ||
        item.status === EVENT_STATUS.INVITES_SENT ||
        item.status === EVENT_STATUS.ON_HOLD ||
        item.status === EVENT_STATUS.SENDING_INVITES
    );

    const isDeleteEventActionAvailable = selectedItems.every(
      item => item.status === EVENT_STATUS.CANCELED || item.status === EVENT_STATUS.REJECTED
    );

    const actionItems = [
      {
        itemText: 'Cancel event',
        onItemClick: this.cancelEventModalOpen,
        isActive: selectedItems.length > 0 && isCancelEventActionAvailable
      },
      {
        itemText: 'Delete event',
        onItemClick: this.deleteEventModalOpen,
        isActive: selectedItems.length > 0 && isDeleteEventActionAvailable
      }
    ];

    const { user } = this.props;
    const gridTitle = typeof club !== 'undefined' ? `${club.name} / Events` : '';

    if (isLoading) {
      return <Loader />;
    }

    const classes = isErrorModalOpen ? 'mt-3 modal-open' : 'mt-3';

    return (
      <div className={classes}>
        {this.renderSendingError()}
        {this.renderCancelEventModal()}
        {this.renderDeleteEventModal()}
        <div className="row">
          <div className="col-md-12">
            <Grid2
              dataItems={items}
              filters={filters}
              currentPage={currentPage}
              total={total}
              isSelectAllChecked={isSelectAllChecked}
              isDataFiltered={isDataFiltered}
              sortDirection={sortDirection}
              sortColumnsName={sortColumnsName}
              isShowFilter={isShowFilter}
              dataItemsSelected={selectedItems}
              columns={COLUMNS}
              actionItems={actionItems}
              options={filterOptions}
              onItemClick={this.onItemClick}
              onSortClick={this.onTableSortClick}
              onApplyFilterClick={this.onApplyFilterClick}
              onClearFilterClick={this.onClearFilterClick}
              onTableFilterChange={this.onTableFilterChange}
              onTableFilterClick={this.onTableFilterClick}
              setCurrentPageParams={this.setCurrentPageParams}
              onSelectAllClick={this.onSelectAllClick}
              onSelectAllOnPageClick={this.onSelectAllOnPageClick}
              onUnselectAllClick={this.onUnselectAllClick}
              goBack={this.goBack}
              gridTitle={gridTitle}
              user={user}
            />
          </div>
        </div>
      </div>
    );
  }
}
