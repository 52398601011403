import { PHONE_CODE, REGION } from 'Src/consts/common';

export const getPhoneRegion = (phone: string) => {
  let region;

  const isPhoneExists = typeof phone !== 'undefined';
  if (!isPhoneExists) {
    return 'GB';
  }

  switch (true) {
    case phone.indexOf(PHONE_CODE.US) === 0:
      region = REGION.US;
      break;
    case phone.indexOf(PHONE_CODE.UK) === 0:
      region = REGION.UK;
      break;
    case phone.indexOf(PHONE_CODE.DE) === 0:
      region = REGION.DE;
      break;
    case phone.indexOf(PHONE_CODE.ES) === 0:
      region = REGION.ES;
      break;
    case phone.indexOf(PHONE_CODE.AE) === 0:
      region = REGION.AE;
      break;
    default:
      region = REGION.UK;
      break;
  }

  return region;
};
