import * as React from 'react';
import { Component } from 'react';
import { Tournament } from 'Src/models/tournament';
import {
  isFootballSubTypeTournament,
  isMiniTeamTournament,
  isRugbySubTypeTournament,
  isSailingSubTypeTournament,
  isSingleDoubleTournament
} from 'Src/helpers/tournament/tournament';
import { Switch } from 'Src/components/Switch/Switch';
import { TournamentAllGroupsForm } from './TournamentAllGroupsForm';
import { TournamentBadmintonGroupsForm } from './TournamentBadmintonGroupsForm';
import { TournamentRugbyGroupsForm } from './TournamentRugbyGroupsForm';
import { TournamentSailingGroupsForm } from './TournamentSailingGroupsForm';
import { TournamentFootballGroupsForm } from './TournamentFootballGroupsForm';

interface Props {
  onCancel: () => void;
  onSubmit: (data) => void;
  tournament: Tournament;
}

interface State {
  isShowAllGroups: boolean;
}

export class TournamentDefaultGroupsForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isShowAllGroups: false
    };
  }

  renderForm(): React.ReactNode {
    const { tournament, onCancel, onSubmit } = this.props;
    const { isShowAllGroups } = this.state;

    const isSingleDoubleOrMiniTeam = isSingleDoubleTournament(tournament) || isMiniTeamTournament(tournament);
    const isFootballSubType = isFootballSubTypeTournament(tournament);
    const isRugbySubType = isRugbySubTypeTournament(tournament);
    const isSailingSubType = isSailingSubTypeTournament(tournament);

    switch (true) {
      case isShowAllGroups:
        return <TournamentAllGroupsForm onCancel={onCancel} onSubmit={onSubmit} tournament={tournament} />;
      case isSingleDoubleOrMiniTeam:
        return <TournamentBadmintonGroupsForm onCancel={onCancel} onSubmit={onSubmit} tournament={tournament} />;
      case isFootballSubType:
        return <TournamentFootballGroupsForm onCancel={onCancel} onSubmit={onSubmit} tournament={tournament} />;
      case isRugbySubType:
        return <TournamentRugbyGroupsForm onCancel={onCancel} onSubmit={onSubmit} tournament={tournament} />;
      case isSailingSubType:
        return <TournamentSailingGroupsForm onCancel={onCancel} onSubmit={onSubmit} tournament={tournament} />;
      default:
        return <TournamentAllGroupsForm onCancel={onCancel} onSubmit={onSubmit} tournament={tournament} />;
    }
  }

  onShowAllGroupsChange = () => {
    this.setState({
      isShowAllGroups: !this.state.isShowAllGroups
    });
  };

  render() {
    const { isShowAllGroups } = this.state;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <Switch
              value={isShowAllGroups}
              onChange={this.onShowAllGroupsChange}
              text={'Show all groups'}
              name={'isShowAllGroups'}
            />
            {this.renderForm()}
          </div>
        </div>
      </div>
    );
  }
}
