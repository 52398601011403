import * as React from 'react';
import * as BPromise from 'bluebird';
import * as propz from 'propz';
import { AppUser } from 'Src/views/App/App';
import { parse } from 'query-string';
import { getOrder, getSearchFilter, getSearchOrder, getServerFieldSectionWhere } from 'Src/helpers/table/table';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { Loader } from 'Src/components/Loader/Loader';
import {
  ColumnDefinition,
  getFilters2,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { History, Location } from 'history';
import { Grid2 } from 'Src/components/Grid/Grid2';
import {
  getAllSchoolActivities,
  getSchoolActivitiesCount,
  getSchoolSport
} from '../../../../../helpers/service/admin/sports';
import { SimpleModal } from '../../../../../components/SimpleModal/SimpleModal';
import { Sport } from '../../../../../models/sport';
import { getFavoriteSports } from '../../../../../helpers/accessor/accessor';
import { getSchoolSports } from '../../../../../helpers/service/admin/user';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Activities/Sports',
    field: 'name',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['name']
  },
  {
    text: 'Description',
    field: 'description',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['description']
  },
  {
    text: 'Favourite sports',
    field: 'isFavorite',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getFavoriteSports
  }
];

interface State {
  items: Sport[];
  currentPage: number;
  selectedItems: Sport[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;
  isAddSportFavoritesPopupOpened: boolean;
  isRemoveSportFavoritesPopupOpened: boolean;
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export class Sports extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',
      isAddSportFavoritesPopupOpened: false,
      isRemoveSportFavoritesPopupOpened: false
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setItems().then(res => {
      this.setState({
        isLoading: false
      });
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems().then(res => {
        this.setState({
          isLoading: false
        });
      });
    }
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const getItemsPromise = getSchoolSports(user, serverQueryFilter);
    const getItemsCountPromise = getSchoolActivitiesCount(user, where);

    return BPromise.all([getItemsCountPromise, getItemsPromise]).then(([countObj, items]) => {
      this.setState({
        currentPage: page,
        sortDirection: sortDirection,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: items,
        total: countObj.count,
        basePath: history.location.pathname
      });

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: nextSelectedItems,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    getAllSchoolActivities(user, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const nextSelectedItems = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: nextSelectedItems,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: ''
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  onConfirmationSportFavouritesButtonClick = () => {
    this.setState({
      isAddSportFavoritesPopupOpened: false,
      isRemoveSportFavoritesPopupOpened: false
    });
    this.setItems();
  };

  onAddSportFavouritesClick = (): void => {
    const { selectedItems } = this.state;
    const { user } = this.props;

    this.setState({
      isLoading: true
    });
    const promises = selectedItems.map(sport => {
      const data = { isFavorite: true };
      return getSchoolSport(user, sport.id, data);
    });

    BPromise.all(promises).then(sportFavourites => {
      this.setState({
        isAddSportFavoritesPopupOpened: true,
        isLoading: false,
        selectedItems: []
      });
    });
  };

  renderConfirmationAddSportFavouritesModal() {
    const { isAddSportFavoritesPopupOpened } = this.state;

    return (
      <SimpleModal
        isOpen={isAddSportFavoritesPopupOpened}
        title={'Confirmation'}
        body={`The list of favourite activities/sports has been successfully updated.`}
        buttonText={'Ok'}
        onButtonClick={this.onConfirmationSportFavouritesButtonClick}
      />
    );
  }

  onRemoveSportFavouritesClick = (): void => {
    const { selectedItems } = this.state;
    const { user } = this.props;

    this.setState({
      isLoading: true
    });
    const promises = selectedItems.map(sport => {
      const data = { isFavorite: false };
      return getSchoolSport(user, sport.id, data);
    });

    BPromise.all(promises).then(sportFavourites => {
      this.setState({
        isRemoveSportFavoritesPopupOpened: true,
        isLoading: false,
        selectedItems: []
      });
    });
  };

  renderConfirmationRemoveSportFavouritesModal() {
    const { isRemoveSportFavoritesPopupOpened } = this.state;

    return (
      <SimpleModal
        isOpen={isRemoveSportFavoritesPopupOpened}
        title={'Confirmation'}
        body={`The list of favourite activities/sports has been successfully updated.`}
        buttonText={'Ok'}
        onButtonClick={this.onConfirmationSportFavouritesButtonClick}
      />
    );
  }

  renderSchoolSportsTable(): React.ReactNode {
    const { user } = this.props;
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isSelectAllChecked,
      currentPage,
      total
    } = this.state;

    const isFavoriteSportsEnabled = propz.get(user, ['activeSchool', 'isFavoriteSportsEnabled']);

    const actionItems = [
      {
        itemText: 'Add to favourites',
        onItemClick: this.onAddSportFavouritesClick,
        isActive: selectedItems.length > 0 && isFavoriteSportsEnabled && selectedItems.every(item => !item.isFavorite)
      },
      {
        itemText: 'Remove from favourites',
        onItemClick: this.onRemoveSportFavouritesClick,
        isActive: selectedItems.length > 0 && isFavoriteSportsEnabled && selectedItems.every(item => item.isFavorite)
      }
    ];

    const filterOptions = {};

    return (
      <div className="row">
        <div className="col-md-12">
          <Grid2
            dataItems={items}
            filters={filters}
            currentPage={currentPage}
            total={total}
            isSelectAllChecked={isSelectAllChecked}
            isDataFiltered={isDataFiltered}
            sortDirection={sortDirection}
            sortColumnsName={sortColumnsName}
            isShowFilter={isShowFilter}
            dataItemsSelected={selectedItems}
            columns={COLUMNS}
            actionItems={actionItems}
            options={filterOptions}
            onItemClick={this.onItemClick}
            onSortClick={this.onTableSortClick}
            onApplyFilterClick={this.onApplyFilterClick}
            onClearFilterClick={this.onClearFilterClick}
            onTableFilterChange={this.onTableFilterChange}
            onTableFilterClick={this.onTableFilterClick}
            setCurrentPageParams={this.setCurrentPageParams}
            onSelectAllClick={this.onSelectAllClick}
            onSelectAllOnPageClick={this.onSelectAllOnPageClick}
            onUnselectAllClick={this.onUnselectAllClick}
          />
        </div>
      </div>
    );
  }

  render() {
    const { isLoading, isRemoveSportFavoritesPopupOpened, isAddSportFavoritesPopupOpened } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes = isRemoveSportFavoritesPopupOpened || isAddSportFavoritesPopupOpened ? 'mt-3 modal-open' : 'mt-3';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderConfirmationAddSportFavouritesModal()}
        {this.renderConfirmationRemoveSportFavouritesModal()}
        <div className="row">
          <div className="col-md-12">{this.renderSchoolSportsTable()}</div>
        </div>
      </div>
    );
  }
}
