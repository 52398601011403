import * as React from 'react';
import { FunctionComponent } from 'react';
import * as propz from 'propz';
import { CONFIRMATION_REQUESTS_STATUS_SERVER_TO_CLIENT_MAPPING } from '../../consts/message';

interface Props {
  messages: any[];
}

export const TournamentConfirmationRequestsSummary: FunctionComponent<Props> = props => {
  const messages = props.messages;

  const rows = messages.map((message, index) => {
    const invitationStatus = propz.get(message, ['invitationStatus'], '');
    const participantSchoolName = propz.get(message, ['participantSchoolData', 'name'], '');
    const invitationStatusMapped = CONFIRMATION_REQUESTS_STATUS_SERVER_TO_CLIENT_MAPPING[invitationStatus];

    return (
      <tr key={`tournament_confirmation_requests_summary_${index}`}>
        <td>{index + 1}</td>
        <td>{participantSchoolName}</td>
        <td>{invitationStatusMapped}</td>
      </tr>
    );
  });

  return (
    <table className={'table table-sm mPre'}>
      <thead>
        <tr key={`tournament_confirmation_requests_summary_heading`}>
          <th />
          <th>Name</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
};

TournamentConfirmationRequestsSummary.displayName = 'TournamentConfirmationRequestsSummary';
