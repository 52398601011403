import * as React from 'react';
import { Component } from 'react';
import * as propz from 'propz';
import { AppUser } from 'Src/views/App/App';
import { UserNotificationChannel } from '../../../../models/userNotificationChannel';
import { USER_NOTIFICATION_CHANNEL_STATUSES, USER_NOTIFICATION_CHANNEL_TYPES } from '../../../../consts/notifications';
import {
  getNotificationsChannels,
  deleteNotificationChannel,
  createNotificationChannel
} from 'Src/helpers/service/admin/user';
import { Switch } from '../../../../components/Switch/Switch';
import { Loader } from '../../../../components/Loader/Loader';
import './NotificaionsTab.scss';

interface Props {
  user: AppUser;
}

interface State {
  isLoading: boolean;
  emailNotificationChannel: UserNotificationChannel;
  isEmailSwitchActive: boolean;
}

export class NotificaionsTab extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      emailNotificationChannel: undefined,
      isEmailSwitchActive: undefined
    };
  }

  componentDidMount() {
    const { user } = this.props;

    this.setState({
      isLoading: true
    });

    getNotificationsChannels(user).then(notificationChannels => {
      const emailNotificationChannel = notificationChannels.find(
        notificationChannel => notificationChannel.type === USER_NOTIFICATION_CHANNEL_TYPES.DEFAULT_EMAIL
      );
      const emailNotificationChannelStatus = propz.get(emailNotificationChannel, ['status']);
      const isEmailSwitchActive = emailNotificationChannelStatus === USER_NOTIFICATION_CHANNEL_STATUSES.ACTIVE;

      this.setState({
        isEmailSwitchActive,
        emailNotificationChannel,
        isLoading: false
      });
    });
  }

  onSubmit = () => {
    const { user } = this.props;
    const { isEmailSwitchActive, emailNotificationChannel } = this.state;
    const emailNotificationChannelStatus = propz.get(emailNotificationChannel, ['status']);
    const isEmailNotificationChannelActive =
      emailNotificationChannelStatus === USER_NOTIFICATION_CHANNEL_STATUSES.ACTIVE;

    if (isEmailNotificationChannelActive !== isEmailSwitchActive) {
      this.setState({
        isLoading: true
      });

      if (isEmailSwitchActive) {
        const data = { token: 'email', type: USER_NOTIFICATION_CHANNEL_TYPES.DEFAULT_EMAIL };
        createNotificationChannel(user, data).then(emailNotificationChannel => {
          this.setState({
            emailNotificationChannel,
            isLoading: false
          });
        });
      } else {
        deleteNotificationChannel(user, emailNotificationChannel.id).then(() => {
          this.setState({
            emailNotificationChannel: undefined,
            isLoading: false
          });
        });
      }
    }
  };

  onEmailSwitchChange = () => {
    this.setState(prevState => ({
      isEmailSwitchActive: !prevState.isEmailSwitchActive
    }));
  };

  render() {
    const { isEmailSwitchActive, isLoading } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    return (
      <div className="row">
        <div className="col-xl-8 ml-3">
          <div className="eNotificaionsTabTitle ml-2">{'NOTIFICATIONS SETTING'}</div>
          <div className="ml-2">
            <div> From here you can turn on / off email and text notification.</div>
            <div> Text notifications are only available if they have been set up on your schools account.</div>
            <div>
              {' '}
              If you have the Squad in Touch mobile app you can manage push notification preferences on your
              smartphone/tablet.
            </div>
          </div>
          <div className="mt-3 ml-2">
            <Switch
              value={isEmailSwitchActive}
              text={'Email notifications'}
              name={'notifications_tab_switch'}
              onChange={this.onEmailSwitchChange}
            />
          </div>
          <button className={'eNotificaionsTabButton'} onClick={this.onSubmit}>
            {'Save'}
          </button>
        </div>
      </div>
    );
  }
}
