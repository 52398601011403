import * as React from 'react';
import { FunctionComponent } from 'react';
import * as classNames from 'classnames';

interface Props {
  text: string;
  customClass?: string;
}

export const TextCell: FunctionComponent<Props> = props => {
  const { customClass } = props;
  const classes = classNames('mPreWrap', customClass);

  return <td className={classes}>{props.text}</td>;
};

TextCell.displayName = 'TextCell';
