import * as React from 'react';
import * as propz from 'propz';
import { AppUser } from 'Src/views/App/App';
import { History, Location } from 'history';
import { SchoolSummary } from './SchoolSummary/SchoolSummary';
import { Button } from 'Src/components/Button/Button';
import { Map } from 'Src/components/Map/Map';
import './SchoolSettings.scss';

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export const SchoolSettings: React.FunctionComponent<Props> = props => {
  const { history, user } = props;

  const point = propz.get(user, ['activeSchool', 'postcode', 'point']);
  const classes = 'mt-3';

  const onEditSettingsClick = () => {
    history.push({
      pathname: `/school/settings/base/edit`
    });
  };

  return (
    <div className={classes}>
      <div className="d-flex justify-content-between">
        <h2>School settings</h2>
        <Button text="Edit settings" onClick={onEditSettingsClick} />
      </div>

      <SchoolSummary user={user} />
      <Map point={point} customStylingClass="eSchoolMap" isMarkerDraggable={false} />
    </div>
  );
};
