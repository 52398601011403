import * as React from 'react';
import * as BPromise from 'bluebird';
import * as Lazy from 'lazy.js';
import * as propz from 'propz';
import { Component } from 'react';
import { History, Location } from 'history';
import { parse } from 'query-string';
import { TwoPanelEditor } from 'Src/components/TwoPanelEditor/TwoPanelEditor';
import { Loader } from 'Src/components/Loader/Loader';
import { DEFAULT_LIMIT, DEFAULT_SKIP, FILTER_TYPE } from 'Src/consts/table';
import { AGE_GROUPS } from 'Src/consts/school';
import { getTwoPanelEditorFilters } from 'Src/helpers/twoPanelEditor/twoPanelEditor';
import { DATE_INTERVAL, LIMIT } from 'Src/consts/table';
import {
  ColumnDefinition,
  getOrder,
  getServerFieldSectionWhere,
  isFilterExist2,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { Button } from 'Src/components/Button/Button';
import { getTournament } from 'Src/helpers/service/adminSU/tournamentsTable';
import { AppUser } from 'Src/views/App/App';
import {
  getAllSchoolTournamentTeamPlayers,
  getSchoolTournamentTeam,
  getSchoolTournamentTeamPlayersCount,
  SchoolTournamentTeamPlayersBatchData,
  updateSchoolTournamentTeamPlayersBatch
} from 'Src/helpers/service/admin/tournamentTeamPlayers';
import { SchoolForm } from 'Src/models/form';
import { getAllForms } from 'Src/helpers/service/admin/forms';
import { getSchoolStudents, getSchoolStudentsCount } from 'Src/helpers/service/admin/students';
import { getGender, getAge } from 'Src/helpers/accessor/accessor';
import { getSelectOptionForAge, getSelectOptionForForms, getSelectOptionForGender } from 'Src/helpers/table/select';
import { getUserGenderArrayConvertedFromEventGender } from 'Src/helpers/tournament/tournament';
import { TournamentTeamPlayer } from 'Src/models/tournamentTeamPlayer';
import { Count } from 'Src/models/count';
import { getEventClash, getEventTitle } from 'Src/helpers/event/event';
import { getSchoolStudentsList } from 'Src/helpers/service/adminSU/schoolTeamPlayers';
import { SchoolStudentClash, SchoolStudent } from 'Src/models/schoolStudent';
import { SchoolEventTeamPlayerWithClashes } from 'Src/models/eventTeamPlayer';
import { SchoolEvent } from 'Src/models/event';
import { getSchoolEvent } from 'Src/helpers/service/admin/event';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { Checkbox } from 'Src/components/Checkbox/Checkbox';

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}
interface State {
  items: SchoolStudent[];
  itemsSelected: SchoolStudent[];
  itemsCount: number;

  itemsFilters: any;
  isShowItemsFilter: boolean;
  isItemsFiltered: boolean;

  isSelectAllItemsChecked: boolean;

  sortItemsDirection: TABLE_SORT_DIRECTION;
  sortItemsColumnsName: string;

  itemCurrentPage: number;

  itemsAccumulatorInitial: TournamentTeamPlayer[];
  itemsAccumulator: TournamentTeamPlayer[];
  itemsAccumulatorSelected: TournamentTeamPlayer[];
  itemsAccumulatorCount: number;

  itemsAccumulatorFilters: any;
  isShowItemsAccumulatorFilter: boolean;
  isItemsAccumulatorFiltered: boolean;

  isSelectAllItemsAccumulatorChecked: boolean;

  sortItemsAccumulatorDirection: TABLE_SORT_DIRECTION;
  sortItemsAccumulatorColumnsName: string;

  tournament: any;
  team: any;
  forms: SchoolForm[];

  isLoading: boolean;

  itemsAddBuffer: SchoolStudent[];
  itemsRemoveBuffer: (SchoolStudent | TournamentTeamPlayer)[];

  studentEventsModalText: string;
  isStudentEventsModalOpen: boolean;

  isShowYounger: boolean;
}

const ITEMS_ACCUMULATOR_COLUMNS: ColumnDefinition[] = [
  {
    text: 'Name',
    field: 'firstName',
    accessor: ['firstName'],
    type: FILTER_TYPE.NONE,
    isSort: false
  },
  {
    text: 'Surname',
    field: 'lastName',
    accessor: ['lastName'],
    type: FILTER_TYPE.NONE,
    isSort: false
  },
  {
    text: 'School',
    field: 'schoolName',
    accessor: ['schoolName'],
    type: FILTER_TYPE.NONE,
    isSort: false
  },
  {
    text: 'Form',
    field: 'forms',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: ['form', 'name']
  },
  {
    text: 'Gender',
    field: 'gender',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getGender
  }
];

const ITEMS_COLUMNS: ColumnDefinition[] = [
  {
    text: 'Name',
    field: 'firstName',
    accessor: ['firstName'],
    type: FILTER_TYPE.TEXT,
    isSort: false
  },
  {
    text: 'Surname',
    field: 'lastName',
    accessor: ['lastName'],
    type: FILTER_TYPE.TEXT,
    isSort: false
  },
  {
    text: 'School',
    field: 'schoolName',
    accessor: ['schoolName'],
    type: FILTER_TYPE.NONE,
    isSort: false
  },
  {
    text: 'Age',
    field: 'ages',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getAge
  },
  {
    text: 'Gender',
    field: 'gender',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getGender
  }
];

const DEFAULT_TEAM_PLAYERS_ORDER = 'firstName ASC';

export class HighLevelTournamentTeamPlayersEdit extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      itemsCount: 0,
      itemsSelected: [],

      itemsFilters: {},
      isShowItemsFilter: false,
      isItemsFiltered: false,

      isSelectAllItemsChecked: false,

      sortItemsDirection: '',
      sortItemsColumnsName: '',

      itemCurrentPage: 1,

      itemsAccumulatorInitial: [],
      itemsAccumulator: [],
      itemsAccumulatorCount: 0,
      itemsAccumulatorSelected: [],

      itemsAccumulatorFilters: {},
      isShowItemsAccumulatorFilter: false,
      isItemsAccumulatorFiltered: false,

      isSelectAllItemsAccumulatorChecked: false,

      sortItemsAccumulatorDirection: '',
      sortItemsAccumulatorColumnsName: '',

      tournament: undefined,
      team: undefined,
      forms: [],

      isLoading: false,

      itemsAddBuffer: [],
      itemsRemoveBuffer: [],

      studentEventsModalText: '',
      isStudentEventsModalOpen: false,

      isShowYounger: false
    };
  }

  getTournamentId(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    const tournamentId = search.tournamentId;
    return tournamentId;
  }

  getTeamId(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    const teamId = search.teamId;
    return teamId;
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    const { user } = this.props;
    const tournamentId = this.getTournamentId();
    const teamId = this.getTeamId();

    const itemsFilters = getTwoPanelEditorFilters(ITEMS_COLUMNS);
    const isShowItemsFilter = isFilterExist2(itemsFilters);

    const itemsAccumulatorFilters = getTwoPanelEditorFilters(ITEMS_ACCUMULATOR_COLUMNS);
    const isShowItemsAccumulatorFilter = isFilterExist2(itemsAccumulatorFilters);
    const isItemsAccumulatorFiltered = isFilterExist2(itemsAccumulatorFilters);

    BPromise.all([
      getTournament(user, tournamentId),
      getSchoolTournamentTeam(user, tournamentId, teamId),
      getAllForms(user)
    ])
      .then(([_tournament, _team, _forms]) => {
        const defaultItemsFilter = this.getDefaultItemsFilter(_team, _forms);

        this.setState({
          tournament: _tournament,
          team: _team,
          forms: _forms,
          itemsFilters: defaultItemsFilter
        });

        return this.getItemsAndItemsAccumulator();
      })
      .then(({ teamPlayersInitial, teamPlayers, teamPlayersCount, students, studentsCount }) => {
        this.setState({
          items: students,
          itemsCount: studentsCount.length,
          isShowItemsFilter: isShowItemsFilter,
          isItemsFiltered: true,

          itemsAccumulatorInitial: teamPlayersInitial,
          itemsAccumulator: teamPlayers,
          itemsAccumulatorCount: teamPlayersCount.count,
          itemsAccumulatorFilters: itemsAccumulatorFilters,
          isShowItemsAccumulatorFilter: isShowItemsAccumulatorFilter,
          isItemsAccumulatorFiltered: isItemsAccumulatorFiltered,

          isLoading: false
        });
      });
  }

  getFormsFiltered(ages: number[], forms: SchoolForm[], isStrictEqual: boolean) {
    const maxAge = Math.max(...ages);
    const minAge = Math.min(...ages);

    switch (true) {
      case isStrictEqual && ages.length === 1:
        return forms.filter(form => form.age === minAge);
      case !isStrictEqual && ages.length === 1:
        return forms.filter(form => form.age <= maxAge);
      case isStrictEqual && ages.length > 1:
        return forms.filter(form => ages.some(age => form.age === age));
      case !isStrictEqual && ages.length > 1:
        return forms.filter(form => ages.some(age => form.age <= age));
    }
  }

  getDefaultItemsFilter(team, forms, isStrictEqual = true) {
    const { gender, ages = [] } = team;
    const genderArrayConverted = getUserGenderArrayConvertedFromEventGender(gender);

    const formsFiltered = this.getFormsFiltered(ages, forms, isStrictEqual);
    const formIds = formsFiltered.map(form => form.id);

    return {
      firstName: '',
      lastName: '',
      schoolName: '',
      gender: genderArrayConverted,
      forms: formIds
    };
  }

  getItemsAndItemsAccumulator() {
    const { user } = this.props;
    const { itemsAccumulatorFilters } = this.state;
    const tournamentId = this.getTournamentId();
    const teamId = this.getTeamId();

    const where = getServerFieldSectionWhere(itemsAccumulatorFilters);

    const getTeamPlayersInitialPromise = getAllSchoolTournamentTeamPlayers(user, tournamentId, teamId);
    const getTeamPlayersPromise = getAllSchoolTournamentTeamPlayers(user, tournamentId, teamId, where);
    const getTeamPlayersCountPromise = getSchoolTournamentTeamPlayersCount(user, tournamentId, teamId, where);

    let teamPlayersInitial: TournamentTeamPlayer[], teamPlayers: TournamentTeamPlayer[], teamPlayersCount: Count;

    return BPromise.all([getTeamPlayersInitialPromise, getTeamPlayersPromise, getTeamPlayersCountPromise])
      .then(([_teamPlayersInitial, _teamPlayers, _teamPlayersCount]) => {
        teamPlayersInitial = _teamPlayersInitial;
        teamPlayers = _teamPlayers;
        teamPlayersCount = _teamPlayersCount;

        const filter = this.getItemsFilter(1, teamPlayersInitial, []);
        const filterToGetAllStudents = this.getItemsFilter(1, teamPlayersInitial, [], true);

        const getStudentsPromise = getSchoolStudentsList(user, filter);
        const getSchoolStudentsCountPromise = getSchoolStudentsList(user, filterToGetAllStudents);

        return BPromise.all([getStudentsPromise, getSchoolStudentsCountPromise]);
      })
      .then(([students, studentsCount]) => {
        return {
          teamPlayersInitial,
          teamPlayers,
          teamPlayersCount,
          students,
          studentsCount
        };
      });
  }

  onItemClick = (index: number): void => {
    const { items, itemsSelected } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = itemsSelected.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...itemsSelected];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      itemsAccumulatorSelected: [],
      itemsSelected: selectedItemsUpdated
    });
  };

  onItemAccumulatorClick = (index: number): void => {
    const { itemsAccumulator, itemsAccumulatorSelected } = this.state;
    const selectedItem = itemsAccumulator[index];

    const selectedItemIndex = itemsAccumulatorSelected.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...itemsAccumulatorSelected];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      itemsAccumulatorSelected: selectedItemsUpdated,
      itemsSelected: []
    });
  };

  onAddClick = () => {
    const { itemsSelected, itemsAddBuffer, itemsAccumulatorInitial, itemCurrentPage } = this.state;
    const { user } = this.props;

    const nextItemsAddBuffer = [...itemsAddBuffer, ...itemsSelected];

    this.setState({
      isLoading: true
    });

    const filter = this.getItemsFilter(itemCurrentPage, itemsAccumulatorInitial, nextItemsAddBuffer);
    const filterToGetAllStudents = this.getItemsFilter(1, itemsAccumulatorInitial, [], true);

    const getStudentsPromise = getSchoolStudentsList(user, filter);
    const getStudentsCountPromise = getSchoolStudentsList(user, filterToGetAllStudents);

    BPromise.all([getStudentsPromise, getStudentsCountPromise]).then(([students, studentsCount]) => {
      this.setState({
        items: students,
        isLoading: false,
        itemsCount: studentsCount.length,
        itemsAddBuffer: nextItemsAddBuffer,
        itemsAccumulatorSelected: [],
        itemsSelected: [],
        isSelectAllItemsAccumulatorChecked: false,
        isSelectAllItemsChecked: false
      });
    });
  };

  onRemoveClick = () => {
    const {
      itemsAccumulatorSelected,
      itemsAddBuffer,
      itemsRemoveBuffer,
      itemsAccumulatorInitial,
      itemsAccumulator,
      itemCurrentPage
    } = this.state;
    const { user } = this.props;

    const nextItemsRemoveBuffer = [...itemsAccumulatorSelected, ...itemsRemoveBuffer];
    const nextItemsAccumulatorInitial = itemsAccumulatorInitial.filter(itemAccumulatorInitial =>
      nextItemsRemoveBuffer.every(nextItemRemoveBuffer => nextItemRemoveBuffer.id !== itemAccumulatorInitial.id)
    );
    const nextItemsAccumulator = itemsAccumulator.filter(itemAccumulator =>
      nextItemsRemoveBuffer.every(nextItemRemoveBuffer => nextItemRemoveBuffer.id !== itemAccumulator.id)
    );

    this.setState({
      isLoading: true
    });

    const filter = this.getItemsFilter(itemCurrentPage, nextItemsAccumulatorInitial, itemsAddBuffer);
    const filterToGetAllStudents = this.getItemsFilter(1, itemsAccumulatorInitial, [], true);

    const getStudentsPromise = getSchoolStudentsList(user, filter);
    const getStudentsCountPromise = getSchoolStudentsList(user, filterToGetAllStudents);

    BPromise.all([getStudentsPromise, getStudentsCountPromise]).then(([students, studentsCount]) => {
      this.setState({
        items: students,
        isLoading: false,
        itemsCount: studentsCount.length,
        itemsAccumulatorSelected: [],
        itemsSelected: [],
        isSelectAllItemsAccumulatorChecked: false,
        isSelectAllItemsChecked: false,
        itemsRemoveBuffer: nextItemsRemoveBuffer,
        itemsAccumulatorInitial: nextItemsAccumulatorInitial,
        itemsAccumulator: nextItemsAccumulator,
        itemsAccumulatorCount: nextItemsAccumulator.length
      });
    });
  };

  onClickSave = () => {
    const { itemsAddBuffer, itemsRemoveBuffer, team, forms } = this.state;
    const { user } = this.props;

    const tournamentId = this.getTournamentId();
    const teamId = this.getTeamId();

    const data: SchoolTournamentTeamPlayersBatchData = {
      add: itemsAddBuffer.map(item => ({ userId: item.id, permissionId: item.permissionId })),
      remove: itemsRemoveBuffer.map(item => item.id)
    };

    const defaultItemsFilter = this.getDefaultItemsFilter(team, forms);

    this.setState({
      isLoading: true,
      // set default values
      itemsSelected: [],
      itemsFilters: defaultItemsFilter,
      isShowItemsFilter: false,
      isItemsFiltered: true,
      isSelectAllItemsChecked: false,
      sortItemsDirection: '',
      sortItemsColumnsName: '',
      itemCurrentPage: 1,
      itemsAccumulatorSelected: [],
      itemsAccumulatorFilters: {},
      isShowItemsAccumulatorFilter: false,
      isItemsAccumulatorFiltered: false,
      isSelectAllItemsAccumulatorChecked: false,
      sortItemsAccumulatorDirection: '',
      sortItemsAccumulatorColumnsName: '',
      itemsAddBuffer: [],
      itemsRemoveBuffer: []
    });

    updateSchoolTournamentTeamPlayersBatch(user, tournamentId, teamId, data)
      .then(res => {
        return this.getItemsAndItemsAccumulator();
      })
      .then(({ teamPlayersInitial, teamPlayers, teamPlayersCount, students, studentsCount }) => {
        this.setState({
          //set items and items accumulator
          items: students,
          itemsCount: studentsCount.length,
          itemsAccumulator: teamPlayers,
          itemsAccumulatorInitial: teamPlayersInitial,
          itemsAccumulatorCount: teamPlayersCount.count,
          isLoading: false
        });
      });
  };

  onItemsFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.itemsFilters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (filterField === 'ages') {
      nextFilters.forms = [];
    }

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = ITEMS_COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      itemsFilters: nextFilters
    });
  };

  onItemsAccumulatorFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.itemsAccumulatorFilters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = ITEMS_COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      itemsAccumulatorFilters: nextFilters
    });
  };

  onItemsFilterClick = (event): void => {
    event.preventDefault();

    const { isShowItemsFilter } = this.state;

    this.setState({
      isShowItemsFilter: !isShowItemsFilter
    });
  };

  onItemsAccumulatorFilterClick = (event): void => {
    event.preventDefault();

    const { isShowItemsAccumulatorFilter } = this.state;

    this.setState({
      isShowItemsAccumulatorFilter: !isShowItemsAccumulatorFilter
    });
  };

  getItemsFilter(page, itemsAccumulator, itemsAddBuffer, isAllItems = false) {
    const { itemsFilters, forms } = this.state;

    const {
      gender: itemsFiltersGender,
      ages: itemsFiltersAges = [],
      firstName: itemsFiltersFirstName = '',
      lastName: itemsFiltersLastName = '',
      schoolName: itemsFiltersSchoolName = '',
      forms: itemsFiltersForms = []
    } = itemsFilters;

    let where = {
      gender: {
        $in: itemsFiltersGender
      }
    };

    const allItems = [...itemsAccumulator, ...itemsAddBuffer];
    const ninIds = Lazy(allItems)
      .map(item => item.userId)
      .uniq()
      .toArray();
    propz.set(where, ['_id', '$nin'], ninIds);

    if (itemsFiltersForms.length > 0) {
      propz.set(where, ['formId', '$in'], itemsFiltersForms);
    } else {
      const ages = itemsFiltersAges.length > 0 ? itemsFiltersAges : AGE_GROUPS.ENGLISH.map((age, index) => index);
      const formIds = forms.filter(form => ages.some(age => Number(form.age) === Number(age))).map(form => form.id);

      if (formIds.length > 0) {
        propz.set(where, ['formId', '$in'], formIds);
      }
    }

    let and = [];

    if (itemsFiltersFirstName.length > 0) {
      and.push({ firstName: { like: itemsFiltersFirstName, options: 'i' } });
    }

    if (itemsFiltersLastName.length > 0) {
      and.push({ lastName: { like: itemsFiltersLastName, options: 'i' } });
    }

    if (and.length > 0) {
      propz.set(where, ['$and'], and);
    }

    const filter = {
      where: where,
      limit: isAllItems ? DEFAULT_LIMIT : LIMIT,
      skip: isAllItems ? 0 : (page - 1) * LIMIT,
      order: DEFAULT_TEAM_PLAYERS_ORDER
    };

    return filter;
  }

  itemsSetCurrentPageParams = (currentPage: number): void => {
    const { user } = this.props;
    const { itemsAccumulator, sortItemsColumnsName, sortItemsDirection, itemsAddBuffer } = this.state;

    this.setState({
      isLoading: true
    });

    let orderForFilter = undefined;

    if (sortItemsColumnsName !== '' && sortItemsDirection !== '') {
      orderForFilter = `${sortItemsColumnsName} ${sortItemsDirection}`;
    }

    const filter = this.getItemsFilter(currentPage, itemsAccumulator, itemsAddBuffer, orderForFilter);

    getSchoolStudents(user, filter).then(students => {
      this.setState({
        itemCurrentPage: currentPage,
        items: students,
        isLoading: false
      });
    });
  };

  itemsOnApplyFilterClick = () => {
    const { itemsAccumulator, itemsAddBuffer } = this.state;

    this.setState({
      isLoading: true,
      isShowYounger: false
    });

    const filter = this.getItemsFilter(1, itemsAccumulator, itemsAddBuffer);
    const filterToGetAllStudents = this.getItemsFilter(1, itemsAccumulator, [], true);

    const { user } = this.props;

    const getStudentsPromise = getSchoolStudentsList(user, filter);
    const getStudentCountPromise = getSchoolStudentsList(user, filterToGetAllStudents);

    BPromise.all([getStudentsPromise, getStudentCountPromise]).then(([students, studentsCount]) => {
      this.setState({
        items: students,
        isLoading: false,
        itemsCount: studentsCount.length,
        itemsAccumulatorSelected: [],
        itemsSelected: [],
        itemCurrentPage: 1,
        sortItemsDirection: '',
        sortItemsColumnsName: ''
      });
    });
  };

  itemsAccumulatorOnApplyFilterClick = () => {};

  itemsOnClearFilterClick = () => {
    const { itemsAccumulatorInitial, itemsAddBuffer, team, forms, isShowYounger } = this.state;

    const defaultItemsFilter = this.getDefaultItemsFilter(team, forms);

    const { ages } = team;

    this.setState({
      isLoading: true,
      itemsFilters: defaultItemsFilter,
      isShowYounger: false
    });

    const { gender: itemsFiltersGender } = defaultItemsFilter;

    let where = {
      gender: {
        $in: itemsFiltersGender
      }
    };

    const allItems = [...itemsAccumulatorInitial, ...itemsAddBuffer];
    const ninIds = Lazy(allItems)
      .map(item => item.userId)
      .uniq()
      .toArray();
    propz.set(where, ['_id', '$nin'], ninIds);

    const formsFiltered = this.getFormsFiltered(ages, forms, true);
    const formIds = formsFiltered.map(form => form.id);
    propz.set(where, ['formId', '$in'], formIds);

    const filter = {
      where: where,
      limit: LIMIT,
      skip: DEFAULT_SKIP,
      order: DEFAULT_TEAM_PLAYERS_ORDER
    };

    const filterToGetAllStudents = {
      where: where,
      limit: DEFAULT_LIMIT,
      skip: DEFAULT_SKIP,
      order: DEFAULT_TEAM_PLAYERS_ORDER
    };

    const { user } = this.props;

    const getStudentsPromise = getSchoolStudentsList(user, filter);
    const getSchoolsCountPromise = getSchoolStudentsList(user, filterToGetAllStudents);

    BPromise.all([getStudentsPromise, getSchoolsCountPromise]).then(([students, studentsCount]) => {
      this.setState({
        items: students,
        isLoading: false,
        itemsCount: studentsCount.length,
        itemsAccumulatorSelected: [],
        itemsSelected: [],
        itemCurrentPage: 1,
        sortItemsColumnsName: '',
        sortItemsDirection: '',
        isItemsFiltered: true,
        isShowItemsFilter: false
      });
    });
  };

  itemsAccumulatorOnClearFilterClick = () => {};

  goBack = () => {
    const { location } = this.props;
    const { state } = location;
    const { prevPath } = state as any;
    const tournamentId = this.getTournamentId();

    this.props.history.push({
      pathname: '/highLevelTournaments/teams',
      search: `tournamentId=${tournamentId}`,
      state: { tournamentId, prevPath }
    });
  };

  onSelectAllItemsClick = () => {
    const { itemsSelected, itemCurrentPage, itemsAccumulatorInitial, itemsAddBuffer } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;
    const nextItemsAddBuffer = [...itemsAddBuffer, ...itemsSelected];
    const filter = this.getItemsFilter(itemCurrentPage, itemsAccumulatorInitial, nextItemsAddBuffer, true);

    getSchoolStudentsList(user, filter).then(items => {
      const itemsFiltered = items.filter(item => {
        return itemsSelected.every(selectedItem => selectedItem.id !== item.id);
      });

      const nextSelectedItems = [...itemsSelected, ...itemsFiltered];

      this.setState({
        itemsSelected: nextSelectedItems,
        isSelectAllItemsChecked: true,
        itemsAccumulatorSelected: [],
        isSelectAllItemsAccumulatorChecked: false,
        isLoading: false
      });
    });
  };

  onSelectAllItemsOnPageClick = () => {
    const { items, itemsSelected } = this.state;

    const itemsFiltered = items.filter(item => {
      return itemsSelected.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...itemsSelected, ...itemsFiltered];

    this.setState({
      itemsSelected: nextSelectedItems,
      isSelectAllItemsChecked: true,
      itemsAccumulatorSelected: [],
      isSelectAllItemsAccumulatorChecked: false
    });
  };

  onUnselectAllItemsClick = () => {
    this.setState({
      itemsSelected: [],
      isSelectAllItemsChecked: false,
      itemsAccumulatorSelected: [],
      isSelectAllItemsAccumulatorChecked: false
    });
  };

  onSelectAllItemsAccumulatorClick = () => {
    const { itemsAccumulatorSelected, itemsAccumulatorFilters } = this.state;

    this.setState({
      isLoading: true
    });

    const { user } = this.props;
    const tournamentId = this.getTournamentId();
    const teamId = this.getTeamId();
    const where = getServerFieldSectionWhere(itemsAccumulatorFilters);

    getAllSchoolTournamentTeamPlayers(user, tournamentId, teamId, where).then(teamPlayers => {
      const teamPlayersFiltered = teamPlayers.filter(teamPlayer => {
        return itemsAccumulatorSelected.every(selectedItem => selectedItem.id !== teamPlayer.id);
      });

      const nextSelectedItems = [...itemsAccumulatorSelected, ...teamPlayersFiltered];

      this.setState({
        itemsAccumulatorSelected: nextSelectedItems,
        isSelectAllItemsAccumulatorChecked: true,
        itemsSelected: [],
        isSelectAllItemsChecked: false,
        isLoading: false
      });
    });
  };

  onSelectAllItemsAccumulatorOnPageClick = () => {
    const { itemsAccumulator, itemsAccumulatorSelected } = this.state;

    const itemsFiltered = itemsAccumulator.filter(item => {
      return itemsAccumulatorSelected.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...itemsAccumulatorSelected, ...itemsFiltered];

    this.setState({
      itemsAccumulatorSelected: nextSelectedItems,
      isSelectAllItemsAccumulatorChecked: true,
      itemsSelected: [],
      isSelectAllItemsChecked: false
    });
  };

  onUnselectAllItemsAccumulatorClick = () => {
    this.setState({
      itemsAccumulatorSelected: [],
      isSelectAllItemsAccumulatorChecked: false,
      itemsSelected: [],
      isSelectAllItemsChecked: false
    });
  };

  onSortItemsClick = (sortField: string) => {
    const { sortItemsDirection, sortItemsColumnsName, itemCurrentPage, itemsAccumulator, itemsAddBuffer } = this.state;

    const order = getOrder(sortField, sortItemsDirection, sortItemsColumnsName) as TABLE_SORT_DIRECTION;
    const orderForFilter = `${sortField} ${order}`;

    const filter = this.getItemsFilter(itemCurrentPage, itemsAccumulator, itemsAddBuffer);
    const { user } = this.props;
    const getSchoolStudentsPromise = getSchoolStudents(user, filter);
    const getSchoolStudentsCountPromise = getSchoolStudentsCount(user, filter.where);

    const promiseItems = [getSchoolStudentsPromise, getSchoolStudentsCountPromise];

    BPromise.all(promiseItems).then(([students, studentsCount]) => {
      this.setState({
        items: students,
        isLoading: false,
        itemsCount: studentsCount.count,
        itemsAccumulatorSelected: [],
        itemsSelected: [],
        itemCurrentPage: 1,
        sortItemsColumnsName: sortField,
        sortItemsDirection: order
      });
    });
  };

  onSortItemsAccumulatorClick = (sortField: string) => {};

  onCloseStudentEventsModalClick = () => {
    this.setState({
      isStudentEventsModalOpen: false
    });
  };

  renderStudentEventsModal(): React.ReactNode {
    const { isStudentEventsModalOpen, studentEventsModalText } = this.state;
    return (
      <SimpleModal
        isOpen={isStudentEventsModalOpen}
        title={'Info'}
        body={studentEventsModalText}
        buttonText={'Ok'}
        onButtonClick={this.onCloseStudentEventsModalClick}
      />
    );
  }

  onShowYoungerChange = () => {
    const { user } = this.props;
    const { team, forms, isShowYounger, itemsAccumulatorInitial, itemsAddBuffer } = this.state;

    const defaultItemsFilter = this.getDefaultItemsFilter(team, forms, isShowYounger);

    this.setState({
      isLoading: true,
      itemsFilters: defaultItemsFilter,
      isShowYounger: !isShowYounger
    });

    const { gender: itemsFiltersGender, forms: itemsFiltersForms = [] } = defaultItemsFilter;

    let where = {
      gender: {
        $in: itemsFiltersGender
      }
    };

    const allItems = [...itemsAccumulatorInitial, ...itemsAddBuffer];

    const ninIds = Lazy(allItems)
      .map(item => item.userId)
      .uniq()
      .toArray();

    propz.set(where, ['_id', '$nin'], ninIds);

    propz.set(where, ['formId', '$in'], itemsFiltersForms);

    const filter = {
      where: where,
      limit: LIMIT,
      skip: DEFAULT_SKIP,
      order: DEFAULT_TEAM_PLAYERS_ORDER
    };

    const filterToGetAllStudents = {
      where: where,
      limit: DEFAULT_LIMIT,
      skip: DEFAULT_SKIP,
      order: DEFAULT_TEAM_PLAYERS_ORDER
    };

    const getStudentsPromise = getSchoolStudentsList(user, filter);
    const getSchoolsCountPromise = getSchoolStudentsList(user, filterToGetAllStudents);

    BPromise.all([getStudentsPromise, getSchoolsCountPromise]).then(([students, studentsCount]) => {
      this.setState({
        items: students,
        isLoading: false,
        itemsCount: studentsCount.length,
        itemsAccumulatorSelected: [],
        itemsSelected: [],
        itemCurrentPage: 1,
        sortItemsColumnsName: '',
        sortItemsDirection: '',
        isItemsFiltered: true,
        isShowItemsFilter: false
      });
    });
  };

  render() {
    const {
      items,
      isLoading,
      itemsSelected,
      itemsAccumulatorSelected,
      itemsAccumulator,
      itemsFilters,
      isShowItemsFilter,
      isItemsFiltered,
      itemsCount,
      itemsAccumulatorCount,
      itemCurrentPage,
      itemsAccumulatorFilters,
      isShowItemsAccumulatorFilter,
      isItemsAccumulatorFiltered,
      tournament,
      isSelectAllItemsChecked,
      isSelectAllItemsAccumulatorChecked,
      sortItemsDirection,
      sortItemsColumnsName,
      sortItemsAccumulatorDirection,
      sortItemsAccumulatorColumnsName,
      itemsAddBuffer,
      itemsRemoveBuffer,
      isStudentEventsModalOpen,
      forms,
      isShowYounger
    } = this.state;

    const name = propz.get(tournament, ['name'], '');

    if (isLoading) {
      return <Loader />;
    }

    const { user } = this.props;
    const { activeSchool } = user;
    const { ageGroupsNaming } = activeSchool;

    const itemsFiltersOptions = {
      ages: getSelectOptionForAge(forms, ageGroupsNaming),
      gender: getSelectOptionForGender()
    };

    const itemsAccumulatorFiltersOptions = {};

    const classes = isStudentEventsModalOpen ? 'mt-3 modal-open' : 'mt-3';

    return (
      <div className={classes}>
        {this.renderStudentEventsModal()}
        <div className="row">
          <div className="col-md-12">
            <Button text={'← back'} onClick={this.goBack} customClass={'btn-secondary mr-3 mb-3'} />
            <div className="d-inline h1 mb-3">{name}</div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className={'mb-3 mt-3'}>
                <Checkbox
                  value={isShowYounger}
                  id={'isShowYounger'}
                  onChange={this.onShowYoungerChange}
                  labelText={'Show younger'}
                />
              </div>
            </div>
          </div>
        </div>
        <TwoPanelEditor
          //items
          items={items}
          itemsTitle={'Eligible students'}
          itemsColumns={ITEMS_COLUMNS}
          itemsSelected={itemsSelected}
          onItemClick={this.onItemClick}
          //items filters
          onItemsFilterChange={this.onItemsFilterChange}
          itemsFilters={itemsFilters}
          itemsFiltersOptions={itemsFiltersOptions}
          isShowItemsFilter={isShowItemsFilter}
          isItemsFiltered={isItemsFiltered}
          onItemsFilterClick={this.onItemsFilterClick}
          //accumulator
          itemsAccumulator={itemsAccumulator}
          itemsAccumulatorTitle={'Team members'}
          itemsAccumulatorColumns={ITEMS_ACCUMULATOR_COLUMNS}
          onItemAccumulatorClick={this.onItemAccumulatorClick}
          itemsAccumulatorSelected={itemsAccumulatorSelected}
          //accumulator filters
          onItemsAccumulatorFilterChange={this.onItemsAccumulatorFilterChange}
          itemsAccumulatorFilters={itemsAccumulatorFilters}
          itemsAccumulatorFiltersOptions={itemsAccumulatorFiltersOptions}
          isShowItemsAccumulatorFilter={isShowItemsAccumulatorFilter}
          isItemsAccumulatorFiltered={isItemsAccumulatorFiltered}
          onItemsAccumulatorFilterClick={this.onItemsAccumulatorFilterClick}
          //buttons
          onAddClick={this.onAddClick}
          onRemoveClick={this.onRemoveClick}
          //counts
          itemsCount={itemsCount}
          itemsAccumulatorCount={itemsAccumulatorCount}
          //current pages
          itemAccumulatorCurrentPage={1}
          itemCurrentPage={itemCurrentPage}
          itemsSetCurrentPageParams={this.itemsSetCurrentPageParams}
          //items filter buttons
          itemsOnApplyFilterClick={this.itemsOnApplyFilterClick}
          itemsOnClearFilterClick={this.itemsOnClearFilterClick}
          //items accumulator filter buttons
          itemsAccumulatorOnApplyFilterClick={this.itemsAccumulatorOnApplyFilterClick}
          itemsAccumulatorOnClearFilterClick={this.itemsAccumulatorOnClearFilterClick}
          //items select checkbox
          onSelectAllItemsClick={this.onSelectAllItemsClick}
          onSelectAllItemsOnPageClick={this.onSelectAllItemsOnPageClick}
          onUnselectAllItemsClick={this.onUnselectAllItemsClick}
          isSelectAllItemsChecked={isSelectAllItemsChecked}
          //items accumulator select checkbox
          onSelectAllItemsAccumulatorClick={this.onSelectAllItemsAccumulatorClick}
          onSelectAllItemsAccumulatorOnPageClick={this.onSelectAllItemsAccumulatorOnPageClick}
          onUnselectAllItemsAccumulatorClick={this.onUnselectAllItemsAccumulatorClick}
          isSelectAllItemsAccumulatorChecked={isSelectAllItemsAccumulatorChecked}
          //items sort
          sortItemsDirection={sortItemsDirection}
          sortItemsColumnsName={sortItemsColumnsName}
          onSortItemsClick={this.onSortItemsClick}
          //items accumulator sort
          sortItemsAccumulatorDirection={sortItemsAccumulatorDirection}
          sortItemsAccumulatorColumnsName={sortItemsAccumulatorColumnsName}
          onSortItemsAccumulatorClick={this.onSortItemsAccumulatorClick}
          itemsAddBuffer={itemsAddBuffer}
          itemsRemoveBuffer={itemsRemoveBuffer}
          onCellItemsClick={() => {}}
          onCellItemsAccumulatorClick={() => {}}
        />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <Button text={'Save'} onClick={this.onClickSave} customClass={'btn-lg mt-3'} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
