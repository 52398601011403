import * as React from 'react';
import * as BPromise from 'bluebird';
import * as Lazy from 'lazy.js';
import { AppUser } from 'Src/views/App/App';
import * as propz from 'propz';
import * as Moment from 'moment';
import { parse } from 'query-string';
import { getOrder, getSearchFilter, getSearchOrder, getServerFieldSectionWhere } from 'Src/helpers/table/table';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE, DEFAULT_LIMIT, DEFAULT_SKIP } from 'Src/consts/table';
import { Loader } from 'Src/components/Loader/Loader';
import {
  ColumnDefinition,
  getFilters2,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { History, Location } from 'history';
import { Grid2 } from 'Src/components/Grid/Grid2';
import {
  getBirthday,
  getGender,
  getStudentTags,
  getStudentHouses,
  getStudentSpecialEducationNeeds,
  getStudentPupilsPremium,
  getStudentFreeSchoolMeals
} from 'Src/helpers/accessor/accessor';
import { SchoolStudent } from 'Src/models/schoolStudent';
import { Tag } from 'Src/models/tag';
import { getAllForms } from 'Src/helpers/service/admin/forms';
import { getAllUserTags } from 'Src/helpers/service/admin/userTags';
import { SchoolForm } from 'Src/models/form';
import {
  assignUserTagByStudentId,
  deleteUserTagByStudentId,
  getAllSchoolStudents,
  getSchoolStudents,
  getSchoolStudentsCount,
  updateSchoolStudent
} from 'Src/helpers/service/admin/students';
import {
  getSelectOptionForHouses,
  getSelectOptionForAge,
  getSelectOptionForForms,
  getSelectOptionForGender,
  getSelectOptionForTag,
  getSelectOptionForBooleanQuestion
} from 'Src/helpers/table/select';
import { ResizablePanel } from 'Src/components/ResizablePanel/ResizablePanel';
import { STUDENT_TABS } from 'Src/consts/school';
import { GenericSummary } from 'Src/components/GenericSummary/GenericSummary';
import { Button } from 'Src/components/Button/Button';
import { StudentSummary } from 'Src/components/StudentSummary/StudentSummary';
import { UserTagDeleteModal } from 'Src/components/UserTagDeleteModal/UserTagDeleteModal';
import { UserTagAssignModal } from 'Src/components/UserTagAssignModal/UserTagAssignModal';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { GeneralMessageWizzard } from 'Src/components/GeneralMessageWizzard/GeneralMessageWizzard';
import { getSettingsUploadFiles } from 'Src/helpers/service/nobody/settings';
import { getStudentsParents } from 'Src/helpers/accessor/accessor';
import { StudentNextOfKin } from '../../../../../components/StudentNextOfKin/StudentNextOfKin';
import { StudentNextOfKinForm } from '../../../../../components/StudentNextOfKin/StudentNextOfKinForm';
import { addSchoolStudent, deleteStudent } from '../../../../../helpers/service/admin/students';
import { StudentForm } from '../../../../../components/StudentForm/StudentForm';
import { getAllHouses } from 'Src/helpers/service/admin/houses';
import { getFullName } from 'Src/helpers/autocomplete/autocomplete';
import { uploadReport } from 'Src/helpers/service/admin/report';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/report';
import { StudentParticipationInEventsReportForm } from './Reports/StudentParticipationInEventsReportForm/StudentParticipationInEventsReportForm';
import { StudentParticipationStatisticsByActivitiesReportForm } from './Reports/StudentParticipationStatisticsByActivitiesReportForm/StudentParticipationStatisticsByActivitiesReportForm';
import { ClubListForStudentReportForm } from './Reports/ClubListForStudentReportForm/ClubListForStudentReportForm';
import { SelectedStudentTargetCompletionReportForm } from './Reports/SelectedStudentTargetCompletionReportForm/SelectedStudentTargetCompletionReportForm';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Name',
    field: 'firstName',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['firstName']
  },
  {
    text: 'Surname',
    field: 'lastName',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['lastName']
  },
  {
    text: 'Form',
    field: 'forms',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: ['form', 'name']
  },
  {
    text: 'Age',
    field: 'ages',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: ['form', 'ageGroup']
  },
  {
    text: 'House',
    field: 'studentHouseName',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getStudentHouses
  },
  {
    text: 'Gender',
    field: 'gender',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getGender
  },
  {
    text: 'Birthday',
    field: 'birthday',
    isSort: true,
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: getBirthday
  },
  {
    text: 'SEN',
    field: 'specialEducationNeeds',
    isSort: false,
    type: FILTER_TYPE.SELECT,
    accessor: getStudentSpecialEducationNeeds
  },
  {
    text: 'PP',
    field: 'pupilsPremium',
    isSort: false,
    type: FILTER_TYPE.SELECT,
    accessor: getStudentPupilsPremium
  },
  {
    text: 'FSM',
    field: 'freeSchoolMeals',
    isSort: false,
    type: FILTER_TYPE.SELECT,
    accessor: getStudentFreeSchoolMeals
  },
  {
    text: 'Tag',
    field: 'tag',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getStudentTags
  },
  {
    text: 'Parents',
    field: 'parents',
    isSort: false,
    type: FILTER_TYPE.TEXT,
    accessor: getStudentsParents
  }
];

interface State {
  items: SchoolStudent[];
  currentPage: number;
  selectedItems: SchoolStudent[];
  similarStudents: SchoolStudent[];
  identicalStudents: SchoolStudent[];
  studentToAdd: SchoolStudent;
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;

  isMessageModalOpen: boolean;

  isTagAssignModalOpen: boolean;
  isTagDeleteModalOpen: boolean;
  selectedTagId: string;

  currentTabIndex: number;
  isAddNextOfKinModalOpen: boolean;
  personIndex: number;
  isEditStudent: boolean;
  isEditStudentModalOpen: boolean;
  isCreateStudentsChain: boolean;
  isDeleteStudentModalOpen: boolean;

  isStudentParticipationInEventsReportModalOpen: boolean; //015
  isStudentParticipationStatisticsByActivitiesReportModalOpen: boolean; //071
  isClubListForStudentReportModalOpen: boolean; //019
  isSelectedStudentTargetCompletionReportModalOpen: boolean; //056

  errorMessage: string;
  isErrorModalOpen: boolean;

  tags: Tag[];
  forms: SchoolForm[];
  houses: any[];
  maxSizeUploadFile: number;
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export class Students extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      similarStudents: [],
      identicalStudents: [],
      studentToAdd: undefined,
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',

      isMessageModalOpen: false,

      isTagAssignModalOpen: false,
      isTagDeleteModalOpen: false,
      selectedTagId: '',

      currentTabIndex: 0,
      isEditStudent: false,
      isEditStudentModalOpen: false,
      isCreateStudentsChain: false,
      isDeleteStudentModalOpen: false,

      isStudentParticipationInEventsReportModalOpen: false,
      isStudentParticipationStatisticsByActivitiesReportModalOpen: false,
      isClubListForStudentReportModalOpen: false,
      isSelectedStudentTargetCompletionReportModalOpen: false,

      errorMessage: '',
      isErrorModalOpen: false,

      tags: [],
      forms: [],
      houses: [],
      maxSizeUploadFile: 0,
      isAddNextOfKinModalOpen: false,
      personIndex: -1
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setAdditionalItems().then(res => {
      this.setItems();
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  setAdditionalItems() {
    const { user } = this.props;

    return BPromise.all([
      getAllHouses(user),
      getAllForms(user),
      getAllUserTags(user),
      getSettingsUploadFiles(user)
    ]).then(([houses, forms, tags, maxSizeUploadFile]) => {
      this.setState({
        houses,
        forms: forms,
        tags: tags,
        maxSizeUploadFile: maxSizeUploadFile
      });

      return true;
    });
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const options = {
      forms: this.state.forms
    };

    const where = getServerFieldSectionWhere(filters, options);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const getItemsPromise = getSchoolStudents(user, serverQueryFilter);
    const getItemsCountPromise = getSchoolStudentsCount(user, where);

    const promises = [getItemsCountPromise, getItemsPromise];

    return BPromise.all(promises).then(([countObj, items]) => {
      this.setState({
        currentPage: page,
        sortDirection: sortDirection,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: items,
        total: countObj.count,
        basePath: history.location.pathname,
        isLoading: false
      });

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: nextSelectedItems,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters, forms } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const options = {
      forms: forms
    };

    const where = getServerFieldSectionWhere(filters, options);

    getAllSchoolStudents(user, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const nextSelectedItems = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: nextSelectedItems,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (filterField === 'forms') {
      nextFilters.ages = [];
    }

    if (filterField === 'ages') {
      nextFilters.forms = [];
    }

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      isSelectAllChecked: false,
      selectedItems: []
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: ''
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  stylizeCell = cell => {
    const itemWithoutForm = propz.get(cell, ['formId'], '');
    const isHighliteRow = itemWithoutForm === '';

    return isHighliteRow ? 'text-danger' : '';
  };

  onSubmitReportForm = (data, reportType) => {
    const { user } = this.props;
    const { activeSchoolId, activeSchool } = user;
    const { selectedItems, forms } = this.state;
    const student = selectedItems[0];
    const studentId = propz.get(student, ['id'], '');

    const {
      dateTo,
      dateFrom,
      isShowSportEvents,
      isShowClubEvents,
      isShowTournamentEvents,
      isShowCompleted,
      isShowUncompleted,
      isPrintFormName,
      isPrintAgeGroup,
      isPrintHouse,
      isPrintPP,
      isPrintSEN,
      isPrintFSM,
      isPrintAttendance,
      isGroupBySports,
      isGroupByEventTypes,
      isShowZero,
      isShowNonZero
    } = data;

    let modData;
    let formsUniq;
    let ages: number[];

    switch (reportType) {
      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.STUDENT_PARTICIPATION_IN_EVENTS:
        this.setState({
          isStudentParticipationInEventsReportModalOpen: false,
          isLoading: true
        });

        dateFrom.setHours(0, 0, 0, 0);
        dateTo.setHours(23, 59, 59, 0);

        modData = {
          reportParamsCSV: {
            schoolId: activeSchoolId,
            studentId: selectedItems.map(item => item.id),
            dateTo: Moment(dateTo).format('YYYY-MM-DD HH:mm:ss'),
            dateFrom: Moment(dateFrom).format('YYYY-MM-DD HH:mm:ss'),
            printPP: isPrintPP,
            printSEN: isPrintSEN,
            printFSM: isPrintFSM,
            printAttendance: isPrintAttendance,
            showSportEvents: isShowSportEvents,
            showClubEvents: isShowClubEvents,
            showTournamentEvents: isShowTournamentEvents
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.STUDENT_PARTICIPATION_STATISTICS_BY_ACTIVITIES:
        this.setState({
          isStudentParticipationStatisticsByActivitiesReportModalOpen: false,
          isLoading: true
        });

        dateFrom.setHours(0, 0, 0, 0);
        dateTo.setHours(23, 59, 59, 0);

        modData = {
          reportParamsCSV: {
            schoolId: activeSchoolId,
            studentId: selectedItems.map(item => item.id),
            dateTo: Moment(dateTo).format('YYYY-MM-DD HH:mm:ss'),
            dateFrom: Moment(dateFrom).format('YYYY-MM-DD HH:mm:ss'),
            printPP: isPrintPP,
            printSEN: isPrintSEN,
            printFSM: isPrintFSM,
            showClubEvents: isShowClubEvents,
            groupBySports: isGroupBySports,
            groupByEventTypes: isGroupByEventTypes,
            showZero: isShowZero,
            showNonZero: isShowNonZero
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.CLUB_LIST_FOR_STUDENT:
        this.setState({
          isClubListForStudentReportModalOpen: false,
          isLoading: true
        });

        dateFrom.setHours(0, 0, 0, 0);
        dateTo.setHours(23, 59, 59, 0);

        modData = {
          reportParamsCSV: {
            schoolId: activeSchoolId,
            studentId: selectedItems.map(item => item.id),
            dateTo: Moment(dateTo).format('YYYY-MM-DD HH:mm:ss'),
            dateFrom: Moment(dateFrom).format('YYYY-MM-DD HH:mm:ss'),
            printFormName: isPrintFormName,
            printAgeGroup: isPrintAgeGroup,
            printHouse: isPrintHouse,
            printPP: isPrintPP,
            printSEN: isPrintSEN,
            printFSM: isPrintFSM,
            printAttendance: isPrintAttendance
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.SELECTED_STUDENT_TARGET_COMPLETION:
        this.setState({
          isSelectedStudentTargetCompletionReportModalOpen: false,
          isLoading: true
        });

        dateFrom.setHours(0, 0, 0, 0);
        dateTo.setHours(23, 59, 59, 0);

        modData = {
          reportParamsCSV: {
            schoolId: activeSchoolId,
            studentId: selectedItems.map(item => item.id),
            dateTo: Moment(dateTo).format('YYYY-MM-DD HH:mm:ss'),
            dateFrom: Moment(dateFrom).format('YYYY-MM-DD HH:mm:ss'),
            showCompleted: isShowCompleted,
            showUncompleted: isShowUncompleted
          }
        };
        break;
    }

    uploadReport(user, reportType, modData)
      .then(data => {
        if (typeof data.reportId !== 'undefined') {
          const { reportType } = data;

          this.setState({
            isLoading: false
          });

          const url = `${window.apiBase}/public/report/${data.reportId}/reportType/${reportType}`;
          window.open(url);
        } else {
          this.setState({
            isLoading: false,
            errorMessage: 'No read report',
            isErrorModalOpen: true
          });
        }
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        console.error(error);

        this.setState({
          isLoading: false,
          errorMessage: errorText,
          isErrorModalOpen: true
        });
      });
  };

  openStudentParticipationInEventsReportForm = () => {
    this.setState({
      isStudentParticipationInEventsReportModalOpen: true
    });
  };

  closeStudentParticipationInEventsReportForm = () => {
    this.setState({
      isStudentParticipationInEventsReportModalOpen: false
    });
  };

  openStudentParticipationStatisticsByActivitiesReportForm = () => {
    this.setState({
      isStudentParticipationStatisticsByActivitiesReportModalOpen: true
    });
  };

  closeStudentParticipationStatisticsByActivitiesReportForm = () => {
    this.setState({
      isStudentParticipationStatisticsByActivitiesReportModalOpen: false
    });
  };

  openClubListForStudentReportForm = () => {
    this.setState({
      isClubListForStudentReportModalOpen: true
    });
  };

  closeClubListForStudentReportForm = () => {
    this.setState({
      isClubListForStudentReportModalOpen: false
    });
  };

  openSelectedStudentTargetCompletionReportForm = () => {
    this.setState({
      isSelectedStudentTargetCompletionReportModalOpen: true
    });
  };

  closeSelectedStudentTargetCompletionReportForm = () => {
    this.setState({
      isStudentParticipationInEventsReportModalOpen: false
    });
  };

  renderStudentParticipationInEventsReportFormModal() {
    const { isStudentParticipationInEventsReportModalOpen, selectedItems } = this.state;
    const student = selectedItems[0];
    const { user } = this.props;
    const activeSchoolId = user && user.activeSchool ? user.activeSchool.id : null;
    return (
      <SimpleModal isOpen={isStudentParticipationInEventsReportModalOpen}>
        <StudentParticipationInEventsReportForm
          onCloseClick={this.closeStudentParticipationInEventsReportForm}
          onSubmit={this.onSubmitReportForm}
          student={selectedItems}
          activeSchoolId={activeSchoolId}
        />
      </SimpleModal>
    );
  }

  renderStudentParticipationStatisticsByActivitiesReportFormModal() {
    const { isStudentParticipationStatisticsByActivitiesReportModalOpen, selectedItems } = this.state;
    const student = selectedItems[0];
    const { user } = this.props;
    const activeSchoolId = user && user.activeSchool ? user.activeSchool.id : null;
    return (
      <SimpleModal isOpen={isStudentParticipationStatisticsByActivitiesReportModalOpen}>
        <StudentParticipationStatisticsByActivitiesReportForm
          onCloseClick={this.closeStudentParticipationStatisticsByActivitiesReportForm}
          onSubmit={this.onSubmitReportForm}
          student={selectedItems}
          activeSchoolId={activeSchoolId}
        />
      </SimpleModal>
    );
  }

  renderClubListForStudentReportFormModal() {
    const { isClubListForStudentReportModalOpen, selectedItems } = this.state;
    const student = selectedItems[0];
    const { user } = this.props;
    const activeSchoolId = user && user.activeSchool ? user.activeSchool.id : null;
    return (
      <SimpleModal isOpen={isClubListForStudentReportModalOpen}>
        <ClubListForStudentReportForm
          onCloseClick={this.closeClubListForStudentReportForm}
          onSubmit={this.onSubmitReportForm}
          student={selectedItems}
          activeSchoolId={activeSchoolId}
        />
      </SimpleModal>
    );
  }

  renderSelectedStudentTargetCompletionReportFormModal() {
    const { isSelectedStudentTargetCompletionReportModalOpen, selectedItems } = this.state;
    const student = selectedItems[0];
    return (
      <SimpleModal isOpen={isSelectedStudentTargetCompletionReportModalOpen}>
        <SelectedStudentTargetCompletionReportForm
          onCloseClick={this.closeSelectedStudentTargetCompletionReportForm}
          onSubmit={this.onSubmitReportForm}
        />
      </SimpleModal>
    );
  }

  renderStudentsTable(): React.ReactNode {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isSelectAllChecked,
      currentPage,
      houses,
      total,
      tags,
      forms
    } = this.state;

    const actionItems = [
      {
        itemText: 'Create message',
        onItemClick: this.onSendMessageClick,
        isActive: selectedItems.length !== 0
      },
      {
        itemText: 'Assign tag',
        onItemClick: this.onTagAssignClick,
        isActive: selectedItems.length !== 0
      },
      {
        itemText: 'Delete tag',
        onItemClick: this.onTagDeleteClick,
        isActive: selectedItems.length !== 0
      },
      {
        itemText: 'Add student',
        onItemClick: this.onStudentAddButtonClick,
        isActive: selectedItems.length === 0
      },
      {
        itemText: 'Delete student(s)',
        onItemClick: this.openDeleteStudentModal,
        isActive: selectedItems.length !== 0
      },
      {
        itemText: 'Reports',
        isActive: true,
        subItems: [
          {
            itemText: 'Student participation in events',
            onItemClick: this.openStudentParticipationInEventsReportForm,
            isActive: selectedItems.length > 0
          },
          {
            itemText: 'Student participation statistics by sports/activities',
            onItemClick: this.openStudentParticipationStatisticsByActivitiesReportForm,
            isActive: selectedItems.length > 0
          },
          {
            itemText: 'Club list for student',
            onItemClick: this.openClubListForStudentReportForm,
            isActive: selectedItems.length > 0
          },
          {
            itemText: 'Target Completion for the Period',
            onItemClick: this.openSelectedStudentTargetCompletionReportForm,
            isActive: selectedItems.length > 0
          }
        ]
      }
    ];

    const user = this.props.user;
    const activeSchool = user.activeSchool;
    const ageGroupsNaming = activeSchool.ageGroupsNaming;

    const filterOptions = {
      studentHouseName: getSelectOptionForHouses(houses),
      ages: getSelectOptionForAge(forms, ageGroupsNaming),
      gender: getSelectOptionForGender(),
      tag: getSelectOptionForTag(tags),
      forms: getSelectOptionForForms(forms),
      specialEducationNeeds: getSelectOptionForBooleanQuestion(),
      pupilsPremium: getSelectOptionForBooleanQuestion(),
      freeSchoolMeals: getSelectOptionForBooleanQuestion()
    };

    return (
      <Grid2
        dataItems={items}
        filters={filters}
        currentPage={currentPage}
        total={total}
        isSelectAllChecked={isSelectAllChecked}
        isDataFiltered={isDataFiltered}
        sortDirection={sortDirection}
        sortColumnsName={sortColumnsName}
        isShowFilter={isShowFilter}
        dataItemsSelected={selectedItems}
        columns={COLUMNS}
        actionItems={actionItems}
        options={filterOptions}
        onItemClick={this.onItemClick}
        onSortClick={this.onTableSortClick}
        onApplyFilterClick={this.onApplyFilterClick}
        onClearFilterClick={this.onClearFilterClick}
        onTableFilterChange={this.onTableFilterChange}
        onTableFilterClick={this.onTableFilterClick}
        stylizeCell={this.stylizeCell}
        setCurrentPageParams={this.setCurrentPageParams}
        onSelectAllClick={this.onSelectAllClick}
        onSelectAllOnPageClick={this.onSelectAllOnPageClick}
        onUnselectAllClick={this.onUnselectAllClick}
      />
    );
  }

  onTagAssignClick = (): void => {
    this.setState({
      isTagAssignModalOpen: true
    });
  };

  onTagDeleteClick = (): void => {
    this.setState({
      isTagDeleteModalOpen: true
    });
  };

  onTagAssignModalCloseClick = (): void => {
    this.setState({
      isTagAssignModalOpen: false
    });
  };

  onSendMessageClick = (): void => {
    this.setState({
      isMessageModalOpen: true
    });
  };

  renderSummary(): React.ReactNode {
    const isOneStudentSelected = this.isOneStudentSelected();

    if (!isOneStudentSelected) {
      return <div className="font-weight-bold">Select a student above</div>;
    }

    const { currentTabIndex } = this.state;

    const studentSummaryTabs = this.getStudentSummaryTabs();

    const isShowEditButton = currentTabIndex === 0;
    const isShowNextOfKinButton = currentTabIndex === 1;

    return (
      <GenericSummary onTabClick={this.onTabClick} tabs={STUDENT_TABS} currentTabIndex={currentTabIndex}>
        {isShowNextOfKinButton && (
          <Button onClick={this.onAddNextOfKinClick} text={'Add next of kin'} customClass={'mt-3 mb-3'} />
        )}
        {isShowEditButton && (
          <Button
            onClick={() => {
              this.setState({
                isEditStudent: true,
                isEditStudentModalOpen: true
              });
            }}
            text={'Edit'}
            customClass={'mt-3 mb-3'}
          />
        )}
        {studentSummaryTabs}
      </GenericSummary>
    );
  }

  onStudentAddButtonClick = (): void => {
    this.setState({
      isEditStudentModalOpen: true
    });
  };

  onTabClick = (event, index: number) => {
    event.preventDefault();

    this.setState({
      currentTabIndex: index
    });
  };

  onAddNextOfKinClick = (): void => {
    this.setState({
      isAddNextOfKinModalOpen: true
    });
  };

  onNextOfKinCancelButtonClick = (): void => {
    this.setState({
      isAddNextOfKinModalOpen: false,
      personIndex: -1
    });
  };

  renderAddNextOfKinModal(): React.ReactNode {
    const { isAddNextOfKinModalOpen, personIndex, selectedItems } = this.state;
    const user = this.props.user;
    const student = { ...selectedItems[0] };
    const nextOfKin = [...propz.get(student, ['nextOfKin'], [])];

    if (personIndex !== -1) {
      const person = nextOfKin[personIndex];

      return (
        <SimpleModal isOpen={isAddNextOfKinModalOpen} title={'Edit next of kin'}>
          <StudentNextOfKinForm
            onSubmit={this.onNextOfKinSaveButtonClick}
            onCancel={this.onNextOfKinCancelButtonClick}
            user={user}
            person={person}
          />
        </SimpleModal>
      );
    } else {
      return (
        <SimpleModal isOpen={isAddNextOfKinModalOpen} title={'Add next of kin'}>
          <StudentNextOfKinForm
            onSubmit={this.onNextOfKinSaveButtonClick}
            onCancel={this.onNextOfKinCancelButtonClick}
            user={user}
          />
        </SimpleModal>
      );
    }
  }

  onDeleteStudentsClick = (): void => {
    const { user } = this.props;
    const { selectedItems } = this.state;

    this.setState({
      isLoading: true
    });

    BPromise.all(selectedItems.map(selectedItem => deleteStudent(user, selectedItem.id))).then(() => {
      this.setState({
        isDeleteStudentModalOpen: false,
        selectedItems: []
      });

      this.setItems();
    });
  };

  renderDeleteStudentModal = (): React.ReactNode => {
    const { isDeleteStudentModalOpen, selectedItems } = this.state;

    const selectedStudents = selectedItems.map(student => getFullName(student)).join(', ');

    return (
      <SimpleModal
        isOpen={isDeleteStudentModalOpen}
        title="Confirm the action"
        buttonText="Apply"
        body={`You are going to remove ${selectedStudents} students from your school account. Are you sure?`}
        onCloseClick={this.closeDeleteStudentModal}
        onButtonClick={this.onDeleteStudentsClick}
      />
    );
  };

  openDeleteStudentModal = (): void => {
    this.setState({
      isDeleteStudentModalOpen: true
    });
  };

  closeDeleteStudentModal = (): void => {
    this.setState({
      isDeleteStudentModalOpen: false
    });
  };

  onNextOfKinSaveButtonClick = (data): void => {
    const { user } = this.props;
    const { personIndex, selectedItems } = this.state;
    const student = { ...selectedItems[0] };
    const nextOfKin = [...propz.get(student, ['nextOfKin'], [])];

    const { priority, phones, personEmail, ...rest } = data;
    const currentPhones = data.phones.filter(phone => phone !== '');

    const dataCopy = {
      ...rest,
      priority: typeof data.priority !== 'undefined' ? Number(data.priority) : undefined,
      phones: currentPhones,
      email: personEmail
    };

    if (personIndex !== -1) {
      student.nextOfKin[personIndex] = dataCopy;
    } else {
      nextOfKin.push(dataCopy);
      student.nextOfKin = nextOfKin;
    }

    this.setState({
      isLoading: true
    });

    updateSchoolStudent(user, student.id, student).then(student => {
      this.setState({
        isAddNextOfKinModalOpen: false,
        selectedItems: [student],
        personIndex: -1
      });
      this.setItems();
    });
  };

  onEditNextOfKinClick = (index): void => {
    this.setState({
      isAddNextOfKinModalOpen: true,
      personIndex: index
    });
  };

  onNextOfKinRemoveButtonClick = (index: number): void => {
    const { user } = this.props;
    const { selectedItems } = this.state;
    const student = { ...selectedItems[0] };
    const newNextOfKin = [...propz.get(student, ['nextOfKin'], [])];

    newNextOfKin.splice(index, 1);
    student.nextOfKin = newNextOfKin;

    this.setState({
      isLoading: true
    });

    updateSchoolStudent(user, student.id, student).then(student => {
      this.setState({
        selectedItems: [student]
      });
      this.setItems();
    });
  };

  getStudentSummaryTabs(): React.ReactNode {
    const { currentTabIndex, selectedItems } = this.state;

    switch (currentTabIndex) {
      case 0:
        return <StudentSummary student={selectedItems[0]} />;
      case 1:
        return (
          <StudentNextOfKin
            student={selectedItems[0]}
            onEditClick={this.onEditNextOfKinClick}
            onRemoveClick={this.onNextOfKinRemoveButtonClick}
          />
        );
      default:
        console.error('Students.tsx \n Wrong tab index ' + currentTabIndex);
        return null;
    }
  }

  isOneStudentSelected() {
    const selectedStudents = this.state.selectedItems;
    return selectedStudents.length === 1;
  }

  getStudentTags(): any[] {
    const { selectedItems } = this.state;

    return Lazy(selectedItems)
      .map(selectedItem => selectedItem.tags || [])
      .filter(tagList => tagList.length > 0)
      .flatten()
      .uniq('id')
      .toArray();
  }

  onTagChange = (event): void => {
    const value = event.target.value;

    this.setState({
      selectedTagId: value
    });
  };

  onTagDeleteModalCloseClick = (): void => {
    this.setState({
      isTagDeleteModalOpen: false
    });
  };

  onTagDelete = (): void => {
    const studentTagId = this.state.selectedTagId;
    const user = this.props.user;

    this.setState({
      isLoading: true
    });

    if (!studentTagId) {
      this.setState({
        isTagDeleteModalOpen: false,
        selectedTagId: '',
        isLoading: false
      });
      console.error('Can not assign user tag id');
    } else {
      const selectedItems = this.state.selectedItems;

      BPromise.all(
        selectedItems.map(selectedItem => {
          const userId = selectedItem.id;
          return deleteUserTagByStudentId(user, userId, studentTagId);
        })
      ).then(res => {
        //replace deleted tag
        selectedItems.forEach(item => {
          const tagIndex = item.tags.findIndex(tag => tag.id === studentTagId);

          item.tags.splice(tagIndex, 1);
        });

        this.setState({
          isTagDeleteModalOpen: false,
          selectedTagId: '',
          selectedItems: selectedItems
        });

        this.setItems();
      });
    }
  };

  onUserTagSaveClick = (): void => {
    const tagId = this.state.selectedTagId;
    const user = this.props.user;

    const data = {
      userTagId: tagId
    };

    this.setState({
      isLoading: true
    });

    if (!tagId) {
      this.setState({
        isTagAssignModalOpen: false,
        selectedTagId: '',
        isLoading: false
      });
      console.error('Can not assign student tag id');
    } else {
      const selectedItems = this.state.selectedItems;
      BPromise.all(
        selectedItems.map(selectedItem => {
          const userId = selectedItem.id;
          return assignUserTagByStudentId(user, data, userId);
        })
      ).then(items => {
        this.setState({
          isTagAssignModalOpen: false,
          selectedTagId: '',
          selectedItems: items
        });
        this.setItems();
      });
    }
  };

  renderTagModals(): React.ReactNode {
    const { tags, selectedTagId, isTagAssignModalOpen, isTagDeleteModalOpen } = this.state;

    return (
      <>
        <UserTagDeleteModal
          modalTitle={'Delete Student Tag'}
          userTags={this.getStudentTags()}
          selectedUserTagId={selectedTagId}
          onUserTagChange={this.onTagChange}
          onCloseClick={this.onTagDeleteModalCloseClick}
          isOpen={isTagDeleteModalOpen}
          onDeleteClick={this.onTagDelete}
        />
        <UserTagAssignModal
          modalTitle={'Assign Student Tag'}
          userTags={tags}
          selectedUserTagId={selectedTagId}
          onUserTagChange={this.onTagChange}
          onCloseClick={this.onTagAssignModalCloseClick}
          isOpen={isTagAssignModalOpen}
          onSaveClick={this.onUserTagSaveClick}
        />
      </>
    );
  }

  onStudentSaveButtonClick = (data: any, isContinue: boolean, isSavingSimilarStudentConfirmed) => {
    const { user } = this.props;
    const { selectedItems, isCreateStudentsChain, isEditStudent } = this.state;
    const selectedItemsCount = selectedItems.length;
    const { firstName: studentToAddFirstName, lastName: studentToAddLastName, birthday: studentToAddBirthday } = data;

    this.setState({
      isLoading: true,
      identicalStudents: [],
      similarStudents: []
    });

    if (data.formId === '') {
      delete data.formId;
    }

    if (data.houseId === '') {
      delete data.houseId;
    }

    const filterForCheckExistingStudentWithoutBirthday = {
      limit: DEFAULT_LIMIT,
      skip: DEFAULT_SKIP,
      where: {
        firstName: {
          like: studentToAddFirstName.trim(),
          options: 'i'
        },
        lastName: {
          like: studentToAddLastName.trim(),
          options: 'i'
        }
      }
    };

    const filterForCheckExistingStudentWithoutFirstName = {
      limit: DEFAULT_LIMIT,
      skip: DEFAULT_SKIP,
      where: {
        lastName: {
          like: studentToAddLastName.trim(),
          options: 'i'
        },
        birthday: {
          $gte: new Date(studentToAddBirthday),
          $lte: new Date(studentToAddBirthday)
        }
      }
    };

    const filterForCheckExistingStudentWithoutLastName = {
      limit: DEFAULT_LIMIT,
      skip: DEFAULT_SKIP,
      where: {
        firstName: {
          like: studentToAddFirstName.trim(),
          options: 'i'
        },
        birthday: {
          $gte: new Date(studentToAddBirthday),
          $lte: new Date(studentToAddBirthday)
        }
      }
    };

    const isStudentToAddWithoutBirthday = typeof studentToAddBirthday === 'undefined';

    let promises = [];

    if (isStudentToAddWithoutBirthday) {
      promises = [getSchoolStudents(user, filterForCheckExistingStudentWithoutBirthday)];
    } else {
      promises = [
        getSchoolStudents(user, filterForCheckExistingStudentWithoutBirthday),
        getSchoolStudents(user, filterForCheckExistingStudentWithoutFirstName),
        getSchoolStudents(user, filterForCheckExistingStudentWithoutLastName)
      ];
    }

    return BPromise.all(promises).then(response => {
      const allFoundSimilarStudents = response.reduce((result, item) => [...result, ...item], []);

      const allFoundSimilarStudentsUniq = Lazy(allFoundSimilarStudents)
        .uniq('id')
        .toArray();

      const identicalStudents = allFoundSimilarStudentsUniq.filter(similarStudent => {
        const {
          firstName: similarStudentFirstName,
          lastName: similarStudentLastName,
          birthday: similarStudentBirthday
        } = similarStudent;

        const similarStudentFirstNameFormatted = similarStudentFirstName.toLowerCase().trim();
        const similarStudentLastNameFormatted = similarStudentLastName.toLowerCase().trim();

        const studentToAddFirstNameFormatted = studentToAddFirstName.toLowerCase().trim();
        const studentToAddLastNameFormatted = studentToAddLastName.toLowerCase().trim();

        return (
          similarStudentFirstNameFormatted === studentToAddFirstNameFormatted &&
          similarStudentLastNameFormatted === studentToAddLastNameFormatted &&
          similarStudentBirthday === studentToAddBirthday
        );
      });

      const similarStudents = allFoundSimilarStudentsUniq.filter(similarStudent => {
        const {
          firstName: similarStudentFirstName,
          lastName: similarStudentLastName,
          birthday: similarStudentBirthday
        } = similarStudent;

        const similarStudentFirstNameFormatted = similarStudentFirstName.toLowerCase().trim();
        const similarStudentLastNameFormatted = similarStudentLastName.toLowerCase().trim();

        const studentToAddFirstNameFormatted = studentToAddFirstName.toLowerCase().trim();
        const studentToAddLastNameFormatted = studentToAddLastName.toLowerCase().trim();

        const isStudentToAddWithoutBirthday = typeof studentToAddBirthday === 'undefined';

        if (isStudentToAddWithoutBirthday) {
          return (
            similarStudentFirstNameFormatted === studentToAddFirstNameFormatted &&
            similarStudentLastNameFormatted === studentToAddLastNameFormatted
          );
        }

        return (
          (similarStudentFirstNameFormatted === studentToAddFirstNameFormatted &&
            similarStudentLastNameFormatted === studentToAddLastNameFormatted &&
            similarStudentBirthday !== studentToAddBirthday) ||
          (similarStudentFirstNameFormatted === studentToAddFirstNameFormatted &&
            similarStudentBirthday === studentToAddBirthday &&
            similarStudentLastNameFormatted !== studentToAddLastNameFormatted) ||
          (similarStudentLastNameFormatted === studentToAddLastNameFormatted &&
            similarStudentBirthday === studentToAddBirthday &&
            similarStudentFirstNameFormatted !== studentToAddFirstNameFormatted)
        );
      });

      const isIdenticalStudentsExist = identicalStudents.length > 0;

      if (
        (isSavingSimilarStudentConfirmed && !isIdenticalStudentsExist) ||
        allFoundSimilarStudentsUniq.length === 0 ||
        isEditStudent
      ) {
        switch (true) {
          case selectedItemsCount === 0 || isCreateStudentsChain:
            return addSchoolStudent(user, data).then(student => {
              this.setState(prevState => ({
                isEditStudentModalOpen: isContinue,
                selectedItems: isContinue ? [student, ...prevState.selectedItems] : [student],
                isCreateStudentsChain: isContinue,
                similarStudents: [],
                identicalStudents: [],
                studentToAdd: undefined
              }));

              return this.setItems();
            });

          case selectedItemsCount === 1:
            const studentId = selectedItems[0].id;
            const nextOfKin = propz.get(selectedItems[0], ['nextOfKin'], []);
            const dataUpdated = { ...data, nextOfKin };
            return updateSchoolStudent(user, studentId, dataUpdated).then(studentUpdated => {
              this.setState({
                isEditStudentModalOpen: false,
                isEditStudent: false,
                selectedItems: [studentUpdated],
                isCreateStudentsChain: false,
                similarStudents: [],
                identicalStudents: [],
                studentToAdd: undefined
              });

              return this.setItems();
            });

          default:
            return BPromise.resolve(true);
        }
      } else {
        this.setState({
          identicalStudents,
          similarStudents,
          studentToAdd: data,
          isLoading: false
        });
      }
    });
  };

  onStudentCancelEditButtonClick = (): void => {
    this.setState({
      isEditStudentModalOpen: false,
      isEditStudent: false,
      isCreateStudentsChain: false,
      similarStudents: [],
      identicalStudents: [],
      studentToAdd: undefined
    });
  };

  renderStudentFormModal(): React.ReactNode {
    const {
      isEditStudentModalOpen,
      selectedItems,
      isCreateStudentsChain,
      identicalStudents,
      similarStudents,
      studentToAdd,
      forms,
      isEditStudent
    } = this.state;
    const { user } = this.props;
    const selectedItemsCount = selectedItems.length;
    const isSelectedItemExist = selectedItemsCount > 0;
    const isFormsExist = forms.length > 0;

    let modalTitle = selectedItemsCount === 0 || isCreateStudentsChain ? 'Add student' : 'Edit student';

    return (
      <SimpleModal isOpen={isEditStudentModalOpen} title={modalTitle}>
        <StudentForm
          student={isSelectedItemExist ? selectedItems[0] : studentToAdd}
          onSubmit={this.onStudentSaveButtonClick}
          onCancel={this.onStudentCancelEditButtonClick}
          user={user}
          isCreateStudentsChain={isCreateStudentsChain}
          identicalStudents={identicalStudents}
          similarStudents={similarStudents}
          isFormsExist={isFormsExist}
          isEditStudent={isEditStudent}
        />
      </SimpleModal>
    );
  }

  onMessageSubmitClick = message => {
    const { history } = this.props;

    history.push({
      pathname: '/generalMessages',
      state: { message: message }
    });
  };

  onCloseMessageWizardClick = (): void => {
    this.setState({
      isMessageModalOpen: false
    });
  };

  renderGMWizard(): React.ReactNode {
    const { user } = this.props;
    const { isMessageModalOpen, selectedItems, maxSizeUploadFile } = this.state;

    if (!isMessageModalOpen) {
      return null;
    }

    return (
      <GeneralMessageWizzard
        user={user}
        onMessageSubmit={this.onMessageSubmitClick}
        onCancelClick={this.onCloseMessageWizardClick}
        sendToStudents={true}
        sendToParents={true}
        sendToStaff={false}
        isOpen={isMessageModalOpen}
        students={selectedItems}
        maxSizeUploadFile={maxSizeUploadFile}
      />
    );
  }

  render() {
    const {
      isLoading,
      isMessageModalOpen,
      isEditStudentModalOpen,
      isAddNextOfKinModalOpen,
      isStudentParticipationInEventsReportModalOpen,
      isStudentParticipationStatisticsByActivitiesReportModalOpen,
      isClubListForStudentReportModalOpen,
      isSelectedStudentTargetCompletionReportModalOpen,
      isErrorModalOpen
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes =
      isMessageModalOpen ||
      isEditStudentModalOpen ||
      isErrorModalOpen ||
      isStudentParticipationInEventsReportModalOpen ||
      isStudentParticipationStatisticsByActivitiesReportModalOpen ||
      isClubListForStudentReportModalOpen ||
      isSelectedStudentTargetCompletionReportModalOpen ||
      isAddNextOfKinModalOpen
        ? 'mt-3 modal-open'
        : 'mt-3';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderGMWizard()}
        {this.renderTagModals()}
        {this.renderStudentFormModal()}
        {this.renderStudentParticipationInEventsReportFormModal()}
        {this.renderStudentParticipationStatisticsByActivitiesReportFormModal()}
        {this.renderClubListForStudentReportFormModal()}
        {this.renderSelectedStudentTargetCompletionReportFormModal()}
        {this.renderDeleteStudentModal()}
        <div className="row">
          <div className="col-md-12">
            <ResizablePanel>
              {this.renderStudentsTable()}
              {this.renderSummary()}
              {this.renderAddNextOfKinModal()}
            </ResizablePanel>
          </div>
        </div>
      </div>
    );
  }
}
