import * as React from 'react';
import { Field, Form, Formik } from 'formik';
import { Button } from '../Button/Button';
import { Autocomplete } from '../Autocomplete/Autocomplete';
import { getFullName, searchFunctionMergeableUsers } from 'Src/helpers/autocomplete/autocomplete';
import { AppUser } from 'Src/views/App/App';
import { LabelWithQuestionIcon } from '../LabelWithQuestionIcon/LabelWithQuestionIcon';
import {
  getName,
  searchFunctionSchoolFormsPublic,
  searchFunctionSchoolHousesPublic
} from '../../helpers/autocomplete/autocomplete';
import { ROLE } from '../../consts/user';

interface Props {
  onCloseClick: () => void;
  onAcceptClick: (values) => void;
  request: any;
  user: AppUser;
}

interface State {
  formId: string;
  houseId: string;
}

export class AcceptAndMergeRequestForm extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      formId: '',
      houseId: ''
    };
  }

  getUser = (text: string, setFieldValue) => {
    const { user } = this.props;
    const { formId } = this.state;
    const { houseId } = this.state;
    if (text === '') {
      setFieldValue('userId', '');
      setFieldValue('user', undefined);
    }
    return searchFunctionMergeableUsers(user, text, this.props.request.requestedPermission, formId, houseId);
  };

  getSchoolForms = (text: string, setFieldValue) => {
    if (text === '') {
      setFieldValue('formId', '');
      setFieldValue('form', undefined);
      this.setState({
        formId: ''
      });
    }
    const { activeSchoolId } = this.props.user;
    return searchFunctionSchoolFormsPublic(text, activeSchoolId);
  };

  getSchoolHouses = (text: string, setFieldValue) => {
    if (text === '') {
      setFieldValue('houseId', '');
      setFieldValue('house', undefined);
      this.setState({
        houseId: ''
      });
    }

    const { activeSchoolId } = this.props.user;
    return searchFunctionSchoolHousesPublic(text, activeSchoolId);
  };

  render() {
    const { firstName, lastName } = this.props.request.requester;
    const isStudent = this.props.request.requestedPermission.preset === ROLE.STUDENT;

    const userForm = {
      user: undefined,
      userId: '',
      form: undefined,
      formId: this.state.formId,
      house: undefined,
      houseId: this.state.houseId
    };
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <Formik
              initialValues={userForm}
              onSubmit={values => {
                this.props.onAcceptClick(values);
              }}
              render={({ values, setFieldValue }) => (
                <Form>
                  <div className="form-group form-check">
                    <div className="form-group text-center">
                      <label>
                        Choose the existing user to merge with the request for
                        <br />
                        the role on behalf of
                        <br />
                        {`${firstName} ${lastName}`}
                      </label>
                      {isStudent && (
                        <div className="d-flex">
                          <div className="mr-3">
                            <LabelWithQuestionIcon labelText="Form" hintText={''} />
                            <Field
                              name="form"
                              render={({ field }) => {
                                return (
                                  <Autocomplete
                                    searchFunction={text => this.getSchoolForms(text, setFieldValue)}
                                    getElementTitle={getName}
                                    customClass="mFullWidth mb-3"
                                    defaultItem={values.form}
                                    onSelect={form => {
                                      setFieldValue('formId', form.id);
                                      setFieldValue('form', form);

                                      this.setState({
                                        formId: form.id
                                      });
                                    }}
                                  />
                                );
                              }}
                            />
                          </div>
                          <div>
                            <LabelWithQuestionIcon labelText="House" hintText={''} />
                            <Field
                              name="house"
                              render={({ field }) => {
                                return (
                                  <Autocomplete
                                    searchFunction={text => this.getSchoolHouses(text, setFieldValue)}
                                    getElementTitle={getName}
                                    customClass="mFullWidth mb-3"
                                    defaultItem={values.house}
                                    onSelect={house => {
                                      setFieldValue('houseId', house.id);
                                      setFieldValue('house', house);
                                      this.setState({
                                        houseId: house.id
                                      });
                                    }}
                                  />
                                );
                              }}
                            />
                          </div>
                        </div>
                      )}
                      <Field
                        name="userId"
                        render={({ field }) => {
                          return (
                            <Autocomplete
                              searchFunction={text => this.getUser(text, setFieldValue)}
                              getElementTitle={getFullName}
                              customClass="mFullWidth mb-3"
                              defaultItem={values.user}
                              onSelect={user => {
                                setFieldValue('user', user);
                                setFieldValue('userId', user.id);
                              }}
                            />
                          );
                        }}
                      />
                      <Button
                        onClick={this.props.onCloseClick}
                        text={'Cancel'}
                        customClass={'mt-3 mb-3 mr-3 btn-secondary'}
                      />
                      <button
                        type="submit"
                        className="mt-3 mb-3 btn btn-primary"
                        disabled={typeof values.user === 'undefined' || values.userId === ''}
                      >
                        Merge
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}
