import * as React from 'react';
import { SchoolEvent, SchoolEventTeamDataPlayer } from 'Src/models/event';
import { StudentEventTeamPlayerPosition } from './TeamPlayerPosition';
import {
  isEventStatusFinished,
  isMultipartyEvent,
  isShowIndividualScoreForTeam
} from '../../../../../../../helpers/event/event';
import {
  convertPointsToStringWithoutDelimeter,
  getIndividualScoreByStudent
} from '../../../../../../../helpers/event/schoolEventViewHelper';
import { StudentEventTeamPlayerSubOrCaptain } from './TeamPlayerSubOrCaptain';
import { convertPointsCricket, isCricket } from '../../../../../../../helpers/sport/cricket';

interface Props {
  players: SchoolEventTeamDataPlayer[];
  event: SchoolEvent;
}

export function StudentEventTeamPlayers(props: Props) {
  const { players, event } = props;

  const isShowIndividualScore = isShowIndividualScoreForTeam(event, players);

  const rows = players.map((player, playerIndex) => {
    const { userId, permissionId } = player;
    const isEventFinished = isEventStatusFinished(event);
    const playerPoints = getIndividualScoreByStudent(event, userId, permissionId);

    let playerPointsStr;

    if (isCricket(event) && !isMultipartyEvent(event)) {
      const { runs, wickets } = convertPointsCricket(playerPoints);
      playerPointsStr = `${runs} / ${wickets}`;
    } else {
      playerPointsStr = convertPointsToStringWithoutDelimeter(event, playerPoints);
    }

    const isShowPoints = (isEventFinished && isShowIndividualScore) || Boolean(playerPoints);

    return (
      <tr key={`school_players_${playerIndex}`}>
        <td style={{ width: '5%' }}>
          <span className={'ml-1'}>{playerIndex + 1}</span>
        </td>

        <td style={{ width: '15%' }}>
          <span className={'ml-2'}>{player.firstName}</span>
        </td>

        <td style={{ width: '15%' }}>
          <span className={'ml-2'}>{player.lastName}</span>
        </td>

        <td style={{ width: '22%' }}>
          {isShowPoints && <span className={'ml-3 font-weight-bold'}>{`${playerPointsStr} `}</span>}
        </td>

        <td style={{ width: '30%' }}>
          <span className={'ePlayersPosition'}>
            <StudentEventTeamPlayerPosition player={player} event={event} />
          </span>
        </td>

        <td style={{ width: '13%' }}>
          <span className={'ePlayersSubOrCaptain'}>
            <StudentEventTeamPlayerSubOrCaptain player={player} event={event} />
          </span>
        </td>
      </tr>
    );
  });
  return (
    <table className={'w-100'}>
      <tbody>{rows}</tbody>
    </table>
  );
}
