import * as React from 'react';
import * as Lazy from 'lazy.js';
import { History } from 'history';
import { Loader } from '../../../../../../components/Loader/Loader';
import { getNameAndAges } from '../../../../../../helpers/autocomplete/autocomplete';
import { SchoolEventAddExistingTeamPlayers } from '../SchoolEventAddExistingTeam/SchoolEventAddExistingTeamPlayers';
import { Button } from '../../../../../../components/Button/Button';
import { Team, TeamPlayerWithClashes } from '../../../../../../models/team';
import { SchoolEvent } from '../../../../../../models/event';
import { AppUser } from '../../../../../App/App';
import { SimpleModal } from '../../../../../../components/SimpleModal/SimpleModal';
import * as Moment from 'moment';
import * as propz from 'propz';
import {
  getEventClash,
  getEventTitle,
  getPrototypeTeamPlayersMethod,
  isClubEvent
} from '../../../../../../helpers/event/event';
import { getSchoolEvent } from '../../../../../../helpers/service/admin/event';
import * as BPromise from 'bluebird';
import { getFromHistory } from '../../../../../../helpers/history/history';
import { TEAM_TYPE } from '../../../../../../consts/team';
import { removeSchoolEventTeam } from '../../../../../../helpers/service/admin/schoolEventTeams';
import { addSchoolEventTeam } from '../../../../../../helpers/service/admin/tournamentTeams';
import { SchoolEventClubEventRadioButtons } from '../SchoolEventClubEventRadioButtons/SchoolEventClubEventRadioButtons';
import { CLUB_EVENT_EDIT_MODE } from '../../../../../../consts/event';
import { addTeamInClubEvents } from '../../../../../../helpers/service/admin/clubTeams';
import { getSchoolTeam } from '../../../../../../helpers/service/admin/schoolTeams';

interface Props {
  user: AppUser;
  history: History;
}
interface State {
  players: TeamPlayerWithClashes[];
  isLoading: boolean;
  isError: boolean;
  playerEventsModalText: string;
  isPlayerEventsModalOpen: boolean;
  isClubEventsModalOpen: boolean;
  event: SchoolEvent;
  team: Team;
  editMode: string;
}

export class SchoolEventUpdatingTeamManager extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      team: undefined,
      players: [],
      isLoading: true,
      isError: false,
      playerEventsModalText: '',
      isPlayerEventsModalOpen: false,
      isClubEventsModalOpen: false,
      event: undefined,
      editMode: CLUB_EVENT_EDIT_MODE.SINGLE
    };
  }

  getTeamId(): string {
    const { history } = this.props;
    return getFromHistory(history, 'team');
  }

  getCloneOf(): string {
    const { history } = this.props;
    return getFromHistory(history, 'clone');
  }

  getEventId(): string {
    const { history } = this.props;
    return getFromHistory(history, 'event');
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    const { user } = this.props;
    const eventId = this.getEventId();

    const eventPromise = getSchoolEvent(user, eventId);

    BPromise.all([eventPromise])
      .then(([event]) => {
        this.setState({
          event: event
        });

        const teamSourceOfCloneId = this.getCloneOf();
        return getSchoolTeam(user, teamSourceOfCloneId);
      })
      .then(updatedTeam => {
        this.onSelectTeam(updatedTeam, event);
      });
  }

  onClosePlayerEventsModalClick = () => {
    this.setState({
      isPlayerEventsModalOpen: false
    });
  };

  renderPlayerEventsModal(): React.ReactNode {
    const { isPlayerEventsModalOpen, playerEventsModalText } = this.state;
    return (
      <SimpleModal
        isOpen={isPlayerEventsModalOpen}
        title={'Info'}
        body={playerEventsModalText}
        buttonText={'Ok'}
        onButtonClick={this.onClosePlayerEventsModalClick}
      />
    );
  }

  onEditModeClick = editMode => {
    this.setState({
      editMode: editMode
    });
  };

  onCloseClubEventsModal = () => {
    this.setState({
      isClubEventsModalOpen: false
    });
  };

  onClubEventsSaveClick = () => {
    const { team, editMode, event } = this.state;
    const { user, history } = this.props;
    const eventId = this.getEventId();
    const teamId = this.getTeamId();

    const isEditModeSingle = editMode === CLUB_EVENT_EDIT_MODE.SINGLE;

    this.setState({
      isLoading: true
    });

    const data = { ...team, cloneOf: team.id, teamType: TEAM_TYPE.CLONE };

    if (isEditModeSingle) {
      removeSchoolEventTeam(user, eventId, teamId)
        .then(res => {
          return addSchoolEventTeam(user, eventId, data);
        })
        .then(res => {
          history.push({
            pathname: '/events/event',
            search: `id=${eventId}`
          });
        });
    } else {
      const { clubId } = event;
      addTeamInClubEvents(user, clubId, eventId, teamId, data).then(res => {
        history.push({
          pathname: '/events/event',
          search: `id=${eventId}`
        });
      });
    }
  };

  renderClubEventsModal(): React.ReactNode {
    const { isClubEventsModalOpen, editMode } = this.state;
    return (
      <SimpleModal
        isOpen={isClubEventsModalOpen}
        title={'Info'}
        onCloseClick={this.onCloseClubEventsModal}
        onButtonClick={this.onClubEventsSaveClick}
        buttonText={'Save'}
      >
        <SchoolEventClubEventRadioButtons onEditModeClick={this.onEditModeClick} editMode={editMode} />
      </SimpleModal>
    );
  }

  onSelectTeam = (team, event) => {
    const { user } = this.props;
    this.setState({
      isLoading: true
    });

    const { startTime, endTime } = event;
    const { id } = team;

    let where = {};

    // copy-paste from old frontend
    const startTimeCopy = new Date(startTime);
    const startTimeDate = new Date(startTime);
    startTimeCopy.setHours(startTimeDate.getHours() - 1);
    const intervalClashStartDate = Moment(startTimeCopy)
      .utc()
      .format();

    const endTimeCopy = new Date(endTime);
    const endTimeDate = new Date(endTime);
    endTimeCopy.setHours(endTimeDate.getHours() + 1);
    const intervalClashEndDate = Moment(endTimeCopy)
      .utc()
      .format();

    propz.set(where, ['intervalClashStartDate'], intervalClashStartDate);
    propz.set(where, ['intervalClashEndDate'], intervalClashEndDate);
    propz.set(where, ['fullClashStartDate'], startTime);
    propz.set(where, ['fullClashEndDate'], endTime);

    getPrototypeTeamPlayersMethod(user)(user, id, { where }).then(players => {
      this.setState({
        team,
        players,
        isLoading: false,
        isError: false
      });
    });
  };

  onClashClick = (player: TeamPlayerWithClashes) => {
    const { user } = this.props;
    const { clashes, firstName, lastName } = player;
    const { fullClashEvents, intervalClashEvents } = clashes;
    const fullClashEventIds = fullClashEvents.map(fullClashEvent => fullClashEvent._id);
    const intervalClashEventIds = intervalClashEvents.map(intervalClashEvent => intervalClashEvent._id);

    const allPlayerEventIds = [...fullClashEventIds, ...intervalClashEventIds];
    const allPlayerEventIdsUniq = Lazy(allPlayerEventIds)
      .uniq()
      .toArray();

    this.setState({
      isLoading: true
    });

    const eventsPromise: Promise<SchoolEvent>[] = allPlayerEventIdsUniq.map(eventId => getSchoolEvent(user, eventId));

    BPromise.all(eventsPromise).then(events => {
      const eventNames = events
        .map((event, index) => {
          const title = getEventTitle(event, user);
          const clash = getEventClash(event, fullClashEventIds, intervalClashEventIds);
          return `${index + 1}. ${title} ${clash}`;
        })
        .join('\n ');

      this.setState({
        isLoading: false,
        playerEventsModalText: `${firstName} ${lastName} is participating in the following events: \n${eventNames}`,
        isPlayerEventsModalOpen: true
      });
    });
  };

  onCancelClick = () => {
    const { history } = this.props;
    const eventId = this.getEventId();

    history.push({
      pathname: '/events/event',
      search: `id=${eventId}`
    });
  };

  onSaveClick = () => {
    const { team, event } = this.state;
    const { user, history } = this.props;
    const eventId = this.getEventId();
    const teamId = this.getTeamId();
    const isTeamExist = typeof team !== 'undefined';
    if (isTeamExist) {
      if (isClubEvent(event)) {
        this.setState({
          isClubEventsModalOpen: true
        });
      } else {
        this.setState({
          isLoading: true
        });

        const data = { ...team, cloneOf: team.id, teamType: TEAM_TYPE.CLONE };

        removeSchoolEventTeam(user, eventId, teamId)
          .then(res => {
            return addSchoolEventTeam(user, eventId, data);
          })
          .then(res => {
            history.push({
              pathname: '/events/event',
              search: `id=${eventId}`
            });
          });
      }
    } else {
      this.setState({
        isError: true
      });
    }
  };

  render() {
    const { isLoading, players, isError, isPlayerEventsModalOpen, isClubEventsModalOpen, team } = this.state;
    const { user } = this.props;

    if (isLoading) {
      return <Loader />;
    }

    const { activeSchool } = user;
    const { isShowStudentEventClashes } = activeSchool;

    const classes = isPlayerEventsModalOpen || isClubEventsModalOpen ? 'mt-3 modal-open' : 'mt-3';

    return (
      <div className={classes}>
        {this.renderPlayerEventsModal()}
        {this.renderClubEventsModal()}

        <h3 className="mb-3">{getNameAndAges(team)}</h3>

        {isError && <div className="mt-3 alert alert-danger">The team cannot be found.</div>}

        <div className={'mt-3'}>
          <SchoolEventAddExistingTeamPlayers
            onClashClick={this.onClashClick}
            isShowStudentEventClashes={isShowStudentEventClashes}
            players={players}
          />
        </div>

        <Button onClick={this.onCancelClick} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
        <Button onClick={this.onSaveClick} text={'Save'} customClass={'mt-3 mb-3 mr-3'} />
      </div>
    );
  }
}
