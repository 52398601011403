import axios from 'axios';
import { AppUser } from 'Src/views/App/App';

export function updateSchoolSettings(user: AppUser, data) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.put(`${window.apiBase}/i/schools/${activeSchoolId}`, data, config).then(response => {
    return response.data;
  });
}

export function updateSchoolAdvancedSettings(user: AppUser, data) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.put(`${window.apiBase}/i/schools/${activeSchoolId}/settings`, data, config).then(response => {
    return response.data;
  });
}
