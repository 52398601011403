import * as React from 'react';
import { FunctionComponent } from 'react';
import { getEmailOrMisEmail, getUserRole } from 'Src/helpers/accessor/accessor';

interface Props {
  affectedUsers: any[];
  isEmailExist: boolean;
  isPushExist: boolean;
  isSmsExist: boolean;
}

export const AffectedUserList: FunctionComponent<Props> = props => {
  const { isEmailExist, isPushExist, isSmsExist, affectedUsers } = props;

  const usersCount = affectedUsers.length;

  const body: React.ReactNodeArray = affectedUsers.map((user, index) => {
    return (
      <tr key={`affected_user_list_row_${index}`}>
        <td>{user.firstName}</td>
        <td>{user.lastName}</td>
        <td>{getEmailOrMisEmail(user)}</td>
        <td>{getUserRole(user)}</td>
      </tr>
    );
  });

  const affectedUsersSms = affectedUsers.filter(user => user.channels.isSmsChannelsExist).length;
  const affectedUsersEmail = affectedUsers.filter(user => user.channels.isEmailChannelsExist).length;
  const affectedUsersPush = affectedUsers.filter(user => user.channels.isPushChannelsExist).length;

  const allUsers = affectedUsers.length;

  const unAffectedUsers = affectedUsers
    .filter(user => {
      return isEmailExist ? !user.channels.isEmailChannelsExist : true;
    })
    .filter(user => {
      return isPushExist ? !user.channels.isPushChannelsExist : true;
    })
    .filter(user => {
      return isSmsExist ? !user.channels.isSmsChannelsExist : true;
    }).length;

  return (
    <div>
      <div className="font-weight-bold">{`Users affected (${allUsers})`}</div>

      {isEmailExist && (
        <>
          <div className="ml-2">{`Users with email channels (${affectedUsersEmail})`}</div>
        </>
      )}

      {isPushExist && (
        <>
          <div className="ml-2">{`Users with push channels (${affectedUsersPush})`}</div>
        </>
      )}

      {isSmsExist && (
        <>
          <div className="ml-2">{`Users with SMS channels (${affectedUsersSms})`}</div>
        </>
      )}

      <div className="ml-2">{`Users with no channels (${unAffectedUsers})`}</div>

      {usersCount === 0 ? (
        <div>The message appears to be sent to 0 users</div>
      ) : (
        <table className={'table table-sm mPre mt-3'}>
          <thead className="font-weight-bold">
            <tr key="affected_user_list_head">
              <td>Name</td>
              <td>Surname</td>
              <td>Email</td>
              <td>Roles</td>
            </tr>
          </thead>
          <tbody>{body}</tbody>
        </table>
      )}
    </div>
  );
};

AffectedUserList.displayName = 'AffectedUserList';
