import * as React from 'react';
import { Component } from 'react';
import * as propz from 'propz';
import { parse } from 'query-string';
import { History, Location } from 'history';
import { AppUser } from 'Src/views/App/App';
import { Request } from '../../../../models/request';
import { FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { PERMISSION_REQUEST_STATUS } from '../../../../consts/permissionRequest';
import { DEFAULT_LIMIT } from 'Src/consts/table';
import {
  ColumnDefinition,
  TABLE_SORT_DIRECTION,
  getOrder,
  getServerFieldSectionWhere,
  getServerQueryFilter2,
  getFilters2,
  FunctionAccessorColumnDefinition
} from 'Src/helpers/table/table';
import {
  getCreatedAt,
  getRequestSports,
  getRequestRole,
  getRequestStatus,
  getRequestSchoolName,
  getRequestComment
} from '../../../../helpers/accessor/accessor';
import { getProfileRequests, createProfileRequest, deleteProfileRequest } from 'Src/helpers/service/admin/user';
import { Loader } from '../../../../components/Loader/Loader';
import { SimpleModal } from '../../../../components/SimpleModal/SimpleModal';
import { GridSortIcon } from '../../../../components/Grid/GridSortIcon';
import { TextCell } from '../../../../components/Grid/Cells/TextCell';
import { RequestForm } from './RequestForm/RequestForm';
import './RequestsTab.scss';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Date',
    field: 'createdAt',
    isSort: true,
    type: FILTER_TYPE.NONE,
    accessor: getCreatedAt
  },
  {
    text: 'School',
    field: 'school',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getRequestSchoolName
  },
  {
    text: 'Request',
    field: 'requestPreset',
    isSort: true,
    type: FILTER_TYPE.NONE,
    accessor: getRequestRole
  },
  {
    text: 'Details',
    field: 'requestComment',
    isSort: true,
    type: FILTER_TYPE.NONE,
    accessor: getRequestComment
  },
  {
    text: 'Activities / Sports',
    field: 'sports',
    isSort: true,
    type: FILTER_TYPE.NONE,
    accessor: getRequestSports
  },
  {
    text: 'Status',
    field: 'status',
    isSort: true,
    type: FILTER_TYPE.NONE,
    accessor: getRequestStatus
  }
];

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

interface State {
  isLoading: boolean;
  items: Request[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  basePath: string;
  isRequestFormModalOpen: boolean;
  isConfirmationModalOpen: boolean;
  isSuccessModalOpen: boolean;
  requestToDeleteId: string;
}

export class RequestsTab extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      items: [],
      sortDirection: '',
      sortColumnsName: '',
      basePath: '',
      isRequestFormModalOpen: false,
      isConfirmationModalOpen: false,
      isSuccessModalOpen: false,
      requestToDeleteId: undefined
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setItems();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);
    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order, DEFAULT_LIMIT);

    getProfileRequests(user, serverQueryFilter).then(requests => {
      this.setState({
        sortDirection: sortDirection,
        sortColumnsName: sortColumnsName,
        items: requests,
        basePath: history.location.pathname,
        isLoading: false
      });

      return true;
    });
  }

  onSortClick = (event, sortField) => {
    event.preventDefault();

    const { sortDirection, sortColumnsName } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  getTitles = () => {
    return COLUMNS.map((column, index) => {
      const { isSort, text, field } = column;
      const { sortDirection, sortColumnsName } = this.state;

      if (isSort) {
        return (
          <th key={`${text}_sort_${index}`}>
            <a className="eHeadSort" href="" onClick={event => this.onSortClick(event, field)}>
              {`${text} `}
              <GridSortIcon
                tableSortDirection={sortDirection}
                tableSortColumnsName={sortColumnsName}
                tableColumnName={field}
              />
            </a>
          </th>
        );
      } else {
        return <th key={`${text}_sort_${index}`}>{`${text}`}</th>;
      }
    });
  };

  renderDataRow = (dataItem: any, columns: ColumnDefinition[]) => {
    const { user } = this.props;
    const options = { user };
    const rowCells = columns.map(column => {
      const funcAccessorColumn = column as FunctionAccessorColumnDefinition;
      const value = funcAccessorColumn.accessor(dataItem, options);
      const strValue = String(value);
      return <TextCell key={column.field} text={strValue} />;
    });
    return rowCells;
  };

  renderActionCell = (requestItem: Request) => {
    const status = propz.get(requestItem, ['status']);
    const actionCell =
      status === PERMISSION_REQUEST_STATUS.NEW ? (
        <td onClick={() => this.onCancelRequestClick(requestItem.id)} className="eRolesTabLink">
          Cancel
        </td>
      ) : (
        <td></td>
      );

    return actionCell;
  };

  onCloseRequestFormModal = () => {
    this.setState({
      isRequestFormModalOpen: false
    });
  };

  onSubmitRequestForm = data => {
    const { user } = this.props;

    this.setState({
      isLoading: true
    });

    createProfileRequest(user, data).then(request => {
      this.setState({
        isLoading: false,
        isRequestFormModalOpen: false,
        isSuccessModalOpen: true
      });
    });
  };

  renderRequestFormModal = () => {
    const { user } = this.props;
    const { isRequestFormModalOpen, items } = this.state;

    return (
      <SimpleModal customClass="mSchoolRequestFormWidth" isOpen={isRequestFormModalOpen}>
        <RequestForm
          user={user}
          onCancel={this.onCloseRequestFormModal}
          onSubmit={this.onSubmitRequestForm}
          requests={items}
        />
      </SimpleModal>
    );
  };

  onAddRequestClick = () => {
    this.setState({
      isRequestFormModalOpen: true
    });
  };

  renderSuccessModal = () => {
    const { isSuccessModalOpen } = this.state;

    return (
      <SimpleModal
        title={'Success'}
        isOpen={isSuccessModalOpen}
        body={'You just requested new role. Approving will take some time. We will send you email notification.'}
        buttonText={'Ok'}
        onButtonClick={this.onCloseSuccessModal}
      />
    );
  };

  onCloseSuccessModal = () => {
    this.setState({
      isSuccessModalOpen: false
    });
    this.setItems();
  };

  renderConfirmationModal = () => {
    const { isConfirmationModalOpen } = this.state;

    return (
      <SimpleModal
        title={'Confirmation'}
        isOpen={isConfirmationModalOpen}
        body={'Are you sure you want to cancel pending request?'}
        buttonText={'Ok'}
        onButtonClick={this.onOkConfirmationModalClick}
        buttonCancelText={'Cancel'}
        onCloseClick={this.onCancelConfirmationModalClick}
      />
    );
  };

  onCancelConfirmationModalClick = () => {
    this.setState({
      isConfirmationModalOpen: false
    });
  };

  onCancelRequestClick = (requestId: string) => {
    this.setState({
      isConfirmationModalOpen: true,
      requestToDeleteId: requestId
    });
  };

  onOkConfirmationModalClick = () => {
    const { user } = this.props;
    const { requestToDeleteId } = this.state;

    this.setState({
      isLoading: true
    });

    deleteProfileRequest(user, requestToDeleteId).then(() => {
      const { items } = this.state;
      const itemsUpdated = items.filter(request => request.id !== requestToDeleteId);

      this.setState({
        isConfirmationModalOpen: false,
        items: itemsUpdated,
        isLoading: false
      });
    });
  };

  render() {
    const { isLoading, items, isRequestFormModalOpen } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes = isRequestFormModalOpen ? 'modal-open' : '';

    return (
      <div className={classes}>
        <div className="row ml-1">
          {this.renderRequestFormModal()}
          {this.renderSuccessModal()}
          {this.renderConfirmationModal()}
          <div className="col-xl-8">
            <div className={'mb-3'}>
              <div className="eProfileRequestsTitle ml-2">{'Profile Requests'}</div>
              <div className="ml-2">
                <div>You can see the status of all role requests from this tab.</div>
                <div>
                  Pending – Your request is still awaiting approval, Accepted – You have this role on your account,
                  Declined – Your request has been declined by your school.
                </div>
                <div>
                  Please note from here you can cancel any pending role requests as well as send any new requests.
                </div>
              </div>
              <button className="btn btn-primary mb-3 mt-3 ml-2" onClick={this.onAddRequestClick}>
                Add new request
              </button>
              <table className={'table table-sm'}>
                <thead>
                  <tr>
                    {this.getTitles()}
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((requestItem, index) => {
                    return (
                      <tr key={`profile_requests_tab_${index}`}>
                        {this.renderDataRow(requestItem, COLUMNS)}
                        {this.renderActionCell(requestItem)}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
