import * as React from 'react';
import { FunctionComponent } from 'react';
import * as classNames from 'classnames';

interface Props {
  tabs: string[];
  currentTabIndex: number;
  onTabClick: (event, index: number) => void;
  customClass?: string;
}

export const Tabs: FunctionComponent<Props> = props => {
  const { customClass } = props;
  const classes = classNames('nav nav-tabs', customClass);

  const tabs = props.tabs.map((tab, index) => {
    const tabClassName = props.currentTabIndex === index ? 'nav-link active' : 'nav-link';

    return (
      <li key={`tab_${index}`} className="nav-item">
        <a
          className={tabClassName}
          href=""
          onClick={event => {
            props.onTabClick(event, index);
          }}
        >
          {tab}
        </a>
      </li>
    );
  });

  return <ul className={classes}>{tabs}</ul>;
};

Tabs.displayName = 'Tabs';
