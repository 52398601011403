import * as React from 'react';
import * as propz from 'propz';
import { Field, Form, Formik } from 'formik';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from '../../../../../../consts/report';
import { Tournament } from '../../../../../../models/tournament';
import { Switch } from '../../../../../../components/Switch/Switch';
import { LabelWithQuestionIcon } from '../../../../../../components/LabelWithQuestionIcon/LabelWithQuestionIcon';

interface Props {
  onCloseClick: () => void;
  onSubmit: (data: any, reportType: string) => void;
  tournament: Tournament;
}

// report 012
export function TournamentParticipationConfirmationsReportForm(props: Props) {
  const { tournament } = props;

  const requestInitial = {
    isShowAccepted: true,
    isShowRejected: true,
    isShowUnresponded: true,
    isShowNotSent: true
  };

  return (
    <Formik
      onSubmit={values => {
        props.onSubmit(values, REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.TOURNAMENT_PARTICIPATION_CONFIRMATIONS);
      }}
      initialValues={requestInitial}
      render={({ values, setFieldValue }) => (
        <Form>
          <div className="bReportFormTitle">Tournament participation confirmations</div>
          <div className="mb-3 mt-3">
            {'Get a report with all details contained in the responses to your tournament confirmation request.'}
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isShowAccepted"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowAccepted}
                    onChange={event => {
                      const isShowAccepted = event.target.checked;
                      setFieldValue('isShowAccepted', isShowAccepted);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show Accepted requests"
                hintText={'Show confirmation requests that have been accepted.'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowAccepted"
              />
            </div>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isShowRejected"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowRejected}
                    onChange={event => {
                      const isShowRejected = event.target.checked;
                      setFieldValue('isShowRejected', isShowRejected);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show Rejected requests"
                hintText={'Show confirmation requests that have been rejected.'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowRejected"
              />
            </div>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isShowUnresponded"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowUnresponded}
                    onChange={event => {
                      const isShowUnresponded = event.target.checked;
                      setFieldValue('isShowUnresponded', isShowUnresponded);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show pending requests"
                hintText={'Show confirmation requests that have not been responded to yet.'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowUnresponded"
              />
            </div>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isShowNotSent"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowNotSent}
                    onChange={event => {
                      const isShowNotSent = event.target.checked;
                      setFieldValue('isShowNotSent', isShowNotSent);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show not sent"
                hintText={'Show schools the confirmation requests have not been sent to.'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowNotSent"
              />
            </div>
          </div>

          <button type="button" className="btn btn-secondary mr-3" onClick={props.onCloseClick}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Get
          </button>
        </Form>
      )}
    />
  );
}
