import * as React from 'react';
import { Component } from 'react';
import { Field, Form, Formik } from 'formik';
import { Button } from 'Src/components/Button/Button';
import { SketchPicker } from 'react-color';
import { TournamentTag } from 'Src/models/tournamentTag';
import { AppUser } from 'Src/views/App/App';
import './TournamentTagForm.css';
import { Switch } from 'Src/components/Switch/Switch';
import { LabelWithQuestionIcon } from 'Src/components/LabelWithQuestionIcon/LabelWithQuestionIcon';

interface Props {
  tournamentTag?: TournamentTag;
  onCancel: () => void;
  onSubmit: (data: any) => void;
  user: AppUser;
}

interface State {
  isLoading: boolean;
  tagColor: string;
  tempTagColor: string;
  displayColorPicker: boolean;
}

export class TournamentTagForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    // Initial state setup
    this.state = {
      isLoading: false,
      tagColor: props.tournamentTag?.tagColor ?? '#ffffff',
      tempTagColor: props.tournamentTag?.tagColor ?? '#ffffff',
      displayColorPicker: false
    };
  }

  toggleColorPicker = () => {
    this.setState(prevState => ({ displayColorPicker: !prevState.displayColorPicker }));
  };

  onColorChange = color => {
    this.setState({ tempTagColor: color.hex });
  };

  confirmColor = () => {
    this.setState({ tagColor: this.state.tempTagColor, displayColorPicker: false });
  };

  cancelColor = () => {
    this.setState({ tempTagColor: this.state.tagColor, displayColorPicker: false });
  };

  onSubmit = values => {
    const data = {
      ...values,
      schoolId: this.props.user.activeSchoolId,
      tagColor: this.state.tagColor
    };
    this.props.onSubmit(data);
  };

  render() {
    const { onCancel } = this.props;
    const { tagColor, tempTagColor, displayColorPicker } = this.state;

    const initialValues = {
      tagName: this.props.tournamentTag?.tagName || '',
      tagDescription: this.props.tournamentTag?.tagDescription || '',
      isPublic: this.props.tournamentTag?.isPublic ?? false
    };

    return (
      <div className={`container-fluid ${displayColorPicker ? 'modal-open' : ''}`}>
        {displayColorPicker && <div className="backdrop" onClick={this.toggleColorPicker}></div>}
        <Formik initialValues={initialValues} onSubmit={this.onSubmit}>
          {({ touched, errors, setFieldValue, values }) => (
            <Form>
              <Field name="tagName" placeholder="Tag Name" className="form-control mb-3" />
              <Field as="textarea" name="tagDescription" placeholder="Description" className="form-control mb-3" />

              <div className="mb-3">
                <label>Color</label>
                <div
                  className="color-preview"
                  onClick={this.toggleColorPicker}
                  style={{
                    backgroundColor: tagColor,
                    cursor: 'pointer',
                    width: '36px',
                    height: '36px',
                    borderRadius: '50%',
                    border: '2px solid #000',
                    marginLeft: '10px',
                    display: 'inline-block'
                  }}
                ></div>
                {displayColorPicker && (
                  <div
                    className="color-picker-modal"
                    style={{
                      position: 'fixed',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 1050
                    }}
                  >
                    <div className="color-picker">
                      <SketchPicker color={tempTagColor} onChange={this.onColorChange} />
                      <div className="d-flex justify-content-between mt-2">
                        <Button onClick={this.confirmColor} text="Confirm" customClass="btn-primary" />
                        <button className="btn btn-secondary" onClick={this.cancelColor}>
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="d-flex mb-3">
                <Field
                  name="isPublic"
                  render={({ field }) => (
                    <Switch
                      {...field}
                      value={values.isPublic}
                      customClass="mb-3"
                      onChange={event => {
                        const isPublic = event.target.checked;
                        setFieldValue('isPublic', isPublic);
                      }}
                    />
                  )}
                />
                <LabelWithQuestionIcon
                  labelText="Public"
                  hintText="Make this tag public or private"
                  labelHtmlFor="isPublic"
                  customLabelClass="form-check-label pl-2"
                />
              </div>

              <Button onClick={onCancel} text="Cancel" customClass="btn-secondary mr-2" />
              <button type="submit" className="btn btn-primary">
                Save
              </button>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}
