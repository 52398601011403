import * as React from 'react';
import { Select } from '../../../../components/Select/Select';
import { getOptionsForMonthDropdown } from '../../../../helpers/calendar/calendar';

interface Props {
  activeMonthIndex: number;
  onMonthChange: (value: number) => void;
}

export function MonthDropdown(props: Props) {
  const { activeMonthIndex, onMonthChange } = props;
  const options = getOptionsForMonthDropdown();

  return (
    <Select
      customClass="eMonthDropdown"
      isSetDefaultValue={false}
      onChange={onMonthChange}
      activeResultIndex={activeMonthIndex}
      options={options}
    />
  );
}
