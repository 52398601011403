import * as React from 'react';
import * as Moment from 'moment';
import * as propz from 'propz';
import { getFullName } from 'Src/helpers/autocomplete/autocomplete';
import { EventInvitationMessage } from 'Src/models/message';
import { INVITE_TYPE } from 'Src/consts/invite';
import { DetailsIcon } from 'Src/components/SvgIcons/DetailsIcon';
import './AvailabilityReportCard.scss';

interface Props {
  item: EventInvitationMessage;
  type: string;
  onViewMapClick: (item: EventInvitationMessage) => void;
  onShowDetailsClick: (item: EventInvitationMessage) => void;
}

export const AvailabilityReportCard = (props: Props) => {
  const { item, type, onViewMapClick, onShowDetailsClick } = props;

  const { playerDetailsData } = item;

  const isInviteTypeOutbox = type === INVITE_TYPE.OUTBOX;
  const studentName = getFullName(playerDetailsData);
  const schoolLogo = propz.get(item, ['schoolData', 'pic'], '');
  const isSchoolLogoExist = schoolLogo !== '';
  const details = propz.get(item, ['details'], '');
  const detailsText = details === '' ? 'Details are missing' : details;
  const sportName = propz.get(item, ['eventData', 'sport', 'name'], '');
  const postcode = propz.get(item, ['eventData', 'venue', 'placeData', 'postcode'], '');
  const placeName = propz.get(item, ['eventData', 'venue', 'placeData', 'name'], '');
  const isVenueDataExist = postcode !== '' && placeName !== '';
  const venue = `${postcode}, ${placeName}`;
  const startTime = propz.get(item, ['eventData', 'startTime'], '');
  const startTimeFormatted = Moment(startTime).format('DD-MM-YYYY HH:mm');
  const isTakePart = propz.get(item, ['isTakePart'], false);
  const takePartValue = isTakePart ? 'Yes' : 'No';

  const renderVenue = () => {
    return isVenueDataExist ? (
      <>
        {venue}
        <img src="/dist/images/icons/location.png" alt="Location icon" />
      </>
    ) : (
      'There is no information about the venue'
    );
  };

  return (
    <div className="bAvailabiltyReportCard">
      <div className="eAvailabiltyReportCardStudentName">
        <p className={isSchoolLogoExist ? '' : 'text-center'}>{studentName}</p>

        {isSchoolLogoExist && (
          <div className="eAvailabiltyReportCardSchoolLogo">
            <img src={schoolLogo} alt="SchoolLogo" />
          </div>
        )}
      </div>

      <div className="eAvailabiltyReportCardSport">{sportName}</div>

      <div className="eAvailabiltyReportCardVenue" onClick={() => isVenueDataExist && onViewMapClick(item)}>
        {renderVenue()}
      </div>

      <div className="eAvailabiltyReportCardTakePart">
        <span className="font-weight-bold mr-1">Take part:</span>
        <span className={isTakePart ? 'text-success' : 'text-danger'}>{takePartValue}</span>
      </div>

      <div className="mx-auto mt-2">
        <p className="text-center">Start time: {startTimeFormatted}</p>
      </div>

      <div className="d-flex justify-content-end">
        <div className="eAvailabiltyReportCardShowDetailsButton" onClick={() => onShowDetailsClick(item)}>
          <DetailsIcon />
          <span>Details</span>
        </div>
      </div>
    </div>
  );
};

AvailabilityReportCard.displayName = 'AvailabilityReportCard';
