import * as React from 'react';
import { Component } from 'react';
import {HTMLEditorQuillForMatchReport} from "../../../../../../components/HTMLEditorQuill/HTMLEditorQuillForMatchReport";
import {SchoolEvent} from "../../../../../../models/event";

interface Props {
    schoolEvent: SchoolEvent;
    matchReport: string;
}

interface State {
    isEditorActive: boolean;
}

export class StudentEventMatchReportSummaryTab extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isEditorActive: false
        };
    }

    render() {
        const { isEditorActive } = this.state;

        return (
            <div className="container-fluid pl-0 mt-5">
                <div className="row">
                    <div className="col-md-10">
                        <div className="form-group">
                            <div className="d-flex eMatchReportEditor">
                                <HTMLEditorQuillForMatchReport
                                    onTextMatchReportChange={() => {}}
                                    value={this.props.matchReport}
                                    isEnabled={isEditorActive}
                                    isEnabledEditorActiveToolbar={false}
                                    placeholder="Nothing has been added yet"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}