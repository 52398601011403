import * as React from 'react';
import { FunctionComponent } from 'react';
import * as Lazy from 'lazy.js';
import { Tournament } from '../../models/tournament';
import * as propz from 'propz';
import { getSpaces } from '../../helpers/tournament/tournament';

interface Props {
  tournament: Tournament;
  schoolsWaitingListCount: number;
}

const ROWS = [
  { title: 'Autoenrollment', field: 'isAutoEnrollmentTeams' },
  { title: 'Spaces', field: 'spaces' },
  { title: 'Waiting list', field: 'waitingList' }
];

export const TournamentAutoEnrollmentSummary: FunctionComponent<Props> = props => {
  const rows = ROWS.map((row, index) => {
    const { tournament, schoolsWaitingListCount } = props;
    let contentCell: string;
    const field = row.field;

    switch (field) {
      case 'isAutoEnrollmentTeams':
        contentCell = tournament.isAutoEnrollmentTeams ? 'yes' : 'no';
        break;
      case 'spaces':
        contentCell = getSpaces(tournament);
        break;
      case 'waitingList':
        contentCell = `${schoolsWaitingListCount}`;
        break;
      default:
        console.error('field not defined');
        contentCell = '';
    }

    return (
      <tr key={`tournament_autoEnrollment_summary_${index}`}>
        <td>{row.title}</td>
        <td>{contentCell}</td>
      </tr>
    );
  });

  return (
    <table className={'table table-sm mPre'}>
      <tbody>{rows}</tbody>
    </table>
  );
};

TournamentAutoEnrollmentSummary.displayName = 'TournamentAutoEnrollmentSummary';
