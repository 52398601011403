import * as React from 'react';
import { Component } from 'react';
import * as propz from 'propz';
import { AppUser } from 'Src/views/App/App';
import { getFullName } from 'Src/helpers/autocomplete/autocomplete';
import { IndividualTournamentTeamPlayer } from 'Src/models/tournamentTeamPlayer';
import { Autocomplete } from 'Src/components/Autocomplete/Autocomplete';
import { searchFunctionHighLevelTournamentEvents } from 'Src/helpers/autocomplete/autocomplete';
import { Switch } from 'Src/components/Switch/Switch';
import { SchoolEvent } from 'Src/models/event';
import { AGE_GROUPS } from 'Src/consts/school';
import { sortTournamentEventsByAge } from 'Src/helpers/event/event';

interface Props {
  user: AppUser;
  students: IndividualTournamentTeamPlayer[];
  tournamentId: string;
  addPlayerToEvent: (students: IndividualTournamentTeamPlayer[], events: SchoolEvent[]) => void;
  onCloseClick: () => void;
}

interface State {
  isEventsForOlderGroup: boolean;
  selectedEvents: SchoolEvent[];
}

export class AddPlayerToEventForm extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isEventsForOlderGroup: false,
      selectedEvents: []
    };
  }

  renderStudents = () => {
    const { students } = this.props;

    return (
      <ul>
        {students.map(student => {
          const { id } = student;
          const studentFullName = getFullName(student);

          return <li key={id}>{studentFullName}</li>;
        })}
      </ul>
    );
  };

  renderEvents() {
    const { user } = this.props;
    const { activeSchoolId } = user;
    const { selectedEvents } = this.state;

    return selectedEvents.map(event => {
      const eventName = propz.get(event, ['generatedNames', activeSchoolId], '');

      return (
        <div key={event.id} className={'d-flex'}>
          <div className="bg-primary text-white w-100 p-2 mt-2 d-flex justify-content-between rounded">
            <span>{eventName}</span>
            <div className="ml-1">
              <div
                className="eButtonClose"
                onClick={() => {
                  const filteredEvents = selectedEvents.filter(item => item.id !== event.id);
                  this.setState({
                    selectedEvents: filteredEvents
                  });
                }}
              >
                <span>&times;</span>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  searchFunction = (text: string) => {
    const { user, students, tournamentId } = this.props;
    const { isEventsForOlderGroup, selectedEvents } = this.state;

    const ageGroupsNaming = propz.get(user, ['activeSchool', 'ageGroupsNaming'], '');
    const ageGroupsCount = AGE_GROUPS[ageGroupsNaming].length;

    const selectedEventsIds = selectedEvents.map(event => event.id);

    return searchFunctionHighLevelTournamentEvents(
      user,
      students,
      tournamentId,
      isEventsForOlderGroup,
      selectedEventsIds,
      text
    ).then(events => {
      const studentsAges = students.map(student => {
        return propz.get(student, ['form', 'age']);
      });

      const studentsMaxAge = Math.max(...studentsAges);

      if (isEventsForOlderGroup) {
        const olderAges = [studentsMaxAge];

        for (let index = studentsMaxAge + 1; index < ageGroupsCount; index++) {
          olderAges.push(index);
        }

        const filteredEvents = events.filter(event => {
          const { ages } = event;

          return olderAges.some(olderAge => {
            return ages.includes(olderAge);
          });
        });

        return sortTournamentEventsByAge(filteredEvents, ageGroupsNaming);
      } else {
        const filteredEvents = events.filter(event => {
          const { ages } = event;

          return ages.includes(studentsMaxAge);
        });

        return sortTournamentEventsByAge(filteredEvents, ageGroupsNaming);
      }
    });
  };

  onSubmit = () => {
    const { addPlayerToEvent, students } = this.props;
    const { selectedEvents } = this.state;

    addPlayerToEvent(students, selectedEvents);
  };

  render() {
    const { user, students, onCloseClick } = this.props;
    const { activeSchoolId } = user;
    const { isEventsForOlderGroup, selectedEvents } = this.state;

    const isSelectedEventsEmpty = selectedEvents.length === 0;
    const studentsCount = students.length;

    return (
      <div>
        <div className="pb-5">
          <h6>Student(s) you are adding:</h6>
          {this.renderStudents()}

          {studentsCount > 1 && (
            <div>
              <span className="text-info">
                Please be aware that only events all students selected are eligible for will be shown. If your chosen
                students are in different age groups you will need enable the "Include events for older groups" checkbox
                to view all eligible events. If a student has been added to an event already, the event will not be
                available for selection.
              </span>
            </div>
          )}

          <div className="mt-3 mb-5">
            <Switch
              value={isEventsForOlderGroup}
              onChange={() => {
                this.setState({ isEventsForOlderGroup: !isEventsForOlderGroup });
              }}
              text={'Include events for older groups'}
              name={'ageGroup'}
              customClass="mb-3"
            />

            <Autocomplete
              searchFunction={this.searchFunction}
              getElementTitle={item => propz.get(item, ['generatedNames', activeSchoolId], '')}
              customClass="mFullWidth"
              placeholder="Start typing the event name"
              onSelect={event => {
                this.setState(prevState => {
                  return {
                    selectedEvents: [...prevState.selectedEvents, event]
                  };
                });
              }}
            />
            {this.renderEvents()}
          </div>
        </div>
        <div className="d-flex justify-content-end mt-3">
          <button className="btn btn-primary mr-2" disabled={isSelectedEventsEmpty} onClick={this.onSubmit}>
            OK
          </button>
          <button className="btn btn-secondary" onClick={onCloseClick}>
            CLOSE
          </button>
        </div>
      </div>
    );
  }
}
