import * as React from 'react';
import * as propz from 'propz';
import { AppUser } from 'Src/views/App/App';
import './UserName.scss';

interface Props {
  user: AppUser;
}

export function UserName(props: Props) {
  const { user } = props;
  const firstName = propz.get(user, ['profile', 'firstName']);
  const lastName = propz.get(user, ['profile', 'lastName']);
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="eProfileName">{`${firstName} ${lastName}`}</div>
      </div>
    </div>
  );
}
