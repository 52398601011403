import { SchoolEvent, SchoolEventTeamData } from '../../models/event';
import {
  isEventStatusFinished,
  isMultipartyEvent,
  isResultsModePlaces,
  isResultsModePoints,
  isTeamSportEvent
} from '../event/event';
import { getTeamScoreByTeamId } from '../event/schoolEventViewHelper';
import { isLessScoringSport } from '../sport/sport';
import { isCricket } from '../sport/cricket';
import { getTeamPlacesLeft } from 'Src/helpers/tournament/tournament';
import { isPositiveNumber } from 'Src/helpers/form/form';

export function sortByNameAsc(item1, item2) {
  switch (true) {
    case item1.name > item2.name:
      return 1;
    case item1.name < item2.name:
      return -1;
    default:
      return 0;
  }
}

export function sortTeams(event: SchoolEvent, team1: SchoolEventTeamData, team2: SchoolEventTeamData) {
  const isEventFinished = isEventStatusFinished(event);
  const isEventCricket = isCricket(event);
  const isMultiparty = isMultipartyEvent(event);
  const score1 = getTeamScoreByTeamId(event, team1.id);
  const score2 = getTeamScoreByTeamId(event, team2.id);

  switch (true) {
    case isEventCricket && !isMultiparty:
      return sortByNameAsc(team1, team2);
    case isEventFinished && isMultiparty:
      const isPlaces = isResultsModePlaces(event);
      const isPoints = isResultsModePoints(event);
      switch (true) {
        case isPlaces:
          return score1 - score2;
        case isPoints:
          return score2 - score1;
        default:
          console.error('Can not find results mode');
          return 0;
      }
    case isEventFinished && !isMultiparty:
      const isLessScoringEventSport = isLessScoringSport(event.sport);
      return isLessScoringEventSport ? score1 - score2 : score2 - score1;
    default:
      return sortByNameAsc(team1, team2);
  }
}

export function getPlayers(schoolId, event) {
  let players = [];

  if (isTeamSportEvent(event)) {
    event.teamsData.forEach(t => {
      if (typeof t.players !== 'undefined') {
        players = players.concat(t.players);
      }
    });
  } else {
    players = event.individualsData;
  }

  return players.filter(p => p.schoolId === schoolId);
}

export function checkIsTeamCountNegativeNumber(values) {
  for (let key in values) {
    const value = values[key];

    return !isPositiveNumber(value);
  }
}

export function checkIsTeamLimitExceeded(values, autoEnrollmentTeamsSettings, maximumNumberTeamsOfSchool) {
  let isTeamLimitExceeded = false;

  for (let key in values) {
    const [age, gender] = key.split('-');

    const newTeamsCount = Number(values[key]);
    const teamPlacesLeft = getTeamPlacesLeft(autoEnrollmentTeamsSettings, Number(age), gender);

    const autoEnrollmentTeamsSetting = autoEnrollmentTeamsSettings.find(setting => {
      return setting.age === Number(age) && setting.gender === gender;
    });

    const { maximumNumberTeams } = autoEnrollmentTeamsSetting;

    const isTeamsLimitedByType = maximumNumberTeams !== 0;
    const isTeamsLimitedBySchool = maximumNumberTeamsOfSchool !== 0;

    const isTeamLimitByTypeExceeded = isTeamsLimitedByType && newTeamsCount > teamPlacesLeft;
    const isTeamLimitBySchoolExceeded = isTeamsLimitedBySchool && newTeamsCount > maximumNumberTeamsOfSchool;

    if (isTeamLimitByTypeExceeded || isTeamLimitBySchoolExceeded) {
      isTeamLimitExceeded = true;
    }
  }

  return isTeamLimitExceeded;
}
