import * as React from 'react';
import { Field, Form, Formik } from 'formik';
import { SchoolEvent } from 'Src/models/event';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/report';
import { isTeamSportEvent, isIndividualSportEvent } from 'Src/helpers/event/event';
import { Switch } from 'Src/components/Switch/Switch';
import { LabelWithQuestionIcon } from 'Src/components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import { SelectField } from 'Src/components/SelectField/SelectField';
import '../../SchoolEventView.scss';

interface Props {
  event: SchoolEvent;
  activeSchoolId: string;
  onCloseClick: () => void;
  onSubmit: (data: any, reportType: string) => void;
}

//report 043
export function CautionsDismissalsReportForm({ onCloseClick, onSubmit, event, activeSchoolId }: Props) {
  const { id: eventId } = event;

  const requestInitial = {
    isPrintFullNames: true,
    isPrintComments: true
  };

  return (
    <Formik
      onSubmit={values => onSubmit(values, REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.CAUTIONS_DISMISSALS)}
      initialValues={requestInitial}
      render={({ values, setFieldValue }) => (
        <Form>
          <div className="bReportFormTitle">Cautions and dismissals</div>
          <div className="mb-3 mt-3">
            {'Get your own pre-filled form to keep record of players cautions and dismissals'}
          </div>

          <div className="form-check mb-4">
            <div className="d-flex">
              <Field
                name="isPrintFullNames"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintFullNames}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintFullNames = event.target.checked;
                      setFieldValue('isPrintFullNames', isPrintFullNames);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show full names"
                hintText={
                  `Select if you would like full student names to be printed. When unselected only first name and ` +
                  ` first letter of the surname will be shown. Please be aware that in case of inter-school fixtures ` +
                  ` due to the GDPR policies the opposing team full names will never be shown.`
                }
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintFullNames"
              />
            </div>
          </div>

          <div className="form-check mb-4">
            <div className="d-flex">
              <Field
                name="isPrintComments"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintComments}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintComments = event.target.checked;
                      setFieldValue('isPrintComments', isPrintComments);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Keep space for adding comments"
                hintText={`When this checkbox is On, an extra column will be displayed for your comments ' +
                  ' related to a particular student.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintComments"
              />
            </div>
          </div>

          <button type="button" className="btn btn-secondary mr-3" onClick={onCloseClick}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Get
          </button>
        </Form>
      )}
    />
  );
}
