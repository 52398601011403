import * as React from 'react';
import * as propz from 'propz';
import { FunctionComponent } from 'react';
import { TextCell } from '../Grid/Cells/TextCell';
import {
  ColumnDefinition,
  CustomCellColumnDefinition,
  FunctionAccessorColumnDefinition,
  getColSpanNumber,
  isCustomCellColumnDefinition,
  isFunctionAccessorBasedColumnDefinition,
  isPathAccessorBasedColumnDefinition,
  PathAccessorColumnDefinition,
  TABLE_SORT_DIRECTION
} from '../../helpers/table/table';
import { FilterRow2 } from '../Grid/FilterRow2';
import { FilterIcon } from '../FilterIcon/FilterIcon';
import { Pagination2 } from '../Pagination/Pagination2';
import { GridFilterButtons } from '../Grid/GridFilterButtons';
import { SelectAllCheckbox } from '../SelectAllCheckbox/SelectAllCheckbox';
import { GridSortIcon } from '../Grid/GridSortIcon';
import { SelectOptions } from '../Grid/Grid2';
import { getPersonListSortedByName } from 'Src/helpers/table/table';

interface Props {
  items: any[];
  itemsSelected: any[];
  title?: string;
  columns: ColumnDefinition[];
  onItemClick: (index: number) => void;
  onFilterChange?: (event, filterField: string, options?) => void;
  filters?: any;
  filterOptions?: SelectOptions;
  isShowFilter?: boolean;
  isFiltered?: boolean;
  onFilterClick?: (event) => void;

  total: number;
  currentPage: number;
  paginationLimit?: number;

  setCurrentPageParams: (currentPage: number) => void;

  onApplyFilterClick: () => void;
  onClearFilterClick: () => void;

  onSelectAllClick: () => void;
  onSelectAllOnPageClick: () => void;
  onUnselectAllClick: () => void;
  isSelectAllChecked: boolean;
  isNoVisibleBlock?: boolean;

  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  onSortClick: (sortField: string) => void;

  itemsAddBuffer?: any[];
  itemsRemoveBuffer?: any[];

  onCellClick?: (item) => void;
  removeItemFromBuffer?: (itemId: string) => void;
}

export const Panel: FunctionComponent<Props> = props => {
  const {
    items,
    title,
    columns,
    itemsSelected,
    onItemClick,
    onFilterChange,
    filters,
    filterOptions,
    isShowFilter,
    isFiltered,
    onFilterClick,
    total,
    currentPage,
    setCurrentPageParams,
    onApplyFilterClick,
    onClearFilterClick,
    paginationLimit,
    onSelectAllClick,
    onSelectAllOnPageClick,
    onUnselectAllClick,
    isSelectAllChecked,
    sortDirection,
    sortColumnsName,
    onSortClick,
    itemsAddBuffer,
    itemsRemoveBuffer,
    onCellClick,
    isNoVisibleBlock,
    removeItemFromBuffer
  } = props;

  const sortClick = (event, field) => {
    event.preventDefault();
    onSortClick(field);
  };

  const renderHead = columns.map((column, index) => {
    const { isSort, text, field } = column;

    if (isSort) {
      return (
        <th key={`${text}_sort_${index}`}>
          <a className="eHeadSort" href="" onClick={event => sortClick(event, field)}>
            {`${text} `}
            <GridSortIcon
              tableSortDirection={sortDirection}
              tableSortColumnsName={sortColumnsName}
              tableColumnName={field}
            />
          </a>
        </th>
      );
    } else {
      return <th key={`${text}_sort_${index}`}>{`${text}`}</th>;
    }
  });

  const renderItem = (item: any, columns: ColumnDefinition[]) => {
    const rowCells = columns.map(column => {
      switch (true) {
        case isPathAccessorBasedColumnDefinition(column): {
          // this can be removed once user-defined type guards will support switch-case [https://github.com/Microsoft/TypeScript/issues/2214]
          const pathAccessorBasedColumn = column as PathAccessorColumnDefinition;
          const value = propz.get<any>(item, pathAccessorBasedColumn.accessor, '');
          const strValue = String(value);
          return <TextCell key={column.field} text={strValue} />;
        }

        case isFunctionAccessorBasedColumnDefinition(column): {
          const funcAccessorColumn = column as FunctionAccessorColumnDefinition;
          const value = funcAccessorColumn.accessor(item);
          const strValue = String(value);
          return <TextCell key={column.field} text={strValue} />;
        }

        case isCustomCellColumnDefinition(column): {
          const cellColumn = column as CustomCellColumnDefinition;
          const cell = cellColumn.cell(item, onCellClick);
          return cell;
        }

        default: {
          console.error('Not supported column type: ' + JSON.stringify(column, null, 2));
          return null;
        }
      }
    });

    return rowCells;
  };

  const sortedItems = getPersonListSortedByName(items);

  const renderBody = sortedItems.map((item, index) => {
    const isItemSelected = itemsSelected.some(itemSelected => itemSelected.id === item.id);
    const isItemRemoved = itemsRemoveBuffer.some(itemRemove => itemRemove.id === item.id);

    switch (true) {
      case isItemSelected:
        return (
          <tr
            key={item.id}
            className={'table-primary'}
            onClick={() => {
              onItemClick(index);
            }}
          >
            <td>
              <input onChange={() => {}} className="" type="checkbox" checked={isItemSelected} />
            </td>
            {renderItem(item, columns)}
          </tr>
        );
      case isItemRemoved:
        return (
          <tr key={item.id} className={'table-secondary'}>
            <td />
            {renderItem(item, columns)}
          </tr>
        );
      default:
        return (
          <tr
            key={item.id}
            onClick={() => {
              onItemClick(index);
            }}
          >
            <td>
              <input onChange={() => {}} className="" type="checkbox" checked={isItemSelected} />
            </td>
            {renderItem(item, columns)}
          </tr>
        );
    }
  });

  const renderBodyAddBuffer = itemsAddBuffer.map(item => (
    <tr key={item.id} className={'table-warning'}>
      <td>
        {removeItemFromBuffer && (
          <button onClick={() => removeItemFromBuffer(item.id)} className="btn btn-danger btn-sm">
            Remove
          </button>
        )}
      </td>
      {renderItem(item, columns)}
    </tr>
  ));

  const colSpanNumber = getColSpanNumber(columns);

  return (
    <div className="col-md-6">
      {title && <div className={'ePanelTitle'}>{title}</div>}

      <div className="row">
        <div className="col-md-6 mb-3">
          <SelectAllCheckbox
            onSelectAllClick={onSelectAllClick}
            onSelectAllOnPageClick={onSelectAllOnPageClick}
            onUnselectAllClick={onUnselectAllClick}
            isChecked={isSelectAllChecked}
            totalCount={total}
            currentPage={currentPage}
            selectedItems={itemsSelected}
            isNoVisibleCheckbox={isNoVisibleBlock}
          />
          {!isNoVisibleBlock && (
            <FilterIcon onTableFilterClick={onFilterClick} isFilterShown={isShowFilter} isDataFiltered={isFiltered} />
          )}
        </div>
        <div className="col-md-6">
          {!isNoVisibleBlock && (
            <Pagination2
              currentPage={currentPage}
              setCurrentPageParams={setCurrentPageParams}
              total={total}
              limit={paginationLimit}
            />
          )}
        </div>
      </div>
      <table className="table table-sm table-hover ">
        <thead>
          <tr>
            <th />
            {renderHead}
          </tr>
          {isShowFilter && (
            <>
              <tr>
                <FilterRow2
                  columns={columns}
                  filters={filters}
                  onTableFilterChange={onFilterChange}
                  onApplyFilterClick={onApplyFilterClick}
                  options={filterOptions}
                />
              </tr>
              <tr>
                <GridFilterButtons
                  onApplyFilterClick={onApplyFilterClick}
                  onClearFilterClick={onClearFilterClick}
                  colSpanNumber={colSpanNumber}
                />
              </tr>
            </>
          )}
        </thead>
        <tbody>
          {renderBody}
          {itemsAddBuffer && renderBodyAddBuffer}
        </tbody>
      </table>
    </div>
  );
};

Panel.displayName = 'Panel';
