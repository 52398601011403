import * as React from 'react';
import { Component } from 'react';
import * as propz from 'propz';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Championship } from 'Src/models/championship';
import { AppUser } from 'Src/views/App/App';
import { AGE_GROUPS } from 'Src/consts/school';
import * as Moment from 'moment';
import DatePicker from 'react-datepicker';
import { GENDER, SPORT_GENDER_SERVER_TO_CLIENT_MAPPING, YES_NO_OPTIONS } from 'Src/consts/common';
import {
  TOURNAMENT_SUBTYPE,
  TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING,
  TOURNAMENT_TYPE,
  TOURNAMENT_TYPE_SERVER_TO_CLIENT_MAPPING
} from 'Src/consts/tournament';
import { Autocomplete } from 'Src/components/Autocomplete/Autocomplete';
import { getName, searchFunctionSports } from 'Src/helpers/autocomplete/autocomplete';
import { uploadImage } from 'Src/helpers/service/image';
import { Multiselect } from 'Src/components/Multiselect/Multiselect';
import { getBooleanForYesOrNo, getYesOrNoForBoolean } from 'Src/helpers/tournament/tournament';
import { getGenderOptionsBySport } from '../../../../../helpers/select/select';

interface Props {
  onCancel: () => void;
  onSubmit: (data: any) => void;
  championship?: Championship;
  user: AppUser;
}

interface State {
  picUrls: any[];
  isImageError: boolean;
}

const ChampionshipValidationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  ageGroups: Yup.string().required('Required'),
  startTime: Yup.date().required('Required'),
  endTime: Yup.string().required('Required'),
  sportId: Yup.string().required('Required'),
  organisersEmail: Yup.string().email('enter email address')
});

export class ChampionshipForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      picUrls: propz.get(this.props.championship, ['photos'], []),
      isImageError: false
    };
  }

  isChampionshipExists(): boolean {
    const { championship } = this.props;
    return typeof championship !== 'undefined';
  }

  getAgeGroup() {
    const user = this.props.user;
    const activeSchool = user.activeSchool;
    const ageGroupsNaming = activeSchool.ageGroupsNaming;
    const ageGroups = propz.get(AGE_GROUPS, [ageGroupsNaming]);
    return ageGroups.map((ageGroup, index) => {
      return {
        value: index,
        text: ageGroup
      };
    });
  }

  getSports = (text: string) => {
    const user = this.props.user;
    return searchFunctionSports(user, text);
  };

  onImageSelected = event => {
    const image = event.target.files[0];
    const oldPicUrls = [...this.state.picUrls];

    uploadImage(this.props.user, image)
      .then(response => {
        const picUrl = `${window.apiImg}/images/${response.key}`;

        oldPicUrls.push({ picUrl: picUrl });
        this.setState({
          picUrls: oldPicUrls,
          isImageError: false
        });
      })
      .catch(error => {
        console.error(error);
        this.setState({ isImageError: true });
      });
  };

  onRemoveImageClick = (event, i): void => {
    event.preventDefault();
    const newPicUrls = [...this.state.picUrls];
    newPicUrls.splice(i, 1);

    this.setState({
      picUrls: newPicUrls
    });
  };

  onSubmit = values => {
    const startDate = values.startTime;
    const endDate = values.endTime;
    const startDateUTC = new Date(startDate);
    const finishDateUTC = new Date(endDate);
    const dataCopy = {
      name: values.name,
      sportId: values.sportId,
      startTime: startDateUTC,
      endTime: finishDateUTC,
      ages: values.ageGroups.map(age => Number(age)),
      isAgeGroupsOnWebSiteVisible: getBooleanForYesOrNo(values.isAgeGroupsOnWebSiteVisible),
      type: values.tournamentType,
      subType: values.tournamentSubType,
      photos: this.state.picUrls,
      gender: values.gender,
      isMixedTeamsAvailable: getBooleanForYesOrNo(values.isMixedTeamsAvailable),
      isShowTwitter: getBooleanForYesOrNo(values.isShowTwitter),
      organisers: values.organisers,
      organisersEmail: values.organisersEmail
    };
    if (values.organisersEmail === '') {
      delete dataCopy.organisersEmail;
    }

    this.props.onSubmit(dataCopy);
  };

  renderTournamentSubTypeOptions(tournamentType) {
    let tournamentSybTypeOptions = [];

    switch (true) {
      case tournamentType === TOURNAMENT_TYPE.TEAM_TOURNAMENT:
        tournamentSybTypeOptions.push(
          <>
            <option key="subtype_option_football" value={TOURNAMENT_SUBTYPE.FOOTBALL}>
              {TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING.FOOTBALL}
            </option>
            <option key="subtype_option_cricket" value={TOURNAMENT_SUBTYPE.CRICKET}>
              {TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING.CRICKET}
            </option>
            <option key="subtype_option_sailing" value={TOURNAMENT_SUBTYPE.SAILING}>
              {TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING.SAILING}
            </option>
            <option key="subtype_option_rugby" value={TOURNAMENT_SUBTYPE.RUGBY}>
              {TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING.RUGBY}
            </option>
            <option key="subtype_option_netball" value={TOURNAMENT_SUBTYPE.NETBALL}>
              {TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING.NETBALL}
            </option>
            <option key="subtype_option_badminton" value={TOURNAMENT_SUBTYPE.BADMINTON}>
              {TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING.BADMINTON}
            </option>
          </>
        );
        break;
      case tournamentType === TOURNAMENT_TYPE.INDIVIDUAL_TOURNAMENT:
        tournamentSybTypeOptions.push(
          <>
            <option key="subtype_option_athletic_swimming" value={TOURNAMENT_SUBTYPE.ATHLETIC_SWIMMING}>
              {TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING.ATHLETIC_SWIMMING}
            </option>
            <option key="subtype_option_triathlon" value={TOURNAMENT_SUBTYPE.TRIATHLON}>
              {TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING.TRIATHLON}
            </option>
            <option key="subtype_option_national_triathlon" value={TOURNAMENT_SUBTYPE.NATIONAL_TRIATHLON}>
              {TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING.NATIONAL_TRIATHLON}
            </option>
            <option key="subtype_option_cross_country" value={TOURNAMENT_SUBTYPE.CROSS_COUNTRY}>
              {TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING.CROSS_COUNTRY}
            </option>
            <option key="subtype_option_national_cross_country" value={TOURNAMENT_SUBTYPE.NATIONAL_CROSS_COUNTRY}>
              {TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING.NATIONAL_CROSS_COUNTRY}
            </option>
            <option key="subtype_option_swimming" value={TOURNAMENT_SUBTYPE.ATHLETIC_SWIMMING}>
              {TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING.ATHLETIC_SWIMMING}
            </option>
          </>
        );
        break;
      case tournamentType === TOURNAMENT_TYPE.SINGLE_DOUBLE_TOURNAMENT:
        tournamentSybTypeOptions.push(
          <>
            <option key="subtype_option_badminton" value={TOURNAMENT_SUBTYPE.BADMINTON}>
              {TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING.BADMINTON}
            </option>
            <option key="subtype_option_fencing" value={TOURNAMENT_SUBTYPE.FENCING}>
              {TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING.FENCING}
            </option>
            <option key="subtype_option_tennis" value={TOURNAMENT_SUBTYPE.TENNIS}>
              {TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING.TENNIS}
            </option>
          </>
        );
        break;
      case tournamentType === TOURNAMENT_TYPE.MINI_TEAM_TOURNAMENT:
        tournamentSybTypeOptions.push(
          <option key="subtype_option_badminton" value={TOURNAMENT_SUBTYPE.BADMINTON_MT}>
            {TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING.BADMINTON_MT}
          </option>
        );
        break;
    }
    return tournamentSybTypeOptions;
  }

  render() {
    const { isImageError, picUrls } = this.state;
    const { championship } = this.props,
      isChampionshipExists = this.isChampionshipExists(),
      positiveButtonTitle = isChampionshipExists ? 'Save championship' : 'Create championship',
      imgInputClassName = isImageError ? 'form-control-file is-invalid' : 'form-control-file';
    const isPicUrlExists = picUrls.length > 0;

    const championshipInitial = {
      name: isChampionshipExists ? propz.get(championship, ['name'], '') : '',
      ageGroups: isChampionshipExists ? propz.get(championship, ['ages'], []) : '',
      gender: isChampionshipExists ? propz.get(championship, ['gender'], '') : '',
      startTime: isChampionshipExists ? new Date(championship.startTime) : new Date(),
      endTime: isChampionshipExists ? new Date(championship.endTime) : new Date(),
      isAgeGroupsOnWebSiteVisible: getYesOrNoForBoolean(
        propz.get(championship, ['isAgeGroupsOnWebSiteVisible'], false)
      ),
      isMixedTeamsAvailable: getYesOrNoForBoolean(propz.get(championship, ['isMixedTeamsAvailable'], false)),
      isShowTwitter: getYesOrNoForBoolean(propz.get(championship, ['isShowTwitter'], false)),
      tournamentType: isChampionshipExists
        ? propz.get(championship, ['type'], TOURNAMENT_TYPE.TEAM_TOURNAMENT)
        : TOURNAMENT_TYPE.TEAM_TOURNAMENT,
      tournamentSubType: isChampionshipExists
        ? propz.get(championship, ['subType'], TOURNAMENT_SUBTYPE.FOOTBALL)
        : TOURNAMENT_SUBTYPE.FOOTBALL,
      sport: propz.get(championship, ['sport'], undefined),
      sportId: propz.get(championship, ['sportId'], ''),
      organisers: isChampionshipExists ? propz.get(championship, ['organisers'], '') : '',
      organisersEmail: isChampionshipExists ? propz.get(championship, ['organisersEmail'], '') : ''
    };

    return (
      <Formik
        onSubmit={value => {
          this.onSubmit(value);
        }}
        initialValues={championshipInitial}
        validationSchema={ChampionshipValidationSchema}
        render={({ values, setFieldValue, touched, errors }) => (
          <Form>
            <div className="form-group">
              <label>Name championship</label>
              <Field
                type="text"
                name="name"
                className={`${touched.name && errors.name ? 'form-control is-invalid' : 'form-control '}`}
                placeholder="Enter name championship"
              />
              {touched.name && errors.name ? <div className="alert alert-danger">{errors.name}</div> : null}
            </div>

            <div className="form-group">
              <label>Sport</label>
              <Field
                name="sport"
                render={({ field }) => {
                  return (
                    <Autocomplete
                      searchFunction={this.getSports}
                      getElementTitle={getName}
                      customClass="mFullWidth mb-3"
                      defaultItem={values.sport}
                      onSelect={sport => {
                        setFieldValue('sportId', sport.id);
                        setFieldValue('sport', sport);

                        switch (true) {
                          case sport.genders.maleOnly:
                            setFieldValue('gender', GENDER.MALE_ONLY);
                            break;
                          case sport.genders.femaleOnly:
                            setFieldValue('gender', GENDER.FEMALE_ONLY);
                            break;
                          case sport.genders.mixed:
                            setFieldValue('gender', GENDER.MIXED);
                            break;
                        }
                      }}
                    />
                  );
                }}
              />
              {touched.sportId && errors.sportId ? <div className="alert alert-danger">{errors.sportId}</div> : null}
            </div>
            <label>Gender</label>
            <Field disabled={!values.sport} component="select" name="gender" className="form-control mb-3">
              {getGenderOptionsBySport(values.sport)}
            </Field>

            <label>Mixed teams</label>
            <Field
              disabled={values.gender !== GENDER.MIXED}
              component="select"
              name="isMixedTeamsAvailable"
              className="form-control mb-3"
            >
              <option value={YES_NO_OPTIONS.NO}>{YES_NO_OPTIONS.NO}</option>
              <option value={YES_NO_OPTIONS.YES}>{YES_NO_OPTIONS.YES}</option>
            </Field>

            <div className="form-group">
              <label>Ages</label>
              <Field
                name="ageGroups"
                render={({ field }) => {
                  return (
                    <Multiselect
                      items={this.getAgeGroup()}
                      onChange={options => {
                        setFieldValue('ageGroups', options);
                      }}
                      value={field.value || []}
                    />
                  );
                }}
              />
              {touched.ageGroups && errors.ageGroups ? (
                <div className="alert alert-danger">{errors.ageGroups}</div>
              ) : null}
            </div>
            <div className="form-group">
              <label>Start time</label>
              <Field
                name="startTime"
                render={({ field }) => {
                  let startTime;
                  if (Moment(values.startTime, 'DD-MM-YYYY HH:mm aa').isValid()) {
                    startTime = Moment(values.startTime, 'DD-MM-YYYY HH:mm aa').toDate();
                  }
                  return (
                    <div className="mb-3">
                      <DatePicker
                        selected={startTime}
                        onChange={startTime => {
                          setFieldValue('startTime', startTime);
                        }}
                        className="form-control"
                        timeInputLabel="Time: "
                        showTimeSelect={true}
                        dateFormat={'dd-MM-yyyy hh:mm:a'}
                      />
                    </div>
                  );
                }}
              />
              {touched.startTime && errors.startTime ? (
                <div className="alert alert-danger">{errors.startTime}</div>
              ) : null}
            </div>
            <div className="form-group">
              <label>End time</label>
              <Field
                name="endTime"
                render={({ field }) => {
                  let endTime;
                  if (Moment(values.endTime, 'DD-MM-YYYY HH:mm aa').isValid()) {
                    endTime = Moment(values.endTime, 'DD-MM-YYYY HH:mm aa').toDate();
                  }
                  return (
                    <div className="mb-3">
                      <DatePicker
                        selected={endTime}
                        onChange={endTime => {
                          setFieldValue('endTime', endTime);
                        }}
                        className="form-control"
                        timeInputLabel="Time: "
                        showTimeSelect={true}
                        dateFormat={'dd-MM-yyyy hh:mm a'}
                      />
                    </div>
                  );
                }}
              />
              {touched.endTime && errors.endTime ? <div className="alert alert-danger">{errors.endTime}</div> : null}
            </div>

            <label>Show age groups on website</label>
            <Field component="select" name="isAgeGroupsOnWebSiteVisible" className="form-control mb-3">
              <option value={YES_NO_OPTIONS.NO}>{YES_NO_OPTIONS.NO}</option>
              <option value={YES_NO_OPTIONS.YES}>{YES_NO_OPTIONS.YES}</option>
            </Field>

            <label>Type of tournament</label>
            <Field
              component="select"
              name="tournamentType"
              className="form-control mb-3"
              onChange={event => {
                const tournamentType = event.target.value;
                setFieldValue('tournamentType', tournamentType);

                switch (tournamentType) {
                  case TOURNAMENT_TYPE.TEAM_TOURNAMENT:
                    setFieldValue('tournamentSubType', TOURNAMENT_SUBTYPE.FOOTBALL);
                    break;
                  case TOURNAMENT_TYPE.INDIVIDUAL_TOURNAMENT:
                    setFieldValue('tournamentSubType', TOURNAMENT_SUBTYPE.ATHLETIC_SWIMMING);
                    break;
                  case TOURNAMENT_TYPE.SINGLE_DOUBLE_TOURNAMENT:
                    setFieldValue('tournamentSubType', TOURNAMENT_SUBTYPE.BADMINTON);
                    break;
                  case TOURNAMENT_TYPE.MINI_TEAM_TOURNAMENT:
                    setFieldValue('tournamentSubType', TOURNAMENT_SUBTYPE.BADMINTON_MT);
                    break;
                }
              }}
            >
              <option value={TOURNAMENT_TYPE.TEAM_TOURNAMENT}>
                {TOURNAMENT_TYPE_SERVER_TO_CLIENT_MAPPING.TEAM_TOURNAMENT}
              </option>
              <option value={TOURNAMENT_TYPE.INDIVIDUAL_TOURNAMENT}>
                {TOURNAMENT_TYPE_SERVER_TO_CLIENT_MAPPING.INDIVIDUAL_TOURNAMENT}
              </option>
              <option value={TOURNAMENT_TYPE.SINGLE_DOUBLE_TOURNAMENT}>
                {TOURNAMENT_TYPE_SERVER_TO_CLIENT_MAPPING.SINGLE_DOUBLE_TOURNAMENT}
              </option>
              <option value={TOURNAMENT_TYPE.MINI_TEAM_TOURNAMENT}>
                {TOURNAMENT_TYPE_SERVER_TO_CLIENT_MAPPING.MINI_TEAM_TOURNAMENT}
              </option>
            </Field>
            <label>Sub Type of tournament</label>
            <Field component="select" name="tournamentSubType" className="form-control mb-3">
              {this.renderTournamentSubTypeOptions(values.tournamentType)}
            </Field>

            <label>Show twitter feed on public site (individual tournament)</label>
            <Field component="select" name="isShowTwitter" className="form-control mb-3">
              <option value={YES_NO_OPTIONS.NO}>{YES_NO_OPTIONS.NO}</option>
              <option value={YES_NO_OPTIONS.YES}>{YES_NO_OPTIONS.YES}</option>
            </Field>

            <div className="form-group">
              <label>Organisers</label>
              <Field
                type="text"
                name="organisers"
                className={`${touched.organisers && errors.organisers ? 'form-control is-invalid' : 'form-control '}`}
                placeholder="Enter organisers"
              />
              {touched.organisers && errors.organisers ? (
                <div className="invalid-feedback">{errors.organisers}</div>
              ) : null}
            </div>

            <div className="form-group">
              <label>Organisers email</label>
              <Field
                type="email"
                name="organisersEmail"
                className={`${
                  touched.organisersEmail && errors.organisersEmail ? 'form-control is-invalid' : 'form-control '
                }`}
                placeholder="Enter organisers email"
              />
              {touched.organisersEmail && errors.organisersEmail ? (
                <div className="invalid-feedback">{errors.organisersEmail}</div>
              ) : null}
            </div>

            <div className="form-group">
              <label>Photos</label>
              <input type="file" name="picUrl" className={imgInputClassName} onChange={this.onImageSelected} />
              {isImageError && <div className="invalid-feedback">Error uploading image</div>}
            </div>
            {isPicUrlExists &&
              this.state.picUrls.map((picUrl, index) => {
                return (
                  <div className="mMaxWidth200px">
                    <button type="button" className="close" onClick={event => this.onRemoveImageClick(event, index)}>
                      <span>&times;</span>
                    </button>
                    <img className="img-fluid img-thumbnail" src={picUrl.picUrl} alt="News picture" />
                  </div>
                );
              })}

            <button type="button" className="btn btn-secondary mr-3" onClick={this.props.onCancel}>
              Cancel
            </button>
            <button type="submit" className="btn btn-primary">
              {positiveButtonTitle}
            </button>
          </Form>
        )}
      />
    );
  }
}
