import * as React from 'react';
import * as BPromise from 'bluebird';
import * as Lazy from 'lazy.js';
import * as propz from 'propz';
import * as Moment from 'moment';
import { parse } from 'query-string';
import { AppUser } from 'Src/views/App/App';
import {
  ColumnDefinition,
  getFilters2,
  getOrder,
  getPagesCount,
  getSearchFilter,
  getSearchOrder,
  getServerFieldSectionWhere,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE, LIMIT } from 'Src/consts/table';
import { Loader } from 'Src/components/Loader/Loader';
import { getAllForms } from 'Src/helpers/service/admin/forms';
import { sortAges } from 'Src/helpers/event/event';
import {
  getClubAcademicYear,
  getClubAges,
  getAgesGroupFormatted,
  getClubCoaches,
  getClubGender,
  getClubMembersOfStaff,
  getClubRecurrence,
  getClubStartDate,
  getClubStartTime,
  getClubStatus,
  getClubWeekDays
} from 'Src/helpers/accessor/accessor';
import { History, Location } from 'history';
import { Grid2 } from 'Src/components/Grid/Grid2';
import { Club, ClubParticipantsExtended } from 'Src/models/club';
import {
  activateClub,
  deleteClub,
  getAllClubs,
  getClubs,
  getClubsCount,
  upcomingEvents,
  updateClub,
  removeUpcomingEventsForClub,
  UpcomingEventsResponse
} from 'Src/helpers/service/admin/clubs';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { ClubForm } from './ClubForm/ClubForm';
import { ClubActivateSummary, DRAFT_TEXT } from './ClubActivateSummary/ClubActivateSummary';
import {
  CLUB_INVITATIONS,
  CLUB_INVITATIONS_SERVER_TO_CLIENT_MAPPING,
  CLUB_MESSAGE_INVITE_STATUS_ALL,
  CLUB_STATUS,
  CLUB_TABS_ARRAY,
  CLUB_TABS,
  CLUB_TABS_FOR_SCHOOL_WITHOUT_PAYMENT
} from 'Src/consts/club';
import { sendClubMessages } from 'Src/helpers/service/admin/clubMessages';
import { getAllClubStudents, getClubConfirmationRequestTemplate } from 'Src/helpers/service/admin/clubStudents';
import {
  getSelectOptionForAge,
  getSelectOptionForClubAcademicYears,
  getSelectOptionForClubGender,
  getSelectOptionForClubRecurrence,
  getSelectOptionForClubStatus,
  getSelectOptionForClubWeekDays
} from 'Src/helpers/table/select';
import { ResizablePanel } from 'Src/components/ResizablePanel/ResizablePanel';
import { Button } from 'Src/components/Button/Button';
import { GenericSummary } from 'Src/components/GenericSummary/GenericSummary';
import { ClubSummary } from './ClubSummary/ClubSummary';
import { ClubParticipantsSummary } from './ClubParticipantsSummary/ClubParticipantsSummary';
import { getAllClubParticipants, getClubEvents, getClubPayments } from 'Src/helpers/service/admin/clubParticipants';
import './Clubs.css';
import { ClubPaymentsSummary } from './ClubPaymentsSummary/ClubPaymentsSummary';
import { Transaction } from 'Src/models/transaction';
import { getClubInvitations } from 'Src/helpers/club/club';
import { ConfirmationRequestTemplate, ConfirmationRequestTemplateField } from 'Src/models/confirmationRequestTemplate';
import {
  ConfirmationRequestForm,
  ConfirmationRequestFormField
} from 'Src/components/ConfirmationRequestForm/ConfirmationRequestForm';
import { UK_INPUT_MASK_VALIDATION, UK_INPUT_MASK } from 'Src/consts/date';
import { sortPlayersByLastNameAsc } from 'Src/helpers/players/players';
import { uploadReport } from 'Src/helpers/service/admin/report';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/report';
import { MODE_TYPE } from '../../../../../consts/common';
import { AGE_GROUPS_SORTED } from '../../../../../consts/school';
import { ClubPaymentSettingsSummary } from './ClubPaymentSettingsSummary/ClubPaymentSettingsSummary';
import { SchoolForm } from 'Src/models/form';
import MaskedInput from 'react-text-mask';
import { INVITE_KIND } from 'Src/consts/invite';
import { INVITE_KIND_TYPE } from 'Src/types/invite';
import { AggregateClubParticipationReportForm } from './Reports/AggregateClubParticipationReportForm/AggregateClubParticipationReportForm';
import { ClubAttendanceReportForm } from './Reports/ClubAttendanceReportForm/ClubAttendanceReportForm';
import { ParticipationsByClubsReportForm } from './Reports/ParticipationsByClubsReportForm/ParticipationsByClubsReportForm';
import { ClubParticipationByStudentsReportForm } from './Reports/ClubParticipationByStudentsReportForm/ClubParticipationByStudentsReportForm';
import { ClubListByStudentsReportForm } from './Reports/ClubListByStudentsReportForm/ClubListByStudentsReportForm';
import { ClubAttendanceByStudentsReportForm } from './Reports/ClubAttendanceByStudentsReportForm/ClubAttendanceByStudentsReportForm';
import { ParticipationsByClubsYearGroupsReportForm } from './Reports/ParticipationsByClubsYearGroupsReportForm/ParticipationsByClubsYearGroupsReportForm';
import { ClubBookingsReportForm } from './Reports/ClubBookingsReportForm/ClubBookingsReportForm';
import { SelectedClubParticipationByStudentsReportForm } from './Reports/SelectedClubParticipationByStudentsReportForm/SelectedClubParticipationByStudentsReportForm';
import { DateClubsReportForm } from './Reports/DateClubsReportForm/DateClubsReportForm';
import { ClubParticipationByClubNumberReportForm } from './Reports/ClubParticipationByClubNumberReportForm/ClubParticipationByClubNumberReportForm';
import { WeeklyClubScheduleReportForm } from './Reports/WeeklyClubScheduleReportForm/WeeklyClubScheduleReportForm';
import { AverageClubAttendanceReportForm } from './Reports/AverageClubAttendanceReportForm/AverageClubAttendanceReportForm';
import { PaperAttendanceRegisterClubReportForm } from './Reports/PaperAttendanceRegisterClubReportForm/PaperAttendanceRegisterClubReportForm';
import { isUserTeacher, isUserCoach } from 'Src/helpers/user/user';
import { isThisWeek } from 'date-fns';

export const DEFAULT_CLUB_TABLE_FILTERS = `status=${CLUB_STATUS.ACTIVE}&status=${CLUB_STATUS.DRAFT}`;

const DEFAULT_CLUB_SEARCH = {
  status: [CLUB_STATUS.ACTIVE, CLUB_STATUS.DRAFT]
};

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Name',
    field: 'name',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['name']
  },
  {
    text: 'Academic year',
    field: 'clubAcademicYear',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getClubAcademicYear
  },
  {
    text: 'Start date',
    field: 'clubStartDate',
    isSort: true,
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: getClubStartDate
  },
  {
    text: 'Gender',
    field: 'gender',
    isSort: true,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getClubGender
  },
  {
    text: 'Ages',
    field: 'clubAges',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getAgesGroupFormatted
  },
  {
    text: 'Leader/Coach',
    field: 'clubCoaches',
    isSort: false,
    type: FILTER_TYPE.TEXT,
    accessor: getClubCoaches
  },
  {
    text: 'Member of staff',
    field: 'clubMembersOfStaff',
    isSort: false,
    type: FILTER_TYPE.TEXT,
    accessor: getClubMembersOfStaff
  },
  {
    text: 'Week days',
    field: 'clubWeekDays',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getClubWeekDays
  },
  {
    text: 'Recurrence',
    field: 'clubRecurrence',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getClubRecurrence
  },
  {
    text: 'Start time',
    field: 'clubStartTime',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getClubStartTime
  },
  {
    text: 'Status',
    field: 'status',
    isSort: true,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getClubStatus
  }
];

interface State {
  items: Club[];
  currentPage: number;
  selectedItems: Club[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  filters: any;
  isDataFiltered: boolean;
  isLoading: boolean;
  totalWithFilters: number;
  total: number;
  basePath: string;
  isSelectAllChecked: boolean;
  isDeleteClubModalOpen: boolean;
  currentTabIndex: number;
  clubParticipants: ClubParticipantsExtended[];
  clubEvents: any[];
  forms: SchoolForm[];
  isActivateClubModalOpen: boolean;
  isSendInvitationsModalOpen: boolean;
  allClubsStudents: any[];
  clubPayments: Transaction[];
  isErrorInPayments: boolean;
  errorMessageInPayments: string;
  confirmationRequestTemplate: ConfirmationRequestTemplate;
  isConfirmationRequestTemplateModalOpen: boolean;
  fields: ConfirmationRequestFormField[];
  deadlineForAnswers: string;

  isAggregateClubParticipationReportModalOpen: boolean; //001
  isClubAttendanceReportModalOpen: boolean; //002
  isParticipationByClubsReportModalOpen: boolean; //003
  isClubParticipationByStudentsReportModalOpen: boolean; //004
  isSelectedClubParticipationByStudentsReportModalOpen: boolean; //037
  isClubListByStudentsReportModalOpen: boolean; //016
  isClubAttendanceByStudentsReportModalOpen: boolean; //005
  isParticipationsByClubsYearGroupsReportModalOpen: boolean; //006
  isClubBookingsReportModalOpen: boolean; //013
  isWeeklyClubScheduleReportModalOpen: boolean; //022
  isDateClubsReportModalOpen: boolean; //020
  isClubParticipationByCLubNumberReportModalOpen: boolean; //045
  isAverageClubAttendanceReportModalOpen: boolean; //065
  isPaperAttendanceRegisterClubReportModalOpen: boolean; //069

  errorMessage: string;
  isErrorModalOpen: boolean;
  isArchiveClubsModalOpen: boolean;
  upcomingEventsExists: { isUpcomingEventsExist: boolean; count: number }[];
  isCreateClubsDisabledModalOpen: boolean;
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export class Clubs extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      filters: {},
      isDataFiltered: false,
      isLoading: false,
      totalWithFilters: 0,
      total: 0,
      basePath: '',
      isSelectAllChecked: false,
      isDeleteClubModalOpen: false,
      currentTabIndex: 0,
      clubParticipants: [],
      clubEvents: [],
      isActivateClubModalOpen: false,
      isSendInvitationsModalOpen: false,
      allClubsStudents: [],
      forms: [],
      clubPayments: [],
      isErrorInPayments: false,
      errorMessageInPayments: '',
      confirmationRequestTemplate: undefined,
      isConfirmationRequestTemplateModalOpen: false,
      deadlineForAnswers: '',
      fields: [],

      isAggregateClubParticipationReportModalOpen: false,
      isClubAttendanceReportModalOpen: false,
      isParticipationByClubsReportModalOpen: false,
      isClubParticipationByStudentsReportModalOpen: false,
      isSelectedClubParticipationByStudentsReportModalOpen: false,
      isClubListByStudentsReportModalOpen: false,
      isClubAttendanceByStudentsReportModalOpen: false,
      isParticipationsByClubsYearGroupsReportModalOpen: false,
      isClubBookingsReportModalOpen: false,
      isWeeklyClubScheduleReportModalOpen: false,
      isDateClubsReportModalOpen: false,
      isClubParticipationByCLubNumberReportModalOpen: false,
      isAverageClubAttendanceReportModalOpen: false,
      isPaperAttendanceRegisterClubReportModalOpen: false,

      errorMessage: '',
      isErrorModalOpen: false,
      isArchiveClubsModalOpen: false,
      upcomingEventsExists: [] as UpcomingEventsResponse[],
      isCreateClubsDisabledModalOpen: false
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setAdditionalItems().then(res => {
      this.setItems();
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  setAdditionalItems() {
    const { user } = this.props;

    return getAllForms(user).then(forms => {
      this.setState({
        forms
      });
    });
  }

  setItems() {
    const { history, location, user } = this.props;

    const userId = propz.get(user, ['userId']);

    const isUserTeacherOrCoach = isUserTeacher(user) || isUserCoach(user);

    const isCoachHasAccessOnlyToAffectedEvents = propz.get(user, ['activeSchool', 'isCoachSeenOnlyAffectedEvents']);
    const isTeacherHasAccessOnlyToAffectedEvents = propz.get(user, ['activeSchool', 'isTeacherSeenOnlyAffectedEvents']);

    const userPermissionSports = propz.get(user, ['activePermission', 'sports'], []);
    const userPermissionSportsIds = userPermissionSports.map(sport => sport.id);
    const isUserPermissionSportsExist = userPermissionSports.length > 0;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);
    const defaultFilters = getFilters2(COLUMNS, DEFAULT_CLUB_SEARCH);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters);
    const defaultWhere = getServerFieldSectionWhere(defaultFilters);

    switch (true) {
      case isUserTeacherOrCoach && isUserPermissionSportsExist:
        propz.set(where, ['sportId'], {
          $in: userPermissionSportsIds
        });

        propz.set(defaultWhere, ['sportId'], {
          $in: userPermissionSportsIds
        });

      case isUserCoach(user) && isCoachHasAccessOnlyToAffectedEvents:
        propz.set(where, ['staff.userId'], {
          $in: [userId]
        });

        propz.set(defaultWhere, ['staff.userId'], {
          $in: [userId]
        });

      case isUserTeacher(user) && isTeacherHasAccessOnlyToAffectedEvents:
        propz.set(where, ['staff.userId'], {
          $in: [userId]
        });

        propz.set(defaultWhere, ['staff.userId'], {
          $in: [userId]
        });
    }

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const getItemsPromise = getClubs(user, serverQueryFilter);
    const getItemsCountPromise = getClubsCount(user, where);
    const getItemsTotalCountPromise = getClubsCount(user, defaultWhere);

    const promises = [getItemsCountPromise, getItemsTotalCountPromise, getItemsPromise];

    const tabs = this.getTabs();
    const clubId = propz.get(location, ['state', 'clubId']);
    const currentTabIndex = propz.get(location, ['state', 'currentTabIndex'], 0);

    let totalWithFiltersObj;
    let totalObj;
    let items;

    return BPromise.all(promises)
      .then(([_totalWithFiltersObj, _totalObj, _items]) => {
        totalWithFiltersObj = _totalWithFiltersObj;
        totalObj = _totalObj;
        items = _items;

        if (tabs[currentTabIndex] === CLUB_TABS.PARTICIPANT) {
          return getAllClubParticipants(user, clubId);
        }
      })
      .then(participants => {
        if (typeof clubId !== 'undefined') {
          const club = items.find(item => item.id === clubId);
          const isParticipantsExist = typeof participants !== 'undefined';
          const sortParticipants = isParticipantsExist ? [...participants].sort(sortPlayersByLastNameAsc) : [];

          this.setState({
            currentPage: page,
            sortDirection: sortDirection,
            sortColumnsName: sortColumnsName,
            isShowFilter: isShowFilter,
            isDataFiltered: isDataFiltered,
            filters: filters,
            items: items,
            totalWithFilters: totalWithFiltersObj.count,
            total: totalObj.count,
            basePath: history.location.pathname,
            isLoading: false,
            selectedItems: typeof club !== 'undefined' ? [club] : [],
            currentTabIndex,
            clubParticipants: sortParticipants
          });

          const state = { ...(history.location.state as any) };
          delete state.clubId;
          history.replace({ ...history.location, state });
        } else {
          this.setState({
            currentPage: page,
            sortDirection: sortDirection as TABLE_SORT_DIRECTION,
            sortColumnsName: sortColumnsName,
            isShowFilter: isShowFilter,
            isDataFiltered: isDataFiltered,
            filters: filters,
            items: items,
            totalWithFilters: totalWithFiltersObj.count,
            total: totalObj.count,
            basePath: history.location.pathname,
            currentTabIndex,
            isLoading: false
          });
        }
        return true;
      });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated,
      currentTabIndex: 0
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: nextSelectedItems,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    getAllClubs(user, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const nextSelectedItems = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: nextSelectedItems,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (filterField === 'forms') {
      nextFilters.ages = [];
    }

    if (filterField === 'ages') {
      nextFilters.forms = [];
    }

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: DEFAULT_CLUB_TABLE_FILTERS
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  renderDeleteClubModal(): React.ReactNode {
    const { selectedItems, isDeleteClubModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isDeleteClubModalOpen}
        title={'Delete club'}
        body={`Are you sure you want to remove ${
          selectedItems.length === 1 ? `club ${selectedItems[0].name}` : `${selectedItems.length} clubs`
        }?`}
        buttonText={'Delete'}
        onButtonClick={this.onDeleteClubClick}
        onCloseClick={this.deleteClubModalClose}
      />
    );
  }

  onDeleteClubClick = () => {
    const { selectedItems } = this.state;
    const user = this.props.user;
    const clubIdList = selectedItems.map(item => item.id);

    this.setState({
      isLoading: true
    });

    BPromise.all(clubIdList.map(clubId => deleteClub(user, clubId))).then(res => {
      this.setState({
        isDeleteClubModalOpen: false,
        isSelectAllChecked: false,
        selectedItems: []
      });
      this.setItems();
    });
  };

  deleteClubModalClose = (): void => {
    this.setState({
      isDeleteClubModalOpen: false
    });
  };

  renderActivateClubModal(): React.ReactNode {
    const { selectedItems, isActivateClubModalOpen } = this.state;

    const error = selectedItems.some(club => {
      const { participants, maxParticipants } = club;
      const numberParticipants = participants.length;
      const maxParticipantsEqualZero = maxParticipants === 0;
      const numberParticipantsGreaterThanMaxParticipants = numberParticipants > maxParticipants;
      return numberParticipantsGreaterThanMaxParticipants && !maxParticipantsEqualZero;
    });

    const infoText = selectedItems.map(club => {
      const { name, participants, maxParticipants } = club;
      const numberParticipants = participants.length;
      const maxParticipantsEqualZero = maxParticipants === 0;
      const maxParticipantsText = maxParticipantsEqualZero ? 'Unlimited' : `${maxParticipants}`;
      const spacesAvailable = maxParticipants - numberParticipants;
      const spacesAvailableText = maxParticipantsEqualZero ? 'Unlimited' : `${spacesAvailable}`;

      return `Club name: ${name} \nMax participants: ${maxParticipantsText} \nParticipants: ${numberParticipants} \nSpaces available: ${spacesAvailableText} \n`;
    });

    let additionalText;

    if (!error) {
      additionalText = `\n${DRAFT_TEXT[0]} \n${DRAFT_TEXT[1]}`;
    } else {
      additionalText = `\nPlease, check club settings`;
    }

    return (
      <SimpleModal
        isOpen={isActivateClubModalOpen}
        title={selectedItems.length === 1 ? 'Activate club' : 'Activate clubs'}
        body={`${infoText.join('\n')} ${additionalText}`}
        buttonText={error ? undefined : 'Activate'}
        onButtonClick={selectedItems.length > 1 ? this.onActivateClubsClick : this.onActivateOneClubClick}
        onCloseClick={this.activateClubModalClose}
      />
    );
  }

  renderArchiveClubsModal(): React.ReactNode {
    const { selectedItems, isArchiveClubsModalOpen, upcomingEventsExists } = this.state;

    if (selectedItems.length !== upcomingEventsExists.length) {
      return null;
    }

    const clubsWithEvents = selectedItems
      .filter((club, index) => upcomingEventsExists[index].isUpcomingEventsExist)
      .map((club, index) => {
        const { name } = club;
        const eventCount = upcomingEventsExists[index].count;
        return `Club name: ${name} (${eventCount} active events)`;
      });

    const clubsWithoutEvents = selectedItems
      .filter((club, index) => !upcomingEventsExists[index].isUpcomingEventsExist)
      .map((club, index) => {
        const { name } = club;
        return `Club name: ${name} (without active events)`;
      });

    const headerText = 'You are going to archive a club/clubs.';
    const footerText = `${
      clubsWithEvents.length > 0 ? 'After archiving, all future events will be deleted.' : ''
    } Are you sure?`;

    return (
      <SimpleModal
        isOpen={isArchiveClubsModalOpen}
        title={selectedItems.length === 1 ? 'Archive club' : 'Archive clubs'}
        body={`${headerText} \n${clubsWithEvents.join('\n')} \n${clubsWithoutEvents.join('\n')} \n${footerText}`}
        buttonText={'Archive'}
        onButtonClick={this.onArchiveClubsClick}
        onCloseClick={this.archiveClubModalClose}
      />
    );
  }

  onArchiveClubsClick = async (): Promise<void> => {
    const { user } = this.props;
    const { selectedItems } = this.state;

    this.setState({
      isLoading: true
    });

    const results = await BPromise.allSettled(
      selectedItems.map(async club => {
        const clubId = club.id;

        try {
          await updateClub(user, { status: CLUB_STATUS.ARCHIVED }, clubId);
        } catch (error) {
          console.error('Error during archiving club:', error);
        }
        try {
          await removeUpcomingEventsForClub(user, clubId);
        } catch (error) {
          console.error('Error during removing upcoming events for club:', error);
        }
      })
    );

    this.setState({
      isArchiveClubsModalOpen: false,
      isSelectAllChecked: false,
      selectedItems: [],
      isLoading: false
    });
    this.setItems();
  };

  onActivateClubsClick = (): void => {
    const { user } = this.props;
    const { selectedItems } = this.state;

    BPromise.all(
      selectedItems.map(club => {
        const clubId = club.id;
        return activateClub(user, clubId);
      })
    ).then(res => {
      this.setState({
        isActivateClubModalOpen: false,
        isSelectAllChecked: false,
        selectedItems: []
      });

      this.setItems();
    });
  };

  onActivateOneClubClick = (): void => {
    const { user } = this.props;
    const { selectedItems } = this.state;
    const club = selectedItems[0];
    const clubId = club.id;
    this.setState({
      isLoading: true
    });
    let clubUpdated;
    activateClub(user, clubId)
      .then(clubData => {
        clubUpdated = clubData.club;
        return getClubEvents(user, clubId);
      })
      .then(events => {
        this.setState({
          isActivateClubModalOpen: false,
          isSelectAllChecked: false,
          selectedItems: [clubUpdated],
          currentTabIndex: 2,
          clubEvents: events
        });
        this.setItems();
      });
  };

  activateClubModalClose = (): void => {
    this.setState({
      isActivateClubModalOpen: false
    });
  };

  archiveClubModalClose = (): void => {
    this.setState({
      isArchiveClubsModalOpen: false
    });
  };

  renderSendInvitationsClubModal() {
    const { user } = this.props;
    const { deadlineForAnswers, selectedItems, isSendInvitationsModalOpen, allClubsStudents } = this.state;

    const { activeSchool } = user;
    const { clubInvitations } = activeSchool;

    if (!isSendInvitationsModalOpen) {
      return null;
    }

    const isPaymentsEnabled = propz.get(user, ['activeSchool', 'isPaymentsEnabled'], false);
    const isAllClubsAskToPayFieldActive = selectedItems.every(item => {
      const isAskToPay = propz.get(item, ['paymentSettings', 'askToPay'], false);

      return isAskToPay;
    });

    const clubInfoText = selectedItems.map((club, index) => {
      const { name } = club;
      const clubStudents = allClubsStudents[index];
      const numberStudents = clubStudents.length;
      const invitations = getClubInvitations(clubInvitations, club);
      const requiredForBook = propz.get(club, ['paymentSettings', 'requiredForBook'], false);
      const price = propz.get(club, ['price', 'price'], 0);

      let contacts, numberContacts;

      switch (invitations) {
        case CLUB_INVITATIONS.STUDENT:
          contacts = clubStudents.filter(student => {
            const { messageStatus } = student;
            const isMessageStatusPending = messageStatus === CLUB_MESSAGE_INVITE_STATUS_ALL.PENDING;
            const isMessageStatusNotSent = messageStatus === CLUB_MESSAGE_INVITE_STATUS_ALL.NOT_SENT;

            return typeof student.email !== 'undefined' && (isMessageStatusPending || isMessageStatusNotSent);
          });
          numberContacts = contacts.length;
          break;
        case CLUB_INVITATIONS.PARENT:
          contacts = clubStudents.filter(student => {
            const { messageStatus } = student;
            const isMessageStatusPending = messageStatus === CLUB_MESSAGE_INVITE_STATUS_ALL.PENDING;
            const isMessageStatusNotSent = messageStatus === CLUB_MESSAGE_INVITE_STATUS_ALL.NOT_SENT;

            return (
              Array.isArray(student.parents) &&
              student.parents.length > 0 &&
              (isMessageStatusPending || isMessageStatusNotSent)
            );
          });
          const parents = Lazy(contacts)
            .map(contact => contact.parents)
            .flatten()
            .toArray();
          numberContacts = parents.length;
          break;
      }

      let recipientsText;

      switch (invitations) {
        case CLUB_INVITATIONS.PARENT:
          recipientsText = CLUB_INVITATIONS_SERVER_TO_CLIENT_MAPPING.PARENT;
          break;
        case CLUB_INVITATIONS.STUDENT:
          recipientsText = CLUB_INVITATIONS_SERVER_TO_CLIENT_MAPPING.STUDENT;
          break;
      }

      return (
        <div key={index} className="mb-2">
          <p className="font-weight-bold mb-0">
            Club name: <span className="font-weight-normal">{name}</span>
          </p>
          <p className="font-weight-bold mb-0">
            Eligible students: <span className="font-weight-normal">{numberStudents}</span>
          </p>
          <p className="font-weight-bold mb-0">
            Number of contacts: <span className="font-weight-normal">{numberContacts}</span>
          </p>
          <p className="font-weight-bold mb-0">
            Invitations will be sent to: <span className="font-weight-normal">{recipientsText}</span>
          </p>
          <p className="font-weight-bold mb-0">
            Price: <span className="font-weight-normal">£ {price}</span>
          </p>

          <hr />
        </div>
      );
    });

    return (
      <SimpleModal
        isOpen={isSendInvitationsModalOpen}
        title={'Sending invitations'}
        buttonText={'Send invitations'}
        onButtonClick={this.onSendInvitationsClick}
        onCloseClick={this.sendInvitationsModalClose}
      >
        <div className="font-weight-bold">
          <p>You are going to send booking forms to parents or students depending on your school settings.</p>
          <p>
            Please be aware that invitations will be sent to contacts linked to all eligible students. If you need to
            amend the recepients list for a particular club you can do it from that club dedicated page.
          </p>
        </div>

        <hr />

        {clubInfoText}

        <div className="mt-4 mb-2">Deadline for answers (optional)</div>
        <MaskedInput
          type="text"
          className={'form-control'}
          value={deadlineForAnswers}
          onChange={this.onDeadlineChange}
          mask={UK_INPUT_MASK}
          placeholder={UK_INPUT_MASK_VALIDATION}
        />

        {isPaymentsEnabled && !isAllClubsAskToPayFieldActive && (
          <div className="text-danger mt-2">
            You haven't specified the club cost so users will be booking without making payments.
          </div>
        )}
      </SimpleModal>
    );
  }

  onDeadlineChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    this.setState({
      deadlineForAnswers: value
    });
  };

  sendInvitationsModalClose = (): void => {
    this.setState({
      isSendInvitationsModalOpen: false
    });
  };

  onSendInvitationsClick = () => {
    const { allClubsStudents, selectedItems, deadlineForAnswers, fields } = this.state;

    const { user } = this.props;
    const { activeSchool } = user;
    const { clubInvitations } = activeSchool;

    this.setState({
      isLoading: true
    });

    BPromise.all(
      selectedItems.map((club, index) => {
        const invitations = getClubInvitations(clubInvitations, club);
        const clubStudents = allClubsStudents[index];
        const clubId = club.id;
        let preparedUsers = [];

        //preparedUsers contains student user id and permission id for club invitations 'STUDENT'
        //and parent user id, parent permission id and child id for club invitations 'PARENT'
        //club invitations params get from school data
        switch (invitations) {
          case CLUB_INVITATIONS.STUDENT:
            preparedUsers = clubStudents
              .filter(student => {
                const { messageStatus } = student;
                const isMessageStatusPending = messageStatus === CLUB_MESSAGE_INVITE_STATUS_ALL.PENDING;
                const isMessageStatusNotSent = messageStatus === CLUB_MESSAGE_INVITE_STATUS_ALL.NOT_SENT;

                return typeof student.email !== 'undefined' && (isMessageStatusPending || isMessageStatusNotSent);
              })
              .map(student => {
                return {
                  userId: student.id,
                  permissionId: student.permissionId
                };
              });
            break;
          case CLUB_INVITATIONS.PARENT:
            preparedUsers = Lazy(clubStudents)
              .filter(student => {
                const { messageStatus } = student;
                const isMessageStatusPending = messageStatus === CLUB_MESSAGE_INVITE_STATUS_ALL.PENDING;
                const isMessageStatusNotSent = messageStatus === CLUB_MESSAGE_INVITE_STATUS_ALL.NOT_SENT;

                return (
                  Array.isArray(student.parents) &&
                  student.parents.length > 0 &&
                  (isMessageStatusPending || isMessageStatusNotSent)
                );
              })
              .map(student =>
                student.parents.map(parent => {
                  return {
                    ...parent,
                    childId: student.id
                  };
                })
              )
              .flatten()
              .map(parent => {
                return {
                  userId: parent.userId,
                  permissionId: parent.permissionId,
                  childId: parent.childId
                };
              })
              .toArray();
            break;
        }

        let data;

        if (deadlineForAnswers) {
          const deadlineForAnswersDate = Moment(deadlineForAnswers, UK_INPUT_MASK_VALIDATION, true).toDate();
          const deadlineForAnswersStr = deadlineForAnswersDate.toISOString();
          data = {
            participants: preparedUsers,
            fields: fields,
            deadlineForAnswers: deadlineForAnswersStr
          };
        } else {
          data = {
            participants: preparedUsers,
            fields: fields,
            deadlineForAnswers: null
          };
        }

        return sendClubMessages(user, clubId, data);
      })
    ).then(res => {
      this.setState({
        isSendInvitationsModalOpen: false,
        allClubsStudents: [],
        isSelectAllChecked: false,
        deadlineForAnswers: ''
      });

      this.setItems();
    });
  };

  isActivateClubAndSendInvitationsActive(): boolean {
    const { selectedItems } = this.state;
    const isAlSelectedClubStatusDraft = selectedItems.every(club => club.status === CLUB_STATUS.DRAFT);

    return selectedItems.length > 0 && isAlSelectedClubStatusDraft;
  }

  isDeleteClubStatusDraft(): boolean {
    const { selectedItems } = this.state;
    const isAlSelectedClubStatusDraft = selectedItems.every(club => club.status === CLUB_STATUS.DRAFT);

    return selectedItems.length > 0 && isAlSelectedClubStatusDraft;
  }

  onCreateClubClick = () => {
    const { user } = this.props;
    const isClubsEnabled = propz.get(user, ['activeSchool', 'isClubsEnabled']);

    isClubsEnabled ? this.onCreateClubWithWizardClick() : this.openCreateClubsDisabledModal();
  };

  onCreateClubWithWizardClick = (): void => {
    const { history } = this.props;
    const search = propz.get(history, ['location', 'search'], '');

    const { basePath, total } = this.state;
    const lastClubPage = getPagesCount(total + 1, LIMIT);

    history.push({
      pathname: `${basePath}/wizard`,
      state: {
        lastClubPage,
        search
      }
    });
  };

  renderCreateClubsDisabledModal(): React.ReactNode {
    const { selectedItems, isCreateClubsDisabledModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isCreateClubsDisabledModalOpen}
        title={'This function is not available to you'}
        body={
          'Extracurricular clubs functionality is not included in your current subscription. Please feel free to get in touch with Squad In Touch support team to discuss it.'
        }
        buttonText={'Cancel'}
        onCloseClick={this.closeCreateClubsDisabledModal}
      />
    );
  }

  openCreateClubsDisabledModal = () => {
    this.setState({
      isCreateClubsDisabledModalOpen: true
    });
  };

  closeCreateClubsDisabledModal = () => {
    this.setState({
      isCreateClubsDisabledModalOpen: false
    });
  };

  onRestoreClubClick = () => {
    const { user } = this.props;
    const { selectedItems } = this.state;
    const clubIds = selectedItems.map(club => club.id);

    const promises = clubIds.map(clubId => updateClub(user, { status: CLUB_STATUS.ACTIVE }, clubId));

    this.setState({
      isLoading: true
    });

    BPromise.all(promises).then(res => {
      this.setState({
        isSelectAllChecked: false,
        selectedItems: []
      });

      this.setItems();
    });
  };

  onArchiveClubClick = () => {
    const { user } = this.props;
    const { selectedItems } = this.state;
    const clubIds = selectedItems.map(club => club.id);

    const upcomingEventsPromises = clubIds.map(clubId => upcomingEvents(user, clubId));

    this.setState({
      isLoading: true
    });

    BPromise.all(upcomingEventsPromises).then(upcomingEventsExists => {
      this.setState({
        isLoading: false,
        upcomingEventsExists,
        isArchiveClubsModalOpen: true
      });
    });
  };

  onCopyClubWithWizardClick = (modeType): void => {
    const { history } = this.props;
    const search = propz.get(history, ['location', 'search'], '');

    const { basePath, selectedItems, total } = this.state;
    const lastClubPage = getPagesCount(total + 1, LIMIT);
    const [club] = selectedItems;

    history.push({
      pathname: `${basePath}/wizard`,
      state: {
        lastClubPage,
        search
      },
      search: `club=${club.id}&mode=${modeType}`
    });
  };

  onEditClubWithWizardClick = (): void => {
    const { history } = this.props;
    const search = propz.get(history, ['location', 'search'], '');
    const { selectedItems, basePath } = this.state;
    const [club] = selectedItems;

    history.push({
      pathname: `${basePath}/wizard`,
      search: `club=${club.id}&mode=${MODE_TYPE.EDIT}`,
      state: {
        search
      }
    });
  };

  deleteClubModalOpen = (): void => {
    this.setState({
      isDeleteClubModalOpen: true
    });
  };

  activateClubModalOpen = (): void => {
    this.setState({
      isActivateClubModalOpen: true
    });
  };

  sendInvitationsModalOpen = (): void => {
    const { selectedItems } = this.state;
    const { user } = this.props;

    this.setState({
      isLoading: true
    });

    getClubConfirmationRequestTemplate(user).then(confirmationRequestTemplate => {
      const confirmationRequestTemplateFields = propz.get<ConfirmationRequestTemplateField[]>(
        confirmationRequestTemplate,
        ['fields'],
        []
      );
      const isConfirmationRequestTemplateFieldsExist = confirmationRequestTemplateFields.length > 0;

      if (isConfirmationRequestTemplateFieldsExist) {
        this.setState({
          isLoading: false,
          confirmationRequestTemplate: confirmationRequestTemplate,
          isConfirmationRequestTemplateModalOpen: true
        });
      } else {
        BPromise.all(
          selectedItems.map(club => {
            const clubId = club.id;
            return getAllClubStudents(user, clubId);
          })
        ).then(allClubsStudents => {
          this.setState({
            isLoading: false,
            isSendInvitationsModalOpen: true,
            allClubsStudents: allClubsStudents
          });
        });
      }
    });
  };

  isOneItemSelected(): boolean {
    const selectedItems = this.state.selectedItems;
    return selectedItems.length === 1;
  }

  getSummaryTabs(): React.ReactNode {
    const {
      currentTabIndex,
      selectedItems,
      clubParticipants,
      clubEvents,
      clubPayments,
      isErrorInPayments,
      errorMessageInPayments
    } = this.state;

    const { user } = this.props;
    const { activeSchool } = user;

    switch (currentTabIndex) {
      case 0:
        return <ClubSummary club={selectedItems[0]} school={activeSchool} />;
      case 1:
        return <ClubParticipantsSummary clubParticipants={clubParticipants} />;
      case 2:
        return <ClubActivateSummary club={selectedItems[0]} clubEvents={clubEvents} user={user} />;
      case 3:
        return (
          <ClubPaymentsSummary
            clubTransactions={clubPayments}
            isErrorInPayments={isErrorInPayments}
            errorMessageInPayments={errorMessageInPayments}
            onCloseErrorInPaymentsClick={this.onCloseErrorInPaymentsClick}
          />
        );
      case 4:
        return (
          <ClubPaymentSettingsSummary user={user} club={selectedItems[0]} onSubmit={this.onUpdatePaymentSettings} />
        );
    }
  }

  onTabClick = (event, index: number) => {
    event.preventDefault();

    const selectedItems = this.state.selectedItems;
    const club = selectedItems[0];
    const isClubActive = club.status === CLUB_STATUS.ACTIVE;
    const clubId = club.id;
    const { user } = this.props;

    switch (index) {
      case 1:
        this.setState({
          isLoading: true
        });
        getAllClubParticipants(user, clubId).then(participants => {
          const sortParticipants = [...participants].sort(sortPlayersByLastNameAsc);
          this.setState({
            currentTabIndex: index,
            clubParticipants: sortParticipants,
            isLoading: false
          });
        });
        break;
      case 2:
        if (isClubActive) {
          this.setState({
            isLoading: true
          });
          getClubEvents(user, clubId).then(events => {
            this.setState({
              currentTabIndex: index,
              clubEvents: events,
              isLoading: false
            });
          });
          break;
        } else {
          this.setState({
            currentTabIndex: index
          });
          break;
        }
      case 3:
        this.setState({
          isLoading: true
        });
        getClubPayments(user, clubId)
          .then(payments => {
            this.setState({
              currentTabIndex: index,
              clubPayments: payments,
              isLoading: false
            });
          })
          .catch(error => {
            console.error(error.response.data.details.text);
            this.setState({
              currentTabIndex: index,
              isLoading: false,
              isErrorInPayments: true,
              errorMessageInPayments: error.response.data.details.text
            });
          });
        break;
      default:
        this.setState({
          currentTabIndex: index
        });
    }
  };

  onCloseErrorInPaymentsClick = (event: Event) => {
    event.preventDefault();
    this.setState({
      isErrorInPayments: false,
      errorMessageInPayments: ''
    });
  };

  onBookingButtonClick = (): void => {
    const { selectedItems } = this.state;
    const { history, location } = this.props;
    const { search } = location;

    const club = selectedItems[0];
    const clubId = club.id;

    history.push({
      pathname: `${this.state.basePath}/messages`,
      search: `club=${clubId}`,
      state: { search }
    });
  };

  onEligibleStudentsButtonClick = (): void => {
    const { selectedItems } = this.state;
    const { location } = this.props;
    const { search } = location;

    const club = selectedItems[0];
    const clubId = club.id;

    this.props.history.push({
      pathname: `${this.state.basePath}/students`,
      search: `club=${clubId}`,
      state: { search }
    });
  };

  onEditParticipantsButtonClick = (): void => {
    const selectedItems = this.state.selectedItems;
    const club = selectedItems[0];
    const clubId = club.id;
    const search = propz.get(this.props, ['location', 'search']);

    this.props.history.push({
      pathname: `${this.state.basePath}/participants`,
      search: `club=${clubId}`,
      state: { search }
    });
  };

  onEditEventsButtonClick = (): void => {
    const selectedItems = this.state.selectedItems;
    const club = selectedItems[0];
    const clubId = club.id;
    const search = propz.get(this.props, ['location', 'search']);

    this.props.history.push({
      pathname: `${this.state.basePath}/events`,
      search: `club=${clubId}`,
      state: { search }
    });
  };

  onSubmitReportForm = (data, reportType) => {
    const { user } = this.props;
    const { activeSchoolId, activeSchool } = user;
    const { selectedItems, forms } = this.state;
    const club = selectedItems[0];
    const clubId = propz.get(club, ['id'], '');
    const { ageGroupsNaming } = activeSchool;
    const ageGroup = propz.get(AGE_GROUPS_SORTED, [ageGroupsNaming]);

    const {
      dateTo,
      dateFrom,
      isPrintPP,
      isPrintSEN,
      isPrintFSM,
      isPrintEthnicity,
      isPrintEligible,
      isPrintPercent,
      isShowZero,
      isShowNonZero,
      genders,
      ages: ageItems,
      isGroupByForms,
      isGroupByYears,
      isGroupByKeyStages,
      isAttendance,
      isParticipation,
      isPrintMedicalInfo,
      isPrintNextOfKin,
      isPrintPriority,
      isShowAccepted,
      isShowRejected,
      isShowPending,
      isPrintCapacity,
      isCurrentWeek,
      isNextWeek
    } = data;

    let modData;
    let formsUniq;
    let ages: number[];

    switch (reportType) {
      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.AGGREGATE_CLUB_PARTICIPATION_FOR_PERIOD:
        this.setState({
          isAggregateClubParticipationReportModalOpen: false,
          isLoading: true
        });
        dateFrom.setHours(0, 0, 0, 0);
        dateTo.setHours(23, 59, 59, 0);
        const formattedDateTo = Moment(dateTo).format('YYYY-MM-DD HH:mm:ss');
        const formattedDateFrom = Moment(dateFrom).format('YYYY-MM-DD HH:mm:ss');
        modData = {
          reportParamsCSV: {
            schoolId: activeSchoolId,
            dateFrom: formattedDateFrom,
            dateTo: formattedDateTo,
            printPP: isPrintPP,
            printSEN: isPrintSEN,
            printEthnicity: isPrintEthnicity,
            printFSM: isPrintFSM,
            groupByForms: isGroupByForms,
            groupByYears: isGroupByYears,
            groupByKeyStages: isGroupByKeyStages
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.CLUB_ATTENDANCE:
        this.setState({
          isClubAttendanceReportModalOpen: false,
          isLoading: true
        });
        dateFrom.setHours(0, 0, 0, 0);
        dateTo.setHours(23, 59, 59, 0);
        modData = {
          reportParamsCSV: {
            dateTo: Moment(dateTo).format('YYYY-MM-DD HH:mm:ss'),
            dateFrom: Moment(dateFrom).format('YYYY-MM-DD HH:mm:ss'),
            clubId,
            printPP: isPrintPP,
            printSEN: isPrintSEN,
            printFSM: isPrintFSM,
            attendance: isAttendance,
            participation: isParticipation
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.PARTICIPATION_STATISTICS_BY_CLUBS:
        this.setState({
          isParticipationByClubsReportModalOpen: false,
          isLoading: true
        });
        dateFrom.setHours(0, 0, 0, 0);
        dateTo.setHours(23, 59, 59, 0);

        modData = {
          reportParamsCSV: {
            dateTo: Moment(dateTo).format('YYYY-MM-DD HH:mm:ss'),
            dateFrom: Moment(dateFrom).format('YYYY-MM-DD HH:mm:ss'),
            schoolId: activeSchoolId,
            printPP: isPrintPP,
            printSEN: isPrintSEN,
            printFSM: isPrintFSM,
            printEthnicity: isPrintEthnicity,
            showZero: isShowZero,
            showNonZero: isShowNonZero
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.CLUB_PARTICIPATION_STATISTICS_BY_STUDENTS:
        this.setState({
          isClubParticipationByStudentsReportModalOpen: false,
          isLoading: true
        });
        dateFrom.setHours(0, 0, 0, 0);
        dateTo.setHours(23, 59, 59, 0);

        formsUniq = Lazy(forms)
          .uniq('age')
          .sort((form1, form2) => {
            const ageIndex1 = ageGroup.indexOf(form1.ageGroup);
            const ageIndex2 = ageGroup.indexOf(form2.ageGroup);

            return ageIndex1 - ageIndex2;
          })
          .toArray();

        ages = formsUniq.filter((form, index) => ageItems[index].isChecked).map(form => form.age);

        modData = {
          reportParamsCSV: {
            dateTo: Moment(dateTo).format('YYYY-MM-DD HH:mm:ss'),
            dateFrom: Moment(dateFrom).format('YYYY-MM-DD HH:mm:ss'),
            schoolId: activeSchoolId,
            showZero: isShowZero,
            showNonZero: isShowNonZero,
            genders: genders.filter(gender => gender.isChecked === true).map(gender => gender.name.toUpperCase()),
            ageGroups: ages,
            printPP: isPrintPP,
            printSEN: isPrintSEN,
            printFSM: isPrintFSM
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.SELECTED_CLUBS_PARTICIPATION_BY_STUDENTS:
        this.setState({
          isSelectedClubParticipationByStudentsReportModalOpen: false,
          isLoading: true
        });
        dateFrom.setHours(0, 0, 0, 0);
        dateTo.setHours(23, 59, 59, 0);

        formsUniq = Lazy(forms)
          .uniq('age')
          .sort((form1, form2) => {
            const ageIndex1 = ageGroup.indexOf(form1.ageGroup);
            const ageIndex2 = ageGroup.indexOf(form2.ageGroup);

            return ageIndex1 - ageIndex2;
          })
          .toArray();

        ages = formsUniq.filter((form, index) => ageItems[index].isChecked).map(form => form.age);

        modData = {
          reportParamsCSV: {
            dateTo: Moment(dateTo).format('YYYY-MM-DD HH:mm:ss'),
            dateFrom: Moment(dateFrom).format('YYYY-MM-DD HH:mm:ss'),
            clubId: selectedItems.map(item => item.id),
            schoolId: activeSchoolId,
            showZero: isShowZero,
            showNonZero: isShowNonZero,
            genders: genders.filter(gender => gender.isChecked === true).map(gender => gender.name.toUpperCase()),
            ageGroups: ages,
            printPP: isPrintPP,
            printSEN: isPrintSEN,
            printFSM: isPrintFSM
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.CLUB_LIST_BY_STUDENTS:
        this.setState({
          isClubListByStudentsReportModalOpen: false,
          isLoading: true
        });

        dateFrom.setHours(0, 0, 0, 0);
        dateTo.setHours(23, 59, 59, 0);

        formsUniq = Lazy(forms)
          .uniq('age')
          .sort((form1, form2) => sortAges(form1.age, form2.age, ageGroupsNaming))
          .toArray();

        ages = formsUniq.filter((form, index) => ageItems[index].isChecked).map(form => form.age);

        modData = {
          reportParamsCSV: {
            dateTo: Moment(dateTo).format('YYYY-MM-DD HH:mm:ss'),
            dateFrom: Moment(dateFrom).format('YYYY-MM-DD HH:mm:ss'),
            schoolId: activeSchoolId,
            genders: genders.filter(gender => gender.isChecked === true).map(gender => gender.name.toUpperCase()),
            ageGroups: ages,
            printPP: isPrintPP,
            printSEN: isPrintSEN,
            printFSM: isPrintFSM
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.CLUB_ATTENDANCE_STATISTICS_BY_STUDENTS:
        this.setState({
          isClubAttendanceByStudentsReportModalOpen: false,
          isLoading: true
        });

        dateFrom.setHours(0, 0, 0, 0);
        dateTo.setHours(23, 59, 59, 0);

        formsUniq = Lazy(forms)
          .uniq('age')
          .sort((form1, form2) => {
            const ageIndex1 = ageGroup.indexOf(form1.ageGroup);
            const ageIndex2 = ageGroup.indexOf(form2.ageGroup);

            return ageIndex1 - ageIndex2;
          })
          .toArray();

        ages = formsUniq.filter((form, index) => ageItems[index].isChecked).map(form => form.age);

        modData = {
          reportParamsCSV: {
            dateTo: Moment(dateTo).format('YYYY-MM-DD HH:mm:ss'),
            dateFrom: Moment(dateFrom).format('YYYY-MM-DD HH:mm:ss'),
            schoolId: activeSchoolId,
            genders: genders.filter(gender => gender.isChecked === true).map(gender => gender.name.toUpperCase()),
            ageGroups: ages,
            printPP: isPrintPP,
            printSEN: isPrintSEN,
            printFSM: isPrintFSM
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.PARTICIPATION_STATISTICS_BY_CLUBS_YEAR_GROUPS:
        this.setState({
          isParticipationsByClubsYearGroupsReportModalOpen: false,
          isLoading: true
        });

        dateFrom.setHours(0, 0, 0, 0);
        dateTo.setHours(23, 59, 59, 0);

        modData = {
          reportParamsCSV: {
            dateTo: Moment(dateTo).format('YYYY-MM-DD HH:mm:ss'),
            dateFrom: Moment(dateFrom).format('YYYY-MM-DD HH:mm:ss'),
            schoolId: activeSchoolId,
            printCapacity: isPrintCapacity,
            printPP: isPrintPP,
            printSEN: isPrintSEN,
            printFSM: isPrintFSM,
            printEthnicity: isPrintEthnicity,
            printEligible: isPrintEligible,
            printPercent: isPrintPercent
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.CLUB_BOOKINGS:
        this.setState({
          isClubBookingsReportModalOpen: false,
          isLoading: true
        });

        modData = {
          reportParamsCSV: {
            schoolId: activeSchoolId,
            clubId,
            printMedicalInfo: isPrintMedicalInfo,
            printNextOfKin: isPrintNextOfKin,
            printPriority: isPrintPriority,
            showAccepted: isShowAccepted,
            showRejected: isShowRejected,
            showPending: isShowPending
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.WEEKLY_CLUB_SCHEDULE:
        this.setState({
          isWeeklyClubScheduleReportModalOpen: false,
          isLoading: true
        });
        formsUniq = Lazy(forms)
          .uniq('age')
          .sort((form1, form2) => {
            const ageIndex1 = ageGroup.indexOf(form1.ageGroup);
            const ageIndex2 = ageGroup.indexOf(form2.ageGroup);

            return ageIndex1 - ageIndex2;
          })
          .toArray();

        ages = formsUniq.filter((form, index) => ageItems[index].isChecked).map(form => form.age);

        modData = {
          reportParamsCSV: {
            schoolId: activeSchoolId,
            currentWeek: isCurrentWeek,
            nextWeek: isNextWeek,
            genders: genders.filter(gender => gender.isChecked === true).map(gender => gender.name.toUpperCase()),
            ageGroups: ages
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.DATE_CLUBS:
        this.setState({
          isDateClubsReportModalOpen: false,
          isLoading: true
        });

        dateFrom.setHours(0, 0, 0, 0);

        modData = {
          reportParamsCSV: {
            dateFrom: Moment(dateFrom).format('YYYY-MM-DD HH:mm:ss'),
            schoolId: activeSchoolId
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.CLUB_PARTICIPATION_BY_CLUB_NUMBER:
        this.setState({
          isClubParticipationByCLubNumberReportModalOpen: false,
          isLoading: true
        });

        dateFrom.setHours(0, 0, 0, 0);
        dateTo.setHours(23, 59, 59, 0);

        modData = {
          reportParamsCSV: {
            dateTo: Moment(dateTo).format('YYYY-MM-DD HH:mm:ss'),
            dateFrom: Moment(dateFrom).format('YYYY-MM-DD HH:mm:ss'),
            schoolId: activeSchoolId,
            printEligible: isPrintEligible,
            printPP: isPrintPP,
            printSEN: isPrintSEN,
            printFSM: isPrintFSM,
            groupByForms: isGroupByForms,
            groupByYears: isGroupByYears,
            groupByKeyStages: isGroupByKeyStages
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.AVERAGE_CLUB_ATTENDANCE:
        this.setState({
          isAverageClubAttendanceReportModalOpen: false,
          isLoading: true
        });
        formsUniq = Lazy(forms)
          .uniq('age')
          .sort((form1, form2) => {
            const ageIndex1 = ageGroup.indexOf(form1.ageGroup);
            const ageIndex2 = ageGroup.indexOf(form2.ageGroup);

            return ageIndex1 - ageIndex2;
          })
          .toArray();

        ages = formsUniq.filter((form, index) => ageItems[index].isChecked).map(form => form.age);

        dateFrom.setHours(0, 0, 0, 0);
        dateTo.setHours(23, 59, 59, 0);

        modData = {
          reportParamsCSV: {
            dateTo: Moment(dateTo).format('YYYY-MM-DD HH:mm:ss'),
            dateFrom: Moment(dateFrom).format('YYYY-MM-DD HH:mm:ss'),
            schoolId: activeSchoolId,
            genders: genders.filter(gender => gender.isChecked === true).map(gender => gender.name.toUpperCase()),
            ageGroups: ages
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.PAPER_ATTENDANCE_REGISTER_CLUB:
        this.setState({
          isPaperAttendanceRegisterClubReportModalOpen: false,
          isLoading: true
        });

        dateFrom.setHours(0, 0, 0, 0);
        dateTo.setHours(23, 59, 59, 0);

        modData = {
          reportParamsCSV: {
            dateTo: Moment(dateTo).format('YYYY-MM-DD HH:mm:ss'),
            dateFrom: Moment(dateFrom).format('YYYY-MM-DD HH:mm:ss'),
            schoolId: activeSchoolId,
            clubId
          }
        };
        break;
    }

    uploadReport(user, reportType, modData)
      .then(data => {
        if (typeof data.reportId !== 'undefined') {
          const reportType = data.reportType;

          this.setState({
            isLoading: false
          });

          const url = `${window.apiBase}/public/report/${data.reportId}/reportType/${reportType}`;
          window.open(url);
        } else {
          this.setState({
            isLoading: false,
            errorMessage: 'No read report',
            isErrorModalOpen: true
          });
        }
      })
      .catch(error => {
        console.error('uploadReport error:', error);

        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        console.error(error);

        this.setState({
          isLoading: false,
          errorMessage: errorText,
          isErrorModalOpen: true
        });
      });
  };

  openAggregateClubParticipationReportForm = () => {
    this.setState({
      isAggregateClubParticipationReportModalOpen: true
    });
  };

  closeAggregateClubParticipationReportForm = () => {
    this.setState({
      isAggregateClubParticipationReportModalOpen: false
    });
  };

  openClubAttendanceReportForm = () => {
    this.setState({
      isClubAttendanceReportModalOpen: true
    });
  };

  closeClubAttendanceReportForm = () => {
    this.setState({
      isClubAttendanceReportModalOpen: false
    });
  };

  openClubParticipationByStudentsReportForm = () => {
    this.setState({
      isClubParticipationByStudentsReportModalOpen: true
    });
  };

  closeClubParticipationByStudentsReportForm = () => {
    this.setState({
      isClubParticipationByStudentsReportModalOpen: false
    });
  };

  openSelectedClubParticipationByStudentsReportForm = () => {
    this.setState({
      isSelectedClubParticipationByStudentsReportModalOpen: true
    });
  };

  closeSelectedClubParticipationByStudentsReportForm = () => {
    this.setState({
      isSelectedClubParticipationByStudentsReportModalOpen: false
    });
  };

  openParticipationByClubsReportForm = () => {
    this.setState({
      isParticipationByClubsReportModalOpen: true
    });
  };

  closeParticipationByClubsReportForm = () => {
    this.setState({
      isParticipationByClubsReportModalOpen: false
    });
  };

  openClubAttendanceByStudentsReportForm = () => {
    this.setState({
      isClubAttendanceByStudentsReportModalOpen: true
    });
  };

  closeClubAttendanceByStudentsReportForm = () => {
    this.setState({
      isClubAttendanceByStudentsReportModalOpen: false
    });
  };

  openParticipationsByClubsYearGroupsReportForm = () => {
    this.setState({
      isParticipationsByClubsYearGroupsReportModalOpen: true
    });
  };

  closeParticipationsByClubsYearGroupsReportForm = () => {
    this.setState({
      isParticipationsByClubsYearGroupsReportModalOpen: false
    });
  };

  openClubListByStudentsReportForm = () => {
    this.setState({
      isClubListByStudentsReportModalOpen: true
    });
  };

  closeClubListByStudentsReportForm = () => {
    this.setState({
      isClubListByStudentsReportModalOpen: false
    });
  };

  openClubBookingsReportForm = () => {
    this.setState({
      isClubBookingsReportModalOpen: true
    });
  };

  closeClubBookingsReportForm = () => {
    this.setState({
      isClubBookingsReportModalOpen: false
    });
  };

  openWeeklyClubScheduleReportForm = () => {
    this.setState({
      isWeeklyClubScheduleReportModalOpen: true
    });
  };

  closeWeeklyClubScheduleReportForm = () => {
    this.setState({
      isWeeklyClubScheduleReportModalOpen: false
    });
  };

  openDateClubsReportForm = () => {
    this.setState({
      isDateClubsReportModalOpen: true
    });
  };

  closeDateClubsReportForm = () => {
    this.setState({
      isDateClubsReportModalOpen: false
    });
  };

  openClubParticipationByClubNumberReportForm = () => {
    this.setState({
      isClubParticipationByCLubNumberReportModalOpen: true
    });
  };

  closeClubParticipationByClubNumberReportForm = () => {
    this.setState({
      isClubParticipationByCLubNumberReportModalOpen: false
    });
  };

  openAverageClubAttendanceReportForm = () => {
    this.setState({
      isAverageClubAttendanceReportModalOpen: true
    });
  };

  closeAverageClubAttendanceReportForm = () => {
    this.setState({
      isAverageClubAttendanceReportModalOpen: false
    });
  };

  openPaperAttendanceRegisterClubReportForm = () => {
    this.setState({
      isPaperAttendanceRegisterClubReportModalOpen: true
    });
  };

  closePaperAttendanceRegisterClubReportForm = () => {
    this.setState({
      isPaperAttendanceRegisterClubReportModalOpen: false
    });
  };

  renderAggregateClubParticipationReportFormModal() {
    const { isAggregateClubParticipationReportModalOpen } = this.state;
    const { user } = this.props;
    const activeSchoolId = user && user.activeSchool ? user.activeSchool.id : null;

    return (
      <SimpleModal isOpen={isAggregateClubParticipationReportModalOpen}>
        <AggregateClubParticipationReportForm
          onCloseClick={this.closeAggregateClubParticipationReportForm}
          onSubmit={this.onSubmitReportForm}
          activeSchoolId={activeSchoolId}
        />
      </SimpleModal>
    );
  }

  renderClubAttendanceReportFormModal() {
    const { isClubAttendanceReportModalOpen, selectedItems } = this.state;
    const club = selectedItems[0];
    const { user } = this.props;
    const activeSchoolId = user && user.activeSchool ? user.activeSchool.id : null;
    return (
      <SimpleModal isOpen={isClubAttendanceReportModalOpen}>
        <ClubAttendanceReportForm
          onCloseClick={this.closeClubAttendanceReportForm}
          onSubmit={this.onSubmitReportForm}
          club={club}
          activeSchoolId={activeSchoolId}
        />
      </SimpleModal>
    );
  }

  renderClubParticipationByStudentsReportFormModal() {
    const { isClubParticipationByStudentsReportModalOpen, forms } = this.state;
    const { user } = this.props;
    const { activeSchool } = user;
    const activeSchoolId = user && user.activeSchool ? user.activeSchool.id : null;
    return (
      <SimpleModal isOpen={isClubParticipationByStudentsReportModalOpen}>
        <ClubParticipationByStudentsReportForm
          onCloseClick={this.closeClubParticipationByStudentsReportForm}
          onSubmit={this.onSubmitReportForm}
          forms={forms}
          school={activeSchool}
          activeSchoolId={activeSchoolId}
        />
      </SimpleModal>
    );
  }

  renderSelectedClubParticipationByStudentsReportFormModal() {
    const { isSelectedClubParticipationByStudentsReportModalOpen, forms } = this.state;
    const { user } = this.props;
    const { activeSchool } = user;
    const activeSchoolId = user && user.activeSchool ? user.activeSchool.id : null;
    return (
      <SimpleModal isOpen={isSelectedClubParticipationByStudentsReportModalOpen}>
        <SelectedClubParticipationByStudentsReportForm
          onCloseClick={this.closeSelectedClubParticipationByStudentsReportForm}
          onSubmit={this.onSubmitReportForm}
          forms={forms}
          school={activeSchool}
          activeSchoolId={activeSchoolId}
        />
      </SimpleModal>
    );
  }

  renderClubListByStudentsReportFormModal() {
    const { isClubListByStudentsReportModalOpen, forms } = this.state;
    const { user } = this.props;
    const { activeSchool } = user;
    const activeSchoolId = user && user.activeSchool ? user.activeSchool.id : null;

    return (
      <SimpleModal isOpen={isClubListByStudentsReportModalOpen}>
        <ClubListByStudentsReportForm
          onCloseClick={this.closeClubListByStudentsReportForm}
          onSubmit={this.onSubmitReportForm}
          forms={forms}
          school={activeSchool}
          activeSchoolId={activeSchoolId}
        />
      </SimpleModal>
    );
  }

  renderParticipationsByClubsReportFormModal() {
    const { isParticipationByClubsReportModalOpen, forms } = this.state;
    const { user } = this.props;
    const activeSchoolId = user && user.activeSchool ? user.activeSchool.id : null;
    return (
      <SimpleModal isOpen={isParticipationByClubsReportModalOpen}>
        <ParticipationsByClubsReportForm
          onCloseClick={this.closeParticipationByClubsReportForm}
          onSubmit={this.onSubmitReportForm}
          activeSchoolId={activeSchoolId}
        />
      </SimpleModal>
    );
  }

  renderClubAttendancesByStudentsReportFormModal() {
    const { isClubAttendanceByStudentsReportModalOpen, forms } = this.state;
    const { user } = this.props;
    const { activeSchool } = user;
    const activeSchoolId = user && user.activeSchool ? user.activeSchool.id : null;
    return (
      <SimpleModal isOpen={isClubAttendanceByStudentsReportModalOpen}>
        <ClubAttendanceByStudentsReportForm
          onCloseClick={this.closeClubAttendanceByStudentsReportForm}
          onSubmit={this.onSubmitReportForm}
          forms={forms}
          school={activeSchool}
          activeSchoolId={activeSchoolId}
        />
      </SimpleModal>
    );
  }

  renderParticipationsByClubsYearGroupsReportFormModal() {
    const {
      isParticipationsByClubsYearGroupsReportModalOpen: isOpenParticipationsByClubsYearGroups,
      forms
    } = this.state;
    const { user } = this.props;
    const { activeSchool } = user;
    const activeSchoolId = user && user.activeSchool ? user.activeSchool.id : null;
    return (
      <SimpleModal isOpen={isOpenParticipationsByClubsYearGroups}>
        <ParticipationsByClubsYearGroupsReportForm
          onCloseClick={this.closeParticipationsByClubsYearGroupsReportForm}
          onSubmit={this.onSubmitReportForm}
          activeSchoolId={activeSchoolId}
        />
      </SimpleModal>
    );
  }

  renderClubBookingsReportFormModal() {
    const { isClubBookingsReportModalOpen, selectedItems } = this.state;
    const club = selectedItems[0];
    return (
      <SimpleModal isOpen={isClubBookingsReportModalOpen}>
        <ClubBookingsReportForm
          onCloseClick={this.closeClubBookingsReportForm}
          onSubmit={this.onSubmitReportForm}
          club={club}
        />
      </SimpleModal>
    );
  }

  renderWeeklyClubScheduleReportFormModal() {
    const { isWeeklyClubScheduleReportModalOpen, forms } = this.state;
    const { user } = this.props;
    const { activeSchool } = user;
    return (
      <SimpleModal isOpen={isWeeklyClubScheduleReportModalOpen}>
        <WeeklyClubScheduleReportForm
          onCloseClick={this.closeWeeklyClubScheduleReportForm}
          onSubmit={this.onSubmitReportForm}
          forms={forms}
          school={activeSchool}
        />
      </SimpleModal>
    );
  }

  renderDateClubsReportFormModal() {
    const { isDateClubsReportModalOpen: isOpenDateClubs, forms } = this.state;
    return (
      <SimpleModal isOpen={isOpenDateClubs}>
        <DateClubsReportForm onCloseClick={this.closeDateClubsReportForm} onSubmit={this.onSubmitReportForm} />
      </SimpleModal>
    );
  }

  renderClubParticipationByClubNumberReportFormModal() {
    const { isClubParticipationByCLubNumberReportModalOpen } = this.state;
    const { user } = this.props;
    const { activeSchool } = user;
    const activeSchoolId = user && user.activeSchool ? user.activeSchool.id : null;
    return (
      <SimpleModal isOpen={isClubParticipationByCLubNumberReportModalOpen}>
        <ClubParticipationByClubNumberReportForm
          onCloseClick={this.closeClubParticipationByClubNumberReportForm}
          onSubmit={this.onSubmitReportForm}
          activeSchoolId={activeSchoolId}
        />
      </SimpleModal>
    );
  }

  renderAverageClubAttendanceReportFormModal() {
    const { isAverageClubAttendanceReportModalOpen, forms } = this.state;
    const { user } = this.props;
    const { activeSchool } = user;
    return (
      <SimpleModal isOpen={isAverageClubAttendanceReportModalOpen}>
        <AverageClubAttendanceReportForm
          onCloseClick={this.closeAverageClubAttendanceReportForm}
          onSubmit={this.onSubmitReportForm}
          forms={forms}
          school={activeSchool}
        />
      </SimpleModal>
    );
  }

  renderPaperAttendanceRegisterClubReportFormModal() {
    const { isPaperAttendanceRegisterClubReportModalOpen, selectedItems } = this.state;
    const { user } = this.props;
    const { activeSchool } = user;
    const club = selectedItems[0];
    return (
      <SimpleModal isOpen={isPaperAttendanceRegisterClubReportModalOpen}>
        <PaperAttendanceRegisterClubReportForm
          onCloseClick={this.closePaperAttendanceRegisterClubReportForm}
          onSubmit={this.onSubmitReportForm}
          club={club}
        />
      </SimpleModal>
    );
  }

  getTabs() {
    const { user } = this.props;
    const isPaymentsEnabled = propz.get(user, ['activeSchool', 'isPaymentsEnabled'], false);

    return isPaymentsEnabled ? CLUB_TABS_ARRAY : CLUB_TABS_FOR_SCHOOL_WITHOUT_PAYMENT;
  }

  renderSummary(): React.ReactNode {
    const isOneItemSelected = this.isOneItemSelected();

    if (!isOneItemSelected) {
      return <div className="font-weight-bold">Select a club above</div>;
    }

    const { currentTabIndex, selectedItems, clubEvents } = this.state;

    const club = selectedItems[0];
    const isClubActive = club.status === CLUB_STATUS.ACTIVE;

    const summaryTabs = this.getSummaryTabs();

    const isShowActivateButton = currentTabIndex === 2 && !isClubActive;
    const isShowEditParticipantsButton = currentTabIndex === 1;

    const isShowBookingButton = currentTabIndex === 0;
    const isShowEligibleStudentsButton = currentTabIndex === 0 && !isClubActive;

    const isShowEditEventsButton = currentTabIndex === 2 && clubEvents.length > 0;

    const tabs = this.getTabs();

    return (
      <GenericSummary onTabClick={this.onTabClick} tabs={tabs} currentTabIndex={currentTabIndex}>
        {isShowBookingButton && (
          <Button onClick={this.onBookingButtonClick} text={'Booking'} customClass={'mt-3 mb-3 mr-3'} />
        )}

        {isShowEligibleStudentsButton && (
          <Button
            onClick={this.onEligibleStudentsButtonClick}
            text={'Eligible students'}
            customClass={'mt-3 mb-3 mr-3'}
          />
        )}

        {isShowActivateButton && (
          <Button onClick={this.activateClubModalOpen} text={'Activate'} customClass={'mt-3 mb-3'} />
        )}

        {isShowEditParticipantsButton && (
          <Button onClick={this.onEditParticipantsButtonClick} text={'Edit'} customClass={'mt-3 mb-3'} />
        )}

        {isShowEditEventsButton && (
          <Button onClick={this.onEditEventsButtonClick} text={'Manage events'} customClass={'mt-3 mb-3'} />
        )}

        {summaryTabs}
      </GenericSummary>
    );
  }

  renderGrid() {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      forms,
      isSelectAllChecked,
      currentPage,
      totalWithFilters
    } = this.state;

    const { user } = this.props;

    const ageGroupName = propz.get(user, ['activeSchool', 'ageGroupsNaming'], '');

    const isActiveShowClubAttendanceReportButton =
      selectedItems.length === 1 && selectedItems[0].status !== CLUB_STATUS.DRAFT;

    const isActiveShowPaperAttendanceRegisterClubReportButton =
      selectedItems.length === 1 && selectedItems[0].status !== CLUB_STATUS.DRAFT;

    const isActiveShowClubBookingsReportButton = selectedItems.length === 1;

    const isUserTeacherOrCoach = isUserTeacher(user) || isUserCoach(user);

    const actionItems = [
      {
        itemText: 'Create club',
        onItemClick: this.onCreateClubClick,
        isActive: selectedItems.length === 0
      },
      {
        itemText: 'Create like this (details only)',
        onItemClick: () => this.onCopyClubWithWizardClick(MODE_TYPE.CLUB_COPY),
        isActive: selectedItems.length === 1
      },
      {
        itemText: 'Create like this (details and students)',
        onItemClick: () => this.onCopyClubWithWizardClick(MODE_TYPE.CLUB_COPY_WITH_PARTICIPANTS),
        isActive: selectedItems.length === 1
      },
      {
        itemText: 'Edit club',
        onItemClick: this.onEditClubWithWizardClick,
        isActive: selectedItems.length === 1
      },
      {
        itemText: selectedItems.length === 1 ? 'Delete club' : 'Delete clubs',
        onItemClick: this.deleteClubModalOpen,
        isActive: this.isDeleteClubStatusDraft()
      },
      {
        itemText: selectedItems.length === 1 ? 'Activate club' : 'Activate clubs',
        onItemClick: this.activateClubModalOpen,
        isActive: this.isActivateClubAndSendInvitationsActive()
      },
      {
        itemText: 'Send invitations',
        onItemClick: this.sendInvitationsModalOpen,
        isActive: this.isActivateClubAndSendInvitationsActive()
      },
      {
        itemText: 'Reports',
        isActive: true,
        subItems: isUserTeacherOrCoach
          ? [
              {
                itemText: 'Club attendance',
                onItemClick: this.openClubAttendanceReportForm,
                isActive: isActiveShowClubAttendanceReportButton
              },
              {
                itemText: 'Paper attendance register',
                onItemClick: this.openPaperAttendanceRegisterClubReportForm,
                isActive: isActiveShowPaperAttendanceRegisterClubReportButton
              },
              {
                itemText: 'Club booking summary',
                onItemClick: this.openClubBookingsReportForm,
                isActive: isActiveShowClubBookingsReportButton
              },
              {
                itemText: 'Club list for the selected date',
                onItemClick: this.openDateClubsReportForm,
                isActive: true
              }
            ]
          : [
              {
                itemText: 'Aggregated club participation report',
                onItemClick: this.openAggregateClubParticipationReportForm,
                isActive: selectedItems.length == 0
              },
              {
                itemText: 'Club attendance',
                onItemClick: this.openClubAttendanceReportForm,
                isActive: isActiveShowClubAttendanceReportButton
              },
              {
                itemText: 'Paper attendance register',
                onItemClick: this.openPaperAttendanceRegisterClubReportForm,
                isActive: selectedItems.length == 1
              },
              {
                itemText: 'Average club attendance',
                onItemClick: this.openAverageClubAttendanceReportForm,
                isActive: selectedItems.length == 0
              },
              {
                itemText: 'Participation report by students',
                onItemClick: this.openClubParticipationByStudentsReportForm,
                isActive: selectedItems.length == 0
              },
              {
                itemText: 'Participation report by students (selected clubs)',
                onItemClick: this.openSelectedClubParticipationByStudentsReportForm,
                isActive: selectedItems.length > 0
              },
              {
                itemText: 'Participation statistics by clubs',
                onItemClick: this.openParticipationByClubsReportForm,
                isActive: selectedItems.length == 0
              },
              {
                itemText: 'Attendance statistics by students',
                onItemClick: this.openClubAttendanceByStudentsReportForm,
                isActive: selectedItems.length == 0
              },
              {
                itemText: 'Club list by students',
                onItemClick: this.openClubListByStudentsReportForm,
                isActive: selectedItems.length == 0
              },
              {
                itemText: 'Involvement statistics by students',
                onItemClick: this.openParticipationsByClubsYearGroupsReportForm,
                isActive: selectedItems.length == 0
              },
              {
                itemText: 'Club booking summary',
                onItemClick: this.openClubBookingsReportForm,
                isActive: isActiveShowClubBookingsReportButton
              },

              {
                itemText: 'Weekly club schedule',
                onItemClick: this.openWeeklyClubScheduleReportForm,
                isActive: selectedItems.length == 0
              },

              {
                itemText: 'Club list for the selected date',
                onItemClick: this.openDateClubsReportForm,
                isActive: selectedItems.length == 0
              },
              {
                itemText: 'Club participation by club number',
                onItemClick: this.openClubParticipationByClubNumberReportForm,
                isActive: selectedItems.length == 0
              }
            ]
      },
      {
        itemText: selectedItems.length === 1 ? 'Archive club' : 'Archive clubs',
        onItemClick: this.onArchiveClubClick,
        isActive: selectedItems.length > 0 && selectedItems.every(club => club.status === CLUB_STATUS.ACTIVE)
      }
    ];

    const filterOptions = {
      status: getSelectOptionForClubStatus(),
      clubAges: getSelectOptionForAge(forms, ageGroupName),
      gender: getSelectOptionForClubGender(),
      clubAcademicYear: getSelectOptionForClubAcademicYears(),
      clubWeekDays: getSelectOptionForClubWeekDays(),
      clubRecurrence: getSelectOptionForClubRecurrence()
    };

    return (
      <Grid2
        user={user}
        dataItems={items}
        filters={filters}
        currentPage={currentPage}
        total={totalWithFilters}
        isSelectAllChecked={isSelectAllChecked}
        isDataFiltered={isDataFiltered}
        sortDirection={sortDirection}
        sortColumnsName={sortColumnsName}
        isShowFilter={isShowFilter}
        dataItemsSelected={selectedItems}
        columns={COLUMNS}
        actionItems={actionItems}
        options={filterOptions}
        onItemClick={this.onItemClick}
        onSortClick={this.onTableSortClick}
        onApplyFilterClick={this.onApplyFilterClick}
        onClearFilterClick={this.onClearFilterClick}
        onTableFilterChange={this.onTableFilterChange}
        onTableFilterClick={this.onTableFilterClick}
        setCurrentPageParams={this.setCurrentPageParams}
        onSelectAllClick={this.onSelectAllClick}
        onSelectAllOnPageClick={this.onSelectAllOnPageClick}
        onUnselectAllClick={this.onUnselectAllClick}
      />
    );
  }

  onCloseConfirmationRequestTemplateModalClick = () => {
    this.setState({
      isConfirmationRequestTemplateModalOpen: false
    });
  };

  onNextClick = (fields, deadlineForAnswers) => {
    const { selectedItems } = this.state;
    const { user } = this.props;
    this.setState({
      isLoading: true
    });
    BPromise.all(
      selectedItems.map(club => {
        const clubId = club.id;
        return getAllClubStudents(user, clubId);
      })
    ).then(allClubsStudents => {
      this.setState({
        isLoading: false,
        isConfirmationRequestTemplateModalOpen: false,
        isSendInvitationsModalOpen: true,
        fields,
        deadlineForAnswers,
        allClubsStudents
      });
    });
  };

  onCloseErrorClick = () => {
    this.setState({
      isErrorModalOpen: false,
      errorMessage: ''
    });
  };

  renderConfirmationRequestTemplateModal() {
    const { isConfirmationRequestTemplateModalOpen, confirmationRequestTemplate, selectedItems } = this.state;
    const { user } = this.props;
    const { activeSchool } = user;
    const { clubInvitations } = activeSchool;

    const isPaidClubsExist = selectedItems.reduce((result, item) => {
      const invitations = getClubInvitations(clubInvitations, item);
      const isStudentInvitation = invitations === CLUB_INVITATIONS.STUDENT;
      const isAskToPay = propz.get(item, ['paymentSettings', 'askToPay']);

      return isStudentInvitation && isAskToPay;
    }, false);

    if (isPaidClubsExist) {
      return (
        <SimpleModal
          customClass="mClubSendMessagesFormWidth"
          isOpen={isConfirmationRequestTemplateModalOpen}
          title={'Send invitations'}
          body={
            'Sending invitations for a paid club to students is not included in your school subscription. Please get in touch with our support team for more details'
          }
          onCloseClick={this.onCloseConfirmationRequestTemplateModalClick}
        />
      );
    } else {
      return (
        <SimpleModal
          customClass="mClubSendInvitationsFormWidth"
          isOpen={isConfirmationRequestTemplateModalOpen}
          title={'Send invitations'}
        >
          <ConfirmationRequestForm
            template={confirmationRequestTemplate}
            onCancelClick={this.onCloseConfirmationRequestTemplateModalClick}
            onSendConfirmationRequestsClick={this.onNextClick}
            textSubmitButton={'Next'}
            isRenderDeadline={true}
            inviteKind={INVITE_KIND.CLUB_SCHOOL_INVITE as INVITE_KIND_TYPE}
          />
        </SimpleModal>
      );
    }
  }

  renderSendingError(): React.ReactNode {
    const { errorMessage, isErrorModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isErrorModalOpen}
        title={'Error'}
        body={errorMessage}
        buttonCancelText={'Ok'}
        onCloseClick={this.onCloseErrorClick}
      />
    );
  }

  onUpdatePaymentSettings = (data: any) => {
    const { user } = this.props;
    const { selectedItems, items } = this.state;
    const club = selectedItems[0];

    updateClub(user, data, club.id).then(clubUpdated => {
      const index = items.findIndex(item => item.id === clubUpdated.id);
      const itemsUpdated = [...items];
      itemsUpdated[index] = club;

      this.setState({
        items: itemsUpdated,
        selectedItems: [clubUpdated]
      });
    });
  };

  render() {
    const {
      isLoading,
      isActivateClubModalOpen,
      isSendInvitationsModalOpen,
      isConfirmationRequestTemplateModalOpen,
      isAggregateClubParticipationReportModalOpen,
      isClubAttendanceReportModalOpen,
      isClubParticipationByStudentsReportModalOpen,
      isClubListByStudentsReportModalOpen,
      isErrorModalOpen,
      isArchiveClubsModalOpen,
      isParticipationByClubsReportModalOpen,
      isClubAttendanceByStudentsReportModalOpen,
      isParticipationsByClubsYearGroupsReportModalOpen,
      isClubBookingsReportModalOpen,
      isWeeklyClubScheduleReportModalOpen,
      isDateClubsReportModalOpen,
      isClubParticipationByCLubNumberReportModalOpen,
      isAverageClubAttendanceReportModalOpen,
      isPaperAttendanceRegisterClubReportModalOpen
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes =
      isActivateClubModalOpen ||
      isSendInvitationsModalOpen ||
      isConfirmationRequestTemplateModalOpen ||
      isArchiveClubsModalOpen ||
      isErrorModalOpen ||
      isAggregateClubParticipationReportModalOpen ||
      isClubAttendanceReportModalOpen ||
      isParticipationByClubsReportModalOpen ||
      isClubParticipationByStudentsReportModalOpen ||
      isClubListByStudentsReportModalOpen ||
      isClubAttendanceByStudentsReportModalOpen ||
      isParticipationsByClubsYearGroupsReportModalOpen ||
      isClubBookingsReportModalOpen ||
      isWeeklyClubScheduleReportModalOpen ||
      isDateClubsReportModalOpen ||
      isClubParticipationByCLubNumberReportModalOpen ||
      isAverageClubAttendanceReportModalOpen ||
      isPaperAttendanceRegisterClubReportModalOpen
        ? 'mt-3 modal-open'
        : 'mt-3';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderDeleteClubModal()}
        {this.renderActivateClubModal()}
        {this.renderSendInvitationsClubModal()}
        {this.renderConfirmationRequestTemplateModal()}
        {this.renderAggregateClubParticipationReportFormModal()}
        {this.renderClubAttendanceReportFormModal()}
        {this.renderClubParticipationByStudentsReportFormModal()}
        {this.renderParticipationsByClubsReportFormModal()}
        {this.renderClubAttendancesByStudentsReportFormModal()}
        {this.renderClubListByStudentsReportFormModal()}
        {this.renderParticipationsByClubsYearGroupsReportFormModal()}
        {this.renderClubBookingsReportFormModal()}
        {this.renderWeeklyClubScheduleReportFormModal()}
        {this.renderDateClubsReportFormModal()}
        {this.renderSendingError()}
        {this.renderArchiveClubsModal()}
        {this.renderCreateClubsDisabledModal()}
        {this.renderSelectedClubParticipationByStudentsReportFormModal()}
        {this.renderClubParticipationByClubNumberReportFormModal()}
        {this.renderAverageClubAttendanceReportFormModal()}
        {this.renderPaperAttendanceRegisterClubReportFormModal()}
        <div className="row">
          <div className="col-md-12">
            <ResizablePanel>
              {this.renderGrid()}
              {this.renderSummary()}
            </ResizablePanel>
          </div>
        </div>
      </div>
    );
  }
}
