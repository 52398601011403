import * as React from 'react';
import { Component } from 'react';
import * as propz from 'propz';
import * as Moment from 'moment';
import { AppUser } from 'Src/views/App/App';
import { ClubParticipantInviteMessage, MessageField } from 'Src/models/message';
import { SPORT_GENDER_SERVER_TO_CLIENT_MAPPING, SECONDS_IN_MINUTE, MILLISECONDS_IN_SECOND } from 'Src/consts/common';
import {
  WEEK_DAYS_SERVER_TO_CLIENT_MAPPING,
  DATE_FORMAT_FULL_MONTH_US,
  DATE_FORMAT_FULL_MONTH,
  TIME
} from 'Src/consts/date';
import { isNumeric } from 'Src/helpers/common/common';
import { getPhoneRegion } from 'Src/helpers/region/region';
import { Map } from 'Src/components/Map/Map';
import { Select } from 'Src/components/Select/Select';
import {
  getSelectOptionsForClubPriority,
  getSelectOptionForBooleanQuestion,
  getSelectOptionForClubInvitationEnumQuestion
} from 'Src/helpers/table/select';
import { SelectOption } from 'Src/helpers/table/table';
import { QUESTIONS_TEMPLATE_TYPE } from 'Src/consts/message';
import './ClubInvitationForm.scss';

interface Props {
  user: AppUser;
  message: ClubParticipantInviteMessage;
  onDeclineClick: () => void;
  onBookNowClick: (fields: MessageField[], priority: number) => void;
  closeClubInvitationModal: () => void;
}
interface State {
  fields: MessageField[];
  isMapVisible: boolean;
  activePriorityIndex: number;
  selectOptionsForClubPriority: SelectOption[];
  activeSelectIndex: number[];
  errors: Error[];
}

interface Error {
  isError: boolean;
  errorText: string;
}

export class ClubInvitationForm extends Component<Props, State> {
  constructor(props) {
    super(props);
    const { message } = this.props;
    const selectOptionsForClubPriority = getSelectOptionsForClubPriority();
    const fields = propz.get(message, ['fields']);

    let fieldsUpdated;
    let activeSelectIndex = [];
    let errors = [];

    const isFieldsExist = typeof fields !== 'undefined';
    if (isFieldsExist) {
      fieldsUpdated = fields.map((field, index) => {
        activeSelectIndex[index] = 0;
        errors[index] = { isError: false, errorText: '' };
        return { ...field, value: '' };
      });
    }

    this.state = {
      fields: isFieldsExist ? fieldsUpdated : [],
      isMapVisible: false,
      activePriorityIndex: 0,
      activeSelectIndex: isFieldsExist ? activeSelectIndex : [],
      errors: isFieldsExist ? errors : [],
      selectOptionsForClubPriority: selectOptionsForClubPriority
    };
  }

  onSubmit = () => {
    const { fields, errors, selectOptionsForClubPriority, activePriorityIndex } = this.state;
    let submitAbility = true;
    let fieldsUpdated = [...fields];
    const priority = Number(selectOptionsForClubPriority[activePriorityIndex].value);

    const errorsUpdated = errors.map(item => {
      return {
        isError: false,
        errorText: ''
      };
    });

    fields.forEach((field, index) => {
      const isNumericField = isNumeric(field.value);
      const isNumberType = field.type === QUESTIONS_TEMPLATE_TYPE.NUMBER;
      const isBooleanType = field.type === QUESTIONS_TEMPLATE_TYPE.BOOLEAN;
      const isEnumType = field.type === QUESTIONS_TEMPLATE_TYPE.ENUM;
      const isFieldEmpty = field.value === '';

      if (isBooleanType && isFieldEmpty) {
        const options = getSelectOptionForBooleanQuestion();
        const value = options[0].value;
        propz.set(fieldsUpdated[index], ['value'], value);
      }

      if (isEnumType && isFieldEmpty) {
        const options = getSelectOptionForClubInvitationEnumQuestion(field.enumOptions);
        const value = options[0].value;
        propz.set(fieldsUpdated[index], ['value'], value);
      }

      if (isNumberType && !isNumericField && !isFieldEmpty) {
        errorsUpdated[index].isError = true;
        errorsUpdated[index].errorText = 'please enter a number';
        submitAbility = false;
      }

      const isFieldUpdatedEmpty = fieldsUpdated[index].value === '';

      if (isFieldUpdatedEmpty && field.isRequired) {
        errorsUpdated[index].isError = true;
        errorsUpdated[index].errorText = 'required';
        submitAbility = false;
      }
    });

    if (submitAbility) {
      this.props.onBookNowClick(fieldsUpdated, priority);
    } else {
      this.setState({
        errors: errorsUpdated
      });
    }
  };

  isNumeric = n => {
    return !isNaN(parseFloat(n)) && isFinite(n);
  };

  getAges(): string {
    const { message } = this.props;
    const ages = propz.get(message, ['clubData', 'ages']);
    const isAgesExist = typeof ages !== 'undefined';
    let result = '';

    if (isAgesExist) {
      if (ages.length === 0) {
        result = 'All ages';
      } else {
        result = ages.map(ageItem => (ageItem === 0 ? 'Reception' : 'Y' + ageItem)).join(', ');
      }
    }

    return result;
  }

  renderDate() {
    const { message, user } = this.props;
    const phone = propz.get(user, ['profile', 'phone']);
    const region = getPhoneRegion(phone);
    const startDate = propz.get(message, ['clubData', 'startDate']);
    const startDateFormatted =
      region === 'US'
        ? Moment(startDate).format(DATE_FORMAT_FULL_MONTH_US)
        : Moment(startDate).format(DATE_FORMAT_FULL_MONTH);
    const endDate = propz.get(message, ['clubData', 'finishDate']);
    const endDateFormatted =
      region === 'US'
        ? Moment(endDate).format(DATE_FORMAT_FULL_MONTH_US)
        : Moment(endDate).format(DATE_FORMAT_FULL_MONTH);

    let result = null;
    if (startDateFormatted === endDateFormatted) {
      result = (
        <div>
          <span className="mClubInvitationFormBold">Date:</span>
          {` ${startDateFormatted}`}
        </div>
      );
    } else {
      result = (
        <div className={'d-flex flex-column justify-content-start'}>
          <div>
            <span className="mClubInvitationFormBold">Start:</span>
            {` ${startDateFormatted} `}
          </div>
          <div>
            <span className="mClubInvitationFormBold">End:</span>
            {` ${endDateFormatted}`}
          </div>
        </div>
      );
    }
    return result;
  }

  getTime() {
    const { message, user } = this.props;
    const phone = propz.get(user, ['profile', 'phone']);
    const region = getPhoneRegion(phone);
    DATE_FORMAT_FULL_MONTH_US;

    const startDate = propz.get(message, ['clubData', 'startDate']);
    const startDateFormatted = DATE_FORMAT_FULL_MONTH_US;
    region === 'US'
      ? Moment(startDate).format(DATE_FORMAT_FULL_MONTH_US)
      : Moment(startDate).format(DATE_FORMAT_FULL_MONTH);
    const endDate = propz.get(message, ['clubData', 'finishDate']);
    const endDateFormatted =
      region === 'US'
        ? Moment(endDate).format(DATE_FORMAT_FULL_MONTH_US)
        : Moment(endDate).format(DATE_FORMAT_FULL_MONTH);

    const startTime = propz.get(message, ['clubData', 'time']);
    const duration = propz.get(message, ['clubData', 'duration']);

    const startDateTimeStamp = new Date(startTime).getTime();
    const endDateTimeStamp = startDateTimeStamp + duration * SECONDS_IN_MINUTE * MILLISECONDS_IN_SECOND;
    const endTime = new Date(endDateTimeStamp).toISOString();

    const startTimeFormatted = Moment(startTime).format(TIME);
    const endTimeFormatted = Moment(endTime).format(TIME);
    const eventTime = `${startTimeFormatted}-${endTimeFormatted}`;

    const weekDays = propz.get(message, ['clubData', 'days'], []);
    let weekDaysString = '';
    weekDays.forEach(day => {
      weekDaysString = weekDaysString + ` ${WEEK_DAYS_SERVER_TO_CLIENT_MAPPING[day]},`;
    });

    let time = null;

    if (startDateFormatted === endDateFormatted) {
      time = ` ${eventTime}`;
    } else {
      time = ` ${weekDaysString} ${eventTime}`;
    }

    return time;
  }

  renderDescription() {
    const { message } = this.props;
    const description = propz.get(message, ['clubData', 'description']);
    const isDescriptionExist = typeof description !== 'undefined' && description !== '';

    let result = isDescriptionExist ? (
      <div>
        <span className="mClubInvitationFormBold">Description:</span> {description}
      </div>
    ) : null;

    return result;
  }

  toggleMap = () => {
    const { isMapVisible } = this.state;
    this.setState({
      isMapVisible: !isMapVisible
    });
  };

  onPrioritySelect = (value: string): void => {
    const { selectOptionsForClubPriority } = this.state;
    const nextIndex = selectOptionsForClubPriority.findIndex(priority => Number(priority.value) === Number(value));
    this.setState({
      activePriorityIndex: nextIndex
    });
  };

  render() {
    const { message, user } = this.props;
    const {
      fields,
      isMapVisible,
      activePriorityIndex,
      selectOptionsForClubPriority,
      activeSelectIndex,
      errors
    } = this.state;

    const isFieldsExist = fields.length > 0;
    const clubName = propz.get(message, ['clubData', 'name']);
    const deadlineForAnswers = propz.get(message, ['deadlineForAnswers']);
    const deadlineForAnswersFormatted = Moment(deadlineForAnswers).format('DD.MM.YYYY / HH:mm');
    const isDeadlineExist = typeof deadlineForAnswers !== 'undefined';
    const now = new Date();
    const isDeadlineArrived = Number(new Date(deadlineForAnswers)) < Number(now);
    const postcode = propz.get(message, ['clubData', 'venue', 'placeData', 'postcode']);
    const placeName = propz.get(message, ['clubData', 'venue', 'placeData', 'name']);

    const isClubPriorityEnabled = propz.get(user, ['activeSchool', 'isClubPriorityEnabled']);

    const gender = SPORT_GENDER_SERVER_TO_CLIENT_MAPPING[propz.get(message, ['clubData', 'gender'])];
    const ages = this.getAges();

    const fieldsUpdated = [...fields];
    const point = propz.get(message, ['clubData', 'venue', 'point']);

    const picUrl = propz.get(message, ['schoolData', 'pic'], '');
    const isSchoolLogoExist = picUrl !== '';

    return (
      <>
        <div className={'eClubInvitationFormRow'}>
          <img
            src={isSchoolLogoExist ? picUrl : '/dist/images/no-image.jpg'}
            alt="school logo"
            className={'bClubInvitationFormLogo'}
          />

          <div className={'ml-3'}>
            <div className={'mClubInvitationFormBold'}>{`A new opportunity for you is available`}</div>
            <div className={'mClubInvitationFormBold'}>{clubName}</div>
            {this.renderDescription()}
            <div>{`${gender}, ${ages}`}</div>
            {this.renderDate()}
            <div>
              <span className="mClubInvitationFormBold">Time:</span>
              {this.getTime()}
            </div>
            <div>
              <span className={'mClubInvitationFormBold'}>{'Venue:'} </span>
              {`${postcode}, ${placeName}`}
            </div>
          </div>
        </div>

        {isClubPriorityEnabled && (
          <div>
            <div className={'mClubInvitationFormBold'}>Priority:</div>
            <Select
              onChange={this.onPrioritySelect}
              options={selectOptionsForClubPriority}
              activeResultIndex={activePriorityIndex}
              customClass={'form-control'}
            />
            <div className={'eClubParticipationMessagePriorityCaption'}>
              Please ensure you only select one Priority number per club within the below booking forms, otherwise, the
              school will allocate your child at its discretion.
            </div>
          </div>
        )}

        {isFieldsExist &&
          fieldsUpdated.map((field, index) => (
            <div key={`club_invitation_form_field_${index}`}>
              <div>
                {field.heading} {field.isRequired ? '(required)' : ''}
              </div>

              {(field.type === QUESTIONS_TEMPLATE_TYPE.STRING || field.type === QUESTIONS_TEMPLATE_TYPE.NUMBER) && (
                <input
                  type="text"
                  className="form-control"
                  value={field.value}
                  onChange={event => {
                    propz.set(fieldsUpdated[index], ['value'], event.target.value);
                    this.setState({ fields: fieldsUpdated });
                  }}
                />
              )}
              {field.type === QUESTIONS_TEMPLATE_TYPE.BOOLEAN && (
                <Select
                  onChange={value => {
                    const options = getSelectOptionForBooleanQuestion();
                    const nextIndex = options.findIndex(option => option.value === value);
                    const activeSelectIndexUpdated = [...activeSelectIndex];
                    activeSelectIndexUpdated[index] = nextIndex;
                    propz.set(fieldsUpdated[index], ['value'], value);

                    this.setState({
                      activeSelectIndex: activeSelectIndexUpdated
                    });
                  }}
                  options={getSelectOptionForBooleanQuestion()}
                  activeResultIndex={activeSelectIndex[index]}
                  customClass={'form-control'}
                />
              )}
              {field.type === QUESTIONS_TEMPLATE_TYPE.ENUM && (
                <Select
                  onChange={value => {
                    const options = getSelectOptionForClubInvitationEnumQuestion(field.enumOptions);
                    const nextIndex = options.findIndex(option => option.value === value);
                    const activeSelectIndexUpdated = [...activeSelectIndex];
                    activeSelectIndexUpdated[index] = nextIndex;
                    propz.set(fieldsUpdated[index], ['value'], value);

                    this.setState({
                      activeSelectIndex: activeSelectIndexUpdated
                    });
                  }}
                  options={getSelectOptionForClubInvitationEnumQuestion(field.enumOptions)}
                  activeResultIndex={activeSelectIndex[index]}
                  customClass={'form-control'}
                />
              )}
              {errors[index].isError && <div className="alert alert-danger mt-3">{errors[index].errorText}</div>}
            </div>
          ))}

        <div className="eClubInvitationFormRow">
          <img src={'/dist/images/icons/pin.png'} alt="pin icon" className={'bClubInvitationFormIcon'} />
          <div className="bClubInvitationFormMap" onClick={this.toggleMap}>
            View map
          </div>
        </div>

        {isMapVisible && <Map point={point} customStylingClass="mb-3 eClubWizardMap" isMarkerDraggable={false} />}

        {isDeadlineExist && isDeadlineArrived ? (
          <div className="eClubInvitationFormRow">
            <span className="mClubInvitationFormBold">Unfortunately, booking for this activity has now closed.</span>
          </div>
        ) : (
          <div className="eClubInvitationFormRow">
            <span className="mClubInvitationFormBold">
              {`To reserve a place for you, click the 'Book now' button below`}
            </span>
          </div>
        )}

        {isDeadlineExist && (
          <div className="eClubInvitationFormRow">{`Deadline for answers: ${deadlineForAnswersFormatted}`}</div>
        )}

        <button onClick={this.props.closeClubInvitationModal} className="btn btn-secondary mr-2">
          Cancel
        </button>
        {!(isDeadlineExist && isDeadlineArrived) && (
          <>
            <button onClick={this.props.onDeclineClick} className="btn btn-primary mr-2">
              Decline
            </button>
            <button onClick={this.onSubmit} className="btn btn-primary mr-2">
              Book now
            </button>
          </>
        )}
      </>
    );
  }
}
