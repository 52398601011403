import * as React from 'react';
import * as propz from 'propz';
import { FunctionComponent } from 'react';
import * as classNames from 'classnames';

interface Props {
  onCloseClick: () => void;
  onAcceptClick: () => void;
  isOpen: boolean;
  request: any;
  mergeUser: any;
}

export const AcceptAndMergeRequestInfoModal: FunctionComponent<Props> = props => {
  if (!props.isOpen) {
    return null;
  }

  const modalClasses = classNames({
    mDisplayBlock: props.isOpen,
    show: props.isOpen,
    modal: true,
    fade: true
  });

  const request = props.request;
  const mergeUser = props.mergeUser;

  const getConfirmMessage = (request: string, mergeUser: string) => {
    const requesterFirstName = propz.get(request, ['requester', 'firstName'], '');
    const requesterLastName = propz.get(request, ['requester', 'lastName'], '');
    const mergeUserFirstName = propz.get(mergeUser, ['user', 'firstName'], '');
    const mergeUserLastName = propz.get(mergeUser, ['user', 'lastName'], '');
    const mergeUserDOB = propz.get(mergeUser, ['user', 'birthday'], '');

    return (
      <div>
        <h5>
          {mergeUserFirstName} {mergeUserLastName} {mergeUserDOB} <br />
          will be merged with the request for the role on behalf of <br />
          {requesterFirstName} {requesterLastName}
        </h5>
      </div>
    );
  };

  return (
    <div className={modalClasses}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" onClick={props.onCloseClick}>
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body text-center">{getConfirmMessage(request, mergeUser)}</div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={props.onCloseClick}>
              Close
            </button>
            <button type="button" className="btn btn-primary" onClick={props.onAcceptClick}>
              Merge
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

AcceptAndMergeRequestInfoModal.displayName = 'AcceptAndMergeRequestInfoModal';
