import * as React from 'react';
import * as BPromise from 'bluebird';
import { AppUser } from 'Src/views/App/App';
import { parse } from 'query-string';
import { getOrder, getSearchFilter, getSearchOrder, getServerFieldSectionWhere } from 'Src/helpers/table/table';
import {
  ColumnDefinition,
  getFilters2,
  getPagesCount,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { History, Location } from 'history';
import { Grid2 } from 'Src/components/Grid/Grid2';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { ResizablePanel } from 'Src/components/ResizablePanel/ResizablePanel';
import { GenericSummary } from 'Src/components/GenericSummary/GenericSummary';
import { getEndTime, getSeason, getStartTime } from 'Src/helpers/accessor/accessor';
import { Championship } from 'Src/models/championship';
import {
  createChampionship,
  deleteChampionship,
  getAllChampionships,
  getChampionships,
  getChampionshipsCount,
  updateChampionship
} from 'Src/helpers/service/adminSU/championshipsTable';
import { CHAMPIONSHIP_TABS } from 'Src/consts/championship';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE, LIMIT } from 'Src/consts/table';
import { Loader } from 'Src/components/Loader/Loader';
import { ChampionshipSummary } from 'Src/components/ChampionshipSummary/ChampionshipSummary';
import { ChampionshipForm } from './ChampionshipForm/ChampionshipForm';
import { Button } from 'Src/components/Button/Button';
import { getSelectOptionForSeason } from 'Src/helpers/table/select';
import { ChampionshipPublicSiteSettingsForm } from './ChampionshipPublicSiteSettingsForm/ChampionshipPublicSiteSettingsForm';
import { ChampionshipPublicSiteSettingsSummary } from 'Src/components/ChampionshipPublicSiteSettingsSummary/ChampionshipPublicSiteSettingsSummary';
import { ChampionshipTournamentsSummary } from 'Src/components/ChampionshipTournamentsSummary/ChampionshipTournamentsSummary';
import { getIsPromoteTournament, getIsPublishOnTheWebsite } from '../../../../helpers/accessor/accessor';
import { getSelectOptionForBooleanQuestion } from '../../../../helpers/table/select';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Name',
    field: 'name',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['name']
  },
  {
    text: 'Season',
    field: 'season',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getSeason
  },
  {
    text: 'Start',
    field: 'startTime',
    isSort: true,
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: getStartTime
  },
  {
    text: 'End',
    field: 'endTime',
    isSort: true,
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: getEndTime
  },
  {
    text: 'Publish on union website',
    field: 'isPublishOnTheWebsite',
    isSort: true,
    type: FILTER_TYPE.SELECT,
    accessor: getIsPublishOnTheWebsite
  },
  {
    text: 'Highlighted',
    field: 'isPromoteTournament',
    isSort: true,
    type: FILTER_TYPE.SELECT,
    accessor: getIsPromoteTournament
  }
];

interface State {
  items: Championship[];
  currentPage: number;
  selectedItems: Championship[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  filters: any;
  isDataFiltered: boolean;
  isLoading: boolean;
  total: number;
  basePath: string;
  isSelectAllChecked: boolean;
  isDeleteChampionshipModalOpen: boolean;
  currentTabIndex: number;
  isEditable: boolean;
  isEditablePublicSiteSettings: boolean;
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export class Championships extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      filters: {},
      isDataFiltered: false,
      isLoading: false,
      total: 0,
      basePath: '',
      isSelectAllChecked: false,
      isDeleteChampionshipModalOpen: false,
      currentTabIndex: 0,
      isEditable: false,
      isEditablePublicSiteSettings: false
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setItems();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters, { isCorrectTime: true });

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const getItemsPromise = getChampionships(user, serverQueryFilter);
    const getItemsCountPromise = getChampionshipsCount(user, where);

    const promises = [getItemsCountPromise, getItemsPromise];

    return BPromise.all(promises).then(([countObj, championship]) => {
      this.setState({
        currentPage: page,
        sortDirection: sortDirection,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: championship,
        total: countObj.count,
        basePath: history.location.pathname,
        isLoading: false
      });

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated,
      currentTabIndex: 0
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: nextSelectedItems,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    getAllChampionships(user, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const nextSelectedItems = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: nextSelectedItems,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: ''
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  renderDeleteChampionshipModal(): React.ReactNode {
    const { selectedItems, isDeleteChampionshipModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isDeleteChampionshipModalOpen}
        title={'Delete championship'}
        body={`Are you sure you want to remove ${
          selectedItems.length === 1 ? `championship ${selectedItems[0].name}` : `${selectedItems.length} championships`
        }?`}
        buttonText={'Delete'}
        onButtonClick={this.onDeleteChampionshipClick}
        onCloseClick={this.deleteChampionshipModalClose}
      />
    );
  }

  onDeleteChampionshipClick = () => {
    const { selectedItems } = this.state;
    const user = this.props.user;
    const championshipIdList = selectedItems.map(item => item.id);

    this.setState({
      isLoading: true
    });

    BPromise.all(championshipIdList.map(championshipId => deleteChampionship(user, championshipId))).then(res => {
      this.setState({
        isDeleteChampionshipModalOpen: false,
        isSelectAllChecked: false,
        selectedItems: []
      });
      this.setItems();
    });
  };

  deleteChampionshipModalClose = (): void => {
    this.setState({
      isDeleteChampionshipModalOpen: false
    });
  };

  renderFormModal(): React.ReactNode {
    const { isEditable, selectedItems } = this.state;
    const { user } = this.props;

    switch (selectedItems.length) {
      case 0:
        return (
          <SimpleModal isOpen={isEditable}>
            <ChampionshipForm user={user} onCancel={this.onCancelButtonClick} onSubmit={this.onSubmitForm} />
          </SimpleModal>
        );
      case 1:
        return (
          <SimpleModal isOpen={isEditable}>
            <ChampionshipForm
              user={user}
              championship={selectedItems[0]}
              onCancel={this.onCancelButtonClick}
              onSubmit={this.onSubmitForm}
            />
          </SimpleModal>
        );
    }
  }

  onSubmitForm = (data): void => {
    const { selectedItems } = this.state;
    const { user } = this.props;

    this.setState({
      isLoading: true
    });

    if (selectedItems.length === 0) {
      createChampionship(user, data).then(championship => {
        this.setState({
          isEditable: false,
          selectedItems: [championship]
        });
        //go to last page
        this.setItems().then(res => {
          const { total } = this.state;
          const lastPage = getPagesCount(total, LIMIT);
          this.setCurrentPageParams(lastPage);
        });
      });
    } else {
      const championshipId = selectedItems[0].id;

      updateChampionship(user, data, championshipId).then(championshipUpdated => {
        this.setState({
          isEditable: false,
          isEditablePublicSiteSettings: false,
          selectedItems: [championshipUpdated]
        });

        this.setItems();
      });
    }
  };

  onCancelButtonClick = (): void => {
    this.setState({
      isEditable: false
    });
  };

  onCreateChampionshipButtonClick = (): void => {
    this.setState({
      isEditable: true,
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onEditChampionshipButtonClick = (): void => {
    this.setState({
      isEditable: true
    });
  };

  deleteChampionshipModalOpen = (): void => {
    this.setState({
      isDeleteChampionshipModalOpen: true
    });
  };

  onCancelPublicSiteSettingsButtonClick = (): void => {
    this.setState({
      isEditablePublicSiteSettings: false
    });
  };

  onEditPublicSiteSettingsButtonClick = (): void => {
    this.setState({
      isEditablePublicSiteSettings: true
    });
  };

  isOneItemSelected(): boolean {
    const selectedItems = this.state.selectedItems;
    return selectedItems.length === 1;
  }

  getSummaryTabs(): React.ReactNode {
    const { currentTabIndex, selectedItems } = this.state;
    const { user } = this.props;

    switch (currentTabIndex) {
      case 0:
        return <ChampionshipSummary championship={selectedItems[0]} user={user} />;
      case 1:
        return <ChampionshipTournamentsSummary championship={selectedItems[0]} />;
      case 2:
        return <ChampionshipPublicSiteSettingsSummary user={user} championship={selectedItems[0]} />;
    }
  }

  onTabClick = (event, index: number) => {
    event.preventDefault();

    this.setState({
      currentTabIndex: index
    });
  };

  onEditChampionship = (value, field) => {
    const { selectedItems } = this.state;
    const { user } = this.props;

    this.setState({
      isLoading: true
    });

    BPromise.all(
      selectedItems.map(tournament => {
        const dataCopy = { [field]: value };
        return updateChampionship(user, dataCopy, tournament.id);
      })
    )
      .then(res => {
        this.setState({
          selectedItems: []
        });
        this.setItems();
      })
      .catch(error => {
        console.error(error.response.data.details.text);
      });
  };

  renderSummary(): React.ReactNode {
    const isOneItemSelected = this.isOneItemSelected();

    if (!isOneItemSelected) {
      return <div className="font-weight-bold">Select a championship above</div>;
    }

    const { currentTabIndex, selectedItems } = this.state;

    const championship = selectedItems[0];

    const summaryTabs = this.getSummaryTabs();
    const isShowEditButton = currentTabIndex === 0;
    const isShowPublicSiteSettingsButton = currentTabIndex === 2;

    return (
      <GenericSummary onTabClick={this.onTabClick} tabs={CHAMPIONSHIP_TABS} currentTabIndex={currentTabIndex}>
        {isShowEditButton && (
          <Button onClick={this.onEditChampionshipButtonClick} text={'Edit championship'} customClass={'mt-3 mb-3'} />
        )}
        {isShowPublicSiteSettingsButton && (
          <Button
            onClick={this.onEditPublicSiteSettingsButtonClick}
            text={'Edit championship website settings'}
            customClass={'mt-3 mb-3'}
          />
        )}
        {summaryTabs}
      </GenericSummary>
    );
  }

  renderPublicSiteSettingsModal() {
    const { isEditablePublicSiteSettings, selectedItems } = this.state;
    const { user } = this.props;

    return (
      <SimpleModal isOpen={isEditablePublicSiteSettings} title={'Edit championship website settings'}>
        <ChampionshipPublicSiteSettingsForm
          user={user}
          championship={selectedItems[0]}
          onCancel={this.onCancelPublicSiteSettingsButtonClick}
          onSubmit={this.onSubmitForm}
        />
      </SimpleModal>
    );
  }

  renderGrid() {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isSelectAllChecked,
      currentPage,
      total
    } = this.state;

    const isAllYesPromoteTournamentSelectedItems = selectedItems.every(item => item.isPromoteTournament);
    const isAllNoPromoteTournamentSelectedItems = selectedItems.every(item => !item.isPromoteTournament);
    const isAllYesPublishOnTheWebsiteSelectedItems = selectedItems.every(item => item.isPublishOnTheWebsite);
    const isAllNoPublishOnTheWebsiteSelectedItems = selectedItems.every(item => !item.isPublishOnTheWebsite);

    const actionItems = [
      {
        itemText: 'Create championship',
        onItemClick: this.onCreateChampionshipButtonClick,
        isActive: selectedItems.length === 0
      },
      {
        itemText: selectedItems.length === 1 ? 'Delete championship' : 'Delete championships',
        onItemClick: this.deleteChampionshipModalOpen,
        isActive: selectedItems.length > 0
      },
      {
        itemText:
          isAllYesPublishOnTheWebsiteSelectedItems && !isAllNoPublishOnTheWebsiteSelectedItems
            ? 'Unpublish from union website'
            : 'Publish on union website',
        onItemClick: () =>
          this.onEditChampionship(
            !isAllYesPublishOnTheWebsiteSelectedItems && isAllNoPublishOnTheWebsiteSelectedItems,
            'isPublishOnTheWebsite'
          ),
        isActive:
          selectedItems.length > 0 &&
          (isAllYesPublishOnTheWebsiteSelectedItems || isAllNoPublishOnTheWebsiteSelectedItems)
      },
      {
        itemText:
          isAllYesPromoteTournamentSelectedItems && !isAllNoPromoteTournamentSelectedItems
            ? 'Unhighlight'
            : 'Highlight',
        onItemClick: () =>
          this.onEditChampionship(
            !isAllYesPromoteTournamentSelectedItems && isAllNoPromoteTournamentSelectedItems,
            'isPromoteTournament'
          ),
        isActive:
          selectedItems.length > 0 && (isAllYesPromoteTournamentSelectedItems || isAllNoPromoteTournamentSelectedItems)
      }
    ];

    const filterOptions = {
      season: getSelectOptionForSeason(),
      isPublishOnTheWebsite: getSelectOptionForBooleanQuestion(),
      isPromoteTournament: getSelectOptionForBooleanQuestion()
    };

    return (
      <Grid2
        dataItems={items}
        filters={filters}
        currentPage={currentPage}
        total={total}
        isSelectAllChecked={isSelectAllChecked}
        isDataFiltered={isDataFiltered}
        sortDirection={sortDirection}
        sortColumnsName={sortColumnsName}
        isShowFilter={isShowFilter}
        dataItemsSelected={selectedItems}
        columns={COLUMNS}
        actionItems={actionItems}
        options={filterOptions}
        onItemClick={this.onItemClick}
        onSortClick={this.onTableSortClick}
        onApplyFilterClick={this.onApplyFilterClick}
        onClearFilterClick={this.onClearFilterClick}
        onTableFilterChange={this.onTableFilterChange}
        onTableFilterClick={this.onTableFilterClick}
        setCurrentPageParams={this.setCurrentPageParams}
        onSelectAllClick={this.onSelectAllClick}
        onSelectAllOnPageClick={this.onSelectAllOnPageClick}
        onUnselectAllClick={this.onUnselectAllClick}
      />
    );
  }

  render() {
    const { isLoading, isEditable, isDeleteChampionshipModalOpen, isEditablePublicSiteSettings } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes =
      isEditable || isDeleteChampionshipModalOpen || isEditablePublicSiteSettings ? 'mt-3 modal-open' : 'mt-3';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderPublicSiteSettingsModal()}
        {this.renderDeleteChampionshipModal()}
        {this.renderFormModal()}
        <div className="row">
          <div className="col-md-12">
            <ResizablePanel>
              {this.renderGrid()}
              {this.renderSummary()}
            </ResizablePanel>
          </div>
        </div>
      </div>
    );
  }
}
