import * as React from 'react';
import * as propz from 'propz';
import { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import DatePicker from 'react-datepicker';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from '../../../../../../consts/report';
import { Switch } from 'Src/components/Switch/Switch';
import { LabelWithQuestionIcon } from '../../../../../../components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import { MultiSelectCheckboxes } from '../../../../../../components/MultiSelectCheckboxes/MultiSelectCheckboxes';
import {
  getReportDefaultGendersForMultiSelect,
  getReportAgesForMultiSelect
} from '../../../../../../helpers/multiselect/multiselect';
import { School } from '../../../../../../models/school';
import { SchoolForm } from '../../../../../../models/form';
import { getStartDateForSchoolYear } from '../../../../../../helpers/calendar/calendar';
import '../SchoolEvents.scss';

const OPTIONS = {
  IS_CURRENT_WEEK: 'isCurrentWeek',
  IS_NEXT_WEEK: 'isNextWeek',
  IS_CURRENT_MONTH: 'isCurrentMonth',
  IS_NEXT_MONTH: 'isNextMonth',
  IS_CUSTOM_INTERVAL: 'isCustomInterval'
};

interface Props {
  onCloseClick: () => void;
  onSubmit: (data: any, reportType: string) => void;
  school: School;
  forms: SchoolForm[];
}

export function FixturesScheduleReport({ onCloseClick, onSubmit, school, forms }: Props) {
  const now = new Date();
  const genders = getReportDefaultGendersForMultiSelect();
  const ages = getReportAgesForMultiSelect(school, forms);

  const [isAgesAndGendersChecked, setIsAgesAndGendersChecked] = useState(true);

  const requestInitial = {
    options: OPTIONS.IS_CURRENT_WEEK,
    isCurrentWeek: false,
    isNextWeek: false,
    isCurrentMonth: false,
    isNextMonth: false,
    isCustomInterval: false,
    genders,
    ages,
    isSelectAllAges: true,
    dateFrom: (() => {
      const today = new Date();
      today.setHours(0, 0, 1, 0);
      return today;
    })(),
    dateTo: (() => {
      const today = new Date();
      const nextWeek = new Date(today);
      nextWeek.setDate(today.getDate() + 6);
      nextWeek.setHours(23, 59, 59, 999);
      return nextWeek;
    })(),
    isShowTournament: true
  };

  switch (requestInitial.options) {
    case OPTIONS.IS_CURRENT_WEEK: {
      const today = new Date();
      const dayOfWeek = today.getDay();
      const daysUntilMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
      const closestPastMonday = new Date(today);
      closestPastMonday.setDate(today.getDate() - daysUntilMonday);

      const nextSunday = new Date(closestPastMonday);
      nextSunday.setDate(closestPastMonday.getDate() + 7);

      requestInitial.dateFrom = new Date(closestPastMonday.setHours(0, 0, 1, 0)); // 00:00:01
      requestInitial.dateTo = new Date(nextSunday.setHours(23, 59, 59, 999)); // 23:59:59
      break;
    }

    case OPTIONS.IS_NEXT_WEEK: {
      const today = new Date();
      const dayOfWeek = today.getDay();
      const daysUntilMonday = dayOfWeek === 0 ? 6 : 7 - dayOfWeek; // Days until next Monday
      const nextMonday = new Date(today);
      nextMonday.setDate(today.getDate() + daysUntilMonday);

      const nextSunday = new Date(nextMonday);
      nextSunday.setDate(nextMonday.getDate() + 6);

      requestInitial.dateFrom = new Date(nextMonday.setHours(0, 0, 1, 0)); // 00:00:01
      requestInitial.dateTo = new Date(nextSunday.setHours(23, 59, 59, 999)); // 23:59:59
      break;
    }

    case OPTIONS.IS_CURRENT_MONTH: {
      const today = new Date();
      const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      requestInitial.dateFrom = new Date(startOfMonth.setHours(0, 0, 1, 0)); // 00:00:01
      requestInitial.dateTo = new Date(endOfMonth.setHours(23, 59, 59, 999)); // 23:59:59
      break;
    }

    case OPTIONS.IS_NEXT_MONTH: {
      const today = new Date();
      const startOfNextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
      const endOfNextMonth = new Date(today.getFullYear(), today.getMonth() + 2, 0);

      requestInitial.dateFrom = new Date(startOfNextMonth.setHours(0, 0, 1, 0)); // 00:00:01
      requestInitial.dateTo = new Date(endOfNextMonth.setHours(23, 59, 59, 999)); // 23:59:59
      break;
    }
  }

  const onGendersChange = (index, values, setFieldValue) => {
    const genderItems = values.genders;
    const ageItems = values.ages;

    const genderItemsUpdated = genderItems.map((genderItem, genderIndex) => {
      return genderIndex === index
        ? {
            ...genderItem,
            isChecked: !genderItem.isChecked
          }
        : genderItem;
    });

    const gendersChecked = genderItemsUpdated.map(gender => gender.isChecked);
    const agesChecked = ageItems.map(age => age.isChecked);
    const isCheckedGendersExist = gendersChecked.includes(true);
    const isCheckedAgesExist = agesChecked.includes(true);

    setIsAgesAndGendersChecked(isCheckedGendersExist && isCheckedAgesExist);
    setFieldValue('genders', genderItemsUpdated);
  };

  const onAgesChange = (index, values, setFieldValue) => {
    const ageItems = values.ages;
    const genderItems = values.genders;

    const ageItemsUpdated = ageItems.map((ageItem, ageIndex) => {
      return ageIndex === index
        ? {
            ...ageItem,
            isChecked: !ageItem.isChecked
          }
        : ageItem;
    });

    const gendersChecked = genderItems.map(gender => gender.isChecked);
    const agesChecked = ageItemsUpdated.map(age => age.isChecked);
    const isCheckedGendersExist = gendersChecked.includes(true);
    const isCheckedAgesExist = agesChecked.includes(true);

    const isSelectAllAges = ageItemsUpdated.every(age => age.isChecked === true);

    setIsAgesAndGendersChecked(isCheckedAgesExist && isCheckedGendersExist);
    setFieldValue('isSelectAllAges', isSelectAllAges);
    setFieldValue('ages', ageItemsUpdated);
  };

  const onSelectAllAgesChange = (event, values, setFieldValue) => {
    const isSelectAllAges = event.target.checked;
    const ageItems = values.ages;
    const genderItems = values.genders;

    const ageItemsUpdated = ageItems.map(age => {
      return {
        ...age,
        isChecked: isSelectAllAges
      };
    });

    const gendersChecked = genderItems.map(gender => gender.isChecked);
    const agesChecked = ageItemsUpdated.map(age => age.isChecked);
    const isCheckedGendersExist = gendersChecked.includes(true);
    const isCheckedAgesExist = agesChecked.includes(true);

    setIsAgesAndGendersChecked(isCheckedAgesExist && isCheckedGendersExist);
    setFieldValue('isSelectAllAges', isSelectAllAges);
    setFieldValue('ages', ageItemsUpdated);
  };

  return (
    <Formik
      onSubmit={values => {
        const valuesToTransfer = { ...values };
        propz.set(valuesToTransfer, [valuesToTransfer.options], true);
        delete valuesToTransfer.options;

        switch (values.options) {
          case OPTIONS.IS_CURRENT_WEEK:
            const currentWeekToday = new Date();
            const dayOfWeekCurrent = currentWeekToday.getDay();
            const daysUntilMondayCurrent = dayOfWeekCurrent === 0 ? 6 : dayOfWeekCurrent - 1;
            const closestPastMondayCurrent = new Date(currentWeekToday);
            closestPastMondayCurrent.setDate(currentWeekToday.getDate() - daysUntilMondayCurrent);

            const nextSundayCurrent = new Date(closestPastMondayCurrent);
            nextSundayCurrent.setDate(closestPastMondayCurrent.getDate() + 6);

            valuesToTransfer.dateFrom = closestPastMondayCurrent;
            valuesToTransfer.dateTo = new Date(nextSundayCurrent.setHours(23, 59, 59, 999));
            break;

          case OPTIONS.IS_NEXT_WEEK:
            const nextWeekToday = new Date();
            const dayOfWeekNext = nextWeekToday.getDay();
            const daysUntilNextMonday = dayOfWeekNext === 0 ? 6 : 7 - dayOfWeekNext; // Days until next Monday
            const nextMondayWeek = new Date(nextWeekToday);
            nextMondayWeek.setDate(nextWeekToday.getDate() + daysUntilNextMonday);

            const nextSundayWeek = new Date(nextMondayWeek);
            nextSundayWeek.setDate(nextMondayWeek.getDate() + 6);

            valuesToTransfer.dateFrom = new Date(nextMondayWeek.setHours(0, 0, 1, 0)); // 00:00:01
            valuesToTransfer.dateTo = new Date(nextSundayWeek.setHours(23, 59, 59, 999)); // 23:59:59
            break;

          case OPTIONS.IS_CURRENT_MONTH:
            const currentMonthToday = new Date();
            const startOfCurrentMonth = new Date(currentMonthToday.getFullYear(), currentMonthToday.getMonth(), 1);
            const endOfCurrentMonth = new Date(currentMonthToday.getFullYear(), currentMonthToday.getMonth() + 1, 0);

            valuesToTransfer.dateFrom = new Date(startOfCurrentMonth.setHours(0, 0, 1, 0)); // 00:00:01
            valuesToTransfer.dateTo = new Date(endOfCurrentMonth.setHours(23, 59, 59, 999)); // 23:59:59
            break;

          case OPTIONS.IS_NEXT_MONTH:
            const nextMonthToday = new Date();
            const startOfNextMonth = new Date(nextMonthToday.getFullYear(), nextMonthToday.getMonth() + 1, 1);
            const endOfNextMonth = new Date(nextMonthToday.getFullYear(), nextMonthToday.getMonth() + 2, 0);

            valuesToTransfer.dateFrom = new Date(startOfNextMonth.setHours(0, 0, 1, 0)); // 00:00:01
            valuesToTransfer.dateTo = new Date(endOfNextMonth.setHours(23, 59, 59, 999)); // 23:59:59
            break;

          case OPTIONS.IS_CUSTOM_INTERVAL:
            const selectedDateFrom = values.dateFrom;
            const selectedDateTo = values.dateTo;

            valuesToTransfer.dateFrom = selectedDateFrom;
            valuesToTransfer.dateTo = selectedDateTo;
            break;
        }

        onSubmit(valuesToTransfer, REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.FIXTURES_SCHEDULE);
      }}
      initialValues={requestInitial}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <div className="bReportFormTitle">Fixture schedule</div>
          <div className="mb-3 mt-3">View fixture schedule for the selected period.</div>

          <div className="form-group">
            <div className="form-check mb-1">
              <input
                className="form-check-input"
                type="radio"
                name={OPTIONS.IS_CURRENT_WEEK}
                id={OPTIONS.IS_CURRENT_WEEK}
                value={OPTIONS.IS_CURRENT_WEEK}
                checked={values.options === OPTIONS.IS_CURRENT_WEEK}
                onChange={() => {
                  setFieldValue('options', OPTIONS.IS_CURRENT_WEEK);
                }}
              />
              <LabelWithQuestionIcon
                labelText="This week"
                hintText="Select to view the data for the current week"
                customLabelClass="form-check-label pl-2"
                labelHtmlFor={OPTIONS.IS_CURRENT_WEEK}
              />
            </div>

            <div className="form-check mb-1">
              <input
                className="form-check-input"
                type="radio"
                name={OPTIONS.IS_NEXT_WEEK}
                id={OPTIONS.IS_NEXT_WEEK}
                value={OPTIONS.IS_NEXT_WEEK}
                checked={values.options === OPTIONS.IS_NEXT_WEEK}
                onChange={() => {
                  setFieldValue('options', OPTIONS.IS_NEXT_WEEK);
                }}
              />

              <LabelWithQuestionIcon
                labelText="Next week"
                hintText="Select to view the data for the next week"
                customLabelClass="form-check-label pl-2"
                labelHtmlFor={OPTIONS.IS_NEXT_WEEK}
              />
            </div>

            <div className="form-check mb-1">
              <input
                className="form-check-input"
                type="radio"
                name={OPTIONS.IS_CURRENT_MONTH}
                id={OPTIONS.IS_CURRENT_MONTH}
                value={OPTIONS.IS_CURRENT_MONTH}
                checked={values.options === OPTIONS.IS_CURRENT_MONTH}
                onChange={() => {
                  setFieldValue('options', OPTIONS.IS_CURRENT_MONTH);
                }}
              />

              <LabelWithQuestionIcon
                labelText="This month"
                hintText="Select to view the data for the current month"
                customLabelClass="form-check-label pl-2"
                labelHtmlFor={OPTIONS.IS_CURRENT_MONTH}
              />
            </div>

            <div className="form-check mb-1">
              <input
                className="form-check-input"
                type="radio"
                name={OPTIONS.IS_NEXT_MONTH}
                id={OPTIONS.IS_NEXT_MONTH}
                value={OPTIONS.IS_NEXT_MONTH}
                checked={values.options === OPTIONS.IS_NEXT_MONTH}
                onChange={() => {
                  setFieldValue('options', OPTIONS.IS_NEXT_MONTH);
                }}
              />

              <LabelWithQuestionIcon
                labelText="Next month"
                hintText="Select to view the data for the next month"
                customLabelClass="form-check-label pl-2"
                labelHtmlFor={OPTIONS.IS_NEXT_MONTH}
              />
            </div>

            <div className="form-check mb-1">
              <input
                className="form-check-input"
                type="radio"
                name="options"
                id={OPTIONS.IS_CUSTOM_INTERVAL}
                value={OPTIONS.IS_CUSTOM_INTERVAL}
                checked={values.options === OPTIONS.IS_CUSTOM_INTERVAL}
                onChange={() => {
                  setFieldValue('options', OPTIONS.IS_CUSTOM_INTERVAL);

                  let dateFrom = getStartDateForSchoolYear(now);

                  setFieldValue('dateFrom', new Date(dateFrom.setHours(0, 0, 1, 0)));
                  setFieldValue('dateTo', new Date(now.setHours(23, 59, 59, 999)));
                }}
              />

              <LabelWithQuestionIcon
                labelText="Custom interval"
                hintText="Select to specify a custom date interval"
                customLabelClass="form-check-label pl-2"
                labelHtmlFor={OPTIONS.IS_CUSTOM_INTERVAL}
              />
            </div>

            {values.options === OPTIONS.IS_CUSTOM_INTERVAL && (
              <div>
                <div className="form-group">
                  <label>Date from</label>
                  <Field name="dateFrom">
                    {({ field, form }) => (
                      <div className="mb-3">
                        <DatePicker
                          {...field}
                          selected={field.value}
                          onChange={date => {
                            form.setFieldValue('dateFrom', date);
                            if (form.values.dateTo && form.values.dateTo < date) {
                              form.setFieldValue('dateTo', date);
                            }
                          }}
                          className="form-control"
                          dateFormat="dd-MM-yyyy"
                        />
                      </div>
                    )}
                  </Field>
                </div>
                <div className="form-group">
                  <label>Date to</label>
                  <Field name="dateTo">
                    {({ field, form }) => (
                      <div className="mb-3">
                        <DatePicker
                          {...field}
                          selected={field.value}
                          onChange={date => {
                            form.setFieldValue('dateTo', date);
                          }}
                          minDate={form.values.dateFrom}
                          className="form-control"
                          dateFormat="dd-MM-yyyy"
                        />
                      </div>
                    )}
                  </Field>
                </div>
              </div>
            )}
          </div>

          <div className="mt-3 mb-3">
            <MultiSelectCheckboxes
              items={values.genders}
              cols={3}
              onChange={index => onGendersChange(index, values, setFieldValue)}
            />
          </div>

          <div className="mt-3 mb-3">
            <MultiSelectCheckboxes
              items={values.ages}
              cols={3}
              onChange={index => onAgesChange(index, values, setFieldValue)}
            />
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isSelectAllAges"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isSelectAllAges}
                    customClass="mb-3"
                    onChange={event => onSelectAllAgesChange(event, values, setFieldValue)}
                  />
                )}
              />

              <label htmlFor="isSelectAllAges" className="form-check-label pl-2">
                Select all ages
              </label>
            </div>
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isShowTournament"
                render={({ field }) => <Switch {...field} value={values.isShowTournament} customClass="mb-3" />}
              />
              <label htmlFor="isShowTournament" className="form-check-label pl-2">
                Show tournaments your school is taking part in
              </label>
            </div>
          </div>

          <button type="button" className="btn btn-secondary mr-3" onClick={onCloseClick}>
            Cancel
          </button>

          <button type="submit" className="btn btn-primary">
            Get
          </button>
        </Form>
      )}
    </Formik>
  );
}
