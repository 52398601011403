import * as React from 'react';
import * as propz from 'propz';
import { FunctionComponent } from 'react';
import { SchoolForm } from '../../models/form';

interface Props {
  form: SchoolForm;
}

const ROWS = [
  { title: 'Name', field: 'name' },
  { title: 'Age group ', field: 'ageGroups' }
];

export const FormSummary: FunctionComponent<Props> = props => {
  const rows = ROWS.map((row, index) => {
    let contentCell: string;
    const { field } = row;
    const { form } = props;

    switch (field) {
      case 'ageGroups':
        contentCell = form.ageGroup;
        break;
      case 'name':
        contentCell = form.name;
        break;
      default:
        contentCell = propz.get(form, row.field.split('.'), '');
    }

    return (
      <tr key={`form_summary_${index}`}>
        <td>{row.title}</td>
        <td>{contentCell}</td>
      </tr>
    );
  });

  return (
    <table className={'table table-sm mPre mVerticalAlignMiddle'}>
      <tbody>{rows}</tbody>
    </table>
  );
};

FormSummary.displayName = 'FormSummary';
