import * as React from 'react';
import * as propz from 'propz';
import { Component } from 'react';
import { AppUser } from 'Src/views/App/App';
import { Participant, SchoolEvent, SchoolEventIndividualData, SchoolEventTeamDataPlayer } from 'Src/models/event';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from 'Src/consts/table';
import {
  getAllSchoolIdsFromEvent,
  getEventParticipants,
  getParticipantImg,
  getParticipantName,
  getParticipantTeamResultNumber,
  getParticipantText,
  getHouseColors
} from 'Src/helpers/event/event';
import { Loader } from 'Src/components/Loader/Loader';
import { StudentEventTeamPlayers } from './TeamPlayers/TeamPlayers';
import { StudentEventIndividualPlayers } from './IndividualPlayers/IndividualPlayers';
import './TeamsTab.scss';
import { getSchoolsPublic } from '../../../../../../helpers/service/public/public';
import {
  getParticipantTeamResult,
  isEventStatusFinished,
  isMultipartyEvent,
  isTeamOrTwoOnTwoSportEvent
} from '../../../../../../helpers/event/event';
import { EVENT_RESULTS_MODE } from '../../../../../../consts/event';
import { SchoolEventCricketResult } from 'Src/views/GenericView/AdminView/TeamsAndEvents/Events/SchoolEventView/SchoolEventCricketResult';
import { isCricket } from '../../../../../../helpers/sport/cricket';

interface State {
  isLoading: boolean;
  participants: Participant[];
}

interface Props {
  user: AppUser;
  eventInitial: SchoolEvent;
}

export class StudentEventTeamsSummaryTab extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      participants: []
    };
  }

  componentDidMount() {
    const { eventInitial, user } = this.props;
    const allSchoolIds = getAllSchoolIdsFromEvent(eventInitial);

    this.setState({
      isLoading: true
    });

    const filter = {
      where: { id: { $in: allSchoolIds } },
      view: {
        type: 'id_name_pic'
      },
      skip: DEFAULT_SKIP,
      limit: DEFAULT_LIMIT
    };

    getSchoolsPublic(filter).then(schools => {
      const participants = getEventParticipants(eventInitial, user, schools);

      this.setState({
        isLoading: false,
        participants
      });
    });
  }

  getTextResult(event: SchoolEvent): string {
    let textResult;

    const { resultsMode } = event;

    switch (resultsMode) {
      case EVENT_RESULTS_MODE.PLACES:
        textResult = 'Place: ';
        break;
      case EVENT_RESULTS_MODE.POINTS:
        textResult = 'Points: ';
        break;
      default:
        textResult = '';
        break;
    }

    return textResult;
  }

  render() {
    const { eventInitial, user } = this.props;
    const { isLoading, participants } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const isCricketResultShow =
      isEventStatusFinished(eventInitial) && isCricket(eventInitial) && !isMultipartyEvent(eventInitial);

    return (
      <div className="mt-3">
        {isCricketResultShow && <SchoolEventCricketResult event={eventInitial} user={user} />}
        <div className="eSchoolParticipantScroll">
          {participants.map((participant, index) => {
            const participantName = getParticipantName(eventInitial, participant);
            const participantText = getParticipantText(eventInitial, participant, user);
            const participantImg = getParticipantImg(participant);
            const teamPlayers = propz.get<SchoolEventTeamDataPlayer[]>(participant, ['teamPlayers'], []);
            const individualPlayers = propz.get<SchoolEventIndividualData[]>(participant, ['individualPlayers'], []);
            const isEventFinished = isEventStatusFinished(eventInitial);
            const isTeamOrTwoOnTwoEvent = isTeamOrTwoOnTwoSportEvent(eventInitial);
            const textResult = this.getTextResult(eventInitial);
            const teamResult = isTeamOrTwoOnTwoEvent ? getParticipantTeamResult(eventInitial, participant) : '';
            const teamResultNumber = isTeamOrTwoOnTwoEvent
              ? getParticipantTeamResultNumber(eventInitial, participant)
              : 0;
            const isShowResult = (isEventFinished && isTeamOrTwoOnTwoEvent) || Boolean(teamResultNumber);
            const houseColors = getHouseColors(participant);

            return (
              <div key={`participant_${index}`} className={'eParticipant'}>
                <div className="bSchoolLogoSections">
                  <img className="img-thumbnail eParticipantSchoolLogo" src={participantImg} />
                  <div className="font-weight-bold">
                    <span>{participantName}</span>
                    {houseColors.length > 0 && (
                      <span className="house-colors">
                        {houseColors.map((color, index) => (
                          <span
                            key={index}
                            style={{
                              display: 'inline-block',
                              width: '10px',
                              height: '10px',
                              backgroundColor: color,
                              borderRadius: '50%',
                              marginLeft: '5px'
                            }}
                          ></span>
                        ))}
                      </span>
                    )}
                  </div>
                  {isShowResult && (
                    <div className="ePlayerScore">
                      {textResult} {teamResult}
                    </div>
                  )}
                </div>
                <div className="bSchoolPlayersSections">
                  {participantText ? (
                    <div className="eSchoolParticipantText">{participantText}</div>
                  ) : (
                    <>
                      <StudentEventTeamPlayers players={teamPlayers} event={eventInitial} />
                      <StudentEventIndividualPlayers players={individualPlayers} event={eventInitial} />
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
