import * as React from 'react';
import { Field, Form, Formik } from 'formik';
import DatePicker from 'react-datepicker';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from '../../../../../../consts/report';
import { Switch } from '../../../../../../components/Switch/Switch';
import { LabelWithQuestionIcon } from '../../../../../../components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import { School } from '../../../../../../models/school';
import '../SchoolEvents.scss';

interface Props {
  onCloseClick: () => void;
  onSubmit: (data: any, reportType: string) => void;
  school: School;
}

// report 021
export function DateFixturesReport({ onCloseClick, onSubmit, school }: Props) {
  const now = new Date();

  const requestInitial = {
    dateFrom: now
  };

  return (
    <Formik
      onSubmit={values => onSubmit(values, REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.DATE_FIXTURES)}
      initialValues={requestInitial}
      render={({ values, setFieldValue }) => (
        <Form>
          <div className="bReportFormTitle">Fixture list for the selected date</div>
          <div className="mb-3 mt-3">{'A report to show all the fixtures for your chosen date.'}</div>
          <div className="form-group">
            <label>Date</label>
            <Field
              render={({ field }) => {
                return (
                  <div className="mb-3">
                    <DatePicker
                      selected={values.dateFrom}
                      onChange={dateFrom => {
                        setFieldValue('dateFrom', dateFrom);
                      }}
                      className="form-control"
                      dateFormat={'dd-MM-yyyy'}
                    />
                  </div>
                );
              }}
            />
          </div>

          <button type="button" className="btn btn-secondary mr-3" onClick={onCloseClick}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Get
          </button>
        </Form>
      )}
    />
  );
}
