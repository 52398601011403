import * as React from 'react';
import { Component } from 'react';
import { parse } from 'query-string';
import * as BPromise from 'bluebird';
import * as propz from 'propz';
import {
  ColumnDefinition,
  getFilters2,
  getOrder,
  getSearchFilter,
  getSearchOrder,
  getServerFieldSectionWhere,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';

import { Loader } from 'Src/components/Loader/Loader';
import { AppUser } from 'Src/views/App/App';
import { History, Location } from 'history';
import { Grid2 } from 'Src/components/Grid/Grid2';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { SchoolFile } from '../../../../../models/schoolFile';
import {
  createSchoolFile,
  deleteSchoolFile,
  getAllSchoolFiles,
  getSchoolFiles,
  getSchoolFilesCount,
  updateSchoolFile
} from '../../../../../helpers/service/admin/documents';
import { SchoolFileForm } from './SchoolFileForm/SchoolFileForm';
import { getCreatedAt, getFileCategory, getIsPublished } from '../../../../../helpers/accessor/accessor';
import {
  getSelectOptionForBooleanQuestion,
  getSelectOptionsForFilesCategory
} from '../../../../../helpers/table/select';
import { getFileNameWithLink } from '../../../../../helpers/cell/cell';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Title',
    field: 'title',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['title']
  },
  {
    text: 'Date added',
    field: 'createdAt',
    isSort: true,
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: getCreatedAt
  },
  {
    text: 'File',
    field: 'url',
    isSort: true,
    type: FILTER_TYPE.NONE,
    cell: getFileNameWithLink
  },
  {
    text: 'Published',
    field: 'isPublished',
    isSort: false,
    type: FILTER_TYPE.SELECT,
    accessor: getIsPublished
  },
  {
    text: 'Category',
    field: 'category',
    isSort: false,
    type: FILTER_TYPE.SELECT,
    accessor: getFileCategory
  }
];

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

interface State {
  items: SchoolFile[];
  currentPage: number;
  selectedItems: SchoolFile[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;
  currentTabIndex: number;
  isErrorModalOpen: boolean;
  errorMessage: string;
  schoolFileId: string;
  isEditFilePopupOpened: boolean;
  isRemoveFileModalOpen: boolean;
}

export class SchoolFiles extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',
      currentTabIndex: 0,
      schoolFileId: propz.get(this.props.location, ['state', 'schoolFileId']),
      isErrorModalOpen: false,
      errorMessage: '',
      isEditFilePopupOpened: false,
      isRemoveFileModalOpen: false
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });
    this.setItems();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  setItems() {
    const { history, user } = this.props;
    const { schoolFileId } = this.state;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const getItemsPromise = getSchoolFiles(user, serverQueryFilter);
    const getItemsCountPromise = getSchoolFilesCount(user, where);

    return BPromise.all([getItemsCountPromise, getItemsPromise]).then(([countObj, items]) => {
      if (typeof schoolFileId !== 'undefined') {
        const schoolFile = items.find(item => item.id === schoolFileId);
        this.setState({
          currentPage: page,
          sortDirection: sortDirection as TABLE_SORT_DIRECTION,
          sortColumnsName: sortColumnsName,
          isShowFilter: isShowFilter,
          isDataFiltered: isDataFiltered,
          filters: filters,
          items: items,
          total: countObj.count,
          basePath: history.location.pathname,
          isLoading: false,
          selectedItems: typeof schoolFile !== 'undefined' ? [schoolFile] : [],
          schoolFileId: undefined
        });
        //clear history
        const state = { ...(history.location.state as any) };
        delete state.documentId;
        history.replace({ ...history.location, state });
      } else {
        this.setState({
          currentPage: page,
          sortDirection: sortDirection,
          sortColumnsName: sortColumnsName,
          isShowFilter: isShowFilter,
          isDataFiltered: isDataFiltered,
          filters: filters,
          items: items,
          total: countObj.count,
          basePath: history.location.pathname,
          isLoading: false
        });
      }
      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated,
      currentTabIndex: 0
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: nextSelectedItems,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    getAllSchoolFiles(user, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const nextSelectedItems = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: nextSelectedItems,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (filterField === 'ageGroup') {
      nextFilters.ageGroup = [];
    }

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: ''
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  onSaveButtonClick = (data): void => {
    const { user } = this.props;
    const { selectedItems } = this.state;

    this.setState({
      isLoading: true
    });

    switch (selectedItems.length) {
      case 0: {
        createSchoolFile(user, data)
          .then(file => {
            this.setState({
              isEditFilePopupOpened: false,
              selectedItems: [file]
            });
            this.setItems();
          })
          .catch(error => {
            const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
            console.error(error);
            this.setState({
              isLoading: false,
              errorMessage: errorText,
              isErrorModalOpen: true
            });
          });
        break;
      }
      case 1:
        const fileId = selectedItems[0].id;
        updateSchoolFile(user, fileId, data)
          .then(file => {
            this.setState({
              isEditFilePopupOpened: false,
              selectedItems: [file]
            });
            this.setItems();
          })
          .catch(error => {
            const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
            console.error(error);
            this.setState({
              isLoading: false,
              errorMessage: errorText,
              isErrorModalOpen: true
            });
          });
        break;
    }
  };

  onCancelButtonClick = (): void => {
    this.setState({
      isEditFilePopupOpened: false
    });
  };

  renderEditSchoolFileModal() {
    const { isEditFilePopupOpened, selectedItems } = this.state;
    const { user } = this.props;

    if (selectedItems.length > 1) {
      return null;
    }

    return (
      <SimpleModal isOpen={isEditFilePopupOpened}>
        <SchoolFileForm
          file={selectedItems[0]}
          onSubmit={this.onSaveButtonClick}
          onCancel={this.onCancelButtonClick}
          user={user}
        />
      </SimpleModal>
    );
  }

  onCreateSchoolFile = () => {
    this.setState({
      isEditFilePopupOpened: true
    });
  };

  onEditSchoolFile = () => {
    this.setState({
      isEditFilePopupOpened: true
    });
  };

  openRemoveFilesModal = () => {
    this.setState({
      isRemoveFileModalOpen: true
    });
  };

  cancelRemoveFilesModalClose = (): void => {
    this.setState({
      isRemoveFileModalOpen: false
    });
  };

  onRemoveSchoolFileClick = () => {
    const { selectedItems } = this.state;
    const { user } = this.props;
    if (typeof selectedItems === 'undefined' || selectedItems.length === 0) {
      return;
    }
    this.setState({ isLoading: true });
    const promises = selectedItems.map(document => {
      return deleteSchoolFile(user, document.id);
    });
    BPromise.all(promises)
      .then(() => {
        this.setState({
          selectedItems: [],
          isRemoveFileModalOpen: false
        });
        this.setItems();
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        console.error(error);
        this.setState({
          isLoading: false,
          isRemoveFileModalOpen: false,
          errorMessage: errorText,
          isErrorModalOpen: true
        });
      });
  };

  onPublishSchoolFiles = (value: boolean) => {
    const { selectedItems } = this.state;
    const { user } = this.props;

    this.setState({
      isLoading: true
    });

    BPromise.all(
      selectedItems.map(file => {
        const dataCopy = { isPublished: value };
        return updateSchoolFile(user, file.id, dataCopy);
      })
    )
      .then(res => {
        this.setState({
          selectedItems: []
        });
        this.setItems();
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        console.error(error);
        this.setState({
          isLoading: false,
          errorMessage: errorText,
          isErrorModalOpen: true
        });
      });
  };

  renderSendingError(): React.ReactNode {
    const { errorMessage, isErrorModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isErrorModalOpen}
        title={'Error'}
        body={errorMessage}
        buttonCancelText={'Ok'}
        onCloseClick={this.onCloseErrorClick}
      />
    );
  }

  onCloseErrorClick = () => {
    this.setState({
      isErrorModalOpen: false,
      errorMessage: ''
    });
  };

  renderRemoveFileModal() {
    const { selectedItems, isRemoveFileModalOpen } = this.state;
    return (
      <SimpleModal
        isOpen={isRemoveFileModalOpen}
        title={'Remove document'}
        body={`Are you sure you want to remove ${
          selectedItems.length === 1 ? `document ${selectedItems[0].title}` : `${selectedItems.length} documents`
        }?`}
        buttonText={'Ok'}
        onButtonClick={this.onRemoveSchoolFileClick}
        onCloseClick={this.cancelRemoveFilesModalClose}
      />
    );
  }

  render() {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isLoading,
      isSelectAllChecked,
      currentPage,
      total,
      isEditFilePopupOpened,
      isErrorModalOpen,
      isRemoveFileModalOpen
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes = isErrorModalOpen || isEditFilePopupOpened || isRemoveFileModalOpen ? 'mt-3 modal-open' : 'mt-3';

    const isAllFilesPublishedSelectedItems = selectedItems.every(item => item.isPublished);
    const isAllFilesNoPublishedSelectedItems = selectedItems.every(item => !item.isPublished);

    const actionItems = [
      {
        itemText: 'Add new document',
        onItemClick: this.onCreateSchoolFile,
        isActive: selectedItems.length === 0
      },
      {
        itemText: 'Edit document',
        onItemClick: this.onEditSchoolFile,
        isActive: selectedItems.length === 1
      },
      {
        itemText: 'Delete',
        onItemClick: this.openRemoveFilesModal,
        isActive: selectedItems.length > 0
      },
      {
        itemText: isAllFilesPublishedSelectedItems && !isAllFilesNoPublishedSelectedItems ? 'Unpublish' : 'Publish',
        onItemClick: () =>
          this.onPublishSchoolFiles(!isAllFilesPublishedSelectedItems && isAllFilesNoPublishedSelectedItems),
        isActive: selectedItems.length > 0 && (isAllFilesPublishedSelectedItems || isAllFilesNoPublishedSelectedItems)
      }
    ];

    const filterOptions = {
      isPublished: getSelectOptionForBooleanQuestion(),
      category: getSelectOptionsForFilesCategory()
    };

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderEditSchoolFileModal()}
        {this.renderRemoveFileModal()}
        {this.renderSendingError()}
        <div className="row">
          <div className="col-md-12">
            <Grid2
              dataItems={items}
              filters={filters}
              currentPage={currentPage}
              total={total}
              isSelectAllChecked={isSelectAllChecked}
              isDataFiltered={isDataFiltered}
              sortDirection={sortDirection}
              sortColumnsName={sortColumnsName}
              isShowFilter={isShowFilter}
              dataItemsSelected={selectedItems}
              columns={COLUMNS}
              actionItems={actionItems}
              options={filterOptions}
              onItemClick={this.onItemClick}
              onSortClick={this.onTableSortClick}
              onApplyFilterClick={this.onApplyFilterClick}
              onClearFilterClick={this.onClearFilterClick}
              onTableFilterChange={this.onTableFilterChange}
              onTableFilterClick={this.onTableFilterClick}
              setCurrentPageParams={this.setCurrentPageParams}
              onSelectAllClick={this.onSelectAllClick}
              onSelectAllOnPageClick={this.onSelectAllOnPageClick}
              onUnselectAllClick={this.onUnselectAllClick}
            />
          </div>
        </div>
      </div>
    );
  }
}
