import { AppUser } from 'Src/views/App/App';
import axios from 'axios';
import * as React from 'react';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from 'Src/consts/table';

const DEFAULT_FILTER: any = {
  limit: DEFAULT_LIMIT,
  skip: DEFAULT_SKIP
};

export function getPaymentAccounts(user: AppUser, filter = DEFAULT_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order
      }
    }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/payments/accounts`, config).then(response => {
    return response.data;
  });
}

export function getAllPaymentsAccounts(user: AppUser, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/payments/accounts`, config).then(response => {
    return response.data;
  });
}

export function getPaymentAccountCount(user: AppUser, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where
        }
      }
    };
  } else {
    config = {
      headers: { usid: key }
    };
  }

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/payments/accounts`, config).then(response => {
    // in future /count
    return { count: response.data.length };
  });
}

export function getPaymentAccount(user: AppUser, accountId) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/payments/accounts/${accountId}`, config)
    .then(response => {
      return response.data;
    });
}

export function createPaymentAccount(user: AppUser, account: any) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/payments/accounts`, account, config)
    .then(response => {
      return response.data;
    });
}

export function updatePaymentAccount(user: AppUser, id: string, account: any) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .put(`${window.apiBase}/i/schools/${activeSchoolId}/payments/accounts/${id}`, account, config)
    .then(response => {
      return response.data;
    });
}

export function deletePaymentAccount(user: AppUser, accountId) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .delete(`${window.apiBase}/i/schools/${activeSchoolId}/payments/accounts/${accountId}`, config)
    .then(response => {
      return response.data;
    });
}

export function getPaymentAccountPersons(user: AppUser, accountId, filter = DEFAULT_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order
      }
    }
  };

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/payments/accounts/${accountId}/persons`, config)
    .then(response => {
      return response.data;
    });
}

export function createPaymentAccountPersons(user: AppUser, accountId: string, persons: any[]) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/payments/accounts/${accountId}/persons`, persons, config)
    .then(response => {
      return response.data;
    });
}

export function updatePaymentAccountPersons(user: AppUser, accountId: string, personKey: string, person: any) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .put(
      `${window.apiBase}/i/schools/${activeSchoolId}/payments/accounts/${accountId}/persons/${personKey}`,
      person,
      config
    )
    .then(response => {
      return response.data;
    });
}

export function deletePaymentAccountPersons(user: AppUser, accountId: string, personKey: string) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .delete(`${window.apiBase}/i/schools/${activeSchoolId}/payments/accounts/${accountId}/persons/${personKey}`, config)
    .then(response => {
      return response.data;
    });
}

export function uploadVerificationImage(user: AppUser, formData) {
  const key = user.roleSessionKey;

  const config = {
    headers: {
      usid: key,
      'Content-Type': 'multipart/form-data'
    }
  };

  return axios.post(`${window.apiFile}/stripe/identityDocument`, formData, config).then(response => {
    return response.data;
  });
}

export function getIp() {
  return axios.get(`https://api.ipify.org?format=json&callback=?`).then(response => {
    return response.data;
  });
}

export function createStripeAccountLink(user: AppUser, accountId: string, data: any) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: {
      usid: key
    }
  };

  return axios
    .post(
      `${window.apiBase}/i/schools/${activeSchoolId}/payments/accounts/${accountId}/stripeAccountLink/edit`,
      data,
      config
    )
    .then(response => {
      return response.data;
    });
}
