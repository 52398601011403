import { AppUser } from 'Src/views/App/App';
import axios from 'axios';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from 'Src/consts/table';
import { Count } from '../../../models/count';
import { SchoolEvent } from '../../../models/event';
import { SchoolLeague } from '../../../models/schoolLeague';

const DEFAULT_FILTER: any = {
  limit: DEFAULT_LIMIT,
  skip: DEFAULT_SKIP
};

export function getSchoolLeagues(user: AppUser, filter = DEFAULT_FILTER): Promise<SchoolLeague[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order
      }
    }
  };

  return axios
    .get<SchoolLeague[]>(`${window.apiBase}/i/schools/${activeSchoolId}/schoolLeagues`, config)
    .then(response => {
      return response.data;
    });
}

export function getAllSchoolLeagues(user: AppUser, where?): Promise<SchoolLeague[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios
    .get<SchoolLeague[]>(`${window.apiBase}/i/schools/${activeSchoolId}/schoolLeagues`, config)
    .then(response => {
      return response.data;
    });
}

export function getSchoolLeaguesCount(user: AppUser, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where
        }
      }
    };
  } else {
    config = {
      headers: { usid: key }
    };
  }

  return axios
    .get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/schoolLeagues/count`, config)
    .then(response => {
      return response.data;
    });
}

export function createSchoolLeagues(user: AppUser, league: any) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios.post(`${window.apiBase}/i/schools/${activeSchoolId}/schoolLeagues`, league, config).then(response => {
    return response.data;
  });
}

export function updateSchoolLeagues(user: AppUser, leagueId: string, data: any) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .put(`${window.apiBase}/i/schools/${activeSchoolId}/schoolLeagues/${leagueId}`, data, config)
    .then(response => {
      return response.data;
    });
}

export function deleteSchoolLeagues(user: AppUser, leagueId) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .delete(`${window.apiBase}/i/schools/${activeSchoolId}/schoolLeagues/${leagueId}`, config)
    .then(response => {
      return response.data;
    });
}

export function getSchoolLeague(user: AppUser, leagueId): Promise<SchoolLeague> {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .get<SchoolLeague>(`${window.apiBase}/i/schools/${activeSchoolId}/schoolLeagues/${leagueId}`, config)
    .then(response => {
      return response.data;
    });
}

export function addEventToSchoolLeague(user: AppUser, leagueId, data: any) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/schoolLeagues/${leagueId}/attach`, data, config)
    .then(response => {
      return response.data;
    });
}

export function removeEventToSchoolLeague(user: AppUser, leagueId, data: any) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/schoolLeagues/${leagueId}/detach`, data, config)
    .then(response => {
      return response.data;
    });
}

export function getSchoolLeagueEvents(user: AppUser, leagueId, filter = DEFAULT_FILTER): Promise<SchoolEvent[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order
      }
    }
  };

  return axios
    .get<SchoolEvent[]>(`${window.apiBase}/i/schools/${activeSchoolId}/schoolLeagues/${leagueId}/events`, config)
    .then(response => {
      return response.data;
    });
}

export function getAllSchoolLeagueEvents(user: AppUser, leagueId, where?): Promise<SchoolEvent[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios
    .get<SchoolEvent[]>(`${window.apiBase}/i/schools/${activeSchoolId}/schoolLeagues/${leagueId}/events`, config)
    .then(response => {
      return response.data;
    });
}

export function getSchoolLeagueEventsCount(user: AppUser, leagueId, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where
        }
      }
    };
  } else {
    config = {
      headers: { usid: key }
    };
  }

  return axios
    .get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/schoolLeagues/${leagueId}/events/count`, config)
    .then(response => {
      return response.data;
    });
}
