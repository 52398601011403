import * as React from 'react';
import * as BPromise from 'bluebird';
import * as propz from 'propz';
import * as Moment from 'moment';
import { AppUser } from 'Src/views/App/App';
import { parse } from 'query-string';
import { getOrder, getSearchFilter, getSearchOrder, getServerFieldSectionWhere } from 'Src/helpers/table/table';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { Loader } from 'Src/components/Loader/Loader';
import {
  ColumnDefinition,
  getFilters2,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { History, Location } from 'history';
import { Grid2 } from 'Src/components/Grid/Grid2';
import {
  getClubMessageStatus,
  getUpdatedAt,
  getClubMessageDeadline,
  getIsParticipant
} from 'Src/helpers/accessor/accessor';
import { Club } from 'Src/models/club';
import {
  getClub,
  updateClubBookingType,
  getAvailableClubSpacesMax,
  cancelClubMessageOutdatedStatus
} from 'Src/helpers/service/admin/clubs';
import { getAllClubMessages, getClubMessages, getClubMessagesCount } from 'Src/helpers/service/admin/clubMessages';
import { updateClubMessage } from 'Src/helpers/service/admin/clubStudents';
import { ClubMessage } from 'Src/models/clubMessage';
import { UK_INPUT_MASK_VALIDATION, UK_INPUT_MASK } from 'Src/consts/date';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { Switch } from 'Src/components/Switch/Switch';
import { CLUB_CONFIRMATION_REQUEST_TABS, CLUB_STATUS } from 'Src/consts/club';
import { addClubStudents } from 'Src/helpers/service/admin/clubStudents';
import {
  getSelectOptionsForClubPriority,
  getSelectOptionsForClubMessagesStatus,
  getSelectOptionForBooleanQuestion
} from 'Src/helpers/table/select';
import { ResizablePanel } from 'Src/components/ResizablePanel/ResizablePanel';
import { CONFIRMATION_REQUESTS_STATUS } from 'Src/consts/message';
import { GenericSummary } from 'Src/components/GenericSummary/GenericSummary';
import MaskedInput from 'react-text-mask';
import { ClubConfirmationRequestSummary } from './ClubConfirmationRequestSummary/ClubConfirmationRequestSummary';
import { DEFAULT_CLUB_TABLE_FILTERS } from './Clubs';
import { MESSAGE_INVITATION_STATUS, CLUB_PARTICIPATION_MESSAGE_INVITATION_STATUS } from 'Src/consts/message';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Name',
    field: 'firstName',
    isSort: false,
    type: FILTER_TYPE.TEXT,
    accessor: ['playerDetailsData', 'firstName']
  },
  {
    text: 'Surname',
    field: 'lastName',
    isSort: false,
    type: FILTER_TYPE.TEXT,
    accessor: ['playerDetailsData', 'lastName']
  },
  {
    text: 'Updated',
    field: 'updatedAt',
    isSort: true,
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: getUpdatedAt
  },
  {
    text: 'Invitation status',
    field: 'invitationStatus',
    isSort: true,
    type: FILTER_TYPE.SELECT,
    accessor: getClubMessageStatus
  },
  {
    text: 'Priority',
    field: 'priority',
    isSort: true,
    accessor: ['priority'],
    type: FILTER_TYPE.SELECT
  },
  {
    text: 'Deadline',
    field: 'deadlineForAnswers',
    isSort: true,
    accessor: getClubMessageDeadline,
    type: FILTER_TYPE.NONE
  },
  {
    text: 'Participant',
    field: 'participant',
    isSort: false,
    type: FILTER_TYPE.SELECT,
    accessor: getIsParticipant
  }
];

interface ErrorDetail {
  name: string;
  maxParticipants: number;
  participantsCount: number;
  potentialClubParticipantsCount: number;
}

interface State {
  items: ClubMessage[];
  currentPage: number;
  selectedItems: ClubMessage[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  itemsWithWaitingListCanBookStatusCount: number;
  basePath: string;
  deadlineForAnswers: string;
  additionalOptions: {
    isBookingFromWaitingList: boolean;
  };

  club: Club;
  availableParticipants: number;

  isInviteToBookModalOpen: boolean;
  isClubErrorModalOpen: boolean;
  isClubInfoModalOpen: boolean;

  currentTabIndex: number;
  errors: ErrorDetail[];
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export class ClubMessages extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      itemsWithWaitingListCanBookStatusCount: 0,
      basePath: '',
      deadlineForAnswers: '',
      additionalOptions: {
        isBookingFromWaitingList: true
      },

      club: undefined,
      availableParticipants: 0,

      isInviteToBookModalOpen: false,
      isClubErrorModalOpen: false,
      isClubInfoModalOpen: false,

      currentTabIndex: 0,
      errors: []
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setAdditionalItems().then(res => {
      this.setItems();
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  getClubId(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    const clubId = search.club;
    return clubId;
  }

  setAdditionalItems() {
    const { user } = this.props;
    const { additionalOptions } = this.state;
    const clubId = this.getClubId();

    const promises = [getClub(user, clubId), getAvailableClubSpacesMax(user, clubId)];

    return BPromise.all(promises).then(([club, availableParticipantsObj]) => {
      const isBookingFromWaitingList = propz.get(club, ['isBookingFromWaitingList'], true);

      const additionalOptionsUpdated = {
        ...additionalOptions,
        isBookingFromWaitingList
      };

      this.setState({
        club,
        availableParticipants: availableParticipantsObj.availableParticipants,
        additionalOptions: additionalOptionsUpdated
      });

      return true;
    });
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const clubId = this.getClubId();

    const whereStatusWaitingListCanBook = {
      invitationStatus: {
        $in: MESSAGE_INVITATION_STATUS.WAITING_LIST_CAN_BOOK
      }
    };

    const getItemsPromise = getClubMessages(user, clubId, serverQueryFilter);
    const getItemsCountPromise = getClubMessagesCount(user, clubId, where);
    const getItemsWithWaitingListCanBookStatusCountPromise = getClubMessagesCount(
      user,
      clubId,
      whereStatusWaitingListCanBook
    );

    const promises = [getItemsPromise, getItemsCountPromise, getItemsWithWaitingListCanBookStatusCountPromise];

    return BPromise.all(promises).then(([items, countObj, waitingListCanBookStatusCountObj]) => {
      this.setState({
        currentPage: page,
        sortDirection: sortDirection,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: items,
        total: countObj.count,
        itemsWithWaitingListCanBookStatusCount: waitingListCanBookStatusCountObj.count,
        basePath: history.location.pathname,
        isLoading: false
      });

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const clubId = this.getClubId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `club=${clubId}&${search.join('&')}`
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: nextSelectedItems,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    const clubId = this.getClubId();

    getAllClubMessages(user, clubId, where).then(messages => {
      const messagesFiltered = messages.filter(message => {
        return selectedItems.every(selectedMessage => selectedMessage.id !== message.id);
      });

      const selectedMessages = [...selectedItems, ...messagesFiltered];

      this.setState({
        selectedItems: selectedMessages,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const clubId = this.getClubId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `club=${clubId}&${search.join('&')}`
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { location } = this.props;
    const { state } = location;
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const clubId = this.getClubId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `club=${clubId}&${search.join('&')}`,
      state
    });

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onClearFilterClick = (): void => {
    const { location } = this.props;
    const { state } = location;

    const clubId = this.getClubId();

    this.setState({
      selectedItems: []
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: `club=${clubId}`,
      state
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  renderClubErrorsModal = () => {
    const { isClubErrorModalOpen, errors } = this.state;
    const errorMessages = errors
      .map(error => {
        return `Club name: ${error.name}, Spaces available: ${error.maxParticipants -
          error.participantsCount}, Students you are trying to add: ${error.potentialClubParticipantsCount}`;
      })
      .join('\n');

    return (
      <SimpleModal
        isOpen={isClubErrorModalOpen}
        title="Add students to club"
        body={`There are no sufficient spaces in the club(s): \n\n${errorMessages}\nPlease either amend the number of spaces available on the club settings page or deselect some students to match the number of available spaces.`}
        onCloseClick={this.clubErrorModalClose}
      />
    );
  };

  clubErrorModalClose = () => {
    this.setState({
      isClubErrorModalOpen: false
    });
  };

  addStudentsToClub = () => {
    const { selectedItems, club } = this.state;
    const { user } = this.props;
    const clubId = this.getClubId();

    this.setState({ isLoading: true });

    const participantsData = selectedItems.map(item => ({
      userId: item.playerDetails.userId,
      permissionId: item.playerDetails.permissionId
    }));

    addClubStudents(user, clubId, { participants: participantsData })
      .then(() => getClub(user, clubId))
      .then(updatedClub => {
        this.setState({
          club: updatedClub,
          isLoading: false,
          selectedItems: []
        });
        this.setItems();
      })
      .catch(error => {
        console.error('Failed to add participants', error);
        this.setState({ isLoading: false });
      });
  };

  clubInfoModalClose = () => {
    this.setState({
      isClubInfoModalOpen: false
    });
  };

  renderClubInfoModal(): React.ReactNode {
    const { selectedItems, isClubInfoModalOpen, club } = this.state;

    if (typeof club === 'undefined') {
      return null;
    }

    const numberPotentialClubParticipants = selectedItems.length;

    const { maxParticipants, participants, name } = club;
    const numberParticipants = participants.length;

    const infoText = `Club name: ${name} \nSpaces available: ${maxParticipants -
      numberParticipants} \nStudents you are trying to add: ${numberPotentialClubParticipants} \n`;

    return (
      <SimpleModal
        isOpen={isClubInfoModalOpen}
        title={'Add students to club'}
        body={infoText}
        buttonText={'Add'}
        onButtonClick={this.addStudentsToClub}
        onCloseClick={this.clubInfoModalClose}
      />
    );
  }

  onAddToClubClick = () => {
    const { selectedItems, club } = this.state;
    const { user } = this.props;
    const clubId = this.getClubId();
    if (club.maxParticipants !== 0 && club.participants.length + selectedItems.length > club.maxParticipants) {
      const errors = [
        {
          name: club.name,
          maxParticipants: club.maxParticipants,
          participantsCount: club.participants.length,
          potentialClubParticipantsCount: selectedItems.length
        }
      ];
      this.setState({
        isClubErrorModalOpen: true,
        errors: errors
      });
    } else {
      this.setState({ isLoading: true });
      const participantsData = selectedItems.map(item => ({
        userId: item.playerDetails.userId,
        permissionId: item.playerDetails.permissionId
      }));

      addClubStudents(user, clubId, { participants: participantsData })
        .then(() => getClub(user, clubId))
        .then(updatedClub => {
          return this.setItems().then(() => {
            this.setState({
              club: updatedClub,
              selectedItems: [],
              isLoading: false
            });
          });
        })
        .catch(error => {
          console.error('Failed to add participants', error);
          this.setState({ isLoading: false });
        });
    }
  };

  isAddToClubButtonActive(): boolean {
    const { selectedItems, club } = this.state;
    const isActiveClub = typeof club !== 'undefined' && club.status !== 'ACTIVE';
    const isSomeSelectedItemsParticipant = selectedItems.some(message => message.isParticipant);
    return selectedItems.length !== 0 && !isSomeSelectedItemsParticipant && isActiveClub;
  }

  renderInviteToBookModal(): React.ReactNode {
    const { isInviteToBookModalOpen, deadlineForAnswers } = this.state;

    return (
      <SimpleModal
        isOpen={isInviteToBookModalOpen}
        title={'Invite to book'}
        body={''}
        buttonText={'invite'}
        onButtonClick={this.onInviteToBookClick}
        onCloseClick={this.closeInviteToBookModal}
      >
        <div className="mt-4 mb-2">Deadline for answers (optional)</div>
        <MaskedInput
          type="text"
          className={'form-control'}
          value={deadlineForAnswers}
          onChange={this.onDeadlineChange}
          mask={UK_INPUT_MASK}
          placeholder={UK_INPUT_MASK_VALIDATION}
        />
      </SimpleModal>
    );
  }

  openInviteToBookModal = () => {
    this.setState({
      isInviteToBookModalOpen: true
    });
  };

  closeInviteToBookModal = () => {
    this.setState({
      isInviteToBookModalOpen: false,
      deadlineForAnswers: ''
    });
  };

  onDeadlineChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    this.setState({
      deadlineForAnswers: value
    });
  };

  onInviteToBookClick = () => {
    const { user } = this.props;
    const { selectedItems, deadlineForAnswers } = this.state;
    const isDeadlineExist = deadlineForAnswers !== '';
    const clubId = this.getClubId();
    const messageId = selectedItems[0].id;

    const isSelectedItemInvitationStatusWaitingList =
      propz.get(selectedItems[0], ['invitationStatus']) === CLUB_PARTICIPATION_MESSAGE_INVITATION_STATUS.WAITING_LIST;

    const isSelectedItemsInvitationStatusOutdated = selectedItems.every(item => {
      return item.invitationStatus === CLUB_PARTICIPATION_MESSAGE_INVITATION_STATUS.OUTDATED;
    });

    this.setState({
      isLoading: true
    });

    switch (true) {
      case isSelectedItemInvitationStatusWaitingList:
        let data;

        if (isDeadlineExist) {
          const deadlineForAnswersDate = Moment(deadlineForAnswers, UK_INPUT_MASK_VALIDATION, true).toDate();
          const deadlineForAnswersString = deadlineForAnswersDate.toISOString();

          data = {
            invitationStatus: MESSAGE_INVITATION_STATUS.WAITING_LIST_CAN_BOOK,
            deadlineForAnswers: deadlineForAnswersString
          };
        } else {
          data = {
            invitationStatus: MESSAGE_INVITATION_STATUS.WAITING_LIST_CAN_BOOK
          };
        }

        updateClubMessage(user, clubId, messageId, data)
          .then(() => {
            return this.setItems();
          })
          .then(() => {
            this.setState({
              isInviteToBookModalOpen: false,
              selectedItems: []
            });
          });

        break;

      case isSelectedItemsInvitationStatusOutdated:
        const messageIdList = selectedItems.map(item => item.id);

        cancelClubMessageOutdatedStatus(user, clubId, messageIdList)
          .then(() => {
            return this.setItems();
          })
          .then(() => {
            this.setState({
              selectedItems: []
            });
          });

        break;
    }
  };

  onIsBookingFromWaitingListChange = (event, field) => {
    const { user } = this.props;
    const clubId = this.getClubId();

    this.setState({
      isLoading: true
    });

    const { additionalOptions } = this.state;
    const value = event.target.checked;

    const additionalOptionsUpdated = {
      ...additionalOptions,
      [field]: value
    };

    updateClubBookingType(user, clubId, value)
      .then(() => {
        return this.setItems();
      })
      .then(() => {
        this.setState({
          additionalOptions: additionalOptionsUpdated
        });
      });
  };

  renderAdditionalOptions = () => {
    const { additionalOptions, club, itemsWithWaitingListCanBookStatusCount, availableParticipants } = this.state;
    const { isBookingFromWaitingList } = additionalOptions;
    const maxParticipants = propz.get(club, ['maxParticipants']);
    const spacesLeft = maxParticipants === 0 ? 'Unlimited' : availableParticipants;

    return (
      <div className="d-flex flex-wrap">
        <div className="mr-2">
          <Switch
            value={isBookingFromWaitingList}
            onChange={event => this.onIsBookingFromWaitingListChange(event, 'isBookingFromWaitingList')}
            text="Book from waiting list"
            name="isBookingFromWaitingList"
          />
        </div>

        <span className="mr-2">/</span>

        <span className="mr-2">Spaces left: {spacesLeft}</span>

        <span className="mr-2">/</span>

        <span>Invited to book from the Waiting List: {itemsWithWaitingListCanBookStatusCount}</span>
      </div>
    );
  };

  goBack = () => {
    const { history, location } = this.props;
    const search = propz.get(location, ['state', 'search']);
    const isSearchExist = typeof search !== 'undefined';

    const clubId = this.getClubId();

    history.push({
      pathname: '/clubs',
      search: isSearchExist ? search : '',
      state: {
        ...search,
        clubId
      }
    });
  };

  renderGrid(): React.ReactNode {
    const { user } = this.props;
    const {
      items,
      additionalOptions,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isSelectAllChecked,
      currentPage,
      total,
      itemsWithWaitingListCanBookStatusCount,
      club,
      availableParticipants
    } = this.state;

    const { isBookingFromWaitingList } = additionalOptions;

    const clubStatus = propz.get(club, ['status'], '');
    const isClubStatusActive = clubStatus === CLUB_STATUS.ACTIVE;

    const firstSelectedInvitationStatus = propz.get(selectedItems[0], ['invitationStatus']);

    const isSelectedItemInvitationStatusWaitingList =
      firstSelectedInvitationStatus === CLUB_PARTICIPATION_MESSAGE_INVITATION_STATUS.WAITING_LIST;

    const isSelectedItemsInvitationStatusOutdated = selectedItems.every(item => {
      return item.invitationStatus === CLUB_PARTICIPATION_MESSAGE_INVITATION_STATUS.OUTDATED;
    });

    const isInviteToBookForWaitingListStatusActive =
      itemsWithWaitingListCanBookStatusCount < availableParticipants &&
      selectedItems.length === 1 &&
      isSelectedItemInvitationStatusWaitingList &&
      isBookingFromWaitingList;

    const isInviteToBookForOutdatedStatusActive =
      isClubStatusActive &&
      !isBookingFromWaitingList &&
      selectedItems.length > 0 &&
      isSelectedItemsInvitationStatusOutdated;

    let actionItems = [
      {
        itemText: 'Add to club',
        onItemClick: this.onAddToClubClick,
        isActive: this.isAddToClubButtonActive()
      },
      {
        itemText: 'Invite to book',
        onItemClick: this.onInviteToBookClick,
        isActive: isInviteToBookForWaitingListStatusActive || isInviteToBookForOutdatedStatusActive
      },
      {
        itemText: 'Invite to book (with a deadline)',
        onItemClick: this.openInviteToBookModal,
        isActive: isInviteToBookForWaitingListStatusActive
      }
    ];

    const filterOptions = {
      invitationStatus: getSelectOptionsForClubMessagesStatus(user),
      priority: getSelectOptionsForClubPriority(),
      participant: getSelectOptionForBooleanQuestion()
    };

    const gridTitle = typeof club !== 'undefined' ? `${club.name} / Booking` : '';

    return (
      <Grid2
        dataItems={items}
        filters={filters}
        currentPage={currentPage}
        total={total}
        isSelectAllChecked={isSelectAllChecked}
        isDataFiltered={isDataFiltered}
        sortDirection={sortDirection}
        sortColumnsName={sortColumnsName}
        isShowFilter={isShowFilter}
        dataItemsSelected={selectedItems}
        columns={COLUMNS}
        actionItems={actionItems}
        options={filterOptions}
        onItemClick={this.onItemClick}
        onSortClick={this.onTableSortClick}
        onApplyFilterClick={this.onApplyFilterClick}
        onClearFilterClick={this.onClearFilterClick}
        onTableFilterChange={this.onTableFilterChange}
        onTableFilterClick={this.onTableFilterClick}
        setCurrentPageParams={this.setCurrentPageParams}
        onSelectAllClick={this.onSelectAllClick}
        onSelectAllOnPageClick={this.onSelectAllOnPageClick}
        onUnselectAllClick={this.onUnselectAllClick}
        renderAdditionalOptions={this.renderAdditionalOptions}
        goBack={this.goBack}
        gridTitle={gridTitle}
      />
    );
  }

  onTabClick = (event, index) => {
    event.preventDefault();

    this.setState({ currentTabIndex: index });
  };

  getSummaryTabs(): React.ReactNode {
    const { currentTabIndex, selectedItems } = this.state;

    const message = selectedItems[0];

    switch (currentTabIndex) {
      case 0:
        return <ClubConfirmationRequestSummary message={message} />;
    }
  }

  renderSummary(): React.ReactNode {
    const { currentTabIndex, selectedItems } = this.state;
    const isOneItemSelected = this.isOneItemSelected();

    if (!isOneItemSelected) {
      return <div className="font-weight-bold">Select an invitation from the list above</div>;
    }

    const { invitationStatus } = selectedItems[0];
    const isConfirmationRequestRejected = invitationStatus === CONFIRMATION_REQUESTS_STATUS.REJECTED;
    const isConfirmationRequestNotReady = invitationStatus === CONFIRMATION_REQUESTS_STATUS.NOT_READY;

    if (isConfirmationRequestRejected || isConfirmationRequestNotReady) {
      return null;
    }

    const summaryTabs = this.getSummaryTabs();

    return (
      <GenericSummary
        onTabClick={this.onTabClick}
        tabs={CLUB_CONFIRMATION_REQUEST_TABS}
        currentTabIndex={currentTabIndex}
      >
        {summaryTabs}
      </GenericSummary>
    );
  }

  isOneItemSelected(): boolean {
    const selectedItems = this.state.selectedItems;
    return selectedItems.length === 1;
  }

  render() {
    const { isLoading, isClubErrorModalOpen, isClubInfoModalOpen } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes = isClubErrorModalOpen || isClubInfoModalOpen ? 'mt-3 modal-open' : 'mt-3';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderClubErrorsModal()}
        {this.renderClubInfoModal()}
        {this.renderInviteToBookModal()}
        <div className="row">
          <div className="col-md-12">
            <ResizablePanel>
              {this.renderGrid()}
              {this.renderSummary()}
            </ResizablePanel>
          </div>
        </div>
      </div>
    );
  }
}
