import * as React from 'react';
import * as propz from 'propz';
import { Field, Form, Formik } from 'formik';
import DatePicker from 'react-datepicker';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from '../../../../../../../consts/report';
import { Switch } from '../../../../../../../components/Switch/Switch';
import { SchoolUser } from 'Src/models/schoolUser';
import { LabelWithQuestionIcon } from '../../../../../../../components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import '../../Users.css';

interface Props {
  onCloseClick: () => void;
  onSubmit: (data: any, reportType: string) => void;
}

// report 024
export function StudentActivityReportForm(props: Props) {
  const now = new Date();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const requestInitial = {
    dateFrom: yesterday,
    dateTo: now,
    isShowZero: true,
    isShowNonZero: true
  };

  return (
    <Formik
      onSubmit={values => {
        props.onSubmit(values, REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.STUDENT_ACTIVITY);
      }}
      initialValues={requestInitial}
      render={({ values, setFieldValue }) => (
        <Form>
          <div className="bReportFormTitle">User activity details</div>
          <div className="mb-3 mt-3">{'View selected user activity on the platform for a chosen period of time.'}</div>

          <div className="form-group">
            <label>Date from</label>
            <Field name="dateFrom">
              {({ field, form }) => (
                <div className="mb-3">
                  <DatePicker
                    {...field}
                    selected={field.value}
                    onChange={date => {
                      form.setFieldValue('dateFrom', date);
                      if (form.values.dateTo && form.values.dateTo < date) {
                        form.setFieldValue('dateTo', date);
                      }
                    }}
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
              )}
            </Field>
          </div>
          <div className="form-group">
            <label>Date to</label>
            <Field name="dateTo">
              {({ field, form }) => (
                <div className="mb-3">
                  <DatePicker
                    {...field}
                    selected={field.value}
                    onChange={date => {
                      form.setFieldValue('dateTo', date);
                    }}
                    minDate={form.values.dateFrom}
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
              )}
            </Field>
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isShowZero"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowZero}
                    customClass="mb-3"
                    onChange={event => {
                      const isShowZero = event.target.checked;
                      setFieldValue('isShowZero', isShowZero);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show students without activity"
                hintText={`This will enable you to see all students who didn't log in to the platform within your chosen period.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowZero"
              />
            </div>
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isShowNonZero"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowNonZero}
                    customClass="mb-3"
                    onChange={event => {
                      const isShowNonZero = event.target.checked;
                      setFieldValue('isShowNonZero', isShowNonZero);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show students with activity"
                hintText={
                  'This will enable you to see all students who logged in to the platform within your chosen period.'
                }
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowNonZero"
              />
            </div>
          </div>

          <button type="button" className="btn btn-secondary mr-3" onClick={props.onCloseClick}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Get
          </button>
        </Form>
      )}
    />
  );
}
