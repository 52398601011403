import {
  INVITE_STATUS,
  INVITE_STATUS_AUTO_ACCEPTED_MAPPING,
  INVITE_STATUS_NOT_SENT_MAPPING,
  INVITE_STATUS_SERVER_TO_CLIENT_MAPPING
} from '../../consts/invite';
import { DATE_TIME_FORMAT } from '../../consts/date';
import * as Moment from 'moment';

export function getTournamentInviteNormalizedStatus(item: any): string {
  const { inviteStatus, isInviteAutoAccepted } = item;
  const isInviteAutoAcceptedBoolean = Boolean(isInviteAutoAccepted);

  const isInviteStatusAccepted = inviteStatus === INVITE_STATUS.ACCEPTED;
  const isInviteStatusRejected = inviteStatus === INVITE_STATUS.REJECTED;
  const isInviteStatusNotReady = inviteStatus === INVITE_STATUS.NOT_READY;
  const isInviteStatusCanceled = inviteStatus === INVITE_STATUS.CANCELED;
  const isInviteStatusPrebooked = inviteStatus === INVITE_STATUS.PREBOOKED;
  const isInviteStatusOutdated = inviteStatus === INVITE_STATUS.OUTDATED;
  const isInviteStatusNotDefined = typeof inviteStatus === 'undefined';

  switch (true) {
    case isInviteStatusAccepted && !isInviteAutoAcceptedBoolean:
      return INVITE_STATUS_SERVER_TO_CLIENT_MAPPING.ACCEPTED;
    case isInviteStatusAccepted && isInviteAutoAcceptedBoolean:
      return INVITE_STATUS_AUTO_ACCEPTED_MAPPING;
    case isInviteStatusRejected:
      return INVITE_STATUS_SERVER_TO_CLIENT_MAPPING.REJECTED;
    case isInviteStatusNotReady:
      return INVITE_STATUS_SERVER_TO_CLIENT_MAPPING.NOT_READY;
    case isInviteStatusCanceled:
      return INVITE_STATUS_SERVER_TO_CLIENT_MAPPING.CANCELED;
    case isInviteStatusOutdated:
      return INVITE_STATUS_SERVER_TO_CLIENT_MAPPING.OUTDATED;
    case isInviteStatusPrebooked:
      return INVITE_STATUS_SERVER_TO_CLIENT_MAPPING.PREBOOKED;
    case isInviteStatusNotDefined:
      return INVITE_STATUS_NOT_SENT_MAPPING;
    default:
      return inviteStatus;
  }
}

export function getTournamentInviteNormalizedDate(item: any): string {
  const { inviteStatus, inviteStatusSetAt } = item;
  const isInviteStatusDefined = typeof inviteStatus !== 'undefined';
  return isInviteStatusDefined ? Moment(inviteStatusSetAt).format(DATE_TIME_FORMAT) : '';
}
