import * as React from 'react';
import * as propz from 'propz';
import * as BPromise from 'bluebird';
import { parse } from 'query-string';
import { AppUser } from 'Src/views/App/App';
import { Component } from 'react';
import { History, Location } from 'history';
import { Loader } from 'Src/components/Loader/Loader';
import { EventInvitationMessage } from 'Src/models/message';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { getOrder, getSearchFilter, getSearchOrder, getServerFieldSectionWhere } from 'Src/helpers/table/table';
import {
  ColumnDefinition,
  getFilters2,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { getParentArchiveMessages, getParentArchiveMessagesCount } from 'Src/helpers/service/parent/messagesTable';
import { getSelectOptionsForParentChildren } from 'Src/helpers/table/select';
import { Button } from 'Src/components/Button/Button';
import { Filters } from '../../ConsentRequests/Filters/Filters';
import { Pagination2 } from 'Src/components/Pagination/Pagination2';
import { Child } from 'Src/models/child';
import { getChildren } from 'Src/helpers/service/parent/children';
import { CARD_LIMIT } from 'Src/consts/table';
import { AvailabilityReportCard } from 'Src/components/AvailabilityReportCard/AvailabilityReportCard';
import { INVITE_TYPE } from 'Src/consts/invite';
import { Map } from 'Src/components/Map/Map';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { AvailabilityReportsDetails } from '../AvailabilityReportsDetails/AvailabilityReportsDetails';
import { SchoolEvent } from 'Src/models/event';
import { getChildrenEvent } from 'Src/helpers/service/parent/childrenEvents';

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

interface State {
  items: EventInvitationMessage[];
  selectedItem: EventInvitationMessage;
  event: SchoolEvent;
  total: number;
  currentPage: number;
  filters: any;
  children: Child[];
  sortFieldName: string;
  sortDirection: string;
  isFiltersOpen: boolean;
  isLoading: boolean;
  basePath: string;
  isViewMapModalOpen: boolean;
  isShowDetailsModalOpen: boolean;
}

const FILTER_FIELDS: ColumnDefinition[] = [
  {
    text: 'Children',
    field: 'childIdList',
    type: FILTER_TYPE.MULTISELECT,
    accessor: [''],
    isSort: false
  },
  {
    text: 'Sport',
    field: 'sport',
    type: FILTER_TYPE.TEXT,
    accessor: [''],
    isSort: false
  },
  {
    text: 'Start date',
    field: 'startTime',
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: [''],
    isSort: true
  },
  {
    text: 'Finish date',
    field: 'finishTime',
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: [''],
    isSort: false
  },
  {
    text: 'Deadline',
    field: 'deadlineForAnswers',
    type: FILTER_TYPE.NONE,
    accessor: [''],
    isSort: true
  }
];

export class AvailabilityReportsArchive extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      selectedItem: undefined,
      event: undefined,
      total: 0,
      currentPage: FIRST_PAGE,
      filters: {},
      sortFieldName: '',
      sortDirection: '',
      isFiltersOpen: false,
      isLoading: false,
      basePath: '',
      children: [],
      isViewMapModalOpen: false,
      isShowDetailsModalOpen: false
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setItems();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);

    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortFieldName = '';

    if (typeof search.order === 'string') {
      [sortFieldName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortFieldName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(FILTER_FIELDS, search);

    const where = {
      kind: 'EventParticipationMessage',
      ...getServerFieldSectionWhere(filters)
    };

    const order = sortByFieldExist && sortDirectionExist ? `${sortFieldName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order, CARD_LIMIT);

    const getItemsPromise = getParentArchiveMessages(user, serverQueryFilter);
    const getItemsCountPromise = getParentArchiveMessagesCount(user, where);
    const getChildrenPromise = getChildren(user);

    const promises = [getItemsPromise, getItemsCountPromise, getChildrenPromise];

    return BPromise.all(promises).then(([availabilityReports, availabilityReportsCountObj, children]) => {
      this.setState({
        items: availabilityReports,
        currentPage: page,
        filters,
        sortDirection,
        sortFieldName,
        children,
        total: availabilityReportsCountObj.count,
        basePath: history.location.pathname,
        isLoading: false
      });
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    const { history } = this.props;
    let search = [];

    const { filters, sortFieldName, sortDirection, basePath } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortFieldName)) {
      search.push(getSearchOrder(sortDirection, sortFieldName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    history.push({
      pathname: basePath,
      search: search.join('&')
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortFieldName, filters, basePath } = this.state;

    const order = getOrder(sortField, sortDirection, sortFieldName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: basePath,
      search: search.join('&')
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = propz.get(event, ['target', 'value'], '');
    const { filters } = this.state;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = FILTER_FIELDS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortFieldName, basePath } = this.state;
    const { history } = this.props;
    let search = [];

    if (isSortExist(sortDirection, sortFieldName)) {
      search.push(getSearchOrder(sortDirection, sortFieldName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    history.push({
      pathname: basePath,
      search: search.join('&')
    });
  };

  onClearFilterClick = (): void => {
    const { basePath } = this.state;

    this.props.history.push({
      pathname: basePath,
      search: ''
    });
  };

  renderViewMapModal = () => {
    const { isViewMapModalOpen, selectedItem } = this.state;

    const point = propz.get(selectedItem, ['eventData', 'venue', 'placeData', 'point']);

    return (
      <SimpleModal isOpen={isViewMapModalOpen} onCloseClick={this.closeViewMapModal} title="View map">
        <Map point={point} customStylingClass="eChildEventVenueMap" isMarkerDraggable={false} />
      </SimpleModal>
    );
  };

  openViewMapModal = item => {
    this.setState({
      isViewMapModalOpen: true,
      selectedItem: item
    });
  };

  closeViewMapModal = () => {
    this.setState({
      isViewMapModalOpen: false,
      selectedItem: undefined,
      event: undefined
    });
  };

  renderShowDetailsModal = () => {
    const { user } = this.props;
    const { activeSchoolId } = user;
    const { isShowDetailsModalOpen, selectedItem, event } = this.state;

    return (
      <SimpleModal
        isOpen={isShowDetailsModalOpen}
        onCloseClick={this.closeShowDetailsModal}
        title="Availability report details"
      >
        <AvailabilityReportsDetails item={selectedItem} event={event} activeSchoolId={activeSchoolId} />
      </SimpleModal>
    );
  };

  openShowDetailsModal = item => {
    const { user } = this.props;
    const eventId = propz.get(item, ['eventData', 'id'], '');

    this.setState({
      isLoading: true
    });

    getChildrenEvent(user, eventId).then(event => {
      this.setState({
        isShowDetailsModalOpen: true,
        selectedItem: item,
        event,
        isLoading: false
      });
    });
  };

  closeShowDetailsModal = () => {
    this.setState({
      isShowDetailsModalOpen: false,
      selectedItem: undefined,
      event: undefined
    });
  };

  render() {
    const {
      isLoading,
      isFiltersOpen,
      total,
      currentPage,
      items,
      children,
      filters,
      sortFieldName,
      sortDirection
    } = this.state;

    const filterOptions = {
      childIdList: getSelectOptionsForParentChildren(children)
    };

    if (isLoading) {
      return <Loader />;
    }

    return (
      <div>
        {this.renderViewMapModal()}
        {this.renderShowDetailsModal()}
        <div className="d-flex justify-content-end align-items-center">
          {/* <Button
            text="Filters & Sorting"
            onClick={() => {
              this.setState(prevState => {
                return { isFiltersOpen: !prevState.isFiltersOpen };
              });
            }}
            customClass="my-3"
          /> */}

          <Pagination2
            currentPage={currentPage}
            total={total}
            setCurrentPageParams={this.setCurrentPageParams}
            limit={CARD_LIMIT}
          />
        </div>

        {isFiltersOpen && (
          <div>
            <Filters
              fields={FILTER_FIELDS}
              filters={filters}
              options={filterOptions}
              onTableFilterChange={this.onTableFilterChange}
              onTableSortClick={this.onTableSortClick}
              onApplyFilterClick={this.onApplyFilterClick}
              onClearFilterClick={this.onClearFilterClick}
              sortFieldName={sortFieldName}
              sortDirection={sortDirection}
            />
          </div>
        )}

        <div className="row gap-3">
          {items.map(item => {
            const { id } = item;

            return (
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3" key={id}>
                <AvailabilityReportCard
                  item={item}
                  type={INVITE_TYPE.ARCHIVE}
                  onViewMapClick={this.openViewMapModal}
                  onShowDetailsClick={this.openShowDetailsModal}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
