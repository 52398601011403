import * as React from 'react';
import { GENDER, SPORT_GENDER_SERVER_TO_CLIENT_MAPPING } from '../../consts/common';
import {
  SCHOOL_STATUS,
  SCHOOL_STATUS_SERVER_TO_CLIENT_MAPPING,
  STUDENT_NAME_VIEW,
  STUDENT_NAME_VIEW_SERVER_TO_CLIENT_MAPPING,
  AGE_GROUPS_NAMING
} from 'Src/consts/school';
import { QUESTIONS_TEMPLATE_TYPE_SERVER_TO_CLIENT_MAPPING, QUESTIONS_TEMPLATE_TYPE } from 'Src/consts/message';
import { Sport } from '../../models/sport';
import { NON_FINISHED_PLAYER_RESULTS } from 'Src/consts/player';
import {
  SUBSTAGES_OF_TOURNAMENT,
  SUBSTAGES_OF_TOURNAMENT_SERVER_TO_CLIENT_MAPPING,
  TOURNAMENT_PRIZES,
  TOURNAMENT_PRIZES_SERVER_TO_CLIENT_MAPPING
} from 'Src/consts/tournament';

export const TWITTER_STATUS_OPTIONS = [
  { text: 'Disabled', value: 'false' },
  { text: 'Enabled', value: 'true' }
];

export function getGenderOptionsBySport(sport: Sport, text: string = 'Select sport first'): React.ReactFragment {
  if (typeof sport === 'undefined') {
    return <option value={''}>{text}</option>;
  }

  let genderOptions = [];

  if (sport.genders.maleOnly) {
    genderOptions.push(
      <option key="gender_option_male" value={GENDER.MALE_ONLY}>
        {SPORT_GENDER_SERVER_TO_CLIENT_MAPPING.MALE_ONLY}
      </option>
    );
  }

  if (sport.genders.femaleOnly) {
    genderOptions.push(
      <option key="gender_option_female" value={GENDER.FEMALE_ONLY}>
        {SPORT_GENDER_SERVER_TO_CLIENT_MAPPING.FEMALE_ONLY}
      </option>
    );
  }

  if (sport.genders.mixed) {
    genderOptions.push(
      <option key="gender_option_mixed" value={GENDER.MIXED}>
        {SPORT_GENDER_SERVER_TO_CLIENT_MAPPING.MIXED}
      </option>
    );
  }

  return genderOptions;
}

export function getSelectOptionsForPublicSiteAccess() {
  let statusOptions = [];

  for (let status in SCHOOL_STATUS_SERVER_TO_CLIENT_MAPPING) {
    if (SCHOOL_STATUS_SERVER_TO_CLIENT_MAPPING.hasOwnProperty(status)) {
      statusOptions.push({
        text: SCHOOL_STATUS_SERVER_TO_CLIENT_MAPPING[status],
        value: SCHOOL_STATUS[status]
      });
    }
  }

  return statusOptions;
}

export function getSelectOptionsForAgesGroupNaming() {
  let agesGroupOptions = [];

  for (let agesGroup in AGE_GROUPS_NAMING) {
    agesGroupOptions.push({
      text: AGE_GROUPS_NAMING[agesGroup],
      value: AGE_GROUPS_NAMING[agesGroup]
    });
  }

  return agesGroupOptions;
}

export function getSelectOptionsForStudentNameView() {
  const viewOptions = [];

  for (let view in STUDENT_NAME_VIEW_SERVER_TO_CLIENT_MAPPING) {
    if (STUDENT_NAME_VIEW_SERVER_TO_CLIENT_MAPPING.hasOwnProperty(view)) {
      viewOptions.push({
        value: STUDENT_NAME_VIEW[view],
        text: STUDENT_NAME_VIEW_SERVER_TO_CLIENT_MAPPING[view]
      });
    }
  }

  return viewOptions;
}

export function getSelectOptionsForNonFinishedPlayerResult() {
  const viewOptions = [];

  for (let view in NON_FINISHED_PLAYER_RESULTS) {
    if (NON_FINISHED_PLAYER_RESULTS.hasOwnProperty(view)) {
      viewOptions.push({
        value: NON_FINISHED_PLAYER_RESULTS[view],
        text: NON_FINISHED_PLAYER_RESULTS[view]
      });
    }
  }

  return viewOptions;
}

export function getConfirmationTemplateQuestionsFieldType() {
  const typeOptions = [];

  for (let type in QUESTIONS_TEMPLATE_TYPE_SERVER_TO_CLIENT_MAPPING) {
    if (QUESTIONS_TEMPLATE_TYPE_SERVER_TO_CLIENT_MAPPING.hasOwnProperty(type)) {
      typeOptions.push({
        value: QUESTIONS_TEMPLATE_TYPE[type],
        text: QUESTIONS_TEMPLATE_TYPE_SERVER_TO_CLIENT_MAPPING[type]
      });
    }
  }

  return typeOptions;
}

export function getSelectOptionsForTournamentPrize() {
  const options = [];

  for (let key in TOURNAMENT_PRIZES) {
    options.push({
      value: TOURNAMENT_PRIZES[key],
      text: TOURNAMENT_PRIZES_SERVER_TO_CLIENT_MAPPING[key]
    });
  }

  return options;
}

export function getSelectOptionsForTournamentSubstage() {
  const options = [];

  for (let key in SUBSTAGES_OF_TOURNAMENT) {
    options.push({
      value: SUBSTAGES_OF_TOURNAMENT[key],
      text: SUBSTAGES_OF_TOURNAMENT_SERVER_TO_CLIENT_MAPPING[key]
    });
  }

  return options;
}
