import * as React from 'react';
import { Component } from 'react';
import { AppUser } from 'Src/views/App/App';
import { Attachment, ParentsDeliverySettings } from '../../models/generalMessage';
import { GeneralMessageSettingsForm } from '../GeneralMessageSettingsForm/GeneralMessageSettingsForm';
import { GeneralMessageTextForm } from '../GeneralMessageTextForm/GeneralMessageTextForm';
import { uploadFileAllPath } from '../../helpers/service/file';
import { GENERAL_MESSAGE_MODAL_TOTAL_STEP } from '../../consts/generalMessage';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { GeneralMessageModal } from '../GeneralMessageModal/GeneralMessageModal';
import {
  createSchoolGeneralMessage,
  deleteSchoolGeneralMessage,
  getSchoolGeneralMessage,
  scheduleSchoolGeneralMessage,
  sendSchoolGeneralMessage,
  updateSchoolGeneralMessage
} from '../../helpers/service/admin/generalMessage';
import { GeneralMessagePreviewWithTabs } from '../GeneralMessagePreview/GeneralMessagesPreviewWithTabs';
import { TextUtils } from '../../helpers/utils/TextUtils';
import * as Moment from 'moment';
import { ACTION_TYPE } from '../GeneralMessageActionsButton/GeneralMessageActionsButton';
import { ScheduleSendingForm } from '../GeneralMessages/ScheduleSendingForm/ScheduleSendingForm';
import { DATE_TIME_FORMAT } from '../../consts/date';
import { getProgress } from '../../helpers/message/message';
import { HTML_FIRST_PART_PAGE_CODE, HTML_LAST_PART_PAGE_CODE } from '../HTMLEditorQuill/HTMLEditorConst';

interface Props {
  user: AppUser;
  onMessageSubmit: (message) => void;
  onCancelClick: () => void;
  students?: any[];
  schoolStaff?: any[];
  sendToStudents?: boolean;
  sendToParents?: boolean;
  sendToStaff?: boolean;
  isOpen: boolean;
  maxSizeUploadFile: number;
}

interface StepState {
  currentStep: number;
}

interface ErrorState {
  isError: boolean;
  errorMessage: string;
}

interface ScheduleFormState {
  isScheduleFormVisible: boolean;
}

interface MessageState {
  messageId: string;

  //settings
  messageTitle: string;
  messageDescription: string;
  messageIsSendParents?: boolean;
  messageIsSendStudents?: boolean;
  messageIsSendStaff?: boolean;
  messageParentsDeliverySettings?: ParentsDeliverySettings;

  //content
  messageSmsText: string;
  messageEmailSubject: string;
  messageEmailBody: string;
  messagePushTitle: string;
  messagePushBody: string;

  isSmsEditorEnabled: boolean;
  isPushEditorEnabled: boolean;
  isEmailEditorEnabled: boolean;
  isLoadingFile: boolean;

  //email attachments
  messageAttachments: Attachment[];
  isImageError: boolean;
  filesSize: number;
  maxSizeUploadFile: number;

  messageScheduleAt: string;

  affectedUserList: any[];
}

type State = MessageState & StepState & ErrorState & ScheduleFormState;

export class GeneralMessageWizzard extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      messageId: '',

      messageTitle: '',
      messageDescription: '',
      messageIsSendParents: props.sendToParents,
      messageIsSendStudents: props.sendToStudents,
      messageIsSendStaff: props.sendToStaff,

      messageParentsDeliverySettings: {
        all: true,
        firstPriority: false,
        secondPriority: false,
        thirdPriority: false
      },

      messageSmsText: '',
      messageEmailSubject: '',
      messageEmailBody: '',
      messagePushTitle: '',
      messagePushBody: '',

      isSmsEditorEnabled: true,
      isPushEditorEnabled: true,
      isEmailEditorEnabled: true,
      isLoadingFile: false,

      messageScheduleAt: '',

      affectedUserList: [],

      messageAttachments: [],

      currentStep: 1,

      isError: false,
      errorMessage: '',
      isImageError: false,
      filesSize: 0,
      maxSizeUploadFile: props.maxSizeUploadFile,

      isScheduleFormVisible: false
    };
  }

  // step callbacks
  onNextClick = (): void => {
    const {
      currentStep,
      isSmsEditorEnabled,
      isPushEditorEnabled,
      isEmailEditorEnabled,
      messageEmailBody,
      messageEmailSubject,
      messagePushBody,
      messagePushTitle,
      messageSmsText
    } = this.state;

    let newMessageEmailBody;
    if (messageEmailBody === '<p><br></p>') {
      //  because   "<p><br></p>" === ''  in editor
      newMessageEmailBody = '';
      this.setState({
        messageEmailBody: newMessageEmailBody
      });
    } else {
      newMessageEmailBody = messageEmailBody;
    }

    const isCurrentStepContent = currentStep === 2;

    // returns an error if content is empty
    const isSMSTextExist = messageSmsText !== '';
    const isPushBodyExist = messagePushBody !== '';
    const isPushTitleExist = messagePushTitle !== '';
    const isEmailSubjectExist = messageEmailSubject !== '';
    const isEmailBodyExist = newMessageEmailBody !== '';

    const isShowSMSError = !isSMSTextExist && isSmsEditorEnabled;
    const isShowPushError = (!isPushBodyExist || !isPushTitleExist) && isPushEditorEnabled;
    const isShowEmailError = (!isEmailSubjectExist || !isEmailBodyExist) && isEmailEditorEnabled;

    if (isCurrentStepContent && (isShowSMSError || isShowPushError || isShowEmailError)) {
      let errorText = '';

      switch (true) {
        case isShowSMSError:
          errorText = 'Text for sms content should be defined';
          break;
        case isShowPushError:
          errorText = 'Title or body for push content should be defined';
          break;
        case isShowEmailError:
          errorText = 'Subject or body for email content should be defined';
          break;
      }
      this.setState({
        isError: true,
        errorMessage: errorText
      });
    } else {
      this.saveGeneralMessage().then(message => {
        this.setState(prevState => ({
          currentStep: prevState.currentStep + 1,
          messageId: message.id,
          affectedUserList: message.affectedUserList
        }));
      });
    }
  };

  onPreviousClick = (): void => {
    const { currentStep } = this.state;

    if (currentStep === 1) {
      return;
    }

    this.setState({
      currentStep: currentStep - 1
    });
  };

  // message settings callbacks
  onTitleChange = event => {
    this.setState({ messageTitle: event.target.value });
  };

  onDescriptionChange = event => {
    this.setState({ messageDescription: event.target.value });
  };

  onIsSendParentsChange = event => {
    const isSendParents = event.target.checked;

    this.setState({ messageIsSendParents: isSendParents });
  };

  onIsSendStudentsChange = event => {
    const isSendStudents = event.target.checked;

    this.setState({ messageIsSendStudents: isSendStudents });
  };

  onIsSendStaffChange = (event): void => {
    this.setState({
      messageIsSendStaff: event.target.checked
    });
  };

  onDeliverySettingsChanged = (settings: ParentsDeliverySettings): void => {
    this.setState({
      messageParentsDeliverySettings: settings
    });
  };

  // message content callbacks
  onEmailSubjectChange = event => {
    this.setState({ messageEmailSubject: event.target.value });
  };

  onEmailBodyChange = (body: any) => {
    this.setState({
      messageEmailBody: body
    });
  };

  onSmsTextChange = event => {
    this.setState({ messageSmsText: event.target.value });
  };

  onPushTitleChange = event => {
    this.setState({ messagePushTitle: event.target.value });
  };

  onPushBodyChange = event => {
    this.setState({ messagePushBody: event.target.value });
  };

  onEmailAttachmentChange = event => {
    const { user } = this.props;
    const { filesSize, maxSizeUploadFile } = this.state;
    const attachments = [...this.state.messageAttachments];
    const file = event.target.files[0];
    const currentFileSize = event.target.files[0].size;
    const fileName = file.name;
    const fileNameWithoutSpaces = fileName.split(' ').join('_');
    const formData = new FormData();
    formData.append('file', file, fileNameWithoutSpaces);

    const allFilesSize = filesSize + currentFileSize;

    if (allFilesSize > maxSizeUploadFile) {
      let maxSizeUploadFileText = '0 Mb';
      if (maxSizeUploadFile > 0) {
        maxSizeUploadFileText = `${(maxSizeUploadFile / 1000000).toFixed(0)}Mb`;
      }
      const errorText = `The message cannot be sent as the total size of attachments is too large.
      Please ensure your attachments don't exceed ${maxSizeUploadFileText} and try sending a message again.`;
      this.setState({
        errorMessage: errorText,
        isError: true
      });
    } else {
      this.setState({
        isLoadingFile: true
      });
      uploadFileAllPath(user, formData)
        .then(data => {
          const { key, link } = data;
          attachments.push({
            fileName: fileNameWithoutSpaces,
            link: link,
            externalFileId: key,
            fileSize: currentFileSize
          });
          this.setState({
            messageAttachments: attachments,
            filesSize: allFilesSize,
            isLoadingFile: false
          });
        })
        .catch(error => {
          this.setState({
            isImageError: true,
            isLoadingFile: false
          });
        });
    }
  };

  onRemoveAttachment = (key: string) => {
    const attachments = this.state.messageAttachments;
    const attachmentIndex = attachments.findIndex(attachment => attachment.externalFileId === key);

    attachments.splice(attachmentIndex, 1);

    this.setState({
      messageAttachments: attachments
    });
  };

  onIsEmailEditorChange = event => {
    const isEmailEditorEnabled = event.target.checked;

    if (!isEmailEditorEnabled) {
      this.setState({
        messageEmailSubject: '',
        messageEmailBody: '',
        messageAttachments: []
      });
    }

    this.setState({
      isEmailEditorEnabled: isEmailEditorEnabled
    });
  };

  onIsSmsEditorChange = event => {
    const isSmsEditorEnabled = event.target.checked;

    if (!isSmsEditorEnabled) {
      this.setState({
        messageSmsText: ''
      });
    }

    this.setState({ isSmsEditorEnabled: isSmsEditorEnabled });
  };

  onIsPushEditorChange = event => {
    const isPushEditorEnabled = event.target.checked;
    if (!isPushEditorEnabled) {
      this.setState({
        messagePushBody: '',
        messagePushTitle: ''
      });
    }
    this.setState({ isPushEditorEnabled: isPushEditorEnabled });
  };

  onCloseImageError = () => {
    this.setState({
      isImageError: false
    });
  };

  // error callbacks
  onShowError = (message: string) => {
    const errorMessage = typeof message === 'string' ? message : 'Error saving general message';

    this.setState({
      isError: true,
      errorMessage: errorMessage
    });
  };

  onCloseErrorClick = event => {
    event.preventDefault();

    this.setState({
      isError: false,
      errorMessage: ''
    });
  };

  // create message callbacks
  onSaveMessageClick = () => {
    const { onMessageSubmit } = this.props;

    this.saveGeneralMessage().then(response => {
      onMessageSubmit(response);
    });
  };

  onSendMessageModalClick = () => {
    const { user, onMessageSubmit } = this.props;

    this.saveGeneralMessage()
      .then(response => sendSchoolGeneralMessage(user, response.id))
      .then(response => {
        onMessageSubmit(response);
      })
      .catch(error => {
        console.error(error.response.data.details.text);
        this.onShowError(error.response.data.details.text);
      });
  };

  onScheduleMessageClick = () => {
    if (!this.isScheduleDateValid()) {
      return;
    }

    const { user, onMessageSubmit } = this.props;
    const { messageScheduleAt } = this.state;
    let newMessage;

    this.saveGeneralMessage()
      .then(message => {
        newMessage = message;
        return scheduleSchoolGeneralMessage(user, message.id, messageScheduleAt);
      })
      .then(() => {
        // there is message with status DRAFT and we need to reload message from server with new status
        return getSchoolGeneralMessage(user, newMessage.id);
      })
      .then(message => {
        onMessageSubmit(message);
      })
      .catch(reason => {
        console.error(reason.response.data.details.text);
        this.onShowError(reason.response.data.details.text);
      });
  };

  onActiveActionChange = (actionType: ACTION_TYPE) => {
    this.setState({
      isScheduleFormVisible: actionType === ACTION_TYPE.SCHEDULE
    });
  };

  onScheduleDateChange = (event: any) => {
    const scheduleAt = Moment(event.target.value, DATE_TIME_FORMAT).toISOString();
    this.setState({
      messageScheduleAt: scheduleAt
    });
  };

  onCancelClick = () => {
    const { user } = this.props;
    const { messageId } = this.state;

    if (TextUtils.isEmpty(messageId)) {
      this.props.onCancelClick();
      return;
    }

    deleteSchoolGeneralMessage(user, messageId).then(() => this.props.onCancelClick());
  };

  getAttachmentAsLinksString = () => {
    const { messageAttachments } = this.state;
    if (messageAttachments.length > 0) {
      const attachmentAsLink = messageAttachments
        .map((attachment, index) => {
          return `<div key='attachment_${index}'><a href=${attachment.link} target="_blank">${attachment.fileName}</a></div>`;
        })
        .join('');
      return `<div>Attachments: ${attachmentAsLink}</div>`;
    } else {
      return '';
    }
  };

  saveGeneralMessage = () => {
    const {
      messageId,
      messageTitle,
      messageDescription,
      messageSmsText,
      messageEmailSubject,
      messageEmailBody,
      messageAttachments,
      messagePushTitle,
      messagePushBody,
      messageIsSendStaff,
      messageIsSendParents,
      messageIsSendStudents,
      messageParentsDeliverySettings
    } = this.state;

    const { user, schoolStaff, students } = this.props;

    const staffs =
      typeof schoolStaff !== 'undefined'
        ? schoolStaff.map(staff => ({ userId: staff.id, permissionId: staff.permissions[0].id }))
        : undefined;
    const studentsList =
      typeof students !== 'undefined'
        ? students.map(student => ({ userId: student.id, permissionId: student.permissionId }))
        : undefined;
    const messageEmailBodyWithAttachment = messageEmailBody + this.getAttachmentAsLinksString();

    const messageHTMLEmailBody =
      messageEmailBody !== ''
        ? `${HTML_FIRST_PART_PAGE_CODE} ${messageEmailBodyWithAttachment} ${HTML_LAST_PART_PAGE_CODE}`
        : '';

    const data = {
      title: messageTitle,
      description: messageDescription,
      content: {
        sms: { text: messageSmsText },
        email: { subject: messageEmailSubject, body: messageHTMLEmailBody, attachments: messageAttachments },
        push: { title: messagePushTitle, body: messagePushBody }
      },
      subscribers: {
        types: {
          schoolStaff: messageIsSendStaff,
          parents: messageIsSendParents,
          students: messageIsSendStudents
        },
        lists: {
          staffs: staffs,
          students: studentsList
        },
        parentsDeliverySettings: messageParentsDeliverySettings
      }
    };

    if (!TextUtils.isEmpty(messageId)) {
      return updateSchoolGeneralMessage(user, data, messageId);
    } else {
      return createSchoolGeneralMessage(user, data);
    }
  };

  isNextButtonDisabled(): boolean {
    const {
      currentStep,
      messageIsSendStaff,
      messageIsSendStudents,
      messageIsSendParents,
      affectedUserList,
      isSmsEditorEnabled,
      isPushEditorEnabled,
      isEmailEditorEnabled,
      isLoadingFile,
      isError
    } = this.state;

    let isNextButtonDisabled = false;

    const isAllEditorDisabled = !isSmsEditorEnabled && !isPushEditorEnabled && !isEmailEditorEnabled;

    switch (currentStep) {
      case 1:
        isNextButtonDisabled = !messageIsSendStaff && !messageIsSendParents && !messageIsSendStudents;
        break;
      case 2:
        isNextButtonDisabled = isError || isLoadingFile || isAllEditorDisabled;
        break;
      case 3:
        isNextButtonDisabled = typeof affectedUserList === 'undefined' || affectedUserList.length === 0;
        break;
    }

    return isNextButtonDisabled;
  }

  isScheduleDateValid = (): boolean => {
    const { messageScheduleAt } = this.state;

    if (TextUtils.isEmpty(messageScheduleAt) || !Moment(messageScheduleAt).isValid()) {
      this.onShowError('Please enter a valid date');
      return false;
    }

    const now = new Date().getTime();
    const scheduleDate = new Date(messageScheduleAt).getTime();

    if (now > scheduleDate) {
      this.onShowError('The scheduled sending time is in the past, please amend it');
      return false;
    }

    return true;
  };

  renderViewByStep(): React.ReactNode {
    const {
      currentStep,
      messageSmsText,
      messageEmailSubject,
      messageEmailBody,
      messagePushTitle,
      messagePushBody,
      messageTitle,
      messageDescription,
      messageAttachments,
      messageParentsDeliverySettings,
      messageIsSendParents,
      messageIsSendStudents,
      messageIsSendStaff,
      affectedUserList,
      isImageError,
      isError,
      isSmsEditorEnabled,
      isPushEditorEnabled,
      isEmailEditorEnabled,
      isLoadingFile
    } = this.state;

    switch (currentStep) {
      case 1:
        return (
          <GeneralMessageSettingsForm
            messageTitle={messageTitle}
            messageDescription={messageDescription}
            parentsDeliverySettings={messageParentsDeliverySettings}
            isSendParents={messageIsSendParents}
            isSendStudents={messageIsSendStudents}
            isSendStaff={messageIsSendStaff}
            onTitleChange={this.onTitleChange}
            onDescriptionChange={this.onDescriptionChange}
            onIsSendParentsChange={this.onIsSendParentsChange}
            onIsSendStudentsChange={this.onIsSendStudentsChange}
            onSendStaffChange={this.onIsSendStaffChange}
            onDeliverySettingsChanged={this.onDeliverySettingsChanged}
          />
        );
      case 2:
        return (
          <GeneralMessageTextForm
            onEmailSubjectChange={this.onEmailSubjectChange}
            onEmailBodyChange={this.onEmailBodyChange}
            onSmsTextChange={this.onSmsTextChange}
            onPushTitleChange={this.onPushTitleChange}
            onPushBodyChange={this.onPushBodyChange}
            onEmailAttachmentChange={this.onEmailAttachmentChange}
            onRemoveAttachment={this.onRemoveAttachment}
            onCloseImageError={this.onCloseImageError}
            messageSmsText={messageSmsText}
            messageEmailSubject={messageEmailSubject}
            messageEmailBody={messageEmailBody}
            messagePushTitle={messagePushTitle}
            messagePushBody={messagePushBody}
            messageAttachments={messageAttachments}
            isImageError={isImageError}
            isError={isError}
            isLoadingFile={isLoadingFile}
            isEmailEditorEnabled={isEmailEditorEnabled}
            isSmsEditorEnabled={isSmsEditorEnabled}
            isPushEditorEnabled={isPushEditorEnabled}
            onIsEmailEditorChange={this.onIsEmailEditorChange}
            onIsSmsEditorChange={this.onIsSmsEditorChange}
            onIsPushEditorChange={this.onIsPushEditorChange}
          />
        );
      case 3:
        return (
          <GeneralMessagePreviewWithTabs
            messageSmsText={messageSmsText}
            messageEmailSubject={messageEmailSubject}
            messageEmailBody={messageEmailBody}
            messagePushTitle={messagePushTitle}
            messagePushBody={messagePushBody}
            messageAttachments={messageAttachments}
            affectedUsers={affectedUserList}
          />
        );
    }
  }

  renderErrorAlert(): React.ReactNode {
    const { errorMessage, isError } = this.state;

    if (!isError) {
      return null;
    }

    return (
      <div className="alert fade show alert-danger d-flex justify-content-between align-items-center" role="alert">
        {errorMessage}
        <button
          type="button"
          className="ml-3 btn btn-danger"
          aria-label="Close"
          onClick={event => this.onCloseErrorClick(event)}
        >
          Close
        </button>
      </div>
    );
  }

  render() {
    const { isOpen } = this.props;
    const { currentStep, isScheduleFormVisible, isError } = this.state;

    return (
      <GeneralMessageModal
        isOpen={isOpen}
        title={'New message'}
        closeButtonText={'Close'}
        submitButtonText={'Next step'}
        onCloseClick={this.onCancelClick}
        onPreviousClick={this.onPreviousClick}
        onNextClick={this.onNextClick}
        onSaveClick={this.onSaveMessageClick}
        onSendClick={this.onSendMessageModalClick}
        onScheduleClick={this.onScheduleMessageClick}
        hasNext={currentStep !== GENERAL_MESSAGE_MODAL_TOTAL_STEP}
        hasPrevious={currentStep > 1}
        isNextButtonDisabled={this.isNextButtonDisabled()}
        onActiveActionChange={this.onActiveActionChange}
        isError={isError}
      >
        {this.renderErrorAlert()}

        {isScheduleFormVisible && <ScheduleSendingForm onScheduleDateChange={this.onScheduleDateChange} />}

        <ProgressBar
          progress={getProgress(currentStep, GENERAL_MESSAGE_MODAL_TOTAL_STEP)}
          step={currentStep}
          stepTotal={GENERAL_MESSAGE_MODAL_TOTAL_STEP}
        />
        {this.renderViewByStep()}
      </GeneralMessageModal>
    );
  }
}
