import * as React from 'react';
import { Button } from '../../../../components/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  onNextMonthClick: () => void;
}

export function NextMonthArrow(props: Props) {
  const { onNextMonthClick } = props;
  return (
    <button className="btn eNextMonthArrow" onClick={onNextMonthClick}>
      {[<FontAwesomeIcon color="#007abf" icon="arrow-right" key={'next_month_arrow'} />]}
    </button>
  );
}
