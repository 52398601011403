import * as React from 'react';
import * as Moment from 'moment';
import MaskedInput from 'react-text-mask';
import { ReactSortable } from 'react-sortablejs';
import { ConsentRequestTemplate, ConsentRequestTemplateField } from '../../../../../../models/consentRequestTemplate';
import { UK_INPUT_MASK, UK_INPUT_MASK_VALIDATION } from '../../../../../../consts/date';
import { Button } from '../../../../../../components/Button/Button';
import { BurgerIcon } from 'Src/components/SvgIcons/BurgerIcon';
import './SchoolEventConsentRequestForm.scss';

interface Props {
  template: ConsentRequestTemplate;
  onCancelClick: () => void;
  onSendConsentRequestsClick: (fields: ConsentRequestFormField[], deadlineForAnswers: string) => void;
}

interface State {
  fields: ConsentRequestFormField[];
  deadlineForAnswers: string;
  isDeadlineForAnswersError: boolean;
}

export interface ConsentRequestFormField extends ConsentRequestTemplateField {
  isChecked: boolean;
  id: number;
}

export class ConsentRequestForm extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    const propsFields = [...props.template.fields];

    this.state = {
      deadlineForAnswers: '',
      isDeadlineForAnswersError: false,
      fields: propsFields.map((field, index) => ({
        ...field,
        isChecked: field.isDefault,
        id: index
      }))
    };
  }

  onCheckboxClick = index => {
    const { fields } = this.state;
    const nextFields = fields.map((field, fieldIndex) => {
      if (fieldIndex === index) {
        return {
          ...field,
          isChecked: !field.isChecked
        };
      } else {
        return field;
      }
    });

    this.setState({
      fields: nextFields
    });
  };

  sortFields = sortedFields => {
    this.setState({
      fields: sortedFields
    });
  };

  renderFields(): React.ReactNode {
    const { fields } = this.state;

    return (
      <ReactSortable list={fields} setList={this.sortFields} handle=".eConsentRequestFieldsDrag" animation={150}>
        {fields.map((field, index) => (
          <div className="bConsentRequestFields">
            <div className="pl-3 d-flex align-items-center justify-content-between">
              <div className={'custom-control custom-switch'} key={`consent_request_field_${field._id}`}>
                <input
                  id={`switch_${field._id}`}
                  onChange={() => this.onCheckboxClick(index)}
                  checked={field.isChecked}
                  type="checkbox"
                  className="custom-control-input"
                />
                <label className="custom-control-label pl-3 mb-3 mt-3" htmlFor={`switch_${field._id}`}>
                  {field.heading}
                </label>
              </div>

              <div className="eConsentRequestFieldsDrag">
                <BurgerIcon />
              </div>
            </div>
          </div>
        ))}
      </ReactSortable>
    );
  }

  onSelectAllClick = () => {
    const { fields } = this.state;
    const isSelectAllChecked = fields.every(field => field.isChecked);

    this.setState({
      fields: fields.map(field => {
        return {
          ...field,
          isChecked: !isSelectAllChecked
        };
      })
    });
  };

  renderSelectAll(): React.ReactNode {
    const { fields } = this.state;
    const isSelectAllChecked = fields.every(field => field.isChecked);

    return (
      <div className="bConsentRequestFields">
        <div className="pl-3">
          <div className={'custom-control custom-switch'} key={`consent_request_field_selectAll`}>
            <input
              id={`switch_selectAll`}
              onChange={this.onSelectAllClick}
              checked={isSelectAllChecked}
              type="checkbox"
              className="custom-control-input"
            />
            <label className="custom-control-label pl-3 mb-3 mt-3" htmlFor={`switch_selectAll`}>
              {isSelectAllChecked ? 'Unselect All' : 'Select All'}
            </label>
          </div>
        </div>
      </div>
    );
  }

  onSendClick = () => {
    const { fields, deadlineForAnswers } = this.state;
    const { onSendConsentRequestsClick } = this.props;
    const checkedFields = fields.filter(field => field.isChecked);

    const isValidDeadline = Moment(deadlineForAnswers, UK_INPUT_MASK_VALIDATION, true).isValid();

    if (deadlineForAnswers === '' || isValidDeadline) {
      onSendConsentRequestsClick(checkedFields, deadlineForAnswers);
    } else {
      this.setState({
        isDeadlineForAnswersError: true
      });
    }
  };

  renderButtons(): React.ReactNode {
    const { onCancelClick } = this.props;
    return (
      <>
        <Button onClick={onCancelClick} text={'Cancel'} customClass={'btn-secondary mt-3 mr-3'} />
        <Button onClick={this.onSendClick} text={'Send consent request'} customClass={'mt-3'} />
      </>
    );
  }

  onDeadlineChange = (event: any) => {
    const value = event.target.value;

    this.setState({
      deadlineForAnswers: value,
      isDeadlineForAnswersError: false
    });
  };

  renderDeadline(): React.ReactNode {
    return (
      <MaskedInput
        type="text"
        className={'form-control'}
        onChange={this.onDeadlineChange}
        mask={UK_INPUT_MASK}
        placeholder={UK_INPUT_MASK_VALIDATION}
      />
    );
  }

  render() {
    const { fields, isDeadlineForAnswersError } = this.state;
    const isChecked = fields.filter(field => field.isChecked);
    const isCheckedCount = isChecked.length;
    const isFieldsExist = fields.length > 0;

    return (
      <>
        {isFieldsExist && (
          <>
            {this.renderFields()}
            {this.renderSelectAll()}
          </>
        )}
        <div className="mt-2 mb-2">Deadline for answers (optional)</div>
        {this.renderDeadline()}
        {isDeadlineForAnswersError && <div className="alert alert-danger mt-3">Please enter a valid date</div>}
        <div className="mt-2">{`${isCheckedCount} additional questions will be answered`}</div>
        {this.renderButtons()}
      </>
    );
  }
}
