import * as React from 'react';
import * as propz from 'propz';
import { Field, Form, Formik } from 'formik';
import { Switch } from '../../../../../../../components/Switch/Switch';
import { LabelWithQuestionIcon } from '../../../../../../../components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import DatePicker from 'react-datepicker';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from '../../../../../../../consts/report';
import { getStartDateForSchoolYear } from '../../../../../../../helpers/calendar/calendar';
import '../../Clubs.css';

const GROUP_BY = {
  SCHOOL_FORMS: 'isGroupByForms',
  YEAR_GROUPS: 'isGroupByYears',
  KEY_STAGES: 'isGroupByKeyStages'
};

const GROUP_BY_SERVER_TO_CLIENT_MAPPING = {
  SCHOOL_FORMS: 'School forms',
  YEAR_GROUPS: 'Year groups',
  KEY_STAGES: 'Key stages'
};

interface Props {
  onCloseClick: () => void;
  onSubmit: (data: any, reportType: string) => void;
  activeSchoolId: string;
}

// report 045
export const ClubParticipationByClubNumberReportForm = ({ onCloseClick, onSubmit, activeSchoolId }: Props) => {
  const now = new Date();
  let dateFrom = getStartDateForSchoolYear(now);

  const [schoolData, setSchoolData] = React.useState<any>(null);

  React.useEffect(() => {
    fetch('/dist/images/mis/misIntegrationSchoolsList.json')
      .then(response => response.json())
      .then(data => {
        const matchedSchoolData = data.find(item => item.schoolSITId === activeSchoolId);
        setSchoolData(matchedSchoolData);
      })
      .catch(error => console.error('Error loading JSON:', error));
  }, [activeSchoolId]);

  const requestInitial = {
    dateFrom,
    dateTo: now,
    isPrintPP: schoolData ? schoolData.studentsPPInfo === 1 : false,
    isPrintSEN: schoolData ? schoolData.studentsSENInfo === 1 : false,
    isPrintFSM: schoolData ? schoolData.studentsFSMInfo === 1 : false,
    isPrintEligible: true,
    groupBy: GROUP_BY.SCHOOL_FORMS,
    isGroupByForms: false,
    isGroupByYears: false,
    isGroupByKeyStages: false
  };

  return (
    <Formik
      onSubmit={values => {
        const valuesToTransfer = { ...values };
        propz.set(valuesToTransfer, [valuesToTransfer.groupBy], true);
        delete valuesToTransfer.groupBy;

        onSubmit(valuesToTransfer, REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.CLUB_PARTICIPATION_BY_CLUB_NUMBER);
      }}
      initialValues={requestInitial}
      render={({ touched, errors, values, setFieldValue }) => (
        <Form>
          <div className="bReportFormTitle">Club participation by club number</div>
          <div className="mb-3 mt-3">
            {'An aggregated report showing the number of students taking part in clubs and activities ' +
              ' across school forms, age groups or key stages within the period. The data is shown for ' +
              ' students taking part in one club, two clubs, three and more clubs. ' +
              ' It is worth noting that percentages within this report are based upon the number of students ' +
              ' who are eligible to participate.'}
          </div>

          <div className="form-group">
            <label>Date from</label>
            <Field name="dateFrom">
              {({ field, form }) => (
                <div className="mb-3">
                  <DatePicker
                    {...field}
                    selected={field.value}
                    onChange={date => {
                      form.setFieldValue('dateFrom', date);
                      if (form.values.dateTo && form.values.dateTo < date) {
                        form.setFieldValue('dateTo', date);
                      }
                    }}
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
              )}
            </Field>
          </div>
          <div className="form-group">
            <label>Date to</label>
            <Field name="dateTo">
              {({ field, form }) => (
                <div className="mb-3">
                  <DatePicker
                    {...field}
                    selected={field.value}
                    onChange={date => {
                      form.setFieldValue('dateTo', date);
                    }}
                    minDate={form.values.dateFrom}
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
              )}
            </Field>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isPrintEligible"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintEligible}
                    onChange={event => {
                      const isPrintEligible = event.target.checked;
                      setFieldValue('isPrintEligible', isPrintEligible);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show number of eligible students"
                hintText={'Show absolute numbers of eligible students within a category.'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintEligible"
              />
            </div>
          </div>

          {schoolData && schoolData.studentsSENInfo === 1 && (
            <div className="form-group">
              <div className="d-flex">
                <Field
                  name="isPrintSEN"
                  render={({ field }) => (
                    <Switch
                      {...field}
                      value={values.isPrintSEN}
                      onChange={event => {
                        const isPrintSEN = event.target.checked;
                        setFieldValue('isPrintSEN', isPrintSEN);
                      }}
                    />
                  )}
                />
                <LabelWithQuestionIcon
                  labelText="Show SEN"
                  hintText={'Show data relating to students with SEN.'}
                  customLabelClass="form-check-label pl-2"
                  labelHtmlFor="isPrintSEN"
                />
              </div>
            </div>
          )}

          {schoolData && schoolData.studentsPPInfo === 1 && (
            <div className="form-group">
              <div className="d-flex">
                <Field
                  name="isPrintPP"
                  render={({ field }) => (
                    <Switch
                      {...field}
                      value={values.isPrintPP}
                      onChange={event => {
                        const isPrintPP = event.target.checked;
                        setFieldValue('isPrintPP', isPrintPP);
                      }}
                    />
                  )}
                />
                <LabelWithQuestionIcon
                  labelText="Show PP"
                  hintText={'Show data relating to students with PP.'}
                  customLabelClass="form-check-label pl-2"
                  labelHtmlFor="isPrintPP"
                />
              </div>
            </div>
          )}

          {schoolData && schoolData.studentsFSMInfo === 1 && (
            <div className="form-group">
              <div className="d-flex">
                <Field
                  name="isPrintFSM"
                  render={({ field }) => (
                    <Switch
                      {...field}
                      value={values.isPrintFSM}
                      onChange={event => {
                        const isPrintFSM = event.target.checked;
                        setFieldValue('isPrintFSM', isPrintFSM);
                      }}
                    />
                  )}
                />
                <LabelWithQuestionIcon
                  labelText="Show FSM"
                  hintText={'Show data relating to students receiving free school meals.'}
                  customLabelClass="form-check-label pl-2"
                  labelHtmlFor="isPrintFSM"
                />
              </div>
            </div>
          )}

          <div className="form-group">
            <label>Group by</label>
            <div className="form-check mb-1">
              <input
                className="form-check-input"
                type="radio"
                name={GROUP_BY.SCHOOL_FORMS}
                id={GROUP_BY.SCHOOL_FORMS}
                value={GROUP_BY.SCHOOL_FORMS}
                checked={values.groupBy === GROUP_BY.SCHOOL_FORMS}
                onChange={() => {
                  setFieldValue('groupBy', GROUP_BY.SCHOOL_FORMS);
                }}
              />
              <label className="form-check-label pl-3" htmlFor={GROUP_BY.SCHOOL_FORMS}>
                {GROUP_BY_SERVER_TO_CLIENT_MAPPING.SCHOOL_FORMS}
              </label>
            </div>

            <div className="form-check mb-1">
              <input
                className="form-check-input"
                type="radio"
                name={GROUP_BY.YEAR_GROUPS}
                id={GROUP_BY.YEAR_GROUPS}
                value={GROUP_BY.YEAR_GROUPS}
                checked={values.groupBy === GROUP_BY.YEAR_GROUPS}
                onChange={() => {
                  setFieldValue('groupBy', GROUP_BY.YEAR_GROUPS);
                }}
              />
              <label className="form-check-label pl-3" htmlFor={GROUP_BY.YEAR_GROUPS}>
                {GROUP_BY_SERVER_TO_CLIENT_MAPPING.YEAR_GROUPS}
              </label>
            </div>

            <div className="form-check mb-1">
              <input
                className="form-check-input"
                type="radio"
                name={GROUP_BY.KEY_STAGES}
                id={GROUP_BY.KEY_STAGES}
                value={GROUP_BY.KEY_STAGES}
                checked={values.groupBy === GROUP_BY.KEY_STAGES}
                onChange={() => {
                  setFieldValue('groupBy', GROUP_BY.KEY_STAGES);
                }}
              />
              <label className="form-check-label pl-3" htmlFor={GROUP_BY.KEY_STAGES}>
                {GROUP_BY_SERVER_TO_CLIENT_MAPPING.KEY_STAGES}
              </label>
            </div>
          </div>

          <button type="button" className="btn btn-secondary mr-3" onClick={onCloseClick}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Get
          </button>
        </Form>
      )}
    />
  );
};
