import * as React from 'react';
import { useState } from 'react';
import './AvailabilityReportForm.scss';
import { Switch } from '../../../../../components/Switch/Switch';
import { Select } from 'Src/components/Select/Select';
import { getSelectOptionsForChildren } from 'Src/helpers/table/select';
import { Child } from 'Src/models/child';

interface Props {
  onCancel: () => void;
  onSubmit: (data) => void;
  childrenInEvent: Child[];
}

export function AvailabilityReportForm(props: Props) {
  const { childrenInEvent } = props;

  const isSeveralChildrenInEvent = childrenInEvent.length > 1;
  const selectOptionsForChildren = getSelectOptionsForChildren(childrenInEvent);

  const [details, setDetails] = useState('');
  const [selectedChildIndex, setSelectedChildIndex] = useState(0);
  const [isTakePart, setIsTakePart] = useState(false);
  const [isError, setIsError] = useState(false);

  const selectedChild = childrenInEvent[selectedChildIndex];

  const submitForm = () => {
    const isTakePartExist = typeof isTakePart !== 'undefined';
    if (isTakePartExist) {
      const data = {
        details,
        isTakePart,
        playerDetails: {
          permissionId: selectedChild.permissionId,
          userId: selectedChild.id
        }
      };
      props.onSubmit(data);
    } else {
      setIsError(true);
    }
  };

  const onDetailsChange = event => {
    setDetails(event.target.value);
  };

  const onReportChange = () => {
    setIsTakePart(!isTakePart);
  };

  return (
    <div className="bAvailabilityReport">
      <div>
        <div className="eAvailabilityReportTitle">Report Availability</div>

        {isSeveralChildrenInEvent && (
          <div>
            <div className="eAvailabilityReportLabel">Select child</div>
            <div>
              <Select
                onChange={value => {
                  const options = selectOptionsForChildren;
                  const nextIndex = options.findIndex(option => option.value === value);
                  setSelectedChildIndex(nextIndex);
                }}
                options={selectOptionsForChildren}
                activeResultIndex={selectedChildIndex}
                customClass={'form-control'}
              />
            </div>
          </div>
        )}

        <div className="eAvailabilityReportLabel">Availability</div>
        <div className="d-flex">
          <div className="mr-3">Not availible</div>
          <Switch
            key={'availability'}
            value={isTakePart}
            onChange={() => {
              onReportChange();
            }}
            text={''}
            name="availability"
          />
          <div className="ml-1">Availible</div>
        </div>

        {isError && <div className="alert alert-danger">Please enter availability.</div>}

        <div className="eAvailabilityReportLabel">Details</div>
        <textarea
          className="eAvailabilityReportInput"
          value={details}
          onChange={event => {
            onDetailsChange(event);
          }}
        />

        <div>
          <button className="btn btn-secondary mt-3 mb-3 mr-3" onClick={props.onCancel}>
            Cancel
          </button>
          <button className="btn btn-primary mt-3 mb-3" onClick={submitForm}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
