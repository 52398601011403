import * as React from 'react';
import { HTMLEditorQuill } from 'Src/components/HTMLEditorQuill/HTMLEditorQuill';
import { Attachment } from 'Src/models/generalMessage';
import { Loader } from 'Src/components/Loader/Loader';
import { FunctionComponent } from 'react';
import { getFileName } from '../../../../../helpers/file/file';

interface Props {
  onEmailSubjectChange: (event) => void;
  onEmailBodyChange: (editorState: any) => void;
  onEmailBodyEmptyTextChange: (emptyText: string) => void;
  onPushTitleChange: (event) => void;
  onPushBodyChange: (event) => void;
  onEmailAttachmentChange: (event) => void;
  onRemoveAttachment: (key: string) => void;
  onCloseImageError: () => void;
  onIsEmailEditorChange: (event) => void;
  onIsPushEditorChange: (event) => void;
  isLoadingFile: boolean;

  isEmailEditorEnabled: boolean;
  isPushEditorEnabled: boolean;
  isImageError: boolean;
  isError: boolean;
  isEditable?: boolean;
  messageEmailSubject: string;
  messageEmailBody: string;
  messagePushTitle: string;
  messagePushBody: string;
  messageAttachments: Attachment[];
}

export const TournamentCustomNotificationTextForm: FunctionComponent<Props> = props => {
  const renderAttachments = () => {
    const { isImageError, isError, messageAttachments } = props;
    const onRemoveAttachment = props.onRemoveAttachment;

    return messageAttachments.map((attachment, index) => {
      const fileName = attachment.fileName;
      const attachmentFileName = getFileName(fileName);
      let fileSize = '';
      if (typeof attachment.fileSize !== 'undefined') {
        fileSize = `${(attachment.fileSize / 1000).toFixed(0)} Kb`;
      }

      return (
        <div key={`attachment_${index}`}>
          {attachmentFileName} {fileSize}
          <button
            type="button"
            className="close"
            disabled={isImageError || isError}
            onClick={() => onRemoveAttachment(attachment.externalFileId)}
          >
            <span>&times;</span>
          </button>
        </div>
      );
    });
  };

  const {
    onEmailSubjectChange,
    onEmailBodyChange,
    onPushTitleChange,
    onPushBodyChange,
    onEmailAttachmentChange,
    messageEmailSubject,
    messageEmailBody,
    messagePushTitle,
    messagePushBody,
    isImageError,
    isError,
    isEditable,
    isEmailEditorEnabled,
    isPushEditorEnabled,
    isLoadingFile
  } = props;
  const isDisabled = isEditable === false;

  const isAllEditorDisabled = !isPushEditorEnabled && !isEmailEditorEnabled;
  return (
    <div className="bMessageTextForm">
      <div className="container-fluid pl-0">
        <div className="row">
          <div className="col-md-7">
            <div className="bMessageTextFormEmailEditor border border-primary rounded">
              <div className="form-group form-check">
                <input
                  id="messageTextFormIsEmailEditorEnabled"
                  type="checkbox"
                  className="form-check-input"
                  checked={isEmailEditorEnabled}
                  onChange={props.onIsEmailEditorChange}
                  disabled={isDisabled}
                />
                <label htmlFor="messageTextFormIsEmailEditorEnabled" className="form-check-label">
                  Send email
                </label>
              </div>
              <div className="form-group">
                <label>Subject</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={onEmailSubjectChange}
                  value={messageEmailSubject}
                  disabled={!isEmailEditorEnabled || isDisabled}
                />
              </div>
              <div className="form-group eMessageTextEditor">
                <label>Body</label>
                <HTMLEditorQuill
                  onEmailBodyChange={onEmailBodyChange}
                  value={messageEmailBody}
                  isEnabled={isEmailEditorEnabled}
                />
              </div>
              {!isDisabled && (
                <div className="form-group">
                  <label htmlFor="attachment">Attachments</label>
                  <input
                    type="file"
                    className="form-control-file"
                    id="attachment"
                    onChange={onEmailAttachmentChange}
                    disabled={isImageError || isError || isAllEditorDisabled}
                  />
                  {isLoadingFile && <Loader />}
                  {isImageError ? (
                    <div className="alert-danger">
                      Error uploading
                      <button type="button" className="close" onClick={props.onCloseImageError}>
                        <span>&times;</span>
                      </button>
                    </div>
                  ) : null}
                </div>
              )}
              {renderAttachments()}
            </div>
          </div>
          <div className="col-md-5">
            <div className="bMessageTextFormPushEditor border border-primary rounded">
              <div className="form-group form-check">
                <input
                  id="messageTextFormIsPushEditorEnabled"
                  type="checkbox"
                  className="form-check-input"
                  checked={isPushEditorEnabled}
                  onChange={props.onIsPushEditorChange}
                  disabled={isDisabled}
                />
                <label htmlFor="messageTextFormIsPushEditorEnabled" className="form-check-label">
                  Send push
                </label>
              </div>
              <div className="form-group">
                <label>Title</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={onPushTitleChange}
                  value={messagePushTitle}
                  disabled={!isPushEditorEnabled}
                />
              </div>
              <div className="form-group">
                <label>Body</label>
                <textarea
                  rows={8}
                  onChange={onPushBodyChange}
                  value={messagePushBody}
                  disabled={!isPushEditorEnabled}
                  className="form-control"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TournamentCustomNotificationTextForm.displayName = 'TournamentCustomNotificationTextForm';
