import * as React from 'react';
import * as propz from 'propz';
import * as BPromise from 'bluebird';
import { parse } from 'query-string';
import { History, Location } from 'history';
import { Component } from 'react';
import { AppUser } from 'Src/views/App/App';
import { getParentInboxMessages, getParentInboxMessagesCount } from 'Src/helpers/service/parent/messagesTable';
import { Loader } from 'Src/components/Loader/Loader';
import { ConsentRequestCard } from 'Src/components/ConsentRequestCard/ConsentRequestCard';
import { Filters } from '../Filters/Filters';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { getOrder, getSearchFilter, getSearchOrder, getServerFieldSectionWhere } from 'Src/helpers/table/table';
import { getSelectOptionsForParentChildren } from 'Src/helpers/table/select';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { MessageField } from 'Src/models/message';
import { Map } from 'Src/components/Map/Map';
import { ParentalConsentRequestForm } from './ParentalConsentRequestForm/ParentalConsentRequestForm';
import {
  acceptParentConsentRequest,
  rejectParentConsentRequest,
  updateParentConsentRequest
} from 'Src/helpers/service/parent/messagesTable';
import {
  ColumnDefinition,
  getFilters2,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { Pagination2 } from 'Src/components/Pagination/Pagination2';
import { getChildrenEvent } from 'Src/helpers/service/parent/childrenEvents';
import { getChildren } from '../../../../../helpers/service/parent/children';
import { EventInvitationMessage } from 'Src/models/message';
import { SchoolEvent } from 'Src/models/event';
import { Child } from 'Src/models/child';
import { INVITE_TYPE } from 'Src/consts/invite';
import { CARD_LIMIT } from 'Src/consts/table';
import './ConsentRequestsInbox.scss';

const FILTER_FIELDS: ColumnDefinition[] = [
  {
    text: 'Children',
    field: 'childIdList',
    type: FILTER_TYPE.MULTISELECT,
    accessor: [''],
    isSort: false
  },
  {
    text: 'Sport',
    field: 'sport',
    type: FILTER_TYPE.TEXT,
    accessor: [''],
    isSort: false
  },
  {
    text: 'Start date',
    field: 'startTime',
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: [''],
    isSort: true
  },
  {
    text: 'Finish date',
    field: 'finishTime',
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: [''],
    isSort: false
  },
  {
    text: 'Deadline',
    field: 'deadlineForAnswers',
    type: FILTER_TYPE.NONE,
    accessor: [''],
    isSort: true
  }
];

interface Props {
  user: AppUser;
  history: History;
  location: Location;
  setNeedUpdate: () => void;
}

interface State {
  items: {
    consentRequest: EventInvitationMessage;
    childEvent: SchoolEvent;
  }[];
  selectedItem: {
    consentRequest: EventInvitationMessage;
    childEvent: SchoolEvent;
  };
  total: number;
  currentPage: number;
  filters: any;
  sortFieldName: string;
  sortDirection: string;
  isFiltersOpen: boolean;
  isLoading: boolean;
  isSchoolEventLoading: boolean;
  children: Child[];
  basePath: string;
  isRejectModalOpen: boolean;
  isAcceptModalOpen: boolean;
  isViewMapModalOpen: boolean;
}

export class ConsentRequestsInbox extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      selectedItem: undefined,
      total: 0,
      currentPage: FIRST_PAGE,
      filters: {},
      sortFieldName: '',
      sortDirection: '',
      isFiltersOpen: false,
      isLoading: false,
      isSchoolEventLoading: false,
      basePath: '',
      children: [],
      isRejectModalOpen: false,
      isAcceptModalOpen: false,
      isViewMapModalOpen: false
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setItems();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);

    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortFieldName = '';

    if (typeof search.order === 'string') {
      [sortFieldName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortFieldName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(FILTER_FIELDS, search);

    const where = {
      kind: 'EventInvitationMessage',
      ...getServerFieldSectionWhere(filters)
    };

    const order = sortByFieldExist && sortDirectionExist ? `${sortFieldName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order, CARD_LIMIT);

    const getItemsPromise = getParentInboxMessages(user, serverQueryFilter);
    const getItemsCountPromise = getParentInboxMessagesCount(user, where);
    const getChildrenPromise = getChildren(user);

    const promises = [getItemsPromise, getItemsCountPromise, getChildrenPromise];

    let consentRequests;
    let consentRequestsCount;
    let children;

    return BPromise.all(promises)
      .then(([_consentRequests, _consentRequestsCount, _children]) => {
        consentRequests = _consentRequests;
        consentRequestsCount = _consentRequestsCount;
        children = _children;

        const promises = consentRequests.map((item: EventInvitationMessage) => {
          const eventId = propz.get(item, ['eventData', 'id']);

          return getChildrenEvent(user, eventId);
        });

        return BPromise.all(promises);
      })
      .then(childrenEvents => {
        let requests = [...consentRequests];

        const updatedItems = childrenEvents.map((childEvent: SchoolEvent) => {
          const { id } = childEvent;

          const consentRequest = requests.find((request: EventInvitationMessage) => {
            const { eventId, threadId } = request;

            requests = requests.filter(item => item.threadId !== threadId);

            return eventId === id;
          });

          return {
            consentRequest,
            childEvent
          };
        });

        this.setState({
          items: updatedItems,
          currentPage: page,
          children,
          filters,
          sortDirection,
          sortFieldName,
          total: consentRequestsCount.count,
          basePath: history.location.pathname,
          isLoading: false
        });
      });
  }

  setCurrentPageParams = (currentPage: number): void => {
    const { history } = this.props;
    let search = [];

    const { filters, sortFieldName, sortDirection, basePath } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortFieldName)) {
      search.push(getSearchOrder(sortDirection, sortFieldName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortFieldName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortFieldName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = propz.get(event, ['target', 'value'], '');
    const { filters } = this.state;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = FILTER_FIELDS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortFieldName } = this.state;
    const { history } = this.props;
    let search = [];

    if (isSortExist(sortDirection, sortFieldName)) {
      search.push(getSearchOrder(sortDirection, sortFieldName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onClearFilterClick = (): void => {
    this.props.history.push({
      pathname: this.state.basePath,
      search: ''
    });
  };

  onRejectConsentRequestClick = () => {
    const { user, setNeedUpdate } = this.props;
    const { selectedItem } = this.state;

    const id = propz.get(selectedItem, ['consentRequest', 'id'], '');

    this.setState({
      isLoading: true
    });

    rejectParentConsentRequest(user, id).then(() => {
      this.setState({
        isRejectModalOpen: false,
        selectedItem: undefined
      });

      this.setItems().then(() => {
        setNeedUpdate();
      });
    });
  };

  onAcceptConsentRequestClick = (fields: MessageField[]) => {
    const { user, setNeedUpdate } = this.props;
    const { selectedItem } = this.state;

    const id = propz.get(selectedItem, ['consentRequest', 'id'], '');

    this.setState({
      isLoading: true
    });

    const data = { fields };

    acceptParentConsentRequest(user, id)
      .then(() => {
        return updateParentConsentRequest(user, id, data);
      })
      .then(() => {
        this.setState({
          isAcceptModalOpen: false,
          selectedItem: undefined
        });
        this.setItems().then(() => {
          setNeedUpdate();
        });
      });
  };

  renderAcceptConsentRequestModal = () => {
    const { user } = this.props;
    const { isAcceptModalOpen, selectedItem } = this.state;
    const consentRequest = propz.get(selectedItem, ['consentRequest']);

    return (
      <SimpleModal isOpen={isAcceptModalOpen} title="Confirm the action">
        <ParentalConsentRequestForm
          user={user}
          message={consentRequest}
          onCloseClick={this.closeAcceptConsentRequestModal}
          onAcceptClick={this.onAcceptConsentRequestClick}
        />
      </SimpleModal>
    );
  };

  openAcceptConsentRequestModal = message => {
    this.setState({
      isAcceptModalOpen: true,
      selectedItem: message
    });
  };

  closeAcceptConsentRequestModal = () => {
    this.setState({
      isAcceptModalOpen: false,
      selectedItem: undefined
    });
  };

  renderRejectConsentRequestModal = () => {
    const { isRejectModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isRejectModalOpen}
        onCloseClick={this.closeRejectConsentRequestModal}
        onButtonClick={this.onRejectConsentRequestClick}
        buttonText="Proceed"
        title="Confirm the action"
        body="You are going to decline consent request for this event, so the school will be notified that your child is unable to take part. Please confirm you would like to proceed."
      />
    );
  };

  openRejectConsentRequestModal = message => {
    this.setState({
      isRejectModalOpen: true,
      selectedItem: message
    });
  };

  closeRejectConsentRequestModal = () => {
    this.setState({
      isRejectModalOpen: false,
      selectedItem: undefined
    });
  };

  renderViewMapModal = () => {
    const { isViewMapModalOpen, selectedItem } = this.state;

    const point = propz.get(selectedItem, ['childEvent', 'venue', 'point']);

    return (
      <SimpleModal isOpen={isViewMapModalOpen} onCloseClick={this.closeViewMapModal} title="View map">
        <Map point={point} customStylingClass="eChildEventVenueMap" isMarkerDraggable={false} />
      </SimpleModal>
    );
  };

  openViewMapModal = message => {
    this.setState({
      isViewMapModalOpen: true,
      selectedItem: message
    });
  };

  closeViewMapModal = () => {
    this.setState({
      isViewMapModalOpen: false,
      selectedItem: undefined
    });
  };

  render() {
    const { user } = this.props;
    const {
      isLoading,
      isFiltersOpen,
      total,
      currentPage,
      items,
      filters,
      children,
      sortFieldName,
      sortDirection
    } = this.state;

    const filterOptions = {
      childIdList: getSelectOptionsForParentChildren(children)
    };

    const childIdList = children.map(child => child.id);

    if (isLoading) {
      return <Loader />;
    }

    return (
      <div>
        {this.renderRejectConsentRequestModal()}
        {this.renderAcceptConsentRequestModal()}
        {this.renderViewMapModal()}
        <div className="d-flex justify-content-end align-items-center">
          {/* <Button
            text="Filters & Sorting"
            onClick={() => {
              this.setState(prevState => {
                return { isFiltersOpen: !prevState.isFiltersOpen };
              });
            }}
            customClass="my-3"
          /> */}

          <Pagination2
            currentPage={currentPage}
            total={total}
            setCurrentPageParams={this.setCurrentPageParams}
            limit={CARD_LIMIT}
          />
        </div>

        {isFiltersOpen && (
          <div>
            <Filters
              fields={FILTER_FIELDS}
              filters={filters}
              options={filterOptions}
              onTableFilterChange={this.onTableFilterChange}
              onTableSortClick={this.onTableSortClick}
              onApplyFilterClick={this.onApplyFilterClick}
              onClearFilterClick={this.onClearFilterClick}
              sortFieldName={sortFieldName}
              sortDirection={sortDirection}
            />
          </div>
        )}

        <div className="row gap-3">
          {items.map(item => {
            const { consentRequest } = item;
            const { threadId } = consentRequest;

            return (
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3" key={threadId}>
                <ConsentRequestCard
                  item={item}
                  type={INVITE_TYPE.INBOX}
                  childIdList={childIdList}
                  onRejectClick={this.openRejectConsentRequestModal}
                  onAcceptClick={this.openAcceptConsentRequestModal}
                  onViewMapClick={this.openViewMapModal}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
