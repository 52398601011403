import * as React from 'react';
import { FunctionComponent } from 'react';
import { Attachment } from 'Src/models/generalMessage';
import './TournamentCustomNotificationPreview.css';

interface Props {
  messageEmailSubject: string;
  messageEmailBody: string;
  messageAttachments?: Attachment[];
  showTitle?: boolean;
}

export const TournamentCustomNotificationPreviewEmail: FunctionComponent<Props> = props => {
  const isAttachmentExist = props.messageAttachments.length > 0;
  const renderAttachments = (): React.ReactNode => {
    return props.messageAttachments.map((attachment, index) => {
      return (
        <div key={`attachment_${index}`}>
          <a href={attachment.link} target="_blank">
            {attachment.fileName}
          </a>
        </div>
      );
    });
  };

  const renderBody = (): React.ReactNode => {
    if (typeof props.messageEmailBody === 'undefined' || props.messageEmailBody.length === 0) {
      return null;
    }
    return <div dangerouslySetInnerHTML={{ __html: props.messageEmailBody }} />;
  };

  return (
    <div className="card border-success h-100">
      {props.showTitle && <h5 className="card-header text-white eGeneralMessagePreviewEmailHeader">Email preview</h5>}
      <div className="card-body">
        <h5 className="card-title">{props.messageEmailSubject}</h5>
        {renderBody()}
      </div>
      {isAttachmentExist && <div className="card-footer bg-white border-white">Attachments: {renderAttachments()}</div>}
    </div>
  );
};
