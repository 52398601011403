const FILE_NAME_MAX_LENGTH = 15;

export const ALLOWED_MIME_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/gif',
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.ms-office',
  'application/vnd-xls',
  'application/msword',
  'application/doc',
  'application/vnd.msword',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/xls',
  'application/x-xls',
  'text/plain',
  'application/vnd.ms-excel',
  'application/msexcel',
  'application/x-msexcel',
  'application/x-ms-excel',
  'application/x-excel',
  'application/x-dos_ms_excel',
  'application/excel',
  'text/calendar'
];

export function getFileName(fileName: string): string {
  if (fileName.length <= FILE_NAME_MAX_LENGTH) {
    return fileName;
  }
  const fileNameExtension = fileName.substr(fileName.length - 5, 5);
  const fileNameCropped = fileName.substr(0, FILE_NAME_MAX_LENGTH);

  return `${fileNameCropped}...${fileNameExtension}`;
}

export function getSafeFileName(fileName: string): string {
  const dotLastIndex = fileName.lastIndexOf('.');
  const fileNameLength = fileName.length;
  const fileNameWithoutExtension = fileName.slice(0, dotLastIndex);
  const extensionWithStartDot = fileName.slice(dotLastIndex, fileNameLength);

  const regExp = /\.|\s/gi;

  const safeFileNameWithoutExtension = fileNameWithoutExtension.replace(regExp, '_');

  return `${safeFileNameWithoutExtension}${extensionWithStartDot}`;
}
