import * as React from 'react';
import * as propz from 'propz';
import { Button } from 'Src/components/Button/Button';
import { LabelWithQuestionIcon } from 'Src/components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import { Field, Form, Formik } from 'formik';
import { VENUE_DISTANCE, VENUE_DISTANCE_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/venue';
import * as Yup from 'yup';
import { Autocomplete } from 'Src/components/Autocomplete/Autocomplete';
import { getSchoolName, searchFunctionParticipantsOfTournament } from 'Src/helpers/autocomplete/autocomplete';
import { AppUser } from '../../../../App/App';
import { SchoolEvent, Participant } from 'Src/models/event';

interface Props {
  event: SchoolEvent;
  user: AppUser;
  participants: Participant[];
  onCancel: () => void;
  onSubmit: (schoolId: string) => void;
}

interface State {
  distance: number;
}

const initialValues = {
  schoolId: '',
  school: undefined
};

const validationSchema = Yup.object().shape({
  schoolId: Yup.string().required('Required')
});

export class TournamentEventAddSchoolForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      distance: VENUE_DISTANCE.UNLIMITED
    };
  }

  getSchools = (text: string) => {
    const { user, event, participants } = this.props;

    return searchFunctionParticipantsOfTournament(text, user, event, participants);
  };

  render() {
    const { onCancel, onSubmit } = this.props;
    const { distance } = this.state;

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <LabelWithQuestionIcon labelText="Maximum distance" hintText={''} />
              <select
                value={distance}
                className="form-control"
                onChange={event => {
                  this.setState({
                    distance: Number(event.target.value)
                  });
                }}
              >
                <option value={VENUE_DISTANCE.UNLIMITED}>{VENUE_DISTANCE_SERVER_TO_CLIENT_MAPPING.UNLIMITED}</option>
                <option value={VENUE_DISTANCE['10M']}>{VENUE_DISTANCE_SERVER_TO_CLIENT_MAPPING['10M']}</option>
                <option value={VENUE_DISTANCE['20M']}>{VENUE_DISTANCE_SERVER_TO_CLIENT_MAPPING['20M']}</option>
                <option value={VENUE_DISTANCE['30M']}>{VENUE_DISTANCE_SERVER_TO_CLIENT_MAPPING['30M']}</option>
                <option value={VENUE_DISTANCE['40M']}>{VENUE_DISTANCE_SERVER_TO_CLIENT_MAPPING['40M']}</option>
                <option value={VENUE_DISTANCE['50M']}>{VENUE_DISTANCE_SERVER_TO_CLIENT_MAPPING['50M']}</option>
              </select>
            </div>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={data => {
                const { schoolId } = data;
                onSubmit(schoolId);
              }}
              render={({ values, setFieldValue, touched, errors }) => (
                <Form>
                  <div className="form-group">
                    <Field
                      name="participants"
                      render={({ field }) => {
                        return (
                          <Autocomplete
                            searchFunction={this.getSchools}
                            getElementTitle={getSchoolName}
                            customClass="mFullWidth mb-3"
                            defaultItem={values.school}
                            placeholder="Start typing the school name"
                            onSelect={school => {
                              setFieldValue('school', school);
                              setFieldValue('schoolId', school.schoolId);
                            }}
                          />
                        );
                      }}
                    />
                    {touched.schoolId && errors.schoolId && <div className="alert alert-danger">{errors.schoolId}</div>}
                  </div>

                  <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                  <button type="submit" className="mt-3 mb-3 btn btn-primary">
                    Save
                  </button>
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}
