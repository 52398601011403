export const INTEGRATION_TYPE_SERVER_TO_CLIENT_MAPPING = {
  google: 'Google Calendar',
  twitter: 'Twitter',
  outlook: 'Outlook',
  iCalendar: 'iCalendar'
};

export const INTEGRATION_TYPE = {
  GOOGLE_CALENDAR: 'google',
  TWITTER: 'twitter',
  OUTLOOK: 'outlook',
  I_CALENDAR: 'iCalendar'
};
