import * as React from 'react';
import * as propz from 'propz';
import * as BPromise from 'bluebird';
import { parse } from 'query-string';
import { History, Location } from 'history';
import { AppUser } from 'Src/views/App/App';
import { Component } from 'react';
import { Basket } from 'Src/models/basket';
import { Loader } from 'Src/components/Loader/Loader';
import { ClubBookingCard } from 'Src/components/ClubBookingCard/ClubBookingCard';
import { Button } from 'Src/components/Button/Button';
import { Filters } from '../../ConsentRequests/Filters/Filters';
import { EventInvitationMessage } from 'Src/models/message';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { MessageField } from 'Src/models/message';
import { Map } from 'Src/components/Map/Map';
import { Child } from 'Src/models/child';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { getParentInboxMessages, getParentInboxMessagesCount } from 'Src/helpers/service/parent/messagesTable';
import { getOrder, getSearchFilter, getSearchOrder, getServerFieldSectionWhere } from 'Src/helpers/table/table';
import { getSelectOptionsForParentChildren } from 'Src/helpers/table/select';
import { rejectParentClubInvitation, acceptParentClubInvitation } from 'Src/helpers/service/parent/messagesTable';
import { getBasket } from 'Src/helpers/service/parent/basket';
import {
  ColumnDefinition,
  getFilters2,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { Pagination2 } from 'Src/components/Pagination/Pagination2';
import { getChildren } from 'Src/helpers/service/parent/children';
import { addOrderToBasket } from 'Src/helpers/service/parent/basket';
import { INVITE_KIND, INVITE_TYPE } from 'Src/consts/invite';
import { ClubInvitationForm } from 'Src/components/ClubInvitationForm/ClubInvitationForm';
import { setMessageWaitingStatus } from 'Src/helpers/service/parent/messagesTable';
import { ClubBookingDeatils } from '../ClubBookingDeatils/ClubBookingDeatils';
import { CARD_LIMIT } from 'Src/consts/table';

interface Props {
  user: AppUser;
  history: History;
  location: Location;
  setNeedUpdate: () => void;
}

interface State {
  items: EventInvitationMessage[];
  selectedItem: EventInvitationMessage;
  basket: Basket;
  total: number;
  currentPage: number;
  filters: any;
  sortFieldName: string;
  sortDirection: string;
  isFiltersOpen: boolean;
  isLoading: boolean;
  children: Child[];
  basePath: string;
  isViewMapModalOpen: boolean;
  isDeclineModalOpen: boolean;
  isAcceptModalOpen: boolean;
  isAddToBasketProceedModalOpen: boolean;
  isShowDetailsModalOpen: boolean;
}

const FILTER_FIELDS: ColumnDefinition[] = [
  {
    text: 'Children',
    field: 'childIdList',
    type: FILTER_TYPE.MULTISELECT,
    accessor: [''],
    isSort: false
  },
  {
    text: 'Sport',
    field: 'sport',
    type: FILTER_TYPE.TEXT,
    accessor: [''],
    isSort: false
  },
  {
    text: 'Start date',
    field: 'startTime',
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: [''],
    isSort: true
  },
  {
    text: 'Finish date',
    field: 'finishTime',
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: [''],
    isSort: false
  },
  {
    text: 'Deadline',
    field: 'deadlineForAnswers',
    type: FILTER_TYPE.NONE,
    accessor: [''],
    isSort: true
  }
];

export class ClubBookingsInbox extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      selectedItem: undefined,
      basket: undefined,
      total: 0,
      currentPage: FIRST_PAGE,
      filters: {},
      sortFieldName: '',
      sortDirection: '',
      isFiltersOpen: false,
      isLoading: false,
      basePath: '',
      children: [],
      isViewMapModalOpen: false,
      isDeclineModalOpen: false,
      isAcceptModalOpen: false,
      isAddToBasketProceedModalOpen: false,
      isShowDetailsModalOpen: false
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setItems();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);

    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortFieldName = '';

    if (typeof search.order === 'string') {
      [sortFieldName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortFieldName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(FILTER_FIELDS, search);

    const where = {
      kind: 'ClubParticipantInviteMessage',
      ...getServerFieldSectionWhere(filters)
    };

    const order = sortByFieldExist && sortDirectionExist ? `${sortFieldName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order, CARD_LIMIT);

    const getItemsPromise = getParentInboxMessages(user, serverQueryFilter);
    const getItemsCountPromise = getParentInboxMessagesCount(user, where);
    const getChildrenPromise = getChildren(user);
    const getBasketPromise = getBasket(user);

    const promises = [getItemsPromise, getItemsCountPromise, getChildrenPromise, getBasketPromise];

    return BPromise.all(promises).then(([clubBookings, clubBookingsCountObj, children, basket]) => {
      this.setState({
        items: clubBookings,
        basket,
        currentPage: page,
        children,
        filters,
        sortDirection,
        sortFieldName,
        total: clubBookingsCountObj.count,
        basePath: history.location.pathname,
        isLoading: false
      });
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    const { history } = this.props;
    let search = [];

    const { filters, sortFieldName, sortDirection, basePath } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortFieldName)) {
      search.push(getSearchOrder(sortDirection, sortFieldName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortFieldName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortFieldName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = propz.get(event, ['target', 'value'], '');
    const { filters } = this.state;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = FILTER_FIELDS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortFieldName } = this.state;
    const { history } = this.props;
    let search = [];

    if (isSortExist(sortDirection, sortFieldName)) {
      search.push(getSearchOrder(sortDirection, sortFieldName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onClearFilterClick = (): void => {
    this.props.history.push({
      pathname: this.state.basePath,
      search: ''
    });
  };

  onBookNowClick = (fields: MessageField[], priority: number) => {
    const { user, setNeedUpdate } = this.props;
    const { selectedItem } = this.state;

    this.setState({
      isLoading: true
    });

    const data = { fields, priority };

    acceptParentClubInvitation(user, selectedItem.id, data)
      .then(() => {
        this.setState({
          isAcceptModalOpen: false,
          selectedItem: undefined
        });

        return this.setItems();
      })
      .then(() => {
        setNeedUpdate();
      });
  };

  onAddToBasketClick = () => {
    const { user, setNeedUpdate } = this.props;
    const { selectedItem } = this.state;

    this.setState({
      isLoading: true
    });

    const schoolId = propz.get(selectedItem, ['schoolId'], '');
    const studentId = propz.get(selectedItem, ['playerDetailsData', 'id'], '');
    const clubId = propz.get(selectedItem, ['clubId'], '');
    const clubPrice = propz.get(selectedItem, ['clubData', 'price', 'price']);
    const messageId = propz.get(selectedItem, ['id']);

    addOrderToBasket(user, schoolId, clubId, studentId, clubPrice, messageId)
      .then(() => {
        setNeedUpdate();

        return this.setItems();
      })
      .then(() => {
        this.setState({
          isAddToBasketProceedModalOpen: true,
          isAcceptModalOpen: false
        });
      });
  };

  onAddToWaitingListClick = () => {
    const { user } = this.props;
    const { selectedItem } = this.state;

    setMessageWaitingStatus(user, selectedItem.id).then(() => {
      this.setState({
        isAcceptModalOpen: false,
        selectedItem: undefined
      });
      this.setItems();
    });
  };

  renderAcceptClubBookingModal = () => {
    const { user } = this.props;
    const { isAcceptModalOpen, selectedItem, basket } = this.state;

    return (
      <SimpleModal isOpen={isAcceptModalOpen} title="Confirm the action" customClass="modal-lg">
        <ClubInvitationForm
          user={user}
          message={selectedItem}
          onBookNowClick={this.onBookNowClick}
          onAddToBasketClick={this.onAddToBasketClick}
          onAddToWaitingListClick={this.onAddToWaitingListClick}
          closeClubInvitationModal={this.closeAcceptClubBookingModal}
        />
      </SimpleModal>
    );
  };

  openAcceptClubBookingModal = message => {
    this.setState({
      isAcceptModalOpen: true,
      selectedItem: message
    });
  };

  closeAcceptClubBookingModal = () => {
    this.setState({
      isAcceptModalOpen: false,
      selectedItem: undefined
    });
  };

  onGoToBasketClick = () => {
    const { history } = this.props;

    history.push({
      pathname: `/basket`
    });
  };

  renderAddToBasketProceedModal = () => {
    const { isAddToBasketProceedModalOpen, selectedItem, basket } = this.state;
    const orderAmount = propz.get(basket, ['orderAmount'], 0);

    let basketOrdersCount = 0;

    const isBasketExist = typeof basket !== 'undefined';

    if (isBasketExist) {
      const { items } = isBasketExist && basket;

      basketOrdersCount = isBasketExist && items.length;
    }

    return (
      <SimpleModal
        isOpen={isAddToBasketProceedModalOpen}
        title={`Basket Subtotal: £${orderAmount}`}
        body="Please select how you would like to proceed."
        customClass="modal-lg"
      >
        <div className="d-flex flex-wrap mt-4">
          <Button customClass="mr-2" text={`Proceed to checkout ${basketOrdersCount} item(s)`} onClick={() => {}} />
          <Button customClass="mr-2" text="Go to basket" onClick={this.onGoToBasketClick} />
          <Button text="Continue booking" onClick={this.closeAddToBasketProceedModal} />
        </div>
      </SimpleModal>
    );
  };

  openAddToBasketProceedModal = () => {
    this.setState({
      isAddToBasketProceedModalOpen: true
    });
  };

  closeAddToBasketProceedModal = () => {
    this.setState({
      isAddToBasketProceedModalOpen: false,
      selectedItem: undefined
    });
  };

  onDeclineClubBookingClick = () => {
    const { user, setNeedUpdate } = this.props;
    const { selectedItem } = this.state;

    this.setState({
      isLoading: true
    });

    rejectParentClubInvitation(user, selectedItem.id).then(() => {
      this.setState({
        isDeclineModalOpen: false,
        selectedItem: undefined
      });
      this.setItems().then(() => {
        setNeedUpdate();
      });
    });
  };

  renderDeclineClubBookingModal = () => {
    const { isDeclineModalOpen, selectedItem } = this.state;
    const clubName = propz.get(selectedItem, ['clubData', 'name'], '');

    return (
      <SimpleModal
        isOpen={isDeclineModalOpen}
        onCloseClick={this.closeDeclineClubBookingModal}
        onButtonClick={this.onDeclineClubBookingClick}
        buttonText="Proceed"
        title="Confirm the action"
        body={`You are going to decline club invitation for ${clubName}. Please confirm you would like to proceed.`}
      />
    );
  };

  openDeclineClubBookingModal = message => {
    this.setState({
      isDeclineModalOpen: true,
      selectedItem: message
    });
  };

  closeDeclineClubBookingModal = () => {
    this.setState({
      isDeclineModalOpen: false,
      selectedItem: undefined
    });
  };

  renderViewMapModal = () => {
    const { isViewMapModalOpen, selectedItem } = this.state;

    const point = propz.get(selectedItem, ['clubData', 'venue', 'placeData', 'point']);

    return (
      <SimpleModal isOpen={isViewMapModalOpen} onCloseClick={this.closeViewMapModal} title="View map">
        <Map point={point} customStylingClass="eChildEventVenueMap" isMarkerDraggable={false} />
      </SimpleModal>
    );
  };

  openViewMapModal = message => {
    this.setState({
      isViewMapModalOpen: true,
      selectedItem: message
    });
  };

  closeViewMapModal = () => {
    this.setState({
      isViewMapModalOpen: false,
      selectedItem: undefined
    });
  };

  renderShowDetailsModal = () => {
    const { isShowDetailsModalOpen, selectedItem } = this.state;

    return (
      <SimpleModal isOpen={isShowDetailsModalOpen} onCloseClick={this.closeShowDetailsModal} title="Club details">
        <ClubBookingDeatils message={selectedItem} />
      </SimpleModal>
    );
  };

  openShowDetailsModal = message => {
    this.setState({
      isShowDetailsModalOpen: true,
      selectedItem: message
    });
  };

  closeShowDetailsModal = () => {
    this.setState({
      isShowDetailsModalOpen: false,
      selectedItem: undefined
    });
  };

  render() {
    const { user } = this.props;
    const {
      isLoading,
      isFiltersOpen,
      total,
      currentPage,
      items,
      basket,
      filters,
      children,
      sortFieldName,
      sortDirection
    } = this.state;

    const filterOptions = {
      childIdList: getSelectOptionsForParentChildren(children)
    };

    const childIdList = children.map(child => child.id);

    if (isLoading) {
      return <Loader />;
    }

    return (
      <div>
        {this.renderAddToBasketProceedModal()}
        {this.renderDeclineClubBookingModal()}
        {this.renderAcceptClubBookingModal()}
        {this.renderViewMapModal()}
        {this.renderShowDetailsModal()}
        <div className="d-flex justify-content-end align-items-center">
          {/* <Button
            text="Filters & Sorting"
            onClick={() => {
              this.setState(prevState => {
                return { isFiltersOpen: !prevState.isFiltersOpen };
              });
            }}
            customClass="my-3"
          /> */}

          <Pagination2
            currentPage={currentPage}
            total={total}
            setCurrentPageParams={this.setCurrentPageParams}
            limit={CARD_LIMIT}
          />
        </div>

        {isFiltersOpen && (
          <div>
            <Filters
              fields={FILTER_FIELDS}
              filters={filters}
              options={filterOptions}
              onTableFilterChange={this.onTableFilterChange}
              onTableSortClick={this.onTableSortClick}
              onApplyFilterClick={this.onApplyFilterClick}
              onClearFilterClick={this.onClearFilterClick}
              sortFieldName={sortFieldName}
              sortDirection={sortDirection}
            />
          </div>
        )}

        <div className="row gap-3 mt-2">
          {items.map(item => {
            const { id } = item;

            return (
              <div className="col-12 col-md-6 col-lg-4 mb-4" key={id}>
                <ClubBookingCard
                  user={user}
                  item={item}
                  type={INVITE_TYPE.INBOX}
                  onDeclineClick={this.openDeclineClubBookingModal}
                  onBookClick={this.openAcceptClubBookingModal}
                  onViewMapClick={this.openViewMapModal}
                  onShowDetailsClick={this.openShowDetailsModal}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
