import axios from 'axios';
import { AppUser } from '../../../views/App/App';

export function getSchoolEventDates(user: AppUser, filter) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: filter
    }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/events/dates/distinct`, config).then(response => {
    return response.data;
  });
}

export function getSchoolTournamentEventDates(user: AppUser, filter) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: filter
    }
  };

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/dates/distinct`, config)
    .then(response => {
      return response.data;
    });
}
