import { AppUser } from 'Src/views/App/App';
import axios from 'axios';

export function updateSchoolEventTeam(user: AppUser, eventId: string, teamId: string, data) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .put(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/teams/${teamId}`, data, config)
    .then(response => {
      return response.data;
    });
}

export function getSchoolEventTeam(user: AppUser, eventId: string, teamId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/teams/${teamId}`, config)
    .then(response => {
      return response.data;
    });
}

export function removeSchoolEventTeam(user: AppUser, eventId: string, teamId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .delete(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/teams/${teamId}`, config)
    .then(response => {
      return response.data;
    });
}
