import * as React from 'react';
import * as propz from 'propz';
import { FunctionComponent } from 'react';
import { AppUser } from 'Src/views/App/App';
import { Tournament } from '../../models/tournament';
import { getTeamAges, getTeamGender, getTeamGroupsName } from '../../helpers/accessor/accessor';

interface Props {
  teams: any[];
  tournament: Tournament;
  user: AppUser;
}

export const TournamentTeamsSummary: FunctionComponent<Props> = props => {
  const { teams, tournament, user } = props;

  const rows = teams.map((team, index) => {
    const schoolName = propz.get(team, ['school', 'name'], '');
    const teamName = propz.get(team, ['name'], '');
    const teamGender = getTeamGender(team);
    const teamAges = getTeamAges(team, { user });
    const teamGroupsName = getTeamGroupsName(team, tournament);

    return (
      <tr key={team.id}>
        <td>{index + 1}</td>
        <td>{schoolName}</td>
        <td>{teamName}</td>
        <td>{teamGender}</td>
        <td>{teamAges}</td>
        <td>{teamGroupsName}</td>
      </tr>
    );
  });

  const emptyRow = (
    <tr key={'tournament_teams_empty_row'}>
      <td>No teams</td>
    </tr>
  );

  return (
    <table className={'table table-sm mPre mVerticalAlignMiddle'}>
      <thead>
        <tr>
          <th>#</th>
          <th>School Name</th>
          <th>Team Name</th>
          <th>Gender</th>
          <th>Ages</th>
          <th>Group</th>
        </tr>
      </thead>
      <tbody>{rows.length > 0 ? rows : emptyRow}</tbody>
    </table>
  );
};

TournamentTeamsSummary.displayName = 'TournamentTeamsSummary';
