import * as React from 'react';
import { SelectOptionCricket } from '../../../../../../../consts/cricket';

interface Props {
  onCricketTextResultChange: (event) => void;
  cricketResultSelected: string;
  availableResults: SelectOptionCricket[];
}

export function CricketTextResultSelect(props: Props) {
  const { onCricketTextResultChange, cricketResultSelected, availableResults } = props;

  const options = availableResults.map((option, index) => {
    const { value, text, teamId } = option;
    const isOptionTeamIdExist = typeof teamId !== 'undefined';
    const optionValue = isOptionTeamIdExist ? `${value}/${teamId}` : `${value}`;
    return (
      <option key={index} value={optionValue}>
        {text}
      </option>
    );
  });

  return (
    <div>
      <div>Game result:</div>
      <select className="form-control mb-3" onChange={onCricketTextResultChange} value={cricketResultSelected}>
        {options}
      </select>
    </div>
  );
}
