import * as React from 'react';
import * as BPromise from 'bluebird';
import * as Lazy from 'lazy.js';
import { AppUser } from 'Src/views/App/App';
import { parse } from 'query-string';
import { getOrder, getSearchFilter, getSearchOrder, getServerFieldSectionWhere } from 'Src/helpers/table/table';
import { DATE_INTERVAL, DEFAULT_LIMIT, DEFAULT_SKIP, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { Loader } from 'Src/components/Loader/Loader';
import {
  ColumnDefinition,
  getFilters2,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { History, Location } from 'history';
import { Grid2 } from 'Src/components/Grid/Grid2';
import {
  getEmailOrMisEmail,
  getPhoneOrMisPhone,
  getUserForms,
  getUserHouses,
  getUserRole,
  getUserTags
} from 'Src/helpers/accessor/accessor';
import { SchoolUser } from 'Src/models/schoolUser';
import { getSchoolForms, getSchoolHouses, getSchoolSports } from 'Src/helpers/service/admin/user';
import { getSelectOptionForTag } from 'Src/helpers/table/select';
import {
  deleteDataToPermission,
  getAllSchoolStaffs,
  getSchoolStaffs,
  getSchoolStaffsCount,
  postUserPermission,
  putDataToPermission,
  putUserPermission
} from 'Src/helpers/service/admin/staffs';
import { getAllUserTags } from 'Src/helpers/service/admin/userTags';
import { GeneralMessageWizzard } from 'Src/components/GeneralMessageWizzard/GeneralMessageWizzard';
import { RoleForm } from 'Src/components/RoleForm/RoleForm';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { TextUtils } from 'Src/helpers/utils/TextUtils';
import { HouseRemoveModal } from 'Src/components/StaffListModals/HouseRemoveModal';
import { FormRemoveModal } from 'Src/components/StaffListModals/FormRemoveModal';
import { HouseAssignModal } from 'Src/components/StaffListModals/HouseAssignModal';
import { FormAssignModal } from 'Src/components/StaffListModals/FormAssignModal';
import * as propz from 'propz';
import { ROLE, STAFF_LIST } from 'Src/consts/user';
import { ResizablePanel } from 'Src/components/ResizablePanel/ResizablePanel';
import { MEMBER_OF_STAFF_TABS } from 'Src/consts/school';
import { GenericSummary } from 'Src/components/GenericSummary/GenericSummary';
import { StaffPermissions } from 'Src/components/StaffSummary/StaffPermissions';
import { StaffSummary } from 'Src/components/StaffSummary/StaffSummary';
import { getSelectOptionForForms, getSelectOptionForHouses, getSelectOptionForStaffs } from 'Src/helpers/table/select';
import './Staffs.css';
import { RoleRemoveModal } from 'Src/components/StaffListModals/RoleRemoveModal';
import { getSettingsUploadFiles } from 'Src/helpers/service/nobody/settings';
import { SchoolForm } from '../../../../../models/form';
import { SchoolHouse } from '../../../../../models/house';
import { Sport } from '../../../../../models/sport';
import { Tag } from 'Src/models/tag';
import { UserTagAssignModal } from 'Src/components/UserTagAssignModal/UserTagAssignModal';
import { UserTagDeleteModal } from 'Src/components/UserTagDeleteModal/UserTagDeleteModal';
import { assignUserTag, deleteUserTagByUser } from 'Src/helpers/service/admin/users';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Name',
    field: 'firstName',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['firstName']
  },
  {
    text: 'Surname',
    field: 'lastName',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['lastName']
  },
  {
    text: 'Email',
    field: 'email',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: getEmailOrMisEmail
  },
  {
    text: 'Phone',
    field: 'phone',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: getPhoneOrMisPhone
  },
  {
    text: 'Role',
    field: 'role',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getUserRole
  },
  {
    text: 'Forms',
    field: 'permissionFormIds',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getUserForms
  },
  {
    text: 'Houses',
    field: 'permissionHouseIds',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getUserHouses
  },
  {
    text: 'Tag',
    field: 'tag',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getUserTags
  }
];

interface State {
  items: SchoolUser[];
  currentPage: number;
  selectedItems: SchoolUser[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;
  isMessageModalOpen: boolean;
  isUserTagModalOpen: boolean;
  isUserTagDeleteModalOpen: boolean;
  currentTabIndex: number;
  editablePermissionId: string;
  isAddRoleModalOpen: boolean;
  isListActionsModalOpen: boolean;
  activeListAction: STAFF_LIST_ACTIONS;
  maxSizeUploadFile: number;
  forms: SchoolForm[];
  houses: SchoolHouse[];
  sports: Sport[];
  tags: Tag[];
  selectedUserTagId: string;
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

enum STAFF_LIST_ACTIONS {
  ASSIGN_FORM = 'ASSIGN_FORM',
  ASSIGN_HOUSE = 'ASSIGN_HOUSE',
  REMOVE_FORM = 'REMOVE_FORM',
  REMOVE_HOUSE = 'REMOVE_HOUSE',
  REMOVE_ROLE = 'REMOVE_ROLE'
}

export class Staffs extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',
      isMessageModalOpen: false,
      isUserTagModalOpen: false,
      isUserTagDeleteModalOpen: false,
      currentTabIndex: 0,
      editablePermissionId: '',
      isAddRoleModalOpen: false,
      isListActionsModalOpen: false,
      activeListAction: null,
      maxSizeUploadFile: 0,
      forms: [],
      houses: [],
      sports: [],
      tags: [],
      selectedUserTagId: ''
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setAdditionalItems().then(res => {
      this.setItems();
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  setAdditionalItems() {
    const { user } = this.props;

    const staffTags = getAllUserTags(user);
    const formsPromise = getSchoolForms(user, { limit: DEFAULT_LIMIT, skip: DEFAULT_SKIP });
    const housesPromise = getSchoolHouses(user, { limit: DEFAULT_LIMIT, skip: DEFAULT_SKIP });
    const settingsUploadPromise = getSettingsUploadFiles(user);

    return BPromise.all([staffTags, formsPromise, housesPromise, settingsUploadPromise]).then(
      ([tags, forms, houses, maxSizeUploadFile]) => {
        this.setState({
          forms,
          houses,
          maxSizeUploadFile,
          tags
        });

        return true;
      }
    );
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const getItemsPromise = getSchoolStaffs(user, serverQueryFilter);
    const getItemsCountPromise = getSchoolStaffsCount(user, where);

    const promises = [getItemsCountPromise, getItemsPromise];

    return BPromise.all(promises).then(([countObj, items]) => {
      this.setState({
        currentPage: page,
        sortDirection: sortDirection,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: items,
        total: countObj.count,
        basePath: history.location.pathname,
        isLoading: false
      });

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: nextSelectedItems,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    getAllSchoolStaffs(user, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const nextSelectedItems = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: nextSelectedItems,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: ''
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  renderGMWizzard(): React.ReactNode {
    const { user } = this.props;
    const { selectedItems, isMessageModalOpen, maxSizeUploadFile } = this.state;

    if (!isMessageModalOpen) {
      return null;
    }

    return (
      <GeneralMessageWizzard
        user={user}
        onMessageSubmit={this.onMessageSubmitClick}
        onCancelClick={this.onCloseMessageWizardClick}
        schoolStaff={selectedItems}
        sendToStaff={true}
        isOpen={isMessageModalOpen}
        maxSizeUploadFile={maxSizeUploadFile}
      />
    );
  }

  onCloseMessageWizardClick = () => {
    this.setState({
      isMessageModalOpen: false
    });
  };

  onMessageSubmitClick = message => {
    const { history } = this.props;

    history.push({
      pathname: '/generalMessages',
      state: { message: message }
    });
  };

  renderAddRoleModal(): React.ReactNode {
    const selectedItem = this.state.selectedItems[0];
    const { editablePermissionId, isAddRoleModalOpen, sports } = this.state;

    if (typeof selectedItem === 'undefined') {
      return null;
    }

    const permission = selectedItem.permissions.find(permission => permission.id === editablePermissionId);

    return (
      <SimpleModal isOpen={isAddRoleModalOpen}>
        <RoleForm
          user={this.props.user}
          onSubmit={this.onRoleSubmitClick}
          onCancel={this.onAddRoleCancelClick}
          permission={permission}
          selectedItem={selectedItem}
          sports={sports}
        />
      </SimpleModal>
    );
  }

  onAddRoleCancelClick = (): void => {
    this.setState({
      isAddRoleModalOpen: false
    });
  };

  onRoleSubmitClick = data => {
    let promise;

    this.setState({
      isLoading: true
    });

    const selectedItem = this.state.selectedItems[0];

    if (data.preset !== ROLE.COACH && data.preset !== ROLE.TEACHER) {
      delete data.sportIds;
      delete data.sports;
    }

    if (TextUtils.isEmpty(data.id)) {
      promise = postUserPermission(this.props.user, selectedItem.id, data);
    } else {
      promise = putUserPermission(this.props.user, selectedItem.id, data);
    }

    promise.then(item => {
      if (TextUtils.isEmpty(data.id)) {
        selectedItem.permissions.push(item);
      } else {
        const prevPermissionIndex = selectedItem.permissions.findIndex(permission => permission.id === item.id);
        selectedItem.permissions.splice(prevPermissionIndex, 1, item);
      }

      this.setState({
        isAddRoleModalOpen: false,
        editablePermissionId: '',
        selectedItems: [selectedItem]
      });
      this.setItems();
    });
  };

  getSelectedUsersIdWithStaffPermission(): { userId: string; permission: any }[] {
    const { selectedItems } = this.state;
    let idsList: { userId: string; permission: any }[] = [];

    [...selectedItems].forEach(staff => {
      const permission = staff.permissions.find(perm => STAFF_LIST.findIndex(preset => preset === perm.preset) !== -1);

      idsList.push({ userId: staff.id, permission: permission });
    });

    return idsList;
  }

  onAssignFormsClick = (forms): void => {
    const { user } = this.props;
    const formIds = forms.map(form => form.id);
    const userAndPermissionIdList = this.getSelectedUsersIdWithStaffPermission();
    const promises = userAndPermissionIdList.map(({ userId, permission }) => {
      const permissionFormIds = propz.get(permission, ['formIds'], []);
      formIds.forEach(formId => {
        if (permissionFormIds.findIndex(id => id === formId) === -1) {
          permissionFormIds.push(formId);
        }
      });

      return putDataToPermission(user, userId, permission.id, { formIds: permissionFormIds });
    });

    this.setState({
      isLoading: true
    });

    BPromise.all(promises).then(items => {
      const { selectedItems } = this.state;
      //replace old permission
      const nextSelectedItems = items.map(item => {
        const user = [...selectedItems].find(selectedItem =>
          selectedItem.permissions.some(permission => permission.id === item.id)
        );

        const prevPermissionIndex = user.permissions.findIndex(permission => permission.id === item.id);
        user.permissions.splice(prevPermissionIndex, 1, item);

        return user;
      });

      this.setState({
        selectedItems: nextSelectedItems
      });

      this.onCloseActionsPopupClick();
      this.setItems();
    });
  };

  onCloseActionsPopupClick = (): void => {
    this.setState({
      isListActionsModalOpen: false,
      activeListAction: null
    });
  };

  onAssignHouseClick = (houses): void => {
    const { user } = this.props;
    const houseIds = houses.map(house => house.id);
    const userAndPermissionIdList = this.getSelectedUsersIdWithStaffPermission();
    const promises = userAndPermissionIdList.map(({ userId, permission }) => {
      const permissionHouseIds = propz.get(permission, ['houseIds'], []);
      houseIds.forEach(houseId => {
        if (permissionHouseIds.findIndex(id => id === houseId) === -1) {
          permissionHouseIds.push(houseId);
        }
      });

      return putDataToPermission(user, userId, permission.id, { houseIds: permissionHouseIds });
    });

    this.setState({
      isLoading: true
    });

    BPromise.all(promises).then(items => {
      const { selectedItems } = this.state;
      //replace old permission
      const nextSelectedItems = items.map(item => {
        const user = [...selectedItems].find(selectedItem =>
          selectedItem.permissions.some(permission => permission.id === item.id)
        );

        const prevPermissionIndex = user.permissions.findIndex(permission => permission.id === item.id);
        user.permissions.splice(prevPermissionIndex, 1, item);

        return user;
      });

      this.setState({
        selectedItems: nextSelectedItems
      });
      this.onCloseActionsPopupClick();
      this.setItems();
    });
  };

  getFormsFiltered() {
    const { selectedItems } = this.state;

    const permissions = Lazy(selectedItems)
      .map(item => item.permissions)
      .flatten()
      .toArray();
    const allForms = Lazy(permissions)
      .map(permission => permission.forms || [])
      .flatten()
      .uniq('id')
      .toArray();

    const formsFiltered = allForms.filter(form => {
      return selectedItems.every(item =>
        item.permissions.some(permission => permission.forms.some(permissionForm => permissionForm.id === form.id))
      );
    });

    return formsFiltered;
  }

  getHousesFiltered() {
    const { selectedItems } = this.state;

    const permissions = Lazy(selectedItems)
      .map(item => item.permissions)
      .flatten()
      .toArray();
    const allHouses = Lazy(permissions)
      .map(permission => permission.houses || [])
      .flatten()
      .uniq('id')
      .toArray();

    const housesFiltered = allHouses.filter(house => {
      return selectedItems.every(item =>
        item.permissions.some(permission => permission.houses.some(permissionHouse => permissionHouse.id === house.id))
      );
    });

    return housesFiltered;
  }

  getRolesFiltered() {
    const { selectedItems } = this.state;

    const permissions = Lazy(selectedItems)
      .map(item => item.permissions)
      .flatten()
      .toArray();

    const allRoles = Lazy(permissions)
      .map(permission => permission.preset)
      .uniq()
      .toArray();

    const rolesFiltered = allRoles.filter(role => {
      return selectedItems.every(item => item.permissions.some(permission => permission.preset === role));
    });

    return rolesFiltered;
  }

  onRemoveRoleClick = (role): void => {
    const { user } = this.props;
    const { selectedItems } = this.state;

    let promises = [];

    selectedItems.forEach(item => {
      const userId = item.id;
      const permissionsFiltered = item.permissions.filter(permission => permission.preset === role);

      permissionsFiltered.forEach(permission => {
        promises.push(deleteDataToPermission(user, userId, permission.id));
      });
    });

    this.setState({
      isLoading: true
    });

    BPromise.all(promises).then(items => {
      //replace old permission
      const nextSelectedItems = items.map(item => {
        const user = [...selectedItems].find(selectedItem =>
          selectedItem.permissions.some(permission => permission.id === item.id)
        );

        const prevPermissionIndex = user.permissions.findIndex(permission => permission.id === item.id);
        user.permissions.splice(prevPermissionIndex, 1);

        return user;
      });

      this.setState({
        selectedItems: [],
        isSelectAllChecked: false
      });
      this.onCloseActionsPopupClick();
      this.setItems();
    });
  };

  onRemoveFormClick = (form): void => {
    const { user } = this.props;
    const formId = form.id;
    const { selectedItems } = this.state;

    let promises = [];

    selectedItems.forEach(item => {
      const userId = item.id;
      const permissionsFiltered = item.permissions.filter(permission =>
        permission.forms.some(permissionForm => permissionForm.id === formId)
      );
      permissionsFiltered.forEach(permission => {
        const formIds = permission.formIds.filter(permissionFormId => permissionFormId !== formId);
        promises.push(putDataToPermission(user, userId, permission.id, { formIds: formIds }));
      });
    });

    this.setState({
      isLoading: true
    });

    BPromise.all(promises).then(items => {
      //replace old permission
      const nextSelectedItems = items.map(item => {
        const user = [...selectedItems].find(selectedItem =>
          selectedItem.permissions.some(permission => permission.id === item.id)
        );

        const prevPermissionIndex = user.permissions.findIndex(permission => permission.id === item.id);
        user.permissions.splice(prevPermissionIndex, 1, item);

        return user;
      });

      this.setState({
        selectedItems: nextSelectedItems
      });
      this.onCloseActionsPopupClick();
      this.setItems();
    });
  };

  onRemoveHouseClick = (house): void => {
    const { user } = this.props;
    const houseId = house.id;
    const { selectedItems } = this.state;

    let promises = [];

    selectedItems.forEach(item => {
      const userId = item.id;
      const permissionsFiltered = item.permissions.filter(permission =>
        permission.houses.some(permissionHouse => permissionHouse.id === houseId)
      );
      permissionsFiltered.forEach(permission => {
        const houseIds = permission.houseIds.filter(permissionHouseId => permissionHouseId !== houseId);
        promises.push(putDataToPermission(user, userId, permission.id, { houseIds: houseIds }));
      });
    });

    this.setState({
      isLoading: true
    });

    BPromise.all(promises).then(items => {
      //replace old permission
      const nextSelectedItems = items.map(item => {
        const user = [...selectedItems].find(selectedItem =>
          selectedItem.permissions.some(permission => permission.id === item.id)
        );

        const prevPermissionIndex = user.permissions.findIndex(permission => permission.id === item.id);
        user.permissions.splice(prevPermissionIndex, 1, item);

        return user;
      });

      this.setState({
        selectedItems: nextSelectedItems
      });

      this.onCloseActionsPopupClick();
      this.setItems();
    });
  };

  renderActionsModal(): React.ReactNode {
    const { user } = this.props;
    const { isListActionsModalOpen, activeListAction } = this.state;

    if (!isListActionsModalOpen) {
      return null;
    }

    switch (activeListAction) {
      case STAFF_LIST_ACTIONS.ASSIGN_FORM:
        return (
          <FormAssignModal
            user={user}
            onSubmitFormsClick={this.onAssignFormsClick}
            onCancelClick={this.onCloseActionsPopupClick}
            isOpen={isListActionsModalOpen}
          />
        );
      case STAFF_LIST_ACTIONS.ASSIGN_HOUSE:
        return (
          <HouseAssignModal
            user={user}
            onSubmitHousesClick={this.onAssignHouseClick}
            onCancelClick={this.onCloseActionsPopupClick}
            isOpen={isListActionsModalOpen}
          />
        );
      case STAFF_LIST_ACTIONS.REMOVE_FORM:
        const forms = this.getFormsFiltered();
        return (
          <FormRemoveModal
            user={user}
            onSubmitFormsClick={this.onRemoveFormClick}
            onCancelClick={this.onCloseActionsPopupClick}
            forms={forms}
            isOpen={isListActionsModalOpen}
          />
        );
      case STAFF_LIST_ACTIONS.REMOVE_HOUSE:
        const houses = this.getHousesFiltered();
        return (
          <HouseRemoveModal
            user={user}
            onSubmitHouseClick={this.onRemoveHouseClick}
            onCancelClick={this.onCloseActionsPopupClick}
            houses={houses}
            isOpen={isListActionsModalOpen}
          />
        );
      case STAFF_LIST_ACTIONS.REMOVE_ROLE:
        const roles = this.getRolesFiltered();
        return (
          <RoleRemoveModal
            user={user}
            onSubmitClick={this.onRemoveRoleClick}
            onCancelClick={this.onCloseActionsPopupClick}
            roles={roles}
            isOpen={isListActionsModalOpen}
          />
        );
    }
  }

  onUserTagChange = (event): void => {
    const value = event.target.value;

    this.setState({
      selectedUserTagId: value
    });
  };

  onUserTagSaveClick = (): void => {
    const userTagId = this.state.selectedUserTagId;
    const user = this.props.user;

    const data = {
      userTagId: userTagId
    };

    this.setState({
      isLoading: true
    });

    if (!userTagId) {
      this.setState({
        isUserTagModalOpen: false,
        selectedUserTagId: ''
      });
      console.error('Can not assign user tag id');
    } else {
      const selectedItems = this.state.selectedItems;
      BPromise.all(
        selectedItems.map(selectedItem => {
          const userId = selectedItem.id;
          return assignUserTag(user, data, userId);
        })
      ).then(items => {
        this.setState({
          isUserTagModalOpen: false,
          selectedUserTagId: '',
          selectedItems: items
        });
        this.setItems();
      });
    }
  };

  renderUserTagAssignModal(): React.ReactNode {
    const { tags, selectedUserTagId, isUserTagModalOpen } = this.state;

    return (
      <UserTagAssignModal
        modalTitle={'Assign User Tag'}
        userTags={tags}
        selectedUserTagId={selectedUserTagId}
        onUserTagChange={this.onUserTagChange}
        onCloseClick={this.closeUserTagAssignModal}
        isOpen={isUserTagModalOpen}
        onSaveClick={this.onUserTagSaveClick}
      />
    );
  }

  openUserTagAssignModal = (): void => {
    this.setState({
      isUserTagModalOpen: true
    });
  };

  closeUserTagAssignModal = () => {
    this.setState({
      isUserTagModalOpen: false
    });
  };

  getUserTags(): Tag[] {
    const { selectedItems, items } = this.state;

    const tags = Lazy(selectedItems)
      .map(selectedItem => {
        const selectedItemId = selectedItem.id;
        const user = items.find(userState => userState.id === selectedItemId);

        if (typeof user !== 'undefined') {
          return user.permissions[0].tags;
        } else {
          return [];
        }
      })
      .filter(tagList => tagList.length > 0)
      .flatten()
      .uniq('id')
      .toArray();

    return tags;
  }

  onUserTagDelete = (): void => {
    const userTagId = this.state.selectedUserTagId;
    const user = this.props.user;

    this.setState({
      isLoading: true
    });

    if (!userTagId) {
      this.setState({
        isUserTagDeleteModalOpen: false,
        selectedUserTagId: ''
      });
      console.error('Can not assign user tag id');
    } else {
      const selectedItems = this.state.selectedItems;

      BPromise.all(
        selectedItems.map(selectedItem => {
          const userId = selectedItem.id;
          return deleteUserTagByUser(user, userId, userTagId);
        })
      ).then(res => {
        //replace deleted tag
        selectedItems.forEach(item => {
          item.permissions.forEach(permission => {
            const tagIdIndex = permission.tagIds.findIndex(tagId => tagId === userTagId);
            const tagIndex = permission.tags.findIndex(tag => tag.id === userTagId);

            permission.tagIds.splice(tagIdIndex, 1);
            permission.tags.splice(tagIndex, 1);
          });
        });

        this.setState({
          isUserTagDeleteModalOpen: false,
          selectedUserTagId: '',
          selectedItems: selectedItems,
          isSelectAllChecked: false
        });

        this.setItems();
      });
    }
  };

  renderUserTagDeleteModal(): React.ReactNode {
    const { selectedUserTagId, isUserTagDeleteModalOpen } = this.state;

    return (
      <UserTagDeleteModal
        modalTitle={'Delete User Tag'}
        userTags={this.getUserTags()}
        selectedUserTagId={selectedUserTagId}
        onUserTagChange={this.onUserTagChange}
        onCloseClick={this.closeUserTagDeleteModal}
        isOpen={isUserTagDeleteModalOpen}
        onDeleteClick={this.onUserTagDelete}
      />
    );
  }

  openUserTagDeleteModal = () => {
    this.setState({
      isUserTagDeleteModalOpen: true
    });
  };

  closeUserTagDeleteModal = () => {
    this.setState({
      isUserTagDeleteModalOpen: false
    });
  };

  renderStaffTable(): React.ReactNode {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isSelectAllChecked,
      currentPage,
      total,
      forms,
      houses,
      tags
    } = this.state;

    const isMultipleActionsAvailable = selectedItems.length > 0;
    const formsFiltered = this.getFormsFiltered();
    const housesFiltered = this.getHousesFiltered();
    const rolesFiltered = this.getRolesFiltered();
    const isEqualFormsExist = formsFiltered.length > 0;
    const isEqualHousesExist = housesFiltered.length > 0;
    const isEqualRolesExist = rolesFiltered.length > 0;

    const actionItems = [
      {
        itemText: 'Create message',
        onItemClick: this.onSendMessageClick,
        isActive: isMultipleActionsAvailable
      },
      {
        itemText: 'Assign tag',
        onItemClick: this.openUserTagAssignModal,
        isActive: selectedItems.length > 0
      },
      {
        itemText: 'Remove tag',
        onItemClick: this.openUserTagDeleteModal,
        isActive: selectedItems.length > 0
      },
      {
        itemText: 'Add role',
        onItemClick: this.onAddRoleClick,
        isActive: this.isOneItemSelected()
      },
      {
        itemText: 'Assign form',
        onItemClick: () => this.onListActionClick(STAFF_LIST_ACTIONS.ASSIGN_FORM),
        isActive: isMultipleActionsAvailable
      },
      {
        itemText: 'Assign house',
        onItemClick: () => this.onListActionClick(STAFF_LIST_ACTIONS.ASSIGN_HOUSE),
        isActive: isMultipleActionsAvailable
      },
      {
        itemText: 'Remove form',
        onItemClick: () => this.onListActionClick(STAFF_LIST_ACTIONS.REMOVE_FORM),
        isActive: isMultipleActionsAvailable && isEqualFormsExist
      },
      {
        itemText: 'Remove house',
        onItemClick: () => this.onListActionClick(STAFF_LIST_ACTIONS.REMOVE_HOUSE),
        isActive: isMultipleActionsAvailable && isEqualHousesExist
      },
      {
        itemText: 'Remove role',
        onItemClick: () => this.onListActionClick(STAFF_LIST_ACTIONS.REMOVE_ROLE),
        isActive: isMultipleActionsAvailable && isEqualRolesExist
      }
    ];

    const filterOptions = {
      role: getSelectOptionForStaffs(),
      permissionFormIds: getSelectOptionForForms(forms),
      permissionHouseIds: getSelectOptionForHouses(houses),
      tag: getSelectOptionForTag(tags)
    };

    return (
      <Grid2
        dataItems={items}
        filters={filters}
        currentPage={currentPage}
        total={total}
        isSelectAllChecked={isSelectAllChecked}
        isDataFiltered={isDataFiltered}
        sortDirection={sortDirection}
        sortColumnsName={sortColumnsName}
        isShowFilter={isShowFilter}
        dataItemsSelected={selectedItems}
        columns={COLUMNS}
        actionItems={actionItems}
        options={filterOptions}
        onItemClick={this.onItemClick}
        onSortClick={this.onTableSortClick}
        onApplyFilterClick={this.onApplyFilterClick}
        onClearFilterClick={this.onClearFilterClick}
        onTableFilterChange={this.onTableFilterChange}
        onTableFilterClick={this.onTableFilterClick}
        setCurrentPageParams={this.setCurrentPageParams}
        onSelectAllClick={this.onSelectAllClick}
        onSelectAllOnPageClick={this.onSelectAllOnPageClick}
        onUnselectAllClick={this.onUnselectAllClick}
      />
    );
  }

  isOneItemSelected(): boolean {
    const selectedStaffs = this.state.selectedItems;
    return selectedStaffs.length === 1;
  }

  onSendMessageClick = (): void => {
    this.setState({
      isMessageModalOpen: true
    });
  };

  onAddRoleClick = (): void => {
    this.setState({
      isAddRoleModalOpen: true,
      editablePermissionId: ''
    });
  };

  onListActionClick = (actionType: STAFF_LIST_ACTIONS): void => {
    this.setState({
      isListActionsModalOpen: true,
      activeListAction: actionType
    });
  };

  renderSummary(): React.ReactNode {
    const isOneStaffSelected = this.isOneItemSelected();

    if (!isOneStaffSelected) {
      return <div className="font-weight-bold">Select a member of staff above</div>;
    }

    const { currentTabIndex } = this.state;
    const tabs = this.getStaffSummaryTabs();

    return (
      <GenericSummary currentTabIndex={currentTabIndex} onTabClick={this.onSummaryTabClick} tabs={MEMBER_OF_STAFF_TABS}>
        {tabs}
      </GenericSummary>
    );
  }

  onSummaryTabClick = (event: any, index: number): void => {
    event.preventDefault();
    this.setState({
      currentTabIndex: index
    });
  };

  getStaffSummaryTabs(): React.ReactNode {
    const { currentTabIndex, selectedItems } = this.state;

    switch (currentTabIndex) {
      case 0:
        return <StaffSummary staff={selectedItems[0]} />;
      case 1:
        return (
          <StaffPermissions
            staff={selectedItems[0]}
            onAddClick={this.onAddRoleClick}
            onEditClick={this.onEditRoleClick}
          />
        );
      default:
        console.error('Staffs.tsx \n Wrong tab index ' + currentTabIndex);
        return null;
    }
  }

  onEditRoleClick = (permission): void => {
    const { user } = this.props;
    const isCoachOrTeacher = permission.preset === ROLE.COACH || permission.preset === ROLE.TEACHER;

    this.setState({
      isLoading: true
    });

    if (isCoachOrTeacher) {
      const filter = {
        where: {
          id: {
            $in: permission.sportIds
          }
        },
        limit: 10,
        order: 'name ASC',
        skip: DEFAULT_SKIP
      };
      getSchoolSports(user, filter).then(sports => {
        this.setState({
          sports: sports,
          isLoading: false,
          editablePermissionId: permission.id,
          isAddRoleModalOpen: true
        });
      });
    } else {
      this.setState({
        isLoading: false,
        editablePermissionId: permission.id,
        isAddRoleModalOpen: true
      });
    }
  };

  render() {
    const { isLoading, isMessageModalOpen, isAddRoleModalOpen } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes = isMessageModalOpen || isAddRoleModalOpen ? 'mt-3 modal-open' : 'mt-3';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderUserTagAssignModal()}
        {this.renderUserTagDeleteModal()}
        {this.renderAddRoleModal()}
        {this.renderGMWizzard()}
        {this.renderActionsModal()}
        <div className="row">
          <div className="col-md-12">
            <ResizablePanel>
              {this.renderStaffTable()}
              {this.renderSummary()}
            </ResizablePanel>
          </div>
        </div>
      </div>
    );
  }
}
