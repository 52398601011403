import * as React from 'react';
import { Component } from 'react';
import * as propz from 'propz';
import * as Moment from 'moment';
import { loadStripe } from '@stripe/stripe-js';
import { AppUser } from 'Src/views/App/App';
import { ClubParticipantInviteMessage, MessageField } from 'Src/models/message';
import { EventInvitationMessage } from 'Src/models/message';
import { CLUB_PARTICIPATION_MESSAGE_INVITATION_STATUS } from 'Src/consts/message';
import { CLUB_MESSAGE_INVITE_STATUS_ALL } from 'Src/consts/club';
import { isNumeric } from 'Src/helpers/common/common';
import { CLUB_PRICING } from 'Src/consts/club';
import { Map } from 'Src/components/Map/Map';
import { Select } from 'Src/components/Select/Select';
import {
  getSelectOptionsForClubPriority,
  getSelectOptionForBooleanQuestion,
  getSelectOptionForClubInvitationEnumQuestion
} from 'Src/helpers/table/select';
import { SelectOption } from 'Src/helpers/table/table';
import { createPayment, sentAnswers } from 'Src/helpers/service/parent/messagesTable';
import { QUESTIONS_TEMPLATE_TYPE } from 'Src/consts/message';
import { getAvailableClubSpaces } from 'Src/helpers/service/parent/messagesTable';
import { convertStringToBoolean } from 'Src/helpers/common/common';
import './ClubInvitationForm.scss';

interface Props {
  user: AppUser;
  message: EventInvitationMessage;
  onBookNowClick: (fields: MessageField[], priority: number) => void;
  closeClubInvitationModal: () => void;
  onAddToWaitingListClick: () => void;
  onAddToBasketClick?: () => void;
}

interface State {
  fields: MessageField[];
  isMapVisible: boolean;
  activePriorityIndex: number;
  selectOptionsForClubPriority: SelectOption[];
  activeSelectIndex: number[];
  errors: Error[];
  isWaitingListInfoVisible: boolean;
  noClubSpacesInfoVisible: boolean;
}

interface Error {
  isError: boolean;
  errorText: string;
}

export class ClubInvitationForm extends Component<Props, State> {
  constructor(props) {
    super(props);
    const { message } = this.props;
    const selectOptionsForClubPriority = getSelectOptionsForClubPriority();
    const selectOptionForBooleanQuestion = getSelectOptionForBooleanQuestion();
    const fields = propz.get(message, ['fields']);

    let fieldsUpdated;
    let activeSelectIndex = [];
    let errors = [];

    const isFieldsExist = typeof fields !== 'undefined';
    if (isFieldsExist) {
      fieldsUpdated = fields.map((field, index) => {
        const isValueExist = typeof field.value !== 'undefined' && field.value !== '';

        switch (true) {
          case field.type === QUESTIONS_TEMPLATE_TYPE.BOOLEAN:
            activeSelectIndex[index] = isValueExist
              ? selectOptionForBooleanQuestion.findIndex(item => item.value === field.value.toString())
              : 0;
            break;

          case field.type === QUESTIONS_TEMPLATE_TYPE.ENUM:
            activeSelectIndex[index] = isValueExist ? field.enumOptions.findIndex(item => item === field.value) : 0;
            break;

          default:
            activeSelectIndex[index] = 0;
            break;
        }

        errors[index] = { isError: false, errorText: '' };

        return {
          ...field,
          value: isValueExist ? field.value : ''
        };
      });
    }

    this.state = {
      fields: isFieldsExist ? fieldsUpdated : [],
      isMapVisible: false,
      activePriorityIndex: 0,
      activeSelectIndex: isFieldsExist ? activeSelectIndex : [],
      errors: isFieldsExist ? errors : [],
      selectOptionsForClubPriority: selectOptionsForClubPriority,
      isWaitingListInfoVisible: false,
      noClubSpacesInfoVisible: true
    };
  }

  pay = () => {
    const { message, user } = this.props;

    let sessionId;
    let publicKey;

    const cancelUrl = document.location.href;
    const successUrl = `${document.location.origin}/paymentSuccess`;

    const data = { successUrl, cancelUrl };

    createPayment(user, message.id, data)
      .then(session => {
        sessionId = session.id;
        publicKey = session.publicKey;
      })
      .then(result => {
        return loadStripe(publicKey);
      })
      .then(stripe => {
        return stripe.redirectToCheckout({
          sessionId: sessionId
        });
      })
      .then(result => {
        if (result.error) {
          alert(result.error.message);
        }
      })
      .catch(err => {
        if (typeof err !== 'undefined') {
          //  TODO: for prod replace on  "contact support"
          const displayMessageText = propz.get(err, ['response', 'data', 'details', 'text']);
          console.log(err);
          alert(`Error: ${displayMessageText}`);
        }
      });
  };

  onSubmit = (isAddToBasket: boolean) => {
    const { fields, errors, selectOptionsForClubPriority, activePriorityIndex } = this.state;
    const { message, user, onAddToBasketClick } = this.props;
    const askToPay = propz.get<Boolean>(message, ['clubData', 'paymentSettings', 'askToPay'], false);
    const isBookingFromWaitingList = propz.get(message, ['clubData', 'isBookingFromWaitingList'], false);
    const invitationStatus = propz.get(message, ['invitationStatus']);

    let submitAbility = true;
    let fieldsUpdated = [...fields];
    const priority = Number(selectOptionsForClubPriority[activePriorityIndex].value);

    const errorsUpdated = errors.map(item => {
      return {
        isError: false,
        errorText: ''
      };
    });

    fields.forEach((field, index) => {
      const isNumericField = isNumeric(field.value);
      const isNumberType = field.type === QUESTIONS_TEMPLATE_TYPE.NUMBER;
      const isBooleanType = field.type === QUESTIONS_TEMPLATE_TYPE.BOOLEAN;
      const isEnumType = field.type === QUESTIONS_TEMPLATE_TYPE.ENUM;
      const isFieldEmpty = field.value === '';

      if (isBooleanType) {
        let value;

        if (isFieldEmpty) {
          const options = getSelectOptionForBooleanQuestion();
          value = options[0].value;
        } else {
          value = propz.get(field, ['value']);
        }

        const booleanValue = convertStringToBoolean(value);
        propz.set(fieldsUpdated[index], ['value'], booleanValue);
      }

      if (isEnumType && isFieldEmpty) {
        const options = getSelectOptionForClubInvitationEnumQuestion(field.enumOptions);
        const value = options[0].value;
        propz.set(fieldsUpdated[index], ['value'], value);
      }

      if (isNumberType && !isNumericField && !isFieldEmpty) {
        errorsUpdated[index].isError = true;
        errorsUpdated[index].errorText = 'please enter a number';
        submitAbility = false;
      }

      const isFieldUpdatedEmpty = fieldsUpdated[index].value === '';

      if (isFieldUpdatedEmpty && field.isRequired) {
        errorsUpdated[index].isError = true;
        errorsUpdated[index].errorText = 'required';
        submitAbility = false;
      }
    });

    switch (true) {
      case submitAbility && askToPay:
        getAvailableClubSpaces(user, message.id).then(countObj => {
          const { availableParticipants } = countObj;

          const isSpacesAvailable = availableParticipants > 0;

          const isInviteStatusPending = invitationStatus === CLUB_MESSAGE_INVITE_STATUS_ALL.PENDING;
          const isInviteStatusPendingAndAvailableForBooking =
            isInviteStatusPending && !isBookingFromWaitingList && isSpacesAvailable;

          const isInviteStatusWaitingListCanBook =
            invitationStatus === CLUB_MESSAGE_INVITE_STATUS_ALL.WAITING_LIST_CAN_BOOK;
          const isInviteStatusWaitingCanBookAndAvailableForBooking =
            isInviteStatusWaitingListCanBook && isBookingFromWaitingList && isSpacesAvailable;

          const isBookingAvailable =
            isInviteStatusPendingAndAvailableForBooking || isInviteStatusWaitingCanBookAndAvailableForBooking;

          const data = { fields: fieldsUpdated, priority };

          sentAnswers(user, message.id, data);

          if (isBookingAvailable) {
            isAddToBasket ? onAddToBasketClick() : this.pay();
          } else {
            isInviteStatusWaitingListCanBook
              ? this.setState({
                  noClubSpacesInfoVisible: false
                })
              : this.setState({
                  isWaitingListInfoVisible: true
                });
          }
        });

        break;

      case submitAbility && !askToPay:
        this.props.onBookNowClick(fieldsUpdated, priority);

        break;

      case !submitAbility:
        this.setState({
          errors: errorsUpdated
        });

        break;
    }
  };

  getAges(): string {
    const { message } = this.props;
    const ages = propz.get(message, ['clubData', 'ages']);
    const isAgesExist = typeof ages !== 'undefined';
    let result = '';

    if (isAgesExist) {
      if (ages.length === 0) {
        result = 'All ages';
      } else {
        result = ages.map(ageItem => (ageItem === 0 ? 'Reception' : 'Y' + ageItem)).join(', ');
      }
    }

    return result;
  }

  onPrioritySelect = (value: string): void => {
    const { selectOptionsForClubPriority } = this.state;
    const nextIndex = selectOptionsForClubPriority.findIndex(priority => Number(priority.value) === Number(value));
    this.setState({
      activePriorityIndex: nextIndex
    });
  };

  renderActionButtons = () => {
    const { message } = this.props;
    const { isWaitingListInfoVisible } = this.state;

    const askToPay = propz.get(message, ['clubData', 'paymentSettings', 'askToPay'], false);

    return (
      <>
        {isWaitingListInfoVisible ? (
          <button onClick={this.props.onAddToWaitingListClick} className="btn btn-primary mr-2">
            Add to waiting list
          </button>
        ) : (
          <>
            <button onClick={() => this.onSubmit(false)} className="btn btn-primary mr-2">
              {askToPay ? 'Book and pay now' : 'Book now'}
            </button>

            {askToPay && (
              <button onClick={() => this.onSubmit(true)} className="btn btn-success">
                Add to basket
              </button>
            )}
          </>
        )}
      </>
    );
  };

  renderInvitationStatusText = () => {
    const { message } = this.props;
    const { noClubSpacesInfoVisible } = this.state;

    const invitationStatus = propz.get(message, ['invitationStatus'], '');

    const isAuthorisingStatus = invitationStatus === CLUB_PARTICIPATION_MESSAGE_INVITATION_STATUS.AUTHORISING;
    const isPrebookedStatus = invitationStatus === CLUB_PARTICIPATION_MESSAGE_INVITATION_STATUS.PREBOOKED;

    switch (true) {
      case !noClubSpacesInfoVisible: {
        return <div className="mb-4 text-primary">Booking is not available. There are no spaces left.</div>;
      }

      case isPrebookedStatus: {
        return (
          <div className="mb-4 text-primary">
            This item has already been added to your basket. Go to the basket to complete checkout or remove items from
            the basket.
          </div>
        );
      }

      case isAuthorisingStatus: {
        return (
          <div className="mb-4 text-primary">
            You have already started the checkout process for this item. Please complete your payment to book your
            space. Or, you can try booking this item once again a bit later if you have already closed the checkout tab
            in your browser.
          </div>
        );
      }

      default:
        return <div></div>;
    }
  };

  render() {
    const { message, user } = this.props;
    const {
      fields,
      activePriorityIndex,
      selectOptionsForClubPriority,
      activeSelectIndex,
      errors,
      isWaitingListInfoVisible,
      noClubSpacesInfoVisible
    } = this.state;

    const askToPay = propz.get(message, ['clubData', 'paymentSettings', 'askToPay'], false);

    const invitationStatus = propz.get(message, ['invitationStatus'], '');

    const isAuthorisingStatus = invitationStatus === CLUB_PARTICIPATION_MESSAGE_INVITATION_STATUS.AUTHORISING;
    const isPrebookedStatus = invitationStatus === CLUB_PARTICIPATION_MESSAGE_INVITATION_STATUS.PREBOOKED;

    const isSameOrderAlreadyExist = isAuthorisingStatus || isPrebookedStatus;

    const isFieldsExist = fields.length > 0;

    const isClubPriorityEnabled = propz.get(user, ['activeSchool', 'isClubPriorityEnabled']);

    const firstName = propz.get(message, ['playerDetailsData', 'firstName']);
    const lastName = propz.get(message, ['playerDetailsData', 'lastName']);
    const fullName = `${firstName} ${lastName}`;
    const deadlineForAnswers = propz.get(message, ['deadlineForAnswers']);
    const deadlineForAnswersFormatted = Moment(deadlineForAnswers).format('DD.MM.YYYY / HH:mm');
    const isDeadlineExist = typeof deadlineForAnswers !== 'undefined';
    const now = new Date();
    const isDeadlineArrived = Number(new Date(deadlineForAnswers)) < Number(now);

    const fieldsUpdated = [...fields];

    const isShowActionButtons = !(isDeadlineExist && isDeadlineArrived) && !isSameOrderAlreadyExist;

    return !isSameOrderAlreadyExist && noClubSpacesInfoVisible ? (
      <>
        {isClubPriorityEnabled && (
          <div>
            <div className={'mClubInvitationFormBold'}>Priority:</div>
            <Select
              onChange={this.onPrioritySelect}
              options={selectOptionsForClubPriority}
              activeResultIndex={activePriorityIndex}
              customClass={'form-control'}
            />
            <div className={'eClubParticipationMessagePriorityCaption'}>
              Please ensure you only select one Priority number per club within the below booking forms, otherwise, the
              school will allocate your child at its discretion.
            </div>
          </div>
        )}

        {isFieldsExist &&
          fieldsUpdated.map((field, index) => (
            <div key={`club_invitation_form_field_${index}`} className="mb-3">
              <div className={field.isRequired ? 'font-weight-bold' : ''}>
                {field.heading} {field.isRequired ? '(required)' : ''}
              </div>

              {(field.type === QUESTIONS_TEMPLATE_TYPE.STRING || field.type === QUESTIONS_TEMPLATE_TYPE.NUMBER) && (
                <input
                  type="text"
                  className="form-control"
                  value={field.value}
                  onChange={event => {
                    propz.set(fieldsUpdated[index], ['value'], event.target.value);
                    this.setState({ fields: fieldsUpdated });
                  }}
                />
              )}
              {field.type === QUESTIONS_TEMPLATE_TYPE.BOOLEAN && (
                <Select
                  onChange={value => {
                    const options = getSelectOptionForBooleanQuestion();
                    const nextIndex = options.findIndex(option => option.value === value);
                    const activeSelectIndexUpdated = [...activeSelectIndex];
                    activeSelectIndexUpdated[index] = nextIndex;
                    propz.set(fieldsUpdated[index], ['value'], value);

                    this.setState({
                      activeSelectIndex: activeSelectIndexUpdated
                    });
                  }}
                  options={getSelectOptionForBooleanQuestion()}
                  activeResultIndex={activeSelectIndex[index]}
                  customClass={'form-control'}
                />
              )}
              {field.type === QUESTIONS_TEMPLATE_TYPE.ENUM && (
                <Select
                  onChange={value => {
                    const options = getSelectOptionForClubInvitationEnumQuestion(field.enumOptions);
                    const nextIndex = options.findIndex(option => option.value === value);
                    const activeSelectIndexUpdated = [...activeSelectIndex];
                    activeSelectIndexUpdated[index] = nextIndex;
                    propz.set(fieldsUpdated[index], ['value'], value);

                    this.setState({
                      activeSelectIndex: activeSelectIndexUpdated
                    });
                  }}
                  options={getSelectOptionForClubInvitationEnumQuestion(field.enumOptions)}
                  activeResultIndex={activeSelectIndex[index]}
                  customClass={'form-control'}
                />
              )}
              {errors[index].isError && <div className="alert alert-danger mt-3">{errors[index].errorText}</div>}
            </div>
          ))}

        {isDeadlineExist && isDeadlineArrived ? (
          <div className="eClubInvitationFormRow">
            <span className="mClubInvitationFormBold">Unfortunately, booking for this activity has now closed.</span>
          </div>
        ) : (
          <div className="eClubInvitationFormRow">
            <span className="mClubInvitationFormBold">
              {`To reserve a place for ${fullName}, click the 'Book now' button below`}
            </span>
          </div>
        )}

        {isDeadlineExist && (
          <div className="eClubInvitationFormRow">{`Deadline for answers: ${deadlineForAnswersFormatted}`}</div>
        )}

        {isWaitingListInfoVisible && (
          <div className="alert alert-danger mt-3">
            {
              'All spaces have been booked already, so you will be added to the waiting list. Once there is a space you will be asked to make a payment for being added to the club.'
            }
          </div>
        )}

        <button onClick={this.props.closeClubInvitationModal} className="btn btn-secondary mr-2">
          Cancel
        </button>

        {isShowActionButtons && this.renderActionButtons()}

        {askToPay && (
          <div className="mt-2">
            <p className="text-primary">
              When you proceed to the checkout you will be forwarded to the Stripe secure payment page. Stripe is a
              payment platform that we are partnered with for processing payments. Paying via Stripe means you agree
              with their T&C's and their Privacy Policy, these can be found on their website.
            </p>

            <p className="text-primary">
              Squad In Touch does not have access to or store any payment information associated with you; when you
              agree to save your card details on Stripe's secure payment page you are agreeing to Stripe holding your
              details, not Squad In Touch.
            </p>

            <p className="font-weight-bold">
              It should be noted that as part of your booking a booking fee is included, therefore if for any reason the
              school issues you a refund the booking fee is non-refundable, we thank you for your understanding.
            </p>
          </div>
        )}
      </>
    ) : (
      <>
        {this.renderInvitationStatusText()}

        <button onClick={this.props.closeClubInvitationModal} className="btn btn-secondary mr-2">
          Cancel
        </button>
      </>
    );
  }
}
