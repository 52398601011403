import * as React from 'react';
import * as propz from 'propz';
import { FunctionComponent } from 'react';

interface Props {
  invite: any;
}

const ROWS = [{ title: 'id', field: 'id' }];

export const InviteInboxSummary: FunctionComponent<Props> = props => {
  const rows = ROWS.map((row, index) => {
    let contentCell: any;
    const field = row.field;

    const invite = props.invite;

    switch (field) {
      default:
        contentCell = propz.get(invite, row.field.split('.'), '');
    }

    return (
      <tr key={`invite_summary_${index}`}>
        <td>{row.title}</td>
        <td>{contentCell}</td>
      </tr>
    );
  });

  return (
    <table className={'table table-sm mPre mVerticalAlignMiddle'}>
      <tbody>{rows}</tbody>
    </table>
  );
};

InviteInboxSummary.displayName = 'InviteInboxSummary';
