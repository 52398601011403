import * as React from 'react';
import { Component } from 'react';
import * as propz from 'propz';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { AppUser } from 'Src/views/App/App';
import './TournamentView.css';
import { History, Location } from 'history';
import DatePicker from 'react-datepicker';
import { parse } from 'query-string';
import { Loader } from 'Src/components/Loader/Loader';
import { createTournament, getTournament, updateTournament } from 'Src/helpers/service/adminSU/tournamentsTable';
import { Tournament } from 'Src/models/tournament';
import {
  DEFAULT_EVENT_IMPORT_PROPERTIES,
  TOURNAMENT_PAYMENT_TYPE,
  TOURNAMENT_PRICE_TYPE,
  TOURNAMENT_SUBTYPE,
  TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING,
  TOURNAMENT_TYPE,
  TOURNAMENT_TYPE_SERVER_TO_CLIENT_MAPPING
} from 'Src/consts/tournament';
import { GENDER, MODE_TYPE, YES_NO_OPTIONS } from 'Src/consts/common';
import { Autocomplete } from 'Src/components/Autocomplete/Autocomplete';
import {
  getName,
  getNameByPlaceOrPostcode,
  searchFunctionPaymentAccounts,
  searchFunctionSports
} from 'Src/helpers/autocomplete/autocomplete';
import { Multiselect } from 'Src/components/Multiselect/Multiselect';
import * as Moment from 'moment';
import { AGE_GROUPS } from 'Src/consts/school';
import { uploadImage } from 'Src/helpers/service/image';
import { CLUB_CURRENCY } from 'Src/consts/club';
import { CurrencyInput } from 'Src/components/CurrencyInput/CurrencyInput';
import { CURRENCY_TYPE } from 'Src/types/common';
import { TournamentCustomSectionForm } from './TournamentCustomSectionForm/TournamentCustomSectionForm';
import { uploadFile } from 'Src/helpers/service/file';
import { TournamentNationalGoverningBodiesForm } from './TournamentNationalGoverningBodiesForm/TournamentNationalGoverningBodiesForm';
import { Map } from 'Src/components/Map/Map';
import { Point } from 'Src/models/venue';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { createVenue, deleteVenue } from 'Src/helpers/service/admin/venues';
import { VenueForm } from 'Src/views/GenericView/AdminView/School/Venues/VenueForm/VenueForm';
import { HTML_FIRST_PART_PAGE_CODE, HTML_LAST_PART_PAGE_CODE } from 'Src/components/HTMLEditorQuill/HTMLEditorConst';
import { HTMLEditorQuillHide } from 'Src/components/HTMLEditorQuill/HTMLEditorQuillHide';
import { getBooleanForYesOrNo, getYesOrNoForBoolean } from 'Src/helpers/tournament/tournament';
import { getGenderOptionsBySport } from '../../../../../helpers/select/select';
import {
  convertPlaceToPostcodeWithPlaceName,
  getDefaultPoint,
  getDefaultTournamentPostcode,
  getHomeSchoolPostCode,
  getPostcodes,
  getVenueTypeByPostcode,
  isPlace
} from '../../../../../helpers/venue/venue';
import { FIRST_PAGE } from '../../../../../consts/table';
import { LabelWithQuestionIcon } from './../../../../../components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import { Switch } from './../../../../../components/Switch/Switch';

const TYPE_OF_TOURNAMENT_PAYMENT_TYPE_OPTIONS = [{ text: 'Fixed price', value: 'FIXED_PRICE' }];
const TYPE_OF_TOURNAMENT_PRICE_TYPE_OPTIONS = [
  { text: 'Free', value: 'FREE' },
  { text: 'Paid', value: 'PAID' }
];

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

interface State {
  tournament: Tournament;
  picUrl: string;
  isImageError: boolean;
  isLoading: boolean;
  announcements: any[];
  nationalGoverningBodies: any[];
  point: Point;
  venue: any;
  isDeleteVenueModalOpen: boolean;
  isCreateVenueFormOpen: boolean;

  isPaymentSettingsVisible: boolean;
  isAdvancedSettingsVisible: boolean;
}

const today = new Date();
today.setHours(0, 0, 0, 0);

const tournamentValidationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  ageGroups: Yup.mixed()
    .test('ageGroup-check', 'Only one age group can be selected for this tournament type', function(value) {
      const { tournamentType } = this.parent;
      if (tournamentType !== TOURNAMENT_TYPE.INDIVIDUAL_TOURNAMENT && Array.isArray(value) && value.length > 1) {
        return false;
      }
      return true;
    })
    .required('Required'),
  startTime: Yup.date().required('Required'),
  endTime: Yup.date()
    .required('Required')
    .when('startTime', (startTime, schema) => {
      return startTime ? schema.min(startTime, 'End time must be after start time') : schema;
    }),
  sportId: Yup.string().required('Required'),
  entriesOpen: Yup.date()
    .nullable()
    .notRequired(),
  deadlineForEntries: Yup.date()
    .nullable()
    .notRequired()
    .when('entriesOpen', (entriesOpen, schema) =>
      entriesOpen ? schema.min(entriesOpen, 'Deadline for entries must be after entries open') : schema
    ),
  scoring: Yup.string()
    .matches(/^[0-9\ ,]*$/, 'Should contain only number, space and comma')
    .required('Required'),
  price: Yup.number().required('Required'),
  resultImportPropertiesFieldsPlayerNumber: Yup.string().required('Required'),
  resultImportPropertiesFieldsTeamCode: Yup.string().required('Required'),
  resultImportPropertiesFieldsResult: Yup.string().required('Required'),
  resultImportPropertiesFieldsDelimiter: Yup.string().required('Required'),
  maxPlayersPerTeam: Yup.number()
    .integer('Must be an integer')
    .min(0, 'Must be at least 0')
    .nullable()
});

export class TournamentView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { user } = this.props;

    this.state = {
      tournament: undefined,
      isLoading: false,
      picUrl: '',
      isImageError: false,
      announcements: [],
      nationalGoverningBodies: [],
      point: getDefaultPoint(user),
      venue: undefined,

      isDeleteVenueModalOpen: false,
      isCreateVenueFormOpen: false,

      isPaymentSettingsVisible: false,
      isAdvancedSettingsVisible: false
    };
  }

  componentDidMount() {
    if (this.isTournamentExist()) {
      this.setState({
        isLoading: true
      });
      this.setAdditionalItems();
    }
  }

  getTournamentId(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    const tournamentId = search.tournament;

    return tournamentId;
  }

  getMode(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    const mode = propz.get(search, ['mode'], '');

    return mode;
  }

  setAdditionalItems() {
    const { user } = this.props;

    const tournamentId = this.getTournamentId();

    return getTournament(user, tournamentId).then(tournament => {
      this.setState({
        tournament: tournament,
        picUrl: propz.get(tournament, ['photos', '0', 'picUrl'], ''), //   since the tournament has only one avatar
        announcements: propz.get(tournament, ['announcements'], []),
        nationalGoverningBodies: propz.get(tournament, ['nationalGoverningBodies'], []),
        point:
          typeof tournament.venue !== 'undefined' &&
          propz.get(tournament, ['venue', 'point', 'coordinates'], []).length !== 0
            ? propz.get(tournament, ['venue', 'point'])
            : getDefaultPoint(user),
        venue: getDefaultTournamentPostcode(tournament, user.activeSchool),
        isLoading: false
      });
      return true;
    });
  }

  isTournamentExist(): boolean {
    const { history } = this.props;
    const search = parse(history.location.search);
    const tournamentId = search.tournament;

    return typeof tournamentId !== 'undefined';
  }

  onSaveButtonClick = (dataCopy): void => {
    const { user, history } = this.props;
    const isTournamentExists = this.isTournamentExist();
    const tournamentId = this.getTournamentId();
    const mode = this.getMode();

    switch (true) {
      case isTournamentExists && mode === 'edit':
        updateTournament(user, dataCopy, tournamentId)
          .then(tournament => {
            this.goBackEditTournament(tournament.id);
          })
          .catch(error => {
            console.error(error.response.data.details.text);
          });
        break;

      case isTournamentExists && mode === 'copy':
        createTournament(user, dataCopy)
          .then(tournament => {
            this.goBackCreateTournament(tournament.id);
          })
          .catch(error => {
            console.error(error.response.data.details.text);
          });
        break;
      case !isTournamentExists:
        createTournament(user, dataCopy)
          .then(tournament => {
            this.goBackCreateTournament(tournament.id);
          })
          .catch(error => {
            console.error(error.response.data.details.text);
          });
        break;
    }
  };

  onCancelButtonClick = (): void => {
    const tournamentId = this.getTournamentId();

    const { history, location } = this.props;
    const { state } = location;
    const { prevPath } = state as any;

    const filters = propz.get(history, ['location', 'state', 'filters']);
    const currentTournamentPage = propz.get(history, ['location', 'state', 'currentTournamentPage'], FIRST_PAGE);

    history.push({
      pathname: prevPath,
      search: `page=${currentTournamentPage}`,
      state: {
        tournamentId,
        filters
      }
    });
  };

  goBackCreateTournament = (tournamentId): void => {
    const { history, location } = this.props;
    const { state } = location;
    const { prevPath } = state as any;

    const lastTournamentPage = propz.get(history, ['location', 'state', 'lastTournamentPage'], FIRST_PAGE);

    history.push({
      pathname: prevPath,
      search: `page=${lastTournamentPage}`,
      state: { tournamentId }
    });
  };

  goBackEditTournament = (tournamentId): void => {
    const { history, location } = this.props;
    const { state } = location;
    const { prevPath } = state as any;

    const currentTournamentPage = propz.get(history, ['location', 'state', 'currentTournamentPage'], FIRST_PAGE);
    const selectedFilters = propz.get(history, ['location', 'state', 'filters'], undefined);

    history.push({
      pathname: prevPath,
      search: `page=${currentTournamentPage}`,
      state: { tournamentId, filters: selectedFilters }
    });
  };

  getAgeGroup() {
    const user = this.props.user;
    const activeSchool = user.activeSchool;
    const ageGroupsNaming = activeSchool.ageGroupsNaming;
    const ageGroups = propz.get(AGE_GROUPS, [ageGroupsNaming]);
    return ageGroups.map((ageGroup, index) => {
      return {
        value: index,
        text: ageGroup
      };
    });
  }

  getSports = (text: string) => {
    const user = this.props.user;
    return searchFunctionSports(user, text);
  };

  onImageSelected = event => {
    const image = event.target.files[0];

    uploadImage(this.props.user, image)
      .then(response => {
        const picUrl = `${window.apiImg}/images/${response.key}`;
        this.setState({
          picUrl: picUrl,
          isImageError: false
        });
      })
      .catch(error => {
        console.error(error);
        this.setState({ isImageError: true });
      });
  };

  onRemoveImageClick = (): void => {
    this.setState({
      picUrl: ''
    });
  };

  getScoring(): string {
    const { tournament } = this.state;
    const isTournamentExists = this.isTournamentExist();
    if (isTournamentExists) {
      const scoringArray = propz.get(tournament, ['scoring'], []);
      return scoringArray.length > 0 ? scoringArray.map(scoring => scoring.score).join(', ') : '0';
    } else {
      return '0';
    }
  }

  togglePaymentSettingsVisibility = () => {
    this.setState(prevState => ({
      isPaymentSettingsVisible: !prevState.isPaymentSettingsVisible
    }));
  };

  toggleAdvancedSettingsVisibility = () => {
    this.setState(prevState => ({
      isAdvancedSettingsVisible: !prevState.isAdvancedSettingsVisible
    }));
  };

  getPaymentAccounts = () => {
    const user = this.props.user;

    return searchFunctionPaymentAccounts(user);
  };

  onSubmit = values => {
    const { venue, picUrl } = this.state;
    const { user } = this.props;
    const { activeSchool } = user;
    const startDate = values.startTime;
    const endDate = values.endTime;
    const startDateUTC = new Date(startDate);
    const finishDateUTC = new Date(endDate);
    const dataCopy = {
      name: values.name,
      description: values.description,
      sportId: values.sportId,
      startTime: startDateUTC,
      endTime: finishDateUTC,
      ages: values.ageGroups.map(age => Number(age)),
      isAgeGroupsOnWebSiteVisible: getBooleanForYesOrNo(values.isAgeGroupsOnWebSiteVisible),
      type: values.tournamentType,
      subType: values.tournamentSubType,
      photos: [picUrl.length > 0 ? { picUrl: picUrl } : {}], // front1 as needed:  [ {} ]
      gender: values.gender,
      isMixedTeamsAvailable: getBooleanForYesOrNo(values.isMixedTeamsAvailable),
      isSchoolCanSubmitResult: getBooleanForYesOrNo(values.isSchoolCanSubmitResult),
      isShowTwitter: getBooleanForYesOrNo(values.isShowTwitter),
      organisers: values.organisers,
      organisersEmail: values.organisersEmail,
      scoring: [],
      price: {
        priceType: values.priceType,
        paymentType: values.paymentType,
        price: values.values
      },
      paymentSettings: {
        paymentAccountId: values.paymentAccountId
      },
      entriesOpen: values.entriesOpen ? new Date(values.entriesOpen) : null,
      deadlineForEntries: values.deadlineForEntries ? new Date(values.deadlineForEntries) : null,
      isWhenSpacesAreFull: getBooleanForYesOrNo(values.isWhenSpacesAreFull),
      tournamentStream: {
        isEnabled: getBooleanForYesOrNo(values.tournamentStream)
      },
      resultImportProperties: {
        fields: {
          playerNumber: values.resultImportPropertiesFieldsPlayerNumber,
          teamCode: values.resultImportPropertiesFieldsTeamCode,
          result: values.resultImportPropertiesFieldsResult,
          delimiter: values.resultImportPropertiesFieldsDelimiter
        },
        resultMask: values.resultImportPropertiesResultMask
      },
      announcements: this.state.announcements.map(announcement => {
        announcement.body = `${HTML_FIRST_PART_PAGE_CODE} ${announcement.body} ${HTML_LAST_PART_PAGE_CODE}`;
        return announcement;
      }),
      nationalGoverningBodies: this.state.nationalGoverningBodies,
      venue: venue,
      maxPlayersPerTeam: values.maxPlayersPerTeam !== '' ? values.maxPlayersPerTeam : 0
    };
    if (!values.scoring || values.scoring.trim() === '') {
      dataCopy.scoring = ['0'];
    } else {
      const scoring = values.scoring.split(',').map(score => score.trim());
      if (Array.isArray(scoring) && scoring.length > 0) {
        dataCopy.scoring = scoring.map(score => {
          return { score: Number(score) };
        });
      }
    }

    if (values.entriesOpen) {
      dataCopy.entriesOpen = new Date(values.entriesOpen);
    } else {
      dataCopy.entriesOpen = null;
    }

    if (values.deadlineForEntries) {
      dataCopy.deadlineForEntries = new Date(values.deadlineForEntries);
    } else {
      dataCopy.deadlineForEntries = null;
    }

    if (values.organisersEmail && values.organisersEmail.trim() !== '') {
      dataCopy.organisersEmail = values.organisersEmail;
    } else {
      dataCopy.organisersEmail = null;
    }

    if (values.priceType === TOURNAMENT_PRICE_TYPE.FREE) {
      delete dataCopy.paymentSettings.paymentAccountId;
    }

    switch (true) {
      case typeof venue !== 'undefined' && typeof venue.point !== 'undefined' && venue.point.coordinates.length > 0:
        if (typeof venue.postcodeId === 'undefined') {
          dataCopy.venue.postcodeId = venue.id;
        }
        dataCopy.venue.venueType = getVenueTypeByPostcode(venue, activeSchool);
        break;
      case typeof venue !== 'undefined' && typeof venue.point === 'undefined':
        dataCopy.venue.venueType = getVenueTypeByPostcode(venue, activeSchool);
        break;
      default:
        delete dataCopy.venue;
    }
    this.onSaveButtonClick(dataCopy);
  };

  renderTournamentSubTypeOptions(tournamentType) {
    switch (tournamentType) {
      case TOURNAMENT_TYPE.TEAM_TOURNAMENT:
        return (
          <>
            <option key="subtype_option_football" value={TOURNAMENT_SUBTYPE.FOOTBALL}>
              {TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING.FOOTBALL}
            </option>
            <option key="subtype_option_cricket" value={TOURNAMENT_SUBTYPE.CRICKET}>
              {TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING.CRICKET}
            </option>
            <option key="subtype_option_sailing" value={TOURNAMENT_SUBTYPE.SAILING}>
              {TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING.SAILING}
            </option>
            <option key="subtype_option_rugby" value={TOURNAMENT_SUBTYPE.RUGBY}>
              {TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING.RUGBY}
            </option>
            <option key="subtype_option_netball" value={TOURNAMENT_SUBTYPE.NETBALL}>
              {TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING.NETBALL}
            </option>
            <option key="subtype_option_badminton" value={TOURNAMENT_SUBTYPE.BADMINTON}>
              {TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING.BADMINTON}
            </option>
            <option key="subtype_option_swimming" value={TOURNAMENT_SUBTYPE.WINNERS_ONLY}>
              {TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING.WINNERS_ONLY}
            </option>
          </>
        );
      case TOURNAMENT_TYPE.INDIVIDUAL_TOURNAMENT:
        return (
          <>
            <option key="subtype_option_swimming" value={TOURNAMENT_SUBTYPE.ATHLETIC_SWIMMING}>
              {TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING.ATHLETIC_SWIMMING}
            </option>
            <option key="subtype_option_regular_individual" value={TOURNAMENT_SUBTYPE.REGULAR_INDIVIDUAL}>
              {TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING.REGULAR_INDIVIDUAL}
            </option>
            <option key="subtype_option_triathlon" value={TOURNAMENT_SUBTYPE.TRIATHLON}>
              {TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING.TRIATHLON}
            </option>
            <option key="subtype_option_national_triathlon" value={TOURNAMENT_SUBTYPE.NATIONAL_TRIATHLON}>
              {TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING.NATIONAL_TRIATHLON}
            </option>
            <option key="subtype_option_cross_country" value={TOURNAMENT_SUBTYPE.CROSS_COUNTRY}>
              {TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING.CROSS_COUNTRY}
            </option>
            <option key="subtype_option_national_cross_country" value={TOURNAMENT_SUBTYPE.NATIONAL_CROSS_COUNTRY}>
              {TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING.NATIONAL_CROSS_COUNTRY}
            </option>
            <option key="subtype_option_pseudo_two_level" value={TOURNAMENT_SUBTYPE.PSEUDO_TWO_LEVEL}>
              {TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING.PSEUDO_TWO_LEVEL}
            </option>
            <option key="subtype_option_pseudo_two_level_runtime" value={TOURNAMENT_SUBTYPE.PSEUDO_TWO_LEVEL_RUNTIME}>
              {TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING.PSEUDO_TWO_LEVEL_RUNTIME}
            </option>
          </>
        );
      case TOURNAMENT_TYPE.SINGLE_DOUBLE_TOURNAMENT:
        return (
          <>
            <option key="subtype_option_badminton" value={TOURNAMENT_SUBTYPE.BADMINTON}>
              {TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING.BADMINTON}
            </option>
            <option key="subtype_option_fencing" value={TOURNAMENT_SUBTYPE.FENCING}>
              {TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING.FENCING}
            </option>
            <option key="subtype_option_tennis" value={TOURNAMENT_SUBTYPE.TENNIS}>
              {TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING.TENNIS}
            </option>
          </>
        );
      case TOURNAMENT_TYPE.MINI_TEAM_TOURNAMENT:
        return (
          <option key="subtype_option_badminton" value={TOURNAMENT_SUBTYPE.BADMINTON_MT}>
            {TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING.BADMINTON_MT}
          </option>
        );
    }
  }

  onCloseFormFieldListAnnouncementClick = index => {
    const { announcements } = this.state;
    const currentAnnouncements = announcements.slice();
    currentAnnouncements.splice(index, 1);

    this.setState({
      announcements: currentAnnouncements
    });
  };

  onCloseFormFieldListNationalGoverningBodyClick = index => {
    const { nationalGoverningBodies } = this.state;
    const currentNationalGoverningBodies = nationalGoverningBodies.slice();
    currentNationalGoverningBodies.splice(index, 1);

    this.setState({
      nationalGoverningBodies: currentNationalGoverningBodies
    });
  };

  onGlobalAnnouncementsFormChange = (event, index, field) => {
    const { announcements } = this.state;
    let changeAnnouncements = announcements.slice();
    switch (true) {
      case field === 'date':
        const date = event; //  for understanding
        changeAnnouncements[index].date = date.toISOString();
        break;
      case field === 'isDateVisible':
        changeAnnouncements[index].isDateVisible = event.target.checked;
        break;
      case field === 'removeImageButton':
        changeAnnouncements[index].attachments.images[0].url = '';
        break;
      case field === 'body':
        const text = event;
        changeAnnouncements[index].body = text;
        break;
      case field === 'title':
        changeAnnouncements[index].title = event.target.value;
        break;
      case field === 'uploadImage':
        const url = event;
        changeAnnouncements[index].attachments.images[0].url = url;
        break;
      case field === 'removeFileButton':
        const fileIndex = event;
        changeAnnouncements[index].attachments.files.splice(fileIndex, 1);
        break;
      case field === 'uploadFile':
        const { user } = this.props;
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        const fileName = event.target.files[0].name;
        uploadFile(user, formData).then(data => {
          const key = data.key;
          changeAnnouncements[index].attachments.files.push({
            name: fileName,
            url: key
          });
        });
        break;
    }

    this.setState({
      announcements: changeAnnouncements
    });
  };

  onGlobalNationalGoverningBodiesFormChange = (event, index, field) => {
    const { nationalGoverningBodies } = this.state;
    let changeNationalGoverningBodies = nationalGoverningBodies.slice();
    switch (true) {
      case field === 'removeImageButton':
        changeNationalGoverningBodies[index].picUrl = '';
        break;
      case field === 'link':
        changeNationalGoverningBodies[index].link = event.target.value;
        break;
      case field === 'name':
        changeNationalGoverningBodies[index].name = event.target.value;
        break;
      case field === 'uploadImage':
        const url = event;
        changeNationalGoverningBodies[index].picUrl = url;
        break;
    }

    this.setState({
      nationalGoverningBodies: changeNationalGoverningBodies
    });
  };

  renderFormFieldListAnnouncements() {
    const { announcements } = this.state;
    const { user } = this.props;
    let announcementsItems;
    announcementsItems = announcements.map((announcement, index) => {
      return (
        <div key={`announcement_${index}`}>
          <TournamentCustomSectionForm
            announcement={announcement}
            onGlobalAnnouncementsFormChange={this.onGlobalAnnouncementsFormChange}
            user={user}
            onCloseClick={this.onCloseFormFieldListAnnouncementClick}
            announcementIndex={index}
          />
        </div>
      );
    });
    return announcementsItems;
  }

  onClickAddCustomSectionForm = (): void => {
    const { announcements } = this.state;
    const newAnnouncements = announcements.slice();
    newAnnouncements.push({
      attachments: {
        files: [],
        images: [
          {
            url: ''
          }
        ]
      },
      body: '',
      date: new Date(),
      isDateVisible: false,
      title: ''
    });

    this.setState({
      announcements: newAnnouncements
    });
  };

  renderFormFieldListGoverningBodies() {
    const { nationalGoverningBodies } = this.state;
    const { user } = this.props;
    let nationalGoverningBodiesItems;
    nationalGoverningBodiesItems = nationalGoverningBodies.map((nationalGoverningBody, index) => {
      return (
        <div key={`nationalGoverningBody_${index}`}>
          <TournamentNationalGoverningBodiesForm
            nationalGoverningBody={nationalGoverningBody}
            onGlobalNationalGoverningBodiesFormChange={this.onGlobalNationalGoverningBodiesFormChange}
            user={user}
            onCloseClick={this.onCloseFormFieldListNationalGoverningBodyClick}
            nationalGoverningBodyIndex={index}
          />
        </div>
      );
    });
    return nationalGoverningBodiesItems;
  }

  onClickAddGoverningBodiesForm = (): void => {
    const { nationalGoverningBodies } = this.state;
    const newNationalGoverningBodies = nationalGoverningBodies.slice();
    newNationalGoverningBodies.push({
      link: '',
      picUrl: '',
      name: ''
    });

    this.setState({
      nationalGoverningBodies: newNationalGoverningBodies
    });
  };

  getNewPoint = pointNew => {
    this.setState({
      point: pointNew
    });
  };

  onCancelCreateVenueFormOpenClick = (): void => {
    this.setState({
      isCreateVenueFormOpen: false
    });
  };

  isDeleteButton() {
    const { venue } = this.state;
    const venueName = propz.get(venue, ['name'], undefined);
    return venueName !== undefined;
  }

  deleteVenueModalOpen = (): void => {
    this.setState({
      isDeleteVenueModalOpen: true
    });
  };

  createVenueFormOpen = (): void => {
    this.setState({
      isCreateVenueFormOpen: true
    });
  };

  onDeleteVenueClick = venue => {
    const { user } = this.props;
    const { activeSchool } = user;
    const { tournament, point } = this.state;
    deleteVenue(user, venue.placeId).then(res => {
      let oldVenue;
      let oldPoint;

      switch (true) {
        case typeof tournament === 'undefined':
          oldVenue = getHomeSchoolPostCode(activeSchool);
          oldPoint = getDefaultPoint(user);
          break;
        case typeof tournament.venue !== 'undefined' &&
          typeof tournament.venue.postcodeId !== 'undefined' &&
          venue.id === tournament.venue.postcodeId:
          oldVenue = getHomeSchoolPostCode(activeSchool);
          oldPoint = getDefaultPoint(user);
          break;
        default:
          oldVenue = getDefaultTournamentPostcode(tournament, user.activeSchool);
          oldPoint = point;
          break;
      }
      this.setState({
        isDeleteVenueModalOpen: false,
        venue: oldVenue,
        point: oldPoint
      });
    });
  };

  deleteVenueModalClose = (): void => {
    this.setState({
      isDeleteVenueModalOpen: false
    });
  };

  onSubmitVenueForm = (data): void => {
    const { user } = this.props;

    createVenue(user, data).then(venue => {
      const convertPlace = convertPlaceToPostcodeWithPlaceName(venue);

      this.setState({
        venue: convertPlace,
        point: convertPlace.point,
        isCreateVenueFormOpen: false
      });
    });
  };

  renderDeleteVenueModal() {
    const { isDeleteVenueModalOpen, venue } = this.state;

    const venueName = propz.get(venue, ['name'], '');
    return (
      <SimpleModal
        isOpen={isDeleteVenueModalOpen}
        title={'Delete venue'}
        body={`Are you sure you want to remove venue ${venueName}`}
        buttonText={'Delete'}
        onButtonClick={() => this.onDeleteVenueClick(venue)}
        onCloseClick={this.deleteVenueModalClose}
      />
    );
  }

  renderCreateVenueForm() {
    const { user } = this.props;
    return (
      <SimpleModal isOpen={this.state.isCreateVenueFormOpen} title={'New venue'}>
        <VenueForm user={user} onCancel={this.onCancelCreateVenueFormOpenClick} onSubmit={this.onSubmitVenueForm} />
      </SimpleModal>
    );
  }

  render() {
    const {
      isImageError,
      tournament,
      isLoading,
      picUrl,
      isCreateVenueFormOpen,
      isDeleteVenueModalOpen,
      venue
    } = this.state;
    const { user } = this.props;
    const isTournamentExists = this.isTournamentExist();
    const mode = this.getMode();
    const positiveButtonTitle = isTournamentExists ? 'Save tournament' : 'Create tournament';
    const imgInputClassName = isImageError ? 'form-control-file is-invalid' : 'form-control-file';

    const modeCopy = mode === MODE_TYPE.COPY;
    const point = this.state.point;
    const mainTitle = isTournamentExists ? 'Edit tournament' : 'Add a new tournament';
    const isPicUrlExists = typeof picUrl !== 'undefined' && picUrl.length > 0;
    const isPaymentsEnabled = propz.get(this.props, ['user', 'activeSchool', 'isPaymentsEnabled'], false);
    const defaultPaymentAccountId = propz.get(tournament, ['paymentSettings', 'paymentAccountId'], undefined);
    const defaultPaymentAccountName = propz.get(tournament, ['paymentSettings', 'paymentAccountName'], undefined);
    const defaultPaymentsAccount =
      typeof defaultPaymentAccountId !== 'undefined' && typeof defaultPaymentAccountName !== 'undefined'
        ? { id: defaultPaymentAccountId, name: defaultPaymentAccountName }
        : undefined;
    const entriesOpen =
      typeof tournament !== 'undefined' &&
      typeof tournament.entriesOpen !== 'undefined' &&
      tournament.entriesOpen !== null
        ? new Date(tournament.entriesOpen)
        : '';
    const deadlineForEntries =
      typeof tournament !== 'undefined' &&
      typeof tournament.deadlineForEntries !== 'undefined' &&
      tournament.deadlineForEntries !== null
        ? new Date(tournament.deadlineForEntries)
        : '';

    const hintTextResultMask =
      'h:  -hours\nm:  -minutes or meters\ns.  -seconds\nc  -split second or centimeters\nseparator : and .\n\n' +
      '(for example \nh:mm:ss.ccc   -  for time\nor\nm.c  -   for distances)';

    if (isLoading) {
      return <Loader />;
    }

    const tournamentInitial = {
      name: isTournamentExists ? propz.get(tournament, ['name'], '') : '',
      description: isTournamentExists ? propz.get(tournament, ['description'], '') : '',
      ageGroups: isTournamentExists ? propz.get(tournament, ['ages'], []) : '',
      gender: isTournamentExists ? propz.get(tournament, ['gender'], '') : '',
      startTime: isTournamentExists && !modeCopy ? new Date(propz.get(tournament, ['startTime'], '')) : '',
      endTime: isTournamentExists && !modeCopy ? new Date(propz.get(tournament, ['endTime'], '')) : '',
      isAgeGroupsOnWebSiteVisible: getYesOrNoForBoolean(propz.get(tournament, ['isAgeGroupsOnWebSiteVisible'], false)),
      isMixedTeamsAvailable: getYesOrNoForBoolean(propz.get(tournament, ['isMixedTeamsAvailable'], false)),
      isSchoolCanSubmitResult: getYesOrNoForBoolean(propz.get(tournament, ['isSchoolCanSubmitResult'], false)),
      isShowTwitter: getYesOrNoForBoolean(propz.get(tournament, ['isShowTwitter'], false)),
      tournamentType: isTournamentExists
        ? propz.get(tournament, ['type'], TOURNAMENT_TYPE.TEAM_TOURNAMENT)
        : TOURNAMENT_TYPE.TEAM_TOURNAMENT,
      tournamentSubType: isTournamentExists
        ? propz.get(tournament, ['subType'], TOURNAMENT_SUBTYPE.FOOTBALL)
        : TOURNAMENT_SUBTYPE.FOOTBALL,
      sport: propz.get(tournament, ['sport'], undefined),
      sportId: propz.get(tournament, ['sportId'], ''),
      scoring: isTournamentExists ? this.getScoring() : '0',
      organisers: isTournamentExists ? propz.get(tournament, ['organisers'], '') : '',
      organisersEmail: isTournamentExists ? propz.get(tournament, ['organisersEmail'], '') : '',
      priceType: propz.get(tournament, ['price', 'priceType'], TOURNAMENT_PRICE_TYPE.FREE),
      isPaymentSettingsVisible: this.state.isPaymentSettingsVisible,
      paymentType: propz.get(tournament, ['price', 'paymentType'], TOURNAMENT_PAYMENT_TYPE.FIXED_PRICE),
      price: propz.get(tournament, ['price', 'price'], 0),
      paymentAccountId: propz.get(tournament, ['paymentSettings', 'paymentAccountId'], 0),
      paymentAccountCurrent: defaultPaymentsAccount,
      isAdvancedSettingsVisible: this.state.isAdvancedSettingsVisible,
      entriesOpen: modeCopy ? '' : entriesOpen || '',
      deadlineForEntries: modeCopy ? '' : deadlineForEntries || '',
      isWhenSpacesAreFull: getYesOrNoForBoolean(propz.get(tournament, ['isWhenSpacesAreFull'], false)),
      tournamentStreamIsEnabled: getYesOrNoForBoolean(propz.get(tournament, ['tournamentStream', 'isEnabled'], false)),
      tournamentStreamUrl: propz.get(tournament, ['tournamentStream', 'url'], ''),
      resultImportPropertiesFieldsPlayerNumber: propz.get(
        tournament,
        ['resultImportProperties', 'fields', 'playerNumber'],
        DEFAULT_EVENT_IMPORT_PROPERTIES.playerNumber
      ),
      resultImportPropertiesFieldsTeamCode: propz.get(
        tournament,
        ['resultImportProperties', 'fields', 'teamCode'],
        DEFAULT_EVENT_IMPORT_PROPERTIES.teamCode
      ),
      resultImportPropertiesFieldsResult: propz.get(
        tournament,
        ['resultImportProperties', 'fields', 'result'],
        DEFAULT_EVENT_IMPORT_PROPERTIES.result
      ),
      resultImportPropertiesFieldsDelimiter: propz.get(
        tournament,
        ['resultImportProperties', 'fields', 'delimiter'],
        DEFAULT_EVENT_IMPORT_PROPERTIES.delimiter
      ),
      resultImportPropertiesResultMask: propz.get(tournament, ['resultImportProperties', 'resultMask'], ''),
      venue: venue,
      maxPlayersPerTeam: isTournamentExists ? propz.get(tournament, ['maxPlayersPerTeam'], '') : ''
    };

    const classes = isCreateVenueFormOpen || isDeleteVenueModalOpen ? 'mt-3 modal-open' : 'mt-3';

    return (
      <div className={classes}>
        {this.renderCreateVenueForm()}
        {this.renderDeleteVenueModal()}
        <Formik
          onSubmit={value => {
            this.onSubmit(value);
          }}
          initialValues={tournamentInitial}
          validationSchema={tournamentValidationSchema}
          render={({ values, setFieldValue, touched, errors }) => (
            <Form>
              <div className="font-weight-bold">{mainTitle}</div>
              <div className="row">
                <div className="col-md-6">
                  <div className="bTournamentFormBlock rounded">
                    <div className="form-group">
                      <LabelWithQuestionIcon
                        customClass="bTournamentViewLabel"
                        labelText="Tournament name"
                        hintText={
                          "Recommended format: Include the union name and the season. For example, 'ISA U11 Boys Football National Finals 2024/2025'. This helps in clearly identifying the tournament and its specifics."
                        }
                      />
                      <Field
                        type="text"
                        name="name"
                        className={`${touched.name && errors.name ? 'form-control is-invalid' : 'form-control '}`}
                      />
                      {touched.name && errors.name ? <div className="alert alert-danger">{errors.name}</div> : null}
                    </div>
                    <div className="form-group">
                      <LabelWithQuestionIcon
                        customClass="bTournamentViewLabel"
                        labelText="Description"
                        hintText={
                          "Use this field to provide essential information that participants and visitors need to see first. For example, you can highlight important announcements, deadlines, or requirements, such as 'Please check the Documents tab of the tournament card to download the program and risk assessment form.' Utilize the available formatting tools to make important details stand out. This ensures that crucial information is clearly visible and can catch the viewer's attention effectively."
                        }
                      />

                      <Field name="description">
                        {({ field, form }) => (
                          <HTMLEditorQuillHide
                            value={field.value}
                            onTextChange={value => form.setFieldValue(field.name, value)}
                            isEnabled={true}
                          />
                        )}
                      </Field>
                      {touched.description && errors.description && (
                        <div className="alert alert-danger">{errors.description}</div>
                      )}
                    </div>

                    <div className="row">
                      {/* Sport Field */}
                      <div className="col-md-12">
                        <div className="form-group">
                          <LabelWithQuestionIcon customClass="bTournamentViewLabel" labelText="Sport" hintText={''} />
                          <Field
                            name="sport"
                            render={({ field }) => (
                              <Autocomplete
                                searchFunction={this.getSports}
                                getElementTitle={getName}
                                customClass="mFullWidth mb-3"
                                defaultItem={values.sport}
                                onSelect={sport => {
                                  setFieldValue('sportId', sport.id);
                                  setFieldValue('sport', sport);
                                  switch (true) {
                                    case sport.genders.maleOnly:
                                      setFieldValue('gender', GENDER.MALE_ONLY);
                                      setFieldValue('isMixedTeamsAvailable', YES_NO_OPTIONS.NO);
                                      break;
                                    case sport.genders.femaleOnly:
                                      setFieldValue('gender', GENDER.FEMALE_ONLY);
                                      setFieldValue('isMixedTeamsAvailable', YES_NO_OPTIONS.NO);
                                      break;
                                    case sport.genders.mixed:
                                      setFieldValue('gender', GENDER.MIXED);
                                      setFieldValue('isMixedTeamsAvailable', YES_NO_OPTIONS.YES);
                                      break;
                                  }
                                }}
                              />
                            )}
                          />
                          {touched.sportId && errors.sportId && (
                            <div className="alert alert-danger">{errors.sportId}</div>
                          )}
                        </div>
                      </div>

                      {/* Tournament Type and Subtype Fields */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <LabelWithQuestionIcon
                            customClass="bTournamentViewLabel"
                            labelText="Tournament Type"
                            hintText={''}
                          />
                          <Field
                            component="select"
                            name="tournamentType"
                            className="form-control"
                            onChange={event => {
                              const tournamentType = event.target.value;
                              setFieldValue('tournamentType', tournamentType);
                              setFieldValue('ageGroups', []); // Reset ageGroups on type change
                              switch (tournamentType) {
                                case TOURNAMENT_TYPE.TEAM_TOURNAMENT:
                                  setFieldValue('tournamentSubType', TOURNAMENT_SUBTYPE.FOOTBALL);
                                  break;
                                case TOURNAMENT_TYPE.INDIVIDUAL_TOURNAMENT:
                                  setFieldValue('tournamentSubType', TOURNAMENT_SUBTYPE.REGULAR_INDIVIDUAL);
                                  break;
                                case TOURNAMENT_TYPE.SINGLE_DOUBLE_TOURNAMENT:
                                  setFieldValue('tournamentSubType', TOURNAMENT_SUBTYPE.BADMINTON);
                                  break;
                                case TOURNAMENT_TYPE.MINI_TEAM_TOURNAMENT:
                                  setFieldValue('tournamentSubType', TOURNAMENT_SUBTYPE.BADMINTON_MT);
                                  break;
                              }
                            }}
                          >
                            <option value={TOURNAMENT_TYPE.TEAM_TOURNAMENT}>
                              {TOURNAMENT_TYPE_SERVER_TO_CLIENT_MAPPING.TEAM_TOURNAMENT}
                            </option>
                            <option value={TOURNAMENT_TYPE.INDIVIDUAL_TOURNAMENT}>
                              {TOURNAMENT_TYPE_SERVER_TO_CLIENT_MAPPING.INDIVIDUAL_TOURNAMENT}
                            </option>
                            <option value={TOURNAMENT_TYPE.SINGLE_DOUBLE_TOURNAMENT}>
                              {TOURNAMENT_TYPE_SERVER_TO_CLIENT_MAPPING.SINGLE_DOUBLE_TOURNAMENT}
                            </option>
                            <option value={TOURNAMENT_TYPE.MINI_TEAM_TOURNAMENT}>
                              {TOURNAMENT_TYPE_SERVER_TO_CLIENT_MAPPING.MINI_TEAM_TOURNAMENT}
                            </option>
                          </Field>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <LabelWithQuestionIcon
                            customClass="bTournamentViewLabel"
                            labelText="Tournament Subtype"
                            hintText={''}
                          />
                          <Field component="select" name="tournamentSubType" className="form-control">
                            {this.renderTournamentSubTypeOptions(values.tournamentType)}
                          </Field>
                        </div>
                      </div>

                      {/* Ages and Gender Fields */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <LabelWithQuestionIcon customClass="bTournamentViewLabel" labelText="Gender" hintText={''} />
                          <Field
                            component="select"
                            name="gender"
                            className="form-control mb-3"
                            onChange={event => {
                              const gender = event.target.value;
                              setFieldValue('gender', gender);
                              if (gender === GENDER.MIXED) {
                                setFieldValue('isMixedTeamsAvailable', YES_NO_OPTIONS.YES);
                              } else {
                                setFieldValue('isMixedTeamsAvailable', YES_NO_OPTIONS.NO);
                              }
                            }}
                            disabled={!values.sport}
                          >
                            {getGenderOptionsBySport(values.sport)}
                          </Field>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <LabelWithQuestionIcon customClass="bTournamentViewLabel" labelText="Ages" hintText={''} />
                          <Field
                            name="ageGroups"
                            render={({ field }) => (
                              <Multiselect
                                items={this.getAgeGroup()}
                                onChange={options => {
                                  if (values.tournamentType !== TOURNAMENT_TYPE.INDIVIDUAL_TOURNAMENT) {
                                    options = options.slice(-1); // Ensure only one age group is selected
                                  }
                                  setFieldValue('ageGroups', options);
                                }}
                                value={field.value || []}
                              />
                            )}
                          />
                          {touched.ageGroups && errors.ageGroups && (
                            <div className="alert alert-danger">{errors.ageGroups}</div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <LabelWithQuestionIcon
                            customClass="bTournamentViewLabel"
                            labelText="Start date/time"
                            hintText=""
                          />
                          <Field
                            name="startTime"
                            render={({ field }) => {
                              let startTime;
                              if (Moment(values.startTime, 'DD-MM-YYYY HH:mm aa').isValid()) {
                                startTime = Moment(values.startTime, 'DD-MM-YYYY HH:mm aa').toDate();
                              }
                              return (
                                <DatePicker
                                  selected={startTime}
                                  onChange={startTime => setFieldValue('startTime', startTime)}
                                  className="form-control"
                                  timeInputLabel="Time: "
                                  showTimeSelect
                                  dateFormat="dd-MM-yyyy HH:mm"
                                  timeFormat="HH:mm"
                                />
                              );
                            }}
                          />
                          {touched.startTime && errors.startTime && (
                            <div className="alert alert-danger">{errors.startTime}</div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <LabelWithQuestionIcon
                            customClass="bTournamentViewLabel"
                            labelText="Finish date/time"
                            hintText=""
                          />
                          <Field
                            name="endTime"
                            render={({ field }) => {
                              let endTime;
                              if (Moment(values.endTime, 'DD-MM-YYYY HH:mm aa').isValid()) {
                                endTime = Moment(values.endTime, 'DD-MM-YYYY HH:mm aa').toDate();
                              }
                              return (
                                <DatePicker
                                  selected={endTime}
                                  onChange={endTime => setFieldValue('endTime', endTime)}
                                  className="form-control"
                                  timeInputLabel="Time: "
                                  showTimeSelect
                                  dateFormat="dd-MM-yyyy HH:mm"
                                  minDate={
                                    Moment(values.startTime, 'DD-MM-YYYY HH:mm').isValid()
                                      ? Moment(values.startTime, 'DD-MM-YYYY HH:mm').toDate()
                                      : undefined
                                  }
                                  timeFormat="HH:mm"
                                />
                              );
                            }}
                          />
                          {touched.endTime && errors.endTime && (
                            <div className="alert alert-danger">{errors.endTime}</div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <LabelWithQuestionIcon
                            customClass="bTournamentViewLabel"
                            labelText="Entries open"
                            hintText={''}
                          />
                          <Field
                            name="entriesOpen"
                            render={({ field }) => {
                              let entriesOpen;
                              if (Moment(values.entriesOpen, 'DD-MM-YYYY HH:mm aa').isValid()) {
                                entriesOpen = Moment(values.entriesOpen, 'DD-MM-YYYY HH:mm aa').toDate();
                              }
                              return (
                                <DatePicker
                                  selected={entriesOpen}
                                  onChange={entriesOpen => setFieldValue('entriesOpen', entriesOpen)}
                                  className="form-control"
                                  timeInputLabel="Time: "
                                  showTimeSelect
                                  dateFormat="dd-MM-yyyy HH:mm"
                                  timeFormat="HH:mm"
                                  minDate={today}
                                />
                              );
                            }}
                          />
                          {touched.entriesOpen && errors.entriesOpen && (
                            <div className="alert alert-danger">{errors.entriesOpen}</div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <LabelWithQuestionIcon
                            customClass="bTournamentViewLabel"
                            labelText="Deadline for entries"
                            hintText={''}
                          />
                          <Field
                            name="deadlineForEntries"
                            render={({ field }) => {
                              let deadlineForEntries;
                              if (Moment(values.deadlineForEntries, 'DD-MM-YYYY HH:mm aa').isValid()) {
                                deadlineForEntries = Moment(values.deadlineForEntries, 'DD-MM-YYYY HH:mm aa').toDate();
                              }
                              return (
                                <DatePicker
                                  selected={deadlineForEntries}
                                  onChange={deadlineForEntries => {
                                    setFieldValue('deadlineForEntries', deadlineForEntries);
                                    if (deadlineForEntries) {
                                      setFieldValue('isWhenSpacesAreFull', YES_NO_OPTIONS.NO);
                                    }
                                  }}
                                  className="form-control"
                                  timeInputLabel="Time: "
                                  showTimeSelect
                                  dateFormat="dd-MM-yyyy HH:mm"
                                  minDate={
                                    Moment(values.entriesOpen, 'DD-MM-YYYY HH:mm').isValid()
                                      ? Moment(values.entriesOpen, 'DD-MM-YYYY HH:mm').toDate()
                                      : undefined
                                  }
                                  timeFormat="HH:mm"
                                />
                              );
                            }}
                          />
                          {touched.deadlineForEntries && errors.deadlineForEntries && (
                            <div className="alert alert-danger">{errors.deadlineForEntries}</div>
                          )}
                        </div>

                        {!values.deadlineForEntries && (
                          <div className="form-group">
                            <LabelWithQuestionIcon
                              customClass="bTournamentViewLabel"
                              labelText="Or when spaces are full"
                              hintText={''}
                            />
                            <Field component="select" name="isWhenSpacesAreFull" className="form-control">
                              <option value={YES_NO_OPTIONS.NO}>{YES_NO_OPTIONS.NO}</option>
                              <option value={YES_NO_OPTIONS.YES}>{YES_NO_OPTIONS.YES}</option>
                            </Field>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-group">
                      <LabelWithQuestionIcon customClass="bTournamentViewLabel" labelText="Venue" hintText={''} />
                      <Field
                        name="venue"
                        render={({ field }) => {
                          return (
                            <Autocomplete
                              placeholder="Start typing the postcode or venue name"
                              searchFunction={text => getPostcodes(text, user)}
                              getElementTitle={getNameByPlaceOrPostcode}
                              customClass="mFullWidth mb-3 bVenueInput"
                              defaultItem={this.state.venue}
                              onSelect={venue => {
                                let postcode = venue;
                                if (isPlace(postcode)) {
                                  postcode = convertPlaceToPostcodeWithPlaceName(postcode);
                                }
                                setFieldValue('venue', postcode);
                                this.setState({
                                  venue: postcode
                                });
                                this.getNewPoint(postcode.point);
                              }}
                            />
                          );
                        }}
                      />
                      {propz.get(venue, ['venueType']) !== 'TBD' && typeof point !== 'undefined' && (
                        <Map
                          key={tournament ? tournament.id : 'emptyPostcode'}
                          point={point}
                          getNewPoint={this.getNewPoint}
                          customStylingClass="eVenue_map"
                          isMarkerDraggable={true}
                        />
                      )}
                    </div>

                    <div className="form-group">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={this.isDeleteButton() ? this.deleteVenueModalOpen : this.createVenueFormOpen}
                      >
                        {this.isDeleteButton() ? 'Delete venue' : 'Create venue'}
                      </button>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <LabelWithQuestionIcon
                            customClass="bTournamentViewLabel"
                            labelText="Scoring (ex. 8,6,5,4,3,2,1/5,2,1,0)"
                            hintText={
                              "For individual tournaments, specify the scoring format using a descending sequence of points based on the placement, such as '10,9,8,7,6,5,4,3,2,1' or a custom sequence like '5,4,3,3,3' where points decrease as the rank lowers. The highest-ranking participant receives the first number of points, the second highest receives the second number of points, and so on. For team tournaments, define the scoring format to reflect the outcome of matches, using a format like '5,2,1,0', where '5' points are awarded for a win, '2' points for a goal draw, '1' point for a goalless draw, and '0' points for a loss. This format helps in accurately representing the performance of teams based on match results."
                            }
                          />
                          <Field
                            type="text"
                            name="scoring"
                            className={`form-control ${touched.scoring && errors.scoring ? 'is-invalid' : ''}`}
                          />
                          {touched.scoring && errors.scoring && (
                            <div className="alert alert-danger">{errors.scoring}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <LabelWithQuestionIcon
                            customClass="bTournamentViewLabel"
                            labelText="Schools can submit results"
                            hintText={
                              'Enable this option to allow schools to submit their own competition results. This feature is particularly useful for remote or virtual competitions, facilitating efficient result collection without the need for physical presence. It can also streamline the process of selecting teams to represent your union at higher-level competitions.'
                            }
                          />
                          <Field
                            component="select"
                            name="isSchoolCanSubmitResult"
                            className="form-control"
                            disabled={values.tournamentType !== TOURNAMENT_TYPE.INDIVIDUAL_TOURNAMENT}
                          >
                            <option value={YES_NO_OPTIONS.NO}>{YES_NO_OPTIONS.NO}</option>
                            <option value={YES_NO_OPTIONS.YES}>{YES_NO_OPTIONS.YES}</option>
                          </Field>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <LabelWithQuestionIcon
                            customClass="bTournamentViewLabel"
                            labelText="Display age groups on the website"
                            hintText={''}
                          />
                          <Field component="select" name="isAgeGroupsOnWebSiteVisible" className="form-control">
                            <option value={YES_NO_OPTIONS.NO}>{YES_NO_OPTIONS.NO}</option>
                            <option value={YES_NO_OPTIONS.YES}>{YES_NO_OPTIONS.YES}</option>
                          </Field>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <LabelWithQuestionIcon
                            customClass="bTournamentViewLabel"
                            labelText="Display the twitter feed on the website"
                            hintText={''}
                          />
                          <Field component="select" name="isShowTwitter" className="form-control">
                            <option value={YES_NO_OPTIONS.NO}>{YES_NO_OPTIONS.NO}</option>
                            <option value={YES_NO_OPTIONS.YES}>{YES_NO_OPTIONS.YES}</option>
                          </Field>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <LabelWithQuestionIcon
                              customClass="bTournamentViewLabel"
                              labelText="Max Players Per Team"
                              hintText="Specify the maximum number of players allowed per team. Leave empty if there's no limit."
                            />
                            <Field
                              type="number"
                              name="maxPlayersPerTeam"
                              className={`form-control ${
                                touched.maxPlayersPerTeam && errors.maxPlayersPerTeam ? 'is-invalid' : ''
                              }`}
                              placeholder="Enter maximum players per team"
                            />
                            {touched.maxPlayersPerTeam && errors.maxPlayersPerTeam && (
                              <div className="alert alert-danger">{errors.maxPlayersPerTeam}</div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <LabelWithQuestionIcon customClass="bTournamentViewLabel" labelText="Organisers" hintText={''} />
                      <Field
                        component="textarea"
                        rows="3"
                        name="organisers"
                        className={`${
                          touched.organisers && errors.organisers ? 'form-control is-invalid' : 'form-control '
                        }`}
                        placeholder="Add organisers’ details "
                      />
                      {touched.organisers && errors.organisers ? (
                        <div className="alert alert-danger">{errors.organisers}</div>
                      ) : null}
                    </div>

                    <div className="form-group">
                      <LabelWithQuestionIcon
                        customClass="bTournamentViewLabel"
                        labelText="Organisers email"
                        hintText={
                          "Enter the organiser's email address to enable schools to contact them directly for any inquiries or issues. Additionally, this email address will be set as the 'reply-to' address for all notifications sent regarding this tournament, streamlining communication."
                        }
                      />
                      <Field
                        type="email"
                        name="organisersEmail"
                        className={`${
                          touched.organisersEmail && errors.organisersEmail
                            ? 'form-control is-invalid'
                            : 'form-control '
                        }`}
                        placeholder="Enter organisers email"
                      />
                      {touched.organisersEmail && errors.organisersEmail ? (
                        <div className="alert alert-danger">{errors.organisersEmail}</div>
                      ) : null}
                    </div>

                    <div className="form-group">
                      <LabelWithQuestionIcon
                        customClass="bTournamentViewLabel"
                        labelText="Tournament picture"
                        hintText={
                          'For the best display quality, we recommend using an image with a 3:1 aspect ratio. Additionally, to ensure fast loading times on the tournament website, please make sure the picture file size does not exceed 1MB. '
                        }
                      />
                      <input type="file" name="picUrl" className={imgInputClassName} onChange={this.onImageSelected} />
                      {isImageError && <div className="alert alert-danger">Error uploading image</div>}
                    </div>
                    {isPicUrlExists && (
                      <div className="mMaxWidth200px">
                        <button type="button" className="close" onClick={this.onRemoveImageClick}>
                          <span>&times;</span>
                        </button>
                        <img className="img-fluid img-thumbnail" src={picUrl} alt="News picture" />
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="bTournamentFormBlock rounded">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group form-check">
                            <div className="d-flex align-items-center">
                              <Field
                                name="isPaymentSettingsVisible"
                                render={({ field, form }) => (
                                  <Switch
                                    {...field}
                                    value={field.value}
                                    onChange={() => form.setFieldValue(field.name, !field.value)}
                                    customClass="mb-3"
                                  />
                                )}
                              />
                              <LabelWithQuestionIcon
                                labelText="Payment Settings"
                                customLabelClass="form-check-label pl-2"
                                labelHtmlFor="payment-settings-toggle"
                                hintText=""
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-9" />
                      </div>
                    </div>

                    {values.isPaymentSettingsVisible && (
                      <>
                        <LabelWithQuestionIcon
                          customClass="bTournamentViewLabel"
                          labelText={
                            isPaymentsEnabled
                              ? 'Price type'
                              : 'Price type (paid price type available for school union with payment enabled options)'
                          }
                          hintText=""
                        />
                        <Field
                          disabled={isTournamentExists}
                          component="select"
                          name="priceType"
                          className="form-control mb-3"
                          onChange={event => {
                            const priceType = event.target.value;
                            setFieldValue('priceType', priceType);
                            if (priceType === TOURNAMENT_PRICE_TYPE.FREE) {
                              setFieldValue('price', 0);
                            }
                          }}
                        >
                          <option value={TYPE_OF_TOURNAMENT_PRICE_TYPE_OPTIONS[0].value}>
                            {TYPE_OF_TOURNAMENT_PRICE_TYPE_OPTIONS[0].text}
                          </option>
                          {isPaymentsEnabled && (
                            <option value={TYPE_OF_TOURNAMENT_PRICE_TYPE_OPTIONS[1].value}>
                              {TYPE_OF_TOURNAMENT_PRICE_TYPE_OPTIONS[1].text}
                            </option>
                          )}
                        </Field>
                        <LabelWithQuestionIcon
                          customClass="bTournamentViewLabel"
                          labelText="Payment type (only for paid price type)"
                          hintText={''}
                        />
                        <Field
                          disabled={values.priceType === TOURNAMENT_PRICE_TYPE.FREE}
                          component="select"
                          name="paymentType"
                          className="form-control mb-3"
                        >
                          <option value={TYPE_OF_TOURNAMENT_PAYMENT_TYPE_OPTIONS[0].value}>
                            {TYPE_OF_TOURNAMENT_PAYMENT_TYPE_OPTIONS[0].text}
                          </option>
                        </Field>
                        <LabelWithQuestionIcon customClass="bTournamentViewLabel" labelText="Price" hintText={''} />
                        <Field
                          disabled={values.priceType === TOURNAMENT_PRICE_TYPE.FREE}
                          type="text"
                          name="price"
                          render={({ field }) => {
                            return (
                              <CurrencyInput
                                currency={CLUB_CURRENCY.POUND as CURRENCY_TYPE}
                                onChange={value => {
                                  setFieldValue('price', Number(value));
                                }}
                                value={field.value}
                                disabled={values.priceType === TOURNAMENT_PRICE_TYPE.FREE}
                              />
                            );
                          }}
                        />
                        {touched.price && errors.price ? (
                          <div className="alert alert-danger">{errors.price}</div>
                        ) : null}
                        <LabelWithQuestionIcon
                          customClass="bTournamentViewLabel"
                          labelText="Payment Accounts (only for paid price type)"
                          hintText={''}
                        />
                        <Field
                          name="paymentAccountCurrent"
                          render={({ field }) => {
                            return (
                              <Autocomplete
                                searchFunction={this.getPaymentAccounts}
                                getElementTitle={getName}
                                customClass="mFullWidth mb-3"
                                defaultItem={values.paymentAccountCurrent}
                                onSelect={paymentAccountCurrent => {
                                  setFieldValue('paymentAccountId', paymentAccountCurrent.id);
                                }}
                                disabled={values.priceType === TOURNAMENT_PRICE_TYPE.FREE}
                              />
                            );
                          }}
                        />
                      </>
                    )}

                    <div className="col-md-12">
                      <div className="form-group form-check">
                        <div className="d-flex align-items-center">
                          <Field
                            name="isAdvancedSettingsVisible"
                            render={({ field, form }) => (
                              <Switch
                                {...field}
                                value={field.value}
                                onChange={() => form.setFieldValue(field.name, !field.value)}
                                customClass="mb-3"
                              />
                            )}
                          />
                          <LabelWithQuestionIcon
                            labelText="Advanced Settings"
                            customLabelClass="form-check-label pl-2"
                            labelHtmlFor="advanced-settings-toggle"
                            hintText=""
                          />
                        </div>
                      </div>
                    </div>

                    {values.isAdvancedSettingsVisible && (
                      <>
                        <LabelWithQuestionIcon
                          customClass="bTournamentViewLabel"
                          labelText="Tournament Stream"
                          hintText={''}
                        />
                        <Field component="select" name="tournamentStreamIsEnabled" className="form-control mb-3">
                          <option value={YES_NO_OPTIONS.NO}>{YES_NO_OPTIONS.NO}</option>
                          <option value={YES_NO_OPTIONS.YES}>{YES_NO_OPTIONS.YES}</option>
                        </Field>
                        <div className="form-group">
                          <LabelWithQuestionIcon
                            customClass="bTournamentViewLabel"
                            labelText="Stream ID http://youtube.com/"
                            hintText={''}
                          />
                          <Field type="text" name="tournamentStreamUrl" className="form-control" />
                        </div>
                        <label>Event import properties</label>
                        <div className="form-group">
                          <LabelWithQuestionIcon
                            customClass="bTournamentViewLabel"
                            labelText="Player number:"
                            hintText={''}
                          />
                          <Field
                            type="text"
                            name="resultImportPropertiesFieldsPlayerNumber"
                            className="form-control mb-3"
                          />
                          {touched.resultImportPropertiesFieldsPlayerNumber &&
                          errors.resultImportPropertiesFieldsPlayerNumber ? (
                            <div className="alert alert-danger">{errors.resultImportPropertiesFieldsPlayerNumber}</div>
                          ) : null}

                          <LabelWithQuestionIcon
                            customClass="bTournamentViewLabel"
                            labelText="Team code:"
                            hintText={''}
                          />
                          <Field
                            type="text"
                            name="resultImportPropertiesFieldsTeamCode"
                            className="form-control mb-3"
                          />
                          {touched.resultImportPropertiesFieldsTeamCode &&
                          errors.resultImportPropertiesFieldsTeamCode ? (
                            <div className="alert alert-danger">{errors.resultImportPropertiesFieldsTeamCode}</div>
                          ) : null}

                          <LabelWithQuestionIcon customClass="bTournamentViewLabel" labelText="Result:" hintText={''} />
                          <Field type="text" name="resultImportPropertiesFieldsResult" className="form-control mb-3" />
                          {touched.resultImportPropertiesFieldsResult && errors.resultImportPropertiesFieldsResult ? (
                            <div className="alert alert-danger">{errors.resultImportPropertiesFieldsResult}</div>
                          ) : null}

                          <LabelWithQuestionIcon
                            customClass="bTournamentViewLabel"
                            labelText="Delimiter:"
                            hintText={''}
                          />
                          <Field
                            type="text"
                            name="resultImportPropertiesFieldsDelimiter"
                            className="form-control mb-3"
                          />
                          {touched.resultImportPropertiesFieldsDelimiter &&
                          errors.resultImportPropertiesFieldsDelimiter ? (
                            <div className="alert alert-danger">{errors.resultImportPropertiesFieldsDelimiter}</div>
                          ) : null}

                          <LabelWithQuestionIcon
                            customClass="bTournamentViewLabel"
                            labelText="Result mask:"
                            hintText={''}
                          />
                          <Field
                            type="text"
                            name="resultImportPropertiesResultMask"
                            className="form-control mb-3"
                            title={hintTextResultMask}
                          />
                          {touched.resultImportPropertiesResultMask && errors.resultImportPropertiesResultMask ? (
                            <div className="alert alert-danger">{errors.resultImportPropertiesResultMask}</div>
                          ) : null}
                        </div>
                      </>
                    )}

                    <div className="bTournamentFormBlock rounded">
                      {this.renderFormFieldListAnnouncements()}
                      <button type="button" className="btn btn-primary" onClick={this.onClickAddCustomSectionForm}>
                        Add custom section
                      </button>
                    </div>
                    <div className="bTournamentFormBlock rounded">
                      {this.renderFormFieldListGoverningBodies()}
                      <button type="button" className="btn btn-primary" onClick={this.onClickAddGoverningBodiesForm}>
                        Add National Governing Bodies
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bTournamentFormSectionButton">
                <button type="button" className="btn btn-secondary mr-3" onClick={this.onCancelButtonClick}>
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  {positiveButtonTitle}
                </button>
              </div>
            </Form>
          )}
        />
      </div>
    );
  }
}
