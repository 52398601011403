import * as React from 'react';
import { ACCESS_PRESET_SERVER_TO_CLIENT_MAPPING, PHOTO_WIDTH } from '../../../../../../../consts/album';
import { getResizedToMinValueUrl } from '../../../../../../../helpers/gallery/gallery';
import './PreviewPhoto.scss';
import { ACCESS_PRESET_TYPE } from '../../../../../../../types/album';
import { getAccessPresetColor } from '../../../../../../../helpers/event/eventPhotoAndVideo';

interface Props {
  id: string;
  url: string;
  isOnSale: boolean;
  saleLink: string;
  onDeletePhotoClick: (id: string) => void;
  onPhotoClick: (id: string) => void;
  accessPreset: ACCESS_PRESET_TYPE;
}
interface State {}

export class PreviewPhoto extends React.Component<Props, State> {
  onDeletePhoto = event => {
    const { onDeletePhotoClick, id } = this.props;
    onDeletePhotoClick(id);
    event.stopPropagation();
  };

  render() {
    const { url, isOnSale, saleLink, id, onPhotoClick, accessPreset } = this.props;
    const background = {
      backgroundImage: `url(${getResizedToMinValueUrl(url, PHOTO_WIDTH)})`
    };

    const color = getAccessPresetColor(accessPreset);

    return (
      <div className="bPreviewPhoto" onClick={() => onPhotoClick(id)} style={background}>
        {isOnSale && (
          <a
            href={saleLink}
            //className ={'bPreviewPhotoSaleLink'}
            //onClick = { this.onClickSaleLink }
          >
            Buy now
          </a>
        )}
        <div className="ePreviewPhoto_actions" onClick={this.onDeletePhoto}>
          <span>Delete</span>
        </div>
        <div className="ePreviewPhoto_accessPreset" style={{ color: color }}>
          <span>{ACCESS_PRESET_SERVER_TO_CLIENT_MAPPING[accessPreset]}</span>
        </div>
      </div>
    );
  }
}
