import { AppUser } from 'Src/views/App/App';
import axios from 'axios';
import * as propz from 'propz';
import { DEFAULT_FILTER } from '../admin/user';
import { DEFAULT_LIMIT, DEFAULT_SKIP, SORT } from '../../../consts/table';
import { Count } from '../../../models/count';
import { Tournament } from '../../../models/tournament';
import { getTodayMidnight } from 'Src/helpers/common/common';

const DEFAULT_TOURNAMENT_CREATOR_FILTER: any = {
  isCreator: true
};

const DEFAULT_TOURNAMENT_PARTICIPANT_FILTER: any = {
  isParticipant: true
};

function getUpcomingTournamentsStartTimeFilterField(filter) {
  const todayMidnight = getTodayMidnight();

  const startTimeFromFilter = propz.get(filter, ['where', 'startTime']);
  const isStartTimeFromFilterExist = typeof startTimeFromFilter !== 'undefined';
  const isStartTimeFromFilterLessThanCurrentDate =
    isStartTimeFromFilterExist && new Date(startTimeFromFilter['$gte']) <= new Date(todayMidnight);

  const startTime = isStartTimeFromFilterLessThanCurrentDate
    ? { ...startTimeFromFilter, $gte: todayMidnight }
    : startTimeFromFilter;

  return isStartTimeFromFilterExist ? startTime : { $gte: todayMidnight };
}

function getPastTournamentsStartTimeFilterField(filter) {
  const todayMidnight = getTodayMidnight();

  const startTimeFromFilter = propz.get(filter, ['where', 'startTime']);
  const isStartTimeFromFilterExist = typeof startTimeFromFilter !== 'undefined';
  const isStartTimeFromFilterMoreThanCurrentDate =
    isStartTimeFromFilterExist && new Date(startTimeFromFilter['$lte']) >= new Date(todayMidnight);

  const startTime = isStartTimeFromFilterMoreThanCurrentDate
    ? { ...startTimeFromFilter, $lte: todayMidnight }
    : startTimeFromFilter;

  return isStartTimeFromFilterExist ? startTime : { $lte: todayMidnight };
}

export function getTournaments(user: AppUser, filter = DEFAULT_TOURNAMENT_CREATOR_FILTER): Promise<Tournament[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...DEFAULT_TOURNAMENT_CREATOR_FILTER,
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order
      }
    }
  };

  return axios.get<Tournament[]>(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments`, config).then(response => {
    return response.data;
  });
}

export function getUpcomingTournaments(
  user: AppUser,
  filter = DEFAULT_TOURNAMENT_CREATOR_FILTER
): Promise<Tournament[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  const startTime = getUpcomingTournamentsStartTimeFilterField(filter);

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...DEFAULT_TOURNAMENT_CREATOR_FILTER,
          ...filter.where,
          startTime
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order || `startTime ${SORT.ASC}`
      }
    }
  };

  return axios.get<Tournament[]>(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments`, config).then(response => {
    return response.data;
  });
}

export function getPastTournaments(user: AppUser, filter = DEFAULT_TOURNAMENT_CREATOR_FILTER): Promise<Tournament[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  const startTime = getPastTournamentsStartTimeFilterField(filter);

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...DEFAULT_TOURNAMENT_CREATOR_FILTER,
          ...filter.where,
          startTime
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order || `startTime ${SORT.ASC}`
      }
    }
  };

  return axios.get<Tournament[]>(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments`, config).then(response => {
    return response.data;
  });
}

export function getHighLevelTournaments(user: AppUser, filter): Promise<Tournament[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER,
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order
      }
    }
  };

  return axios.get<Tournament[]>(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments`, config).then(response => {
    return response.data;
  });
}

export function getUpcomingHighLevelTournaments(user: AppUser, filter): Promise<Tournament[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  const startTime = getUpcomingTournamentsStartTimeFilterField(filter);

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER,
          ...filter.where,
          startTime
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order || `startTime ${SORT.ASC}`
      }
    }
  };

  return axios.get<Tournament[]>(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments`, config).then(response => {
    return response.data;
  });
}

export function getPastHighLevelTournaments(user: AppUser, filter): Promise<Tournament[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  const startTime = getPastTournamentsStartTimeFilterField(filter);

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER,
          ...filter.where,
          startTime
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order || `startTime ${SORT.ASC}`
      }
    }
  };

  return axios.get<Tournament[]>(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments`, config).then(response => {
    return response.data;
  });
}

export function getHighLevelTournamentsCount(user: AppUser, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER,
            ...where
          }
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER
          }
        }
      }
    };
  }

  return axios.get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/count`, config).then(response => {
    return response.data;
  });
}

export function getUpcomingHighLevelTournamentsCount(user: AppUser, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  const startTime = getUpcomingTournamentsStartTimeFilterField({ where });

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER,
            ...where,
            startTime
          }
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER,
            startTime
          }
        }
      }
    };
  }

  return axios.get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/count`, config).then(response => {
    return response.data;
  });
}

export function getPastHighLevelTournamentsCount(user: AppUser, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  const startTime = getPastTournamentsStartTimeFilterField({ where });

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER,
            ...where,
            startTime
          }
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER,
            startTime
          }
        }
      }
    };
  }

  return axios.get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/count`, config).then(response => {
    return response.data;
  });
}

export function getAllHighLevelTournamentTeamPlayers(user: AppUser, tournamentId: string, teamId: string, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER,
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/teams/${teamId}/players`, config)
    .then(response => {
      return response.data;
    });
}

export function getHighLevelTournamentTeamPlayers(user: AppUser, tournamentId: string, teamId: string, filter) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...filter.where
        },
        limit: filter.limit,
        order: filter.order,
        skip: filter.skip
      }
    }
  };

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/teams/${teamId}/players`, config)
    .then(response => {
      return response.data;
    });
}

export function getHighLevelTournamentTeamPlayersCount(
  user: AppUser,
  tournamentId: string,
  teamId: string,
  where
): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER,
            ...where
          }
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER
          }
        }
      }
    };
  }

  return axios
    .get<Count>(
      `${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/teams/${teamId}/players/count`,
      config
    )
    .then(response => {
      return response.data;
    });
}

export function getAllHighLevelTournaments(user: AppUser, where?): Promise<Tournament[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER,
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios.get<Tournament[]>(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments`, config).then(response => {
    return response.data;
  });
}

export function getAllUpcomingHighLevelTournaments(user: AppUser, where?): Promise<Tournament[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  const todayMidnight = getTodayMidnight();

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            startTime: { $gte: todayMidnight },
            ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER,
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            startTime: { $gte: todayMidnight },
            ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios.get<Tournament[]>(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments`, config).then(response => {
    return response.data;
  });
}

export function getAllPastHighLevelTournaments(user: AppUser, where?): Promise<Tournament[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  const todayMidnight = getTodayMidnight();

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            startTime: { $lte: todayMidnight },
            ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER,
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            startTime: { $lte: todayMidnight },
            ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios.get<Tournament[]>(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments`, config).then(response => {
    return response.data;
  });
}

export function getAllTournaments(user: AppUser, where?): Promise<Tournament[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_TOURNAMENT_CREATOR_FILTER,
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_TOURNAMENT_CREATOR_FILTER
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios.get<Tournament[]>(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments`, config).then(response => {
    return response.data;
  });
}

export function getAllUpcomingTournaments(user: AppUser, where?): Promise<Tournament[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  const todayMidnight = getTodayMidnight();

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            startTime: { $gte: todayMidnight },
            ...DEFAULT_TOURNAMENT_CREATOR_FILTER,
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            startTime: { $gte: todayMidnight },
            ...DEFAULT_TOURNAMENT_CREATOR_FILTER
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios.get<Tournament[]>(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments`, config).then(response => {
    return response.data;
  });
}

export function getAllPastTournaments(user: AppUser, where?): Promise<Tournament[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  const todayMidnight = getTodayMidnight();

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            startTime: { $lte: todayMidnight },
            ...DEFAULT_TOURNAMENT_CREATOR_FILTER,
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            startTime: { $lte: todayMidnight },
            ...DEFAULT_TOURNAMENT_CREATOR_FILTER
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios.get<Tournament[]>(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments`, config).then(response => {
    return response.data;
  });
}

export function getTournamentsCount(user: AppUser, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_TOURNAMENT_CREATOR_FILTER,
            ...where
          }
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_TOURNAMENT_CREATOR_FILTER
          }
        }
      }
    };
  }

  return axios.get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/count`, config).then(response => {
    return response.data;
  });
}

export function getUpcomingTournamentsCount(user: AppUser, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  const startTime = getUpcomingTournamentsStartTimeFilterField({ where });

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_TOURNAMENT_CREATOR_FILTER,
            ...where,
            startTime
          }
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_TOURNAMENT_CREATOR_FILTER,
            startTime
          }
        }
      }
    };
  }

  return axios.get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/count`, config).then(response => {
    return response.data;
  });
}

export function getPastTournamentsCount(user: AppUser, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  const startTime = getPastTournamentsStartTimeFilterField({ where });

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_TOURNAMENT_CREATOR_FILTER,
            ...where,
            startTime
          }
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_TOURNAMENT_CREATOR_FILTER,
            startTime
          }
        }
      }
    };
  }

  return axios.get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/count`, config).then(response => {
    return response.data;
  });
}

export function getTournament(user: AppUser, tournamentId: string): Promise<Tournament> {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .get<Tournament>(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}`, config)
    .then(response => {
      return response.data;
    });
}

export function createTournament(user: AppUser, data: any) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios.post(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments`, data, config).then(response => {
    return response.data;
  });
}

export function deleteTournament(user: AppUser, tournamentId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .delete(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}`, config)
    .then(response => {
      return response.data;
    });
}

export function getTournamentEligibleSchools(user: AppUser, tournamentId, filter = DEFAULT_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter
    }
  };

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/eligibleSchools`, config)
    .then(response => {
      return response.data;
    });
}

export function getTournamentEligibleSchoolsCount(user: AppUser, tournamentId, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where
        }
      }
    };
  } else {
    config = {
      headers: { usid: key }
    };
  }

  return axios
    .get<Count>(
      `${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/eligibleSchools/count`,
      config
    )
    .then(response => {
      return response.data;
    });
}

export function getAllTournamentEligibleSchools(user: AppUser, tournamentId, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/eligibleSchools`, config)
    .then(response => {
      return response.data;
    });
}

export function updateTournamentAutoEnrollment(user: AppUser, data, tournamentId) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .put(
      `${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/autoEnrollmentTeamsSettings`,
      data,
      config
    )
    .then(response => {
      return response.data;
    });
}

export function getCsvListTournamentStudents(user: AppUser, tournamentId) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/players/csv`, config)
    .then(response => {
      return response.data;
    });
}

export function updateTournament(user: AppUser, data, tournamentId) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .put(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}`, data, config)
    .then(response => {
      return response.data;
    });
}

export function getTournamentCsv(user: AppUser, tournamentId) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/csv`, config)
    .then(response => {
      return response.data;
    });
}

export function unsubscribeTournament(user: AppUser, tournamentId) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/unsubscribe`, {}, config)
    .then(response => {
      return response.data;
    });
}
