import * as React from 'react';
import ReactQuill from 'react-quill';
import './HTMLEditorQuillHide.css';
import { FunctionComponent } from 'react';
import {
  HTML_EDITOR_QUILL_FORMATS,
  HTML_EDITOR_QUILL_MODULES,
  HTML_EDITOR_QUILL_MODULES_HIDE
} from './HTMLEditorConst';

interface Props {
  onTextChange: (editorState: any) => void;
  value: string;
  isEnabled: boolean;
}

export const HTMLEditorQuillHide: FunctionComponent<Props> = props => {
  const isEnabled = props.isEnabled;
  return (
    <div className="quillEditor-root">
      <ReactQuill
        onChange={props.onTextChange}
        value={props.value}
        modules={isEnabled ? HTML_EDITOR_QUILL_MODULES : HTML_EDITOR_QUILL_MODULES_HIDE}
        formats={HTML_EDITOR_QUILL_FORMATS}
      />
    </div>
  );
};

HTMLEditorQuillHide.displayName = 'HTMLEditorQuillHide';
