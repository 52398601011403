import { AppUser } from 'Src/views/App/App';
import axios from 'axios';
import { DEFAULT_FILTER } from '../admin/user';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../consts/table';
import { Count } from '../../../models/count';
import { MESSAGE_KIND } from '../../../consts/message';

const DEFAULT_MESSAGES_FILTER: any = {
  allMessageTypes: true
};

export function getParentOutboxMessages(user: AppUser, filter = DEFAULT_MESSAGES_FILTER) {
  const { roleSessionKey: key } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...DEFAULT_MESSAGES_FILTER,
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order
      }
    }
  };

  return axios.get(`${window.apiBase}/i/children/events/messages/outbox`, config).then(response => {
    return response.data;
  });
}

export function getParentAllOutboxMessages(user: AppUser, where?) {
  const { roleSessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_MESSAGES_FILTER,
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_MESSAGES_FILTER
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios.get(`${window.apiBase}/i/children/events/messages/outbox`, config).then(response => {
    return response.data;
  });
}

export function getParentOutboxMessagesCount(user: AppUser, where?): Promise<Count> {
  const { roleSessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_MESSAGES_FILTER,
            ...where
          }
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_MESSAGES_FILTER
          }
        }
      }
    };
  }

  return axios.get<Count>(`${window.apiBase}/i/children/events/messages/outbox/count`, config).then(response => {
    return response.data;
  });
}

export function getParentArchiveMessages(user: AppUser, filter = DEFAULT_MESSAGES_FILTER) {
  const { roleSessionKey: key } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...DEFAULT_MESSAGES_FILTER,
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order
      }
    }
  };

  return axios.get(`${window.apiBase}/i/children/events/messages/archive`, config).then(response => {
    return response.data;
  });
}

export function getParentAllArchiveMessages(user: AppUser, where?) {
  const { roleSessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_MESSAGES_FILTER,
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_MESSAGES_FILTER
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios.get(`${window.apiBase}/i/children/events/messages/archive`, config).then(response => {
    return response.data;
  });
}

export function getParentArchiveMessagesCount(user: AppUser, where?): Promise<Count> {
  const { roleSessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_MESSAGES_FILTER,
            ...where
          }
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_MESSAGES_FILTER
          }
        }
      }
    };
  }

  return axios.get<Count>(`${window.apiBase}/i/children/events/messages/archive/count`, config).then(response => {
    return response.data;
  });
}

export function sendAbsenceMessage(user: AppUser, data) {
  const { roleSessionKey: key } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.post(`${window.apiBase}/i/children/messages/absence`, data, config).then(response => {
    return response.data;
  });
}

export function getParentInboxMessages(user: AppUser, filter) {
  const { roleSessionKey: key } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        ...filter,
        order: 'updatedAt DESC'
      }
    }
  };

  return axios.get(`${window.apiBase}/i/children/events/messages/inbox`, config).then(response => {
    return response.data;
  });
}

export function getParentAllInboxMessages(user: AppUser, where?) {
  const { roleSessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios.get(`${window.apiBase}/i/children/events/messages/inbox`, config).then(response => {
    return response.data;
  });
}

export function getParentInboxMessagesCount(user: AppUser, where?): Promise<Count> {
  const { roleSessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where
          }
        }
      }
    };
  } else {
    config = {
      headers: { usid: key }
    };
  }

  return axios.get<Count>(`${window.apiBase}/i/children/events/messages/inbox/count`, config).then(response => {
    return response.data;
  });
}

export function acceptParentClubInvitation(user: AppUser, messageId, data) {
  const { roleSessionKey: key } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.post(`${window.apiBase}/i/children/clubs/messages/${messageId}/accept`, data, config).then(response => {
    return response.data;
  });
}

export function rejectParentClubInvitation(user: AppUser, messageId) {
  const { roleSessionKey: key } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.post(`${window.apiBase}/i/children/clubs/messages/${messageId}/reject`, {}, config).then(response => {
    return response.data;
  });
}

export function cancelRejectionOfParentClubInvitation(user: AppUser, messageId) {
  const { roleSessionKey: key } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.delete(`${window.apiBase}/i/children/clubs/messages/${messageId}/reject`, config).then(response => {
    return response.data;
  });
}

export function acceptParentConsentRequest(user: AppUser, messageId) {
  const { roleSessionKey: key } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.post(`${window.apiBase}/i/children/events/messages/${messageId}/accept`, {}, config).then(response => {
    return response.data;
  });
}

export function updateParentConsentRequest(user: AppUser, messageId, data) {
  const { roleSessionKey: key } = user;
  const config = {
    headers: { usid: key }
  };

  return axios.put(`${window.apiBase}/i/children/events/messages/${messageId}`, data, config).then(response => {
    return response.data;
  });
}

export function rejectParentConsentRequest(user: AppUser, messageId) {
  const { roleSessionKey: key } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.post(`${window.apiBase}/i/children/events/messages/${messageId}/reject`, {}, config).then(response => {
    return response.data;
  });
}

export function cancelRejectionOfParentConsentRequest(user: AppUser, messageId) {
  const { roleSessionKey: key } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.delete(`${window.apiBase}/i/children/events/messages/${messageId}/reject`, config).then(response => {
    return response.data;
  });
}

export function getParentalConsentRequest(user: AppUser, eventId) {
  const { roleSessionKey: key } = user;
  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          eventId: eventId,
          kind: MESSAGE_KIND.EventInvitationMessage
        },
        limit: DEFAULT_FILTER.limit,
        skip: DEFAULT_FILTER.skip
      }
    }
  };
  return axios.get(`${window.apiBase}/i/children/events/${eventId}/messages`, config).then(response => {
    return response.data;
  });
}

export function createPayment(user: AppUser, messageId, data) {
  const { roleSessionKey: key } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.post(`${window.apiBase}/i/children/clubs/messages/${messageId}/payment`, data, config).then(response => {
    return response.data;
  });
}

export function getAvailableClubSpaces(user: AppUser, messageId: string) {
  const { roleSessionKey: key } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .get(`${window.apiBase}/i/children/clubs/messages/${messageId}/participantAvailable`, config)
    .then(response => {
      return response.data;
    });
}

export function setMessageWaitingStatus(user: AppUser, messageId) {
  const { roleSessionKey: key } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.post(`${window.apiBase}/i/children/clubs/messages/${messageId}/waiting`, {}, config).then(response => {
    return response.data;
  });
}

export function sentAnswers(user: AppUser, messageId, data) {
  const { roleSessionKey: key } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.post(`${window.apiBase}/i/children/clubs/messages/${messageId}/answers`, data, config).then(response => {
    return response.data;
  });
}
