import { AppUser } from 'Src/views/App/App';
import axios from 'axios';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from 'Src/consts/table';
import { Count } from '../../../models/count';
import { TournamentTeam } from '../../../models/tournamentTeam';

const DEFAULT_TOURNAMENT_PARTICIPANT_FILTER: any = {
  isParticipant: true
};

export function getSchoolTournamentTeams(
  user: AppUser,
  tournamentId,
  filter = DEFAULT_TOURNAMENT_PARTICIPANT_FILTER
): Promise<TournamentTeam[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER,
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order
      }
    }
  };

  return axios
    .get<TournamentTeam[]>(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/teams`, config)
    .then(response => {
      return response.data;
    });
}

export function getAllSchoolTournamentTeams(user: AppUser, tournamentId, where?): Promise<TournamentTeam[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER,
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios
    .get<TournamentTeam[]>(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/teams`, config)
    .then(response => {
      return response.data;
    });
}

export function getSchoolTournamentTeamsCount(user: AppUser, tournamentId, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER,
            ...where
          }
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER
          }
        }
      }
    };
  }

  return axios
    .get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/teams/count`, config)
    .then(response => {
      return response.data;
    });
}

export function addSchoolEventTeam(user: AppUser, eventId: string, data) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/teams`, data, config)
    .then(response => {
      return response.data;
    });
}

export function addSchoolEventTeamPlayer(user: AppUser, eventId: string, teamId: string, data) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/teams/${teamId}/players`, data, config)
    .then(response => {
      return response.data;
    });
}

export function addSchoolEventIndividualPlayer(user: AppUser, eventId: string, data) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/individuals`, data, config)
    .then(response => {
      return response.data;
    });
}

export function removeSchoolEventTeamPlayer(user: AppUser, eventId: string, teamId: string, playerId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .delete(
      `${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/teams/${teamId}/players/${playerId}`,
      config
    )
    .then(response => {
      return response.data;
    });
}

export function removeSchoolEventIndividualPlayer(user: AppUser, eventId: string, playerId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .delete(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/individuals/${playerId}`, config)
    .then(response => {
      return response.data;
    });
}

export function addSchoolTournamentTeam(user: AppUser, tournamentId: string, data) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/teams`, data, config)
    .then(response => {
      return response.data;
    });
}

export function addTournamentTeamsBatch(user: AppUser, tournamentId: string, teamsData) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  const data = {
    teams: teamsData
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/teams/batch`, data, config)
    .then(response => {
      return response.data;
    });
}

export function editSchoolTournamentTeam(user: AppUser, tournamentId: string, teamId: string, data) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .put(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/teams/${teamId}`, data, config)
    .then(response => {
      return response.data;
    });
}

export function removeSchoolTournamentTeam(user: AppUser, tournamentId: string, teamId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .delete(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/teams/${teamId}`, config)
    .then(response => {
      return response.data;
    });
}
