import { DEFAULT_FILTER } from './user';
import { AppUser } from 'Src/views/App/App';
import axios from 'axios';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from 'Src/consts/table';
import { Count } from '../../../models/count';

/** GENERAL MESSAGE TABLE */
export function createSchoolGeneralMessage(user: AppUser, data) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key, 'notification-mode': 'MANUAL' }
  };

  return axios.post(`${window.apiBase}/i/schools/${activeSchoolId}/messages/general`, data, config).then(response => {
    return response.data;
  });
}

export function updateSchoolGeneralMessage(user: AppUser, data, messageId) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key, 'notification-mode': 'MANUAL' }
  };

  return axios
    .put(`${window.apiBase}/i/schools/${activeSchoolId}/messages/general/${messageId}`, data, config)
    .then(response => {
      return response.data;
    });
}

export function sendSchoolGeneralMessage(user: AppUser, generalMessageId) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/messages/general/${generalMessageId}/send`, {}, config)
    .then(response => {
      return response.data;
    });
}

export function scheduleSchoolGeneralMessage(user: AppUser, generalMessageId: string, scheduleAt: string) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .put(
      `${window.apiBase}/i/schools/${activeSchoolId}/messages/general/${generalMessageId}/schedule`,
      { scheduleAt: scheduleAt },
      config
    )
    .then(response => {
      return response.data;
    });
}

export function getGeneralMessageActionDescriptor(user: AppUser, generalMessageId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/messages/general/${generalMessageId}/schedule`, config)
    .then(response => {
      return response.data;
    });
}

export function getSchoolGeneralMessage(user: AppUser, generalMessageId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/messages/general/${generalMessageId}`, config)
    .then(response => {
      return response.data;
    });
}

export function getSchoolGeneralMessages(user: AppUser, filter = DEFAULT_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter
    }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/messages/general`, config).then(response => {
    return response.data;
  });
}

export function getAllSchoolGeneralMessages(user: AppUser, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/messages/general`, config).then(response => {
    return response.data;
  });
}

export function getSchoolGeneralMessagesCount(user: AppUser, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where
        }
      }
    };
  } else {
    config = {
      headers: { usid: key }
    };
  }

  return axios
    .get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/messages/general/count`, config)
    .then(response => {
      return response.data;
    });
}

export function getGeneralMessageNotifications(user: AppUser, messageId: string, filter = DEFAULT_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter
    }
  };

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/messages/general/${messageId}/notifications`, config)
    .then(response => {
      return response.data;
    });
}

export function getGeneralMessageNotificationsCount(user: AppUser, messageId: string, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where
        }
      }
    };
  } else {
    config = {
      headers: { usid: key }
    };
  }

  return axios
    .get<Count>(
      `${window.apiBase}/i/schools/${activeSchoolId}/messages/general/${messageId}/notifications/count`,
      config
    )
    .then(response => {
      return response.data;
    });
}

export function getAllGeneralMessageNotifications(user: AppUser, messageId: string, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where,
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/messages/general/${messageId}/notifications`, config)
    .then(response => {
      return response.data;
    });
}

export function deleteSchoolGeneralMessage(user: AppUser, generalMessageId) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .delete(`${window.apiBase}/i/schools/${activeSchoolId}/messages/general/${generalMessageId}`, config)
    .then(response => {
      return response.data;
    });
}
