import * as React from 'react';
import ReactQuill from 'react-quill';
import './HTMLEditorQuill.css';
import { FunctionComponent } from 'react';
import { HTML_EDITOR_QUILL_FORMATS, HTML_EDITOR_QUILL_MODULES } from './HTMLEditorConst';

interface Props {
  onEmailBodyChange: (editorState: any) => void;
  value: string;
  isEnabled: boolean;
}

export const HTMLEditorQuill: FunctionComponent<Props> = props => {
  const isEnabled = props.isEnabled;
  const editorClassName = isEnabled ? 'quillEditor-root' : 'quillEditor-root mDisabled';
  return (
    <div className={editorClassName}>
      <ReactQuill
        onChange={props.onEmailBodyChange}
        value={props.value}
        modules={HTML_EDITOR_QUILL_MODULES}
        formats={HTML_EDITOR_QUILL_FORMATS}
        readOnly={!isEnabled}
      />
    </div>
  );
};

HTMLEditorQuill.displayName = 'HTMLEditorQuill';
