import * as React from 'react';
import * as Lazy from 'lazy.js';
import { AppUser } from 'Src/views/App/App';
import * as Moment from 'moment';
import {
  ColumnDefinition,
  getOrder,
  getServerFieldSectionWhere,
  isFilterExist2,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { DATE_INTERVAL, DEFAULT_LIMIT, DEFAULT_SKIP, FILTER_TYPE, LIMIT } from 'Src/consts/table';
import { getAge, getGender } from 'Src/helpers/accessor/accessor';
import { getTwoPanelEditorFilters } from 'Src/helpers/twoPanelEditor/twoPanelEditor';
import * as BPromise from 'bluebird';
import * as propz from 'propz';
import { Loader } from 'Src/components/Loader/Loader';
import { TwoPanelEditor } from 'Src/components/TwoPanelEditor/TwoPanelEditor';
import { getAllForms } from 'Src/helpers/service/admin/forms';
import { getAllHouses } from 'Src/helpers/service/admin/houses';
import {
  getSelectOptionForAge,
  getSelectOptionForForms,
  getSelectOptionForGender,
  getSelectOptionForHouses
} from 'Src/helpers/table/select';
import { getUserGenderArrayConvertedFromEventGender } from 'Src/helpers/tournament/tournament';
import { Button } from 'Src/components/Button/Button';
import { TEAM_TYPE } from 'Src/consts/team';
import {
  getEventClash,
  getEventTitle,
  getSchoolEventAges,
  getStudentsMethod,
  isHousesEvent,
  isNonTeamSportEvent,
  isTeamOrTwoOnTwoSportEvent
} from 'Src/helpers/event/event';
import { SPORT_GENDER_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/common';
import { getEventType } from 'Src/helpers/accessor/accessor';
import { getEventStartDateAndTime } from 'Src/helpers/event/schoolEventViewHelper';
import { LabelWithQuestionIcon } from 'Src/components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import { SchoolEventFromForm } from '../SchoolEventManager';
import {
  getSchoolStudentsWithClashesCount,
  getSchoolStudentsWithoutClashes
} from 'Src/helpers/service/admin/schoolTeamPlayers';
import { AGE_GROUPS } from 'Src/consts/school';
import { getColorClashes } from 'Src/helpers/cell/cell';
import { SchoolStudentClash } from 'Src/models/schoolStudent';
import { CLASHES } from 'Src/consts/player';
import { SchoolForm } from 'Src/models/form';
import { SchoolHouse } from 'Src/models/house';
import { EventClash, SchoolEvent } from 'Src/models/event';
import { getSchoolEvent } from 'Src/helpers/service/admin/event';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';

interface Props {
  user: AppUser;
  currentEvent: SchoolEventFromForm;
  isSaveButtonDisabled: boolean;
  isAddTeamButtonDisabled: boolean;
  onAddExistingTeamClick: (team: any) => void;
  onAddNewTeamClick: (team: any) => void;
  onSaveClick: (team: any, isCreateAnotherEvent: boolean) => void;
  teams: any[];
  goBack: () => void;
}

interface State {
  items: Item[];
  itemsSelected: Item[];
  itemsCount: number;

  itemsFilters: any;
  isShowItemsFilter: boolean;
  isItemsFiltered: boolean;

  isSelectAllItemsChecked: boolean;

  sortItemsDirection: TABLE_SORT_DIRECTION;
  sortItemsColumnsName: string;

  itemCurrentPage: number;

  itemsAccumulatorInitial: Item[];
  itemsAccumulator: Item[];
  itemsAccumulatorSelected: Item[];
  itemsAccumulatorCount: number;

  itemsAccumulatorFilters: any;
  isShowItemsAccumulatorFilter: boolean;
  isItemsAccumulatorFiltered: boolean;

  isSelectAllItemsAccumulatorChecked: boolean;

  sortItemsAccumulatorDirection: TABLE_SORT_DIRECTION;
  sortItemsAccumulatorColumnsName: string;

  isLoading: boolean;
  isError: boolean;

  itemsAddBuffer: Item[];
  itemsRemoveBuffer: Item[];

  forms: SchoolForm[];
  houses: SchoolHouse[];

  teamName: string;
  houseId: string;

  studentEventsModalText: string;
  isStudentEventsModalOpen: boolean;
}

interface Item {
  id: string;
  permissionId: string;
  form: any;
  house: any;
  gender: string;
  firstName: string;
  lastName: string;
  clashes: {
    status: string;
    fullClashEvents: EventClash[];
    intervalClashEvents: EventClash[];
  };
  playerId?: string;
}

const ITEMS_ACCUMULATOR_COLUMNS: ColumnDefinition[] = [
  {
    text: 'Surname',
    field: 'lastName',
    accessor: ['lastName'],
    type: FILTER_TYPE.NONE,
    isSort: false
  },
  {
    text: 'Name',
    field: 'firstName',
    accessor: ['firstName'],
    type: FILTER_TYPE.NONE,
    isSort: false
  },
  {
    text: 'Form',
    field: 'forms',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: ['form', 'name']
  },
  {
    text: 'House',
    field: 'house',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: ['house', 'name']
  },
  {
    text: 'Age',
    field: 'ages',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getAge
  },
  {
    text: 'Gender',
    field: 'gender',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getGender
  }
];

const ITEMS_COLUMNS: ColumnDefinition[] = [
  {
    text: 'Surname',
    field: 'lastName',
    accessor: ['lastName'],
    type: FILTER_TYPE.TEXT,
    isSort: false
  },
  {
    text: 'Name',
    field: 'firstName',
    accessor: ['firstName'],
    type: FILTER_TYPE.TEXT,
    isSort: false
  },
  {
    text: 'Form',
    field: 'forms',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: ['form', 'name']
  },
  {
    text: 'House',
    field: 'houses',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: ['house', 'name']
  },
  {
    text: 'Age',
    field: 'ages',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getAge
  },
  {
    text: 'Gender',
    field: 'gender',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getGender
  }
];

const ITEMS_COLUMNS_WITH_CLASHES: ColumnDefinition[] = [
  {
    text: 'Clashes',
    field: 'clashes',
    cell: getColorClashes,
    type: FILTER_TYPE.NONE,
    isSort: false
  },
  {
    text: 'Surname',
    field: 'lastName',
    accessor: ['lastName'],
    type: FILTER_TYPE.TEXT,
    isSort: false
  },
  {
    text: 'Name',
    field: 'firstName',
    accessor: ['firstName'],
    type: FILTER_TYPE.TEXT,
    isSort: false
  },
  {
    text: 'Form',
    field: 'forms',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: ['form', 'name']
  },
  {
    text: 'House',
    field: 'houses',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: ['house', 'name']
  },
  {
    text: 'Age',
    field: 'ages',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getAge
  },
  {
    text: 'Gender',
    field: 'gender',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getGender
  }
];

const DEFAULT_TEAM_PLAYERS_ORDER = 'firstName ASC';

export class SchoolEventAddNewTeam extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { currentEvent, teams } = props;
    let houseId = '';
    let teamName = '';

    if (isHousesEvent(currentEvent)) {
      const { houses } = currentEvent;
      const housesFiltered = houses.filter(house => teams.every(team => team.houseId !== house.id));
      const [house] = housesFiltered;
      houseId = house.id;
      teamName = house.name;
    }

    this.state = {
      items: [],
      itemsCount: 0,
      itemsSelected: [],

      itemsFilters: {},
      isShowItemsFilter: false,
      isItemsFiltered: false,

      isSelectAllItemsChecked: false,

      sortItemsDirection: '',
      sortItemsColumnsName: '',

      itemCurrentPage: 1,

      itemsAccumulatorInitial: [],
      itemsAccumulator: [],
      itemsAccumulatorCount: 0,
      itemsAccumulatorSelected: [],

      itemsAccumulatorFilters: {},
      isShowItemsAccumulatorFilter: false,
      isItemsAccumulatorFiltered: false,

      isSelectAllItemsAccumulatorChecked: false,

      sortItemsAccumulatorDirection: '',
      sortItemsAccumulatorColumnsName: '',

      isLoading: false,
      isError: false,

      itemsAddBuffer: [],
      itemsRemoveBuffer: [],

      forms: [],
      houses: [],
      teamName: teamName,
      houseId: houseId,

      studentEventsModalText: '',
      isStudentEventsModalOpen: false
    };
  }

  getDefaultItemsFilter(houseId: string) {
    const { currentEvent } = this.props;
    const { gender, ages } = currentEvent;

    const genderArrayConverted = getUserGenderArrayConvertedFromEventGender(gender);

    return isHousesEvent(currentEvent)
      ? {
          firstName: '',
          lastName: '',
          gender: genderArrayConverted,
          forms: [],
          ages: ages,
          houses: [houseId]
        }
      : {
          firstName: '',
          lastName: '',
          gender: genderArrayConverted,
          forms: [],
          ages: ages
        };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    const { user } = this.props;
    const { houseId } = this.state;

    const itemsFilters = getTwoPanelEditorFilters(ITEMS_COLUMNS);
    const isShowItemsFilter = isFilterExist2(itemsFilters);
    const isItemsFiltered = isFilterExist2(itemsFilters);

    const itemsAccumulatorFilters = getTwoPanelEditorFilters(ITEMS_ACCUMULATOR_COLUMNS);
    const isShowItemsAccumulatorFilter = isFilterExist2(itemsAccumulatorFilters);
    const isItemsAccumulatorFiltered = isFilterExist2(itemsAccumulatorFilters);

    const formsPromise = getAllForms(user);
    const housesPromise = getAllHouses(user);

    BPromise.all([formsPromise, housesPromise])
      .then(([forms, houses]) => {
        const defaultItemsFilter = this.getDefaultItemsFilter(houseId);
        this.setState({
          forms: forms,
          houses: houses,
          itemsFilters: defaultItemsFilter
        });

        return this.getItemsAndItemsAccumulator();
      })
      .then(({ teamPlayersInitial, teamPlayers, teamPlayersCount, students, studentsCount }) => {
        const studentsNormalized = this.getStudentsNormalized(students);
        const teamPlayersNormalized = this.getTeamPlayersNormalized(teamPlayers);
        const teamPlayersInitialNormalized = this.getTeamPlayersNormalized(teamPlayersInitial);

        this.setState({
          items: studentsNormalized,
          itemsCount: studentsCount.count,
          isShowItemsFilter: isShowItemsFilter,
          isItemsFiltered: isItemsFiltered,

          itemsAccumulatorInitial: teamPlayersInitialNormalized,
          itemsAccumulator: teamPlayersNormalized,
          itemsAccumulatorCount: teamPlayersCount.count,
          itemsAccumulatorFilters: itemsAccumulatorFilters,
          isShowItemsAccumulatorFilter: isShowItemsAccumulatorFilter,
          isItemsAccumulatorFiltered: isItemsAccumulatorFiltered,

          isLoading: false
        });
      });
  }

  componentDidUpdate(prevProps) {
    const { teams } = this.props;
    const { teams: prevPropsTeams } = prevProps;
    if (teams.length !== prevPropsTeams.length) {
      this.setState({
        isLoading: true
      });

      const itemsFilters = getTwoPanelEditorFilters(ITEMS_COLUMNS);
      const isShowItemsFilter = isFilterExist2(itemsFilters);
      const isItemsFiltered = isFilterExist2(itemsFilters);

      const itemsAccumulatorFilters = getTwoPanelEditorFilters(ITEMS_ACCUMULATOR_COLUMNS);
      const isShowItemsAccumulatorFilter = isFilterExist2(itemsAccumulatorFilters);
      const isItemsAccumulatorFiltered = isFilterExist2(itemsAccumulatorFilters);

      this.getItemsAndItemsAccumulator().then(
        ({ teamPlayersInitial, teamPlayers, teamPlayersCount, students, studentsCount }) => {
          const studentsNormalized = this.getStudentsNormalized(students);
          const teamPlayersNormalized = this.getTeamPlayersNormalized(teamPlayers);
          const teamPlayersInitialNormalized = this.getTeamPlayersNormalized(teamPlayersInitial);

          this.setState({
            items: studentsNormalized,
            itemsCount: studentsCount.count,
            isShowItemsFilter: isShowItemsFilter,
            isItemsFiltered: isItemsFiltered,

            itemsAccumulatorInitial: teamPlayersInitialNormalized,
            itemsAccumulator: teamPlayersNormalized,
            itemsAccumulatorCount: teamPlayersCount.count,
            itemsAccumulatorFilters: itemsAccumulatorFilters,
            isShowItemsAccumulatorFilter: isShowItemsAccumulatorFilter,
            isItemsAccumulatorFiltered: isItemsAccumulatorFiltered,

            isLoading: false
          });
        }
      );
    }
  }

  getTeamPlayersNormalized(teamPlayers: any[]): Item[] {
    return teamPlayers.map(teamPlayer => {
      const { permissionId, form, gender, firstName, lastName, userId, id, house } = teamPlayer;
      return {
        permissionId,
        form,
        house,
        gender,
        firstName,
        lastName,
        id: userId,
        clashes: {
          status: CLASHES.NO_CLASH,
          fullClashEvents: [],
          intervalClashEvents: []
        },
        playerId: id
      };
    });
  }

  getStudentsNormalized(students: SchoolStudentClash[]): Item[] {
    return students.map(student => {
      const { permissionId, form, gender, firstName, lastName, id, house, clashes } = student;

      return {
        permissionId,
        form,
        house,
        gender,
        firstName,
        lastName,
        clashes,
        id
      };
    });
  }

  getItemsFilter(page, itemsAccumulator, itemsAddBuffer, isAllItems = false) {
    const { itemsFilters, forms, houseId } = this.state;
    const { teams, currentEvent } = this.props;
    const { startTime, endTime } = currentEvent;

    const {
      gender: itemsFiltersGender,
      ages: itemsFiltersAges = [],
      firstName: itemsFiltersFirstName = '',
      lastName: itemsFiltersLastName = '',
      forms: itemsFiltersForms = [],
      houses: itemsFiltersHouses = []
    } = itemsFilters;

    let where = {
      gender: {
        $in: itemsFiltersGender
      }
    };

    const playerUserIds = Lazy(teams)
      .map(team => team.players)
      .flatten()
      .map(player => ({ id: player.userId }))
      .toArray();

    const allItems = [...itemsAccumulator, ...itemsAddBuffer, ...playerUserIds];
    const ninIds = Lazy(allItems)
      .map(item => item.id)
      .uniq()
      .toArray();
    propz.set(where, ['_id', '$nin'], ninIds);

    if (itemsFiltersForms.length > 0) {
      propz.set(where, ['formId', '$in'], itemsFiltersForms);
    } else {
      const ages = itemsFiltersAges.length > 0 ? itemsFiltersAges : AGE_GROUPS.ENGLISH.map((age, index) => index);
      const formIds = forms.filter(form => ages.some(age => Number(form.age) === Number(age))).map(form => form.id);

      if (formIds.length > 0) {
        propz.set(where, ['formId', '$in'], formIds);
      }
    }

    if (isHousesEvent(currentEvent)) {
      propz.set(where, ['houseId'], houseId);
    }

    if (itemsFiltersHouses.length > 0) {
      propz.set(where, ['houseId', '$in'], itemsFiltersHouses);
    }

    let and = [];

    if (itemsFiltersFirstName.length > 0) {
      and.push({ firstName: { like: itemsFiltersFirstName, options: 'i' } });
    }

    if (itemsFiltersLastName.length > 0) {
      and.push({ lastName: { like: itemsFiltersLastName, options: 'i' } });
    }

    if (and.length > 0) {
      propz.set(where, ['$and'], and);
    }

    // copy-paste from old frontend
    const startTimeCopy = new Date(startTime);
    startTimeCopy.setHours(startTime.getHours() - 1);
    const intervalClashStartDate = Moment(startTimeCopy)
      .utc()
      .format();

    const endTimeCopy = new Date(endTime);
    endTimeCopy.setHours(endTime.getHours() + 1);
    const intervalClashEndDate = Moment(endTimeCopy)
      .utc()
      .format();

    propz.set(where, ['intervalClashStartDate'], intervalClashStartDate);
    propz.set(where, ['intervalClashEndDate'], intervalClashEndDate);
    propz.set(where, ['fullClashStartDate'], startTime);
    propz.set(where, ['fullClashEndDate'], endTime);

    const filter = {
      where: where,
      limit: isAllItems ? DEFAULT_LIMIT : LIMIT,
      skip: isAllItems ? 0 : (page - 1) * LIMIT,
      order: DEFAULT_TEAM_PLAYERS_ORDER
    };

    return filter;
  }

  getItemsAndItemsAccumulator() {
    const { user } = this.props;

    const filter = this.getItemsFilter(1, [], []);

    const method = getStudentsMethod(user);
    const getStudentsPromise = method(user, filter);
    const getStudentsCountPromise = getSchoolStudentsWithClashesCount(user, filter);

    return BPromise.all([getStudentsPromise, getStudentsCountPromise]).then(([students, studentsCount]) => {
      return {
        teamPlayersInitial: [],
        teamPlayers: [],
        teamPlayersCount: { count: 0 },
        students,
        studentsCount
      };
    });
  }

  onItemClick = (index: number): void => {
    const { items, itemsSelected } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = itemsSelected.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...itemsSelected];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      itemsAccumulatorSelected: [],
      itemsSelected: selectedItemsUpdated
    });
  };

  onItemAccumulatorClick = (index: number): void => {
    const { itemsAccumulator, itemsAccumulatorSelected } = this.state;
    const selectedItem = itemsAccumulator[index];

    const selectedItemIndex = itemsAccumulatorSelected.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...itemsAccumulatorSelected];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      itemsAccumulatorSelected: selectedItemsUpdated,
      itemsSelected: []
    });
  };

  onAddClick = () => {
    const { itemsSelected, itemsAddBuffer, itemsAccumulatorInitial, itemCurrentPage } = this.state;
    const { user } = this.props;

    const nextItemsAddBuffer = [...itemsAddBuffer, ...itemsSelected];

    this.setState({
      isLoading: true
    });

    const filter = this.getItemsFilter(itemCurrentPage, itemsAccumulatorInitial, nextItemsAddBuffer);
    const method = getStudentsMethod(user);
    const getStudentsPromise = method(user, filter);
    const getStudentsCountPromise = getSchoolStudentsWithClashesCount(user, filter);

    BPromise.all([getStudentsPromise, getStudentsCountPromise]).then(([students, studentsCount]) => {
      const studentsNormalized = this.getStudentsNormalized(students);

      this.setState({
        items: studentsNormalized,
        isLoading: false,
        itemsCount: studentsCount.count,
        itemsAddBuffer: nextItemsAddBuffer,
        itemsAccumulatorSelected: [],
        itemsSelected: [],
        isSelectAllItemsAccumulatorChecked: false,
        isSelectAllItemsChecked: false
      });
    });
  };

  onRemoveClick = () => {
    const {
      itemsAccumulatorSelected,
      itemsAddBuffer,
      itemsRemoveBuffer,
      itemsAccumulatorInitial,
      itemsAccumulator,
      itemCurrentPage
    } = this.state;
    const { user } = this.props;

    const nextItemsRemoveBuffer = [...itemsAccumulatorSelected, ...itemsRemoveBuffer];
    const nextItemsAccumulatorInitial = itemsAccumulatorInitial.filter(itemAccumulatorInitial =>
      nextItemsRemoveBuffer.every(nextItemRemoveBuffer => nextItemRemoveBuffer.id !== itemAccumulatorInitial.id)
    );
    const nextItemsAccumulator = itemsAccumulator.filter(itemAccumulator =>
      nextItemsRemoveBuffer.every(nextItemRemoveBuffer => nextItemRemoveBuffer.id !== itemAccumulator.id)
    );

    this.setState({
      isLoading: true
    });

    const filter = this.getItemsFilter(itemCurrentPage, nextItemsAccumulatorInitial, itemsAddBuffer);
    const method = getStudentsMethod(user);
    const getStudentsPromise = method(user, filter);
    const getStudentsCountPromise = getSchoolStudentsWithClashesCount(user, filter);

    BPromise.all([getStudentsPromise, getStudentsCountPromise]).then(([students, studentsCount]) => {
      const studentsNormalized = this.getStudentsNormalized(students);

      this.setState({
        items: studentsNormalized,
        isLoading: false,
        itemsCount: studentsCount.count,
        itemsAccumulatorSelected: [],
        itemsSelected: [],
        isSelectAllItemsAccumulatorChecked: false,
        isSelectAllItemsChecked: false,
        itemsRemoveBuffer: nextItemsRemoveBuffer,
        itemsAccumulatorInitial: nextItemsAccumulatorInitial,
        itemsAccumulator: nextItemsAccumulator,
        itemsAccumulatorCount: nextItemsAccumulator.length
      });
    });
  };

  onItemsFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.itemsFilters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (filterField === 'forms') {
      nextFilters.ages = [];
    }

    if (filterField === 'ages') {
      nextFilters.forms = [];
    }

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = ITEMS_COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      itemsFilters: nextFilters
    });
  };

  onItemsAccumulatorFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.itemsAccumulatorFilters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = ITEMS_COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      itemsAccumulatorFilters: nextFilters
    });
  };

  onItemsFilterClick = (event): void => {
    event.preventDefault();

    const { isShowItemsFilter } = this.state;

    this.setState({
      isShowItemsFilter: !isShowItemsFilter
    });
  };

  onItemsAccumulatorFilterClick = (event): void => {
    event.preventDefault();

    const { isShowItemsAccumulatorFilter } = this.state;

    this.setState({
      isShowItemsAccumulatorFilter: !isShowItemsAccumulatorFilter
    });
  };

  itemsSetCurrentPageParams = (currentPage: number): void => {
    const { user } = this.props;
    const { itemsAccumulator, itemsAddBuffer } = this.state;

    this.setState({
      isLoading: true
    });

    const filter = this.getItemsFilter(currentPage, itemsAccumulator, itemsAddBuffer);
    const method = getStudentsMethod(user);

    method(user, filter).then(students => {
      const studentsNormalized = this.getStudentsNormalized(students);

      this.setState({
        itemCurrentPage: currentPage,
        items: studentsNormalized,
        isLoading: false
      });
    });
  };

  itemsOnApplyFilterClick = () => {
    const { itemsAccumulator, itemsAddBuffer } = this.state;

    this.setState({
      isLoading: true
    });

    const filter = this.getItemsFilter(1, itemsAccumulator, itemsAddBuffer);

    const { user } = this.props;

    const method = getStudentsMethod(user);
    const getStudentsPromise = method(user, filter);
    const getStudentCountPromise = getSchoolStudentsWithClashesCount(user, filter);

    BPromise.all([getStudentsPromise, getStudentCountPromise]).then(([students, studentsCount]) => {
      const studentsNormalized = this.getStudentsNormalized(students);

      this.setState({
        items: studentsNormalized,
        isLoading: false,
        itemsCount: studentsCount.count,
        itemsAccumulatorSelected: [],
        itemsSelected: [],
        itemCurrentPage: 1,
        sortItemsDirection: '',
        sortItemsColumnsName: ''
      });
    });
  };

  itemsAccumulatorOnApplyFilterClick = () => {
    const { itemsAccumulatorFilters, itemsRemoveBuffer } = this.state;
    this.setState({
      isLoading: true
    });

    let where = getServerFieldSectionWhere(itemsAccumulatorFilters);
    const ninIds = itemsRemoveBuffer.map(item => item.id);
    propz.set(where, ['id', '$nin'], ninIds);

    this.setState({
      itemsAccumulator: [],
      isLoading: false,
      itemsAccumulatorCount: 0,
      itemsAccumulatorSelected: [],
      itemsSelected: []
    });
  };

  itemsOnClearFilterClick = () => {
    const { itemsAccumulatorInitial, itemsAddBuffer, forms, houseId } = this.state;
    const { teams, currentEvent } = this.props;

    const defaultItemsFilter = this.getDefaultItemsFilter(houseId);

    this.setState({
      isLoading: true,
      itemsFilters: defaultItemsFilter
    });

    const { user } = this.props;

    const {
      gender: itemsFiltersGender,
      ages: itemsFiltersAges = [],
      firstName: itemsFiltersFirstName = '',
      lastName: itemsFiltersLastName = ''
    } = defaultItemsFilter;

    let where = {
      gender: {
        $in: itemsFiltersGender
      }
    };

    const playerUserIds = Lazy(teams)
      .map(team => team.players)
      .flatten()
      .map(player => ({ id: player.userId }))
      .toArray();

    const allItems = [...itemsAccumulatorInitial, ...itemsAddBuffer, ...playerUserIds];
    const ninIds = Lazy(allItems)
      .map(item => item.id)
      .uniq()
      .toArray();
    propz.set(where, ['_id', '$nin'], ninIds);

    const ages = itemsFiltersAges.length > 0 ? itemsFiltersAges : AGE_GROUPS.ENGLISH.map((age, index) => index);
    const formIds = forms.filter(form => ages.some(age => Number(form.age) === Number(age))).map(form => form.id);

    if (formIds.length > 0) {
      propz.set(where, ['formId', '$in'], formIds);
    }

    if (isHousesEvent(currentEvent)) {
      propz.set(where, ['houseId'], houseId);
    }

    let and = [];

    if (itemsFiltersFirstName.length > 0) {
      and.push({ firstName: { like: itemsFiltersFirstName, options: 'i' } });
    }

    if (itemsFiltersLastName.length > 0) {
      and.push({ lastName: { like: itemsFiltersLastName, options: 'i' } });
    }

    if (and.length > 0) {
      propz.set(where, ['$and'], and);
    }

    const { startTime, endTime } = currentEvent;

    // copy-paste from old frontend
    const startTimeCopy = new Date(startTime);
    startTimeCopy.setHours(startTime.getHours() - 1);
    const intervalClashStartDate = Moment(startTimeCopy)
      .utc()
      .format();

    const endTimeCopy = new Date(endTime);
    endTimeCopy.setHours(endTime.getHours() + 1);
    const intervalClashEndDate = Moment(endTimeCopy)
      .utc()
      .format();

    propz.set(where, ['intervalClashStartDate'], intervalClashStartDate);
    propz.set(where, ['intervalClashEndDate'], intervalClashEndDate);
    propz.set(where, ['fullClashStartDate'], startTime);
    propz.set(where, ['fullClashEndDate'], endTime);

    const filter = {
      where: where,
      limit: LIMIT,
      skip: DEFAULT_SKIP,
      order: DEFAULT_TEAM_PLAYERS_ORDER
    };

    const method = getStudentsMethod(user);
    const getStudentsPromise = method(user, filter);
    const getSchoolsCountPromise = getSchoolStudentsWithClashesCount(user, filter);

    BPromise.all([getStudentsPromise, getSchoolsCountPromise]).then(([students, studentsCount]) => {
      const studentsNormalized = this.getStudentsNormalized(students);

      this.setState({
        items: studentsNormalized,
        isLoading: false,
        itemsCount: studentsCount.count,
        itemsAccumulatorSelected: [],
        itemsSelected: [],
        itemCurrentPage: 1,
        sortItemsColumnsName: '',
        sortItemsDirection: ''
      });
    });
  };

  itemsAccumulatorOnClearFilterClick = () => {
    this.setState({
      itemsAccumulator: [],
      itemsAccumulatorCount: 0,
      itemsAccumulatorSelected: [],
      itemsSelected: [],
      itemsAccumulatorFilters: {}
    });
  };

  onSelectAllItemsClick = () => {
    const { itemsSelected, itemsAccumulator, itemsAddBuffer, itemCurrentPage } = this.state;

    this.setState({
      isLoading: true
    });

    const { user } = this.props;

    const filter = this.getItemsFilter(itemCurrentPage, itemsAccumulator, itemsAddBuffer, true);

    getSchoolStudentsWithoutClashes(user, filter).then(items => {
      const itemsFiltered = items.filter(item => {
        return itemsSelected.every(selectedItem => selectedItem.id !== item.id);
      });

      const studentsNormalizedFiltered = this.getStudentsNormalized(itemsFiltered);

      const nextSelectedItems = [...itemsSelected, ...studentsNormalizedFiltered];

      this.setState({
        itemsSelected: nextSelectedItems,
        isSelectAllItemsChecked: true,
        itemsAccumulatorSelected: [],
        isSelectAllItemsAccumulatorChecked: false,
        isLoading: false
      });
    });
  };

  onSelectAllItemsOnPageClick = () => {
    const { items, itemsSelected } = this.state;

    const itemsFiltered = items.filter(item => {
      return itemsSelected.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...itemsSelected, ...itemsFiltered];

    this.setState({
      itemsSelected: nextSelectedItems,
      isSelectAllItemsChecked: true,
      itemsAccumulatorSelected: [],
      isSelectAllItemsAccumulatorChecked: false
    });
  };

  onUnselectAllItemsClick = () => {
    this.setState({
      itemsSelected: [],
      isSelectAllItemsChecked: false,
      itemsAccumulatorSelected: [],
      isSelectAllItemsAccumulatorChecked: false
    });
  };

  onSelectAllItemsAccumulatorClick = () => {
    this.setState({
      isSelectAllItemsAccumulatorChecked: true,
      itemsSelected: [],
      isSelectAllItemsChecked: false,
      isLoading: false
    });
  };

  onSelectAllItemsAccumulatorOnPageClick = () => {
    const { itemsAccumulator, itemsAccumulatorSelected } = this.state;

    const itemsFiltered = itemsAccumulator.filter(item => {
      return itemsAccumulatorSelected.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...itemsAccumulatorSelected, ...itemsFiltered];

    this.setState({
      itemsAccumulatorSelected: nextSelectedItems,
      isSelectAllItemsAccumulatorChecked: true,
      itemsSelected: [],
      isSelectAllItemsChecked: false
    });
  };

  onUnselectAllItemsAccumulatorClick = () => {
    this.setState({
      itemsAccumulatorSelected: [],
      isSelectAllItemsAccumulatorChecked: false,
      itemsSelected: [],
      isSelectAllItemsChecked: false
    });
  };

  onSortItemsClick = (sortField: string) => {
    const { sortItemsDirection, sortItemsColumnsName, itemCurrentPage, itemsAccumulator, itemsAddBuffer } = this.state;

    const order = getOrder(sortField, sortItemsDirection, sortItemsColumnsName) as TABLE_SORT_DIRECTION;

    const filter = this.getItemsFilter(itemCurrentPage, itemsAccumulator, itemsAddBuffer);
    const { user } = this.props;

    const method = getStudentsMethod(user);
    const getStudentsPromise = method(user, filter);
    const getSchoolsCountPromise = getSchoolStudentsWithClashesCount(user, filter);

    BPromise.all([getStudentsPromise, getSchoolsCountPromise]).then(([students, studentsCount]) => {
      const studentsNormalized = this.getStudentsNormalized(students);

      this.setState({
        items: studentsNormalized,
        isLoading: false,
        itemsCount: studentsCount.count,
        itemsAccumulatorSelected: [],
        itemsSelected: [],
        itemCurrentPage: 1,
        sortItemsColumnsName: sortField,
        sortItemsDirection: order
      });
    });
  };

  onSortItemsAccumulatorClick = (sortField: string) => {};

  onAddExistingTeamButtonClick = () => {
    const { onAddExistingTeamClick, currentEvent } = this.props;
    const { teamName } = this.state;
    const team = this.getTeam();

    if (teamName || isNonTeamSportEvent(currentEvent)) {
      onAddExistingTeamClick(team);
    } else {
      this.setState({
        isError: true
      });
    }
  };

  onAddNewTeamButtonClick = () => {
    const { onAddNewTeamClick, currentEvent, teams, user } = this.props;
    const { teamName } = this.state;
    const team = this.getTeam();

    if (teamName || isNonTeamSportEvent(currentEvent)) {
      let houseId = '';
      let teamName = '';

      if (isHousesEvent(currentEvent)) {
        const { houses } = currentEvent;
        const allTeams = [...teams, team];
        const housesFiltered = houses.filter(house => allTeams.every(team => team.houseId !== house.id));
        const [house] = housesFiltered;
        houseId = house.id;
        teamName = house.name;
      }

      const defaultItemsFilter = this.getDefaultItemsFilter(houseId);

      this.setState({
        items: [],
        itemsCount: 0,
        itemsSelected: [],

        itemsFilters: defaultItemsFilter,
        isShowItemsFilter: false,
        isItemsFiltered: false,

        isSelectAllItemsChecked: false,

        sortItemsDirection: '',
        sortItemsColumnsName: '',

        itemCurrentPage: 1,

        itemsAccumulatorInitial: [],
        itemsAccumulator: [],
        itemsAccumulatorCount: 0,
        itemsAccumulatorSelected: [],

        itemsAccumulatorFilters: {},
        isShowItemsAccumulatorFilter: false,
        isItemsAccumulatorFiltered: false,

        isSelectAllItemsAccumulatorChecked: false,

        sortItemsAccumulatorDirection: '',
        sortItemsAccumulatorColumnsName: '',

        isLoading: true,
        isError: false,

        itemsAddBuffer: [],
        itemsRemoveBuffer: [],

        teamName: teamName,
        houseId: houseId
      });

      onAddNewTeamClick(team);
    } else {
      this.setState({
        isError: true
      });
    }
  };

  getTeam() {
    const { currentEvent, user } = this.props;
    const { teamName, itemsAddBuffer, houseId } = this.state;
    const { activeSchoolId } = user;
    const { ages, gender, sportId } = currentEvent;

    return isHousesEvent(currentEvent)
      ? {
          ages: ages.map(age => Number(age)),
          gender: gender,
          name: teamName,
          players: itemsAddBuffer.map(item => ({
            userId: item.id,
            permissionId: item.permissionId
          })),
          schoolId: activeSchoolId,
          sportId: sportId,
          teamType: TEAM_TYPE.ADHOC,
          houseId: houseId
        }
      : {
          ages: ages.map(age => Number(age)),
          gender: gender,
          name: teamName,
          players: itemsAddBuffer.map(item => ({
            userId: item.id,
            permissionId: item.permissionId
          })),
          schoolId: activeSchoolId,
          sportId: sportId,
          teamType: TEAM_TYPE.ADHOC
        };
  }

  onSaveButtonClick = isCreateAnotherEvent => {
    const { onSaveClick, currentEvent } = this.props;
    const { teamName } = this.state;

    const team = this.getTeam();

    if (teamName || isNonTeamSportEvent(currentEvent)) {
      onSaveClick(team, isCreateAnotherEvent);
    } else {
      this.setState({
        isError: true
      });
    }
  };

  onChangeTeamName = event => {
    const teamName = event.target.value;

    this.setState({
      teamName
    });
  };

  //maybe useful later
  // onChangeHouse = event => {
  //   const houseId = event.target.value;
  //
  //   const { itemsAccumulatorInitial, itemsAddBuffer, forms } = this.state;
  //
  //   const defaultItemsFilter = this.getDefaultItemsFilter(houseId);
  //
  //   this.setState({
  //     isLoading: true,
  //     itemsFilters: defaultItemsFilter
  //   });
  //
  //   const options = { forms };
  //
  //   let where = getServerFieldSectionWhere(defaultItemsFilter, options);
  //   const allItems = [...itemsAccumulatorInitial, ...itemsAddBuffer];
  //   const ninIds = allItems.map(item => item.id);
  //   propz.set(where, ['id', '$nin'], ninIds);
  //
  //   const filter = {
  //     where: where,
  //     limit: LIMIT,
  //     skip: DEFAULT_SKIP,
  //     order: DEFAULT_TEAM_PLAYERS_ORDER
  //   };
  //
  //   const { user } = this.props;
  //
  //   const getStudentsPromise = getSchoolStudents(user, filter);
  //   const getSchoolsCountPromise = getSchoolStudentsCount(user, filter.where);
  //
  //   const promiseItems = [getStudentsPromise, getSchoolsCountPromise];
  //
  //   BPromise.all(promiseItems).then(([students, studentsCount]) => {
  //     const studentsNormalized = this.getStudentsNormalized(students);
  //
  //     this.setState({
  //       items: studentsNormalized,
  //       isLoading: false,
  //       itemsCount: studentsCount.count,
  //       itemsAccumulatorSelected: [],
  //       itemsSelected: [],
  //       itemCurrentPage: 1,
  //       sortItemsColumnsName: '',
  //       sortItemsDirection: '',
  //       houseId: houseId
  //     });
  //   });
  // };

  onClashClick = (student: SchoolStudentClash) => {
    const { user } = this.props;
    const { clashes, firstName, lastName } = student;
    const { fullClashEvents, intervalClashEvents } = clashes;
    const fullClashEventIds = fullClashEvents.map(fullClashEvent => fullClashEvent._id);
    const intervalClashEventIds = intervalClashEvents.map(intervalClashEvent => intervalClashEvent._id);

    const allPlayerEventIds = [...fullClashEventIds, ...intervalClashEventIds];
    const allPlayerEventIdsUniq = Lazy(allPlayerEventIds)
      .uniq()
      .toArray();

    this.setState({
      isLoading: true
    });

    const eventsPromise: Promise<SchoolEvent>[] = allPlayerEventIdsUniq.map(eventId => getSchoolEvent(user, eventId));

    BPromise.all(eventsPromise).then(events => {
      const eventNames = events
        .map((event, index) => {
          const title = getEventTitle(event, user);
          const clash = getEventClash(event, fullClashEventIds, intervalClashEventIds);
          return `${index + 1}. ${title} ${clash}`;
        })
        .join('\n ');

      this.setState({
        isLoading: false,
        studentEventsModalText: `${firstName} ${lastName} is participating in the following events: \n${eventNames}`,
        isStudentEventsModalOpen: true
      });
    });
  };

  onCloseStudentEventsModalClick = () => {
    this.setState({
      isStudentEventsModalOpen: false
    });
  };

  renderStudentEventsModal(): React.ReactNode {
    const { isStudentEventsModalOpen, studentEventsModalText } = this.state;
    return (
      <SimpleModal
        isOpen={isStudentEventsModalOpen}
        title={'Info'}
        body={studentEventsModalText}
        buttonText={'Ok'}
        onButtonClick={this.onCloseStudentEventsModalClick}
      />
    );
  }

  render() {
    const {
      items,
      isLoading,
      itemsSelected,
      itemsAccumulatorSelected,
      itemsAccumulator,
      itemsFilters,
      isShowItemsFilter,
      isItemsFiltered,
      itemsCount,
      itemsAccumulatorCount,
      itemCurrentPage,
      itemsAccumulatorFilters,
      isShowItemsAccumulatorFilter,
      isItemsAccumulatorFiltered,
      isSelectAllItemsChecked,
      isSelectAllItemsAccumulatorChecked,
      sortItemsDirection,
      sortItemsColumnsName,
      sortItemsAccumulatorDirection,
      sortItemsAccumulatorColumnsName,
      itemsAddBuffer,
      itemsRemoveBuffer,
      forms,
      houses,
      isError,
      teamName,
      houseId,
      isStudentEventsModalOpen
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const { user, isSaveButtonDisabled, isAddTeamButtonDisabled, currentEvent, teams, goBack } = this.props;
    const { activeSchool } = user;
    const { ageGroupsNaming, isShowStudentEventClashes } = activeSchool;
    const { houses: eventHouses } = currentEvent;

    const eventStartDateAndTimeFormatted = getEventStartDateAndTime(currentEvent);
    const eventSportName = propz.get(currentEvent, ['sport', 'name'], '');
    const eventGender = SPORT_GENDER_SERVER_TO_CLIENT_MAPPING[currentEvent.gender];
    const eventAges = getSchoolEventAges(currentEvent, user);
    const eventStatus = getEventType(currentEvent);

    const itemsFiltersOptions = {
      ages: getSelectOptionForAge(forms, ageGroupsNaming),
      gender: getSelectOptionForGender(),
      forms: getSelectOptionForForms(forms),
      houses: getSelectOptionForHouses(houses)
    };

    const itemsAccumulatorFiltersOptions = {};

    const classes = isStudentEventsModalOpen ? 'mt-3 modal-open' : 'mt-3';

    return (
      <div className={classes}>
        {this.renderStudentEventsModal()}
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="mt-3 bEventInformation">
                <div>{eventStartDateAndTimeFormatted}</div>
                <LabelWithQuestionIcon
                  labelText={`${eventSportName} ${eventGender} ${eventAges} ${eventStatus}`}
                  hintText={
                    'Here you can see basic event settings as you set them up within the previous step. ' +
                    'If you would like to correct something you can select the "GO BACK" option.'
                  }
                  customClass=""
                />
              </div>
              <LabelWithQuestionIcon
                labelText="Within this section you can add participants to the event"
                hintText={
                  'The students in this section will match the criteria you specified in your event creation ' +
                  '(Age, Gender and House for Inter-House events). Select the students from the right hand side and move ' +
                  'them to the left by clicking the "<<" button at the bottom of the list. You can search the students' +
                  ' using the filter panel on the right hand side. Once you have moved students into your team, they are ' +
                  'highlighted yellow which means the changes have not yet been saved, to apply changes you need to click ' +
                  'the "SAVE" button, you are able to edit team members after the event. If you are not sure who will ' +
                  'be involved in the event, you can add a Team name and save without adding any players.'
                }
                customClass="mt-3"
              />
              {isHousesEvent(currentEvent) && (
                <div className="form-group mb-3 mt-3">
                  <LabelWithQuestionIcon labelText="House name:" hintText="" customClass="mt-3" />
                  <select className="form-control w-25" disabled value={houseId}>
                    {eventHouses
                      .filter(house => teams.every(team => team.houseId !== house.id))
                      .map(house => (
                        <option key={house.id} value={house.id}>
                          {house.name}
                        </option>
                      ))}
                  </select>
                </div>
              )}
              {isTeamOrTwoOnTwoSportEvent(currentEvent) && (
                <div className="form-group mb-3 mt-3">
                  <LabelWithQuestionIcon
                    labelText="Team name:"
                    hintText={
                      'You must add a team name before you can move on to the next section,' +
                      ' NB; you do not need to add age group to the team name as this will be specified' +
                      ' in the description of the event automatically'
                    }
                    customClass="mt-3"
                  />
                  <input
                    className="form-control eAutocompleteInput w-25"
                    type={'text'}
                    placeholder={'Enter the team name'}
                    onChange={this.onChangeTeamName}
                    value={teamName}
                  />
                  {isError && <div className="mt-3 alert alert-danger">Enter team name</div>}
                </div>
              )}
            </div>
          </div>
        </div>

        <TwoPanelEditor
          //items
          items={items}
          itemsTitle={'Eligible students'}
          itemsColumns={isShowStudentEventClashes ? ITEMS_COLUMNS_WITH_CLASHES : ITEMS_COLUMNS}
          itemsSelected={itemsSelected}
          onItemClick={this.onItemClick}
          //items filters
          onItemsFilterChange={this.onItemsFilterChange}
          itemsFilters={itemsFilters}
          itemsFiltersOptions={itemsFiltersOptions}
          isShowItemsFilter={isShowItemsFilter}
          isItemsFiltered={isItemsFiltered}
          onItemsFilterClick={this.onItemsFilterClick}
          //accumulator
          itemsAccumulator={itemsAccumulator}
          itemsAccumulatorTitle={'Participants'}
          itemsAccumulatorColumns={ITEMS_ACCUMULATOR_COLUMNS}
          onItemAccumulatorClick={this.onItemAccumulatorClick}
          itemsAccumulatorSelected={itemsAccumulatorSelected}
          //accumulator filters
          onItemsAccumulatorFilterChange={this.onItemsAccumulatorFilterChange}
          itemsAccumulatorFilters={itemsAccumulatorFilters}
          itemsAccumulatorFiltersOptions={itemsAccumulatorFiltersOptions}
          isShowItemsAccumulatorFilter={isShowItemsAccumulatorFilter}
          isItemsAccumulatorFiltered={isItemsAccumulatorFiltered}
          onItemsAccumulatorFilterClick={this.onItemsAccumulatorFilterClick}
          //buttons
          onAddClick={this.onAddClick}
          onRemoveClick={this.onRemoveClick}
          //counts
          itemsCount={itemsCount}
          itemsAccumulatorCount={itemsAccumulatorCount}
          //current pages
          itemAccumulatorCurrentPage={1}
          itemCurrentPage={itemCurrentPage}
          itemsSetCurrentPageParams={this.itemsSetCurrentPageParams}
          //items filter buttons
          itemsOnApplyFilterClick={this.itemsOnApplyFilterClick}
          itemsOnClearFilterClick={this.itemsOnClearFilterClick}
          //items accumulator filter buttons
          itemsAccumulatorOnApplyFilterClick={this.itemsAccumulatorOnApplyFilterClick}
          itemsAccumulatorOnClearFilterClick={this.itemsAccumulatorOnClearFilterClick}
          //items select checkbox
          onSelectAllItemsClick={this.onSelectAllItemsClick}
          onSelectAllItemsOnPageClick={this.onSelectAllItemsOnPageClick}
          onUnselectAllItemsClick={this.onUnselectAllItemsClick}
          isSelectAllItemsChecked={isSelectAllItemsChecked}
          //items accumulator select checkbox
          onSelectAllItemsAccumulatorClick={this.onSelectAllItemsAccumulatorClick}
          onSelectAllItemsAccumulatorOnPageClick={this.onSelectAllItemsAccumulatorOnPageClick}
          onUnselectAllItemsAccumulatorClick={this.onUnselectAllItemsAccumulatorClick}
          isSelectAllItemsAccumulatorChecked={isSelectAllItemsAccumulatorChecked}
          //items sort
          sortItemsDirection={sortItemsDirection}
          sortItemsColumnsName={sortItemsColumnsName}
          onSortItemsClick={this.onSortItemsClick}
          //items accumulator sort
          sortItemsAccumulatorDirection={sortItemsAccumulatorDirection}
          sortItemsAccumulatorColumnsName={sortItemsAccumulatorColumnsName}
          onSortItemsAccumulatorClick={this.onSortItemsAccumulatorClick}
          itemsAddBuffer={itemsAddBuffer}
          itemsRemoveBuffer={itemsRemoveBuffer}
          onCellItemsClick={this.onClashClick}
        />
        <div className="container-fluid mt-3">
          <div className="row">
            <div className="col-md-12">
              <div>
                The list of available options you can continue with depends on your event settings. For events allowing
                this, you can add more teams either from your list of pre-created teams or through adding a team from
                scratch. If the "SAVE & ADD ANOTHER EXISTING TEAM" AND "SAVE & ANOTHER NEW TEAM" options are greyed out
                then it means you have limited the number of teams that can be included within the event, e.g you have
                not selected 'Multiple teams available' when creating the event details.
              </div>
              <Button onClick={goBack} text={'Go back'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
              <Button
                onClick={this.onAddExistingTeamButtonClick}
                text={'SAVE & ADD ANOTHER EXISTING TEAM'}
                customClass={'mt-3 mb-3 mr-3 btn btn-secondary'}
                disabled={isAddTeamButtonDisabled || isNonTeamSportEvent(currentEvent)}
              />

              <Button
                onClick={this.onAddNewTeamButtonClick}
                text={'SAVE & ADD ANOTHER NEW TEAM'}
                customClass={'mt-3 mb-3 mr-3 btn btn-secondary'}
                disabled={isAddTeamButtonDisabled}
              />

              <Button
                onClick={() => this.onSaveButtonClick(true)}
                text={'SAVE & ADD ANOTHER EVENT'}
                customClass={'mt-3 mb-3 mr-3 btn btn-secondary'}
                disabled={isSaveButtonDisabled}
              />

              <Button
                disabled={isSaveButtonDisabled}
                onClick={() => this.onSaveButtonClick(false)}
                text={'CREATE EVENT'}
                customClass={'mt-3 mb-3 mr-3 btn btn-primary'}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
