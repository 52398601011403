import * as React from 'react';
import { FunctionComponent } from 'react';
import { Tournament } from '../../models/tournament';
import { TournamentCustomGroupsSummary } from '../TournamentCustomGroupsSummary/TournamentCustomGroupsSummary';

interface Props {
  tournament: Tournament;
}

export const TournamentPitchesSummary: FunctionComponent<Props> = props => {
  const { tournament } = props;
  const pitches = tournament.pitches || [];

  const rows = pitches.map((pitch, index) => (
    <tr key={`tournament_pitches_summary_${index}`}>
      <td>{index + 1}</td>
      <td>{pitch.name}</td>
    </tr>
  ));

  return (
    <table className={'table table-sm'}>
      <thead>
        <tr key={`tournament_pitches_summary_heading`}>
          <th />
          <th>Name</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
};

TournamentPitchesSummary.displayName = 'TournamentPitchesSummary';
