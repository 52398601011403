import * as React from 'react';
import * as propz from 'propz';
import { Field, Form, Formik } from 'formik';
import DatePicker from 'react-datepicker';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from '../../../../../../../consts/report';
import { Switch } from '../../../../../../../components/Switch/Switch';
import { getStartDateForSchoolYear } from '../../../../../../../helpers/calendar/calendar';
import { SchoolStudent } from 'Src/models/schoolStudent';
import { LabelWithQuestionIcon } from '../../../../../../../components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import '../../Students.css';

interface Props {
  onCloseClick: () => void;
  onSubmit: (data: any, reportType: string) => void;
  student: SchoolStudent[];
  activeSchoolId: string;
}

// report 019
export const ClubListForStudentReportForm = ({ onCloseClick, onSubmit, student, activeSchoolId }: Props) => {
  const [schoolData, setSchoolData] = React.useState<any>(null);

  React.useEffect(() => {
    fetch('/dist/images/mis/misIntegrationSchoolsList.json')
      .then(response => response.json())
      .then(data => {
        const matchedSchoolData = data.find(item => item.schoolSITId === activeSchoolId);
        setSchoolData(matchedSchoolData);
      })
      .catch(error => console.error('Error loading JSON:', error));
  }, [activeSchoolId]);
  const now = new Date();
  let dateFrom = getStartDateForSchoolYear(now);

  const requestInitial = {
    dateFrom,
    dateTo: now,
    isPrintFormName: false,
    isPrintAgeGroup: false,
    isPrintHouse: false,
    isPrintPP: false,
    isPrintSEN: false,
    isPrintFSM: false,
    isPrintAttendance: true
  };

  return (
    <Formik
      onSubmit={values => {
        onSubmit(values, REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.CLUB_LIST_FOR_STUDENT);
      }}
      initialValues={requestInitial}
      render={({ values, setFieldValue }) => (
        <Form>
          <div className="bReportFormTitle">Club list for student</div>
          <div className="mb-3 mt-3">{'View all the clubs relating to a student of your choice.'}</div>

          <div className="form-group">
            <label>Date from</label>
            <Field name="dateFrom">
              {({ field, form }) => (
                <div className="mb-3">
                  <DatePicker
                    {...field}
                    selected={field.value}
                    onChange={date => {
                      form.setFieldValue('dateFrom', date);
                      if (form.values.dateTo && form.values.dateTo < date) {
                        form.setFieldValue('dateTo', date);
                      }
                    }}
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
              )}
            </Field>
          </div>
          <div className="form-group">
            <label>Date to</label>
            <Field name="dateTo">
              {({ field, form }) => (
                <div className="mb-3">
                  <DatePicker
                    {...field}
                    selected={field.value}
                    onChange={date => {
                      form.setFieldValue('dateTo', date);
                    }}
                    minDate={form.values.dateFrom}
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
              )}
            </Field>
          </div>

          {schoolData && schoolData.studentsPPInfo === 1 && (
            <div className="form-group">
              <div className="d-flex">
                <Field
                  name="isPrintPP"
                  render={({ field }) => (
                    <Switch
                      {...field}
                      value={values.isPrintPP}
                      onChange={event => {
                        const isPrintPP = event.target.checked;
                        setFieldValue('isPrintPP', isPrintPP);
                      }}
                    />
                  )}
                />
                <LabelWithQuestionIcon
                  labelText="Show PP"
                  hintText={'Show information relating to PP.'}
                  customLabelClass="form-check-label pl-2"
                  labelHtmlFor="isPrintPP"
                />
              </div>
            </div>
          )}

          {schoolData && schoolData.studentsSENInfo === 1 && (
            <div className="form-group">
              <div className="d-flex">
                <Field
                  name="isPrintSEN"
                  render={({ field }) => (
                    <Switch
                      {...field}
                      value={values.isPrintSEN}
                      onChange={event => {
                        const isPrintSEN = event.target.checked;
                        setFieldValue('isPrintSEN', isPrintSEN);
                      }}
                    />
                  )}
                />
                <LabelWithQuestionIcon
                  labelText="Show SEN"
                  hintText={'Show information relating to SEN.'}
                  customLabelClass="form-check-label pl-2"
                  labelHtmlFor="isPrintSEN"
                />
              </div>
            </div>
          )}

          {schoolData && schoolData.studentsFSMInfo === 1 && (
            <div className="form-group">
              <div className="d-flex">
                <Field
                  name="isPrintFSM"
                  render={({ field }) => (
                    <Switch
                      {...field}
                      value={values.isPrintFSM}
                      onChange={event => {
                        const isPrintFSM = event.target.checked;
                        setFieldValue('isPrintFSM', isPrintFSM);
                      }}
                    />
                  )}
                />
                <LabelWithQuestionIcon
                  labelText="Show FSM"
                  hintText={'Show information relating to FSM.'}
                  customLabelClass="form-check-label pl-2"
                  labelHtmlFor="isPrintFSM"
                />
              </div>
            </div>
          )}

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isPrintAttendance"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintAttendance}
                    onChange={event => {
                      const isPrintAttendance = event.target.checked;
                      setFieldValue('isPrintAttendance', isPrintAttendance);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show Attendance Rate"
                hintText={'Show average attendance rate for each club.'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintAttendance"
              />
            </div>
          </div>

          <button type="button" className="btn btn-secondary mr-3" onClick={onCloseClick}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Get
          </button>
        </Form>
      )}
    />
  );
};
