import * as React from 'react';
import { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import DatePicker from 'react-datepicker';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from '../../../../../../consts/report';
import { Switch } from '../../../../../../components/Switch/Switch';
import { LabelWithQuestionIcon } from '../../../../../../components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import { MultiSelectCheckboxes } from '../../../../../../components/MultiSelectCheckboxes/MultiSelectCheckboxes';
import {
  getReportDefaultGendersForMultiSelect,
  getReportAgesForMultiSelect
} from '../../../../../../helpers/multiselect/multiselect';
import { School } from '../../../../../../models/school';
import { SchoolForm } from '../../../../../../models/form';
import { getStartDateForSchoolYear } from '../../../../../../helpers/calendar/calendar';
import '../SchoolEvents.scss';

interface Props {
  onCloseClick: () => void;
  onSubmit: (data: any, reportType: string) => void;
  school: School;
  forms: SchoolForm[];
}

// report 007
export function FixtureParticipationByStudentsReport({ onCloseClick, onSubmit, school, forms }: Props) {
  const now = new Date();
  let dateFrom = getStartDateForSchoolYear(now);
  const genders = getReportDefaultGendersForMultiSelect();
  const ages = getReportAgesForMultiSelect(school, forms);

  const [isAgesAndGendersChecked, setIsAgesAndGendersChecked] = useState(true);

  const requestInitial = {
    dateFrom,
    dateTo: now,
    isShowZero: false,
    isShowNonZero: true,
    isPrintPP: false,
    isPrintSEN: false,
    isPrintFSM: false,
    isGroupBySports: true,
    genders,
    ages,
    isSelectAllAges: true
  };

  const onGendersChange = (index, values, setFieldValue) => {
    const genderItems = values.genders;
    const ageItems = values.ages;

    const genderItemsUpdated = genderItems.map((genderItem, genderIndex) => {
      return genderIndex === index
        ? {
            ...genderItem,
            isChecked: !genderItem.isChecked
          }
        : genderItem;
    });

    const gendersChecked = genderItemsUpdated.map(gender => gender.isChecked);
    const agesChecked = ageItems.map(age => age.isChecked);
    const isCheckedGendersExist = gendersChecked.includes(true);
    const isCheckedAgesExist = agesChecked.includes(true);

    setIsAgesAndGendersChecked(isCheckedGendersExist && isCheckedAgesExist);
    setFieldValue('genders', genderItemsUpdated);
  };

  const onAgesChange = (index, values, setFieldValue) => {
    const ageItems = values.ages;
    const genderItems = values.genders;

    const ageItemsUpdated = ageItems.map((ageItem, ageIndex) => {
      return ageIndex === index
        ? {
            ...ageItem,
            isChecked: !ageItem.isChecked
          }
        : ageItem;
    });

    const gendersChecked = genderItems.map(gender => gender.isChecked);
    const agesChecked = ageItemsUpdated.map(age => age.isChecked);
    const isCheckedGendersExist = gendersChecked.includes(true);
    const isCheckedAgesExist = agesChecked.includes(true);

    const isSelectAllAges = ageItemsUpdated.every(age => age.isChecked === true);

    setIsAgesAndGendersChecked(isCheckedAgesExist && isCheckedGendersExist);
    setFieldValue('isSelectAllAges', isSelectAllAges);
    setFieldValue('ages', ageItemsUpdated);
  };

  const onSelectAllAgesChange = (event, values, setFieldValue) => {
    const isSelectAllAges = event.target.checked;
    const ageItems = values.ages;
    const genderItems = values.genders;

    const ageItemsUpdated = ageItems.map(age => {
      return {
        ...age,
        isChecked: isSelectAllAges
      };
    });

    const gendersChecked = genderItems.map(gender => gender.isChecked);
    const agesChecked = ageItemsUpdated.map(age => age.isChecked);
    const isCheckedGendersExist = gendersChecked.includes(true);
    const isCheckedAgesExist = agesChecked.includes(true);

    setIsAgesAndGendersChecked(isCheckedAgesExist && isCheckedGendersExist);
    setFieldValue('isSelectAllAges', isSelectAllAges);
    setFieldValue('ages', ageItemsUpdated);
  };

  return (
    <Formik
      onSubmit={values =>
        onSubmit(values, REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.FIXTURE_PARTICIPATION_STATISTICS_BY_STUDENTS)
      }
      initialValues={requestInitial}
      render={({ values, setFieldValue }) => (
        <Form>
          <div className="bReportFormTitle">Fixture participation by students</div>
          <div className="mb-3 mt-3">
            {'View the number of fixtures that a student has been involved in during the period you select. ' +
              'Also, get information on PP and SEN students.'}
          </div>
          <div className="form-group">
            <label>Date from</label>
            <Field name="dateFrom">
              {({ field, form }) => (
                <div className="mb-3">
                  <DatePicker
                    {...field}
                    selected={field.value}
                    onChange={date => {
                      form.setFieldValue('dateFrom', date);
                      if (form.values.dateTo && form.values.dateTo < date) {
                        form.setFieldValue('dateTo', date);
                      }
                    }}
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
              )}
            </Field>
          </div>
          <div className="form-group">
            <label>Date to</label>
            <Field name="dateTo">
              {({ field, form }) => (
                <div className="mb-3">
                  <DatePicker
                    {...field}
                    selected={field.value}
                    onChange={date => {
                      form.setFieldValue('dateTo', date);
                    }}
                    minDate={form.values.dateFrom}
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
              )}
            </Field>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isShowZero"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowZero}
                    onChange={event => {
                      const isShowZero = event.target.checked;
                      setFieldValue('isShowZero', isShowZero);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show non-participants"
                hintText={`This will enable you to see all students within your chosen criteria who didn't participate in any fixtures.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowZero"
              />
            </div>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isShowNonZero"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowNonZero}
                    onChange={event => {
                      const isShowNonZero = event.target.checked;
                      setFieldValue('isShowNonZero', isShowNonZero);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show participants"
                hintText={
                  'This will enable you to see all students within your chosen criteria who participated in fixtures.'
                }
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowNonZero"
              />
            </div>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isPrintPP"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintPP}
                    onChange={event => {
                      const isPrintPP = event.target.checked;
                      setFieldValue('isPrintPP', isPrintPP);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show PP"
                hintText={`See data relating to PP.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintPP"
              />
            </div>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isPrintSEN"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintSEN}
                    onChange={event => {
                      const isPrintSEN = event.target.checked;
                      setFieldValue('isPrintSEN', isPrintSEN);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show SEN"
                hintText={`See data relating to SEN.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintSEN"
              />
            </div>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isPrintFSM"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintFSM}
                    onChange={event => {
                      const isPrintFSM = event.target.checked;
                      setFieldValue('isPrintFSM', isPrintFSM);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show FSM"
                hintText={`See data relating to FSM.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintFSM"
              />
            </div>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isGroupBySports"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isGroupBySports}
                    onChange={event => {
                      const isGroupBySports = event.target.checked;
                      setFieldValue('isGroupBySports', isGroupBySports);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Group by sports/activities"
                hintText={'Show details related to the number of fixtures by sports.'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isGroupBySports"
              />
            </div>
          </div>

          <div className={'mt-3 mb-3'}>
            <MultiSelectCheckboxes
              items={values.genders}
              cols={3}
              onChange={index => onGendersChange(index, values, setFieldValue)}
            />
          </div>

          <div className={'mt-3 mb-3'}>
            <MultiSelectCheckboxes
              items={values.ages}
              cols={3}
              onChange={index => onAgesChange(index, values, setFieldValue)}
            />
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isSelectAllAges"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isSelectAllAges}
                    customClass="mb-3"
                    onChange={event => onSelectAllAgesChange(event, values, setFieldValue)}
                  />
                )}
              />

              <label htmlFor="isSelectAllAges" className="form-check-label pl-2">
                Select all ages
              </label>
            </div>
          </div>

          <button type="button" className="btn btn-secondary mr-3" onClick={onCloseClick}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary" disabled={!isAgesAndGendersChecked}>
            Get
          </button>
        </Form>
      )}
    />
  );
}
