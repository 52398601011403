import * as React from 'react';
import * as BPromise from 'bluebird';
import * as propz from 'propz';
import { AppUser } from 'Src/views/App/App';
import { parse } from 'query-string';
import { History, Location } from 'history';
import {
  ColumnDefinition,
  getFilters2,
  getOrder,
  getSearchFilter,
  getSearchOrder,
  getServerFieldSectionWhere,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { Grid2 } from 'Src/components/Grid/Grid2';
import { INVITE_KIND, INVITE_TABS, INVITE_TYPE } from 'Src/consts/invite';
import { GenericSummary } from 'Src/components/GenericSummary/GenericSummary';
import { Loader } from 'Src/components/Loader/Loader';
import { ResizablePanel } from 'Src/components/ResizablePanel/ResizablePanel';
import {
  getEventDataToInvite,
  getEventVenueInviteInbox,
  getInviteStatus,
  getSenderNameIfNeedInviteInbox,
  getStartFromInvite
} from 'Src/helpers/accessor/accessor';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { AdminSchoolsEventSchoolInviteSummary } from 'Src/components/AdminSchoolInviteSummary/AdminSchoolsEventSchoolInviteSummary';
import {
  declineAdminSchoolsInvites,
  sendCommentsAdminSchoolsInvites,
  getAdminSchoolsAllInboxInvites,
  getAdminSchoolsInboxInvites,
  getAdminSchoolsInboxInvitesCount
} from 'Src/helpers/service/adminSU/schoolInvitesTable';
import { AdminSchoolsTournamentSchoolInviteSummary } from 'Src/components/AdminSchoolInviteSummary/AdminSchoolsTournamentSchoolInviteSummary';
import { SchoolInvite } from 'Src/models/invite';
import { AdminSchoolsDeclineInvitesModal } from 'Src/components/AdminSchoolsDeclineInvitesInboxModal/AdminSchoolsDeclineInvitesModal';
import { isSchoolEventInvite, isSchoolTournamentInvite } from 'Src/helpers/invite/invite';
import { acceptAutoEnrollmentInvite, acceptInvite } from 'Src/helpers/service/admin/inivtes';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { AcceptTournamentInviteWithoutAutoEnrollmentTeams } from './AcceptTournamentInviteWithoutAutoEnrollmentTeams/AcceptTournamentInviteWithoutAutoEnrollmentTeams';
import { AcceptTournamentInviteWithAutoEnrollmentTeams } from './AcceptTournamentInviteWithAutoEnrollmentTeams/AcceptTournamentInviteWithAutoEnrollmentTeams';
import { getTeamPlacesLeft, isIndividualTournament } from 'Src/helpers/tournament/tournament';
import { GENDER } from 'Src/consts/common';
import { addSchoolTournamentTeam } from 'Src/helpers/service/admin/tournamentTeams';
import { uniqArray } from 'Src/helpers/common/common';
import { AGE_GROUPS } from 'Src/consts/school';
import { TOURNAMENT_GENDER_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/tournament';
import { isNonTeamSportEvent } from 'Src/helpers/event/event';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'School',
    field: 'name',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: getSenderNameIfNeedInviteInbox
  },
  {
    text: 'Event',
    field: 'event',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getEventDataToInvite
  },
  {
    text: 'Start time',
    field: 'startTime',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getStartFromInvite
  },
  {
    text: 'Venue',
    field: 'venue',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getEventVenueInviteInbox
  },
  {
    text: 'Status',
    field: 'status',
    isSort: true,
    type: FILTER_TYPE.NONE,
    accessor: getInviteStatus
  }
];

interface State {
  items: SchoolInvite[];
  currentPage: number;
  selectedItems: SchoolInvite[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;
  commentText: string;

  currentTabIndex: number;
  isInviteDeclineModalOpen: boolean;
  isTournamentInviteWithoutAutoEnrollmentTeamsModalOpen: boolean;
  isTournamentInviteWithAutoEnrollmentTeamsModalOpen: boolean;
  isTournamentInviteWaitingListModalOpen: boolean;
  isTournamentInviteInfoModalOpen: boolean;
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
  setNeedUpdate: () => void;
}

export class AdminSchoolsInvitesInbox extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',
      commentText: '',

      currentTabIndex: 0,
      isInviteDeclineModalOpen: false,
      isTournamentInviteWithoutAutoEnrollmentTeamsModalOpen: false,
      isTournamentInviteWithAutoEnrollmentTeamsModalOpen: false,
      isTournamentInviteWaitingListModalOpen: false,
      isTournamentInviteInfoModalOpen: false
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setItems();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const getItemsPromise = getAdminSchoolsInboxInvites(user, serverQueryFilter);
    const getItemsCountPromise = getAdminSchoolsInboxInvitesCount(user, where);

    const promises = [getItemsCountPromise, getItemsPromise];

    return BPromise.all(promises).then(([countObj, items]) => {
      this.setState({
        currentPage: page,
        sortDirection: sortDirection,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: items,
        total: countObj.count,
        basePath: history.location.pathname,
        isLoading: false
      });

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated,
      currentTabIndex: 0
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: nextSelectedItems,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    getAdminSchoolsAllInboxInvites(user, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const nextSelectedItems = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: nextSelectedItems,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: ''
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  acceptEventSchoolInvite = (): void => {
    const { user, history, setNeedUpdate } = this.props;
    const { selectedItems } = this.state;
    const [invite] = selectedItems;
    this.setState({
      isLoading: true
    });

    acceptInvite(user, invite.id)
      .then(res => {
        const isIndividual = isNonTeamSportEvent(invite.event);
        if (isIndividual) {
          history.push({
            pathname: '/events/individualManager',
            search: `event=${invite.eventId}`
          });
        } else {
          history.push({
            pathname: '/events/teamManager',
            search: `event=${invite.eventId}`
          });
        }
      })
      .then(() => {
        setNeedUpdate();
      });
  };

  acceptTournamentSchoolInvite = (): void => {
    const { selectedItems } = this.state;
    const [invite] = selectedItems;

    const isAutoEnrollmentTeams = propz.get(invite, ['tournament', 'isAutoEnrollmentTeams'], false);

    if (isAutoEnrollmentTeams) {
      this.setState({
        isTournamentInviteWithAutoEnrollmentTeamsModalOpen: true
      });
    } else {
      this.setState({
        isTournamentInviteWithoutAutoEnrollmentTeamsModalOpen: true
      });
    }
  };

  getAcceptInviteCallback(): () => void {
    const { selectedItems } = this.state;
    const [invite] = selectedItems;

    switch (true) {
      case isSchoolEventInvite(invite):
        return this.acceptEventSchoolInvite;
      case isSchoolTournamentInvite(invite):
        return this.acceptTournamentSchoolInvite;
      default:
        return () => {};
    }
  }

  renderInviteTable(): React.ReactNode {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isSelectAllChecked,
      currentPage,
      total
    } = this.state;

    const actionItems = [
      {
        itemText: 'Accept',
        onItemClick: selectedItems.length === 1 ? this.getAcceptInviteCallback() : () => {},
        isActive: selectedItems.length === 1
      },
      {
        itemText: 'Decline',
        onItemClick: this.adminSchoolsDeclineInvitesInboxModalOpen,
        isActive: selectedItems.length > 0
      }
    ];

    const filterOptions = {};

    return (
      <Grid2
        dataItems={items}
        filters={filters}
        currentPage={currentPage}
        total={total}
        isSelectAllChecked={isSelectAllChecked}
        isDataFiltered={isDataFiltered}
        sortDirection={sortDirection}
        sortColumnsName={sortColumnsName}
        isShowFilter={isShowFilter}
        dataItemsSelected={selectedItems}
        columns={COLUMNS}
        actionItems={actionItems}
        options={filterOptions}
        onItemClick={this.onItemClick}
        onSortClick={this.onTableSortClick}
        onApplyFilterClick={this.onApplyFilterClick}
        onClearFilterClick={this.onClearFilterClick}
        onTableFilterChange={this.onTableFilterChange}
        onTableFilterClick={this.onTableFilterClick}
        setCurrentPageParams={this.setCurrentPageParams}
        onSelectAllClick={this.onSelectAllClick}
        onSelectAllOnPageClick={this.onSelectAllOnPageClick}
        onUnselectAllClick={this.onUnselectAllClick}
      />
    );
  }

  isOneItemSelected(): boolean {
    const selectedItems = this.state.selectedItems;
    return selectedItems.length === 1;
  }

  renderSummary(): React.ReactNode {
    const isOneItemSelected = this.isOneItemSelected();

    if (!isOneItemSelected) {
      return <div className="font-weight-bold">Select a invite above</div>;
    }

    const { currentTabIndex } = this.state;
    const summaryTabs = this.getSummaryTabs();

    return (
      <GenericSummary onTabClick={this.onTabClick} tabs={INVITE_TABS} currentTabIndex={currentTabIndex}>
        {summaryTabs}
      </GenericSummary>
    );
  }

  onTabClick = (event, index: number) => {
    event.preventDefault();

    this.setState({
      currentTabIndex: index
    });
  };

  getSummaryTabs(): React.ReactNode {
    const { activeSchoolId } = this.props.user;

    const { currentTabIndex, selectedItems } = this.state;

    const invite = selectedItems[0];
    const messageType = INVITE_TYPE.INBOX;

    switch (currentTabIndex) {
      case 0:
        switch (true) {
          case invite.kind === INVITE_KIND.EVENT_SCHOOL_INVITE:
            return (
              <AdminSchoolsEventSchoolInviteSummary
                invite={invite}
                activeSchoolId={activeSchoolId}
                messageType={messageType}
              />
            );

          case invite.kind === INVITE_KIND.TOURNAMENT_SCHOOL_INVITE:
            return (
              <AdminSchoolsTournamentSchoolInviteSummary
                invite={invite}
                activeSchoolId={activeSchoolId}
                messageType={messageType}
              />
            );
        }
    }
  }

  onAdminSchoolsDeclineInvitesInboxOkClick = (): void => {
    const { selectedItems, commentText } = this.state;
    const { user, setNeedUpdate } = this.props;

    this.setState({
      isLoading: true
    });

    const data = {
      text: 'Rejected by opponent. ' + commentText
    };

    const promises = selectedItems.map(invite => {
      return [declineAdminSchoolsInvites(user, invite.id), sendCommentsAdminSchoolsInvites(user, invite.id, data)];
    });
    BPromise.all(promises).then(em => {
      this.setState({
        isInviteDeclineModalOpen: false,
        isLoading: false,
        commentText: '',
        selectedItems: []
      });

      this.setItems().then(() => {
        setNeedUpdate();
      });
    });
  };

  onCommentTextChange = (event): void => {
    this.setState({
      commentText: event.target.value
    });
  };

  adminSchoolsDeclineInvitesInboxModalOpen = (): void => {
    this.setState({
      isInviteDeclineModalOpen: true
    });
  };

  onAdminSchoolsDeclineInvitesInboxModalCloseClick = (): void => {
    this.setState({
      isInviteDeclineModalOpen: false
    });
  };

  renderInviteDeclineModal(): React.ReactNode {
    const { isInviteDeclineModalOpen, commentText } = this.state;
    return (
      <AdminSchoolsDeclineInvitesModal
        modalTitle={'Are you sure you want to decline?'}
        commentText={commentText}
        onCommentTextChange={this.onCommentTextChange}
        onCloseClick={this.onAdminSchoolsDeclineInvitesInboxModalCloseClick}
        isOpen={isInviteDeclineModalOpen}
        onSaveClick={this.onAdminSchoolsDeclineInvitesInboxOkClick}
      />
    );
  }

  onCancelTournamentInviteWithoutAutoEnrollmentTeamsClick = () => {
    this.setState({
      isTournamentInviteWithoutAutoEnrollmentTeamsModalOpen: false
    });
  };

  onCancelTournamentInviteWithAutoEnrollmentTeamsClick = () => {
    this.setState({
      isTournamentInviteWithAutoEnrollmentTeamsModalOpen: false
    });
  };

  onAcceptTournamentInviteWithoutAutoEnrollmentTeamsClick = () => {
    const { selectedItems } = this.state;
    const [invite] = selectedItems;
    const { user, setNeedUpdate } = this.props;

    this.setState({
      isLoading: true
    });

    acceptInvite(user, invite.id)
      .then(res => {
        this.setState({
          isLoading: false,
          isTournamentInviteInfoModalOpen: true
        });
      })
      .then(() => {
        setNeedUpdate();
      });
  };

  onAcceptTournamentInviteWithAutoEnrollmentTeamsClick = (data?): void => {
    const { user, history, location, setNeedUpdate } = this.props;
    const { activeSchoolId, activeSchoolName } = user;

    const { selectedItems } = this.state;
    const [invite] = selectedItems;

    const { tournament, id: inviteId, tournamentId } = invite;
    const { autoEnrollmentTeamsSettings, gender: tournamentGender, ages, sportId } = tournament;

    const isAutoEnrollmentToWaitingList = propz.get<boolean>(tournament, ['isAutoEnrollmentToWaitingList'], false);
    const isIndividual = isIndividualTournament(tournament);

    const isMixedTeamsAvailable = propz.get<boolean>(tournament, ['isMixedTeamsAvailable'], false);
    const isGenderMixed = tournamentGender === GENDER.MIXED;
    const gender = isGenderMixed && !isMixedTeamsAvailable ? GENDER.MALE_ONLY : tournamentGender;

    const ageIndex = propz.get(tournament, ['ages', 0], 0);

    const teamPlacesLeft = getTeamPlacesLeft(autoEnrollmentTeamsSettings, ageIndex, gender);
    const autoEnrollmentTeamsSetting = propz.get(autoEnrollmentTeamsSettings, [0]);
    const maximumNumberTeams = propz.get(autoEnrollmentTeamsSetting, ['maximumNumberTeams']);
    const isAllSpacesBooked = isIndividual && maximumNumberTeams === 0 ? false : teamPlacesLeft === 0;

    const isAllGenderAndAgeTeamPlacesLeft = autoEnrollmentTeamsSettings.every(autoEnrollmentTeamsSetting => {
      return autoEnrollmentTeamsSetting.maximumNumberTeams - autoEnrollmentTeamsSetting.currentNumberTeams > 0;
    });

    let isAllTeamsCountEmpty = true;

    if (typeof data !== 'undefined') {
      for (let key in data) {
        const currentTeamsCount = Number(data[key]);
        if (currentTeamsCount !== 0) {
          isAllTeamsCountEmpty = false;
        }
      }
    }

    this.setState({
      isLoading: true
    });

    switch (true) {
      case isAutoEnrollmentToWaitingList: {
        acceptInvite(user, inviteId).then(res => {
          this.setState({
            isLoading: false,
            selectedItems: [],
            isSelectAllChecked: false,
            isTournamentInviteWithAutoEnrollmentTeamsModalOpen: false
          });

          this.setItems().then(() => {
            setNeedUpdate();
          });
        });
        break;
      }
      case isIndividual && !isAllSpacesBooked: {
        acceptAutoEnrollmentInvite(user, inviteId)
          .then(res => {
            const teamBody = {
              name: activeSchoolName,
              schoolId: activeSchoolId,
              gender: tournamentGender,
              ages: ages,
              sportId: sportId,
              teamType: 'ADHOC'
            };

            return addSchoolTournamentTeam(user, tournamentId, teamBody);
          })
          .then(res => {
            this.setState({
              isLoading: false,
              isTournamentInviteInfoModalOpen: true
            });
          })
          .then(() => {
            setNeedUpdate();
          });
        break;
      }
      case isIndividual && isAllSpacesBooked: {
        acceptInvite(user, inviteId).then(res => {
          this.setState({
            isLoading: false,
            selectedItems: [],
            isSelectAllChecked: false,
            isTournamentInviteWithAutoEnrollmentTeamsModalOpen: false
          });

          this.setItems().then(() => {
            setNeedUpdate();
          });
        });
        break;
      }
      case !isAllGenderAndAgeTeamPlacesLeft && isAllTeamsCountEmpty: {
        this.setState({
          isLoading: false,
          isTournamentInviteWithAutoEnrollmentTeamsModalOpen: false,
          isTournamentInviteWaitingListModalOpen: true
        });
        break;
      }
      default: {
        let teamsArray = [];

        for (let key in data) {
          const [age, gender] = key.split('-');
          const currentTeamsCount = Number(data[key]);
          if (currentTeamsCount > 0) {
            teamsArray.push({
              age: Number(age),
              gender: gender,
              count: Number(data[key])
            });
          }
        }

        let teamName = '';

        const teamsGenderArray = teamsArray.map(team => team.gender);
        const teamsGenderArrayUniq = uniqArray(teamsGenderArray);

        const isGenderMale = tournamentGender === GENDER.MALE_ONLY;
        const isGenderFemale = tournamentGender === GENDER.FEMALE_ONLY;

        const isSomeTeamsWithEqualGender = teamsGenderArray.length !== teamsGenderArrayUniq.length;

        const charArray = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('');

        let teamsDataToPost = [];

        teamsArray.forEach(team => {
          const teamCount = team.count;
          const teamAge = team.age;
          const teamAgeConverted = AGE_GROUPS['U5-U18'][teamAge];
          switch (true) {
            case isGenderMale:
            case isGenderFemale:
              teamName = `${teamAgeConverted}`;
              if (teamCount === 1) {
                teamName = `${teamAgeConverted}`;
                teamsDataToPost.push({
                  name: teamName,
                  schoolId: activeSchoolId,
                  gender: team.gender,
                  ages: [teamAge],
                  isAutoEnrolledTeam: true
                });
              } else {
                for (let i = 0; i < teamCount; i++) {
                  teamName = `${teamAgeConverted} ${charArray[i]}`;
                  teamsDataToPost.push({
                    name: teamName,
                    schoolId: activeSchoolId,
                    gender: team.gender,
                    ages: [teamAge],
                    isAutoEnrolledTeam: true
                  });
                }
              }
              break;

            case !isSomeTeamsWithEqualGender && teamCount === 1:
              teamName = `${teamAgeConverted}`;
              teamsDataToPost.push({
                name: teamName,
                schoolId: activeSchoolId,
                gender: team.gender,
                ages: [teamAge],
                isAutoEnrolledTeam: true
              });
              break;
            case !isSomeTeamsWithEqualGender && teamCount > 1:
              for (let i = 0; i < teamCount; i++) {
                teamName = `${teamAgeConverted} ${charArray[i]}`;
                teamsDataToPost.push({
                  name: teamName,
                  schoolId: activeSchoolId,
                  gender: team.gender,
                  ages: [teamAge],
                  isAutoEnrolledTeam: true
                });
              }
              break;
            case isSomeTeamsWithEqualGender && teamCount === 1:
              teamName = `${teamAgeConverted} ${TOURNAMENT_GENDER_SERVER_TO_CLIENT_MAPPING[team.gender]}`;
              teamsDataToPost.push({
                name: teamName,
                schoolId: activeSchoolId,
                gender: team.gender,
                ages: [teamAge],
                isAutoEnrolledTeam: true
              });
              break;
            case isSomeTeamsWithEqualGender && teamCount > 1:
              for (let i = 0; i < teamCount; i++) {
                teamName = `${teamAgeConverted} ${TOURNAMENT_GENDER_SERVER_TO_CLIENT_MAPPING[team.gender]} ${
                  charArray[i]
                }`;
                teamsDataToPost.push({
                  name: teamName,
                  schoolId: activeSchoolId,
                  gender: team.gender,
                  ages: [teamAge],
                  isAutoEnrolledTeam: true
                });
              }
              break;
          }
        });

        acceptAutoEnrollmentInvite(user, inviteId)
          .then(res => BPromise.all(teamsDataToPost.map(team => addSchoolTournamentTeam(user, tournamentId, team))))
          .then(res => {
            const { search, pathname } = location;
            history.push({
              pathname: `/tournaments/teams`,
              search: `tournamentId=${tournamentId}`,

              state: {
                search,
                isGoBackWithoutSave: true,
                prevRoutePath: pathname
              }
            });
          })
          .then(() => {
            setNeedUpdate();
          });
      }
    }
  };

  renderTournamentInviteWithoutAutoEnrollmentTeamsModal(): React.ReactNode {
    const { isTournamentInviteWithoutAutoEnrollmentTeamsModalOpen, selectedItems } = this.state;
    const [invite] = selectedItems;
    const { user } = this.props;

    return (
      <SimpleModal isOpen={isTournamentInviteWithoutAutoEnrollmentTeamsModalOpen}>
        <AcceptTournamentInviteWithoutAutoEnrollmentTeams
          onCancel={this.onCancelTournamentInviteWithoutAutoEnrollmentTeamsClick}
          onAccept={this.onAcceptTournamentInviteWithoutAutoEnrollmentTeamsClick}
          invite={invite}
          user={user}
        />
      </SimpleModal>
    );
  }

  renderTournamentInviteWithAutoEnrollmentTeamsModal(): React.ReactNode {
    const { isTournamentInviteWithAutoEnrollmentTeamsModalOpen, selectedItems } = this.state;
    const [invite] = selectedItems;
    const { user } = this.props;
    return (
      <SimpleModal isOpen={isTournamentInviteWithAutoEnrollmentTeamsModalOpen}>
        <AcceptTournamentInviteWithAutoEnrollmentTeams
          onCancel={this.onCancelTournamentInviteWithAutoEnrollmentTeamsClick}
          onAccept={this.onAcceptTournamentInviteWithAutoEnrollmentTeamsClick}
          invite={invite}
          user={user}
        />
      </SimpleModal>
    );
  }

  onTournamentInviteWaitingListModalCloseClick = () => {
    this.setState({
      isTournamentInviteWaitingListModalOpen: false,
      isTournamentInviteWithAutoEnrollmentTeamsModalOpen: true
    });
  };

  acceptTournamentInviteWaitingList = () => {
    const { user } = this.props;
    const { selectedItems } = this.state;
    const [invite] = selectedItems;
    const { id: inviteId } = invite;

    this.setState({
      isLoading: true
    });

    acceptInvite(user, inviteId).then(res => {
      this.setState({
        isLoading: false,
        selectedItems: [],
        isSelectAllChecked: false,
        isTournamentInviteWaitingListModalOpen: false
      });

      this.setItems();
    });
  };

  renderTournamentInviteWaitingListModalOpen(): React.ReactNode {
    const { isTournamentInviteWaitingListModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isTournamentInviteWaitingListModalOpen}
        title={'Warning'}
        body={
          "You haven't specified any teams for the tournament. Your school will not be enrolled to the tournament but will be put on to the waiting list"
        }
        buttonText={'Accept (waiting list)'}
        buttonCancelText={'Cancel'}
        onButtonClick={this.acceptTournamentInviteWaitingList}
        onCloseClick={this.onTournamentInviteWaitingListModalCloseClick}
      />
    );
  }

  renderTournamentInviteInfoModalOpen(): React.ReactNode {
    const { isTournamentInviteInfoModalOpen, selectedItems } = this.state;
    if (selectedItems.length !== 1) {
      return null;
    }
    const [invite] = selectedItems;
    const { history } = this.props;

    const name = propz.get(invite, ['tournament', 'name'], '');

    return (
      <SimpleModal
        isOpen={isTournamentInviteInfoModalOpen}
        title={'Info'}
        body={`You have successfully booked your space in the ${name}. You will get a notification from the organisers once you are able to add your athletes to the individual events.`}
        buttonText={'OK'}
        onButtonClick={() => {
          history.push({
            pathname: '/calendar'
          });
        }}
      />
    );
  }

  render() {
    const {
      isLoading,
      isInviteDeclineModalOpen,
      isTournamentInviteWithoutAutoEnrollmentTeamsModalOpen,
      isTournamentInviteWithAutoEnrollmentTeamsModalOpen,
      isTournamentInviteWaitingListModalOpen,
      isTournamentInviteInfoModalOpen
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }
    const classes =
      isInviteDeclineModalOpen ||
      isTournamentInviteWithoutAutoEnrollmentTeamsModalOpen ||
      isTournamentInviteWithAutoEnrollmentTeamsModalOpen ||
      isTournamentInviteInfoModalOpen ||
      isTournamentInviteWaitingListModalOpen
        ? 'mt-3 modal-open'
        : 'mt-3';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderInviteDeclineModal()}
        {this.renderTournamentInviteWithoutAutoEnrollmentTeamsModal()}
        {this.renderTournamentInviteWithAutoEnrollmentTeamsModal()}
        {this.renderTournamentInviteWaitingListModalOpen()}
        {this.renderTournamentInviteInfoModalOpen()}
        <div className="row">
          <div className="col-md-12">
            <ResizablePanel>
              {this.renderInviteTable()}
              {this.renderSummary()}
            </ResizablePanel>
          </div>
        </div>
      </div>
    );
  }
}
