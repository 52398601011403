export const EVENT_TABS = [
  'Summary',
  'Participants',
  'Details',
  'Jobs',
  'Discipline',
  'Gallery',
  'Match report',
  'Feed'
];
export const EVENT_CLUB_TABS = [
  'Summary',
  'Participants',
  'Details',
  'Jobs',
  'Discipline',
  'Gallery',
  'Leader/Coach notes',
  'Feed'
];
export const EVENT_VIEW_TABS = [
  'Participants',
  'Details',
  'Positions',
  'Jobs',
  'Gallery',
  'Match report',
  'Feed',
  'Parental consent',
  'Availability reports'
];
export const EVENT_CLUB_VIEW_TABS = [
  'Participants',
  'Details',
  'Jobs',
  'Gallery',
  'Leader/Coach notes',
  'Feed',
  'Parental consent',
  'Availability reports'
];
export const EVENT_TAB_ATTENDANCE = 'Attendance';
export const EVENT_TAB_PERFORMANCE = 'Performance';
export const EVENT_TAB_CLUB_PROGRESS = 'Progress';

export const STUDENT_EVENT_VIEW_TABS = [
  'Participants',
  'Details',
  // 'Positions',
  'Jobs',
  'Gallery',
  'Match report',
  'Feed',
  'Availability reports'
];

export const STUDENT_EVENT_CLUB_VIEW_TABS = [
  'Participants',
  'Details',
  'Jobs',
  'Gallery',
  'Leader/Coach notes',
  'Feed',
  'Availability reports'
];

export const PARENT_EVENT_VIEW_TABS = [
  'Participants',
  'Details',
  // 'Positions',
  'Jobs',
  'Gallery',
  'Match report',
  'Feed',
  'Parental consent',
  'Availability reports'
];

export const PARENT_EVENT_CLUB_VIEW_TABS = [
  'Participants',
  'Details',
  'Jobs',
  'Gallery',
  'Leader/Coach notes',
  'Feed',
  'Parental consent',
  'Availability reports'
];

export const TOURNAMENT_DETAILS_TABS = ['Details & Booking', 'Additional info & Documents'];

export const EVENT_STATUS = {
  DRAFT: 'DRAFT', // can be filled with any data, visible to creator only
  SENDING_INVITES: 'SENDING_INVITES', // in process of generating and sending invites
  INVITES_SENT: 'INVITES_SENT', // all invites are delivered to recipients
  COLLECTING_INVITE_RESPONSE: 'COLLECTING_INVITE_RESPONSE', // got at least one decision from invite in case of multiple invites sent
  ACCEPTED: 'ACCEPTED', // every invite recipient accepted invite
  REJECTED: 'REJECTED', // every invite recipient rejected invite
  FINISHED: 'FINISHED', // event finished
  ON_HOLD: 'ON_HOLD', // event temporary paused
  CANCELED: 'CANCELED' // event canceled because of some reason
};

export const EVENT_STATUS_SERVER_TO_CLIENT_MAPPING = {
  DRAFT: 'Draft',
  SENDING_INVITES: 'Sending invites',
  INVITES_SENT: 'Invites sent',
  COLLECTING_INVITE_RESPONSE: 'Collecting invite response',
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected',
  FINISHED: 'Finished',
  ON_HOLD: 'On hold',
  CANCELED: 'Cancelled'
};

export const EVENT_NO_RESULTS_YET = 'No results yet';
export const EVENT_VIEW_RESULTS = 'View results';
export const EVENT_VIEW_DETAILS = 'View details';

export const EVENT_TYPES = {
  EXTERNAL_SCHOOLS: 'EXTERNAL_SCHOOLS',
  INTERNAL_HOUSES: 'INTERNAL_HOUSES',
  INTERNAL_TEAMS: 'INTERNAL_TEAMS'
};

export const EVENT_TYPES_SERVER_TO_CLIENT_MAPPING = {
  EXTERNAL_SCHOOLS: 'Inter-schools',
  INTERNAL_HOUSES: 'House',
  INTERNAL_TEAMS: 'Internal'
};

export const AGGREGATION_AND_EVENT_TYPES_SERVER_TO_CLIENT_MAPPING = {
  TEAM: 'Internal',
  HOUSE: 'House',
  INDIVIDUAL: 'Internal'
};

export const AGGREGATION_AND_EVENT_TYPES_MAPPING = {
  TEAM: 'INTERNAL_TEAMS',
  HOUSE: 'INTERNAL_HOUSES',
  INDIVIDUAL: 'INTERNAL_TEAMS'
};

export const EVENT_NOTIFICATION_MODE = {
  MANUAL: 'MANUAL',
  AUTO: 'AUTO',
  NONE: 'NONE'
};

export const EVENT_SUB_TYPES_SERVER_TO_CLIENT_MAPPING = {
  FIXTURE_EVENT: 'Fixture',
  CLUB_EVENT: 'Club',
  TOURNAMENT_EVENT: 'Tournament'
};

export const EVENT_PARTICIPANT_TEXT_SELECT_TEAM_LATER = 'Select team later';
export const EVENT_PARTICIPANT_TEXT_NO_TEAM_MEMBERS = 'No team members to display';
export const EVENT_PARTICIPANT_TEXT_MEMBERS_NOT_ADDED = 'No participants have been added yet';
export const EVENT_PARTICIPANT_TEXT_ACCEPTED_BY_OPPONENT =
  'Accepted by opponent but no participants have been added yet';
export const EVENT_PARTICIPANT_TEXT_AWAITING_OPPONENT = 'Awaiting opponent';
export const EVENT_PARTICIPANT_TEXT_EVENT_INVITE_REJECTED = 'The event invite has been rejected';

export const EVENT_ACTION_BUTTONS_MAPPING = {
  EDIT_EVENT_SETTINGS: 'Edit event settings',
  DUPLICATE_EVENT: 'Duplicate event',
  ADD_SCHOOL: 'Add school',
  ADD_TEAM: 'Add team',
  ADD_RESULTS: 'Add results',
  UPDATE_RESULTS: 'Update results',
  UPDATE_POINTS: 'Update points',
  SEND_CONSENT_REQUEST: 'Send consent request',
  CANCEL_EVENT: 'Cancel event',
  CANCEL_EVENT_AND_EDIT_NOTIFICATION_LIST: 'Cancel event and edit notification list',
  REPORT_AVAILABILITY: 'Report availability',
  PRINT: 'Print',
  DOWNLOAD_CSV: 'Download CSV',
  DELETE_EVENT: 'Delete event',
  IMPORT_RESULTS_FROM_QUANTUM: 'Import results from Quantum',
  EXPORT_START_LIST_TO_MACSHA_ONE_4_ALL: 'Export start list to Macsha One4All',
  IMPORT_RESULTS_FROM_MACSHA_ONE_4_ALL: 'Import results from Macsha One4All',
  CLEAN_SCHOOL_LIST: 'Clean school list',
  REMOVE_SCHOOLS: 'Remove schools',
  SEND_EVENT_NOTIFICATION: 'Send event notification',
  SEND_EVENT_NOTIFICATION_ELIGIBLE_STUDENTS: 'Send notification to all eligible students',
  IMPORT_RESULTS: 'Import results',
  IMPORT_RESULTS_FROM_QUANTUM_AQ: 'Import results from Quantum AQ',
  CLONE_TOURNAMENT_EVENT: 'Clone tournament event',
  ARBITER_BOARD: 'Score board',
  ADD_VIDEO: 'Add video instructions',
  EXTENDED_PARTICIPANT_LIST: 'Extended participant list',
  EXTENDED_TEAM_SHEET: 'Extended team sheet',
  EVENT_CONSENT_REQUESTS: 'Event consent requests',
  EVENT_AVAILABILITY_REPORTS: 'Event availability reports'
};

export const EVENT_ACTION_BUTTONS = {
  EDIT_EVENT_SETTINGS: 'EDIT_EVENT_SETTINGS',
  DUPLICATE_EVENT: 'DUPLICATE_EVENT',
  ADD_SCHOOL: 'ADD_SCHOOL',
  ADD_TEAM: 'ADD_TEAM',
  ADD_RESULTS: 'ADD_RESULTS',
  UPDATE_RESULTS: 'UPDATE_RESULTS',
  UPDATE_POINTS: 'UPDATE_POINTS',
  SEND_CONSENT_REQUEST: 'SEND_CONSENT_REQUEST',
  CANCEL_EVENT: 'CANCEL_EVENT',
  CANCEL_EVENT_AND_EDIT_NOTIFICATION_LIST: 'CANCEL_EVENT_AND_EDIT_NOTIFICATION_LIST',
  REPORT_AVAILABILITY: 'REPORT_AVAILABILITY',
  PRINT: 'PRINT',
  DOWNLOAD_CSV: 'DOWNLOAD_CSV',
  DELETE_EVENT: 'DELETE_EVENT',
  IMPORT_RESULTS_FROM_QUANTUM: 'IMPORT_RESULTS_FROM_QUANTUM',
  EXPORT_START_LIST_TO_MACSHA_ONE_4_ALL: 'EXPORT_START_LIST_TO_MACSHA_ONE_4_ALL',
  IMPORT_RESULTS_FROM_MACSHA_ONE_4_ALL: 'IMPORT_RESULTS_FROM_MACSHA_ONE_4_ALL',
  CLEAN_SCHOOL_LIST: 'CLEAN_SCHOOL_LIST',
  REMOVE_SCHOOLS: 'REMOVE_SCHOOLS',
  SEND_EVENT_NOTIFICATION: 'SEND_EVENT_NOTIFICATION',
  SEND_EVENT_NOTIFICATION_ELIGIBLE_STUDENTS: 'SEND_EVENT_NOTIFICATION_ELIGIBLE_STUDENTS',
  IMPORT_RESULTS: 'IMPORT_RESULTS',
  IMPORT_RESULTS_FROM_QUANTUM_AQ: 'IMPORT_RESULTS_FROM_QUANTUM_AQ',
  CLONE_TOURNAMENT_EVENT: 'CLONE_TOURNAMENT_EVENT',
  ARBITER_BOARD: 'ARBITER_BOARD',
  ADD_VIDEO: 'ADD_VIDEO',
  EXTENDED_PARTICIPANT_LIST: 'EXTENDED_PARTICIPANT_LIST',
  EXTENDED_TEAM_SHEET: 'EXTENDED_TEAM_SHEET',
  EVENT_CONSENT_REQUESTS: 'EVENT_CONSENT_REQUESTS',
  EVENT_AVAILABILITY_REPORTS: 'EVENT_AVAILABILITY_REPORTS'
};

export const EVENT_RESULTS_MODE = {
  POINTS: 'POINTS',
  RESULTS: 'RESULTS',
  PLACES: 'PLACES'
};

export const EVENT_RESULTS_MODE_MAPPING = {
  POINTS: 'Points',
  RESULTS: 'Results',
  PLACES: 'Places'
};

export const EVENT_ADD_TEAM = {
  NEW: 'NEW',
  EXISTING: 'EXISTING',
  COPY: 'COPY'
};

export const CLUB_EVENT_EDIT_MODE = {
  GROUP: 'GROUP',
  SINGLE: 'SINGLE'
};
