import * as React from 'react';
import * as propz from 'propz';
import * as Moment from 'moment';
import { AppUser } from 'Src/views/App/App';
import { SchoolEvent } from 'Src/models/event';
import { SPORT_GENDER_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/common';
import {
  getPitchNameFromTournamentByPitchId,
  getSchoolEventLocation,
  getTournamentGroupNameByGroupId
} from 'Src/helpers/event/schoolEventViewHelper';
import { getSchoolEventAges, isClubEvent } from 'Src/helpers/event/event';
import { getEventStatus, getEventGeneratedName } from 'Src/helpers/accessor/accessor';
import { EVENT_STATUS_SERVER_TO_CLIENT_MAPPING } from '../../../../../consts/event';
import * as classNames from 'classnames';

interface Props {
  user: AppUser;
  event: SchoolEvent;
  onViewMapClick: () => void;
}

export function StudentEventMainInformation(props: Props) {
  const { event, user, onViewMapClick } = props;
  const { tournamentGroupId, quantumEventNumber, pitchId, startTime, endTime, sport, gender, tournament } = event;

  const tournamentName = propz.get(tournament, ['name']);
  const tournamentCustomGroups = propz.get(tournament, ['customGroups'], []);
  const isTournamentNameExist = typeof tournamentName !== 'undefined';
  const isTournamentGroupIdExist = typeof tournamentGroupId !== 'undefined';
  const tournamentGroupName = getTournamentGroupNameByGroupId(tournamentGroupId, tournamentCustomGroups);
  const isTournamentGroupNameExist = isTournamentGroupIdExist && tournamentGroupName !== '';

  const isQuantumNumberExist = typeof quantumEventNumber !== 'undefined';

  const isPitchIdExist = typeof pitchId !== 'undefined';
  const pitchName = getPitchNameFromTournamentByPitchId(tournament || {}, pitchId);

  const eventAges = getSchoolEventAges(event, user);
  const eventLocation = getSchoolEventLocation(event, user);
  const eventSpecificLocation = propz.get(event, ['location'], '');
  const isExistEventSpecificLocation = eventSpecificLocation !== '';

  const eventStartDateFormatted = Moment(startTime).format('D MMMM YYYY');
  const eventStartTimeFormatted = Moment(startTime).format('HH:mm');

  const isEndTimeExist = typeof endTime !== 'undefined';
  const eventEndDateFormatted = Moment(endTime).format('D MMMM YYYY');
  const eventEndTimeFormatted = Moment(endTime).format('HH:mm');
  const eventSportName = sport.name;
  const eventOfficialName = getEventGeneratedName(event, { user: user });
  const eventGender = SPORT_GENDER_SERVER_TO_CLIENT_MAPPING[gender];
  const eventStatus = getEventStatus(event);
  const isRedColor = EVENT_STATUS_SERVER_TO_CLIENT_MAPPING.CANCELED === eventStatus;
  const classes = classNames({
    fontWeightBold: true,
    eCancelledStatusEvent: isRedColor
  });

  return (
    <div>
      <div className={'mt-3 h4 font-weight-bold'}>{eventOfficialName}</div>
      <div className={`${classes} font-weight-bold`}>{eventStatus}</div>
      <div>{`${eventSportName}`}</div>
      <div>{`Start time: ${eventStartDateFormatted}   ${eventStartTimeFormatted}`}</div>
      {isEndTimeExist && <div>{`Finish/Collection time: ${eventEndDateFormatted}    ${eventEndTimeFormatted}`}</div>}
      {isTournamentNameExist && <div>{`Tournament name: ${tournamentName}`}</div>}
      {isQuantumNumberExist && <div>{`Quantum number: ${quantumEventNumber}`}</div>}
      {isTournamentGroupNameExist && <div>{`Group: ${tournamentGroupName}`}</div>}
      {isPitchIdExist && pitchName !== '' && <div>{`Pitch: ${pitchName}`}</div>}
      <div>{`Years: ${eventAges}`}</div>
      <div>{`Gender: ${eventGender}`}</div>
      {isClubEvent(event) ? (
        <>
          <div className="d-flex">
            <div className="mr-3">{`Location: ${eventLocation}`}</div>
            <div className="bViewMap" onClick={onViewMapClick}>
              <span>View map</span>
              <img src="/dist/images/icons/location.png" alt="Location icon" />
            </div>
          </div>

          {isExistEventSpecificLocation && <div>{`Specific location: ${eventSpecificLocation}`}</div>}
        </>
      ) : (
        <div className="d-flex">
          <div className="mr-3">{`Venue: ${eventLocation}`}</div>
          <div className="bViewMap" onClick={onViewMapClick}>
            <span>View map</span>
            <img src="/dist/images/icons/location.png" alt="Location icon" />
          </div>
        </div>
      )}
    </div>
  );
}
