import * as React from 'react';
import { FunctionComponent } from 'react';

export const RotateIconLeft: FunctionComponent = () => {
  return (
    <div>
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="30px"
        height="30px"
        viewBox="0 0 481.95 481.95"
        fill="#8C8C8C"
        style={{ cursor: 'pointer' }}
      >
        <g>
          <g id="rotate-left">
            <path
              d="M114.75,191.25l-35.7-35.7C56.1,186.15,40.8,219.3,38.25,255h51C91.8,232.05,102,211.65,114.75,191.25z M89.25,306h-51
                     c5.1,35.7,17.85,68.85,40.8,99.45l35.7-35.7C102,349.35,91.8,328.95,89.25,306z M114.75,441.15
                     c30.6,22.949,63.75,35.699,99.45,40.8v-51c-22.95-2.55-43.35-12.75-63.75-25.5L114.75,441.15z M265.2,79.05V0L150.45,114.75
                     L265.2,229.5v-99.45c71.4,12.75,127.5,73.95,127.5,150.45s-56.1,137.7-127.5,150.45v51c99.45-12.75,178.5-99.45,178.5-201.45
                     S364.65,91.8,265.2,79.05z"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

RotateIconLeft.displayName = 'RotateIconLeft';
