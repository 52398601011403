import * as React from 'react';
import * as propz from 'propz';
import { getSelectOptionForPositionsSport } from '../../helpers/table/select';
import { FunctionComponent } from 'react';
import { isClubEvent, isHousesEvent, isTeamOrTwoOnTwoSportEvent } from '../../helpers/event/event';
import { SchoolEvent } from '../../models/event';
import { Sport } from '../../models/sport';

interface Props {
  schoolEvent: SchoolEvent;
  sport: Sport;
  players: SchoolEventPlayerSummary[];
  teams: SchoolEventTeamSummary[];
  activeTeamIndex: number;
  isEditPositions: boolean;
  onChangeTeam: (event) => void;
  onChangePositionPlayer: (event, playerId: string) => void;
  onChangeIsSubPlayer: (event, playerId: string) => void;
  onChangeIsCaptainPlayer: (event, playerId: string) => void;
}

export interface SchoolEventPlayerSummary {
  name: string;
  form: string;
  playerId: string;
  positionId?: string;
  isCaptain?: boolean;
  isSub?: boolean;
  teamId?: string;
}

export interface SchoolEventTeamSummary {
  teamName: string;
  teamId: string;
  houseName?: string;
}

export const SchoolEventPlayersSummary: FunctionComponent<Props> = props => {
  const { isEditPositions, sport, schoolEvent, teams, players, activeTeamIndex } = props;
  const positions = getSelectOptionForPositionsSport(sport);
  const isTeamsExist = teams.length > 0;
  const isPositionsExist = positions.length > 0;
  const teamId = isTeamsExist ? teams[activeTeamIndex].teamId : undefined;

  const renderPositionsOptions = () => {
    return (
      <>
        <option value={''}>Not selected</option>;
        {positions.map(position => {
          return (
            <option key={position.value} value={position.value}>
              {position.text}
            </option>
          );
        })}
      </>
    );
  };

  const renderTeamOptions = () => {
    return teams.map((team, index) => {
      const teamName = isHousesEvent(schoolEvent) ? `${team.teamName} / ${team.houseName}` : team.teamName;
      return (
        <option key={index} value={team.teamId}>
          {teamName}
        </option>
      );
    });
  };

  const renderSelectTeam = () => {
    return (
      <div className="form-group">
        <label>Team name: </label>
        <select className="form-control mb-3" onChange={e => props.onChangeTeam(e)} value={teamId}>
          {renderTeamOptions()}
        </select>
      </div>
    );
  };

  const renderPlayers = () => {
    const textPlayers = isClubEvent(schoolEvent) ? 'No participants have been added yet.' : 'No participants';

    if (players.length > 0) {
      return players
        .filter(player => (isTeamsExist ? player.teamId === teamId : true))
        .map((player, index) => {
          const playerName = player.name;
          const playerForm = player.form;

          return (
            <tr key={player.playerId}>
              <td>{index + 1}</td>
              <td>{playerName}</td>
              <td>{playerForm}</td>
              {isPositionsExist && !isClubEvent(schoolEvent) && (
                <td>
                  <select
                    className="form-control"
                    onChange={e => props.onChangePositionPlayer(e, player.playerId)}
                    value={player.positionId ? player.positionId : ''}
                    disabled={!isEditPositions}
                  >
                    {renderPositionsOptions()}
                  </select>
                </td>
              )}

              {isTeamOrTwoOnTwoSportEvent(schoolEvent) && !isClubEvent(schoolEvent) && (
                <td>
                  <input
                    id="captain"
                    type="checkbox"
                    className="ml-3"
                    checked={player.isCaptain}
                    onChange={e => props.onChangeIsCaptainPlayer(e, player.playerId)}
                    disabled={!isEditPositions}
                  />
                </td>
              )}
              {isTeamOrTwoOnTwoSportEvent(schoolEvent) && !isClubEvent(schoolEvent) && (
                <td>
                  <input
                    id="sub"
                    type="checkbox"
                    className="ml-1"
                    defaultChecked={player.isSub}
                    onChange={e => props.onChangeIsSubPlayer(e, player.playerId)}
                    disabled={!isEditPositions}
                  />
                </td>
              )}
            </tr>
          );
        });
    } else {
      return (
        <tr key={'team_players_empty_row'}>
          <td>{textPlayers}</td>
        </tr>
      );
    }
  };

  const picUrl = propz.get(sport, ['field', 'pic']);
  const picUrlExist = typeof picUrl !== 'undefined';
  const tableClass = picUrlExist && !isClubEvent(schoolEvent) ? 'w-75' : '';
  const isShowSelectTeam = isTeamsExist && !isClubEvent(schoolEvent);

  return (
    <div>
      {isShowSelectTeam && renderSelectTeam()}
      <div className="d-flex">
        <table className={`${tableClass} table table-sm mPre`}>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Form</th>
              {isPositionsExist && !isClubEvent(schoolEvent) && <th>Position</th>}
              {isTeamOrTwoOnTwoSportEvent(schoolEvent) && !isClubEvent(schoolEvent) && <th>Captain</th>}
              {isTeamOrTwoOnTwoSportEvent(schoolEvent) && !isClubEvent(schoolEvent) && <th>Sub</th>}
            </tr>
          </thead>
          <tbody>{renderPlayers()}</tbody>
        </table>
        {picUrlExist && !isClubEvent(schoolEvent) && (
          <div className="w-25">
            <img className="img-fluid img-thumbnail" src={picUrl} alt="News picture" />
          </div>
        )}
      </div>
    </div>
  );
};
