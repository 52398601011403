import * as React from 'react';
import { Component } from 'react';
import * as propz from 'propz';
import { AppUser } from 'Src/views/App/App';
import { Map } from 'Src/components/Map/Map';
import { EventParticipationMessage } from '../../../../../models/message';
import * as Moment from 'moment';
import { VENUE_SERVER_TO_CLIENT_MAPPING } from '../../../../../consts/venue';

interface Props {
  user: AppUser;
  message: EventParticipationMessage;
  onClickGotIt: () => void;
  closeAvailabilityReportModal: () => void;
}
interface State {
  isMapVisible: boolean;
  isLoading: boolean;
  errorMessage: string;
  isErrorModalOpen: boolean;
}

export class AvailabilityReportForm extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isMapVisible: false,
      errorMessage: '',
      isLoading: false,
      isErrorModalOpen: false
    };
  }

  getAges(): string {
    const { message } = this.props;
    const ages = propz.get(message, ['eventData', 'ages']);
    const isAgesExist = typeof ages !== 'undefined';
    let result = '';

    if (isAgesExist) {
      if (ages.length === 0) {
        result = 'All ages';
      } else {
        result = ages.map(ageItem => (ageItem === 0 ? 'Reception' : 'Y' + ageItem)).join(', ');
      }
    }
    return result;
  }

  getVenue(): string {
    const { message } = this.props;
    const postcode = propz.get(message, ['eventData', 'venue', 'postcodeData', 'postcode'], '');
    const placeName = propz.get(
      message,
      ['eventData', 'venue', 'placeData', 'name'],
      VENUE_SERVER_TO_CLIENT_MAPPING.HOME
    );
    const venueType = propz.get(message, ['eventData', 'venue', 'venueType'], VENUE_SERVER_TO_CLIENT_MAPPING.TBD);

    switch (true) {
      case venueType === VENUE_SERVER_TO_CLIENT_MAPPING.TBD:
        return 'TBD';
      case typeof placeName !== 'undefined':
        return `${placeName}, ${postcode}`;
      default:
        return postcode;
    }
  }

  toggleMap = () => {
    const { isMapVisible } = this.state;
    this.setState({
      isMapVisible: !isMapVisible
    });
  };

  render() {
    const { message } = this.props;
    const { isMapVisible } = this.state;
    const firstName = propz.get(message, ['playerDetailsData', 'firstName'], '');
    const lastName = propz.get(message, ['playerDetailsData', 'lastName'], '');
    const fullName = `${firstName} ${lastName}`;
    const school = propz.get(message, ['schoolData', 'name'], '');
    const startTime = propz.get(message, ['eventData', 'startTime'], '');
    const startTimeFormatted = Moment(startTime).format('DD.MM.YYYY / HH:mm');
    const sportName = propz.get(message, ['eventData', 'sport', 'name'], '');
    const ages = this.getAges();
    const postcode = this.getVenue();
    const point = propz.get(message, ['eventData', 'venue', 'point']);
    const picUrl = propz.get(message, ['schoolData', 'pic'], '');
    const isSchoolLogoExist = picUrl !== '';
    const isTakePart = message.isTakePart ? 'yes' : 'no';

    return (
      <>
        <div className={'eClubInvitationFormRow'}>
          <img
            src={isSchoolLogoExist ? picUrl : '/dist/images/no-image.jpg'}
            alt="school logo"
            className={'bClubInvitationFormLogo'}
          />

          <div className="d-flex flex-column ml-3">
            <div className="eEventMessageInfoText">{fullName}</div>
            <div className="eEventMessageInfoText">{school}</div>
            <div className="">{`${sportName} / ${ages}`}</div>
            <div>
              <span className="">Time:</span>
              {startTimeFormatted}
            </div>
            <div>
              <span className="">{'Venue:'} </span>
              {postcode}
            </div>
            <div className="eEventMessageInfoText">{`${fullName}can take part: ${isTakePart}`}</div>
          </div>
        </div>

        <div className="eClubInvitationFormRow">
          <img src={'/dist/images/icons/pin.png'} alt="pin icon" className={'bClubInvitationFormIcon'} />
          <div className="bClubInvitationFormMap" onClick={this.toggleMap}>
            View map
          </div>
        </div>

        {isMapVisible && <Map point={point} customStylingClass="mb-3 eClubWizardMap" isMarkerDraggable={false} />}

        <button onClick={this.props.closeAvailabilityReportModal} className="btn btn-secondary mr-2">
          Cancel
        </button>

        <>
          <button onClick={this.props.onClickGotIt} className="btn btn-primary mr-2">
            Got it
          </button>
        </>
      </>
    );
  }
}
