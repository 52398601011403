import * as React from 'react';
import './LoginForm.css';
import { Link } from 'react-router-dom';

interface Props {
  onEmailChange: (event: React.FormEvent<HTMLInputElement>) => void;
  onPasswordChange: (event: React.FormEvent<HTMLInputElement>) => void;
  onRememberMeChange: (event: React.FormEvent<HTMLInputElement>) => void;
  onFormSubmit: (event: React.SyntheticEvent) => void;
  email: string;
  password: string;
  isRememberMe: boolean;
  isError: boolean;
}

export function LoginForm(props: Props) {
  const {
    onFormSubmit,
    isError,
    onEmailChange,
    email,
    password,
    onPasswordChange,
    isRememberMe,
    onRememberMeChange
  } = props;
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="bFormSignIn">
            <div className="eFormSignIn">
              <form onSubmit={onFormSubmit}>
                <div className="eFormSignInImg">
                  <img src="/dist/images/logoImage.png" alt={'Logo image'} />
                  <img src="/dist/images/logoText.png" alt={'Logo text'} />
                </div>
                <div className="mb-3 font-weight-normal">
                  Sign in or <Link to={{ pathname: '/registration' }}> join us for free</Link>
                </div>

                {isError && <div className="alert alert-danger">Incorrect email or password.</div>}

                <input
                  type="email"
                  className="form-control"
                  placeholder="Email address"
                  value={email}
                  onChange={onEmailChange}
                  id="login_email"
                />
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  id="login_password"
                  value={password}
                  onChange={onPasswordChange}
                />
                <div className="checkbox mb-3">
                  <input type="checkbox" checked={isRememberMe} onChange={onRememberMeChange} /> Remember me
                </div>
                <button className="btn btn-lg btn-primary btn-block" type="submit" id="login_submit">
                  Sign in
                </button>
              </form>
              <Link className={'nav-link '} to={{ pathname: '/resetPassword' }}>
                Forgot password
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
