import * as React from 'react';
import { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from '../../../../../../consts/report';
import { Switch } from 'Src/components/Switch/Switch';
import { LabelWithQuestionIcon } from '../../../../../../components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import { MultiSelectCheckboxes } from '../../../../../../components/MultiSelectCheckboxes/MultiSelectCheckboxes';
import {
  getReportDefaultGendersForMultiSelect,
  getReportAgesForMultiSelect
} from '../../../../../../helpers/multiselect/multiselect';
import { School } from '../../../../../../models/school';
import { SchoolForm } from '../../../../../../models/form';
import '../SchoolEvents.scss';

interface Props {
  onCloseClick: () => void;
  onSubmit: (data: any, reportType: string) => void;
  school: School;
  forms: SchoolForm[];
}

// report 031
export function FixtureHistoryReport({ onCloseClick, onSubmit, school, forms }: Props) {
  const genders = getReportDefaultGendersForMultiSelect();
  const ages = getReportAgesForMultiSelect(school, forms);

  const [isAgesAndGendersChecked, setIsAgesAndGendersChecked] = useState(true);

  const requestInitial = {
    isShowInterSchool: true,
    isShowHouses: true,
    isShowIntraSchool: true,
    isShowTournament: true,
    genders,
    ages,
    isSelectAllAges: true
  };

  const onGendersChange = (index, values, setFieldValue) => {
    const genderItems = values.genders;
    const ageItems = values.ages;

    const genderItemsUpdated = genderItems.map((genderItem, genderIndex) => {
      return genderIndex === index
        ? {
            ...genderItem,
            isChecked: !genderItem.isChecked
          }
        : genderItem;
    });

    const gendersChecked = genderItemsUpdated.map(gender => gender.isChecked);
    const agesChecked = ageItems.map(age => age.isChecked);
    const isCheckedGendersExist = gendersChecked.includes(true);
    const isCheckedAgesExist = agesChecked.includes(true);

    setIsAgesAndGendersChecked(isCheckedGendersExist && isCheckedAgesExist);
    setFieldValue('genders', genderItemsUpdated);
  };

  const onAgesChange = (index, values, setFieldValue) => {
    const ageItems = values.ages;
    const genderItems = values.genders;

    const ageItemsUpdated = ageItems.map((ageItem, ageIndex) => {
      return ageIndex === index
        ? {
            ...ageItem,
            isChecked: !ageItem.isChecked
          }
        : ageItem;
    });

    const gendersChecked = genderItems.map(gender => gender.isChecked);
    const agesChecked = ageItemsUpdated.map(age => age.isChecked);
    const isCheckedGendersExist = gendersChecked.includes(true);
    const isCheckedAgesExist = agesChecked.includes(true);

    const isSelectAllAges = ageItemsUpdated.every(age => age.isChecked === true);

    setIsAgesAndGendersChecked(isCheckedAgesExist && isCheckedGendersExist);
    setFieldValue('isSelectAllAges', isSelectAllAges);
    setFieldValue('ages', ageItemsUpdated);
  };

  const onSelectAllAgesChange = (event, values, setFieldValue) => {
    const isSelectAllAges = event.target.checked;
    const ageItems = values.ages;
    const genderItems = values.genders;

    const ageItemsUpdated = ageItems.map(age => {
      return {
        ...age,
        isChecked: isSelectAllAges
      };
    });

    const gendersChecked = genderItems.map(gender => gender.isChecked);
    const agesChecked = ageItemsUpdated.map(age => age.isChecked);
    const isCheckedGendersExist = gendersChecked.includes(true);
    const isCheckedAgesExist = agesChecked.includes(true);

    setIsAgesAndGendersChecked(isCheckedAgesExist && isCheckedGendersExist);
    setFieldValue('isSelectAllAges', isSelectAllAges);
    setFieldValue('ages', ageItemsUpdated);
  };

  return (
    <Formik
      onSubmit={values => onSubmit(values, REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.FIXTURE_HISTORY)}
      initialValues={requestInitial}
      render={({ values, setFieldValue }) => (
        <Form>
          <div className="bReportFormTitle">Year-on-year fixtures history</div>
          <div className="mb-3 mt-3">
            {'A report to show all your sports fixtures you have entered onto Squad In Touch.' +
              'The numbers are shown for each academic year for which the data is stored. Please' +
              'be aware that for the current academic year it will not only show past fixtures' +
              'but also all the fixtures you have scheduled for future'}
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isShowInterSchool"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowInterSchool}
                    onChange={event => {
                      const isShowInterSchool = event.target.checked;
                      setFieldValue('isShowInterSchool', isShowInterSchool);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show inter-school fixtures"
                hintText={`Show events where you're playing against another school.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowInterSchool"
              />
            </div>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isShowHouses"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowHouses}
                    onChange={event => {
                      const isShowHouses = event.target.checked;
                      setFieldValue('isShowHouses', isShowHouses);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show house fixtures"
                hintText={`Show events where it is internal house matches.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowHouses"
              />
            </div>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isShowIntraSchool"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowIntraSchool}
                    onChange={event => {
                      const isShowIntraSchool = event.target.checked;
                      setFieldValue('isShowIntraSchool', isShowIntraSchool);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show internal fixtures"
                hintText={`Show internal matches.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowIntraSchool"
              />
            </div>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isShowTournament"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowTournament}
                    onChange={event => {
                      const isShowTournament = event.target.checked;
                      setFieldValue('isShowTournament', isShowTournament);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show tournament events"
                hintText={`Show games from tournaments your school participated in.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowTournament"
              />
            </div>
          </div>

          <div className={'mt-3 mb-3'}>
            <MultiSelectCheckboxes
              items={values.genders}
              cols={3}
              onChange={index => onGendersChange(index, values, setFieldValue)}
            />
          </div>

          <div className={'mt-3 mb-3'}>
            <MultiSelectCheckboxes
              items={values.ages}
              cols={3}
              onChange={index => onAgesChange(index, values, setFieldValue)}
            />
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isSelectAllAges"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isSelectAllAges}
                    customClass="mb-3"
                    onChange={event => onSelectAllAgesChange(event, values, setFieldValue)}
                  />
                )}
              />

              <label htmlFor="isSelectAllAges" className="form-check-label pl-2">
                Select all ages
              </label>
            </div>
          </div>

          <button type="button" className="btn btn-secondary mr-3" onClick={onCloseClick}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary" disabled={!isAgesAndGendersChecked}>
            Get
          </button>
        </Form>
      )}
    />
  );
}
