import * as React from 'react';
import * as propz from 'propz';
import { AppUser } from 'Src/views/App/App';
import { SchoolEvent } from 'Src/models/event';
import { Photo } from 'Src/models/photo';
import * as classNames from 'classnames';
import { GALLERY_MODE, LAYOUT_WIDTH, PHOTO_WIDTH } from 'Src/consts/album';
import { PreviewPhoto } from './PreviewPhoto';
import './Gallery.scss';
import { ACCESS_PRESET_TYPE, GALLERY_MODE_TYPE } from 'Src/types/album';
import { FullScreenPhoto } from './FullScreenPhoto';
import { ROLE } from 'Src/consts/user';

interface Props {
  user: AppUser;
  eventInitial: SchoolEvent;
  photos: Photo[];
  onDeletePhotoClick: (id: string) => void;
  isUpdateAccessPreset: boolean;
  onAccessPresetClick: (id: string, accessPreset: ACCESS_PRESET_TYPE) => void;
  onChangeSchoolPhotoInfoClick?: (
    id: string,
    photoInfo: {
      name?: string;
      description?: string;
      picUrl?: string;
      accessPreset?: string;
    }
  ) => void;
}

interface State {
  currentPhotoIndex: number;
  windowWidth: number;
  mode: GALLERY_MODE_TYPE;
  currentFullScreenPhotoId: string;
}

export class EventGallery extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      currentPhotoIndex: 0,
      windowWidth: window.innerWidth,
      mode: GALLERY_MODE.PREVIEW,
      currentFullScreenPhotoId: ''
    };
  }

  onLeft = () => {
    const { currentPhotoIndex } = this.state;
    const index = currentPhotoIndex === 0 ? 0 : currentPhotoIndex - 1;

    this.setState({
      currentPhotoIndex: index
    });
  };

  onRight = () => {
    const { photos } = this.props;
    const photosCount = photos.length;
    const { currentPhotoIndex, windowWidth } = this.state;
    const index =
      currentPhotoIndex >=
      photosCount - Math.floor((windowWidth < LAYOUT_WIDTH ? windowWidth - 60 : LAYOUT_WIDTH) / PHOTO_WIDTH)
        ? currentPhotoIndex
        : currentPhotoIndex + 1;

    this.setState({
      currentPhotoIndex: index
    });
  };

  renderPhotos() {
    const { photos, onDeletePhotoClick } = this.props;
    return photos.map(photo => (
      <PreviewPhoto
        key={photo.id}
        id={photo.id}
        url={photo.picUrl}
        accessPreset={photo.accessPreset}
        isOnSale={photo.isOnSale}
        saleLink={photo.saleLink}
        onDeletePhotoClick={onDeletePhotoClick}
        onPhotoClick={this.onPhotoClick}
      />
    ));
  }

  onPhotoClick = (id: string): void => {
    this.setState({
      mode: GALLERY_MODE.FULLSCREEN,
      currentFullScreenPhotoId: id
    });
  };

  onCloseClick = (): void => {
    this.setState({
      mode: GALLERY_MODE.PREVIEW,
      currentFullScreenPhotoId: ''
    });
  };

  getCurrentPhotoIndex() {
    const { photos } = this.props;
    const { currentFullScreenPhotoId } = this.state;
    return photos.findIndex(photo => photo.id === currentFullScreenPhotoId);
  }

  onPreviousPhotoClick = () => {
    const { photos } = this.props;
    const currentPhotoIndex = this.getCurrentPhotoIndex();
    const photosCount = photos.length;

    const currentFullScreenPhotoId =
      currentPhotoIndex === 0 ? photos[photosCount - 1].id : photos[currentPhotoIndex - 1].id;

    this.setState({ currentFullScreenPhotoId: currentFullScreenPhotoId });
  };

  onNextPhotoClick = () => {
    const { photos } = this.props;
    const currentPhotoIndex = this.getCurrentPhotoIndex();
    const photosCount = photos.length;

    const currentFullScreenPhotoId =
      currentPhotoIndex === photosCount - 1 ? photos[0].id : photos[currentPhotoIndex + 1].id;

    this.setState({ currentFullScreenPhotoId: currentFullScreenPhotoId });
  };

  onAccessPresetClick = accessPreset => {
    const { currentFullScreenPhotoId } = this.state;
    const { onAccessPresetClick } = this.props;

    onAccessPresetClick(currentFullScreenPhotoId, accessPreset);
  };

  renderFullScreenPhoto() {
    const { mode, currentFullScreenPhotoId } = this.state;
    const {
      photos,
      isUpdateAccessPreset,
      user,
      onChangeSchoolPhotoInfoClick,
      eventInitial,
      onDeletePhotoClick
    } = this.props;
    const photosCount = photos.length;
    const albumId = propz.get(eventInitial, ['albumId'], '');

    if (mode === GALLERY_MODE.FULLSCREEN) {
      const currentPhoto = photos.find(photo => photo.id === currentFullScreenPhotoId);
      const authorSchoolId = propz.get(currentPhoto, ['author', 'schoolId']);
      const { activeSchoolId, activeRole } = user;

      const isUserParentOrStudent = activeRole === ROLE.STUDENT || activeRole === ROLE.PARENT;

      const isShowAccessPresetRadioButtons = !isUserParentOrStudent && activeSchoolId === authorSchoolId;

      return (
        <FullScreenPhoto
          user={user}
          photo={currentPhoto}
          isShowArrowButtons={photosCount > 1}
          onPreviousPhotoClick={this.onPreviousPhotoClick}
          onNextPhotoClick={this.onNextPhotoClick}
          onCloseClick={this.onCloseClick}
          onAccessPresetClick={this.onAccessPresetClick}
          isUpdateAccessPreset={isUpdateAccessPreset}
          isShowAccessPresetRadioButtons={isShowAccessPresetRadioButtons}
          onDeletePhotoClick={onDeletePhotoClick}
          onChangeSchoolPhotoInfoClick={onChangeSchoolPhotoInfoClick}
          isShowEditPhotoPanel={true}
          albumId={albumId}
        />
      );
    } else {
      return null;
    }
  }

  render() {
    const { windowWidth, currentPhotoIndex } = this.state;
    const { photos } = this.props;
    const windowWidthCorrected = windowWidth < LAYOUT_WIDTH ? windowWidth - 60 : LAYOUT_WIDTH;
    const photosCount = photos.length;
    const widthStrip = photosCount * PHOTO_WIDTH;
    const offset = currentPhotoIndex * PHOTO_WIDTH;
    const margin =
      offset + windowWidthCorrected <= widthStrip || offset === 0 ? -offset : windowWidthCorrected - widthStrip;
    const style = { width: widthStrip, marginLeft: margin };

    const lBtnClasses = classNames({
      eArrow: true,
      mLeft: true,
      mHidden: offset === 0
    });

    const rBtnClasses = classNames({
      eArrow: true,
      mRight: true,
      mHidden: windowWidthCorrected > widthStrip || margin !== -offset
    });

    return (
      <div className="bGallery">
        {photosCount > 0 && <h3>Photos</h3>}
        <div className="bPhotos">
          <div className="ePhotoStrip" style={style}>
            {this.renderPhotos()}
            {this.renderFullScreenPhoto()}
          </div>
        </div>
        <div className={lBtnClasses} onClick={this.onLeft} />
        <div className={rBtnClasses} onClick={this.onRight} />
      </div>
    );
  }
}
