import * as React from 'react';
import * as propz from 'propz';
import { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import DatePicker from 'react-datepicker';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from '../../../../../../../consts/report';
import { Switch } from '../../../../../../../components/Switch/Switch';
import { LabelWithQuestionIcon } from '../../../../../../../components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import { MultiSelectCheckboxes } from '../../../../../../../components/MultiSelectCheckboxes/MultiSelectCheckboxes';
import {
  getReportDefaultGendersForMultiSelect,
  getReportAgesForMultiSelect
} from '../../../../../../../helpers/multiselect/multiselect';
import { School } from '../../../../../../../models/school';
import { SchoolForm } from '../../../../../../../models/form';
import '../../Clubs.css';

const OPTIONS = {
  IS_CURRENT_WEEK: 'isCurrentWeek',
  IS_NEXT_WEEK: 'isNextWeek'
};

interface Props {
  onCloseClick: () => void;
  onSubmit: (data: any, reportType: string) => void;
  school: School;
  forms: SchoolForm[];
}

// report 022
export function WeeklyClubScheduleReportForm({ onCloseClick, onSubmit, school, forms }: Props) {
  const genders = getReportDefaultGendersForMultiSelect();
  const ages = getReportAgesForMultiSelect(school, forms);
  const [isAgesAndGendersChecked, setIsAgesAndGendersChecked] = useState(true);
  const requestInitial = {
    options: OPTIONS.IS_CURRENT_WEEK,
    isCurrentWeek: false,
    isNextWeek: false,
    genders,
    ages,
    isSelectAllAges: true
  };

  const onGendersChange = (index, values, setFieldValue) => {
    const genderItems = values.genders;
    const ageItems = values.ages;

    const genderItemsUpdated = genderItems.map((genderItem, genderIndex) => {
      return genderIndex === index
        ? {
            ...genderItem,
            isChecked: !genderItem.isChecked
          }
        : genderItem;
    });

    const gendersChecked = genderItemsUpdated.map(gender => gender.isChecked);
    const agesChecked = ageItems.map(age => age.isChecked);
    const isCheckedGendersExist = gendersChecked.includes(true);
    const isCheckedAgesExist = agesChecked.includes(true);

    setIsAgesAndGendersChecked(isCheckedGendersExist && isCheckedAgesExist);
    setFieldValue('genders', genderItemsUpdated);
  };

  const onAgesChange = (index, values, setFieldValue) => {
    const ageItems = values.ages;
    const genderItems = values.genders;

    const ageItemsUpdated = ageItems.map((ageItem, ageIndex) => {
      return ageIndex === index
        ? {
            ...ageItem,
            isChecked: !ageItem.isChecked
          }
        : ageItem;
    });

    const gendersChecked = genderItems.map(gender => gender.isChecked);
    const agesChecked = ageItemsUpdated.map(age => age.isChecked);
    const isCheckedGendersExist = gendersChecked.includes(true);
    const isCheckedAgesExist = agesChecked.includes(true);

    const isSelectAllAges = ageItemsUpdated.every(age => age.isChecked === true);

    setIsAgesAndGendersChecked(isCheckedAgesExist && isCheckedGendersExist);
    setFieldValue('isSelectAllAges', isSelectAllAges);
    setFieldValue('ages', ageItemsUpdated);
  };

  const onSelectAllAgesChange = (event, values, setFieldValue) => {
    const isSelectAllAges = event.target.checked;
    const ageItems = values.ages;
    const genderItems = values.genders;

    const ageItemsUpdated = ageItems.map(age => {
      return {
        ...age,
        isChecked: isSelectAllAges
      };
    });

    const gendersChecked = genderItems.map(gender => gender.isChecked);
    const agesChecked = ageItemsUpdated.map(age => age.isChecked);
    const isCheckedGendersExist = gendersChecked.includes(true);
    const isCheckedAgesExist = agesChecked.includes(true);

    setIsAgesAndGendersChecked(isCheckedAgesExist && isCheckedGendersExist);
    setFieldValue('isSelectAllAges', isSelectAllAges);
    setFieldValue('ages', ageItemsUpdated);
  };

  return (
    <Formik
      onSubmit={values => {
        const valuesToTransfer = { ...values };
        propz.set(valuesToTransfer, [valuesToTransfer.options], true);
        delete valuesToTransfer.options;

        onSubmit(valuesToTransfer, REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.WEEKLY_CLUB_SCHEDULE);
      }}
      initialValues={requestInitial}
      render={({ values, setFieldValue }) => (
        <Form>
          <div className="bReportFormTitle">Weekly club schedule</div>
          <div className="mb-3 mt-3">{'View your school club timetable for the selected week.'}</div>

          <div className="form-group">
            <div className="form-check mb-1">
              <input
                className="form-check-input"
                type="radio"
                name={OPTIONS.IS_CURRENT_WEEK}
                id={OPTIONS.IS_CURRENT_WEEK}
                value={OPTIONS.IS_CURRENT_WEEK}
                checked={values.options === OPTIONS.IS_CURRENT_WEEK}
                onChange={() => {
                  setFieldValue('options', OPTIONS.IS_CURRENT_WEEK);
                }}
              />
              <LabelWithQuestionIcon
                labelText="This week"
                hintText={'Select to view club timetable for the current week'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor={OPTIONS.IS_CURRENT_WEEK}
              />
            </div>

            <div className="form-check mb-1">
              <input
                className="form-check-input"
                type="radio"
                name={OPTIONS.IS_NEXT_WEEK}
                id={OPTIONS.IS_NEXT_WEEK}
                value={OPTIONS.IS_NEXT_WEEK}
                checked={values.options === OPTIONS.IS_NEXT_WEEK}
                onChange={() => {
                  setFieldValue('options', OPTIONS.IS_NEXT_WEEK);
                }}
              />

              <LabelWithQuestionIcon
                labelText="Next week"
                hintText={'Select to view club timetable for the next week'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor={OPTIONS.IS_NEXT_WEEK}
              />
            </div>
          </div>
          <div className={'mt-3 mb-3'}>
            <MultiSelectCheckboxes
              items={values.genders}
              cols={3}
              onChange={index => onGendersChange(index, values, setFieldValue)}
            />
          </div>

          <div className={'mt-3 mb-3'}>
            <MultiSelectCheckboxes
              items={values.ages}
              cols={3}
              onChange={index => onAgesChange(index, values, setFieldValue)}
            />
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isSelectAllAges"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isSelectAllAges}
                    customClass="mb-3"
                    onChange={event => onSelectAllAgesChange(event, values, setFieldValue)}
                  />
                )}
              />

              <label htmlFor="isSelectAllAges" className="form-check-label pl-2">
                Select all ages
              </label>
            </div>
          </div>

          <button type="button" className="btn btn-secondary mr-3" onClick={onCloseClick}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Get
          </button>
        </Form>
      )}
    />
  );
}
