import * as React from 'react';
import { Component } from 'react';
import { AppUser } from 'Src/views/App/App';
import { School } from '../../../../../models/school';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { LabelWithQuestionIcon } from '../../../../../components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import { getPostcodes } from '../../../../../helpers/venue/venue';
import { getName, getPostcode, searchFunctionSchoolNinSchools } from '../../../../../helpers/autocomplete/autocomplete';
import { Autocomplete } from '../../../../../components/Autocomplete/Autocomplete';

interface Props {
  user: AppUser;
  postcode?: string;
  schools: School[];
  onCancel: () => void;
  onSubmit: (data: any) => void;
}

interface State {
  postcode: string;
}

export class SchoolFormModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      postcode: undefined
    };
  }

  getSchools = (text: string) => {
    const { schools } = this.props;
    const postcode = this.state.postcode;

    return searchFunctionSchoolNinSchools(text, schools, postcode);
  };

  render() {
    const { user } = this.props;

    return (
      <div>
        <Formik
          onSubmit={values => {
            const { schoolId } = values;
            this.props.onSubmit({ schoolId: schoolId });
          }}
          initialValues={{
            postcode: undefined,
            school: undefined,
            schoolId: ''
          }}
          render={({ setFieldValue, values }) => (
            <Form>
              <LabelWithQuestionIcon labelText="Postcode" hintText="" />
              <div className="form-row">
                <div className="form-group col-md-11">
                  <Autocomplete
                    searchFunction={text => getPostcodes(text, user)}
                    getElementTitle={getPostcode}
                    customClass="mFullWidth mb-3"
                    defaultItem={values.postcode}
                    onSelect={postcode => {
                      this.setState({ postcode });
                      setFieldValue('postcode', postcode);
                    }}
                    placeholder={'Please type your postcode'}
                  />
                </div>
                <div className="form-group col-md-1 text-right">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      setFieldValue('postcode', undefined);
                      this.setState({ postcode: undefined });
                    }}
                  >
                    X
                  </button>
                </div>
              </div>

              <LabelWithQuestionIcon labelText="School" hintText="" />
              <Field
                name="schoolId"
                render={({ field }) => {
                  return (
                    <Autocomplete
                      searchFunction={this.getSchools}
                      getElementTitle={getName}
                      customClass="mFullWidth mb-3"
                      defaultItem={values.school}
                      onSelect={school => {
                        setFieldValue('school', school);
                        setFieldValue('schoolId', school.id);
                      }}
                      placeholder={'Please select school'}
                      isItemWithImage={true}
                    />
                  );
                }}
              />
              <ErrorMessage component="div" className="alert alert-danger" name="schoolId" />

              <button className="btn btn-secondary mb-3 mr-3" onClick={this.props.onCancel}>
                Cancel
              </button>
              <button disabled={!values.school} type="submit" className="btn btn-primary mb-3 mr-3">
                Submit
              </button>
            </Form>
          )}
        />
      </div>
    );
  }
}
