import * as React from 'react';
import * as propz from 'propz';
import * as classNames from 'classnames';
import { Component } from 'react';
import {
  ColumnDefinition,
  CustomCellColumnDefinition,
  FunctionAccessorColumnDefinition,
  isCustomCellColumnDefinition,
  isFunctionAccessorBasedColumnDefinition,
  isPathAccessorBasedColumnDefinition,
  PathAccessorColumnDefinition
} from '../../helpers/table/table';
import { TextCell } from './Cells/TextCell';
import { AppUser } from 'Src/views/App/App';
import { Tournament } from '../../models/tournament';

interface Props {
  dataItems: any[];
  dataItemsSelected?: any[];
  columns: ColumnDefinition[];
  onDataItemClick: (index: number) => void;
  user?: AppUser;
  tournament?: Tournament;
  isSelectDisabled: boolean;
  stylizeCell: (cell: any) => string;
  onCellIconClick?: (data, cell) => void;
}

export class GridBody extends Component<Props> {
  constructor(props) {
    super(props);
  }

  static defaultProps = {
    dataItemsSelected: []
  };

  render() {
    const {
      dataItems,
      columns,
      dataItemsSelected,
      onDataItemClick,
      user,
      tournament,
      isSelectDisabled,
      stylizeCell,
      onCellIconClick
    } = this.props;
    const options = { user, tournament };

    const renderDataRow = (dataItem: any, columns: ColumnDefinition[]) => {
      const isStylizeCellExist = typeof stylizeCell !== 'undefined';
      const styledCell = isStylizeCellExist ? stylizeCell(dataItem) : '';

      const rowCells = columns.map(column => {
        switch (true) {
          case isPathAccessorBasedColumnDefinition(column): {
            // this can be removed once user-defined type guards will support switch-case [https://github.com/Microsoft/TypeScript/issues/2214]
            const pathAccessorBasedColumn = column as PathAccessorColumnDefinition;
            const value = propz.get<any>(dataItem, pathAccessorBasedColumn.accessor, '');
            const strValue = String(value);
            return <TextCell key={column.field} text={strValue} customClass={styledCell} />;
          }

          case isFunctionAccessorBasedColumnDefinition(column): {
            const funcAccessorColumn = column as FunctionAccessorColumnDefinition;
            const value = funcAccessorColumn.accessor(dataItem, options);
            const strValue = String(value);
            return <TextCell key={column.field} text={strValue} customClass={styledCell} />;
          }

          case isCustomCellColumnDefinition(column): {
            const cellColumn = column as CustomCellColumnDefinition;
            const cell = cellColumn.cell(dataItem, options, onCellIconClick);
            return cell;
          }

          default: {
            console.error('Not supported column type: ' + JSON.stringify(column, null, 2));
            return null;
          }
        }
      });

      return rowCells;
    };

    return (
      <tbody>
        {dataItems.map((dataItem, index) => {
          const isDataItemSelected = dataItemsSelected.some(dataItemSelected => dataItemSelected.id === dataItem.id);

          const rowClasses = classNames({
            'table-primary': isDataItemSelected
          });

          return (
            <tr key={typeof dataItem.id === 'string' ? dataItem.id : `row_${index}`} className={rowClasses}>
              <td>
                <input
                  onChange={() => {
                    if (isSelectDisabled) {
                      return;
                    } else {
                      onDataItemClick(index);
                    }
                  }}
                  className=""
                  type="checkbox"
                  checked={isDataItemSelected}
                />
              </td>
              {renderDataRow(dataItem, columns)}
            </tr>
          );
        })}
      </tbody>
    );
  }
}
