import * as React from 'react';
import { Component } from 'react';
import { Field, Form, Formik } from 'formik';
import { TournamentPitch } from 'Src/models/tournament';
import { Button } from 'Src/components/Button/Button';
import * as Yup from 'yup';

interface Props {
  onCancel: () => void;
  onSubmit: (data: { name: string }) => void;
  pitch?: TournamentPitch;
}

const PitchSchema = Yup.object().shape({
  name: Yup.string().required('Required')
});

export class TournamentPitchForm extends Component<Props> {
  render() {
    const { pitch } = this.props;
    const isPitchExist = typeof pitch !== 'undefined';
    const pitchForm = {
      name: isPitchExist ? pitch.name : ''
    };

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <Formik
              initialValues={pitchForm}
              validationSchema={PitchSchema}
              onSubmit={values => {
                this.props.onSubmit(values);
              }}
              render={({ touched, errors }) => (
                <Form>
                  <div className="form-group form-check">
                    <div className="form-group">
                      <label>Name</label>
                      <Field name="name" className="form-control mb-3" />
                      {touched.name && errors.name ? <div className="alert alert-danger">{errors.name}</div> : null}
                    </div>
                    <Button
                      onClick={this.props.onCancel}
                      text={'Cancel'}
                      customClass={'mt-3 mb-3 mr-3 btn-secondary'}
                    />
                    <button type="submit" className="mt-3 mb-3 btn btn-primary">
                      Save
                    </button>
                  </div>
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}
