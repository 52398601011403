import * as React from 'react';
import * as propz from 'propz';
import { AppUser } from 'Src/views/App/App';
import { getSchoolPublicSiteLink } from 'Src/helpers/link/link';
import { SCHOOL_STATUS } from 'Src/consts/school';
import './SchoolSummary.scss';

interface Props {
  user: AppUser;
}

export const SchoolSummary = (props: Props) => {
  const { user } = props;
  const { activeSchool } = user;
  const { pic: logo, name: title, address: address } = activeSchool;
  const schoolPostcode = propz.get(user, ['activeSchool', 'postcode', 'postcode'], '');
  const logoUrl = logo === '' ? '/dist/images/empty_pic_uploader_box.png' : logo;

  const publicSiteStatus = propz.get(activeSchool, ['publicSite', 'status']);
  const publicBigscreenSiteStatus = propz.get(activeSchool, ['publicBigscreenSite', 'status']);
  const isPublicSiteDisabled = publicSiteStatus === SCHOOL_STATUS.DISABLED;
  const isPublicBigscreenSiteDisabled = publicBigscreenSiteStatus === SCHOOL_STATUS.DISABLED;

  const getDescription = (): React.ReactNode => {
    const description = propz.get(user, ['activeSchool', 'description'], '');
    const descriptionParagraphs = description.split('\n').filter(item => item !== '');

    return descriptionParagraphs.map((item, index) => <p key={`description_paragraph_${index}`}>{item}</p>);
  };

  const renderSiteLink = (): React.ReactNode => {
    const { domain } = activeSchool;

    const schoolSiteLink = getSchoolPublicSiteLink(domain);
    const { link, linkToShow } = schoolSiteLink;

    switch (true) {
      case isPublicSiteDisabled:
        return <p>Public website: Disabled</p>;

      case domain !== '':
        return (
          <p className="bSchoolLink">
            <span>Public website:</span>
            <a href={link} target="_blank" title={domain}>
              {linkToShow}
            </a>
          </p>
        );

      default:
        return <p>Public website: School has no active domain</p>;
    }
  };

  const renderBigscreenLink = (): React.ReactNode => {
    const { domain } = activeSchool;

    const schoolSiteLink = getSchoolPublicSiteLink(domain);
    const { bigScreenLink, bigScreenLinkToShow } = schoolSiteLink;

    switch (true) {
      case isPublicBigscreenSiteDisabled:
        return <p>Bigscreen: Disabled</p>;

      case domain !== '':
        return (
          <p className="bSchoolLink">
            <span>Bigscreen:</span>
            <a href={bigScreenLink} target="_blank" title={domain}>
              {bigScreenLinkToShow}
            </a>
          </p>
        );

      default:
        return <p>Bigscreen: School has no active bigscreen</p>;
    }
  };

  return (
    <div className="bSchool">
      <div className="bSchoolMainInfo">
        <div className="bSchoolLogo" style={{ backgroundImage: `url(${logoUrl})` }}></div>
        <div>
          <div className="bSchoolTitle">{title}</div>
          <div>{schoolPostcode}</div>
          <div>{address}</div>
        </div>
      </div>
      <div className="bSchoolDescription">{getDescription()}</div>
      <div>
        {renderSiteLink()}
        {renderBigscreenLink()}
      </div>
    </div>
  );
};

SchoolSummary.displayName = 'SchoolSummary';
