import * as React from 'react';
import { AppUser } from 'Src/views/App/App';
import * as Promise from 'bluebird';
import { Component } from 'react';
import { DATE_INTERVAL, DEFAULT_LIMIT, DEFAULT_SKIP, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { History, Location } from 'history';
import { parse } from 'query-string';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import {
  ColumnDefinition,
  getFilters2,
  getOrder,
  getSearchFilter,
  getSearchOrder,
  getServerFieldSectionWhere,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import {
  getCoachEvent,
  getEventAges,
  getEventGender,
  getEventGeneratedName,
  getEventStartTime,
  getEventStatus,
  getEventSubtype,
  getEventType,
  getEventVenue,
  getEventVenueType,
  getStaffEvent
} from 'Src/helpers/accessor/accessor';
import { SchoolForm } from 'Src/models/form';
import { getAllForms } from 'Src/helpers/service/admin/forms';
import * as propz from 'propz';
import * as BPromise from 'bluebird';
import {
  getSelectOptionForAge,
  getSelectOptionForEventTypes,
  getSelectOptionForEventVenueType,
  getSelectOptionForSport,
  getSelectOptionForTournamentEventGender,
  getSelectOptionForTournamentEventStatus
} from 'Src/helpers/table/select';
import { Grid2 } from 'Src/components/Grid/Grid2';
import { SchoolEvent } from 'Src/models/event';
import { Sport } from 'Src/models/sport';
import { getEventNameWithLink } from 'Src/helpers/cell/cell';
import {
  getAllSchoolLeagueEvents,
  getSchoolLeague,
  getSchoolLeagueEvents,
  getSchoolLeagueEventsCount,
  removeEventToSchoolLeague
} from 'Src/helpers/service/admin/schoolLeagues';
import { SchoolLeague } from 'Src/models/schoolLeague';
import { Loader } from 'Src/components/Loader/Loader';
import { getSchoolSports } from 'Src/helpers/service/admin/user';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Event',
    field: 'generatedName',
    isSort: false,
    type: FILTER_TYPE.NONE,
    cell: getEventNameWithLink
  },
  {
    text: 'Start time',
    field: 'startTime',
    isSort: true,
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: getEventStartTime
  },
  {
    text: 'Kind',
    field: 'eventType',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getEventType
  },
  {
    text: 'Status',
    field: 'status',
    isSort: true,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getEventStatus
  },
  {
    text: 'Sport',
    field: 'eventSport',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: ['sport', 'name']
  },
  {
    text: 'Age',
    field: 'eventAges',
    isSort: false,
    type: FILTER_TYPE.CHECKBOX,
    accessor: getEventAges
  },
  {
    text: 'Gender',
    field: 'gender',
    isSort: true,
    type: FILTER_TYPE.CHECKBOX,
    accessor: getEventGender
  },
  {
    text: 'Home/Away',
    field: 'venueType',
    isSort: true,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getEventVenueType
  },
  {
    text: 'Venue',
    field: 'venue',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getEventVenue
  },
  {
    text: 'Coach/Leader',
    field: 'coach',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getCoachEvent
  },
  {
    text: 'Staff',
    field: 'staff',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getStaffEvent
  },
  {
    text: 'Type',
    field: 'subtype',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getEventSubtype
  }
];

interface State {
  items: SchoolEvent[];
  currentPage: number;
  selectedItems: SchoolEvent[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  filterOptions: any;
  currentTabIndex: number;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;
  isErrorModalOpen: boolean;
  errorMessage: string;
  isCancelEventToLeagueModalOpen: boolean;

  forms: SchoolForm[];
  sports: Sport[];
  league: SchoolLeague;
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export class SchoolLeagueEvents extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      filterOptions: undefined,
      isDataFiltered: false,
      currentTabIndex: 0,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',
      isErrorModalOpen: false,
      isCancelEventToLeagueModalOpen: false,
      errorMessage: '',
      forms: [],
      sports: [],
      league: undefined
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });
    this.setAdditionalItems().then(res => {
      this.setItems();
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  getLeagueId(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    const leagueId = search.league;
    return leagueId;
  }

  setAdditionalItems() {
    const { user } = this.props;
    const filter = { limit: DEFAULT_LIMIT, order: 'name ASC', skip: DEFAULT_SKIP };
    const leagueId = this.getLeagueId();

    const formsPromise = getAllForms(user);
    const sportsPromise = getSchoolSports(user, filter);
    const leaguePromise = getSchoolLeague(user, leagueId);

    return Promise.all([formsPromise, sportsPromise, leaguePromise]).then(([forms, sports, league]) => {
      const filterOptions = this.getFilterOptions(forms, sports);
      this.setState({
        forms: forms,
        sports: sports,
        league: league,
        filterOptions: filterOptions
      });

      return true;
    });
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters, { isCorrectTime: true });

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);
    const leagueId = this.getLeagueId();

    const getItemsPromise = getSchoolLeagueEvents(user, leagueId, serverQueryFilter);
    const getItemsCountPromise = getSchoolLeagueEventsCount(user, leagueId, where);

    return Promise.all([getItemsCountPromise, getItemsPromise]).then(([countObj, items]) => {
      this.setState({
        currentPage: page,
        sortDirection: sortDirection as TABLE_SORT_DIRECTION,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: items,
        total: countObj.count,
        basePath: history.location.pathname,
        isLoading: false
      });

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const leagueId = this.getLeagueId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `league=${leagueId}&${search.join('&')}`
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated,
      currentTabIndex: 0
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const selectedItemsNext = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: selectedItemsNext,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);
    const leagueId = this.getLeagueId();

    getAllSchoolLeagueEvents(user, leagueId, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const selectedItemsNext = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: selectedItemsNext,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const leagueId = this.getLeagueId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `league=${leagueId}&${search.join('&')}`
    });
  };

  onTableFilterChange = (event, filterField: string, options?, index?: number, checkBoxIndex?: number): void => {
    const filterValue = propz.get(event, ['target', 'value'], '');
    const { filters, filterOptions } = this.state;
    const currentFilterField = filters[filterField];
    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      switch (filterType) {
        case FILTER_TYPE.MULTISELECT:
          const options = event.target.options;

          const value = [];
          for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
              value.push(options[i].value);
            }
          }
          nextFilters = {
            ...nextFilters,
            [filterField]: value
          };
          break;
        case FILTER_TYPE.CHECKBOX:
          const columns = COLUMNS;
          const currentField = columns[index].field;
          filterOptions[currentField][checkBoxIndex].isChecked = !filterOptions[currentField][checkBoxIndex].isChecked;

          this.setState({
            filterOptions: filterOptions
          });

          const checkBoxValue = [];
          if (typeof filterOptions[filterField] !== 'undefined') {
            for (let i = 0; i < filterOptions[filterField].length; i++) {
              if (filterOptions[filterField][i].isChecked) {
                checkBoxValue.push(filterOptions[filterField][i].value);
              }
            }
          }
          nextFilters = {
            ...nextFilters,
            [filterField]: checkBoxValue
          };
          break;
        default:
          nextFilters = {
            ...nextFilters,
            [filterField]: filterValue
          };
          break;
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    const leagueId = this.getLeagueId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `league=${leagueId}&${search.join('&')}`
    });
  };

  onClearFilterClick = (): void => {
    const { forms, sports } = this.state;
    const filterOptions = this.getFilterOptions(forms, sports);

    this.setState({
      filterOptions: filterOptions,
      selectedItems: [],
      isSelectAllChecked: false
    });

    const leagueId = this.getLeagueId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `league=${leagueId}`
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  onCloseErrorClick = () => {
    this.setState({
      isErrorModalOpen: false,
      errorMessage: ''
    });
  };

  renderSendingError(): React.ReactNode {
    const { errorMessage, isErrorModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isErrorModalOpen}
        title={'Error'}
        body={errorMessage}
        buttonCancelText={'Ok'}
        onCloseClick={this.onCloseErrorClick}
      />
    );
  }

  onShowError = (message: string) => {
    this.setState({
      isErrorModalOpen: true,
      errorMessage: message
    });
  };

  cancelEventToLeagueModalOpen = () => {
    this.setState({
      isCancelEventToLeagueModalOpen: true
    });
  };

  onCancelEventToLeagueClick = () => {
    const { user } = this.props;
    const selectedItems = this.state.selectedItems;
    this.setState({
      isLoading: true
    });

    BPromise.all(
      selectedItems.map(selectedItem => {
        const eventId = selectedItem.id;
        const leagueId = this.getLeagueId();
        const data = {
          eventId: eventId
        };

        return removeEventToSchoolLeague(user, leagueId, data);
      })
    ).then(items => {
      this.setState({
        isCancelEventToLeagueModalOpen: false
      });
      this.setItems();
    });
  };

  cancelEventToLeagueModalClose = (): void => {
    this.setState({
      isCancelEventToLeagueModalOpen: false
    });
  };

  renderCancelEventModal() {
    const { selectedItems, isCancelEventToLeagueModalOpen } = this.state;
    const { user } = this.props;
    const eventName = getEventGeneratedName(selectedItems[0], { user: user });

    return (
      <SimpleModal
        isOpen={isCancelEventToLeagueModalOpen}
        title={'Remove Event'}
        body={`Are you sure you want to remove ${
          selectedItems.length === 1 ? `Event ${eventName}` : `${selectedItems.length} Events`
        }?`}
        buttonText={'Ok'}
        onButtonClick={this.onCancelEventToLeagueClick}
        onCloseClick={this.cancelEventToLeagueModalClose}
      />
    );
  }

  getFilterOptions = (forms, sports) => {
    const user = this.props.user;
    const activeSchool = user.activeSchool;
    const ageGroupsNaming = activeSchool.ageGroupsNaming;

    return {
      venueType: getSelectOptionForEventVenueType(),
      eventAges: getSelectOptionForAge(forms, ageGroupsNaming),
      gender: getSelectOptionForTournamentEventGender(),
      status: getSelectOptionForTournamentEventStatus(),
      eventType: getSelectOptionForEventTypes(),
      eventSport: getSelectOptionForSport(sports)
    };
  };

  goBack = () => {
    this.props.history.push({
      pathname: '/leagues'
    });
  };

  render() {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isSelectAllChecked,
      currentPage,
      total,
      filterOptions,
      league,
      isErrorModalOpen,
      isCancelEventToLeagueModalOpen,
      isLoading
    } = this.state;

    const actionItems = [
      {
        itemText: 'Remove from league',
        onItemClick: this.cancelEventToLeagueModalOpen,
        isActive: selectedItems.length > 0
      }
    ];

    const { user } = this.props;
    const gridTitle = typeof league !== 'undefined' ? `${league.name} / Events` : '';

    if (isLoading) {
      return <Loader />;
    }

    const classes = isErrorModalOpen || isCancelEventToLeagueModalOpen ? 'mt-3 modal-open' : 'mt-3';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderCancelEventModal()}
        {this.renderSendingError()}
        <div className="row">
          <div className="col-md-12">
            <Grid2
              dataItems={items}
              filters={filters}
              currentPage={currentPage}
              total={total}
              isSelectAllChecked={isSelectAllChecked}
              isDataFiltered={isDataFiltered}
              sortDirection={sortDirection}
              sortColumnsName={sortColumnsName}
              isShowFilter={isShowFilter}
              dataItemsSelected={selectedItems}
              columns={COLUMNS}
              actionItems={actionItems}
              options={filterOptions}
              onItemClick={this.onItemClick}
              onSortClick={this.onTableSortClick}
              onApplyFilterClick={this.onApplyFilterClick}
              onClearFilterClick={this.onClearFilterClick}
              onTableFilterChange={this.onTableFilterChange}
              onTableFilterClick={this.onTableFilterClick}
              setCurrentPageParams={this.setCurrentPageParams}
              onSelectAllClick={this.onSelectAllClick}
              onSelectAllOnPageClick={this.onSelectAllOnPageClick}
              onUnselectAllClick={this.onUnselectAllClick}
              goBack={this.goBack}
              gridTitle={gridTitle}
              user={user}
            />
          </div>
        </div>
      </div>
    );
  }
}
