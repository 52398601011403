import * as React from 'react';
import { SchoolEvent } from '../../../../models/event';
import * as Moment from 'moment';
import { getEventGeneratedName } from '../../../../helpers/accessor/accessor';
import { Tournament } from '../../../../models/tournament';
import { EVENT_STATUS_SERVER_TO_CLIENT_MAPPING } from '../../../../consts/event';
import { getEventResultForCalendar, getSignEventType } from '../../../../helpers/calendar/calendar';
import { AppUser } from '../../../App/App';

interface Props {
  user: AppUser;
  events: SchoolEvent[];
  tournaments: Tournament[];
  onEventClick: (eventId: string) => void;
}

export function EventAndTournamentsTable(props: Props) {
  const { events, tournaments, onEventClick, user } = props;
  const { activeSchoolId } = user;

  return (
    <table className="eEventAndTournamentsTable table-striped">
      <thead>
        <tr className="eChallengeRowTitle">
          <th className="eChallengeColumnTitle">Type</th>
          <th className="eChallengeColumnTitle">Event</th>
          <th className="eChallengeColumnTitle text-nowrap">Start time</th>
          <th className="eChallengeColumnTitle">Status</th>
          <th className="eChallengeColumnTitle">Result</th>
        </tr>
      </thead>
      <tbody>
        {events.map((event, index) => (
          <tr
            onClick={() => {
              onEventClick(event.id);
            }}
            key={`event_${index}`}
            className="eChallengeRowInfo"
          >
            <td className="eChallengeInfo mChallengeInfoBlue">{getSignEventType(event)}</td>
            <td className="eChallengeInfo">{getEventGeneratedName(event, { user: user })}</td>
            <td className="eChallengeInfo">{Moment(event.startTime).format('HH:mm')}</td>
            <td className="eChallengeInfo">{EVENT_STATUS_SERVER_TO_CLIENT_MAPPING[event.status]}</td>
            <td className="eChallengeInfo">{getEventResultForCalendar(event, activeSchoolId)}</td>
          </tr>
        ))}
        {tournaments.map((tournament, index) => (
          <tr key={`tournament_${index}`} className="eChallengeRowInfo">
            <td className="eChallengeInfo mChallengeInfoBlue">T</td>
            <td className="eChallengeInfo">{tournament.name}</td>
            <td className="eChallengeInfo">{Moment(tournament.startTime).format('HH:mm')}</td>
            <td className="eChallengeInfo">-</td>
            <td className="eChallengeInfo">Coming soon</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
