import * as React from 'react';
import { Component } from 'react';
import { parse } from 'query-string';
import * as BPromise from 'bluebird';
import {
  ColumnDefinition,
  getFilters2,
  getOrder,
  getSearchFilter,
  getSearchOrder,
  getServerFieldSectionWhere,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE, LIMIT } from 'Src/consts/table';
import { Loader } from 'Src/components/Loader/Loader';
import { AppUser } from 'Src/views/App/App';
import './TournamentTags.css';
import { GenericSummary } from 'Src/components/GenericSummary/GenericSummary';
import { Button } from 'Src/components/Button/Button';
import { History, Location } from 'history';
import { Grid2 } from 'Src/components/Grid/Grid2';
import { getTournamentTagColor } from 'Src/helpers/cell/cell';
import { TournamentTag } from 'Src/models/tournamentTag';
import {
  createTournamentTag,
  deleteTournamentTag,
  getAllTournamentTags,
  getTournamentTags,
  getTournamentTagsCount,
  updateTournamentTag
} from 'Src/helpers/service/adminSU/tournamentTags';
import { TOURNAMENT_TAG_TABS } from 'Src/consts/tournamentTag';
import { TournamentTagForm } from './TournamentTagForm/TournamentTagForm';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { TournamentTagSummary } from 'Src/components/TournamentTagSummary/TournamentTagSummary';
import { ResizablePanel } from 'Src/components/ResizablePanel/ResizablePanel';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Name',
    field: 'tagName',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['tagName']
  },
  {
    text: 'Description',
    field: 'tagDdescription',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: ['tagDescription']
  },
  /* {
    text: 'Colour',
    field: 'tagColor',
    isSort: false,
    type: FILTER_TYPE.NONE,
    cell: getTournamentTagColor
  },*/
  {
    text: 'Colour',
    field: 'tagColor',
    isSort: false,
    type: FILTER_TYPE.NONE,
    cell: row => <td className="color-thumbnail-cell">{getTournamentTagColor(row)}</td>
  },
  {
    text: 'Public',
    field: 'isPublic',
    isSort: false,
    type: FILTER_TYPE.NONE,
    cell: ({ isPublic }) => {
      const displayValue = isPublic ? 'Yes' : 'No';
      return <td>{displayValue}</td>;
    }
  }
];

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

interface State {
  items: TournamentTag[];
  currentPage: number;
  selectedItems: TournamentTag[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;

  isEditTournamentTagPopupOpened: boolean;
  isDeleteTournamentTagModalOpen: boolean;
}

export class TournamentTags extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',

      isEditTournamentTagPopupOpened: false,
      isDeleteTournamentTagModalOpen: false
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setItems();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const getTournamentTagsPromise = getTournamentTags(user, serverQueryFilter);
    const getTournamentTagsCountPromise = getTournamentTagsCount(user, where);

    return BPromise.all([getTournamentTagsCountPromise, getTournamentTagsPromise]).then(
      ([countObj, tournamentTags]) => {
        this.setState({
          currentPage: page,
          sortDirection: sortDirection,
          sortColumnsName: sortColumnsName,
          isShowFilter: isShowFilter,
          isDataFiltered: isDataFiltered,
          filters: filters,
          items: tournamentTags,
          total: countObj.count,
          basePath: history.location.pathname,
          isLoading: false
        });

        return true;
      }
    );
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedTournamentTag = items[index];

    const selectedTournamentTagIndex = selectedItems.findIndex(
      tournamentTag => selectedTournamentTag.id === tournamentTag.id
    );
    let selectedTournamentTagUpdated = [...selectedItems];

    if (selectedTournamentTagIndex !== -1) {
      selectedTournamentTagUpdated.splice(selectedTournamentTagIndex, 1);
    } else {
      selectedTournamentTagUpdated.push(selectedTournamentTag);
    }

    this.setState({
      selectedItems: selectedTournamentTagUpdated
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const tournamentTagsFiltered = items.filter(tournamentTag => {
      return selectedItems.every(selectedTournamentTag => selectedTournamentTag.id !== tournamentTag.id);
    });

    const selectedTournamentTag = [...selectedItems, ...tournamentTagsFiltered];

    this.setState({
      selectedItems: selectedTournamentTag,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    getAllTournamentTags(user, where).then(tournamentTags => {
      const tournamentTagsFiltered = tournamentTags.filter(tournamentTag => {
        return selectedItems.every(selectedTournamentTag => selectedTournamentTag.id !== tournamentTag.id);
      });

      const selectedTournamentTags = [...selectedItems, ...tournamentTagsFiltered];

      this.setState({
        selectedItems: selectedTournamentTags,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: ''
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  onTabClick = (event, index: number) => {
    event.preventDefault();

    this.setState({
      total: index
    });
  };

  onEditTournamentTagClick = event => {
    event.preventDefault();

    this.setState({ isEditTournamentTagPopupOpened: true });
  };

  getSummaryTabs(): React.ReactNode {
    const { selectedItems, items } = this.state;
    const selectedTournamentTags = items.find(tournamentTag => tournamentTag.id === selectedItems[0].id);

    switch (true) {
      case selectedItems.length === 1:
        return <TournamentTagSummary tournamentTag={selectedTournamentTags} />;
    }
  }

  renderTournamentTagModal(): React.ReactNode {
    const { isEditTournamentTagPopupOpened, selectedItems } = this.state;
    const { user } = this.props;

    if (selectedItems.length === 0) {
      return (
        <SimpleModal isOpen={isEditTournamentTagPopupOpened} title={'Create tournament tag'}>
          <TournamentTagForm onCancel={this.onCancelButtonClick} onSubmit={this.onSaveButtonClick} user={user} />
        </SimpleModal>
      );
    } else {
      return (
        <SimpleModal isOpen={isEditTournamentTagPopupOpened} title={'Edit tag'}>
          <TournamentTagForm
            tournamentTag={selectedItems[0]}
            onCancel={this.onCancelButtonClick}
            onSubmit={this.onSaveButtonClick}
            user={user}
          />
        </SimpleModal>
      );
    }
  }

  onCreateTournamentTagClick = () => {
    this.setState({
      isEditTournamentTagPopupOpened: true,
      selectedItems: []
    });
  };

  deleteTournamentTagModalOpen = (): void => {
    this.setState({
      isDeleteTournamentTagModalOpen: true
    });
  };

  deleteTournamentTagModalClose = (): void => {
    this.setState({
      isDeleteTournamentTagModalOpen: false
    });
  };

  onDeleteTournamentTagClick = () => {
    const { selectedItems } = this.state;

    this.setState({ isLoading: true });
    const promises = selectedItems.map(tournamentTag => {
      return deleteTournamentTag(this.props.user, tournamentTag.id);
    });
    BPromise.all(promises).then(() => {
      this.setState({
        selectedItems: [],
        isDeleteTournamentTagModalOpen: false
      });
      this.setItems();
    });
  };

  onSaveButtonClick = (data): void => {
    const { user } = this.props;
    const { selectedItems } = this.state;

    this.setState({
      isLoading: true
    });

    if (selectedItems.length === 0) {
      createTournamentTag(this.props.user, data).then(tournamentTag => {
        this.setState({
          isEditTournamentTagPopupOpened: false,
          selectedItems: []
        });
        this.setItems();
      });
    } else {
      const tournamentTagId = selectedItems[0].id;
      updateTournamentTag(user, data, tournamentTagId).then(tournamentTag => {
        this.setState({
          isEditTournamentTagPopupOpened: false,
          selectedItems: []
        });
        this.setItems();
      });
    }
  };

  onCancelButtonClick = (): void => {
    this.setState({
      isEditTournamentTagPopupOpened: false
    });
  };

  renderDeleteTournamentTagModal() {
    const { selectedItems, isDeleteTournamentTagModalOpen } = this.state;
    const isSingleTag = selectedItems.length === 1;
    const tagName = isSingleTag
      ? `${selectedItems[0].tagName} tournament tag`
      : `${selectedItems.length} tournament tags`;

    const confirmationText = `Are you sure you want to remove ${tagName}? Please note this will also remove the tags from all tournaments where they have been applied.`;

    return (
      <SimpleModal
        isOpen={isDeleteTournamentTagModalOpen}
        title={'Delete Tag'}
        body={confirmationText}
        buttonText={'Delete'}
        onButtonClick={this.onDeleteTournamentTagClick}
        onCloseClick={this.deleteTournamentTagModalClose}
        buttonCancelText="Cancel"
      />
    );
  }

  renderSummary() {
    const { selectedItems } = this.state;
    const isOneItemSelected = selectedItems.length === 1;

    if (!isOneItemSelected) {
      return <div className="font-weight-bold">Select a tag above</div>;
    }

    const summaryTabs = this.getSummaryTabs();

    const isShowEditButton = selectedItems.length === 1;

    return (
      <GenericSummary onTabClick={this.onTabClick} tabs={TOURNAMENT_TAG_TABS} currentTabIndex={0}>
        {isShowEditButton && <Button onClick={this.onEditTournamentTagClick} text={'Edit'} customClass={'mt-3 mb-3'} />}
        {summaryTabs}
      </GenericSummary>
    );
  }

  render() {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isLoading,
      isSelectAllChecked,
      currentPage,
      total,
      isEditTournamentTagPopupOpened
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes = isEditTournamentTagPopupOpened ? 'mt-3 modal-open' : 'mt-3';

    const actionItems = [
      {
        itemText: 'Create tournament tag',
        onItemClick: this.onCreateTournamentTagClick,
        isActive: selectedItems.length === 0
      },
      {
        itemText: 'Delete tag',
        onItemClick: this.deleteTournamentTagModalOpen,
        isActive: selectedItems.length > 0
      }
    ];

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderDeleteTournamentTagModal()}
        {this.renderTournamentTagModal()}
        <div className="row">
          <div className="col-md-12">
            <ResizablePanel>
              <Grid2
                dataItems={items}
                filters={filters}
                currentPage={currentPage}
                total={total}
                isSelectAllChecked={isSelectAllChecked}
                isDataFiltered={isDataFiltered}
                sortDirection={sortDirection}
                sortColumnsName={sortColumnsName}
                isShowFilter={isShowFilter}
                dataItemsSelected={selectedItems}
                columns={COLUMNS}
                actionItems={actionItems}
                onItemClick={this.onItemClick}
                onSortClick={this.onTableSortClick}
                onApplyFilterClick={this.onApplyFilterClick}
                onClearFilterClick={this.onClearFilterClick}
                onTableFilterChange={this.onTableFilterChange}
                onTableFilterClick={this.onTableFilterClick}
                setCurrentPageParams={this.setCurrentPageParams}
                onSelectAllClick={this.onSelectAllClick}
                onSelectAllOnPageClick={this.onSelectAllOnPageClick}
                onUnselectAllClick={this.onUnselectAllClick}
              />
              {this.renderSummary()}
            </ResizablePanel>
          </div>
        </div>
      </div>
    );
  }
}
