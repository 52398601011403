import * as React from 'react';

interface Props {
  tournamentName: string;
  tournamentLocation: string;
  tournamentStartTimeFormatted: string;
  inviterSchoolName: string;
  tournamentSportName: string;
}

export function AcceptTournamentInviteHeader(props: Props) {
  const {
    tournamentName,
    tournamentLocation,
    tournamentStartTimeFormatted,
    inviterSchoolName,
    tournamentSportName
  } = props;

  const style = { marginBottom: '5px', marginLeft: '10px', fontSize: '14px' };

  return (
    <>
      <div style={{ textAlign: 'center' }}>
        {`You are about to book spaces in the tournament`}
        <br />
        {tournamentName}
      </div>
      <div style={{ marginBottom: '10px' }}>
        <div style={style}>{`Organizer: ${inviterSchoolName}`}</div>
        <div style={style}>{`Sport: ${tournamentSportName}`}</div>
        <div style={style}>{`Start time: ${tournamentStartTimeFormatted}`}</div>
        <div style={style}>{`Venue: ${tournamentLocation}`}</div>
      </div>
    </>
  );
}
