import * as React from 'react';
import * as Moment from 'moment';
import * as propz from 'propz';
import { SchoolEvent } from 'Src/models/event';
import { EventInvitationMessage } from 'Src/models/message';
import { INVITE_TYPE } from 'Src/consts/invite';
import { MESSAGE_INVITATION_STATUS } from 'Src/consts/message';
import { getFullName } from 'Src/helpers/autocomplete/autocomplete';
import { getEventNameForParent } from 'Src/helpers/accessor/accessor';
import { getMessageInvitationStatus } from 'Src/helpers/accessor/accessor';
import { Button } from 'Src/components/Button/Button';
import { DetailsIcon } from 'Src/components/SvgIcons/DetailsIcon';
import './ConsentRequestCard.scss';

interface Props {
  item: {
    consentRequest: EventInvitationMessage;
    childEvent: SchoolEvent;
  };
  type: string;
  childIdList: string[];
  onViewMapClick: (item: { consentRequest: EventInvitationMessage; childEvent: SchoolEvent }) => void;
  onRejectClick?: (item: { consentRequest: EventInvitationMessage; childEvent: SchoolEvent }) => void;
  onAcceptClick?: (item: { consentRequest: EventInvitationMessage; childEvent: SchoolEvent }) => void;
  onShowDetailsClick?: (item: { consentRequest: EventInvitationMessage; childEvent: SchoolEvent }) => void;
  onReturnToInboxClick?: (consentRequest: EventInvitationMessage) => void;
}

export const ConsentRequestCard = (props: Props) => {
  const {
    item,
    childIdList,
    type,
    onRejectClick,
    onAcceptClick,
    onViewMapClick,
    onReturnToInboxClick,
    onShowDetailsClick
  } = props;

  const { childEvent, consentRequest } = item;
  const { playerDetailsData } = consentRequest;

  const isInviteTypeInbox = type === INVITE_TYPE.INBOX;
  const isInviteTypeArchive = type === INVITE_TYPE.ARCHIVE;
  const studentName = getFullName(playerDetailsData);
  const eventName = getEventNameForParent(childEvent, childIdList);
  const eventStatus = getMessageInvitationStatus(consentRequest);
  const schoolLogo = propz.get(consentRequest, ['schoolData', 'pic'], '');
  const startTime = propz.get(childEvent, ['startTime'], '');
  const endTime = propz.get(childEvent, ['endTime'], '');
  const sportName = propz.get(consentRequest, ['eventData', 'sport', 'name'], '');
  const startDateFormatted = Moment(startTime).format('DD-MM-YYYY');
  const startTimeFormatted = Moment(startTime).format('HH:mm');
  const endDateFormatted = Moment(endTime).format('DD-MM-YYYY');
  const endTimeFormatted = Moment(endTime).format('HH:mm');
  const postcode = propz.get(childEvent, ['venue', 'postcodeData', 'postcode'], '');
  const placeData = propz.get(childEvent, ['venue', 'placeData', 'name'], '');
  const isCustomVenueType = propz.get(childEvent, ['venue', 'venueType']) === 'CUSTOM';

  let venueType;

  switch (true) {
    case eventName.trim().endsWith('(H)'):
      venueType = 'Home';
      break;

    case eventName.trim().endsWith('(A)'):
      venueType = 'Away';
      break;

    default:
      venueType = isCustomVenueType ? postcode : propz.get(item, ['venue', 'venueType'], '');
      break;
  }

  const isPlaceDataExist = placeData !== '';

  const venue = isPlaceDataExist ? `${placeData} - ${venueType}` : `${postcode} - ${venueType}`;
  const invitationStatus = propz.get(consentRequest, ['invitationStatus']);

  const deadline = propz.get(consentRequest, ['deadlineForAnswers'], '');
  const isDeadlineExist = deadline !== '';
  const deadlineDateFormatted = Moment(deadline).format('DD-MM-YYYY');
  const deadlineTimeFormatted = Moment(deadline).format('HH:mm');
  const formattedDeadline = `${deadlineDateFormatted} / ${deadlineTimeFormatted}`;
  const now = new Date();
  const deadlineDate = new Date(deadline);
  const isDeadLinePassed = now > deadlineDate;

  const deadlinePassedText = 'Unfortunately, deadline for responding has already passed.';

  const renderInboxButtons = () => {
    return !isDeadLinePassed || !isDeadlineExist ? (
      <div>
        <Button text="Reject" onClick={() => onRejectClick(item)} customClass="btn-sm btn-danger mr-2" />
        <Button text="Accept" onClick={() => onAcceptClick(item)} customClass="btn-sm btn-success" />
      </div>
    ) : (
      <p>{deadlinePassedText}</p>
    );
  };

  const renderArchiveButtons = () => {
    const isReturnToInboxExist = typeof onReturnToInboxClick !== 'undefined';
    const isInvitationStatusRejected = invitationStatus === MESSAGE_INVITATION_STATUS.REJECTED;

    if (isReturnToInboxExist && isInvitationStatusRejected) {
      return (
        <Button
          text="Return to inbox"
          onClick={() => onReturnToInboxClick(item.consentRequest)}
          customClass="btn-sm btn-success"
        />
      );
    }
  };

  return (
    <div className="bConsentRequestCard">
      <div className="eConsentRequestCardStudentName">
        <p>{studentName}</p>

        <div className="eConsentRequestCardSchoolLogo">
          <img src={schoolLogo} alt="SchoolLogo" />
        </div>
      </div>

      <div className="eConsentRequestCardEventName">{eventName}</div>

      <div className="eConsentRequestCardSport">{sportName}</div>

      <div className="eConsentRequestCardVenue" onClick={() => onViewMapClick(item)}>
        {venue}
        <img src="/dist/images/icons/location.png" alt="Location icon" />
      </div>

      <div className="eConsentRequestCardStatus">{eventStatus}</div>

      <div className="eConsentRequestCardTime">
        <div className="eConsentRequestCardStartTime">
          <p>Start time:</p>
          <span>{startDateFormatted}</span>
          <span>{startTimeFormatted}</span>
        </div>
        <div className="eConsentRequestCardEndTime">
          <p>End time:</p>
          <span>{endDateFormatted}</span>
          <span>{endTimeFormatted}</span>
        </div>
      </div>

      {isInviteTypeInbox ? (
        <>
          <div className={'eConsentRequestCardButtons'}>{renderInboxButtons()}</div>

          {!isDeadLinePassed && isDeadlineExist && (
            <div className="eConsentRequestCardDeadline">Deadline for answers: {formattedDeadline}</div>
          )}
        </>
      ) : (
        <div className={'eClubBookingCardButtons'}>{renderArchiveButtons()}</div>
      )}

      {isInviteTypeArchive && (
        <div className="d-flex justify-content-end">
          <div className="eConsentRequestCardShowDetailsButton" onClick={() => onShowDetailsClick(item)}>
            <DetailsIcon />
            <span>Details</span>
          </div>
        </div>
      )}
    </div>
  );
};

ConsentRequestCard.displayName = 'ConsentRequestCard';
