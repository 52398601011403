import * as React from 'react';
import { FunctionComponent } from 'react';
import { ColumnDefinition, SelectOption } from 'Src/helpers/table/table';
import { FILTER_TYPE } from 'Src/consts/table';
import { MultiSelectField } from 'Src/components/MultiSelectField/MultiselectField';
import { SelectField } from 'Src/components/SelectField/SelectField';
import { DateIntervalField } from 'Src/components/DateIntervalField/DateIntervalField';
import { TimeIntervalField } from 'Src/components/TimeIntervalField/TimeIntervalField';
import { MultiSelectCheckboxField } from 'Src/components/MultiSelectCheckboxField/MultiSelectCheckboxField';
import { Button } from 'Src/components/Button/Button';
import { GridSortIcon } from 'Src/components/Grid/GridSortIcon';
import './Filters.scss';

interface Props {
  fields: ColumnDefinition[];
  filters: any;
  sortFieldName: string;
  sortDirection: string;
  onTableFilterChange: (event, value: string, options?: any, index?: number, checkBoxIndex?: number) => void;
  onApplyFilterClick: () => void;
  onTableSortClick: (sortField: string) => void;
  onClearFilterClick: () => void;
  options?: {
    childIdList?: SelectOption[];
  };
}

export const Filters: FunctionComponent<Props> = props => {
  const {
    filters,
    fields,
    options,
    sortFieldName,
    sortDirection,
    onTableFilterChange,
    onTableSortClick,
    onClearFilterClick,
    onApplyFilterClick
  } = props;

  const fieldsForSort = fields.filter(field => field.isSort);

  const filtersRow = fields.map((column, index) => {
    const { type, field, text } = column;

    switch (type) {
      case FILTER_TYPE.NONE: {
        return <></>;
      }
      case FILTER_TYPE.TEXT: {
        return (
          <div key={`${text}_filter_${index}`} className="col-12 col-sm-6 col-md-3 my-2">
            <span>{text}</span>

            <input
              onChange={event => {
                onTableFilterChange(event, field);
              }}
              value={filters[field]}
              className="form-control w-100"
            />
          </div>
        );
      }
      case FILTER_TYPE.SELECT: {
        return (
          <div key={`${text}_filter_${index}`} className="col-12 col-sm-6 col-md-3 my-2">
            <span>{text}</span>

            <SelectField
              items={options[field]}
              field={field}
              onChange={onTableFilterChange}
              value={filters[field]}
              isEmptyOptionRequired
              customClass="w-100"
            />
          </div>
        );
      }
      case FILTER_TYPE.MULTISELECT: {
        return (
          <div key={`${text}_filter_${index}`} className="col-12 col-sm-6 col-md-3 my-2">
            <span>{text}</span>

            <MultiSelectField
              items={options[field]}
              field={field}
              onChange={onTableFilterChange}
              value={filters[field]}
              customClass="w-100"
            />
          </div>
        );
      }
      case FILTER_TYPE.CHECKBOX: {
        return (
          <div key={`${text}_filter_${index}`} className="col-12 col-sm-6 col-md-3 my-2">
            <span>{text}</span>

            <MultiSelectCheckboxField
              customClass="w-100"
              items={options[field]}
              onChange={checkBoxIndex => {
                onTableFilterChange(options, field, undefined, index, checkBoxIndex);
              }}
            />
          </div>
        );
      }
      case FILTER_TYPE.DATE_INTERVAL: {
        return (
          <div key={`${text}_filter_${index}`} className="col-12 col-sm-6 col-md-3 my-2">
            <span>{text}</span>

            <DateIntervalField
              onTableFilterChange={onTableFilterChange}
              field={field}
              to={filters[field] !== '' ? filters[field].to : ''}
              from={filters[field] !== '' ? filters[field].from : ''}
              customClass="w-100"
            />
          </div>
        );
      }
      case FILTER_TYPE.TIME_INTERVAL: {
        return (
          <div key={`${text}_filter_${index}`} className="col-12 col-sm-6 col-md-3 my-2">
            <span>{text}</span>

            <TimeIntervalField
              onTableFilterChange={onTableFilterChange}
              field={field}
              to={filters[field] !== '' ? filters[field].to : ''}
              from={filters[field] !== '' ? filters[field].from : ''}
              customClass="w-100"
            />
          </div>
        );
      }
    }
  });

  const sortingRow = fieldsForSort.map(field => {
    return (
      <div key={field.text} className="eSortingRow" onClick={() => onTableSortClick(field.field)}>
        <p className="mr-2">{field.text}</p>

        <GridSortIcon
          tableSortDirection={sortDirection}
          tableSortColumnsName={sortFieldName}
          tableColumnName={field.field}
        />
      </div>
    );
  });

  return (
    <div className="bFiltersWrapper">
      <div className="bFilters">{filtersRow}</div>

      <div className="bSorting">
        <span>Sort by:</span>
        {sortingRow}
      </div>

      <Button text="Clear filter" onClick={onClearFilterClick} customClass="ml-3 mr-2 btn-secondary" />
      <Button text="Apply filter" onClick={onApplyFilterClick} />
    </div>
  );
};

Filters.displayName = 'Filters';
