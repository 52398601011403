import * as React from 'react';
import { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { AppUser } from 'Src/views/App/App';
import { getPhoneAndCode, getUKPhoneWithoutFirstZero } from '../../../../../helpers/phone/phone';
import { Button } from '../../../../../components/Button/Button';
import { PHONE_CODE } from '../../../../../consts/common';
import './PhoneChangeForm.scss';
import { checkAvailability } from '../../../../../helpers/service/public/register';

interface Props {
  phone: string;
  onSubmit: (data: any) => void;
  onCancel: () => void;
}

export function PhoneChangeForm(props: Props) {
  const { onSubmit, onCancel, phone } = props;

  const phoneObj = getPhoneAndCode(phone);

  const [phoneCode, setPhoneCode] = useState(phoneObj.code);

  const PhoneSchema = Yup.object().shape({
    phone: Yup.string()
      .required('Required')
      .test('phone', 'Duplicate phone or incorrect symbol', function(value) {
        const phoneRegExp = /^\d+$|^\d+#\d+$/;
        const phone = getUKPhoneWithoutFirstZero(value, phoneCode);
        const result = phoneRegExp.test(phone);
        const phoneForCheck = `${phoneCode}${phone}`;

        return result
          ? checkAvailability({ phone: phoneForCheck }).then(res => {
              return res.isAvailable;
            })
          : false;
      })
      .test('phone', 'Please enter a valid mobile phone number', value => {
        if (value && value.length < 7) {
          return false;
        } else {
          return true;
        }
      })
  });

  return (
    <Formik
      initialValues={{ phone: phoneObj.phone, phoneCode }}
      validationSchema={PhoneSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, setFieldValue, touched, errors }) => (
        <Form>
          <div className="form-group form-check">
            <>
              <div className="form-group">
                <div className="ePhoneChangeFormTitle">Enter your phone number</div>
                <label>Phone code</label>
                <Field
                  name="phoneCode"
                  component="select"
                  className="form-control"
                  onChange={event => {
                    setPhoneCode(event.target.value);
                    setFieldValue('phoneCode', event.target.value);
                  }}
                >
                  <option value={PHONE_CODE.UK}>{PHONE_CODE.UK}</option>
                  <option value={PHONE_CODE.US}>{PHONE_CODE.US}</option>
                  <option value={PHONE_CODE.AE}>{PHONE_CODE.AE}</option>
                  <option value={PHONE_CODE.DE}>{PHONE_CODE.DE}</option>
                </Field>
              </div>

              <div className="form-group">
                <label>Phone</label>
                <Field name="phone" placeholder="Phone" className={'form-control mb-3'} />

                {touched.phone && errors.phone && <div className="ePhoneChangeFormError">{errors.phone}</div>}
              </div>
            </>

            <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
            <Button onClick={handleSubmit} text={'Save'} customClass={'mt-3 mb-3 btn btn-primary'} type={'submit'} />
          </div>
        </Form>
      )}
    </Formik>
  );
}
