import * as React from 'react';
import * as propz from 'propz';
import { FunctionComponent } from 'react';
import { SchoolLeague } from 'Src/models/schoolLeague';
import { getAggregationType } from 'Src/helpers/accessor/accessor';
import { getSchoolLeaguePublicSiteLink } from 'Src/helpers/link/link';
import '../SchoolLeagues.css';

interface Props {
  league: SchoolLeague;
}

const ROWS = [
  { title: 'Name', field: 'leagueName' },
  { title: 'Description', field: 'description' },
  { title: 'Aggregation', field: 'aggregationType' },
  { title: 'Domain name', field: 'domain' },
  { title: 'League website link', field: 'link' },
  { title: 'Pictures', field: 'photos' }
];

export const SchoolLeagueSummary: FunctionComponent<Props> = props => {
  const rows = ROWS.map((row, index) => {
    let contentCell: object | string;
    const field = row.field;

    const league = props.league;
    const leaguePublicSiteLink = getSchoolLeaguePublicSiteLink(league);

    switch (field) {
      case 'leagueName':
        const leagueName = propz.get(league, ['name'], '');
        contentCell = leagueName;
        break;
      case 'description':
        contentCell = propz.get(league, ['description'], '');
        break;
      case 'aggregationType':
        contentCell = getAggregationType(league);
        break;
      case 'domain':
        contentCell = propz.get(league, ['domain'], '');
        break;
      case 'link':
        contentCell =
          typeof league.domain !== 'undefined' && league.domain !== '' ? (
            <a href={leaguePublicSiteLink} target="_blank">
              {leaguePublicSiteLink}
            </a>
          ) : (
            'no'
          );
        break;
      case 'photos':
        contentCell =
          typeof league.photos !== 'undefined' && league.photos.length > 0 ? (
            <img className="img-fluid img-thumbnail eSchoolLeaguesPhotos" src={league.photos[0].picUrl} alt="picture" />
          ) : (
            'no'
          );
        break;
      default:
        contentCell = propz.get(league, row.field.split('.'), '');
    }

    return (
      <tr key={`league_summary_${index}`}>
        <td>{row.title}</td>
        <td>{contentCell}</td>
      </tr>
    );
  });

  return (
    <table className={'table table-sm mPre mVerticalAlignMiddle'}>
      <tbody>{rows}</tbody>
    </table>
  );
};

SchoolLeagueSummary.displayName = 'SchoolLeagueSummary';
