import * as React from 'react';
import * as propz from 'propz';
import { FunctionComponent } from 'react';
import {PaymentAccount} from "../../models/paymentAccount";

interface Props {
  paymentAccount: PaymentAccount
}

const ROWS = [
  { title: 'Platform', field: 'platform'},
  { title: 'Type', field: 'type'},
  { title: 'Status', field: 'status'},
  { title: 'Country', field: 'stripeData.country'},
  { title: 'Email', field: 'stripeData.email'},
  { title: 'Business profile: name', field: 'stripeData.businessProfile.name'},
  { title: 'Business profile: url', field: 'stripeData.businessProfile.url'},
];

export const PaymentAccountSummary: FunctionComponent<Props> = (props) => {
  const rows = ROWS.map( (row, index) => {
    const field = row.field;
    
    const paymentAccount = props.paymentAccount;

     const contentCell = propz.get(paymentAccount, row.field.split('.'), '');

    
    return (
      <tr key = {`Payment account_summary_${index}`}>
        <td>{row.title}</td>
        <td>{contentCell}</td>
      </tr>
    );
  });
  
  return (
    <table className={"table table-sm mPre mVerticalAlignMiddle"}>
      <tbody>
      {rows}
      </tbody>
    </table>
  );
};
PaymentAccountSummary.displayName = 'PaymentAccountSummary';