import axios from 'axios';
import * as propz from 'propz';
import { AppUser } from 'Src/views/App/App';
import { DEFAULT_FILTER } from '../admin/user';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../consts/table';
import { Count } from '../../../models/count';

export function getTournamentNotifications(user: AppUser, tournamentId: string, filter = DEFAULT_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter
    }
  };

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/tournament/${tournamentId}/schoolNotifications`, config)
    .then(response => {
      return response.data;
    });
}

export function getAllTournamentNotifications(user: AppUser, tournamentId: string, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/tournament/${tournamentId}/schoolNotifications`, config)
    .then(response => {
      return response.data;
    });
}

export function getTournamentNotificationsCount(user: AppUser, tournamentId: string, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where
        }
      }
    };
  } else {
    config = {
      headers: { usid: key }
    };
  }

  return axios
    .get<Count>(
      `${window.apiBase}/i/schools/${activeSchoolId}/tournament/${tournamentId}/schoolNotifications/count`,
      config
    )
    .then(response => {
      return response.data;
    });
}
