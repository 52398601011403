import * as React from 'react';
import { Button } from 'Src/components/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  onNewTweetButtonClick: () => void;
}

export function SchoolEventNewTweetButton(props: Props) {
  const { onNewTweetButtonClick } = props;

  return (
    <Button
      onClick={onNewTweetButtonClick}
      text={[<FontAwesomeIcon key={'event_new_tweet_button'} icon={['fab', 'twitter']} />, ' ', 'Tweet']}
      customClass={'mt-3'}
    />
  );
}
