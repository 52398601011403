import { AppUser } from 'Src/views/App/App';
import { DEFAULT_LIMIT } from 'Src/consts/table';
import axios from 'axios';

export function getSchoolGallery(user: AppUser, albumId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        limit: DEFAULT_LIMIT
      }
    }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/albums/${albumId}/photos`, config).then(response => {
    return response.data;
  });
}

export function addPhotoToSchoolGallery(
  user: AppUser,
  albumId: string,
  data: {
    name?: string;
    description?: string;
    picUrl?: string;
    accessPreset?: string;
  }
) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/albums/${albumId}/photos`, data, config)
    .then(response => {
      return response.data;
    });
}

export function deletePhotoFromSchoolGallery(user: AppUser, albumId: string, photoId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .delete(`${window.apiBase}/i/schools/${activeSchoolId}/albums/${albumId}/photos/${photoId}`, config)
    .then(response => {
      return response.data;
    });
}

export function getKeyToAddPhoto(user: AppUser, formData: FormData) {
  const { roleSessionKey: key } = user;

  const config = {
    headers: { usid: key },
    'Content-Type': 'multipart/form-data'
  };

  return axios.post(`${window.apiImg}/images`, formData, config).then(response => {
    return response.data;
  });
}

export function changeSchoolPhotoInfo(
  user: AppUser,
  albumId: string,
  photoId: string,
  data: {
    name?: string;
    description?: string;
    picUrl?: string;
    accessPreset?: string;
  }
) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .put(`${window.apiBase}/i/schools/${activeSchoolId}/albums/${albumId}/photos/${photoId}`, data, config)
    .then(response => {
      return response.data;
    });
}

export function getImageDirectUrl(url: string) {
  return axios.get(`${url}?direct_url=true`).then(response => {
    return response;
  });
}

export function uploadSchoolPhoto(user, file) {
  const { roleSessionKey: key } = user;

  const formData = new FormData();
  formData.append('image', file);

  const config = {
    headers: { usid: key },
    'Content-Type': 'multipart/form-data'
  };

  return axios.post(`${window.apiImg}/images`, formData, config).then(response => {
    const { data } = response;

    return `${window.apiImg}/images/${data.key}`;
  });
}
