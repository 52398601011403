import * as React from 'react';
import { SchoolEvent } from '../../../../../../models/event';
import { AppUser } from '../../../../../App/App';
import { getCricketResults } from '../../../../../../helpers/sport/cricket';
import './SchoolEventCricketResult.scss';

interface Props {
  event: SchoolEvent;
  user: AppUser;
}

export function SchoolEventCricketResult(props: Props) {
  const { event, user } = props;
  const { activeSchoolId } = user;

  const result = getCricketResults(event, activeSchoolId);

  return <div className="eCricketResultBlock">{result}</div>;
}
