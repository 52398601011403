import { TEAM_TYPE_TYPE } from '../types/tournamentTeam';

export const TEAM_TABS = ['Summary', 'Participants'];

export const TEAM_TYPE: {
  PROTOTYPE: TEAM_TYPE_TYPE;
  CLONE: TEAM_TYPE_TYPE;
  ADHOC: TEAM_TYPE_TYPE;
} = {
  PROTOTYPE: 'PROTOTYPE',
  CLONE: 'CLONE',
  ADHOC: 'ADHOC'
};
