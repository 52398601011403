import * as React from 'react';
import * as propz from 'propz';
import { Field, Form, Formik } from 'formik';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from '../../../../../../consts/report';
import { Tournament } from '../../../../../../models/tournament';
import { Switch } from '../../../../../../components/Switch/Switch';
import { LabelWithQuestionIcon } from '../../../../../../components/LabelWithQuestionIcon/LabelWithQuestionIcon';

interface Props {
  onCloseClick: () => void;
  onSubmit: (data: any, reportType: string) => void;
  tournament: Tournament;
}

// report 046
export function TournamentSchoolsReportForm(props: Props) {
  const { tournament } = props;

  const requestInitial = {
    isShowAllAdmins: false
  };

  return (
    <Formik
      onSubmit={values => {
        props.onSubmit(values, REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.TOURNAMENT_SCHOOLS);
      }}
      initialValues={requestInitial}
      render={({ values, setFieldValue }) => (
        <Form>
          <div className="bReportFormTitle">Tournament schools</div>
          <div className="mb-3 mt-3">
            {'View the list of schools that have booked ' +
              ' onto the tournament as well as the details of ' +
              ' their admins.'}
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isShowAllAdmins"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowAllAdmins}
                    onChange={event => {
                      const isShowAllAdmins = event.target.checked;
                      setFieldValue('isShowAllAdmins', isShowAllAdmins);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show all school users with Admin role"
                hintText={'Show all school users with Admin role.'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowAllAdmins"
              />
            </div>
          </div>

          <button type="button" className="btn btn-secondary mr-3" onClick={props.onCloseClick}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Get
          </button>
        </Form>
      )}
    />
  );
}
