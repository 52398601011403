import * as React from 'react';
import { Component } from 'react';
import { Button } from 'Src/components/Button/Button';
import { SchoolEvent } from 'Src/models/event';
import * as propz from 'propz';
import { isTeamSportEvent } from 'Src/helpers/event/event';
import { SchoolEventSchool } from 'Src/models/event';
import { Checkbox } from 'Src/components/Checkbox/Checkbox';

import './RemoveSchoolsForm.scss';

interface Props {
  onClose: () => void;
  onSubmit: (selectedSchools: SchoolEventSchool[]) => void;
  event: SchoolEvent;
}

interface State {
  selectedSchools: SchoolEventSchool[];
  isError: boolean;
  schools: any[];
}

export default class RemoveSchoolsForm extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      selectedSchools: [],
      schools: [],
      isError: false
    };
  }

  componentDidMount() {
    const { event } = this.props;
    const invitedSchools = propz.get(event, ['invitedSchools'], []);

    const invitedSchoolsWithSelect = invitedSchools.map(school => {
      return { ...school, isSelected: false };
    });

    this.setState({
      schools: invitedSchoolsWithSelect
    });
  }

  onRemoveClick = () => {
    const { selectedSchools } = this.state;
    const { event, onSubmit } = this.props;
    const isError = isTeamSportEvent(event) && selectedSchools.length < 2;
    if (isError) {
      this.setState({
        isError
      });
    } else {
      onSubmit(selectedSchools);
    }
  };

  onSelectSchool = (school: SchoolEventSchool, schoolIndex: number) => {
    const { selectedSchools, schools } = this.state;
    const { event } = this.props;

    const isError = isTeamSportEvent(event) && selectedSchools.length < 2;

    this.setState({
      isError
    });

    const invitedSchoolsUpdated = schools.map((school, index) => {
      return schoolIndex === index ? { ...school, isSelected: !school.isSelected } : school;
    });

    const selectedSchoolIndex = selectedSchools.findIndex(item => school.id === item.id);
    let selectedSchoolsUpdated = [...selectedSchools];

    if (selectedSchoolIndex !== -1) {
      selectedSchoolsUpdated.splice(selectedSchoolIndex, 1);
    } else {
      selectedSchoolsUpdated.push(school);
    }

    this.setState({
      selectedSchools: selectedSchoolsUpdated,
      schools: invitedSchoolsUpdated
    });
  };

  render() {
    const { onClose } = this.props;
    const { selectedSchools, isError, schools } = this.state;
    const isSelectedSchoolsExist = selectedSchools.length !== 0;

    return (
      <div className="bRemoveSchoolsForm">
        <div className="bInvitedSchools">
          {schools.map((school, index) => {
            const schoolLogo = propz.get(school, ['pic'], '');
            const schoolName = propz.get(school, ['name'], '');
            return (
              <div className="eInvitedSchool" key={school.id}>
                <div className="bSchoolLogo">
                  <img src={schoolLogo} alt="" />
                  <div className="eSchoolName">{schoolName}</div>
                </div>
                <Checkbox
                  id={school.id}
                  value={school.isSelected}
                  onChange={() => this.onSelectSchool(school, index)}
                  customClass="eInvitedSchoolCheckbox"
                />
              </div>
            );
          })}
        </div>

        {isError && <div className="eRemoveSchoolsFormError">You need to have at least two schools in the event</div>}

        <Button onClick={onClose} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
        <Button
          onClick={this.onRemoveClick}
          text={'Remove'}
          disabled={!isSelectedSchoolsExist}
          customClass={!isSelectedSchoolsExist ? 'mt-3 mb-3 btn-secondary' : 'mt-3 mb-3 btn-primary'}
        />
      </div>
    );
  }
}
