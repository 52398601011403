import * as React from 'react';
import { Button } from 'Src/components/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  onPrevEventClick: () => void;
  onNextEventClick: () => void;
  isPrevButtonDisabled: boolean;
  isNextButtonDisabled: boolean;
}

export function StudentEventNavigationButtons(props: Props) {
  const { onPrevEventClick, onNextEventClick, isPrevButtonDisabled, isNextButtonDisabled } = props;
  return (
    <div>
      <Button
        text={[<FontAwesomeIcon key={'event_navigation_button_previous'} icon="arrow-left" />, ' ', 'Prev']}
        onClick={onPrevEventClick}
        disabled={isPrevButtonDisabled}
        customClass={'mr-3'}
      />
      <Button
        text={['Next', ' ', <FontAwesomeIcon key={'event_navigation_button_next'} icon="arrow-right" />]}
        onClick={onNextEventClick}
        disabled={isNextButtonDisabled}
        customClass={''}
      />
    </div>
  );
}
