import * as React from 'react';
import {FunctionComponent} from 'react';
import * as Moment from 'moment';

import './GeneralMessagePreview.css';

interface Props {
  messageSmsText: string
  showTitle?: boolean
}

export const GeneralMessagePreviewSms: FunctionComponent<Props> = (props) => {

  const now = new Date();
  const currentTime = Moment(now).format('HH:mm');

  return(
    <div className="card border-warning h-100">
      {props.showTitle && (
        <h5 className="card-header text-white eGeneralMessagePreviewSmsHeader">Text preview</h5>
      )}
      <div className="eGeneralMessagePreviewSms">
        <div className="eGeneralMessagePreviewSmsTime">
          { `Today ${currentTime}` }
        </div>
        <div className="eGeneralMessagePreviewSmsMessage">
          { props.messageSmsText }
        </div>
      </div>
    </div>
  )
};