import * as React from 'react';
import { Component } from 'react';
import * as propz from 'propz';
import { AppUser } from 'Src/views/App/App';
import { SchoolForm } from 'Src/models/form';
import { IndividualTournamentTeamPlayer } from 'Src/models/tournamentTeamPlayer';
import { SchoolEvent } from 'Src/models/event';
import { getEventGeneratedName } from 'Src/helpers/accessor/accessor';
import { getFullName } from 'Src/helpers/autocomplete/autocomplete';
import { Switch } from 'Src/components/Switch/Switch';
import { Autocomplete } from 'Src/components/Autocomplete/Autocomplete';
import { searchFunctionHighLevelTournamentPlayers } from 'Src/helpers/autocomplete/autocomplete';

interface Props {
  user: AppUser;
  events: SchoolEvent[];
  forms: SchoolForm[];
  tournamentId: string;
  teamId: string;
  addStudentsToEvent: (events: SchoolEvent[], selectedStudents: IndividualTournamentTeamPlayer[]) => void;
  onCloseClick: () => void;
}

interface State {
  selectedStudents: IndividualTournamentTeamPlayer[];
  isEventsForYongerGroup: boolean;
}

export class StudentsToAddToEventForm extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      selectedStudents: [],
      isEventsForYongerGroup: false
    };
  }

  renderSelectedEvents = () => {
    const { user, events } = this.props;

    return (
      <ul>
        {events.map(event => {
          const { id } = event;
          const eventName = getEventGeneratedName(event, { user });

          return <li key={id}>{eventName}</li>;
        })}
      </ul>
    );
  };

  renderStudents() {
    const { selectedStudents } = this.state;

    return selectedStudents.map(student => {
      const studentName = `${getFullName(student)} ${student.form.name}`;

      return (
        <div key={student.id} className={'d-flex'}>
          <div className="bg-primary text-white w-100 p-2 mt-2 d-flex justify-content-between rounded">
            <span>{studentName}</span>
            <div className="ml-1">
              <div
                className="eButtonClose"
                onClick={() => {
                  const filteredStudents = selectedStudents.filter(item => item.id !== student.id);

                  this.setState({
                    selectedStudents: filteredStudents
                  });
                }}
              >
                <span>&times;</span>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  searchFunction = (text: string) => {
    const { user, events, forms, tournamentId, teamId } = this.props;
    const { isEventsForYongerGroup, selectedStudents } = this.state;

    const selectedStudentsIds = selectedStudents.map(event => event.userId);

    return searchFunctionHighLevelTournamentPlayers(
      text,
      user,
      events,
      forms,
      tournamentId,
      teamId,
      selectedStudentsIds,
      isEventsForYongerGroup
    );
  };

  onSubmit = () => {
    const { addStudentsToEvent, events } = this.props;
    const { selectedStudents } = this.state;

    addStudentsToEvent(events, selectedStudents);
  };

  render() {
    const { onCloseClick } = this.props;
    const { isEventsForYongerGroup, selectedStudents } = this.state;

    const isSelectedStudentsEmpty = selectedStudents.length === 0;

    return (
      <div>
        <h6>Event(s) you have selected:</h6>

        {this.renderSelectedEvents()}

        <div className="mt-3 mb-5">
          <Switch
            value={isEventsForYongerGroup}
            onChange={() => {
              this.setState({ isEventsForYongerGroup: !isEventsForYongerGroup });
            }}
            text={'Include younger children'}
            name={'ageGroup'}
            customClass="mb-3"
          />
          <Autocomplete
            searchFunction={this.searchFunction}
            getElementTitle={item => `${getFullName(item)} ${item.form.name}`}
            customClass="mFullWidth"
            placeholder="Start typing the student name"
            onSelect={event => {
              this.setState(prevState => {
                return {
                  selectedStudents: [...prevState.selectedStudents, event]
                };
              });
            }}
          />
          {this.renderStudents()}
        </div>

        <div className="d-flex justify-content-end mt-3">
          <button className="btn btn-primary mr-2" disabled={isSelectedStudentsEmpty} onClick={this.onSubmit}>
            OK
          </button>
          <button className="btn btn-secondary" onClick={onCloseClick}>
            CLOSE
          </button>
        </div>
      </div>
    );
  }
}
