import * as React from 'react';
import * as propz from 'propz';
import { FunctionComponent } from 'react';
import { ClubParticipantsExtended } from 'Src/models/club';
import { CLUB_MESSAGE_INVITE_STATUS_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/club';

interface Props {
  clubParticipants: ClubParticipantsExtended[];
}

export const ClubParticipantsSummary: FunctionComponent<Props> = props => {
  const { clubParticipants } = props;

  const rows = clubParticipants.map((participant, index) => {
    const firstName = propz.get(participant, ['firstName'], '');
    const lastName = propz.get(participant, ['lastName'], '');
    const formName = propz.get(participant, ['form', 'name'], '');
    const messageStatus = propz.get(participant, ['messageStatus']);

    return (
      <tr key={`club_students_${index}`}>
        <td>{index + 1}</td>
        <td>{firstName}</td>
        <td>{lastName}</td>
        <td>{formName}</td>
        <td>{CLUB_MESSAGE_INVITE_STATUS_SERVER_TO_CLIENT_MAPPING[messageStatus]}</td>
      </tr>
    );
  });

  const emptyRow = (
    <tr key={'club_participants_empty_row'}>
      <td>No club participants</td>
    </tr>
  );

  return (
    <table className={'table table-sm mPre mVerticalAlignMiddle'}>
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Surname</th>
          <th>Form</th>
          <th>Invitation status</th>
        </tr>
      </thead>
      <tbody>{rows.length > 0 ? rows : emptyRow}</tbody>
    </table>
  );
};

ClubParticipantsSummary.displayName = 'ClubParticipants';
