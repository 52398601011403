import * as React from 'react';
import { Component } from 'react';
import { AppUser } from 'Src/views/App/App';
import { ModalDialog } from '../ModalDialog/ModalDialog';
import { ROLE_SERVER_TO_CLIENT_MAPPING } from '../../consts/user';

interface Props {
  user: AppUser;
  onSubmitClick: (role) => void;
  onCancelClick: () => void;
  roles: any[];
  isOpen: boolean;
}

interface State {
  selectedRole: string;
}

export class RoleRemoveModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const role = typeof props.roles !== 'undefined' && props.roles.length > 0 ? props.roles[0] : '';
    this.state = { selectedRole: role };
  }

  onRoleSelected = (event): void => {
    this.setState({ selectedRole: event.target.value });
  };

  onFormsSubmit = (): void => {
    const selectedRole = this.props.roles.find(role => role === this.state.selectedRole);
    this.props.onSubmitClick(selectedRole);
  };

  render() {
    return (
      <ModalDialog
        title="Remove role"
        isOpen={this.props.isOpen}
        positiveButtonTitle="Remove"
        negativeButtonTitle="Cancel"
        onPositiveButtonClick={this.onFormsSubmit}
        onNegativeButtonClick={this.props.onCancelClick}
      >
        <div className="form-group">
          <label>Role</label>
          <select value={this.state.selectedRole} className="form-control" onChange={this.onRoleSelected}>
            {this.props.roles.map((role, index) => (
              <option key={role} value={role}>
                {ROLE_SERVER_TO_CLIENT_MAPPING[role]}
              </option>
            ))}
          </select>
        </div>
      </ModalDialog>
    );
  }
}
