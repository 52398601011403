import * as React from 'react';
import * as BPromise from 'bluebird';
import * as Lazy from 'lazy.js';
import { parse } from 'query-string';
import { Component } from 'react';
import { ProgressBar } from 'Src/components/ProgressBar/ProgressBar';
import { History, Location } from 'history';
import { AppUser } from 'Src/views/App/App';
import { Loader } from 'Src/components/Loader/Loader';
import { getAllForms } from 'Src/helpers/service/admin/forms';
import { SchoolEventForm } from './SchoolEventsForm/SchoolEventForm';
import { SchoolForm } from 'Src/models/form';
import { EVENT_ADD_TEAM } from '../../../../../consts/event';
import { SchoolEventAddExistingTeam } from './SchoolEventAddExistingTeam/SchoolEventAddExistingTeam';
import {
  getMaxTeams,
  getMinTeams,
  isInterSchoolsEvent,
  isNonTeamSportEvent,
  isTeamOrTwoOnTwoSportEvent
} from '../../../../../helpers/event/event';
import { activateEvent, createEvent } from 'Src/helpers/service/admin/event';
import { addSchoolEventTeam } from 'Src/helpers/service/admin/tournamentTeams';
import { SchoolEventAddNewTeam } from './SchoolEventAddNewTeam/SchoolEventAddNewTeam';
import { EVENT_TYPE_TYPE } from '../../../../../types/event';
import { GENDER_TYPE } from '../../../../../types/common';
import { getVenueTypeByPostcode } from '../../../../../helpers/venue/venue';
import { MODE_TYPE } from '../../../../../consts/common';
import * as propz from 'propz';
import { updateSchoolEventIndividualsBatch } from '../../../../../helpers/service/admin/schoolEventIndividuals';
import { SimpleModal } from '../../../../../components/SimpleModal/SimpleModal';
import { TEAM_TYPE } from '../../../../../consts/team';
import { SchoolEventSaveTeamsForm } from './SchoolEventSaveTeamsForm/SchoolEventSaveTeamsForm';
import { createSchoolTeam } from '../../../../../helpers/service/admin/schoolTeams';
import { addEventToSchoolLeague } from 'Src/helpers/service/admin/schoolLeagues';
import './SchoolEventManager.scss';

export interface SchoolEventFromForm {
  ages: number[];
  distance: number;
  endTime: Date;
  eventType: EVENT_TYPE_TYPE;
  gender: GENDER_TYPE;
  houses: any[];
  multiparty: boolean;
  isStudentCanSubmitResult: boolean;
  isStudentCanJoin: boolean;
  clubId: boolean;
  participants: any[];
  sport: any;
  sportId: string;
  startTime: Date;
  venue: any;
  shortDescription?: string;
  photos: { picUrl: string }[];
  leaguePositionsCount: number;
  replyToEmail?: string;
}

type Step = 'EVENT_SUMMARY' | 'ADD_EXISTING_TEAM' | 'ADD_NEW_TEAM';

const EVENT_WIZARD_STEP: {
  [key: string]: Step;
} = {
  EVENT_SUMMARY: 'EVENT_SUMMARY',
  ADD_EXISTING_TEAM: 'ADD_EXISTING_TEAM',
  ADD_NEW_TEAM: 'ADD_NEW_TEAM'
};

const EVENT_WIZARD_STEP_MAPPING: {
  [key: string]: string;
} = {
  EVENT_SUMMARY: 'New event',
  ADD_EXISTING_TEAM: 'Add existing team',
  ADD_NEW_TEAM: 'Add new team'
};

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

interface State {
  currentEvent: SchoolEventFromForm;
  currentStep: Step;
  forms: SchoolForm[];

  isLoading: boolean;

  addTeamOption: string;

  blackListTeamIds: string[];
  teams: any[];
  isSaveTeamsModalOpen: boolean;
}

export class SchoolEventManager extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      currentEvent: undefined,
      forms: [],

      currentStep: EVENT_WIZARD_STEP.EVENT_SUMMARY,
      isLoading: false,
      addTeamOption: '',
      blackListTeamIds: [],
      teams: [],
      isSaveTeamsModalOpen: false
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });
    this.setItems();
  }

  getCreateDate(): any {
    const { history } = this.props;
    const search = parse(history.location.search);
    const date = propz.get(search, ['date']);
    return date;
  }

  setItems() {
    const { user } = this.props;
    return getAllForms(user).then(forms => {
      this.setState({
        forms: forms,
        isLoading: false
      });

      return true;
    });
  }

  getSteps(): Step[] {
    return [EVENT_WIZARD_STEP.EVENT_SUMMARY, EVENT_WIZARD_STEP.ADD_EXISTING_TEAM, EVENT_WIZARD_STEP.ADD_NEW_TEAM];
  }

  getStepTitle(): string {
    const { currentStep } = this.state;
    return EVENT_WIZARD_STEP_MAPPING[currentStep];
  }

  onCancelClick = () => {
    const { history } = this.props;

    history.push({
      pathname: '/events'
    });
  };

  onEventFormSubmit = data => {
    const { addTeamOption, ...rest } = data;

    switch (addTeamOption) {
      case EVENT_ADD_TEAM.NEW:
        this.setState({
          currentEvent: rest,
          addTeamOption: addTeamOption,
          currentStep: EVENT_WIZARD_STEP.ADD_NEW_TEAM
        });
        break;
      case EVENT_ADD_TEAM.EXISTING:
        this.setState({
          currentEvent: rest,
          addTeamOption: addTeamOption,
          currentStep: EVENT_WIZARD_STEP.ADD_EXISTING_TEAM
        });
        break;
      default:
        console.error('Add team option incorrect');
    }
  };

  onAddExistingTeamClick = team => {
    const { id } = team;
    const { blackListTeamIds, teams } = this.state;
    const blackListTeamIdsUpdated = [...blackListTeamIds, id];
    const teamsUpdated = [...teams, team];

    this.setState({
      blackListTeamIds: blackListTeamIdsUpdated,
      teams: teamsUpdated,
      currentStep: EVENT_WIZARD_STEP.ADD_EXISTING_TEAM
    });
  };

  onAddNewTeamClick = team => {
    const { id } = team;
    const { blackListTeamIds, teams } = this.state;
    const blackListTeamIdsUpdated = [...blackListTeamIds, id];
    const teamsUpdated = [...teams, team];

    this.setState({
      blackListTeamIds: blackListTeamIdsUpdated,
      teams: teamsUpdated,
      currentStep: EVENT_WIZARD_STEP.ADD_NEW_TEAM
    });
  };

  //copy-paste
  onModifiedDataEvent(currentEvent: SchoolEventFromForm, user: AppUser) {
    const {
      gender,
      ages,
      eventType,
      sportId,
      startTime,
      endTime,
      venue,
      participants,
      houses,
      multiparty,
      shortDescription,
      photos,
      isStudentCanSubmitResult,
      isStudentCanJoin,
      leaguePositionsCount,
      replyToEmail
    } = currentEvent;
    const { activeSchool } = user;

    //Important! Modify seconds and milliseconds in start time for navigation
    const now = new Date();
    const nowS = now.getSeconds();
    const nowMs = now.getMilliseconds();
    startTime.setSeconds(nowS, nowMs);

    const body = {
      gender: gender,
      shortDescription: shortDescription,
      multiparty: multiparty,
      eventType: eventType,
      sportId: sportId,
      venue: venue,
      ages: ages.map(age => Number(age)),
      houses: houses.map(house => house.id),
      startTime: startTime.toISOString(),
      endTime: endTime.toISOString(),
      invitedSchoolIds: isInterSchoolsEvent(currentEvent)
        ? participants.map(participant => participant.id)
        : [user.activeSchoolId],
      photos: photos,
      isStudentCanSubmitResult: isStudentCanSubmitResult,
      isStudentCanJoin: isStudentCanJoin,
      leaguePositionsCount: Number(leaguePositionsCount),
      replyToEmail: replyToEmail
    };

    switch (true) {
      case typeof venue !== 'undefined' && typeof venue.point !== 'undefined' && venue.point.coordinates.length > 0:
        if (typeof venue.postcodeId === 'undefined') {
          body.venue.postcodeId = venue.id;
        }
        body.venue.venueType = getVenueTypeByPostcode(venue, activeSchool);
        break;
      case typeof venue !== 'undefined' && typeof venue.point === 'undefined':
        body.venue.venueType = getVenueTypeByPostcode(venue, activeSchool);
        break;
      default:
        delete body.venue;
    }
    delete body.venue.id;
    delete body.venue.isHome;
    delete body.venue.name;
    delete body.venue.postcode;

    return body;
  }

  onSaveClick = (team, isCreateAnotherEvent: boolean) => {
    const { teams, currentEvent } = this.state;
    const { user, history, location } = this.props;
    const teamsUpdated = [...teams, team];

    const isSomeTeamAdhoc = teamsUpdated.some(team => team.teamType === TEAM_TYPE.ADHOC);
    const isShowSaveTeamsModal = isTeamOrTwoOnTwoSportEvent(currentEvent) && isSomeTeamAdhoc;

    const aggregationType = propz.get(location, ['state', 'aggregationType']);
    const leagueId = propz.get(location, ['state', 'leagueId']);
    const isAggregationTypeExist = typeof aggregationType !== 'undefined' && aggregationType !== '';

    if (isShowSaveTeamsModal) {
      this.setState({
        isSaveTeamsModalOpen: true,
        teams: teamsUpdated
      });
    } else {
      this.setState({
        isLoading: true
      });

      const body = this.onModifiedDataEvent(currentEvent, user);

      let eventId;

      if (isNonTeamSportEvent(currentEvent)) {
        createEvent(user, body)
          .then(event => {
            eventId = event.id;
            const players = Lazy(teamsUpdated)
              .map(team => team.players)
              .flatten()
              .toArray();
            return updateSchoolEventIndividualsBatch(user, eventId, {
              add: players.map(item => ({ userId: item.userId, permissionId: item.permissionId }))
            });
          })
          .then(res => {
            return activateEvent(user, eventId);
          })
          .then(event => {
            if (isAggregationTypeExist) {
              const eventId = propz.get(event, ['eventDetails', 'eventId']);
              const data = {
                ...event,
                eventId
              };

              addEventToSchoolLeague(user, leagueId, data);
            }
            if (isCreateAnotherEvent) {
              this.setState({
                isLoading: false,
                currentStep: EVENT_WIZARD_STEP.EVENT_SUMMARY,
                currentEvent: undefined
              });
            } else {
              history.push({
                pathname: '/events/event',
                search: `id=${eventId}&mode=${MODE_TYPE.CREATE_ANOTHER_EVENT}`
              });
            }

            return event;
          });
      } else {
        createEvent(user, body)
          .then(event => {
            eventId = event.id;
            return BPromise.all(
              teamsUpdated.map(team => {
                return addSchoolEventTeam(user, eventId, team);
              })
            );
          })
          .then(res => {
            return activateEvent(user, eventId);
          })
          .then(event => {
            if (isAggregationTypeExist) {
              const eventId = propz.get(event, ['eventDetails', 'eventId']);
              const data = {
                ...event,
                eventId
              };

              addEventToSchoolLeague(user, leagueId, data);
            }
            if (isCreateAnotherEvent) {
              this.setState({
                isLoading: false,
                currentStep: EVENT_WIZARD_STEP.EVENT_SUMMARY,
                currentEvent: undefined
              });
            } else {
              history.push({
                pathname: '/events/event',
                search: `id=${eventId}&mode=${MODE_TYPE.CREATE_ANOTHER_EVENT}`
              });
            }
            return event;
          });
      }
    }
  };
  //return to initial state
  goBack = () => {
    this.setState({
      currentStep: EVENT_WIZARD_STEP.EVENT_SUMMARY,
      addTeamOption: '',
      blackListTeamIds: [],
      teams: []
    });
  };

  renderViewByStep(): React.ReactNode {
    const { currentStep, currentEvent, blackListTeamIds, teams, forms } = this.state;
    const { user, location } = this.props;

    const aggregationType = propz.get(location, ['state', 'aggregationType'], '');

    const minTeams = getMinTeams(currentEvent);
    const maxTeams = getMaxTeams(currentEvent);

    //+1 because team in manager
    const teamsCount = teams.length + 1;

    const isSaveButtonEnabled = teamsCount <= maxTeams && teamsCount >= minTeams;
    const isAddTeamButtonEnabled = teamsCount < maxTeams;
    const createDate = this.getCreateDate();

    switch (currentStep) {
      case EVENT_WIZARD_STEP.EVENT_SUMMARY:
        return (
          <SchoolEventForm
            schoolEvent={currentEvent}
            onSubmit={this.onEventFormSubmit}
            onCancel={this.onCancelClick}
            user={user}
            forms={forms}
            createDate={createDate}
            aggregationType={aggregationType}
          />
        );

      case EVENT_WIZARD_STEP.ADD_NEW_TEAM:
        return (
          <SchoolEventAddNewTeam
            user={user}
            currentEvent={currentEvent}
            isSaveButtonDisabled={!isSaveButtonEnabled}
            isAddTeamButtonDisabled={!isAddTeamButtonEnabled}
            onAddExistingTeamClick={this.onAddExistingTeamClick}
            onAddNewTeamClick={this.onAddNewTeamClick}
            onSaveClick={this.onSaveClick}
            teams={teams}
            goBack={this.goBack}
          />
        );

      case EVENT_WIZARD_STEP.ADD_EXISTING_TEAM: {
        return (
          <SchoolEventAddExistingTeam
            onAddExistingTeamClick={this.onAddExistingTeamClick}
            onAddNewTeamClick={this.onAddNewTeamClick}
            onSaveClick={this.onSaveClick}
            user={user}
            currentEvent={currentEvent}
            blackListTeamIds={blackListTeamIds}
            isSaveButtonDisabled={!isSaveButtonEnabled}
            isAddTeamButtonDisabled={!isAddTeamButtonEnabled}
            teams={teams}
            goBack={this.goBack}
          />
        );
      }
    }
  }

  renderProgressBar() {
    const { currentStep } = this.state;

    //hardcode for progress bar
    const totalSteps = 2;

    let progress, stepIndex;
    switch (currentStep) {
      case EVENT_WIZARD_STEP.EVENT_SUMMARY:
        progress = 50;
        stepIndex = 1;
        break;
      case EVENT_WIZARD_STEP.ADD_NEW_TEAM:
      case EVENT_WIZARD_STEP.ADD_EXISTING_TEAM:
        progress = 100;
        stepIndex = 2;
        break;
    }

    return <ProgressBar progress={progress} step={stepIndex} stepTotal={totalSteps} />;
  }

  renderSaveTeamsModal() {
    const { user } = this.props;
    const { isSaveTeamsModalOpen, teams } = this.state;
    const teamsAdhoc = teams.filter(team => team.teamType === TEAM_TYPE.ADHOC);

    return (
      <SimpleModal isOpen={isSaveTeamsModalOpen}>
        <SchoolEventSaveTeamsForm onSubmit={this.onSaveTeamSubmit} user={user} teamsAdhoc={teamsAdhoc} />
      </SimpleModal>
    );
  }

  onSaveTeamSubmit = (data: any) => {
    const { teams, currentEvent } = this.state;
    const { user, history, location } = this.props;

    const teamsNotAdhoc = teams.filter(team => team.teamType !== TEAM_TYPE.ADHOC);

    const aggregationType = propz.get(location, ['state', 'aggregationType']);
    const leagueId = propz.get(location, ['state', 'leagueId']);
    const isAggregationTypeExist = typeof aggregationType !== 'undefined' && aggregationType !== '';

    const teamsToSaveAsPrototype = data
      .filter(team => team.isSave)
      .map(team => {
        const { isSave, ...rest } = team;

        return { ...rest, teamType: TEAM_TYPE.PROTOTYPE };
      });

    //get teams adhoc again, because team name may change
    const teamsAdhoc = data.map(team => {
      const { isSave, ...rest } = team;
      return { ...rest };
    });

    const teamsToEvent = [...teamsNotAdhoc, ...teamsAdhoc];

    this.setState({
      isLoading: true
    });

    const body = this.onModifiedDataEvent(currentEvent, user);
    let eventId;

    createEvent(user, body)
      .then(event => {
        eventId = event.id;
        return BPromise.all(teamsToEvent.map(team => addSchoolEventTeam(user, eventId, team)));
      })
      .then(res => {
        return activateEvent(user, eventId);
      })
      .then(event => {
        if (isAggregationTypeExist) {
          const eventId = propz.get(event, ['eventDetails', 'eventId']);
          const data = {
            ...event,
            eventId
          };

          addEventToSchoolLeague(user, leagueId, data);
        }

        return BPromise.all(
          teamsToSaveAsPrototype.map(teamToSaveAsPrototype => createSchoolTeam(user, teamToSaveAsPrototype))
        );
      })
      .then(res => {
        history.push({
          pathname: '/events/event',
          search: `id=${eventId}&mode=${MODE_TYPE.CREATE_ANOTHER_EVENT}`
        });
      });
  };

  render() {
    const { isLoading, isSaveTeamsModalOpen } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes = isSaveTeamsModalOpen ? 'mt-3 modal-open' : 'mt-3';

    return (
      <div className={classes}>
        {this.renderSaveTeamsModal()}
        <h3 className="mb-3 d-flex">
          <span className="text-muted">{`${this.getStepTitle()}`}</span>
        </h3>
        {this.renderProgressBar()}
        {this.renderViewByStep()}
      </div>
    );
  }
}
