import * as React from 'react';
import { Component } from 'react';
import * as Lazy from 'lazy.js';
import * as BPromise from 'bluebird';
import * as propz from 'propz';
import { AppUser } from 'Src/views/App/App';
import { History, Location } from 'history';
import { ProgressBar } from 'Src/components/ProgressBar/ProgressBar';
import { Button } from 'Src/components/Button/Button';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { ClubWizardSummaryStep } from './ClubWizardSteps/ClubWizardSummaryStep';
import { Sport } from 'Src/models/sport';
import { Loader } from 'Src/components/Loader/Loader';
import {
  searchFunctionClubSports,
  searchFunctionCoaches,
  searchFunctionMembersOfStaff,
  searchFunctionVenues
} from 'Src/helpers/autocomplete/autocomplete';
import { GENDER, SPORT_GENDER_CLIENT_TO_SERVER_MAPPING } from 'Src/consts/common';
import { USER_STATUS } from 'Src/consts/user';
import { SchoolForm } from 'Src/models/form';
import { DEFAULT_LIMIT, DEFAULT_SKIP, FIRST_PAGE } from 'Src/consts/table';
import { getSchoolForms } from 'Src/helpers/service/admin/user';
import { MultiSelectCheckboxItem } from 'Src/components/MultiSelectCheckboxes/MultiSelectCheckboxes';
import { parse } from 'query-string';
import {
  getAgesForMultiSelect,
  getDaysForMultiselect,
  getDefaultGendersForMultiSelect,
  getGendersForMultiSelect,
  getTermsForMultiSelect
} from 'Src/helpers/multiselect/multiselect';
import { getSchoolTerms } from 'Src/helpers/service/admin/schoolTerms';
import { ClubWizardScheduleStep } from './ClubWizardSteps/ClubWizardScheduleStep';
import { ClubWizardBookingStep } from './ClubWizardSteps/ClubWizardBookingStep';
import { ClubPaymentSettingsStep } from './ClubWizardSteps/ClubPaymentSettingsStep';
import { CLUB_SCHEDULE, CLUB_STAFF, CLUB_PRICING } from 'Src/consts/club';
import { SCHOOL_CLUB_INVITATIONS_TYPE } from 'Src/types/school';
import { ClubWizardVenueStep } from './ClubWizardSteps/ClubWizardVenueStep';
import { SchoolTerm } from 'Src/models/schoolTerm';
import { WEEK_DAYS } from 'Src/consts/date';
import { createClub, getClub, updateClub } from 'Src/helpers/service/admin/clubs';
import { VenueForm } from 'Src/views/GenericView/AdminView/School/Venues/VenueForm/VenueForm';
import { createVenue } from 'Src/helpers/service/admin/venues';
import { Club, ClubSport, ClubStaff, ClubPaymentSettings, ClubPrice } from 'Src/models/club';
import {
  getClubCoachesForForm,
  getClubMembersOfStaffForForm,
  getClubTime,
  getStaffPermissionId
} from 'Src/helpers/club/club';
import { MODE_TYPE } from '../../../../../../consts/common';
import { getAllClubParticipants } from '../../../../../../helpers/service/admin/clubParticipants';
import { ClubParticipantsExtended } from '../../../../../../models/club';
import { addClubStudents } from '../../../../../../helpers/service/admin/clubStudents';
import { USER_GENDER } from '../../../../../../consts/user';
import { DEFAULT_CLUB_TABLE_FILTERS } from '../Clubs';
import { AGE_GROUPS_SORTED } from 'Src/consts/school';
import { getPaymentAccount, getAllPaymentsAccounts } from 'Src/helpers/service/admin/paymentAccounts';
import { isUserTeacher, isUserCoach } from 'Src/helpers/user/user';
import './ClubWizard.scss';

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

type Step = 'SUMMARY' | 'SCHEDULE' | 'LOCATION' | 'BOOKING' | 'PAYMENT_SETTINGS';

const CLUB_WIZARD_STEP: {
  [key: string]: Step;
} = {
  SUMMARY: 'SUMMARY',
  SCHEDULE: 'SCHEDULE',
  LOCATION: 'LOCATION',
  BOOKING: 'BOOKING',
  PAYMENT_SETTINGS: 'PAYMENT_SETTINGS'
};

const CLUB_WIZARD_STEP_MAPPING: {
  [key: string]: string;
} = {
  SUMMARY: 'Summary',
  SCHEDULE: 'Schedule',
  LOCATION: 'Location',
  BOOKING: 'Booking',
  PAYMENT_SETTINGS: 'Payment settings'
};

interface State {
  currentStep: Step;
  isCancelModalOpen: boolean;
  isLoading: boolean;

  //club fields
  name: string;
  description: string;
  time: string;
  location: string;
  repeatEveryWeeks: number;
  maxParticipants: number;
  duration: number;
  sport: Sport | ClubSport;
  forms: SchoolForm[];
  ageItems: MultiSelectCheckboxItem[];
  termItems: MultiSelectCheckboxItem[];
  dayItems: MultiSelectCheckboxItem[];
  genderItems: MultiSelectCheckboxItem[];
  start: Date;
  end: Date;
  invitations: SCHOOL_CLUB_INVITATIONS_TYPE;
  //autoenrollment: CLUB_AUTOENROLLMENT_TYPE;
  venue: any;
  schoolTerms: SchoolTerm[];
  coaches: ClubStaff[];
  membersOfStaff: ClubStaff[];
  clubParticipants: ClubParticipantsExtended[];
  currentClubAges: number[];
  currentClubGender: string;
  price: ClubPrice;
  paymentAccount: any;
  isPaymentRequired: boolean;
  //errors
  errors: ClubWizardErrors;

  isCreateVenueFormOpen: boolean;
  isSaveOnlyPassingStudentsModalOpen: boolean;
}

export interface ClubWizardErrors {
  name: boolean;
  sport: boolean;
  gender: boolean;
  duration: boolean;
  days: boolean;
  interval: boolean;
  venue: boolean;
  date: boolean;
  price: boolean;
  paymentAccount: boolean;
  isPaymentRequired: boolean;
}

export class ClubWizard extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const now = new Date();

    const { user } = props;
    const { activeSchool } = user;
    const { clubInvitations, defaultClubStartTime } = activeSchool;
    const isDefaultClubStartTimeExist = typeof defaultClubStartTime !== 'undefined';

    this.state = {
      currentStep: CLUB_WIZARD_STEP.SUMMARY,
      isCancelModalOpen: false,
      isLoading: false,
      name: '',
      description: '',
      sport: undefined,
      forms: [],
      ageItems: [],
      termItems: [],
      dayItems: [],
      genderItems: [],
      start: now,
      end: now,
      repeatEveryWeeks: 1,
      duration: 0,
      maxParticipants: 0,
      time: isDefaultClubStartTimeExist ? defaultClubStartTime : '10:00',
      invitations: clubInvitations,
      //autoenrollment: CLUB_AUTOENROLLMENT.ON,
      location: '',
      venue: undefined,
      schoolTerms: [],
      errors: {
        name: false,
        sport: false,
        gender: false,
        duration: false,
        days: false,
        interval: false,
        venue: false,
        date: false,
        paymentAccount: false,
        isPaymentRequired: false,
        price: false
      },
      isCreateVenueFormOpen: false,
      isSaveOnlyPassingStudentsModalOpen: false,
      membersOfStaff: [],
      clubParticipants: [],
      currentClubAges: [],
      currentClubGender: '',
      coaches: [],
      price: undefined,
      paymentAccount: '',
      isPaymentRequired: false
    };
  }

  componentDidMount() {
    const { user } = this.props;
    const { activeSchool } = user;
    const { isPaymentsEnabled } = activeSchool;

    const filter = {
      limit: DEFAULT_LIMIT,
      skip: DEFAULT_SKIP
    };

    this.setState({
      isLoading: true
    });

    const clubId = this.getClubId();
    const clubMode = this.getClubMode();
    const isCopyClubWithParticipantsMode = clubMode === MODE_TYPE.CLUB_COPY_WITH_PARTICIPANTS;

    const schoolFormsPromise = getSchoolForms(user, filter);
    const schoolTermsPromise = getSchoolTerms(user);
    const isClubExist = typeof clubId !== 'undefined';
    const clubPromise = isClubExist ? getClub(user, clubId) : BPromise.resolve();
    const clubParticipantsPromise = isCopyClubWithParticipantsMode
      ? getAllClubParticipants(user, clubId)
      : BPromise.resolve([]);

    let isPaymentAccountExist;
    let isPaymentRequired;

    BPromise.all([schoolFormsPromise, schoolTermsPromise, clubPromise, clubParticipantsPromise])
      .then(([forms, schoolTerms, club, clubParticipants]) => {
        if (typeof club !== 'undefined') {
          const {
            name,
            description,
            schedule,
            sport,
            gender,
            ages,
            venue,
            maxParticipants,
            invitations,
            location: clubLocation,
            paymentSettings,
            price
          } = club as Club;

          const { startDate, finishDate, duration, scheduleType, intervals, days, repeatEveryWeeks } = schedule;
          isPaymentRequired = propz.get(paymentSettings, ['askToPay'], false);

          const ageItems = getAgesForMultiSelect(activeSchool, forms, ages);
          const termItems =
            scheduleType === CLUB_SCHEDULE.INTERVALS
              ? getTermsForMultiSelect(schoolTerms, intervals)
              : getTermsForMultiSelect(schoolTerms);

          const dayItems = getDaysForMultiselect(days);
          const { terms } = schoolTerms;

          const genderItems = getGendersForMultiSelect(sport, gender);
          const now = new Date();

          const coaches = getClubCoachesForForm(club);
          const membersOfStaff = getClubMembersOfStaffForForm(club);

          const activeClubParticipants = clubParticipants.filter(
            participant => participant.permissionStatus === USER_STATUS.ACTIVE
          );

          this.setState({
            schoolTerms: terms,
            forms,
            ageItems,
            termItems,
            dayItems,
            name,
            description,
            sport,
            genderItems,
            duration,
            venue,
            time: getClubTime(club),
            maxParticipants,
            invitations,
            repeatEveryWeeks,
            location: typeof clubLocation !== 'undefined' ? clubLocation : '',
            start: scheduleType === CLUB_SCHEDULE.WEEKLY_SCHEDULE ? new Date(startDate) : now,
            end: scheduleType === CLUB_SCHEDULE.WEEKLY_SCHEDULE ? new Date(finishDate) : now,
            coaches,
            membersOfStaff,
            clubParticipants: activeClubParticipants,
            currentClubAges: ages,
            currentClubGender: gender,
            price,
            isPaymentRequired
          });
        } else {
          const ageItems = getAgesForMultiSelect(activeSchool, forms);
          const genderItems = getDefaultGendersForMultiSelect();
          const termItems = getTermsForMultiSelect(schoolTerms);
          const dayItems = getDaysForMultiselect();
          const { terms } = schoolTerms;

          this.setState({
            forms,
            ageItems,
            genderItems,
            termItems,
            dayItems,
            schoolTerms: terms,
            price: { price: 0, currency: 'POUND', priceType: 'TERM' }
          });
        }

        const paymentAccountId = propz.get(club, ['paymentSettings', 'paymentAccountId']);
        isPaymentAccountExist = typeof paymentAccountId !== 'undefined';
        let paymentAccountPromise;

        switch (true) {
          case isClubExist && isPaymentsEnabled:
            paymentAccountPromise = isPaymentAccountExist
              ? getPaymentAccount(user, paymentAccountId)
              : getAllPaymentsAccounts(user);
            break;
          case !isClubExist && isPaymentsEnabled:
            paymentAccountPromise = getAllPaymentsAccounts(user);
            break;
          default:
            paymentAccountPromise = BPromise.resolve();
            break;
        }

        return paymentAccountPromise;
      })
      .then(paymentAccountOrAccounts => {
        switch (true) {
          case isPaymentAccountExist:
            this.setState({
              paymentAccount: paymentAccountOrAccounts,
              isLoading: false
            });

            break;
          case isPaymentsEnabled:
            const paymentAccountDefault = paymentAccountOrAccounts.find(account => account.isDefault);

            this.setState({
              paymentAccount: paymentAccountDefault,
              isLoading: false
            });

            break;
          default:
            this.setState({
              isLoading: false
            });
            break;
        }
      });
  }

  getClubId(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    const clubId = search.club;
    return clubId;
  }

  getClubMode(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    return search.mode;
  }

  onInputChange = event => {
    const { errors } = this.state;
    event.preventDefault();
    const { target } = event;
    const { value, name } = target;

    const isTargetNameRepeatEveryWeeks = name === 'repeatEveryWeeks';
    const isTargetNameDuration = name === 'duration';
    const isTargetNameMaxParticipants = name === 'maxParticipants';

    const isConvertToNumberRequired =
      isTargetNameRepeatEveryWeeks || isTargetNameDuration || isTargetNameMaxParticipants;

    const valueConverted = isConvertToNumberRequired ? Number(value) : value;

    const isTargetNameEqualName = name == 'name';
    const isTargetNameEqualDuration = name == 'duration';
    const isResetErrorRequired = isTargetNameEqualName || isTargetNameEqualDuration;

    if (isResetErrorRequired) {
      this.setState({
        [name]: valueConverted,
        errors: {
          ...errors,
          [name]: false
        }
      } as State);
    } else {
      this.setState({ [name]: valueConverted } as State);
    }
  };

  getSteps(): Step[] {
    const { user } = this.props;
    const { activeSchool } = user;
    if (activeSchool.isPaymentsEnabled) {
      return [
        CLUB_WIZARD_STEP.SUMMARY,
        CLUB_WIZARD_STEP.SCHEDULE,
        CLUB_WIZARD_STEP.LOCATION,
        CLUB_WIZARD_STEP.BOOKING,
        CLUB_WIZARD_STEP.PAYMENT_SETTINGS
      ];
    } else {
      return [CLUB_WIZARD_STEP.SUMMARY, CLUB_WIZARD_STEP.SCHEDULE, CLUB_WIZARD_STEP.LOCATION, CLUB_WIZARD_STEP.BOOKING];
    }
  }

  renderProgressBar() {
    const { currentStep } = this.state;
    const steps = this.getSteps();
    const totalSteps = steps.length;
    const currentStepIndex = steps.findIndex(step => step === currentStep);
    const stepIndex = currentStepIndex + 1;
    const progress = (stepIndex / totalSteps) * 100;

    return <ProgressBar progress={progress} step={stepIndex} stepTotal={totalSteps} />;
  }

  getStepTitle(): string {
    const { currentStep } = this.state;
    return CLUB_WIZARD_STEP_MAPPING[currentStep];
  }

  onNextClick = () => {
    const {
      currentStep,
      name,
      sport,
      errors,
      genderItems,
      dayItems,
      duration,
      termItems,
      start,
      end,
      venue,
      currentClubGender,
      currentClubAges,
      ageItems
    } = this.state;
    const steps = this.getSteps();
    const currentStepIndex = steps.findIndex(step => step === currentStep);
    const totalSteps = steps.length;

    if (currentStep === CLUB_WIZARD_STEP.SUMMARY) {
      const isNameExist = name !== '';
      const isSportExist = typeof sport !== 'undefined';
      const isGenderExist = genderItems.some(genderItem => genderItem.isChecked);
      const dataToPost = this.getDataToPost();
      const isChangeAge = [...currentClubAges].sort().toString() !== [...dataToPost.ages].sort().toString();
      const isChangeGender = dataToPost.gender !== currentClubGender;
      const clubMode = this.getClubMode();
      const isCopyClubWithParticipantsMode = clubMode === MODE_TYPE.CLUB_COPY_WITH_PARTICIPANTS;
      const isAgesNotChanged = ageItems.every(item => item.isChecked === false);

      const isError = !isNameExist || !isSportExist || !isGenderExist;

      if (isError) {
        this.setState({
          errors: {
            ...errors,
            name: !isNameExist,
            sport: !isSportExist,
            gender: !isGenderExist
          }
        });
        return;
      }

      if (isAgesNotChanged) {
        const ageItemsUpdated = ageItems.map(item => {
          return {
            ...item,
            isChecked: true
          };
        });

        this.setState({
          ageItems: ageItemsUpdated
        });
      }

      if (isCopyClubWithParticipantsMode && (isChangeAge || isChangeGender)) {
        this.setState({
          isSaveOnlyPassingStudentsModalOpen: true
        });
        return;
      }
    }

    if (currentStep === CLUB_WIZARD_STEP.SCHEDULE) {
      const isIntervalExist = termItems.some(termItem => termItem.isChecked) || start !== end;
      const isDurationExist = !isNaN(duration) && duration !== 0;
      const isDaysExist = dayItems.some(dayItem => dayItem.isChecked);

      const isError = !isIntervalExist || !isDurationExist || !isDaysExist;

      if (isError) {
        this.setState({
          errors: {
            ...errors,
            interval: !isIntervalExist,
            days: !isDaysExist,
            duration: !isDurationExist
          }
        });
        return;
      }
    }

    if (currentStep === CLUB_WIZARD_STEP.LOCATION) {
      const isVenueExist = typeof venue !== 'undefined';
      const isError = !isVenueExist;

      if (isError) {
        this.setState({
          errors: {
            ...errors,
            venue: !isVenueExist
          }
        });
        return;
      }
    }

    if (currentStepIndex < totalSteps - 1) {
      this.setState({
        currentStep: steps[currentStepIndex + 1]
      });
    }
  };

  onPreviousClick = () => {
    const { currentStep } = this.state;
    const steps = this.getSteps();
    const currentStepIndex = steps.findIndex(step => step === currentStep);

    if (currentStepIndex > 0) {
      this.setState({
        currentStep: steps[currentStepIndex - 1]
      });
    }
  };

  onCancelClick = () => {
    const { name, sport, genderItems } = this.state;
    const { history } = this.props;
    const search = propz.get(history, ['location', 'state', 'search'], '');

    const isNameExist = name !== '';
    const isSportExist = typeof sport !== 'undefined';
    const isGenderExist = genderItems.some(genderItem => genderItem.isChecked);

    const isError = !isNameExist || !isSportExist || !isGenderExist;

    //not save only back
    if (isError) {
      const clubId = this.getClubId();
      history.push({
        pathname: '/clubs',
        search,
        state: { clubId: clubId }
      });
    } else {
      this.setState({
        isCancelModalOpen: true
      });
    }
  };

  renderCancelModal() {
    const { isCancelModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isCancelModalOpen}
        title={'Cancel'}
        body={`Would you like to save club as a draft?`}
        buttonText={'Save'}
        onButtonClick={this.onSaveClick}
        onCloseClick={this.onCancelModalClick}
        buttonCancelText={'← Back to clubs'}
        onCloseIconClick={this.onCancelModalCloseIconClick}
      />
    );
  }

  onCancelModalCloseIconClick = () => {
    this.setState({
      isCancelModalOpen: false
    });
  };

  onCancelModalClick = () => {
    const { history } = this.props;
    const search = propz.get(history, ['location', 'state', 'search'], '');
    const clubId = this.getClubId();

    history.push({
      pathname: '/clubs',
      search,
      state: { clubId: clubId }
    });
  };

  getSports = (text: string) => {
    const { user } = this.props;

    const isUserTeacherOrCoach = isUserTeacher(user) || isUserCoach(user);

    const userPermissionSports = propz.get(user, ['activePermission', 'sports'], []);
    const userPermissionSportsIds = userPermissionSports.map(sport => sport.id);
    const isUserPermissionSportsExist = userPermissionSports.length > 0;

    const where = {};

    if (isUserTeacherOrCoach && isUserPermissionSportsExist) {
      propz.set(where, ['id'], {
        $in: userPermissionSportsIds
      });
    }

    return searchFunctionClubSports(user, text, where);
  };

  getVenues = (text: string) => {
    const { user } = this.props;
    return searchFunctionVenues(user, text);
  };

  onSportSelect = (sport: Sport) => {
    const { errors } = this.state;

    const genderItems = getGendersForMultiSelect(sport);

    this.setState({
      sport,
      genderItems,
      errors: {
        ...errors,
        sport: false
      }
    });
  };

  onVenueSelect = (venue: any) => {
    const { errors } = this.state;
    this.setState({
      venue,
      errors: {
        ...errors,
        venue: false
      }
    });
  };

  onPaymentAccountChange = (paymentAccount: any) => {
    const { errors, isPaymentRequired } = this.state;
    const isPaymentAccountExist = typeof paymentAccount !== 'undefined';

    this.setState({
      paymentAccount,
      errors: {
        ...errors,
        paymentAccount: isPaymentRequired && !isPaymentAccountExist
      }
    });
  };

  onIsPaymentRequiredChange = (value: boolean) => {
    const { errors } = this.state;

    if (!value) {
      this.setState({
        isPaymentRequired: value,
        errors: {
          ...errors,
          price: false,
          paymentAccount: false
        }
      });
    } else {
      this.setState({
        isPaymentRequired: value
      });
    }
  };

  onPriceChange = (newPrice: string) => {
    const { errors, price, isPaymentRequired } = this.state;

    const newPriceNumber = Number(newPrice);
    const isNumber = Number.isFinite(newPriceNumber);
    const isErrorExist = isPaymentRequired && newPriceNumber === 0;

    this.setState({
      price: isNumber ? { ...price, price: newPriceNumber } : price,
      errors: {
        ...errors,
        price: isErrorExist
      }
    });
  };

  onGenderChange = (index: number) => {
    const { genderItems, errors } = this.state;
    const genderItemsUpdated = genderItems.map((genderItem, genderIndex) => {
      return genderIndex === index
        ? {
            ...genderItem,
            isChecked: !genderItem.isChecked
          }
        : genderItem;
    });

    const isGenderExist = genderItemsUpdated.some(genderItem => genderItem.isChecked);

    this.setState({
      genderItems: genderItemsUpdated,
      errors: {
        ...errors,
        gender: !isGenderExist
      }
    });
  };

  onAgesChange = (index: number) => {
    const { ageItems } = this.state;
    const ageItemsUpdated = ageItems.map((ageItem, ageIndex) => {
      return ageIndex === index
        ? {
            ...ageItem,
            isChecked: !ageItem.isChecked
          }
        : ageItem;
    });

    this.setState({
      ageItems: ageItemsUpdated
    });
  };

  onTermsChange = (index: number) => {
    const { termItems, errors } = this.state;
    const termItemsUpdated = termItems.map((term, termIndex) => {
      return termIndex === index
        ? {
            ...term,
            isChecked: !term.isChecked
          }
        : term;
    });

    this.setState({
      termItems: termItemsUpdated,
      errors: {
        ...errors,
        interval: false
      }
    });
  };

  onDaysChange = (index: number) => {
    const { dayItems, errors } = this.state;
    const dayItemsUpdated = dayItems.map((day, dayIndex) => {
      return dayIndex === index
        ? {
            ...day,
            isChecked: !day.isChecked
          }
        : day;
    });

    this.setState({
      dayItems: dayItemsUpdated,
      errors: {
        ...errors,
        days: false
      }
    });
  };

  onStartDateChange = (date: Date) => {
    const { errors, end } = this.state;
    const isStartBiggerThenEnd = Number(date) > Number(end);
    if (date) {
      this.setState({
        start: date,
        errors: {
          ...errors,
          interval: false,
          date: isStartBiggerThenEnd
        }
      });
    }
  };

  onEndDateChange = (date: Date) => {
    const { errors, start } = this.state;
    const isEndLessThenStart = Number(date) < Number(start);
    if (date) {
      this.setState({
        end: date,
        errors: {
          ...errors,
          interval: false,
          date: isEndLessThenStart
        }
      });
    }
  };

  createVenueFormOpen = (): void => {
    this.setState({
      isCreateVenueFormOpen: true
    });
  };

  setCoaches = (coaches: ClubStaff[]): void => {
    this.setState({
      coaches
    });
  };

  setMembersOfStaff = (membersOfStaff: ClubStaff[]): void => {
    this.setState({
      membersOfStaff
    });
  };

  getCoachesSearchFunction = (text: string) => {
    const { user } = this.props;
    const { coaches, sport } = this.state;
    const activeSchoolId = user.activeSchoolId;
    return searchFunctionCoaches(user, text, activeSchoolId, coaches, sport.id);
  };

  getMembersOfStaffSearchFunction = (text: string) => {
    const { user } = this.props;
    const { membersOfStaff, sport } = this.state;
    const activeSchoolId = user.activeSchoolId;
    return searchFunctionMembersOfStaff(user, text, activeSchoolId, membersOfStaff, sport.id);
  };

  renderViewByStep(): React.ReactNode {
    const { user } = this.props;
    const {
      currentStep,
      name,
      description,
      sport,
      ageItems,
      termItems,
      start,
      end,
      dayItems,
      repeatEveryWeeks,
      duration,
      maxParticipants,
      invitations,
      //autoenrollment,
      location,
      venue,
      time,
      errors,
      genderItems,
      coaches,
      membersOfStaff,
      price,
      paymentAccount,
      isPaymentRequired
    } = this.state;
    switch (currentStep) {
      case CLUB_WIZARD_STEP.SUMMARY:
        return (
          <ClubWizardSummaryStep
            onInputChange={this.onInputChange}
            name={name}
            description={description}
            sport={sport}
            onSportSelect={this.onSportSelect}
            getSports={this.getSports}
            ageItems={ageItems}
            genderItems={genderItems}
            onAgesChange={this.onAgesChange}
            onGenderChange={this.onGenderChange}
            errors={errors}
            coaches={coaches}
            membersOfStaff={membersOfStaff}
            setCoaches={this.setCoaches}
            setMembersOfStaff={this.setMembersOfStaff}
            getCoachesSearchFunction={this.getCoachesSearchFunction}
            getMembersOfStaffSearchFunction={this.getMembersOfStaffSearchFunction}
          />
        );

      case CLUB_WIZARD_STEP.SCHEDULE:
        return (
          <ClubWizardScheduleStep
            onStartDateChange={this.onStartDateChange}
            onEndDateChange={this.onEndDateChange}
            start={start}
            end={end}
            duration={duration}
            time={time}
            termItems={termItems}
            onTermsChange={this.onTermsChange}
            onDaysChange={this.onDaysChange}
            dayItems={dayItems}
            repeatEveryWeeks={repeatEveryWeeks}
            onInputChange={this.onInputChange}
            errors={errors}
            name={name}
            sport={sport}
            genderItems={genderItems}
            ageItems={ageItems}
          />
        );

      case CLUB_WIZARD_STEP.LOCATION:
        return (
          <ClubWizardVenueStep
            onVenueSelect={this.onVenueSelect}
            venue={venue}
            getVenues={this.getVenues}
            onInputChange={this.onInputChange}
            location={location}
            createVenueFormOpen={this.createVenueFormOpen}
            errors={errors}
            name={name}
            sport={sport}
            genderItems={genderItems}
            ageItems={ageItems}
          />
        );

      case CLUB_WIZARD_STEP.BOOKING:
        return (
          <ClubWizardBookingStep
            invitations={invitations}
            //autoenrollment={autoenrollment}
            onInputChange={this.onInputChange}
            maxParticipants={maxParticipants}
            name={name}
            sport={sport}
            genderItems={genderItems}
            ageItems={ageItems}
          />
        );

      case CLUB_WIZARD_STEP.PAYMENT_SETTINGS:
        return (
          <ClubPaymentSettingsStep
            user={user}
            price={price}
            paymentAccount={paymentAccount}
            isPaymentRequired={isPaymentRequired}
            errors={errors}
            onPriceChange={this.onPriceChange}
            onPaymentAccountChange={this.onPaymentAccountChange}
            onIsPaymentRequiredChange={this.onIsPaymentRequiredChange}
          />
        );
    }
  }

  getDataToPost() {
    //copy-paste from ClubForm.tsx
    const {
      start,
      end,
      name,
      location,
      description,
      sport,
      maxParticipants,
      termItems,
      invitations,
      venue,
      repeatEveryWeeks,
      schoolTerms,
      duration,
      time,
      dayItems,
      forms,
      ageItems,
      genderItems,
      coaches,
      membersOfStaff,
      price,
      isPaymentRequired,
      paymentAccount
    } = this.state;

    const { user } = this.props;
    const { activeSchool } = user;

    const coachesData = coaches.map(coach => {
      return {
        userId: coach.id,
        permissionId: getStaffPermissionId(coach),
        staffRole: CLUB_STAFF.COACH
      };
    });

    const membersOfStaffData = membersOfStaff.map(memberOfStaff => {
      return {
        userId: memberOfStaff.id,
        permissionId: getStaffPermissionId(memberOfStaff),
        staffRole: CLUB_STAFF.MEMBER_OF_STAFF
      };
    });

    const startDate = start;
    const finishDate = end;
    const startDay = startDate.getDate();
    const startMonth = startDate.getMonth();
    const finishDay = finishDate.getDate();
    const finishMonth = finishDate.getMonth();
    const startDateUTC = new Date(startDate);
    const finishDateUTC = new Date(finishDate);

    if (startDay !== 1) {
      startDateUTC.setUTCDate(startDay);
    } else {
      startDateUTC.setUTCDate(startDay);
      startDateUTC.setUTCMonth(startMonth);
    }

    if (finishDay !== 1) {
      finishDateUTC.setUTCDate(finishDay);
    } else {
      finishDateUTC.setUTCDate(finishDay);
      finishDateUTC.setUTCMonth(finishMonth);
    }

    const isSomeTermItemChecked = termItems.some(term => term.isChecked);
    const days = Object.keys(WEEK_DAYS).filter((day, index) => dayItems[index].isChecked);

    const { ageGroupsNaming, isPaymentsEnabled } = activeSchool;
    const ageGroup = propz.get(AGE_GROUPS_SORTED, [ageGroupsNaming]);

    const formsUniq = Lazy(forms)
      .uniq('age')
      .sort((form1, form2) => {
        const ageIndex1 = ageGroup.indexOf(form1.ageGroup);
        const ageIndex2 = ageGroup.indexOf(form2.ageGroup);

        return ageIndex1 - ageIndex2;
      })
      .toArray();

    const ages: number[] = formsUniq.filter((form, index) => ageItems[index].isChecked).map(form => form.age);

    let gender;

    const genderItemsCount = genderItems.length;
    const genderItemsChecked = genderItems.filter(genderItem => genderItem.isChecked);
    const genderItemsCheckedCount = genderItemsChecked.length;

    switch (true) {
      //Boys or Girls
      case genderItemsCount === 1:
      case genderItemsCount === 2 && genderItemsCheckedCount === 1:
        gender = SPORT_GENDER_CLIENT_TO_SERVER_MAPPING[genderItemsChecked[0].name];
        break;
      case genderItemsCount === 2 && genderItemsCheckedCount === 2:
        gender = GENDER.MIXED;
        break;
      default:
        gender = GENDER.MIXED; // fallback
        console.error(`Gender not defined`);
    }

    const schedule = isSomeTermItemChecked
      ? {
          scheduleType: CLUB_SCHEDULE.INTERVALS,
          repeatEveryWeeks: repeatEveryWeeks,
          intervals: schoolTerms
            .filter((schoolTerm, index) => termItems[index].isChecked)
            .map(schoolTerm => {
              const startDate = new Date(schoolTerm.start);
              const endDate = new Date(schoolTerm.end);
              return {
                start: startDate.toISOString(),
                end: endDate.toISOString()
              };
            }),
          days: days,
          duration: duration
        }
      : {
          scheduleType: CLUB_SCHEDULE.WEEKLY_SCHEDULE,
          repeatEveryWeeks: repeatEveryWeeks,
          startDate: startDateUTC,
          finishDate: finishDateUTC,
          days: days,
          duration: duration
        };

    const dataToPost = {
      name: name,
      location: location,
      description: description,
      sportId: sport && sport.id, //temp solution
      gender: gender,
      ages: ages,
      maxParticipants: maxParticipants,
      invitations: invitations,
      venue: {
        placeId: venue && venue.id //temp solution
      },
      schedule: schedule,
      staff: [...coachesData, ...membersOfStaffData]
    };

    if (isPaymentsEnabled) {
      const paymentSettingsToPost = {
        askToPay: isPaymentRequired,
        requiredForBook: isPaymentRequired,
        paymentAccountId: paymentAccount ? paymentAccount.id : undefined
      };

      propz.set(dataToPost, ['paymentSettings'], paymentSettingsToPost);
      propz.set(dataToPost, ['price'], price);
    }

    if (time !== '') {
      const timeParts = time.split(':');
      const timeFormatted = new Date(startDate);

      timeFormatted.setHours(Number(timeParts[0]), Number(timeParts[1]), 0, 0);
      propz.set(dataToPost, ['schedule', 'time'], timeFormatted.toISOString());
    }

    return dataToPost;
  }

  getParticipantsFiltered = dataToPost => {
    const { clubParticipants } = this.state;

    const participantsFiltered = clubParticipants.filter(participant => {
      const participantAge = propz.get(participant, ['form', 'age']);
      const isExistAge = dataToPost.ages.length === 0 || dataToPost.ages.some(age => age === participantAge);
      let isEqualGender;
      if (dataToPost.gender === GENDER.MIXED) {
        isEqualGender = true;
      } else {
        isEqualGender =
          (dataToPost.gender === GENDER.MALE_ONLY && participant.gender === USER_GENDER.MALE) ||
          (dataToPost.gender === GENDER.FEMALE_ONLY && participant.gender === USER_GENDER.FEMALE);
      }
      return isExistAge && isEqualGender;
    });
    return participantsFiltered;
  };

  onSaveClick = () => {
    const { user, history } = this.props;
    const { currentStep, errors } = this.state;

    let isErrorsExist;

    if (currentStep === CLUB_WIZARD_STEP.PAYMENT_SETTINGS || !isErrorsExist) {
      const { price: priceObj, isPaymentRequired, paymentAccount } = this.state;

      const price = propz.get(priceObj, ['price'], 0);

      // if user doesn't change price and payment account and errors have not been cought
      const isPriceErrorExist = isPaymentRequired && price === 0;
      const isPaymentAccountErrorExist = isPaymentRequired && typeof paymentAccount === 'undefined';
      isErrorsExist = isPriceErrorExist || isPaymentAccountErrorExist;

      this.setState({
        errors: {
          ...errors,
          price: isPriceErrorExist,
          paymentAccount: isPaymentAccountErrorExist
        }
      });
    }

    if (currentStep !== CLUB_WIZARD_STEP.PAYMENT_SETTINGS || !isErrorsExist) {
      const lastClubPage = propz.get(history, ['location', 'state', 'lastClubPage'], FIRST_PAGE);
      const search = propz.get(history, ['location', 'state', 'search'], '');

      const clubId = this.getClubId();
      const clubMode = this.getClubMode();
      const dataToPost = this.getDataToPost();
      const isEditClubMode = clubMode === MODE_TYPE.EDIT;
      const isCopyClubWithParticipantsMode = clubMode === MODE_TYPE.CLUB_COPY_WITH_PARTICIPANTS;

      if (typeof clubId !== 'undefined' && isEditClubMode) {
        updateClub(user, dataToPost, clubId)
          .then(club => {
            this.setState({
              isLoading: false
            });

            history.push({
              pathname: '/clubs',
              search,
              state: { clubId: club.id }
            });
          })
          .catch(err => {
            console.error(err);
            this.setState({
              isLoading: false
            });
          });
      } else {
        let clubIdCreated;
        createClub(user, dataToPost)
          .then(club => {
            clubIdCreated = club.id;

            if (isCopyClubWithParticipantsMode) {
              const participantsFiltered = this.getParticipantsFiltered(dataToPost);

              const data = {
                participants: participantsFiltered.map(student => {
                  return {
                    userId: student.userId,
                    permissionId: student.permissionId
                  };
                })
              };
              return addClubStudents(user, clubIdCreated, data);
            } else {
              return BPromise.resolve([]);
            }
          })
          .then(res => {
            this.setState({
              isLoading: false
            });

            history.push({
              pathname: '/clubs',
              search: [`page=${lastClubPage}`, DEFAULT_CLUB_TABLE_FILTERS].join('&'),
              state: { clubId: clubIdCreated }
            });
          })
          .catch(err => {
            console.error(err);
            this.setState({
              isLoading: false
            });
          });
      }
    }
  };

  onCancelCreateVenue = (): void => {
    this.setState({
      isCreateVenueFormOpen: false
    });
  };

  onSubmitVenueForm = (data): void => {
    const { user } = this.props;

    this.setState({
      isLoading: true
    });

    createVenue(user, data).then(venue => {
      this.setState({
        venue,
        isLoading: false,
        isCreateVenueFormOpen: false
      });
    });
  };

  onOkSaveOnlyPassingStudentsModalClick = (): void => {
    this.setState({
      isSaveOnlyPassingStudentsModalOpen: false,
      currentStep: CLUB_WIZARD_STEP.SCHEDULE
    });
  };

  onCancelSaveOnlyPassingStudentsModalClick = (): void => {
    this.setState({
      isSaveOnlyPassingStudentsModalOpen: false
    });
  };

  renderCreateVenueForm(): React.ReactNode {
    const { user } = this.props;
    return (
      <SimpleModal isOpen={this.state.isCreateVenueFormOpen} title={'New venue'}>
        <VenueForm user={user} onCancel={this.onCancelCreateVenue} onSubmit={this.onSubmitVenueForm} />
      </SimpleModal>
    );
  }

  renderSaveOnlyPassingStudentsModal(): React.ReactNode {
    const { isSaveOnlyPassingStudentsModalOpen } = this.state;
    return (
      <SimpleModal
        isOpen={isSaveOnlyPassingStudentsModalOpen}
        title={'Warning'}
        body={
          'You selected to include students in the club duplication. However, as you changed the age and/or gender criteria, only students matching the new criteria will be saved.'
        }
        buttonText={'Ok'}
        onButtonClick={this.onOkSaveOnlyPassingStudentsModalClick}
        buttonCancelText={'Cancel'}
        onCloseClick={this.onCancelSaveOnlyPassingStudentsModalClick}
      />
    );
  }

  render() {
    const {
      currentStep,
      isLoading,
      isCancelModalOpen,
      isCreateVenueFormOpen,
      isSaveOnlyPassingStudentsModalOpen
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const steps = this.getSteps();
    const currentStepIndex = steps.findIndex(step => step === currentStep);
    const totalSteps = steps.length;

    const isNext = currentStepIndex < totalSteps - 1;
    const isSave = currentStepIndex === totalSteps - 1;
    const isPrevious = currentStepIndex > 0;

    const classes =
      isCancelModalOpen || isCreateVenueFormOpen || isSaveOnlyPassingStudentsModalOpen ? 'mt-3 modal-open' : 'mt-3';
    let title;
    const clubMode = this.getClubMode();

    switch (clubMode) {
      case MODE_TYPE.CLUB_COPY:
      case MODE_TYPE.CLUB_COPY_WITH_PARTICIPANTS:
        title = 'Copy club';
        break;
      case MODE_TYPE.EDIT:
        title = 'Edit club';
        break;
      default:
        title = 'Create new club';
        break;
    }

    return (
      <div className={classes}>
        {this.renderCancelModal()}
        {this.renderCreateVenueForm()}
        {this.renderSaveOnlyPassingStudentsModal()}
        <h3 className="mb-3">
          {title}
          <span className="text-muted">{` / ${this.getStepTitle()}`}</span>
        </h3>
        {this.renderProgressBar()}
        {this.renderViewByStep()}
        <div className="mt-5 mb-3">
          <Button text={'Cancel'} customClass={'mt-3 mr-3 btn-secondary'} onClick={this.onCancelClick} />
          <Button
            text={'Previous step'}
            customClass={isPrevious ? 'mt-3 mr-3' : 'mt-3 mr-3 btn-secondary'}
            onClick={this.onPreviousClick}
          />
          <Button
            text={'Next step'}
            customClass={isNext ? 'mt-3 mr-3' : 'mt-3 mr-3 btn-secondary'}
            onClick={this.onNextClick}
          />
          <Button
            text={'Save'}
            customClass={isSave ? 'mt-3 mr-3' : 'mt-3 mr-3 btn-secondary'}
            disabled={!isSave}
            onClick={this.onSaveClick}
          />
        </div>
      </div>
    );
  }
}
