import * as React from 'react';
import { FunctionComponent } from 'react';
import {PencilEditIcon} from "../PencilEditIcon/PencilEditIcon";
import './StudentsNextOfKin.scss'
import {RemoveIcon} from "../RemoveIcon/RemoveIcon";


interface Props {
  student: any;
  onEditClick: (index: number) => void;
  onRemoveClick: (index: number) => void;
}

export const StudentNextOfKin: FunctionComponent<Props> = props => {

    const nextOfKin = props.student.nextOfKin;

    if (typeof nextOfKin === 'undefined' || nextOfKin.length === 0) {
        return <div>The user has no next of kin</div>;
    }

    const rows: React.ReactNodeArray = nextOfKin.map((person, index) => {

        return (
            <tr key={index}>
                <td>{person.relationship}</td>
                <td>{person.firstName}</td>
                <td>{person.lastName}</td>
                <td>{person.phones.join('\n')}</td>
                <td>{person.email}</td>
                <td>{person.priority}</td>
                <td>
                    <div className="bViewActions">
                        <div className="pr-1">
                            <PencilEditIcon
                                onClick={() => props.onEditClick(index)}
                            />
                        </div>
                        <RemoveIcon
                            onClick={() => props.onRemoveClick(index)}
                        />
                    </div>
                </td>
            </tr>
        );
    });

return (
      <div>
        <table className={'table table-sm mPre'}>
          <thead className="font-weight-bold">
          <tr>
            <td>Relationship</td>
            <td>Name</td>
            <td>Surname</td>
            <td>Phone</td>
            <td>Email</td>
            <td>Priority</td>
            <td></td>

          </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
  );
};

StudentNextOfKin.displayName = 'StudentNextOfKin';
