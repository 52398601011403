import * as React from 'react';
import { Field, Form, Formik } from 'formik';
import { Switch } from '../../../../../../components/Switch/Switch';
import { SchoolUserPermission } from '../../../../../../models/schoolUser';
import { ROLE_SERVER_TO_CLIENT_MAPPING } from '../../../../../../consts/user';

interface Props {
  userPermissions: SchoolUserPermission[];
  onCloseClick: () => void;
  onSubmit: (data: SchoolUserPermission[]) => void;
}

export function UserRevokeRoleForm(props: Props) {
  const { userPermissions } = props;
  const requestInitial = {
    permissions: userPermissions.map(permission => ({ ...permission, isRevoke: false }))
  };
  return (
    <Formik
      onSubmit={values => {
        const revokePermissions = values.permissions.filter(permission => permission.isRevoke);
        const revokePermissionsWithoutIsRevoke = revokePermissions.map(permission => {
          const { isRevoke, ...rest } = permission;
          return { ...rest };
        });
        props.onSubmit(revokePermissionsWithoutIsRevoke);
      }}
      initialValues={requestInitial}
      render={({ values, setFieldValue }) => (
        <Form>
          {values.permissions.map((permission, index) => {
            return (
              <div key={`revoke_role_${index}`} className="form-check">
                <div className="d-flex">
                  <Field
                    name={`isRevoke_${permission.preset}_${index}`}
                    render={({ field }) => (
                      <Switch
                        {...field}
                        value={permission.isRevoke}
                        customClass="mb-3"
                        onChange={event => {
                          const isRevoke = event.target.checked;
                          const permissions = [...values.permissions];
                          permissions[index].isRevoke = isRevoke;
                          setFieldValue('permissions', permissions);
                        }}
                      />
                    )}
                  />
                  <label htmlFor={`isRevoke_${permission.preset}_${index}`}>
                    {ROLE_SERVER_TO_CLIENT_MAPPING[permission.preset]}
                  </label>
                </div>
              </div>
            );
          })}
          <button type="button" className="btn btn-secondary mr-3" onClick={props.onCloseClick}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            REvoke
          </button>
        </Form>
      )}
    />
  );
}
