import { CLUB_PRICING_TYPE } from '../../types/club';
import { Club, ClubStaff } from '../../models/club';
import { CLUB_STAFF } from '../../consts/club';
import * as propz from 'propz';

export function getClubTime(club): string {
  const time = club.schedule.time;

  const timeDate = new Date(time);
  const hours = timeDate.getHours();
  const minutes = timeDate.getMinutes();

  const hoursStr = addZeroToFirst(hours);
  const minutesStr = addZeroToFirst(minutes);

  return `${hoursStr}:${minutesStr}`;
}

export function addZeroToFirst(num: number): string {
  return String(num).length === 1 ? `0${num}` : `${num}`;
}

export function getClubCoachesForForm(club: Club): ClubStaff[] {
  const staff = propz.get(club, ['staff'], []);

  return staff.filter(singleStaff => singleStaff.staffRole === CLUB_STAFF.COACH);
}

export function getClubMembersOfStaffForForm(club: Club): ClubStaff[] {
  const staff = propz.get(club, ['staff'], []);

  return staff.filter(singleStaff => singleStaff.staffRole === CLUB_STAFF.MEMBER_OF_STAFF);
}

export function getClubPriceType(club: Club): CLUB_PRICING_TYPE {
  const priceType = club.price.priceType;

  return priceType;
}

export function getClubInvitations(schoolSettingsClubInvitations: string, club: Club): string {
  const clubInvitations = propz.get(club, ['invitations'], schoolSettingsClubInvitations);
  return clubInvitations;
}

export function getClubStartDateCorrected(club: Club): Date {
  const startDate = new Date(club.schedule.startDate);
  const correctedDate = getCorrectedDate(startDate);

  return correctedDate;
}

export function getClubFinishDateCorrected(club: Club): Date {
  const finishDate = new Date(club.schedule.finishDate);
  const correctedDate = getCorrectedDate(finishDate);

  return correctedDate;
}

//fix some bug in club
function getCorrectedDate(date: Date): Date {
  const day = date.getUTCDate();
  const month = date.getUTCMonth();
  const dateUTC = new Date(date);

  if (day === 1) {
    dateUTC.setDate(day);
  } else {
    dateUTC.setDate(day);
    dateUTC.setMonth(month);
  }

  return dateUTC;
}

export function getStaffPermissionId(staff: any): string {
  return typeof staff.permissionId !== 'undefined' ? staff.permissionId : staff.permissions[0].id;
}
