import * as React from 'react';
import * as BPromise from 'bluebird';
import { AppUser } from 'Src/views/App/App';
import { parse } from 'query-string';
import { getOrder, getSearchFilter, getSearchOrder, getServerFieldSectionWhere } from 'Src/helpers/table/table';
import {
  ColumnDefinition,
  getFilters2,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { History, Location } from 'history';
import { Grid2 } from 'Src/components/Grid/Grid2';
import { Loader } from 'Src/components/Loader/Loader';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { getTournament } from 'Src/helpers/service/adminSU/tournamentsTable';
import { getCreatedAtWithTime, getTournamentLogAction } from 'Src/helpers/accessor/accessor';
import { getSelectOptionsForTournamentEnrolledLogsAction } from 'Src/helpers/table/select';
import { Tournament } from 'Src/models/tournament';
import {
  createCommentEnrollmentLog,
  getAllTournamentEnrollmentLogs,
  getTournamentEnrollmentLogs,
  getTournamentEnrollmentLogsCount
} from 'Src/helpers/service/adminSU/tournamentEnrollmentLogsTable';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { TournamentCommentEnrollmentLogForm } from 'Src/components/TournamentCommentEnrollmentLogsForm/TournamentCommentEnrollmentLogForm';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'School',
    field: 'schoolData.name',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['schoolData', 'name']
  },
  {
    text: 'Name',
    field: 'userData.firstName',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['userData', 'firstName']
  },
  {
    text: 'Surname',
    field: 'userData.lastName',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['userData', 'lastName']
  },
  {
    text: 'Affected school',
    field: 'affectedSchoolData.name',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['affectedSchoolData', 'name']
  },
  {
    text: 'Affected team',
    field: 'affectedTeamData.name',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['affectedTeamData', 'name']
  },
  {
    text: 'Invited school',
    field: 'inviteData.invitedSchoolName',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['inviteData', 'invitedSchoolName']
  },
  {
    text: 'Action',
    field: 'action',
    isSort: true,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getTournamentLogAction
  },
  {
    text: 'Created at',
    field: 'createdAt',
    isSort: true,
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: getCreatedAtWithTime
  },
  {
    text: 'Comment',
    field: 'comment',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: ['comment']
  }
];

interface State {
  items: any[];
  currentPage: number;
  selectedItems: any[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  filters: any;
  isDataFiltered: boolean;
  isLoading: boolean;
  total: number;
  basePath: string;
  isSelectAllChecked: boolean;
  tournament: Tournament;
  isAddCommentEnrollmentLogPopupOpened: boolean;
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export class TournamentEnrolledLogs extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      filters: {},
      isDataFiltered: false,
      isLoading: false,
      total: 0,
      basePath: '',
      isSelectAllChecked: false,
      tournament: undefined,
      isAddCommentEnrollmentLogPopupOpened: false
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setAdditionalItems().then(res => {
      this.setItems();
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  setAdditionalItems() {
    const { user } = this.props;

    const tournamentId = this.getTournamentId();

    return getTournament(user, tournamentId).then(tournament => {
      this.setState({
        tournament: tournament
      });

      return true;
    });
  }

  getTournamentId(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    const tournamentId = search.tournament;
    return tournamentId;
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const tournamentId = this.getTournamentId();

    const getItemsPromise = getTournamentEnrollmentLogs(user, tournamentId, serverQueryFilter);
    const getItemsCountPromise = getTournamentEnrollmentLogsCount(user, tournamentId, where);

    const promises = [getItemsCountPromise, getItemsPromise];

    return BPromise.all(promises).then(([countObj, items]) => {
      this.setState({
        currentPage: page,
        sortDirection: sortDirection,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: items,
        total: countObj.count,
        basePath: history.location.pathname,
        isLoading: false
      });

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;
    const { history } = this.props;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const tournamentId = this.getTournamentId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `tournament=${tournamentId}&${search.join('&')}`,
      state: history.location.state
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: nextSelectedItems,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    const tournamentId = this.getTournamentId();

    getAllTournamentEnrollmentLogs(user, tournamentId, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const nextSelectedItems = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: nextSelectedItems,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;
    const { history } = this.props;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const tournamentId = this.getTournamentId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `tournament=${tournamentId}&${search.join('&')}`,
      state: history.location.state
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    const { history } = this.props;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    const tournamentId = this.getTournamentId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `tournament=${tournamentId}&${search.join('&')}`,
      state: history.location.state
    });
  };

  onClearFilterClick = (): void => {
    const { history } = this.props;
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    const tournamentId = this.getTournamentId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `tournament=${tournamentId}`,
      state: history.location.state
    });
  };

  goBack = () => {
    const { history, location } = this.props;
    const { state } = location;
    const { search, prevPath } = state as any;
    const tournamentId = this.getTournamentId();

    history.push({
      pathname: prevPath,
      search: search,
      state: { tournamentId: tournamentId }
    });
  };

  onAddCommentEnrollmentLogClick = () => {
    this.setState({
      isAddCommentEnrollmentLogPopupOpened: true
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  onCancelCommentEnrollmentLogButtonClick = (): void => {
    this.setState({
      isAddCommentEnrollmentLogPopupOpened: false
    });
  };

  onSaveCommentEnrollmentLogButtonClick = (data): void => {
    const { user } = this.props;
    const { selectedItems, tournament } = this.state;
    const logsId = selectedItems[0].id;

    this.setState({
      isLoading: true
    });
    createCommentEnrollmentLog(user, tournament.id, logsId, data).then(enrollmentLog => {
      this.setState({
        isAddCommentEnrollmentLogPopupOpened: false,
        selectedItems: [enrollmentLog]
      });
      this.setItems();
    });
  };

  renderCommentEnrollmentLogModal() {
    const { isAddCommentEnrollmentLogPopupOpened, selectedItems } = this.state;

    return (
      <SimpleModal isOpen={isAddCommentEnrollmentLogPopupOpened} title={'Create comment'}>
        <TournamentCommentEnrollmentLogForm
          onCancel={this.onCancelCommentEnrollmentLogButtonClick}
          onSubmit={this.onSaveCommentEnrollmentLogButtonClick}
          enrollmentLog={selectedItems[0]}
        />
      </SimpleModal>
    );
  }

  renderGrid() {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isSelectAllChecked,
      currentPage,
      total,
      tournament
    } = this.state;

    const gridTitle = typeof tournament !== 'undefined' ? `${tournament.name} / Enrollment log` : '';

    const actionItems = [
      {
        itemText: 'Add comment',
        onItemClick: this.onAddCommentEnrollmentLogClick,
        isActive: selectedItems.length === 1
      }
    ];
    const filterOptions = {
      action: getSelectOptionsForTournamentEnrolledLogsAction()
    };

    return (
      <Grid2
        dataItems={items}
        filters={filters}
        currentPage={currentPage}
        total={total}
        isSelectAllChecked={isSelectAllChecked}
        isDataFiltered={isDataFiltered}
        sortDirection={sortDirection}
        sortColumnsName={sortColumnsName}
        isShowFilter={isShowFilter}
        dataItemsSelected={selectedItems}
        columns={COLUMNS}
        actionItems={actionItems}
        options={filterOptions}
        onItemClick={this.onItemClick}
        onSortClick={this.onTableSortClick}
        onApplyFilterClick={this.onApplyFilterClick}
        onClearFilterClick={this.onClearFilterClick}
        onTableFilterChange={this.onTableFilterChange}
        onTableFilterClick={this.onTableFilterClick}
        setCurrentPageParams={this.setCurrentPageParams}
        onSelectAllClick={this.onSelectAllClick}
        onSelectAllOnPageClick={this.onSelectAllOnPageClick}
        onUnselectAllClick={this.onUnselectAllClick}
        gridTitle={gridTitle}
        goBack={this.goBack}
      />
    );
  }

  render() {
    const { isLoading, isAddCommentEnrollmentLogPopupOpened } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes = isAddCommentEnrollmentLogPopupOpened ? 'mt-3 modal-open' : 'mt-3';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderCommentEnrollmentLogModal()}
        <div className="row">
          <div className="col-md-12">{this.renderGrid()}</div>
        </div>
      </div>
    );
  }
}
