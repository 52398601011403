import { AppUser } from '../../../views/App/App';
import axios from 'axios';

export function acceptInvite(user: AppUser, inviteId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/invites/${inviteId}/accept`, {}, config)
    .then(response => {
      return response.data;
    });
}

export function acceptAutoEnrollmentInvite(user: AppUser, inviteId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/invites/${inviteId}/autoenrollment`, {}, config)
    .then(response => {
      return response.data;
    });
}

export function getInvites(user: AppUser, filter?) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key },
    params: {
      filter
    }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/invites`, config).then(response => {
    return response.data;
  });
}

export function getInvite(user: AppUser, inviteId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/invites/${inviteId}`, config).then(response => {
    return response.data;
  });
}
