import * as React from 'react';
import { Photo } from 'Src/models/photo';
import './SchoolGalleryPhoto.scss';

interface Props {
  photo: Photo;
  onPhotoClick: (id: string) => void;
}

export const SchoolGalleryPhoto = (props: Props) => {
  const { photo, onPhotoClick } = props;
  const { picUrl, id, name } = photo;

  const isPhotoNameExist = typeof photo.name !== 'undefined' && photo.name !== '';

  return (
    <div
      className="bSchoolGalleryPhoto"
      style={{
        backgroundImage: `url(${picUrl})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        width: '48%',
        height: '160px'
      }}
      onClick={() => onPhotoClick(id)}
    >
      {isPhotoNameExist && <div className="eSchoolGalleryPhotoName">{name}</div>}
    </div>
  );
};
