import * as React from 'react';
import * as propz from 'propz';
import { Field, Form, Formik } from 'formik';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from '../../../../../../consts/report';
import { Tournament } from '../../../../../../models/tournament';
import { Switch } from '../../../../../../components/Switch/Switch';
import { LabelWithQuestionIcon } from '../../../../../../components/LabelWithQuestionIcon/LabelWithQuestionIcon';

interface Props {
  onCloseClick: () => void;
  onSubmit: (data: any, reportType: string) => void;
  tournament: Tournament;
}

// report 042
export function TournamentScoreCardsReportForm(props: Props) {
  const { tournament } = props;

  const requestInitial = {
    isShowSchedule: true
  };

  return (
    <Formik
      onSubmit={values => {
        props.onSubmit(values, REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.TOURNAMENT_SCORE_CARDS);
      }}
      initialValues={requestInitial}
      render={({ values, setFieldValue }) => (
        <Form>
          <div className="bReportFormTitle">Tournament score cards</div>
          <div className="mb-3 mt-3">
            {'Print out the score cards for all of the events created within the selected tournnment. ' +
              ' We have already adapted them to fit the page when fitted, all you need is just generating ' +
              ' the cards and print them out. You can also choose the game schedule for the tournaments that will .' +
              ' be displayed on a separate sheet.'}
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isShowSchedule"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowSchedule}
                    onChange={event => {
                      const isShowSchedule = event.target.checked;
                      setFieldValue('isShowSchedule', isShowSchedule);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Print a game schedule"
                hintText={'Print a full game schedule on a separate sheet.'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowSchedule"
              />
            </div>
          </div>

          <button type="button" className="btn btn-secondary mr-3" onClick={props.onCloseClick}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Get
          </button>
        </Form>
      )}
    />
  );
}
