import * as React from 'react';
import { Component } from 'react';
import * as classNames from 'classnames';
import { DropdownMenuItem } from './DropdownMenuItem';
import './ActionsButton.scss';

interface Props {
  actionItems: ActionItem[];
}

export interface ActionItem {
  itemText: string;
  isActive: boolean;
  onItemClick?: () => void;
  subItems?: any[];
}

interface State {
  isOpen: boolean;
}

export class ActionsButton extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  openMenu = () => {
    this.setState({
      isOpen: true
    });
  };

  closeMenu = () => {
    this.setState({
      isOpen: false
    });
  };

  onItemClick = (actionItem: ActionItem) => {
    const isActionItemActive = actionItem.isActive;

    if (isActionItemActive) {
      this.setState(
        {
          isOpen: false
        },
        () => {
          document.removeEventListener('click', this.closeMenu);
          actionItem.onItemClick();
        }
      );
    }
  };

  renderItems() {
    const actionItems = this.props.actionItems;

    return actionItems.map((actionItem, index) => {
      return <DropdownMenuItem key={`actionItem_${index}`} item={actionItem} onItemClick={this.onItemClick} />;
    });
  }

  render() {
    const isOpen = this.state.isOpen;

    const dropdownClasses = classNames({
      'btn-group': true,
      bActionsButton: true,
      show: isOpen
    });

    const dropdownMenuClasses = classNames({
      'dropdown-menu': true,
      bDropdownMenu: true,
      show: isOpen
    });

    const actionItems = this.props.actionItems;
    const isActionButtonDisabled = actionItems.length === 0;

    return (
      <div className={dropdownClasses}>
        <button
          type="button"
          className="btn btn-primary dropdown-toggle"
          onClick={this.openMenu}
          disabled={isActionButtonDisabled}
        >
          Actions
        </button>

        {isOpen && (
          <>
            <div className="bDropdownMenuOverlay" onClick={this.closeMenu}></div>
            <div className={dropdownMenuClasses}>{this.renderItems()}</div>
          </>
        )}
      </div>
    );
  }
}
