import * as React from 'react';
import * as propz from 'propz';
import { FunctionComponent } from 'react';
import { SPORT_GENDER_SERVER_TO_CLIENT_MAPPING } from '../../consts/common';
import {
  getEventAges,
  getEventEndTime,
  getEventGeneratedName,
  getEventStartTime,
  getEventType,
  getEventVenue
} from '../../helpers/accessor/accessor';
import { AppUser } from 'Src/views/App/App';
import {isClubEvent} from "../../helpers/event/event";
import {getSchoolEventLocation} from "../../helpers/event/schoolEventViewHelper";

interface Props {
  schoolEvent: any;
  user: AppUser;
}

const EVENT_ROWS = [
  { title: 'Name', field: 'name' },
  { title: 'Sport', field: 'sportName' },
  { title: 'Start time', field: 'startTime' },
  { title: 'Finish/Collection time', field: 'endTime' },
  { title: 'Game Type', field: 'eventType' },
  { title: 'Age', field: 'ages' },
  { title: 'Gender', field: 'gender' },
  { title: 'Venue', field: 'venue' }
];

const CLUB_EVENT_ROWS = [
  { title: 'Name', field: 'name' },
  { title: 'Activity', field: 'sportName' },
  { title: 'Start time', field: 'startTime' },
  { title: 'Finish/Collection time', field: 'endTime' },
  { title: 'Age', field: 'ages' },
  { title: 'Gender', field: 'gender' },
  { title: 'Location', field: 'location' },
];

export const SchoolEventSummary: FunctionComponent<Props> = props => {
  const {schoolEvent} = props;
  const ROWS = isClubEvent(schoolEvent) ? [...CLUB_EVENT_ROWS] : [...EVENT_ROWS];

  const rows = ROWS.map((row, index) => {
    let contentCell: string;
    const field = row.field;
    const { schoolEvent, user } = props;
    const location = propz.get(schoolEvent, ['location'], '');
    switch (field) {
      case 'name':
        contentCell = getEventGeneratedName(schoolEvent, { user: user });
        break;
      case 'sportName':
        contentCell = propz.get(schoolEvent, ['sport', 'name'], '');
        break;
      case 'startTime':
        contentCell = getEventStartTime(schoolEvent);
        break;
      case 'endTime':
        contentCell = getEventEndTime(schoolEvent);
        break;
      case 'eventType':
        contentCell = getEventType(schoolEvent);
        break;
      case 'ages':
        contentCell = getEventAges(schoolEvent);
        break;
      case 'gender':
        contentCell = SPORT_GENDER_SERVER_TO_CLIENT_MAPPING[schoolEvent.gender];
        break;
      case 'venue':
        contentCell = getEventVenue(schoolEvent);
        break;
      case 'location':
        contentCell = location !== '' ? `${getSchoolEventLocation(schoolEvent, user)},  ${location}` : getSchoolEventLocation(schoolEvent, user);
        break;
      default:
        contentCell = schoolEvent[row.field];
    }

    return (
      <tr key={`event_summary_${index}`}>
        <td>{row.title}</td>
        <td>{contentCell}</td>
      </tr>
    );
  });

  return (
    <table className={'table table-sm mPre'}>
      <tbody>{rows}</tbody>
    </table>
  );
};

SchoolEventSummary.displayName = 'SchoolEventSummary';
