import * as React from 'react';
import { FunctionComponent } from 'react';
import * as Moment from 'moment';
import { News } from '../../models/news';

interface Props {
  news: News;
  onEditClick: (event: any) => void;
}

export const NewsFooter: FunctionComponent<Props> = props => {
  const { title, body, date, picUrl } = props.news;
  const isImageExists = typeof picUrl !== 'undefined' && picUrl.length > 0;
  const dateFormatted = Moment(date).format('DD-MM-YYYY HH:mm');

  return (
    <div className={'container-fluid'}>
      <div className="mb-3">
        <button className={'btn btn-primary'} onClick={props.onEditClick}>
          Edit news
        </button>
      </div>

      <div className="row">
        {isImageExists && (
          <div className="col-md-3">
            <img className="img-fluid" src={picUrl} />
          </div>
        )}
        <div className="col-md-9">
          <h2>{title}</h2>
          <i>{dateFormatted}</i>
             <div dangerouslySetInnerHTML={{ __html: body }} />
        </div>
      </div>
    </div>
  );
};

NewsFooter.displayName = 'NewsFooter';
