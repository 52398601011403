import * as React from 'react';
import { Component } from 'react';
import { AppUser } from 'Src/views/App/App';
import { getGeneralMessageActionDescriptor } from 'Src/helpers/service/admin/generalMessage';
import * as Moment from 'moment';
import { DATE_TIME_FORMAT } from 'Src/consts/date';
import { Button } from '../../Button/Button';

interface Props {
  user: AppUser;
  messageId: string;
  onSendNowClick: () => void;
}

interface State {
  isLoading: boolean;
  scheduledDate: string;
}

export class ScheduleInfo extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: true,
      scheduledDate: ''
    };
  }

  componentDidMount() {
    this.loadActionDescriptor();
  }

  loadActionDescriptor() {
    getGeneralMessageActionDescriptor(this.props.user, this.props.messageId).then(descriptor => {
      this.setState({
        isLoading: false,
        scheduledDate: descriptor.scheduledDate
      });
    });
  }

  render() {
    if (this.state.isLoading) {
      return <div>Loading...</div>;
    }

    const { scheduledDate } = this.state;
    const formattedDate = Moment(scheduledDate).format(DATE_TIME_FORMAT);

    return (
      <div className="d-flex align-items-center mt-3">
        {`Scheduled to be sent on ${formattedDate}`}
        <Button onClick={this.props.onSendNowClick} text="Send now" customClass="ml-3" />
      </div>
    );
  }
}
