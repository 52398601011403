export const USER_NOTIFICATION_CHANNEL_TYPES = {
  IOS: 'IOS',
  ANDROID: 'ANDROID',
  DEFAULT_EMAIL: 'DEFAULT_EMAIL',
  DEFAULT_SMS: 'DEFAULT_SMS'
};

export const USER_NOTIFICATION_CHANNEL_STATUSES = {
  ACTIVE: 'ACTIVE',
  REMOVED: 'REMOVED',
  OVERRIDEN: 'OVERRIDEN'
};

export const NOTIFICATION_TYPE = {
  PERMISSION_REQUEST_ACCEPTED: 'PERMISSION_REQUEST_ACCEPTED',
  PERMISSION_REQUEST_REJECTED: 'PERMISSION_REQUEST_REJECTED',
  PERMISSION_CREATED: 'PERMISSION_CREATED',
  PERMISSION_REQUEST_CREATED: 'PERMISSION_REQUEST_CREATED',
  MERGE_STUDENT: 'MERGE_STUDENT',
  MERGE_PARENT: 'MERGE_PARENT',
  EVENT_CREATED: 'EVENT_CREATED',
  EVENT_UPDATED: 'EVENT_UPDATED',
  EVENT_CANCELED: 'EVENT_CANCELED',
  EVENT_DETAILS_UPDATED: 'EVENT_DETAILS_UPDATED',
  EVENT_DETAILS_STAFF_ADDED: 'EVENT_DETAILS_STAFF_ADDED',
  EVENT_TEAM_PLAYER_ADDED: 'EVENT_TEAM_PLAYER_ADDED',
  EVENT_TEAM_PLAYER_UPDATED: 'EVENT_TEAM_PLAYER_UPDATED',
  EVENT_TEAM_PLAYER_REMOVED: 'EVENT_TEAM_PLAYER_REMOVED',
  EVENT_INDIVIDUAL_PLAYER_ADDED: 'EVENT_INDIVIDUAL_PLAYER_ADDED',
  EVENT_INDIVIDUAL_PLAYER_REMOVED: 'EVENT_INDIVIDUAL_PLAYER_REMOVED',
  EVENT_TEAM_CHANGED: 'EVENT_TEAM_CHANGED',
  MESSAGE_INBOX_CONSENT_REQUEST: 'MESSAGE_INBOX_CONSENT_REQUEST',
  EVENT_ELIGIBLE_STUDENTS: 'EVENT_ELIGIBLE_STUDENTS',
  EVENT_CUSTOM: 'EVENT_CUSTOM',
  CLUB_CREATED: 'CLUB_CREATED',
  CLUB_PARTICIPATION_INVITE: 'CLUB_PARTICIPATION_INVITE',
  CLUB_ACTIVATED: 'CLUB_ACTIVATED',
  CLUB_ACTIVATED_WAITING_LIST_NOTIFICATION: 'CLUB_ACTIVATED_WAITING_LIST_NOTIFICATION',
  INVITE_INCOMING: 'INVITE_INCOMING',
  INVITE_ACCEPTED_BY_OPPONENT: 'INVITE_ACCEPTED_BY_OPPONENT',
  INVITE_REJECTED_BY_OPPONENT: 'INVITE_REJECTED_BY_OPPONENT',
  NOTIFICATION_CHANNEL_TEST: 'NOTIFICATION_CHANNEL_TEST',
  ABSENCE_REQUEST_SENT: 'ABSENCE_REQUEST_SENT',
  ABSENCE_REQUEST_UPDATED: 'ABSENCE_REQUEST_UPDATED',
  SCHOOL_TOURNAMENT_INVITE_CREATED: 'SCHOOL_TOURNAMENT_INVITE_CREATED',
  SCHOOL_ADDED_TO_TOURNAMENT_PARTICIPANT: 'SCHOOL_ADDED_TO_TOURNAMENT_PARTICIPANT',
  SCHOOL_ADDED_TO_TOURNAMENT_WAITING_LIST: 'SCHOOL_ADDED_TO_TOURNAMENT_WAITING_LIST',
  SCHOOL_REMOVED_FROM_TOURNAMENT: 'SCHOOL_REMOVED_FROM_TOURNAMENT',
  TOURNAMENT_CUSTOM: 'TOURNAMENT_CUSTOM',
  TOURNAMENT_DETAILS_UPDATED: 'TOURNAMENT_DETAILS_UPDATED',
  CONFIRMATION_REQUEST_CREATED: 'CONFIRMATION_REQUEST_CREATED',
  TOURNAMENT_PARTICIPANT_ADDED: 'TOURNAMENT_PARTICIPANT_ADDED',
  PARTICIPANT_ADDED_TO_TOURNAMENT_WAITING_LIST: 'PARTICIPANT_ADDED_TO_TOURNAMENT_WAITING_LIST',
  TOURNAMENT_PARTICIPANT_REMOVED: 'TOURNAMENT_PARTICIPANT_REMOVED',
  GENERAL_MESSAGE: 'GENERAL_MESSAGE',
  CUSTOM: 'CUSTOM',
  ADMIN_PERMISSION_REQUEST_ACCEPTED: 'ADMIN_PERMISSION_REQUEST_ACCEPTED',
  ADMIN_NEW_USER_REGISTERED: 'ADMIN_NEW_USER_REGISTERED',
  REPORT_RECEIVED: 'REPORT_RECEIVED',
  REPORT_UPDATES: 'REPORT_UPDATES',
  NEW_RECORD_ADDED: 'NEW_RECORD_ADDED'
};

export const NOTIFICATION_STATUS_TYPE = {
  AUTO: 'AUTO',
  DISABLED: 'DISABLED'
};
