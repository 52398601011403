import * as React from 'react';
import { useState } from 'react';
import { Route, Switch } from 'react-router';
import * as propz from 'propz';
import { PROFILE_TABS_URLS } from '../../../consts/profile';
import { UserName } from './UserName/UserName';
import { ProfileBar } from './ProfileBar/ProfileBar';
import { ProfileSummary } from './ProfileSummary/ProfileSummary';
import { VerificationTab } from './VerificationTab/VerificationTab';
import { ChangePasswordTab } from './ChangePasswordTab/ChangePasswordTab';
import { RolesTab } from './RolesTab/RolesTab';
import { RequestsTab } from './RequestsTab/RequestsTab';
import { NotificaionsTab } from './NotificaionsTab/NotificaionsTab';
import { SubscriptionsTab } from './SubscriptionsTab/SubscriptionsTab';
import { TopNavigationReduced } from 'Src/components/TopNavigation/TopNavigationReduced';

export const ProfileRouting = allProps => {
  const { user, updateProfile, setAppDefaultState, updateIsRolesExist, onLogoutClick } = allProps;
  const [isEditMode, setIsEditMode] = useState(false);
  const isRolesNotExist = propz.get(user, ['isRolesNotExist'], true);

  return (
    <>
      <Switch>
        <Route
          path="/profile"
          component={props => (
            <>
              {isRolesNotExist && <TopNavigationReduced onLogoutClick={onLogoutClick} {...props} />}
              <UserName user={user} />
              <ProfileBar user={user} isEditMode={isEditMode} />
            </>
          )}
        />
      </Switch>
      <Switch>
        <Route
          exact
          path={PROFILE_TABS_URLS.SUMMARY}
          render={props => (
            <ProfileSummary
              {...props}
              user={user}
              updateProfile={allProps.updateProfile}
              setIsEditMode={setIsEditMode}
              isEditMode={isEditMode}
            />
          )}
        />
        <Route
          exact
          path={PROFILE_TABS_URLS.ROLES}
          render={props => <RolesTab {...props} user={allProps.user} updateIsRolesExist={updateIsRolesExist} />}
        />
        <Route exact path={PROFILE_TABS_URLS.REQUESTS} render={props => <RequestsTab {...props} user={user} />} />
        <Route
          exact
          path={PROFILE_TABS_URLS.CHANGE_PASSWORD}
          render={props => <ChangePasswordTab {...props} user={user} />}
        />
        <Route exact path={PROFILE_TABS_URLS.NOTIFICATIONS} render={props => <NotificaionsTab user={user} />} />
        <Route
          exact
          path={PROFILE_TABS_URLS.SUBSCRIPTIONS}
          render={props => <SubscriptionsTab {...props} user={user} setAppDefaultState={setAppDefaultState} />}
        />
        <Route
          exact
          path={PROFILE_TABS_URLS.VERIFICATION}
          render={props => <VerificationTab {...props} user={user} updateProfile={updateProfile} />}
        />
      </Switch>
    </>
  );
};

ProfileRouting.displayName = 'ProfileRouting';
