import * as React from 'react';
import { FunctionComponent } from 'react';
import * as classNames from 'classnames';

interface Props {
  modalTitle: string;
  userTagName: string;
  onUserTagNameChange: (event) => void;
  onCloseClick: () => void;
  onSaveClick: () => void;
  isOpen: boolean;
}

export const UserTagModal: FunctionComponent<Props> = props => {
  if (!props.isOpen) {
    return null;
  }

  const modalClasses = classNames({
    mDisplayBlock: props.isOpen,
    show: props.isOpen,
    modal: true,
    fade: true
  });

  return (
    <div className={modalClasses}>
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{props.modalTitle}</h5>
            <button type="button" className="close" onClick={props.onCloseClick}>
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label>Title</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter user tag"
                onChange={props.onUserTagNameChange}
                value={props.userTagName}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={props.onCloseClick}>
              Close
            </button>
            <button type="button" className="btn btn-primary" onClick={props.onSaveClick}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

UserTagModal.displayName = 'UserTagModal';
