import { AppUser } from 'Src/views/App/App';
import axios from 'axios';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from 'Src/consts/table';
import { Count } from '../../../models/count';

const DEFAULT_GOVERNORS_FILTER: any = {
  'permissions.preset': { $in: ['GOVERNOR'] }
};

export function getGovernors(user: AppUser, filter = DEFAULT_GOVERNORS_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...DEFAULT_GOVERNORS_FILTER,
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order
      }
    }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/users`, config).then(response => {
    return response.data;
  });
}

/** GOVERNORS TABLE */
export function getAllGovernors(user: AppUser, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_GOVERNORS_FILTER,
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_GOVERNORS_FILTER
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/users`, config).then(response => {
    return response.data;
  });
}

export function getGovernorsCount(user: AppUser, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_GOVERNORS_FILTER,
            ...where
          }
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_GOVERNORS_FILTER
          }
        }
      }
    };
  }

  return axios.get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/users/count`, config).then(response => {
    return response.data;
  });
}
