import * as React from 'react';
import * as propz from 'propz';
import { EventInvitationMessage } from 'Src/models/message';
import { SchoolEvent } from 'Src/models/event';
import { QUESTIONS_TEMPLATE_TYPE } from 'Src/consts/message';
import { convertBooleanToYesNo } from 'Src/helpers/accessor/accessor';

interface Props {
  message: {
    consentRequest: EventInvitationMessage;
    childEvent: SchoolEvent;
  };
}

export const ConsentRequestDetails = (props: Props) => {
  const { message } = props;
  const { consentRequest } = message;

  const fields = propz.get(consentRequest, ['fields'], []);

  const renderFields = () => {
    const fieldsToRender = fields.map(field => {
      const { heading, value, type } = field;
      const booleanValueFormatted = convertBooleanToYesNo(value);
      const isBooleanQuestion = type === QUESTIONS_TEMPLATE_TYPE.BOOLEAN;

      return (
        <li className="mb-3">
          <p className="font-weight-bold mb-0 text-info">{heading}</p>
          <span>{isBooleanQuestion ? booleanValueFormatted : value}</span>

          <hr />
        </li>
      );
    });

    return (
      <div>
        <ul className="pl-3">{fieldsToRender}</ul>
      </div>
    );
  };

  return (
    <div>
      <div className="mb-3">
        <p className="font-weight-bold">Your responses:</p>

        {renderFields()}
      </div>
    </div>
  );
};

ConsentRequestDetails.displayName = 'ConsentRequestDetails';
