import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as classNames from 'classnames';
import './Pagination.scss';
import { Component } from 'react';
import { LIMIT } from '../../consts/table';
import { getFrom, getPagesCount, getTo } from '../../helpers/table/table';

interface Props {
  currentPage: number;
  setCurrentPageParams: (currentPage: number) => void;
  total: number;
  limit?: number;
}

interface State {
  pages: number;
  from: number;
  to: number;
}

export class Pagination2 extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      pages: 0,
      from: 0,
      to: 0
    };
  }

  componentDidMount() {
    const { total, currentPage, limit } = this.props;
    const pages = getPagesCount(total, limit || LIMIT);
    const from = getFrom(total, currentPage, limit);
    const to = getTo(total, currentPage, limit);

    this.setState({
      pages: pages,
      from: from,
      to: to
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { total, currentPage, limit } = nextProps;
    if (total !== this.props.total || currentPage !== this.props.currentPage) {
      const pages = getPagesCount(total, limit || LIMIT);
      const from = getFrom(total, currentPage, limit);
      const to = getTo(total, currentPage, limit);

      this.setState({
        pages: pages,
        from: from,
        to: to
      });
    }
  }
  onNextPageClick = (event): void => {
    event.preventDefault();

    const currentPage = this.props.currentPage + 1;

    if (currentPage < this.state.pages + 1) {
      this.props.setCurrentPageParams(currentPage);
    }
  };

  onPreviousPageClick = (event): void => {
    event.preventDefault();

    const currentPage = this.props.currentPage - 1;

    if (currentPage > 0) {
      this.props.setCurrentPageParams(currentPage);
    }
  };

  onFirstPageClick = (event): void => {
    event.preventDefault();

    const currentPage = 1;

    this.props.setCurrentPageParams(currentPage);
  };

  onLastPageClick = (event): void => {
    event.preventDefault();

    const currentPage = this.state.pages;

    this.props.setCurrentPageParams(currentPage);
  };

  render() {
    const { currentPage, total } = this.props;

    const { pages, from, to } = this.state;

    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage >= pages; //pages = 0, currentPage = 1, (case for filter)

    const leftNavigationStyles = classNames({
      'page-item': true,
      disabled: isFirstPage
    });
    const rightNavigationStyles = classNames({
      'page-item': true,
      disabled: isLastPage
    });

    return (
      <nav className={'bPagination'}>
        <ul className="pagination pagination-sm justify-content-sm-end">
          <li className={leftNavigationStyles}>
            <a className="page-link mPageLinkColor" href="" onClick={this.onFirstPageClick}>
              <FontAwesomeIcon icon="angle-double-left" />
            </a>
          </li>
          <li className={leftNavigationStyles}>
            <a className="page-link mPageLinkColor" href="" onClick={this.onPreviousPageClick}>
              <FontAwesomeIcon icon="angle-left" />
            </a>
          </li>
          <li className={'page-item disabled'}>
            <a className="page-link mPageLinkColor" href="">
              {`${from} to ${to} of ${total}`}
            </a>
          </li>
          <li className={rightNavigationStyles}>
            <a className="page-link mPageLinkColor" href="" onClick={this.onNextPageClick}>
              <FontAwesomeIcon icon="angle-right" />
            </a>
          </li>
          <li className={rightNavigationStyles}>
            <a className="page-link mPageLinkColor" href="" onClick={this.onLastPageClick}>
              <FontAwesomeIcon icon="angle-double-right" />
            </a>
          </li>
        </ul>
      </nav>
    );
  }
}
