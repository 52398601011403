export const LEAGUE_TABS = ['Summary', 'Events', 'News'];

export const LEAGUE_AGGREGATION_TYPE = {
  TEAM: 'TEAM',
  HOUSE: 'HOUSE',
  INDIVIDUAL: 'INDIVIDUAL'
};

export const LEAGUE_AGGREGATION_TYPE_SERVER_TO_CLIENT_MAPPING = {
  TEAM: 'Team',
  HOUSE: 'House',
  INDIVIDUAL: 'Individual'
};
