import * as React from 'react';
import * as propz from 'propz';
import { Field, Form, Formik } from 'formik';
import DatePicker from 'react-datepicker';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from '../../../../../../../consts/report';
import { Switch } from '../../../../../../../components/Switch/Switch';
import { getStartDateForSchoolYear } from '../../../../../../../helpers/calendar/calendar';
import { SchoolStudent } from 'Src/models/schoolStudent';
import { LabelWithQuestionIcon } from '../../../../../../../components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import '../../Students.css';

interface Props {
  onCloseClick: () => void;
  onSubmit: (data: any, reportType: string) => void;
  // student: SchoolStudent;
}

// report 056
export function SelectedStudentTargetCompletionReportForm(props: Props) {
  const now = new Date();
  let dateFrom = getStartDateForSchoolYear(now);
  //const { student } = props;

  const requestInitial = {
    dateFrom,
    dateTo: now,
    isShowCompleted: true,
    isShowUncompleted: true
  };

  return (
    <Formik
      onSubmit={values => {
        props.onSubmit(values, REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.SELECTED_STUDENT_TARGET_COMPLETION);
      }}
      initialValues={requestInitial}
      render={({ values, setFieldValue }) => (
        <Form>
          <div className="bReportFormTitle">Target completion for the period</div>
          <div className="mb-3 mt-3">
            {'The report provides a detailed overview of selected students ' +
              'progress towards their individual targets across different ' +
              'activities during a specific period. It includes information ' +
              'on targets that have been completed as well as targets that ' +
              'have not been completed yet'}
          </div>
          <div className="form-group">
            <label>Date from</label>
            <Field name="dateFrom">
              {({ field, form }) => (
                <div className="mb-3">
                  <DatePicker
                    {...field}
                    selected={field.value}
                    onChange={date => {
                      form.setFieldValue('dateFrom', date);
                      if (form.values.dateTo && form.values.dateTo < date) {
                        form.setFieldValue('dateTo', date);
                      }
                    }}
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
              )}
            </Field>
          </div>
          <div className="form-group">
            <label>Date to</label>
            <Field name="dateTo">
              {({ field, form }) => (
                <div className="mb-3">
                  <DatePicker
                    {...field}
                    selected={field.value}
                    onChange={date => {
                      form.setFieldValue('dateTo', date);
                    }}
                    minDate={form.values.dateFrom}
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
              )}
            </Field>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isShowCompleted"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowCompleted}
                    onChange={event => {
                      const isShowCompleted = event.target.checked;
                      setFieldValue('isShowCompleted', isShowCompleted);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show Completed Targets"
                hintText={'Show targets that have been marked as completed.'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowCompleted"
              />
            </div>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isShowUncompleted"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowUncompleted}
                    onChange={event => {
                      const isShowUncompleted = event.target.checked;
                      setFieldValue('isShowUncompleted', isShowUncompleted);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show Incomplete Targets"
                hintText={'Show targets that have not been completed yet.'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isUncompleted"
              />
            </div>
          </div>

          <button type="button" className="btn btn-secondary mr-3" onClick={props.onCloseClick}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Get
          </button>
        </Form>
      )}
    />
  );
}
