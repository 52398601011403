import * as React from 'react';
import { FunctionComponent } from 'react';

interface Props {
  onApplyFilterClick: () => void;
  onClearFilterClick: () => void;
  colSpanNumber: number;
}

export const GridFilterButtons: FunctionComponent<Props> = props => {
  const { onApplyFilterClick, onClearFilterClick, colSpanNumber } = props;
  return (
    <>
      <th colSpan={colSpanNumber} key="filter_buttons" className="mTextAlignLeft">
        <button className="btn btn-primary mMarginRight" onClick={onApplyFilterClick}>
          Apply filter
        </button>
        <button className="btn btn-secondary" onClick={onClearFilterClick}>
          Clear filter
        </button>
      </th>
    </>
  );
};

GridFilterButtons.displayName = 'GridFilterButtons';
