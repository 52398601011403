import * as React from 'react';
import { Route, Switch } from 'react-router';
import { AbsenceRequestsOutbox } from './AbsenceRequestsOutbox/AbsenceRequestsOutbox';
import { AbsenceRequestsArchive } from './AbsenceRequestsArchive/AbsenceRequestsArchive';

export const AbsenceRequestsRouting = allProps => {
  return (
    <Switch>
      <Route
        exact
        path={'/absenceRequests/outbox'}
        render={props => (
          <AbsenceRequestsOutbox {...props} user={allProps.user} availableRoles={allProps.availableRoles} />
        )}
      />
      <Route
        exact
        path={'/absenceRequests/archive'}
        render={props => <AbsenceRequestsArchive {...props} user={allProps.user} />}
      />
    </Switch>
  );
};

AbsenceRequestsRouting.displayName = 'AbsenceRequestsRouting';
