import * as React from 'react';
import { useState } from 'react';
import './AvailabilityReportForm.scss';
import {Switch} from "../../../../../components/Switch/Switch";

interface Props {
  onCancel: () => void;
  onSubmit: (data) => void;
}

export function AvailabilityReportForm(props: Props) {
  const [details, setDetails] = useState('');
  const [isTakePart, setIsTakePart] = useState(false);
  const [isError, setIsError] = useState(false);

  const submitForm = () => {
    const isTakePartExist = typeof isTakePart !== 'undefined';
    if (isTakePartExist) {
      const data = {
        details,
        isTakePart
      };
      props.onSubmit(data);
    } else {
      setIsError(true);
    }
  };

  const onDetailsChange = event => {
    setDetails(event.target.value);
  };

    const onReportChange = () => {
        setIsTakePart(!isTakePart);
    };

  return (
    <div className="bAvailabilityReport">
      <div>
        <div className="eAvailabilityReportTitle">Report Availability</div>

        <div className="eAvailabilityReportLabel">Availability</div>
          <div className="d-flex">
              <div className="mr-3">Not availible</div>
              <Switch
                  key={"availability"}
                  value={isTakePart}
                  onChange={() => {
                      onReportChange();
                  }}
                  text={''}
                  name="availability"
              />
              <div className="ml-1">Availible</div>
          </div>

        {isError && <div className="alert alert-danger">Please enter availability.</div>}

        <div className="eAvailabilityReportLabel">Details</div>
        <textarea
          className="eAvailabilityReportInput"
          value={details}
          onChange={event => {
            onDetailsChange(event);
          }}
        />

        <div>
          <button className="btn btn-secondary mt-3 mb-3 mr-3" onClick={props.onCancel}>
            Cancel
          </button>
          <button className="btn btn-primary mt-3 mb-3" onClick={submitForm}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
