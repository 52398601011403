import * as React from 'react';
import { DayPanel } from './DayPanel';
import { areDatesInSameDay, isEventsExistInDate, visibleDays } from '../../../../helpers/calendar/calendar';

interface Props {
  onClick: (date) => void;
  selectedDate: Date;
  monthAndYearDate: Date;
  nowDate: Date;
  eventDates: Date[];
}

export function MonthDaysPanel(props: Props) {
  const { nowDate, selectedDate, monthAndYearDate, onClick, eventDates } = props;
  const year = monthAndYearDate.getFullYear();
  const month = monthAndYearDate.getMonth();
  const datesToDraw = visibleDays(year, month);
  const datesCount = datesToDraw.length;
  const rows = [];

  for (let i = 0; i < datesCount; i++) {
    const rowNumber = Math.floor(i / 7);
    const date = datesToDraw[i];
    const dateMonth = date.getMonth();

    const dataAtDate = isEventsExistInDate(date, eventDates);
    const isNextMonth = dateMonth != month;
    const isPrevMonth = dateMonth != month;
    const isToday = areDatesInSameDay(date, nowDate);
    const isSelected = areDatesInSameDay(date, selectedDate);
    const row = rows[rowNumber] || [];

    const dayPanel = (
      <DayPanel
        key={`${date.getFullYear()}_${date.getMonth()}_${date.getDate()}`}
        isActive={dataAtDate}
        isSelected={isSelected}
        isToday={isToday}
        isNextMonth={isNextMonth}
        isPrevMonth={isPrevMonth}
        onClick={() => onClick(date)}
        dayName={date.getDate()}
      />
    );
    row.push(dayPanel);
    rows[rowNumber] = row;
  }

  const renderedRows = [];

  for (let i = 0; i < rows.length; i++) {
    const renderedRow = (
      <div key={`${year}_${month}_row_${i}`} className={'eMonth_row'}>
        {rows[i]}
      </div>
    );
    renderedRows.push(renderedRow);
  }

  return <div className={''}>{renderedRows}</div>;
}
