import * as React from 'react';
import * as propz from 'propz';
import { Autocomplete } from '../Autocomplete/Autocomplete';
import { FunctionComponent } from 'react';

interface Props {
  request: any;
  searchFunctionForms: (text: string) => void;
  searchFunctionHouses: (text: string) => void;
  getFormOrHouseTitle: (item: any) => string;
  onSelectForm: (form: any) => void;
  onSelectHouse: (house: any) => void;
  onAcceptPermission: () => void;
  form: any;
  house: any;
}

export const RequestAcceptStudent: FunctionComponent<Props> = props => {
  const {
    request,
    searchFunctionForms,
    getFormOrHouseTitle,
    onSelectForm,
    searchFunctionHouses,
    onSelectHouse,
    onAcceptPermission,
    form,
    house
  } = props;

  const comment = propz.get(request, ['requestedPermission', 'comment']);

  return (
    <div className="bRequestAcceptPage">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <h1>Accept student permission. Please choose:</h1>
            {typeof comment !== 'undefined' && <p>Comment: {comment}</p>}
          </div>

          <div className={'col-md-2 mb-3'}>
            <div>Form:</div>
            <Autocomplete
              searchFunction={searchFunctionForms}
              getElementTitle={getFormOrHouseTitle}
              onSelect={onSelectForm}
              defaultItem={form}
            />
          </div>
          <div className={'col-md-2 mb-3'}>
            <div>House:</div>
            <Autocomplete
              searchFunction={searchFunctionHouses}
              getElementTitle={getFormOrHouseTitle}
              onSelect={onSelectHouse}
              defaultItem={house}
            />
          </div>
          <div className={'col-md-12'}>
            <button className="btn btn-primary" onClick={onAcceptPermission}>
              Accept permission
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

RequestAcceptStudent.displayName = 'RequestAcceptStudent';
