import * as React from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button } from 'Src/components/Button/Button';
import DatePicker from 'react-datepicker';
import * as propz from 'propz';
import {
  getName,
  getNameByPlaceOrPostcode,
  searchFunctionEventParticipants,
  searchFunctionHousesNinHouseIds,
  searchFunctionSportsWithFavorite
} from 'Src/helpers/autocomplete/autocomplete';
import { Autocomplete } from 'Src/components/Autocomplete/Autocomplete';
import { AppUser } from 'Src/views/App/App';
import { Checkbox } from 'Src/components/Checkbox/Checkbox';
import { EVENT_TYPES, EVENT_TYPES_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/event';
import { Map } from 'Src/components/Map/Map';
import { Point } from 'Src/models/venue';
import { VENUE_DISTANCE, VENUE_DISTANCE_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/venue';
import { createVenue } from 'Src/helpers/service/admin/venues';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { VenueForm } from 'Src/views/GenericView/AdminView/School/Venues/VenueForm/VenueForm';
import { SchoolForm } from 'Src/models/form';
import {
  getAgesForMultiSelect,
  getAgesFromMultiSelect,
  getGendersForMultiSelect,
  getGendersFromMultiSelect
} from 'Src/helpers/multiselect/multiselect';
import { MultiSelectCheckboxes } from 'Src/components/MultiSelectCheckboxes/MultiSelectCheckboxes';
import './SchoolEventCopyForm.scss';
import { Switch } from 'Src/components/Switch/Switch';
import { LabelWithQuestionIcon } from 'Src/components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import { convertPlaceToPostcodeWithPlaceName, getDefaultPoint, getPostcodes, isPlace } from 'Src/helpers/venue/venue';
import './SchoolEventCopyForm.scss';
import { uploadImage } from 'Src/helpers/service/image';
import * as Moment from 'moment';
import { SchoolEvent } from 'Src/models/event';
import { EventDetail } from 'Src/models/eventDetail';
import { isClubEvent } from 'Src/helpers/event/event';
import { getAllHouses } from 'Src/helpers/service/admin/houses';
import { SchoolHouse } from 'Src/models/house';

interface Props {
  schoolEvent: SchoolEvent;
  eventDetails: EventDetail;
  participants: any[];
  houses: any[];
  forms: SchoolForm[];
  onSubmit: (data: any) => void;
  onCancel: () => void;
  user: AppUser;
}

interface State {
  participants: any[];
  point: Point;
  allHouses: SchoolHouse[];
  venue: any;
  houses: any[];
  eventType: string;
  distance: number;
  isDeleteVenueModalOpen: boolean;
  isCreateVenueFormOpen: boolean;
  isShowAllSports: boolean;
  multiparty: boolean;
  isSelectAllHouses: boolean;
}

export class SchoolEventCopyForm extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    const { schoolEvent, user, participants } = this.props;
    const venue = propz.get(schoolEvent, ['venue']);
    const convertPlace = typeof schoolEvent !== 'undefined' ? convertPlaceToPostcodeWithPlaceName(venue) : undefined;
    const participantsSchools = participants
      .map(participant => participant.school)
      .filter(
        participantsSchool => typeof participantsSchool !== 'undefined' && participantsSchool.id !== user.activeSchoolId
      );
    this.state = {
      houses: propz.get(this.props, ['houses'], []),
      allHouses: [],
      participants: participantsSchools,
      point: propz.get(convertPlace, ['point'], getDefaultPoint(user)),
      distance: VENUE_DISTANCE.UNLIMITED,
      eventType: propz.get(schoolEvent, ['eventType'], EVENT_TYPES.EXTERNAL_SCHOOLS),
      venue: propz.get(schoolEvent, ['venue'], undefined),
      isDeleteVenueModalOpen: false,
      isCreateVenueFormOpen: false,
      isShowAllSports: false,
      multiparty: propz.get(schoolEvent, ['multiparty'], false),
      isSelectAllHouses: false
    };
  }

  componentDidMount() {
    // TODO_003 - Warning: memory leak in application.
    const { user } = this.props;

    getAllHouses(user).then(allHouses => {
      this.setState({
        allHouses
      });
    });
  }

  getEventSchema() {
    const { eventType, multiparty } = this.state;

    let schema;

    switch (true) {
      case eventType === EVENT_TYPES.EXTERNAL_SCHOOLS:
        schema = Yup.object().shape({
          startTime: Yup.date().max(Yup.ref('endTime'), 'Must be less then end'),
          endTime: Yup.date().min(Yup.ref('startTime'), 'Must be more then start'),
          sport: Yup.string().required('Required'),
          gender: Yup.array().test('gender', 'Required', function(value) {
            const genderItemsChecked = value.filter(genderItem => genderItem.isChecked);
            const isLeastOneGenderItemsChecked = genderItemsChecked.length > 0;

            return isLeastOneGenderItemsChecked;
          }),
          eventType: Yup.string().required('Required'),
          participants: Yup.string().required('Required'),
          venue: Yup.string().required('Required'),
          multiparty: Yup.boolean(),
          leaguePositionsCount: Yup.number(),
          replyToEmail: Yup.string()
            .nullable()
            .email('Invalid email format')
        });
        break;
      case eventType === EVENT_TYPES.INTERNAL_HOUSES && !multiparty:
        schema = Yup.object().shape({
          startTime: Yup.date().max(Yup.ref('endTime'), 'Must be less then end'),
          endTime: Yup.date().min(Yup.ref('startTime'), 'Must be more then start'),
          sport: Yup.string().required('Required'),
          gender: Yup.string().required('Required'),
          eventType: Yup.string().required('Required'),
          houses: Yup.array()
            .min(2)
            .max(2),
          venue: Yup.string().required('Required'),
          multiparty: Yup.boolean(),
          leaguePositionsCount: Yup.number(),
          replyToEmail: Yup.string()
            .nullable()
            .email('Invalid email format')
        });
        break;
      case eventType === EVENT_TYPES.INTERNAL_HOUSES && multiparty:
        schema = Yup.object().shape({
          startTime: Yup.date().max(Yup.ref('endTime'), 'Must be less then end'),
          endTime: Yup.date().min(Yup.ref('startTime'), 'Must be more then start'),
          sport: Yup.string().required('Required'),
          gender: Yup.string().required('Required'),
          eventType: Yup.string().required('Required'),
          venue: Yup.string().required('Required'),
          houses: Yup.array().min(2),
          multiparty: Yup.boolean(),
          leaguePositionsCount: Yup.number(),
          replyToEmail: Yup.string()
            .nullable()
            .email('Invalid email format')
        });
        break;
      case eventType === EVENT_TYPES.INTERNAL_TEAMS:
        schema = Yup.object().shape({
          startTime: Yup.date().max(Yup.ref('endTime'), 'Must be less then end'),
          endTime: Yup.date().min(Yup.ref('startTime'), 'Must be more then start'),
          sport: Yup.string().required('Required'),
          gender: Yup.string().required('Required'),
          eventType: Yup.string().required('Required'),
          venue: Yup.string().required('Required'),
          multiparty: Yup.boolean(),
          leaguePositionsCount: Yup.number(),
          replyToEmail: Yup.string()
            .nullable()
            .email('Invalid email format')
        });
        break;
    }

    return schema;
  }

  getSports = (text: string, setFieldValue) => {
    const { isShowAllSports } = this.state;
    const { user } = this.props;
    const isFavoriteSportsEnabled = propz.get(user, ['activeSchool', 'canEditFavoriteSports']);
    const isOnlyFavoriteSports = isFavoriteSportsEnabled && !isShowAllSports;

    if (text === '') {
      setFieldValue('sportId', '');
      setFieldValue('sport', undefined);
    }

    return searchFunctionSportsWithFavorite(user, text, isOnlyFavoriteSports);
  };

  isExistSport = sport => {
    return typeof sport !== 'undefined';
  };

  getNewPoint = pointNew => {
    this.setState({
      point: pointNew
    });
  };

  onGenderChange = (index, setFieldValue, values) => {
    const genderItems = values.gender;
    const genderItemsUpdated = genderItems.map((genderItem, genderIndex) => {
      return genderIndex === index
        ? {
            ...genderItem,
            isChecked: !genderItem.isChecked
          }
        : genderItem;
    });

    setFieldValue('gender', genderItemsUpdated);
  };

  onAgesChange = (index, setFieldValue, values) => {
    const ageItems = values.ages;
    const ageItemsUpdated = ageItems.map((ageItem, ageIndex) => {
      return ageIndex === index
        ? {
            ...ageItem,
            isChecked: !ageItem.isChecked
          }
        : ageItem;
    });
    setFieldValue('ages', ageItemsUpdated);
  };

  onCancelCreateVenueFormOpenClick = (): void => {
    this.setState({
      isCreateVenueFormOpen: false
    });
  };

  createVenueFormOpen = (): void => {
    this.setState({
      isCreateVenueFormOpen: true
    });
  };

  onSubmitVenueForm = (data): void => {
    const { user } = this.props;

    createVenue(user, data).then(venue => {
      const convertPlace = convertPlaceToPostcodeWithPlaceName(venue);

      this.setState({
        venue: convertPlace,
        point: convertPlace.point,
        isCreateVenueFormOpen: false
      });
    });
  };

  renderCreateVenueForm() {
    const { user } = this.props;
    return (
      <SimpleModal isOpen={this.state.isCreateVenueFormOpen} title={'New venue'}>
        <VenueForm user={user} onCancel={this.onCancelCreateVenueFormOpenClick} onSubmit={this.onSubmitVenueForm} />
      </SimpleModal>
    );
  }

  renderParticipants(participants, setFieldValue) {
    return participants.map(participant => {
      return (
        <div key={participant.id} className={'d-flex'}>
          <div className="eSelectedItem">
            <span>{participant.name}</span>
            <div className="ml-1">
              <div
                className="eButtonClose"
                onClick={() => {
                  const participantsFiltered = participants.filter(p => p.id !== participant.id);
                  setFieldValue('participants', participantsFiltered);
                  this.setState({
                    participants: participantsFiltered
                  });
                }}
              >
                <span>&times;</span>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  renderHouses(houses, setFieldValue) {
    return houses.map(house => {
      return (
        <div key={house.id} className={'d-flex'}>
          <div className="eSelectedItem">
            <span>{house.name}</span>
            <div className="ml-1">
              <div
                className="eButtonClose"
                onClick={() => {
                  const { allHouses } = this.state;
                  const housesFiltered = houses.filter(item => item.id !== house.id);
                  setFieldValue('houses', housesFiltered);
                  this.setState({
                    houses: housesFiltered,
                    isSelectAllHouses: housesFiltered.length === allHouses.length
                  });
                }}
              >
                <span>&times;</span>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  getSchools = (text: string) => {
    const { user } = this.props;
    const { participants, distance } = this.state;

    return searchFunctionEventParticipants(text, user, participants, distance);
  };

  getHouses = text => {
    const { user } = this.props;

    const { houses } = this.state;
    const houseIds = houses.map(house => house.id);

    return searchFunctionHousesNinHouseIds(user, text, houseIds);
  };

  onIsShowAllSportsChange = (event, setFieldValue, values) => {
    const isShowAllSports = event.target.checked;
    const isActiveSportFavorite = propz.get(values, ['sport', 'isFavorite'], false);
    if (!isShowAllSports && !isActiveSportFavorite) {
      setFieldValue('sportId', '');
      setFieldValue('sport', undefined);
    }
    this.setState({ isShowAllSports: isShowAllSports });
  };

  onMultipartyChange = (event, setFieldValue, values) => {
    const multiparty = event.target.checked;
    setFieldValue('multiparty', multiparty);
    setFieldValue('participants', []);
    setFieldValue('houses', []);
    this.setState({ multiparty: multiparty, houses: [], participants: [] });
  };

  onImageSelected = (event, setFieldValue) => {
    const image = event.target.files[0];
    const { user } = this.props;

    uploadImage(user, image).then(response => {
      const picUrl = `${window.apiImg}/images/${response.key}`;
      setFieldValue('picUrl', picUrl);
    });
  };

  onRemoveImageClick = (setFieldValue): void => {
    setFieldValue('picUrl', '');
  };

  onAllHousesClick = (setFieldValue: (field: string, data: any) => void) => {
    const { allHouses, isSelectAllHouses } = this.state;

    if (isSelectAllHouses) {
      this.setState({
        houses: [],
        isSelectAllHouses: false
      });

      setFieldValue('houses', []);
    } else {
      this.setState({
        houses: allHouses,
        isSelectAllHouses: true
      });

      setFieldValue('houses', allHouses);
    }
  };

  render() {
    const {
      participants,
      point,
      venue,
      houses,
      eventType,
      distance,
      isShowAllSports,
      multiparty,
      isSelectAllHouses
    } = this.state;
    const { schoolEvent, user, onSubmit, forms, eventDetails } = this.props;
    const isSchoolHaveFavoriteSports = propz.get(user, ['activeSchool', 'canEditFavoriteSports']);
    const isExistActiveSchoolPostcode = propz.get(user, ['activeSchool', 'postcode'], false);
    const { activeSchool } = user;

    const eventInitial = {
      startTime: new Date(schoolEvent.startTime),
      endTime: new Date(schoolEvent.endTime),
      sport: schoolEvent.sport,
      sportId: schoolEvent.sportId,
      ages: getAgesForMultiSelect(activeSchool, forms, schoolEvent.ages),
      gender: getGendersForMultiSelect(schoolEvent.sport, schoolEvent.gender),
      eventType: eventType,
      participants: participants,
      venue: venue,
      houses: houses,
      distance: distance,
      isSelectAllHouses,
      multiparty: schoolEvent.multiparty,
      shortDescription: propz.get(eventDetails, ['shortDescription'], ''),
      picUrl: propz.get(schoolEvent, ['photos', 0, 'picUrl'], ''),
      isStudentCanSubmitResult: schoolEvent.isStudentCanSubmitResult,
      isStudentCanJoin: schoolEvent.isStudentCanJoin,
      leaguePositionsCount: schoolEvent.leaguePositionsCount,
      replyToEmail: schoolEvent.replyToEmail
    };
    return (
      <div className="container-fluid mt-4">
        {this.renderCreateVenueForm()}
        <div className="row">
          <div className="col-md-12">
            <Formik
              initialValues={eventInitial}
              validationSchema={this.getEventSchema()}
              onSubmit={values => {
                const { venue, gender, ages, picUrl, ...rest } = values;
                const dataToSubmit = {
                  ...rest,
                  venue: this.state.venue,
                  gender: getGendersFromMultiSelect(gender),
                  ages: getAgesFromMultiSelect(activeSchool, forms, ages),
                  photos: [picUrl.length > 0 ? { picUrl: picUrl } : {}] // empty value
                };
                onSubmit(dataToSubmit);
              }}
              render={({ touched, errors, setFieldValue, values }) => (
                <Form>
                  <div className="container-fluid mb-3">
                    <div className="row">
                      <div className="col-md-3">
                        <LabelWithQuestionIcon
                          labelText="Start time"
                          hintText={
                            'Scheduled start time for the event. Additionally, once the event has been ' +
                            "created you will be able to add other details such as a 'Meet time' as well " +
                            "as a 'Departure time' to give further information around the event."
                          }
                        />
                        <Field
                          name="startTime"
                          render={({ field }) => {
                            let startTime;
                            if (Moment(values.startTime, 'DD-MM-YYYY HH:mm aa').isValid()) {
                              startTime = Moment(values.startTime, 'DD-MM-YYYY HH:mm aa').toDate();
                            }
                            return (
                              <div className="mb-3">
                                <DatePicker
                                  selected={startTime}
                                  onChange={startDate => {
                                    setFieldValue('startTime', startDate);
                                    const endTime = new Date(startDate);
                                    endTime.setHours(startDate.getHours() + 1, startDate.getMinutes());
                                    setFieldValue('endTime', endTime);
                                  }}
                                  className="form-control"
                                  dateFormat={'dd-MM-yyyy HH:mm'}
                                  showTimeSelect
                                  timeFormat="HH:mm"
                                  timeIntervals={5}
                                  timeCaption="time"
                                />
                              </div>
                            );
                          }}
                        />
                        {touched.startTime && errors.startTime ? (
                          <div className="alert alert-danger">{errors.startTime}</div>
                        ) : null}
                      </div>
                      <div className="col-md-3">
                        <div>
                          <LabelWithQuestionIcon
                            labelText="Finish/Collection time"
                            hintText={
                              'Scheduled event finish time indicates the collection time for parents who ' +
                              'are going to collect their children from the venue. By default, event finish time is calculated ' +
                              'as one hour after the Start time.  Please note, when an event is used for an individual challenge ' +
                              'across your school the Finish/Collection time will indicate the deadline for submitting results ' +
                              'and/or photo/video evidence.'
                            }
                          />
                          <Field
                            name="endTime"
                            render={({ field }) => {
                              let endTime;
                              if (Moment(values.endTime, 'DD-MM-YYYY HH:mm aa').isValid()) {
                                endTime = Moment(values.endTime, 'DD-MM-YYYY HH:mm aa').toDate();
                              }
                              return (
                                <div className="mb-3">
                                  <DatePicker
                                    selected={endTime}
                                    onChange={endDate => {
                                      setFieldValue('endTime', endDate);
                                    }}
                                    className="form-control"
                                    dateFormat={'dd-MM-yyyy HH:mm'}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={5}
                                    timeCaption="time"
                                  />
                                </div>
                              );
                            }}
                          />
                          {touched.endTime && errors.endTime ? (
                            <div className="alert alert-danger">{errors.endTime}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6" />
                    </div>
                  </div>

                  {!isClubEvent(schoolEvent) && (
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <LabelWithQuestionIcon
                              labelText="Short description"
                              hintText={
                                'By default, an event name is generated automatically based on the event settings. ' +
                                'Although, you may choose to add a Short Description, it will then be displayed across the platform. ' +
                                'It is particularly useful when an event is used for the individual challenges within your school as ' +
                                'you are able to give it a bright and attractive name to be shown to students/parents.'
                              }
                            />
                            <Field type="text" name="shortDescription" className="form-control" />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="d-flex justify-content-between">
                            <LabelWithQuestionIcon
                              labelText="Activity / Sport"
                              hintText={
                                "By default, only activities marked as 'favourite' on your school account are displayed. " +
                                'To be able to select from the expanded list of activities turn on "Show all activities". ' +
                                'To be able to quickly find the activity start typing its name in the designated field.'
                              }
                            />
                            {isSchoolHaveFavoriteSports && (
                              <div className="d-flex">
                                <Field
                                  name="isShowAllSports"
                                  render={({ field }) => (
                                    <Switch
                                      {...field}
                                      value={isShowAllSports}
                                      customClass="mb-3"
                                      onChange={e => this.onIsShowAllSportsChange(e, setFieldValue, values)}
                                    />
                                  )}
                                />
                                <LabelWithQuestionIcon
                                  labelText="Show all activities"
                                  hintText={
                                    'Turn on this switch to select from the expanded list of activities across the platform, ' +
                                    "rather than from the list of your school's favourite activities. To be able to update your favourite " +
                                    'activities settings go to the admin panel and mark all the activities you would like ' +
                                    'to be displayed as a favourite.'
                                  }
                                  customLabelClass="form-check-label pl-2"
                                  labelHtmlFor="isShowAllSports"
                                />
                              </div>
                            )}
                          </div>
                          <Field
                            name="sport"
                            render={({ field }) => {
                              return (
                                <Autocomplete
                                  searchFunction={text => this.getSports(text, setFieldValue)}
                                  getElementTitle={getName}
                                  customClass="mFullWidth mb-3"
                                  defaultItem={values.sport}
                                  placeholder="Start typing the Activity/Sport name"
                                  onSelect={sport => {
                                    const isMultipartySport = propz.get(sport, ['multiparty'], false);
                                    const gender = getGendersForMultiSelect(sport);

                                    setFieldValue('sportId', sport.id);
                                    setFieldValue('sport', sport);
                                    setFieldValue('participants', []);
                                    setFieldValue('houses', []);
                                    setFieldValue('multiparty', isMultipartySport);
                                    setFieldValue('gender', gender);

                                    this.setState({
                                      multiparty: isMultipartySport,
                                      houses: [],
                                      participants: []
                                    });
                                  }}
                                />
                              );
                            }}
                          />
                          {touched.sportId && errors.sportId ? (
                            <div className="alert alert-danger">{errors.sportId}</div>
                          ) : null}
                          {typeof values.sport !== 'undefined' && (
                            <div className="eSportDescriptionText">{values.sport.description}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6" />
                    </div>
                  </div>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group form-check">
                          <div className="d-flex">
                            <Field
                              name="multiparty"
                              render={({ field }) => (
                                <Switch
                                  {...field}
                                  value={multiparty}
                                  customClass="mb-3"
                                  disabled={!values.sportId}
                                  onChange={e => this.onMultipartyChange(e, setFieldValue, values)}
                                />
                              )}
                            />
                            <LabelWithQuestionIcon
                              labelText="Multiple teams available"
                              hintText={
                                'For some activities / sports this switch is turned on by default (e.g; Athletics or Cross-Country).' +
                                ' For others, where exact scores are specified rather than places or points (e.g; Football or Hockey) this switch ' +
                                'is turned off by default. You can manage this yourself depending on your circumstances. For team sports, turning ' +
                                'on this switch will allow you to close your event by points or places (this is useful when you are attending a' +
                                " tournament and you're not tracking all the individual event scores via Squad In Touch, but instead wish to set" +
                                ' places or points). If this switch is turned off you will only be able to close team sports by their ' +
                                'regular scores (e.g; 3:3, 2:0, 5:8 etc.).'
                              }
                              customLabelClass="form-check-label pl-2"
                              labelHtmlFor="multiparty"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-9" />
                    </div>
                  </div>

                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <LabelWithQuestionIcon
                            labelText="Gender"
                            hintText={
                              "For mixed events turn on both checkboxes. Please be aware, you won't be" +
                              ' able to change this later and if you need to make amendments you will have to ' +
                              'create a new event instead.'
                            }
                          />
                          <MultiSelectCheckboxes
                            items={values.gender}
                            cols={3}
                            isDisabled={!this.isExistSport(values.sport)}
                            onChange={index => this.onGenderChange(index, setFieldValue, values)}
                            customClass={'mb-2 mSchoolEventMultiSelect pt-0'}
                          />
                          {touched.gender && errors.gender ? (
                            <div className="alert alert-danger">{errors.gender}</div>
                          ) : null}
                          <LabelWithQuestionIcon
                            labelText="Ages"
                            hintText={
                              'You can leave this field blank for events involving all the ages. ' +
                              'You will be able to change this later if, for instance, you need to add extra ' +
                              'students - younger or older. '
                            }
                          />
                          <MultiSelectCheckboxes
                            items={values.ages}
                            cols={3}
                            onChange={index => this.onAgesChange(index, setFieldValue, values)}
                            customClass="mSchoolEventMultiSelect pt-0"
                          />
                        </div>
                      </div>
                      <div className="col-md-6" />
                    </div>
                  </div>

                  <div className="container-fluid mb-3">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <LabelWithQuestionIcon
                            labelText="Event Type"
                            hintText={
                              'The platform supports all the types of events - inter-school, ' +
                              'house or internal events where 2 or more school teams are involved. '
                            }
                          />
                          <Field
                            component="select"
                            name="eventType"
                            className="form-control mb-3"
                            onChange={event => {
                              const eventType = event.target.value;
                              setFieldValue('eventType', eventType);
                              this.setState({
                                eventType: eventType
                              });
                            }}
                          >
                            <option key="event_type_option_inter_schools" value={EVENT_TYPES.EXTERNAL_SCHOOLS}>
                              {EVENT_TYPES_SERVER_TO_CLIENT_MAPPING.EXTERNAL_SCHOOLS}
                            </option>
                            <option key="event_type_option_house" value={EVENT_TYPES.INTERNAL_HOUSES}>
                              {EVENT_TYPES_SERVER_TO_CLIENT_MAPPING.INTERNAL_HOUSES}
                            </option>
                            <option key="event_type_option_internal" value={EVENT_TYPES.INTERNAL_TEAMS}>
                              {EVENT_TYPES_SERVER_TO_CLIENT_MAPPING.INTERNAL_TEAMS}
                            </option>
                          </Field>
                          {touched.eventType && errors.eventType ? (
                            <div className="alert alert-danger">{errors.eventType}</div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-3">
                        {values.eventType === EVENT_TYPES.EXTERNAL_SCHOOLS && isExistActiveSchoolPostcode && (
                          <div className="form-group">
                            <LabelWithQuestionIcon
                              labelText="Maximum distance"
                              hintText={
                                'You can limit the distance of searching opposing schools or' +
                                " just leave it as 'Unlimited'. "
                              }
                            />
                            <Field
                              component="select"
                              name="distance"
                              className="form-control mb-3"
                              onChange={event => {
                                const distance = event.target.value;
                                setFieldValue('distance', Number.parseInt(distance));
                                this.setState({
                                  distance: Number.parseInt(distance)
                                });
                              }}
                            >
                              <option value={VENUE_DISTANCE.UNLIMITED}>
                                {VENUE_DISTANCE_SERVER_TO_CLIENT_MAPPING.UNLIMITED}
                              </option>
                              <option value={VENUE_DISTANCE['10M']}>
                                {VENUE_DISTANCE_SERVER_TO_CLIENT_MAPPING['10M']}
                              </option>
                              <option value={VENUE_DISTANCE['20M']}>
                                {VENUE_DISTANCE_SERVER_TO_CLIENT_MAPPING['20M']}
                              </option>
                              <option value={VENUE_DISTANCE['30M']}>
                                {VENUE_DISTANCE_SERVER_TO_CLIENT_MAPPING['30M']}
                              </option>
                              <option value={VENUE_DISTANCE['40M']}>
                                {VENUE_DISTANCE_SERVER_TO_CLIENT_MAPPING['40M']}
                              </option>
                              <option value={VENUE_DISTANCE['50M']}>
                                {VENUE_DISTANCE_SERVER_TO_CLIENT_MAPPING['50M']}
                              </option>
                            </Field>
                          </div>
                        )}
                      </div>
                      <div className="col-md-6" />
                    </div>
                  </div>

                  <div className="container-fluid">
                    <div className="row">
                      {values.eventType === EVENT_TYPES.EXTERNAL_SCHOOLS && (
                        <>
                          <div className="col-md-3">
                            <div className="form-group">
                              <LabelWithQuestionIcon
                                labelText="Select opposing schools"
                                hintText={
                                  'You can select either one or more opposing schools based on the event settings.' +
                                  ' Later, you can change the opponent  if they have not accepted your invitation yet. If they ' +
                                  'have accepted it you will need to cancel this event and create another one. For multi-team' +
                                  ' events you will be able to remove and add opposing schools if there are any changes. If you' +
                                  " are not sure about the opponent(s) right now you can select 'TBD' and change it later when " +
                                  'you have more information. '
                                }
                              />
                              <Field
                                name="participants"
                                render={({ field }) => {
                                  return (
                                    <Autocomplete
                                      searchFunction={this.getSchools}
                                      getElementTitle={getName}
                                      customClass="mFullWidth mb-3"
                                      defaultItem={undefined}
                                      placeholder="Start typing the school name"
                                      isItemWithImage={true}
                                      disabled={multiparty ? false : values.participants.length > 0}
                                      onSelect={participant => {
                                        const nextParticipants = [].concat(values.participants, participant);
                                        setFieldValue('participants', nextParticipants);
                                        this.setState({
                                          participants: nextParticipants
                                        });
                                      }}
                                    />
                                  );
                                }}
                              />
                              <div className="d-flex flex-wrap">
                                {this.renderParticipants(values.participants, setFieldValue)}
                              </div>
                              {touched.participants && errors.participants ? (
                                <div className="alert alert-danger">{errors.participants}</div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-9" />
                        </>
                      )}

                      {values.eventType === EVENT_TYPES.INTERNAL_HOUSES && (
                        <>
                          <div className="col-md-3">
                            <div className="form-group">
                              <div className="d-flex justify-content-between">
                                <LabelWithQuestionIcon
                                  labelText="Select houses"
                                  hintText={
                                    'You can select either two or more houses taking part in the event' +
                                    ' (you can only select more than two houses when the event settings allow). '
                                  }
                                />
                              </div>

                              {multiparty && (
                                <Field
                                  name="setAllHouses"
                                  render={({ field }) => {
                                    return (
                                      <Checkbox
                                        id="selectAllHouses"
                                        customClass="mb-2"
                                        value={isSelectAllHouses}
                                        labelText="Select all"
                                        onChange={() => {
                                          this.onAllHousesClick(setFieldValue);
                                        }}
                                      />
                                    );
                                  }}
                                />
                              )}

                              <Field
                                name="houses"
                                render={({ field }) => {
                                  return (
                                    <Autocomplete
                                      searchFunction={this.getHouses}
                                      getElementTitle={getName}
                                      customClass="mFullWidth mb-3"
                                      defaultItem={undefined}
                                      onSelect={house => {
                                        const nextHouses = [].concat(values.houses, house);
                                        setFieldValue('houses', nextHouses);
                                        this.setState({
                                          houses: nextHouses
                                        });
                                      }}
                                      disabled={multiparty ? false : values.houses.length >= 2}
                                    />
                                  );
                                }}
                              />
                              <div className="d-flex flex-wrap">{this.renderHouses(values.houses, setFieldValue)}</div>
                              {touched.houses && errors.houses ? (
                                <div className="alert alert-danger">{errors.houses}</div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-9" />
                        </>
                      )}
                    </div>
                  </div>

                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="form-group">
                          <LabelWithQuestionIcon
                            labelText="Venue / Location"
                            hintText={
                              'You can either select a venue from your venue list or create a new' +
                              ' venue right from this form. Alternatively, you can type the postcode of the' +
                              ' venue in the field and use it just for this event. If you are not sure about ' +
                              "the venue yet, you can select 'TBD' and change it later when you have more " +
                              'information. Drag the pin on the map to specify the exact location where the ' +
                              'postcode points at approximate location.'
                            }
                          />
                          <Field
                            name="venue"
                            render={({ field }) => {
                              return (
                                <Autocomplete
                                  searchFunction={text =>
                                    getPostcodes(
                                      text,
                                      user,
                                      values.eventType === EVENT_TYPES.EXTERNAL_SCHOOLS,
                                      this.state.participants
                                    )
                                  }
                                  getElementTitle={getNameByPlaceOrPostcode}
                                  customClass="mFullWidth mb-3"
                                  placeholder="Start typing the venue name"
                                  defaultItem={this.state.venue}
                                  disabled={
                                    values.eventType === EVENT_TYPES.EXTERNAL_SCHOOLS &&
                                    values.participants.length === 0
                                  }
                                  onSelect={venue => {
                                    let postcode = venue;
                                    if (isPlace(postcode)) {
                                      postcode = convertPlaceToPostcodeWithPlaceName(postcode);
                                    }
                                    setFieldValue('venue', postcode);
                                    this.setState({
                                      venue: postcode
                                    });
                                    this.getNewPoint(postcode.point);
                                  }}
                                />
                              );
                            }}
                          />
                          {touched.venue && errors.venue ? (
                            <div className="alert alert-danger">{errors.venue}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-2 d-flex flex-column-reverse mb-3">
                        <div className="form-group">
                          <button
                            type="button"
                            className={
                              values.eventType === EVENT_TYPES.EXTERNAL_SCHOOLS && values.participants.length === 0
                                ? 'btn btn-secondary'
                                : 'btn btn-success'
                            }
                            onClick={this.createVenueFormOpen}
                            disabled={
                              values.eventType === EVENT_TYPES.EXTERNAL_SCHOOLS && values.participants.length === 0
                            }
                          >
                            Create venue
                          </button>
                        </div>
                      </div>
                      <div className="col-md-5" />
                    </div>
                  </div>

                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="form-group">
                          {propz.get(values.venue, ['venueType']) !== 'TBD' && typeof point !== 'undefined' && (
                            <Map
                              key={values.venue ? values.venue.id : 'emptyPostcode'}
                              point={point}
                              getNewPoint={this.getNewPoint}
                              customStylingClass="eSchoolEventVenueMap"
                              isMarkerDraggable={true}
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-md-7" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <LabelWithQuestionIcon
                          labelText="Reply To Email"
                          hintText={
                            'Specify an email address to be used as the reply-to address for notifications related to this event. ' +
                            'This field is optional and if empty the default email address from your school settings will be used.'
                          }
                        />
                        <Field
                          type="email"
                          name="replyToEmail"
                          className="form-control"
                          placeholder="Enter reply-to email address"
                          value={values.replyToEmail || ''}
                        />
                        {touched.replyToEmail && errors.replyToEmail ? (
                          <div className="alert alert-danger">{errors.replyToEmail}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid mt-3 mb-3">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="eChallengeSettingsLegend">
                          <LabelWithQuestionIcon
                            labelText="Challenge settings"
                            hintText={
                              'Please only use this section if you are setting up a challenge for an individual league'
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6" />
                    </div>
                  </div>

                  <div className="container-fluid mb-3">
                    <div className="row">
                      <div className="col-md-6 bChallengeSettings">
                        <div className="form-group mt-4">
                          <LabelWithQuestionIcon
                            labelText="Picture"
                            hintText={
                              'Here you can add a picture to an event. When an event is used for individual ' +
                              'challenges across your school students/parents will see this picture on the challenges ' +
                              'list via their mobile app.'
                            }
                          />
                          <input
                            type="file"
                            name="picUrl"
                            className={'form-control-file'}
                            onChange={event => this.onImageSelected(event, setFieldValue)}
                          />
                        </div>
                        {values.picUrl && (
                          <div style={{ maxWidth: '200px' }}>
                            <button
                              type="button"
                              className="close"
                              onClick={() => this.onRemoveImageClick(setFieldValue)}
                            >
                              <span>&times;</span>
                            </button>
                            <img className="img-fluid img-thumbnail" src={values.picUrl} alt="Event picture" />
                          </div>
                        )}

                        <div className="mb-3">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group mt-2">
                                <LabelWithQuestionIcon
                                  labelText="No. leaderboard positions"
                                  hintText={
                                    'Here you can set the number of positions shown in the leader board' +
                                    ' section of the student challenges mobile app and on the public league website'
                                  }
                                />
                                <Field type="text" name="leaguePositionsCount" className="form-control mb-3" />
                                {touched.leaguePositionsCount && errors.leaguePositionsCount ? (
                                  <div className="alert alert-danger">{errors.leaguePositionsCount}</div>
                                ) : null}
                              </div>
                            </div>

                            <div className="col-md-8">
                              <div className="form-check eStudentCanSubmitResult">
                                <div className="d-flex">
                                  <Field
                                    name="isStudentCanSubmitResult"
                                    render={({ field }) => (
                                      <Switch
                                        {...field}
                                        value={values.isStudentCanSubmitResult}
                                        customClass="mb-3"
                                        onChange={event => {
                                          const isStudentCanSubmitResult = event.target.checked;
                                          setFieldValue('isStudentCanSubmitResult', isStudentCanSubmitResult);
                                        }}
                                      />
                                    )}
                                  />
                                  <LabelWithQuestionIcon
                                    labelText="Students can submit results"
                                    hintText={
                                      "In turning on the 'Students can submit results' feature, you are enabling " +
                                      'students to enter their own results in the format allowed by the activity you have selected. ' +
                                      'Please note only use this feature if you are creating an individual challenge for students ' +
                                      'to join themselves'
                                    }
                                    customLabelClass="form-check-label pl-2"
                                    labelHtmlFor="isStudentCanSubmitResult"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="mb-3">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-check eStudentCanJoin">
                                <div className="d-flex">
                                  <Field
                                    name="isStudentCanJoin"
                                    render={({ field }) => (
                                      <Switch
                                        {...field}
                                        value={values.isStudentCanJoin}
                                        customClass="mb-3"
                                        onChange={event => {
                                          const isStudentCanJoin = event.target.checked;
                                          setFieldValue('isStudentCanJoin', isStudentCanJoin);
                                        }}
                                      />
                                    )}
                                  />
                                  <LabelWithQuestionIcon
                                    labelText="Students can join"
                                    hintText={''}
                                    customLabelClass="form-check-label pl-2"
                                    labelHtmlFor="isStudentCanJoin"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="container-fluid mb-3">
                    <div className="row">
                      <div className="col-md-6">
                        <div>
                          In selecting 'ADD EXISTING TEAM" you will be able to select a pre-created team. Alternatively,
                          if you would like to create a team from scratch you can select "ADD NEW TEAM" option. If you
                          are not sure about the event participants yet you can click the "ADD NEW TEAM" button, enter
                          the team name if prompted and click the "SAVE EVENT" at the next step. You will be able to
                          edit the participant list later when you have more details.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="ml-3 mb-3">
                    <Button
                      onClick={() => this.props.onCancel()}
                      text={'Cancel'}
                      customClass={'mt-3 mb-3 mr-3 btn-secondary'}
                    />
                    <button type={'submit'} className="mt-3 mb-3 mr-3 btn btn-primary">
                      Copy
                    </button>
                  </div>
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}
