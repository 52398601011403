import { DEFAULT_FILTER } from './user';
import { AppUser } from 'Src/views/App/App';
import axios from 'axios';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from 'Src/consts/table';
import { Count } from '../../../models/count';

/** CLUB TABLE */
export function getClubs(user: AppUser, filter = DEFAULT_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter
    }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/clubs`, config).then(response => {
    return response.data;
  });
}

export function getAllClubs(user: AppUser, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/clubs`, config).then(response => {
    return response.data;
  });
}

export function getClubsCount(user: AppUser, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where
        }
      }
    };
  } else {
    config = {
      headers: { usid: key }
    };
  }

  return axios.get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/clubs/count`, config).then(response => {
    return response.data;
  });
}

export function createClub(user: AppUser, data) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.post(`${window.apiBase}/i/schools/${activeSchoolId}/clubs`, data, config).then(response => {
    return response.data;
  });
}

export function getClub(user: AppUser, clubId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/clubs/${clubId}`, config).then(response => {
    return response.data;
  });
}

export function updateClub(user: AppUser, data, clubId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.put(`${window.apiBase}/i/schools/${activeSchoolId}/clubs/${clubId}`, data, config).then(response => {
    return response.data;
  });
}

export function deleteClub(user: AppUser, clubId) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.delete(`${window.apiBase}/i/schools/${activeSchoolId}/clubs/${clubId}`, config).then(response => {
    return response.data;
  });
}

export function activateClub(user: AppUser, clubId: string) {
  const { roleSessionKey: key, activeSchoolId } = user,
    timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone,
    data = { timeZoneName: timeZone };

  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/clubs/${clubId}/activate`, data, config)
    .then(response => {
      return response.data;
    });
}

export function updateClubBookingType(user: AppUser, clubId: string, isBookingFromWaitingList: boolean) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const data = {
    isBookingFromWaitingList
  };

  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/clubs/${clubId}/bookingType`, data, config)
    .then(response => {
      return response.data;
    });
}

export interface UpcomingEventsResponse {
  isUpcomingEventsExist: boolean;
  count: number;
}

export function upcomingEvents(user: AppUser, clubId: string): Promise<UpcomingEventsResponse> {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/clubs/${clubId}/upcomingEvents`, {}, config)
    .then(response => {
      return {
        isUpcomingEventsExist: response.data.isUpcomingEventsExist,
        count: response.data.count
      };
    });
}

export function removeUpcomingEventsForClub(user: AppUser, clubId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/clubs/${clubId}/removeUpcomingEvents`, {}, config)
    .then(response => {
      return response.data;
    });
}

export function getCoaches(user: AppUser, filter) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter
    }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/users/coaches`, config).then(response => {
    return response.data;
  });
}

export function getStaffs(user: AppUser, filter) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter
    }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/users/staffs`, config).then(response => {
    return response.data;
  });
}

export function getAvailableClubSpacesMax(user: AppUser, clubId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/clubs/${clubId}/participants/available/max`, config)
    .then(response => {
      return response.data;
    });
}

export function cancelClubMessageOutdatedStatus(user: AppUser, clubId: string, messageIdList: string[]) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const data = {
    messageIdList
  };

  const config = {
    headers: { usid: key }
  };

  return axios
    .put(`${window.apiBase}/i/schools/${activeSchoolId}/clubs/${clubId}/messages/outdated`, data, config)
    .then(response => {
      return response.data;
    });
}
