import * as React from 'react';
import * as BPromise from 'bluebird';
import * as propz from 'propz';
import { AppUser } from 'Src/views/App/App';
import { parse } from 'query-string';
import { getOrder, getSearchFilter, getSearchOrder, getServerFieldSectionWhere } from 'Src/helpers/table/table';
import {
  ColumnDefinition,
  getFilters2,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { History, Location } from 'history';
import { Grid2 } from 'Src/components/Grid/Grid2';
import { Loader } from 'Src/components/Loader/Loader';
import { DATE_INTERVAL, DEFAULT_LIMIT, DEFAULT_SKIP, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import {
  createSchoolTeam,
  getAllSchoolTeams,
  getSchoolTeams,
  getSchoolTeamsCount,
  updateSchoolTeam
} from 'Src/helpers/service/admin/schoolTeams';
import { getEventAges, getEventAgesGroup } from 'Src/helpers/accessor/accessor';
import {
  getSelectOptionForAge,
  getSelectOptionForSport,
  getSelectOptionForTournamentTeamGender
} from 'Src/helpers/table/select';
import { getAllForms } from 'Src/helpers/service/admin/forms';
import { getSchoolSports } from 'Src/helpers/service/admin/user';
import { SchoolForm } from 'Src/models/form';
import { SimpleAlert } from 'Src/components/SimpleAlert/SimpleAlert';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { SchoolTeamForm } from './SchoolTeamForm/SchoolTeamForm';
import { ResizablePanel } from 'Src/components/ResizablePanel/ResizablePanel';
import { GenericSummary } from 'Src/components/GenericSummary/GenericSummary';
import { Button } from 'Src/components/Button/Button';
import { TEAM_TABS } from 'Src/consts/team';
import { TABS } from 'Src/consts/common';
import { getAllSchoolTeamPlayers, updateSchoolTeamPlayersBatch } from 'Src/helpers/service/admin/schoolTeamPlayers';
import { SchoolTeamPlayersSummary } from 'Src/components/SchoolTeamPLayersSummary/SchoolTeamPlayersSummary';
import { SchoolTeamSummary } from 'Src/components/SchoolTeamSummary/SchoolTeamSummary';
import { TYPE_OF_PLAYERS } from 'Src/consts/sport';
import { Team } from '../../../../../models/team';
import { Sport } from '../../../../../models/sport';
import { deleteEvent } from '../../../../../helpers/service/admin/schoolEvents';
import { deleteTeam } from '../../../../../helpers/service/admin/schoolTeams';
import * as Moment from 'moment';
import { uploadReport } from 'Src/helpers/service/admin/report';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/report';
import { TeamPerformanceReportForm } from './Reports/TeamPerformanceReportForm/TeamPerformanceReportForm';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Sport',
    field: 'sport',
    isSort: true,
    type: FILTER_TYPE.MULTISELECT,
    accessor: ['sport', 'name']
  },
  {
    text: 'Name',
    field: 'name',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['name']
  },
  {
    text: 'Description',
    field: 'description',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['description']
  },
  {
    text: 'Age',
    field: 'teamAges',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getEventAgesGroup
  }
];

interface State {
  items: Team[];
  currentPage: number;
  selectedItems: Team[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  filters: any;
  isDataFiltered: boolean;
  isLoading: boolean;
  total: number;
  basePath: string;
  isSelectAllChecked: boolean;

  currentTabIndex: number;
  isError: boolean;
  errorMessage: string;
  isAddTeamModalOpen: boolean;

  forms: SchoolForm[];
  sports: Sport[];
  players: any[];
  isEditTeamPlayers: boolean;
  isDeleteEventModalOpen: boolean;
  isSuccessDeleteEventModalOpen: boolean;
  isErrorModalOpen: boolean;
  isTeamPerformanceReportModalOpen: boolean; //027
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export class SchoolTeams extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      filters: {},
      isDataFiltered: false,
      isLoading: false,
      total: 0,
      basePath: '',
      isSelectAllChecked: false,

      currentTabIndex: 0,

      isTeamPerformanceReportModalOpen: false,

      isError: false,
      errorMessage: '',
      isAddTeamModalOpen: false,

      forms: [],
      sports: [],
      players: [],
      isEditTeamPlayers: true,
      isErrorModalOpen: false,
      isDeleteEventModalOpen: false,
      isSuccessDeleteEventModalOpen: false
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setAdditionalItems().then(res => {
      this.setItems();
    });
  }

  setAdditionalItems() {
    const { user } = this.props;

    const filter = {
      where: {
        players: {
          $nin: [TYPE_OF_PLAYERS['1X1'], TYPE_OF_PLAYERS.INDIVIDUAL]
        },
        isSchoolEventsAvailable: 'true'
      },
      limit: DEFAULT_LIMIT,
      order: 'name ASC',
      skip: DEFAULT_SKIP
    };
    const formsPromise = getAllForms(user);
    const sportsPromise = getSchoolSports(user, filter);

    return Promise.all([formsPromise, sportsPromise]).then(([forms, sports]) => {
      const allowedInSchoolSportIds = propz.get(user, ['activeSchool', 'allowedSportIds'], []);
      const isAllSportsAllowed = allowedInSchoolSportIds.length === 0;

      let sportsForFilter = isAllSportsAllowed
        ? sports
        : sports.filter(sport => {
            const isSportAllowedInSchool = allowedInSchoolSportIds.some(id => id === sport.id);
            return isSportAllowedInSchool;
          });

      this.setState({
        forms: forms,
        sports: sportsForFilter
      });

      return true;
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  setItems() {
    const { history, user, location } = this.props;

    const search = parse(history.location.search);

    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    const lastSelectedFilters = propz.get(location, ['state', 'selectedFilters']);
    const isExistSelectedFilters = typeof lastSelectedFilters !== 'undefined';

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const getItemsPromise = getSchoolTeams(user, serverQueryFilter);
    const getItemsCountPromise = getSchoolTeamsCount(user, where);

    return BPromise.all([getItemsCountPromise, getItemsPromise]).then(([countObj, items]) => {
      const teamId = propz.get(location, ['state', 'teamId']);

      if (typeof teamId !== 'undefined') {
        const team = items.find(item => item.id === teamId);
        this.setState({
          currentPage: page,
          sortDirection: sortDirection,
          sortColumnsName: sortColumnsName,
          isShowFilter: isShowFilter,
          isDataFiltered: isDataFiltered,
          filters: filters,
          items: items,
          total: countObj.count,
          basePath: history.location.pathname,
          isLoading: false,
          selectedItems: typeof team !== 'undefined' ? [team] : []
        });
        const state = { ...(history.location.state as any) };
        delete state.teamId;
        history.replace({ ...history.location, state });
      } else {
        this.setState({
          currentPage: page,
          sortDirection: sortDirection,
          sortColumnsName: sortColumnsName,
          isShowFilter: isShowFilter,
          isDataFiltered: isDataFiltered,
          filters: filters,
          items: items,
          total: countObj.count,
          basePath: history.location.pathname,
          isLoading: false
        });
      }

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated,
      currentTabIndex: 0
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: nextSelectedItems,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    getAllSchoolTeams(user, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const nextSelectedItems = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: nextSelectedItems,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: ''
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  onTabClick = (event, index: number) => {
    event.preventDefault();
    const { user } = this.props;
    const { selectedItems } = this.state;
    const team = selectedItems[0];
    const tabs = TEAM_TABS;
    const currentTab = tabs[index];
    this.setState({
      isEditTeamPlayers: true
    });
    switch (currentTab) {
      case TABS.PARTICIPANTS:
        this.setState({
          isLoading: true
        });
        getAllSchoolTeamPlayers(user, team.id).then(players => {
          this.setState({
            currentTabIndex: index,
            players: players,
            isLoading: false
          });
        });
        break;
      default:
        this.setState({
          currentTabIndex: index
        });
    }
  };

  isOneItemSelected(): boolean {
    const selectedItems = this.state.selectedItems;
    return selectedItems.length === 1;
  }

  onAddTeamClick = () => {
    this.setState({
      isAddTeamModalOpen: true
    });
  };

  onEditTeamClick = () => {
    this.setState({
      isAddTeamModalOpen: true
    });
  };

  onCloseSchoolTeamFormCancel = () => {
    this.setState({
      isAddTeamModalOpen: false
    });
  };

  onEditTeamMembersClick = () => {
    const { history } = this.props;
    const { selectedItems } = this.state;
    const team = selectedItems[0];
    const { id } = team;
    const search = history.location.search;

    history.push({
      pathname: `/teams/teamPlayers`,
      search: `team=${id}`,
      state: {
        search
      }
    });
  };

  onEditTeamPlayersClick = () => {
    const { isEditTeamPlayers, selectedItems, players, currentTabIndex } = this.state;
    const { user } = this.props;
    const team = selectedItems[0];
    const { id } = team;

    if (isEditTeamPlayers) {
      this.setState({
        isEditTeamPlayers: false
      });
    } else {
      this.setState({
        isLoading: true,
        isEditTeamPlayers: true
      });

      const data = {
        update: players.map(player => {
          const { isCaptain, sub, positionId, id } = player;
          return {
            isCaptain: isCaptain,
            sub: sub,
            positionId: positionId !== '' ? positionId : undefined,
            id: id
          };
        })
      };
      updateSchoolTeamPlayersBatch(user, id, data)
        .then(res => {
          this.setState({
            currentTabIndex: currentTabIndex,
            selectedItems: selectedItems
          });
          this.setItems();
        })
        .catch(error => {
          const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
          console.error(error);
          this.setState({
            isLoading: false,
            errorMessage: errorText,
            isError: true
          });
        });
    }
  };

  onChangePositionPlayer = (event, index) => {
    const positionValue = event.target.value;
    const players = [...this.state.players];

    const changePlayer = players[index];
    changePlayer.positionId = positionValue;

    players.splice(index, 1, changePlayer);

    this.setState({
      players: players
    });
  };

  onChangeIsSubPlayer = (event, index) => {
    const isSub = event.target.checked;
    const players = [...this.state.players];
    const changePlayer = players[index];
    changePlayer.sub = isSub;
    players.splice(index, 1, changePlayer);

    this.setState({
      players: players
    });
  };

  onChangeIsCaptainPlayer = (event, playerId) => {
    const isCaptain = event.target.checked;
    const players = [...this.state.players];
    const playerIndex = players.findIndex(player => player.id === playerId);
    const oldCaptainIndex = players.findIndex(player => player.isCaptain);
    players[playerIndex].isCaptain = isCaptain;

    if (isCaptain && oldCaptainIndex !== -1) {
      players[oldCaptainIndex].isCaptain = false;
    }

    this.setState({
      players: players
    });
  };

  getSummaryTabs(): React.ReactNode {
    const { selectedItems, currentTabIndex, sports, players, isEditTeamPlayers } = this.state;
    const team = selectedItems[0];
    const currentSport = sports.find(sport => sport.id === team.sportId);
    switch (currentTabIndex) {
      case 0:
        return <SchoolTeamSummary team={team} />;
      case 1:
        return (
          <SchoolTeamPlayersSummary
            sport={currentSport}
            players={players}
            isEditTeamPlayers={isEditTeamPlayers}
            onChangePositionPlayer={this.onChangePositionPlayer}
            onChangeIsCaptainPlayer={this.onChangeIsCaptainPlayer}
            onChangeIsSubPlayer={this.onChangeIsSubPlayer}
          />
        );
    }
  }

  onTeamFormSubmit = data => {
    const { user } = this.props;
    const { selectedItems } = this.state;
    const { sport, ages, house, isHouseFilter, houseId, ...rest } = data;
    const agesNumber = ages.map(age => Number(age));

    const dataToSubmit = isHouseFilter
      ? {
          ...rest,
          houseId: houseId,
          ages: agesNumber,
          schoolId: user.activeSchoolId
        }
      : {
          ...rest,
          ages: agesNumber,
          schoolId: user.activeSchoolId
        };

    this.setState({
      isLoading: true,
      isAddTeamModalOpen: false
    });

    switch (selectedItems.length) {
      case 0: {
        createSchoolTeam(user, dataToSubmit)
          .then(team => {
            this.setState({
              selectedItems: [team],
              currentTabIndex: 0,
              players: []
            });
            this.setItems();
          })
          .catch(error => {
            const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
            console.error(error);
            this.setState({
              isLoading: false,
              errorMessage: errorText,
              isError: true
            });
          });
        break;
      }
      case 1:
        const teamId = selectedItems[0].id;
        updateSchoolTeam(user, teamId, dataToSubmit)
          .then(team => {
            this.setState({
              selectedItems: [team],
              currentTabIndex: 0,
              players: []
            });
            this.setItems();
          })
          .catch(error => {
            const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
            console.error(error);
            this.setState({
              isLoading: false,
              errorMessage: errorText,
              isError: true
            });
          });
        break;
    }
  };

  onSubmitReportForm = (data, reportType) => {
    const { user } = this.props;
    const { activeSchoolId, activeSchool } = user;
    const { selectedItems, forms } = this.state;
    const team = selectedItems[0];
    const teamId = propz.get(team, ['id'], '');

    const { dateTo, dateFrom, isShowUnclosed } = data;

    let modData;
    let formsUniq;
    let ages: number[];

    switch (reportType) {
      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.TEAM_PERFORMANCE:
        this.setState({
          isTeamPerformanceReportModalOpen: false,
          isLoading: true
        });

        dateFrom.setHours(0, 0, 0, 0);
        dateTo.setHours(23, 59, 59, 0);
        const formattedDateTo = Moment(dateTo).format('YYYY-MM-DD HH:mm:ss');
        const formattedDateFrom = Moment(dateFrom).format('YYYY-MM-DD HH:mm:ss');

        modData = {
          reportParamsCSV: {
            schoolId: activeSchoolId,
            teamId,
            dateFrom: formattedDateFrom,
            dateTo: formattedDateTo,
            showUnclosed: isShowUnclosed
          }
        };
        break;
    }

    uploadReport(user, reportType, modData)
      .then(data => {
        if (typeof data.reportId !== 'undefined') {
          const { reportType } = data;

          this.setState({
            isLoading: false
          });

          const url = `${window.apiBase}/public/report/${data.reportId}/reportType/${reportType}`;
          window.open(url);
        } else {
          this.setState({
            isLoading: false,
            errorMessage: 'No read report',
            isErrorModalOpen: true
          });
        }
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        console.error(error);

        this.setState({
          isLoading: false,
          errorMessage: errorText,
          isErrorModalOpen: true
        });
      });
  };

  openTeamPerformanceReportForm = () => {
    this.setState({
      isTeamPerformanceReportModalOpen: true
    });
  };

  closeTeamPerformanceReportForm = () => {
    this.setState({
      isTeamPerformanceReportModalOpen: false
    });
  };

  renderTeamPerformanceReportFormModal() {
    const { isTeamPerformanceReportModalOpen, selectedItems } = this.state;
    const team = selectedItems[0];
    return (
      <SimpleModal isOpen={isTeamPerformanceReportModalOpen}>
        <TeamPerformanceReportForm
          onCloseClick={this.closeTeamPerformanceReportForm}
          onSubmit={this.onSubmitReportForm}
          team={team}
        />
      </SimpleModal>
    );
  }

  renderTeamForm() {
    const { isAddTeamModalOpen, selectedItems, forms } = this.state;
    const { user } = this.props;

    if (selectedItems.length === 0) {
      return (
        <SimpleModal isOpen={isAddTeamModalOpen} title={'Add team'}>
          <SchoolTeamForm
            onSubmit={this.onTeamFormSubmit}
            onCancel={this.onCloseSchoolTeamFormCancel}
            user={user}
            forms={forms}
          />
        </SimpleModal>
      );
    } else {
      const team = selectedItems[0];
      return (
        <SimpleModal isOpen={isAddTeamModalOpen} title={'Edit team'}>
          <SchoolTeamForm
            onSubmit={this.onTeamFormSubmit}
            onCancel={this.onCloseSchoolTeamFormCancel}
            user={user}
            team={team}
            forms={forms}
          />
        </SimpleModal>
      );
    }
  }

  onCloseErrorClick = (event: Event) => {
    event.preventDefault();

    this.setState({
      isError: false,
      errorMessage: ''
    });
  };

  onDeleteTeamClick = () => {
    const { selectedItems } = this.state;
    const { user } = this.props;

    this.setState({
      isDeleteEventModalOpen: false,
      isLoading: true
    });

    const promises = selectedItems.map(event => {
      return deleteTeam(user, event.id);
    });
    BPromise.all(promises)
      .then(() => {
        this.setState({
          isSelectAllChecked: false,
          selectedItems: []
        });
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        console.error(error);
        this.setState({
          isLoading: false,
          errorMessage: errorText,
          isErrorModalOpen: true
        });
      });

    this.setItems();
  };

  deleteTeamModalOpen = (): void => {
    this.setState({
      isDeleteEventModalOpen: true
    });
  };

  onCloseDeleteTeamConfirmationModalClick = (): void => {
    this.setState({
      isDeleteEventModalOpen: false
    });
  };

  renderDeleteTeamConfirmationModal(): React.ReactNode {
    const { isDeleteEventModalOpen } = this.state;
    const { selectedItems: teams } = this.state;

    return (
      <SimpleModal
        isOpen={isDeleteEventModalOpen}
        title={'Confirmation'}
        onButtonClick={this.onDeleteTeamClick}
        body={`Are you sure you want to remove ${
          teams.length === 1 ? `team ${teams[0].name}` : `${teams.length} teams`
        }?`}
        buttonText={'Remove'}
        onCloseClick={this.onCloseDeleteTeamConfirmationModalClick}
      />
    );
  }

  renderSummary() {
    const { selectedItems, currentTabIndex, isEditTeamPlayers, players } = this.state;
    const isOneItemSelected = selectedItems.length === 1;

    if (!isOneItemSelected) {
      return <div className="font-weight-bold">Select a team above</div>;
    }

    const summaryTabs = this.getSummaryTabs();

    const isShowEditTeamButton = currentTabIndex === 0;
    const isShowEditTeamMembersButton = currentTabIndex === 1;

    const editTeamPlayersButtonText = isEditTeamPlayers ? 'Edit positions' : 'Save positions';
    const isPlayersExist = players.length > 0;

    return (
      <GenericSummary onTabClick={this.onTabClick} tabs={TEAM_TABS} currentTabIndex={currentTabIndex}>
        {isShowEditTeamButton && (
          <Button onClick={this.onEditTeamClick} text={'Edit details'} customClass={'mt-3 mb-3'} />
        )}
        {isShowEditTeamMembersButton && (
          <>
            <Button onClick={this.onEditTeamMembersClick} text={'Edit team members'} customClass={'mt-3 mb-3'} />
            <Button
              onClick={this.onEditTeamPlayersClick}
              text={editTeamPlayersButtonText}
              customClass={'mt-3 mb-3 ml-3'}
              disabled={!isPlayersExist}
            />
          </>
        )}
        {summaryTabs}
      </GenericSummary>
    );
  }

  renderGrid() {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isSelectAllChecked,
      currentPage,
      total,
      forms,
      sports
    } = this.state;

    const { user } = this.props;

    const actionItems = [
      {
        itemText: 'Add Teams',
        onItemClick: this.onAddTeamClick,
        isActive: selectedItems.length === 0
      },
      {
        itemText: selectedItems.length > 1 ? 'Delete teams' : 'Delete team',
        onItemClick: this.deleteTeamModalOpen,
        isActive: selectedItems.length > 0
      },
      {
        itemText: 'Reports',
        isActive: true,
        subItems: [
          {
            itemText: 'Team performance/schedule',
            onItemClick: this.openTeamPerformanceReportForm,
            isActive: selectedItems.length === 1
          }
        ]
      }
    ];

    const activeSchool = user.activeSchool;
    const ageGroupsNaming = activeSchool.ageGroupsNaming;

    const filterOptions = {
      gender: getSelectOptionForTournamentTeamGender(),
      teamAges: getSelectOptionForAge(forms, ageGroupsNaming),
      sport: getSelectOptionForSport(sports)
    };
    return (
      <Grid2
        user={user}
        dataItems={items}
        filters={filters}
        currentPage={currentPage}
        total={total}
        isSelectAllChecked={isSelectAllChecked}
        isDataFiltered={isDataFiltered}
        sortDirection={sortDirection}
        sortColumnsName={sortColumnsName}
        isShowFilter={isShowFilter}
        dataItemsSelected={selectedItems}
        columns={COLUMNS}
        actionItems={actionItems}
        options={filterOptions}
        onItemClick={this.onItemClick}
        onSortClick={this.onTableSortClick}
        onApplyFilterClick={this.onApplyFilterClick}
        onClearFilterClick={this.onClearFilterClick}
        onTableFilterChange={this.onTableFilterChange}
        onTableFilterClick={this.onTableFilterClick}
        setCurrentPageParams={this.setCurrentPageParams}
        onSelectAllClick={this.onSelectAllClick}
        onSelectAllOnPageClick={this.onSelectAllOnPageClick}
        onUnselectAllClick={this.onUnselectAllClick}
      />
    );
  }

  renderSendingError(): React.ReactNode {
    const { errorMessage, isError } = this.state;
    if (!isError) {
      return null;
    }

    return (
      <SimpleAlert
        message={errorMessage}
        additionalClassNames={'alert-danger'}
        onCloseAlertClick={this.onCloseErrorClick}
      />
    );
  }

  render() {
    const { isLoading, isError, isAddTeamModalOpen, isTeamPerformanceReportModalOpen } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes = isError || isAddTeamModalOpen || isTeamPerformanceReportModalOpen ? 'mt-3 modal-open' : 'mt-3';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderTeamForm()}
        {this.renderDeleteTeamConfirmationModal()}
        {this.renderSendingError()}
        {this.renderTeamPerformanceReportFormModal()}
        <div className="row">
          <div className="col-md-12">
            <ResizablePanel>
              {this.renderGrid()}
              {this.renderSummary()}
            </ResizablePanel>
          </div>
        </div>
      </div>
    );
  }
}
