import * as React from 'react';
import { Route, Switch } from 'react-router';
import { Championships } from './Championships';

export const ChampionshipsRouting = allProps => {
  return (
    <Switch>
      <Route exact path="/championships" render={props => <Championships {...props} user={allProps.user} />} />
    </Switch>
  );
};
