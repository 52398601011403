import * as React from 'react';
import { Point } from '../../models/venue';
import { DEFAULT_POINT, POINT } from '../../consts/venue';

export interface MapProps {
  point?: Point;
  customStylingClass?: string;
  getNewPoint?: (pointNew: Point) => void;
  isMarkerDraggable: boolean;
}

export class Map extends React.Component<MapProps, {}> {
  mapView: google.maps.Map;
  marker: google.maps.Marker;
  mapRef: any;
  listener: any;

  constructor(props: MapProps) {
    super(props);

    this.mapRef = React.createRef();
  }

  componentDidMount(): void {
    const { point = DEFAULT_POINT, isMarkerDraggable, getNewPoint } = this.props;
    const { coordinates } = point;
    const [lng, lat] = coordinates;
    const mapCenter = new (window as any).google.maps.LatLng(lat, lng);

    const mapOptions = {
      center: mapCenter,
      zoom: 16,
      disableDefaultUI: true,
      zoomControl: true
    };

    this.mapView = new (window as any).google.maps.Map(this.mapRef.current, mapOptions);

    this.marker = new (window as any).google.maps.Marker({
      position: mapCenter,
      map: this.mapView,
      draggable: isMarkerDraggable
    });

    if (isMarkerDraggable) {
      this.listener = this.marker.addListener('dragend', e => {
        if (typeof getNewPoint !== 'undefined') {
          const pointNew: Point = {
            type: POINT,
            coordinates: [Number(e.latLng.lng()), Number(e.latLng.lat())]
          };
          getNewPoint(pointNew);
        } else {
          console.error('getNewPoint props should be defined for draggable marker');
        }
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: MapProps): void {
    const { point = DEFAULT_POINT } = this.props;
    const { lat: prevLat, lng: prevLng, coordinates: prevCoordinates } = point;
    const { point: nextPoint = DEFAULT_POINT } = nextProps;
    const { lat: nextLat, lng: nextLng, coordinates: nextCoordinates } = nextPoint;
    const [nextCoordinatesLng, nextCoordinatesLat] = nextCoordinates;
    const [prevCoordinatesLng, prevCoordinatesLat] = prevCoordinates;

    const isLatLngChanged = prevLat !== nextLat || prevLng !== nextLng;
    const isCoordinatesChanged = prevCoordinatesLng !== nextCoordinatesLng || prevCoordinatesLat !== nextCoordinatesLat;

    if (isLatLngChanged || isCoordinatesChanged) {
      this.mapView.setCenter({ lat: nextCoordinatesLat, lng: nextCoordinatesLng });
      this.marker.setPosition({ lat: nextCoordinatesLat, lng: nextCoordinatesLng });
    }
  }

  componentWillUnmount() {
    (window as any).google.maps.event.removeListener(this.listener);
  }

  render() {
    const { customStylingClass } = this.props;
    const classNames = typeof customStylingClass !== 'undefined' ? customStylingClass : '';

    return <div className={`bMapView ${classNames}`} ref={this.mapRef} />;
  }
}
