import * as React from 'react';
import * as propz from 'propz';
import * as BPromise from 'bluebird';
import * as Lazy from 'lazy.js';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button } from '../Button/Button';
import { Switch } from '../Switch/Switch';
import { getFullNameAndFormName } from 'Src/helpers/autocomplete/autocomplete';
import { Autocomplete } from '../Autocomplete/Autocomplete';
import { AppUser } from 'Src/views/App/App';
import { Loader } from '../Loader/Loader';
import { getUserGenderArrayConvertedFromEventGender } from '../../helpers/tournament/tournament';
import { DEFAULT_SKIP } from '../../consts/table';
import { getAllForms } from '../../helpers/service/admin/forms';
import { getSchoolTournamentTeams } from '../../helpers/service/admin/tournamentTeams';
import { getSchoolTournamentTeamPlayers } from '../../helpers/service/admin/tournamentTeamPlayers';
import { SchoolForm } from '../../models/form';

interface Props {
  onSubmit: (player) => void;
  onCancel: () => void;
  event: any;
  user: AppUser;
}

interface State {
  forms: SchoolForm[];
  teamId: any;
  isLoading: boolean;
  isShowYounger: boolean;
}

const PlayerSchema = Yup.object().shape({
  player: Yup.string().required('Required')
});

const FORM_COUNT_IN_CHUNK = 30;

export class RemovePlayerForm extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      forms: [],
      teamId: undefined,
      isLoading: false,
      isShowYounger: false
    };
  }

  componentDidMount() {
    const { event, user } = this.props;
    const { tournamentId } = event;

    this.setState({
      isLoading: true
    });

    const getFormsPromise = getAllForms(user);
    const getTeamsPromise = getSchoolTournamentTeams(user, tournamentId);

    BPromise.all([getFormsPromise, getTeamsPromise]).then(([forms, teams]) => {
      const teamId = propz.get(teams, ['0', 'id']);

      if (typeof teamId === 'undefined') {
        console.error('Team id undefined');
      } else {
        this.setState({
          isLoading: false,
          forms: forms,
          teamId: teamId
        });
      }
    });
  }

  getPlayers = playerName => {
    const { event, user } = this.props;
    const { forms, teamId, isShowYounger } = this.state;
    const { activeSchoolId } = user;
    const { gender, ages, tournamentId } = event;

    const genderArrayConverted = getUserGenderArrayConvertedFromEventGender(gender);

    const playerIdList = event.players
      .filter(individual => {
        const individualSchoolId = individual.schoolId;

        return individualSchoolId === activeSchoolId;
      })
      .map(player => player.userId);

    //quick way
    if (playerIdList.length === 0) {
      return BPromise.resolve([]);
    }

    let filter = {
      limit: 20,
      skip: DEFAULT_SKIP
    };

    propz.set(filter, ['where', 'userId', '$in'], playerIdList);

    if (typeof genderArrayConverted !== 'undefined') {
      //mixed events also must be in server response
      propz.set(filter, ['where', 'gender', '$in'], genderArrayConverted);
    }

    if (typeof playerName !== 'undefined' && playerName !== '') {
      propz.set(filter, ['where', 'name', 'like'], playerName);
    }

    let formChunks = [];

    if (ages.length > 0 && forms.length > 0) {
      const filterFormIds = forms
        .filter(form => {
          return isShowYounger ? ages.some(age => age >= form.age) : ages.some(age => age === form.age);
        })
        .map(form => form.id);

      for (let i = 0; i < filterFormIds.length; i += FORM_COUNT_IN_CHUNK) {
        const chunk = filterFormIds.slice(i, i + FORM_COUNT_IN_CHUNK);
        formChunks.push(chunk);
      }
    }

    if (formChunks.length > 0) {
      // divide request to chunks because forms count may be to long and we can get 414 error
      const promises = formChunks.map(chunk => {
        propz.set(filter, ['where', 'formId', '$in'], chunk);
        return getSchoolTournamentTeamPlayers(user, tournamentId, teamId, filter);
      });

      return BPromise.all(promises).then(players => {
        return Lazy(players)
          .flatten()
          .toArray();
      });
    } else {
      return getSchoolTournamentTeamPlayers(user, tournamentId, teamId, filter);
    }
  };

  render() {
    const { isLoading } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const playerInitial = {
      isShowYounger: false,
      player: undefined
    };

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <Formik
              initialValues={playerInitial}
              validationSchema={PlayerSchema}
              onSubmit={values => {
                const { player } = values;
                this.props.onSubmit(player);
              }}
              render={({ touched, errors, setFieldValue, values }) => (
                <Form>
                  <div className="form-group form-check">
                    <div className="form-group">
                      <Field
                        name="isShowYounger"
                        render={({ field }) => (
                          <Switch
                            {...field}
                            onChange={event => {
                              const value = event.target.checked;
                              setFieldValue('isShowYounger', value);
                              this.setState({
                                isShowYounger: value
                              });
                            }}
                            text={'Show younger'}
                            customClass="mb-3"
                          />
                        )}
                      />

                      <label>Player</label>
                      <Field
                        name="player"
                        render={({ field }) => {
                          return (
                            <Autocomplete
                              searchFunction={this.getPlayers}
                              getElementTitle={getFullNameAndFormName}
                              customClass="mFullWidth mb-3"
                              defaultItem={values.player}
                              onSelect={player => {
                                setFieldValue('player', player);
                              }}
                            />
                          );
                        }}
                      />
                      {touched.player && errors.player ? (
                        <div className="alert alert-danger">{errors.player}</div>
                      ) : null}
                    </div>
                    <Button
                      onClick={this.props.onCancel}
                      text={'Cancel'}
                      customClass={'mt-3 mb-3 mr-3 btn-secondary'}
                    />
                    <button type="submit" className="mt-3 mb-3 btn btn-primary">
                      Remove
                    </button>
                  </div>
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}
