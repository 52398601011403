import * as React from 'react';
import { Route, Switch } from 'react-router';
import { AvailabilityReportsOutbox } from './AvailabilityReportsOutbox/AvailabilityReportsOutbox';
import { AvailabilityReportsArchive } from './AvailabilityReportsArchive/AvailabilityReportsArchive';

export const AvailabilityReportsRouting = allProps => {
  return (
    <Switch>
      <Route
        exact
        path={'/availabilityReports/outbox'}
        render={props => <AvailabilityReportsOutbox {...props} user={allProps.user} />}
      />
      <Route
        exact
        path={'/availabilityReports/archive'}
        render={props => <AvailabilityReportsArchive {...props} user={allProps.user} />}
      />
    </Switch>
  );
};

AvailabilityReportsRouting.displayName = 'AvailabilityReportsRouting';
