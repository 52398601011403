import * as React from 'react';
import { Field, Form, Formik } from 'formik';
import { Button } from '../Button/Button';
import { AppUser } from 'Src/views/App/App';
import {LabelWithQuestionIcon} from "../LabelWithQuestionIcon/LabelWithQuestionIcon";
import * as propz from "propz";
import * as Yup from "yup";
import {MAX_NEXT_OF_KIN_PRIORITY, MIN_NEXT_OF_KIN_PRIORITY} from "../../consts/student";

interface Props {
    person?: any;
    onSubmit: (data) => void;
    onCancel: () => void;
    user: AppUser;
}

const NextOfKinSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    personEmail: Yup.string().email('Must be valid email')
});

export const StudentNextOfKinForm: React.FunctionComponent<Props> = props => {
    const { person, onSubmit, onCancel } = props;
    const isNextOfKinExist = typeof person !== 'undefined';

    const nextOfKinForm = {
        relationship: isNextOfKinExist ? propz.get(person, ['relationship'], '') : '',
        firstName: isNextOfKinExist ? propz.get(person, ['firstName'], '') : '',
        lastName: isNextOfKinExist ? propz.get(person, ['lastName'], '') : '',
        phones: [
            propz.get(person, ['phones', '0'], ''),
            propz.get(person, ['phones', '1'], '')
        ],
        personEmail: isNextOfKinExist ? propz.get(person, ['email'], ''): '',
        priority: isNextOfKinExist ? propz.get(person, ['priority'], undefined): undefined
    };

    const nextOfKinPriorityOptions = () => {
        let options = [];

        for (let index = MIN_NEXT_OF_KIN_PRIORITY; index <= MAX_NEXT_OF_KIN_PRIORITY; index++) {
            options.push(
                <option key={index} value={index}>
                    {index}
                </option>
            );
        }

        options.unshift(
            <option key={`empty_priority`} value={0}>
                None
            </option>
        );

        return options;
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <Formik
                        validationSchema={NextOfKinSchema}
                        initialValues={nextOfKinForm}
                        onSubmit={values => {
                            onSubmit(values);
                        }}
                        render={({ touched, errors, setFieldValue, values }) => (
                            <Form>
                                <LabelWithQuestionIcon labelText="Relationship" hintText={''}/>
                                <Field type="text" name="relationship" className="form-control mb-3" />
                                {touched.relationship && errors.relationship ? (
                                    <div className="alert alert-danger">{errors.relationship}</div>
                                ) : null}

                                <LabelWithQuestionIcon labelText="Name" hintText={''}/>
                                <Field type="text" name="firstName" className="form-control mb-3" />
                                {touched.firstName && errors.firstName ? (
                                    <div className="alert alert-danger">{errors.firstName}</div>
                                ) : null}

                                <LabelWithQuestionIcon labelText="Surname" hintText={''}/>
                                <Field type="text" name="lastName" className="form-control mb-3" />
                                {touched.lastName && errors.lastName ? (
                                    <div className="alert alert-danger">{errors.lastName}</div>
                                ) : null}

                                <LabelWithQuestionIcon labelText="Email" hintText={''}/>
                                <Field type="text" name="personEmail" className="form-control mb-3" />
                                {touched.personEmail && errors.personEmail ? (
                                    <div className="alert alert-danger">{errors.personEmail}</div>
                                ) : null}

                                <LabelWithQuestionIcon labelText="Phone" hintText={''}/>
                                        <Field type="text"  value={values.phones[0]} onChange={(event) => {
                                            const currentPhone = event.target.value;
                                            const newPhones = [...values.phones];
                                            newPhones.splice(0, 1, currentPhone);
                                            setFieldValue('phones', newPhones);
                                        }}
                                               className="form-control mb-3" />

                                <LabelWithQuestionIcon labelText="Phone" hintText={''}/>
                                <Field type="text"  value={values.phones[1]} onChange={(event) => {
                                    const currentPhone = event.target.value;
                                    const newPhones = [...values.phones];
                                    newPhones.splice(1, 1, currentPhone);
                                    setFieldValue('phones', newPhones);
                                }}
                                       className="form-control mb-3" />

                                <LabelWithQuestionIcon labelText="Priority" hintText={''}/>
                                <Field component="select" name="priority" className="form-control mb-3">
                                     {nextOfKinPriorityOptions()}
                                </Field>
                                {touched.priority && errors.priority ? (
                                    <div className="alert alert-danger">{errors.priority}</div>
                                ) : null}


                                <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                                <button type="submit" className="mt-3 mb-3 btn btn-primary">
                                    Submit
                                </button>
                            </Form>
                        )}
                    />
                </div>
            </div>
        </div>
    );
};

StudentNextOfKinForm.displayName = 'StudentNextOfKinForm';

