import * as React from 'react';
import { FunctionComponent } from 'react';
import { Transaction } from 'Src/models/transaction';
import * as propz from 'propz';
import { TRANSACTION_CURRENCY_SYMBOL, TRANSACTION_STATUS_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/transaction';
import { SimpleAlert } from 'Src/components/SimpleAlert/SimpleAlert';
import { getCreatedAtWithTime, getUpdatedAt } from 'Src/helpers/accessor/accessor';

interface Props {
  clubTransactions: Transaction[];
  errorMessageInPayments: string;
  isErrorInPayments: boolean;
  onCloseErrorInPaymentsClick: (event: Event) => void;
}

export const ClubPaymentsSummary: FunctionComponent<Props> = props => {
  const clubTransactions = props.clubTransactions;
  const rows = clubTransactions.map((clubTransaction, index) => {
    return (
      <tr key={`club_payments_${index}`}>
        <td>{index + 1}</td>
        <td>
          {TRANSACTION_CURRENCY_SYMBOL.POUND}
          {clubTransaction.price / 100}
        </td>
        <td>{TRANSACTION_STATUS_SERVER_TO_CLIENT_MAPPING[clubTransaction.status]}</td>
        <td>{propz.get(clubTransaction, ['customerData', 'firstName'], 'unknown')}</td>
        <td>{propz.get(clubTransaction, ['customerData', 'lastName'], 'unknown')}</td>
        <td>{propz.get(clubTransaction, ['customerData', 'email'], 'unknown')}</td>
        <td>{propz.get(clubTransaction, ['customerData', 'phone'], 'unknown')}</td>
        <td>{propz.get(clubTransaction, ['playerData', 'firstName'], 'unknown')}</td>
        <td>{propz.get(clubTransaction, ['playerData', 'lastName'], 'unknown')}</td>
        <td>{getCreatedAtWithTime(clubTransaction)}</td>
        <td>{getUpdatedAt(clubTransaction)}</td>
      </tr>
    );
  });

  const emptyRow = (
    <tr key={'club_payments_empty_row'}>
      <td>No club payments</td>
    </tr>
  );

  if (props.isErrorInPayments) {
    return (
      <SimpleAlert
        message={props.errorMessageInPayments}
        additionalClassNames={'alert-danger'}
        onCloseAlertClick={props.onCloseErrorInPaymentsClick}
      />
    );
  }

  return (
    <table className={'table table-sm mPre mVerticalAlignMiddle'}>
      <thead>
        <tr>
          <th>#</th>
          <th>Amount</th>
          <th>Status</th>
          <th>Customer first name</th>
          <th>Customer last name</th>
          <th>Email</th>
          <th>Phone</th>
          <th>Player first name</th>
          <th>Player last name</th>
          <th>Created at</th>
          <th>Updated at</th>
        </tr>
      </thead>
      <tbody>{rows.length > 0 ? rows : emptyRow}</tbody>
    </table>
  );
};

ClubPaymentsSummary.displayName = 'ClubPaymentsSummary';
