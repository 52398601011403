import axios from 'axios';
import { AppUser } from 'Src/views/App/App';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from 'Src/consts/table';
import { Count } from '../../../models/count';
import { Sport } from '../../../models/sport';

function getDefaultSchoolTournamentEventsFilter(schoolId: string) {
  return {
    invitedSchoolIds: {
      $in: [schoolId]
    }
  };
}

const DEFAULT_SCHOOL_TOURNAMENT_EVENTS_ORDER = 'startTime ASC';

const DEFAULT_FILTER: any = {
  limit: DEFAULT_LIMIT,
  skip: DEFAULT_SKIP
};

export function getTournamentEvents(user: AppUser, tournamentId: string, filter = DEFAULT_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order || DEFAULT_SCHOOL_TOURNAMENT_EVENTS_ORDER
      }
    }
  };

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/schools/tournaments/${tournamentId}/events`, config)
    .then(response => {
      return response.data;
    });
}

export function getSchoolTournamentEvents(user: AppUser, tournamentId: string, filter = DEFAULT_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const defaultFilter = getDefaultSchoolTournamentEventsFilter(activeSchoolId);

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...defaultFilter,
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order || DEFAULT_SCHOOL_TOURNAMENT_EVENTS_ORDER
      }
    }
  };

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/schools/tournaments/${tournamentId}/events`, config)
    .then(response => {
      return response.data;
    });
}

export function getAllSchoolTournamentEvents(user: AppUser, tournamentId: string, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const defaultFilter = getDefaultSchoolTournamentEventsFilter(activeSchoolId);

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...defaultFilter,
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...defaultFilter
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/schools/tournaments/${tournamentId}/events`, config)
    .then(response => {
      return response.data;
    });
}

export function getSchoolTournamentEventsCount(user: AppUser, tournamentId: string, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  const defaultFilter = getDefaultSchoolTournamentEventsFilter(activeSchoolId);

  let config;

  config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...defaultFilter,
          ...where
        }
      }
    }
  };

  return axios
    .get<Count>(
      `${window.apiBase}/i/schools/${activeSchoolId}/schools/tournaments/${tournamentId}/events/count`,
      config
    )
    .then(response => {
      return response.data;
    });
}

export function getAllSchoolTournamentEventSports(user: AppUser, tournamentId: string, where?): Promise<Sport[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios
    .get<Sport[]>(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/sports`, config)
    .then(response => {
      return response.data;
    });
}
