import * as React from 'react';
import { useState } from 'react';
import * as Moment from 'moment';
import * as propz from 'propz';
import { AppUser } from 'Src/views/App/App';
import { Tournament } from 'Src/models/tournament';
import { AGE_GROUPS, AGE_GROUPS_SORTED } from 'Src/consts/school';
import { TOURNAMENT_GENDER_SERVER_TO_CLIENT_MAPPING, TOURNAMENT_TYPE } from 'Src/consts/tournament';
import { PUBLIC_SITE_STATUS_OPTIONS_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/tournament';
import { getTournamentPublicSiteLink, getTournamentTVPublicSiteLink } from 'Src/helpers/link/link';
import { sortAutoEnrollmentTeamsSettingsByAge } from 'Src/helpers/event/event';
import { Map } from 'Src/components/Map/Map';
import './DetailsAndBooking.scss';
import { getAgesGroupFormatted } from 'Src/helpers/accessor/accessor';
import { CLUB_GENDER_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/club';
import { SPORT_GENDER_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/common';

interface Props {
  tournament: Tournament;
  user: AppUser;
}

export const DetailsAndBooking = (props: Props) => {
  const { tournament, user } = props;

  const [isMapVisible, setIsMapVisible] = useState(false);

  const { activeSchool } = user;
  const { ageGroupsNaming } = activeSchool;

  const tournamentType = propz.get(tournament, ['type'], '');
  const isIndividualTournament = tournamentType === TOURNAMENT_TYPE.INDIVIDUAL_TOURNAMENT;
  const startTime = propz.get(tournament, ['startTime'], '');
  const deadlineForEntries = propz.get(tournament, ['deadlineForEntries']);
  const isDeadlineForEntriesExist = typeof deadlineForEntries !== 'undefined' && deadlineForEntries !== null;
  const dateFormatted = Moment(startTime).format('dddd Do MMMM YYYY, h:mm a');
  const deadlineFormatted = isDeadlineForEntriesExist
    ? Moment(deadlineForEntries).format('dddd Do MMMM YYYY')
    : 'When closed by the organisers';
  const agesFormatted = getAgesGroupFormatted(tournament, { user });
  const address = propz.get(tournament, ['school', 'address'], '');
  const postcode = propz.get(tournament, ['venue', 'postcode'], '');
  const point = propz.get(tournament, ['venue', 'point']);
  const tournamentGender = propz.get(tournament, ['gender'], '');
  const organisers = propz.get(tournament, ['organisers'], '');
  const organisersEmail = propz.get(tournament, ['organisersEmail'], '');
  const nationalGoverningBodies = propz.get(tournament, ['nationalGoverningBodies'], []);
  const autoEnrollmentTeamsSettings = propz.get(tournament, ['autoEnrollmentTeamsSettings'], []);
  const isAutoEnrollmentTeamsSettingsExist = autoEnrollmentTeamsSettings.length > 0;
  const autoEnrollmentTeamsSettingsSorted = sortAutoEnrollmentTeamsSettingsByAge(
    autoEnrollmentTeamsSettings,
    ageGroupsNaming
  );
  const isAutoEnrollmentTeams = propz.get(tournament, ['isAutoEnrollmentTeams'], false);
  const tournamentPublicSiteLink = getTournamentPublicSiteLink(tournament);
  const tournamentTVPublicSiteLink = getTournamentTVPublicSiteLink(tournament);
  const domain = propz.get(tournament, ['domain'], '');
  const publicSiteStatus = propz.get(tournament, ['publicSite', 'status'], '');
  const isPublicSiteLinkAvailable =
    domain !== '' && publicSiteStatus === PUBLIC_SITE_STATUS_OPTIONS_SERVER_TO_CLIENT_MAPPING.PUBLIC_AVAILABLE;
  const playersGender = TOURNAMENT_GENDER_SERVER_TO_CLIENT_MAPPING[tournamentGender];

  const renderNationalGoverningBodies = () => {
    return nationalGoverningBodies.map(item => {
      const { link, picUrl } = item;

      return (
        <a href={link}>
          <img className="eNationalGoverningBodiesImage" src={picUrl} alt="Governing bodies" />
        </a>
      );
    });
  };

  const getAge = (age: number): string => {
    const sortedAgeGroup: string[] = propz.get(AGE_GROUPS_SORTED, [ageGroupsNaming]);
    const ageGroup: string[] = propz.get(AGE_GROUPS, [ageGroupsNaming]);

    const ageValue: string = ageGroup[age];

    const sortedAgeIndex: number = sortedAgeGroup.indexOf(ageValue);

    return sortedAgeGroup[sortedAgeIndex];
  };

  const renderRemainingSpacesRow = setting => {
    const { age, gender: genderOfTeamSettings } = setting;

    const currentNumberTeams = propz.get(setting, ['currentNumberTeams']);
    const maximumNumberTeams = propz.get(setting, ['maximumNumberTeams']);

    const gender = isIndividualTournament ? tournamentGender : genderOfTeamSettings;

    const spacesLeft = maximumNumberTeams - currentNumberTeams;
    const teamsAge = isIndividualTournament ? agesFormatted : getAge(age);
    const teamsGender = CLUB_GENDER_SERVER_TO_CLIENT_MAPPING[gender] || SPORT_GENDER_SERVER_TO_CLIENT_MAPPING[gender];

    const remainingSpaceRow =
      maximumNumberTeams === 0
        ? `${teamsAge} ${teamsGender} - Unlimited`
        : `${teamsAge} ${teamsGender} - ${spacesLeft} space(s) left`;

    return <p>{remainingSpaceRow}</p>;
  };

  const renderRemainingSpacesSection = () => {
    return isIndividualTournament && isAutoEnrollmentTeamsSettingsExist
      ? renderRemainingSpacesRow(autoEnrollmentTeamsSettings[0])
      : autoEnrollmentTeamsSettings.map((item, index) => {
          return <div key={`auto-enrollment-setting-${index}`}>{renderRemainingSpacesRow(item)}</div>;
        });
  };

  return (
    <div className="bDetailsAndBooking">
      <div className="row border-bottom py-4">
        <div className="col-6 d-flex align-items-center font-weight-bold">Date: </div>
        <div className="col-6 text-right">{dateFormatted}</div>
      </div>

      <div className="row border-bottom py-4">
        <div className="col-6 d-flex align-items-center font-weight-bold">Venue: </div>
        <div className="col-6 text-right">
          {`${address} (${postcode})`}
          <div className="eLocationButton" onClick={() => setIsMapVisible(!isMapVisible)}>
            <img src="/dist/images/icons/location.png" alt="location" />
          </div>
        </div>
      </div>

      {isMapVisible && <Map point={point} customStylingClass="mb-3 eTournamentDetailsMap" isMarkerDraggable={false} />}

      <div className="row border-bottom py-4">
        <div className="col-6 d-flex align-items-center font-weight-bold">Gender: </div>
        <div className="col-6 text-right">{playersGender}</div>
      </div>

      <div className="row border-bottom py-4">
        <div className="col-6 d-flex align-items-center font-weight-bold">Organizers: </div>
        <div className="col-6 text-right">{organisers}</div>
      </div>

      <div className="row border-bottom py-4">
        <div className="col-6 d-flex align-items-center font-weight-bold">Email: </div>
        <div className="col-6 text-right">{organisersEmail}</div>
      </div>

      <div className="row border-bottom py-4">
        <div className="col-6 d-flex align-items-center font-weight-bold">
          <div>
            National Governing Bodies:
            <span className="eNationalGoverningBodiesSubtitle">Click on logo for website and further information</span>
          </div>
        </div>
        <div className="col-6 text-right">{renderNationalGoverningBodies()}</div>
      </div>

      {isAutoEnrollmentTeams && (
        <div className="row border-bottom py-4">
          <div className="col-6 d-flex align-items-center font-weight-bold">Spaces remaining: </div>
          <div className="col-6 text-right">{renderRemainingSpacesSection()}</div>
        </div>
      )}

      <div className="row border-bottom py-4">
        <div className="col-6 d-flex align-items-center font-weight-bold">Entries close: </div>
        <div className="col-6 text-right">{deadlineFormatted}</div>
      </div>

      <div className="row border-bottom py-4">
        <div className="col-6 d-flex align-items-center font-weight-bold">Resulst website: </div>
        <div className="col-6 text-right">
          {isPublicSiteLinkAvailable ? (
            <a href={tournamentPublicSiteLink} target="_blank">
              {tournamentPublicSiteLink}
            </a>
          ) : (
            'The link is not available yet.'
          )}
        </div>
      </div>

      <div className="row border-bottom py-4">
        <div className="col-6 d-flex align-items-center font-weight-bold">Tournament TV: </div>
        <div className="col-6 text-right">
          {isPublicSiteLinkAvailable ? (
            <a href={tournamentTVPublicSiteLink} target="_blank">
              {tournamentTVPublicSiteLink}
            </a>
          ) : (
            'The link is not available yet.'
          )}
        </div>
      </div>
    </div>
  );
};

DetailsAndBooking.displayName = 'DetailsAndBooking';
