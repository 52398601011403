import * as React from 'react';
import * as BPromise from 'bluebird';
import { AppUser } from 'Src/views/App/App';
import { parse } from 'query-string';
import { getOrder, getSearchFilter, getSearchOrder, getServerFieldSectionWhere } from 'Src/helpers/table/table';
import { DATE_INTERVAL, DEFAULT_LIMIT, DEFAULT_SKIP, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { Loader } from 'Src/components/Loader/Loader';
import {
  ColumnDefinition,
  getFilters2,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { History, Location } from 'history';
import { Grid2 } from 'Src/components/Grid/Grid2';
import {
  getEmailOrMisEmail,
  getPhoneOrMisPhone,
  getUserForms,
  getUserHouses,
  getUserRole
} from 'Src/helpers/accessor/accessor';
import { Tag } from 'Src/models/tag';
import { getUserTags } from 'Src/helpers/accessor/accessor';
import { getAllUserTags } from 'Src/helpers/service/admin/userTags';
import { getSelectOptionForTag } from 'Src/helpers/table/select';
import { SchoolUser } from 'Src/models/schoolUser';
import { getSchoolForms, getSchoolHouses } from 'Src/helpers/service/admin/user';
import { getAllSchoolStaffs, getSchoolStaffs, getSchoolStaffsCount } from 'Src/helpers/service/admin/staffs';
import { getSelectOptionForForms, getSelectOptionForHouses, getSelectOptionForStaffs } from 'Src/helpers/table/select';
import { SchoolForm } from '../../models/form';
import { SchoolHouse } from '../../models/house';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Name',
    field: 'firstName',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['firstName']
  },
  {
    text: 'Surname',
    field: 'lastName',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['lastName']
  },
  {
    text: 'Email',
    field: 'email',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: getEmailOrMisEmail
  },
  {
    text: 'Phone',
    field: 'phone',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: getPhoneOrMisPhone
  },
  {
    text: 'Role',
    field: 'role',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getUserRole
  },
  {
    text: 'Forms',
    field: 'permissionFormIds',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getUserForms
  },
  {
    text: 'Houses',
    field: 'permissionHouseIds',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getUserHouses
  },
  {
    text: 'Tag',
    field: 'tag',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getUserTags
  }
];

interface State {
  items: SchoolUser[];
  currentPage: number;
  selectedItems: SchoolUser[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;
  tags: Tag[];
  forms: SchoolForm[];
  houses: SchoolHouse[];
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
  selectedItems: SchoolUser[];
  onSelectedItemsChange: (items: SchoolUser[]) => void;
  onStaffSelectionCompleted(isCompleted: boolean): void;
}

export class CreateGeneralMessageStaffStep extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: props.selectedItems || [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',
      tags: [],
      forms: [],
      houses: []
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setAdditionalItems().then(res => {
      this.setItems();
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  setAdditionalItems() {
    const { user } = this.props;

    const staffsTags = getAllUserTags(user);
    const formsPromise = getSchoolForms(user, { limit: DEFAULT_LIMIT, skip: DEFAULT_SKIP });
    const housesPromise = getSchoolHouses(user, { limit: DEFAULT_LIMIT, skip: DEFAULT_SKIP });

    return BPromise.all([staffsTags, formsPromise, housesPromise]).then(([tags, forms, houses]) => {
      this.setState({
        tags,
        forms,
        houses
      });

      return true;
    });
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const getItemsPromise = getSchoolStaffs(user, serverQueryFilter);
    const getItemsCountPromise = getSchoolStaffsCount(user, where);

    const promises = [getItemsCountPromise, getItemsPromise];

    return BPromise.all(promises).then(([countObj, items]) => {
      this.setState({
        currentPage: page,
        sortDirection: sortDirection,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: items,
        total: countObj.count,
        basePath: history.location.pathname,
        isLoading: false
      });

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated
    });

    this.props.onSelectedItemsChange(selectedItemsUpdated);

    this.props.onStaffSelectionCompleted(selectedItemsUpdated.length > 0);
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: nextSelectedItems,
      isSelectAllChecked: true
    });

    this.props.onSelectedItemsChange(nextSelectedItems);

    this.props.onStaffSelectionCompleted(nextSelectedItems.length > 0);
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    getAllSchoolStaffs(user, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const nextSelectedItems = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: nextSelectedItems,
        isSelectAllChecked: true,
        isLoading: false
      });

      this.props.onSelectedItemsChange(nextSelectedItems);

      this.props.onStaffSelectionCompleted(nextSelectedItems.length > 0);
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.onSelectedItemsChange([]);

    this.props.onStaffSelectionCompleted(false);
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: ''
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  renderStaffTable(): React.ReactNode {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isSelectAllChecked,
      currentPage,
      total,
      forms,
      houses,
      tags
    } = this.state;

    const actionItems = [];

    const filterOptions = {
      role: getSelectOptionForStaffs(),
      permissionFormIds: getSelectOptionForForms(forms),
      permissionHouseIds: getSelectOptionForHouses(houses),
      tag: getSelectOptionForTag(tags)
    };

    return (
      <Grid2
        dataItems={items}
        filters={filters}
        currentPage={currentPage}
        total={total}
        isSelectAllChecked={isSelectAllChecked}
        isDataFiltered={isDataFiltered}
        sortDirection={sortDirection}
        sortColumnsName={sortColumnsName}
        isShowFilter={isShowFilter}
        dataItemsSelected={selectedItems}
        columns={COLUMNS}
        actionItems={actionItems}
        options={filterOptions}
        onItemClick={this.onItemClick}
        onSortClick={this.onTableSortClick}
        onApplyFilterClick={this.onApplyFilterClick}
        onClearFilterClick={this.onClearFilterClick}
        onTableFilterChange={this.onTableFilterChange}
        onTableFilterClick={this.onTableFilterClick}
        setCurrentPageParams={this.setCurrentPageParams}
        onSelectAllClick={this.onSelectAllClick}
        onSelectAllOnPageClick={this.onSelectAllOnPageClick}
        onUnselectAllClick={this.onUnselectAllClick}
        isCSV={false}
      />
    );
  }

  render() {
    const { isLoading } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes = 'mt-3';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        <div>
          Here you will need to select the members of staff you would like to recieve your communication. The filter
          button will enable you to search through the list of staff members, however please note when you select a new
          filter search your previous selected students will automatically be unselected.
        </div>
        <div className="row">
          <div className="col-md-12">{this.renderStaffTable()}</div>
        </div>
      </div>
    );
  }
}
