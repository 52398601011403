import * as React from 'react';
import { NavLink } from 'react-router-dom';
import * as propz from 'propz';
import { AppUser } from 'Src/views/App/App';
import { PROFILE_TABS_TITLES, PROFILE_TABS, PROFILE_TABS_URLS, PROFILE_TABS_ARRAY } from '../../../../consts/profile';
import './ProfileBar.scss';

interface Props {
  user: AppUser;
  isEditMode: boolean;
}

export function ProfileBar(props: Props) {
  const getTabs = () => {
    const { user } = props;
    const isEmailVerified = propz.get(user, ['profile', 'verification', 'status', 'email']);
    const isPhoneVerified = propz.get(user, ['profile', 'verification', 'status', 'sms']);
    const isUserVerified = isEmailVerified && isPhoneVerified;

    const tabs = isUserVerified
      ? [...PROFILE_TABS_ARRAY].filter(tab => tab !== PROFILE_TABS.VERIFICATION)
      : [...PROFILE_TABS_ARRAY];

    const tabObjs = tabs.map(tab => {
      const title = PROFILE_TABS_TITLES[tab];
      const url = PROFILE_TABS_URLS[tab];

      return { title, url };
    });

    return tabObjs;
  };

  const { isEditMode } = props;

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="bProfileBar">
          {getTabs().map((tab, index) => {
            const { title, url } = tab;
            return (
              <NavLink
                className={isEditMode ? 'eProfileBarItem mLinkDisabled' : 'eProfileBarItem'}
                activeClassName={'eProfileBarItem mProfileBarActive'}
                to={url}
                key={`profile_tab_title_${index}`}
              >
                {title}
              </NavLink>
            );
          })}
        </div>
      </div>
    </div>
  );
}
