import * as React from 'react';
import { DropTarget } from 'react-dnd';

interface TeamDropSectionProps {
  // props from dnd lib
  connectDropTarget: (any) => any;
  isOver: boolean;

  onTeamDrop: (teamId: string) => void;
}

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
}

const TeamDropSectionTarget = {
  drop(props: TeamDropSectionProps, monitor) {
    const dropResult = monitor.getItem();
    props.onTeamDrop(dropResult.teamId);

    return {};
  }
};

class TeamDropSection extends React.Component<TeamDropSectionProps> {
  renderChildren() {
    const styles = this.props.isOver ? { opacity: 0.5 } : {};

    return (
      <div className={'col-md-6 eTeamDropSection'} style={styles}>
        {this.props.children}
      </div>
    );
  }
  render() {
    return this.props.connectDropTarget(this.renderChildren());
  }
}

export default DropTarget('teamCard', TeamDropSectionTarget, collect)(TeamDropSection);
