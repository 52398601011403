import * as React from 'react';
import { Component } from 'react';
import * as propz from 'propz';
import { Field, Form } from 'formik';
import DatePicker from 'react-datepicker';
import { uploadImage } from 'Src/helpers/service/image';
import { AppUser } from 'Src/views/App/App';
import * as Moment from 'moment';

interface Props {
  nationalGoverningBody: any;
  user: AppUser;
  onCloseClick: (index) => void;
  onGlobalNationalGoverningBodiesFormChange: (event, index, field) => void;
  nationalGoverningBodyIndex: number;
}

interface State {
  isImageError: boolean;
}

export class TournamentNationalGoverningBodiesForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isImageError: false
    };
  }

  onImageSelected = event => {
    const { user, onGlobalNationalGoverningBodiesFormChange, nationalGoverningBodyIndex } = this.props;
    const image = event.target.files[0];
    uploadImage(user, image)
      .then(response => {
        const picUrl = `${window.apiImg}/images/${response.key}`;
        onGlobalNationalGoverningBodiesFormChange(picUrl, nationalGoverningBodyIndex, 'uploadImage');
        this.setState({
          isImageError: false
        });
      })
      .catch(error => {
        console.error(error);
        this.setState({ isImageError: true });
      });
  };

  render() {
    const { isImageError } = this.state;
    const { nationalGoverningBody, nationalGoverningBodyIndex } = this.props;
    const picUrl = propz.get(nationalGoverningBody, ['picUrl'], '');
    const isPicUrlExists = typeof picUrl !== 'undefined' && picUrl !== '';
    const imgInputClassName = isImageError ? 'form-control-file is-invalid' : 'form-control-file';
    const link = propz.get(nationalGoverningBody, ['link'], ''),
      name = propz.get(nationalGoverningBody, ['name'], '');

    return (
      <div className="bTournamentFormCustomSectionBlock rounded">
        <div className="form-group">
          <button type="button" className="close" onClick={() => this.props.onCloseClick(nationalGoverningBodyIndex)}>
            <span>&times;</span>
          </button>
          <label>Name</label>
          <Field
            type="text"
            value={name}
            onChange={event => {
              this.props.onGlobalNationalGoverningBodiesFormChange(event, nationalGoverningBodyIndex, 'name');
            }}
            className="form-control"
          />
          <label>Link</label>
          <Field
            type="text"
            value={link}
            onChange={event => {
              this.props.onGlobalNationalGoverningBodiesFormChange(event, nationalGoverningBodyIndex, 'link');
            }}
            className="form-control"
          />
          <div className="form-group">
            <label>Photos</label>
            <input type="file" name="picUrl" className={imgInputClassName} onChange={this.onImageSelected} />
            {isImageError && <div className="invalid-feedback">Error uploading image</div>}
          </div>
          {isPicUrlExists && (
            <div className="mMaxWidth200px">
              <button
                type="button"
                className="close"
                onClick={event => {
                  this.props.onGlobalNationalGoverningBodiesFormChange(
                    event,
                    nationalGoverningBodyIndex,
                    'removeImageButton'
                  );
                }}
              >
                <span>&times;</span>
              </button>
              <img className="img-fluid img-thumbnail" src={picUrl} alt="News picture" />
            </div>
          )}
        </div>
      </div>
    );
  }
}
