import * as React from 'react';
import { FunctionComponent } from 'react';
import { Tabs } from '../Tabs/Tabs';
import { AppUser } from 'Src/views/App/App';
import './GenericSummary.css';

interface Props {
  currentTabIndex: number;
  onTabClick: (event, index: number) => void;
  tabs: string[];
  customClass?: string;
}

export const GenericSummary: FunctionComponent<Props> = props => {
  const { currentTabIndex, onTabClick, tabs, customClass } = props;

  return (
    <div>
      <Tabs currentTabIndex={currentTabIndex} onTabClick={onTabClick} tabs={tabs} customClass={customClass} />
      <div>{props.children}</div>
    </div>
  );
};

GenericSummary.displayName = 'GenericSummary';
