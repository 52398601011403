import * as React from 'react';

interface Props {
  onClick?: () => void;
}

export const CloseIcon = (props: Props) => {
  const { onClick } = props;
  const isOnClickExist = typeof onClick !== 'undefined';

  const emptyFunc = () => {};

  return (
    <svg
      className="bTableCellIcon"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 32 32"
      xmlSpace="preserve"
      onClick={isOnClickExist ? onClick : emptyFunc}
    >
      <g xmlns="http://www.w3.org/2000/svg" data-name={30} fill="#f44336">
        <path
          d="M16 30a14 14 0 1 1 14-14 14 14 0 0 1-14 14zm0-26a12 12 0 1 0 12 12A12 12 0 0 0 16 4z"
          data-original="#000000"
        />
        <path
          d="M21 22a1 1 0 0 1-.71-.29l-5-5a1 1 0 0 1 1.42-1.42l5 5a1 1 0 0 1 0 1.42A1 1 0 0 1 21 22z"
          data-original="#000000"
        />
        <path
          d="M11 22a1 1 0 0 1-.71-.29 1 1 0 0 1 0-1.42l5-5a1 1 0 0 1 1.42 1.42l-5 5A1 1 0 0 1 11 22z"
          data-original="#000000"
        />
        <path
          d="M16 17a1 1 0 0 1-.71-.29 1 1 0 0 1 0-1.42l5-5a1 1 0 0 1 1.42 1.42l-5 5A1 1 0 0 1 16 17z"
          data-original="#000000"
        />
        <path
          d="M16 17a1 1 0 0 1-.71-.29l-5-5a1 1 0 0 1 1.42-1.42l5 5a1 1 0 0 1 0 1.42A1 1 0 0 1 16 17z"
          data-original="#000000"
        />
      </g>
    </svg>
  );
};

CloseIcon.displayName = 'CloseIcon';
