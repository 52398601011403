import * as React from 'react';

interface Props {
  items: any[];
  itemIdList: string[];
  onClick: (id: string) => void;
  getItemName: (item) => string;
  allItemsTitle: string;
}

export function ItemsBar(props: Props) {
  const { itemIdList, items, onClick, getItemName, allItemsTitle } = props;

  const isSeveralitems = items.length > 1;
  const isAllItemsShow = itemIdList.length === items.length;

  const isItemShow = item => {
    return !isAllItemsShow && item.id === itemIdList[0];
  };

  return (
    <div className="d-flex justify-content-around bItemsBar">
      {isSeveralitems && (
        <span
          key={`child_all`}
          className={isAllItemsShow ? 'eItemsBarActiveItem' : 'eItemsBarItem'}
          onClick={() => onClick('all')}
        >
          {allItemsTitle}
        </span>
      )}
      {items.map((item: any) => (
        <span
          key={`item_${item.id}`}
          className={isItemShow(item) ? 'eItemsBarActiveItem' : 'eItemsBarItem'}
          onClick={() => onClick(item.id)}
        >
          {getItemName(item)}
        </span>
      ))}
    </div>
  );
}
