import * as React from 'react';
import * as classNames from 'classnames';
import { Component } from 'react';
import * as propz from 'propz';
import { Button } from '../Button/Button';
import { Switch } from '../Switch/Switch';

interface Props {
  calendars: [];
  isOpen: boolean;
  onButtonClick?: (selectCalendarsIds) => void;
  onCloseClick?: () => void;
}

interface State {
  calendars: any;
}

export class IntegrationEditModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      calendars: propz.get(this.props, ['calendars'], [])
    };
  }

  onSubmit = () => {
    const { calendars } = this.state;
    const selectedCalendarIds = calendars.filter(calendar => calendar.selected === true).map(calendar => calendar.id);

    this.props.onButtonClick(selectedCalendarIds);
  };

  render() {
    const { isOpen, onCloseClick } = this.props;
    const { calendars } = this.state;
    if (!isOpen) {
      return null;
    }

    const modalClasses = classNames({
      mDisplayBlock: isOpen,
      show: isOpen,
      modal: true,
      fade: true
    });

    return (
      <div className={modalClasses}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Choose calendars to import events!</h5>
              <button type="button" className="close" onClick={onCloseClick}>
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {calendars.map((calendar, index) => (
                <Switch
                  key={`group_${index}`}
                  value={calendar.selected}
                  onChange={() => {
                    let changeCalendars = calendars.slice();
                    let changeCalendar = calendar;
                    changeCalendar.selected = !calendar.selected;
                    changeCalendars.splice(index, 1, changeCalendar);
                    this.setState({
                      calendars: changeCalendars
                    });
                  }}
                  text={calendar.name}
                  name={calendar.name}
                />
              ))}
            </div>
            <div className="modal-footer">
              <Button onClick={onCloseClick} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
              <button type="button" onClick={this.onSubmit} className="mt-3 mb-3 btn btn-primary">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
