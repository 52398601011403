import { AppUser } from 'Src/views/App/App';
import axios from 'axios';

export function changePassword(user: AppUser, oldPassword: string, newPassword: string) {
  const { loginSessionKey: key } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.put(`${window.apiBase}/i/profile/password`, { oldPassword, newPassword }, config).then(response => {
    return response.data;
  });
}
