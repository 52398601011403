import axios from 'axios';
import { DEFAULT_FILTER } from '../admin/user';

export function getSchoolsPublic(filter = DEFAULT_FILTER) {
  const config = {
    params: {
      filter
    }
  };

  return axios.get(`${window.apiBase}/public/schools`, config).then(response => {
    return response.data;
  });
}

export function getSchoolPostcode(filter = DEFAULT_FILTER) {
  const config = {
    params: {
      filter
    }
  };

  return axios.get(`${window.apiBase}/public/postcodes`, config).then(response => {
    return response.data;
  });
}

export function getSchoolSportsPublic(schoolId: string, filter = DEFAULT_FILTER) {
  const config = {
    params: {
      filter
    }
  };

  return axios.get(`${window.apiBase}/public/schools/${schoolId}/sports`, config).then(response => {
    return response.data;
  });
}

export function getSchoolFormsPublic(schoolId: string, filter = DEFAULT_FILTER) {
  const config = {
    params: {
      filter
    }
  };

  return axios.get(`${window.apiBase}/public/schools/${schoolId}/forms`, config).then(response => {
    return response.data;
  });
}

export function getSchoolHousesPublic(schoolId: string, filter = DEFAULT_FILTER) {
  const config = {
    params: {
      filter
    }
  };

  return axios.get(`${window.apiBase}/public/schools/${schoolId}/houses`, config).then(response => {
    return response.data;
  });
}

export function getSchoolTournamentsPublic(schoolId: string, filter = DEFAULT_FILTER) {
  const config = {
    params: {
      filter
    }
  };

  return axios.get(`${window.apiBase}/public/schools/${schoolId}/tournaments`, config).then(response => {
    return response.data;
  });
}

export function getSchoolPublic(schoolId: string) {
  return axios.get(`${window.apiBase}/public/schools/${schoolId}`).then(response => {
    return response.data;
  });
}

export function checkDomainAvailabilty(user, domain) {
  const { roleSessionKey: key } = user;

  const config = {
    headers: { usid: key }
  };

  const data = { domain };

  return axios.post(`${window.apiBase}/i/schools/domains/check`, data, config).then(response => {
    return response.data;
  });
}
