import * as React from 'react';
import * as propz from 'propz';
import { FunctionComponent } from 'react';
import * as Moment from 'moment';
import { SPORT_GENDER_SERVER_TO_CLIENT_MAPPING } from '../../consts/common';
import { AppUser } from 'Src/views/App/App';
import { EVENT_STATUS_SERVER_TO_CLIENT_MAPPING } from '../../consts/event';
import { AGE_GROUPS } from '../../consts/school';
import { SchoolEvent } from '../../models/event';

interface Props {
  participationEvent: SchoolEvent;
  user: AppUser;
}

const ROWS = [
  { title: 'Sport', field: 'sportName' },
  { title: 'Event', field: 'eventName' },
  { title: 'Age', field: 'eventAges' },
  { title: 'Gender', field: 'gender' },
  { title: 'Start time', field: 'startTime' },
  { title: 'End time', field: 'endTime' },
  { title: 'Status', field: 'statusEvent' }
];

export const FixtureParticipatingStudentSummary: FunctionComponent<Props> = props => {
  const { user, participationEvent: event } = props;
  const { ageGroupsNaming } = user.activeSchool;
  const rows = ROWS.map((row, index) => {
    let contentCell: string;
    const field = row.field;

    switch (field) {
      case 'sportName':
        contentCell = propz.get(event, ['sport', 'name'], '');
        break;
      case 'eventName':
        contentCell = propz.get(event, ['generatedNames', user.activeSchoolId], '');
        break;
      case 'eventAges':
        if (typeof event.ages !== 'undefined') {
          const ages = [...event.ages] || [];
          contentCell = ages.map(age => propz.get(AGE_GROUPS, [ageGroupsNaming, age], '')).join(', ');
        } else {
          contentCell = '';
        }
        break;
      case 'gender':
        contentCell = event.gender ? SPORT_GENDER_SERVER_TO_CLIENT_MAPPING[event.gender] : '';
        break;
      case 'startTime':
        contentCell = Moment(event.startTime).format('DD-MM-YYYY HH:mm');
        break;
      case 'endTime':
        contentCell = Moment(event.endTime).format('DD-MM-YYYY HH:mm');
        break;
      case 'statusEvent':
        contentCell = event.status ? EVENT_STATUS_SERVER_TO_CLIENT_MAPPING[event.status] : '';
        break;
      default:
        contentCell = event[row.field];
    }

    return (
      <tr key={`participating_student_summary_${index}`}>
        <td>{row.title}</td>
        <td>{contentCell}</td>
      </tr>
    );
  });

  return (
    <table className={'table table-sm mPre'}>
      <tbody>{rows}</tbody>
    </table>
  );
};

FixtureParticipatingStudentSummary.displayName = 'FixtureParticipatingStudentSummary';
