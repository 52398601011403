import * as React from 'react';
import { Component } from 'react';
import * as BPromise from 'bluebird';
import * as propz from 'propz';
import * as Lazy from 'lazy.js';
import { History, Location } from 'history';
import { AppUser } from '../../../App/App';
import { SchoolEvent } from '../../../../models/event';
import { School } from '../../../../models/school';
import { DEFAULT_RANGE_CALENDAR_YEARS, CALENDAR_FILTER } from '../../../../consts/calendar';
import {
  getFilterStudentEventDatesForMonth,
  getFilterStudentEventsForDay,
  getFilterClubEventDatesForMonth,
  getFilterClubEventsForDay,
  getFilterFixtureEventDatesForMonth,
  getFilterFixtureEventsForDay,
  getFilterTournamentEventDatesForMonth,
  getFilterTournamentEventsForDay,
  getYearRangeArray,
  isAllFilter,
  isClubEventsFilter,
  isSportEventsFilter,
  isTournamentEventsFilter
} from '../../../../helpers/calendar/calendar';
import { getSchools } from 'Src/helpers/service/admin/user';
import { Loader } from '../../../../components/Loader/Loader';
import { ItemsBar } from './ItemsBar';
import { DaysOfWeekBar } from './DaysOfWeekBar';
import { MonthDaysPanel } from './MonthDaysPanel';
import { EventAndTournamentsTable } from './EventAndTournamentsTable';
import { CalendarFilterButtons } from './CalendarFilterButtons';
import { MonthYearSelector } from './MonthYearSelector';
import './Calendar.scss';
import { getStudentEvents, getStudentEventsDates } from '../../../../helpers/service/student/events';

export type FilterType = 'SPORT_EVENTS' | 'CLUB_EVENTS' | 'TOURNAMENT_EVENTS' | 'ALL';

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

interface State {
  monthAndYearDate: Date;
  selectedDate: Date;
  nowDate: Date;
  fixtureEvents: SchoolEvent[];
  clubEvents: SchoolEvent[];
  tournamentEvents: SchoolEvent[];
  dates: Date[];
  activeMonthIndex: number;
  activeYearIndex: number;
  isLoading: boolean;
  filter: FilterType;
  schools: School[];
  schoolIdList: string[];
}

export class StudentCalendar extends Component<Props, State> {
  constructor(props) {
    super(props);

    const now = new Date();
    const nowMonth = now.getMonth();
    const nowYear = now.getFullYear();
    const monthAndYearDate = new Date(nowYear, nowMonth, 1);

    this.state = {
      monthAndYearDate,
      selectedDate: now,
      nowDate: now,
      activeMonthIndex: nowMonth,
      activeYearIndex: DEFAULT_RANGE_CALENDAR_YEARS,
      fixtureEvents: [],
      clubEvents: [],
      tournamentEvents: [],
      dates: [],
      isLoading: false,
      filter: CALENDAR_FILTER.ALL as FilterType,
      schools: [],
      schoolIdList: []
    };
  }

  getEventPromises(filter: FilterType, monthAndYearDate: Date, selectedDate: Date, schoolIdList: string[]) {
    const { user } = this.props;

    const filterEvents = getFilterStudentEventsForDay(selectedDate, schoolIdList);
    const filterEventDates = getFilterStudentEventDatesForMonth(monthAndYearDate, schoolIdList);

    const filterSportEventDates = getFilterFixtureEventDatesForMonth(filterEventDates);
    const filterSportEvents = getFilterFixtureEventsForDay(filterEvents);

    const filterClubEventDates = getFilterClubEventDatesForMonth(filterEventDates);
    const filterClubEvents = getFilterClubEventsForDay(filterEvents);

    const filterTournamentEventDates = getFilterTournamentEventDatesForMonth(filterEventDates);
    const filterTournamentEvents = getFilterTournamentEventsForDay(filterEvents);

    const isAllOrSportEventsFilter = isAllFilter(filter) || isSportEventsFilter(filter);
    const isAllOrClubEventsFilter = isAllFilter(filter) || isClubEventsFilter(filter);
    const isAllOrTournamentEventsFilter = isAllFilter(filter) || isTournamentEventsFilter(filter);

    return [
      isAllOrSportEventsFilter ? getStudentEventsDates(user, filterSportEventDates) : BPromise.resolve({ dates: [] }),
      isAllOrSportEventsFilter ? getStudentEvents(user, filterSportEvents) : BPromise.resolve([]),

      isAllOrClubEventsFilter ? getStudentEventsDates(user, filterClubEventDates) : BPromise.resolve({ dates: [] }),
      isAllOrClubEventsFilter ? getStudentEvents(user, filterClubEvents) : BPromise.resolve([]),

      isAllOrTournamentEventsFilter
        ? getStudentEventsDates(user, filterTournamentEventDates)
        : BPromise.resolve({ dates: [] }),
      isAllOrTournamentEventsFilter ? getStudentEvents(user, filterTournamentEvents) : BPromise.resolve([])
    ];
  }

  componentDidMount() {
    const { monthAndYearDate, selectedDate, filter } = this.state;
    const { user, location } = this.props;
    const { state } = location;

    const filterFromHistory = propz.get(state, ['filter']);
    const selectedDateFromHistory = propz.get(state, ['selectedDate']);
    const isFilterFromHistoryExist = typeof filterFromHistory !== 'undefined';
    const isSelectedDateFromHistoryExist = typeof selectedDateFromHistory !== 'undefined';

    const currentFilter = isFilterFromHistoryExist ? filterFromHistory : filter;
    const currentDate = isSelectedDateFromHistoryExist ? selectedDateFromHistory : selectedDate;

    const now = new Date(currentDate);
    const nowMonth = now.getMonth();
    const nowYear = now.getFullYear();
    const currentMonthAndYearDate = new Date(nowYear, nowMonth, 1);

    this.setState({
      isLoading: true
    });

    let schoolIdList;
    let schools;

    getSchools(user)
      .then(_schools => {
        schools = _schools;
        schoolIdList = schools.map(school => school.id);
        const promises = [...this.getEventPromises(currentFilter, currentMonthAndYearDate, now, schoolIdList)];
        return BPromise.all(promises);
      })
      .then(
        ([fixtureEventDates, fixtureEvents, clubEventDates, clubEvents, tournamentEventDates, tournamentEvents]) => {
          const dates = [...fixtureEventDates.dates, ...clubEventDates.dates, ...tournamentEventDates.dates];

          const datesUniq: string[] = Lazy(dates)
            .uniq()
            .toArray();

          this.setState({
            isLoading: false,
            dates: datesUniq.map(date => new Date(date)),
            fixtureEvents,
            clubEvents,
            tournamentEvents,
            schools,
            schoolIdList,
            filter: currentFilter,
            selectedDate: now,
            monthAndYearDate: currentMonthAndYearDate,
            activeMonthIndex: nowMonth
          });
        }
      );
  }

  onNextMonthClick = (): void => {
    const { monthAndYearDate, activeYearIndex, filter, selectedDate, schoolIdList } = this.state;
    const nowMonth = monthAndYearDate.getMonth();
    const nextMonth = new Date(monthAndYearDate);
    nextMonth.setMonth(nowMonth + 1);
    const nextMonthIndex = nextMonth.getMonth();

    const promises = this.getEventPromises(filter, nextMonth, selectedDate, schoolIdList);

    this.setState({
      isLoading: true
    });

    if (nowMonth === 11) {
      if (activeYearIndex === DEFAULT_RANGE_CALENDAR_YEARS * 2) {
        this.setState({
          isLoading: false
        });
        return;
      }

      BPromise.all(promises).then(
        ([fixtureEventDates, fixtureEvents, clubEventDates, clubEvents, tournamentEventDates, tournamentEvents]) => {
          const dates = [...fixtureEventDates.dates, ...clubEventDates.dates, ...tournamentEventDates.dates];
          const datesUniq: string[] = Lazy(dates)
            .uniq()
            .toArray();
          this.setState({
            isLoading: false,
            dates: datesUniq.map(date => new Date(date)),
            monthAndYearDate: nextMonth,
            activeMonthIndex: nextMonthIndex,
            activeYearIndex: activeYearIndex + 1
          });
        }
      );
    } else {
      BPromise.all(promises).then(
        ([fixtureEventDates, fixtureEvents, clubEventDates, clubEvents, tournamentEventDates, tournamentEvents]) => {
          const dates = [...fixtureEventDates.dates, ...clubEventDates.dates, ...tournamentEventDates.dates];
          const datesUniq: string[] = Lazy(dates)
            .uniq()
            .toArray();
          this.setState({
            isLoading: false,
            dates: datesUniq.map(date => new Date(date)),
            monthAndYearDate: nextMonth,
            activeMonthIndex: nextMonthIndex
          });
        }
      );
    }
  };
  onPreviousMonthClick = (): void => {
    const { monthAndYearDate, activeYearIndex, filter, selectedDate, schoolIdList } = this.state;
    const nowMonth = monthAndYearDate.getMonth();
    const previousMonth = new Date(monthAndYearDate);
    previousMonth.setMonth(nowMonth - 1);
    const previousMonthIndex = previousMonth.getMonth();

    const promises = this.getEventPromises(filter, previousMonth, selectedDate, schoolIdList);

    this.setState({
      isLoading: true
    });

    if (nowMonth === 0) {
      if (activeYearIndex === 0) {
        this.setState({
          isLoading: false
        });
        return;
      }

      BPromise.all(promises).then(
        ([fixtureEventDates, fixtureEvents, clubEventDates, clubEvents, tournamentEventDates, tournamentEvents]) => {
          const dates = [...fixtureEventDates.dates, ...clubEventDates.dates, ...tournamentEventDates.dates];
          const datesUniq: string[] = Lazy(dates)
            .uniq()
            .toArray();
          this.setState({
            isLoading: false,
            dates: datesUniq.map(date => new Date(date)),
            monthAndYearDate: previousMonth,
            activeMonthIndex: previousMonthIndex,
            activeYearIndex: activeYearIndex - 1
          });
        }
      );
    } else {
      BPromise.all(promises).then(
        ([fixtureEventDates, fixtureEvents, clubEventDates, clubEvents, tournamentEventDates, tournamentEvents]) => {
          const dates = [...fixtureEventDates.dates, ...clubEventDates.dates, ...tournamentEventDates.dates];
          const datesUniq: string[] = Lazy(dates)
            .uniq()
            .toArray();
          this.setState({
            isLoading: false,
            dates: datesUniq.map(date => new Date(date)),
            monthAndYearDate: previousMonth,
            activeMonthIndex: previousMonthIndex
          });
        }
      );
    }
  };

  onMonthChange = (value: number): void => {
    const { monthAndYearDate, filter, selectedDate, schoolIdList } = this.state;
    const nextMonth = new Date(monthAndYearDate);
    nextMonth.setMonth(value);

    const promises = this.getEventPromises(filter, nextMonth, selectedDate, schoolIdList);

    this.setState({
      isLoading: true
    });

    BPromise.all(promises).then(
      ([fixtureEventDates, fixtureEvents, clubEventDates, clubEvents, tournamentEventDates, tournamentEvents]) => {
        const dates = [...fixtureEventDates.dates, ...clubEventDates.dates, ...tournamentEventDates.dates];
        const datesUniq: string[] = Lazy(dates)
          .uniq()
          .toArray();
        this.setState({
          isLoading: false,
          dates: datesUniq.map(date => new Date(date)),
          monthAndYearDate: nextMonth,
          activeMonthIndex: Number(value)
        });
      }
    );
  };

  onYearChange = (value: number): void => {
    const { monthAndYearDate, filter, selectedDate, schoolIdList } = this.state;
    const nextYear = new Date(monthAndYearDate);
    nextYear.setFullYear(value);

    const promises = this.getEventPromises(filter, nextYear, selectedDate, schoolIdList);

    const yearRangeArray = getYearRangeArray();
    const activeYearIndex = yearRangeArray.findIndex(year => year === Number(value));

    this.setState({
      isLoading: true
    });

    BPromise.all(promises).then(
      ([fixtureEventDates, fixtureEvents, clubEventDates, clubEvents, tournamentEventDates, tournamentEvents]) => {
        const dates = [...fixtureEventDates.dates, ...clubEventDates.dates, ...tournamentEventDates.dates];
        const datesUniq: string[] = Lazy(dates)
          .uniq()
          .toArray();
        this.setState({
          isLoading: false,
          dates: datesUniq.map(date => new Date(date)),
          monthAndYearDate: nextYear,
          activeYearIndex: activeYearIndex
        });
      }
    );
  };

  onDayClick = (date: Date): void => {
    const { filter, monthAndYearDate, schoolIdList } = this.state;
    this.setState({
      isLoading: true
    });

    const promises = this.getEventPromises(filter, monthAndYearDate, date, schoolIdList);

    BPromise.all(promises).then(
      ([fixtureEventDates, fixtureEvents, clubEventDates, clubEvents, tournamentEventDates, tournamentEvents]) => {
        this.setState({
          selectedDate: date,
          isLoading: false,
          fixtureEvents,
          clubEvents,
          tournamentEvents
        });
      }
    );
  };

  onFilterButtonClick = (filter: FilterType) => {
    const { monthAndYearDate, selectedDate, schoolIdList } = this.state;

    this.setState({
      isLoading: true
    });

    const promises = this.getEventPromises(filter, monthAndYearDate, selectedDate, schoolIdList);

    BPromise.all(promises).then(
      ([fixtureEventDates, fixtureEvents, clubEventDates, clubEvents, tournamentEventDates, tournamentEvents]) => {
        const dates = [...fixtureEventDates.dates, ...clubEventDates.dates, ...tournamentEventDates.dates];
        const datesUniq: string[] = Lazy(dates)
          .uniq()
          .toArray();
        this.setState({
          isLoading: false,
          dates: datesUniq.map(date => new Date(date)),
          fixtureEvents,
          clubEvents,
          tournamentEvents,
          filter
        });
      }
    );
  };

  onChildClick = (schoolId: string) => {
    this.setState({
      isLoading: true
    });

    let schoolIdList;

    if (schoolId === 'all') {
      const { schools } = this.state;
      schoolIdList = schools.map(school => school.id);
    } else {
      schoolIdList = [schoolId];
    }

    const { filter, monthAndYearDate, selectedDate } = this.state;

    const promises = this.getEventPromises(filter, monthAndYearDate, selectedDate, schoolIdList);

    BPromise.all(promises).then(
      ([fixtureEventDates, fixtureEvents, clubEventDates, clubEvents, tournamentEventDates, tournamentEvents]) => {
        const dates = [...fixtureEventDates.dates, ...clubEventDates.dates, ...tournamentEventDates.dates];
        const datesUniq: string[] = Lazy(dates)
          .uniq()
          .toArray();

        this.setState({
          isLoading: false,
          dates: datesUniq.map(date => new Date(date)),
          fixtureEvents,
          clubEvents,
          tournamentEvents,
          filter,
          schoolIdList
        });
      }
    );
  };

  onEventClick = (eventId: string): void => {
    const { filter } = this.state;
    const { location } = this.props;
    const { pathname } = location;

    this.props.history.push({
      pathname: '/events/event',
      search: `id=${eventId}`,
      state: {
        filter,
        prevRoutePath: pathname
      }
    });
  };

  getSchoolName = (school: School) => {
    return school.name;
  };

  render() {
    const {
      activeMonthIndex,
      activeYearIndex,
      selectedDate,
      monthAndYearDate,
      nowDate,
      isLoading,
      fixtureEvents,
      clubEvents,
      tournamentEvents,
      dates,
      filter,
      schools,
      schoolIdList
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const { user } = this.props;

    const events = [...fixtureEvents, ...clubEvents, ...tournamentEvents];
    const eventsSorted = events.sort((event1, event2) => {
      const eventStartTimeDate1 = new Date(event1.startTime);
      const eventStartTimeDate2 = new Date(event2.startTime);
      return Number(eventStartTimeDate1) - Number(eventStartTimeDate2);
    });
    return (
      <div className={'mt-3'}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <ItemsBar
                items={schools}
                allItemsTitle={'All schools'}
                itemIdList={schoolIdList}
                onClick={this.onChildClick}
                getItemName={this.getSchoolName}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 d-flex justify-content-start">
              <div>
                <CalendarFilterButtons user={user} filter={filter} onFilterButtonClick={this.onFilterButtonClick} />
              </div>
            </div>

            <div className="col-auto">
              <div className="eEventAndTournamentsCalendarSection">
                <MonthYearSelector
                  activeMonthIndex={activeMonthIndex}
                  activeYearIndex={activeYearIndex}
                  onPreviousMonthClick={this.onPreviousMonthClick}
                  onNextMonthClick={this.onNextMonthClick}
                  onMonthChange={this.onMonthChange}
                  onYearChange={this.onYearChange}
                />
                <DaysOfWeekBar />
                <MonthDaysPanel
                  onClick={this.onDayClick}
                  selectedDate={selectedDate}
                  monthAndYearDate={monthAndYearDate}
                  nowDate={nowDate}
                  eventDates={dates}
                />
              </div>
            </div>
            <div className="col">
              <div className="eEventAndTournamentsTableSection">
                <EventAndTournamentsTable user={user} events={eventsSorted} onEventClick={this.onEventClick} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
