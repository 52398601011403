import * as React from 'react';
import { TEAM_TYPE_TYPE } from '../../../../../../types/tournamentTeam';
import { GENDER_TYPE } from '../../../../../../types/common';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import * as propz from 'propz';
import { checkAvailabilityTeamName } from '../../../../../../helpers/service/admin/schoolTeams';
import { AppUser } from '../../../../../App/App';

interface Props {
  teamsAdhoc: TeamAdhoc[];
  user: AppUser;
  onSubmit: (data: any) => void;
}

interface TeamAdhoc {
  ages: number[];
  gender: GENDER_TYPE;
  name: string;
  players: Player[];
  schoolId: string;
  sportId: string;
  teamType: TEAM_TYPE_TYPE;
  houseId?: string;
}

interface Player {
  userId: string;
  permissionId: string;
}

export function SchoolEventSaveTeamsForm(props: Props) {
  const { teamsAdhoc, user, onSubmit } = props;

  const initialValues = {
    teams: teamsAdhoc.map(teamAdhoc => ({
      name: teamAdhoc.name,
      isSave: false
    }))
  };

  const sportId = propz.get(teamsAdhoc, [0, 'sportId']);

  const SchoolEventTeamsSchema = Yup.object().shape({
    teams: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .required('Required')
          .test(
            'name',
            'This team name has already been used for your chosen sport, please use a different name',
            function(value) {
              const isSave = propz.get(this.parent, ['isSave']);
              switch (true) {
                case !value:
                  return false;
                case isSave === false:
                  return true;
                default:
                  return checkAvailabilityTeamName(user, { name: value, sportId: sportId }).then(res => {
                    return res.isAvailable;
                  });
              }
            }
          ),
        isSave: Yup.boolean()
      })
    )
  });

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik
            initialValues={initialValues}
            validationSchema={SchoolEventTeamsSchema}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={values => {
              const valuesUpdated = values.teams.map((team, index) => ({
                ...teamsAdhoc[index],
                ...team
              }));
              onSubmit(valuesUpdated);
            }}
            render={({ setFieldValue, values, touched, errors }) => (
              <Form>
                <div className="font-weight-bold mb-2">You have created a new team/teams. Please select one of the following options:</div>
                {values.teams.map((team, index) => {
                  return (
                    <div key={`team_${index}`}>
                      <div className="form-group">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            id={`team_isSave_positive_${index}`}
                            checked={team.isSave}
                            onChange={() => {
                              setFieldValue(`teams.${index}.isSave`, true);
                            }}
                          />
                          <label className="form-check-label pl-3 mb-2 mt-1" htmlFor={`team_isSave_positive_${index}`}>
                            Save as new team
                          </label>
                        </div>

                        <Field name={`teams.${index}.name`} className="form-control mb-3" />
                        <ErrorMessage
                          component="div"
                          className="alert alert-danger mt-3"
                          name={`teams.${index}.name`}
                        />
                      </div>
                      <div className="form-group">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            id={`team_isSave_negative_${index}`}
                            checked={!team.isSave}
                            onChange={() => {
                              setFieldValue(`teams.${index}.isSave`, false);
                            }}
                          />
                          <label className="form-check-label pl-3 mb-2 mt-1" htmlFor={`team_isSave_negative_${index}`}>
                            Use team only for this event
                          </label>
                        </div>
                      </div>
                      {index < values.teams.length - 1 && <div className="dropdown-divider" />}
                    </div>
                  );
                })}
                <button type="submit" className="mt-3 mb-3 btn btn-primary">
                  Save
                </button>
              </Form>
            )}
          />
        </div>
      </div>
    </div>
  );
}
