import { DEFAULT_FILTER } from './user';
import { AppUser } from 'Src/views/App/App';
import axios from 'axios';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from 'Src/consts/table';
import { Tag } from '../../../models/tag';
import { Count } from '../../../models/count';

/** USER TAG TABLE */
export function getUserTags(user: AppUser, filter = DEFAULT_FILTER): Promise<Tag[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter
    }
  };

  return axios.get<Tag[]>(`${window.apiBase}/i/schools/${activeSchoolId}/userTags`, config).then(response => {
    return response.data;
  });
}

export function getAllUserTags(user: AppUser, where?): Promise<Tag[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios.get<Tag[]>(`${window.apiBase}/i/schools/${activeSchoolId}/userTags`, config).then(response => {
    return response.data;
  });
}

export function getUserTagsCount(user: AppUser, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where
        }
      }
    };
  } else {
    config = {
      headers: { usid: key }
    };
  }

  return axios.get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/userTags/count`, config).then(response => {
    return response.data;
  });
}

export function createUserTag(user: AppUser, data) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.post(`${window.apiBase}/i/schools/${activeSchoolId}/userTags`, data, config).then(response => {
    return response.data;
  });
}

export function updateUserTag(user: AppUser, userTagId, data) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .put(`${window.apiBase}/i/schools/${activeSchoolId}/userTags/${userTagId}`, data, config)
    .then(response => {
      return response.data;
    });
}

export function deleteUserTag(user: AppUser, userTagId) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.delete(`${window.apiBase}/i/schools/${activeSchoolId}/userTags/${userTagId}`, config).then(response => {
    return response.data;
  });
}
