import * as React from 'react';
import * as propz from 'propz';
import { Field, Form, Formik } from 'formik';
import DatePicker from 'react-datepicker';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from '../../../../../../../consts/report';
import { Club } from '../../../../../../../models/club';
import { getClubStartDateWithoutFormatting } from '../../../../../../../helpers/accessor/accessor';
import { Switch } from '../../../../../../../components/Switch/Switch';
import { LabelWithQuestionIcon } from '../../../../../../../components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import '../../Clubs.css';

interface Props {
  onCloseClick: () => void;
  onSubmit: (data: any, reportType: string) => void;
  club: Club;
}

// report 013
export function ClubBookingsReportForm(props: Props) {
  const { club } = props;

  const requestInitial = {
    isPrintMedicalInfo: false,
    isPrintNextOfKin: false,
    isPrintPriority: true,
    isShowAccepted: true,
    isShowRejected: false,
    isShowPending: false
  };

  return (
    <Formik
      onSubmit={values => {
        props.onSubmit(values, REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.CLUB_BOOKINGS);
      }}
      initialValues={requestInitial}
      render={({ values, setFieldValue }) => (
        <Form>
          <div className="bReportFormTitle">Club booking summary</div>
          <div className="mb-3 mt-3">
            {'This report will enable you to view the booking responses for a specific club, ' +
              'select if you would like to see all responses (accepted, rejected, pending) ' +
              'or just a status of your choosing. View all information from students name to medical information ' +
              'as well as their priority status for the club.'}
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isPrintMedicalInfo"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintMedicalInfo}
                    onChange={event => {
                      const isPrintMedicalInfo = event.target.checked;
                      setFieldValue('isPrintMedicalInfo', isPrintMedicalInfo);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show Medical Info"
                hintText={'Show the medical information linked to specific students.'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintMedicalInfo"
              />
            </div>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isPrintNextOfKin"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintNextOfKin}
                    onChange={event => {
                      const isPrintNextOfKin = event.target.checked;
                      setFieldValue('isPrintNextOfKin', isPrintNextOfKin);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show Next of Kin"
                hintText={'Show next of kin details linked to specific students.'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintNextOfKin"
              />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isPrintPriority"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintPriority}
                    onChange={event => {
                      const isPrintPriority = event.target.checked;
                      setFieldValue('isPrintPriority', isPrintPriority);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show Priority Option"
                hintText={'Show selected priority at the time of booking.'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintPriority"
              />
            </div>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isShowAccepted"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowAccepted}
                    onChange={event => {
                      const isShowAccepted = event.target.checked;
                      setFieldValue('isShowAccepted', isShowAccepted);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show Accepted Bookings"
                hintText={'Show booking forms with an accepted status.'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowAccepted"
              />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isShowRejected"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowRejected}
                    onChange={event => {
                      const isShowRejected = event.target.checked;
                      setFieldValue('isShowRejected', isShowRejected);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show Rejected Bookings"
                hintText={'Show booking forms with a rejected status.'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowRejected"
              />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isShowPending"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowPending}
                    onChange={event => {
                      const isShowPending = event.target.checked;
                      setFieldValue('isShowPending', isShowPending);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show Pending Invites"
                hintText={'Show booking forms that have not been responded to yet.'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowPending"
              />
            </div>
          </div>

          <button type="button" className="btn btn-secondary mr-3" onClick={props.onCloseClick}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Get
          </button>
        </Form>
      )}
    />
  );
}
