import * as React from 'react';
import { PermissionSelector } from '../PermissionSelector/PermissionSelector';
import { AppUser } from 'Src/views/App/App';
import { Role } from '../../models/roles';
import { Permission } from '../../models/permission';
import { isUserParent } from '../../helpers/user/user';
import { Avatar } from '../Avatar/Avatar';
import { BasketIcon } from 'Src/components/SvgIcons/BasketIcon';

interface Props {
  user: AppUser;
  onLogoutClick: (event) => void;
  onRoleChange: (
    roleName: string,
    permissionSchoolId: string,
    permissionSchoolName: string,
    permission: Permission
  ) => any;
  history: any;
  availableRoles: Role[];
  basketOrdersCount: string;
}

export const TopNavigation: React.FunctionComponent<Props> = props => {
  const { user, basketOrdersCount, history } = props;
  const isParent = isUserParent(user);

  const onBasketClick = () => {
    history.push({
      pathname: `/basket`
    });
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light d-flex justify-content-between">
      <a className="navbar-brand" href="" onClick={event => event.preventDefault()}>
        <img src="/dist/images/logo.svg" className="d-inline-block align-top" alt="" />
      </a>
      <div className="d-flex align-items-center">
        {isParent && (
          <div className="mr-4">
            <BasketIcon basketOrdersCount={basketOrdersCount} onClick={onBasketClick} />
          </div>
        )}

        <PermissionSelector {...props} />
      </div>
    </nav>
  );
};

TopNavigation.displayName = 'TopNavigation';
