import { parseDomainName } from '../api/api';
import * as propz from 'propz';

export const TV_PREFIX = 'tv_';

export function getDomainParts() {
  return document.location.host.split('.').slice();
}

export function getProtocol() {
  return `${document.location.protocol}//`;
}

export function getTournamentPublicSiteLink(tournament): string {
  const tournamentDomain = propz.get(tournament, ['domain'], '');
  const domainParts = getDomainParts();

  if (typeof tournamentDomain !== 'undefined') {
    domainParts.splice(0, 1, tournamentDomain);
    return getProtocol() + domainParts.join('.');
  } else {
    return '';
  }
}

export function getTournamentTVPublicSiteLink(tournament): string {
  const tournamentDomain = propz.get(tournament, ['domain'], '');
  const domainParts = getDomainParts();

  if (typeof tournamentDomain !== 'undefined') {
    domainParts.splice(0, 1, tournamentDomain);
    return getProtocol() + TV_PREFIX + domainParts.join('.');
  } else {
    return '';
  }
}

export function getSUPublicSiteTournamentPage(tournament, user): string {
  const activeSchoolDomain = user.activeSchool.domain;
  const domainParts = getDomainParts();

  if (typeof activeSchoolDomain !== 'undefined' && activeSchoolDomain !== '') {
    domainParts.splice(0, 1, activeSchoolDomain);
    return getProtocol() + domainParts.join('.') + '/tournament/' + tournament.id;
  } else {
    return '';
  }
}

export function getIntegrationCalendarPublicSiteLink(integration) {
  const integrationUrl = integration.url;
  const domainParts = getDomainParts();

  if (typeof integrationUrl !== 'undefined' && integrationUrl !== '') {
    domainParts.splice(0, 1, 'calendar');
    return `http://${domainParts.join('.')}${integrationUrl}`;
  } else {
    return '';
  }
}

export function getFileName(file) {
  const fileUrl = file.url;
  const fileUrlArray = fileUrl.split('/');
  let fileName = '';

  if (fileUrlArray.length > 0) {
    fileName = fileUrlArray[fileUrlArray.length - 1];
  }

  return fileName;
}

export function getSchoolLeaguePublicSiteLink(league): string {
  const leagueDomain = league.domain;

  if (typeof leagueDomain === 'undefined') {
    return '';
  }

  const hostname = document.location.hostname;

  const hostnameParsed = parseDomainName(hostname);
  const { env } = hostnameParsed;

  const isLocal = env === 'local';

  const isLeagueDomainExist = typeof leagueDomain !== 'undefined';
  let domainParts = getDomainParts();
  domainParts.splice(0, 1, leagueDomain);
  const protocol = getProtocol();
  const domainPartsJoined = domainParts.join('.');

  switch (true) {
    case isLeagueDomainExist && isLocal:
      //replace port for local dev
      const domainPartsReplaced = domainPartsJoined.replace('5000', '8080');
      return `${protocol}${domainPartsReplaced}`;
    default:
      return `${protocol}${domainPartsJoined}`;
  }
}

export function getSchoolPublicSiteLink(domain) {
  const hostname = document.location.hostname;

  const hostnameParsed = parseDomainName(hostname);
  const { env } = hostnameParsed;

  const isLocal = env === 'local';

  let domainParts = getDomainParts();
  domainParts.splice(0, 1, domain);
  const protocol = getProtocol();
  const domainPartsJoined = domainParts.join('.');

  if (isLocal) {
    const domainPartsReplaced = domainPartsJoined.replace('5000', '8080');
    return {
      link: `${protocol}${domainPartsReplaced}`,
      linkToShow: `${domainPartsReplaced}`,
      bigScreenLink: `${protocol}bigscreen_${domainPartsReplaced}`,
      bigScreenLinkToShow: `bigscreen_${domainPartsReplaced}`
    };
  }

  return {
    link: `${protocol}${domainPartsJoined}`,
    linkToShow: `${domainPartsJoined}`,
    bigScreenLink: `${protocol}bigscreen_${domainPartsJoined}`,
    bigScreenLinkToShow: `bigscreen_${domainPartsJoined}`
  };
}

//  Get new subdomain name
//  @param {string} domainName - domain name, for ex. 'greatwalstead'
//  @return {string} new subdomain name, for ex. 'greatwalstead.squard.com:8080'
export function getSubDomain(domainName: string): string {
  const subdomains = document.location.host.split('.');
  subdomains[0] = domainName;
  return subdomains.join('.');
}
