import { AppUser } from 'Src/views/App/App';
import axios from 'axios';

export function getSettingsUploadFiles(user: AppUser): Promise<number> {
  const { roleSessionKey: key } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.get<number>(`${window.apiBase}/i/settings`, config).then(response => {
    return response.data;
  });
}
