import * as React from 'react';
import * as propz from 'propz';
import { Field, Form, Formik } from 'formik';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from '../../../../../../consts/report';
import { Tournament } from '../../../../../../models/tournament';
import { Switch } from '../../../../../../components/Switch/Switch';
import { LabelWithQuestionIcon } from '../../../../../../components/LabelWithQuestionIcon/LabelWithQuestionIcon';

interface Props {
  onCloseClick: () => void;
  onSubmit: (data: any, reportType: string) => void;
  tournament: Tournament;
}

// report 040
export function TournamentPlayersReportForm(props: Props) {
  const { tournament } = props;

  const requestInitial = {
    isShowBlank: true
  };

  return (
    <Formik
      onSubmit={values => {
        props.onSubmit(values, REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.TOURNAMENT_PLAYERS);
      }}
      initialValues={requestInitial}
      render={({ values, setFieldValue }) => (
        <Form>
          <div className="bReportFormTitle">Tournament players</div>
          <div className="mb-3 mt-3">
            {'View the full list of students involved in a selected tournament as well as details ' +
              ' of those who have been added to the tournament teams and/or events.'}
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isShowBlank"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowBlank}
                    onChange={event => {
                      const isShowBlank = event.target.checked;
                      setFieldValue('isShowBlank', isShowBlank);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show empty entries"
                hintText={'Show schools/teams that have not yet added participants to the teams and events.'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowBlank"
              />
            </div>
          </div>
          <button type="button" className="btn btn-secondary mr-3" onClick={props.onCloseClick}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Get
          </button>
        </Form>
      )}
    />
  );
}
