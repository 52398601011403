import * as React from 'react';
import { Component } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Button } from 'Src/components/Button/Button';
import './AddPhotoForm.scss';

interface Props {
  onCancel: () => void;
  onSubmit: (picUrl: string) => void;
}

interface State {
  src: any;
  crop: {
    unit: any;
    width: any;
    height: any;
    x: any;
    y: any;
  };
  imageBlob: any;
  uploadedImageName: string;
}
// copy-paste from old frontend
export class AddPhotoForm extends Component<Props, State> {
  private imageRef: React.RefObject<HTMLInputElement>;
  private fileUrl: any;

  constructor(props) {
    super(props);
    this.imageRef = React.createRef();
    this.state = {
      src: '',
      crop: {
        unit: '%',
        width: 100,
        height: 35,
        x: 0,
        y: 35
      },
      imageBlob: '',
      uploadedImageName: ''
    };
  }

  onSubmitClick = imageBlob => {
    const { onSubmit } = this.props;

    onSubmit(imageBlob);
  };

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const { name, type } = e.target.files[0];
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({
          src: reader.result,
          uploadedImageName: name
        })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  makeClientCrop(crop) {
    const { uploadedImageName } = this.state;

    if (this.imageRef && crop.width && crop.height) {
      this.getCroppedImg(this.imageRef, crop, uploadedImageName).then(imageBlob => {
        this.setState({
          imageBlob
        });
      });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    let fileType;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob: any) => {
          if (!blob) {
            console.error('Canvas is empty');
            return;
          }
          const fileNameParts = fileName.split('.');
          fileType = fileNameParts[fileNameParts.length - 1];
          blob.name = fileName;
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          resolve(blob);
        },
        `image/${fileType}`,
        1
      );
    });
  }

  render() {
    const { crop, src, imageBlob } = this.state;
    const { onCancel } = this.props;

    return (
      <div className="App">
        <div>
          <label className="btn btn-light">
            Select your image
            <input
              type="file"
              accept="image/*"
              name="image"
              className="bCustomFileInput"
              onChange={this.onSelectFile}
            />
          </label>
        </div>
        {src && (
          <ReactCrop
            src={src}
            crop={crop}
            ruleOfThirds
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
            className="mt-3"
          />
        )}
        <div className="d-flex mt-3">
          {src && <Button onClick={() => this.onSubmitClick(imageBlob)} text={'Save'} customClass="mr-3" />}
          <Button onClick={onCancel} text={'Cancel'} customClass={'btn-secondary'} />
        </div>
      </div>
    );
  }
}
