import * as React from 'react';
import { DragSource } from 'react-dnd';
import { TournamentGridShortTeamData } from './TournamentGrid';

const cardSource = {
  beginDrag(props) {
    return {
      teamId: props.team.teamId
    };
  }
};

interface TeamCardProps {
  team: TournamentGridShortTeamData;

  // Injected by React DnD:
  isDragging: boolean;
  connectDragSource: (any) => {};
}

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

class TeamCard extends React.Component<TeamCardProps> {
  readonly SCHOOL_PICTURE_HEIGHT = 40;

  render() {
    const { teamName, schoolPic, schoolName, groupResults } = this.props.team;

    const { isDragging, connectDragSource } = this.props;

    const style = {
      backgroundImage: `url(${schoolPic}?sizing=height&height=${this.SCHOOL_PICTURE_HEIGHT}`
    };

    return connectDragSource(
      <div className={'eTeamCard'} style={{ opacity: isDragging ? 0.5 : 1 }}>
        <div className={'eTeamCardSchoolPicWrapper '}>
          {schoolPic && <div className={'eTeamCardSchoolPic'} style={style} />}
        </div>
        <div className={'eTeamCardTeamName'}>{`${teamName}, ${schoolName} \n${groupResults}`}</div>
      </div>
    );
  }
}

export default DragSource('teamCard', cardSource, collect)(TeamCard);
