import * as React from 'react';
import * as propz from 'propz';
import { Field, Form, Formik } from 'formik';
import DatePicker from 'react-datepicker';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from '../../../../../../../consts/report';
import { Switch } from '../../../../../../../components/Switch/Switch';
import { getStartDateForSchoolYear } from '../../../../../../../helpers/calendar/calendar';
import { Team } from 'Src/models/team';
import { LabelWithQuestionIcon } from '../../../../../../../components/LabelWithQuestionIcon/LabelWithQuestionIcon';

interface Props {
  onCloseClick: () => void;
  onSubmit: (data: any, reportType: string) => void;
  team: Team;
}

// report 027
export function TeamPerformanceReportForm(props: Props) {
  const now = new Date();
  let dateFrom = getStartDateForSchoolYear(now);
  const { team } = props;

  const requestInitial = {
    dateFrom,
    dateTo: now,
    isShowUnclosed: true
  };

  return (
    <Formik
      onSubmit={values => {
        props.onSubmit(values, REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.TEAM_PERFORMANCE);
      }}
      initialValues={requestInitial}
      render={({ values, setFieldValue }) => (
        <Form>
          <div className="bReportFormTitle">Team performance/schedule</div>
          <div className="mb-3 mt-3">
            {'View the team performance over the period as well as upcoming fixture schedule.'}
          </div>

          <div className="form-group">
            <label>Date from</label>
            <Field name="dateFrom">
              {({ field, form }) => (
                <div className="mb-3">
                  <DatePicker
                    {...field}
                    selected={field.value}
                    onChange={date => {
                      form.setFieldValue('dateFrom', date);
                      if (form.values.dateTo && form.values.dateTo < date) {
                        form.setFieldValue('dateTo', date);
                      }
                    }}
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
              )}
            </Field>
          </div>
          <div className="form-group">
            <label>Date to</label>
            <Field name="dateTo">
              {({ field, form }) => (
                <div className="mb-3">
                  <DatePicker
                    {...field}
                    selected={field.value}
                    onChange={date => {
                      form.setFieldValue('dateTo', date);
                    }}
                    minDate={form.values.dateFrom}
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
              )}
            </Field>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isShowUnclosed"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowUnclosed}
                    onChange={event => {
                      const isShowUnclosed = event.target.checked;
                      setFieldValue('isShowUnclosed', isShowUnclosed);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show Unclosed Events"
                hintText={'Include events that have not been closed yet.'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowUnclosed"
              />
            </div>
          </div>

          <button type="button" className="btn btn-secondary mr-3" onClick={props.onCloseClick}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Get
          </button>
        </Form>
      )}
    />
  );
}
