import * as React from 'react';
import * as propz from 'propz';
import { ConfirmationRequestTemplateField } from 'Src/models/confirmationRequestTemplate';
import { QUESTIONS_TEMPLATE_TYPE } from 'Src/consts/message';
import { getConfirmationTemplateQuestionsFieldType } from 'Src/helpers/select/select';
import { SelectField } from 'Src/components/SelectField/SelectField';
import { CrossIcon } from 'Src/components/SvgIcons/CrossIcon';
import { Switch } from 'Src/components/Switch/Switch';
import './QuestionsTemplateField.scss';

interface Props {
  field: ConfirmationRequestTemplateField;
  onChange: (fieldId: string, objectKey: string, value: any) => void;
  onRemove: (fieldId: string) => void;
}

export const QuestionsTemplateField = (props: Props) => {
  const { field, onChange, onRemove } = props;

  const id = propz.get(field, ['_id']);
  const heading = propz.get(field, ['heading'], '');
  const isDefault = propz.get(field, ['isDefault'], false);
  const isRequired = propz.get(field, ['isRequired'], false);
  const type = propz.get(field, ['type']);
  const enumOptions = propz.get(field, ['enumOptions'], []);
  const enumOptionsFormatted = enumOptions.join(', ');

  const fieldObjectKeys = {
    heading: 'heading',
    isDefault: 'isDefault',
    isRequired: 'isRequired',
    type: 'type',
    enumOptions: 'enumOptions'
  };

  const isEnumType = type === QUESTIONS_TEMPLATE_TYPE.ENUM;

  return (
    <div className="bQuestionsTemplateField">
      <div className="mb-3">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <p className="eQuestionsTemplateFieldTitle">Heading</p>

          <div onClick={() => onRemove(id)}>
            <CrossIcon />
          </div>
        </div>

        <input
          type="text"
          value={heading}
          onChange={event => onChange(id, fieldObjectKeys.heading, event.target.value)}
          className="form-control"
        />
      </div>

      <div className="d-flex justify-content-between align-items-center mb-3">
        <div>
          <p className="eQuestionsTemplateFieldTitle mb-2">Is required</p>
          <Switch
            value={isRequired}
            onChange={event => {
              onChange(id, fieldObjectKeys.isRequired, event.target.checked);
            }}
            text={''}
            name={`${id}_isRequired`}
          />
        </div>

        <div>
          <p className="eQuestionsTemplateFieldTitle mb-2">Is default</p>
          <Switch
            value={isDefault}
            onChange={event => {
              onChange(id, fieldObjectKeys.isDefault, event.target.checked);
            }}
            text={''}
            name={`${id}_isDefault`}
          />
        </div>
      </div>

      <div className="mb-3">
        <p className="eQuestionsTemplateFieldTitle mb-2">Type</p>

        <SelectField
          items={getConfirmationTemplateQuestionsFieldType()}
          field={''}
          onChange={event => onChange(id, fieldObjectKeys.type, event.target.value)}
          value={QUESTIONS_TEMPLATE_TYPE[type]}
          customClass="mMaxWidthNone w-100"
        />
      </div>

      <div>
        <p className="eQuestionsTemplateFieldTitle mb-2">Options, separated by commas (only for enum)</p>

        <input
          type="text"
          value={enumOptionsFormatted}
          onChange={event => {
            const value = event.target.value.split(', ');

            onChange(id, fieldObjectKeys.enumOptions, value);
          }}
          className="form-control"
          disabled={!isEnumType}
        />
      </div>
    </div>
  );
};

QuestionsTemplateField.displayName = 'QuestionsTemplateField';
