import * as React from 'react';
import { FunctionComponent } from 'react';
import * as propz from 'propz';
import * as Moment from 'moment';
import { MessageDefinition } from '../../types/message';
import { TournamentConfirmationRequestMessage } from 'Src/models/message';
import { MESSAGE_KIND_SERVER_TO_CLIENT_MAPPING } from '../../consts/message';

interface Props {
  message: MessageDefinition;
}

const ROWS = [
  { title: 'Type', field: 'type' },
  { title: 'Created at', field: 'createdAt' },
  { title: 'Sender', field: 'sender' },
  { title: 'Tournament', field: 'name' },
  { title: 'Start', field: 'startTime' },
  { title: 'End', field: 'endTime' }
];

export const TournamentConfirmationRequestSummary: FunctionComponent<Props> = props => {
  const { message } = props;

  const rows = ROWS.map((row, index) => {
    let contentCell: string;
    const field = row.field;

    switch (field) {
      case 'type':
        contentCell = MESSAGE_KIND_SERVER_TO_CLIENT_MAPPING[message.kind];
        break;
      case 'createdAt':
        contentCell = message[row.field] ? Moment(message[row.field]).format('DD-MM-YYYY HH:mm') : '';
        break;
      case 'sender':
        contentCell = propz.get(message, ['sender', 'fullName'], '');
        break;
      case 'name':
        contentCell = propz.get(message, ['tournamentData', 'name'], '');
        break;
      case 'startTime':
        const startTime = propz.get(message, ['tournamentData', 'startTime'], '');
        contentCell = Moment(startTime).format('DD-MM-YYYY HH:mm');
        break;
      case 'endTime':
        const endTime = propz.get(message, ['tournamentData', 'endTime'], '');
        contentCell = Moment(endTime).format('DD-MM-YYYY HH:mm');
        break;

      default:
        contentCell = message[row.field];
    }

    return (
      <tr key={`message_summary_${index}`}>
        <td>{row.title}</td>
        <td>{contentCell}</td>
      </tr>
    );
  });

  return (
    <div>
      <table className={'table table-sm mPre'}>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
};

TournamentConfirmationRequestSummary.displayName = 'TournamentConfirmationRequestSummary';
