import { SelectOption } from '../table/table';
import {
  INVITE_STATUS,
  INVITE_STATUS_NOT_SENT,
  INVITE_STATUS_NOT_SENT_MAPPING,
  INVITE_STATUS_SERVER_TO_CLIENT_MAPPING
} from '../../consts/invite';

export function getSelectOptionForTournamentParticipantsInviteStatus(): SelectOption[] {
  return [
    {
      text: INVITE_STATUS_SERVER_TO_CLIENT_MAPPING.ACCEPTED,
      value: INVITE_STATUS.ACCEPTED
    },
    {
      text: INVITE_STATUS_SERVER_TO_CLIENT_MAPPING.REJECTED,
      value: INVITE_STATUS.REJECTED
    },
    {
      text: INVITE_STATUS_SERVER_TO_CLIENT_MAPPING.CANCELED,
      value: INVITE_STATUS.CANCELED
    },
    {
      text: INVITE_STATUS_SERVER_TO_CLIENT_MAPPING.NOT_READY,
      value: INVITE_STATUS.NOT_READY
    },
    {
      text: INVITE_STATUS_SERVER_TO_CLIENT_MAPPING.OUTDATED,
      value: INVITE_STATUS.OUTDATED
    },
    {
      text: INVITE_STATUS_NOT_SENT_MAPPING,
      value: INVITE_STATUS_NOT_SENT
    }
  ];
}
