export const ROLE_SERVER_TO_CLIENT_MAPPING = {
  ADMIN: 'Admin',
  MANAGER: 'Manager',
  PARENT: 'Parent',
  STUDENT: 'Student',
  TOURNAMENT_FOLLOWER: 'Tournament follower',
  TEACHER: 'Teacher',
  COACH: 'Coach',
  GOVERNOR: 'Trustee/Governor'
};

export const ROLE = {
  MANAGER: 'MANAGER',
  PARENT: 'PARENT',
  STUDENT: 'STUDENT',
  TOURNAMENT_FOLLOWER: 'TOURNAMENT_FOLLOWER',
  TEACHER: 'TEACHER',
  COACH: 'COACH',
  ADMIN: 'ADMIN',
  GOVERNOR: 'GOVERNOR'
};

export const ROLE_PUBLIC_BLOGGER = 'PUBLIC_BLOGGER';

export const ALL_SCHOOL_ROLES_LIST = ['ADMIN', 'MANAGER', 'TEACHER', 'COACH', 'PARENT', 'STUDENT', 'GOVERNOR'];
export const ALL_SCHOOL_ROLES_ADDING_LIST = ['ADMIN', 'TEACHER', 'COACH', 'PARENT', 'STUDENT'];

export const STAFF_LIST = ['ADMIN', 'MANAGER', 'TEACHER', 'COACH'];

export const USER_GENDER = {
  MALE: 'MALE',
  FEMALE: 'FEMALE'
};

export const SPORT_GENDER_TO_USER_GENDER_MAPPING = {
  MALE_ONLY: 'MALE',
  FEMALE_ONLY: 'FEMALE'
};

export const GENDER_SERVER_TO_CLIENT_MAPPING = {
  MALE: 'Male',
  FEMALE: 'Female'
};

export const USER_GENDER_NOT_DEFINED = null;

export const MAX_USER_PRIORITY = 10;
export const MIN_USER_PRIORITY = 1;

export const GENDER_NOT_DEFINED_SERVER_TO_CLIENT_MAPPING = {
  NULL: 'Not defined'
};

export const USER_STATUSES = ['ACTIVE', 'BLOCKED', 'REMOVED', 'WIPED'];

export const USER_STATUSES_TO_CLIENT_MAPPING = {
  ACTIVE: 'Active',
  BLOCKED: 'Inactive',
  REMOVED: 'Removed',
  WIPED: 'Wiped'
};

export const USER_STATUS = {
  ACTIVE: 'ACTIVE',
  BLOCKED: 'BLOCKED',
  REMOVED: 'REMOVED',
  WIPED: 'WIPED'
};
