import * as React from 'react';
import * as propz from 'propz';
import * as Moment from 'moment';
import { BasketItem } from 'Src/models/basket';
import { WEEK_DAYS_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/date';
import { DeleteIcon } from 'Src/components/SvgIcons/DeleteIcon';
import './BasketOrder.scss';

interface Props {
  order: BasketItem;
  onDeleteOrderFromBasketClick: (orderId: string) => void;
}

export const BasketOrder = (props: Props) => {
  const { order, onDeleteOrderFromBasketClick } = props;

  const orderId = propz.get(order, ['id'], '');

  const childAvatar = propz.get(order, ['studentAvatar'], '');
  const isChildAvatarExist = childAvatar !== '';
  const childFirstName = propz.get(order, ['studentFirstName'], '');
  const childLastName = propz.get(order, ['studentLastName'], '');
  const childFullName = `${childFirstName} ${childLastName}`;
  const childInitials = `${childFirstName[0]}`;

  const schoolPic = propz.get(order, ['schoolPic'], '');
  const isSchoolPicExist = schoolPic !== '';

  const clubName = propz.get(order, ['clubName'], '');

  const clubStartDate = propz.get(order, ['clubStartDate'], '');
  const clubFinishDate = propz.get(order, ['clubFinishDate'], '');
  const сlubStartTime = propz.get(order, ['ClubStartTime'], '');
  const clubStartDateFormatted = Moment(clubStartDate).format('DD/MM/YYYY');
  const clubFinishDateFormatted = Moment(clubFinishDate).format('DD/MM/YYYY');
  const clubStartTimeFormatted = Moment(сlubStartTime).format('hh:mm');

  const price = propz.get(order, ['price']);

  const deadline = propz.get(order, ['deadline'], '');

  const deadlineHours = new Date(deadline).getHours();
  const deadlineMinutes = new Date(deadline).getMinutes();
  const deadlineMinutesFormatted = deadlineMinutes < 10 ? `0${deadlineMinutes}` : deadlineMinutes;

  const deadlineTime = `${deadlineHours}:${deadlineMinutesFormatted}`;
  const deadlineDate = Moment(deadline).format('DD/MM/YYYY');

  const renderWeekDays = () => {
    const clubDays = propz.get(order, ['clubDays'], []);

    return clubDays.reduce((result, day, index) => {
      const currentday = index + 1;
      const clubDaysCount = clubDays.length;

      switch (true) {
        case currentday === clubDaysCount: {
          return result + ` ${WEEK_DAYS_SERVER_TO_CLIENT_MAPPING[day]}`;
        }

        case clubDaysCount === 1: {
          return result + WEEK_DAYS_SERVER_TO_CLIENT_MAPPING[day];
        }

        default: {
          return result + `, ${WEEK_DAYS_SERVER_TO_CLIENT_MAPPING[day]}`;
        }
      }
    }, '');
  };

  const renderDate = () => {
    const weekdays = renderWeekDays();

    return `${clubStartDateFormatted} - ${clubFinishDateFormatted}, ${weekdays}, ${clubStartTimeFormatted}`;
  };

  return (
    <div className="bBasketOrder">
      <div className="eBasketOrderDeleteIcon">
        <DeleteIcon onClick={() => onDeleteOrderFromBasketClick(orderId)} />
      </div>

      <div className="col-12 mt-2 mt-sm-0 px-0">
        <div className="eBasketOrderChildName">
          <div className="eBasketOrderChildAvatarWrapper">
            {isChildAvatarExist ? (
              <div style={{ backgroundImage: `url(${childAvatar})` }} className="eBasketOrderChildAvatar"></div>
            ) : (
              <span className="eBasketOrderChildInitials">{childInitials}</span>
            )}

            {isSchoolPicExist && (
              <div className="eBasketOrderSchoolLogo">
                <img src={schoolPic} alt="School logo" />
              </div>
            )}
          </div>

          {`Child: ${childFullName}`}
        </div>

        <div className="eBasketOrderInfo">
          <p className="mb-0">{clubName}</p>
          <p>{renderDate()}</p>
        </div>

        <div className="row m-0">
          <p className="col-10 text-danger px-0">
            <span>
              Please checkout before <span className="font-weight-bold">{`${deadlineTime} on ${deadlineDate}`}</span> to
              guarantee your booking as club spaces are limited
            </span>
          </p>
          <p className="col-2 px-0 font-weight-bold text-right">{`£${price}`}</p>
        </div>
      </div>
    </div>
  );
};

BasketOrder.displayName = 'BasketOrder';
