import * as React from 'react';
import { AdminBoardGalleryItem } from './ArbiterBoard';
import { GALLERY_ITEM_PHOTO } from '../../../../../../consts/arbiterBoard';
import { ArbiterBoardGalleryItemPhoto } from './ArbiterBoardGalleryItemPhoto';
import { ArbiterBoardGalleryItemVideo } from './ArbiterBoardGalleryItemVideo';

interface Props {
  items: AdminBoardGalleryItem[];
  onClick: (id: string) => void;
  selectedItemIndex: number;
  windowWidth: number;
}

export function ArbiterBoardGalleryItemsStrip(props: Props) {
  const { items, onClick } = props;

  return (
    <div className="bArbiterBoardGallery">
      <div className="bArbiterBoardPhotos">
        <div className="eArbiterBoardPhotoStrip">
          {items.map(item => {
            const isItemPhoto = item.type === GALLERY_ITEM_PHOTO;
            return isItemPhoto ? (
              <ArbiterBoardGalleryItemPhoto onClick={onClick} key={item.id} item={item} />
            ) : (
              <ArbiterBoardGalleryItemVideo onClick={onClick} key={item.id} item={item} />
            );
          })}
        </div>
      </div>
    </div>
  );
}
