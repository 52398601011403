import * as React from 'react';
import { Route, Switch } from 'react-router';
import { EmptyComponent } from 'Src/components/EmptyComponent/EmptyComponent';
import { AdminSUInvitesInbox } from './InvitesInbox';
import { AdminSUInvitesOutbox } from './InvitesOutbox';
import { AdminSUInvitesArchive } from './InvitesArchive';

export const InvitesRouting = allProps => {
  return (
    <Switch>
      <Route exact path={'/invites'} render={props => <EmptyComponent />} />
      <Route exact path={'/invites/inbox'} render={props => <AdminSUInvitesInbox {...props} user={allProps.user} />} />
      <Route
        exact
        path={'/invites/outbox'}
        render={props => <AdminSUInvitesOutbox {...props} user={allProps.user} />}
      />
      <Route
        exact
        path={'/invites/archive'}
        render={props => <AdminSUInvitesArchive {...props} user={allProps.user} />}
      />
    </Switch>
  );
};

InvitesRouting.displayName = 'InvitesRouting';
