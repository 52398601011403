import { AppUser } from 'Src/views/App/App';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from 'Src/consts/table';
import axios from 'axios';

export function getAllEventPhotos(user: AppUser, eventId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        limit: DEFAULT_LIMIT,
        skip: DEFAULT_SKIP
      }
    }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/photos`, config).then(response => {
    return response.data;
  });
}

export function addEventPhoto(user: AppUser, eventId: string, data) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/photos`, data, config)
    .then(response => {
      return response.data;
    });
}

export function updateEventPhoto(user: AppUser, eventId: string, photoId: string, data) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .put(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/photos/${photoId}`, data, config)
    .then(response => {
      return response.data;
    });
}

export function deleteEventPhoto(user: AppUser, eventId: string, photoId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .delete(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/photos/${photoId}`, config)
    .then(response => {
      return response.data;
    });
}
