import * as React from 'react';
import { Role } from '../../models/roles';
import { ROLE } from '../../consts/user';
import { KIND } from '../../consts/school';
import { Button } from '../Button/Button';
import { History, Location } from 'history';

interface Props {
  availableRoles: Role[];
  history: History;
  location: Location;
  roleOut: () => void;
}

export function TournamentWarning(props: Props) {
  const { availableRoles, history, roleOut, location } = props;

  const isShowWarning = availableRoles.some(role =>
    role.permissions.some(permission => {
      const { preset, school } = permission;
      const isAdmin = preset === ROLE.ADMIN;
      const isCoach = preset === ROLE.COACH;
      const isTeacher = preset === ROLE.TEACHER;
      const isManager = preset === ROLE.MANAGER;

      const roleCondition = isAdmin || isCoach || isTeacher || isManager;

      const { kind } = school;
      const isSchool = kind === KIND.SCHOOL;

      return roleCondition && isSchool;
    })
  );

  return (
    <div>
      The role you are currently logged in with Squad in Touch does not allow you to book a space in the event you
      requested.{' '}
      {isShowWarning && (
        <div>
          <div>Please select an appropriate role to continue.</div>
          <div>
            <Button
              onClick={() => {
                roleOut();
                history.push({
                  pathname: '/role',
                  state: location.state
                });
              }}
              text={'Select role'}
              customClass={'mt-3 mb-3'}
            />
          </div>
        </div>
      )}
    </div>
  );
}
