import * as React from 'react';
import {FunctionComponent} from 'react';


import './GeneralMessagePreview.css';
import {GeneralMessagePreviewEmail} from "./GeneralMessagePreviewEmail";
import {GeneralMessagePreviewPush} from "./GeneralMessagePreviewPush";
import {GeneralMessagePreviewSms} from "./GeneralMessagePreviewSms";
import { Attachment } from "../../models/generalMessage";
import { GeneralMessagePreviewTabs } from "./GeneralMessagePreviewTabs";
import { AffectedUserList } from "../GeneralMessages/AffectedUserList/AffectedUserList";

interface Props {
	messageSmsText: string
	messageEmailSubject: string
	messageEmailBody: string
	messagePushTitle: string
	messagePushBody: string
	messageAttachments: Attachment[]
	affectedUsers?: any[]
}

export const GeneralMessagePreviewWithTabs: FunctionComponent<Props> = (props) => {

	return (
		<div className="container-fluid">
			<div className='bGeneralMessagePreview row'>
				<div className="col-md-6">
					<GeneralMessagePreviewTabs tabs={ [ 'Email preview', 'Push preview', 'Sms preview' ] }>
						{/*email preview*/ }
						<GeneralMessagePreviewEmail
							messageEmailSubject={ props.messageEmailSubject }
							messageEmailBody={ props.messageEmailBody }
							messageAttachments={ props.messageAttachments }
						/>

						{/*push preview*/ }
						<GeneralMessagePreviewPush
							messagePushTitle={ props.messagePushTitle }
							messagePushBody={ props.messagePushBody }
						/>

						{/*text preview*/ }
						<GeneralMessagePreviewSms
							messageSmsText={ props.messageSmsText }
						/>
					</GeneralMessagePreviewTabs>
				</div>
				<div className="col-md-6 overflow-auto">
          <AffectedUserList
            affectedUsers = { props.affectedUsers }
            isEmailExist = { Boolean(props.messageEmailBody) || Boolean(props.messageEmailSubject) }
            isPushExist = { Boolean(props.messagePushTitle) || Boolean(props.messagePushBody) }
            isSmsExist = { Boolean(props.messageSmsText) }
          />
				</div>
			</div>
		</div>
	)
};

GeneralMessagePreviewWithTabs.displayName = 'GeneralMessagePreviewWithTabs';