import * as React from 'react';
import * as propz from 'propz';
import './NotificationSettings.scss';
import { History, Location } from 'history';
import { getNotificationSettings, updateNotificationSettings } from 'Src/helpers/service/adminSU/notificationSettings';
import { AppUser } from 'Src/views/App/App';
import { Loader } from 'Src/components/Loader/Loader';
import { Button } from 'Src/components/Button/Button';
import { Switch } from 'Src/components/Switch/Switch';
import { NOTIFICATION_STATUS_TYPE, NOTIFICATION_TYPE } from 'Src/consts/notifications';
import { Form, Formik } from 'formik';

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

interface State {
  isLoading: boolean;
  notificationSettings: {
    type: string;
    status: 'AUTO' | 'DISABLED';
  }[];
  isEditMode: boolean;
}

const CURRENT_NOTIFICATION_TYPE_ARRAY = [
  NOTIFICATION_TYPE.SCHOOL_TOURNAMENT_INVITE_CREATED,
  NOTIFICATION_TYPE.SCHOOL_ADDED_TO_TOURNAMENT_PARTICIPANT,
  NOTIFICATION_TYPE.SCHOOL_ADDED_TO_TOURNAMENT_WAITING_LIST,
  NOTIFICATION_TYPE.SCHOOL_REMOVED_FROM_TOURNAMENT,
  NOTIFICATION_TYPE.TOURNAMENT_CUSTOM,
  NOTIFICATION_TYPE.TOURNAMENT_DETAILS_UPDATED,
  NOTIFICATION_TYPE.CONFIRMATION_REQUEST_CREATED,
  NOTIFICATION_TYPE.TOURNAMENT_PARTICIPANT_ADDED,
  NOTIFICATION_TYPE.PARTICIPANT_ADDED_TO_TOURNAMENT_WAITING_LIST,
  NOTIFICATION_TYPE.TOURNAMENT_PARTICIPANT_REMOVED,
  NOTIFICATION_TYPE.NEW_RECORD_ADDED
];

const SETTING_DESTINATIONS_TYPE = {
  SCHOOL_NOTIFICATIONS: 'SCHOOL_NOTIFICATIONS',
  UNION_NOTIFICATIONS: 'UNION_NOTIFICATIONS'
};

const SETTINGS_DESIGNATIONS = [
  { type: SETTING_DESTINATIONS_TYPE.SCHOOL_NOTIFICATIONS, name: 'Notifications sent to school admins' },
  { type: SETTING_DESTINATIONS_TYPE.UNION_NOTIFICATIONS, name: 'Notifications sent to union admins' }
];

const SETTINGS = [
  // Notifications sent to school admins
  {
    type: NOTIFICATION_TYPE.SCHOOL_TOURNAMENT_INVITE_CREATED,
    relation: SETTING_DESTINATIONS_TYPE.SCHOOL_NOTIFICATIONS,
    name: 'Tournament invite has been created',
    hintText:
      'Sent to: School admins\n' +
      'Sent after a user clicks the "Book now" or "Book onto WL" button, ' +
      ' or when a tournament invitation is issued by a union admin.'
  },
  {
    type: NOTIFICATION_TYPE.SCHOOL_ADDED_TO_TOURNAMENT_PARTICIPANT,
    relation: SETTING_DESTINATIONS_TYPE.SCHOOL_NOTIFICATIONS,
    name: 'School has been added as a tournament participant',
    hintText:
      'Sent to: School admins\n' +
      'Sent once a school is added to the tournament participant list, ' +
      ' whether through booking or direct addition by a union admin.'
  },
  {
    type: NOTIFICATION_TYPE.SCHOOL_ADDED_TO_TOURNAMENT_WAITING_LIST,
    relation: SETTING_DESTINATIONS_TYPE.SCHOOL_NOTIFICATIONS,
    name: 'School has been added to a tournament waiting list',
    hintText: 'Sent to: School admins\n' + 'Sent when a school is added to the tournament waiting list.'
  },
  {
    type: NOTIFICATION_TYPE.SCHOOL_REMOVED_FROM_TOURNAMENT,
    relation: SETTING_DESTINATIONS_TYPE.SCHOOL_NOTIFICATIONS,
    name: 'School has been removed from a tournament',
    hintText:
      'Sent to: School admins\n' +
      'Sent after a school is removed from the tournament, either through ' +
      ' the Leave the tournament action or by direct removal by a union admin.'
  },
  {
    type: NOTIFICATION_TYPE.TOURNAMENT_CUSTOM,
    relation: SETTING_DESTINATIONS_TYPE.SCHOOL_NOTIFICATIONS,
    name: 'Custom notification',
    hintText: 'Sent to: School admins\n' + 'Sent to selected schools by a union admin.'
  },
  {
    type: NOTIFICATION_TYPE.TOURNAMENT_DETAILS_UPDATED,
    relation: SETTING_DESTINATIONS_TYPE.SCHOOL_NOTIFICATIONS,
    name: 'Tournament details have been updated',
    hintText:
      'Sent to: School admins\n' +
      'Sent when a union admin updates the tournament details, including ' +
      ' changes to the date, timing, or venue.'
  },
  {
    type: NOTIFICATION_TYPE.CONFIRMATION_REQUEST_CREATED,
    relation: SETTING_DESTINATIONS_TYPE.SCHOOL_NOTIFICATIONS,
    name: 'Confirmation request has been received',
    hintText:
      'Sent to: School admins\n' +
      'Sent when a union admin sends a tournament participation ' +
      ' confirmation request.'
  },
  // Notifications sent to union admins

  {
    type: NOTIFICATION_TYPE.TOURNAMENT_PARTICIPANT_ADDED,
    relation: SETTING_DESTINATIONS_TYPE.UNION_NOTIFICATIONS,
    name: 'School has been added to the tournament participant list',
    hintText:
      'Sent to: Union admins\n' +
      'Sent once a school is added to the tournament participant list, ' +
      ' whether through booking or direct addition by a union admin.'
  },
  {
    type: NOTIFICATION_TYPE.PARTICIPANT_ADDED_TO_TOURNAMENT_WAITING_LIST,
    relation: SETTING_DESTINATIONS_TYPE.UNION_NOTIFICATIONS,
    name: 'School has been added to a tournament waiting list',
    hintText: 'Sent to: Union admins\n' + 'Sent when a school is added to the tournament waiting list.'
  },
  {
    type: NOTIFICATION_TYPE.TOURNAMENT_PARTICIPANT_REMOVED,
    relation: SETTING_DESTINATIONS_TYPE.UNION_NOTIFICATIONS,
    name: 'School has been removed from a tournament',
    hintText:
      'Sent to: Union admins\n' +
      'Sent after a school is removed from the tournament, either through ' +
      ' the Leave the tournament action or by direct removal by a union admin.'
  },
  {
    type: NOTIFICATION_TYPE.NEW_RECORD_ADDED,
    relation: SETTING_DESTINATIONS_TYPE.UNION_NOTIFICATIONS,
    name: 'New record has been added',
    hintText: 'Sent to: Union admins\n' + 'Sent after a new record is added either automatically or manually.'
  }
];

export class UnionNotificationSettings extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
      notificationSettings: [],
      isEditMode: false
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });
    this.getNotificationSettingsFromNotificationSettingsObject();
  }

  getNotificationSettingsFromNotificationSettingsObject = (): void => {
    const user = this.props.user;

    getNotificationSettings(user).then(notificationSettingsObject => {
      const notificationSettings = this.getArrayFromObjectDesiredValues(notificationSettingsObject);
      this.setState({
        notificationSettings: notificationSettings,
        isLoading: false
      });
    });
  };

  getArrayFromObjectDesiredValues = (object: { key: string }): { type: string; status: 'AUTO' | 'DISABLED' }[] => {
    const arrayWithData = [];
    const copyObject = { ...object };

    for (let key in copyObject) {
      const existType = CURRENT_NOTIFICATION_TYPE_ARRAY.find(type => type === key);
      if (typeof existType !== 'undefined') {
        arrayWithData.push({
          type: key,
          status: copyObject[key]
        });
      }
    }
    return arrayWithData;
  };

  getNotificationSettingsObjectFromNotificationSettings = (
    arrayWithData: { type: string; status: 'AUTO' | 'DISABLED' }[]
  ): { key: string } | {} => {
    const objectDate = {};
    arrayWithData.forEach(date => {
      objectDate[date.type] = date.status;
    });
    return objectDate;
  };

  getNotificationStatus = (status: boolean): string => {
    return status ? NOTIFICATION_STATUS_TYPE.AUTO : NOTIFICATION_STATUS_TYPE.DISABLED;
  };

  getReverseStatus = (status: string): string => {
    return status === NOTIFICATION_STATUS_TYPE.AUTO ? NOTIFICATION_STATUS_TYPE.DISABLED : NOTIFICATION_STATUS_TYPE.AUTO;
  };

  getIsStatusAuto = (status: string): boolean => {
    return status === NOTIFICATION_STATUS_TYPE.AUTO;
  };

  onCloseClick = (): void => {
    this.setState({
      isEditMode: false,
      isLoading: true
    });
    this.getNotificationSettingsFromNotificationSettingsObject();
  };

  onFormSubmit = (values): void => {
    const { user } = this.props;
    const { isEditMode } = this.state;

    if (isEditMode) {
      this.setState({
        isLoading: true
      });

      const notificationSettings = this.getNotificationSettingsObjectFromNotificationSettings(values.allSettings);

      const data = {
        ...notificationSettings
      };

      updateNotificationSettings(user, data).then(objectNotificationSettings => {
        const arrayNotificationSettings = this.getArrayFromObjectDesiredValues(objectNotificationSettings);
        this.setState({
          notificationSettings: arrayNotificationSettings,
          isEditMode: false,
          isLoading: false
        });
      });
    } else {
      this.setState({
        isEditMode: true
      });
    }
  };

  onAllNotificationsChange = (setFieldValue, values): void => {
    const { isAllNotifications } = values;
    const changedAllSettings = values.allSettings.map(setting => {
      const { status, ...rest } = setting;
      return {
        ...rest,
        status: this.getNotificationStatus(!isAllNotifications)
      };
    });
    setFieldValue('isAllNotifications', !isAllNotifications);
    setFieldValue('isSchoolNotifications', !isAllNotifications);
    setFieldValue('isUnionNotifications', !isAllNotifications);
    setFieldValue('allSettings', changedAllSettings);
  };

  onGroupSettingsChange = (
    values,
    setFieldValue,
    checkType: string,
    groupSetting: { type: string; name: string }
  ): void => {
    const copyAllSettings = [...values.allSettings];
    const settings = SETTINGS.filter(setting => setting.relation === groupSetting.type);
    const checkedSettings = [...copyAllSettings].filter(adjustment => {
      return settings.some(setting => setting.type === adjustment.type);
    });

    const checkedCopySettings = copyAllSettings.map(copySetting => {
      const isExistSetting = checkedSettings.some(checkedSetting => checkedSetting.type === copySetting.type);
      if (isExistSetting) {
        const checkedSetting = { ...copySetting };
        checkedSetting.status = this.getNotificationStatus(!values[checkType]);
        return checkedSetting;
      } else {
        return copySetting;
      }
    });
    const isAutoAllSettings = this.getAllNotificationsStatus(checkedCopySettings);
    setFieldValue('isAllNotifications', isAutoAllSettings);
    setFieldValue('allSettings', checkedCopySettings);
    setFieldValue(checkType, !values[checkType]);
  };

  getCheckType = (type: string): string => {
    switch (type) {
      case SETTING_DESTINATIONS_TYPE.SCHOOL_NOTIFICATIONS:
        return 'isSchoolNotifications';
      case SETTING_DESTINATIONS_TYPE.UNION_NOTIFICATIONS:
        return 'isUnionNotifications';
    }
  };

  getAllNotificationsStatus = (notificationSettings: { type: string; status: 'AUTO' | 'DISABLED' }[]): boolean => {
    const isAutoAllSettings = [...notificationSettings].every(
      setting => setting.status === NOTIFICATION_STATUS_TYPE.AUTO
    );
    return isAutoAllSettings;
  };

  getGroupSettingStatus = (
    notificationSettings: { type: string; status: 'AUTO' | 'DISABLED' }[],
    type: string
  ): boolean => {
    const copyAllSettings = [...notificationSettings];
    const settings = [...SETTINGS].filter(setting => setting.relation === type);
    const someSettings = [...copyAllSettings].filter(adjustment => {
      return settings.some(setting => setting.type === adjustment.type);
    });
    const isAutoCurrentSettings = someSettings.every(setting => setting.status === NOTIFICATION_STATUS_TYPE.AUTO);

    return isAutoCurrentSettings;
  };

  renderGroupSettingsRow = (setting: { type: string; name: string }, values, setFieldValue): React.ReactNode => {
    const { isEditMode } = this.state;
    const checkType = this.getCheckType(setting.type);
    const isChecked = values[checkType];
    return (
      <div>
        <div className="eNotificationSettingRow">
          <p className="h5 ml-2 mt-2 mb-2" style={{ width: '60%' }}>
            <strong>{setting.name}</strong>
          </p>
          <div className="ml-3" style={{ width: '15%' }}>
            <img
              style={{ width: '20px' }}
              src="/dist/images/questionMark.png"
              title={
                'This will turn on / off all notification types within this section. Notifications are sent via email as well as ' +
                'push notification as long as the users have the Squad in Touch app and push notifications are turned on in their settings.'
              }
              alt={'question icon'}
            />
          </div>
          <div className="d-flex align-content-center" style={{ width: '25%' }}>
            <p className="h5 mb-0 mr-4">
              <strong>Off</strong>
            </p>
            <Switch
              key={checkType}
              value={isChecked}
              onChange={() => this.onGroupSettingsChange(values, setFieldValue, checkType, setting)}
              text={''}
              name={checkType}
              disabled={!isEditMode}
            />
            <p className="h5 mb-0 ml-3">
              <strong>On</strong>
            </p>
          </div>
        </div>
        {this.renderSettingsRow(setting, values, setFieldValue)}
      </div>
    );
  };

  renderSettingsRow = (designationSetting: { type: string; name: string }, values, setFieldValue): React.ReactNode => {
    const { isEditMode } = this.state;
    const settings = [...SETTINGS];

    const necessarySettings = values.allSettings.filter(necessarySetting => {
      return settings.some(list => list.type === necessarySetting.type && list.relation === designationSetting.type);
    });
    return necessarySettings.map(necessarySetting => {
      const listMenu = settings.find(row => necessarySetting.type === row.type);
      const settingName = typeof listMenu !== 'undefined' ? listMenu.name : '';
      const hintText = typeof listMenu !== 'undefined' ? listMenu.hintText : '';

      return (
        <div key={`setting_type_${necessarySetting.type}`} className="eNotificationSettingRow">
          <p className="h5 ml-2 mt-2 mb-2" style={{ width: '60%' }}>
            {settingName}
          </p>
          <div className="ml-3" style={{ width: '15%' }}>
            <img style={{ width: '20px' }} src="/dist/images/questionMark.png" title={hintText} alt={'question icon'} />
          </div>
          <div className="d-flex align-content-center" style={{ width: '25%' }}>
            <p className="h5 mb-0 mr-4">Off</p>
            <Switch
              key={necessarySetting.type}
              value={this.getIsStatusAuto(necessarySetting.status)}
              onChange={() => {
                const allSettings = [...values.allSettings];
                const currentSettingIndex = allSettings.findIndex(setting => setting.type === necessarySetting.type);
                const isExistSetting = currentSettingIndex !== -1;
                if (isExistSetting) {
                  const currentSetting = { ...necessarySetting };
                  currentSetting.status = this.getReverseStatus(necessarySetting.status);
                  allSettings.splice(currentSettingIndex, 1, currentSetting);
                  setFieldValue('allSettings', allSettings);

                  const isAutoAllSettings = this.getAllNotificationsStatus(allSettings);
                  setFieldValue('isAllNotifications', isAutoAllSettings);

                  const currentSettingRelation = [...SETTINGS].find(setting => setting.type === currentSetting.type);

                  if (typeof currentSettingRelation !== 'undefined') {
                    const isCheckedGroupSettings = this.getGroupSettingStatus(
                      allSettings,
                      currentSettingRelation.relation
                    );

                    const checkType = this.getCheckType(currentSettingRelation.relation);
                    setFieldValue(checkType, isCheckedGroupSettings);
                  }
                }
              }}
              text={''}
              name={necessarySetting.type}
              disabled={!isEditMode}
            />
            <p className="h5 mb-0 ml-3">On</p>
          </div>
        </div>
      );
    });
  };

  renderSettingsList = (values, setFieldValue): React.ReactNode => {
    const settingsDesignations = [...SETTINGS_DESIGNATIONS];
    return settingsDesignations.map((setting, index) => {
      const isShowLastEmptyRow = settingsDesignations.length - 1 !== index;
      return (
        <div key={`${setting.type}`}>
          {this.renderGroupSettingsRow(setting, values, setFieldValue)}
          {isShowLastEmptyRow && <div className="eNotificationSettingEmptyRow" />}
        </div>
      );
    });
  };

  render() {
    const { notificationSettings, isLoading, isEditMode } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const buttonText = isEditMode ? 'Save' : 'Edit';

    const notificationSettingsForm = {
      allSettings: notificationSettings,
      isSchoolNotifications: this.getGroupSettingStatus(
        notificationSettings,
        SETTING_DESTINATIONS_TYPE.SCHOOL_NOTIFICATIONS
      ),
      isUnionNotifications: this.getGroupSettingStatus(
        notificationSettings,
        SETTING_DESTINATIONS_TYPE.UNION_NOTIFICATIONS
      ),
      isAllNotifications: this.getAllNotificationsStatus(notificationSettings)
    };
    return (
      <Formik initialValues={notificationSettingsForm} onSubmit={this.onFormSubmit}>
        {({ setFieldValue, values }) => (
          <Form>
            <div className={'bGenericView'}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 eNotificationSettings">
                    <div className={''}>
                      <Button
                        text={'Cancel'}
                        disabled={!isEditMode}
                        onClick={() => {
                          this.onCloseClick();
                        }}
                        customClass={'mt-3 mb-3 mr-3 btn-secondary'}
                      />
                      <button type="submit" className="btn btn-primary mt-3 mb-3">
                        {buttonText}
                      </button>
                    </div>
                    <div className="">
                      <div className="eNotificationSettingHead">
                        <p className="h5 ml-3 mt-2 mb-2" style={{ width: '80%' }}>
                          <strong>Notification</strong>
                        </p>
                        <p className="h5 d-flex" style={{ width: '20%' }}>
                          <strong>Status</strong>
                        </p>
                      </div>
                      <div className="eNotificationSettingRow">
                        <p className="h3 ml-2 mt-2 mb-2" style={{ width: '75%' }}>
                          <strong>All notifications</strong>
                        </p>
                        <div className="ml-3 d-flex align-content-center" style={{ width: '25%' }}>
                          <p className="h5 mb-0 mr-4">
                            <strong>Off</strong>
                          </p>
                          <Switch
                            key={'isAllNotifications'}
                            value={values.isAllNotifications}
                            onChange={() => this.onAllNotificationsChange(setFieldValue, values)}
                            text={''}
                            name="isAllNotifications"
                            disabled={!isEditMode}
                          />
                          <p className="h5 mb-0 ml-3">
                            <strong>On</strong>
                          </p>
                        </div>
                      </div>
                      {this.renderSettingsList(values, setFieldValue)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}
