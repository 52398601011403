import * as React from 'react';
import { MessageDefinition } from '../../types/message';
import { MESSAGE_KIND } from '../../consts/message';
import * as Moment from 'moment';
import {
  ABSENCE_MESSAGE_INTERVAL_SERVER_TO_CLIENT_MAPPING,
  ABSENCE_MESSAGE_REASON_SERVER_TO_CLIENT_MAPPING
} from '../../consts/absenceMessage';
import {
  AbsenceMessage,
  ClubParticipantInviteMessage,
  EventInvitationMessage,
  EventParticipationMessage,
  TournamentConfirmationRequestMessage
} from '../../models/message';
import { QUESTIONS_TEMPLATE_TYPE } from 'Src/consts/message';
import { DATE_FORMAT } from '../../consts/date';
import { convertBooleanToYesNo } from 'Src/helpers/accessor/accessor';

interface Props {
  message: MessageDefinition;
}

interface Row {
  title: string;
  field: string;
}

const AbsenceMessageRows: Row[] = [
  { title: 'Date absence', field: 'dateAbsence' },
  { title: 'Interval absence', field: 'intervalAbsence' },
  { title: 'Reason absence', field: 'reasonAbsence' },
  { title: 'Details', field: 'details' },
  { title: 'School comment', field: 'authorizedComment' }
];

const EventParticipationMessageRows: Row[] = [
  { title: 'Take part', field: 'isTakePart' },
  { title: 'Details', field: 'details' }
];

function getValueConverted(type, value): any {
  switch (type) {
    case QUESTIONS_TEMPLATE_TYPE.NUMBER:
    case QUESTIONS_TEMPLATE_TYPE.STRING:
    case QUESTIONS_TEMPLATE_TYPE.ENUM:
      return value;
    case QUESTIONS_TEMPLATE_TYPE.BOOLEAN:
      return convertBooleanToYesNo(value);
  }
}

function renderRowsTournamentConfirmationRequestMessage(
  message: TournamentConfirmationRequestMessage
): React.ReactNode {
  const { fields } = message;
  return fields.map((field, index) => {
    const { heading, value, type } = field;

    return (
      <tr key={`tournament_confirmation_request_${index}`}>
        <td className={'w-50'}>{heading}</td>
        <td className={'w-50 pl-5'}>{getValueConverted(type, value)}</td>
      </tr>
    );
  });
}

function renderRowsEventConsentRequestMessage(message: EventInvitationMessage): React.ReactNode {
  const { fields } = message;
  return fields.map((field, index) => {
    const { heading, value, type } = field;

    return (
      <tr key={`event_consent_request_${index}`}>
        <td className={'w-50'}>{heading}</td>
        <td className={'w-50 pl-5'}>{getValueConverted(type, value)}</td>
      </tr>
    );
  });
}

function renderRowsClubParticipantInviteMessage(message: ClubParticipantInviteMessage): React.ReactNode {
  const { fields } = message;
  return fields.map((field, index) => {
    const { heading, value, type } = field;

    return (
      <tr key={`club_participant_invite_message${index}`}>
        <td className={'w-50'}>{heading}</td>
        <td className={'w-50 pl-5'}>{getValueConverted(type, value)}</td>
      </tr>
    );
  });
}

function renderRowsAbsenceMessage(message: AbsenceMessage): React.ReactNode {
  return AbsenceMessageRows.map((row, index) => {
    let contentCell: string;
    const field = row.field;

    switch (field) {
      case 'dateAbsence':
        contentCell = message[row.field] ? Moment(message[row.field]).format(DATE_FORMAT) : '';
        break;
      case 'intervalAbsence':
        contentCell = ABSENCE_MESSAGE_INTERVAL_SERVER_TO_CLIENT_MAPPING[message.intervalAbsence];
        break;
      case 'reasonAbsence':
        contentCell = ABSENCE_MESSAGE_REASON_SERVER_TO_CLIENT_MAPPING[message.reasonAbsence];
        break;
      default:
        contentCell = message[row.field];
    }

    return (
      <tr key={`message_summary_${index}`}>
        <td className={'w-50'}>{row.title}</td>
        <td className={'w-50 pl-5'}>{contentCell}</td>
      </tr>
    );
  });
}

function renderRowsEventParticipationMessage(message: EventParticipationMessage): React.ReactNode {
  return EventParticipationMessageRows.map((row, index) => {
    let contentCell: string;
    const field = row.field;

    switch (field) {
      case 'isTakePart':
        contentCell = message[row.field] ? 'Yes' : 'No';
        break;
      default:
        contentCell = message[row.field];
    }

    return (
      <tr key={`message_summary_${index}`}>
        <td className={'w-50'}>{row.title}</td>
        <td className={'w-50 pl-5'}>{contentCell}</td>
      </tr>
    );
  });
}

export function MessageAdditionalSummary(props: Props) {
  const { message } = props;
  const { kind } = message;

  let renderRows: React.ReactNode;

  switch (kind) {
    case MESSAGE_KIND.TournamentConfirmationRequestMessage:
      renderRows = renderRowsTournamentConfirmationRequestMessage(message as TournamentConfirmationRequestMessage);
      break;
    case MESSAGE_KIND.EventInvitationMessage:
      renderRows = renderRowsEventConsentRequestMessage(message as EventInvitationMessage);
      break;
    case MESSAGE_KIND.AbsenceMessage:
      renderRows = renderRowsAbsenceMessage(message as AbsenceMessage);
      break;
    case MESSAGE_KIND.EventParticipationMessage:
      renderRows = renderRowsEventParticipationMessage(message as EventParticipationMessage);
      break;
    case MESSAGE_KIND.ClubParticipantInviteMessage:
      renderRows = renderRowsClubParticipantInviteMessage(message as ClubParticipantInviteMessage);
      break;
    default:
      renderRows = <div>Coming soon</div>;
  }

  return (
    <table className={'table table-sm'}>
      <tbody>{renderRows}</tbody>
    </table>
  );
}
