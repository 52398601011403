import * as React from 'react';
import { FunctionComponent } from 'react';
import * as Moment from 'moment';
import { UpcomingTournament } from '../../models/upcomingTournament';
import { DATE_FORMAT } from '../../consts/date';

interface Props {
  upcomingTournaments: UpcomingTournament[];
}

export const SchoolUpcomingTournamentsSummary: FunctionComponent<Props> = props => {
  const { upcomingTournaments } = props;

  const sortedUpcomingTournaments = upcomingTournaments.slice().sort((tournament1, tournament2) => {
    const startTime = new Date(tournament2.startTime);
    const nextStartTime = new Date(tournament1.startTime);
    return startTime > nextStartTime ? -1 : startTime < nextStartTime ? 1 : 0;
  });

  const rows = sortedUpcomingTournaments.map((upcomingTournament, index) => {
    return (
      <tr key={`tournament_${index}`}>
        <td>{index + 1}</td>
        <td>{upcomingTournament.name}</td>
        <td>{upcomingTournament.sportName}</td>
        <td>{Moment(upcomingTournament.startTime).format(DATE_FORMAT)}</td>
        <td>{Moment(upcomingTournament.endTime).format(DATE_FORMAT)}</td>
      </tr>
    );
  });

  const emptyRow = (
    <tr key={'tournament_empty_row'}>
      <td>No upcoming tournament</td>
    </tr>
  );

  return (
    <table className={'table table-sm mPre mVerticalAlignMiddle'}>
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Sport</th>
          <th>Start</th>
          <th>End</th>
        </tr>
      </thead>
      <tbody>{rows.length > 0 ? rows : emptyRow}</tbody>
    </table>
  );
};

SchoolUpcomingTournamentsSummary.displayName = 'SchoolUpcomingTournamentsSummary';
