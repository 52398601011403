import * as React from 'react';
import { FunctionComponent } from 'react';
import * as propz from 'propz';
import * as Moment from 'moment';
import {
  TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING,
  TOURNAMENT_TYPE_SERVER_TO_CLIENT_MAPPING
} from '../../consts/tournament';
import { DATE_TIME_FORMAT } from '../../consts/date';
import { Championship } from '../../models/championship';
import { AGE_GROUPS } from '../../consts/school';
import { AppUser } from 'Src/views/App/App';

interface Props {
  championship: Championship;
  user: AppUser;
}

const ROWS = [
  { title: 'Name', field: 'name' },
  { title: 'Type', field: 'type' },
  { title: 'Subtype', field: 'subType' },
  { title: 'Age groups', field: 'ages' },
  { title: 'Start', field: 'startTime' },
  { title: 'End', field: 'endTime' },
  { title: 'Organisers', field: 'organisers' },
  { title: 'Organisers email', field: 'organisersEmail' },
  { title: 'Sport', field: 'sportName' }
];

export const ChampionshipSummary: FunctionComponent<Props> = props => {
  const { activeSchool } = props.user,
    { ageGroupsNaming } = activeSchool;
  const rows = ROWS.map((row, index) => {
    let contentCell: string;
    const field = row.field;

    const championship = props.championship;

    switch (field) {
      case 'type':
        contentCell = TOURNAMENT_TYPE_SERVER_TO_CLIENT_MAPPING[championship.type];
        break;

      case 'subType':
        contentCell = TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING[championship.subType];
        break;

      case 'ages':
        if (typeof championship.ages !== 'undefined') {
          const ages = [...championship.ages] || [];
          contentCell = ages.map(age => propz.get(AGE_GROUPS, [ageGroupsNaming, age], '')).join(', ');
        } else {
          contentCell = '';
        }
        break;

      case 'startTime':
        contentCell = Moment(championship.startTime).format(DATE_TIME_FORMAT);
        break;

      case 'endTime':
        contentCell = Moment(championship.endTime).format(DATE_TIME_FORMAT);
        break;

      case 'sportName':
        contentCell = propz.get(championship, ['sport', 'name'], '');
        break;

      default:
        contentCell = propz.get(championship, [row.field], '');
    }

    return (
      <tr key={`championship_summary_${index}`}>
        <td>{row.title}</td>
        <td>{contentCell}</td>
      </tr>
    );
  });

  return (
    <table className={'table table-sm mPre'}>
      <tbody>{rows}</tbody>
    </table>
  );
};

ChampionshipSummary.displayName = 'ChampionshipSummary';
