import * as React from 'react';
import * as propz from 'propz';
import { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ChildrenEventAvailabilityReportsSummaryTab.scss';
import * as Moment from 'moment';
import { Report } from '../../../../../../models/report';
import { AppUser } from '../../../../../App/App';
import { Point } from '../../../../../../models/venue';
import {SPORT_GENDER_SERVER_TO_CLIENT_MAPPING} from '../../../../../../consts/common';
import { addZeroToFirst } from '../../../../../../helpers/club/club';
import { getLocation } from '../../../../../../helpers/invite/invite';
import { getPoint } from '../../../../../../helpers/venue/venue';
import { getAgeGroup } from '../../../../../../helpers/accessor/accessor';
import { MESSAGE_KIND } from '../../../../../../consts/message';
import { isEventParticipationMessage } from '../../../../../../helpers/message/message';

interface Props {
    reports: Report[];
    user: AppUser;
    onViewMapClick: (point: Point) => void;
}

interface State {
    isVisibleReports: boolean[];
}

export class ChildrenEventAvailabilityReportsSummaryTab extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isVisibleReports: props.reports.map(report => false)
        };
    }

    getStatus = report => {
        if (report.isActionPerformed) {
            return 'Seen';
        } else {
            return 'Sent';
        }
    };

    getSenderFromMessage = report => {
        const { playerDetails, sender, playerDetailsData } = report;
        const { permissionId, fullName, userId } = sender;
        return playerDetails.permissionId === permissionId && playerDetails.userId === userId
            ? `${fullName} (student)`
            : `${fullName} (${playerDetailsData.firstName}'s parent)`;
    };

    onClickMessage(index) {
        const { isVisibleReports } = this.state;
        const changedIsVisibleReports = [...isVisibleReports];
        changedIsVisibleReports[index] = !isVisibleReports[index];
        this.setState({
            isVisibleReports: changedIsVisibleReports
        });
    }

    getLogoStyle(report) {
        let schoolLogoStyle = {
            backgroundImage: `url(/images/no_image.jpg)`
        };

        const school = report.schoolData;

        const pic = propz.get(school, ['pic'], undefined);
        if (typeof pic !== 'undefined') {
            schoolLogoStyle.backgroundImage = `url(${pic})`;
        }

        return schoolLogoStyle;
    }

    renderSchoolLogo(report) {
        return <div className="eEventMessage_img" style={this.getLogoStyle(report)}></div>;
    }

    renderEventInfo(report) {
        const event = report.eventData;
        const sport = event.sport.name;
        const gender = SPORT_GENDER_SERVER_TO_CLIENT_MAPPING[event.gender];
        const startTimeDataObject = new Date(event.startTime);
        const startDate = Moment(startTimeDataObject).format('DD.MM.YYYY');
        const hours = addZeroToFirst(startTimeDataObject.getHours());
        const minutes = addZeroToFirst(startTimeDataObject.getMinutes());

        return (
            <div className="">
                {sport} / {gender} / {getAgeGroup(event)}
                <br />
                {startDate} / {hours + ':' + minutes}
                <br />
                {this.renderVenue(report)}
                <br />
            </div>
        );
    }

    renderVenue(report): React.ReactNode {
        const eventLocation = getLocation(report.eventData, undefined);
        const isShowMap = propz.get(report, ['eventData', 'venue', 'venueType']) !== 'TBD';

        const venuePoint = propz.get(report, ['eventData', 'venue', 'point']);
        const venuePostcodePoint = propz.get(report, ['eventData', 'venue', 'postcodeData', 'point']);

        const point = getPoint(venuePoint, venuePostcodePoint);

        let result = null;
        if (isShowMap) {
            result = (
                <div className="eEventMessage_simpleField">
                    <span className="eEventMessage_boldTag">Venue: </span>
                    {eventLocation}
                    <br />
                    <div className="d-flex">
                        <div className="eEventMessage_mapMarker" />
                        <div className="eEventMessageVenue" onClick={() => this.props.onViewMapClick(point)}>
                            View map
                        </div>
                    </div>
                </div>
            );
        }

        return result;
    }

    getText(report): string {
        const player = report.playerDetailsData;
        const firstName = propz.get(player, ['firstName'], '');
        const lastName = propz.get(player, ['lastName'], '');
        const playerName = `${firstName} ${lastName}`;
        const now = new Date();

        const deadlineForAnswers = propz.get(report, ['deadlineForAnswers']);
        const isDeadlineExist = typeof deadlineForAnswers !== 'undefined';

        const isMessageInvitation = report.kind === MESSAGE_KIND.EventInvitationMessage;
        const isMessageRefusal = report.kind === MESSAGE_KIND.EventParticipationRefusalMessage;
        const isMessageAvailability = report.kind === MESSAGE_KIND.EventParticipationMessage;
        const isMessageClubParticipantInvite = report.kind === MESSAGE_KIND.ClubParticipantInviteMessage;
        const isMessageConfirmationRequest = report.kind === MESSAGE_KIND.TournamentConfirmationRequestMessage;

        switch (true) {
            case isMessageInvitation && !isDeadlineExist:
            case isMessageInvitation && Number(new Date(deadlineForAnswers)) > Number(now):
                return `Do you give your consent for ${playerName} to take part in this fixture?`;
            case isMessageInvitation && Number(new Date(deadlineForAnswers)) < Number(now):
                return `Unfortunately, the deadline has passed and you cannot accept or decline this consent request.`;
            case isMessageClubParticipantInvite && Number(new Date(deadlineForAnswers)) < Number(now):
                return `Unfortunately, the deadline has passed and you cannot book space at the moment.`;
            case isMessageRefusal:
                return `${playerName} is not available to take part.`;
            case isMessageAvailability:
                const isTakePart = report.isTakePart ? 'yes' : 'no';
                return `${playerName} can take part: ${isTakePart}`;
            case isMessageClubParticipantInvite && !isDeadlineExist:
            case isMessageClubParticipantInvite && Number(new Date(deadlineForAnswers)) > Number(now):
                return `You can book a place for your child now. To send a request for a place click the “Book” button.`;
            case isMessageConfirmationRequest:
                return `Please confirm your participation.`;
        }
    }

    renderReportText = report => {
        const details = isEventParticipationMessage(report) ? report.details : '';

        return (
            <div className="">
                <div className="eEventMessageInfoText">{this.getText(report)}</div>
                <p>{details}</p>
            </div>
        );
    };

    renderReportInfo = (report, index) => {
        const firstName = propz.get(report, ['playerDetailsData', 'firstName'], '');
        const lastName = propz.get(report, ['playerDetailsData', 'lastName'], '');
        const fullName = `${firstName} ${lastName}`;
        const school = report.schoolData;

        return (
            <tr key={`children_report_${index}`}>
                <td colSpan={6}>
                    <div className="container-fluid">
                        <div className="row eEventMessageInfoSection">
                            <div className="col-md-5">
                                <div className="d-flex">
                                    {this.renderSchoolLogo(report)}
                                    <div className="d-flex flex-column">
                                        <div className="eEventMessageInfoText">{fullName}</div>
                                        <div className="eEventMessageInfoText">{school.name}</div>
                                        {this.renderEventInfo(report)}
                                    </div>
                                </div>
                                <div className="">{this.renderReportText(report)}</div>
                            </div>
                            <div className="col-md-7" />
                        </div>
                    </div>
                </td>
            </tr>
        );
    };

    renderRows() {
        const { reports } = this.props;
        const { isVisibleReports } = this.state;

        return reports.map((report, index) => {
            const player = report.playerDetailsData;
            const firstName = propz.get(player, ['firstName'], '');
            const lastName = propz.get(player, ['lastName'], '');
            const playerName = `${firstName} ${lastName}`;
            const isTakePart = report.isTakePart ? 'Yes' : 'No';
            const details = report.details;
            const sender = this.getSenderFromMessage(report);
            const reportCreateDate = Moment(report.createdAt).format('DD-MM-YYYY HH:mm');

            const isShowInfoReport = isVisibleReports[index];
            return (
                <>
                    <tr
                        key={`availability_reports_children_summary_${index}`}
                        onClick={() => {
                            this.onClickMessage(index);
                        }}
                        className="eEventMessagesRow"
                    >
                        <td>
                            <div className="d-flex align-items-center">{playerName}</div>
                        </td>
                        <td>
                            <div className="d-flex align-items-center">{isTakePart}</div>
                        </td>
                        <td>
                            <div>{details}</div>
                        </td>
                        <td>
                            <div>{sender}</div>
                        </td>
                        <td>
                            <div>{reportCreateDate}</div>
                        </td>
                        <td>{this.getStatus(report)}</td>
                        <td>
                            <FontAwesomeIcon icon={isShowInfoReport ? 'chevron-up' : 'chevron-down'} className="" />
                        </td>
                    </tr>
                    {isShowInfoReport && this.renderReportInfo(report, index)}
                </>
            );
        });
    }

    renderEmptyRow() {
        return (
            <tr key={'children_empty_row'}>
                <td>There are no messages to display.</td>
            </tr>
        );
    }

    render() {
        const { reports } = this.props;
        return (
            <table className={'table table-sm mPre table-striped'}>
                <thead>
                <tr>
                    <th>Student</th>
                    <th>Available</th>
                    <th>Details</th>
                    <th>Sender</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th />
                </tr>
                </thead>
                <tbody>{reports.length > 0 ? this.renderRows() : this.renderEmptyRow()}</tbody>
            </table>
        );
    }
}