import * as React from 'react';
import { FunctionComponent } from 'react';
import * as Moment from 'moment';
import * as propz from 'propz';
import { AppUser } from 'Src/views/App/App';
import { Tournament } from '../../models/tournament';
import { TOURNAMENT_SUBTYPE } from '../../consts/tournament';
import { EVENT_STATUS_SERVER_TO_CLIENT_MAPPING } from '../../consts/event';
import { KIND } from '../../consts/school';
import { isTeamOrTwoOnTwoSportEvent } from '../../helpers/event/event';
import {
  isIndividualTournament,
  isSingleDoubleTournament,
  isTeamTournament
} from '../../helpers/tournament/tournament';
import { getEventAges } from '../../helpers/accessor/accessor';
import { SPORT_GENDER_SERVER_TO_CLIENT_MAPPING } from '../../consts/common';
import { SchoolEvent } from '../../models/event';

interface Props {
  tournament: Tournament;
  events: SchoolEvent[];
  user: AppUser;
}

export const TournamentEventsSummary: FunctionComponent<Props> = props => {
  const { user, events, tournament } = props;

  const getPlayersName = event => {
    let playerNameList = [];
    const activeSchoolId = user.activeSchoolId;
    if (isTeamOrTwoOnTwoSportEvent(event)) {
      const team = event.teamsData.find(team => team.schoolId === activeSchoolId);
      if (typeof team !== 'undefined') {
        playerNameList = team.players.map(player => {
          const firstName = propz.get(player, ['firstName'], ''),
            lastName = propz.get(player, ['lastName'], '');

          return `${firstName} ${lastName}`;
        });
      } else {
        console.error(`Can not find team with school id: ${activeSchoolId}`);
      }
    } else {
      playerNameList = event.individualsData
        .filter(individual => {
          const individualSchoolId = individual.schoolId;
          const activeSchool = event.invitedSchools.find(invitedSchool => invitedSchool.id === activeSchoolId);
          const activeSchoolKind = propz.get(activeSchool, ['kind']);
          const isActiveSchoolSchoolUnion = activeSchoolKind === KIND.SCHOOL_UNION;

          if (isActiveSchoolSchoolUnion) {
            const schoolUnionSchoolIds = activeSchool.schoolIds;
            return schoolUnionSchoolIds.some(schoolId => schoolId === individualSchoolId);
          } else {
            return individualSchoolId === activeSchoolId;
          }
        })
        .map(individual => {
          const firstName = propz.get(individual, ['firstName'], ''),
            lastName = propz.get(individual, ['lastName'], '');

          return `${firstName} ${lastName}`;
        });
    }

    return `${playerNameList.join('\n ')}`;
  };

  const { subType } = tournament;
  const isSailing = subType === TOURNAMENT_SUBTYPE.SAILING;
  const isKindSchool = user.activeSchool.kind === KIND.SCHOOL;
  const isKindSchoolUnion = user.activeSchool.kind === KIND.SCHOOL_UNION;

  const isShowGroupName = (isTeamTournament(tournament) || isSingleDoubleTournament(tournament)) && !isSailing;
  const isShowPlayers = isKindSchool && isIndividualTournament(tournament);
  const isShowNumber = isKindSchoolUnion && isIndividualTournament(tournament);

  const rows = events.map((event, index) => {
    const { status, gender: eventGender } = event;
    const sportName = propz.get(event, ['sport', 'name'], '');

    const statusEvent = status ? EVENT_STATUS_SERVER_TO_CLIENT_MAPPING[event.status] : '';
    const eventName = propz.get(event, ['generatedNames', user.activeSchoolId], '');
    const gender = eventGender ? SPORT_GENDER_SERVER_TO_CLIENT_MAPPING[event.gender] : '';
    const eventNumber = propz.get(event, ['quantumEventNumber'], '');
    const eventAges = getEventAges(event);

    let groupName;
    const groupId = propz.get(event, ['tournamentGroupId'], undefined);
    if (typeof groupId === 'undefined') {
      groupName = '';
    } else {
      const group = tournament.customGroups.find(customGroup => customGroup.id === groupId);
      if (typeof group !== 'undefined') {
        groupName = group.name;
      } else {
        console.error(`Can not find group with id: ${groupId}`);
        groupName = '';
      }
    }

    return (
      <tr key={`tournament_events_summary_${index}`}>
        <td>{index + 1}</td>

        {isShowNumber && <td>{eventNumber}</td>}
        <td>{sportName}</td>
        <td>{eventName}</td>
        <td>{eventAges}</td>

        {isShowPlayers && <td>{getPlayersName(event)}</td>}
        <td>{gender}</td>

        {isShowGroupName && <td>{groupName}</td>}
        <td>{Moment(event.startTime).format('DD-MM-YYYY HH:mm')}</td>
        <td>{statusEvent}</td>
      </tr>
    );
  });

  const emptyRow = (
    <tr key={'events_empty_row'}>
      <td>No events</td>
    </tr>
  );

  return (
    <table className={'table table-sm mPre'}>
      <thead>
        <tr>
          <th />

          {isShowNumber && <th>Number</th>}
          <th>Sport</th>
          <th>Event</th>
          <th>Age</th>

          {isShowPlayers && <th>Players</th>}
          <th>Gender</th>

          {isShowGroupName && <th>Group name</th>}
          <th>Start time</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>{rows.length > 0 ? rows : emptyRow}</tbody>
    </table>
  );
};

TournamentEventsSummary.displayName = 'TournamentEventsSummary';
