import * as React from 'react';
import * as BPromise from 'bluebird';
import { Component } from 'react';
import { AppUser } from 'Src/views/App/App';
import { PhotoEdit } from '../PhotoEdit/PhotoEdit';
import { Loader } from 'Src/components/Loader/Loader';
import { Photo } from 'Src/models/photo';
import { EmojiIcon } from '../EmojiIcon/EmojiIcon';
import { SmileIcon } from 'Src/components/SvgIcons/SmileIcon';
import { RotateIconLeft } from 'Src/components/SvgIcons/RotateIconLeft';
import { RotateIconRight } from 'Src/components/SvgIcons/RotateIconRight';
import { TrashIcon } from 'Src/components/SvgIcons/TrashIcon';
import { rotateImage } from 'Src/helpers/gallery/gallery';
import { getImageDirectUrl, uploadSchoolPhoto } from 'Src/helpers/service/admin/schoolGallery';
import { dataURLtoBlob } from 'Src/helpers/gallery/gallery';
import { getRandomString } from 'Src/helpers/common/common';
import './FullScreenPhoto.scss';

const ANGLE = {
  LEFT: -Math.PI / 2,
  RIGHT: Math.PI / 2
};

export interface Props {
  user: AppUser;
  photo: Photo;
  albumId?: string;
  isShowArrowButtons: boolean;
  isShowEditPhotoPanel: boolean;
  onPrevPhotoClick: () => void;
  onNextPhotoClick: () => void;
  onCloseClick: () => void;
  onChangeSchoolPhotoInfoClick: (
    id: string,
    photoInfo: {
      name?: string;
      description?: string;
      picUrl?: string;
      accessPreset?: string;
    }
  ) => void;
  onDeletePhotoClick: (id: string) => any;
}

interface State {
  windowWidth: number;
  windowHeight: number;
  isAddEmojiIconMode: boolean;
  isLoading: boolean;
}

export class FullScreenPhoto extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      isAddEmojiIconMode: false,
      isLoading: false
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    });
  };

  onPrevPhotoClick = e => {
    e.stopPropagation();
    const { onPrevPhotoClick } = this.props;

    onPrevPhotoClick();
  };

  onNextPhotoClick = e => {
    e.stopPropagation();
    const { onNextPhotoClick } = this.props;

    onNextPhotoClick();
  };

  renderPhoto() {
    const { isShowArrowButtons, photo } = this.props;
    const { isLoading, windowHeight } = this.state;
    const { picUrl } = photo;

    const src = picUrl;
    const height = Math.floor(windowHeight * 0.8);

    const photoStyle = {
      height: height,
      backgroundImage: `url(${src})`
    };

    const arrowStyle = {
      height: height
    };

    if (isShowArrowButtons) {
      return (
        <div className="eFullScreenPhoto" style={photoStyle}>
          <div className="eFullScreenPhotoArrowLeft" onClick={this.onPrevPhotoClick} style={arrowStyle}></div>
          <div className="eFullScreenPhotoArrowRight" onClick={this.onNextPhotoClick} style={arrowStyle}></div>
          {isLoading && <Loader />}
        </div>
      );
    } else {
      return <div className="eFullScreenPhoto" style={photoStyle}></div>;
    }
  }

  renderEditPhotoPanel() {
    const { isShowEditPhotoPanel, photo, albumId, onChangeSchoolPhotoInfoClick } = this.props;
    const { windowHeight } = this.state;
    const { id } = photo;

    const height = Math.floor(windowHeight * 0.8);

    const sideContainerStyle = {
      height: height - 10
    };

    if (isShowEditPhotoPanel) {
      return (
        <div className="eFullScreenPhotoInfo" style={sideContainerStyle}>
          <div className="eEditPhotoPanel">
            <span
              onClick={() =>
                this.setState({
                  isAddEmojiIconMode: true,
                  isLoading: true
                })
              }
              className="bTooltip"
              id="addIcon_button"
              data-description="Add anonymous icon"
            >
              <SmileIcon />
            </span>
            <span
              onClick={e => this.rotatePhoto(ANGLE.LEFT)}
              className="bTooltip"
              id="albumLeftRotate_button"
              data-description="Rotate photo to left"
            >
              <RotateIconLeft />
            </span>
            <span
              onClick={e => this.rotatePhoto(ANGLE.RIGHT)}
              className="bTooltip"
              id="albumLeftRotate_button"
              data-description="Rotate photo to right"
            >
              <RotateIconRight />
            </span>
            <span
              onClick={e => this.onDeletePhotoClick(e)}
              className="bTooltip"
              id="albumCover_button"
              data-description="Delete photo"
            >
              <TrashIcon />
            </span>
          </div>

          <PhotoEdit key={id} albumId={albumId} photo={photo} onSubmit={onChangeSchoolPhotoInfoClick} />
        </div>
      );
    }
  }

  onSaveClick(file) {
    const { onChangeSchoolPhotoInfoClick, photo, user } = this.props;

    this.setState({
      isLoading: true
    });

    uploadSchoolPhoto(user, file)
      .then(picUrl => {
        return onChangeSchoolPhotoInfoClick(photo.id, { picUrl });
      })
      .then(() => {
        this.setState({
          isLoading: false,
          isAddEmojiIconMode: false
        });
      });
  }

  onPhotoClick(e) {
    e.stopPropagation();
  }

  onDeletePhotoClick(e) {
    const { onDeletePhotoClick, photo } = this.props;
    const { id } = photo;

    this.setState({
      isLoading: true
    });

    onDeletePhotoClick(id);
  }

  rotatePhoto(angle) {
    const { user, photo, onChangeSchoolPhotoInfoClick } = this.props;
    const { picUrl: pictureUrl, id } = photo;

    this.setState({
      isLoading: true
    });

    return getImageDirectUrl(pictureUrl)
      .then(data => {
        const imageNameParts = data.data.url.split('.');
        const imageFormat = imageNameParts[imageNameParts.length - 1];

        const picUrl = `${data.data.url}?t=${Date.now() + getRandomString()}`;

        return rotateImage(picUrl, angle, imageFormat);
      })
      .then(data => {
        const file = dataURLtoBlob(data);

        return uploadSchoolPhoto(user, file);
      })
      .then(picUrl => {
        return onChangeSchoolPhotoInfoClick(id, { picUrl });
      })
      .then(() =>
        this.setState({
          isLoading: false
        })
      );
  }

  render() {
    const { onCloseClick, photo } = this.props;
    const { isLoading, isAddEmojiIconMode, windowWidth, windowHeight } = this.state;

    const width = Math.floor(windowWidth * 0.8);
    const height = Math.floor(windowHeight * 0.8);

    if (isAddEmojiIconMode) {
      return (
        <div className="bFullScreenPhotoOverlay">
          <EmojiIcon
            onSaveClick={file => this.onSaveClick(file)}
            onCancelClick={() =>
              this.setState({
                isAddEmojiIconMode: false,
                isLoading: false
              })
            }
            getPhotoUrl={() => BPromise.resolve(photo.picUrl)}
            imgContainerWidth={width - 280}
            imgContainerHeight={height}
          />
        </div>
      );
    } else {
      return (
        <div className="bFullScreenPhotoOverlay" onClick={onCloseClick}>
          <div className="eFullScreenPhotoOverlayCross" onClick={onCloseClick} />
          <div className="eFullScreenPhotoContainer" onClick={this.onPhotoClick}>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                {this.renderPhoto()}
                {this.renderEditPhotoPanel()}
              </>
            )}
          </div>
        </div>
      );
    }
  }
}
