import * as React from 'react';
import * as BPromise from 'bluebird';
import * as Lazy from 'lazy.js';
import * as propz from 'propz';
import { MonthYearSelector } from './MonthYearSelector';
import { Component } from 'react';
import * as Moment from 'moment';
import { DEFAULT_RANGE_CALENDAR_YEARS } from '../../../../consts/calendar';
import {
  getFilterClubEventDatesForMonth,
  getFilterClubEventsForDay,
  getFilterEventDatesForMonth,
  getFilterEventsForDay,
  getFilterFixtureEventDatesForMonth,
  getFilterFixtureEventsForDay,
  getFilterTournamentDatesForMonth,
  getFilterTournamentEventDatesForMonth,
  getFilterTournamentEventsForDay,
  getFilterTournamentsForDay,
  getYearRangeArray,
  isAllFilter,
  isClubEventsFilter,
  isSportEventsFilter,
  isTournamentEventsFilter,
  isTournamentsFilter
} from '../../../../helpers/calendar/calendar';
import { DaysOfWeekBar } from './DaysOfWeekBar';
import { MonthDaysPanel } from './MonthDaysPanel';
import './Calendar.scss';
import { SchoolEvent } from '../../../../models/event';
import { Loader } from '../../../../components/Loader/Loader';
import { AppUser } from '../../../App/App';
import { History, Location } from 'history';
import { getSchoolEvents } from 'Src/helpers/service/admin/schoolEvents';
import { EventAndTournamentsTable } from './EventAndTournamentsTable';
import { getSchoolEventDates } from 'Src/helpers/service/admin/calendar';
import { CalendarFilterButtons } from './CalendarFilterButtons';
import { getSchoolTournamentDates, getSchoolTournaments } from 'Src/helpers/service/admin/tournaments';
import { Tournament } from '../../../../models/tournament';
import { Button } from '../../../../components/Button/Button';

export type FilterType = 'TOURNAMENTS' | 'SPORT_EVENTS' | 'CLUB_EVENTS' | 'TOURNAMENT_EVENTS' | 'ALL';

export const CALENDAR_FILTER = {
  SPORT_EVENTS: 'SPORT_EVENTS',
  CLUB_EVENTS: 'CLUB_EVENTS',
  TOURNAMENT_EVENTS: 'TOURNAMENT_EVENTS',
  TOURNAMENTS: 'TOURNAMENTS',
  ALL: 'ALL'
};

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

interface State {
  monthAndYearDate: Date;
  selectedDate: Date;
  nowDate: Date;
  fixtureEvents: SchoolEvent[];
  clubEvents: SchoolEvent[];
  tournamentEvents: SchoolEvent[];
  tournaments: Tournament[];
  dates: Date[];
  activeMonthIndex: number;
  activeYearIndex: number;
  isLoading: boolean;
  filter: FilterType;
}

export class Calendar extends Component<Props, State> {
  constructor(props) {
    super(props);

    const now = new Date();
    const nowMonth = now.getMonth();
    const nowYear = now.getFullYear();
    const monthAndYearDate = new Date(nowYear, nowMonth, 1);

    this.state = {
      monthAndYearDate,
      selectedDate: now,
      nowDate: now,
      activeMonthIndex: nowMonth,
      activeYearIndex: DEFAULT_RANGE_CALENDAR_YEARS,
      fixtureEvents: [],
      clubEvents: [],
      tournamentEvents: [],
      dates: [],
      tournaments: [],
      isLoading: false,
      filter: CALENDAR_FILTER.ALL as FilterType
    };
  }

  getEventPromises(filter: FilterType, monthAndYearDate: Date, selectedDate: Date) {
    const { user } = this.props;

    const filterEventDates = getFilterEventDatesForMonth(monthAndYearDate, user);
    const filterEvents = getFilterEventsForDay(selectedDate, user);

    const filterSportEventDates = getFilterFixtureEventDatesForMonth(filterEventDates);
    const filterSportEvents = getFilterFixtureEventsForDay(filterEvents);

    const filterClubEventDates = getFilterClubEventDatesForMonth(filterEventDates);
    const filterClubEvents = getFilterClubEventsForDay(filterEvents);

    const filterTournamentEventDates = getFilterTournamentEventDatesForMonth(filterEventDates);
    const filterTournamentEvents = getFilterTournamentEventsForDay(filterEvents);

    const filterTournamentDates = getFilterTournamentDatesForMonth(monthAndYearDate);
    const filterTournaments = getFilterTournamentsForDay(selectedDate);

    const isAllOrSportEventsFilter = isAllFilter(filter) || isSportEventsFilter(filter);
    const isAllOrClubEventsFilter = isAllFilter(filter) || isClubEventsFilter(filter);
    const isAllOrTournamentEventsFilter = isAllFilter(filter) || isTournamentEventsFilter(filter);
    const isAllOrTournamentsFilter = isAllFilter(filter) || isTournamentsFilter(filter);

    //TODO: Use it for teacher/coach
    //not show tournaments and tournament events for teacher/coach
    // const isUserCoachOrTeacher = isUserCoach(user) || isUserTeacher(user);
    // return [
    //   isAllOrSportEventsFilter ? getSchoolEventDates(user, filterSportEventDates) : BPromise.resolve({ dates: [] }),
    //   isAllOrSportEventsFilter ? getSchoolEvents(user, filterSportEvents) : BPromise.resolve([]),
    //   isAllOrClubEventsFilter ? getSchoolEventDates(user, filterClubEventDates) : BPromise.resolve({ dates: [] }),
    //   isAllOrClubEventsFilter ? getSchoolEvents(user, filterClubEvents) : BPromise.resolve([]),
    //   isAllOrTournamentEventsFilter && !isUserCoachOrTeacher
    //     ? getSchoolEventDates(user, filterTournamentEventDates)
    //     : BPromise.resolve({ dates: [] }),
    //   isAllOrTournamentEventsFilter && !isUserCoachOrTeacher
    //     ? getSchoolEvents(user, filterTournamentEvents)
    //     : BPromise.resolve([]),
    //   isAllOrTournamentsFilter && !isUserCoachOrTeacher
    //     ? getSchoolTournamentDates(user, filterTournamentDates)
    //     : BPromise.resolve({ dates: [] }),
    //   isAllOrTournamentsFilter && !isUserCoachOrTeacher
    //     ? getSchoolTournaments(user, filterTournaments)
    //     : BPromise.resolve([])
    // ];

    return [
      isAllOrSportEventsFilter ? getSchoolEventDates(user, filterSportEventDates) : BPromise.resolve({ dates: [] }),
      isAllOrSportEventsFilter ? getSchoolEvents(user, filterSportEvents) : BPromise.resolve([]),
      isAllOrClubEventsFilter ? getSchoolEventDates(user, filterClubEventDates) : BPromise.resolve({ dates: [] }),
      isAllOrClubEventsFilter ? getSchoolEvents(user, filterClubEvents) : BPromise.resolve([]),
      isAllOrTournamentEventsFilter
        ? getSchoolEventDates(user, filterTournamentEventDates)
        : BPromise.resolve({ dates: [] }),
      isAllOrTournamentEventsFilter ? getSchoolEvents(user, filterTournamentEvents) : BPromise.resolve([]),
      isAllOrTournamentsFilter
        ? getSchoolTournamentDates(user, filterTournamentDates)
        : BPromise.resolve({ dates: [] }),
      isAllOrTournamentsFilter ? getSchoolTournaments(user, filterTournaments) : BPromise.resolve([])
    ];
  }

  componentDidMount() {
    const { location } = this.props;
    const { monthAndYearDate, selectedDate, filter } = this.state;
    const { state } = location;

    const filterFromHistory = propz.get(state, ['filter']);
    const selectedDateFromHistory = propz.get(state, ['selectedDate']);
    const isFilterFromHistoryExist = typeof filterFromHistory !== 'undefined';
    const isSelectedDateFromHistoryExist = typeof selectedDateFromHistory !== 'undefined';

    const currentFilter = isFilterFromHistoryExist ? filterFromHistory : filter;
    const currentDate = isSelectedDateFromHistoryExist ? selectedDateFromHistory : selectedDate;

    const now = new Date(currentDate);
    const nowMonth = now.getMonth();
    const nowYear = now.getFullYear();
    const currentMonthAndYearDate = new Date(nowYear, nowMonth, 1);

    this.setState({
      isLoading: true
    });

    const promises = this.getEventPromises(currentFilter, currentMonthAndYearDate, now);

    BPromise.all(promises).then(
      ([
        fixtureEventDates,
        fixtureEvents,
        clubEventDates,
        clubEvents,
        tournamentEventDates,
        tournamentEvents,
        tournamentDates,
        tournaments
      ]) => {
        const dates = [
          ...fixtureEventDates.dates,
          ...clubEventDates.dates,
          ...tournamentEventDates.dates,
          ...tournamentDates.dates
        ];
        const datesUniq: string[] = Lazy(dates)
          .uniq()
          .toArray();
        this.setState({
          isLoading: false,
          dates: datesUniq.map(date => new Date(date)),
          fixtureEvents,
          clubEvents,
          tournamentEvents,
          tournaments,
          filter: currentFilter,
          selectedDate: now,
          monthAndYearDate: currentMonthAndYearDate,
          activeMonthIndex: nowMonth
        });
      }
    );
  }

  onNextMonthClick = (): void => {
    const { monthAndYearDate, activeYearIndex, filter, selectedDate } = this.state;
    const nowMonth = monthAndYearDate.getMonth();
    const nextMonth = new Date(monthAndYearDate);
    nextMonth.setMonth(nowMonth + 1);
    const nextMonthIndex = nextMonth.getMonth();

    const promises = this.getEventPromises(filter, nextMonth, selectedDate);

    this.setState({
      isLoading: true
    });

    if (nowMonth === 11) {
      if (activeYearIndex === DEFAULT_RANGE_CALENDAR_YEARS * 2) {
        this.setState({
          isLoading: false
        });
        return;
      }

      BPromise.all(promises).then(
        ([
          fixtureEventDates,
          fixtureEvents,
          clubEventDates,
          clubEvents,
          tournamentEventDates,
          tournamentEvents,
          tournamentDates,
          tournaments
        ]) => {
          const dates = [
            ...fixtureEventDates.dates,
            ...clubEventDates.dates,
            ...tournamentEventDates.dates,
            ...tournamentDates.dates
          ];
          const datesUniq: string[] = Lazy(dates)
            .uniq()
            .toArray();
          this.setState({
            isLoading: false,
            dates: datesUniq.map(date => new Date(date)),
            monthAndYearDate: nextMonth,
            activeMonthIndex: nextMonthIndex,
            activeYearIndex: activeYearIndex + 1
          });
        }
      );
    } else {
      BPromise.all(promises).then(
        ([
          fixtureEventDates,
          fixtureEvents,
          clubEventDates,
          clubEvents,
          tournamentEventDates,
          tournamentEvents,
          tournamentDates,
          tournaments
        ]) => {
          const dates = [
            ...fixtureEventDates.dates,
            ...clubEventDates.dates,
            ...tournamentEventDates.dates,
            ...tournamentDates.dates
          ];
          const datesUniq: string[] = Lazy(dates)
            .uniq()
            .toArray();
          this.setState({
            isLoading: false,
            dates: datesUniq.map(date => new Date(date)),
            monthAndYearDate: nextMonth,
            activeMonthIndex: nextMonthIndex
          });
        }
      );
    }
  };
  onPreviousMonthClick = (): void => {
    const { monthAndYearDate, activeYearIndex, filter, selectedDate } = this.state;
    const nowMonth = monthAndYearDate.getMonth();
    const previousMonth = new Date(monthAndYearDate);
    previousMonth.setMonth(nowMonth - 1);
    const previousMonthIndex = previousMonth.getMonth();

    const promises = this.getEventPromises(filter, previousMonth, selectedDate);

    this.setState({
      isLoading: true
    });

    if (nowMonth === 0) {
      if (activeYearIndex === 0) {
        this.setState({
          isLoading: false
        });
        return;
      }

      BPromise.all(promises).then(
        ([
          fixtureEventDates,
          fixtureEvents,
          clubEventDates,
          clubEvents,
          tournamentEventDates,
          tournamentEvents,
          tournamentDates,
          tournaments
        ]) => {
          const dates = [
            ...fixtureEventDates.dates,
            ...clubEventDates.dates,
            ...tournamentEventDates.dates,
            ...tournamentDates.dates
          ];
          const datesUniq: string[] = Lazy(dates)
            .uniq()
            .toArray();
          this.setState({
            isLoading: false,
            dates: datesUniq.map(date => new Date(date)),
            monthAndYearDate: previousMonth,
            activeMonthIndex: previousMonthIndex,
            activeYearIndex: activeYearIndex - 1
          });
        }
      );
    } else {
      BPromise.all(promises).then(
        ([
          fixtureEventDates,
          fixtureEvents,
          clubEventDates,
          clubEvents,
          tournamentEventDates,
          tournamentEvents,
          tournamentDates,
          tournaments
        ]) => {
          const dates = [
            ...fixtureEventDates.dates,
            ...clubEventDates.dates,
            ...tournamentEventDates.dates,
            ...tournamentDates.dates
          ];
          const datesUniq: string[] = Lazy(dates)
            .uniq()
            .toArray();
          this.setState({
            isLoading: false,
            dates: datesUniq.map(date => new Date(date)),
            monthAndYearDate: previousMonth,
            activeMonthIndex: previousMonthIndex
          });
        }
      );
    }
  };

  onMonthChange = (value: number): void => {
    const { monthAndYearDate, filter, selectedDate } = this.state;
    const nextMonth = new Date(monthAndYearDate);
    nextMonth.setMonth(value);

    const promises = this.getEventPromises(filter, nextMonth, selectedDate);

    this.setState({
      isLoading: true
    });

    BPromise.all(promises).then(
      ([
        fixtureEventDates,
        fixtureEvents,
        clubEventDates,
        clubEvents,
        tournamentEventDates,
        tournamentEvents,
        tournamentDates,
        tournaments
      ]) => {
        const dates = [
          ...fixtureEventDates.dates,
          ...clubEventDates.dates,
          ...tournamentEventDates.dates,
          ...tournamentDates.dates
        ];
        const datesUniq: string[] = Lazy(dates)
          .uniq()
          .toArray();
        this.setState({
          isLoading: false,
          dates: datesUniq.map(date => new Date(date)),
          monthAndYearDate: nextMonth,
          activeMonthIndex: Number(value)
        });
      }
    );
  };

  onYearChange = (value: number): void => {
    const { monthAndYearDate, filter, selectedDate } = this.state;
    const nextYear = new Date(monthAndYearDate);
    nextYear.setFullYear(value);

    const promises = this.getEventPromises(filter, nextYear, selectedDate);

    const yearRangeArray = getYearRangeArray();
    const activeYearIndex = yearRangeArray.findIndex(year => year === Number(value));

    this.setState({
      isLoading: true
    });

    BPromise.all(promises).then(
      ([
        fixtureEventDates,
        fixtureEvents,
        clubEventDates,
        clubEvents,
        tournamentEventDates,
        tournamentEvents,
        tournamentDates,
        tournaments
      ]) => {
        const dates = [
          ...fixtureEventDates.dates,
          ...clubEventDates.dates,
          ...tournamentEventDates.dates,
          ...tournamentDates.dates
        ];
        const datesUniq: string[] = Lazy(dates)
          .uniq()
          .toArray();
        this.setState({
          isLoading: false,
          dates: datesUniq.map(date => new Date(date)),
          monthAndYearDate: nextYear,
          activeYearIndex: activeYearIndex
        });
      }
    );
  };

  onDayClick = (date: Date): void => {
    const { filter, monthAndYearDate } = this.state;
    this.setState({
      isLoading: true
    });

    const promises = this.getEventPromises(filter, monthAndYearDate, date);

    BPromise.all(promises).then(
      ([
        fixtureEventDates,
        fixtureEvents,
        clubEventDates,
        clubEvents,
        tournamentEventDates,
        tournamentEvents,
        tournamentDates,
        tournaments
      ]) => {
        this.setState({
          selectedDate: date,
          isLoading: false,
          fixtureEvents,
          clubEvents,
          tournamentEvents,
          tournaments
        });
      }
    );
  };

  onFilterButtonClick = (filter: FilterType) => {
    const { monthAndYearDate, selectedDate } = this.state;

    this.setState({
      isLoading: true
    });

    const promises = this.getEventPromises(filter, monthAndYearDate, selectedDate);

    BPromise.all(promises).then(
      ([
        fixtureEventDates,
        fixtureEvents,
        clubEventDates,
        clubEvents,
        tournamentEventDates,
        tournamentEvents,
        tournamentDates,
        tournaments
      ]) => {
        const dates = [
          ...fixtureEventDates.dates,
          ...clubEventDates.dates,
          ...tournamentEventDates.dates,
          ...tournamentDates.dates
        ];
        const datesUniq: string[] = Lazy(dates)
          .uniq()
          .toArray();
        this.setState({
          isLoading: false,
          dates: datesUniq.map(date => new Date(date)),
          fixtureEvents,
          clubEvents,
          tournamentEvents,
          tournaments,
          filter
        });
      }
    );
  };

  onCreateEventClick = (event, selectedDate) => {
    event.preventDefault();
    const createDate = Moment(selectedDate).format('DD-MM-YYYY');

    this.props.history.push({
      pathname: '/events/manager',
      search: `date=${createDate}`
    });
  };

  onEventClick = (eventId: string): void => {
    const { location } = this.props;
    const { pathname } = location;
    const { filter } = this.state;

    this.props.history.push({
      pathname: '/events/event',
      search: `id=${eventId}`,
      state: {
        filter,
        prevRoutePath: pathname
      }
    });
  };

  render() {
    const {
      activeMonthIndex,
      activeYearIndex,
      selectedDate,
      monthAndYearDate,
      nowDate,
      isLoading,
      fixtureEvents,
      clubEvents,
      tournamentEvents,
      tournaments,
      dates,
      filter
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const { user } = this.props;

    const events = [...fixtureEvents, ...clubEvents, ...tournamentEvents];
    const eventsSorted = events.sort((event1, event2) => {
      const eventStartTimeDate1 = new Date(event1.startTime);
      const eventStartTimeDate2 = new Date(event2.startTime);
      return Number(eventStartTimeDate1) - Number(eventStartTimeDate2);
    });
    return (
      <div className={'mt-3'}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 d-flex justify-content-between">
              <div>
                <CalendarFilterButtons user={user} filter={filter} onFilterButtonClick={this.onFilterButtonClick} />
              </div>
              {/*<div className="d-flex align-items-center">*/}
              {/*  <input*/}
              {/*    id="isMyEventsOnly"*/}
              {/*    type="checkbox"*/}
              {/*    className="form-check-input"*/}
              {/*    checked={false}*/}
              {/*    onChange={() => {}}*/}
              {/*  />*/}
              {/*  <label htmlFor="isMyEventsOnly" className="ml-2 form-check-label">*/}
              {/*    My events only*/}
              {/*  </label>*/}
              {/*</div>*/}
            </div>
            <div className="col-auto">
              <div className="eEventAndTournamentsCalendarSection">
                <MonthYearSelector
                  activeMonthIndex={activeMonthIndex}
                  activeYearIndex={activeYearIndex}
                  onPreviousMonthClick={this.onPreviousMonthClick}
                  onNextMonthClick={this.onNextMonthClick}
                  onMonthChange={this.onMonthChange}
                  onYearChange={this.onYearChange}
                />
                <DaysOfWeekBar />
                <MonthDaysPanel
                  onClick={this.onDayClick}
                  selectedDate={selectedDate}
                  monthAndYearDate={monthAndYearDate}
                  nowDate={nowDate}
                  eventDates={dates}
                />
              </div>
            </div>
            <div className="col">
              <div className="eEventAndTournamentsTableSection">
                <EventAndTournamentsTable
                  user={user}
                  events={eventsSorted}
                  tournaments={tournaments}
                  onEventClick={this.onEventClick}
                />
              </div>
              <Button
                key={'create_events'}
                customClass={'eCalendarCreateClubButton mt-3'}
                onClick={e => {
                  this.onCreateEventClick(e, selectedDate);
                }}
                text={'Create event'}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
