import * as React from 'react';
import { FunctionComponent } from 'react';
import { Field, Form, Formik } from 'formik';
import { Button } from '../Button/Button';
import * as propz from 'propz';

interface Props {
  onCancel: () => void;
  onSubmit: (data: { comment: string }) => void;
  enrollmentLog: any;
}

export const TournamentCommentEnrollmentLogForm: FunctionComponent<Props> = props => {
  const commentForm = {
    comment: propz.get(props, ['enrollmentLog', 'comment'], '')
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik
            initialValues={commentForm}
            validationSchema={''}
            onSubmit={values => {
              props.onSubmit(values);
            }}
            render={({}) => (
              <Form>
                <div className="form-group form-check">
                  <div className="form-group">
                    <label>Comment</label>
                    <Field type="text" name="comment" className="form-control" />
                    <Button onClick={props.onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                    <button type="submit" className="mt-3 mb-3 btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </Form>
            )}
          />
        </div>
      </div>
    </div>
  );
};
