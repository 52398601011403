import * as React from 'react';
import { Component } from 'react';
import * as classNames from 'classnames';
import './MessageActionsButton.css';
import { LabelWithQuestionIcon } from '../LabelWithQuestionIcon/LabelWithQuestionIcon';

interface Props {
  onSendClick: () => void;
  onSaveClick: () => void;
  onScheduleClick?: () => void;
  isDisabled?: boolean;
  onActiveActionChange?: (actionType: ACTION_TYPE) => void;
}

interface State {
  title: string;
  isExpanded: boolean;
  activeAction: ACTION_TYPE;
}

export enum ACTION_TYPE {
  SAVE = 'SAVE',
  SEND = 'SEND',
  SCHEDULE = 'SCHEDULE'
}

export class GeneralMessageActionsButton extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      title: 'Send',
      isExpanded: false,
      activeAction: ACTION_TYPE.SEND
    };
  }

  onSelectedSend = () => {
    this.onActiveActionChange(ACTION_TYPE.SEND);
    this.setState({
      title: 'Send',
      activeAction: ACTION_TYPE.SEND,
      isExpanded: false
    });
  };

  onSelectedSave = () => {
    this.onActiveActionChange(ACTION_TYPE.SAVE);
    this.setState({
      title: 'Save as draft',
      activeAction: ACTION_TYPE.SAVE,
      isExpanded: false
    });
  };

  onSelectedSchedule = () => {
    this.onActiveActionChange(ACTION_TYPE.SCHEDULE);
    this.setState({
      title: 'Schedule sending',
      activeAction: ACTION_TYPE.SCHEDULE,
      isExpanded: false
    });
  };

  onActiveActionChange = (actionType: ACTION_TYPE) => {
    if (typeof this.props.onActiveActionChange === 'function') {
      this.props.onActiveActionChange(actionType);
    }
  };

  onToggleClick = () => {
    const { isExpanded } = this.state;

    this.setState({ isExpanded: !isExpanded });
  };

  onActionButtonClick = () => {
    const { activeAction } = this.state;

    switch (activeAction) {
      case ACTION_TYPE.SAVE:
        this.props.onSaveClick();
        break;
      case ACTION_TYPE.SEND:
        this.props.onSendClick();
        break;
      case ACTION_TYPE.SCHEDULE:
        this.props.onScheduleClick();
        break;
      default:
        console.error(`GeneralMessageActionButton.tsx: Unexpected ACTION_TYPE ${activeAction}`);
    }
  };

  renderDropdownList() {
    const listClassNames = classNames({
      'dropdown-menu': true,
      'dropdown-menu-right': true,
      show: this.state.isExpanded,
      eMessageActionButton: true
    });

    return (
      <div className={listClassNames}>
        <a className="dropdown-item" onClick={this.onSelectedSend}>
          Send now
        </a>
        <a className="dropdown-item" onClick={this.onSelectedSchedule}>
          Schedule sending
        </a>
        <a className="dropdown-item" onClick={this.onSelectedSave}>
          Save as draft
        </a>
      </div>
    );
  }
  render() {
    return (
      <div className="btn-group">
        <button
          className="btn btn-primary"
          type="button"
          onClick={this.onActionButtonClick}
          disabled={this.props.isDisabled}
        >
          {this.state.title}
        </button>
        <button
          className="btn btn-primary dropdown-toggle"
          onClick={this.onToggleClick}
          disabled={this.props.isDisabled}
        />
        {this.renderDropdownList()}
        <LabelWithQuestionIcon
          labelText=""
          hintText={
            'Using the drop down arrow you can choose whether you would like to schedule sending, send the ' +
            'message now or save the message as a draft.'
          }
        />
      </div>
    );
  }
}
