import * as React from 'react';
import { Field, Form, Formik } from 'formik';
import DatePicker from 'react-datepicker';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from '../../../../../../consts/report';
import { Switch } from '../../../../../../components/Switch/Switch';
import { LabelWithQuestionIcon } from '../../../../../../components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import { getStartDateForSchoolYear } from '../../../../../../helpers/calendar/calendar';

interface Props {
  onCloseClick: () => void;
  onSubmit: (data: any, reportType: string) => void;
}

//report 018
export function UnionMemberSchoolsTournamentsAsPartOfUnionReportForm({ onCloseClick, onSubmit }: Props) {
  const now = new Date();
  let dateFrom = getStartDateForSchoolYear(now);

  const requestInitial = {
    dateFrom,
    dateTo: now
  };

  return (
    <Formik
      onSubmit={values =>
        onSubmit(values, REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.UNION_MEMBER_SCHOOLS_TOURNAMENTS_AS_PART_OF_UNION)
      }
      initialValues={requestInitial}
      render={({ values, setFieldValue }) => (
        <Form>
          <div className="bReportFormTitle">
            Member schools participation in tournaments where their children are/were taking part as a part of area team
          </div>
          <div className="mb-3 mt-3">
            {'This report will enable you to see the full details of the tournaments ' +
              'related to your union member schools (only tournament where ' +
              'a consolidated area team is/was taking part is counted).'}
          </div>
          <div className="form-group">
            <label>Date from</label>
            <Field name="dateFrom">
              {({ field, form }) => (
                <div className="mb-3">
                  <DatePicker
                    {...field}
                    selected={field.value}
                    onChange={date => {
                      form.setFieldValue('dateFrom', date);
                      if (form.values.dateTo && form.values.dateTo < date) {
                        form.setFieldValue('dateTo', date);
                      }
                    }}
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
              )}
            </Field>
          </div>
          <div className="form-group">
            <label>Date to</label>
            <Field name="dateTo">
              {({ field, form }) => (
                <div className="mb-3">
                  <DatePicker
                    {...field}
                    selected={field.value}
                    onChange={date => {
                      form.setFieldValue('dateTo', date);
                    }}
                    minDate={form.values.dateFrom}
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
              )}
            </Field>
          </div>

          <button type="button" className="btn btn-secondary mr-3" onClick={onCloseClick}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Get
          </button>
        </Form>
      )}
    />
  );
}
