import * as React from 'react';
import { FunctionComponent } from 'react';
import * as propz from 'propz';
import { SchoolEvent, SchoolEventIndividualData, SchoolEventTeamDataPlayer } from 'Src/models/event';
import { getFullName } from 'Src/helpers/autocomplete/autocomplete';
import { SchoolEventIndividualDiscipline } from '../../../../../../models/event';
import './DisciplineTab.scss';

interface Props {
  schoolEvent: SchoolEvent;
  allTeamPlayers: (SchoolEventIndividualData | SchoolEventTeamDataPlayer)[];
  individualDiscipline: SchoolEventIndividualDiscipline[];
}

export const ChildrenEventDisciplineSummaryTab: FunctionComponent<Props> = props => {
  const { schoolEvent, allTeamPlayers, individualDiscipline } = props;

  const renderPlayerDiscipline = player => {
    const sportDisciplineArray = propz.get(schoolEvent, ['sport', 'discipline'], []);

    return sportDisciplineArray.map(oneDiscipline => {
      // player Discipline data

      const pData = individualDiscipline.find(
        pUserData => pUserData.disciplineId === oneDiscipline._id && pUserData.userId === player.userId
      );
      const value = pData ? pData.value : 0;

      return (
        <div key={oneDiscipline._id} className="d-flex justify-content-between">
          <div className="font-italic">{oneDiscipline.name}</div>
          <div className="">
            <input min="0" className="eScorePoints" type="number" value={value} disabled={true} />
          </div>
        </div>
      );
    });
  };

  const rows = allTeamPlayers.map((player, index) => {
    const fullName = getFullName(player);
    return (
      <tr key={`discipline_summary_${index}`}>
        <td>{index + 1}</td>
        <td className="">
          <div className="font-weight-bold">{fullName}</div>
        </td>
        <td>
          <div className="">{renderPlayerDiscipline(player)}</div>
        </td>
        <td />
      </tr>
    );
  });

  const emptyRow = (
    <tr key={'players_empty_row'}>
      <td>No participants</td>
    </tr>
  );
  const columnDisciplineName = 'Discipline';
  return (
    <table className={'table table-sm mPre'}>
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>{columnDisciplineName}</th>
          <th />
        </tr>
      </thead>
      <tbody>{rows.length > 0 ? rows : emptyRow}</tbody>
    </table>
  );
};

ChildrenEventDisciplineSummaryTab.displayName = 'ChildrenEventDisciplineSummaryTab';
