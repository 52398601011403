import * as React from 'react';
import * as propz from 'propz';
import { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Moment from 'moment';
import { Report } from '../../../../../../models/report';
import { AppUser } from '../../../../../App/App';
import { Point } from '../../../../../../models/venue';
import { SPORT_GENDER_SERVER_TO_CLIENT_MAPPING } from '../../../../../../consts/common';
import { QUESTIONS_TEMPLATE_TYPE } from 'Src/consts/message';
import { addZeroToFirst } from '../../../../../../helpers/club/club';
import { getLocation } from '../../../../../../helpers/invite/invite';
import { getPoint } from '../../../../../../helpers/venue/venue';
import { getAgesGroupFormatted, getEventGeneratedName } from '../../../../../../helpers/accessor/accessor';
import {
  MESSAGE_INVITATION_STATUS,
  MESSAGE_INVITATION_STATUS_SERVER_TO_CLIENT_MAPPING,
  MESSAGE_KIND,
  MESSAGE_TYPE
} from '../../../../../../consts/message';
import { getStatus, isEventParticipationMessage } from '../../../../../../helpers/message/message';
import './ParentalConsentRequestSummaryTab.scss';
import { ROLE } from '../../../../../../consts/user';
import { Select } from '../../../../../../components/Select/Select';
import {
  getSelectOptionForBooleanQuestion,
  getSelectOptionForClubInvitationEnumQuestion
} from '../../../../../../helpers/table/select';
import { MessageField } from '../../../../../../models/message';
import { isNumeric, convertStringToBoolean } from '../../../../../../helpers/common/common';
import { INVITE_STATUS } from 'Src/consts/invite';
const SELECT_INDEX_OF_TRUE_VALUE = 0;
const SELECT_INDEX_OF_FALSE_VALUE = 1;

interface Props {
  consents: Report[];
  user: AppUser;
  eventName: string;
  onViewMapClick: (point: Point) => void;
  onAcceptClick: (fieldsUpdated: any, activeItemIndex: any) => void;
  onDeclineClick: (activeItemIndex: any) => void;
}

interface State {
  isVisibleConsents: boolean[];
  activeItemIndex: number;

  items: {
    fields: MessageField[];
    activeSelectOptionIndexes: number[];
    errors: Error[];
  }[];
}

interface Error {
  isError: boolean;
  errorText: string;
}

export class ParentalConsentRequestSummaryTab extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const items = props.consents.map(consent => {
      return {
        activeSelectOptionIndexes: [],
        fields: [],
        errors: []
      };
    });

    this.state = {
      isVisibleConsents: props.consents.map(consent => false),
      activeItemIndex: 0,
      items
    };
  }

  onClickMessage(index) {
    const { isVisibleConsents, items } = this.state;
    const changedIsVisibleConsents = [...isVisibleConsents];
    changedIsVisibleConsents[index] = !isVisibleConsents[index];

    const { consents } = this.props;
    const fields = propz.get(consents, [index, 'fields']);

    let fieldsUpdated;
    let activeSelectOptionIndexes = [];
    let errors = [];

    const isFieldsExist = typeof fields !== 'undefined';

    if (isFieldsExist) {
      fieldsUpdated = fields.map((field, index) => {
        const { value, type, enumOptions } = field;

        const isBooleanQuestion = type === QUESTIONS_TEMPLATE_TYPE.BOOLEAN;
        const isEnumType = field.type === QUESTIONS_TEMPLATE_TYPE.ENUM;
        const isFieldEmpty = field.value === '';

        switch (true) {
          case isBooleanQuestion && isFieldEmpty:
            activeSelectOptionIndexes[index] = SELECT_INDEX_OF_TRUE_VALUE;
            break;
          case isBooleanQuestion && !isFieldEmpty:
            const booleanValue = convertStringToBoolean(value);
            activeSelectOptionIndexes[index] = booleanValue ? SELECT_INDEX_OF_TRUE_VALUE : SELECT_INDEX_OF_FALSE_VALUE;
            break;

          case isEnumType && isFieldEmpty:
            activeSelectOptionIndexes[index] = 0;
            break;
          case isEnumType && !isFieldEmpty:
            const options = getSelectOptionForClubInvitationEnumQuestion(enumOptions);
            const activeOptionIndex = options.findIndex(option => option.value === field.value);
            activeSelectOptionIndexes[index] = activeOptionIndex;
            break;

          default:
            activeSelectOptionIndexes[index] = undefined;
            break;
        }

        errors[index] = { isError: false, errorText: '' };
        return { ...field, value: isFieldEmpty ? '' : value };
      });
    }

    let itemsUpdated = [...items];
    itemsUpdated[index] = {
      fields: isFieldsExist ? fieldsUpdated : [],
      activeSelectOptionIndexes: isFieldsExist ? activeSelectOptionIndexes : [],
      errors: isFieldsExist ? errors : []
    };

    this.setState({
      items: itemsUpdated,
      activeItemIndex: index,
      isVisibleConsents: changedIsVisibleConsents
    });
  }

  getLogoStyle(consent) {
    let schoolLogoStyle = {
      backgroundImage: `url(/images/no_image.jpg)`
    };

    const school = consent.schoolData;

    const pic = propz.get(school, ['pic'], undefined);
    if (typeof pic !== 'undefined') {
      schoolLogoStyle.backgroundImage = `url(${pic})`;
    }

    return schoolLogoStyle;
  }

  renderSchoolLogo(consent) {
    return <div className="eEventMessage_img" style={this.getLogoStyle(consent)}></div>;
  }

  getTypeForMessage(consent) {
    const { user } = this.props;
    const role = user.activeRole;
    const { isActionPerformed } = consent;
    if (role === ROLE.PARENT) {
      switch (true) {
        case isActionPerformed === true:
          return 'INBOX';
        case isActionPerformed === false:
          return 'ARCHIVE';
      }
    } else {
      switch (true) {
        case isActionPerformed === true:
          return 'OUTBOX';
        case isActionPerformed === false:
          return 'ARCHIVE';
      }
    }
  }

  renderEventInfo(consent) {
    const event = consent.eventData;
    const { eventName, user } = this.props;

    const gender = SPORT_GENDER_SERVER_TO_CLIENT_MAPPING[event.gender];
    const ageGroupFormatted = getAgesGroupFormatted(event, { user });

    const startTimeDataObject = new Date(event.startTime);
    const startDate = Moment(startTimeDataObject).format('DD.MM.YYYY');
    const hours = addZeroToFirst(startTimeDataObject.getHours());
    const minutes = addZeroToFirst(startTimeDataObject.getMinutes());

    return (
      <div className="">
        <div className="text-wrap">
          <br />
          {eventName} / {gender} / {ageGroupFormatted}
          <br />
          {startDate} / {hours + ':' + minutes}
        </div>
        <br />
        {this.renderVenue(consent)}
        <br />
      </div>
    );
  }

  renderVenue(consent): React.ReactNode {
    const eventLocation = getLocation(consent.eventData, undefined);
    const isShowMap = propz.get(consent, ['eventData', 'venue', 'venueType']) !== 'TBD';

    const venuePoint = propz.get(consent, ['eventData', 'venue', 'point']);
    const venuePostcodePoint = propz.get(consent, ['eventData', 'venue', 'postcodeData', 'point']);

    const point = getPoint(venuePoint, venuePostcodePoint);

    let result = null;
    if (isShowMap) {
      result = (
        <div className="eEventMessage_simpleField">
          <span className="eEventMessage_boldTag">Venue: </span>
          {eventLocation}
          <br />
          <div className="d-flex">
            <div className="eEventMessage_mapMarker" />
            <div className="eEventMessageVenue" onClick={() => this.props.onViewMapClick(point)}>
              View map
            </div>
          </div>
        </div>
      );
    }
    return result;
  }

  getText(consent): string {
    const player = consent.playerDetailsData;
    const firstName = propz.get(player, ['firstName'], '');
    const lastName = propz.get(player, ['lastName'], '');
    const playerName = `${firstName} ${lastName}`;
    const now = new Date();

    const deadlineForAnswers = propz.get(consent, ['deadlineForAnswers']);
    const isDeadlineExist = typeof deadlineForAnswers !== 'undefined';

    const isMessageInvitation = consent.kind === MESSAGE_KIND.EventInvitationMessage;
    const isMessageRefusal = consent.kind === MESSAGE_KIND.EventParticipationRefusalMessage;
    const isMessageAvailability = consent.kind === MESSAGE_KIND.EventParticipationMessage;
    const isMessageClubParticipantInvite = consent.kind === MESSAGE_KIND.ClubParticipantInviteMessage;
    const isMessageConfirmationRequest = consent.kind === MESSAGE_KIND.TournamentConfirmationRequestMessage;

    switch (true) {
      case isMessageInvitation && !isDeadlineExist:
      case isMessageInvitation && Number(new Date(deadlineForAnswers)) > Number(now):
        return `Do you give your consent for ${playerName} to take part in this fixture?`;
      case isMessageInvitation && Number(new Date(deadlineForAnswers)) < Number(now):
        return `Unfortunately, the deadline has passed and you cannot accept or decline this consent request.`;
      case isMessageClubParticipantInvite && Number(new Date(deadlineForAnswers)) < Number(now):
        return `Unfortunately, the deadline has passed and you cannot book space at the moment.`;
      case isMessageRefusal:
        return `${playerName} is not available to take part.`;
      case isMessageAvailability:
        const isTakePart = consent.isTakePart ? 'yes' : 'no';
        return `${playerName} can take part: ${isTakePart}`;
      case isMessageClubParticipantInvite && !isDeadlineExist:
      case isMessageClubParticipantInvite && Number(new Date(deadlineForAnswers)) > Number(now):
        return `You can book a place for your child now. To send a request for a place click the “Book” button.`;
      case isMessageConfirmationRequest:
        return `Please confirm your participation.`;
    }
  }

  getParentsByPlayer(consent) {
    let parents = [];

    const playerId = consent.playerDetailsData.id;
    const currentMessage = this.props.consents.find(consent => consent.playerDetailsData.id === playerId);
    if (typeof currentMessage !== 'undefined') {
      parents = currentMessage.playerDetailsData.parents ? currentMessage.playerDetailsData.parents : [];
    }
    return parents;
  }

  isRenderConsentRequestTemplate(consent): boolean {
    const type = this.getTypeForMessage(consent);

    return (
      (type === MESSAGE_TYPE.ARCHIVE && Array.isArray(consent.fields) && consent.fields.length > 0) ||
      (type === MESSAGE_TYPE.INBOX && Array.isArray(consent.fields) && consent.fields.length > 0)
    );
  }

  renderConsentText = consent => {
    const details = isEventParticipationMessage(consent) ? consent.details : '';

    return (
      <div className="">
        <div className="eEventMessageInfoText text-wrap">{this.getText(consent)}</div>
        <p>{details}</p>
      </div>
    );
  };

  renderStatus(consent) {
    const type = this.getTypeForMessage(consent);

    switch (type) {
      case MESSAGE_TYPE.ARCHIVE:
        const status = getStatus(consent, type);

        return (
          <div className="eInvite_message">
            <span className={'m' + status}>{status}</span>
          </div>
        );
      default:
        return null;
    }
  }

  onSubmit = itemIndex => {
    const { items } = this.state;
    const item = items[itemIndex];
    const { fields, errors } = item;

    let submitAbility = true;
    let fieldsUpdated = [...fields];

    const errorsUpdated = errors.map(item => {
      return {
        isError: false,
        errorText: ''
      };
    });

    fields.forEach((field, index) => {
      const isNumericField = isNumeric(field.value);
      const isNumberType = field.type === QUESTIONS_TEMPLATE_TYPE.NUMBER;
      const isBooleanType = field.type === QUESTIONS_TEMPLATE_TYPE.BOOLEAN;
      const isEnumType = field.type === QUESTIONS_TEMPLATE_TYPE.ENUM;
      const isFieldEmpty = field.value === '';

      if (isBooleanType) {
        let value;

        if (isFieldEmpty) {
          const options = getSelectOptionForBooleanQuestion();
          value = options[0].value;
        } else {
          value = propz.get(field, ['value']);
        }

        const booleanValue = convertStringToBoolean(value);
        propz.set(fieldsUpdated[index], ['value'], booleanValue);
      }

      if (isEnumType && isFieldEmpty) {
        const options = getSelectOptionForClubInvitationEnumQuestion(field.enumOptions);
        const value = options[0].value;
        propz.set(fieldsUpdated[index], ['value'], value);
      }

      if (isNumberType && !isNumericField && !isFieldEmpty) {
        errorsUpdated[index].isError = true;
        errorsUpdated[index].errorText = 'please enter a number';
        submitAbility = false;
      }

      const isFieldUpdatedEmpty = fieldsUpdated[index].value === '';

      if (isFieldUpdatedEmpty && field.isRequired) {
        errorsUpdated[index].isError = true;
        errorsUpdated[index].errorText = 'required';
        submitAbility = false;
      }
    });
    const { activeItemIndex } = this.state;

    if (submitAbility) {
      this.props.onAcceptClick(fieldsUpdated, activeItemIndex);
    } else {
      this.updateItemErrors(errorsUpdated, itemIndex);
    }
  };

  updateItemFields(fields, itemIndex) {
    const { items } = this.state;

    let itemsUpdated = [...items];
    itemsUpdated[itemIndex] = {
      ...itemsUpdated[itemIndex],
      fields
    };

    this.setState({
      items: itemsUpdated
    });
  }

  updateItemErrors(errors, itemIndex) {
    const { items } = this.state;

    let itemsUpdated = [...items];
    itemsUpdated[itemIndex] = {
      ...itemsUpdated[itemIndex],
      errors
    };

    this.setState({
      items: itemsUpdated
    });
  }

  updateItemActiveSelectOptions(activeSelectOptionIndexes, itemIndex) {
    const { items } = this.state;

    let itemsUpdated = [...items];
    itemsUpdated[itemIndex] = {
      ...itemsUpdated[itemIndex],
      activeSelectOptionIndexes
    };

    this.setState({
      items: itemsUpdated
    });
  }

  renderConsentRequestTemplate(consent, consentIndex): React.ReactNode {
    const { items } = this.state;
    const item = items[consentIndex];
    const itemUpdated = { ...item };
    const { fields, activeSelectOptionIndexes, errors } = itemUpdated;

    const isFieldsExist = fields.length > 0;
    const fieldsUpdated = [...fields];
    const { invitationStatus } = consent;
    const isDisabledConsentRequestTemplate = invitationStatus !== INVITE_STATUS.NOT_READY;

    if (this.isRenderConsentRequestTemplate(consent)) {
      return (
        <>
          {isFieldsExist &&
            fieldsUpdated.map((field, index) => (
              <div key={`club_invitation_form_field_${index}`}>
                <div
                  className={
                    field.isRequired
                      ? 'eClubInvitationFormFieldLabel font-weight-bold text-left'
                      : 'eClubInvitationFormFieldLabel text-left'
                  }
                >
                  {field.heading} {field.isRequired ? '(required)' : ''}
                </div>

                {(field.type === QUESTIONS_TEMPLATE_TYPE.STRING || field.type === QUESTIONS_TEMPLATE_TYPE.NUMBER) && (
                  <input
                    type="text"
                    className="form-control mb-3"
                    value={field.value}
                    disabled={isDisabledConsentRequestTemplate}
                    onChange={event => {
                      propz.set(fieldsUpdated[index], ['value'], event.target.value);
                      this.updateItemFields(fieldsUpdated, index);
                    }}
                  />
                )}
                {field.type === QUESTIONS_TEMPLATE_TYPE.BOOLEAN && (
                  <Select
                    onChange={value => {
                      const options = getSelectOptionForBooleanQuestion();
                      const nextIndex = options.findIndex(option => option.value === value);
                      const activeSelectOptionIndexesUpdated = [...activeSelectOptionIndexes];
                      activeSelectOptionIndexesUpdated[index] = nextIndex;
                      propz.set(fieldsUpdated[index], ['value'], value);

                      this.updateItemActiveSelectOptions(activeSelectOptionIndexesUpdated, consentIndex);
                    }}
                    options={getSelectOptionForBooleanQuestion()}
                    activeResultIndex={activeSelectOptionIndexes[index]}
                    customClass={'form-control mb-3'}
                    disabled={isDisabledConsentRequestTemplate}
                  />
                )}
                {field.type === QUESTIONS_TEMPLATE_TYPE.ENUM && (
                  <Select
                    onChange={value => {
                      const options = getSelectOptionForClubInvitationEnumQuestion(field.enumOptions);
                      const nextIndex = options.findIndex(option => option.value === value);
                      const activeSelectOptionIndexesUpdated = [...activeSelectOptionIndexes];
                      activeSelectOptionIndexesUpdated[index] = nextIndex;
                      propz.set(fieldsUpdated[index], ['value'], value);

                      this.updateItemActiveSelectOptions(activeSelectOptionIndexesUpdated, consentIndex);
                    }}
                    options={getSelectOptionForClubInvitationEnumQuestion(field.enumOptions)}
                    activeResultIndex={activeSelectOptionIndexes[index]}
                    customClass={'form-control mb-3'}
                    disabled={isDisabledConsentRequestTemplate}
                  />
                )}
                {errors[index].isError && <div className="alert alert-danger mb-4">{errors[index].errorText}</div>}
              </div>
            ))}
        </>
      );
    } else {
      return null;
    }
  }

  renderConsentRequestInfo = (consent, index) => {
    const firstName = propz.get(consent, ['playerDetailsData', 'firstName'], '');
    const lastName = propz.get(consent, ['playerDetailsData', 'lastName'], '');
    const fullName = `${firstName} ${lastName}`;
    const school = consent.schoolData;
    const deadlineForAnswers = propz.get(consent, ['deadlineForAnswers']);
    const isDeadlineExist = typeof deadlineForAnswers !== 'undefined';
    const deadlineForAnswersFormatted = isDeadlineExist
      ? Moment(deadlineForAnswers).format('DD.MM.YYYY / HH:mm')
      : Moment(new Date()).format('DD.MM.YYYY / HH:mm');
    const { invitationStatus } = consent;
    const now = new Date();
    const isDeadlineArrived = Number(new Date(deadlineForAnswers)) < Number(now);

    const isInvitationStatusPending = invitationStatus === INVITE_STATUS.NOT_READY;

    const isAvailableByDeadline = !(isDeadlineExist && isDeadlineArrived);

    const isInvitationAvailable = isInvitationStatusPending && isAvailableByDeadline;

    return (
      <tr key={`parent_consent_request_${index}`}>
        <td colSpan={6}>
          <div className="container-fluid">
            <div className="row eEventMessageInfoSection">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-4">{this.renderSchoolLogo(consent)}</div>

                  <div className="col-md-8">
                    <div className="eEventMessageInfoText">{fullName}</div>
                    <div className="eEventMessageInfoText">{school.name}</div>
                    {this.renderEventInfo(consent)}
                  </div>
                </div>

                <div>{this.renderConsentText(consent)}</div>

                {isDeadlineExist && (
                  <div className="eInvite_text">{`Deadline for answers: ${deadlineForAnswersFormatted}`}</div>
                )}

                {this.renderStatus(consent)}
              </div>

              <div className="col-md-6">
                <div className="bConsentRequestTemplate">{this.renderConsentRequestTemplate(consent, index)}</div>
              </div>
              {isInvitationAvailable && (
                <div className="eButtonWrap">
                  <button onClick={() => this.props.onDeclineClick(index)} className="btn btn-primary mr-2">
                    Decline
                  </button>
                  <button onClick={() => this.onSubmit(index)} className="btn btn-primary mr-2">
                    Accept
                  </button>
                </div>
              )}
            </div>
          </div>
        </td>
      </tr>
    );
  };

  renderStatusByPlayerAndMessage(consent) {
    const { invitationStatus } = consent;
    let status = '';
    switch (true) {
      case this.getParentsByPlayer(consent).length === 0 && invitationStatus.status === 'NOT_READY': {
        status = 'Not sent';
        break;
      }
      default: {
        status = MESSAGE_INVITATION_STATUS_SERVER_TO_CLIENT_MAPPING[invitationStatus];
        break;
      }
    }
    return status;
  }

  renderParents(consent) {
    const parents = this.getParentsByPlayer(consent);

    if (Array.isArray(parents) && parents.length > 0) {
      return parents.map((parent, index) => {
        return <div key={`parent_${index}`}>{parent}</div>;
      });
    } else {
      return null;
    }
  }

  renderRows() {
    const { consents } = this.props;
    const { isVisibleConsents } = this.state;

    return consents.map((consent, index) => {
      const { playerDetailsData, invitationStatus } = consent;
      const name = `${playerDetailsData.firstName} ${playerDetailsData.lastName}`;
      const isShowInfoConsent = isVisibleConsents[index];
      const isInvitationStatusNoSent = invitationStatus === MESSAGE_INVITATION_STATUS.NOT_SENT;
      const rowClassName = isInvitationStatusNoSent ? '' : 'eEventMessagesRow';
      const consentCreateDate = Moment(consent.createdAt).format('DD-MM-YYYY HH:mm');
      return (
        <>
          <tr
            key={`parent_consent_summary_${index}`}
            onClick={() => {
              if (isInvitationStatusNoSent) {
                () => {};
              } else {
                this.onClickMessage(index);
              }
            }}
            className={rowClassName}
          >
            <td>
              <div>{name}</div>
            </td>
            <td>{this.renderParents(consent)}</td>
            <td>
              <div>{consentCreateDate}</div>
            </td>
            <td>
              <div>{this.renderStatusByPlayerAndMessage(consent)}</div>
            </td>
            <td>
              {!isInvitationStatusNoSent && (
                <FontAwesomeIcon icon={isShowInfoConsent ? 'chevron-up' : 'chevron-down'} className="" />
              )}
            </td>
          </tr>
          {isShowInfoConsent && this.renderConsentRequestInfo(consent, index)}
        </>
      );
    });
  }

  renderEmptyRow() {
    return (
      <tr key={'parent_message_empty_row'}>
        <td>There are no messages to display.</td>
      </tr>
    );
  }

  render() {
    const { consents } = this.props;

    return (
      <table className={'table table-sm mPre'}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Parents</th>
            <th>Date</th>
            <th>Status</th>
            <th />
          </tr>
        </thead>
        <tbody>{consents.length > 0 ? this.renderRows() : this.renderEmptyRow()}</tbody>
      </table>
    );
  }
}
