import * as React from 'react';
import { Component } from 'react';
import * as propz from 'propz';
import * as Moment from 'moment';
import { AppUser } from 'Src/views/App/App';
import { Map } from 'Src/components/Map/Map';
import { Button } from 'Src/components/Button/Button';
import { TournamentConfirmationRequestMessage } from 'Src/models/message';
import { VENUE_SERVER_TO_CLIENT_MAPPING } from '../../../../../consts/venue';
import { TournamentInfo } from './TournamentInfo/TournamentInfo';
import { MessageField } from 'Src/models/message';
import { QUESTIONS_TEMPLATE_TYPE } from 'Src/consts/message';
import { Select } from 'Src/components/Select/Select';
import {
  getSelectOptionForBooleanQuestion,
  getSelectOptionForClubInvitationEnumQuestion
} from 'Src/helpers/table/select';
import { isNumeric } from 'Src/helpers/common/common';

interface Props {
  message: TournamentConfirmationRequestMessage;
  isEditable: boolean;
  onAcceptClick: (data: MessageField[]) => void;
  onDeclineClick: () => void;
  onCloseClick: () => void;
}

interface State {
  fields: MessageField[];
  isMapVisible: boolean;
  activeSelectIndex: number[];
  errors: Error[];
}

interface Error {
  isError: boolean;
  errorText: string;
}

export class ConfirmationRequestForm extends Component<Props, State> {
  constructor(props) {
    super(props);
    const { message } = this.props;
    const fields = propz.get(message, ['fields']);

    let fieldsUpdated;
    let activeSelectIndex = [];
    let errors = [];

    const isFieldsExist = typeof fields !== 'undefined' && fields.length > 0;
    if (isFieldsExist) {
      fieldsUpdated = fields.map((field, index) => {
        activeSelectIndex[index] = 0;
        errors[index] = { isError: false, errorText: '' };
        return { ...field, value: '' };
      });
    }

    this.state = {
      fields: isFieldsExist ? fieldsUpdated : [],
      isMapVisible: false,
      activeSelectIndex: isFieldsExist ? activeSelectIndex : [],
      errors: isFieldsExist ? errors : []
    };
  }

  onSubmit = () => {
    const { fields, errors } = this.state;
    let submitAbility = true;
    let fieldsUpdated = [...fields];

    const errorsUpdated = errors.map(item => {
      return {
        isError: false,
        errorText: ''
      };
    });

    fields.forEach((field, index) => {
      const isNumericField = isNumeric(field.value);
      const isNumberType = field.type === QUESTIONS_TEMPLATE_TYPE.NUMBER;
      const isBooleanType = field.type === QUESTIONS_TEMPLATE_TYPE.BOOLEAN;
      const isEnumType = field.type === QUESTIONS_TEMPLATE_TYPE.ENUM;
      const isFieldEmpty = field.value === '';

      if (isBooleanType && isFieldEmpty) {
        const options = getSelectOptionForBooleanQuestion();
        const value = options[0].value;
        propz.set(fieldsUpdated[index], ['value'], value);
      }

      if (isEnumType && isFieldEmpty) {
        const options = getSelectOptionForClubInvitationEnumQuestion(field.enumOptions);
        const value = options[0].value;
        propz.set(fieldsUpdated[index], ['value'], value);
      }

      if (isNumberType && !isNumericField && !isFieldEmpty) {
        errorsUpdated[index].isError = true;
        errorsUpdated[index].errorText = 'please enter a number';
        submitAbility = false;
      }

      const isFieldUpdatedEmpty = fieldsUpdated[index].value === '';

      if (isFieldUpdatedEmpty && field.isRequired) {
        errorsUpdated[index].isError = true;
        errorsUpdated[index].errorText = 'required';
        submitAbility = false;
      }
    });

    if (submitAbility) {
      this.props.onAcceptClick(fieldsUpdated);
    } else {
      this.setState({
        errors: errorsUpdated
      });
    }
  };

  toggleMap = () => {
    const { isMapVisible } = this.state;
    this.setState({
      isMapVisible: !isMapVisible
    });
  };

  render() {
    const { message, isEditable, onAcceptClick, onDeclineClick, onCloseClick } = this.props;
    const { activeSelectIndex, errors, fields, isMapVisible } = this.state;
    const picUrl = propz.get(message, ['schoolData', 'pic'], '');
    const isSchoolLogoExist = picUrl !== '';
    const fieldsUpdated = [...fields];
    const isFieldsExist = fields.length > 0;
    const point = propz.get(message, ['tournamentData', 'venue', 'point']);

    return (
      <>
        <div className={'eClubInvitationFormRow'}>
          <img
            src={isSchoolLogoExist ? picUrl : '/dist/images/no-image.jpg'}
            alt="logo"
            className={'bClubInvitationFormLogo'}
          />
          <TournamentInfo message={message} onMapClick={this.toggleMap} />
        </div>

        {isMapVisible && <Map point={point} customStylingClass="mb-3 eClubWizardMap" isMarkerDraggable={false} />}

        <div className={'font-weight-bold mt-3 mb-3'}>Please confirm your participation.</div>

        {isFieldsExist &&
          fieldsUpdated.map((field, index) => (
            <div key={`club_invitation_form_field_${index}`}>
              <div className={field.isRequired ? 'font-weight-bold' : ''}>
                {field.heading} {field.isRequired ? '(required)' : ''}
              </div>

              {(field.type === QUESTIONS_TEMPLATE_TYPE.STRING || field.type === QUESTIONS_TEMPLATE_TYPE.NUMBER) && (
                <input
                  type="text"
                  className="form-control"
                  value={field.value}
                  onChange={event => {
                    propz.set(fieldsUpdated[index], ['value'], event.target.value);
                    this.setState({ fields: fieldsUpdated });
                  }}
                />
              )}
              {field.type === QUESTIONS_TEMPLATE_TYPE.BOOLEAN && (
                <Select
                  onChange={value => {
                    const options = getSelectOptionForBooleanQuestion();
                    const nextIndex = options.findIndex(option => option.value === value);
                    const activeSelectIndexUpdated = [...activeSelectIndex];
                    activeSelectIndexUpdated[index] = nextIndex;
                    propz.set(fieldsUpdated[index], ['value'], value);

                    this.setState({
                      activeSelectIndex: activeSelectIndexUpdated
                    });
                  }}
                  options={getSelectOptionForBooleanQuestion()}
                  activeResultIndex={activeSelectIndex[index]}
                  customClass={'form-control'}
                />
              )}
              {field.type === QUESTIONS_TEMPLATE_TYPE.ENUM && (
                <Select
                  onChange={value => {
                    const options = getSelectOptionForClubInvitationEnumQuestion(field.enumOptions);
                    const nextIndex = options.findIndex(option => option.value === value);
                    const activeSelectIndexUpdated = [...activeSelectIndex];
                    activeSelectIndexUpdated[index] = nextIndex;
                    propz.set(fieldsUpdated[index], ['value'], value);

                    this.setState({
                      activeSelectIndex: activeSelectIndexUpdated
                    });
                  }}
                  options={getSelectOptionForClubInvitationEnumQuestion(field.enumOptions)}
                  activeResultIndex={activeSelectIndex[index]}
                  customClass={'form-control'}
                />
              )}
              {errors[index].isError && <div className="alert alert-danger mt-3">{errors[index].errorText}</div>}
            </div>
          ))}
        {isEditable && (
          <>
            <Button onClick={onCloseClick} text={'Close'} customClass={'btn-secondary mt-3 mb-3 mr-2'} />
            <Button onClick={onDeclineClick} text={'Decline'} customClass={'mt-3 mb-3 mr-2'} />
            <Button onClick={this.onSubmit} text={'Accept'} customClass={'mt-3 mb-3 mr-2'} />
          </>
        )}
      </>
    );
  }
}
