import * as React from 'react';
import { FunctionComponent } from 'react';
import * as propz from 'propz';
import { getIsParticipant } from '../../helpers/accessor/accessor';
import { Tournament } from '../../models/tournament';

interface Props {
  tournament: Tournament;
  eligibleSchools: any[];
}

export const TournamentEligibleSchoolsSummary: FunctionComponent<Props> = props => {
  const { eligibleSchools } = props;

  const rows = eligibleSchools.map((school, index) => {
    return (
      <tr key={`tournament_eligibleSchools_summary_${index}`}>
        <td>{index + 1}</td>
        <td>{propz.get(school, ['name'], '')}</td>
        <td>{getIsParticipant(school)}</td>
      </tr>
    );
  });

  return (
    <table className={'table table-sm mPre'}>
      <thead>
        <tr key={`tournament_eligibleSchools_summary_heading`}>
          <th />
          <th>Name</th>
          <th>Participant</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
};

TournamentEligibleSchoolsSummary.displayName = 'TournamentEligibleSchoolsSummary';
