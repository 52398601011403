import * as React from 'react';
import * as BPromise from 'bluebird';
import * as propz from 'propz';
import { AppUser } from 'Src/views/App/App';
import { parse } from 'query-string';
import { getOrder, getSearchFilter, getSearchOrder, getServerFieldSectionWhere } from 'Src/helpers/table/table';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { Loader } from 'Src/components/Loader/Loader';
import {
  ColumnDefinition,
  getFilters2,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { History, Location } from 'history';
import { Grid2 } from 'Src/components/Grid/Grid2';
import {
  getCreatedAtWithTime,
  getMessageActionStatus,
  getMessageInvitationStatus,
  getMessageTakePart,
  getMessageTypeByKind
} from 'Src/helpers/accessor/accessor';
import { MessageDefinition } from 'Src/types/message';
import {
  getParentAllInboxMessages,
  getParentInboxMessages,
  getParentInboxMessagesCount
} from 'Src/helpers/service/parent/messagesTable';
import { getSelectOptionsForMessageActionStatus, getSelectOptionsForMessageKindByType } from 'Src/helpers/table/select';
import { ResizablePanel } from 'Src/components/ResizablePanel/ResizablePanel';
import { MESSAGE_TABS } from 'Src/consts/message';
import { MessageInboxOutboxSummary } from 'Src/components/MessageSummary/MessageInboxOutboxSummary';
import { GenericSummary } from 'Src/components/GenericSummary/GenericSummary';
import { MessageAdditionalSummary } from 'Src/components/MessageSummary/MessageAdditionalSummary';
import { isClubParticipantInviteMessage } from 'Src/helpers/message/message';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { ClubParticipantInviteMessage } from 'Src/models/message';
import { rejectParentClubInvitation, acceptParentClubInvitation } from 'Src/helpers/service/parent/messagesTable';
import { ClubInvitationForm } from './ClubInvitationForm/ClubInvitationForm';
import { MessageField } from 'Src/models/message';
import './MessagesInbox.scss';
import { isEventInvitationMessage, isEventParticipationMessage } from '../../../../helpers/message/message';
import { ParentalConsentRequestForm } from './ParentalConsentRequestForm/ParentalConsentRequestForm';
import { EventInvitationMessage, EventParticipationMessage } from '../../../../models/message';
import {
  acceptParentConsentRequest,
  rejectParentConsentRequest,
  updateParentConsentRequest
} from '../../../../helpers/service/parent/messagesTable';
import { SchoolEvent } from '../../../../models/event';
import { getChildrenEvent } from '../../../../helpers/service/parent/childrenEvents';
import { getEventNameForParent } from '../../../../helpers/accessor/accessor';
import { getChildren } from '../../../../helpers/service/parent/children';
import { setMessageWaitingStatus } from 'Src/helpers/service/parent/messagesTable';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Message type',
    field: 'messageType',
    isSort: false,
    type: FILTER_TYPE.SELECT,
    accessor: getMessageTypeByKind
  },
  {
    text: 'Student name',
    field: 'studentFirstName',
    isSort: false,
    type: FILTER_TYPE.TEXT,
    accessor: ['playerDetailsData', 'firstName']
  },
  {
    text: 'Student surname',
    field: 'studentLastName',
    isSort: false,
    type: FILTER_TYPE.TEXT,
    accessor: ['playerDetailsData', 'lastName']
  },
  {
    text: 'Created at',
    field: 'createdAt',
    isSort: false,
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: getCreatedAtWithTime
  },
  {
    text: 'Status',
    field: 'messageInvitationStatus',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getMessageInvitationStatus
  },
  {
    text: 'Status authorize',
    field: 'messageActionStatus',
    isSort: false,
    type: FILTER_TYPE.SELECT,
    accessor: getMessageActionStatus
  },
  {
    text: 'Take part',
    field: 'messageTakePart',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getMessageTakePart
  }
];

interface State {
  items: MessageDefinition[];
  schoolEvent: SchoolEvent;
  currentPage: number;
  selectedItems: MessageDefinition[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  userTags: any[];
  total: number;
  basePath: string;
  childIdList: string[];

  currentTabIndex: number;
  isClubInvitationModalOpen: boolean;
  isConsentRequestModalOpen: boolean;
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
  setNeedUpdate: () => void;
}

export class ParentMessagesInbox extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      schoolEvent: undefined,
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      userTags: [],
      total: 0,
      basePath: '',
      childIdList: [],

      currentTabIndex: 0,
      isClubInvitationModalOpen: false,
      isConsentRequestModalOpen: false
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setItems();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const getItemsPromise = getParentInboxMessages(user, serverQueryFilter);
    const getItemsCountPromise = getParentInboxMessagesCount(user, where);
    const getChildrenPromise = getChildren(user);

    const promises = [getItemsCountPromise, getItemsPromise, getChildrenPromise];

    return BPromise.all(promises).then(([countObj, items, children]) => {
      const childIdList = children.map(child => child.id);
      this.setState({
        currentPage: page,
        sortDirection: sortDirection,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: items,
        total: countObj.count,
        basePath: history.location.pathname,
        isLoading: false,
        childIdList
      });

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onItemClick = (index: number): void => {
    const { user } = this.props;
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    const message = selectedItemsUpdated[0];
    const eventId = propz.get(message, ['eventData', 'id']);

    if (selectedItemsUpdated.length === 1 && typeof eventId !== 'undefined') {
      this.setState({
        isLoading: true
      });

      getChildrenEvent(user, eventId).then(schoolEvent => {
        this.setState({
          schoolEvent: schoolEvent,
          isLoading: false,
          selectedItems: selectedItemsUpdated
        });
      });
    } else {
      this.setState({
        schoolEvent: undefined,
        selectedItems: selectedItemsUpdated
      });
    }
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: nextSelectedItems,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    getParentAllInboxMessages(user, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const nextSelectedItems = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: nextSelectedItems,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: ''
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  onDeclineClick = () => {
    const { user, setNeedUpdate } = this.props;
    const { selectedItems } = this.state;

    this.setState({
      isLoading: true
    });

    rejectParentClubInvitation(user, selectedItems[0].id).then(() => {
      this.setState({
        isClubInvitationModalOpen: false,
        selectedItems: []
      });
      this.setItems().then(() => {
        setNeedUpdate();
      });
    });
  };

  onBookNowClick = (fields: MessageField[], priority: number) => {
    const { user, setNeedUpdate } = this.props;
    const { selectedItems } = this.state;

    this.setState({
      isLoading: true
    });

    const data = { fields, priority };

    acceptParentClubInvitation(user, selectedItems[0].id, data).then(() => {
      this.setState({
        isClubInvitationModalOpen: false,
        selectedItems: []
      });
      this.setItems().then(() => {
        setNeedUpdate();
      });
    });
  };

  onAddToWaitingListClick = () => {
    const { user } = this.props;
    const { selectedItems } = this.state;

    setMessageWaitingStatus(user, selectedItems[0].id).then(() => {
      this.setState({
        isClubInvitationModalOpen: false,
        selectedItems: []
      });
      this.setItems();
    });
  };

  onViewClick = () => {
    const { selectedItems } = this.state;
    const isViewClubInvitationActive = isClubParticipantInviteMessage(selectedItems[0]);

    if (isViewClubInvitationActive) {
      this.setState({
        isClubInvitationModalOpen: true
      });
    } else {
      this.setState({
        isConsentRequestModalOpen: true
      });
    }
  };

  closeClubInvitationModal = () => {
    this.setState({
      isClubInvitationModalOpen: false
    });
    this.setItems();
  };

  renderClubInvitationModal = () => {
    const { user } = this.props;
    const { isClubInvitationModalOpen, selectedItems } = this.state;
    const message = selectedItems[0] as ClubParticipantInviteMessage;

    return (
      <SimpleModal customClass="mClubInvitationModalWidth" isOpen={isClubInvitationModalOpen}>
        <ClubInvitationForm
          user={user}
          message={message}
          onDeclineClick={this.onDeclineClick}
          onBookNowClick={this.onBookNowClick}
          onAddToWaitingListClick={this.onAddToWaitingListClick}
          closeClubInvitationModal={this.closeClubInvitationModal}
        />
      </SimpleModal>
    );
  };

  closeConsentRequestModal = () => {
    this.setState({
      isConsentRequestModalOpen: false
    });
  };

  onDeclineConsentRequestClick = () => {
    const { user, setNeedUpdate } = this.props;
    const { selectedItems } = this.state;

    this.setState({
      isLoading: true
    });

    rejectParentConsentRequest(user, selectedItems[0].id).then(() => {
      this.setState({
        isConsentRequestModalOpen: false,
        selectedItems: []
      });
      this.setItems().then(() => {
        setNeedUpdate();
      });
    });
  };

  onAcceptConsentRequestClick = (fields: MessageField[]) => {
    const { user, setNeedUpdate } = this.props;
    const { selectedItems } = this.state;

    this.setState({
      isLoading: true
    });

    const data = { fields };

    acceptParentConsentRequest(user, selectedItems[0].id)
      .then(() => {
        return updateParentConsentRequest(user, selectedItems[0].id, data);
      })
      .then(() => {
        this.setState({
          isConsentRequestModalOpen: false,
          selectedItems: []
        });
        this.setItems().then(() => {
          setNeedUpdate();
        });
      });
  };

  renderConsentRequestModal = () => {
    const { user } = this.props;
    const { isConsentRequestModalOpen, selectedItems, schoolEvent, childIdList } = this.state;
    const message = selectedItems[0] as EventInvitationMessage;

    let eventName = '';
    if (typeof schoolEvent !== 'undefined') {
      eventName = getEventNameForParent(schoolEvent, childIdList);
    }

    return (
      <SimpleModal customClass="mConsentRequestModalWidth" isOpen={isConsentRequestModalOpen}>
        <ParentalConsentRequestForm
          user={user}
          message={message}
          onDeclineClick={this.onDeclineConsentRequestClick}
          onAcceptClick={this.onAcceptConsentRequestClick}
          closeConsentRequestModal={this.closeConsentRequestModal}
          eventName={eventName}
        />
      </SimpleModal>
    );
  };

  renderMessageTable(): React.ReactNode {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isSelectAllChecked,
      currentPage,
      total
    } = this.state;

    const isViewClubInvitationActive = selectedItems.length === 1 && isClubParticipantInviteMessage(selectedItems[0]);
    const isViewParentalConsentInvitationActive =
      selectedItems.length === 1 && isEventInvitationMessage(selectedItems[0]);

    const actionItems = [
      {
        itemText: 'View',
        onItemClick: this.onViewClick,
        isActive: isViewClubInvitationActive || isViewParentalConsentInvitationActive
      }
    ];

    const filterOptions = {
      messageType: getSelectOptionsForMessageKindByType(),
      messageActionStatus: getSelectOptionsForMessageActionStatus()
    };

    return (
      <Grid2
        dataItems={items}
        filters={filters}
        currentPage={currentPage}
        total={total}
        isSelectAllChecked={isSelectAllChecked}
        isDataFiltered={isDataFiltered}
        sortDirection={sortDirection}
        sortColumnsName={sortColumnsName}
        isShowFilter={isShowFilter}
        dataItemsSelected={selectedItems}
        columns={COLUMNS}
        actionItems={actionItems}
        options={filterOptions}
        onItemClick={this.onItemClick}
        onSortClick={this.onTableSortClick}
        onApplyFilterClick={this.onApplyFilterClick}
        onClearFilterClick={this.onClearFilterClick}
        onTableFilterChange={this.onTableFilterChange}
        onTableFilterClick={this.onTableFilterClick}
        setCurrentPageParams={this.setCurrentPageParams}
        onSelectAllClick={this.onSelectAllClick}
        onSelectAllOnPageClick={this.onSelectAllOnPageClick}
        onUnselectAllClick={this.onUnselectAllClick}
      />
    );
  }

  isOneItemSelected(): boolean {
    const selectedItems = this.state.selectedItems;
    return selectedItems.length === 1;
  }

  renderSummary(): React.ReactNode {
    const isOneItemSelected = this.isOneItemSelected();

    if (!isOneItemSelected) {
      return <div className="font-weight-bold">Select a message above</div>;
    }

    const { currentTabIndex } = this.state;
    const summaryTabs = this.getSummaryTabs();

    return (
      <GenericSummary onTabClick={this.onTabClick} tabs={MESSAGE_TABS} currentTabIndex={currentTabIndex}>
        {summaryTabs}
      </GenericSummary>
    );
  }

  onTabClick = (event, index: number) => {
    event.preventDefault();

    this.setState({
      currentTabIndex: index
    });
  };

  getSummaryTabs(): React.ReactNode {
    const { currentTabIndex, selectedItems, schoolEvent, childIdList } = this.state;
    const { user } = this.props;

    const message = selectedItems[0];
    let eventName = '';
    if (typeof schoolEvent !== 'undefined') {
      eventName = getEventNameForParent(schoolEvent, childIdList);
    }
    switch (currentTabIndex) {
      case 0:
        return (
          <MessageInboxOutboxSummary message={message} user={user} schoolEvent={schoolEvent} eventName={eventName} />
        );
      case 1:
        return <MessageAdditionalSummary message={message} />;
    }
  }

  render() {
    const { isLoading, isClubInvitationModalOpen } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes = isClubInvitationModalOpen ? 'mt-3 modal-open' : 'mt-3';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderClubInvitationModal()}
        {this.renderConsentRequestModal()}
        <div className="row">
          <div className="col-md-12">
            <ResizablePanel>
              {this.renderMessageTable()}
              {this.renderSummary()}
            </ResizablePanel>
          </div>
        </div>
      </div>
    );
  }
}
