import * as React from 'react';
import * as propz from 'propz';
import { ROLE } from '../../consts/user';
import { AppUser } from '../../views/App/App';
import { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './SchoolParentalConsentSummary.scss';
import * as Moment from 'moment';
import { addZeroToFirst } from '../../helpers/club/club';
import { SPORT_GENDER_SERVER_TO_CLIENT_MAPPING } from '../../consts/common';
import { getAgeGroup, getEventGeneratedName } from '../../helpers/accessor/accessor';
import { getStatus, isEventParticipationMessage } from '../../helpers/message/message';
import {
  MESSAGE_INVITATION_STATUS,
  MESSAGE_INVITATION_STATUS_SERVER_TO_CLIENT_MAPPING,
  MESSAGE_KIND,
  MESSAGE_TYPE
} from '../../consts/message';
import { getPoint } from '../../helpers/venue/venue';
import { getLocation } from '../../helpers/invite/invite';
import { Report } from '../../models/report';
import { Point } from '../../models/venue';
import { ConsentRequestTemplate } from '../ConsentRequestTemplate/ConsentRequestTemplate';
import { SchoolEvent } from '../../models/event';

interface Props {
  consents: Report[];
  schoolEvent: SchoolEvent;
  user: AppUser;
  onViewMapClick: (point: Point) => void;
}

interface State {
  isVisibleConsents: boolean[];
}

export class SchoolParentalConsentSummary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isVisibleConsents: props.consents.map(report => false)
    };
  }

  onClickMessage(index) {
    const { isVisibleConsents } = this.state;
    const changedIsVisibleConsents = [...isVisibleConsents];
    changedIsVisibleConsents[index] = !isVisibleConsents[index];
    this.setState({
      isVisibleConsents: changedIsVisibleConsents
    });
  }

  getLogoStyle(consent) {
    let schoolLogoStyle = {
      backgroundImage: `url(/images/no_image.jpg)`
    };

    const school = consent.schoolData;

    const pic = propz.get(school, ['pic'], undefined);
    if (typeof pic !== 'undefined') {
      schoolLogoStyle.backgroundImage = `url(${pic})`;
    }

    return schoolLogoStyle;
  }

  renderSchoolLogo(consent) {
    return <div className="eEventMessage_img" style={this.getLogoStyle(consent)}></div>;
  }

  getTypeForMessage(consent) {
    const { user } = this.props;
    const role = user.activeRole;
    const { isActionPerformed } = consent;
    if (role !== ROLE.PARENT) {
      switch (true) {
        case isActionPerformed === false:
          return 'INBOX';
        case isActionPerformed === true:
          return 'ARCHIVE';
      }
    } else {
      switch (true) {
        case isActionPerformed === false:
          return 'OUTBOX';
        case isActionPerformed === true:
          return 'ARCHIVE';
      }
    }
  }

  renderEventInfo(consent) {
    const { user, schoolEvent } = this.props;
    const { eventData: event, schoolData: school } = consent;
    const gender = SPORT_GENDER_SERVER_TO_CLIENT_MAPPING[event.gender];
    const startTimeDataObject = new Date(event.startTime);
    const startDate = Moment(startTimeDataObject).format('DD.MM.YYYY');
    const hours = addZeroToFirst(startTimeDataObject.getHours());
    const minutes = addZeroToFirst(startTimeDataObject.getMinutes());
    const eventOfficialName = getEventGeneratedName(schoolEvent, { user: user });
    const firstName = propz.get(consent, ['playerDetailsData', 'firstName'], '');
    const lastName = propz.get(consent, ['playerDetailsData', 'lastName'], '');
    const fullName = `${firstName} ${lastName}`;

    return (
      <div>
        <p className="mb-0">
          <span className="font-weight-bold">Student name: </span>
          {fullName}
        </p>
        <p className="mb-0">
          <span className="font-weight-bold">School name: </span>
          {school.name}
        </p>
        <p className="mb-0">
          <span className="font-weight-bold">Event name: </span>
          {eventOfficialName}
        </p>
        <p className="mb-0">
          <span className="font-weight-bold">Gender: </span>
          {gender}
        </p>
        <p className="mb-0">
          <span className="font-weight-bold">Ages: </span>
          {getAgeGroup(event)}
        </p>
        <p className="mb-0">
          <span className="font-weight-bold">Start time: </span>
          {startDate} / {hours + ':' + minutes}
        </p>
        <p className="mb-2">{this.renderVenue(consent)}</p>
      </div>
    );
  }

  renderVenue(consent): React.ReactNode {
    const eventLocation = getLocation(consent.eventData, undefined);
    const isShowMap = propz.get(consent, ['eventData', 'venue', 'venueType']) !== 'TBD';

    const venuePoint = propz.get(consent, ['eventData', 'venue', 'point']);
    const venuePostcodePoint = propz.get(consent, ['eventData', 'venue', 'postcodeData', 'point']);

    const point = getPoint(venuePoint, venuePostcodePoint);

    let result = null;
    if (isShowMap) {
      result = (
        <div className="eEventMessage_simpleField">
          <span className="font-weight-bold">Venue: </span>
          {eventLocation}
          <br />
          <div className="d-flex mt-2">
            <div className="eEventMessage_mapMarker" />
            <div className="eEventMessageVenue" onClick={() => this.props.onViewMapClick(point)}>
              View map
            </div>
          </div>
        </div>
      );
    }

    return result;
  }

  getText(consent): string {
    const player = consent.playerDetailsData;
    const firstName = propz.get(player, ['firstName'], '');
    const lastName = propz.get(player, ['lastName'], '');
    const playerName = `${firstName} ${lastName}`;
    const now = new Date();

    const deadlineForAnswers = propz.get(consent, ['deadlineForAnswers']);
    const isDeadlineExist = typeof deadlineForAnswers !== 'undefined';

    const isMessageInvitation = consent.kind === MESSAGE_KIND.EventInvitationMessage;
    const isMessageRefusal = consent.kind === MESSAGE_KIND.EventParticipationRefusalMessage;
    const isMessageAvailability = consent.kind === MESSAGE_KIND.EventParticipationMessage;
    const isMessageClubParticipantInvite = consent.kind === MESSAGE_KIND.ClubParticipantInviteMessage;
    const isMessageConfirmationRequest = consent.kind === MESSAGE_KIND.TournamentConfirmationRequestMessage;

    switch (true) {
      case isMessageInvitation && !isDeadlineExist:
      case isMessageInvitation && Number(new Date(deadlineForAnswers)) > Number(now):
        return `Do you give your consent for ${playerName} to take part in this fixture?`;
      case isMessageInvitation && Number(new Date(deadlineForAnswers)) < Number(now):
        return `Unfortunately, the deadline has passed and you cannot accept or decline this consent request.`;
      case isMessageClubParticipantInvite && Number(new Date(deadlineForAnswers)) < Number(now):
        return `Unfortunately, the deadline has passed and you cannot book space at the moment.`;
      case isMessageRefusal:
        return `${playerName} is not available to take part.`;
      case isMessageAvailability:
        const isTakePart = consent.isTakePart ? 'yes' : 'no';
        return `${playerName} can take part: ${isTakePart}`;
      case isMessageClubParticipantInvite && !isDeadlineExist:
      case isMessageClubParticipantInvite && Number(new Date(deadlineForAnswers)) > Number(now):
        return `You can book a place for your child now. To send a request for a place click the “Book” button.`;
      case isMessageConfirmationRequest:
        return `Please confirm your participation.`;
    }
  }

  getParentsByPlayer(consent) {
    let parents = [];

    const playerId = consent.playerDetailsData.id;
    const currentMessage = this.props.consents.find(consent => consent.playerDetailsData.id === playerId);
    if (typeof currentMessage !== 'undefined') {
      parents = currentMessage.playerDetailsData.parents ? currentMessage.playerDetailsData.parents : [];
    }

    return parents;
  }

  isRenderConsentRequestTemplate(consent): boolean {
    const type = this.getTypeForMessage(consent);

    return (
      (type === MESSAGE_TYPE.ARCHIVE && Array.isArray(consent.fields) && consent.fields.length > 0) ||
      (type === MESSAGE_TYPE.INBOX && Array.isArray(consent.fields) && consent.fields.length > 0)
    );
  }

  renderConsentText = consent => {
    const details = isEventParticipationMessage(consent) ? consent.details : '';

    return (
      <div className="">
        <div className="eEventMessageInfoText">{this.getText(consent)}</div>
        <p>{details}</p>
      </div>
    );
  };

  renderStatus(consent) {
    const type = this.getTypeForMessage(consent);

    switch (type) {
      case MESSAGE_TYPE.ARCHIVE:
        const status = getStatus(consent, type);

        return (
          <div className="eInvite_message">
            <span className={'m' + status}>{status}</span>
          </div>
        );
      default:
        return null;
    }
  }

  renderConsentRequestTemplate(consent): React.ReactNode {
    if (this.isRenderConsentRequestTemplate(consent)) {
      const type = this.getTypeForMessage(consent);
      return <ConsentRequestTemplate consent={consent} />;
    } else {
      return null;
    }
  }

  renderReportInfo = (consent, index) => {
    const deadlineForAnswers = propz.get(consent, ['deadlineForAnswers']);
    const isDeadlineExist = typeof deadlineForAnswers !== 'undefined';
    const deadlineForAnswersFormatted = isDeadlineExist
      ? Moment(deadlineForAnswers).format('DD.MM.YYYY / HH:mm')
      : Moment(new Date()).format('DD.MM.YYYY / HH:mm');

    return (
      <tr key={`report_${index}`}>
        <td colSpan={6}>
          <div className="row">
            <div className="col-12 col-md-2">{this.renderSchoolLogo(consent)}</div>
            <div className="col-12 col-md-10">
              {this.renderEventInfo(consent)}
              {this.renderConsentRequestTemplate(consent)}
            </div>
          </div>

          <div>{this.renderConsentText(consent)}</div>

          {isDeadlineExist && (
            <div className="eInvite_text">{`Deadline for answers: ${deadlineForAnswersFormatted}`}</div>
          )}

          {this.renderStatus(consent)}
        </td>
      </tr>
    );
  };

  renderStatusByPlayerAndMessage(consent) {
    const { invitationStatus } = consent;
    let status = '';
    switch (true) {
      case this.getParentsByPlayer(consent).length === 0 && invitationStatus.status === 'NOT_READY': {
        status = 'Not sent';
        break;
      }
      default: {
        status = MESSAGE_INVITATION_STATUS_SERVER_TO_CLIENT_MAPPING[invitationStatus];

        break;
      }
    }
    return status;
  }

  renderParents(consent) {
    const parents = this.getParentsByPlayer(consent);

    if (Array.isArray(parents) && parents.length > 0) {
      return parents.map((parent, index) => {
        return <div key={`parent_${index}`}>{parent}</div>;
      });
    } else {
      return null;
    }
  }

  renderRows() {
    const { consents } = this.props;
    const { isVisibleConsents } = this.state;

    return consents.map((consent, index) => {
      const { playerDetailsData, invitationStatus } = consent;
      const name = `${playerDetailsData.firstName} ${playerDetailsData.lastName}`;
      const isShowInfoConsent = isVisibleConsents[index];
      const isInvitationStatusNoSent = invitationStatus === MESSAGE_INVITATION_STATUS.NOT_SENT;
      const rowClassName = isInvitationStatusNoSent ? '' : 'eEventMessagesRow';
      return (
        <>
          <tr
            key={`consent_summary_${index}`}
            onClick={() => {
              if (isInvitationStatusNoSent) {
                () => {};
              } else {
                this.onClickMessage(index);
              }
            }}
            className={rowClassName}
          >
            <td>
              <div>{name}</div>
            </td>
            <td>
              <div>{this.renderStatusByPlayerAndMessage(consent)}</div>
            </td>
            <td>{this.renderParents(consent)}</td>

            <td>
              {!isInvitationStatusNoSent && (
                <FontAwesomeIcon icon={isShowInfoConsent ? 'chevron-up' : 'chevron-down'} className="" />
              )}
            </td>
          </tr>
          {isShowInfoConsent && this.renderReportInfo(consent, index)}
        </>
      );
    });
  }

  renderEmptyRow() {
    return (
      <tr key={'message_empty_row'}>
        <td>There are no messages to display.</td>
      </tr>
    );
  }

  render() {
    const { consents } = this.props;
    return (
      <table className={'table table-sm mPre'}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Status</th>
            <th>Parents</th>
            <th />
          </tr>
        </thead>
        <tbody>{consents.length > 0 ? this.renderRows() : this.renderEmptyRow()}</tbody>
      </table>
    );
  }
}
