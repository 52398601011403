import * as React from 'react';
import { LeagueNews } from 'Src/models/schoolLeague';
import * as Moment from 'moment';
import { DATE_TIME_FORMAT } from 'Src/consts/date';

interface Props {
  news: LeagueNews[];
}

export function SchoolLeagueNewsSummary(props: Props) {
  const { news } = props;

  const rows = news.map((oneNews, index) => {
    const { title, body, date } = oneNews;
    return (
      <tr key={`news_summary_${index}`}>
        <td>{index + 1}</td>
        <td>{title}</td>
        <td>
          <div dangerouslySetInnerHTML={{ __html: body }} />
        </td>
        <td>{Moment(date).format(DATE_TIME_FORMAT)}</td>
        <td />
      </tr>
    );
  });

  const emptyRow = (
    <tr key={'news_empty_row'}>
      <td>No news</td>
    </tr>
  );

  return (
    <table className={'table table-sm mPre'}>
      <thead>
        <tr>
          <th>#</th>
          <th>Title</th>
          <th>Body</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>{rows.length > 0 ? rows : emptyRow}</tbody>
    </table>
  );
}
