import * as React from 'react';
import { FunctionComponent } from 'react'
import { Notification } from "../../models/notification";
import { GENERAL_MESSAGE_DELIVERY_STATUS_SERVER_TO_CLIENT_MAPPING } from "../../consts/generalMessage";

interface Props {
  notification: Notification
}

export const SchoolNotificationChannels: FunctionComponent<Props> = (props) => {
  const rows = props.notification.channelStatus.map( channel => {
    const status = GENERAL_MESSAGE_DELIVERY_STATUS_SERVER_TO_CLIENT_MAPPING[channel.deliveryStatus];
    const channelType = channel.channelType;

    let channelId = '';
    switch (channelType) {
      case 'DEFAULT_EMAIL':
        channelId = props.notification.user.email;
        break;
      case 'DEFAULT_SMS':
        channelId = props.notification.user.phone;
        break;
      default:
        channelId = channel.channelId;
    }
    //TODO: server return [{}] instead [], delete it then fix server
    return (
      <tr key = { channel.channelId || "school_notification_channel"}>
        <td>{ channelType }</td>
        <td>{ channelId }</td>
        <td>{ status }</td>
      </tr>
    );
  });

  const emptyRow = (
    <tr key = {"school_notification_empty_row"}>
      <td>No available channels</td>
    </tr>
  );

  return (
    <table className="table table-sm mPre">
      <thead>
      <tr>
        <th>Type</th>
        <th>Channel Id</th>
        <th>Status</th>
      </tr>
      </thead>
      <tbody>
      {rows.length > 0 ? rows : emptyRow}
      </tbody>
    </table>
  );
};

SchoolNotificationChannels.displayName = 'SchoolNotificationChannels';