import * as React from 'react';
import { REGISTRATION_PERMISSION, RegistrationPermission, RegistrationStaff } from '../Registration';
import { RegistrationPermissionSelector } from './RegistrationPermissionSelector';
import { StudentPermission } from './StudentPermission';
import { LoginSession } from '../../../models/sessions';
import { StaffPermission } from './StaffPermission';
import { StaffRoleSelector } from './StaffRoleSelector';
import { ParentPermission } from './ParentPermission';
import { ParentDetails } from './ParentDetails';

interface Props {
  permission: RegistrationPermission;
  onPermissionClick: (permission: RegistrationPermission) => void;
  onStaffClick: (staff: RegistrationStaff) => void;
  loginSession: LoginSession;
  onSubmitStudentPermission: (data) => void;
  onSubmitStaffPermission: (data) => void;
  onSubmitParentPermission: (data) => void;
  onSubmitParentDetails: (data) => void;
  phone: string;
  isStaffRoleSelectorEnabled: boolean;
  isParentDetailsEnabled: boolean;
  staff: RegistrationStaff;
  school: any;
}
interface State {}

export class Permission extends React.Component<Props, State> {
  render() {
    const {
      permission,
      onPermissionClick,
      onSubmitStudentPermission,
      onSubmitStaffPermission,
      onSubmitParentPermission,
      onSubmitParentDetails,
      phone,
      isStaffRoleSelectorEnabled,
      isParentDetailsEnabled,
      school,
      onStaffClick,
      staff
    } = this.props;

    switch (permission) {
      case REGISTRATION_PERMISSION.STUDENT:
        return (
          <div className={'mt-3'}>
            <StudentPermission phone={phone} onSubmit={onSubmitStudentPermission} />
          </div>
        );
      case REGISTRATION_PERMISSION.PARENT:
        if (isParentDetailsEnabled) {
          return (
            <div className={'mt-3'}>
              <ParentDetails school={school} onSubmit={onSubmitParentDetails} />
            </div>
          );
        } else {
          return (
            <div className={'mt-3'}>
              <ParentPermission phone={phone} onSubmit={onSubmitParentPermission} />
            </div>
          );
        }

      case REGISTRATION_PERMISSION.STAFF:
        if (isStaffRoleSelectorEnabled) {
          return (
            <div className={'mt-3'}>
              <StaffRoleSelector staff={staff} school={school} onStaffClick={onStaffClick} />
            </div>
          );
        } else {
          return (
            <div className={'mt-3'}>
              <StaffPermission phone={phone} onSubmit={onSubmitStaffPermission} />
            </div>
          );
        }
      default:
        return (
          <div className={'mt-3'}>
            <RegistrationPermissionSelector permission={permission} onPermissionClick={onPermissionClick} />
          </div>
        );
    }
  }
}
