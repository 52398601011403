import * as React from 'react';
import { ReactSortable } from 'react-sortablejs';
import { Button } from '../Button/Button';
import { UK_INPUT_MASK, UK_INPUT_MASK_VALIDATION } from '../../consts/date';
import MaskedInput from 'react-text-mask';
import * as Moment from 'moment';
import {
  ConfirmationRequestTemplate,
  ConfirmationRequestTemplateField
} from '../../models/confirmationRequestTemplate';
import { INVITE_KIND } from 'Src/consts/invite';
import { INVITE_KIND_TYPE } from 'Src/types/invite';
import { BurgerIcon } from 'Src/components/SvgIcons/BurgerIcon';

import './ConfirmationRequestForm.css';

interface Props {
  template: ConfirmationRequestTemplate;
  onCancelClick: () => void;
  onSendConfirmationRequestsClick: (fields: ConfirmationRequestFormField[], deadlineForAnswers?: string) => void;
  textSubmitButton?: string;
  isRenderDeadline?: boolean;
  inviteKind?: INVITE_KIND_TYPE;
}

interface State {
  fields: ConfirmationRequestFormField[];
  deadlineForAnswers: string;
  isDeadlineForAnswersError: boolean;
}

export interface ConfirmationRequestFormField extends ConfirmationRequestTemplateField {
  isChecked: boolean;
  id: number;
}

export class ConfirmationRequestForm extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    const propsFields = [...props.template.fields];

    this.state = {
      deadlineForAnswers: '',
      isDeadlineForAnswersError: false,
      fields: propsFields.map((field, index) => ({
        ...field,
        isChecked: field.isDefault,
        id: index
      }))
    };
  }

  onCheckboxClick = index => {
    const fields = this.state.fields;
    const nextFields = fields.map((field, fieldIndex) => {
      if (fieldIndex === index) {
        return {
          ...field,
          isChecked: !field.isChecked
        };
      } else {
        return field;
      }
    });

    this.setState({
      fields: nextFields
    });
  };

  sortFields = sortedFields => {
    this.setState({
      fields: sortedFields
    });
  };

  renderFields() {
    const { fields } = this.state;

    return (
      <ReactSortable list={fields} setList={this.sortFields} handle=".eConsentRequestFieldsDrag" animation={150}>
        {fields.map((field, index) => (
          <div className="bConsentRequestFields" key={`confirmation_request_field_${field._id}`}>
            <div className="pl-3 d-flex align-items-center justify-content-between">
              <div className={'custom-control custom-switch'}>
                <input
                  id={`switch_${field._id}`}
                  onChange={() => this.onCheckboxClick(index)}
                  checked={field.isChecked}
                  type="checkbox"
                  className="custom-control-input"
                />

                <label className="pl-3 mb-3 mt-3 custom-control-label" htmlFor={`switch_${field._id}`}>
                  {field.heading}
                </label>
              </div>

              <div className="eConsentRequestFieldsDrag">
                <BurgerIcon />
              </div>
            </div>
          </div>
        ))}
      </ReactSortable>
    );
  }

  onSelectAllClick = () => {
    const { fields } = this.state;
    const isSelectAllChecked = fields.every(field => field.isChecked);

    this.setState({
      fields: fields.map(field => {
        if (isSelectAllChecked) {
          return {
            ...field,
            isChecked: false
          };
        } else {
          return {
            ...field,
            isChecked: true
          };
        }
      })
    });
  };

  renderSelectAll(): React.ReactNode {
    const { fields } = this.state;
    const isSelectAllChecked = fields.every(field => field.isChecked);

    return (
      <div className="bConsentRequestFields">
        <div className="pl-3">
          <div className={'custom-control custom-switch'} key={`confirmation_request_field_selectAll`}>
            <input
              id={`switch_selectAll`}
              onChange={this.onSelectAllClick}
              checked={isSelectAllChecked}
              type="checkbox"
              className="custom-control-input"
            />
            <label className="pl-3 mb-3 mt-3 custom-control-label" htmlFor={`switch_selectAll`}>
              {isSelectAllChecked ? 'Unselect All' : 'Select All'}
            </label>
          </div>
        </div>
      </div>
    );
  }

  onSendClick = () => {
    const { fields, deadlineForAnswers } = this.state;
    const checkedFields = fields.filter(field => field.isChecked);

    const isValidDeadline = Moment(deadlineForAnswers, UK_INPUT_MASK_VALIDATION, true).isValid();

    if (deadlineForAnswers === '' || isValidDeadline) {
      this.props.onSendConfirmationRequestsClick(checkedFields, deadlineForAnswers);
    } else {
      this.setState({
        isDeadlineForAnswersError: true
      });
    }
  };

  renderButtons(): React.ReactNode {
    const textSubmitButton = this.props.textSubmitButton || 'Send confirmation request';
    return (
      <>
        <Button onClick={this.props.onCancelClick} text={'Cancel'} customClass={'btn-secondary mt-3 mr-3'} />
        <Button onClick={this.onSendClick} text={textSubmitButton} customClass={'mt-3'} />
      </>
    );
  }

  onDeadlineChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    this.setState({
      deadlineForAnswers: value,
      isDeadlineForAnswersError: false
    });
  };

  renderDeadline(): React.ReactNode {
    return (
      <MaskedInput
        type="text"
        className={'form-control'}
        onChange={this.onDeadlineChange}
        mask={UK_INPUT_MASK}
        placeholder={UK_INPUT_MASK_VALIDATION}
      />
    );
  }

  renderHeaderText() {
    const { inviteKind } = this.props;

    switch (inviteKind) {
      case INVITE_KIND.TOURNAMENT_SCHOOL_INVITE:
        return (
          <div className="mt-2">
            Here you can select questions that schools will be asked to answer when sending their participation
            confirmation. You can set up your questions on your union console.
          </div>
        );

      case INVITE_KIND.CLUB_SCHOOL_INVITE:
        return (
          <div className="mt-2">
            Here you can select questions that parents/students will be asked to answer when booking onto the club(s).
            You can set up your questions on your school console.
          </div>
        );

      default:
        return (
          <div className="mt-2">
            Here you can select questions that parents will be asked to answer when sending their parental consent. You
            can set up your questions on your school console.
          </div>
        );
    }
  }

  render() {
    const { fields, isDeadlineForAnswersError } = this.state;
    const isChecked = fields.filter(field => field.isChecked);
    const isCheckedCount = isChecked.length;
    const { isRenderDeadline } = this.props;

    return (
      <>
        {this.renderHeaderText()}
        {this.renderFields()}
        {this.renderSelectAll()}
        {isRenderDeadline && <div className="mt-2 mb-2">Deadline for answers (optional)</div>}
        {isRenderDeadline && this.renderDeadline()}
        {isDeadlineForAnswersError && <div className="alert alert-danger mt-3">Please enter a valid date</div>}
        <div className="mt-2">{`${isCheckedCount} additional questions will be answered`}</div>
        {this.renderButtons()}
      </>
    );
  }
}
