import * as React from 'react';
import * as propz from 'propz';
import { Field, Form, Formik } from 'formik';
import DatePicker from 'react-datepicker';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from '../../../../../../../consts/report';
import { Switch } from '../../../../../../../components/Switch/Switch';
import { getStartDateForSchoolYear } from '../../../../../../../helpers/calendar/calendar';
import { SchoolStudent } from 'Src/models/schoolStudent';
import { LabelWithQuestionIcon } from '../../../../../../../components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import '../../Students.css';

const OPTIONS = {
  IS_TODAY: 'isToday',
  IS_YESTERDAY: 'isYesterday',
  IS_CURRENT_WEEK: 'isCurrentWeek',
  IS_PAST_WEEK: 'isPastWeek',
  IS_CUSTOM_INTERVAL: 'isCustomInterval'
};

interface Props {
  onCloseClick: () => void;
  onSubmit: (data: any, reportType: string) => void;
  student: SchoolStudent[];
  activeSchoolId: string;
}

// report 015
export const StudentParticipationInEventsReportForm = ({ onCloseClick, onSubmit, student, activeSchoolId }: Props) => {
  const [schoolData, setSchoolData] = React.useState<any>(null);

  React.useEffect(() => {
    fetch('/dist/images/mis/misIntegrationSchoolsList.json')
      .then(response => response.json())
      .then(data => {
        const matchedSchoolData = data.find(item => item.schoolSITId === activeSchoolId);
        setSchoolData(matchedSchoolData);
      })
      .catch(error => console.error('Error loading JSON:', error));
  }, [activeSchoolId]);
  const now = new Date();
  let dateFrom = getStartDateForSchoolYear(now);

  const requestInitial = {
    options: OPTIONS.IS_TODAY,
    isYesterday: false,
    isCurrentWeek: false,
    isPastWeek: false,
    isCustomInterval: false,
    isPrintPP: schoolData ? schoolData.studentsPPInfo === 1 : false,
    isPrintSEN: schoolData ? schoolData.studentsSENInfo === 1 : false,
    isPrintFSM: schoolData ? schoolData.studentsFSMInfo === 1 : false,
    isShowSportEvents: true,
    isShowClubEvents: true,
    isShowTournamentEvents: false,
    isPrintAttendance: true,
    dateFrom: (() => {
      const today = new Date();
      today.setHours(0, 0, 1, 0);
      return today;
    })(),
    dateTo: (() => {
      const today = new Date();
      today.setHours(23, 59, 59, 999);
      return today;
    })()
  };

  switch (requestInitial.options) {
    case OPTIONS.IS_TODAY: {
      const today = new Date();

      requestInitial.dateFrom = new Date(today.setHours(0, 0, 1, 0)); // 00:00:01
      requestInitial.dateTo = new Date(today.setHours(23, 59, 59, 999)); // 23:59:59
      break;
    }

    case OPTIONS.IS_YESTERDAY: {
      const today = new Date();
      const yesterday = new Date(today);

      yesterday.setDate(today.getDate() - 1);
      requestInitial.dateFrom = new Date(yesterday.setHours(0, 0, 1, 0));
      requestInitial.dateTo = new Date(yesterday.setHours(23, 59, 59, 999));
      break;
    }

    case OPTIONS.IS_CURRENT_WEEK: {
      const today = new Date();
      const dayOfWeek = today.getDay();
      const daysUntilMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
      const closestPastMonday = new Date(today);
      closestPastMonday.setDate(today.getDate() - daysUntilMonday);

      const nextSunday = new Date(closestPastMonday);
      nextSunday.setDate(closestPastMonday.getDate() + 7);

      requestInitial.dateFrom = new Date(closestPastMonday.setHours(0, 0, 1, 0)); // 00:00:01
      requestInitial.dateTo = new Date(nextSunday.setHours(23, 59, 59, 999)); // 23:59:59
      break;
    }

    case OPTIONS.IS_PAST_WEEK: {
      const today = new Date();
      const dayOfWeek = today.getDay();
      const daysUntilMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
      const lastMonday = new Date(today);
      lastMonday.setDate(today.getDate() - daysUntilMonday - 7);

      const lastSunday = new Date(lastMonday);
      lastSunday.setDate(lastMonday.getDate() + 6);

      requestInitial.dateFrom = new Date(lastMonday.setHours(0, 0, 1, 0)); // 00:00:01
      requestInitial.dateTo = new Date(lastSunday.setHours(23, 59, 59, 999)); // 23:59:59
      break;
    }
  }

  return (
    <Formik
      onSubmit={values => {
        const today = new Date();
        const valuesToTransfer = { ...values };
        propz.set(valuesToTransfer, [valuesToTransfer.options], true);
        delete valuesToTransfer.options;

        switch (values.options) {
          case OPTIONS.IS_TODAY:
            valuesToTransfer.dateFrom = new Date(today.setHours(0, 0, 0, 1));
            valuesToTransfer.dateTo = new Date(today.setHours(23, 59, 59, 999));
            break;

          case OPTIONS.IS_YESTERDAY:
            const yesterday = new Date(today);

            yesterday.setDate(today.getDate() - 1);
            valuesToTransfer.dateFrom = new Date(yesterday.setHours(0, 0, 1, 0));
            valuesToTransfer.dateTo = new Date(yesterday.setHours(23, 59, 59, 999));
            break;

          case OPTIONS.IS_CURRENT_WEEK:
            const currentWeekToday = new Date();
            const dayOfWeekCurrent = currentWeekToday.getDay();
            const daysUntilMondayCurrent = dayOfWeekCurrent === 0 ? 6 : dayOfWeekCurrent - 1;
            const closestPastMondayCurrent = new Date(currentWeekToday);
            closestPastMondayCurrent.setDate(currentWeekToday.getDate() - daysUntilMondayCurrent);

            const nextSundayCurrent = new Date(closestPastMondayCurrent);
            nextSundayCurrent.setDate(closestPastMondayCurrent.getDate() + 6);

            valuesToTransfer.dateFrom = closestPastMondayCurrent;
            valuesToTransfer.dateTo = new Date(nextSundayCurrent.setHours(23, 59, 59, 999));
            break;

          case OPTIONS.IS_PAST_WEEK:
            const pastWeekToday = new Date();
            const dayOfWeekPast = pastWeekToday.getDay();
            const daysUntilMondayPast = dayOfWeekPast === 0 ? 6 : dayOfWeekPast - 1;
            const lastMondayPast = new Date(pastWeekToday);
            lastMondayPast.setDate(pastWeekToday.getDate() - daysUntilMondayPast - 7);

            const lastSundayPast = new Date(lastMondayPast);
            lastSundayPast.setDate(lastMondayPast.getDate() + 6);

            valuesToTransfer.dateFrom = lastMondayPast;
            valuesToTransfer.dateTo = new Date(lastSundayPast.setHours(23, 59, 59, 999));
            break;

          case OPTIONS.IS_CUSTOM_INTERVAL:
            const selectedDateFrom = values.dateFrom;
            const selectedDateTo = values.dateTo;

            valuesToTransfer.dateFrom = selectedDateFrom;
            valuesToTransfer.dateTo = selectedDateTo;
            break;
        }

        onSubmit(valuesToTransfer, REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.STUDENT_PARTICIPATION_IN_EVENTS);
      }}
      initialValues={requestInitial}
      render={({ values, setFieldValue }) => (
        <Form>
          <div className="bReportFormTitle">Student participation in events</div>
          <div className="mb-3 mt-3">
            {'View all the activity data relating to a student of your choice, see what events they have taken part ' +
              'in for fixtures and / or tournaments as well as viewing all of the club events they have participated in.'}
          </div>

          <div className="form-group">
            <div className="form-check mb-1">
              <input
                className="form-check-input"
                type="radio"
                name={OPTIONS.IS_TODAY}
                id={OPTIONS.IS_TODAY}
                value={OPTIONS.IS_TODAY}
                checked={values.options === OPTIONS.IS_TODAY}
                onChange={() => {
                  setFieldValue('options', OPTIONS.IS_TODAY);
                }}
              />
              <LabelWithQuestionIcon
                labelText="Today"
                hintText="Select to view the data for today"
                customLabelClass="form-check-label pl-2"
                labelHtmlFor={OPTIONS.IS_TODAY}
              />
            </div>

            <div className="form-check mb-1">
              <input
                className="form-check-input"
                type="radio"
                name={OPTIONS.IS_YESTERDAY}
                id={OPTIONS.IS_YESTERDAY}
                value={OPTIONS.IS_YESTERDAY}
                checked={values.options === OPTIONS.IS_YESTERDAY}
                onChange={() => {
                  setFieldValue('options', OPTIONS.IS_YESTERDAY);
                }}
              />
              <LabelWithQuestionIcon
                labelText="Yesterday"
                hintText="Select to view the data for yesterday"
                customLabelClass="form-check-label pl-2"
                labelHtmlFor={OPTIONS.IS_YESTERDAY}
              />
            </div>

            <div className="form-check mb-1">
              <input
                className="form-check-input"
                type="radio"
                name={OPTIONS.IS_CURRENT_WEEK}
                id={OPTIONS.IS_CURRENT_WEEK}
                value={OPTIONS.IS_CURRENT_WEEK}
                checked={values.options === OPTIONS.IS_CURRENT_WEEK}
                onChange={() => {
                  setFieldValue('options', OPTIONS.IS_CURRENT_WEEK);
                }}
              />
              <LabelWithQuestionIcon
                labelText="This week"
                hintText="Select to view the data for the current week"
                customLabelClass="form-check-label pl-2"
                labelHtmlFor={OPTIONS.IS_CURRENT_WEEK}
              />
            </div>

            <div className="form-check mb-1">
              <input
                className="form-check-input"
                type="radio"
                name={OPTIONS.IS_PAST_WEEK}
                id={OPTIONS.IS_PAST_WEEK}
                value={OPTIONS.IS_PAST_WEEK}
                checked={values.options === OPTIONS.IS_PAST_WEEK}
                onChange={() => {
                  setFieldValue('options', OPTIONS.IS_PAST_WEEK);
                }}
              />

              <LabelWithQuestionIcon
                labelText="Last week"
                hintText="Select to view the data for the last week"
                customLabelClass="form-check-label pl-2"
                labelHtmlFor={OPTIONS.IS_PAST_WEEK}
              />
            </div>

            <div className="form-check mb-1">
              <input
                className="form-check-input"
                type="radio"
                name="options"
                id={OPTIONS.IS_CUSTOM_INTERVAL}
                value={OPTIONS.IS_CUSTOM_INTERVAL}
                checked={values.options === OPTIONS.IS_CUSTOM_INTERVAL}
                onChange={() => {
                  setFieldValue('options', OPTIONS.IS_CUSTOM_INTERVAL);

                  let dateFrom = getStartDateForSchoolYear(now);

                  setFieldValue('dateFrom', new Date(dateFrom.setHours(0, 0, 1, 0)));
                  setFieldValue('dateTo', new Date(now.setHours(23, 59, 59, 999)));
                }}
              />

              <LabelWithQuestionIcon
                labelText="Custom interval"
                hintText="Select to specify a custom date interval"
                customLabelClass="form-check-label pl-2"
                labelHtmlFor={OPTIONS.IS_CUSTOM_INTERVAL}
              />
            </div>

            {values.options === OPTIONS.IS_CUSTOM_INTERVAL && (
              <div>
                <div className="form-group">
                  <label>Date from</label>
                  <Field name="dateFrom">
                    {({ field, form }) => (
                      <div className="mb-3">
                        <DatePicker
                          {...field}
                          selected={field.value}
                          onChange={date => {
                            form.setFieldValue('dateFrom', date);
                            if (form.values.dateTo && form.values.dateTo < date) {
                              form.setFieldValue('dateTo', date);
                            }
                          }}
                          className="form-control"
                          dateFormat="dd-MM-yyyy"
                        />
                      </div>
                    )}
                  </Field>
                </div>
                <div className="form-group">
                  <label>Date to</label>
                  <Field name="dateTo">
                    {({ field, form }) => (
                      <div className="mb-3">
                        <DatePicker
                          {...field}
                          selected={field.value}
                          onChange={date => {
                            form.setFieldValue('dateTo', date);
                          }}
                          minDate={form.values.dateFrom}
                          className="form-control"
                          dateFormat="dd-MM-yyyy"
                        />
                      </div>
                    )}
                  </Field>
                </div>
              </div>
            )}
          </div>

          {schoolData && schoolData.studentsPPInfo === 1 && (
            <div className="form-group">
              <div className="d-flex">
                <Field
                  name="isPrintPP"
                  render={({ field }) => (
                    <Switch
                      {...field}
                      value={values.isPrintPP}
                      onChange={event => {
                        const isPrintPP = event.target.checked;
                        setFieldValue('isPrintPP', isPrintPP);
                      }}
                    />
                  )}
                />
                <LabelWithQuestionIcon
                  labelText="Show PP"
                  hintText={'Show data relating to students with PP.'}
                  customLabelClass="form-check-label pl-2"
                  labelHtmlFor="isPrintPP"
                />
              </div>
            </div>
          )}

          {schoolData && schoolData.studentsSENInfo === 1 && (
            <div className="form-group">
              <div className="d-flex">
                <Field
                  name="isPrintSEN"
                  render={({ field }) => (
                    <Switch
                      {...field}
                      value={values.isPrintSEN}
                      onChange={event => {
                        const isPrintSEN = event.target.checked;
                        setFieldValue('isPrintSEN', isPrintSEN);
                      }}
                    />
                  )}
                />
                <LabelWithQuestionIcon
                  labelText="Show SEN"
                  hintText={'Show data relating to students with SEN.'}
                  customLabelClass="form-check-label pl-2"
                  labelHtmlFor="isPrintSEN"
                />
              </div>
            </div>
          )}

          {schoolData && schoolData.studentsFSMInfo === 1 && (
            <div className="form-group">
              <div className="d-flex">
                <Field
                  name="isPrintFSM"
                  render={({ field }) => (
                    <Switch
                      {...field}
                      value={values.isPrintFSM}
                      onChange={event => {
                        const isPrintFSM = event.target.checked;
                        setFieldValue('isPrintFSM', isPrintFSM);
                      }}
                    />
                  )}
                />
                <LabelWithQuestionIcon
                  labelText="Show FSM"
                  hintText={'Show data relating to students with FSM.'}
                  customLabelClass="form-check-label pl-2"
                  labelHtmlFor="isPrintFSM"
                />
              </div>
            </div>
          )}

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isShowSportEvents"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowSportEvents}
                    onChange={event => {
                      const isShowSportEvents = event.target.checked;
                      setFieldValue('isShowSportEvents', isShowSportEvents);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show Sport Events"
                hintText={'Show all sports events your selected student was involved in within your chosen period.'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowSportEvents"
              />
            </div>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isShowClubEvents"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowClubEvents}
                    onChange={event => {
                      const isShowClubEvents = event.target.checked;
                      setFieldValue('isShowClubEvents', isShowClubEvents);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show Club Events"
                hintText={'Show all club events your selected student was involved in within your chosen period.'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowClubEvents"
              />
            </div>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isShowTournamentEvents"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowTournamentEvents}
                    onChange={event => {
                      const isShowTournamentEvents = event.target.checked;
                      setFieldValue('isShowTournamentEvents', isShowTournamentEvents);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show Tournament Events"
                hintText={'Show all tournament events your selected student was involved in within your chosen period.'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowTournamentEvents"
              />
            </div>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isPrintAttendance"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintAttendance}
                    onChange={event => {
                      const isPrintAttendance = event.target.checked;
                      setFieldValue('isPrintAttendance', isPrintAttendance);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show Attendance"
                hintText={''}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintAttendance"
              />
            </div>
          </div>

          <button type="button" className="btn btn-secondary mr-3" onClick={onCloseClick}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Get
          </button>
        </Form>
      )}
    />
  );
};
