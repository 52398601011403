import * as React from 'react';
import { PreviousMonthArrow } from './PreviousMonthArrow';
import { NextMonthArrow } from './NextMonthArrow';
import { MonthDropdown } from './MonthDropdown';
import { YearDropdown } from './YearDropdown';

interface Props {
  onPreviousMonthClick: () => void;
  onNextMonthClick: () => void;
  onMonthChange: (value: number) => void;
  onYearChange: (value: number) => void;
  activeMonthIndex: number;
  activeYearIndex: number;
}

export function MonthYearSelector(props: Props) {
  const {
    onPreviousMonthClick,
    onNextMonthClick,
    activeMonthIndex,
    activeYearIndex,
    onMonthChange,
    onYearChange
  } = props;
  return (
    <div className="eMonthYearSelector">
      <PreviousMonthArrow onPreviousMonthClick={onPreviousMonthClick} />
      <MonthDropdown onMonthChange={onMonthChange} activeMonthIndex={activeMonthIndex} />
      <YearDropdown onYearChange={onYearChange} activeYearIndex={activeYearIndex} />
      <NextMonthArrow onNextMonthClick={onNextMonthClick} />
    </div>
  );
}
