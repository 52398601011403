import * as React from 'react';
import { Route, Switch } from 'react-router';
import { PaymentsAccounts } from './PaymentsAccounts';
// import { PaymentsAccountsView } from './PaymentAccountForm/PaymentsAccountsView';
// import { PaymentsAccountPersonsView } from './PaymentAccountForm/PaymentsAccountPersonsView';
import { Transactions } from 'Src/views/GenericView/AdminView/AdminConsole/PaymentsAccounts/Transactions/Transactions';

export const PaymentsAccountsRouting = allProps => {
  return (
    <Switch>
      <Route exact path={'/accounts'} render={props => <PaymentsAccounts {...props} user={allProps.user} />} />
      {/* <Route
        exact
        path={'/accounts/account'}
        render={props => <PaymentsAccountsView {...props} user={allProps.user} />}
      />
      <Route
        exact
        path={'/accounts/persons'}
        render={props => <PaymentsAccountPersonsView {...props} user={allProps.user} />}
      /> */}
      <Route exact path={'/payments'} render={props => <Transactions {...props} user={allProps.user} />} />
    </Switch>
  );
};

PaymentsAccountsRouting.displayName = 'PaymentsAccountsRouting';
