import * as React from 'react';

interface Props {
  items: any[];
  itemIdList: string[];
  onClick: (id: string) => void;
  getItemName: (item: any) => string;
  allItemsTitle: string;
}

export function ItemsBar(props: Props) {
  const { itemIdList, items, onClick, getItemName, allItemsTitle } = props;

  const isSeveralitems = items.length > 1;
  const isAllItemsShow = itemIdList.length === items.length;

  const isItemShow = item => {
    return !isAllItemsShow && item.id === itemIdList[0];
  };

  return (
    <div className="bItemsBar">
      {isSeveralitems && (
        <div
          className={isAllItemsShow ? 'eItemsBarActiveItem eItemsBarItem' : 'eItemsBarItem'}
          onClick={() => onClick('all')}
        >
          <span>{allItemsTitle}</span>
        </div>
      )}

      {items.map((item: any) => {
        const { avatar: avatarUrl } = item;
        const isAvatarUrlExist = typeof avatarUrl !== 'undefined' && avatarUrl !== '';
        const firstNameInitial = item.firstName.substring(0, 1);

        return isAvatarUrlExist ? (
          <div
            className={isItemShow(item) ? 'eItemsBarActiveItem eItemsBarItem' : 'eItemsBarItem'}
            onClick={() => onClick(item.id)}
            key={`item_${item.id}`}
          >
            <img src={avatarUrl} alt="Avatar" />
            <span>{getItemName(item)}</span>
          </div>
        ) : (
          <div
            className={isItemShow(item) ? 'eItemsBarActiveItem eItemsBarItem' : 'eItemsBarItem'}
            onClick={() => onClick(item.id)}
            key={`item_${item.id}`}
          >
            <div className="eItemsBarItemLiteralAvatar">
              <div className="eItemsBarItemLiteralAvatarText">{firstNameInitial}</div>
            </div>
            <span>{getItemName(item)}</span>
          </div>
        );
      })}
    </div>
  );
}
