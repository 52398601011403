import { AppUser } from 'Src/views/App/App';
import axios from 'axios';
import { SchoolTeamPlayersBatchData } from './schoolTeamPlayers';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from 'Src/consts/table';
import { SchoolEvent } from '../../../models/event';

export function createEvent(user: AppUser, event) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios.post(`${window.apiBase}/i/schools/${activeSchoolId}/events`, event, config).then(response => {
    return response.data;
  });
}

export function finishEvent(user: AppUser, eventId) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/finish`, {}, config)
    .then(response => {
      return response.data;
    });
}

export function addSchoolInEvent(user: AppUser, eventId: string, schoolId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  const data = { invitedSchoolIds: [schoolId] };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/opponents`, data, config)
    .then(response => {
      return response.data;
    });
}

export function changeSchoolInEvent(user: AppUser, eventId: string, schoolId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  const data = { invitedSchoolIds: [schoolId] };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/changeOpponent`, data, config)
    .then(response => {
      return response.data;
    });
}

export function activateEvent(user: AppUser, eventId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/activate`, {}, config)
    .then(response => {
      return response.data;
    });
}

export function addTeam(user: AppUser, tournamentId: string, eventId: string, team) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .post(
      `${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/events/${eventId}/teams`,
      team,
      config
    )
    .then(response => {
      return response.data;
    });
}

export function getSchoolEvent(user: AppUser, eventId): Promise<SchoolEvent> {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .get<SchoolEvent>(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}`, config)
    .then(response => {
      return response.data;
    });
}

export function addGroupEvents(user: AppUser, tournamentId: string, data) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/events/group`, data, config)
    .then(response => {
      return response.data;
    });
}

export function getEventTasks(user: AppUser, eventId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/tasks`, config).then(response => {
    return response.data;
  });
}

export function editEventTasks(user: AppUser, eventId: string, taskId: string, data) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .put(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/tasks/${taskId}`, data, config)
    .then(response => {
      return response.data;
    });
}

export function createEventTasks(user: AppUser, eventId: string, data) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/tasks`, data, config)
    .then(response => {
      return response.data;
    });
}

export function updateSchoolEventTeamPlayersBatch(
  user: AppUser,
  eventId: string,
  teamId: string,
  data: SchoolTeamPlayersBatchData
) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/teams/${teamId}/players/batch`, data, config)
    .then(response => {
      return response.data;
    });
}

export function setEventResultsMode(user: AppUser, eventId: string, resultsMode: string) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/resultsMode`, { resultsMode }, config)
    .then(response => {
      return response.data;
    });
}

export function createEventResultsCompleteness(user: AppUser, eventId: string, data) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/results/completeness`, data, config)
    .then(response => {
      return response.data;
    });
}

export function updateEventResultsCompleteness(user: AppUser, eventId: string, completenessId: string, data) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .put(
      `${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/results/completeness/${completenessId}`,
      data,
      config
    )
    .then(response => {
      return response.data;
    });
}

export function getEventAvailableUsers(user: AppUser, eventId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/notifications/availableUsers`, config)
    .then(response => {
      return response.data;
    });
}

export function sendEventNotification(
  user: AppUser,
  eventId: string,
  data: { text: string; title: string; users: { userId: string; permissionId: string }[] }
) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/notifications`, data, config)
    .then(response => {
      return response.data;
    });
}

export function sendEventNotificationEligibleStudents(
  user: AppUser,
  eventId: string,
  data: { text: string; title: string }
) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/notificationEligibleStudents`, data, config)
    .then(response => {
      return response.data;
    });
}

export function removeSchoolsFromEvent(user: AppUser, eventId: string, schoolIds: string[]) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const data = { invitedSchoolIds: schoolIds };
  const config = {
    headers: { usid: key },
    data: data
  };

  return axios
    .delete(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/opponents`, config)
    .then(response => {
      return response.data;
    });
}

export function updateEventSetting(user: AppUser, eventId: string, data) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .put(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/settings`, data, config)
    .then(response => {
      return response.data;
    });
}

export function getEventRecord(user: AppUser, eventId: string, eventDate: Date) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  const params = {
    eventDate: eventDate.toISOString()
  };

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/records`, { ...config, params })
    .then(response => response.data)
    .catch(error => {
      console.error('Error fetching event records:', error);
      throw error;
    });
}

export function updateEventRecord(user: AppUser, eventId: string, record) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .put(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/records`, record, config)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error('Error updating event record:', error);
    });
}
