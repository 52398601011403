import { AppUser } from 'Src/views/App/App';
import axios from 'axios';
import { DEFAULT_FILTER } from '../admin/user';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../consts/table';
import { Count } from '../../../models/count';
import { TournamentTeam } from '../../../models/tournamentTeam';

const DEFAULT_TOURNAMENT_CREATOR_FILTER: any = {
  isCreator: true
};

const DEFAULT_TOURNAMENT_PARTICIPANT_FILTER: any = {
  isParticipant: true
};

export function getSchoolUnionTournamentTeams(
  user: AppUser,
  tournamentId,
  filter = DEFAULT_TOURNAMENT_CREATOR_FILTER
): Promise<TournamentTeam[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...DEFAULT_TOURNAMENT_CREATOR_FILTER,
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order
      }
    }
  };

  return axios
    .get<TournamentTeam[]>(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/teams`, config)
    .then(response => {
      return response.data;
    });
}

export function getSchoolUnionHighLevelTournamentTeams(
  user: AppUser,
  tournamentId,
  filter = DEFAULT_TOURNAMENT_PARTICIPANT_FILTER
): Promise<TournamentTeam[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER,
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order
      }
    }
  };

  return axios
    .get<TournamentTeam[]>(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/teams`, config)
    .then(response => {
      return response.data;
    });
}

export function getAllSchoolUnionTournamentTeams(user: AppUser, tournamentId, where?): Promise<TournamentTeam[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_TOURNAMENT_CREATOR_FILTER,
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_TOURNAMENT_CREATOR_FILTER
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios
    .get<TournamentTeam[]>(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/teams`, config)
    .then(response => {
      return response.data;
    });
}

export function getAllSchoolUnionHighLevelTournamentTeams(
  user: AppUser,
  tournamentId,
  where?
): Promise<TournamentTeam[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER,
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios
    .get<TournamentTeam[]>(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/teams`, config)
    .then(response => {
      return response.data;
    });
}

export function getSchoolUnionTournamentTeamsCount(user: AppUser, tournamentId, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_TOURNAMENT_CREATOR_FILTER,
            ...where
          }
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_TOURNAMENT_CREATOR_FILTER
          }
        }
      }
    };
  }

  return axios
    .get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/teams/count`, config)
    .then(response => {
      return response.data;
    });
}

export function getSchoolUnionHighLevelTournamentTeamsCount(user: AppUser, tournamentId, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER,
            ...where
          }
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER
          }
        }
      }
    };
  }

  return axios
    .get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/teams/count`, config)
    .then(response => {
      return response.data;
    });
}

export function getTournamentEventTeams(user: AppUser, tournamentId: string, filter: any = DEFAULT_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter
    }
  };

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/eventTeams`, config)
    .then(response => {
      return response.data;
    });
}

export function getTournamentResultTeams(
  user: AppUser,
  tournamentId: string,
  teamId: string,
  filter: any = DEFAULT_FILTER
) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter
    }
  };

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/teams/${teamId}/result`, config)
    .then(response => {
      return response.data;
    });
}

export function updateTournamentResultTeams(user: AppUser, tournamentId: string, teamId: string, data) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .put(
      `${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/teams/${teamId}/result`,
      data,
      config
    )
    .then(response => {
      return response.data;
    });
}

export function createTournamentResultTeams(user: AppUser, tournamentId: string, teamId: string, data) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .post(
      `${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/teams/${teamId}/result`,
      data,
      config
    )
    .then(response => {
      return response.data;
    });
}
