import { Sport } from '../../models/sport';
import { SCORING, SPORT_POINTS_SIGN } from '../../consts/sport';
import { SchoolEvent } from '../../models/event';
import * as propz from 'propz';

export function isLessScoringSport(sport: Sport): boolean {
  return (
    sport.scoring === SCORING.LESS_SCORES ||
    sport.scoring === SCORING.LESS_TIME ||
    sport.scoring === SCORING.LESS_RESULT
  );
}

export function getRegexpFromSportSign(event: SchoolEvent): RegExp {
  let regExp;

  const sign = propz.get(event, ['sport', 'points', 'sign'], SPORT_POINTS_SIGN.PLUS);

  switch (true) {
    case sign === SPORT_POINTS_SIGN.PLUS:
      regExp = /^[0-9.]+$/;
      break;
    case sign === SPORT_POINTS_SIGN.MINUS:
      regExp = /^0+$|^-+$|^-[0-9.]+$/; // 0 or - or -[0..9]
      break;
    case sign === SPORT_POINTS_SIGN.BOTH:
      regExp = /^-+$|^-[0-9.]+$|^[0-9.]+$/;
      break;
  }

  return regExp;
}
