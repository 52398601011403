import * as React from 'react';
import { useState, useEffect } from 'react';
import { ImportStudents } from './ImportStudents';
import { AppUser } from 'Src/views/App/App';
import { Loader } from 'Src/components/Loader/Loader';
import { getAllForms } from 'Src/helpers/service/admin/forms';
import { getAllHouses } from 'Src/helpers/service/admin/houses';

interface ImportStudentsWrapperProps {
  user: AppUser;
}

export const ImportStudentsWrapper: React.FC<ImportStudentsWrapperProps> = ({ user }) => {
  const [school, setSchool] = useState<any>(null);
  const [forms, setForms] = useState<any[]>([]);
  const [houses, setHouses] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchSchoolData = async () => {
      if (user && user.activeSchool) {
        setSchool(user.activeSchool);
        try {
          const fetchedForms = await getAllForms(user);
          setForms(fetchedForms);
          const fetchedHouses = await getAllHouses(user);
          setHouses(fetchedHouses);
        } catch (error) {
          console.error('Error fetching forms or houses:', error);
        }
      } else {
        console.error('Active school data is missing or incomplete.');
      }
      setIsLoading(false);
    };

    fetchSchoolData().catch(error => {
      console.error('Unhandled error in useEffect:', error);
    });

    return () => {};
  }, [user]);

  if (isLoading) {
    return <Loader />;
  }
  return <ImportStudents user={user} school={school} forms={forms} houses={houses} />;
};
