import * as React from 'react';
import * as propz from 'propz';
import { Component } from 'react';
import { Tournament } from 'Src/models/tournament';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button } from 'Src/components/Button/Button';
import { AppUser } from 'Src/views/App/App';
import {
  getTeamPlacesLeft,
  isIndividualTournament,
  isMiniTeamTournament,
  isTeamTournament
} from 'Src/helpers/tournament/tournament';
import { GENDER, SPORT_GENDER_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/common';
import { AGE_GROUPS, AGE_GROUPS_SORTED } from 'Src/consts/school';
import { TournamentTeam } from 'Src/models/tournamentTeam';

interface Props {
  tournament: Tournament;
  onSubmit: (data: any) => void;
  onCancel: () => void;
  user: AppUser;
  team?: TournamentTeam;
  allSchoolTeams: TournamentTeam[];
}

interface State {}

export class TournamentTeamForm extends Component<Props, State> {
  isTeamExist() {
    const { team } = this.props;
    return typeof team !== 'undefined';
  }

  getTeamSchema() {
    const { tournament } = this.props;
    let teamSchema;

    switch (true) {
      case isIndividualTournament(tournament):
        teamSchema = Yup.object().shape({
          name: Yup.string().required('Required')
        });
        break;
      default:
        teamSchema = Yup.object().shape({
          name: Yup.string().required('Required'),
          gender: Yup.string().required('Required'),
          age: Yup.number().required('Required')
        });
    }

    return teamSchema;
  }

  getTeamInitial() {
    const { tournament, user, team } = this.props;

    const tournamentName = propz.get(tournament, ['name'], '');
    const schoolName = propz.get(user, ['activeSchoolName'], '');
    const teamName = propz.get(team, ['name'], '');
    const teamGender = propz.get(team, ['gender'], '');
    const teamAge = propz.get(team, ['ages', '0'], '');

    let teamInitial;

    switch (true) {
      case isIndividualTournament(tournament):
        teamInitial = {
          name: this.isTeamExist() ? teamName : `${schoolName} ${tournamentName}`
        };
        break;
      default:
        teamInitial = {
          name: this.isTeamExist() ? teamName : `${schoolName} ${tournamentName}`,
          gender: this.isTeamExist() ? teamGender : this.getGenderInitial(),
          age: this.isTeamExist() ? teamAge : this.getAgeInitial()
        };
    }

    return teamInitial;
  }

  getGenderInitial(): string {
    const { tournament } = this.props;
    const { isMixedTeamsAvailable, gender } = tournament;
    const isGenderMixed = gender === GENDER.MIXED;

    return isGenderMixed && !isMixedTeamsAvailable ? GENDER.MALE_ONLY : gender;
  }

  getAgeInitial(): number {
    const { tournament } = this.props;
    const { ages } = tournament;

    const agesSorted = [...ages].sort((age1, age2) => age1 - age2);
    const ageInitial = propz.get(agesSorted, ['0'], 0);
    return ageInitial;
  }

  renderGenderOptions(): React.ReactNode {
    const { tournament } = this.props;
    const { isMixedTeamsAvailable, gender } = tournament;

    const isGenderMixed = gender === GENDER.MIXED;
    const isGenderMale = gender === GENDER.MALE_ONLY;
    const isGenderFemale = gender === GENDER.FEMALE_ONLY;

    switch (true) {
      case isGenderMale:
        return (
          <option key="gender_option_male" value={GENDER.MALE_ONLY}>
            {SPORT_GENDER_SERVER_TO_CLIENT_MAPPING.MALE_ONLY}
          </option>
        );
      case isGenderFemale:
        return (
          <option key="gender_option_female" value={GENDER.FEMALE_ONLY}>
            {SPORT_GENDER_SERVER_TO_CLIENT_MAPPING.FEMALE_ONLY}
          </option>
        );
      case isGenderMixed && !isMixedTeamsAvailable:
        return (
          <>
            <option key="gender_option_male" value={GENDER.MALE_ONLY}>
              {SPORT_GENDER_SERVER_TO_CLIENT_MAPPING.MALE_ONLY}
            </option>
            <option key="gender_option_female" value={GENDER.FEMALE_ONLY}>
              {SPORT_GENDER_SERVER_TO_CLIENT_MAPPING.FEMALE_ONLY}
            </option>
          </>
        );
      default:
        return (
          <>
            <option key="gender_option_female" value={GENDER.MIXED}>
              {SPORT_GENDER_SERVER_TO_CLIENT_MAPPING.MIXED}
            </option>
          </>
        );
    }
  }

  renderAgeOptions(): React.ReactNode {
    const { tournament, user } = this.props;
    const { ages } = tournament;

    const { activeSchool } = user;
    const { ageGroupsNaming } = activeSchool;
    const ageGroup = propz.get(AGE_GROUPS, [ageGroupsNaming]);
    const ageGroupSorted = propz.get(AGE_GROUPS_SORTED, [ageGroupsNaming]);

    return [...ages]
      .map(age => {
        return {
          value: age,
          text: ageGroup[age]
        };
      })
      .sort((ageObj1, ageObj2) => {
        const sortedAgeIndex1 = ageGroupSorted.indexOf(ageObj1.text);
        const sortedAgeIndex2 = ageGroupSorted.indexOf(ageObj2.text);

        return sortedAgeIndex1 - sortedAgeIndex2;
      })
      .map(ageObj => (
        <option key={ageObj.value} value={Number(ageObj.value)}>
          {ageObj.text}
        </option>
      ));
  }

  isSaveButtonEnabled(values): boolean {
    const { tournament, allSchoolTeams } = this.props;
    const { autoEnrollmentTeamsSettings, isAutoEnrollmentTeams } = tournament;

    if (this.isTeamExist()) {
      return true;
    }

    if (isIndividualTournament(tournament)) {
      return true;
    }

    if (!isAutoEnrollmentTeams) {
      return true;
    }

    let isTeamLimitExceeded = false;

    const age = Number(values.age);
    const gender = values.gender;

    const currentSchoolTeams = allSchoolTeams.filter(team => {
      const isAgeEqual = team.ages[0] === age;
      const isGenderEqual = team.gender === gender;

      return isAgeEqual && isGenderEqual;
    });

    const currentSchoolTeamsCount = currentSchoolTeams.length;

    const maximumNumberTeamsOfSchool = propz.get(tournament, ['maximumNumberTeamsOfSchool'], 0);
    const teamPlacesAvailableByTeamTypeLimit = getTeamPlacesLeft(autoEnrollmentTeamsSettings, age, gender);
    const teamPlacesAvailableBySchoolLimit = maximumNumberTeamsOfSchool - currentSchoolTeamsCount;

    const autoEnrollmentTeamsSetting = autoEnrollmentTeamsSettings.find(setting => {
      return setting.age === Number(age) && setting.gender === gender;
    });

    const { maximumNumberTeams } = autoEnrollmentTeamsSetting;
    const isTeamsLimitedByType = maximumNumberTeams !== 0;
    const isTeamsLimitedBySchool = maximumNumberTeamsOfSchool !== 0;

    const isTeamLimitByTypeExceeded = isTeamsLimitedByType && teamPlacesAvailableByTeamTypeLimit < 1;
    const isTeamLimitBySchoolExceeded = isTeamsLimitedBySchool && teamPlacesAvailableBySchoolLimit < 1;

    if (isTeamLimitByTypeExceeded || isTeamLimitBySchoolExceeded) {
      isTeamLimitExceeded = true;
    }

    return !isTeamLimitExceeded;
  }

  render() {
    const { tournament } = this.props;
    const isTeamOrMiniTeam = isTeamTournament(tournament) || isMiniTeamTournament(tournament);

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <Formik
              initialValues={this.getTeamInitial()}
              validationSchema={this.getTeamSchema()}
              onSubmit={values => {
                this.props.onSubmit(values);
              }}
              render={({ touched, errors, values }) => (
                <Form>
                  <div className="form-group">
                    <label>Name</label>
                    <Field type="text" name="name" className="form-control" />
                  </div>
                  {touched.name && errors.name ? <div className="alert alert-danger">{errors.name}</div> : null}

                  {isTeamOrMiniTeam && (
                    <div className="form-group">
                      <label>Gender</label>
                      <Field component="select" name="gender" className="form-control mb-3">
                        {this.renderGenderOptions()}
                      </Field>
                    </div>
                  )}
                  {isTeamOrMiniTeam && touched.gender && errors.gender ? (
                    <div className="alert alert-danger">{errors.gender}</div>
                  ) : null}

                  {isTeamOrMiniTeam && (
                    <div className="form-group">
                      <label>Age</label>
                      <Field component="select" name="age" className="form-control mb-3">
                        {this.renderAgeOptions()}
                      </Field>
                    </div>
                  )}
                  {isTeamOrMiniTeam && touched.age && errors.age ? (
                    <div className="alert alert-danger">{errors.age}</div>
                  ) : null}

                  {!this.isSaveButtonEnabled(values) ? <div className="alert alert-danger">Limit exceeded</div> : null}

                  <Button onClick={this.props.onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                  <button
                    type="submit"
                    className="mt-3 mb-3 btn btn-primary"
                    disabled={!this.isSaveButtonEnabled(values)}
                  >
                    Save
                  </button>
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}
