import * as React from 'react';
import { Component } from 'react';
import './MessageSettingsForm.css';
import { GeneralMessagesSettingFormPriorities } from "./GeneralMessagesSettingFormPriorities";
import { ParentsDeliverySettings } from "../../models/generalMessage";
import {LabelWithQuestionIcon} from "../LabelWithQuestionIcon/LabelWithQuestionIcon";

interface Props {
  messageTitle: string
  messageDescription: string

  isSendStudents?: boolean
  isSendParents?: boolean
  isSendStaff?: boolean
  isSendGovernors?: boolean

  onTitleChange: (event) => void
  onDescriptionChange: (event) => void
  onIsSendParentsChange?: (event) => void
  onIsSendStudentsChange?: (event) => void
  onSendStaffChange?: (event) => void
  onSendGovernorsChange?: (event) => void
  onDeliverySettingsChanged?: (settings: ParentsDeliverySettings) => void
  parentsDeliverySettings?: ParentsDeliverySettings
}

export class GeneralMessageSettingsForm extends Component<Props> {
  render(){
    const {
      messageTitle,
      messageDescription,
      isSendStudents,
      isSendParents,
      isSendStaff, isSendGovernors,
      onTitleChange,
      onDescriptionChange,
      onIsSendParentsChange,
      onIsSendStudentsChange,
      onSendStaffChange,
      onSendGovernorsChange,
      onDeliverySettingsChanged,
      parentsDeliverySettings
    } = this.props;
    
    return (
      <div className = "bMessageSettingsForm" >
        <div className = "form-group" >
          <LabelWithQuestionIcon
              labelText="Title"
              hintText={"This is the title of the message, please note this will not be sent to users who recieve the" +
              " communication, it is solely for staff members use to differentiate between messages."}
          />
          <input
            type = "text"
            className = "form-control"
            placeholder = "Enter title"
            onChange = { onTitleChange }
            value = { messageTitle }
          />
        </div>
        <div className="form-group">
          <LabelWithQuestionIcon
              labelText="Description"
              hintText={"The description will not be sent to users who recieve the communication, it is solely for staff" +
              " members use to differentiate between messages."}
          />
          <input
            type = "text"
            className = "form-control"
            placeholder = "Enter description"
            onChange = { onDescriptionChange }
            value = { messageDescription }
          />
        </div>
        {typeof isSendParents !== 'undefined' && (
          <React.Fragment>
            <div className="form-group form-check">
              <input
                id = "messageSettingsFormIsSendParents"
                type = "checkbox"
                className = "form-check-input"
                checked = { isSendParents }
                onChange = { onIsSendParentsChange }
              />
              <LabelWithQuestionIcon
                  labelText="Send to parents"
                  hintText={"This will send communications to parental user, you can select just parents or select " +
                  "parents alongside any other user role type."}
                  customLabelClass="form-check-label"
                  labelHtmlFor="messageSettingsFormIsSendParents"
              />
            </div>
            <GeneralMessagesSettingFormPriorities
              parentsDeliverySettings={ parentsDeliverySettings }
              onDeliverySettingsChanged={ onDeliverySettingsChanged }
              disabled={ !isSendParents }
            />
          </React.Fragment>
        )}
        {typeof isSendStudents !== 'undefined' && (
          <div className="form-group form-check">
            <input
              id = "messageSettingsFormIsSendStudents"
              type = "checkbox"
              className = "form-check-input"
              checked = { isSendStudents }
              onChange = { onIsSendStudentsChange }
            />
            <LabelWithQuestionIcon
                labelText="Send to students"
                hintText={"This will send communications to student users, you can select just students or select students" +
                " alongside any other user role type. Please note that students need to have active accounts within the " +
                "platform to be able to receive communications."}
                customLabelClass="form-check-label"
                labelHtmlFor="messageSettingsFormIsSendStudents"
            />
          </div>
        )}
        {typeof isSendStaff !== 'undefined' && (
          <div className="form-group form-check">
            <input
              id = "messageSettingsFormIsSendStaff"
              type = "checkbox"
              className = "form-check-input"
              checked = { isSendStaff }
              onChange = { onSendStaffChange }
            />
            <LabelWithQuestionIcon
                labelText="Send to school staff"
                hintText={"This will send communications to staff users, you can select just staff or select staff " +
                "alongside any other user role type."}
                customLabelClass="form-check-label"
                labelHtmlFor="messageSettingsFormIsSendStaff"
            />
          </div>
        )}
        {typeof isSendGovernors !== 'undefined' && (
          <div className="form-group form-check">
            <input
              id = "messageSettingsFormIsSendGovernors"
              type = "checkbox"
              className = "form-check-input"
              checked = { isSendGovernors }
              onChange = { onSendGovernorsChange }
            />
            <LabelWithQuestionIcon
                labelText="Send to governors"
                hintText={"This will send communications to trusties/govenors, you can select just govenors or select " +
                "govenors alongside any other user role type. Govenors is not a role that can be signed up to, however " +
                "you can assign a user with the role of govenor so they recieve this type of communication."}
                customLabelClass="form-check-label"
                labelHtmlFor="messageSettingsFormIsSendGovernors"
            />
          </div>
        )}
      </div>
    );
  }
  
}