import * as React from 'react';
import * as BPromise from 'bluebird';
import * as Lazy from 'lazy.js';
import { AppUser } from 'Src/views/App/App';
import { getSchoolUsersCount, getSchoolUsers, getAllSchoolUsers } from 'Src/helpers/service/admin/users';
import { parse } from 'query-string';
import * as Moment from 'moment';
import { getOrder, getSearchFilter, getSearchOrder, getServerFieldSectionWhere } from 'Src/helpers/table/table';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { Loader } from 'Src/components/Loader/Loader';
import { getAllUserTags } from 'Src/helpers/service/admin/userTags';
import { SchoolUser } from 'Src/models/schoolUser';
import {
  ColumnDefinition,
  getFilters2,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import {
  getEmailOrMisEmail,
  getPhoneOrMisPhone,
  getUserRole,
  getUserStatus,
  getUserTags
} from 'Src/helpers/accessor/accessor';
import { History, Location } from 'history';
import { getSelectOptionForRole, getSelectOptionForTag, getSelectOptionForUserStatus } from 'Src/helpers/table/select';
import { Grid2 } from 'Src/components/Grid/Grid2';
import { UserTagAssignModal } from 'Src/components/UserTagAssignModal/UserTagAssignModal';
import { UserTagDeleteModal } from 'Src/components/UserTagDeleteModal/UserTagDeleteModal';
import { assignUserTag, deleteUserTagByUser } from 'Src/helpers/service/admin/users';
import { Tag } from 'Src/models/tag';
import { ROLE } from 'Src/consts/user';
import { GeneralMessageWizzard } from 'Src/components/GeneralMessageWizzard/GeneralMessageWizzard';
import { getSettingsUploadFiles } from 'Src/helpers/service/nobody/settings';
import { SimpleModal } from '../../../../../components/SimpleModal/SimpleModal';
import { SchoolUserPermission } from '../../../../../models/schoolUser';
import { ROLE_SERVER_TO_CLIENT_MAPPING } from '../../../../../consts/user';
import { UserRevokeRoleForm } from './UserRevokeRoleForm/UserRevokeRoleForm';
import * as propz from 'propz';
import { deleteDataToPermission, postUserPermission } from '../../../../../helpers/service/admin/staffs';
import { UserAddRoleForm } from './UserAddRoleForm/UserAddRoleForm';
import { TextUtils } from '../../../../../helpers/utils/TextUtils';
import { uploadReport } from 'Src/helpers/service/admin/report';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/report';
import { UserActivityDetailsReportForm } from './Reports/UserActivityDetailsReportForm/UserActivityDetailsReportForm';
import { StudentActivityReportForm } from './Reports/StudentActivityReportForm/StudentActivityReportForm';
import { ParentActivityReportForm } from './Reports/ParentActivityReportForm/ParentActivityReportForm';
const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Name',
    field: 'firstName',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['firstName']
  },
  {
    text: 'Surname',
    field: 'lastName',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['lastName']
  },
  {
    text: 'Email',
    field: 'email',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: getEmailOrMisEmail
  },
  {
    text: 'Phone',
    field: 'phone',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: getPhoneOrMisPhone
  },
  {
    text: 'Role',
    field: 'role',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getUserRole
  },
  {
    text: 'Status',
    field: 'userStatus',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getUserStatus
  },
  {
    text: 'Tag',
    field: 'tag',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getUserTags
  }
];

interface State {
  items: SchoolUser[];
  currentPage: number;
  selectedItems: SchoolUser[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  userTags: any[];
  total: number;
  basePath: string;
  maxSizeUploadFile: number;
  revokePermissions: SchoolUserPermission[];

  isMessageModalOpen: boolean;
  isUserTagModalOpen: boolean;
  isUserTagDeleteModalOpen: boolean;
  isConfirmRevokeRoleModalOpen: boolean;
  isRevokeRoleFormOpen: boolean;
  isAddRoleFormOpen: boolean;
  isUserActivityDetailsReportModalOpen: boolean; //023
  isStudentActivityReportModalOpen: boolean; //024
  isParentActivityReportModalOpen: boolean; //025

  errorMessage: string;
  isErrorModalOpen: boolean;

  selectedUserTagId: string;
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export class Users extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      userTags: [],
      total: 0,
      basePath: '',
      maxSizeUploadFile: 0,
      revokePermissions: [],

      isMessageModalOpen: false,
      isUserTagModalOpen: false,
      isUserTagDeleteModalOpen: false,
      isConfirmRevokeRoleModalOpen: false,
      isRevokeRoleFormOpen: false,
      isAddRoleFormOpen: false,
      isUserActivityDetailsReportModalOpen: false,
      isStudentActivityReportModalOpen: false,
      isParentActivityReportModalOpen: false,

      errorMessage: '',
      isErrorModalOpen: false,

      selectedUserTagId: ''
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setAdditionalItems().then(res => {
      this.setItems();
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  setAdditionalItems() {
    const { user } = this.props;

    return BPromise.all([getAllUserTags(user), getSettingsUploadFiles(user)]).then(([tags, maxSizeUploadFile]) => {
      this.setState({
        userTags: tags,
        maxSizeUploadFile: maxSizeUploadFile
      });

      return true;
    });
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const getSchoolUsersPromise = getSchoolUsers(user, serverQueryFilter);
    const getSchoolUsersCountPromise = getSchoolUsersCount(user, where);

    const promises = [getSchoolUsersCountPromise, getSchoolUsersPromise];

    return BPromise.all(promises).then(([countObj, users]) => {
      this.setState({
        currentPage: page,
        sortDirection: sortDirection,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: users,
        total: countObj.count,
        basePath: history.location.pathname,
        isLoading: false
      });

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedUser = items[index];

    const selectedUserIndex = selectedItems.findIndex(user => selectedUser.id === user.id);
    let selectedUsersUpdated = [...selectedItems];

    if (selectedUserIndex !== -1) {
      selectedUsersUpdated.splice(selectedUserIndex, 1);
    } else {
      selectedUsersUpdated.push(selectedUser);
    }

    this.setState({
      selectedItems: selectedUsersUpdated
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const usersFiltered = items.filter(user => {
      return selectedItems.every(selectedUser => selectedUser.id !== user.id);
    });

    const selectedUsers = [...selectedItems, ...usersFiltered];

    this.setState({
      selectedItems: selectedUsers,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    getAllSchoolUsers(user, where).then(users => {
      const usersFiltered = users.filter(user => {
        return selectedItems.every(selectedUser => selectedUser.id !== user.id);
      });

      const selectedUsers = [...selectedItems, ...usersFiltered];

      this.setState({
        selectedItems: selectedUsers,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: ''
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  onSendMessageClick = (): void => {
    this.setState({
      isMessageModalOpen: true
    });
  };

  onUserTagAssignClick = (): void => {
    this.setState({
      isUserTagModalOpen: true
    });
  };

  onUserTagDeleteClick = (): void => {
    this.setState({
      isUserTagDeleteModalOpen: true
    });
  };

  onUserTagChange = (event): void => {
    const value = event.target.value;

    this.setState({
      selectedUserTagId: value
    });
  };

  onUserTagAssignModalCloseClick = (): void => {
    this.setState({
      isUserTagModalOpen: false
    });
  };

  onUserTagSaveClick = (): void => {
    const userTagId = this.state.selectedUserTagId;
    const user = this.props.user;

    const data = {
      userTagId: userTagId
    };

    this.setState({
      isLoading: true
    });

    if (!userTagId) {
      this.setState({
        isUserTagModalOpen: false,
        selectedUserTagId: ''
      });
      console.error('Can not assign user tag id');
    } else {
      const selectedItems = this.state.selectedItems;
      BPromise.all(
        selectedItems.map(selectedItem => {
          const userId = selectedItem.id;
          return assignUserTag(user, data, userId);
        })
      ).then(items => {
        this.setState({
          isUserTagModalOpen: false,
          selectedUserTagId: '',
          selectedItems: items
        });
        this.setItems();
      });
    }
  };

  renderUserTagAssignModal(): React.ReactNode {
    const { userTags, selectedUserTagId, isUserTagModalOpen } = this.state;

    return (
      <UserTagAssignModal
        modalTitle={'Assign User Tag'}
        userTags={userTags}
        selectedUserTagId={selectedUserTagId}
        onUserTagChange={this.onUserTagChange}
        onCloseClick={this.onUserTagAssignModalCloseClick}
        isOpen={isUserTagModalOpen}
        onSaveClick={this.onUserTagSaveClick}
      />
    );
  }

  getUserTags(): Tag[] {
    const { selectedItems, items } = this.state;

    const tags = Lazy(selectedItems)
      .map(selectedItem => {
        const selectedItemId = selectedItem.id;
        const user = items.find(userState => userState.id === selectedItemId);

        if (typeof user !== 'undefined') {
          return user.permissions[0].tags;
        } else {
          return [];
        }
      })
      .filter(tagList => tagList.length > 0)
      .flatten()
      .uniq('id')
      .toArray();

    return tags;
  }

  onUserTagDeleteModalCloseClick = (): void => {
    this.setState({
      isUserTagDeleteModalOpen: false
    });
  };

  onUserTagDelete = (): void => {
    const userTagId = this.state.selectedUserTagId;
    const user = this.props.user;

    this.setState({
      isLoading: true
    });

    if (!userTagId) {
      this.setState({
        isUserTagDeleteModalOpen: false,
        selectedUserTagId: ''
      });
      console.error('Can not assign user tag id');
    } else {
      const selectedItems = this.state.selectedItems;

      BPromise.all(
        selectedItems.map(selectedItem => {
          const userId = selectedItem.id;
          return deleteUserTagByUser(user, userId, userTagId);
        })
      ).then(res => {
        //replace deleted tag
        selectedItems.forEach(item => {
          item.permissions.forEach(permission => {
            const tagIdIndex = permission.tagIds.findIndex(tagId => tagId === userTagId);
            const tagIndex = permission.tags.findIndex(tag => tag.id === userTagId);

            permission.tagIds.splice(tagIdIndex, 1);
            permission.tags.splice(tagIndex, 1);
          });
        });

        this.setState({
          isUserTagDeleteModalOpen: false,
          selectedUserTagId: '',
          selectedItems: selectedItems,
          isSelectAllChecked: false
        });

        this.setItems();
      });
    }
  };

  renderUserTagDeleteModal(): React.ReactNode {
    const { selectedUserTagId, isUserTagDeleteModalOpen } = this.state;

    return (
      <UserTagDeleteModal
        modalTitle={'Delete User Tag'}
        userTags={this.getUserTags()}
        selectedUserTagId={selectedUserTagId}
        onUserTagChange={this.onUserTagChange}
        onCloseClick={this.onUserTagDeleteModalCloseClick}
        isOpen={isUserTagDeleteModalOpen}
        onDeleteClick={this.onUserTagDelete}
      />
    );
  }

  onMessageSubmitClick = message => {
    const { history } = this.props;

    history.push({
      pathname: '/generalMessages',
      state: { message: message }
    });
  };

  onCloseMessageWizardClick = (): void => {
    this.setState({
      isMessageModalOpen: false
    });
  };

  onAddUserRoleClick = (): void => {
    this.setState({
      isAddRoleFormOpen: true
    });
  };

  onCloseAddRoleFormModalClick = (): void => {
    this.setState({
      isAddRoleFormOpen: false
    });
  };

  onSubmitUserAddRoleForm = data => {
    const { user } = this.props;
    this.setState({
      isLoading: true
    });

    const selectedItem = { ...this.state.selectedItems[0] };
    const { activeSchoolId } = user;

    const { priority, ...rest } = data;

    let dataCopy = {
      ...rest,
      schoolId: activeSchoolId,
      priority: Number(priority)
    };

    if (data.preset !== ROLE.COACH && data.preset !== ROLE.TEACHER) {
      delete dataCopy.sportIds;
      delete dataCopy.sports;
    }

    if (data.preset !== ROLE.PARENT) {
      delete dataCopy.studentId;
      delete dataCopy.priority;
      delete dataCopy.priority;
    }

    postUserPermission(user, selectedItem.id, dataCopy).then(item => {
      selectedItem.permissions.push(item);

      this.setState({
        isAddRoleFormOpen: false,
        selectedItems: [selectedItem]
      });
      this.setItems();
    });
  };

  onRevokeUserRoleClick = (): void => {
    const { selectedItems } = this.state;
    const selectedItem = selectedItems[0];
    const userPermissions = selectedItem.permissions;
    const isOnePermission = userPermissions.length === 1;

    if (isOnePermission) {
      this.setState({
        revokePermissions: userPermissions,
        isConfirmRevokeRoleModalOpen: true
      });
    } else {
      this.setState({
        isRevokeRoleFormOpen: true
      });
    }
  };

  onRevokeRoleModalClick = (): void => {
    const { revokePermissions, selectedItems } = this.state;
    const { user } = this.props;
    const selectedItem = selectedItems[0];
    const userId = propz.get(selectedItem, ['id'], '');

    let promises = [];
    revokePermissions.forEach(permission => {
      promises.push(deleteDataToPermission(user, userId, permission.id));
    });

    this.setState({
      isLoading: true
    });
    BPromise.all(promises).then(res => {
      this.setState({
        selectedItems: [],
        isConfirmRevokeRoleModalOpen: false,
        isLoading: false
      });
      this.setItems();
    });
  };

  onCloseConfirmRevokeRoleModalClick = (): void => {
    this.setState({
      isConfirmRevokeRoleModalOpen: false
    });
  };

  onCloseRevokeRoleFormModalClick = (): void => {
    this.setState({
      isRevokeRoleFormOpen: false
    });
  };

  onSubmitUserRevokeRoleForm = (revokePermissions: SchoolUserPermission[]): void => {
    const isExistRevokePermissions = revokePermissions.length > 0;
    if (isExistRevokePermissions) {
      this.setState({
        revokePermissions: revokePermissions,
        isRevokeRoleFormOpen: false,
        isConfirmRevokeRoleModalOpen: true
      });
    } else {
      this.setState({
        isRevokeRoleFormOpen: false
      });
    }
  };

  renderRevokeRoleFormModal(): React.ReactNode {
    const { isRevokeRoleFormOpen, selectedItems } = this.state;
    const selectedItem = selectedItems[0];
    const userPermissions = propz.get(selectedItem, ['permissions'], []);
    return (
      <SimpleModal isOpen={isRevokeRoleFormOpen} title={'Revoke role'}>
        <UserRevokeRoleForm
          userPermissions={userPermissions}
          onSubmit={this.onSubmitUserRevokeRoleForm}
          onCloseClick={this.onCloseRevokeRoleFormModalClick}
        />
      </SimpleModal>
    );
  }

  renderConfirmRevokeRoleModalOpen(): React.ReactNode {
    const { isConfirmRevokeRoleModalOpen, revokePermissions } = this.state;
    const roleNames = revokePermissions.map(permission => ROLE_SERVER_TO_CLIENT_MAPPING[permission.preset]).join(', ');

    return (
      <SimpleModal
        isOpen={isConfirmRevokeRoleModalOpen}
        title={'Warning'}
        body={`The selected ${
          revokePermissions.length === 1 ? 'role' : 'roles'
        } "${roleNames}" will be revoked. Are you sure?`}
        buttonText={'Revoke'}
        buttonCancelText={'Cancel'}
        onButtonClick={this.onRevokeRoleModalClick}
        onCloseClick={this.onCloseConfirmRevokeRoleModalClick}
      />
    );
  }

  renderAddRoleFormModal(): React.ReactNode {
    const { isAddRoleFormOpen, selectedItems } = this.state;
    const { user } = this.props;
    const selectedItem = selectedItems[0];
    const userPermissions = propz.get(selectedItem, ['permissions'], []);
    return (
      <SimpleModal isOpen={isAddRoleFormOpen} title={'New Permission'}>
        <UserAddRoleForm
          user={user}
          userPermissions={userPermissions}
          onSubmit={this.onSubmitUserAddRoleForm}
          onCloseClick={this.onCloseAddRoleFormModalClick}
        />
      </SimpleModal>
    );
  }

  renderGMWizard(): React.ReactNode {
    const { user } = this.props;
    const { selectedItems, isMessageModalOpen, maxSizeUploadFile } = this.state;
    const selectedUsers = selectedItems.slice();

    if (!isMessageModalOpen) {
      return null;
    }

    let isContainStaff = undefined;
    let isContainStudents = undefined;
    let isContainParents = undefined;

    selectedUsers.forEach(user => {
      user.permissions.forEach(permission => {
        const preset = permission.preset;

        const isAdmin = preset === ROLE.ADMIN;
        const isManager = preset === ROLE.MANAGER;
        const isCoach = preset === ROLE.COACH;
        const isGovernor = preset === ROLE.GOVERNOR;
        const isTeacher = preset === ROLE.TEACHER;
        const isStudent = preset === ROLE.STUDENT;
        const isParent = preset === ROLE.PARENT;

        if (isAdmin || isManager || isCoach || isGovernor || isTeacher) {
          isContainStaff = true;
        }
        if (isStudent) {
          isContainStudents = true;
        }
        if (isParent) {
          isContainParents = true;
        }
      });
    });

    return (
      <GeneralMessageWizzard
        user={user}
        onMessageSubmit={this.onMessageSubmitClick}
        onCancelClick={this.onCloseMessageWizardClick}
        isOpen={isMessageModalOpen}
        schoolStaff={selectedItems}
        sendToParents={isContainParents}
        sendToStudents={isContainStudents}
        sendToStaff={isContainStaff}
        maxSizeUploadFile={maxSizeUploadFile}
      />
    );
  }

  onSubmitReportForm = (data, reportType) => {
    const { user } = this.props;
    const { activeSchoolId, activeSchool } = user;
    const { selectedItems } = this.state;
    const schoolUser = selectedItems[0];
    const schoolUserId = propz.get(schoolUser, ['id'], '');

    const { dateTo, dateFrom, isShowZero, isShowNonZero } = data;

    let modData;
    let formsUniq;
    let ages: number[];

    switch (reportType) {
      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.USER_ACTIVITY_DETAILS:
        this.setState({
          isUserActivityDetailsReportModalOpen: false,
          isLoading: true
        });

        dateFrom.setHours(0, 0, 0, 0);
        dateTo.setHours(23, 59, 59, 0);
        const formattedDateTo = Moment(dateTo).format('YYYY-MM-DD HH:mm:ss');
        const formattedDateFrom = Moment(dateFrom).format('YYYY-MM-DD HH:mm:ss');

        modData = {
          reportParamsCSV: {
            schoolId: activeSchoolId,
            schoolUserId,
            dateFrom: formattedDateFrom,
            dateTo: formattedDateTo
          }
        };
        break;
      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.STUDENT_ACTIVITY:
        this.setState({
          isStudentActivityReportModalOpen: false,
          isLoading: true
        });

        dateFrom.setHours(0, 0, 0, 0);
        dateTo.setHours(23, 59, 59, 0);
        modData = {
          reportParamsCSV: {
            schoolId: activeSchoolId,
            dateFrom: Moment(dateFrom).format('YYYY-MM-DD HH:mm:ss'),
            dateTo: Moment(dateTo).format('YYYY-MM-DD HH:mm:ss'),
            showZero: isShowZero,
            showNonZero: isShowNonZero
          }
        };
        break;
      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.PARENT_ACTIVITY:
        this.setState({
          isParentActivityReportModalOpen: false,
          isLoading: true
        });

        dateFrom.setHours(0, 0, 0, 0);
        dateTo.setHours(23, 59, 59, 0);
        modData = {
          reportParamsCSV: {
            schoolId: activeSchoolId,
            dateFrom: Moment(dateFrom).format('YYYY-MM-DD HH:mm:ss'),
            dateTo: Moment(dateTo).format('YYYY-MM-DD HH:mm:ss'),
            showZero: isShowZero,
            showNonZero: isShowNonZero
          }
        };
        break;
    }

    uploadReport(user, reportType, modData)
      .then(data => {
        if (typeof data.reportId !== 'undefined') {
          const { reportType } = data;

          this.setState({
            isLoading: false
          });

          const url = `${window.apiBase}/public/report/${data.reportId}/reportType/${reportType}`;
          window.open(url);
        } else {
          this.setState({
            isLoading: false,
            errorMessage: 'No read report',
            isErrorModalOpen: true
          });
        }
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        console.error(error);

        this.setState({
          isLoading: false,
          errorMessage: errorText,
          isErrorModalOpen: true
        });
      });
  };

  openUserActivityDetailsReportForm = () => {
    this.setState({
      isUserActivityDetailsReportModalOpen: true
    });
  };

  closeUserActivityDetailsReportForm = () => {
    this.setState({
      isUserActivityDetailsReportModalOpen: false
    });
  };

  openStudentActivityReportForm = () => {
    this.setState({
      isStudentActivityReportModalOpen: true
    });
  };

  closeStudentActivityReportForm = () => {
    this.setState({
      isStudentActivityReportModalOpen: false
    });
  };

  openParentActivityReportForm = () => {
    this.setState({
      isParentActivityReportModalOpen: true
    });
  };

  closeParentActivityReportForm = () => {
    this.setState({
      isParentActivityReportModalOpen: false
    });
  };

  renderUserActivityDetailsReportFormModal() {
    const { isUserActivityDetailsReportModalOpen, selectedItems } = this.state;
    const user = selectedItems[0];
    return (
      <SimpleModal isOpen={isUserActivityDetailsReportModalOpen}>
        <UserActivityDetailsReportForm
          onCloseClick={this.closeUserActivityDetailsReportForm}
          onSubmit={this.onSubmitReportForm}
          user={user}
        />
      </SimpleModal>
    );
  }

  renderStudentActivityReportFormModal() {
    const { isStudentActivityReportModalOpen } = this.state;
    return (
      <SimpleModal isOpen={isStudentActivityReportModalOpen}>
        <StudentActivityReportForm
          onCloseClick={this.closeStudentActivityReportForm}
          onSubmit={this.onSubmitReportForm}
        />
      </SimpleModal>
    );
  }

  renderParentActivityReportFormModal() {
    const { isParentActivityReportModalOpen } = this.state;
    return (
      <SimpleModal isOpen={isParentActivityReportModalOpen}>
        <ParentActivityReportForm
          onCloseClick={this.closeParentActivityReportForm}
          onSubmit={this.onSubmitReportForm}
        />
      </SimpleModal>
    );
  }

  render() {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isLoading,
      userTags,
      isSelectAllChecked,
      currentPage,
      total,

      isMessageModalOpen,
      isUserTagModalOpen,
      isConfirmRevokeRoleModalOpen,
      isRevokeRoleFormOpen,
      isAddRoleFormOpen,
      isUserActivityDetailsReportModalOpen,
      isStudentActivityReportModalOpen,
      isParentActivityReportModalOpen,
      isErrorModalOpen
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes =
      isMessageModalOpen ||
      isUserTagModalOpen ||
      isConfirmRevokeRoleModalOpen ||
      isRevokeRoleFormOpen ||
      isUserActivityDetailsReportModalOpen ||
      isStudentActivityReportModalOpen ||
      isParentActivityReportModalOpen ||
      isAddRoleFormOpen
        ? 'mt-3 modal-open'
        : 'mt-3';

    const actionItems = [
      {
        itemText: 'Create message',
        onItemClick: this.onSendMessageClick,
        isActive: selectedItems.length > 0
      },
      {
        itemText: 'Assign tag',
        onItemClick: this.onUserTagAssignClick,
        isActive: selectedItems.length > 0
      },
      {
        itemText: 'Remove tag',
        onItemClick: this.onUserTagDeleteClick,
        isActive: selectedItems.length > 0
      },
      {
        itemText: 'Add role',
        onItemClick: this.onAddUserRoleClick,
        isActive: selectedItems.length === 1
      },
      {
        itemText: 'Revoke role',
        onItemClick: this.onRevokeUserRoleClick,
        isActive: selectedItems.length === 1
      },
      {
        itemText: 'Reports',
        isActive: true,
        subItems: [
          {
            itemText: 'User activity details',
            onItemClick: this.openUserActivityDetailsReportForm,
            isActive: selectedItems.length === 1
          },
          {
            itemText: 'Students activity summary',
            onItemClick: this.openStudentActivityReportForm,
            isActive: true
          },
          {
            itemText: 'Parents activity summary',
            onItemClick: this.openParentActivityReportForm,
            isActive: true
          }
        ]
      }
    ];

    const filterOptions = {
      role: getSelectOptionForRole(),
      userStatus: getSelectOptionForUserStatus(),
      tag: getSelectOptionForTag(userTags)
    };

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderUserTagAssignModal()}
        {this.renderUserTagDeleteModal()}
        {this.renderConfirmRevokeRoleModalOpen()}
        {this.renderRevokeRoleFormModal()}
        {this.renderAddRoleFormModal()}
        {this.renderGMWizard()}
        {this.renderUserActivityDetailsReportFormModal()}
        {this.renderStudentActivityReportFormModal()}
        {this.renderParentActivityReportFormModal()}
        <div className="row">
          <div className="col-md-12">
            <Grid2
              dataItems={items}
              filters={filters}
              currentPage={currentPage}
              total={total}
              isSelectAllChecked={isSelectAllChecked}
              isDataFiltered={isDataFiltered}
              sortDirection={sortDirection}
              sortColumnsName={sortColumnsName}
              isShowFilter={isShowFilter}
              dataItemsSelected={selectedItems}
              columns={COLUMNS}
              actionItems={actionItems}
              options={filterOptions}
              onItemClick={this.onItemClick}
              onSortClick={this.onTableSortClick}
              onApplyFilterClick={this.onApplyFilterClick}
              onClearFilterClick={this.onClearFilterClick}
              onTableFilterChange={this.onTableFilterChange}
              onTableFilterClick={this.onTableFilterClick}
              setCurrentPageParams={this.setCurrentPageParams}
              onSelectAllClick={this.onSelectAllClick}
              onSelectAllOnPageClick={this.onSelectAllOnPageClick}
              onUnselectAllClick={this.onUnselectAllClick}
            />
          </div>
        </div>
      </div>
    );
  }
}
