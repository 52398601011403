import { AppUser } from 'Src/views/App/App';
import axios from 'axios';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../consts/table';
import { Count } from '../../../models/count';

const DEFAULT_FILTER: any = {
  limit: DEFAULT_LIMIT,
  skip: DEFAULT_SKIP
};

export function getSchoolUnionRecords(user: AppUser, filter = DEFAULT_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order
      }
    }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/records`, config).then(response => {
    return response.data;
  });
}

export function getAllSchoolUnionRecords(user: AppUser, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/records`, config).then(response => {
    return response.data;
  });
}

export function getSchoolUnionRecordsCount(user: AppUser, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where
        }
      }
    };
  } else {
    config = {
      headers: { usid: key }
    };
  }

  return axios.get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/records/count`, config).then(response => {
    return response.data;
  });
}

export function createSchoolUnionRecords(user: AppUser, form: any) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios.post(`${window.apiBase}/i/schools/${activeSchoolId}/records`, form, config).then(response => {
    return response.data;
  });
}

export function updateSchoolUnionRecords(user: AppUser, id: string, form: any) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios.put(`${window.apiBase}/i/schools/${activeSchoolId}/records/${id}`, form, config).then(response => {
    return response.data;
  });
}

export function deleteSchoolUnionRecords(user: AppUser, recordsId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.delete(`${window.apiBase}/i/schools/${activeSchoolId}/records/${recordsId}`, config).then(response => {
    return response.data;
  });
}

export function getSportsOfRecords(user: AppUser, filter = DEFAULT_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key },
    params: {
      filter
    }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/records/sports`, config).then(response => {
    return response.data;
  });
}
