import { AppUser } from 'Src/views/App/App';
import axios from 'axios';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from 'Src/consts/table';

const DEFAULT_FILTER: any = {
  limit: DEFAULT_LIMIT,
  skip: DEFAULT_SKIP
};

export function getPaymentAccounts(user: AppUser, filter = DEFAULT_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order
      }
    }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/payments/accounts`, config).then(response => {
    return response.data;
  });
}

export function getTransactions(user: AppUser, accountId, filter = DEFAULT_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order
      }
    }
  };

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/payments/accounts/${accountId}/payments`, config)
    .then(response => {
      return response.data;
    });
}

export function getAllTransactions(user: AppUser, accountId, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }
  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/payments/accounts/${accountId}/payments`, config)
    .then(response => {
      return response.data;
    });
}

export function getTransactionsCount(user: AppUser, accountId, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/payments/accounts/${accountId}/payments/count`, config)
    .then(response => {
      return response.data;
    });
}

export function refundTransaction(
  user: AppUser,
  accountId: string,
  paymentId: string,
  paymentItemId: string,
  amount: number
) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: {
      usid: key
    }
  };

  return axios
    .post(
      `${window.apiBase}/i/schools/${activeSchoolId}/payments/accounts/${accountId}/payments/${paymentId}/paymentItemId/${paymentItemId}/refund`,
      { amount },
      config
    )
    .then(response => {
      return response.data;
    });
}
