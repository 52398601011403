import * as React from 'react';
import { FunctionComponent } from 'react';
import { MessageDefinition } from '../../types/message';
import { AppUser } from 'Src/views/App/App';
import {
  MESSAGE_INVITATION_STATUS_SERVER_TO_CLIENT_MAPPING,
  MESSAGE_KIND,
  MESSAGE_KIND_SERVER_TO_CLIENT_MAPPING
} from '../../consts/message';
import * as propz from 'propz';
import * as Moment from 'moment';
import {
  ClubParticipantInviteMessage,
  EventInvitationMessage,
  EventParticipationMessage,
  TournamentConfirmationRequestMessage
} from '../../models/message';
import { ClubInviteMainInformation } from './../ClubInviteMainInformation/ClubInviteMainInformation';
import { ChildrenEventMainInformation } from '../../views/GenericView/ParentView/Events/MainInformation/MainInformation';
import { getEventGeneratedName } from '../../helpers/accessor/accessor';
import { SchoolEvent } from '../../models/event';

interface Props {
  message: MessageDefinition;
  user: AppUser;
  schoolEvent?: SchoolEvent;
  eventName?: string;
}

export const MessageArchiveSummary: FunctionComponent<Props> = props => {
  const { message, user, schoolEvent, eventName } = props;
  const isExistSchoolEvent = typeof schoolEvent !== 'undefined';

  const DEFAULT_ROWS = [
    { title: 'Type', field: 'type' },
    { title: 'Created at', field: 'createdAt' },
    { title: 'Sender', field: 'sender' },
    { title: 'Action set', field: 'isActionPerformedSetAt' }
  ];

  const CLUB_PARTICIPATION_INVITE_MESSAGE_ROWS = [
    ...DEFAULT_ROWS,
    { title: 'Club name', field: 'clubName' },
    { title: 'Start date', field: 'clubEventStartDate' },
    { title: 'Finish date', field: 'clubEventFinishDate' },
    { title: 'Start time', field: 'clubEventStartTime' },
    { title: 'Venue', field: 'clubEventVenue' },
    { title: 'Student', field: 'studentFullName' },
    { title: 'Status', field: 'invitationStatus' },
    { title: 'Status changed', field: 'invitationStatusSetAt' }
  ];

  const EVENT_INVITATION_ROWS = [
    ...DEFAULT_ROWS,
    { title: 'Event name', field: 'eventName' },
    { title: 'Start', field: 'eventStart' },
    { title: 'Venue', field: 'eventVenue' },
    { title: 'Student', field: 'studentFullName' },
    { title: 'Status', field: 'invitationStatus' },
    { title: 'Status changed', field: 'invitationStatusSetAt' }
  ];

  const TOURNAMENT_CONFIRMATION_REQUEST_MESSAGE_ROWS = [
    ...DEFAULT_ROWS,
    { title: 'Tournament', field: 'tournamentName' },
    { title: 'Start', field: 'tournamentStart' },
    { title: 'End', field: 'tournamentEnd' },
    { title: 'Venue', field: 'tournamentVenue' },
    { title: 'Status', field: 'invitationStatus' },
    { title: 'Status changed', field: 'invitationStatusSetAt' }
  ];

  const ABSENCE_MESSAGE_ROWS = [
    ...DEFAULT_ROWS,
    { title: 'Student', field: 'studentFullName' },
    { title: 'Changed by', field: 'isAuthorizedSetByUserId' },
    { title: 'Status authorize', field: 'isAuthorized' }
  ];

  const EVENT_PARTICIPATION_MESSAGE_ROWS = [
    ...DEFAULT_ROWS,
    { title: 'Event name', field: 'eventName' },
    { title: 'Student', field: 'studentFullName' },
    { title: 'Take part', field: 'isTakePart' }
  ];

  const kind = message.kind;

  const isAbsenceMessage = kind === MESSAGE_KIND.AbsenceMessage;
  const isClubParticipantInviteMessage = kind === MESSAGE_KIND.ClubParticipantInviteMessage;
  const isTournamentConfirmationRequestMessage = kind === MESSAGE_KIND.TournamentConfirmationRequestMessage;
  const isEventInvitationMessage = kind === MESSAGE_KIND.EventInvitationMessage;
  const isEventParticipationMessage = kind === MESSAGE_KIND.EventParticipationMessage;

  let rowsForRender = [];

  switch (true) {
    case isAbsenceMessage:
      rowsForRender = ABSENCE_MESSAGE_ROWS;
      break;
    case isClubParticipantInviteMessage:
      rowsForRender = CLUB_PARTICIPATION_INVITE_MESSAGE_ROWS;
      break;
    case isTournamentConfirmationRequestMessage:
      rowsForRender = TOURNAMENT_CONFIRMATION_REQUEST_MESSAGE_ROWS;
      break;
    case isEventInvitationMessage:
      rowsForRender = EVENT_INVITATION_ROWS;
      break;
    case isEventParticipationMessage:
      rowsForRender = EVENT_PARTICIPATION_MESSAGE_ROWS;
      break;
    default:
      rowsForRender = DEFAULT_ROWS;
  }

  const rows = rowsForRender.map((row, index) => {
    let contentCell: string;
    const field = row.field;

    switch (field) {
      case 'type':
        contentCell = MESSAGE_KIND_SERVER_TO_CLIENT_MAPPING[message.kind];
        break;
      case 'createdAt':
        contentCell = message[row.field] ? Moment(message[row.field]).format('DD-MM-YYYY HH:mm') : '';
        break;
      case 'sender':
        contentCell = propz.get(message, ['sender', 'fullName'], '');
        break;
      case 'studentFullName':
        const firstName = propz.get(message, ['playerDetailsData', 'firstName'], '');
        const lastName = propz.get(message, ['playerDetailsData', 'lastName'], '');
        contentCell = `${firstName} ${lastName}`;
        break;
      case 'invitationStatusSetAt':
        contentCell = message[row.field] ? Moment(message[row.field]).format('DD-MM-YYYY HH:mm') : '';
        break;
      case 'isActionPerformedSetAt':
        contentCell = message[row.field] ? Moment(message[row.field]).format('DD-MM-YYYY HH:mm') : '';
        break;
      case 'tournamentName':
        contentCell = propz.get(message, ['tournamentData', 'name'], '');
        break;
      case 'tournamentStart':
        const tournamentStartTime = propz.get(message, ['tournamentData', 'startTime'], '');
        contentCell = Moment(tournamentStartTime).format('DD-MM-YYYY HH:mm');
        break;
      case 'tournamentEnd':
        const endTime = propz.get(message, ['tournamentData', 'endTime'], '');
        contentCell = Moment(endTime).format('DD-MM-YYYY HH:mm');
        break;
      case 'tournamentVenue':
        contentCell = propz.get(message, ['tournamentData', 'venue', 'venueType'], '');
        break;
      case 'eventName':
        contentCell = eventName;
        break;
      case 'eventStart':
        const eventStartTime = propz.get(message, ['eventData', 'startTime'], '');
        contentCell = Moment(eventStartTime).format('DD-MM-YYYY HH:mm');
        break;
      case 'eventVenue':
        contentCell = propz.get(message, ['eventData', 'venue', 'venueType'], '');
        break;
      case 'clubName':
        contentCell = propz.get(message, ['clubData', 'name'], '');
        break;
      case 'clubEventStartDate':
        const clubEventStartDate = propz.get(message, ['clubData', 'startDate'], '');
        contentCell = Moment(clubEventStartDate).format('DD-MM-YYYY');
        break;
      case 'clubEventFinishDate':
        const clubEventFinishDate = propz.get(message, ['clubData', 'finishDate'], '');
        contentCell = Moment(clubEventFinishDate).format('DD-MM-YYYY');
        break;
      case 'clubEventStartTime':
        const clubEventStartTime = propz.get(message, ['clubData', 'time'], '');
        contentCell = Moment(clubEventStartTime).format('HH:mm');
        break;
      case 'clubEventVenue':
        contentCell = propz.get(message, ['clubData', 'venue', 'placeName'], '');
        break;
      case 'isAuthorized':
        const isAuthorized = propz.get(message, ['isAuthorized']);
        switch (true) {
          case typeof isAuthorized === 'undefined':
            contentCell = 'Outdated';
            break;
          case isAuthorized === true:
            contentCell = 'Authorised';
            break;
          case isAuthorized === false:
            contentCell = 'Unauthorised';
            break;
        }
        break;
      case 'invitationStatus':
        const invitationStatus = (message as
          | ClubParticipantInviteMessage
          | TournamentConfirmationRequestMessage
          | EventInvitationMessage).invitationStatus;
        contentCell = MESSAGE_INVITATION_STATUS_SERVER_TO_CLIENT_MAPPING[invitationStatus];
        break;
      case 'isAuthorizedSetByUserId':
        const fullName = propz.get(message, ['authorizedSetBy', 'fullName']);
        if (typeof fullName !== 'undefined') {
          contentCell = fullName;
        } else {
          contentCell = 'System';
        }
        break;
      case 'isTakePart':
        const isTakePart = (message as EventParticipationMessage).isTakePart;
        switch (true) {
          case typeof isTakePart === 'undefined':
            contentCell = '';
            break;
          case isTakePart === true:
            contentCell = 'Yes';
            break;
          case isTakePart === false:
            contentCell = 'No';
            break;
        }
        break;
      default:
        contentCell = message[row.field];
    }

    return (
      <tr key={`message_summary_${index}`}>
        <td>{row.title}</td>
        <td>{contentCell}</td>
      </tr>
    );
  });

  return (
    <>
      {isExistSchoolEvent && <ChildrenEventMainInformation user={user} event={schoolEvent} eventName={eventName} />}
      {isClubParticipantInviteMessage && <ClubInviteMainInformation user={user} message={message} />}
      <table className={'table table-sm mPre'}>
        <tbody>{rows}</tbody>
      </table>
    </>
  );
};

MessageArchiveSummary.displayName = 'MessageArchiveSummary';
