import * as React from 'react';
import { Component } from 'react';
import { History } from 'history';
import { Link } from 'react-router-dom';
import * as propz from 'propz';
import * as Moment from 'moment';
import { AppUser } from 'Src/views/App/App';
import { login } from '../../../../helpers/auth/auth';
import { deleteUser } from 'Src/helpers/service/admin/user';
import { Loader } from 'Src/components/Loader/Loader';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { TerminatingForm } from './TerminatingForm/TerminatingForm';
import './SubscriptionsTab.scss';

interface Props {
  user: AppUser;
  setAppDefaultState: () => void;
  history: History;
}

interface State {
  isLoading: boolean;
  isTerminatingModalOpen: boolean;
  isConfirmationModalOpen: boolean;
  isSuccessModalOpen: boolean;
  email: string;
  password: string;
  isAgree: boolean;
  isError: boolean;
}

export class SubscriptionsTab extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isTerminatingModalOpen: false,
      isConfirmationModalOpen: false,
      isSuccessModalOpen: false,
      email: '',
      password: '',
      isAgree: false,
      isError: false
    };
  }

  renderTerminatingModal = () => {
    const { isTerminatingModalOpen, email, password, isError, isAgree } = this.state;
    return (
      <SimpleModal
        isOpen={isTerminatingModalOpen}
        title={'Terminate subscription'}
        onCloseIconClick={this.onCloseTerminatingModalClick}
        customClass="mMaxWidth550"
      >
        <TerminatingForm
          onSubmit={this.onSubmitTerminatingModal}
          onCloseClick={this.onCloseTerminatingModalClick}
          email={email}
          password={password}
          onEmailChange={this.onEmailChange}
          onPasswordChange={this.onPasswordChange}
          isAgree={isAgree}
          onAgreementChange={this.onAgreementChange}
          isError={isError}
        />
      </SimpleModal>
    );
  };

  onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({
      email: value
    });
  };

  onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({
      password: value
    });
  };

  onAgreementChange = () => {
    this.setState({
      isAgree: !this.state.isAgree
    });
  };

  onSubmitTerminatingModal = () => {
    const { email, password } = this.state;
    this.setState({
      isLoading: true
    });
    login(email, password)
      .then(() => {
        this.setState({
          isTerminatingModalOpen: false,
          isConfirmationModalOpen: true,
          email: '',
          password: '',
          isAgree: false,
          isError: false,
          isLoading: false
        });
      })
      .catch(error => {
        this.setState({
          isError: true,
          isLoading: false
        });
      });
  };

  onCloseTerminatingModalClick = () => {
    this.setState({
      isTerminatingModalOpen: false
    });
  };

  renderConfirmationModal = () => {
    const { isConfirmationModalOpen } = this.state;
    return (
      <SimpleModal
        isOpen={isConfirmationModalOpen}
        title={'Confirmation'}
        buttonText={'Terminate Subscription'}
        buttonCancelText={'Cancel termination process'}
        onButtonClick={this.onSubmitConfirmationModal}
        onCloseClick={this.onCloseConfirmationModalClick}
        onCloseIconClick={this.onCloseConfirmationModalClick}
      >
        <>
          <div>Are you sure you wish to terminate the subscription for Squad In Touch?</div>
          <div>
            By cancelling this subscription, your configured access to all data with Squad In Touch will not work
            anymore!
          </div>
          <div>All your personal data will be permanently removed from the Squad In Touch database.</div>
        </>
      </SimpleModal>
    );
  };

  onSubmitConfirmationModal = () => {
    const { user } = this.props;
    this.setState({
      isLoading: true
    });

    deleteUser(user).then(() => {
      this.setState({
        isConfirmationModalOpen: false,
        isSuccessModalOpen: true,
        isLoading: false
      });
    });
  };

  onCloseConfirmationModalClick = () => {
    this.setState({
      isConfirmationModalOpen: false
    });
  };

  renderSuccessModal = () => {
    const { isSuccessModalOpen } = this.state;
    return (
      <SimpleModal
        title={'Success'}
        isOpen={isSuccessModalOpen}
        buttonText={'Ok'}
        body={'Your Squad In Touch Account and its data have been deleted.'}
        onButtonClick={this.onCloseSuccessModalClick}
        onCloseIconClick={this.onCloseSuccessModalClick}
      />
    );
  };

  onCloseSuccessModalClick = () => {
    this.setState({
      isSuccessModalOpen: false
    });
    this.props.setAppDefaultState();
    this.props.history.push('/login');
  };

  onCancelSubscriptionClick = () => {
    this.setState({
      isTerminatingModalOpen: true
    });
  };

  render() {
    const { isLoading } = this.state;
    const { user } = this.props;
    const createdAt = propz.get(user, ['profile', 'createdAt']);
    const createdAtConverted = Moment(createdAt).format('DD-MM-YYYY HH:mm');

    if (isLoading) {
      return <Loader />;
    }

    return (
      <div>
        {this.renderTerminatingModal()}
        {this.renderConfirmationModal()}
        {this.renderSuccessModal()}
        <div className="row ml-2">
          <div className="col-xl-6">
            <div className="mt-3 ml-2">
              <div>
                {' '}
                From this section you can view your accounts current active subscription, you can download them here in
                a PDF format by clicking on the PDF icon.
              </div>
              <div>
                You can also terminate your Squad in Touch subscription by clicking the ‘Cancel’ button if you wish.
              </div>
            </div>
            <div className={'mt-3 mb-3'}>
              <table className={'table table-sm'}>
                <thead>
                  <tr>
                    <th>{'Subscription'}</th>
                    <th>{'Subscribed since'}</th>
                    <th>{'Fee'}</th>
                    <th>{'T&Cs'}</th>
                    <th>{'Actions'}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{'Squad In Touch registered user subscription'}</td>
                    <td>{createdAtConverted}</td>
                    <td>{'Free of charge'}</td>
                    <td>
                      <Link className="" to="/dist/images/docs/terms_of_use.pdf" target="_blank">
                        <img src="/dist/images/icons/adobe_icon.png" className="eSubscriptionTabDocIcon" />
                      </Link>
                    </td>
                    <td onClick={this.onCancelSubscriptionClick} className="eSubscriptionTabLink">
                      {'Cancel'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
