import * as React from 'react';
import * as propz from 'propz';
import { Component } from 'react';
import { Field, Form, Formik } from 'formik';
import { Button } from 'Src/components/Button/Button';
import { AppUser } from 'Src/views/App/App';
import { PUBLIC_SITE_STATUS_OPTIONS, PUBLIC_SITE_STATUS_OPTIONS_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/tournament';
import * as Yup from 'yup';
import { YES_NO_OPTIONS } from 'Src/consts/common';
import { getBooleanForYesOrNo, getYesOrNoForBoolean } from 'Src/helpers/tournament/tournament';

interface Props {
  user: AppUser;
  championship: any;
  onCancel: () => void;
  onSubmit: (data: any) => void;
}

interface State {}

const SettingSchema = Yup.object().shape({
  domain: Yup.string()
    .notRequired()
    .matches(/^[a-z0-9-_]*$/, 'Should contain only lowercase characters and digits')
    .matches(/^.{0,61}$/, 'Domain length must be less than 61 characters')
});

export class ChampionshipPublicSiteSettingsForm extends Component<Props, State> {
  constructor(props) {
    super(props);
  }

  onSubmit(values) {
    const dataCopy = {
      isPublishOnTheWebsite: getBooleanForYesOrNo(values.isPublishOnTheWebsite),
      isPromoteTournament: getBooleanForYesOrNo(values.isPublishOnTheWebsite)
        ? getBooleanForYesOrNo(values.isPromoteTournament)
        : false,
      publicSite: {
        status: values.publicSiteStatus,
        password: values.publicSitePassword
      },
      allowedForFollowing: getBooleanForYesOrNo(values.allowedForFollowing),
      domain: values.domain,
      isSchoolsListOnTop: getBooleanForYesOrNo(values.isSchoolsListOnTop),
      link: values.link,
      isPhotosAvailable: getBooleanForYesOrNo(values.isPhotosAvailable),
      isPhotosPromotion: getBooleanForYesOrNo(values.isPhotosAvailable)
        ? getBooleanForYesOrNo(values.isPhotosPromotion)
        : false,
      disclaimer: values.disclaimer,
      isCsvResultAvailable: getBooleanForYesOrNo(values.isCsvResultAvailable),
      publicBigscreenSite: {
        status: values.publicBigscreenSiteStatus,
        password: values.publicBigscreenSitePassword,
        autoScrollingDelay: values.publicBigscreenSiteAutoScrollingDelay
      }
    };

    if (
      values.publicSiteStatus !== PUBLIC_SITE_STATUS_OPTIONS_SERVER_TO_CLIENT_MAPPING.PROTECTED ||
      values.publicSitePassword === ''
    ) {
      delete dataCopy.publicSite.password;
    }
    if (
      values.publicBigscreenSiteStatus !== PUBLIC_SITE_STATUS_OPTIONS_SERVER_TO_CLIENT_MAPPING.PROTECTED ||
      values.publicBigscreenSitePassword === ''
    ) {
      delete dataCopy.publicBigscreenSite.password;
    }

    this.props.onSubmit(dataCopy);
  }

  render() {
    const { championship } = this.props;

    const settingsForm = {
      isPublishOnTheWebsite: getYesOrNoForBoolean(propz.get(championship, ['isPublishOnTheWebsite'], false)),
      isPromoteTournament: getYesOrNoForBoolean(propz.get(championship, ['isPromoteTournament'], false)),
      publicSiteStatus: propz.get(
        championship,
        ['publicSite', 'status'],
        PUBLIC_SITE_STATUS_OPTIONS_SERVER_TO_CLIENT_MAPPING.DISABLED
      ),
      publicSitePassword: propz.get(championship, ['publicSite', 'password'], ''),
      allowedForFollowing: getYesOrNoForBoolean(propz.get(championship, ['allowedForFollowing'], false)),
      domain: propz.get(championship, ['domain'], ''),
      isSchoolsListOnTop: getYesOrNoForBoolean(propz.get(championship, ['isSchoolsListOnTop'], false)),
      link: propz.get(championship, ['link'], ''),
      isPhotosAvailable: getYesOrNoForBoolean(propz.get(championship, ['isPhotosAvailable'], false)),
      isPhotosPromotion: getYesOrNoForBoolean(propz.get(championship, ['isPhotosPromotion'], false)),
      disclaimer: propz.get(championship, ['disclaimer'], ''),
      isCsvResultAvailable: getYesOrNoForBoolean(propz.get(championship, ['isCsvResultAvailable'], false)),
      publicBigscreenSiteStatus: propz.get(
        championship,
        ['publicBigscreenSite', 'status'],
        PUBLIC_SITE_STATUS_OPTIONS_SERVER_TO_CLIENT_MAPPING.DISABLED
      ),
      publicBigscreenSitePassword: propz.get(championship, ['publicBigscreenSite', 'password'], ''),
      publicBigscreenSiteAutoScrollingDelay: propz.get(championship, ['publicBigscreenSite', 'autoScrollingDelay'], 6)
    };

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <Formik
              initialValues={settingsForm}
              validationSchema={SettingSchema}
              onSubmit={values => {
                this.onSubmit(values);
              }}
              render={({ touched, errors, values }) => (
                <Form>
                  <label>Publish championship on school union's website</label>
                  <Field component="select" name="isPublishOnTheWebsite" className="form-control mb-3">
                    <option value={YES_NO_OPTIONS.NO}>{YES_NO_OPTIONS.NO}</option>
                    <option value={YES_NO_OPTIONS.YES}>{YES_NO_OPTIONS.YES}</option>
                  </Field>

                  {values.isPublishOnTheWebsite === YES_NO_OPTIONS.YES && (
                    <>
                      <label>Highlight championship</label>
                      <Field component="select" name="isPromoteTournament" className="form-control mb-3">
                        <option value={YES_NO_OPTIONS.NO}>{YES_NO_OPTIONS.NO}</option>
                        <option value={YES_NO_OPTIONS.YES}>{YES_NO_OPTIONS.YES}</option>
                      </Field>
                    </>
                  )}

                  <label>championship's public website access</label>
                  <Field component="select" name="publicSiteStatus" className="form-control mb-3">
                    <option value={PUBLIC_SITE_STATUS_OPTIONS_SERVER_TO_CLIENT_MAPPING.DISABLED}>
                      {PUBLIC_SITE_STATUS_OPTIONS.DISABLED}
                    </option>
                    <option value={PUBLIC_SITE_STATUS_OPTIONS_SERVER_TO_CLIENT_MAPPING.PUBLIC_AVAILABLE}>
                      {PUBLIC_SITE_STATUS_OPTIONS.PUBLIC_AVAILABLE}
                    </option>
                    <option value={PUBLIC_SITE_STATUS_OPTIONS_SERVER_TO_CLIENT_MAPPING.PROTECTED}>
                      {PUBLIC_SITE_STATUS_OPTIONS.PROTECTED}
                    </option>
                  </Field>

                  {values.publicSiteStatus === PUBLIC_SITE_STATUS_OPTIONS_SERVER_TO_CLIENT_MAPPING.PROTECTED && (
                    <div className="form-group">
                      <label>Championship's public website access password</label>
                      <Field
                        type="text"
                        name="publicSitePassword"
                        className={`${
                          touched.publicSitePassword && errors.publicSitePassword
                            ? 'form-control is-invalid'
                            : 'form-control '
                        }`}
                      />
                      {touched.publicSitePassword && errors.publicSitePassword ? (
                        <div className="invalid-feedback">{errors.publicSitePassword}</div>
                      ) : null}
                    </div>
                  )}
                  <label>Allowed for following on the mobile app</label>
                  <Field component="select" name="allowedForFollowing" className="form-control mb-3">
                    <option value={YES_NO_OPTIONS.NO}>{YES_NO_OPTIONS.NO}</option>
                    <option value={YES_NO_OPTIONS.YES}>{YES_NO_OPTIONS.YES}</option>
                  </Field>

                  <label>Championship's public website domain name</label>
                  <Field
                    type="text"
                    name="domain"
                    className={`${touched.domain && errors.domain ? 'form-control is-invalid' : 'form-control '}`}
                  />
                  {touched.domain && errors.domain ? <div className="invalid-feedback">{errors.domain}</div> : null}

                  <label>Highlight schools list on top</label>
                  <Field component="select" name="isSchoolsListOnTop" className="form-control mb-3">
                    <option value={YES_NO_OPTIONS.NO}>{YES_NO_OPTIONS.NO}</option>
                    <option value={YES_NO_OPTIONS.YES}>{YES_NO_OPTIONS.YES}</option>
                  </Field>

                  <label>Championship's public website external link</label>
                  <Field type="text" name="link" className="form-control" />

                  <label>Photos available on championship's website</label>
                  <Field component="select" name="isPhotosAvailable" className="form-control mb-3">
                    <option value={YES_NO_OPTIONS.NO}>{YES_NO_OPTIONS.NO}</option>
                    <option value={YES_NO_OPTIONS.YES}>{YES_NO_OPTIONS.YES}</option>
                  </Field>

                  {values.isPhotosAvailable === YES_NO_OPTIONS.YES && (
                    <>
                      <label>Photos promotion on championship's website</label>
                      <Field component="select" name="isPhotosPromotion" className="form-control mb-3">
                        <option value={YES_NO_OPTIONS.NO}>{YES_NO_OPTIONS.NO}</option>
                        <option value={YES_NO_OPTIONS.YES}>{YES_NO_OPTIONS.YES}</option>
                      </Field>
                    </>
                  )}

                  <label>Text of disclaimer on championship's website</label>
                  <Field component="textarea" name="disclaimer" className="form-control mb-3" />

                  <label>Csv result availability</label>
                  <Field component="select" name="isCsvResultAvailable" className="form-control mb-3">
                    <option value={YES_NO_OPTIONS.NO}>{YES_NO_OPTIONS.NO}</option>
                    <option value={YES_NO_OPTIONS.YES}>{YES_NO_OPTIONS.YES}</option>
                  </Field>

                  <label>Public Bigscreen Site Access</label>
                  <Field component="select" name="publicBigscreenSiteStatus" className="form-control mb-3">
                    <option value={PUBLIC_SITE_STATUS_OPTIONS_SERVER_TO_CLIENT_MAPPING.DISABLED}>
                      {PUBLIC_SITE_STATUS_OPTIONS.DISABLED}
                    </option>
                    <option value={PUBLIC_SITE_STATUS_OPTIONS_SERVER_TO_CLIENT_MAPPING.PUBLIC_AVAILABLE}>
                      {PUBLIC_SITE_STATUS_OPTIONS.PUBLIC_AVAILABLE}
                    </option>
                    <option value={PUBLIC_SITE_STATUS_OPTIONS_SERVER_TO_CLIENT_MAPPING.PROTECTED}>
                      {PUBLIC_SITE_STATUS_OPTIONS.PROTECTED}
                    </option>
                  </Field>

                  {values.publicBigscreenSiteStatus ===
                    PUBLIC_SITE_STATUS_OPTIONS_SERVER_TO_CLIENT_MAPPING.PROTECTED && (
                    <>
                      <label>Championship's public bigscreen website access</label>
                      <Field type="text" name="publicBigscreenSitePassword" className="form-control" />
                    </>
                  )}

                  <div className="form-group">
                    <label>Scrolling delay (in seconds)</label>
                    <Field
                      type="number"
                      min="0"
                      name="publicBigscreenSiteAutoScrollingDelay"
                      className={`${
                        touched.publicBigscreenSiteAutoScrollingDelay && errors.publicBigscreenSiteAutoScrollingDelay
                          ? 'form-control is-invalid'
                          : 'form-control '
                      }`}
                    />
                    {touched.publicBigscreenSiteAutoScrollingDelay && errors.publicBigscreenSiteAutoScrollingDelay ? (
                      <div className="invalid-feedback">{errors.publicBigscreenSiteAutoScrollingDelay}</div>
                    ) : null}
                  </div>
                  <Button onClick={this.props.onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                  <button type="submit" className="mt-3 mb-3 btn btn-primary">
                    Save
                  </button>
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}
