import * as React from 'react';

const phoneCodes = [
  {
    code: '+44',
    flagImgSrc: 'uk_flag.png',
    alt: 'UK flag'
  },
  {
    code: '+1',
    flagImgSrc: 'usa_flag.png',
    alt: 'USA flag'
  },
  {
    code: '+49',
    flagImgSrc: 'german_flag.png',
    alt: 'German flag'
  },
  {
    code: '+971',
    flagImgSrc: 'uae_flag.png',
    alt: 'UAE flag'
  }
  /*  {
    code: '+7',
    flagImgSrc: 'russian_flag.png',
    alt: 'Russian flag'
  }
*/
];

export function PhoneCodesList(props) {
  const { onClick } = props;

  return (
    <div className="bProfileSummaryPhoneCodeSelect">
      {phoneCodes.map((option, index) => {
        return (
          <div
            className="eProfileSummaryPhoneCodeOption"
            key={`phone_code_${index}`}
            onClick={() => onClick(option.code)}
          >
            <img
              src={`/dist/images/flags/${option.flagImgSrc}`}
              alt={option.alt}
              className="eProfileSummaryPhoneSelectFlag"
            />
            <span className="eProfileSummaryPhoneSelectCode">{option.code}</span>
          </div>
        );
      })}
    </div>
  );
}
