import * as React from 'react';
import { useState } from 'react';
import * as propz from 'propz';
import * as Lazy from 'lazy.js';
import { AppUser } from 'Src/views/App/App';
import { SchoolEvent } from 'Src/models/event';
import { SchoolEventIndividualData } from 'Src/models/event';
import { CELL_ACTION_ICON } from 'Src/consts/cell';
import { getFullName } from 'Src/helpers/autocomplete/autocomplete';
import { DeleteIcon } from 'Src/components/SvgIcons/DeleteIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './EventParticipants.scss';

interface Props {
  user: AppUser;
  event: SchoolEvent;
  onClick: (
    data: {
      currentEvent: SchoolEvent;
      currentParticipant: SchoolEventIndividualData;
    },
    cell: string
  ) => void;
}

export function EventParticipants(props: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const { event, onClick, user } = props;
  const { individualsData, teamsData, players, embeddedTeams = [] } = event;
  const schoolIds = propz.get(user, ['activeSchool', 'schoolIds'], []);

  const isRelay = propz.get(event, ['sport', 'isRelay'], false);
  let participantsFromCurrentSchool = [];

  if (isRelay) {
    const activeSchoolTeam = embeddedTeams.find(team => team.schoolId === user.activeSchoolId);
    const isActiveSchoolTeamExist = typeof activeSchoolTeam !== 'undefined';

    if (isActiveSchoolTeamExist) {
      participantsFromCurrentSchool = Lazy(teamsData)
        .filter(team => team.id === activeSchoolTeam._id)
        .map(team => team.players)
        .flatten()
        .toArray();
    }
  } else {
    participantsFromCurrentSchool = individualsData.filter(item => {
      return schoolIds.includes(item.schoolId);
    });
  }

  const isParticipantsExist = participantsFromCurrentSchool.length > 0;

  if (isParticipantsExist) {
    const participantsData = isOpen ? participantsFromCurrentSchool : participantsFromCurrentSchool.slice(0, 3);

    return (
      <td className="mPreWrap">
        {participantsData.map(participant => {
          const participantName = getFullName(participant);
          const { id } = participant;

          const data = {
            currentEvent: event,
            currentParticipant: participant
          };

          return (
            <div key={id}>
              <div className="d-flex align-items-center justify-content-between px-2 py-1 mb-1 rounded bg-light">
                <span className="mr-2">{participantName}</span>
                <DeleteIcon onClick={() => onClick(data, CELL_ACTION_ICON.REMOVE_FROM_EVENT)} />
              </div>
            </div>
          );
        })}

        {participantsFromCurrentSchool.length > 3 && (
          <button className="bEventParticipantsShowMoreButton" onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? 'Hide' : 'Show All'}
            <FontAwesomeIcon
              color="#828282"
              className={isOpen ? 'eEventParticipantsShowMoreButtonIcon' : 'mEventParticipantsShowMoreButtonIconActive'}
              icon="arrow-right"
            />
          </button>
        )}
      </td>
    );
  }

  return <td className="mPreWrap"></td>;
}
