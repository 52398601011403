import * as React from 'react';
import { FunctionComponent } from 'react';
import MaskedInput from 'react-text-mask';
import * as classNames from 'classnames';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { COUNT_INTERVAL } from '../../consts/table';

interface Props {
  onTableFilterChange: (event, value: string, options?) => void;
  field: string;
  from: string;
  to: string;
  customClass?: string;
  onEnterPress?: (event) => void;
}

export const CountIntervalField: FunctionComponent<Props> = props => {
  const { onEnterPress, customClass } = props;
  const classes = classNames('pl-2', 'mMinWidth185', 'mMaxWidth250', customClass);

  const mask = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2,
    integerLimit: 7,
    allowNegative: false,
    allowLeadingZeroes: false,
    includeThousandsSeparator: false
  });

  return (
    <div className={classes}>
      <div className="input-group mMarginBottom4">
        <div className="input-group-prepend">
          <span className="input-group-text mMinWidth67">From:</span>
        </div>
        <MaskedInput
          type="text"
          className="form-control mMaxWidth80"
          onChange={event => {
            props.onTableFilterChange(event, props.field, COUNT_INTERVAL.FROM);
          }}
          mask={mask}
          value={props.from}
          onKeyDown={onEnterPress}
        />
      </div>

      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text mMinWidth67">To:</span>
        </div>
        <MaskedInput
          type="text"
          className="form-control mMaxWidth80"
          onChange={event => {
            props.onTableFilterChange(event, props.field, COUNT_INTERVAL.TO);
          }}
          mask={mask}
          value={props.to}
          onKeyDown={onEnterPress}
        />
      </div>
    </div>
  );
};

CountIntervalField.displayName = 'CountIntervalField';
