import * as React from 'react';
import { FunctionComponent } from 'react';
import * as propz from 'propz';
import { Tournament } from '../../models/tournament';

interface Props {
  tournament: Tournament;
}

export const TournamentParticipantsSummary: FunctionComponent<Props> = props => {
  const tournament = props.tournament;
  const schools = tournament.schools || [];

  const rows = schools.map((school, index) => {
    return (
      <tr key={`tournament_participants_summary_${index}`}>
        <td>{index + 1}</td>
        <td>{propz.get(school, ['name'], '')}</td>
      </tr>
    );
  });

  return (
    <table className={'table table-sm mPre'}>
      <thead>
        <tr key={`tournament_participants_summary_heading`}>
          <th />
          <th>Name</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
};

TournamentParticipantsSummary.displayName = 'TournamentParticipantsSummary';
