import * as React from 'react';
import * as propz from 'propz';
import * as Moment from 'moment';
import { EventInvitationMessage } from 'Src/models/message';
import { SchoolEvent } from 'Src/models/event';

interface Props {
  item: EventInvitationMessage;
  event: SchoolEvent;
  activeSchoolId: string;
}

export const AvailabilityReportsDetails = (props: Props) => {
  const { item, event, activeSchoolId } = props;
  const details = propz.get(item, ['details'], '');
  const isDetailsExist = details !== '';
  const detailsText = isDetailsExist ? details : 'Details are not specified';
  const generatedNames = propz.get(event, ['generatedNames']);
  const isGeneratedNamesExist = typeof generatedNames !== 'undefined' || generatedNames !== '';
  let eventName;

  if (isGeneratedNamesExist) {
    const schoolIdsFromGeneratedNames = Object.keys(generatedNames);
    const isActiveSchoolIdExistInGeneratedNames = schoolIdsFromGeneratedNames.includes(activeSchoolId);

    eventName = isActiveSchoolIdExistInGeneratedNames ? generatedNames[activeSchoolId] : generatedNames.official;
  } else {
    eventName = 'Event name not assigned';
  }

  const startTime = propz.get(event, ['startTime'], '');
  const endTime = propz.get(event, ['endTime'], '');
  const startTimeFormatted = Moment(startTime).format('DD-MM-YYYY HH:mm');
  const endTimeFormatted = Moment(endTime).format('DD-MM-YYYY HH:mm');

  return (
    <div>
      <div className="d-flex justify-content-between mb-3">
        <p className="font-weight-bold col-6 pl-0">Description:</p>
        <p className="col-6 pl-0">{detailsText}</p>
      </div>

      <div className="d-flex justify-content-between mb-3">
        <p className="font-weight-bold col-6 pl-0">Event name:</p>
        <p className="col-6 pl-0">{eventName}</p>
      </div>

      <div className="d-flex justify-content-between mb-3">
        <p className="font-weight-bold col-6 pl-0">Start time:</p>
        <p className="col-6 pl-0">{startTimeFormatted}</p>
      </div>

      <div className="d-flex justify-content-between mb-3">
        <p className="font-weight-bold col-6 pl-0">End time:</p>
        <p className="col-6 pl-0">{endTimeFormatted}</p>
      </div>
    </div>
  );
};

AvailabilityReportsDetails.displayName = 'AvailabilityReportsDetails';
