import * as React from 'react';
import * as classNames from 'classnames';
import './MultiSelectCheckboxField.css';
interface Props {
  items: {
    name: string;
    isChecked: boolean;
  }[];
  onChange: (index: number) => void;
  customClass?: string;
  onEnterPress?: (event) => void;
}

export function MultiSelectCheckboxField(props: Props) {
  const { items, onChange, customClass, onEnterPress } = props;

  if (Array.isArray(items) && items.length === 0) {
    return null;
  }

  const classes = classNames(
    'form-check',
    'w-eMultiSelectCheckboxField',
    'mOverflowYAuto',
    'mMaxWidth250',
    customClass
  );

  return (
    <div className={classes}>
      {items.map((item, index) => {
        return (
          <div className={'pl-3'} key={`multiSelectCheckbox_filter_${index}`}>
            <input
              onChange={() => onChange(index)}
              className="form-check-input"
              type="checkbox"
              checked={item.isChecked}
              id={item.name}
              onKeyDown={onEnterPress}
            />
            <label className="form-check-label pl-2 pt-1" htmlFor={item.name}>
              {item.name}
            </label>
          </div>
        );
      })}
    </div>
  );
}
