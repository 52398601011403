import axios from 'axios';
import { AppUser } from 'Src/views/App/App';
import { DEFAULT_LIMIT, DEFAULT_SKIP, SORT } from 'Src/consts/table';
import { DEFAULT_FILTER } from './user';
import { Count } from '../../../models/count';

const DEFAULT_CLUB_PARTICIPANT_FILTER: any = {
  limit: 10,
  skip: DEFAULT_SKIP,
  order: `lastName ${SORT.ASC}`
};
export function getClubParticipants(user: AppUser, clubId: string, filter) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...filter.where
        },
        skip: filter.skip || DEFAULT_CLUB_PARTICIPANT_FILTER.skip,
        limit: filter.limit || DEFAULT_CLUB_PARTICIPANT_FILTER.limit,
        order: filter.order || DEFAULT_CLUB_PARTICIPANT_FILTER.order
      }
    }
  };

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/clubs/${clubId}/participants`, config)
    .then(response => {
      return response.data;
    });
}

export function getClubParticipantsCount(user: AppUser, clubId: string, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where
        }
      }
    };
  } else {
    config = {
      headers: { usid: key }
    };
  }

  return axios
    .get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/clubs/${clubId}/participants/count`, config)
    .then(response => {
      return response.data;
    });
}

export function getAllClubParticipants(user: AppUser, clubId: string, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP,
          order: DEFAULT_CLUB_PARTICIPANT_FILTER.order
        }
      }
    };
  }

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/clubs/${clubId}/participants`, config)
    .then(response => {
      return response.data;
    });
}

export function getClubEvents(user: AppUser, clubId: string, filter = DEFAULT_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        limit: DEFAULT_LIMIT,
        skip: DEFAULT_SKIP,
        order: 'startTime ASC'
      }
    }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/clubs/${clubId}/events`, config).then(response => {
    return response.data;
  });
}

export function getClubPayments(user: AppUser, clubId: string, filter = DEFAULT_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter
    }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/clubs/${clubId}/payments`, config).then(response => {
    return response.data;
  });
}
