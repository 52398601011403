import * as React from 'react';
import * as propz from 'propz';
import {AppUser} from 'src/views/App/App';
import {Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SchoolEvent} from "../../../../../../models/event";
import {getProtocol, getSubDomain} from "../../../../../../helpers/link/link";
import {TWEET_LENGTH} from "../../../../../../consts/common";
import {getSchoolEventAges} from "../../../../../../helpers/event/event";
import {getEventGeneratedName, getEventStartTime} from "../../../../../../helpers/accessor/accessor";
import {Button} from "../../../../../../components/Button/Button";


interface Props {
    user: AppUser;
    event: SchoolEvent;
    onCancel: () => void;
    onSubmit: (data: any) => void;
}


export function SchoolUnionEventTwitterModal(props: Props) {

    //If a user has more than one twitter account, we give him the choice of which account to make a tweet
    const getTwitterAccountOptions = () => {
        const {user} = props;
        const twitterIntegrations = propz.get(user, ['activeSchool', 'integrations', 'twitter'], []);
        return twitterIntegrations.map(twitAccount => (
            <option key={twitAccount._id} value={twitAccount._id}>
                {twitAccount.name}
            </option>
        ));
    };

    const getNumberLinksInTweet = (textForTweet) => {
        const arrayOfMatches = textForTweet !== '' ? textForTweet.match(/https?:\/\//g) : [];

        return typeof arrayOfMatches !== 'undefined' && arrayOfMatches !== null ? arrayOfMatches.length : 0;
    };

    const getTextWithoutLinkLength = (textForTweet, numberLinksInTweet) => {
        for (let i = 0; i < numberLinksInTweet; i++) {
            let startPos, endPos, startStr, endStr;

            if (i === numberLinksInTweet - 1) {
                startPos = textForTweet.match(/https?:\/\//).index;
                endPos = textForTweet.indexOf(' ', startPos);

                if (endPos === -1) {
                    textForTweet = textForTweet.substring(0, startPos);
                } else {
                    startStr = textForTweet.substring(0, startPos - 1);
                    endStr = textForTweet.substring(endPos + 1);
                    textForTweet = startStr + endStr;
                }
            } else {
                startPos = textForTweet.match(/https?:\/\//).index;
                endPos = textForTweet.indexOf(' ', startPos);
                startStr = textForTweet.substring(0, startPos - 1);
                endStr = textForTweet.substring(endPos + 1);
                textForTweet = startStr + endStr;
            }
        }

        return textForTweet.length;
    };

    const getTextForTweetLength = (text) => {
        const numberLinksInTweet = getNumberLinksInTweet(text);
        return numberLinksInTweet > 0 ? getTextWithoutLinkLength(text, numberLinksInTweet) : text.length;
    };


    const {event, user} = props;
    const eventOfficialName = getEventGeneratedName(event, {user: user});
    const isPublicAvailableDomain = propz.get(user, ['activeSchool', 'publicSite', 'status']);
    const protocol = getProtocol();
    const isExistPublicAvailableDomain = typeof isPublicAvailableDomain !== 'undefined' && isPublicAvailableDomain === 'PUBLIC_AVAILABLE';
    const schoolDomain = isExistPublicAvailableDomain ? propz.get(user, ['activeSchool', 'domain'], '') : '';
    const eventId = propz.get(event, ['id'], '');
    const schoolDomainFull = getSubDomain(schoolDomain);
    const linkForTweet = schoolDomain !== '' ? protocol + schoolDomainFull + '/#event/' + eventId : '';
    const twitterData = propz.get(user, ['activeSchool', 'integrations', 'twitter'], []);
    const isExistTwitterData = twitterData.length > 0;
    const eventStartFormatted = getEventStartTime(event);
    const eventAges = getSchoolEventAges(event, user);
    const textForTweet = `${eventOfficialName} ${eventStartFormatted} Years: ${eventAges}`;

    const tweetSchema = Yup.object().shape({
        text: Yup.string().test('text', 'Must be no more than 140 characters', text => {
            const without = getTextForTweetLength(text)
            return without <= TWEET_LENGTH
        })
    });

    const tweetInitial = {
        text: `${textForTweet} ${linkForTweet}`,
        twitterId: isExistTwitterData ? twitterData[0]._id : ''
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <Formik
                        initialValues={tweetInitial}
                        validationSchema={tweetSchema}
                        onSubmit={values => {
                            props.onSubmit(values);
                        }}
                        render={({touched, errors, values}) => (
                            <Form>
                                <div className="form-group">
                                    <Field component="select" name="twitterId" className="form-control mb-3">
                                        {getTwitterAccountOptions()}
                                    </Field>
                                    {touched.twitterId && errors.twitterId ?
                                        <div className="alert alert-danger">{errors.twitterId}</div> : null}
                                </div>
                                <div className="form-group">
                                    <Field
                                        component="textarea"
                                        name="text"
                                        rows="4"
                                        className={`${touched.text && errors.text ? 'form-control is-invalid' : 'form-control '}`}
                                    />
                                    {touched.text && errors.text ?
                                        <div className="invalid-feedback">{errors.text}</div> : null}
                                </div>

                                <div className="form-group">
                                    Number of characters: {getTextForTweetLength(values.text)}
                                </div>

                                <Button onClick={props.onCancel} text={'Cancel'}
                                        customClass={'mt-3 mb-3 mr-3 btn-secondary'}/>
                                <button type="submit" className="mt-3 mb-3 btn btn-primary">
                                    {[<FontAwesomeIcon key={'event_new_tweet_button'}
                                                       icon={['fab', 'twitter']}/>, ' ', 'Tweet']}
                                </button>
                            </Form>
                        )}
                    />
                </div>
            </div>
        </div>
    );
}
