import * as React from 'react';
import { FunctionComponent } from 'react';
import * as propz from 'propz';
import * as Moment from 'moment';
import {
  TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING,
  TOURNAMENT_TYPE_SERVER_TO_CLIENT_MAPPING
} from '../../consts/tournament';
import { DATE_TIME_FORMAT } from '../../consts/date';
import { Tournament } from '../../models/tournament';
import { Championship } from '../../models/championship';

interface Props {
  tournament: Tournament;
  championships: Championship[];
}

const ROWS = [
  { title: 'Name', field: 'name' },
  { title: 'Description', field: 'description' },
  { title: 'Type', field: 'type' },
  { title: 'Subtype', field: 'subType' },
  { title: 'Participation in the championship', field: 'inChampionship' },
  { title: 'Start', field: 'startTime' },
  { title: 'End', field: 'endTime' },
  { title: 'Entries open', field: 'entriesOpen' },
  { title: 'Deadline for entries', field: 'deadlineForEntries' },
  { title: 'Organisers', field: 'organisers' },
  { title: 'Organisers email', field: 'organisersEmail' },
  { title: 'Domain', field: 'domain' },
  { title: 'Sport', field: 'sportName' },
  { title: 'Maximum number of players per team', field: 'maxPlayersPerTeam' }
];

export const TournamentSummary: FunctionComponent<Props> = props => {
  const rows = ROWS.map((row, index) => {
    let contentCell: any;
    const field = row.field;

    const tournament = props.tournament;

    switch (field) {
      case 'description':
        const description = propz.get(tournament, ['description'], '');
        contentCell = <div dangerouslySetInnerHTML={{ __html: description }} />;
        break;

      case 'type':
        contentCell = TOURNAMENT_TYPE_SERVER_TO_CLIENT_MAPPING[tournament.type];
        break;

      case 'subType':
        contentCell = TOURNAMENT_SUBTYPE_SERVER_TO_CLIENT_MAPPING[tournament.subType];
        break;

      case 'inChampionship':
        const onlyParticipatesChampionships = props.championships.filter(championship =>
          championship.tournamentIds.some(tournamentId => tournamentId === tournament.id)
        );
        contentCell = propz.get(onlyParticipatesChampionships[0], ['name'], 'no'); // [0] since the tournament can only be in one championship
        break;

      case 'startTime':
        contentCell = Moment(tournament.startTime).format(DATE_TIME_FORMAT);
        break;

      case 'endTime':
        contentCell = Moment(tournament.endTime).format(DATE_TIME_FORMAT);
        break;

      case 'entriesOpen':
        const entriesOpen = tournament.entriesOpen ? Moment(tournament.entriesOpen).format(DATE_TIME_FORMAT) : '';
        contentCell = entriesOpen;
        break;

      case 'deadlineForEntries':
        const deadlineForEntries = tournament.deadlineForEntries
          ? Moment(tournament.deadlineForEntries).format(DATE_TIME_FORMAT)
          : '';
        contentCell = deadlineForEntries;
        break;

      case 'sportName':
        contentCell = propz.get(tournament, ['sport', 'name'], '');
        break;

      default:
        contentCell = propz.get(tournament, [row.field], '');
    }

    return (
      <tr key={`tournament_summary_${index}`}>
        <td>{row.title}</td>
        <td>{contentCell}</td>
      </tr>
    );
  });

  return (
    <table className={'table table-sm mPre'}>
      <tbody>{rows}</tbody>
    </table>
  );
};

TournamentSummary.displayName = 'TournamentSummary';
