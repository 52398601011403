import axios from 'axios';
export function getKey(user) {
  const { loginSessionKey: key } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.get(`${window.apiBase}/i/key`, config).then(response => {
    return response.data;
  });
}
