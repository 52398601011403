import * as React from 'react';
import { ArbiterBoardGalleryItemMediaDetails } from './ArbiterBoardGalleryItemMediaDetails';
import { AdminBoardGalleryItem } from './ArbiterBoard';
import { ArbiterBoardGalleryFullScreen } from './ArbiterBoardGalleryFullScreen';
import { ArbiterBoardGalleryItemsStrip } from './ArbiterBoardGalleryItemsStrip';
import { EventVideos } from '../SchoolEventView/Videos/EventVideos';
import { ACCESS_PRESET_TYPE } from '../../../../../../types/album';
import { updateEventVideo } from '../../../../../../helpers/service/admin/eventVideos';
import { ARBITER_BOARD_GALLERY_ITEM_TYPE } from '../../../../../../types/arbiterBoard';

interface Props {
  items: AdminBoardGalleryItem[];
  onAccessPresetClick: (id: string, accessPreset: ACCESS_PRESET_TYPE, type: ARBITER_BOARD_GALLERY_ITEM_TYPE) => void;
  isUpdateAccessPreset: boolean;
}
interface State {
  selectedItemId: string;
  selectedItemIndex: number;
  windowWidth: number;
}

export class ArbiterBoardGallery extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { items } = props;
    const [item] = items;
    const { id } = item;

    this.state = {
      selectedItemId: id,
      selectedItemIndex: 0,
      windowWidth: window.innerWidth
    };
  }

  onClick = (id: string) => {
    const { items } = this.props;
    const itemIndex = items.findIndex(item => item.id === id);
    this.setState({
      selectedItemId: id,
      selectedItemIndex: itemIndex
    });
  };

  onLeftClick = () => {
    const { selectedItemIndex } = this.state;
    const { items } = this.props;

    if (selectedItemIndex > 0) {
      const selectedItemIndexNext = selectedItemIndex - 1;

      this.setState({
        selectedItemIndex: selectedItemIndexNext,
        selectedItemId: items[selectedItemIndexNext].id
      });
    }
  };

  onRightClick = () => {
    const { selectedItemIndex } = this.state;
    const { items } = this.props;

    const itemsCount = items.length;

    if (selectedItemIndex < itemsCount - 1) {
      const selectedItemIndexNext = selectedItemIndex + 1;

      this.setState({
        selectedItemIndex: selectedItemIndexNext,
        selectedItemId: items[selectedItemIndexNext].id
      });
    }
  };

  render() {
    const { items, isUpdateAccessPreset } = this.props;
    const { selectedItemIndex, windowWidth } = this.state;

    const item = items[selectedItemIndex];

    return (
      <div>
        <ArbiterBoardGalleryFullScreen
          onLeftClick={this.onLeftClick}
          onRightClick={this.onRightClick}
          onAccessPresetClick={this.props.onAccessPresetClick}
          isUpdateAccessPreset={isUpdateAccessPreset}
          item={item}
        />
        <ArbiterBoardGalleryItemsStrip
          windowWidth={windowWidth}
          selectedItemIndex={selectedItemIndex}
          items={items}
          onClick={this.onClick}
        />
        <ArbiterBoardGalleryItemMediaDetails item={item} />
      </div>
    );
  }
}
