import * as React from 'react';
import { FunctionComponent } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button } from '../Button/Button';
import { TournamentPitch } from '../../models/tournament';

interface Props {
  onCancel: () => void;
  onSubmit: (data: { pitchId: string }) => void;
  pitches: TournamentPitch[];
}

const PitchSchema = Yup.object().shape({
  pitchId: Yup.string().required('Required')
});

export const EventPitchForm: FunctionComponent<Props> = props => {
  const pitches = props.pitches || [];

  const pitchForm = {
    pitchId: ''
  };

  const getPitchOptions = () =>
    pitches.map(pitch => (
      <option key={pitch.id} value={pitch.id}>
        {pitch.name}
      </option>
    ));

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik
            initialValues={pitchForm}
            validationSchema={PitchSchema}
            onSubmit={values => {
              props.onSubmit(values);
            }}
            render={({ touched, errors }) => (
              <Form>
                <div className="form-group form-check">
                  <div className="form-group">
                    <label>Pitch</label>
                    <Field component="select" name="pitchId" className="form-control mb-3">
                      <option value={''} />
                      {getPitchOptions()}
                    </Field>
                    {touched.pitchId && errors.pitchId ? (
                      <div className="alert alert-danger">{errors.pitchId}</div>
                    ) : null}
                  </div>
                  <Button onClick={props.onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                  <button type="submit" className="mt-3 mb-3 btn btn-primary">
                    Save
                  </button>
                </div>
              </Form>
            )}
          />
        </div>
      </div>
    </div>
  );
};

EventPitchForm.displayName = 'EventPitchForm';
