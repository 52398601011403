import * as React from 'react';
import { SelectOption } from '../../helpers/table/table';

interface Props {
  onChange: (value: string | number) => void;
  options: SelectOption[];
  activeResultIndex: number;
  customClass?: string;
  isSetDefaultValue?: boolean;
  disabled?: boolean;
}

export class Select extends React.Component<Props> {
  componentDidMount() {
    //set default value
    const { options, activeResultIndex, onChange, isSetDefaultValue } = this.props;
    const option = options[activeResultIndex];

    if (typeof option !== 'undefined' && isSetDefaultValue) {
      onChange(option.value);
    }
  }

  handleChange = event => {
    const { onChange } = this.props;
    onChange(event.target.value);
  };

  renderOptions() {
    const { options } = this.props;

    return options.map((option, index) => {
      return (
        <option key={`select_${index}`} value={option.value}>
          {option.text}
        </option>
      );
    });
  }

  render() {
    const { options, activeResultIndex, customClass, disabled } = this.props;
    const option = options[activeResultIndex];

    if (typeof option === 'undefined') {
      console.error('Not active option');
      return <span>Not active option</span>;
    }

    const value = option.value;

    return (
      <select onChange={this.handleChange} value={value} className={customClass} disabled={disabled}>
        {this.renderOptions()}
      </select>
    );
  }
}
