import * as React from 'react';
import { Field, Form, Formik } from 'formik';
import DatePicker from 'react-datepicker';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from '../../../../../../consts/report';
import { getStartDateForSchoolYear } from '../../../../../../helpers/calendar/calendar';

interface Props {
  onCloseClick: () => void;
  onSubmit: (data: any, reportType: string) => void;
}

//report 011
export function ExtendedTournamentScheduleReportForm({ onCloseClick, onSubmit }: Props) {
  const now = new Date();
  let dateFrom = getStartDateForSchoolYear(now);

  const requestInitial = {
    dateFrom,
    dateTo: now
  };

  return (
    <Formik
      onSubmit={values => onSubmit(values, REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.EXTENDED_TOURNAMENT_SCHEDULE)}
      initialValues={requestInitial}
      render={({ values, setFieldValue }) => (
        <Form>
          <div className="bReportFormTitle">Extended tournament schedule</div>
          <div className="mb-3 mt-3">
            {'This report will enable you to see the full details of your tournament schedule, ' +
              'view all information surrounding the tournament, from the number of schools booked on ' +
              'to the number of spaces left. Also you are able to see all webaddresses linked to your tournament and organiser details.'}
          </div>
          <div className="form-group">
            <label>Date from</label>
            <Field>
              {({ form }) => (
                <div className="mb-3">
                  <DatePicker
                    selected={form.values.dateFrom}
                    onChange={dateFrom => {
                      form.setFieldValue('dateFrom', dateFrom);
                      if (form.values.dateTo && form.values.dateTo < dateFrom) {
                        form.setFieldValue('dateTo', dateFrom);
                      }
                    }}
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
              )}
            </Field>
          </div>

          <div className="form-group">
            <label>Date to</label>
            <Field>
              {({ form }) => (
                <div className="mb-3">
                  <DatePicker
                    selected={form.values.dateTo}
                    onChange={dateTo => {
                      form.setFieldValue('dateTo', dateTo);
                    }}
                    minDate={form.values.dateFrom}
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
              )}
            </Field>
          </div>

          <button type="button" className="btn btn-secondary mr-3" onClick={onCloseClick}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Get
          </button>
        </Form>
      )}
    />
  );
}
