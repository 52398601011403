import * as React from 'react';
import { Component } from 'react';
import { SchoolEvent } from '../../../../../../../models/event';
import * as propz from 'propz';
import * as classNames from 'classnames';
import {
  convertPointsToStringWithoutDelimeter,
  convertStringToPoints,
  getDefaultDigitMask,
  getDefaultTimeOrDistanceMask,
  getIndividualScoreByStudent,
  getIsErrorHashMapFromDataHashMap,
  getMask,
  getPlayerScoreId,
  getScorePointsForTeamEvents,
  getTeamScoreByTeamId,
  getTeamScoreId,
  isSportPointsDisplayPlain
} from '../../../../../../../helpers/event/schoolEventViewHelper';
import * as TextMaskCore from 'text-mask-core';
import { EMPTY_STRING } from '../../../../../../../consts/date';
import { Button } from '../../../../../../../components/Button/Button';
import './Results.scss';
import { Checkbox } from '../../../../../../../components/Checkbox/Checkbox';
import { getRegexpFromSportSign } from '../../../../../../../helpers/sport/sport';
import { isMultipartyEvent } from '../../../../../../../helpers/event/event';
import { EVENT_RESULTS_MODE } from '../../../../../../../consts/event';
import { ResultsMode } from './ResultsMode';
import { sortTeams } from '../../../../../../../helpers/team/team';
import { ARROW_DOWN_KEY, ARROW_UP_KEY, ENTER_KEY } from '../../../../../../../consts/common';

interface Props {
  event: SchoolEvent;
  onSaveClick: (data, resultsMode?: string) => void;
  onCloseClick: () => void;
}
interface State {
  mask: boolean | (string | RegExp)[];
  dataHashMap: DataHashMap;
  players: Player[];
  teams: Team[];
  continuousCount: number;
  resultsMode: string;
}

export interface DataHashMap {
  [key: string]: {
    value: string;
    isError: boolean;
    oldValue: string;
    continuousCount: number;
    isInputEnabled: boolean;
    //only for team
    playerIds?: string[];
    teamPoints?: number;
    //only for player
    teamId?: string;
  };
}

interface Player {
  id: string;
  userId: string;
  permissionId: string;
  teamId: string;
  continuousCount: number;
}

interface Team {
  id: string;
  continuousCount: number;
}

export class TeamInternalResults extends Component<Props, State> {
  inputElement: any[];
  textMaskInputElement: any[];

  constructor(props: Props) {
    super(props);

    this.inputElement = [];
    this.textMaskInputElement = [];

    const { event } = props;
    const mask = propz.get(event, ['sport', 'points', 'inputMask']);

    const inputMask = getMask(mask);

    const { teamsData } = event;
    let players = [];
    let teams = [];

    let continuousCount = 0;
    let dataHashMap: DataHashMap = {};

    [...teamsData]
      .sort((team1, team2) => sortTeams(event, team1, team2))
      .forEach(team => {
        const teamId = team.id;
        const teamPoints = getTeamScoreByTeamId(event, teamId);
        const teamPointsStr = convertPointsToStringWithoutDelimeter(event, teamPoints);

        const isTeamIndividualPlayersScoreExists = event.results.individualScore
          .filter(scoreData => scoreData.teamId === teamId)
          .some(scoreData => scoreData.score !== 0);

        continuousCount++;

        dataHashMap[teamId] = {
          value: teamPoints === 0 ? '' : teamPointsStr,
          oldValue: teamPoints === 0 ? '' : teamPointsStr,
          isError: false,
          continuousCount: continuousCount,
          playerIds: team.players.map(player => player.id),
          isInputEnabled: !isTeamIndividualPlayersScoreExists,
          teamPoints: teamPoints
        };

        teams.push({ id: teamId, continuousCount });

        team.players.forEach(({ id, userId, permissionId }) => {
          const playerPoints = getIndividualScoreByStudent(event, userId, permissionId);
          const playerPointsStr = convertPointsToStringWithoutDelimeter(event, playerPoints);

          continuousCount++;

          dataHashMap[id] = {
            value: playerPoints === 0 ? '' : playerPointsStr,
            oldValue: playerPoints === 0 ? '' : playerPointsStr,
            isError: false,
            continuousCount: continuousCount,
            isInputEnabled: isTeamIndividualPlayersScoreExists,
            teamId: teamId
          };

          players.push({ id, userId, permissionId, continuousCount, teamId });
        });
      });

    const defaultResultsMode = isMultipartyEvent(event) ? EVENT_RESULTS_MODE.PLACES : EVENT_RESULTS_MODE.RESULTS;
    const resultsMode = propz.get(event, ['resultsMode'], defaultResultsMode);

    this.state = {
      mask: inputMask,
      dataHashMap: dataHashMap,
      players: players,
      teams: teams,
      continuousCount: continuousCount,
      resultsMode: resultsMode
    };
  }

  componentDidMount() {
    this.initTextMask();
  }

  initTextMask() {
    const { dataHashMap, continuousCount, players, teams, mask } = this.state;
    const { event } = this.props;

    for (let i = 1; i <= continuousCount; i++) {
      const playersIndex = players.findIndex(player => player.continuousCount === i);
      const teamsIndex = teams.findIndex(team => team.continuousCount === i);

      const isPlayer = playersIndex !== -1;
      const isTeam = teamsIndex !== -1;

      let id;

      switch (true) {
        case isPlayer:
          id = players[playersIndex].id;
          break;
        case isTeam:
          id = teams[teamsIndex].id;
          break;
      }

      const { value, isInputEnabled } = dataHashMap[id];

      switch (true) {
        //very specific condition
        case mask && isTeam && !isInputEnabled && !isSportPointsDisplayPlain(event) && !isMultipartyEvent(event):
          this.textMaskInputElement[i] = TextMaskCore.createTextMaskInputElement({
            inputElement: this.inputElement[i],
            mask: inputValue => getDefaultTimeOrDistanceMask(event, inputValue),
            showMask: false
          });
          break;
        default:
          this.textMaskInputElement[i] = TextMaskCore.createTextMaskInputElement({
            inputElement: this.inputElement[i],
            mask: mask || (inputValue => getDefaultDigitMask(event, inputValue)),
            showMask: !mask
          });
      }

      this.textMaskInputElement[i].update(value);
    }
  }

  onSubmit = () => {
    const { dataHashMap, players, teams, resultsMode } = this.state;
    const { event, onSaveClick } = this.props;

    const isErrorHashMap = getIsErrorHashMapFromDataHashMap(event, dataHashMap, players, [], teams);

    const isErrorPlayers = players.some(player => isErrorHashMap[player.id] === true);
    const isErrorTeams = teams.some(team => isErrorHashMap[team.id] === true);

    if (isErrorPlayers || isErrorTeams) {
      let dataHashMapUpdated: DataHashMap = {};

      for (let dataId in dataHashMap) {
        dataHashMapUpdated[dataId] = {
          ...dataHashMap[dataId],
          isError: isErrorHashMap[dataId]
        };
      }

      this.setState({
        dataHashMap: dataHashMapUpdated
      });
      return;
    }

    const playerScores = players.map(({ userId, permissionId, id, teamId }) => {
      return {
        userId: userId,
        permissionId: permissionId,
        teamId: teamId,
        score: getScorePointsForTeamEvents(event, dataHashMap, id),
        scoreId: getPlayerScoreId(event, userId, permissionId)
      };
    });

    const teamScores = teams.map(({ id }) => {
      const { isInputEnabled, teamPoints } = dataHashMap[id];
      return {
        teamId: id,
        score: isInputEnabled ? getScorePointsForTeamEvents(event, dataHashMap, id) : teamPoints,
        scoreId: getTeamScoreId(event, id)
      };
    });

    const allScores = [...playerScores, ...teamScores];

    onSaveClick(allScores, resultsMode);
  };

  onChange(eventDescriptor: React.ChangeEvent<HTMLInputElement>, index: number, id: string) {
    const { dataHashMap } = this.state;
    const value = eventDescriptor.target.value;

    let dataHashMapUpdated: DataHashMap = { ...dataHashMap };

    for (let dataId in dataHashMap) {
      if (dataId === id) {
        dataHashMapUpdated[dataId] = {
          ...dataHashMap[dataId],
          isError: false,
          value: value
        };
      }
    }

    this.setState({
      dataHashMap: dataHashMapUpdated
    });

    this.textMaskInputElement[index].update(value);
  }

  onKeyDown = (eventDescriptor, index: number) => {
    const { continuousCount } = this.state;

    const isKeyEnter = eventDescriptor.key === ENTER_KEY;
    const isKeyArrowUp = eventDescriptor.key === ARROW_UP_KEY;
    const isKeyArrowDown = eventDescriptor.key === ARROW_DOWN_KEY;
    const isLastInput = index === continuousCount;
    const isFirstInput = index === 1;

    switch (true) {
      case isKeyEnter && !isLastInput:
      case isKeyArrowDown && !isLastInput:
        this.inputElement[index + 1].focus();
        break;
      case isKeyArrowUp && !isFirstInput:
        this.inputElement[index - 1].focus();
        break;
    }
  };

  onFocus(e, index: number, id: string) {
    const { dataHashMap } = this.state;
    const value = dataHashMap[id].value;

    let dataHashMapUpdated: DataHashMap = {};

    for (let dataId in dataHashMap) {
      if (dataId === id) {
        dataHashMapUpdated[dataId] = {
          ...dataHashMap[dataId],
          oldValue: value
        };
      } else {
        dataHashMapUpdated[dataId] = {
          ...dataHashMap[dataId]
        };
      }
    }

    this.setState({
      dataHashMap: dataHashMapUpdated
    });

    this.textMaskInputElement[index].update('');

    e.stopPropagation();
  }

  onBlur(e, index: number, id: string) {
    const eventValue = e.target.value;
    const { dataHashMap } = this.state;
    const { event } = this.props;

    let dataHashMapUpdated: DataHashMap = { ...dataHashMap };

    if (eventValue === EMPTY_STRING) {
      for (let dataId in dataHashMap) {
        if (dataId === id) {
          dataHashMapUpdated[id] = {
            ...dataHashMap[id],
            value: dataHashMap[id].oldValue
          };
        } else {
          dataHashMapUpdated[dataId] = {
            ...dataHashMap[dataId]
          };
        }
      }
      this.setState({
        dataHashMap: dataHashMapUpdated
      });
      this.textMaskInputElement[index].update(dataHashMap[id].oldValue);
    } else {
      for (let schoolId in dataHashMap) {
        if (schoolId === id) {
          dataHashMapUpdated[id] = {
            ...dataHashMap[id],
            value: eventValue
          };

          const isTeamIdExist = typeof dataHashMap[schoolId].teamId !== 'undefined';
          if (isTeamIdExist) {
            const teamId = dataHashMap[schoolId].teamId;
            const { playerIds } = dataHashMap[teamId];

            let playersValue = 0;

            playerIds.forEach(playerId => {
              const playerValue = dataHashMapUpdated[playerId].value;
              const playerValueCleaned = playerValue.replace('_', '');
              const points = convertStringToPoints(event, playerValueCleaned);
              playersValue += isNaN(points) ? 0 : points;
            });

            const pointsStr = convertPointsToStringWithoutDelimeter(event, playersValue);

            const teamContinuousCount = dataHashMapUpdated[teamId].continuousCount;
            dataHashMapUpdated[teamId] = {
              ...dataHashMap[teamId],
              isError: false,
              value: pointsStr,
              teamPoints: playersValue
            };

            //only for sport points plain
            const regExpSportSign = getRegexpFromSportSign(event);

            if (isSportPointsDisplayPlain(event) && regExpSportSign.test(eventValue)) {
              this.textMaskInputElement[teamContinuousCount].update(pointsStr);
            } else {
              this.textMaskInputElement[teamContinuousCount].update(pointsStr);
            }
          }
        } else {
          dataHashMapUpdated[schoolId] = {
            ...dataHashMap[schoolId]
          };
        }
      }

      this.setState({
        dataHashMap: dataHashMapUpdated
      });

      this.textMaskInputElement[index].update(eventValue);
    }

    e.stopPropagation();
  }

  renderInput(index: number, id: string): React.ReactNode {
    const { dataHashMap } = this.state;
    return (
      <input
        ref={el => (this.inputElement[index] = el)}
        onKeyDown={event => this.onKeyDown(event, index)}
        onChange={event => this.onChange(event, index, id)}
        onFocus={event => this.onFocus(event, index, id)}
        onBlur={event => this.onBlur(event, index, id)}
        className={dataHashMap[id].isError === true ? 'eTeamInternalResultsInput mError' : 'eTeamInternalResultsInput'}
        disabled={!dataHashMap[id].isInputEnabled}
      />
    );
  }

  onIndividualResultsChange = (teamId: string): void => {
    const { dataHashMap, mask } = this.state;
    const { event } = this.props;

    let dataHashMapUpdated: DataHashMap = { ...dataHashMap };

    const team = dataHashMap[teamId];

    if (team.isInputEnabled) {
      dataHashMapUpdated[teamId] = {
        ...team,
        oldValue: team.value,
        value: '',
        isInputEnabled: !team.isInputEnabled
      };
      dataHashMapUpdated[teamId].playerIds.forEach(playerId => {
        const player = dataHashMap[playerId];
        dataHashMapUpdated[playerId] = {
          ...player,
          value: '',
          isInputEnabled: !player.isInputEnabled
        };
        this.textMaskInputElement[player.continuousCount].update('');
      });

      if (isSportPointsDisplayPlain(event)) {
        this.textMaskInputElement[team.continuousCount].update('');
      } else {
        const { continuousCount } = dataHashMapUpdated[teamId];

        this.textMaskInputElement[continuousCount] = TextMaskCore.createTextMaskInputElement({
          inputElement: this.inputElement[continuousCount],
          mask: inputValue => getDefaultTimeOrDistanceMask(event, inputValue),
          showMask: false
        });

        this.textMaskInputElement[team.continuousCount].update('');
      }
    } else {
      dataHashMapUpdated[teamId] = {
        ...team,
        value: team.oldValue,
        isInputEnabled: !team.isInputEnabled
      };
      dataHashMapUpdated[teamId].playerIds.forEach(playerId => {
        const player = dataHashMap[playerId];
        dataHashMapUpdated[playerId] = {
          ...player,
          oldValue: player.value,
          value: '',
          isInputEnabled: !player.isInputEnabled
        };
        this.textMaskInputElement[player.continuousCount].update('');
      });

      if (isSportPointsDisplayPlain(event)) {
        this.textMaskInputElement[team.continuousCount].update(team.oldValue);
      } else {
        const { continuousCount } = dataHashMapUpdated[teamId];

        this.textMaskInputElement[continuousCount] = TextMaskCore.createTextMaskInputElement({
          inputElement: this.inputElement[continuousCount],
          mask: mask,
          showMask: false
        });

        this.textMaskInputElement[team.continuousCount].update(team.oldValue);
      }
    }

    this.setState({
      dataHashMap: dataHashMapUpdated
    });
  };

  onResultsModeClick = (resultsMode: string): void => {
    const { dataHashMap } = this.state;

    let dataHashMapUpdated = { ...dataHashMap };

    for (let dataId in dataHashMap) {
      const isTeam = typeof dataHashMap[dataId].teamId !== 'undefined';

      if (isTeam) {
        dataHashMapUpdated[dataId] = {
          ...dataHashMap[dataId],
          isError: false,
          value: '',
          oldValue: '',
          teamPoints: 0
        };
      } else {
        dataHashMapUpdated[dataId] = {
          ...dataHashMap[dataId],
          isError: false,
          value: '',
          oldValue: ''
        };
      }

      this.textMaskInputElement[dataHashMap[dataId].continuousCount].update('');
    }

    this.setState({
      dataHashMap: dataHashMapUpdated,
      resultsMode
    });
  };

  render() {
    const { event, onCloseClick } = this.props;
    const { dataHashMap, resultsMode } = this.state;
    const { teamsData } = event;

    let count = 0;

    return (
      <div>
        <ResultsMode
          isMultipartyEvent={isMultipartyEvent(event)}
          resultsMode={resultsMode}
          onResultsModeClick={this.onResultsModeClick}
        />
        {[...teamsData]
          .sort((team1, team2) => sortTeams(event, team1, team2))
          .map(({ id: teamId, name, players }, index) => {
            count++;
            const isPlayersExist = dataHashMap[teamId].playerIds.length !== 0;

            const hidePlayersListClasses = classNames({
              mHidden: dataHashMap[teamId].isInputEnabled
            });

            return (
              <table className={'eTeamInternalResultsTable'} key={`teams_inter_teams_results_table_${index}`}>
                <tbody>
                  <tr key={`teams_inter_teams_results_${index}`}>
                    <td colSpan={3} className={'eTeamInternalResultsTeamName'}>
                      {name}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} className={'eTeamInternalResultsCell'} style={{ width: '50%' }}>
                      Total:
                    </td>
                    <td className={'eTeamInternalResultsCell'} style={{ width: '50%' }}>
                      {this.renderInput(count, teamId)}
                    </td>
                  </tr>
                  {isPlayersExist && !isMultipartyEvent(event) && (
                    <tr>
                      <td colSpan={3} className={'eTeamInternalResultsCell'}>
                        <Checkbox
                          value={!dataHashMap[teamId].isInputEnabled}
                          id={teamId}
                          onChange={this.onIndividualResultsChange}
                          labelText={'Individual Scoring'}
                          disabled={isMultipartyEvent(event)}
                        />
                      </td>
                    </tr>
                  )}

                  {players.map(({ firstName, lastName, id }, playerIndex) => {
                    count++;
                    return (
                      <tr key={`teams_inter_teams_results_player_${playerIndex}`} className={hidePlayersListClasses}>
                        <td className={'eTeamInternalResultsCell'}>{playerIndex + 1}.</td>
                        <td className={'eTeamInternalResultsCell'}>{`${firstName} ${lastName}`}</td>
                        <td className={'eTeamInternalResultsCell'}>{this.renderInput(count, id)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            );
          })}
        <Button text={'Cancel'} onClick={onCloseClick} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
        <Button text={'Save results'} onClick={this.onSubmit} customClass={'mt-3 mb-3'} />
      </div>
    );
  }
}
