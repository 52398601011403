import * as React from 'react';
import { Component } from 'react';
import { Participant, SchoolEvent } from 'Src/models/event';
import * as propz from 'propz';
import * as classNames from 'classnames';
import {
  convertPointsToStringWithoutDelimeter,
  convertStringToPoints,
  getDefaultDigitMask,
  getDefaultTimeOrDistanceMask,
  getIndividualScoreByStudent,
  getIsErrorHashMapFromDataHashMap,
  getMask,
  getPlayerScoreId,
  getSchoolScoreBySchoolId,
  getSchoolScoreId,
  getScorePointsForTeamEvents,
  getTeamScoreByTeamId,
  getTeamScoreId,
  isSportPointsDisplayPlain
} from 'Src/helpers/event/schoolEventViewHelper';
import * as TextMaskCore from 'text-mask-core';
import { EMPTY_STRING } from 'Src/consts/date';
import { Button } from 'Src/components/Button/Button';
import { Checkbox } from 'Src/components/Checkbox/Checkbox';
import { getRegexpFromSportSign } from 'Src/helpers/sport/sport';
import { getInterSchoolsTeamEventParticipants, isMultipartyEvent } from 'Src/helpers/event/event';
import { AppUser } from 'Src/views/App/App';
import { ARROW_DOWN_KEY, ARROW_UP_KEY, ENTER_KEY } from 'Src/consts/common';
import { getEventGeneratedName } from '../../../../../helpers/accessor/accessor';

interface Props {
  event: SchoolEvent;
  onSaveClick: (data, resultsMode?: string) => void;
  onCloseClick: () => void;
  user: AppUser;
}
interface State {
  mask: boolean | (string | RegExp)[];
  dataHashMap: DataHashMap;
  players: Player[];
  team: Team;
  school: School;
  continuousCount: number;
  participant: Participant;
}

export interface DataHashMap {
  [key: string]: {
    value: string;
    isError: boolean;
    oldValue: string;
    continuousCount: number;
    isInputEnabled: boolean;
    //only for team
    playerIds?: string[];
    teamPoints?: number;
    //only for player
    teamId?: string;
  };
}

interface Player {
  id: string;
  userId: string;
  permissionId: string;
  teamId: string;
  continuousCount: number;
}

interface Team {
  id: string;
  continuousCount: number;
  schoolId: string;
}

interface School {
  id: string;
  name: string;
  continuousCount: number;
}

export class SchoolIndividualTournamentTeamEventResults extends Component<Props, State> {
  inputElement: any[];
  textMaskInputElement: any[];

  constructor(props: Props) {
    super(props);

    this.inputElement = [];
    this.textMaskInputElement = [];

    const { event, user } = props;
    const mask = propz.get(event, ['sport', 'points', 'inputMask']);

    const inputMask = getMask(mask);

    let players = [];
    let team: Team;
    let school: School;

    let continuousCount = 0;
    let dataHashMap: DataHashMap = {};

    const participants = getInterSchoolsTeamEventParticipants(event, user);

    //for tournament events we can update results only for active school
    const participantFounded = participants.find(participant => {
      const { user } = props;
      const { activeSchoolId } = user;
      const schoolId = participant.school.id;

      return activeSchoolId === schoolId;
    });

    const { team: teamParticipant, school: schoolParticipant } = participantFounded;
    const isTeamExist = typeof teamParticipant !== 'undefined';

    if (!isTeamExist) {
      continuousCount++;
      const schoolPoints = getSchoolScoreBySchoolId(event, schoolParticipant.id);
      const schoolPointsStr = convertPointsToStringWithoutDelimeter(event, schoolPoints);

      dataHashMap[schoolParticipant.id] = {
        value: schoolPoints === 0 ? '' : schoolPointsStr,
        oldValue: schoolPoints === 0 ? '' : schoolPointsStr,
        isError: false,
        continuousCount: continuousCount,
        isInputEnabled: true
      };

      school = { id: schoolParticipant.id, continuousCount, name: schoolParticipant.name };
    } else {
      const teamId = teamParticipant.id;
      const teamPoints = getTeamScoreByTeamId(event, teamId);
      const teamPointsStr = convertPointsToStringWithoutDelimeter(event, teamPoints);

      const isTeamIndividualPlayersScoreExists = event.results.individualScore
        .filter(scoreData => scoreData.teamId === teamId)
        .some(scoreData => scoreData.score !== 0);

      continuousCount++;

      dataHashMap[teamId] = {
        value: teamPoints === 0 ? '' : teamPointsStr,
        oldValue: teamPoints === 0 ? '' : teamPointsStr,
        isError: false,
        continuousCount: continuousCount,
        playerIds: teamParticipant.players.map(player => player.id),
        isInputEnabled: !isTeamIndividualPlayersScoreExists,
        teamPoints: teamPoints
      };

      team = { id: teamId, continuousCount, schoolId: schoolParticipant.id };

      teamParticipant.players.forEach(({ id, userId, permissionId }) => {
        const playerPoints = getIndividualScoreByStudent(event, userId, permissionId);
        const playerPointsStr = convertPointsToStringWithoutDelimeter(event, playerPoints);

        continuousCount++;

        dataHashMap[id] = {
          value: playerPoints === 0 ? '' : playerPointsStr,
          oldValue: playerPoints === 0 ? '' : playerPointsStr,
          isError: false,
          continuousCount: continuousCount,
          isInputEnabled: isTeamIndividualPlayersScoreExists,
          teamId: teamId
        };

        players.push({ id, userId, permissionId, continuousCount, teamId });
      });
    }

    this.state = {
      mask: inputMask,
      dataHashMap: dataHashMap,
      players: players,
      team: team,
      continuousCount: continuousCount,
      school: school,
      participant: participantFounded
    };
  }

  componentDidMount() {
    this.initTextMask();
  }

  initTextMask() {
    const { dataHashMap, continuousCount, players, team, mask, school } = this.state;
    const { event } = this.props;

    for (let i = 1; i <= continuousCount; i++) {
      const playersIndex = players.findIndex(player => player.continuousCount === i);
      const isPlayer = playersIndex !== -1;
      const isTeam = propz.get(team, ['continuousCount']) === i;
      const isSchool = propz.get(school, ['continuousCount']) === i;

      let id;

      switch (true) {
        case isPlayer:
          id = players[playersIndex].id;
          break;
        case isTeam:
          id = team.id;
          break;
        case isSchool:
          id = school.id;
          break;
      }

      const { value, isInputEnabled } = dataHashMap[id];

      switch (true) {
        //very specific condition
        case mask && isTeam && !isInputEnabled && !isSportPointsDisplayPlain(event) && !isMultipartyEvent(event):
          this.textMaskInputElement[i] = TextMaskCore.createTextMaskInputElement({
            inputElement: this.inputElement[i],
            mask: inputValue => getDefaultTimeOrDistanceMask(event, inputValue),
            showMask: false
          });
          break;
        default:
          this.textMaskInputElement[i] = TextMaskCore.createTextMaskInputElement({
            inputElement: this.inputElement[i],
            mask: mask || (inputValue => getDefaultDigitMask(event, inputValue)),
            showMask: !mask
          });
      }

      this.textMaskInputElement[i].update(value);
    }
  }

  onSubmit = () => {
    const { dataHashMap, players, team, school } = this.state;
    const { event, onSaveClick } = this.props;

    const isTeamExist = typeof team !== 'undefined';
    const isSchoolExist = typeof school !== 'undefined';

    const isErrorHashMap = getIsErrorHashMapFromDataHashMap(
      event,
      dataHashMap,
      players,
      [],
      isTeamExist ? [team] : [],
      isSchoolExist ? [school] : []
    );

    const isErrorPlayers = players.some(player => isErrorHashMap[player.id] === true);
    const isErrorTeams = isTeamExist ? isErrorHashMap[team.id] === true : false;
    const isErrorSchools = isSchoolExist ? isErrorHashMap[school.id] === true : false;

    if (isErrorPlayers || isErrorTeams || isErrorSchools) {
      let dataHashMapUpdated: DataHashMap = {};

      for (let dataId in dataHashMap) {
        dataHashMapUpdated[dataId] = {
          ...dataHashMap[dataId],
          isError: isErrorHashMap[dataId]
        };
      }

      this.setState({
        dataHashMap: dataHashMapUpdated
      });
      return;
    }

    const playerScores = players.map(({ userId, permissionId, id, teamId }) => {
      return {
        userId: userId,
        permissionId: permissionId,
        teamId: teamId,
        score: getScorePointsForTeamEvents(event, dataHashMap, id),
        scoreId: getPlayerScoreId(event, userId, permissionId)
      };
    });

    const teamScores = isTeamExist
      ? [
          {
            teamId: team.id,
            score: dataHashMap[team.id].isInputEnabled
              ? getScorePointsForTeamEvents(event, dataHashMap, team.id)
              : dataHashMap[team.id].teamPoints,
            scoreId: getTeamScoreId(event, team.id)
          }
        ]
      : [];

    const schoolScores = isSchoolExist
      ? [
          {
            schoolId: school.id,
            score: getScorePointsForTeamEvents(event, dataHashMap, school.id),
            scoreId: getSchoolScoreId(event, school.id)
          }
        ]
      : [];

    const allScores = [...playerScores, ...teamScores, ...schoolScores];

    onSaveClick(allScores);
  };

  onChange(eventDescriptor: React.ChangeEvent<HTMLInputElement>, index: number, id: string) {
    const { dataHashMap } = this.state;

    const value = eventDescriptor.target.value;

    let dataHashMapUpdated: DataHashMap = { ...dataHashMap };

    for (let dataId in dataHashMap) {
      if (dataId === id) {
        dataHashMapUpdated[dataId] = {
          ...dataHashMap[dataId],
          isError: false,
          value: value
        };
      }
    }

    this.setState({
      dataHashMap: dataHashMapUpdated
    });

    this.textMaskInputElement[index].update(value);
  }

  onKeyDown = (eventDescriptor, index: number) => {
    const { continuousCount } = this.state;

    const isKeyEnter = eventDescriptor.key === ENTER_KEY;
    const isKeyArrowUp = eventDescriptor.key === ARROW_UP_KEY;
    const isKeyArrowDown = eventDescriptor.key === ARROW_DOWN_KEY;
    const isLastInput = index === continuousCount;
    const isFirstInput = index === 1;

    switch (true) {
      case isKeyEnter && !isLastInput:
      case isKeyArrowDown && !isLastInput:
        this.inputElement[index + 1].focus();
        break;
      case isKeyArrowUp && !isFirstInput:
        this.inputElement[index - 1].focus();
        break;
    }
  };

  onFocus(e, index: number, id: string) {
    const { dataHashMap } = this.state;
    const value = dataHashMap[id].value;

    let dataHashMapUpdated: DataHashMap = {};

    for (let dataId in dataHashMap) {
      if (dataId === id) {
        dataHashMapUpdated[dataId] = {
          ...dataHashMap[dataId],
          oldValue: value
        };
      } else {
        dataHashMapUpdated[dataId] = {
          ...dataHashMap[dataId]
        };
      }
    }

    this.setState({
      dataHashMap: dataHashMapUpdated
    });

    this.textMaskInputElement[index].update('');

    e.stopPropagation();
  }

  onBlur(e, index: number, id: string) {
    const eventValue = e.target.value;
    const { dataHashMap } = this.state;
    const { event } = this.props;

    let dataHashMapUpdated: DataHashMap = { ...dataHashMap };

    if (eventValue === EMPTY_STRING) {
      for (let dataId in dataHashMap) {
        if (dataId === id) {
          dataHashMapUpdated[id] = {
            ...dataHashMap[id],
            value: dataHashMap[id].oldValue
          };
        } else {
          dataHashMapUpdated[dataId] = {
            ...dataHashMap[dataId]
          };
        }
      }
      this.setState({
        dataHashMap: dataHashMapUpdated
      });
      this.textMaskInputElement[index].update(dataHashMap[id].oldValue);
    } else {
      for (let dataId in dataHashMap) {
        if (dataId === id) {
          dataHashMapUpdated[id] = {
            ...dataHashMap[id],
            value: eventValue
          };

          const isTeamIdExist = typeof dataHashMap[dataId].teamId !== 'undefined';
          if (isTeamIdExist) {
            const teamId = dataHashMap[dataId].teamId;
            const { playerIds } = dataHashMap[teamId];

            let playersValue = 0;

            playerIds.forEach(playerId => {
              const playerValue = dataHashMapUpdated[playerId].value;
              const playerValueCleaned = playerValue.replace('_', '');
              const points = convertStringToPoints(event, playerValueCleaned);
              playersValue += isNaN(points) ? 0 : points;
            });

            const pointsStr = convertPointsToStringWithoutDelimeter(event, playersValue);

            const teamContinuousCount = dataHashMapUpdated[teamId].continuousCount;
            dataHashMapUpdated[teamId] = {
              ...dataHashMap[teamId],
              isError: false,
              value: pointsStr,
              teamPoints: playersValue
            };

            //only for sport points plain
            const regExpSportSign = getRegexpFromSportSign(event);

            if (isSportPointsDisplayPlain(event) && regExpSportSign.test(eventValue)) {
              this.textMaskInputElement[teamContinuousCount].update(pointsStr);
            } else {
              this.textMaskInputElement[teamContinuousCount].update(pointsStr);
            }
          }
        } else {
          dataHashMapUpdated[dataId] = {
            ...dataHashMap[dataId]
          };
        }
      }

      this.setState({
        dataHashMap: dataHashMapUpdated
      });

      this.textMaskInputElement[index].update(eventValue);
    }

    e.stopPropagation();
  }

  renderInput(index: number, id: string): React.ReactNode {
    const { dataHashMap } = this.state;
    const { event } = this.props;
    const mask = propz.get(event, ['sport', 'points', 'inputMask'], '');

    return (
      <input
        ref={el => (this.inputElement[index] = el)}
        onKeyDown={event => this.onKeyDown(event, index)}
        onChange={event => this.onChange(event, index, id)}
        onFocus={event => this.onFocus(event, index, id)}
        onBlur={event => this.onBlur(event, index, id)}
        placeholder={mask}
        className={
          dataHashMap[id].isError === true
            ? 'eTeamExternalSchoolsResultsInput mError'
            : 'eTeamExternalSchoolsResultsInput'
        }
        disabled={!dataHashMap[id].isInputEnabled}
      />
    );
  }

  onIndividualResultsChange = (teamId: string): void => {
    const { dataHashMap, mask } = this.state;
    const { event } = this.props;

    let dataHashMapUpdated: DataHashMap = { ...dataHashMap };

    const team = dataHashMap[teamId];

    if (team.isInputEnabled) {
      dataHashMapUpdated[teamId] = {
        ...team,
        oldValue: team.value,
        value: '',
        isInputEnabled: !team.isInputEnabled
      };
      dataHashMapUpdated[teamId].playerIds.forEach(playerId => {
        const player = dataHashMap[playerId];
        dataHashMapUpdated[playerId] = {
          ...player,
          value: '',
          isInputEnabled: !player.isInputEnabled
        };
        this.textMaskInputElement[player.continuousCount].update('');
      });

      if (isSportPointsDisplayPlain(event)) {
        this.textMaskInputElement[team.continuousCount].update('');
      } else {
        const { continuousCount } = dataHashMapUpdated[teamId];

        this.textMaskInputElement[continuousCount] = TextMaskCore.createTextMaskInputElement({
          inputElement: this.inputElement[continuousCount],
          mask: inputValue => getDefaultTimeOrDistanceMask(event, inputValue),
          showMask: false
        });

        this.textMaskInputElement[team.continuousCount].update('');
      }
    } else {
      dataHashMapUpdated[teamId] = {
        ...team,
        value: team.oldValue,
        isInputEnabled: !team.isInputEnabled
      };
      dataHashMapUpdated[teamId].playerIds.forEach(playerId => {
        const player = dataHashMap[playerId];
        dataHashMapUpdated[playerId] = {
          ...player,
          oldValue: player.value,
          value: '',
          isInputEnabled: !player.isInputEnabled
        };
        this.textMaskInputElement[player.continuousCount].update('');
      });
      if (isSportPointsDisplayPlain(event)) {
        this.textMaskInputElement[team.continuousCount].update(team.oldValue);
      } else {
        const { continuousCount } = dataHashMapUpdated[teamId];

        this.textMaskInputElement[continuousCount] = TextMaskCore.createTextMaskInputElement({
          inputElement: this.inputElement[continuousCount],
          mask: mask,
          showMask: false
        });

        this.textMaskInputElement[team.continuousCount].update(team.oldValue);
      }
    }

    this.setState({
      dataHashMap: dataHashMapUpdated
    });
  };

  render() {
    const { user, event, onCloseClick } = this.props;
    const { dataHashMap, participant } = this.state;
    const eventName = getEventGeneratedName(event, { user: user });
    const mask = propz.get(event, ['sport', 'points', 'inputMask'], '');
    const isEmptyMask = mask === '';

    let count = 0;
    const { team, school } = participant;
    const isTeamExist = typeof team !== 'undefined';

    let table;

    if (!isTeamExist) {
      count++;
      table = (
        <>
          {!isEmptyMask && (
            <div className="mb-2">
              <div>You are submitting results for:</div>
              <div className="font-weight-bold">{eventName}</div>
              <span>The input mask for this event is </span>
              <span className="font-weight-bold">{mask}</span>
            </div>
          )}
          <table className={'eTournamentResultsTable'}>
            <tbody>
              <tr>
                <td colSpan={3} className={'eTournamentResultsSchoolName'}>
                  {school.name}
                </td>
              </tr>
              <tr>
                <td colSpan={2} className={'eTournamentResultsCell'}>
                  Total:
                </td>
                <td className={'eTournamentResultsCell'}>{this.renderInput(count, school.id)}</td>
              </tr>
            </tbody>
          </table>
        </>
      );
    } else {
      count++;
      const { id: teamId, name, players } = team;

      const isPlayersExist = dataHashMap[teamId].playerIds.length !== 0;

      const hidePlayersListClasses = classNames({
        mHidden: dataHashMap[teamId].isInputEnabled
      });

      table = (
        <>
          {!isEmptyMask && (
            <div className="mb-2">
              <div>You are submitting results for:</div>
              <div className="font-weight-bold">{eventName}</div>
              <span>The input mask for this event is </span>
              <span className="font-weight-bold">{mask}</span>
            </div>
          )}
          <table className={'eTournamentResultsTable'}>
            <tbody>
              <tr>
                <td colSpan={3} className={'eTournamentResultsTeamName'}>
                  {`${name} / ${school.name}`}
                </td>
              </tr>
              <tr>
                <td colSpan={2} className={'eTournamentResultsCell'}>
                  Total:
                </td>
                <td className={'eTournamentResultsCell'}>{this.renderInput(count, teamId)}</td>
              </tr>
              {isPlayersExist && !isMultipartyEvent(event) && (
                <tr>
                  <td colSpan={3} className={'eTournamentResultsCell'}>
                    <Checkbox
                      value={!dataHashMap[teamId].isInputEnabled}
                      id={teamId}
                      onChange={this.onIndividualResultsChange}
                      labelText={'Individual score available'}
                      disabled={isMultipartyEvent(event)}
                    />
                  </td>
                </tr>
              )}

              {players.map(({ firstName, lastName, id }, playerIndex) => {
                count++;
                return (
                  <tr key={`teams_inter_teams_results_player_${playerIndex}`} className={hidePlayersListClasses}>
                    <td className={'eTournamentResultsCell'}>{playerIndex + 1}.</td>
                    <td className={'eTournamentResultsCell'}>{`${firstName} ${lastName}`}</td>
                    <td className={'eTournamentResultsCell'}>{this.renderInput(count, id)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      );
    }

    return (
      <div>
        {table}
        <Button text={'Cancel'} onClick={onCloseClick} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
        <Button text={'Save results'} onClick={this.onSubmit} customClass={'mt-3 mb-3'} />
      </div>
    );
  }
}
