import * as React from 'react';
import { FunctionComponent } from 'react';
import { getFullName } from 'Src/helpers/autocomplete/autocomplete';
import { Button } from '../Button/Button';

interface Props {
  eventTasks: any[];
  onEditEventJobClick: (e, index) => void;
  onDeleteJobClick: (e, index) => void;
}

export const SchoolEventJobsSummary: FunctionComponent<Props> = props => {
  const { eventTasks } = props;

  const rows = eventTasks.map((eventTask, index) => {
    const assignees = eventTask.assignees.map(assignee => getFullName(assignee)).join(',\n');

    return (
      <tr key={`jobs_summary_${index}`}>
        <td>{index + 1}</td>
        <td className="">{assignees}</td>
        <td className="">{eventTask.text}</td>
        <td>
          <Button
            text={'Edit job'}
            onClick={e => props.onEditEventJobClick(e, index)}
            customClass={'btn btn-primary mr-3 mb-3'}
          />
          <Button
            text={'Delete job'}
            onClick={e => props.onDeleteJobClick(e, index)}
            customClass={'btn btn-primary mr-3 mb-3'}
          />
        </td>
      </tr>
    );
  });

  const emptyRow = (
    <tr key={'events_empty_row'}>
      <td>No jobs</td>
    </tr>
  );

  return (
    <table className={'table table-sm'}>
      <thead>
        <tr>
          <th>#</th>
          <th>Players</th>
          <th>Job description</th>
          <th />
        </tr>
      </thead>
      <tbody>{rows.length > 0 ? rows : emptyRow}</tbody>
    </table>
  );
};

SchoolEventJobsSummary.displayName = 'SchoolEventJobsSummary';
