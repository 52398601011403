import * as React from 'react';
import { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import DatePicker from 'react-datepicker';
import {
  getReportDefaultGendersForMultiSelect,
  getDefaultReportAgesForMultiSelect
} from 'Src/helpers/multiselect/multiselect';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/report';
import { Switch } from 'Src/components/Switch/Switch';
import { LabelWithQuestionIcon } from 'Src/components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import { getStartDateForSchoolYear } from 'Src/helpers/calendar/calendar';
import { MultiSelectCheckboxes } from 'Src/components/MultiSelectCheckboxes/MultiSelectCheckboxes';
import { School } from 'Src/models/school';
import { SchoolForm } from '../../../../../../models/form';

interface Props {
  onCloseClick: () => void;
  onSubmit: (data: any, reportType: string) => void;
}

//report 057
export function UnionSchoolTargetCompletionReportForm(props: Props) {
  const { onCloseClick, onSubmit } = props;

  const now = new Date();
  let dateFrom = getStartDateForSchoolYear(now);
  const genders = getReportDefaultGendersForMultiSelect();
  const ages = getDefaultReportAgesForMultiSelect();

  const [isAgesAndGendersChecked, setIsAgesAndGendersChecked] = useState(true);

  const requestInitial = {
    dateFrom,
    dateTo: now,
    ageGroups: ages,
    genders,
    isShowCompleted: true,
    isShowUncompleted: true,
    isShowBlank: true,
    isPrintFullNames: false,
    isSelectAllAges: true
  };

  const onGendersChange = (index, values, setFieldValue) => {
    const genderItems = values.genders;
    const ageItems = values.ageGroups;

    const genderItemsUpdated = genderItems.map((genderItem, genderIndex) => {
      return genderIndex === index
        ? {
            ...genderItem,
            isChecked: !genderItem.isChecked
          }
        : genderItem;
    });

    const gendersChecked = genderItemsUpdated.map(gender => gender.isChecked);
    const agesChecked = ageItems.map(age => age.isChecked);
    const isCheckedGendersExist = gendersChecked.includes(true);
    const isCheckedAgesExist = agesChecked.includes(true);

    setIsAgesAndGendersChecked(isCheckedGendersExist && isCheckedAgesExist);
    setFieldValue('genders', genderItemsUpdated);
  };

  const onAgesChange = (index, values, setFieldValue) => {
    const ageItems = values.ageGroups;
    const genderItems = values.genders;

    const ageItemsUpdated = ageItems.map((ageItem, ageIndex) => {
      return ageIndex === index
        ? {
            ...ageItem,
            isChecked: !ageItem.isChecked
          }
        : ageItem;
    });

    const gendersChecked = genderItems.map(gender => gender.isChecked);
    const agesChecked = ageItemsUpdated.map(age => age.isChecked);
    const isCheckedGendersExist = gendersChecked.includes(true);
    const isCheckedAgesExist = agesChecked.includes(true);

    const isSelectAllAges = ageItemsUpdated.every(age => age.isChecked === true);

    setIsAgesAndGendersChecked(isCheckedAgesExist && isCheckedGendersExist);
    setFieldValue('isSelectAllAges', isSelectAllAges);
    setFieldValue('ageGroups', ageItemsUpdated);
  };

  const onSelectAllAgesChange = (event, values, setFieldValue) => {
    const isSelectAllAges = event.target.checked;
    const ageItems = values.ageGroups;
    const genderItems = values.genders;

    const ageItemsUpdated = ageItems.map(age => {
      return {
        ...age,
        isChecked: isSelectAllAges
      };
    });

    const gendersChecked = genderItems.map(gender => gender.isChecked);
    const agesChecked = ageItemsUpdated.map(age => age.isChecked);
    const isCheckedGendersExist = gendersChecked.includes(true);
    const isCheckedAgesExist = agesChecked.includes(true);

    setIsAgesAndGendersChecked(isCheckedAgesExist && isCheckedGendersExist);
    setFieldValue('isSelectAllAges', isSelectAllAges);
    setFieldValue('ageGroups', ageItemsUpdated);
  };

  return (
    <Formik
      onSubmit={values => onSubmit(values, REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.UNION_SCHOOL_TARGET_COMPLETION)}
      initialValues={requestInitial}
      render={({ values, setFieldValue }) => (
        <Form>
          <div className="mb-3 mt-3">
            {'A summary report of student performance on specific tasks or activities ' +
              'grouped by schools across the union. The report includes information on ' +
              'students that have completed their  targets across the selected activities ' +
              'within the selected period.'}
          </div>
          <div className="form-group">
            <label>Date from</label>
            <Field>
              {({ form }) => (
                <div className="mb-3">
                  <DatePicker
                    selected={form.values.dateFrom}
                    onChange={dateFrom => {
                      form.setFieldValue('dateFrom', dateFrom);
                      if (form.values.dateTo && form.values.dateTo < dateFrom) {
                        form.setFieldValue('dateTo', dateFrom);
                      }
                    }}
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
              )}
            </Field>
          </div>

          <div className="form-group">
            <label>Date to</label>
            <Field>
              {({ form }) => (
                <div className="mb-3">
                  <DatePicker
                    selected={form.values.dateTo}
                    onChange={dateTo => {
                      form.setFieldValue('dateTo', dateTo);
                    }}
                    minDate={form.values.dateFrom}
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
              )}
            </Field>
          </div>

          <div className={'mt-3 mb-3'}>
            <MultiSelectCheckboxes
              items={values.genders}
              cols={3}
              onChange={index => onGendersChange(index, values, setFieldValue)}
            />
          </div>

          <div className={'mt-3 mb-3'}>
            <MultiSelectCheckboxes
              items={values.ageGroups}
              cols={3}
              onChange={index => onAgesChange(index, values, setFieldValue)}
            />
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isSelectAllAges"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isSelectAllAges}
                    customClass="mb-2"
                    onChange={event => onSelectAllAgesChange(event, values, setFieldValue)}
                  />
                )}
              />

              <label htmlFor="isSelectAllAges" className="form-check-label pl-2">
                Select all ages
              </label>
            </div>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isShowCompleted"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowCompleted}
                    onChange={event => {
                      const isShowCompleted = event.target.checked;

                      setFieldValue('isShowCompleted', isShowCompleted);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show completed targets"
                hintText={
                  'This will allow you to view all the students who have been marked as having completed their targets.'
                }
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowCompleted"
              />
            </div>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isShowUncompleted"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowUncompleted}
                    onChange={event => {
                      const isShowUncompleted = event.target.checked;

                      setFieldValue('isShowUncompleted', isShowUncompleted);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show incomplete targets"
                hintText={
                  'This will allow you to view all the students who have not been marked as having completed their targets.'
                }
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowUncompleted"
              />
            </div>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isShowBlank"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowBlank}
                    onChange={event => {
                      const isShowBlank = event.target.checked;

                      setFieldValue('isShowBlank', isShowBlank);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show blank"
                hintText={
                  'Include or exclude schools that did not organise any sport events during the specified period'
                }
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowBlank"
              />
            </div>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isPrintFullNames"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintFullNames}
                    onChange={event => {
                      const isPrintFullNames = event.target.checked;

                      setFieldValue('isPrintFullNames', isPrintFullNames);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Print full names"
                hintText={
                  'Select if you would like to see full student names or just their first name and the first letter of the last name'
                }
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintFullNames"
              />
            </div>
          </div>

          <button type="button" className="btn btn-secondary mr-3" onClick={onCloseClick}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary" disabled={!isAgesAndGendersChecked}>
            Get
          </button>
        </Form>
      )}
    />
  );
}
