import * as React from 'react';
import * as propz from 'propz';
import * as Moment from 'moment';
import { SchoolEvent } from 'Src/models/event';
import { getActiveSchoolIdForParent, getEventNameForParent } from 'Src/helpers/accessor/accessor';
import { EVENT_STATUS_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/event';
import { getSignEventType, getEventResultForCalendar } from 'Src/helpers/calendar/calendar';
import { Child } from 'Src/models/child';
import { AppUser } from 'Src/views/App/App';

interface Props {
  user: AppUser;
  events: SchoolEvent[];
  children: Child[];
  onEventClick: (eventId: string) => void;
}

export function EventAndTournamentsTable(props: Props) {
  const { events, children, onEventClick } = props;

  const childIdList = children.map(child => child.id);

  return (
    <table className="eEventAndTournamentsTable table-striped">
      <thead>
        <tr className="eChallengeRowTitle">
          <th className="eChallengeColumnTitle">Child</th>
          <th className="eChallengeColumnTitle">Type</th>
          <th className="eChallengeColumnTitle">Event</th>
          <th className="eChallengeColumnTitle text-nowrap">Start time</th>
          <th className="eChallengeColumnTitle">Status</th>
          <th className="eChallengeColumnTitle">Result</th>
        </tr>
      </thead>
      <tbody>
        {events.map((event, index) => {
          const activeSchoolId = getActiveSchoolIdForParent(event, childIdList);
          const eventPlayers = propz.get(event, ['players'], []);
          const eventPlayersIds = eventPlayers.map(player => player.userId);
          const childrenInEvent = children.filter(child => {
            return eventPlayersIds.includes(child.id);
          });
          const childrenInEventAvatarUrls = childrenInEvent.map(child => child.avatar);

          return (
            <tr
              onClick={() => {
                onEventClick(event.id);
              }}
              key={`event_${index}`}
              className="eChallengeRowInfo"
            >
              <td className="eChallengeInfo">
                {childrenInEvent.map(child => {
                  const childAvatar = propz.get(child, ['avatar'], '');
                  const isAvatarUrlExist = childAvatar !== '';
                  const firstNameInitial = child.firstName.substring(0, 1);

                  return isAvatarUrlExist ? (
                    <div className="eChallengeInfoChildAvatar" style={{ backgroundImage: `url(${childAvatar})` }}></div>
                  ) : (
                    <div className="eChallengeInfoChildAvatar">{firstNameInitial}</div>
                  );
                })}
              </td>
              <td className="eChallengeInfo mChallengeInfoBlue">{getSignEventType(event)}</td>
              <td className="eChallengeInfo">{getEventNameForParent(event, childIdList)}</td>
              <td className="eChallengeInfo">{Moment(event.startTime).format('HH:mm')}</td>
              <td className="eChallengeInfo">{EVENT_STATUS_SERVER_TO_CLIENT_MAPPING[event.status]}</td>
              <td className="eChallengeInfo">{getEventResultForCalendar(event, activeSchoolId)}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
