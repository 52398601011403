export function uniqArray(arr: string[]): string[] {
  const obj = {};

  for (let i = 0; i < arr.length; i++) {
    const str = arr[i];
    obj[str] = true;
  }

  return Object.keys(obj);
}

export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function getRandomString() {
  return (Math.random() + 1).toString(36).substring(2);
}

export function isArraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) return false;

  for (let i = arr1.length; i--; ) {
    if (arr1[i] !== arr2[i]) return false;
  }

  return true;
}

export function getTodayMidnight() {
  return new Date(new Date().setHours(0, 0, 1)).toISOString();
}

export function convertStringToBoolean(value: string | boolean) {
  switch (typeof value) {
    case 'string':
      return value.toLowerCase() === 'true';
    default:
      return value;
  }
}
