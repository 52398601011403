import * as React from 'react';
import { CLUB_EVENT_EDIT_MODE } from '../../../../../../consts/event';

interface Props {
  editMode: string;
  onEditModeClick: (editMode: string) => void;
}

export function SchoolEventClubEventRadioButtons(props: Props) {
  const { editMode, onEditModeClick } = props;
  const isEditModeSingle = editMode === CLUB_EVENT_EDIT_MODE.SINGLE;
  const isEditModeGroup = editMode === CLUB_EVENT_EDIT_MODE.GROUP;
  return (
    <>
      <div className="">Would you like to change this event only or this and all following events in the series?</div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="single"
          id="single"
          value={CLUB_EVENT_EDIT_MODE.SINGLE}
          checked={isEditModeSingle}
          disabled={false}
          onChange={() => {
            onEditModeClick(CLUB_EVENT_EDIT_MODE.SINGLE);
          }}
        />
        <label className="form-check-label" htmlFor="single">
          Only this event
        </label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="group"
          id="group"
          value={CLUB_EVENT_EDIT_MODE.GROUP}
          checked={isEditModeGroup}
          disabled={false}
          onChange={() => {
            onEditModeClick(CLUB_EVENT_EDIT_MODE.GROUP);
          }}
        />
        <label className="form-check-label" htmlFor="group">
          Following events
        </label>
      </div>
    </>
  );
}
