import * as React from 'react';
import * as BPromise from 'bluebird';
import * as propz from 'propz';
import { AppUser } from 'Src/views/App/App';
import { parse } from 'query-string';
import { getOrder, getSearchFilter, getSearchOrder, getServerFieldSectionWhere } from 'Src/helpers/table/table';
import {
  ColumnDefinition,
  getFilters2,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { History, Location } from 'history';
import { Grid2 } from 'Src/components/Grid/Grid2';
import { Loader } from 'Src/components/Loader/Loader';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { getTournament } from 'Src/helpers/service/adminSU/tournamentsTable';
import { Tournament } from 'Src/models/tournament';
import {
  addSchoolTournamentTeam,
  editSchoolTournamentTeam,
  getAllSchoolTournamentTeams,
  getSchoolTournamentTeams,
  getSchoolTournamentTeamsCount,
  removeSchoolTournamentTeam
} from 'Src/helpers/service/admin/tournamentTeams';
import { getTeamAges, getTeamGender } from 'Src/helpers/accessor/accessor';
import { getSelectOptionForTournamentTeamAges, getSelectOptionForTournamentTeamGender } from 'Src/helpers/table/select';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { TEAM_TYPE } from 'Src/consts/team';
import { isIndividualTournament } from 'Src/helpers/tournament/tournament';
import { TournamentTeamForm } from './TournamentTeamForm/TournamentTeamForm';
import { TournamentTeam } from 'Src/models/tournamentTeam';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Name',
    field: 'name',
    isSort: false,
    type: FILTER_TYPE.TEXT,
    accessor: ['name']
  },
  {
    text: 'Gender',
    field: 'gender',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getTeamGender
  },
  {
    text: 'Age',
    field: 'teamAges',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getTeamAges
  }
];

interface State {
  items: TournamentTeam[];
  currentPage: number;
  selectedItems: TournamentTeam[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  filters: any;
  isDataFiltered: boolean;
  isLoading: boolean;
  total: number;
  basePath: string;
  isSelectAllChecked: boolean;
  tournament: Tournament;

  isAddTeamModalOpen: boolean;
  isEditTeamModalOpen: boolean;
  isDeleteTeamErrorModalOpen: boolean;
  isDeleteTeamConfirmationModalOpen: boolean;

  allItems: TournamentTeam[];
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export class TournamentTeams extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      filters: {},
      isDataFiltered: false,
      isLoading: true,
      total: 0,
      basePath: '',
      isSelectAllChecked: false,
      tournament: undefined,

      isAddTeamModalOpen: false,
      isEditTeamModalOpen: false,
      isDeleteTeamErrorModalOpen: false,
      isDeleteTeamConfirmationModalOpen: false,
      allItems: []
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setAdditionalItems().then(res => {
      this.setItems();
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  getTournamentId(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    const tournamentId = search.tournamentId;
    return tournamentId;
  }

  setAdditionalItems() {
    const { user } = this.props;

    const tournamentId = this.getTournamentId();

    return BPromise.all([getTournament(user, tournamentId), getAllSchoolTournamentTeams(user, tournamentId)]).then(
      ([tournament, allItems]) => {
        this.setState({
          tournament: tournament,
          allItems: allItems
        });

        return true;
      }
    );
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const tournamentId = this.getTournamentId();

    const getItemsPromise = getSchoolTournamentTeams(user, tournamentId, serverQueryFilter);
    const getItemsCountPromise = getSchoolTournamentTeamsCount(user, tournamentId, where);

    return BPromise.all([getItemsCountPromise, getItemsPromise]).then(([countObj, items]) => {
      this.setState({
        currentPage: page,
        sortDirection: sortDirection,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: items,
        total: countObj.count,
        basePath: history.location.pathname,
        isLoading: false
      });

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const tournamentId = this.getTournamentId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `tournamentId=${tournamentId}&${search.join('&')}`
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: nextSelectedItems,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    const tournamentId = this.getTournamentId();

    getAllSchoolTournamentTeams(user, tournamentId, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const nextSelectedItems = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: nextSelectedItems,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const tournamentId = this.getTournamentId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `tournamentId=${tournamentId}&${search.join('&')}`
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    const tournamentId = this.getTournamentId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `tournamentId=${tournamentId}&${search.join('&')}`
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    const tournamentId = this.getTournamentId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `tournamentId=${tournamentId}`
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  isOneItemSelected(): boolean {
    const selectedItems = this.state.selectedItems;
    return selectedItems.length === 1;
  }

  goBack = () => {
    const { history, location } = this.props;
    const { state } = location;
    const { search, isGoBackWithoutSave, prevRoutePath } = state as any;
    const tournamentId = isGoBackWithoutSave ? undefined : this.getTournamentId();

    history.push({
      pathname: prevRoutePath,
      search,
      state: { tournamentId }
    });
  };

  isAddTeamActive(): boolean {
    const { tournament, allItems, selectedItems } = this.state;

    const isSelectedItemsEmpty = selectedItems.length === 0;
    const totalItemsCount = allItems.length;

    let result;

    switch (true) {
      case isIndividualTournament(tournament):
        result = isSelectedItemsEmpty && totalItemsCount === 0;
        break;
      default:
        result = isSelectedItemsEmpty;
    }

    return result;
  }

  onDeleteTeamClick = (): void => {
    const { selectedItems: teams, total } = this.state;
    const teamsCount = teams.length;
    const diff = total - teamsCount;

    if (diff > 0) {
      this.setState({
        isDeleteTeamConfirmationModalOpen: true
      });
    } else {
      this.setState({
        isDeleteTeamErrorModalOpen: true
      });
    }
  };

  onEditTeamPlayersClick = () => {
    const { history, location } = this.props;
    const { selectedItems } = this.state;
    const { state } = location;
    const { prevRoutePath } = state as any;
    const team = selectedItems[0];
    const { id } = team;
    const tournamentId = this.getTournamentId();

    history.push({
      pathname: `/tournaments/teamPlayers/manage`,
      search: `teamId=${id}&tournamentId=${tournamentId}`,
      state: { prevRoutePath }
    });
  };

  renderGrid() {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isSelectAllChecked,
      currentPage,
      total,
      tournament
    } = this.state;

    const { isReadOnly } = tournament;

    const actionItems = [
      {
        itemText: 'Add team',
        onItemClick: this.onAddTeamClick,
        isActive: this.isAddTeamActive() && !isReadOnly
      },
      {
        itemText: 'Edit team',
        onItemClick: this.onEditTeamClick,
        isActive: selectedItems.length === 1 && !isReadOnly
      },
      {
        itemText: 'Edit participants',
        onItemClick: this.onEditTeamPlayersClick,
        isActive: selectedItems.length === 1 && !isReadOnly
      },
      {
        itemText: 'Allocate to events',
        onItemClick: this.onAllocateToEventsClick,
        isActive: selectedItems.length === 1
      },
      {
        itemText: selectedItems.length > 1 ? 'Delete teams' : 'Delete team',
        onItemClick: this.onDeleteTeamClick,
        isActive: selectedItems.length > 0 && !isReadOnly
      }
    ];

    const filterOptions = {
      gender: getSelectOptionForTournamentTeamGender(),
      teamAges: getSelectOptionForTournamentTeamAges(tournament)
    };
    const gridTitle = typeof tournament !== 'undefined' ? `${tournament.name} / Teams` : '';

    const { user } = this.props;

    return (
      <Grid2
        dataItems={items}
        filters={filters}
        currentPage={currentPage}
        total={total}
        isSelectAllChecked={isSelectAllChecked}
        isDataFiltered={isDataFiltered}
        sortDirection={sortDirection}
        sortColumnsName={sortColumnsName}
        isShowFilter={isShowFilter}
        dataItemsSelected={selectedItems}
        columns={COLUMNS}
        actionItems={actionItems}
        options={filterOptions}
        onItemClick={this.onItemClick}
        onSortClick={this.onTableSortClick}
        onApplyFilterClick={this.onApplyFilterClick}
        onClearFilterClick={this.onClearFilterClick}
        onTableFilterChange={this.onTableFilterChange}
        onTableFilterClick={this.onTableFilterClick}
        setCurrentPageParams={this.setCurrentPageParams}
        onSelectAllClick={this.onSelectAllClick}
        onSelectAllOnPageClick={this.onSelectAllOnPageClick}
        onUnselectAllClick={this.onUnselectAllClick}
        gridTitle={gridTitle}
        goBack={this.goBack}
        user={user}
      />
    );
  }

  onAddTeamFormCancel = (): void => {
    this.setState({
      isAddTeamModalOpen: false
    });
  };

  onAddTeamClick = (): void => {
    this.setState({
      isAddTeamModalOpen: true
    });
  };

  onEditTeamFormCancel = (): void => {
    this.setState({
      isEditTeamModalOpen: false
    });
  };

  onEditTeamClick = (): void => {
    this.setState({
      isEditTeamModalOpen: true
    });
  };

  onAddTeamFormSubmit = (data: any) => {
    const { name } = data;
    const { tournament } = this.state;
    const { user } = this.props;
    const { activeSchoolId: schoolId } = user;

    const { sportId } = tournament;
    const tournamentId = this.getTournamentId();

    this.setState({
      isLoading: true,
      isAddTeamModalOpen: false
    });

    let teamData;

    switch (true) {
      case isIndividualTournament(tournament): {
        const { gender, ages } = tournament;
        teamData = {
          name,
          ages,
          gender,
          sportId,
          schoolId,
          teamType: TEAM_TYPE.ADHOC,
          players: []
        };
        break;
      }

      default: {
        const { age, gender } = data;
        teamData = {
          name,
          gender,
          sportId,
          schoolId,
          teamType: TEAM_TYPE.ADHOC,
          players: [],
          ages: [Number(age)]
        };
      }
    }

    addSchoolTournamentTeam(user, tournamentId, teamData).then(res => {
      this.setAdditionalItems().then(res => {
        this.setItems();
      });
    });
  };

  onEditTeamFormSubmit = (data: any) => {
    const { name } = data;
    const { user } = this.props;
    const tournamentId = this.getTournamentId();

    const { selectedItems, tournament } = this.state;
    const team = selectedItems[0];
    const { id: teamId } = team;

    this.setState({
      isLoading: true,
      isEditTeamModalOpen: false
    });

    let teamData;

    switch (true) {
      case isIndividualTournament(tournament): {
        teamData = { name };
        break;
      }

      default: {
        const { age, gender } = data;
        teamData = {
          name,
          gender,
          ages: [Number(age)]
        };
      }
    }

    editSchoolTournamentTeam(user, tournamentId, teamId, teamData).then(team => {
      this.setState({
        isLoading: false,
        selectedItems: [team]
      });
      this.setAdditionalItems().then(res => {
        this.setItems();
      });
    });
  };

  onAllocateToEventsClick = () => {
    const { location } = this.props;
    const { state } = location;
    const search = propz.get(state, ['search'], '');
    const prevRoutePath = propz.get(state, ['prevRoutePath'], '');
    const { selectedItems } = this.state;
    const team = selectedItems[0];
    const { id, name } = team;
    const tournamentId = this.getTournamentId();

    this.props.history.push({
      pathname: `/tournaments/teamPlayers`,
      search: `teamId=${id}&tournamentId=${tournamentId}`,
      state: { search, teamName: name, prevRoutePath }
    });
  };

  renderAddTeamModal(): React.ReactNode {
    const { isAddTeamModalOpen, tournament, allItems } = this.state;
    const { user } = this.props;

    return (
      <SimpleModal isOpen={isAddTeamModalOpen}>
        <TournamentTeamForm
          tournament={tournament}
          onSubmit={this.onAddTeamFormSubmit}
          onCancel={this.onAddTeamFormCancel}
          user={user}
          allSchoolTeams={allItems}
        />
      </SimpleModal>
    );
  }

  renderEditTeamModal(): React.ReactNode {
    const { isEditTeamModalOpen, tournament, allItems, selectedItems } = this.state;
    const { user } = this.props;
    const team = selectedItems[0];

    return (
      <SimpleModal isOpen={isEditTeamModalOpen}>
        <TournamentTeamForm
          tournament={tournament}
          onSubmit={this.onEditTeamFormSubmit}
          onCancel={this.onEditTeamFormCancel}
          user={user}
          team={team}
          allSchoolTeams={allItems}
        />
      </SimpleModal>
    );
  }

  onCloseDeleteTeamErrorModalClick = (): void => {
    this.setState({
      isDeleteTeamErrorModalOpen: false
    });
  };

  renderDeleteTeamErrorModal(): React.ReactNode {
    const { isDeleteTeamErrorModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isDeleteTeamErrorModalOpen}
        title={'Error'}
        onButtonClick={this.onCloseDeleteTeamErrorModalClick}
        body={`You only have one team in this event and therefore can't remove it, if you would like to withdraw your school from the tournament then please go to the relevant competition in the tournament list and select 'Leave the tournament'`}
        buttonText={'Close'}
      />
    );
  }

  onCloseDeleteTeamConfirmationModalClick = (): void => {
    this.setState({
      isDeleteTeamConfirmationModalOpen: false
    });
  };

  onDeleteTeamModalClick = (): void => {
    const { selectedItems: teams } = this.state;
    const { user } = this.props;
    const tournamentId = this.getTournamentId();

    BPromise.all(teams.map(team => removeSchoolTournamentTeam(user, tournamentId, team.id))).then(res => {
      this.setState({
        isDeleteTeamConfirmationModalOpen: false,
        selectedItems: [],
        isSelectAllChecked: false
      });
      this.setAdditionalItems().then(res => {
        this.setItems();
      });
    });
  };

  renderDeleteTeamConfirmationModal(): React.ReactNode {
    const { isDeleteTeamConfirmationModalOpen } = this.state;
    const { selectedItems: teams } = this.state;

    return (
      <SimpleModal
        isOpen={isDeleteTeamConfirmationModalOpen}
        title={'Confirmation'}
        onButtonClick={this.onDeleteTeamModalClick}
        body={`Are you sure you want to remove this ${teams.length === 1 ? 'team' : 'teams'} from tournament?`}
        buttonText={'Remove'}
        onCloseClick={this.onCloseDeleteTeamConfirmationModalClick}
      />
    );
  }

  render() {
    const {
      isLoading,
      isAddTeamModalOpen,
      isDeleteTeamErrorModalOpen,
      isDeleteTeamConfirmationModalOpen,
      isEditTeamModalOpen
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes =
      isAddTeamModalOpen || isDeleteTeamErrorModalOpen || isDeleteTeamConfirmationModalOpen || isEditTeamModalOpen
        ? 'mt-3 modal-open'
        : 'mt-3';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderAddTeamModal()}
        {this.renderEditTeamModal()}
        {this.renderDeleteTeamErrorModal()}
        {this.renderDeleteTeamConfirmationModal()}
        <div className="row">
          <div className="col-md-12">{this.renderGrid()}</div>
        </div>
      </div>
    );
  }
}
