import * as React from 'react';
import { Component } from 'react';
import { AppUser } from 'Src/views/App/App';
import './SchoolEventTabs.scss';
import { GenericSummary } from 'Src/components/GenericSummary/GenericSummary';
import { getEventPlayers, getEventViewTabs } from 'Src/helpers/event/event';
import { EmptyComponent } from 'Src/components/EmptyComponent/EmptyComponent';
import { TABS } from 'Src/consts/common';
import { SchoolEventDetailsSummary } from 'Src/components/SchoolEventDetailsSummary/SchoolEventDetailsSummary';
import { SchoolEventJobsSummary } from 'Src/components/SchoolEventJobsSummary/SchoolEventJobsSummary';
import { SchoolEventPerformanceSummary } from 'Src/components/SchoolEventPerformanceSummary/SchoolEventPerformanceSummary';
import { SchoolEventAttendanceSummary } from 'Src/components/SchoolEventAttendance/SchoolEventAttendanceSummary';
import {
  createEventAttendance,
  createEventPerformance,
  getSchoolEventDetails,
  onDeleteJob,
  updateEventAttendance,
  updateEventPerformance,
  updateSchoolEventDetails
} from 'Src/helpers/service/admin/schoolEvents';
import { Loader } from 'Src/components/Loader/Loader';
import { createEventTasks, editEventTasks, getEventTasks, getSchoolEvent } from 'Src/helpers/service/admin/event';
import { Button } from 'Src/components/Button/Button';
import * as propz from 'propz';
import * as BPromise from 'bluebird';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { SchoolEventJobForm } from '../SchoolEventJobForm/SchoolEventJobForm';
import { SchoolEventDetailsForm } from '../SchoolEventDetailsForm/SchoolEventDetailsForm';
import { SchoolEventTeamsTab } from './SchoolEventTeamsTab';
import { isClubEvent, isHousesEvent, isNonTeamSportEvent } from '../../../../../../helpers/event/event';
import { History } from 'history';
import { EventGallery } from './Gallery/Gallery';
import { Video } from '../../../../../../models/video';
import { Photo } from '../../../../../../models/photo';
import { ACCESS_PRESET } from '../../../../../../consts/album';
import { getKey } from 'Src/helpers/service/nobody/key';
import { getVideoConfig } from 'Src/helpers/service/nobody/config';

import * as AWS from 'aws-sdk';
import { EventVideos } from './Videos/EventVideos';
import { addConvertJob } from 'Src/helpers/service/nobody/aws';
import {
  addEventPhoto,
  deleteEventPhoto,
  getAllEventPhotos,
  updateEventPhoto
} from 'Src/helpers/service/admin/eventPhotos';
import {
  addEventVideo,
  deleteEventVideo,
  getAllEventVideos,
  updateEventVideo
} from 'Src/helpers/service/admin/eventVideos';
import { ACCESS_PRESET_TYPE } from '../../../../../../types/album';
import { SchoolEventMatchReportSummary } from '../../../../../../components/SchoolEventMatchReportSummary/SchoolEventMatchReportSummary';
import {
  createEventDiscipline,
  getSchoolEventMatchReport,
  updateEventDiscipline,
  updateGroupSchoolEventDetails,
  updateSchoolEventMatchReport
} from '../../../../../../helpers/service/admin/schoolEvents';
import { uploadImage } from '../../../../../../helpers/service/image';
import { getSafeFileName } from '../../../../../../helpers/file/file';
import {
  doGotItActionForEventMessage,
  getAvailabilityReports,
  getParentalConsent
} from '../../../../../../helpers/service/admin/messages';
import { SchoolEventAvailabilityReportsSummary } from '../../../../../../components/SchoolEventAvailabilityReportsSummary/SchoolEventAvailabilityReportsSummary';
import { VenueView } from '../../../../../../components/VenueView/VenueView';
import { Report } from '../../../../../../models/report';
import { Point } from '../../../../../../models/venue';
import { SchoolParentalConsentSummary } from '../../../../../../components/SchoolParentalConsentSummary/SchoolParentalConsentSummary';
import { getPlayers } from '../../../../../../helpers/team/team';
import {
  SchoolEventPlayersSummary,
  SchoolEventPlayerSummary,
  SchoolEventTeamSummary
} from '../../../../../../components/SchoolEventPlayersSummary/SchoolEventPlayersSummary';
import { Sport } from '../../../../../../models/sport';
import { updateSchoolEventTeamPlayersBatch } from '../../../../../../helpers/service/admin/event';
import { getSchoolSports } from '../../../../../../helpers/service/admin/user';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../../../../consts/table';
import { CLUB_EVENT_EDIT_MODE } from '../../../../../../consts/event';
import { updateSchoolEventIndividualsBatch } from '../../../../../../helpers/service/admin/schoolEventIndividuals';
import {
  Participant,
  SchoolEvent,
  SchoolEventAttendance,
  SchoolEventIndividualData,
  SchoolEventIndividualDiscipline,
  SchoolEventIndividualPerformance,
  SchoolEventTeamDataPlayer
} from '../../../../../../models/event';
import { SchoolEventDisciplineSummary } from '../../../../../../components/SchoolEventDisciplineSummary/SchoolEventDisciplineSummary';
import { changeSchoolPhotoInfo } from 'Src/helpers/service/admin/schoolGallery';

interface Props {
  user: AppUser;
  history: History;
  eventInitial: SchoolEvent;
  participants?: Participant[];
  reloadEvent: (tabIndex: number) => void;
  tabIndex: number;
  onRemoveParticipantClick: (participant: Participant, isOneParticipant: boolean) => void;
  onChangeSchoolClick: (participant: Participant) => void;
}

interface State {
  currentTabIndex: number;
  eventTaskIndex: number;
  eventDetails: any;
  isLoading: boolean;
  isEditPerformance: boolean;
  isEditDiscipline: boolean;
  isEditAttendance: boolean;
  isEditMatchReportActive: boolean;
  isEditEventDetailsModalOpen: boolean;
  isCreateEventJobModalOpen: boolean;
  isDeleteJobModalOpen: boolean;
  isErrorModalOpen: boolean;
  isVenueFormOpen: boolean;
  errorMessage: string;
  eventTasks: any[];
  reports: Report[];
  consents: Report[];
  point: Point;
  individualPerformance: SchoolEventIndividualPerformance[];
  individualDiscipline: SchoolEventIndividualDiscipline[];
  attendancePlayers: SchoolEventAttendance[];
  event: SchoolEvent;
  photos: Photo[];
  videos: Video[];
  isUpdateAccessPreset: boolean;
  matchReport: string;
  sports: Sport[];
  playersForSummary: SchoolEventPlayerSummary[];
  teamsForSummary: SchoolEventTeamSummary[];
  isEditPositions: boolean;
  isEditAllAttendance: boolean;
  activeTeamIndex: number;
}

export class SchoolEventTabs extends Component<Props, State> {
  photoFileInputRef: any;
  videoFileInputRef: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      currentTabIndex: props.tabIndex || 0,
      eventTaskIndex: 0,
      eventDetails: undefined,
      isLoading: true,
      isEditPerformance: false,
      isEditDiscipline: false,
      isEditAttendance: false,
      isEditMatchReportActive: true,
      isEditEventDetailsModalOpen: false,
      isCreateEventJobModalOpen: false,
      isDeleteJobModalOpen: false,
      isVenueFormOpen: false,
      eventTasks: [],
      reports: [],
      consents: [],
      point: undefined,
      individualPerformance: [],
      individualDiscipline: [],
      attendancePlayers: [],
      isErrorModalOpen: false,
      errorMessage: '',
      event: props.eventInitial,
      photos: [],
      videos: [],
      isUpdateAccessPreset: false,
      matchReport: '',
      sports: [],
      playersForSummary: [],
      teamsForSummary: [],
      isEditPositions: false,
      isEditAllAttendance: false,
      activeTeamIndex: 0
    };

    this.photoFileInputRef = React.createRef();
    this.videoFileInputRef = React.createRef();
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setItems();
  }

  setItems() {
    const { currentTabIndex, event, reports, consents } = this.state;
    const filter = { limit: DEFAULT_LIMIT, order: 'name ASC', skip: DEFAULT_SKIP };
    const isExistReports = reports.length > 0;
    const isExistConsents = consents.length > 0;
    const { user } = this.props;
    const tabs = getEventViewTabs(event, isExistReports, isExistConsents);
    const currentTab = tabs[currentTabIndex];
    const { id } = event;
    const isCurrentTabDetails = currentTab === TABS.DETAILS;
    const sportsPromise = getSchoolSports(user, filter);

    const getAvailabilityReportsPromise = getAvailabilityReports(user, event.id);
    const getParentalConsentPromise = getParentalConsent(user, event.id);
    const getSchoolEventDetailsPromise = isCurrentTabDetails ? getSchoolEventDetails(user, id) : [];
    const promises = [
      getAvailabilityReportsPromise,
      getParentalConsentPromise,
      getSchoolEventDetailsPromise,
      sportsPromise
    ];

    BPromise.all(promises)
      .then(([reports, consents, details, sports]) => {
        const allConsents = this.getMessagesSortedAsWellAsTeam(consents);
        this.setState({
          eventDetails: details,
          reports: reports,
          consents: allConsents,
          sports: sports,
          isLoading: false
        });
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        console.error(error);
        this.setState({
          isLoading: false,
          errorMessage: errorText,
          isErrorModalOpen: true
        });
      });
  }

  getMessagesSortedAsWellAsTeam(consents) {
    const { event } = this.state;
    const { user } = this.props;
    const { activeSchoolId } = user;

    const teamPlayers = getPlayers(activeSchoolId, event);
    const msgPlayers = consents.map(msg => msg.playerDetails);
    //sorts an array of messages in the same order as the players in the team
    let messagesSortedAsWellAsTeam = [];

    teamPlayers.forEach(teamPlayer => {
      const index = msgPlayers.findIndex(
        msgPlayer => teamPlayer.userId === msgPlayer.userId && teamPlayer.permissionId === msgPlayer.permissionId
      );

      if (index === -1) {
        //not notified players
        messagesSortedAsWellAsTeam.push({
          playerDetails: {
            userId: teamPlayer.userId,
            permissionId: teamPlayer.permissionId
          },
          playerDetailsData: teamPlayer,
          schoolId: activeSchoolId,
          invitationStatus: 'NOT_SEND'
        });
      } else {
        messagesSortedAsWellAsTeam.push(consents[index]);
      }
    });

    return messagesSortedAsWellAsTeam;
  }

  getInitAttendancePlayers = attendancePlayers => {
    const { user } = this.props;
    const { activeSchoolId } = user;
    const { event } = this.state;
    const allTeamPlayers = getEventPlayers(event, activeSchoolId);
    const nextAttendancePlayers = allTeamPlayers.map(player => {
      const attendanceIndex = attendancePlayers.findIndex(attendance => attendance.userId === player.userId);
      if (attendanceIndex === -1) {
        return {
          userId: player.userId,
          isAttendance: false,
          permissionId: player.permissionId
        };
      } else {
        const allAttendancePlayers = attendancePlayers.map(attendance => ({ ...attendance }));
        return allAttendancePlayers[attendanceIndex];
      }
    });
    return nextAttendancePlayers;
  };

  onTabClick = (e, index: number) => {
    if (typeof e !== 'undefined') {
      e.preventDefault();
    }

    const { user } = this.props;
    const {
      isEditPerformance,
      isEditAttendance,
      event,
      reports,
      consents,
      isEditPositions,
      isEditDiscipline
    } = this.state;
    const { id, results } = event;
    const isExistReports = reports.length > 0;
    const isExistConsents = consents.length > 0;

    const tabs = getEventViewTabs(event, isExistReports, isExistConsents);
    const currentTab = tabs[index];

    if (isEditPerformance || isEditAttendance || isEditPositions || isEditDiscipline) {
      return;
    }

    const { individualPerformance, attendance, individualDiscipline } = results;
    const attendancePlayers = attendance.map(player => ({ ...player }));

    switch (currentTab) {
      case TABS.DETAILS: {
        this.setState({
          isLoading: true
        });
        getSchoolEventDetails(user, id).then(details => {
          this.setState({
            currentTabIndex: index,
            eventDetails: details,
            isLoading: false
          });
        });
        break;
      }
      case TABS.JOBS: {
        this.setState({
          isLoading: true
        });
        getEventTasks(user, id).then(eventTasks => {
          this.setState({
            currentTabIndex: index,
            eventTasks: eventTasks,
            isLoading: false
          });
        });
        break;
      }

      case TABS.POSITIONS:
        const playersForSummary = this.getPlayersForSummary(event);
        const teamsForSummary = this.getTeamsForSummary();
        this.setState({
          currentTabIndex: index,
          playersForSummary: playersForSummary,
          teamsForSummary: teamsForSummary
        });
        break;

      case isClubEvent(event) ? TABS.PROGRESS : TABS.PERFORMANCE: {
        this.setState({
          currentTabIndex: index,
          individualPerformance: individualPerformance
        });
        break;
      }

      case TABS.DISCIPLINE: {
        this.setState({
          currentTabIndex: index,
          individualDiscipline: individualDiscipline
        });
        break;
      }

      case TABS.ATTENDANCE: {
        this.setState({
          currentTabIndex: index,
          attendancePlayers: attendancePlayers
        });
        break;
      }

      case TABS.GALLERY: {
        this.setState({
          isLoading: true
        });
        const promises = [getAllEventPhotos(user, id), getAllEventVideos(user, id)];
        BPromise.all(promises).then(([photos, videos]) => {
          this.setState({
            isLoading: false,
            currentTabIndex: index,
            photos,
            videos
          });
        });
        break;
      }
      case isClubEvent(event) ? TABS.LEADER_COACH_NOTES : TABS.MATCH_REPORT:
        this.setState({
          isLoading: true
        });
        getSchoolEventMatchReport(user, id).then(matchReport => {
          const content = propz.get(matchReport, ['content'], '');
          this.setState({
            currentTabIndex: index,
            matchReport: content,
            isLoading: false
          });
        });
        break;
      default:
        this.setState({
          currentTabIndex: index
        });
    }
  };

  onClickGotIt = messageId => {
    const { user } = this.props;

    this.setState({
      isLoading: true
    });

    doGotItActionForEventMessage(user, messageId)
      .then(res => {
        this.setState({
          isLoading: false
        });
        this.setItems();
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        console.error(error);
        this.setState({
          isLoading: false,
          errorMessage: errorText,
          isErrorModalOpen: true
        });
      });
  };

  onEditEventJobClick = (event, index) => {
    event.preventDefault();

    this.setState({
      isCreateEventJobModalOpen: true,
      eventTaskIndex: index
    });
  };

  deleteJobModalOpen = (event, index): void => {
    this.setState({
      isDeleteJobModalOpen: true,
      eventTaskIndex: index
    });
  };

  deleteJobModalClose = (): void => {
    this.setState({
      isDeleteJobModalOpen: false
    });
  };

  onDeleteJobClick = () => {
    const { user } = this.props;
    const { eventTasks, event, eventTaskIndex, reports, consents } = this.state;
    const { id } = event;
    const eventTaskId = eventTasks[eventTaskIndex].id;
    const isExistReports = reports.length > 0;
    const isExistConsents = consents.length > 0;

    this.setState({
      isDeleteJobModalOpen: false,
      isLoading: true
    });

    onDeleteJob(user, id, eventTaskId)
      .then(res => getEventTasks(user, id))
      .then(eventTasks => {
        const eventTab = getEventViewTabs(event, isExistReports, isExistConsents);
        const tabIndex = eventTab.indexOf(TABS.JOBS);
        this.setState({
          currentTabIndex: tabIndex,
          eventTasks: eventTasks,
          isLoading: false
        });
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        console.error(error);
        this.setState({
          isLoading: false,
          errorMessage: errorText,
          isErrorModalOpen: true
        });
      });
  };

  onChangeEventPerformanceClick = (value, player, performanceId) => {
    const { individualPerformance } = this.state;

    const performanceIndex = individualPerformance.findIndex(
      performance => performance.performanceId === performanceId && performance.userId === player.userId
    );

    let nextPerformance;

    if (performanceIndex === -1) {
      nextPerformance = [
        ...individualPerformance,
        {
          performanceId: performanceId,
          userId: player.userId,
          teamId: player.teamId,
          value: value,
          permissionId: player.permissionId
        }
      ];
    } else {
      nextPerformance = [...individualPerformance];
      nextPerformance[performanceIndex].value = value;
    }

    this.setState({
      individualPerformance: nextPerformance
    });
  };

  onChangeEventDisciplineClick = (value, player, disciplineId) => {
    const { individualDiscipline } = this.state;

    const disciplineIndex = individualDiscipline.findIndex(
      discipline => discipline.disciplineId === disciplineId && discipline.userId === player.userId
    );

    let nextDiscipline;

    if (disciplineIndex === -1) {
      nextDiscipline = [
        ...individualDiscipline,
        {
          disciplineId: disciplineId,
          userId: player.userId,
          teamId: player.teamId,
          value: Number(value),
          permissionId: player.permissionId
        }
      ];
    } else {
      nextDiscipline = [...individualDiscipline];
      nextDiscipline[disciplineIndex].value = Number(value);
    }

    this.setState({
      individualDiscipline: nextDiscipline
    });
  };

  onChangeMatchReport = matchReport => {
    this.setState({
      matchReport: matchReport
    });
  };

  onChangeEventAllAttendanceClick = (value: boolean): void => {
    const { user } = this.props;
    const { activeSchoolId } = user;
    const { attendancePlayers, event } = this.state;
    const allTeamPlayers = getEventPlayers(event, activeSchoolId);

    const nextAttendancePlayers = allTeamPlayers.map(player => {
      const attendanceIndex = attendancePlayers.findIndex(attendance => attendance.userId === player.userId);
      if (attendanceIndex === -1) {
        return {
          userId: player.userId,
          isAttendance: value,
          permissionId: player.permissionId
        };
      } else {
        const allAttendancePlayers = attendancePlayers.map(attendance => {
          return {
            ...attendance,
            isAttendance: value
          };
        });
        return allAttendancePlayers[attendanceIndex];
      }
    });
    this.setState({
      isEditAllAttendance: value,
      attendancePlayers: nextAttendancePlayers
    });
  };

  onChangeEventAttendanceClick = (
    value: boolean,
    player: SchoolEventIndividualData | SchoolEventTeamDataPlayer
  ): void => {
    const { attendancePlayers } = this.state;

    const attendanceIndex = attendancePlayers.findIndex(attendance => attendance.userId === player.userId);

    let nextAttendancePlayers;

    if (attendanceIndex === -1) {
      nextAttendancePlayers = [
        ...attendancePlayers,
        {
          userId: player.userId,
          isAttendance: value,
          permissionId: player.permissionId
        }
      ];
    } else {
      nextAttendancePlayers = attendancePlayers.map(attendance => {
        return attendance.userId === player.userId
          ? {
              ...attendance,
              isAttendance: value
            }
          : { ...attendance };
      });
    }

    const isOneAttendancePresent = nextAttendancePlayers.every(player => {
      return propz.get(player, ['isAttendance'], true);
    });

    this.setState({
      isEditAllAttendance: isOneAttendancePresent,
      attendancePlayers: nextAttendancePlayers
    });
  };

  onChangeSchoolPhotoInfoClick = (photoId, data) => {
    const { user, eventInitial } = this.props;
    const { photos } = this.state;
    const { id } = eventInitial;

    this.setState({
      isUpdateAccessPreset: true
    });

    updateEventPhoto(user, id, photoId, data).then(photoNext => {
      const photosNext = photos.map(photo => (photoId === photo.id ? photoNext : photo));
      this.setState({
        photos: photosNext,
        isUpdateAccessPreset: false
      });
    });
  };

  getContentTabs(): React.ReactNode {
    const { user, history, onRemoveParticipantClick, onChangeSchoolClick, participants, eventInitial } = this.props;
    const {
      currentTabIndex,
      eventDetails,
      isEditPerformance,
      isEditAttendance,
      isEditMatchReportActive,
      eventTasks,
      attendancePlayers,
      individualPerformance,
      matchReport,
      event,
      photos,
      videos,
      isUpdateAccessPreset,
      reports,
      consents,
      sports,
      activeTeamIndex,
      playersForSummary,
      teamsForSummary,
      isEditPositions,
      isEditAllAttendance,
      individualDiscipline,
      isEditDiscipline
    } = this.state;

    const { activeSchoolId } = user;
    const isExistReports = reports.length > 0;
    const isExistConsents = consents.length > 0;

    const tabs = getEventViewTabs(event, isExistReports, isExistConsents);
    const currentTab = tabs[currentTabIndex];

    const allTeamPlayers = getEventPlayers(event, activeSchoolId);

    const currentSport = sports.find(sport => sport.id === event.sportId);

    switch (currentTab) {
      case TABS.DETAILS:
        return <SchoolEventDetailsSummary schoolEvent={eventInitial} eventsDetails={eventDetails} user={user} />;
      case TABS.POSITIONS:
        return (
          <SchoolEventPlayersSummary
            schoolEvent={event}
            sport={currentSport}
            players={playersForSummary}
            teams={teamsForSummary}
            activeTeamIndex={activeTeamIndex}
            isEditPositions={isEditPositions}
            onChangeTeam={this.onChangeTeam}
            onChangePositionPlayer={this.onChangePositionPlayer}
            onChangeIsCaptainPlayer={this.onChangeIsCaptainPlayer}
            onChangeIsSubPlayer={this.onChangeIsSubPlayer}
          />
        );
      case TABS.JOBS:
        return (
          <SchoolEventJobsSummary
            eventTasks={eventTasks}
            onEditEventJobClick={this.onEditEventJobClick}
            onDeleteJobClick={this.deleteJobModalOpen}
          />
        );
      case isClubEvent(event) ? TABS.PROGRESS : TABS.PERFORMANCE:
        return (
          <SchoolEventPerformanceSummary
            schoolEvent={event}
            allTeamPlayers={allTeamPlayers}
            onEditEventPerformanceClick={this.onChangeEventPerformanceClick}
            individualPerformance={individualPerformance}
            isEditPerformance={isEditPerformance}
          />
        );
      case TABS.DISCIPLINE:
        return (
          <SchoolEventDisciplineSummary
            schoolEvent={event}
            allTeamPlayers={allTeamPlayers}
            onEditEventDisciplineClick={this.onChangeEventDisciplineClick}
            individualDiscipline={individualDiscipline}
            isEditDiscipline={isEditDiscipline}
          />
        );
      case TABS.ATTENDANCE:
        return (
          <SchoolEventAttendanceSummary
            schoolEvent={event}
            allTeamPlayers={allTeamPlayers}
            onChangeEventAttendanceClick={this.onChangeEventAttendanceClick}
            onChangeEventAllAttendanceClick={this.onChangeEventAllAttendanceClick}
            attendancePlayers={attendancePlayers}
            isEditAttendance={isEditAttendance}
            isEditAllAttendance={isEditAllAttendance}
          />
        );
      case TABS.PARTICIPANTS:
      case TABS.PLAYERS:
        return (
          <SchoolEventTeamsTab
            onRemoveParticipantClick={onRemoveParticipantClick}
            onChangeSchoolClick={onChangeSchoolClick}
            history={history}
            user={user}
            participants={participants}
            eventInitial={event}
          />
        );
      case TABS.GALLERY:
        return (
          <>
            <EventGallery
              onDeletePhotoClick={this.onDeletePhotoClick}
              user={user}
              eventInitial={event}
              photos={photos}
              onAccessPresetClick={this.onAccessPresetPhotoClick}
              isUpdateAccessPreset={isUpdateAccessPreset}
              onChangeSchoolPhotoInfoClick={this.onChangeSchoolPhotoInfoClick}
            />
            <EventVideos
              onDeleteVideoClick={this.onDeleteVideoClick}
              user={user}
              eventInitial={event}
              videos={videos}
              onAccessPresetClick={this.onAccessPresetVideoClick}
              isUpdateAccessPreset={isUpdateAccessPreset}
            />
          </>
        );
      case isClubEvent(event) ? TABS.LEADER_COACH_NOTES : TABS.MATCH_REPORT:
        return (
          <SchoolEventMatchReportSummary
            isEditMatchReportActive={isEditMatchReportActive}
            schoolEvent={event}
            matchReport={matchReport}
            onChangeMatchReport={this.onChangeMatchReport}
          />
        );
      case TABS.AVAILABILITY_REPORTS:
        return (
          <SchoolEventAvailabilityReportsSummary
            reports={reports}
            user={user}
            onClickGotIt={this.onClickGotIt}
            onViewMapClick={this.onViewMapClick}
          />
        );
      case TABS.PARENTAL_CONSENT:
        return (
          <SchoolParentalConsentSummary
            consents={consents}
            schoolEvent={event}
            user={user}
            onViewMapClick={this.onViewMapClick}
          />
        );
      default:
        return <EmptyComponent />;
    }
  }

  onAccessPresetPhotoClick = (photoId: string, accessPreset: ACCESS_PRESET_TYPE) => {
    const { user } = this.props;
    const { event, photos } = this.state;
    const { id } = event;

    this.setState({
      isUpdateAccessPreset: true
    });

    const dataToUpdate = {
      accessPreset
    };

    updateEventPhoto(user, id, photoId, dataToUpdate).then(photoNext => {
      const photosNext = photos.map(photo => (photoId === photo.id ? photoNext : photo));
      this.setState({
        photos: photosNext,
        isUpdateAccessPreset: false
      });
    });
  };

  onAccessPresetVideoClick = (videoId: string, accessPreset: ACCESS_PRESET_TYPE) => {
    const { user } = this.props;
    const { event, videos } = this.state;
    const { id } = event;

    this.setState({
      isUpdateAccessPreset: true
    });

    const dataToUpdate = {
      accessPreset
    };

    updateEventVideo(user, id, videoId, dataToUpdate).then(videoNext => {
      const videosNext = videos.map(video => (videoId === video.id ? videoNext : video));
      this.setState({
        videos: videosNext,
        isUpdateAccessPreset: false
      });
    });
  };

  editEventDetailsModalOpen = () => {
    this.setState({
      isEditEventDetailsModalOpen: true
    });
  };

  onAddEventJobClick = event => {
    event.preventDefault();

    this.setState({
      isCreateEventJobModalOpen: true,
      eventTaskIndex: -1
    });
  };

  onEditEventPerformanceClick = () => {
    this.setState({
      isEditPerformance: true
    });
  };

  onSaveEventPerformanceClick = () => {
    const { individualPerformance, event } = this.state;
    const { user } = this.props;
    const { id } = event;

    this.setState({
      isLoading: true
    });

    const performanceWithoutId = individualPerformance.filter(performance => typeof performance._id === 'undefined');
    const performanceWithId = individualPerformance.filter(performance => typeof performance._id !== 'undefined');

    const performanceWithoutIdPromises = performanceWithoutId.map(data => createEventPerformance(user, id, data));
    const performanceWithIdPromises = performanceWithId.map(performance => {
      const updatePerformanceId = performance._id;
      const value = performance.value;

      return updateEventPerformance(user, id, updatePerformanceId, { value });
    });

    const promises = [...performanceWithoutIdPromises, ...performanceWithIdPromises];

    BPromise.all(promises)
      .then(res => {
        return getSchoolEvent(user, id);
      })
      .then(event => {
        this.setState({
          isLoading: false,
          isEditPerformance: false,
          individualPerformance: event.results.individualPerformance,
          event: event
        });
      });
  };

  onEditEventDisciplineClick = () => {
    this.setState({
      isEditDiscipline: true
    });
  };

  onSaveEventDisciplineClick = () => {
    const { individualDiscipline, event } = this.state;
    const { user } = this.props;
    const { id } = event;

    this.setState({
      isLoading: true
    });

    const disciplineWithoutId = individualDiscipline.filter(discipline => typeof discipline._id === 'undefined');
    const disciplineWithId = individualDiscipline.filter(discipline => typeof discipline._id !== 'undefined');

    const disciplineWithoutIdPromises = disciplineWithoutId.map(data => createEventDiscipline(user, id, data));
    const disciplineWithIdPromises = disciplineWithId.map(discipline => {
      const updateDisciplineId = discipline._id;
      const value = discipline.value;

      return updateEventDiscipline(user, id, updateDisciplineId, { value });
    });

    const promises = [...disciplineWithoutIdPromises, ...disciplineWithIdPromises];

    BPromise.all(promises)
      .then(res => {
        return getSchoolEvent(user, id);
      })
      .then(event => {
        this.setState({
          isLoading: false,
          isEditDiscipline: false,
          individualDiscipline: event.results.individualDiscipline,
          event: event
        });
      });
  };

  onEditEventMatchReportClick = () => {
    this.setState({
      isEditMatchReportActive: false
    });
  };

  onSaveEventMatchReportClick = () => {
    const { user } = this.props;
    const { event, matchReport } = this.state;
    const { id } = event;

    this.setState({
      isLoading: true,
      isEditMatchReportActive: true
    });

    const content = {
      content: matchReport
    };

    updateSchoolEventMatchReport(user, id, content)
      .then(matchReport => {
        const content = propz.get(matchReport, ['content'], '');

        this.setState({
          isLoading: false,
          matchReport: content
        });
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        console.error(error);
        this.setState({
          isLoading: false,
          errorMessage: errorText,
          isErrorModalOpen: true
        });
      });
  };

  onEditEventAttendanceClick = () => {
    const { event } = this.state;
    const { results } = event;
    const { attendance } = results;
    const attendancePlayers = attendance.map(player => ({ ...player }));

    const allAttendancePlayers = this.getInitAttendancePlayers(attendancePlayers);

    const isOneAttendancePresent = allAttendancePlayers.every(player => {
      return propz.get(player, ['isAttendance'], false);
    });

    this.setState({
      attendancePlayers: allAttendancePlayers,
      isEditAllAttendance: isOneAttendancePresent,
      isEditAttendance: true
    });
  };

  onCancelEventAttendanceClick = () => {
    const { event } = this.state;
    const attendance = event.results.attendance;
    const attendancePlayers = attendance.map(player => ({ ...player }));

    this.setState({
      attendancePlayers: attendancePlayers,
      isEditAttendance: false
      // isEditAllAttendance: true
    });
  };

  onSaveEventAttendanceClick = () => {
    const { attendancePlayers, event } = this.state;
    const { user } = this.props;
    const { id } = event;

    this.setState({
      isLoading: true
    });

    const attendanceWithoutId = attendancePlayers.filter(attendance => typeof attendance._id === 'undefined');
    const attendanceWithId = attendancePlayers.filter(attendance => typeof attendance._id !== 'undefined');

    const attendanceWithoutIdPromises = attendanceWithoutId.map(data => createEventAttendance(user, id, data));
    const attendanceWithIdPromises = attendanceWithId.map(attendance => {
      const updateAttendanceId = attendance._id;
      const isAttendance = attendance.isAttendance;

      return updateEventAttendance(user, id, updateAttendanceId, { isAttendance });
    });

    const promises = [...attendanceWithoutIdPromises, ...attendanceWithIdPromises];

    BPromise.all(promises)
      .then(res => {
        return getSchoolEvent(user, id);
      })
      .then(event => {
        this.setState({
          isLoading: false,
          isEditAttendance: false,
          attendancePlayers: event.results.attendance,
          event: event
        });
      });
  };

  onSavePositionsClick = () => {
    const { playersForSummary, teamsForSummary, event } = this.state;
    const { user } = this.props;
    const { id } = event;

    this.setState({
      isLoading: true
    });

    const promise = isNonTeamSportEvent(event)
      ? updateSchoolEventIndividualsBatch(user, id, {
          add: [],
          remove: [],
          update: playersForSummary.map(player => {
            const { isCaptain, isSub, positionId, playerId } = player;
            return {
              isCaptain: isCaptain,
              sub: isSub,
              positionId: positionId !== '' ? positionId : null,
              id: playerId
            };
          })
        })
      : BPromise.all(
          teamsForSummary.map(teamForSummary =>
            updateSchoolEventTeamPlayersBatch(user, id, teamForSummary.teamId, {
              add: [],
              remove: [],
              update: playersForSummary
                .filter(player => player.teamId === teamForSummary.teamId)
                .map(player => {
                  const { isCaptain, isSub, positionId, playerId } = player;
                  return {
                    isCaptain: isCaptain,
                    sub: isSub,
                    positionId: positionId !== '' ? positionId : null,
                    id: playerId
                  };
                })
            })
          )
        );

    promise
      .then(res => {
        return getSchoolEvent(user, id);
      })
      .then(event => {
        const playersForSummary = this.getPlayersForSummary(event);
        this.setState({
          event: event,
          isLoading: false,
          isEditPositions: false,
          playersForSummary: playersForSummary,
          activeTeamIndex: 0
        });
      });
  };

  onEditPositionsClick = () => {
    this.setState({
      isEditPositions: true
    });
  };

  onChangeTeam = event => {
    const { teamsForSummary } = this.state;
    const teamId = event.target.value;
    const currentTeamIndex = teamsForSummary.findIndex(team => team.teamId === teamId);

    this.setState({
      activeTeamIndex: currentTeamIndex
    });
  };

  onChangeIsSubPlayer = (event, playerId: string): void => {
    const isSub = event.target.checked;
    const { playersForSummary } = this.state;
    const playerIndex = playersForSummary.findIndex(playerForSummary => playerForSummary.playerId === playerId);

    const nextPlayersForSummary = [...playersForSummary];
    nextPlayersForSummary[playerIndex].isSub = isSub;

    this.setState({
      playersForSummary: nextPlayersForSummary
    });
  };

  onChangeIsCaptainPlayer = (event, playerId) => {
    const isCaptain = event.target.checked;
    const { playersForSummary, teamsForSummary, activeTeamIndex } = this.state;
    const playerIndex = playersForSummary.findIndex(playerForSummary => playerForSummary.playerId === playerId);

    const nextPlayersForSummary = [...playersForSummary];

    if (isCaptain) {
      const teamId = teamsForSummary[activeTeamIndex].teamId;
      // ahah old captain, yohoho
      const oldCaptainIndex = playersForSummary.findIndex(player => player.teamId === teamId && player.isCaptain);

      // kill captain, i'm a captain
      if (oldCaptainIndex !== -1) {
        nextPlayersForSummary[oldCaptainIndex].isCaptain = false;
      }
    }

    nextPlayersForSummary[playerIndex].isCaptain = isCaptain;

    this.setState({
      playersForSummary: nextPlayersForSummary
    });
  };

  onChangePositionPlayer = (event, playerId: string): void => {
    const positionValue = event.target.value;

    const { playersForSummary } = this.state;
    const playerIndex = playersForSummary.findIndex(playerForSummary => playerForSummary.playerId === playerId);

    const nextPlayersForSummary = [...playersForSummary];
    nextPlayersForSummary[playerIndex].positionId = positionValue;

    this.setState({
      playersForSummary: nextPlayersForSummary
    });
  };

  getPlayersForSummary(event: SchoolEvent): SchoolEventPlayerSummary[] {
    const { user } = this.props;
    const { activeSchoolId } = user;

    const schoolPlayers = getEventPlayers(event, activeSchoolId);

    return schoolPlayers.map(schoolPlayer => {
      const { userId, permissionId, firstName, lastName, form, id, teamId } = schoolPlayer;
      const isFormExist = typeof form !== 'undefined';
      const formName = isFormExist ? form.name : '';

      const playerFromEvent = event.players.find(
        player => player.userId === userId && player.permissionId === permissionId
      );

      const positionId = typeof playerFromEvent !== 'undefined' ? playerFromEvent.positionId : '';
      const isCaptain = typeof playerFromEvent !== 'undefined' ? playerFromEvent.isCaptain : false;
      const isSub = typeof playerFromEvent !== 'undefined' ? playerFromEvent.sub : false;

      return {
        name: `${firstName} ${lastName}`,
        playerId: id,
        form: formName,
        positionId: positionId,
        isCaptain: isCaptain,
        isSub: isSub,
        teamId: teamId
      };
    });
  }

  getTeamsForSummary(): SchoolEventTeamSummary[] {
    const { user } = this.props;
    const { activeSchoolId } = user;
    const { event } = this.state;

    return event.teamsData
      .filter(team => team.schoolId === activeSchoolId)
      .map(team => ({
        teamName: team.name,
        teamId: team.id,
        houseName: isHousesEvent(event) ? team.house.name : undefined
      }));
  }

  onCloseEventJobClick = event => {
    event.preventDefault();

    this.setState({
      isCreateEventJobModalOpen: false,
      eventTaskIndex: 0
    });
  };

  renderEventJobForm(): React.ReactNode {
    const { isCreateEventJobModalOpen, eventTasks, eventTaskIndex, event } = this.state;
    const { user } = this.props;

    const modalText = eventTaskIndex === -1 ? 'Create job' : 'Edit job';
    return (
      <SimpleModal isOpen={isCreateEventJobModalOpen} title={modalText}>
        <SchoolEventJobForm
          user={user}
          schoolEvent={event}
          eventTasks={eventTasks}
          eventTaskIndex={eventTaskIndex}
          onCancel={this.onCloseEventJobClick}
          onSubmit={this.onSubmitEventJobForm}
        />
      </SimpleModal>
    );
  }

  onSubmitEventJobForm = data => {
    const { user } = this.props;
    const { eventTaskIndex, eventTasks, event, reports, consents } = this.state;
    const { id } = event;

    this.setState({
      isLoading: true,
      isCreateEventJobModalOpen: false
    });

    const { players, ...rest } = data;

    const dataToSubmit = {
      ...rest,
      assignees: players
    };
    if (eventTaskIndex !== -1) {
      const activeTaskId = eventTasks[eventTaskIndex].id;
      const isExistReports = reports.length > 0;
      const isExistConsents = consents.length > 0;

      editEventTasks(user, id, activeTaskId, dataToSubmit)
        .then(res => getEventTasks(user, id))
        .then(eventTasks => {
          const eventTab = getEventViewTabs(event, isExistReports, isExistConsents);
          const tabIndex = eventTab.indexOf(TABS.JOBS);
          this.setState({
            currentTabIndex: tabIndex,
            eventTasks: eventTasks,
            isCreateEventJobModalOpen: false,
            isLoading: false
          });
        })
        .catch(error => {
          const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
          console.error(error);
          this.setState({
            isLoading: false,
            errorMessage: errorText,
            isErrorModalOpen: true
          });
        });
    } else {
      createEventTasks(user, id, dataToSubmit)
        .then(task => {
          const tasks = [...eventTasks];
          tasks.push(task);
          this.setState({
            eventTasks: tasks,
            isLoading: false
          });
        })
        .catch(error => {
          const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
          console.error(error);
          this.setState({
            isCreateEventJobModalOpen: false,
            isLoading: false,
            errorMessage: errorText,
            isErrorModalOpen: true
          });
        });
    }
  };

  renderDeleteJobModal() {
    const { isDeleteJobModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isDeleteJobModalOpen}
        title={'Remove Job'}
        body={`Are you sure you want to remove Job ?`}
        buttonText={'Delete'}
        onButtonClick={this.onDeleteJobClick}
        onCloseClick={this.deleteJobModalClose}
      />
    );
  }

  updateEventDetails = (data, editMode) => {
    const { user, reloadEvent } = this.props;
    const { event, currentTabIndex } = this.state;
    const { id } = event;

    this.setState({
      isLoading: true,
      isEditEventDetailsModalOpen: false
    });

    const singleEditMode = editMode === CLUB_EVENT_EDIT_MODE.SINGLE;
    const getEditEventService = singleEditMode
      ? updateSchoolEventDetails(user, event.id, data)
      : updateGroupSchoolEventDetails(user, event.id, data);

    getEditEventService
      .then(res => {
        reloadEvent(currentTabIndex);
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        console.error(error);
        this.setState({
          isLoading: false,
          errorMessage: errorText,
          isErrorModalOpen: true
        });
      });
  };

  editEventDetailsModalClose = () => {
    this.setState({
      isEditEventDetailsModalOpen: false
    });
  };

  renderEventDetailsForm(): React.ReactNode {
    const { isEditEventDetailsModalOpen, eventDetails, event } = this.state;
    const { user } = this.props;
    const { id } = event;
    return (
      <SimpleModal
        customClass="mSchoolEventDetailsFormWidth"
        isOpen={isEditEventDetailsModalOpen}
        title={'Event details'}
      >
        <SchoolEventDetailsForm
          eventId={id}
          user={user}
          onSubmit={this.updateEventDetails}
          onCancel={this.editEventDetailsModalClose}
          eventsDetails={eventDetails}
          event={event}
        />
      </SimpleModal>
    );
  }

  renderSendingError(): React.ReactNode {
    const { errorMessage, isErrorModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isErrorModalOpen}
        title={'Error'}
        buttonCancelText={'Ok'}
        onCloseClick={this.onCloseErrorClick}
      >
        <pre>{errorMessage}</pre>
      </SimpleModal>
    );
  }

  onCloseErrorClick = () => {
    this.setState({
      isErrorModalOpen: false,
      errorMessage: ''
    });
  };

  onDeletePhotoClick = (photoId: string) => {
    const { user, eventInitial } = this.props;
    const { id } = eventInitial;
    this.setState({
      isLoading: true
    });

    deleteEventPhoto(user, id, photoId)
      .then(res => getAllEventPhotos(user, id))
      .then(photos => {
        this.setState({
          isLoading: false,
          photos
        });
      });
  };

  onDeleteVideoClick = (videoId: string) => {
    const { user, eventInitial } = this.props;
    const { id } = eventInitial;
    this.setState({
      isLoading: true
    });

    deleteEventVideo(user, id, videoId)
      .then(res => getAllEventVideos(user, id))
      .then(videos => {
        this.setState({
          isLoading: false,
          videos
        });
      });
  };

  onAddPhotoClick = eventDescriptor => {
    let event;
    //This is true only for IE,firefox
    //Dirty hack from https://codeproject.com/Tips/893254/JavaScript-Triggering-Event-Manually-in-Internet-E
    //However, MDN recommend use very similar polyfill https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/MouseEvent#Polyfill
    if (document.createEvent) {
      // To create a mouse event , first we need to create an event and then initialize it.
      event = document.createEvent('MouseEvent');
      event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    } else {
      event = new MouseEvent('click');
    }
    this.photoFileInputRef.dispatchEvent(event);
    eventDescriptor.stopPropagation();
  };

  //it may come in handy

  onAddVideoClick = eventDescriptor => {
    let event;
    //This is true only for IE,firefox
    //Dirty hack from https://codeproject.com/Tips/893254/JavaScript-Triggering-Event-Manually-in-Internet-E
    //However, MDN recommend use very similar polyfill https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/MouseEvent#Polyfill
    if (document.createEvent) {
      // To create a mouse event , first we need to create an event and then initialize it.
      event = document.createEvent('MouseEvent');
      event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    } else {
      event = new MouseEvent('click');
    }
    this.videoFileInputRef.dispatchEvent(event);
    eventDescriptor.stopPropagation();
  };

  onPhotoFileChange = event => {
    const { user, eventInitial } = this.props;
    const { id } = eventInitial;
    const image = event.target.files[0];

    const { activeSchool } = user;
    const photoAccessPreset = propz.get(activeSchool, ['photoAccessPreset'], ACCESS_PRESET.PRIVATE);

    this.setState({
      isLoading: true
    });

    uploadImage(user, image)
      .then(response => {
        const picUrl = `${window.apiImg}/images/${response.key}`;
        const data = {
          picUrl: picUrl,
          originalFileName: image.name,
          accessPreset: photoAccessPreset
        };
        return addEventPhoto(user, id, data);
      })
      .then(res => getAllEventPhotos(user, id))
      .then(photos => {
        this.setState({
          isLoading: false,
          photos: photos
        });
      });
  };

  onVideoFileChange = event => {
    const { user, eventInitial } = this.props;
    const { id } = eventInitial;
    const video = event.target.files[0];
    const { name } = video;
    const safeName = getSafeFileName(name);

    const { activeSchool } = user;
    const photoAccessPreset = propz.get(activeSchool, ['photoAccessPreset'], ACCESS_PRESET.PRIVATE);

    this.setState({
      isLoading: true
    });

    const promises = [getVideoConfig(user), getKey(user)];

    let s3, folderKey, folderName, videoFullName, bucket;

    BPromise.all(promises)
      .then(([videoConfig, keyObj]) => {
        const { accessKey, secretAccessKey, bucketRegion } = videoConfig;
        bucket = videoConfig.bucketName;

        s3 = new AWS.S3({
          region: bucketRegion,
          credentials: {
            accessKeyId: accessKey,
            secretAccessKey: secretAccessKey
          }
        });

        folderKey = keyObj.key;
        folderName = `orig/${folderKey}/`;
        videoFullName = folderName + safeName;

        return s3.putObject({ Key: folderName, Bucket: bucket }).promise();
      })
      .then(res => {
        return s3.putObject({ Key: videoFullName, Bucket: bucket, Body: video }).promise();
      })
      .then(res => {
        const data = {
          key: folderKey,
          fileName: safeName,
          bucketName: bucket
        };
        return addConvertJob(user, data);
      })
      .then(jobObj => {
        const job = jobObj.Job;
        const jobId = job.Id;

        const data = {
          fileName: safeName,
          key: folderKey,
          jobId: jobId,
          accessPreset: photoAccessPreset
        };

        return addEventVideo(user, id, data);
      })
      .then(res => getAllEventVideos(user, id))
      .then(videos => {
        this.setState({
          isLoading: false,
          videos: videos
        });
      })
      .catch(error => {
        console.error(error);
        this.setState({
          isLoading: false,
          errorMessage: JSON.stringify(error),
          isErrorModalOpen: true
        });
      });
  };

  onCancelButtonClick = (): void => {
    this.setState({
      isVenueFormOpen: false
    });
  };

  onViewMapClick = (point): void => {
    this.setState({
      point: point,
      isVenueFormOpen: true
    });
  };

  renderPhotoFileInput() {
    return (
      <input
        className="eAddPhotoButton_fileInput"
        type="file"
        onChange={this.onPhotoFileChange}
        ref={input => (this.photoFileInputRef = input)}
      />
    );
  }

  renderVideoFileInput() {
    return (
      <input
        className="eAddVideoButton_fileInput"
        type="file"
        onChange={this.onVideoFileChange}
        ref={input => (this.videoFileInputRef = input)}
      />
    );
  }

  renderVenueModal() {
    const { user } = this.props;
    const { point } = this.state;
    return (
      <SimpleModal isOpen={this.state.isVenueFormOpen} onButtonClick={this.onCancelButtonClick} title={'Venue'}>
        <VenueView point={point} onOkClick={this.onCancelButtonClick} />
      </SimpleModal>
    );
  }

  render() {
    const {
      currentTabIndex,
      isLoading,
      isEditPerformance,
      isEditDiscipline,
      isEditAttendance,
      isEditMatchReportActive,
      isErrorModalOpen,
      isEditEventDetailsModalOpen,
      isCreateEventJobModalOpen,
      isVenueFormOpen,
      event,
      reports,
      consents,
      isEditPositions
    } = this.state;
    const { user } = this.props;
    const { activeSchoolId, activeSchool, activeRole } = user;
    const { settings } = activeSchool;
    const { photosEnabled } = settings;
    const isExistReports = reports.length > 0;
    const isExistConsents = consents.length > 0;
    const tabs = getEventViewTabs(event, isExistReports, isExistConsents);
    const contentTabs = this.getContentTabs();
    const currentTab = tabs[currentTabIndex];

    if (isLoading) {
      return <Loader />;
    }

    const players = getEventPlayers(event, activeSchoolId);

    const isShowEditDetailsButton = currentTab === TABS.DETAILS;
    const isShowAddJobButton = currentTab === TABS.JOBS;
    const isPlayersExist = players.length > 0;

    const isTabProgress = currentTab === TABS.PROGRESS;
    const isTabPerformance = currentTab === TABS.PERFORMANCE;
    const isTabProgressOrPerformance = isTabProgress || isTabPerformance;
    const isShowEditPerformanceButton = !isEditPerformance && isTabProgressOrPerformance;
    const isShowSavePerformanceButton = isEditPerformance && isTabProgressOrPerformance;

    const isShowEditDisciplineButton = !isEditDiscipline && currentTab === TABS.DISCIPLINE;
    const isShowSaveDisciplineButton = isEditDiscipline && currentTab === TABS.DISCIPLINE;

    const isShowEditAttendanceButton = !isEditAttendance && currentTab === TABS.ATTENDANCE;
    const isShowSaveAttendanceButton = isEditAttendance && currentTab === TABS.ATTENDANCE;

    const isShowAddPhotoButton = currentTab === TABS.GALLERY;
    const isShowAddVideoButton = currentTab === TABS.GALLERY;

    const isTabLeaderAndCoach = currentTab === TABS.LEADER_COACH_NOTES;
    const isTabMatchReport = currentTab === TABS.MATCH_REPORT;
    const isTabbLeaderCoachOrMatchReport = isTabLeaderAndCoach || isTabMatchReport;
    const isShowEditMatchReportButton = isEditMatchReportActive && isTabbLeaderCoachOrMatchReport;
    const isShowSaveMatchReportButton = !isEditMatchReportActive && isTabbLeaderCoachOrMatchReport;

    const isShowEditPositionsButton = !isEditPositions && !isClubEvent(event) && currentTab === TABS.POSITIONS;
    const isShowSavePositionsButton = isEditPositions && !isClubEvent(event) && currentTab === TABS.POSITIONS;

    const classes =
      isErrorModalOpen || isEditEventDetailsModalOpen || isCreateEventJobModalOpen || isVenueFormOpen
        ? 'mt-3 modal-open'
        : 'mt-3';

    return (
      <div className={classes}>
        {this.renderEventJobForm()}
        {this.renderEventDetailsForm()}
        {this.renderDeleteJobModal()}
        {this.renderSendingError()}
        {this.renderPhotoFileInput()}
        {this.renderVideoFileInput()}
        {this.renderVenueModal()}

        <GenericSummary onTabClick={this.onTabClick} tabs={tabs} currentTabIndex={currentTabIndex}>
          {isShowEditDetailsButton && (
            <Button onClick={this.editEventDetailsModalOpen} text={'Edit details'} customClass={'mt-3 mb-3 ml-3'} />
          )}
          {isShowAddJobButton && (
            <Button onClick={this.onAddEventJobClick} text={'Add job'} customClass={'mt-3 mb-3 ml-3'} />
          )}
          {isShowEditPerformanceButton && (
            <Button
              onClick={this.onEditEventPerformanceClick}
              text={isClubEvent(event) ? 'Edit progress' : 'Edit performance'}
              customClass={'mt-3 mb-3 ml-3'}
              disabled={!isPlayersExist}
            />
          )}
          {isShowSavePerformanceButton && (
            <Button
              onClick={this.onSaveEventPerformanceClick}
              text={isClubEvent(event) ? 'Save progress' : 'Save performance'}
              customClass={'mt-3 mb-3 ml-3'}
            />
          )}
          {isShowEditDisciplineButton && (
            <Button
              onClick={this.onEditEventDisciplineClick}
              text={'Edit discipline'}
              customClass={'mt-3 mb-3 ml-3'}
              disabled={!isPlayersExist}
            />
          )}
          {isShowSaveDisciplineButton && (
            <Button
              onClick={this.onSaveEventDisciplineClick}
              text={'Save discipline'}
              customClass={'mt-3 mb-3 ml-3'}
              disabled={!isPlayersExist}
            />
          )}
          {isShowEditAttendanceButton && (
            <Button
              onClick={this.onEditEventAttendanceClick}
              text={'Edit attendance'}
              customClass={'mt-3 mb-3 ml-3'}
              disabled={!isPlayersExist}
            />
          )}
          {isShowSaveAttendanceButton && (
            <>
              <Button
                onClick={this.onCancelEventAttendanceClick}
                text={'Cancel'}
                customClass={'mt-3 mb-3 ml-3 btn-secondary'}
              />
              <Button onClick={this.onSaveEventAttendanceClick} text={'Save'} customClass={'mt-3 mb-3 ml-3'} />
            </>
          )}
          {isShowAddPhotoButton && (
            <Button onClick={this.onAddPhotoClick} text={'Add photo'} customClass={'mt-3 mb-3 ml-3'} />
          )}
          {isShowAddVideoButton && (
            <Button onClick={this.onAddVideoClick} text={'Add video'} customClass={'mt-3 mb-3 ml-3'} />
          )}
          {isShowEditMatchReportButton && (
            <Button
              onClick={this.onEditEventMatchReportClick}
              text={isClubEvent(event) ? 'Edit Leader/Coach notes' : 'Edit match report'}
              customClass={'mt-3 mb-3 ml-3'}
              disabled={!isEditMatchReportActive}
            />
          )}
          {isShowSaveMatchReportButton && (
            <Button
              onClick={this.onSaveEventMatchReportClick}
              text={isClubEvent(event) ? 'Save Leader/Coach notes' : 'Save match report'}
              customClass={'mt-3 mb-3 ml-3'}
            />
          )}
          {isShowEditPositionsButton && (
            <Button
              onClick={this.onEditPositionsClick}
              text={'Edit positions'}
              customClass={'mt-3 mb-3 ml-3'}
              disabled={!isPlayersExist}
            />
          )}
          {isShowSavePositionsButton && (
            <Button
              onClick={this.onSavePositionsClick}
              text={'Save positions'}
              customClass={'mt-3 mb-3 ml-3'}
              disabled={!isPlayersExist}
            />
          )}
          {contentTabs}
        </GenericSummary>
      </div>
    );
  }
}
