import * as React from 'react';
import { Component } from 'react';
import { AppUser } from 'Src/views/App/App';
import { Loader } from 'Src/components/Loader/Loader';
import { Child } from 'Src/models/child';
import './Children.scss';
import { getChildren, setChildAvatar } from '../../../../helpers/service/parent/children';
import { uploadImage } from '../../../../helpers/service/image';

interface Props {
  user: AppUser;
}

interface State {
  isLoading: boolean;
  children: Child[];
}

export class Children extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      children: []
    };
  }

  componentDidMount() {
    const { user } = this.props;

    this.setState({
      isLoading: true
    });

    getChildren(user).then(children => {
      this.setState({
        children,
        isLoading: false
      });
    });
  }

  onAvatarChange = (event, childId, index) => {
    const avatarFile = event.target.files[0];
    const { user } = this.props;

    this.setState({
      isLoading: true
    });

    uploadImage(user, avatarFile)
      .then(res => {
        const { key } = res;
        const avatarUrl = `${window.apiImg}/images/${key}`;
        return setChildAvatar(user, childId, avatarUrl);
      })
      .then((child: Child) => {
        const { children } = this.state;
        const childrenUpdated = [...children];
        childrenUpdated[index] = child;

        this.setState({
          children: childrenUpdated,
          isLoading: false
        });
      });
  };

  render() {
    const { isLoading, children } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    return (
      <div>
        {children.map((child, index) => {
          const { avatar, id } = child;
          const isAvatarUrlExist = typeof avatar !== 'undefined' && avatar !== '';
          return (
            <div className="bChildCard" key={`children_${index}`}>
              <div>
                {isAvatarUrlExist ? (
                  <div style={{ backgroundImage: `url(${avatar})` }} className="eChildCardAvatar" />
                ) : (
                  <div className="eChildCardLiteralAvatar">
                    <div className="eChildCardLiteralAvatarText">{child.firstName.substring(0, 1)}</div>
                  </div>
                )}
              </div>
              <input
                type="file"
                className="eChildCardFileInput"
                id={`children_tab_file_input_${index}`}
                accept=".jpg, .jpeg, .png"
                onChange={() => this.onAvatarChange(event, id, index)}
              />
              <div className="eChildCardPlusIcon">
                <label className="eChildCardPlusIconText" htmlFor={`children_tab_file_input_${index}`}>
                  {'+'}
                </label>
              </div>

              <div className="eChildCardInfo">
                <div className="eChildCardName">{`${child.firstName} ${child.lastName}`}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
