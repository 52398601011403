import * as React from 'react';
import * as BPromise from 'bluebird';
import { Component } from 'react';
import { Participant, SchoolEvent, SchoolEventCricketResult } from 'Src/models/event';
import { AppUser } from 'Src/views/App/App';
import { History, Location } from 'history';
import { parse } from 'query-string';
import { Loader } from 'Src/components/Loader/Loader';
import 'Src/views/GenericView/AdminView/TeamsAndEvents/Events/SchoolEventView/SchoolEventView.scss';
import {
  finishEvent,
  getEventAvailableUsers,
  getSchoolEvent,
  setEventResultsMode,
  sendEventNotification,
  sendEventNotificationEligibleStudents,
  addSchoolInEvent,
  removeSchoolsFromEvent,
  getEventRecord
} from 'Src/helpers/service/admin/event';
import { Button } from 'Src/components/Button/Button';
import { getSchoolEvents, reasonForCancellation } from 'Src/helpers/service/admin/schoolEvents';
import { ActionsButton } from 'Src/components/ActionsButton/ActionsButton';
import { getActionList, getNextEventFilter, getPreviousEventFilter } from 'Src/helpers/event/schoolEventViewHelper';
import { SchoolEventNavigationButtons } from 'Src/views/GenericView/AdminView/TeamsAndEvents/Events/SchoolEventView/SchoolEventNavigationButtons';
import { SchoolEventMainInformation } from 'Src/views/GenericView/AdminView/TeamsAndEvents/Events/SchoolEventView/SchoolEventMainInformation';
import { SchoolEventNewTweetButton } from 'Src/views/GenericView/AdminView/TeamsAndEvents/Events/SchoolEventView/SchoolEventNewTweetButton';
import { SchoolEventTabs } from 'Src/views/GenericView/AdminView/TeamsAndEvents/Events/SchoolEventView/SchoolEventTabs';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import {
  getAllSchoolIdsFromEvent,
  getEventParticipants,
  isEventStatusFinished,
  isHousesEvent,
  isInternalEvent,
  isInterSchoolsEvent,
  isMultipartyEvent,
  isNonTeamSportEvent,
  isTeamOrTwoOnTwoSportEvent,
  isTeamSportEvent,
  setExtraScores,
  sortByScore,
  isIndividualTournamentEvent
} from 'Src/helpers/event/event';
import { getSchoolsPublic } from '../../../../../helpers/service/public/public';
import { IndividualInternalResults } from 'Src/views/GenericView/AdminView/TeamsAndEvents/Events/SchoolEventView/ResultsViews/IndividualInternalResults';
import {
  createCricketResults,
  createHouseScore,
  createIndividualScore,
  createSchoolScore,
  createTeamScore,
  updateHouseScore,
  updateIndividualScore,
  updateSchoolScore,
  updateTeamScore
} from 'Src/helpers/service/admin/eventResults';
import { IndividualInternalHousesResults } from 'Src/views/GenericView/AdminView/TeamsAndEvents/Events/SchoolEventView/ResultsViews/IndividualInternalHousesResults';
import { IndividualExternalSchoolsResults } from 'Src/views/GenericView/AdminView/TeamsAndEvents/Events/SchoolEventView/ResultsViews/IndividualExternalSchoolsResults';
import { TeamInternalResults } from 'Src/views/GenericView/AdminView/TeamsAndEvents/Events/SchoolEventView/ResultsViews/TeamInternalResults';
import { TeamInternalHousesResults } from 'Src/views/GenericView/AdminView/TeamsAndEvents/Events/SchoolEventView/ResultsViews/TeamInternalHousesResults';
import { TeamExternalSchoolsResults } from 'Src/views/GenericView/AdminView/TeamsAndEvents/Events/SchoolEventView/ResultsViews/TeamExternalSchoolsResults';
import {
  cancelEvent,
  deleteEvent,
  getActionDescriptor,
  updateActionDescriptor,
  updateSchoolEvent
} from 'Src/helpers/service/admin/schoolEvents';
import { USER_GENDER_TYPE } from 'Src/types/user';
import { SendNotificationsForm } from 'Src/views/GenericView/AdminView/TeamsAndEvents/Events/SchoolEventView/SendNotificationsForm/SendNotificationsForm';
import { getVideoConfig } from 'Src/helpers/service/nobody/config';
import { getKey } from 'Src/helpers/service/nobody/key';
import * as AWS from 'aws-sdk';
import { addConvertJob } from 'Src/helpers/service/nobody/aws';
import { addEventPinnedVideo } from 'Src/helpers/service/admin/eventVideos';
import { SendNotificationEligibleStudentsForm } from 'Src/views/GenericView/AdminView/TeamsAndEvents/Events/SchoolEventView/SendNotificationEligibleStudentsForm/SendNotificationEligibleStudentsForm';
import * as propz from 'propz';
import { SchoolEventReasonCancelForm } from 'Src/views/GenericView/AdminView/TeamsAndEvents/Events/SchoolEventReasonCancelForm/SchoolEventReasonCancelForm';
import { EVENT_NOTIFICATION_MODE, EVENT_RESULTS_MODE } from 'Src/consts/event';
import { getEventGeneratedName } from 'Src/helpers/accessor/accessor';
import { SchoolReasonEventSettingsForm } from 'Src/views/GenericView/AdminView/TeamsAndEvents/Events/SchoolReasonEventSettingsForm/SchoolReasonEventSettingsForm';
import { getSafeFileName } from 'Src/helpers/file/file';
import { IndividualInternalExtraPoints } from 'Src/views/GenericView/AdminView/TeamsAndEvents/Events/SchoolEventView/ExtraPointsView/IndividualInternalExtraPoints';
import { IndividualInternalHousesExtraPoints } from 'Src/views/GenericView/AdminView/TeamsAndEvents/Events/SchoolEventView/ExtraPointsView/IndividualInternalHousesExtraPoints';
import { IndividualExternalSchoolsExtraPoints } from 'Src/views/GenericView/AdminView/TeamsAndEvents/Events/SchoolEventView/ExtraPointsView/IndividualExternalSchoolsExtraPoints';
import { TeamExternalSchoolsExtraPoints } from 'Src/views/GenericView/AdminView/TeamsAndEvents/Events/SchoolEventView/ExtraPointsView/TeamExternalSchoolsExtraPoints';
import { TournamentEventAddSchoolForm } from 'Src/views/GenericView/AdminSUView/Tournaments/TournamentEventAddSchoolForm/TournamentEventAddSchoolForm';
import { removeSchoolEventIndividualPlayer } from 'Src/helpers/service/admin/tournamentTeams';
import { SchoolEventChangeSchoolForm } from 'Src/views/GenericView/AdminView/TeamsAndEvents/Events/SchoolEventChangeSchoolForm/SchoolEventChangeSchoolForm';
import { getConsentRequestTemplate, sendConsentRequest } from 'Src/helpers/service/admin/consentRequestTemplate';
import { ConsentRequestTemplate } from 'Src/models/consentRequestTemplate';
import {
  ConsentRequestForm,
  ConsentRequestFormField
} from 'Src/views/GenericView/AdminView/TeamsAndEvents/Events/SchoolEventConsentRequestForm/SchoolEventConsentRequestForm';
import * as Moment from 'moment';
import { UK_INPUT_MASK_VALIDATION } from 'Src/consts/date';
import { isCricket } from 'Src/helpers/sport/cricket';
import { CricketExternalSchoolsResults } from 'Src/views/GenericView/AdminView/TeamsAndEvents/Events/SchoolEventView/ResultsViews/CricketExternalSchoolsResults';
import { CricketInternalResults } from 'Src/views/GenericView/AdminView/TeamsAndEvents/Events/SchoolEventView/ResultsViews/CricketInternalResults';
import { CricketInternalHousesResults } from 'Src/views/GenericView/AdminView/TeamsAndEvents/Events/SchoolEventView/ResultsViews/CricketInternalHousesResults';
import { SchoolEventsCreateAnotherEventAlert } from 'Src/views/GenericView/AdminView/TeamsAndEvents/Events/SchoolEventsCreateAnotherEventAlert/SchoolEventsCreateAnotherEventAlert';
import { MODE_TYPE } from 'Src/consts/common';
import { removeSchoolEventTeam } from '../../../../../helpers/service/admin/schoolEventTeams';
import { TOURNAMENT_SUBTYPE } from '../../../../../consts/tournament';
import { ROLE } from '../../../../../consts/user';
import { createTweet } from '../../../../../helpers/service/admin/integrations';
import { SchoolUnionEventTwitterModal } from './Twitter/SchoolUnionEventTwitterForm';
import { SchoolUnionEventEditSettingsForm } from './SchooUnionlEventEditSettingsForm/SchoolUnionEventEditSettingsForm';
import { getVenueTypeByPostcode } from 'Src/helpers/venue/venue';
import RemoveSchoolsForm from './RemoveSchoolsForm/RemoveSchoolsForm';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from 'Src/consts/table';
import { Map } from 'Src/components/Map/Map';
import { SCORING, TYPE_OF_PLAYERS } from 'Src/consts/sport';
import { createSchoolUnionRecords, updateSchoolUnionRecords } from 'Src/helpers/service/adminSU/recordsTable';
import { Record } from 'Src/models/record';

export interface EventActionButtonCallbacks {
  EDIT_EVENT_SETTINGS: () => void;
  DUPLICATE_EVENT: () => void;
  ADD_SCHOOL: () => void;
  ADD_TEAM: () => void;
  ADD_RESULTS: () => void;
  UPDATE_RESULTS: () => void;
  UPDATE_POINTS: () => void;
  SEND_CONSENT_REQUEST: () => void;
  CANCEL_EVENT: () => void;
  CANCEL_EVENT_AND_EDIT_NOTIFICATION_LIST: () => void;
  REPORT_AVAILABILITY: () => void;
  PRINT: () => void;
  DOWNLOAD_CSV: () => void;
  DELETE_EVENT: () => void;
  IMPORT_RESULTS_FROM_QUANTUM: () => void;
  EXPORT_START_LIST_TO_MACSHA_ONE_4_ALL: () => void;
  IMPORT_RESULTS_FROM_MACSHA_ONE_4_ALL: () => void;
  CLEAN_SCHOOL_LIST: () => void;
  REMOVE_SCHOOLS: () => void;
  SEND_EVENT_NOTIFICATION: () => void;
  SEND_EVENT_NOTIFICATION_ELIGIBLE_STUDENTS: () => void;
  IMPORT_RESULTS: () => void;
  IMPORT_RESULTS_FROM_QUANTUM_AQ: () => void;
  CLONE_TOURNAMENT_EVENT: () => void;
  ARBITER_BOARD: () => void;
  ADD_VIDEO: () => void;
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

interface State {
  isLoading: boolean;
  event: SchoolEvent;
  participants: Participant[];
  prevEvent: SchoolEvent;
  nextEvent: SchoolEvent;
  //forms: SchoolForm[];
  isResultsModalOpen: boolean;
  isExtraPointsModalOpen: boolean;
  isEditableEventSettings: boolean;
  isSendNotificationModalOpen: boolean;
  isSendNotificationEligibleStudentsModalOpen: boolean;
  availableUsers: AvailableUserExtended[];
  isVideoModalOpen: boolean;
  isCancelEventModalOpen: boolean;
  isReasonCancelModalOpen: boolean;
  errorMessage: string;
  isErrorModalOpen: boolean;
  actionDescriptorId: string;
  isReasonEventSettingsModalOpen: boolean;
  isRemoveSchoolsModalOpen: boolean;
  isRemoveSchoolsConfirmationModalOpen: boolean;
  tabIndex: number;
  isDeleteEventModalOpen: boolean;
  isSuccessDeleteEventModalOpen: boolean;
  isAddSchoolModalOpen: boolean;
  isChangeSchoolModalOpen: boolean;
  isRemoveParticipantModalOpen: boolean;
  participant: Participant;
  isOneParticipant: boolean;
  consentRequestTemplate: ConsentRequestTemplate;
  isSendConsentRequestModalOpen: boolean;
  isSuccessSendConsentRequestModalOpen: boolean;
  isShowCreateAnotherEventAlert: boolean;
  isViewMapModalOpen: boolean;

  isAutoAssignExtraPointsModal: boolean;
  schoolIdsForRemove: string[];
  scores: any[];
  resultsMode: string;
  isTwitterModalOpen: boolean;
  bodyText: string;
  isPostTweetModalOpen: boolean;
}

interface AvailableUser {
  extra: {
    type: string;
  };
  firstName: string;
  gender: USER_GENDER_TYPE;
  lastName: string;
  permissionId: string;
  userId: string;
  userIdStr: string;
}

export interface AvailableUserExtended extends AvailableUser {
  isChecked: boolean;
}

export class SchoolUnionEventView extends Component<Props, State> {
  videoFileInput: any;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      event: undefined,
      participants: [],
      prevEvent: undefined,
      nextEvent: undefined,
      //forms: [],
      isResultsModalOpen: false,
      isExtraPointsModalOpen: false,
      isVideoModalOpen: false,
      isEditableEventSettings: false,
      availableUsers: [],
      isSendNotificationModalOpen: false,
      isSendNotificationEligibleStudentsModalOpen: false,
      isCancelEventModalOpen: false,
      isReasonCancelModalOpen: false,
      errorMessage: '',
      isErrorModalOpen: false,
      actionDescriptorId: undefined,
      isReasonEventSettingsModalOpen: false,
      isRemoveSchoolsModalOpen: false,
      isRemoveSchoolsConfirmationModalOpen: false,
      tabIndex: undefined,
      isDeleteEventModalOpen: false,
      isSuccessDeleteEventModalOpen: false,
      isAddSchoolModalOpen: false,
      isChangeSchoolModalOpen: false,
      isRemoveParticipantModalOpen: false,
      participant: undefined,
      isOneParticipant: false,
      consentRequestTemplate: undefined,
      isSendConsentRequestModalOpen: false,
      isSuccessSendConsentRequestModalOpen: false,
      isShowCreateAnotherEventAlert: false,
      isAutoAssignExtraPointsModal: false,
      scores: [],
      resultsMode: EVENT_RESULTS_MODE.RESULTS,
      isTwitterModalOpen: false,
      bodyText: '',
      isPostTweetModalOpen: false,
      schoolIdsForRemove: [],
      isViewMapModalOpen: false
    };

    this.videoFileInput = React.createRef();
  }

  getEventId(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    const eventId = search.id;
    return eventId;
  }

  getMode(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    const mode = propz.get(search, ['mode'], '');

    return mode;
  }

  //reload component after change eventId by navigation buttons
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location } = this.props;
    const { location: nextLocation } = nextProps;
    const { search } = location;
    const { search: nextSearch } = nextLocation;

    if (search !== nextSearch) {
      this.setState({
        isLoading: true
      });
      this.load();
    }
  }

  load() {
    const { user } = this.props;
    const eventId = this.getEventId();
    const mode = this.getMode();
    const isShowCreateAnotherEventAlert = mode === MODE_TYPE.CREATE_ANOTHER_EVENT;

    let event, prevEvent, nextEvent;

    // 1. Get current event
    // 2. Get previous/next events (for navigation)
    getSchoolEvent(user, eventId)
      .then(_event => {
        event = _event;

        const allSchoolIds = getAllSchoolIdsFromEvent(event);

        const filter = {
          where: { id: { $in: allSchoolIds } },
          view: {
            type: 'id_name_pic'
          },
          skip: DEFAULT_SKIP,
          limit: DEFAULT_LIMIT
        };

        getSchoolsPublic(filter).then(schools => {
          const participants = getEventParticipants(event, user, schools);

          this.setState({
            participants
          });
        });

        const currentEventStartTime = new Date(_event.startTime);

        const nextEventFilter = getNextEventFilter(eventId, currentEventStartTime, user);
        const prevEventFilter = getPreviousEventFilter(eventId, currentEventStartTime, user);

        const prevEventPromise = getSchoolEvents(user, prevEventFilter);
        const nextEventPromise = getSchoolEvents(user, nextEventFilter);

        return BPromise.all([prevEventPromise, nextEventPromise]);
      })
      .then(([_prevEvent, _nextEvent]) => {
        [prevEvent] = _prevEvent;
        [nextEvent] = _nextEvent;

        this.setState({
          isLoading: false,
          event,
          nextEvent,
          prevEvent,
          isShowCreateAnotherEventAlert
        });
      });
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.load();
  }

  onPrevEventClick = () => {
    const { prevEvent } = this.state;
    const { history } = this.props;
    const { id } = prevEvent;

    history.push({
      pathname: '/tournaments/events/event',
      search: `id=${id}`
    });
  };

  onNextEventClick = () => {
    const { nextEvent } = this.state;
    const { history } = this.props;
    const { id } = nextEvent;

    history.push({
      pathname: '/tournaments/events/event',
      search: `id=${id}`
    });
  };

  emptyFunc = () => {};

  updateExtraPoints = () => {
    this.setState({
      isExtraPointsModalOpen: true
    });
  };

  closeEvent = () => {
    this.setState({
      isResultsModalOpen: true
    });
  };

  onArbiterBoardClick = () => {
    const { history } = this.props;
    const { event } = this.state;
    const { id } = event;

    history.push({
      pathname: '/events/arbiterBoard',
      search: `id=${id}`
    });
  };

  addTeam = () => {
    const { history } = this.props;
    const { event } = this.state;
    const { id } = event;

    history.push({
      pathname: '/events/teamManager',
      search: `event=${id}`
    });
  };

  addSchool = () => {
    this.setState({
      isAddSchoolModalOpen: true
    });
  };

  onAddVideoClick = () => {
    let event;
    //This is true only for IE,firefox
    //Dirty hack from https://codeproject.com/Tips/893254/JavaScript-Triggering-Event-Manually-in-Internet-E
    //However, MDN recommend use very similar polyfill https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/MouseEvent#Polyfill
    if (document.createEvent) {
      // To create a mouse event , first we need to create an event and then initialize it.
      event = document.createEvent('MouseEvent');
      event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    } else {
      event = new MouseEvent('click');
    }
    this.videoFileInput.dispatchEvent(event);
  };

  sendEventNotificationEligibleStudents = () => {
    this.setState({
      isSendNotificationEligibleStudentsModalOpen: true
    });
  };

  sendConsentRequest = () => {
    const { user } = this.props;
    this.setState({
      isLoading: true
    });

    getConsentRequestTemplate(user).then(template => {
      this.setState({
        isSendConsentRequestModalOpen: true,
        isLoading: false,
        consentRequestTemplate: template
      });
    });
  };

  copyEvent = () => {
    const { history } = this.props;
    const { event } = this.state;
    const { id } = event;

    history.push({
      pathname: '/events/copyManager',
      search: `event=${id}`
    });
  };

  getCallbacks(): EventActionButtonCallbacks {
    return {
      EDIT_EVENT_SETTINGS: this.onEditEventSettingsButtonClick,
      DUPLICATE_EVENT: this.copyEvent,
      ADD_SCHOOL: this.addSchool,
      ADD_TEAM: this.addTeam,
      ADD_RESULTS: this.closeEvent,
      UPDATE_RESULTS: this.closeEvent,
      UPDATE_POINTS: this.updateExtraPoints,
      SEND_CONSENT_REQUEST: this.sendConsentRequest,
      CANCEL_EVENT: this.cancelEventModalOpen,
      CANCEL_EVENT_AND_EDIT_NOTIFICATION_LIST: this.emptyFunc,
      REPORT_AVAILABILITY: this.emptyFunc,
      PRINT: this.emptyFunc,
      DOWNLOAD_CSV: this.emptyFunc,
      DELETE_EVENT: this.deleteEventModalOpen,
      IMPORT_RESULTS_FROM_QUANTUM: this.emptyFunc,
      EXPORT_START_LIST_TO_MACSHA_ONE_4_ALL: this.emptyFunc,
      IMPORT_RESULTS_FROM_MACSHA_ONE_4_ALL: this.emptyFunc,
      REMOVE_SCHOOLS: this.onRemoveSchoolsClick,
      CLEAN_SCHOOL_LIST: this.cleanSchoolList,
      SEND_EVENT_NOTIFICATION: this.sendEventNotification,
      SEND_EVENT_NOTIFICATION_ELIGIBLE_STUDENTS: this.sendEventNotificationEligibleStudents,
      IMPORT_RESULTS: this.emptyFunc,
      IMPORT_RESULTS_FROM_QUANTUM_AQ: this.emptyFunc,
      CLONE_TOURNAMENT_EVENT: this.emptyFunc,
      ARBITER_BOARD: this.onArbiterBoardClick,
      ADD_VIDEO: this.onAddVideoClick
    };
  }

  sendEventNotification = () => {
    const { user } = this.props;
    const { event } = this.state;

    this.setState({
      isLoading: true
    });

    getEventAvailableUsers(user, event.id).then((availableUsers: AvailableUser[]) => {
      this.setState({
        isLoading: false,
        isSendNotificationModalOpen: true,
        availableUsers: availableUsers.map(user => ({
          ...user,
          isChecked: true
        }))
      });
    });
  };

  getResultsView() {
    const { event } = this.state;
    const { user } = this.props;
    switch (true) {
      case isCricket(event) && isInternalEvent(event) && !isMultipartyEvent(event):
        return (
          <CricketInternalResults
            event={event}
            onSaveClick={this.onCricketResultsSaveClick}
            onCloseClick={this.onCloseResultsModalClick}
          />
        );
      case isCricket(event) && isHousesEvent(event) && !isMultipartyEvent(event):
        return (
          <CricketInternalHousesResults
            event={event}
            onSaveClick={this.onCricketResultsSaveClick}
            onCloseClick={this.onCloseResultsModalClick}
          />
        );
      case isCricket(event) && isInterSchoolsEvent(event) && !isMultipartyEvent(event):
        return (
          <CricketExternalSchoolsResults
            event={event}
            onSaveClick={this.onCricketResultsSaveClick}
            onCloseClick={this.onCloseResultsModalClick}
            user={user}
          />
        );
      case isNonTeamSportEvent(event) && isInternalEvent(event):
        return (
          <IndividualInternalResults
            event={event}
            onSaveClick={this.onResultsSaveClick}
            onCloseClick={this.onCloseResultsModalClick}
          />
        );
      case isNonTeamSportEvent(event) && isHousesEvent(event):
        return (
          <IndividualInternalHousesResults
            event={event}
            onSaveClick={this.onResultsSaveClick}
            onCloseClick={this.onCloseResultsModalClick}
          />
        );
      case isNonTeamSportEvent(event) && isInterSchoolsEvent(event):
        return (
          <IndividualExternalSchoolsResults
            event={event}
            onSaveClick={this.onResultsSaveClick}
            onCloseClick={this.onCloseResultsModalClick}
            user={user}
          />
        );
      case isTeamOrTwoOnTwoSportEvent(event) && isInternalEvent(event):
        return (
          <TeamInternalResults
            event={event}
            onSaveClick={this.onResultsSaveClick}
            onCloseClick={this.onCloseResultsModalClick}
          />
        );
      case isTeamOrTwoOnTwoSportEvent(event) && isHousesEvent(event):
        return (
          <TeamInternalHousesResults
            event={event}
            onSaveClick={this.onResultsSaveClick}
            onCloseClick={this.onCloseResultsModalClick}
          />
        );
      case isTeamOrTwoOnTwoSportEvent(event) && isInterSchoolsEvent(event):
        return (
          <TeamExternalSchoolsResults
            event={event}
            onSaveClick={this.onResultsSaveClick}
            onCloseClick={this.onCloseResultsModalClick}
            user={user}
          />
        );
      default:
        return <h1>Coming soon</h1>;
    }
  }

  getExtraPointsView() {
    const { event } = this.state;
    const { user } = this.props;
    switch (true) {
      case isNonTeamSportEvent(event) && isInternalEvent(event):
        return (
          <IndividualInternalExtraPoints
            event={event}
            onSaveClick={this.onIndividualExtraPointsSaveClick}
            onCloseClick={this.onCloseExtraPointsModalClick}
          />
        );
      case isNonTeamSportEvent(event) && isHousesEvent(event):
        return (
          <IndividualInternalHousesExtraPoints
            event={event}
            onSaveClick={this.onIndividualExtraPointsSaveClick}
            onCloseClick={this.onCloseExtraPointsModalClick}
          />
        );
      case isNonTeamSportEvent(event) && isInterSchoolsEvent(event):
        return (
          <IndividualExternalSchoolsExtraPoints
            event={event}
            user={user}
            onSaveClick={this.onIndividualExtraPointsSaveClick}
            onCloseClick={this.onCloseExtraPointsModalClick}
          />
        );
      case isTeamSportEvent(event) && isInterSchoolsEvent(event):
        return (
          <TeamExternalSchoolsExtraPoints
            event={event}
            user={user}
            onSaveClick={this.onExtraPointsSaveClick}
            onCloseClick={this.onCloseExtraPointsModalClick}
          />
        );
      default:
        return <h1>Coming soon</h1>;
    }
  }

  onIndividualExtraPointsSaveClick = scores => {
    const { event } = this.state;
    const { user } = this.props;
    const { id } = event;

    let promises = [];

    promises = promises.concat(
      scores.filter(score => typeof score.scoreId === 'undefined').map(score => createIndividualScore(user, id, score))
    );

    promises = promises.concat(
      scores
        .filter(score => typeof score.scoreId !== 'undefined')
        .map(score => updateIndividualScore(user, id, score.scoreId, score))
    );

    BPromise.all(promises).then(res => {
      this.setState({
        isExtraPointsModalOpen: false,
        isLoading: true
      });
      this.load();
    });
  };

  onExtraPointsSaveClick = scores => {
    const { event } = this.state;
    const { user } = this.props;
    const { id } = event;

    this.setState({
      isLoading: true
    });

    let promises = [];

    promises = scores
      .filter(score => typeof score.teamId !== 'undefined')
      .filter(score => typeof score.scoreId === 'undefined')
      .map(score => createTeamScore(user, id, score));

    promises = [
      ...promises,
      scores
        .filter(score => typeof score.teamId !== 'undefined')
        .filter(score => typeof score.scoreId !== 'undefined')
        .map(score => updateTeamScore(user, id, score.scoreId, score))
    ];

    promises = [
      ...promises,
      scores
        .filter(score => typeof score.teamId === 'undefined')
        .filter(score => typeof score.scoreId === 'undefined')
        .map(score => createSchoolScore(user, id, score))
    ];

    promises = [
      ...promises,
      scores
        .filter(score => typeof score.teamId === 'undefined')
        .filter(score => typeof score.scoreId !== 'undefined')
        .map(score => updateSchoolScore(user, id, score.scoreId, score))
    ];

    BPromise.all(promises).then(res => {
      this.setState({
        isExtraPointsModalOpen: false,
        isLoading: true
      });
      this.load();
    });
  };

  onCloseExtraPointsModalClick = (): void => {
    this.setState({
      isExtraPointsModalOpen: false
    });
  };

  onCloseResultsModalClick = (): void => {
    this.setState({
      isResultsModalOpen: false
    });
  };

  onCricketResultsSaveClick = (scores: any, cricketResult: SchoolEventCricketResult) => {
    const { event } = this.state;
    const { user } = this.props;
    const { id } = event;

    let promises = [];

    switch (true) {
      case isInternalEvent(event):
        //players
        promises = promises.concat(
          scores
            .filter(score => typeof score.userId !== 'undefined')
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => createIndividualScore(user, id, score))
        );

        promises = promises.concat(
          scores
            .filter(score => typeof score.userId !== 'undefined')
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => updateIndividualScore(user, id, score.scoreId, score))
        );

        //teams
        promises = promises.concat(
          scores
            .filter(score => typeof score.userId === 'undefined')
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => createTeamScore(user, id, score))
        );

        promises = promises.concat(
          scores
            .filter(score => typeof score.userId === 'undefined')
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => updateTeamScore(user, id, score.scoreId, score))
        );

        break;
      case isHousesEvent(event):
        //players
        promises = promises.concat(
          scores
            .filter(score => typeof score.userId !== 'undefined')
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => createIndividualScore(user, id, score))
        );

        promises = promises.concat(
          scores
            .filter(score => typeof score.userId !== 'undefined')
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => updateIndividualScore(user, id, score.scoreId, score))
        );

        //teams
        promises = promises.concat(
          scores
            .filter(score => typeof score.userId === 'undefined' && typeof score.teamId !== 'undefined')
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => createTeamScore(user, id, score))
        );

        promises = promises.concat(
          scores
            .filter(score => typeof score.userId === 'undefined' && typeof score.teamId !== 'undefined')
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => updateTeamScore(user, id, score.scoreId, score))
        );

        //houses
        promises = promises.concat(
          scores
            .filter(score => typeof score.houseId !== 'undefined')
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => createHouseScore(user, id, score))
        );

        promises = promises.concat(
          scores
            .filter(score => typeof score.houseId !== 'undefined')
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => updateHouseScore(user, id, score.scoreId, score))
        );
        break;
      case isInterSchoolsEvent(event):
        //players
        promises = promises.concat(
          scores
            .filter(score => typeof score.userId !== 'undefined')
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => createIndividualScore(user, id, score))
        );

        promises = promises.concat(
          scores
            .filter(score => typeof score.userId !== 'undefined')
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => updateIndividualScore(user, id, score.scoreId, score))
        );

        //teams
        promises = promises.concat(
          scores
            .filter(score => typeof score.userId === 'undefined' && typeof score.teamId !== 'undefined')
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => {
              let scoreData = {
                ...score,
                richScore: {
                  ...score.richScore,
                  ...(score.abbreviationResult && { abbreviationResult: score.abbreviationResult })
                }
              };
              delete scoreData.abbreviationResult;
              return createTeamScore(user, id, scoreData);
            })
        );

        promises = promises.concat(
          scores
            .filter(score => typeof score.userId === 'undefined' && typeof score.teamId !== 'undefined')
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => {
              let scoreData = {
                ...score,
                richScore: {
                  ...score.richScore,
                  ...(score.abbreviationResult && { abbreviationResult: score.abbreviationResult })
                }
              };
              delete scoreData.abbreviationResult;
              return updateTeamScore(user, id, score.scoreId, scoreData);
            })
        );

        //schools
        promises = promises.concat(
          scores
            .filter(score => typeof score.schoolId !== 'undefined')
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => {
              let scoreData = {
                ...score,
                richScore: {
                  ...score.richScore,
                  ...(score.abbreviationResult && { abbreviationResult: score.abbreviationResult })
                }
              };
              delete scoreData.abbreviationResult;
              return createSchoolScore(user, id, scoreData);
            })
        );

        promises = promises.concat(
          scores
            .filter(score => typeof score.schoolId !== 'undefined')
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => {
              let scoreData = {
                ...score,
                richScore: {
                  ...score.richScore,
                  ...(score.abbreviationResult && { abbreviationResult: score.abbreviationResult })
                }
              };
              delete scoreData.abbreviationResult;
              return updateSchoolScore(user, id, score.scoreId, scoreData);
            })
        );
        break;
      default:
        console.log(scores);
        console.log(cricketResult);
        this.setState({
          isResultsModalOpen: false
        });
    }

    BPromise.all(promises)
      .then(res => {
        if (!isEventStatusFinished(event)) {
          return finishEvent(user, id);
        } else {
          return BPromise.resolve(true);
        }
      })
      .then(res => {
        return createCricketResults(user, id, cricketResult);
      })
      .then(res => {
        this.setState({
          isResultsModalOpen: false,
          isLoading: true
        });
        this.load();
      });
  };

  getAutoAssignExtraPointsModalCondition(): boolean {
    const { event } = this.state;
    const tournamentSubtype = propz.get(event, ['tournament', 'subType']);

    const isTournamentSubtypeSwimming = tournamentSubtype === TOURNAMENT_SUBTYPE.ATHLETIC_SWIMMING;
    const isTournamentSubtypeRegularIndividual = tournamentSubtype === TOURNAMENT_SUBTYPE.REGULAR_INDIVIDUAL;
    const isTournamentSubtypeJuniorTriathlon = tournamentSubtype === TOURNAMENT_SUBTYPE.TRIATHLON;
    const isTournamentSubtypePseudoTwoLevel = tournamentSubtype === TOURNAMENT_SUBTYPE.PSEUDO_TWO_LEVEL;
    const isTournamentSubtypePseudoTwoLevelRuntime = tournamentSubtype === TOURNAMENT_SUBTYPE.PSEUDO_TWO_LEVEL_RUNTIME;

    return (
      isTournamentSubtypeSwimming ||
      isTournamentSubtypeRegularIndividual ||
      isTournamentSubtypeJuniorTriathlon ||
      isTournamentSubtypePseudoTwoLevel ||
      isTournamentSubtypePseudoTwoLevelRuntime
    );
  }

  onResultsSaveClick = (scores, resultsMode?: string): void => {
    const condition = this.getAutoAssignExtraPointsModalCondition();

    if (condition) {
      this.setState({
        isAutoAssignExtraPointsModal: true,
        isResultsModalOpen: false,
        scores: scores,
        resultsMode: resultsMode
      });
    } else {
      this.saveWithoutAutoAssignExtraPoints(scores, resultsMode);
    }
  };

  saveWithAutoAssignExtraPoints(scores, resultsMode?: string): void {
    const { event } = this.state;
    const { user } = this.props;
    const { id } = event;
    const inviterSchoolId = event.inviterSchoolId;
    const { individualsData } = event;
    const { invitedSchools } = event;
    const { embeddedTeams } = event;
    const { players } = event;

    let promises = [];

    switch (true) {
      case isNonTeamSportEvent(event) && isInterSchoolsEvent(event):
        const playersZeroScore = scores.filter(scoreObj => scoreObj.score === 0);
        const playersNonZeroScore = scores.filter(scoreObj => scoreObj.score !== 0);
        const playersZeroScoreSorted = sortByScore(event, playersNonZeroScore);
        const scoresSorted = [...playersZeroScoreSorted, ...playersZeroScore];

        const winner = scoresSorted[0];

        const scoresWithExtraPoints = setExtraScores(scoresSorted, event);

        promises = promises.concat(
          scoresWithExtraPoints
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => createIndividualScore(user, id, score))
        );

        promises = promises.concat(
          scoresWithExtraPoints
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => updateIndividualScore(user, id, score.scoreId, score))
        );

        BPromise.all(promises)
          .then(() => {
            return getEventRecord(user, event.id, new Date(event.startTime));
          })
          .then(recordResponse => {
            const existingRecord = recordResponse ? recordResponse : null;
            const scoring = event.sport.scoring;
            const isMoreScores = scoring === SCORING.MORE_SCORES;
            const isMoreTime = scoring === SCORING.MORE_TIME;
            const isMoreResult = scoring === SCORING.MORE_RESULT;
            const isFirstToNPoints = scoring === SCORING.FIRST_TO_N_POINTS;

            const isNewRecord =
              isMoreScores || isMoreTime || isMoreResult || isFirstToNPoints
                ? winner.score > (existingRecord ? existingRecord.record : -Infinity)
                : winner.score < (existingRecord ? existingRecord.record : Infinity);

            const winnerDetails = individualsData.find(individual => individual.userId === winner.userId);

            const winnerPlayer = players.find(player => player.userId === winner.userId);
            const winnerSchoolId = winnerPlayer ? winnerPlayer.schoolId : null;

            const winnerSchool = invitedSchools.find(school => school.id === winnerSchoolId);
            const winnerSchoolName = winnerSchool ? winnerSchool.name : '';

            if (isNewRecord) {
              const newRecord = {
                id: '',
                createdAt: '',
                updatedAt: '',
                schoolId: inviterSchoolId,
                sportId: event.sportId,
                gender: event.gender === 'MALE_ONLY' ? 'MALE' : 'FEMALE',
                date: new Date(event.startTime).toISOString(),
                athlete: winnerDetails ? `${winnerDetails.firstName} ${winnerDetails.lastName}` : 'Unknown Athlete',
                area: winnerSchoolName ? winnerSchoolName : '',
                record: winner.score,
                ages: event.ages,
                eventId: event.id,
                sport: event.sport
              };

              const eventDate = new Date(event.startTime).getTime();
              const recordDate = existingRecord ? new Date(existingRecord.date).getTime() : null;

              if (existingRecord && recordDate === eventDate) {
                console.log('Updating existing record with ID:', existingRecord.id);
                return updateSchoolUnionRecords(user, existingRecord.id, newRecord)
                  .then(() => {})
                  .catch(error => {
                    console.error('Error updating existing record:', error);
                  });
              } else {
                return createSchoolUnionRecords(user, newRecord)
                  .then(() => {})
                  .catch(error => {
                    console.error('Error adding new record:', error);
                  });
              }
            }
          })
          .then(() => {
            if (!isEventStatusFinished(event)) {
              return finishEvent(user, id);
            } else {
              return BPromise.resolve(true);
            }
          })
          .then(() => {
            this.setState({
              isResultsModalOpen: false,
              isAutoAssignExtraPointsModal: false,
              isLoading: true
            });
            this.load();
          });
        break;
      case isTeamOrTwoOnTwoSportEvent(event) && isInterSchoolsEvent(event):
        const teamAndSchoolScores = scores.filter(
          score =>
            (typeof score.userId === 'undefined' && typeof score.teamId !== 'undefined') ||
            (typeof score.userId === 'undefined' && typeof score.schoolId !== 'undefined')
        );

        const teamAndSchoolZeroScores = teamAndSchoolScores.filter(team => team.score === 0);
        const teamAndSchoolNonZeroScores = teamAndSchoolScores.filter(team => team.score !== 0);
        const teamAndSchoolNonZeroScoresSorted = sortByScore(event, teamAndSchoolNonZeroScores);
        const teamAndSchoolScoresSorted = [...teamAndSchoolNonZeroScoresSorted, ...teamAndSchoolZeroScores];
        const teamWinner = teamAndSchoolScoresSorted[0];

        const teamToSchoolMap = {};
        embeddedTeams.forEach(team => {
          teamToSchoolMap[team._id] = team.schoolId;
        });

        const getSchoolNameFromTeamWinner = (teamWinner, invitedSchools, teamToSchoolMap) => {
          let schoolId;
          if (teamWinner.schoolId) {
            schoolId = teamWinner.schoolId;
          } else if (teamWinner.teamId) {
            schoolId = teamToSchoolMap[teamWinner.teamId];
          }
          if (!schoolId) {
            return null;
          }
          const school = invitedSchools.find(school => school.id === schoolId);
          return school ? school.name : null;
        };

        const teamAndSchoolScoresWithExtraPoints = setExtraScores(teamAndSchoolScoresSorted, event);

        const teamScoresWithExtraPoints = teamAndSchoolScoresWithExtraPoints.filter(
          score => typeof score.userId === 'undefined' && typeof score.teamId !== 'undefined'
        );

        const schoolScoresWithExtraPoints = teamAndSchoolScoresWithExtraPoints.filter(
          score => typeof score.userId === 'undefined' && typeof score.schoolId !== 'undefined'
        );

        //players
        promises = promises.concat(
          scores
            .filter(score => typeof score.userId !== 'undefined')
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => createIndividualScore(user, id, score))
        );

        promises = promises.concat(
          scores
            .filter(score => typeof score.userId !== 'undefined')
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => updateIndividualScore(user, id, score.scoreId, score))
        );

        //teams
        promises = promises.concat(
          teamScoresWithExtraPoints
            .filter(score => typeof score.userId === 'undefined' && typeof score.teamId !== 'undefined')
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => {
              let scoreData = {
                ...score,
                richScore: {
                  ...score.richScore,
                  ...(score.abbreviationResult && { abbreviationResult: score.abbreviationResult })
                }
              };
              delete scoreData.abbreviationResult;
              return createTeamScore(user, id, scoreData);
            })
        );

        promises = promises.concat(
          teamScoresWithExtraPoints
            .filter(score => typeof score.userId === 'undefined' && typeof score.teamId !== 'undefined')
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => {
              let scoreData = {
                ...score,
                richScore: {
                  ...score.richScore,
                  ...(score.abbreviationResult && { abbreviationResult: score.abbreviationResult })
                }
              };
              delete scoreData.abbreviationResult;
              return updateTeamScore(user, id, score.scoreId, scoreData);
            })
        );

        //schools
        promises = promises.concat(
          schoolScoresWithExtraPoints
            .filter(score => typeof score.userId === 'undefined' && typeof score.schoolId !== 'undefined')
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => {
              let scoreData = {
                ...score,
                richScore: {
                  ...score.richScore,
                  ...(score.abbreviationResult && { abbreviationResult: score.abbreviationResult })
                }
              };
              delete scoreData.abbreviationResult;
              return createSchoolScore(user, id, scoreData);
            })
        );

        promises = promises.concat(
          schoolScoresWithExtraPoints
            .filter(score => typeof score.userId === 'undefined' && typeof score.schoolId !== 'undefined')
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => {
              let scoreData = {
                ...score,
                richScore: {
                  ...score.richScore,
                  ...(score.abbreviationResult && { abbreviationResult: score.abbreviationResult })
                }
              };
              delete scoreData.abbreviationResult;
              return updateSchoolScore(user, id, score.scoreId, scoreData);
            })
        );

        BPromise.all(promises)
          .then(() => {
            if (isIndividualTournamentEvent(event)) {
              return getEventRecord(user, event.id, new Date(event.startTime));
            } else {
              return null;
            }
          })
          .then(recordResponse => {
            if (isIndividualTournamentEvent(event) && recordResponse) {
              const existingRecord = recordResponse ? recordResponse : null;

              const scoring = event.sport.scoring;
              const isMoreScores = scoring === SCORING.MORE_SCORES;
              const isMoreTime = scoring === SCORING.MORE_TIME;
              const isMoreResult = scoring === SCORING.MORE_RESULT;
              const isFirstToNPoints = scoring === SCORING.FIRST_TO_N_POINTS;
              const isNewTeamRecord =
                isMoreScores || isMoreTime || isMoreResult || isFirstToNPoints
                  ? teamWinner.score > existingRecord.record
                  : teamWinner.score < existingRecord.record;

              const schoolName = getSchoolNameFromTeamWinner(teamWinner, invitedSchools, teamToSchoolMap);

              if (isNewTeamRecord) {
                const newRecord = {
                  id: '',
                  createdAt: '',
                  updatedAt: '',
                  schoolId: inviterSchoolId,
                  sportId: event.sportId,
                  gender: event.gender === 'MALE_ONLY' ? 'MALE' : 'FEMALE',
                  date: new Date(event.startTime).toISOString(),
                  athlete: '',
                  area: schoolName,
                  record: teamWinner.score,
                  ages: event.ages,
                  eventId: event.id,
                  sport: event.sport
                };

                const eventDate = new Date(event.startTime).getTime();
                const recordDate = existingRecord ? new Date(existingRecord.date).getTime() : null;

                if (existingRecord && recordDate === eventDate) {
                  return updateSchoolUnionRecords(user, existingRecord.id, newRecord)
                    .then(() => {})
                    .catch(error => {
                      console.error('Error updating existing record:', error);
                    });
                } else {
                  return createSchoolUnionRecords(user, newRecord)
                    .then(() => {})
                    .catch(error => {
                      console.error('Error adding new record:', error);
                    });
                }
              }
            }
          })
          .then(res => {
            if (!isEventStatusFinished(event)) {
              return finishEvent(user, id);
            } else {
              return BPromise.resolve(true);
            }
          })
          .then(res => {
            if (typeof resultsMode !== 'undefined') {
              return setEventResultsMode(user, id, resultsMode);
            } else {
              return BPromise.resolve(true);
            }
          })
          .then(res => {
            this.setState({
              isResultsModalOpen: false,
              isLoading: true,
              isAutoAssignExtraPointsModal: false
            });
            this.load();
          });
        break;
      default:
        this.setState({
          isResultsModalOpen: false,
          isAutoAssignExtraPointsModal: false
        });
    }
  }

  saveWithoutAutoAssignExtraPoints(scores, resultsMode?: string): void {
    const { event } = this.state;
    const { user } = this.props;
    const { id } = event;
    const inviterSchoolId = event.inviterSchoolId;
    const { individualsData } = event;
    const { invitedSchools } = event;
    const { embeddedTeams } = event;
    const { players } = event;

    let promises = [];

    switch (true) {
      case isNonTeamSportEvent(event) && isInternalEvent(event):
      case isNonTeamSportEvent(event) && isHousesEvent(event):
      case isNonTeamSportEvent(event) && isInterSchoolsEvent(event):
        promises = promises.concat(
          scores
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => createIndividualScore(user, id, score))
        );

        promises = promises.concat(
          scores
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => updateIndividualScore(user, id, score.scoreId, score))
        );

        BPromise.all(promises)
          .then(() => {
            return getEventRecord(user, event.id, new Date(event.startTime));
          })
          .then(recordResponse => {
            const existingRecord = recordResponse ? recordResponse.record : null;
            const scoring = event.sport.scoring;
            const isMoreScores = scoring === SCORING.MORE_SCORES;
            const isMoreTime = scoring === SCORING.MORE_TIME;
            const isMoreResult = scoring === SCORING.MORE_RESULT;
            const isFirstToNPoints = scoring === SCORING.FIRST_TO_N_POINTS;

            const playersZeroScore = scores.filter(scoreObj => scoreObj.score === 0);
            const playersNonZeroScore = scores.filter(scoreObj => scoreObj.score !== 0);
            const playersZeroScoreSorted = sortByScore(event, playersNonZeroScore);
            const scoresSorted = [...playersZeroScoreSorted, ...playersZeroScore];

            const winner = scoresSorted[0];

            const isNewRecord =
              isMoreScores || isMoreTime || isMoreResult || isFirstToNPoints
                ? winner.score > existingRecord
                : winner.score < existingRecord;
            const winnerDetails = individualsData.find(individual => individual.userId === winner.userId);
            const winnerPlayer = players.find(player => player.userId === winner.userId);
            const winnerSchoolId = winnerPlayer ? winnerPlayer.schoolId : null;

            const winnerSchool = invitedSchools.find(school => school.id === winnerSchoolId);
            const winnerSchoolName = winnerSchool ? winnerSchool.name : '';

            if (isNewRecord) {
              const newRecord = {
                id: '',
                createdAt: '',
                updatedAt: '',
                schoolId: inviterSchoolId,
                sportId: event.sportId,
                gender: event.gender === 'MALE_ONLY' ? 'MALE' : 'FEMALE',
                date: new Date(event.startTime).toISOString(),
                athlete: winnerDetails ? `${winnerDetails.firstName} ${winnerDetails.lastName}` : 'Unknown Athlete',
                area: winnerSchoolName ? winnerSchoolName : '',
                record: winner.score,
                ages: event.ages,
                eventId: event.id,
                sport: event.sport
              };

              const eventDate = new Date(event.startTime).getTime();
              const recordDate = existingRecord ? new Date(existingRecord.date).getTime() : null;

              if (existingRecord && recordDate === eventDate) {
                console.log('Updating existing record with ID:', existingRecord.id);
                return updateSchoolUnionRecords(user, existingRecord.id, newRecord)
                  .then(() => {})
                  .catch(error => {
                    console.error('Error updating existing record:', error);
                  });
              } else {
                return createSchoolUnionRecords(user, newRecord)
                  .then(() => {})
                  .catch(error => {
                    console.error('Error adding new record:', error);
                  });
              }
            }
          })
          .then(() => {
            if (!isEventStatusFinished(event)) {
              return finishEvent(user, id);
            } else {
              return BPromise.resolve(true);
            }
          })
          .then(() => {
            this.setState({
              isResultsModalOpen: false,
              isAutoAssignExtraPointsModal: false,
              isLoading: true
            });
            this.load();
          });
        break;
      case isTeamOrTwoOnTwoSportEvent(event) && isInternalEvent(event):
        //players
        promises = promises.concat(
          scores
            .filter(score => typeof score.userId !== 'undefined')
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => createIndividualScore(user, id, score))
        );

        promises = promises.concat(
          scores
            .filter(score => typeof score.userId !== 'undefined')
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => updateIndividualScore(user, id, score.scoreId, score))
        );

        //teams
        promises = promises.concat(
          scores
            .filter(score => typeof score.userId === 'undefined')
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => createTeamScore(user, id, score))
        );

        promises = promises.concat(
          scores
            .filter(score => typeof score.userId === 'undefined')
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => updateTeamScore(user, id, score.scoreId, score))
        );

        BPromise.all(promises)
          .then(res => {
            if (!isEventStatusFinished(event)) {
              return finishEvent(user, id);
            } else {
              return BPromise.resolve(true);
            }
          })
          .then(res => {
            if (typeof resultsMode !== 'undefined') {
              return setEventResultsMode(user, id, resultsMode);
            } else {
              return BPromise.resolve(true);
            }
          })
          .then(res => {
            this.setState({
              isResultsModalOpen: false,
              isAutoAssignExtraPointsModal: false,
              isLoading: true
            });
            this.load();
          });
        break;
      case isTeamOrTwoOnTwoSportEvent(event) && isHousesEvent(event):
        //players
        promises = promises.concat(
          scores
            .filter(score => typeof score.userId !== 'undefined')
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => createIndividualScore(user, id, score))
        );

        promises = promises.concat(
          scores
            .filter(score => typeof score.userId !== 'undefined')
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => updateIndividualScore(user, id, score.scoreId, score))
        );

        //teams
        promises = promises.concat(
          scores
            .filter(score => typeof score.userId === 'undefined' && typeof score.teamId !== 'undefined')
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => createTeamScore(user, id, score))
        );

        promises = promises.concat(
          scores
            .filter(score => typeof score.userId === 'undefined' && typeof score.teamId !== 'undefined')
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => updateTeamScore(user, id, score.scoreId, score))
        );

        //houses
        promises = promises.concat(
          scores
            .filter(score => typeof score.houseId !== 'undefined')
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => createHouseScore(user, id, score))
        );

        promises = promises.concat(
          scores
            .filter(score => typeof score.houseId !== 'undefined')
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => updateHouseScore(user, id, score.scoreId, score))
        );

        BPromise.all(promises)
          .then(res => {
            if (!isEventStatusFinished(event)) {
              return finishEvent(user, id);
            } else {
              return BPromise.resolve(true);
            }
          })
          .then(res => {
            if (typeof resultsMode !== 'undefined') {
              return setEventResultsMode(user, id, resultsMode);
            } else {
              return BPromise.resolve(true);
            }
          })
          .then(res => {
            this.setState({
              isResultsModalOpen: false,
              isAutoAssignExtraPointsModal: false,
              isLoading: true
            });
            this.load();
          });
        break;
      case isTeamOrTwoOnTwoSportEvent(event) && isInterSchoolsEvent(event):
        const teamAndSchoolScores = scores.filter(
          score =>
            (typeof score.userId === 'undefined' && typeof score.teamId !== 'undefined') ||
            (typeof score.userId === 'undefined' && typeof score.schoolId !== 'undefined')
        );

        const teamAndSchoolZeroScores = teamAndSchoolScores.filter(team => team.score === 0);
        const teamAndSchoolNonZeroScores = teamAndSchoolScores.filter(team => team.score !== 0);
        const teamAndSchoolNonZeroScoresSorted = sortByScore(event, teamAndSchoolNonZeroScores);
        const teamAndSchoolScoresSorted = [...teamAndSchoolNonZeroScoresSorted, ...teamAndSchoolZeroScores];
        const teamWinner = teamAndSchoolScoresSorted[0];

        const teamToSchoolMap = {};
        embeddedTeams.forEach(team => {
          teamToSchoolMap[team._id] = team.schoolId;
        });

        const getSchoolNameFromTeamWinner = (teamWinner, invitedSchools, teamToSchoolMap) => {
          let schoolId;
          if (teamWinner.schoolId) {
            schoolId = teamWinner.schoolId;
          } else if (teamWinner.teamId) {
            schoolId = teamToSchoolMap[teamWinner.teamId];
          }
          if (!schoolId) {
            return null;
          }
          const school = invitedSchools.find(school => school.id === schoolId);
          return school ? school.name : null;
        };

        //players
        promises = promises.concat(
          scores
            .filter(score => typeof score.userId !== 'undefined')
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => createIndividualScore(user, id, score))
        );

        promises = promises.concat(
          scores
            .filter(score => typeof score.userId !== 'undefined')
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => updateIndividualScore(user, id, score.scoreId, score))
        );

        //teams
        promises = promises.concat(
          scores
            .filter(score => typeof score.userId === 'undefined' && typeof score.teamId !== 'undefined')
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => {
              let scoreData = {
                teamId: score.teamId,
                score: score.score,
                richScore: {
                  abbreviationResult: score.abbreviationResult
                }
              };
              return createTeamScore(user, id, scoreData);
            })
        );

        promises = promises.concat(
          scores
            .filter(score => typeof score.userId === 'undefined' && typeof score.teamId !== 'undefined')
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => {
              let scoreData = {
                teamId: score.teamId,
                score: score.score,
                scoreId: score.scoreId,
                richScore: {
                  abbreviationResult: score.abbreviationResult
                }
              };
              return updateTeamScore(user, id, score.scoreId, scoreData);
            })
        );

        //schools
        promises = promises.concat(
          scores
            .filter(score => typeof score.userId === 'undefined' && typeof score.schoolId !== 'undefined')
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => {
              let scoreData = {
                schoolId: score.schoolId,
                score: score.score,
                richScore: {
                  abbreviationResult: score.abbreviationResult
                }
              };
              return createSchoolScore(user, id, scoreData);
            })
        );

        promises = promises.concat(
          scores
            .filter(score => typeof score.userId === 'undefined' && typeof score.schoolId !== 'undefined')
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => {
              let scoreData = {
                schoolId: score.schoolId,
                score: score.score,
                scoreId: score.scoreId,
                richScore: {
                  abbreviationResult: score.abbreviationResult
                }
              };
              return updateSchoolScore(user, id, score.scoreId, scoreData);
            })
        );

        BPromise.all(promises)
          .then(() => {
            if (isIndividualTournamentEvent(event)) {
              return getEventRecord(user, event.id, new Date(event.startTime));
            } else {
              return null;
            }
          })
          .then(recordResponse => {
            if (isIndividualTournamentEvent(event) && recordResponse) {
              const existingRecord = recordResponse ? recordResponse : null;

              const scoring = event.sport.scoring;
              const isMoreScores = scoring === SCORING.MORE_SCORES;
              const isMoreTime = scoring === SCORING.MORE_TIME;
              const isMoreResult = scoring === SCORING.MORE_RESULT;
              const isFirstToNPoints = scoring === SCORING.FIRST_TO_N_POINTS;
              const isNewTeamRecord =
                isMoreScores || isMoreTime || isMoreResult || isFirstToNPoints
                  ? teamWinner.score > existingRecord.record
                  : teamWinner.score < existingRecord.record;

              const schoolName = getSchoolNameFromTeamWinner(teamWinner, invitedSchools, teamToSchoolMap);

              if (isNewTeamRecord) {
                const newRecord = {
                  id: '',
                  createdAt: '',
                  updatedAt: '',
                  schoolId: inviterSchoolId,
                  sportId: event.sportId,
                  gender: event.gender === 'MALE_ONLY' ? 'MALE' : 'FEMALE',
                  date: new Date(event.startTime).toISOString(),
                  athlete: '',
                  area: schoolName,
                  record: teamWinner.score,
                  ages: event.ages,
                  eventId: event.id,
                  sport: event.sport
                };

                const eventDate = new Date(event.startTime).getTime();
                const recordDate = existingRecord ? new Date(existingRecord.date).getTime() : null;

                if (existingRecord && recordDate === eventDate) {
                  return updateSchoolUnionRecords(user, existingRecord.id, newRecord)
                    .then(() => {})
                    .catch(error => {
                      console.error('Error updating existing record:', error);
                    });
                } else {
                  return createSchoolUnionRecords(user, newRecord)
                    .then(() => {})
                    .catch(error => {
                      console.error('Error adding new record:', error);
                    });
                }
              }
            }
          })
          .then(res => {
            if (!isEventStatusFinished(event)) {
              return finishEvent(user, id);
            } else {
              return BPromise.resolve(true);
            }
          })
          .then(res => {
            if (typeof resultsMode !== 'undefined') {
              return setEventResultsMode(user, id, resultsMode);
            } else {
              return BPromise.resolve(true);
            }
          })
          .then(res => {
            this.setState({
              isResultsModalOpen: false,
              isAutoAssignExtraPointsModal: false,
              isLoading: true
            });
            this.load();
          });
        break;
      default:
        this.setState({
          isResultsModalOpen: false,
          isAutoAssignExtraPointsModal: false
        });
    }
  }

  onSubmitEventSettingsForm = (data): void => {
    const { user } = this.props;
    const { activeSchool } = user;
    const { event } = this.state;
    const { startTime, endTime, venue } = data;

    const isEventUpdateNotificationCustomMessageEnabled = propz.get(
      user,
      ['activeSchool', 'isEventUpdateNotificationCustomMessageEnabled'],
      false
    );
    const notificationMode = isEventUpdateNotificationCustomMessageEnabled
      ? EVENT_NOTIFICATION_MODE.MANUAL
      : EVENT_NOTIFICATION_MODE.AUTO;

    const body = {
      venue,
      startTime: startTime.toISOString(),
      endTime: endTime.toISOString()
    };

    const isVenueExist = typeof venue !== 'undefined';
    const isVenuePointExist = typeof venue.point !== 'undefined';
    const isVenuePostcodeIdExist = typeof venue.postcodeId !== 'undefined';
    const isVenueCoordinatesExist = venue.point.coordinates.length > 0;

    switch (true) {
      case isVenueExist && isVenuePointExist && isVenueCoordinatesExist:
        if (!isVenuePostcodeIdExist) {
          propz.set(body, ['venue', 'postcodeId'], venue.id);
        }

        propz.set(body, ['venue', 'venueType'], getVenueTypeByPostcode(venue, activeSchool));
        break;

      case isVenueExist && !isVenuePointExist:
        propz.set(body, ['venue', 'venueType'], getVenueTypeByPostcode(venue, activeSchool));
        break;

      default:
        delete body.venue;
    }

    //TODO: fix it
    if (typeof venue.id !== 'undefined') {
      delete body.venue.id;
    }
    if (typeof venue.isHome !== 'undefined') {
      delete body.venue.isHome;
    }
    if (typeof venue.name !== 'undefined') {
      delete body.venue.name;
    }
    if (typeof venue.postcode !== 'undefined') {
      delete body.venue.postcode;
    }

    this.setState({
      isLoading: true
    });

    updateSchoolEvent(user, event.id, body, notificationMode)
      .then(res => {
        // const actionDescriptorId = propz.get(res, ['headers', 'action-descriptor-id']);
        // const isExistActionDescriptorId = typeof actionDescriptorId !== 'undefined';

        // if (isEventUpdateNotificationCustomMessageEnabled && isExistActionDescriptorId) {
        //   this.setState({
        //     actionDescriptorId: actionDescriptorId,
        //     isLoading: false,
        //     isEditableEventSettings: false,
        //     isReasonEventSettingsModalOpen: true
        //   });
        //   this.load();
        // } else {
        this.setState({
          isEditableEventSettings: false,
          actionDescriptorId: undefined,
          isLoading: false
        });
        this.load();
        // }
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        console.error(error);

        this.setState({
          isLoading: false,
          errorMessage: errorText,
          isErrorModalOpen: true
        });
      });
  };

  onCancelEventSettingsButtonClick = (): void => {
    this.setState({
      isEditableEventSettings: false
    });
  };

  onEditEventSettingsButtonClick = (): void => {
    this.setState({
      isEditableEventSettings: true
    });
  };

  cancelEventModalOpen = () => {
    this.setState({
      isCancelEventModalOpen: true
    });
  };

  cancelEventModalClose = (): void => {
    this.setState({
      isCancelEventModalOpen: false
    });
  };

  deleteEventModalOpen = () => {
    this.setState({
      isDeleteEventModalOpen: true
    });
  };

  deleteSuccessEventModalClose = (): void => {
    const { history } = this.props;
    this.setState({
      isSuccessDeleteEventModalOpen: false
    });
    history.push({
      pathname: '/events'
    });
  };

  deleteEventModalClose = () => {
    this.setState({
      isDeleteEventModalOpen: false
    });
  };

  reasonEventSettingModalClose = () => {
    this.setState({
      actionDescriptorId: undefined,
      isReasonEventSettingsModalOpen: false
    });
  };

  renderResultsModal() {
    const { isResultsModalOpen, event } = this.state;
    const resultsView = this.getResultsView();
    return (
      <SimpleModal
        customClass={isCricket(event) && !isMultipartyEvent(event) ? 'modal-lg' : 'mSchoolsResultsModalWidth'}
        isOpen={isResultsModalOpen}
      >
        {resultsView}
      </SimpleModal>
    );
  }

  renderExtraPointsModal() {
    const { isExtraPointsModalOpen } = this.state;
    const extraPointsView = this.getExtraPointsView();
    return (
      <SimpleModal customClass="mSchoolsResultsModalWidth" isOpen={isExtraPointsModalOpen}>
        {extraPointsView}
      </SimpleModal>
    );
  }

  onCloseVideoModal = () => {
    this.setState({
      isVideoModalOpen: false
    });
  };

  renderVideoModal() {
    const { isVideoModalOpen } = this.state;
    return (
      <SimpleModal
        title={'Add video'}
        body={'Success!'}
        buttonText={'Ok'}
        onButtonClick={this.onCloseVideoModal}
        isOpen={isVideoModalOpen}
      />
    );
  }

  renderEventSettingModal() {
    const { isEditableEventSettings, event } = this.state;
    const { user } = this.props;

    return (
      <SimpleModal
        customClass="mSchoolEventEditSettingsFormWidth"
        isOpen={isEditableEventSettings}
        title={'Edit event'}
      >
        <SchoolUnionEventEditSettingsForm
          user={user}
          schoolEvent={event}
          // forms={forms}
          onCancel={this.onCancelEventSettingsButtonClick}
          onSubmit={this.onSubmitEventSettingsForm}
        />
      </SimpleModal>
    );
  }

  onSubmitAddSchoolForm = (schoolId: string): void => {
    const { user } = this.props;
    const { event } = this.state;
    const { id } = event;

    this.setState({
      isLoading: true
    });

    addSchoolInEvent(user, id, schoolId).then(eventUpdated => {
      this.setState({
        isLoading: false,
        event: eventUpdated,
        isAddSchoolModalOpen: false
      });
      this.load();
    });
  };

  onCancelAddSchoolButtonClick = () => {
    this.setState({
      isAddSchoolModalOpen: false
    });
  };

  renderAddSchoolModal() {
    const { isAddSchoolModalOpen, event, participants } = this.state;
    const { user } = this.props;

    return (
      <SimpleModal isOpen={isAddSchoolModalOpen} title={'Add school'}>
        <TournamentEventAddSchoolForm
          event={event}
          user={user}
          participants={participants}
          onCancel={this.onCancelAddSchoolButtonClick}
          onSubmit={this.onSubmitAddSchoolForm}
        />
      </SimpleModal>
    );
  }

  renderRemoveParticipantModal() {
    const { isRemoveParticipantModalOpen, participant } = this.state;
    const participantTeam = propz.get(participant, ['team']);
    const participantTeamName = propz.get(participant, ['team', 'name']);
    const participantSchoolName = propz.get(participant, ['school', 'name']);

    const body = participantTeam
      ? `Are you sure you want to remove ${participantTeamName} from ${participantSchoolName}`
      : `Are you sure you want to remove opponent ${participantSchoolName}`;

    return (
      <SimpleModal
        isOpen={isRemoveParticipantModalOpen}
        title={'Remove participant'}
        onCloseClick={this.onCancelRemoveParticipantClick}
        onButtonClick={this.onRemoveParticipantModalClick}
        buttonText={'Remove'}
        body={body}
      />
    );
  }

  onCancelSentConsentRequestClick = () => {
    this.setState({
      isSendConsentRequestModalOpen: false
    });
  };

  renderSentConsentRequestModal() {
    const { isSendConsentRequestModalOpen, consentRequestTemplate } = this.state;

    return (
      <SimpleModal
        customClass="mSchoolConsentRequestFormWidth"
        isOpen={isSendConsentRequestModalOpen}
        title={'Send consent requests'}
      >
        <div>
          Here you can select questions that parents will be asked to answer when sending their parental consent. You
          can set up your questions on your school console.{' '}
        </div>
        <ConsentRequestForm
          template={consentRequestTemplate}
          onCancelClick={this.onCancelSentConsentRequestClick}
          onSendConsentRequestsClick={this.onSendConsentRequestClick}
        />
      </SimpleModal>
    );
  }

  onSendConsentRequestClick = (fields: ConsentRequestFormField[], deadlineForAnswers: string) => {
    const { event } = this.state;
    const { id: eventId } = event;
    const { user } = this.props;
    this.setState({
      isLoading: true,
      isSendConsentRequestModalOpen: false
    });

    let data;
    if (deadlineForAnswers) {
      const deadlineForAnswersDate = Moment(deadlineForAnswers, UK_INPUT_MASK_VALIDATION, true).toDate();
      const deadlineForAnswersStr = deadlineForAnswersDate.toISOString();
      data = {
        fields: fields,
        deadlineForAnswers: deadlineForAnswersStr
      };
    } else {
      data = {
        fields: fields
      };
    }

    sendConsentRequest(user, eventId, data).then(res => {
      this.setState({
        isLoading: false,
        isSuccessSendConsentRequestModalOpen: true
      });
    });
  };

  onRemoveParticipantClick = (participant: Participant, isOneParticipant: boolean): void => {
    this.setState({
      isRemoveParticipantModalOpen: true,
      participant: participant,
      isOneParticipant: isOneParticipant
    });
  };

  onCancelRemoveParticipantClick = () => {
    this.setState({
      isRemoveParticipantModalOpen: false,
      participant: undefined,
      isOneParticipant: false
    });
  };

  onCancelEventClick = () => {
    const { user } = this.props;
    const { event } = this.state;
    const eventId = event.id;
    const isEventUpdateNotificationCustomMessageEnabled = propz.get(
      user,
      ['activeSchool', 'isEventUpdateNotificationCustomMessageEnabled'],
      false
    );
    const notificationMode = isEventUpdateNotificationCustomMessageEnabled
      ? EVENT_NOTIFICATION_MODE.MANUAL
      : EVENT_NOTIFICATION_MODE.AUTO;

    this.setState({
      isLoading: true,
      isCancelEventModalOpen: false
    });

    cancelEvent(user, eventId, notificationMode)
      .then(res => {
        const currentActionDescriptorId = propz.get(res, ['headers', 'action-descriptor-id']);
        const updateEvent = res.data;

        if (isEventUpdateNotificationCustomMessageEnabled) {
          this.setState({
            event: updateEvent,
            actionDescriptorId: currentActionDescriptorId,
            isLoading: false,
            isReasonCancelModalOpen: true
          });
        } else {
          this.setState({
            event: updateEvent,
            isLoading: false,
            actionDescriptorId: undefined
          });
        }
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        console.error(error);

        this.setState({
          isLoading: false,
          errorMessage: errorText,
          isErrorModalOpen: true
        });
      });
  };

  reasonCancelModalClose = () => {
    this.setState({
      actionDescriptorId: undefined,
      isReasonCancelModalOpen: false
    });
  };

  renderCancelEventModal() {
    const { isCancelEventModalOpen } = this.state;
    const { user } = this.props;
    const { event } = this.state;
    const eventName = getEventGeneratedName(event, { user: user });

    return (
      <SimpleModal
        isOpen={isCancelEventModalOpen}
        title={'Cancel Event'}
        body={`Are you sure you want to cancel Event ${eventName}?`}
        buttonText={'Ok'}
        onButtonClick={this.onCancelEventClick}
        onCloseClick={this.cancelEventModalClose}
      />
    );
  }

  renderReasonCancelEventModal() {
    const { isReasonCancelModalOpen } = this.state;

    return (
      <SimpleModal isOpen={isReasonCancelModalOpen} title={'Notification'}>
        <SchoolEventReasonCancelForm
          onSubmit={this.onReasonCancelFormSubmit}
          onCloseClick={this.reasonCancelModalClose}
        />
      </SimpleModal>
    );
  }

  onReasonCancelFormSubmit = data => {
    const { actionDescriptorId, event } = this.state;
    const eventId = event.id;
    const { user } = this.props;
    const additionalDescription = propz.get(data, ['body'], '');
    const isExistActionDescriptorId = typeof actionDescriptorId !== 'undefined';

    const reasonForCancellationPromise = additionalDescription
      ? reasonForCancellation(user, eventId, additionalDescription)
      : BPromise.resolve();

    this.setState({
      isLoading: true
    });
    if (isExistActionDescriptorId) {
      reasonForCancellationPromise
        .then(res => getActionDescriptor(user, actionDescriptorId))
        .then(actionDescriptor => {
          const USERS_COUNT_IN_CHUNK = 30;

          const usersFiltered = [...actionDescriptor.affectedUserList];

          let userChunks = [];

          for (let i = 0; i < usersFiltered.length; i += USERS_COUNT_IN_CHUNK) {
            const chunk = usersFiltered.slice(i, i + USERS_COUNT_IN_CHUNK);
            userChunks.push(chunk);
          }

          const userChunksCount = userChunks.length;

          BPromise.all(
            userChunks.map((userChunk, index) => {
              const data = {
                manualConfirmationStatus: index === userChunksCount - 1 ? 'CONFIRMED' : 'AWAITING_CONFIRMATION',
                usersToNotifyList: userChunk,
                additionalDescription: additionalDescription
              };
              return updateActionDescriptor(user, actionDescriptor.id, data);
            })
          ).then(res => {
            this.setState({
              isLoading: false,
              isReasonCancelModalOpen: false,
              actionDescriptorId: undefined
            });
            return true;
          });
        })
        .catch(error => {
          const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
          console.error(error);
          this.setState({
            isLoading: false,
            isReasonCancelModalOpen: false,
            errorMessage: errorText,
            isErrorModalOpen: true
          });
        });
      this.load();
    } else {
      reasonForCancellationPromise.then(res => {
        console.error('actionDescriptorId is undefined');
        this.setState({
          isLoading: false,
          errorMessage: 'Error',
          isReasonEventSettingsModalOpen: false,
          isErrorModalOpen: true
        });
      });
    }
  };

  renderReasonEventSettingsModal() {
    const { isReasonEventSettingsModalOpen } = this.state;

    return (
      <SimpleModal isOpen={isReasonEventSettingsModalOpen} title={'Notification'}>
        <SchoolReasonEventSettingsForm
          onSubmit={this.onReasonEventSettingsFormSubmit}
          onCloseClick={this.reasonEventSettingModalClose}
        />
      </SimpleModal>
    );
  }

  onReasonEventSettingsFormSubmit = data => {
    const { actionDescriptorId } = this.state;
    const { user } = this.props;
    const additionalDescription = propz.get(data, ['body'], '');
    const isExistActionDescriptorId = typeof actionDescriptorId !== 'undefined';

    this.setState({
      isLoading: true
    });
    if (isExistActionDescriptorId) {
      getActionDescriptor(user, actionDescriptorId)
        .then(actionDescriptor => {
          const USERS_COUNT_IN_CHUNK = 30;
          const usersFiltered = [...actionDescriptor.affectedUserList];

          let userChunks = [];

          for (let i = 0; i < usersFiltered.length; i += USERS_COUNT_IN_CHUNK) {
            const chunk = usersFiltered.slice(i, i + USERS_COUNT_IN_CHUNK);
            userChunks.push(chunk);
          }
          const userChunksCount = userChunks.length;
          BPromise.all(
            userChunks.map((userChunk, index) => {
              const data = {
                manualConfirmationStatus: index === userChunksCount - 1 ? 'CONFIRMED' : 'AWAITING_CONFIRMATION',
                usersToNotifyList: userChunk,
                additionalDescription: additionalDescription
              };
              return updateActionDescriptor(user, actionDescriptor.id, data);
            })
          ).then(res => {
            this.setState({
              isLoading: false,
              isReasonEventSettingsModalOpen: false,
              actionDescriptorId: undefined
            });
            return true;
          });
        })
        .catch(error => {
          const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
          console.error(error);
          this.setState({
            isLoading: false,
            errorMessage: errorText,
            isReasonEventSettingsModalOpen: false,
            isErrorModalOpen: true
          });
        });
      this.load();
    } else {
      console.error('actionDescriptorId is undefined');
      this.setState({
        isLoading: false,
        errorMessage: 'Error',
        isReasonEventSettingsModalOpen: false,
        isErrorModalOpen: true
      });
    }
  };

  onDeleteEventClick = () => {
    const { user } = this.props;
    const { event } = this.state;
    const { id } = event;

    this.setState({
      isDeleteEventModalOpen: false,
      isLoading: false
    });

    deleteEvent(user, id)
      .then(res => {
        this.setState({
          isSuccessDeleteEventModalOpen: true
        });
      })

      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        console.error(error);
        this.setState({
          isLoading: false,
          errorMessage: errorText,
          isErrorModalOpen: true
        });
      });
  };

  renderDeleteEventModal() {
    const { isDeleteEventModalOpen } = this.state;
    const { user } = this.props;
    const { event } = this.state;
    const eventName = getEventGeneratedName(event, { user: user });

    return (
      <SimpleModal
        isOpen={isDeleteEventModalOpen}
        title={'Remove Event'}
        body={`Are you sure you want to remove Event ${eventName}?`}
        buttonText={'Delete'}
        onButtonClick={this.onDeleteEventClick}
        onCloseClick={this.deleteEventModalClose}
      />
    );
  }

  renderSuccessDeleteEventModal() {
    const { isSuccessDeleteEventModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isSuccessDeleteEventModalOpen}
        title={'Success'}
        body={`Event has been successfully removed`}
        buttonText={'Ok'}
        onButtonClick={this.deleteSuccessEventModalClose}
      />
    );
  }

  renderSuccessSendConsentRequestModal() {
    const { isSuccessSendConsentRequestModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isSuccessSendConsentRequestModalOpen}
        title={'Success'}
        body={`Consent requests have been successfully sent`}
        buttonText={'Ok'}
        onButtonClick={this.successSendConsentRequestModalClose}
      />
    );
  }

  successSendConsentRequestModalClose = () => {
    this.setState({
      isSuccessSendConsentRequestModalOpen: false
    });
  };

  onSubmitSendNotification = data => {
    const { availableUsers, event } = this.state;
    const { user } = this.props;
    const { title, text, players, parents } = data;

    const users = [].concat(players, parents);
    const usersToPost = availableUsers
      .filter(availableUser => users.some(user => user.value === true && user.name === availableUser.userId))
      .map(user => ({ userId: user.userId, permissionId: user.permissionId }));

    this.setState({
      isLoading: true
    });

    const dataToPost = {
      title,
      text,
      users: usersToPost
    };

    sendEventNotification(user, event.id, dataToPost).then(() => {
      this.setState({
        isLoading: false,
        isSendNotificationModalOpen: false
      });
    });
  };

  onCancelSendNotification = () => {
    this.setState({
      isSendNotificationModalOpen: false
    });
  };

  onSubmitSendNotificationEligibleStudents = data => {
    const { event } = this.state;
    const { user } = this.props;
    const { title, text } = data;

    this.setState({
      isLoading: true
    });

    const dataToPost = {
      title,
      text
    };

    sendEventNotificationEligibleStudents(user, event.id, dataToPost).then(() => {
      this.setState({
        isLoading: false,
        isSendNotificationEligibleStudentsModalOpen: false
      });
    });
  };

  onCancelSendNotificationEligibleStudents = () => {
    this.setState({
      isSendNotificationEligibleStudentsModalOpen: false
    });
  };

  onVideoFileChange = eventDescriptor => {
    const { user } = this.props;
    const { event } = this.state;
    const { id } = event;
    const video = eventDescriptor.target.files[0];
    const { name } = video;
    const safeName = getSafeFileName(name);

    this.setState({
      isLoading: true
    });

    const promises = [getVideoConfig(user), getKey(user)];

    let s3, folderKey, folderName, videoFullName, bucket;

    BPromise.all(promises)
      .then(([videoConfig, keyObj]) => {
        const { accessKey, secretAccessKey, bucketRegion } = videoConfig;
        bucket = videoConfig.bucketName;

        s3 = new AWS.S3({
          region: bucketRegion,
          credentials: {
            accessKeyId: accessKey,
            secretAccessKey: secretAccessKey
          }
        });

        folderKey = keyObj.key;
        folderName = `orig/${folderKey}/`;
        videoFullName = folderName + safeName;

        return s3.putObject({ Key: folderName, Bucket: bucket }).promise();
      })
      .then(res => {
        return s3.putObject({ Key: videoFullName, Bucket: bucket, Body: video }).promise();
      })
      .then(res => {
        const data = {
          key: folderKey,
          fileName: safeName,
          bucketName: bucket
        };
        return addConvertJob(user, data);
      })
      .then(jobObj => {
        const job = jobObj.Job;
        const jobId = job.Id;

        const data = {
          videos: [
            {
              fileName: safeName,
              key: folderKey,
              jobId: jobId
            }
          ]
        };

        return addEventPinnedVideo(user, id, data);
      })
      .then(eventNext => {
        this.setState({
          isLoading: false,
          event: eventNext,
          isVideoModalOpen: true
        });
      })
      .catch(error => {
        console.error(error);
        this.setState({
          isLoading: false,
          errorMessage: JSON.stringify(error),
          isErrorModalOpen: true
        });
      });
  };

  onCloseErrorClick = () => {
    this.setState({
      isErrorModalOpen: false,
      errorMessage: undefined
    });
  };

  renderError(): React.ReactNode {
    const { errorMessage, isErrorModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isErrorModalOpen}
        title={'Error'}
        buttonCancelText={'Ok'}
        onCloseClick={this.onCloseErrorClick}
      >
        <pre>{errorMessage}</pre>
      </SimpleModal>
    );
  }

  renderSendNotificationsModal() {
    const { isSendNotificationModalOpen, availableUsers } = this.state;
    return (
      <SimpleModal customClass={'mSchoolSendNotificationFormWidth'} isOpen={isSendNotificationModalOpen}>
        <SendNotificationsForm
          availableUsers={availableUsers}
          onSubmit={this.onSubmitSendNotification}
          onCancel={this.onCancelSendNotification}
        />
      </SimpleModal>
    );
  }

  renderSendNotificationEligibleStudentsModal() {
    const { isSendNotificationEligibleStudentsModalOpen } = this.state;
    return (
      <SimpleModal isOpen={isSendNotificationEligibleStudentsModalOpen}>
        <SendNotificationEligibleStudentsForm
          onSubmit={this.onSubmitSendNotificationEligibleStudents}
          onCancel={this.onCancelSendNotificationEligibleStudents}
        />
      </SimpleModal>
    );
  }

  renderVideoFileInput() {
    return (
      <input
        className="eAddVideoButton_fileInput"
        type="file"
        onChange={this.onVideoFileChange}
        ref={input => (this.videoFileInput = input)}
      />
    );
  }

  reloadEvent = (tabIndex: number) => {
    const { user } = this.props;
    const eventId = this.getEventId();

    this.setState({
      isLoading: true
    });

    getSchoolEvent(user, eventId).then(event => {
      this.setState({
        isLoading: false,
        event: event,
        tabIndex: tabIndex
      });
    });
  };

  onRemoveSchoolsClick = (): void => {
    this.setState({
      isRemoveSchoolsModalOpen: true
    });
  };

  renderRemoveSchoolsModal() {
    const { isRemoveSchoolsModalOpen, event } = this.state;
    return (
      <SimpleModal isOpen={isRemoveSchoolsModalOpen} title={'Remove schools'}>
        <RemoveSchoolsForm
          onClose={this.onCloseRemoveSchoolsModalClick}
          onSubmit={this.onSubmitRemoveSchoolsForm}
          event={event}
        />
      </SimpleModal>
    );
  }

  onCloseRemoveSchoolsModalClick = () => {
    this.setState({
      isRemoveSchoolsModalOpen: false
    });
  };

  onSubmitRemoveSchoolsForm = (selectedSchools): void => {
    const selectedSchoolsIds = selectedSchools.map(school => school.id);

    this.setState({
      schoolIdsForRemove: selectedSchoolsIds,
      isRemoveSchoolsConfirmationModalOpen: true,
      isRemoveSchoolsModalOpen: false
    });
  };

  renderConfirmationRemoveSchoolsModal = () => {
    const { isRemoveSchoolsConfirmationModalOpen, schoolIdsForRemove, participants } = this.state;

    const schoolsForRemoveWithParticipants = participants.filter(school => {
      const schoolIndividualPlayers = propz.get(school, ['individualPlayers'], []);
      const schoolIndividualPlayersCount = schoolIndividualPlayers.length;
      return schoolIndividualPlayersCount > 0;
    });

    const selectedSchoolsWithParticipants = schoolIdsForRemove.filter(selectedSchoolId => {
      return schoolsForRemoveWithParticipants.some(item => {
        const schoolWithParticipantId: string = propz.get(item, ['school', 'id'], '');
        return schoolWithParticipantId === selectedSchoolId;
      });
    });

    const selectedSchoolsForRemoveWithParticipantsCount = selectedSchoolsWithParticipants.length;
    const selectedSchoolsForRemoveCount = schoolIdsForRemove.length;

    return (
      <SimpleModal isOpen={isRemoveSchoolsConfirmationModalOpen} title={'Please confirm the action'}>
        <div>
          {`${selectedSchoolsForRemoveCount} schools will be removed from the event, ${selectedSchoolsForRemoveWithParticipantsCount} of them have participants added.`}
        </div>
        <Button
          onClick={this.onCloseConfirmationRemoveSchoolsModal}
          text={'Cancel'}
          customClass={'mt-3 mb-3 mr-3 btn-secondary'}
        />
        <Button onClick={this.onConfirmRemoveSchoolsClick} text={'Confirm'} customClass={'mt-3 mb-3 btn-primary'} />
      </SimpleModal>
    );
  };

  onConfirmRemoveSchoolsClick = () => {
    const { user } = this.props;
    const { event, schoolIdsForRemove } = this.state;
    const { id: eventId } = event;

    this.setState({
      isLoading: true
    });

    removeSchoolsFromEvent(user, eventId, schoolIdsForRemove).then(() => {
      this.setState({
        isRemoveSchoolsConfirmationModalOpen: false,
        isRemoveSchoolsModalOpen: false
      });

      this.load();
    });
  };

  onCloseConfirmationRemoveSchoolsModal = () => {
    this.setState({
      isRemoveSchoolsConfirmationModalOpen: false,
      isRemoveSchoolsModalOpen: true,
      schoolIdsForRemove: []
    });
  };

  cleanSchoolList = () => {
    const { user } = this.props;
    const { event, participants } = this.state;
    const { id: eventId } = event;
    const schoolsWithoutParticipants = participants.filter(participant => {
      const participantIndividualPlayers = propz.get(participant, ['individualPlayers'], []);
      const participantIndividualPlayersCount = participantIndividualPlayers.length;
      return participantIndividualPlayersCount === 0;
    });

    this.setState({
      isLoading: true
    });

    const schoolIdsForRemove = schoolsWithoutParticipants.map(item => {
      const itemSchoolId = propz.get(item, ['school', 'id'], '');
      return itemSchoolId;
    });

    removeSchoolsFromEvent(user, eventId, schoolIdsForRemove).then(() => {
      this.load();
    });
  };

  onRemoveParticipantModalClick = (): void => {
    const { event, participant, isOneParticipant } = this.state;
    const { user } = this.props;
    const { activeSchoolId } = user;
    const { team, school, house } = participant;
    const { id: participantSchoolId } = school;
    const isTeamExist = typeof team !== 'undefined';
    const isActiveSchoolParticipant = activeSchoolId === participantSchoolId;
    const eventId = this.getEventId();

    let promises = [];

    if (isNonTeamSportEvent(event)) {
      promises = promises.concat(
        participant.individualPlayers.map(player => removeSchoolEventIndividualPlayer(user, eventId, player.id))
      );
    }

    if (isTeamOrTwoOnTwoSportEvent(event) && isTeamExist) {
      const { id } = team;
      promises = promises.concat(removeSchoolEventTeam(user, eventId, id));
    }

    if (!isActiveSchoolParticipant && isOneParticipant) {
      promises = promises.concat(removeSchoolsFromEvent(user, eventId, [participantSchoolId]));
    }

    if (isHousesEvent(event)) {
      const { id: houseId } = house;
      const houses = event.houses.filter(eventHouseId => houseId !== eventHouseId);
      promises = promises.concat(updateSchoolEvent(user, eventId, { houses: houses }));
    }

    this.setState({
      isLoading: true,
      isRemoveParticipantModalOpen: false,
      participant: undefined,
      isOneParticipant: false
    });

    BPromise.all(promises).then(() => this.load());
  };

  onChangeSchoolClick = (participant: Participant): void => {
    this.setState({
      isChangeSchoolModalOpen: true,
      participant: participant
    });
  };

  onCancelChangeSchoolButtonClick = () => {
    this.setState({
      isChangeSchoolModalOpen: false,
      participant: undefined
    });
  };

  onSubmitChangeSchoolForm = (schoolId: string) => {
    const { user } = this.props;
    const { event, participant } = this.state;
    const { school } = participant;
    const { id: selectedSchoolId } = school;
    const { id } = event;

    this.setState({
      isLoading: true,
      isChangeSchoolModalOpen: false,
      participant: undefined
    });

    removeSchoolsFromEvent(user, id, [selectedSchoolId])
      .then(res => addSchoolInEvent(user, id, schoolId))
      .then(eventUpdated => {
        this.setState({
          isLoading: false,
          event: eventUpdated
        });
      });
  };

  renderChangeSchoolModal() {
    const { isChangeSchoolModalOpen, event, participant } = this.state;
    const { user } = this.props;

    return (
      <SimpleModal isOpen={isChangeSchoolModalOpen} title={'Change school'}>
        <SchoolEventChangeSchoolForm
          event={event}
          user={user}
          participant={participant}
          onCancel={this.onCancelChangeSchoolButtonClick}
          onSubmit={this.onSubmitChangeSchoolForm}
        />
      </SimpleModal>
    );
  }

  onCreateAnotherEventAlertCloseClick = () => {
    this.setState({
      isShowCreateAnotherEventAlert: false
    });
  };

  renderAutoAssignExtraPointsModal() {
    const { isAutoAssignExtraPointsModal, scores, resultsMode } = this.state;
    return (
      <SimpleModal
        isOpen={isAutoAssignExtraPointsModal}
        title={'Auto assign extra points'}
        body={'Would you like Scores to be assigned?'}
        buttonText={'Yes'}
        buttonCancelText={'No'}
        onButtonClick={() => {
          this.saveWithAutoAssignExtraPoints(scores, resultsMode);
        }}
        onCloseClick={() => {
          this.saveWithoutAutoAssignExtraPoints(scores, resultsMode);
        }}
      />
    );
  }

  onCancelTwitterButtonClick = () => {
    this.setState({
      isTwitterModalOpen: false
    });
  };

  onOpenButtonClick = () => {
    this.setState({
      isTwitterModalOpen: true
    });
  };

  isTweetButtonRender() {
    const { user } = this.props;
    const twitterIntegrations = propz.get(user, ['activeSchool', 'integrations', 'twitter'], []);
    const activeRole = propz.get(user, ['activeRole'], '');

    return activeRole === ROLE.ADMIN && twitterIntegrations.length > 0;
  }

  onSubmitTweet = values => {
    const { user } = this.props;
    const data = {
      text: values.text
    };

    this.setState({
      isLoading: true
    });

    const twitterId = values.twitterId;

    createTweet(user, twitterId, data).then(res => {
      this.onCancelTwitterButtonClick();
      this.setState({
        bodyText: 'Your tweet has successfully been posted!',
        isLoading: false,
        isPostTweetModalOpen: true
      });
    });
  };

  renderTwitterModal() {
    const { isTwitterModalOpen, event } = this.state;
    const { user } = this.props;

    return (
      <SimpleModal isOpen={isTwitterModalOpen} title={'Create tweet'}>
        <SchoolUnionEventTwitterModal
          event={event}
          user={user}
          onCancel={this.onCancelTwitterButtonClick}
          onSubmit={this.onSubmitTweet}
        />
      </SimpleModal>
    );
  }

  onPostTweetModalClose = () => {
    this.setState({
      isPostTweetModalOpen: false
    });
  };

  renderSuccessPostedModal(): React.ReactNode {
    const { isPostTweetModalOpen, bodyText } = this.state;

    return (
      <SimpleModal
        isOpen={isPostTweetModalOpen}
        title={''}
        body={bodyText}
        buttonText={'Ok'}
        onButtonClick={this.onPostTweetModalClose}
      />
    );
  }

  renderViewMapModal = (): React.ReactNode => {
    const { isViewMapModalOpen, event } = this.state;

    const point = propz.get(event, ['venue', 'point']);

    return (
      <SimpleModal isOpen={isViewMapModalOpen} onCloseClick={this.closeViewMapModal} title="View map">
        <Map point={point} customStylingClass="eChildEventVenueMap" isMarkerDraggable={false} />
      </SimpleModal>
    );
  };

  openViewMapModal = (): void => {
    this.setState({
      isViewMapModalOpen: true
    });
  };

  closeViewMapModal = (): void => {
    this.setState({
      isViewMapModalOpen: false
    });
  };

  render() {
    const {
      isLoading,
      prevEvent,
      nextEvent,
      event,
      isResultsModalOpen,
      isExtraPointsModalOpen,
      isEditableEventSettings,
      isSendNotificationModalOpen,
      isSendNotificationEligibleStudentsModalOpen,
      isVideoModalOpen,
      isErrorModalOpen,
      isAddSchoolModalOpen,
      isRemoveParticipantModalOpen,
      isChangeSchoolModalOpen,
      tabIndex,
      isSendConsentRequestModalOpen,
      isSuccessSendConsentRequestModalOpen,
      isShowCreateAnotherEventAlert,
      isAutoAssignExtraPointsModal,
      participants
    } = this.state;
    const { user, history } = this.props;

    if (isLoading) {
      return <Loader />;
    }

    const callbacks = this.getCallbacks();

    const actionItems = getActionList(user, event, callbacks);

    const isPrevEventExist = typeof prevEvent !== 'undefined';
    const isNextEventExist = typeof nextEvent !== 'undefined';

    const classes =
      isResultsModalOpen ||
      isEditableEventSettings ||
      isSendNotificationModalOpen ||
      isVideoModalOpen ||
      isSendNotificationEligibleStudentsModalOpen ||
      isErrorModalOpen ||
      isExtraPointsModalOpen ||
      isAddSchoolModalOpen ||
      isRemoveParticipantModalOpen ||
      isChangeSchoolModalOpen ||
      isSendConsentRequestModalOpen ||
      isAutoAssignExtraPointsModal ||
      isSuccessSendConsentRequestModalOpen
        ? 'mt-3 modal-open'
        : 'mt-3';

    return (
      <div className={classes}>
        {this.renderResultsModal()}
        {this.renderExtraPointsModal()}
        {this.renderAutoAssignExtraPointsModal()}
        {this.renderEventSettingModal()}
        {this.renderReasonEventSettingsModal()}
        {this.renderCancelEventModal()}
        {this.renderReasonCancelEventModal()}
        {this.renderDeleteEventModal()}
        {this.renderSuccessDeleteEventModal()}
        {this.renderSendNotificationsModal()}
        {this.renderSendNotificationEligibleStudentsModal()}
        {this.renderVideoModal()}
        {this.renderVideoFileInput()}
        {this.renderError()}
        {this.renderAddSchoolModal()}
        {this.renderChangeSchoolModal()}
        {this.renderRemoveParticipantModal()}
        {this.renderSentConsentRequestModal()}
        {this.renderSuccessSendConsentRequestModal()}
        {this.renderTwitterModal()}
        {this.renderSuccessPostedModal()}
        {this.renderRemoveSchoolsModal()}
        {this.renderConfirmationRemoveSchoolsModal()}
        {this.renderViewMapModal()}
        {isShowCreateAnotherEventAlert && (
          <div className={'bCreateOtherEventPanelWrapper'}>
            <SchoolEventsCreateAnotherEventAlert
              history={history}
              onCreateAnotherEventAlertCloseClick={this.onCreateAnotherEventAlertCloseClick}
            />
          </div>
        )}
        <div className={'d-flex justify-content-between'}>
          <ActionsButton actionItems={actionItems} />
          <SchoolEventNavigationButtons
            isPrevButtonDisabled={!isPrevEventExist}
            isNextButtonDisabled={!isNextEventExist}
            onPrevEventClick={this.onPrevEventClick}
            onNextEventClick={this.onNextEventClick}
          />
        </div>
        <SchoolEventMainInformation user={user} event={event} onViewMapClick={this.openViewMapModal} />
        {this.isTweetButtonRender() && <SchoolEventNewTweetButton onNewTweetButtonClick={this.onOpenButtonClick} />}
        <SchoolEventTabs
          history={history}
          user={user}
          eventInitial={event}
          participants={participants}
          tabIndex={tabIndex}
          reloadEvent={this.reloadEvent}
          onRemoveParticipantClick={this.onRemoveParticipantClick}
          onChangeSchoolClick={this.onChangeSchoolClick}
        />
      </div>
    );
  }
}
