import { DEFAULT_FILTER } from './../admin/user';
import { AppUser } from 'Src/views/App/App';
import axios from 'axios';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from 'Src/consts/table';
import { Count } from '../../../models/count';
import { TournamentTag, TournamentTagFilter } from '../../../models/tournamentTag';

/** USER TAG TABLE */

export function getTournamentTags(user: AppUser, filter = DEFAULT_FILTER): Promise<TournamentTag[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter
    }
  };

  return axios
    .get<TournamentTag[]>(`${window.apiBase}/i/schools/${activeSchoolId}/tournamentTags`, config)
    .then(response => {
      return response.data;
    });
}

export function getAllTournamentTags(user: AppUser, where?): Promise<TournamentTag[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios
    .get<TournamentTag[]>(`${window.apiBase}/i/schools/${activeSchoolId}/tournamentTags`, config)
    .then(response => {
      return response.data;
    });
}

export function getTournamentTagsCount(user: AppUser, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/tournamentTags`, config).then(response => {
    // count
    return { count: response.data.length };
  });
}

export function createTournamentTag(user: AppUser, data) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.post(`${window.apiBase}/i/schools/${activeSchoolId}/tournamentTags`, data, config).then(response => {
    return response.data;
  });
}

export function updateTournamentTag(user: AppUser, data, tournamentTagId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .put(`${window.apiBase}/i/schools/${activeSchoolId}/tournamentTags/${tournamentTagId}`, data, config)
    .then(response => {
      return response.data;
    });
}

export function deleteTournamentTag(user: AppUser, tournamentTagId) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .delete(`${window.apiBase}/i/schools/${activeSchoolId}/tournamentTags/${tournamentTagId}`, config)
    .then(response => {
      return response.data;
    });
}
