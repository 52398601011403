import * as React from 'react';
import * as propz from 'propz';
import { Component } from 'react';
import * as BPromise from 'bluebird';
import { AppUser } from 'Src/views/App/App';
import { getRequest, updateRequest } from 'Src/helpers/service/admin/requests';
import { Loader } from 'Src/components/Loader/Loader';
import { ROLE } from 'Src/consts/user';
import { RequestAcceptStudent } from 'Src/components/RequestAcceptStudentForm/RequestAcceptStudent';
import './RequestAcceptPage.css';
import { PERMISSION_REQUEST_STATUS } from 'Src/consts/permissionRequest';
import { RequestAcceptParent } from 'Src/components/RequestAcceptParentForm/RequestAcceptParent';
import { getSchoolStudents } from 'Src/helpers/service/admin/students';
import { RequestAcceptTeacherOrCoach } from 'Src/components/RequestAcceptTeacherOrCoachForm/RequestAcceptTeacherOrCoachForm';
import { History } from 'history';
import { DEFAULT_SKIP } from 'Src/consts/table';
import { getSchoolForms, getSchoolHouses, getSchoolSports } from 'Src/helpers/service/admin/user';
import { SchoolForm } from '../../../../../../models/form';

interface Props {
  user: AppUser;
  history: History;
  match: any;
  setNeedUpdate: () => void;
}

interface State {
  request: any;
  isLoading: boolean;
  isUserAlreadyLinkedError: boolean;
  form: any;
  house: any;
  student: any;
  sports: any[];
  students: any[];
}

export class RequestAcceptPage extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      request: undefined,
      isLoading: false,
      isUserAlreadyLinkedError: false,
      form: undefined,
      house: undefined,
      student: undefined,
      sports: [],
      students: []
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    const user = this.props.user;
    const requestId = this.props.match.params.requestId;
    let requestData;

    getRequest(user, requestId)
      .then(request => {
        requestData = { ...request };
        const comment = propz.get(request, ['requestedPermission', 'comment'], '');

        const leftBracerIndex = comment.indexOf('[');
        const rightBracerIndex = comment.indexOf(']');

        const studentName =
          leftBracerIndex !== -1 && rightBracerIndex !== -1
            ? comment.substring(leftBracerIndex + 1, rightBracerIndex)
            : '';

        if (studentName) {
          const studentNameParts = studentName.split(' ');
          const filterNames = [
            {
              firstName: { like: studentNameParts[0], options: 'i' },
              lastName: { like: studentNameParts[1], options: 'i' }
            },
            {
              firstName: { like: studentNameParts[1], options: 'i' },
              lastName: { like: studentNameParts[0], options: 'i' }
            }
          ];

          const filter = {
            limit: 10,
            skip: DEFAULT_SKIP,
            where: {
              $or: filterNames,
              gender: requestData.requestedPermission.childGender
            }
          };

          return getSchoolStudents(user, filter);
        } else {
          return BPromise.resolve([]);
        }
      })
      .then(students => {
        if (students.length === 0) {
          const filter = {
            limit: 10,
            skip: DEFAULT_SKIP,
            where: {
              lastName: requestData.requester.lastName,
              gender: requestData.requestedPermission.childGender
            }
          };
          getSchoolStudents(user, filter).then(studentsByLastNameAndGender => {
            this.setState({
              request: requestData,
              isLoading: false,
              sports: requestData.sports,
              students: studentsByLastNameAndGender
            });
          });
        } else {
          this.setState({
            request: requestData,
            isLoading: false,
            sports: requestData.sports,
            students: students
          });
        }
      });
  }

  onSelectForm = form => {
    this.setState({
      form: form,
      student: undefined
    });
  };

  onSelectHouse = house => {
    this.setState({
      house: house,
      student: undefined
    });
  };

  onSelectStudent = student => {
    this.setState({
      form: student.form,
      house: student.house,
      student: student,
      isUserAlreadyLinkedError: false
    });
  };

  onChangeToStudent = (event, student) => {
    event.preventDefault();
    this.onSelectStudent(student);
  };

  onSelectSport = sport => {
    const { sports } = this.state;

    const sportsUpdated = [...sports, sport];

    this.setState({
      sports: sportsUpdated
    });
  };

  onClearSportButtonClick = sportId => {
    const { sports } = this.state;

    const sportsUpdated = sports.filter(sport => sport.id !== sportId);

    this.setState({
      sports: sportsUpdated
    });
  };

  onClear = () => {
    this.setState({
      form: undefined,
      house: undefined,
      student: undefined,
      isUserAlreadyLinkedError: false
    });
  };

  onAccept = (): void => {
    const { request, form, house, student, sports } = this.state;
    const { requester } = request;

    const user = this.props.user;
    const requestId = request.id;
    const preset = propz.get(request, ['requestedPermission', 'preset']);
    const studentParents = propz.get(student, ['parents'], []);
    const isRequestedPermissionPresetCoach = preset === ROLE.COACH;
    const isRequestedPermissionPresetTeacher = preset === ROLE.TEACHER;
    const isRequestedPermissionPresetParent = preset === ROLE.PARENT;
    const isRequestedPermissionPresetStudent = preset === ROLE.STUDENT;

    let data = {};

    switch (true) {
      case isRequestedPermissionPresetStudent:
        data = {
          status: PERMISSION_REQUEST_STATUS.ACCEPTED,
          houseId: house.id,
          formId: form.id
        };
        break;
      case isRequestedPermissionPresetParent:
        data = {
          status: PERMISSION_REQUEST_STATUS.ACCEPTED,
          studentId: student.id
        };
        break;
      case isRequestedPermissionPresetCoach:
      case isRequestedPermissionPresetTeacher:
        data = {
          status: PERMISSION_REQUEST_STATUS.ACCEPTED,
          sportIds: sports.map(sport => sport.id)
        };
    }

    this.setState({
      isLoading: true
    });

    const { firstName: requesterFirstName, lastName: requesterLastName } = requester;

    const isRequesterLinkedToChildAsParent = studentParents.some(item => {
      const { firstName: parentFirstName, lastName: parentLastName } = item;

      return parentFirstName === requesterFirstName && parentLastName === requesterLastName;
    });

    if (isRequesterLinkedToChildAsParent) {
      this.setState({
        isLoading: false,
        isUserAlreadyLinkedError: true
      });
    } else {
      updateRequest(user, requestId, data).then(res => {
        this.props.setNeedUpdate();

        this.props.history.push({
          pathname: '/requests'
        });

        this.setState({
          isLoading: false
        });
      });
    }
  };

  searchFunctionForms = (text: string): Promise<SchoolForm[]> => {
    const user = this.props.user;

    if (text === '') {
      this.setState({
        form: undefined
      });
    }

    const filter = {
      where: {
        name: {
          like: text,
          options: 'i'
        }
      },
      limit: 10,
      skip: DEFAULT_SKIP
    };

    return getSchoolForms(user, filter);
  };

  searchFunctionHouses = (text: string) => {
    const user = this.props.user;

    if (text === '') {
      this.setState({
        house: undefined
      });
    }

    const filter = {
      where: {
        name: {
          like: text,
          options: 'i'
        }
      },
      limit: 10,
      skip: DEFAULT_SKIP
    };

    return getSchoolHouses(user, filter);
  };

  searchFunctionSports = (text: string) => {
    const user = this.props.user;
    const { sports } = this.state;

    let filter;

    if (typeof text === 'undefined' || text === '') {
      filter = {
        where: {}
      };
    } else {
      filter = {
        where: {
          $or: [{ isSchoolEventsAvailable: true }, { isClubEventsAvailable: true }],
          name: {
            like: text,
            options: 'i'
          }
        }
      };
    }

    if (sports.length > 0) {
      filter.where.id = { $nin: sports.map(sport => sport.id) };
    }

    return getSchoolSports(user, filter);
  };

  searchFunctionStudents = (text: string) => {
    const { form, house } = this.state;
    const user = this.props.user;

    let filter;

    if (text === '') {
      this.setState({
        student: undefined
      });
    }

    if (typeof text === 'undefined' || text === '') {
      filter = {
        limit: 10,
        where: {}
      };
    } else {
      filter = {
        limit: 10,
        where: {
          $or: [{ firstName: { like: text, options: 'i' } }, { lastName: { like: text, options: 'i' } }]
        }
      };
    }

    // form Id is optional so it can be empty
    if (typeof form !== 'undefined') {
      filter.where.formId = { $in: [form.id] };
    }

    // house Id is optional so it can be empty
    if (typeof house !== 'undefined') {
      filter.where.houseId = { $in: [house.id] };
    }

    return getSchoolStudents(user, filter);
  };

  getName = (item): string => {
    return item.name;
  };

  getStudentTitle = (student): string => {
    const firstName = student.firstName || '';
    const lastName = student.lastName || '';
    return `${firstName} ${lastName}`;
  };

  render() {
    const { isLoading, form, house, student, sports, students, isUserAlreadyLinkedError } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const request = this.state.request;
    const preset = propz.get(request, ['requestedPermission', 'preset']);
    const isRequestedPermissionPresetCoach = preset === ROLE.COACH;
    const isRequestedPermissionPresetTeacher = preset === ROLE.TEACHER;
    const isRequestedPermissionPresetParent = preset === ROLE.PARENT;
    const isRequestedPermissionPresetStudent = preset === ROLE.STUDENT;

    switch (true) {
      case isRequestedPermissionPresetStudent:
        return (
          <RequestAcceptStudent
            onSelectForm={this.onSelectForm}
            onSelectHouse={this.onSelectHouse}
            onAcceptPermission={this.onAccept}
            request={request}
            searchFunctionForms={this.searchFunctionForms}
            searchFunctionHouses={this.searchFunctionHouses}
            getFormOrHouseTitle={this.getName}
            form={form}
            house={house}
          />
        );
      case isRequestedPermissionPresetParent:
        return (
          <RequestAcceptParent
            onSelectForm={this.onSelectForm}
            onSelectHouse={this.onSelectHouse}
            onAcceptPermission={this.onAccept}
            onClearPermission={this.onClear}
            isUserAlreadyLinkedError={isUserAlreadyLinkedError}
            request={request}
            onSelectStudent={this.onSelectStudent}
            searchFunctionForms={this.searchFunctionForms}
            searchFunctionHouses={this.searchFunctionHouses}
            searchFunctionStudents={this.searchFunctionStudents}
            getFormOrHouseTitle={this.getName}
            getStudentTitle={this.getStudentTitle}
            onChangeToStudent={this.onChangeToStudent}
            form={form}
            house={house}
            student={student}
            students={students}
          />
        );
      case isRequestedPermissionPresetCoach:
      case isRequestedPermissionPresetTeacher:
        return (
          <RequestAcceptTeacherOrCoach
            sports={sports}
            getElementTitle={this.getName}
            searchFunctionSports={this.searchFunctionSports}
            onSelectSport={this.onSelectSport}
            onClearSportButtonClick={this.onClearSportButtonClick}
            onAcceptPermission={this.onAccept}
          />
        );
      default:
        return <div>Can not find view for render</div>;
    }
  }
}
