import axios from 'axios';
import { AppUser } from 'Src/views/App/App';
import { DEFAULT_FILTER } from './user';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from 'Src/consts/table';
import { Count } from '../../../models/count';
import { TournamentTeamPlayer } from '../../../models/tournamentTeamPlayer';
import { TournamentTeam } from '../../../models/tournamentTeam';

export function getSchoolTournamentTeam(user: AppUser, tournamentId: string, teamId: string): Promise<TournamentTeam> {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .get<TournamentTeam>(
      `${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/teams/${teamId}`,
      config
    )
    .then(response => {
      return response.data;
    });
}

export function getSchoolTournamentTeamPlayers(
  user: AppUser,
  tournamentId: string,
  teamId: string,
  filter = DEFAULT_FILTER
): Promise<TournamentTeamPlayer[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter
    }
  };

  return axios
    .get<TournamentTeamPlayer[]>(
      `${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/teams/${teamId}/players`,
      config
    )
    .then(response => {
      return response.data;
    });
}

export function getAllSchoolTournamentTeamPlayers(
  user: AppUser,
  tournamentId: string,
  teamId: string,
  where?
): Promise<TournamentTeamPlayer[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios
    .get<TournamentTeamPlayer[]>(
      `${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/teams/${teamId}/players`,
      config
    )
    .then(response => {
      return response.data;
    });
}

export function getSchoolTournamentTeamPlayersCount(
  user: AppUser,
  tournamentId: string,
  teamId: string,
  where?
): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where
        }
      }
    };
  } else {
    config = {
      headers: { usid: key }
    };
  }

  return axios
    .get<Count>(
      `${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/teams/${teamId}/players/count`,
      config
    )
    .then(response => {
      return response.data;
    });
}

export interface SchoolTournamentTeamPlayersBatchData {
  add?: SchoolTournamentTeamPlayerBatchData[];
  remove?: string[];
}
interface SchoolTournamentTeamPlayerBatchData {
  userId: string;
  permissionId: string;
}

export function updateSchoolTournamentTeamPlayersBatch(
  user: AppUser,
  tournamentId: string,
  teamId: string,
  data: SchoolTournamentTeamPlayersBatchData
) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .post(
      `${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/teams/${teamId}/players/batch`,
      data,
      config
    )
    .then(response => {
      return response.data;
    });
}
