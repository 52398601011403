import * as React from 'react';
import * as BPromise from 'bluebird';
import { AppUser } from 'Src/views/App/App';
import { History, Location } from 'history';
import { parse } from 'query-string';
import { SchoolEvent } from 'Src/models/event';
import {
  getSchoolEvent,
  createEventResultsCompleteness,
  updateEventResultsCompleteness
} from 'Src/helpers/service/admin/event';
import { Loader } from 'Src/components/Loader/Loader';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { getEventGeneratedName } from 'Src/helpers/accessor/accessor';
import { ArbiterBoardPlayers } from './ArbiterBoardPlayers';
import { Photo } from 'Src/models/photo';
import { Video } from 'Src/models/video';
import { ArbiterBoardPlayer } from './ArbiterBoardPlayer';
import { ArbiterBoardGallery } from './ArbiterBoardGallery';
import { ARBITER_BOARD_GALLERY_ITEM_TYPE, ARBITER_BOARD_MODE_TYPE } from 'Src/types/arbiterBoard';
import { GALLERY_ITEM_PHOTO, GALLERY_ITEM_VIDEO, MODE_EDIT, MODE_VIEW } from 'Src/consts/arbiterBoard';
import { ROLE } from 'Src/consts/user';
import { createIndividualScore, updateIndividualScore } from 'Src/helpers/service/admin/eventResults';
import { uploadImage } from 'Src/helpers/service/image';
import * as AWS from 'aws-sdk';
import { getSafeFileName } from 'Src/helpers/file/file';
import { getKey } from 'Src/helpers/service/nobody/key';
import { getVideoConfig } from 'Src/helpers/service/nobody/config';
import { addConvertJob } from 'Src/helpers/service/nobody/aws';
import {
  addEventPhoto,
  deleteEventPhoto,
  getAllEventPhotos,
  updateEventPhoto
} from 'Src/helpers/service/admin/eventPhotos';
import {
  addEventVideo,
  deleteEventVideo,
  getAllEventVideos,
  updateEventVideo
} from 'Src/helpers/service/admin/eventVideos';
import * as propz from 'propz';
import { ROLE_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/user';
import { ACCESS_PRESET } from 'Src/consts/album';
import * as Moment from 'moment';
import { ACCESS_PRESET_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/album';
import './ArbiterBoard.scss';
import { Button } from 'Src/components/Button/Button';
import { ACCESS_PRESET_TYPE } from 'Src/types/album';
import { ArbiterBoardGalleryFullScreen } from './ArbiterBoardGalleryFullScreen';

export interface AdminBoardGalleryItem {
  id: string;
  url: string;
  type: ARBITER_BOARD_GALLERY_ITEM_TYPE;
  accessPreset: ACCESS_PRESET_TYPE;
  uploadedAt: string;
  uploadedBy: string;
}

//For testing only!!!
// const FAKE_PHOTOS: Photo[] = [
//   {
//     id: '60193791239b1d522068e9eb',
//     uploadedAt: '2021-02-02T12:42:05.087Z',
//     originalFileName: '12.jpg',
//     picUrl: '//localhost:3001/images/gh1gjjcp8jky5xi9of5k9xj1pwh783r0dfb6_1612265360444.jpg',
//     accessPreset: 'PRIVATE',
//     isOnSale: false,
//     author: {
//       role: 'STUDENT',
//       schoolId: '6005440547f0c8504880bf04',
//       userId: '600a999566ad86523c5eec8f',
//       isSuperadmin: false
//     },
//     saleLink: 'https://store.squadintouch.com/products/60193791239b1d522068e9eb'
//   },
//   {
//     id: '60193796239b1d522068e9f3',
//     uploadedAt: '2021-02-02T12:54:05.087Z',
//     originalFileName: '2.jpg',
//     picUrl: '//localhost:3001/images/6rtlprjpxc84g65cticpy1yign2d0zx09ejm_1612265365118.jpg',
//     accessPreset: 'PRIVATE',
//     isOnSale: false,
//     author: {
//       role: 'STUDENT',
//       schoolId: '6005440547f0c8504880bf04',
//       userId: '600a999566ad86523c5eec8f',
//       isSuperadmin: false
//     },
//     saleLink: 'https://store.squadintouch.com/products/60193796239b1d522068e9f3'
//   },
//   {
//     id: '601937a5239b1d522068ea05',
//     uploadedAt: '2021-02-02T12:33:05.087Z',
//     originalFileName: '4.jpg',
//     picUrl: '//localhost:3001/images/2l8dqtkv7oqmey4ggfwpk80wx5uwbzq6qs2v_1612265380521.jpg',
//     accessPreset: 'PRIVATE',
//     isOnSale: false,
//     author: {
//       role: 'STUDENT',
//       schoolId: '6005440547f0c8504880bf04',
//       userId: '600a999566ad86523c5eec8f',
//       isSuperadmin: false
//     },
//     saleLink: 'https://store.squadintouch.com/products/601937a5239b1d522068ea05'
//   },
//   {
//     id: '601937ba239b1d522068ea13',
//     uploadedAt: '2021-02-02T12:21:05.087Z',
//     originalFileName: '5.jpg',
//     picUrl: '//localhost:3001/images/w2tlxzhgnklblmurum85llk2ol3noj0fod74_1612265401202.jpg',
//     accessPreset: 'PRIVATE',
//     isOnSale: false,
//     author: {
//       role: 'STUDENT',
//       schoolId: '6005440547f0c8504880bf04',
//       userId: '600a999566ad86523c5eec8f',
//       isSuperadmin: false
//     },
//     saleLink: 'https://store.squadintouch.com/products/601937ba239b1d522068ea13'
//   },
//   {
//     id: '601937c2239b1d522068ea1b',
//     uploadedAt: '2021-02-02T10:38:05.087Z',
//     originalFileName: '6.jpg',
//     picUrl: '//localhost:3001/images/jxm7fhrshqvvt2gyrvucsf2ojqnn3yh0w8ur_1612265410061.jpg',
//     accessPreset: 'PRIVATE',
//     isOnSale: false,
//     author: {
//       role: 'STUDENT',
//       schoolId: '6005440547f0c8504880bf04',
//       userId: '600a999566ad86523c5eec8f',
//       isSuperadmin: false
//     },
//     saleLink: 'https://store.squadintouch.com/products/601937c2239b1d522068ea1b'
//   },
//   {
//     id: '601937c8239b1d522068ea23',
//     uploadedAt: '2021-02-02T12:38:05.087Z',
//     originalFileName: '7.jpg',
//     picUrl: '//localhost:3001/images/05huwezwzwyezq2peh8b7ecp99s0m6p2iydp_1612265415820.jpg',
//     accessPreset: 'PRIVATE',
//     isOnSale: false,
//     author: {
//       role: 'STUDENT',
//       schoolId: '6005440547f0c8504880bf04',
//       userId: '600a999566ad86523c5eec8f',
//       isSuperadmin: false
//     },
//     saleLink: 'https://store.squadintouch.com/products/601937c8239b1d522068ea23'
//   },
//   {
//     id: '601937cf239b1d522068ea2b',
//     uploadedAt: '2021-02-02T12:38:05.087Z',
//     originalFileName: '8.jpg',
//     picUrl: '//localhost:3001/images/fv59lvuplx6o228yq62e7xm7aj23ve90i3il_1612265422716.jpg',
//     accessPreset: 'PRIVATE',
//     isOnSale: false,
//     author: {
//       role: 'STUDENT',
//       schoolId: '6005440547f0c8504880bf04',
//       userId: '600a999566ad86523c5eec8f',
//       isSuperadmin: false
//     },
//     saleLink: 'https://store.squadintouch.com/products/601937cf239b1d522068ea2b'
//   },
//   {
//     id: '601937d6239b1d522068ea33',
//     uploadedAt: '2021-02-02T12:38:05.087Z',
//     originalFileName: '9.jpg',
//     picUrl: '//localhost:3001/images/0jnoeyghpy51h2ut1ukixu0biq9gwpe9xzpn_1612265429944.jpg',
//     accessPreset: 'PRIVATE',
//     isOnSale: false,
//     author: {
//       role: 'STUDENT',
//       schoolId: '6005440547f0c8504880bf04',
//       userId: '600a999566ad86523c5eec8f',
//       isSuperadmin: false
//     },
//     saleLink: 'https://store.squadintouch.com/products/601937d6239b1d522068ea33'
//   }
// ];
// const FAKE_VIDEOS: Video[] = [
//   {
//     id: '601937e8239b1d522068ea42',
//     jobId: '1612265446903-ur5hfj',
//     key: '601937de239b1d522068ea37',
//     fileName: 'file_example_MP4_1920_18MG (1).mp4',
//     uploadedAt: '2021-02-02T12:43:05.087Z',
//     author: {
//       role: 'STUDENT',
//       schoolId: '6005440547f0c8504880bf04',
//       userId: '600a999566ad86523c5eec8f',
//       isSuperadmin: false
//     },
//     streamData: [
//       {
//         url:
//           'https://squad-in-touch-video-dev.s3-eu-west-1.amazonaws.com/AppleHLS/convert/601937de239b1d522068ea37/file_example_MP4_1920_18MG (1).m3u8',
//         _id: '601937f7239b1d522068ea4b',
//         quality: 'MEDIUM'
//       }
//     ],
//     status: 'COMPLETE',
//     accessPreset: 'PRIVATE'
//   },
//   {
//     id: '601937fb239b1d522068ea52',
//     jobId: '1612265466362-8y1q8u',
//     key: '601937f5239b1d522068ea46',
//     fileName: '1280.mp4',
//     uploadedAt: '2021-02-02T12:55:05.087Z',
//     author: {
//       role: 'STUDENT',
//       schoolId: '6005440547f0c8504880bf04',
//       userId: '600a999566ad86523c5eec8f',
//       isSuperadmin: false
//     },
//     streamData: [
//       {
//         // url:
//         //   'https://squad-in-touch-video-dev.s3-eu-west-1.amazonaws.com/AppleHLS/convert/601937f5239b1d522068ea46/1280.m3u8',
//         url: undefined,
//         _id: '6019380b239b1d522068ea5c',
//         quality: 'MEDIUM'
//       }
//     ],
//     status: 'COMPLETE',
//     accessPreset: 'PRIVATE'
//   }
// ];

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

interface State {
  event: SchoolEvent;
  isLoading: boolean;
  isAccessPresetModalOpen: boolean;
  selectedUserId: string;
  photos: Photo[];
  videos: Video[];
  mode: ARBITER_BOARD_MODE_TYPE;
  selectedGalleryItemId: string;
  errorMessage: string;
  isUpdateAccessPreset: boolean;
  isErrorModalOpen: boolean;
}

export class ArbiterBoard extends React.Component<Props, State> {
  photoFileInputRef: any;
  videoFileInputRef: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      event: undefined,
      isLoading: true,
      isAccessPresetModalOpen: false,
      selectedUserId: '',
      selectedGalleryItemId: '',
      errorMessage: '',
      photos: [],
      videos: [],
      mode: MODE_VIEW,
      isUpdateAccessPreset: false,
      isErrorModalOpen: false
    };

    this.photoFileInputRef = React.createRef();
    this.videoFileInputRef = React.createRef();
  }

  getEventId(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    const eventId = search.id;
    return eventId;
  }

  componentDidMount() {
    this.load();
  }

  load() {
    const { user } = this.props;
    const eventId = this.getEventId();

    this.setState({
      isLoading: true
    });

    const promises = [
      getSchoolEvent(user, eventId),
      getAllEventVideos(user, eventId),
      getAllEventPhotos(user, eventId)
    ];

    BPromise.all(promises).then(([event, videos, photos]) => {
      this.setState({
        isLoading: false,
        event,
        videos,
        photos
      });
    });
  }

  renderAccessPresetModal() {
    const { isAccessPresetModalOpen } = this.state;
    return <SimpleModal isOpen={isAccessPresetModalOpen}>Access Modal Open</SimpleModal>;
  }

  onPlayerClick = (userId: string): void => {
    const { mode } = this.state;
    const isViewMode = mode === MODE_VIEW;
    if (isViewMode) {
      this.setState({
        selectedUserId: userId
      });
    }
  };

  onEditResultClick = () => {
    this.setState({
      mode: MODE_EDIT
    });
  };

  onEditCompletedClick = data => {
    const { user } = this.props;
    const { event, selectedUserId } = this.state;
    const individualCompleteness = propz.get(event, ['results', 'individualCompleteness'], []);
    const selectedPlayerCompletenessData = individualCompleteness.find(item => item.userId === selectedUserId);
    const isSelectedPlayerCompletenessDataExist = typeof selectedPlayerCompletenessData !== 'undefined';
    const eventId = this.getEventId();

    const completenessId = propz.get(selectedPlayerCompletenessData, ['_id'], '');

    this.setState({
      isLoading: true
    });

    const promise = isSelectedPlayerCompletenessDataExist
      ? updateEventResultsCompleteness(user, eventId, completenessId, data)
      : createEventResultsCompleteness(user, eventId, data);

    promise.then(() => {
      this.load();
    });
  };

  onCancelResultClick = () => {
    this.setState({
      mode: MODE_VIEW
    });
  };

  onSaveResultClick = (data: { userId: string; permissionId: string; scoreId: string; score: number }) => {
    const { user } = this.props;
    const { event } = this.state;
    const { id } = event;
    const { scoreId } = data;

    this.setState({
      isLoading: true
    });

    const isScoreExist = typeof scoreId !== 'undefined';

    const promise = isScoreExist
      ? updateIndividualScore(user, id, data.scoreId, data)
      : createIndividualScore(user, id, data);

    promise
      .then(res => getSchoolEvent(user, id))
      .then(event => {
        this.setState({
          isLoading: false,
          mode: MODE_VIEW,
          event
        });
      });
  };

  goBack = () => {
    const { history } = this.props;
    const eventId = this.getEventId();
    history.push({
      pathname: '/events/event',
      search: `id=${eventId}`
    });
  };

  onAccessPresetClick = (
    contentId: string,
    accessPreset: ACCESS_PRESET_TYPE,
    type: ARBITER_BOARD_GALLERY_ITEM_TYPE
  ) => {
    const { user } = this.props;
    const { event, videos, photos } = this.state;
    const { id } = event;

    this.setState({
      isUpdateAccessPreset: true
    });

    const dataToUpdate = {
      accessPreset
    };

    if (type === GALLERY_ITEM_VIDEO) {
      updateEventVideo(user, id, contentId, dataToUpdate).then(videoNext => {
        const videosNext = videos.map(video => (contentId === video.id ? videoNext : video));
        this.setState({
          videos: videosNext,
          isUpdateAccessPreset: false
        });
      });
    } else {
      updateEventPhoto(user, id, contentId, dataToUpdate).then(photoNext => {
        const photosNext = photos.map(photo => (contentId === photo.id ? photoNext : photo));
        this.setState({
          photos: photosNext,
          isUpdateAccessPreset: false
        });
      });
    }
  };

  renderPhotoFileInput() {
    return (
      <input
        className="eAddPhotoButton_fileInput"
        type="file"
        onChange={this.onPhotoFileChange}
        ref={input => (this.photoFileInputRef = input)}
      />
    );
  }

  renderVideoFileInput() {
    return (
      <input
        className="eAddVideoButton_fileInput"
        type="file"
        onChange={this.onVideoFileChange}
        ref={input => (this.videoFileInputRef = input)}
      />
    );
  }

  onAddPhotoClick = eventDescriptor => {
    let event;
    //This is true only for IE,firefox
    //Dirty hack from https://codeproject.com/Tips/893254/JavaScript-Triggering-Event-Manually-in-Internet-E
    //However, MDN recommend use very similar polyfill https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/MouseEvent#Polyfill
    if (document.createEvent) {
      // To create a mouse event , first we need to create an event and then initialize it.
      event = document.createEvent('MouseEvent');
      event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    } else {
      event = new MouseEvent('click');
    }
    this.photoFileInputRef.dispatchEvent(event);
    eventDescriptor.stopPropagation();
  };

  //it may come in handy

  onAddVideoClick = eventDescriptor => {
    let event;
    //This is true only for IE,firefox
    //Dirty hack from https://codeproject.com/Tips/893254/JavaScript-Triggering-Event-Manually-in-Internet-E
    //However, MDN recommend use very similar polyfill https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/MouseEvent#Polyfill
    if (document.createEvent) {
      // To create a mouse event , first we need to create an event and then initialize it.
      event = document.createEvent('MouseEvent');
      event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    } else {
      event = new MouseEvent('click');
    }
    this.videoFileInputRef.dispatchEvent(event);
    eventDescriptor.stopPropagation();
  };

  onPhotoFileChange = eventDescriptor => {
    const { user } = this.props;
    const { event, selectedUserId } = this.state;
    const { id, individualsData } = event;
    const image = eventDescriptor.target.files[0];

    const { activeSchool } = user;
    const photoAccessPreset = propz.get(activeSchool, ['photoAccessPreset'], ACCESS_PRESET.PUBLIC);
    const player = individualsData.find(individualData => individualData.userId === selectedUserId);

    this.setState({
      isLoading: true
    });

    uploadImage(user, image)
      .then(response => {
        const picUrl = `${window.apiImg}/images/${response.key}`;
        const data = {
          picUrl: picUrl,
          originalFileName: image.name,
          accessPreset: photoAccessPreset,
          userId: player.userId,
          role: ROLE.STUDENT
        };

        return addEventPhoto(user, id, data);
      })
      .then(() => getAllEventPhotos(user, id))
      .then(photos => {
        this.setState({
          isLoading: false,
          photos: photos
        });
      });
  };

  onVideoFileChange = eventDescriptor => {
    const { user } = this.props;
    const { event, selectedUserId } = this.state;
    const { id, individualsData } = event;
    const video = eventDescriptor.target.files[0];
    const { name } = video;
    const safeName = getSafeFileName(name);

    const { activeSchool } = user;
    const photoAccessPreset = propz.get(activeSchool, ['photoAccessPreset'], ACCESS_PRESET.PRIVATE);
    const player = individualsData.find(individualData => individualData.userId === selectedUserId);

    this.setState({
      isLoading: true
    });

    const promises = [getVideoConfig(user), getKey(user)];

    let s3, folderKey, folderName, videoFullName, bucket;

    BPromise.all(promises)
      .then(([videoConfig, keyObj]) => {
        const { accessKey, secretAccessKey, bucketRegion } = videoConfig;
        bucket = videoConfig.bucketName;

        s3 = new AWS.S3({
          region: bucketRegion,
          credentials: {
            accessKeyId: accessKey,
            secretAccessKey: secretAccessKey
          }
        });

        folderKey = keyObj.key;
        folderName = `orig/${folderKey}/`;
        videoFullName = folderName + safeName;

        return s3.putObject({ Key: folderName, Bucket: bucket }).promise();
      })
      .then(() => {
        return s3.putObject({ Key: videoFullName, Bucket: bucket, Body: video }).promise();
      })
      .then(() => {
        const data = {
          key: folderKey,
          fileName: safeName,
          bucketName: bucket
        };

        return addConvertJob(user, data);
      })
      .then(jobObj => {
        const job = jobObj.Job;
        const jobId = job.Id;

        const data = {
          fileName: safeName,
          key: folderKey,
          jobId: jobId,
          accessPreset: photoAccessPreset,
          userId: player.userId,
          role: ROLE.STUDENT
        };

        return addEventVideo(user, id, data);
      })
      .then(() => getAllEventVideos(user, id))
      .then(videos => {
        this.setState({
          isLoading: false,
          videos: videos
        });
      })
      .catch(error => {
        console.error(error);

        this.setState({
          isLoading: false,
          errorMessage: JSON.stringify(error),
          isErrorModalOpen: true
        });
      });
  };

  renderSendingError(): React.ReactNode {
    const { errorMessage, isErrorModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isErrorModalOpen}
        title={'Error'}
        buttonCancelText={'Ok'}
        onCloseClick={this.onCloseErrorClick}
      >
        <pre>{errorMessage}</pre>
      </SimpleModal>
    );
  }

  onCloseErrorClick = () => {
    this.setState({
      isErrorModalOpen: false,
      errorMessage: ''
    });
  };

  render() {
    const { user } = this.props;
    const {
      isLoading,
      isAccessPresetModalOpen,
      event,
      selectedUserId,
      photos,
      videos,
      mode,
      isUpdateAccessPreset
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes = isAccessPresetModalOpen ? 'mt-3 modal-open' : 'mt-3';
    const eventName = getEventGeneratedName(event, { user: user });
    const isSelectedUserIdExist = selectedUserId !== '';

    const photoItems: AdminBoardGalleryItem[] = photos
      .filter(photo => {
        const authorUserId = propz.get(photo, ['author', 'userId']);
        return authorUserId === selectedUserId;
      })
      .map(photo => {
        const { individualsData } = event;
        const player = individualsData.find(individualData => individualData.userId === selectedUserId);
        const { firstName, lastName } = player;
        return {
          id: photo.id,
          url: photo.picUrl,
          type: GALLERY_ITEM_PHOTO,
          accessPreset: photo.accessPreset,
          uploadedAt: Moment(photo.uploadedAt).format('DD/MM/YYYY HH:mm'),
          uploadedBy: `${firstName} ${lastName}, ${ROLE_SERVER_TO_CLIENT_MAPPING[photo.author.role]}`
        };
      });

    const videoItems: AdminBoardGalleryItem[] = videos
      .filter(video => {
        const authorUserId = propz.get(video, ['author', 'userId']);
        return authorUserId === selectedUserId;
      })
      .map(video => {
        const url = propz.get(video, ['streamData', '0', 'url']);

        const { individualsData } = event;
        const player = individualsData.find(individualData => individualData.userId === selectedUserId);
        const { firstName, lastName } = player;

        return {
          id: video.id,
          url: url,
          type: GALLERY_ITEM_VIDEO,
          accessPreset: video.accessPreset,
          uploadedAt: Moment(video.uploadedAt).format('DD/MM/YYYY HH:mm'),
          uploadedBy: `${firstName} ${lastName}, ${ROLE_SERVER_TO_CLIENT_MAPPING[video.author.role]}`
        };
      });

    const galleryItems: AdminBoardGalleryItem[] = [...videoItems, ...photoItems];
    const isGalleryItemsExist = galleryItems.length > 0;

    return (
      <div className={classes}>
        {this.renderAccessPresetModal()}
        {this.renderPhotoFileInput()}
        {this.renderVideoFileInput()}
        {this.renderSendingError()}

        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 d-flex align-content-start justify-content-start">
              <Button onClick={this.goBack} text={'← back'} customClass={'mr-3 btn-secondary'} />
              <div className="eArbiterBoardChallengeName">{eventName}</div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 eArbiterBoardPlayers">
              <ArbiterBoardPlayers
                photos={photos}
                videos={videos}
                event={event}
                onClick={this.onPlayerClick}
                selectedUserId={selectedUserId}
              />
            </div>
            <div className="col-md-8 pl-4 pb-5">
              {isSelectedUserIdExist && (
                <>
                  <ArbiterBoardPlayer
                    user={user}
                    event={event}
                    mode={mode}
                    selectedUserId={selectedUserId}
                    onEditResultClick={this.onEditResultClick}
                    onEditCompletedClick={this.onEditCompletedClick}
                    onSaveResultClick={this.onSaveResultClick}
                    onCancelResultClick={this.onCancelResultClick}
                    onAddPhotoClick={this.onAddPhotoClick}
                    onAddVideoClick={this.onAddVideoClick}
                  />
                  {isGalleryItemsExist ? (
                    <ArbiterBoardGallery
                      items={galleryItems}
                      onAccessPresetClick={this.onAccessPresetClick}
                      isUpdateAccessPreset={isUpdateAccessPreset}
                    />
                  ) : (
                    <div>No gallery items</div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
