import * as React from 'react';
import { Component } from 'react';
import * as propz from 'propz';
import { AppUser } from 'Src/views/App/App';
import { MessageField } from 'Src/models/message';
import { isNumeric, convertStringToBoolean } from 'Src/helpers/common/common';
import { Select } from 'Src/components/Select/Select';
import {
  getSelectOptionForBooleanQuestion,
  getSelectOptionForClubInvitationEnumQuestion
} from 'Src/helpers/table/select';
import { QUESTIONS_TEMPLATE_TYPE } from 'Src/consts/message';
import { EventInvitationMessage } from 'Src/models/message';

interface Props {
  user: AppUser;
  message: EventInvitationMessage;
  onAcceptClick: (fields: MessageField[]) => void;
  onCloseClick: () => void;
}

interface State {
  fields: MessageField[];
  activeSelectIndex: number[];
  errors: Error[];
}

interface Error {
  isError: boolean;
  errorText: string;
}

export class ParentalConsentRequestForm extends Component<Props, State> {
  constructor(props) {
    super(props);
    const { message } = this.props;
    const fields = propz.get(message, ['fields']);

    let fieldsUpdated;
    let activeSelectIndex = [];
    let errors = [];

    const isFieldsExist = typeof fields !== 'undefined';
    if (isFieldsExist) {
      fieldsUpdated = fields.map((field, index) => {
        activeSelectIndex[index] = 0;
        errors[index] = { isError: false, errorText: '' };
        return { ...field, value: '' };
      });
    }

    this.state = {
      fields: isFieldsExist ? fieldsUpdated : [],
      activeSelectIndex: isFieldsExist ? activeSelectIndex : [],
      errors: isFieldsExist ? errors : []
    };
  }

  onSubmit = () => {
    const { fields, errors } = this.state;
    let submitAbility = true;
    let fieldsUpdated = [...fields];

    const errorsUpdated = errors.map(item => {
      return {
        isError: false,
        errorText: ''
      };
    });

    fields.forEach((field, index) => {
      const isNumericField = isNumeric(field.value);
      const isNumberType = field.type === QUESTIONS_TEMPLATE_TYPE.NUMBER;
      const isBooleanType = field.type === QUESTIONS_TEMPLATE_TYPE.BOOLEAN;
      const isEnumType = field.type === QUESTIONS_TEMPLATE_TYPE.ENUM;
      const isFieldEmpty = field.value === '';

      if (isBooleanType) {
        let value;

        if (isFieldEmpty) {
          const options = getSelectOptionForBooleanQuestion();
          value = options[0].value;
        } else {
          value = propz.get(field, ['value']);
        }

        const booleanValue = convertStringToBoolean(value);
        propz.set(fieldsUpdated[index], ['value'], booleanValue);
      }

      if (isEnumType && isFieldEmpty) {
        const options = getSelectOptionForClubInvitationEnumQuestion(field.enumOptions);
        const value = options[0].value;
        propz.set(fieldsUpdated[index], ['value'], value);
      }

      if (isNumberType && !isNumericField && !isFieldEmpty) {
        errorsUpdated[index].isError = true;
        errorsUpdated[index].errorText = 'please enter a number';
        submitAbility = false;
      }

      const isFieldUpdatedEmpty = fieldsUpdated[index].value === '';

      if (isFieldUpdatedEmpty && field.isRequired) {
        errorsUpdated[index].isError = true;
        errorsUpdated[index].errorText = 'required';
        submitAbility = false;
      }
    });

    if (submitAbility) {
      this.props.onAcceptClick(fieldsUpdated);
    } else {
      this.setState({
        errors: errorsUpdated
      });
    }
  };

  render() {
    const { message, onCloseClick } = this.props;
    const { fields, activeSelectIndex, errors } = this.state;

    const isFieldsExist = fields.length > 0;
    const firstName = propz.get(message, ['playerDetailsData', 'firstName'], '');
    const lastName = propz.get(message, ['playerDetailsData', 'lastName'], '');
    const fullName = `${firstName} ${lastName}`;

    const fieldsUpdated = [...fields];

    return (
      <>
        <div className={'font-weight-bold mb-3'}>
          {`Do you give your consent for ${fullName} to take part in this fixture?`}
        </div>

        {isFieldsExist &&
          fieldsUpdated.map((field, index) => (
            <div className="my-3" key={`club_invitation_form_field_${index}`}>
              <div className={field.isRequired ? 'font-weight-bold' : ''}>
                {field.heading} {field.isRequired ? '(required)' : ''}
              </div>

              {(field.type === QUESTIONS_TEMPLATE_TYPE.STRING || field.type === QUESTIONS_TEMPLATE_TYPE.NUMBER) && (
                <input
                  type="text"
                  className="form-control"
                  value={field.value}
                  onChange={event => {
                    propz.set(fieldsUpdated[index], ['value'], event.target.value);
                    this.setState({ fields: fieldsUpdated });
                  }}
                />
              )}
              {field.type === QUESTIONS_TEMPLATE_TYPE.BOOLEAN && (
                <Select
                  onChange={value => {
                    const options = getSelectOptionForBooleanQuestion();
                    const nextIndex = options.findIndex(option => option.value === value);
                    const activeSelectIndexUpdated = [...activeSelectIndex];
                    activeSelectIndexUpdated[index] = nextIndex;
                    propz.set(fieldsUpdated[index], ['value'], value);

                    this.setState({
                      activeSelectIndex: activeSelectIndexUpdated
                    });
                  }}
                  options={getSelectOptionForBooleanQuestion()}
                  activeResultIndex={activeSelectIndex[index]}
                  customClass={'form-control'}
                />
              )}
              {field.type === QUESTIONS_TEMPLATE_TYPE.ENUM && (
                <Select
                  onChange={value => {
                    const options = getSelectOptionForClubInvitationEnumQuestion(field.enumOptions);
                    const nextIndex = options.findIndex(option => option.value === value);
                    const activeSelectIndexUpdated = [...activeSelectIndex];
                    activeSelectIndexUpdated[index] = nextIndex;
                    propz.set(fieldsUpdated[index], ['value'], value);

                    this.setState({
                      activeSelectIndex: activeSelectIndexUpdated
                    });
                  }}
                  options={getSelectOptionForClubInvitationEnumQuestion(field.enumOptions)}
                  activeResultIndex={activeSelectIndex[index]}
                  customClass={'form-control'}
                />
              )}
              {errors[index].isError && <div className="alert alert-danger mt-3">{errors[index].errorText}</div>}
            </div>
          ))}

        <div className="mt-3">
          <button onClick={onCloseClick} className="btn btn-secondary mr-2">
            Cancel
          </button>
          <button onClick={this.onSubmit} className="btn btn-primary mr-2">
            Proceed
          </button>
        </div>
      </>
    );
  }
}
