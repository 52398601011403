import 'babel-polyfill';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { App } from './views/App/App';
import { BrowserRouter } from 'react-router-dom';
import { apiSelector } from './helpers/api/api';

const myDomain = document.location.hostname;
const api = apiSelector(myDomain);

declare global {
  interface Window {
    apiBase: string;
    apiImg: string;
    apiFile: string;
    apiBaseFile: string;
  }
}

window.apiBase = api.main;
window.apiImg = api.img;
window.apiFile = api.file;
window.apiBaseFile = api.baseFile;

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('app')
);
