export interface SelectOptionCricket {
  text: string;
  value: string;
  teamId?: string;
}

export const CRICKET_RESULT = {
  WON_BY_RUNS: 'WON_BY_RUNS',
  WON_BY_WICKETS: 'WON_BY_WICKETS',
  WON_BY_INNINGS_AND_RUNS: 'WON_BY_INNINGS_AND_RUNS',
  TIE: 'TIE',
  DRAW: 'DRAW',
  NO_RESULT: 'NO_RESULT',
  MATCH_AWARDED: 'MATCH_AWARDED',
  TBD: 'TBD'
};

export const CRICKET_RESULT_SERVER_TO_CLIENT_MAPPING = {
  WON_BY_RUNS: 'Won by runs',
  WON_BY_WICKETS: 'Won by wickets',
  WON_BY_INNINGS_AND_RUNS: 'Won by innings and runs',
  TIE: 'Tie',
  DRAW: 'Draw',
  NO_RESULT: 'No result',
  MATCH_AWARDED: 'Match awarded',
  TBD: 'TBD'
};

export const RESULTS_FOR_CRICKET_WON_BY_RUNS_OPTION: SelectOptionCricket = {
  text: 'Won by runs',
  value: 'WON_BY_RUNS'
};
export const RESULTS_FOR_CRICKET_WON_BY_WICKETS_OPTION: SelectOptionCricket = {
  text: 'Won by wickets',
  value: 'WON_BY_WICKETS'
};
export const RESULTS_FOR_CRICKET_WON_BY_INNINGS_AND_RUNS_OPTION: SelectOptionCricket = {
  text: 'Won by innings and runs',
  value: 'WON_BY_INNINGS_AND_RUNS'
};
export const RESULTS_FOR_CRICKET_MATCH_AWARDED_OPTION: SelectOptionCricket = {
  text: 'Match awarded',
  value: 'MATCH_AWARDED'
};
export const RESULTS_FOR_CRICKET_FOR_SELECT_TBD_OPTION: SelectOptionCricket = {
  text: 'TBD',
  value: 'TBD'
};
export const RESULTS_FOR_CRICKET_FOR_SELECT_TIE_OPTION: SelectOptionCricket = {
  text: 'Tie',
  value: 'TIE'
};
export const RESULTS_FOR_CRICKET_FOR_SELECT_DRAW_OPTION: SelectOptionCricket = {
  text: 'Draw',
  value: 'DRAW'
};
export const RESULTS_FOR_CRICKET_FOR_SELECT_NO_RESULT_OPTION: SelectOptionCricket = {
  text: 'No result',
  value: 'NO_RESULT'
};
