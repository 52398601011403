import * as React from 'react';
import { Field, Form, Formik } from 'formik';
import { AppUser } from 'Src/views/App/App';
import * as Yup from 'yup';
import { LEAGUE_AGGREGATION_TYPE, LEAGUE_AGGREGATION_TYPE_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/league';
import { PUBLIC_SITE_STATUS_OPTIONS, PUBLIC_SITE_STATUS_OPTIONS_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/tournament';
import { Button } from 'Src/components/Button/Button';
import * as propz from 'propz';
import { SchoolLeague } from 'Src/models/schoolLeague';
import { useState } from 'react';
import { LabelWithQuestionIcon } from 'Src/components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import { uploadImage } from 'Src/helpers/service/image';
import { Switch } from 'Src/components/Switch/Switch';

interface Props {
  league: SchoolLeague;
  onSubmit: (data) => void;
  onCancel: () => void;
  user: AppUser;
}

const SchoolLeagueSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  domain: Yup.string()
    .required('Required')
    .matches(/[!^a-z0-9\-]+$/, 'Should contain only lowercase characters and digits')
});

export const SchoolLeagueForm: React.FunctionComponent<Props> = props => {
  const { league, onSubmit, onCancel, user } = props;

  const [picUrl, setPicUrl] = useState(propz.get(league, ['photos', '0', 'picUrl'], ''));
  const [isImageError, setIsImageError] = useState(false);

  const isLeagueExist = typeof league !== 'undefined';

  const leagueForm = {
    name: isLeagueExist ? league.name : '',
    description: isLeagueExist ? league.description : '',
    aggregationType: isLeagueExist ? league.aggregationType : LEAGUE_AGGREGATION_TYPE.TEAM,
    domain: isLeagueExist ? league.domain : '',
    status: isLeagueExist ? league.publicSite.status : PUBLIC_SITE_STATUS_OPTIONS_SERVER_TO_CLIENT_MAPPING.DISABLED,
    password: isLeagueExist ? league.publicSite.password : '',
    isDisplayHousePoints: isLeagueExist ? propz.get(league, ['isDisplayHousePoints'], false) : false
  };

  const onImageSelected = event => {
    const image = event.target.files[0];

    uploadImage(user, image)
      .then(response => {
        const picUrl = `${window.apiImg}/images/${response.key}`;
        setPicUrl(picUrl);
        setIsImageError(false);
      })
      .catch(error => {
        console.error(error);
        setIsImageError(true);
      });
  };

  const onRemoveImageClick = (): void => {
    setPicUrl('');
  };

  const isPicUrlExists = typeof picUrl !== 'undefined' && picUrl.length > 0;
  const imgInputClassName = isImageError ? 'form-control-file is-invalid' : 'form-control-file';

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik
            initialValues={leagueForm}
            validationSchema={SchoolLeagueSchema}
            onSubmit={values => {
              const { password, status, domain, ...rest } = values;
              const dataToSubmit = {
                ...rest,
                publicSite: {
                  status: values.status,
                  password: values.password
                },
                photos: picUrl.length > 0 ? [{ picUrl: picUrl }] : [],
                domain: domain.toLowerCase()
              };
              if (
                values.status !== PUBLIC_SITE_STATUS_OPTIONS_SERVER_TO_CLIENT_MAPPING.PROTECTED ||
                values.password === ''
              ) {
                delete dataToSubmit.publicSite.password;
              }

              onSubmit(dataToSubmit);
            }}
            render={({ touched, errors, setFieldValue, values }) => (
              <Form>
                <LabelWithQuestionIcon
                  labelText="Name"
                  hintText={
                    'This is the name that will appear to everyone who enters your challenge, ' +
                    'you can call it something all encompasing for a varied challenge that has multiple ' +
                    "different types of activities e.g 'Summer Holidays mini challenges 2021' or you can " +
                    "call it something more generic for a specific type of activity 'Get those running shoes on'."
                  }
                />
                <Field type="text" name="name" className="form-control mb-3" />
                {touched.name && errors.name ? <div className="alert alert-danger">{errors.name}</div> : null}

                <LabelWithQuestionIcon
                  labelText="Description"
                  hintText={
                    'The description allows you to explain what the challenge activities will entail,' +
                    ' this is for school staff to diferentiate between different challenges, for example you may' +
                    ' want to put thiese are all individual based challenges for either specific year groups ' +
                    'and / or genders.'
                  }
                />
                <Field type="text" name="description" className="form-control mb-3" />
                {touched.description && errors.description ? (
                  <div className="alert alert-danger">{errors.description}</div>
                ) : null}

                <LabelWithQuestionIcon
                  labelText="Aggregation"
                  hintText={
                    'The aggregation enables you to determine what type of challenge activities ' +
                    'you will include and how the leaderboard will be presented:\n' +
                    'Individual: students compete individually within activities\n' +
                    'Team: You will use pre-created teams and add them to all activities\n' +
                    'House: students will compete in activities that will provide points to their house total.'
                  }
                />
                <Field component="select" name="aggregationType" className="form-control mb-3">
                  <option value={LEAGUE_AGGREGATION_TYPE.HOUSE}>
                    {LEAGUE_AGGREGATION_TYPE_SERVER_TO_CLIENT_MAPPING.HOUSE}
                  </option>
                  <option value={LEAGUE_AGGREGATION_TYPE.TEAM}>
                    {LEAGUE_AGGREGATION_TYPE_SERVER_TO_CLIENT_MAPPING.TEAM}
                  </option>
                  <option value={LEAGUE_AGGREGATION_TYPE.INDIVIDUAL}>
                    {LEAGUE_AGGREGATION_TYPE_SERVER_TO_CLIENT_MAPPING.INDIVIDUAL}
                  </option>
                </Field>

                <LabelWithQuestionIcon
                  labelText="Domain name"
                  hintText={
                    'The domain name must be a continuous string of letters or words, that can be ' +
                    "separated using a '-' this is to create your unique webaddress for your challenge public " +
                    'website. This is where your leaderboard will be presented. Please make it as unique as ' +
                    "possible, here is the layout we .recommend 'SCHOOLNAME-CHALLENGENAME-ACADEMICYEAR'"
                  }
                />
                <Field type="text" name="domain" className="form-control mb-3" />
                {touched.domain && errors.domain ? <div className="alert alert-danger">{errors.domain}</div> : null}

                <LabelWithQuestionIcon
                  labelText="Public Site Access"
                  hintText={
                    'Here you can determine the accessibility of your challenge public website:\n' +
                    '- Enabled: it is available to be publicly viewed providing users have the website address, ' +
                    'we recommend enabling a website once you have set up your challenges so students can ' +
                    'view the leaderboard.\n' +
                    '- Disabled: There is no websire for users to view.\n' +
                    '- Protected: Users can only access the public website if they have the password and webaddress.\n' +
                    '\n' +
                    'Please note, all data is limited on the public website for data protection purposes.'
                  }
                />
                <Field component="select" name="status" className="form-control mb-3">
                  <option value={PUBLIC_SITE_STATUS_OPTIONS_SERVER_TO_CLIENT_MAPPING.DISABLED}>
                    {PUBLIC_SITE_STATUS_OPTIONS.DISABLED}
                  </option>
                  <option value={PUBLIC_SITE_STATUS_OPTIONS_SERVER_TO_CLIENT_MAPPING.PUBLIC_AVAILABLE}>
                    {PUBLIC_SITE_STATUS_OPTIONS.PUBLIC_AVAILABLE}
                  </option>
                  <option value={PUBLIC_SITE_STATUS_OPTIONS_SERVER_TO_CLIENT_MAPPING.PROTECTED}>
                    {PUBLIC_SITE_STATUS_OPTIONS.PROTECTED}
                  </option>
                </Field>

                {values.status === PUBLIC_SITE_STATUS_OPTIONS_SERVER_TO_CLIENT_MAPPING.PROTECTED && (
                  <>
                    <LabelWithQuestionIcon
                      labelText="League's public website access password"
                      hintText={
                        'Here you can input your chosen password for access to your public website, ' +
                        "if you don't want to add a password please change your 'public site access' choice."
                      }
                    />
                    <Field type="text" name="password" className="form-control" />
                  </>
                )}
                {values.aggregationType === LEAGUE_AGGREGATION_TYPE.INDIVIDUAL && (
                  <div className="d-flex">
                    <Field
                      name="isDisplayHousePoints"
                      render={({ field }) => (
                        <Switch
                          {...field}
                          value={values.isDisplayHousePoints}
                          customClass="mb-3"
                          onChange={event => {
                            const isDisplayHousePoints = event.target.checked;
                            setFieldValue('isDisplayHousePoints', isDisplayHousePoints);
                          }}
                        />
                      )}
                    />
                    <LabelWithQuestionIcon
                      labelText="Display house points on the league website"
                      hintText=""
                      labelHtmlFor="isDisplayHousePoints"
                      customLabelClass="form-check-label pl-2"
                    />
                  </div>
                )}

                <div className="form-group">
                  <LabelWithQuestionIcon
                    labelText="Pictures"
                    hintText={
                      'This picture will be shown on the league public website, for best results' +
                      ' we recommend using a picture with 3:1 proportions - for instance 1200:400 px or 1800x600 px.'
                    }
                  />
                  <input type="file" name="picUrl" className={imgInputClassName} onChange={onImageSelected} />
                  {isImageError && <div className="alert alert-danger">Error uploading image</div>}
                </div>
                {isPicUrlExists && (
                  <div className="mMaxWidth200px">
                    <button type="button" className="close" onClick={onRemoveImageClick}>
                      <span>&times;</span>
                    </button>
                    <img className="img-fluid img-thumbnail" src={picUrl} alt="picture" />
                  </div>
                )}

                <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                <button type="submit" className="mt-3 mb-3 btn btn-primary">
                  Save
                </button>
              </Form>
            )}
          />
        </div>
      </div>
    </div>
  );
};

SchoolLeagueForm.displayName = 'SchoolLeagueForm';
