import * as React from 'react';
import { FunctionComponent } from 'react';
import { Field, Form, Formik } from 'formik';
import { Button } from 'Src/components/Button/Button';

interface Props {
  onCloseClick: () => void;
  onSubmit: (data: any) => void;
}

export const SchoolReasonEventSettingsForm: FunctionComponent<Props> = props => {
  return (
    <Formik
      onSubmit={props.onSubmit}
      initialValues={{
        body: ''
      }}
      render={({ touched, errors }) => (
        <Form>
          <div className="form-group">
            <label>Reason for change</label>
            <Field
              component="textarea"
              name="body"
              placeholder="Enter reason for change"
              className={`${touched.body && errors.body ? 'form-control is-invalid' : 'form-control '}`}
            />
            {touched.body && errors.body ? <div className="invalid-feedback">{errors.body}</div> : null}
          </div>
          <Button onClick={props.onCloseClick} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
          <button type="submit" className="btn btn-primary">
            Save
          </button>
        </Form>
      )}
    />
  );
};
SchoolReasonEventSettingsForm.displayName = 'SchoolReasonEventSettingsForm';
