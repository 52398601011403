import { AppUser } from 'Src/views/App/App';
import axios from 'axios';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../consts/table';
import { Count } from '../../../models/count';
const DEFAULT_FILTER = {
  order: 'createdAt DESC'
};

export function getTournamentEnrollmentLogs(user: AppUser, tournamentId, filter) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order || DEFAULT_FILTER.order
      }
    }
  };

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/logs`, config)
    .then(response => {
      return response.data;
    });
}

export function getTournamentEnrollmentLogsCount(user: AppUser, tournamentId, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where
        }
      }
    };
  } else {
    config = {
      headers: { usid: key }
    };
  }

  return axios
    .get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/logs/count`, config)
    .then(response => {
      return response.data;
    });
}

export function getAllTournamentEnrollmentLogs(user: AppUser, tournamentId, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where
          },
          limit: DEFAULT_LIMIT,
          order: DEFAULT_FILTER.order,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          order: DEFAULT_FILTER.order,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/logs`, config)
    .then(response => {
      return response.data;
    });
}

export function createCommentEnrollmentLog(user: AppUser, tournamentId: string, logId: string, data) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .put(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/logs/${logId}`, data, config)
    .then(response => {
      return response.data;
    });
}
