import * as React from 'react';
import * as propz from 'propz';
import { FunctionComponent } from 'react';
import { SchoolHouse } from '../../models/house';

interface Props {
  house: SchoolHouse;
}

const ROWS = [
  { title: 'Name', field: 'name' },
  { title: 'Description', field: 'description' },
  { title: 'Colours', field: 'colors' }
];

export const HouseSummary: FunctionComponent<Props> = props => {
  const rows = ROWS.map((row, index) => {
    let contentCell: any;
    const field = row.field;

    const house = props.house;

    switch (field) {
      case 'colors':
        const result =
          house.colors.length !== 0
            ? house.colors.map((color, clrKey) => {
                return <div key={clrKey} className="eDataList_listItemColor" style={{ background: color }} />;
              })
            : null;

        contentCell = result;
        break;
      default:
        contentCell = propz.get(house, row.field.split('.'), '');
    }

    return (
      <tr key={`house_summary_${index}`}>
        <td>{row.title}</td>
        <td>{contentCell}</td>
      </tr>
    );
  });

  return (
    <table className={'table table-sm mPre mVerticalAlignMiddle'}>
      <tbody>{rows}</tbody>
    </table>
  );
};
HouseSummary.displayName = 'HouseSummary';
