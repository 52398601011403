import * as React from 'react';
import MaskedInput from 'react-text-mask';
import {
  MultiSelectCheckboxes,
  MultiSelectCheckboxItem
} from 'Src/components/MultiSelectCheckboxes/MultiSelectCheckboxes';
import DatePicker from 'react-datepicker';
import { DATE_FORMAT, HH_MM, TIME_FORMAT } from 'Src/consts/date';
import { ClubWizardErrors } from '../ClubWizard';
import { LabelWithQuestionIcon } from 'Src/components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import * as propz from 'propz';
import { ClubSport } from '../../../../../../../models/club';
import { Sport } from '../../../../../../../models/sport';
import { getGendersFromClubMultiSelect } from '../../../../../../../helpers/multiselect/multiselect';
import { getAgesFromClubMultiSelect } from '../../../../../../../helpers/accessor/accessor';
import '../ClubWizard.scss';

interface Props {
  termItems: MultiSelectCheckboxItem[];
  dayItems: MultiSelectCheckboxItem[];
  start: Date;
  end: Date;
  repeatEveryWeeks: number;
  duration: number;
  time: string;
  name: string;
  sport: Sport | ClubSport;
  genderItems: MultiSelectCheckboxItem[];
  ageItems: MultiSelectCheckboxItem[];
  onTermsChange: (index: number) => void;
  onStartDateChange: (value: Date) => void;
  onEndDateChange: (value: Date) => void;
  onDaysChange: (index: number) => void;
  onInputChange: (event) => void;
  errors: ClubWizardErrors;
}

export function ClubWizardScheduleStep(props: Props) {
  const {
    termItems,
    onTermsChange,
    start,
    onStartDateChange,
    onEndDateChange,
    end,
    dayItems,
    onDaysChange,
    onInputChange,
    repeatEveryWeeks,
    duration,
    time,
    name,
    sport,
    genderItems,
    ageItems,
    errors
  } = props;
  const isSomeTermItemChecked = termItems.some(termItem => termItem.isChecked);
  const clubSportName = propz.get(sport, ['name'], '');
  const clubGender = getGendersFromClubMultiSelect(genderItems);
  const clubAges = getAgesFromClubMultiSelect(ageItems);

  return (
    <div className={'mt-3'}>
      <div className="container-fluid pl-0">
        <div className="form-group">
          <div className="bClubInformation">
            <div>{name}</div>
            <LabelWithQuestionIcon
              labelText={`${clubSportName} ${clubGender} ${clubAges}  `}
              hintText=""
              customClass=""
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <LabelWithQuestionIcon
                labelText="Start"
                hintText={
                  'The start is any date within a week before your first club, if ' +
                  'you are selecting the terms rather than specific dates you can ignore this box'
                }
              />
              <div>
                <DatePicker
                  selected={start}
                  disabled={isSomeTermItemChecked}
                  onChange={onStartDateChange}
                  className="form-control"
                  dateFormat={'dd-MM-yyyy'}
                  customInput={
                    <MaskedInput
                      mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                      placeholder={DATE_FORMAT}
                    />
                  }
                />
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <LabelWithQuestionIcon
              labelText="End"
              hintText={
                'This is any date after your club is due to finish, within a week,  ' +
                'if you are selecting the terms rather than specific dates you can ignore this box'
              }
            />
            <div>
              <DatePicker
                disabled={isSomeTermItemChecked}
                selected={end}
                onChange={onEndDateChange}
                className="form-control"
                dateFormat={'dd-MM-yyyy'}
                minDate={start}
                customInput={
                  <MaskedInput
                    mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                    placeholder={DATE_FORMAT}
                  />
                }
              />
            </div>
          </div>
          {errors.date && (
            <div className="alert alert-danger">Club finish date cannot be earlier than the start date.</div>
          )}
        </div>
      </div>

      <div className="container-fluid pl-0">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <LabelWithQuestionIcon
                labelText="or select Terms"
                hintText={
                  'This is for use instead of using the above dates section, by default' +
                  ' these dates will be generic term starting / finishing dates, you can edit term ' +
                  'dates via your admin panel'
                }
              />
              <MultiSelectCheckboxes items={termItems} cols={3} onChange={onTermsChange} />
            </div>
            {errors.interval && <div className="alert alert-danger">Terms or start/end Date required</div>}
          </div>
        </div>
      </div>

      <div className="container-fluid pl-0">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <LabelWithQuestionIcon
                labelText="Days"
                hintText={
                  'Select the days of the week your club will be taking place on, ' +
                  'you can select one day or multiple days, please note the club will only ' +
                  'take place on the specic days that fall within the dates you selected above'
                }
              />
              <MultiSelectCheckboxes items={dayItems} cols={3} onChange={onDaysChange} />
            </div>
            {errors.days && <div className="alert alert-danger">Required</div>}
          </div>
        </div>
      </div>

      <div className="container-fluid pl-0">
        <div className="row">
          <div className="col-md-2">
            <div className="form-group">
              <LabelWithQuestionIcon
                labelText="Repeating (weeks)"
                hintText={
                  'This allows you to choose if the club' + ' is taking place weekly or every 2 / 3 / 4.... weeks'
                }
              />
              <input
                type="text"
                className="form-control"
                onChange={onInputChange}
                value={repeatEveryWeeks}
                name={'repeatEveryWeeks'}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid pl-0">
        <div className="row">
          <div className="col-md-2">
            <div className="form-group">
              <LabelWithQuestionIcon
                labelText="Start time"
                hintText={
                  'The start time for every club, this will be the default start time' +
                  ' for all clubs within the series, you can change individual club start dates ' +
                  'once  you have created your series of clubs'
                }
              />
              <MaskedInput
                type="text"
                className="form-control"
                onChange={onInputChange}
                mask={HH_MM}
                placeholder={TIME_FORMAT}
                value={time}
                name={'time'}
              />
            </div>
          </div>

          <div className="col-md-2">
            <LabelWithQuestionIcon
              labelText="Duration, min."
              hintText={'This is the length of each club, please note it is in minutes format'}
            />
            <input
              type="text"
              className="form-control mb-3"
              onChange={onInputChange}
              value={duration}
              name={'duration'}
            />
            {errors.duration && <div className="alert alert-danger">Required</div>}
          </div>
        </div>
      </div>
    </div>
  );
}
