import * as React from 'react';
import { Route, Switch } from 'react-router';
import { EmptyComponent } from 'Src/components/EmptyComponent/EmptyComponent';
import { AdminSchoolsInvitesInbox } from './AdminSchoolsInvitesInbox';
import { AdminSchoolsInvitesOutbox } from './AdminSchoolsInvitesOutbox';
import { AdminSchoolsInvitesArchive } from './AdminSchoolsInvitesArchive';

export const SchoolInvitesRouting = allProps => {
  return (
    <Switch>
      <Route exact path={'/invites'} render={props => <EmptyComponent />} />
      <Route
        exact
        path={'/invites/inbox'}
        render={props => (
          <AdminSchoolsInvitesInbox {...props} user={allProps.user} setNeedUpdate={allProps.setNeedUpdate} />
        )}
      />
      } />
      <Route
        exact
        path={'/invites/outbox'}
        render={props => <AdminSchoolsInvitesOutbox {...props} user={allProps.user} />}
      />
      <Route
        exact
        path={'/invites/archive'}
        render={props => <AdminSchoolsInvitesArchive {...props} user={allProps.user} />}
      />
    </Switch>
  );
};

SchoolInvitesRouting.displayName = 'SchoolInvitesRouting';
