import * as React from 'react';
import * as Yup from 'yup';
import * as propz from 'propz';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { AppUser } from 'Src/views/App/App';
import { changePassword } from 'Src/helpers/service/nobody/password';
import { WarningTriangle } from '../../../../components/SvgIcons/WarningTriangle';
import { SimpleModal } from '../../../../components/SimpleModal/SimpleModal';
import { Loader } from '../../../../components/Loader/Loader';
import './ChangePasswordTab.scss';

interface Props {
  user: AppUser;
}

interface State {
  isErrorModalOpen: boolean;
  isSuccessModalOpen: boolean;
  isLoading: boolean;
  errorText: string;
}

export class ChangePasswordTab extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isErrorModalOpen: false,
      isSuccessModalOpen: false,
      isLoading: false,
      errorText: ''
    };
  }

  onSubmit = data => {
    const { user } = this.props;
    const { oldPassword, newPassword } = data;

    this.setState({
      isLoading: true
    });

    changePassword(user, oldPassword, newPassword)
      .then(() => {
        this.setState({
          isLoading: false,
          isSuccessModalOpen: true
        });
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        this.setState({
          isLoading: false,
          isErrorModalOpen: true,
          errorText
        });
      });
  };

  closeErrorModal = () => {
    this.setState({
      isErrorModalOpen: false
    });
  };

  renderErrorModal() {
    const { isErrorModalOpen, errorText } = this.state;
    return (
      <SimpleModal
        isOpen={isErrorModalOpen}
        title={'Info'}
        body={errorText}
        buttonCancelText={'Ok'}
        onCloseClick={this.closeErrorModal}
        customClass={'eChangePasswordTabModal'}
      />
    );
  }

  closeSuccessModal = () => {
    this.setState({
      isSuccessModalOpen: false
    });
  };

  renderSuccessModal() {
    const { isSuccessModalOpen } = this.state;
    return (
      <SimpleModal
        isOpen={isSuccessModalOpen}
        title={'Info'}
        body={'Your password has been successfully changed.'}
        buttonCancelText={'Ok'}
        onCloseClick={this.closeSuccessModal}
        customClass={'eChangePasswordTabModal'}
      />
    );
  }

  render() {
    const changePasswordForm = {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    };

    const changePasswordSchema = Yup.object().shape({
      oldPassword: Yup.string().required('please enter current password'),
      newPassword: Yup.string()
        .required('please enter new password')
        .test(
          'newPassword',
          `password must contain at least one capital, lower case character, one number and must be 8 digits long`,
          value => {
            const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
            return passwordRegExp.test(value);
          }
        ),
      confirmNewPassword: Yup.string()
        .required('please confirm new password')
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    });

    const { isLoading } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    return (
      <div className="row">
        <div className="col-md-12">
          {this.renderErrorModal()}
          {this.renderSuccessModal()}
          <Formik initialValues={changePasswordForm} validationSchema={changePasswordSchema} onSubmit={this.onSubmit}>
            {({ errors, touched }) => (
              <Form className="ml-3">
                <div>
                  <div className="row">
                    <div className="col-md-6 ml-2">
                      <div className="eChangePasswordSectionTitle">{'Change Password'}</div>

                      <div
                        className={
                          touched.oldPassword && errors.oldPassword
                            ? 'eChangePasswordLabel mChangePasswordLabelwithError'
                            : 'eChangePasswordLabel'
                        }
                      >
                        {'Current password'}
                        <ErrorMessage component="span" className="eChangePasswordErrorText" name="oldPassword" />
                      </div>

                      <div className="eChangePasswordRowSection">
                        <Field
                          type="password"
                          name="oldPassword"
                          className={
                            touched.oldPassword && errors.oldPassword
                              ? 'eChangePasswordInput mChangePasswordInputWithError'
                              : 'eChangePasswordInput'
                          }
                        />
                        {touched.oldPassword && errors.oldPassword && (
                          <div className="eChangePasswordWarningIcon">
                            <WarningTriangle />
                          </div>
                        )}
                      </div>

                      <div
                        className={
                          touched.newPassword && errors.newPassword
                            ? 'eChangePasswordLabel mChangePasswordLabelwithError'
                            : 'eChangePasswordLabel'
                        }
                      >
                        {'New password'}
                        <ErrorMessage component="span" className="eChangePasswordErrorText" name="newPassword" />
                      </div>

                      <div className="eChangePasswordRowSection">
                        <Field
                          type="password"
                          name="newPassword"
                          className={
                            touched.newPassword && errors.newPassword
                              ? 'eChangePasswordInput mChangePasswordInputWithError'
                              : 'eChangePasswordInput'
                          }
                        />
                        {touched.newPassword && errors.newPassword && (
                          <div className="eChangePasswordWarningIcon">
                            <WarningTriangle />
                          </div>
                        )}
                      </div>

                      <div
                        className={
                          touched.confirmNewPassword && errors.confirmNewPassword
                            ? 'eChangePasswordLabel mChangePasswordLabelwithError'
                            : 'eChangePasswordLabel'
                        }
                      >
                        {'Confirm new password'}
                        <ErrorMessage component="span" className="eChangePasswordErrorText" name="confirmNewPassword" />
                      </div>

                      <div className="eChangePasswordRowSection">
                        <Field
                          type="password"
                          name="confirmNewPassword"
                          className={
                            touched.confirmNewPassword && errors.confirmNewPassword
                              ? 'eChangePasswordInput mChangePasswordInputWithError'
                              : 'eChangePasswordInput'
                          }
                        />
                        {touched.confirmNewPassword && errors.confirmNewPassword && (
                          <div className="eChangePasswordWarningIcon">
                            <WarningTriangle />
                          </div>
                        )}
                      </div>
                      <button type={'submit'} className="eChangePasswordButton">
                        {'Save'}
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}
