import * as React from 'react';
import * as BPromise from 'bluebird';
import * as propz from 'propz';
import * as Lazy from 'lazy.js';
import { Component } from 'react';
import { History, Location } from 'history';
import { AppUser } from 'Src/views/App/App';
import { Loader } from 'Src/components/Loader/Loader';
import { getTournament } from 'Src/helpers/service/adminSU/tournamentsTable';
import { parse } from 'query-string';
import { getAllSchoolUnionTournamentTeams } from 'Src/helpers/service/adminSU/tournamentTeamsTable';
import { Tournament, TournamentCustomGroup, TournamentLayout } from 'Src/models/tournament';
import { TOURNAMENT_GROUP_TAG_MAPPING, TOURNAMENT_LAYOUT_TYPE } from 'Src/consts/tournament';
import { GENDER, SPORT_GENDER_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/common';
import { AGE_GROUPS } from 'Src/consts/school';
import { Select } from 'Src/components/Select/Select';
import TeamCard from './TeamCard';
import { TeamDistributedCard } from './TeamDistributedCard';
import TeamDropSection from './TeamDropSection';
import { Button } from 'Src/components/Button/Button';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import './TournamentGrid.css';
import { addTournamentTeamGroupIds } from 'Src/helpers/service/adminSU/tournamentGrid';

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}
interface State {
  isLoading: boolean;
  tournament: Tournament;
  teams: TournamentGridShortTeamData[];
  activeGroupIndex: number;
  activeGroupId: string;
  activeFilterIndex: number;
}

export interface TournamentGridShortTeamData {
  schoolPic?: string;
  teamAges: number[];
  teamGender: string;
  schoolName: string;
  teamName: string;
  teamId: string;
  teamGroupTag: string | null;
  groupIds?: string[];
  groupResults: string;
  place: string;
}

interface GroupAdditionalData {
  groupAgeGenderAndName: string;
  groupAgeGenderAndId: string;
  groupAgeGroupNameGroupGenderAndStageName: string;
  groupId: string;
  groupAge: number;
  groupName: string;
}

const PLACE_IN_GROUP = ['1st', '2nd', '3d', '4th', '5th', '6h'];
//copy-paste from old frontend
class AdminSUTournamentGrid extends Component<Props, State> {
  GROUP_ADDITIONAL_DATA_LIST: GroupAdditionalData[] = [];

  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
      tournament: undefined,
      teams: [],
      activeGroupIndex: 0,
      activeGroupId: '',
      activeFilterIndex: 0
    };
  }

  static getSortedTeams(teams = []) {
    return [...teams].sort((team1, team2) => {
      const points1 = propz.get(team1, ['results', 'group', 'points'], 0);
      const points2 = propz.get(team2, ['results', 'group', 'points'], 0);
      return points2 - points1;
    });
  }

  static getPlaceInGroup(team, teamsSorted, groupName): string {
    const teamIndex = teamsSorted
      .filter(teamSorted => teamSorted.results.group.name === groupName)
      .findIndex(teamSorted => teamSorted.id === team.id);

    return teamIndex !== -1 ? PLACE_IN_GROUP[teamIndex] : '';
  }

  getTournamentId(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    const tournamentId = search.tournament;
    return tournamentId;
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    const { user } = this.props;
    const tournamentId = this.getTournamentId();

    const tournamentsPromise = getTournament(user, tournamentId);
    const tournamentTeamsPromise = getAllSchoolUnionTournamentTeams(user, tournamentId);

    BPromise.all([tournamentsPromise, tournamentTeamsPromise]).then(([tournament, teams]) => {
      const teamsSorted = AdminSUTournamentGrid.getSortedTeams(teams);

      const shortTeams: TournamentGridShortTeamData[] = teams.map(team => {
        const { total, won, lost, drew, points, name: groupName } = team.results.group;
        const place = total !== 0 ? AdminSUTournamentGrid.getPlaceInGroup(team, teamsSorted, groupName) : '';
        const groupResults =
          total !== 0 ? `${groupName} ${place} (P: ${total}, W:${won}, D:${lost}, L:${drew}, Pts:${points})` : '';

        const { name, ages, gender, id, groupTag, groupIds } = team;
        const schoolPic = propz.get(team, ['school', 'pic']);
        const schoolName = propz.get(team, ['school', 'name']);

        return {
          schoolPic: schoolPic,
          schoolName: schoolName,
          teamName: name,
          teamAges: ages,
          teamGender: gender,
          teamId: id,
          teamGroupTag: groupTag,
          groupIds: groupIds,
          groupResults: groupResults,
          place: place
        };
      });

      this.GROUP_ADDITIONAL_DATA_LIST = this.getGroupAdditionalDataList(tournament);

      const initialGroupId = this.GROUP_ADDITIONAL_DATA_LIST[0].groupId;

      this.setState({
        isLoading: false,
        tournament: tournament,
        teams: shortTeams,
        activeGroupId: initialGroupId
      });
    });
  }

  getGroupAdditionalDataList(tournament): GroupAdditionalData[] {
    const groups: TournamentCustomGroup[] = this.getGroups(tournament);
    const tournamentGender = tournament.gender;

    const isTournamentGenderMixed = tournamentGender === GENDER.MIXED;
    const isMixedTeamsAvailable = tournament.isMixedTeamsAvailable;

    const tournamentAges = tournament.ages;

    const groupAdditionalDataList = (Lazy(groups) as any)
      .map(group => {
        return (Lazy(tournamentAges) as any)
          .map(tournamentAge => {
            const groupMapped = TOURNAMENT_GROUP_TAG_MAPPING[group.name];
            const tournamentGroup = typeof groupMapped !== 'undefined' ? groupMapped : group.name;
            const groupStageId = group.stageId;
            const stage = tournament.tournamentLayout.find(tournamentStage => tournamentStage.id === groupStageId);
            const stageName = stage.name;
            const age = AGE_GROUPS['U5-U18'][tournamentAge];

            if (isTournamentGenderMixed && !isMixedTeamsAvailable) {
              const genderMale = SPORT_GENDER_SERVER_TO_CLIENT_MAPPING[GENDER.MALE_ONLY];
              const genderFemale = SPORT_GENDER_SERVER_TO_CLIENT_MAPPING[GENDER.FEMALE_ONLY];

              return [
                {
                  groupAgeGenderAndName: `${age} ${GENDER.MALE_ONLY} ${tournamentGroup}`,
                  groupAgeGenderAndId: `${age} ${GENDER.MALE_ONLY} ${group.id}`,
                  groupAgeGroupNameGroupGenderAndStageName: `${age} ${genderMale} ${tournamentGroup} [${stageName}]`,
                  groupId: group.id,
                  groupAge: group.age,
                  groupName: group.name
                },
                {
                  groupAgeGenderAndName: `${age} ${GENDER.FEMALE_ONLY} ${tournamentGroup}`,
                  groupAgeGenderAndId: `${age} ${GENDER.FEMALE_ONLY} ${group.id}`,
                  groupAgeGroupNameGroupGenderAndStageName: `${age} ${genderFemale} ${tournamentGroup} [${stageName}]`,
                  groupId: group.id,
                  groupAge: group.age,
                  groupName: group.name
                }
              ];
            } else {
              const gender = SPORT_GENDER_SERVER_TO_CLIENT_MAPPING[tournamentGender];
              return {
                groupAgeGenderAndName: `${age} ${tournamentGender} ${tournamentGroup}`,
                groupAgeGenderAndId: `${age} ${tournamentGender} ${group.id}`,
                groupAgeGroupNameGroupGenderAndStageName: `${age} ${gender} ${tournamentGroup} [${stageName}]`,
                groupId: group.id,
                groupAge: group.age,
                groupName: group.name
              };
            }
          })
          .flatten()
          .toArray();
      })
      .flatten()
      .toArray();

    groupAdditionalDataList.sort((groupAdditionalData1, groupAdditionalData2) => {
      const stage1 = this.getStageByGroupId(tournament, groupAdditionalData1.groupId);
      const stageOrder1 = stage1.order;
      const stage2 = this.getStageByGroupId(tournament, groupAdditionalData2.groupId);
      const stageOrder2 = stage2.order;

      switch (true) {
        case stageOrder1 < stageOrder2:
          return -1;
        case stageOrder1 > stageOrder2:
          return 1;
        case stageOrder1 === stageOrder2:
          switch (true) {
            case groupAdditionalData1.groupAge < groupAdditionalData2.groupAge:
              return -1;
            case groupAdditionalData1.groupAge > groupAdditionalData2.groupAge:
              return 1;
            case groupAdditionalData1.groupAge === groupAdditionalData2.groupAge:
              switch (true) {
                case groupAdditionalData1.groupName < groupAdditionalData2.groupName:
                  return -1;
                case groupAdditionalData1.groupName > groupAdditionalData2.groupName:
                  return 1;
                case groupAdditionalData1.groupName === groupAdditionalData2.groupName:
                  return 0;
              }
          }
      }
    });

    return groupAdditionalDataList;
  }

  //Return group from stages: GROUP, QUALIFIER, PLAYOFF
  getGroups(tournament: Tournament): TournamentCustomGroup[] {
    const customGroups = tournament.customGroups.filter(customGroup => {
      const stage = tournament.tournamentLayout.find(tournamentStage => tournamentStage.id === customGroup.stageId);
      if (typeof stage !== 'undefined') {
        const stageType = stage.type;
        const isStageTypeGroup = stageType === TOURNAMENT_LAYOUT_TYPE.GROUP;
        const isStageTypeQualifier = stageType === TOURNAMENT_LAYOUT_TYPE.QUALIFIER;
        const isStageTypePlayoff = stageType === TOURNAMENT_LAYOUT_TYPE.PLAYOFF;
        return isStageTypeGroup || isStageTypeQualifier || isStageTypePlayoff;
      } else {
        console.error(`Can not find stage with Id: ${customGroup.stageId}`);
        return false;
      }
    });

    return (Lazy(customGroups) as any).uniq().toArray();
  }

  getStageByGroupId(tournament: Tournament, groupId: string): TournamentLayout {
    const { customGroups = [], tournamentLayout: stages = [] } = tournament;

    const group = customGroups.find(customGroup => customGroup.id === groupId);
    const stageId = group.stageId;
    const stage = stages.find(tournamentStage => tournamentStage.id === stageId);
    return stage;
  }

  getStageTypeByGroupId(tournament: Tournament, groupId: string): string {
    const stage = this.getStageByGroupId(tournament, groupId);
    const stageType = stage.type;

    return stageType;
  }

  isGroupIdQualifierStage(groupId: string): boolean {
    const { tournament } = this.state;
    const stageType = this.getStageTypeByGroupId(tournament, groupId);

    return stageType === TOURNAMENT_LAYOUT_TYPE.QUALIFIER;
  }

  isGroupIdPlayoffStage(groupId: string): boolean {
    const { tournament } = this.state;
    const stageType = this.getStageTypeByGroupId(tournament, groupId);

    return stageType === TOURNAMENT_LAYOUT_TYPE.PLAYOFF;
  }

  onFilterChange = (value: string): void => {
    const nextIndex = PLACE_IN_GROUP.findIndex(place => place === value);

    //nextIndex + 1: because first item 'All', not contains in PLACE_IN_GROUP array
    this.setState({
      activeFilterIndex: nextIndex === -1 ? 0 : nextIndex + 1
    });
  };

  renderTeamCards() {
    const { activeGroupId, activeGroupIndex, teams, activeFilterIndex, tournament } = this.state;
    const groupAgeGenderAndNameList = this.GROUP_ADDITIONAL_DATA_LIST.map(
      additionalData => additionalData.groupAgeGenderAndName
    );
    const [currentAge, currentGender] = groupAgeGenderAndNameList[activeGroupIndex].split(` `);

    const teamsFiltered = teams.filter(team => {
      const { teamAges } = team;

      const teamAgesMapped = (Lazy(teamAges) as any).map(teamAge => AGE_GROUPS['U5-U18'][teamAge]).toArray();

      const isTeamAgeEqualSelectorAge = teamAgesMapped.some(teamAge => teamAge === currentAge);
      const isTeamGenderEqualSelectorGender = team.teamGender === currentGender;

      const group = tournament.customGroups.find(customGroup => customGroup.id === activeGroupId);

      const stage = tournament.tournamentLayout.find(tournamentStage => tournamentStage.id === group.stageId);
      const stageType = stage.type;

      const stagesUniq = (Lazy(team.groupIds) as any)
        .map(groupId => {
          const group = tournament.customGroups.find(customGroup => customGroup.id === groupId);
          return tournament.tournamentLayout.find(tournamentStage => tournamentStage.id === group.stageId);
        })
        .uniq()
        .toArray();

      const isTeamGroupStagesConsistInActiveGroupStage = stagesUniq.some(stage => stage.type === stageType);

      let isTeamPlaceInActiveFilterPlace = true;

      if (activeFilterIndex !== 0) {
        const placeFilter = PLACE_IN_GROUP[activeFilterIndex - 1];
        isTeamPlaceInActiveFilterPlace = placeFilter === team.place;
      }

      return (
        isTeamAgeEqualSelectorAge &&
        isTeamGenderEqualSelectorGender &&
        !isTeamGroupStagesConsistInActiveGroupStage &&
        isTeamPlaceInActiveFilterPlace
      );
    });

    return teamsFiltered.map(team => {
      return <TeamCard team={team} key={team.teamId} />;
    });
  }

  onTeamDrop = (teamId: string): void => {
    const { teams, activeGroupId } = this.state;

    const teamIndex = teams.findIndex(team => team.teamId === teamId);

    const groupIds = [].concat(teams[teamIndex].groupIds, activeGroupId);

    teams[teamIndex].groupIds = groupIds;

    this.setState({
      teams: teams
    });
  };

  onSelectGroupChange = (groupAgeAndId: string): void => {
    const groupAgeGenderAndIdList = this.GROUP_ADDITIONAL_DATA_LIST.map(
      additionalData => additionalData.groupAgeGenderAndId
    );
    const groupIdList = this.GROUP_ADDITIONAL_DATA_LIST.map(additionalData => additionalData.groupId);

    const activeGroupIndex = groupAgeGenderAndIdList.findIndex(id => id === groupAgeAndId);

    const currentGroupId = groupIdList[activeGroupIndex];

    this.setState({
      activeGroupIndex: activeGroupIndex,
      activeGroupId: currentGroupId
    });
  };

  renderTeamDistributedCards() {
    const { activeGroupId, activeGroupIndex, teams, tournament } = this.state;

    const groupAgeGenderAndNameList = this.GROUP_ADDITIONAL_DATA_LIST.map(
      additionalData => additionalData.groupAgeGenderAndName
    );

    const [currentAge, currentGender] = groupAgeGenderAndNameList[activeGroupIndex].split(` `);

    const teamsDistributedFiltered = teams.filter(team => {
      const { teamAges } = team;

      const teamAgesMapped = (Lazy(teamAges) as any).map(teamAge => AGE_GROUPS['U5-U18'][teamAge]).toArray();

      const stagesUniq = (Lazy(team.groupIds) as any)
        .map(groupId => {
          const group = tournament.customGroups.find(customGroup => customGroup.id === groupId);
          return tournament.tournamentLayout.find(tournamentStage => tournamentStage.id === group.stageId);
        })
        .uniq()
        .toArray();

      const group = tournament.customGroups.find(customGroup => customGroup.id === activeGroupId);
      const stage = tournament.tournamentLayout.find(tournamentStage => tournamentStage.id === group.stageId);
      const stageType = stage.type;

      const isTeamAgeEqualSelectorAge = teamAgesMapped.some(teamAge => teamAge === currentAge);
      const isTeamGenderEqualSelectorGender = team.teamGender === currentGender;
      const isTeamGroupStagesConsistInSelectorStage = stagesUniq.some(stage => stage.type === stageType);
      const isTeamGroupIdConsistInGroupIdSelector = team.groupIds.some(groupId => groupId === activeGroupId);

      return (
        isTeamAgeEqualSelectorAge &&
        isTeamGenderEqualSelectorGender &&
        isTeamGroupStagesConsistInSelectorStage &&
        isTeamGroupIdConsistInGroupIdSelector
      );
    });

    if (teamsDistributedFiltered.length === 0) {
      const style = {
        height: '62px',
        lineHeight: '62px',
        border: '1px solid #ccc',
        marginBottom: '10px',
        marginTop: '10px',
        color: '#777'
      };

      return (
        <div style={style} className="text-center">
          Grab and move teams over here
        </div>
      );
    }

    return teamsDistributedFiltered.map(team => {
      return <TeamDistributedCard team={team} key={team.teamId} onDeleteClick={this.onDistributedCardDeleteClick} />;
    });
  }

  onDistributedCardDeleteClick = (teamId: string): void => {
    const { teams, activeGroupId } = this.state;

    const teamIndex = teams.findIndex(team => team.teamId === teamId);
    const groupIndex = teams[teamIndex].groupIds.findIndex(groupId => groupId === activeGroupId);

    const groupIds = teams[teamIndex].groupIds;
    groupIds.splice(groupIndex, 1);

    teams[teamIndex].groupIds = groupIds;

    this.setState({
      teams: teams
    });
  };

  goBack = () => {
    const { history, location } = this.props;
    const { state } = location;
    const { search, prevPath } = state as any;
    const tournamentId = this.getTournamentId();

    history.push({
      pathname: prevPath,
      search: search,
      state: { tournamentId: tournamentId }
    });
  };

  onSubmit = () => {
    const { user } = this.props;
    const { teams } = this.state;
    const tournamentId = this.getTournamentId();

    const allTeamsData = {
      teams: teams.map(team => {
        return {
          teamId: team.teamId,
          groupIds: team.groupIds
        };
      })
    };

    this.setState({
      isLoading: true
    });

    addTournamentTeamGroupIds(user, tournamentId, allTeamsData).then(res => {
      this.setState({
        isLoading: false
      });
    });
  };

  render() {
    const { isLoading, tournament } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    if (this.GROUP_ADDITIONAL_DATA_LIST.length === 0) {
      return null;
    }

    const { activeGroupIndex, activeFilterIndex } = this.state;
    const groupAgeGroupNameAndStageNameList = this.GROUP_ADDITIONAL_DATA_LIST.map(additionalData => {
      return {
        text: additionalData.groupAgeGroupNameGroupGenderAndStageName,
        value: additionalData.groupAgeGenderAndId
      };
    });

    const activeGroup = this.GROUP_ADDITIONAL_DATA_LIST[activeGroupIndex];
    const activeGroupId = activeGroup.groupId;
    const isGroupIdQualifierStage = this.isGroupIdQualifierStage(activeGroupId);
    const isGroupIdPlayoffStage = this.isGroupIdPlayoffStage(activeGroupId);
    const isGroupIdQualifierOrPlayoff = isGroupIdQualifierStage || isGroupIdPlayoffStage;

    const filterOptions = PLACE_IN_GROUP.map(place => {
      return {
        text: `${place} in group`,
        value: place
      };
    });

    filterOptions.unshift({
      text: 'All',
      value: 'All'
    });

    const title = typeof tournament !== 'undefined' ? `${tournament.name} / Grid` : '';

    return (
      <div className={'container-fluid mt-3'}>
        <div className={'row'}>
          <div className="col-md-12">
            <Button text={'← back'} onClick={this.goBack} customClass={'btn-secondary mr-3 mb-3'} />
            <div className="h3 mb-3 d-inline">{title}</div>
          </div>
        </div>
        <div className={'row text-center'}>
          <div className={'col-md-6'}>
            {isGroupIdQualifierOrPlayoff && (
              <div className={'form-group'}>
                <Select
                  options={filterOptions}
                  onChange={this.onFilterChange}
                  activeResultIndex={activeFilterIndex}
                  customClass={'form-control'}
                />
              </div>
            )}
          </div>

          <div className={'col-md-6'}>
            <div className={'form-group'}>
              <Select
                options={groupAgeGroupNameAndStageNameList}
                onChange={this.onSelectGroupChange}
                activeResultIndex={activeGroupIndex}
                customClass={'form-control'}
              />
            </div>
          </div>
        </div>

        <div className={'row'}>
          <div className={'col-md-6'}>{this.renderTeamCards()}</div>
          <TeamDropSection onTeamDrop={this.onTeamDrop}>{this.renderTeamDistributedCards()}</TeamDropSection>
        </div>
        <div>
          <Button text={'Save'} onClick={this.onSubmit} />
        </div>
      </div>
    );
  }
}

export default DragDropContext(HTML5Backend)(AdminSUTournamentGrid);
