import * as React from 'react';
import * as propz from 'propz';
import { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import DatePicker from 'react-datepicker';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from '../../../../../../../consts/report';
import { Switch } from '../../../../../../../components/Switch/Switch';
import { LabelWithQuestionIcon } from '../../../../../../../components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import { MultiSelectCheckboxes } from '../../../../../../../components/MultiSelectCheckboxes/MultiSelectCheckboxes';
import {
  getReportDefaultGendersForMultiSelect,
  getReportAgesForMultiSelect
} from '../../../../../../../helpers/multiselect/multiselect';
import { School } from '../../../../../../../models/school';
import { SchoolForm } from '../../../../../../../models/form';
import { getStartDateForSchoolYear } from '../../../../../../../helpers/calendar/calendar';
import '../../Clubs.css';

const OPTIONS = {
  IS_CURRENT_WEEK: 'isCurrentWeek',
  IS_PAST_WEEK: 'isPastWeek',
  IS_CUSTOM_INTERVAL: 'isCustomInterval'
};

interface Props {
  onCloseClick: () => void;
  onSubmit: (data: any, reportType: string) => void;
  school: School;
  forms: SchoolForm[];
}

// report 065
export function AverageClubAttendanceReportForm({ onCloseClick, onSubmit, school, forms }: Props) {
  const genders = getReportDefaultGendersForMultiSelect();
  const ages = getReportAgesForMultiSelect(school, forms);
  const [isAgesAndGendersChecked, setIsAgesAndGendersChecked] = useState(true);

  const now = new Date();
  let dateFrom = getStartDateForSchoolYear(now);

  const requestInitial = {
    options: OPTIONS.IS_CURRENT_WEEK,
    isCurrentWeek: false,
    isPastWeek: false,
    isCustomInterval: false,
    genders,
    ages,
    isSelectAllAges: true,
    dateFrom: (() => {
      const today = new Date();
      today.setHours(0, 0, 1, 0);
      return today;
    })(),
    dateTo: (() => {
      const today = new Date();
      const nextWeek = new Date(today);
      nextWeek.setDate(today.getDate() + 6);
      nextWeek.setHours(23, 59, 59, 999);
      return nextWeek;
    })()
  };

  switch (requestInitial.options) {
    case OPTIONS.IS_CURRENT_WEEK: {
      const today = new Date();
      const dayOfWeek = today.getDay();
      const daysUntilMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
      const closestPastMonday = new Date(today);
      closestPastMonday.setDate(today.getDate() - daysUntilMonday);

      const nextSunday = new Date(closestPastMonday);
      nextSunday.setDate(closestPastMonday.getDate() + 7);

      requestInitial.dateFrom = new Date(closestPastMonday.setHours(0, 0, 1, 0)); // 00:00:01
      requestInitial.dateTo = new Date(nextSunday.setHours(23, 59, 59, 999)); // 23:59:59
      break;
    }

    case OPTIONS.IS_PAST_WEEK: {
      const today = new Date();
      const dayOfWeek = today.getDay();
      const daysUntilMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
      const lastMonday = new Date(today);
      lastMonday.setDate(today.getDate() - daysUntilMonday - 7);

      const lastSunday = new Date(lastMonday);
      lastSunday.setDate(lastMonday.getDate() + 6);

      requestInitial.dateFrom = new Date(lastMonday.setHours(0, 0, 1, 0)); // 00:00:01
      requestInitial.dateTo = new Date(lastSunday.setHours(23, 59, 59, 999)); // 23:59:59
      break;
    }
  }

  const onGendersChange = (index, values, setFieldValue) => {
    const genderItems = values.genders;
    const ageItems = values.ages;

    const genderItemsUpdated = genderItems.map((genderItem, genderIndex) => {
      return genderIndex === index
        ? {
            ...genderItem,
            isChecked: !genderItem.isChecked
          }
        : genderItem;
    });

    const gendersChecked = genderItemsUpdated.map(gender => gender.isChecked);
    const agesChecked = ageItems.map(age => age.isChecked);
    const isCheckedGendersExist = gendersChecked.includes(true);
    const isCheckedAgesExist = agesChecked.includes(true);

    setIsAgesAndGendersChecked(isCheckedGendersExist && isCheckedAgesExist);
    setFieldValue('genders', genderItemsUpdated);
  };

  const onAgesChange = (index, values, setFieldValue) => {
    const ageItems = values.ages;
    const genderItems = values.genders;

    const ageItemsUpdated = ageItems.map((ageItem, ageIndex) => {
      return ageIndex === index
        ? {
            ...ageItem,
            isChecked: !ageItem.isChecked
          }
        : ageItem;
    });

    const gendersChecked = genderItems.map(gender => gender.isChecked);
    const agesChecked = ageItemsUpdated.map(age => age.isChecked);
    const isCheckedGendersExist = gendersChecked.includes(true);
    const isCheckedAgesExist = agesChecked.includes(true);

    const isSelectAllAges = ageItemsUpdated.every(age => age.isChecked === true);

    setIsAgesAndGendersChecked(isCheckedAgesExist && isCheckedGendersExist);
    setFieldValue('isSelectAllAges', isSelectAllAges);
    setFieldValue('ages', ageItemsUpdated);
  };

  const onSelectAllAgesChange = (event, values, setFieldValue) => {
    const isSelectAllAges = event.target.checked;
    const ageItems = values.ages;
    const genderItems = values.genders;

    const ageItemsUpdated = ageItems.map(age => {
      return {
        ...age,
        isChecked: isSelectAllAges
      };
    });

    const gendersChecked = genderItems.map(gender => gender.isChecked);
    const agesChecked = ageItemsUpdated.map(age => age.isChecked);
    const isCheckedGendersExist = gendersChecked.includes(true);
    const isCheckedAgesExist = agesChecked.includes(true);

    setIsAgesAndGendersChecked(isCheckedAgesExist && isCheckedGendersExist);
    setFieldValue('isSelectAllAges', isSelectAllAges);
    setFieldValue('ages', ageItemsUpdated);
  };

  return (
    <Formik
      onSubmit={values => {
        const valuesToTransfer = { ...values };
        propz.set(valuesToTransfer, [valuesToTransfer.options], true);
        delete valuesToTransfer.options;

        switch (values.options) {
          case OPTIONS.IS_CURRENT_WEEK:
            const currentWeekToday = new Date();
            const dayOfWeekCurrent = currentWeekToday.getDay();
            const daysUntilMondayCurrent = dayOfWeekCurrent === 0 ? 6 : dayOfWeekCurrent - 1;
            const closestPastMondayCurrent = new Date(currentWeekToday);
            closestPastMondayCurrent.setDate(currentWeekToday.getDate() - daysUntilMondayCurrent);

            const nextSundayCurrent = new Date(closestPastMondayCurrent);
            nextSundayCurrent.setDate(closestPastMondayCurrent.getDate() + 6);

            valuesToTransfer.dateFrom = closestPastMondayCurrent;
            valuesToTransfer.dateTo = new Date(nextSundayCurrent.setHours(23, 59, 59, 999));
            break;

          case OPTIONS.IS_PAST_WEEK:
            const pastWeekToday = new Date();
            const dayOfWeekPast = pastWeekToday.getDay();
            const daysUntilMondayPast = dayOfWeekPast === 0 ? 6 : dayOfWeekPast - 1;
            const lastMondayPast = new Date(pastWeekToday);
            lastMondayPast.setDate(pastWeekToday.getDate() - daysUntilMondayPast - 7);

            const lastSundayPast = new Date(lastMondayPast);
            lastSundayPast.setDate(lastMondayPast.getDate() + 6);

            valuesToTransfer.dateFrom = lastMondayPast;
            valuesToTransfer.dateTo = new Date(lastSundayPast.setHours(23, 59, 59, 999));
            break;

          case OPTIONS.IS_CUSTOM_INTERVAL:
            const selectedDateFrom = values.dateFrom;
            const selectedDateTo = values.dateTo;

            valuesToTransfer.dateFrom = selectedDateFrom;
            valuesToTransfer.dateTo = selectedDateTo;
            break;
        }

        onSubmit(valuesToTransfer, REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.AVERAGE_CLUB_ATTENDANCE);
      }}
      initialValues={requestInitial}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <div className="bReportFormTitle">Average club attendance</div>
          <div className="mb-3 mt-3">View the data related to club attendance for the selected period.</div>

          <div className="form-group">
            <div className="form-check mb-1">
              <input
                className="form-check-input"
                type="radio"
                name={OPTIONS.IS_CURRENT_WEEK}
                id={OPTIONS.IS_CURRENT_WEEK}
                value={OPTIONS.IS_CURRENT_WEEK}
                checked={values.options === OPTIONS.IS_CURRENT_WEEK}
                onChange={() => {
                  setFieldValue('options', OPTIONS.IS_CURRENT_WEEK);
                }}
              />
              <LabelWithQuestionIcon
                labelText="This week"
                hintText="Select to view the data for the current week"
                customLabelClass="form-check-label pl-2"
                labelHtmlFor={OPTIONS.IS_CURRENT_WEEK}
              />
            </div>

            <div className="form-check mb-1">
              <input
                className="form-check-input"
                type="radio"
                name={OPTIONS.IS_PAST_WEEK}
                id={OPTIONS.IS_PAST_WEEK}
                value={OPTIONS.IS_PAST_WEEK}
                checked={values.options === OPTIONS.IS_PAST_WEEK}
                onChange={() => {
                  setFieldValue('options', OPTIONS.IS_PAST_WEEK);
                }}
              />

              <LabelWithQuestionIcon
                labelText="Last week"
                hintText="Select to view the data for the last week"
                customLabelClass="form-check-label pl-2"
                labelHtmlFor={OPTIONS.IS_PAST_WEEK}
              />
            </div>

            <div className="form-check mb-1">
              <input
                className="form-check-input"
                type="radio"
                name="options"
                id={OPTIONS.IS_CUSTOM_INTERVAL}
                value={OPTIONS.IS_CUSTOM_INTERVAL}
                checked={values.options === OPTIONS.IS_CUSTOM_INTERVAL}
                onChange={() => {
                  setFieldValue('options', OPTIONS.IS_CUSTOM_INTERVAL);

                  let dateFrom = getStartDateForSchoolYear(now);

                  setFieldValue('dateFrom', new Date(dateFrom.setHours(0, 0, 1, 0)));
                  setFieldValue('dateTo', new Date(now.setHours(23, 59, 59, 999)));
                }}
              />

              <LabelWithQuestionIcon
                labelText="Custom interval"
                hintText="Select to specify a custom date interval"
                customLabelClass="form-check-label pl-2"
                labelHtmlFor={OPTIONS.IS_CUSTOM_INTERVAL}
              />
            </div>

            {values.options === OPTIONS.IS_CUSTOM_INTERVAL && (
              <div>
                <div className="form-group">
                  <label>Date from</label>
                  <Field name="dateFrom">
                    {({ field, form }) => (
                      <div className="mb-3">
                        <DatePicker
                          {...field}
                          selected={field.value}
                          onChange={date => {
                            form.setFieldValue('dateFrom', date);
                            if (form.values.dateTo && form.values.dateTo < date) {
                              form.setFieldValue('dateTo', date);
                            }
                          }}
                          className="form-control"
                          dateFormat="dd-MM-yyyy"
                        />
                      </div>
                    )}
                  </Field>
                </div>
                <div className="form-group">
                  <label>Date to</label>
                  <Field name="dateTo">
                    {({ field, form }) => (
                      <div className="mb-3">
                        <DatePicker
                          {...field}
                          selected={field.value}
                          onChange={date => {
                            form.setFieldValue('dateTo', date);
                          }}
                          minDate={form.values.dateFrom}
                          className="form-control"
                          dateFormat="dd-MM-yyyy"
                        />
                      </div>
                    )}
                  </Field>
                </div>
              </div>
            )}
          </div>

          <div className="mt-3 mb-3">
            <MultiSelectCheckboxes
              items={values.genders}
              cols={3}
              onChange={index => onGendersChange(index, values, setFieldValue)}
            />
          </div>

          <div className="mt-3 mb-3">
            <MultiSelectCheckboxes
              items={values.ages}
              cols={3}
              onChange={index => onAgesChange(index, values, setFieldValue)}
            />
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isSelectAllAges"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isSelectAllAges}
                    customClass="mb-3"
                    onChange={event => onSelectAllAgesChange(event, values, setFieldValue)}
                  />
                )}
              />

              <label htmlFor="isSelectAllAges" className="form-check-label pl-2">
                Select all ages
              </label>
            </div>
          </div>

          <button type="button" className="btn btn-secondary mr-3" onClick={onCloseClick}>
            Cancel
          </button>

          <button type="submit" className="btn btn-primary">
            Get
          </button>
        </Form>
      )}
    </Formik>
  );
}
