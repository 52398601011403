import * as React from 'react';
import * as propz from 'propz';
import { Field, Form, Formik } from 'formik';
import { SchoolEvent } from 'Src/models/event';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING, REPORT_PRINT_FORMAT } from 'Src/consts/report';
import { Switch } from 'Src/components/Switch/Switch';
import { LabelWithQuestionIcon } from 'Src/components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import '../../SchoolEventView.scss';

const OPTIONS = {
  IS_THIS_EVENT: 'isThisEvent',
  IS_FUTURE_EVENTS: 'isFutureEvents',
  IS_ALL_EVENTS: 'isAllEvents'
};

interface Props {
  event: SchoolEvent;
  activeSchoolId: string;
  onCloseClick: () => void;
  onSubmit: (data: any, reportType: string) => void;
}

//report 068
export function PaperAttendanceRegisterEventReportForm(props: Props) {
  const { event } = props;

  const requestInitial = {
    options: OPTIONS.IS_THIS_EVENT,
    isThisEvent: false,
    isFutureEvents: false,
    isAllEvents: false
  };

  return (
    <Formik
      onSubmit={values => {
        const valuesToTransfer = { ...values };
        propz.set(valuesToTransfer, [valuesToTransfer.options], true);
        delete valuesToTransfer.options;

        props.onSubmit(valuesToTransfer, REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.PAPER_ATTENDANCE_REGISTER_EVENT);
      }}
      initialValues={requestInitial}
      render={({ values, setFieldValue }) => (
        <Form>
          <div className="bReportFormTitle">Paper attendance register</div>
          <div className="mb-3 mt-3">{'A printable paper register to mark student attendance'}</div>

          <div className="form-group">
            <div className="form-check mb-1">
              <input
                className="form-check-input"
                type="radio"
                name={OPTIONS.IS_THIS_EVENT}
                id={OPTIONS.IS_THIS_EVENT}
                value={OPTIONS.IS_THIS_EVENT}
                checked={values.options === OPTIONS.IS_THIS_EVENT}
                onChange={() => {
                  setFieldValue('options', OPTIONS.IS_THIS_EVENT);
                }}
              />
              <LabelWithQuestionIcon
                labelText="This event only"
                hintText={'Select this option if you only need to print out a register for this particular event'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor={OPTIONS.IS_THIS_EVENT}
              />
            </div>

            <div className="form-check mb-1">
              <input
                className="form-check-input"
                type="radio"
                name={OPTIONS.IS_FUTURE_EVENTS}
                id={OPTIONS.IS_FUTURE_EVENTS}
                value={OPTIONS.IS_FUTURE_EVENTS}
                checked={values.options === OPTIONS.IS_FUTURE_EVENTS}
                onChange={() => {
                  setFieldValue('options', OPTIONS.IS_FUTURE_EVENTS);
                }}
              />
              <LabelWithQuestionIcon
                labelText="This event and all subsequent events"
                hintText={
                  'Select this option if you need to print out a register for the selected event and all subsequent events within this club'
                }
                customLabelClass="form-check-label pl-2"
                labelHtmlFor={OPTIONS.IS_FUTURE_EVENTS}
              />
            </div>

            <div className="form-check mb-1">
              <input
                className="form-check-input"
                type="radio"
                name={OPTIONS.IS_ALL_EVENTS}
                id={OPTIONS.IS_ALL_EVENTS}
                value={OPTIONS.IS_ALL_EVENTS}
                checked={values.options === OPTIONS.IS_ALL_EVENTS}
                onChange={() => {
                  setFieldValue('options', OPTIONS.IS_ALL_EVENTS);
                }}
              />
              <LabelWithQuestionIcon
                labelText="All events"
                hintText={
                  'Select this option to print out a register for all events in the club, including both past and future events.'
                }
                customLabelClass="form-check-label pl-2"
                labelHtmlFor={OPTIONS.IS_ALL_EVENTS}
              />
            </div>
          </div>

          <button type="button" className="btn btn-secondary mr-3" onClick={props.onCloseClick}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Get
          </button>
        </Form>
      )}
    />
  );
}
