import { AppUser } from 'Src/views/App/App';
import axios from 'axios';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../consts/table';
import { DEFAULT_FILTER } from '../admin/user';
import { Count } from '../../../models/count';
import { SchoolForm } from '../../../models/form';
import { SchoolHouse } from '../../../models/house';

export function getSchoolUnionSchoolStudents(user: AppUser, schoolId: string, filter = DEFAULT_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter
    }
  };

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/schools/${schoolId}/students`, config)
    .then(response => response.data);
}

export function getAllSchoolUnionSchoolStudents(user: AppUser, schoolId: string, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/schools/${schoolId}/students`, config)
    .then(response => {
      return response.data;
    });
}

export function getSchoolUnionSchoolStudentsCount(user: AppUser, schoolId: string, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where
        }
      }
    };
  } else {
    config = {
      headers: { usid: key }
    };
  }

  return axios
    .get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/schools/${schoolId}/students/count`, config)
    .then(response => {
      return response.data;
    });
}

export function addSchoolUnionSchoolStudent(user: AppUser, schoolId: string, data) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/schools/${schoolId}/students`, data, config)
    .then(response => {
      return response.data;
    });
}

export function deleteSchoolUnionSchoolStudent(user: AppUser, schoolId: string, studentId) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .delete(`${window.apiBase}/i/schools/${activeSchoolId}/schools/${schoolId}/students/${studentId}`, config)
    .then(response => {
      return response.data;
    });
}

export function updateSchoolUnionSchoolStudent(user: AppUser, schoolId: string, studentId, data) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .put(`${window.apiBase}/i/schools/${activeSchoolId}/schools/${schoolId}/students/${studentId}`, data, config)
    .then(response => {
      return response.data;
    });
}

export function getAllSchoolUnionSchoolForms(user: AppUser, schoolId: string, where?): Promise<SchoolForm[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios
    .get<SchoolForm[]>(`${window.apiBase}/i/schools/${activeSchoolId}/schools/${schoolId}/forms`, config)
    .then(response => {
      return response.data;
    });
}

export function getSchoolUnionSchoolForms(
  user: AppUser,
  schoolId: string,
  filter = DEFAULT_FILTER
): Promise<SchoolForm[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter
    }
  };

  return axios
    .get<SchoolForm[]>(`${window.apiBase}/i/schools/${activeSchoolId}/schools/${schoolId}/forms`, config)
    .then(response => {
      return response.data;
    });
}

export function getSchoolUnionSchoolFormsCount(user: AppUser, schoolId: string, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where
        }
      }
    };
  } else {
    config = {
      headers: { usid: key }
    };
  }

  return axios
    .get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/schools/${schoolId}/forms/count`, config)
    .then(response => {
      return response.data;
    });
}

export function createSchoolUnionSchoolForm(user: AppUser, schoolId: string, data) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/schools/${schoolId}/forms`, data, config)
    .then(response => {
      return response.data;
    });
}

export function deleteSchoolUnionSchoolForm(user: AppUser, schoolId: string, formId) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .delete(`${window.apiBase}/i/schools/${activeSchoolId}/schools/${schoolId}/forms/${formId}`, config)
    .then(response => {
      return response.data;
    });
}

export function updateSchoolUnionSchoolForm(user: AppUser, schoolId: string, formId, data) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .put(`${window.apiBase}/i/schools/${activeSchoolId}/schools/${schoolId}/forms/${formId}`, data, config)
    .then(response => {
      return response.data;
    });
}

export function checkAvailabilityFormName(user: AppUser, schoolId, data) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };
  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/schools/${schoolId}/forms/check`, data, config)
    .then(response => {
      return response.data;
    });
}
