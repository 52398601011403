import * as React from 'react';
import { Field, Form, Formik } from 'formik';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from '../../../../../../consts/report';
import { Tournament } from '../../../../../../models/tournament';
import { Switch } from '../../../../../../components/Switch/Switch';
import { LabelWithQuestionIcon } from '../../../../../../components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import DatePicker from 'react-datepicker';
import { getFinishDateForSchoolYear } from '../../../../../../helpers/calendar/calendar';

interface Props {
  onCloseClick: () => void;
  onSubmit: (data: any, reportType: string) => void;
}

// report 047
export function SchoolTournamentScheduleReportForm({ onCloseClick, onSubmit }: Props) {
  const now = new Date();
  let dateTo = getFinishDateForSchoolYear(now);

  const requestInitial = {
    dateFrom: now,
    dateTo,
    isShowBooked: true,
    isShowWaitingList: true,
    isShowAvailableForBooking: true,
    isShowCancelledPostponed: false,
    isShowUnavailableForBooking: false
  };

  return (
    <Formik
      onSubmit={values => onSubmit(values, REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.SCHOOL_TOURNAMENT_SCHEDULE)}
      initialValues={requestInitial}
      render={({ values, setFieldValue }) => (
        <Form>
          <div className="bReportFormTitle">Tournament schedule</div>
          <div className="mb-3 mt-3">{'View details of the tournaments your school is eligible to join.'}</div>
          <div className="form-group">
            <label>Date from</label>
            <Field name="dateFrom">
              {({ field, form }) => (
                <div className="mb-3">
                  <DatePicker
                    {...field}
                    selected={field.value}
                    onChange={date => {
                      form.setFieldValue('dateFrom', date);
                      if (form.values.dateTo && form.values.dateTo < date) {
                        form.setFieldValue('dateTo', date);
                      }
                    }}
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
              )}
            </Field>
          </div>
          <div className="form-group">
            <label>Date to</label>
            <Field name="dateTo">
              {({ field, form }) => (
                <div className="mb-3">
                  <DatePicker
                    {...field}
                    selected={field.value}
                    onChange={date => {
                      form.setFieldValue('dateTo', date);
                    }}
                    minDate={form.values.dateFrom}
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
              )}
            </Field>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isShowBooked"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowBooked}
                    onChange={event => {
                      const isShowBooked = event.target.checked;
                      setFieldValue('isShowBooked', isShowBooked);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show booked tournaments"
                hintText={'Show tournaments where your school is a participant.'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowBooked"
              />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isShowWaitingList"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowWaitingList}
                    onChange={event => {
                      const isShowWaitingList = event.target.checked;
                      setFieldValue('isShowWaitingList', isShowWaitingList);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show waiting list"
                hintText={'Show tournaments your school has been added to the waiting list for.'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowWaitingList"
              />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isShowBooked"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowAvailableForBooking}
                    onChange={event => {
                      const isShowAvailableForBooking = event.target.checked;
                      setFieldValue('isShowAvailableForBooking', isShowAvailableForBooking);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show available for booking"
                hintText={'Show tournaments available for booking.'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowAvailableForBooking"
              />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isShowCancelledPostponed"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowCancelledPostponed}
                    onChange={event => {
                      const isShowCancelledPostponed = event.target.checked;
                      setFieldValue('isShowCancelledPostponed', isShowCancelledPostponed);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show cancelled/postponed tournaments"
                hintText={'Show tournaments that have been cancelled or postponed.'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowCancelledPostponed"
              />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isShowUnavailableForBooking"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowUnavailableForBooking}
                    onChange={event => {
                      const isShowUnavailableForBooking = event.target.checked;
                      setFieldValue('isShowUnavailableForBooking', isShowUnavailableForBooking);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show tournaments unavailable for booking"
                hintText={'Show tournaments if bookings are unavailable.'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowUnavailableForBooking"
              />
            </div>
          </div>

          <button type="button" className="btn btn-secondary mr-3" onClick={onCloseClick}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Get
          </button>
        </Form>
      )}
    />
  );
}
