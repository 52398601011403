import * as React from 'react';

interface Props {
  onClick?: () => void;
}

export const DetailsIcon = (props: Props) => {
  const { onClick } = props;
  const isOnClickExist = typeof onClick !== 'undefined';

  const emptyFunc = () => {};

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={32}
      viewBox="0 0 520 520"
      xmlSpace="preserve"
      onClick={isOnClickExist ? onClick : emptyFunc}
    >
      <title>Click the button to see detailed information about the tournament.</title>
      <g fill="#2394be" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M429.241 82.759C382.967 36.484 321.442 11 256 11S129.033 36.484 82.759 82.759 11 190.558 11 256s25.484 126.967 71.759 173.241S190.558 501 256 501s126.967-25.484 173.241-71.759S501 321.442 501 256s-25.484-126.967-71.759-173.241zM256 481C131.935 481 31 380.065 31 256S131.935 31 256 31s225 100.935 225 225-100.935 225-225 225z"
          data-original="#000000"
        />
        <path
          d="M139.328 125.185c-3.905-3.904-10.237-3.904-14.143 0-72.131 72.132-72.131 189.499 0 261.63 1.953 1.952 4.512 2.929 7.071 2.929s5.119-.977 7.071-2.929c3.905-3.905 3.905-10.237 0-14.143-64.334-64.333-64.334-169.012 0-233.346 3.906-3.904 3.906-10.236.001-14.141zM386.815 125.185c-3.905-3.904-10.237-3.904-14.143 0-3.905 3.905-3.905 10.237 0 14.142 64.334 64.334 64.334 169.013 0 233.346-3.905 3.905-3.905 10.237 0 14.143 1.953 1.952 4.512 2.929 7.071 2.929s5.119-.977 7.071-2.929c72.132-72.132 72.132-189.499.001-261.631zM256 211c-30.327 0-55 24.673-55 55v120c0 30.327 24.673 55 55 55s55-24.673 55-55V266c0-30.327-24.673-55-55-55zm35 175c0 19.299-15.701 35-35 35s-35-15.701-35-35V266c0-19.299 15.701-35 35-35s35 15.701 35 35zM256 81c-30.327 0-55 24.673-55 55s24.673 55 55 55 55-24.673 55-55-24.673-55-55-55zm0 90c-19.299 0-35-15.701-35-35s15.701-35 35-35 35 15.701 35 35-15.701 35-35 35z"
          data-original="#000000"
        />
      </g>
    </svg>
  );
};

DetailsIcon.displayName = 'DetailsIcon';
