import * as React from 'react';
import * as Moment from 'moment';
import * as propz from 'propz';
import { FunctionComponent } from 'react'
import { GENERAL_MESSAGE_DELIVERY_STATUS_OBJECT } from '../../consts/generalMessage'

interface Props {
  message: any
}

const ROWS = [
  { title: 'Title', field: 'title'} ,
  { title: 'Description', field: 'description'} ,
  { title: 'Created', field: 'createdAt'} ,
  { title: 'Created by', field: 'createdBy' },
  { title: 'Status', field: 'status' },
  { title: 'Send to parents', field: 'parents' },
  { title: 'Send to students', field: 'students' },
  { title: 'Send to staff', field: 'staffs' },
  { title: 'Sent at', field: 'sentAt' },
  { title: 'Sender', field: 'senderData' }
];

export const GeneralMessageContent: FunctionComponent<Props> = (props) => {
  const rows = ROWS.map( (row, index) => {
    let contentCell: string;
    const field = row.field;
    
    const message = props.message;
  
    const createdBy = message.createdBy;
    const createdAt = message.createdAt;
    const sentAt = message.sentAt;
    const isStudents = message.subscribers.types.students;
    const isParents = message.subscribers.types.parents;
    const isStaffs = message.subscribers.types.schoolStaff;
    
    switch(field) {
      case 'createdBy':
        const {firstName, lastName} = createdBy;
        contentCell = `${firstName} ${lastName}`;
        break;
      case 'createdAt':
        const createdAtConverted =  Moment(createdAt).format('DD/MM/YYYY HH:mm:ss');
        contentCell = createdAtConverted;
        break;
      case 'students':
        contentCell = isStudents ? 'Yes' : 'No';
        break;
      case 'parents':
        contentCell = isParents ? 'Yes' : 'No';
        break;
      case 'staffs':
        contentCell = isStaffs ? 'Yes' : 'No';
        break;
      case 'sentAt':
        const isSent = props.message.status === GENERAL_MESSAGE_DELIVERY_STATUS_OBJECT.SENT;
        const sentAtConverted =  Moment(sentAt).format('DD/MM/YYYY HH:mm:ss');
        
        contentCell = isSent ? sentAtConverted : 'Not sent yet';
        break;
      case 'senderData':
        const senderFirstName = propz.get(props.message, ['senderData', 'firstName'], '');
        const senderLastName = propz.get(props.message, ['senderData', 'lastName'], '');

        contentCell = `${senderFirstName} ${senderLastName}`;
        break;
      default:
        contentCell = props.message[row.field];
    }
    
    return (
      <tr key = {`message_content_${index}`}>
        <td>{row.title}</td>
        <td>{contentCell}</td>
      </tr>
    );
  });
  
  return (
    <table className={"table table-sm"}>
      <tbody>
        {rows}
      </tbody>
    </table>
  );
};

GeneralMessageContent.displayName = 'GeneralMessageContent';