import * as React from 'react';
import { Sport } from 'Src/models/sport';
import { Autocomplete } from 'Src/components/Autocomplete/Autocomplete';
import {
  MultiSelectCheckboxes,
  MultiSelectCheckboxItem
} from 'Src/components/MultiSelectCheckboxes/MultiSelectCheckboxes';
import { ClubWizardErrors } from '../ClubWizard';
import { ClubSport, ClubStaff } from 'Src/models/club';
import { LabelWithQuestionIcon } from 'Src/components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import { getFullName, getName } from 'Src/helpers/autocomplete/autocomplete';

interface Props {
  onInputChange: (event) => void;
  onSportSelect: (sport: Sport) => void;
  onAgesChange: (index: number) => void;
  onGenderChange: (index: number) => void;
  getSports: (text: string) => any;
  name: string;
  description: string;
  sport: Sport | ClubSport;
  ageItems: MultiSelectCheckboxItem[];
  genderItems: MultiSelectCheckboxItem[];
  errors: ClubWizardErrors;
  coaches: ClubStaff[];
  membersOfStaff: ClubStaff[];
  setCoaches: (coaches: ClubStaff[]) => void;
  setMembersOfStaff: (membersOfStaff: ClubStaff[]) => void;
  getCoachesSearchFunction: (text: string) => Promise<any>;
  getMembersOfStaffSearchFunction: (text: string) => Promise<any>;
}

export function ClubWizardSummaryStep(props: Props) {
  const {
    onInputChange,
    name,
    description,
    sport,
    getSports,
    onSportSelect,
    onAgesChange,
    onGenderChange,
    ageItems,
    genderItems,
    membersOfStaff,
    coaches,
    setCoaches,
    setMembersOfStaff,
    errors,
    getCoachesSearchFunction,
    getMembersOfStaffSearchFunction
  } = props;

  const isSportExist = typeof sport !== 'undefined';

  const renderCoaches = () => {
    return coaches.map(coach => {
      return (
        <div key={coach.id} className={'d-flex'}>
          <div className="eSelectedItem">
            <span>{getFullName(coach)}</span>
            <div className="ml-1">
              <div
                className="eButtonClose"
                onClick={() => {
                  const coachesFiltered = coaches.filter(c => c.id !== coach.id);
                  setCoaches(coachesFiltered);
                }}
              >
                <span>&times;</span>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  const renderMembersOfStaff = () => {
    return membersOfStaff.map(memberOfStaff => {
      return (
        <div key={memberOfStaff.id} className={'d-flex'}>
          <div className="eSelectedItem">
            <span>{getFullName(memberOfStaff)}</span>
            <div className="ml-1">
              <div
                className="eButtonClose"
                onClick={() => {
                  const membersOfStaffFiltered = membersOfStaff.filter(m => m.id !== memberOfStaff.id);
                  setMembersOfStaff(membersOfStaffFiltered);
                }}
              >
                <span>&times;</span>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className={'mt-3'}>
      <div className="form-group">
        <LabelWithQuestionIcon
          labelText="Name"
          hintText={'This is the name that will appear on all users calendars who' + ' have access to the club.'}
        />
        <input type="text" className="form-control mb-3" onChange={onInputChange} value={name} name={'name'} />
        {errors.name && <div className="alert alert-danger">Required</div>}
      </div>

      <div className="form-group">
        <LabelWithQuestionIcon
          labelText="Description"
          hintText={
            'This is for school users to enable you to differentiate between clubs in the club list.' +
            ' Parents and students will also see this description when booking onto a club.'
          }
        />
        <input type="text" className="form-control" onChange={onInputChange} value={description} name={'description'} />
      </div>

      <div className="container-fluid pl-0">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <div className="d-flex justify-content-between">
                <LabelWithQuestionIcon
                  labelText="Activity"
                  hintText={'Select the activity that best describes your club'}
                />
              </div>
              <Autocomplete
                searchFunction={getSports}
                getElementTitle={getName}
                customClass="mFullWidth mb-3"
                defaultItem={sport}
                placeholder="Start typing the activity name"
                onSelect={onSportSelect}
              />
              {errors.sport && <div className="alert alert-danger">Required</div>}
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid pl-0">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <LabelWithQuestionIcon
                labelText="Gender"
                hintText={
                  "For mixed events turn on both checkboxes. Please be aware, you won't be " +
                  'able to change this once you have activated the club, however while it is set as ' +
                  "a 'Draft' club you are able to change the gender. NOTE: If you send invitations " +
                  'to one gender, however then decide to change the gender, users can still accept the ' +
                  'invite even if they are no longer eligible to participate within the club.'
                }
              />
              <MultiSelectCheckboxes
                items={genderItems}
                cols={3}
                isDisabled={!isSportExist}
                onChange={onGenderChange}
              />
            </div>
          </div>
        </div>
        {errors.gender && <div className="alert alert-danger">Required</div>}
      </div>

      <div className="container-fluid pl-0">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <LabelWithQuestionIcon
                labelText="Ages"
                hintText={
                  'You can leave this field blank for events involving all the ages. ' +
                  'You will be able to change this later if, for instance, you need to add extra ' +
                  'students - younger or older. NOTE: If you send invitations to one age, however ' +
                  'then decide to remove this age group, users can still accept the invite even if ' +
                  'they are no longer eligible to participate within the club.'
                }
              />
              <MultiSelectCheckboxes items={ageItems} cols={3} onChange={onAgesChange} />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid pl-0">
        <div className="row">
          <div className="col-md-3">
            <LabelWithQuestionIcon
              labelText="Leader/Coach"
              hintText={
                'This is where you can tag any users who have a coach role, this role is ' +
                'often provided to external coaches / club leaders. All users who have access to the ' +
                'clubs will see the staff members tagged. If you include any users here then they will ' +
                'recieve a notification to let them know a club has been created in draft format. NOTE: ' +
                'Dependant on how your schools settings are managed will depend on the coach role - there ' +
                'are three options for coach role functionality. \n' +
                '1 - a Coach can see all events within their calendar\n' +
                '2 - a Coach has access to events providing they have been affiliated with the activity ' +
                'selected when creating the club (they are also only selectable when creating a club for ' +
                'their afiliated activities) \n' +
                '3 - a Coach can only view clubs they have been added to as a Leader/Coach'
              }
            />
            <Autocomplete
              searchFunction={text => getCoachesSearchFunction(text)}
              getElementTitle={getFullName}
              customClass="mb-3"
              defaultItem={undefined}
              disabled={!isSportExist}
              onSelect={coach => {
                const coachesUpdated = [...coaches, coach];
                setCoaches(coachesUpdated);
              }}
            />
            <div className="d-flex flex-wrap">{renderCoaches()}</div>
          </div>

          <div className="col-md-3 ">
            <LabelWithQuestionIcon
              labelText="Member of staff"
              hintText={
                'This is where you can tag any staff users. All users who have access to the' +
                ' clubs will see the staff members tagged. If you include any staff members here then ' +
                'they will recieve a notification to let them know a club has been created in draft format.' +
                ' Please note members of staff view type of clubs is dependant on your account settings ' +
                'please see information below:\n' +
                "1. If you have 'view only their events' turned ON then members of staff can ONLY view events " +
                'they have been linked with\n' +
                "2. If you have 'view only their events' turned OFF then members of staff will ONLY see events" +
                ' that have been created using the activity they are affiliated with\n' +
                "3. If you have 'view only their events' turned OFF and a member of staff is not affiliated" +
                ' to any activity, they will see all activities within their calendar'
              }
            />
            <Autocomplete
              searchFunction={text => getMembersOfStaffSearchFunction(text)}
              getElementTitle={getFullName}
              customClass="mb-3"
              defaultItem={undefined}
              disabled={!isSportExist}
              onSelect={memberOfStaff => {
                const membersOfStaffUpdated = [...membersOfStaff, memberOfStaff];
                setMembersOfStaff(membersOfStaffUpdated);
              }}
            />
            <div className="d-flex flex-wrap">{renderMembersOfStaff()}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
