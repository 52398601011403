import * as React from 'react';
import { Component } from 'react';
import { parse } from 'query-string';
import * as BPromise from 'bluebird';
import { News } from 'Src/models/news';
import {
  ColumnDefinition,
  getFilters2,
  getOrder,
  getSearchFilter,
  getSearchOrder,
  getServerFieldSectionWhere,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { createNews, deleteNews, getAllNews, getNews, getNewsCount, updateNews } from 'Src/helpers/service/admin/news';
import { Loader } from 'Src/components/Loader/Loader';
import { NewsForm } from 'Src/components/NewsForm/NewsForm';
import { ResizablePanel } from 'Src/components/ResizablePanel/ResizablePanel';
import { NewsFooter } from 'Src/components/NewsFooter/NewsFooter';
import { AppUser } from 'Src/views/App/App';
import './NewsList.css';
import { getNewsDate } from 'Src/helpers/accessor/accessor';
import { History, Location } from 'history';
import { Grid2 } from 'Src/components/Grid/Grid2';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Date',
    field: 'date',
    isSort: true,
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: getNewsDate
  },
  {
    text: 'Title',
    field: 'title',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['title']
  }
];

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

interface State {
  items: News[];
  currentPage: number;
  selectedItems: News[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;

  isEditNewsPopupOpened: boolean;
  isDeleteNewsModalOpen: boolean;
}

export class NewsList extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',

      isEditNewsPopupOpened: false,
      isDeleteNewsModalOpen: false
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setItems();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const getNewsPromise = getNews(user, serverQueryFilter);
    const getNewsCountPromise = getNewsCount(user, where);
    const promises = [getNewsCountPromise, getNewsPromise];

    return BPromise.all(promises).then(([countObj, news]) => {
      this.setState({
        currentPage: page,
        sortDirection: sortDirection,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: news,
        total: countObj.count,
        basePath: history.location.pathname,
        isLoading: false
      });

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedNews = items[index];

    const selectedNewsIndex = selectedItems.findIndex(news => selectedNews.id === news.id);
    let selectedNewsUpdated = [...selectedItems];

    if (selectedNewsIndex !== -1) {
      selectedNewsUpdated.splice(selectedNewsIndex, 1);
    } else {
      selectedNewsUpdated.push(selectedNews);
    }

    this.setState({
      selectedItems: selectedNewsUpdated
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const newsFiltered = items.filter(news => {
      return selectedItems.every(selectedNews => selectedNews.id !== news.id);
    });

    const selectedNews = [...selectedItems, ...newsFiltered];

    this.setState({
      selectedItems: selectedNews,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    getAllNews(user, where).then(news => {
      const newsFiltered = news.filter(news => {
        return selectedItems.every(selectedNews => selectedNews.id !== news.id);
      });

      const selectedNews = [...selectedItems, ...newsFiltered];

      this.setState({
        selectedItems: selectedNews,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters;

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...filters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...filters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
        default:
          nextFilters = { ...filters };
          console.error('Can not set new filter');
      }
    } else {
      nextFilters = {
        ...filters,
        [filterField]: filterValue
      };
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: ''
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  closeNewsFormModal = (): void => {
    this.setState({
      isEditNewsPopupOpened: false
    });
  };

  onTabClick = (event, index: number) => {
    event.preventDefault();

    this.setState({
      total: index
    });
  };

  onEditNewsClick = event => {
    event.preventDefault();

    this.setState({ isEditNewsPopupOpened: true });
  };

  onFormSubmit = data => {
    const { selectedItems } = this.state;
    const { user } = this.props;

    switch (selectedItems.length) {
      case 0: {
        createNews(user, data).then(news => {
          this.closeNewsFormModal();
          this.setState({
            isLoading: true,
            selectedItems: [news]
          });
          this.setItems();
        });
        break;
      }

      case 1: {
        updateNews(user, selectedItems[0].id, data).then(news => {
          this.closeNewsFormModal();
          this.setState({
            isLoading: true,
            selectedItems: [news]
          });
          this.setItems();
        });
        break;
      }

      default:
        return;
    }
  };

  renderNewsForm() {
    const { isEditNewsPopupOpened, selectedItems } = this.state;

    switch (selectedItems.length) {
      case 0:
        return (
          <SimpleModal customClass="mNewsFormWidth" isOpen={isEditNewsPopupOpened} title={'Add news'}>
            <NewsForm user={this.props.user} onCloseClick={this.closeNewsFormModal} onFormSubmit={this.onFormSubmit} />
          </SimpleModal>
        );
      case 1:
        return (
          <SimpleModal customClass="mNewsFormWidth" isOpen={isEditNewsPopupOpened} title={'Edit news'}>
            <NewsForm
              user={this.props.user}
              onCloseClick={this.closeNewsFormModal}
              onFormSubmit={this.onFormSubmit}
              news={selectedItems[0]}
            />
          </SimpleModal>
        );
      default:
        return null;
    }
  }

  onCreateNewsClick = () => {
    this.setState({
      isEditNewsPopupOpened: true
    });
  };

  deleteNewsModalOpen = (): void => {
    this.setState({
      isDeleteNewsModalOpen: true
    });
  };

  deleteNewsModalClose = (): void => {
    this.setState({
      isDeleteNewsModalOpen: false
    });
  };

  onRemoveNewsClick = () => {
    const { selectedItems } = this.state;

    this.setState({ isLoading: true });
    const promises = selectedItems.map(news => {
      return deleteNews(this.props.user, news.id);
    });
    BPromise.all(promises).then(() => {
      this.setState({
        selectedItems: [],
        isDeleteNewsModalOpen: false
      });
      this.setItems();
    });
  };

  renderDeleteNewsModal() {
    const { selectedItems, isDeleteNewsModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isDeleteNewsModalOpen}
        title={'Remove News'}
        body={`Are you sure you want to remove ${
          selectedItems.length === 1 ? `News ${selectedItems[0].title}` : `${selectedItems.length} News`
        }?`}
        buttonText={'Delete'}
        onButtonClick={this.onRemoveNewsClick}
        onCloseClick={this.deleteNewsModalClose}
      />
    );
  }

  renderSummary() {
    const { selectedItems, items } = this.state;
    const isOneItemSelected = selectedItems.length === 1;

    if (!isOneItemSelected) {
      return <div className="font-weight-bold">Select a news above</div>;
    }

    const selectedNews = items.find(singleNews => singleNews.id === selectedItems[0].id);

    return <NewsFooter news={selectedNews} onEditClick={this.onEditNewsClick} />;
  }

  render() {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isLoading,
      isSelectAllChecked,
      currentPage,
      total,
      isEditNewsPopupOpened
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }
    const classes = isEditNewsPopupOpened ? 'mt-3 modal-open' : 'mt-3';
    const actionItems = [
      {
        itemText: 'Add News',
        onItemClick: this.onCreateNewsClick,
        isActive: selectedItems.length === 0
      },
      {
        itemText: 'Remove News',
        onItemClick: this.deleteNewsModalOpen,
        isActive: selectedItems.length > 0
      }
    ];

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderDeleteNewsModal()}
        {this.renderNewsForm()}
        <ResizablePanel>
          <Grid2
            dataItems={items}
            filters={filters}
            currentPage={currentPage}
            total={total}
            isSelectAllChecked={isSelectAllChecked}
            isDataFiltered={isDataFiltered}
            sortDirection={sortDirection}
            sortColumnsName={sortColumnsName}
            isShowFilter={isShowFilter}
            dataItemsSelected={selectedItems}
            columns={COLUMNS}
            actionItems={actionItems}
            onItemClick={this.onItemClick}
            onSortClick={this.onTableSortClick}
            onApplyFilterClick={this.onApplyFilterClick}
            onClearFilterClick={this.onClearFilterClick}
            onTableFilterChange={this.onTableFilterChange}
            onTableFilterClick={this.onTableFilterClick}
            setCurrentPageParams={this.setCurrentPageParams}
            onSelectAllClick={this.onSelectAllClick}
            onSelectAllOnPageClick={this.onSelectAllOnPageClick}
            onUnselectAllClick={this.onUnselectAllClick}
          />
          {this.renderSummary()}
        </ResizablePanel>
      </div>
    );
  }
}
