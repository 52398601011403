import * as React from 'react';
import { Component } from 'react';
import { ParentsDeliverySettings } from '../../models/generalMessage';
import {LabelWithQuestionIcon} from "../LabelWithQuestionIcon/LabelWithQuestionIcon";

interface Props {
  parentsDeliverySettings: ParentsDeliverySettings;
  onDeliverySettingsChanged: (settings: ParentsDeliverySettings) => void;
  disabled: boolean;
}

interface State {
  parentsDeliverySettings: ParentsDeliverySettings;
}

// this is not on the server it's used only on the frontend and only in this file
enum PRIORITIES {
  FIRST_ONLY = 'FIRST_ONLY',
  SECOND_ONLY = 'SECOND_ONLY',
  THIRD_ONLY = 'THIRD_ONLY'
}

export class GeneralMessagesSettingFormPriorities extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      parentsDeliverySettings: { ...props.parentsDeliverySettings }
    };
  }

  onAllParentsCheckedChange = () => {
    const all = !this.state.parentsDeliverySettings.all;
    const newSettings = {
      all: all,
      firstPriority: false,
      secondPriority: false,
      thirdPriority: false
    };

    this.onDeliverySettingsChanged(newSettings);
  };

  onPrioritiesCheckedChange = (event: any) => {
    const priority = event.target.value;
    const { parentsDeliverySettings } = this.state;
    let newSettings = parentsDeliverySettings;

    switch (priority) {
      case PRIORITIES.FIRST_ONLY:
        newSettings.firstPriority = !parentsDeliverySettings.firstPriority;
        break;
      case PRIORITIES.SECOND_ONLY:
        newSettings.secondPriority = !parentsDeliverySettings.secondPriority;
        break;
      case PRIORITIES.THIRD_ONLY:
        newSettings.thirdPriority = !parentsDeliverySettings.thirdPriority;
        break;
    }

    this.onDeliverySettingsChanged(newSettings);
  };

  onDeliverySettingsChanged = newSettings => {
    this.setState({
      parentsDeliverySettings: newSettings
    });
    this.props.onDeliverySettingsChanged(newSettings);
  };

  render() {
    const { all, firstPriority, secondPriority, thirdPriority } = this.state.parentsDeliverySettings;
    const { disabled } = this.props;
    const isPrioritiesDisabled = all || disabled;

    return (
      <div className="form-group row">
        <LabelWithQuestionIcon
            labelText="Priorities"
            hintText={"Providing parents have been assigned priority numbers on your MIS and that your MIS integration " +
            "has been set up you can select which priority parents to send a message to, for example; all parents or " +
            "just to priority 1 and 2."}
            customClass="col-sm-2 text-secondary"
        />

        <div className="col-sm-10">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              checked={all}
              onChange={this.onAllParentsCheckedChange}
              disabled={disabled}
            />
            <label className="form-check-label">All parents</label>
          </div>

          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              checked={firstPriority}
              disabled={isPrioritiesDisabled}
              value={PRIORITIES.FIRST_ONLY}
              onChange={this.onPrioritiesCheckedChange}
            />
            <label className="form-check-label">1st priority only</label>
          </div>

          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              checked={secondPriority}
              disabled={isPrioritiesDisabled}
              value={PRIORITIES.SECOND_ONLY}
              onChange={this.onPrioritiesCheckedChange}
            />
            <label className="form-check-label">2nd priority only</label>
          </div>

          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              checked={thirdPriority}
              disabled={isPrioritiesDisabled}
              value={PRIORITIES.THIRD_ONLY}
              onChange={this.onPrioritiesCheckedChange}
            />
            <label className="form-check-label">3rd priority only</label>
          </div>
        </div>
      </div>
    );
  }
}
