import * as React from 'react';
import { Field, Form, Formik } from 'formik';
import { AppUser } from 'Src/views/App/App';
import * as Yup from 'yup';
import { LEAGUE_AGGREGATION_TYPE, LEAGUE_AGGREGATION_TYPE_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/league';
import { PUBLIC_SITE_STATUS_OPTIONS, PUBLIC_SITE_STATUS_OPTIONS_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/tournament';
import { Button } from 'Src/components/Button/Button';
import * as propz from 'propz';
import { SchoolLeague } from 'Src/models/schoolLeague';
import { useState } from 'react';
import { LabelWithQuestionIcon } from 'Src/components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import { uploadImage } from 'Src/helpers/service/image';
import { Switch } from 'Src/components/Switch/Switch';
import { SchoolFile } from '../../../../../../models/schoolFile';
import { uploadFile, uploadFileAllPath } from '../../../../../../helpers/service/file';
import { FILES_CATEGORY, FILES_CATEGORY_SERVER_TO_CLIENT_MAPPING } from '../../../../../../consts/file';
import { ALLOWED_MIME_TYPES } from '../../../../../../helpers/file/file';

interface Props {
  file: SchoolFile;
  onSubmit: (data) => void;
  onCancel: () => void;
  user: AppUser;
}

const SchoolLeagueSchema = Yup.object().shape({
  title: Yup.string().required('Required')
});

export const SchoolFileForm: React.FunctionComponent<Props> = props => {
  const { file, onSubmit, onCancel, user } = props;

  const [picUrl, setPicUrl] = useState(propz.get(file, ['url'], ''));
  const [isFileOtherError, setIsFileOtherError] = useState(false);
  const [textError, setTextError] = useState('');

  const isFileExist = typeof file !== 'undefined';

  const leagueForm = {
    title: isFileExist ? file.title : '',
    isPublished: isFileExist ? file.isPublished : false,
    category: isFileExist ? file.category : FILES_CATEGORY.CALENDAR
  };

  const onFileSelected = event => {
    const file = event.target.files[0];
    const isAllowedMimeType = ALLOWED_MIME_TYPES.some(type => file.type === type);

    if (isAllowedMimeType) {
      const fileName = file.name;
      const fileNameWithoutSpaces = fileName.split(' ').join('_');
      const formData = new FormData();
      formData.append('file', file, fileNameWithoutSpaces);
      uploadFileAllPath(user, formData)
        .then(response => {
          const { link } = response;
          setPicUrl(link);
          setIsFileOtherError(false);
          setTextError('');
        })
        .catch(error => {
          console.error(error);
          setTextError('Error uploading file');
          setIsFileOtherError(true);
        });
    } else {
      setTextError('The file format is not valid');
      setIsFileOtherError(true);
    }
  };

  const imgInputClassName = isFileOtherError ? 'form-control-file is-invalid' : 'form-control-file';

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik
            initialValues={leagueForm}
            validationSchema={SchoolLeagueSchema}
            onSubmit={values => {
              if (typeof picUrl !== 'undefined' && picUrl !== '' && !isFileOtherError) {
                const { title, isPublished, category } = values;
                const dataToSubmit = {
                  title,
                  isPublished,
                  category,
                  url: picUrl
                };
                onSubmit(dataToSubmit);
              } else {
                setTextError('Required');
                setIsFileOtherError(true);
              }
            }}
            render={({ touched, errors, setFieldValue, values }) => (
              <Form>
                <LabelWithQuestionIcon
                  labelText="Title"
                  hintText={
                    'This is the name that will appear to everyone who enters your challenge, ' +
                    'you can call it something all encompasing for a varied challenge that has multiple ' +
                    "different types of activities e.g 'Summer Holidays mini challenges 2021' or you can " +
                    "call it something more generic for a specific type of activity 'Get those running shoes on'."
                  }
                />
                <Field type="text" name="title" className="form-control mb-3" />
                {touched.title && errors.title ? <div className="alert alert-danger">{errors.title}</div> : null}

                <div className="d-flex">
                  <Field
                    name="isPublished"
                    render={({ field }) => (
                      <Switch
                        {...field}
                        value={values.isPublished}
                        customClass="mb-3"
                        onChange={event => {
                          const isPublished = event.target.checked;
                          setFieldValue('isPublished', isPublished);
                        }}
                      />
                    )}
                  />
                  <LabelWithQuestionIcon
                    labelText="Published"
                    hintText=""
                    labelHtmlFor="isPublished"
                    customLabelClass="form-check-label pl-2"
                  />
                </div>

                <Field
                  component="select"
                  name="category"
                  className="form-control mb-3"
                  onChange={event => {
                    const category = event.target.value;
                    setFieldValue('category', category);
                  }}
                >
                  <option value={FILES_CATEGORY.CALENDAR}>{FILES_CATEGORY_SERVER_TO_CLIENT_MAPPING.CALENDAR}</option>
                  <option value={FILES_CATEGORY.RULES}>{FILES_CATEGORY_SERVER_TO_CLIENT_MAPPING.RULES}</option>
                  <option value={FILES_CATEGORY.RISK_ASSESSMENT}>
                    {FILES_CATEGORY_SERVER_TO_CLIENT_MAPPING.RISK_ASSESSMENT}
                  </option>
                </Field>
                <div className="form-group">
                  <LabelWithQuestionIcon labelText="File" hintText={''} />
                  <input type="file" name="picUrl" className={imgInputClassName} onChange={onFileSelected} />
                  {isFileOtherError && <div className="alert alert-danger">{textError}</div>}
                </div>

                <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                <button type="submit" className="mt-3 mb-3 btn btn-primary">
                  Save
                </button>
              </Form>
            )}
          />
        </div>
      </div>
    </div>
  );
};

SchoolFileForm.displayName = 'SchoolFileForm';
