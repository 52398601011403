import * as React from 'react';
import { FunctionComponent } from 'react';
import { SchoolUser } from '../../models/schoolUser';
import { GENDER_SERVER_TO_CLIENT_MAPPING } from '../../consts/user';
import { getUserRole } from '../../helpers/accessor/accessor';

interface Props {
  staff: SchoolUser;
}

const ROWS = [
  { title: 'Name', field: 'firstName' },
  { title: 'Surname', field: 'lastName' },
  { title: 'Gender', field: 'gender' },
  { title: 'Date of birth', field: 'birthday' },
  { title: 'Email', field: 'email' },
  { title: 'Phone', field: 'phone' },
  { title: 'Roles', field: 'permissions' }
];

export const StaffSummary: FunctionComponent<Props> = props => {
  const rows = ROWS.map((row, index) => {
    let contentCell: string;
    const field = row.field;

    const staff = props.staff;

    switch (field) {
      case 'gender':
        contentCell = GENDER_SERVER_TO_CLIENT_MAPPING[staff.gender];
        break;
      case 'permissions':
        contentCell = getUserRole(staff);
        break;
      default:
        contentCell = staff[row.field];
    }

    return (
      <tr key={`student_summary_${index}`}>
        <td>{row.title}</td>
        <td>{contentCell}</td>
      </tr>
    );
  });

  return (
    <table className={'table table-sm mPre'}>
      <tbody>{rows}</tbody>
    </table>
  );
};

StaffSummary.displayName = 'StaffSummary';
