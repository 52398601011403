import * as React from 'react';

interface Props {
  onClick?: () => void;
}

export const TeamsIcon = (props: Props) => {
  const { onClick } = props;
  const isOnClickExist = typeof onClick !== 'undefined';

  const emptyFunc = () => {};

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 128 128"
      xmlSpace="preserve"
      onClick={isOnClickExist ? onClick : emptyFunc}
    >
      <title>Click here to manage your school teams for this tournament.</title>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m118.982 67.458-10.095-5.112a9.672 9.672 0 0 0 5.031-8.478v-7.932a13.411 13.411 0 1 0-26.821 0v7.932a9.672 9.672 0 0 0 5.031 8.478l-10.094 5.111c-.457.231-.912.5-1.364.79l-6.96-3.524a12.043 12.043 0 0 0 7.186-11v-10.3a16.9 16.9 0 1 0-33.793 0v10.3a12.045 12.045 0 0 0 7.186 11l-6.959 3.524a16.113 16.113 0 0 0-1.363-.789l-10.095-5.112a9.672 9.672 0 0 0 5.028-8.478v-7.932a13.411 13.411 0 1 0-26.821 0v7.932a9.672 9.672 0 0 0 5.031 8.478L9.018 67.458A16.37 16.37 0 0 0 0 82.133v7.158a1.75 1.75 0 0 0 1.75 1.75h27.063v8.689a1.751 1.751 0 0 0 1.75 1.75h66.873a1.75 1.75 0 0 0 1.75-1.75v-8.689h27.064a1.75 1.75 0 0 0 1.75-1.75v-7.158a16.37 16.37 0 0 0-9.018-14.675ZM90.6 45.936a9.911 9.911 0 1 1 19.821 0v7.932a6.172 6.172 0 0 1-6.165 6.165h-7.494a6.172 6.172 0 0 1-6.162-6.165Zm-40 7.783v-10.3a13.4 13.4 0 1 1 26.793 0v10.3a8.541 8.541 0 0 1-8.531 8.531h-9.73a8.542 8.542 0 0 1-8.532-8.531Zm-33.018-7.783a9.911 9.911 0 1 1 19.821 0v7.932a6.172 6.172 0 0 1-6.165 6.165h-7.491a6.172 6.172 0 0 1-6.165-6.165ZM3.5 82.133a12.888 12.888 0 0 1 7.1-11.553l13.916-7.047h5.954l13.084 6.626-3.314 1.678a20.733 20.733 0 0 0-11.228 15.7H3.5ZM95.686 97.98H32.313v-7.547c0-.381.016-.759.04-1.134v-.012a17.253 17.253 0 0 1 9.469-14.328l18.186-9.209h7.984l18.186 9.209a17.253 17.253 0 0 1 9.469 14.328v.013c.024.375.039.753.039 1.134ZM124.5 87.541H98.988a20.733 20.733 0 0 0-11.228-15.7l-3.314-1.678 13.084-6.63h5.954L117.4 70.58a12.888 12.888 0 0 1 7.1 11.553Z"
        fill="#2394be"
        data-original="#000000"
      />
    </svg>
  );
};

TeamsIcon.displayName = 'TeamsIcon';
