import * as React from 'react';
import { Component } from 'react';
import * as classNames from 'classnames';
import './SelectAllCheckbox.css';
import { getFrom, getTo } from '../../helpers/table/table';

interface Props {
  onSelectAllClick: () => void;
  onSelectAllOnPageClick: () => void;
  onUnselectAllClick: () => void;
  isChecked: boolean;
  totalCount: number;
  currentPage: number;
  selectedItems: any[];
  isSelectDisabled?: boolean;
  isNoVisibleCheckbox?: boolean;
}

interface State {
  isChecked: boolean;
  isMenuOpen: boolean;
}

export class SelectAllCheckbox extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isChecked: props.isChecked,
      isMenuOpen: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isChecked !== this.props.isChecked) {
      this.setState({
        isChecked: nextProps.isChecked
      });
    }
  }

  onCheckedChange = () => {
    const { isChecked } = this.state;

    const { isSelectDisabled } = this.props;
    if (isSelectDisabled) {
      return;
    }

    if (isChecked) {
      this.props.onUnselectAllClick();
    } else {
      this.setState(
        {
          isMenuOpen: true,
          isChecked: true
        },
        () => {
          document.addEventListener('click', this.closeMenu);
        }
      );
    }
  };

  closeMenu = () => {
    this.setState(
      {
        isMenuOpen: false,
        isChecked: false
      },
      () => {
        document.removeEventListener('click', this.closeMenu);
      }
    );
  };

  onSelectAllOnPageClick = event => {
    event.preventDefault();

    const { isSelectDisabled } = this.props;
    if (isSelectDisabled) {
      return;
    }

    this.props.onSelectAllOnPageClick();
    this.setState({ isMenuOpen: false });
    document.removeEventListener('click', this.closeMenu);
  };

  onSelectAllClick = event => {
    event.preventDefault();

    const { isSelectDisabled } = this.props;
    if (isSelectDisabled) {
      return;
    }

    this.props.onSelectAllClick();
    this.setState({ isMenuOpen: false });
    document.removeEventListener('click', this.closeMenu);
  };

  renderDropdownList() {
    const { isMenuOpen } = this.state;
    const { totalCount, currentPage, isNoVisibleCheckbox } = this.props;

    const pageCount = totalCount === 0 ? 0 : getTo(totalCount, currentPage) - getFrom(totalCount, currentPage) + 1;

    const listClassNames = classNames({
      'dropdown-menu': true,
      show: isMenuOpen,
      eSelectAllCheckbox: true
    });

    return (
      <div className={listClassNames}>
        {!isNoVisibleCheckbox && (
          <a className="dropdown-item" onClick={this.onSelectAllOnPageClick}>
            {`Select this page (${pageCount})`}
          </a>
        )}
        <a className="dropdown-item" onClick={this.onSelectAllClick}>
          {`Select all (${totalCount})`}
        </a>
      </div>
    );
  }

  render() {
    const timestamp = Number(new Date());
    const { selectedItems } = this.props;
    return (
      <div className="d-inline form-check mr-3 pl-0">
        <input
          type="checkbox"
          checked={this.state.isChecked}
          onChange={this.onCheckedChange}
          id={`selectAllCheckbox_${timestamp}`}
          className="mr-2"
        />
        <label className="form-check-label" htmlFor={`selectAllCheckbox_${timestamp}`}>
          {`Selected: ${selectedItems.length}`}
        </label>
        {this.renderDropdownList()}
      </div>
    );
  }
}
