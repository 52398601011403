import * as React from 'react';
import { FunctionComponent } from 'react';
import { ActionItem, ActionsButton } from '../ActionsButton/ActionsButton';
import { Pagination2 } from '../Pagination/Pagination2';
import { SelectAllCheckbox } from '../SelectAllCheckbox/SelectAllCheckbox';
import { FilterIcon } from '../FilterIcon/FilterIcon';
import { Button } from '../Button/Button';
import { PdfIcon } from '../PdfIcon/PdfIcon';
import { CsvIcon } from '../CsvIcon/CsvIcon';
import { ColumnDefinition } from '../../helpers/table/table';

interface Props {
  actionItems: ActionItem[];
  currentPage: number;
  setCurrentPageParams: (currentPage: number) => void;
  total: number;
  onSelectAllClick: () => void;
  onSelectAllOnPageClick: () => void;
  onUnselectAllClick: () => void;
  isSelectAllChecked: boolean;
  selectedItems: any[];
  onTableFilterClick: (event) => void;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  columns: ColumnDefinition[];
  filters: any;
  renderAdditionalOptions?: () => React.ReactNode;
  goBack?: () => void;
  gridTitle?: string;
  isPDF?: boolean;
  isCSV?: boolean;
  isSelectDisabled: boolean;
}

export const GridPanel: FunctionComponent<Props> = ({
  actionItems,
  currentPage,
  setCurrentPageParams,
  total,
  onSelectAllClick,
  onSelectAllOnPageClick,
  onUnselectAllClick,
  isSelectAllChecked,
  selectedItems,
  onTableFilterClick,
  isShowFilter,
  isDataFiltered,
  renderAdditionalOptions,
  goBack,
  gridTitle,
  isPDF,
  isCSV,
  columns,
  filters,
  isSelectDisabled
}) => {
  const isShowFilters = Object.keys(filters).length > 0;
  const isRenderAdditionalOptionsExist = typeof renderAdditionalOptions !== 'undefined';

  return (
    <div className="container-fluid">
      {gridTitle && goBack && (
        <div className="row">
          <div className="col-md-12 pl-0">
            <Button text={'← back'} onClick={goBack} customClass={'btn-secondary mr-3 mb-3'} />
            <div className="eClubMessagesTitle h3 mb-3">{gridTitle}</div>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-md-6 pl-0 mb-sm-0 mb-3">
          {actionItems.length > 0 && <ActionsButton actionItems={actionItems} />}
        </div>
        <div className="col-md-6 pl-0">
          <Pagination2 currentPage={currentPage} setCurrentPageParams={setCurrentPageParams} total={total} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 pl-0">
          <SelectAllCheckbox
            onSelectAllClick={onSelectAllClick}
            onSelectAllOnPageClick={onSelectAllOnPageClick}
            onUnselectAllClick={onUnselectAllClick}
            isChecked={isSelectAllChecked}
            totalCount={total}
            currentPage={currentPage}
            selectedItems={selectedItems}
            isSelectDisabled={isSelectDisabled}
          />

          <div className="d-flex align-items-center flex-wrap gap-1 mt-2">
            {isShowFilters && (
              <FilterIcon
                onTableFilterClick={onTableFilterClick}
                isFilterShown={isShowFilter}
                isDataFiltered={isDataFiltered}
              />
            )}
            {isPDF && <PdfIcon selectedItems={selectedItems} />}
            {isCSV && <CsvIcon selectedItems={selectedItems} columns={columns} />}
            {isRenderAdditionalOptionsExist && <div>{renderAdditionalOptions()}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

GridPanel.displayName = 'GridPanel';
