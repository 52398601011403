import { AppUser } from 'Src/views/App/App';
import axios from 'axios';

export function createIndividualScore(user: AppUser, eventId: string, data) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/results/individuals/score`, data, config)
    .then(response => {
      return response.data;
    });
}

export function updateIndividualScore(user: AppUser, eventId: string, scoreId: string, data) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .put(
      `${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/results/individuals/score/${scoreId}`,
      data,
      config
    )
    .then(response => {
      return response.data;
    });
}

export function createTeamScore(user: AppUser, eventId: string, data) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/results/teams/score`, data, config)
    .then(response => {
      return response.data;
    });
}

export function updateTeamScore(user: AppUser, eventId: string, scoreId: string, data) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .put(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/results/teams/score/${scoreId}`, data, config)
    .then(response => {
      return response.data;
    });
}

export function createHouseScore(user: AppUser, eventId: string, data) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/results/houses/score`, data, config)
    .then(response => {
      return response.data;
    });
}

export function updateHouseScore(user: AppUser, eventId: string, scoreId: string, data) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .put(
      `${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/results/houses/score/${scoreId}`,
      data,
      config
    )
    .then(response => {
      return response.data;
    });
}

export function createSchoolScore(user: AppUser, eventId: string, data) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/results/schools/score`, data, config)
    .then(response => {
      return response.data;
    });
}

export function updateSchoolScore(user: AppUser, eventId: string, scoreId: string, data) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .put(
      `${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/results/schools/score/${scoreId}`,
      data,
      config
    )
    .then(response => {
      return response.data;
    });
}

export function createCricketResults(user: AppUser, eventId: string, data) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/results/cricket`, data, config)
    .then(response => {
      return response.data;
    });
}
