import * as React from 'react';
import * as propz from 'propz';
import { History } from 'history';
import { AppUser } from 'Src/views/App/App';
import { Permission } from '../../../../models/permission';
import { PROFILE_TABS_URLS } from '../../../../consts/profile';
import { DEFAULT_LIMIT } from '../../../../consts/table';
import { getPermissions, deletePermission } from 'Src/helpers/service/admin/user';
import { SimpleModal } from '../../../../components/SimpleModal/SimpleModal';
import { Loader } from '../../../../components/Loader/Loader';
import './RolesTab.scss';
import { ROLE } from '../../../../consts/user';

interface Props {
  user: AppUser;
  history: History;
  updateIsRolesExist?: (boolean) => void;
}

interface State {
  permissions: Permission[];
  permissionToDelete: Permission;
  isLoading: boolean;
  isConfirmationModalOpen: boolean;
  permissionToDetailsView: Permission;
  isConfirmationModalDetailsViewOpen: boolean;
  isRemoveErrorModalOpen: boolean;
}

export class RolesTab extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      permissions: [],
      permissionToDelete: undefined,
      isLoading: false,
      isConfirmationModalOpen: false,
      permissionToDetailsView: undefined,
      isConfirmationModalDetailsViewOpen: false,
      isRemoveErrorModalOpen: false
    };
  }

  componentDidMount() {
    const { user } = this.props;
    this.setState({
      isLoading: true
    });

    const filter = {
      limit: DEFAULT_LIMIT,
      skip: 0
    };

    getPermissions(user, filter).then(permissions => {
      this.setState({
        permissions,
        isLoading: false
      });
    });
  }

  onDetailsViewClick = (permission: Permission): void => {
    this.setState({
      permissionToDetailsView: permission,
      isConfirmationModalDetailsViewOpen: true
    });
  };

  onOkConfirmationModalDetailsViewClick = () => {
    this.setState({
      isConfirmationModalDetailsViewOpen: false
    });
  };

  renderConfirmationModalDetailsView = () => {
    const { isConfirmationModalDetailsViewOpen, permissionToDetailsView } = this.state;
    const sports = propz.get(permissionToDetailsView, ['sports'], []);
    const schoolName = propz.get(permissionToDetailsView, ['school', 'name'], '');

    const textSports = sports.map(sport => sport.name).join(', ');

    const textSchool = `${schoolName}\n${textSports}`;

    return (
      <SimpleModal
        isOpen={isConfirmationModalDetailsViewOpen}
        title={'Allowed activities/sports'}
        body={textSchool}
        buttonText={'Ok'}
        onButtonClick={this.onOkConfirmationModalDetailsViewClick}
      />
    );
  };

  onRevokeClick = (permission: Permission): void => {
    const isStudentRole = permission.preset === ROLE.STUDENT;

    if (isStudentRole) {
      this.setState({
        isRemoveErrorModalOpen: true
      });
    } else {
      this.setState({
        permissionToDelete: permission,
        isConfirmationModalOpen: true
      });
    }
  };

  onOkConfirmationModalClick = (): void => {
    const { user } = this.props;
    const { permissionToDelete } = this.state;
    this.setState({
      isLoading: true
    });

    deletePermission(user, permissionToDelete.id).then(() => {
      const { permissions } = this.state;
      const permissionsUpdated = permissions.filter(permission => permission.id !== permissionToDelete.id);
      this.setState({
        isLoading: false,
        permissions: permissionsUpdated,
        isConfirmationModalOpen: false
      });
      if (permissionsUpdated.length === 0) {
        const { updateIsRolesExist } = this.props;
        updateIsRolesExist(false);
      }
    });
  };

  onCancelConfirmationModalClick = (): void => {
    this.setState({
      permissionToDelete: undefined,
      isConfirmationModalOpen: false
    });
  };

  renderConfirmationModal(): React.ReactNode {
    const { isConfirmationModalOpen, permissionToDelete } = this.state;
    const role = propz.get(permissionToDelete, ['preset'], '');

    return (
      <SimpleModal
        isOpen={isConfirmationModalOpen}
        title={'Confirmation'}
        body={`Are you sure you want revoke role ${role}?`}
        buttonText={'Ok'}
        onButtonClick={this.onOkConfirmationModalClick}
        buttonCancelText={'Cancel'}
        onCloseClick={this.onCancelConfirmationModalClick}
        customClass={'eVerificationTabModal'}
      />
    );
  }

  onRequestsTabClick = (): void => {
    this.props.history.push(PROFILE_TABS_URLS.REQUESTS);
  };

  renderRemoveErrorModal(): React.ReactNode {
    const { isRemoveErrorModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isRemoveErrorModalOpen}
        title={'Error'}
        body={`You can't delete a student role.`}
        buttonCancelText={'Close'}
        onCloseClick={this.closeRemoveErrorModal}
        customClass={'eVerificationTabModal'}
      />
    );
  }

  closeRemoveErrorModal = () => {
    this.setState({
      isRemoveErrorModalOpen: false
    });
  };

  render() {
    const { permissions, isLoading } = this.state;

    const isPermissionsExist = permissions.length > 0;

    if (isLoading) {
      return <Loader />;
    }

    return (
      <div className="ml-3">
        {this.renderConfirmationModal()}
        {this.renderConfirmationModalDetailsView()}
        {this.renderRemoveErrorModal()}
        <div className="mt-3 ml-2">
          <div>
            Within this tab you can see the roles that you have across the platform and revoke any you no longer need /
            want.
          </div>
          <div>
            {' '}
            Please note if you revoke a role accidentally, you will need to send a new role request, you can do this via
            the Requests tab.
          </div>
          <div>
            {' '}
            To switch between the roles you can do so in the top right corner of the page via the drop down arrow.
          </div>
          <div>
            If you cannot see a role here then it means it is either yet to be approved by your school, or you have not
            sent a role request.
          </div>
          <div>Check your requests tab to view your role request status.</div>
        </div>
        <div className="row">
          <div className="col-xl-6">
            <div className={'mt-3 mb-3'}>
              {!isPermissionsExist ? (
                <div className="ml-2">
                  You don't have any roles right now. Please request one via the
                  <span className="eRolesTabLink" onClick={this.onRequestsTabClick}>
                    {' '}
                    Requests tab
                  </span>
                </div>
              ) : (
                <table className={'table table-sm'}>
                  <thead>
                    <tr>
                      <th>School</th>
                      <th>Role</th>
                      <th>Comment</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {permissions.map((permission, index) => {
                      const { school, comment, preset, id } = permission;
                      const isPresetCoach = preset === ROLE.COACH;
                      const isPresetTeacher = preset === ROLE.TEACHER;

                      return (
                        <tr key={`profile_roles_tab_${index}`}>
                          <td>{school.name}</td>
                          <td>{preset}</td>
                          <td>{comment}</td>
                          <td className="eRolesTabLink">
                            {(isPresetCoach || isPresetTeacher) && (
                              <div onClick={() => this.onDetailsViewClick(permission)}>View Details</div>
                            )}
                            <div onClick={() => this.onRevokeClick(permission)}>Revoke</div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
