import axios from 'axios';
import { AppUser } from 'Src/views/App/App';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from 'Src/consts/table';
import { Count } from '../../../models/count';
import { Team } from '../../../models/team';

export function getSchoolTeams(user: AppUser, filter): Promise<Team[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...filter.where,
          removed: false
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order
      }
    }
  };

  return axios.get<Team[]>(`${window.apiBase}/i/schools/${activeSchoolId}/teams`, config).then(response => {
    return response.data;
  });
}

export function getAllSchoolTeams(user: AppUser, where?): Promise<Team[]> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where,
            removed: false
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios.get<Team[]>(`${window.apiBase}/i/schools/${activeSchoolId}/teams`, config).then(response => {
    return response.data;
  });
}

export function getSchoolTeamsCount(user: AppUser, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where,
            removed: false
          }
        }
      }
    };
  } else {
    config = {
      headers: { usid: key }
    };
  }

  return axios.get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/teams/count`, config).then(response => {
    return response.data;
  });
}

export function getSchoolTeam(user: AppUser, teamId: string): Promise<Team> {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios.get<Team>(`${window.apiBase}/i/schools/${activeSchoolId}/teams/${teamId}`, config).then(response => {
    return response.data;
  });
}

export function createSchoolTeam(user: AppUser, team: any) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios.post(`${window.apiBase}/i/schools/${activeSchoolId}/teams`, team, config).then(response => {
    return response.data;
  });
}

export function updateSchoolTeam(user: AppUser, teamId: string, team: any): Promise<Team> {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .put<Team>(`${window.apiBase}/i/schools/${activeSchoolId}/teams/${teamId}`, team, config)
    .then(response => {
      return response.data;
    });
}

export function checkAvailabilityTeamName(user: AppUser, data) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };
  return axios.post(`${window.apiBase}/i/schools/${activeSchoolId}/teams/check`, data, config).then(response => {
    return response.data;
  });
}

export function deleteTeam(user: AppUser, teamId: string){
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.delete(`${window.apiBase}/i/schools/${activeSchoolId}/teams/${teamId}`, config)
      .then(response => {
    return response.data;
  });
}