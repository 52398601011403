import * as React from 'react';
import { FunctionComponent } from 'react';
import { getFullName } from 'Src/helpers/autocomplete/autocomplete';

interface Props {
  eventTasks: any[];
  childrenInEventsIds: string[];
}

export const ChildrenEventJobsSummaryTab: FunctionComponent<Props> = props => {
  const { eventTasks, childrenInEventsIds }: any = props;

  const rows = eventTasks.map((eventTask, index) => {
    const { assignees } = eventTask;

    const assigneesAmongСhildren: any = assignees.filter(assignee => {
      const { userId } = assignee;
      return childrenInEventsIds.includes(userId);
    });

    let childFullName = '';

    if (assigneesAmongСhildren.length > 1) {
      assigneesAmongСhildren.forEach((assignee, index) => {
        const lastIndex = assigneesAmongСhildren.length - 1;
        index === lastIndex
          ? (childFullName += getFullName(assignee))
          : (childFullName += `${getFullName(assignee)}, `);
      });
    } else {
      childFullName += getFullName(assigneesAmongСhildren[0]);
    }

    return (
      <tr key={`jobs_summary_${index}`}>
        <td>{index + 1}</td>
        <td className="">{childFullName}</td>
        <td className="">{eventTask.text}</td>
      </tr>
    );
  });

  const emptyRow = (
    <tr key={'events_empty_row'}>
      <td>No jobs</td>
    </tr>
  );

  return (
    <table className={'table table-sm'}>
      <thead>
        <tr>
          <th>#</th>
          <th>Players</th>
          <th>Job description</th>
        </tr>
      </thead>
      <tbody>{rows.length > 0 ? rows : emptyRow}</tbody>
    </table>
  );
};

ChildrenEventJobsSummaryTab.displayName = 'ChildrenEventJobsSummaryTab';
