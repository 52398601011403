import * as Cookies from 'js-cookie';

const sessionStorage = window.sessionStorage;
const localStorage = window.localStorage;

interface Helper {
  LocalStorage: {
    set: (key: string, value) => boolean
    get: (key: string) => any
    remove: (key: string) => any
    clear: () => void
  }
  SessionStorage: {
    set: (key: string, value) => boolean
    get: (key: string) => any
    remove: (key: string) => any
    clear: () => void
  }
  Cookie: {
    set: (key: string, value, options?) => boolean
    get: (key: string) => any
    remove: (key: string, options?) => any
  }
}

const StorageHelper: Helper = {
  LocalStorage: {
    set: function (key, value) {
      value = typeof value === 'string' ? String(value) : JSON.stringify(value);
    
      try {
        localStorage.setItem(key, value);
        return true;
      } catch (error_text) {
        console.error('Out of local store');
        return false;
      }
    },
    get: function (key) {
      const value = localStorage.getItem(key);
    
      if (value !== null) {
        return (value.indexOf('{') !== -1 || value.indexOf('[') !== -1 ? JSON.parse(value) : value);
      } else {
        return undefined;
      }
    },
    remove: function (key) {
      return localStorage.removeItem(key);
    },
    clear: function () {
      localStorage.clear();
    }
  },
  SessionStorage: {
    set: function (key, value) {
      value = typeof value === 'string' ? String(value) : JSON.stringify(value);
    
      try {
        sessionStorage.setItem(key, value);
        return true;
      } catch (error_text) {
        console.error('Out of session store');
        return false;
      }
    },
    get: function (key) {
      const value = sessionStorage.getItem(key);
    
      if (value !== null) {
        return (value.indexOf('{') !== -1 || value.indexOf('[') !== -1 ? JSON.parse(value) : value);
      } else {
        return undefined;
      }
    },
    remove: function (key) {
      return sessionStorage.removeItem(key);
    },
    clear: function () {
      sessionStorage.clear();
    }
  },
  Cookie: {
    get: function (key) {
      let value = Cookies.get(key);
    
      // Cookies method set use JSON.stringify
      // JSON.stringify for undefined is 'undefined'
      // So convert it manually
      if (value === "undefined" || typeof value === 'undefined') {
        value = undefined;
        // For other cases parse value
      } else {
        try {
          value = JSON.parse(value);
        } catch (err) {
          console.error('Not valid data in cookie.');
          console.error(value);
        
          this.remove(key);
          value = undefined;
        }
      }
    
      return value;
    },
    set: function (key, value, options) {
      if (typeof options !== 'undefined') {
        Cookies.set(key, value, options);
      } else {
        Cookies.set(key, value);
      }
    
      return true;
    },
    remove: function (key, options?) {
      return Cookies.remove(key, options);
    }
  }
};

export default StorageHelper;
