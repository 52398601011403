import * as React from 'react';
import * as propz from 'propz';
import * as classNames from 'classnames';
import { Participant, SchoolEvent, SchoolEventCricketResult } from '../../../../../../../models/event';

import {
  getCricketRunsMask,
  getCricketWicketsMask,
  getIndividualScoreByStudent,
  getIsErrorRunsHashMapFromCricketDataHashMap,
  getIsErrorWicketsHashMapFromCricketDataHashMap,
  getPlayerScoreId,
  getSchoolScoreBySchoolId,
  getSchoolScoreId,
  getTeamScoreByTeamId,
  getTeamScoreId
} from '../../../../../../../helpers/event/schoolEventViewHelper';

import * as TextMaskCore from 'text-mask-core';

import { Checkbox } from '../../../../../../../components/Checkbox/Checkbox';
import { Button } from '../../../../../../../components/Button/Button';
import { ARROW_DOWN_KEY, ARROW_UP_KEY, ENTER_KEY } from '../../../../../../../consts/common';
import { EMPTY_STRING } from '../../../../../../../consts/date';
import { CricketTextResultSelect } from './CricketTextResultSelect';
import {
  CRICKET_RESULT,
  RESULTS_FOR_CRICKET_FOR_SELECT_DRAW_OPTION,
  RESULTS_FOR_CRICKET_FOR_SELECT_NO_RESULT_OPTION,
  RESULTS_FOR_CRICKET_FOR_SELECT_TBD_OPTION,
  RESULTS_FOR_CRICKET_FOR_SELECT_TIE_OPTION,
  SelectOptionCricket
} from '../../../../../../../consts/cricket';
import { CRICKET_WICKETS } from '../../../../../../../consts/sport';
import { CRICKET_RESULT_TYPE } from '../../../../../../../types/sport';
import { convertPointsCricket } from '../../../../../../../helpers/sport/cricket';
import { getInterSchoolsTeamEventParticipants, isMultipartyEvent } from '../../../../../../../helpers/event/event';
import { AppUser } from '../../../../../../App/App';

interface Props {
  event: SchoolEvent;
  onSaveClick: (data, cricketResult: SchoolEventCricketResult) => void;
  onCloseClick: () => void;
  user: AppUser;
}

interface State {
  dataHashMap: CricketDataHashMap;
  players: Player[];
  teams: Team[];
  schools: School[];
  continuousCount: number;
  textResults: SelectOptionCricket[];
  cricketResultSelected: string;
  participants: Participant[];
}

export interface CricketDataHashMap {
  [key: string]: {
    runsValue: string;
    runsIsError: boolean;
    runsOldValue: string;
    wicketsValue: string;
    wicketsIsError: boolean;
    wicketsOldValue: string;
    continuousCount: number;
    isInputEnabled: boolean;
    //only for team
    playerIds?: string[];
    teamRuns?: number;
    teamWickets?: number;
    //only for player
    teamId?: string;
  };
}

interface Player {
  id: string;
  userId: string;
  permissionId: string;
  teamId: string;
  continuousCount: number;
}

interface Team {
  id: string;
  continuousCount: number;
  schoolId: string;
}

interface School {
  id: string;
  name: string;
  continuousCount: number;
}

export class CricketExternalSchoolsResults extends React.Component<Props, State> {
  runsInputElement: any[];
  runsTextMaskInputElement: any[];

  wicketsInputElement: any[];
  wicketsTextMaskInputElement: any[];

  constructor(props: Props) {
    super(props);

    this.runsInputElement = [];
    this.runsTextMaskInputElement = [];

    this.wicketsInputElement = [];
    this.wicketsTextMaskInputElement = [];

    const { event, user } = props;
    const { results, teamsData } = event;
    const { cricketResult } = results;
    const cricketResultSelected = propz.get(cricketResult, ['result'], CRICKET_RESULT.TBD);
    const cricketResultSelectedWho = propz.get(cricketResult, ['who']);
    let players = [];
    let teams = [];
    let schools = [];

    let continuousCount = 0;
    let dataHashMap: CricketDataHashMap = {};

    const participants = getInterSchoolsTeamEventParticipants(event, user);

    participants.forEach(participant => {
      const { team, school } = participant;
      const isTeamExist = typeof team !== 'undefined';

      if (!isTeamExist) {
        continuousCount++;
        const schoolPoints = getSchoolScoreBySchoolId(event, school.id);
        const { runs: schoolRuns, wickets: schoolWickets } = convertPointsCricket(schoolPoints);

        dataHashMap[school.id] = {
          runsValue: schoolRuns === 0 ? '' : String(schoolRuns),
          runsOldValue: schoolRuns === 0 ? '' : String(schoolRuns),
          runsIsError: false,
          wicketsValue: schoolWickets === 0 ? '' : String(schoolWickets),
          wicketsOldValue: schoolWickets === 0 ? '' : String(schoolWickets),
          wicketsIsError: false,
          teamRuns: schoolRuns,
          teamWickets: schoolWickets,
          continuousCount: continuousCount,
          isInputEnabled: true
        };

        schools.push({ id: school.id, continuousCount, name: school.name });
      } else {
        const teamId = team.id;
        const teamPoints = getTeamScoreByTeamId(event, teamId);
        const { runs: teamRuns, wickets: teamWickets } = convertPointsCricket(teamPoints);

        const isTeamIndividualPlayersScoreExists = event.results.individualScore
          .filter(scoreData => scoreData.teamId === teamId)
          .some(scoreData => scoreData.score !== 0);

        continuousCount++;

        dataHashMap[teamId] = {
          runsValue: teamRuns === 0 ? '' : String(teamRuns),
          runsOldValue: teamRuns === 0 ? '' : String(teamRuns),
          runsIsError: false,
          wicketsValue: teamWickets === 0 ? '' : String(teamWickets),
          wicketsOldValue: teamWickets === 0 ? '' : String(teamWickets),
          wicketsIsError: false,
          continuousCount: continuousCount,
          playerIds: team.players.map(player => player.id),
          isInputEnabled: !isTeamIndividualPlayersScoreExists,
          teamRuns: teamRuns,
          teamWickets: teamWickets
        };

        teams.push({ id: teamId, continuousCount, schoolId: school.id });

        team.players.forEach(({ id, userId, permissionId }) => {
          const playerPoints = getIndividualScoreByStudent(event, userId, permissionId);
          const { runs: runsValue, wickets: wicketsValue } = convertPointsCricket(playerPoints);
          continuousCount++;

          dataHashMap[id] = {
            runsValue: runsValue === 0 ? '' : String(runsValue),
            runsOldValue: runsValue === 0 ? '' : String(runsValue),
            runsIsError: false,
            wicketsValue: wicketsValue === 0 ? '' : String(wicketsValue),
            wicketsOldValue: wicketsValue === 0 ? '' : String(wicketsValue),
            wicketsIsError: false,
            continuousCount: continuousCount,
            isInputEnabled: isTeamIndividualPlayersScoreExists,
            teamId: teamId
          };

          players.push({ id, userId, permissionId, continuousCount, teamId });
        });
      }
    });

    this.state = {
      dataHashMap: dataHashMap,
      players: players,
      teams: teams,
      schools: schools,
      continuousCount: continuousCount,
      textResults: [],
      participants,
      cricketResultSelected: cricketResultSelectedWho
        ? `${cricketResultSelected}/${cricketResultSelectedWho}`
        : cricketResultSelected
    };
  }

  componentDidMount() {
    this.initTextMask();
  }

  initTextMask() {
    const { dataHashMap, continuousCount, players, teams, schools } = this.state;
    const { event } = this.props;

    for (let i = 1; i <= continuousCount; i++) {
      const playersIndex = players.findIndex(player => player.continuousCount === i);
      const teamsIndex = teams.findIndex(team => team.continuousCount === i);
      const schoolsIndex = schools.findIndex(school => school.continuousCount === i);

      const isPlayer = playersIndex !== -1;
      const isTeam = teamsIndex !== -1;
      const isSchool = schoolsIndex !== -1;

      let id;

      switch (true) {
        case isPlayer:
          id = players[playersIndex].id;
          break;
        case isTeam:
          id = teams[teamsIndex].id;
          break;
        case isSchool:
          id = schools[schoolsIndex].id;
          break;
      }

      const { runsValue, wicketsValue } = dataHashMap[id];

      this.runsTextMaskInputElement[i] = TextMaskCore.createTextMaskInputElement({
        inputElement: this.runsInputElement[i],
        mask: inputValue => getCricketRunsMask(event, inputValue),
        showMask: false
      });

      this.runsTextMaskInputElement[i].update(runsValue);

      this.wicketsTextMaskInputElement[i] = TextMaskCore.createTextMaskInputElement({
        inputElement: this.wicketsInputElement[i],
        mask: inputValue => getCricketWicketsMask(event, inputValue, isPlayer),
        showMask: false
      });

      this.wicketsTextMaskInputElement[i].update(wicketsValue);
    }
  }

  onSubmit = () => {
    const { dataHashMap, players, teams, cricketResultSelected, schools } = this.state;
    const { event, onSaveClick } = this.props;

    const isErrorRunsHashMap = getIsErrorRunsHashMapFromCricketDataHashMap(
      event,
      dataHashMap,
      players,
      [],
      teams,
      schools
    );
    const isErrorRunsPlayers = players.some(player => isErrorRunsHashMap[player.id] === true);
    const isErrorRunsTeams = teams.some(team => isErrorRunsHashMap[team.id] === true);

    const isErrorWicketsHashMap = getIsErrorWicketsHashMapFromCricketDataHashMap(
      event,
      dataHashMap,
      players,
      [],
      teams,
      schools
    );
    const isErrorWicketsPlayers = players.some(player => isErrorWicketsHashMap[player.id] === true);
    const isErrorWicketsTeams = teams.some(team => isErrorWicketsHashMap[team.id] === true);

    if (isErrorRunsPlayers || isErrorRunsTeams || isErrorWicketsPlayers || isErrorWicketsTeams) {
      let dataHashMapUpdated: CricketDataHashMap = {};

      for (let dataId in dataHashMap) {
        dataHashMapUpdated[dataId] = {
          ...dataHashMap[dataId],
          runsIsError: isErrorRunsHashMap[dataId],
          wicketsIsError: isErrorWicketsHashMap[dataId]
        };
      }

      this.setState({
        dataHashMap: dataHashMapUpdated
      });
      return;
    }

    const playerScores = players.map(({ userId, permissionId, id, teamId }) => {
      const runs = dataHashMap[id].runsValue;
      const wickets = dataHashMap[id].wicketsValue;
      const wicketsFormatted = Number(wickets) < 10 ? `0${wickets}` : wickets;
      return {
        userId: userId,
        permissionId: permissionId,
        teamId: teamId,
        score: Number(`${runs}.${wicketsFormatted}`),
        scoreId: getPlayerScoreId(event, userId, permissionId)
      };
    });

    const teamScores = teams.map(({ id }) => {
      const { isInputEnabled, teamRuns, teamWickets } = dataHashMap[id];
      const runs = dataHashMap[id].runsValue;
      const wickets = dataHashMap[id].wicketsValue;
      const wicketsFormatted = Number(wickets) < 10 ? `0${wickets}` : wickets;
      const teamWicketsFormatted = teamWickets < 10 ? `0${teamWickets}` : teamWickets;

      return {
        teamId: id,
        score: isInputEnabled ? Number(`${runs}.${wicketsFormatted}`) : Number(`${teamRuns}.${teamWicketsFormatted}`),
        scoreId: getTeamScoreId(event, id)
      };
    });

    const schoolScores = schools.map(({ id }) => {
      const { isInputEnabled, teamRuns, teamWickets } = dataHashMap[id];
      const runs = dataHashMap[id].runsValue;
      const wickets = dataHashMap[id].wicketsValue;
      const wicketsFormatted = Number(wickets) < 10 ? `0${wickets}` : wickets;
      const teamWicketsFormatted = teamWickets < 10 ? `0${teamWickets}` : teamWickets;

      return {
        schoolId: id,
        score: isInputEnabled ? Number(`${runs}.${wicketsFormatted}`) : Number(`${teamRuns}.${teamWicketsFormatted}`),
        scoreId: getSchoolScoreId(event, id)
      };
    });

    const allScores = [...playerScores, ...teamScores, ...schoolScores];
    let cricketResult: SchoolEventCricketResult;

    if (cricketResultSelected.indexOf('/') !== -1) {
      const resultArray = cricketResultSelected.split('/');
      const [result, who] = resultArray;
      cricketResult = {
        who: who,
        result: result as CRICKET_RESULT_TYPE
      };
    } else {
      cricketResult = {
        result: cricketResultSelected as CRICKET_RESULT_TYPE
      };
    }

    onSaveClick(allScores, cricketResult);
  };

  onRunsChange(eventDescriptor: React.ChangeEvent<HTMLInputElement>, index: number, id: string) {
    const { dataHashMap } = this.state;
    const value = eventDescriptor.target.value;

    let dataHashMapUpdated: CricketDataHashMap = { ...dataHashMap };

    for (let dataId in dataHashMap) {
      if (dataId === id) {
        dataHashMapUpdated[dataId] = {
          ...dataHashMap[dataId],
          runsIsError: false,
          runsValue: value
        };
      }
    }

    this.setState({
      dataHashMap: dataHashMapUpdated
    });

    this.runsTextMaskInputElement[index].update(value);
  }

  onRunsKeyDown = (eventDescriptor, index: number) => {
    const { continuousCount } = this.state;

    const isKeyEnter = eventDescriptor.key === ENTER_KEY;
    const isKeyArrowUp = eventDescriptor.key === ARROW_UP_KEY;
    const isKeyArrowDown = eventDescriptor.key === ARROW_DOWN_KEY;
    const isLastInput = index === continuousCount;
    const isFirstInput = index === 1;

    switch (true) {
      case isKeyEnter && !isLastInput:
      case isKeyArrowDown && !isLastInput:
        this.runsInputElement[index + 1].focus();
        break;
      case isKeyArrowUp && !isFirstInput:
        this.runsInputElement[index - 1].focus();
        break;
    }
  };

  onRunsFocus(e, index: number, id: string) {
    const { dataHashMap } = this.state;
    const value = dataHashMap[id].runsValue;

    let dataHashMapUpdated: CricketDataHashMap = {};

    for (let dataId in dataHashMap) {
      if (dataId === id) {
        dataHashMapUpdated[dataId] = {
          ...dataHashMap[dataId],
          runsOldValue: value
        };
      } else {
        dataHashMapUpdated[dataId] = {
          ...dataHashMap[dataId]
        };
      }
    }

    this.setState({
      dataHashMap: dataHashMapUpdated
    });

    this.runsTextMaskInputElement[index].update('');

    e.stopPropagation();
  }

  onRunsBlur(e, index: number, id: string) {
    const eventValue = e.target.value;
    const { dataHashMap } = this.state;
    const { event } = this.props;

    let dataHashMapUpdated: CricketDataHashMap = { ...dataHashMap };

    if (eventValue === EMPTY_STRING) {
      for (let dataId in dataHashMap) {
        if (dataId === id) {
          dataHashMapUpdated[id] = {
            ...dataHashMap[id],
            runsValue: dataHashMap[id].runsOldValue
          };
        } else {
          dataHashMapUpdated[dataId] = {
            ...dataHashMap[dataId]
          };
        }
      }
      this.setState({
        dataHashMap: dataHashMapUpdated
      });
      this.runsTextMaskInputElement[index].update(dataHashMap[id].runsOldValue);
    } else {
      for (let schoolId in dataHashMap) {
        if (schoolId === id) {
          dataHashMapUpdated[id] = {
            ...dataHashMap[id],
            runsValue: eventValue
          };

          const isTeamIdExist = typeof dataHashMap[schoolId].teamId !== 'undefined';
          if (isTeamIdExist) {
            const teamId = dataHashMap[schoolId].teamId;
            const { playerIds } = dataHashMap[teamId];

            let playersValue = 0;

            playerIds.forEach(playerId => {
              const playerValue = dataHashMapUpdated[playerId].runsValue;
              const points = Number(playerValue);
              playersValue += isNaN(points) ? 0 : points;
            });

            const pointsStr = String(playersValue);

            const teamContinuousCount = dataHashMapUpdated[teamId].continuousCount;
            dataHashMapUpdated[teamId] = {
              ...dataHashMap[teamId],
              runsIsError: false,
              runsValue: pointsStr,
              teamRuns: playersValue
            };

            this.runsTextMaskInputElement[teamContinuousCount].update(pointsStr);
          }
        } else {
          dataHashMapUpdated[schoolId] = {
            ...dataHashMap[schoolId]
          };
        }
      }

      this.setState({
        dataHashMap: dataHashMapUpdated
      });

      this.runsTextMaskInputElement[index].update(eventValue);
    }

    e.stopPropagation();
  }

  onWicketsChange(eventDescriptor: React.ChangeEvent<HTMLInputElement>, index: number, id: string) {
    const { dataHashMap } = this.state;
    const value = eventDescriptor.target.value;

    let dataHashMapUpdated: CricketDataHashMap = { ...dataHashMap };

    for (let dataId in dataHashMap) {
      if (dataId === id) {
        dataHashMapUpdated[dataId] = {
          ...dataHashMap[dataId],
          wicketsIsError: false,
          wicketsValue: value
        };
      }
    }

    this.setState({
      dataHashMap: dataHashMapUpdated
    });

    this.wicketsTextMaskInputElement[index].update(value);
  }

  onWicketsKeyDown = (eventDescriptor, index: number) => {
    const { continuousCount } = this.state;

    const isKeyEnter = eventDescriptor.key === ENTER_KEY;
    const isKeyArrowUp = eventDescriptor.key === ARROW_UP_KEY;
    const isKeyArrowDown = eventDescriptor.key === ARROW_DOWN_KEY;
    const isLastInput = index === continuousCount;
    const isFirstInput = index === 1;

    switch (true) {
      case isKeyEnter && !isLastInput:
      case isKeyArrowDown && !isLastInput:
        this.wicketsInputElement[index + 1].focus();
        break;
      case isKeyArrowUp && !isFirstInput:
        this.wicketsInputElement[index - 1].focus();
        break;
    }
  };

  onWicketsFocus(e, index: number, id: string) {
    const { dataHashMap } = this.state;
    const value = dataHashMap[id].wicketsValue;

    let dataHashMapUpdated: CricketDataHashMap = {};

    for (let dataId in dataHashMap) {
      if (dataId === id) {
        dataHashMapUpdated[dataId] = {
          ...dataHashMap[dataId],
          wicketsOldValue: value
        };
      } else {
        dataHashMapUpdated[dataId] = {
          ...dataHashMap[dataId]
        };
      }
    }

    this.setState({
      dataHashMap: dataHashMapUpdated
    });

    this.wicketsTextMaskInputElement[index].update('');

    e.stopPropagation();
  }

  onWicketsBlur(e, index: number, id: string) {
    const eventValue = e.target.value;
    const { dataHashMap } = this.state;

    let dataHashMapUpdated: CricketDataHashMap = { ...dataHashMap };

    if (eventValue === EMPTY_STRING) {
      for (let dataId in dataHashMap) {
        if (dataId === id) {
          dataHashMapUpdated[id] = {
            ...dataHashMap[id],
            wicketsValue: dataHashMap[id].wicketsOldValue
          };
        } else {
          dataHashMapUpdated[dataId] = {
            ...dataHashMap[dataId]
          };
        }
      }
      this.setState({
        dataHashMap: dataHashMapUpdated
      });
      this.wicketsTextMaskInputElement[index].update(dataHashMap[id].wicketsOldValue);
    } else {
      for (let schoolId in dataHashMap) {
        if (schoolId === id) {
          dataHashMapUpdated[id] = {
            ...dataHashMap[id],
            wicketsValue: eventValue
          };

          const isTeamIdExist = typeof dataHashMap[schoolId].teamId !== 'undefined';
          if (isTeamIdExist) {
            const teamId = dataHashMap[schoolId].teamId;
            const { playerIds } = dataHashMap[teamId];

            let playersValue = 0;

            playerIds.forEach(playerId => {
              const playerValue = dataHashMapUpdated[playerId].wicketsValue;
              const points = Number(playerValue);
              playersValue += isNaN(points) ? 0 : points;
            });

            const pointsStr = String(playersValue);

            const teamContinuousCount = dataHashMapUpdated[teamId].continuousCount;
            dataHashMapUpdated[teamId] = {
              ...dataHashMap[teamId],
              wicketsIsError: false,
              wicketsValue: pointsStr,
              teamWickets: playersValue
            };

            this.wicketsTextMaskInputElement[teamContinuousCount].update(pointsStr);
          }
        } else {
          dataHashMapUpdated[schoolId] = {
            ...dataHashMap[schoolId]
          };
        }
      }

      this.setState({
        dataHashMap: dataHashMapUpdated
      });

      this.wicketsTextMaskInputElement[index].update(eventValue);
    }

    e.stopPropagation();
  }

  renderRunsInput(index: number, id: string): React.ReactNode {
    const { dataHashMap } = this.state;
    return (
      <input
        ref={el => (this.runsInputElement[index] = el)}
        onKeyDown={event => this.onRunsKeyDown(event, index)}
        onChange={event => this.onRunsChange(event, index, id)}
        onFocus={event => this.onRunsFocus(event, index, id)}
        onBlur={event => this.onRunsBlur(event, index, id)}
        className={
          dataHashMap[id].runsIsError === true ? 'eTeamInternalResultsInput mError' : 'eTeamInternalResultsInput'
        }
        disabled={!dataHashMap[id].isInputEnabled}
      />
    );
  }

  renderWicketsInput(index: number, id: string): React.ReactNode {
    const { dataHashMap } = this.state;
    return (
      <input
        ref={el => (this.wicketsInputElement[index] = el)}
        onKeyDown={event => this.onWicketsKeyDown(event, index)}
        onChange={event => this.onWicketsChange(event, index, id)}
        onFocus={event => this.onWicketsFocus(event, index, id)}
        onBlur={event => this.onWicketsBlur(event, index, id)}
        className={
          dataHashMap[id].wicketsIsError === true ? 'eTeamInternalResultsInput mError' : 'eTeamInternalResultsInput'
        }
        disabled={!dataHashMap[id].isInputEnabled}
      />
    );
  }

  onIndividualResultsChange = (teamId: string): void => {
    const { dataHashMap } = this.state;

    let dataHashMapUpdated: CricketDataHashMap = { ...dataHashMap };

    const team = dataHashMap[teamId];

    if (team.isInputEnabled) {
      dataHashMapUpdated[teamId] = {
        ...team,
        runsOldValue: team.runsValue,
        runsValue: '',
        wicketsOldValue: team.wicketsValue,
        wicketsValue: '',
        isInputEnabled: !team.isInputEnabled,
        teamRuns: 0,
        teamWickets: 0
      };
      dataHashMapUpdated[teamId].playerIds.forEach(playerId => {
        const player = dataHashMap[playerId];
        dataHashMapUpdated[playerId] = {
          ...player,
          runsValue: '',
          wicketsValue: '',
          isInputEnabled: !player.isInputEnabled
        };
        this.runsTextMaskInputElement[player.continuousCount].update('');
        this.wicketsTextMaskInputElement[player.continuousCount].update('');
      });

      this.runsTextMaskInputElement[team.continuousCount].update('');
      this.wicketsTextMaskInputElement[team.continuousCount].update('');
    } else {
      dataHashMapUpdated[teamId] = {
        ...team,
        runsValue: team.runsOldValue,
        wicketsValue: team.wicketsOldValue,
        isInputEnabled: !team.isInputEnabled
      };
      dataHashMapUpdated[teamId].playerIds.forEach(playerId => {
        const player = dataHashMap[playerId];
        dataHashMapUpdated[playerId] = {
          ...player,
          runsOldValue: player.runsValue,
          wicketsOldValue: player.wicketsValue,
          runsValue: '',
          wicketsValue: '',
          isInputEnabled: !player.isInputEnabled
        };
        this.runsTextMaskInputElement[player.continuousCount].update('');
        this.wicketsTextMaskInputElement[player.continuousCount].update('');
      });

      this.runsTextMaskInputElement[team.continuousCount].update(team.runsOldValue);
      this.wicketsTextMaskInputElement[team.continuousCount].update(team.wicketsOldValue);
    }

    this.setState({
      dataHashMap: dataHashMapUpdated
    });
  };

  onCricketTextResultChange = event => {
    const { value } = event.target;

    this.setState({
      cricketResultSelected: value
    });
  };

  getAvailableResults(): SelectOptionCricket[] {
    const { event } = this.props;
    const { dataHashMap } = this.state;
    const { teamsData, housesData, inviterSchool, invitedSchools } = event;
    const isSchoolVsSchool = teamsData.length === 0;
    const isSchoolVsTeam = teamsData.length === 1;
    const isTeamVsTeam = teamsData.length === 2;

    let opponent1, opponent2;

    switch (true) {
      case isSchoolVsSchool:
        opponent1 = inviterSchool;
        [opponent2] = invitedSchools;
        break;
      case isSchoolVsTeam:
        [opponent1] = teamsData;
        opponent2 = [...invitedSchools, inviterSchool].find(school => school.id !== opponent1.schoolId);
        break;
      case isTeamVsTeam:
        [opponent1, opponent2] = teamsData;
        break;
    }

    const { id: id1, name: name1 } = opponent1;
    const { id: id2, name: name2 } = opponent2;

    const runsTeam1 = isNaN(Number(dataHashMap[id1].runsValue)) ? 0 : Number(dataHashMap[id1].runsValue);
    const runsTeam2 = isNaN(Number(dataHashMap[id2].runsValue)) ? 0 : Number(dataHashMap[id2].runsValue);
    const wickets1 = isNaN(Number(dataHashMap[id1].wicketsValue)) ? 0 : Number(dataHashMap[id1].wicketsValue);
    const wickets2 = isNaN(Number(dataHashMap[id2].wicketsValue)) ? 0 : Number(dataHashMap[id2].wicketsValue);

    let wonResults: SelectOptionCricket[] = [];

    if (runsTeam1 > runsTeam2) {
      wonResults.push({
        text: `${name1} won by ${runsTeam1 - runsTeam2} runs`,
        value: CRICKET_RESULT.WON_BY_RUNS,
        teamId: id1
      });
      wonResults.push({
        text: `${name1} won by ${CRICKET_WICKETS - wickets1} wickets`,
        value: CRICKET_RESULT.WON_BY_WICKETS,
        teamId: id1
      });

      wonResults.push({
        text: `${name1} won by an innings and ${runsTeam1 - runsTeam2} runs`,
        value: CRICKET_RESULT.WON_BY_INNINGS_AND_RUNS,
        teamId: id1
      });
    }

    if (runsTeam1 < runsTeam2) {
      wonResults.push({
        text: `${name2} won by ${runsTeam2 - runsTeam1} runs`,
        value: CRICKET_RESULT.WON_BY_RUNS,
        teamId: id2
      });

      wonResults.push({
        text: `${name2} won by ${CRICKET_WICKETS - wickets2} wickets`,
        value: CRICKET_RESULT.WON_BY_WICKETS,
        teamId: id2
      });

      wonResults.push({
        text: `${name2} won by an innings and ${runsTeam2 - runsTeam1} runs`,
        value: CRICKET_RESULT.WON_BY_INNINGS_AND_RUNS,
        teamId: id2
      });
    }

    let matchAwardedResults: SelectOptionCricket[] = [];

    matchAwardedResults.push({
      text: `Match awarded to ${name1}`,
      value: CRICKET_RESULT.MATCH_AWARDED,
      teamId: id1
    });

    matchAwardedResults.push({
      text: `Match awarded to ${name2}`,
      value: CRICKET_RESULT.MATCH_AWARDED,
      teamId: id2
    });

    const results = [
      RESULTS_FOR_CRICKET_FOR_SELECT_TBD_OPTION,
      ...wonResults,
      RESULTS_FOR_CRICKET_FOR_SELECT_NO_RESULT_OPTION,
      RESULTS_FOR_CRICKET_FOR_SELECT_TIE_OPTION,
      RESULTS_FOR_CRICKET_FOR_SELECT_DRAW_OPTION,
      ...matchAwardedResults
    ];

    return results;
  }

  render() {
    const { event, onCloseClick } = this.props;
    const { dataHashMap, cricketResultSelected, participants } = this.state;
    const { teamsData } = event;

    let count = 0;

    const availableResults = this.getAvailableResults();

    return (
      <div>
        {participants.map((participant, index) => {
          const { team, school } = participant;
          const isTeamExist = typeof team !== 'undefined';

          if (!isTeamExist) {
            count++;
            return (
              <table
                className={'eTeamExternalSchoolsResultsTable'}
                key={`teams_external_schools_results_table_${index}`}
              >
                <tbody>
                  <tr key={`teams_external_schools_results_${index}`}>
                    <td colSpan={2} className={'eTeamExternalSchoolsResultsSchoolName'} style={{ width: '40%' }}>
                      {school.name}
                    </td>
                    <td className={'eTeamInternalResultsCell'} style={{ width: '30%' }}>
                      Runs
                    </td>
                    <td className={'eTeamInternalResultsCell'} style={{ width: '30%' }}>
                      Wickets
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} className={'eTeamExternalSchoolsResultsCell'} style={{ width: '40%' }}>
                      Total:
                    </td>
                    <td className={'eTeamInternalResultsCell'} style={{ width: '30%' }}>
                      {this.renderRunsInput(count, school.id)}
                    </td>
                    <td className={'eTeamInternalResultsCell'} style={{ width: '30%' }}>
                      {this.renderWicketsInput(count, school.id)}
                    </td>
                  </tr>
                </tbody>
              </table>
            );
          } else {
            count++;
            const { id: teamId, name, players } = team;

            const isPlayersExist = dataHashMap[teamId].playerIds.length !== 0;

            const hidePlayersListClasses = classNames({
              mHidden: dataHashMap[teamId].isInputEnabled
            });

            return (
              <table
                className={'eTeamExternalSchoolsResultsTable'}
                key={`teams_external_schools_results_table_${index}`}
              >
                <tbody>
                  <tr key={`teams_external_schools_results_${index}`}>
                    <td colSpan={2} className={'eTeamExternalSchoolsResultsTeamName'} style={{ width: '40%' }}>
                      {`${name} / ${school.name}`}
                    </td>
                    <td className={'eTeamInternalResultsCell'} style={{ width: '30%' }}>
                      Runs
                    </td>
                    <td className={'eTeamInternalResultsCell'} style={{ width: '30%' }}>
                      Wickets
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} className={'eTeamExternalSchoolsResultsCell'} style={{ width: '40%' }}>
                      Total:
                    </td>
                    <td className={'eTeamInternalResultsCell'} style={{ width: '30%' }}>
                      {this.renderRunsInput(count, teamId)}
                    </td>
                    <td className={'eTeamInternalResultsCell'} style={{ width: '30%' }}>
                      {this.renderWicketsInput(count, teamId)}
                    </td>
                  </tr>
                  {isPlayersExist && !isMultipartyEvent(event) && (
                    <tr>
                      <td colSpan={3} className={'eTeamExternalSchoolsResultsCell'}>
                        <Checkbox
                          value={!dataHashMap[teamId].isInputEnabled}
                          id={teamId}
                          onChange={this.onIndividualResultsChange}
                          labelText={'Individual Scoring'}
                          disabled={isMultipartyEvent(event)}
                        />
                      </td>
                    </tr>
                  )}

                  {players.map(({ firstName, lastName, id }, playerIndex) => {
                    count++;
                    return (
                      <tr key={`teams_inter_teams_results_player_${playerIndex}`} className={hidePlayersListClasses}>
                        <td className={'eTeamInternalResultsCell'}>{playerIndex + 1}.</td>
                        <td className={'eTeamInternalResultsCell'}>{`${firstName} ${lastName}`}</td>
                        <td className={'eTeamInternalResultsCell'}>{this.renderRunsInput(count, id)}</td>
                        <td className={'eTeamInternalResultsCell'}>{this.renderWicketsInput(count, id)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            );
          }
        })}

        <CricketTextResultSelect
          cricketResultSelected={cricketResultSelected}
          onCricketTextResultChange={this.onCricketTextResultChange}
          availableResults={availableResults}
        />

        <Button text={'Cancel'} onClick={onCloseClick} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
        <Button text={'Save results'} onClick={this.onSubmit} customClass={'mt-3 mb-3'} />
      </div>
    );
  }
}
