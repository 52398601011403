import axios from 'axios';
import {AppUser} from 'Src/views/App/App';
import {DEFAULT_LIMIT, DEFAULT_SKIP} from 'Src/consts/table';

export function getAllSchoolActivities(user: AppUser, where?) {
    const {roleSessionKey: key, activeSchoolId} = user;

    let config;

    if (typeof where !== 'undefined') {
        config = {
            headers: {usid: key},
            params: {
                filter: {
                    where: {
                        ...where
                    },
                    limit: DEFAULT_LIMIT,
                    skip: DEFAULT_SKIP
                }
            }
        };
    } else {
        config = {
            headers: {usid: key},
            params: {
                filter: {
                    limit: DEFAULT_LIMIT,
                    skip: DEFAULT_SKIP
                }
            }
        };
    }

    return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/sports`, config).then(response => {
        return response.data;
    });
}

export function getSchoolActivitiesCount(user: AppUser, where?) {
    const {roleSessionKey: key, activeSchoolId} = user;

    let config;

    if (typeof where !== 'undefined') {
        config = {
            headers: {usid: key},
            params: {
                filter: {
                    where: {
                        ...where
                    },
                    limit: DEFAULT_LIMIT,
                    skip: DEFAULT_SKIP
                }
            }
        };
    } else {
        config = {
            headers: {usid: key}
        };
    }

    return axios
        .get(`${window.apiBase}/i/schools/${activeSchoolId}/sports`, config)
        .then(response => {
            // in future /count
            return {count: response.data.length};
        });
}

export function getSchoolSport(user: AppUser, sportId, data) {
    const {roleSessionKey: key, activeSchoolId} = user;

    const config = {
        headers: {usid: key}
    };

    return axios.put(`${window.apiBase}/i/schools/${activeSchoolId}/sports/${sportId}`, data, config).then(response => {
        return response.data;
    });
}
