import * as React from 'react';
import { FunctionComponent } from 'react';
import { GeneralMessageActionsButton } from '../GeneralMessageActionsButton/GeneralMessageActionsButton';

interface Props {
  hasNext: boolean;
  hasPrevious: boolean;
  onNextClick: () => void;
  onPreviousClick: () => void;
  onSaveClick?: () => void;
  onSendClick: () => void;
  onCancelClick: () => void;
  isNextButtonDisabled?: boolean;
  isError?: boolean;
}

export const CreateGeneralMessageButtons: FunctionComponent<Props> = props => {
  const { hasNext, hasPrevious, onNextClick, onPreviousClick, onCancelClick, isNextButtonDisabled, isError } = props;

  return (
    <div className="mt-3">
      <button type="button" className="btn btn-secondary mr-3" disabled={isError} onClick={onCancelClick}>
        Cancel
      </button>
      {hasPrevious && (
        <button type="button" className="btn btn-primary mr-3" disabled={isError} onClick={onPreviousClick}>
          Previous step
        </button>
      )}

      {hasNext && (
        <button type="button" className="btn btn-primary" onClick={onNextClick} disabled={isNextButtonDisabled}>
          Next
        </button>
      )}
    </div>
  );
};
