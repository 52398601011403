import { SCHOOL_GENDER_TYPE, SCHOOL_SUBSCRIPTION_TYPE } from '../types/school';

export const KIND = {
  SCHOOL: 'School',
  SCHOOL_UNION: 'SchoolUnion'
};

// Reception, Y1..Y13 (14 age group)
// P1..P7, S1..S6 (13 age group, Y13 does not exist)
// U5..U18 (14 age group),
// Pre-kindergarten, 1st..12th Grade (14 age group)
export const AGE_GROUPS_COUNT = 14;
export const AGE_GROUPS = {
  ENGLISH: [
    'Reception', //0
    'Y1', //1
    'Y2', //2
    'Y3', //3
    'Y4', //4
    'Y5', //5
    'Y6', //6
    'Y7', //7
    'Y8', //8
    'Y9', //9
    'Y10', //10
    'Y11', //11
    'Y12', //12
    'Y13', //13
    'Y14', //14
    'N1', //15
    'N2', //16
    'Post-19' //17
  ],
  SCOTTISH: [
    'Nursery', // 0
    'P1', //1
    'P2', //2
    'P3', //3
    'P4', //4
    'P5', //5
    'P6', //6
    'P7', //7
    'S1', //8
    'S2', //9
    'S3', //10
    'S4', //11
    'S5', //12
    'S6' //13
  ],
  'U5-U18': [
    'U5', //0
    'U6', //1
    'U7', //2
    'U8', //3
    'U9', //4
    'U10', //5
    'U11', //6
    'U12', //7
    'U13', //8
    'U14', //9
    'U15', //10
    'U16', //11
    'U17', //12
    'U18', //13
    'U19', //14
    'U3', //15
    'U4' //16
  ],
  US: [
    'Pre-kindergarten', //0
    'Kindergarten', //1
    '1st Grade', //2
    '2nd Grade', //3
    '3rd Grade', //4
    '4th Grade', //5
    '5th Grade', //6
    '6th Grade', //7
    '7th Grade', //8
    '8th Grade', //9
    '9th Grade', //10
    '10th Grade', //11
    '11th Grade', //12
    '12th Grade' //13
  ]
};

export const AGE_GROUPS_SORTED = {
  ENGLISH: [
    'N1', //15
    'N2', //16
    'Reception', //0
    'Y1', //1
    'Y2', //2
    'Y3', //3
    'Y4', //4
    'Y5', //5
    'Y6', //6
    'Y7', //7
    'Y8', //8
    'Y9', //9
    'Y10', //10
    'Y11', //11
    'Y12', //12
    'Y13', //13
    'Y14', //14
    'Post-19' //17
  ],
  SCOTTISH: [
    'Nursery', // 0
    'P1', //1
    'P2', //2
    'P3', //3
    'P4', //4
    'P5', //5
    'P6', //6
    'P7', //7
    'S1', //8
    'S2', //9
    'S3', //10
    'S4', //11
    'S5', //12
    'S6' //13
  ],
  'U5-U18': [
    'U3', //15
    'U4', //16
    'U5', //0
    'U6', //1
    'U7', //2
    'U8', //3
    'U9', //4
    'U10', //5
    'U11', //6
    'U12', //7
    'U13', //8
    'U14', //9
    'U15', //10
    'U16', //11
    'U17', //12
    'U18', //13
    'U19' //14
  ],
  US: [
    'Pre-kindergarten', //0
    'Kindergarten', //1
    '1st Grade', //2
    '2nd Grade', //3
    '3rd Grade', //4
    '4th Grade', //5
    '5th Grade', //6
    '6th Grade', //7
    '7th Grade', //8
    '8th Grade', //9
    '9th Grade', //10
    '10th Grade', //11
    '11th Grade', //12
    '12th Grade' //13
  ]
};

export const AGE_GROUPS_NAMING = {
  ENGLISH: 'ENGLISH',
  SCOTTISH: 'SCOTTISH',
  'U5-U18': 'U5-U18',
  US: 'US'
};

export const SUBSCRIPTION_PLAN: {
  FULL: SCHOOL_SUBSCRIPTION_TYPE;
  LITE: SCHOOL_SUBSCRIPTION_TYPE;
  TOURNAMENTS_PARTICIPATION: SCHOOL_SUBSCRIPTION_TYPE;
  FREE_COMMUNICATION: SCHOOL_SUBSCRIPTION_TYPE;
} = {
  FULL: 'FULL',
  LITE: 'LITE',
  TOURNAMENTS_PARTICIPATION: 'TOURNAMENTS_PARTICIPATION',
  FREE_COMMUNICATION: 'FREE_COMMUNICATION'
};

export const STUDENT_TABS = ['Summary', 'Next of kin'];
export const MEMBER_OF_STAFF_TABS = ['Summary', 'Roles'];
export const SCHOOL_TABS = ['Summary', 'Upcoming tournaments', 'Admins', 'Students', 'Forms'];
export const CLUB_PARTICIPATING_STUDENT_TABS = ['Summary'];
export const FIXTURE_PARTICIPATING_STUDENT_TABS = ['Event'];

export const MAX_WIZARD_STEP = 4;

export const AVAILABLE_SCHOOL_TYPE = {
  TOURNAMENT_WAITING_LIST: 'TOURNAMENT_WAITING_LIST',
  TOURNAMENT_PARTICIPANT: 'TOURNAMENT_PARTICIPANT',
  SCHOOL_UNION_PARTICIPANT: 'SCHOOL_UNION_PARTICIPANT'
};

export const SCHOOL_PERMISSION_REQUEST_FIELD_STATUS = {
  HIDDEN: 'HIDDEN',
  OPTIONAL: 'OPTIONAL',
  REQUIRED: 'REQUIRED'
};

export const SCHOOL_GENDER: {
  MALE: SCHOOL_GENDER_TYPE;
  FEMALE: SCHOOL_GENDER_TYPE;
  MIXED: SCHOOL_GENDER_TYPE;
} = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  MIXED: 'MIXED'
};

export const SCHOOL_GENDER_SERVER_TO_CLIENT_MAPPING = {
  MALE: 'Male',
  FEMALE: 'Female',
  MIXED: 'Mixed'
};

export const SCHOOL_STATUS = {
  PUBLIC_AVAILABLE: 'PUBLIC_AVAILABLE',
  PROTECTED: 'PROTECTED',
  DISABLED: 'DISABLED'
};

export const SCHOOL_STATUS_SERVER_TO_CLIENT_MAPPING = {
  PUBLIC_AVAILABLE: 'Enabled',
  PROTECTED: 'Protected',
  DISABLED: 'Disabled'
};

export const STUDENT_NAME_VIEW = {
  SHORTEN_LASTNAME_ONLY: 'SHORTEN_LASTNAME_ONLY',
  SHORTEN_FIRSTNAME_AND_LASTNAME: 'SHORTEN_FIRSTNAME_AND_LASTNAME'
};

export const STUDENT_NAME_VIEW_SERVER_TO_CLIENT_MAPPING = {
  SHORTEN_LASTNAME_ONLY: 'Shorten Last Name only (Thomas S.)',
  SHORTEN_FIRSTNAME_AND_LASTNAME: 'Shorten First Name and Last Name (T.S.)'
};

export const BIGSCREEN_SITE_THEME = {
  AMBIENCE: 'AMBIENCE'
};

export const DEFAULT_PUBLIC_SITE = {
  isTwitterVisible: false,
  status: SCHOOL_STATUS.DISABLED
};

export const DEFAULT_PUBLIC_BIGSCREEN_SITE = {
  autoScrollingDelay: 6,
  status: SCHOOL_STATUS.DISABLED,
  theme: BIGSCREEN_SITE_THEME.AMBIENCE
};

export const AVAILABLE_AGES_MAX = 13;
export const AVAILABLE_AGES_MIN = 0;

export const GALLERY_ACCESS_PRESET = {
  MANAGER: 'MANAGER',
  PARENT: 'PARENT',
  STUDENT: 'STUDENT',
  PUBLIC: 'PUBLIC'
};
