import * as React from 'react';
import * as propz from 'propz';
import { Autocomplete } from '../Autocomplete/Autocomplete';
import { FunctionComponent } from 'react';
import * as Moment from 'moment';

interface Props {
  request: any;
  isUserAlreadyLinkedError: boolean;
  onSelectForm: (form: any) => void;
  onSelectHouse: (house: any) => void;
  onSelectStudent: (student: any) => void;
  onAcceptPermission: () => void;
  onClearPermission: () => void;
  searchFunctionForms: (text: string) => void;
  searchFunctionHouses: (text: string) => void;
  searchFunctionStudents: (text: string) => void;
  getFormOrHouseTitle: (item: any) => string;
  getStudentTitle: (item: any) => string;
  onChangeToStudent: (event: any, student: any) => void;
  form: any;
  house: any;
  student: any;
  students: any[];
}

export const RequestAcceptParent: FunctionComponent<Props> = props => {
  const {
    request,
    isUserAlreadyLinkedError,
    onSelectForm,
    onSelectHouse,
    onAcceptPermission,
    onClearPermission,
    onSelectStudent,
    searchFunctionForms,
    searchFunctionHouses,
    searchFunctionStudents,
    getFormOrHouseTitle,
    getStudentTitle,
    form,
    house,
    student,
    students
  } = props;

  const comment = propz.get(request, ['requestedPermission', 'comment']);

  const getChildDetails = () => {
    const childDetails = [];

    if (typeof request.requestedPermission.childDateOfBirth !== 'undefined') {
      childDetails.push(`DOB: ${Moment(request.requestedPermission.childDateOfBirth).format('DD.MM.YYYY')}`);
    }

    if (typeof request.requestedPermission.childGender !== 'undefined') {
      childDetails.push(`Gender: ${request.requestedPermission.childGender}`);
    }

    if (typeof request.requestedPermission.form !== 'undefined') {
      const formName = propz.get(request, ['requestedPermission', 'form', 'name'], '');
      childDetails.push(`Form: ${formName}`);
    }

    if (typeof request.requestedPermission.house !== 'undefined') {
      const houseName = propz.get(request, ['requestedPermission', 'house', 'name'], '');
      childDetails.push(`House: ${houseName}`);
    }

    return childDetails;
  };

  const renderChildDetails = () => {
    const childDetails = getChildDetails();

    if (childDetails.length > 0) {
      return <div className="mb-3">Child details received from parent: {childDetails.join(', ')}</div>;
    }
  };

  const renderStudentList = () => {
    return (
      <>
        {students.length > 0 && <p>May be you need these students:</p>}
        {students.map((student, index) => {
          const studentFormName = propz.get(student, ['form', 'name'], '');
          const studentHouseName = propz.get(student, ['house', 'name'], '');

          return (
            <div key={`student_${index}`}>
              <a className="" href="" onClick={event => props.onChangeToStudent(event, student)}>
                {student.firstName} {student.lastName} {studentFormName} {studentHouseName}
              </a>
            </div>
          );
        })}
      </>
    );
  };

  const isExistStudent = typeof student !== 'undefined';

  return (
    <div className="bRequestAcceptPage">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <h1>Accept parent permission. Please choose:</h1>
            {typeof comment !== 'undefined' && <p>Comment: {comment}</p>}
            {renderChildDetails()}
          </div>

          <div className={'col-md-2 mb-3'}>
            <div>Form:</div>
            <Autocomplete
              searchFunction={searchFunctionForms}
              getElementTitle={getFormOrHouseTitle}
              onSelect={onSelectForm}
              defaultItem={form}
            />
          </div>
          <div className={'col-md-2 mb-3'}>
            <div>House:</div>
            <Autocomplete
              searchFunction={searchFunctionHouses}
              getElementTitle={getFormOrHouseTitle}
              onSelect={onSelectHouse}
              defaultItem={house}
            />
          </div>
          <div className={'col-md-2 mb-3'}>
            <div>Student name:</div>
            <Autocomplete
              searchFunction={searchFunctionStudents}
              getElementTitle={getStudentTitle}
              onSelect={onSelectStudent}
              defaultItem={student}
            />
          </div>
        </div>

        {isUserAlreadyLinkedError && (
          <div className="text-danger">The user has already been linked to the selected child.</div>
        )}

        <div className="row">
          <div className="col-md-12">{renderStudentList()}</div>
          <div className={'col-md-12'}>
            <button className="btn btn-primary mb-3 mt-3 mr-3" onClick={onAcceptPermission} disabled={!isExistStudent}>
              Accept permission
            </button>
            <button className="btn btn-secondary mb-3 mt-3 mr-3" onClick={onClearPermission} disabled={!isExistStudent}>
              Clear permission
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

RequestAcceptParent.displayName = 'RequestAcceptParent';
