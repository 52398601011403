export const PROFILE_TABS_ARRAY = [
  'SUMMARY',
  'ROLES',
  'REQUESTS',
  'CHANGE_PASSWORD',
  'NOTIFICATIONS',
  'SUBSCRIPTIONS',
  'VERIFICATION'
];

export const PROFILE_TABS = {
  SUMMARY: 'SUMMARY',
  ROLES: 'ROLES',
  REQUESTS: 'REQUESTS',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  NOTIFICATIONS: 'NOTIFICATIONS',
  SUBSCRIPTIONS: 'SUBSCRIPTIONS',
  VERIFICATION: 'VERIFICATION'
};

export const PROFILE_TABS_TITLES = {
  SUMMARY: 'Summary',
  ROLES: 'Roles',
  REQUESTS: 'Requests',
  CHANGE_PASSWORD: 'Change Password',
  NOTIFICATIONS: 'Notifications',
  SUBSCRIPTIONS: 'Subscriptions',
  VERIFICATION: 'Verification'
};

export const PROFILE_TABS_URLS = {
  SUMMARY: '/profile/summary',
  ROLES: '/profile/roles',
  REQUESTS: '/profile/requests',
  CHANGE_PASSWORD: '/profile/changePassword',
  NOTIFICATIONS: '/profile/notifications',
  SUBSCRIPTIONS: '/profile/subscriptions',
  VERIFICATION: '/profile/verification'
};
