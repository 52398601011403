import * as React from 'react';
import { Component } from 'react';
import { Autocomplete } from '../Autocomplete/Autocomplete';
import { getName, searchFunctionFormsNinFormIds } from 'Src/helpers/autocomplete/autocomplete';
import { AppUser } from 'Src/views/App/App';
import { ModalDialog } from '../ModalDialog/ModalDialog';

interface Props {
  user: AppUser;
  onSubmitFormsClick: (forms) => void;
  onCancelClick: () => void;
  isOpen: boolean;
}

interface State {
  selectedForms: any[];
}

export class FormAssignModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { selectedForms: [] };
  }

  getForms = text => {
    const user = this.props.user;
    const selectedFormIds = this.state.selectedForms.map(form => form.id);

    return searchFunctionFormsNinFormIds(user, text, selectedFormIds);
  };

  onFormSelected = (form): void => {
    const { selectedForms } = this.state;
    const forms = selectedForms.slice();
    const formIndex = forms.findIndex(f => f.id === form.id);
    if (formIndex === -1) {
      forms.push(form);
    }

    this.setState({ selectedForms: forms });
  };

  onRemoveFormClick = (form): void => {
    const { selectedForms } = this.state;
    const forms = selectedForms.slice();
    const formIndex = forms.findIndex(f => f.id === form.id);
    if (formIndex !== -1) {
      forms.splice(formIndex, 1);
    }

    this.setState({ selectedForms: forms });
  };

  onFormsSubmit = (): void => {
    this.props.onSubmitFormsClick(this.state.selectedForms);
  };

  render() {
    return (
      <ModalDialog
        title="Assign form"
        isOpen={this.props.isOpen}
        positiveButtonTitle="Save"
        negativeButtonTitle="Cancel"
        onPositiveButtonClick={this.onFormsSubmit}
        onNegativeButtonClick={this.props.onCancelClick}
      >
        <div>
          {this.state.selectedForms.map(form => (
            <span
              key={`form_key_${form.id}`}
              className="badge badge-primary p-1 mr-1 mb-1 font-weight-normal"
              style={{ fontSize: '14px' }}
            >
              {form.name}
              <span
                key={`autocomplete_result_key_${form.id}`}
                aria-hidden="true"
                className="ml-2"
                style={{ cursor: 'pointer' }}
                onClick={() => this.onRemoveFormClick(form)}
              >
                &times;
              </span>
            </span>
          ))}
        </div>
        <Autocomplete
          customClass="w-100"
          onSelect={this.onFormSelected}
          getElementTitle={getName}
          searchFunction={this.getForms}
        />
      </ModalDialog>
    );
  }
}
