import * as React from 'react';
import * as BPromise from 'bluebird';
import { Component } from 'react';
import { parse } from 'query-string';
import { History, Location } from 'history';
import { AppUser } from 'Src/views/App/App';
import { SchoolEvent } from 'Src/models/event';
import { getActionList, getNextEventFilter, getPreviousEventFilter } from 'Src/helpers/event/schoolEventViewHelper';
import { Loader } from 'Src/components/Loader/Loader';
import { ActionsButton } from 'Src/components/ActionsButton/ActionsButton';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { EventActionButtonCallbacks } from '../../AdminView/TeamsAndEvents/Events/SchoolEventView/SchoolEventView';
import {
  getChildrenEvent,
  getChildrenEvents,
  sentChildrenAvailabilityReport
} from '../../../../helpers/service/parent/childrenEvents';
import { ChildrenEventNavigationButtons } from './NavigationButtons/NavigationButtons';
import { ChildrenEventMainInformation } from './MainInformation/MainInformation';
import { ChildrenSummaryTabs } from './SummaryTabs/SummaryTabs';
import { AvailabilityReportForm } from './Forms/AvailabilityReportForm';
import { getChildren } from '../../../../helpers/service/parent/children';
import {
  getNextParentEventFilter,
  getPreviousParentEventFilter
} from '../../../../helpers/event/schoolEventViewHelper';
import { getEventNameForParent } from '../../../../helpers/accessor/accessor';
import { Child } from 'Src/models/child';
import * as propz from 'propz';
import { Map } from 'Src/components/Map/Map';
import { getNewPoint } from 'Src/helpers/venue/venue';
import { Button } from 'Src/components/Button/Button';

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

interface State {
  isLoading: boolean;
  event: SchoolEvent;
  prevEvent: SchoolEvent;
  nextEvent: SchoolEvent;
  isAvailabilityFormModalOpen: boolean;
  isSuccessModalOpen: boolean;
  isMapModalOpen: boolean;
  childIdList: string[];
  childrenInEvent: Child[];
}

export class ChildrenEventView extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      event: undefined,
      prevEvent: undefined,
      nextEvent: undefined,
      isAvailabilityFormModalOpen: false,
      isSuccessModalOpen: false,
      isMapModalOpen: false,
      childIdList: [],
      childrenInEvent: []
    };
  }

  getEventId(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    const eventId = search.id;
    return eventId;
  }

  //reload component after change eventId by navigation buttons
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location } = this.props;
    const { location: nextLocation } = nextProps;
    const { search } = location;
    const { search: nextSearch } = nextLocation;

    if (search !== nextSearch) {
      this.setState({
        isLoading: true
      });
      this.load();
    }
  }

  load() {
    const { user, history, location } = this.props;
    const eventId = this.getEventId();

    const calendarFilterType = propz.get(location, ['state', 'filter']);

    let event,
      prevEvent,
      nextEvent,
      childIdList,
      childrenInEvent = [];

    // 1. Get children
    // 2. Get current event
    // 3. Get previous/next events (for navigation)
    getChildren(user).then(children => {
      childIdList = children.map(child => child.id);
      return getChildrenEvent(user, eventId)
        .then(_event => {
          event = _event;

          const { players } = event;
          const playersIdList = players.map(player => player.userId);

          children.forEach(child => {
            const isEventParticipantChild = playersIdList.some(playerId => playerId === child.id);
            if (isEventParticipantChild) {
              childrenInEvent.push(child);
            }
          });

          const currentEventStartTime = new Date(_event.startTime);

          const nextEventFilter = getNextParentEventFilter(
            eventId,
            currentEventStartTime,
            childIdList,
            calendarFilterType
          );
          const prevEventFilter = getPreviousParentEventFilter(
            eventId,
            currentEventStartTime,
            childIdList,
            calendarFilterType
          );

          const prevEventPromise = getChildrenEvents(user, prevEventFilter);
          const nextEventPromise = getChildrenEvents(user, nextEventFilter);

          return BPromise.all([prevEventPromise, nextEventPromise]);
        })
        .then(([_prevEvent, _nextEvent]) => {
          [prevEvent] = _prevEvent;
          [nextEvent] = _nextEvent;

          this.setState({
            isLoading: false,
            event,
            nextEvent,
            prevEvent,
            childIdList,
            childrenInEvent
          });
        })
        .catch(error => {
          console.error(error);
          history.push({
            //  if there are no rights to the event, then return it to the calendar
            pathname: '/calendar'
          });
        });
    });
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.load();
  }

  onPrevEventClick = () => {
    const { prevEvent } = this.state;
    const { history, location } = this.props;
    const { id } = prevEvent;
    const state = propz.get(location, ['state'], {});

    history.push({
      pathname: '/events/event',
      search: `id=${id}`,
      state
    });
  };

  onNextEventClick = () => {
    const { nextEvent } = this.state;
    const { history, location } = this.props;
    const { id } = nextEvent;
    const state = propz.get(location, ['state'], {});

    history.push({
      pathname: '/events/event',
      search: `id=${id}`,
      state
    });
  };

  openAvailabilityModal = () => {
    this.setState({
      isAvailabilityFormModalOpen: true
    });
  };

  closeAvailabilityFormModal = () => {
    this.setState({
      isAvailabilityFormModalOpen: false
    });
  };

  onSubmit = data => {
    const { user } = this.props;
    const { event } = this.state;

    this.setState({
      isLoading: true
    });

    sentChildrenAvailabilityReport(user, event.id, data).then(() => {
      this.setState({
        isAvailabilityFormModalOpen: false,
        isSuccessModalOpen: true,
        isLoading: false
      });
    });
  };

  renderAvailabilityFormModal = () => {
    const { isAvailabilityFormModalOpen, childrenInEvent } = this.state;
    return (
      <SimpleModal isOpen={isAvailabilityFormModalOpen}>
        <AvailabilityReportForm
          childrenInEvent={childrenInEvent}
          onCancel={this.closeAvailabilityFormModal}
          onSubmit={this.onSubmit}
        />
      </SimpleModal>
    );
  };

  closeSuccessModal = () => {
    this.setState({
      isSuccessModalOpen: false
    });
  };

  renderSuccessModal() {
    const { isSuccessModalOpen } = this.state;
    return (
      <SimpleModal
        isOpen={isSuccessModalOpen}
        title={'Info'}
        body={'Your report has been successfully sent.'}
        buttonCancelText={'Ok'}
        onCloseClick={this.closeSuccessModal}
        customClass={'eChangePasswordTabModal'}
      />
    );
  }

  emptyFunc = () => {};

  getCallbacks(): EventActionButtonCallbacks {
    return {
      EDIT_EVENT_SETTINGS: this.emptyFunc,
      DUPLICATE_EVENT: this.emptyFunc,
      ADD_SCHOOL: this.emptyFunc,
      ADD_TEAM: this.emptyFunc,
      ADD_RESULTS: this.emptyFunc,
      UPDATE_RESULTS: this.emptyFunc,
      UPDATE_POINTS: this.emptyFunc,
      SEND_CONSENT_REQUEST: this.emptyFunc,
      CANCEL_EVENT: this.emptyFunc,
      CANCEL_EVENT_AND_EDIT_NOTIFICATION_LIST: this.emptyFunc,
      REPORT_AVAILABILITY: this.openAvailabilityModal,
      PRINT: this.emptyFunc,
      DOWNLOAD_CSV: this.emptyFunc,
      DELETE_EVENT: this.emptyFunc,
      IMPORT_RESULTS_FROM_QUANTUM: this.emptyFunc,
      EXPORT_START_LIST_TO_MACSHA_ONE_4_ALL: this.emptyFunc,
      IMPORT_RESULTS_FROM_MACSHA_ONE_4_ALL: this.emptyFunc,
      CLEAN_SCHOOL_LIST: this.emptyFunc,
      SEND_EVENT_NOTIFICATION: this.emptyFunc,
      SEND_EVENT_NOTIFICATION_ELIGIBLE_STUDENTS: this.emptyFunc,
      IMPORT_RESULTS: this.emptyFunc,
      IMPORT_RESULTS_FROM_QUANTUM_AQ: this.emptyFunc,
      CLONE_TOURNAMENT_EVENT: this.emptyFunc,
      ARBITER_BOARD: this.emptyFunc,
      ADD_VIDEO: this.emptyFunc
    };
  }

  renderMapModal() {
    const { isMapModalOpen, event } = this.state;

    const { venue } = event;
    const point = getNewPoint(venue);
    const { coordinates } = point;
    const [latitude, longitude] = coordinates;

    return (
      <SimpleModal isOpen={isMapModalOpen} title={'Venue'} buttonCancelText={'Close'} onCloseClick={this.closeMapModal}>
        <Map point={point} customStylingClass="mb-3 eClubWizardMap" isMarkerDraggable={false} />
        <div>
          <a
            target="_blank"
            href={`http://maps.google.com/maps?q=${longitude},${latitude}+(My+Point)&z=14&ll=${longitude},${latitude}`}
          >
            View on Google Maps
          </a>
        </div>
      </SimpleModal>
    );
  }

  openMapModal = () => {
    this.setState({
      isMapModalOpen: true
    });
  };

  closeMapModal = () => {
    this.setState({
      isMapModalOpen: false
    });
  };

  goBack = () => {
    const { event } = this.state;
    const { history, location } = this.props;
    const { state } = location;
    const { prevRoutePath, filter } = state as any;

    const selectedDate = propz.get(event, ['startTime']);

    const isSelectedDateExist = typeof selectedDate !== 'undefined';
    const isFilterExist = typeof filter !== 'undefined';

    const data: any = {
      pathname: prevRoutePath,
      state: {}
    };

    switch (true) {
      case isFilterExist:
        data.state.filter = filter;
        break;

      case isSelectedDateExist:
        data.state.filter = filter;
        break;
    }

    if (isFilterExist) {
      data.state.selectedDate = selectedDate;
    }

    history.push(data);
  };

  render() {
    const { isLoading, prevEvent, nextEvent, event, childIdList, childrenInEvent } = this.state;
    const { user, location } = this.props;

    const prevRoutePath = propz.get(location, ['state', 'prevRoutePath']);
    const isPrevRoutePathExist = typeof prevRoutePath !== 'undefined' && prevRoutePath !== '';

    if (isLoading) {
      return <Loader />;
    }

    const isPrevEventExist = typeof prevEvent !== 'undefined';
    const isNextEventExist = typeof nextEvent !== 'undefined';

    const callbacks = this.getCallbacks();
    const actionItems = getActionList(user, event, callbacks);
    const eventName = getEventNameForParent(event, childIdList);
    const classes = 'mt-3';

    return (
      <div className={classes}>
        {this.renderAvailabilityFormModal()}
        {this.renderSuccessModal()}
        {this.renderMapModal()}

        {isPrevRoutePathExist && (
          <div>
            <Button text={'← back to calendar'} onClick={this.goBack} customClass={'btn-secondary mr-3 mb-3'} />
          </div>
        )}

        <div className={'d-flex justify-content-between'}>
          <ActionsButton actionItems={actionItems} />
          <ChildrenEventNavigationButtons
            isPrevButtonDisabled={!isPrevEventExist}
            isNextButtonDisabled={!isNextEventExist}
            onPrevEventClick={this.onPrevEventClick}
            onNextEventClick={this.onNextEventClick}
          />
        </div>
        <ChildrenEventMainInformation
          user={user}
          event={event}
          eventName={eventName}
          onViewMapClick={this.openMapModal}
        />
        <ChildrenSummaryTabs user={user} eventInitial={event} eventName={eventName} childrenInEvent={childrenInEvent} />
      </div>
    );
  }
}
