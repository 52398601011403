import * as React from 'react';
import { Component } from 'react';
import * as propz from 'propz';
import { Field, Form } from 'formik';
import DatePicker from 'react-datepicker';
import { uploadImage } from 'Src/helpers/service/image';
import { AppUser } from 'Src/views/App/App';
import * as Moment from 'moment';
import { HTMLEditorQuill } from 'Src/components/HTMLEditorQuill/HTMLEditorQuill';
import { getFileName } from '../../../../../../helpers/file/file';

interface Props {
  announcement: any;
  user: AppUser;
  onCloseClick: (index) => void;
  onGlobalAnnouncementsFormChange: (event, index, field) => void;
  announcementIndex: number;
}

interface State {
  isImageError: boolean;
}

export class TournamentCustomSectionForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isImageError: false
    };
  }

  onImageSelected = event => {
    const { user, onGlobalAnnouncementsFormChange, announcementIndex } = this.props;
    const image = event.target.files[0];
    uploadImage(user, image)
      .then(response => {
        const picUrl = `${window.apiImg}/images/${response.key}`;
        onGlobalAnnouncementsFormChange(picUrl, announcementIndex, 'uploadImage');
        this.setState({
          isImageError: false
        });
      })
      .catch(error => {
        console.error(error);
        this.setState({ isImageError: true });
      });
  };

  renderAttachments() {
    const { announcement, announcementIndex } = this.props;

    const files = announcement.attachments.files || [];
    return files.map((file, fileIndex) => {
      const fileName = propz.get(file, ['name'], '');
      const attachmentFileName = getFileName(fileName);
      const isPicUrlExists = typeof file.url !== 'undefined' && file.url !== '';

      if (!isPicUrlExists) {
        return null;
      }

      return (
        <div key={`attachment_${fileIndex}`}>
          {attachmentFileName}
          <button
            type="button"
            className="close"
            onClick={() => this.props.onGlobalAnnouncementsFormChange(fileIndex, announcementIndex, 'removeFileButton')}
          >
            <span>&times;</span>
          </button>
        </div>
      );
    });
  }

  render() {
    const { isImageError } = this.state;
    const { announcement, announcementIndex } = this.props;
    const picUrl = propz.get(announcement, ['attachments', 'images', '0', 'url'], '');
    const isPicUrlExists = typeof picUrl !== 'undefined' && picUrl !== '';
    const imgInputClassName = isImageError ? 'form-control-file is-invalid' : 'form-control-file';
    const dateObj = new Date(announcement.date);
    const date = Moment(dateObj, 'DD-MM-YYYY HH:mm aa').isValid()
        ? Moment(dateObj, 'DD-MM-YYYY HH:mm aa').toDate()
        : null,
      isDateVisible = propz.get(announcement, ['isDateVisible'], false),
      title = propz.get(announcement, ['title'], ''),
      body = propz.get(announcement, ['body'], '');

    return (
      <div className="bTournamentFormCustomSectionBlock rounded">
        <div className="form-group">
          <button type="button" className="close" onClick={() => this.props.onCloseClick(announcementIndex)}>
            <span>&times;</span>
          </button>
          <label>Date</label>
          <Field
            render={({ field }) => {
              return (
                <div className="mb-3">
                  <DatePicker
                    selected={date}
                    onChange={date => {
                      this.props.onGlobalAnnouncementsFormChange(date, announcementIndex, 'date');
                    }}
                    className="form-control"
                    timeInputLabel="Time: "
                    showTimeSelect={true}
                    dateFormat={'dd-MM-yyyy hh:mm:a'}
                  />
                </div>
              );
            }}
          />
          <div className="form-group form-check">
            <input
              id="isDateVisible"
              type="checkbox"
              className="form-check-input"
              checked={isDateVisible}
              onChange={event => {
                this.props.onGlobalAnnouncementsFormChange(event, announcementIndex, 'isDateVisible');
              }}
            />
            <label htmlFor="isDateVisible" className="form-check-label">
              Show date and time
            </label>
          </div>

          <label>Title</label>
          <Field
            type="text"
            value={title}
            onChange={event => {
              this.props.onGlobalAnnouncementsFormChange(event, announcementIndex, 'title');
            }}
            className="form-control"
          />
          <label>Body</label>
          <HTMLEditorQuill
            onEmailBodyChange={text => {
              this.props.onGlobalAnnouncementsFormChange(text, announcementIndex, 'body');
            }}
            value={body}
            isEnabled
          />
          <div className="form-group">
            <label htmlFor="attachment">Attachments</label>
            <input
              type="file"
              className="form-control-file"
              id="attachment"
              onChange={event => {
                this.props.onGlobalAnnouncementsFormChange(event, announcementIndex, 'uploadFile');
              }}
            />

            {this.renderAttachments()}
          </div>
          <div className="form-group">
            <label>Photos</label>
            <input type="file" name="picUrl" className={imgInputClassName} onChange={this.onImageSelected} />
            {isImageError && <div className="invalid-feedback">Error uploading image</div>}
          </div>
          {isPicUrlExists && (
            <div className="mMaxWidth200px">
              <button
                type="button"
                className="close"
                onClick={event => {
                  this.props.onGlobalAnnouncementsFormChange(event, announcementIndex, 'removeImageButton');
                }}
              >
                <span>&times;</span>
              </button>
              <img className="img-fluid img-thumbnail" src={picUrl} alt="News picture" />
            </div>
          )}
        </div>
      </div>
    );
  }
}
