import * as React from 'react';
import { FunctionComponent } from 'react';

export const Loader: FunctionComponent = () => {
  return (
    <div className="bLoading">
      <div className="spinner-border">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

Loader.displayName = 'Loader';