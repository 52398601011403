import * as React from 'react';
import ReactQuill from 'react-quill';
import './HTMLEditorQuillForMatchReport.css';
import { FunctionComponent } from 'react';
import {
  HTML_EDITOR_QUILL_FORMATS,
  HTML_EDITOR_QUILL_MODULES,
  HTML_EDITOR_QUILL_MODULES_HIDE
} from './HTMLEditorConst';

interface Props {
  onTextMatchReportChange: (editorState: any) => void;
  value: string;
  isEnabled: boolean;
  isEnabledEditorActiveToolbar: boolean;
  placeholder: string;
}

export const HTMLEditorQuillForMatchReport: FunctionComponent<Props> = props => {
  const isEnabled = props.isEnabled;
  const isEnabledEditorActiveToolbar = props.isEnabledEditorActiveToolbar;
  const editorClassName = isEnabled ? 'quillEditor-root' : 'quillEditor-root mDisabled';

  return (
    <div className={editorClassName}>
      <ReactQuill
        placeholder={props.placeholder}
        onChange={props.onTextMatchReportChange}
        value={props.value}
        readOnly={!isEnabled}
        modules={isEnabledEditorActiveToolbar ? HTML_EDITOR_QUILL_MODULES : HTML_EDITOR_QUILL_MODULES_HIDE}
        formats={HTML_EDITOR_QUILL_FORMATS}
      />
    </div>
  );
};

HTMLEditorQuillForMatchReport.displayName = 'HTMLEditorQuillForMatchReport';
