//copy-paste from backend
export const PLAYER_PARTICIPATION_STATUS = {
  DONT_CARE: 'DONT_CARE', // don't want to send any invites. Default one
  INVITE_SENT: 'INVITE_SENT', // invite was sent but no decision made by user
  INVITE_ACCEPTED: 'INVITE_ACCEPTED', // invite was send and accepted
  INVITE_REJECTED: 'INVITE_REJECTED', // invite was send and rejected
  INVITE_CANCELED: 'INVITE_CANCELED', // invite was send and then canceled by sender
  USER_CANCELED: 'USER_CANCELED' // no invites involved, user just set that he can't take part
};

export const PLAYER_POSITIONS = {
  CAPTAIN: '(C)',
  SUB: '(S)'
};

export const AVAILABLE_USERS_EXTRA_TYPE = {
  PARENT: 'PARENT',
  PLAYER: 'PLAYER',
  STAFF: 'STAFF'
};

export const CLASHES = {
  FULL_CLASH: 'FULL_CLASH',
  INTERVAL_CLASH: 'INTERVAL_CLASH',
  NO_CLASH: 'NO_CLASH'
};

export const CLASHES_SERVER_TO_CLIENT_MAPPING = {
  FULL_CLASH: 'Full clash',
  INTERVAL_CLASH: 'Interval clash',
  NO_CLASH: 'No clash'
};

export const CLASHES_STATUS_COLOR = {
  NO_CLASH: '#03a84e',
  INTERVAL_CLASH: '#fbec08',
  FULL_CLASH: '#fb2f08',
  DEFAULT: '#ffffff'
};

// The results of the participants who either did not start or started, but did not reach or were disqualified
export const NON_FINISHED_PLAYER_RESULTS = {
  DNS: 'DNS',
  DNQ: 'DNQ',
  DNF: 'DNF'
};

export const NON_FINISHED_PLAYER_RESULTS_ARRAY = ['DNS', 'DNQ', 'DNF'];
