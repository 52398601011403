import * as React from 'react';
import * as BPromise from 'bluebird';
import * as Lazy from 'lazy.js';
import { Component } from 'react';
import './SchoolEventCopyManager.scss';
import { History, Location } from 'history';
import { AppUser } from 'Src/views/App/App';
import { Loader } from 'Src/components/Loader/Loader';
import { getAllForms } from 'Src/helpers/service/admin/forms';
import { SchoolEventCopyForm } from '../SchoolEventsCopyForm/SchoolEventCopyForm';
import { SchoolForm } from 'Src/models/form';
import { getEventParticipants, isHousesEvent, isInterSchoolsEvent, isNonTeamSportEvent } from 'Src/helpers/event/event';
import { activateEvent, createEvent } from 'Src/helpers/service/admin/event';
import { addSchoolEventTeam } from 'Src/helpers/service/admin/tournamentTeams';
import { getVenueTypeByPostcode } from 'Src/helpers/venue/venue';
import { getFromHistory } from 'Src/helpers/history/history';
import { getSchoolEvent } from 'Src/helpers/service/admin/event';
import {
  getSchoolEventDetails,
  getSchoolEventTeams,
  updateSchoolEventDetails
} from 'Src/helpers/service/admin/schoolEvents';
getVenueTypeByPostcode;
import { getSchoolsPublic } from 'Src/helpers/service/public/public';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from 'Src/consts/table';
import { SchoolEvent } from 'Src/models/event';
import { getHouses } from 'Src/helpers/service/admin/houses';
import { SchoolHouse } from 'Src/models/house';
import { updateSchoolEventIndividualsBatch } from 'Src/helpers/service/admin/schoolEventIndividuals';
import { EventDetail } from 'Src/models/eventDetail';
import { getDefaultPoint } from '../../../../../../helpers/venue/venue';

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

interface State {
  currentEvent: SchoolEvent;
  forms: SchoolForm[];
  isLoading: boolean;
  teams: any[];
  eventDetails: EventDetail;
  participants: any[];
  houses: SchoolHouse[];
}

export class SchoolEventCopyManager extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      currentEvent: undefined,
      forms: [],
      isLoading: false,
      teams: [],
      eventDetails: undefined,
      participants: [],
      houses: []
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setItems();
  }

  setItems() {
    const { user } = this.props;
    const eventId = this.getEventId();
    const eventPromise = getSchoolEvent(user, eventId);
    const formsPromise = getAllForms(user);
    const teamPromise = getSchoolEventTeams(user, eventId);
    const eventDetailsPromise = getSchoolEventDetails(user, eventId);

    const promises = [eventPromise, formsPromise, teamPromise, eventDetailsPromise];

    let filter;

    BPromise.all(promises).then(([event, forms, teams, eventDetails]) => {
      const allSchoolIds = event.invitedSchoolIds;
      filter = {
        where: { id: { $in: allSchoolIds } },
        view: {
          type: 'id_name_pic'
        },
        skip: DEFAULT_SKIP,
        limit: DEFAULT_LIMIT
      };
      getSchoolsPublic(filter).then(schools => {
        const participants = getEventParticipants(event, user, schools);

        if (isHousesEvent(event)) {
          const participantsHouseIds = event.houses;

          filter = {
            where: {
              id: {
                $in: participantsHouseIds
              }
            },
            limit: DEFAULT_LIMIT,
            skip: DEFAULT_SKIP
          };

          getHouses(user, filter).then(houses => {
            this.setState({
              participants: participants,
              houses: houses,
              forms: forms,
              eventDetails: eventDetails,
              teams: teams,
              currentEvent: event,
              isLoading: false
            });
          });
        } else {
          this.setState({
            participants: participants,
            forms: forms,
            eventDetails: eventDetails,
            teams,
            currentEvent: event,
            isLoading: false
          });
        }
      });
    });
  }

  getEventId(): string {
    const { history } = this.props;
    return getFromHistory(history, 'event');
  }

  onCancelClick = () => {
    const { history } = this.props;
    const { currentEvent } = this.state;
    history.push({
      pathname: '/events/event',
      search: `id=${currentEvent.id}`
    });
  };

  onCopyEvent = data => {
    const { participants, eventDetails, currentEvent } = this.state;
    const { user, history } = this.props;
    const isChangeAge = data.ages.sort().toString() !== currentEvent.ages.sort().toString();
    const isChangeGender = data.gender !== currentEvent.gender;

    this.setState({
      isLoading: true
    });

    let eventId;
    const body = this.onModifiedDataEvent(data, user);
    const { shortDescription, ...rest } = eventDetails;
    const detailsBody = {
      ...rest,
      shortDescription: data.shortDescription
    };

    const teams = participants.map(participant => {
      return isNonTeamSportEvent(data) ? participant.individualPlayers : participant.team;
    });
    const teamsFiltered = teams
      .filter(team => typeof team !== 'undefined' && team.schoolId === user.activeSchoolId)
      .map(team => {
        const { players, ...rest } = team;

        return {
          ...rest,
          players:
            isChangeAge || isChangeGender
              ? []
              : players.map(player => ({
                  userId: player.userId,
                  permissionId: player.permissionId
                }))
        };
      });

    if (isNonTeamSportEvent(data)) {
      createEvent(user, body)
        .then(event => {
          eventId = event.id;

          return activateEvent(user, eventId);
        })
        .then(res => {
          const players = Lazy(teams)
            .flatten()
            .toArray();
          return updateSchoolEventIndividualsBatch(user, eventId, {
            add: players.map(item => ({ userId: item.userId, permissionId: item.permissionId }))
          });
        })
        .then(res => {
          return updateSchoolEventDetails(user, eventId, detailsBody);
        })
        .then(event => {
          history.push({
            pathname: '/events/event',
            search: `id=${eventId}`
          });

          return event;
        });
    } else {
      createEvent(user, body)
        .then(event => {
          eventId = event.id;

          return activateEvent(user, eventId);
        })
        .then(res => {
          return BPromise.all(
            teamsFiltered.map(team => {
              return addSchoolEventTeam(user, eventId, team);
            })
          );
        })
        .then(res => {
          return updateSchoolEventDetails(user, eventId, detailsBody);
        })
        .then(res => {
          history.push({
            pathname: '/events/event',
            search: `id=${eventId}`
          });
          return true;
        });
    }
  };

  //copy-paste
  onModifiedDataEvent(currentEvent, user: AppUser) {
    const {
      gender,
      ages,
      eventType,
      sportId,
      startTime,
      endTime,
      venue,
      participants,
      houses,
      multiparty,
      shortDescription,
      photos,
      isStudentCanSubmitResult,
      isStudentCanJoin,
      leaguePositionsCount,
      replyToEmail
    } = currentEvent;
    const { activeSchool } = user;

    //Important! Modify seconds and milliseconds in start time for navigation
    const now = new Date();
    const nowS = now.getSeconds();
    const nowMs = now.getMilliseconds();
    startTime.setSeconds(nowS, nowMs);

    const body = {
      gender: gender,
      shortDescription: shortDescription,
      multiparty: multiparty,
      eventType: eventType,
      sportId: sportId,
      venue: venue,
      ages: ages.map(age => Number(age)),
      houses: houses.map(house => house.id),
      startTime: startTime.toISOString(),
      endTime: endTime.toISOString(),
      invitedSchoolIds: isInterSchoolsEvent(currentEvent)
        ? participants.map(participant => participant.id)
        : [user.activeSchoolId],
      photos: photos,
      isStudentCanSubmitResult: isStudentCanSubmitResult,
      isStudentCanJoin: isStudentCanJoin,
      leaguePositionsCount: Number(leaguePositionsCount),
      replyToEmail: replyToEmail
    };

    switch (true) {
      case typeof venue !== 'undefined' && typeof venue.point !== 'undefined' && venue.point.coordinates.length > 0:
        if (typeof venue.postcodeId === 'undefined') {
          body.venue.postcodeId = venue.id;
        }
        body.venue.venueType = getVenueTypeByPostcode(venue, activeSchool);
        break;
      case typeof venue !== 'undefined' && typeof venue.point === 'undefined':
        body.venue.venueType = getVenueTypeByPostcode(venue, activeSchool);
        break;
      case typeof venue !== 'undefined' && typeof venue.venueType !== 'undefined' && venue.venueType === 'TBD':
        body.venue.point = getDefaultPoint(user);
        break;
      default:
        delete body.venue;
    }
    if (typeof body.venue !== 'undefined') {
      delete body.venue.id;
      delete body.venue.isHome;
      delete body.venue.name;
      delete body.venue.postcode;
    }

    return body;
  }

  //return to initial state
  goBack = () => {
    this.setState({
      teams: []
    });
  };

  renderSchoolEventForm(): React.ReactNode {
    const { forms, currentEvent, participants, houses, eventDetails } = this.state;
    const { user } = this.props;
    const isExistSchoolEvent = typeof currentEvent !== 'undefined';
    if (isExistSchoolEvent) {
      return (
        <SchoolEventCopyForm
          schoolEvent={currentEvent}
          eventDetails={eventDetails}
          participants={participants}
          houses={houses}
          onSubmit={this.onCopyEvent}
          onCancel={this.onCancelClick}
          user={user}
          forms={forms}
        />
      );
    } else {
      return '';
    }
  }

  render() {
    const { isLoading } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    return (
      <div className="bSchoolEventManager">
        <h3 className="mb-3 d-flex">
          <span className="text-muted">Copy event</span>
        </h3>
        {this.renderSchoolEventForm()}
      </div>
    );
  }
}
