import { CLUB_STAFF } from '../../consts/club';
import * as propz from 'propz';
import * as Lazy from 'lazy.js';
import {
  EVENT_STATUS,
  EVENT_TABS,
  EVENT_TYPES,
  EVENT_TAB_ATTENDANCE,
  EVENT_TAB_PERFORMANCE,
  EVENT_VIEW_TABS,
  EVENT_PARTICIPANT_TEXT_NO_TEAM_MEMBERS,
  EVENT_PARTICIPANT_TEXT_EVENT_INVITE_REJECTED,
  EVENT_PARTICIPANT_TEXT_SELECT_TEAM_LATER,
  EVENT_PARTICIPANT_TEXT_MEMBERS_NOT_ADDED,
  EVENT_PARTICIPANT_TEXT_ACCEPTED_BY_OPPONENT,
  EVENT_PARTICIPANT_TEXT_AWAITING_OPPONENT,
  EVENT_CLUB_VIEW_TABS,
  EVENT_TAB_CLUB_PROGRESS,
  EVENT_CLUB_TABS,
  EVENT_RESULTS_MODE,
  STUDENT_EVENT_CLUB_VIEW_TABS,
  STUDENT_EVENT_VIEW_TABS,
  PARENT_EVENT_VIEW_TABS,
  PARENT_EVENT_CLUB_VIEW_TABS
} from '../../consts/event';
import {
  Participant,
  SchoolEvent,
  SchoolEventIndividualData,
  SchoolEventInvite,
  SchoolEventSchool,
  SchoolEventTeamDataPlayer
} from '../../models/event';
import { SCORING, TYPE_OF_PLAYERS } from '../../consts/sport';
import { AppUser } from 'Src/views/App/App';
import { AGE_GROUPS, AGE_GROUPS_SORTED, KIND } from '../../consts/school';
import { isIndividualTournament, isTeamTournament } from '../tournament/tournament';
import { PublicSchoolShort } from '../../models/school';
import { isInviteStatusAccepted, isInviteStatusNotReady, isInviteStatusRejected } from '../invite/invite';
import { SportPosition } from '../../models/sport';
import { NO_IMAGE, TABS } from '../../consts/common';
import {
  sortIndividualPlayers,
  sortIndividualPlayersByExtraPointsOrByScoreOrByLastName,
  sortPlayersByLastName,
  sortPlayersByLastNameAsc
} from '../players/players';
import {
  convertPointsToStringWithoutDelimeter,
  getExtraPointsByStudent,
  getHouseScoreByHouseId,
  getIndividualScoreByStudent,
  getSchoolScoreBySchoolId,
  getTeamScoreByTeamId
} from './schoolEventViewHelper';
import { sortByNameAsc, sortTeams } from '../team/team';
import { sortHouses } from '../house/house';
import { SchoolEventFromForm } from 'Src/views/GenericView/AdminView/TeamsAndEvents/Events/SchoolEventManager';
import { convertPointsCricket, isCricket } from '../sport/cricket';
import { EventSchoolInvite } from '../../models/invite';
import * as Moment from 'moment';
import {
  INDIVIDUAL_TOURNAMENT_DEFAULT_SCORING,
  TOURNAMENT_GENDER_SERVER_TO_CLIENT_MAPPING
} from '../../consts/tournament';
import { SchoolStudentClash } from '../../models/schoolStudent';
import {
  getSchoolStudentsWithClashes,
  getSchoolStudentsWithoutClashes,
  getSchoolTeamPlayersWithClashes,
  getSchoolTeamPlayersWithoutClashes
} from '../service/admin/schoolTeamPlayers';
import { SchoolEventTeamPlayerWithClashes } from '../../models/eventTeamPlayer';
import {
  getSchoolEventPlayersWithClashes,
  getSchoolEventPlayersWithoutClashes
} from '../service/admin/schoolEventTeamPlayers';
import {
  getSchoolEventIndividualsWithClashes,
  getSchoolEventIndividualsWithoutClashes
} from '../service/admin/schoolEventIndividuals';
import { SchoolEventIndividualWithClashes } from '../../models/eventIndividuals';
import { TeamPlayerWithClashes } from '../../models/team';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { updateEventRecord } from '../service/admin/event';

const DEFAULT_MULTIPARTY_TEAMS_LIMIT = 100;

export function getEventDetailsCoaches(eventDetail): any[] {
  const staff = propz.get(eventDetail, ['staff'], []);

  return staff
    .filter(singleStaff => singleStaff.staffRole === CLUB_STAFF.COACH)
    .map(singleStaff => {
      if (typeof singleStaff.id === 'undefined') {
        propz.set(singleStaff, ['id'], singleStaff.userId);
      }
      return singleStaff;
    });
}

export function getEventDetailsMembersOfStaff(eventDetail): any[] {
  const staff = propz.get(eventDetail, ['staff'], []);

  return staff
    .filter(singleStaff => singleStaff.staffRole === CLUB_STAFF.MEMBER_OF_STAFF)
    .map(singleStaff => {
      if (typeof singleStaff.id === 'undefined') {
        propz.set(singleStaff, ['id'], singleStaff.userId);
      }
      return singleStaff;
    });
}

//only for school, not SU
export function getEventPlayers(
  event: SchoolEvent,
  activeSchoolId: string
): (SchoolEventIndividualData | SchoolEventTeamDataPlayer)[] {
  const { teamsData, individualsData } = event;
  let players;

  if (isNonTeamSportEvent(event)) {
    if (isInterSchoolsEvent(event)) {
      players = individualsData.filter(p => p.schoolId === activeSchoolId).sort(sortPlayersByLastName);
    } else {
      players = [...individualsData].sort(sortPlayersByLastName);
    }
  } else {
    const allPlayers = Lazy(teamsData)
      .map(teamData => teamData.players)
      .flatten()
      .toArray();
    players = allPlayers.filter(player => player.schoolId === activeSchoolId).sort(sortPlayersByLastName);
  }

  return players;
}

export function getParentEventPlayers(
  event: SchoolEvent,
  childIdList: []
): (SchoolEventIndividualData | SchoolEventTeamDataPlayer)[] {
  const { teamsData, individualsData } = event;
  let players;

  if (isNonTeamSportEvent(event)) {
    players = individualsData
      .filter(p => childIdList.some(childId => p.userId === childId))
      .sort(sortPlayersByLastName);
  } else {
    const allPlayers = Lazy(teamsData)
      .map(teamData => teamData.players)
      .flatten()
      .toArray();
    players = allPlayers
      .filter(player => childIdList.some(childId => player.userId === childId))
      .sort(sortPlayersByLastName);
  }

  return players;
}

export function isClubEvent(event: SchoolEvent | SchoolEventFromForm): boolean {
  return typeof event.clubId !== 'undefined';
}

export function isTournamentEvent(event: SchoolEvent): boolean {
  return typeof event.tournamentId !== 'undefined';
}

export function isLeagueEvent(event: SchoolEvent): boolean {
  return typeof event.schoolLeagueId !== 'undefined';
}

export function isSchoolEvent(event): boolean {
  return !isClubEvent(event) && !isTournamentEvent(event);
}

export function isTeamOrTwoOnTwoSportEvent(event: SchoolEvent | SchoolEventFromForm): boolean {
  return isTeamSportEvent(event) || isTwoOnTwoSportEvent(event);
}

export function isTeamSportEvent(event: SchoolEvent | SchoolEventFromForm): boolean {
  const { sport } = event;
  return sport.players === TYPE_OF_PLAYERS.TEAM;
}

export function isIndividualSportEvent(event: SchoolEvent | SchoolEventFromForm | EventSchoolInvite): boolean {
  const { sport } = event;
  return sport.players === TYPE_OF_PLAYERS.INDIVIDUAL;
}

export function isOneOnOneSportEvent(event: SchoolEvent | SchoolEventFromForm | EventSchoolInvite): boolean {
  const { sport } = event;
  return sport.players === TYPE_OF_PLAYERS['1X1'];
}

export function isNonTeamSportEvent(event: SchoolEvent | SchoolEventFromForm | EventSchoolInvite): boolean {
  return isIndividualSportEvent(event) || isOneOnOneSportEvent(event);
}

export function isTwoOnTwoSportEvent(event: SchoolEvent | SchoolEventFromForm): boolean {
  const { sport } = event;
  return sport.players === TYPE_OF_PLAYERS['2X2'];
}

export function isMultipartyEvent(event: SchoolEvent | SchoolEventFromForm): boolean {
  return event.multiparty;
}

export function isInternalEventForIndividualSportEvent(event: SchoolEvent | SchoolEventFromForm): boolean {
  return isInternalEvent(event) && isIndividualSportEvent(event);
}

export function isInterSchoolsEvent(event: SchoolEvent | SchoolEventFromForm): boolean {
  const { eventType } = event;
  return eventType === EVENT_TYPES.EXTERNAL_SCHOOLS;
}

export function isHousesEvent(event: SchoolEvent | SchoolEventFromForm): boolean {
  const { eventType } = event;
  return eventType === EVENT_TYPES.INTERNAL_HOUSES;
}

export function isInternalEvent(event: SchoolEvent | SchoolEventFromForm): boolean {
  const { eventType } = event;
  return eventType === EVENT_TYPES.INTERNAL_TEAMS;
}

export function isInterSchoolsEventForTeamOrTwoOnTwoSportEvent(event: SchoolEvent): boolean {
  return isInterSchoolsEvent(event) && isTeamOrTwoOnTwoSportEvent(event);
}

export function isInternalEventForTeamOrTwoOnTwoSportEvent(event: SchoolEvent): boolean {
  return isInternalEvent(event) && isTeamOrTwoOnTwoSportEvent(event);
}

export function getSchoolEventAges(event: SchoolEvent | SchoolEventFromForm, user: AppUser): string {
  const { activeSchool } = user;
  const ageGroupsNaming = propz.get(activeSchool, ['ageGroupsNaming']);
  const { ages } = event;

  return ages.length > 0
    ? [...ages]
        .sort()
        .map(elem => propz.get(AGE_GROUPS, [ageGroupsNaming, elem]))
        .join(', ')
    : 'All ages';
}

export function isIndividualTournamentEvent(event: SchoolEvent): boolean {
  return isTournamentEvent(event) && isIndividualTournament(event.tournament);
}

export function isTeamTournamentEvent(event: SchoolEvent): boolean {
  return isTournamentEvent(event) && isTeamTournament(event.tournament);
}

export function isInviterSchool(event: SchoolEvent, schoolId: string): boolean {
  const inviterSchoolId = propz.get(event, ['inviterSchoolId']);
  return inviterSchoolId === schoolId;
}

export function isInvitedSchool(event: SchoolEvent, schoolId: string): boolean {
  const invitedSchoolIds = propz.get(event, ['invitedSchoolIds']);
  return invitedSchoolIds.some(invitedSchoolId => invitedSchoolId === schoolId);
}

export function isEventStatusRejected(event: SchoolEvent): boolean {
  const status = propz.get(event, ['status']);
  return status === EVENT_STATUS.REJECTED;
}

export function isEventStatusCanceled(event: SchoolEvent): boolean {
  const status = propz.get(event, ['status']);
  return status === EVENT_STATUS.CANCELED;
}

export function isEventStatusFinished(event: SchoolEvent): boolean {
  const status = propz.get(event, ['status']);
  return status === EVENT_STATUS.FINISHED;
}

export function isEventStatusInvitesSent(event: SchoolEvent): boolean {
  const status = propz.get(event, ['status']);
  return status === EVENT_STATUS.INVITES_SENT;
}

export function isEventStatusCollectingInviteResponse(event: SchoolEvent): boolean {
  const status = propz.get(event, ['status']);
  return status === EVENT_STATUS.COLLECTING_INVITE_RESPONSE;
}

export function isEventStatusAccepted(event: SchoolEvent): boolean {
  const status = propz.get(event, ['status']);
  return status === EVENT_STATUS.ACCEPTED;
}

export function getInviteForActiveSchool(event: SchoolEvent, schoolId: string): SchoolEventInvite {
  const invites = event.invites || [];

  return invites.find(invite => invite.invitedSchoolId === schoolId);
}

export function isTournamentEventReadOnly(event: SchoolEvent): boolean {
  const isReadOnly = propz.get(event, ['tournament', 'isReadOnly'], false);
  return isReadOnly;
}

export function isEventCreatorSchoolUnion(event: SchoolEvent): boolean {
  const kind = propz.get(event, ['inviterSchool', 'kind']);
  return kind === KIND.SCHOOL_UNION;
}

export function isSportNameContainSwimming(event: SchoolEvent): boolean {
  const sportName = propz.get(event, ['sport', 'name'], '');
  return sportName.toLowerCase().indexOf('swimming') !== -1;
}

export function isSportNameContainCrossCountryRunning(event: SchoolEvent): boolean {
  const sportName = propz.get(event, ['sport', 'name'], '');
  return sportName.toLowerCase().indexOf('cross-country running') !== -1;
}

export function isSportNameContainTriathlon(event: SchoolEvent): boolean {
  const sportName = propz.get(event, ['sport', 'name'], '');
  return sportName.toLowerCase().indexOf('triathlon') !== -1;
}

export function isNotExpiredEventTime(event: SchoolEvent): boolean {
  const today = new Date();
  const eventTime = new Date(event.startTime);

  const todayTimeStamp = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    today.getHours(),
    today.getMinutes()
  ).getTime();
  const eventTimeStamp = new Date(
    eventTime.getFullYear(),
    eventTime.getMonth(),
    eventTime.getDate(),
    eventTime.getHours(),
    eventTime.getMinutes()
  ).getTime();

  return todayTimeStamp <= eventTimeStamp;
}

function isAttendanceTabExist(event: SchoolEvent): boolean {
  return isSchoolEvent(event) || isClubEvent(event);
}

function isPerformanceTabExist(event: SchoolEvent): boolean {
  return event.sport.performance.length > 0;
}

function isDisciplineTabExist(event: SchoolEvent): boolean {
  return event.sport.discipline.length > 0;
}

export function getEventTabs(event: SchoolEvent): string[] {
  let tabs = isClubEvent(event) ? [...EVENT_CLUB_TABS] : [...EVENT_TABS];

  if (isAttendanceTabExist(event)) {
    tabs = [...tabs];
    tabs.splice(3, 0, EVENT_TAB_ATTENDANCE);
  }

  if (isPerformanceTabExist(event)) {
    tabs = [...tabs];
    const nameTabs = isClubEvent(event) ? EVENT_TAB_CLUB_PROGRESS : EVENT_TAB_PERFORMANCE;
    tabs.splice(5, 0, nameTabs);
  }

  if (isDisciplineTabExist && isIndividualSportEvent(event)) {
    tabs = [...tabs];
    const disciplineTabIndex = tabs.findIndex(tab => tab === TABS.DISCIPLINE);

    tabs.splice(disciplineTabIndex, 1);
  }

  return tabs;
}

export function getEventViewTabs(event: SchoolEvent, isExistReports?, isExistConsents?): string[] {
  let tabs = isClubEvent(event) ? [...EVENT_CLUB_VIEW_TABS] : [...EVENT_VIEW_TABS];

  if (isAttendanceTabExist(event)) {
    tabs = [...tabs];
    tabs.splice(3, 0, EVENT_TAB_ATTENDANCE);
  }

  if (isPerformanceTabExist(event)) {
    tabs = [...tabs];
    const nameTabs = isClubEvent(event) ? EVENT_TAB_CLUB_PROGRESS : EVENT_TAB_PERFORMANCE;
    tabs.splice(5, 0, nameTabs);
  }

  if (!isExistReports) {
    tabs = [...tabs];
    const reportTabIndex = tabs.findIndex(tab => tab === TABS.AVAILABILITY_REPORTS);

    tabs.splice(reportTabIndex, 1);
  }

  if (!isExistConsents) {
    tabs = [...tabs];
    const consentTabIndex = tabs.findIndex(tab => tab === TABS.PARENTAL_CONSENT);

    tabs.splice(consentTabIndex, 1);
  }

  if (isDisciplineTabExist(event)) {
    tabs = [...tabs];
    tabs.splice(4, 0, TABS.DISCIPLINE);
  }

  return tabs;
}

export function getStudentEventViewTabs(event: SchoolEvent, isExistReports?): string[] {
  let tabs = isClubEvent(event) ? [...STUDENT_EVENT_CLUB_VIEW_TABS] : [...STUDENT_EVENT_VIEW_TABS];

  if (isAttendanceTabExist(event)) {
    tabs = [...tabs];
    tabs.splice(3, 0, EVENT_TAB_ATTENDANCE);
  }

  if (isPerformanceTabExist(event)) {
    tabs = [...tabs];
    const nameTabs = isClubEvent(event) ? EVENT_TAB_CLUB_PROGRESS : EVENT_TAB_PERFORMANCE;
    tabs.splice(5, 0, nameTabs);
  }

  if (!isExistReports) {
    tabs = [...tabs];
    const reportTabIndex = tabs.findIndex(tab => tab === TABS.AVAILABILITY_REPORTS);

    tabs.splice(reportTabIndex, 1);
  }

  if (isDisciplineTabExist(event)) {
    tabs = [...tabs];
    tabs.splice(4, 0, TABS.DISCIPLINE);
  }

  return tabs;
}

export function getParentEventViewTabs(event: SchoolEvent, isExistReports?, isExistConsents?): string[] {
  let tabs = isClubEvent(event) ? [...PARENT_EVENT_CLUB_VIEW_TABS] : [...PARENT_EVENT_VIEW_TABS];

  if (isAttendanceTabExist(event)) {
    tabs = [...tabs];
    tabs.splice(3, 0, EVENT_TAB_ATTENDANCE);
  }

  if (isPerformanceTabExist(event)) {
    tabs = [...tabs];
    const nameTabs = isClubEvent(event) ? EVENT_TAB_CLUB_PROGRESS : EVENT_TAB_PERFORMANCE;
    tabs.splice(5, 0, nameTabs);
  }

  if (!isExistReports) {
    tabs = [...tabs];
    const reportTabIndex = tabs.findIndex(tab => tab === TABS.AVAILABILITY_REPORTS);

    tabs.splice(reportTabIndex, 1);
  }

  if (!isExistConsents) {
    tabs = [...tabs];
    const consentTabIndex = tabs.findIndex(tab => tab === TABS.PARENTAL_CONSENT);

    tabs.splice(consentTabIndex, 1);
  }

  if (isDisciplineTabExist(event)) {
    tabs = [...tabs];
    tabs.splice(4, 0, TABS.DISCIPLINE);
  }

  return tabs;
}

export function getEventParticipants(event: SchoolEvent, user: AppUser, schools: PublicSchoolShort[]): Participant[] {
  switch (true) {
    case isTeamOrTwoOnTwoSportEvent(event):
      return getTeamEventParticipants(event, user, schools);
    case isNonTeamSportEvent(event):
      return getIndividualEventParticipants(event, user, schools);
    default:
      console.error('Type of event incorrect');
      return [];
  }
}

export function getTeamEventParticipants(
  event: SchoolEvent,
  user: AppUser,
  schools: PublicSchoolShort[]
): Participant[] {
  switch (true) {
    case isInterSchoolsEvent(event):
      return getInterSchoolsTeamEventParticipants(event, user, schools);
    case isHousesEvent(event):
      return getHousesTeamEventParticipants(event, user, schools);
    case isInternalEvent(event):
      return getInternalTeamEventParticipants(event, user, schools);
    default:
      console.error('Event type not defined');
      return [];
  }
}

export function getIndividualEventParticipants(event: SchoolEvent, user: AppUser, schools: PublicSchoolShort[]): any[] {
  switch (true) {
    case isInterSchoolsEvent(event):
      return getInterSchoolsIndividualEventParticipants(event, user, schools);
    case isHousesEvent(event):
      return getHousesIndividualEventParticipants(event, user, schools);
    case isInternalEvent(event):
      return getInternalIndividualEventParticipants(event, user, schools);
    default:
      console.error('Event type not defined');
      return [];
  }
}

function getSchoolFromEventBySchoolId(event: SchoolEvent, schoolId: string): SchoolEventSchool {
  const { inviterSchool, invitedSchools } = event;
  return [inviterSchool, ...invitedSchools].find(school => school.id === schoolId);
}

function filterSchoolUnionWhoCreateEvent(event: SchoolEvent, schools: PublicSchoolShort[]): PublicSchoolShort[] {
  const { eventCreatorId } = event;

  return schools.filter(school => {
    const schoolFound = getSchoolFromEventBySchoolId(event, school.id);
    const isSchoolFoundKindSchoolUnion = schoolFound.kind === KIND.SCHOOL_UNION;
    const isSchoolFoundEventCreator = school.id === eventCreatorId;

    return !(isSchoolFoundKindSchoolUnion && isSchoolFoundEventCreator);
  });
}

export function getInterSchoolsTeamEventParticipants(
  event: SchoolEvent,
  user: AppUser,
  schools?: PublicSchoolShort[]
): Participant[] {
  const { teamsData, invites } = event;
  let participants = [];

  const isEventFinished = isEventStatusFinished(event);
  const schoolsSorted = getSortedSchoolsFromEvent(user, event);

  schoolsSorted.forEach(school => {
    const teams = teamsData.filter(team => team.schoolId === school.id);
    const invite = [...invites]
      .sort((invite1, invite2) => {
        const inviteCreatedDate1 = new Date(invite1.createdAt);
        const inviteCreatedDate2 = new Date(invite2.createdAt);
        return Number(inviteCreatedDate2) - Number(inviteCreatedDate1);
      })
      .find(invite => invite.invitedSchoolId === school.id);
    const isTeamsExist = teams.length > 0;
    if (!isTeamsExist) {
      participants.push({
        team: undefined,
        school: school,
        invite: invite
      });
    } else {
      // school can have many teams
      [...teams].sort(sortByNameAsc).forEach(team => {
        participants.push({
          team: team,
          school: school,
          invite: invite,
          teamPlayers: [...team.players].sort(sortPlayersByLastNameAsc)
        });
      });
    }
  });

  if (isEventFinished && isMultipartyEvent(event)) {
    return [...participants].sort((participant1, participant2) => {
      const isTeamExist1 = typeof participant1.team !== 'undefined';
      const isTeamExist2 = typeof participant2.team !== 'undefined';

      const score1 = isTeamExist1
        ? getTeamScoreByTeamId(event, participant1.team.id)
        : getSchoolScoreBySchoolId(event, participant1.school.id);
      const score2 = isTeamExist2
        ? getTeamScoreByTeamId(event, participant2.team.id)
        : getSchoolScoreBySchoolId(event, participant2.school.id);

      const isMultiparty = isMultipartyEvent(event);
      if (isMultiparty) {
        const isPlaces = isResultsModePlaces(event);
        const isPoints = isResultsModePoints(event);
        switch (true) {
          case isPlaces:
            return score1 - score2;
          case isPoints:
            return score2 - score1;
          default:
            console.error('Can not find results mode');
            return 0;
        }
      }
    });
  }

  return participants;
}
export function getHousesTeamEventParticipants(
  event: SchoolEvent,
  user: AppUser,
  schools: PublicSchoolShort[]
): Participant[] {
  const { housesData, teamsData } = event;

  const [school] = schools;

  const participants = housesData
    .sort((house1, house2) => sortHouses(event, house1, house2))
    .map(house => {
      const { id } = house;
      const team = teamsData.find(team => team.houseId === id);
      const isTeamExist = typeof team !== 'undefined';

      if (isTeamExist) {
        return {
          team: team,
          invite: undefined,
          house: house,
          school: school,
          teamPlayers: [...team.players].sort(sortPlayersByLastNameAsc)
        };
      } else {
        return {
          team: undefined,
          invite: undefined,
          house: house,
          school: school
        };
      }
    });

  return participants;
}
export function getInternalTeamEventParticipants(
  event: SchoolEvent,
  user: AppUser,
  schools: PublicSchoolShort[]
): Participant[] {
  const { teamsData } = event;

  const [school] = schools;

  const participants = [...teamsData]
    .sort((team1, team2) => sortTeams(event, team1, team2))
    .map(team => {
      return {
        team: team,
        teamPlayers: [...team.players].sort(sortPlayersByLastNameAsc),
        invite: undefined,
        school: school
      };
    });

  return participants;
}

export function getInterSchoolsIndividualEventParticipants(
  event: SchoolEvent,
  user: AppUser,
  schools?: PublicSchoolShort[]
): Participant[] {
  let participants = [];

  const schoolsSorted = getSortedSchoolsFromEvent(user, event);

  const { individualsData, players, invites } = event;

  schoolsSorted.forEach(school => {
    const invite = [...invites]
      .sort((invite1, invite2) => {
        const inviteCreatedDate1 = new Date(invite1.createdAt);
        const inviteCreatedDate2 = new Date(invite2.createdAt);
        return Number(inviteCreatedDate2) - Number(inviteCreatedDate1);
      })
      .find(invite => invite.invitedSchoolId === school.id || invite.inviterSchoolId === school.id);

    let individualPlayers: SchoolEventIndividualData[] = [];

    const schoolFound = getSchoolFromEventBySchoolId(event, school.id);
    const schoolIds = propz.get(schoolFound, ['schoolIds']);

    individualsData.forEach(individual => {
      // isRemovedFromSU - temp backend solution only for public event routs
      // TO DO fix it (back + front)
      const { isRemovedFromSU } = individual;

      // event.individualsData  contains schoolId - id of player school and personal player data
      // event.players          contains schoolId - id of union includes player school (or included in past)
      // solution includes players from schools removed from union
      const schoolPlayer = players.find(player => {
        return player.userId === individual.userId && player.schoolId === school.id;
      });

      const isSchoolPlayerExist = typeof schoolPlayer !== 'undefined';

      if (isSchoolPlayerExist || isRemovedFromSU) {
        individualPlayers.push(individual);
      }
    });

    participants.push({
      team: undefined,
      school: school,
      invite: invite,
      individualPlayers: getPlayersSorted(event, individualPlayers)
    });
  });

  return participants;
}

export function getPlayersSorted(
  event: SchoolEvent,
  individualPlayers: SchoolEventIndividualData[]
): SchoolEventIndividualData[] {
  const playersWithScoreOrExtraPoints = individualPlayers.filter(individualPlayer => {
    const score = getIndividualScoreByStudent(event, individualPlayer.userId, individualPlayer.permissionId);
    const extraPoints = getExtraPointsByStudent(event, individualPlayer.userId);
    return score !== 0 || extraPoints !== 0;
  });

  const playersWithOutScoreAndExtraPoints = individualPlayers.filter(individualPlayer => {
    const score = getIndividualScoreByStudent(event, individualPlayer.userId, individualPlayer.permissionId);
    const extraPoints = getExtraPointsByStudent(event, individualPlayer.userId);
    return score === 0 && extraPoints === 0;
  });

  const playersWithScoreSorted = [...playersWithScoreOrExtraPoints].sort((player1, player2) =>
    sortIndividualPlayersByExtraPointsOrByScoreOrByLastName(event, player1, player2)
  );

  const playersWithOutScoreSorted = [...playersWithOutScoreAndExtraPoints].sort((player1, player2) =>
    sortPlayersByLastNameAsc(player1, player2)
  );

  return [...playersWithScoreSorted, ...playersWithOutScoreSorted];
}

export function getPlayersSortedExtraPointsView(
  event: SchoolEvent,
  individualPlayers: SchoolEventIndividualData[]
): SchoolEventIndividualData[] {
  const playersWithScoreOrExtraPoints = individualPlayers.filter(individualPlayer => {
    const score = getIndividualScoreByStudent(event, individualPlayer.userId, individualPlayer.permissionId);
    const extraPoints = getExtraPointsByStudent(event, individualPlayer.userId);
    return score !== 0 || extraPoints !== 0;
  });

  const playersWithOutScoreAndExtraPoints = individualPlayers.filter(individualPlayer => {
    const score = getIndividualScoreByStudent(event, individualPlayer.userId, individualPlayer.permissionId);
    const extraPoints = getExtraPointsByStudent(event, individualPlayer.userId);
    return score === 0 && extraPoints === 0;
  });

  const playersWithScoreSorted = [...playersWithScoreOrExtraPoints].sort((player1, player2) =>
    sortIndividualPlayers(event, player1, player2)
  );

  const playersWithOutScoreSorted = [...playersWithOutScoreAndExtraPoints].sort((player1, player2) =>
    sortPlayersByLastNameAsc(player1, player2)
  );

  return [...playersWithScoreSorted, ...playersWithOutScoreSorted];
}

export function sortAutoEnrollmentTeamsSettingsByAge(settings, ageGroupsNaming) {
  return settings.sort((prevSettings, nextSettings) => {
    const { age: prevAge } = prevSettings;
    const { age: nextAge } = nextSettings;

    return sortAges(prevAge, nextAge, ageGroupsNaming);
  });
}

export function sortTournamentEventsByAge(events, ageGroupsNaming) {
  return events.sort((prevEvent, nextEvent) => {
    const { ages: prevEventAges } = prevEvent;
    const { ages: nextEventAges } = nextEvent;

    const sortedPrevEventAges: number[] = prevEventAges.sort((prev, next) => {
      return sortAges(prev, next, ageGroupsNaming);
    });
    const sortedNextEventAges: number[] = nextEventAges.sort((prev, next) => {
      return sortAges(prev, next, ageGroupsNaming);
    });

    const prevEventMinAge = sortedPrevEventAges[0];
    const nextEventMinAge = sortedNextEventAges[0];

    return sortAges(prevEventMinAge, nextEventMinAge, ageGroupsNaming);
  });
}

export function sortAges(prev: number, next: number, ageGroupsNaming: string): number {
  const sortedAgeGroup: string[] = propz.get(AGE_GROUPS_SORTED, [ageGroupsNaming]);
  const ageGroup: string[] = propz.get(AGE_GROUPS, [ageGroupsNaming]);

  const prevAge: string = ageGroup[prev];
  const nextAge: string = ageGroup[next];

  const ageIndex1: number = sortedAgeGroup.indexOf(prevAge);
  const ageIndex2: number = sortedAgeGroup.indexOf(nextAge);

  return ageIndex1 - ageIndex2;
}

export function getHousesIndividualEventParticipants(
  event: SchoolEvent,
  user: AppUser,
  schools: PublicSchoolShort[]
): Participant[] {
  const [school] = schools;

  const { housesData, individualsData } = event;

  const participants = housesData
    .map(house => {
      let individualPlayers = [];

      individualsData.forEach(individual => {
        const isHouseIdEqualIndividualHouseId = individual.houseId === house.id;

        if (isHouseIdEqualIndividualHouseId) {
          individualPlayers.push(individual);
        }
      });

      return {
        school: school,
        house: house,
        individualPlayers: getPlayersSorted(event, individualPlayers),
        team: undefined,
        invite: undefined
      };
    })
    .sort((participant1, participant2) => {
      if (participant1.house.name < participant2.house.name) {
        return -1;
      }

      if (participant1.house.name > participant2.house.name) {
        return 1;
      }

      return 0;
    });

  return participants;
}
export function getInternalIndividualEventParticipants(
  event: SchoolEvent,
  user: AppUser,
  schools: PublicSchoolShort[]
): Participant[] {
  const [school] = schools;

  const { individualsData } = event;

  const individualPlayers = [...individualsData];

  return [
    {
      school: school,
      house: undefined,
      individualPlayers: getPlayersSorted(event, individualPlayers),
      team: undefined,
      invite: undefined
    }
  ];
}

export function getAllSchoolIdsFromEvent(event: SchoolEvent): string[] {
  const { inviterSchoolId, invitedSchoolIds } = event;

  return Lazy([])
    .concat(inviterSchoolId, invitedSchoolIds)
    .uniq()
    .toArray();
}

export function getParticipantName(event: SchoolEvent, participant: Participant): string {
  const schoolName = getParticipantSchoolName(participant);
  const isSchoolNameExist = typeof schoolName !== 'undefined';

  const houseName = getHouseEventParticipantHouseName(participant);
  const isHouseNameExist = typeof houseName !== 'undefined';

  const teamName = getTeamEventParticipantTeamName(participant);
  const isTeamNameExist = typeof teamName !== 'undefined';

  switch (true) {
    case isClubEvent(event):
      return schoolName;
    case isTeamNameExist && isHouseNameExist:
      return `${teamName} / ${houseName}`;
    case isHouseNameExist:
      return houseName;
    case isTeamNameExist && isSchoolNameExist:
      return `${teamName} / ${schoolName}`;
    case isTeamNameExist:
      return teamName;
    //select team later
    case isSchoolNameExist:
      return schoolName;
    default:
      console.error('Can not find school name and team name');
      return '';
  }
}

export function getHouseColors(participant: Participant): string[] {
  return participant.house ? participant.house.colors : [];
}

export function getHouseEventParticipantHouseName(participant: Participant): string {
  return propz.get<string>(participant, ['house', 'name']);
}

export function getTeamEventParticipantTeamName(participant: Participant): string {
  return propz.get<string>(participant, ['team', 'name']);
}
export function getParticipantSchoolName(participant: Participant): string {
  return propz.get<string>(participant, ['school', 'name']);
}

export function getParticipantImg(participant: Participant): string {
  const houseImg = propz.get<string>(participant, ['house', 'pic']);
  const schoolImg = propz.get<string>(participant, ['school', 'pic']);

  const isHouseImgExist = typeof houseImg !== 'undefined';
  const isSchoolImgExist = typeof schoolImg !== 'undefined';

  switch (true) {
    case isHouseImgExist:
      return houseImg;
    case isSchoolImgExist:
      return schoolImg;
    default:
      return NO_IMAGE;
  }
}

//TODO_006 need refactoring
export function getParticipantText(event: SchoolEvent, participant: Participant, user: AppUser): string {
  const { activeSchoolId } = user;
  const { invite, individualPlayers, teamPlayers, school } = participant;
  if (typeof school !== 'undefined') {
    const { id: participantSchoolId } = school;
    const isActiveSchoolIdEqualParticipantSchoolId = activeSchoolId === participantSchoolId;

    const isTeamPlayersExists = typeof teamPlayers !== 'undefined';
    const isTeamPlayersEmpty = isTeamPlayersExists && teamPlayers.length === 0;

    const isIndividualPlayersExists = typeof individualPlayers !== 'undefined';
    const isIndividualPlayersEmpty = isIndividualPlayersExists && individualPlayers.length === 0;

    const isPlayersExist =
      (isTeamPlayersExists && !isTeamPlayersEmpty) || (isIndividualPlayersExists && !isIndividualPlayersEmpty);

    const isParticipantInviteExist = typeof invite !== 'undefined';
    const isParticipantInviter = event.inviterSchoolId === participantSchoolId;

    //If participant is activeSchool
    if (isActiveSchoolIdEqualParticipantSchoolId) {
      switch (true) {
        case !isTeamPlayersExists && !isIndividualPlayersExists && isEventStatusFinished(event):
        case !isTeamPlayersExists && isIndividualPlayersEmpty && isEventStatusFinished(event):
        case isTeamPlayersExists && isTeamPlayersEmpty && isEventStatusFinished(event):
        case !isPlayersExist && isEventStatusCanceled(event):
          return EVENT_PARTICIPANT_TEXT_NO_TEAM_MEMBERS;

        case isInviteStatusRejected(invite):
          return EVENT_PARTICIPANT_TEXT_EVENT_INVITE_REJECTED;

        // Select team later
        case !isTeamPlayersExists && !isIndividualPlayersExists:
        case !isTeamPlayersExists && isIndividualPlayersEmpty:
          return EVENT_PARTICIPANT_TEXT_SELECT_TEAM_LATER;

        // Team was set, but empty (only team name filled)
        case isTeamPlayersExists && isTeamPlayersEmpty:
          return EVENT_PARTICIPANT_TEXT_MEMBERS_NOT_ADDED;

        default:
          return '';
      }
    } else {
      switch (true) {
        case !isTeamPlayersExists && isIndividualPlayersEmpty && isEventStatusFinished(event):
        case !isPlayersExist && isEventStatusCanceled(event):
          return EVENT_PARTICIPANT_TEXT_NO_TEAM_MEMBERS;

        case !isParticipantInviteExist && isEventStatusAccepted(event) && !isParticipantInviter:
        case isTeamPlayersEmpty && isInviteStatusAccepted(invite):
        case !isTeamPlayersExists && isInviteStatusAccepted(invite) && !isIndividualPlayersExists:
        case !isTeamPlayersExists && isInviteStatusAccepted(invite) && isIndividualPlayersEmpty:
          return EVENT_PARTICIPANT_TEXT_ACCEPTED_BY_OPPONENT;

        case !isParticipantInviteExist && !isParticipantInviter:
          return EVENT_PARTICIPANT_TEXT_AWAITING_OPPONENT;

        case isInviteStatusRejected(invite):
          return EVENT_PARTICIPANT_TEXT_EVENT_INVITE_REJECTED;

        case isInviteStatusNotReady(invite):
          return EVENT_PARTICIPANT_TEXT_AWAITING_OPPONENT;

        default:
          return '';
      }
    }
  } else {
    return '';
  }
}

export function getPositionNameById(positionId: string, event: SchoolEvent): string {
  const positions: SportPosition[] = propz.get(event, ['sport', 'field', 'positions'], []);
  const position = positions.find(p => p._id === positionId);
  return propz.get<string>(position, ['name'], '');
}

export function isResultsModeResults(event: SchoolEvent): boolean {
  const resultsMode = propz.get(event, ['resultsMode'], EVENT_RESULTS_MODE.RESULTS);
  return resultsMode === EVENT_RESULTS_MODE.RESULTS;
}

export function isResultsModePlaces(event: SchoolEvent): boolean {
  const resultsMode = propz.get(event, ['resultsMode'], EVENT_RESULTS_MODE.RESULTS);
  return resultsMode === EVENT_RESULTS_MODE.PLACES;
}

export function isResultsModePoints(event: SchoolEvent): boolean {
  const resultsMode = propz.get(event, ['resultsMode'], EVENT_RESULTS_MODE.RESULTS);
  return resultsMode === EVENT_RESULTS_MODE.POINTS;
}

export function getMinTeams(event: SchoolEventFromForm) {
  if (typeof event === 'undefined') {
    return 0;
  }

  switch (true) {
    case isInterSchoolsEvent(event):
    case isInternalEventForIndividualSportEvent(event):
      return 1;
    default:
      return 2;
  }
}

export function getMaxTeams(event: SchoolEventFromForm) {
  if (typeof event === 'undefined') {
    return 0;
  }

  const { multiparty, eventType, houses } = event;
  const isEventTypeExternalSchools = eventType === EVENT_TYPES.EXTERNAL_SCHOOLS;
  switch (true) {
    case multiparty && isHousesEvent(event):
      return houses.length;
    case multiparty && !isHousesEvent(event) && isTeamOrTwoOnTwoSportEvent(event):
      return DEFAULT_MULTIPARTY_TEAMS_LIMIT;
    case isEventTypeExternalSchools:
    case isInternalEventForIndividualSportEvent(event):
      return 1;
    default:
      return 2;
  }
}

export function getParticipantTeamResult(event: SchoolEvent, participant: Participant): string {
  let result;
  switch (true) {
    case isCricket(event) && !isMultipartyEvent(event) && isInternalEvent(event): {
      const score = getTeamScoreByTeamId(event, participant.team.id);
      const { runs, wickets } = convertPointsCricket(score);
      result = `Runs: ${runs} / Wickets: ${wickets}`;
      break;
    }

    case isCricket(event) && !isMultipartyEvent(event) && isHousesEvent(event): {
      const score =
        typeof participant.team !== 'undefined'
          ? getTeamScoreByTeamId(event, participant.team.id)
          : getHouseScoreByHouseId(event, participant.house.id);
      const { runs, wickets } = convertPointsCricket(score);
      result = `Runs: ${runs} / Wickets: ${wickets}`;
      break;
    }

    case isCricket(event) && !isMultipartyEvent(event) && isInterSchoolsEvent(event): {
      const score =
        typeof participant.team !== 'undefined'
          ? getTeamScoreByTeamId(event, participant.team.id)
          : getSchoolScoreBySchoolId(event, participant.school.id);
      const { runs, wickets } = convertPointsCricket(score);
      result = `Runs: ${runs} / Wickets: ${wickets}`;
      break;
    }

    case isInternalEvent(event):
      result = convertPointsToStringWithoutDelimeter(event, getTeamScoreByTeamId(event, participant.team.id));
      break;
    case isHousesEvent(event):
      result =
        typeof participant.team !== 'undefined'
          ? convertPointsToStringWithoutDelimeter(event, getTeamScoreByTeamId(event, participant.team.id))
          : convertPointsToStringWithoutDelimeter(event, getHouseScoreByHouseId(event, participant.house.id));
      break;
    case isInterSchoolsEvent(event):
      result =
        typeof participant.team !== 'undefined'
          ? convertPointsToStringWithoutDelimeter(event, getTeamScoreByTeamId(event, participant.team.id))
          : convertPointsToStringWithoutDelimeter(event, getSchoolScoreBySchoolId(event, participant.school.id));
      break;
    default:
      console.error('Can not find event type');
  }

  return String(result);
}

export function getParticipantTeamResultNumber(event: SchoolEvent, participant: Participant): number {
  let result;
  switch (true) {
    case isInternalEvent(event):
      result = getTeamScoreByTeamId(event, participant.team.id);
      break;
    case isHousesEvent(event):
      result =
        typeof participant.team !== 'undefined'
          ? getTeamScoreByTeamId(event, participant.team.id)
          : getHouseScoreByHouseId(event, participant.house.id);
      break;
    case isInterSchoolsEvent(event):
      result =
        typeof participant.team !== 'undefined'
          ? getTeamScoreByTeamId(event, participant.team.id)
          : getSchoolScoreBySchoolId(event, participant.school.id);
      break;
    default:
      console.error('Can not find event type');
  }

  return result;
}

export function isShowIndividualScoreForTeam(event: SchoolEvent, players: SchoolEventTeamDataPlayer[]): boolean {
  const isPlayersExist = players.length > 0;

  if (!isPlayersExist) {
    return false;
  }

  const [player] = players;
  const { teamId } = player;

  const team = event.teamsData.find(teamData => teamData.id === teamId);
  const teamResults = event.results.teamScore.find(score => score.teamId === teamId);
  const isTeamResults = typeof teamResults !== 'undefined';
  const { players: teamPlayers } = team;
  const isPlayersResults = event.results.individualScore.some(score =>
    teamPlayers.some(teamPlayer => {
      const { userId, permissionId } = teamPlayer;
      const { userId: scoreUserId, permissionId: scorePermissionId, score: playerScore } = score;
      return scoreUserId === userId && scorePermissionId === permissionId && playerScore !== 0;
    })
  );

  return isTeamResults && isPlayersResults;
}

export function getSortedSchoolsFromEvent(user: AppUser, event: SchoolEvent): SchoolEventSchool[] {
  const { invitedSchools, inviterSchool, eventCreatorId } = event;
  const schoolsData = [...invitedSchools, inviterSchool];
  const { activeSchoolId } = user;
  const activeSchool = schoolsData.find(school => school.id === activeSchoolId);
  const restSchools = [...schoolsData].filter(school => school.id !== activeSchoolId).sort(sortByNameAsc);
  const schools = typeof activeSchool !== 'undefined' ? [activeSchool, ...restSchools] : restSchools;
  const schoolsSorted = schools.filter(school => {
    const isSchoolFoundKindSchoolUnion = school.kind === KIND.SCHOOL_UNION;
    const isSchoolFoundEventCreator = school.id === eventCreatorId;

    return !(isSchoolFoundKindSchoolUnion && isSchoolFoundEventCreator);
  });

  return schoolsSorted;
}

export function getEventTitle(event: SchoolEvent, user: AppUser): string {
  const { activeSchoolId } = user;
  const { gender, startTime, endTime } = event;

  const eventName = propz.get(event, ['generatedNames', activeSchoolId], '');
  const eventStartDateTime = Moment(startTime).format('DD.MM.YY HH:mm');
  const eventEndTime = Moment(endTime).format('HH:mm');
  const eventGender = TOURNAMENT_GENDER_SERVER_TO_CLIENT_MAPPING[gender];
  const eventAges = getSchoolEventAges(event, user);

  return `${eventGender} ${eventAges} ${eventStartDateTime} - ${eventEndTime} ${eventName}`;
}

export function getEventClash(
  event: SchoolEvent,
  fullClashEventIds: string[],
  intervalClashEventIds: string[]
): string {
  const isFullClash = fullClashEventIds.some(eventId => eventId === event.id);
  const isIntervalClash = intervalClashEventIds.some(eventId => eventId === event.id);

  let clash = '';

  switch (true) {
    case isFullClash:
      clash = '[Full overlap]';
      break;
    case isIntervalClash:
      clash = '[Close overlap]';
      break;
  }

  return clash;
}

export function getStudentsMethod(user: AppUser): (user: AppUser, filter: any) => Promise<SchoolStudentClash[]> {
  const { activeSchool } = user;
  const { isShowStudentEventClashes } = activeSchool;
  return isShowStudentEventClashes ? getSchoolStudentsWithClashes : getSchoolStudentsWithoutClashes;
}

export function getTeamPlayersMethod(
  user: AppUser
): (user: AppUser, eventId: string, teamId: string, filter: any) => Promise<SchoolEventTeamPlayerWithClashes[]> {
  const { activeSchool } = user;
  const { isShowStudentEventClashes } = activeSchool;
  return isShowStudentEventClashes ? getSchoolEventPlayersWithClashes : getSchoolEventPlayersWithoutClashes;
}

export function getIndividualsMethod(
  user: AppUser
): (user: AppUser, eventId: string, filter?: any) => Promise<SchoolEventIndividualWithClashes[]> {
  const { activeSchool } = user;
  const { isShowStudentEventClashes } = activeSchool;
  return isShowStudentEventClashes ? getSchoolEventIndividualsWithClashes : getSchoolEventIndividualsWithoutClashes;
}

export function getPrototypeTeamPlayersMethod(
  user: AppUser
): (user: AppUser, eventId: string, filter?: any) => Promise<TeamPlayerWithClashes[]> {
  const { activeSchool } = user;
  const { isShowStudentEventClashes } = activeSchool;
  return isShowStudentEventClashes ? getSchoolTeamPlayersWithClashes : getSchoolTeamPlayersWithoutClashes;
}

export function sortByScore(event: SchoolEvent, scores) {
  const scoring = event.sport.scoring;
  const isMoreScores = scoring === SCORING.MORE_SCORES;
  const isMoreTime = scoring === SCORING.MORE_TIME;
  const isMoreResult = scoring === SCORING.MORE_RESULT;
  const isFirstToNPoints = scoring === SCORING.FIRST_TO_N_POINTS;

  //Depending on the sport, we change the order of sorting the results of players (desc or asc)
  const sortDesc = isMoreScores || isMoreTime || isMoreResult || isFirstToNPoints;

  return [...scores]
    .sort((score1, score2) => (sortDesc ? score2.score - score1.score : score1.score - score2.score))
    .map(score => ({ ...score }));
}

export function setExtraScores(scores, event: SchoolEvent) {
  const tournamentScoringObj = propz.get(event, ['tournament', 'scoring'], []);
  const tournamentScoring = tournamentScoringObj.map(tournamentScoring => tournamentScoring.score);
  const scoring: number[] = tournamentScoring.length > 0 ? tournamentScoring : INDIVIDUAL_TOURNAMENT_DEFAULT_SCORING;

  const scoresCopy = scores.map(score => ({ ...score }));

  let scoringIndex = 0;

  //set extra score for all scores
  for (let index = 0; index < scoresCopy.length; index++) {
    const prevExtraPoint = propz.get(scoresCopy, [index - 1, 'richScore', 'points']);
    const prevScore = propz.get(scoresCopy, [index - 1, 'score']);
    const currScore = propz.get(scoresCopy, [index, 'score']);

    switch (true) {
      //crutch for do not finish, disqualified schools
      //so schools extra points set 0
      case currScore === 0:
        propz.set(scoresCopy, [index, 'richScore', 'points'], 0);
        break;

      case index === 0:
        propz.set(scoresCopy, [index, 'richScore', 'points'], scoring[scoringIndex]);
        scoringIndex++;
        break;

      case currScore === prevScore:
        propz.set(scoresCopy, [index, 'richScore', 'points'], prevExtraPoint);
        break;

      case index < scoresCopy.length:
        propz.set(
          scoresCopy,
          [index, 'richScore', 'points'],
          typeof scoring[scoringIndex] !== 'undefined' ? scoring[scoringIndex] : 0
        );
        scoringIndex++;
        break;

      default:
        propz.set(scoresCopy, [index, 'richScore', 'points'], 0);
        break;
    }
  }

  return scoresCopy;
}

export function getUserDetails(userId, permissionId, event, activeSchoolId) {
  const individual = event.individualsData.find(ind => ind.userId === userId && ind.permissionId === permissionId);

  if (!individual) {
    return {};
  }

  const activeSchool = event.invitedSchools.find(school => school.id === activeSchoolId);
  const isActiveSchoolUnion = propz.get(activeSchool, ['kind']) === 'SCHOOL_UNION';
  const isIndividualInActiveSchool = isActiveSchoolUnion
    ? activeSchool.schoolIds.some(id => id === individual.schoolId)
    : individual.schoolId === activeSchoolId;

  if (!isIndividualInActiveSchool) {
    return {};
  }

  const fullName = `${individual.firstName} ${individual.lastName}`;
  const { gender, teamId, schoolName } = individual;
  return { fullName, gender, teamId, schoolName };
}
