import axios from 'axios';
import { AppUser } from 'Src/views/App/App';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from 'Src/consts/table';
import { Count } from '../../../models/count';
import { Sport } from '../../../models/sport';
import { SchoolEvent } from '../../../models/event';
import { Club } from '../../../models/club';

const DEFAULT_SCHOOL_CLUB_EVENTS_ORDER = 'startTime ASC';

const DEFAULT_FILTER: any = {
  limit: DEFAULT_LIMIT,
  skip: DEFAULT_SKIP,
  order: DEFAULT_SCHOOL_CLUB_EVENTS_ORDER
};

interface Filter {
  where?: any;
  limit?: number;
  skip?: number;
  order?: string;
}

function getDefaultClubEventsFilter(clubId: string) {
  return {
    clubId: clubId,
    _isRemoved: false
  };
}

const DEFAULT_CLUB_EVENTS_ORDER = 'startTime ASC';

export function getClub(user: AppUser, clubId): Promise<Club> {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios.get<Club>(`${window.apiBase}/i/schools/${activeSchoolId}/clubs/${clubId}`, config).then(response => {
    return response.data;
  });
}

export function getClubEvents(user: AppUser, clubId, filter = DEFAULT_FILTER): Promise<SchoolEvent[]> {
  const { roleSessionKey: key, activeSchoolId } = user;
  const effectiveOrder = filter.order || DEFAULT_CLUB_EVENTS_ORDER;
  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...getDefaultClubEventsFilter(clubId),
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: effectiveOrder
      }
    }
  };
  return axios
    .get<SchoolEvent[]>(`${window.apiBase}/i/schools/${activeSchoolId}/clubs/${clubId}/events`, config)
    .then(response => {
      return response.data;
    });
}

export function getClubEventsCount(user: AppUser, clubId, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;
  let config;
  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where
        }
      }
    };
  } else {
    config = {
      headers: { usid: key }
    };
  }
  return axios
    .get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/clubs/${clubId}/events/count`, config)
    .then(response => {
      return response.data;
    });
}

export function getAllClubEvents(user: AppUser, clubId, where?): Promise<SchoolEvent[]> {
  const { roleSessionKey: key, activeSchoolId } = user;
  let config;
  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios
    .get<SchoolEvent[]>(`${window.apiBase}/i/schools/${activeSchoolId}/clubs/${clubId}/events`, config)
    .then(response => {
      return response.data;
    });
}
