import * as React from 'react';
import { FunctionComponent } from 'react';
import './ProgressBar.scss';

interface Props {
  progress: number;
  step: number;
  stepTotal: number;
}

export const ProgressBar: FunctionComponent<Props> = props => {
  const { progress, step, stepTotal } = props;
  return (
    <div className="bProgress">
      <div
        className="progress-bar"
        style={{ width: `${props.progress}%` }}
        aria-valuenow={progress}
        aria-valuemin={0}
        aria-valuemax={100}
      >
        {`Step ${step} of ${stepTotal}`}
      </div>
    </div>
  );
};

ProgressBar.displayName = 'ProgressBar';
