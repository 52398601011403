import * as React from 'react';
import { Tournament } from 'Src/models/tournament';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button } from 'Src/components/Button/Button';
import { getName, getSchoolName, searchFunctionTournamentParticipants } from 'Src/helpers/autocomplete/autocomplete';
import { GENDER, SPORT_GENDER_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/common';
import { Autocomplete } from 'Src/components/Autocomplete/Autocomplete';
import { AppUser } from 'Src/views/App/App';
import * as propz from 'propz';
import { AGE_GROUPS } from 'Src/consts/school';
import { TournamentTeam } from 'Src/models/tournamentTeam';
import { getTeamPlacesLeft, isIndividualTournament } from 'Src/helpers/tournament/tournament';
import { TOURNAMENT_SUBTYPE } from '../../../../../consts/tournament';

interface Props {
  tournament: Tournament;
  onSubmit: (data: any) => void;
  onCancel: () => void;
  user: AppUser;
  team?: TournamentTeam;
  allTournamentTeams: TournamentTeam[];
  result: any;
}

const schema = Yup.object().shape({
  schoolId: Yup.string().required('Required'),
  name: Yup.string().required('Required'),
  gender: Yup.string().required('Required'),
  age: Yup.number().required('Required')
});

export class AdminSUTournamentTeamForm extends React.Component<Props> {
  isTeamExist() {
    const { team } = this.props;
    return typeof team !== 'undefined';
  }

  renderGenderOptions(): React.ReactNode {
    const { tournament } = this.props;
    const { isMixedTeamsAvailable, gender } = tournament;

    const isGenderMixed = gender === GENDER.MIXED;
    const isGenderMale = gender === GENDER.MALE_ONLY;
    const isGenderFemale = gender === GENDER.FEMALE_ONLY;

    switch (true) {
      case isGenderMale:
        return (
          <option key="gender_option_male" value={GENDER.MALE_ONLY}>
            {SPORT_GENDER_SERVER_TO_CLIENT_MAPPING.MALE_ONLY}
          </option>
        );
      case isGenderFemale:
        return (
          <option key="gender_option_female" value={GENDER.FEMALE_ONLY}>
            {SPORT_GENDER_SERVER_TO_CLIENT_MAPPING.FEMALE_ONLY}
          </option>
        );
      case isGenderMixed && !isMixedTeamsAvailable:
        return (
          <>
            <option key="gender_option_male" value={GENDER.MALE_ONLY}>
              {SPORT_GENDER_SERVER_TO_CLIENT_MAPPING.MALE_ONLY}
            </option>
            <option key="gender_option_female" value={GENDER.FEMALE_ONLY}>
              {SPORT_GENDER_SERVER_TO_CLIENT_MAPPING.FEMALE_ONLY}
            </option>
          </>
        );
      default:
        return (
          <>
            <option key="gender_option_female" value={GENDER.MIXED}>
              {SPORT_GENDER_SERVER_TO_CLIENT_MAPPING.MIXED}
            </option>
          </>
        );
    }
  }

  renderAgeOptions(): React.ReactNode {
    const { tournament, user } = this.props;
    const { ages } = tournament;
    const tournamentAgesGroupName = propz.get(user, ['activeSchool', 'ageGroupsNaming'], '');

    return [...ages]
      .sort((age1, age2) => age1 - age2)
      .map(ageIndex => {
        const ageGroup = AGE_GROUPS[tournamentAgesGroupName];
        return (
          <option key={ageIndex} value={Number(ageIndex)}>
            {ageGroup[ageIndex]}
          </option>
        );
      });
  }

  renderGroupOptions(): React.ReactNode {
    const { tournament } = this.props;
    const { customGroups } = tournament;

    const options = customGroups.map(group => (
      <option key={group.name} value={group.name}>
        {group.name}
      </option>
    ));

    options.unshift(
      <option key={`empty_priority`} value={''}>
        No select
      </option>
    );

    return options;
  }

  getTeamInitial() {
    const { team, result } = this.props;
    const teamName = propz.get(team, ['name'], '');
    const teamSchool = propz.get(team, ['school']);
    const teamSchoolId = propz.get(team, ['schoolId'], '');
    const teamGender = propz.get(team, ['gender'], '');
    const teamAge = propz.get(team, ['ages', '0'], '');
    const teamPoint = propz.get(result, ['point'], 0);
    const teamGroupName = propz.get(result, ['group'], '');
    const teamDescription = propz.get(result, ['description'], '');

    const teamInitial = {
      schoolId: this.isTeamExist() ? teamSchoolId : '',
      school: this.isTeamExist() ? teamSchool : undefined,
      name: this.isTeamExist() ? teamName : '',
      gender: this.isTeamExist() ? teamGender : this.getGenderInitial(),
      age: this.isTeamExist() ? teamAge : this.getAgeInitial(),
      point: this.isTeamExist() ? teamPoint : 0,
      group: this.isTeamExist() ? teamGroupName : '',
      description: this.isTeamExist() ? teamDescription : ''
    };

    return teamInitial;
  }

  getGenderInitial(): string {
    const { tournament } = this.props;
    const { isMixedTeamsAvailable, gender } = tournament;
    const isGenderMixed = gender === GENDER.MIXED;

    return isGenderMixed && !isMixedTeamsAvailable ? GENDER.MALE_ONLY : gender;
  }

  getAgeInitial(): number {
    const { tournament } = this.props;
    const { ages } = tournament;

    const agesSorted = [...ages].sort((age1, age2) => age1 - age2);
    const ageInitial = propz.get(agesSorted, ['0'], 0);
    return ageInitial;
  }

  getSchools = (text: string) => {
    const { user, tournament } = this.props;

    return searchFunctionTournamentParticipants(user, text, tournament, []);
  };

  isSaveButtonEnabled(values): boolean {
    const { tournament, allTournamentTeams } = this.props;
    const { autoEnrollmentTeamsSettings, isAutoEnrollmentTeams } = tournament;

    if (this.isTeamExist()) {
      return true;
    }

    if (isIndividualTournament(tournament)) {
      return true;
    }

    if (!isAutoEnrollmentTeams) {
      return true;
    }

    let isTeamLimitExceeded = false;

    const age = Number(values.age);
    const gender = values.gender;
    const schoolId = values.schoolId;

    if (!schoolId) {
      return true;
    }

    const teamPlacesLeft = getTeamPlacesLeft(autoEnrollmentTeamsSettings, age, gender);
    const currentSchoolTeams = allTournamentTeams.filter(team => {
      const isAgeEqual = team.ages[0] === age;
      const isGenderEqual = team.gender === gender;
      const isSchoolIdEqual = team.schoolId === schoolId;

      return isAgeEqual && isGenderEqual && isSchoolIdEqual;
    });

    const currentSchoolTeamsCount = currentSchoolTeams.length;

    const maximumNumberTeamsOfSchool = propz.get(tournament, ['maximumNumberTeamsOfSchool'], 0);
    const teamPlacesAvailableByTeamTypeLimit = getTeamPlacesLeft(autoEnrollmentTeamsSettings, age, gender);
    const teamPlacesAvailableBySchoolLimit = maximumNumberTeamsOfSchool - currentSchoolTeamsCount;

    const autoEnrollmentTeamsSetting = autoEnrollmentTeamsSettings.find(setting => {
      return setting.age === Number(age) && setting.gender === gender;
    });

    const { maximumNumberTeams } = autoEnrollmentTeamsSetting;
    const isTeamsLimitedByType = maximumNumberTeams !== 0;
    const isTeamsLimitedBySchool = maximumNumberTeamsOfSchool !== 0;

    const isTeamLimitByTypeExceeded = isTeamsLimitedByType && teamPlacesAvailableByTeamTypeLimit < 1;
    const isTeamLimitBySchoolExceeded = isTeamsLimitedBySchool && teamPlacesAvailableBySchoolLimit < 1;

    if (isTeamLimitByTypeExceeded || isTeamLimitBySchoolExceeded) {
      isTeamLimitExceeded = true;
    }

    return !isTeamLimitExceeded;
  }

  render() {
    const teamInitial = this.getTeamInitial();
    const { onSubmit, onCancel, tournament } = this.props;
    const tournamentSubtype = propz.get(tournament, ['subType']);
    const winnersOnlyTournament = tournamentSubtype === TOURNAMENT_SUBTYPE.WINNERS_ONLY;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <Formik
              initialValues={teamInitial}
              validationSchema={schema}
              onSubmit={values => {
                onSubmit(values);
              }}
              render={({ touched, errors, setFieldValue, values }) => (
                <Form>
                  <label>School</label>
                  <Field
                    name="school"
                    render={({ field }) => {
                      return (
                        <Autocomplete
                          disabled={this.isTeamExist()}
                          searchFunction={this.getSchools}
                          getElementTitle={this.isTeamExist() ? getName : getSchoolName}
                          defaultItem={values.school}
                          customClass="mFullWidth mb-3"
                          onSelect={participant => {
                            setFieldValue('schoolId', participant.school.id);
                            setFieldValue('school', participant);
                          }}
                        />
                      );
                    }}
                  />
                  {touched.schoolId && errors.schoolId ? (
                    <div className="alert alert-danger">{errors.schoolId}</div>
                  ) : null}

                  <div className="form-group">
                    <label>Name</label>
                    <Field type="text" name="name" className="form-control" />
                  </div>
                  {touched.name && errors.name ? <div className="alert alert-danger">{errors.name}</div> : null}

                  <div className="form-group">
                    <label>Gender</label>
                    <Field component="select" name="gender" className="form-control mb-3">
                      {this.renderGenderOptions()}
                    </Field>
                  </div>
                  {touched.gender && errors.gender ? <div className="alert alert-danger">{errors.gender}</div> : null}

                  <div className="form-group">
                    <label>Age</label>
                    <Field component="select" name="age" className="form-control mb-3">
                      {this.renderAgeOptions()}
                    </Field>
                  </div>
                  {touched.age && errors.age ? <div className="alert alert-danger">{errors.age}</div> : null}

                  {winnersOnlyTournament && (
                    <>
                      <div className="form-group">
                        <label>Group</label>
                        <Field
                          component="select"
                          name="group"
                          className="form-control mb-3"
                          onChange={event => {
                            const group = event.target.value;
                            setFieldValue('group', group);
                          }}
                        >
                          {this.renderGroupOptions()}
                        </Field>
                      </div>
                      {touched.group && errors.group ? <div className="alert alert-danger">{errors.group}</div> : null}

                      <div className="form-group">
                        <label>Result</label>
                        <Field type="number" name="point" min="0" className="form-control" />
                      </div>
                      {touched.point && errors.point ? <div className="alert alert-danger">{errors.point}</div> : null}

                      <div className="form-group">
                        <label>Description</label>
                        <Field type="textarea" name="description" className="form-control" />
                      </div>
                      {touched.description && errors.description ? (
                        <div className="alert alert-danger">{errors.description}</div>
                      ) : null}
                    </>
                  )}

                  {!this.isTeamExist() && !this.isSaveButtonEnabled(values) ? (
                    <div className="alert alert-danger">Limit exceeded</div>
                  ) : null}

                  <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                  <button
                    type="submit"
                    className="mt-3 mb-3 btn btn-primary"
                    disabled={this.isTeamExist() ? false : !this.isSaveButtonEnabled(values)}
                  >
                    Save
                  </button>
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}
