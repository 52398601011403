export const TOURNAMENT_LOG_ACTION_TYPES_SERVER_TO_CLIENT_MAPPING = {
  INVITE_SENT: 'Invite sent',
  INVITE_ACCEPTED: 'Invite accepted',
  INVITE_CANCELLED: 'Invite cancelled',
  INVITE_REJECTED: 'Invite rejected',
  SCHOOL_AUTO_ENROLLED: 'School auto enrolled',
  SCHOOL_MANUALLY_ENROLLED: 'School manually enrolled',
  SCHOOL_REMOVED: 'School removed',
  SCHOOL_ADDED: 'School added',
  TEAM_AUTO_ADDED: 'Team auto added',
  TEAM_MANUALLY_ADDED: 'Team manually added',
  TEAM_REMOVED: 'Team removed'
};

export const TOURNAMENT_LOG_ACTION_TYPES = {
  INVITE_SENT: 'INVITE_SENT',
  INVITE_ACCEPTED: 'INVITE_ACCEPTED',
  INVITE_CANCELLED: 'INVITE_CANCELLED',
  INVITE_REJECTED: 'INVITE_REJECTED',
  SCHOOL_AUTO_ENROLLED: 'SCHOOL_AUTO_ENROLLED',
  SCHOOL_MANUALLY_ENROLLED: 'SCHOOL_MANUALLY_ENROLLED',
  SCHOOL_REMOVED: 'SCHOOL_REMOVED',
  SCHOOL_ADDED: 'SCHOOL_ADDED',
  TEAM_AUTO_ADDED: 'TEAM_AUTO_ADDED',
  TEAM_MANUALLY_ADDED: 'TEAM_MANUALLY_ADDED',
  TEAM_REMOVED: 'TEAM_REMOVED'
};
