import * as React from 'react';
import { FunctionComponent } from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { CLUB_CURRENCY_SYMBOL } from '../../consts/club';
import { CURRENCY_TYPE } from '../../types/common';

interface Props {
  currency: CURRENCY_TYPE;
  onChange: (value: string) => void;
  value: string;
  disabled: boolean;
}

export const CurrencyInput: FunctionComponent<Props> = props => {
  const { value, disabled } = props;

  const currencySymbol = CLUB_CURRENCY_SYMBOL[props.currency];

  const mask = createNumberMask({
    prefix: currencySymbol,
    suffix: '',
    allowDecimal: true,
    requireDecimal: true,
    allowLeadingZeroes: true,
    decimalSymbol: '.',
    decimalLimit: 2,
    integerLimit: 7,
    allowNegative: false,
    includeThousandsSeparator: false
  });

  const onChange = event => {
    event.stopPropagation();
    //delete currency symbol
    const value = event.target.value.substring(1);

    const isValueExist = value !== '' && value !== '.';
    const valueFormatted = isValueExist ? value : '0';

    props.onChange(valueFormatted);
  };

  return (
    <MaskedInput
      type="text"
      className="form-control mb-3"
      onChange={onChange}
      mask={mask}
      value={value}
      disabled={disabled}
    />
  );
};

CurrencyInput.displayName = 'CurrencyInput';
