import * as React from 'react';
import * as classNames from 'classnames';

interface Props {
  value: boolean;
  id: string;
  onChange: (id: string | any) => void;
  labelText?: string;
  disabled?: boolean;
  isOnChangeIdRequired?: boolean;
  customClass?: string;
}

export function Checkbox(props: Props) {
  const { value, onChange, labelText, id, disabled = false, isOnChangeIdRequired = true, customClass } = props;
  const checkboxClasses = classNames('form-check', customClass);

  return (
    <div className={checkboxClasses}>
      <input
        className="form-check-input"
        disabled={disabled}
        type="checkbox"
        checked={value}
        onChange={isOnChangeIdRequired ? () => onChange(id) : event => onChange(event)}
        id={id}
      />
      {labelText && (
        <label className="form-check-label" htmlFor={id}>
          {labelText}
        </label>
      )}
    </div>
  );
}
