import { AppUser } from 'Src/views/App/App';
import axios from 'axios';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from 'Src/consts/table';
import { Count } from '../../../models/count';

const DEFAULT_SCHOOL_USERS_FILTER: any = {
  'permissions.preset': { $in: ['ADMIN', 'MANAGER', 'TEACHER', 'COACH', 'PARENT', 'STUDENT', 'GOVERNOR'] }
};

/** USER TABLE */
export function getSchoolUsers(user: AppUser, filter = DEFAULT_SCHOOL_USERS_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter
    }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/users`, config).then(response => {
    return response.data;
  });
}

export function getAllSchoolUsers(user: AppUser, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_SCHOOL_USERS_FILTER,
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_SCHOOL_USERS_FILTER
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/users`, config).then(response => {
    return response.data;
  });
}

export function getSchoolUsersCount(user: AppUser, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where
        }
      }
    };
  } else {
    config = {
      headers: { usid: key }
    };
  }

  return axios.get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/users/count`, config).then(response => {
    return response.data;
  });
}

export function assignUserTag(user: AppUser, data, userId) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/users/${userId}/userTags`, data, config)
    .then(response => {
      return response.data;
    });
}

export function deleteUserTagByUser(user: AppUser, userId, userTagId) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .delete(`${window.apiBase}/i/schools/${activeSchoolId}/users/${userId}/userTags/${userTagId}`, config)
    .then(response => {
      return response.data;
    });
}
