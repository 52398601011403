import * as React from 'react';
import * as Lazy from 'lazy.js';
import { AppUser } from '../../../../../App/App';
import { History, Location } from 'history';
import {
  getPersonListSortedByName,
  ColumnDefinition,
  getOrder,
  getServerFieldSectionWhere,
  isFilterExist2,
  TABLE_SORT_DIRECTION
} from '../../../../../../helpers/table/table';
import { DATE_INTERVAL, DEFAULT_LIMIT, DEFAULT_SKIP, FILTER_TYPE, LIMIT } from '../../../../../../consts/table';
import { getAge, getGender } from '../../../../../../helpers/accessor/accessor';
import { getTwoPanelEditorFilters } from '../../../../../../helpers/twoPanelEditor/twoPanelEditor';
import * as BPromise from 'bluebird';
import * as propz from 'propz';
import { Loader } from '../../../../../../components/Loader/Loader';
import { TwoPanelEditor } from '../../../../../../components/TwoPanelEditor/TwoPanelEditor';
import { getAllForms } from 'Src/helpers/service/admin/forms';
import { getAllHouses } from 'Src/helpers/service/admin/houses';
import {
  getSelectOptionForAge,
  getSelectOptionForForms,
  getSelectOptionForGender,
  getSelectOptionForHouses
} from '../../../../../../helpers/table/select';
import { getUserGenderArrayConvertedFromEventGender } from '../../../../../../helpers/tournament/tournament';
import { Button } from '../../../../../../components/Button/Button';
import { getSchoolEvent, updateSchoolEventTeamPlayersBatch } from 'Src/helpers/service/admin/event';
import { getAllClubParticipants } from 'Src/helpers/service/admin/clubParticipants';
import { addClubStudents } from 'Src/helpers/service/admin/clubStudents';
import {
  getEventClash,
  getEventTitle,
  getStudentsMethod,
  getTeamPlayersMethod,
  isClubEvent,
  isTournamentEvent,
  isHousesEvent,
  isTeamOrTwoOnTwoSportEvent
} from '../../../../../../helpers/event/event';
import { SchoolEvent } from '../../../../../../models/event';
import { getFromHistory } from '../../../../../../helpers/history/history';
import { TEAM_TYPE } from '../../../../../../consts/team';
import {
  addSchoolEventTeam,
  addSchoolTournamentTeam,
  getSchoolTournamentTeams
} from 'Src/helpers/service/admin/tournamentTeams';
import { updateSchoolTournamentTeamPlayersBatch } from 'Src/helpers/service/admin/tournamentTeamPlayers';
import { TournamentTeam } from 'Src/models/tournamentTeam';
import { getSchoolEventTeam, updateSchoolEventTeam } from 'Src/helpers/service/admin/schoolEventTeams';
import { CLUB_EVENT_EDIT_MODE } from '../../../../../../consts/event';
import {
  getSchoolStudentsWithClashesCount,
  getSchoolStudentsWithoutClashes
} from '../../../../../../helpers/service/admin/schoolTeamPlayers';
import { AGE_GROUPS } from '../../../../../../consts/school';
import * as Moment from 'moment';
import { getColorClashes } from '../../../../../../helpers/cell/cell';
import { SchoolStudentClash } from '../../../../../../models/schoolStudent';
import { SchoolForm } from '../../../../../../models/form';
import { SchoolHouse } from '../../../../../../models/house';
import { SimpleModal } from '../../../../../../components/SimpleModal/SimpleModal';
import { SchoolEventTeamPlayer, SchoolEventTeamPlayerWithClashes } from '../../../../../../models/eventTeamPlayer';
import { getSchoolEventTeamPlayersCount } from '../../../../../../helpers/service/admin/schoolEventTeamPlayers';
import { updateSchoolEventIndividualsGroupBatch } from '../../../../../../helpers/service/admin/schoolEventIndividuals';
import { SchoolEventClubEventRadioButtons } from '../SchoolEventClubEventRadioButtons/SchoolEventClubEventRadioButtons';
import { createTeamScore } from 'Src/helpers/service/admin/eventResults';

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

interface State {
  items: Item[];
  itemsSelected: Item[];
  itemsCount: number;

  itemsFilters: any;
  isShowItemsFilter: boolean;
  isItemsFiltered: boolean;

  isSelectAllItemsChecked: boolean;

  sortItemsDirection: TABLE_SORT_DIRECTION;
  sortItemsColumnsName: string;

  itemCurrentPage: number;

  itemsAccumulatorInitial: Item[];
  itemsAccumulator: Item[];
  itemsAccumulatorSelected: Item[];
  itemsAccumulatorCount: number;

  itemsAccumulatorFilters: any;
  isShowItemsAccumulatorFilter: boolean;
  isItemsAccumulatorFiltered: boolean;

  isExitConfirmationModalOpen: boolean;

  isSelectAllItemsAccumulatorChecked: boolean;

  sortItemsAccumulatorDirection: TABLE_SORT_DIRECTION;
  sortItemsAccumulatorColumnsName: string;

  isLoading: boolean;
  isError: boolean;

  itemsAddBuffer: Item[];
  itemsRemoveBuffer: Item[];

  team: any;

  forms: SchoolForm[];
  houses: SchoolHouse[];

  event: SchoolEvent;
  teamName: string;
  isShowFollowingEventsSection: boolean;
  editMode: string;

  studentEventsModalText: string;
  isStudentEventsModalOpen: boolean;
  tournamentTeams: TournamentTeam[];
}

interface Item {
  id: string;
  permissionId: string;
  form: any;
  house: any;
  gender: string;
  firstName: string;
  lastName: string;
  clashes: {
    status?: string;
    fullClashEvents?: any[];
    intervalClashEvents?: any[];
  };
  playerId?: string;
}

const ITEMS_ACCUMULATOR_COLUMNS: ColumnDefinition[] = [
  {
    text: 'Name',
    field: 'firstName',
    accessor: ['firstName'],
    type: FILTER_TYPE.NONE,
    isSort: false
  },
  {
    text: 'Surname',
    field: 'lastName',
    accessor: ['lastName'],
    type: FILTER_TYPE.NONE,
    isSort: false
  },
  {
    text: 'Form',
    field: 'forms',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: ['form', 'name']
  },
  {
    text: 'House',
    field: 'house',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: ['house', 'name']
  },
  {
    text: 'Age',
    field: 'ages',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getAge
  },
  {
    text: 'Gender',
    field: 'gender',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getGender
  }
];

const ITEMS_ACCUMULATOR_COLUMNS_WITH_CLASHES: ColumnDefinition[] = [
  {
    text: 'Clashes',
    field: 'clashes',
    cell: getColorClashes,
    type: FILTER_TYPE.NONE,
    isSort: false
  },
  {
    text: 'Surname',
    field: 'lastName',
    accessor: ['lastName'],
    type: FILTER_TYPE.NONE,
    isSort: false
  },
  {
    text: 'Name',
    field: 'firstName',
    accessor: ['firstName'],
    type: FILTER_TYPE.NONE,
    isSort: false
  },
  {
    text: 'Form',
    field: 'forms',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: ['form', 'name']
  },
  {
    text: 'House',
    field: 'house',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: ['house', 'name']
  },
  {
    text: 'Age',
    field: 'ages',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getAge
  },
  {
    text: 'Gender',
    field: 'gender',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getGender
  }
];

const ITEMS_COLUMNS: ColumnDefinition[] = [
  {
    text: 'Name',
    field: 'firstName',
    accessor: ['firstName'],
    type: FILTER_TYPE.TEXT,
    isSort: false
  },
  {
    text: 'Surname',
    field: 'lastName',
    accessor: ['lastName'],
    type: FILTER_TYPE.TEXT,
    isSort: false
  },
  {
    text: 'Form',
    field: 'forms',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: ['form', 'name']
  },
  {
    text: 'House',
    field: 'houses',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: ['house', 'name']
  },
  {
    text: 'Age',
    field: 'ages',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getAge
  },
  {
    text: 'Gender',
    field: 'gender',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getGender
  }
];

const ITEMS_COLUMNS_WITH_CLASHES: ColumnDefinition[] = [
  {
    text: 'Clashes',
    field: 'clashes',
    cell: getColorClashes,
    type: FILTER_TYPE.NONE,
    isSort: false
  },
  {
    text: 'Surname',
    field: 'lastName',
    accessor: ['lastName'],
    type: FILTER_TYPE.TEXT,
    isSort: false
  },
  {
    text: 'Name',
    field: 'firstName',
    accessor: ['firstName'],
    type: FILTER_TYPE.TEXT,
    isSort: false
  },
  {
    text: 'Form',
    field: 'forms',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: ['form', 'name']
  },
  {
    text: 'House',
    field: 'houses',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: ['house', 'name']
  },
  {
    text: 'Age',
    field: 'ages',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getAge
  },
  {
    text: 'Gender',
    field: 'gender',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getGender
  }
];

const DEFAULT_TEAM_PLAYERS_ORDER = 'firstName ASC';

export class SchoolTeamManager extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      itemsCount: 0,
      itemsSelected: [],

      itemsFilters: {},
      isShowItemsFilter: false,
      isItemsFiltered: false,

      isSelectAllItemsChecked: false,

      sortItemsDirection: '',
      sortItemsColumnsName: '',

      itemCurrentPage: 1,

      itemsAccumulatorInitial: [],
      itemsAccumulator: [],
      itemsAccumulatorCount: 0,
      itemsAccumulatorSelected: [],

      itemsAccumulatorFilters: {},
      isShowItemsAccumulatorFilter: false,
      isItemsAccumulatorFiltered: false,

      isSelectAllItemsAccumulatorChecked: false,

      sortItemsAccumulatorDirection: '',
      sortItemsAccumulatorColumnsName: '',

      isExitConfirmationModalOpen: false,

      isLoading: true,
      isError: false,

      itemsAddBuffer: [],
      itemsRemoveBuffer: [],

      team: undefined,
      forms: [],
      houses: [],

      event: undefined,
      teamName: '',
      isShowFollowingEventsSection: false,
      editMode: CLUB_EVENT_EDIT_MODE.SINGLE,

      studentEventsModalText: '',
      isStudentEventsModalOpen: false,
      tournamentTeams: []
    };
  }

  getDefaultItemsFilter(team, event: SchoolEvent) {
    const isTeamExist = typeof team !== 'undefined';
    const { ages } = event;
    if (isTeamExist) {
      const { gender, houseId } = team;

      const genderArrayConverted = getUserGenderArrayConvertedFromEventGender(gender);

      return typeof houseId !== 'undefined'
        ? {
            firstName: '',
            lastName: '',
            gender: genderArrayConverted,
            forms: [],
            houses: [houseId],
            ages: ages
          }
        : {
            firstName: '',
            lastName: '',
            gender: genderArrayConverted,
            forms: [],
            ages: ages
          };
    } else {
      const { gender } = event;
      const genderArrayConverted = getUserGenderArrayConvertedFromEventGender(gender);
      const houseId = this.getHouseId();

      return isHousesEvent(event)
        ? {
            firstName: '',
            lastName: '',
            gender: genderArrayConverted,
            forms: [],
            ages: ages,
            houses: [houseId]
          }
        : {
            firstName: '',
            lastName: '',
            gender: genderArrayConverted,
            forms: [],
            ages: ages
          };
    }
  }

  getTeamId(): string {
    const { history } = this.props;
    return getFromHistory(history, 'team');
  }

  getEventId(): string {
    const { history } = this.props;
    return getFromHistory(history, 'event');
  }

  getHouseId(): string {
    const { history } = this.props;
    return getFromHistory(history, 'house');
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    const { user } = this.props;
    const teamId = this.getTeamId();
    const eventId = this.getEventId();

    const itemsFilters = getTwoPanelEditorFilters(ITEMS_COLUMNS);
    const isShowItemsFilter = isFilterExist2(itemsFilters);
    const isItemsFiltered = isFilterExist2(itemsFilters);

    const itemsAccumulatorFilters = getTwoPanelEditorFilters(ITEMS_ACCUMULATOR_COLUMNS);
    const isShowItemsAccumulatorFilter = isFilterExist2(itemsAccumulatorFilters);
    const isItemsAccumulatorFiltered = isFilterExist2(itemsAccumulatorFilters);

    const isTeamIdExist = typeof teamId !== 'undefined';

    const teamPromise = isTeamIdExist ? getSchoolEventTeam(user, eventId, teamId) : BPromise.resolve(undefined);
    const formsPromise = getAllForms(user);
    const housesPromise = getAllHouses(user);
    const eventPromise = getSchoolEvent(user, eventId);

    let team;
    let forms;
    let houses;
    let event;

    BPromise.all([teamPromise, formsPromise, housesPromise, eventPromise])
      .then(([_team, _forms, _houses, _event]) => {
        team = _team;
        forms = _forms;
        houses = _houses;
        event = _event;

        if (isTournamentEvent(event)) {
          const { tournamentId } = event;

          return getSchoolTournamentTeams(user, tournamentId);
        }
      })
      .then(tournamentTeams => {
        const defaultItemsFilter = this.getDefaultItemsFilter(team, event);
        const isTeamExist = typeof team !== 'undefined';
        const houseId = this.getHouseId();
        const house = event.housesData.find(eventHouse => eventHouse.id === houseId);
        const houseName = propz.get(house, ['name'], '');

        isTeamExist
          ? this.setState({
              team,
              forms,
              houses,
              event,
              itemsFilters: defaultItemsFilter,
              teamName: team.name,
              tournamentTeams: isTournamentEvent(event) ? tournamentTeams : []
            })
          : this.setState({
              forms,
              houses,
              event,
              itemsFilters: defaultItemsFilter,
              teamName: isHousesEvent(event) ? houseName : '',
              tournamentTeams: isTournamentEvent(event) ? tournamentTeams : []
            });

        return this.getItemsAndItemsAccumulator();
      })
      .then(({ teamPlayersInitial, teamPlayers, teamPlayersCount, students, studentsCount }) => {
        const studentsNormalized = this.getStudentsNormalized(students);
        const teamPlayersNormalized = this.getTeamPlayersNormalized(teamPlayers);
        const teamPlayersInitialNormalized = this.getTeamPlayersNormalized(teamPlayersInitial);

        this.setState({
          items: studentsNormalized,
          itemsCount: studentsCount.count,
          isShowItemsFilter: isShowItemsFilter,
          isItemsFiltered: isItemsFiltered,

          itemsAccumulatorInitial: teamPlayersInitialNormalized,
          itemsAccumulator: teamPlayersNormalized,
          itemsAccumulatorCount: teamPlayersCount.count,
          itemsAccumulatorFilters: itemsAccumulatorFilters,
          isShowItemsAccumulatorFilter: isShowItemsAccumulatorFilter,
          isItemsAccumulatorFiltered: isItemsAccumulatorFiltered,

          isLoading: false
        });
      });
  }

  getTeamPlayersNormalized(teamPlayers: SchoolEventTeamPlayerWithClashes[]): Item[] {
    const sortedTeamPlayers = getPersonListSortedByName(teamPlayers);

    return sortedTeamPlayers.map(teamPlayer => {
      const { permissionId, form, gender, firstName, lastName, userId, id, house, clashes } = teamPlayer;
      return {
        permissionId,
        form,
        house,
        gender,
        firstName,
        lastName,
        clashes: clashes,
        id: userId,
        playerId: id
      };
    });
  }

  getStudentsNormalized(students: SchoolStudentClash[]): Item[] {
    const sortedStudents = getPersonListSortedByName(students);

    return sortedStudents.map(student => {
      const { permissionId, form, gender, firstName, lastName, id, house, clashes } = student;

      return {
        permissionId,
        form,
        house,
        gender,
        firstName,
        lastName,
        clashes,
        id
      };
    });
  }

  getItemsFilter(page, itemsAccumulator, itemsAddBuffer, isAllItems = false) {
    const { itemsFilters, forms, event } = this.state;

    const { startTime, endTime, id } = event;
    const { players } = event;
    const teamId = this.getTeamId();

    const {
      gender: itemsFiltersGender,
      ages: itemsFiltersAges = [],
      firstName: itemsFiltersFirstName = '',
      lastName: itemsFiltersLastName = '',
      forms: itemsFiltersForms = [],
      houses: itemsFiltersHouses = []
    } = itemsFilters;

    let where = {
      eventId: id,
      gender: {
        $in: itemsFiltersGender
      }
    };

    const playerUserIdsFromOtherTeams = players
      .filter(player => player.teamId !== teamId)
      .map(player => ({
        id: player.userId
      }));

    const allItems = [...itemsAccumulator, ...itemsAddBuffer, ...playerUserIdsFromOtherTeams];
    const ninIds = Lazy(allItems)
      .map(item => item.id)
      .uniq()
      .toArray();
    propz.set(where, ['_id', '$nin'], ninIds);

    if (itemsFiltersForms.length > 0) {
      propz.set(where, ['formId', '$in'], itemsFiltersForms);
    } else {
      const ages = itemsFiltersAges.length > 0 ? itemsFiltersAges : AGE_GROUPS.ENGLISH.map((age, index) => index);
      const formIds = forms.filter(form => ages.some(age => Number(form.age) === Number(age))).map(form => form.id);

      if (formIds.length > 0) {
        propz.set(where, ['formId', '$in'], formIds);
      }
    }

    if (isHousesEvent(event)) {
      const houseId = this.getHouseId();
      propz.set(where, ['houseId'], houseId);
    }

    if (itemsFiltersHouses.length > 0) {
      propz.set(where, ['houseId', '$in'], itemsFiltersHouses);
    }

    let and = [];

    if (itemsFiltersFirstName.length > 0) {
      and.push({ firstName: { like: itemsFiltersFirstName, options: 'i' } });
    }

    if (itemsFiltersLastName.length > 0) {
      and.push({ lastName: { like: itemsFiltersLastName, options: 'i' } });
    }

    if (and.length > 0) {
      propz.set(where, ['$and'], and);
    }

    // copy-paste from old frontend
    const startTimeDate = new Date(startTime);
    const startTimeCopy = new Date(startTime);
    startTimeCopy.setHours(startTimeDate.getHours() - 1);
    const intervalClashStartDate = Moment(startTimeCopy)
      .utc()
      .format();

    const endTimeDate = new Date(endTime);
    const endTimeCopy = new Date(endTime);
    endTimeCopy.setHours(endTimeDate.getHours() + 1);
    const intervalClashEndDate = Moment(endTimeCopy)
      .utc()
      .format();

    propz.set(where, ['intervalClashStartDate'], intervalClashStartDate);
    propz.set(where, ['intervalClashEndDate'], intervalClashEndDate);
    propz.set(where, ['fullClashStartDate'], startTime);
    propz.set(where, ['fullClashEndDate'], endTime);

    const filter = {
      where: where,
      limit: isAllItems ? DEFAULT_LIMIT : LIMIT,
      skip: isAllItems ? 0 : (page - 1) * LIMIT,
      order: DEFAULT_TEAM_PLAYERS_ORDER
    };

    return filter;
  }

  getItemsAndItemsAccumulator() {
    const { user } = this.props;
    const { itemsAccumulatorFilters, forms } = this.state;
    const teamId = this.getTeamId();

    const options = { forms };
    const where = getServerFieldSectionWhere(itemsAccumulatorFilters, options);

    const eventId = this.getEventId();
    const isTeamIdExist = typeof teamId !== 'undefined';

    const getTeamPlayersInitialPromise: Promise<SchoolEventTeamPlayerWithClashes[]> = isTeamIdExist
      ? getTeamPlayersMethod(user)(user, eventId, teamId, {})
      : BPromise.resolve([]);
    const getTeamPlayersPromise: Promise<SchoolEventTeamPlayerWithClashes[]> = isTeamIdExist
      ? getTeamPlayersMethod(user)(user, eventId, teamId, {})
      : BPromise.resolve([]);
    const getTeamPlayersCountPromise = isTeamIdExist
      ? getSchoolEventTeamPlayersCount(user, eventId, teamId, where)
      : BPromise.resolve({ count: 0 });

    let teamPlayersInitial, teamPlayers, teamPlayersCount;

    return BPromise.all([getTeamPlayersInitialPromise, getTeamPlayersPromise, getTeamPlayersCountPromise])
      .then(([_teamPlayersInitial, _teamPlayers, _teamPlayersCount]) => {
        teamPlayersInitial = _teamPlayersInitial;
        teamPlayers = _teamPlayers;
        teamPlayersCount = _teamPlayersCount;

        const teamPlayersInitialNormalized = this.getTeamPlayersNormalized(teamPlayersInitial);

        const filter = this.getItemsFilter(1, teamPlayersInitialNormalized, []);

        const method = getStudentsMethod(user);
        const getStudentsPromise = method(user, filter);
        const getStudentsCountPromise = getSchoolStudentsWithClashesCount(user, filter);

        return BPromise.all([getStudentsPromise, getStudentsCountPromise]);
      })
      .then(([students, studentsCount]) => {
        return {
          teamPlayersInitial,
          teamPlayers,
          teamPlayersCount,
          students,
          studentsCount
        };
      });
  }

  onItemClick = (index: number): void => {
    const { items, itemsSelected } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = itemsSelected.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...itemsSelected];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      itemsAccumulatorSelected: [],
      itemsSelected: selectedItemsUpdated
    });
  };

  onItemAccumulatorClick = (index: number): void => {
    const { itemsAccumulator, itemsAccumulatorSelected } = this.state;
    const selectedItem = itemsAccumulator[index];

    const selectedItemIndex = itemsAccumulatorSelected.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...itemsAccumulatorSelected];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      itemsAccumulatorSelected: selectedItemsUpdated,
      itemsSelected: []
    });
  };

  onAddClick = () => {
    const { itemsSelected, itemsAddBuffer, itemsAccumulatorInitial, itemCurrentPage } = this.state;
    const { user } = this.props;

    const nextItemsAddBuffer = [...itemsAddBuffer, ...itemsSelected];

    this.setState({
      isLoading: true
    });

    const filter = this.getItemsFilter(itemCurrentPage, itemsAccumulatorInitial, nextItemsAddBuffer);
    const method = getStudentsMethod(user);
    const getStudentsPromise = method(user, filter);
    const getStudentsCountPromise = getSchoolStudentsWithClashesCount(user, filter);

    BPromise.all([getStudentsPromise, getStudentsCountPromise]).then(([students, studentsCount]) => {
      const studentsNormalized = this.getStudentsNormalized(students);

      this.setState({
        items: studentsNormalized,
        isLoading: false,
        itemsCount: studentsCount.count,
        itemsAddBuffer: nextItemsAddBuffer,
        itemsAccumulatorSelected: [],
        itemsSelected: [],
        isSelectAllItemsAccumulatorChecked: false,
        isSelectAllItemsChecked: false
      });
    });
  };

  onRemoveClick = () => {
    const {
      itemsAccumulatorSelected,
      itemsAddBuffer,
      itemsRemoveBuffer,
      itemsAccumulatorInitial,
      itemsAccumulator,
      itemCurrentPage
    } = this.state;
    const { user } = this.props;

    const nextItemsRemoveBuffer = [...itemsAccumulatorSelected, ...itemsRemoveBuffer];
    const nextItemsAccumulatorInitial = itemsAccumulatorInitial.filter(itemAccumulatorInitial =>
      nextItemsRemoveBuffer.every(nextItemRemoveBuffer => nextItemRemoveBuffer.id !== itemAccumulatorInitial.id)
    );
    const nextItemsAccumulator = itemsAccumulator.filter(itemAccumulator =>
      nextItemsRemoveBuffer.every(nextItemRemoveBuffer => nextItemRemoveBuffer.id !== itemAccumulator.id)
    );

    this.setState({
      isLoading: true
    });

    const filter = this.getItemsFilter(itemCurrentPage, nextItemsAccumulatorInitial, itemsAddBuffer);
    const method = getStudentsMethod(user);
    const getStudentsPromise = method(user, filter);
    const getStudentsCountPromise = getSchoolStudentsWithClashesCount(user, filter);

    BPromise.all([getStudentsPromise, getStudentsCountPromise]).then(([students, studentsCount]) => {
      const studentsNormalized = this.getStudentsNormalized(students);

      this.setState({
        items: studentsNormalized,
        isLoading: false,
        itemsCount: studentsCount.count,
        itemsAccumulatorSelected: [],
        itemsSelected: [],
        isSelectAllItemsAccumulatorChecked: false,
        isSelectAllItemsChecked: false,
        itemsRemoveBuffer: nextItemsRemoveBuffer,
        itemsAccumulatorInitial: nextItemsAccumulatorInitial,
        itemsAccumulator: nextItemsAccumulator,
        itemsAccumulatorCount: nextItemsAccumulator.length
      });
    });
  };

  getTeam() {
    const { user } = this.props;
    const { teamName, event } = this.state;
    const { activeSchoolId } = user;
    const { ages, gender, sportId } = event;
    const houseId = this.getHouseId();

    return isHousesEvent(event)
      ? {
          ages: ages.map(age => Number(age)),
          gender: gender,
          name: teamName,
          schoolId: activeSchoolId,
          sportId: sportId,
          teamType: TEAM_TYPE.ADHOC,
          houseId: houseId
        }
      : {
          ages: ages.map(age => Number(age)),
          gender: gender,
          name: teamName,
          schoolId: activeSchoolId,
          sportId: sportId,
          teamType: TEAM_TYPE.ADHOC
        };
  }

  showFollowingEventsSection = () => {
    this.setState({
      isShowFollowingEventsSection: true
    });
  };

  onEditModeClick = editMode => {
    this.setState({
      editMode: editMode
    });
  };

  onClickSave = () => {
    const {
      itemsAddBuffer,
      itemsRemoveBuffer,
      teamName,
      event,
      editMode,
      isShowFollowingEventsSection,
      itemsAccumulator,
      tournamentTeams,
      team
    } = this.state;
    const { user, history } = this.props;
    const { activeSchoolId } = user;
    const { clubId } = event;
    const groupEditMode = editMode === CLUB_EVENT_EDIT_MODE.GROUP;
    const eventId = this.getEventId();
    const schoolsScores = propz.get(event, ['results', 'schoolScore'], []);
    const activeSchoolScore = schoolsScores.find(score => score.schoolId === activeSchoolId);
    const isScoreExist = typeof activeSchoolScore !== 'undefined';

    switch (true) {
      case isClubEvent(event) && !isShowFollowingEventsSection:
        this.showFollowingEventsSection();

        break;

      case isClubEvent(event) && groupEditMode: {
        this.setState({
          isLoading: true
        });

        const players = [...itemsAddBuffer, ...itemsAccumulator];
        const playersCommit = players.map(player => {
          return {
            userId: player.id,
            permissionId: player.permissionId
          };
        });

        const teamPromise = isClubEvent(event)
          ? BPromise.all([
              getAllClubParticipants(user, clubId),
              updateSchoolEventIndividualsGroupBatch(user, eventId, { participants: playersCommit })
            ])
          : updateSchoolEventIndividualsGroupBatch(user, eventId, { participants: playersCommit });

        teamPromise
          .then(response => {
            if (isClubEvent(event)) {
              const [participants] = response;

              const studentsAreNotClubParticipants = itemsAddBuffer.filter(item => {
                return !participants.some(participant => {
                  return participant.userId === item.id;
                });
              });

              const isStudentsAreNotClubParticipantsExist = studentsAreNotClubParticipants.length > 0;

              if (isStudentsAreNotClubParticipantsExist) {
                const studentToAddData = {
                  participants: studentsAreNotClubParticipants.map(student => {
                    return {
                      userId: student.id,
                      permissionId: student.permissionId
                    };
                  })
                };

                return addClubStudents(user, clubId, studentToAddData);
              }

              return BPromise.resolve();
            }
          })
          .then(() => {
            history.push({
              pathname: '/events/event',
              search: `id=${eventId}`
            });
          });

        break;
      }

      case isTournamentEvent(event): {
        this.setState({
          isLoading: true
        });

        const { tournamentId } = event;
        const teamId = this.getTeamId();
        const currentSchoolTeam = tournamentTeams.find(item => {
          return item.schoolId === activeSchoolId;
        });
        const isCurrentSchoolTeamExist = typeof currentSchoolTeam !== 'undefined';

        const tournamentTeamsPlayers = propz.get(currentSchoolTeam, ['players'], []);
        const tournamentTeamsPlayersIds = tournamentTeamsPlayers.map(player => player.userId);

        const studentsToAddToTeam = itemsAddBuffer.filter(item => !tournamentTeamsPlayersIds.includes(item.id));
        const isStudentsToAddToTeamExist = studentsToAddToTeam.length > 0;

        const data = {
          add: itemsAddBuffer.map(item => ({ userId: item.id, permissionId: item.permissionId })),
          remove: itemsRemoveBuffer.map(item => item.playerId)
        };

        const isTeamExist = typeof teamId !== 'undefined';
        const team = this.getTeam();
        const teamPromise = isTeamExist ? BPromise.resolve(undefined) : addSchoolEventTeam(user, eventId, team);

        teamPromise
          .then(addTeam =>
            isTeamExist
              ? updateSchoolEventTeamPlayersBatch(user, eventId, teamId, data)
              : updateSchoolEventTeamPlayersBatch(user, eventId, addTeam.id, data)
          )
          .then(() => {
            switch (true) {
              case isCurrentSchoolTeamExist:
                if (isStudentsToAddToTeamExist) {
                  const addToTeamData = {
                    add: studentsToAddToTeam.map(item => ({ userId: item.id, permissionId: item.permissionId })),
                    remove: []
                  };

                  return updateSchoolTournamentTeamPlayersBatch(
                    user,
                    tournamentId,
                    currentSchoolTeam.id,
                    addToTeamData
                  );
                }

                break;

              case !isCurrentSchoolTeamExist:
                return this.createDefaultTournamentTeam();
            }
          })
          .then(newTeam => {
            if (!isCurrentSchoolTeamExist) {
              const addToTeamData = {
                add: studentsToAddToTeam.map(item => ({ userId: item.id, permissionId: item.permissionId })),
                remove: []
              };

              return updateSchoolTournamentTeamPlayersBatch(user, tournamentId, newTeam.id, addToTeamData);
            }
          })
          .then(() => {
            history.push({
              pathname: '/events/event',
              search: `id=${eventId}`
            });
          });

        break;
      }

      case isClubEvent(event) || teamName !== '': {
        this.setState({
          isLoading: true
        });

        const teamId = this.getTeamId();
        const data = {
          add: itemsAddBuffer.map(item => ({ userId: item.id, permissionId: item.permissionId })),
          remove: itemsRemoveBuffer.map(item => item.playerId)
        };

        const isTeamExist = typeof teamId !== 'undefined';
        const team = this.getTeam();
        const teamPromise = isTeamExist
          ? updateSchoolEventTeam(user, eventId, teamId, team)
          : addSchoolEventTeam(user, eventId, team);

        let addTeam;

        teamPromise
          .then(_addTeam => {
            addTeam = _addTeam;

            const updateSchoolEventTeamPlayersPromise = isTeamExist
              ? updateSchoolEventTeamPlayersBatch(user, eventId, teamId, data)
              : updateSchoolEventTeamPlayersBatch(user, eventId, addTeam.id, data);

            return isClubEvent(event)
              ? BPromise.all([getAllClubParticipants(user, clubId), updateSchoolEventTeamPlayersPromise])
              : updateSchoolEventTeamPlayersPromise;
          })
          .then(response => {
            if (isClubEvent(event)) {
              const [participants] = response;

              const studentsAreNotClubParticipants = itemsAddBuffer.filter(item => {
                const isSelectedStudentClubParticipant = participants.some(participant => {
                  return participant.userId === item.id;
                });

                return !isSelectedStudentClubParticipant;
              });

              const isStudentsAreNotClubParticipantsExist = studentsAreNotClubParticipants.length > 0;

              if (isStudentsAreNotClubParticipantsExist) {
                const studentToAddData = {
                  participants: studentsAreNotClubParticipants.map(student => {
                    return {
                      userId: student.id,
                      permissionId: student.permissionId
                    };
                  })
                };

                return addClubStudents(user, clubId, studentToAddData);
              }
            }
          })
          .then(() => {
            if (!isTeamExist && isScoreExist) {
              const { id } = event;
              const score = {
                score: activeSchoolScore.score,
                teamId: addTeam.id
              };

              return createTeamScore(user, id, score);
            }
          })
          .then(() => {
            history.push({
              pathname: '/events/event',
              search: `id=${eventId}`
            });
          });

        break;
      }

      default:
        this.setState({
          isError: true
        });

        break;
    }
  };

  createDefaultTournamentTeam = () => {
    const { user } = this.props;
    const { event } = this.state;
    const { activeSchoolId: schoolId } = user;
    const { tournament, tournamentId } = event;
    const { gender, ages, sportId } = tournament;
    const tournamentName = propz.get(tournament, ['name'], '');
    const schoolName = propz.get(user, ['activeSchoolName'], '');
    const name = `${schoolName} ${tournamentName}`;

    const teamData = {
      name,
      ages,
      gender,
      sportId,
      schoolId,
      teamType: TEAM_TYPE.ADHOC,
      isAutoEnrolledTeam: true,
      players: []
    };

    return addSchoolTournamentTeam(user, tournamentId, teamData);
  };

  onItemsFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.itemsFilters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (filterField === 'forms') {
      nextFilters.ages = [];
    }

    if (filterField === 'ages') {
      nextFilters.forms = [];
    }

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = ITEMS_COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      itemsFilters: nextFilters
    });
  };

  onItemsAccumulatorFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.itemsAccumulatorFilters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = ITEMS_COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      itemsAccumulatorFilters: nextFilters
    });
  };

  onItemsFilterClick = (event): void => {
    event.preventDefault();

    const { isShowItemsFilter } = this.state;

    this.setState({
      isShowItemsFilter: !isShowItemsFilter
    });
  };

  onItemsAccumulatorFilterClick = (event): void => {
    event.preventDefault();

    const { isShowItemsAccumulatorFilter } = this.state;

    this.setState({
      isShowItemsAccumulatorFilter: !isShowItemsAccumulatorFilter
    });
  };

  itemsSetCurrentPageParams = (currentPage: number): void => {
    const { user } = this.props;
    const { itemsAccumulator, itemsAddBuffer } = this.state;

    this.setState({
      isLoading: true
    });

    const filter = this.getItemsFilter(currentPage, itemsAccumulator, itemsAddBuffer);

    const method = getStudentsMethod(user);

    method(user, filter).then(students => {
      const studentsNormalized = this.getStudentsNormalized(students);

      this.setState({
        itemCurrentPage: currentPage,
        items: studentsNormalized,
        isLoading: false
      });
    });
  };

  itemsOnApplyFilterClick = () => {
    const { itemsAccumulator, itemsAddBuffer } = this.state;

    this.setState({
      isLoading: true
    });

    const filter = this.getItemsFilter(1, itemsAccumulator, itemsAddBuffer);

    const { user } = this.props;

    const method = getStudentsMethod(user);
    const getStudentsPromise = method(user, filter);
    const getStudentCountPromise = getSchoolStudentsWithClashesCount(user, filter);

    BPromise.all([getStudentsPromise, getStudentCountPromise]).then(([students, studentsCount]) => {
      const studentsNormalized = this.getStudentsNormalized(students);

      this.setState({
        items: studentsNormalized,
        isLoading: false,
        itemsCount: studentsCount.count,
        itemsAccumulatorSelected: [],
        itemsSelected: [],
        itemCurrentPage: 1,
        sortItemsDirection: '',
        sortItemsColumnsName: ''
      });
    });
  };

  itemsAccumulatorOnApplyFilterClick = () => {};
  // itemsAccumulatorOnApplyFilterClick = () => {
  //   const { itemsAccumulatorFilters, itemsRemoveBuffer, forms } = this.state;
  //   this.setState({
  //     isLoading: true
  //   });
  //
  //   const options = { forms };
  //
  //   let where = getServerFieldSectionWhere(itemsAccumulatorFilters, options);
  //   const ninIds = itemsRemoveBuffer.map(item => item.id);
  //   propz.set(where, ['id', '$nin'], ninIds);
  //
  //   const { user } = this.props;
  //
  //   const teamId = this.getTeamId();
  //   const eventId = this.getEventId();
  //
  //   const getTeamPlayersPromise = getAllSchoolEventTeamPlayers(user, eventId, teamId, where);
  //   const getTeamPlayersCountPromise = getSchoolEventTeamPlayersCount(user, eventId, teamId, where);
  //
  //   BPromise.all([getTeamPlayersPromise, getTeamPlayersCountPromise]).then(([teamPlayers, teamPlayersCount]) => {
  //     const teamPlayersNormalized = this.getTeamPlayersNormalized(teamPlayers);
  //
  //     this.setState({
  //       itemsAccumulator: teamPlayersNormalized,
  //       isLoading: false,
  //       itemsAccumulatorCount: teamPlayersCount.count,
  //       itemsAccumulatorSelected: [],
  //       itemsSelected: []
  //     });
  //   });
  // };

  itemsOnClearFilterClick = () => {
    const { itemsAccumulatorInitial, itemsAddBuffer, team, forms, event } = this.state;

    const defaultItemsFilter = this.getDefaultItemsFilter(team, event);

    this.setState({
      isLoading: true,
      itemsFilters: defaultItemsFilter
    });

    const teamId = this.getTeamId();
    const { players, id } = event;
    const playerUserIdsFromOtherTeams = players
      .filter(player => player.teamId !== teamId)
      .map(player => ({
        id: player.userId
      }));

    const { gender: itemsFiltersGender, ages: itemsFiltersAges = [] } = defaultItemsFilter;

    let where = {
      eventId: id,
      gender: {
        $in: itemsFiltersGender
      }
    };

    const allItems = [...itemsAccumulatorInitial, ...itemsAddBuffer, ...playerUserIdsFromOtherTeams];
    const ninIds = Lazy(allItems)
      .map(item => item.id)
      .uniq()
      .toArray();
    propz.set(where, ['_id', '$nin'], ninIds);

    const ages = itemsFiltersAges.length > 0 ? itemsFiltersAges : AGE_GROUPS.ENGLISH.map((age, index) => index);
    const formIds = forms.filter(form => ages.some(age => Number(form.age) === Number(age))).map(form => form.id);

    if (formIds.length > 0) {
      propz.set(where, ['formId', '$in'], formIds);
    }

    if (isHousesEvent(event)) {
      const houseId = this.getHouseId();
      propz.set(where, ['houseId'], houseId);
    }

    const { startTime, endTime } = event;

    // copy-paste from old frontend
    const startTimeDate = new Date(startTime);
    const startTimeCopy = new Date(startTime);
    startTimeCopy.setHours(startTimeDate.getHours() - 1);
    const intervalClashStartDate = Moment(startTimeCopy)
      .utc()
      .format();

    const endTimeDate = new Date(endTime);
    const endTimeCopy = new Date(endTime);
    endTimeCopy.setHours(endTimeDate.getHours() + 1);
    const intervalClashEndDate = Moment(endTimeCopy)
      .utc()
      .format();

    propz.set(where, ['intervalClashStartDate'], intervalClashStartDate);
    propz.set(where, ['intervalClashEndDate'], intervalClashEndDate);
    propz.set(where, ['fullClashStartDate'], startTime);
    propz.set(where, ['fullClashEndDate'], endTime);

    const filter = {
      where: where,
      limit: LIMIT,
      skip: DEFAULT_SKIP,
      order: DEFAULT_TEAM_PLAYERS_ORDER
    };

    const { user } = this.props;

    const method = getStudentsMethod(user);
    const getStudentsPromise = method(user, filter);
    const getSchoolsCountPromise = getSchoolStudentsWithClashesCount(user, filter);

    BPromise.all([getStudentsPromise, getSchoolsCountPromise]).then(([students, studentsCount]) => {
      const studentsNormalized = this.getStudentsNormalized(students);

      this.setState({
        items: studentsNormalized,
        isLoading: false,
        itemsCount: studentsCount.count,
        itemsAccumulatorSelected: [],
        itemsSelected: [],
        itemCurrentPage: 1,
        sortItemsColumnsName: '',
        sortItemsDirection: ''
      });
    });
  };

  itemsAccumulatorOnClearFilterClick = () => {};
  // itemsAccumulatorOnClearFilterClick = () => {
  //   this.setState({
  //     isLoading: true
  //   });
  //
  //   const { user } = this.props;
  //   const teamId = this.getTeamId();
  //   const { itemsRemoveBuffer } = this.state;
  //
  //   const eventId = this.getEventId();
  //
  //   const getTeamPlayersPromise = getAllSchoolEventTeamPlayers(user, eventId, teamId);
  //   const getTeamPlayersCountPromise = getSchoolEventTeamPlayersCount(user, eventId, teamId);
  //
  //   BPromise.all([getTeamPlayersPromise, getTeamPlayersCountPromise]).then(([teamPlayers, teamPlayersCount]) => {
  //     const teamPlayersNormalized = this.getTeamPlayersNormalized(teamPlayers);
  //     const nextTeamPlayersNormalized = teamPlayersNormalized.filter(teamPlayerNormalized =>
  //       itemsRemoveBuffer.every(item => item.id !== teamPlayerNormalized.id)
  //     );
  //
  //     this.setState({
  //       itemsAccumulator: nextTeamPlayersNormalized,
  //       isLoading: false,
  //       itemsAccumulatorCount: teamPlayersCount.count,
  //       itemsAccumulatorSelected: [],
  //       itemsSelected: [],
  //       itemsAccumulatorFilters: {}
  //     });
  //   });
  // };

  onSelectAllItemsClick = () => {
    const { itemsSelected, itemCurrentPage, itemsAccumulator, itemsAddBuffer } = this.state;

    this.setState({
      isLoading: true
    });

    const { user } = this.props;

    const filter = this.getItemsFilter(itemCurrentPage, itemsAccumulator, itemsAddBuffer, true);

    getSchoolStudentsWithoutClashes(user, filter).then(items => {
      const itemsFiltered = items.filter(item => {
        return itemsSelected.every(selectedItem => selectedItem.id !== item.id);
      });

      const studentsNormalizedFiltered = this.getStudentsNormalized(itemsFiltered);

      const nextSelectedItems = [...itemsSelected, ...studentsNormalizedFiltered];

      this.setState({
        itemsSelected: nextSelectedItems,
        isSelectAllItemsChecked: true,
        itemsAccumulatorSelected: [],
        isSelectAllItemsAccumulatorChecked: false,
        isLoading: false
      });
    });
  };

  onSelectAllItemsOnPageClick = () => {
    const { items, itemsSelected } = this.state;

    const itemsFiltered = items.filter(item => {
      return itemsSelected.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...itemsSelected, ...itemsFiltered];

    this.setState({
      itemsSelected: nextSelectedItems,
      isSelectAllItemsChecked: true,
      itemsAccumulatorSelected: [],
      isSelectAllItemsAccumulatorChecked: false
    });
  };

  onUnselectAllItemsClick = () => {
    this.setState({
      itemsSelected: [],
      isSelectAllItemsChecked: false,
      itemsAccumulatorSelected: [],
      isSelectAllItemsAccumulatorChecked: false
    });
  };

  onSelectAllItemsAccumulatorClick = () => {
    const { itemsAccumulatorSelected, itemsRemoveBuffer } = this.state;

    this.setState({
      isLoading: true
    });

    const { user } = this.props;

    const teamId = this.getTeamId();
    const eventId = this.getEventId();

    getTeamPlayersMethod(user)(user, eventId, teamId, {}).then(teamPlayers => {
      const teamPlayersNormalized = this.getTeamPlayersNormalized(teamPlayers);

      const teamPlayersFiltered = teamPlayersNormalized.filter(teamPlayer => {
        return itemsAccumulatorSelected.every(selectedItem => selectedItem.id !== teamPlayer.id);
      });

      const nextSelectedItems = [...itemsAccumulatorSelected, ...teamPlayersFiltered].filter(item => {
        return itemsRemoveBuffer.every(itemRemoveBuffer => itemRemoveBuffer.id !== item.id);
      });

      this.setState({
        itemsAccumulatorSelected: nextSelectedItems,
        isSelectAllItemsAccumulatorChecked: true,
        itemsSelected: [],
        isSelectAllItemsChecked: false,
        isLoading: false
      });
    });
  };

  onSelectAllItemsAccumulatorOnPageClick = () => {
    const { itemsAccumulator, itemsAccumulatorSelected, itemsRemoveBuffer } = this.state;

    const itemsFiltered = itemsAccumulator.filter(item => {
      return itemsAccumulatorSelected.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...itemsAccumulatorSelected, ...itemsFiltered].filter(item => {
      return itemsRemoveBuffer.every(itemRemoveBuffer => itemRemoveBuffer.id !== item.id);
    });

    this.setState({
      itemsAccumulatorSelected: nextSelectedItems,
      isSelectAllItemsAccumulatorChecked: true,
      itemsSelected: [],
      isSelectAllItemsChecked: false
    });
  };

  onUnselectAllItemsAccumulatorClick = () => {
    this.setState({
      itemsAccumulatorSelected: [],
      isSelectAllItemsAccumulatorChecked: false,
      itemsSelected: [],
      isSelectAllItemsChecked: false
    });
  };

  onSortItemsClick = (sortField: string) => {
    const { sortItemsDirection, sortItemsColumnsName, itemCurrentPage, itemsAccumulator, itemsAddBuffer } = this.state;

    const order = getOrder(sortField, sortItemsDirection, sortItemsColumnsName) as TABLE_SORT_DIRECTION;

    const filter = this.getItemsFilter(itemCurrentPage, itemsAccumulator, itemsAddBuffer);
    const { user } = this.props;

    const method = getStudentsMethod(user);
    const getStudentsPromise = method(user, filter);
    const getSchoolsCountPromise = getSchoolStudentsWithClashesCount(user, filter);

    BPromise.all([getStudentsPromise, getSchoolsCountPromise]).then(([students, studentsCount]) => {
      const studentsNormalized = this.getStudentsNormalized(students);

      this.setState({
        items: studentsNormalized,
        isLoading: false,
        itemsCount: studentsCount.count,
        itemsAccumulatorSelected: [],
        itemsSelected: [],
        itemCurrentPage: 1,
        sortItemsColumnsName: sortField,
        sortItemsDirection: order
      });
    });
  };

  onSortItemsAccumulatorClick = (sortField: string) => {};

  onChangeTeamName = event => {
    const teamName = event.target.value;

    this.setState({
      teamName
    });
  };

  goBack = () => {
    const { event } = this.state;
    const { history } = this.props;
    const eventId = event.id;
    history.push({
      pathname: '/events/event',
      search: `id=${eventId}`
    });
  };

  renderExitConfirmationModal = () => {
    const { isExitConfirmationModalOpen } = this.state;

    return (
      <SimpleModal isOpen={isExitConfirmationModalOpen} title={'Confirm the action'}>
        <div>You haven’t saved your team, are you sure you want to leave this page?</div>
        <div>
          <Button onClick={this.closeExitConfirmationModal} text={'Cancel'} customClass={'btn-secondary mt-3 mr-3'} />
          <Button onClick={this.goBack} text={'Ok'} customClass={'mt-3'} />
        </div>
      </SimpleModal>
    );
  };

  openExitConfirmationModal = () => {
    this.setState({
      isExitConfirmationModalOpen: true
    });
  };

  closeExitConfirmationModal = () => {
    this.setState({
      isExitConfirmationModalOpen: false
    });
  };

  goBackWithOrWithoutConfirmation = () => {
    const { itemsAddBuffer, itemsRemoveBuffer } = this.state;
    const isChangesInTeamExist = itemsAddBuffer.length > 0 || itemsRemoveBuffer.length > 0;

    isChangesInTeamExist ? this.openExitConfirmationModal() : this.goBack();
  };

  onClashClick = (student: SchoolStudentClash | SchoolEventTeamPlayerWithClashes) => {
    const { user } = this.props;
    const { clashes, firstName, lastName } = student;
    const { fullClashEvents, intervalClashEvents } = clashes;
    const fullClashEventIds = fullClashEvents.map(fullClashEvent => fullClashEvent._id);
    const intervalClashEventIds = intervalClashEvents.map(intervalClashEvent => intervalClashEvent._id);

    const allPlayerEventIds = [...fullClashEventIds, ...intervalClashEventIds];
    const allPlayerEventIdsUniq = Lazy(allPlayerEventIds)
      .uniq()
      .toArray();

    this.setState({
      isLoading: true
    });

    const eventsPromise: Promise<SchoolEvent>[] = allPlayerEventIdsUniq.map(eventId => getSchoolEvent(user, eventId));

    BPromise.all(eventsPromise).then(events => {
      const eventNames = events
        .map((event, index) => {
          const title = getEventTitle(event, user);
          const clash = getEventClash(event, fullClashEventIds, intervalClashEventIds);
          return `${index + 1}. ${title} ${clash}`;
        })
        .join('\n ');

      this.setState({
        isLoading: false,
        studentEventsModalText: `${firstName} ${lastName} is participating in the following events: \n${eventNames}`,
        isStudentEventsModalOpen: true
      });
    });
  };

  onCloseStudentEventsModalClick = () => {
    this.setState({
      isStudentEventsModalOpen: false
    });
  };

  renderStudentEventsModal(): React.ReactNode {
    const { isStudentEventsModalOpen, studentEventsModalText } = this.state;
    return (
      <SimpleModal
        isOpen={isStudentEventsModalOpen}
        title={'Info'}
        body={studentEventsModalText}
        buttonText={'Ok'}
        onButtonClick={this.onCloseStudentEventsModalClick}
      />
    );
  }

  render() {
    const {
      items,
      isLoading,
      itemsSelected,
      itemsAccumulatorSelected,
      itemsAccumulator,
      itemsFilters,
      isShowItemsFilter,
      isItemsFiltered,
      itemsCount,
      itemsAccumulatorCount,
      itemCurrentPage,
      itemsAccumulatorFilters,
      isShowItemsAccumulatorFilter,
      isItemsAccumulatorFiltered,
      isSelectAllItemsChecked,
      isSelectAllItemsAccumulatorChecked,
      sortItemsDirection,
      sortItemsColumnsName,
      sortItemsAccumulatorDirection,
      sortItemsAccumulatorColumnsName,
      itemsAddBuffer,
      itemsRemoveBuffer,
      forms,
      houses,
      event,
      teamName,
      isError,
      isShowFollowingEventsSection,
      editMode,
      isStudentEventsModalOpen
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const { user } = this.props;
    const { activeSchool } = user;
    const { ageGroupsNaming, isShowStudentEventClashes } = activeSchool;

    const itemsFiltersOptions = {
      ages: getSelectOptionForAge(forms, ageGroupsNaming),
      gender: getSelectOptionForGender(),
      forms: getSelectOptionForForms(forms),
      houses: getSelectOptionForHouses(houses)
    };

    const itemsAccumulatorFiltersOptions = {};

    const houseId = this.getHouseId();
    const house = event.housesData.find(eventHouse => eventHouse.id === houseId);
    const isShowTeamName = isTeamOrTwoOnTwoSportEvent(event) && !isClubEvent(event) && !isTournamentEvent(event);

    const classes = isStudentEventsModalOpen ? 'mt-3 modal-open' : 'mt-3';

    return (
      <div className={classes}>
        {this.renderStudentEventsModal()}
        {this.renderExitConfirmationModal()}
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              {isHousesEvent(event) && (
                <div className="form-group mb-3 mt-3">
                  <label className={'h2'}>House name:</label>
                  <select className="form-control" disabled value={houseId}>
                    <option key={house.id} value={house.id}>
                      {house.name}
                    </option>
                  </select>
                </div>
              )}
              {isShowTeamName && (
                <div className="form-group mb-3 mt-3">
                  <label className={'h2'}>Team name:</label>
                  <input
                    className="form-control"
                    type={'text'}
                    placeholder={'Team name'}
                    onChange={this.onChangeTeamName}
                    value={teamName}
                  />
                  {isError && <div className="mt-3 alert alert-danger">Enter team name</div>}
                </div>
              )}
            </div>
          </div>
        </div>

        <TwoPanelEditor
          //items
          items={items}
          itemsTitle={'Eligible students'}
          itemsColumns={isShowStudentEventClashes ? ITEMS_COLUMNS_WITH_CLASHES : ITEMS_COLUMNS}
          itemsSelected={itemsSelected}
          onItemClick={this.onItemClick}
          //items filters
          onItemsFilterChange={this.onItemsFilterChange}
          itemsFilters={itemsFilters}
          itemsFiltersOptions={itemsFiltersOptions}
          isShowItemsFilter={isShowItemsFilter}
          isItemsFiltered={isItemsFiltered}
          onItemsFilterClick={this.onItemsFilterClick}
          //accumulator
          itemsAccumulator={itemsAccumulator}
          itemsAccumulatorTitle={'Participants'}
          itemsAccumulatorColumns={
            isShowStudentEventClashes ? ITEMS_ACCUMULATOR_COLUMNS_WITH_CLASHES : ITEMS_ACCUMULATOR_COLUMNS
          }
          onItemAccumulatorClick={this.onItemAccumulatorClick}
          itemsAccumulatorSelected={itemsAccumulatorSelected}
          //accumulator filters
          onItemsAccumulatorFilterChange={this.onItemsAccumulatorFilterChange}
          itemsAccumulatorFilters={itemsAccumulatorFilters}
          itemsAccumulatorFiltersOptions={itemsAccumulatorFiltersOptions}
          isShowItemsAccumulatorFilter={isShowItemsAccumulatorFilter}
          isItemsAccumulatorFiltered={isItemsAccumulatorFiltered}
          onItemsAccumulatorFilterClick={this.onItemsAccumulatorFilterClick}
          //buttons
          onAddClick={this.onAddClick}
          onRemoveClick={this.onRemoveClick}
          //counts
          itemsCount={itemsCount}
          itemsAccumulatorCount={itemsAccumulatorCount}
          //current pages
          itemAccumulatorCurrentPage={1}
          itemCurrentPage={itemCurrentPage}
          itemsSetCurrentPageParams={this.itemsSetCurrentPageParams}
          //items filter buttons
          itemsOnApplyFilterClick={this.itemsOnApplyFilterClick}
          itemsOnClearFilterClick={this.itemsOnClearFilterClick}
          //items accumulator filter buttons
          itemsAccumulatorOnApplyFilterClick={this.itemsAccumulatorOnApplyFilterClick}
          itemsAccumulatorOnClearFilterClick={this.itemsAccumulatorOnClearFilterClick}
          //items select checkbox
          onSelectAllItemsClick={this.onSelectAllItemsClick}
          onSelectAllItemsOnPageClick={this.onSelectAllItemsOnPageClick}
          onUnselectAllItemsClick={this.onUnselectAllItemsClick}
          isSelectAllItemsChecked={isSelectAllItemsChecked}
          //items accumulator select checkbox
          onSelectAllItemsAccumulatorClick={this.onSelectAllItemsAccumulatorClick}
          onSelectAllItemsAccumulatorOnPageClick={this.onSelectAllItemsAccumulatorOnPageClick}
          onUnselectAllItemsAccumulatorClick={this.onUnselectAllItemsAccumulatorClick}
          isSelectAllItemsAccumulatorChecked={isSelectAllItemsAccumulatorChecked}
          //items sort
          sortItemsDirection={sortItemsDirection}
          sortItemsColumnsName={sortItemsColumnsName}
          onSortItemsClick={this.onSortItemsClick}
          //items accumulator sort
          sortItemsAccumulatorDirection={sortItemsAccumulatorDirection}
          sortItemsAccumulatorColumnsName={sortItemsAccumulatorColumnsName}
          onSortItemsAccumulatorClick={this.onSortItemsAccumulatorClick}
          itemsAddBuffer={itemsAddBuffer}
          itemsRemoveBuffer={itemsRemoveBuffer}
          onCellItemsClick={this.onClashClick}
          onCellItemsAccumulatorClick={this.onClashClick}
        />
        <div className="container-fluid mt-3 mb-3">
          <div className="row">
            <div className="col-md-12">
              {isShowFollowingEventsSection && (
                <SchoolEventClubEventRadioButtons onEditModeClick={this.onEditModeClick} editMode={editMode} />
              )}
              <Button
                onClick={this.goBackWithOrWithoutConfirmation}
                text={'Cancel'}
                customClass={'btn-lg mt-3 mr-3 btn-secondary'}
              />
              <Button text={'Save'} onClick={this.onClickSave} customClass={'btn-lg mt-3'} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
