import * as React from 'react';
import { Component } from 'react';
import { History } from 'history';
import { ChangePasswordForm } from './ChangePasswordForm';
import { ResetPasswordForm } from './ResetPasswordForm';
import { getFromHistory } from '../../helpers/history/history';
import { SimpleModal } from '../../components/SimpleModal/SimpleModal';
import { resetPassword, resetRequestPassword } from '../../helpers/service/public/password';
import './ResetPassword.scss';

interface Props {
  history: History;
}

interface State {
  secretKey: string;
  isResetPasswordModalOpen: boolean;
  isChangePasswordModalOpen: boolean;
}

export class ResetPassword extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      secretKey: undefined,
      isResetPasswordModalOpen: false,
      isChangePasswordModalOpen: false
    };
  }
  componentDidMount(): void {
    const { history } = this.props;
    const secretKey = getFromHistory(history, 'secretKey');
    this.setState({
      secretKey
    });
  }

  redirectToLoginPage = (): void => {
    const { history } = this.props;
    history.push('/login');
  };

  onChangePasswordFormSubmit = (data): void => {
    const { secretKey } = this.state;
    resetPassword(data.password, secretKey).then(res => {
      this.setState({
        isChangePasswordModalOpen: true
      });
    });
  };

  onResetPasswordFormSubmit = (data): void => {
    resetRequestPassword(data.email).then(res => {
      this.setState({
        isResetPasswordModalOpen: true
      });
    });
  };

  renderResetPasswordModal(): React.ReactNode {
    const { isResetPasswordModalOpen } = this.state;
    return (
      <SimpleModal
        isOpen={isResetPasswordModalOpen}
        title={'Info'}
        body={'Please check your email inbox, and click the link in the email you received to reset your password.'}
        buttonText={'Ok'}
        onButtonClick={this.redirectToLoginPage}
      />
    );
  }

  renderChangePasswordModal(): React.ReactNode {
    const { isChangePasswordModalOpen } = this.state;
    return (
      <SimpleModal
        isOpen={isChangePasswordModalOpen}
        title={'Info'}
        body={'Password changed'}
        buttonText={'Ok'}
        onButtonClick={this.redirectToLoginPage}
      />
    );
  }

  render() {
    const { secretKey } = this.state;

    return (
      <div>
        {this.renderResetPasswordModal()}
        {this.renderChangePasswordModal()}
        <div className="container">
          <div className="row">
            <div className="offset-xl-3 col-xl-6">
              <div className={'eResetPasswordWrapper'}>
                <div className="eResetPasswordFormWrapper">
                  <div className="eResetPasswordFormImg">
                    <img src="/dist/images/logoImage.png" />
                    <img src="/dist/images/logoText.png" />
                  </div>

                  <div className="mt-3 mb-3">
                    {secretKey ? (
                      <ChangePasswordForm
                        redirectToLoginPage={this.redirectToLoginPage}
                        onSubmit={this.onChangePasswordFormSubmit}
                      />
                    ) : (
                      <ResetPasswordForm
                        redirectToLoginPage={this.redirectToLoginPage}
                        onSubmit={this.onResetPasswordFormSubmit}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
