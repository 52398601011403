import * as React from 'react';
import { Component } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { AppUser } from 'Src/views/App/App';
import { History, Location } from 'history';
import * as propz from 'propz';
import { Basket as BasketModel, BasketItem } from 'Src/models/basket';
import { getBasket, deleteOrderFromBasket, createBasketPayment } from 'Src/helpers/service/parent/basket';
import { BasketOrder } from './BasketOrder/BasketOrder';
import { Loader } from 'Src/components/Loader/Loader';
import { Button } from 'Src/components/Button/Button';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';

const MINUTE = 1000 * 5;

interface Props {
  user: AppUser;
  history: History;
  location: Location;
  setNeedUpdate: () => void;
}

interface State {
  isLoading: boolean;
  basket: BasketModel;
  basketOrderWithNearestDeadline: BasketItem;
  orderId: string;
  basketRequestIntervalId: number;
  isConfirmationRemoveFromBasketModalOpen: boolean;
  isDeadlineTimeModalOpen: boolean;
}

export class Basket extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      basket: undefined,
      basketOrderWithNearestDeadline: undefined,
      orderId: undefined,
      basketRequestIntervalId: undefined,
      isConfirmationRemoveFromBasketModalOpen: false,
      isDeadlineTimeModalOpen: false
    };
  }

  componentDidMount() {
    this.setBasket().then(() => this.setBasketRequestInterval());
  }

  componentWillUnmount() {
    const { basketRequestIntervalId } = this.state;

    clearInterval(basketRequestIntervalId);
  }

  onCheckoutClick = () => {
    this.setState({
      isLoading: true
    });

    const { user } = this.props;
    const { basket } = this.state;
    const messageIds = basket.items.map(item => item.messageId);

    const cancelUrl = document.location.href;
    const successUrl = `${document.location.origin}/paymentSuccess`;

    const data = { successUrl, cancelUrl, messageIds };

    let sessionId;
    let publicKey;

    createBasketPayment(user, basket.id, data)
      .then(session => {
        sessionId = session.id;
        publicKey = session.publicKey;
      })
      .then(result => {
        return loadStripe(publicKey);
      })
      .then(stripe => {
        return stripe.redirectToCheckout({
          sessionId: sessionId
        });
      })
      .then(result => {
        this.setState({
          isLoading: false
        });

        if (result.error) {
          alert(result.error.message);
        }
      })
      .catch(err => {
        this.setState({
          isLoading: false
        });

        if (typeof err !== 'undefined') {
          //  TODO: for prod replace on  "contact support"
          const displayMessageText = propz.get(err, ['response', 'data', 'details', 'text']);
          console.log(err);
          alert(`Error: ${displayMessageText}`);
        }
      });
  };

  setBasket = () => {
    const { user, setNeedUpdate } = this.props;

    this.setState({
      isLoading: true
    });

    return getBasket(user).then(basket => {
      this.setState({
        basket,
        isLoading: false
      });

      setNeedUpdate();
    });
  };

  setBasketRequestInterval = () => {
    const { basket } = this.state;

    const isBasketExist = typeof basket !== 'undefined';
    const isBasketOrdersExist = isBasketExist && basket.items.length > 0;

    if (isBasketOrdersExist) {
      const { items } = basket;

      const basketOrderWithNearestDeadline = items.reduce((initial, item) => {
        const itemDeadline = new Date(item.deadline);
        const initialDeadline = new Date(initial.deadline);

        return itemDeadline > initialDeadline ? initial : item;
      });

      const { deadline: nearestDeadline } = basketOrderWithNearestDeadline;

      const nearestDeadlineDate = new Date(nearestDeadline);

      const basketRequestIntervalId = setInterval(() => {
        const now = new Date();

        console.log('nearestDeadlineDate', nearestDeadlineDate);

        if (now >= nearestDeadlineDate) {
          this.openDeadlineTimeModal();

          clearInterval(basketRequestIntervalId);
        }
      }, MINUTE);

      this.setState({
        basketRequestIntervalId,
        basketOrderWithNearestDeadline
      });
    }
  };

  onDeleteOrderFromBasketClick = orderId => {
    const { user, setNeedUpdate } = this.props;

    this.setState({
      isLoading: true
    });

    deleteOrderFromBasket(user, orderId).then(() => {
      setNeedUpdate();

      this.closeConfirmationRemoveFromBasketModal();

      this.setBasket();
    });
  };

  renderDeadlineTimeModal = () => {
    const { isDeadlineTimeModalOpen, basketOrderWithNearestDeadline } = this.state;

    const clubName = propz.get(basketOrderWithNearestDeadline, ['clubName'], '');

    return (
      <SimpleModal
        isOpen={isDeadlineTimeModalOpen}
        title="Confirm the action"
        body={`The checkout deadline for "${clubName}" has expired and it will be removed from your basket. You will need to add this item to the basket again to make a payment.`}
        buttonText="Ok"
        onButtonClick={this.closeDeadlineTimeModal}
        onCloseIconClick={this.closeDeadlineTimeModal}
      />
    );
  };

  openDeadlineTimeModal = () => {
    this.setState({
      isDeadlineTimeModalOpen: true
    });
  };

  closeDeadlineTimeModal = () => {
    this.setState({
      isDeadlineTimeModalOpen: false
    });

    this.setBasket().then(() => this.setBasketRequestInterval());
  };

  renderConfirmationRemoveFromBasketModal = () => {
    const { isConfirmationRemoveFromBasketModalOpen, orderId } = this.state;

    return (
      <SimpleModal
        isOpen={isConfirmationRemoveFromBasketModalOpen}
        title="Confirm the action"
        body="You are going to remove the item from your basket. Are you sure?"
        buttonText="Confirm"
        onButtonClick={() => this.onDeleteOrderFromBasketClick(orderId)}
        onCloseClick={this.closeConfirmationRemoveFromBasketModal}
      />
    );
  };

  openConfirmationRemoveFromBasketModal = (orderId: string) => {
    this.setState({
      isConfirmationRemoveFromBasketModalOpen: true,
      orderId
    });
  };

  closeConfirmationRemoveFromBasketModal = () => {
    this.setState({
      isConfirmationRemoveFromBasketModalOpen: false,
      orderId: undefined
    });
  };

  render() {
    const { basket, isLoading } = this.state;

    const orderAmount = propz.get(basket, ['orderAmount'], 0);
    const basketOrders = propz.get(basket, ['items'], []);

    const basketOrdersCount = basketOrders.length;
    const isOrdersExist = basketOrders.length > 0;

    if (isLoading) {
      return <Loader />;
    }

    return (
      <div className="mt-4">
        {this.renderConfirmationRemoveFromBasketModal()}
        {this.renderDeadlineTimeModal()}
        <h4>Your Basket</h4>

        <hr />

        <div className="d-flex flex-wrap mt-3 align-items-start">
          {isOrdersExist ? (
            <>
              <div className="col-12 col-md-8 order-1 order-md-0 pl-0 pr-0 pr-md-3">
                {basketOrders.map(order => {
                  return (
                    <div className="mb-2" key={order.id}>
                      <BasketOrder
                        order={order}
                        onDeleteOrderFromBasketClick={orderId => this.openConfirmationRemoveFromBasketModal(orderId)}
                      />
                    </div>
                  );
                })}
              </div>

              <div className="col-12 col-md-4 mb-3 mb-md-0 order-0 order-md-1 p-3 border rounded ">
                <p>
                  {`Subtotal ${basketOrdersCount} item(s):`} <span className="font-weight-bold">£{orderAmount}</span>
                </p>

                <Button text="Proceed to checkout" onClick={this.onCheckoutClick} customClass="btn-sm btn-block mt-2" />

                <div className="mt-3">
                  <p className="text-primary">
                    When you proceed to the checkout you will be forwarded to the Stripe secure payment page. Stripe is
                    a payment platform that we are partnered with for processing payments. Paying via Stripe means you
                    agree with their T&C's and their Privacy Policy, these can be found on their website.
                  </p>

                  <p className="text-primary">
                    Squad In Touch does not have access to or store any payment information associated with you; when
                    you agree to save your card details on Stripe's secure payment page you are agreeing to Stripe
                    holding your details, not Squad In Touch.
                  </p>

                  <p className="font-weight-bold">
                    It should be noted that as part of your booking a booking fee is included, therefore if for any
                    reason the school issues you a refund the booking fee is non-refundable, we thank you for your
                    understanding.
                  </p>
                </div>
              </div>
            </>
          ) : (
            <div className="col-12">The basket is empty</div>
          )}
        </div>
      </div>
    );
  }
}
