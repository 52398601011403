import * as React from 'react';
import * as propz from 'propz';
import {
  SchoolEvent,
  SchoolEventAttendance,
  SchoolEventIndividualData,
  SchoolEventTeamDataPlayer
} from '../../models/event';
import { getFullName } from 'Src/helpers/autocomplete/autocomplete';
import { Switch } from '../Switch/Switch';
import './SchoolEventAttendanceSummary.scss';

interface Props {
  schoolEvent: SchoolEvent;
  allTeamPlayers: (SchoolEventIndividualData | SchoolEventTeamDataPlayer)[];
  attendancePlayers: SchoolEventAttendance[];
  onChangeEventAttendanceClick: (value: boolean, player: SchoolEventIndividualData | SchoolEventTeamDataPlayer) => void;
  onChangeEventAllAttendanceClick: (value: boolean) => void;
  isEditAttendance: boolean;
  isEditAllAttendance: boolean;
}

export function SchoolEventAttendanceSummary(props: Props) {
  const {
    allTeamPlayers,
    isEditAttendance,
    attendancePlayers,
    onChangeEventAllAttendanceClick,
    onChangeEventAttendanceClick,
    isEditAllAttendance
  } = props;

  const renderAttendanceEditMode = (player: SchoolEventIndividualData | SchoolEventTeamDataPlayer) => {
    const playerAttendance = attendancePlayers.find(attendance => attendance.userId === player.userId);
    const value = propz.get(playerAttendance, ['isAttendance'], true);
    return (
      <div className="d-flex justify-content-center">
        <div className="mr-3">Absent</div>
        <Switch
          key={player.userId}
          value={value}
          onChange={() => onChangeEventAttendanceClick(!value, player)}
          text={''}
          name={`attendance_${player.userId}`}
        />
        <div className="ml-1">Present</div>
      </div>
    );
  };

  const renderAllAttendanceEditMode = () => {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <div className="mr-3">Absent</div>
        <Switch
          key={'all'}
          value={isEditAllAttendance}
          onChange={() => onChangeEventAllAttendanceClick(!isEditAllAttendance)}
          text={''}
          name={`all_attendance`}
        />
        <div className="ml-1">Present</div>
      </div>
    );
  };

  const renderAttendanceViewMode = (player: SchoolEventIndividualData | SchoolEventTeamDataPlayer) => {
    const playerAttendance = attendancePlayers.find(attendance => attendance.userId === player.userId);
    switch (true) {
      case typeof playerAttendance === 'undefined':
        return '';
      case playerAttendance.isAttendance:
        return <div className="d-flex align-items-center justify-content-center">Present</div>;
      case !playerAttendance.isAttendance:
        return <div className="eAbsentPlayersAttendance">Absent</div>;
    }
  };

  const rows = allTeamPlayers.map((player, index) => {
    const fullName = getFullName(player);
    return (
      <tr key={`attendance_summary_${index}`}>
        <td>{index + 1}</td>
        <td>
          <div className="font-weight-bold">{fullName}</div>
        </td>
        <td>
          <div>{isEditAttendance ? renderAttendanceEditMode(player) : renderAttendanceViewMode(player)}</div>
        </td>
        <td />
      </tr>
    );
  });

  const emptyRow = (
    <tr key={'players_empty_row'}>
      <td>No participants</td>
    </tr>
  );

  return (
    <table className={'table table-sm mPre'}>
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th className="text-center">Attendance</th>
          <th />
        </tr>
        {isEditAttendance && (
          <tr>
            <th />
            <th />
            <th>{renderAllAttendanceEditMode()}</th>
            <th />
          </tr>
        )}
      </thead>
      <tbody>{rows.length > 0 ? rows : emptyRow}</tbody>
    </table>
  );
}
