import * as React from 'react';
import { Component } from 'react';
import * as propz from 'propz';
import * as Moment from 'moment';
import { AppUser } from 'Src/views/App/App';
import { MessageField } from 'Src/models/message';
import { isNumeric } from 'Src/helpers/common/common';
import { Select } from 'Src/components/Select/Select';
import {
  getSelectOptionForBooleanQuestion,
  getSelectOptionForClubInvitationEnumQuestion
} from 'Src/helpers/table/select';
import { QUESTIONS_TEMPLATE_TYPE } from 'Src/consts/message';
import { EventInvitationMessage } from '../../../../../models/message';
import { Map } from 'Src/components/Map/Map';
import { SPORT_GENDER_SERVER_TO_CLIENT_MAPPING } from '../../../../../consts/common';
import './ParentalConsentRequestForm.scss';
import { VENUE_SERVER_TO_CLIENT_MAPPING } from '../../../../../consts/venue';

interface Props {
  user: AppUser;
  message: EventInvitationMessage;
  onDeclineClick: () => void;
  onAcceptClick: (fields: MessageField[]) => void;
  closeConsentRequestModal: () => void;
  eventName?: string;
}

interface State {
  fields: MessageField[];
  isMapVisible: boolean;
  activeSelectIndex: number[];
  errors: Error[];
}

interface Error {
  isError: boolean;
  errorText: string;
}

export class ParentalConsentRequestForm extends Component<Props, State> {
  constructor(props) {
    super(props);
    const { message } = this.props;
    const fields = propz.get(message, ['fields']);

    let fieldsUpdated;
    let activeSelectIndex = [];
    let errors = [];

    const isFieldsExist = typeof fields !== 'undefined';
    if (isFieldsExist) {
      fieldsUpdated = fields.map((field, index) => {
        activeSelectIndex[index] = 0;
        errors[index] = { isError: false, errorText: '' };
        return { ...field, value: '' };
      });
    }

    this.state = {
      fields: isFieldsExist ? fieldsUpdated : [],
      isMapVisible: false,
      activeSelectIndex: isFieldsExist ? activeSelectIndex : [],
      errors: isFieldsExist ? errors : []
    };
  }

  getAges(): string {
    const { message } = this.props;
    const ages = propz.get(message, ['eventData', 'ages']);
    const isAgesExist = typeof ages !== 'undefined';
    let result = '';

    if (isAgesExist) {
      if (ages.length === 0) {
        result = 'All ages';
      } else {
        result = ages.map(ageItem => (ageItem === 0 ? 'Reception' : 'Y' + ageItem)).join(', ');
      }
    }
    return result;
  }

  getVenue(): string {
    const { message } = this.props;
    const postcode = propz.get(message, ['eventData', 'venue', 'postcodeData', 'postcode'], '');
    const placeName = propz.get(
      message,
      ['eventData', 'venue', 'placeData', 'name'],
      VENUE_SERVER_TO_CLIENT_MAPPING.HOME
    );
    const venueType = propz.get(message, ['eventData', 'venue', 'venueType'], VENUE_SERVER_TO_CLIENT_MAPPING.TBD);

    switch (true) {
      case venueType === VENUE_SERVER_TO_CLIENT_MAPPING.TBD:
        return 'TBD';
      case typeof placeName !== 'undefined':
        return `${placeName}, ${postcode}`;
      default:
        return postcode;
    }
  }

  onSubmit = () => {
    const { fields, errors } = this.state;
    let submitAbility = true;
    let fieldsUpdated = [...fields];

    const errorsUpdated = errors.map(item => {
      return {
        isError: false,
        errorText: ''
      };
    });

    fields.forEach((field, index) => {
      const isNumericField = isNumeric(field.value);
      const isNumberType = field.type === QUESTIONS_TEMPLATE_TYPE.NUMBER;
      const isBooleanType = field.type === QUESTIONS_TEMPLATE_TYPE.BOOLEAN;
      const isEnumType = field.type === QUESTIONS_TEMPLATE_TYPE.ENUM;
      const isFieldEmpty = field.value === '';

      if (isBooleanType && isFieldEmpty) {
        const options = getSelectOptionForBooleanQuestion();
        const value = options[0].value;
        propz.set(fieldsUpdated[index], ['value'], value);
      }

      if (isEnumType && isFieldEmpty) {
        const options = getSelectOptionForClubInvitationEnumQuestion(field.enumOptions);
        const value = options[0].value;
        propz.set(fieldsUpdated[index], ['value'], value);
      }

      if (isNumberType && !isNumericField && !isFieldEmpty) {
        errorsUpdated[index].isError = true;
        errorsUpdated[index].errorText = 'please enter a number';
        submitAbility = false;
      }

      const isFieldUpdatedEmpty = fieldsUpdated[index].value === '';

      if (isFieldUpdatedEmpty && field.isRequired) {
        errorsUpdated[index].isError = true;
        errorsUpdated[index].errorText = 'required';
        submitAbility = false;
      }
    });

    if (submitAbility) {
      this.props.onAcceptClick(fieldsUpdated);
    } else {
      this.setState({
        errors: errorsUpdated
      });
    }
  };

  toggleMap = () => {
    const { isMapVisible } = this.state;
    this.setState({
      isMapVisible: !isMapVisible
    });
  };

  render() {
    const { message, eventName } = this.props;
    const { fields, isMapVisible, activeSelectIndex, errors } = this.state;

    const isFieldsExist = fields.length > 0;
    const firstName = propz.get(message, ['playerDetailsData', 'firstName'], '');
    const lastName = propz.get(message, ['playerDetailsData', 'lastName'], '');
    const fullName = `${firstName} ${lastName}`;
    const sport = propz.get(message, ['eventData', 'sport', 'name'], '');
    const startTime = propz.get(message, ['eventData', 'startTime'], '');
    const startTimeFormatted = Moment(startTime).format('DD.MM.YYYY / HH:mm');
    const postcode = this.getVenue();
    const point = propz.get(message, ['eventData', 'venue', 'point']);
    const gender = SPORT_GENDER_SERVER_TO_CLIENT_MAPPING[propz.get(message, ['eventData', 'gender'])];
    const deadlineForAnswers = propz.get(message, ['deadlineForAnswers']);
    const deadlineForAnswersFormatted = Moment(deadlineForAnswers).format('DD.MM.YYYY / HH:mm');
    const isDeadlineExist = typeof deadlineForAnswers !== 'undefined';
    const now = new Date();
    const isDeadlineArrived = Number(new Date(deadlineForAnswers)) < Number(now);
    const ages = this.getAges();

    const fieldsUpdated = [...fields];

    const picUrl = propz.get(message, ['schoolData', 'pic'], '');
    const isSchoolLogoExist = picUrl !== '';

    return (
      <>
        <div className={'eConsentRequestFormRow'}>
          <img
            src={isSchoolLogoExist ? picUrl : '/dist/images/no-image.jpg'}
            alt="school logo"
            className={'bConsentRequestFormLogo'}
          />

          <div className="pl-4">
            <div className={'mConsentRequestFormBold'}>{`${eventName}`}</div>

            <div className={'mConsentRequestFormBold'}>{`${sport}`}</div>

            <div>
              <span className={'mConsentRequestFormBold'}>{'Years:'} </span>
              {ages}
            </div>

            <div>
              <span className={'mConsentRequestFormBold'}>{'Gender:'} </span>
              {gender}
            </div>

            <div>
              <span className={'mConsentRequestFormBold'}>{'Start time:'} </span>
              {startTimeFormatted}
            </div>

            <div>
              <span className={'mConsentRequestFormBold'}>{'Venue:'} </span>
              {postcode}
            </div>
          </div>
        </div>

        <div className={'mConsentRequestFormBold'}>
          {`Do you give your consent for ${fullName} to take part in this fixture?`}
        </div>

        {isFieldsExist &&
          fieldsUpdated.map((field, index) => (
            <div key={`club_invitation_form_field_${index}`}>
              <div className={field.isRequired ? 'font-weight-bold' : ''}>
                {field.heading} {field.isRequired ? '(required)' : ''}
              </div>

              {(field.type === QUESTIONS_TEMPLATE_TYPE.STRING || field.type === QUESTIONS_TEMPLATE_TYPE.NUMBER) && (
                <input
                  type="text"
                  className="form-control"
                  value={field.value}
                  onChange={event => {
                    propz.set(fieldsUpdated[index], ['value'], event.target.value);
                    this.setState({ fields: fieldsUpdated });
                  }}
                />
              )}
              {field.type === QUESTIONS_TEMPLATE_TYPE.BOOLEAN && (
                <Select
                  onChange={value => {
                    const options = getSelectOptionForBooleanQuestion();
                    const nextIndex = options.findIndex(option => option.value === value);
                    const activeSelectIndexUpdated = [...activeSelectIndex];
                    activeSelectIndexUpdated[index] = nextIndex;
                    propz.set(fieldsUpdated[index], ['value'], value);

                    this.setState({
                      activeSelectIndex: activeSelectIndexUpdated
                    });
                  }}
                  options={getSelectOptionForBooleanQuestion()}
                  activeResultIndex={activeSelectIndex[index]}
                  customClass={'form-control'}
                />
              )}
              {field.type === QUESTIONS_TEMPLATE_TYPE.ENUM && (
                <Select
                  onChange={value => {
                    const options = getSelectOptionForClubInvitationEnumQuestion(field.enumOptions);
                    const nextIndex = options.findIndex(option => option.value === value);
                    const activeSelectIndexUpdated = [...activeSelectIndex];
                    activeSelectIndexUpdated[index] = nextIndex;
                    propz.set(fieldsUpdated[index], ['value'], value);

                    this.setState({
                      activeSelectIndex: activeSelectIndexUpdated
                    });
                  }}
                  options={getSelectOptionForClubInvitationEnumQuestion(field.enumOptions)}
                  activeResultIndex={activeSelectIndex[index]}
                  customClass={'form-control'}
                />
              )}
              {errors[index].isError && <div className="alert alert-danger mt-3">{errors[index].errorText}</div>}
            </div>
          ))}

        <div className="eClubInvitationFormRow">
          <img src={'/dist/images/icons/pin.png'} alt="pin icon" className={'bConsentRequestFormIcon'} />
          <div className="bConsentRequestFormMap" onClick={this.toggleMap}>
            View map
          </div>
        </div>

        {isMapVisible && <Map point={point} customStylingClass="mb-3 eClubWizardMap" isMarkerDraggable={false} />}

        {isDeadlineExist && isDeadlineArrived && (
          <div className="eConsentRequestFormRow">
            <span className="mConsentRequestFormBold">Unfortunately, booking for this activity has now closed.</span>
          </div>
        )}

        {isDeadlineExist && (
          <div className="eConsentRequestFormRow">{`Deadline for answers: ${deadlineForAnswersFormatted}`}</div>
        )}

        <button onClick={this.props.closeConsentRequestModal} className="btn btn-secondary mr-2">
          Cancel
        </button>
        {!(isDeadlineExist && isDeadlineArrived) && (
          <>
            <button onClick={this.props.onDeclineClick} className="btn btn-primary mr-2">
              Decline
            </button>
            <button onClick={this.onSubmit} className="btn btn-primary mr-2">
              Accept
            </button>
          </>
        )}
      </>
    );
  }
}
