export const TIME_FORMAT = 'HH:MM';
export const TIME = 'HH:mm';
export const DATE_FORMAT = 'DD-MM-YYYY';
export const DATE_TIME_FORMAT = 'DD-MM-YYYY HH:mm';

export const DATE_FORMAT_FULL_MONTH_US = 'MMMM DD YYYY';
export const DATE_FORMAT_FULL_MONTH = 'DD MMMM YYYY';

export const DATE_TIME_MASK = [
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  ':',
  /\d/,
  /\d/
];

const DD = [/[0-3]/, /\d/];
const MM = [/[0-1]/, /\d/];
const YYYY = [/[2]/, /[0-2]/, /\d/, /\d/];
export const HH_MM = [/[0-2]/, /\d/, ':', /[0-5]/, /\d/];
export const UK_INPUT_MASK = [...DD, '-', ...MM, '-', ...YYYY, '/', ...HH_MM];
export const UK_INPUT_MASK_VALIDATION = 'DD-MM-YYYY/HH:mm';

export const WEEK_DAYS = {
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
  SUNDAY: 'SUNDAY'
};

export const WEEK_DAYS_SERVER_TO_CLIENT_MAPPING = {
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
  SUNDAY: 'Sunday'
};

export const WEEK_DAYS_SHORT = {
  MONDAY: 'MON',
  TUESDAY: 'TUE',
  WEDNESDAY: 'WED',
  THURSDAY: 'THU',
  FRIDAY: 'FRI',
  SATURDAY: 'SAT',
  SUNDAY: 'SUN'
};

export const WEEK_DAYS_SHORT_SERVER_TO_CLIENT_MAPPING = {
  MONDAY: 'Mon',
  TUESDAY: 'Tue',
  WEDNESDAY: 'Wed',
  THURSDAY: 'Thu',
  FRIDAY: 'Fri',
  SATURDAY: 'Sat',
  SUNDAY: 'Sun'
};

export type WEEK_DAYS_TYPE = 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';

export const EMPTY_STRING = '';

export const DEFAULT_TIME_CONTENT_CELL = '--:--';
