import StorageHelper from 'Src/helpers/storage/storage';
import { LoginSession, RoleSession } from '../../models/sessions';

export function getSessions(): { loginSession: LoginSession; roleSession: RoleSession } {
  return {
    loginSession: StorageHelper.Cookie.get('loginSession'),
    roleSession: StorageHelper.SessionStorage.get('roleSession')
  };
}

export function getUserIdFromSession(loginSession: LoginSession, roleSession: RoleSession) {
  switch (true) {
    case typeof roleSession !== 'undefined':
      return roleSession.userId;
    case typeof loginSession !== 'undefined':
      return loginSession.userId;
    default:
      return undefined;
  }
}

export function getUserRoleFromSession(roleSession: RoleSession) {
  return typeof roleSession !== 'undefined' ? roleSession.role : undefined;
}

export function getActiveSchoolId() {
  return StorageHelper.SessionStorage.get('activeSchoolId');
}

export function getActiveSchoolName() {
  return StorageHelper.SessionStorage.get('activeSchoolName');
}

export function getActivePermission() {
  return StorageHelper.SessionStorage.get('activePermission');
}

export function clearSessions(): void {
  StorageHelper.SessionStorage.remove('activeSchoolId');
  StorageHelper.SessionStorage.remove('activeSchoolName');
  StorageHelper.Cookie.remove('loginSession');
  StorageHelper.Cookie.remove('loginSession', { domain: '.squadintouch.com' });
  StorageHelper.SessionStorage.remove('roleSession');
  StorageHelper.SessionStorage.remove('activePermission');
}

export function clearRole(): void {
  StorageHelper.SessionStorage.remove('activeSchoolId');
  StorageHelper.SessionStorage.remove('activeSchoolName');
  StorageHelper.SessionStorage.remove('roleSession');
  StorageHelper.SessionStorage.remove('activePermission');
}
