import * as React from 'react';
import { LogOut } from '../LogOut/LogOut';

interface Props {
  onLogoutClick: (event) => void;
  history: any;
}

export const TopNavigationReduced: React.FunctionComponent<Props> = props => {
  const { onLogoutClick, history } = props;

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light d-flex justify-content-between">
      <a className="navbar-brand" href="" onClick={event => event.preventDefault()}>
        <img src="/dist/images/logo.svg" className="d-inline-block align-top" alt="" />
      </a>
      <div className="d-flex align-items-center">
        <LogOut onLogoutClick={onLogoutClick} history={history} />
      </div>
    </nav>
  );
};

TopNavigationReduced.displayName = 'TopNavigationReduced';
