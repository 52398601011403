import * as React from 'react';
import { FunctionComponent } from 'react';
import { SelectOption } from '../../helpers/table/table';
import * as classNames from 'classnames';
import './SelectField.css';

interface Props {
  items: SelectOption[];
  field: string;
  value: any;
  onChange: (event, value: string) => void;
  onEnterPress?: (event) => void;
  isEmptyOptionRequired?: boolean;
  customClass?: string;
}

export const SelectField: FunctionComponent<Props> = props => {
  const { customClass, onEnterPress } = props;
  const options = props.items.map(item => {
    return (
      <option key={item.value} value={item.value}>
        {item.text}
      </option>
    );
  });

  const emptyOption = <option value={''} />;
  const classes = classNames('form-control', 'mMaxWidth250', customClass);

  return (
    <select
      value={props.value}
      className={classes}
      onKeyDown={onEnterPress}
      onChange={event => props.onChange(event, props.field)}
    >
      {props.isEmptyOptionRequired && emptyOption}
      {options}
    </select>
  );
};

SelectField.displayName = 'SelectField';
