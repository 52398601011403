import * as React from 'react';
import { FunctionComponent } from 'react';
import { ROLE_SERVER_TO_CLIENT_MAPPING, STAFF_LIST } from '../../consts/user';
import { Button } from '../Button/Button';

interface Props {
  staff: any;
  onEditClick: (permissionId: string) => void;
  onAddClick: () => void;
}

export const StaffPermissions: FunctionComponent<Props> = props => {
  const { permissions } = props.staff;

  if (typeof props.staff.permissions === 'undefined' || permissions.length === 0) {
    return <div>The user has no roles</div>;
  }

  const rows: React.ReactNodeArray = permissions.map(permission => {
    const forms = permission.forms || [];
    const houses = permission.houses || [];

    return (
      <tr key={permission.id}>
        <td>{ROLE_SERVER_TO_CLIENT_MAPPING[permission.preset]}</td>
        <td>{forms.map(form => form.name).join()}</td>
        <td>{houses.map(house => house.name).join()}</td>
        <td>
          {STAFF_LIST.findIndex(preset => preset === permission.preset) !== -1 && (
            // now we can edit only staff permissions
            // to remove this condition when editing of other permission will be needed
            <a
              className="text-primary pointer-event"
              style={{ cursor: 'pointer' }}
              onClick={() => props.onEditClick(permission)}
            >
              Edit
            </a>
          )}
        </td>
      </tr>
    );
  });

  return (
    <div>
      <Button onClick={props.onAddClick} text={'Add role'} customClass={'mt-3 mb-3'} />
      <table className={'table table-sm mPre'}>
        <thead className="font-weight-bold">
          <tr>
            <td>Role</td>
            <td>Forms</td>
            <td>Houses</td>
            <td></td>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
};

StaffPermissions.displayName = 'StaffPermissions';
