import * as React from 'react';
import { FunctionComponent } from 'react';
import { ROLE_SERVER_TO_CLIENT_MAPPING } from '../../consts/user'
import {getEmailOrMisEmail} from "../../helpers/accessor/accessor";

interface Props {
  subscribers: any[]
}

export const GeneralMessageSubscribers: FunctionComponent<Props> = (props) => {
  const rows = props.subscribers.map( subscriber => {
    const permissions = subscriber.permissions;
    const permissionList = permissions
      .map(permission => permission.preset)
      .map(preset => ROLE_SERVER_TO_CLIENT_MAPPING[preset]);
  
    const permissionsStr = permissionList.join('\n');
      
    return (
      <tr key = { subscriber.userId }>
        <td>{ subscriber.firstName }</td>
        <td>{ subscriber.lastName }</td>
        <td>{getEmailOrMisEmail(subscriber)}</td>
        <td>{ permissionsStr }</td>
      </tr>
    );
  });
  
  const emptyRow = (
    <tr>
      <td>Empty subscribers list</td>
    </tr>
  );
  
  return (
    <table className="table table-sm mPre">
      <thead>
      <tr>
        <th>Name</th>
        <th>Surname</th>
        <th>Email</th>
        <th>Roles</th>
      </tr>
      </thead>
      <tbody>
      {rows.length > 0 ? rows : emptyRow}
      </tbody>
    </table>
  );
};

GeneralMessageSubscribers.displayName = 'GeneralMessageSubscribers';