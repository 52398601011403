export const REPORT_TYPE = {
  AGGREGATE_CLUB_PARTICIPATION_FOR_PERIOD: 'AGGREGATE_CLUB_PARTICIPATION_FOR_PERIOD',
  CLUB_ATTENDANCE: 'CLUB_ATTENDANCE',
  PARTICIPATION_STATISTICS_BY_CLUBS: 'PARTICIPATION_STATISTICS_BY_CLUBS',
  CLUB_PARTICIPATION_STATISTICS_BY_STUDENTS: 'CLUB_PARTICIPATION_STATISTICS_BY_STUDENTS',
  SELECTED_CLUBS_PARTICIPATION_BY_STUDENTS: 'SELECTED_CLUBS_PARTICIPATION_BY_STUDENTS',
  CLUB_LIST_BY_STUDENTS: 'CLUB_LIST_BY_STUDENTS',
  CLUB_ATTENDANCE_STATISTICS_BY_STUDENTS: 'CLUB_ATTENDANCE_STATISTICS_BY_STUDENTS',
  PARTICIPATION_STATISTICS_BY_CLUBS_YEAR_GROUPS: 'PARTICIPATION_STATISTICS_BY_CLUBS_YEAR_GROUPS',
  FIXTURE_PARTICIPATION_STATISTICS_BY_STUDENTS: 'FIXTURE_PARTICIPATION_STATISTICS_BY_STUDENTS',
  FIXTURE_RESULTS: 'FIXTURE_RESULTS',
  EXTENDED_TEAM_SHEET: 'EXTENDED_TEAM_SHEET',
  EXTENDED_PARTICIPANT_LIST: 'EXTENDED_PARTICIPANT_LIST',
  EVENT_CONSENT_REQUESTS: 'EVENT_CONSENT_REQUESTS',
  EVENT_AVAILABILITY_REPORTS: 'EVENT_AVAILABILITY_REPORTS',
  EXTENDED_TOURNAMENT_SCHEDULE: 'EXTENDED_TOURNAMENT_SCHEDULE',
  TOURNAMENT_PARTICIPATION_CONFIRMATIONS: 'TOURNAMENT_PARTICIPATION_CONFIRMATIONS',
  UNION_MEMBER_SCHOOLS_STATISTICS: 'UNION_MEMBER_SCHOOLS_STATISTICS',
  CLUB_BOOKINGS: 'CLUB_BOOKINGS',
  STUDENT_PARTICIPATION_IN_EVENTS: 'STUDENT_PARTICIPATION_IN_EVENTS',
  CLUB_LIST_FOR_STUDENT: 'CLUB_LIST_FOR_STUDENT',
  DATE_CLUBS: 'DATE_CLUBS',
  DATE_FIXTURES: 'DATE_FIXTURES',
  USER_ACTIVITY_DETAILS: 'USER_ACTIVITY_DETAILS',
  STUDENT_ACTIVITY: 'STUDENT_ACTIVITY',
  PARENT_ACTIVITY: 'PARENT_ACTIVITY',
  BASIC_TEAM_SHEET: 'BASIC_TEAM_SHEET',
  BASIC_TEAM_SHEET_INDIVIDUAL: 'BASIC_TEAM_SHEET_INDIVIDUAL',
  TEAM_PERFORMANCE: 'TEAM_PERFORMANCE',
  FIXTURE_HISTORY: 'FIXTURE_HISTORY',
  TOURNAMENT_TEAM_MEMBERS: 'TOURNAMENT_TEAM_MEMBERS',
  TOURNAMENT_START_LIST: 'TOURNAMENT_START_LIST',
  TOURNAMENT_SCHOOL_START_LIST: 'TOURNAMENT_SCHOOL_START_LIST',
  UNION_MEMBER_SCHOOLS_TOURNAMENTS: 'UNION_MEMBER_SCHOOLS_TOURNAMENTS',
  UNION_MEMBER_SCHOOLS_TOURNAMENTS_AS_PARTOF_UNION: 'UNION_MEMBER_SCHOOLS_TOURNAMENTS_AS_PART_OF_UNION',
  TOURNAMENT_PLAYERS: 'TOURNAMENT_PLAYERS',
  TOURNAMENT_SCHOOL_PLAYERS: 'TOURNAMENT_SCHOOL_PLAYERS',
  TOURNAMENT_RESULTS_INDIVIDUAL: 'TOURNAMENT_RESULTS_INDIVIDUAL',
  TOURNAMENT_RESULTS_INDIVIDUAL_PARTICIPANT_SIDE: 'TOURNAMENT_RESULTS_INDIVIDUAL_PARTICIPANT_SIDE',
  CLUB_PARTICIPATION_BY_CLUB_NUMBER: 'CLUB_PARTICIPATION_BY_CLUB_NUMBER',
  TOURNAMENT_SCORE_CARDS: 'TOURNAMENT_SCORE_CARDS',
  EVENT_SCORE_CARD: 'EVENT_SCORE_CARD',
  CAUTIONS_DISMISSALS: 'CAUTIONS_DISMISSALS',
  TOURNAMENT_SCHOOLS: 'TOURNAMENT_SCHOOLS',
  SCHOOL_TOURNAMENT_SCHEDULE: 'SCHOOL_TOURNAMENT_SCHEDULE',
  WEEKLY_CLUB_SCHEDULE: 'WEEKLY_CLUB_SCHEDULE',
  UNION_SCHOOL_CLUBS: 'UNION_SCHOOL_CLUBS',
  UNION_SCHOOL_FIXTURES: 'UNION_SCHOOL_FIXTURES',
  STUDENT_TARGET_COMPLETION: 'STUDENT_TARGET_COMPLETION',
  AGGREGATED_TARGET_COMPLETION: 'AGGREGATED_TARGET_COMPLETION',
  AGGREGATED_TARGET_COMPLETION_FOR_THE_PERIOD: 'AGGREGATED_TARGET_COMPLETION_FOR_THE_PERIOD',
  TARGET_PARTICIPATION_STATISTICS: 'TARGET_PARTICIPATION_STATISTICS',
  TARGET_COMPLETION_STATISTICS: 'TARGET_COMPLETION_STATISTICS',
  UNION_SCHOOL_TARGET_COMPLETION: 'UNION_SCHOOL_TARGET_COMPLETION',
  UNION_SCHOOL_TARGET_PARTICIPATION_STATISTICS: 'UNION_SCHOOL_TARGET_PARTICIPATION_STATISTICS',
  UNION_SCHOOL_TARGET_COMPLETION_STATISTICS: 'UNION_SCHOOL_TARGET_COMPLETION_STATISTICS',
  SELECTED_STUDENT_TARGET_COMPLETION: 'SELECTED_STUDENT_TARGET_COMPLETION',
  HIGH_LEVEL_TOURNAMENT_AREA_START_LIST: 'HIGH_LEVEL_TOURNAMENT_AREA_START_LIST',
  HIGH_LEVEL_TOURNAMENT_SCHOOL_ADMINS: 'HIGH_LEVEL_TOURNAMENT_AREA_SCHOOL_ADMINS',
  HIGH_LEVEL_TOURNAMENT_SCHOOL_ADMINS_AREA_SIDE: 'HIGH_LEVEL_TOURNAMENT_AREA_SCHOOL_ADMINS_AREA_SIDE',
  AVERAGE_CLUB_ATTENDANCE: 'AVERAGE_CLUB_ATTENDANCE',
  SCHOOL_TOURNAMENT_SCHEDULE_UNION_SIDE: 'SCHOOL_UNION_SCHEDULE_UNION_SIDE',
  FIXTURE_STATISTICS_FOR_PERIOD: 'FIXTURE_STATISTICS_FOR_PERIOD',
  PAPER_ATTENDANCE_REGISTER_EVENT: 'PAPER_ATTENDANCE_REGISTER_EVENT',
  PAPER_ATTENDANCE_REGISTER_CLUB: 'PAPER_ATTENDANCE_REGISTER_CLUB',
  STUDENT_PARTICIPATION_STATISTICS_BY_ACTIVITIES: 'STUDENT_PARTICIPATION_STATISTICS_BY_ACTIVITIES',
  FIXTURES_SCHEDULE: 'FIXTURES_SCHEDULE'
};

export const REPORT_TYPE_SERVER_TO_CLIENT_MAPPING = {
  AGGREGATE_CLUB_PARTICIPATION_FOR_PERIOD: 'aggregateClubParticipationForPeriod',
  CLUB_ATTENDANCE: 'clubAttendance',
  PARTICIPATION_STATISTICS_BY_CLUBS: 'participationStatisticsByClubs',
  CLUB_PARTICIPATION_STATISTICS_BY_STUDENTS: 'clubParticipationStatisticsByStudents',
  SELECTED_CLUBS_PARTICIPATION_BY_STUDENTS: 'clubParticipationStatisticsByStudentsSelectedClubs',
  CLUB_LIST_BY_STUDENTS: 'clubListByStudents',
  CLUB_ATTENDANCE_STATISTICS_BY_STUDENTS: 'clubAttendanceStatisticsByStudents',
  PARTICIPATION_STATISTICS_BY_CLUBS_YEAR_GROUPS: 'participationStatisticsByClubsYearGroups',
  FIXTURE_PARTICIPATION_STATISTICS_BY_STUDENTS: 'fixtureParticipationStatisticsByStudents',
  FIXTURE_RESULTS: 'fixtureResults',
  EXTENDED_TEAM_SHEET: 'extendedTeamSheet',
  EXTENDED_PARTICIPANT_LIST: 'extendedParticipantList',
  EVENT_CONSENT_REQUESTS: 'eventConsentRequests',
  EVENT_AVAILABILITY_REPORTS: 'eventAvailabilityReports',
  EXTENDED_TOURNAMENT_SCHEDULE: 'extendedTournamentSchedule',
  TOURNAMENT_PARTICIPATION_CONFIRMATIONS: 'tournamentParticipationConfirmations',
  UNION_MEMBER_SCHOOLS_STATISTICS: 'unionMemberSchoolsStatistics',
  CLUB_BOOKINGS: 'clubBookings',
  STUDENT_PARTICIPATION_IN_EVENTS: 'studentParticipationInEvents',
  CLUB_LIST_FOR_STUDENT: 'clubListForStudent',
  DATE_CLUBS: 'dateClubs',
  DATE_FIXTURES: 'dateFixtures',
  USER_ACTIVITY_DETAILS: 'userActivityDetails',
  STUDENT_ACTIVITY: 'studentActivity',
  PARENT_ACTIVITY: 'parentActivity',
  BASIC_TEAM_SHEET: 'basicTeamSheet',
  BASIC_TEAM_SHEET_INDIVIDUAL: 'basicTeamSheetIndividual',
  TEAM_PERFORMANCE: 'teamPerformance',
  FIXTURE_HISTORY: 'fixtureHistory',
  TOURNAMENT_TEAM_MEMBERS: 'tournamentTeamMembers',
  TOURNAMENT_START_LIST: 'tournamentStartList',
  TOURNAMENT_SCHOOL_START_LIST: 'tournamentSchoolStartList',
  UNION_MEMBER_SCHOOLS_TOURNAMENTS: 'unionMemberSchoolsTournaments',
  UNION_MEMBER_SCHOOLS_TOURNAMENTS_AS_PART_OF_UNION: 'unionMemberSchoolsTournamentsAsPartOfUnion',
  TOURNAMENT_PLAYERS: 'tournamentPlayers',
  TOURNAMENT_SCHOOL_PLAYERS: 'tournamentSchoolPlayers',
  TOURNAMENT_RESULTS_INDIVIDUAL: 'tournamentResultsIndividual',
  TOURNAMENT_RESULTS_INDIVIDUAL_PARTICIPANT_SIDE: 'tournamentResultsIndividualParticipantSide',
  CLUB_PARTICIPATION_BY_CLUB_NUMBER: 'clubParticipationByClubNumber',
  TOURNAMENT_SCORE_CARDS: 'tournamentScoreCards',
  EVENT_SCORE_CARD: 'eventScoreCard',
  CAUTIONS_DISMISSALS: 'cautionsDismissals',
  TOURNAMENT_SCHOOLS: 'tournamentSchools',
  SCHOOL_TOURNAMENT_SCHEDULE: 'schoolTournamentSchedule',
  WEEKLY_CLUB_SCHEDULE: 'weeklyClubSchedule',
  UNION_SCHOOL_CLUBS: 'unionSchoolClubs',
  UNION_SCHOOL_FIXTURES: 'unionSchoolFixtures',
  STUDENT_TARGET_COMPLETION: 'studentTargetCompletion',
  AGGREGATED_TARGET_COMPLETION: 'aggregatedTargetCompletion',
  AGGREGATED_TARGET_COMPLETION_FOR_THE_PERIOD: 'aggregatedTargetCompletionForThePeriod',
  TARGET_PARTICIPATION_STATISTICS: 'targetParticipationStatistics',
  TARGET_COMPLETION_STATISTICS: 'targetCompletionStatistics',
  UNION_SCHOOL_TARGET_COMPLETION: 'unionSchoolTargetCompletion',
  UNION_SCHOOL_TARGET_PARTICIPATION_STATISTICS: 'unionSchoolTargetParticipationStatistics',
  UNION_SCHOOL_TARGET_COMPLETION_STATISTICS: 'unionSchoolTargetCompletionStatistics',
  SELECTED_STUDENT_TARGET_COMPLETION: 'selectedStudentTargetCompletion',
  HIGH_LEVEL_TOURNAMENT_AREA_START_LIST: 'highLevelTournamentAreaStartList',
  HIGH_LEVEL_TOURNAMENT_SCHOOL_ADMINS: 'highLevelTournamentSchoolAdmins',
  HIGH_LEVEL_TOURNAMENT_SCHOOL_ADMINS_AREA_SIDE: 'highLevelTournamentSchoolAdminsAreaSide',
  AVERAGE_CLUB_ATTENDANCE: 'averageClubAttendance',
  SCHOOL_TOURNAMENT_SCHEDULE_UNION_SIDE: 'schoolTournamentScheduleUnionSide',
  FIXTURE_STATISTICS_FOR_PERIOD: 'fixtureStatisticsForPeriod',
  PAPER_ATTENDANCE_REGISTER_EVENT: 'paperAttendanceRegisterEvent',
  PAPER_ATTENDANCE_REGISTER_CLUB: 'paperAttendanceRegisterClub',
  STUDENT_PARTICIPATION_STATISTICS_BY_ACTIVITIES: 'studentParticipationStatisticsByActivities',
  FIXTURES_SCHEDULE: 'fixturesSchedule'
};
export const REPORT_PRINT_FORMAT = {
  XLSX: 'xlsx',
  PDF: 'pdf'
};
