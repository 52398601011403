import * as React from 'react';
import { Component } from 'react';
import * as propz from 'propz';
import { AppUser } from 'Src/views/App/App';
import { TABS } from 'Src/consts/common';
import {
  SchoolEvent,
  SchoolEventAttendance,
  SchoolEventIndividualData,
  SchoolEventIndividualPerformance,
  SchoolEventTeamDataPlayer
} from 'Src/models/event';
import { getEventPlayers } from 'Src/helpers/event/event';
import { getStudentEventViewTabs, isClubEvent, isHousesEvent } from '../../../../../helpers/event/event';
import { GenericSummary } from 'Src/components/GenericSummary/GenericSummary';
import { EmptyComponent } from 'Src/components/EmptyComponent/EmptyComponent';
import { StudentEventDetailsSummaryTab } from './DetailsTab/DetailsTab';
import { StudentEventJobsSummaryTab } from './JobsTab/JobsTab';
import { StudentEventPerformanceSummaryTab } from './PerformanceTab/PerformanceTab';
import { Loader } from 'Src/components/Loader/Loader';
import { StudentEventTeamsSummaryTab } from './TeamsTab/TeamsTab';
import './SummaryTabs.scss';
import { getSchoolEventDetails, getSchoolEventMatchReport } from '../../../../../helpers/service/admin/schoolEvents';
import { getEventTasks } from '../../../../../helpers/service/admin/event';
import { StudentEventMatchReportSummaryTab } from './MatchReportTab/MatchReportTab';
import {
  SchoolEventPlayerSummary,
  SchoolEventTeamSummary
} from '../../../../../components/SchoolEventPlayersSummary/SchoolEventPlayersSummary';
import { StudentEventPlayersPositionsSummary } from './PositionsTab/PositionsTab';
import { Sport } from '../../../../../models/sport';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../../../consts/table';
import { getSchoolSportsPublic } from '../../../../../helpers/service/public/public';
import { Report } from '../../../../../models/report';
import { getStudentAvailabilityReports } from '../../../../../helpers/service/admin/messages';
import * as BPromise from 'bluebird';
import { StudentEventAvailabilityReportsSummaryTab } from './StudentEventAvailabilityReportsSummaryTab/StudentEventAvailabilityReportsSummaryTab';
import { VenueView } from '../../../../../components/VenueView/VenueView';
import { SimpleModal } from '../../../../../components/SimpleModal/SimpleModal';
import { Point } from '../../../../../models/venue';
import { SchoolEventIndividualDiscipline } from '../../../../../models/event';
import { StudentEventDisciplineSummaryTab } from './DisciplineTab/DisciplineTab';
import { StudentEventAttendanceSummaryTab } from './AttendanceTab/AttendanceTab';
import { Button } from 'Src/components/Button/Button';
import { ACCESS_PRESET } from 'Src/consts/album';
import { Video } from 'Src/models/video';
import { Photo } from 'Src/models/photo';
import { EventGallery } from 'Src/views/GenericView/AdminView/TeamsAndEvents/Events/SchoolEventView/Gallery/Gallery';
import { EventVideos } from 'Src/views/GenericView/AdminView/TeamsAndEvents/Events/SchoolEventView/Videos/EventVideos';
import {
  addEventPhoto,
  deleteEventPhoto,
  getAllEventPhotos,
  updateEventPhoto
} from 'Src/helpers/service/admin/eventPhotos';
import {
  addEventVideo,
  deleteEventVideo,
  getAllEventVideos,
  updateEventVideo
} from 'Src/helpers/service/admin/eventVideos';
import { ACCESS_PRESET_TYPE } from 'Src/types/album';
import { uploadImage } from 'Src/helpers/service/image';
// first frontend use school request for student
// first frontend use school request for student

interface Props {
  user: AppUser;
  eventInitial: SchoolEvent;
}

interface State {
  currentTabIndex: number;
  eventTaskIndex: number;
  eventDetails: any;
  isLoading: boolean;
  isErrorModalOpen: boolean;
  errorMessage: string;
  eventTasks: any[];
  individualPerformance: SchoolEventIndividualPerformance[];
  individualDiscipline: SchoolEventIndividualDiscipline[];
  attendancePlayers: SchoolEventAttendance[];
  event: SchoolEvent;
  matchReport: string;
  sports: Sport[];
  playersForSummary: SchoolEventPlayerSummary[];
  teamsForSummary: SchoolEventTeamSummary[];
  activeTeamIndex: number;
  reports: Report[];
  isVenueFormOpen: boolean;
  point: Point;
  photos: Photo[];
  videos: Video[];
  isUpdateAccessPreset: boolean;
}

export class StudentSummaryTabs extends Component<Props, State> {
  photoFileInputRef: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      currentTabIndex: 0,
      eventTaskIndex: 0,
      eventDetails: undefined,
      isLoading: true,
      isErrorModalOpen: false,
      errorMessage: '',
      eventTasks: [],
      individualPerformance: [],
      individualDiscipline: [],
      attendancePlayers: [],
      event: props.eventInitial,
      matchReport: '',
      sports: [],
      playersForSummary: [],
      teamsForSummary: [],
      activeTeamIndex: 0,
      reports: [],
      isVenueFormOpen: false,
      point: undefined,
      photos: [],
      videos: [],
      isUpdateAccessPreset: false
    };
  }

  componentDidMount() {
    const { user } = this.props;
    const { event } = this.state;

    this.setState({
      isLoading: true
    });
    this.setItems();
  }

  setItems() {
    const { user } = this.props;
    const { event } = this.state;

    this.setState({
      isLoading: true
    });
    const filter = { limit: DEFAULT_LIMIT, order: 'name ASC', skip: DEFAULT_SKIP };

    const sportsPromise = getSchoolSportsPublic(user.activeSchoolId, filter);
    const getAvailabilityReportsPromise = getStudentAvailabilityReports(user, event.id);
    const promises = [sportsPromise, getAvailabilityReportsPromise];
    BPromise.all(promises)
      .then(([sports, reports]) => {
        this.setState({
          sports,
          reports,
          isLoading: false
        });
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        console.error(error);
        this.setState({
          isLoading: false,
          errorMessage: errorText,
          isErrorModalOpen: true
        });
      });
  }

  onTabClick = (e, index: number) => {
    e.preventDefault();

    const { user } = this.props;
    const { event, reports } = this.state;
    const { id, results } = event;
    const isExistReports = reports.length > 0;

    const tabs = getStudentEventViewTabs(event, isExistReports);
    const currentTab = tabs[index];

    const { individualPerformance, individualDiscipline, attendance } = results;
    const attendancePlayers = attendance.map(player => ({ ...player }));

    switch (currentTab) {
      case TABS.DETAILS:
        this.setState({
          isLoading: true
        });
        getSchoolEventDetails(user, id).then(details => {
          this.setState({
            currentTabIndex: index,
            eventDetails: details,
            isLoading: false
          });
        });
        break;
      case TABS.POSITIONS:
        const playersForSummary = this.getPlayersForSummary(event);
        const teamsForSummary = this.getTeamsForSummary();
        this.setState({
          currentTabIndex: index,
          playersForSummary: playersForSummary,
          teamsForSummary: teamsForSummary
        });
        break;
      case TABS.ATTENDANCE:
        this.setState({
          currentTabIndex: index,
          attendancePlayers
        });
        break;
      case TABS.JOBS:
        this.setState({
          isLoading: true
        });
        getEventTasks(user, id).then(eventTasks => {
          this.setState({
            currentTabIndex: index,
            eventTasks: eventTasks,
            isLoading: false
          });
        });
        break;
      case isClubEvent(event) ? TABS.PROGRESS : TABS.PERFORMANCE:
        this.setState({
          currentTabIndex: index,
          individualPerformance: individualPerformance
        });
        break;
      case TABS.DISCIPLINE:
        this.setState({
          currentTabIndex: index,
          individualDiscipline: individualDiscipline
        });
        break;
      case isClubEvent(event) ? TABS.LEADER_COACH_NOTES : TABS.MATCH_REPORT:
        this.setState({
          isLoading: true
        });
        getSchoolEventMatchReport(user, id).then(matchReport => {
          const content = propz.get(matchReport, ['content'], '');
          this.setState({
            currentTabIndex: index,
            matchReport: content,
            isLoading: false
          });
        });
        break;

      case TABS.GALLERY: {
        this.setState({
          isLoading: true
        });
        const promises = [getAllEventPhotos(user, id), getAllEventVideos(user, id)];
        BPromise.all(promises).then(([photos, videos]) => {
          this.setState({
            isLoading: false,
            currentTabIndex: index,
            photos,
            videos
          });
        });
        break;
      }

      default:
        this.setState({
          currentTabIndex: index
        });
    }
  };

  onAccessPresetPhotoClick = (photoId: string, accessPreset: ACCESS_PRESET_TYPE) => {
    const { user } = this.props;
    const { event, photos } = this.state;
    const { id } = event;

    this.setState({
      isUpdateAccessPreset: true
    });

    const dataToUpdate = {
      accessPreset
    };

    updateEventPhoto(user, id, photoId, dataToUpdate).then(photoNext => {
      const photosNext = photos.map(photo => (photoId === photo.id ? photoNext : photo));
      this.setState({
        photos: photosNext,
        isUpdateAccessPreset: false
      });
    });
  };

  onAccessPresetVideoClick = (videoId: string, accessPreset: ACCESS_PRESET_TYPE) => {
    const { user } = this.props;
    const { event, videos } = this.state;
    const { id } = event;

    this.setState({
      isUpdateAccessPreset: true
    });

    const dataToUpdate = {
      accessPreset
    };

    updateEventVideo(user, id, videoId, dataToUpdate).then(videoNext => {
      const videosNext = videos.map(video => (videoId === video.id ? videoNext : video));
      this.setState({
        videos: videosNext,
        isUpdateAccessPreset: false
      });
    });
  };

  onViewMapClick = (point): void => {
    this.setState({
      point: point,
      isVenueFormOpen: true
    });
  };

  getContentTabs(): React.ReactNode {
    const { user } = this.props;
    const { event, reports } = this.state;
    const { activeSchoolId, userId } = user;
    const {
      currentTabIndex,
      eventDetails,
      eventTasks,
      individualPerformance,
      individualDiscipline,
      matchReport,
      sports,
      activeTeamIndex,
      playersForSummary,
      teamsForSummary,
      isUpdateAccessPreset,
      photos,
      videos
    } = this.state;
    const isExistReports = reports.length > 0;

    const tabs = getStudentEventViewTabs(event, isExistReports);
    const currentTab = tabs[currentTabIndex];

    const { results } = event;

    const { attendance } = results;
    const attendancePlayers = attendance.map(player => ({ ...player }));

    const allTeamPlayers = getEventPlayers(event, activeSchoolId);
    const currentPlayer = allTeamPlayers.find(player => player.userId === userId);

    const currentSport = sports.find(sport => sport.id === event.sportId);

    switch (currentTab) {
      case TABS.DETAILS:
        return <StudentEventDetailsSummaryTab schoolEvent={event} eventsDetails={eventDetails} user={user} />;
      case TABS.POSITIONS:
        return (
          <StudentEventPlayersPositionsSummary
            schoolEvent={event}
            sport={currentSport}
            players={playersForSummary}
            teams={teamsForSummary}
            activeTeamIndex={activeTeamIndex}
            onChangeTeam={this.onChangeTeam}
          />
        );
      case TABS.ATTENDANCE:
        return (
          <StudentEventAttendanceSummaryTab
            schoolEvent={event}
            allTeamPlayers={allTeamPlayers}
            attendancePlayers={attendancePlayers}
            user={user}
          />
        );
      case TABS.JOBS:
        return <StudentEventJobsSummaryTab eventTasks={eventTasks} user={user} />;
      case isClubEvent(event) ? TABS.PROGRESS : TABS.PERFORMANCE:
        return (
          <StudentEventPerformanceSummaryTab
            schoolEvent={event}
            currentPlayer={currentPlayer}
            individualPerformance={individualPerformance}
          />
        );
      case TABS.DISCIPLINE:
        return (
          <StudentEventDisciplineSummaryTab
            user={user}
            schoolEvent={event}
            allTeamPlayers={allTeamPlayers}
            individualDiscipline={individualDiscipline}
          />
        );
      case TABS.PARTICIPANTS:
      case TABS.PLAYERS:
        return <StudentEventTeamsSummaryTab user={user} eventInitial={event} />;
      case isClubEvent(event) ? TABS.LEADER_COACH_NOTES : TABS.MATCH_REPORT:
        return <StudentEventMatchReportSummaryTab schoolEvent={event} matchReport={matchReport} />;
      case TABS.AVAILABILITY_REPORTS:
        return (
          <StudentEventAvailabilityReportsSummaryTab
            reports={reports}
            user={user}
            onViewMapClick={this.onViewMapClick}
          />
        );

      case TABS.GALLERY:
        return (
          <>
            <EventGallery
              onDeletePhotoClick={this.onDeletePhotoClick}
              user={user}
              eventInitial={event}
              photos={photos}
              onAccessPresetClick={this.onAccessPresetPhotoClick}
              isUpdateAccessPreset={isUpdateAccessPreset}
            />
            <EventVideos
              onDeleteVideoClick={this.onDeleteVideoClick}
              user={user}
              eventInitial={event}
              videos={videos}
              onAccessPresetClick={this.onAccessPresetVideoClick}
              isUpdateAccessPreset={isUpdateAccessPreset}
            />
          </>
        );

      default:
        return <EmptyComponent />;
    }
  }

  getPlayersForSummary(event: SchoolEvent): SchoolEventPlayerSummary[] {
    const { user } = this.props;
    const { activeSchoolId } = user;

    const schoolPlayers = getEventPlayers(event, activeSchoolId);

    return schoolPlayers.map(schoolPlayer => {
      const { userId, permissionId, firstName, lastName, form, id, teamId } = schoolPlayer;
      const isFormExist = typeof form !== 'undefined';
      const formName = isFormExist ? form.name : '';

      const playerFromEvent = event.players.find(
        player => player.userId === userId && player.permissionId === permissionId
      );

      const positionId = typeof playerFromEvent !== 'undefined' ? playerFromEvent.positionId : '';
      const isCaptain = typeof playerFromEvent !== 'undefined' ? playerFromEvent.isCaptain : false;
      const isSub = typeof playerFromEvent !== 'undefined' ? playerFromEvent.sub : false;

      return {
        name: `${firstName} ${lastName}`,
        playerId: id,
        form: formName,
        positionId: positionId,
        isCaptain: isCaptain,
        isSub: isSub,
        teamId: teamId
      };
    });
  }

  getTeamsForSummary(): SchoolEventTeamSummary[] {
    const { user } = this.props;
    const { activeSchoolId } = user;
    const { event } = this.state;

    return event.teamsData
      .filter(team => team.schoolId === activeSchoolId)
      .map(team => ({
        teamName: team.name,
        teamId: team.id,
        houseName: isHousesEvent(event) ? team.house.name : undefined
      }));
  }

  onChangeTeam = event => {
    const { teamsForSummary } = this.state;
    const teamId = event.target.value;
    const currentTeamIndex = teamsForSummary.findIndex(team => team.teamId === teamId);

    this.setState({
      activeTeamIndex: currentTeamIndex
    });
  };

  onCancelButtonClick = (): void => {
    this.setState({
      isVenueFormOpen: false
    });
  };

  onCloseErrorClick = () => {
    this.setState({
      isErrorModalOpen: false,
      errorMessage: ''
    });
  };

  renderVenueModal() {
    const { point } = this.state;
    return (
      <SimpleModal isOpen={this.state.isVenueFormOpen} title={'Venue'}>
        <VenueView point={point} onOkClick={this.onCancelButtonClick} />
      </SimpleModal>
    );
  }

  renderSendingError(): React.ReactNode {
    const { errorMessage, isErrorModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isErrorModalOpen}
        title={'Error'}
        buttonCancelText={'Ok'}
        onCloseClick={this.onCloseErrorClick}
      >
        <pre>{errorMessage}</pre>
      </SimpleModal>
    );
  }

  onPhotoFileChange = event => {
    const { user, eventInitial } = this.props;
    const { id } = eventInitial;
    const image = event.target.files[0];
    const photoAccessPreset = ACCESS_PRESET.PRIVATE;

    this.setState({
      isLoading: true
    });

    uploadImage(user, image)
      .then(response => {
        const picUrl = `${window.apiImg}/images/${response.key}`;
        const data = {
          picUrl: picUrl,
          originalFileName: image.name,
          accessPreset: photoAccessPreset
        };
        return addEventPhoto(user, id, data);
      })
      .then(res => getAllEventPhotos(user, id))
      .then(photos => {
        this.setState({
          isLoading: false,
          photos: photos
        });
      });
  };

  renderPhotoFileInput() {
    return (
      <input
        className="eAddPhotoButton_fileInput"
        type="file"
        onChange={this.onPhotoFileChange}
        ref={input => (this.photoFileInputRef = input)}
      />
    );
  }

  //Cannot access this resource with "STUDENT" role
  onDeletePhotoClick = (photoId: string) => {};
  //Cannot access this resource with "STUDENT" role
  onDeleteVideoClick = (videoId: string) => {};

  onAddPhotoClick = eventDescriptor => {
    let event;
    //This is true only for IE,firefox
    //Dirty hack from https://codeproject.com/Tips/893254/JavaScript-Triggering-Event-Manually-in-Internet-E
    //However, MDN recommend use very similar polyfill https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/MouseEvent#Polyfill
    if (document.createEvent) {
      // To create a mouse event , first we need to create an event and then initialize it.
      event = document.createEvent('MouseEvent');
      event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    } else {
      event = new MouseEvent('click');
    }
    this.photoFileInputRef.dispatchEvent(event);
    eventDescriptor.stopPropagation();
  };

  render() {
    const { user } = this.props;
    const { currentTabIndex, isLoading, event, reports, isVenueFormOpen, isErrorModalOpen } = this.state;
    const { activeSchool } = user;
    const { settings } = activeSchool;
    const { photosEnabled } = settings;
    const isExistReports = reports.length > 0;
    const tabs = getStudentEventViewTabs(event, isExistReports);
    const contentTabs = this.getContentTabs();
    const currentTab = tabs[currentTabIndex];

    const isShowAddPhotoButton = photosEnabled && currentTab === TABS.GALLERY;

    if (isLoading) {
      return <Loader />;
    }

    const classes = isVenueFormOpen || isErrorModalOpen ? 'mt-3 modal-open' : 'mt-3';
    return (
      <div className={classes}>
        {this.renderVenueModal()}
        {this.renderSendingError()}
        {this.renderPhotoFileInput()}
        <GenericSummary onTabClick={this.onTabClick} tabs={tabs} currentTabIndex={currentTabIndex}>
          {isShowAddPhotoButton && (
            <Button onClick={this.onAddPhotoClick} text={'Add photo'} customClass={'mt-3 mb-3 ml-3'} />
          )}
          {contentTabs}
        </GenericSummary>
      </div>
    );
  }
}
