import * as React from 'react';
import { Field, Form, Formik } from 'formik';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/report';
import { Switch } from 'Src/components/Switch/Switch';
import { LabelWithQuestionIcon } from 'Src/components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import '../../SchoolEventView.scss';

interface Props {
  onCloseClick: () => void;
  onSubmit: (data: any, reportType: string) => void;
}

//report 009
export function EventAvailabilityReportsReportForm({ onCloseClick, onSubmit }: Props) {
  const requestInitial = {
    isShowAll: false
  };

  return (
    <Formik
      onSubmit={values => onSubmit(values, REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.EVENT_AVAILABILITY_REPORTS)}
      initialValues={requestInitial}
      render={({ values, setFieldValue }) => (
        <Form>
          <div className="bReportFormTitle">'Availability reports summary'</div>
          <div className="mb-3 mt-3">
            {'Within this report you will be able to see all availability reports ' +
              'sent regarding a specific event / activity. ' +
              'Select if you would like to show all reports sent, or just the most recent for each student.'}
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isShowAll"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowAll}
                    customClass="mb-3"
                    onChange={event => {
                      const isShowAll = event.target.checked;
                      setFieldValue('isShowAll', isShowAll);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show all"
                hintText={
                  'Turn it on to show all availability reports, even if there are multiple responses for some students.' +
                  'Turn it off to show only one availability report per student, therefore only showing the most recent one sent'
                }
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintMedicalInfo"
              />
            </div>
          </div>

          <button type="button" className="btn btn-secondary mr-3" onClick={onCloseClick}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Get
          </button>
        </Form>
      )}
    />
  );
}
