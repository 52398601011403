import * as React from 'react';
import { SchoolEvent } from '../../models/event';
import { Component } from 'react';
import { HTMLEditorQuillForMatchReport } from '../HTMLEditorQuill/HTMLEditorQuillForMatchReport';
import { EditorIcon } from '../EditorIcon/EditorIcon';

interface Props {
  schoolEvent: SchoolEvent;
  matchReport: string;
  onChangeMatchReport: (matchReport: any) => void;
  isEditMatchReportActive: boolean;
}

interface State {
  isEditorActive: boolean;
}

export class SchoolEventMatchReportSummary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isEditorActive: false
    };
  }

  onEditEventMatchReportClick = () => {
    const { isEditorActive } = this.state;

    this.setState({
      isEditorActive: !isEditorActive
    });
  };

  render() {
    const { isEditorActive } = this.state;

    return (
      <div className="container-fluid pl-0">
        <div className="row">
          <div className="col-md-10">
            <div className="form-group">
              <div className="d-flex eMatchReportEditor">
                <HTMLEditorQuillForMatchReport
                  onTextMatchReportChange={matchReport => {
                    this.props.onChangeMatchReport(matchReport);
                  }}
                  value={this.props.matchReport}
                  isEnabled={!this.props.isEditMatchReportActive}
                  isEnabledEditorActiveToolbar={isEditorActive && !this.props.isEditMatchReportActive}
                  placeholder="Enter body"
                />
                <EditorIcon onClick={() => this.onEditEventMatchReportClick()} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
