import { AppUser } from 'Src/views/App/App';
import axios from 'axios';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../consts/table';
import { Count } from '../../../models/count';

const DEFAULT_CHAMPIONSHIPS_FILTER: any = {
  limit: 20,
  skip: DEFAULT_SKIP
};

export function getChampionships(user: AppUser, filter = DEFAULT_CHAMPIONSHIPS_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order || 'startTime DESC'
      }
    }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/championships`, config).then(response => {
    return response.data;
  });
}

export function getAllChampionships(user: AppUser, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/championships`, config).then(response => {
    return response.data;
  });
}

export function getChampionshipsCount(user: AppUser, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where
          }
        }
      }
    };
  } else {
    config = {
      headers: { usid: key }
    };
  }

  return axios
    .get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/championships/count`, config)
    .then(response => {
      return response.data;
    });
}

export function getChampionship(user: AppUser, championshipId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/championships/${championshipId}`, config)
    .then(response => {
      return response.data;
    });
}

export function createChampionship(user: AppUser, data) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.post(`${window.apiBase}/i/schools/${activeSchoolId}/championships`, data, config).then(response => {
    return response.data;
  });
}

export function updateChampionship(user: AppUser, data, championshipsId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .put(`${window.apiBase}/i/schools/${activeSchoolId}/championships/${championshipsId}`, data, config)
    .then(response => {
      return response.data;
    });
}

export function deleteChampionship(user: AppUser, championshipId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .delete(`${window.apiBase}/i/schools/${activeSchoolId}/championships/${championshipId}`, config)
    .then(response => {
      return response.data;
    });
}

export function addOrRemoveTournamentsToChampionship(user: AppUser, data, championshipsId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .post(
      `${window.apiBase}/i/schools/${activeSchoolId}/championships/${championshipsId}/tournaments/batch`,
      data,
      config
    )
    .then(response => {
      return response.data;
    });
}
