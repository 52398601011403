import * as React from 'react';
import { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import DatePicker from 'react-datepicker';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from '../../../../../../../consts/report';
import { Switch } from '../../../../../../../components/Switch/Switch';
import { LabelWithQuestionIcon } from '../../../../../../../components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import { MultiSelectCheckboxes } from '../../../../../../../components/MultiSelectCheckboxes/MultiSelectCheckboxes';
import {
  getReportAgesForMultiSelect,
  getReportDefaultGendersForMultiSelect
} from '../../../../../../../helpers/multiselect/multiselect';
import { getStartDateForSchoolYear } from 'Src/helpers/calendar/calendar';
import { School } from '../../../../../../../models/school';
import { SchoolForm } from '../../../../../../../models/form';
import '../../SchoolLeagues.css';

interface Props {
  onCloseClick: () => void;
  onSubmit: (data: any, reportType: string) => void;
  school: School;
  forms: SchoolForm[];
}

// report 055
export function AggregatedTargetCompletionForThePeriodReportForm({ onCloseClick, onSubmit, school, forms }: Props) {
  const now = new Date();
  let dateFrom = getStartDateForSchoolYear(now);
  const genders = getReportDefaultGendersForMultiSelect();
  const ages = getReportAgesForMultiSelect(school, forms);

  const [isAgesAndGendersChecked, setIsAgesAndGendersChecked] = useState(true);

  const requestInitial = {
    dateFrom,
    dateTo: now,
    isShowCompleted: true,
    isShowUncompleted: true,
    isShowBlank: true,
    genders,
    ages,
    isSelectAllAges: true
  };

  const onGendersChange = (index, values, setFieldValue) => {
    const genderItems = values.genders;
    const ageItems = values.ages;

    const genderItemsUpdated = genderItems.map((genderItem, genderIndex) => {
      return genderIndex === index
        ? {
            ...genderItem,
            isChecked: !genderItem.isChecked
          }
        : genderItem;
    });

    const gendersChecked = genderItemsUpdated.map(gender => gender.isChecked);
    const agesChecked = ageItems.map(age => age.isChecked);
    const isCheckedGendersExist = gendersChecked.includes(true);
    const isCheckedAgesExist = agesChecked.includes(true);

    setIsAgesAndGendersChecked(isCheckedGendersExist && isCheckedAgesExist);
    setFieldValue('genders', genderItemsUpdated);
  };

  const onAgesChange = (index, values, setFieldValue) => {
    const ageItems = values.ages;
    const genderItems = values.genders;

    const ageItemsUpdated = ageItems.map((ageItem, ageIndex) => {
      return ageIndex === index
        ? {
            ...ageItem,
            isChecked: !ageItem.isChecked
          }
        : ageItem;
    });

    const gendersChecked = genderItems.map(gender => gender.isChecked);
    const agesChecked = ageItemsUpdated.map(age => age.isChecked);
    const isCheckedGendersExist = gendersChecked.includes(true);
    const isCheckedAgesExist = agesChecked.includes(true);

    const isSelectAllAges = ageItemsUpdated.every(age => age.isChecked === true);

    setIsAgesAndGendersChecked(isCheckedAgesExist && isCheckedGendersExist);
    setFieldValue('isSelectAllAges', isSelectAllAges);
    setFieldValue('ages', ageItemsUpdated);
  };

  const onSelectAllAgesChange = (event, values, setFieldValue) => {
    const isSelectAllAges = event.target.checked;
    const ageItems = values.ages;
    const genderItems = values.genders;

    const ageItemsUpdated = ageItems.map(age => {
      return {
        ...age,
        isChecked: isSelectAllAges
      };
    });

    const gendersChecked = genderItems.map(gender => gender.isChecked);
    const agesChecked = ageItemsUpdated.map(age => age.isChecked);
    const isCheckedGendersExist = gendersChecked.includes(true);
    const isCheckedAgesExist = agesChecked.includes(true);

    setIsAgesAndGendersChecked(isCheckedAgesExist && isCheckedGendersExist);
    setFieldValue('isSelectAllAges', isSelectAllAges);
    setFieldValue('ages', ageItemsUpdated);
  };

  return (
    <Formik
      onSubmit={values =>
        onSubmit(values, REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.AGGREGATED_TARGET_COMPLETION_FOR_THE_PERIOD)
      }
      initialValues={requestInitial}
      render={({ values, setFieldValue }) => (
        <Form>
          <div className="bReportFormTitle">{'Aggregated Target Completion for the Period'}</div>
          <div className="mb-3 mt-3">
            {'A summary report of student performance on specific tasks or activities. ' +
              ' The report includes information on students that have completed their ' +
              ' targets across the selected activities within the selected period.'}
          </div>
          <div className="form-group">
            <label>Date from</label>
            <Field name="dateFrom">
              {({ field, form }) => (
                <div className="mb-3">
                  <DatePicker
                    {...field}
                    selected={field.value}
                    onChange={date => {
                      form.setFieldValue('dateFrom', date);
                      if (form.values.dateTo && form.values.dateTo < date) {
                        form.setFieldValue('dateTo', date);
                      }
                    }}
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
              )}
            </Field>
          </div>
          <div className="form-group">
            <label>Date to</label>
            <Field name="dateTo">
              {({ field, form }) => (
                <div className="mb-3">
                  <DatePicker
                    {...field}
                    selected={field.value}
                    onChange={date => {
                      form.setFieldValue('dateTo', date);
                    }}
                    minDate={form.values.dateFrom}
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
              )}
            </Field>
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isShowCompleted"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowCompleted}
                    customClass="mb-3"
                    onChange={event => {
                      const isShowCompleted = event.target.checked;
                      setFieldValue('isShowCompleted', isShowCompleted);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show completed targets"
                hintText={`This will allow you to view the students who have been marked as having completed their target.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowCompleted"
              />
            </div>
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isShowUncompleted"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowUncompleted}
                    customClass="mb-3"
                    onChange={event => {
                      const isShowUncompleted = event.target.checked;
                      setFieldValue('isShowUncompleted', isShowUncompleted);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show incomplete targets"
                hintText={`This will allow you to view the students who have not been marked as having completed their target.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowUncompleted"
              />
            </div>
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isShowBlank"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowBlank}
                    customClass="mb-3"
                    onChange={event => {
                      const isShowBlank = event.target.checked;
                      setFieldValue('isShowBlank', isShowBlank);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show non-participants"
                hintText={`This will allow you to view the students who match your chosen age and gender criteria but werent added to the selected activities.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowBlank"
              />
            </div>
          </div>

          <div className={'mt-3 mb-3'}>
            <MultiSelectCheckboxes
              items={values.genders}
              cols={3}
              onChange={index => onGendersChange(index, values, setFieldValue)}
            />
          </div>

          <div className={'mt-3 mb-3'}>
            <MultiSelectCheckboxes
              items={values.ages}
              cols={3}
              onChange={index => onAgesChange(index, values, setFieldValue)}
            />
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isSelectAllAges"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isSelectAllAges}
                    customClass="mb-3"
                    onChange={event => onSelectAllAgesChange(event, values, setFieldValue)}
                  />
                )}
              />

              <label htmlFor="isSelectAllAges" className="form-check-label pl-2">
                Select all ages
              </label>
            </div>
          </div>

          <button type="button" className="btn btn-secondary mr-3" onClick={onCloseClick}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary" disabled={!isAgesAndGendersChecked}>
            Get
          </button>
        </Form>
      )}
    />
  );
}
