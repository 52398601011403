import * as React from 'react';
import { Component } from 'react';
import { parse } from 'query-string';
import * as BPromise from 'bluebird';
import {
  ColumnDefinition,
  getFilters2,
  getOrder,
  getSearchFilter,
  getSearchOrder,
  getServerFieldSectionWhere,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE, LIMIT } from 'Src/consts/table';
import { Loader } from 'Src/components/Loader/Loader';
import { AppUser } from 'Src/views/App/App';
import './Houses.css';
import { GenericSummary } from 'Src/components/GenericSummary/GenericSummary';
import { Button } from 'Src/components/Button/Button';
import { History, Location } from 'history';
import { Grid2 } from 'Src/components/Grid/Grid2';
import { getColor } from 'Src/helpers/cell/cell';
import { SchoolHouse } from 'Src/models/house';
import {
  createHouse,
  deleteHouse,
  getAllHouses,
  getHouses,
  getHousesCount,
  updateHouse
} from 'Src/helpers/service/admin/houses';
import { HOUSE_TABS } from 'Src/consts/house';
import { HouseForm } from './HouseForm/HouseForm';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { HouseSummary } from 'Src/components/HouseSummary/HouseSummary';
import { ResizablePanel } from 'Src/components/ResizablePanel/ResizablePanel';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Name',
    field: 'name',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['name']
  },
  {
    text: 'Description',
    field: 'description',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: ['description']
  },
  {
    text: 'Colours',
    field: 'colors',
    isSort: false,
    type: FILTER_TYPE.NONE,
    cell: getColor
  }
];

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

interface State {
  items: SchoolHouse[];
  currentPage: number;
  selectedItems: SchoolHouse[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;

  isEditHousePopupOpened: boolean;
  isDeleteHouseModalOpen: boolean;
}

export class Houses extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',

      isEditHousePopupOpened: false,
      isDeleteHouseModalOpen: false
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setItems();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const getHousesPromise = getHouses(user, serverQueryFilter);
    const getHousesCountPromise = getHousesCount(user, where);

    return BPromise.all([getHousesCountPromise, getHousesPromise]).then(([countObj, houses]) => {
      this.setState({
        currentPage: page,
        sortDirection: sortDirection,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: houses,
        total: countObj.count,
        basePath: history.location.pathname,
        isLoading: false
      });

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedHouse = items[index];

    const selectedHouseIndex = selectedItems.findIndex(house => selectedHouse.id === house.id);
    let selectedHousesUpdated = [...selectedItems];

    if (selectedHouseIndex !== -1) {
      selectedHousesUpdated.splice(selectedHouseIndex, 1);
    } else {
      selectedHousesUpdated.push(selectedHouse);
    }

    this.setState({
      selectedItems: selectedHousesUpdated
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const housesFiltered = items.filter(house => {
      return selectedItems.every(selectedHouse => selectedHouse.id !== house.id);
    });

    const selectedHouse = [...selectedItems, ...housesFiltered];

    this.setState({
      selectedItems: selectedHouse,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    getAllHouses(user, where).then(houses => {
      const housesFiltered = houses.filter(house => {
        return selectedItems.every(selectedHouse => selectedHouse.id !== house.id);
      });

      const selectedHouses = [...selectedItems, ...housesFiltered];

      this.setState({
        selectedItems: selectedHouses,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: ''
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  onTabClick = (event, index: number) => {
    event.preventDefault();

    this.setState({
      total: index
    });
  };

  onEditHouseClick = event => {
    event.preventDefault();

    this.setState({ isEditHousePopupOpened: true });
  };

  getSummaryTabs(): React.ReactNode {
    const { selectedItems, items } = this.state;
    const selectedHouses = items.find(house => house.id === selectedItems[0].id);

    switch (true) {
      case selectedItems.length === 1:
        return <HouseSummary house={selectedHouses} />;
    }
  }

  renderHouseModal(): React.ReactNode {
    const { isEditHousePopupOpened, selectedItems } = this.state;
    const { user } = this.props;

    if (selectedItems.length === 0) {
      return (
        <SimpleModal isOpen={isEditHousePopupOpened} title={'Create house'}>
          <HouseForm onCancel={this.onCancelButtonClick} onSubmit={this.onSaveButtonClick} user={user} />
        </SimpleModal>
      );
    } else {
      return (
        <SimpleModal isOpen={isEditHousePopupOpened} title={'Edit house'}>
          <HouseForm
            house={selectedItems[0]}
            onCancel={this.onCancelButtonClick}
            onSubmit={this.onSaveButtonClick}
            user={user}
          />
        </SimpleModal>
      );
    }
  }

  onCreateHouseClick = () => {
    this.setState({
      isEditHousePopupOpened: true,
      selectedItems: []
    });
  };

  deleteHouseModalOpen = (): void => {
    this.setState({
      isDeleteHouseModalOpen: true
    });
  };

  deleteHouseModalClose = (): void => {
    this.setState({
      isDeleteHouseModalOpen: false
    });
  };

  onDeleteHouseClick = () => {
    const { selectedItems } = this.state;

    this.setState({ isLoading: true });
    const promises = selectedItems.map(house => {
      return deleteHouse(this.props.user, house.id);
    });
    BPromise.all(promises).then(() => {
      this.setState({
        selectedItems: [],
        isDeleteHouseModalOpen: false
      });
      this.setItems();
    });
  };

  onSaveButtonClick = (data): void => {
    const { user } = this.props;
    const { selectedItems } = this.state;

    this.setState({
      isLoading: true
    });

    if (selectedItems.length === 0) {
      createHouse(this.props.user, data).then(house => {
        this.setState({
          isEditHousePopupOpened: false,
          selectedItems: [house]
        });
        this.setItems();
      });
    } else {
      const houseId = selectedItems[0].id;
      updateHouse(user, data, houseId).then(house => {
        this.setState({
          isEditHousePopupOpened: false,
          selectedItems: [house]
        });
        this.setItems();
      });
    }
  };

  onCancelButtonClick = (): void => {
    this.setState({
      isEditHousePopupOpened: false
    });
  };

  renderDeleteHouseModal() {
    const { selectedItems, isDeleteHouseModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isDeleteHouseModalOpen}
        title={'Delete house'}
        body={`Are you sure you want to remove ${
          selectedItems.length === 1 ? `house ${selectedItems[0].name}` : `${selectedItems.length} house`
        }?`}
        buttonText={'Delete'}
        onButtonClick={this.onDeleteHouseClick}
        onCloseClick={this.deleteHouseModalClose}
      />
    );
  }

  renderSummary() {
    const { selectedItems } = this.state;
    const isOneItemSelected = selectedItems.length === 1;

    if (!isOneItemSelected) {
      return <div className="font-weight-bold">Select a house above</div>;
    }

    const summaryTabs = this.getSummaryTabs();

    const isShowEditButton = selectedItems.length === 1;

    return (
      <GenericSummary onTabClick={this.onTabClick} tabs={HOUSE_TABS} currentTabIndex={0}>
        {isShowEditButton && <Button onClick={this.onEditHouseClick} text={'Edit'} customClass={'mt-3 mb-3'} />}
        {summaryTabs}
      </GenericSummary>
    );
  }

  render() {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isLoading,
      isSelectAllChecked,
      currentPage,
      total,
      isEditHousePopupOpened
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes = isEditHousePopupOpened ? 'mt-3 modal-open' : 'mt-3';

    const actionItems = [
      {
        itemText: 'Create house',
        onItemClick: this.onCreateHouseClick,
        isActive: selectedItems.length === 0
      },
      {
        itemText: 'Delete house',
        onItemClick: this.deleteHouseModalOpen,
        isActive: selectedItems.length > 0
      }
    ];

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderDeleteHouseModal()}
        {this.renderHouseModal()}
        <div className="row">
          <div className="col-md-12">
            <ResizablePanel>
              <Grid2
                dataItems={items}
                filters={filters}
                currentPage={currentPage}
                total={total}
                isSelectAllChecked={isSelectAllChecked}
                isDataFiltered={isDataFiltered}
                sortDirection={sortDirection}
                sortColumnsName={sortColumnsName}
                isShowFilter={isShowFilter}
                dataItemsSelected={selectedItems}
                columns={COLUMNS}
                actionItems={actionItems}
                onItemClick={this.onItemClick}
                onSortClick={this.onTableSortClick}
                onApplyFilterClick={this.onApplyFilterClick}
                onClearFilterClick={this.onClearFilterClick}
                onTableFilterChange={this.onTableFilterChange}
                onTableFilterClick={this.onTableFilterClick}
                setCurrentPageParams={this.setCurrentPageParams}
                onSelectAllClick={this.onSelectAllClick}
                onSelectAllOnPageClick={this.onSelectAllOnPageClick}
                onUnselectAllClick={this.onUnselectAllClick}
              />
              {this.renderSummary()}
            </ResizablePanel>
          </div>
        </div>
      </div>
    );
  }
}
