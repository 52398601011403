import * as React from 'react';
import * as propz from 'propz';
import { Field, Form, Formik } from 'formik';
import DatePicker from 'react-datepicker';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from '../../../../../../../consts/report';
import { Club } from '../../../../../../../models/club';
import {
  getClubStartDateWithoutFormatting,
  getClubFinishDateWithoutFormatting
} from '../../../../../../../helpers/accessor/accessor';
import { Switch } from '../../../../../../../components/Switch/Switch';
import { LabelWithQuestionIcon } from '../../../../../../../components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import '../../Clubs.css';

const OPTIONS = {
  IS_ATTENDANCE: 'isAttendance',
  IS_PARTICIPATION: 'isParticipation'
};

interface Props {
  onCloseClick: () => void;
  onSubmit: (data: any, reportType: string) => void;
  club: Club;
  activeSchoolId: string;
}

// report 002
export const ClubAttendanceReportForm = ({ onCloseClick, onSubmit, club, activeSchoolId }: Props) => {
  const [schoolData, setSchoolData] = React.useState<any>(null);

  React.useEffect(() => {
    fetch('/dist/images/mis/misIntegrationSchoolsList.json')
      .then(response => response.json())
      .then(data => {
        const matchedSchoolData = data.find(item => item.schoolSITId === activeSchoolId);
        setSchoolData(matchedSchoolData);
      })
      .catch(error => console.error('Error loading JSON:', error));
  }, [activeSchoolId]);

  const now = new Date();
  const dateFrom = new Date(getClubStartDateWithoutFormatting(club));
  const clubFinishDate = new Date(getClubFinishDateWithoutFormatting(club));
  const dateTo = clubFinishDate < now ? clubFinishDate : now;

  const requestInitial = {
    dateFrom: dateFrom,
    dateTo: dateTo,
    isPrintPP: schoolData ? schoolData.studentsPPInfo === 1 : false,
    isPrintSEN: schoolData ? schoolData.studentsSENInfo === 1 : false,
    isPrintFSM: schoolData ? schoolData.studentsFSMInfo === 1 : false,
    options: OPTIONS.IS_ATTENDANCE,
    isAttendance: false,
    isParticipation: false
  };

  return (
    <Formik
      onSubmit={values => {
        const valuesToTransfer = { ...values };
        propz.set(valuesToTransfer, [valuesToTransfer.options], true);
        delete valuesToTransfer.options;

        onSubmit(valuesToTransfer, REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.CLUB_ATTENDANCE);
      }}
      initialValues={requestInitial}
      render={({ values, setFieldValue }) => (
        <Form>
          <div className="bReportFormTitle">Club attendance report</div>
          <div className="mb-3 mt-3">
            {'A report showing attendance for the selected club within your chosen period. You can select the data ' +
              'to be shown by attendance or participation based on how you track registers on Squad In Touch.'}
          </div>
          <div className="form-group">
            <label>Date from</label>
            <Field name="dateFrom">
              {({ field, form }) => (
                <div className="mb-3">
                  <DatePicker
                    {...field}
                    selected={field.value}
                    onChange={date => {
                      form.setFieldValue('dateFrom', date);
                      if (form.values.dateTo && form.values.dateTo < date) {
                        form.setFieldValue('dateTo', date);
                      }
                    }}
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
              )}
            </Field>
          </div>
          <div className="form-group">
            <label>Date to</label>
            <Field name="dateTo">
              {({ field, form }) => (
                <div className="mb-3">
                  <DatePicker
                    {...field}
                    selected={field.value}
                    onChange={date => {
                      form.setFieldValue('dateTo', date);
                    }}
                    minDate={form.values.dateFrom}
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
              )}
            </Field>
          </div>

          <div className="form-group">
            <div className="form-check mb-1">
              <input
                className="form-check-input"
                type="radio"
                name={OPTIONS.IS_ATTENDANCE}
                id={OPTIONS.IS_ATTENDANCE}
                value={OPTIONS.IS_ATTENDANCE}
                checked={values.options === OPTIONS.IS_ATTENDANCE}
                onChange={() => {
                  setFieldValue('options', OPTIONS.IS_ATTENDANCE);
                }}
              />
              <LabelWithQuestionIcon
                labelText="Attendance"
                hintText={
                  'Select the Attendance option if you mark students as Present/Absent across all of your club sessions.'
                }
                customLabelClass="form-check-label pl-2"
                labelHtmlFor={OPTIONS.IS_ATTENDANCE}
              />
            </div>

            <div className="form-check mb-1">
              <input
                className="form-check-input"
                type="radio"
                name={OPTIONS.IS_PARTICIPATION}
                id={OPTIONS.IS_PARTICIPATION}
                value={OPTIONS.IS_PARTICIPATION}
                checked={values.options === OPTIONS.IS_PARTICIPATION}
                onChange={() => {
                  setFieldValue('options', OPTIONS.IS_PARTICIPATION);
                }}
              />
              <LabelWithQuestionIcon
                labelText="Participation"
                hintText={'Select Participation if you prefer to add and remove students from the club registers.'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor={OPTIONS.IS_PARTICIPATION}
              />
            </div>
          </div>

          {schoolData && schoolData.studentsSENInfo === 1 && (
            <div className="form-group">
              <div className="d-flex">
                <Field
                  name="isPrintSEN"
                  render={({ field }) => (
                    <Switch
                      {...field}
                      value={values.isPrintSEN}
                      onChange={event => {
                        const isPrintSEN = event.target.checked;
                        setFieldValue('isPrintSEN', isPrintSEN);
                      }}
                    />
                  )}
                />
                <LabelWithQuestionIcon
                  labelText="Show SEN"
                  hintText={'Show data relating to students with SEN.'}
                  customLabelClass="form-check-label pl-2"
                  labelHtmlFor="isPrintSEN"
                />
              </div>
            </div>
          )}

          {schoolData && schoolData.studentsPPInfo === 1 && (
            <div className="form-group">
              <div className="d-flex">
                <Field
                  name="isPrintPP"
                  render={({ field }) => (
                    <Switch
                      {...field}
                      value={values.isPrintPP}
                      onChange={event => {
                        const isPrintPP = event.target.checked;
                        setFieldValue('isPrintPP', isPrintPP);
                      }}
                    />
                  )}
                />
                <LabelWithQuestionIcon
                  labelText="Show PP"
                  hintText={'Show data relating to students with PP.'}
                  customLabelClass="form-check-label pl-2"
                  labelHtmlFor="isPrintPP"
                />
              </div>
            </div>
          )}

          {schoolData && schoolData.studentsFSMInfo === 1 && (
            <div className="form-group">
              <div className="d-flex">
                <Field
                  name="isPrintFSM"
                  render={({ field }) => (
                    <Switch
                      {...field}
                      value={values.isPrintFSM}
                      onChange={event => {
                        const isPrintFSM = event.target.checked;
                        setFieldValue('isPrintFSM', isPrintFSM);
                      }}
                    />
                  )}
                />
                <LabelWithQuestionIcon
                  labelText="Show FSM"
                  hintText={'Show data relating to students receiving free school meals.'}
                  customLabelClass="form-check-label pl-2"
                  labelHtmlFor="isPrintFSM"
                />
              </div>
            </div>
          )}

          <button type="button" className="btn btn-secondary mr-3" onClick={onCloseClick}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Get
          </button>
        </Form>
      )}
    />
  );
};
