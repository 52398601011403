import axios from 'axios';
import { AppUser } from 'Src/views/App/App';

export function getStudentEvent(user: AppUser, eventId) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.get(`${window.apiBase}/i/events/${eventId}`, config).then(response => {
    return response.data;
  });
}

export function getStudentEvents(user: AppUser, filter) {
  const { roleSessionKey: key } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: filter
    }
  };

  return axios.get(`${window.apiBase}/i/events`, config).then(response => {
    return response.data;
  });
}

export function getStudentEventsDates(user: AppUser, filter) {
  const { roleSessionKey: key } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: filter
    }
  };

  return axios.get(`${window.apiBase}/i/events/dates/distinct`, config).then(response => {
    return response.data;
  });
}

export function sentStudentAvailabilityReport(user: AppUser, eventId, data) {
  const { roleSessionKey: key } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.post(`${window.apiBase}/i/events/${eventId}/messages/report`, data, config).then(response => {
    return response.data;
  });
}
