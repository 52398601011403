import * as React from 'react';
import { Component } from 'react';
import { SidebarItemExpandable, SimpleSidebarItem } from './Sidebar';
import { SidebarItem } from './SidebarItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppUser } from 'Src/views/App/App';
import { getUserActiveSchoolSubscriptionPlan } from '../../helpers/user/user';

interface SidebarItemsProps {
  item: SidebarItemExpandable;
  user: AppUser;
}

interface SidebarItemsState {
  isOpen: boolean;
}

export class SidebarItems extends Component<SidebarItemsProps, SidebarItemsState> {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  onToggle = event => {
    event.preventDefault();

    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    const { title, items } = this.props.item;

    const { user } = this.props;

    const { isOpen } = this.state;

    const itemsWithNotifications = items.filter(item => typeof item.notificationCount !== 'undefined');
    const notificationsCountsSum = itemsWithNotifications.reduce(
      (sum, current) => sum + Number(current.notificationCount),
      0
    );
    const isNotificationExist = itemsWithNotifications.length > 0;

    const isNotificationToShow = isNotificationExist && notificationsCountsSum > 0 && !isOpen;

    return (
      <>
        <div className="eSidebarItemExpandable" onClick={this.onToggle}>
          <FontAwesomeIcon
            icon={'chevron-right'}
            className={
              isOpen ? 'eSidebarItemExpandableIcon mSidebarItemExpandableIconOpen' : 'eSidebarItemExpandableIcon'
            }
          />
          <div className="eSidebarItemExpandableTitle">
            <div className="eSidebarItemNotificationWrapper">
              {title}
              {isNotificationToShow && <span className="eSidebarItemNotification">{notificationsCountsSum}</span>}
            </div>
          </div>
        </div>
        {isOpen && (
          <ul className={'nav flex-column'}>
            {items.map((item, index) => {
              const isVisible = item.visibleToSchoolSubscriptions.some(
                subscription => subscription === getUserActiveSchoolSubscriptionPlan(user)
              );
              return isVisible ? (
                <li key={`sidebar_expandable_item_${index}`} className="nav-item">
                  <SidebarItem item={item} user={user} />
                </li>
              ) : null;
            })}
          </ul>
        )}
      </>
    );
  }
}
