import * as React from 'react';
import * as propz from 'propz';
import { AppUser } from '../../../../../../App/App';
import { SchoolEvent } from '../../../../../../../models/event';
import { Video } from '../../../../../../../models/video';
import { PreviewVideo } from './PreviewVideo';
import { LAYOUT_WIDTH, VIDEO_WIDTH } from '../../../../../../../consts/album';
import * as classNames from 'classnames';
import './EventVideos.scss';
import { ACCESS_PRESET_TYPE } from '../../../../../../../types/album';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { FullScreenVideo } from './FullScreenVideo';

interface Props {
  user: AppUser;
  eventInitial: SchoolEvent;
  videos: Video[];
  onDeleteVideoClick: (id: string) => void;
  isUpdateAccessPreset: boolean;
  onAccessPresetClick: (id: string, accessPreset: ACCESS_PRESET_TYPE) => void;
}
interface State {
  currentVideoIndex: number;
  windowWidth: number;
  currentVideoId: string;
  isVideoModalOpen: boolean;
  isVideoLoadingModalOpen: boolean;
}

export class EventVideos extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      currentVideoIndex: 0,
      windowWidth: window.innerWidth,
      currentVideoId: '',
      isVideoModalOpen: false,
      isVideoLoadingModalOpen: false
    };
  }

  onVideoClick = (id: string, isVideoLoading: boolean): void => {
    isVideoLoading
      ? this.openVideoLoadingModal()
      : this.setState({
          isVideoModalOpen: true,
          currentVideoId: id
        });
  };

  onCloseVideoModalClick = () => {
    this.setState({
      isVideoModalOpen: false
    });
  };

  renderVideos() {
    const { videos, onDeleteVideoClick } = this.props;
    return videos.map(video => {
      const url = propz.get(video, ['streamData', '0', 'url']);
      return (
        <PreviewVideo
          key={video.id}
          id={video.id}
          url={url}
          onDeleteVideoClick={onDeleteVideoClick}
          onVideoClick={this.onVideoClick}
          accessPreset={video.accessPreset}
        />
      );
    });
  }

  onLeft = () => {
    const { currentVideoIndex } = this.state;
    const index = currentVideoIndex === 0 ? 0 : currentVideoIndex - 1;

    this.setState({
      currentVideoIndex: index
    });
  };

  onRight = () => {
    const { videos } = this.props;
    const videosCount = videos.length;
    const { currentVideoIndex, windowWidth } = this.state;
    const index =
      currentVideoIndex >=
      videosCount - Math.floor((windowWidth < LAYOUT_WIDTH ? windowWidth - 60 : LAYOUT_WIDTH) / VIDEO_WIDTH)
        ? currentVideoIndex
        : currentVideoIndex + 1;

    this.setState({
      currentVideoIndex: index
    });
  };

  getCurrentPhotoIndex() {
    const { videos } = this.props;
    const { currentVideoId } = this.state;
    return videos.findIndex(photo => photo.id === currentVideoId);
  }

  onPreviousPhotoClick = () => {
    const { videos } = this.props;
    const currentPhotoIndex = this.getCurrentPhotoIndex();
    const photosCount = videos.length;

    const currentVideoId = currentPhotoIndex === 0 ? videos[photosCount - 1].id : videos[currentPhotoIndex - 1].id;

    this.setState({ currentVideoId: currentVideoId });
  };

  onNextPhotoClick = () => {
    const { videos } = this.props;
    const currentPhotoIndex = this.getCurrentPhotoIndex();
    const photosCount = videos.length;

    const currentVideoId = currentPhotoIndex === photosCount - 1 ? videos[0].id : videos[currentPhotoIndex + 1].id;

    this.setState({ currentVideoId: currentVideoId });
  };

  renderVideoLoadingModal() {
    const { isVideoLoadingModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isVideoLoadingModalOpen}
        title={'Loading'}
        body="Your video is being uploaded and will be available once it is ready. In the meantime you can close this message and continue managing your events."
        onCloseClick={this.closeVideoLoadingModal}
      />
    );
  }

  openVideoLoadingModal = () => {
    this.setState({
      isVideoLoadingModalOpen: true
    });
  };

  closeVideoLoadingModal = () => {
    this.setState({
      isVideoLoadingModalOpen: false
    });
  };

  renderVideoModal() {
    const { isVideoModalOpen, currentVideoId } = this.state;
    const { videos, onAccessPresetClick, isUpdateAccessPreset, user } = this.props;

    if (isVideoModalOpen) {
      const video = videos.find(v => v.id === currentVideoId);
      const videosCount = videos.length;
      const authorSchoolId = propz.get(video, ['author', 'schoolId']);
      const { activeSchoolId, activeSchool } = user;
      const isShowAccessPresetRadioButtons = activeSchoolId === authorSchoolId;

      return (
        <FullScreenVideo
          video={video}
          isShowArrowButtons={videosCount > 1}
          onPreviousVideoClick={this.onPreviousPhotoClick}
          onNextVideoClick={this.onNextPhotoClick}
          onCloseClick={this.onCloseVideoModalClick}
          onAccessPresetClick={accessPreset => {
            onAccessPresetClick(video.id, accessPreset);
          }}
          isUpdateAccessPreset={isUpdateAccessPreset}
          isShowAccessPresetRadioButtons={isShowAccessPresetRadioButtons}
        />
      );
    } else {
      return null;
    }
  }

  render() {
    const { windowWidth, currentVideoIndex, isVideoModalOpen } = this.state;
    const { videos } = this.props;
    const windowWidthCorrected = windowWidth < LAYOUT_WIDTH ? windowWidth - 60 : LAYOUT_WIDTH;
    const videosCount = videos.length;
    const widthStrip = videosCount * VIDEO_WIDTH;
    const offset = currentVideoIndex * VIDEO_WIDTH;
    const margin =
      offset + windowWidthCorrected <= widthStrip || offset === 0 ? -offset : windowWidthCorrected - widthStrip;
    const style = { width: widthStrip, marginLeft: margin };

    const lBtnClasses = classNames({
      eArrow: true,
      mLeft: true,
      mHidden: offset === 0
    });

    const rBtnClasses = classNames({
      eArrow: true,
      mRight: true,
      mHidden: windowWidthCorrected > widthStrip || margin !== -offset
    });

    const classes = isVideoModalOpen ? 'mt-3 modal-open' : 'mt-3';

    return (
      <div className={classes}>
        {this.renderVideoModal()}
        {this.renderVideoLoadingModal()}
        <div className="bEventVideos">
          {videosCount > 0 && <h3>Videos</h3>}
          <div className="bVideos">
            <div className="eVideoStrip" style={style}>
              {this.renderVideos()}
            </div>
          </div>
          <div className={lBtnClasses} onClick={this.onLeft} />
          <div className={rBtnClasses} onClick={this.onRight} />
        </div>
      </div>
    );
  }
}
