import * as React from 'react';
import { Route, Switch } from 'react-router';
import { SchoolEvents } from './SchoolEvents';
import { SchoolEventCopyManager } from './SchoolEventCopyManager/SchoolEventCopyManager';
import { SchoolEventView } from './SchoolEventView/SchoolEventView';
import { SchoolTeamManager } from './SchoolTeamManager/SchoolTeamManager';
import { SchoolIndividualManager } from './SchoolIndividualManager/SchoolIndividualManager';
import { ArbiterBoard } from './SchoolArbiterBoard/ArbiterBoard';
import { SchoolEventManager } from './SchoolEventManager';
import { SchoolEventExistingTeamManager } from './SchoolEventExistingTeamManager/SchoolEventExistingTeamManager';
import { SchoolEventUpdatingTeamManager } from './SchoolEventUpdatingTeamManager/SchoolEventUpdatingTeamManager';

export const SchoolEventsRouting = allProps => {
  return (
    <Switch>
      <Route exact path={'/events'} render={props => <SchoolEvents {...props} user={allProps.user} />} />
      <Route exact path={'/events/manager'} render={props => <SchoolEventManager {...props} user={allProps.user} />} />
      <Route
        exact
        path={'/events/copyManager'}
        render={props => <SchoolEventCopyManager {...props} user={allProps.user} />}
      />
      <Route exact path={'/events/event'} render={props => <SchoolEventView {...props} user={allProps.user} />} />
      <Route exact path={'/events/arbiterBoard'} render={props => <ArbiterBoard {...props} user={allProps.user} />} />
      <Route
        exact
        path={'/events/teamManager'}
        render={props => <SchoolTeamManager {...props} user={allProps.user} />}
      />
      <Route
        exact
        path={'/events/individualManager'}
        render={props => <SchoolIndividualManager {...props} user={allProps.user} />}
      />

      <Route
        exact
        path={'/events/existingTeamManager'}
        render={props => <SchoolEventExistingTeamManager {...props} user={allProps.user} />}
      />

      <Route
        exact
        path={'/events/updatingTeamManager'}
        render={props => <SchoolEventUpdatingTeamManager {...props} user={allProps.user} />}
      />
    </Switch>
  );
};

SchoolEventsRouting.displayName = 'SchoolEventsRouting';
