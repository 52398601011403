import { AppUser } from 'Src/views/App/App';
import axios from 'axios';
import { DEFAULT_FILTER } from '../admin/user';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../consts/table';
import { Count } from '../../../models/count';

export function getTournamentCustomGroups(user: AppUser, tournamentId: string, filter = DEFAULT_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter
    }
  };

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/customGroups`, config)
    .then(response => {
      return response.data;
    });
}

export function getAllTournamentCustomGroups(user: AppUser, tournamentId: string, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/customGroups`, config)
    .then(response => {
      return response.data;
    });
}

export function getTournamentCustomGroupsCount(user: AppUser, tournamentId: string, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where
        }
      }
    };
  } else {
    config = {
      headers: { usid: key }
    };
  }

  return axios
    .get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/customGroups/count`, config)
    .then(response => {
      return response.data;
    });
}

export function assignPitchByTournamentIdAndCustomGroupId(user: AppUser, data, tournamentId, customGroupId) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .post(
      `${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/customGroups/${customGroupId}/pitches`,
      data,
      config
    )
    .then(response => {
      return response.data;
    });
}

export function unassignPitchByTournamentIdAndCustomGroupId(user: AppUser, tournamentId, customGroupId) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .delete(
      `${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/customGroups/${customGroupId}/pitches`,
      config
    )
    .then(response => {
      return response.data;
    });
}

export function createTournamentGroup(user: AppUser, tournamentId: string, data) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/customGroups`, data, config)
    .then(response => {
      return response.data;
    });
}

export function editTournamentGroup(user: AppUser, tournamentId: string, groupId: string, data) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .put(
      `${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/customGroups/${groupId}`,
      data,
      config
    )
    .then(response => {
      return response.data;
    });
}

export function deleteTournamentGroup(user: AppUser, tournamentId: string, groupId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .delete(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/customGroups/${groupId}`, config)
    .then(response => {
      return response.data;
    });
}
