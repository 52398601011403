import * as React from 'react';
import * as propz from 'propz';
import { useState } from 'react';
import { REGISTRATION_STAFF_PRESET, RegistrationStaff } from '../Registration';
import { Button } from '../../../components/Button/Button';

interface Props {
  staff: RegistrationStaff;
  onStaffClick: (staff: RegistrationStaff) => void;
  school: any;
}

export function StaffRoleSelector(props: Props) {
  const { staff, onStaffClick, school } = props;

  const [staffPreset, changeStaffPreset] = useState(staff);
  const isStaffPresetExist = typeof staffPreset !== 'undefined';

  const isAdminAllowed = propz.get(school, ['allowedPermissionPresets', REGISTRATION_STAFF_PRESET.ADMIN], false);
  const isManagerAllowed = propz.get(school, ['allowedPermissionPresets', REGISTRATION_STAFF_PRESET.MANAGER], false);
  const isCoachAllowed = propz.get(school, ['allowedPermissionPresets', REGISTRATION_STAFF_PRESET.COACH], false);
  const isTeacherAllowed = propz.get(school, ['allowedPermissionPresets', REGISTRATION_STAFF_PRESET.TEACHER], false);

  return (
    <>
      <div className={'font-weight-bold'}>Please, select the type of user</div>
      {isAdminAllowed && (
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="admin"
            id="admin"
            value={REGISTRATION_STAFF_PRESET.ADMIN}
            checked={staffPreset === REGISTRATION_STAFF_PRESET.ADMIN}
            onChange={() => {
              changeStaffPreset(REGISTRATION_STAFF_PRESET.ADMIN);
            }}
          />
          <label className="form-check-label" htmlFor="admin">
            I'm a school administrator
          </label>
        </div>
      )}

      {isManagerAllowed && (
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="manager"
            id="manager"
            value={REGISTRATION_STAFF_PRESET.MANAGER}
            checked={staffPreset === REGISTRATION_STAFF_PRESET.MANAGER}
            onChange={() => {
              changeStaffPreset(REGISTRATION_STAFF_PRESET.MANAGER);
            }}
          />
          <label className="form-check-label" htmlFor="manager">
            I'm a school manager
          </label>
        </div>
      )}

      {isCoachAllowed && (
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="coach"
            id="coach"
            value={REGISTRATION_STAFF_PRESET.COACH}
            checked={staffPreset === REGISTRATION_STAFF_PRESET.COACH}
            onChange={() => {
              changeStaffPreset(REGISTRATION_STAFF_PRESET.COACH);
            }}
          />
          <label className="form-check-label" htmlFor="coach">
            I'm a coach
          </label>
        </div>
      )}

      {isTeacherAllowed && (
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="teacher"
            id="teacher"
            value={REGISTRATION_STAFF_PRESET.TEACHER}
            checked={staffPreset === REGISTRATION_STAFF_PRESET.TEACHER}
            onChange={() => {
              changeStaffPreset(REGISTRATION_STAFF_PRESET.TEACHER);
            }}
          />
          <label className="form-check-label" htmlFor="teacher">
            I'm a school PE teacher
          </label>
        </div>
      )}

      <Button
        disabled={!isStaffPresetExist}
        onClick={() => {
          onStaffClick(staffPreset);
        }}
        text={'Continue'}
        customClass={'mt-3 mb-3'}
      />
    </>
  );
}
