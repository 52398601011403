import { FILTER_TYPE } from '../../consts/table';
import { ColumnDefinition } from '../table/table';

export function getTwoPanelEditorFilters(columns: ColumnDefinition[], search?): any {
  const filters = {};

  const columnsWithFilter = columns.filter(column => column.type !== FILTER_TYPE.NONE);

  columnsWithFilter.forEach(filter => {
    const { type, field } = filter;

    const isFilterTypeMultiSelect = type === FILTER_TYPE.MULTISELECT;
    const searchField = typeof search !== 'undefined' ? search[field] : undefined;
    const isSearchFieldUndefined = typeof searchField === 'undefined';
    const isSearchFieldString = typeof searchField === 'string';
    const isSearchFieldArray = Array.isArray(searchField);

    switch (true) {
      case isFilterTypeMultiSelect && isSearchFieldUndefined:
        filters[field] = [];
        break;
      case isFilterTypeMultiSelect && isSearchFieldString:
        filters[field] = [searchField];
        break;
      case isFilterTypeMultiSelect && !isSearchFieldString:
        filters[field] = searchField;
        break;
      case isSearchFieldString:
        //query-parse insert space instead plus symbol, so convert it back
        if (field === 'phone' || field === 'email') {
          filters[field] = searchField.replace(' ', '+');
        } else {
          filters[field] = searchField;
        }
        break;
      case isSearchFieldArray:
        const toIndex = (searchField as string[]).findIndex(field => field.indexOf('to') !== -1);
        const fromIndex = (searchField as string[]).findIndex(field => field.indexOf('from') !== -1);

        const to = searchField[toIndex];
        const from = searchField[fromIndex];

        filters[field] = {
          to: '',
          from: ''
        };

        if (typeof to !== 'undefined') {
          filters[field].to = to.split('_')[1];
        }

        if (typeof from !== 'undefined') {
          filters[field].from = from.split('_')[1];
        }
        break;
      case field === 'birthday':
      case field === 'createdAt':
      case field === 'sentAt':
      case field === 'date':
      case field === 'finishDate':
      case field === 'startDate':
      case field === 'startTime':
        filters[field] = {
          to: '',
          from: ''
        };
        break;
      default:
        filters[field] = '';
    }
  });

  return filters;
}
