import { AppUser } from 'Src/views/App/App';
import axios from 'axios';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from 'Src/consts/table';

const DEFAULT_REQUESTS_FILTER: any = {
  status: {
    $ne: 'NEW'
  }
};

/** ARCHIVE REQUEST TABLE */
export function getArchiveRequests(user: AppUser, filter = DEFAULT_REQUESTS_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...DEFAULT_REQUESTS_FILTER,
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order
      }
    }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/permissions/requests`, config).then(response => {
    return response.data;
  });
}

export function getArchiveRequestsCount(user: AppUser, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_REQUESTS_FILTER,
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_REQUESTS_FILTER
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/permissions/requests`, config).then(response => {
    return { count: response.data.length };
  });

  //add server method and uncomment this
  /*const {roleSessionKey: key, activeSchoolId} = user;
  
  let config;
  
  if (typeof where !== 'undefined') {
    config = {
      headers: { 'usid': key },
      params: {
        filter: {
          where: {
            ...DEFAULT_REQUESTS_FILTER,
            ...where
          }
        }
      }
    };
  } else {
    config = {
      headers: { 'usid': key },
      params: {
        filter: {
          where: {
            ...DEFAULT_REQUESTS_FILTER
          }
        }
      }
    };
  }
  
  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/permissions/requests/count`, config).then(response => {
    return response.data;
  });*/
}

export function getAllArchiveRequests(user: AppUser, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_REQUESTS_FILTER,
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_REQUESTS_FILTER
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/permissions/requests`, config).then(response => {
    return response.data;
  });
}
