import axios from 'axios';
import { AppUser } from '../../../views/App/App';

export function addConvertJob(user: AppUser, data) {
  const { loginSessionKey: key } = user;

  const config = {
    headers: { usid: key }
  };
  return axios.post(`${window.apiBase}/i/aws/convert`, data, config).then(response => {
    return response.data;
  });
}
