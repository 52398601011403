import * as React from 'react';
import { FunctionComponent } from 'react';

interface Props {
  affectedSchools: any[];
}

export const AffectedSchoolList: FunctionComponent<Props> = props => {
  const { affectedSchools } = props;

  const schoolsCount = affectedSchools.length;

  const body: React.ReactNodeArray = affectedSchools.map((school, index) => {
    return (
      <tr key={`affected_school_list_row_${index}`}>
        <td>{index + 1}</td>
        <td>{school.schoolName}</td>
      </tr>
    );
  });

  const allUsers = affectedSchools.length;

  return (
    <div>
      <div className="font-weight-bold">{`Schools affected (${allUsers})`}</div>

      {schoolsCount === 0 ? (
        <div>The message appears to be sent to 0 schools</div>
      ) : (
        <table className={'table table-sm mPre mt-3'}>
          <thead className="font-weight-bold">
            <tr key="affected_school_list_head">
              <td></td>
              <td>Name</td>
            </tr>
          </thead>
          <tbody>{body}</tbody>
        </table>
      )}
    </div>
  );
};

AffectedSchoolList.displayName = 'AffectedSchoolList';
