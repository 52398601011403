import { AppUser } from '../../../views/App/App';
import axios from 'axios';

export function addTeamInClubEvents(user: AppUser, clubId: string, eventId: string, teamId: string, data: any) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .post(
      `${window.apiBase}/i/schools/${activeSchoolId}/clubs/${clubId}/events/${eventId}/teams/${teamId}/rgroup`,
      data,
      config
    )
    .then(response => {
      return response.data;
    });
}
