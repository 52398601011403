import * as React from 'react';
import { Component } from 'react';
import { AppUser } from 'Src/views/App/App';
import { ModalDialog } from '../ModalDialog/ModalDialog';

interface Props {
  user: AppUser;
  onSubmitHouseClick: (house) => void;
  onCancelClick: () => void;
  houses: any[];
  isOpen: boolean;
}

interface State {
  selectedHouseId: string;
}

export class HouseRemoveModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const selectedId = typeof props.houses !== 'undefined' && props.houses.length > 0 ? props.houses[0].id : '';
    this.state = { selectedHouseId: selectedId };
  }

  onHouseSelected = (event): void => {
    this.setState({ selectedHouseId: event.target.value });
  };

  onHouseSubmit = (): void => {
    const house = this.props.houses.find(house => house.id === this.state.selectedHouseId);
    this.props.onSubmitHouseClick(house);
  };

  render() {
    return (
      <ModalDialog
        title="Remove house"
        isOpen={this.props.isOpen}
        positiveButtonTitle="Remove"
        negativeButtonTitle="Cancel"
        onPositiveButtonClick={this.onHouseSubmit}
        onNegativeButtonClick={this.props.onCancelClick}
      >
        <div className="form-group">
          <label>House</label>
          <select value={this.state.selectedHouseId} className="form-control" onChange={this.onHouseSelected}>
            {this.props.houses.map(house => (
              <option key={house.id} value={house.id}>
                {house.name}
              </option>
            ))}
          </select>
        </div>
      </ModalDialog>
    );
  }
}
