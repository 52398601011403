export const DEFAULT_RANGE_CALENDAR_YEARS = 5;

export const CLUB_SIGN = 'C';
export const TOURNAMENT_SIGN = 'T';
export const DEFAULT_SIGN = 'F';

export const CALENDAR_FILTER = {
  SPORT_EVENTS: 'SPORT_EVENTS',
  CLUB_EVENTS: 'CLUB_EVENTS',
  TOURNAMENT_EVENTS: 'TOURNAMENT_EVENTS',
  TOURNAMENTS: 'TOURNAMENTS',
  ALL: 'ALL'
};
