import { AppUser } from 'Src/views/App/App';
import * as propz from 'propz';

export function availabilityParentEnabled(user: AppUser): boolean {
  const { activeSchool } = user;
  const availabilityParentEnabled = propz.get<boolean>(activeSchool, ['settings', 'availabilityParentEnabled']);
  return availabilityParentEnabled;
}
export function availabilityStudentEnabled(user: AppUser): boolean {
  const { activeSchool } = user;
  const availabilityStudentEnabled = propz.get<boolean>(activeSchool, ['settings', 'availabilityStudentEnabled']);
  return availabilityStudentEnabled;
}
