import { AppUser } from 'Src/views/App/App';
import axios from 'axios';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../consts/table';
import { Count } from '../../../models/count';

const DEFAULT_SCHOOL_TOURNAMENT_EVENTS_ORDER = 'startTime ASC';

export function getTournamentEvents(user: AppUser, tournamentId: string, filter) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order || DEFAULT_SCHOOL_TOURNAMENT_EVENTS_ORDER
      }
    }
  };

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/schools/tournaments/${tournamentId}/events`, config)
    .then(response => {
      return response.data;
    });
}

export function getAllTournamentEvents(user: AppUser, tournamentId: string, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP,
          order: DEFAULT_SCHOOL_TOURNAMENT_EVENTS_ORDER
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP,
          order: DEFAULT_SCHOOL_TOURNAMENT_EVENTS_ORDER
        }
      }
    };
  }

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/schools/tournaments/${tournamentId}/events`, config)
    .then(response => {
      return response.data;
    });
}

export function getTournamentEventsCount(user: AppUser, tournamentId: string, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where
        }
      }
    };
  } else {
    config = {
      headers: { usid: key }
    };
  }

  return axios
    .get<Count>(
      `${window.apiBase}/i/schools/${activeSchoolId}/schools/tournaments/${tournamentId}/events/count`,
      config
    )
    .then(response => {
      return response.data;
    });
}

export function assignPitchByTournamentIdAndEventId(user: AppUser, data, tournamentId, eventId) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .post(
      `${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/events/${eventId}/pitches`,
      data,
      config
    )
    .then(response => {
      return response.data;
    });
}

export function unassignPitchByTournamentIdAndEventId(user: AppUser, tournamentId, eventId) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .delete(
      `${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/events/${eventId}/pitches`,
      config
    )
    .then(response => {
      return response.data;
    });
}

export function deleteEvent(user: AppUser, eventId) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.delete(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}`, config).then(response => {
    return response.data;
  });
}

export function getHighLevelTournamentEvents(user: AppUser, filter) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...filter.where
        },
        limit: filter.limit,
        order: filter.order || DEFAULT_SCHOOL_TOURNAMENT_EVENTS_ORDER
      }
    }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/events/`, config).then(response => {
    return response.data;
  });
}

export function getAllSchoolUnionHighLevelTournamentEvents(user: AppUser, tournamentId: string, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP,
          order: DEFAULT_SCHOOL_TOURNAMENT_EVENTS_ORDER
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP,
          order: DEFAULT_SCHOOL_TOURNAMENT_EVENTS_ORDER
        }
      }
    };
  }

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/schools/tournaments/${tournamentId}/events`, config)
    .then(response => {
      return response.data;
    });
}

export function getSchoolUnionHighLevelTournamentEvents(user: AppUser, tournamentId: string, filter) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...filter.where
        },
        limit: filter.limit,
        order: filter.order || DEFAULT_SCHOOL_TOURNAMENT_EVENTS_ORDER,
        skip: filter.skip
      }
    }
  };

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/schools/tournaments/${tournamentId}/events`, config)
    .then(response => {
      return response.data;
    });
}

export function getSchoolUnionHighLevelTournamentEventsCount(user: AppUser, tournamentId: string, where) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where
        }
      }
    };
  } else {
    config = {
      headers: { usid: key }
    };
  }

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/schools/tournaments/${tournamentId}/events/count`, config)
    .then(response => {
      return response.data;
    });
}
