import * as React from 'react';
import * as BPromise from 'bluebird';
import { AppUser } from 'Src/views/App/App';
import { parse } from 'query-string';
import { getOrder, getSearchFilter, getSearchOrder, getServerFieldSectionWhere } from 'Src/helpers/table/table';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { Loader } from 'Src/components/Loader/Loader';
import {
  ColumnDefinition,
  getFilters2,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { History, Location } from 'history';
import { Grid2 } from 'Src/components/Grid/Grid2';
import { getCreatedAt, getRequestRole, getRequestSports } from 'Src/helpers/accessor/accessor';
import { Request } from 'Src/models/request';
import {
  getAllRequests,
  getRequests,
  getRequestsCount,
  mergeUserStaff,
  updateRequest
} from 'Src/helpers/service/admin/requests';
import { AcceptRequestModal } from 'Src/components/AcceptRequestModal/AcceptRequestModal';
import { isUserActiveSchoolSubscriptionPlanFree, isUserActiveSchoolSubscriptionPlanFull } from 'Src/helpers/user/user';
import { PERMISSION_REQUEST_STATUS } from 'Src/consts/permissionRequest';
import { ROLE } from 'Src/consts/user';
import { getSelectOptionForRole } from 'Src/helpers/table/select';
import { AcceptAndMergeRequestForm } from 'Src/components/AcceptRequestModal/AcceptAndMergeRequestForm';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { AcceptAndMergeRequestInfoModal } from 'Src/components/AcceptRequestModal/AcceptAndMergeRequestInfoModal';
import { SimpleAlert } from 'Src/components/SimpleAlert/SimpleAlert';
import * as propz from 'propz';
import { mergeStudent } from '../../../../../helpers/service/admin/students';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Date',
    field: 'createdAt',
    isSort: false,
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: getCreatedAt
  },
  {
    text: 'Name',
    field: 'requester.firstName',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['requester', 'firstName']
  },
  {
    text: 'Surname',
    field: 'requester.lastName',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['requester', 'lastName']
  },
  {
    text: 'Phone',
    field: 'phone',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: ['requester', 'phone']
  },
  {
    text: 'Email',
    field: 'requester.email',
    isSort: false,
    type: FILTER_TYPE.TEXT,
    accessor: ['requester', 'email']
  },
  {
    text: 'Role',
    field: 'requestedPermissionPreset',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getRequestRole
  },
  {
    text: 'Comment',
    field: 'comment',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: ['requestedPermission', 'comment']
  },
  {
    text: 'Sports',
    field: 'sports',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getRequestSports
  }
];

interface State {
  items: Request[];
  mergeUser: any;
  currentPage: number;
  selectedItems: Request[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;

  isAcceptModalOpen: boolean;
  isAcceptAndMergeModalOpen: boolean;
  isAcceptAndMergeRequestInfoModal: boolean;
  isAcceptRequestDisabledModalOpen: boolean;
  isError: boolean;
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
  setNeedUpdate: () => void;
}

export class Requests extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      mergeUser: undefined,
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',

      isAcceptModalOpen: false,
      isAcceptAndMergeModalOpen: false,
      isAcceptAndMergeRequestInfoModal: false,
      isAcceptRequestDisabledModalOpen: false,
      isError: false
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setItems();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const getItemsPromise = getRequests(user, serverQueryFilter);
    const getItemsCountPromise = getRequestsCount(user, where);

    const promises = [getItemsCountPromise, getItemsPromise];

    return BPromise.all(promises)
      .then(([countObj, items]) => {
        this.setState({
          currentPage: page,
          sortDirection: sortDirection,
          sortColumnsName: sortColumnsName,
          isShowFilter: isShowFilter,
          isDataFiltered: isDataFiltered,
          filters: filters,
          items: items,
          total: countObj.count,
          basePath: history.location.pathname,
          isLoading: false
        });
        return true;
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        this.setState({ isLoading: false, items: [] });
      });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: nextSelectedItems,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    getAllRequests(user, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const nextSelectedItems = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: nextSelectedItems,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: ''
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  closeAcceptRequestModal = (): void => {
    this.setState({
      isAcceptModalOpen: false
    });
  };

  onAcceptRequestClick = () => {
    const user = this.props.user;
    const requests = this.state.items;
    const { selectedItems } = this.state;
    const selectedItem = selectedItems[0];
    const selectedItemId = selectedItem.id;

    const request = requests.find(requestState => requestState.id === selectedItemId);

    const isRequestedPermissionPresetCoach = request.requestedPermission.preset === ROLE.COACH;
    const isRequestedPermissionPresetTeacher = request.requestedPermission.preset === ROLE.TEACHER;
    const isRequestedPermissionPresetParent = request.requestedPermission.preset === ROLE.PARENT;
    const isRequestedPermissionPresetStudent = request.requestedPermission.preset === ROLE.STUDENT;

    const isSubscriptionPlanFull = isUserActiveSchoolSubscriptionPlanFull(user);

    switch (true) {
      case isRequestedPermissionPresetCoach:
      case isRequestedPermissionPresetTeacher:
      // for free communication school parent request accept without student
      case isRequestedPermissionPresetParent && isSubscriptionPlanFull:
      case isRequestedPermissionPresetStudent:
        this.props.history.push({
          pathname: `/requests/${selectedItemId}`
        });
        break;
      default:
        const data = {
          status: PERMISSION_REQUEST_STATUS.ACCEPTED
        };

        this.setState({
          isLoading: true
        });

        updateRequest(user, selectedItemId, data).then(res => {
          this.setState({
            isAcceptModalOpen: false,
            selectedItems: []
          });
          this.setItems().then(() => {
            this.props.setNeedUpdate();
          });
        });
    }
  };

  onAcceptAndMergeRequestClick = mergeUser => {
    this.setState({
      isAcceptAndMergeModalOpen: false,
      isAcceptAndMergeRequestInfoModal: true,
      mergeUser: mergeUser
    });
  };

  onAcceptAndMergeRequestInfoModalClick = () => {
    const user = this.props.user;
    const requests = this.state.items;
    const { selectedItems, mergeUser } = this.state;
    const mergeUserId = mergeUser.user.id;
    const [selectedItem] = selectedItems;
    const selectedItemId = selectedItem.id;

    const request = requests.find(requestState => requestState.id === selectedItemId);

    const data = {
      permissionId: request.id,
      userId: request.requesterId
    };

    this.setState({
      isLoading: true
    });

    const promise =
      selectedItem.requestedPermission.preset === ROLE.STUDENT
        ? mergeStudent(user, mergeUserId, data)
        : mergeUserStaff(user, mergeUserId, data);

    promise
      .then(res => {
        this.setState({
          isAcceptAndMergeRequestInfoModal: false,
          selectedItems: []
        });
        this.setItems().then(() => {
          this.props.setNeedUpdate();
        });
      })
      .catch(error => {
        console.error(error);
        this.setState({
          isAcceptAndMergeRequestInfoModal: false,
          isError: true
        });
        this.setItems();
      });
  };

  onCancelButtonAcceptAndMergeRequestInfoModalClick = (): void => {
    this.setState({
      isAcceptAndMergeRequestInfoModal: false
    });
  };

  onCloseErrorClick = (event: Event) => {
    event.preventDefault();
    this.setState({
      isError: false
    });
  };

  renderErrorAlert(): React.ReactNode {
    const { isError } = this.state;

    if (!isError) {
      return null;
    }

    const errorMessage =
      'Unable to merge this user. Some error. Contact Squad In Touch support team if you should have further questions.';
    return (
      <SimpleAlert
        message={errorMessage}
        additionalClassNames={'alert-danger'}
        onCloseAlertClick={this.onCloseErrorClick}
      />
    );
  }

  renderAcceptRequestModal(): React.ReactNode {
    const { selectedItems, items } = this.state;
    const isOneItemSelected = selectedItems.length === 1;
    const request = isOneItemSelected ? items.find(requestState => requestState.id === selectedItems[0].id) : undefined;

    return (
      <AcceptRequestModal
        request={request}
        onCloseClick={this.closeAcceptRequestModal}
        onAcceptClick={this.onAcceptRequestClick}
        isOpen={this.state.isAcceptModalOpen}
      />
    );
  }

  renderAcceptAndMergeRequestForm(): React.ReactNode {
    const { selectedItems, items, isAcceptAndMergeModalOpen } = this.state;
    const { user } = this.props;
    const isOneItemSelected = selectedItems.length === 1;
    const request = isOneItemSelected ? items.find(requestState => requestState.id === selectedItems[0].id) : undefined;

    return (
      <SimpleModal isOpen={isAcceptAndMergeModalOpen}>
        <AcceptAndMergeRequestForm
          request={request}
          onCloseClick={this.onAcceptAndMergeRequestCloseClick}
          onAcceptClick={this.onAcceptAndMergeRequestClick}
          user={user}
        />
      </SimpleModal>
    );
  }

  renderAcceptAndMergeRequestInfoModal(): React.ReactNode {
    const { selectedItems, items, mergeUser, isAcceptAndMergeRequestInfoModal } = this.state;
    const isOneItemSelected = selectedItems.length === 1;
    const request = isOneItemSelected ? items.find(requestState => requestState.id === selectedItems[0].id) : undefined;

    return (
      <AcceptAndMergeRequestInfoModal
        isOpen={isAcceptAndMergeRequestInfoModal}
        request={request}
        mergeUser={mergeUser}
        onAcceptClick={this.onAcceptAndMergeRequestInfoModalClick}
        onCloseClick={this.onCancelButtonAcceptAndMergeRequestInfoModalClick}
      />
    );
  }

  onAcceptAndMergeRequestCloseClick = (): void => {
    this.setState({
      isAcceptAndMergeModalOpen: false
    });
  };

  openAcceptRequestModal = (): void => {
    const { user } = this.props;
    const isAcceptRolesAvailable = propz.get(user, ['activeSchool', 'canAcceptStaffRoles']);
    const { selectedItems } = this.state;
    const isOneItemSelected = selectedItems.length === 1;
    const request = isOneItemSelected ? selectedItems[0] : undefined;
    const role = propz.get(request, ['requestedPermission', 'preset']);

    if (role === ROLE.ADMIN || isAcceptRolesAvailable) {
      this.setState({
        isAcceptModalOpen: true
      });
    } else {
      this.openAcceptRequestDisabledModal();
    }
  };

  openAcceptAndMergeRequestModal = (): void => {
    const { user } = this.props;
    const isAcceptRolesAvailable = propz.get(user, ['activeSchool', 'canAcceptStaffRoles']);

    if (isAcceptRolesAvailable) {
      this.setState({
        isAcceptAndMergeModalOpen: true
      });
    } else {
      this.openAcceptRequestDisabledModal();
    }
  };

  renderAcceptRequestDisabledModal = () => {
    const { isAcceptRequestDisabledModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isAcceptRequestDisabledModalOpen}
        title={'Access denied'}
        buttonText={'Ok'}
        onButtonClick={this.closeAcceptRequestDisabledModal}
        body={'This feature is not available in your current subscription plan'}
      />
    );
  };

  openAcceptRequestDisabledModal = () => {
    this.setState({
      isAcceptRequestDisabledModalOpen: true
    });
  };

  closeAcceptRequestDisabledModal = () => {
    this.setState({
      isAcceptRequestDisabledModalOpen: false
    });
  };

  isActiveActionItem(): boolean {
    const { selectedItems, items } = this.state;
    const user = this.props.user;
    const isOneItemSelected = selectedItems.length === 1;

    if (!isOneItemSelected) {
      return false;
    }

    const isSubscriptionPlanFree = isUserActiveSchoolSubscriptionPlanFree(user);
    const isSubscriptionPlanFull = isUserActiveSchoolSubscriptionPlanFull(user);

    const request = items.find(requestState => requestState.id === selectedItems[0].id);
    const isRequestedPermissionPresetParent = request.requestedPermission.preset === ROLE.PARENT;

    switch (true) {
      case isSubscriptionPlanFull:
      case isSubscriptionPlanFree && isRequestedPermissionPresetParent:
        return true;
      default:
        return false;
    }
  }

  onRejectRequestClick = () => {
    const user = this.props.user;
    const { selectedItems } = this.state;
    const selectedItem = selectedItems[0];
    const selectedItemId = selectedItem.id;

    const data = {
      status: PERMISSION_REQUEST_STATUS.REJECTED
    };

    this.setState({
      selectedItems: [],
      isLoading: true
    });

    updateRequest(user, selectedItemId, data).then(res => {
      this.setItems().then(() => {
        this.props.setNeedUpdate();
      });
    });
  };

  render() {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isLoading,
      isSelectAllChecked,
      currentPage,
      total,
      isAcceptModalOpen,
      isAcceptAndMergeModalOpen,
      isAcceptAndMergeRequestInfoModal,
      isError
    } = this.state;

    const { studentImportForAdminAllowed } = this.props.user.activeSchool;
    const request = selectedItems[0];
    const role = propz.get(request, ['requestedPermission', 'preset']);
    const isStudent = role === ROLE.STUDENT;

    if (isLoading) {
      return <Loader />;
    }

    const classes =
      isAcceptModalOpen || isAcceptAndMergeModalOpen || isAcceptAndMergeRequestInfoModal || isError
        ? 'mt-3 modal-open'
        : 'mt-3';

    const actionItems = [
      {
        itemText: 'Accept request',
        onItemClick: this.openAcceptRequestModal,
        isActive: this.isActiveActionItem() && !isStudent
      },
      {
        itemText: 'Accept and merge request',
        onItemClick: this.openAcceptAndMergeRequestModal,
        isActive: this.isActiveActionItem() && isStudent
      },
      {
        itemText: 'Reject request',
        onItemClick: this.onRejectRequestClick,
        isActive: this.isActiveActionItem()
      }
    ];

    const filterOptions = {
      requestedPermissionPreset: getSelectOptionForRole()
    };

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderErrorAlert()}
        {this.renderAcceptAndMergeRequestInfoModal()}
        {this.renderAcceptRequestModal()}
        {this.renderAcceptAndMergeRequestForm()}
        {this.renderAcceptRequestDisabledModal()}
        <div className="row">
          <div className="col-md-12">
            <Grid2
              dataItems={items}
              filters={filters}
              currentPage={currentPage}
              total={total}
              isSelectAllChecked={isSelectAllChecked}
              isDataFiltered={isDataFiltered}
              sortDirection={sortDirection}
              sortColumnsName={sortColumnsName}
              isShowFilter={isShowFilter}
              dataItemsSelected={selectedItems}
              columns={COLUMNS}
              actionItems={actionItems}
              options={filterOptions}
              onItemClick={this.onItemClick}
              onSortClick={this.onTableSortClick}
              onApplyFilterClick={this.onApplyFilterClick}
              onClearFilterClick={this.onClearFilterClick}
              onTableFilterChange={this.onTableFilterChange}
              onTableFilterClick={this.onTableFilterClick}
              setCurrentPageParams={this.setCurrentPageParams}
              onSelectAllClick={this.onSelectAllClick}
              onSelectAllOnPageClick={this.onSelectAllOnPageClick}
              onUnselectAllClick={this.onUnselectAllClick}
            />
          </div>
        </div>
      </div>
    );
  }
}
