import * as React from 'react';
import { AppUser } from 'Src/views/App/App';
import * as propz from 'propz';
import * as Moment from 'moment';
import {
  WEEK_DAYS_SERVER_TO_CLIENT_MAPPING,
  DATE_FORMAT_FULL_MONTH_US,
  DATE_FORMAT_FULL_MONTH,
  TIME
} from 'Src/consts/date';
import { SPORT_GENDER_SERVER_TO_CLIENT_MAPPING, SECONDS_IN_MINUTE, MILLISECONDS_IN_SECOND } from 'Src/consts/common';
import { getPhoneRegion } from 'Src/helpers/region/region';
import { MessageDefinition } from 'Src/types/message';

interface Props {
  message: MessageDefinition;
  user: AppUser;
}

export const ClubInviteMainInformation = (props: Props) => {
  const { user, message } = props;

  const firstName = propz.get(message, ['playerDetailsData', 'firstName']);
  const lastName = propz.get(message, ['playerDetailsData', 'lastName']);
  const tournament = propz.get(message, ['clubData', 'name']);

  const gender = SPORT_GENDER_SERVER_TO_CLIENT_MAPPING[propz.get(message, ['clubData', 'gender'])];

  const postcode = propz.get(message, ['clubData', 'venue', 'placeData', 'postcode']);
  const placeName = propz.get(message, ['clubData', 'venue', 'placeData', 'name']);

  const getAges = () => {
    const ages = propz.get(message, ['clubData', 'ages']);
    const isAgesExist = typeof ages !== 'undefined';
    let result = '';

    if (isAgesExist) {
      if (ages.length === 0) {
        result = 'All ages';
      } else {
        result = ages.map(ageItem => (ageItem === 0 ? 'Reception' : 'Y' + ageItem)).join(', ');
      }
    }

    return result;
  };

  const renderDate = () => {
    const phone = propz.get(user, ['profile', 'phone']);
    const region = getPhoneRegion(phone);
    const startDate = propz.get(message, ['clubData', 'startDate']);
    const startDateFormatted =
      region === 'US'
        ? Moment(startDate).format(DATE_FORMAT_FULL_MONTH_US)
        : Moment(startDate).format(DATE_FORMAT_FULL_MONTH);
    const endDate = propz.get(message, ['clubData', 'finishDate']);
    const endDateFormatted =
      region === 'US'
        ? Moment(endDate).format(DATE_FORMAT_FULL_MONTH_US)
        : Moment(endDate).format(DATE_FORMAT_FULL_MONTH);

    let result = null;
    if (startDateFormatted === endDateFormatted) {
      result = (
        <div>
          <span className="mClubInvitationFormBold">Date:</span>
          {` ${startDateFormatted}`}
        </div>
      );
    } else {
      result = (
        <div>
          <span className="mClubInvitationFormBold">Start:</span>
          {` ${startDateFormatted} `}
          <span className="mClubInvitationFormBold">End:</span>
          {` ${endDateFormatted}`}
        </div>
      );
    }
    return result;
  };

  const getTime = () => {
    const phone = propz.get(user, ['profile', 'phone']);
    const region = getPhoneRegion(phone);

    const startDate = propz.get(message, ['clubData', 'startDate']);
    const startDateFormatted =
      region === 'US'
        ? Moment(startDate).format(DATE_FORMAT_FULL_MONTH_US)
        : Moment(startDate).format(DATE_FORMAT_FULL_MONTH);
    const endDate = propz.get(message, ['clubData', 'finishDate']);
    const endDateFormatted =
      region === 'US'
        ? Moment(endDate).format(DATE_FORMAT_FULL_MONTH_US)
        : Moment(endDate).format(DATE_FORMAT_FULL_MONTH);

    const startTime = propz.get(message, ['clubData', 'time']);
    const duration = propz.get(message, ['clubData', 'duration']);

    const startDateTimeStamp = new Date(startTime).getTime();
    const endDateTimeStamp = startDateTimeStamp + duration * SECONDS_IN_MINUTE * MILLISECONDS_IN_SECOND;
    const endTime = new Date(endDateTimeStamp).toISOString();

    const startTimeFormatted = Moment(startTime).format(TIME);
    const endTimeFormatted = Moment(endTime).format(TIME);
    const eventTime = `${startTimeFormatted}-${endTimeFormatted}`;

    const weekDays = propz.get(message, ['clubData', 'days'], []);
    let weekDaysString = '';
    weekDays.forEach(day => {
      weekDaysString = weekDaysString + ` ${WEEK_DAYS_SERVER_TO_CLIENT_MAPPING[day]},`;
    });

    let time = null;

    if (startDateFormatted === endDateFormatted) {
      time = ` ${eventTime}`;
    } else {
      time = ` ${weekDaysString} ${eventTime}`;
    }

    return time;
  };

  const renderDescription = () => {
    const description = propz.get(message, ['clubData', 'description']);
    const isDescriptionExist = typeof description !== 'undefined' && description !== '';

    let result = isDescriptionExist ? (
      <div>
        <span className="font-weight-bold">Description:</span> {description}
      </div>
    ) : null;

    return result;
  };

  return (
    <div className="mt-3 mb-3">
      <h4>A new opportunity for {`${firstName} ${lastName}`} is available</h4>
      <div className="font-weight-bold">{tournament}</div>
      <div>{renderDescription()}</div>
      <div>{`${gender}, ${getAges()}`}</div>
      <div>{renderDate()}</div>
      <div>
        <span className="font-weight-bold">Time:</span>
        {getTime()}
      </div>
      <div>
        <span className="font-weight-bold">Venue: </span>
        {`${postcode}, ${placeName}`}
      </div>
    </div>
  );
};
