import * as React from 'react';
import * as BPromise from 'bluebird';
import { AppUser } from 'Src/views/App/App';
import { parse } from 'query-string';
import { getOrder, getSearchFilter, getSearchOrder, getServerFieldSectionWhere } from 'Src/helpers/table/table';
import { DATE_INTERVAL, DEFAULT_LIMIT, DEFAULT_SKIP, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { Loader } from 'Src/components/Loader/Loader';
import {
  ColumnDefinition,
  getFilters2,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { History, Location } from 'history';
import { Grid2 } from 'Src/components/Grid/Grid2';
import {
  getFixtureParticipatingAges,
  getParticipatingStudentHouses,
  getParticipatingStudentsBirthday,
  getParticipatingStudentsPupilsPremium,
  getParticipatingStudentsSpecialEducationNeeds,
  getParticipatingStudentsFreeSchoolMeals
} from 'Src/helpers/accessor/accessor';
import { SchoolForm } from 'Src/models/form';
import {
  getSelectOptionForAge,
  getSelectOptionForForms,
  getSelectOptionForHouses,
  getSelectOptionForBooleanQuestion,
  getSelectOptionForSport
} from 'Src/helpers/table/select';
import { ResizablePanel } from 'Src/components/ResizablePanel/ResizablePanel';
import { FIXTURE_PARTICIPATING_STUDENT_TABS } from 'Src/consts/school';
import { GenericSummary } from 'Src/components/GenericSummary/GenericSummary';
import { getSchoolSports } from 'Src/helpers/service/admin/user';
import {
  getAllFixtureParticipations,
  getFixtureParticipations,
  getFixtureParticipationsCount
} from 'Src/helpers/service/parent/fixtureParticipationTable';
import { FixtureParticipatingStudentSummary } from 'Src/components/FixtureParticipatingStudentSummary/FixtureParticipatingStudentSummary';
import { getSchoolEvent } from 'Src/helpers/service/admin/event';
import { getAllForms } from 'Src/helpers/service/admin/forms';
import { getAllHouses } from 'Src/helpers/service/admin/houses';
import { StudentParticipatingEvent } from 'Src/models/studentParticipatingEvent';
import { SchoolHouse } from '../../../../../models/house';
import { Sport } from '../../../../../models/sport';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Name',
    field: 'userFirstName',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['user', 'firstName']
  },
  {
    text: 'Surname',
    field: 'userLastName',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['user', 'lastName']
  },
  {
    text: 'Birthday',
    field: 'userBirthday',
    isSort: true,
    type: FILTER_TYPE.NONE,
    accessor: getParticipatingStudentsBirthday
  },
  {
    text: 'Form',
    field: 'userFormName',
    isSort: true,
    type: FILTER_TYPE.MULTISELECT,
    accessor: ['user', 'formName']
  },
  {
    text: 'House',
    field: 'userHouseName',
    isSort: true,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getParticipatingStudentHouses
  },
  {
    text: 'Age',
    field: 'participationStatisticAges',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getFixtureParticipatingAges
  },
  {
    text: 'PP',
    field: 'pupilsPremium',
    isSort: true,
    type: FILTER_TYPE.SELECT,
    accessor: getParticipatingStudentsPupilsPremium
  },
  {
    text: 'SEN',
    field: 'specialEducationNeeds',
    isSort: true,
    type: FILTER_TYPE.SELECT,
    accessor: getParticipatingStudentsSpecialEducationNeeds
  },
  {
    text: 'FSM',
    field: 'freeSchoolMeals',
    isSort: true,
    type: FILTER_TYPE.SELECT,
    accessor: getParticipatingStudentsFreeSchoolMeals
  },
  {
    text: 'Sport',
    field: 'sport',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: ['sport', 'sportName']
  },
  {
    text: '',
    field: 'eventStartTime',
    isSort: false,
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: ['']
  }
];

interface State {
  items: StudentParticipatingEvent[];
  currentPage: number;
  selectedItems: StudentParticipatingEvent[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;

  isMessageModalOpen: boolean;

  isTagAssignModalOpen: boolean;
  isTagDeleteModalOpen: boolean;
  selectedTagId: string;

  currentTabIndex: number;
  isEditable: boolean;

  forms: SchoolForm[];
  houses: SchoolHouse[];
  sports: Sport[];
  participationEvent: any;
  isSummaryLoading: boolean;
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export class FixtureParticipatingStudents extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',

      isMessageModalOpen: false,

      isTagAssignModalOpen: false,
      isTagDeleteModalOpen: false,
      selectedTagId: '',

      currentTabIndex: 0,
      isEditable: false,

      forms: [],
      houses: [],
      sports: [],
      participationEvent: undefined,
      isSummaryLoading: false
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setAdditionalItems().then(res => {
      this.setItems();
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  setAdditionalItems() {
    const { user } = this.props;
    const filter = {
      where: {
        isSchoolEventsAvailable: true
      },
      limit: DEFAULT_LIMIT,
      order: 'name ASC',
      skip: DEFAULT_SKIP
    };

    const formsPromise = getAllForms(user);
    const housesPromise = getAllHouses(user);
    const sportsPromise = getSchoolSports(user, filter);

    return BPromise.all([formsPromise, housesPromise, sportsPromise]).then(([forms, houses, sports]) => {
      this.setState({
        forms: forms,
        houses: houses,
        sports: sports
      });

      return true;
    });
  }

  getEventParticipation(eventId) {
    const { user } = this.props;

    this.setState({
      isSummaryLoading: true
    });

    return getSchoolEvent(user, eventId).then(participationEvent => {
      this.setState({
        participationEvent: participationEvent,
        isSummaryLoading: false
      });
    });
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const options = {
      forms: this.state.forms
    };

    const where = getServerFieldSectionWhere(filters, options);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const getItemsPromise = getFixtureParticipations(user, serverQueryFilter);
    const getItemsCountPromise = getFixtureParticipationsCount(user, where);

    const promises = [getItemsCountPromise, getItemsPromise];

    return BPromise.all(promises).then(([countObj, items]) => {
      /** We need the unique ID for the grid keys */
      const itemsWithAdditionUniqueId = items.map(element => {
        const uniqueId = element.user.id + element.eventId;
        return {
          ...element,
          id: uniqueId
        };
      });
      this.setState({
        currentPage: page,
        sortDirection: sortDirection,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: itemsWithAdditionUniqueId,
        total: countObj.count,
        basePath: history.location.pathname,
        isLoading: false
      });

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated
    });
    const eventId = selectedItemsUpdated[0].eventId;
    if (selectedItemsUpdated.length === 1 && typeof eventId !== 'undefined') {
      this.getEventParticipation(eventId);
    }
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: nextSelectedItems,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters, forms } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const options = {
      forms: forms
    };

    const where = getServerFieldSectionWhere(filters, options);

    getAllFixtureParticipations(user, where).then(items => {
      /** We need the unique ID for the grid keys */
      const itemsWithAdditionUniqueId = items.map(element => {
        const uniqueId = element.user.id + element.eventId;
        return {
          ...element,
          id: uniqueId
        };
      });
      const itemsFiltered = itemsWithAdditionUniqueId.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const nextSelectedItems = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: nextSelectedItems,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (filterField === 'forms') {
      nextFilters.ages = [];
    }

    if (filterField === 'ages') {
      nextFilters.forms = [];
    }

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      isSelectAllChecked: false,
      selectedItems: []
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: ''
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  renderStudentsTable(): React.ReactNode {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isSelectAllChecked,
      currentPage,
      total,
      forms,
      houses,
      sports
    } = this.state;

    const actionItems = [];

    const user = this.props.user;
    const activeSchool = user.activeSchool;
    const ageGroupsNaming = activeSchool.ageGroupsNaming;

    const filterOptions = {
      participationStatisticAges: getSelectOptionForAge(forms, ageGroupsNaming),
      userFormName: getSelectOptionForForms(forms),
      userHouseName: getSelectOptionForHouses(houses),
      pupilsPremium: getSelectOptionForBooleanQuestion(),
      specialEducationNeeds: getSelectOptionForBooleanQuestion(),
      freeSchoolMeals: getSelectOptionForBooleanQuestion(),
      sport: getSelectOptionForSport(sports)
    };

    return (
      <Grid2
        dataItems={items}
        filters={filters}
        currentPage={currentPage}
        total={total}
        isSelectAllChecked={isSelectAllChecked}
        isDataFiltered={isDataFiltered}
        sortDirection={sortDirection}
        sortColumnsName={sortColumnsName}
        isShowFilter={isShowFilter}
        dataItemsSelected={selectedItems}
        columns={COLUMNS}
        actionItems={actionItems}
        options={filterOptions}
        onItemClick={this.onItemClick}
        onSortClick={this.onTableSortClick}
        onApplyFilterClick={this.onApplyFilterClick}
        onClearFilterClick={this.onClearFilterClick}
        onTableFilterChange={this.onTableFilterChange}
        onTableFilterClick={this.onTableFilterClick}
        setCurrentPageParams={this.setCurrentPageParams}
        onSelectAllClick={this.onSelectAllClick}
        onSelectAllOnPageClick={this.onSelectAllOnPageClick}
        onUnselectAllClick={this.onUnselectAllClick}
      />
    );
  }

  renderSummary(): React.ReactNode {
    const isOneStudentSelected = this.isOneStudentSelected();
    const { participationEvent, currentTabIndex, isSummaryLoading } = this.state;
    const isParticipationEventExist = typeof participationEvent !== 'undefined';

    if (isSummaryLoading) {
      return <Loader />;
    }

    if (!isOneStudentSelected || !isParticipationEventExist) {
      return <div className="font-weight-bold">Select the participating student above to view the event</div>;
    }

    const participatingStudentSummaryTabs = this.getParticipatingStudentSummaryTabs();

    return (
      <GenericSummary
        onTabClick={this.onTabClick}
        tabs={FIXTURE_PARTICIPATING_STUDENT_TABS}
        currentTabIndex={currentTabIndex}
      >
        {participatingStudentSummaryTabs}
      </GenericSummary>
    );
  }

  onTabClick = (event, index: number) => {
    event.preventDefault();

    this.setState({
      currentTabIndex: index
    });
  };

  getParticipatingStudentSummaryTabs(): React.ReactNode {
    const { participationEvent } = this.state;
    const { user } = this.props;

    if (typeof participationEvent !== 'undefined') {
      return <FixtureParticipatingStudentSummary participationEvent={participationEvent} user={user} />;
    }
  }

  isOneStudentSelected() {
    const selectedStudents = this.state.selectedItems;
    return selectedStudents.length === 1;
  }

  render() {
    const { isLoading, isMessageModalOpen, isEditable } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes = isMessageModalOpen || isEditable ? 'mt-3 modal-open' : 'mt-3';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        <div className="row">
          <div className="col-md-12">
            <ResizablePanel>
              {this.renderStudentsTable()}
              {this.renderSummary()}
            </ResizablePanel>
          </div>
        </div>
      </div>
    );
  }
}
