import * as React from 'react';
import * as propz from 'propz';
import * as moment from 'moment';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import DatePicker from 'react-datepicker';
import { AppUser } from '../../../../../App/App';
import { SchoolEvent } from 'Src/models/event';
import { Point } from 'Src/models/venue';
// import { SchoolForm } from 'Src/models/form';
import { EVENT_TYPES } from 'Src/consts/event';
import { createVenue } from 'Src/helpers/service/admin/venues';
import { convertPlaceToPostcodeWithPlaceName, getDefaultPoint, getPostcodes, isPlace } from 'Src/helpers/venue/venue';
// import { getAgesForMultiSelect, getAgesFromMultiSelect } from 'Src/helpers/multiselect/multiselect';
import { getNameByPlaceOrPostcode } from 'Src/helpers/autocomplete/autocomplete';
import { Button } from 'Src/components/Button/Button';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { Map } from 'Src/components/Map/Map';
import { Autocomplete } from 'Src/components/Autocomplete/Autocomplete';
import { LabelWithQuestionIcon } from 'Src/components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import { VenueForm } from 'Src/views/GenericView/AdminView/School/Venues/VenueForm/VenueForm';
import './SchoolUnionEventEditSettingsForm.scss';

interface Props {
  user: AppUser;
  schoolEvent: SchoolEvent;
  // forms: SchoolForm[];
  onCancel: () => void;
  onSubmit: (data: any) => void;
}

interface State {
  point: Point;
  venue: any;
  isCreateVenueFormOpen: boolean;
}

export class SchoolUnionEventEditSettingsForm extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    const { schoolEvent, user } = this.props;
    const venue = propz.get(schoolEvent, ['venue']);
    const placeConverted = typeof schoolEvent !== 'undefined' ? convertPlaceToPostcodeWithPlaceName(venue) : undefined;
    const point = propz.get(placeConverted, ['point'], getDefaultPoint(user));

    this.state = {
      point,
      venue: venue,
      isCreateVenueFormOpen: false
    };
  }

  // onAgesChange = (index, setFieldValue, values) => {
  //   const ageItems = values.ages;
  //   const ageItemsUpdated = ageItems.map((ageItem, ageIndex) => {
  //     return ageIndex === index
  //       ? {
  //           ...ageItem,
  //           isChecked: !ageItem.isChecked
  //         }
  //       : ageItem;
  //   });
  //   setFieldValue('ages', ageItemsUpdated);
  // };

  onSubmitVenueForm = (data): void => {
    const { user } = this.props;

    createVenue(user, data).then(venue => {
      const placeConverted = convertPlaceToPostcodeWithPlaceName(venue);

      this.setState({
        venue: placeConverted,
        point: placeConverted.point,
        isCreateVenueFormOpen: false
      });
    });
  };

  setNewPoint = pointNew => {
    this.setState({
      point: pointNew
    });
  };

  closeCreateVenueForm = (): void => {
    this.setState({
      isCreateVenueFormOpen: false
    });
  };

  onCreateVenueClick = (): void => {
    this.setState({
      isCreateVenueFormOpen: true
    });
  };

  renderCreateVenueForm = () => {
    const { user } = this.props;
    return (
      <SimpleModal isOpen={this.state.isCreateVenueFormOpen} title={'New venue'}>
        <VenueForm user={user} onCancel={this.closeCreateVenueForm} onSubmit={this.onSubmitVenueForm} />
      </SimpleModal>
    );
  };

  render() {
    const { schoolEvent, onSubmit, onCancel, user } = this.props;
    const { point, venue } = this.state;
    const startTimeDateObj = moment(schoolEvent.startTime).toDate();
    const endTimeDateObj = moment(schoolEvent.endTime).toDate();

    const validationSchema = Yup.object().shape({
      startTime: Yup.date().max(Yup.ref('endTime'), 'Must be less then end'),
      endTime: Yup.date().min(Yup.ref('startTime'), 'Must be more then start'),
      venue: Yup.string().test('venue', 'Required', function(value) {
        const isExistVenue = typeof venue !== 'undefined';
        return isExistVenue;
      })
    });

    const eventSettingForm = {
      startTime: startTimeDateObj,
      endTime: endTimeDateObj,
      // ages: getAgesForMultiSelect(forms, schoolEvent.ages),
      venue: venue
    };

    return (
      <div className="container-fluid">
        {this.renderCreateVenueForm()}
        <div className="row">
          <div className="col-md-12">
            <Formik
              initialValues={eventSettingForm}
              validationSchema={validationSchema}
              onSubmit={values => {
                const valuesUpdated = { ...values };

                if (typeof venue !== 'undefined') {
                  valuesUpdated.venue = venue;
                }

                if (typeof point !== 'undefined') {
                  propz.set(valuesUpdated, ['venue', 'point'], point);
                }

                const dataToSubmit = {
                  ...valuesUpdated
                  // ages: getAgesFromMultiSelect(forms, ages),
                };

                onSubmit(dataToSubmit);
              }}
              render={({ values, setFieldValue, touched, errors }) => (
                <Form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group mt-3">
                        <LabelWithQuestionIcon labelText="Start time" hintText="" />
                        <Field
                          name="startTime"
                          render={({ field }) => {
                            return (
                              <div className="mb-3">
                                <DatePicker
                                  selected={field.value}
                                  onChange={startDate => {
                                    setFieldValue('startTime', startDate);
                                    const endTime = new Date(startDate);
                                    endTime.setHours(startDate.getHours() + 1, startDate.getMinutes());
                                    setFieldValue('endTime', endTime);
                                  }}
                                  className="form-control"
                                  dateFormat={'dd-MM-yyyy HH:mm'}
                                  showTimeSelect
                                  timeFormat="HH:mm"
                                  timeIntervals={5}
                                  timeCaption="time"
                                />
                              </div>
                            );
                          }}
                        />
                        {touched.startTime && errors.startTime ? (
                          <div className="alert alert-danger">{errors.startTime}</div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group mt-3">
                        <LabelWithQuestionIcon labelText="Finish/Collection time" hintText="" />
                        <Field
                          name="endTime"
                          render={({ field }) => {
                            return (
                              <div className="mb-3">
                                <DatePicker
                                  selected={field.value}
                                  onChange={endDate => {
                                    setFieldValue('endTime', endDate);
                                  }}
                                  className="form-control"
                                  dateFormat={'dd-MM-yyyy HH:mm'}
                                  showTimeSelect
                                  timeFormat="HH:mm"
                                  timeIntervals={5}
                                  timeCaption="time"
                                />
                              </div>
                            );
                          }}
                        />
                        {touched.endTime && errors.endTime ? (
                          <div className="alert alert-danger">{errors.endTime}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  {/* <div className="form-group">
                    <LabelWithQuestionIcon labelText="Ages" hintText="" />
                    <MultiSelectCheckboxes
                      items={values.ages}
                      cols={3}
                      onChange={index => this.onAgesChange(index, setFieldValue, values)}
                      customClass="mSchoolEventMultiSelect pt-0"
                    />
                  </div> */}

                  <div className="form-group">
                    <LabelWithQuestionIcon labelText="Venue / Location" hintText="" />
                    <Field
                      name="venue"
                      render={({ field }) => {
                        return (
                          <Autocomplete
                            searchFunction={text =>
                              getPostcodes(
                                text,
                                user,
                                schoolEvent.eventType === EVENT_TYPES.EXTERNAL_SCHOOLS,
                                schoolEvent.invitedSchools
                              )
                            }
                            getElementTitle={getNameByPlaceOrPostcode}
                            customClass="mFullWidth mb-3"
                            placeholder="Start typing the venue name"
                            defaultItem={venue}
                            onSelect={value => {
                              let postcode;

                              if (isPlace(value)) {
                                postcode = convertPlaceToPostcodeWithPlaceName(value);
                              } else {
                                postcode = value;
                              }

                              const { point } = postcode;

                              setFieldValue('venue', postcode);
                              this.setState({
                                venue: postcode,
                                point
                              });

                              this.setNewPoint(point);
                            }}
                          />
                        );
                      }}
                    />
                    {touched.venue && errors.venue && <div className="alert alert-danger">{errors.venue}</div>}
                  </div>
                  <div className="form-group">
                    <button type="button" className={'btn btn-success'} onClick={this.onCreateVenueClick}>
                      Create venue
                    </button>
                  </div>
                  <div className="form-group">
                    {propz.get(values, ['venue', 'venueType']) !== 'TBD' && typeof point !== 'undefined' && (
                      <Map
                        point={point}
                        getNewPoint={this.setNewPoint}
                        customStylingClass="eSchoolEventVenueMap"
                        isMarkerDraggable={true}
                      />
                    )}
                  </div>

                  <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                  <button type="submit" className="mt-3 mb-3 btn btn-primary">
                    Save
                  </button>
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}
