import * as React from 'react';
import { FunctionComponent } from 'react';
import * as propz from 'propz';
import { PUBLIC_SITE_STATUS_OPTIONS } from '../../consts/tournament';
import { Tournament } from '../../models/tournament';
import { AppUser } from 'Src/views/App/App';
import {
  getSUPublicSiteTournamentPage,
  getTournamentPublicSiteLink,
  getTournamentTVPublicSiteLink
} from '../../helpers/link/link';

interface Props {
  tournament: Tournament;
  user: AppUser;
}

const ROWS = [
  { title: "Publish tournament on school union's website", field: 'isPublishOnTheWebsite' },
  { title: 'Highlight tournament', field: 'isPromoteTournament' },
  { title: "Tournament's public website access", field: 'publicSiteStatus' },
  { title: "Tournament's public website access password", field: 'publicSitePassword' },
  { title: 'Allowed for following on the mobile app', field: 'allowedForFollowing' },
  { title: "Tournament's public website domain name", field: 'domain' },
  { title: 'Highlight schools list on top', field: 'isSchoolsListOnTop' },
  { title: "Tournament's public website external link", field: 'link' },
  { title: "Photos available on tournament's website", field: 'isPhotosAvailable' },
  { title: "Photos promotion on tournament's website", field: 'isPhotosAvailable' },
  { title: "Text of disclaimer on tournament's website", field: 'disclaimer' },
  { title: 'Csv result availability', field: 'isCsvResultAvailable' },
  { title: 'Public Bigscreen Site Access', field: 'publicBigscreenSiteStatus' },
  { title: "Tournament's public bigscreen website access", field: 'publicBigscreenSitePassword' },
  { title: 'Scrolling delay (in seconds)', field: 'publicBigscreenSiteAutoScrollingDelay' },
  { title: 'Tournament public website link', field: 'publicWebsiteLink' },
  { title: 'Tournament TV link', field: 'publicWebsiteTvLink' },
  { title: 'Tournament details/booking page', field: 'detailsBookingPage' }
];

export const TournamentPublicSiteSettingsSummary: FunctionComponent<Props> = props => {
  const { tournament, user } = props;

  const tournamentPublicSiteLink = getTournamentPublicSiteLink(tournament),
    tournamentTVPublicSiteLink = getTournamentTVPublicSiteLink(tournament),
    suPublicSiteTournamentPage = getSUPublicSiteTournamentPage(tournament, user),
    activeSchoolDomain = user.activeSchool.domain;

  const rows = ROWS.map((row, index) => {
    let contentCell: object | string;
    const field = row.field;
    let isTournamentTVlink;
    let isExternalSite;

    switch (field) {
      case 'isPublishOnTheWebsite':
        contentCell = tournament.isPublishOnTheWebsite ? 'yes' : 'no';
        break;
      case 'isPromoteTournament':
        contentCell = tournament.isPromoteTournament ? 'yes' : 'no';
        break;
      case 'publicSiteStatus':
        contentCell = PUBLIC_SITE_STATUS_OPTIONS[tournament.publicSite.status];
        break;
      case 'publicSitePassword':
        contentCell = propz.get(tournament, ['publicSite', 'password'], '');
        break;
      case 'allowedForFollowing':
        contentCell = tournament.allowedForFollowing ? 'yes' : 'no';
        break;
      case 'domain':
        contentCell = propz.get(tournament, ['domain'], '');
        break;
      case 'isSchoolsListOnTop':
        contentCell = tournament.isSchoolsListOnTop ? 'yes' : 'no';
        break;
      case 'link':
        contentCell = propz.get(tournament, ['link'], '');
        break;
      case 'isPhotosAvailable':
        contentCell = tournament.isPhotosAvailable ? 'yes' : 'no';
        break;
      case 'isPhotosPromotion':
        contentCell = tournament.isPhotosPromotion ? 'yes' : 'no';
        break;
      case 'disclaimer':
        contentCell = propz.get(tournament, ['disclaimer'], '');
        break;
      case 'isCsvResultAvailable':
        contentCell = tournament.isCsvResultAvailable ? 'yes' : 'no';
        break;
      case 'publicBigscreenSiteStatus':
        contentCell = PUBLIC_SITE_STATUS_OPTIONS[tournament.publicBigscreenSite.status];
        break;
      case 'publicBigscreenSitePassword':
        contentCell = propz.get(tournament, ['publicBigscreenSite', 'password'], '');
        break;
      case 'publicBigscreenSiteAutoScrollingDelay':
        contentCell = propz.get(tournament, ['publicBigscreenSite', 'autoScrollingDelay'], '');
        break;
      case 'publicWebsiteLink':
        contentCell =
          typeof tournament.domain !== 'undefined' && tournament.domain !== '' ? (
            <a href={tournamentPublicSiteLink} target="_blank">
              {tournamentPublicSiteLink}
            </a>
          ) : (
            'no'
          );
        break;
      case 'publicWebsiteTvLink':
        isTournamentTVlink = true;
        isExternalSite = true;
        contentCell =
          typeof tournament.domain !== 'undefined' && tournament.domain !== '' ? (
            <a href={tournamentTVPublicSiteLink} target="_blank">
              {tournamentTVPublicSiteLink}
            </a>
          ) : (
            'no'
          );
        break;
      case 'detailsBookingPage':
        isExternalSite = false;
        contentCell =
          typeof activeSchoolDomain !== 'undefined' && activeSchoolDomain !== '' ? (
            <a href={suPublicSiteTournamentPage} target="_blank">
              {suPublicSiteTournamentPage}
            </a>
          ) : (
            'no'
          );
        break;
      default:
        contentCell = propz.get(tournament, [row.field], '');
    }

    return (
      <tr key={`tournament_PublicSiteSettings_summary_${index}`}>
        <td>{row.title}</td>
        <td>{contentCell}</td>
      </tr>
    );
  });

  return (
    <table className={'table table-sm mPre'}>
      <tbody>{rows}</tbody>
    </table>
  );
};

TournamentPublicSiteSettingsSummary.displayName = 'TournamentPublicSiteSettingsSummary';
