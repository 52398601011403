import * as React from 'react';
import { Switch } from 'Src/components/Switch/Switch';

interface Props {
  isError: boolean;
  email: string;
  password: string;
  isAgree: boolean;
  onEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onAgreementChange: () => void;
  onSubmit: (event: React.SyntheticEvent) => void;
  onCloseClick: () => void;
}

export function TerminatingForm(props: Props) {
  const {
    onCloseClick,
    onSubmit,
    onAgreementChange,
    onEmailChange,
    onPasswordChange,
    email,
    password,
    isAgree,
    isError
  } = props;
  const submitDisabled = email === '' || password === '' || !isAgree;
  return (
    <div>
      {isError && <div className="alert alert-danger">Incorrect email or password.</div>}
      <div>To complete the termination of this subscription please fill in the following fields.</div>

      <label className="mt-3">Your Email</label>
      <input type="email" className="form-control" value={email} onChange={onEmailChange} id="login_email" />

      <label className="mt-3">Your Password</label>
      <input
        type="password"
        className="form-control"
        id="login_password"
        value={password}
        onChange={onPasswordChange}
      />
      <Switch
        name={'terminating_form_switch'}
        value={isAgree}
        text={'Yes I want to irrevocably terminate the subscription for Squad In Touch (will be cancelled immediately)'}
        onChange={onAgreementChange}
        customClass="mb-3 mt-3"
      />
      <button className="btn btn-secondary mb-3 mt-3 mr-3" onClick={onCloseClick}>
        Cancel termination process
      </button>
      <button type="submit" className="btn btn-primary mb-3 mt-3 mr-3" onClick={onSubmit} disabled={submitDisabled}>
        Terminate Subscription
      </button>
    </div>
  );
}
