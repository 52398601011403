import * as React from 'react';
import { Stage, Layer, Group, Circle, Image } from 'react-konva';
import { Loader } from 'Src/components/Loader/Loader';
import { dataURLtoBlob } from 'Src/helpers/gallery/gallery';
import { getImageDirectUrl } from 'Src/helpers/service/admin/schoolGallery';
import { getRandomString } from 'Src/helpers/common/common';

interface Props {
  getPhotoUrl: () => any;
  onSaveClick: (file: any) => void;
  onCancelClick: () => void;
  imgContainerWidth: number;
  imgContainerHeight: number;
}

interface State {
  isLoading: boolean;
  isSynchronized: boolean;
  imageToLoad: HTMLImageElement;
  imageFormat: string;
  activeIconIndex: number;
  icons: Icon[];
  imgWidth: number;
  imgHeight: number;
}

interface Icon {
  img: HTMLImageElement;
  x: number;
  y: number;
  width: number;
  height: number;
  active: boolean;
  anchors: {
    name: string;
    x: number;
    y: number;
  }[];
}

const ICON_WIDTH = 50;

export class EmojiIcon extends React.Component<Props, State> {
  stageRef: any;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isSynchronized: false,
      imageToLoad: null,
      imageFormat: '',
      icons: [],
      activeIconIndex: undefined,
      imgWidth: 0,
      imgHeight: 0
    };
  }

  componentDidUpdate(nextProps, nextState) {
    const { imgContainerWidth, imgContainerHeight } = this.props;
    const { imageToLoad } = this.state;

    if (nextProps.imgContainerWidth !== imgContainerWidth || nextProps.imgContainerHeight !== imgContainerHeight) {
      const { imgWidth, imgHeight } = this.getImgParameters(
        imageToLoad,
        nextProps.imgContainerWidth,
        nextProps.imgContainerHeight
      );

      this.setState({
        imgWidth,
        imgHeight
      });
    }
  }

  componentDidMount() {
    const { getPhotoUrl, imgContainerWidth, imgContainerHeight } = this.props;

    getPhotoUrl()
      .then(imgServerUrl => getImageDirectUrl(imgServerUrl))
      .then(({ data }) => {
        const picUrl = `${data.url}?t=${Date.now() + getRandomString()}`;
        const image = document.createElement('img');

        image.crossOrigin = 'Anonymous';
        image.src = picUrl;

        const imageNameParts = data.url.split('.');
        const imageFormat = imageNameParts[imageNameParts.length - 1];

        image.onload = () => {
          const { imgWidth, imgHeight } = this.getImgParameters(image, imgContainerWidth, imgContainerHeight);

          this.setState({
            imageToLoad: image,
            isSynchronized: true,
            imgWidth,
            imgHeight,
            imageFormat
          });
        };
      });
  }

  getImgParameters(image, widthImgContainer, heightImgContainer) {
    const containerRatio = widthImgContainer / heightImgContainer;
    const ratioImg = image.width / image.height;

    let imgWidth;
    let imgHeight;

    if (containerRatio < ratioImg) {
      imgWidth = widthImgContainer;
      imgHeight = Math.floor((1 / ratioImg) * widthImgContainer);
    } else {
      imgHeight = heightImgContainer;
      imgWidth = Math.floor(ratioImg * heightImgContainer);
    }

    return { imgWidth, imgHeight };
  }

  addEmojiIcon(): void {
    const { icons, imgWidth, imgHeight } = this.state;

    this.setState({
      isLoading: true
    });

    const image = document.createElement('img');
    image.crossOrigin = 'Anonymous';
    image.src = '/dist/images/smile.png';

    image.onload = () => {
      const iconX = imgWidth / 2 - ICON_WIDTH / 2;
      const iconY = imgHeight / 2 - ICON_WIDTH / 2;

      const anchors = [
        { name: 'topLeft', x: iconX, y: iconY },
        { name: 'topRight', x: ICON_WIDTH + iconX, y: iconY },
        { name: 'bottomRight', x: ICON_WIDTH + iconX, y: ICON_WIDTH + iconY },
        { name: 'bottomLeft', x: iconX, y: ICON_WIDTH + iconY }
      ];

      const newIcon = {
        img: image,
        x: iconX,
        y: iconY,
        width: ICON_WIDTH,
        height: ICON_WIDTH,
        active: false,
        anchors
      };

      this.setState(prevState => {
        return {
          isSynchronized: true,
          icons: [...prevState.icons, newIcon],
          activeIconIndex: [...prevState.icons, newIcon].length - 1,
          isLoading: false
        };
      });
    };
  }

  deleteEmojiIcon(): void {
    const { icons, activeIconIndex } = this.state;

    icons.splice(activeIconIndex, 1);

    this.setState({
      icons,
      activeIconIndex: undefined
    });
  }

  onSaveClick(): void {
    const { onSaveClick } = this.props;
    const { icons, imageToLoad, imgWidth, imageFormat } = this.state;

    const scale = imageToLoad.width / imgWidth;

    icons.forEach(icon => {
      icon.width *= scale;
      icon.height *= scale;
      icon.x *= scale;
      icon.y *= scale;
    });

    this.setState(
      {
        isLoading: true,
        activeIconIndex: undefined,
        imgWidth: imageToLoad.width,
        imgHeight: imageToLoad.height,
        icons
      },
      () => {
        const file = dataURLtoBlob(
          this.stageRef.getStage().toDataURL({ mimeType: `image/${imageFormat}`, quality: 1 })
        );

        onSaveClick(file);
      }
    );
  }

  onDragEndIcon(e, index: number): void {
    const icons = this.state.icons;
    const currentIcon = this.state.icons[index];
    currentIcon.x = e.target.x();
    currentIcon.y = e.target.y();
    currentIcon.anchors[0].x = e.target.x();
    currentIcon.anchors[0].y = e.target.y();
    currentIcon.anchors[1].x = e.target.x() + currentIcon.width;
    currentIcon.anchors[1].y = e.target.y();
    currentIcon.anchors[2].x = e.target.x() + currentIcon.width;
    currentIcon.anchors[2].y = e.target.y() + currentIcon.height;
    currentIcon.anchors[3].x = e.target.x();
    currentIcon.anchors[3].y = e.target.y() + currentIcon.height;
    icons[index] = currentIcon;

    this.setState({
      icons
    });
  }

  renderAnchors(icon: Icon, index: number): React.ReactNode {
    return icon.anchors.map(anchor => {
      return (
        <Circle
          x={anchor.x}
          y={anchor.y}
          stroke="#666"
          fill="#ddd"
          strokeWidth={2}
          radius={5}
          key={`${anchor.name}${index}`}
          name={`${anchor.name}${index}`}
          draggable={true}
          onDragMove={e => this.updateAnchor(e, anchor.name, index)}
        />
      );
    });
  }

  updateAnchor(e, name: string, index: number): void {
    const { icons, imgWidth } = this.state;

    const anchorX = e.target.x() < 0 ? 0 : e.target.x() > imgWidth ? imgWidth : e.target.x();

    const currentIcon = icons[index];

    let differenceX;

    switch (name) {
      case 'topLeft':
        differenceX = anchorX - currentIcon.anchors[0].x;

        if (this.checkYCoordinate(currentIcon.anchors[0].y + differenceX)) {
          currentIcon.anchors[0].x = anchorX;
          currentIcon.anchors[0].y += differenceX;
          currentIcon.anchors[1].y += differenceX;
          currentIcon.anchors[3].x = anchorX;
        }

        break;

      case 'topRight':
        differenceX = anchorX - currentIcon.anchors[1].x;

        if (this.checkYCoordinate(currentIcon.anchors[1].y - differenceX)) {
          currentIcon.anchors[1].x = anchorX;
          currentIcon.anchors[1].y -= differenceX;
          currentIcon.anchors[0].y -= differenceX;
          currentIcon.anchors[2].x = anchorX;
        }

        break;

      case 'bottomRight':
        differenceX = anchorX - currentIcon.anchors[2].x;

        if (this.checkYCoordinate(currentIcon.anchors[2].y + differenceX)) {
          currentIcon.anchors[2].x = anchorX;
          currentIcon.anchors[2].y += differenceX;
          currentIcon.anchors[3].y += differenceX;
          currentIcon.anchors[1].x = anchorX;
        }

        break;

      case 'bottomLeft':
        differenceX = anchorX - currentIcon.anchors[3].x;

        if (this.checkYCoordinate(currentIcon.anchors[3].y - differenceX)) {
          currentIcon.anchors[3].x = anchorX;
          currentIcon.anchors[3].y -= differenceX;
          currentIcon.anchors[2].y -= differenceX;
          currentIcon.anchors[0].x = anchorX;
        }
        break;
    }

    currentIcon.width = currentIcon.anchors[1].x - currentIcon.anchors[0].x;
    currentIcon.height = currentIcon.anchors[3].y - currentIcon.anchors[0].y;

    if (currentIcon.width && currentIcon.height) {
      currentIcon.x = currentIcon.anchors[0].x;
      currentIcon.y = currentIcon.anchors[0].y;

      icons[index] = currentIcon;

      this.setState({
        icons
      });
    }
  }

  checkYCoordinate(y: number): boolean {
    const { imgHeight } = this.state;

    return y < 0 ? false : y <= imgHeight;
  }

  setActive(index: number): void {
    const { activeIconIndex } = this.state;

    const activeIndex = index === activeIconIndex ? undefined : index;

    this.setState({
      activeIconIndex: activeIndex
    });
  }

  getDragСoordinates(pos: { x: number; y: number }, icon: Icon, index) {
    const { activeIconIndex, imgWidth, imgHeight } = this.state;
    //You can not drag the icon outside the image
    if (activeIconIndex === index) {
      const width = imgWidth - icon.width;
      const height = imgHeight - icon.height;

      const newY = pos.y < 0 ? 0 : pos.y > height ? height : pos.y;

      const newX = pos.x < 0 ? 0 : pos.x > width ? width : pos.x;

      return {
        x: newX,
        y: newY
      };
    } else {
      return {
        x: icon.x,
        y: icon.y
      };
    }
  }

  onClickImage(e): void {
    if (e.target.attrs.id === 'coverImg') {
      this.setState({
        activeIconIndex: undefined
      });
    }
  }

  render() {
    const { onCancelClick, imgContainerHeight, imgContainerWidth } = this.props;
    const { isSynchronized, imageToLoad, imgWidth, imgHeight, icons, activeIconIndex, isLoading } = this.state;

    if (!isSynchronized) {
      return <Loader />;
    }

    return (
      <div className="eFullScreenPhotoContainer">
        <div className="eFullScreenPhoto" style={{ height: imgContainerHeight }}>
          {isLoading && (
            <div className="mFullScreenPhotoLoading">
              <Loader />
            </div>
          )}

          <Stage
            width={imgWidth}
            height={imgHeight}
            ref={node => {
              this.stageRef = node;
            }}
          >
            <Layer>
              <Image
                id="coverImg"
                onClick={e => this.onClickImage(e)}
                image={imageToLoad}
                width={imgWidth}
                height={imgHeight}
              />
              {icons.map((icon, index) => {
                return (
                  <Group key={`emoji_icon_${index}`}>
                    <Image
                      key={index}
                      image={icon.img}
                      width={icon.width}
                      height={icon.height}
                      x={icon.x}
                      y={icon.y}
                      onClick={() => this.setActive(index)}
                      draggable={true}
                      dragBoundFunc={pos => this.getDragСoordinates(pos, icon, index)}
                      onDragMove={e => this.onDragEndIcon(e, index)}
                    />
                    {activeIconIndex === index ? this.renderAnchors(icon, index) : null}
                  </Group>
                );
              })}
            </Layer>
          </Stage>
        </div>

        <div className="eFullScreenPhotoInfo">
          <div className="d-flex flex-column justify-content-between h-100">
            <div>
              <div>
                <p>Click on 'Add icon' to add an icon. Select the added icon and drag it to the desired location.</p>
                <p>You can add an unlimited number of icons.</p>
                <p>In order to delete the icon, select the desired one and click 'Delete icon'.</p>
              </div>

              <div className="d-flex justify-content-between">
                <button className="btn btn-primary mr-2" onClick={() => this.addEmojiIcon()}>
                  Add icon
                </button>

                <button
                  className="btn btn-danger"
                  onClick={() => this.deleteEmojiIcon()}
                  disabled={activeIconIndex === -1}
                >
                  Delete icon
                </button>
              </div>
            </div>

            <div className="d-flex justify-content-between">
              <button className="btn btn-primary mr-2" onClick={() => this.onSaveClick()}>
                Save
              </button>

              <button className="btn btn-secondary" onClick={() => onCancelClick()}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
