import * as React from 'react';

import {
  SchoolEvent,
  SchoolEventAttendance,
  SchoolEventIndividualData,
  SchoolEventTeamDataPlayer
} from 'Src/models/event';
import { getFullName } from 'Src/helpers/autocomplete/autocomplete';

interface Props {
  schoolEvent: SchoolEvent;
  allTeamPlayers: (SchoolEventIndividualData | SchoolEventTeamDataPlayer)[];
  attendancePlayers: SchoolEventAttendance[];
}

export function ChildrenEventAttendanceSummaryTab(props: Props) {
  const { allTeamPlayers, attendancePlayers } = props;

  const renderAttendanceViewMode = (player: SchoolEventIndividualData | SchoolEventTeamDataPlayer) => {
    const playerAttendance = attendancePlayers.find(attendance => attendance.userId === player.userId);
    switch (true) {
      case typeof playerAttendance === 'undefined':
        return '';
      case playerAttendance.isAttendance:
        return 'Present';
      case !playerAttendance.isAttendance:
        return 'Absent';
    }
  };

  const rows = allTeamPlayers.map((player, index) => {
    const fullName = getFullName(player);
    const playerAttendance = attendancePlayers.find(attendance => attendance.userId === player.userId);
    let attendanceStyle;

    if (typeof playerAttendance === 'undefined') {
      attendanceStyle = '';
    } else {
      attendanceStyle = playerAttendance.isAttendance ? '' : 'text-danger';
    }

    return (
      <tr key={`attendance_summary_${index}`}>
        <td>{index + 1}</td>
        <td>
          <div className="font-weight-bold">{fullName}</div>
        </td>
        <td>
          <div className={attendanceStyle}>{renderAttendanceViewMode(player)}</div>
        </td>
        <td />
      </tr>
    );
  });

  const emptyRow = (
    <tr key={'players_empty_row'}>
      <td>No participants</td>
    </tr>
  );

  return (
    <table className={'table table-sm mPre'}>
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Attendance</th>
          <th />
        </tr>
      </thead>
      <tbody>{rows.length > 0 ? rows : emptyRow}</tbody>
    </table>
  );
}
