import * as React from 'react';
import { AppUser } from 'Src/views/App/App';
import { History, Location } from 'history';
import './MessageSettingsForm.css';
import { FunctionComponent } from 'react';

interface Props {
  user: AppUser;
  history: History;
  location: Location;
  availableSchools: any[];
  tournamentId: string;
  onItemCheck: (nextItems) => void;
  onUpdateItemsEmptyFilterText: (text) => void;
  onUpdateItemsFilterText: (itemsFiltered, text) => void;
  onSelectAllClick: () => void;
  onSelectTournamentParticipantClick: () => void;
  onSelectTournamentWaitingListClick: () => void;
  filterText: string;
  isSelectAll: boolean;
  isSelectTournamentParticipant: boolean;
  isSelectTournamentWaitingList: boolean;
}

export const TournamentCustomNotifications: FunctionComponent<Props> = props => {
  const handleChangeFilterText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    let itemsFiltered = props.availableSchools;
    if (text.length >= 1) {
      itemsFiltered = itemsFiltered.filter(item => {
        return (
          typeof item.schoolName !== 'undefined' && item.schoolName.toLowerCase().indexOf(text.toLowerCase()) !== -1
        );
      });

      props.onUpdateItemsFilterText(itemsFiltered, text);
    }
    if (text.length < 1) {
      props.onUpdateItemsEmptyFilterText(text);
    }
  };

  const handleClickItemCheckbox = (schoolId: string) => {
    const items = props.availableSchools;
    const nextItems = items.map(item => {
      if (item.schoolId === schoolId) {
        return { ...item, checked: !item.checked };
      } else {
        return item;
      }
    });

    props.onItemCheck(nextItems);
  };

  const renderListBody = () => {
    const { availableSchools } = props;

    if (typeof availableSchools === 'undefined' || availableSchools.length === 0) {
      return <div>No schools</div>;
    }

    const rows: React.ReactNodeArray = availableSchools.map((item, index) => {
      return (
        <tr key={`available _schools_${index}`}>
          <td>
            <div className="form-group form-check">
              <input
                id="availableSchools"
                type="checkbox"
                className="form-check-input"
                checked={item.checked}
                onChange={() => handleClickItemCheckbox(item.schoolId)}
              />
            </div>
          </td>
          <td>{item.schoolName}</td>
        </tr>
      );
    });

    return (
      <div>
        <table className={'table table-sm mPre'}>
          <thead className="font-weight-bold">
            <tr>
              <td></td>
              <td>School name</td>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  };

  const { isSelectAll, isSelectTournamentParticipant, isSelectTournamentWaitingList, filterText } = props;

  return (
    <div className="bMessageSettingsForm">
      <div className="form-group form-check">
        <input
          value={filterText}
          type="text"
          placeholder="Enter school name..."
          onChange={handleChangeFilterText}
          className="form-control"
        />
      </div>
      <div className="form-group form-check">
        <input
          id="isSelectAll"
          type="checkbox"
          className="form-check-input"
          checked={isSelectAll}
          onChange={props.onSelectAllClick}
        />
        <label htmlFor="isSelectAll" className="form-check-label">
          {isSelectAll ? 'Deselect All' : 'Select All'}
        </label>
      </div>
      <div className="form-group form-check">
        <input
          id="isSelectTournamentParticipant"
          type="checkbox"
          className="form-check-input"
          checked={isSelectTournamentParticipant}
          onChange={props.onSelectTournamentParticipantClick}
        />
        <label htmlFor="isSelectTournamentParticipant" className="form-check-label">
          {isSelectTournamentParticipant ? 'Deselect tournament participants' : 'Select tournament participants'}
        </label>
      </div>
      <div className="form-group form-check">
        <input
          id="isSelectTournamentWaitingList"
          type="checkbox"
          className="form-check-input"
          checked={isSelectTournamentWaitingList}
          onChange={props.onSelectTournamentWaitingListClick}
        />
        <label htmlFor="isSelectTournamentWaitingList" className="form-check-label">
          {isSelectTournamentWaitingList ? 'Deselect tournament waiting list' : 'Select tournament waiting list'}
        </label>
      </div>
      {renderListBody()}
    </div>
  );
};

TournamentCustomNotifications.displayName = 'TournamentCustomNotifications';
