import { AppUser } from 'Src/views/App/App';
import axios from 'axios';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../consts/table';
import { Count } from '../../../models/count';

const DEFAULT_FILTER: any = {
  limit: 10,
  skip: DEFAULT_SKIP,
  order: 'name ASC'
};

export function getSchoolUnionSchools(user: AppUser, filter = DEFAULT_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order || DEFAULT_FILTER.order
      }
    }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/schools`, config).then(response => {
    return response.data;
  });
}

export function getAllSchoolUnionSchools(user: AppUser, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP,
          order: DEFAULT_FILTER.order
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP,
          order: DEFAULT_FILTER.order
        }
      }
    };
  }

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/schools`, config).then(response => {
    return response.data;
  });
}

export function getSchoolUnionSchoolsCount(user: AppUser, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where
        }
      }
    };
  } else {
    config = {
      headers: { usid: key }
    };
  }

  return axios.get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/schools/count`, config).then(response => {
    return response.data;
  });
}

export function deleteSchoolUnionSchool(user: AppUser, schoolsId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.delete(`${window.apiBase}/i/schools/${activeSchoolId}/schools/${schoolsId}`, config).then(response => {
    return response.data;
  });
}

export function addSchoolUnionSchool(user: AppUser, data) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.post(`${window.apiBase}/i/schools/${activeSchoolId}/schools`, data, config).then(response => {
    return response.data;
  });
}

export function getUpcomingTournaments(user: AppUser, memberSchoolId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        limit: DEFAULT_LIMIT,
        skip: DEFAULT_SKIP
      }
    }
  };
  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/schools/${memberSchoolId}/tournamentScheduleList`, config)
    .then(response => {
      return response.data;
    });
}

export function getAvailableSchools(user: AppUser, tournamentId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .get(
      `${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/notifications/availableSchools`,
      config
    )
    .then(response => {
      return response.data;
    });
}
