import axios from 'axios';
import { AppUser } from 'Src/views/App/App';

export function uploadFile(user: AppUser, formData) {
  const key = user.roleSessionKey;

  const config = {
    headers: {
      usid: key,
      'Content-Type': 'multipart/form-data'
    }
  };

  return axios.post(`${window.apiFile}/files`, formData, config).then(response => {
    return response.data;
  });
}

export function uploadFileAllPath(user: AppUser, formData) {
  const key = user.roleSessionKey;

  const config = {
    headers: {
      usid: key,
      'Content-Type': 'multipart/form-data'
    }
  };

  return axios.post(`${window.apiFile}/files/allPath`, formData, config).then(response => {
    return response.data;
  });
}
