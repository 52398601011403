import * as React from 'react';
import { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import DatePicker from 'react-datepicker';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/report';
import { Switch } from 'Src/components/Switch/Switch';
import { LabelWithQuestionIcon } from 'Src/components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import { getStartDateForSchoolYear, getFinishDateForSchoolYear } from 'Src/helpers/calendar/calendar';
import { School } from 'Src/models/school';

interface Props {
  onCloseClick: () => void;
  onSubmit: (data: any, reportType: string) => void;
  school: School;
}

//report 066
export function SchoolTournamentScheduleUnionSideReportForm(props: Props) {
  const { school, onCloseClick, onSubmit } = props;

  const now = new Date();
  let dateTo = getFinishDateForSchoolYear(now);

  const requestInitial = {
    dateFrom: now,
    dateTo,
    isShowBooked: true,
    isShowWaitingList: true,
    isShowRemoved: false,
    isShowOtherUnionTournaments: false
  };

  return (
    <Formik
      onSubmit={values => onSubmit(values, REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.SCHOOL_TOURNAMENT_SCHEDULE_UNION_SIDE)}
      initialValues={requestInitial}
      render={({ values, setFieldValue }) => (
        <Form>
          <div className="mb-3 mt-3">
            {'A comprehensive overview of all tournaments in which a selected school is currently ' +
              ' booked to participate or added to a waiting list. ' +
              ' You have the option to include tournaments organised by other unions as well. '}
          </div>
          <div className="form-group">
            <label>Date from</label>
            <Field>
              {({ form }) => (
                <div className="mb-3">
                  <DatePicker
                    selected={form.values.dateFrom}
                    onChange={dateFrom => {
                      form.setFieldValue('dateFrom', dateFrom);
                      if (form.values.dateTo && form.values.dateTo < dateFrom) {
                        form.setFieldValue('dateTo', dateFrom);
                      }
                    }}
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
              )}
            </Field>
          </div>

          <div className="form-group">
            <label>Date to</label>
            <Field>
              {({ form }) => (
                <div className="mb-3">
                  <DatePicker
                    selected={form.values.dateTo}
                    onChange={dateTo => {
                      form.setFieldValue('dateTo', dateTo);
                    }}
                    minDate={form.values.dateFrom}
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
              )}
            </Field>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isShowBooked"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowBooked}
                    onChange={event => {
                      const isShowBooked = event.target.checked;

                      setFieldValue('isShowBooked', isShowBooked);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show booked"
                hintText={
                  'Include or exclude tournaments the selected school has been added to the participant list for'
                }
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowBooked"
              />
            </div>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isShowWaitingList"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowWaitingList}
                    onChange={event => {
                      const isShowWaitingList = event.target.checked;

                      setFieldValue('isShowWaitingList', isShowWaitingList);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show waiting list"
                hintText={'Include or exclude tournaments the selected school has been added to the waiting list for'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowWaitingList"
              />
            </div>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isShowRemoved"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowRemoved}
                    onChange={event => {
                      const isShowRemoved = event.target.checked;

                      setFieldValue('isShowRemoved', isShowRemoved);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show tournaments the school was removed from"
                hintText={'Include or exclude tournaments the selected school has been removed from'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowRemoved"
              />
            </div>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isShowOtherUnionTournaments"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowOtherUnionTournaments}
                    onChange={event => {
                      const isShowOtherUnionTournaments = event.target.checked;

                      setFieldValue('isShowOtherUnionTournaments', isShowOtherUnionTournaments);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show tournaments organised by other unions"
                hintText={'Include or exclude that are organised by other unions'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowOtherUnionTournaments"
              />
            </div>
          </div>

          <button type="button" className="btn btn-secondary mr-3" onClick={onCloseClick}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Get
          </button>
        </Form>
      )}
    />
  );
}
