import * as React from 'react';
import * as propz from 'propz';
import { Component } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button } from 'Src/components/Button/Button';
import { SketchPicker } from 'react-color';
import { SchoolHouse } from 'Src/models/house';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { checkAvailabilityHouseName } from 'Src/helpers/service/admin/houses';
import { AppUser } from 'Src/views/App/App';

interface Props {
  house?: SchoolHouse;
  onCancel: () => void;
  onSubmit: (data: any) => void;
  user: AppUser;
}

interface State {
  isLoading: boolean;
  colors: any[];
  displayColorPicker: boolean;
  newColor: string;
}

export class HouseForm extends Component<Props, State> {
  constructor(props) {
    super(props);

    const isHouseExist = this.isHouseExist();

    const { house } = this.props;

    this.state = {
      isLoading: false,
      colors: isHouseExist ? propz.get(house, ['colors'], ['#fff']) : ['#fff'],
      displayColorPicker: false,
      newColor: '#fff'
    };
  }

  isHouseExist(): boolean {
    const { house } = this.props;

    return typeof house !== 'undefined';
  }

  acceptColor = () => {
    const newColors = this.state.colors;
    newColors.push(this.state.newColor);

    this.setState({ colors: newColors });
    this.handleClose();
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  onColorChange = color => {
    this.setState({ newColor: color.hex });
  };

  onRemoveColor = (index: number): void => {
    const colors = [...this.state.colors];
    colors.splice(index, 1);

    this.setState({
      colors: colors
    });
  };

  addColorClick = () => {
    const newColors = this.state.colors;

    this.setState({
      colors: newColors,
      displayColorPicker: true
    });
  };

  onSubmit(values) {
    const dataCopy = {
      name: values.houseName.trim(),
      description: values.description,
      colors: this.state.colors
    };
    this.props.onSubmit(dataCopy);
  }

  render() {
    const { user } = this.props;
    const house = this.props.house;
    const houseSchema = Yup.object().shape({
      houseName: Yup.string()
        .required('Required')
        .test('houseName', 'You already have a house matching this name.', value => {
          const houseName = propz.get(house, ['name'], '');
          const isEqualName = houseName === value;

          switch (true) {
            case isEqualName:
              return true;
            case !value:
              return false;
            default:
              return checkAvailabilityHouseName(user, { name: value.trim() }).then(res => {
                return res.isAvailable;
              });
          }
        })
    });

    const colorNodes = this.state.colors.map((color, clrIndex) => {
      return (
        <div
          key={`color_index_${clrIndex}`}
          style={{ background: color }}
          className="eHouseForm_listItemColor mRemovable"
          onClick={() => this.onRemoveColor(clrIndex)}
        />
      );
    });

    const houseInitial = {
      houseName: propz.get(house, ['name'], ''),
      description: propz.get(house, ['description'], '')
    };

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <Formik
              initialValues={houseInitial}
              validationSchema={houseSchema}
              onSubmit={values => {
                this.onSubmit(values);
              }}
              validateOnBlur={false}
              validateOnChange={false}
              render={({ touched, errors }) => (
                <Form>
                  <label>House name</label>
                  <Field type="text" name="houseName" className="form-control mb-3" />
                  {touched.houseName && errors.houseName ? (
                    <div className="alert alert-danger">{errors.houseName}</div>
                  ) : null}
                  <label>Description</label>
                  <Field component="textarea" name="description" className="form-control mb-3" />
                  <label>Colours</label>
                  <div>
                    {colorNodes}
                    {this.state.colors.length <= 1 ? (
                      <div className="eHouseForm_IconAdd" onClick={this.addColorClick}>
                        <FontAwesomeIcon icon="plus-circle" />
                      </div>
                    ) : null}
                  </div>
                  <div
                    className="eHouseForm_ColorEditor"
                    style={this.state.displayColorPicker ? { display: 'block' } : { display: 'none' }}
                  >
                    <SketchPicker color={this.state.newColor} onChange={this.onColorChange} />
                    <div className="'mt-3 mb-3 mr-3 btn btn-secondary" onClick={this.handleClose}>
                      Cancel
                    </div>
                    <div className="'mt-3 mb-3 mr-3 btn btn-primary" onClick={this.acceptColor}>
                      Accept
                    </div>
                  </div>
                  <Button onClick={this.props.onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                  <button type="submit" className="mt-3 mb-3 btn btn-primary">
                    Save
                  </button>
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}
