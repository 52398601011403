import * as React from 'react';
import { FunctionComponent } from 'react';
import * as classNames from 'classnames';

interface Props {
  modalTitle: string;
  tournamentTags: any[];
  selectedTournamentTagId: string;
  onTournamentTagChange: (event) => void;
  onCloseClick: () => void;
  isOpen: boolean;
  onSaveClick: () => void;
}

export const TournamentTagAssignModal: FunctionComponent<Props> = props => {
  if (!props.isOpen) {
    return null;
  }

  const modalClasses = classNames({
    mDisplayBlock: props.isOpen,
    show: props.isOpen,
    modal: true,
    fade: true
  });

  const options = props.tournamentTags.map(item => {
    const visibilityStatus = item.isPublic ? 'Public' : 'Non-public';
    return (
      <option key={item.id} value={item.id}>
        {`${item.tagName} (${visibilityStatus})`}
      </option>
    );
  });

  const emptyOption = (
    <option key={'empty_option'} value={''}>
      {''}
    </option>
  );

  return (
    <div className={modalClasses}>
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{props.modalTitle}</h5>
            <button type="button" className="close" onClick={props.onCloseClick}>
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <select
                value={props.selectedTournamentTagId}
                className="form-control"
                onChange={event => props.onTournamentTagChange(event)}
              >
                {emptyOption}
                {options}
              </select>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={props.onCloseClick}>
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              disabled={!props.selectedTournamentTagId}
              onClick={props.onSaveClick}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

TournamentTagAssignModal.displayName = 'TournamentTagAssignModal';
