import * as React from 'react';
import { TournamentGridShortTeamData } from './TournamentGrid';

interface TeamDistributedCardProps {
  team: TournamentGridShortTeamData;
  onDeleteClick: (teamId: string) => void;
}

export class TeamDistributedCard extends React.Component<TeamDistributedCardProps> {
  readonly SCHOOL_PICTURE_HEIGHT = 40;

  onClick = () => {
    const { team, onDeleteClick } = this.props;

    onDeleteClick(team.teamId);
  };

  render() {
    const { teamName, schoolPic, schoolName } = this.props.team;

    const style = {
      backgroundImage: `url(${schoolPic}?sizing=height&height=${this.SCHOOL_PICTURE_HEIGHT}`
    };

    return (
      <div className="eTeamCard">
        <div className="eTeamCardSchoolPicWrapper">
          {schoolPic && <div className={'eTeamCardSchoolPic'} style={style} />}
        </div>
        <div className="eTeamCardTeamName">{`${teamName}, ${schoolName}`}</div>
        <div className="eTeamCardTeamAction" onClick={this.onClick}>
          Remove
        </div>
      </div>
    );
  }
}
