import * as React from 'react';
import * as Moment from 'moment';
import * as propz from 'propz';
import { EventInvitationMessage } from 'Src/models/message';
import {
  ABSENCE_MESSAGE_REASON_SERVER_TO_CLIENT_MAPPING,
  ABSENCE_MESSAGE_INTERVAL_SERVER_TO_CLIENT_MAPPING
} from 'Src/consts/absenceMessage';
import { getFullName } from 'Src/helpers/autocomplete/autocomplete';
import { DetailsIcon } from 'Src/components/SvgIcons/DetailsIcon';
import './AbsenceRequsetCard.scss';

interface Props {
  item: EventInvitationMessage;
  onShowDetailsClick: (item: EventInvitationMessage) => void;
}

export const AbsenceRequestCard = (props: Props) => {
  const { item, onShowDetailsClick } = props;
  const { playerDetailsData } = item;

  const studentName = getFullName(playerDetailsData);
  const schoolLogo = propz.get(item, ['schoolData', 'pic'], '');
  const isSchoolLogoExist = schoolLogo !== '';
  const intervalAbsence = propz.get(item, ['intervalAbsence'], '');
  const absenceIntervalFormatted = ABSENCE_MESSAGE_INTERVAL_SERVER_TO_CLIENT_MAPPING[intervalAbsence];
  const reasonAbsence = propz.get(item, ['reasonAbsence'], '');
  const absenceReasonFormatted = ABSENCE_MESSAGE_REASON_SERVER_TO_CLIENT_MAPPING[reasonAbsence];
  const absenceDate = propz.get(item, ['dateAbsence'], '');
  const absenceTime = propz.get(item, ['dateAbsence'], '');
  const absenceDateFormatted = Moment(absenceDate).format('DD/MM/YYYY');
  const absenceTimeFormatted = Moment(absenceTime).format('hh:mm');
  const isAuthorized = propz.get(item, ['isAuthorized']);
  const isAuthorizedFieldExist = typeof isAuthorized !== 'undefined';

  return (
    <div className="bAbsenceRequestCard">
      <div>
        <div className="eAbsenceRequestCardStudentName">
          <p className={isSchoolLogoExist ? '' : 'text-center'}>{studentName}</p>

          {isSchoolLogoExist && (
            <div className="eAbsenceRequestCardSchoolLogo">
              <img src={schoolLogo} alt="SchoolLogo" />
            </div>
          )}
        </div>

        {isAuthorizedFieldExist ? (
          <div className="eAbsenceRequestCardStatus">{isAuthorized ? 'Authorised' : 'Not authorised'}</div>
        ) : (
          <div className="eAbsenceRequestCardStatus">Pending</div>
        )}

        <div className="eAbsenceRequestCardInfo">
          <span className="font-weight-bold">Interval: </span>
          {absenceIntervalFormatted}
        </div>
        <div className="eAbsenceRequestCardInfo">
          <span className="font-weight-bold">Reason: </span>
          {absenceReasonFormatted}
        </div>

        <div className="eAbsenceRequestCardTime">
          <p>{absenceDateFormatted}</p>
          <p>{absenceTimeFormatted}</p>
        </div>
      </div>

      <div className="d-flex justify-content-end">
        <div className="eAbsenceRequestCardShowDetailsButton" onClick={() => onShowDetailsClick(item)}>
          <DetailsIcon />
          <span>Details</span>
        </div>
      </div>
    </div>
  );
};

AbsenceRequestCard.displayName = 'AbsenceRequestCard';
