import { Point } from 'Src/models/venue';

export const EVENT_VENUE = {
  HOME: 'HOME',
  AWAY: 'AWAY',
  CUSTOM: 'CUSTOM',
  TBD: 'TBD'
};

export const VENUE_SERVER_TO_CLIENT_MAPPING = {
  HOME: 'Home',
  AWAY: 'Away',
  CUSTOM: 'Away',
  TBD: 'TBD'
};

export const VENUE_TABS = ['Summary'];

export const POINT = 'Point';
export const DEFAULT_LATITUDE = 50.832949;
export const DEFAULT_LONGITUDE = -0.246722;
export const DEFAULT_COORDINATES: [number, number] = [DEFAULT_LONGITUDE, DEFAULT_LATITUDE];

export const DEFAULT_POINT: Point = {
  coordinates: DEFAULT_COORDINATES,
  lat: DEFAULT_LATITUDE,
  lng: DEFAULT_LONGITUDE,
  type: POINT
};

export const TBD = { id: 'TBD', postcode: 'TBD' };

export const MILES_TO_METERS = 1609.344;

export const VENUE_DISTANCE_SERVER_TO_CLIENT_MAPPING = {
  UNLIMITED: 'Unlimited',
  '10M': '10 miles',
  '20M': '20 miles',
  '30M': '30 miles',
  '40M': '40 miles',
  '50M': '50 miles'
};

export const VENUE_DISTANCE = {
  UNLIMITED: 0,
  '10M': 10,
  '20M': 20,
  '30M': 30,
  '40M': 40,
  '50M': 50
};
