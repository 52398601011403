import * as React from 'react';
import { Route, Switch } from 'react-router';
import { SchoolTeams } from './SchoolTeams';
import { SchoolTeamPlayers } from './SchoolTeamPlayers';

export const SchoolTeamsRouting = allProps => {
  return (
    <Switch>
      <Route exact path={'/teams'} render={props => <SchoolTeams {...props} user={allProps.user} />} />
      <Route
        exact
        path={'/teams/teamPlayers'}
        render={props => <SchoolTeamPlayers {...props} user={allProps.user} />}
      />
    </Switch>
  );
};

SchoolTeamsRouting.displayName = 'SchoolTeamsRouting';
