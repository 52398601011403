import * as React from 'react';
import { Component } from 'react';
import { AppUser } from 'Src/views/App/App';
import * as propz from 'propz';

import './CreateGeneralMessageView.css';
import { CreateGeneralMessageButtons } from 'Src/components/CreateGeneralMessageButtons/CreateGeneralMessageButtons';
import { GeneralMessageSettingsForm } from 'Src/components/GeneralMessageSettingsForm/GeneralMessageSettingsForm';
import { Attachment, ParentsDeliverySettings } from 'Src/models/generalMessage';
import { GeneralMessageTextForm } from 'Src/components/GeneralMessageTextForm/GeneralMessageTextForm';
import { CreateGeneralMessageStudentsStep } from 'Src/components/CreateGeneralMessageSteps/CreateGeneralMessageStudentsStep';
import { CreateGeneralMessageStaffStep } from 'Src/components/CreateGeneralMessageSteps/CreateGeneralMessageStaffStep';
import { CreateGeneralMessageGovernorsStep } from 'Src/components/CreateGeneralMessageSteps/CreateGeneralMessageGovernorsStep';
import {
  createSchoolGeneralMessage,
  deleteSchoolGeneralMessage,
  getSchoolGeneralMessage,
  scheduleSchoolGeneralMessage,
  sendSchoolGeneralMessage,
  updateSchoolGeneralMessage
} from 'Src/helpers/service/admin/generalMessage';
import { ProgressBar } from 'Src/components/ProgressBar/ProgressBar';
import { SchoolStudent } from 'Src/models/schoolStudent';
import { GeneralMessagePreviewWithTabs } from 'Src/components/GeneralMessagePreview/GeneralMessagesPreviewWithTabs';
import {
  ACTION_TYPE,
  GeneralMessageActionsButton
} from 'Src/components/GeneralMessageActionsButton/GeneralMessageActionsButton';
import { ScheduleSendingForm } from 'Src/components/GeneralMessages/ScheduleSendingForm/ScheduleSendingForm';
import * as Moment from 'moment';
import { DATE_TIME_FORMAT } from 'Src/consts/date';
import { TextUtils } from 'Src/helpers/utils/TextUtils';
import { SchoolUser } from 'Src/models/schoolUser';
import { History, Location } from 'history';
import { ROLE, STAFF_LIST } from 'Src/consts/user';
import { uploadFileAllPath } from 'Src/helpers/service/file';
import { HTML_FIRST_PART_PAGE_CODE, HTML_LAST_PART_PAGE_CODE } from 'Src/components/HTMLEditorQuill/HTMLEditorConst';
import { getSettingsUploadFiles } from 'Src/helpers/service/nobody/settings';

enum MESSAGE_CREATING_STEPS {
  SETTINGS = 'SETTINGS',
  STUDENT_LIST = 'STUDENT_LIST',
  STAFF_LIST = 'STAFF_LIST',
  GOVERNORS_LIST = 'GOVERNORS_LIST',
  CONTENT = 'CONTENT',
  PREVIEW = 'PREVIEW'
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

interface MessageState {
  messageId?: string;
  messageTitle: string;
  messageDescription: string;
  parentsDeliverySettings: ParentsDeliverySettings;
  isSendStudents: boolean;
  isSendParents: boolean;
  isSendStaff: boolean;
  isSendGovernors: boolean;
  messageSmsText: string;
  messageEmailSubject: string;
  messageEmailBody: string;
  messagePushTitle: string;
  messagePushBody: string;
  messageAttachments: Attachment[];
  selectedStudents: SchoolStudent[];
  selectedStaff: SchoolUser[];
  selectedGovernors: SchoolUser[];
  affectedUserList: any[];
  messageScheduleAt: string;
  isImageError: boolean;
  filesSize: number;
  maxSizeUploadFile: number;
  isLoadingFile: boolean;

  isSmsEditorEnabled: boolean;
  isPushEditorEnabled: boolean;
  isEmailEditorEnabled: boolean;
}

interface StepState {
  currentStep: number;
}

interface ErrorState {
  isError: boolean;
  errorMessage: string;
}

interface ScheduleFormState {
  isScheduleFormVisible: boolean;
}

interface FullState extends MessageState, StepState, ErrorState, ScheduleFormState {
  isStudentsSelectionCompleted: boolean;
  isStaffSelectionCompleted: boolean;
  isGovernorsSelectionCompleted: boolean;
}

const DEFAULT_PARENTS_DELIVERY_SETTINGS: ParentsDeliverySettings = {
  all: false,
  firstPriority: true,
  secondPriority: true,
  thirdPriority: false
};

const DEFAULT_STATE: FullState = {
  currentStep: 0,

  isError: false,
  errorMessage: '',

  messageTitle: '',
  messageDescription: '',
  parentsDeliverySettings: DEFAULT_PARENTS_DELIVERY_SETTINGS,
  isSendParents: false,
  isSendStudents: false,
  isSendStaff: false,
  isSendGovernors: false,
  messageSmsText: '',
  messageEmailSubject: '',
  messageEmailBody: '',
  messagePushTitle: '',
  messagePushBody: '',
  messageAttachments: [],
  selectedStudents: [],
  selectedStaff: [],
  selectedGovernors: [],
  affectedUserList: [],
  messageScheduleAt: '',
  isSmsEditorEnabled: true,
  isPushEditorEnabled: true,
  isEmailEditorEnabled: true,
  isScheduleFormVisible: false,
  isLoadingFile: false,
  isImageError: false,
  filesSize: 0,
  maxSizeUploadFile: 0,
  isStudentsSelectionCompleted: false,
  isStaffSelectionCompleted: false,
  isGovernorsSelectionCompleted: false
};

export class CreateGeneralMessageView extends Component<Props, FullState> {
  constructor(props: Props) {
    super(props);

    let initialState = { ...DEFAULT_STATE };
    const locationState = props.history.location.state as any;

    if (typeof locationState !== 'undefined' && locationState.isCreateLikeThis === true) {
      const { message, generalMessage, isAllMessageCopy } = locationState;

      // fill message data from selected message
      initialState.messageTitle = propz.get(message, ['title'], '');
      initialState.messageDescription = propz.get(message, ['description'], '');
      initialState.isSendParents = propz.get(generalMessage, ['subscribers', 'types', 'parents'], false);
      initialState.isSendStudents = propz.get(generalMessage, ['subscribers', 'types', 'students'], false);
      initialState.isSendStaff = this.isSendSchoolStaff();
      initialState.isSendGovernors = this.isSendGovernor();
      initialState.messageSmsText = propz.get(generalMessage, ['content', 'sms', 'text'], '');
      initialState.messageEmailSubject = propz.get(generalMessage, ['content', 'email', 'subject'], '');
      initialState.messageEmailBody = propz.get(generalMessage, ['content', 'email', 'body'], '');
      initialState.messageAttachments = propz.get(generalMessage, ['content', 'email', 'attachments'], []);
      initialState.messagePushTitle = propz.get(generalMessage, ['content', 'push', 'title'], '');
      initialState.messagePushBody = propz.get(generalMessage, ['content', 'push', 'body'], '');
      initialState.parentsDeliverySettings = propz.get(
        generalMessage,
        ['subscribers', 'parentsDeliverySettings'],
        DEFAULT_PARENTS_DELIVERY_SETTINGS
      );
      initialState.affectedUserList = propz.get(generalMessage, ['affectedUserList'], []);

      // attachments are located in the message body, so you need to remove them
      const messageEmailBody = propz.get(generalMessage, ['content', 'email', 'body']);
      if (typeof messageEmailBody !== 'undefined') {
        initialState.messageEmailBody = messageEmailBody.replace(/<div>Attachments:(.+)\s?/, '');
      }

      if (isAllMessageCopy) {
        // subscribers object is different with students and school user object
        // not a good solution but we map it's
        const usersMapped = initialState.affectedUserList.map(user => ({
          ...user,
          id: user.userId
        }));

        const subscribersStudents = propz.get(generalMessage, ['subscribers', 'lists', 'students'], []);
        const subscribersStaff = propz.get(generalMessage, ['subscribers', 'lists', 'staffs'], []);

        const studentsMapped = subscribersStudents.map(student => ({
          ...student,
          id: student.userId
        }));
        // in code below we find users which includes in 'subscribers.lists'
        // because affectedUserList may include parents
        const students = usersMapped.filter(student => {
          return subscribersStudents.findIndex(s => s.userId === student.id) !== -1;
        });
        const staffs = usersMapped.filter(staff => {
          return subscribersStaff.findIndex(s => s.userId === staff.id) !== -1;
        });

        initialState.selectedStudents = initialState.isSendParents ? studentsMapped : students;
        initialState.selectedStaff = staffs;
      }
    }

    getSettingsUploadFiles(this.props.user).then(maxSizeUploadFile => {
      initialState.maxSizeUploadFile = maxSizeUploadFile;
    });

    this.state = initialState;
  }

  isSendSchoolStaff() {
    const locationState = this.props.history.location.state as any;
    const { generalMessage } = locationState;

    const staffs = propz.get(generalMessage, ['subscribers', 'lists', 'staffs'], []);

    return staffs.some(staff =>
      staff.permissions.some(permission => STAFF_LIST.some(staffPreset => staffPreset === permission.preset))
    );
  }

  isSendGovernor() {
    const locationState = this.props.history.location.state as any;
    const { generalMessage } = locationState;

    const staffs = propz.get(generalMessage, ['subscribers', 'lists', 'staffs'], []);

    return staffs.some(staff => staff.permissions.some(permission => permission.preset === ROLE.GOVERNOR));
  }

  onNextClick = () => {
    const {
      currentStep,
      isSmsEditorEnabled,
      isPushEditorEnabled,
      isEmailEditorEnabled,
      messageEmailBody,
      messageEmailSubject,
      messagePushBody,
      messagePushTitle,
      messageSmsText
    } = this.state;

    let newMessageEmailBody;
    if (messageEmailBody === '<p><br></p>') {
      //  because   "<p><br></p>" === ''  in editor
      newMessageEmailBody = '';
      this.setState({
        messageEmailBody: newMessageEmailBody
      });
    } else {
      newMessageEmailBody = messageEmailBody;
    }

    const steps = this.getSteps();
    const currentStepName = steps[currentStep];
    const isCurrentStepContent = currentStepName === MESSAGE_CREATING_STEPS.CONTENT;

    // returns an error if content is empty
    const isSMSTextExist = messageSmsText !== '';
    const isPushBodyExist = messagePushBody !== '';
    const isPushTitleExist = messagePushTitle !== '';
    const isEmailSubjectExist = messageEmailSubject !== '';
    const isEmailBodyExist = newMessageEmailBody !== '';

    const isShowSMSError = !isSMSTextExist && isSmsEditorEnabled;
    const isShowPushError = (!isPushBodyExist || !isPushTitleExist) && isPushEditorEnabled;
    const isShowEmailError = (!isEmailSubjectExist || !isEmailBodyExist) && isEmailEditorEnabled;

    if (isCurrentStepContent && (isShowSMSError || isShowPushError || isShowEmailError)) {
      let errorText = '';

      switch (true) {
        case isShowSMSError:
          errorText = 'Text for sms content should be defined';
          break;
        case isShowPushError:
          errorText = 'Title or body for push content should be defined';
          break;
        case isShowEmailError:
          errorText = 'Subject or body for email content should be defined';
          break;
      }
      this.setState({
        isError: true,
        errorMessage: errorText
      });
    } else {
      this.saveGeneralMessage()
        .then(message => {
          this.setState(prevState => ({
            currentStep: prevState.currentStep + 1,
            messageId: message.id,
            affectedUserList: message.affectedUserList
          }));

          //clear search
          const { history } = this.props;
          const { search, ...rest } = history.location;
          history.replace(rest);
        })
        .catch(error => {
          console.error(error);
          this.setState({
            errorMessage: error.response.data.details.text,
            isError: true
          });
        });
    }
  };

  onIsEmailEditorChange = event => {
    const isEmailEditorEnabled = event.target.checked;

    if (!isEmailEditorEnabled) {
      this.setState({
        messageEmailSubject: '',
        messageEmailBody: '',
        messageAttachments: []
      });
    }

    this.setState({
      isEmailEditorEnabled: isEmailEditorEnabled
    });
  };

  onIsSmsEditorChange = event => {
    const isSmsEditorEnabled = event.target.checked;

    if (!isSmsEditorEnabled) {
      this.setState({
        messageSmsText: ''
      });
    }

    this.setState({ isSmsEditorEnabled: isSmsEditorEnabled });
  };

  onIsPushEditorChange = event => {
    const isPushEditorEnabled = event.target.checked;
    if (!isPushEditorEnabled) {
      this.setState({
        messagePushBody: '',
        messagePushTitle: ''
      });
    }
    this.setState({ isPushEditorEnabled: isPushEditorEnabled });
  };

  onPrevClick = () => {
    this.setState(prevState => ({
      currentStep: prevState.currentStep - 1
    }));
  };

  onSaveClick = () => {
    this.saveGeneralMessage()
      .then(response => {
        this.onMessageSaved(response);
      })
      .catch(reason => {
        console.error(reason.response.data.details.text);
        this.onShowError(reason.response.data.details.text);
      });
  };

  onSendClick = () => {
    const { user } = this.props;

    this.saveGeneralMessage()
      .then(response => {
        return sendSchoolGeneralMessage(user, response.id);
      })
      .then(response => {
        this.onMessageSaved(response);
      })
      .catch(reason => {
        console.error(reason.response.data.details.text);
        this.onShowError(reason.response.data.details.text);
      });
  };

  onScheduleClick = () => {
    if (!this.isScheduleDateValid()) {
      return;
    }

    const { user } = this.props;
    const { messageScheduleAt } = this.state;
    let newMessage;

    this.saveGeneralMessage()
      .then(message => {
        newMessage = message;
        return scheduleSchoolGeneralMessage(user, message.id, messageScheduleAt);
      })
      .then(() => {
        // there is message with status DRAFT and we need to reload message from server with new status
        return getSchoolGeneralMessage(user, newMessage.id);
      })
      .then(message => {
        this.onMessageSaved(message);
      })
      .catch(reason => {
        console.error(reason.response.data.details.text);
        this.onShowError(reason.response.data.details.text);
      });
  };

  onActiveActionChange = (actionType: ACTION_TYPE) => {
    this.setState({
      isScheduleFormVisible: actionType === ACTION_TYPE.SCHEDULE
    });
  };

  onCancelClick = () => {
    const { user } = this.props;
    const { messageId } = this.state;

    if (typeof messageId === 'undefined') {
      this.goToMessagesList();
    } else {
      deleteSchoolGeneralMessage(user, messageId).then(() => this.goToMessagesList());
    }
  };

  onScheduleDateChange = (event: any) => {
    const scheduleAt = Moment(event.target.value, DATE_TIME_FORMAT).toISOString();

    this.setState({
      messageScheduleAt: scheduleAt
    });
  };

  onMessageSaved = (message): void => {
    this.goToMessagesList(message);
  };

  onTitleChange = (event): void => {
    this.setState({
      messageTitle: event.target.value
    });
  };

  onDescriptionChange = (event): void => {
    this.setState({
      messageDescription: event.target.value
    });
  };

  onSendParentsChange = (event): void => {
    this.setState({
      isSendParents: event.target.checked
    });
  };

  onSendStudentsChange = (event): void => {
    this.setState({
      isSendStudents: event.target.checked
    });
  };

  onSendGovernorsChange = (event): void => {
    this.setState({
      isSendGovernors: event.target.checked
    });
  };

  onSendStaffChange = (event): void => {
    this.setState({
      isSendStaff: event.target.checked
    });
  };

  onDeliverySettingsChanged = (settings: ParentsDeliverySettings): void => {
    this.setState({
      parentsDeliverySettings: settings
    });
  };

  onEmailSubjectChange = event => {
    this.setState({
      messageEmailSubject: event.target.value
    });
  };

  onEmailBodyChange = body => {
    this.setState({
      messageEmailBody: body
    });
  };

  onSmsTextChange = event => {
    this.setState({
      messageSmsText: event.target.value
    });
  };

  onPushTitleChange = event => {
    this.setState({
      messagePushTitle: event.target.value
    });
  };

  onPushBodyChange = event => {
    this.setState({
      messagePushBody: event.target.value
    });
  };

  onEmailAttachmentChange = event => {
    const { user } = this.props;
    const { filesSize, maxSizeUploadFile } = this.state;
    const attachments = [...this.state.messageAttachments];
    const file = event.target.files[0];
    const currentFileSize = event.target.files[0].size;
    const fileName = file.name;
    const fileNameWithoutSpaces = fileName.split(' ').join('_');
    const formData = new FormData();
    formData.append('file', file, fileNameWithoutSpaces);

    const allFilesSize = filesSize + currentFileSize;

    if (allFilesSize > maxSizeUploadFile) {
      let maxSizeUploadFileText = '0 Mb';
      if (maxSizeUploadFile > 0) {
        maxSizeUploadFileText = `${(maxSizeUploadFile / 1000000).toFixed(0)}Mb`;
      }
      const errorText = `The message cannot be sent as the total size of attachments is too large.
      Please ensure your attachments don't exceed ${maxSizeUploadFileText} and try sending a message again.`;
      this.setState({
        errorMessage: errorText,
        isError: true
      });
    } else {
      this.setState({
        isLoadingFile: true
      });
      uploadFileAllPath(user, formData)
        .then(data => {
          const { key, link } = data;
          attachments.push({
            fileName: fileNameWithoutSpaces,
            link: link,
            externalFileId: key,
            fileSize: currentFileSize
          });
          this.setState({
            messageAttachments: attachments,
            filesSize: allFilesSize,
            isLoadingFile: false
          });
        })
        .catch(error => {
          this.setState({
            isImageError: true,
            isLoadingFile: false
          });
        });
    }
  };

  onRemoveAttachment = (key: string) => {
    const { filesSize } = this.state;
    const attachments = [...this.state.messageAttachments];
    const attachmentIndex = attachments.findIndex(attachment => attachment.externalFileId === key);
    const attachment = attachments.find(attachment => attachment.externalFileId === key);

    attachments.splice(attachmentIndex, 1);
    const newFileSize = filesSize - propz.get(attachment, ['fileSize'], 0);

    this.setState({
      messageAttachments: attachments,
      filesSize: newFileSize
    });
  };

  onSelectedStudentsChange = (students: SchoolStudent[]) => {
    this.setState({
      selectedStudents: students
    });
  };

  onStudentsSelectionCompleted = (isCompleted: boolean) => {
    this.setState({
      isStudentsSelectionCompleted: isCompleted
    });
  };

  onSelectedStaffChange = (staff: SchoolUser[]) => {
    this.setState({
      selectedStaff: staff
    });
  };

  onStaffSelectionCompleted = (isCompleted: boolean) => {
    this.setState({
      isStaffSelectionCompleted: isCompleted
    });
  };

  onSelectedGovernorsChange = (governors: SchoolUser[]) => {
    this.setState({
      selectedGovernors: governors
    });
  };

  onGovernorsSelectionCompleted = (isCompleted: boolean) => {
    this.setState({
      isGovernorsSelectionCompleted: isCompleted
    });
  };

  onCloseErrorClick = event => {
    event.preventDefault();

    this.setState({
      isError: false,
      errorMessage: ''
    });
  };

  onCloseImageError = () => {
    this.setState({
      isImageError: false
    });
  };

  onShowError = (message: string) => {
    const errorMessage = typeof message === 'string' ? message : 'Error saving general message';

    this.setState({
      isError: true,
      errorMessage: errorMessage
    });
  };

  isScheduleDateValid = (): boolean => {
    const { messageScheduleAt } = this.state;

    if (TextUtils.isEmpty(messageScheduleAt) || !Moment(messageScheduleAt).isValid()) {
      this.onShowError('Please enter a valid date');
      return false;
    }

    const now = new Date().getTime();
    const scheduleDate = new Date(messageScheduleAt).getTime();

    if (now > scheduleDate) {
      this.onShowError('The scheduled sending time is in the past, please amend it');
      return false;
    }

    return true;
  };

  getSteps(): MESSAGE_CREATING_STEPS[] {
    const { isSendStudents, isSendParents, isSendStaff, isSendGovernors } = this.state;
    let steps = [MESSAGE_CREATING_STEPS.SETTINGS];

    if (isSendParents || isSendStudents) {
      steps.push(MESSAGE_CREATING_STEPS.STUDENT_LIST);
    }
    if (isSendStaff) {
      steps.push(MESSAGE_CREATING_STEPS.STAFF_LIST);
    }
    if (isSendGovernors) {
      steps.push(MESSAGE_CREATING_STEPS.GOVERNORS_LIST);
    }

    steps.push(MESSAGE_CREATING_STEPS.CONTENT);
    steps.push(MESSAGE_CREATING_STEPS.PREVIEW);

    return steps;
  }

  getStepTitle(): string {
    const { currentStep } = this.state;
    const steps = this.getSteps();
    const currentStepName = steps[currentStep];

    switch (currentStepName) {
      case MESSAGE_CREATING_STEPS.SETTINGS:
        return 'Settings';

      case MESSAGE_CREATING_STEPS.STUDENT_LIST:
        return 'Select students';

      case MESSAGE_CREATING_STEPS.STAFF_LIST:
        return 'Select school staff';

      case MESSAGE_CREATING_STEPS.GOVERNORS_LIST:
        return 'Select trustees/governors';

      case MESSAGE_CREATING_STEPS.CONTENT:
        return 'Message content';

      case MESSAGE_CREATING_STEPS.PREVIEW:
        return 'Preview';
    }
  }

  getAttachmentAsLinksString = () => {
    const { messageAttachments } = this.state;
    if (messageAttachments.length > 0) {
      const attachmentAsLink = messageAttachments
        .map((attachment, index) => {
          return `<div key='attachment_${index}'><a href=${attachment.link} target="_blank">${attachment.fileName}</a></div>`;
        })
        .join('');
      return `<div>Attachments: ${attachmentAsLink}</div>`;
    } else {
      return '';
    }
  };

  saveGeneralMessage = () => {
    const {
      messageTitle,
      messageDescription,
      isSendParents,
      isSendStudents,
      isSendStaff,
      isSendGovernors,
      messageSmsText,
      messageEmailSubject,
      messageEmailBody,
      messagePushTitle,
      messagePushBody,
      messageAttachments,
      parentsDeliverySettings,
      selectedStudents,
      selectedStaff,
      selectedGovernors
    } = this.state;

    const { user } = this.props;

    const staffs = selectedStaff
      .map(staff => {
        return {
          userId: staff.id,
          permissionId: staff.permissions[0].id
        };
      })
      .filter(({ userId, permissionId }) => !TextUtils.isEmpty(userId) && !TextUtils.isEmpty(permissionId));

    const governors = selectedGovernors
      .map(governor => {
        return {
          userId: governor.id,
          permissionId: governor.permissions[0].id
        };
      })
      .filter(({ userId, permissionId }) => !TextUtils.isEmpty(userId) && !TextUtils.isEmpty(permissionId));

    const students = selectedStudents
      .map(student => {
        return {
          userId: student.id,
          permissionId: student.permissionId
        };
      })
      .filter(({ userId, permissionId }) => !TextUtils.isEmpty(userId) && !TextUtils.isEmpty(permissionId));

    const messageEmailBodyWithAttachment = messageEmailBody + this.getAttachmentAsLinksString();

    const messageHTMLEmailBody =
      messageEmailBody !== ''
        ? `${HTML_FIRST_PART_PAGE_CODE} ${messageEmailBodyWithAttachment} ${HTML_LAST_PART_PAGE_CODE}`
        : '';

    const data = {
      title: messageTitle,
      description: messageDescription,
      content: {
        sms: { text: messageSmsText },
        email: {
          subject: messageEmailSubject,
          body: messageHTMLEmailBody,
          attachments: messageAttachments
        },
        push: { title: messagePushTitle, body: messagePushBody }
      },
      subscribers: {
        types: {
          students: isSendStudents,
          parents: isSendParents,
          schoolStaff: isSendStaff || isSendGovernors
        },
        lists: {
          students: students,
          staffs: [...staffs, ...governors]
        },
        parentsDeliverySettings: parentsDeliverySettings
      }
    };

    if (typeof this.state.messageId !== 'undefined') {
      return updateSchoolGeneralMessage(user, data, this.state.messageId);
    } else {
      return createSchoolGeneralMessage(user, data);
    }
  };

  goToMessagesList(message?) {
    const { history } = this.props;
    const search = propz.get(history, ['location', 'state', 'search'], '');

    if (typeof message !== 'undefined') {
      history.push({
        pathname: `/generalMessages`,
        search,
        state: {
          message
        }
      });
    } else {
      history.push({
        pathname: `/generalMessages`,
        search
      });
    }
  }

  isNextButtonDisabled(): boolean {
    const {
      currentStep,
      isSendStaff,
      isSendStudents,
      isSendParents,
      isSendGovernors,
      affectedUserList,
      isError,
      isSmsEditorEnabled,
      isEmailEditorEnabled,
      isPushEditorEnabled,
      isLoadingFile,
      isStudentsSelectionCompleted,
      isStaffSelectionCompleted,
      isGovernorsSelectionCompleted
    } = this.state;
    const steps = this.getSteps();
    const currentStepName = steps[currentStep];

    let isNextButtonDisabled = false;
    const isAllEditorDisabled = !isSmsEditorEnabled && !isPushEditorEnabled && !isEmailEditorEnabled;
    switch (true) {
      case currentStepName === MESSAGE_CREATING_STEPS.SETTINGS:
        isNextButtonDisabled = !isSendStaff && !isSendGovernors && !isSendParents && !isSendStudents;
        break;
      case currentStepName === MESSAGE_CREATING_STEPS.STUDENT_LIST:
        isNextButtonDisabled = !isStudentsSelectionCompleted;
        break;
      case currentStepName === MESSAGE_CREATING_STEPS.STAFF_LIST:
        isNextButtonDisabled = !isStaffSelectionCompleted;
        break;
      case currentStepName === MESSAGE_CREATING_STEPS.GOVERNORS_LIST:
        isNextButtonDisabled = !isGovernorsSelectionCompleted;
        break;
      case currentStepName === MESSAGE_CREATING_STEPS.CONTENT:
        isNextButtonDisabled = isError || isLoadingFile || isAllEditorDisabled;
        break;
      case currentStepName === MESSAGE_CREATING_STEPS.PREVIEW:
        isNextButtonDisabled = typeof affectedUserList === 'undefined' || affectedUserList.length === 0;
        break;
      case isError:
        isNextButtonDisabled = true;
        break;
    }

    return isNextButtonDisabled;
  }

  renderViewByStep(): React.ReactNode {
    const { currentStep } = this.state;
    const steps = this.getSteps();
    const currentStepName = steps[currentStep];

    switch (currentStepName) {
      case MESSAGE_CREATING_STEPS.SETTINGS:
        return this.renderMessageSettingsForm();

      case MESSAGE_CREATING_STEPS.STUDENT_LIST:
        return this.renderStudentList();

      case MESSAGE_CREATING_STEPS.STAFF_LIST:
        return this.renderStaffList();

      case MESSAGE_CREATING_STEPS.GOVERNORS_LIST:
        return this.renderGovernorsList();

      case MESSAGE_CREATING_STEPS.CONTENT:
        return this.renderMessageTextForm();

      case MESSAGE_CREATING_STEPS.PREVIEW:
        return this.renderMessagePreview();
    }
  }

  renderMessageSettingsForm(): React.ReactNode {
    const {
      messageTitle,
      messageDescription,
      isSendParents,
      isSendStudents,
      isSendStaff,
      isSendGovernors,
      parentsDeliverySettings
    } = this.state;

    return (
      <GeneralMessageSettingsForm
        messageTitle={messageTitle}
        messageDescription={messageDescription}
        parentsDeliverySettings={parentsDeliverySettings}
        onTitleChange={this.onTitleChange}
        onDescriptionChange={this.onDescriptionChange}
        isSendParents={isSendParents}
        isSendStudents={isSendStudents}
        isSendStaff={isSendStaff}
        isSendGovernors={isSendGovernors}
        onIsSendParentsChange={this.onSendParentsChange}
        onIsSendStudentsChange={this.onSendStudentsChange}
        onSendStaffChange={this.onSendStaffChange}
        onSendGovernorsChange={this.onSendGovernorsChange}
        onDeliverySettingsChanged={this.onDeliverySettingsChanged}
      />
    );
  }

  renderStudentList(): React.ReactNode {
    return (
      <CreateGeneralMessageStudentsStep
        user={this.props.user}
        history={this.props.history}
        location={this.props.location}
        onSelectedItemsChange={this.onSelectedStudentsChange}
        selectedItems={this.state.selectedStudents}
        onStudentsSelectionCompleted={this.onStudentsSelectionCompleted}
      />
    );
  }

  renderStaffList(): React.ReactNode {
    return (
      <CreateGeneralMessageStaffStep
        user={this.props.user}
        history={this.props.history}
        location={this.props.location}
        selectedItems={this.state.selectedStaff}
        onSelectedItemsChange={this.onSelectedStaffChange}
        onStaffSelectionCompleted={this.onStaffSelectionCompleted}
      />
    );
  }

  renderGovernorsList(): React.ReactNode {
    return (
      <CreateGeneralMessageGovernorsStep
        user={this.props.user}
        history={this.props.history}
        location={this.props.location}
        selectedItems={this.state.selectedGovernors}
        onSelectedItemsChange={this.onSelectedGovernorsChange}
        onGovernorsSelectionCompleted={this.onGovernorsSelectionCompleted}
      />
    );
  }

  renderMessageTextForm(): React.ReactNode {
    const {
      messageEmailSubject,
      messageEmailBody,
      messagePushTitle,
      messagePushBody,
      messageSmsText,
      messageAttachments,
      isImageError,
      isError,
      isLoadingFile,
      isEmailEditorEnabled,
      isPushEditorEnabled,
      isSmsEditorEnabled
    } = this.state;

    return (
      <GeneralMessageTextForm
        onEmailSubjectChange={this.onEmailSubjectChange}
        onEmailBodyChange={this.onEmailBodyChange}
        onSmsTextChange={this.onSmsTextChange}
        onPushTitleChange={this.onPushTitleChange}
        onPushBodyChange={this.onPushBodyChange}
        onEmailAttachmentChange={this.onEmailAttachmentChange}
        onRemoveAttachment={this.onRemoveAttachment}
        onCloseImageError={this.onCloseImageError}
        messageSmsText={messageSmsText}
        messageEmailSubject={messageEmailSubject}
        messageEmailBody={messageEmailBody}
        messagePushTitle={messagePushTitle}
        messagePushBody={messagePushBody}
        messageAttachments={messageAttachments}
        isImageError={isImageError}
        isError={isError}
        isLoadingFile={isLoadingFile}
        isEmailEditorEnabled={isEmailEditorEnabled}
        isSmsEditorEnabled={isSmsEditorEnabled}
        isPushEditorEnabled={isPushEditorEnabled}
        onIsEmailEditorChange={this.onIsEmailEditorChange}
        onIsSmsEditorChange={this.onIsSmsEditorChange}
        onIsPushEditorChange={this.onIsPushEditorChange}
      />
    );
  }

  renderMessagePreview(): React.ReactNode {
    const {
      messageEmailSubject,
      messageEmailBody,
      messagePushTitle,
      messagePushBody,
      messageSmsText,
      messageAttachments,
      affectedUserList
    } = this.state;

    return (
      <GeneralMessagePreviewWithTabs
        messageSmsText={messageSmsText}
        messageEmailSubject={messageEmailSubject}
        messageEmailBody={messageEmailBody}
        messagePushTitle={messagePushTitle}
        messagePushBody={messagePushBody}
        messageAttachments={messageAttachments}
        affectedUsers={affectedUserList}
      />
    );
  }

  renderSendingError(): React.ReactNode {
    const { errorMessage, isError } = this.state;

    if (!isError) {
      return null;
    }

    return (
      <div className="alert fade show alert-danger d-flex justify-content-between align-items-center" role="alert">
        {errorMessage}
        <button
          type="button"
          className="ml-3 btn btn-danger"
          aria-label="Close"
          onClick={event => this.onCloseErrorClick(event)}
        >
          Close
        </button>
      </div>
    );
  }

  renderProgressBar() {
    const currentStep = this.state.currentStep + 1;
    const totalSteps = this.getSteps().length;
    const progress = (currentStep / totalSteps) * 100;

    return <ProgressBar progress={progress} step={currentStep} stepTotal={totalSteps} />;
  }

  render() {
    const stepCount = this.getSteps().length;
    const { currentStep, isError } = this.state;
    const hasNext = currentStep + 1 < stepCount;
    const hasPrev = currentStep > 0;
    const isNextButtonDisabled = this.isNextButtonDisabled();

    return (
      <div className="bCreateGeneralMessagesView">
        {this.renderSendingError()}

        <h3 className="mb-3 d-flex">
          Create new message
          <span className="text-muted">{` / ${this.getStepTitle()}`}</span>
          {!hasNext && (
            <div className="ml-auto">
              <GeneralMessageActionsButton
                onSendClick={this.onSendClick}
                onSaveClick={this.onSaveClick}
                onScheduleClick={this.onScheduleClick}
                onActiveActionChange={this.onActiveActionChange}
                isDisabled={isNextButtonDisabled}
              />
            </div>
          )}
        </h3>

        {this.state.isScheduleFormVisible && <ScheduleSendingForm onScheduleDateChange={this.onScheduleDateChange} />}

        {this.renderProgressBar()}
        {this.renderViewByStep()}

        <CreateGeneralMessageButtons
          hasNext={hasNext}
          hasPrevious={hasPrev}
          onNextClick={this.onNextClick}
          onPreviousClick={this.onPrevClick}
          onSaveClick={this.onSaveClick}
          onSendClick={this.onSendClick}
          onCancelClick={this.onCancelClick}
          isError={isError}
          isNextButtonDisabled={isNextButtonDisabled}
        />
      </div>
    );
  }
}
