import * as React from 'react';
import { Route, Switch } from 'react-router';
import { Requests } from './Requests';
import { RequestAcceptPage } from './RequestAcceptPage/RequestAcceptPage';

export const RequestRouting = allProps => {
  return (
    <Switch>
      <Route
        exact
        path={'/requests'}
        render={props => <Requests {...props} user={allProps.user} setNeedUpdate={allProps.setNeedUpdate} />}
      />
      <Route
        path={'/requests/:requestId'}
        render={props => <RequestAcceptPage {...props} user={allProps.user} setNeedUpdate={allProps.setNeedUpdate} />}
      />
    </Switch>
  );
};

RequestRouting.displayName = 'RequestRouting';
