import * as React from 'react';
import * as BPromise from 'bluebird';
import { AppUser } from 'Src/views/App/App';
import { parse } from 'query-string';
import { getOrder, getSearchFilter, getSearchOrder, getServerFieldSectionWhere } from 'Src/helpers/table/table';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { Loader } from 'Src/components/Loader/Loader';
import {
  ColumnDefinition,
  getFilters2,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { History, Location } from 'history';
import { Grid2 } from 'Src/components/Grid/Grid2';
import {
  createUserTag,
  deleteUserTag,
  getAllUserTags,
  getUserTags,
  getUserTagsCount,
  updateUserTag
} from 'Src/helpers/service/admin/userTags';
import { UserTagModal } from 'Src/components/UserTagModal/UserTagModal';
import { getSchoolUsersCount } from 'Src/helpers/service/admin/users';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Tag name',
    field: 'tagName',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['tagName']
  }
];

interface State {
  items: any[];
  currentPage: number;
  selectedItems: any[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;

  isUserTagModalOpen: boolean;
  userTagName: string;

  isDeleteUserTagModalOpen: boolean;
  deleteUserTagAffected: number;
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export class UserTags extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',

      isUserTagModalOpen: false,
      userTagName: '',

      isDeleteUserTagModalOpen: false,
      deleteUserTagAffected: 0
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setItems();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const getItemsPromise = getUserTags(user, serverQueryFilter);
    const getItemsCountPromise = getUserTagsCount(user, where);

    return BPromise.all([getItemsCountPromise, getItemsPromise]).then(([countObj, items]) => {
      this.setState({
        currentPage: page,
        sortDirection: sortDirection,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: items,
        total: countObj.count,
        basePath: history.location.pathname,
        isLoading: false
      });

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: nextSelectedItems,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    getAllUserTags(user, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const nextSelectedItems = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: nextSelectedItems,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: ''
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  onUserTagNameChange = (event): void => {
    const value = event.target.value;

    this.setState({
      userTagName: value
    });
  };

  onUserTagModalCloseClick = (): void => {
    this.setState({
      isUserTagModalOpen: false
    });
  };

  onUserTagSaveClick = (): void => {
    const { userTagName, selectedItems } = this.state;
    const user = this.props.user;

    const isCreate = selectedItems.length === 0;
    const isUpdate = selectedItems.length === 1;

    const data = {
      tagName: userTagName
    };

    this.setState({
      isLoading: true
    });

    switch (true) {
      case isCreate:
        {
          createUserTag(user, data).then(tag => {
            this.setState({
              isUserTagModalOpen: false,
              userTagName: ''
            });
            this.setItems();
          });
        }
        break;
      case isUpdate:
        {
          const { selectedItems } = this.state;
          const selectedItem = selectedItems[0];
          const selectedItemId = selectedItem.id;
          updateUserTag(user, selectedItemId, data).then(tag => {
            this.setState({
              isUserTagModalOpen: false,
              userTagName: '',
              selectedItems: [tag]
            });
            this.setItems();
          });
        }
        break;
      default:
        console.error('Can not save user tag');
        break;
    }
  };

  renderUserTagModal(): React.ReactNode {
    const { userTagName, isUserTagModalOpen } = this.state;

    return (
      <UserTagModal
        modalTitle={'User Tag'}
        userTagName={userTagName}
        onUserTagNameChange={this.onUserTagNameChange}
        onCloseClick={this.onUserTagModalCloseClick}
        isOpen={isUserTagModalOpen}
        onSaveClick={this.onUserTagSaveClick}
      />
    );
  }

  userTagModalOpen(): void {
    this.setState({
      isUserTagModalOpen: true
    });
  }

  onCreateUserTagClick = (): void => {
    this.userTagModalOpen();
  };

  onUpdateUserTagClick = (): void => {
    this.userTagModalOpen();
    const { selectedItems, items } = this.state;
    const selectedItem = selectedItems[0];
    const selectedItemId = selectedItem.id;
    const userTag = items.find(tag => tag.id === selectedItemId);

    this.setState({
      userTagName: userTag.tagName
    });
  };

  deleteUserTagModalOpen = (): void => {
    const user = this.props.user;

    const { selectedItems } = this.state;
    const selectedItemIds = selectedItems.map(selectedItem => selectedItem.id);

    const where = { 'permissions.tagIds': { $in: selectedItemIds } };

    getSchoolUsersCount(user, where).then(countObj => {
      this.setState({
        isDeleteUserTagModalOpen: true,
        deleteUserTagAffected: countObj.count
      });
    });
  };

  onDeleteUserTagClick = () => {
    const { selectedItems } = this.state;
    const user = this.props.user;
    const userTagIdList = selectedItems.map(item => item.id);

    this.setState({
      isLoading: true
    });

    BPromise.all(userTagIdList.map(userTagId => deleteUserTag(user, userTagId))).then(res => {
      this.setState({
        isDeleteUserTagModalOpen: false,
        deleteUserTagAffected: 0,
        selectedItems: [],
        isSelectAllChecked: false
      });
      this.setItems();
    });
  };

  deleteUserTagModalClose = (): void => {
    this.setState({
      isDeleteUserTagModalOpen: false,
      deleteUserTagAffected: 0
    });
  };

  renderDeleteUserTagModal(): React.ReactNode {
    const { isDeleteUserTagModalOpen, deleteUserTagAffected } = this.state;

    return (
      <SimpleModal
        isOpen={isDeleteUserTagModalOpen}
        title={'Delete user tag'}
        body={`Tag you are deleting is assigned with ${deleteUserTagAffected} users. Are you sure?`}
        buttonText={'Delete'}
        onButtonClick={this.onDeleteUserTagClick}
        onCloseClick={this.deleteUserTagModalClose}
      />
    );
  }

  render() {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isLoading,
      isSelectAllChecked,
      currentPage,
      total,
      isUserTagModalOpen
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes = isUserTagModalOpen ? 'mt-3 modal-open' : 'mt-3';

    const actionItems = [
      {
        itemText: 'Create user tag',
        onItemClick: this.onCreateUserTagClick,
        isActive: selectedItems.length === 0
      },
      {
        itemText: 'Update user tag',
        onItemClick: this.onUpdateUserTagClick,
        isActive: selectedItems.length === 1
      },
      {
        itemText: 'Delete user tag',
        onItemClick: this.deleteUserTagModalOpen,
        isActive: selectedItems.length > 0
      }
    ];

    const filterOptions = {};

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderUserTagModal()}
        {this.renderDeleteUserTagModal()}
        <div className="row">
          <div className="col-md-12">
            <Grid2
              dataItems={items}
              filters={filters}
              currentPage={currentPage}
              total={total}
              isSelectAllChecked={isSelectAllChecked}
              isDataFiltered={isDataFiltered}
              sortDirection={sortDirection}
              sortColumnsName={sortColumnsName}
              isShowFilter={isShowFilter}
              dataItemsSelected={selectedItems}
              columns={COLUMNS}
              actionItems={actionItems}
              options={filterOptions}
              onItemClick={this.onItemClick}
              onSortClick={this.onTableSortClick}
              onApplyFilterClick={this.onApplyFilterClick}
              onClearFilterClick={this.onClearFilterClick}
              onTableFilterChange={this.onTableFilterChange}
              onTableFilterClick={this.onTableFilterClick}
              setCurrentPageParams={this.setCurrentPageParams}
              onSelectAllClick={this.onSelectAllClick}
              onSelectAllOnPageClick={this.onSelectAllOnPageClick}
              onUnselectAllClick={this.onUnselectAllClick}
            />
          </div>
        </div>
      </div>
    );
  }
}
