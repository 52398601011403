import * as React from 'react';
import { Field, Form, Formik } from 'formik';
import { SchoolEvent } from 'Src/models/event';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/report';
import { isTeamSportEvent, isIndividualSportEvent } from 'Src/helpers/event/event';
import { Switch } from 'Src/components/Switch/Switch';
import { LabelWithQuestionIcon } from 'Src/components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import { SelectField } from 'Src/components/SelectField/SelectField';
import '../../SchoolEventView.scss';

interface Props {
  event: SchoolEvent;
  activeSchoolId: string;
  onCloseClick: () => void;
  onSubmit: (data: any, reportType: string) => void;
}

//report 043
export function EventScoreCardReportForm({ onCloseClick, onSubmit, event, activeSchoolId }: Props) {
  const { id: eventId } = event;

  const requestInitial = {
    isPrintPOM: true
  };

  return (
    <Formik
      onSubmit={values => onSubmit(values, REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.EVENT_SCORE_CARD)}
      initialValues={requestInitial}
      render={({ values, setFieldValue }) => (
        <Form>
          <div className="bReportFormTitle">A pre-filled score card for your team event</div>
          <div className="mb-3 mt-3">
            {'Print out a score card containing all the important details like start time, venue and team names'}
          </div>

          <div className="form-check mb-4">
            <div className="d-flex">
              <Field
                name="isPrintPOM"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintPOM}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintPOM = event.target.checked;
                      setFieldValue('isPrintPOM', isPrintPOM);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Keep space for adding a name of Player of the Match"
                hintText={``}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintPOM"
              />
            </div>
          </div>

          <button type="button" className="btn btn-secondary mr-3" onClick={onCloseClick}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Get
          </button>
        </Form>
      )}
    />
  );
}
