import * as React from 'react';
import * as propz from 'propz';
import MaskedInput from 'react-text-mask';
import { Component } from 'react';
import { Field, Form, Formik } from 'formik';
import DatePicker from 'react-datepicker';
import { AppUser } from 'Src/views/App/App';
import {
  getFullName,
  searchFunctionEventCoaches,
  searchFunctionEventMembersOfStaff
} from 'Src/helpers/autocomplete/autocomplete';
import { CLUB_STAFF } from 'Src/consts/club';
import { Autocomplete } from 'Src/components/Autocomplete/Autocomplete';
import { Button } from 'Src/components/Button/Button';
import { getEventDetailsCoaches, getEventDetailsMembersOfStaff } from 'Src/helpers/event/event';
import { isClubEvent } from '../../../../../../helpers/event/event';
import { SchoolEvent } from '../../../../../../models/event';
import { HTMLEditorQuillHide } from '../../../../../../components/HTMLEditorQuill/HTMLEditorQuillHide';
import { LabelWithQuestionIcon } from '../../../../../../components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import { EditorIcon } from '../../../../../../components/EditorIcon/EditorIcon';
import { CLUB_EVENT_EDIT_MODE } from '../../../../../../consts/event';
import { SchoolEventClubEventRadioButtons } from '../SchoolEventClubEventRadioButtons/SchoolEventClubEventRadioButtons';
import './SchoolEventDetailsForm.scss';

interface Props {
  user: AppUser;
  eventId: string;
  eventsDetails: any;
  onCancel: () => void;
  onSubmit: (data: any, editMode: string) => void;
  event: SchoolEvent;
}

interface State {
  membersOfStaff: any[];
  coaches: any[];
  editorActiveArray: boolean[];
  isChangeFollowingEvents: boolean;
  editMode: string;
}

export class SchoolEventDetailsForm extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      coaches: getEventDetailsCoaches(this.props.eventsDetails),
      membersOfStaff: getEventDetailsMembersOfStaff(this.props.eventsDetails),
      editorActiveArray: [false, false, false, false, false],
      isChangeFollowingEvents: false,
      editMode: CLUB_EVENT_EDIT_MODE.SINGLE
    };
  }

  getCoaches = (values, text: string) => {
    const coaches = [...this.state.coaches];
    const { user, eventId } = this.props;
    const activeSchoolId = user.activeSchoolId;
    return searchFunctionEventCoaches(user, text, activeSchoolId, coaches, eventId);
  };

  getMembersOfStaff = (values, text: string) => {
    const membersOfStaff = [...this.state.membersOfStaff];
    const { user, eventId } = this.props;
    const activeSchoolId = user.activeSchoolId;
    return searchFunctionEventMembersOfStaff(user, text, activeSchoolId, membersOfStaff, eventId);
  };

  getPermissionId(item): string {
    return typeof item.permissionId !== 'undefined' ? item.permissionId : item.permissions[0].id;
  }

  onIsChangeFollowingEventsClick = () => {
    this.setState({
      isChangeFollowingEvents: true
    });
  };

  onEditModeClick = editMode => {
    this.setState({
      editMode: editMode
    });
  };

  onSubmit(data, editMode) {
    const coachesData = this.state.coaches.map(coach => {
      const currentCoach = {
        ...coach,
        permissionId: this.getPermissionId(coach),
        staffRole: CLUB_STAFF.COACH,
        firstName: coach.firstName,
        lastName: coach.lastName
      };

      if (typeof coach.userId === 'undefined') {
        propz.set(currentCoach, ['userId'], coach.id);
      }
      return currentCoach;
    });

    const membersOfStaffData = this.state.membersOfStaff.map(memberOfStaff => {
      const currentMemberOfStaff = {
        ...memberOfStaff,
        permissionId: this.getPermissionId(memberOfStaff),
        staffRole: CLUB_STAFF.MEMBER_OF_STAFF,
        firstName: memberOfStaff.firstName,
        lastName: memberOfStaff.lastName
      };

      if (typeof memberOfStaff.userId === 'undefined') {
        propz.set(currentMemberOfStaff, ['userId'], memberOfStaff.id);
      }

      return currentMemberOfStaff;
    });

    const { coaches, membersOfStaff, ...rest } = data;
    const dataCopy = {
      ...rest,
      staff: [...coachesData, ...membersOfStaffData]
    };
    this.props.onSubmit(dataCopy, editMode);
  }

  renderCoaches(coaches, setFieldValue) {
    return coaches.map(coach => {
      return (
        <div key={coach.id} className={'d-flex'}>
          <div className="eSelectedItem">
            <span>{getFullName(coach)}</span>
            <div className="ml-1">
              <div
                className="eButtonClose"
                onClick={() => {
                  const coachesFiltered = coaches.filter(c => c.id !== coach.id);
                  setFieldValue('coaches', coachesFiltered);
                  this.setState({
                    coaches: coachesFiltered
                  });
                }}
              >
                <span>&times;</span>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  renderMembersOfStaff(membersOfStaff, setFieldValue) {
    return membersOfStaff.map(memberOfStaff => {
      return (
        <div key={memberOfStaff.id} className={'d-flex'}>
          <div className="eSelectedItem">
            <span>{getFullName(memberOfStaff)}</span>
            <div className="ml-1">
              <div
                className="eButtonClose"
                onClick={() => {
                  const membersOfStaffFiltered = membersOfStaff.filter(m => m.id !== memberOfStaff.id);
                  setFieldValue('membersOfStaff', membersOfStaffFiltered);
                  this.setState({
                    membersOfStaff: membersOfStaffFiltered
                  });
                }}
              >
                <span>&times;</span>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  onShowEditorClick = indexEditor => {
    const { editorActiveArray } = this.state;
    const isCurrentEditorActive = editorActiveArray[indexEditor];
    const currentEditorActiveArray = [...editorActiveArray];
    currentEditorActiveArray[indexEditor] = !isCurrentEditorActive;

    this.setState({
      editorActiveArray: currentEditorActiveArray
    });
  };

  isExistDate = date => {
    return typeof date !== 'undefined';
  };

  render() {
    const { eventsDetails, event } = this.props;
    const { coaches, membersOfStaff, editorActiveArray, isChangeFollowingEvents, editMode } = this.state;

    const defaultDate = new Date();
    defaultDate.setHours(0);
    defaultDate.setMinutes(0);
    const clubInitial = {
      description: propz.get(eventsDetails, ['description'], ''),
      kitNotes: propz.get(eventsDetails, ['kitNotes'], ''),
      comments: propz.get(eventsDetails, ['comments'], ''),
      transportDetails: propz.get(eventsDetails, ['transportDetails'], ''),
      oppositionContactDetails: propz.get(eventsDetails, ['oppositionContactDetails'], ''),
      coaches: coaches,
      membersOfStaff: membersOfStaff,
      teamDeparts: this.isExistDate(eventsDetails?.teamDeparts) ? new Date(eventsDetails.teamDeparts) : undefined,
      teamReturns: this.isExistDate(eventsDetails?.teamReturns) ? new Date(eventsDetails.teamReturns) : undefined,
      meetTime: this.isExistDate(eventsDetails?.meetTime) ? new Date(eventsDetails.meetTime) : undefined,
      teaTime: this.isExistDate(eventsDetails?.teaTime) ? new Date(eventsDetails.teaTime) : undefined,
      lunchTime: this.isExistDate(eventsDetails?.lunchTime) ? new Date(eventsDetails.lunchTime) : undefined
    };

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <Formik
              initialValues={clubInitial}
              validationSchema={{}}
              onSubmit={values => {
                if (isClubEvent(event) && !isChangeFollowingEvents) {
                  this.onIsChangeFollowingEventsClick();
                } else {
                  this.onSubmit(values, editMode);
                }
              }}
              render={({ touched, errors, setFieldValue, values }) => (
                <Form>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <LabelWithQuestionIcon labelText="Description" hintText="" />
                          <div className="d-flex">
                            <div className="eSchoolEventDetailsInputWith">
                              <HTMLEditorQuillHide
                                onTextChange={description => {
                                  setFieldValue('description', description);
                                }}
                                value={values.description}
                                isEnabled={editorActiveArray[0]}
                              />
                            </div>
                            <EditorIcon onClick={() => this.onShowEditorClick(0)} />
                          </div>
                        </div>

                        <div className="form-group">
                          <LabelWithQuestionIcon
                            labelText={isClubEvent(event) ? 'Clothing/Kit notes' : 'Kit notes'}
                            hintText=""
                          />
                          <div className="d-flex">
                            <div className="eSchoolEventDetailsInputWith">
                              <HTMLEditorQuillHide
                                onTextChange={kitNotes => {
                                  setFieldValue('kitNotes', kitNotes);
                                }}
                                value={values.kitNotes}
                                isEnabled={editorActiveArray[1]}
                              />
                            </div>
                            <EditorIcon onClick={() => this.onShowEditorClick(1)} />
                          </div>
                        </div>

                        <div className="form-group">
                          <LabelWithQuestionIcon labelText="Comments" hintText="" />
                          <div className="d-flex">
                            <div className="eSchoolEventDetailsInputWith">
                              <HTMLEditorQuillHide
                                onTextChange={comments => {
                                  setFieldValue('comments', comments);
                                }}
                                value={values.comments}
                                isEnabled={editorActiveArray[2]}
                              />
                            </div>
                            <EditorIcon onClick={() => this.onShowEditorClick(2)} />
                          </div>
                        </div>

                        <div className="form-group">
                          <LabelWithQuestionIcon labelText="Transport" hintText="" />
                          <div className="d-flex">
                            <div className="eSchoolEventDetailsInputWith">
                              <HTMLEditorQuillHide
                                onTextChange={transportDetails => {
                                  setFieldValue('transportDetails', transportDetails);
                                }}
                                value={values.transportDetails}
                                isEnabled={editorActiveArray[3]}
                              />
                            </div>
                            <EditorIcon onClick={() => this.onShowEditorClick(3)} />
                          </div>
                        </div>

                        <div className="form-group">
                          <LabelWithQuestionIcon labelText="Opposition contact details" hintText="" />
                          <div className="d-flex">
                            <div className="eSchoolEventDetailsInputWith">
                              <HTMLEditorQuillHide
                                onTextChange={oppositionContactDetails => {
                                  setFieldValue('oppositionContactDetails', oppositionContactDetails);
                                }}
                                value={values.oppositionContactDetails}
                                isEnabled={editorActiveArray[4]}
                              />
                            </div>
                            <EditorIcon onClick={() => this.onShowEditorClick(4)} />
                          </div>
                        </div>
                      </div>

                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-md-6">
                            <LabelWithQuestionIcon
                              labelText={isClubEvent(event) ? 'Leader/Coach' : 'Coach'}
                              hintText=""
                            />
                            <Field
                              name="coaches"
                              render={({ field }) => {
                                return (
                                  <Autocomplete
                                    searchFunction={text => this.getCoaches(values, text)}
                                    getElementTitle={getFullName}
                                    customClass="mb-3"
                                    defaultItem={undefined}
                                    onSelect={coach => {
                                      const coaches = [].concat(values.coaches, coach);
                                      setFieldValue('coaches', coaches);
                                      this.setState({
                                        coaches: coaches
                                      });
                                    }}
                                  />
                                );
                              }}
                            />
                            <div className="d-flex flex-wrap">{this.renderCoaches(values.coaches, setFieldValue)}</div>

                            <LabelWithQuestionIcon labelText="Meet time" hintText="" />
                            <Field
                              name="meetTime"
                              render={({ field }) => {
                                return (
                                  <div className="mb-3 d-flex align-items-center">
                                    <DatePicker
                                      selected={field.value}
                                      onChange={meetTime => {
                                        setFieldValue('meetTime', meetTime);
                                      }}
                                      className="form-control"
                                      showTimeSelect
                                      showTimeSelectOnly
                                      timeFormat="hh:mm aa"
                                      timeIntervals={30}
                                      timeCaption="time"
                                      dateFormat={'hh:mm aa'}
                                      placeholderText={'00:00'}
                                      customInput={
                                        <MaskedInput
                                          mask={[/\d/, /\d/, ':', /\d/, /\d/, ' ', /[APap]/, /[Mm]/]}
                                          placeholder="hh:mm"
                                          pipe={val => val.toUpperCase()}
                                        />
                                      }
                                    />
                                  </div>
                                );
                              }}
                            />

                            <LabelWithQuestionIcon
                              labelText={isClubEvent(event) ? 'Group departs' : 'Team departs'}
                              hintText=""
                            />
                            <Field
                              name="teamDeparts"
                              render={({ field }) => {
                                return (
                                  <div className="mb-3">
                                    <DatePicker
                                      selected={field.value}
                                      onChange={teamDeparts => {
                                        setFieldValue('teamDeparts', teamDeparts);
                                      }}
                                      className="form-control"
                                      showTimeSelect
                                      showTimeSelectOnly
                                      timeIntervals={5}
                                      timeCaption="Time"
                                      dateFormat={'hh:mm a'}
                                      placeholderText={'00:00'}
                                      customInput={
                                        <MaskedInput
                                          mask={[/\d/, /\d/, ':', /\d/, /\d/, ' ', /[APap]/, /[Mm]/]}
                                          placeholder="hh:mm"
                                          pipe={val => val.toUpperCase()}
                                        />
                                      }
                                    />
                                  </div>
                                );
                              }}
                            />

                            <LabelWithQuestionIcon
                              labelText={isClubEvent(event) ? 'Group returns' : 'Team returns'}
                              hintText=""
                            />
                            <Field
                              name="teamReturns"
                              render={({ field }) => {
                                return (
                                  <div className="mb-3">
                                    <DatePicker
                                      selected={field.value}
                                      onChange={teamReturns => {
                                        setFieldValue('teamReturns', teamReturns);
                                      }}
                                      className="form-control"
                                      showTimeSelect
                                      showTimeSelectOnly
                                      timeIntervals={5}
                                      timeCaption="Time"
                                      dateFormat={'hh:mm a'}
                                      placeholderText={'00:00'}
                                      customInput={
                                        <MaskedInput
                                          mask={[/\d/, /\d/, ':', /\d/, /\d/, ' ', /[APap]/, /[Mm]/]}
                                          placeholder="hh:mm"
                                          pipe={val => val.toUpperCase()}
                                        />
                                      }
                                    />
                                  </div>
                                );
                              }}
                            />
                          </div>
                          <div className="col-md-6">
                            <LabelWithQuestionIcon labelText="Member of staff" hintText="" />
                            <Field
                              name="membersOfStaff"
                              render={({ field }) => {
                                return (
                                  <Autocomplete
                                    searchFunction={text => this.getMembersOfStaff(values, text)}
                                    getElementTitle={getFullName}
                                    customClass="mb-3"
                                    defaultItem={undefined}
                                    onSelect={memberOfStaff => {
                                      const membersOfStaff = [].concat(values.membersOfStaff, memberOfStaff);
                                      setFieldValue('membersOfStaff', membersOfStaff);
                                      this.setState({
                                        membersOfStaff: membersOfStaff
                                      });
                                    }}
                                  />
                                );
                              }}
                            />
                            <div className="d-flex flex-wrap">
                              {this.renderMembersOfStaff(values.membersOfStaff, setFieldValue)}
                            </div>

                            <LabelWithQuestionIcon labelText="Lunch time" hintText="" />
                            <Field
                              name="lunchTime"
                              render={({ field }) => {
                                return (
                                  <div className="mb-3">
                                    <DatePicker
                                      selected={field.value}
                                      onChange={lunchTime => {
                                        setFieldValue('lunchTime', lunchTime);
                                      }}
                                      className="form-control"
                                      showTimeSelect
                                      showTimeSelectOnly
                                      timeIntervals={5}
                                      timeCaption="Time"
                                      dateFormat={'hh:mm aa'}
                                      placeholderText={'00:00'}
                                      customInput={
                                        <MaskedInput
                                          mask={[/\d/, /\d/, ':', /\d/, /\d/, ' ', /[APap]/, /[Mm]/]}
                                          placeholder="hh:mm"
                                          pipe={val => val.toUpperCase()}
                                        />
                                      }
                                    />
                                  </div>
                                );
                              }}
                            />

                            <LabelWithQuestionIcon labelText="Tea time" hintText="" />
                            <Field
                              name="teaTime"
                              render={({ field }) => {
                                return (
                                  <div className="mb-3">
                                    <DatePicker
                                      selected={field.value}
                                      onChange={teaTime => {
                                        setFieldValue('teaTime', teaTime);
                                      }}
                                      className="form-control"
                                      showTimeSelect
                                      showTimeSelectOnly
                                      timeIntervals={5}
                                      timeCaption="Time"
                                      dateFormat={'hh:mm a'}
                                      placeholderText={'00:00'}
                                      customInput={
                                        <MaskedInput
                                          mask={[/\d/, /\d/, ':', /\d/, /\d/, ' ', /[APap]/, /[Mm]/]}
                                          placeholder="hh:mm"
                                          pipe={val => val.toUpperCase()}
                                        />
                                      }
                                    />
                                  </div>
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="container-fluid mt-3 mb-3">
                        <div className="row">
                          <div className="col-md-12">
                            {isChangeFollowingEvents && (
                              <SchoolEventClubEventRadioButtons
                                onEditModeClick={this.onEditModeClick}
                                editMode={editMode}
                              />
                            )}
                            <Button
                              onClick={this.props.onCancel}
                              text={'Cancel'}
                              customClass={'mt-3 mb-3 mr-3 btn-secondary'}
                            />
                            <button type="submit" className="mt-3 mb-3 btn btn-primary">
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}
