import { POINTS_DISPLAY } from '../../consts/sport';

export const zeroPrint = function(number: number): string {
  if (number < 10) return `0${number}`;
  return number.toString();
};

type ConvertedPoints = {
  str: string;
  h?: number;
  min?: number;
  m?: number;
  sec?: number;
  km?: number;
  cm?: number;
};

export function convertPoints(countPoints: number, pointsType: string): ConvertedPoints {
  const zeroPrintMs = function(number: number): string {
    if (number === 0) return `00`;
    if (number < 10) return `00${number}`;
    if (number < 100) return `0${number}`;
    return number.toString();
  };

  const getTimeResult = function(countPoints: number) {
    const sec_in_hours = 3600;
    const sec_in_min = 60;
    const points = Math.floor(countPoints);
    const ms = Math.round((countPoints - points) * 1000);
    const h = Math.floor(points / sec_in_hours);
    const min = Math.floor((points - h * sec_in_hours) / sec_in_min);
    const sec = points - h * sec_in_hours - min * sec_in_min;

    let str = '';

    if (h) {
      str += h + '.';
    }

    if (min) {
      str += zeroPrint(min) + '.';
    }

    if (sec) {
      str += zeroPrint(sec) + '.';
    }

    // 1.999 -> 1.999
    // 1.990 -> 1.99
    // 1.900 -> 1.90
    // 1.090 -> 1.09
    if (ms >= 100 && ms % 10 === 0) {
      str += points === 0 ? `0.` + Math.floor(ms / 10) : Math.floor(ms / 10);
    } else if (ms > 10 && ms < 100 && ms % 10 === 0) {
      str += ms ? zeroPrint(Math.floor(ms / 10)) : '';
    } else {
      str += zeroPrintMs(ms);
    }

    if (str === `00`) {
      str = `0`;
    }

    return {
      h: h,
      min: min,
      sec: sec,
      ms: ms,
      str: str.trim()
    };
  };

  const getDistanceResult = function(countPoints: number) {
    const cm_in_km = 100000;
    const cm_in_m = 100;
    const km = Math.floor(countPoints / cm_in_km);
    const m = Math.floor((countPoints - km * cm_in_km) / cm_in_m);
    const cm = countPoints - km * cm_in_km - m * cm_in_m;

    let str = '';

    str += km ? km + '.' : '';
    str += m ? m + '.' : '';
    str += cm >= 10 ? cm : '0' + cm;

    return {
      km: km,
      m: m,
      cm: cm,
      str: str.trim()
    };
  };

  switch (pointsType) {
    case POINTS_DISPLAY.PLAIN:
      return {
        str: typeof countPoints !== 'undefined' ? countPoints.toString() : '-'
      };
    case POINTS_DISPLAY.TIME:
      return getTimeResult(countPoints);
    case POINTS_DISPLAY.DISTANCE:
      return getDistanceResult(countPoints);
    case POINTS_DISPLAY.PRESENCE_ONLY:
      return {
        str: countPoints === 1 ? 'Present' : 'Absent'
      };
  }
}
