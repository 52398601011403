export const PERMISSION_REQUEST_STATUS = {
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  NEW: 'NEW',
  ARCHIVE: 'ARCHIVE'
};

export const PERMISSION_REQUEST_STATUS_SERVER_TO_CLIENT_MAPPING = {
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected',
  NEW: 'Pending',
  ARCHIVE: 'Archive'
};

export const PERMISSION_REQUEST_ARCHiVE_STATUS_SERVER_TO_CLIENT_MAPPING = {
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected',
  ARCHIVE: 'Archive'
};
