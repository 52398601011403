import * as React from 'react';
import { Photo } from 'Src/models/photo';

interface Props {
  photo: Photo;
  albumId: string;
  onSubmit: (
    id: string,
    data: {
      name?: string;
      description?: string;
      picUrl?: string;
      accessPreset?: string;
    }
  ) => void;
}

interface State {
  name: string;
  description: string;
}

export class PhotoEdit extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    const { name, description } = this.props.photo;
    this.state = {
      name,
      description
    };
  }

  onChange = (e): void => {
    const { name, value } = e.target;

    this.setState(state => ({
      ...state,
      [name]: value
    }));
  };

  submitData = (e): void => {
    const { onSubmit, photo } = this.props;
    const { name: nameToChange, description: descriptionToChange } = this.state;

    const { name, value } = e.target;

    const { id, name: prevName, description: prevDescription } = photo;

    const isDataChanged = prevName !== nameToChange || prevDescription !== descriptionToChange;

    isDataChanged && onSubmit(id, { [name]: value });
  };

  render() {
    const { name, description } = this.state;
    return (
      <div className="bForm">
        <div className="form-group">
          <div className="eForm_fieldName">Name</div>
          <div>
            <input
              className="form-control"
              type="text"
              name="name"
              value={name}
              onChange={this.onChange}
              onBlur={this.submitData}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="eForm_fieldName">Description</div>
          <div>
            <textarea
              className="form-control"
              name="description"
              value={description}
              onChange={this.onChange}
              onBlur={this.submitData}
            />
          </div>
        </div>
      </div>
    );
  }
}
