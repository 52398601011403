import * as React from 'react';
import { Button } from '../../../../components/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  onPreviousMonthClick: () => void;
}

export function PreviousMonthArrow(props: Props) {
  const { onPreviousMonthClick } = props;
  return (
    <button className="btn ePreviousMonthArrow" onClick={onPreviousMonthClick}>
      {[<FontAwesomeIcon color="#007abf" icon="arrow-left" key={'previous_month_arrow'} />]}
    </button>
  );
}
