import * as React from 'react';
import { Component } from 'react';
import { History, Location } from 'history';
import * as Yup from 'yup';
import * as Moment from 'moment';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import { AppUser } from 'Src/views/App/App';
import { UserProfile } from '../../../../models/profile';
import { DATE_FORMAT } from '../../../../consts/date';
import { PHONE_CODE } from '../../../../consts/common';
import {
  USER_GENDER,
  GENDER_SERVER_TO_CLIENT_MAPPING,
  GENDER_NOT_DEFINED_SERVER_TO_CLIENT_MAPPING
} from '../../../../consts/user';
import { getPhoneAndCode } from '../../../../helpers/phone/phone';
import { setProfile } from 'Src/helpers/service/admin/user';
import { TriangleDown } from '../../../../components/SvgIcons/TriangleDown';
import { WarningTriangle } from '../../../../components/SvgIcons/WarningTriangle';
import { PhoneCodesList } from './PhoneCodesList';
import './ProfileSummary.scss';
import { uploadImage } from '../../../../helpers/service/image';
import { DEFAULT_LIMIT } from '../../../../consts/table';
import { getPermissions } from 'Src/helpers/service/admin/user';
import { ROLE } from '../../../../consts/user';
import { Loader } from 'Src/components/Loader/Loader';

interface Props {
  user: AppUser;
  history: History;
  location: Location;
  updateProfile: (profile: UserProfile) => void;
  setIsEditMode: (boolean) => void;
  isEditMode: boolean;
}

interface State {
  isPhoneCodeOptionsVisible: boolean;
  isLoading: boolean;
  isStudentRole: boolean;
  isLoadingPhoto: boolean;
}

const profileSummarySchema = Yup.object().shape({
  firstName: Yup.string().required('please enter your name'),
  lastName: Yup.string().required('please enter your surname'),
  birthday: Yup.date()
});

export class ProfileSummary extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isPhoneCodeOptionsVisible: false,
      isLoading: false,
      isStudentRole: false,
      isLoadingPhoto: false
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    const filter = {
      limit: DEFAULT_LIMIT,
      skip: 0
    };

    const { user } = this.props;
    let isStudentRole = false;

    getPermissions(user, filter).then(permissions => {
      permissions.forEach(permission => {
        if (permission.preset === ROLE.STUDENT) {
          isStudentRole = true;
        }
      });

      this.setState({
        isStudentRole,
        isLoading: false
      });
    });
  }

  onEditClick = (): void => {
    this.props.setIsEditMode(true);
  };

  onCancelClick = (resetForm): void => {
    resetForm();
    this.props.setIsEditMode(false);
  };

  onAvatarChange = (event, setFieldValue) => {
    const avatarFile = event.target.files[0];
    const { user } = this.props;

    this.setState({
      isLoadingPhoto: true
    });

    uploadImage(user, avatarFile).then(res => {
      const { key } = res;
      const avatarUrl = `${window.apiImg}/images/${key}`;
      setFieldValue('avatar', avatarUrl);

      this.setState({
        isLoadingPhoto: false
      });
    });
  };

  onPhoneCodeClick = () => {
    const { isPhoneCodeOptionsVisible } = this.state;
    this.setState({
      isPhoneCodeOptionsVisible: !isPhoneCodeOptionsVisible
    });
  };

  onSubmit = data => {
    const { user, updateProfile } = this.props;

    this.setState({
      isLoading: true
    });

    setProfile(user, data).then(profile => {
      updateProfile(profile);
      this.setState({
        isLoading: false
      });
      this.props.setIsEditMode(false);
    });
  };

  getPhoneCodeWithFlag = (phoneCode: string) => {
    switch (phoneCode) {
      /*      case PHONE_CODE.RU:
        return (
          <>
            <img
              src="/dist/images/flags/russian_flag.png"
              alt="Russian flag"
              className="eProfileSummaryPhoneSelectFlag"
            />
            <span className="eProfileSummaryPhoneSelectCode">{PHONE_CODE.RU}</span>
            <TriangleDown />
          </>
        );
*/
      case PHONE_CODE.UK:
        return (
          <>
            <img src="/dist/images/flags/uk_flag.png" alt="UK flag" className="eProfileSummaryPhoneSelectFlag" />
            <span className="eProfileSummaryPhoneSelectCode">{PHONE_CODE.UK}</span>
            <TriangleDown />
          </>
        );
      case PHONE_CODE.US:
        return (
          <>
            <img src="/dist/images/flags/usa_flag.png" alt="USA flag" className="eProfileSummaryPhoneSelectFlag" />
            <span className="eProfileSummaryPhoneSelectCode">{PHONE_CODE.US}</span>
            <TriangleDown />
          </>
        );
      case PHONE_CODE.AE:
        return (
          <>
            <img src="/dist/images/flags/uae_flag.png" alt="UAE flag" className="eProfileSummaryPhoneSelectFlag" />
            <span className="eProfileSummaryPhoneSelectCode">{PHONE_CODE.AE}</span>
            <TriangleDown />
          </>
        );
      case PHONE_CODE.DE:
        return (
          <>
            <img
              src="/dist/images/flags/german_flag.png"
              alt="German flag"
              className="eProfileSummaryPhoneSelectFlag"
            />
            <span className="eProfileSummaryPhoneSelectCode">{PHONE_CODE.DE}</span>
            <TriangleDown />
          </>
        );
      default:
        <>
          <div className="eProfileSummaryPhoneSelectFlag" />
          <span className="eProfileSummaryPhoneSelectCode">{''}</span>
        </>;
    }
  };

  isProfileInfoDisabled = () => {
    const { isStudentRole } = this.state;
    const { isEditMode } = this.props;

    if (isStudentRole) {
      return true;
    } else {
      return !isEditMode;
    }
  };

  render() {
    const { user, isEditMode } = this.props;
    const { profile } = user;
    const { isPhoneCodeOptionsVisible, isLoadingPhoto } = this.state;
    const { avatar, firstName, lastName, gender, birthday, email, phone } = profile;

    const isAvatarUrlExist = typeof avatar !== 'undefined' && avatar !== '';
    const isGenderExist = typeof gender !== 'undefined' && gender !== null;

    const phoneObj = getPhoneAndCode(phone);
    const { code: phoneCode, phone: phoneBody } = phoneObj;

    const profileSummaryForm = {
      avatar,
      firstName,
      lastName,
      gender: isGenderExist ? gender : '',
      birthday: birthday ? Moment(birthday) : ''
    };

    const isProfileInfoDisabled = this.isProfileInfoDisabled();

    return (
      <Formik
        initialValues={profileSummaryForm}
        validationSchema={profileSummarySchema}
        onSubmit={values => {
          const dataCopy = { ...values };

          if (dataCopy.birthday) {
            dataCopy.birthday = Moment(values.birthday).format('YYYY-MM-DD');
          } else {
            delete dataCopy.birthday;
          }

          if (!dataCopy.gender) {
            delete dataCopy.gender;
          }

          this.onSubmit(dataCopy);
        }}
      >
        {({ values, setFieldValue, errors, touched, resetForm }) => (
          <Form className="ml-3">
            <div>
              <div className="mt-3 ml-3">
                <div>Welcome to your profile!</div>
                <div>The Summary page is where you can find all of your account details.</div>
                <div>
                  You can also add your avatar photo. This will be shown when you add comments to events and other users
                  will be able to see it.
                </div>
                <div>
                  If you would like to alter your phone number or your email address please get in touch with the Squad
                  in Touch support team.
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex">
                <div className="col-md-2">
                  <div className="eProfileSummarySectionTitle">{'YOUR PHOTO'}</div>
                  <div>
                    {isLoadingPhoto ? (
                      <div className="eProfileSummaryAvatarFrame">
                        <Loader />
                      </div>
                    ) : (
                      <img
                        src={isAvatarUrlExist ? values.avatar : '/dist/images/empty_pic_uploader_box.png'}
                        className="eProfileSummaryAvatar"
                        alt="avatar"
                      />
                    )}
                  </div>
                  <input
                    type="file"
                    className="eProfileSummaryFileInput"
                    id={'profile_summary_file_input'}
                    accept=".jpg, .jpeg, .png"
                    onChange={() => this.onAvatarChange(event, setFieldValue)}
                    disabled={!isEditMode}
                  />
                  <div className="eProfileSummaryPlusIcon">
                    <label className="eProfileSummaryPlusIconText" htmlFor={'profile_summary_file_input'}>
                      {'+'}
                    </label>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="eProfileSummarySectionTitle">{'SUMMARY'}</div>

                  <div className="eProfileSummaryRowSection">
                    <div
                      className={
                        touched.firstName && errors.firstName
                          ? 'eProfileSummaryLabel mProfileSummaryLabelwithError'
                          : 'eProfileSummaryLabel'
                      }
                    >
                      {'Name'}
                    </div>
                    <ErrorMessage component="div" className="eProfileSummaryErrorText" name="firstName" />
                  </div>
                  <div className="eProfileSummaryRowSection">
                    <Field
                      type="text"
                      name="firstName"
                      disabled={isProfileInfoDisabled}
                      className={
                        touched.firstName && errors.firstName
                          ? 'eProfileSummaryInput mProfileSummaryInputWithError'
                          : 'eProfileSummaryInput'
                      }
                    />
                    {touched.firstName && errors.firstName && (
                      <div className="eProfileSummaryWarningIcon">
                        <WarningTriangle />
                      </div>
                    )}
                  </div>

                  <div className="eProfileSummaryRowSection">
                    <div
                      className={
                        touched.lastName && errors.lastName
                          ? 'eProfileSummaryLabel mProfileSummaryLabelwithError'
                          : 'eProfileSummaryLabel'
                      }
                    >
                      {'Surname'}
                    </div>
                    <ErrorMessage component="div" className="eProfileSummaryErrorText" name="lastName" />
                  </div>
                  <div className="eProfileSummaryRowSection">
                    <Field
                      type="text"
                      name="lastName"
                      disabled={isProfileInfoDisabled}
                      className={
                        touched.lastName && errors.lastName
                          ? 'eProfileSummaryInput mProfileSummaryInputWithError'
                          : 'eProfileSummaryInput'
                      }
                    />
                    {touched.lastName && errors.lastName && (
                      <div className="eProfileSummaryWarningIcon">
                        <WarningTriangle />
                      </div>
                    )}
                  </div>

                  <div className="eProfileSummaryLabel">{'Gender'}</div>
                  <Field
                    component="select"
                    name="gender"
                    className="eProfileSummaryInput"
                    disabled={isProfileInfoDisabled}
                  >
                    <option value={USER_GENDER.MALE} key={'profile_summary_gender_male'}>
                      {GENDER_SERVER_TO_CLIENT_MAPPING.MALE}
                    </option>
                    <option value={USER_GENDER.FEMALE} key={'profile_summary_gender_female'}>
                      {GENDER_SERVER_TO_CLIENT_MAPPING.FEMALE}
                    </option>
                    <option value="" key={'profile_summary_gender_not_defined'}>
                      {GENDER_NOT_DEFINED_SERVER_TO_CLIENT_MAPPING.NULL}
                    </option>
                  </Field>

                  <div className="eProfileSummaryLabel">{'Date of birth'}</div>
                  <Field
                    name="birthday"
                    render={({ field }) => {
                      //convert server field birthday
                      let date;

                      if (Moment(values.birthday, DATE_FORMAT).isValid()) {
                        date = Moment(values.birthday, DATE_FORMAT).toDate();
                      }

                      return (
                        <div className="mb-3">
                          <DatePicker
                            selected={date}
                            disabled={isProfileInfoDisabled}
                            onChange={birthdayDate => {
                              if (birthdayDate !== null) {
                                //empty date
                                setFieldValue('birthday', birthdayDate);
                              } else {
                                setFieldValue('birthday', '');
                              }
                            }}
                            className="eProfileSummaryInput"
                            dateFormat={'dd-MM-yyyy'}
                            placeholderText="DD-MM-YYYY"
                            customInput={
                              <MaskedInput mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} />
                            }
                          />
                        </div>
                      );
                    }}
                  />

                  <div className="eProfileSummarySectionTitle">{'VERIFICATION INFORMATION'}</div>

                  <div className="eProfileSummaryLabel">{'Email'}</div>
                  <input
                    type="text"
                    className="eProfileSummaryInput mProfileSummaryInputLight"
                    value={email}
                    disabled={true}
                  />

                  <div className="eProfileSummaryLabel">{'Phone number'}</div>
                  <div>
                    <div className="eProfileSummaryPhone">
                      <div className="eProfileSummaryPhoneSelect">{this.getPhoneCodeWithFlag(phoneCode)}</div>
                      <input type="text" className="eProfileSummaryPhoneInput" value={phoneBody} disabled={true} />
                    </div>
                    {isPhoneCodeOptionsVisible && <PhoneCodesList />}
                  </div>

                  <div className={'eProfileSummaryButtonSection'}>
                    {!isEditMode ? (
                      <button onClick={this.onEditClick} type={'button'} className={'eProfileSummaryButton'}>
                        {'Edit'}
                      </button>
                    ) : (
                      <>
                        <button
                          onClick={() => this.onCancelClick(resetForm)}
                          type={'button'}
                          className={'eProfileSummaryButton mProfileSummaryButtonGrey'}
                        >
                          {'Cancel'}
                        </button>
                        <button type={'submit'} className="eProfileSummaryButton">
                          {'Save'}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}
