import * as React from 'react';
import { Button } from 'Src/components/Button/Button';

interface Props {
  onApprove: () => void;
  onClose: () => void;
}

const ApproveModal = (props: Props) => {
  const { onApprove, onClose } = props;
  return (
    <div className="approve-modal">
      <p>You are about to confirm that your school is no longer taking part in this tournament. Are you sure?</p>
      <Button onClick={onApprove} text={'Yes'} customClass={'mt-3 mb-3 mr-3'} />
      <Button onClick={onClose} text={'Cancel'} customClass={'mt-3 mb-3 mr-3'} />
    </div>
  );
};

export default ApproveModal;
