import * as React from 'react';
import { EVENT_RESULTS_MODE } from 'Src/consts/event';

interface Props {
  resultsMode: string;
  isMultipartyEvent: boolean;
  onResultsModeClick: (resultsMode: string) => void;
}

export function ResultsMode(props: Props) {
  const { resultsMode, isMultipartyEvent, onResultsModeClick } = props;

  return (
    <>
      <div className={'font-weight-bold mb-2'}>Results input mode</div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="results"
          id="results"
          value={EVENT_RESULTS_MODE.RESULTS}
          checked={resultsMode === EVENT_RESULTS_MODE.RESULTS}
          disabled={isMultipartyEvent}
          onChange={() => {
            onResultsModeClick(EVENT_RESULTS_MODE.RESULTS);
          }}
        />
        <label className="form-check-label pl-3 mb-2 mt-1" htmlFor="results">
          Results (default)
        </label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="places"
          id="places"
          value={EVENT_RESULTS_MODE.PLACES}
          checked={resultsMode === EVENT_RESULTS_MODE.PLACES}
          disabled={!isMultipartyEvent}
          onChange={() => {
            onResultsModeClick(EVENT_RESULTS_MODE.PLACES);
          }}
        />
        <label className="form-check-label pl-3 mb-2 mt-1" htmlFor="places">
          Places (the team placed first is the winner)
        </label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="points"
          id="points"
          value={EVENT_RESULTS_MODE.POINTS}
          checked={resultsMode === EVENT_RESULTS_MODE.POINTS}
          disabled={!isMultipartyEvent}
          onChange={() => {
            onResultsModeClick(EVENT_RESULTS_MODE.POINTS);
          }}
        />
        <label className="form-check-label pl-3 mb-2 mt-1" htmlFor="points">
          Points (the team with the highest number of points is the winner)
        </label>
      </div>
    </>
  );
}
