import * as React from 'react';
import { Field, Form, Formik } from 'formik';
import { Autocomplete } from '../../../components/Autocomplete/Autocomplete';
import { getName, getPostcode } from '../../../helpers/autocomplete/autocomplete';
import * as Yup from 'yup';
import { getSchoolPostcode, getSchoolsPublic } from '../../../helpers/service/public/public';
import { LabelWithQuestionIcon } from '../../../components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import { getPhoneRegion } from '../../../helpers/region/region';
import { DEFAULT_SKIP } from '../../../consts/table';
import * as propz from 'propz';
import { getMainGeoSchoolFilterByParams } from '../../../helpers/venue/venue';

interface Props {
  phone: string;
  onSubmit: (data) => void;
}

interface State {
  postcode: any;
}

const studentPermissionInitial = {
  schoolId: '',
  school: undefined,
  oneOffCode: ''
};

const StudentPermissionSchema = Yup.object().shape({
  schoolId: Yup.string().required('Required')
});

export class StudentPermission extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      postcode: undefined
    };
  }

  getSchools = (text: string) => {
    const { phone } = this.props;
    const { postcode } = this.state;
    const region = getPhoneRegion(phone);

    const filter: any = {
      view: {
        type: 'id_name_pic'
      },
      where: {
        region: region,
        allowedPermissionPresets: { STUDENT: true },
        studentSelfRegistrationEnabled: { $ne: false },
        kind: { $in: ['School'] },
        name: {
          like: text,
          options: 'i'
        },
        /* this param was added later, so it is undefined on some schools. Default value is true.
         * undefined considered as 'true'. So, just checking if it is not explicitly set to false
         */
        availableForRegistration: { $ne: false }
      },
      limit: 10,
      skip: DEFAULT_SKIP
    };

    if (typeof postcode !== 'undefined') {
      const point = propz.get(postcode, ['point']);
      propz.set(filter, ['where', 'postcode.point'], getMainGeoSchoolFilterByParams(0, point));
    } else {
      propz.set(filter, ['order'], 'name ASC');
    }

    return getSchoolsPublic(filter);
  };

  getPostcodes = (text: string) => {
    const { phone } = this.props;
    const region = getPhoneRegion(phone);
    const postcodesFilter = {
      where: {
        region: region,
        postcode: {
          like: text,
          options: 'i'
        }
      },
      limit: 10,
      skip: DEFAULT_SKIP
    };

    return getSchoolPostcode(postcodesFilter);
  };

  render() {
    const { onSubmit } = this.props;
    const { postcode } = this.state;

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className={'font-weight-bold'}>Choose a school</div>
            <Formik
              initialValues={studentPermissionInitial}
              validationSchema={StudentPermissionSchema}
              onSubmit={onSubmit}
              render={({ touched, errors, setFieldValue, values }) => (
                <Form>
                  <LabelWithQuestionIcon labelText="Postcode" hintText="" />
                  <div className="form-row">
                    <div className="form-group col-md-11">
                      <Autocomplete
                        searchFunction={this.getPostcodes}
                        getElementTitle={getPostcode}
                        customClass="mFullWidth mb-3"
                        defaultItem={postcode}
                        onSelect={postcodeNext => {
                          this.setState({ postcode: postcodeNext });
                        }}
                        placeholder={'Please type your postcode'}
                      />
                    </div>
                    <div className="form-group col-md-1 text-right">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => {
                          this.setState({ postcode: undefined });
                        }}
                      >
                        X
                      </button>
                    </div>
                  </div>

                  <LabelWithQuestionIcon labelText="School" hintText="" />
                  <Field
                    name="schoolId"
                    render={({ field }) => {
                      return (
                        <Autocomplete
                          searchFunction={this.getSchools}
                          getElementTitle={getName}
                          customClass="mFullWidth mb-3"
                          defaultItem={values.school}
                          onSelect={school => {
                            setFieldValue('school', school);
                            setFieldValue('schoolId', school.id);
                            setFieldValue('oneOffCode', '');
                          }}
                          placeholder={'Please select school'}
                          isItemWithImage = {true}
                        />
                      );
                    }}
                  />
                  {touched.schoolId && errors.schoolId && <div className="alert alert-danger">{errors.schoolId}</div>}

                  {values.school && values.school.isOneOffCodeOnRegisterEnabled && (
                    <div className="form-group">
                      <label>If the school provided you with a unique code please enter it here</label>
                      <Field name="oneOffCode" className={'form-control mb-3'} />
                    </div>
                  )}

                  <button type="submit" className="mt-3 mb-3 btn btn-primary">
                    Save
                  </button>
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}
