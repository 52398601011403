import { AbsenceMessageActionType } from '../types/message';

export const MESSAGE_KIND_SERVER_TO_CLIENT_MAPPING: {
  EventParticipationMessage: string;
  AbsenceMessage: string;
  EventInvitationMessage: string;
  ClubParticipantInviteMessage: string;
  EventParticipationRefusalMessage: string;
  TournamentConfirmationRequestMessage: string;
} = {
  EventParticipationMessage: 'Availability report',
  AbsenceMessage: 'Absence request',
  EventInvitationMessage: 'Parental consent request',
  ClubParticipantInviteMessage: 'Club invitation',
  EventParticipationRefusalMessage: 'Availability report (deprecated)',
  TournamentConfirmationRequestMessage: 'Tournament confirmation request'
};

export const MESSAGE_KIND: {
  EventInvitationMessage: string;
  EventParticipationMessage: string;
  ClubParticipantInviteMessage: string;
  AbsenceMessage: string;
  EventParticipationRefusalMessage: string;
  TournamentConfirmationRequestMessage: string;
} = {
  EventInvitationMessage: 'EventInvitationMessage',
  EventParticipationMessage: 'EventParticipationMessage',
  ClubParticipantInviteMessage: 'ClubParticipantInviteMessage',
  AbsenceMessage: 'AbsenceMessage',
  EventParticipationRefusalMessage: 'EventParticipationRefusalMessage',
  TournamentConfirmationRequestMessage: 'TournamentConfirmationRequestMessage'
};

export const ABSENCE_MESSAGE_ACTIONS: {
  Authorised: AbsenceMessageActionType;
  Unauthorised: AbsenceMessageActionType;
} = {
  Authorised: 'Authorised',
  Unauthorised: 'Unauthorised'
};

export const MESSAGE_ACTION_SEEN = 'Seen';

export const MESSAGE_TABS = ['Summary', 'Additional info'];

export const CONFIRMATION_REQUESTS_STATUS = {
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  NOT_READY: 'NOT_READY'
};

export const CONFIRMATION_REQUESTS_STATUS_SERVER_TO_CLIENT_MAPPING = {
  ACCEPTED: 'Accepted',
  REJECTED: 'Declined',
  NOT_READY: 'Pending'
};

export const CLUB_PARTICIPATION_MESSAGE_INVITATION_STATUS = {
  ACCEPTED: 'ACCEPTED', // those who received invite accepted sender's invitation
  REJECTED: 'REJECTED', // those who received invite rejected sender's invitation
  PREBOOKED: 'PREBOOKED', // invite booked. still await final status ACCEPTED or REJECTED
  PENDING: 'PENDING', // no decision made on this invite
  OUTDATED: 'OUTDATED', // invite was mark as outdated by system cron script
  NOT_SENT: 'NOT_SENT', // invite was not sent by sender
  WAITING_LIST: 'WAITING_LIST', // those who received invite wants to pay, but no places are available in club
  WAITING_LIST_NOT_BOOKED: 'WAITING_LIST_NOT_BOOKED',
  WAITING_LIST_CAN_BOOK: 'WAITING_LIST_CAN_BOOK',
  AUTHORISING: 'AUTHORISING', // those who received invite go to stripe
  REFUNDED: 'REFUNDED'
};

export const MESSAGE_INVITATION_STATUS_SERVER_TO_CLIENT_MAPPING = {
  ACCEPTED: 'Accepted', // those who received invite accepted sender's invitation
  REJECTED: 'Declined', // those who received invite rejected sender's invitation
  PREBOOKED: 'Prebooked', // invite booked. still await final status ACCEPTED or REJECTED
  PENDING: 'Pending', // no decision made on this invite
  OUTDATED: 'Outdated', // invite was mark as outdated by system cron script
  NOT_SENT: 'Not sent', // invite was not sent by sender
  GOT_IT: 'Got it',
  NOT_READY: 'Pending',
  WAITING_LIST: 'Waiting list', // those who received invite wants to pay, but no places are available in club
  WAITING_LIST_NOT_BOOKED: 'Waiting list not booked',
  WAITING_LIST_CAN_BOOK: 'Waiting list can book',
  AUTHORISING: 'Authorising', // those who received invite go to stripe
  REFUNDED: 'Refunded'
};

export const MESSAGE_TYPE = {
  INBOX: 'INBOX',
  OUTBOX: 'OUTBOX',
  ARCHIVE: 'ARCHIVE'
};

export const MESSAGE_INVITATION_STATUS = {
  NOT_SENT: 'NOT_SEND',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  NOT_READY: 'NOT_READY',
  OUTDATED: 'OUTDATED',
  CANCELED: 'CANCELED',
  GOT_IT: 'GOT_IT',
  WAITING_LIST_CAN_BOOK: 'WAITING_LIST_CAN_BOOK'
};

export const MESSAGE_INVITATION_ACTION_TYPE = {
  ACCEPT: 'ACCEPT',
  DECLINE: 'DECLINE',
  GOT_IT: 'GOT_IT',
  PREBOOKED: 'PREBOOKED'
};

export const QUESTIONS_TEMPLATE_TYPE = {
  STRING: 'STRING',
  NUMBER: 'NUMBER',
  BOOLEAN: 'BOOLEAN',
  ENUM: 'ENUM'
};

export const QUESTIONS_TEMPLATE_TYPE_SERVER_TO_CLIENT_MAPPING = {
  STRING: 'String',
  NUMBER: 'Number',
  BOOLEAN: 'Boolean',
  ENUM: 'Enum'
};
