import * as React from 'react';
import * as propz from 'propz';
import { Component } from 'react';
import * as Lazy from 'lazy.js';
import * as BPromise from 'bluebird';
import { Field, Form, Formik } from 'formik';
import { Button } from '../Button/Button';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import {
  ABSENCE_MESSAGE_INTERVAL,
  ABSENCE_MESSAGE_INTERVAL_SERVER_TO_CLIENT_MAPPING,
  ABSENCE_MESSAGE_REASON,
  ABSENCE_MESSAGE_REASON_SERVER_TO_CLIENT_MAPPING
} from 'Src/consts/absenceMessage';
import { SelectOption } from '../../helpers/table/table';
import { AppUser } from 'Src/views/App/App';
import { Role } from '../../models/roles';
import { ROLE } from '../../consts/user';
import * as Yup from 'yup';
import { getChild } from '../../helpers/service/parent/children';
import { getSelectOptionsForSchools } from '../../helpers/table/select';
import { Loader } from '../Loader/Loader';

interface Props {
  onSubmit: (data: any) => void;
  onCancel: () => void;
  user: AppUser;
  availableRoles: Role[];
}

interface State {
  children: any[];
  schoolIds: any[];
  isLoading: boolean;
}

const AbsenceMessageSchema = Yup.object().shape({
  schoolId: Yup.string().required('Required'),
  userId: Yup.string().required('Required'),
  reasonAbsence: Yup.string().required('Required')
});

export class AbsenceMessageForm extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      children: [],
      schoolIds: [],
      isLoading: false
    };
  }

  componentDidMount() {
    const { availableRoles, user } = this.props;

    const schoolIds = Lazy(availableRoles)
      .filter(role => role.name === ROLE.PARENT)
      .map(role => role.permissions)
      .flatten()
      .filter(permission => {
        const isStudentIdExist = typeof permission.studentId !== 'undefined';
        const isAbsenceRequestEnabled = propz.get(permission, ['school', 'isAbsenceRequestEnabled'], false);

        return isStudentIdExist && isAbsenceRequestEnabled;
      })
      .map(permission => permission.school.id)
      .uniq()
      .toArray();

    this.setState({
      isLoading: true,
      schoolIds: schoolIds
    });

    const childIds = Lazy(availableRoles)
      .filter(role => role.name === ROLE.PARENT)
      .map(role => role.permissions)
      .flatten()
      .filter(permission => {
        const isStudentIdExist = typeof permission.studentId !== 'undefined';
        const isSchoolIdEqual = schoolIds.some(schoolId => permission.schoolId === schoolId);

        return isStudentIdExist && isSchoolIdEqual;
      })
      .map(permission => permission.studentId)
      .toArray();

    BPromise.all(childIds.map(childId => getChild(user, childId))).then(children => {
      this.setState({
        children: children,
        isLoading: false
      });
    });
  }

  renderSchoolOptions(options: SelectOption[]): React.ReactNode {
    let result = options.map(option => {
      return (
        <option key={option.value} value={option.value}>
          {option.text}
        </option>
      );
    });

    //add empty option
    result.unshift(
      <option value={''} key={`absent_message_form_school_empty`}>
        Select school
      </option>
    );

    return result;
  }

  renderChildrenOptions(schoolId): React.ReactNode {
    const { children } = this.state;

    const childrenFiltered = children
      .filter(child => child.schoolId === schoolId)
      .map(child => ({
        text: `${child.firstName} ${child.lastName}`,
        value: child.id
      }));

    let result = childrenFiltered.map(option => (
      <option key={option.value} value={option.value}>
        {option.text}
      </option>
    ));

    //add empty option
    result.unshift(
      <option value={''} key={`absent_message_form_children_empty`}>
        Select child
      </option>
    );

    return result;
  }

  getPermissionIdByUserId(userId: string): string {
    const { children } = this.state;
    const child = children.find(child => child.id === userId);
    if (typeof child !== 'undefined') {
      return child.permissionId;
    } else {
      console.error(`Can not find child with id: ${userId}`);
      return '';
    }
  }

  render() {
    const { schoolIds, children, isLoading } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    if (schoolIds.length === 0) {
      return (
        <>
          <p>
            Unfortunately, absence requests are not enabled on your account, please get in touch with your school
            directly to learn more.
          </p>
          <Button onClick={this.props.onCancel} text={'Close'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
        </>
      );
    }

    const { availableRoles } = this.props;
    const isOneSchool = schoolIds.length === 1;
    const isOneChild = children.length === 1;

    const initialValues = {
      schoolId: isOneSchool ? schoolIds[0] : '',
      userId: isOneChild ? children[0].id : '',
      permissionId: isOneChild ? children[0].permissionId : '',
      details: '',
      dateAbsence: new Date(),
      intervalAbsence: ABSENCE_MESSAGE_INTERVAL.FULL,
      reasonAbsence: ''
    };

    const schoolsSelectOptions = getSelectOptionsForSchools(availableRoles);

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <Formik
              initialValues={initialValues}
              validationSchema={AbsenceMessageSchema}
              onSubmit={values => {
                const { userId, permissionId, ...rest } = values;

                const dataToSubmit = {
                  ...rest,
                  playerDetails: {
                    userId: userId,
                    permissionId: permissionId
                  }
                };

                this.props.onSubmit(dataToSubmit);
              }}
              render={({ touched, errors, setFieldValue, values }) => (
                <Form>
                  <label>School</label>
                  <Field
                    disabled={isOneSchool}
                    component="select"
                    name="schoolId"
                    className="form-control mb-3"
                    onChange={event => {
                      const schoolId = event.target.value;
                      setFieldValue('schoolId', schoolId);
                      setFieldValue('userId', '');
                      setFieldValue('permissionId', '');
                    }}
                  >
                    {this.renderSchoolOptions(schoolsSelectOptions)}
                  </Field>
                  {touched.schoolId && errors.schoolId ? (
                    <div className="alert alert-danger">{errors.schoolId}</div>
                  ) : null}

                  <label>Child</label>
                  <Field
                    disabled={!values.schoolId || isOneChild}
                    component="select"
                    name="userId"
                    className="form-control mb-3"
                    onChange={event => {
                      const userId = event.target.value;
                      const permissionId = this.getPermissionIdByUserId(userId);
                      setFieldValue('userId', userId);
                      setFieldValue('permissionId', permissionId);
                    }}
                  >
                    {this.renderChildrenOptions(values.schoolId)}
                  </Field>
                  {touched.userId && errors.userId ? <div className="alert alert-danger">{errors.userId}</div> : null}

                  <label>Date</label>
                  <Field
                    name="dateAbsence"
                    render={({ field }) => {
                      return (
                        <div className="mb-3">
                          <DatePicker
                            selected={field.value}
                            minDate={new Date()}
                            onChange={dateAbsence => {
                              setFieldValue('dateAbsence', dateAbsence);
                            }}
                            className="form-control"
                            dateFormat={'dd-MM-yyyy'}
                            customInput={
                              <MaskedInput
                                mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                placeholder="DD-MM-YYYY"
                              />
                            }
                          />
                        </div>
                      );
                    }}
                  />

                  <label>Interval</label>
                  <Field component="select" name="intervalAbsence" className="form-control mb-3">
                    <option value={ABSENCE_MESSAGE_INTERVAL.FULL}>
                      {ABSENCE_MESSAGE_INTERVAL_SERVER_TO_CLIENT_MAPPING.FULL}
                    </option>
                    <option value={ABSENCE_MESSAGE_INTERVAL.HALF}>
                      {ABSENCE_MESSAGE_INTERVAL_SERVER_TO_CLIENT_MAPPING.HALF}
                    </option>
                  </Field>

                  <label>Reason for absence</label>
                  <Field component="select" name="reasonAbsence" className="form-control mb-3">
                    <option value={''}>Please select</option>
                    <option value={ABSENCE_MESSAGE_REASON.ILLNESS}>
                      {ABSENCE_MESSAGE_REASON_SERVER_TO_CLIENT_MAPPING.ILLNESS}
                    </option>
                    <option value={ABSENCE_MESSAGE_REASON.MEDICAL_APPOINTMENT}>
                      {ABSENCE_MESSAGE_REASON_SERVER_TO_CLIENT_MAPPING.MEDICAL_APPOINTMENT}
                    </option>
                    <option value={ABSENCE_MESSAGE_REASON.OTHER_REASON}>
                      {ABSENCE_MESSAGE_REASON_SERVER_TO_CLIENT_MAPPING.OTHER_REASON}
                    </option>
                  </Field>

                  {touched.reasonAbsence && errors.reasonAbsence ? (
                    <div className="alert alert-danger">{errors.reasonAbsence}</div>
                  ) : null}

                  <label>Details</label>
                  <Field component="textarea" name="details" rows="3" className="form-control mb-3" />

                  <Button onClick={this.props.onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                  <button type="submit" className="mt-3 mb-3 btn btn-primary">
                    Submit
                  </button>
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}
