import * as React from 'react';
import * as propz from 'propz';
import { FunctionComponent } from 'react';
import * as classNames from 'classnames';

interface Props {
  onCloseClick: () => void;
  onAcceptClick: () => void;
  isOpen: boolean;
  request: any;
}

export const AcceptRequestModal: FunctionComponent<Props> = props => {
  if (!props.isOpen) {
    return null;
  }

  const modalClasses = classNames({
    mDisplayBlock: props.isOpen,
    show: props.isOpen,
    modal: true,
    fade: true
  });

  const request = props.request;
  const email = propz.get(request, ['requester', 'email'], '');
  const phone = propz.get(request, ['requester', 'phone'], '');

  const title = 'Please Read Carefully!';

  const getConfirmMessage = (email: string, phone: string) => {
    return (
      <div>
        <p className="">
          We use reasonable measures to check the identity of each User registering with Squad In Touch. We require that
          each User provides a valid mobile phone number and email address so we check their validity via confirmation
          codes.
        </p>

        <p className="">The Mobile phone and email address the User has verified is as follows:</p>

        <p className="text-primary">{email}</p>
        <p className="text-primary">{phone}</p>

        <p className="">
          Notwithstanding mobile phone and email address verification, it is the responsibility of the School to satisfy
          that the User’s identity has been verified prior to accepting a role request. If you are not completely
          satisfied the User is genuine, please complete additional checks before granting them any permissions in the
          system or simply decline a role request.
        </p>
      </div>
    );
  };

  return (
    <div className={modalClasses}>
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button type="button" className="close" onClick={props.onCloseClick}>
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body">{getConfirmMessage(email, phone)}</div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={props.onCloseClick}>
              Close
            </button>
            <button type="button" className="btn btn-primary" onClick={props.onAcceptClick}>
              Accept
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

AcceptRequestModal.displayName = 'AcceptRequestModal';
