import * as React from 'react';
import { SchoolEvent, SchoolEventIndividualData } from 'Src/models/event';
import { isEventStatusFinished } from '../../../../../../helpers/event/event';
import {
  convertPointsToStringWithoutDelimeter,
  getExtraPointsByStudent,
  getIndividualScoreByStudent,
  getAbbreviationResultByStudent,
  getAbbreviationColor
} from '../../../../../../helpers/event/schoolEventViewHelper';

interface Props {
  players: SchoolEventIndividualData[];
  event: SchoolEvent;
}

export function SchoolEventIndividualPlayers(props: Props) {
  const { players, event } = props;

  const hasPositiveExtraPoints = players.some(player => {
    const extraPoints = getExtraPointsByStudent(event, player.userId);
    return extraPoints > 0;
  });

  const rows = players.map((player, playerIndex) => {
    const { userId, permissionId } = player;
    const playerPoints = getIndividualScoreByStudent(event, userId, permissionId);
    const playerPointsStr = convertPointsToStringWithoutDelimeter(event, playerPoints);
    const extraPoints = getExtraPointsByStudent(event, userId);
    const extraPointsStr = String(extraPoints);
    const abbreviationResult = getAbbreviationResultByStudent(event, userId, permissionId) || '';
    const abbreviationColor = abbreviationResult ? getAbbreviationColor(event, abbreviationResult) : '';
    const isEventFinished = isEventStatusFinished(event);
    const isShowPoints = isEventFinished || Boolean(playerPoints);
    const isShowExtraPoints = isEventFinished || Boolean(extraPoints);

    return (
      <tr key={`school_players_${player.id}`}>
        <td style={{ width: '5%' }}>{playerIndex + 1}</td>
        <td style={{ width: '25%' }}>{player.firstName}</td>
        <td style={{ width: '25%' }}>{player.lastName}</td>
        <td style={{ width: '25%' }}>{isShowPoints && <span className="font-weight-bold">{playerPointsStr}</span>}</td>
        <td style={{ width: '10%' }}>
          {isShowExtraPoints && hasPositiveExtraPoints && <span className="font-weight-bold">{extraPointsStr}</span>}
        </td>
        <td style={{ width: '10%', color: abbreviationColor }}>
          {abbreviationResult && <span className="font-weight-bold">{abbreviationResult}</span>}
        </td>
      </tr>
    );
  });

  return (
    <table className="w-100">
      <tbody>{rows}</tbody>
    </table>
  );
}
