import * as React from 'react';
import * as BPromise from 'bluebird';
import { AppUser } from 'Src/views/App/App';
import { parse } from 'query-string';
import { getOrder, getSearchFilter, getSearchOrder, getServerFieldSectionWhere } from 'Src/helpers/table/table';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { Loader } from 'Src/components/Loader/Loader';
import {
  ColumnDefinition,
  getFilters2,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { History, Location } from 'history';
import { Grid2 } from 'Src/components/Grid/Grid2';
import { getIntegrationType } from 'Src/helpers/accessor/accessor';
import {
  createIntegrationICalendar,
  deleteIntegration,
  getAllIntegrations,
  getCalendarsOfIntegration,
  getIntegrations,
  getIntegrationsCount,
  getUrlToIntegrationGoogleCalendar,
  getUrlToIntegrationOutlook,
  getUrlToIntegrationTwitter,
  selectIntegrationCalendar,
  syncIntegrationCalendar,
  syncIntegrationICalendar,
  syncOffIntegrationCalendar,
  syncOffIntegrationICalendar
} from 'Src/helpers/service/admin/integrations';
import { Integration } from 'Src/models/Integration';
import { getIntegrationName } from 'Src/helpers/cell/cell';
import { IntegrationAddModal } from 'Src/components/IntegrationAddModal/IntegrationAddModal';
import { INTEGRATION_TYPE, INTEGRATION_TYPE_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/integration';
import { SimpleAlert } from 'Src/components/SimpleAlert/SimpleAlert';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { IntegrationEditModal } from 'Src/components/IntegrationEditModal/IntegrationEditModal';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Integration',
    field: 'type',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getIntegrationType
  },
  {
    text: 'Name',
    field: 'name',
    isSort: false,
    type: FILTER_TYPE.NONE,
    cell: getIntegrationName
  }
];

interface State {
  items: Integration[];
  currentPage: number;
  selectedItems: Integration[];
  calendars: [];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;

  isAddIntegrationModalOpen: boolean;
  isSyncIntegrationModalOpen: boolean;
  isSyncOffIntegrationModalOpen: boolean;
  isEditIntegrationModalOpen: boolean;
  isError: boolean;
  errorMessage: string;
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export class Integrations extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      calendars: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',

      isAddIntegrationModalOpen: false,
      isSyncIntegrationModalOpen: false,
      isSyncOffIntegrationModalOpen: false,
      isEditIntegrationModalOpen: false,
      isError: false,
      errorMessage: ''
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setItems().then(res => {
      this.setState({
        isLoading: false
      });
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems().then(res => {
        this.setState({
          isLoading: false
        });
      });
    }
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const getItemsPromise = getIntegrations(user, serverQueryFilter);
    const getItemsCountPromise = getIntegrationsCount(user, where);

    const promises = [getItemsCountPromise, getItemsPromise];

    return BPromise.all(promises).then(([countObj, items]) => {
      this.setState({
        currentPage: page,
        sortDirection: sortDirection,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: items,
        total: countObj.count,
        basePath: history.location.pathname,
        isLoading: false
      });

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: nextSelectedItems,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    getAllIntegrations(user, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const nextSelectedItems = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: nextSelectedItems,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: ''
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  onCloseAddIntegrationModal = () => {
    this.setState({
      isAddIntegrationModalOpen: false
    });
  };

  onCloseSyncIntegrationModalClick = () => {
    this.setState({
      isSyncIntegrationModalOpen: false
    });
  };

  onCloseSyncOffIntegrationModalClick = () => {
    this.setState({
      isSyncOffIntegrationModalOpen: false
    });
  };

  onCloseEditIntegrationModalClick = () => {
    this.setState({
      isEditIntegrationModalOpen: false
    });
  };

  selectIntegrationCalendarClick = selectedCalendarIds => {
    const user = this.props.user;
    const { selectedItems } = this.state;
    const integrationId = selectedItems[0].id;
    this.setState({
      isLoading: true
    });

    if (selectedItems.length === 1) {
      selectIntegrationCalendar(user, integrationId, selectedCalendarIds)
        .then(empty => {
          this.setState({
            isEditIntegrationModalOpen: false,
            selectedItems: []
          });
          this.setItems();
        })
        .catch(error => {
          this.setState({
            isLoading: false
          });
          console.error(`Error: ${error.message}`);
          this.onShowError(error.message);
        });
    }
  };

  deletedIntegrationClick = () => {
    const user = this.props.user;
    const { selectedItems } = this.state;
    const integrationId = selectedItems[0].id;
    this.setState({
      isLoading: true
    });

    if (selectedItems.length === 1) {
      deleteIntegration(user, integrationId)
        .then(empty => {
          this.setState({
            selectedItems: []
          });
          this.setItems();
        })
        .catch(error => {
          this.setState({
            isLoading: false
          });
          console.error(`Error: ${error.message}`);
          this.onShowError(error.message);
        });
    }
  };

  addIntegrationClick = (event, type) => {
    const user = this.props.user;
    this.setState({
      isLoading: true,
      isAddIntegrationModalOpen: false
    });
    switch (true) {
      case type === INTEGRATION_TYPE.I_CALENDAR:
        createIntegrationICalendar(user)
          .then(empty => {
            this.setItems();
          })
          .catch(error => {
            this.setState({
              isLoading: false
            });
            console.error(`Error: ${error.message}`);
            this.onShowError(error.message);
          });
        break;
      case type === INTEGRATION_TYPE.GOOGLE_CALENDAR:
        getUrlToIntegrationGoogleCalendar(user)
          .then(url => {
            this.setState({
              isLoading: true
            });
            window.location.href = url.url;
          })
          .catch(error => {
            this.setState({
              isLoading: false
            });
            console.error(`Error: ${error.message}`);
            this.onShowError(error.message);
          });
        break;
      case type === INTEGRATION_TYPE.TWITTER:
        getUrlToIntegrationTwitter(user)
          .then(url => {
            this.setState({
              isLoading: true
            });
            window.location.href = url.url;
          })
          .catch(error => {
            this.setState({
              isLoading: false
            });
            console.error(`Error: ${error.message}`);
            this.onShowError(error.message);
          });
        break;
      case type === INTEGRATION_TYPE.OUTLOOK:
        getUrlToIntegrationOutlook(user)
          .then(url => {
            this.setState({
              isLoading: true
            });
            window.location.href = url.url;
          })
          .catch(error => {
            this.setState({
              isLoading: false
            });
            console.error(`Error: ${error.message}`);
            this.onShowError(error.message);
          });
        break;
    }
  };

  syncIntegrationClick = () => {
    const user = this.props.user;
    const { selectedItems } = this.state;
    const selectedItem = selectedItems[0];
    const integrationType = selectedItem.type;
    const integrationId = selectedItem.id;

    this.setState({
      isLoading: true,
      isSyncIntegrationModalOpen: false
    });

    switch (true) {
      case integrationType === INTEGRATION_TYPE_SERVER_TO_CLIENT_MAPPING.iCalendar:
        syncIntegrationICalendar(user, integrationId)
          .then(empty => {
            this.setItems();
          })
          .catch(error => {
            this.setState({
              isLoading: false
            });
            console.error(`Error: ${error.message}`);
            this.onShowError(error.message);
          });
        break;
      case integrationType === INTEGRATION_TYPE.GOOGLE_CALENDAR:
        syncIntegrationCalendar(user, integrationId)
          .then(empty => {
            this.setItems();
          })
          .catch(error => {
            this.setState({
              isLoading: false
            });
            console.error(`Error: ${error.message}`);
            this.onShowError(error.message);
          });
        break;
      case integrationType === INTEGRATION_TYPE.TWITTER:
        this.onShowError('This action is not available');
        break;
      case integrationType === INTEGRATION_TYPE.OUTLOOK:
        syncIntegrationCalendar(user, integrationId)
          .then(empty => {
            this.setItems();
          })
          .catch(error => {
            this.setState({
              isLoading: false
            });
            console.error(`Error: ${error.message}`);
            this.onShowError(error.message);
          });
        break;
    }
  };

  syncOffIntegrationClick = () => {
    const user = this.props.user;
    const { selectedItems } = this.state;
    const selectedItem = selectedItems[0];
    const integrationType = selectedItem.type;
    const integrationId = selectedItem.id;

    this.setState({
      isLoading: true,
      isSyncOffIntegrationModalOpen: false
    });

    switch (true) {
      case integrationType === INTEGRATION_TYPE_SERVER_TO_CLIENT_MAPPING.iCalendar:
        syncOffIntegrationICalendar(user, integrationId)
          .then(empty => {
            this.setItems();
          })
          .catch(error => {
            this.setState({
              isLoading: false
            });
            console.error(`Error: ${error.message}`);
            this.onShowError(error.message);
          });
        break;
      case integrationType === INTEGRATION_TYPE.GOOGLE_CALENDAR:
        syncOffIntegrationCalendar(user, integrationId)
          .then(empty => {
            this.setItems();
          })
          .catch(error => {
            this.setState({
              isLoading: false
            });
            console.error(`Error: ${error.message}`);
            this.onShowError(error.message);
          });
        break;
      case integrationType === INTEGRATION_TYPE.TWITTER:
        this.onShowError('This action is not available');
        break;
      case integrationType === INTEGRATION_TYPE.OUTLOOK:
        syncOffIntegrationCalendar(user, integrationId)
          .then(empty => {
            this.setItems();
          })
          .catch(error => {
            this.setState({
              isLoading: false
            });
            console.error(`Error: ${error.message}`);
            this.onShowError(error.message);
          });
        break;
    }
  };

  getIntegrationClick = () => {
    const user = this.props.user;
    const { selectedItems } = this.state;
    const selectedItem = selectedItems[0];
    const integrationId = selectedItem.id;
    const isTwitterOrICalendarIntegration =
      selectedItem.type === INTEGRATION_TYPE.TWITTER || selectedItem.type === INTEGRATION_TYPE.I_CALENDAR;

    if (isTwitterOrICalendarIntegration) {
      this.setState({
        isEditIntegrationModalOpen: true
      });
    } else {
      this.setState({
        isLoading: true
      });
      getCalendarsOfIntegration(user, integrationId)
        .then(calendars => {
          this.setState({
            calendars: calendars,
            isLoading: false,
            isEditIntegrationModalOpen: true
          });
        })
        .catch(error => {
          this.setState({
            isLoading: false
          });
          console.error(`Error: ${error.message}`);
          this.onShowError(error.message);
        });
    }
  };

  renderAddIntegrationModal() {
    const { isAddIntegrationModalOpen, items } = this.state;

    return (
      <IntegrationAddModal
        items={items}
        isOpen={isAddIntegrationModalOpen}
        onCloseClick={this.onCloseAddIntegrationModal}
        onButtonClick={this.addIntegrationClick}
      />
    );
  }

  renderSyncIntegrationModal() {
    const { isSyncIntegrationModalOpen, selectedItems } = this.state;

    if (selectedItems.length !== 1) {
      return null;
    }

    const selectedItem = selectedItems[0];
    const isTwitterIntegration = selectedItem.type === INTEGRATION_TYPE.TWITTER;
    const infoText = isTwitterIntegration ? 'This action is not available' : `Are you sure you want to sync calendar?`;

    return (
      <SimpleModal
        isOpen={isSyncIntegrationModalOpen}
        title={'Sync calendar'}
        body={infoText}
        buttonText={isTwitterIntegration ? undefined : `Yes`}
        onButtonClick={this.syncIntegrationClick}
        onCloseClick={this.onCloseSyncIntegrationModalClick}
      />
    );
  }

  renderSyncOffIntegrationModal() {
    const { isSyncOffIntegrationModalOpen, selectedItems } = this.state;

    if (selectedItems.length !== 1) {
      return null;
    }

    const selectedItem = selectedItems[0];
    const isTwitterIntegration = selectedItem.type === INTEGRATION_TYPE.TWITTER;
    const infoText = isTwitterIntegration
      ? 'This action is not available'
      : `Are you sure you want to remove all synced events in calendar?`;

    return (
      <SimpleModal
        isOpen={isSyncOffIntegrationModalOpen}
        title={'Sync off calendar'}
        body={infoText}
        buttonText={isTwitterIntegration ? undefined : `Yes`}
        onButtonClick={this.syncOffIntegrationClick}
        onCloseClick={this.onCloseSyncOffIntegrationModalClick}
      />
    );
  }

  renderEditIntegrationModal() {
    const { isEditIntegrationModalOpen, selectedItems, calendars } = this.state;

    if (selectedItems.length !== 1) {
      return null;
    }

    const selectedItem = selectedItems[0];
    const isTwitterOrICalendarIntegration =
      selectedItem.type === INTEGRATION_TYPE.TWITTER || selectedItem.type === INTEGRATION_TYPE.I_CALENDAR;
    const infoText = 'This action is not available';

    if (isTwitterOrICalendarIntegration) {
      return (
        <SimpleModal
          isOpen={isEditIntegrationModalOpen}
          title={''}
          body={infoText}
          buttonText={undefined}
          onButtonClick={undefined}
          onCloseClick={this.onCloseEditIntegrationModalClick}
        />
      );
    } else {
      return (
        <IntegrationEditModal
          calendars={calendars}
          isOpen={isEditIntegrationModalOpen}
          onButtonClick={this.selectIntegrationCalendarClick}
          onCloseClick={this.onCloseEditIntegrationModalClick}
        />
      );
    }
  }

  onAddIntegrationModalClick = () => {
    this.setState({
      isAddIntegrationModalOpen: true
    });
  };

  onSyncIntegrationModalClick = () => {
    this.setState({
      isSyncIntegrationModalOpen: true
    });
  };

  onSyncOffIntegrationModalClick = () => {
    this.setState({
      isSyncOffIntegrationModalOpen: true
    });
  };

  onShowError = (message: string) => {
    this.setState({
      isError: true,
      errorMessage: message
    });
  };

  onCloseErrorClick = (event: Event) => {
    event.preventDefault();
    this.setState({
      isError: false,
      errorMessage: ''
    });
  };

  renderSendingError(): React.ReactNode {
    const { errorMessage, isError } = this.state;
    if (!isError) {
      return null;
    }

    return (
      <SimpleAlert
        message={errorMessage}
        additionalClassNames={'alert-danger'}
        onCloseAlertClick={this.onCloseErrorClick}
      />
    );
  }

  renderSubscriptionsTable(): React.ReactNode {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isSelectAllChecked,
      currentPage,
      total
    } = this.state;

    const isOneItemSelected = selectedItems.length === 1;

    const actionItems = [
      {
        itemText: 'Add integration',
        onItemClick: this.onAddIntegrationModalClick,
        isActive: true
      },
      {
        itemText: 'Remove integration',
        onItemClick: this.deletedIntegrationClick,
        isActive: isOneItemSelected
      },
      {
        itemText: 'Sync integration',
        onItemClick: this.onSyncIntegrationModalClick,
        isActive: isOneItemSelected
      },
      {
        itemText: 'Sync off integration',
        onItemClick: this.onSyncOffIntegrationModalClick,
        isActive: isOneItemSelected
      },
      {
        itemText: 'Edit integration',
        onItemClick: this.getIntegrationClick,
        isActive: isOneItemSelected
      }
    ];

    const filterOptions = {};

    return (
      <Grid2
        dataItems={items}
        filters={filters}
        currentPage={currentPage}
        total={total}
        isSelectAllChecked={isSelectAllChecked}
        isDataFiltered={isDataFiltered}
        sortDirection={sortDirection}
        sortColumnsName={sortColumnsName}
        isShowFilter={isShowFilter}
        dataItemsSelected={selectedItems}
        columns={COLUMNS}
        actionItems={actionItems}
        options={filterOptions}
        onItemClick={this.onItemClick}
        onSortClick={this.onTableSortClick}
        onApplyFilterClick={this.onApplyFilterClick}
        onClearFilterClick={this.onClearFilterClick}
        onTableFilterChange={this.onTableFilterChange}
        onTableFilterClick={this.onTableFilterClick}
        setCurrentPageParams={this.setCurrentPageParams}
        onSelectAllClick={this.onSelectAllClick}
        onSelectAllOnPageClick={this.onSelectAllOnPageClick}
        onUnselectAllClick={this.onUnselectAllClick}
        isCSV={false}
      />
    );
  }

  render() {
    const {
      isLoading,
      isAddIntegrationModalOpen,
      isSyncIntegrationModalOpen,
      isSyncOffIntegrationModalOpen,
      isEditIntegrationModalOpen
    } = this.state;
    if (isLoading) {
      return <Loader />;
    }

    const classes =
      isAddIntegrationModalOpen ||
      isSyncIntegrationModalOpen ||
      isSyncOffIntegrationModalOpen ||
      isEditIntegrationModalOpen
        ? 'mt-3 modal-open'
        : 'mt-3';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderEditIntegrationModal()}
        {this.renderSyncOffIntegrationModal()}
        {this.renderSyncIntegrationModal()}
        {this.renderAddIntegrationModal()}
        {this.renderSendingError()}
        <div className="row">
          <div className="col-md-12">{this.renderSubscriptionsTable()}</div>
        </div>
      </div>
    );
  }
}
