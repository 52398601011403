import * as React from 'react';
import { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import DatePicker from 'react-datepicker';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from '../../../../../../consts/report';
import { Switch } from 'Src/components/Switch/Switch';
import { LabelWithQuestionIcon } from '../../../../../../components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import { MultiSelectCheckboxes } from '../../../../../../components/MultiSelectCheckboxes/MultiSelectCheckboxes';
import {
  getReportDefaultGendersForMultiSelect,
  getReportAgesForMultiSelect
} from '../../../../../../helpers/multiselect/multiselect';
import { School } from '../../../../../../models/school';
import { SchoolForm } from '../../../../../../models/form';
import { getStartDateForSchoolYear } from '../../../../../../helpers/calendar/calendar';
import '../SchoolEvents.scss';

interface Props {
  onCloseClick: () => void;
  onSubmit: (data: any, reportType: string) => void;
  school: School;
  forms: SchoolForm[];
}

// report 067
export function FixtureStatisticsForPeriodReport({ onCloseClick, onSubmit, school, forms }: Props) {
  const now = new Date();
  let dateFrom = getStartDateForSchoolYear(now);
  const genders = getReportDefaultGendersForMultiSelect();
  const ages = getReportAgesForMultiSelect(school, forms);

  const [isAgesAndGendersChecked, setIsAgesAndGendersChecked] = useState(true);

  const requestInitial = {
    dateFrom,
    dateTo: now,
    isShowInterSchool: true,
    isShowHouses: true,
    isShowIntraSchool: true,
    isShowTournament: true,
    genders,
    ages,
    isSelectAllAges: true,
    isPrintPP: false,
    isPrintSEN: false,
    isPrintFSM: false,
    isPrintEthnicity: false
  };

  const onGendersChange = (index, values, setFieldValue) => {
    const genderItems = values.genders;
    const ageItems = values.ages;

    const genderItemsUpdated = genderItems.map((genderItem, genderIndex) => {
      return genderIndex === index
        ? {
            ...genderItem,
            isChecked: !genderItem.isChecked
          }
        : genderItem;
    });

    const gendersChecked = genderItemsUpdated.map(gender => gender.isChecked);
    const agesChecked = ageItems.map(age => age.isChecked);
    const isCheckedGendersExist = gendersChecked.includes(true);
    const isCheckedAgesExist = agesChecked.includes(true);

    setIsAgesAndGendersChecked(isCheckedGendersExist && isCheckedAgesExist);
    setFieldValue('genders', genderItemsUpdated);
  };

  const onAgesChange = (index, values, setFieldValue) => {
    const ageItems = values.ages;
    const genderItems = values.genders;

    const ageItemsUpdated = ageItems.map((ageItem, ageIndex) => {
      return ageIndex === index
        ? {
            ...ageItem,
            isChecked: !ageItem.isChecked
          }
        : ageItem;
    });

    const gendersChecked = genderItems.map(gender => gender.isChecked);
    const agesChecked = ageItemsUpdated.map(age => age.isChecked);
    const isCheckedGendersExist = gendersChecked.includes(true);
    const isCheckedAgesExist = agesChecked.includes(true);

    const isSelectAllAges = ageItemsUpdated.every(age => age.isChecked === true);

    setIsAgesAndGendersChecked(isCheckedAgesExist && isCheckedGendersExist);
    setFieldValue('isSelectAllAges', isSelectAllAges);
    setFieldValue('ages', ageItemsUpdated);
  };

  const onSelectAllAgesChange = (event, values, setFieldValue) => {
    const isSelectAllAges = event.target.checked;
    const ageItems = values.ages;
    const genderItems = values.genders;

    const ageItemsUpdated = ageItems.map(age => {
      return {
        ...age,
        isChecked: isSelectAllAges
      };
    });

    const gendersChecked = genderItems.map(gender => gender.isChecked);
    const agesChecked = ageItemsUpdated.map(age => age.isChecked);
    const isCheckedGendersExist = gendersChecked.includes(true);
    const isCheckedAgesExist = agesChecked.includes(true);

    setIsAgesAndGendersChecked(isCheckedAgesExist && isCheckedGendersExist);
    setFieldValue('isSelectAllAges', isSelectAllAges);
    setFieldValue('ages', ageItemsUpdated);
  };

  return (
    <Formik
      onSubmit={values => onSubmit(values, REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.FIXTURE_STATISTICS_FOR_PERIOD)}
      initialValues={requestInitial}
      render={({ values, setFieldValue }) => (
        <Form>
          <div className="bReportFormTitle">Fixture statistics</div>
          <div className="mb-3 mt-3">
            {'A report to present detailed statistics on sports fixtures and student participation ' +
              ' for a specified period, with data organised by sport. ' +
              ' It includes the number of fixtures and a unique count of student involvement, ' +
              ' ensuring no student is counted more than once in the total participation figures, ' +
              ' regardless of their participation in multiple sports.'}
          </div>
          <div className="form-group">
            <label>Date from</label>
            <Field name="dateFrom">
              {({ field, form }) => (
                <div className="mb-3">
                  <DatePicker
                    {...field}
                    selected={field.value}
                    onChange={date => {
                      form.setFieldValue('dateFrom', date);
                      if (form.values.dateTo && form.values.dateTo < date) {
                        form.setFieldValue('dateTo', date);
                      }
                    }}
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
              )}
            </Field>
          </div>
          <div className="form-group">
            <label>Date to</label>
            <Field name="dateTo">
              {({ field, form }) => (
                <div className="mb-3">
                  <DatePicker
                    {...field}
                    selected={field.value}
                    onChange={date => {
                      form.setFieldValue('dateTo', date);
                    }}
                    minDate={form.values.dateFrom}
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
              )}
            </Field>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isShowInterSchool"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowInterSchool}
                    onChange={event => {
                      const isShowInterSchool = event.target.checked;
                      setFieldValue('isShowInterSchool', isShowInterSchool);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show inter-school fixtures"
                hintText={`Show events where you're playing against another school.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowInterSchool"
              />
            </div>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isShowHouses"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowHouses}
                    onChange={event => {
                      const isShowHouses = event.target.checked;
                      setFieldValue('isShowHouses', isShowHouses);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show house fixtures"
                hintText={`Show events where it is internal house matches.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowHouses"
              />
            </div>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isShowIntraSchool"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowIntraSchool}
                    onChange={event => {
                      const isShowIntraSchool = event.target.checked;
                      setFieldValue('isShowIntraSchool', isShowIntraSchool);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show internal fixtures"
                hintText={`Show internal matches.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowIntraSchool"
              />
            </div>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isShowTournament"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowTournament}
                    onChange={event => {
                      const isShowTournament = event.target.checked;
                      setFieldValue('isShowTournament', isShowTournament);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show tournament events"
                hintText={`Show games from tournaments your school participated in.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowTournament"
              />
            </div>
          </div>

          <div className={'mt-3 mb-3'}>
            <MultiSelectCheckboxes
              items={values.genders}
              cols={3}
              onChange={index => onGendersChange(index, values, setFieldValue)}
            />
          </div>

          <div className={'mt-3 mb-3'}>
            <MultiSelectCheckboxes
              items={values.ages}
              cols={3}
              onChange={index => onAgesChange(index, values, setFieldValue)}
            />
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isSelectAllAges"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isSelectAllAges}
                    customClass="mb-3"
                    onChange={event => onSelectAllAgesChange(event, values, setFieldValue)}
                  />
                )}
              />

              <label htmlFor="isSelectAllAges" className="form-check-label pl-2">
                Select all ages
              </label>
            </div>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isPrintPP"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintPP}
                    onChange={event => {
                      const isPrintPP = event.target.checked;
                      setFieldValue('isPrintPP', isPrintPP);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show PP"
                hintText={`Show data relating to students with PP.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintPP"
              />
            </div>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isPrintSEN"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintSEN}
                    onChange={event => {
                      const isPrintSEN = event.target.checked;
                      setFieldValue('isPrintSEN', isPrintSEN);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show SEN"
                hintText={`Show data relating to students with SEN.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintSEN"
              />
            </div>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isPrintFSM"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintFSM}
                    onChange={event => {
                      const isPrintFSM = event.target.checked;
                      setFieldValue('isPrintFSM', isPrintFSM);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show FSM"
                hintText={`Show data relating to students with FSM.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintFSM"
              />
            </div>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isPrintEthnicity"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintEthnicity}
                    onChange={event => {
                      const isPrintEthnicity = event.target.checked;
                      setFieldValue('isPrintEthnicity', isPrintEthnicity);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Breakdown by ethnicity codes"
                hintText={``}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintEthnicity"
              />
            </div>
          </div>

          <button type="button" className="btn btn-secondary mr-3" onClick={onCloseClick}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary" disabled={!isAgesAndGendersChecked}>
            Get
          </button>
        </Form>
      )}
    />
  );
}
