import * as React from 'react';
import { Route, Switch } from 'react-router';
import { TournamentsUpcoming } from './TournamentsUpcoming/TournamentsUpcoming';
import { TournamentEvents } from './TournamentEvents';
import { TournamentTeams } from './TournamentTeams';
import { TournamentTeamPlayersManager } from './TournamentTeamPlayersManager';
import { TournamentTeamPlayers } from './TournamentTeamPlayers/TournamentTeamPlayers';
import { WaitingList } from './WaitingList/WaitingList';
import { TournamentsArchive } from './TournamentsArchive/TournamentsArchive';

export const TournamentRouting = allProps => {
  return (
    <Switch>
      <Route
        exact
        path={'/tournaments/upcoming'}
        render={props => <TournamentsUpcoming {...props} user={allProps.user} />}
      />
      <Route
        exact
        path={'/tournaments/events'}
        render={props => <TournamentEvents {...props} user={allProps.user} />}
      />
      <Route exact path={'/tournaments/teams'} render={props => <TournamentTeams {...props} user={allProps.user} />} />
      <Route
        exact
        path={'/tournaments/teamPlayers/manage'}
        render={props => <TournamentTeamPlayersManager {...props} user={allProps.user} />}
      />
      <Route
        exact
        path={'/tournaments/teamPlayers'}
        render={props => <TournamentTeamPlayers {...props} user={allProps.user} />}
      />
      <Route
        exact
        path={'/tournaments/waitingList'}
        render={props => <WaitingList {...props} user={allProps.user} />}
      />
      <Route
        exact
        path={'/tournaments/archive'}
        render={props => <TournamentsArchive {...props} user={allProps.user} />}
      />
    </Switch>
  );
};

TournamentRouting.displayName = 'TournamentRouting';
