import * as React from 'react';
import * as propz from 'propz';
import { Component } from 'react';
import { Form, Formik } from 'formik';
import { Switch } from 'Src/components/Switch/Switch';
import { Loader } from 'Src/components/Loader/Loader';
import { AppUser } from 'Src/views/App/App';
import { updateSchoolAdvancedSettings } from 'Src/helpers/service/admin/settings';
import { Button } from 'Src/components/Button/Button';
import { School } from 'Src/models/school';
import './SchoolSettingsAdvanced.scss';

interface Props {
  user: AppUser;
  onSchoolUpdate: () => Promise<School>;
}

interface State {
  isLoading: boolean;
}

const advancedSettings = [
  { title: 'Parent and student can upload photos to event', field: 'photosEnabled' },
  { title: 'User can write comments for event', field: 'commentsEnabled' },
  { title: 'Parent can send an availability report', field: 'availabilityParentEnabled' },
  { title: 'Student can send an availability report', field: 'availabilityStudentEnabled' }
];

export class SchoolSettingsAdvanced extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };
  }

  onSubmit = values => {
    const { user } = this.props;

    this.setState({
      isLoading: true
    });

    updateSchoolAdvancedSettings(user, values).then(() => {
      const { onSchoolUpdate } = this.props;

      return onSchoolUpdate().then(() => {
        this.setState({
          isLoading: false
        });
      });
    });
  };

  renderSchoolSettingsAdvanced = (values, setFieldValue) => {
    return advancedSettings.map(setting => {
      const field = setting.field;
      const settingValue = values[field];

      return (
        <div className="eAdvancedSettingsRow" key={field}>
          <p className="h5 font-weight-normal">{setting.title}</p>

          <div className="d-flex align-content-center">
            <p className="h6 mb-0 mr-4">Off</p>
            <Switch
              value={settingValue}
              onChange={() => {
                const fieldValue = values[field];
                setFieldValue(field, !fieldValue);
              }}
              text={''}
              name={field}
            />
            <p className="h6 mb-0 ml-3">On</p>
          </div>
        </div>
      );
    });
  };

  render() {
    const { isLoading } = this.state;
    const { user } = this.props;

    const initialValues = {
      photosEnabled: propz.get(user, ['activeSchool', 'settings', 'photosEnabled'], false),
      commentsEnabled: propz.get(user, ['activeSchool', 'settings', 'commentsEnabled'], false),
      availabilityParentEnabled: propz.get(user, ['activeSchool', 'settings', 'availabilityParentEnabled'], false),
      availabilityStudentEnabled: propz.get(user, ['activeSchool', 'settings', 'availabilityStudentEnabled'], false)
    };

    if (isLoading) {
      return <Loader />;
    }

    return (
      <div className="mt-3">
        <h2>Advanced settings</h2>

        <div className="mt-4 mb-3">
          <div className="row justify-content-center mx-0">
            <div className="col-md-12 col-lg-10 px-0">
              <Formik
                initialValues={initialValues}
                onSubmit={values => this.onSubmit(values)}
                render={({ setFieldValue, handleSubmit, values, dirty }) => {
                  return (
                    <Form>
                      <div className="bAdvancedSettings">
                        <div className="eAdvancedSettingsTitle">
                          <p className="h4">Moderation</p>
                          <p className="h4">Status</p>
                        </div>
                        {this.renderSchoolSettingsAdvanced(values, setFieldValue)}
                      </div>

                      <div className="d-flex justify-content-end">
                        <Button text="Save" customClass="btn-lg" onClick={handleSubmit} disabled={!dirty} />
                      </div>
                    </Form>
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
