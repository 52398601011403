import * as React from 'react';
import { Route, Switch } from 'react-router';
import { ClubBookingsInbox } from './ClubBookingsInbox/ClubBookingsInbox';
import { ClubBookingArchive } from './ClubBookingArchive/ClubBookingArchive';

export const ClubBookingsRouting = allProps => {
  return (
    <Switch>
      <Route
        exact
        path={'/clubBookings/inbox'}
        render={props => <ClubBookingsInbox {...props} setNeedUpdate={allProps.setNeedUpdate} user={allProps.user} />}
      />
      <Route
        exact
        path={'/clubBookings/archive'}
        render={props => <ClubBookingArchive {...props} user={allProps.user} />}
      />
    </Switch>
  );
};

ClubBookingsRouting.displayName = 'ClubBookingsRouting';
