import * as React from 'react';
import { Component } from 'react';
import { Tournament } from 'Src/models/tournament';
import {
  BADMINTON_FINALS_STAGES,
  BADMINTON_GROUP_STAGES,
  BADMINTON_PLAYOFF_STAGES,
  BADMINTON_QUALIFIERS_STAGES,
  BADMINTON_THIRD_PLACE_STAGES,
  FOOTBALL_FINALS_STAGES,
  FOOTBALL_GROUP_STAGES,
  FOOTBALL_PLAYOFF_STAGES,
  FOOTBALL_QUALIFIERS_STAGES,
  FOOTBALL_THIRD_PLACE_STAGES,
  RUGBY_GROUP_STAGES,
  RUGBY_PLAYOFF_STAGES,
  SWIMMING_GROUP_STAGES,
  TOURNAMENT_GROUP_TAG_MAPPING,
  TOURNAMENT_GROUP_TAG_MAPPING_REVERSE
} from 'Src/consts/tournament';
import { Field, Form, Formik } from 'formik';
import { Button } from 'Src/components/Button/Button';
import {
  isFinalStageExist,
  isGroupStageExist,
  isPlayoffStageExist,
  isQualifierStageExist
} from 'Src/helpers/tournament/tournament';
import { Switch } from 'Src/components/Switch/Switch';

interface Props {
  tournament: Tournament;
  onCancel: () => void;
  onSubmit: (data) => void;
}

export class TournamentBadmintonGroupsForm extends Component<Props> {
  renderGroups(groups: string[], values, setFieldValue): React.ReactNode {
    return groups.map((group, index) => (
      <Switch
        key={`group_${index}`}
        value={values[group]}
        onChange={() => {
          setFieldValue(group, !values[group]);
        }}
        text={TOURNAMENT_GROUP_TAG_MAPPING[group]}
        name={group}
      />
    ));
  }

  getTournamentGroups(): string[] {
    const { tournament } = this.props;
    const { customGroups, stages: groupsDeprecated } = tournament;

    const customGroupsFiltered = customGroups
      .filter(customGroup => {
        const customGroupName = customGroup.name;
        const customGroupNameMapped = TOURNAMENT_GROUP_TAG_MAPPING_REVERSE[customGroupName];

        return typeof customGroupNameMapped !== 'undefined';
      })
      .map(customGroup => {
        const customGroupName = customGroup.name;
        const customGroupNameMapped = TOURNAMENT_GROUP_TAG_MAPPING_REVERSE[customGroupName];
        return customGroupNameMapped;
      });

    return [...groupsDeprecated, ...customGroupsFiltered];
  }

  getGroupValue(group): boolean {
    const tournamentGroups = this.getTournamentGroups();
    const tournamentGroupIndex = tournamentGroups.findIndex(tournamentGroup => tournamentGroup === group);

    return tournamentGroupIndex !== -1;
  }

  getGroupsObject(groupList) {
    let groupsObject = {};

    groupList.forEach(group => {
      groupsObject[group] = this.getGroupValue(group);
    });

    return groupsObject;
  }

  getAllGroups() {
    const { tournament } = this.props;

    let groups = {};

    if (isGroupStageExist(tournament)) {
      const groupGroups = [...BADMINTON_GROUP_STAGES];

      const groupsObject = this.getGroupsObject(groupGroups);
      groups = { ...groups, ...groupsObject };
    }

    if (isQualifierStageExist(tournament)) {
      const qualifierGroups = [...FOOTBALL_QUALIFIERS_STAGES, ...BADMINTON_QUALIFIERS_STAGES];

      const groupsObject = this.getGroupsObject(qualifierGroups);
      groups = { ...groups, ...groupsObject };
    }

    if (isPlayoffStageExist(tournament)) {
      const playoffGroups = [
        ...FOOTBALL_PLAYOFF_STAGES,
        ...BADMINTON_PLAYOFF_STAGES,
        ...FOOTBALL_THIRD_PLACE_STAGES,
        ...BADMINTON_THIRD_PLACE_STAGES
      ];

      const groupsObject = this.getGroupsObject(playoffGroups);
      groups = { ...groups, ...groupsObject };
    }

    if (isFinalStageExist(tournament)) {
      const finalGroups = [...FOOTBALL_FINALS_STAGES, ...BADMINTON_FINALS_STAGES];

      const groupsObject = this.getGroupsObject(finalGroups);
      groups = { ...groups, ...groupsObject };
    }

    return groups;
  }

  renderBadmintonGroups(values, setFieldValue): React.ReactNode {
    const { tournament } = this.props;
    let groupAndQualifier = [];

    if (isGroupStageExist(tournament)) {
      groupAndQualifier.push(
        <div key="group_stage_badminton" className="mb-3 mt-3 font-weight-bold">
          Group Stage Badminton
        </div>
      );
      groupAndQualifier.push(this.renderGroups(BADMINTON_GROUP_STAGES, values, setFieldValue));
    }

    if (isQualifierStageExist(tournament)) {
      groupAndQualifier.push(
        <div key="qualifier_stage" className="mb-3 mt-3 font-weight-bold">
          Qualifiers Stage
        </div>
      );
      groupAndQualifier.push(this.renderGroups(FOOTBALL_QUALIFIERS_STAGES, values, setFieldValue));
      groupAndQualifier.push(this.renderGroups(BADMINTON_QUALIFIERS_STAGES, values, setFieldValue));
    }

    let playoffAndFinal = [];

    if (isPlayoffStageExist(tournament)) {
      playoffAndFinal.push(
        <div key="playoff_stage" className="mb-3 mt-3 font-weight-bold">
          Playoff Stage
        </div>
      );
      playoffAndFinal.push(this.renderGroups(FOOTBALL_PLAYOFF_STAGES, values, setFieldValue));
      playoffAndFinal.push(this.renderGroups(BADMINTON_PLAYOFF_STAGES, values, setFieldValue));
      playoffAndFinal.push(this.renderGroups(FOOTBALL_THIRD_PLACE_STAGES, values, setFieldValue));
      playoffAndFinal.push(this.renderGroups(BADMINTON_THIRD_PLACE_STAGES, values, setFieldValue));
    }

    if (isFinalStageExist(tournament)) {
      playoffAndFinal.push(
        <div key="final_stage" className="mb-3 mt-3 font-weight-bold">
          Finals Stage
        </div>
      );
      playoffAndFinal.push(this.renderGroups(FOOTBALL_FINALS_STAGES, values, setFieldValue));
      playoffAndFinal.push(this.renderGroups(BADMINTON_FINALS_STAGES, values, setFieldValue));
    }

    return (
      <div className="row">
        <div className="col-md-6">{groupAndQualifier}</div>
        <div className="col-md-6">{playoffAndFinal}</div>
      </div>
    );
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <Formik
              initialValues={this.getAllGroups()}
              onSubmit={values => {
                this.props.onSubmit(values);
              }}
              render={({ values, setFieldValue }) => (
                <Form>
                  <div className="form-group form-check">
                    {this.renderBadmintonGroups(values, setFieldValue)}
                    <Button
                      onClick={this.props.onCancel}
                      text={'Cancel'}
                      customClass={'mt-3 mb-3 mr-3 btn-secondary'}
                    />
                    <button type="submit" className="mt-3 mb-3 btn btn-primary">
                      Save
                    </button>
                  </div>
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}
