import * as React from 'react';

interface Props {
  onClick: () => void;
}

export function PencilEditIcon(props: Props) {
  const { onClick } = props;
  return (
    <div className="">
      <img style={{ width: '35px' }} src="/dist/images/edit.png" title="" onClick={onClick} alt={'font'} />
    </div>
  );
}
