import * as React from 'react';
import { ACCESS_PRESET, ACCESS_PRESET_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/album';
import { ACCESS_PRESET_TYPE } from '../../../../../../../types/album';

interface Props {
  accessPreset: ACCESS_PRESET_TYPE;
  onAccessPresetClick: (accessPreset: ACCESS_PRESET_TYPE) => void;
}

export function AccessPresetRadioButtons(props: Props) {
  const { accessPreset, onAccessPresetClick } = props;

  return (
    <>
      <div className={'font-weight-bold'}>Access presets</div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="accessPreset"
          id="accessPresetPrivate"
          value={ACCESS_PRESET.PRIVATE}
          checked={accessPreset === ACCESS_PRESET.PRIVATE}
          onChange={() => {
            onAccessPresetClick(ACCESS_PRESET.PRIVATE as ACCESS_PRESET_TYPE);
          }}
        />
        <label className="form-check-label" htmlFor="accessPresetPrivate">
          {ACCESS_PRESET_SERVER_TO_CLIENT_MAPPING[ACCESS_PRESET.PRIVATE]}
        </label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="accessPreset"
          id="accessPresetPublic"
          value={ACCESS_PRESET.PUBLIC}
          checked={accessPreset === ACCESS_PRESET.PUBLIC}
          onChange={() => {
            onAccessPresetClick(ACCESS_PRESET.PUBLIC as ACCESS_PRESET_TYPE);
          }}
        />
        <label className="form-check-label" htmlFor="accessPresetPublic">
          {ACCESS_PRESET_SERVER_TO_CLIENT_MAPPING[ACCESS_PRESET.PUBLIC]}
        </label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="accessPreset"
          id="accessPresetAllParticipantParents"
          value={ACCESS_PRESET.ALL_PARTICIPANT_PARENTS}
          checked={accessPreset === ACCESS_PRESET.ALL_PARTICIPANT_PARENTS}
          onChange={() => {
            onAccessPresetClick(ACCESS_PRESET.ALL_PARTICIPANT_PARENTS as ACCESS_PRESET_TYPE);
          }}
        />
        <label className="form-check-label" htmlFor="accessPresetAllParticipantParents">
          {ACCESS_PRESET_SERVER_TO_CLIENT_MAPPING[ACCESS_PRESET.ALL_PARTICIPANT_PARENTS]}
        </label>
      </div>
    </>
  );
}
