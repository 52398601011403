import * as React from 'react';
import * as propz from 'propz';
import { EventInvitationMessage } from 'Src/models/message';
import { CLUB_STAFF } from 'Src/consts/club';
import { getFullName } from 'Src/helpers/autocomplete/autocomplete';
import { QUESTIONS_TEMPLATE_TYPE } from 'Src/consts/message';
import { convertBooleanToYesNo } from 'Src/helpers/accessor/accessor';

interface Props {
  message: EventInvitationMessage;
}

export const ClubBookingDeatils = (props: Props) => {
  const { message } = props;

  const clubDescription = propz.get(message, ['clubData', 'description'], '');
  const staff = propz.get(message, ['clubData', 'staff'], []);
  const fields = propz.get(message, ['fields'], []);

  const coaches = staff
    .filter(item => item.staffRole === CLUB_STAFF.COACH)
    .map(item => getFullName(item))
    .join(', ');

  const members = staff
    .filter(item => item.staffRole === CLUB_STAFF.MEMBER_OF_STAFF)
    .map(item => getFullName(item))
    .join(', ');

  const renderFields = () => {
    const fieldsToRender = fields.map(field => {
      const { heading, value, type } = field;
      const booleanValueFormatted = convertBooleanToYesNo(value);
      const isBooleanQuestion = type === QUESTIONS_TEMPLATE_TYPE.BOOLEAN;

      return (
        <li className="mb-3">
          <p className="font-weight-bold mb-0 text-info">{heading}</p>
          <span>{isBooleanQuestion ? booleanValueFormatted : value}</span>

          <hr />
        </li>
      );
    });

    return (
      <div>
        <ul className="pl-3">{fieldsToRender}</ul>
      </div>
    );
  };

  return (
    <div>
      <div className="d-flex justify-content-between mb-3">
        <p className="font-weight-bold">Description:</p>
        <p>{clubDescription}</p>
      </div>

      <div className="d-flex justify-content-between mb-3">
        <p className="font-weight-bold">Leader / Coach:</p>
        <p>{coaches}</p>
      </div>

      <div className="d-flex justify-content-between mb-3">
        <p className="font-weight-bold">Member(s) of staff:</p>
        <p>{members}</p>
      </div>

      <div className="mb-3">
        <p className="font-weight-bold">Your responses:</p>

        {renderFields()}
      </div>
    </div>
  );
};

ClubBookingDeatils.displayName = 'ClubBookingDeatils';
