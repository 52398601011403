import * as React from 'react';
import { FunctionComponent } from 'react';
import { AppUser } from 'Src/views/App/App';

interface Props {
  user?: AppUser;
}

export const EmptyComponent: FunctionComponent<Props> = props => {
  return <h1>Coming soon!</h1>;
};

EmptyComponent.displayName = 'EmptyComponent';
