import * as React from 'react';
import * as propz from 'propz';
import { SchoolEvent, SchoolEventTeamDataPlayer } from '../../../../../../models/event';
import { getPositionNameById } from '../../../../../../helpers/event/event';

interface Props {
  player: SchoolEventTeamDataPlayer;
  event: SchoolEvent;
}

export function SchoolEventTeamPlayerPosition(props: Props) {
  const { player, event } = props;

  const playerData = event.players.find(eventPlayer => eventPlayer._id === player.id);
  const positionId: string = propz.get(playerData, ['positionId']);

  if (typeof positionId !== 'undefined') {
    const playerTeamId: string = player.teamId;
    const team = event.embeddedTeams.find(embeddedTeam => embeddedTeam._id === playerTeamId);
    const teamPositionName: string | undefined = propz.get(team, ['positionName'], undefined);
    const playerPositionName: string = getPositionNameById(positionId, event);

    if (typeof teamPositionName !== 'undefined') {
      return <>{`${playerPositionName} / ${teamPositionName}`}</>;
    } else {
      return <>{`${playerPositionName}`}</>;
    }
  }

  return null;
}
