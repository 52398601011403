import * as React from 'react';
import { NO_VIDEO } from 'Src/consts/common';
import * as Hls from 'hls.js';
import { AdminBoardGalleryItem } from './ArbiterBoard';
import { ACCESS_PRESET_SERVER_TO_CLIENT_MAPPING } from '../../../../../../consts/album';
import { getAccessPresetColor } from '../../../../../../helpers/event/eventPhotoAndVideo';

interface Props {
  item: AdminBoardGalleryItem;
  onClick: (id: string) => void;
}
interface State {}

export class ArbiterBoardGalleryItemVideo extends React.Component<Props, State> {
  videoFileRef: any;

  constructor(props) {
    super(props);

    this.videoFileRef = React.createRef();
  }

  componentDidMount() {
    const { item } = this.props;
    const { url } = item;
    const isUrlExist = typeof url !== 'undefined';

    if (isUrlExist) {
      const hls = new Hls();
      hls.loadSource(url);
      hls.attachMedia(this.videoFileRef);
    }
  }

  onVideoClick = event => {
    const { onClick, item } = this.props;
    const { id } = item;
    onClick(id);
    event.stopPropagation();
  };

  render() {
    const { item } = this.props;
    const background = {
      backgroundImage: `url(${NO_VIDEO})`,
      backgroundPosition: 'top center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    };

    const { url } = item;
    const isUrlExist = typeof url !== 'undefined';
    const color = getAccessPresetColor(item.accessPreset);

    return isUrlExist ? (
      <div className="eArbiterBoardGalleryItemVideo" onClick={this.onVideoClick}>
        <video
          controls={false}
          style={{ width: '100%', height: '100%', backgroundColor: '#000000' }}
          ref={input => (this.videoFileRef = input)}
        />
        <div className="eArbiterBoardGalleryItem_accessPreset" style={{ color: color }}>
          <span>{ACCESS_PRESET_SERVER_TO_CLIENT_MAPPING[item.accessPreset]}</span>
        </div>
      </div>
    ) : (
      <div className="eArbiterBoardGalleryItemVideo" style={background} />
    );
  }
}
