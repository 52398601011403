import { SchoolEvent } from '../../models/event';
import { isEventStatusFinished, isMultipartyEvent, isResultsModePlaces, isResultsModePoints } from '../event/event';
import { getHouseScoreByHouseId, getTeamScoreByTeamId } from '../event/schoolEventViewHelper';
import { isLessScoringSport } from '../sport/sport';
import { sortByNameAsc } from '../team/team';
import { isCricket } from '../sport/cricket';

export function sortHouses(event: SchoolEvent, house1, house2) {
  const isEventFinished = isEventStatusFinished(event);
  const isEventCricket = isCricket(event);
  const isMultiparty = isMultipartyEvent(event);

  const { teamsData } = event;
  const team1 = teamsData.find(teamData => teamData.houseId === house1.id);
  const isTeamExist1 = typeof team1 !== 'undefined';

  const team2 = teamsData.find(teamData => teamData.houseId === house2.id);
  const isTeamExist2 = typeof team2 !== 'undefined';

  const score1 = isTeamExist1 ? getTeamScoreByTeamId(event, team1.id) : getHouseScoreByHouseId(event, house1.id);
  const score2 = isTeamExist2 ? getTeamScoreByTeamId(event, team2.id) : getHouseScoreByHouseId(event, house2.id);

  switch (true) {
    case isEventCricket && !isMultiparty:
      return sortByNameAsc(house1, house2);
    case isEventFinished && isMultiparty:
      const isPlaces = isResultsModePlaces(event);
      const isPoints = isResultsModePoints(event);
      switch (true) {
        case isPlaces:
          return score1 - score2;
        case isPoints:
          return score2 - score1;
        default:
          console.error('Can not find results mode');
          return 0;
      }
    case isEventFinished && !isMultiparty:
      const isLessScoringEventSport = isLessScoringSport(event.sport);

      return isLessScoringEventSport ? score1 - score2 : score2 - score1;
    default:
      return sortByNameAsc(house1, house2);
  }
}
