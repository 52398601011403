import * as React from 'react';
import { Component } from 'react';
import MaskedInput from 'react-text-mask';
import * as Moment from 'moment';
import { ModalDialog } from '../ModalDialog/ModalDialog';
import { Subscription } from '../../models/subscription';
import { getSubscriptionStatement } from '../../helpers/service/admin/subscriptions';
import { AppUser } from 'Src/views/App/App';
import { DATE_FORMAT } from '../../consts/date';

interface Props {
  isOpen: boolean;
  onCancelClick: () => void;
  subscription: Subscription;
  user: AppUser;
}

interface State {
  dateFrom: string;
  dateTo: string;
  isIncludeText: boolean;
  isDateFromInvalid: boolean;
  isDateToInvalid: boolean;
}

export class SubscriptionReportSettingsModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      dateFrom: Moment(props.subscription.purchaseDate).format(DATE_FORMAT),
      dateTo: Moment(new Date()).format(DATE_FORMAT),
      isIncludeText: false,
      isDateFromInvalid: false,
      isDateToInvalid: false
    };
  }

  onDateFromChange = event => {
    this.setState({
      dateFrom: event.target.value,
      isDateFromInvalid: false
    });
  };

  onDateToChange = event => {
    this.setState({
      dateTo: event.target.value,
      isDateToInvalid: false
    });
  };

  onIncludeTextChange = event => {
    this.setState(prevState => ({
      isIncludeText: !prevState.isIncludeText
    }));
  };

  onDownloadClick = () => {
    if (!this.isFormDataValid()) {
      return;
    }

    const { user, subscription } = this.props;
    const { dateFrom, dateTo, isIncludeText } = this.state;
    const from = Moment.utc(dateFrom, DATE_FORMAT).toDate();
    const to = Moment.utc(dateTo, DATE_FORMAT).toDate();

    getSubscriptionStatement(user, subscription.id, from, to, isIncludeText).then(response => {
      const file = new Blob([response.data], { type: 'application/pdf' });
      const fileUrl = window.URL.createObjectURL(file);

      // This is the only solution of those that I found which works!
      // window.open() works too, but it not works correctly in firefox
      const fileName = `Report_${user.activeSchool.name}_${subscription.phoneNumber}.pdf`;
      const link = document.createElement('a');
      link.setAttribute('href', fileUrl);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      this.props.onCancelClick();
    });
  };

  isFormDataValid = (): boolean => {
    const { dateFrom, dateTo } = this.state;
    let isDateFromValid = true;
    let isDateToValid = true;

    if (!Moment(dateFrom, DATE_FORMAT, true).isValid()) {
      isDateFromValid = false;
    }
    if (!Moment(dateTo, DATE_FORMAT, true).isValid()) {
      isDateToValid = false;
    }

    if (isDateFromValid && isDateToValid) {
      return true;
    } else {
      this.setState({
        isDateFromInvalid: !isDateFromValid,
        isDateToInvalid: !isDateToValid
      });
      return false;
    }
  };

  render() {
    const { isOpen, onCancelClick } = this.props;
    const { dateFrom, dateTo, isDateFromInvalid, isDateToInvalid } = this.state;

    return (
      <ModalDialog
        isOpen={isOpen}
        positiveButtonTitle="Download report"
        negativeButtonTitle="Cancel"
        onPositiveButtonClick={this.onDownloadClick}
        onNegativeButtonClick={onCancelClick}
        title="Report settings"
      >
        <form>
          <h5>Get detailed report for the period:</h5>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>From</label>
              <MaskedInput
                type="text"
                className={`form-control ${isDateFromInvalid ? 'is-invalid' : ''}`}
                onChange={this.onDateFromChange}
                mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                placeholder={DATE_FORMAT}
                value={dateFrom}
              />
              {isDateFromInvalid && <div className="invalid-feedback">Invalid date</div>}
            </div>
            <div className="form-group col-md-6">
              <label>To</label>
              <MaskedInput
                type="text"
                className={`form-control ${isDateToInvalid ? 'is-invalid' : ''}`}
                onChange={this.onDateToChange}
                mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                placeholder={DATE_FORMAT}
                value={dateTo}
              />
              {isDateToInvalid && <div className="invalid-feedback">Invalid date</div>}
            </div>
          </div>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" onChange={this.onIncludeTextChange} />
            <label className="form-check-label" htmlFor="autoSizingCheck2">
              Including SMS text
            </label>
          </div>
        </form>
      </ModalDialog>
    );
  }
}
