import * as React from 'react';
import * as Moment from 'moment';
import * as propz from 'propz';
import * as classNames from 'classnames';
import { AppUser } from 'Src/views/App/App';
import { Button } from 'Src/components/Button/Button';
import { EventInvitationMessage } from 'Src/models/message';
import { CLUB_MESSAGE_INVITE_STATUS_ALL, CLUB_MESSAGE_INVITE_STATUS_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/club';
import { INVITE_TYPE } from 'Src/consts/invite';
import { SECONDS_IN_MINUTE, MILLISECONDS_IN_SECOND } from 'Src/consts/common';
import {
  WEEK_DAYS_SERVER_TO_CLIENT_MAPPING,
  DATE_FORMAT_FULL_MONTH_US,
  DATE_FORMAT_FULL_MONTH,
  TIME
} from 'Src/consts/date';
import { getFullName } from 'Src/helpers/autocomplete/autocomplete';
import { getPhoneRegion } from 'Src/helpers/region/region';
import { DetailsIcon } from 'Src/components/SvgIcons/DetailsIcon';
import './ClubBookingCard.scss';

interface Props {
  user: AppUser;
  type: string;
  item: EventInvitationMessage;
  onViewMapClick: (item: EventInvitationMessage) => void;
  onShowDetailsClick: (item: EventInvitationMessage) => void;
  onDeclineClick?: (item: EventInvitationMessage) => void;
  onBookClick?: (item: EventInvitationMessage) => void;
  onReturnToInboxClick?: (item: EventInvitationMessage) => void;
}

export const ClubBookingCard = (props: Props) => {
  const {
    user,
    item,
    type,
    onDeclineClick,
    onBookClick,
    onViewMapClick,
    onShowDetailsClick,
    onReturnToInboxClick
  } = props;
  const { playerDetailsData } = item;

  const isInviteTypeInbox = type === INVITE_TYPE.INBOX;
  const studentName = getFullName(playerDetailsData);
  const schoolLogo = propz.get(item, ['schoolData', 'pic'], '');
  const isSchoolLogoExist = schoolLogo !== '';
  const clubName = propz.get(item, ['clubData', 'name'], '');
  const invitationStatus = propz.get(item, ['invitationStatus']);
  const isInvitationStatusWaitingListCanBook =
    invitationStatus === CLUB_MESSAGE_INVITE_STATUS_ALL.WAITING_LIST_CAN_BOOK;
  const isInvitationStatusPrebooked = invitationStatus === CLUB_MESSAGE_INVITE_STATUS_ALL.PREBOOKED;
  const isInvitationStatusAuthorising = invitationStatus === CLUB_MESSAGE_INVITE_STATUS_ALL.AUTHORISING;
  const invitationStatusFormatted = CLUB_MESSAGE_INVITE_STATUS_SERVER_TO_CLIENT_MAPPING[invitationStatus];
  const postcode = propz.get(item, ['clubData', 'venue', 'placeData', 'postcode'], '');
  const placeName = propz.get(item, ['clubData', 'venue', 'placeData', 'name'], '');
  const isAskToPay = propz.get(item, ['clubData', 'paymentSettings', 'askToPay'], false);
  const price = propz.get(item, ['clubData', 'price', 'price']);
  const venue = `${postcode}, ${placeName}`;
  const deadline = propz.get(item, ['deadlineForAnswers'], '');
  const deadlineDateFormatted = Moment(deadline).format('DD-MM-YYYY');
  const deadlineTimeFormatted = Moment(deadline).format('HH:mm');
  const deadlineFormatted = `${deadlineDateFormatted} / ${deadlineTimeFormatted}`;
  const isDeadlineExist = deadline !== '';
  const now = new Date();
  const deadlineDate = new Date(deadline);
  const isDeadLinePassed = now > deadlineDate;

  const deadlinePassedText = 'Unfortunately, booking for this activity has now closed.';

  const renderDate = () => {
    const startDate = propz.get(item, ['clubData', 'startDate']);
    const startDateFormatted = Moment(startDate).format('DD/MM/YYYY');

    const endDate = propz.get(item, ['clubData', 'finishDate']);
    const endDateFormatted = Moment(endDate).format('DD/MM/YYYY');

    return startDateFormatted === endDateFormatted ? (
      <p>{`Run: ${startDateFormatted}`}</p>
    ) : (
      <p>{`Run: ${startDateFormatted} - ${endDateFormatted}`}</p>
    );
  };

  const getTime = () => {
    const phone = propz.get(user, ['profile', 'phone']);
    const region = getPhoneRegion(phone);

    const startDate = propz.get(item, ['clubData', 'startDate']);
    const startDateFormatted =
      region === 'US'
        ? Moment(startDate).format(DATE_FORMAT_FULL_MONTH_US)
        : Moment(startDate).format(DATE_FORMAT_FULL_MONTH);
    const endDate = propz.get(item, ['clubData', 'finishDate']);
    const endDateFormatted =
      region === 'US'
        ? Moment(endDate).format(DATE_FORMAT_FULL_MONTH_US)
        : Moment(endDate).format(DATE_FORMAT_FULL_MONTH);

    const startTime = propz.get(item, ['clubData', 'time']);
    const duration = propz.get(item, ['clubData', 'duration'], 0);

    const startDateTimeStamp = new Date(startTime).getTime();
    const endDateTimeStamp = startDateTimeStamp + duration * SECONDS_IN_MINUTE * MILLISECONDS_IN_SECOND;
    const endTime = new Date(endDateTimeStamp).toISOString();

    const startTimeFormatted = Moment(startTime).format(TIME);
    const endTimeFormatted = Moment(endTime).format(TIME);
    const eventTime = `${startTimeFormatted}-${endTimeFormatted}`;

    const weekDays = propz.get(item, ['clubData', 'days'], []);
    let weekDaysString = '';
    weekDays.forEach((day, index, array) => {
      const isLastWeekDay = array.length - 1 === index;
      weekDaysString = isLastWeekDay
        ? weekDaysString + ` ${WEEK_DAYS_SERVER_TO_CLIENT_MAPPING[day]}`
        : weekDaysString + ` ${WEEK_DAYS_SERVER_TO_CLIENT_MAPPING[day]},`;
    });

    let time = null;

    if (startDateFormatted === endDateFormatted) {
      time = <p className="mb-0">{eventTime}</p>;
    } else {
      time = (
        <>
          <p className="mb-1">{weekDaysString}</p>
          <p className="mb-0">{eventTime}</p>
        </>
      );
    }

    return time;
  };

  const renderInboxButtons = () => {
    const deadline = !isDeadLinePassed && isDeadlineExist && (
      <div className="text-danger mt-2 font-italic">Deadline for booking: {deadlineFormatted}</div>
    );

    const buttons =
      isInvitationStatusPrebooked || isInvitationStatusAuthorising ? (
        <>
          <Button text="Info" onClick={() => onBookClick(item)} customClass="btn-sm btn-primary" />
          {deadline}
        </>
      ) : (
        <>
          <Button text="Decline" onClick={() => onDeclineClick(item)} customClass="btn-sm btn-danger mr-2" />
          <Button text="Book" onClick={() => onBookClick(item)} customClass="btn-sm btn-success" />
          {deadline}
        </>
      );

    return !isDeadLinePassed || !isDeadlineExist ? <div>{buttons}</div> : <p>{deadlinePassedText}</p>;
  };

  const renderArchiveButtons = () => {
    const isReturnToInboxExist = typeof onReturnToInboxClick !== 'undefined';
    const isInvitationStatusRejected = invitationStatus === CLUB_MESSAGE_INVITE_STATUS_ALL.REJECTED;

    if (isReturnToInboxExist && isInvitationStatusRejected) {
      return (
        <Button text="Return to inbox" onClick={() => onReturnToInboxClick(item)} customClass="btn-sm btn-success" />
      );
    }
  };

  const cardClasses = classNames({
    bClubBookingCard: true,
    bClubBookingCardHighlighted: isInvitationStatusWaitingListCanBook
  });

  return (
    <div className={cardClasses}>
      <div className="eClubBookingCardStudentName">
        <p className={isSchoolLogoExist ? '' : 'text-center'}>{studentName}</p>

        {isSchoolLogoExist && (
          <div className="eClubBookingCardSchoolLogo">
            <img src={schoolLogo} alt="SchoolLogo" />
          </div>
        )}
      </div>

      <div className="eClubBookingCardEventName">{clubName}</div>

      <div className="eClubBookingCardVenue" onClick={() => onViewMapClick(item)}>
        {venue}
        <img src="/dist/images/icons/location.png" alt="Location icon" />
      </div>

      <div className="eClubBookingCardStatus">{invitationStatusFormatted}</div>

      <div className="eClubBookingCardTime">{renderDate()}</div>

      <div className={`d-flex justify-content-between align-items-center ${isInviteTypeInbox && 'mb-4'}`}>
        <div>
          <span>{getTime()}</span>
        </div>

        {isAskToPay && (
          <div className="d-flex flex-column">
            <p className="mb-0">Cost:</p>
            <span className="d-block font-weight-bold">{`£ ${price}`}</span>
          </div>
        )}

        <div className="eClubBookingCardShowDetailsButton" onClick={() => onShowDetailsClick(item)}>
          <DetailsIcon />
          <span>Details</span>
        </div>
      </div>

      {isInviteTypeInbox ? (
        <div className={'eClubBookingCardButtons'}>{renderInboxButtons()}</div>
      ) : (
        <div className={'eClubBookingCardButtons'}>{renderArchiveButtons()}</div>
      )}
    </div>
  );
};

ClubBookingCard.displayName = 'ClubBookingCard';
