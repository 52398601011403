import * as React from 'react';
import './MultiSelectCheckboxes.css';

interface Props {
  cols?: number;
  items: MultiSelectCheckboxItem[];
  onChange: (index: number) => void;
  customClass?: string;
  isDisabled?: boolean;
}

export interface MultiSelectCheckboxItem {
  name: string;
  isChecked: boolean;
}

export function MultiSelectCheckboxes(props: Props) {
  const { items, onChange, cols = 1, customClass, isDisabled } = props;

  if (Array.isArray(items) && items.length === 0) {
    return null;
  }

  return (
    <div className={customClass ? `eMultiSelectCheckboxes ${customClass}` : 'eMultiSelectCheckboxes'}>
      <div style={{ columns: cols }}>
        {items.map((item, index) => (
          <div className={'eMultiSelectCheckbox'} key={`${item.name}-${index}`}>
            <input
              onChange={() => onChange(index)}
              className="form-check-input"
              type="checkbox"
              checked={item.isChecked}
              id={item.name}
              disabled={isDisabled}
            />
            <label className="form-check-label" htmlFor={item.name}>
              {item.name}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}
