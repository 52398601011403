import * as Moment from 'moment';

export default function dateParser(dateString: string): string {
  const possibleFormats = [
    'DD/MM/YYYY',
    'MM/DD/YYYY',
    'YYYY-MM-DD',
    'DD-MM-YYYY',
    'MM-DD-YYYY',
    'YYYY/MM/DD',
    'D/M/YYYY',
    'M/D/YYYY'
  ];

  const parsedDate = Moment(dateString, possibleFormats, true);
  if (parsedDate.isValid()) {
    return parsedDate.format('YYYY-MM-DD');
  }

  console.error(`Invalid date format: ${dateString}`);
  return dateString;
}
