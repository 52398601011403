import * as React from 'react';
import * as propz from 'propz';
import * as Yup from 'yup';
import * as Moment from 'moment';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import { useRef } from 'react';
import { Field, Form, Formik } from 'formik';
import { USER_GENDER, GENDER_SERVER_TO_CLIENT_MAPPING } from '../../consts/user';
import { Button } from '../Button/Button';
import { Autocomplete } from '../Autocomplete/Autocomplete';
import { AppUser } from 'Src/views/App/App';
import { searchFunctionForms, searchFunctionHouses, getName } from 'Src/helpers/autocomplete/autocomplete';
import { Switch } from '../Switch/Switch';
import { DATE_FORMAT } from '../../consts/date';
import { LabelWithQuestionIcon } from '../LabelWithQuestionIcon/LabelWithQuestionIcon';
import { SchoolStudent } from 'Src/models/schoolStudent';
import { is } from 'bluebird';

interface Props {
  student: any;
  onSubmit: (data: any, isContinue: boolean, isSavingSimilarStudentConfirmed: boolean) => Promise<boolean>;
  onCancel: () => void;
  user: AppUser;
  isCreateStudentsChain: boolean;
  identicalStudents: SchoolStudent[];
  similarStudents: SchoolStudent[];
  isFormsExist: boolean;
  isEditStudent: boolean;
}

const StudentEditSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  form: Yup.string().required('Required'),
  birthday: Yup.date()
});

export const StudentForm: React.FunctionComponent<Props> = props => {
  const {
    student,
    onSubmit,
    onCancel,
    isCreateStudentsChain,
    identicalStudents,
    similarStudents,
    isFormsExist,
    isEditStudent
  } = props;
  const isStudentExist = typeof student !== 'undefined';
  const isEditStudentMode = isEditStudent || (isStudentExist && !isCreateStudentsChain);
  const datePicker = useRef<DatePicker>();
  const isIdenticalStudentsExist = identicalStudents.length > 0;
  const isSimilarStudentsExist = similarStudents.length > 0;

  let isSaveAndContinueClick = false;

  const getForms = (text: string, setFieldValue) => {
    const user = props.user;

    if (text === '') {
      setFieldValue('formId', '');
      setFieldValue('form', undefined);
    }

    return searchFunctionForms(user, text);
  };

  const getHouse = (text: string, setFieldValue) => {
    const user = props.user;

    if (text === '') {
      setFieldValue('houseId', '');
      setFieldValue('house', undefined);
    }

    return searchFunctionHouses(user, text);
  };

  const studentForm = {
    firstName: isEditStudentMode ? propz.get(student, ['firstName'], '') : '',
    lastName: isEditStudentMode ? propz.get(student, ['lastName'], '') : '',
    gender: isEditStudentMode ? propz.get(student, ['gender'], USER_GENDER.MALE) : USER_GENDER.MALE,
    birthday: isEditStudentMode && typeof student.birthday !== 'undefined' ? Moment(student.birthday) : '',
    formId: isEditStudentMode ? propz.get(student, ['formId'], '') : '',
    houseId: isEditStudentMode ? propz.get(student, ['houseId'], '') : '',
    specialEducationNeeds: isEditStudentMode ? propz.get(student, ['specialEducationNeeds'], false) : false,
    pupilsPremium: isEditStudentMode ? propz.get(student, ['pupilsPremium'], false) : false,
    freeSchoolMeals: isEditStudentMode ? propz.get(student, ['freeSchoolMeals'], false) : false,
    photoConsentOptOut: isEditStudentMode ? propz.get(student, ['photoConsentOptOut'], false) : false,
    medicalInfo: isEditStudentMode ? propz.get(student, ['medicalInfo'], '') : '',
    form: isEditStudentMode ? propz.get(student, ['form']) : undefined,
    house: isEditStudentMode ? propz.get(student, ['house']) : undefined
  };

  const onSubmitForm = (values, isContinue, resetForm?) => {
    const dataCopy = { ...values };
    const isResetFormExist = typeof resetForm !== 'undefined';
    const isSavingSimilarStudentConfirmed = isSimilarStudentsExist && !isIdenticalStudentsExist;

    isSaveAndContinueClick = false;

    if (dataCopy.birthday) {
      dataCopy.birthday = Moment(values.birthday).format('YYYY-MM-DD');
    } else {
      delete dataCopy.birthday;
    }

    onSubmit(dataCopy, isContinue, isSavingSimilarStudentConfirmed).then(() => {
      if (isResetFormExist) {
        resetForm();
      }
    });
  };

  const renderSimilarStudents = () => {
    const studentToAddFirstName = propz.get(student, ['firstName'], '');
    const studentToAddLastName = propz.get(student, ['lastName'], '');
    const studentToAddBirthday = propz.get(student, ['birthday'], '');

    const similarStudentsRow = similarStudents.map(similarStudent => {
      const similarStudentFirstName = propz.get(similarStudent, ['firstName'], '');
      const similarStudentLastName = propz.get(similarStudent, ['lastName'], '');
      const similarStudentBirthday = propz.get(similarStudent, ['birthday'], '');

      return (
        <p
          key={similarStudent.id}
          className="text-primary"
        >{`${similarStudentFirstName} ${similarStudentLastName} ${similarStudentBirthday}`}</p>
      );
    });

    return (
      <div>
        <p className="text-danger">
          There seems to be a student with similar details. Please check the data to avoid duplicating students.
        </p>
        <p className="font-weight-bold mb-0">The student you are adding: </p>
        <p className="text-primary">{`${studentToAddFirstName} ${studentToAddLastName} ${studentToAddBirthday}`}</p>
        <p className="font-weight-bold mb-0">The existing student(s): </p>
        {similarStudentsRow}
      </div>
    );
  };

  return (
    <div className="container-fluid">
      {!isFormsExist && (
        <div>
          <p className="text-danger">Please add school forms (classes) before creating students.</p>
          <p className="text-primary">You can add forms via the School &gt; Forms tab.</p>
          <p className="text-primary">
            You can add specific forms or just use forms to reflect age groups, for instance, Year 6, Year 7 etc.
          </p>
        </div>
      )}

      {isIdenticalStudentsExist && (
        <div className="text-danger">
          <p>There is already a student in the system, matching the data you have entered.</p>
          <p>
            If you need to change any additional details like form, house, etc. please select the existing student and
            edit their data.
          </p>
        </div>
      )}

      {isSimilarStudentsExist && !isIdenticalStudentsExist && renderSimilarStudents()}

      <div className="row">
        <div className="col-md-12">
          <Formik
            initialValues={studentForm}
            validationSchema={StudentEditSchema}
            onSubmit={(values, formikActions) => {
              isSaveAndContinueClick
                ? onSubmitForm(values, true, formikActions.resetForm)
                : onSubmitForm(values, false);
            }}
          >
            {({ touched, errors, setFieldValue, values, handleSubmit }) => (
              <Form>
                <LabelWithQuestionIcon labelText="Name" hintText={''} />
                <Field type="text" tabIndex="1" name="firstName" className="form-control mb-3" />
                {touched.firstName && errors.firstName && <div className="alert alert-danger">{errors.firstName}</div>}

                <LabelWithQuestionIcon labelText="Surname" hintText={''} />
                <Field type="text" tabIndex="2" name="lastName" className="form-control mb-3" />
                {touched.lastName && errors.lastName && <div className="alert alert-danger">{errors.lastName}</div>}

                <LabelWithQuestionIcon labelText="Gender" hintText={''} />
                <Field component="select" tabIndex="3" name="gender" className="form-control mb-3">
                  <option value={USER_GENDER.MALE}>{GENDER_SERVER_TO_CLIENT_MAPPING.MALE}</option>
                  <option value={USER_GENDER.FEMALE}>{GENDER_SERVER_TO_CLIENT_MAPPING.FEMALE}</option>
                </Field>

                <LabelWithQuestionIcon labelText="Date of birth" hintText={''} />
                <Field
                  name="birthday"
                  render={({ field }) => {
                    //convert server field birthday
                    let date;

                    if (Moment(values.birthday, DATE_FORMAT).isValid()) {
                      date = Moment(values.birthday, DATE_FORMAT).toDate();
                    }

                    return (
                      <div className="mb-3">
                        <DatePicker
                          // TODO_005: try to close when pressed 'tab' without ref
                          ref={datePicker}
                          selected={date}
                          tabIndex={4}
                          onChange={birthdayDate => {
                            if (birthdayDate !== null) {
                              //empty date
                              setFieldValue('birthday', birthdayDate);
                            } else {
                              setFieldValue('birthday', '');
                            }
                          }}
                          onKeyDown={event => {
                            if (event.key === 'Tab') {
                              datePicker.current.setOpen(false);
                            }
                          }}
                          className="form-control"
                          dateFormat={'dd-MM-yyyy'}
                          customInput={
                            <MaskedInput
                              mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                              placeholder="DD-MM-YYYY"
                            />
                          }
                        />
                      </div>
                    );
                  }}
                />
                {touched.birthday && errors.birthday && <div className="alert alert-danger">{errors.birthday}</div>}

                <LabelWithQuestionIcon labelText="Form" hintText={''} />
                <Field
                  name="form"
                  render={({ field }) => {
                    return (
                      <Autocomplete
                        searchFunction={text => getForms(text, setFieldValue)}
                        getElementTitle={getName}
                        customClass="mFullWidth mb-3"
                        defaultItem={values.form}
                        tabIndex={5}
                        onSelect={form => {
                          setFieldValue('formId', form.id);
                          setFieldValue('form', form);
                        }}
                      />
                    );
                  }}
                />
                {touched.form && errors.form && <div className="alert alert-danger">{errors.form}</div>}

                <LabelWithQuestionIcon labelText="House" hintText={''} />
                <Field
                  name="house"
                  render={({ field }) => {
                    return (
                      <Autocomplete
                        searchFunction={text => getHouse(text, setFieldValue)}
                        getElementTitle={getName}
                        customClass="mFullWidth mb-3"
                        defaultItem={values.house}
                        tabIndex={6}
                        onSelect={house => {
                          setFieldValue('houseId', house.id);
                          setFieldValue('house', house);
                        }}
                      />
                    );
                  }}
                />

                <div className="d-flex">
                  <Field
                    name="specialEducationNeeds"
                    render={({ field }) => <Switch {...field} customClass="mb-3" />}
                  />
                  <LabelWithQuestionIcon
                    labelText="SEN"
                    hintText={''}
                    customLabelClass="form-check-label"
                    labelHtmlFor="isSpecialEducationNeeds"
                  />
                </div>

                <div className="d-flex">
                  <Field name="pupilsPremium" render={({ field }) => <Switch {...field} customClass="mb-3" />} />
                  <LabelWithQuestionIcon
                    labelText="PP"
                    hintText={''}
                    customLabelClass="form-check-label"
                    labelHtmlFor="isPupilsPremium"
                  />
                </div>

                <div className="d-flex">
                  <Field name="freeSchoolMeals" render={({ field }) => <Switch {...field} customClass="mb-3" />} />
                  <LabelWithQuestionIcon
                    labelText="FSM"
                    hintText={''}
                    customLabelClass="form-check-label"
                    labelHtmlFor="isFreeSchoolMeals"
                  />
                </div>

                <div className="d-flex">
                  <Field name="photoConsentOptOut" render={({ field }) => <Switch {...field} customClass="mb-3" />} />
                  <LabelWithQuestionIcon
                    labelText="Photo consent opt-out"
                    hintText={''}
                    customLabelClass="form-check-label"
                    labelHtmlFor="isPhotoConsentOptOut"
                  />
                </div>

                <LabelWithQuestionIcon labelText="Medical information" hintText={''} />
                <Field component="textarea" name="medicalInfo" className="form-control mb-3" />

                <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-2 btn-secondary'} />
                {!isEditStudent && (
                  <Button
                    type="button"
                    text="Save and continue"
                    tabIndex={7}
                    onClick={() => {
                      isSaveAndContinueClick = true;
                      handleSubmit();
                    }}
                    disabled={!isFormsExist}
                    customClass="mr-2"
                  />
                )}

                <Button type="button" text="Save" tabIndex={8} onClick={handleSubmit} disabled={!isFormsExist} />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

StudentForm.displayName = 'StudentForm';
