import axios from 'axios';
import { AppUser } from 'Src/views/App/App';
import { DEFAULT_FILTER } from './user';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from 'Src/consts/table';
import { Count } from '../../../models/count';

export function getIntegrations(user: AppUser, filter = DEFAULT_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter
    }
  };
  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/integrations`, config).then(response => {
    return response.data;
  });
}

export function getAllIntegrations(user: AppUser, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/integrations`, config).then(response => {
    return response.data;
  });
}

export function getIntegrationsCount(user: AppUser, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where
        }
      }
    };
  } else {
    config = {
      headers: { usid: key }
    };
  }

  return axios.get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/integrations/count`, config).then(response => {
    return response.data;
  });
}

export function getCalendarsOfIntegration(user: AppUser, integrationId) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/integrations/${integrationId}/calendars`, config)
    .then(response => {
      return response.data;
    });
}

export function createIntegrationICalendar(user: AppUser) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/integrations/icalendar`, {}, config)
    .then(response => {
      return response.data;
    });
}

export function deleteIntegration(user: AppUser, integrationId) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .delete(`${window.apiBase}/i/schools/${activeSchoolId}/integrations/${integrationId}`, config)
    .then(response => {
      return response.data;
    });
}

export function getUrlToIntegrationGoogleCalendar(user: AppUser) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/integrations/googlecalendar`, {}, config)
    .then(response => {
      return response.data;
    });
}

export function getUrlToIntegrationTwitter(user: AppUser) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios.post(`${window.apiBase}/i/schools/${activeSchoolId}/integrations/twitter`, {}, config).then(response => {
    return response.data;
  });
}

export function getUrlToIntegrationOutlook(user: AppUser) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios.post(`${window.apiBase}/i/schools/${activeSchoolId}/integrations/outlook`, {}, config).then(response => {
    return response.data;
  });
}

export function syncIntegrationICalendar(user: AppUser, integrationId) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/integrations/${integrationId}/icalendar/sync`, {}, config)
    .then(response => {
      return response.data;
    });
}

export function syncIntegrationCalendar(user: AppUser, integrationId) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/integrations/${integrationId}/calendars/sync`, {}, config)
    .then(response => {
      return response.data;
    });
}

export function syncOffIntegrationICalendar(user: AppUser, integrationId) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/integrations/${integrationId}/icalendar/unsync`, {}, config)
    .then(response => {
      return response.data;
    });
}

export function syncOffIntegrationCalendar(user: AppUser, integrationId) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/integrations/${integrationId}/calendars/unsync`, {}, config)
    .then(response => {
      return response.data;
    });
}

export function selectIntegrationCalendar(user: AppUser, integrationId, selectedCalendarIds) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .put(`${window.apiBase}/i/schools/${activeSchoolId}/integrations/${integrationId}`, { selectedCalendarIds }, config)
    .then(response => {
      return response.data;
    });
}

export function createTweet(user: AppUser, twitterId: string, data ) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios.post(`${window.apiBase}/i/schools/${activeSchoolId}/integrations/twitter/${twitterId}/tweet`, data, config).then(response => {
    return response.data;
  });
}