import * as React from 'react';
import { FunctionComponent } from 'react';
import { Autocomplete } from '../Autocomplete/Autocomplete';

interface Props {
  sports: any[];
  getElementTitle: (item) => string;
  searchFunctionSports: (text: string) => void;
  onSelectSport: (house: any) => void;
  onClearSportButtonClick: (id: string) => void;
  onAcceptPermission: () => void;
}

export const RequestAcceptTeacherOrCoach: FunctionComponent<Props> = props => {
  const sports = props.sports.map(sport => {
    return (
      <div key={sport.id} className={'mb-3'}>
        <Autocomplete
          onSelect={() => {}}
          searchFunction={() => {}}
          getElementTitle={props.getElementTitle}
          defaultItem={sport}
          disabled
        />
        <div className="eClearItemButton ml-3">
          <button
            type="button"
            className="close"
            onClick={() => {
              props.onClearSportButtonClick(sport.id);
            }}
          >
            <span>&times;</span>
          </button>
        </div>
      </div>
    );
  });

  return (
    <div className="bRequestAcceptPage">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <h1>Accept coach/teacher permission.</h1>
            <p>
              You are accepting a coach/teacher onto your school's account. Please select the sports that this user will
              have access to once they have been granted permissions. If no sports are selected, the user will have
              access to all sports by default.
            </p>
          </div>
          <div className={'col-md-12'}>
            <p>Allow coach/teacher activities/sports:</p>
            {sports}
            <div className={'mb-3'}>
              <Autocomplete
                onSelect={props.onSelectSport}
                searchFunction={props.searchFunctionSports}
                getElementTitle={props.getElementTitle}
              />
            </div>
          </div>
          <div className={'col-md-12'}>
            <button className="btn btn-primary" onClick={props.onAcceptPermission}>
              Accept permission
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

RequestAcceptTeacherOrCoach.displayName = 'RequestAcceptTeacherOrCoach';
