import {
  SchoolEvent,
  SchoolEventHouseScore,
  SchoolEventSchool,
  SchoolEventSchoolScore,
  SchoolEventTeamData,
  SchoolEventTeamScore
} from '../../models/event';
import { CRICKET_WICKETS } from '../../consts/sport';
import { EVENT_TYPE_TYPE } from '../../types/event';
import { EVENT_TYPES } from '../../consts/event';
import { SchoolHouse } from '../../models/house';
import * as propz from 'propz';
import { CRICKET_RESULT, CRICKET_RESULT_SERVER_TO_CLIENT_MAPPING } from '../../consts/cricket';
import { isHousesEvent, isInternalEvent } from '../event/event';

export function isCricket(event: SchoolEvent): boolean {
  const sportName = propz.get(event, ['sport', 'name']);
  const cricketNames = ['cricket', 'cricket t20', 'cricket sixes', 'kwick cricket', 'kwik cricket'];

  return cricketNames.some(cricketName => cricketName === sportName.toLowerCase());
}

export function convertPointsCricket(countPoints: number): { runs: number; wickets: number } {
  const runs = Math.floor(countPoints);
  const wickets = Math.round(countPoints * 100) % 100;

  return {
    runs: runs,
    wickets: wickets
  };
}

export function getCricketResults(event: SchoolEvent, activeSchoolId: string): string {
  // Cricket - это полная жопа
  const { results, teamsData, eventType, housesData, inviterSchool, invitedSchools } = event;
  const schoolsData = [inviterSchool, ...invitedSchools];

  const { teamScore, houseScore, schoolScore } = results;
  const isCricketResultExist = typeof results.cricketResult !== 'undefined';

  const whoId = isCricketResultExist ? results.cricketResult.who : undefined;
  const result = isCricketResultExist ? results.cricketResult.result : undefined;

  const scores = getScoreForCricket(eventType, teamScore, houseScore, schoolScore);

  const isResultMatchAwarded = result === CRICKET_RESULT.MATCH_AWARDED;
  const isResultTBD = result === CRICKET_RESULT.TBD;
  const isResultTie = result === CRICKET_RESULT.TIE;
  const isResultDraw = result === CRICKET_RESULT.DRAW;
  const isResultNoResult = result === CRICKET_RESULT.NO_RESULT;
  const isResultWonByRuns = result === CRICKET_RESULT.WON_BY_RUNS;
  const isResultWonByWickets = result === CRICKET_RESULT.WON_BY_WICKETS;
  const isResultWonByInningsAndRuns = result === CRICKET_RESULT.WON_BY_INNINGS_AND_RUNS;

  const isTeamFromActiveSchool = isTeamFromActiveSchoolCricket(whoId, activeSchoolId, teamsData, schoolsData, event);
  const teamName =
    typeof whoId !== 'undefined'
      ? getTeamNameCricket(
          whoId,
          teamsData,
          housesData,
          schoolsData,
          eventType,
          isTeamFromActiveSchool,
          isResultMatchAwarded,
          activeSchoolId
        )
      : '';
  const runsAbs = getRuns(scores);
  const wickets = getWickets(scores, whoId, eventType);

  switch (true) {
    case isResultTBD:
      return CRICKET_RESULT_SERVER_TO_CLIENT_MAPPING.TBD;
    case isResultTie:
      return CRICKET_RESULT_SERVER_TO_CLIENT_MAPPING.TIE;
    case isResultDraw:
      return CRICKET_RESULT_SERVER_TO_CLIENT_MAPPING.DRAW;
    case isResultNoResult:
      return CRICKET_RESULT_SERVER_TO_CLIENT_MAPPING.NO_RESULT;
    case isResultWonByRuns && isTeamFromActiveSchool:
      return `${teamName} won by ${runsAbs} runs`;
    case isResultWonByWickets && isTeamFromActiveSchool:
      return `${teamName} won by ${wickets} wickets`;
    case isResultWonByInningsAndRuns && isTeamFromActiveSchool:
      return `${teamName} won by an innings and ${runsAbs} runs`;
    case isResultWonByRuns && !isTeamFromActiveSchool:
      return `Lost, ${teamName} won by ${runsAbs} runs`;
    case isResultWonByWickets && !isTeamFromActiveSchool:
      return `Lost, ${teamName} won by ${wickets} wickets`;
    case isResultWonByInningsAndRuns && !isTeamFromActiveSchool:
      return `Lost, ${teamName} won by an innings and ${runsAbs} runs`;
    case isResultMatchAwarded && isTeamFromActiveSchool:
      return `Match awarded to ${teamName}`;
    case isResultMatchAwarded && !isTeamFromActiveSchool:
      return `Match conceded by ${teamName}`;
    default:
      return `No result yet`;
  }
}

function getScoreForCricket(
  eventType: EVENT_TYPE_TYPE,
  teamScore: SchoolEventTeamScore[],
  houseScore: SchoolEventHouseScore[],
  schoolScore: SchoolEventSchoolScore[]
): (SchoolEventSchoolScore | SchoolEventHouseScore | SchoolEventTeamScore)[] {
  switch (eventType) {
    case EVENT_TYPES.EXTERNAL_SCHOOLS:
      switch (teamScore.length) {
        case 0: //school vs school
          return schoolScore;
        case 1: //school vs team[school]
          return [teamScore[0], schoolScore[0]];
        default:
          //team[school] vs team[school]
          return teamScore;
      }

    case EVENT_TYPES.INTERNAL_HOUSES:
      switch (teamScore.length) {
        case 0: //house vs house
          return houseScore;
        case 1: //house vs team[house]
          return [teamScore[0], houseScore[0]];
        default:
          return teamScore;
      }

    case EVENT_TYPES.INTERNAL_TEAMS:
      return teamScore; //team vs team

    default:
      console.error(`Can not find event type: ${eventType}`);
      return [];
  }
}

function getTeamNameCricket(
  teamId: string,
  teamsData: SchoolEventTeamData[],
  housesData: SchoolHouse[],
  schoolsData: SchoolEventSchool[],
  eventType: string,
  isTeamFromActiveSchoolCricket: boolean,
  isMatchAwarded: boolean,
  activeSchoolId: string
): string {
  switch (eventType) {
    case EVENT_TYPES.EXTERNAL_SCHOOLS: //for inter schools cricket we show only school name
      if (isMatchAwarded && !isTeamFromActiveSchoolCricket) {
        const school = schoolsData.find(school => school.id === activeSchoolId);
        return school.name;
      } else {
        const schoolByTeamId = schoolsData.find(school => school.id === teamId);
        switch (true) {
          case typeof schoolByTeamId !== 'undefined':
            return schoolByTeamId.name;
          case teamsData.length !== 0:
            const team = teamsData.find(team => team.id === teamId);
            const schoolByTeamSchoolId = schoolsData.find(school => school.id === team.schoolId);
            return schoolByTeamSchoolId.name;
          default:
            return 'No school name';
        }
      }

    case EVENT_TYPES.INTERNAL_HOUSES:
      const house = housesData.find(house => house.id === teamId);
      switch (true) {
        case typeof house !== 'undefined':
          return house.name;
        case teamsData.length !== 0:
          const team = teamsData.find(team => team.id === teamId);
          return team.name;
        default:
          return 'No house name';
      }

    case EVENT_TYPES.INTERNAL_TEAMS:
      const team = teamsData.find(team => team.id === teamId);
      if (typeof team !== 'undefined') {
        return team.name;
      } else {
        return 'No team name';
      }

    default:
      console.error(`Error: Event type - ${eventType}`);
      return '';
  }
}

function isTeamFromActiveSchoolCricket(
  teamId: string,
  activeSchoolId: string,
  teamsData,
  schoolsData,
  event: SchoolEvent
): boolean {
  const teamsDataFiltered = teamsData.filter(team => team.schoolId === activeSchoolId);

  switch (true) {
    case isInternalEvent(event):
    case isHousesEvent(event):
      return true;
    case teamsDataFiltered.length === 0:
      const school = schoolsData.find(school => school.id === activeSchoolId);
      return school && teamId === school.id;
    case teamsDataFiltered.length === 1: //for EXTERNAL_SCHOOLS matches only 1 team may be from active school
      return teamId === teamsDataFiltered[0].schoolId || teamId === teamsDataFiltered[0].id; //teamId maybe schoolId or just id
    default:
      //for INTERNAL_TEAMS and INTERNAL_HOUSES matches 2 teams may be from active school
      return true; //for INTERNAL_TEAMS and INTERNAL_HOUSES we set flag isTeamFromActiveSchoolCricket in true
  }
}

//We get the difference module of the runs, because we only care about this, then we display text result of game
function getRuns(scores: (SchoolEventSchoolScore | SchoolEventHouseScore | SchoolEventTeamScore)[]) {
  if (scores.length !== 0) {
    return Math.abs(Math.floor(scores[0].score) - Math.floor(scores[1].score));
  } else {
    return 0;
  }
}

//We get wickets from team score, as (10 - wickets winner team)
function getWickets(
  scores: (SchoolEventSchoolScore | SchoolEventHouseScore | SchoolEventTeamScore)[],
  whoId: string,
  eventType: string
) {
  switch (eventType) {
    case EVENT_TYPES.EXTERNAL_SCHOOLS:
      if (scores.findIndex(score => (score as SchoolEventSchoolScore).schoolId === whoId) === -1) {
        scores = scores.filter(score => (score as SchoolEventTeamScore).teamId === whoId);
      } else {
        scores = scores.filter(score => (score as SchoolEventSchoolScore).schoolId === whoId);
      }
      break;
    case EVENT_TYPES.INTERNAL_TEAMS:
      scores = scores.filter(score => (score as SchoolEventTeamScore).teamId === whoId);
      break;
    case EVENT_TYPES.INTERNAL_HOUSES:
      if (scores.findIndex(score => (score as SchoolEventHouseScore).houseId === whoId) === -1) {
        scores = scores.filter(score => (score as SchoolEventTeamScore).teamId === whoId);
      } else {
        scores = scores.filter(score => (score as SchoolEventHouseScore).houseId === whoId);
      }
      break;
  }

  if (scores.length !== 0) {
    return CRICKET_WICKETS - (Math.round(scores[0].score * 100) % 100);
  } else {
    return 0;
  }
}
