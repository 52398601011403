import { SUBSCRIPTION_PLAN } from './school';
import { SCHOOL_SUBSCRIPTION_TYPE } from '../types/school';
import { SidebarItemExpandable, SimpleSidebarItem } from '../components/Sidebar/Sidebar';
import { DEFAULT_CLUB_TABLE_FILTERS } from '../views/GenericView/AdminView/Clubs/Clubs/Clubs';

const ALL_SUBSCRIPTIONS_PLAN: SCHOOL_SUBSCRIPTION_TYPE[] = [
  SUBSCRIPTION_PLAN.LITE,
  SUBSCRIPTION_PLAN.FULL,
  SUBSCRIPTION_PLAN.TOURNAMENTS_PARTICIPATION,
  SUBSCRIPTION_PLAN.FREE_COMMUNICATION
];

const FREE_AND_FULL_SUBSCRIPTION_PLAN: SCHOOL_SUBSCRIPTION_TYPE[] = [
  SUBSCRIPTION_PLAN.FULL,
  SUBSCRIPTION_PLAN.FREE_COMMUNICATION
];

export function getSidebarItemsAdmin(countBadges: {
  requests: string;
  bookings: string;
  inboxMessages: string;
  invites: string;
  waitingList: string;
  upcomingTournaments: string;
}): (SidebarItemExpandable | SimpleSidebarItem)[] {
  return [
    // {
    //   // Dashboard
    //   itemTitle: 'Dashboard',
    //   to: { pathname: '/dashboard' },
    //   visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
    // },
    {
      // Calendar
      itemTitle: 'Calendar',
      to: { pathname: '/calendar' },
      visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
    },
    {
      // Users
      title: 'Users',
      items: [
        {
          to: { pathname: '/users' },
          itemTitle: 'All users and contacts',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/staff' },
          itemTitle: 'Staff',
          visibleToSchoolSubscriptions: [SUBSCRIPTION_PLAN.FULL]
        },
        {
          to: { pathname: '/students' },
          itemTitle: 'Students',
          visibleToSchoolSubscriptions: [SUBSCRIPTION_PLAN.FULL]
        },
        {
          to: { pathname: '/governors' },
          itemTitle: 'Trustees/Governors',
          visibleToSchoolSubscriptions: [SUBSCRIPTION_PLAN.FULL]
        },
        {
          to: { pathname: '/tags' },
          itemTitle: 'User tags',
          visibleToSchoolSubscriptions: FREE_AND_FULL_SUBSCRIPTION_PLAN
        },
        {
          to: { pathname: '/requests' },
          itemTitle: `New Requests`,
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN,
          notificationCount: countBadges.requests
        },
        {
          to: { pathname: '/archive' },
          itemTitle: 'Requests archive',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/import-students' },
          itemTitle: 'Import Students',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        }
      ]
    },

    {
      // Communications
      title: 'Communications',
      items: [
        {
          to: { pathname: '/generalMessages' },
          itemTitle: 'General Messages',
          visibleToSchoolSubscriptions: FREE_AND_FULL_SUBSCRIPTION_PLAN
        },
        {
          to: { pathname: '/notifications' },
          itemTitle: 'Notifications',
          visibleToSchoolSubscriptions: FREE_AND_FULL_SUBSCRIPTION_PLAN
        },
        {
          to: { pathname: '/news' },
          itemTitle: 'News',
          visibleToSchoolSubscriptions: FREE_AND_FULL_SUBSCRIPTION_PLAN
        }
      ]
    },

    {
      // School
      title: 'School',
      items: [
        {
          to: { pathname: '/forms' },
          itemTitle: 'Forms',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/houses' },
          itemTitle: 'Houses',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/venues' },
          itemTitle: 'Venues',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        }
      ]
    },

    {
      // Admin console
      title: 'Admin console',
      items: [
        {
          to: { pathname: '/school/settings/base' },
          itemTitle: 'School settings',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/school/settings/advanced' },
          itemTitle: 'Advanced settings',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/school/terms' },
          itemTitle: 'Term dates',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/template/club' },
          itemTitle: 'Club template questions',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/template/consentRequests' },
          itemTitle: 'Consent requests template questions',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/activities' },
          itemTitle: 'Activities/Sports',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/schoolGallery' },
          itemTitle: 'School gallery',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/subscriptions' },
          itemTitle: 'Subscriptions',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/notificationSettings' },
          itemTitle: 'Notification settings',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/accounts' },
          itemTitle: 'Payment accounts',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/integrations' },
          itemTitle: 'Integrations',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        }
      ]
    },
    {
      // Events
      title: 'Teams & Events',
      items: [
        {
          to: { pathname: '/teams' },
          itemTitle: 'Teams',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/events' },
          itemTitle: 'All events',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        }
      ]
    },
    {
      // Leagues & Competitions
      title: 'Leagues & Competitions',
      items: [
        {
          to: { pathname: '/leagues' },
          itemTitle: 'School Leagues',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        }
      ]
    },
    {
      // Clubs
      title: 'Clubs',
      items: [
        {
          to: { pathname: '/clubs', search: DEFAULT_CLUB_TABLE_FILTERS },
          itemTitle: 'Clubs',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/invitations' },
          itemTitle: 'Invitations',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        }
      ]
    },
    {
      // Messages
      title: 'Messages',
      items: [
        {
          to: { pathname: '/messages/inbox' },
          itemTitle: 'Inbox',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN,
          notificationCount: countBadges.inboxMessages
        },
        {
          to: { pathname: '/messages/outbox' },
          itemTitle: 'Outbox',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/messages/archive' },
          itemTitle: 'Archive',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        }
      ]
    },
    {
      // Invites
      title: 'Invites',
      items: [
        {
          to: { pathname: '/invites/inbox' },
          itemTitle: 'Inbox',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN,
          notificationCount: countBadges.invites
        },
        {
          to: { pathname: '/invites/outbox' },
          itemTitle: 'Outbox',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/invites/archive' },
          itemTitle: 'Archive',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        }
      ]
    },
    // {
    // Statistics
    // title: 'Statistics',
    // items: [
    // {
    // to: { pathname: '/clubParticipation' },
    // itemTitle: 'Club participation',
    // visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
    // },
    // {
    // to: { pathname: '/fixtureParticipation' },
    // itemTitle: 'Fixture participation',
    // visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
    // }
    // ]
    // },
    {
      title: 'Tournaments',
      items: [
        {
          to: { pathname: '/tournaments/upcoming' },
          itemTitle: 'Upcoming tournaments',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN,
          notificationCount: countBadges.upcomingTournaments
        },
        {
          to: { pathname: '/tournaments/waitingList' },
          itemTitle: 'Waiting list',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN,
          notificationCount: countBadges.waitingList
        },
        {
          to: { pathname: '/tournaments/archive' },
          itemTitle: 'Tournaments archive',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        }
      ]
    }
  ];
}

export function getSidebarItemsManager(countBadges: {
  requests: string;
  inboxMessages: string;
  invites: string;
}): (SidebarItemExpandable | SimpleSidebarItem)[] {
  return [
    // {
    //   // Dashboard
    //   itemTitle: 'Dashboard',
    //   to: { pathname: '/dashboard' },
    //   visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
    // },
    {
      // Calendar
      itemTitle: 'Calendar',
      to: { pathname: '/calendar' },
      visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
    },
    {
      // Users
      title: 'Users',
      items: [
        {
          to: { pathname: '/users' },
          itemTitle: 'All users and contacts',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/staff' },
          itemTitle: 'Staff',
          visibleToSchoolSubscriptions: [SUBSCRIPTION_PLAN.FULL]
        },
        {
          to: { pathname: '/students' },
          itemTitle: 'Students',
          visibleToSchoolSubscriptions: [SUBSCRIPTION_PLAN.FULL]
        },
        {
          to: { pathname: '/governors' },
          itemTitle: 'Trustees/Governors',
          visibleToSchoolSubscriptions: [SUBSCRIPTION_PLAN.FULL]
        },
        {
          to: { pathname: '/tags' },
          itemTitle: 'User tags',
          visibleToSchoolSubscriptions: FREE_AND_FULL_SUBSCRIPTION_PLAN
        },
        {
          to: { pathname: '/requests' },
          itemTitle: `New Requests`,
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN,
          notificationCount: countBadges.requests
        },
        {
          to: { pathname: '/archive' },
          itemTitle: 'Requests archive',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        }
      ]
    },

    {
      // Communications
      title: 'Communications',
      items: [
        {
          to: { pathname: '/generalMessages' },
          itemTitle: 'General Messages',
          visibleToSchoolSubscriptions: FREE_AND_FULL_SUBSCRIPTION_PLAN
        },
        {
          to: { pathname: '/notifications' },
          itemTitle: 'Notifications',
          visibleToSchoolSubscriptions: FREE_AND_FULL_SUBSCRIPTION_PLAN
        },
        {
          to: { pathname: '/news' },
          itemTitle: 'News',
          visibleToSchoolSubscriptions: FREE_AND_FULL_SUBSCRIPTION_PLAN
        }
      ]
    },
    {
      // School
      title: 'School',
      items: [
        {
          to: { pathname: '/forms' },
          itemTitle: 'Forms',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/houses' },
          itemTitle: 'Houses',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        }
      ]
    },
    {
      // Events
      title: 'Teams & Events',
      items: [
        {
          to: { pathname: '/teams' },
          itemTitle: 'Teams',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/events' },
          itemTitle: 'All events',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        }
      ]
    },
    {
      // Leagues & Competitions
      title: 'Leagues & Competitions',
      items: [
        {
          to: { pathname: '/leagues' },
          itemTitle: 'School Leagues',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        }
      ]
    },
    {
      // Clubs
      title: 'Clubs',
      items: [
        {
          to: { pathname: '/clubs', search: DEFAULT_CLUB_TABLE_FILTERS },
          itemTitle: 'Clubs',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/invitations' },
          itemTitle: 'Invitations',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        }
      ]
    },
    {
      // Messages
      title: 'Messages',
      items: [
        {
          to: { pathname: '/messages/inbox' },
          itemTitle: 'Inbox',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN,
          notificationCount: countBadges.inboxMessages
        },
        {
          to: { pathname: '/messages/outbox' },
          itemTitle: 'Outbox',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/messages/archive' },
          itemTitle: 'Archive',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        }
      ]
    },
    {
      // Invites
      title: 'Invites',
      items: [
        {
          to: { pathname: '/invites/inbox' },
          itemTitle: 'Inbox',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN,
          notificationCount: countBadges.invites
        },
        {
          to: { pathname: '/invites/outbox' },
          itemTitle: 'Outbox',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/invites/archive' },
          itemTitle: 'Archive',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        }
      ]
    }
    // {
    // Statistics
    // title: 'Statistics',
    // items: [
    // {
    // to: { pathname: '/clubParticipation' },
    // itemTitle: 'Club participation',
    // visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
    // },
    // {
    // to: { pathname: '/fixtureParticipation' },
    // itemTitle: 'Fixture participation',
    // visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
    // }
    // ]
    // }
  ];
}

export function getSidebarItemsParent(countBadges: {
  requests: string;
  bookings: string;
  inboxMessages: string;
  invites: string;
  availabilityReports: string;
  absenceRequests: string;
}) {
  return [
    {
      // Calendar
      itemTitle: 'Calendar',
      to: { pathname: '/calendar' },
      visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
    },
    {
      // Children
      itemTitle: 'Children',
      to: { pathname: '/children' },
      visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
    },
    {
      // Consent requests
      title: 'Consent requests',
      items: [
        {
          to: { pathname: '/consentRequests/inbox' },
          itemTitle: 'Inbox',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN,
          notificationCount: countBadges.requests
        },
        {
          to: { pathname: '/consentRequests/archive' },
          itemTitle: 'Archive',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        }
      ]
    },
    {
      // Club bookings
      title: 'Club bookings',
      items: [
        {
          to: { pathname: '/clubBookings/inbox' },
          itemTitle: 'Inbox',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN,
          notificationCount: countBadges.bookings
        },
        {
          to: { pathname: '/clubBookings/archive' },
          itemTitle: 'Archive',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        }
      ]
    },
    {
      // Availability reports
      title: 'Availability reports',
      items: [
        {
          to: { pathname: '/availabilityReports/outbox' },
          itemTitle: 'Outbox',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN,
          notificationCount: countBadges.availabilityReports
        },
        {
          to: { pathname: '/availabilityReports/archive' },
          itemTitle: 'Archive',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        }
      ]
    },
    {
      // Absence requests
      title: 'Absence requests',
      items: [
        {
          to: { pathname: '/absenceRequests/outbox' },
          itemTitle: 'Outbox',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN,
          notificationCount: countBadges.absenceRequests
        },
        {
          to: { pathname: '/absenceRequests/archive' },
          itemTitle: 'Archive',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        }
      ]
    }
  ];
}

export function getSidebarItemsStudent(countBadges: {
  requests: string;
  inboxMessages: string;
  invites: string;
  bookings: string;
  availabilityReports: string;
}) {
  return [
    {
      // Calendar
      itemTitle: 'Calendar',
      to: { pathname: '/calendar' },
      visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
    },
    {
      // Club bookings
      title: 'Club bookings',
      items: [
        {
          to: { pathname: '/clubBookings/inbox' },
          itemTitle: 'Inbox',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN,
          notificationCount: countBadges.bookings
        },
        {
          to: { pathname: '/clubBookings/archive' },
          itemTitle: 'Archive',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        }
      ]
    },
    {
      // Availability reports
      title: 'Availability reports',
      items: [
        {
          to: { pathname: '/availabilityReports/outbox' },
          itemTitle: 'Outbox',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN,
          notificationCount: countBadges.availabilityReports
        },
        {
          to: { pathname: '/availabilityReports/archive' },
          itemTitle: 'Archive',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        }
      ]
    }
  ];
}

export function getSidebarItemsCoach(countBadges: { requests: string; inboxMessages: string; invites: string }) {
  return [
    // {
    //   // Dashboard
    //   itemTitle: 'Dashboard',
    //   to: { pathname: '/dashboard' },
    //   visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
    // },
    {
      // Calendar
      itemTitle: 'Calendar',
      to: { pathname: '/calendar' },
      visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
    },
    {
      // Events
      title: 'Teams & Events',
      items: [
        {
          to: { pathname: '/teams' },
          itemTitle: 'Teams',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/events' },
          itemTitle: 'All events',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        }
      ]
    },
    {
      // Leagues & Competitions
      title: 'Leagues & Competitions',
      items: [
        {
          to: { pathname: '/leagues' },
          itemTitle: 'School Leagues',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        }
      ]
    },
    {
      // Clubs
      title: 'Clubs',
      items: [
        {
          to: { pathname: '/clubs', search: DEFAULT_CLUB_TABLE_FILTERS },
          itemTitle: 'Clubs',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/invitations' },
          itemTitle: 'Invitations',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        }
      ]
    },
    {
      // Messages
      title: 'Messages',
      items: [
        {
          to: { pathname: '/messages/inbox' },
          itemTitle: 'Inbox',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN,
          notificationCount: countBadges.inboxMessages
        },
        {
          to: { pathname: '/messages/outbox' },
          itemTitle: 'Outbox',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/messages/archive' },
          itemTitle: 'Archive',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        }
      ]
    },
    {
      // Invites
      title: 'Invites',
      items: [
        {
          to: { pathname: '/invites/inbox' },
          itemTitle: 'Inbox',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN,
          notificationCount: countBadges.invites
        },
        {
          to: { pathname: '/invites/outbox' },
          itemTitle: 'Outbox',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/invites/archive' },
          itemTitle: 'Archive',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        }
      ]
    }
  ];
}

export function getSidebarItemsTeacher(countBadges: { requests: string; inboxMessages: string; invites: string }) {
  return [
    // {
    //   // Dashboard
    //   itemTitle: 'Dashboard',
    //   to: { pathname: '/dashboard' },
    //   visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
    // },
    {
      // Calendar
      itemTitle: 'Calendar',
      to: { pathname: '/calendar' },
      visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
    },
    {
      // Events
      title: 'Teams & Events',
      items: [
        {
          to: { pathname: '/teams' },
          itemTitle: 'Teams',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/events' },
          itemTitle: 'All events',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        }
      ]
    },
    {
      // Leagues & Competitions
      title: 'Leagues & Competitions',
      items: [
        {
          to: { pathname: '/leagues' },
          itemTitle: 'School Leagues',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        }
      ]
    },
    {
      // Clubs
      title: 'Clubs',
      items: [
        {
          to: { pathname: '/clubs', search: DEFAULT_CLUB_TABLE_FILTERS },
          itemTitle: 'Clubs',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/invitations' },
          itemTitle: 'Invitations',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        }
      ]
    },
    {
      // Messages
      title: 'Messages',
      items: [
        {
          to: { pathname: '/messages/inbox' },
          itemTitle: 'Inbox',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN,
          notificationCount: countBadges.inboxMessages
        },
        {
          to: { pathname: '/messages/outbox' },
          itemTitle: 'Outbox',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/messages/archive' },
          itemTitle: 'Archive',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        }
      ]
    },
    {
      // Invites
      title: 'Invites',
      items: [
        {
          to: { pathname: '/invites/inbox' },
          itemTitle: 'Inbox',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN,
          notificationCount: countBadges.invites
        },
        {
          to: { pathname: '/invites/outbox' },
          itemTitle: 'Outbox',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/invites/archive' },
          itemTitle: 'Archive',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        }
      ]
    }
    // {
    // Statistics
    // title: 'Statistics',
    // items: [
    // {
    // to: { pathname: '/clubParticipation' },
    // itemTitle: 'Club participation',
    // visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
    // },
    // {
    // to: { pathname: '/fixtureParticipation' },
    // itemTitle: 'Fixture participation',
    // visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
    // }
    // ]
    // }
  ];
}

export function getSidebarItemsAdminSU() {
  return [
    {
      // Championships
      to: { pathname: '/championships' },
      itemTitle: 'Championships',
      visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
    },
    {
      // Tournaments
      title: 'Tournaments',
      items: [
        {
          to: { pathname: '/tournaments/upcoming' },
          itemTitle: 'Upcoming tournaments',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/tournaments/archive' },
          itemTitle: 'Tournaments archive',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        }
      ]
    },
    {
      // Tournaments
      title: 'High-level tournaments',
      items: [
        {
          to: { pathname: '/highLevelTournaments/upcoming' },
          itemTitle: 'Upcoming high-level tournaments',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/highLevelTournaments/archive' },
          itemTitle: 'High-level tournaments archive',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        }
      ]
    },
    {
      // Schools
      title: 'Schools',
      items: [
        {
          to: { pathname: '/schools' },
          itemTitle: 'Schools',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/schoolsAdmins' },
          itemTitle: 'Schools admins',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        }
      ]
    },
    {
      // Subscriptions
      title: 'Admin console',
      items: [
        {
          to: { pathname: '/venues' },
          itemTitle: 'Venues',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/notificationSettings' },
          itemTitle: 'Notification settings',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/template/tournamentRequests' },
          itemTitle: 'Tournament confirmation requests',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/tournamentTags' },
          itemTitle: 'Tournament tags',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/accounts' },
          itemTitle: 'Payment accounts',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/records' },
          itemTitle: 'Records',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/documents' },
          itemTitle: 'Downloadable documents',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        }
      ]
    },
    {
      // Messages
      title: 'Invites',
      items: [
        // {
        //   to: { pathname: '/invites/inbox' },                             //   most likely it will be necessary
        //   itemTitle: 'Inbox',
        //   visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        // },
        {
          to: { pathname: '/invites/outbox' },
          itemTitle: 'Outbox',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        },
        {
          to: { pathname: '/invites/archive' },
          itemTitle: 'Archive',
          visibleToSchoolSubscriptions: ALL_SUBSCRIPTIONS_PLAN
        }
      ]
    }
  ];
}
