import * as React from 'react';
import { Tournament } from 'Src/models/tournament';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button } from 'Src/components/Button/Button';
import DatePicker from 'react-datepicker';
import {
  getSchoolName,
  getName,
  getNameByPlaceOrPostcode,
  getSchoolNameAndTeamName,
  searchFunctionPlacesAndPostcodes,
  searchFunctionSports,
  searchFunctionTournamentParticipants,
  searchFunctionTournamentTeams
} from 'Src/helpers/autocomplete/autocomplete';
import { GENDER, SPORT_GENDER_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/common';
import { Autocomplete } from 'Src/components/Autocomplete/Autocomplete';
import { AppUser } from 'Src/views/App/App';
import { isIndividualTournament, isMiniTeamTournament, isTeamTournament } from 'Src/helpers/tournament/tournament';
import { Multiselect } from 'Src/components/Multiselect/Multiselect';
import { getSelectOptionForTournamentEventAgesGroup } from 'Src/helpers/table/select';

interface Props {
  tournament: Tournament;
  onSubmit: (data: any) => void;
  onCancel: () => void;
  user: AppUser;
  participants: any[];
}

interface State {
  teams: any[];
  participants: any[];
  ages: number[];
  gender: string;
}

export class TournamentAddEventForm extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    const { tournament } = props;
    const { ages } = tournament;

    const gender = this.getGender();

    this.state = {
      teams: [],
      participants: props.participants,
      ages: ages,
      gender: gender
    };
  }

  getEventSchema() {
    const { tournament } = this.props;

    let schema;

    switch (true) {
      case isTeamTournament(tournament):
      case isMiniTeamTournament(tournament):
        schema = Yup.object().shape({
          start: Yup.date().max(Yup.ref('end'), 'The start date/time must be earlier than the finish date/time.'),
          end: Yup.date().min(Yup.ref('start'), 'The finish date/time must be later than the start date/time.'),
          sportId: Yup.string().required('Required'),
          gender: Yup.string().required('Required'),
          groupId: Yup.string().required('Required'),
          teams: Yup.array()
            .min(2)
            .max(2)
        });
        break;
      case isIndividualTournament(tournament):
        schema = Yup.object().shape({
          start: Yup.date().max(Yup.ref('end'), 'The start date/time must be earlier than the finish date/time.'),
          end: Yup.date().min(Yup.ref('start'), 'The finish date/time must be later than the start date/time.'),
          sportId: Yup.string().required('Required'),
          gender: Yup.string().required('Required')
        });
        break;
      default:
        schema = Yup.object().shape({
          start: Yup.date().max(Yup.ref('end'), 'The start date/time must be earlier than the finish date/time.'),
          end: Yup.date().min(Yup.ref('start'), 'The finish date/time must be later than the start date/time.'),
          sportId: Yup.string().required('Required'),
          gender: Yup.string().required('Required')
        });
        break;
    }

    return schema;
  }

  renderGenderOptions(sport) {
    const { tournament } = this.props;
    const { isMixedTeamsAvailable } = tournament;

    if (typeof sport === 'undefined') {
      return <option value={''}>Select game</option>;
    }

    let genderOptions = [];

    if (sport.genders.maleOnly) {
      genderOptions.push(
        <option key="gender_option_male" value={GENDER.MALE_ONLY}>
          {SPORT_GENDER_SERVER_TO_CLIENT_MAPPING.MALE_ONLY}
        </option>
      );
    }

    if (sport.genders.femaleOnly) {
      genderOptions.push(
        <option key="gender_option_female" value={GENDER.FEMALE_ONLY}>
          {SPORT_GENDER_SERVER_TO_CLIENT_MAPPING.FEMALE_ONLY}
        </option>
      );
    }

    if (sport.genders.mixed && isMixedTeamsAvailable) {
      genderOptions.push(
        <option key="gender_option_mixed" value={GENDER.MIXED}>
          {SPORT_GENDER_SERVER_TO_CLIENT_MAPPING.MIXED}
        </option>
      );
    }

    return genderOptions;
  }

  renderCustomGroupsOptions() {
    const { tournament } = this.props;
    const customGroups = tournament.customGroups || [];

    if (customGroups.length === 0) {
      return <option value={''}>Not active option</option>;
    }

    return customGroups.map(customGroup => {
      return (
        <option key={customGroup.id} value={customGroup.id}>
          {customGroup.name}
        </option>
      );
    });
  }

  getSports = (text: string) => {
    const user = this.props.user;
    return searchFunctionSports(user, text);
  };

  getVenues = (text: string) => {
    const user = this.props.user;
    return searchFunctionPlacesAndPostcodes(user, text);
  };

  getGender() {
    const { tournament } = this.props;
    const { gender, isMixedTeamsAvailable } = tournament;
    const isGenderMixed = gender === GENDER.MIXED;

    switch (true) {
      case isGenderMixed && !isMixedTeamsAvailable:
        return GENDER.MALE_ONLY;
      default:
        return gender;
    }
  }

  getEventInitial() {
    const { tournament, participants } = this.props;
    const { startTime, sportId, sport, ages, venue } = tournament;

    const gender = this.getGender();

    const startTimeDateObj = new Date(startTime);
    const endTimeDateObj = new Date(startTimeDateObj);
    endTimeDateObj.setHours(startTimeDateObj.getHours() + 1);

    const customGroups = tournament.customGroups || [];

    let eventInitial;

    switch (true) {
      case isTeamTournament(tournament):
      case isMiniTeamTournament(tournament):
        eventInitial = {
          start: startTimeDateObj,
          end: endTimeDateObj,
          sport: sport,
          sportId: sportId,
          gender: gender,
          ages: ages,
          groupId: customGroups.length === 0 ? '' : customGroups[0].id,
          venue: venue,
          teams: []
        };
        break;
      case isIndividualTournament(tournament):
        eventInitial = {
          start: startTimeDateObj,
          end: endTimeDateObj,
          sport: sport,
          sportId: sportId,
          gender: gender,
          ages: ages,
          venue: venue,
          participants: participants
        };
        break;
      default:
        eventInitial = {
          start: startTimeDateObj,
          end: endTimeDateObj,
          sport: sport,
          sportId: sportId,
          gender: gender,
          ages: ages,
          venue: venue
        };
    }

    return eventInitial;
  }

  renderTeams(teams, setFieldValue) {
    return teams.map(team => {
      return (
        <div key={team.id} className={'mb-3'}>
          <Autocomplete
            onSelect={() => {}}
            searchFunction={() => {}}
            getElementTitle={getSchoolNameAndTeamName}
            defaultItem={team}
            disabled
          />
          <div className="eClearItemButton ml-3">
            <button
              type="button"
              className="close"
              onClick={() => {
                const teamsFiltered = teams.filter(t => t.id !== team.id);
                setFieldValue('teams', teamsFiltered);
                this.setState({
                  teams: teamsFiltered
                });
              }}
            >
              <span>&times;</span>
            </button>
          </div>
        </div>
      );
    });
  }

  renderParticipants(participants, setFieldValue) {
    return participants.map(participant => {
      return (
        <div key={participant.schoolId} className={'mb-3'}>
          <Autocomplete
            onSelect={() => {}}
            searchFunction={() => {}}
            getElementTitle={getSchoolName}
            defaultItem={participant}
            disabled
          />
          <div className="eClearItemButton ml-3">
            <button
              type="button"
              className="close"
              onClick={() => {
                const participantsFiltered = participants.filter(p => p.id !== participant.id);
                setFieldValue('participants', participantsFiltered);
                this.setState({
                  participants: participantsFiltered
                });
              }}
            >
              <span>&times;</span>
            </button>
          </div>
        </div>
      );
    });
  }

  getTeams = (text: string) => {
    const { teams, ages, gender } = this.state;
    const { user, tournament } = this.props;

    return searchFunctionTournamentTeams(user, text, tournament, teams, ages, gender);
  };

  getSchools = (text: string) => {
    const { participants } = this.state;
    const { user, tournament } = this.props;

    return searchFunctionTournamentParticipants(user, text, tournament, participants);
  };

  render() {
    const eventInitial = this.getEventInitial();
    const { tournament, user } = this.props;
    const { gender } = tournament;
    const { teams } = this.state;

    const isTeamOrMiniTeam = isTeamTournament(tournament) || isMiniTeamTournament(tournament);
    const isGenderMixed = gender === GENDER.MIXED;

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <Formik
              initialValues={eventInitial}
              validationSchema={this.getEventSchema()}
              onSubmit={values => {
                this.props.onSubmit(values);
              }}
              render={({ touched, errors, setFieldValue, values }) => (
                <Form>
                  <label>Start time</label>
                  <Field
                    name="start"
                    render={({ field }) => {
                      return (
                        <div className="mb-3">
                          <DatePicker
                            selected={field.value}
                            onChange={startDate => {
                              setFieldValue('start', startDate);
                              const end = new Date(startDate);
                              end.setHours(startDate.getHours() + 1, startDate.getMinutes());
                              setFieldValue('end', end);
                            }}
                            className="form-control"
                            dateFormat={'dd-MM-yyyy HH:mm'}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={5}
                            timeCaption="time"
                          />
                        </div>
                      );
                    }}
                  />
                  {touched.start && errors.start ? <div className="alert alert-danger">{errors.start}</div> : null}

                  <label>Finish/Collection time</label>
                  <Field
                    name="end"
                    render={({ field }) => {
                      return (
                        <div className="mb-3">
                          <DatePicker
                            selected={field.value}
                            onChange={endDate => {
                              setFieldValue('end', endDate);
                            }}
                            className="form-control"
                            dateFormat={'dd-MM-yyyy HH:mm'}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={5}
                            timeCaption="time"
                          />
                        </div>
                      );
                    }}
                  />
                  {touched.end && errors.end ? <div className="alert alert-danger">{errors.end}</div> : null}

                  <label>Game</label>
                  <Field
                    name="sport"
                    render={({ field }) => {
                      return (
                        <Autocomplete
                          disabled={isTeamTournament(tournament)}
                          searchFunction={this.getSports}
                          getElementTitle={getName}
                          customClass="mFullWidth mb-3"
                          defaultItem={values.sport}
                          onSelect={sport => {
                            setFieldValue('sportId', sport.id);
                            setFieldValue('sport', sport);

                            switch (true) {
                              case sport.genders.maleOnly:
                                setFieldValue('gender', GENDER.MALE_ONLY);
                                this.setState({
                                  gender: GENDER.MALE_ONLY
                                });
                                break;
                              case sport.genders.femaleOnly:
                                setFieldValue('gender', GENDER.FEMALE_ONLY);
                                this.setState({
                                  gender: GENDER.FEMALE_ONLY
                                });
                                break;
                              case sport.genders.mixed:
                                setFieldValue('gender', GENDER.MIXED);
                                this.setState({
                                  gender: GENDER.MIXED
                                });
                                break;
                            }
                          }}
                        />
                      );
                    }}
                  />
                  {touched.sportId && errors.sportId ? (
                    <div className="alert alert-danger">{errors.sportId}</div>
                  ) : null}

                  <label>Gender</label>
                  <Field
                    disabled={!isGenderMixed}
                    component="select"
                    name="gender"
                    className="form-control mb-3"
                    onChange={event => {
                      const value = event.target.value;
                      setFieldValue('gender', value);
                      setFieldValue('teams', []);
                      this.setState({
                        gender: value,
                        teams: []
                      });
                    }}
                  >
                    {this.renderGenderOptions(values.sport)}
                  </Field>
                  {touched.gender && errors.gender ? <div className="alert alert-danger">{errors.gender}</div> : null}

                  <div className="form-group">
                    <label>Ages</label>
                    <Field
                      name="ages"
                      render={({ field }) => {
                        return (
                          <Multiselect
                            items={getSelectOptionForTournamentEventAgesGroup(tournament, user)}
                            onChange={options => {
                              setFieldValue('ages', options);
                              setFieldValue('teams', []);
                              this.setState({
                                ages: options,
                                teams: []
                              });
                            }}
                            value={field.value || []}
                          />
                        );
                      }}
                    />
                  </div>
                  {touched.ages && errors.ages ? <div className="alert alert-danger">{errors.ages}</div> : null}

                  {isTeamOrMiniTeam && (
                    <div className="form-group">
                      <label>Group name</label>
                      <Field name="groupId" component="select" className="form-control mb-3">
                        {this.renderCustomGroupsOptions()}
                      </Field>
                    </div>
                  )}

                  {isTeamOrMiniTeam && touched.groupId && errors.groupId ? (
                    <div className="alert alert-danger">{errors.groupId}</div>
                  ) : null}

                  {isIndividualTournament(tournament) && (
                    <div className="form-group">
                      <label>Choose schools</label>
                      {this.renderParticipants(values.participants, setFieldValue)}
                      <Field
                        name="schools"
                        render={({ field }) => {
                          return (
                            <Autocomplete
                              searchFunction={this.getSchools}
                              getElementTitle={getSchoolName}
                              customClass="mFullWidth mb-3"
                              defaultItem={undefined}
                              onSelect={participant => {
                                const nextParticipants = [].concat(values.participants, participant);
                                setFieldValue('participants', nextParticipants);
                                this.setState({
                                  participants: nextParticipants
                                });
                              }}
                            />
                          );
                        }}
                      />
                      {touched.teams && errors.teams ? <div className="alert alert-danger">{errors.teams}</div> : null}
                    </div>
                  )}

                  {isTeamOrMiniTeam && (
                    <div className="form-group">
                      <label>Choose teams</label>
                      {this.renderTeams(values.teams, setFieldValue)}
                      {teams.length < 2 && (
                        <Field
                          name="teams"
                          render={({ field }) => {
                            return (
                              <Autocomplete
                                searchFunction={this.getTeams}
                                getElementTitle={getSchoolNameAndTeamName}
                                customClass="mFullWidth mb-3"
                                defaultItem={undefined}
                                onSelect={team => {
                                  const nextTeams = [].concat(values.teams, team);
                                  setFieldValue('teams', nextTeams);
                                  this.setState({
                                    teams: nextTeams
                                  });
                                }}
                              />
                            );
                          }}
                        />
                      )}
                      {touched.teams && errors.teams ? <div className="alert alert-danger">{errors.teams}</div> : null}
                    </div>
                  )}

                  <label>Venue</label>
                  <Field
                    name="venue"
                    render={({ field }) => {
                      return (
                        <Autocomplete
                          onSelect={venue => {
                            setFieldValue('venue', venue);
                          }}
                          getElementTitle={getNameByPlaceOrPostcode}
                          customClass="mFullWidth mb-3"
                          defaultItem={values.venue}
                          searchFunction={this.getVenues}
                          disabled
                        />
                      );
                    }}
                  />

                  <Button onClick={this.props.onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                  <button type="submit" className="mt-3 mb-3 btn btn-primary">
                    Save
                  </button>
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}
