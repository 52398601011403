import * as React from 'react';
import { Component } from 'react';
import { parse } from 'query-string';
import * as Lazy from 'lazy.js';
import * as BPromise from 'bluebird';
import { History, Location } from 'history';
import {
  ColumnDefinition,
  getFilters2,
  getOrder,
  getSearchFilter,
  getSearchOrder,
  getServerFieldSectionWhere,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { DATE_INTERVAL, COUNT_INTERVAL, FILTER_TYPE, FIRST_PAGE, LIMIT } from 'Src/consts/table';
import { TRANSACTION_STATUS } from 'Src/consts/transaction';
import { getSelectOptionForPaymentStatus, getSelectOptionForClubStatus } from 'Src/helpers/table/select';
import { Loader } from 'Src/components/Loader/Loader';
import { AppUser } from 'Src/views/App/App';
// import { GenericSummary } from 'Src/components/GenericSummary/GenericSummary';
import { Grid2 } from 'Src/components/Grid/Grid2';
// import { TransactionSummary } from './TransactionSummary/TransactionSummary';
import { ResizablePanel } from 'Src/components/ResizablePanel/ResizablePanel';
import { Transaction } from 'Src/models/transaction';
import { getAllTransactions, getTransactions, getTransactionsCount } from 'Src/helpers/service/admin/transactions';
import { PaymentAccount } from 'Src/models/paymentAccount';
import {
  getTransactionAmount,
  getTransactionStatus,
  getTransactionClubStatus,
  getTransactionNameTournamentOrClub,
  getCreatedAt,
  getUpdatedAt
} from 'Src/helpers/accessor/accessor';
import { getPaymentAccount } from 'Src/helpers/service/admin/paymentAccounts';
import { refundTransaction } from 'Src/helpers/service/admin/transactions';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import {
  searchFunctionUsersByFirstName,
  searchFunctionUsersByLastName,
  searchFunctionUsersByEmail,
  searchFunctionStudentsByFirstName,
  searchFunctionStudentsByLastName,
  searchFunctionUserClubsByName,
  getFirstName,
  getLastName,
  getEmail,
  getClubName
} from 'Src/helpers/autocomplete/autocomplete';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Amount',
    field: 'price',
    isSort: true,
    type: FILTER_TYPE.COUNT_INTERVAL,
    accessor: getTransactionAmount
  },
  {
    text: 'Status',
    field: 'status',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getTransactionStatus
  },
  {
    text: 'Last name',
    field: 'customerLastName',
    isSort: false,
    type: FILTER_TYPE.AUTOCOMPLETE,
    accessor: ['customerData', 'lastName']
  },
  {
    text: 'First name',
    field: 'customerFirstName',
    isSort: false,
    type: FILTER_TYPE.AUTOCOMPLETE,
    accessor: ['customerData', 'firstName']
  },
  {
    text: 'Email',
    field: 'customerEmail',
    isSort: false,
    type: FILTER_TYPE.AUTOCOMPLETE,
    accessor: ['customerData', 'email']
  },
  {
    text: 'Player last name',
    field: 'playerLastName',
    isSort: false,
    type: FILTER_TYPE.AUTOCOMPLETE,
    accessor: ['playerData', 'lastName']
  },
  {
    text: 'Player first name',
    field: 'playerFirstName',
    isSort: false,
    type: FILTER_TYPE.AUTOCOMPLETE,
    accessor: ['playerData', 'firstName']
  },
  {
    text: 'Club name',
    field: 'clubName',
    isSort: false,
    type: FILTER_TYPE.AUTOCOMPLETE,
    accessor: getTransactionNameTournamentOrClub
  },
  {
    text: 'Club status',
    field: 'clubStatus',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getTransactionClubStatus
  },
  {
    text: 'Created at',
    field: 'createdAt',
    isSort: true,
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: getCreatedAt
  },
  {
    text: 'Updated at',
    field: 'updatedAt',
    isSort: true,
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: getUpdatedAt
  }
];

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

interface State {
  items: Transaction[];
  paymentAccount: PaymentAccount;
  currentPage: number;
  selectedItems: Transaction[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;
  autocompleteDefaultTexts: any;
  autocompleteSearchFunctions: any;
  autocompleteGetElementsTitles: any;
  isRefundConfirmationModalOpen: boolean;
}

export class Transactions extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      paymentAccount: undefined,
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',
      autocompleteDefaultTexts: {
        customerFirstName: '',
        customerLastName: '',
        customerEmail: '',
        playerFirstName: '',
        playerLastName: '',
        clubName: ''
      },
      autocompleteSearchFunctions: {
        customerFirstName: this.getUsersByFirstName,
        customerLastName: this.getUsersByLastName,
        customerEmail: this.getUsersEmail,
        playerFirstName: this.getStudentsByFirstName,
        playerLastName: this.getStudentsByLastName,
        clubName: this.getClubs
      },
      autocompleteGetElementsTitles: {
        customerFirstName: getFirstName,
        customerLastName: getLastName,
        customerEmail: getEmail,
        playerFirstName: getFirstName,
        playerLastName: getLastName,
        clubName: getClubName
      },

      isRefundConfirmationModalOpen: false
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setAdditionalItems().then(res => {
      this.setItems();
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  getUsersByFirstName = (text: string) => {
    const { user } = this.props;

    return searchFunctionUsersByFirstName(user, text).then(users => {
      return Lazy(users)
        .uniq('firstName')
        .toArray();
    });
  };

  getUsersByLastName = (text: string) => {
    const { user } = this.props;

    return searchFunctionUsersByLastName(user, text).then(users => {
      return Lazy(users)
        .uniq('lastName')
        .toArray();
    });
  };

  getStudentsByFirstName = (text: string) => {
    const { user } = this.props;

    return searchFunctionStudentsByFirstName(user, text).then(users => {
      return Lazy(users)
        .uniq('firstName')
        .toArray();
    });
  };

  getStudentsByLastName = (text: string) => {
    const { user } = this.props;

    return searchFunctionStudentsByLastName(user, text).then(users => {
      return Lazy(users)
        .uniq('lastName')
        .toArray();
    });
  };

  getUsersEmail = (text: string) => {
    const { user } = this.props;

    return searchFunctionUsersByEmail(user, text);
  };

  getClubs = (text: string) => {
    const { user } = this.props;

    return searchFunctionUserClubsByName(user, text);
  };

  getPaymentAccountId(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    const paymentAccountId = search.paymentAccount;

    return paymentAccountId;
  }

  setAdditionalItems() {
    const { user } = this.props;
    const paymentAccountId = this.getPaymentAccountId();

    return getPaymentAccount(user, paymentAccountId).then(paymentAccount => {
      this.setState({
        paymentAccount: paymentAccount
      });
      return true;
    });
  }

  setItems() {
    const { history, user } = this.props;
    const { paymentAccount } = this.state;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const getItemsCountPromise = getTransactionsCount(user, paymentAccount.id, where);
    const getItemsPromise = getTransactions(user, paymentAccount.id, serverQueryFilter);
    const promises = [getItemsCountPromise, getItemsPromise];

    //TODO_004 backend must return {count: ...}
    return BPromise.all(promises).then(([count, transactions]) => {
      this.setState({
        currentPage: page,
        sortDirection: sortDirection,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: transactions,
        total: count,
        basePath: history.location.pathname,
        isLoading: false,
        autocompleteDefaultTexts: {
          customerFirstName: filters.customerFirstName,
          customerLastName: filters.customerLastName,
          customerEmail: filters.customerEmail,
          playerFirstName: filters.playerFirstName,
          playerLastName: filters.playerLastName,
          clubName: filters.clubName
        }
      });
      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    const search = parse(this.props.history.location.search);
    let searchUpdated = [];

    searchUpdated.push(`paymentAccount=${search.paymentAccount}`);

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      searchUpdated.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      searchUpdated.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      searchUpdated.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: searchUpdated.join('&')
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedTransaction = items[index];

    const selectedTransactionIndex = selectedItems.findIndex(transaction => selectedTransaction.id === transaction.id);
    let selectedTransactionsUpdated = [...selectedItems];

    if (selectedTransactionIndex !== -1) {
      selectedTransactionsUpdated.splice(selectedTransactionIndex, 1);
    } else {
      selectedTransactionsUpdated.push(selectedTransaction);
    }

    this.setState({
      selectedItems: selectedTransactionsUpdated
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const transactionsFiltered = items.filter(transaction => {
      return selectedItems.every(selectedTransaction => selectedTransaction.id !== transaction.id);
    });

    const selectedTransaction = [...selectedItems, ...transactionsFiltered];

    this.setState({
      selectedItems: selectedTransaction,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters, paymentAccount } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    getAllTransactions(user, paymentAccount.id, where).then(transactions => {
      const transactionsFiltered = transactions.filter(transaction => {
        return selectedItems.every(selectedTransaction => selectedTransaction.id !== transaction.id);
      });

      const selectedTransactions = [...selectedItems, ...transactionsFiltered];

      this.setState({
        selectedItems: selectedTransactions,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters, paymentAccount } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`paymentAccount=${paymentAccount.id}&order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName, paymentAccount } = this.state;

    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: `paymentAccount=${paymentAccount.id}&${search.join('&')}`
    });
  };

  onClearFilterClick = (): void => {
    const { paymentAccount } = this.state;
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: `/payments`,
      search: `paymentAccount=${paymentAccount.id}`
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  onTableAutocompleteFilterChange = (item, filterField: string): void => {
    let filterFieldUpdated;

    const isItemExist = typeof item !== 'undefined';

    switch (filterField) {
      case 'customerFirstName':
        filterFieldUpdated = 'firstName';

        break;

      case 'customerLastName':
        filterFieldUpdated = 'lastName';

        break;

      case 'customerEmail':
        filterFieldUpdated = 'email';

        break;

      case 'playerFirstName':
        filterFieldUpdated = 'firstName';

        break;

      case 'playerLastName':
        filterFieldUpdated = 'lastName';

        break;

      case 'clubName':
        filterFieldUpdated = 'name';

        break;

      default:
        filterFieldUpdated = filterField;
    }

    const filterValue = isItemExist ? item[filterFieldUpdated] : '';
    const filters = this.state.filters;

    let nextFilters = { ...filters };

    nextFilters = {
      ...nextFilters,
      [filterField]: filterValue
    };

    this.setState({
      filters: nextFilters
    });
  };

  onTabClick = (event, index: number) => {
    event.preventDefault();

    this.setState({
      total: index
    });
  };

  goBack = () => {
    this.props.history.push({
      pathname: '/accounts'
    });
  };

  // getSummaryTabs() {
  //   const { selectedItems, items } = this.state;

  //   switch (true) {
  //     case selectedItems.length === 1:
  //       return <TransactionSummary transaction={selectedItems[0]} />;
  //   }
  // }

  // renderSummary() {
  //   const { selectedItems } = this.state;
  //   const isOneItemSelected = selectedItems.length === 1;

  //   if (!isOneItemSelected) {
  //     return <div className="font-weight-bold">Select a transaction above</div>;
  //   }

  //   const summaryTabs = this.getSummaryTabs();

  //   return (
  //     <GenericSummary onTabClick={this.onTabClick} tabs={['Summary']} currentTabIndex={0}>
  //       {summaryTabs}
  //     </GenericSummary>
  //   );
  // }

  createRefund = () => {
    const { selectedItems, paymentAccount } = this.state;
    const { user } = this.props;
    const selectedItem = selectedItems[0];
    const { id: paymentItemId, paymentId, price } = selectedItem;

    this.setState({
      isLoading: true
    });

    refundTransaction(user, paymentAccount.id, paymentId, paymentItemId, price)
      .then(res => {
        const selecedItemUpdated = { ...selectedItem, status: TRANSACTION_STATUS.REFUND };

        this.setState({
          isRefundConfirmationModalOpen: false,
          selectedItems: [selecedItemUpdated]
        });

        this.setItems();
      })
      .catch(error => {
        this.setState({
          isRefundConfirmationModalOpen: false,
          isLoading: false
        });
      });
  };

  onRefundClick = () => {
    this.setState({
      isRefundConfirmationModalOpen: true
    });
  };

  closeRefunConfirmationButton = () => {
    this.setState({
      isRefundConfirmationModalOpen: false
    });
  };

  renderRefundConfirmationModal() {
    const { isRefundConfirmationModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isRefundConfirmationModalOpen}
        title={'Confirmation'}
        body={`You are going to refund the selected payment. Are you sure?`}
        buttonText={'Refund'}
        onButtonClick={this.createRefund}
        buttonCancelText={'Cancel'}
        onCloseClick={this.closeRefunConfirmationButton}
      />
    );
  }

  render() {
    const {
      items,
      paymentAccount,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isLoading,
      isSelectAllChecked,
      currentPage,
      total,
      autocompleteSearchFunctions,
      autocompleteGetElementsTitles,
      autocompleteDefaultTexts,
      isRefundConfirmationModalOpen
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes = isRefundConfirmationModalOpen ? 'mt-3 modal-open' : 'mt-3';
    const isRefundActionActive =
      selectedItems.length === 1 &&
      (selectedItems[0].status === TRANSACTION_STATUS.SUCCESS ||
        selectedItems[0].status === TRANSACTION_STATUS.PARTIAL_REFUND);

    const actionItems = [
      {
        itemText: 'Refund',
        onItemClick: this.onRefundClick,
        isActive: isRefundActionActive
      }
    ];

    const gridTitle = typeof paymentAccount !== 'undefined' ? `${paymentAccount.name} / Payments` : 'Payments';

    const filterOptions = {
      status: getSelectOptionForPaymentStatus(),
      clubStatus: getSelectOptionForClubStatus()
    };

    return (
      <div className={classes}>
        {this.renderRefundConfirmationModal()}
        <div className="row">
          <div className="col-md-12">
            {/* <ResizablePanel> */}
            <Grid2
              dataItems={items}
              filters={filters}
              currentPage={currentPage}
              total={total}
              isSelectAllChecked={isSelectAllChecked}
              isDataFiltered={isDataFiltered}
              sortDirection={sortDirection}
              sortColumnsName={sortColumnsName}
              isShowFilter={isShowFilter}
              dataItemsSelected={selectedItems}
              columns={COLUMNS}
              actionItems={actionItems}
              onItemClick={this.onItemClick}
              onSortClick={this.onTableSortClick}
              onApplyFilterClick={this.onApplyFilterClick}
              onClearFilterClick={this.onClearFilterClick}
              onTableFilterChange={this.onTableFilterChange}
              onTableFilterClick={this.onTableFilterClick}
              setCurrentPageParams={this.setCurrentPageParams}
              onSelectAllClick={this.onSelectAllClick}
              onSelectAllOnPageClick={this.onSelectAllOnPageClick}
              onUnselectAllClick={this.onUnselectAllClick}
              autocompleteSearchFunctions={autocompleteSearchFunctions}
              autocompleteGetElementsTitles={autocompleteGetElementsTitles}
              autocompleteDefaultTexts={autocompleteDefaultTexts}
              onTableAutocompleteFilterChange={this.onTableAutocompleteFilterChange}
              goBack={this.goBack}
              gridTitle={gridTitle}
              isCSV={false}
              isPDF={false}
              options={filterOptions}
            />
            {/* {this.renderSummary()} */}
            {/* </ResizablePanel> */}
          </div>
        </div>
      </div>
    );
  }
}
