import * as React from 'react';
import * as propz from 'propz';
import { FunctionComponent } from 'react';
import { Venue } from '../../models/venue';

interface Props {
  venue: Venue;
}

const ROWS = [
  { title: 'Name', field: 'name' },
  { title: 'Postcode', field: 'postcode' }
];

export const VenueSummary: FunctionComponent<Props> = props => {
  const rows = ROWS.map((row, index) => {
    let contentCell: string;
    const field = row.field;

    const venue = props.venue;

    switch (field) {
      case 'postcode':
        contentCell = venue.postcode;
        break;
      case 'name':
        contentCell = venue.name;
        break;
      default:
        contentCell = propz.get(venue, row.field.split('.'), '');
    }

    return (
      <tr key={`venue_summary_${index}`}>
        <td>{row.title}</td>
        <td>{contentCell}</td>
      </tr>
    );
  });

  return (
    <table className={'table table-sm mPre mVerticalAlignMiddle'}>
      <tbody>{rows}</tbody>
    </table>
  );
};

VenueSummary.displayName = 'VenueSummary';
