import * as React from 'react';
import * as Hls from 'hls.js';

interface Props {
  url: string;
}
interface State {}

export class EventVideo extends React.Component<Props, State> {
  videoFileRef: any;

  constructor(props) {
    super(props);

    this.videoFileRef = React.createRef();
  }

  componentDidMount() {
    const { url } = this.props;
    const hls = new Hls();
    hls.loadSource(url);
    hls.attachMedia(this.videoFileRef);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.url !== this.props.url) {
      const { url } = nextProps;
      const hls = new Hls();
      hls.loadSource(url);
      hls.attachMedia(this.videoFileRef);
    } else {
      return null;
    }
  }

  render() {
    return (
      <video
        controls={true}
        style={{ width: '100%', height: '100%', backgroundColor: '#000000' }}
        ref={input => (this.videoFileRef = input)}
      />
    );
  }
}
