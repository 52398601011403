import * as React from 'react';
import * as propz from 'propz';
import { FunctionComponent } from 'react';
import { Tournament } from '../../models/tournament';
import { getStageByGroupId } from '../../helpers/tournament/tournament';
import { getTournamentCustomGroupPitch } from '../../helpers/accessor/accessor';

interface Props {
  tournament: Tournament;
}

export const TournamentCustomGroupsSummary: FunctionComponent<Props> = props => {
  const { tournament } = props;
  const customGroups = tournament.customGroups || [];

  const rows = customGroups.map((customGroup, index) => {
    const stage = getStageByGroupId(tournament, customGroup.id);
    const stageName = propz.get(stage, ['name'], '');
    const customGroupName = propz.get(customGroup, ['name'], '');
    const options = { tournament };
    const pitchName = getTournamentCustomGroupPitch(customGroup, options);

    return (
      <tr key={`tournament_custom_groups_summary_${index}`}>
        <td>{index + 1}</td>
        <td>{customGroupName}</td>
        <td>{stageName}</td>
        <td>{pitchName}</td>
      </tr>
    );
  });

  return (
    <table className={'table table-sm'}>
      <thead>
        <tr key={`tournament_custom_groups_summary_heading`}>
          <th />
          <th>Name</th>
          <th>Stage</th>
          <th>Pitch</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
};

TournamentCustomGroupsSummary.displayName = 'TournamentCustomGroupsSummary';
