import * as React from 'react';
import { Component } from 'react';
import * as propz from 'propz';
import { AppUser } from 'Src/views/App/App';
import { verifyEmail, verifyPhone } from 'Src/helpers/service/nobody/confirm';
import { changeEmail, changePhone, resendEmailCode, resendPhoneCode } from 'Src/helpers/service/admin/user';
import { Button } from '../../../../components/Button/Button';
import { Loader } from '../../../../components/Loader/Loader';
import { SimpleModal } from '../../../../components/SimpleModal/SimpleModal';
import { CheckIcon } from '../../../../components/SvgIcons/CheckIcon';
import { EmailChangeForm } from './EmailChangeForm/EmailChangeForm';
import { PhoneChangeForm } from './PhoneChangeForm/PhoneChangeForm';
import './VerificationTab.scss';
import { PHONE_CODE } from '../../../../consts/common';
import { getUKPhoneWithoutFirstZero } from '../../../../helpers/phone/phone';

interface Props {
  user: AppUser;
  updateProfile: (profile) => void;
}

interface State {
  emailToken: string;
  phoneToken: string;
  isPhoneVerified: boolean;
  isEmailVerified: boolean;
  isEmailChangeModalOpen: boolean;
  isPhoneChangeModalOpen: boolean;
  isSuccessEmailChangeModalOpen: boolean;
  isSuccessPhoneChangeModalOpen: boolean;
  isResendEmailCodeModalOpen: boolean;
  isResendPhoneCodeModalOpen: boolean;
  isLoading: boolean;
  emailUpdated: string;
  phoneUpdated: string;
}

export class VerificationTab extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      emailToken: '',
      phoneToken: '',
      emailUpdated: '',
      phoneUpdated: '',
      isPhoneVerified: undefined,
      isEmailVerified: undefined,
      isEmailChangeModalOpen: false,
      isPhoneChangeModalOpen: false,
      isSuccessEmailChangeModalOpen: false,
      isSuccessPhoneChangeModalOpen: false,
      isResendEmailCodeModalOpen: false,
      isResendPhoneCodeModalOpen: false,
      isLoading: false
    };
  }

  componentDidMount() {
    const { user } = this.props;

    this.setState({
      isLoading: true
    });

    const isEmailVerified = propz.get(user, ['profile', 'verification', 'status', 'email']);
    const isPhoneVerified = propz.get(user, ['profile', 'verification', 'status', 'sms']);

    switch (true) {
      case isPhoneVerified && isEmailVerified:
        this.setState({
          isPhoneVerified,
          isEmailVerified,
          isLoading: false
        });
        break;
      case isPhoneVerified:
        this.setState({
          isPhoneVerified,
          isLoading: false
        });
        break;
      case isEmailVerified:
        this.setState({
          isEmailVerified,
          isLoading: false
        });
        break;
      default:
        this.setState({
          isLoading: false
        });
    }
  }

  componentWillUnmount() {
    // To update profile in App state only one time then user leave the tab
    // and to avoid immediate redirect after entering codes

    const { user, updateProfile } = this.props;
    const { isEmailVerified, isPhoneVerified, emailUpdated, phoneUpdated } = this.state;
    const { profile } = user;
    const profileUpdated = { ...profile };

    if (emailUpdated !== '') {
      propz.set(profileUpdated, ['email'], emailUpdated);
    }

    if (phoneUpdated !== '') {
      propz.set(profileUpdated, ['phone'], phoneUpdated);
    }

    if (isEmailVerified) {
      propz.set(profileUpdated, ['verification', 'status', 'email'], isEmailVerified);
    }

    if (isPhoneVerified) {
      propz.set(profileUpdated, ['verification', 'status', 'sms'], isPhoneVerified);
    }

    updateProfile(profileUpdated);
  }

  onEmailTokenChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ emailToken: value });
  };

  onPhoneTokenChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ phoneToken: value });
  };

  onVerifyEmailClick = () => {
    const { user } = this.props;
    const { emailToken } = this.state;

    this.setState({
      isLoading: true
    });

    verifyEmail(user, emailToken).then(res => {
      const { confirmed } = res;

      this.setState({
        isEmailVerified: confirmed,
        isLoading: false
      });
    });
  };

  onVerifyPhoneClick = () => {
    const { user } = this.props;
    const { phoneToken } = this.state;

    this.setState({
      isLoading: true
    });

    verifyPhone(user, phoneToken).then(res => {
      const { confirmed } = res;

      this.setState({
        isPhoneVerified: confirmed,
        isLoading: false
      });
    });
  };

  onChangeEmailClick = () => {
    this.setState({
      isEmailChangeModalOpen: true
    });
  };

  onChangePhoneClick = () => {
    this.setState({
      isPhoneChangeModalOpen: true
    });
  };

  onPhoneChangeFormCancelClick = () => {
    this.setState({
      isPhoneChangeModalOpen: false
    });
  };

  onEmailChangeFormCancelClick = () => {
    this.setState({
      isEmailChangeModalOpen: false
    });
  };

  onResendEmailCodeClick = () => {
    const { user } = this.props;

    this.setState({
      isLoading: true
    });

    resendEmailCode(user).then(res => {
      this.setState({
        isLoading: false,
        isResendEmailCodeModalOpen: true
      });
    });
  };

  onResendPhoneCodeClick = () => {
    const { user } = this.props;

    this.setState({
      isLoading: true
    });

    resendPhoneCode(user).then(res => {
      this.setState({
        isLoading: false,
        isResendPhoneCodeModalOpen: true
      });
    });
  };

  onEmailChangeFormSubmit = data => {
    const { user } = this.props;
    this.setState({
      isLoading: true
    });

    changeEmail(user, data.email).then(res => {
      this.setState({
        isLoading: false,
        isEmailChangeModalOpen: false,
        isSuccessEmailChangeModalOpen: true,
        emailUpdated: data.email
      });
    });
  };

  onPhoneChangeFormSubmit = data => {
    const { user } = this.props;
    this.setState({
      isLoading: true
    });

    const { phone, phoneCode } = data;
    const phoneWithoutFirstZero = getUKPhoneWithoutFirstZero(phone, phoneCode);

    const phoneToPost = `${phoneCode}${phoneWithoutFirstZero}`;

    changePhone(user, phoneToPost).then(res => {
      this.setState({
        isLoading: false,
        isPhoneChangeModalOpen: false,
        isSuccessPhoneChangeModalOpen: true,
        phoneUpdated: phoneToPost
      });
    });
  };

  renderEmailChangeModal() {
    const { isEmailChangeModalOpen } = this.state;
    const { user } = this.props;
    const { profile } = user;
    const { email } = profile;
    return (
      <SimpleModal isOpen={isEmailChangeModalOpen} customClass={'eVerificationTabModal'}>
        <EmailChangeForm
          onCancel={this.onEmailChangeFormCancelClick}
          onSubmit={this.onEmailChangeFormSubmit}
          email={email}
        />
      </SimpleModal>
    );
  }

  renderPhoneChangeModal() {
    const { isPhoneChangeModalOpen } = this.state;
    const { user } = this.props;
    const { profile } = user;
    const { phone } = profile;
    return (
      <SimpleModal isOpen={isPhoneChangeModalOpen} customClass={'eVerificationTabModal'}>
        <PhoneChangeForm
          onCancel={this.onPhoneChangeFormCancelClick}
          onSubmit={this.onPhoneChangeFormSubmit}
          phone={phone}
        />
      </SimpleModal>
    );
  }

  closeSuccessEmailChangeModal = () => {
    this.setState({
      isSuccessEmailChangeModalOpen: false
    });
  };

  renderSuccessEmailChangeModal() {
    const { isSuccessEmailChangeModalOpen, emailUpdated } = this.state;
    const { user } = this.props;

    const isEmailUpdatedExist = typeof emailUpdated !== 'undefined' && emailUpdated !== '';
    const profileEmail = propz.get(user, ['profile', 'email']);
    const email = isEmailUpdatedExist ? emailUpdated : profileEmail;

    return (
      <SimpleModal
        isOpen={isSuccessEmailChangeModalOpen}
        title={'Info'}
        body={`We have sent the verification code to ${email}`}
        buttonCancelText={'Ok'}
        onCloseClick={this.closeSuccessEmailChangeModal}
        customClass={'eVerificationTabModal'}
      />
    );
  }

  closeSuccessPhoneChangeModal = () => {
    this.setState({
      isSuccessPhoneChangeModalOpen: false
    });
  };

  renderSuccessPhoneChangeModal() {
    const { isSuccessPhoneChangeModalOpen, phoneUpdated } = this.state;
    const { user } = this.props;

    const iPhoneUpdatedExist = typeof phoneUpdated !== 'undefined' && phoneUpdated !== '';
    const profileEmail = propz.get(user, ['profile', 'email']);
    const phone = iPhoneUpdatedExist ? phoneUpdated : profileEmail;

    return (
      <SimpleModal
        isOpen={isSuccessPhoneChangeModalOpen}
        title={'Info'}
        body={`We have sent the verification code to ${phone}`}
        buttonCancelText={'Ok'}
        onCloseClick={this.closeSuccessPhoneChangeModal}
        customClass={'eVerificationTabModal'}
      />
    );
  }

  closeResendEmailCodeModal = () => {
    this.setState({
      isResendEmailCodeModalOpen: false
    });
  };

  renderResendEmailCodeModal() {
    const { isResendEmailCodeModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isResendEmailCodeModalOpen}
        title={'Info'}
        body={'We have resent your email verification code'}
        buttonCancelText={'Ok'}
        onCloseClick={this.closeResendEmailCodeModal}
        customClass={'eVerificationTabModal'}
      />
    );
  }

  closeResendPhoneCodeModal = () => {
    this.setState({
      isResendPhoneCodeModalOpen: false
    });
  };

  renderResendPhoneCodeModal() {
    const { isResendPhoneCodeModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isResendPhoneCodeModalOpen}
        title={'Info'}
        body={'We have resent your phone verification code'}
        buttonCancelText={'Ok'}
        onCloseClick={this.closeResendPhoneCodeModal}
        customClass={'eVerificationTabModal'}
      />
    );
  }

  render() {
    const {
      emailToken,
      phoneToken,
      isEmailVerified,
      isPhoneVerified,
      isLoading,
      isEmailChangeModalOpen,
      isPhoneChangeModalOpen,
      isResendEmailCodeModalOpen,
      isResendPhoneCodeModalOpen,
      isSuccessEmailChangeModalOpen,
      isSuccessPhoneChangeModalOpen,
      emailUpdated,
      phoneUpdated
    } = this.state;

    const { user } = this.props;
    const { profile } = user;

    const isEmailUpdatedExist = emailUpdated !== '';
    const isPhoneUpdatedExist = phoneUpdated !== '';

    const email = isEmailUpdatedExist ? emailUpdated : profile.email;
    const phone = isPhoneUpdatedExist ? phoneUpdated : profile.phone;

    if (isLoading) {
      return <Loader />;
    }

    const classes =
      isEmailChangeModalOpen ||
      isPhoneChangeModalOpen ||
      isResendEmailCodeModalOpen ||
      isResendPhoneCodeModalOpen ||
      isSuccessEmailChangeModalOpen ||
      isSuccessPhoneChangeModalOpen
        ? 'mt-3 modal-open'
        : 'mt-3';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderEmailChangeModal()}
        {this.renderPhoneChangeModal()}
        {this.renderResendEmailCodeModal()}
        {this.renderResendPhoneCodeModal()}
        {this.renderSuccessEmailChangeModal()}
        {this.renderSuccessPhoneChangeModal()}
        <div className="row">
          <div className="col-xl-6 offset-xl-1">
            <div className={'mt-3 mb-3'}>
              <label className="eVerificationTabLabel">Email Verification Code</label>

              {isEmailVerified === false && (
                <div className="eVerificationTabError">{'Incorrect email code. Please try again.'}</div>
              )}

              <div className="eVerificationTabRowSection">
                <input
                  type="text"
                  className="eVerificationTabInput"
                  placeholder={isEmailVerified ? 'Email was verified' : 'Email code'}
                  value={emailToken}
                  onChange={this.onEmailTokenChange}
                  disabled={isEmailVerified}
                />
                {!isEmailVerified && (
                  <Button text={'Verify'} onClick={this.onVerifyEmailClick} customClass={'eVerificationTabButton'} />
                )}
                {isEmailVerified && (
                  <div className="eVerificationTabIconWrapper">
                    <CheckIcon />
                  </div>
                )}
              </div>

              {!isEmailVerified && (
                <>
                  <p className="eVerificationTabText">
                    We have sent your verification code to <strong>{email}</strong>
                  </p>
                  <div onClick={this.onChangeEmailClick} className={'eVerificationTabLink'}>
                    {'Wrong email?'}
                  </div>
                  <div onClick={this.onResendEmailCodeClick} className={'eVerificationTabLink'}>
                    {'Send code again.'}
                  </div>
                </>
              )}
            </div>

            <div className={'mt-3 mb-3'}>
              <label className="eVerificationTabLabel">Phone verification Code</label>

              {isPhoneVerified === false && (
                <div className="eVerificationTabError">{'Incorrect phone code. Please try again.'}</div>
              )}

              <div className="eVerificationTabRowSection">
                <input
                  type="text"
                  className="eVerificationTabInput"
                  placeholder={isPhoneVerified ? 'Phone was verified' : 'Phone code'}
                  value={phoneToken}
                  onChange={this.onPhoneTokenChange}
                  disabled={isPhoneVerified}
                />
                {!isPhoneVerified && (
                  <Button
                    disabled={isPhoneVerified}
                    text={'Verify'}
                    onClick={this.onVerifyPhoneClick}
                    customClass={'eVerificationTabButton'}
                  />
                )}

                {isPhoneVerified && (
                  <div className="eVerificationTabIconWrapper">
                    <CheckIcon />
                  </div>
                )}
              </div>

              {!isPhoneVerified && (
                <>
                  <p className="eVerificationTabText">
                    We have sent your verification code to <strong>{phone}</strong>
                  </p>
                  <div onClick={this.onChangePhoneClick} className={'eVerificationTabLink'}>
                    {'Wrong phone number?'}
                  </div>
                  <div onClick={this.onResendPhoneCodeClick} className={'eVerificationTabLink'}>
                    {'Send code again.'}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
