import * as React from 'react';
import { AppUser } from 'Src/views/App/App';
import { Component } from 'react';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { History, Location } from 'history';
import { parse } from 'query-string';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import {
  ColumnDefinition,
  getFilters2,
  getOrder,
  getSearchFilter,
  getSearchOrder,
  getServerFieldSectionWhere,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { getLeagueNewsDate } from 'Src/helpers/accessor/accessor';
import * as propz from 'propz';
import * as BPromise from 'bluebird';
import { Grid2 } from 'Src/components/Grid/Grid2';
import { getSchoolLeague } from 'Src/helpers/service/admin/schoolLeagues';
import { LeagueNews, SchoolLeague } from 'Src/models/schoolLeague';
import { Loader } from 'Src/components/Loader/Loader';
import {
  createSchoolLeagueNews,
  deleteSchoolLeagueNews,
  getAllSchoolLeagueNews,
  getSchoolLeagueNews,
  getSchoolLeagueNewsCount,
  updateSchoolLeagueNews
} from 'Src/helpers/service/admin/schoolLeagueNews';
import { SchoolLeagueNewsForm } from './SchoolLeagueNewsForm/SchoolLeagueNewsForm';
import { getBody } from 'Src/helpers/cell/cell';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Title',
    field: 'title',
    isSort: false,
    type: FILTER_TYPE.TEXT,
    accessor: ['title']
  },
  {
    text: 'Body',
    field: 'body',
    isSort: false,
    type: FILTER_TYPE.TEXT,
    cell: getBody
  },
  {
    text: 'Date',
    field: 'date',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getLeagueNewsDate
  }
];

interface State {
  items: LeagueNews[];
  currentPage: number;
  selectedItems: LeagueNews[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  filterOptions: any;
  currentTabIndex: number;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;
  isErrorModalOpen: boolean;
  errorMessage: string;
  league: SchoolLeague;
  isNewsFormModalOpen: boolean;
  isNewsRemoveModalOpen: boolean;
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export class SchoolLeagueNews extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      filterOptions: undefined,
      isDataFiltered: false,
      currentTabIndex: 0,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',
      isErrorModalOpen: false,
      errorMessage: '',
      league: undefined,
      isNewsFormModalOpen: false,
      isNewsRemoveModalOpen: false
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });
    this.setAdditionalItems().then(res => {
      this.setItems();
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  getLeagueId(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    const leagueId = search.league;
    return leagueId;
  }

  setAdditionalItems() {
    const { user } = this.props;
    const leagueId = this.getLeagueId();

    return getSchoolLeague(user, leagueId).then(league => {
      this.setState({
        league: league
      });

      return true;
    });
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);
    const leagueId = this.getLeagueId();

    const getItemsPromise = getSchoolLeagueNews(user, leagueId, serverQueryFilter);
    const getItemsCountPromise = getSchoolLeagueNewsCount(user, leagueId, where);

    const promises = [getItemsCountPromise, getItemsPromise];

    return BPromise.all(promises).then(([countObj, items]) => {
      this.setState({
        currentPage: page,
        sortDirection: sortDirection as TABLE_SORT_DIRECTION,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: items,
        total: countObj.count,
        basePath: history.location.pathname,
        isLoading: false
      });

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection, basePath } = this.state;
    const { history } = this.props;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const leagueId = this.getLeagueId();

    history.push({
      pathname: basePath,
      search: `league=${leagueId}&${search.join('&')}`
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated,
      currentTabIndex: 0
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const selectedItemsNext = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: selectedItemsNext,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true
    });

    const { user } = this.props;

    const where = getServerFieldSectionWhere(filters);

    getAllSchoolLeagueNews(user, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const selectedItemsNext = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: selectedItemsNext,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters, basePath } = this.state;
    const { history } = this.props;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const leagueId = this.getLeagueId();

    history.push({
      pathname: basePath,
      search: `league=${leagueId}&${search.join('&')}`
    });
  };

  onTableFilterChange = (event, filterField: string, options?, index?: number, checkBoxIndex?: number): void => {
    const filterValue = propz.get(event, ['target', 'value'], '');
    const { filters, filterOptions } = this.state;
    const currentFilterField = filters[filterField];
    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      switch (filterType) {
        case FILTER_TYPE.MULTISELECT:
          const options = event.target.options;

          const value = [];
          for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
              value.push(options[i].value);
            }
          }
          nextFilters = {
            ...nextFilters,
            [filterField]: value
          };
          break;
        case FILTER_TYPE.CHECKBOX:
          const columns = COLUMNS;
          const currentField = columns[index].field;
          filterOptions[currentField][checkBoxIndex].isChecked = !filterOptions[currentField][checkBoxIndex].isChecked;

          this.setState({
            filterOptions: filterOptions
          });

          const checkBoxValue = [];
          if (typeof filterOptions[filterField] !== 'undefined') {
            for (let i = 0; i < filterOptions[filterField].length; i++) {
              if (filterOptions[filterField][i].isChecked) {
                checkBoxValue.push(filterOptions[filterField][i].value);
              }
            }
          }
          nextFilters = {
            ...nextFilters,
            [filterField]: checkBoxValue
          };
          break;
        default:
          nextFilters = {
            ...nextFilters,
            [filterField]: filterValue
          };
          break;
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName, basePath } = this.state;
    const { history } = this.props;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    const leagueId = this.getLeagueId();

    history.push({
      pathname: basePath,
      search: `league=${leagueId}&${search.join('&')}`
    });
  };

  onClearFilterClick = (): void => {
    const { history } = this.props;
    const { basePath } = this.state;

    this.setState({
      filterOptions: {},
      selectedItems: [],
      isSelectAllChecked: false
    });

    const leagueId = this.getLeagueId();

    history.push({
      pathname: basePath,
      search: `league=${leagueId}`
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  onCloseErrorClick = () => {
    this.setState({
      isErrorModalOpen: false,
      errorMessage: ''
    });
  };

  renderSendingError(): React.ReactNode {
    const { errorMessage, isErrorModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isErrorModalOpen}
        title={'Error'}
        body={errorMessage}
        buttonCancelText={'Ok'}
        onCloseClick={this.onCloseErrorClick}
      />
    );
  }

  onShowError = (message: string) => {
    this.setState({
      isErrorModalOpen: true,
      errorMessage: message
    });
  };

  goBack = () => {
    const { history } = this.props;
    history.push({
      pathname: '/leagues'
    });
  };

  onAddOrEditActionClick = () => {
    this.setState({
      isNewsFormModalOpen: true
    });
  };

  closeNewsFormModal = () => {
    this.setState({
      isNewsFormModalOpen: false
    });
  };

  onFormSubmit = data => {
    const { user } = this.props;
    const leagueId = this.getLeagueId();
    const { selectedItems } = this.state;

    const isEdit = selectedItems.length === 1;

    this.setState({
      isLoading: true,
      isNewsFormModalOpen: false,
      selectedItems: []
    });

    if (isEdit) {
      const [news] = selectedItems;
      const { id } = news;
      updateSchoolLeagueNews(user, leagueId, id, data).then(res => {
        this.setItems();
      });
    } else {
      createSchoolLeagueNews(user, leagueId, data).then(res => {
        this.setItems();
      });
    }
  };

  renderNewsForm() {
    const { isNewsFormModalOpen, selectedItems } = this.state;
    const { user } = this.props;

    const isEdit = selectedItems.length === 1;

    return isEdit ? (
      <SimpleModal isOpen={isNewsFormModalOpen} title={'Edit news'}>
        <SchoolLeagueNewsForm
          news={selectedItems[0]}
          user={user}
          onCloseClick={this.closeNewsFormModal}
          onFormSubmit={this.onFormSubmit}
        />
      </SimpleModal>
    ) : (
      <SimpleModal isOpen={isNewsFormModalOpen} title={'Add news'}>
        <SchoolLeagueNewsForm user={user} onCloseClick={this.closeNewsFormModal} onFormSubmit={this.onFormSubmit} />
      </SimpleModal>
    );
  }

  onRemoveClick = () => {
    const { selectedItems } = this.state;
    const { user } = this.props;

    const leagueId = this.getLeagueId();

    this.setState({
      isLoading: true
    });

    const promises = selectedItems.map(news => {
      return deleteSchoolLeagueNews(user, leagueId, news.id);
    });

    BPromise.all(promises).then(() => {
      this.setState({
        selectedItems: [],
        isNewsRemoveModalOpen: false
      });
      this.setItems();
    });
  };

  renderRemoveNewsForm() {
    const { selectedItems, isNewsRemoveModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isNewsRemoveModalOpen}
        title={'Remove News'}
        body={`Are you sure you want to remove ${
          selectedItems.length === 1 ? `News ${selectedItems[0].title}` : `${selectedItems.length} News`
        }?`}
        buttonText={'Remove'}
        onButtonClick={this.onRemoveClick}
        onCloseClick={this.onRemoveNewsCancelClick}
      />
    );
  }

  onRemoveNewsClick = () => {
    this.setState({
      isNewsRemoveModalOpen: true
    });
  };

  onRemoveNewsCancelClick = () => {
    this.setState({
      isNewsRemoveModalOpen: false
    });
  };

  render() {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isSelectAllChecked,
      currentPage,
      total,
      filterOptions,
      league,
      isErrorModalOpen,
      isNewsFormModalOpen,
      isNewsRemoveModalOpen,
      isLoading
    } = this.state;

    const actionItems = [
      {
        itemText: 'Add news',
        onItemClick: this.onAddOrEditActionClick,
        isActive: selectedItems.length === 0
      },
      {
        itemText: 'Edit news',
        onItemClick: this.onAddOrEditActionClick,
        isActive: selectedItems.length === 1
      },
      {
        itemText: 'Remove news',
        onItemClick: this.onRemoveNewsClick,
        isActive: selectedItems.length > 0
      }
    ];

    const { user } = this.props;
    const gridTitle = typeof league !== 'undefined' ? `${league.name} / News` : '';

    if (isLoading) {
      return <Loader />;
    }

    const classes = isErrorModalOpen || isNewsFormModalOpen || isNewsRemoveModalOpen ? 'mt-3 modal-open' : 'mt-3';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderSendingError()}
        {this.renderNewsForm()}
        {this.renderRemoveNewsForm()}
        <div className="row">
          <div className="col-md-12">
            <Grid2
              dataItems={items}
              filters={filters}
              currentPage={currentPage}
              total={total}
              isSelectAllChecked={isSelectAllChecked}
              isDataFiltered={isDataFiltered}
              sortDirection={sortDirection}
              sortColumnsName={sortColumnsName}
              isShowFilter={isShowFilter}
              dataItemsSelected={selectedItems}
              columns={COLUMNS}
              actionItems={actionItems}
              options={filterOptions}
              onItemClick={this.onItemClick}
              onSortClick={this.onTableSortClick}
              onApplyFilterClick={this.onApplyFilterClick}
              onClearFilterClick={this.onClearFilterClick}
              onTableFilterChange={this.onTableFilterChange}
              onTableFilterClick={this.onTableFilterClick}
              setCurrentPageParams={this.setCurrentPageParams}
              onSelectAllClick={this.onSelectAllClick}
              onSelectAllOnPageClick={this.onSelectAllOnPageClick}
              onUnselectAllClick={this.onUnselectAllClick}
              goBack={this.goBack}
              gridTitle={gridTitle}
              user={user}
            />
          </div>
        </div>
      </div>
    );
  }
}
