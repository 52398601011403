import { AppUser } from '../../../views/App/App';
import axios from 'axios';
import { SchoolTeamPlayersBatchData } from './schoolTeamPlayers';
import { SchoolEventIndividualWithClashes } from '../../../models/eventIndividuals';

export function getSchoolEventIndividualsWithClashes(
  user: AppUser,
  eventId: string,
  filter?
): Promise<SchoolEventIndividualWithClashes[]> {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .post<SchoolEventIndividualWithClashes[]>(
      `${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/individualsWithClashes`,
      filter,
      config
    )
    .then(response => {
      return response.data;
    });
}

export function getSchoolEventIndividualsWithoutClashes(
  user: AppUser,
  eventId: string,
  filter?
): Promise<SchoolEventIndividualWithClashes[]> {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .post<SchoolEventIndividualWithClashes[]>(
      `${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/individualsWithoutClashes`,
      filter,
      config
    )
    .then(response => {
      return response.data;
    });
}

export function updateSchoolEventIndividualsBatch(user: AppUser, eventId: string, data: SchoolTeamPlayersBatchData) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/individuals/batch`, data, config)
    .then(response => {
      return response.data;
    });
}

export function updateSchoolEventIndividualsGroupBatch(user: AppUser, eventId: string, data) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/individuals/groupBatch`, data, config)
    .then(response => {
      return response.data;
    });
}
