import * as React from 'react';
import { CALENDAR_FILTER } from '../../../../consts/calendar';
import { Button } from '../../../../components/Button/Button';
import { FilterType } from './Calendar';
import {
  isAllFilter,
  isClubEventsFilter,
  isSportEventsFilter,
  isTournamentEventsFilter
} from '../../../../helpers/calendar/calendar';
import { AppUser } from '../../../App/App';

interface Props {
  onFilterButtonClick: (filter: FilterType) => void;
  filter: FilterType;
  user: AppUser;
}

export function CalendarFilterButtons(props: Props) {
  const { onFilterButtonClick, filter } = props;

  return (
    <div className={'mt-3 mb-3'}>
      <Button
        key={'calendar_filter_button_all'}
        customClass={isAllFilter(filter) ? 'eCalendarFilterActiveButton mr-3' : 'eCalendarFilterButton mr-3'}
        onClick={() => onFilterButtonClick(CALENDAR_FILTER.ALL as FilterType)}
        text={'All events'}
      />
      <Button
        key={'calendar_filter_button_sport_events'}
        customClass={isSportEventsFilter(filter) ? 'eCalendarFilterActiveButton mr-3' : 'eCalendarFilterButton mr-3'}
        onClick={() => onFilterButtonClick(CALENDAR_FILTER.SPORT_EVENTS as FilterType)}
        text={'Sport events'}
      />
      <Button
        key={'calendar_filter_button_club_events'}
        customClass={isClubEventsFilter(filter) ? 'eCalendarFilterActiveButton mr-3' : 'eCalendarFilterButton mr-3'}
        onClick={() => onFilterButtonClick(CALENDAR_FILTER.CLUB_EVENTS as FilterType)}
        text={'Club events'}
      />
      <Button
        key={'calendar_filter_button_tournament_events'}
        customClass={
          isTournamentEventsFilter(filter) ? 'eCalendarFilterActiveButton mr-3' : 'eCalendarFilterButton mr-3'
        }
        onClick={() => onFilterButtonClick(CALENDAR_FILTER.TOURNAMENT_EVENTS as FilterType)}
        text={'Tournament events'}
      />
    </div>
  );
}
