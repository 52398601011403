import * as React from 'react';
import { Field, Form, Formik } from 'formik';
import { Button } from '../../../../../../../components/Button/Button';
import * as Yup from 'yup';
import { AvailableUserExtended } from '../SchoolEventView';
import { Switch } from '../../../../../../../components/Switch/Switch';
import { AVAILABLE_USERS_EXTRA_TYPE } from '../../../../../../../consts/player';
import { LabelWithQuestionIcon } from '../../../../../../../components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import './SendNotificationsForm.scss';

interface Props {
  onSubmit: (data: any) => void;
  onCancel: () => void;
  availableUsers: AvailableUserExtended[];
}

const SendNotificationsSchema = Yup.object().shape({
  title: Yup.string().required('Required'),
  text: Yup.string().required('Required')
});

export function SendNotificationsForm(props: Props) {
  const { onSubmit, onCancel, availableUsers } = props;

  const SendNotificationsFormInitial = {
    title: '',
    text: '',
    parents: availableUsers
      .filter(user => user.extra.type === AVAILABLE_USERS_EXTRA_TYPE.PARENT)
      .map(availableUser => ({
        value: availableUser.isChecked,
        name: availableUser.userId
      })),
    players: availableUsers
      .filter(user => user.extra.type === AVAILABLE_USERS_EXTRA_TYPE.PLAYER)
      .map(availableUser => ({
        value: availableUser.isChecked,
        name: availableUser.userId
      })),
    staff: availableUsers
        .filter(user => user.extra.type === AVAILABLE_USERS_EXTRA_TYPE.STAFF)
        .map(availableUser => ({
          value: availableUser.isChecked,
          name: availableUser.userId
        })),
    isSelectAllParentsChecked: true,
    isSelectAllPlayersChecked: true,
    isSelectAllStaffChecked: true
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik
            initialValues={SendNotificationsFormInitial}
            validationSchema={SendNotificationsSchema}
            onSubmit={onSubmit}
            render={({ touched, errors, values, setFieldValue }) => (
              <Form>
                <div className="form-group form-check">
                  <div className="form-group">
                    <label>Title</label>
                    <Field type="text" name="title" className="form-control" />
                  </div>
                  {touched.title && errors.title ? <div className="alert alert-danger">{errors.title}</div> : null}

                  <div className="form-group">
                    <label>Text</label>
                    <Field component="textarea" name="text" rows="6" className="form-control mb-3" />
                  </div>
                  {touched.text && errors.text ? <div className="alert alert-danger">{errors.text}</div> : null}

                  <div className="container-fluid mt-3 mb-3">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="eParentsSendNotificationLegend">
                          <LabelWithQuestionIcon labelText="Parents:" hintText={''} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="container-fluid mb-3">
                    <div className="row">
                      <div className="col-md-12 bParentsSendNotification">
                        <div className="form-group mt-4">
                          <Field
                            name={`isSelectAllParentsChecked`}
                            key={'isSelectAllParentsChecked'}
                            render={({ field }) => (
                              <Switch
                                {...field}
                                text={values.isSelectAllParentsChecked ? 'Select all parents' : 'Unselect all parents'}
                                customClass="mb-4"
                                onChange={event => {
                                  const isSelectAllParentsChecked = event.target.checked;

                                  const parents = values.parents.map(user => {
                                    const { isChecked, ...rest } = user;
                                    return {
                                      ...rest,
                                      value: isSelectAllParentsChecked
                                    };
                                  });
                                  setFieldValue('parents', parents);
                                  setFieldValue('isSelectAllParentsChecked', isSelectAllParentsChecked);
                                }}
                              />
                            )}
                          />
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group mt-2 mb-2 eWrapList">
                                {availableUsers
                                  .filter(user => user.extra.type === AVAILABLE_USERS_EXTRA_TYPE.PARENT)
                                  .map((availableUser, index) => {
                                    const { firstName, lastName, userId } = availableUser;
                                    return (
                                      <Field
                                        name={`parents[${index}].value`}
                                        key={userId}
                                        render={({ field }) => (
                                          <Switch {...field} text={`${firstName} ${lastName}`} customClass="mb-3" />
                                        )}
                                      />
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="container-fluid mt-3 mb-3">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="ePlayersSendNotificationLegend">
                          <LabelWithQuestionIcon labelText="Students:" hintText={''} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="container-fluid mb-3">
                    <div className="row">
                      <div className="col-md-12 bPlayersSendNotification">
                        <div className="form-group mt-4">
                          <Field
                            name={`isSelectAllPlayersChecked`}
                            key={'isSelectAllPlayersChecked'}
                            render={({ field }) => (
                              <Switch
                                {...field}
                                text={
                                  values.isSelectAllPlayersChecked ? 'Select all students' : 'Unselect all students'
                                }
                                customClass="mb-4"
                                onChange={event => {
                                  const isSelectAllPlayersChecked = event.target.checked;

                                  const players = values.players.map(user => {
                                    const { isChecked, ...rest } = user;
                                    return {
                                      ...rest,
                                      value: isSelectAllPlayersChecked
                                    };
                                  });
                                  setFieldValue('players', players);
                                  setFieldValue('isSelectAllPlayersChecked', isSelectAllPlayersChecked);
                                }}
                              />
                            )}
                          />

                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group mt-2 mb-2 eWrapList">
                                {availableUsers
                                  .filter(user => user.extra.type === AVAILABLE_USERS_EXTRA_TYPE.PLAYER)
                                  .map((availableUser, index) => {
                                    const { firstName, lastName, userId } = availableUser;
                                    return (
                                      <Field
                                        name={`players[${index}].value`}
                                        key={userId}
                                        render={({ field }) => (
                                          <Switch {...field} text={`${firstName} ${lastName}`} customClass="mb-3" />
                                        )}
                                      />
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="container-fluid mt-3 mb-3">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="eStaffSendNotificationLegend">
                          <LabelWithQuestionIcon labelText="Members off staff:" hintText={''} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="container-fluid mb-3">
                    <div className="row">
                      <div className="col-md-12 bStaffSendNotification">
                        <div className="form-group mt-4">
                          <Field
                              name={`isSelectAllStaffChecked`}
                              key={'isSelectAllStaffChecked'}
                              render={({ field }) => (
                                  <Switch
                                      {...field}
                                      text={
                                        values.isSelectAllStaffChecked ? 'Select all members off staff' : 'Unselect all members off staff'
                                      }
                                      customClass="mb-4"
                                      onChange={event => {
                                        const isSelectAllStaffChecked = event.target.checked;

                                        const staff = values.staff.map(user => {
                                          const { isChecked, ...rest } = user;
                                          return {
                                            ...rest,
                                            value: isSelectAllStaffChecked
                                          };
                                        });
                                        setFieldValue('staff', staff);
                                        setFieldValue('isSelectAllStaffChecked', isSelectAllStaffChecked);
                                      }}
                                  />
                              )}
                          />

                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group mt-2 mb-2 eWrapList">
                                {availableUsers
                                    .filter(user => user.extra.type === AVAILABLE_USERS_EXTRA_TYPE.STAFF)
                                    .map((availableUser, index) => {
                                      const { firstName, lastName, userId } = availableUser;
                                      return (
                                          <Field
                                              name={`staff[${index}].value`}
                                              key={userId}
                                              render={({ field }) => (
                                                  <Switch {...field} text={`${firstName} ${lastName}`} customClass="mb-3" />
                                              )}
                                          />
                                      );
                                    })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                  <button type="submit" className="mt-3 mb-3 btn btn-primary">
                    Send
                  </button>
                </div>
              </Form>
            )}
          />
        </div>
      </div>
    </div>
  );
}
