import * as React from 'react';
import { FunctionComponent } from 'react';
import * as classNames from 'classnames';
import { RatingStar } from './RatingStar/RatingStar';

interface Props {
  minValue: number;
  maxValue: number;
  onePerformanceId: string;
  player: any;
  isEditMode: boolean;
  value: number;
  handleValueChanges: (value, userId, onePerformanceId) => void;
}

export const StarRatingBar: FunctionComponent<Props> = props => {
  const { minValue, maxValue } = props;

  const handleClick = (value, isChecked) => {
    props.handleValueChanges(isChecked ? value : value - 1, props.player, props.onePerformanceId);
  };

  const stars = [];

  for (let i = minValue; i < maxValue; i++) {
    stars.push(
      <RatingStar
        key={`${i}_rating_star`}
        isEditMode={props.isEditMode}
        isChecked={i < props.value}
        value={i + 1}
        handleValueChanges={handleClick}
      />
    );
  }

  return <div className="bStarRatingBar">{stars}</div>;
};

StarRatingBar.displayName = 'StarRatingBar';
