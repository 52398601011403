import * as React from 'react';
import { FunctionComponent } from 'react';
import './TimeIntervalField.css';
import { DATE_INTERVAL } from '../../consts/table';
import MaskedInput from 'react-text-mask';
import * as classNames from 'classnames';

interface Props {
  onTableFilterChange: (event, value: string, options?) => void;
  field: string;
  from: string;
  to: string;
  customClass?: string;
  onEnterPress?: (event) => void;
}

export const TimeIntervalField: FunctionComponent<Props> = props => {
  const { onEnterPress, customClass } = props;
  const classes = classNames('pl-2', 'mMinWidth185', 'mMaxWidth250', customClass);

  return (
    <div className={classes}>
      <div className="input-group mMarginBottom4">
        <div className="input-group-prepend">
          <span className="input-group-text mMinWidth67">From:</span>
        </div>
        <MaskedInput
          type="text"
          className="form-control mMaxWidth80"
          onChange={event => {
            props.onTableFilterChange(event, props.field, DATE_INTERVAL.FROM);
          }}
          mask={[/\d/, /\d/, ':', /\d/, /\d/]}
          placeholder="HH:MM"
          value={props.from}
          onKeyDown={onEnterPress}
        />
      </div>
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text mMinWidth67">To:</span>
        </div>
        <MaskedInput
          type="text"
          className="form-control mMaxWidth80"
          onChange={event => {
            props.onTableFilterChange(event, props.field, DATE_INTERVAL.TO);
          }}
          mask={[/\d/, /\d/, ':', /\d/, /\d/]}
          placeholder="HH:MM"
          value={props.to}
          onKeyDown={onEnterPress}
        />
      </div>
    </div>
  );
};

TimeIntervalField.displayName = 'TimeIntervalField';
