import * as React from 'react';
import { Attachment } from '../../models/generalMessage';
import { HTMLEditorQuill } from '../HTMLEditorQuill/HTMLEditorQuill';
import { Loader } from '../Loader/Loader';
import { FunctionComponent } from 'react';
import { LabelWithQuestionIcon } from '../LabelWithQuestionIcon/LabelWithQuestionIcon';
import { getFileName } from '../../helpers/file/file';

interface Props {
  onEmailSubjectChange: (event) => void;
  onEmailBodyChange: (editorState: any) => void;
  onSmsTextChange: (event) => void;
  onPushTitleChange: (event) => void;
  onPushBodyChange: (event) => void;
  onEmailAttachmentChange: (event) => void;
  onRemoveAttachment: (key: string) => void;
  onCloseImageError: () => void;
  onIsEmailEditorChange: (event) => void;
  onIsSmsEditorChange: (event) => void;
  onIsPushEditorChange: (event) => void;

  isEmailEditorEnabled: boolean;
  isSmsEditorEnabled: boolean;
  isPushEditorEnabled: boolean;

  isEditable?: boolean;

  messageSmsText: string;
  messageEmailSubject: string;
  messageEmailBody: string;
  messagePushTitle: string;
  messagePushBody: string;

  messageAttachments: Attachment[];
  isImageError: boolean;
  isError: boolean;
  isLoadingFile: boolean;
}

const SMS_CHARACTERS_LIMIT = 160;

export const GeneralMessageTextForm: FunctionComponent<Props> = props => {
  const renderAttachments = () => {
    const { isImageError, isError, messageAttachments } = props;
    const onRemoveAttachment = props.onRemoveAttachment;

    return messageAttachments.map((attachment, index) => {
      const fileName = attachment.fileName;
      const attachmentFileName = getFileName(fileName);

      let fileSize = '';
      if (typeof attachment.fileSize !== 'undefined') {
        fileSize = `${(attachment.fileSize / 1000).toFixed(0)} Kb`;
      }

      return (
        <div key={`attachment_${index}`}>
          {attachmentFileName} {fileSize}
          <button
            type="button"
            className="close"
            disabled={isImageError || isError}
            onClick={() => onRemoveAttachment(attachment.externalFileId)}
          >
            <span>&times;</span>
          </button>
        </div>
      );
    });
  };

  const renderSmsCharCounters = () => {
    const { messageSmsText } = props;
    const smsCharactersCount = messageSmsText.length;
    const smsMessagesCount = Math.ceil(smsCharactersCount / SMS_CHARACTERS_LIMIT);

    return (
      <div className="eMessageTextFormSmsEditorCounters text-secondary">
        <i>{`${smsCharactersCount} ${smsCharactersCount === 1 ? 'character' : 'characters'} have been entered.`}</i>
        <br />
        <i>{`${smsMessagesCount} ${smsMessagesCount === 1 ? 'message' : 'messages'} to be sent.`}</i>
      </div>
    );
  };

  const {
    onEmailSubjectChange,
    onEmailBodyChange,
    onSmsTextChange,
    onPushTitleChange,
    onPushBodyChange,
    messageSmsText,
    messageEmailSubject,
    messageEmailBody,
    messagePushTitle,
    messagePushBody,
    isEditable,
    onEmailAttachmentChange,
    isImageError,
    isError,
    isEmailEditorEnabled,
    isSmsEditorEnabled,
    isPushEditorEnabled,
    isLoadingFile
  } = props;

  const isDisabled = isEditable === false;
  const isAllEditorDisabled = !isSmsEditorEnabled && !isPushEditorEnabled && !isEmailEditorEnabled;

  return (
    <div className="bMessageTextForm">
      <div className="container-fluid pl-0">
        <div className="row">
          <div className="col-md-6">
            <div className="bMessageTextFormEmailEditor border border-primary rounded">
              <div className="form-group form-check">
                <input
                  id="messageTextFormIsEmailEditorEnabled"
                  type="checkbox"
                  className="form-check-input"
                  checked={isEmailEditorEnabled}
                  onChange={props.onIsEmailEditorChange}
                  disabled={isDisabled}
                />
                <LabelWithQuestionIcon
                  labelText="Send email"
                  hintText={
                    'Email will be sent to all users, you can choose files to attache please note the files ' +
                    'size is limited - you will see an error message if you try to send a file that is larger than allowed.'
                  }
                  customLabelClass="form-check-label"
                  labelHtmlFor="messageTextFormIsEmailEditorEnabled"
                />
              </div>
              <div className="form-group">
                <label>Subject</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={onEmailSubjectChange}
                  value={messageEmailSubject}
                  disabled={!isEmailEditorEnabled || isDisabled}
                />
              </div>
              <div className="form-group eMessageTextEditor">
                <label>Body</label>
                <HTMLEditorQuill
                  onEmailBodyChange={onEmailBodyChange}
                  value={messageEmailBody}
                  isEnabled={isEmailEditorEnabled}
                />
              </div>
              {!isDisabled && (
                <div className="form-group">
                  <label htmlFor="attachment">Attachments</label>
                  <input
                    type="file"
                    className="form-control-file"
                    id="attachment"
                    onChange={onEmailAttachmentChange}
                    disabled={isImageError || isError || isAllEditorDisabled}
                  />
                  {isLoadingFile && <Loader />}
                  {isImageError ? (
                    <div className="alert-danger">
                      Error uploading
                      <button type="button" className="close" onClick={props.onCloseImageError}>
                        <span>&times;</span>
                      </button>
                    </div>
                  ) : null}
                </div>
              )}
              {renderAttachments()}
            </div>
          </div>
          <div className="col-md-3">
            <div className="bMessageTextFormPushEditor border border-primary rounded">
              <div className="form-group form-check">
                <input
                  id="messageTextFormIsPushEditorEnabled"
                  type="checkbox"
                  className="form-check-input"
                  checked={isPushEditorEnabled}
                  onChange={props.onIsPushEditorChange}
                  disabled={isDisabled}
                />
                <LabelWithQuestionIcon
                  labelText=" Send push"
                  hintText={
                    'Push notifications will only be sent to users providing they have the SiT app installed on their smart device.'
                  }
                  customLabelClass="form-check-label"
                  labelHtmlFor="messageTextFormIsPushEditorEnabled"
                />
              </div>
              <div className="form-group">
                <label>Title</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={onPushTitleChange}
                  value={messagePushTitle}
                  disabled={!isPushEditorEnabled || isDisabled}
                />
              </div>
              <div className="form-group">
                <label>Body</label>
                <textarea
                  rows={17}
                  onChange={onPushBodyChange}
                  value={messagePushBody}
                  disabled={!isPushEditorEnabled || isDisabled}
                  className="form-control"
                />
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="bMessageTextFormSmsEditor border border-primary rounded">
              <div className="form-group form-check">
                <input
                  id="messageTextFormIsSmsEditorEnabled"
                  type="checkbox"
                  className="form-check-input"
                  checked={isSmsEditorEnabled}
                  onChange={props.onIsSmsEditorChange}
                  disabled={isDisabled}
                />
                <LabelWithQuestionIcon
                  labelText="Send sms"
                  hintText={
                    'SMS messages are charged per fragment sent, so ensure that you check how many messages ' +
                    'are being sent when composing you message. To enable you to send SMS messages this needs to be ' +
                    'included within your SiT subscription.'
                  }
                  customLabelClass="form-check-label"
                  labelHtmlFor="messageTextFormIsSmsEditorEnabled"
                />
              </div>
              <div className="form-group">
                <label>Text</label>
                <textarea
                  rows={18}
                  onChange={onSmsTextChange}
                  value={messageSmsText}
                  disabled={!isSmsEditorEnabled || isDisabled}
                  className="form-control"
                />
                {renderSmsCharCounters()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

GeneralMessageTextForm.displayName = 'GeneralMessageTextForm';
