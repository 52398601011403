import * as React from 'react';
import * as BPromise from 'bluebird';
import { Loader } from 'Src/components/Loader/Loader';
import { AppUser } from 'Src/views/App/App';
import { History, Location } from 'history';
import { getFromHistory } from 'Src/helpers/history/history';
import { getTournament } from 'Src/helpers/service/adminSU/tournamentsTable';
import { INVITE_STATUS } from 'Src/consts/invite';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { acceptAutoEnrollmentInvite, acceptInvite, getInvite, getInvites } from 'Src/helpers/service/admin/inivtes';
import { SchoolInvite } from 'Src/models/invite';
import { AcceptTournamentInviteWithoutAutoEnrollmentTeams } from 'Src/views/GenericView/AdminView/Invites/AcceptTournamentInviteWithoutAutoEnrollmentTeams/AcceptTournamentInviteWithoutAutoEnrollmentTeams';
import { getSchoolPublic } from 'Src/helpers/service/public/public';
import { sendTournamentInvite } from 'Src/helpers/service/adminSU/tournamentEligibleSchoolsTable';
import * as propz from 'propz';
import { AcceptTournamentInviteWithAutoEnrollmentTeams } from 'Src/views/GenericView/AdminView/Invites/AcceptTournamentInviteWithAutoEnrollmentTeams/AcceptTournamentInviteWithAutoEnrollmentTeams';
import { getTeamPlacesLeft, isIndividualTournament, isTeamTournament } from 'Src/helpers/tournament/tournament';
import { GENDER } from 'Src/consts/common';
import { addSchoolTournamentTeam } from 'Src/helpers/service/admin/tournamentTeams';
import { uniqArray } from 'Src/helpers/common/common';
import { AGE_GROUPS } from 'Src/consts/school';
import { TOURNAMENT_GENDER_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/tournament';

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}
interface State {
  isLoading: boolean;
  isAcceptTournamentInviteWithoutAutoEnrollmentModalOpen: boolean;
  isAcceptTournamentInviteWithAutoEnrollmentModalOpen: boolean;
  isTournamentInviteWaitingListModalOpen: boolean;
  isTournamentInviteInfoModalOpen: boolean;
  invite: SchoolInvite;
}

export class TournamentInvite extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: true,
      isAcceptTournamentInviteWithoutAutoEnrollmentModalOpen: false,
      isAcceptTournamentInviteWithAutoEnrollmentModalOpen: false,
      isTournamentInviteWaitingListModalOpen: false,
      isTournamentInviteInfoModalOpen: false,
      invite: undefined
    };
  }
  componentDidMount() {
    const { history, user } = this.props;
    const tournamentId = getFromHistory(history, 'tournament');
    const { activeSchoolId } = user;

    const filter = { where: { tournamentId: tournamentId } };

    this.setState({
      isLoading: true
    });

    const promises = [getTournament(user, tournamentId), getInvites(user, filter)];

    let invites = [];
    let tournament;

    BPromise.all(promises)
      .then(([_tournament, _invites]) => {
        invites = _invites;
        tournament = _tournament;
        if (invites.length === 0) {
          //send invite yourself
          const { schoolId } = tournament;
          return getSchoolPublic(schoolId);
        } else {
          return BPromise.resolve();
        }
      })
      .then(schoolUnion => {
        if (typeof schoolUnion === 'undefined') {
          return BPromise.resolve();
        } else {
          const isSchoolConsistInSU = schoolUnion.schoolIds.some(
            schoolIdInSchoolUnion => schoolIdInSchoolUnion === activeSchoolId
          );
          return isSchoolConsistInSU
            ? sendTournamentInvite(user, tournamentId, { invitedSchoolId: activeSchoolId }, schoolUnion.id)
            : BPromise.resolve();
        }
      })
      .then(invite => {
        return typeof invite === 'undefined' ? BPromise.resolve() : getInvite(user, invite.id);
      })
      .then(invite => {
        const inviteList = typeof invite !== 'undefined' ? [invite] : invites;

        const notAcceptedInvites = inviteList.filter(invite => invite.status === INVITE_STATUS.NOT_READY);
        const tournamentSchoolIds = tournament.schoolIds.map(schoolIdObj => schoolIdObj.schoolId) || [];
        const isSchoolTournamentParticipant = tournamentSchoolIds.some(
          tournamentSchoolId => tournamentSchoolId === activeSchoolId
        );

        switch (true) {
          // show invite
          case notAcceptedInvites.length > 0: {
            const [firstInvite] = inviteList;

            const isAutoEnrollmentTeams = propz.get(firstInvite, ['tournament', 'isAutoEnrollmentTeams'], false);

            if (isAutoEnrollmentTeams) {
              this.setState({
                isLoading: false,
                isAcceptTournamentInviteWithAutoEnrollmentModalOpen: true,
                invite: firstInvite
              });
            } else {
              this.setState({
                isLoading: false,
                isAcceptTournamentInviteWithoutAutoEnrollmentModalOpen: true,
                invite: firstInvite
              });
            }

            break;
          }
          // school in waiting list
          case !isSchoolTournamentParticipant:
            history.push({
              pathname: '/calendar'
            });
            break;
          // show tournament teams
          default:
            history.push({
              pathname: `/tournaments/teams`,
              search: `tournamentId=${tournamentId}`
            });
            break;
        }
      });
  }

  onAcceptTournamentInviteWithoutAutoEnrollmentTeamsClick = () => {
    const { invite } = this.state;
    const { user, history } = this.props;

    this.setState({
      isLoading: true
    });

    acceptInvite(user, invite.id).then(res => {
      this.setState({
        isLoading: false,
        isTournamentInviteInfoModalOpen: true
      });
    });
  };

  onAcceptTournamentInviteWithAutoEnrollmentTeamsClick = (data?) => {
    const { user, history } = this.props;
    const { activeSchoolId, activeSchoolName } = user;

    const { invite } = this.state;

    const { tournament, id: inviteId, tournamentId } = invite;
    const { autoEnrollmentTeamsSettings, gender: tournamentGender, ages, sportId } = tournament;

    const isAutoEnrollmentToWaitingList = propz.get<boolean>(tournament, ['isAutoEnrollmentToWaitingList'], false);
    const isIndividual = isIndividualTournament(tournament);
    const isTeam = isTeamTournament(tournament);

    const isMixedTeamsAvailable = propz.get<boolean>(tournament, ['isMixedTeamsAvailable'], false);
    const isGenderMixed = tournamentGender === GENDER.MIXED;
    const gender = isGenderMixed && !isMixedTeamsAvailable ? GENDER.MALE_ONLY : tournamentGender;

    const ageIndex = propz.get(tournament, ['ages', 0], 0);

    const teamPlacesLeft = getTeamPlacesLeft(autoEnrollmentTeamsSettings, ageIndex, gender);
    const autoEnrollmentTeamsSetting = propz.get(autoEnrollmentTeamsSettings, [0]);
    const maximumNumberTeams = propz.get(autoEnrollmentTeamsSetting, ['maximumNumberTeams']);
    const isAllSpacesBooked = isIndividual && maximumNumberTeams === 0 ? false : teamPlacesLeft === 0;

    const isAllGenderAndAgeTeamPlacesLeft = autoEnrollmentTeamsSettings.every(autoEnrollmentTeamsSetting => {
      return autoEnrollmentTeamsSetting.maximumNumberTeams - autoEnrollmentTeamsSetting.currentNumberTeams > 0;
    });

    let isAllTeamsCountEmpty = true;

    if (typeof data !== 'undefined') {
      for (let key in data) {
        const currentTeamsCount = Number(data[key]);
        if (currentTeamsCount !== 0) {
          isAllTeamsCountEmpty = false;
        }
      }
    }

    this.setState({
      isLoading: true
    });

    switch (true) {
      case isAutoEnrollmentToWaitingList:
        acceptInvite(user, inviteId).then(res => {
          history.push({
            pathname: '/calendar'
          });
        });
        break;
      case isIndividual && !isAllSpacesBooked:
        acceptAutoEnrollmentInvite(user, inviteId)
          .then(res => {
            const teamBody = {
              name: activeSchoolName,
              schoolId: activeSchoolId,
              gender: tournamentGender,
              ages: ages,
              sportId: sportId,
              teamType: 'ADHOC'
            };

            return addSchoolTournamentTeam(user, tournamentId, teamBody);
          })
          .then(res => {
            this.setState({
              isLoading: false,
              isTournamentInviteInfoModalOpen: true
            });
          });
        break;
      case isIndividual && isAllSpacesBooked:
        acceptInvite(user, inviteId).then(res => {
          history.push({
            pathname: '/calendar'
          });
        });
        break;
      case isTeam && maximumNumberTeams === 0: {
        acceptAutoEnrollmentInvite(user, inviteId)
          .then(res => {
            const teamBody = {
              name: activeSchoolName,
              schoolId: activeSchoolId,
              gender: tournamentGender,
              ages: ages,
              sportId: sportId,
              teamType: 'ADHOC'
            };

            return addSchoolTournamentTeam(user, tournamentId, teamBody);
          })
          .then(res => {
            this.setState({
              isLoading: false,
              isAcceptTournamentInviteWithAutoEnrollmentModalOpen: false,
              isTournamentInviteInfoModalOpen: true
            });
          });
        break;
      }
      case !isAllGenderAndAgeTeamPlacesLeft && isAllTeamsCountEmpty: {
        this.setState({
          isLoading: false,
          isAcceptTournamentInviteWithAutoEnrollmentModalOpen: false,
          isTournamentInviteWaitingListModalOpen: true
        });
        break;
      }
      default:
        let teamsArray = [];

        for (let key in data) {
          const [age, gender] = key.split('-');
          const currentTeamsCount = Number(data[key]);
          if (currentTeamsCount > 0) {
            teamsArray.push({
              age: Number(age),
              gender: gender,
              count: Number(data[key])
            });
          }
        }

        let teamName = '';

        const teamsGenderArray = teamsArray.map(team => team.gender);
        const teamsGenderArrayUniq = uniqArray(teamsGenderArray);

        const isGenderMale = tournamentGender === GENDER.MALE_ONLY;
        const isGenderFemale = tournamentGender === GENDER.FEMALE_ONLY;

        const isSomeTeamsWithEqualGender = teamsGenderArray.length !== teamsGenderArrayUniq.length;

        const charArray = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('');

        let teamsDataToPost = [];

        teamsArray.forEach(team => {
          const teamCount = team.count;
          const teamAge = team.age;
          const teamAgeConverted = AGE_GROUPS['U5-U18'][teamAge];
          switch (true) {
            case isGenderMale:
            case isGenderFemale:
              teamName = `${teamAgeConverted}`;
              if (teamCount === 1) {
                teamName = `${teamAgeConverted}`;
                teamsDataToPost.push({
                  name: teamName,
                  schoolId: activeSchoolId,
                  gender: team.gender,
                  ages: [teamAge],
                  isAutoEnrolledTeam: true
                });
              } else {
                for (let i = 0; i < teamCount; i++) {
                  teamName = `${teamAgeConverted} ${charArray[i]}`;
                  teamsDataToPost.push({
                    name: teamName,
                    schoolId: activeSchoolId,
                    gender: team.gender,
                    ages: [teamAge],
                    isAutoEnrolledTeam: true
                  });
                }
              }
              break;

            case !isSomeTeamsWithEqualGender && teamCount === 1:
              teamName = `${teamAgeConverted}`;
              teamsDataToPost.push({
                name: teamName,
                schoolId: activeSchoolId,
                gender: team.gender,
                ages: [teamAge],
                isAutoEnrolledTeam: true
              });
              break;
            case !isSomeTeamsWithEqualGender && teamCount > 1:
              for (let i = 0; i < teamCount; i++) {
                teamName = `${teamAgeConverted} ${charArray[i]}`;
                teamsDataToPost.push({
                  name: teamName,
                  schoolId: activeSchoolId,
                  gender: team.gender,
                  ages: [teamAge],
                  isAutoEnrolledTeam: true
                });
              }
              break;
            case isSomeTeamsWithEqualGender && teamCount === 1:
              teamName = `${teamAgeConverted} ${TOURNAMENT_GENDER_SERVER_TO_CLIENT_MAPPING[team.gender]}`;
              teamsDataToPost.push({
                name: teamName,
                schoolId: activeSchoolId,
                gender: team.gender,
                ages: [teamAge],
                isAutoEnrolledTeam: true
              });
              break;
            case isSomeTeamsWithEqualGender && teamCount > 1:
              for (let i = 0; i < teamCount; i++) {
                teamName = `${teamAgeConverted} ${TOURNAMENT_GENDER_SERVER_TO_CLIENT_MAPPING[team.gender]} ${
                  charArray[i]
                }`;
                teamsDataToPost.push({
                  name: teamName,
                  schoolId: activeSchoolId,
                  gender: team.gender,
                  ages: [teamAge],
                  isAutoEnrolledTeam: true
                });
              }
              break;
          }
        });

        acceptAutoEnrollmentInvite(user, inviteId)
          .then(res => BPromise.all(teamsDataToPost.map(team => addSchoolTournamentTeam(user, tournamentId, team))))
          .then(res => {
            history.push({
              pathname: `/tournaments/teams`,
              search: `tournamentId=${tournamentId}`
            });
          });
    }
  };

  onCancelTournamentInviteWithoutAutoEnrollmentTeamsClick = () => {
    const { history } = this.props;
    history.push({
      pathname: '/calendar'
    });
  };

  onCancelTournamentInviteWithAutoEnrollmentTeamsClick = () => {
    const { history } = this.props;
    history.push({
      pathname: '/calendar'
    });
  };

  renderAcceptTournamentInviteWithoutAutoEnrollmentModal() {
    const { isAcceptTournamentInviteWithoutAutoEnrollmentModalOpen, invite } = this.state;
    const { user } = this.props;

    return (
      <SimpleModal isOpen={isAcceptTournamentInviteWithoutAutoEnrollmentModalOpen}>
        <AcceptTournamentInviteWithoutAutoEnrollmentTeams
          onCancel={this.onCancelTournamentInviteWithoutAutoEnrollmentTeamsClick}
          onAccept={this.onAcceptTournamentInviteWithoutAutoEnrollmentTeamsClick}
          invite={invite}
          user={user}
        />
      </SimpleModal>
    );
  }

  renderAcceptTournamentInviteWithAutoEnrollmentModal() {
    const { isAcceptTournamentInviteWithAutoEnrollmentModalOpen, invite } = this.state;
    const { user } = this.props;

    return (
      <SimpleModal isOpen={isAcceptTournamentInviteWithAutoEnrollmentModalOpen}>
        <AcceptTournamentInviteWithAutoEnrollmentTeams
          onCancel={this.onCancelTournamentInviteWithAutoEnrollmentTeamsClick}
          onAccept={this.onAcceptTournamentInviteWithAutoEnrollmentTeamsClick}
          invite={invite}
          user={user}
        />
      </SimpleModal>
    );
  }

  acceptTournamentInviteWaitingList = () => {
    const { user, history } = this.props;
    const { invite } = this.state;
    const { id: inviteId } = invite;

    this.setState({
      isLoading: true
    });

    acceptInvite(user, inviteId).then(res => {
      history.push({
        pathname: '/calendar'
      });
    });
  };

  onTournamentInviteWaitingListModalCloseClick = () => {
    this.setState({
      isTournamentInviteWaitingListModalOpen: false,
      isAcceptTournamentInviteWithAutoEnrollmentModalOpen: true
    });
  };

  renderTournamentInviteWaitingListModalOpen(): React.ReactNode {
    const { isTournamentInviteWaitingListModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isTournamentInviteWaitingListModalOpen}
        title={'Warning'}
        body={
          "You haven't specified any teams for the tournament. Your school will not be enrolled to the tournament but will be put on to the waiting list"
        }
        buttonText={'Accept (waiting list)'}
        buttonCancelText={'Cancel'}
        onButtonClick={this.acceptTournamentInviteWaitingList}
        onCloseClick={this.onTournamentInviteWaitingListModalCloseClick}
      />
    );
  }

  renderTournamentInviteInfoModalOpen(): React.ReactNode {
    const { isTournamentInviteInfoModalOpen, invite } = this.state;
    const { tournament } = invite;
    const { history } = this.props;

    return (
      <SimpleModal
        isOpen={isTournamentInviteInfoModalOpen}
        title={'Info'}
        body={`You have successfully booked your space in the ${tournament.name}. You will get a notification from the organisers once you are able to add your athletes to the individual events.`}
        buttonText={'OK'}
        onButtonClick={() => {
          history.push({
            pathname: '/calendar'
          });
        }}
      />
    );
  }

  render() {
    const {
      isLoading,
      isAcceptTournamentInviteWithoutAutoEnrollmentModalOpen,
      isAcceptTournamentInviteWithAutoEnrollmentModalOpen,
      isTournamentInviteWaitingListModalOpen,
      isTournamentInviteInfoModalOpen
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes =
      isAcceptTournamentInviteWithoutAutoEnrollmentModalOpen ||
      isAcceptTournamentInviteWithAutoEnrollmentModalOpen ||
      isTournamentInviteInfoModalOpen ||
      isTournamentInviteWaitingListModalOpen
        ? 'mt-3 modal-open'
        : 'mt-3';

    return (
      <div className={classes}>
        {this.renderAcceptTournamentInviteWithoutAutoEnrollmentModal()}
        {this.renderAcceptTournamentInviteWithAutoEnrollmentModal()}
        {this.renderTournamentInviteWaitingListModalOpen()}
        {this.renderTournamentInviteInfoModalOpen()}
      </div>
    );
  }
}
