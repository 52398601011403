import * as React from 'react';
import { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from '../../../../../../../consts/report';
import { Switch } from '../../../../../../../components/Switch/Switch';
import { LabelWithQuestionIcon } from '../../../../../../../components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import { MultiSelectCheckboxes } from '../../../../../../../components/MultiSelectCheckboxes/MultiSelectCheckboxes';
import {
  getReportAgesForMultiSelect,
  getReportDefaultGendersForMultiSelect
} from '../../../../../../../helpers/multiselect/multiselect';
import { School } from '../../../../../../../models/school';
import { SchoolForm } from '../../../../../../../models/form';
import '../../SchoolLeagues.css';

interface Props {
  onCloseClick: () => void;
  onSubmit: (data: any, reportType: string) => void;
  school: School;
  forms: SchoolForm[];
}

// report 058
export function TargetCompletionStatisticsReportForm({ onCloseClick, onSubmit, school }: Props) {
  const requestInitial = {
    isPrintParticipating: true,
    isPrintPercent: true,
    isPrintPP: true,
    isPrintSEN: true,
    isPrintFSM: true,
    isSelectAllAges: true
  };

  return (
    <Formik
      onSubmit={values => onSubmit(values, REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.TARGET_COMPLETION_STATISTICS)}
      initialValues={requestInitial}
      render={({ values, setFieldValue }) => (
        <Form>
          <div className="bReportFormTitle">{'Target Completion Statistics'}</div>
          <div className="mb-3 mt-3">
            {'The Target Completion Statistics report is an essential resource ' +
              'for tracking student progress towards their individual targets ' +
              'across selected activities. The report includes the numbers and ' +
              'percentages of students who have completed their targets, as compared ' +
              'to the total number of eligible students. Additionally, the report ' +
              'offers breakdowns by gender and PP/SEN attributes. '}
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isPrintParticipating"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintParticipating}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintParticipating = event.target.checked;
                      setFieldValue('isPrintParticipating', isPrintParticipating);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show number of participating students"
                hintText={`This will allow you to view the number of students taking part in particular activities.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintParticipating"
              />
            </div>
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isPrintPercent"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintPercent}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintPercent = event.target.checked;
                      setFieldValue('isPrintPercent', isPrintPercent);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show percents"
                hintText={`This will allow you to view the percentage rates calculated as a ratio of students who have completed their targets to participating students.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintPercent"
              />
            </div>
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isPrintPP"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintPP}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintPP = event.target.checked;
                      setFieldValue('isPrintPP', isPrintPP);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Breakdown by PP"
                hintText={`This will allow you to view the numbers of participating/eligible students marked as PP.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintPP"
              />
            </div>
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isPrintSEN"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintSEN}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintSEN = event.target.checked;
                      setFieldValue('isPrintSEN', isPrintSEN);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Breakdown by SEN"
                hintText={`This will allow you to view the numbers of participating/eligible students marked as SEN.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintSEN"
              />
            </div>
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isPrintFSM"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintFSM}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintFSM = event.target.checked;
                      setFieldValue('isPrintFSM', isPrintFSM);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Breakdown by FSM"
                hintText={`This will allow you to view the numbers of participating/eligible students marked as receiving free school meals.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintFSM"
              />
            </div>
          </div>

          <button type="button" className="btn btn-secondary mr-3" onClick={onCloseClick}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Get
          </button>
        </Form>
      )}
    />
  );
}
