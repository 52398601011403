import * as React from 'react';
import { Component } from 'react';
import * as propz from 'propz';
import { Switch } from 'Src/components/Switch/Switch';
import { CLUB_CURRENCY } from 'Src/consts/club';
import { CurrencyInput } from 'Src/components/CurrencyInput/CurrencyInput';
import { CURRENCY_TYPE } from 'Src/types/common';
import { AppUser } from 'Src/views/App/App';
import { getName, searchFunctionPaymentAccounts } from 'Src/helpers/autocomplete/autocomplete';
import { Autocomplete } from 'Src/components/Autocomplete/Autocomplete';
import { ClubWizardErrors } from '../ClubWizard';

interface Props {
  user: AppUser;
  isPaymentRequired: boolean;
  paymentAccount: any;
  price: any;
  errors: ClubWizardErrors;
  onPriceChange: (price: string) => void;
  onPaymentAccountChange: (paymentAccountId: string) => void;
  onIsPaymentRequiredChange: (isPaymentRequired: boolean) => void;
}

export class ClubPaymentSettingsStep extends Component<Props> {
  getPaymentAccounts = () => {
    const { user } = this.props;

    return searchFunctionPaymentAccounts(user);
  };

  render() {
    const {
      price: priceObj,
      onPaymentAccountChange,
      paymentAccount,
      isPaymentRequired,
      onIsPaymentRequiredChange,
      onPriceChange,
      errors
    } = this.props;

    const currency = propz.get(priceObj, ['currency'], CLUB_CURRENCY.POUND);
    const price = propz.get(priceObj, ['price'], 0);

    return (
      <div className="form-group form-check">
        <Switch
          name="isPaymentRequired"
          value={isPaymentRequired}
          onChange={event => {
            const value = event.target.checked;
            onIsPaymentRequiredChange(value);
          }}
          text={'Payment required'}
          customClass="mb-3 mt-5"
        />

        <label>Payment account</label>
        <Autocomplete
          searchFunction={this.getPaymentAccounts}
          getElementTitle={getName}
          customClass="mFullWidth mb-3"
          defaultItem={paymentAccount}
          onSelect={paymentAccountCurrent => {
            onPaymentAccountChange(paymentAccountCurrent);
          }}
        />
        {errors.paymentAccount && <div className="alert alert-danger">Required</div>}

        <label>Price</label>
        <CurrencyInput
          currency={currency as CURRENCY_TYPE}
          onChange={value => {
            onPriceChange(value);
          }}
          value={String(price)}
          disabled={false}
        />
        {errors.price && <div className="alert alert-danger">Required, enter a number please</div>}
      </div>
    );
  }
}
