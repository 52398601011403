export function stringTimeToPoints(value, mask) {
  const maskParts = mask.replace(/[^hmsc]/g, ':').split(':');
  const valueParts = value.replace(/[^0-9_]/g, ':').split(':');

  let result = 0;
  for (let i = 0; i < maskParts.length; i++) {
    let currentMaskPart = maskParts[i];

    let tmp = 0;
    switch (true) {
      case isHourMask(currentMaskPart): {
        tmp = valueParts[i] * 3600;
        break;
      }
      case isMinuteMask(currentMaskPart): {
        if (valueParts[i] < 60) {
          tmp = valueParts[i] * 60;
        } else {
          tmp = NaN;
        }
        break;
      }
      case isSecondMask(currentMaskPart): {
        if (valueParts[i] < 60) {
          result += valueParts[i] * 1;
        } else {
          tmp = NaN;
        }
        break;
      }
      case isMillisecondMask(currentMaskPart): {
        if (typeof valueParts[i] !== 'undefined') {
          tmp = (valueParts[i] * 1) / Math.pow(10, valueParts[i].length);
        }
        break;
      }
      default:
        tmp = NaN;
        break;
    }
    if (isNaN(tmp)) {
      return NaN;
    }
    result += tmp;
  }

  return result;
}

export function plainPointsToTimeString(value, mask) {
  let valueCopy = value;

  if (isNaN(value) || value === null) {
    valueCopy = 0;
  }

  const valueParts = String(valueCopy).split('.');
  const integerPartOfValue = Number(valueParts[0]);

  // just copy integer part of plain points
  let remainder = Number(integerPartOfValue);

  let timeString = '';

  const maskParts = mask.replace(/[^hmsc]/g, '.').split('.');

  maskParts.forEach(maskPart => {
    switch (true) {
      case isHourMask(maskPart): {
        const hourCount = getCountOfCurrentTimeUnit(remainder, 'HOURS');
        // remove hours in sec from remainder
        remainder -= hourCount * 3600;

        const hourString = convertValueUnitToStringByMask(hourCount, maskPart, 'TIME');

        timeString = hourString + getDelimiterByMaskAndRegex(mask, HOUR_WITH_DELIMITER_REGEX);
        break;
      }
      case isMinuteMask(maskPart): {
        const minCount = getCountOfCurrentTimeUnit(remainder, 'MINUTES');
        // remove minutes in sec from remainder
        remainder -= minCount * 60;

        const minString = convertValueUnitToStringByMask(minCount, maskPart, 'TIME');

        timeString += minString + getDelimiterByMaskAndRegex(mask, MINUTE_WITH_DELIMITER_REGEX);
        break;
      }
      case isSecondMask(maskPart): {
        // at this step remainder is a fresh seconds without hours(in sec naturally) and minutes(in sec naturally)
        const secCount = remainder;

        const secString = convertValueUnitToStringByMask(secCount, maskPart, 'TIME');

        timeString += secString + getDelimiterByMaskAndRegex(mask, SECOND_WITH_DELIMITER_REGEX);
        break;
      }
      case isMillisecondMask(maskPart): {
        const roundedValueArray = valueCopy.toFixed(maskPart.length).split('.'),
          floatPartOfRoundedValue = String(typeof roundedValueArray[1] !== 'undefined' ? roundedValueArray[1] : 0);

        const msecString = convertValueUnitToStringByMask(floatPartOfRoundedValue, maskPart, 'TIME');

        timeString += msecString;
        break;
      }
    }
  });

  return timeString;
}

export function plainPointsToDistanceString(value, mask) {
  // just copy integer part of plain points
  let remainder = Number(value);

  let distanceString = '';

  const maskParts = mask.replace(/[^kmc]/g, ':').split(':');

  maskParts.forEach(maskPart => {
    switch (true) {
      case isKilometerMask(maskPart): {
        const kmCount = getCountOfCurrentDistanceUnit(remainder, 'KILOMETERS');
        // remove km in cm from remainder
        remainder -= kmCount * 100000;

        const kmString = convertValueUnitToStringByMask(kmCount, maskPart, 'DISTANCE');

        distanceString = kmString + getDelimiterByMaskAndRegex(mask, KILOMETER_WITH_DELIMITER_REGEX);
        break;
      }
      case isMeterMask(maskPart): {
        const mCount = getCountOfCurrentDistanceUnit(remainder, 'METERS');
        // remove m in cm from remainder
        remainder -= mCount * 100;

        const mString = convertValueUnitToStringByMask(mCount, maskPart, 'DISTANCE');

        distanceString += mString + getDelimiterByMaskAndRegex(mask, METER_WITH_DELIMITER_REGEX);
        break;
      }
      case isCentimeterMask(maskPart): {
        // at this step remainder is a fresh cm without km(in cm naturally) and m(in cm naturally)
        const cmCount = remainder;

        const cmString = convertValueUnitToStringByMask(cmCount, maskPart, 'DISTANCE');

        distanceString += cmString;
        break;
      }
    }
  });

  return distanceString;
}

export function stringDistanceToPoints(value, mask) {
  const maskParts = mask.replace(/[^kmc]/g, ':').split(':'),
    valueParts = value.replace(/[^0-9_]/g, ':').split(':');

  let result = 0;
  for (let i = 0; i < maskParts.length; i++) {
    let currentMaskPart = maskParts[i];

    let tmp = 0;
    switch (true) {
      case isKilometerMask(currentMaskPart): {
        tmp = valueParts[i] * 100000;
        break;
      }
      case isMeterMask(currentMaskPart): {
        if (valueParts[i] < 1000) {
          tmp = valueParts[i] * 100;
        } else {
          tmp = NaN;
        }
        break;
      }
      case isCentimeterMask(currentMaskPart): {
        if (valueParts[i] < 100) {
          result += valueParts[i] * 1;
        } else {
          tmp = NaN;
        }
        break;
      }
      default:
        tmp = NaN;
        break;
    }
    if (isNaN(tmp)) {
      return NaN;
    }
    result += tmp;
  }
  return result;
}

function isHourMask(mask) {
  return mask.search(HOUR_REGEX) !== -1;
}
function isMinuteMask(mask) {
  return mask.search(MINUTE_REGEX) !== -1;
}
function isSecondMask(mask) {
  return mask.search(SECOND_REGEX) !== -1;
}
function isMillisecondMask(mask) {
  return mask.search(MILLISECOND_REGEX) !== -1;
}
function isKilometerMask(mask) {
  return mask.search(KILOMETER_REGEX) !== -1;
}
function isMeterMask(mask) {
  return mask.search(METER_REGEX) !== -1;
}
function isCentimeterMask(mask) {
  return mask.search(CENTIMETER_REGEX) !== -1;
}

export const DEFAULT_TIME_MASK = 'hh:mm:ss.ccc';
export const DEFAULT_DISTANCE_MASK = 'kk mmm.cc';
const HOUR_REGEX = /h{1,}/i;
const HOUR_WITH_DELIMITER_REGEX = /h{1,}(.)/i;
const MINUTE_REGEX = /m{1,2}/i;
const MINUTE_WITH_DELIMITER_REGEX = /m{1,2}(.)/i;
const SECOND_REGEX = /s{1,2}/i;
const SECOND_WITH_DELIMITER_REGEX = /s{1,2}(.)/i;
const MILLISECOND_REGEX = /c{1,}/i;
const KILOMETER_REGEX = /k{1,3}/i;
const KILOMETER_WITH_DELIMITER_REGEX = /k{1,3}(.)/i;
const METER_REGEX = /m{1,3}/i;
const METER_WITH_DELIMITER_REGEX = /m{1,3}(.)/i;
const CENTIMETER_REGEX = /c{1,2}/i;

function getCountOfCurrentTimeUnit(value, timeUnit) {
  switch (timeUnit) {
    case 'HOURS':
      return Math.floor(value / 3600);
    case 'MINUTES':
      return Math.floor(value / 60);
    case 'SECONDS':
      return value;
    default:
      return 0;
  }
}

function getCountOfCurrentDistanceUnit(value, distanceUnit) {
  switch (distanceUnit) {
    case 'KILOMETERS':
      return Math.floor(value / 100000);
    case 'METERS':
      return Math.floor(value / 100);
    default:
      return 0;
  }
}

function convertValueUnitToStringByMask(value, mask, unit) {
  // convert value to string
  let result = String(value);

  switch (true) {
    case isCentimeterMask(mask) && unit === 'DISTANCE': {
      result = addZerosToStartByMask(result, mask);
      break;
    }
    // For milliseconds we have different rules than for other units
    // Because milliseconds are stored as fraction of float number
    // So we should add zeros to end if it need
    // Also we should cut off score string value
    // if value length more than mask length
    case isMillisecondMask(mask) && unit === 'TIME': {
      if (mask.length > result.length) {
        result = addZerosToEndByMask(result, mask);
      } else if (mask.length < result.length) {
        result = cutScoreValueByMask(result, mask);
      }
      break;
    }
    default: {
      result = addZerosToStartByMask(result, mask);
      break;
    }
  }

  return result;
}

function getDelimiterByMaskAndRegex(mask, regex) {
  const result = mask.match(regex);

  return result[1];
}

function addZerosToEndByMask(stringValue, mask) {
  let result = String(stringValue);

  const zerosCount = mask.length - result.length;
  for (let i = 0; i < zerosCount; i++) {
    result = result + '0';
  }

  return result;
}

function addZerosToStartByMask(stringValue, mask) {
  let result = String(stringValue);

  const zerosCount = mask.length - result.length;
  for (let i = 0; i < zerosCount; i++) {
    result = '0' + result;
  }

  return result;
}

function cutScoreValueByMask(stringValue, mask) {
  return stringValue.substring(0, mask.length);
}

export function validateStringTime(value, mask): boolean {
  const points = stringTimeToPoints(value, mask);

  // Points must be a number but not a Nan or Infinite
  // Otherwise points are not valid
  return typeof points === 'number' && isFinite(points);
}

export function stringDistanceValidation(value, mask): boolean {
  const points = stringDistanceToPoints(value, mask);

  // Points must be a number but not a Nan or Infinite
  // Otherwise points are not valid
  return typeof points === 'number' && isFinite(points);
}

export function stringPlainValidation(value): boolean {
  const points = Number(value);
  // value must be a number but not a Nan or Infinite
  // Otherwise points are not valid
  return !isNaN(points) && isFinite(points);
}
