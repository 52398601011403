import * as React from 'react';
import { Component } from 'react';
import * as propz from 'propz';
import { Field, Form, Formik } from 'formik';
import { Club } from 'Src/models/club';
import { Switch } from 'Src/components/Switch/Switch';
import { CLUB_CURRENCY, CLUB_PRICING } from 'Src/consts/club';
import { CurrencyInput } from 'Src/components/CurrencyInput/CurrencyInput';
import { CURRENCY_TYPE } from 'Src/types/common';
import { Button } from 'Src/components/Button/Button';
import { AppUser } from 'Src/views/App/App';
import { getName, searchFunctionPaymentAccounts } from 'Src/helpers/autocomplete/autocomplete';
import { Autocomplete2 } from 'Src/components/Autocomplete/Autocomplete2';
import { Loader } from 'Src/components/Loader/Loader';
import { PaymentAccount } from 'Src/models/paymentAccount';
import { getPaymentAccount } from 'Src/helpers/service/admin/paymentAccounts';

interface Props {
  user: AppUser;
  club: Club;
  onSubmit: (data: any) => void;
}

interface State {
  isEditMode: boolean;
  isLoading: boolean;
  paymentAccountCurrent: PaymentAccount;
  paymentAccountDefault: PaymentAccount;
  errors: {
    paymentAccount: boolean;
    isPaymentRequired: boolean;
    price: boolean;
  };
}

export class ClubPaymentSettingsSummary extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isEditMode: false,
      isLoading: false,
      paymentAccountCurrent: undefined,
      paymentAccountDefault: undefined,
      errors: {
        paymentAccount: false,
        isPaymentRequired: false,
        price: false
      }
    };
  }

  componentDidMount() {
    const { user, club } = this.props;

    const paymentAccountId = propz.get(club, ['paymentSettings', 'paymentAccountId']);
    const isPaymentAccountExist = typeof paymentAccountId !== 'undefined';

    this.setState({
      isLoading: true
    });

    const paymentPromise = isPaymentAccountExist
      ? getPaymentAccount(user, paymentAccountId)
      : this.getPaymentAccounts();

    paymentPromise.then(paymentAccountOrAccounts => {
      if (isPaymentAccountExist) {
        this.setState({
          paymentAccountCurrent: paymentAccountOrAccounts,
          isLoading: false
        });
      } else {
        const paymentAccountDefault = paymentAccountOrAccounts.find(account => account.isDefault);

        this.setState({
          paymentAccountDefault,
          isLoading: false
        });
      }
    });
  }

  getPaymentAccounts = () => {
    const { user } = this.props;

    return searchFunctionPaymentAccounts(user);
  };

  render() {
    const { club, onSubmit } = this.props;
    const { isLoading, paymentAccountCurrent, paymentAccountDefault, isEditMode, errors } = this.state;
    const isPaymentAccountExist = typeof paymentAccountCurrent !== 'undefined';

    if (isLoading) {
      return <Loader />;
    }

    const askToPay = propz.get(club, ['paymentSettings', 'askToPay'], false);

    const currency = propz.get(club, ['price', 'currency'], CLUB_CURRENCY.POUND);
    const price = propz.get(club, ['price', 'price'], 0);
    const priceType = propz.get(club, ['price', 'priceType'], CLUB_PRICING.FREE);

    const initialValues = {
      paymentAccount: paymentAccountCurrent,
      isPaymentRequired: askToPay,
      currency,
      price,
      priceType
    };

    return (
      <div>
        <Formik
          initialValues={initialValues}
          onSubmit={values => {
            const { isPaymentRequired, currency, price: priceValue, priceType, paymentAccount } = values;

            // if user doesn't change price and payment account and errors have not been cought
            const isPriceErrorExist = isPaymentRequired && priceValue === 0;
            const isPaymentAccountExist = typeof paymentAccount !== 'undefined';
            const isPaymentAccountErrorExist = isPaymentRequired && typeof paymentAccount === 'undefined';
            const isErrorsExist = isPriceErrorExist || isPaymentAccountErrorExist;

            if (isErrorsExist) {
              this.setState({
                errors: {
                  ...errors,
                  price: isPriceErrorExist,
                  paymentAccount: isPaymentAccountErrorExist
                }
              });
            } else {
              const paymentSettings = isPaymentAccountExist
                ? {
                    askToPay: isPaymentRequired,
                    requiredForBook: isPaymentRequired,
                    paymentAccountId: paymentAccount.id
                  }
                : {};

              const price = {
                currency,
                price: priceValue,
                priceType
              };

              const ClubUpdated = {
                ...club,
                paymentSettings,
                price
              };

              onSubmit(ClubUpdated);

              this.setState({
                isEditMode: false,
                errors: {
                  ...errors,
                  price: false,
                  paymentAccount: false
                }
              });
            }
          }}
          onReset={() => this.setState({ isEditMode: false })}
          render={({ setFieldValue, values, handleReset, handleSubmit }) => (
            <Form>
              {!isEditMode ? (
                <Button
                  onClick={() => {
                    this.setState({ isEditMode: true });
                    if (!isPaymentAccountExist) {
                      setFieldValue('paymentAccount', paymentAccountDefault);
                    }
                  }}
                  text={'Edit'}
                  customClass={'mt-3 mb-3 ml-3'}
                />
              ) : (
                <>
                  <Button onClick={handleReset} text={'Cancel'} customClass={'mt-3 mb-3 ml-3'} />
                  <Button onClick={handleSubmit} text={'Save'} customClass={'mt-3 mb-3 ml-3'} />
                </>
              )}

              <div className="form-group form-check">
                <Field
                  name="isPaymentRequired"
                  render={({ field }) => (
                    <Switch
                      {...field}
                      onChange={event => {
                        const value = event.target.checked;
                        setFieldValue('isPaymentRequired', value);
                        if (!value) {
                          this.setState({
                            errors: {
                              ...errors,
                              price: false,
                              paymentAccount: false
                            }
                          });
                        }
                      }}
                      text={'Payment required'}
                      customClass="mb-3 mt-3"
                      disabled={!isEditMode}
                    />
                  )}
                />

                <label>Payment account</label>
                <Field
                  name="paymentAccount"
                  render={({ field }) => {
                    return (
                      <Autocomplete2
                        searchFunction={this.getPaymentAccounts}
                        getElementTitle={getName}
                        customClass="mFullWidth mb-3"
                        defaultItem={values.paymentAccount}
                        onSelect={paymentAccount => {
                          setFieldValue('paymentAccount', paymentAccount);
                        }}
                        disabled={!isEditMode}
                      />
                    );
                  }}
                />
                {errors.paymentAccount && <div className="alert alert-danger">Required</div>}

                <label>Price</label>
                <Field
                  disabled={!isEditMode}
                  type="text"
                  name="price"
                  render={({ field }) => {
                    return (
                      <CurrencyInput
                        currency={values.currency as CURRENCY_TYPE}
                        onChange={value => {
                          const newPriceNumber = Number(value);
                          const isNumber = Number.isFinite(newPriceNumber);
                          const isErrorExist = values.isPaymentRequired && newPriceNumber === 0;

                          this.setState({
                            errors: {
                              ...errors,
                              price: isErrorExist
                            }
                          });

                          if (isNumber) {
                            setFieldValue('price', newPriceNumber);
                          }
                        }}
                        value={String(field.value)}
                        disabled={!isEditMode}
                      />
                    );
                  }}
                />
                {errors.price && <div className="alert alert-danger">Required, enter a number please</div>}
              </div>
            </Form>
          )}
        />
      </div>
    );
  }
}
