import * as React from 'react';
import * as propz from 'propz';
import { TeamPlayerWithClashes } from 'Src/models/team';
import { getColorClashes } from 'Src/helpers/cell/cell';

interface Props {
  players: TeamPlayerWithClashes[];
  isShowStudentEventClashes: boolean;
  onClashClick: (item) => void;
}

export function SchoolEventAddExistingTeamPlayers(props: Props) {
  const { players, isShowStudentEventClashes, onClashClick } = props;

  const renderPlayers = () => {
    if (players.length > 0) {
      return players.map((player, index) => {
        const playerName = propz.get(player, ['firstName'], '');
        const playerLastName = propz.get(player, ['lastName'], '');
        const playerForm = propz.get(player, ['form', 'name'], '');
        const playerHouse = propz.get(player, ['house', 'name'], '');

        return (
          <tr key={player.id}>
            <td>{index + 1}</td>
            {isShowStudentEventClashes && getColorClashes(player, onClashClick)}
            <td>{playerName}</td>
            <td>{playerLastName}</td>
            <td>{playerForm}</td>
            <td>{playerHouse}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr key={'team_players_empty_row'}>
          <td>No participants</td>
        </tr>
      );
    }
  };

  return (
    <div className="d-flex">
      <table className={`table table-sm mPre`}>
        <thead>
          <tr>
            <th>#</th>
            {isShowStudentEventClashes && <th>Clashes</th>}
            <th>Name</th>
            <th>Last name</th>
            <th>Form</th>
            <th>House</th>
          </tr>
        </thead>
        <tbody>{renderPlayers()}</tbody>
      </table>
    </div>
  );
}
