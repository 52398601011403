import * as React from 'react';
import { useState } from 'react';
import * as propz from 'propz';
import { AppUser } from 'Src/views/App/App';
import { Tournament } from 'Src/models/tournament';
import { GenericSummary } from 'Src/components/GenericSummary/GenericSummary';
import { TOURNAMENT_DETAILS_TABS } from 'Src/consts/event';
import { TABS } from 'Src/consts/common';
import { EmptyComponent } from 'Src/components/EmptyComponent/EmptyComponent';
import { AdditionalInfo } from './AdditionalInfo/AdditionalInfo';
import { DetailsAndBooking } from './DetailsAndBooking/DetailsAndBooking';
import './TournamentDetails.scss';

interface Props {
  tournament: Tournament;
  user: AppUser;
}

export const TournamentDetails = (props: Props) => {
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);
  const { tournament, user } = props;
  const tournamentName = propz.get(tournament, ['name'], '');
  const schoolLogo = propz.get(tournament, ['school', 'pic'], '');

  const onTabClick = (event, tabIndex) => {
    event.preventDefault();

    setCurrentTabIndex(tabIndex);
  };

  const renderTournamentDetailsTab = (): React.ReactNode => {
    const currentTab = TOURNAMENT_DETAILS_TABS[currentTabIndex];

    switch (currentTab) {
      case TABS.DETAILS_AND_BOOKING:
        return <DetailsAndBooking tournament={tournament} user={user} />;

      case TABS.ADDITIONAL_INFO_AND_DOCUMENTS:
        return <AdditionalInfo tournament={tournament} />;

      default:
        return <EmptyComponent />;
    }
  };

  return (
    <div className="bTournamentDetails">
      <div className="eTournamentSchoolLogo">
        <img src={schoolLogo} alt="School logo" />
      </div>
      <div className="eTournamentName">{tournamentName}</div>
      <div className="mt-4">
        <GenericSummary
          tabs={TOURNAMENT_DETAILS_TABS}
          onTabClick={onTabClick}
          currentTabIndex={currentTabIndex}
          customClass="eTournamentDetailsTabs"
        >
          {renderTournamentDetailsTab()}
        </GenericSummary>
      </div>
    </div>
  );
};

TournamentDetails.displayName = 'TournamentDetails';
