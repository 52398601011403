import * as React from 'react';
import { Route, Switch } from 'react-router';
import { HighLevelTournamentsUpcoming } from './HighLevelTournamentsUpcoming/HighLevelTournamentsUpcoming';
import { HighLevelTournamentsArchive } from './HighLevelTournamentsArchive/HighLevelTournamentsArchive';
import { HighLevelTournamentTeams } from './HighLevelTournamentTeams/HighLevelTournamentTeams';
import { HighLevelTournamentTeamPlayersEdit } from './HighLevelTournamentTeamPlayersEdit/HighLevelTournamentTeamPlayersEdit';
import { HighLevelTournamentTeamPlayers } from './HighLevelTournamentTeamPlayers/HighLevelTournamentTeamPlayers';
import { HighLevelTournamentEvents } from './HighLevelTournamentEvents/HighLevelTournamentEvents';

export const HighLevelTournamentsRouting = allProps => {
  return (
    <Switch>
      <Route
        exact
        key="upcoming"
        path={'/highLevelTournaments/upcoming'}
        render={props => <HighLevelTournamentsUpcoming {...props} user={allProps.user} />}
      />
      <Route
        exact
        key="archive"
        path={'/highLevelTournaments/archive'}
        render={props => <HighLevelTournamentsArchive {...props} user={allProps.user} />}
      />
      <Route
        exact
        path={'/highLevelTournaments/teams'}
        render={props => <HighLevelTournamentTeams {...props} user={allProps.user} />}
      />
      <Route
        exact
        path={'/highLevelTournaments/teams/edit'}
        render={props => <HighLevelTournamentTeamPlayersEdit {...props} user={allProps.user} />}
      />
      <Route
        exact
        path={'/highLevelTournaments/teams/players'}
        render={props => <HighLevelTournamentTeamPlayers {...props} user={allProps.user} />}
      />
      <Route
        exact
        path={'/highLevelTournaments/events'}
        render={props => <HighLevelTournamentEvents {...props} user={allProps.user} />}
      />
    </Switch>
  );
};

HighLevelTournamentsRouting.displayName = 'HighLevelTournamentsRouting';
