import * as React from 'react';
import { Component } from 'react';
import { Tabs } from "../Tabs/Tabs";
import { SchoolNotificationSummary } from "./SchoolNotificationSummary";
import { SchoolNotificationChannels } from "./SchoolNotificationChannels";
import { Notification } from "../../models/notification";

const SCHOOL_NOTIFICATION_TABS = ['Summary', 'Channels'];

interface Props {
  notification: Notification
}

interface State {
  activeTabIndex: number
}

export class SchoolNotificationFooter extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { activeTabIndex: 0 }
  }

  onTabClick = (event, index) => {
    event.preventDefault();
    if (index === this.state.activeTabIndex) { return }

    this.setState({activeTabIndex: index})
  };

  renderActiveTab(): React.ReactNode {
    const {activeTabIndex} = this.state;
    switch (activeTabIndex) {
      case 0:
        return (<SchoolNotificationSummary notification={this.props.notification}/>);
      case 1:
        return (<SchoolNotificationChannels notification={this.props.notification}/>);
      default:
        console.error('SchoolNotificationFooter.tsx: Undefined tab has been selected');
        return null;
    }
  }

  render() {
    const {activeTabIndex} = this.state;

    return (
      <div className='mMarginTop'>
        <Tabs
          currentTabIndex = { activeTabIndex }
          onTabClick = { this.onTabClick }
          tabs = { SCHOOL_NOTIFICATION_TABS }
        />
        {this.renderActiveTab()}
      </div>
    );
  }

}

