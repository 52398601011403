import * as React from 'react';
import { Component } from 'react';
import { Autocomplete } from '../Autocomplete/Autocomplete';
import { getName, searchFunctionHousesNinHouseIds } from 'Src/helpers/autocomplete/autocomplete';
import { AppUser } from 'Src/views/App/App';
import { ModalDialog } from '../ModalDialog/ModalDialog';

interface Props {
  user: AppUser;
  onSubmitHousesClick: (houses) => void;
  onCancelClick: () => void;
  isOpen: boolean;
}

interface State {
  selectedHouses: any[];
}

export class HouseAssignModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { selectedHouses: [] };
  }

  getHouses = text => {
    const user = this.props.user;
    const selectedHouseIds = this.state.selectedHouses.map(house => house.id);

    return searchFunctionHousesNinHouseIds(user, text, selectedHouseIds);
  };

  onHouseSelected = (house): void => {
    const { selectedHouses } = this.state;
    const houses = selectedHouses.slice();
    const houseIndex = houses.findIndex(h => h.id === house.id);
    if (houseIndex === -1) {
      houses.push(house);
    }

    this.setState({ selectedHouses: houses });
  };

  onRemoveHouseClick = (house): void => {
    const { selectedHouses } = this.state;
    const houses = selectedHouses.slice();
    const houseIndex = houses.findIndex(h => h.id === house.id);
    if (houseIndex !== -1) {
      houses.splice(houseIndex, 1);
    }

    this.setState({ selectedHouses: houses });
  };

  onHousesSubmit = (): void => {
    this.props.onSubmitHousesClick(this.state.selectedHouses);
  };

  render() {
    return (
      <ModalDialog
        title="Assign house"
        isOpen={this.props.isOpen}
        positiveButtonTitle="Save"
        negativeButtonTitle="Cancel"
        onPositiveButtonClick={this.onHousesSubmit}
        onNegativeButtonClick={this.props.onCancelClick}
      >
        <div>
          {this.state.selectedHouses.map(house => (
            <span
              key={`house_key_${house.id}`}
              className="badge badge-primary p-1 mr-1 mb-1 font-weight-normal"
              style={{ fontSize: '14px' }}
            >
              {house.name}
              <span
                key={`autocomplete_result_key_${house.id}`}
                aria-hidden="true"
                className="ml-2"
                style={{ cursor: 'pointer' }}
                onClick={() => this.onRemoveHouseClick(house)}
              >
                &times;
              </span>
            </span>
          ))}
        </div>
        <Autocomplete
          customClass="w-100"
          onSelect={this.onHouseSelected}
          getElementTitle={getName}
          searchFunction={this.getHouses}
        />
      </ModalDialog>
    );
  }
}
