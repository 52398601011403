import { Role } from 'Src/models/roles';
import { Permission } from 'Src/models/permission';
import { KIND, SUBSCRIPTION_PLAN } from 'Src/consts/school';
import { AppUser } from 'Src/views/App/App';
import * as propz from 'propz';
import { ROLE } from '../../consts/user';

export function getUserPermissions(availableRoles: Role[]): Permission[] {
  const permissions = [];

  let isAlreadyHaveParentPermission = false;
  let isAlreadyHaveStudentPermission = false;

  availableRoles.forEach(role => {
    role.permissions.forEach(permission => {
      // Always add all permissions besides PARENT and STUDENT permissions.
      // Add parent and student permissions only at once.
      switch (true) {
        case permission.preset === 'PARENT' && !isAlreadyHaveParentPermission:
          permissions.push(permission);
          isAlreadyHaveParentPermission = true;
          break;
        case permission.preset === 'STUDENT' && !isAlreadyHaveStudentPermission:
          permissions.push(permission);
          isAlreadyHaveStudentPermission = true;
          break;
        case permission.preset === 'PARENT' && isAlreadyHaveParentPermission:
          break;
        case permission.preset === 'STUDENT' && isAlreadyHaveStudentPermission:
          break;
        default:
          permissions.push(permission);
      }
    });
  });

  return permissions;
}

export function getUserActiveSchoolSubscriptionPlan(user: AppUser) {
  const subscriptionPlan = propz.get(user, ['activeSchool', 'subscriptionPlan'], SUBSCRIPTION_PLAN.FREE_COMMUNICATION);
  return subscriptionPlan;
}

export function isUserActiveSchoolSubscriptionPlanFree(user: AppUser) {
  const subscriptionPlan = getUserActiveSchoolSubscriptionPlan(user);
  return subscriptionPlan === SUBSCRIPTION_PLAN.FREE_COMMUNICATION;
}

export function isUserActiveSchoolSubscriptionPlanFull(user: AppUser) {
  const subscriptionPlan = getUserActiveSchoolSubscriptionPlan(user);
  return subscriptionPlan === SUBSCRIPTION_PLAN.FULL;
}

export function getUserActiveRole(user: AppUser) {
  const role = propz.get(user, ['activeRole']);
  return role;
}

export function isUserParent(user: AppUser): boolean {
  const role = propz.get(user, ['activeRole']);
  return role === ROLE.PARENT;
}

export function isUserStudent(user: AppUser): boolean {
  const role = propz.get(user, ['activeRole']);
  return role === ROLE.STUDENT;
}

export function isUserAdmin(user: AppUser): boolean {
  const role = propz.get(user, ['activeRole']);
  return role === ROLE.ADMIN;
}

export function isUserManager(user: AppUser): boolean {
  const role = propz.get(user, ['activeRole']);
  return role === ROLE.MANAGER;
}

export function isUserTeacher(user: AppUser): boolean {
  const role = propz.get(user, ['activeRole']);
  return role === ROLE.TEACHER;
}

export function isUserCoach(user: AppUser): boolean {
  const role = propz.get(user, ['activeRole']);
  return role === ROLE.COACH;
}

export function isUserSchoolWorker(user: AppUser): boolean {
  return isUserAdmin(user) || isUserManager(user) || isUserTeacher(user) || isUserCoach(user);
}

export function isUserActiveSchoolKindSU(user: AppUser): boolean {
  const { activeSchool } = user;
  const kind = activeSchool.kind;
  return kind === KIND.SCHOOL_UNION;
}

export function isUserActiveSchoolKindSchool(user: AppUser): boolean {
  const { activeSchool } = user;
  const kind = activeSchool.kind;
  return kind === KIND.SCHOOL;
}

export function isUserAdminSU(user: AppUser): boolean {
  return isUserAdmin(user) && isUserActiveSchoolKindSU(user);
}
