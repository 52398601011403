import * as React from 'react';
import * as BPromise from 'bluebird';
import { Photo } from '../../../../../../../models/photo';
import './FullScreenPhoto.scss';
import { AppUser } from 'Src/views/App/App';
import { AccessPresetRadioButtons } from './AccessPresetRadioButtons';
import { Loader } from '../../../../../../../components/Loader/Loader';
import { ACCESS_PRESET_TYPE } from '../../../../../../../types/album';
import { EmojiIcon } from 'Src/views/GenericView/AdminView/AdminConsole/SchoolGallery/EmojiIcon/EmojiIcon';
import { PhotoEdit } from 'Src/views/GenericView/AdminView/AdminConsole/SchoolGallery/PhotoEdit/PhotoEdit';
import { SmileIcon } from 'Src/components/SvgIcons/SmileIcon';
import { RotateIconLeft } from 'Src/components/SvgIcons/RotateIconLeft';
import { RotateIconRight } from 'Src/components/SvgIcons/RotateIconRight';
import { TrashIcon } from 'Src/components/SvgIcons/TrashIcon';
import { rotateImage } from 'Src/helpers/gallery/gallery';
import { getImageDirectUrl, uploadSchoolPhoto } from 'Src/helpers/service/admin/schoolGallery';
import { dataURLtoBlob } from 'Src/helpers/gallery/gallery';
import { getRandomString } from 'Src/helpers/common/common';
import { ROLE } from 'Src/consts/user';

const ANGLE = {
  LEFT: -Math.PI / 2,
  RIGHT: Math.PI / 2
};

interface Props {
  user: AppUser;
  onCloseClick: () => void;
  onNextPhotoClick: () => void;
  onPreviousPhotoClick: () => void;
  photo: Photo;
  isShowEditPhotoPanel: boolean;
  isShowArrowButtons: boolean;
  isUpdateAccessPreset: boolean;
  onAccessPresetClick: (accessPreset: ACCESS_PRESET_TYPE) => void;
  isShowAccessPresetRadioButtons: boolean;
  onChangeSchoolPhotoInfoClick?: (
    id: string,
    photoInfo: {
      name?: string;
      description?: string;
      picUrl?: string;
      accessPreset?: string;
    }
  ) => void;
  onDeletePhotoClick?: (id: string) => any;
  albumId?: string;
}
interface State {
  windowWidth: number;
  windowHeight: number;
  isAddEmojiIconMode: boolean;
  isLoading: boolean;
}

export class FullScreenPhoto extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      isAddEmojiIconMode: false,
      isLoading: false
    };
  }

  onPhotoClick = e => {
    e.stopPropagation();
  };

  renderPhoto(photoStyle, arrowStyle) {
    const { isLoading } = this.state;
    const { isShowArrowButtons, onPreviousPhotoClick, onNextPhotoClick } = this.props;

    if (isShowArrowButtons) {
      return (
        <div className="eFullScreenPhotoPhoto" style={photoStyle}>
          <div className="eFullScreenPhotoArrowLeft" onClick={onPreviousPhotoClick} style={arrowStyle} />
          <div className="eFullScreenPhotoArrowRight" onClick={onNextPhotoClick} style={arrowStyle} />
          {isLoading && <Loader />}
        </div>
      );
    } else {
      return <div className="eFullScreenPhotoPhoto" style={photoStyle} />;
    }
  }

  renderEditPhotoPanel() {
    const { isShowEditPhotoPanel, photo, albumId, onChangeSchoolPhotoInfoClick } = this.props;
    const { windowHeight } = this.state;
    const { id } = photo;

    const height = Math.floor(windowHeight * 0.8);

    const sideContainerStyle = {
      height: height - 10
    };

    if (isShowEditPhotoPanel) {
      return (
        <div className="eFullScreenPhotoInfo" style={sideContainerStyle}>
          <div className="eEditPhotoPanel">
            <span
              onClick={() =>
                this.setState({
                  isAddEmojiIconMode: true,
                  isLoading: true
                })
              }
              className="bTooltip"
              id="addIcon_button"
              data-description="Add anonymous icon"
            >
              <SmileIcon />
            </span>
            <span
              onClick={e => this.rotatePhoto(ANGLE.LEFT)}
              className="bTooltip"
              id="albumLeftRotate_button"
              data-description="Rotate photo to left"
            >
              <RotateIconLeft />
            </span>
            <span
              onClick={e => this.rotatePhoto(ANGLE.RIGHT)}
              className="bTooltip"
              id="albumLeftRotate_button"
              data-description="Rotate photo to right"
            >
              <RotateIconRight />
            </span>
            <span
              onClick={e => this.onDeletePhotoClick(e)}
              className="bTooltip"
              id="albumCover_button"
              data-description="Delete photo"
            >
              <TrashIcon />
            </span>
          </div>

          <PhotoEdit key={id} albumId={albumId} photo={photo} onSubmit={onChangeSchoolPhotoInfoClick} />
        </div>
      );
    }
  }

  renderAccessPresetButtons() {
    const { windowHeight } = this.state;
    const height = Math.floor(windowHeight * 0.8);

    const sideContainerStyle = {
      height: height - 10 // 10px - it is a left padding
    };

    const { isShowAccessPresetRadioButtons, photo, onAccessPresetClick } = this.props;

    return isShowAccessPresetRadioButtons ? (
      <div className="eFullScreenPhotoSideContainer" style={sideContainerStyle}>
        <AccessPresetRadioButtons accessPreset={photo.accessPreset} onAccessPresetClick={onAccessPresetClick} />
      </div>
    ) : null;
  }

  onSaveClick(file) {
    const { onChangeSchoolPhotoInfoClick, photo, user } = this.props;

    this.setState({
      isLoading: true
    });

    uploadSchoolPhoto(user, file)
      .then(picUrl => {
        return onChangeSchoolPhotoInfoClick(photo.id, { picUrl });
      })
      .then(() => {
        this.setState({
          isLoading: false,
          isAddEmojiIconMode: false
        });
      });
  }

  onDeletePhotoClick(e) {
    const { onDeletePhotoClick, photo } = this.props;
    const { id } = photo;

    this.setState({
      isLoading: true
    });

    onDeletePhotoClick(id);
  }

  rotatePhoto(angle) {
    const { user, photo, onChangeSchoolPhotoInfoClick } = this.props;
    const { picUrl: pictureUrl, id } = photo;

    this.setState({
      isLoading: true
    });

    return getImageDirectUrl(pictureUrl)
      .then(data => {
        const imageNameParts = data.data.url.split('.');
        const imageFormat = imageNameParts[imageNameParts.length - 1];

        const picUrl = `${data.data.url}?t=${Date.now() + getRandomString()}`;

        return rotateImage(picUrl, angle, imageFormat);
      })
      .then(data => {
        const file = dataURLtoBlob(data);

        return uploadSchoolPhoto(user, file);
      })
      .then(picUrl => {
        return onChangeSchoolPhotoInfoClick(id, { picUrl });
      })
      .then(() =>
        this.setState({
          isLoading: false
        })
      );
  }

  render() {
    const { photo, onCloseClick, isUpdateAccessPreset, user } = this.props;
    const { windowWidth, windowHeight, isAddEmojiIconMode, isLoading } = this.state;
    const { picUrl } = photo;
    const { activeSchoolId, activeRole } = user;

    const isUserParentOrStudent = activeRole === ROLE.STUDENT || activeRole === ROLE.PARENT;

    const width = Math.floor(windowWidth * 0.8);
    const height = Math.floor(windowHeight * 0.8);

    const photoContainerStyle = {
      width: width,
      height: height
    };

    const photoStyle = {
      height: height,
      backgroundImage: `url(${picUrl})`
    };

    const arrowStyle = {
      height: height
    };

    if (isAddEmojiIconMode) {
      return (
        <div className="bFullScreenPhotoOverlay">
          <EmojiIcon
            onSaveClick={file => this.onSaveClick(file)}
            onCancelClick={() =>
              this.setState({
                isAddEmojiIconMode: false,
                isLoading: false
              })
            }
            getPhotoUrl={() => BPromise.resolve(photo.picUrl)}
            imgContainerWidth={width - 280}
            imgContainerHeight={height}
          />
        </div>
      );
    } else {
      return (
        <div className="bFullScreenPhoto" onClick={onCloseClick}>
          <div className="eFullScreenPhotoCloseCross" onClick={onCloseClick} />
          <div className="eFullScreenPhotoPhotoContainer" onClick={this.onPhotoClick} style={photoContainerStyle}>
            {isUpdateAccessPreset || isLoading ? (
              <div className="eFullScreenPhotoSideContainer">
                <Loader />
              </div>
            ) : (
              <>
                {this.renderPhoto(photoStyle, arrowStyle)}
                {!isUserParentOrStudent && (
                  <div className="eFullScreenPhotoEditPanel">
                    {this.renderEditPhotoPanel()}
                    {this.renderAccessPresetButtons()}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      );
    }
  }
}
