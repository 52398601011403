import * as React from 'react';
import { FunctionComponent } from 'react';
import { Field, Form, Formik } from 'formik';
import {
  getName,
  searchFunctionFormsNinFormIds,
  searchFunctionHousesNinHouseIds,
  searchFunctionSportsNinSportIds
} from 'Src/helpers/autocomplete/autocomplete';
import { AppUser } from 'Src/views/App/App';
import { Autocomplete } from '../Autocomplete/Autocomplete';
import { ROLE, ROLE_SERVER_TO_CLIENT_MAPPING, STAFF_LIST } from '../../consts/user';
import { Button } from '../Button/Button';
import * as propz from 'propz';
import { SchoolUser } from '../../models/schoolUser';
import * as Yup from 'yup';

interface Props {
  user: AppUser;
  onSubmit: (data) => void;
  onCancel: () => void;
  permission?: any;
  selectedItem: SchoolUser;
  sports: any[];
}

const RoleSchema = Yup.object().shape({
  preset: Yup.string().required('Required')
});

export const RoleForm: FunctionComponent<Props> = props => {
  const { user, selectedItem, permission, sports } = props;
  const initialValues = {
    id: propz.get(permission, ['id']),
    preset: propz.get(permission, ['preset'], ''),
    formIds: propz.get(permission, ['formIds'], []),
    forms: propz.get(permission, ['forms'], []),
    houseIds: propz.get(permission, ['houseIds'], []),
    houses: propz.get(permission, ['houses'], []),
    sports: sports,
    sportIds: sports.map(sport => sport.id) || []
  };

  const getForms = (text: string, formIds: string[]) => {
    return searchFunctionFormsNinFormIds(user, text, formIds);
  };

  const getHouse = (text: string, houseIds: string[]) => {
    return searchFunctionHousesNinHouseIds(user, text, houseIds);
  };

  const getSports = (text: string, sportIds) => {
    return searchFunctionSportsNinSportIds(user, text, sportIds);
  };

  function renderPreset() {
    let options = [];

    if (typeof permission === 'undefined') {
      options = STAFF_LIST.filter(preset =>
        selectedItem.permissions.every(permission => permission.preset !== preset)
      ).map((preset, index) => (
        <option key={`role_add_form_preset_${index}`} value={preset}>
          {ROLE_SERVER_TO_CLIENT_MAPPING[preset]}
        </option>
      ));

      options.unshift(
        <option value={''} key={`role_add_form_preset_empty`}>
          Select preset
        </option>
      );
    } else {
      options.push(
        <option value={permission.preset} key={`role_add_form_preset_fill`}>
          {ROLE_SERVER_TO_CLIENT_MAPPING[permission.preset]}
        </option>
      );
    }

    return options;
  }

  return (
    <Formik
      onSubmit={props.onSubmit}
      initialValues={initialValues}
      validationSchema={RoleSchema}
      render={({ touched, errors, values, setFieldValue }) => (
        <Form>
          <label>Role</label>
          <Field component="select" name="preset" className="form-control mb-3">
            {renderPreset()}
          </Field>
          {touched.preset && errors.preset ? <div className="alert alert-danger">{errors.preset}</div> : null}
          <label>Form</label>
          <div>
            {values.forms.map((form, index) => (
              // FORMS
              <span
                key={`form_tag_${form.id}`}
                className="badge badge-primary p-1 mr-1 mb-1 font-weight-normal"
                style={{ fontSize: '14px' }}
              >
                {form.name}
                <span
                  aria-hidden="true"
                  className="ml-2"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    const ids = values.formIds;
                    const forms = values.forms;

                    ids.splice(index, 1);
                    forms.splice(index, 1);
                    setFieldValue('formIds', ids);
                    setFieldValue('forms', forms);
                  }}
                >
                  &times;
                </span>
              </span>
            ))}
          </div>
          <Field
            name="form"
            render={({ field }) => {
              const formIds = values.formIds || [];

              return (
                <Autocomplete
                  searchFunction={text => getForms(text, formIds)}
                  getElementTitle={getName}
                  customClass="mFullWidth mb-3"
                  onSelect={form => {
                    const forms = values.forms;

                    if (formIds.findIndex(id => id === form.id)) {
                      formIds.push(form.id);
                      forms.push(form);
                      setFieldValue('formIds', formIds);
                      setFieldValue('forms', forms);
                    }
                  }}
                />
              );
            }}
          />

          <label>House</label>
          <div>
            {values.houses.map((house, index) => (
              // HOUSES
              <span
                key={`house_tag_${house.id}`}
                className="badge badge-primary p-1 mr-1 mb-1 font-weight-normal"
                style={{ fontSize: '14px' }}
              >
                {house.name}
                <span
                  aria-hidden="true"
                  className="ml-2"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    const ids = values.houseIds;
                    const houses = values.houses;

                    ids.splice(index, 1);
                    houses.splice(index, 1);
                    setFieldValue('houseIds', ids);
                    setFieldValue('houses', houses);
                  }}
                >
                  &times;
                </span>
              </span>
            ))}
          </div>
          <Field
            name="house"
            render={({ field }) => {
              const houseIds = values.houseIds;
              const houses = values.houses;

              return (
                <Autocomplete
                  searchFunction={text => getHouse(text, houseIds)}
                  getElementTitle={getName}
                  customClass="mFullWidth mb-3"
                  onSelect={house => {
                    if (houseIds.findIndex(id => id === house.id) === -1) {
                      houseIds.push(house.id);
                      houses.push(house);
                      setFieldValue('houseIds', houseIds);
                      setFieldValue('houses', houses);
                    }
                  }}
                />
              );
            }}
          />
          {(values.preset === ROLE.COACH || values.preset === ROLE.TEACHER) &&(
            <>
              <label>Sport</label>
              <div>
                {values.sports.map((sport, index) => (
                  // SPORTS TO COACHES
                  <span
                    key={`sport_tag_${sport.id}`}
                    className="badge badge-primary p-1 mr-1 mb-1 font-weight-normal"
                    style={{ fontSize: '14px' }}
                  >
                    {sport.name}
                    <span
                      aria-hidden="true"
                      className="ml-2"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        const ids = values.sportIds;
                        const sports = values.sports;

                        ids.splice(index, 1);
                        sports.splice(index, 1);
                        setFieldValue('sportIds', ids);
                        setFieldValue('sports', sports);
                      }}
                    >
                      &times;
                    </span>
                  </span>
                ))}
              </div>
              <Field
                name="sports"
                render={({ field }) => {
                  const sportIds = values.sportIds;
                  const sports = values.sports;

                  return (
                    <Autocomplete
                      searchFunction={text => getSports(text, sportIds)}
                      getElementTitle={getName}
                      customClass="mFullWidth mb-3"
                      onSelect={sport => {
                        if (sportIds.findIndex(id => id === sport.id) === -1) {
                          sportIds.push(sport.id);
                          sports.push(sport);
                          setFieldValue('sportIds', sportIds);
                          setFieldValue('sports', sports);
                        }
                      }}
                    />
                  );
                }}
              />
            </>
          )}
          <Button onClick={props.onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
          <button type="submit" className="mt-3 mb-3 btn btn-primary">
            Submit
          </button>
        </Form>
      )}
    />
  );
};

RoleForm.displayName = 'RoleForm';
