import axios from 'axios';
import { AppUser } from 'Src/views/App/App';
import { DEFAULT_FILTER } from './user';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from 'Src/consts/table';
import { Count } from '../../../models/count';
import { SchoolEventTeamPlayer } from '../../../models/eventTeamPlayer';

const DEFAULT_SCHOOL_TOURNAMENT_EVENTS_ORDER = 'startTime ASC';

export function getSchoolEvents(user: AppUser, filter) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order || DEFAULT_SCHOOL_TOURNAMENT_EVENTS_ORDER
      }
    }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/events`, config).then(response => {
    return response.data;
  });
}

export function getAllSchoolEvents(user: AppUser, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP,
          order: DEFAULT_SCHOOL_TOURNAMENT_EVENTS_ORDER
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP,
          order: DEFAULT_SCHOOL_TOURNAMENT_EVENTS_ORDER
        }
      }
    };
  }

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/events`, config).then(response => {
    return response.data;
  });
}

export function getSchoolEventsCount(user: AppUser, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where
        }
      }
    };
  } else {
    config = {
      headers: { usid: key }
    };
  }

  return axios.get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/events/count`, config).then(response => {
    return response.data;
  });
}

export function updateSchoolEvent(appUser: AppUser, eventId: string, data: any, notificationMode?) {
  const { roleSessionKey: key, activeSchoolId } = appUser;

  const config = {
    headers: {
      usid: key,
      ...(notificationMode && { 'notification-mode': notificationMode })
    },
    ...(notificationMode ? { isDataOnly: false } : {})
  };

  return axios.put(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}`, data, config).then(response => {
    return notificationMode ? response : response.data;
  });
}

export function updateGroupSchoolClubEvents(appUser: AppUser, eventId: string, data: any, notificationMode?) {
  const { roleSessionKey: key, activeSchoolId } = appUser;

  const config = {
    headers: {
      usid: key,
      ...(notificationMode && { 'notification-mode': notificationMode })
    },
    ...(notificationMode ? { isDataOnly: false } : {})
  };

  return axios
    .put(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/rgroup`, data, config)
    .then(response => {
      return notificationMode ? response : response.data;
    });
}

export function getSchoolEventDetails(user: AppUser, eventId) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/details`, config).then(response => {
    return response.data;
  });
}

export function updateSchoolEventDetails(user: AppUser, eventId: string, data) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .put(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/details`, data, config)
    .then(response => {
      return response.data;
    });
}

export function updateGroupSchoolEventDetails(user: AppUser, eventId: string, data) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .put(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/groupDetails`, data, config)
    .then(response => {
      return response.data;
    });
}

export function getSchoolEventTeams(user: AppUser, eventId) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/teams`, config).then(response => {
    return response.data;
  });
}

export function cancelEvent(user: AppUser, eventId, notificationMode) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: {
      usid: key,
      'notification-mode': notificationMode
    },
    isDataOnly: false
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/cancel`, {}, config)
    .then(response => {
      return response; //  since we need headers
    });
}

export function reasonForCancellation(user: AppUser, eventId, reasonForCancellation: string) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: {
      usid: key
    }
  };

  return axios
    .post(
      `${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/reasonForCancellation`,
      { reasonForCancellation },
      config
    )
    .then(response => {
      return response.data;
    });
}

export function getActionDescriptor(user: AppUser, actiondescriptorId: string) {
  const { roleSessionKey: key } = user;
  const config = {
    headers: { usid: key }
  };

  return axios.get(`${window.apiBase}/i/actiondescriptors/${actiondescriptorId}`, config).then(response => {
    return response.data;
  });
}

export function updateActionDescriptor(user: AppUser, actiondescriptorId: string, data) {
  const { roleSessionKey: key } = user;
  const config = {
    headers: { usid: key }
  };

  return axios.put(`${window.apiBase}/i/actiondescriptors/${actiondescriptorId}`, data, config).then(response => {
    return response.data;
  });
}

export function getSchoolEventCoaches(user: AppUser, eventId: string, filter) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order
      }
    }
  };

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/users/coaches`, config)
    .then(response => {
      return response.data;
    });
}

export function getSchoolEventStaff(user: AppUser, eventId: string, filter) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order
      }
    }
  };

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/users/staffs`, config)
    .then(response => {
      return response.data;
    });
}

export function createEventPerformance(user: AppUser, eventId, data) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: {
      usid: key
    }
  };

  return axios
    .post(
      `${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/results/individuals/performance`,
      data,
      config
    )
    .then(response => {
      return response; //  since we need headers
    });
}

export function updateEventPerformance(user: AppUser, eventId, performanceId, data) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: {
      usid: key
    }
  };

  return axios
    .put(
      `${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/results/individuals/performance/${performanceId}`,
      data,
      config
    )
    .then(response => {
      return response; //  since we need headers
    });
}

export function onDeleteJob(user: AppUser, eventId: string, eventTaskId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .delete(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/tasks/${eventTaskId}`, config)
    .then(response => {
      return response.data;
    });
}

export function createEventAttendance(user: AppUser, eventId, data) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: {
      usid: key
    }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/results/attendance`, data, config)
    .then(response => {
      return response.data;
    });
}

export function updateEventAttendance(user: AppUser, eventId, attendanceId, data) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: {
      usid: key
    }
  };

  return axios
    .put(
      `${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/results/attendance/${attendanceId}`,
      data,
      config
    )
    .then(response => {
      return response.data;
    });
}

export function getAllSchoolEventIndividuals(user: AppUser, eventId: string, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where,
            schoolId: activeSchoolId
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            schoolId: activeSchoolId
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/individuals`, config)
    .then(response => {
      return response.data;
    });
}

export function getSchoolEventPlayersCount(user: AppUser, eventId: string, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where,
            schoolId: activeSchoolId
          }
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            schoolId: activeSchoolId
          }
        }
      }
    };
  }

  return axios
    .get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/individuals/count`, config)
    .then(response => {
      return response.data;
    });
}

export function getSchoolEventMatchReport(user: AppUser, eventId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/report`, config).then(response => {
    return response.data;
  });
}

export function updateSchoolEventMatchReport(appUser: AppUser, eventId: string, data: any) {
  const { roleSessionKey: key, activeSchoolId } = appUser;
  const config = { headers: { usid: key } };

  return axios
    .put(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/report`, data, config)
    .then(response => {
      return response.data;
    });
}

export function deleteEvent(user: AppUser, eventId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.delete(`${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}`, config).then(response => {
    return response.data;
  });
}

export function createEventDiscipline(user: AppUser, eventId, data) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: {
      usid: key
    }
  };

  return axios
    .post(
      `${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/results/individuals/discipline`,
      data,
      config
    )
    .then(response => {
      return response; //  since we need headers
    });
}

export function updateEventDiscipline(user: AppUser, eventId, disciplineId, data) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: {
      usid: key
    }
  };

  return axios
    .put(
      `${window.apiBase}/i/schools/${activeSchoolId}/events/${eventId}/results/individuals/discipline/${disciplineId}`,
      data,
      config
    )
    .then(response => {
      return response; //  since we need headers
    });
}
