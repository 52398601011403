import axios from 'axios';
import { AppUser } from 'Src/views/App/App';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../consts/table';
import { DEFAULT_FILTER } from '../admin/user';
import { Count } from '../../../models/count';

/**PARTICIPATING STUDENTS TABLE */
export function getFixtureParticipations(user: AppUser, filter = DEFAULT_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter
    }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/events/studentsAffected`, config).then(response => {
    return response.data;
  });
}

export function getAllFixtureParticipations(user: AppUser, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/events/studentsAffected`, config).then(response => {
    return response.data;
  });
}

export function getFixtureParticipationsCount(user: AppUser, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where
        }
      }
    };
  } else {
    config = {
      headers: { usid: key }
    };
  }

  return axios
    .get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/events/studentsAffected/count`, config)
    .then(response => {
      return response.data;
    });
}
