import { AppUser } from 'Src/views/App/App';
import axios from 'axios';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../consts/table';
import { Count } from '../../../models/count';

const DEFAULT_INVITE_OUTBOX_FILTER: any = {
  where: {
    $or: [
      {
        $and: [
          { kind: { $in: ['EventSchoolInvite'] } },
          { invitedSchoolId: { $exists: true } },
          { invitedSchoolId: { $nin: ['58611f5d4387fc25d725ddad'] } } //  this is hardcode "TBD"
        ]
      },
      { kind: 'TournamentSchoolInvite' }
    ]
  },
  order: 'startTime DESC'
};

const DEFAULT_INVITE_INBOX_FILTER: any = {
  where: {
    $or: [
      { $and: [{ kind: { $in: ['EventSchoolInvite'] } }, { invitedSchoolId: { $exists: true } }] },
      { kind: 'TournamentSchoolInvite' }
    ]
  },
  order: 'startTime DESC'
};

const DEFAULT_INVITE_ARCHIVE_FILTER: any = {
  where: {
    $or: [
      { $and: [{ kind: { $in: ['EventSchoolInvite'] } }, { invitedSchoolId: { $exists: true } }] },
      { kind: 'TournamentSchoolInvite' }
    ]
  },
  order: 'updatedAt DESC'
};

export function getAdminSchoolsInboxInvites(user: AppUser, filter = DEFAULT_INVITE_INBOX_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...DEFAULT_INVITE_INBOX_FILTER.where,
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order || DEFAULT_INVITE_INBOX_FILTER.order
      }
    }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/invites/inbox`, config).then(response => {
    return response.data;
  });
}

export function getAdminSchoolsAllInboxInvites(user: AppUser, where = DEFAULT_INVITE_INBOX_FILTER.where) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_INVITE_INBOX_FILTER.where,
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP,
          order: DEFAULT_INVITE_INBOX_FILTER.order
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP,
          order: DEFAULT_INVITE_INBOX_FILTER.order
        }
      }
    };
  }

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/invites/inbox`, config).then(response => {
    return response.data;
  });
}

export function getAdminSchoolsInboxInvitesCount(user: AppUser, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_INVITE_INBOX_FILTER.where,
            ...where
          }
        }
      }
    };
  } else {
    config = {
      headers: { usid: key }
    };
  }

  return axios
    .get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/invites/inbox/count`, config)
    .then(response => {
      return response.data;
    });
}

export function getAdminSchoolOutboxInvites(user: AppUser, filter = DEFAULT_INVITE_OUTBOX_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...DEFAULT_INVITE_OUTBOX_FILTER.where,
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order || DEFAULT_INVITE_OUTBOX_FILTER.order
      }
    }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/invites/outbox`, config).then(response => {
    return response.data;
  });
}

export function getAdminSchoolsAllOutboxInvites(user: AppUser, where = DEFAULT_INVITE_OUTBOX_FILTER.where) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_INVITE_OUTBOX_FILTER.where,
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP,
          order: DEFAULT_INVITE_OUTBOX_FILTER.order
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP,
          order: DEFAULT_INVITE_OUTBOX_FILTER.order
        }
      }
    };
  }

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/invites/outbox`, config).then(response => {
    return response.data;
  });
}

export function getAdminSchoolsOutboxInvitesCount(user: AppUser, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_INVITE_OUTBOX_FILTER.where,
            ...where
          }
        }
      }
    };
  } else {
    config = {
      headers: { usid: key }
    };
  }

  return axios
    .get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/invites/outbox/count`, config)
    .then(response => {
      return response.data;
    });
}

export function getAdminSchoolsArchiveInvites(user: AppUser, filter = DEFAULT_INVITE_ARCHIVE_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...DEFAULT_INVITE_ARCHIVE_FILTER.where,
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order || DEFAULT_INVITE_ARCHIVE_FILTER.order
      }
    }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/invites/archive`, config).then(response => {
    return response.data;
  });
}

export function getAdminSchoolsAllArchiveInvites(user: AppUser, where = DEFAULT_INVITE_ARCHIVE_FILTER.where) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_INVITE_ARCHIVE_FILTER.where,
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP,
          order: DEFAULT_INVITE_ARCHIVE_FILTER.order
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP,
          order: DEFAULT_INVITE_ARCHIVE_FILTER.order
        }
      }
    };
  }

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/invites/archive`, config).then(response => {
    return response.data;
  });
}

export function getAdminSchoolsArchiveInvitesCount(user: AppUser, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_INVITE_ARCHIVE_FILTER.where,
            ...where
          }
        }
      }
    };
  } else {
    config = {
      headers: { usid: key }
    };
  }

  return axios
    .get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/invites/archive/count`, config)
    .then(response => {
      return response.data;
    });
}

export function declineAdminSchoolsInvites(user: AppUser, inviteId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/invites/${inviteId}/reject`, {}, config)
    .then(response => {
      return response.data;
    });
}

export function cancelAdminSchoolsInvites(user: AppUser, inviteId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/invites/${inviteId}/cancel`, {}, config)
    .then(response => {
      return response.data;
    });
}

export function sendCommentsAdminSchoolsInvites(user: AppUser, inviteId: string, data) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/invites/${inviteId}/comments`, data, config)
    .then(response => {
      return response.data;
    });
}
