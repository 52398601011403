import * as React from 'react';
import * as propz from 'propz';
import { Tournament } from 'Src/models/tournament';
import { Announce } from '../Announce/Announce';

interface Props {
  tournament: Tournament;
}

export const AdditionalInfo = (props: Props) => {
  const { tournament } = props;

  const announcements = propz.get(tournament, ['announcements'], []);
  const isAnnouncementsExist = announcements.length > 0;

  return (
    <div className="mt-4">
      {isAnnouncementsExist &&
        announcements.map(item => {
          const { _id: itemKey } = item;

          return <Announce item={item} key={itemKey} />;
        })}
    </div>
  );
};

AdditionalInfo.displayName = 'AdditionalInfo';
