import * as React from 'react';
import { Component } from 'react';
import { parse } from 'query-string';
import * as BPromise from 'bluebird';
import {
  ColumnDefinition,
  getFilters2,
  getOrder,
  getSearchFilter,
  getSearchOrder,
  getServerFieldSectionWhere,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE, LIMIT } from 'Src/consts/table';
import {
  deleteForm,
  getAllForms,
  getForms,
  getFormCount,
  updateForm,
  createForm
} from 'Src/helpers/service/admin/forms';
import { Loader } from 'Src/components/Loader/Loader';
import { AppUser } from 'Src/views/App/App';
import './Forms.css';
import { SchoolForm } from 'Src/models/form';
import { SchoolFormForm } from 'Src/components/SchoolFormForm/SchoolFormForm';
import { GenericSummary } from 'Src/components/GenericSummary/GenericSummary';
import { Button } from 'Src/components/Button/Button';
import { FormSummary } from 'Src/components/FormSummary/FormSummary';
import { FORM_TABS } from 'Src/consts/form';
import { History, Location } from 'history';
import { getSelectOptionForAge } from 'Src/helpers/table/select';
import { Grid2 } from 'Src/components/Grid/Grid2';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { ResizablePanel } from 'Src/components/ResizablePanel/ResizablePanel';
import * as propz from 'propz';
import { AGE_GROUPS } from 'Src/consts/school';
import { getPagesCount } from 'Src/helpers/table/table';
import { getSchoolStudentsCount } from 'Src/helpers/service/admin/students';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Name',
    field: 'name',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['name']
  },
  {
    text: 'Age Group',
    field: 'ageGroup',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: ['ageGroup']
  }
];

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

interface State {
  items: SchoolForm[];
  currentPage: number;
  selectedItems: SchoolForm[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  totalWithFilters: number;
  total: number;
  basePath: string;
  forms: SchoolForm[];
  deleteFormConfirmationModalText: string;
  deleteFormProhibitionModalText: string;
  formsWithoutStudents: SchoolForm[];

  isCreateOrEditFormModalOpen: boolean;
  isDeleteFormConfirmationModalOpen: boolean;
  isDeleteFormProhibitionModalOpen: boolean;
}

export class Forms extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      totalWithFilters: 0,
      total: 0,
      basePath: '',
      forms: [],
      deleteFormConfirmationModalText: '',
      deleteFormProhibitionModalText: '',
      formsWithoutStudents: [],

      isCreateOrEditFormModalOpen: false,
      isDeleteFormConfirmationModalOpen: false,
      isDeleteFormProhibitionModalOpen: false
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setAdditionalItems().then(res => {
      this.setItems();
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  setAdditionalItems() {
    const { user } = this.props;
    const promises = [getAllForms(user)];
    return BPromise.all(promises).then(([forms]) => {
      this.setState({
        forms: forms
      });

      return true;
    });
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    // TODO_002 fix it
    const options = {
      forms: this.state.forms
    };
    const where = getServerFieldSectionWhere(filters, options);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const getFormsPromise = getForms(user, serverQueryFilter);
    const getFormCountPromise = getFormCount(user, where);
    const getFormsTotalCountPromise = getFormCount(user);

    return BPromise.all([getFormCountPromise, getFormsTotalCountPromise, getFormsPromise]).then(
      ([totalWithFiltersObj, totalObj, forms]) => {
        this.setState({
          currentPage: page,
          sortDirection: sortDirection,
          sortColumnsName: sortColumnsName,
          isShowFilter: isShowFilter,
          isDataFiltered: isDataFiltered,
          filters: filters,
          items: forms,
          totalWithFilters: totalWithFiltersObj.count,
          total: totalObj.count,
          basePath: history.location.pathname,
          isLoading: false
        });

        return true;
      }
    );
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedForm = items[index];

    const selectedFormIndex = selectedItems.findIndex(form => selectedForm.id === form.id);
    let selectedFormUpdated = [...selectedItems];

    if (selectedFormIndex !== -1) {
      selectedFormUpdated.splice(selectedFormIndex, 1);
    } else {
      selectedFormUpdated.push(selectedForm);
    }

    this.setState({
      selectedItems: selectedFormUpdated
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const formsFiltered = items.filter(form => {
      return selectedItems.every(selectedForm => selectedForm.id !== form.id);
    });

    const selectedForms = [...selectedItems, ...formsFiltered];

    this.setState({
      selectedItems: selectedForms,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters, forms } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const options = {
      forms: forms
    };

    const where = getServerFieldSectionWhere(filters, options);

    getAllForms(user, where).then(forms => {
      const formsFiltered = forms.filter(form => {
        return selectedItems.every(selectedForm => selectedForm.id !== form.id);
      });

      const selectedForms = [...selectedItems, ...formsFiltered];

      this.setState({
        selectedItems: selectedForms,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (filterField === 'ageGroup') {
      nextFilters.ageGroup = [];
    }

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: ''
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  onSaveButtonClick = (data): void => {
    const { user, history } = this.props;
    const { selectedItems, total } = this.state;

    this.setState({
      isLoading: true
    });

    const { name, ageGroup } = data;
    const activeSchool = user.activeSchool;
    const ageGroupsNaming = activeSchool.ageGroupsNaming;

    const ageGroups = propz.get(AGE_GROUPS, [ageGroupsNaming]);

    const age = ageGroups.findIndex(group => group === ageGroup);
    const formToTransfer = {
      age: age,
      name: name.trim()
    };

    switch (selectedItems.length) {
      case 0: {
        createForm(this.props.user, formToTransfer).then((form: SchoolForm) => {
          const lastPage = getPagesCount(total, LIMIT);

          history.push({
            pathname: this.state.basePath,
            search: `page=${lastPage}`
          });

          this.setState({
            isCreateOrEditFormModalOpen: false,
            selectedItems: [form]
          });

          this.setItems();
        });
        break;
      }
      case 1:
        const formId = selectedItems[0].id;
        updateForm(user, formId, formToTransfer).then((form: SchoolForm) => {
          this.setState({
            isCreateOrEditFormModalOpen: false,
            selectedItems: [form]
          });

          this.setItems();
        });
        break;
    }
  };

  onCancelButtonClick = (): void => {
    this.setState({
      isCreateOrEditFormModalOpen: false
    });
  };

  onTabClick = (event, index: number) => {
    event.preventDefault();

    //TODO_001 fix it
    this.setState({
      total: index
    });
  };

  onEditFormClick = event => {
    event.preventDefault();

    this.setState({
      isCreateOrEditFormModalOpen: true
    });
  };

  getSummaryTabs(): React.ReactNode {
    const { selectedItems, items, forms } = this.state;

    switch (true) {
      case selectedItems.length === 1:
        return <FormSummary form={selectedItems[0]} />;
    }
  }

  renderForm() {
    const { isCreateOrEditFormModalOpen, selectedItems } = this.state;
    const { user } = this.props;

    switch (selectedItems.length) {
      case 0:
        return (
          <SimpleModal isOpen={isCreateOrEditFormModalOpen} title={'Create form'}>
            <SchoolFormForm
              isOpen={isCreateOrEditFormModalOpen}
              onCloseClick={this.onCancelButtonClick}
              onFormCreated={this.onSaveButtonClick}
              user={user}
            />
          </SimpleModal>
        );
      case 1:
        return (
          <SimpleModal isOpen={isCreateOrEditFormModalOpen} title={'Edit form'}>
            <SchoolFormForm
              isOpen={isCreateOrEditFormModalOpen}
              onCloseClick={this.onCancelButtonClick}
              onFormCreated={this.onSaveButtonClick}
              form={selectedItems[0]}
              user={user}
            />
          </SimpleModal>
        );
    }
  }

  onCreateFormClick = () => {
    this.setState({
      isCreateOrEditFormModalOpen: true
    });
  };

  onRemoveFormClick = () => {
    const { user } = this.props;
    const { selectedItems } = this.state;

    const selectedItemsCount = selectedItems.length;

    const promises = selectedItems.map(item => {
      const { id: formId } = item;

      const where = {
        formId
      };

      return getSchoolStudentsCount(user, where);
    });

    this.setState({ isLoading: true });

    BPromise.all(promises).then(studentsCountsArray => {
      const formsWithStudens = [];
      const formsWithoutStudents = [];

      studentsCountsArray.forEach((item, index) => {
        const { count } = item;
        const form = selectedItems[index];
        const formName = selectedItems[index].name;
        count > 0 ? formsWithStudens.push(formName) : formsWithoutStudents.push(form);
      });

      const formsWithStudensCount: number = formsWithStudens.length;
      const formsWithoutStudentsCount: number = formsWithoutStudents.length;

      const formsWithStudensText: string = formsWithStudens.join(', ');
      const formsWithoutStudentsText: string = formsWithoutStudents.map(form => form.name).join(', ');

      const isAllSelectedFormsContainStudents: boolean = formsWithStudensCount > 0 && formsWithoutStudentsCount === 0;
      const isAllSelectedFormsNotContainStudents: boolean =
        formsWithStudensCount === 0 && formsWithoutStudentsCount > 0;
      const isSomeSelectedFormsContainStudentsSomeNot: boolean =
        formsWithStudensCount > 0 && formsWithoutStudentsCount > 0;

      let deleteFormConfirmationModalText: string;
      let deleteFormProhibitionModalText: string;

      switch (true) {
        case isAllSelectedFormsContainStudents:
          deleteFormProhibitionModalText = `The form(s) ${formsWithStudensText} cannot be deleted as there are students linked to it. Please update the students details first.`;

          this.setState({
            deleteFormProhibitionModalText,
            formsWithoutStudents,
            isDeleteFormProhibitionModalOpen: true,
            isLoading: false
          });

          break;

        case isAllSelectedFormsNotContainStudents:
          deleteFormConfirmationModalText = `Are you sure you want to remove ${
            selectedItemsCount === 1 ? `form ${formsWithoutStudentsText}` : `${selectedItemsCount} form`
          }?`;

          this.setState({
            deleteFormConfirmationModalText,
            formsWithoutStudents,
            isDeleteFormConfirmationModalOpen: true,
            isLoading: false
          });

          break;

        case isSomeSelectedFormsContainStudentsSomeNot:
          deleteFormConfirmationModalText = `Are you sure you want to remove ${formsWithoutStudentsCount} form?\nThe form(s) ${formsWithStudensText} cannot be deleted as there are students linked to it.`;

          this.setState({
            deleteFormConfirmationModalText,
            formsWithoutStudents,
            isDeleteFormConfirmationModalOpen: true,
            isLoading: false
          });

          break;
      }
    });
  };

  removeForm = () => {
    const { user } = this.props;
    const { formsWithoutStudents } = this.state;

    this.setState({ isLoading: true });

    const promises = formsWithoutStudents.map(form => {
      const { id } = form;

      return deleteForm(user, id);
    });

    BPromise.all(promises).then(() => {
      this.setState({
        selectedItems: [],
        isDeleteFormConfirmationModalOpen: false
      });

      this.setItems();
    });
  };

  openDeleteFormConfirmationModal = (): void => {
    this.setState({
      isDeleteFormConfirmationModalOpen: true
    });
  };

  closeDeleteFormConfirmationModal = (): void => {
    this.setState({
      isDeleteFormConfirmationModalOpen: false
    });
  };

  renderDeleteFormConfirmationModal() {
    const { selectedItems, isDeleteFormConfirmationModalOpen, deleteFormConfirmationModalText } = this.state;

    return (
      <SimpleModal
        isOpen={isDeleteFormConfirmationModalOpen}
        title={'Remove form'}
        body={deleteFormConfirmationModalText}
        buttonText={'Delete'}
        onButtonClick={this.removeForm}
        onCloseClick={this.closeDeleteFormConfirmationModal}
      />
    );
  }

  openDeleteFormProhibitionModal = (): void => {
    this.setState({
      isDeleteFormProhibitionModalOpen: true
    });
  };

  closeDeleteFormProhibitionModal = (): void => {
    this.setState({
      isDeleteFormProhibitionModalOpen: false
    });
  };

  renderDeleteFormProhibitionModal() {
    const { isDeleteFormProhibitionModalOpen, deleteFormProhibitionModalText } = this.state;

    return (
      <SimpleModal
        isOpen={isDeleteFormProhibitionModalOpen}
        title={'Action is prohibited'}
        body={deleteFormProhibitionModalText}
        onCloseClick={this.closeDeleteFormProhibitionModal}
      />
    );
  }

  renderSummary() {
    const { selectedItems } = this.state;
    const isOneItemSelected = selectedItems.length === 1;

    if (!isOneItemSelected) {
      return <div className="font-weight-bold">Select a form above</div>;
    }

    const summaryTabs = this.getSummaryTabs();

    const isShowEditButton = selectedItems.length === 1;

    return (
      <GenericSummary onTabClick={this.onTabClick} tabs={FORM_TABS} currentTabIndex={0}>
        {isShowEditButton && <Button onClick={this.onEditFormClick} text={'Edit'} customClass={'mt-3 mb-3'} />}
        {summaryTabs}
      </GenericSummary>
    );
  }

  stylizeCell = cell => {
    const isItemWithStudents = propz.get(cell, ['isContainStudents']);

    return isItemWithStudents ? '' : 'text-danger';
  };

  render() {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isLoading,
      isSelectAllChecked,
      currentPage,
      totalWithFilters,
      isCreateOrEditFormModalOpen,
      isDeleteFormConfirmationModalOpen,
      forms
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes = isCreateOrEditFormModalOpen || isDeleteFormConfirmationModalOpen ? 'mt-3 modal-open' : 'mt-3';

    const actionItems = [
      {
        itemText: 'Create form',
        onItemClick: this.onCreateFormClick,
        isActive: selectedItems.length === 0
      },
      {
        itemText: 'Remove form',
        onItemClick: this.onRemoveFormClick,
        isActive: selectedItems.length > 0
      }
    ];

    const user = this.props.user;
    const activeSchool = user.activeSchool;
    const ageGroupsNaming = activeSchool.ageGroupsNaming;

    const filterOptions = {
      ageGroup: getSelectOptionForAge(forms, ageGroupsNaming)
    };

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderDeleteFormConfirmationModal()}
        {this.renderDeleteFormProhibitionModal()}
        {this.renderForm()}
        <div className="row">
          <div className="col-md-12">
            <ResizablePanel>
              <Grid2
                dataItems={items}
                filters={filters}
                currentPage={currentPage}
                total={totalWithFilters}
                isSelectAllChecked={isSelectAllChecked}
                isDataFiltered={isDataFiltered}
                sortDirection={sortDirection}
                sortColumnsName={sortColumnsName}
                isShowFilter={isShowFilter}
                dataItemsSelected={selectedItems}
                columns={COLUMNS}
                actionItems={actionItems}
                options={filterOptions}
                stylizeCell={this.stylizeCell}
                onItemClick={this.onItemClick}
                onSortClick={this.onTableSortClick}
                onApplyFilterClick={this.onApplyFilterClick}
                onClearFilterClick={this.onClearFilterClick}
                onTableFilterChange={this.onTableFilterChange}
                onTableFilterClick={this.onTableFilterClick}
                setCurrentPageParams={this.setCurrentPageParams}
                onSelectAllClick={this.onSelectAllClick}
                onSelectAllOnPageClick={this.onSelectAllOnPageClick}
                onUnselectAllClick={this.onUnselectAllClick}
              />
              {this.renderSummary()}
            </ResizablePanel>
          </div>
        </div>
      </div>
    );
  }
}
