import * as React from 'react';
import { FunctionComponent } from 'react';
import * as propz from 'propz';
import {
    SchoolEvent,
    SchoolEventIndividualData,
    SchoolEventIndividualPerformance,
    SchoolEventTeamDataPlayer
} from 'Src/models/event';
import { getFullName } from 'Src/helpers/autocomplete/autocomplete';
import { isClubEvent } from 'Src/helpers/event/event';
import { StarRatingBar } from 'Src/components/StarRatingBar/StarRatingBar';

interface Props {
    schoolEvent: SchoolEvent;
    allTeamPlayers: (SchoolEventIndividualData | SchoolEventTeamDataPlayer)[];
    individualPerformance: SchoolEventIndividualPerformance[];
}

export const ChildrenEventPerformanceSummaryTab: FunctionComponent<Props> = props => {
    const { schoolEvent, allTeamPlayers, individualPerformance } = props;

    const renderPlayerPerformance = player => {
        const sportPerformanceArray = propz.get(schoolEvent, ['sport', 'performance'], []);

        return sportPerformanceArray.map(onePerformance => {
            // player performance data
            const pData = individualPerformance.find(
                pUserData => pUserData.performanceId === onePerformance._id && pUserData.userId === player.userId
            );
            const value = pData ? pData.value : 0;

            const minValue = propz.get(onePerformance, ['minValue'], 0);
            const maxValue = propz.get(onePerformance, ['maxValue'], 0);

            return (
                <div key={onePerformance._id} className="d-flex justify-content-between">
                    <div className="font-italic">{onePerformance.name}</div>
                    <div className="ePlayer_performanceItemValueContainer">
                        <StarRatingBar
                            minValue={minValue}
                            maxValue={maxValue}
                            player={player}
                            onePerformanceId={onePerformance._id}
                            value={value}
                            isEditMode={false}
                            handleValueChanges={() => {}}
                        />
                    </div>
                </div>
            );
        });
    };

    const rows = allTeamPlayers.map((player, index) => {
        const fullName = getFullName(player);
        return (
            <tr key={`performance_summary_${index}`}>
                <td>{index + 1}</td>
                <td className="">
                    <div className="font-weight-bold">{fullName}</div>
                </td>
                <td>
                    <div className="">{renderPlayerPerformance(player)}</div>
                </td>
                <td />
            </tr>
        );
    });

    const emptyRow = (
        <tr key={'players_empty_row'}>
            <td>No participants</td>
        </tr>
    );
    const columnPerformanceName = isClubEvent(schoolEvent) ? 'Progress' : 'Performance';
    return (
        <table className={'table table-sm mPre'}>
            <thead>
            <tr>
                <th>#</th>
                <th>Name</th>
                <th>{columnPerformanceName}</th>
                <th />
            </tr>
            </thead>
            <tbody>{rows.length > 0 ? rows : emptyRow}</tbody>
        </table>
    );
};

ChildrenEventPerformanceSummaryTab.displayName = 'ChildrenEventPerformanceSummaryTab';