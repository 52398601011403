import * as React from 'react';
import { CLUB_INVITATIONS, CLUB_INVITATIONS_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/club';
import { SCHOOL_CLUB_INVITATIONS_TYPE } from 'Src/types/school';
import { LabelWithQuestionIcon } from 'Src/components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import {Sport} from "../../../../../../../models/sport";
import {ClubSport} from "../../../../../../../models/club";
import {MultiSelectCheckboxItem} from "../../../../../../../components/MultiSelectCheckboxes/MultiSelectCheckboxes";
import * as propz from "propz";
import {getGendersFromClubMultiSelect} from "../../../../../../../helpers/multiselect/multiselect";
import {getAgesFromClubMultiSelect} from "../../../../../../../helpers/accessor/accessor";
import '../ClubWizard.scss';

interface Props {
  maxParticipants: number;
  onInputChange: (event) => void;
  invitations: SCHOOL_CLUB_INVITATIONS_TYPE;
  //autoenrollment: CLUB_AUTOENROLLMENT_TYPE;
  name: string;
  sport: Sport | ClubSport;
  genderItems: MultiSelectCheckboxItem[];
  ageItems: MultiSelectCheckboxItem[];
}

export function ClubWizardBookingStep(props: Props) {
  const { maxParticipants, onInputChange, invitations, name, sport, genderItems, ageItems } = props;
  const clubSportName = propz.get(sport, ['name'], '');
  const clubGender = getGendersFromClubMultiSelect(genderItems);
  const clubAges = getAgesFromClubMultiSelect(ageItems);

  return (
    <div className={'mt-3'}>
      <div className="container-fluid pl-0">
          <div className="form-group">
            <div className="bClubInformation">
              <div>{name}</div>
              <LabelWithQuestionIcon
                  labelText={`${clubSportName} ${clubGender} ${clubAges}  `}
                  hintText=''
                  customClass=""
              />
            </div>
          </div>
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <LabelWithQuestionIcon
                labelText="Maximum number of students (0 - unlimited)"
                hintText={'This is the number of students who can enroll onto the club'}
              />
              <input
                type="text"
                className="form-control"
                onChange={onInputChange}
                value={maxParticipants}
                name={'maxParticipants'}
              />
            </div>

            <LabelWithQuestionIcon
              labelText="Booking forms are sent to"
              hintText={
                'You can choose to send booking forms to either parents or students, ' +
                'this will not mean they go to them as soon as you create the club, it is just ' +
                'determining the settings for if / when you decide to send booking forms. ' +
                'Booking forms can be sent once the club has been created'
              }
            />
            <div className="form-group">
              <select value={invitations} name="invitations" className="form-control mb-3" onChange={onInputChange}>
                <option value={CLUB_INVITATIONS.PARENT}>{CLUB_INVITATIONS_SERVER_TO_CLIENT_MAPPING.PARENT}</option>
                <option value={CLUB_INVITATIONS.STUDENT}>{CLUB_INVITATIONS_SERVER_TO_CLIENT_MAPPING.STUDENT}</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      {/*<label>Autoenrollment is</label>*/}
      {/*<div className="form-group">*/}
      {/*  <select name="autoenrollment" className="form-control mb-3" onChange={onInputChange}>*/}
      {/*    <option value={CLUB_AUTOENROLLMENT.ON}>{CLUB_AUTOENROLLMENT_SERVER_TO_CLIENT_MAPPING.ON}</option>*/}
      {/*    <option value={CLUB_AUTOENROLLMENT.OFF}>{CLUB_AUTOENROLLMENT_SERVER_TO_CLIENT_MAPPING.OFF}</option>*/}
      {/*  </select>*/}
      {/*</div>*/}
    </div>
  );
}
