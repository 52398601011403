import * as React from 'react';
import { Component } from 'react';
import { SchoolEvent } from 'Src/models/event';
import * as propz from 'propz';
import {
  convertPointsToString,
  getDefaultDigitMask,
  getIndividualScoreByStudent,
  getIsErrorHashMap,
  getMask,
  getPlayerScoreId,
  getScorePoints
} from 'Src/helpers/event/schoolEventViewHelper';
import * as TextMaskCore from 'text-mask-core';
import { Button } from 'Src/components/Button/Button';
import { EMPTY_STRING } from 'Src/consts/date';
import { sortIndividualPlayers } from 'Src/helpers/players/players';
import { AppUser } from 'Src/views/App/App';
import { getSortedSchoolsFromEvent } from 'Src/helpers/event/event';
import { ARROW_DOWN_KEY, ARROW_UP_KEY, ENTER_KEY } from 'Src/consts/common';
import {
  ErrorHashMap,
  ValueHashMap
} from '../../TeamsAndEvents/Events/SchoolEventView/ResultsViews/IndividualInternalResults';
import {getEventGeneratedName} from "../../../../../helpers/accessor/accessor";

interface Props {
  event: SchoolEvent;
  onSaveClick: (data) => void;
  onCloseClick: () => void;
  user: AppUser;
}

interface State {
  mask: boolean | (string | RegExp)[];
  valueHashMap: ValueHashMap;
  isErrorHashMap: ErrorHashMap;
  oldValueHashMap: ValueHashMap;
  count: number;
  players: Player[];
  school: any;
}

interface Player {
  id: string;
  userId: string;
  permissionId: string;
  schoolId: string;
}

export class SchoolIndividualTournamentIndividualEventResults extends Component<Props, State> {
  inputElement: any[];
  textMaskInputElement: any[];

  constructor(props: Props) {
    super(props);

    this.inputElement = [];
    this.textMaskInputElement = [];

    const { event, user } = props;
    const mask = propz.get(event, ['sport', 'points', 'inputMask']);

    const inputMask = getMask(mask);

    const { individualsData } = event;
    const schoolsSorted = getSortedSchoolsFromEvent(user, event);

    let valueHashMap = {};
    let oldValueHashMap = {};
    let isErrorHashMap = {};
    let players = [];

    //for tournament events we can update results only for active school
    const schoolFounded = schoolsSorted.find(school => {
      const { user } = props;
      const { activeSchoolId } = user;
      const schoolId = school.id;

      return activeSchoolId === schoolId;
    });

    const schoolId = schoolFounded.id;
    const playersFiltered = individualsData.filter(individual => individual.schoolId === schoolId);

    [...playersFiltered]
      .sort((player1, player2) => sortIndividualPlayers(event, player1, player2))
      .forEach(({ id, userId, permissionId }) => {
        const playerPoints = getIndividualScoreByStudent(event, userId, permissionId);
        const playerPointsStr = convertPointsToString(event, playerPoints);

        valueHashMap[id] = playerPoints === 0 ? '' : playerPointsStr;
        oldValueHashMap[id] = playerPoints === 0 ? '' : playerPointsStr;
        isErrorHashMap[id] = false;
        players.push({ id, userId, permissionId, schoolId: schoolId });
      });
    this.state = {
      mask: inputMask,
      valueHashMap: valueHashMap,
      isErrorHashMap: isErrorHashMap,
      count: players.length,
      oldValueHashMap: oldValueHashMap,
      players: players,
      school: schoolFounded
    };
  }

  componentDidMount() {
    this.initTextMask();
  }

  initTextMask() {
    const { valueHashMap, count, players, mask } = this.state;
    const { event } = this.props;

    for (let i = 0; i < count; i++) {
      this.textMaskInputElement[i] = TextMaskCore.createTextMaskInputElement({
        inputElement: this.inputElement[i],
        mask: mask || (inputValue => getDefaultDigitMask(event, inputValue)),
        showMask: false
      });
      const { id } = players[i];
      const value = valueHashMap[id];
      this.textMaskInputElement[i].update(value);
    }
  }

  onSubmit = () => {
    const { valueHashMap, players } = this.state;
    const { event, onSaveClick } = this.props;

    const isErrorHashMap = getIsErrorHashMap(event, valueHashMap, players);

    const isErrorPlayers = players.some(player => isErrorHashMap[player.id] === true);

    if (isErrorPlayers) {
      this.setState({
        isErrorHashMap: isErrorHashMap
      });
      return;
    }

    const playerScores = players
      .filter(({ id }) => {
        return valueHashMap[id] !== '';
      })
      .map(({ userId, permissionId, id, schoolId }) => {
        return {
          userId: userId,
          permissionId: permissionId,
          schoolId: schoolId,
          score: getScorePoints(event, valueHashMap, id),
          scoreId: getPlayerScoreId(event, userId, permissionId)
        };
      });

    onSaveClick(playerScores);
  };

  onChange(event: React.ChangeEvent<HTMLInputElement>, index: number, id: string) {
    const valueHashMap = { ...this.state.valueHashMap };
    const isErrorHashMap = { ...this.state.isErrorHashMap };

    const value = event.target.value;

    valueHashMap[id] = value;
    isErrorHashMap[id] = false;

    this.setState({
      valueHashMap: valueHashMap,
      isErrorHashMap: isErrorHashMap
    });

    this.textMaskInputElement[index].update(value);
  }

  onKeyDown = (eventDescriptor, index: number) => {
    const { count } = this.state;

    const isKeyEnter = eventDescriptor.key === ENTER_KEY;
    const isKeyArrowUp = eventDescriptor.key === ARROW_UP_KEY;
    const isKeyArrowDown = eventDescriptor.key === ARROW_DOWN_KEY;
    const isLastInput = index === count;
    const isFirstInput = index === 0;

    switch (true) {
      case isKeyEnter && !isLastInput:
      case isKeyArrowDown && !isLastInput:
        this.inputElement[index + 1].focus();
        break;
      case isKeyArrowUp && !isFirstInput:
        this.inputElement[index - 1].focus();
        break;
    }
  };

  onFocus(e, index: number, id: string) {
    const value = this.state.valueHashMap[id];
    const oldValueHashMap = { ...this.state.oldValueHashMap };
    oldValueHashMap[id] = value;

    this.setState({
      oldValueHashMap: oldValueHashMap
    });

    this.textMaskInputElement[index].update('');

    e.stopPropagation();
  }

  onBlur(e, index: number, id: string) {
    const eventValue = e.target.value;
    const oldValueHashMap = { ...this.state.oldValueHashMap };

    if (eventValue === EMPTY_STRING) {
      const valueHashMapWithOldValue = { ...this.state.valueHashMap };
      valueHashMapWithOldValue[id] = oldValueHashMap[id];
      this.setState({
        valueHashMap: valueHashMapWithOldValue
      });
      this.textMaskInputElement[index].update(oldValueHashMap[id]);
    } else {
      const valueHashMap = { ...this.state.valueHashMap };
      valueHashMap[id] = eventValue;
      this.setState({
        valueHashMap: valueHashMap
      });
      this.textMaskInputElement[index].update(eventValue);
    }

    e.stopPropagation();
  }

  renderInput(index: number, id: string): React.ReactNode {
    const { isErrorHashMap } = this.state;
    const { event } = this.props;
    const mask = propz.get(event, ['sport', 'points', 'inputMask'], '');

    return (
      <input
        ref={el => (this.inputElement[index] = el)}
        onKeyDown={event => this.onKeyDown(event, index)}
        onChange={event => this.onChange(event, index, id)}
        onFocus={event => this.onFocus(event, index, id)}
        onBlur={event => this.onBlur(event, index, id)}
        placeholder={mask}
        className={
          isErrorHashMap[id] === true
            ? 'eIndividualExternalSchoolsResultsInput mError'
            : 'eIndividualExternalSchoolsResultsInput'
        }
      />
    );
  }

  render() {
    const { user, event, onCloseClick } = this.props;
    const { players, school } = this.state;
    const { individualsData } = event;
    const eventName = getEventGeneratedName(event, { user: user });
    const mask = propz.get(event, ['sport', 'points', 'inputMask'], '');
    const isEmptyMask = mask === '';

    let count = 0;

    const isPlayersExist = players.length > 0;

    if (!isPlayersExist) {
      return (
        <>
          <h1>Error</h1>
          <div className="alert alert-danger">First, add players in event</div>
          <Button text={'Close'} onClick={onCloseClick} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
        </>
      );
    }

    const { id: schoolId, name } = school;

    const playersFilteredAndSorted = [...individualsData]
      .filter(individual => individual.schoolId === schoolId)
      .sort((player1, player2) => sortIndividualPlayers(event, player1, player2));

    return (
      <div>
        {!isEmptyMask &&
        <div className="mb-2">
          <div>You are submitting results for:</div>
          <div className="font-weight-bold">{eventName}</div>
          <span>The input mask for this event is </span>
          <span className="font-weight-bold">{mask}</span>
        </div>
        }
        <table className={'eTournamentResultsTable'}>
          <tbody>
            <tr key={`tournament_results`}>
              <td colSpan={3} className={'eTournamentResultsSchoolName'}>
                {name}
              </td>
            </tr>
            {playersFilteredAndSorted.map(({ firstName, lastName, id }, playerIndex) => {
              count++;
              return (
                <tr key={`tournament_results_player_${playerIndex}`}>
                  <td className={'eTournamentResultsCell'}>{playerIndex + 1}.</td>
                  <td className={'eTournamentResultsCell'}>{`${firstName} ${lastName}`}</td>
                  <td className={'eTournamentResultsCell'}>{this.renderInput(count - 1, id)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Button text={'Cancel'} onClick={onCloseClick} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
        <Button text={'Save results'} onClick={this.onSubmit} customClass={'mt-3 mb-3'} />
      </div>
    );
  }
}
