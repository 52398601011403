export const PAYMENT_ACCOUNT_STATUS_SERVER_TO_CLIENT_MAPPING = {
  ACTIVE: 'Active',
  REMOVED: 'Removed',
  NEW: 'New'
};

export const PAYMENT_ACCOUNT_STATUS = {
  ACTIVE: 'ACTIVE',
  REMOVED: 'REMOVED',
  NEW: 'NEW'
};

export const PAYMENT_ACCOUNT_STATE = {
  DEFAULT: 'default',
  CUSTOM: 'not default'
};

export const PAYMENT_TYPE_SERVER_TO_CLIENT_MAPPING = {
  COMPANY: 'Company',
  INDIVIDUAL: 'Individual'
};

export const PAYMENT_TYPE_PRICING = {
  COMPANY: 'company',
  INDIVIDUAL: 'individual'
};

export const PAYMENT_ACCOUNT_COUNTRY = {
  GB: 'GB'
};

export const PAYMENT_ACCOUNT_CURRENCY = {
  GBP: 'GBP'
};

export const PAYMENT_ACCOUNT_GENDER_TO_CLIENT_MAPPING = {
  MALE: 'male',
  FEMALE: 'female'
};

export const PAYMENT_ACCOUNT_GENDER = {
  MALE: 'MALE',
  FEMALE: 'FEMALE'
};

export const PAYMENT_ACCOUNT_HOLDER_TYPE_TO_CLIENT_MAPPING = {
  COMPANY: 'company',
  INDIVIDUAL: 'individual'
};
