import * as React from 'react';
import { Route, Switch } from 'react-router';
import { EmptyComponent } from 'Src/components/EmptyComponent/EmptyComponent';
import { StudentMessagesOutbox } from './MessagesOutbox';
import { StudentMessagesArchive } from './MessagesArchive';
import { StudentMessagesInbox } from './MessagesInbox';

export const StudentMessagesRouting = allProps => {
  return (
    <Switch>
      <Route exact path={'/messages'} render={props => <EmptyComponent {...props} user={allProps.user} />} />
      <Route
        exact
        path={'/messages/inbox'}
        render={props => (
          <StudentMessagesInbox {...props} user={allProps.user} setNeedUpdate={allProps.setNeedUpdate} />
        )}
      />
      <Route
        exact
        path={'/messages/outbox'}
        render={props => (
          <StudentMessagesOutbox {...props} user={allProps.user} availableRoles={allProps.availableRoles} />
        )}
      />
      <Route
        exact
        path={'/messages/archive'}
        render={props => <StudentMessagesArchive {...props} user={allProps.user} />}
      />
    </Switch>
  );
};

StudentMessagesRouting.displayName = 'StudentMessagesRouting';
