import * as React from 'react';
import { SchoolEvent, SchoolEventTeamDataPlayer } from 'Src/models/event';
import * as propz from 'propz';
import { PLAYER_POSITIONS } from '../../../../../../../consts/player';

interface Props {
  player: SchoolEventTeamDataPlayer;
  event: SchoolEvent;
}

export function ChildrenEventTeamPlayerSubOrCaptain(props: Props) {
  const { player, event } = props;

  const playerData = event.players.find(eventPlayer => eventPlayer._id === player.id);
  const sub: boolean = propz.get(playerData, ['sub']);
  const captain: boolean = propz.get(playerData, ['isCaptain']);

  let playersCaptainOrSubText;

  switch (true) {
    case captain && sub:
      playersCaptainOrSubText = `${PLAYER_POSITIONS.CAPTAIN} ${PLAYER_POSITIONS.SUB}`;
      break;
    case captain:
      playersCaptainOrSubText = PLAYER_POSITIONS.CAPTAIN;
      break;
    case sub:
      playersCaptainOrSubText = PLAYER_POSITIONS.SUB;
      break;
    default:
      playersCaptainOrSubText = '';
  }

  return playersCaptainOrSubText !== '' ? playersCaptainOrSubText : null;
}