import * as React from 'react';
import * as propz from 'propz';
import { Autocomplete } from 'Src/components/Autocomplete/Autocomplete';
import { getNameByPlaceOrPostcode } from 'Src/helpers/autocomplete/autocomplete';
import { Map } from 'Src/components/Map/Map';
import { ClubWizardErrors } from '../ClubWizard';
import { LabelWithQuestionIcon } from 'Src/components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import {Sport} from "../../../../../../../models/sport";
import {ClubSport} from "../../../../../../../models/club";
import {MultiSelectCheckboxItem} from "../../../../../../../components/MultiSelectCheckboxes/MultiSelectCheckboxes";
import {getGendersFromClubMultiSelect} from "../../../../../../../helpers/multiselect/multiselect";
import {getAgesFromClubMultiSelect} from "../../../../../../../helpers/accessor/accessor";
import '../ClubWizard.scss';

interface Props {
  location: string;
  onInputChange: (event) => void;
  getVenues: (text: string) => any;
  onVenueSelect: (venue: any) => void;
  venue: any;
  createVenueFormOpen: () => void;
  errors: ClubWizardErrors;
  name: string;
  sport: Sport | ClubSport;
  genderItems: MultiSelectCheckboxItem[];
  ageItems: MultiSelectCheckboxItem[];
}

export function ClubWizardVenueStep(props: Props) {
  const { location, onInputChange, getVenues, venue, onVenueSelect, createVenueFormOpen, errors, name,
    sport,
    genderItems,
    ageItems } = props;
  const point = propz.get(venue, ['point']);
  const clubSportName = propz.get(sport, ['name'], '');
  const clubGender = getGendersFromClubMultiSelect(genderItems);
  const clubAges = getAgesFromClubMultiSelect(ageItems);

  return (
    <div className={'mt-3'}>
      <div className="container-fluid pl-0">
          <div className="form-group">
            <div className="bClubInformation">
              <div>{name}</div>
              <LabelWithQuestionIcon
                  labelText={`${clubSportName} ${clubGender} ${clubAges}  `}
                  hintText=''
                  customClass=""
              />
            </div>
          </div>
        <div className="row">
          <div className="col-md-5">
            <div className="form-group">
              <LabelWithQuestionIcon
                labelText="Location/Postcode"
                hintText={
                  'You can either select a venue from your venue list or create ' + 'a new venue right from this form. '
                }
              />
              <Autocomplete
                searchFunction={getVenues}
                getElementTitle={getNameByPlaceOrPostcode}
                customClass="mFullWidth mb-3"
                placeholder="Start typing the venue name"
                defaultItem={venue}
                onSelect={onVenueSelect}
              />
            </div>
            {errors.venue && <div className="alert alert-danger">Required</div>}
          </div>

          <div className="col-md-2 d-flex flex-column-reverse mb-3">
            <div className="form-group">
              <button type="button" className="btn btn-success" onClick={createVenueFormOpen}>
                {'Create venue'}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-5 pl-0">
        <Map point={point} customStylingClass="mb-3 eClubWizardMap" isMarkerDraggable={false} />
      </div>

      <div className="col-md-6 pl-0">
        <div className="form-group">
          <LabelWithQuestionIcon
            labelText="Specific location"
            hintText={'You can add a specific room or playing field within your school or other location'}
          />
          <input type="text" className="form-control" onChange={onInputChange} value={location} name={'location'} />
        </div>
      </div>
    </div>
  );
}
