import * as React from 'react';
import { Route, Switch } from 'react-router';
import { TournamentsUpcoming } from './TournamentsUpcoming/TournamentsUpcoming';
import { TournamentsArchive } from './TournamentsArchive/TournamentsArchive';
import { TournamentNotifications } from './TournamentNotifications';
import { TournamentParticipants } from './TournamentParticipants';
import { TournamentEligibleSchools } from './TournamentEligibleSchools';
import { TournamentConfirmationRequests } from './TournamentConfirmationRequests';
import { SchoolUnionTournamentEvents } from './TournamentEvents';
import { TournamentAdmins } from './TournamentAdmins';
import { TournamentCustomGroups } from './TournamentCustomGroups';
import { TournamentPitches } from './TournamentPitches';
import { TournamentEnrolledLogs } from './TournamentEnrolledLogs';
import { CreateTournamentCustomNotificationsView } from './CreateTournamentCustomNotificationsView/CreateTournamentCustomNotificationsView';
import { TournamentParticipants2 } from './TournamentParticipants2';
import { AdminSUTournamentTeams } from './TournamentTeams/TournamentTeams';
import { TournamentView } from './TournamentForm/TournamentView';
import AdminSUTournamentGrid from './TournamentGrid/TournamentGrid';
import { SchoolUnionEventView } from './TournamentEvent/SchoolUnionEventView';

export const TournamentRouting = allProps => {
  return (
    <Switch>
      <Route
        exact
        key="upcoming"
        path={'/tournaments/upcoming'}
        render={props => <TournamentsUpcoming {...props} user={allProps.user} />}
      />
      <Route
        exact
        key="archive"
        path={'/tournaments/archive'}
        render={props => <TournamentsArchive {...props} user={allProps.user} />}
      />
      <Route
        exact
        path={'/tournaments/tournamentView'}
        render={props => <TournamentView {...props} user={allProps.user} />}
      />
      <Route
        exact
        path={'/tournaments/notifications'}
        render={props => <TournamentNotifications {...props} user={allProps.user} />}
      />
      <Route
        exact
        path={'/tournaments/notifications/send'}
        render={props => <CreateTournamentCustomNotificationsView {...props} user={allProps.user} />}
      />
      <Route
        exact
        path={'/tournaments/participants'}
        render={props => <TournamentParticipants2 {...props} user={allProps.user} />}
      />
      <Route
        exact
        path={'/tournaments/confirmationRequests/send'}
        render={props => <TournamentParticipants {...props} user={allProps.user} />}
      />
      <Route
        exact
        path={'/tournaments/eligibleSchools'}
        render={props => <TournamentEligibleSchools {...props} user={allProps.user} />}
      />
      <Route
        exact
        path={'/tournaments/confirmationRequests'}
        render={props => <TournamentConfirmationRequests {...props} user={allProps.user} />}
      />
      <Route
        exact
        path={'/tournaments/pitches'}
        render={props => <TournamentPitches {...props} user={allProps.user} />}
      />
      <Route
        exact
        path={'/tournaments/events'}
        render={props => <SchoolUnionTournamentEvents {...props} user={allProps.user} />}
      />
      <Route
        exact
        path={'/tournaments/events/event'}
        render={props => <SchoolUnionEventView {...props} user={allProps.user} />}
      />
      <Route
        exact
        path={'/tournaments/admins'}
        render={props => <TournamentAdmins {...props} user={allProps.user} />}
      />
      <Route
        exact
        path={'/tournaments/customGroups'}
        render={props => <TournamentCustomGroups {...props} user={allProps.user} />}
      />
      <Route
        exact
        path={'/tournaments/logs'}
        render={props => <TournamentEnrolledLogs {...props} user={allProps.user} />}
      />
      <Route
        exact
        path={'/tournaments/teams'}
        render={props => <AdminSUTournamentTeams {...props} user={allProps.user} />}
      />
      <Route
        exact
        path={'/tournaments/grid'}
        render={props => <AdminSUTournamentGrid {...props} user={allProps.user} />}
      />
    </Switch>
  );
};

TournamentRouting.displayName = 'TournamentRouting';
