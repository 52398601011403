import * as React from 'react';
import { DAYS_OF_WEEK } from '../../../../consts/common';

export function DaysOfWeekBar() {
  return (
    <div className={'eMonth_row eMonth_row_wrapper mWeeks'}>
      {DAYS_OF_WEEK.map((dayOfWeek, index) => (
        <span className={'eMonth_day mWeekName'} key={`day_of_week_${index}`}>
          {dayOfWeek}
        </span>
      ))}
    </div>
  );
}
