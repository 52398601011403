import { AppUser } from 'Src/views/App/App';
import axios from 'axios';

export function uploadReport(user: AppUser, reportType: string, date) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/report/import/${reportType}`, date, config)
    .then(response => {
      return response.data;
    });
}
