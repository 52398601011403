import * as React from 'react';
import * as propz from 'propz';
import { AppUser } from 'Src/views/App/App';
import { ConfirmationRequestTemplate } from 'Src/models/confirmationRequestTemplate';
import { QUESTIONS_TEMPLATE_TYPE } from 'Src/consts/message';
import {
  getConfirmationRequestTemplate,
  updateConfirmationRequestTemplate
} from 'Src/helpers/service/adminSU/tournamentParticipantsTable';
import { QuestionsTemplateField } from 'Src/components/QuestionsTemplateField/QuestionsTemplateField';
import { Loader } from 'Src/components/Loader/Loader';
import { Button } from 'Src/components/Button/Button';

interface Props {
  user: AppUser;
}

interface State {
  isLoading: boolean;
  template: ConfirmationRequestTemplate;
}

export class TournamentConfirmationRequests extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      template: undefined
    };
  }

  componentDidMount() {
    this.setFields();
  }

  setFields = () => {
    const { user } = this.props;

    this.setState({
      isLoading: true
    });

    getConfirmationRequestTemplate(user).then(template => {
      this.setState({
        template,
        isLoading: false
      });
    });
  };

  onSubmit = () => {
    const { user } = this.props;
    const { template } = this.state;
    const fields = propz.get(template, ['fields'], []);

    const data = fields.map(field => {
      const isEnumType = field.type === QUESTIONS_TEMPLATE_TYPE.ENUM;
      const isHeadingExist = typeof field.heading !== 'undefined' && field.heading !== '';

      const result = { ...field };

      delete result._id;

      if (!isHeadingExist) {
        delete result.heading;
      }

      if (isEnumType) {
        result.enumOptions = field.enumOptions
          .map(option => option.trim())
          .join(',')
          .split(',')
          .filter(option => option !== '');
      } else {
        delete result.enumOptions;
      }

      return result;
    });

    this.setState({
      isLoading: true
    });

    updateConfirmationRequestTemplate(user, { fields: data }).then(template => {
      this.setState({
        isLoading: false,
        template
      });
    });
  };

  renderFields = () => {
    const { template } = this.state;
    const fields = propz.get(template, ['fields'], []);

    return fields.map(field => {
      return (
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3" key={field._id}>
          <QuestionsTemplateField field={field} onChange={this.onFieldsChange} onRemove={this.onRemoveFieldClick} />
        </div>
      );
    });
  };

  onFieldsChange = (fieldId, objectKey, value) => {
    const { template } = this.state;
    const fields = propz.get(template, ['fields'], []);

    const fieldsUpdated = fields.map(field => {
      if (fieldId === field._id) {
        return {
          ...field,
          [objectKey]: value
        };
      }

      return field;
    });

    this.setState({
      template: {
        ...template,
        fields: fieldsUpdated
      }
    });
  };

  onRemoveFieldClick = fieldId => {
    const { template } = this.state;
    const fields = propz.get(template, ['fields'], []);

    const fieldsUpdated = fields.filter(field => {
      return field._id !== fieldId;
    });

    this.setState({
      template: {
        ...template,
        fields: fieldsUpdated
      }
    });
  };

  onAddFieldClick = () => {
    const { template } = this.state;
    const fields = propz.get(template, ['fields'], []);

    const id = new Date().getTime();

    const defaultField = {
      _id: id,
      heading: '',
      isDefault: false,
      isRequired: false,
      type: QUESTIONS_TEMPLATE_TYPE.STRING
    };

    this.setState({
      template: {
        ...template,
        fields: [...fields, defaultField]
      }
    });
  };

  onCancelClick = () => {
    this.setFields();
  };

  render() {
    const { isLoading } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    return (
      <div className="mt-3">
        <h2>Tournament confirmation requests questions</h2>

        <div className="row mt-3">{this.renderFields()}</div>

        <div className="d-flex justify-content-between mt-3 mb-5">
          <Button text="Add field" onClick={this.onAddFieldClick} />

          <div>
            <Button text="Cancel" onClick={this.onCancelClick} customClass="btn-secondary mr-2" />
            <Button text="Submit" onClick={this.onSubmit} />
          </div>
        </div>
      </div>
    );
  }
}
