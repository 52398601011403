import * as React from 'react';
import './FullScreenVideo.scss';
import { Loader } from '../../../../../../../components/Loader/Loader';
import { ACCESS_PRESET_TYPE } from '../../../../../../../types/album';
import * as propz from 'propz';
import { AccessPresetRadioButtons } from '../Gallery/AccessPresetRadioButtons';
import { EventVideo } from './EventVideo';

interface Props {
  onCloseClick: () => void;
  onNextVideoClick: () => void;
  onPreviousVideoClick: () => void;
  video: any;
  isShowArrowButtons: boolean;
  isUpdateAccessPreset: boolean;
  onAccessPresetClick: (accessPreset: ACCESS_PRESET_TYPE) => void;
  isShowAccessPresetRadioButtons: boolean;
}
interface State {
  windowWidth: number;
  windowHeight: number;
}

export class FullScreenVideo extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    };
  }

  onVideoClick = e => {
    e.stopPropagation();
  };

  renderVideo(url, arrowStyle) {
    const { isShowArrowButtons, onPreviousVideoClick, onNextVideoClick } = this.props;
    if (isShowArrowButtons) {
      return (
        <div className="eFullScreenVideo_video">
          <div className="eFullScreenVideo_arrowLeft" onClick={onPreviousVideoClick} style={arrowStyle} />
          <EventVideo url={url} />
          <div className="eFullScreenVideo_arrowRight" onClick={onNextVideoClick} style={arrowStyle} />
        </div>
      );
    } else {
      return <EventVideo url={url} />;
    }
  }

  renderAccessPresetButtons() {
    const { windowHeight } = this.state;
    const height = Math.floor(windowHeight * 0.8);

    const sideContainerStyle = {
      height: height - 10 // 10px - it is a left padding
    };

    const { isShowAccessPresetRadioButtons, video, onAccessPresetClick } = this.props;

    return isShowAccessPresetRadioButtons ? (
      <div className="eFullScreenVideo_sideContainer" style={sideContainerStyle}>
        <AccessPresetRadioButtons accessPreset={video.accessPreset} onAccessPresetClick={onAccessPresetClick} />
      </div>
    ) : null;
  }

  render() {
    const { video, onCloseClick, isUpdateAccessPreset } = this.props;
    const { windowWidth, windowHeight } = this.state;
    const url = propz.get(video, ['streamData', '0', 'url']);

    const width = Math.floor(windowWidth * 0.8);
    const height = Math.floor(windowHeight * 0.8);
    const topOffset = height * 0.5;
    const leftOffset = width * 0.5;

    const videoContainerStyle = {
      marginTop: -topOffset,
      marginLeft: -leftOffset,
      width: width,
      height: height
    };

    const arrowStyle = {
      height: height
    };

    if (isUpdateAccessPreset) {
      return <Loader />;
    }

    return (
      <div className="bFullScreenVideo" onClick={onCloseClick}>
        <div className="eFullScreenVideo_closeCross" onClick={onCloseClick} />
        <div className={`eFullScreenVideo_videoContainer`} onClick={this.onVideoClick} style={videoContainerStyle}>
          {this.renderVideo(url, arrowStyle)}

          {isUpdateAccessPreset ? (
            <div className="eFullScreenVideo_sideContainer">
              <Loader />
            </div>
          ) : (
            this.renderAccessPresetButtons()
          )}
        </div>
      </div>
    );
  }
}
