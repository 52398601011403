import * as React from 'react';
import { Component } from 'react';
import * as propz from 'propz';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import moment = require('moment');
import { AppUser } from 'Src/views/App/App';
import MaskedInput from 'react-text-mask';
import { PaymentAccount } from 'Src/models/paymentAccount';
import { getPaymentAccount, updatePaymentAccount } from 'Src/helpers/service/admin/paymentAccounts';
import { Loader } from 'Src/components/Loader/Loader';
import {
  PAYMENT_ACCOUNT_COUNTRY,
  PAYMENT_ACCOUNT_CURRENCY,
  PAYMENT_ACCOUNT_GENDER_TO_CLIENT_MAPPING,
  PAYMENT_ACCOUNT_HOLDER_TYPE_TO_CLIENT_MAPPING,
  PAYMENT_ACCOUNT_STATUS,
  PAYMENT_TYPE_PRICING
} from 'Src/consts/paymentAccount';

interface Props {
  user: AppUser;
  onCancelButtonClick: () => void;
  paymentAccountId: string;
}

interface State {
  isLoading: boolean;
  isSendingRequest: boolean;
  paymentAccount: PaymentAccount;
  errorText: string;
  isError: boolean;
  successText: string;
  isSuccess: boolean;
}

const validationSchema = Yup.object().shape({
  routingNumber: Yup.string().required('Required'),
  accountNumber: Yup.string().required('Required')
});

export class PayoutForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
      isSendingRequest: false,
      paymentAccount: undefined,
      errorText: '',
      isError: false,
      successText: '',
      isSuccess: false
    };
  }

  componentDidMount() {
    const { user, paymentAccountId } = this.props;

    return getPaymentAccount(user, paymentAccountId).then(paymentAccount => {
      this.setState({
        paymentAccount: paymentAccount,
        isLoading: false
      });
    });
  }

  onSaveButtonClick = (data): void => {
    const { user, paymentAccountId } = this.props;
    const { paymentAccount } = this.state;

    const isDefault = propz.get(paymentAccount, ['isDefault'], false);

    const isPaymentTypeCompany = paymentAccount.type === PAYMENT_TYPE_PRICING.COMPANY;

    let dataCopy = {};

    if (isPaymentTypeCompany) {
      dataCopy = {
        platform: 'STRIPE',
        type: data.type,
        name: data.name,
        status: data.status,
        isDefault: isDefault,
        stripeData: {
          country: data.country,
          businessProfile: {
            name: data.businessName,
            url: data.url
          },
          company: {
            name: data.companyName,
            directorsProvided: true, // comment from old form: so most likely correctly, testing will show, so far only one person is the owner of the company
            taxId: data.taxId,
            phone: data.companySupportPhone ? data.companySupportPhone.split(' ').join('') : null,
            vatId: data.vatId,
            address: {
              postcode: data.postcode,
              country: data.companyCountry,
              state: data.companyState,
              city: data.companyCity,
              line1: data.companyLine1,
              line2: data.companyLine2
            }
          },
          externalAccount: {
            country: data.externalAccountCountry,
            currency: data.externalAccountCurrency,
            accountHolderName: data.accountHolderName,
            accountHolderType: data.accountHolderType,
            routingNumber: data.routingNumber,
            accountNumber: data.accountNumber
          }
        }
      };
    } else {
      dataCopy = {
        platform: 'STRIPE',
        type: data.type,
        name: data.name,
        status: data.status,
        isDefault: isDefault,
        stripeData: {
          country: data.country,
          // email: data.email,
          businessProfile: {
            name: data.businessName,
            url: data.url
          },
          individual: {
            firstName: data.individualFirstName,
            lastName: data.individualLastName,
            gender: data.individualGender,
            phone: data.companySupportPhone ? data.companySupportPhone.split(' ').join('') : null,
            address: {
              postcode: data.individualPostcode,
              country: data.individualCountry,
              state: data.individualState,
              city: data.individualCity,
              line1: data.individualLine1,
              line2: data.individualLine2
            },
            dob: {
              day: data.individualDob[0],
              month: data.individualDob[1],
              year: data.individualDob[2]
            }
          },
          externalAccount: {
            country: data.externalAccountCountry,
            currency: data.externalAccountCurrency,
            accountHolderName: data.accountHolderName,
            accountHolderType: data.accountHolderType,
            routingNumber: data.routingNumber,
            accountNumber: data.accountNumber
          }
        }
      };
    }

    this.setState({
      isSendingRequest: true
    });

    updatePaymentAccount(user, paymentAccountId, dataCopy)
      .then(paymentAccount => {
        this.setState({
          isSendingRequest: false,
          successText: 'Payout account details have been successfully saved.',
          isSuccess: true,
          isError: false,
          errorText: ''
        });
      })
      .catch(error => {
        console.error(error.response.data.details.text);
        this.setState({
          isSendingRequest: false,
          isError: true,
          errorText: error.response.data.details.text,
          successText: '',
          isSuccess: false
        });
      });
  };

  onSubmit = data => {
    const sortCode = data.routingNumber.split('-').join('');

    const dataCopy = {
      ...data,
      routingNumber: sortCode
    };

    this.onSaveButtonClick(dataCopy);
  };

  render() {
    const { paymentAccount, isSendingRequest, isLoading, isError, errorText, isSuccess, successText } = this.state;

    const now = moment();
    const individualYear = propz.get(paymentAccount, ['stripeData', 'persons'[0], 'dob', 'year'], now.format('YYYY'));
    const individualMonth = propz.get(paymentAccount, ['stripeData', 'persons'[0], 'dob', 'month'], now.format('M'));
    const individualDay = propz.get(paymentAccount, ['stripeData', 'persons'[0], 'dob', 'day'], now.format('DD'));
    const dobIndividualDate = new Date(Number(individualYear), Number(individualMonth) - 1, Number(individualDay));

    const accountInitial = {
      // comment from old form: after for testing delete default value
      name: propz.get(paymentAccount, ['name'], ''),
      type: propz.get(paymentAccount, ['type'], ''),
      status: propz.get(paymentAccount, ['status'], PAYMENT_ACCOUNT_STATUS.ACTIVE),

      country: propz.get(paymentAccount, ['stripeData', 'country'], PAYMENT_ACCOUNT_COUNTRY.GB),
      email: propz.get(paymentAccount, ['stripeData', 'email'], ''),
      businessName: propz.get(paymentAccount, ['stripeData', 'businessProfile', 'name'], ''),
      url: propz.get(paymentAccount, ['stripeData', 'businessProfile', 'url'], ''),

      externalAccountCountry: propz.get(
        paymentAccount,
        ['stripeData', 'externalAccount', 'country'],
        PAYMENT_ACCOUNT_COUNTRY.GB
      ),
      externalAccountCurrency: propz.get(
        paymentAccount,
        ['stripeData', 'externalAccount', 'currency'],
        PAYMENT_ACCOUNT_CURRENCY.GBP
      ),
      accountHolderName: propz.get(paymentAccount, ['stripeData', 'externalAccount', 'accountHolderName'], ''),
      accountHolderType: propz.get(
        paymentAccount,
        ['stripeData', 'externalAccount', 'accountHolderType'],
        PAYMENT_ACCOUNT_HOLDER_TYPE_TO_CLIENT_MAPPING.COMPANY
      ),
      routingNumber: propz.get(paymentAccount, ['stripeData', 'externalAccount', 'routingNumber'], ''),
      accountNumber: propz.get(paymentAccount, ['stripeData', 'externalAccount', 'accountNumberLast4'], ''),

      // Payment Type Company
      companyName: propz.get(paymentAccount, ['stripeData', 'company', 'name'], ''),
      taxId: propz.get(paymentAccount, ['stripeData', 'company', 'taxId'], ''),
      companySupportPhone: propz.get(paymentAccount, ['stripeData', 'company', 'phone'], ''),
      vatId: propz.get(paymentAccount, ['stripeData', 'company', 'vatId'], ''),
      postcode: propz.get(paymentAccount, ['stripeData', 'company', 'address', 'postcode'], ''),
      companyCountry: propz.get(
        paymentAccount,
        ['stripeData', 'company', 'address', 'country'],
        PAYMENT_ACCOUNT_COUNTRY.GB
      ),
      companyState: propz.get(paymentAccount, ['stripeData', 'company', 'address', 'state'], ''),
      companyCity: propz.get(paymentAccount, ['stripeData', 'company', 'address', 'city'], ''),
      companyLine1: propz.get(paymentAccount, ['stripeData', 'company', 'address', 'line1'], ''),
      companyLine2: propz.get(paymentAccount, ['stripeData', 'company', 'address', 'line2'], ''),

      // Payment Type Individual
      individualFirstName: propz.get(paymentAccount, ['stripeData', 'individual', 'firstName'], ''),
      individualLastName: propz.get(paymentAccount, ['stripeData', 'individual', 'lastName'], ''),
      individualEmail: propz.get(paymentAccount, ['stripeData', 'individual', 'email'], ''),
      individualPhone: propz.get(paymentAccount, ['stripeData', 'individual', 'phone'], ''),
      individualGender: propz.get(
        paymentAccount,
        ['stripeData', 'individual', 'gender'],
        PAYMENT_ACCOUNT_GENDER_TO_CLIENT_MAPPING.MALE
      ),
      individualDob: dobIndividualDate,
      individualPostcode: propz.get(paymentAccount, ['stripeData', 'individual', 'address', 'postcode'], ''),
      individualCountry: propz.get(
        paymentAccount,
        ['stripeData', 'individual', 'address', 'country'],
        PAYMENT_ACCOUNT_COUNTRY.GB
      ),
      individualState: propz.get(paymentAccount, ['stripeData', 'individual', 'address', 'state'], ''),
      individualCity: propz.get(paymentAccount, ['stripeData', 'individual', 'address', 'city'], ''),
      individualLine1: propz.get(paymentAccount, ['stripeData', 'individual', 'address', 'line1'], ''),
      individualLine2: propz.get(paymentAccount, ['stripeData', 'individual', 'address', 'line2'], '')
    };

    const isInitialDataExist = accountInitial.routingNumber !== '' && accountInitial.accountNumber !== '';

    if (isLoading) {
      return <Loader />;
    }

    return (
      <div>
        <Formik
          onSubmit={values => {
            this.onSubmit(values);
          }}
          initialValues={accountInitial}
          validationSchema={validationSchema}
          render={({ touched, errors, setFieldValue, values }) => (
            <Form>
              <div>Please go through the sign up procedure with Stripe before setting up the payout account.</div>
              <div className="form-group mt-3">
                <label>Sort code</label>
                <MaskedInput
                  type="text"
                  className={`${
                    touched.routingNumber && errors.routingNumber ? 'form-control is-invalid' : 'form-control '
                  }`}
                  onChange={event => {
                    setFieldValue('routingNumber', event.target.value);
                  }}
                  mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
                  value={values.routingNumber}
                />
                {touched.routingNumber && errors.routingNumber && (
                  <div className="invalid-feedback">{errors.routingNumber}</div>
                )}
              </div>

              <div className="form-group">
                <label>{isInitialDataExist ? 'Account number (last 4 figures)' : 'Account number'}</label>
                <Field
                  type="text"
                  name="accountNumber"
                  className={`${
                    touched.accountNumber && errors.accountNumber ? 'form-control is-invalid' : 'form-control '
                  }`}
                />
                {touched.accountNumber && errors.accountNumber && (
                  <div className="invalid-feedback">{errors.accountNumber}</div>
                )}
              </div>

              {!isInitialDataExist && (
                <div className="alert alert-danger">Please thoroughly check your payout details before confirming.</div>
              )}

              {isError && <div className="alert alert-danger">{errorText}</div>}
              {isSuccess && <div className="alert alert-success">{successText}</div>}

              <div>
                <button type="button" className="btn btn-secondary mr-3" onClick={this.props.onCancelButtonClick}>
                  Close
                </button>
                {!isInitialDataExist && (
                  <button type="submit" className="btn btn-primary" disabled={isSendingRequest}>
                    Save
                  </button>
                )}
              </div>
              <div>{isSendingRequest && <div className="spinner-border spinner-border-sm" />}</div>
            </Form>
          )}
        />
      </div>
    );
  }
}
