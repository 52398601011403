import * as React from 'react';
import { Field, Form, Formik } from 'formik';
import { Button } from '../../../../../../../components/Button/Button';
import * as Yup from 'yup';

interface Props {
  onSubmit: (data: any) => void;
  onCancel: () => void;
}

const SendNotificationsSchema = Yup.object().shape({
  title: Yup.string().required('Required'),
  text: Yup.string().required('Required')
});

export function SendNotificationEligibleStudentsForm(props: Props) {
  const { onSubmit, onCancel } = props;

  const SendNotificationsFormInitial = {
    title: '',
    text: ''
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik
            initialValues={SendNotificationsFormInitial}
            validationSchema={SendNotificationsSchema}
            onSubmit={onSubmit}
            render={({ touched, errors }) => (
              <Form>
                <div className="form-group form-check">
                  <div className="form-group">
                    <label>Title</label>
                    <Field type="text" name="title" className="form-control" />
                  </div>
                  {touched.title && errors.title ? <div className="alert alert-danger">{errors.title}</div> : null}

                  <div className="form-group">
                    <label>Text</label>
                    <Field component="textarea" name="text" rows="3" className="form-control mb-3" />
                  </div>
                  {touched.text && errors.text ? <div className="alert alert-danger">{errors.text}</div> : null}

                  <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                  <button type="submit" className="mt-3 mb-3 btn btn-primary">
                    Save
                  </button>
                </div>
              </Form>
            )}
          />
        </div>
      </div>
    </div>
  );
}
