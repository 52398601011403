import * as React from 'react';
import * as propz from 'propz';
import { SchoolEvent } from '../../../../../../models/event';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NO_IMAGE } from '../../../../../../consts/common';
import { Photo } from '../../../../../../models/photo';
import { Video } from '../../../../../../models/video';
import { convertPointsToString, getIndividualScoreByStudent } from 'Src/helpers/event/schoolEventViewHelper';

interface Props {
  event: SchoolEvent;
  onClick: (id: string) => void;
  selectedUserId: string;
  photos: Photo[];
  videos: Video[];
}

export function ArbiterBoardPlayers(props: Props) {
  const { event, onClick, selectedUserId, photos, videos } = props;
  const { individualsData } = event;
  const { individualScore } = event.results;
  const studentsWithResultNoZero = [...individualScore]
    .map(score => {
      return individualsData.find(student => student.userId === score.userId);
    })
    .filter(student => {
      return typeof student !== 'undefined';
    })
    .filter(student => {
      const { userId, permissionId } = student;
      const points = getIndividualScoreByStudent(event, userId, permissionId);

      return points !== 0;
    })
    .reverse();

  const studentsWithoutResultOrZero = individualsData.filter(individualData => {
    const student = individualScore.find(score => score.userId === individualData.userId);
    const points = getIndividualScoreByStudent(event, individualData.userId, individualData.permissionId);

    return typeof student === 'undefined' || points === 0;
  });

  const allStudents = [...studentsWithResultNoZero, ...studentsWithoutResultOrZero];

  return (
    <div className={'container-fluid'}>
      {allStudents.map((student, index) => {
        const { userId, permissionId, firstName, lastName, avatar } = student;
        const isAvatarExist = typeof avatar !== 'undefined' && avatar !== '';
        const avatarSrc = isAvatarExist ? avatar : NO_IMAGE;
        const isSelected = userId === selectedUserId;

        const photosFiltered = photos.filter(photo => {
          const authorUserId = propz.get(photo, ['author', 'userId']);
          return authorUserId === userId;
        });

        const photosCount = photosFiltered.length;

        const videosFiltered = videos.filter(video => {
          const authorUserId = propz.get(video, ['author', 'userId']);
          return authorUserId === userId;
        });

        const videosCount = videosFiltered.length;

        const points = getIndividualScoreByStudent(event, userId, permissionId);
        const pointsStr = convertPointsToString(event, points);

        return (
          <div
            className={
              isSelected ? 'mArbiterBoardPlayerSectionActive eArbiterBoardPlayerSection' : 'eArbiterBoardPlayerSection'
            }
            style={{ cursor: 'pointer' }}
            key={userId}
            onClick={() => onClick(userId)}
          >
            <div className="col-md-8">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="eArbiterBoardPlayersName">{`${firstName} ${lastName}`}</div>
                    <div>{`Result: ${pointsStr}`}</div>
                  </div>
                  <div className="col-md-6">
                    <FontAwesomeIcon icon={'video'} />
                    {` ${videosCount}`}
                  </div>
                  <div className="col-md-6">
                    <FontAwesomeIcon icon={'camera'} />
                    {` ${photosCount}`}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex justify-content-center align-items-center">
              <div className="">
                <img className="eArbiterBoardPlayerAvatar" src={avatarSrc} alt={''} />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
