import * as React from 'react';
import { FunctionComponent } from 'react';
import { MessageDefinition } from '../../types/message';
import * as propz from 'propz';
import * as Moment from 'moment';
import { MESSAGE_KIND, MESSAGE_KIND_SERVER_TO_CLIENT_MAPPING } from '../../consts/message';
import { SchoolEvent } from '../../models/event';
import { ChildrenEventMainInformation } from '../../views/GenericView/ParentView/Events/MainInformation/MainInformation';
import { ClubInviteMainInformation } from './../ClubInviteMainInformation/ClubInviteMainInformation';
import { AppUser } from '../../views/App/App';

interface Props {
  message: MessageDefinition;
  schoolEvent?: SchoolEvent;
  user?: AppUser;
  eventName?: string;
}

export const MessageInboxOutboxSummary: FunctionComponent<Props> = props => {
  const { message, user, schoolEvent, eventName } = props;
  const isExistSchoolEvent = typeof schoolEvent !== 'undefined';

  const DEFAULT_ROWS = [
    { title: 'Type', field: 'type' },
    { title: 'Created at', field: 'createdAt' },
    { title: 'Sender', field: 'sender' },
    { title: 'Action set', field: 'isActionPerformedSetAt' }
  ];

  const CLUB_PARTICIPATION_INVITE_MESSAGE_ROWS = [
    ...DEFAULT_ROWS,
    { title: 'Club name', field: 'clubName' },
    { title: 'Start date', field: 'clubEventStartDate' },
    { title: 'Finish date', field: 'clubEventFinishDate' },
    { title: 'Start time', field: 'clubEventStartTime' },
    { title: 'Venue', field: 'clubEventVenue' },
    { title: 'Student', field: 'studentFullName' },
    { title: 'Status', field: 'invitationStatus' },
    { title: 'Status changed', field: 'invitationStatusSetAt' }
  ];

  const kind = message.kind;

  const isClubParticipantInviteMessage = kind === MESSAGE_KIND.ClubParticipantInviteMessage;

  let rowsForRender = [];

  switch (true) {
    case isClubParticipantInviteMessage:
      rowsForRender = CLUB_PARTICIPATION_INVITE_MESSAGE_ROWS;
      break;
    default:
      rowsForRender = DEFAULT_ROWS;
  }

  const rows = rowsForRender.map((row, index) => {
    let contentCell: string;
    const field = row.field;

    switch (field) {
      case 'studentFullName':
        const firstName = propz.get(message, ['playerDetailsData', 'firstName'], '');
        const lastName = propz.get(message, ['playerDetailsData', 'lastName'], '');
        contentCell = `${firstName} ${lastName}`;
        break;
      case 'type':
        contentCell = MESSAGE_KIND_SERVER_TO_CLIENT_MAPPING[message.kind];
        break;
      case 'createdAt':
        contentCell = message[row.field] ? Moment(message[row.field]).format('DD-MM-YYYY HH:mm') : '';
        break;
      case 'sender':
        contentCell = propz.get(message, ['sender', 'fullName'], '');
        break;
      case 'clubName':
        contentCell = propz.get(message, ['clubData', 'name'], '');
        break;
      case 'isActionPerformedSetAt':
        contentCell = message[row.field] ? Moment(message[row.field]).format('DD-MM-YYYY HH:mm') : '';
        break;
      case 'invitationStatusSetAt':
        contentCell = message[row.field] ? Moment(message[row.field]).format('DD-MM-YYYY HH:mm') : '';
        break;
      case 'clubEventStartDate':
        const clubEventStartDate = propz.get(message, ['clubData', 'startDate'], '');
        contentCell = Moment(clubEventStartDate).format('DD-MM-YYYY');
        break;
      case 'clubEventFinishDate':
        const clubEventFinishDate = propz.get(message, ['clubData', 'finishDate'], '');
        contentCell = Moment(clubEventFinishDate).format('DD-MM-YYYY');
        break;
      case 'clubEventStartTime':
        const clubEventStartTime = propz.get(message, ['clubData', 'time'], '');
        contentCell = Moment(clubEventStartTime).format('HH:mm');
        break;
      case 'clubEventVenue':
        contentCell = propz.get(message, ['clubData', 'venue', 'placeName'], '');
        break;

      default:
        contentCell = message[row.field];
    }

    return (
      <tr key={`message_summary_${index}`}>
        <td>{row.title}</td>
        <td>{contentCell}</td>
      </tr>
    );
  });

  return (
    <div>
      {isExistSchoolEvent && <ChildrenEventMainInformation user={user} event={schoolEvent} eventName={eventName} />}
      {isClubParticipantInviteMessage && <ClubInviteMainInformation user={user} message={message} />}
      <table className={'table table-sm mPre'}>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
};

MessageInboxOutboxSummary.displayName = 'MessageInboxOutboxSummary';
