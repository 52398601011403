import * as React from 'react';
import { Field, Form, Formik } from 'formik';
import { SchoolEvent } from 'Src/models/event';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING, REPORT_PRINT_FORMAT } from 'Src/consts/report';
import { isTeamSportEvent, isIndividualSportEvent } from 'Src/helpers/event/event';
import { Switch } from 'Src/components/Switch/Switch';
import { LabelWithQuestionIcon } from 'Src/components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import { SelectField } from 'Src/components/SelectField/SelectField';
import '../../SchoolEventView.scss';

interface Props {
  event: SchoolEvent;
  activeSchoolId: string;
  onCloseClick: () => void;
  onSubmit: (data: any, reportType: string) => void;
}

//report 017
export function BasicTeamSheetReportForm({ onCloseClick, onSubmit, event, activeSchoolId }: Props) {
  const { embeddedTeams, id: eventId } = event;

  const embeddedTeamsForActiveSchool = embeddedTeams.filter(team => team.schoolId === activeSchoolId);

  const isEmbeddedTeamsExist = embeddedTeams.length > 0;

  const getSelectOptionsForTeams = () => {
    return embeddedTeamsForActiveSchool.map(team => {
      return {
        text: team.name,
        value: team._id
      };
    });
  };

  const teamOptions = getSelectOptionsForTeams();

  const firstTeamValue = isEmbeddedTeamsExist ? teamOptions[0].value : undefined;

  const requestInitial = {
    team: firstTeamValue,
    isPrintFullNames: true,
    isPrintPhotoConsentOptOut: false,
    isPrintDescription: true,
    isPrintKitNotes: true,
    isPrintComments: true,
    isPrintTransportDetails: true,
    isPrintOppositionContactDetails: false,
    isPrintMembersOfStaff: true,
    isPrintEventTimings: true,
    printFormat: REPORT_PRINT_FORMAT.PDF
  };

  return (
    <Formik
      onSubmit={values => {
        const {
          team,
          isPrintFullNames,
          isPrintPhotoConsentOptOut,
          isPrintDescription,
          isPrintKitNotes,
          isPrintComments,
          isPrintTransportDetails,
          isPrintOppositionContactDetails,
          isPrintMembersOfStaff,
          isPrintEventTimings,
          printFormat
        } = values;

        let isPrintXLSX = false;
        let isPrintPDF = false;

        switch (printFormat) {
          case REPORT_PRINT_FORMAT.XLSX:
            isPrintXLSX = true;
            break;
          case REPORT_PRINT_FORMAT.PDF:
            isPrintPDF = true;
            break;
        }

        let reportType = '';

        switch (true) {
          case isTeamSportEvent(event):
            reportType = REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.BASIC_TEAM_SHEET;

            break;

          case isIndividualSportEvent(event):
            reportType = REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.BASIC_TEAM_SHEET_INDIVIDUAL;

            break;
        }

        const data = {
          schoolId: activeSchoolId,
          eventId: eventId,
          teamId: team,
          isPrintFullNames,
          isPrintPhotoConsentOptOut,
          isPrintDescription,
          isPrintKitNotes,
          isPrintComments,
          isPrintTransportDetails,
          isPrintOppositionContactDetails,
          isPrintMembersOfStaff,
          isPrintEventTimings,
          isPrintXLSX,
          isPrintPDF
        };

        onSubmit(data, reportType);
      }}
      initialValues={requestInitial}
      render={({ values, setFieldValue }) => (
        <Form>
          <div className="mb-3 mt-3">
            {'A report that enables you to see basic information about team participants. If you are using this team sheet ' +
              ' for putting it on the school board you may choose to hide full student surnames.'}
          </div>

          {isEmbeddedTeamsExist && (
            <div className="form-check mb-4">
              <div className="d-flex align-items-center">
                <Field
                  name="Teams"
                  render={({ field }) => (
                    <SelectField
                      onChange={event => setFieldValue('team', event.target.value)}
                      field={field}
                      items={teamOptions}
                      value={values.team}
                      customClass={'form-control'}
                    />
                  )}
                />
                <label className="form-check-label pl-2" htmlFor="Teams">
                  Team
                </label>
              </div>
            </div>
          )}

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isPrintFullNames"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintFullNames}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintFullNames = event.target.checked;
                      setFieldValue('isPrintFullNames', isPrintFullNames);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show full names"
                hintText={`Select if you would like full student names to be printed. When unselected only first name and first letter of the surname will be shown.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintFullNames"
              />
            </div>
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isPrintPhotoConsentOptOut"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintPhotoConsentOptOut}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintPhotoConsentOptOut = event.target.checked;
                      setFieldValue('isPrintPhotoConsentOptOut', isPrintPhotoConsentOptOut);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show photo consent opt-out"
                hintText={`Select if you would like students with photo consent withdrawn to be marked.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintPhotoConsentOptOut"
              />
            </div>
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isPrintDescription"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintDescription}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintDescription = event.target.checked;
                      setFieldValue('isPrintDescription', isPrintDescription);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show event description"
                hintText={`Show the description you have assigned to your event.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintDescription"
              />
            </div>
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isPrintKitNotes"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintKitNotes}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintKitNotes = event.target.checked;
                      setFieldValue('isPrintKitNotes', isPrintKitNotes);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show kit notes"
                hintText={`If you have added kit notes to your event these can be shown on your teamsheet.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintKitNotes"
              />
            </div>
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isPrintComments"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintComments}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintComments = event.target.checked;
                      setFieldValue('isPrintComments', isPrintComments);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show event comments"
                hintText={`If you have added comments to your event these can be shown on your teamsheet.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintComments"
              />
            </div>
          </div>
          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isPrintTransportDetails"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintTransportDetails}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintTransportDetails = event.target.checked;
                      setFieldValue('isPrintTransportDetails', isPrintTransportDetails);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show transport details"
                hintText={`If you have added transport details to your event these can be shown on your teamsheet.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintTransportDetails"
              />
            </div>
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isPrintOppositionContactDetails"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintOppositionContactDetails}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintOppositionContactDetails = event.target.checked;
                      setFieldValue('isPrintOppositionContactDetails', isPrintOppositionContactDetails);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show opposition contact details"
                hintText={`If you have added opposition contact details to your event these can be shown on your teamsheet.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintOppositionContactDetails"
              />
            </div>
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isPrintMembersOfStaff"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintMembersOfStaff}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintMembersOfStaff = event.target.checked;
                      setFieldValue('isPrintMembersOfStaff', isPrintMembersOfStaff);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show the coach and members of staff names"
                hintText={`View the names of the coach and members of staff assigned to the event.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintMembersOfStaff"
              />
            </div>
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isPrintEventTimings"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintEventTimings}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintEventTimings = event.target.checked;
                      setFieldValue('isPrintEventTimings', isPrintEventTimings);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show event timings"
                hintText={`If you've added timings (e.g., meet time, departure time) to your event details, they will be displayed here.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintEventTimings"
              />
            </div>
          </div>

          <div className="form-check">
            <Field name="printFormat">
              {({ field }) => (
                <>
                  <div className="d-flex">
                    <input
                      type="radio"
                      id="isPrintXLSX"
                      {...field}
                      checked={field.value === REPORT_PRINT_FORMAT.XLSX}
                      onChange={() => setFieldValue('printFormat', REPORT_PRINT_FORMAT.XLSX)}
                    />
                    <label htmlFor="isPrintXLSX" className="pl-2">
                      Print as XLSX
                    </label>
                  </div>

                  <div className="d-flex">
                    <input
                      type="radio"
                      id="isPrintPDF"
                      {...field}
                      checked={field.value === REPORT_PRINT_FORMAT.PDF}
                      onChange={() => setFieldValue('printFormat', REPORT_PRINT_FORMAT.PDF)}
                    />
                    <label htmlFor="isPrintPDF" className="pl-2">
                      Print as PDF
                    </label>
                  </div>
                </>
              )}
            </Field>
          </div>

          <button type="button" className="btn btn-secondary mr-3" onClick={onCloseClick}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Get
          </button>
        </Form>
      )}
    />
  );
}
