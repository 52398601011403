import * as React from 'react';
import { Field, Form, Formik } from 'formik';
import DatePicker from 'react-datepicker';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from '../../../../../../../consts/report';
import { Switch } from '../../../../../../../components/Switch/Switch';
import { LabelWithQuestionIcon } from '../../../../../../../components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import { getStartDateForSchoolYear } from '../../../../../../../helpers/calendar/calendar';
import '../../Clubs.css';

interface Props {
  onCloseClick: () => void;
  onSubmit: (data: any, reportType: string) => void;
  activeSchoolId: string;
}

// report 006
export const ParticipationsByClubsYearGroupsReportForm = ({ onCloseClick, onSubmit, activeSchoolId }: Props) => {
  const now = new Date();
  let dateFrom = getStartDateForSchoolYear(now);

  const [schoolData, setSchoolData] = React.useState<any>(null);

  React.useEffect(() => {
    fetch('/dist/images/mis/misIntegrationSchoolsList.json')
      .then(response => response.json())
      .then(data => {
        const matchedSchoolData = data.find(item => item.schoolSITId === activeSchoolId);
        setSchoolData(matchedSchoolData);
      })
      .catch(error => console.error('Error loading JSON:', error));
  }, [activeSchoolId]);

  const requestInitial = {
    dateFrom,
    dateTo: now,
    isPrintCapacity: true,
    isPrintPP: schoolData ? schoolData.studentsPPInfo === 1 : false,
    isPrintSEN: schoolData ? schoolData.studentsSENInfo === 1 : false,
    isPrintFSM: schoolData ? schoolData.studentsFSMInfo === 1 : false,
    isPrintEthnicity: schoolData ? schoolData.studentsEthnicityCode === 1 : false,
    isPrintEligible: true,
    isPrintPercent: true
  };

  return (
    <Formik
      onSubmit={values =>
        onSubmit(values, REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.PARTICIPATION_STATISTICS_BY_CLUBS_YEAR_GROUPS)
      }
      initialValues={requestInitial}
      render={({ values, setFieldValue }) => (
        <Form>
          <div className="bReportFormTitle">Involvement statistics by students</div>
          <div className="mb-3 mt-3">
            {'View participation information as both totals and percentages across all areas. ' +
              'You can view data for PP, SEN, the number of students eligible to take part and well as percentages.'}
          </div>
          <div className="form-group">
            <label>Date from</label>
            <Field name="dateFrom">
              {({ field, form }) => (
                <div className="mb-3">
                  <DatePicker
                    {...field}
                    selected={field.value}
                    onChange={date => {
                      form.setFieldValue('dateFrom', date);
                      if (form.values.dateTo && form.values.dateTo < date) {
                        form.setFieldValue('dateTo', date);
                      }
                    }}
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
              )}
            </Field>
          </div>
          <div className="form-group">
            <label>Date to</label>
            <Field name="dateTo">
              {({ field, form }) => (
                <div className="mb-3">
                  <DatePicker
                    {...field}
                    selected={field.value}
                    onChange={date => {
                      form.setFieldValue('dateTo', date);
                    }}
                    minDate={form.values.dateFrom}
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
              )}
            </Field>
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isPrintCapacity"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintCapacity}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintCapacity = event.target.checked;
                      setFieldValue('isPrintCapacity', isPrintCapacity);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show club capacity"
                hintText={'See data relating to the maximum number of club participants.'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintCapacity"
              />
            </div>
          </div>

          {schoolData && schoolData.studentsPPInfo === 1 && (
            <div className="form-check">
              <div className="d-flex">
                <Field
                  name="isPrintPP"
                  render={({ field }) => (
                    <Switch
                      {...field}
                      value={values.isPrintPP}
                      customClass="mb-3"
                      onChange={event => {
                        const isPrintPP = event.target.checked;
                        setFieldValue('isPrintPP', isPrintPP);
                      }}
                    />
                  )}
                />
                <LabelWithQuestionIcon
                  labelText="Show PP"
                  hintText={'See data relating to PP.'}
                  customLabelClass="form-check-label pl-2"
                  labelHtmlFor="isPrintPP"
                />
              </div>
            </div>
          )}

          {schoolData && schoolData.studentsSENInfo === 1 && (
            <div className="form-check">
              <div className="d-flex">
                <Field
                  name="isPrintSEN"
                  render={({ field }) => (
                    <Switch
                      {...field}
                      value={values.isPrintSEN}
                      customClass="mb-3"
                      onChange={event => {
                        const isPrintSEN = event.target.checked;
                        setFieldValue('isPrintSEN', isPrintSEN);
                      }}
                    />
                  )}
                />
                <LabelWithQuestionIcon
                  labelText="Show SEN"
                  hintText={'See data relating to SEN.'}
                  customLabelClass="form-check-label pl-2"
                  labelHtmlFor="isPrintSEN"
                />
              </div>
            </div>
          )}

          {schoolData && schoolData.studentsFSMInfo === 1 && (
            <div className="form-check">
              <div className="d-flex">
                <Field
                  name="isPrintFSM"
                  render={({ field }) => (
                    <Switch
                      {...field}
                      value={values.isPrintFSM}
                      customClass="mb-3"
                      onChange={event => {
                        const isPrintFSM = event.target.checked;
                        setFieldValue('isPrintFSM', isPrintFSM);
                      }}
                    />
                  )}
                />
                <LabelWithQuestionIcon
                  labelText="Show FSM"
                  hintText={'See data relating to FSM.'}
                  customLabelClass="form-check-label pl-2"
                  labelHtmlFor="isPrintFSM"
                />
              </div>
            </div>
          )}

          {schoolData && schoolData.studentsEthnicityCode === 1 && (
            <div className="form-check">
              <div className="d-flex">
                <Field
                  name="isPrintEthnicity"
                  render={({ field }) => (
                    <Switch
                      {...field}
                      value={values.isPrintEthnicity}
                      customClass="mb-3"
                      onChange={event => {
                        const isPrintEthnicity = event.target.checked;
                        setFieldValue('isPrintEthnicity', isPrintEthnicity);
                      }}
                    />
                  )}
                />
                <LabelWithQuestionIcon
                  labelText="Breakdown by ethnicity codes"
                  hintText={''}
                  customLabelClass="form-check-label pl-2"
                  labelHtmlFor="isPrintEthnicity"
                />
              </div>
            </div>
          )}

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isPrintEligible"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintEligible}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintEligible = event.target.checked;
                      setFieldValue('isPrintEligible', isPrintEligible);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show eligible students"
                hintText={'See data relating to students eligible for the club.'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintEligible"
              />
            </div>
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isPrintPercent"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintPercent}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintPercent = event.target.checked;
                      setFieldValue('isPrintPercent', isPrintPercent);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show percentages"
                hintText={'See data as a percentage.'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintPercent"
              />
            </div>
          </div>

          <button type="button" className="btn btn-secondary mr-3" onClick={onCloseClick}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Get
          </button>
        </Form>
      )}
    />
  );
};
