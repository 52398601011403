import axios from 'axios';
import { AppUser } from 'Src/views/App/App';
import { DEFAULT_FILTER } from './user';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from 'Src/consts/table';
import { Count } from '../../../models/count';

export function getSubscriptions(user: AppUser, filter = DEFAULT_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter
    }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/subscriptions`, config).then(response => {
    return response.data;
  });
}

export function getAllSubscriptions(user: AppUser, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/subscriptions`, config).then(response => {
    return response.data;
  });
}

export function getSubscriptionsCount(user: AppUser, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where
        }
      }
    };
  } else {
    config = {
      headers: { usid: key }
    };
  }

  return axios
    .get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/subscriptions/count`, config)
    .then(response => {
      return response.data;
    });
}

export function getSubscriptionStatistics(user: AppUser, subscriptionId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/subscriptions/${subscriptionId}/statistics`, config)
    .then(response => {
      return response.data;
    });
}

export function getSubscriptionStatement(
  user: AppUser,
  subscriptionId: string,
  dateFrom: Date,
  dateTo: Date,
  includeText: boolean
) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    responseType: 'blob',
    params: {
      filter: {
        where: {
          includeText: includeText,
          dateSentBefore: dateTo,
          dateSentAfter: dateFrom
        }
      }
    }
  };
  // const url = `${ window.apiBase }/i/schools/${ activeSchoolId }/subscriptions/${ subscriptionId }/statement`;
  // window.open(url, '_blank', config);
  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/subscriptions/${subscriptionId}/statement`, config)
    .then(response => {
      return response;
    });
}
