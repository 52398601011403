import * as React from 'react';
import * as propz from 'propz';
import { FunctionComponent } from 'react';
import * as Moment from 'moment';
import { AppUser } from 'Src/views/App/App';
import { getVenueView } from 'Src/helpers/venue/venue';
import { CLUB_STAFF } from 'Src/consts/club';
import { SchoolEvent } from 'Src/models/event';
import { isClubEvent } from 'Src/helpers/event/event';
import { getSchoolEventLocation } from 'Src/helpers/event/schoolEventViewHelper';
import { DEFAULT_TIME_CONTENT_CELL } from 'Src/consts/date';
import './DetailsTab.css';
import { getEventNameForParent } from '../../../../../../helpers/accessor/accessor';

interface Props {
  schoolEvent: SchoolEvent;
  user: AppUser;
  eventsDetails: any;
  eventName: string;
}

const EVENT_ROWS = [
  { title: 'Name', field: 'name' },
  { title: 'Venue', field: 'venue' },
  { title: 'Event Description', field: 'eventDescription' },
  { title: 'Short Event Description', field: 'shortDescription' },
  { title: 'Kit notes', field: 'kitNotes' },
  { title: 'Comments', field: 'comments' },
  { title: 'Transport', field: 'transportDetails' },
  { title: 'Members of staff', field: 'membersOfStaff' },
  { title: 'Coach', field: 'coaches' },
  { title: 'Team departs', field: 'teamDeparts' },
  { title: 'Team returns', field: 'teamReturns' },
  { title: 'Meet time', field: 'meetTime' },
  { title: 'Tea time', field: 'teaTime' },
  { title: 'Lunch time', field: 'lunchTime' },
  { title: 'Sport description', field: 'sportDescription' }
];

const CLUB_EVENT_ROWS = [
  { title: 'Name', field: 'name' },
  { title: 'Activity', field: 'activity' },
  { title: 'Location', field: 'location' },
  { title: 'Event Description', field: 'eventDescription' },
  { title: 'Short Event Description', field: 'shortDescription' },
  { title: 'Clothing/Kit notes', field: 'kitNotes' },
  { title: 'Comments', field: 'comments' },
  { title: 'Leader/Coach', field: 'coaches' },
  { title: 'Members of staff', field: 'membersOfStaff' },
  { title: 'Group departs', field: 'teamDeparts' },
  { title: 'Group returns', field: 'teamReturns' },
  { title: 'Meet time', field: 'meetTime' },
  { title: 'Tea time', field: 'teaTime' },
  { title: 'Lunch time', field: 'lunchTime' },
  { title: 'Sport description', field: 'sportDescription' }
];

export const ChildrenEventDetailsSummaryTab: FunctionComponent<Props> = props => {
  const { eventsDetails, schoolEvent, eventName, user } = props;
  const ROWS = isClubEvent(schoolEvent) ? [...CLUB_EVENT_ROWS] : [...EVENT_ROWS];

  const rows = ROWS.map((row, index) => {
    let contentCell: any;
    const field = row.field;
    const defaultDate = new Date();
    defaultDate.setHours(0);
    defaultDate.setMinutes(0);
    const location = propz.get(schoolEvent, ['location'], '');
    const isEventsDetailsExist = typeof eventsDetails !== 'undefined';
    const { activeSchoolId } = user;

    switch (field) {
      case 'name':
        contentCell = eventName;
        break;
      case 'activity':
        contentCell = propz.get(schoolEvent, ['sport', 'name'], '');
        break;
      case 'sportDescription':
        contentCell = propz.get(schoolEvent, ['sport', 'description'], '');
        break;
      case 'venue':
        contentCell = getVenueView(schoolEvent, activeSchoolId);
        break;
      case 'location':
        contentCell =
          location !== ''
            ? `${getSchoolEventLocation(schoolEvent, user)},  ${location}`
            : getSchoolEventLocation(schoolEvent, user);
        break;
      case 'eventDescription':
        const description = propz.get(eventsDetails, ['description'], '');
        contentCell = <div dangerouslySetInnerHTML={{ __html: description }} />;
        break;
      case 'shortDescription':
        contentCell = propz.get(eventsDetails, ['shortDescription'], '');
        break;
      case 'kitNotes':
        const kitNotes = propz.get(eventsDetails, ['kitNotes'], '');
        contentCell = <div dangerouslySetInnerHTML={{ __html: kitNotes }} />;
        break;
      case 'comments':
        const comments = propz.get(eventsDetails, ['comments'], '');
        contentCell = <div dangerouslySetInnerHTML={{ __html: comments }} />;
        break;
      case 'transportDetails':
        const transportDetails = propz.get(eventsDetails, ['transportDetails'], '');
        contentCell = <div dangerouslySetInnerHTML={{ __html: transportDetails }} />;
        break;
      case 'membersOfStaff':
        const staffs = propz
          .get(eventsDetails, ['staff'], [])
          .filter(staff => staff.staffRole === CLUB_STAFF.MEMBER_OF_STAFF);

        contentCell = staffs
          .map(staff => {
            const staffFirstName = propz.get(staff, ['firstName'], '');
            const staffLastName = propz.get(staff, ['lastName'], '');
            return `${staffFirstName} ${staffLastName}`;
          })
          .join('\n');
        break;
      case 'coaches':
        const coaches = propz.get(eventsDetails, ['staff'], []).filter(coach => coach.staffRole === CLUB_STAFF.COACH);

        contentCell = coaches
          .map(coach => {
            const coachFirstName = propz.get(coach, ['firstName'], '');
            const coachLastName = propz.get(coach, ['lastName'], '');
            return `${coachFirstName} ${coachLastName}`;
          })
          .join('\n');
        break;
      case 'teamDeparts':
        if (isEventsDetailsExist) {
          const departsDate = new Date(propz.get(eventsDetails, ['teamDeparts'], defaultDate));
          const departsTime = Moment(departsDate, 'hh:mm A').format('hh:mm A');
          contentCell = typeof eventsDetails.teamDeparts !== 'undefined' ? departsTime : DEFAULT_TIME_CONTENT_CELL;
        } else {
          contentCell = DEFAULT_TIME_CONTENT_CELL;
        }
        break;
      case 'teamReturns':
        if (isEventsDetailsExist) {
          const returnsDate = new Date(propz.get(eventsDetails, ['teamReturns'], defaultDate));
          const returnsTime = Moment(returnsDate, 'hh:mm A').format('hh:mm A');
          contentCell = typeof eventsDetails.teamReturns !== 'undefined' ? returnsTime : DEFAULT_TIME_CONTENT_CELL;
        } else {
          contentCell = DEFAULT_TIME_CONTENT_CELL;
        }
        break;
      case 'meetTime':
        if (isEventsDetailsExist) {
          const meetDate = new Date(propz.get(eventsDetails, ['meetTime'], defaultDate));
          const meetTime = Moment(meetDate, 'hh:mm A').format('hh:mm A');
          contentCell = typeof eventsDetails.meetTime !== 'undefined' ? meetTime : DEFAULT_TIME_CONTENT_CELL;
        } else {
          contentCell = DEFAULT_TIME_CONTENT_CELL;
        }
        break;
      case 'teaTime':
        if (isEventsDetailsExist) {
          const teaDate = new Date(propz.get(eventsDetails, ['teaTime'], defaultDate));
          const teaTime = Moment(teaDate, 'hh:mm A').format('hh:mm A');
          contentCell = typeof eventsDetails.teaTime !== 'undefined' ? teaTime : DEFAULT_TIME_CONTENT_CELL;
        } else {
          contentCell = DEFAULT_TIME_CONTENT_CELL;
        }
        break;
      case 'lunchTime':
        if (isEventsDetailsExist) {
          const lunchDate = new Date(propz.get(eventsDetails, ['lunchTime'], defaultDate));
          const lunchTime = Moment(lunchDate, 'hh:mm A').format('hh:mm A');
          contentCell = typeof eventsDetails.lunchTime !== 'undefined' ? lunchTime : DEFAULT_TIME_CONTENT_CELL;
          break;
        } else {
          contentCell = DEFAULT_TIME_CONTENT_CELL;
        }
        break;
      default:
        contentCell = schoolEvent[row.field];
    }

    return (
      <tr key={`event_details_summary_${index}`}>
        <td className={'w-25'}>{row.title}</td>
        <td className={'w-75 mPreWrap'}>{contentCell}</td>
      </tr>
    );
  });

  return (
    <table className={'table table-sm mPre'}>
      <tbody>{rows}</tbody>
    </table>
  );
};

ChildrenEventDetailsSummaryTab.displayName = 'ChildrenEventDetailsSummaryTab';
