import * as React from 'react';
import { FunctionComponent } from 'react';
import { SelectOption } from '../../helpers/table/table';

interface Props {
  items: SelectOption[];
  onChange: (values) => void;
  value: string[];
}

export const Multiselect: FunctionComponent<Props> = props => {
  const options = props.items.map(item => {
    return (
      <option key={item.value} value={item.value}>
        {item.text}
      </option>
    );
  });

  const onChange = event => {
    const options = event.target.options;
    const values = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        values.push(options[i].value);
      }
    }

    props.onChange(values);
  };

  return (
    <select multiple={true} value={props.value} className="form-control mb-3" onChange={onChange}>
      {options}
    </select>
  );
};

Multiselect.displayName = 'Multiselect';
