import * as React from 'react';
import * as propz from 'propz';

import { getEventNameWithLink } from 'Src/helpers/cell/cell';
import {
  getCoachEvent,
  getEventAges,
  getEventGender,
  getEventStartTime,
  getEventStatus,
  getEventSubtype,
  getEventType,
  getEventVenue,
  getEventVenueType,
  getStaffEvent
} from 'Src/helpers/accessor/accessor';
import { AppUser } from 'Src/views/App/App';
import { SchoolEvent } from 'Src/models/event';

interface Props {
  leagueEvents: SchoolEvent[];
  user: AppUser;
}

export function SchoolLeagueEventsSummary(props: Props) {
  const { leagueEvents, user } = props;
  const options = {
    user
  };

  const rows = leagueEvents.map((event, index) => {
    const eventName = getEventNameWithLink(event, options);
    const startTime = getEventStartTime(event);
    const eventType = getEventType(event);
    const eventStatus = getEventStatus(event);
    const eventSport = propz.get(event, ['sport', 'name'], '');
    const eventAges = getEventAges(event);
    const eventGender = getEventGender(event);
    const venueType = getEventVenueType(event);
    const venue = getEventVenue(event);
    const coach = getCoachEvent(event);
    const staff = getStaffEvent(event);
    const subtype = getEventSubtype(event);

    return (
      <tr key={`event_summary_${index}`}>
        <td>{index + 1}</td>
        {eventName}
        <td>{startTime}</td>
        <td>{eventType}</td>
        <td>{eventStatus}</td>
        <td>{eventSport}</td>
        <td>{eventAges}</td>
        <td>{eventGender}</td>
        <td>{venueType}</td>
        <td>{venue}</td>
        <td>{coach}</td>
        <td>{staff}</td>
        <td>{subtype}</td>
        <td />
      </tr>
    );
  });

  const emptyRow = (
    <tr key={'events_empty_row'}>
      <td>No events</td>
    </tr>
  );

  return (
    <table className={'table table-sm mPre'}>
      <thead>
        <tr>
          <th>#</th>
          <th>Event</th>
          <th>Start time</th>
          <th>Kind</th>
          <th>Status</th>
          <th>Sport</th>
          <th>Age</th>
          <th>Gender</th>
          <th>Home/Away</th>
          <th>Venue</th>
          <th>Coach/Leader</th>
          <th>Staff</th>
          <th>Type</th>
          <th />
        </tr>
      </thead>
      <tbody>{rows.length > 0 ? rows : emptyRow}</tbody>
    </table>
  );
}
