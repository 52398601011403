import * as React from 'react';
import { History } from 'history';
import './SchoolEventsCreateAnotherEventAlert.scss';

interface Props {
  history: History;
  onCreateAnotherEventAlertCloseClick: () => void;
}

export function SchoolEventsCreateAnotherEventAlert(props: Props) {
  const { onCreateAnotherEventAlertCloseClick } = props;

  const onCreateEventClick = event => {
    event.preventDefault();

    props.history.push({
      pathname: '/events/manager'
    });
  };

  return (
    <div className="col-md-12">
      <div className="bCreateOtherEventPanel">
        <div className="eCreateOtherEventPanel_left">
          {'You have successfully created an event. '}
          <a
            className="eCreateOtherEventPanel_link"
            onClick={e => {
              onCreateEventClick(e);
            }}
          >
            Add another event
          </a>
        </div>
        <div className="eCreateOtherEventPanel_right">
          <div
            className="eCreateOtherEventPanel_cross"
            onClick={() => {
              onCreateAnotherEventAlertCloseClick();
            }}
          >
            x
          </div>
        </div>
      </div>
    </div>
  );
}
