import * as React from 'react';
import { Field, Form, Formik } from 'formik';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/report';
import { Switch } from 'Src/components/Switch/Switch';
import { LabelWithQuestionIcon } from 'Src/components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import '../../SchoolEventView.scss';

interface Props {
  onCloseClick: () => void;
  onSubmit: (data: any, reportType: string) => void;
  activeSchoolId: string;
}

//report 009
export const EventConsentRequestsReportForm = ({ onCloseClick, onSubmit, activeSchoolId }: Props) => {
  const [schoolData, setSchoolData] = React.useState<any>(null);

  React.useEffect(() => {
    fetch('/dist/images/mis/misIntegrationSchoolsList.json')
      .then(response => response.json())
      .then(data => {
        const matchedSchoolData = data.find(item => item.schoolSITId === activeSchoolId);
        setSchoolData(matchedSchoolData);
      })
      .catch(error => console.error('Error loading JSON:', error));
  }, [activeSchoolId]);
  const requestInitial = {
    isPrintMedicalInfo: schoolData ? schoolData.studentsMedicalInfo === 1 : false,
    isPrintNextOfKin: true
  };

  return (
    <Formik
      onSubmit={values => onSubmit(values, REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.EVENT_CONSENT_REQUESTS)}
      initialValues={requestInitial}
      render={({ values, setFieldValue }) => (
        <Form>
          <div className="bReportFormTitle">'Consent requests summary'</div>
          <div className="mb-3 mt-3">
            {'Within this report you can extract all of the information from consent requests, ' +
              'view the status of the response, answers to any additional questions asked as well ' +
              'as details of medical information and emergency contact if needed.'}
          </div>

          {schoolData && schoolData.studentsMedicalInfo === 1 && (
            <div className="form-check">
              <div className="d-flex">
                <Field
                  name="isPrintMedicalInfo"
                  render={({ field }) => (
                    <Switch
                      {...field}
                      value={values.isPrintMedicalInfo}
                      customClass="mb-3"
                      onChange={event => {
                        const isPrintMedicalInfo = event.target.checked;
                        setFieldValue('isPrintMedicalInfo', isPrintMedicalInfo);
                      }}
                    />
                  )}
                />
                <LabelWithQuestionIcon
                  labelText="Show medical info"
                  hintText={'Show any medical information relating to students.'}
                  customLabelClass="form-check-label pl-2"
                  labelHtmlFor="isPrintMedicalInfo"
                />
              </div>
            </div>
          )}

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isPrintNextOfKin"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintNextOfKin}
                    customClass="mb-3"
                    onChange={event => {
                      const isPrintNextOfKin = event.target.checked;
                      setFieldValue('isPrintNextOfKin', isPrintNextOfKin);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show next of kin details"
                hintText={'Show emergency contact details relating to specific students.'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintNextOfKin"
              />
            </div>
          </div>

          <button type="button" className="btn btn-secondary mr-3" onClick={onCloseClick}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Get
          </button>
        </Form>
      )}
    />
  );
};
