import * as React from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { AppUser } from 'Src/views/App/App';
import { Button } from '../../../../../components/Button/Button';
import './EmailChangeForm.scss';
import { checkAvailability } from '../../../../../helpers/service/public/register';

interface Props {
  email: string;
  onSubmit: (data: any) => void;
  onCancel: () => void;
}

const EmailSchema = Yup.object().shape({
  email: Yup.string()
    .required('Required')
    .email('Must be valid email')
    .test('email', 'Duplicate email', value =>
      checkAvailability({ email: value }).then(res => {
        return res.isAvailable;
      })
    )
});

export function EmailChangeForm(props: Props) {
  const { onSubmit, onCancel, email } = props;

  return (
    <Formik
      initialValues={{ email }}
      validationSchema={EmailSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, touched, errors }) => (
        <Form>
          <div className="form-group form-check">
            <div className="form-group">
              <label className="eEmailChangeFormTitle">Enter your email address</label>
              <Field name="email" className="form-control mb-3" />

              {touched.email && errors.email && <div className="eEmailChangeFormError">{errors.email}</div>}
            </div>

            <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
            <Button onClick={handleSubmit} text={'Save'} customClass={'mt-3 mb-3 btn btn-primary'} type={'submit'} />
          </div>
        </Form>
      )}
    </Formik>
  );
}
