import * as React from 'react';
import * as Lazy from 'lazy.js';
import { Component } from 'react';
import * as BPromise from 'bluebird';
import * as propz from 'propz';
import { History, Location } from 'history';
import { parse } from 'query-string';
import { TwoPanelEditor } from 'Src/components/TwoPanelEditor/TwoPanelEditor';
import { Loader } from 'Src/components/Loader/Loader';
import { DATE_INTERVAL, DEFAULT_LIMIT, DEFAULT_SKIP, FILTER_TYPE, LIMIT } from 'Src/consts/table';
import { getTwoPanelEditorFilters } from 'Src/helpers/twoPanelEditor/twoPanelEditor';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import {
  ColumnDefinition,
  getOrder,
  getServerFieldSectionWhere,
  isFilterExist2,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { Button } from 'Src/components/Button/Button';
import { SchoolTournamentTeamPlayersBatchData } from 'Src/helpers/service/admin/tournamentTeamPlayers';
import {
  getAllSchoolTeamPlayers,
  getSchoolStudentsWithClashesCount,
  getSchoolStudentsWithoutClashes,
  getSchoolTeamPlayersCount,
  updateSchoolTeamPlayersBatch
} from 'Src/helpers/service/admin/schoolTeamPlayers';
import { AppUser } from 'Src/views/App/App';
import { SchoolForm } from 'Src/models/form';
import { getAge, getGender } from 'Src/helpers/accessor/accessor';
import { getAllForms } from 'Src/helpers/service/admin/forms';
import { getUserGenderArrayConvertedFromEventGender } from 'Src/helpers/tournament/tournament';
import {
  getSelectOptionForAge,
  getSelectOptionForForms,
  getSelectOptionForGender,
  getSelectOptionForHouses
} from 'Src/helpers/table/select';
import { getAllHouses } from 'Src/helpers/service/admin/houses';
import { getSchoolTeam } from '../../../../../helpers/service/admin/schoolTeams';
import { Team, TeamPlayer } from '../../../../../models/team';
import { SchoolHouse } from '../../../../../models/house';
import { AGE_GROUPS } from '../../../../../consts/school';
interface Props {
  user: AppUser;
  history: History;
  location: Location;
}
interface State {
  items: Item[];
  itemsSelected: Item[];
  itemsCount: number;

  itemsFilters: any;
  isShowItemsFilter: boolean;
  isItemsFiltered: boolean;

  isExitConfirmationModalOpen: boolean;

  isSelectAllItemsChecked: boolean;

  sortItemsDirection: TABLE_SORT_DIRECTION;
  sortItemsColumnsName: string;

  itemCurrentPage: number;

  itemsAccumulatorInitial: Item[];
  itemsAccumulator: Item[];
  itemsAccumulatorSelected: Item[];
  itemsAccumulatorCount: number;

  itemsAccumulatorFilters: any;
  isShowItemsAccumulatorFilter: boolean;
  isItemsAccumulatorFiltered: boolean;

  isSelectAllItemsAccumulatorChecked: boolean;

  sortItemsAccumulatorDirection: TABLE_SORT_DIRECTION;
  sortItemsAccumulatorColumnsName: string;

  team: Team;
  forms: SchoolForm[];
  houses: SchoolHouse[];

  isLoading: boolean;

  itemsAddBuffer: Item[];
  itemsRemoveBuffer: Item[];
}

const ITEMS_ACCUMULATOR_COLUMNS: ColumnDefinition[] = [
  {
    text: 'Surname',
    field: 'lastName',
    accessor: ['lastName'],
    type: FILTER_TYPE.NONE,
    isSort: false
  },
  {
    text: 'Name',
    field: 'firstName',
    accessor: ['firstName'],
    type: FILTER_TYPE.NONE,
    isSort: false
  },
  {
    text: 'Form',
    field: 'forms',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: ['form', 'name']
  },
  {
    text: 'House',
    field: 'house',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: ['house', 'name']
  },
  {
    text: 'Age',
    field: 'ages',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getAge
  },
  {
    text: 'Gender',
    field: 'gender',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getGender
  }
];

const ITEMS_COLUMNS: ColumnDefinition[] = [
  {
    text: 'Surname',
    field: 'lastName',
    accessor: ['lastName'],
    type: FILTER_TYPE.TEXT,
    isSort: false
  },
  {
    text: 'Name',
    field: 'firstName',
    accessor: ['firstName'],
    type: FILTER_TYPE.TEXT,
    isSort: false
  },
  {
    text: 'Form',
    field: 'forms',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: ['form', 'name']
  },
  {
    text: 'House',
    field: 'houses',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: ['house', 'name']
  },
  {
    text: 'Age',
    field: 'ages',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getAge
  },
  {
    text: 'Gender',
    field: 'gender',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getGender
  }
];

interface Item {
  permissionId: string;
  form: any;
  house: any;
  gender: string;
  firstName: string;
  lastName: string;
  id: string;
  playerId?: string;
}

const DEFAULT_TEAM_PLAYERS_ORDER = 'firstName ASC';

export class SchoolTeamPlayers extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      itemsCount: 0,
      itemsSelected: [],

      itemsFilters: {},
      isShowItemsFilter: false,
      isItemsFiltered: false,

      isExitConfirmationModalOpen: false,

      isSelectAllItemsChecked: false,

      sortItemsDirection: '',
      sortItemsColumnsName: '',

      itemCurrentPage: 1,

      itemsAccumulatorInitial: [],
      itemsAccumulator: [],
      itemsAccumulatorCount: 0,
      itemsAccumulatorSelected: [],

      itemsAccumulatorFilters: {},
      isShowItemsAccumulatorFilter: false,
      isItemsAccumulatorFiltered: false,

      isSelectAllItemsAccumulatorChecked: false,

      sortItemsAccumulatorDirection: '',
      sortItemsAccumulatorColumnsName: '',

      team: undefined,
      forms: [],
      houses: [],

      isLoading: true,

      itemsAddBuffer: [],
      itemsRemoveBuffer: []
    };
  }

  getTeamId(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    return search.team;
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    const { user } = this.props;
    const teamId = this.getTeamId();

    const itemsFilters = getTwoPanelEditorFilters(ITEMS_COLUMNS);
    const isShowItemsFilter = isFilterExist2(itemsFilters);

    const itemsAccumulatorFilters = getTwoPanelEditorFilters(ITEMS_ACCUMULATOR_COLUMNS);
    const isShowItemsAccumulatorFilter = isFilterExist2(itemsAccumulatorFilters);
    const isItemsAccumulatorFiltered = isFilterExist2(itemsAccumulatorFilters);

    let team: Team;

    getSchoolTeam(user, teamId)
      .then(_team => {
        team = _team;
        const { houseId, ages } = team;
        const isAgesExist = ages.length > 0;
        const isHouseIdExist = typeof houseId !== 'undefined';

        const formFilter = isAgesExist ? { age: { $in: ages } } : undefined;

        const houseFilter = isHouseIdExist ? { id: { $in: [houseId] } } : undefined;

        return BPromise.all([getAllForms(user, formFilter), getAllHouses(user, houseFilter)]);
      })
      .then(([forms, houses]) => {
        const defaultItemsFilter = this.getDefaultItemsFilter(team, forms, houses);

        this.setState({
          houses: houses,
          team: team,
          forms: forms,
          itemsFilters: defaultItemsFilter
        });

        return this.getItemsAndItemsAccumulator();
      })
      .then(({ teamPlayersInitial, teamPlayers, teamPlayersCount, students, studentsCount }) => {
        const studentsNormalized = this.getStudentsNormalized(students);
        const teamPlayersNormalized = this.getTeamPlayersNormalized(teamPlayers);
        const teamPlayersInitialNormalized = this.getTeamPlayersNormalized(teamPlayersInitial);

        this.setState({
          items: studentsNormalized,
          itemsCount: studentsCount.count,
          isShowItemsFilter: isShowItemsFilter,
          isItemsFiltered: true,

          itemsAccumulatorInitial: teamPlayersInitialNormalized,
          itemsAccumulator: teamPlayersNormalized,
          itemsAccumulatorCount: teamPlayersCount.count,
          itemsAccumulatorFilters: itemsAccumulatorFilters,
          isShowItemsAccumulatorFilter: isShowItemsAccumulatorFilter,
          isItemsAccumulatorFiltered: isItemsAccumulatorFiltered,

          isLoading: false
        });
      });
  }

  getTeamPlayersNormalized(teamPlayers: TeamPlayer[]): Item[] {
    return teamPlayers.map(teamPlayer => {
      const { permissionId, form, gender, firstName, lastName, userId, id, house } = teamPlayer;
      return {
        permissionId,
        form,
        house,
        gender,
        firstName,
        lastName,
        id: userId,
        playerId: id
      };
    });
  }

  getStudentsNormalized(students): Item[] {
    return students.map(student => {
      const { permissionId, form, gender, firstName, lastName, id, house } = student;
      return {
        permissionId,
        form,
        house,
        gender,
        firstName,
        lastName,
        id
      };
    });
  }

  getDefaultItemsFilter(team, forms, houses) {
    const { gender, houseId } = team;
    const genderArrayConverted = getUserGenderArrayConvertedFromEventGender(gender);
    const formIds = forms.map(form => form.id);

    return typeof houseId !== 'undefined'
      ? {
          firstName: '',
          lastName: '',
          gender: genderArrayConverted,
          forms: formIds,
          ages: [],
          houses: houses.map(house => house.id)
        }
      : {
          firstName: '',
          lastName: '',
          ages: [],
          gender: genderArrayConverted,
          forms: formIds
        };
  }

  getItemsAndItemsAccumulator() {
    const { user } = this.props;
    const { itemsAccumulatorFilters, forms, houses } = this.state;

    const teamId = this.getTeamId();
    const options = {
      forms: forms,
      houses: houses
    };
    const where = getServerFieldSectionWhere(itemsAccumulatorFilters, options);

    const getTeamPlayersInitialPromise = getAllSchoolTeamPlayers(user, teamId);
    const getTeamPlayersPromise = getAllSchoolTeamPlayers(user, teamId, where);
    const getTeamPlayersCountPromise = getSchoolTeamPlayersCount(user, teamId, where);

    let teamPlayersInitial, teamPlayers, teamPlayersCount;

    return BPromise.all([getTeamPlayersInitialPromise, getTeamPlayersPromise, getTeamPlayersCountPromise])
      .then(([_teamPlayersInitial, _teamPlayers, _teamPlayersCount]) => {
        teamPlayersInitial = _teamPlayersInitial;
        teamPlayers = _teamPlayers;
        teamPlayersCount = _teamPlayersCount;

        const teamPlayersInitialNormalized = this.getTeamPlayersNormalized(teamPlayersInitial);

        const filter = this.getItemsFilter(1, teamPlayersInitialNormalized, []);

        const getSchoolStudentsPromise = getSchoolStudentsWithoutClashes(user, filter);
        const getSchoolStudentsCountPromise = getSchoolStudentsWithClashesCount(user, filter);

        return BPromise.all([getSchoolStudentsPromise, getSchoolStudentsCountPromise]);
      })
      .then(([students, studentsCount]) => {
        return {
          teamPlayersInitial,
          teamPlayers,
          teamPlayersCount,
          students,
          studentsCount
        };
      });
  }

  onItemClick = (index: number): void => {
    const { items, itemsSelected } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = itemsSelected.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...itemsSelected];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      itemsAccumulatorSelected: [],
      itemsSelected: selectedItemsUpdated
    });
  };

  onItemAccumulatorClick = (index: number): void => {
    const { itemsAccumulator, itemsAccumulatorSelected } = this.state;
    const selectedItem = itemsAccumulator[index];

    const selectedItemIndex = itemsAccumulatorSelected.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...itemsAccumulatorSelected];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      itemsAccumulatorSelected: selectedItemsUpdated,
      itemsSelected: []
    });
  };

  onAddClick = () => {
    const { itemsSelected, itemsAddBuffer, itemsAccumulatorInitial, itemCurrentPage } = this.state;
    const { user } = this.props;

    const nextItemsAddBuffer = [...itemsAddBuffer, ...itemsSelected];

    this.setState({
      isLoading: true
    });

    const filter = this.getItemsFilter(itemCurrentPage, itemsAccumulatorInitial, nextItemsAddBuffer);
    const getSchoolStudentsPromise = getSchoolStudentsWithoutClashes(user, filter);
    const getSchoolStudentsCountPromise = getSchoolStudentsWithClashesCount(user, filter);

    BPromise.all([getSchoolStudentsPromise, getSchoolStudentsCountPromise]).then(([students, studentsCount]) => {
      const studentsNormalized = this.getStudentsNormalized(students);

      this.setState({
        items: studentsNormalized,
        isLoading: false,
        itemsCount: studentsCount.count,
        itemsAddBuffer: nextItemsAddBuffer,
        itemsAccumulatorSelected: [],
        itemsSelected: [],
        isSelectAllItemsAccumulatorChecked: false,
        isSelectAllItemsChecked: false
      });
    });
  };

  onRemoveClick = () => {
    const {
      itemsAccumulatorSelected,
      itemsAddBuffer,
      itemsRemoveBuffer,
      itemsAccumulatorInitial,
      itemsAccumulator,
      itemCurrentPage
    } = this.state;
    const { user } = this.props;

    const nextItemsRemoveBuffer = [...itemsAccumulatorSelected, ...itemsRemoveBuffer];
    const nextItemsAccumulatorInitial = itemsAccumulatorInitial.filter(itemAccumulatorInitial =>
      nextItemsRemoveBuffer.every(nextItemRemoveBuffer => nextItemRemoveBuffer.id !== itemAccumulatorInitial.id)
    );
    const nextItemsAccumulator = itemsAccumulator.filter(itemAccumulator =>
      nextItemsRemoveBuffer.every(nextItemRemoveBuffer => nextItemRemoveBuffer.id !== itemAccumulator.id)
    );

    this.setState({
      isLoading: true
    });

    const filter = this.getItemsFilter(itemCurrentPage, nextItemsAccumulatorInitial, itemsAddBuffer);
    const getSchoolStudentsPromise = getSchoolStudentsWithoutClashes(user, filter);
    const getSchoolStudentsCountPromise = getSchoolStudentsWithClashesCount(user, filter);

    BPromise.all([getSchoolStudentsPromise, getSchoolStudentsCountPromise]).then(([students, studentsCount]) => {
      const studentsNormalized = this.getStudentsNormalized(students);
      this.setState({
        items: studentsNormalized,
        isLoading: false,
        itemsCount: studentsCount.count,
        itemsAccumulatorSelected: [],
        itemsSelected: [],
        isSelectAllItemsAccumulatorChecked: false,
        isSelectAllItemsChecked: false,
        itemsRemoveBuffer: nextItemsRemoveBuffer,
        itemsAccumulatorInitial: nextItemsAccumulatorInitial,
        itemsAccumulator: nextItemsAccumulator,
        itemsAccumulatorCount: nextItemsAccumulator.length
      });
    });
  };

  onClickSave = () => {
    const { itemsAddBuffer, itemsRemoveBuffer, team, forms, houses } = this.state;
    const { user } = this.props;

    // const tournamentId = this.getTournamentId();
    const teamId = this.getTeamId();

    const data: SchoolTournamentTeamPlayersBatchData = {
      add: itemsAddBuffer.map(item => ({ userId: item.id, permissionId: item.permissionId })),
      remove: itemsRemoveBuffer.map(item => item.playerId)
    };

    const defaultItemsFilter = this.getDefaultItemsFilter(team, forms, houses);

    this.setState({
      isLoading: true,
      // set default values
      itemsSelected: [],
      itemsFilters: defaultItemsFilter,
      isShowItemsFilter: false,
      isItemsFiltered: true,
      isSelectAllItemsChecked: false,
      sortItemsDirection: '',
      sortItemsColumnsName: '',
      itemCurrentPage: 1,
      itemsAccumulatorSelected: [],
      itemsAccumulatorFilters: {},
      isShowItemsAccumulatorFilter: false,
      isItemsAccumulatorFiltered: false,
      isSelectAllItemsAccumulatorChecked: false,
      sortItemsAccumulatorDirection: '',
      sortItemsAccumulatorColumnsName: '',
      itemsAddBuffer: [],
      itemsRemoveBuffer: []
    });

    updateSchoolTeamPlayersBatch(user, teamId, data)
      .then(res => {
        return this.getItemsAndItemsAccumulator();
      })
      .then(({ teamPlayersInitial, teamPlayers, teamPlayersCount, students, studentsCount }) => {
        const studentsNormalized = this.getStudentsNormalized(students);
        const teamPlayersNormalized = this.getTeamPlayersNormalized(teamPlayers);
        const teamPlayersInitialNormalized = this.getTeamPlayersNormalized(teamPlayersInitial);

        this.setState({
          //set items and items accumulator
          items: studentsNormalized,
          itemsCount: studentsCount.count,
          itemsAccumulator: teamPlayersNormalized,
          itemsAccumulatorInitial: teamPlayersInitialNormalized,
          itemsAccumulatorCount: teamPlayersCount.count,
          isLoading: false
        });
      });
  };

  onItemsFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.itemsFilters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (filterField === 'forms') {
      nextFilters.ages = [];
    }

    if (filterField === 'ages') {
      nextFilters.forms = [];
    }
    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = ITEMS_COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      itemsFilters: nextFilters
    });
  };

  onItemsAccumulatorFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.itemsAccumulatorFilters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = ITEMS_COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      itemsAccumulatorFilters: nextFilters
    });
  };

  onItemsFilterClick = (event): void => {
    event.preventDefault();

    const { isShowItemsFilter } = this.state;

    this.setState({
      isShowItemsFilter: !isShowItemsFilter
    });
  };

  onItemsAccumulatorFilterClick = (event): void => {
    event.preventDefault();

    const { isShowItemsAccumulatorFilter } = this.state;

    this.setState({
      isShowItemsAccumulatorFilter: !isShowItemsAccumulatorFilter
    });
  };

  getItemsFilter(page, itemsAccumulator, itemsAddBuffer, isAllItems = false) {
    const { itemsFilters, team, forms, houses } = this.state;
    const { houseId } = team;
    const isHouseIdExist = typeof houseId !== 'undefined';

    const {
      gender: itemsFiltersGender,
      ages: itemsFiltersAges = [],
      firstName: itemsFiltersFirstName = '',
      lastName: itemsFiltersLastName = '',
      forms: itemsFiltersForms = [],
      houses: itemsFiltersHouses = []
    } = itemsFilters;

    let where = {
      gender: {
        $in: itemsFiltersGender
      }
    };

    const allItems = [...itemsAccumulator, ...itemsAddBuffer];
    const ninIds = Lazy(allItems)
      .map(item => item.id)
      .uniq()
      .toArray();
    propz.set(where, ['_id', '$nin'], ninIds);

    if (itemsFiltersForms.length > 0) {
      propz.set(where, ['formId', '$in'], itemsFiltersForms);
    } else {
      const ages = itemsFiltersAges.length > 0 ? itemsFiltersAges : AGE_GROUPS.ENGLISH.map((age, index) => index);
      const formIds = forms.filter(form => ages.some(age => Number(form.age) === Number(age))).map(form => form.id);

      if (formIds.length > 0) {
        propz.set(where, ['formId', '$in'], formIds);
      }
    }

    if (isHouseIdExist) {
      propz.set(where, ['houseId'], houseId);
    }

    if (itemsFiltersHouses.length > 0) {
      propz.set(where, ['houseId', '$in'], itemsFiltersHouses);
    }

    let and = [];

    if (itemsFiltersFirstName.length > 0) {
      and.push({ firstName: { like: itemsFiltersFirstName, options: 'i' } });
    }

    if (itemsFiltersLastName.length > 0) {
      and.push({ lastName: { like: itemsFiltersLastName, options: 'i' } });
    }

    if (and.length > 0) {
      propz.set(where, ['$and'], and);
    }

    const filter = {
      where: where,
      limit: isAllItems ? DEFAULT_LIMIT : LIMIT,
      skip: isAllItems ? 0 : (page - 1) * LIMIT,
      order: DEFAULT_TEAM_PLAYERS_ORDER
    };

    return filter;
  }

  itemsSetCurrentPageParams = (currentPage: number): void => {
    const { user } = this.props;
    const { itemsAccumulator, sortItemsColumnsName, sortItemsDirection, itemsAddBuffer } = this.state;

    this.setState({
      isLoading: true
    });

    let orderForFilter = undefined;

    if (sortItemsColumnsName !== '' && sortItemsDirection !== '') {
      orderForFilter = `${sortItemsColumnsName} ${sortItemsDirection}`;
    }

    const filter = this.getItemsFilter(currentPage, itemsAccumulator, itemsAddBuffer);

    getSchoolStudentsWithoutClashes(user, filter).then(students => {
      const studentsNormalized = this.getStudentsNormalized(students);
      this.setState({
        itemCurrentPage: currentPage,
        items: studentsNormalized,
        isLoading: false
      });
    });
  };

  itemsOnApplyFilterClick = () => {
    const { itemsAccumulator, itemsAddBuffer } = this.state;

    this.setState({
      isLoading: true
    });

    const filter = this.getItemsFilter(1, itemsAccumulator, itemsAddBuffer);

    const { user } = this.props;

    const getSchoolStudentsPromise = getSchoolStudentsWithoutClashes(user, filter);
    const getSchoolStudentsCountPromise = getSchoolStudentsWithClashesCount(user, filter);

    BPromise.all([getSchoolStudentsPromise, getSchoolStudentsCountPromise]).then(([students, studentsCount]) => {
      const studentsNormalized = this.getStudentsNormalized(students);
      this.setState({
        items: studentsNormalized,
        isLoading: false,
        itemsCount: studentsCount.count,
        itemsAccumulatorSelected: [],
        itemsSelected: [],
        itemCurrentPage: 1,
        sortItemsDirection: '',
        sortItemsColumnsName: ''
      });
    });
  };

  itemsAccumulatorOnApplyFilterClick = () => {};
  /*itemsAccumulatorOnApplyFilterClick = () => {
    const { itemsAccumulatorFilters, itemsRemoveBuffer } = this.state;
    this.setState({
      isLoading: true
    });

    let where = getServerFieldSectionWhere(itemsAccumulatorFilters);
    const ninIds = itemsRemoveBuffer.map(item => item.id);
    propz.set(where, ['id', '$nin'], ninIds);

    const { user } = this.props;

    const teamId = this.getTeamId();

    const getTeamPlayersPromise = getAllSchoolTeamPlayers(user, teamId, where);
    const getTeamPlayersCountPromise = getSchoolTeamPlayersCount(user, teamId, where);

    BPromise.all([getTeamPlayersPromise, getTeamPlayersCountPromise]).then(([teamPlayers, teamPlayersCount]) => {
      const teamPlayersNormalized = this.getTeamPlayersNormalized(teamPlayers);
      this.setState({
        itemsAccumulator: teamPlayersNormalized,
        isLoading: false,
        itemsAccumulatorCount: teamPlayersCount.count,
        itemsAccumulatorSelected: [],
        itemsSelected: []
      });
    });
  };*/

  itemsOnClearFilterClick = () => {
    const { itemsAccumulatorInitial, itemsAddBuffer, team, forms, houses } = this.state;
    const { houseId } = team;
    const isHouseIdExist = typeof houseId !== 'undefined';

    const defaultItemsFilter = this.getDefaultItemsFilter(team, forms, houses);

    this.setState({
      isLoading: true,
      itemsFilters: defaultItemsFilter
    });

    const { gender: itemsFiltersGender, ages: itemsFiltersAges = [] } = defaultItemsFilter;

    let where = {
      gender: {
        $in: itemsFiltersGender
      }
    };

    const allItems = [...itemsAccumulatorInitial, ...itemsAddBuffer];
    const ninIds = Lazy(allItems)
      .map(item => item.id)
      .uniq()
      .toArray();
    propz.set(where, ['_id', '$nin'], ninIds);

    const ages = itemsFiltersAges.length > 0 ? itemsFiltersAges : AGE_GROUPS.ENGLISH.map((age, index) => index);
    const formIds = forms.filter(form => ages.some(age => Number(form.age) === Number(age))).map(form => form.id);

    if (formIds.length > 0) {
      propz.set(where, ['formId', '$in'], formIds);
    }

    if (isHouseIdExist) {
      propz.set(where, ['houseId'], houseId);
    }

    const filter = {
      where: where,
      limit: LIMIT,
      skip: DEFAULT_SKIP,
      order: DEFAULT_TEAM_PLAYERS_ORDER
    };

    const { user } = this.props;

    const getSchoolStudentsPromise = getSchoolStudentsWithoutClashes(user, filter);
    const getSchoolStudentsCountPromise = getSchoolStudentsWithClashesCount(user, filter);

    BPromise.all([getSchoolStudentsPromise, getSchoolStudentsCountPromise]).then(([students, studentsCount]) => {
      const studentsNormalized = this.getStudentsNormalized(students);
      this.setState({
        items: studentsNormalized,
        isLoading: false,
        itemsCount: studentsCount.count,
        itemsAccumulatorSelected: [],
        itemsSelected: [],
        itemCurrentPage: 1,
        sortItemsColumnsName: '',
        sortItemsDirection: '',
        isItemsFiltered: true,
        isShowItemsFilter: false
      });
    });
  };

  itemsAccumulatorOnClearFilterClick = () => {};

  /*itemsAccumulatorOnClearFilterClick = () => {
    this.setState({
      isLoading: true
    });

    const { user } = this.props;
    const teamId = this.getTeamId();
    const { itemsRemoveBuffer } = this.state;

    const getTeamPlayersPromise = getAllSchoolTeamPlayers(user, teamId);
    const getTeamPlayersCountPromise = getSchoolTeamPlayersCount(user, teamId);

    BPromise.all([getTeamPlayersPromise, getTeamPlayersCountPromise]).then(([teamPlayers, teamPlayersCount]) => {
      const teamPlayersNormalized = this.getTeamPlayersNormalized(teamPlayers);
      const nextTeamPlayersNormalized = teamPlayersNormalized.filter(teamPlayerNormalized =>
        itemsRemoveBuffer.every(item => item.id !== teamPlayerNormalized.id)
      );

      this.setState({
        itemsAccumulator: nextTeamPlayersNormalized,
        isLoading: false,
        itemsAccumulatorCount: teamPlayersCount.count,
        itemsAccumulatorSelected: [],
        itemsSelected: [],
        itemsAccumulatorFilters: {}
      });
    });
  };*/

  goBack = () => {
    const { history, location } = this.props;
    const { state } = location;
    const { search } = state as any;

    const teamId = this.getTeamId();

    history.push({
      pathname: '/teams',
      search,
      state: { teamId }
    });
  };

  goBackWithOrWithoutConfirmation = () => {
    const { itemsAddBuffer } = this.state;
    const isChangesInTeamExist = itemsAddBuffer.length > 0;

    isChangesInTeamExist ? this.onConfirmationExitWithoutSavingModalOpen() : this.goBack();
  };

  renderConfirmationExitWithoutSavingModal = () => {
    const { isExitConfirmationModalOpen } = this.state;

    return (
      <SimpleModal isOpen={isExitConfirmationModalOpen} title={'Confirm the action'}>
        <div>You haven’t saved your team, are you sure you want to leave this page?</div>
        <div>
          <Button
            onClick={this.onConfirmationExitWithoutSavingModalClose}
            text={'Cancel'}
            customClass={'btn-secondary mt-3 mr-3'}
          />
          <Button onClick={this.goBack} text={'Ok'} customClass={'mt-3'} />
        </div>
      </SimpleModal>
    );
  };

  onConfirmationExitWithoutSavingModalOpen = () => {
    this.setState({
      isExitConfirmationModalOpen: true
    });
  };

  onConfirmationExitWithoutSavingModalClose = () => {
    this.setState({
      isExitConfirmationModalOpen: false
    });
  };

  onSelectAllItemsClick = () => {
    const { itemsSelected, itemsAddBuffer, itemsAccumulatorInitial, itemCurrentPage } = this.state;
    this.setState({
      isLoading: true
    });

    const user = this.props.user;
    const nextItemsAddBuffer = [...itemsAddBuffer, ...itemsSelected];
    const filter = this.getItemsFilter(itemCurrentPage, itemsAccumulatorInitial, nextItemsAddBuffer, true);

    getSchoolStudentsWithoutClashes(user, filter).then(items => {
      const itemsFiltered = items.filter(item => {
        return itemsSelected.every(selectedItem => selectedItem.id !== item.id);
      });

      const studentsNormalizedFiltered = this.getStudentsNormalized(itemsFiltered);

      const nextSelectedItems = [...itemsSelected, ...studentsNormalizedFiltered];

      this.setState({
        itemsSelected: nextSelectedItems,
        isSelectAllItemsChecked: true,
        itemsAccumulatorSelected: [],
        isSelectAllItemsAccumulatorChecked: false,
        isLoading: false
      });
    });
  };

  onSelectAllItemsOnPageClick = () => {
    const { items, itemsSelected } = this.state;

    const itemsFiltered = items.filter(item => {
      return itemsSelected.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...itemsSelected, ...itemsFiltered];

    this.setState({
      itemsSelected: nextSelectedItems,
      isSelectAllItemsChecked: true,
      itemsAccumulatorSelected: [],
      isSelectAllItemsAccumulatorChecked: false
    });
  };

  onUnselectAllItemsClick = () => {
    this.setState({
      itemsSelected: [],
      isSelectAllItemsChecked: false,
      itemsAccumulatorSelected: [],
      isSelectAllItemsAccumulatorChecked: false
    });
  };

  onSelectAllItemsAccumulatorClick = () => {
    const { itemsAccumulatorSelected, itemsAccumulatorFilters } = this.state;
    this.setState({
      isLoading: true
    });

    const { user } = this.props;
    const teamId = this.getTeamId();
    const where = getServerFieldSectionWhere(itemsAccumulatorFilters);

    getAllSchoolTeamPlayers(user, teamId, where).then(teamPlayers => {
      const teamPlayersNormalized = this.getTeamPlayersNormalized(teamPlayers);

      const teamPlayersFiltered = teamPlayersNormalized.filter(teamPlayer => {
        return itemsAccumulatorSelected.every(selectedItem => selectedItem.id !== teamPlayer.id);
      });

      const nextSelectedItems = [...itemsAccumulatorSelected, ...teamPlayersFiltered];

      this.setState({
        itemsAccumulatorSelected: nextSelectedItems,
        isSelectAllItemsAccumulatorChecked: true,
        itemsSelected: [],
        isSelectAllItemsChecked: false,
        isLoading: false
      });
    });
  };

  onSelectAllItemsAccumulatorOnPageClick = () => {
    const { itemsAccumulator, itemsAccumulatorSelected } = this.state;

    const itemsFiltered = itemsAccumulator.filter(item => {
      return itemsAccumulatorSelected.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...itemsAccumulatorSelected, ...itemsFiltered];

    this.setState({
      itemsAccumulatorSelected: nextSelectedItems,
      isSelectAllItemsAccumulatorChecked: false,
      itemsSelected: [],
      isSelectAllItemsChecked: false
    });
  };

  onUnselectAllItemsAccumulatorClick = () => {
    this.setState({
      itemsAccumulatorSelected: [],
      isSelectAllItemsAccumulatorChecked: false,
      itemsSelected: [],
      isSelectAllItemsChecked: false
    });
  };

  onSortItemsClick = (sortField: string) => {
    const { sortItemsDirection, sortItemsColumnsName, itemCurrentPage, itemsAccumulator, itemsAddBuffer } = this.state;

    const order = getOrder(sortField, sortItemsDirection, sortItemsColumnsName) as TABLE_SORT_DIRECTION;
    const orderForFilter = `${sortField} ${order}`;

    const filter = this.getItemsFilter(itemCurrentPage, itemsAccumulator, itemsAddBuffer);
    const { user } = this.props;
    const getSchoolStudentsPromise = getSchoolStudentsWithoutClashes(user, filter);
    const getSchoolStudentsCountPromise = getSchoolStudentsWithClashesCount(user, filter.where);

    BPromise.all([getSchoolStudentsPromise, getSchoolStudentsCountPromise]).then(([students, studentsCount]) => {
      const studentsNormalized = this.getStudentsNormalized(students);
      this.setState({
        items: studentsNormalized,
        isLoading: false,
        itemsCount: studentsCount.count,
        itemsAccumulatorSelected: [],
        itemsSelected: [],
        itemCurrentPage: 1,
        sortItemsColumnsName: sortField,
        sortItemsDirection: order
      });
    });
  };

  onSortItemsAccumulatorClick = (sortField: string) => {};

  render() {
    const {
      items,
      isLoading,
      itemsSelected,
      itemsAccumulatorSelected,
      itemsAccumulator,
      itemsFilters,
      isShowItemsFilter,
      isItemsFiltered,
      itemsCount,
      itemsAccumulatorCount,
      itemCurrentPage,
      itemsAccumulatorFilters,
      isShowItemsAccumulatorFilter,
      isItemsAccumulatorFiltered,
      isSelectAllItemsChecked,
      isSelectAllItemsAccumulatorChecked,
      sortItemsDirection,
      sortItemsColumnsName,
      sortItemsAccumulatorDirection,
      sortItemsAccumulatorColumnsName,
      itemsAddBuffer,
      itemsRemoveBuffer,
      forms,
      houses,
      team
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const { user } = this.props;
    const { activeSchool } = user;
    const { name, gender } = team;
    const { ageGroupsNaming } = activeSchool;

    const itemsFiltersOptions = {
      ages: getSelectOptionForAge(forms, ageGroupsNaming),
      gender: getSelectOptionForGender(gender),
      forms: getSelectOptionForForms(forms),
      houses: getSelectOptionForHouses(houses)
    };

    const itemsAccumulatorFiltersOptions = {};

    return (
      <>
        {this.renderConfirmationExitWithoutSavingModal()}
        <div className="row">
          <div className="col-md-12">
            <Button
              text={'← back'}
              onClick={this.goBackWithOrWithoutConfirmation}
              customClass={'btn-secondary mr-3 mb-3'}
            />
            <div className="d-inline h1 mb-3">{name}</div>
          </div>
        </div>
        <TwoPanelEditor
          //items
          items={items}
          itemsTitle={'Eligible students'}
          itemsColumns={ITEMS_COLUMNS}
          itemsSelected={itemsSelected}
          onItemClick={this.onItemClick}
          //items filters
          onItemsFilterChange={this.onItemsFilterChange}
          itemsFilters={itemsFilters}
          itemsFiltersOptions={itemsFiltersOptions}
          isShowItemsFilter={isShowItemsFilter}
          isItemsFiltered={isItemsFiltered}
          onItemsFilterClick={this.onItemsFilterClick}
          //accumulator
          itemsAccumulator={itemsAccumulator}
          itemsAccumulatorTitle={'Team members'}
          itemsAccumulatorColumns={ITEMS_ACCUMULATOR_COLUMNS}
          onItemAccumulatorClick={this.onItemAccumulatorClick}
          itemsAccumulatorSelected={itemsAccumulatorSelected}
          //accumulator filters
          onItemsAccumulatorFilterChange={this.onItemsAccumulatorFilterChange}
          itemsAccumulatorFilters={itemsAccumulatorFilters}
          itemsAccumulatorFiltersOptions={itemsAccumulatorFiltersOptions}
          isShowItemsAccumulatorFilter={isShowItemsAccumulatorFilter}
          isItemsAccumulatorFiltered={isItemsAccumulatorFiltered}
          onItemsAccumulatorFilterClick={this.onItemsAccumulatorFilterClick}
          //buttons
          onAddClick={this.onAddClick}
          onRemoveClick={this.onRemoveClick}
          //counts
          itemsCount={itemsCount}
          itemsAccumulatorCount={itemsAccumulatorCount}
          //current pages
          itemAccumulatorCurrentPage={1}
          itemCurrentPage={itemCurrentPage}
          itemsSetCurrentPageParams={this.itemsSetCurrentPageParams}
          //items filter buttons
          itemsOnApplyFilterClick={this.itemsOnApplyFilterClick}
          itemsOnClearFilterClick={this.itemsOnClearFilterClick}
          //items accumulator filter buttons
          itemsAccumulatorOnApplyFilterClick={this.itemsAccumulatorOnApplyFilterClick}
          itemsAccumulatorOnClearFilterClick={this.itemsAccumulatorOnClearFilterClick}
          //items select checkbox
          onSelectAllItemsClick={this.onSelectAllItemsClick}
          onSelectAllItemsOnPageClick={this.onSelectAllItemsOnPageClick}
          onUnselectAllItemsClick={this.onUnselectAllItemsClick}
          isSelectAllItemsChecked={isSelectAllItemsChecked}
          //items accumulator select checkbox
          onSelectAllItemsAccumulatorClick={this.onSelectAllItemsAccumulatorClick}
          onSelectAllItemsAccumulatorOnPageClick={this.onSelectAllItemsAccumulatorOnPageClick}
          onUnselectAllItemsAccumulatorClick={this.onUnselectAllItemsAccumulatorClick}
          isSelectAllItemsAccumulatorChecked={isSelectAllItemsAccumulatorChecked}
          //items sort
          sortItemsDirection={sortItemsDirection}
          sortItemsColumnsName={sortItemsColumnsName}
          onSortItemsClick={this.onSortItemsClick}
          //items accumulator sort
          sortItemsAccumulatorDirection={sortItemsAccumulatorDirection}
          sortItemsAccumulatorColumnsName={sortItemsAccumulatorColumnsName}
          onSortItemsAccumulatorClick={this.onSortItemsAccumulatorClick}
          itemsAddBuffer={itemsAddBuffer}
          itemsRemoveBuffer={itemsRemoveBuffer}
        />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <Button text={'Save'} onClick={this.onClickSave} customClass={'btn-lg mt-3'} />
            </div>
          </div>
        </div>
      </>
    );
  }
}
