import * as React from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button } from 'Src/components/Button/Button';
import * as propz from 'propz';
import {
  getFullNameAndFormName,
  getName,
  searchFunctionHouses,
  searchFunctionSports,
  searchFunctionTeamSports
} from 'Src/helpers/autocomplete/autocomplete';
import { GENDER, SPORT_GENDER_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/common';
import { Autocomplete } from 'Src/components/Autocomplete/Autocomplete';
import { AppUser } from 'Src/views/App/App';
import { Multiselect } from 'Src/components/Multiselect/Multiselect';
import { SelectOption } from 'Src/helpers/table/table';
import { SchoolForm } from 'Src/models/form';
import { getSelectOptionForAge } from 'Src/helpers/table/select';
import { Switch } from '../../../../../../components/Switch/Switch';
import { checkAvailabilityTeamName } from 'Src/helpers/service/admin/schoolTeams';

interface Props {
  team?: any;
  onSubmit: (data) => void;
  onCancel: () => void;
  user: AppUser;
  forms: SchoolForm[];
}

export const SchoolTeamForm: React.FunctionComponent<Props> = props => {
  const { team, forms, user } = props;
  const isTeamExist = typeof team !== 'undefined';

  const teamName = propz.get(team, ['name'], '');

  const SchoolTeamSchema = Yup.object().shape({
    name: Yup.string()
      .required('Required')
      .test('name', 'This team name has already been used for your chosen sport, please use a different name', function(
        value
      ) {
        const sportId = propz.get(this.parent, ['sportId']);
        const isExistSportId = typeof sportId !== 'undefined';
        const isTeamNameNotChanged = value === teamName;
        switch (true) {
          case !isExistSportId:
            return true;
          case !value:
            return false;
          case isTeamNameNotChanged:
            return true;
          default:
            return checkAvailabilityTeamName(user, { name: value, sportId: sportId }).then(res => {
              return res.isAvailable;
            });
        }
      }),
    sportId: Yup.string().required('Required'),
    gender: Yup.string().required('Required')
  });

  const renderGenderOptions = sport => {
    if (typeof sport === 'undefined') {
      return <option value={''}>Please select sport</option>;
    }

    let genderOptions = [];

    if (sport.genders.maleOnly) {
      genderOptions.push(
        <option key="gender_option_male" value={GENDER.MALE_ONLY}>
          {SPORT_GENDER_SERVER_TO_CLIENT_MAPPING.MALE_ONLY}
        </option>
      );
    }

    if (sport.genders.femaleOnly) {
      genderOptions.push(
        <option key="gender_option_female" value={GENDER.FEMALE_ONLY}>
          {SPORT_GENDER_SERVER_TO_CLIENT_MAPPING.FEMALE_ONLY}
        </option>
      );
    }

    if (sport.genders.mixed) {
      genderOptions.push(
        <option key="gender_option_mixed" value={GENDER.MIXED}>
          {SPORT_GENDER_SERVER_TO_CLIENT_MAPPING.MIXED}
        </option>
      );
    }

    return genderOptions;
  };

  const getAgeGroup = (): SelectOption[] => {
    const activeSchool = user.activeSchool;
    const ageGroupsNaming = activeSchool.ageGroupsNaming;

    return getSelectOptionForAge(forms, ageGroupsNaming);
  };

  const getSports = (text: string) => {
    const user = props.user;
    const isFavoriteSportsEnabled = propz.get(user, ['activeSchool', 'isFavoriteSportsEnabled'], false);

    return searchFunctionTeamSports(user, text, !isFavoriteSportsEnabled);
  };

  const teamInitial = {
    name: isTeamExist ? propz.get(team, ['name'], '') : '',
    description: isTeamExist ? propz.get(team, ['description'], '') : '',
    sport: propz.get(team, ['sport'], undefined),
    sportId: propz.get(team, ['sportId'], ''),
    ages: propz.get(team, ['ages'], []),
    gender: propz.get(team, ['gender'], ''),
    isHouseFilter: Boolean(propz.get(team, ['houseId'], false)),
    house: propz.get(team, ['house'], undefined),
    houseId: propz.get(team, ['houseId'], '')
  };

  const getHouse = text => {
    const user = props.user;
    return searchFunctionHouses(user, text);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik
            initialValues={teamInitial}
            validationSchema={SchoolTeamSchema}
            onSubmit={values => {
              props.onSubmit(values);
            }}
            render={({ touched, errors, setFieldValue, values }) => (
              <Form>
                <label>Team Name</label>
                <Field type="text" name="name" className="form-control mb-3" />
                {touched.name && errors.name ? <div className="alert alert-danger">{errors.name}</div> : null}

                <label>Team Description</label>
                <Field component="textarea" name="description" className="form-control mb-3" />

                <label>Sport</label>
                <Field
                  name="sport"
                  render={({ field }) => {
                    return (
                      <Autocomplete
                        searchFunction={getSports}
                        getElementTitle={getName}
                        customClass="mFullWidth mb-3"
                        defaultItem={values.sport}
                        onSelect={sport => {
                          setFieldValue('sportId', sport.id);
                          setFieldValue('sport', sport);

                          switch (true) {
                            case sport.genders.maleOnly:
                              setFieldValue('gender', GENDER.MALE_ONLY);
                              break;
                            case sport.genders.femaleOnly:
                              setFieldValue('gender', GENDER.FEMALE_ONLY);
                              break;
                            case sport.genders.mixed:
                              setFieldValue('gender', GENDER.MIXED);
                              break;
                          }
                        }}
                      />
                    );
                  }}
                />
                {touched.sportId && errors.sportId ? <div className="alert alert-danger">{errors.sportId}</div> : null}

                <label>Gender</label>
                <Field component="select" name="gender" className="form-control mb-3">
                  {renderGenderOptions(values.sport)}
                </Field>
                {touched.gender && errors.gender ? <div className="alert alert-danger">{errors.gender}</div> : null}

                <label>Ages</label>
                <Field
                  name="ages"
                  render={({ field }) => {
                    return (
                      <Multiselect
                        items={getAgeGroup()}
                        onChange={options => {
                          setFieldValue('ages', options);
                        }}
                        value={field.value}
                      />
                    );
                  }}
                />

                <Field
                  name="isHouseFilter"
                  render={({ field }) => <Switch {...field} text={'Filtered by house'} customClass="mb-3" />}
                />

                {values.isHouseFilter && (
                  <>
                    <label>House</label>
                    <Field
                      name="player"
                      render={({ field }) => {
                        return (
                          <Autocomplete
                            searchFunction={getHouse}
                            getElementTitle={getName}
                            customClass="mFullWidth mb-3"
                            defaultItem={values.house}
                            onSelect={house => {
                              setFieldValue('house', house);
                              setFieldValue('houseId', house.id);
                            }}
                          />
                        );
                      }}
                    />
                  </>
                )}

                <Button onClick={props.onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                <button type="submit" className="mt-3 mb-3 btn btn-primary">
                  Save
                </button>
              </Form>
            )}
          />
        </div>
      </div>
    </div>
  );
};

SchoolTeamForm.displayName = 'SchoolTeamForm';
