import * as React from 'react';
import * as BPromise from 'bluebird';
import * as propz from 'propz';
import { AppUser } from 'Src/views/App/App';
import { parse } from 'query-string';
import { getOrder, getSearchFilter, getSearchOrder, getServerFieldSectionWhere } from 'Src/helpers/table/table';
import {
  ColumnDefinition,
  getFilters2,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { History, Location } from 'history';
import { Grid2 } from 'Src/components/Grid/Grid2';
import { Loader } from 'Src/components/Loader/Loader';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import {
  getAllTournamentParticipants,
  getConfirmationRequestTemplate,
  getTournamentParticipants,
  getTournamentParticipantsCount,
  removeTournamentParticipant,
  sendConfirmationRequest
} from 'Src/helpers/service/adminSU/tournamentParticipantsTable';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { getTournament } from 'Src/helpers/service/adminSU/tournamentsTable';
import { ConfirmationRequestForm } from 'Src/components/ConfirmationRequestForm/ConfirmationRequestForm';
import { Tournament, TournamentParticipant } from 'Src/models/tournament';
import { SimpleAlert } from 'Src/components/SimpleAlert/SimpleAlert';
import { ConfirmationRequestTemplate } from '../../../../models/confirmationRequestTemplate';
import { INVITE_KIND } from 'Src/consts/invite';
import { INVITE_KIND_TYPE } from 'Src/types/invite';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Name',
    field: 'name',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: ['school', 'name']
  }
];

interface State {
  items: TournamentParticipant[];
  currentPage: number;
  selectedItems: TournamentParticipant[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  filters: any;
  isDataFiltered: boolean;
  isLoading: boolean;
  total: number;
  basePath: string;
  isSelectAllChecked: boolean;
  confirmationRequestTemplate: ConfirmationRequestTemplate;
  isConfirmationRequestModal: boolean;
  tournament: Tournament;
  isSchoolsDeleteInfoModalShow: boolean;
  isError: boolean;
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export class TournamentParticipants extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      filters: {},
      isDataFiltered: false,
      isLoading: false,
      total: 0,
      basePath: '',
      isSelectAllChecked: false,
      confirmationRequestTemplate: undefined,
      isConfirmationRequestModal: false,
      isSchoolsDeleteInfoModalShow: false,
      isError: false,
      tournament: undefined
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setAdditionalItems().then(res => {
      this.setItems();
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  getTournamentId(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    const tournamentId = search.tournament;
    return tournamentId;
  }

  setAdditionalItems() {
    const { user } = this.props;

    const tournamentId = this.getTournamentId();

    return getTournament(user, tournamentId).then(tournament => {
      this.setState({
        tournament: tournament
      });

      return true;
    });
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters, { isCorrectTime: true });

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const tournamentId = this.getTournamentId();

    const getItemsPromise = getTournamentParticipants(user, tournamentId, serverQueryFilter);
    const getItemsCountPromise = getTournamentParticipantsCount(user, tournamentId, where);

    const promises = [getItemsCountPromise, getItemsPromise];

    return BPromise.all(promises).then(([countObj, items]) => {
      this.setState({
        currentPage: page,
        sortDirection: sortDirection,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: items,
        total: countObj.count,
        basePath: history.location.pathname,
        isLoading: false
      });

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;
    const { history } = this.props;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const tournamentId = this.getTournamentId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `tournament=${tournamentId}&${search.join('&')}`,
      state: history.location.state
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: nextSelectedItems,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    const tournamentId = this.getTournamentId();

    getAllTournamentParticipants(user, tournamentId, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const nextSelectedItems = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: nextSelectedItems,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;
    const { history } = this.props;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const tournamentId = this.getTournamentId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `tournament=${tournamentId}&${search.join('&')}`,
      state: history.location.state
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    const { history } = this.props;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    const tournamentId = this.getTournamentId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `tournament=${tournamentId}&${search.join('&')}`,
      state: history.location.state
    });
  };

  onClearFilterClick = (): void => {
    const { history } = this.props;
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    const tournamentId = this.getTournamentId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `tournament=${tournamentId}`,
      state: history.location.state
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  isOneItemSelected(): boolean {
    const selectedItems = this.state.selectedItems;
    return selectedItems.length === 1;
  }

  isSchoolsDeleteModalClose = () => {
    this.setState({
      isSchoolsDeleteInfoModalShow: false
    });
  };

  isSchoolsDeleteModalOpen = (): void => {
    this.setState({
      isSchoolsDeleteInfoModalShow: true
    });
  };

  removeTournamentParticipants = () => {
    this.setState({
      isLoading: true
    });

    const { selectedItems } = this.state;
    const { user } = this.props;
    const tournamentId = this.getTournamentId();

    BPromise.each(selectedItems, (item, index) => {
      return removeTournamentParticipant(user, tournamentId, item.id);
    })
      .then(res => {
        this.setState({
          isSchoolsDeleteInfoModalShow: false,
          selectedItems: []
        });
        this.setItems();
      })
      .catch(error => {
        console.error(error);
        this.setState({
          isSchoolsDeleteInfoModalShow: false,
          isError: true
        });
        this.setItems();
      });
  };

  sendConfirmationRequest = () => {
    const { user } = this.props;

    this.setState({
      isLoading: true
    });

    getConfirmationRequestTemplate(user).then(confirmationRequestTemplate => {
      this.setState({
        isLoading: false,
        confirmationRequestTemplate: confirmationRequestTemplate,
        isConfirmationRequestModal: true
      });
    });
  };

  confirmationRequestModalClose = (): void => {
    this.setState({
      isConfirmationRequestModal: false
    });
  };

  onSendConfirmationRequestClick = fields => {
    this.setState({
      isConfirmationRequestModal: false,
      isLoading: true
    });

    const { selectedItems } = this.state;
    const { user } = this.props;
    const tournamentId = this.getTournamentId();

    const { history, location } = this.props;
    const { state } = location;
    const { search, prevPath } = state as any;

    BPromise.all(
      selectedItems.map(item => {
        const dataToTransfer = {
          participantSchoolId: item.schoolId,
          fields: fields
        };

        return sendConfirmationRequest(user, tournamentId, dataToTransfer);
      })
    ).then(res => {
      this.setState({
        isLoading: false
      });

      history.push({
        pathname: prevPath,
        search: search,
        state: { tournamentId: tournamentId }
      });
    });
  };

  onCloseErrorClick = (event: Event) => {
    event.preventDefault();
    this.setState({
      isError: false
    });
  };

  renderConfirmationRequestModal(): React.ReactNode {
    const { isConfirmationRequestModal, confirmationRequestTemplate } = this.state;
    const { location } = this.props;

    const inviteKind = propz.get(location, ['state', 'inviteKind']);

    return (
      <SimpleModal isOpen={isConfirmationRequestModal} title={'Send confirmation requests'}>
        <ConfirmationRequestForm
          template={confirmationRequestTemplate}
          onCancelClick={this.confirmationRequestModalClose}
          onSendConfirmationRequestsClick={this.onSendConfirmationRequestClick}
          inviteKind={inviteKind as INVITE_KIND_TYPE}
        />
      </SimpleModal>
    );
  }

  renderSchoolsDeleteInfoModal(): React.ReactNode {
    const { selectedItems, isSchoolsDeleteInfoModalShow } = this.state;
    const schoolNames = selectedItems.map(participants => participants.school.name).join(', ');
    const infoText = `You are removing schools from the tournament: ${schoolNames}.\nAll relevant teams will be also removed. Continue?`;

    return (
      <SimpleModal
        isOpen={isSchoolsDeleteInfoModalShow}
        title={'Remove from tournament'}
        body={infoText}
        buttonText={'Remove'}
        onButtonClick={this.removeTournamentParticipants}
        onCloseClick={this.isSchoolsDeleteModalClose}
      />
    );
  }

  renderSendingError(): React.ReactNode {
    const { isError } = this.state;
    if (!isError) {
      return null;
    }

    const errorMessage =
      'The school you are trying to remove is taking part in some events within the tournament. ' +
      'Please, remove those events first to be able to exclude the school from the competition.';

    return (
      <SimpleAlert
        message={errorMessage}
        additionalClassNames={'alert-danger'}
        onCloseAlertClick={this.onCloseErrorClick}
      />
    );
  }

  goBack = () => {
    const { history, location } = this.props;
    const { state } = location;
    const { search, prevPath } = state as any;
    const tournamentId = this.getTournamentId();

    history.push({
      pathname: prevPath,
      search: search,
      state: { tournamentId: tournamentId }
    });
  };

  renderGrid() {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isSelectAllChecked,
      currentPage,
      total,
      tournament
    } = this.state;

    const actionItems = [
      {
        itemText: 'Send confirmation request',
        onItemClick: this.sendConfirmationRequest,
        isActive: selectedItems.length > 0
      },
      {
        itemText: 'Remove from tournament',
        onItemClick: this.isSchoolsDeleteModalOpen,
        isActive: selectedItems.length > 0
      }
    ];

    const filterOptions = {};
    const gridTitle = typeof tournament !== 'undefined' ? `${tournament.name} / Participants` : '';

    return (
      <Grid2
        dataItems={items}
        filters={filters}
        currentPage={currentPage}
        total={total}
        isSelectAllChecked={isSelectAllChecked}
        isDataFiltered={isDataFiltered}
        sortDirection={sortDirection}
        sortColumnsName={sortColumnsName}
        isShowFilter={isShowFilter}
        dataItemsSelected={selectedItems}
        columns={COLUMNS}
        actionItems={actionItems}
        options={filterOptions}
        onItemClick={this.onItemClick}
        onSortClick={this.onTableSortClick}
        onApplyFilterClick={this.onApplyFilterClick}
        onClearFilterClick={this.onClearFilterClick}
        onTableFilterChange={this.onTableFilterChange}
        onTableFilterClick={this.onTableFilterClick}
        setCurrentPageParams={this.setCurrentPageParams}
        onSelectAllClick={this.onSelectAllClick}
        onSelectAllOnPageClick={this.onSelectAllOnPageClick}
        onUnselectAllClick={this.onUnselectAllClick}
        gridTitle={gridTitle}
        goBack={this.goBack}
      />
    );
  }

  render() {
    const { isLoading, isConfirmationRequestModal, isSchoolsDeleteInfoModalShow, isError } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes = isConfirmationRequestModal || isSchoolsDeleteInfoModalShow || isError ? 'mt-3 modal-open' : 'mt-3';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderSendingError()}
        {this.renderSchoolsDeleteInfoModal()}
        {this.renderConfirmationRequestModal()}
        <div className="row">
          <div className="col-md-12">{this.renderGrid()}</div>
        </div>
      </div>
    );
  }
}
