import * as React from 'react';
import * as BPromise from 'bluebird';
import * as propz from 'propz';
import { AppUser } from 'Src/views/App/App';
import { parse } from 'query-string';
import { getOrder, getSearchFilter, getSearchOrder, getServerFieldSectionWhere } from 'Src/helpers/table/table';
import {
  ColumnDefinition,
  getFilters2,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { History, Location } from 'history';
import { Grid2 } from 'Src/components/Grid/Grid2';
import { Loader } from 'Src/components/Loader/Loader';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { getTournament } from 'Src/helpers/service/adminSU/tournamentsTable';
import { Tournament } from 'Src/models/tournament';
import {
  getEventAges,
  getEventGender,
  getEventGroupName,
  getEventPitch,
  getEventStartTime,
  getEventStatus,
  getIndividualTournamentEventActiveSchoolPlayers
} from 'Src/helpers/accessor/accessor';
import {
  getSelectOptionForSport,
  getSelectOptionForTournamentCustomGroups,
  getSelectOptionForTournamentEventAges,
  getSelectOptionForTournamentEventGender,
  getSelectOptionForTournamentEventStatus
} from 'Src/helpers/table/select';
import { isIndividualTournament, isTeamTournament } from 'Src/helpers/tournament/tournament';
import {
  getAllSchoolTournamentEvents,
  getAllSchoolTournamentEventSports,
  getSchoolTournamentEvents,
  getSchoolTournamentEventsCount
} from 'Src/helpers/service/admin/tournamentEvents';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { AddPlayerForm } from 'Src/components/AddPlayerForm/AddPlayerForm';
import {
  isEventStatusFinished,
  isNonTeamSportEvent,
  isTeamOrTwoOnTwoSportEvent,
  isTeamSportEvent
} from 'Src/helpers/event/event';
import {
  addSchoolEventIndividualPlayer,
  addSchoolEventTeam,
  addSchoolEventTeamPlayer,
  removeSchoolEventIndividualPlayer,
  removeSchoolEventTeamPlayer
} from 'Src/helpers/service/admin/tournamentTeams';
import { RemovePlayerForm } from 'Src/components/RemovePlayerForm/RemovePlayerForm';
import { SchoolEvent } from '../../../../models/event';
import { getEventNameWithLink } from '../../../../helpers/cell/cell';
import { SchoolIndividualTournamentIndividualEventResults } from './Results/SchoolIndividualTournamentIndividualEventResults';
import {
  createIndividualScore,
  createSchoolScore,
  createTeamScore,
  updateIndividualScore,
  updateSchoolScore,
  updateTeamScore
} from '../../../../helpers/service/admin/eventResults';
import { SchoolIndividualTournamentTeamEventResults } from './Results/SchoolIndividualTournamentTeamEventResults';
import { TournamentSchoolIndividualManager } from './Managers/TournamentSchoolIndividualManager';
import { TournamentSchoolTeamManager } from './Managers/TournamentSchoolTeamManager';
import { Sport } from '../../../../models/sport';
import './TournamentEvents.css';
import { ACCESS_PRESET } from '../../../../consts/album';
import { uploadImage } from '../../../../helpers/service/image';
import { addEventPhoto, getAllEventPhotos } from '../../../../helpers/service/admin/eventPhotos';
import { getSafeFileName } from '../../../../helpers/file/file';
import { getVideoConfig } from '../../../../helpers/service/nobody/config';
import { getKey } from '../../../../helpers/service/nobody/key';
import * as AWS from 'aws-sdk';
import { addConvertJob } from '../../../../helpers/service/nobody/aws';
import { addEventVideo, getAllEventVideos } from '../../../../helpers/service/admin/eventVideos';
import { isTournamentEventReadOnly } from '../../../../helpers/event/event';
import { Button } from 'Src/components/Button/Button';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Sport',
    field: 'sport',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: ['sport', 'name']
  },
  {
    text: 'Event',
    field: 'generatedName',
    isSort: false,
    type: FILTER_TYPE.NONE,
    cell: getEventNameWithLink
  },
  {
    text: 'Age',
    field: 'tournamentAges',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getEventAges
  },
  {
    text: 'Gender',
    field: 'gender',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getEventGender
  },
  {
    text: 'Group name',
    field: 'eventTournamentGroup',
    isSort: false,
    type: FILTER_TYPE.SELECT,
    accessor: getEventGroupName
  },
  {
    text: 'Start time',
    field: 'tournamentStartTime',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getEventStartTime
  },
  {
    text: 'Status',
    field: 'status',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getEventStatus
  }
];
const COLUMNS_FOR_INDIVIDUAL_TOURNAMENT: ColumnDefinition[] = [
  {
    text: 'Sport',
    field: 'sport',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: ['sport', 'name']
  },
  {
    text: 'Event',
    field: 'generatedName',
    isSort: false,
    type: FILTER_TYPE.NONE,
    cell: getEventNameWithLink
  },
  {
    text: 'Age',
    field: 'tournamentAges',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getEventAges
  },
  {
    text: 'Gender',
    field: 'gender',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getEventGender
  },
  {
    text: 'Start time',
    field: 'tournamentStartTime',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getEventStartTime
  },
  {
    text: 'Athletes',
    field: '',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getIndividualTournamentEventActiveSchoolPlayers
  },
  {
    text: 'Status',
    field: 'status',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getEventStatus
  }
];
const COLUMNS_FOR_TEAM_TOURNAMENT: ColumnDefinition[] = [
  {
    text: 'Sport',
    field: 'sport',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: ['sport', 'name']
  },
  {
    text: 'Event',
    field: 'generatedName',
    isSort: false,
    type: FILTER_TYPE.NONE,
    cell: getEventNameWithLink
  },
  {
    text: 'Age',
    field: 'tournamentAges',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getEventAges
  },
  {
    text: 'Gender',
    field: 'gender',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getEventGender
  },
  {
    text: 'Group name',
    field: 'eventTournamentGroup',
    isSort: false,
    type: FILTER_TYPE.SELECT,
    accessor: getEventGroupName
  },
  {
    text: 'Start time',
    field: 'startTime',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getEventStartTime
  },
  {
    text: 'Status',
    field: 'status',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getEventStatus
  },
  {
    text: 'Pitch',
    field: 'pitch',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getEventPitch
  }
];

interface State {
  items: SchoolEvent[];
  currentPage: number;
  selectedItems: SchoolEvent[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  filters: any;
  isDataFiltered: boolean;
  isLoading: boolean;
  total: number;
  basePath: string;
  isSelectAllChecked: boolean;
  tournament: Tournament;

  isAddPlayerModalOpen: boolean;
  isRemovePlayerModalOpen: boolean;
  isUpdateResultsModalOpen: boolean;
  isEditPlayersModalOpen: boolean;
  isCancelConfirmationModalOpen: boolean;

  sports: Sport[];
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export class TournamentEvents extends React.Component<Props, State> {
  photoFileInputRef: any;
  videoFileInputRef: any;

  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      filters: {},
      isDataFiltered: false,
      isLoading: false,
      total: 0,
      basePath: '',
      isSelectAllChecked: false,
      tournament: undefined,
      isAddPlayerModalOpen: false,
      isRemovePlayerModalOpen: false,
      isUpdateResultsModalOpen: false,
      isEditPlayersModalOpen: false,
      isCancelConfirmationModalOpen: false,
      sports: []
    };

    this.photoFileInputRef = React.createRef();
    this.videoFileInputRef = React.createRef();
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setAdditionalItems().then(res => {
      this.setItems();
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  getColumns(): ColumnDefinition[] {
    const { tournament } = this.state;
    switch (true) {
      case isTeamTournament(tournament):
        return COLUMNS_FOR_TEAM_TOURNAMENT;
      case isIndividualTournament(tournament):
        return COLUMNS_FOR_INDIVIDUAL_TOURNAMENT;
      default:
        return COLUMNS;
    }
  }

  getTournamentId(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    const tournamentId = search.tournamentId;
    return tournamentId;
  }

  setAdditionalItems() {
    const { user } = this.props;

    const tournamentId = this.getTournamentId();

    return BPromise.all([
      getTournament(user, tournamentId),
      getAllSchoolTournamentEventSports(user, tournamentId)
    ]).then(([tournament, sports]) => {
      this.setState({
        tournament: tournament,
        sports: sports
      });

      return true;
    });
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(this.getColumns(), search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters, { isCorrectTime: true });

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const tournamentId = this.getTournamentId();

    const getItemsPromise = getSchoolTournamentEvents(user, tournamentId, serverQueryFilter);
    const getItemsCountPromise = getSchoolTournamentEventsCount(user, tournamentId, where);

    const promises = [getItemsCountPromise, getItemsPromise];

    return BPromise.all(promises).then(([countObj, items]) => {
      this.setState({
        currentPage: page,
        sortDirection: sortDirection,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: items,
        total: countObj.count,
        basePath: history.location.pathname,
        isLoading: false
      });

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const tournamentId = this.getTournamentId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `tournamentId=${tournamentId}&${search.join('&')}`
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: nextSelectedItems,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    const tournamentId = this.getTournamentId();

    getAllSchoolTournamentEvents(user, tournamentId, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const nextSelectedItems = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: nextSelectedItems,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const tournamentId = this.getTournamentId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `tournamentId=${tournamentId}&${search.join('&')}`
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = this.getColumns().find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    const tournamentId = this.getTournamentId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `tournamentId=${tournamentId}&${search.join('&')}`
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    const tournamentId = this.getTournamentId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `tournamentId=${tournamentId}`
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  isOneItemSelected(): boolean {
    const selectedItems = this.state.selectedItems;
    return selectedItems.length === 1;
  }

  goBack = () => {
    const { history, location } = this.props;
    const { state }: any = location;
    const { search, isGoBackWithoutSave, prevRoutePath } = state;
    const tournamentId = isGoBackWithoutSave ? undefined : this.getTournamentId();

    history.push({
      pathname: prevRoutePath,
      search,
      state: { tournamentId }
    });
  };

  onAddPlayerClick = (): void => {
    this.setState({
      isAddPlayerModalOpen: true
    });
  };

  onRemovePlayerClick = (): void => {
    this.setState({
      isRemovePlayerModalOpen: true
    });
  };

  onUpdateResultsClick = (): void => {
    this.setState({
      isUpdateResultsModalOpen: true
    });
  };

  onUpdateResultsCancelClick = (): void => {
    this.setState({
      isUpdateResultsModalOpen: false
    });
  };

  isAddPlayerActive(): boolean {
    const { selectedItems } = this.state;
    const tournament = propz.get(selectedItems, ['0', 'tournament']);

    return selectedItems.length === 1 && isIndividualTournament(tournament);
  }

  isUpdateResultsActive(): boolean {
    const { selectedItems } = this.state;
    if (selectedItems.length !== 1) {
      return false;
    }

    const [event] = selectedItems;
    const { tournament } = event;
    const { isSchoolCanSubmitResult } = tournament;

    return (
      isIndividualTournament(tournament) &&
      isSchoolCanSubmitResult &&
      !isEventStatusFinished(event) &&
      !isTournamentEventReadOnly(event)
    );
  }

  isRemovePlayerActive(): boolean {
    const { selectedItems } = this.state;
    const tournament = propz.get(selectedItems, ['0', 'tournament']);

    return selectedItems.length === 1 && isIndividualTournament(tournament);
  }

  onAddPlayerCancelClick = (): void => {
    this.setState({
      isAddPlayerModalOpen: false
    });
  };

  onRemovePlayerCancelClick = (): void => {
    this.setState({
      isRemovePlayerModalOpen: false
    });
  };

  onAddPlayerSubmitClick = player => {
    const { selectedItems } = this.state;
    const { user } = this.props;
    const { activeSchoolId } = user;
    const event = selectedItems[0];
    const eventId = event.id;

    const playerUserId = player.userId;
    const playerPermissionId = player.permissionId;

    const playerData = {
      userId: playerUserId,
      permissionId: playerPermissionId
    };

    const playerTeamData = {
      userId: playerUserId,
      permissionId: playerPermissionId
    };

    //Relays is team sport, but in individual tournament
    //So we handle this case as for the team

    const teamData = {
      gender: event.gender,
      ages: event.ages,
      players: [playerTeamData]
    };

    const team = event.teamsData.find(team => team.schoolId === activeSchoolId);

    let addPromise;

    this.setState({
      isLoading: true,
      isAddPlayerModalOpen: false
    });

    if (isTeamOrTwoOnTwoSportEvent(event)) {
      if (typeof team === 'undefined') {
        addPromise = addSchoolEventTeam(user, eventId, [teamData]);
      } else {
        const teamId = team.id;
        addPromise = addSchoolEventTeamPlayer(user, eventId, teamId, playerTeamData);
      }
    } else {
      addPromise = addSchoolEventIndividualPlayer(user, eventId, playerData);
    }

    addPromise
      .then(res => {
        return this.setItems();
      })
      .then(res => {
        this.setState({
          isLoading: false,
          selectedItems: []
        });
      });
  };

  renderAddPlayerModal(): React.ReactNode {
    const { isAddPlayerModalOpen, selectedItems } = this.state;
    const event = selectedItems[0];

    const { user } = this.props;

    return (
      <SimpleModal isOpen={isAddPlayerModalOpen} title={'Add player'}>
        <AddPlayerForm
          onSubmit={this.onAddPlayerSubmitClick}
          onCancel={this.onAddPlayerCancelClick}
          event={event}
          user={user}
        />
      </SimpleModal>
    );
  }

  onRemovePlayerSubmitClick = player => {
    const { selectedItems } = this.state;
    const event = selectedItems[0];
    const eventId = event.id;
    const { user } = this.props;
    const { activeSchoolId } = user;

    const playerUserId = player.userId;
    const playerPermissionId = player.permissionId;

    const team = event.teamsData.find(team => team.schoolId === activeSchoolId);
    const playerIndex = event.players.findIndex(
      player => player.userId === playerUserId && player.permissionId === playerPermissionId
    );
    const playerId = event.players[playerIndex]._id;

    this.setState({
      isLoading: true,
      isRemovePlayerModalOpen: false
    });

    let removePromise;

    if (isTeamOrTwoOnTwoSportEvent(event)) {
      const teamId = team.id;
      removePromise = removeSchoolEventTeamPlayer(user, eventId, teamId, playerId);
    } else {
      removePromise = removeSchoolEventIndividualPlayer(user, eventId, playerId);
    }

    removePromise
      .then(res => {
        return this.setItems();
      })
      .then(res => {
        this.setState({
          isLoading: false,
          selectedItems: []
        });
      });
  };

  renderRemovePlayerModal(): React.ReactNode {
    const { user } = this.props;
    const { isRemovePlayerModalOpen, selectedItems } = this.state;
    const event = selectedItems[0];

    return (
      <SimpleModal isOpen={isRemovePlayerModalOpen} title={'Remove player'}>
        <RemovePlayerForm
          onSubmit={this.onRemovePlayerSubmitClick}
          onCancel={this.onRemovePlayerCancelClick}
          event={event}
          user={user}
        />
      </SimpleModal>
    );
  }

  renderUpdateResultsModal(): React.ReactNode {
    const { user } = this.props;
    const { isUpdateResultsModalOpen, selectedItems } = this.state;
    const [event] = selectedItems;

    if (typeof event === 'undefined') {
      return null;
    }

    return (
      <SimpleModal isOpen={isUpdateResultsModalOpen} title={'Update results'}>
        {isTeamSportEvent(event) ? (
          <SchoolIndividualTournamentTeamEventResults
            event={event}
            onSaveClick={this.onResultsSaveClick}
            onCloseClick={this.onUpdateResultsCancelClick}
            user={user}
          />
        ) : (
          <SchoolIndividualTournamentIndividualEventResults
            event={event}
            onSaveClick={this.onResultsSaveClick}
            onCloseClick={this.onUpdateResultsCancelClick}
            user={user}
          />
        )}
      </SimpleModal>
    );
  }

  onResultsSaveClick = (scores): void => {
    const { selectedItems } = this.state;
    const [event] = selectedItems;
    const { user } = this.props;
    const { id } = event;

    let promises = [];

    switch (true) {
      case isNonTeamSportEvent(event):
        promises = promises.concat(
          scores
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => createIndividualScore(user, id, score))
        );

        promises = promises.concat(
          scores
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => updateIndividualScore(user, id, score.scoreId, score))
        );

        BPromise.all(promises).then(res => {
          this.setState({
            isUpdateResultsModalOpen: false,
            selectedItems: [],
            isSelectAllChecked: false
          });
          this.setItems();
        });
        break;
      case isTeamOrTwoOnTwoSportEvent(event):
        //players
        promises = promises.concat(
          scores
            .filter(score => typeof score.userId !== 'undefined')
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => createIndividualScore(user, id, score))
        );

        promises = promises.concat(
          scores
            .filter(score => typeof score.userId !== 'undefined')
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => updateIndividualScore(user, id, score.scoreId, score))
        );

        //teams
        promises = promises.concat(
          scores
            .filter(score => typeof score.userId === 'undefined' && typeof score.teamId !== 'undefined')
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => createTeamScore(user, id, score))
        );

        promises = promises.concat(
          scores
            .filter(score => typeof score.userId === 'undefined' && typeof score.teamId !== 'undefined')
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => updateTeamScore(user, id, score.scoreId, score))
        );

        //schools
        promises = promises.concat(
          scores
            .filter(score => typeof score.schoolId !== 'undefined')
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => createSchoolScore(user, id, score))
        );

        promises = promises.concat(
          scores
            .filter(score => typeof score.schoolId !== 'undefined')
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => updateSchoolScore(user, id, score.scoreId, score))
        );

        BPromise.all(promises).then(res => {
          this.setState({
            isUpdateResultsModalOpen: false,
            selectedItems: [],
            isSelectAllChecked: false
          });
          this.setItems();
        });
        break;
      default:
        console.error('Can not find sport in event');
        this.setState({
          isUpdateResultsModalOpen: false,
          selectedItems: [],
          isSelectAllChecked: false
        });
    }
  };

  isEditPlayersActive(): boolean {
    const { selectedItems } = this.state;
    if (selectedItems.length !== 1) {
      return false;
    }

    const [event] = selectedItems;

    return !isEventStatusFinished(event) && !isTournamentEventReadOnly(event);
  }

  onEditPlayersClick = (): void => {
    this.setState({
      isEditPlayersModalOpen: true
    });
  };

  onEditPlayersCancelClick = (): void => {
    this.setState({
      isEditPlayersModalOpen: false,
      isCancelConfirmationModalOpen: false
    });
  };

  renderCancelConfirmationModal = () => {
    const { isCancelConfirmationModalOpen } = this.state;

    return (
      <SimpleModal isOpen={isCancelConfirmationModalOpen} title={'Confirm the action'}>
        <div>You haven’t saved your team, are you sure you want to leave this page?</div>
        <div>
          <Button
            onClick={this.onCancelConfirmationModalClose}
            text={'Cancel'}
            customClass={'btn-secondary mt-3 mr-3'}
          />
          <Button onClick={this.onEditPlayersCancelClick} text={'Ok'} customClass={'mt-3'} />
        </div>
      </SimpleModal>
    );
  };

  onCancelConfirmationModalOpen = () => {
    this.setState({
      isCancelConfirmationModalOpen: true
    });
  };

  onCancelConfirmationModalClose = () => {
    this.setState({
      isCancelConfirmationModalOpen: false
    });
  };

  cancelWithOrWithoutConfirmation = (itemsAddBuffer, itemsRemoveBuffer) => {
    const isChangesInTeamExist = itemsAddBuffer.length > 0 || itemsRemoveBuffer.length > 0;

    isChangesInTeamExist ? this.onCancelConfirmationModalOpen() : this.onEditPlayersCancelClick();
  };

  onEditPlayersSaveClick = (): void => {
    this.setState({
      isEditPlayersModalOpen: false,
      selectedItems: [],
      isSelectAllChecked: false
    });
    this.setItems();
  };

  renderEditPlayersModal(): React.ReactNode {
    const { user } = this.props;
    const { isEditPlayersModalOpen, selectedItems } = this.state;
    const [event] = selectedItems;

    if (typeof event === 'undefined') {
      return null;
    }

    return (
      <SimpleModal
        isOpen={isEditPlayersModalOpen}
        title={'Edit participants'}
        customClass="mTournamentEditPlayersFormWidth"
      >
        {isTeamOrTwoOnTwoSportEvent(event) ? (
          <TournamentSchoolTeamManager
            onSaveClick={this.onEditPlayersSaveClick}
            onCancelClick={this.cancelWithOrWithoutConfirmation}
            event={event}
            user={user}
          />
        ) : (
          <TournamentSchoolIndividualManager
            onSaveClick={this.onEditPlayersSaveClick}
            onCancelClick={this.onEditPlayersCancelClick}
            event={event}
            user={user}
          />
        )}
      </SimpleModal>
    );
  }

  onAddPhotoClick = () => {
    let event;
    //This is true only for IE,firefox
    //Dirty hack from https://codeproject.com/Tips/893254/JavaScript-Triggering-Event-Manually-in-Internet-E
    //However, MDN recommend use very similar polyfill https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/MouseEvent#Polyfill
    if (document.createEvent) {
      // To create a mouse event , first we need to create an event and then initialize it.
      event = document.createEvent('MouseEvent');
      event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    } else {
      event = new MouseEvent('click');
    }
    this.photoFileInputRef.dispatchEvent(event);
  };

  onAddVideoClick = () => {
    let event;
    //This is true only for IE,firefox
    //Dirty hack from https://codeproject.com/Tips/893254/JavaScript-Triggering-Event-Manually-in-Internet-E
    //However, MDN recommend use very similar polyfill https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/MouseEvent#Polyfill
    if (document.createEvent) {
      // To create a mouse event , first we need to create an event and then initialize it.
      event = document.createEvent('MouseEvent');
      event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    } else {
      event = new MouseEvent('click');
    }
    this.videoFileInputRef.dispatchEvent(event);
  };

  onPhotoFileChange = eventDescriptor => {
    const { user } = this.props;
    const { selectedItems } = this.state;
    const [event] = selectedItems;
    const { id } = event;
    const image = eventDescriptor.target.files[0];

    const { activeSchool } = user;
    const photoAccessPreset = propz.get(activeSchool, ['photoAccessPreset'], ACCESS_PRESET.PRIVATE);

    this.setState({
      isLoading: true
    });

    uploadImage(user, image)
      .then(response => {
        const picUrl = `${window.apiImg}/images/${response.key}`;
        const data = {
          picUrl: picUrl,
          originalFileName: image.name,
          accessPreset: photoAccessPreset
        };
        return addEventPhoto(user, id, data);
      })
      .then(res => {
        this.setState({
          isLoading: false
        });
      });
  };

  onVideoFileChange = eventDescriptor => {
    const { user } = this.props;
    const { selectedItems } = this.state;
    const [event] = selectedItems;

    const { id } = event;
    const video = eventDescriptor.target.files[0];
    const { name } = video;
    const safeName = getSafeFileName(name);

    const { activeSchool } = user;
    const photoAccessPreset = propz.get(activeSchool, ['photoAccessPreset'], ACCESS_PRESET.PRIVATE);

    this.setState({
      isLoading: true
    });

    const promises = [getVideoConfig(user), getKey(user)];

    let s3, folderKey, folderName, videoFullName, bucket;

    BPromise.all(promises)
      .then(([videoConfig, keyObj]) => {
        const { accessKey, secretAccessKey, bucketRegion } = videoConfig;
        bucket = videoConfig.bucketName;

        s3 = new AWS.S3({
          region: bucketRegion,
          credentials: {
            accessKeyId: accessKey,
            secretAccessKey: secretAccessKey
          }
        });

        folderKey = keyObj.key;
        folderName = `orig/${folderKey}/`;
        videoFullName = folderName + safeName;

        return s3.putObject({ Key: folderName, Bucket: bucket }).promise();
      })
      .then(res => {
        return s3.putObject({ Key: videoFullName, Bucket: bucket, Body: video }).promise();
      })
      .then(res => {
        const data = {
          key: folderKey,
          fileName: safeName,
          bucketName: bucket
        };
        return addConvertJob(user, data);
      })
      .then(jobObj => {
        const job = jobObj.Job;
        const jobId = job.Id;

        const data = {
          fileName: safeName,
          key: folderKey,
          jobId: jobId,
          accessPreset: photoAccessPreset
        };

        return addEventVideo(user, id, data);
      })
      .then(videos => {
        this.setState({
          isLoading: false
        });
      });
  };

  renderPhotoFileInput() {
    return (
      <input
        className="eAddPhotoButton_fileInput"
        type="file"
        onChange={this.onPhotoFileChange}
        ref={input => (this.photoFileInputRef = input)}
      />
    );
  }

  renderVideoFileInput() {
    return (
      <input
        className="eAddVideoButton_fileInput"
        type="file"
        onChange={this.onVideoFileChange}
        ref={input => (this.videoFileInputRef = input)}
      />
    );
  }

  renderGrid() {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isSelectAllChecked,
      currentPage,
      total,
      tournament,
      sports
    } = this.state;

    const actionItems = [
      {
        itemText: 'Edit participants',
        onItemClick: this.onEditPlayersClick,
        isActive: this.isEditPlayersActive()
      },
      {
        itemText: 'Update results',
        onItemClick: this.onUpdateResultsClick,
        isActive: this.isUpdateResultsActive()
      },
      {
        itemText: 'Add photo',
        onItemClick: this.onAddPhotoClick,
        isActive: selectedItems.length === 1
      },
      {
        itemText: 'Add video',
        onItemClick: this.onAddVideoClick,
        isActive: selectedItems.length === 1
      }
      // {
      //   itemText: 'Add the player to an event',
      //   onItemClick: this.onAddPlayerClick,
      //   isActive: this.isAddPlayerActive()
      // },
      // {
      //   itemText: 'Remove player from event',
      //   onItemClick: this.onRemovePlayerClick,
      //   isActive: this.isRemovePlayerActive()
      // }
    ];

    const customGroups = propz.get(tournament, ['customGroups'], []);

    const filterOptions = isTeamTournament(tournament)
      ? {
          eventTournamentGroup: getSelectOptionForTournamentCustomGroups(customGroups),
          tournamentAges: getSelectOptionForTournamentEventAges(tournament),
          gender: getSelectOptionForTournamentEventGender(),
          status: getSelectOptionForTournamentEventStatus(),
          sport: getSelectOptionForSport(sports)
        }
      : {
          tournamentAges: getSelectOptionForTournamentEventAges(tournament),
          gender: getSelectOptionForTournamentEventGender(),
          status: getSelectOptionForTournamentEventStatus(),
          sport: getSelectOptionForSport(sports)
        };
    const gridTitle = typeof tournament !== 'undefined' ? `${tournament.name} / Events` : '';

    const { user } = this.props;

    return (
      <Grid2
        dataItems={items}
        filters={filters}
        currentPage={currentPage}
        total={total}
        isSelectAllChecked={isSelectAllChecked}
        isDataFiltered={isDataFiltered}
        sortDirection={sortDirection}
        sortColumnsName={sortColumnsName}
        isShowFilter={isShowFilter}
        dataItemsSelected={selectedItems}
        columns={this.getColumns()}
        actionItems={actionItems}
        options={filterOptions}
        onItemClick={this.onItemClick}
        onSortClick={this.onTableSortClick}
        onApplyFilterClick={this.onApplyFilterClick}
        onClearFilterClick={this.onClearFilterClick}
        onTableFilterChange={this.onTableFilterChange}
        onTableFilterClick={this.onTableFilterClick}
        setCurrentPageParams={this.setCurrentPageParams}
        onSelectAllClick={this.onSelectAllClick}
        onSelectAllOnPageClick={this.onSelectAllOnPageClick}
        onUnselectAllClick={this.onUnselectAllClick}
        gridTitle={gridTitle}
        goBack={this.goBack}
        user={user}
      />
    );
  }

  render() {
    const {
      isLoading,
      isAddPlayerModalOpen,
      isRemovePlayerModalOpen,
      isUpdateResultsModalOpen,
      isEditPlayersModalOpen
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes =
      isAddPlayerModalOpen || isRemovePlayerModalOpen || isUpdateResultsModalOpen || isEditPlayersModalOpen
        ? 'mt-3 modal-open'
        : 'mt-3';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderAddPlayerModal()}
        {this.renderRemovePlayerModal()}
        {this.renderUpdateResultsModal()}
        {this.renderEditPlayersModal()}
        {this.renderPhotoFileInput()}
        {this.renderVideoFileInput()}
        {this.renderCancelConfirmationModal()}
        <div className="row">
          <div className="col-md-12">{this.renderGrid()}</div>
        </div>
      </div>
    );
  }
}
