import * as React from 'react';
import { AppUser } from 'Src/views/App/App';
import { ROLE, ROLE_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/user';
import { become } from 'Src/helpers/auth/auth';
import { RoleSession } from 'Src/models/sessions';
import { Role } from 'Src/models/roles';
import './RoleSelector.css';
import { Loader } from 'Src/components/Loader/Loader';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { Permission } from 'Src/models/permission';
import { getRoles } from '../../../helpers/service/admin/user';

interface Props {
  history: any;
  user: AppUser;
  setDefaultState: () => void;
  onRoleSelect: (
    roleName: string,
    key: string,
    permissionSchoolId: string,
    permissionSchoolName: string,
    roleSession: RoleSession,
    permission: Permission
  ) => void;
  updateIsRolesNotExist: (isRoleExist: boolean) => void;
}

interface State {
  availableRoles: Role[];
  isLoading: boolean;
  isModalOpen: boolean;
}

export class RoleSelector extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      availableRoles: [],
      isLoading: false,
      isModalOpen: false
    };
  }

  componentDidMount() {
    const user = this.props.user;

    this.setState({
      isLoading: true
    });

    getRoles(user)
      .then((roles: Role[]) => {
        const rolesCount = roles.length;
        switch (rolesCount) {
          case 0:
            const { updateIsRolesNotExist } = this.props;
            updateIsRolesNotExist(true);
            break;
          case 1:
            const [role] = roles;
            if (role.permissions.length === 1) {
              this.becomeByRole(role);
            } else {
              this.setState({
                availableRoles: roles,
                isLoading: false
              });
            }
            break;
          default:
            this.setState({
              availableRoles: roles,
              isLoading: false
            });
        }
      })
      .catch(err => {
        this.props.setDefaultState();
      });
  }

  becomeByRole(role) {
    const user = this.props.user;
    const roleName = role.name;
    const [permission] = role.permissions;
    const permissionSchoolId = role.permissions[0].schoolId;
    const permissionSchoolName = role.permissions[0].school.name;

    const isRoleAdmin = roleName === ROLE.ADMIN;
    const isRoleManager = roleName === ROLE.MANAGER;
    const isRoleParent = roleName === ROLE.PARENT;
    const isRoleCoach = roleName === ROLE.COACH;
    const isRoleTeacher = roleName === ROLE.TEACHER;
    const isRoleStudent = roleName === ROLE.STUDENT;

    const isAccessEnabled =
      isRoleAdmin || isRoleManager || isRoleParent || isRoleCoach || isRoleTeacher || isRoleStudent;

    if (!isAccessEnabled) {
      this.setState({
        isModalOpen: true,
        isLoading: false
      });
    } else {
      become(user, roleName).then((roleSession: RoleSession) => {
        const { role: userRole, key } = roleSession;

        this.props.onRoleSelect(userRole, key, permissionSchoolId, permissionSchoolName, roleSession, permission);
      });
    }
  }

  becomeByPermission(permission: Permission & { role: string }) {
    const { user, onRoleSelect } = this.props;
    const { schoolId, preset, school } = permission;

    const permissionSchoolId = schoolId;
    const permissionSchoolName = school.name;

    const isRoleAdmin = preset === ROLE.ADMIN;
    const isRoleManager = preset === ROLE.MANAGER;
    const isRoleParent = preset === ROLE.PARENT;
    const isRoleCoach = preset === ROLE.COACH;
    const isRoleTeacher = preset === ROLE.TEACHER;
    const isRoleStudent = preset === ROLE.STUDENT;

    const isAccessEnabled =
      isRoleAdmin || isRoleManager || isRoleParent || isRoleCoach || isRoleTeacher || isRoleStudent;

    if (!isAccessEnabled) {
      this.setState({
        isModalOpen: true,
        isLoading: false
      });
    } else {
      become(user, preset).then((roleSession: RoleSession) => {
        const { role: userRole, key } = roleSession;

        onRoleSelect(userRole, key, permissionSchoolId, permissionSchoolName, roleSession, permission);
      });
    }
  }

  //copy-paste from old frontend
  getPermissions(roles: Role[]): (Permission & { role: string })[] {
    if (roles && roles.length > 0) {
      let permissions: (Permission & { role: string })[] = [];

      let isAlreadyHaveParentPermission = false;
      let isAlreadyHaveStudentPermission = false;

      roles.forEach(role => {
        role.permissions.forEach(permission => {
          // Always add all permissions besides PARENT and STUDENT permissions.
          // Add parent and student permissions only at once.
          switch (true) {
            case permission.preset === 'PARENT' && !isAlreadyHaveParentPermission:
              permissions.push({
                ...permission,
                role: role.name
              });
              isAlreadyHaveParentPermission = true;
              break;
            case permission.preset === 'STUDENT' && !isAlreadyHaveStudentPermission:
              permissions.push({
                ...permission,
                role: role.name
              });
              isAlreadyHaveStudentPermission = true;
              break;
            case permission.preset === 'PARENT' && isAlreadyHaveParentPermission:
              break;
            case permission.preset === 'STUDENT' && isAlreadyHaveStudentPermission:
              break;
            default:
              permissions.push({
                ...permission,
                role: role.name
              });
          }
        });
      });

      return permissions;
    } else {
      return [];
    }
  }

  renderRoles(): React.ReactFragment {
    const { availableRoles } = this.state;

    const permissions = this.getPermissions(availableRoles);

    return permissions.map((permission, index) => {
      const { preset, school } = permission;
      const isStudent = preset === ROLE.STUDENT;
      const isParent = preset === ROLE.PARENT;
      const isShowSchoolName = !isParent && !isStudent;
      const presetMapped = ROLE_SERVER_TO_CLIENT_MAPPING[permission.preset];
      const buttonText = isShowSchoolName ? `${presetMapped} ${school.name}` : `${presetMapped}`;

      return (
        <button
          type="button"
          className="list-group-item list-group-item-action"
          key={`role_${index}`}
          onClick={() => this.becomeByPermission(permission)}
        >
          {buttonText}
        </button>
      );
    });
  }

  onSimpleModalButtonClick = () => {
    this.props.setDefaultState();
  };

  render() {
    const { isLoading, isModalOpen } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    if (isModalOpen) {
      return (
        <SimpleModal
          isOpen={isModalOpen}
          buttonText={'Ok'}
          body={
            'You are not authorised to access the school administration console. Ask your school administrator for details.'
          }
          title={'Access forbidden'}
          onButtonClick={this.onSimpleModalButtonClick}
        />
      );
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="bRoleSelector text-center">
              <div className="eRoleSelectorImg">
                <img src="/dist/images/logoImage.png" alt={'logo image'} />
                <img src="/dist/images/logoText.png" alt={'logo text'} />
              </div>
              <p>Select role:</p>
              <div className="list-group">{this.renderRoles()}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
