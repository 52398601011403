import * as React from 'react';
import * as propz from 'propz';
import { FunctionComponent } from 'react';
import { GENDER_SERVER_TO_CLIENT_MAPPING } from '../../consts/user';
import * as Moment from 'moment';
import { DATE_FORMAT } from '../../consts/date';

interface Props {
  student: any;
}

const ROWS = [
  { title: 'Name', field: 'firstName' },
  { title: 'Surname', field: 'lastName' },
  { title: 'Gender', field: 'gender' },
  { title: 'Date of birth', field: 'birthday' },
  { title: 'Form', field: 'formName' },
  { title: 'House', field: 'houseName' },
  { title: 'Medical information', field: 'medicalInfo' },
  { title: 'SEN', field: 'specialEducationNeeds' },
  { title: 'PP', field: 'pupilsPremium' },
  { title: 'FSM', field: 'freeSchoolMeals' },
  { title: 'Photo consent opt-out', field: 'photoConsentOptOut' }
];

export const StudentSummary: FunctionComponent<Props> = props => {
  const rows = ROWS.map((row, index) => {
    let contentCell: string;
    const field = row.field;

    const student = props.student;

    switch (field) {
      case 'gender':
        contentCell = GENDER_SERVER_TO_CLIENT_MAPPING[student.gender];
        break;
      case 'formName':
        contentCell = propz.get(student, ['form', 'name'], '');
        break;
      case 'houseName':
        contentCell = propz.get(student, ['house', 'name'], '');
        break;
      case 'specialEducationNeeds':
        contentCell = student[row.field] ? 'Yes' : 'No';
        break;
      case 'pupilsPremium':
        contentCell = student[row.field] ? 'Yes' : 'No';
        break;
      case 'freeSchoolMeals':
        contentCell = student[row.field] ? 'Yes' : 'No';
        break;
      case 'photoConsentOptOut':
        contentCell = student[row.field] ? 'Yes' : 'No';
        break;
      case 'birthday':
        contentCell = student[row.field] ? Moment(student[row.field]).format(DATE_FORMAT) : '';
        break;
      default:
        contentCell = student[row.field];
    }

    return (
      <tr key={`student_summary_${index}`}>
        <td>{row.title}</td>
        <td>{contentCell}</td>
      </tr>
    );
  });

  return (
    <table className={'table table-sm mPre'}>
      <tbody>{rows}</tbody>
    </table>
  );
};

StudentSummary.displayName = 'StudentSummary';
