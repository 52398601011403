import * as React from 'react';
import * as BPromise from 'bluebird';
import * as propz from 'propz';
import * as Moment from 'moment';
import { AppUser } from 'Src/views/App/App';
import { parse } from 'query-string';
import { getOrder, getSearchFilter, getSearchOrder, getServerFieldSectionWhere } from 'Src/helpers/table/table';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { Loader } from 'Src/components/Loader/Loader';
import {
  ColumnDefinition,
  getFilters2,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { History, Location } from 'history';
import { Grid2 } from 'Src/components/Grid/Grid2';
import { Club, ClubAcceptableUsers } from 'Src/models/club';
import { getSelectOptionForClubMessageStatus, getSelectOptionForBooleanQuestion } from 'Src/helpers/table/select';
import {
  getClubMessageInvitationStatus,
  getClubMessageUpdatedAt,
  getClubStudentParents,
  getIsParticipant
} from 'Src/helpers/accessor/accessor';
import { getClub } from 'Src/helpers/service/admin/clubs';
import {
  addClubStudents,
  getAllClubStudents,
  getClubConfirmationRequestTemplate,
  getClubStudents,
  getClubStudentsCount,
  removeClubStudents
} from 'Src/helpers/service/admin/clubStudents';
import { getAllClubParticipants } from 'Src/helpers/service/admin/clubParticipants';
import { CLUB_INVITATIONS } from 'Src/consts/club';
import { sendClubMessages } from 'Src/helpers/service/admin/clubMessages';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { ConfirmationRequestForm } from 'Src/components/ConfirmationRequestForm/ConfirmationRequestForm';
import { UK_INPUT_MASK_VALIDATION, UK_INPUT_MASK } from 'Src/consts/date';
import { getClubInvitations } from 'Src/helpers/club/club';
import { ConfirmationRequestTemplate } from 'Src/models/confirmationRequestTemplate';
import './ClubsStudents.css';
import { DEFAULT_CLUB_TABLE_FILTERS } from './Clubs';
import MaskedInput from 'react-text-mask';
import { INVITE_KIND } from 'Src/consts/invite';
import { INVITE_KIND_TYPE } from 'Src/types/invite';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Name',
    field: 'firstName',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['firstName']
  },
  {
    text: 'Surname',
    field: 'lastName',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['lastName']
  },
  {
    text: 'Parent',
    field: 'parents',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getClubStudentParents
  },
  {
    text: 'Invitation status',
    field: 'clubMessageStatus',
    isSort: false,
    type: FILTER_TYPE.SELECT,
    accessor: getClubMessageInvitationStatus
  },
  {
    text: 'Updated',
    field: 'messageUpdatedAt',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getClubMessageUpdatedAt
  },
  {
    text: 'Participant',
    field: 'participant',
    isSort: false,
    type: FILTER_TYPE.SELECT,
    accessor: getIsParticipant
  },
  {
    text: 'Priority',
    field: 'priority',
    isSort: false,
    accessor: ['messagePriority'],
    type: FILTER_TYPE.NONE
  }
];

interface State {
  items: ClubAcceptableUsers[];
  currentPage: number;
  selectedItems: ClubAcceptableUsers[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;
  deadlineForAnswers: string;

  club: Club;

  isSendMessagesModalOpen: boolean;
  confirmationRequestTemplate: ConfirmationRequestTemplate;
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export class ClubStudents extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',
      deadlineForAnswers: '',

      club: undefined,
      isSendMessagesModalOpen: false,
      confirmationRequestTemplate: undefined
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setAdditionalItems().then(res => {
      this.setItems();
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  getClubId(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    const clubId = search.club;
    return clubId;
  }

  setAdditionalItems() {
    const { user } = this.props;

    const clubId = this.getClubId();

    return getClub(user, clubId).then(club => {
      this.setState({
        club: club
      });

      return true;
    });
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const clubId = this.getClubId();

    const getItemsPromise = getClubStudents(user, clubId, serverQueryFilter);
    const getItemsCountPromise = getClubStudentsCount(user, clubId, where);

    const promises = [getItemsCountPromise, getItemsPromise];

    return BPromise.all(promises).then(([countObj, items]) => {
      this.setState({
        currentPage: page,
        sortDirection: sortDirection,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: items,
        total: countObj.count,
        basePath: history.location.pathname,
        isLoading: false
      });

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const clubId = this.getClubId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `club=${clubId}&${search.join('&')}`
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: nextSelectedItems,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    const clubId = this.getClubId();

    getAllClubStudents(user, clubId, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const nextSelectedItems = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: nextSelectedItems,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const clubId = this.getClubId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `club=${clubId}&${search.join('&')}`
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { location } = this.props;
    const { state } = location;
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const clubId = this.getClubId();

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: `club=${clubId}&${search.join('&')}`,
      state
    });
  };

  onClearFilterClick = (): void => {
    const { location } = this.props;
    const { state } = location;

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    const clubId = this.getClubId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `club=${clubId}`,
      state
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  goBack = () => {
    const { history, location } = this.props;
    const search = propz.get(location, ['state', 'search']);
    const isSearchExist = typeof search !== 'undefined';

    const clubId = this.getClubId();
    history.push({
      pathname: '/clubs',
      search: isSearchExist ? search : '',
      state: {
        ...search,
        clubId
      }
    });
  };

  addClubStudents = (): void => {
    this.setState({
      isLoading: true
    });

    const { selectedItems } = this.state;

    const data = {
      participants: selectedItems.map(student => {
        return {
          userId: student.id,
          permissionId: student.permissionId
        };
      })
    };

    const { user } = this.props;

    const clubId = this.getClubId();

    addClubStudents(user, clubId, data).then(res => {
      this.setState({
        selectedItems: [],
        isSelectAllChecked: false
      });

      this.setItems();
    });
  };

  isAddToClubButtonActive(): boolean {
    const { selectedItems } = this.state;
    const isSomeSelectedItemsParticipant = selectedItems.some(student => student.isParticipant);
    return selectedItems.length !== 0 && !isSomeSelectedItemsParticipant;
  }

  removeClubStudents = (): void => {
    this.setState({
      isLoading: true
    });

    const { selectedItems } = this.state;
    const { user } = this.props;
    const clubId = this.getClubId();

    getAllClubParticipants(user, clubId).then(participants => {
      BPromise.all(
        selectedItems.map(student => {
          const userId = student.id;
          const permissionId = student.permissionId;

          const participant = participants.find(
            clubParticipant => clubParticipant.userId === userId && clubParticipant.permissionId === permissionId
          );

          if (typeof participant !== 'undefined') {
            const participantId = participant.id;
            return removeClubStudents(user, clubId, participantId);
          } else {
            console.error(`Can not find participant with userId: ${userId} permissionId: ${permissionId}`);
            return BPromise.resolve(undefined);
          }
        })
      ).then(res => {
        this.setState({
          isLoading: false
        });

        this.props.history.push({
          pathname: '/clubs',
          search: DEFAULT_CLUB_TABLE_FILTERS
        });
      });
    });
  };

  isRemoveToClubButtonActive(): boolean {
    const { selectedItems } = this.state;
    const isEverySelectedItemsParticipant = selectedItems.every(student => student.isParticipant);
    return selectedItems.length !== 0 && isEverySelectedItemsParticipant;
  }

  getPreparedUsers() {
    const { selectedItems, club } = this.state;
    const { user } = this.props;
    const { activeSchool } = user;
    const { clubInvitations } = activeSchool;
    const invitations = getClubInvitations(clubInvitations, club);
    const preparedUsers = [];

    //preparedUsers contains student user id and permission id for club invitations 'STUDENT'
    //and parent user id, parent permission id and child id for club invitations 'PARENT'
    //club invitations params get from school data
    switch (invitations) {
      case CLUB_INVITATIONS.STUDENT:
        selectedItems
          .filter(item => typeof item.email !== 'undefined')
          .forEach(student => {
            preparedUsers.push({
              userId: student.id,
              permissionId: student.permissionId
            });
          });
        break;
      case CLUB_INVITATIONS.PARENT:
        selectedItems
          .filter(item => Array.isArray(item.parents) && item.parents.length > 0)
          .forEach(student => {
            student.parents.forEach(parent => {
              preparedUsers.push({
                userId: parent.userId,
                permissionId: parent.permissionId,
                childId: student.id
              });
            });
          });
        break;
    }

    return preparedUsers;
  }

  onSendMessagesModalClick = (): void => {
    const { user } = this.props;
    const { deadlineForAnswers } = this.state;

    const preparedUsers = this.getPreparedUsers();

    const data = {
      participants: preparedUsers
    };

    if (deadlineForAnswers) {
      const deadlineForAnswersDateString = Moment(deadlineForAnswers, UK_INPUT_MASK_VALIDATION, true)
        .toDate()
        .toISOString();
      data['deadlineForAnswers'] = deadlineForAnswersDateString;
    } else {
      data['deadlineForAnswers'] = null;
    }

    if (preparedUsers.length === 0) {
      this.setState({
        selectedItems: [],
        isSelectAllChecked: false,
        isSendMessagesModalOpen: false
      });
    } else {
      this.setState({
        isLoading: true
      });

      const clubId = this.getClubId();

      sendClubMessages(user, clubId, data).then(res => {
        this.setState({
          isLoading: false,
          selectedItems: [],
          isSelectAllChecked: false,
          isSendMessagesModalOpen: false
        });

        this.setItems();
      });
    }
  };

  onSendMessagesClick = () => {
    const { club } = this.state;
    const { user } = this.props;
    const { activeSchool } = user;
    const { clubInvitations } = activeSchool;
    const invitations = getClubInvitations(clubInvitations, club);

    const isStudentInvitation = invitations === CLUB_INVITATIONS.STUDENT;
    const isAskToPay = propz.get(club, ['paymentSettings', 'askToPay']);

    if (isStudentInvitation && isAskToPay) {
      this.setState({
        isSendMessagesModalOpen: true
      });
    } else {
      this.setState({
        isLoading: true
      });

      getClubConfirmationRequestTemplate(user).then(confirmationRequestTemplate => {
        this.setState({
          isLoading: false,
          confirmationRequestTemplate: confirmationRequestTemplate,
          isSendMessagesModalOpen: true
        });
      });
    }
  };

  onCloseSendMessagesModalClick = () => {
    this.setState({
      isSendMessagesModalOpen: false
    });
  };

  onSendMessagesWithConfirmationRequestModalClick = (fields, deadlineForAnswers) => {
    const { user } = this.props;

    const preparedUsers = this.getPreparedUsers();

    let data;

    if (deadlineForAnswers) {
      const deadlineForAnswersDateString = Moment(deadlineForAnswers, UK_INPUT_MASK_VALIDATION, true)
        .toDate()
        .toISOString();

      data = {
        participants: preparedUsers,
        fields: fields,
        deadlineForAnswers: deadlineForAnswersDateString
      };
    } else {
      data = {
        participants: preparedUsers,
        fields: fields,
        deadlineForAnswers: null
      };
    }

    if (preparedUsers.length === 0) {
      this.setState({
        selectedItems: [],
        isSelectAllChecked: false,
        isSendMessagesModalOpen: false
      });
    } else {
      this.setState({
        isLoading: true
      });

      const clubId = this.getClubId();

      sendClubMessages(user, clubId, data).then(res => {
        this.setState({
          isLoading: false,
          selectedItems: [],
          isSelectAllChecked: false,
          isSendMessagesModalOpen: false
        });

        this.setItems();
      });
    }
  };

  renderSendMessagesModal(): React.ReactNode {
    const { isSendMessagesModalOpen, selectedItems, confirmationRequestTemplate, club } = this.state;
    const { user } = this.props;
    const { activeSchool } = user;
    const { clubInvitations } = activeSchool;
    const invitations = getClubInvitations(clubInvitations, club);

    const isPaymentsEnabled = propz.get(user, ['activeSchool', 'isPaymentsEnabled'], false);

    const totalStudentsCount = selectedItems.length;
    let studentsFilteredCount, studentsFiltered, diffStudentsCount;

    const isStudentInvitation = invitations === CLUB_INVITATIONS.STUDENT;
    const isAskToPay = propz.get(club, ['paymentSettings', 'askToPay']);

    let infoText = '';

    switch (invitations) {
      case CLUB_INVITATIONS.STUDENT:
        studentsFiltered = selectedItems.filter(item => typeof item.email !== 'undefined');
        studentsFilteredCount = studentsFiltered.length;
        diffStudentsCount = totalStudentsCount - studentsFilteredCount;
        infoText = `You have selected ${totalStudentsCount} students. Invitations will be sent for ${studentsFilteredCount} students. ${diffStudentsCount} students have no accounts.`;
        break;
      case CLUB_INVITATIONS.PARENT:
        studentsFiltered = selectedItems.filter(item => Array.isArray(item.parents) && item.parents.length > 0);
        studentsFilteredCount = studentsFiltered.length;
        diffStudentsCount = totalStudentsCount - studentsFilteredCount;
        infoText = `You have selected ${totalStudentsCount} students. Invitations will be sent for ${studentsFilteredCount} students. ${diffStudentsCount} students have no parent accounts.`;
        break;
    }

    const confirmationRequestTemplateFields = propz.get(confirmationRequestTemplate, ['fields'], []);
    const isConfirmationRequestTemplateFieldsExist = confirmationRequestTemplateFields.length > 0;

    switch (true) {
      case isAskToPay && isStudentInvitation: {
        return (
          <SimpleModal
            customClass="mClubSendMessagesFormWidth"
            isOpen={isSendMessagesModalOpen}
            title={'Send invitations'}
            body={
              'Sending invitations for a paid club to students is not included in your school subscription. Please get in touch with our support team for more details'
            }
            onCloseClick={this.onCloseSendMessagesModalClick}
          />
        );
      }

      case isConfirmationRequestTemplateFieldsExist: {
        return (
          <SimpleModal
            customClass="mClubSendMessagesFormWidth"
            isOpen={isSendMessagesModalOpen}
            title={'Send invitations'}
            body={infoText}
          >
            <ConfirmationRequestForm
              template={confirmationRequestTemplate}
              onCancelClick={this.onCloseSendMessagesModalClick}
              onSendConfirmationRequestsClick={this.onSendMessagesWithConfirmationRequestModalClick}
              textSubmitButton={'Send invitations'}
              isRenderDeadline={true}
              inviteKind={INVITE_KIND.CLUB_SCHOOL_INVITE as INVITE_KIND_TYPE}
            />

            {isPaymentsEnabled && !isAskToPay && (
              <div className="text-danger mt-2">
                You haven't specified the club cost so users will be booking without making payments.
              </div>
            )}
          </SimpleModal>
        );
      }

      default: {
        return (
          <SimpleModal
            isOpen={isSendMessagesModalOpen}
            title={'Send invitations'}
            body={infoText}
            buttonText={`Send ${studentsFilteredCount} messages`}
            onButtonClick={() => this.onSendMessagesModalClick()}
            onCloseClick={this.onCloseSendMessagesModalClick}
          >
            <div className="mt-4 mb-2">Deadline for answers (optional)</div>
            <MaskedInput
              type="text"
              className={'form-control'}
              onChange={this.onDeadlineChange}
              mask={UK_INPUT_MASK}
              placeholder={UK_INPUT_MASK_VALIDATION}
            />
          </SimpleModal>
        );
      }
    }
  }

  onDeadlineChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    this.setState({
      deadlineForAnswers: value
    });
  };

  render() {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isLoading,
      isSelectAllChecked,
      currentPage,
      total,
      club,
      isSendMessagesModalOpen
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes = isSendMessagesModalOpen ? 'mt-3 modal-open' : 'mt-3';

    const actionItems = [
      {
        itemText: 'Add to club',
        onItemClick: this.addClubStudents,
        isActive: this.isAddToClubButtonActive()
      },
      {
        itemText: 'Remove from club',
        onItemClick: this.removeClubStudents,
        isActive: this.isRemoveToClubButtonActive()
      },
      {
        itemText: 'Send invitations',
        onItemClick: this.onSendMessagesClick,
        isActive: selectedItems.length > 0
      }
    ];

    const filterOptions = {
      clubMessageStatus: getSelectOptionForClubMessageStatus(),
      participant: getSelectOptionForBooleanQuestion()
    };

    const gridTitle = typeof club !== 'undefined' ? `${club.name} / Eligible students` : '';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderSendMessagesModal()}
        <div className="row">
          <div className="col-md-12">
            <Grid2
              dataItems={items}
              filters={filters}
              currentPage={currentPage}
              total={total}
              isSelectAllChecked={isSelectAllChecked}
              isDataFiltered={isDataFiltered}
              sortDirection={sortDirection}
              sortColumnsName={sortColumnsName}
              isShowFilter={isShowFilter}
              dataItemsSelected={selectedItems}
              columns={COLUMNS}
              actionItems={actionItems}
              options={filterOptions}
              onItemClick={this.onItemClick}
              onSortClick={this.onTableSortClick}
              onApplyFilterClick={this.onApplyFilterClick}
              onClearFilterClick={this.onClearFilterClick}
              onTableFilterChange={this.onTableFilterChange}
              onTableFilterClick={this.onTableFilterClick}
              setCurrentPageParams={this.setCurrentPageParams}
              onSelectAllClick={this.onSelectAllClick}
              onSelectAllOnPageClick={this.onSelectAllOnPageClick}
              onUnselectAllClick={this.onUnselectAllClick}
              goBack={this.goBack}
              gridTitle={gridTitle}
            />
          </div>
        </div>
      </div>
    );
  }
}
