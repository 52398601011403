import * as React from 'react';
import { FunctionComponent } from 'react';
import { TOURNAMENT_LOG_ACTION_TYPES, TOURNAMENT_LOG_ACTION_TYPES_SERVER_TO_CLIENT_MAPPING } from '../../consts/log';
import * as propz from 'propz';
import * as Moment from 'moment';
import { Tournament } from '../../models/tournament';
import { DATE_TIME_FORMAT } from '../../consts/date';

interface Props {
  enrollmentLogs: any[];
  tournament: Tournament;
}

export const TournamentEnrollmentLogsSummary: FunctionComponent<Props> = props => {
  const { enrollmentLogs, tournament } = props;
  const rows = enrollmentLogs.map((log, index) => {
    const { userData } = log;
    const tournamentLogAction = propz.get(TOURNAMENT_LOG_ACTION_TYPES_SERVER_TO_CLIENT_MAPPING, [log.action], ''),
      firstName = propz.get(userData, ['firstName'], ''),
      lastName = propz.get(userData, ['lastName'], ''),
      userFullName = `${firstName} ${lastName}`,
      createdAtDate = new Date(log.createdAt),
      createdAtFormatted = Moment(createdAtDate).format(DATE_TIME_FORMAT),
      schoolName = propz.get(log, ['schoolData', 'name'], ''),
      commentLog = propz.get(log, ['comment'], '');

    let affectedObject;
    switch (true) {
      case log.action === TOURNAMENT_LOG_ACTION_TYPES.INVITE_ACCEPTED ||
        log.action === TOURNAMENT_LOG_ACTION_TYPES.INVITE_CANCELLED ||
        log.action === TOURNAMENT_LOG_ACTION_TYPES.INVITE_REJECTED ||
        log.action === TOURNAMENT_LOG_ACTION_TYPES.INVITE_SENT:
        affectedObject = propz.get(log, ['inviteData', 'invitedSchoolName'], '');
        break;
      case log.action === TOURNAMENT_LOG_ACTION_TYPES.SCHOOL_ADDED ||
        log.action === TOURNAMENT_LOG_ACTION_TYPES.SCHOOL_AUTO_ENROLLED ||
        log.action === TOURNAMENT_LOG_ACTION_TYPES.SCHOOL_MANUALLY_ENROLLED ||
        log.action === TOURNAMENT_LOG_ACTION_TYPES.SCHOOL_REMOVED:
        affectedObject = propz.get(log, ['affectedSchoolData', 'name'], '');
        break;
      case log.action === TOURNAMENT_LOG_ACTION_TYPES.TEAM_AUTO_ADDED ||
        log.action === TOURNAMENT_LOG_ACTION_TYPES.TEAM_MANUALLY_ADDED:
        const affectedTeamName = propz.get(log, ['affectedTeamData', 'name'], ''),
          affectedSchoolName = propz.get(log, ['affectedSchoolData', 'name'], '');

        affectedObject = `School: ${affectedSchoolName}\nTeam: ${affectedTeamName}`;
        break;
      case log.action === TOURNAMENT_LOG_ACTION_TYPES.TEAM_REMOVED:
        affectedObject = '';
        break;
      default:
        affectedObject = '';
        break;
    }
    return (
      <tr key={`tournament_${index}`}>
        <td>{index + 1}</td>
        <td>{schoolName}</td>
        <td>{tournament.name}</td>
        <td>{userFullName}</td>
        <td>{affectedObject}</td>
        <td>{tournamentLogAction}</td>
        <td>{createdAtFormatted}</td>
        <td>{commentLog}</td>
      </tr>
    );
  });

  const emptyRow = (
    <tr key={'tournament_empty_row'}>
      <td>No logs</td>
    </tr>
  );

  return (
    <table className={'table table-sm mPre mVerticalAlignMiddle'}>
      <thead>
        <tr>
          <th>#</th>
          <th>School</th>
          <th>Tournament</th>
          <th>User</th>
          <th>Affected object</th>
          <th>Action</th>
          <th>Date</th>
          <th>Comment</th>
        </tr>
      </thead>
      <tbody>{rows.length > 0 ? rows : emptyRow}</tbody>
    </table>
  );
};

TournamentEnrollmentLogsSummary.displayName = 'TournamentEnrollmentLogsSummary';
