import axios from 'axios';
import { AppUser } from 'Src/views/App/App';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../consts/table';
import { DEFAULT_FILTER } from '../admin/user';
import { ConfirmationRequestTemplate } from '../../../models/confirmationRequestTemplate';
import { Count } from '../../../models/count';

export function getTournamentParticipants(user: AppUser, tournamentId: string, filter = DEFAULT_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter
    }
  };

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/participants`, config)
    .then(response => {
      return response.data;
    });
}

export function getTournamentParticipantsCount(user: AppUser, tournamentId: string, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where
        }
      }
    };
  } else {
    config = {
      headers: { usid: key }
    };
  }

  return axios
    .get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/participants/count`, config)
    .then(response => {
      return response.data;
    });
}

export function getAllTournamentParticipants(user: AppUser, tournamentId: string, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/participants`, config)
    .then(response => {
      return response.data;
    });
}

export function removeTournamentParticipant(user: AppUser, tournamentId: string, participantId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .delete(
      `${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/participants/${participantId}`,
      config
    )
    .then(response => {
      return response.data;
    });
}

export function addTournamentParticipants(user: AppUser, tournamentId: string, data) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/participants/batch`, data, config)
    .then(response => {
      return response.data;
    });
}

export function getConfirmationRequestTemplate(user: AppUser): Promise<ConfirmationRequestTemplate> {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .get<ConfirmationRequestTemplate>(
      `${window.apiBase}/i/schools/${activeSchoolId}/confirmationRequestTemplate`,
      config
    )
    .then(response => {
      return response.data;
    });
}

export function updateConfirmationRequestTemplate(
  user: AppUser,
  data: {
    fields: ConfirmationRequestTemplate[];
  }
) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .put(`${window.apiBase}/i/schools/${activeSchoolId}/confirmationRequestTemplate`, data, config)
    .then(response => {
      return response.data;
    });
}

export function sendConfirmationRequest(user: AppUser, tournamentId: string, data) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .post(
      `${window.apiBase}/i/schools/${activeSchoolId}/tournaments/${tournamentId}/messages/confirmationRequest`,
      data,
      config
    )
    .then(response => {
      return response.data;
    });
}
