import * as React from 'react';
import { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import DatePicker from 'react-datepicker';
import {
  getReportDefaultGendersForMultiSelect,
  getDefaultReportAgesForMultiSelect
} from 'Src/helpers/multiselect/multiselect';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/report';
import { Switch } from 'Src/components/Switch/Switch';
import { LabelWithQuestionIcon } from 'Src/components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import { getStartDateForSchoolYear } from 'Src/helpers/calendar/calendar';
import { MultiSelectCheckboxes } from 'Src/components/MultiSelectCheckboxes/MultiSelectCheckboxes';
import { School } from 'Src/models/school';
import { SchoolForm } from '../../../../../../models/form';

interface Props {
  onCloseClick: () => void;
  onSubmit: (data: any, reportType: string) => void;
}

//report 060
export function UnionSchoolTargetParticipationStatisticsReportForm(props: Props) {
  const { onCloseClick, onSubmit } = props;

  const now = new Date();
  let dateFrom = getStartDateForSchoolYear(now);
  const genders = getReportDefaultGendersForMultiSelect();
  const ages = getDefaultReportAgesForMultiSelect();

  const [isAgesAndGendersChecked, setIsAgesAndGendersChecked] = useState(true);

  const requestInitial = {
    dateFrom,
    dateTo: now,
    ageGroups: ages,
    genders,
    isPrintEligible: true,
    isPrintPercent: true,
    isPrintPP: true,
    isPrintSEN: true,
    isPrintFSM: true,
    isSelectAllAges: true
  };

  const onGendersChange = (index, values, setFieldValue) => {
    const genderItems = values.genders;
    const ageItems = values.ageGroups;

    const genderItemsUpdated = genderItems.map((genderItem, genderIndex) => {
      return genderIndex === index
        ? {
            ...genderItem,
            isChecked: !genderItem.isChecked
          }
        : genderItem;
    });

    const gendersChecked = genderItemsUpdated.map(gender => gender.isChecked);
    const agesChecked = ageItems.map(age => age.isChecked);
    const isCheckedGendersExist = gendersChecked.includes(true);
    const isCheckedAgesExist = agesChecked.includes(true);

    setIsAgesAndGendersChecked(isCheckedGendersExist && isCheckedAgesExist);
    setFieldValue('genders', genderItemsUpdated);
  };

  const onAgesChange = (index, values, setFieldValue) => {
    const ageItems = values.ageGroups;
    const genderItems = values.genders;

    const ageItemsUpdated = ageItems.map((ageItem, ageIndex) => {
      return ageIndex === index
        ? {
            ...ageItem,
            isChecked: !ageItem.isChecked
          }
        : ageItem;
    });

    const gendersChecked = genderItems.map(gender => gender.isChecked);
    const agesChecked = ageItemsUpdated.map(age => age.isChecked);
    const isCheckedGendersExist = gendersChecked.includes(true);
    const isCheckedAgesExist = agesChecked.includes(true);

    const isSelectAllAges = ageItemsUpdated.every(age => age.isChecked === true);

    setIsAgesAndGendersChecked(isCheckedAgesExist && isCheckedGendersExist);
    setFieldValue('isSelectAllAges', isSelectAllAges);
    setFieldValue('ageGroups', ageItemsUpdated);
  };

  const onSelectAllAgesChange = (event, values, setFieldValue) => {
    const isSelectAllAges = event.target.checked;
    const ageItems = values.ageGroups;
    const genderItems = values.genders;

    const ageItemsUpdated = ageItems.map(age => {
      return {
        ...age,
        isChecked: isSelectAllAges
      };
    });

    const gendersChecked = genderItems.map(gender => gender.isChecked);
    const agesChecked = ageItemsUpdated.map(age => age.isChecked);
    const isCheckedGendersExist = gendersChecked.includes(true);
    const isCheckedAgesExist = agesChecked.includes(true);

    setIsAgesAndGendersChecked(isCheckedAgesExist && isCheckedGendersExist);
    setFieldValue('isSelectAllAges', isSelectAllAges);
    setFieldValue('ageGroups', ageItemsUpdated);
  };

  return (
    <Formik
      onSubmit={values =>
        onSubmit(values, REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.UNION_SCHOOL_TARGET_PARTICIPATION_STATISTICS)
      }
      initialValues={requestInitial}
      render={({ values, setFieldValue }) => (
        <Form>
          <div className="mb-3 mt-3">
            {'The Target Participation Statistics report provides a comprehensive overview ' +
              'of student participation in selected activities, including the percentage ' +
              'of eligible students who participated across all union member schools. ' +
              ' The report also includes breakdowns by gender and PP/SEN attributes, ' +
              'allowing for a detailed analysis of participation rates across different ' +
              'student groups.'}
          </div>
          <div className="form-group">
            <label>Date from</label>
            <Field>
              {({ form }) => (
                <div className="mb-3">
                  <DatePicker
                    selected={form.values.dateFrom}
                    onChange={dateFrom => {
                      form.setFieldValue('dateFrom', dateFrom);
                      if (form.values.dateTo && form.values.dateTo < dateFrom) {
                        form.setFieldValue('dateTo', dateFrom);
                      }
                    }}
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
              )}
            </Field>
          </div>

          <div className="form-group">
            <label>Date to</label>
            <Field>
              {({ form }) => (
                <div className="mb-3">
                  <DatePicker
                    selected={form.values.dateTo}
                    onChange={dateTo => {
                      form.setFieldValue('dateTo', dateTo);
                    }}
                    minDate={form.values.dateFrom}
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
              )}
            </Field>
          </div>

          <div className={'mt-3 mb-3'}>
            <MultiSelectCheckboxes
              items={values.genders}
              cols={3}
              onChange={index => onGendersChange(index, values, setFieldValue)}
            />
          </div>

          <div className={'mt-3 mb-3'}>
            <MultiSelectCheckboxes
              items={values.ageGroups}
              cols={3}
              onChange={index => onAgesChange(index, values, setFieldValue)}
            />
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isSelectAllAges"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isSelectAllAges}
                    customClass="mb-2"
                    onChange={event => onSelectAllAgesChange(event, values, setFieldValue)}
                  />
                )}
              />

              <label htmlFor="isSelectAllAges" className="form-check-label pl-2">
                Select all ages
              </label>
            </div>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isPrintEligible"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintEligible}
                    onChange={event => {
                      const isPrintEligible = event.target.checked;

                      setFieldValue('isPrintEligible', isPrintEligible);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show number of eligible students"
                hintText={
                  'This will allow you to view the number of students eligible for taking part in particular activities based on their gender and age.'
                }
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintEligible"
              />
            </div>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isPrintPercent"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintPercent}
                    onChange={event => {
                      const isPrintPercent = event.target.checked;

                      setFieldValue('isPrintPercent', isPrintPercent);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show percents"
                hintText={
                  'This will allow you to view the percentage rates calculated as a ratio of participating students to eligible students.'
                }
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintPercent"
              />
            </div>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isPrintPP"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintPP}
                    onChange={event => {
                      const isPrintPP = event.target.checked;

                      setFieldValue('isPrintPP', isPrintPP);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Breakdown by PP"
                hintText={'This will allow you to view the numbers of participating/eligible students marked as PP.'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintPP"
              />
            </div>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isPrintSEN"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintSEN}
                    onChange={event => {
                      const isPrintSEN = event.target.checked;

                      setFieldValue('isPrintSEN', isPrintSEN);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Breakdown by SEN"
                hintText={'This will allow you to view the numbers of participating/eligible students marked as SEN.'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintSEN"
              />
            </div>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isPrintFSM"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintFSM}
                    onChange={event => {
                      const isPrintFSM = event.target.checked;

                      setFieldValue('isPrintFSM', isPrintFSM);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Breakdown by FSM"
                hintText={
                  'This will allow you to view the numbers of participating/eligible students marked as receiving free school meals.'
                }
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintFSM"
              />
            </div>
          </div>

          <button type="button" className="btn btn-secondary mr-3" onClick={onCloseClick}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary" disabled={!isAgesAndGendersChecked}>
            Get
          </button>
        </Form>
      )}
    />
  );
}
