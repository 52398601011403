import * as React from 'react';
import {FunctionComponent} from 'react';


import './GeneralMessagePreview.css';
import {GeneralMessagePreviewEmail} from "./GeneralMessagePreviewEmail";
import {GeneralMessagePreviewPush} from "./GeneralMessagePreviewPush";
import {GeneralMessagePreviewSms} from "./GeneralMessagePreviewSms";
import { Attachment } from "../../models/generalMessage";

interface Props {
  messageSmsText: string
  messageEmailSubject: string
  messageEmailBody: string
  messagePushTitle: string
  messagePushBody: string
  messageAttachments: Attachment[]
}

export const GeneralMessagePreview: FunctionComponent<Props> = (props) => {

  return (
    <div className="container-fluid">
      <div className='bGeneralMessagePreview row'>
    
        <div className="col-xl-6 col-lg-4 col-md-12">
          {/*email preview*/}
          <GeneralMessagePreviewEmail
            messageEmailSubject={props.messageEmailSubject}
            messageEmailBody={props.messageEmailBody}
            messageAttachments={props.messageAttachments}
            showTitle={true}
          />
        </div>
    
        <div className="col-xl-3 col-lg-4 col-md-6">
          {/*push preview*/}
          <GeneralMessagePreviewPush
            messagePushTitle={props.messagePushTitle}
            messagePushBody={props.messagePushBody}
            showTitle={true}
          />
        </div>
    
        <div className="col-xl-3 col-lg-4 col-md-6">
          {/*text preview*/}
          <GeneralMessagePreviewSms
            messageSmsText={props.messageSmsText}
            showTitle={true}
          />
        </div>
        
      </div>
    </div>
  )
};

GeneralMessagePreview.displayName = 'GeneralMessagePreview';