import * as React from 'react';
import { Component } from 'react';
import * as propz from 'propz';
import * as Moment from 'moment';
import { TournamentConfirmationRequestMessage } from 'Src/models/message';
import { VENUE_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/venue';

interface Props {
  message: TournamentConfirmationRequestMessage;
  onMapClick: () => void;
}

export class TournamentInfo extends Component<Props, {}> {
  getAges = (ages: number[]) => {
    return ages.map(elem => (elem === 0 ? 'Reception' : 'Y' + elem)).join(', ');
  };

  render() {
    const { message, onMapClick } = this.props;
    const schoolUnionName = propz.get(message, ['schoolData', 'name'], '');
    const tournamentName = propz.get(message, ['tournamentData', 'name'], '');
    const startTime = propz.get(message, ['tournamentData', 'startTime'], '');
    const endTime = propz.get(message, ['tournamentData', 'endTime'], '');
    const ages = propz.get(message, ['tournamentData', 'ages'], []);

    const agesFormatted = this.getAges(ages);

    const startTimeFormatted = startTime ? Moment(startTime).format('DD.MM.YYYY / HH:mm') : '';
    const endTimeFormatted = startTime ? Moment(endTime).format('DD.MM.YYYY / HH:mm') : '';

    const postcode = propz.get(message, ['tournamentData', 'venue', 'postcode'], '');
    const placeName = VENUE_SERVER_TO_CLIENT_MAPPING[propz.get(message, ['tournamentData', 'venue', 'venueType'], '')];

    return (
      <div className={'d-flex flex-column ml-3'}>
        <div>
          <span className={'font-weight-bold'}>School union name: </span>
          {schoolUnionName}
        </div>

        <div>
          <span className={'font-weight-bold'}>Tournament: </span>
          {tournamentName}
        </div>

        <div>
          <span className={'font-weight-bold'}>Start: </span>
          {startTimeFormatted}
        </div>

        <div>
          <span className={'font-weight-bold'}>End: </span>
          {endTimeFormatted}
        </div>

        <div>
          <span className={'font-weight-bold'}>Ages: </span>
          {agesFormatted}
        </div>

        <div>
          <span className={'mClubInvitationFormBold'}>{'Venue:'} </span>
          {`${postcode}, ${placeName}`}
        </div>

        <div className="eClubInvitationFormRow">
          <img src={'/dist/images/icons/pin.png'} alt="pin icon" className={'bClubInvitationFormIcon'} />
          <div className="bClubInvitationFormMap" onClick={onMapClick}>
            View map
          </div>
        </div>
      </div>
    );
  }
}
