import * as React from 'react';
import { Route, Switch } from 'react-router';
import { SchoolAdmins } from './SchoolAdmins';
import { SchoolStudents } from './SchoolStudents/SchoolStudents';
import { SchoolForms } from './SchoolForms/SchoolForms';
import { Schools } from './Schools';

export const SchoolsRouting = allProps => {
  return (
    <Switch>
      <Route exact path={'/schools'} render={props => <Schools {...props} user={allProps.user} />} />
      <Route exact path={'/schools/admins'} render={props => <SchoolAdmins {...props} user={allProps.user} />} />
      <Route exact path={'/schools/students'} render={props => <SchoolStudents {...props} user={allProps.user} />} />
      <Route exact path={'/schools/forms'} render={props => <SchoolForms {...props} user={allProps.user} />} />
    </Switch>
  );
};

SchoolsRouting.displayName = 'SchoolsRouting';
