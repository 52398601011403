import * as React from 'react';
import { AdminBoardGalleryItem } from './ArbiterBoard';
import { getResizedToMinValueUrl } from '../../../../../../helpers/gallery/gallery';
import { ACCESS_PRESET_SERVER_TO_CLIENT_MAPPING, PHOTO_WIDTH } from '../../../../../../consts/album';
import { getAccessPresetColor } from '../../../../../../helpers/event/eventPhotoAndVideo';

interface Props {
  item: AdminBoardGalleryItem;
  onClick: (id: string) => void;
}

export function ArbiterBoardGalleryItemPhoto(props: Props) {
  const { item, onClick } = props;
  const color = getAccessPresetColor(item.accessPreset);

  return (
    <div className={'eArbiterBoardGalleryItemPhoto'}>
      <div
        className={'mArbiterBoardGalleryItemPhoto'}
        onClick={() => {
          onClick(item.id);
        }}
        style={{
          backgroundImage: `url(${getResizedToMinValueUrl(item.url, PHOTO_WIDTH)})`
        }}
      />
      <div className="eArbiterBoardGalleryItem_accessPreset" style={{ color: color }}>
        <span>{ACCESS_PRESET_SERVER_TO_CLIENT_MAPPING[item.accessPreset]}</span>
      </div>
    </div>
  );
}
