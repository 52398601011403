import * as React from 'react';
import { Route, Switch } from 'react-router';
import { EmptyComponent } from 'Src/components/EmptyComponent/EmptyComponent';
import { MessagesInbox } from './MessagesInbox';
import { MessagesArchive } from './MessagesArchive';

export const MessagesRouting = allProps => {
  return (
    <Switch>
      <Route exact path={'/messages'} render={props => <EmptyComponent {...props} user={allProps.user} />} />
      <Route
        exact
        path={'/messages/inbox'}
        render={props => <MessagesInbox {...props} user={allProps.user} setNeedUpdate={allProps.setNeedUpdate} />}
      />
      <Route exact path={'/messages/outbox'} render={props => <EmptyComponent {...props} user={allProps.user} />} />
      <Route exact path={'/messages/archive'} render={props => <MessagesArchive {...props} user={allProps.user} />} />
    </Switch>
  );
};

MessagesRouting.displayName = 'MessagesRouting';
