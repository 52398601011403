import * as propz from 'propz';
import { EVENT_VENUE, VENUE_SERVER_TO_CLIENT_MAPPING } from '../../consts/venue';
import { EventSchoolInvite, SchoolInvite, SchoolInviteSchool, TournamentSchoolInvite } from '../../models/invite';
import { AGE_GROUPS, KIND } from '../../consts/school';
import { INVITE_STATUS, INVITE_TYPE } from '../../consts/invite';
import { SchoolEvent, SchoolEventInvite } from '../../models/event';

export function getLocation(item: SchoolEvent | EventSchoolInvite, activeSchoolId): string {
  const venue = item.venue;

  const inviterVenue = propz.get(venue, ['venueType'], EVENT_VENUE.TBD);

  let eventLocationServer;
  const isActiveSchoolInviter = activeSchoolId === item.inviterSchoolId;

  if (isActiveSchoolInviter) {
    eventLocationServer = inviterVenue;
  } else {
    switch (inviterVenue) {
      case EVENT_VENUE.HOME:
        eventLocationServer = EVENT_VENUE.AWAY;
        break;
      case EVENT_VENUE.AWAY:
        eventLocationServer = EVENT_VENUE.HOME;
        break;
      default:
        eventLocationServer = inviterVenue;
        break;
    }
  }

  let eventLocation = VENUE_SERVER_TO_CLIENT_MAPPING[eventLocationServer];

  const postcode = propz.get(venue, ['postcodeData', 'postcode']);
  if (typeof postcode !== 'undefined') {
    eventLocation += `, ${postcode}`;
  }
  const venueName = propz.get(venue, ['placeData', 'name']);
  if (typeof venueName !== 'undefined') {
    eventLocation += `, ${venueName}`;
  }
  return eventLocation;
}

export function getTournamentLocation(invite: SchoolInvite, activeSchoolId): string {
  const venue = invite.tournament.venue;

  const inviterVenue = propz.get(venue, ['venueType'], EVENT_VENUE.TBD);

  let eventLocationServer;
  const isActiveSchoolInviter = activeSchoolId === invite.inviterSchoolId;

  if (isActiveSchoolInviter) {
    eventLocationServer = inviterVenue;
  } else {
    switch (inviterVenue) {
      case EVENT_VENUE.HOME:
        eventLocationServer = EVENT_VENUE.AWAY;
        break;
      case EVENT_VENUE.AWAY:
        eventLocationServer = EVENT_VENUE.HOME;
        break;
      default:
        eventLocationServer = inviterVenue;
        break;
    }
  }

  let eventLocation = VENUE_SERVER_TO_CLIENT_MAPPING[eventLocationServer];

  const postcode = propz.get(venue, ['postcodeData', 'postcode']);
  if (typeof postcode !== 'undefined') {
    eventLocation += `, ${postcode}`;
  }
  const venueName = propz.get(venue, ['placeData', 'name']);
  if (typeof venueName !== 'undefined') {
    eventLocation += `, ${venueName}`;
  }
  return eventLocation;
}

export function getSchoolUnionNameIfNeed(inviterSchool: SchoolInviteSchool) {
  if (inviterSchool.kind === KIND.SCHOOL_UNION) {
    return inviterSchool.name;
  } else {
    return '';
  }
}

export function getRivalName(
  inviterSchool: SchoolInviteSchool,
  invitedSchools: SchoolInviteSchool[] | SchoolInviteSchool,
  rival,
  activeSchoolId: string
) {
  if (inviterSchool.kind === KIND.SCHOOL_UNION) {
    if (Array.isArray(invitedSchools)) {
      return `\n${invitedSchools
        .filter(school => school.id !== activeSchoolId)
        .map(school => school.name)
        .join(', ')}`;
    } else {
      return `\n${invitedSchools.name}`;
    }
  } else {
    if (typeof rival === 'undefined') {
      console.error('Broken invite');
      return '';
    }

    return `\n${rival.name}`;
  }
}

export function getRivalSchool(
  inviterSchool: SchoolInviteSchool,
  invitedSchool: SchoolInviteSchool,
  activeSchoolId: string,
  messageType: string
): SchoolInviteSchool {
  switch (messageType) {
    case INVITE_TYPE.INBOX:
      return inviterSchool;
    case INVITE_TYPE.OUTBOX:
      return invitedSchool;
    case INVITE_TYPE.ARCHIVE:
      return activeSchoolId === inviterSchool.id ? invitedSchool : inviterSchool;
  }
}

export function getTournamentNameIfNeed(invite: SchoolInvite) {
  if (typeof invite.tournament !== 'undefined') {
    return `\nTournament: ${invite.tournament.name}`;
  } else {
    return '';
  }
}

export function getSchoolInviteRecepient(
  inviterSchool: SchoolInviteSchool,
  invitedSchool: SchoolInviteSchool,
  activeSchoolId: string
) {
  if (inviterSchool.kind === KIND.SCHOOL_UNION && inviterSchool.id === activeSchoolId) {
    return `\nTo: ${invitedSchool.name}`;
  } else {
    return '';
  }
}

export function getTeamDataName(teamData, inviterSchool, messageType) {
  let teamDataName = '';
  if (teamData.length > 0) {
    for (let i = 0; i < teamData.length; i++) {
      if (inviterSchool.id === teamData[i].schoolId) {
        switch (messageType) {
          case INVITE_TYPE.INBOX:
            teamDataName = `\nOpponent team name: ${teamData[i].name}`;
            break;
          case INVITE_TYPE.OUTBOX:
            teamDataName = `\nOur team name: ${teamData[i].name}`;
            break;
          case INVITE_TYPE.ARCHIVE:
            teamDataName = '';
            break;
        }
      }
    }
  } else {
    teamDataName = '';
  }
  return teamDataName;
}

export function getAgeGroup(event) {
  const ages = [...event.ages] || [];

  return ages
    .sort((age1, age2) => age1 - age2)
    .map(age => propz.get(AGE_GROUPS, ['ENGLISH', age]))
    .join(', ');
}

export function isInviteStatusRejected(invite: SchoolInvite | SchoolEventInvite) {
  const status = propz.get(invite, ['status']);
  return status === INVITE_STATUS.REJECTED;
}

export function isInviteStatusAccepted(invite: SchoolInvite | SchoolEventInvite) {
  const status = propz.get(invite, ['status']);
  return status === INVITE_STATUS.ACCEPTED;
}

export function isInviteStatusNotReady(invite: SchoolInvite | SchoolEventInvite) {
  const status = propz.get(invite, ['status']);
  return status === INVITE_STATUS.NOT_READY;
}

export function isSchoolEventInvite(invite: SchoolInvite): boolean {
  return invite.kind === 'EventSchoolInvite';
}

export function isSchoolTournamentInvite(invite: SchoolInvite): boolean {
  return invite.kind === 'TournamentSchoolInvite';
}
