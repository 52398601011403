import * as React from 'react';
import { FunctionComponent } from 'react';
import * as classNames from 'classnames';

interface Props {
  modalTitle: string;
  commentText: string;
  onCommentTextChange: (event) => void;
  onCloseClick: () => void;
  onSaveClick: () => void;
  isOpen: boolean;
}

export const AdminSchoolsDeclineInvitesModal: FunctionComponent<Props> = props => {
  if (!props.isOpen) {
    return null;
  }

  const modalClasses = classNames({
    mDisplayBlock: props.isOpen,
    show: props.isOpen,
    modal: true,
    fade: true
  });

  const isEmptyCommentText = props.commentText === '';

  return (
    <div className={modalClasses}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{props.modalTitle}</h5>
            <button type="button" className="close" onClick={props.onCloseClick}>
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label>Comment</label>
              <textarea
                rows={3}
                placeholder="Enter your comment"
                onChange={props.onCommentTextChange}
                value={props.commentText}
                className="form-control"
              />
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={props.onCloseClick}>
              Close
            </button>
            <button type="button" className="btn btn-primary" onClick={props.onSaveClick} disabled={isEmptyCommentText}>
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

AdminSchoolsDeclineInvitesModal.displayName = 'AdminSchoolsDeclineInvitesModal';
