import * as React from 'react';
import * as propz from 'propz';
import { getSelectOptionForPositionsSport } from '../../helpers/table/select';
import { FunctionComponent } from 'react';
import { getPersonListSortedByName } from '../../helpers/table/table';

interface Props {
  sport: any;
  players: any[];
  isEditTeamPlayers: boolean;
  onChangePositionPlayer: (event, index) => void;
  onChangeIsSubPlayer: (event, playerId) => void;
  onChangeIsCaptainPlayer: (event, index) => void;
}

export const SchoolTeamPlayersSummary: FunctionComponent<Props> = props => {
  const { players, isEditTeamPlayers, sport } = props;

  const playersSortedByLastName: any[] = getPersonListSortedByName(players);

  const isSortedPlayersExist = playersSortedByLastName.length > 0;

  const renderPositionsOptions = () => {
    const positions = getSelectOptionForPositionsSport(sport);
    return (
      <>
        <option value={''}>Not selected</option>;
        {positions.map(position => {
          return (
            <option key={position.value} value={position.value}>
              {position.text}
            </option>
          );
        })}
      </>
    );
  };

  const renderPlayers = () => {
    if (isSortedPlayersExist) {
      return players.map((player, index) => {
        const playerLastName = propz.get(player, ['lastName'], '');
        const playerName = propz.get(player, ['firstName'], '');
        const playerForm = propz.get(player, ['form', 'name'], '');
        const playerHouse = propz.get(player, ['house', 'name'], '');

        return (
          <tr key={player.id}>
            <td>{index + 1}</td>
            <td>{playerLastName}</td>
            <td>{playerName}</td>
            <td>{playerForm}</td>
            <td>{playerHouse}</td>
            <td>
              <select
                className="form-control"
                onChange={e => props.onChangePositionPlayer(e, index)}
                value={player.positionId}
                disabled={isEditTeamPlayers}
              >
                {renderPositionsOptions()}
              </select>
            </td>
            <td>
              <input
                id="captain"
                type="checkbox"
                className="ml-3"
                checked={player.isCaptain}
                onChange={e => props.onChangeIsCaptainPlayer(e, player.id)}
                disabled={isEditTeamPlayers}
              />
            </td>
            <td>
              <input
                id="sub"
                type="checkbox"
                className="ml-1"
                checked={player.sub}
                onChange={e => props.onChangeIsSubPlayer(e, index)}
                disabled={isEditTeamPlayers}
              />
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr key={'team_players_empty_row'}>
          <td>No participants</td>
        </tr>
      );
    }
  };

  const picUrl = propz.get(sport, ['field', 'pic']);
  const picUrlExist = typeof picUrl !== 'undefined';
  const tableClass = picUrlExist ? 'w-75' : '';
  return (
    <div className="d-flex">
      <table className={`${tableClass} table table-sm mPre`}>
        <thead>
          <tr>
            <th>#</th>
            <th>Last name</th>
            <th>Name</th>
            <th>Form</th>
            <th>House</th>
            <th>Position</th>
            <th>Captain</th>
            <th>Sub</th>
          </tr>
        </thead>
        <tbody>{renderPlayers()}</tbody>
      </table>
      {picUrlExist && (
        <div className="w-25">
          <img className="img-fluid img-thumbnail" src={picUrl} alt="News picture" />
        </div>
      )}
    </div>
  );
};

SchoolTeamPlayersSummary.displayName = 'SchoolTeamPlayersSummary';
