import * as React from 'react';
import { Route, Switch } from 'react-router';
import { CreateGeneralMessageView } from './CreateGeneralMessageView/CreateGeneralMessageView';
import { GeneralMessages } from './GeneralMessages';

export const GeneralMessageRouting = allProps => {
  return (
    <Switch>
      <Route exact path={'/generalMessages'} render={props => <GeneralMessages {...props} user={allProps.user} />} />
      <Route
        exact
        path={'/generalMessages/create'}
        render={props => <CreateGeneralMessageView {...props} user={allProps.user} />}
      />
    </Switch>
  );
};

GeneralMessageRouting.displayName = 'GeneralMessageRouting';
