import * as React from 'react';
import { Component } from 'react';
import * as Moment from 'moment';
import * as BPromise from 'bluebird';
import { Field, Form, Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import MaskedInput from 'react-text-mask';
import DatePicker from 'react-datepicker';

import { AppUser } from 'Src/views/App/App';
import { SchoolTerm, SchoolTerms } from 'Src/models/schoolTerm';
import { DATE_FORMAT } from 'Src/consts/date';
import { getSchoolTerms, addSchoolTerms, updateSchoolTerms } from 'Src/helpers/service/admin/schoolTerms';
import { Loader } from 'Src/components/Loader/Loader';
import { Switch } from 'Src/components/Switch/Switch';
import { LabelWithQuestionIcon } from 'Src/components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import { TrashIcon } from 'Src/components/SvgIcons/TrashIcon';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';

interface Props {
  user: AppUser;
}

interface State {
  isLoading: boolean;
  initialTerms: SchoolTermInDateFormat[];
  termsId: string; // single id for terms array, if undefined - it is default terms, not school personal from DB
  isEditCustomTerms: boolean;
  isEditMode: boolean;
  isSuccessModalOpen: boolean;
  errors: any;
}

export interface SchoolTermInDateFormat {
  name: string;
  start: Date;
  end: Date;
}

const validationSchema = Yup.object().shape({
  terms: Yup.array().of(
    Yup.object().shape({
      name: Yup.string()
        .required('Required')
        .test('name', 'Empty name', value => {
          const isValidStringValue = typeof value !== 'undefined' && value !== '';
          return isValidStringValue;
        }),
      start: Yup.mixed().test('start', 'Empty start', value => {
        const isValidStringValue = typeof value !== 'undefined' && value !== null && value !== '';
        return isValidStringValue;
      }),
      end: Yup.mixed().test('end', 'Empty end', value => {
        const isValidStringValue = typeof value !== 'undefined' && value !== null && value !== '';
        return isValidStringValue;
      })
    })
  )
});

export class Terms extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      initialTerms: undefined,
      termsId: undefined,
      isEditCustomTerms: false,
      isEditMode: false,
      isSuccessModalOpen: false,
      errors: undefined
    };
  }

  convertDateToISOString(date: Date) {
    const DateString = Moment(date)
      .format('YYYY-MM-DD')
      .toString();
    const ISOString = Moment.utc(DateString, 'YYYY-MM-DD').toISOString();
    return ISOString;
  }

  convertTermsToDate(terms: SchoolTerm[]) {
    const termDates = terms.map(term => ({
      name: term.name,
      start: new Date(term.start),
      end: new Date(term.end)
    }));

    return termDates;
  }

  componentDidMount() {
    const { user } = this.props;

    getSchoolTerms(user).then((terms: SchoolTerms) => {
      const termErrors = terms.terms.map(term => ({
        start: { isError: false, errorText: '' },
        end: { isError: false, errorText: '' }
      }));

      const termDates = this.convertTermsToDate(terms.terms);

      this.setState({
        isLoading: false,
        initialTerms: termDates,
        termsId: terms.id, // terms.id may be undefined
        errors: { terms: termErrors }
      });
    });
  }

  onSubmit = (values, initialTerms) => {
    const { user } = this.props;
    const { termsId, errors } = this.state;
    const isTermIdExist = typeof termsId !== 'undefined';

    const isComplexErrorsExist = errors.terms.some(error => {
      return error.start.isError || error.end.isError;
    });

    if (!isComplexErrorsExist) {
      this.setState({
        isLoading: true
      });

      const termsWithConvertedDates = values.terms.map(term => ({
        name: term.name,
        start: this.convertDateToISOString(term.start),
        end: this.convertDateToISOString(term.end)
      }));

      const data = { terms: termsWithConvertedDates };

      const promise = isTermIdExist ? updateSchoolTerms(user, termsId, data) : addSchoolTerms(user, data);

      BPromise.resolve(promise).then((terms: SchoolTerms) => {
        const termDates = this.convertTermsToDate(terms.terms);

        this.setState({
          isLoading: false,
          initialTerms: termDates,
          isEditCustomTerms: false,
          isEditMode: false,
          isSuccessModalOpen: true
        });
      });
    }
  };

  getTermView = (term, values, length, index, replace, insert, remove, touched, errors) => {
    const { isEditMode, errors: complexErrors } = this.state;

    const isTermErrorExist = errors.terms && errors.terms[index];

    const errorTerm = isTermErrorExist && errors.terms[index];

    const isComplexErrorExist = typeof complexErrors !== 'undefined';
    const errorComplexTerm = isComplexErrorExist && complexErrors.terms[index];

    const isDeleteButtonActive = length != 1;

    return (
      <div className="react-datepicker-wrapper" key={`term_${index}`}>
        <div className="d-flex justify-content-between mt-3 mb-2">
          <div className="font-weight-bold text-primary">{`Term ${index + 1}`} </div>
          {isEditMode && isDeleteButtonActive && (
            <div
              onClick={() => {
                remove(index);
              }}
            >
              <TrashIcon />
            </div>
          )}
        </div>

        <div className="form-group">
          <LabelWithQuestionIcon labelText="Name" hintText={''} />

          <Field
            type="text"
            name={`terms.${index}.name`}
            className="form-control"
            value={term.name}
            onChange={event => {
              const newValue = event.target.value;
              replace(index, { name: newValue, start: term.start, end: term.end });
            }}
            disabled={!isEditMode}
          />
        </div>

        {errorTerm && errorTerm.name && <div className="alert alert-danger">{errorTerm.name}</div>}

        <div className="form-group">
          <LabelWithQuestionIcon labelText="Start" hintText={''} />
          <Field
            name={`terms.${index}.start`}
            render={({ field }) => {
              const isDateExist = typeof term.start !== 'undefined' && term.start !== null && term.start !== '';
              const date = isDateExist ? new Date(term.start) : undefined;

              return (
                <div className="mb-3">
                  <DatePicker
                    selected={date}
                    onChange={startDate => {
                      replace(index, { name: term.name, start: startDate, end: term.end });

                      //errors
                      const newStart = new Date(startDate);
                      const currentEnd = term.end;
                      const prevEnd = index > 0 && values.terms[index - 1].end;

                      let errorsNext = { ...complexErrors };
                      const isStartError = startDate !== null && index > 0 && prevEnd !== null && newStart <= prevEnd;

                      if (isStartError) {
                        errorsNext.terms[index].start = {
                          isError: true,
                          errorText: 'Should be greater than end of previous term'
                        };
                      } else {
                        errorsNext.terms[index].start = {
                          isError: false,
                          errorText: ''
                        };
                      }

                      const isEndError = startDate !== null && currentEnd !== null && currentEnd <= newStart;

                      if (isEndError) {
                        errorsNext.terms[index].end = {
                          isError: true,
                          errorText: 'Should be greater than start of this term'
                        };
                      } else {
                        errorsNext.terms[index].end = {
                          isError: false,
                          errorText: ''
                        };
                      }

                      this.setState({
                        errors: errorsNext
                      });
                    }}
                    className="form-control"
                    dateFormat={'dd-MM-yyyy'}
                    disabled={!isEditMode}
                  />
                </div>
              );
            }}
          />
        </div>

        {errorTerm && errorTerm.start && <div className="alert alert-danger">{errorTerm.start}</div>}
        {isComplexErrorExist && errorComplexTerm.start.isError && (
          <div className="alert alert-danger">{errorComplexTerm.start.errorText}</div>
        )}

        <div className="form-group">
          <LabelWithQuestionIcon labelText="End" hintText={''} />
          <Field
            name={`terms.${index}.end`}
            render={({ field }) => {
              const isDateExist = typeof term.end !== 'undefined' && term.end !== null && term.end !== '';
              const date = isDateExist ? new Date(term.end) : undefined;

              return (
                <div className="mb-3">
                  <DatePicker
                    selected={date}
                    onChange={endDate => {
                      replace(index, { name: term.name, start: term.start, end: endDate });

                      //errors
                      const newEnd = new Date(endDate);
                      const currentStart = term.start;
                      const nextStart = index < values.terms.length - 1 && values.terms[index + 1].start;

                      let errorsNext = { ...complexErrors };
                      const isEndError = endDate !== null && currentStart !== null && newEnd <= currentStart;

                      if (isEndError) {
                        errorsNext.terms[index].end = {
                          isError: true,
                          errorText: 'Should be greater than start of this term'
                        };
                      } else {
                        errorsNext.terms[index].end = {
                          isError: false,
                          errorText: ''
                        };
                      }

                      const isStartError =
                        endDate !== null &&
                        index < values.terms.length - 1 &&
                        nextStart !== null &&
                        nextStart <= newEnd;

                      if (isStartError) {
                        errorsNext.terms[index + 1].start = {
                          isError: true,
                          errorText: 'Should be greater than end of previous term'
                        };
                      } else {
                        if (index < values.terms.length - 1) {
                          errorsNext.terms[index + 1].start = {
                            isError: false,
                            errorText: ''
                          };
                        }
                      }

                      this.setState({
                        errors: errorsNext
                      });
                    }}
                    className="form-control"
                    dateFormat={'dd-MM-yyyy'}
                    disabled={!isEditMode}
                  />
                </div>
              );
            }}
          />
        </div>
        {errorTerm && errorTerm.end && <div className="alert alert-danger">{errorTerm.end}</div>}
        {isComplexErrorExist && errorComplexTerm.end.isError && (
          <div className="alert alert-danger">{errorComplexTerm.end.errorText}</div>
        )}

        {isEditMode && (
          <button
            type="button"
            className="mt-2 mb-3 btn btn-secondary"
            onClick={() => {
              insert(index + 1, { name: '', start: '', end: '' });
              let errorsNext = { ...complexErrors };
              const item = {
                start: { isError: false, errorText: '' },
                end: { isError: false, errorText: '' }
              };

              errorsNext.terms.splice(index, 0, item);

              this.setState({
                errors: errorsNext
              });
            }}
          >
            + Add term
          </button>
        )}
      </div>
    );
  };

  defaultTerms = () => {
    const { initialTerms, isEditCustomTerms, isEditMode } = this.state;
    const initialValues = { terms: initialTerms };

    if (isEditCustomTerms) {
      return <></>;
    }

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={values => this.onSubmit(values, initialValues)}
        render={({ touched, errors, setFieldValue, values, resetForm }) => {
          return (
            <Form>
              <FieldArray
                name="terms"
                render={({ replace, insert, remove }) => (
                  <div className="col-12 col-sm-4 col-lg-3 pl-0 pr-sm-3 pr-0">
                    {values.terms.map((term, index) =>
                      this.getTermView(
                        term,
                        values,
                        values.terms.length,
                        index,
                        replace,
                        insert,
                        remove,
                        touched,
                        errors
                      )
                    )}
                  </div>
                )}
              />
              {isEditMode! && (
                <div className="mt-3 mb-5">
                  <button type="submit" className="mt-3 btn btn-lg btn-primary">
                    Save
                  </button>
                  <button
                    type="button"
                    className="mt-3 ml-3 btn btn-lg btn-secondary"
                    onClick={() => {
                      resetForm();
                      this.onCancelClick();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              )}
            </Form>
          );
        }}
      />
    );
  };

  customTerms = () => {
    const { isEditCustomTerms, isEditMode } = this.state;
    const initialValues = { terms: [{ name: '', start: '', end: '' }] };

    if (!isEditCustomTerms) {
      return <></>;
    }

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={values => this.onSubmit(values, initialValues)}
        render={({ touched, errors, setFieldValue, values }) => {
          return (
            <Form>
              <FieldArray
                name="terms"
                render={({ replace, insert, remove }) => (
                  <div className="col-12 col-sm-4 col-lg-3 pl-0 pr-sm-3 pr-0">
                    {values.terms.map((term, index) =>
                      this.getTermView(
                        term,
                        values,
                        values.terms.length,
                        index,
                        replace,
                        insert,
                        remove,
                        touched,
                        errors
                      )
                    )}
                  </div>
                )}
              />

              {isEditMode && (
                <div>
                  {this.renderCreateButtonInfo()}
                  <div className="mt-3 mb-5">
                    <button type="submit" className="mt-3 btn btn-lg btn-primary">
                      Save
                    </button>
                    <button type="button" className="mt-3 ml-3 btn btn-lg btn-secondary" onClick={this.onCancelClick}>
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </Form>
          );
        }}
      />
    );
  };

  onEditTermsClick = () => {
    this.setState({
      isEditMode: true,
      isEditCustomTerms: false
    });
  };

  onCreateTermsClick = () => {
    this.setState({
      isEditMode: true,
      isEditCustomTerms: true
    });
  };

  onCancelClick = () => {
    this.setState({
      isEditMode: false,
      isEditCustomTerms: false
    });
  };

  closeSuccessModal = () => {
    this.setState({
      isSuccessModalOpen: false
    });
  };

  renderSuccessModal() {
    const { isSuccessModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isSuccessModalOpen}
        title={'Info'}
        body={'Terms saved'}
        buttonText={'Ok'}
        onButtonClick={this.closeSuccessModal}
      />
    );
  }

  renderCreateButtonInfo() {
    return (
      <>
        <div className="font-weight-bold text-danger">
          Please be aware that selecting the "Create new terms" option will delete all your existing terms, requiring
          you to create new ones from scratch.
        </div>
        <div className="font-weight-bold text-danger">
          If you only wish to add or modify any terms, please use the 'Edit Terms' option instead.
        </div>
      </>
    );
  }

  render() {
    const { isLoading, isEditCustomTerms, isEditMode } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    return (
      <div>
        {this.renderSuccessModal()}
        <div className="col-9">
          <div className="mt-3">
            <h2>Term Dates</h2>
            {isEditCustomTerms ? <this.customTerms /> : <this.defaultTerms />}
            {!isEditMode && (
              <div>
                {this.renderCreateButtonInfo()}
                <div className="mt-3 mb-5">
                  <button type="button" className="btn btn-lg btn-primary" onClick={this.onEditTermsClick}>
                    Edit terms
                  </button>
                  <button type="button" className="ml-3 btn btn-lg btn-primary" onClick={this.onCreateTermsClick}>
                    Create new terms
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
