export const TRANSACTION_CURRENCY_SYMBOL = {
  POUND: '£'
};

export const TRANSACTION_STATUS = {
  SUCCESS: 'succeeded',
  PENDING: 'pending',
  FAILED: 'failed',
  REFUND: 'refunded',
  PARTIAL_REFUND: 'partially_refunded'
};

export const TRANSACTION_STATUS_SERVER_TO_CLIENT_MAPPING = {
  succeeded: 'Success',
  pending: 'Pending',
  failed: 'Failed',
  refunded: 'Refund',
  partially_refunded: 'Partial refund'
};
