import * as React from 'react';
import { AdminBoardGalleryItem } from './ArbiterBoard';
import { GALLERY_ITEM_PHOTO, GALLERY_ITEM_VIDEO } from '../../../../../../consts/arbiterBoard';
import * as Hls from 'hls.js';
import * as classNames from 'classnames';
import { Loader } from '../../../../../../components/Loader/Loader';
import { AccessPresetRadioButtons } from '../SchoolEventView/Gallery/AccessPresetRadioButtons';
import { ACCESS_PRESET_TYPE } from '../../../../../../types/album';
import { ARBITER_BOARD_GALLERY_ITEM_TYPE } from '../../../../../../types/arbiterBoard';
import { ACCESS_PRESET_SERVER_TO_CLIENT_MAPPING } from '../../../../../../consts/album';
import { getAccessPresetColor } from '../../../../../../helpers/event/eventPhotoAndVideo';

interface Props {
  item: AdminBoardGalleryItem;
  onLeftClick: () => void;
  onRightClick: () => void;
  onAccessPresetClick: (id: string, accessPreset: ACCESS_PRESET_TYPE, type: ARBITER_BOARD_GALLERY_ITEM_TYPE) => void;
  isUpdateAccessPreset: boolean;
}
interface State {}

export class ArbiterBoardGalleryFullScreen extends React.Component<Props, State> {
  videoFileRef: any;

  constructor(props) {
    super(props);

    this.videoFileRef = React.createRef();
  }

  componentDidMount() {
    const { item } = this.props;
    const { type, url } = item;

    const isItemVideo = type === GALLERY_ITEM_VIDEO;
    const isUrlExist = typeof url !== 'undefined';

    if (isItemVideo && isUrlExist) {
      const hls = new Hls();
      hls.loadSource(url);
      hls.attachMedia(this.videoFileRef);
    }
  }

  componentDidUpdate(prevProps) {
    const { item } = this.props;
    const { type, url } = item;

    const isItemChange = url !== prevProps.item.url;
    const isItemVideo = type === GALLERY_ITEM_VIDEO;
    const isUrlExist = typeof url !== 'undefined';

    if (isItemVideo && isItemChange && isUrlExist) {
      const hls = new Hls();
      hls.loadSource(item.url);
      hls.attachMedia(this.videoFileRef);
    }
  }

  render() {
    const { item, onRightClick, onLeftClick, onAccessPresetClick, isUpdateAccessPreset } = this.props;
    const { type } = item;
    const isItemPhoto = type === GALLERY_ITEM_PHOTO;

    const lBtnClasses = classNames({
      eArrow: true,
      mLeft: true
    });

    const rBtnClasses = classNames({
      eArrow: true,
      mRight: true
    });

    const color = getAccessPresetColor(item.accessPreset);

    return (
      <div className={'mb-3 mt-3'}>
        <div className="eArbiterBoardGalleryFullScreenSection">
          <div className={'eArbiterBoardGalleryFullScreenWrapper'}>
            {isItemPhoto ? (
              <img className={'eArbiterBoardGalleryFullScreenPhoto'} src={item.url} alt={'Fullscreen photo'} />
            ) : (
              <video
                controls={true}
                style={{ width: '100%', height: '400px', backgroundColor: '#000000' }}
                ref={input => (this.videoFileRef = input)}
              />
            )}
            <div className={lBtnClasses} onClick={onLeftClick} />
            <div className={rBtnClasses} onClick={onRightClick} />
            <div className="eArbiterBoardGalleryFullScreen_accessPreset" style={{ color: color }}>
              <span>{ACCESS_PRESET_SERVER_TO_CLIENT_MAPPING[item.accessPreset]}</span>
            </div>
          </div>
          <div className="eAccessPresetRadioButtonsSection">
            {isUpdateAccessPreset ? (
              <Loader />
            ) : (
              <AccessPresetRadioButtons
                accessPreset={item.accessPreset}
                onAccessPresetClick={accessPreset => {
                  onAccessPresetClick(item.id, accessPreset, item.type);
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
