import * as React from 'react';
import { Component } from 'react';
import * as ReactDOM from 'react-dom';

import './ResizablePanel.css';
import { CONTENT_HEIGHT } from '../../consts/height';

interface State {
  isDragging: boolean;
  panels: any[];
  currentPanel: number;
  initialPos: number;
  delta: number;
}

export class ResizablePanel extends Component<{}, State> {
  constructor(props) {
    super(props);

    const initialPanelHeight = CONTENT_HEIGHT / 2;

    this.state = {
      isDragging: false,
      panels: [initialPanelHeight, initialPanelHeight],
      currentPanel: null,
      initialPos: 0,
      delta: 0
    };
  }

  componentDidMount() {
    ReactDOM.findDOMNode(this).addEventListener('mousemove', this.resizePanel);
    ReactDOM.findDOMNode(this).addEventListener('mouseup', this.stopResize);
    ReactDOM.findDOMNode(this).addEventListener('mouseleave', this.stopResize);
  }

  startResize = (event, index) => {
    this.setState({
      isDragging: true,
      currentPanel: index,
      initialPos: event.clientY
    });
  };

  stopResize = () => {
    if (this.state.isDragging) {
      this.setState(({ panels, currentPanel, delta }) => ({
        isDragging: false,
        panels: {
          ...panels,
          [currentPanel]: (panels[currentPanel] || 0) - delta,
          [currentPanel - 1]: (panels[currentPanel - 1] || 0) + delta
        },
        delta: 0,
        currentPanel: null
      }));
    }
  };

  resizePanel = event => {
    if (this.state.isDragging) {
      const delta = event.clientY - this.state.initialPos;
      this.setState({
        delta: delta
      });
    }
  };

  render() {
    const children = React.Children.toArray(this.props.children);
    const rest = children.slice(1);
    return (
      <div onMouseUp={() => this.stopResize()} style={{ height: CONTENT_HEIGHT + 'px' }}>
        <div style={{ height: `calc(100% - ${this.state.panels[1]}px)`, overflowX: 'hidden' }}>
          {this.props.children[0]}
        </div>
        {[].concat(
          ...rest.map((child, i) => {
            return [
              <div
                onMouseDown={e => this.startResize(e, i + 1)}
                key={'resizer_' + i}
                style={this.state.currentPanel === i + 1 ? { top: this.state.delta } : {}}
                className="bResizer"
              />,

              <div className="px-3" key={'panel_' + i} style={{ height: this.state.panels[i + 1], overflow: 'auto' }}>
                {child}
              </div>
            ];
          })
        )}
      </div>
    );
  }
}
