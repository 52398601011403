import * as React from 'react';
import { AppUser } from 'Src/views/App/App';
import { ModalDialog } from '../ModalDialog/ModalDialog';
import * as propz from 'propz';

import { Championship } from '../../models/championship';
import { Tournament } from '../../models/tournament';
import { FunctionComponent } from 'react';

interface Props {
  user: AppUser;
  onSubmitChampionshipClick: (data, championshipId) => void;
  onCancelClick: () => void;
  championships: Championship[];
  tournaments: Tournament[];
  isOpen: boolean;
}

export const TournamentRemoveToChampionshipModal: FunctionComponent<Props> = props => {
  const { tournaments, championships } = props;

  const tournamentIds = tournaments.map(tournament => tournament.id);
  const championshipsFiltered = championships.filter(championship => {
    return tournamentIds.every(tournamentId =>
      championship.tournamentIds.some(championshipTournamentId => tournamentId === championshipTournamentId)
    );
  });

  const championshipName = propz.get(championshipsFiltered[0], ['name'], '');
  const championshipId = championshipsFiltered[0].id; // [0] since the tournament can only be in one championship

  const tournamentsName = tournaments.map(tournament => tournament.name).join(', ');
  const isOneTournament = tournamentIds.length === 1;

  const onSubmit = (): void => {
    const data = {
      remove: tournamentIds
    };

    props.onSubmitChampionshipClick(data, championshipId);
  };

  return (
    <ModalDialog
      title="Remove tournament to championship"
      isOpen={props.isOpen}
      positiveButtonTitle="Remove"
      negativeButtonTitle="Cancel"
      onPositiveButtonClick={onSubmit}
      onNegativeButtonClick={props.onCancelClick}
    >
      <h5 className="text-center">
        Are you sure you want to exclude the {isOneTournament ? `tournament ` : `tournaments`}
        <br />
        {tournamentsName}
        <br />
        from the championship
        <br />
        {championshipName} ?
      </h5>
    </ModalDialog>
  );
};

TournamentRemoveToChampionshipModal.displayName = 'TournamentRemoveToChampionshipModal';
