import * as React from 'react';
import { Route, Switch } from 'react-router';
import { ConsentRequestsInbox } from './ConsentRequestsInbox/ConsentRequestsInbox';
import { ConsentRequestsArchive } from './ConsentRequestsArchive/ConsentRequestsArchive';

export const ConsentRequestsRouting = allProps => {
  return (
    <Switch>
      <Route
        exact
        path={'/consentRequests/inbox'}
        render={props => (
          <ConsentRequestsInbox {...props} setNeedUpdate={allProps.setNeedUpdate} user={allProps.user} />
        )}
      />
      <Route
        exact
        path={'/consentRequests/archive'}
        render={props => <ConsentRequestsArchive {...props} user={allProps.user} />}
      />
    </Switch>
  );
};

ConsentRequestsRouting.displayName = 'ConsentRequestsRouting';
