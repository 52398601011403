import * as React from 'react';
import { Field, Form, Formik } from 'formik';
import { Button } from '../../../components/Button/Button';
import * as Yup from 'yup';
import { checkAvailability } from '../../../helpers/service/public/register';
import { Checkbox } from '../../../components/Checkbox/Checkbox';
import { PHONE_CODE } from '../../../consts/common';
import { getUKPhoneWithoutFirstZero } from '../../../helpers/phone/phone';

interface Props {
  onSubmit: (data) => void;
  onCancel: () => void;
}

const TERMS_TEXT = (
  <>
    I have read and agree to the
    <a href="https://www.squadintouch.com/privacy-policy/" target="_blank">
      {' '}
      Privacy Policy
    </a>
    ,
    <a href="https://www.squadintouch.com/terms-of-use/" target="_blank">
      {' '}
      Terms of Use
    </a>{' '}
    and
    <a href="https://www.squadintouch.com/acceptable-use-policy/" target="_blank">
      {' '}
      Acceptable Use Policy
    </a>
  </>
);

const ADDITIONAL_TERMS_TEXT = (
  <>
    I confirm I am 13 years old or over. I do understand that if it is revealed that I am younger than 13 years old my
    account may be disabled until either a parent or guardian has provided a verified permission to Squad In Touch for
    me to use my Squad In Touch account.
  </>
);

export function PersonalDetailsForm(props: Props) {
  const { onSubmit, onCancel } = props;

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    phone: '',
    phoneCode: PHONE_CODE.UK,
    terms: false,
    additionalTerms: false
  };

  const PersonalDetailsSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    password: Yup.string()
      .required('Required')
      .test(
        'password',
        'Password must contain at least one capital, lower case character, one number and must be 8 digits long',
        value => {
          const passwordRegExp = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/);

          return value && passwordRegExp.test(value);
        }
      ),
    confirmPassword: Yup.string().test('confirmPassword', 'Passwords must match', function(value) {
      return value === this.parent.password;
    }),
    email: Yup.string()
      .required('Required')
      .email('Must be valid email')
      .test('email', 'Duplicate email', value => {
        if (!value) {
          return false;
        } else {
          return checkAvailability({ email: value }).then(res => {
            return res.isAvailable;
          });
        }
      }),
    phone: Yup.string()
      .required('Required')
      .test('phone', 'Duplicate phone or incorrect symbol', function(value) {
        const phoneRegExp = /^\d+$|^\d+#\d+$/;
        const phoneCode = this.parent.phoneCode;
        const phone = getUKPhoneWithoutFirstZero(value, phoneCode);
        const result = phoneRegExp.test(phone);
        const phoneForCheck = `${phoneCode}${phone}`;

        return result
          ? checkAvailability({ phone: phoneForCheck }).then(res => {
              return res.isAvailable;
            })
          : false;
      })
      .test('phone', 'Please enter a valid mobile phone number', value => {
        if (value && value.length < 7) {
          return false;
        } else {
          return true;
        }
      }),
    terms: Yup.boolean().test('terms', 'Select the tick box to continue', value => value),
    additionalTerms: Yup.boolean().test('additionalTerms', 'Select the tick box to continue', value => value)
  });

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik
            initialValues={initialValues}
            validationSchema={PersonalDetailsSchema}
            onSubmit={onSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            render={({ touched, errors, setFieldValue, values }) => (
              <Form className={'mt-3 text-left'}>
                <div className="form-group">
                  <label>Name</label>
                  <Field name="firstName" className={'form-control mb-3'} />
                  {touched.firstName && errors.firstName && (
                    <div className="alert alert-danger">{errors.firstName}</div>
                  )}
                </div>

                <div className="form-group">
                  <label>Surname</label>
                  <Field name="lastName" className={'form-control mb-3'} />
                  {touched.lastName && errors.lastName && <div className="alert alert-danger">{errors.lastName}</div>}
                </div>

                <div className="form-group">
                  <label>Email</label>
                  <Field name="email" className={'form-control mb-3'} />
                  {touched.email && errors.email && <div className="alert alert-danger">{errors.email}</div>}
                </div>

                <div className="form-row">
                  <div className="form-group col-md-2">
                    <label>Phone code</label>
                    <Field name="phoneCode" component="select" className="form-control">
                      <option value={PHONE_CODE.UK}>{PHONE_CODE.UK}</option>
                      <option value={PHONE_CODE.US}>{PHONE_CODE.US}</option>
                      <option value={PHONE_CODE.AE}>{PHONE_CODE.AE}</option>
                      <option value={PHONE_CODE.DE}>{PHONE_CODE.DE}</option>
                    </Field>
                  </div>

                  <div className="form-group col-md-10">
                    <label>Phone</label>
                    <Field name="phone" placeholder="Phone" className={'form-control mb-3'} />
                    {touched.phone && errors.phone && <div className="alert alert-danger">{errors.phone}</div>}
                  </div>
                </div>

                <div className="form-group">
                  <label>Password</label>
                  <Field type={'password'} name="password" className={'form-control mb-3'} />
                  {touched.password && errors.password && <div className="alert alert-danger">{errors.password}</div>}
                </div>

                <div className="form-group">
                  <label>Confirm password</label>
                  <Field type={'password'} name="confirmPassword" className={'form-control mb-3'} />
                  {touched.confirmPassword && errors.confirmPassword && (
                    <div className="alert alert-danger">{errors.confirmPassword}</div>
                  )}
                </div>

                <Field
                  name="terms"
                  render={({ field }) => (
                    <Checkbox id={'terms'} isOnChangeIdRequired={false} {...field} labelText={TERMS_TEXT} />
                  )}
                />
                {touched.terms && errors.terms && <div className="alert alert-danger">{errors.terms}</div>}

                <Field
                  name="additionalTerms"
                  render={({ field }) => (
                    <Checkbox
                      id={'additionalTerms'}
                      isOnChangeIdRequired={false}
                      {...field}
                      labelText={ADDITIONAL_TERMS_TEXT}
                    />
                  )}
                />
                {touched.additionalTerms && errors.additionalTerms && (
                  <div className="alert alert-danger">{errors.additionalTerms}</div>
                )}

                <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                <button type="submit" className="mt-3 mb-3 btn btn-primary">
                  Submit
                </button>
              </Form>
            )}
          />
        </div>
      </div>
    </div>
  );
}
