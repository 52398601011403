import * as React from 'react';
import { Map } from 'Src/components/Map/Map';
import { Point } from '../../models/venue';
import { Button } from '../Button/Button';

interface Props {
  point: Point;
  onOkClick: () => void;
}

export function VenueView(props: Props) {
  const { point, onOkClick } = props;

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Map point={point} customStylingClass="mb-3 eClubWizardMap" isMarkerDraggable={false} />
          <Button onClick={onOkClick} text={'Ok'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
        </div>
      </div>
    </div>
  );
}
