import { ACCESS_PRESET } from '../../consts/album';
import { ACCESS_PRESET_TYPE } from '../../types/album';

export function getAccessPresetColor(accessPreset: ACCESS_PRESET_TYPE) {
  switch (accessPreset) {
    case ACCESS_PRESET.PRIVATE:
      return '#38AF24';
    case ACCESS_PRESET.PUBLIC:
      return '#FB3333';
    case ACCESS_PRESET.ALL_PARTICIPANT_PARENTS:
      return '#FDCA22';
  }
}
