import * as React from 'react';
import * as propz from 'propz';
import { FunctionComponent } from 'react';
import * as Moment from 'moment';
import { getInviteStatus } from '../../helpers/accessor/accessor';
import {
  getAgeGroup,
  getLocation,
  getRivalName,
  getRivalSchool,
  getSchoolInviteRecepient,
  getSchoolUnionNameIfNeed,
  getTeamDataName,
  getTournamentNameIfNeed
} from '../../helpers/invite/invite';
import { SchoolInvite } from '../../models/invite';
import { SPORT_GENDER_SERVER_TO_CLIENT_MAPPING } from '../../consts/common';

interface Props {
  invite: SchoolInvite;
  activeSchoolId: string;
  messageType: string;
}

const ROWS = [
  { title: 'School', field: 'schoolName' },
  { title: 'Event', field: 'event' },
  { title: 'Start time', field: 'startTime' },
  { title: 'Venue', field: 'venue' },
  { title: 'Status', field: 'status' }
];

export const AdminSchoolsEventSchoolInviteSummary: FunctionComponent<Props> = props => {
  const rows = ROWS.map((row, index) => {
    let contentCell: any;
    const field = row.field;

    const { invite, messageType, activeSchoolId } = props,
      { event, invitedSchools, inviterSchool, invitedSchool } = invite;

    switch (field) {
      case 'schoolName':
        const recepient = getSchoolInviteRecepient(inviterSchool, invitedSchool, activeSchoolId);

        const eventSchoolName = getSchoolUnionNameIfNeed(inviterSchool),
          rival = getRivalSchool(inviterSchool, invitedSchool, activeSchoolId, messageType),
          rivalName = getRivalName(inviterSchool, invitedSchools, rival, activeSchoolId),
          tournamentName = getTournamentNameIfNeed(invite),
          teamDataName = getTeamDataName(event.teamsData, inviterSchool, messageType);

        contentCell = `${eventSchoolName}${rivalName}${tournamentName}${recepient}${teamDataName}`;
        break;
      case 'event':
        const sportName = invite.sport ? invite.sport.name : '';
        const gender = event.gender ? ` / ${SPORT_GENDER_SERVER_TO_CLIENT_MAPPING[event.gender]}` : '',
          ageGroup = getAgeGroup(event),
          formAgeGroup = ageGroup !== '' ? ` / ${ageGroup}` : '';

        contentCell = `${sportName}${gender}${formAgeGroup}`;
        break;
      case 'startTime':
        const eventDate = new Date(event.startTime);

        contentCell = typeof eventDate !== 'undefined' ? Moment(eventDate).format('DD.MM.YYYY HH:mm') : '';
        break;
      case 'venue':
        contentCell = getLocation(invite.event, activeSchoolId);
        break;
      case 'status':
        contentCell = typeof invite.status !== 'undefined' ? getInviteStatus(invite) : '';
        break;
      default:
        contentCell = propz.get(invite, row.field.split('.'), '');
    }

    return (
      <tr key={`invite_summary_${index}`}>
        <td>{row.title}</td>
        <td>{contentCell}</td>
      </tr>
    );
  });

  return (
    <table className={'table table-sm mPre mVerticalAlignMiddle'}>
      <tbody>{rows}</tbody>
    </table>
  );
};

AdminSchoolsEventSchoolInviteSummary.displayName = 'AdminSchoolsEventSchoolInviteSummary';
