import * as React from 'react';
import { Field, Form, Formik } from 'formik';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/report';
import { Switch } from 'Src/components/Switch/Switch';
import { LabelWithQuestionIcon } from 'Src/components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import { MultiSelectCheckboxes } from 'Src/components/MultiSelectCheckboxes/MultiSelectCheckboxes';
import {
  getReportAgesForMultiSelect,
  getReportDefaultGendersForMultiSelect
} from 'Src/helpers/multiselect/multiselect';
import { School } from 'Src/models/school';
import { SchoolForm } from 'Src/models/form';
import '../../SchoolEventView.scss';

interface Props {
  onCloseClick: () => void;
  onSubmit: (data: any, reportType: string) => void;
}

//report 053
export function StudentTargetCompletionReportForm({ onCloseClick, onSubmit }: Props) {
  const requestInitial = {
    isShowCompleted: true,
    isShowUncompleted: true
  };

  return (
    <Formik
      onSubmit={values => onSubmit(values, REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.STUDENT_TARGET_COMPLETION)}
      initialValues={requestInitial}
      render={({ values, setFieldValue }) => (
        <Form>
          <div className="bReportFormTitle">'Student Target Completion'</div>
          <div className="mb-3 mt-3">
            {'The report includes a list of all students added to this target checklist, ' +
              ' as well as a clear indication of whether or not they have completed their target. ' +
              ' In addition to this, the report also features the date of confirmation and the name ' +
              ' of the staff member responsible for confirming each student completion status as well ' +
              ' as additional detail on whether or not photo and/video evidence has been added.'}
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isShowCompleted"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowCompleted}
                    customClass="mb-3"
                    onChange={event => {
                      const isShowCompleted = event.target.checked;
                      setFieldValue('isShowCompleted', isShowCompleted);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show completed targets"
                hintText={`This will allow you to view the students who have been marked as having completed their target.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowCompleted"
              />
            </div>
          </div>

          <div className="form-check">
            <div className="d-flex">
              <Field
                name="isShowUncompleted"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowUncompleted}
                    customClass="mb-3"
                    onChange={event => {
                      const isShowUncompleted = event.target.checked;
                      setFieldValue('isShowUncompleted', isShowUncompleted);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show Incomplete Targets"
                hintText={`This will allow you to view the students who have not been marked as having completed their target.`}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowUncompleted"
              />
            </div>
          </div>

          <button type="button" className="btn btn-secondary mr-3" onClick={onCloseClick}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Get
          </button>
        </Form>
      )}
    />
  );
}
