import * as BPromise from 'bluebird';
// copy-paste from old frontend
export function getResizedToMinValueUrl(origUrl: string, minValue: number) {
  return `${origUrl}?sizing=minvalue&value=${minValue}`;
}
// copy-paste from old frontend
export function isCanvasSupported() {
  return !!document.createElement('canvas').getContext;
}
// copy-paste from old frontend
export function rotateImage(picUrl: any, angle: number, imageFormat?: string): BPromise<any> {
  const canvas = addCanvasToPage(),
    image = document.createElement('img');
  return loadImage(picUrl, image, canvas)
    .then(result => drawRotateCanvas(angle, result.image, result.canvas, imageFormat))
    .finally(deleteCanvasFromPage(canvas));
}
// copy-paste from old frontend
function loadImage(picUrl: string, image: any, canvas: any): any {
  return new BPromise((resolve, reject) => {
    image.crossOrigin = 'anonymous';
    image.onload = () => {
      canvas.width = image.height;
      canvas.height = image.width;
      resolve({ image, canvas });
    };
    image.src = picUrl;
    image.onerror = e => reject(e);
  });
}
// copy-paste from old frontend
function addCanvasToPage(): any {
  const canvas = document.createElement('canvas'),
    body = document.getElementsByTagName('body')[0];

  (canvas as any).class = 'mDisplayNone';
  body.appendChild(canvas);
  return canvas;
}
// copy-paste from old frontend
function deleteCanvasFromPage(canvas: any): void {
  const body = document.getElementsByTagName('body')[0];
  body.removeChild(canvas);
}
// copy-paste from old frontend
function drawRotateCanvas(angle: number, image: any, canvas: any, imageFormat: string): any {
  const ctx = canvas.getContext('2d');
  ctx.translate(image.height / 2, image.width / 2);
  ctx.rotate(angle);
  ctx.drawImage(image, -(image.width / 2), -(image.height / 2));
  return canvas.toDataURL(`image/${imageFormat}`);
}
// copy-paste from old frontend
export function dataURLtoBlob(dataUrl): Blob {
  const arrayFromDataUrl = dataUrl.split(',');
  const mimeType = arrayFromDataUrl[0].match(/:(.*?);/)[1]; //mime type: image/jpeg
  const stringFromDataUrl = window.atob(arrayFromDataUrl[1]); //function decodes a string of data which has been encoded using base-64 encoding

  let stringFromDataUrlLength = stringFromDataUrl.length;
  const u8arr = new window.Uint8Array(stringFromDataUrlLength);

  while (stringFromDataUrlLength--) {
    u8arr[stringFromDataUrlLength] = stringFromDataUrl.charCodeAt(stringFromDataUrlLength);
  }

  return new Blob([u8arr], { type: mimeType });
}
