import * as React from 'react';
import * as propz from 'propz';
import { Field, Form, Formik } from 'formik';
import { Button } from 'Src/components/Button/Button';
import { AGE_GROUPS } from 'Src/consts/school';
import { TOURNAMENT_GENDER_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/tournament';
import { TournamentSchoolInvite } from 'Src/models/invite';
import { GENDER } from 'Src/consts/common';
import { getTeamPlacesLeft } from 'Src/helpers/tournament/tournament';
import { checkIsTeamCountNegativeNumber, checkIsTeamLimitExceeded } from 'Src/helpers/team/team';

interface Props {
  onSubmit: (data) => void;
  onCancel: () => void;
  tournament: TournamentSchoolInvite;
}

export function AddTeamsForm(props: Props) {
  const { onSubmit, onCancel, tournament } = props;

  const { autoEnrollmentTeamsSettings = [] } = tournament;
  const isMixedTeamsAvailable = propz.get(tournament, ['isMixedTeamsAvailable']);

  const ages = propz.get(tournament, ['ages'], []);
  const gender = propz.get(tournament, ['gender'], GENDER.MIXED);

  const isGenderMixed = gender === GENDER.MIXED;
  const isGenderMale = gender === GENDER.MALE_ONLY;
  const isGenderFemale = gender === GENDER.FEMALE_ONLY;

  const maximumNumberTeamsOfSchool = propz.get(tournament, ['maximumNumberTeamsOfSchool'], 0);

  let initialValues = {};

  switch (true) {
    case isGenderMixed && !isMixedTeamsAvailable:
      ages.forEach(age => {
        initialValues[`${age}-${GENDER.MALE_ONLY}`] = 0;
        initialValues[`${age}-${GENDER.FEMALE_ONLY}`] = 0;
      });
      break;
    case isGenderMixed && isMixedTeamsAvailable:
      ages.forEach(age => {
        initialValues[`${age}-${GENDER.MIXED}`] = 0;
      });
      break;
    case isGenderMale:
      ages.forEach(age => {
        initialValues[`${age}-${GENDER.MALE_ONLY}`] = 0;
      });
      break;
    case isGenderFemale:
      ages.forEach(age => {
        initialValues[`${age}-${GENDER.FEMALE_ONLY}`] = 0;
      });
      break;
  }

  const renderAddTeamsFormFields = () => {
    switch (true) {
      case isGenderMixed && !isMixedTeamsAvailable:
        return ages.map(age => {
          const teamPlacesLeftMale = getTeamPlacesLeft(autoEnrollmentTeamsSettings, age, GENDER.MALE_ONLY);
          const teamPlacesLeftFemale = getTeamPlacesLeft(autoEnrollmentTeamsSettings, age, GENDER.FEMALE_ONLY);

          const maleFormFields = renderField(
            autoEnrollmentTeamsSettings,
            maximumNumberTeamsOfSchool,
            teamPlacesLeftMale,
            age,
            GENDER.MALE_ONLY
          );
          const femaleFormFields = renderField(
            autoEnrollmentTeamsSettings,
            maximumNumberTeamsOfSchool,
            teamPlacesLeftFemale,
            age,
            GENDER.FEMALE_ONLY
          );
          return [maleFormFields, femaleFormFields];
        });
      case isGenderMixed && isMixedTeamsAvailable:
        return ages.map(age => {
          const teamPlacesLeft = getTeamPlacesLeft(autoEnrollmentTeamsSettings, age, gender);
          return renderField(autoEnrollmentTeamsSettings, maximumNumberTeamsOfSchool, teamPlacesLeft, age, gender);
        });
      case isGenderMale:
        return ages.map(age => {
          const teamPlacesLeft = getTeamPlacesLeft(autoEnrollmentTeamsSettings, age, gender);
          return renderField(autoEnrollmentTeamsSettings, maximumNumberTeamsOfSchool, teamPlacesLeft, age, gender);
        });
      case isGenderFemale:
        return ages.map(age => {
          const teamPlacesLeft = getTeamPlacesLeft(autoEnrollmentTeamsSettings, age, gender);
          return renderField(autoEnrollmentTeamsSettings, maximumNumberTeamsOfSchool, teamPlacesLeft, age, gender);
        });
    }
  };

  const renderField = (autoEnrollmentTeamsSettings, maximumNumberTeamsOfSchool, teamPlacesLeft, age, gender) => {
    const autoEnrollmentTeamsSetting = autoEnrollmentTeamsSettings.find(setting => {
      return setting.age === age && setting.gender === gender;
    });

    const isLimitSpaces = autoEnrollmentTeamsSetting.maximumNumberTeams !== 0;

    const availablePlaceText = isLimitSpaces ? `, ${teamPlacesLeft} spaces left` : '';
    const maximumNumberTeamsOfSchoolText =
      maximumNumberTeamsOfSchool !== 0 ? `, maximum - ${maximumNumberTeamsOfSchool} teams` : ``;

    const ageGroup = AGE_GROUPS['U5-U18'][age];
    const genderMapped = TOURNAMENT_GENDER_SERVER_TO_CLIENT_MAPPING[gender];

    return (
      <div key={`${age}-${gender}`} className="form-group">
        <label>{`${ageGroup}, ${genderMapped}${availablePlaceText}${maximumNumberTeamsOfSchoolText}`}</label>
        <Field type="text" name={`${age}-${gender}`} className="form-control mb-3" />
      </div>
    );
  };

  const validate = values => {
    let errors = {};

    //validation
    let isPositiveNumberError = checkIsTeamCountNegativeNumber(values);

    if (isPositiveNumberError) {
      errors['commonError'] = 'Teams count should be positive number';
      return errors;
    }

    let IsLimitExceedError = checkIsTeamLimitExceeded(values, autoEnrollmentTeamsSettings, maximumNumberTeamsOfSchool);

    if (IsLimitExceedError) {
      errors['commonError'] = 'Limit exceeded';
      return errors;
    }

    const isAllGenderAndAgeTeamPlacesLeft = autoEnrollmentTeamsSettings.every(autoEnrollmentTeamsSetting => {
      return autoEnrollmentTeamsSetting.maximumNumberTeams - autoEnrollmentTeamsSetting.currentNumberTeams > 0;
    });

    let isAllTeamsCountEmpty = true;

    for (let key in values) {
      const currentTeamsCount = Number(values[key]);
      if (currentTeamsCount !== 0) {
        isAllTeamsCountEmpty = false;
      }
    }

    if (isAllGenderAndAgeTeamPlacesLeft && isAllTeamsCountEmpty) {
      errors['commonError'] = 'You must specify at least one team to be accepted for the tournament';
      return errors;
    }

    return errors;
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validate}
            render={({ touched, errors }) => (
              <Form>
                <div className="form-group form-check">
                  {renderAddTeamsFormFields()}
                  {errors.commonError ? <div className="alert alert-danger">{errors.commonError}</div> : null}
                  <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                  <button type="submit" className="mt-3 mb-3 btn btn-primary">
                    Book spaces
                  </button>
                </div>
              </Form>
            )}
          />
        </div>
      </div>
    </div>
  );
}
