import * as React from 'react';
import * as propz from 'propz';
import { Field, Form, Formik } from 'formik';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from '../../../../../../consts/report';
import { Tournament } from '../../../../../../models/tournament';
import { Switch } from '../../../../../../components/Switch/Switch';
import { LabelWithQuestionIcon } from '../../../../../../components/LabelWithQuestionIcon/LabelWithQuestionIcon';

interface Props {
  onCloseClick: () => void;
  onSubmit: (data: any, reportType: string) => void;
  tournament: Tournament;
}

// report 034
export function TournamentStartListReportForm(props: Props) {
  const { tournament } = props;

  const requestInitial = {
    isShowBlank: true,
    isHighlightNoAge: true,
    isPrintScoreCards: false
  };

  return (
    <Formik
      onSubmit={values => {
        props.onSubmit(values, REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.TOURNAMENT_START_LIST);
      }}
      initialValues={requestInitial}
      render={({ values, setFieldValue }) => (
        <Form>
          <div className="bReportFormTitle">Tournament start list</div>
          <div className="mb-3 mt-3">
            {'View the full list of events and which schools have entered students into which events, ' +
              'select if you would like to see details of schools that have not added participants yet ' +
              'or just those schools who have added participating students.'}
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isShowBlank"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isShowBlank}
                    onChange={event => {
                      const isShowBlank = event.target.checked;
                      setFieldValue('isShowBlank', isShowBlank);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Show empty entries"
                hintText={'Show schools/teams that have not yet added participants to the events.'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isShowBlank"
              />
            </div>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isHighlightNoAge"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isHighlightNoAge}
                    onChange={event => {
                      const isHighlightNoAge = event.target.checked;
                      setFieldValue('isHighlightNoAge', isHighlightNoAge);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Highlight students without DOB"
                hintText={'Highlight students if their data does not contain their date of birth.'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isHighlightNoAge"
              />
            </div>
          </div>

          <div className="form-group">
            <div className="d-flex">
              <Field
                name="isPrintScoreCards"
                render={({ field }) => (
                  <Switch
                    {...field}
                    value={values.isPrintScoreCards}
                    onChange={event => {
                      const isPrintScoreCards = event.target.checked;
                      setFieldValue('isPrintScoreCards', isPrintScoreCards);
                    }}
                  />
                )}
              />
              <LabelWithQuestionIcon
                labelText="Include score cards"
                hintText={'Separate ready to print score cards will be created for each event.'}
                customLabelClass="form-check-label pl-2"
                labelHtmlFor="isPrintScoreCards"
              />
            </div>
          </div>

          <button type="button" className="btn btn-secondary mr-3" onClick={props.onCloseClick}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Get
          </button>
        </Form>
      )}
    />
  );
}
