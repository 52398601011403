import * as React from 'react';
import { FunctionComponent } from 'react';
import { SimpleSidebarItem } from './Sidebar';
import { NavLink } from 'react-router-dom';
import { AppUser } from 'Src/views/App/App';
import { getUserActiveSchoolSubscriptionPlan } from '../../helpers/user/user';

interface Props {
  item: SimpleSidebarItem;
  user: AppUser;
}

export const SidebarItem: FunctionComponent<Props> = props => {
  const { user, item } = props;
  const { itemTitle, to, visibleToSchoolSubscriptions, notificationCount } = item;

  const isNotificationExist = typeof notificationCount !== 'undefined';
  const isNotificationToShow = isNotificationExist && Number(notificationCount) > 0;

  const isVisible = visibleToSchoolSubscriptions.some(
    subscription => subscription === getUserActiveSchoolSubscriptionPlan(user)
  );

  return isVisible ? (
    <NavLink activeStyle={{ fontWeight: 'bold', color: '#3bafda' }} className={'nav-link eSidebarItem'} to={to}>
      <div className="eSidebarItemNotificationWrapper">
        {itemTitle}
        {isNotificationToShow && <span className="eSidebarItemNotification">{notificationCount}</span>}
      </div>
    </NavLink>
  ) : null;
};

SidebarItem.displayName = 'SidebarItem';
