import * as React from 'react';
import { SchoolEvent } from '../../../../../../../models/event';
import { ValueHashMap } from '../ResultsViews/IndividualInternalResults';
import {
  convertPointsToString,
  getDigitMask,
  getExtraPointsByStudent,
  getIndividualScoreByStudent,
  getPlayerScoreId,
  compareNames
} from '../../../../../../../helpers/event/schoolEventViewHelper';
import * as TextMaskCore from 'text-mask-core';
import { ARROW_DOWN_KEY, ARROW_UP_KEY, ENTER_KEY } from '../../../../../../../consts/common';
import { EMPTY_STRING } from '../../../../../../../consts/date';
import { Button } from '../../../../../../../components/Button/Button';
import { getPlayersSortedExtraPointsView } from '../../../../../../../helpers/event/event';

interface Props {
  event: SchoolEvent;
  onSaveClick: (data) => void;
  onCloseClick: () => void;
}
interface State {
  oldExtraPointHashMap: ValueHashMap;
  extraPointHashMap: ValueHashMap;
  playersCount: number;
  players: Player[];
}

interface Player {
  id: string;
  userId: string;
  permissionId: string;
  firstName: string;
  lastName: string;
  houseId: string;
  houseName: string;
  houseColors: string[];
}

export class IndividualInternalHousesExtraPoints extends React.Component<Props, State> {
  inputElement: any[];
  textMaskInputElement: any[];

  constructor(props: Props) {
    super(props);

    this.inputElement = [];
    this.textMaskInputElement = [];

    const { event } = props;
    const { housesData, individualsData } = event;

    let extraPointHashMap = {};
    let oldExtraPointHashMap = {};
    let players = [];

    housesData.forEach(house => {
      const houseId = house.id;
      const houseName = house.name.slice(0, 3); // Get first three letters of house name
      const houseColors = house.colors; // Assuming house.colors contains the color information
      const playersFiltered = individualsData.filter(individual => individual.houseId === houseId);

      getPlayersSortedExtraPointsView(event, playersFiltered).forEach(
        ({ id, userId, permissionId, firstName, lastName }) => {
          const extraPoints = getExtraPointsByStudent(event, userId);
          const extraPointsStr = String(extraPoints);

          extraPointHashMap[id] = extraPoints === 0 ? '' : extraPointsStr;
          oldExtraPointHashMap[id] = extraPoints === 0 ? '' : extraPointsStr;
          players.push({ id, userId, permissionId, houseId, firstName, lastName, houseName, houseColors });
        }
      );
    });

    // Sorting logic
    const { scoring } = event.sport;
    players.sort((player1, player2) => {
      const points1 = getExtraPointsByStudent(event, player1.userId);
      const points2 = getExtraPointsByStudent(event, player2.userId);

      if (points1 !== points2) {
        return points2 - points1; // Descending order
      }

      const result1 = getIndividualScoreByStudent(event, player1.userId, player1.permissionId);
      const result2 = getIndividualScoreByStudent(event, player2.userId, player2.permissionId);

      if ((result1 === 0 || result1 === null) && (result2 === 0 || result2 === null)) {
        return compareNames(player1, player2);
      }
      if (result1 === 0 || result1 === null) return 1;
      if (result2 === 0 || result2 === null) return -1;

      if (['LESS_TIME', 'LESS_RESULT', 'LESS_SCORES'].includes(scoring)) {
        if (result1 !== result2) {
          return result1 - result2;
        }
      } else if (['MORE_TIME', 'MORE_RESULT', 'MORE_SCORES', 'FIRST_TO_N_POINTS'].includes(scoring)) {
        if (result1 !== result2) {
          return result2 - result1;
        }
      }

      return compareNames(player1, player2);
    });

    this.state = {
      oldExtraPointHashMap: oldExtraPointHashMap,
      extraPointHashMap: extraPointHashMap,
      playersCount: individualsData.length,
      players: players
    };
  }

  componentDidMount() {
    this.initTextMask();
  }

  initTextMask() {
    const { extraPointHashMap, playersCount, players } = this.state;

    for (let i = 0; i < playersCount; i++) {
      this.textMaskInputElement[i] = TextMaskCore.createTextMaskInputElement({
        inputElement: this.inputElement[i],
        mask: inputValue => getDigitMask(inputValue),
        showMask: false
      });
      const { id } = players[i];
      const value = extraPointHashMap[id];

      this.textMaskInputElement[i].update(value);
    }
  }

  onChange(event: React.ChangeEvent<HTMLInputElement>, index: number, id: string) {
    const extraPointHashMap = { ...this.state.extraPointHashMap };
    const value = event.target.value;

    extraPointHashMap[id] = value;

    this.setState({
      extraPointHashMap: extraPointHashMap
    });

    this.textMaskInputElement[index].update(value);
  }

  onKeyDown = (eventDescriptor: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    const { playersCount } = this.state;

    const isKeyEnter = eventDescriptor.key === ENTER_KEY;
    const isKeyArrowUp = eventDescriptor.key === ARROW_UP_KEY;
    const isKeyArrowDown = eventDescriptor.key === ARROW_DOWN_KEY;
    const isLastInput = index + 1 === playersCount;
    const isFirstInput = index === 0;

    switch (true) {
      case isKeyEnter && !isLastInput:
      case isKeyArrowDown && !isLastInput:
        this.inputElement[index + 1].focus();
        break;
      case isKeyArrowUp && !isFirstInput:
        this.inputElement[index - 1].focus();
        break;
    }
  };

  onFocus(e: React.FocusEvent<HTMLInputElement>, index: number, id: string) {
    const extraPoint = this.state.extraPointHashMap[id];
    const oldExtraPointHashMap = { ...this.state.oldExtraPointHashMap };
    oldExtraPointHashMap[id] = extraPoint;

    this.setState({
      oldExtraPointHashMap: oldExtraPointHashMap
    });

    this.textMaskInputElement[index].update('');

    e.stopPropagation();
  }

  onBlur(e: React.FocusEvent<HTMLInputElement>, index: number, id: string) {
    const eventValue = (e.target as HTMLInputElement).value;
    const oldExtraPointHashMap = { ...this.state.oldExtraPointHashMap };

    if (eventValue === EMPTY_STRING) {
      const extraPointHashMapWithOldValue = { ...this.state.extraPointHashMap };
      extraPointHashMapWithOldValue[id] = oldExtraPointHashMap[id];

      this.setState({
        extraPointHashMap: extraPointHashMapWithOldValue
      });
      this.textMaskInputElement[index].update(oldExtraPointHashMap[id]);
    } else {
      const extraPointHashMap = { ...this.state.extraPointHashMap };
      extraPointHashMap[id] = eventValue;

      this.setState({
        extraPointHashMap: extraPointHashMap
      });
      this.textMaskInputElement[index].update(eventValue);
    }

    e.stopPropagation();
  }

  renderInput(index: number, id: string): React.ReactNode {
    return (
      <input
        ref={el => (this.inputElement[index] = el)}
        onKeyDown={event => this.onKeyDown(event, index)}
        onChange={event => this.onChange(event, index, id)}
        onFocus={event => this.onFocus(event, index, id)}
        onBlur={event => this.onBlur(event, index, id)}
        className={'eIndividualInternalResultsInput'}
        style={{ maxWidth: '80px' }}
      />
    );
  }

  onSubmit = () => {
    const { players, extraPointHashMap } = this.state;
    const { event, onSaveClick } = this.props;

    const scores = players
      .filter(({ id }) => {
        return extraPointHashMap[id] !== '';
      })
      .map(({ userId, permissionId, id, houseId }) => ({
        userId: userId,
        permissionId: permissionId,
        houseId: houseId,
        richScore: {
          points: Number(extraPointHashMap[id])
        },
        scoreId: getPlayerScoreId(event, userId, permissionId)
      }));

    onSaveClick(scores);
  };

  render() {
    const { onCloseClick, event } = this.props;
    const { players } = this.state;

    return (
      <div style={{ width: '100%' }}>
        <h2>Participant points</h2>
        <div>
          Any extra points you add here will be used when generating the leaderboard for this event; please note when
          extra points are added, the leaderboard will be based on extra points then on results. Also, if you are
          running a house competition, aggregated house points will include these points (providing this event is
          included in an individual league).
        </div>
        <table className={'eIndividualInterHousesResultsTable'}>
          <thead>
            <tr>
              <th className={'eIndividualInterHousesResultsCell'} style={{ width: '5%' }}>
                #
              </th>
              <th className={'eIndividualInterHousesResultsCell'} style={{ width: '30%' }}>
                Player
              </th>
              <th className={'eIndividualInterHousesResultsCell'} style={{ width: '10%' }}>
                House
              </th>
              <th className={'eIndividualInterHousesResultsCell'} style={{ width: '15%' }}></th>
              <th className={'eIndividualInterHousesResultsCell'} style={{ width: '15%' }}>
                Results
              </th>
              <th className={'eIndividualInterHousesResultsCell'} style={{ width: '25%' }}>
                Extra points
              </th>
            </tr>
          </thead>
          <tbody>
            {players.map(({ firstName, lastName, id, houseName, houseColors, userId, permissionId }, index) => {
              const position = index + 1;
              const points = getIndividualScoreByStudent(event, userId, permissionId);
              const pointsStr = convertPointsToString(event, points);

              return (
                <tr key={`individual_inter_houses_results_player_${index}`}>
                  <td className={'eIndividualInterHousesResultsCell'} style={{ width: '5%' }}>
                    {position}.
                  </td>
                  <td
                    className={'eIndividualInterHousesResultsCell'}
                    style={{ width: '30%' }}
                  >{`${firstName} ${lastName}`}</td>
                  <td className={'eIndividualInterHousesResultsCell'} style={{ width: '10%' }}>
                    {houseName}
                  </td>
                  <td className={'eIndividualInterHousesResultsCell'} style={{ width: '15%' }}>
                    {houseColors.map((color, index) => (
                      <span
                        key={index}
                        style={{
                          display: 'inline-block',
                          width: '10px',
                          height: '10px',
                          backgroundColor: color,
                          borderRadius: '50%',
                          marginLeft: '5px'
                        }}
                      ></span>
                    ))}
                  </td>
                  <td className={'eIndividualInterHousesResultsCell'} style={{ width: '15%' }}>
                    {pointsStr}
                  </td>
                  <td className={'eIndividualInterHousesResultsCell'} style={{ width: '25%' }}>
                    {this.renderInput(position - 1, id)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Button text={'Cancel'} onClick={onCloseClick} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
        <Button text={'Save points'} onClick={this.onSubmit} customClass={'mt-3 mb-3'} />
      </div>
    );
  }
}
