import axios from 'axios';
import { AppUser } from '../../../views/App/App';

export function getVideoConfig(user: AppUser) {
  const { loginSessionKey: key } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.get(`${window.apiBase}/i/videoConfig`, config).then(response => {
    return response.data;
  });
}
