import * as React from 'react';
import * as propz from 'propz';
import { Redirect, Route } from 'react-router';
import { ROLE } from '../../consts/user';
import { KIND } from '../../consts/school';

export const LoginRoute = ({ component: Component, user: user, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const userActiveRole = propz.get(user, ['activeRole']);
      const userActiveSchoolKind = propz.get(user, ['activeSchool', 'kind']);

      const isParent = userActiveRole === ROLE.PARENT;
      const isAdmin = userActiveRole === ROLE.ADMIN;
      const isManager = userActiveRole === ROLE.MANAGER;
      const isCoach = userActiveRole === ROLE.COACH;
      const isTeacher = userActiveRole === ROLE.TEACHER;
      const isStudent = userActiveRole === ROLE.STUDENT;
      const isAuthorized = propz.get(user, ['authorized']);

      const isSchoolUnion = userActiveSchoolKind === KIND.SCHOOL_UNION;
      const isSchoolUnionAdmin = isAdmin && isSchoolUnion;

      const isUserActiveRole = typeof userActiveRole !== 'undefined';
      const currentPathname = propz.get(props, ['location', 'state', 'from', 'pathname']);
      const currentSearch = propz.get(props, ['location', 'state', 'from', 'search']);

      switch (true) {
        case currentPathname && isUserActiveRole:
          return (
            <Redirect
              to={{
                pathname: currentPathname,
                state: props.location.state,
                search: currentSearch
              }}
            />
          );
        case isSchoolUnionAdmin:
          return (
            <Redirect
              to={{
                pathname: '/tournaments/upcoming',
                state: props.location.state
              }}
            />
          );
        case isParent || isStudent || isTeacher || isCoach || isManager || isAdmin:
          return (
            <Redirect
              to={{
                pathname: '/calendar',
                state: props.location.state
              }}
            />
          );
        case isAuthorized:
          return <Component {...props} />;
        default:
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: props.location.state
              }}
            />
          );
      }
    }}
  />
);

LoginRoute.displayName = 'LoginRoute';
