import { AppUser } from 'Src/views/App/App';
import axios from 'axios';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../consts/table';
import { Count } from '../../../models/count';

const DEFAULT_MESSAGES_FILTER: any = {
  allMessageTypes: true
};

export function getStudentOutboxMessages(user: AppUser, filter = DEFAULT_MESSAGES_FILTER) {
  const { roleSessionKey: key } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...DEFAULT_MESSAGES_FILTER,
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order
      }
    }
  };

  return axios.get(`${window.apiBase}/i/events/messages/outbox`, config).then(response => {
    return response.data;
  });
}

export function getStudentAllOutboxMessages(user: AppUser, where?) {
  const { roleSessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_MESSAGES_FILTER,
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_MESSAGES_FILTER
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios.get(`${window.apiBase}/i/events/messages/outbox`, config).then(response => {
    return response.data;
  });
}

export function getStudentOutboxMessagesCount(user: AppUser, where?): Promise<Count> {
  const { roleSessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_MESSAGES_FILTER,
            ...where
          }
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_MESSAGES_FILTER
          }
        }
      }
    };
  }

  return axios.get<Count>(`${window.apiBase}/i/events/messages/outbox/count`, config).then(response => {
    return response.data;
  });
}

export function getStudentArchiveMessages(user: AppUser, filter = DEFAULT_MESSAGES_FILTER) {
  const { roleSessionKey: key } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...DEFAULT_MESSAGES_FILTER,
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order
      }
    }
  };

  return axios.get(`${window.apiBase}/i/events/messages/archive`, config).then(response => {
    return response.data;
  });
}

export function getStudentAllArchiveMessages(user: AppUser, where?) {
  const { roleSessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_MESSAGES_FILTER,
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_MESSAGES_FILTER
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios.get(`${window.apiBase}/i/events/messages/archive`, config).then(response => {
    return response.data;
  });
}

export function getStudentArchiveMessagesCount(user: AppUser, where?): Promise<Count> {
  const { roleSessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_MESSAGES_FILTER,
            ...where
          }
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_MESSAGES_FILTER
          }
        }
      }
    };
  }

  return axios.get<Count>(`${window.apiBase}/i/events/messages/archive/count`, config).then(response => {
    return response.data;
  });
}

export function getStudentInboxMessages(user: AppUser, filter = DEFAULT_MESSAGES_FILTER) {
  const { roleSessionKey: key } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...DEFAULT_MESSAGES_FILTER,
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order
      }
    }
  };

  return axios.get(`${window.apiBase}/i/events/messages/inbox`, config).then(response => {
    return response.data;
  });
}

export function getStudentAllInboxMessages(user: AppUser, where?) {
  const { roleSessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_MESSAGES_FILTER,
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_MESSAGES_FILTER
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios.get(`${window.apiBase}/i/events/messages/inbox`, config).then(response => {
    return response.data;
  });
}

export function getStudentInboxMessagesCount(user: AppUser, where?): Promise<Count> {
  const { roleSessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_MESSAGES_FILTER,
            ...where
          }
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_MESSAGES_FILTER
          }
        }
      }
    };
  }

  return axios.get<Count>(`${window.apiBase}/i/events/messages/inbox/count`, config).then(response => {
    return response.data;
  });
}

export function acceptStudentClubInvitation(user: AppUser, messageId, data) {
  const { roleSessionKey: key } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.post(`${window.apiBase}/i/clubs/messages/${messageId}/accept`, data, config).then(response => {
    return response.data;
  });
}

export function rejectStudentClubInvitation(user: AppUser, messageId) {
  const { roleSessionKey: key } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.post(`${window.apiBase}/i/clubs/messages/${messageId}/reject`, {}, config).then(response => {
    return response.data;
  });
}

export function cancelRejectionOfStudentClubInvitation(user: AppUser, messageId) {
  const { roleSessionKey: key } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.delete(`${window.apiBase}/i/clubs/messages/${messageId}/reject`, config).then(response => {
    return response.data;
  });
}
